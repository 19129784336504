import React, { useState, useEffect } from "react";
import TopHeading from "../../TopHeading";
import { useNavigate } from "react-router-dom";
import api, {
	report_endpoint,
	unit_endpoint,
	owner_endpoint,
	first_note_endpoint,
	report_unit_assignment_endpoint,
	report_owner_assignment_endpoint
} from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericInput from "react-numeric-input";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TbGripVertical } from "react-icons/tb";
import { BsTrash } from "react-icons/bs";
import edit from "../../../assets/images/edit.svg";
import Modal from "react-bootstrap/Modal";
import { FaPlusCircle } from "react-icons/fa";

import AutoSizeTextarea from "./AutoSizeTextarea";
import ButtonRm from "../../ButtonRm";

import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';



//INVIA A FORM DATA MANAGERCOMPETENCE, se % totalWithPercentage, se ammontare totalWithAmount o 0


const CreateReportSection = () => {
	const [formData, setFormData] = useState(() => {
		const initialValue = JSON.parse(localStorage.getItem("report"));
		return {
			type: initialValue?.type || "unit",
			userID: initialValue?.userID || 0,
			scale: initialValue?.scale || "",
			fromDate: initialValue?.fromDate || "",
			toDate: initialValue?.toDate || "",
			report: initialValue?.report || "",
			addParcel: initialValue?.addParcel || false,
			typology: initialValue?.typology || "",
			value: initialValue?.value || 0,
			unit: initialValue?.unit || [],
			owner: initialValue?.owner || [],
			vat: initialValue?.vat || "",
			totalExpenses: 0,
			managerCompetence: 0,
			movement:"",
		};
	});
	useEffect(() => {
		localStorage.setItem("report", JSON.stringify(formData));
	}, [formData]);

	const onChange = (e) => {
		if (e.target.type == "checkbox") {
			setFormData({ ...formData, [e.target.name]: e.target.checked });
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const [count, setCount] = useState(() => {
		const initialCount = JSON.parse(localStorage.getItem("reportState"));
		return initialCount ? initialCount : 0;
	});

	useEffect(() => {
		localStorage.setItem("reportState", JSON.stringify(count));
	}, [count]);
	const forward = () => {
		var num = count;
		setCount(++num);
	};

	const backward = () => {
		var num = count;
		if (num > 0) {
			setCount(--num);
		}
	};
	const navigate = useNavigate();


	const [units, setUnits] = useState([]);
	async function getUnits() {
		try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				setUnits(response.data.content);
				console.log("units", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [owners, setOwners] = useState([]);
	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setOwners(response.data.content);
				console.log("owners", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [firstNotes, setFirstNotes] = useState([]);

	//NOTES E' L'ARRAY CREATO CONTENTENTE DATI FROMATTATI DA PRIMA NOTA PER COSTRUIRE LA TABELLA
	const [notes, setNotes] = useState([]);
	async function getNotes() {
		try {
			const response = await api().get(`${first_note_endpoint}`);
			if (response.data) {
				// setNotes(response.data.content);
				setFirstNotes(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getUnits();
		getOwners();
		getNotes();
	}, []);

	const [resultData, setResultData] = useState(0);

	const handleSelectedResultData = (e, value) => {
		var temp = resultData;
		if (e.target.checked) {
			setResultData(temp + value);
		} else {
			setResultData(temp - value);
		}
	};
	
	//CALCOLO SPETTANZA SE COMPENSO IN %
	let spettanzaGestore = resultData != 0 ? (resultData / 100) * parseFloat(formData.value) : 0
	let spettanzaGestoreConIvaPercentuale = spettanzaGestore != 0? (parseFloat(spettanzaGestore) * parseFloat(formData.vat)) * -1 : 0


	//CALCOLO SPETTANZA SU AMMONTARE FISSO
	let spettanzaGestoreConIvaAmmontare = formData.value != 0 && formData.value != "" ?  (parseFloat(formData.value) * parseFloat(formData.vat)) * -1 : 0

	//OTTIENI INDIRIZZO DI UNITA' PASSANDO OGGETTO UNIT
	let getAddress = (unit) => {
	let address = unit.address || '';
	if (unit.houseNumber) {
		address += ' ' + unit.houseNumber;
	}
	if (unit.scale) {
		address += ', Scala ' + unit.scale;
	}
	if (unit.internal) {
		address += ', Interno ' + unit.internal;
	}
	return address.trim();
	}
	
	//LAVORO I DATI DELL'UNITA DA SALVARE PER IL REPORT
	let createOutputObject = (immobile) => {
	return {
		id: immobile.id,
		name: immobile.name,
		address: getAddress(immobile),
		municipality: immobile.municipality,
		province: immobile.province,
		category: immobile.category
	};
	}
	
	

	//QUI HA LAVORATO/FORMATTATO I DATI PROVENIENTI DALL'ENDPOINT NOTE PER OTTERE L'ARRAY PER CREARE LE VARIE CARD
	const arrangeNote = (data) => {
		if (data?.length > 0) {
			var newItems = [...data];
			var sum = 0;
			var sumTaxable = 0
			for (var i = 0; i < newItems?.length - 1; i++) {
				for (var j = i + 1; j < newItems.length; j++) {
					var k = 0;
					if (newItems[i].account !== newItems[j].account) {
						const [removed] = newItems.splice(j, 1);
						newItems.push(removed);
						k++;
					}
				}
				i += k;
			}
			var realItems = [];
			let unitData = newItems[0].unit?.length > 0 ? createOutputObject(newItems[0].unit[0]) : {}
			realItems.push({ type: "account", name: newItems[0].account, date:"", unit:"", amount:"", description:"", });
			realItems.push({
				type: "subAccount",
				date: newItems[0]?.operationDate?.substr(0,10), 
				unit: unitData,
				name: newItems[0].subAccount,
				amount: newItems[0].isEnteringAmount ? newItems[0].amount + newItems[0].withholdingTax  : (newItems[0].amount + newItems[0].withholdingTax) * -1,
				taxable: newItems[0].isEnteringAmount ? newItems[0].taxable  : (newItems[0].taxable) * -1,
				vat:  newItems[0].vatCode,
				description: newItems[0].operationDescription,
				fornitore: newItems[0].suppliers?.length > 0 ? newItems[0].suppliers[0].businessName : "",
				preventivo:"",
				note:""
			});
			sum += newItems[0].isEnteringAmount ? newItems[0].amount + newItems[0].withholdingTax  : (newItems[0].amount + newItems[0].withholdingTax) * -1
			sumTaxable += newItems[0].isEnteringAmount ? newItems[0].taxable  : (newItems[0].taxable) * -1


			for (var m = 0; m < newItems.length - 1; m++) {
				if (newItems[m].account !== newItems[m + 1].account) {
					realItems.push({ type: "account", name: newItems[m + 1].account });
					realItems.push({
						type: "subAccount",
						/* date: newItems[m + 1]?.operationDate?.substr(0,10), */
						name: newItems[m + 1].subAccount,
						date: newItems[m + 1]?.operationDate?.substr(0,10), 
						unit: newItems[m + 1].unit?.length > 0 ? createOutputObject(newItems[ m + 1].unit[0]) : {},
						amount: newItems[m + 1].isEnteringAmount ? newItems[m + 1].amount + newItems[m + 1].withholdingTax  : (newItems[m + 1].amount + newItems[m + 1].withholdingTax) * -1,
						taxable:  newItems[m + 1].isEnteringAmount ? newItems[m + 1].taxable : (newItems[m + 1].taxable) * -1,
						vat:  newItems[m + 1].vatCode,
						description: newItems[m + 1].operationDescription,
						fornitore:
							newItems[m + 1]?.suppliers?.length > 0
								? newItems[m + 1].suppliers[0].businessName
								: "",
						preventivo:"",
						note:""
					});
				} else {
					realItems.push({
						type: "subAccount",
						date: newItems[m + 1]?.operationDate?.substr(0,10), 
						unit: newItems[m + 1].unit?.length > 0 ? createOutputObject(newItems[ m + 1].unit[0]) : {},
						name: newItems[m + 1].subAccount,
						amount: newItems[m + 1].isEnteringAmount ? newItems[m + 1].amount + newItems[m + 1].withholdingTax  : (newItems[m + 1].amount + newItems[m + 1].withholdingTax) * -1,
						taxable: newItems[m + 1].isEnteringAmount ? newItems[m + 1].taxable : (newItems[m + 1].taxable) * -1,
						vat:  newItems[m + 1].vatCode,
						description: newItems[m + 1].operationDescription,
						fornitore:
							newItems[m + 1]?.suppliers?.length > 0
								? newItems[m + 1].suppliers[0].businessName
								: "",
						preventivo:"",
						note:""
					});
				}
				sumTaxable += newItems[m + 1].isEnteringAmount ? newItems[m + 1].taxable : (newItems[m + 1].taxable) * -1
				sum += newItems[m + 1].isEnteringAmount ? newItems[m + 1].amount + newItems[m + 1].withholdingTax : (newItems[m + 1].amount + newItems[m + 1].withholdingTax) * -1
			}

			setNotes([...realItems]);
			setTotalValue(sum);
			setTotalTaxable(sumTaxable);
		}
	};

	const vatOption = { esente: 0, "4%": 0.04, "10%": 0.1, "22%": 0.22 };

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const newItems = [...notes];
		const [removed] = newItems.splice(result.source.index, 1);
		newItems.splice(result.destination.index, 0, removed);
		setNotes(newItems);
	};

	const [subAccountNum, setSubAccountNum] = useState(4);

	//CONTIENE SOMMA DEI SOTTOCONTI IMP + IVA
	const [totalValue, setTotalValue] = useState(0);
	//SSOMA SOTTOCONTI IMPONIBILE
	const [totalTaxable, setTotalTaxable] = useState(0);

	console.log({totalValue, totalTaxable})



	//FUNZIONE CHE PRENDE COME PARAMETRO UN ARRAY E NE RESTITUISCE LA SOMMA DEI VALORI CONTENUTI IN AMOUNT
	function sumAmounts(array) {
		return array.reduce((total, item) => {
			return total + (item.amount || 0); // aggiunge l'importo all totale, o 0 se l'importo non è definito
		}, 0); // inizia con un totale di 0
	}

	function sumTaxable(array) {
		return array.reduce((total, item) => {
			return total + (item.taxable || 0); // aggiunge l'importo all totale, o 0 se l'importo non è definito
		}, 0); // inizia con un totale di 0
	}

	const deleteSubItem = (index) => {
		let temp = [...notes];
		temp.splice(index, 1);
		setNotes(temp);
		//setTotalValue(sumAmounts(temp))
	};

	//QAUANDO NOTES ARRAY CAMBIA, RICALCOLA IL TOTALE
	useEffect(() => {
		setTotalValue(sumAmounts(notes))
		setTotalTaxable(sumTaxable(notes))
	}, [notes])

	const [calculationModal, setCalculationModal] = useState(false);

	

	const [calculatedResult, setCalculatedResult] = useState(false);

	const handleResultData = () => {
		setCalculatedResult(true);
		setCalculationModal(false);
	};

	const handleCalcualteModal = () => {
		setCalculationModal(true);
		setResultData(0);
		setCalculatedResult(false);
	};

	const [accountheaderModal, setAccountHeaderModal] = useState(false);
	const [isCustomHeader, setIsCustomHeader] = useState(false);

	const defaultHeaderList = ["Preventivo", "Note", "Fornitore"];
	//GESTIONE COLONNE AGGIUNGIBILI
	const [isFornitore, setIsFornitore] = useState(false);
	const [isPreventivo, setIsPreventivo] = useState(false);
	const [isNote, setIsNote] = useState(false);

	const [selectedDefaultHeader, setSelectedDefaultHeader] = 
		useState("Preventivo");

	const handleDefaultHeader = () => {
		if (selectedDefaultHeader === "Fornitore") {
			setIsFornitore(true);
			setSubAccountNum(subAccountNum + 1);
		} else if(selectedDefaultHeader === "Preventivo"){
			setIsPreventivo(true)
			setSubAccountNum(subAccountNum + 1);
		} else if(selectedDefaultHeader === "Note"){
			setIsNote(true)
			setSubAccountNum(subAccountNum + 1);
		}
		setAccountHeaderModal(false);
	};

	const [accountHeader, setAccountHeader] = useState([]);
	const [customHeader, setCustomHeader] = useState("");
	const [customeHeaderType, setCustomHeaderType] = useState("testo");

	//SE PROPRIETARIO, PRENDO SOLO LE PRIME NOTE DEL PROPRIETARIO
	//SE UNITA, PRENDO SOLO LE PRIME NOTE DELL'UNITA'
	//FILTRATE PER DATA

	const [currentNote, setCurrentNote] = useState([])

	//TENENDO CONTO LE RIAPRTIZIONI	per owner, se spesa divisa prendo solo amount di owner
	const calcolaAmmontareDovuto = (array, personaId) => {
		const ammontariDovuti = array.map((oggetto) => {
		  const partition = oggetto.partition || [];
		  let ammontareDovuto = 0;
	
		  if (!partition || partition.length === 0) {
			ammontareDovuto = oggetto.amount || 0;
		  } else {
			const persona = partition.find((p) => p.id === personaId);
	
			if (persona) {
			  const percentage = persona.percentage || 0;
			  const amount = oggetto.amount || 0;
			  ammontareDovuto = (percentage / 100) * amount;
			}
		  }
	
		  return {
			...oggetto,
			amount: ammontareDovuto,
		  };
		});
	
		return ammontariDovuti;
	  };
	

	useEffect(() => {
		let initialDate = formData.fromDate? new Date(formData.fromDate) : "" 
		let endDate = formData.toDate? new Date(formData.toDate) : "" 

		let array = []

		if (formData.type == "unit" && formData.unit.length > 0){
			array = formData.unit[0].firstNotes
			//FILTRO PER DATE, SE DATE UNSET MOSTRA TUTTI
			let filteredArray = (formData.fromDate && formData.toDate) ?
			array.filter(item => {
				let operationDate = new Date(item.operationDate);
					return operationDate >= initialDate && operationDate <= endDate;
				}) : 
			array
			setCurrentNote(filteredArray)
			arrangeNote(filteredArray);
		} else if(formData.type == "owner" && formData.owner.length > 0) {
			array = formData.owner[0].firstNotes

			//PARSO PARTITION
			let newArray = array.length> 0 ? array.map(item => {
				// Creare una copia dell'item
				let newItem = { ...item };
				
				// Parsare 'partition' se è una stringa
				if (newItem.partition) {

					if (typeof newItem.partition === "string") {
						try {
						  newItem.partition = JSON.parse(newItem.partition);
						} catch (error) {
						  console.error('Errore nel parsing JSON:', error);
						}
					  }
				}
				console.log("newItem", newItem)

				// Ritornare l'item modificato
				return newItem;
			  }) : []
			
			console.log("newArray", newArray)
			
			//FILTRO PER DATE, SE DATE UNSET MOSTRA TUTTI
			let filteredArray = (formData.fromDate && formData.toDate) ?
			newArray.filter(item => {
				let operationDate = new Date(item.operationDate);
				return operationDate >= initialDate && operationDate <= endDate;
			}) :
			newArray;

			setCurrentNote(calcolaAmmontareDovuto(filteredArray, formData.owner[0].id));
			arrangeNote(calcolaAmmontareDovuto(filteredArray, formData.owner[0].id));

		}
	}, [formData.owner, formData.unit, formData.toDate, formData.fromDate])

	//se unità selezionata, proprietario [], e viceversa
	useEffect(() => {
		if(formData.type == "unit"){
			setFormData({ ...formData, owner: [] })
		} else {
			setFormData({ ...formData, unit: [] })
		}
	}, [formData.type])

	//se toggle competenze del gestore
	useEffect(() => {
		if(formData.addParcel){
			setFormData({ ...formData, 
				vat:"",
				typology:"",
				value:0,
				})
		} else {
			setFormData({ ...formData, 
			vat:"",
			typology:"",
			value:0,
			})
		}
	}, [formData.addParcel])


	//CALCOLO TOTALE GESTORE: PRIME NOTE + SPETTANZE GESTORE => TOTAL VALUE + SPETTANZE GESTORE
	const totalWithPercentage = (totalValue + spettanzaGestoreConIvaPercentuale).toFixed(2)

	const totalWithAmmount = (totalValue + spettanzaGestoreConIvaAmmontare).toFixed(2)



	//MODIFICA SOTTOCONTI
	function handleInputChange(e, key, index) {
		const newArray = [...notes];
		const parsedValue = e.target.type == "number" ? parseFloat(e.target.value) : e.target.value
		newArray[index][key] = parsedValue;
		setNotes(newArray); 
	}

	//AGGIUNTA CONT0
	const [showModalAccount, setModalAccount] = useState(false)

	function handleCloseModalAccount(){
		setNewAccount({name:"", })
		setModalAccount(false)
	}

	const [newAccount, setNewAccount] = useState({
		name:"",
		type:"account"
	})

	function handleNewAccount(e){
		if(e.target.value){
			let el = {
			type: "account",
			name: e.target.value
			}

			setNewAccount(el)
		}
	}

	function saveNewAccount(){
		setNotes(prev => {
			return [
				...prev,
				newAccount
			]
		})

		handleCloseModalAccount()
	}

	//AGGIUNTA SUBACCONT0
	const [showModalSubaccount, setModalSubaccount] = useState(false)

	function handleCloseModalSubaccount(){
		setNewSubaccount({
			name:"",
			amount: 0,
			taxable: 0,
			vat:1,
			fornitore:"",
			description:"",
			type:"subAccount",
			preventivo:"",
			unit:{},
			note:"",
			date:""
		})
		setModalSubaccount(false)
	}

	const [newSubaccount, setNewSubaccount] = useState({
		name:"",
		amount: 0,
		taxable: 0,
		vat:1,
		fornitore:"",
		description:"",
		type:"subAccount",
		preventivo:"",
		unit:{},
		note:"",
		date:""

	})

	function handleNewSubaccount(e){
		const {name, value, type} = e.target
		//CONVERTO AMOUNT DA STRINGA A NUMER0
		const parsedValue = type == "number" ? parseFloat(value) : value
		setNewSubaccount(prev => {
			return {
				...prev,
			[name]: parsedValue
			}
		})
	}

	function saveNewSubaccount(){
		setNotes(prev => {
			return [
				...prev,
				{...newSubaccount, amount: Number(newSubaccount.taxable * newSubaccount.vat)}
			]
		})

		handleCloseModalSubaccount()
	}

	console.log(currentNote)

	//TOTALE PREVENTIVO, MOSTRATO SOLO SE IS PREVENTIVO == TRUE
	let sumPreventivo = notes.length> 0 && notes.reduce((total, item) => {
		return total + (item.preventivo || 0); // Aggiunge il valore di 'preventivo' al totale, o 0 se 'preventivo' non è definito
	}, 0);

	//SALVA SPETTANZE NEL FORMDATA
	const save = async () => {
		let str
			str = {
				...formData,
				totalExpenses: totalValue? totalValue : 0,
				managerCompetence: formData.addParcel ?
					(formData.typology == "percentuale") ? (spettanzaGestoreConIvaPercentuale).toFixed(2) :  (spettanzaGestoreConIvaAmmontare).toFixed(2)
					: 0,
				movement: JSON.stringify(notes)
			} 

			//RIMUOVO UNIT E OWNER CHE ASSOCIO A PARTE
			delete str.unit
			delete str.owner
		try {

			const response = await api().post(`${report_endpoint}`, str);
            const reportID = response?.data.id;

			//assign unit
            if (formData.type === "unit" && reportID) {
                const unitID = formData.unit?.[0]?.id; 
                await api().post(`${report_unit_assignment_endpoint}`, {unitID: unitID, reportID: reportID});    
            } 

			 //assign owner
            if (formData.type === "owner" && reportID) {
                const ownerID = formData.owner?.[0]?.id; 
                await api().post(`${report_owner_assignment_endpoint}`, {ownerID: ownerID, reportID: reportID});    
            }  

		} catch (error) {
			console.log(error);
		}
		localStorage.removeItem("report");
		localStorage.removeItem("reportState");
		navigate("/report");
	};

	//ASSOCIA A UNITA'

	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="Nuovo rendiconto" />
				</div>
			</div>
			<div className="step-show">
				<div className="content-container">
					{count === 0 && (
						<div className="form-item-align-box-white d-block">
							<div className="row">
								<div className="col-md-6 mt-3">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Genera rendiconto per
										</label>
										<select
											name="type"
											value={formData.type}
											onChange={(e) => {
												let t = {
													target: {
														name: "type",
														value: e.target.value 
													},
												};
												onChange(t);
											}}>
											<option value={"unit"}>Unità</option>
											<option value={"owner"}>Proprietario</option>
										</select>
									</div>
								</div>
								<div className="col-md-6 mt-3">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											{formData.type == "" && "Unità"}
											{formData.type == "unit" && "Unità"}
											{formData.type == "owner" && "Proprietario"}
										</label>
										<select
											name={formData.type === "unit" ? "unit" : "owner"}
											value={
												formData.type === "unit"
													? formData.unit.length > 0
														? JSON.stringify(formData.unit[0])
														: ""
													: formData.owner.length > 0
													? JSON.stringify(formData.owner[0])
													: ""
											}
											onChange={(e) => {
												setFormData({
													...formData,
													[e.target.name]: [JSON.parse(e.target.value)],
												});
											}}>
											<option value="">Seleziona</option>
											{formData.type === "unit"
												? units?.map((item, index) => {
														return (
															<option key={index} value={JSON.stringify(item)}>
																{item.name}
															</option>
														);
												  })
												: owners?.map((item, index) => {

														return (
															<option key={index} value={JSON.stringify(item)}>
																{item?.name + " " + item?.surname}
															</option>
														);
												  })}
										</select>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 mt-3">
									<div className="d-flex flex-column">
										<label className="f-label fs-16 d-flex align-items-center
mb-2">Rendiconta dal</label>
										<input
											name="fromDate"
											value={formData.fromDate}
											onChange={onChange}
											max="9999-12-31"
											type="date"></input>
									</div>
								</div>
								<div className="col-md-6 mt-3">
									<div className="d-flex flex-column">
										<label className="f-label fs-16 d-flex align-items-center
mb-2">Rendiconta al</label>
										<input
											name="toDate"
											value={formData.toDate}
											onChange={onChange}
											max="9999-12-31"
											type="date"></input>
									</div>
								</div>
							</div>
							{/* INTRODURRE QUANTO FUNZIONANO CONTI */}
							{/* <div className="row mt-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label className="f-label fs-16 d-flex align-items-center
mb-2">
											Conto di riferimento
										</label>
										<input
											name="report"
											type="text"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											value={formData.report}
											onChange={onChange}
										/>
									</div>
								</div>
							</div> */}
							<div className="input-checkbox mt-3">
								<input
									type="checkbox"
									name="addParcel"
									checked={formData.addParcel}
									onChange={onChange}
								/>
								<label
									className="fs-16 me-2"
									style={{ textDecoration: "underline" }}>
									Includi parcella del gestore
								</label>
							</div>
							{formData.addParcel && 
								<div>
									<div className="row ">
										<div className="col-md-6 mt-3">
											<div className="d-flex flex-column">
												<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
													Tipologia di parcella
												</label>
												<select
													
													name="typology"
													value={formData.typology}
													onChange={onChange}>
													<option value="">Seleziona</option>
													<option value="percentuale">Percentuale</option>
													<option value="ammontare fisso">Ammontare fisso</option>
												</select>
											</div>
										</div>
										{ formData.typology &&<div className="col-md-6 mt-3">
											<div className="d-flex flex-column">
												<label className="f-label fs-16 d-flex align-items-center
mb-2">
													{formData.typology == "percentuale" && "Percentuale %"}
													{formData.typology == "ammontare fisso" && "Ammontare fisso €"}
												</label>
												<input 
													type="number"
													value={formData.value}
													name="value"
													onChange={onChange}
												/>
											</div>
										</div>}
										{formData.typology && <div className="col-md-6 mt-3">
												<div className="d-flex flex-column">
													<label className="f-label fs-16 d-flex align-items-center
mb-2">Iva</label>
													<select
														name="vat"
														value={formData.vat}
														onChange={onChange}>
														<option value=""> Seleziona</option>
														<option value={1}> Esente </option>
														<option value={1.04}> 4% </option>
														<option value={1.05}> 5% </option>
														<option value={1.1}> 10% </option>
														<option value={1.2}> 20% </option>
														<option value={1.21}> 21% </option>
														<option value={1.22}> 22% </option>
													</select>
												</div>
										</div>}
									</div>
								</div>
							}
						</div>
					)}
					{count === 1 && (
						<div className="form-item-align-box-white d-block">
							<div className="d-flex justify-content-start">
								<ButtonRm onClick={() => setModalAccount(true)} variant="outline" className="me-2">
									Aggiungi Conto
								</ButtonRm>
								<ButtonRm onClick={() => setModalSubaccount(true)} variant="outline">
									Aggiungi Sottoconto
								</ButtonRm>
							</div>
							<div className="mt-4 mb-2">
								<div className="account-headers">
									<div
										style={{
											width: 100 / (subAccountNum) + "%",
										}}>
										<p >MOVIMENTI</p>
									</div>
									<div
										style={{
											width: 100 / subAccountNum + "%",
										}}>
										<p>IMPONIBILE</p>
									</div>
									<div
										style={{
											width: 100 / subAccountNum + "%",
										}}>
										<p>IVA</p>
									</div>
									<div
										style={{
											width: 100 / subAccountNum + "%",
										}}>
										<p>DESCRIZIONE</p>
									</div>
									{isFornitore && (
										<div
											className="d-flex align-items-center"
											style={{
												width: 100 / subAccountNum + "%",
											}}>
											<p>FORNITORE</p>
											<div
												className="header-trash ms-2"
												onClick={() => {
													setIsFornitore(false);
													setSubAccountNum(subAccountNum - 1);
												}}>
												<BsTrash />
											</div>
										</div>
									)}
									{isPreventivo && (
										<div
											className="d-flex align-items-center"
											style={{
												width: 100 / subAccountNum + "%",
											}}>
											<p>PREVENTIVO</p>
											<div
												className="header-trash ms-2"
												onClick={() => {
													setIsPreventivo(false);
													setSubAccountNum(subAccountNum - 1);
												}}>
												<BsTrash />
											</div>
										</div>
									)}
									{isNote && (
										<div
											className="d-flex align-items-center"
											style={{
												width: 100 / subAccountNum + "%",
											}}>
											<p>NOTE</p>
											<div
												className="header-trash ms-2"
												onClick={() => {
													setIsNote(false);
													setSubAccountNum(subAccountNum - 1);
												}}>
												<BsTrash />
											</div>
										</div>
									)}

									<div>
										<FaPlusCircle
											style={{
												width: "20px",
												height: "20px",
												cursor: "pointer",
											}}
											onClick={() => setAccountHeaderModal(true)}
										/>
									</div>
								</div>
							</div>
							<DragDropContext onDragEnd={handleOnDragEnd}>
								<Droppable droppableId="accounts">
									{(provided) => (
										<table
											className="accounts"
											{...provided.droppableProps}
											ref={provided.innerRef}>
											{notes.map((item, index) => (
												<Draggable
													key={index}
													draggableId={index.toString()}
													index={index}>
													{(provided, snapshot) => (
														<div
															key={index}
															ref={provided.innerRef}
															snapshot={snapshot}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															>
																{item.type === "account" ? (
																	<th className="account-item type-account">
																		{Object.entries(item).map(([key, value]) => {
																			return (
																				key !== "type" &&
																				(
																					<td key={key} className="w-100 text-capitalize account-td">
																						{key === "name" && (
																							<input 
																								type="text"
																								value={value}
																								className="account-input"
																								onChange={(e) => handleInputChange(e, key, index)}
																							/>  
																						)}
																					</td>
																				)
																			);
																		})}
																		<td className="d-flex align-items-center justify-content-end">
																			<TbGripVertical className="mx-3" />
																			<BsTrash
																				style={{ cursor: "pointer" }}
																				onClick={() => {
																					deleteSubItem(index);
																				}}
																			/>
																		</td>
																	</th>
																) : (
																	<tr key={index} className="account-item ">
																		{Object.entries(item).map(([key, value]) => {
																			if (key === "type") return null; // Escludi il campo 'type'

																			if (!isFornitore && key === "fornitore") return null; // Escludi il campo 'fornitore' se non isFornitore
																			if (!isPreventivo && key === "preventivo") return null; 
																			if (!isNote && key === "note") return null; 


																			//NEL CASO POI VOLESSIMO MOSTRARE QUESTI DATI BASTA ASSEGNARE LORO UNA VARIBAILE E RENDERLA TRUE, COME IN FORNITORI
																			if (key === "date") return null;
																			if (key === "unit") return null;
																			if (key === "amount") return null;

																			return (
																				<td key={key} className="w-100 text-capitalize subaccount-td">
																					{key === "taxable" || (isPreventivo && key === "preventivo") ? (
																						<div className="d-flex align-items-center">
																						€ 
																						<input 
																							type="number"
																							value={value}
																							className="subaccount-input"
																							onChange={(e) => handleInputChange(e, key, index)}
																						/>
																						</div>
																					) : key === "unit" ? (
																						value.name // Se la chiave è 'unit', visualizza solo il nome dell'unità
																					) : key === "vat" ? (
																						(
																							value == 1 ? "Esente" :
																							value == 1.04 ? "4%" :
																							value == 1.05 ? "5%" :
																							value == 1.1 ? "10%" :
																							value == 1.2 ? "20%" :
																							value == 1.21 ? "21%" :
																							value == 1.22 ? "22%" :
																							""
																						  )
																					  ): (
																						<AutoSizeTextarea
																						value={value}
																						onChange={(e) => handleInputChange(e, key, index)}
																						/>
																					)}
																				</td>
																			);
																			})}

																		<td className="d-flex align-items-center justify-content-end pe-2">
																			<TbGripVertical className="mx-3" />
																			<BsTrash
																				style={{ cursor: "pointer" }}
																				onClick={() => {
																					deleteSubItem(index);
																				}}
																			/>
																		</td>
																	</tr>
															)}
														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</table>
									)}
								</Droppable>
							</DragDropContext>
							<div className="mt-5">
							<tr className="result-item">
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}> IMPONIBILE GESTIONE </td>
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}>
										<div>€ {totalTaxable}</div>
									</td>
								</tr>
								<tr className="result-item">
									<td  style={{width: 100 / (subAccountNum - 1 ) + "%"}} >SPETTANZE GESTORE</td>
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}  >
										{formData.typology === "percentuale" ? (
											calculatedResult ? (
												<div className="d-flex">
													<div className="me-2">
														€ {spettanzaGestoreConIvaPercentuale}  (€{spettanzaGestore} + IVA  {Math.ceil((formData.vat ? parseFloat(formData.vat) : 1) * 100 - 100) === 0 ? "Esente" : Math.ceil((formData.vat ? parseFloat(formData.vat) : 1) * 100 - 100) + "%"} )
													</div>
													<button
														type="button"
														className="btn round-icon-btn small"
														onClick={handleCalcualteModal}>
														<img loading="lazy" src={edit} alt="icon" />
													</button>
												</div>
											) : (
												<div
													className="calculate-btn text-center me-4"
													onClick={handleCalcualteModal}>
													Calcola
												</div>
											)
										) : (
											<div>
												€{formData.value ? `${spettanzaGestoreConIvaAmmontare} (€${formData.value} + IVA ${Math.ceil((formData.vat ? parseFloat(formData.vat) : 1) * 100 - 100) === 0 ? "Esente" : Math.ceil((formData.vat ? parseFloat(formData.vat) : 1) * 100 - 100) + "%"})` : 0}

												
											</div>
										)}
									</td>
								</tr>
							
								<tr className="result-item">
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}> IMPONIBILE + IVA  </td>
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}>
									{formData.typology === "percentuale" ?
											<div>€ {totalTaxable + spettanzaGestoreConIvaPercentuale}</div> :
											<div>€ {totalTaxable + spettanzaGestoreConIvaAmmontare}</div>
										}
									</td>
								</tr>
								{isPreventivo && <tr className="result-item">
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}> TOTALE PREVENTIVATO </td>
									<td style={{width: 100 / (subAccountNum - 1 ) + "%"}}>
											<div>€ {sumPreventivo}</div> 
									</td>
								</tr>}
							</div>


							<Modal show={calculationModal} centered >
								<Modal.Body className="p-5">
									<div className="d-flex flex-column justify-content-center align-items-center">
										<div>
											<div className="fs-24 fw-bold mb-3">
												Calcola spettanze gestore (%)
											</div>
											<div className="f-label">
												Seleziona le voci da prendere in considerazione per
												calcolare le spettanze
											</div>
											<div className="mt-3">
												{currentNote.length > 0 &&
													currentNote.filter(el => el.isEnteringAmount).map((item) => {
														return (
															<div key={item.id} className="input-checkbox">
																<input
																	type="checkbox"
																	name="result"
																	// checked={formData.addParcel}
																	onChange={(e) =>
																		handleSelectedResultData(
																			e,
																			item.taxable 
																		)
																	}
																/>
																<label className="fs-14 me-2">
																	{item.subAccount +
																		" " +
																		(item.taxable) +
																		"€"}
																</label>
															</div>
														);
													})}
											</div>
											{resultData !== 0 && (
												<div className="mt-3">
													<hr></hr>
													
												<div>TOTALE: € {resultData}</div>
												<div>SPETTANZA GESTORE: {formData.value}% di €{resultData} = € {spettanzaGestore}</div>
												<div>SPETTANZE GESTORE CON IVA : {spettanzaGestore} + IVA = € {spettanzaGestoreConIvaPercentuale} </div>
												</div>
											)}
										</div>
										<div className="d-flex flex-column mt-2">
											<button
												type="button"
												className="general-btn pink-btn w-193"
												onClick={handleResultData}>
												Salva
											</button>
											<button
												type="button"
												className="general-btn white-btn w-193 mt-2"
												onClick={() => setCalculationModal(false)}>
												Annulla
											</button>
										</div>
									</div>
								</Modal.Body>
							</Modal>

							<Modal show={accountheaderModal} centered >
								<Modal.Body className="p-4">
									<div className="d-flex flex-column justify-content-center align-items-center form-item-align-box">
										<div>
											<div className="fs-24 fw-bold">AGGIUNGI COLONNA</div>
										</div>
										<div className="w-100">
											<div className="input-radio">
												<input
													type="radio"
													value={false}
													name="typology"
													checked={!isCustomHeader}
													onChange={() => setIsCustomHeader(false)}
												/>
												<label>Colonne Predefinite</label>
											</div>
											{/* <div className="input-radio">
												<input
													type="radio"
													value={true}
													name="typology"
													checked={isCustomHeader}
													onChange={() => setIsCustomHeader(true)}
												/>
												<label>Colonna personalizzata</label>
											</div> */}
											<div>
												{/* {isCustomHeader ? (
													<div className="d-flex flex-column">
														<label className="f-label fs-16 d-flex align-items-center
mb-2">
															Nome colonna
														</label>
														<input
															name="customHeader"
															type="text"
															className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
															// value={formData.report}
															onChange={(e) => setCustomHeader(e.target.value)}
														/>

														<label className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
															Tipologia
														</label>
														<select
															className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100"
															name="customHeaderType"
															// value={formData.type}
															onChange={(e) =>
																setCustomHeaderType(e.target.value)
															}>
															<option value="testo">testo</option>
															<option value="numero">numero</option>
														</select>
													</div>
												) : ( */}
													<div className="d-flex flex-column">
														<label className="f-label fs-16 d-flex align-items-center
mb-2">
															Seleziona colonna da aggiungere
														</label>
														<select
															className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100"
															name="defaultHeader"
															value={selectedDefaultHeader}
															onChange={(e) =>
																setSelectedDefaultHeader(e.target.value)
															}>
															{defaultHeaderList.map((item, index) => {
																return (
																	<option key={index} value={item}>
																		{item}
																	</option>
																);
															})}
														</select>
													</div>
												{/* )} */}
											</div>
										</div>
										<div className="d-flex flex-column mt-3">
											<button
												type="button"
												className="general-btn pink-btn w-193"
												onClick={handleDefaultHeader}>
												Salva
											</button>
											<button
												type="button"
												className="general-btn white-btn w-193 mt-2"
												onClick={() => setAccountHeaderModal(false)}>
												Annulla
											</button>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							
							{/* MODAL CONTO */}
							<Modal show={showModalAccount} centered onHide={handleCloseModalAccount}>
								<Modal.Header closeButton  className="border-0"></Modal.Header>
								<Modal.Body className="p-5 pt-1">
									<div className="d-flex flex-column justify-content-center align-items-center form-item-align-box">
										<div>
											<div className="fs-24 fw-bold mb-2">Aggiungi conto</div>
										</div>
										<div className="w-100">
											<div className="d-flex flex-column">
												<label className="f-label fs-16 d-flex align-items-center
mb-2">
													Nome
												</label>
												<input
													type="text"
													value={newAccount.name}
													name="name"
													onChange={handleNewAccount}
												/>
											</div>
										</div>
										<div className="d-flex flex-column mt-4">
											<button
												type="button"
												className="general-btn pink-btn w-193"
												onClick={saveNewAccount}>
												Salva
											</button>
											<button
												type="button"
												className="general-btn white-btn w-193 mt-2"
												onClick={ handleCloseModalAccount}>
												Annulla
											</button>
										</div>
									</div>
								</Modal.Body>
							</Modal>

							{/* MODAL SOTTOCONTO */}
							<Modal show={showModalSubaccount} centered onHide={handleCloseModalSubaccount}>
								<Modal.Header closeButton  className="border-0"></Modal.Header>
								<Modal.Body className="p-5 pt-1">
									<div className="d-flex flex-column justify-content-center align-items-center form-item-align-box">
										<div>
											<div className="fs-24 fw-bold mb-2">Aggiungi sottoconto</div>
										</div>
										<div className="w-100">
											<div className="d-flex flex-column">
												<label className="f-label fs-16 d-flex align-items-center
mb-2">
													Nome
												</label>
												<input
													type="text"
													value={newSubaccount.name}
													name="name"
													onChange={handleNewSubaccount}
												/>
											</div>
										</div>
										<div className="w-100 mt-3">
											<div className="d-flex flex-column">
												<label className="f-label fs-16 d-flex align-items-center
mb-2">
													Imponibile €
												</label>
												<input
													type="number"
													value={newSubaccount.taxable}
													name="taxable"
													onChange={handleNewSubaccount}
												/>
											</div>
										</div>
										<div className="w-100 mt-3">
											<div className="d-flex flex-column">
												<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Codice IVA
												</label>
												<select
												name="vat"
												value={newSubaccount.vat}
												onChange={handleNewSubaccount}
												>
												<option value={1}> Esente </option>
												<option value={1.04}> 4% </option>
												<option value={1.05}> 5% </option>
												<option value={1.1}> 10% </option>
												<option value={1.2}> 20% </option>
												<option value={1.21}> 21% </option>
												<option value={1.22}> 22% </option>
												</select>
											</div>
											</div>
										<div className="d-flex flex-column mt-4">
											<button
												type="button"
												className="general-btn pink-btn w-193"
												disabled={(newSubaccount.taxable && newSubaccount.name && newSubaccount.vat) ? false: true}
												onClick={saveNewSubaccount}>
												Salva
											</button>
											<button
												type="button"
												className="general-btn white-btn w-193 mt-2"
												onClick={ handleCloseModalSubaccount}>
												Annulla
											</button>
										</div>
									</div>
								</Modal.Body>
							</Modal>
						</div>
					)}
					<div className="d-flex justify-content-end mt-5">
						{count === 0 ? (
							<ButtonRm variant="tertiary" className="me-2"
							onClick={() => navigate(-1)}>Annulla</ButtonRm>
						) : (
							<ButtonRm variant="tertiary" className="me-2"
							onClick={() => backward()}>Indietro</ButtonRm>
							
						)}
						{count === 1 ? (
							<ButtonRm variant="primary" disabled={notes.length > 0 ? false : true}
							onClick={() => save()}>Genera</ButtonRm>
						) : (							
							<ButtonRm variant="primary" disabled={formData.type && (formData.unit.length > 0 || formData.owner.length > 0? false : true)}
							onClick={() => forward()}>Avanti</ButtonRm>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateReportSection;

