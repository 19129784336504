import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "../components/Container";
import MidTabsE from "../components/Membri/ProprietariSublocatori/edit_mode/MidTabsE";
import TopHeadPartE from "../components/Membri/ProprietariSublocatori/edit_mode/TopHeadPartE";
import { owner_endpoint, BASE_URL } from "../api/api";
import { useEffect } from "react";
import axios from "axios";
import TopHeading from "../components/TopHeading";


export default function EditPropritari() {
  const location = useLocation();
  const [owner, setOwner] = useState({});


  // TODO con api() da problemi con la chiamata API, da risolvere
  const fetchOwner = () => {
    axios
      .create({
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
        },
      })
      .get(
        owner_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
      .then((res) => {
        if (res.data) {
          setOwner({
            ...res.data,
            initialBankAccountsIDs: res.data.bankAccounts.map(
              (bankAccount) => bankAccount.id
            ),
          });
        }
      });
  };

  useEffect(() => {
    fetchOwner();
  }, []);





  return (
    <>
      <TopHeading SmallHeading="empty"/>
      <TopHeadPartE formData={owner} setFormData={setOwner} fetchOwner={fetchOwner} />
      <MidTabsE formData={owner} setFormData={setOwner} />
    </>
  );
}
