import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TopHeading from '../TopHeading';
import "../../assets/css/Supporto.css"

//La mia intenzione era usare un linguaggio MD, più facilmente mantenibile, ma non ho trovato un modo per poter gestire classi e id (non sono standard nel linguaggio di markdown)
//In un futuro per la mantenibilità potremmo valutare un CMS Headless (strapi - prisma) o wordpress,
//per renderlo più facilmente mantenibile, però richiede del lavoro lato backend (node api)
//AGGIUNGERE AD ASANA

function scrollToParagraph(id) {
  const element = document.getElementById(id);
  if (element) {
    const offsetTop = element.getBoundingClientRect().top + window.pageYOffset - 50;
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });
  }
}

const Struttura = ({ articoloComponent, fileSystemNavigatorComponent, breadcrumbName }) => {
  const handleSelect = (event, nodeId) => {
    scrollToParagraph(nodeId);
  };

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollTop(newScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  console.log(scrollTop, "scrollTop");

  return (
    <>
      <div className="right-header">
        <TopHeading SmallHeading="empty" Heading="Supporto" />
      </div>
      <Container fluid> 
        <Row className="settings-container">
          <Row> 
            <Breadcrumb>
              <Breadcrumb.Item 
                className="settings-archive"
                onClick={() => {
                  navigate(-1)
                }}>
                Pagina iniziale
              </Breadcrumb.Item>
              <Breadcrumb.Item active={true} className="settings-archive">
                {breadcrumbName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row className="mt-4" style={{overflow: "unset"}}>
            <Col lg={3} style={{overflow: "unset"}}>
              <div style={{
                  position: 'sticky',
                  top: `3.5rem`,
                  marginBottom: "2rem"
              }}>
                {fileSystemNavigatorComponent({handleSelect})}
              </div>
            </Col>
            <Col lg={9} className="">
              <div>
                {articoloComponent}
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default Struttura;
