import React, { useRef, useState, useEffect } from "react";
import { BsCalendar2Event, BsPrinter } from "react-icons/bs";
import { formatDateFromString, formatItalianDate } from "../../../../utility";

import { Row, Col } from "react-bootstrap";
import PrintDwonloadIcons from "./PrintDwonloadIcons";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";
import { Form } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import axios from "axios";

import { NATION } from "../../../../constants";

import translationMapping from '../../mapping'
import ExportButton from "../../Fornitori/ExportButton";

import CustomToggle from "../../../CustomToggle";

import CustomBadge from "../../../CustomBadge.js";

export default function AnagraficaV(props) {
  const componentRef = useRef();
  const isPersonaFisica = props.formData.type === "persona fisica";

  const [viewMode, setMode] = useState(true)

  let item = {
	name: props.formData.name,
	surname: props.formData.surname,
	birthday: props.formData.birthday,
	state: props.formData.state,
	province: props.formData.province,
	municipality: props.formData.municipality,
	fiscalCode: props.formData.fiscalCode,
	sex: props.formData.sex,
	notes: props.formData.notes,
	businessName: props.formData.businessName,
	withholding: props.formData.withholding,
	subjectType: props.formData.subjectType,
	societyFiscalCode: props.formData.societyFiscalCode,
	vatNumber: props.formData.vatNumber,
	chamberOfCommerceNumber: props.formData.chamberOfCommerceNumber,
	chamberOfCommerceCity: props.formData.chamberOfCommerceCity,
	reaNumber: props.formData.reaNumber,
    shareCapital: props.formData.shareCapital
  };


    /* GESTISCE L'ELEMENTO ACCORDION ATTIVO */
    const [activeKey, setActiveKey] = useState('');

  return (
    <>
      <div className="d-flex mb-3 justify-content-end mt-3 me-3">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					
					<ExportButton showPrint data={item} mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
      <fieldset disabled={viewMode} ref={componentRef}>
        <div className="form-item-align-box d-block pb-5">
        <Accordion defaultActiveKey={['0', '1', '4']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
						<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="0" activeKey={activeKey}>Tipo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
							</Card.Header>
							<Accordion.Body>
								<Row className="mb-3" >
									<Col md={6}>
										<div className="d-block">
											<div className="radioLabel">
												<input
													type="radio"
													id="contactChoice1"
													value="persona fisica"
													name="type"
													className="btn-radio"
													// onChange={onChange}
													checked={props.formData.type == "persona fisica"}
												/>
												<label htmlFor="contactChoice1" className="btn-radio">
													&nbsp;Persona fisica
												</label>
											</div>
											<div className="radioLabel">
												<input
													type="radio"
													id="contactChoice2"
													value="società od altri soggetti"
													name="type"
													className="btn-radio"
													// onChange={onChange}
													checked={props.formData.type == "società od altri soggetti"}
												/>
												<label htmlFor="contactChoice2" className="btn-radio">
													&nbsp;Società od altri soggetti
												</label>
											</div>
										</div>
									</Col>
								</Row>							
							</Accordion.Body>
						</Accordion.Item>

						{props.formData.type === "società od altri soggetti" ? (
						<>
							<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="1" activeKey={activeKey}>Società <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
								</Card.Header>
								<Accordion.Body>
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Denominazione o ragione sociale
												<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												type="text" 
												name="businessName"
												value={props.formData.businessName}
												// onChange={onChange}
												// isInvalid={!!errors.businessName}
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.businessName?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
										</Col>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Ritenuta
											</Form.Label>
											<Form.Select
												name="withholding"
												value={props.formData.withholding}
												// onChange={onChange}
												// isInvalid={!!errors.withholding}
											>
												<option value=""></option>
												<option value="Nessuna">Nessuna</option>
												<option value="Ritenuta normale">Ritenuta normale</option>
												<option value="Ritenuta condominiale (4%)">Ritenuta condominiale (4%)</option>
											</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
												{errors.withholding?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
										</Col>
									</Row>	
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Tipo di soggetto
											</Form.Label>
											<Form.Select 
												/* å */
												name="subjectType"
												value={props.formData.subjectType}
												/* å */
											>
											<option value="società">Società</option>
												<option value="ditta individuale">Ditta Individuale</option>
												<option value="cooperativa">Cooperativa</option>
												<option value="ente">Ente</option>
												<option value="associazione">Associazione</option>
												<option value="fondazione">Fondazione</option>
												<option value="studio">Studio</option>
												<option value="professionista">Professionista</option>
											</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
												{errors.subjectType?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
										</Col>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Codice Fiscale
											</Form.Label>
											<Form.Control 
												// {...register("societyFiscalCode")}
												type="text" 
												name="societyFiscalCode"
												value={props.formData.societyFiscalCode}
												// onChange={onChange}
												// isInvalid={!!errors.societyFiscalCode}
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.societyFiscalCode?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
										</Col>
									</Row>	
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Partita IVA
											</Form.Label>
											<Form.Control 
												// {...register("vatNumber")}
												type="text" 
												name="vatNumber"
												value={props.formData.vatNumber} 
												// onChange={onChange}
												// isInvalid={!!errors.vatNumber}
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.vatNumber?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
										</Col>
									</Row>						
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="2" activeKey={activeKey}>Camera di Commercio</CustomToggle>
								</Card.Header>
								<Accordion.Body>
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Numero
											</Form.Label>
											<Form.Control 
												/* {...register("chamberOfCommerceNumber")} */
												type="text"
												name="chamberOfCommerceNumber"
												value={props.formData.chamberOfCommerceNumber}
												/* onChange={onChange}
												isInvalid={!!errors.chamberOfCommerceNumber} */
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.chamberOfCommerceNumber?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Città
												</Form.Label>
												<Form.Control 
													// {...register("chamberOfCommerceCity")}
													type="text"
													name="chamberOfCommerceCity"
													value={props.formData.chamberOfCommerceCity}
													// onChange={onChange}
													// isInvalid={!!errors.chamberOfCommerceCity}
												/>
												{/* <Form.Control.Feedback type="invalid" >
												{errors.chamberOfCommerceCity?.message}
												</Form.Control.Feedback> */}
											</Form.Group>
										</Col>
									</Row>						
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="3" activeKey={activeKey}>Altri dati</CustomToggle>
								</Card.Header>
								<Accordion.Body>
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													REA
												</Form.Label>
												<Form.Control 
													// {...register("reaNumber")}
													type="text"
													name="reaNumber"
													value={props.formData.reaNumber}
													// onChange={onChange}
													// isInvalid={!!errors.reaNumber}
												/>
												{/* <Form.Control.Feedback type="invalid" >
												{errors.reaNumber?.message}
												</Form.Control.Feedback> */}
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Capitale sociale
												</Form.Label>
												<Form.Control 
													// {...register("shareCapital")}
													type="number"
													name="shareCapital"
													value={props.formData.shareCapital}
													// onChange={onChange}
													// isInvalid={!!errors.shareCapital}
												/>
												{/* <Form.Control.Feedback type="invalid" >
												{errors.shareCapital?.message}
												</Form.Control.Feedback> */}
											</Form.Group>
										</Col>
									</Row>						
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="4" activeKey={activeKey}>Rappresentante legale <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
								</Card.Header>
								<Accordion.Body>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Nome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												// {...register("name")}	
												type="text" 
												name="name"
												value={props.formData.name}
												// onChange={onChange}
												// isInvalid={!!errors.name}

											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.name?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Cognome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												// {...register("surname")}
												type="text" 
												name="surname"
												value={props.formData.surname}
												// onChange={onChange}
												// isInvalid={!!errors.surname}

											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.surname?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Data di nascita
											</Form.Label>
											<Form.Control
												// {...register("birthday")}
												type="date"
												name="birthday"
												value={
													props.formData.birthday &&
													formatDateFromString(props.formData.birthday)
												}
												// onChange={onChange}
												// isInvalid={!!errors.birthday}
											>
											</Form.Control>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.birthday?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Stato di nascita
											</Form.Label>
												<Form.Select
													/* {...register("state")} */
													name="state"
													id="nationSelect"
													value={props.formData.state}
													readOnly
													/* onChange={onChange}
													isInvalid={!!errors.state} */
												>
													<option value={props.formData.state}>{props.formData.state}</option>
												{/* {nationList &&
													Object.keys(nationList).map((key, index) => {
														return (
															<option value={nationList[key]} key={index}>
																{nationList[key]}
															</option>
														);
												})} */}
												</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.state?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
								</Row>


								 <Row >
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Provincia di Nascita
											</Form.Label>
												<Form.Select
													// {...register("province")}
													name="province"
													value={props.formData.province}
													readOnly
													// onChange={onChange}
													// isInvalid={!!errors.province}
												>
													<option value={props.formData.province}>{props.formData.province}</option>
												{/* {props.provinceList.map((province, index) => {
													return (
														<option value={province} key={index}>
															{province}
														</option>
													);
												})} */}
												</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.province?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Comune di nascita
											</Form.Label>
												<Form.Select
													// {...register("municipality")}
													name="municipality"
													value={props.formData.municipality}
													readOnly
													// onChange={onChange}
													// isInvalid={!!errors.municipality}
													>
														<option value={props.formData.municipality}>{props.formData.municipality}</option>
													{/* {municipalityList?.map((municipality, index) => {
														return (
															<option value={municipality} key={index}>
																{municipality}
															</option>
														);
													})} */}
												</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.municipality?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
								</Row>	

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Codice Fiscale
											</Form.Label>
											<Form.Control 
												// {...register("fiscalCode")}
												type="text" 
												name="fiscalCode"
												value={props.formData.fiscalCode}
												// onChange={onChange}
												// isInvalid={!!errors.fiscalCode}
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.fiscalCode?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Sesso
										</Form.Label>
										<Form.Select
											// {...register("sex")}
											name="sex"
											value={props.formData.sex}
											// onChange={onChange}
											// isInvalid={!!errors.sex}
											>
											<option value="Maschio">Maschio</option>
											<option value="Femmina">Femmina</option>
										</Form.Select>
										{/* <Form.Control.Feedback type="invalid" >
										{errors.sex?.message}
										</Form.Control.Feedback> */}
									</Form.Group>
									</Col>
								</Row>

								<Row className="mb-3">
										<Col md={6} className="d-flex align-items-center">
											<Form.Group className="me-2" style={{width:"90%"}}>	
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													In qualità di
												</Form.Label>
												<Form.Select 
													name="inVirtueOf"
													value={props.formData.inVirtueOf?.[0]}
													// onChange={onChange}
												>{/* 
													{categoryOptions.map((category, index) => {
													return (
													<option className="text-capitalize" value={category} key={index}>
														{category}
													</option>
													);
													})} */}
													<option value={props.formData.inVirtueOf?.[0]}>{props.formData.inVirtueOf?.[0]}</option>
												</Form.Select>
											</Form.Group>
											{/* <BsPlusCircleFill
												className="col-sm-2 col-md-1 fs-30 plus-button mt-4"
											/> */}
										</Col>
								</Row>						
								</Accordion.Body>
							</Accordion.Item>
						</>
						) :
							(<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="1" activeKey={activeKey}>Persona fisica</CustomToggle>
								</Card.Header>
								<Accordion.Body>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Nome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												// {...register("name")}	
												type="text" 
												name="name"
												value={props.formData.name}
												// onChange={onChange}
												// isInvalid={!!errors.name}

											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.name?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Cognome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												// {...register("surname")}
												type="text" 
												name="surname"
												value={props.formData.surname}
												// onChange={onChange}
												// isInvalid={!!errors.surname}

											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.surname?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Data di nascita
											</Form.Label>
											<Form.Control
												// {...register("birthday")}
												type="date"
												name="birthday"
												value={
													props.formData.birthday &&
													formatDateFromString(props.formData.birthday)
												}
												// onChange={onChange}
												// isInvalid={!!errors.birthday}
											>
											</Form.Control>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.birthday?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Stato di nascita
											</Form.Label>
												<Form.Select
													// {...register("state")}
													name="state"
													id="nationSelect"
													value={props.formData.state}
													readOnly
													// onChange={onChange}
													// isInvalid={!!errors.state}
												>
													<option value={props.formData.state}>{props.formData.state}</option>
												{/* {nationList &&
													Object.keys(nationList).map((key, index) => {
														return (
															<option value={nationList[key]} key={index}>
																{nationList[key]}
															</option>
														);
												})} */}
												</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.state?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
								</Row>

								<Row >
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Provincia di Nascita
											</Form.Label>
												<Form.Select
													// {...register("province")}
													name="province"
													value={props.formData.province}
													readOnly
													// onChange={onChange}
													// isInvalid={!!errors.province}
												>
													<option value={props.formData.province}>{props.formData.province}</option>
												{/* {props.provinceList.map((province, index) => {
													return (
														<option value={province} key={index}>
															{province}
														</option>
													);
												})} */}
												</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.state?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Comune di nascita
											</Form.Label>
												<Form.Select
													// {...register("municipality")}
													name="municipality"
													value={props.formData.municipality}
													// onChange={onChange}
													// isInvalid={!!errors.municipality}
													readOnly
													>
														<option value={props.formData.municipality}>{props.formData.municipality}</option>
													{/* {municipalityList?.map((municipality, index) => {
														return (
															<option value={municipality} key={index}>
																{municipality}
															</option>
														);
													})} */}
												</Form.Select>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.municipality?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
								</Row>	

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Codice Fiscale
											</Form.Label>
											<Form.Control 
												// {...register("fiscalCode")}
												type="text" 
												name="fiscalCode"
												value={props.formData.fiscalCode}
												// onChange={onChange}
												// isInvalid={!!errors.fiscalCode}
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.fiscalCode?.message}
										</Form.Control.Feedback> */}
										</Form.Group>
									</Col>
									<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Sesso
										</Form.Label>
										<Form.Select
											// {...register("sex")}
											name="sex"
											value={props.formData.sex}
											// onChange={onChange}
											// isInvalid={!!errors.sex}
											>
											<option value="Maschio">Maschio</option>
											<option value="Femmina">Femmina</option>
										</Form.Select>
										{/* <Form.Control.Feedback type="invalid" >
										{errors.sex?.message}
										</Form.Control.Feedback> */}
									</Form.Group>
									</Col>
								</Row>
							
								</Accordion.Body>
							</Accordion.Item>)
						}

						<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="5" activeKey={activeKey}>Note</CustomToggle>
							</Card.Header>
							<Accordion.Body>
								<Row className="mb-3">
									<Col md={6}>
									<Form.Group>
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
										Tipologia di Conduttore
										</Form.Label>
										<Form.Select
											// {...register("typeOfConductor")}
											value={props.formData.typeOfConductor}
											// onChange={onChange}
										>
											<option value="normale">Normale</option>
											<option value="buono">Buono</option>
											<option value="cattivo">Cattivo</option>
										</Form.Select>
									</ Form.Group >
									</Col>
								</Row>
								<Row>
									<Col>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Note
											</Form.Label>
											<Form.Control 
												// {...register("notes")}
												as="textarea"
												name="notes"
												value={props.formData.notes}
												// onChange={onChange}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>	
					</Accordion>
        </div>
      </fieldset>

    </>
  );
}
