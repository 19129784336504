import React from "react";
import { BsTrash } from "react-icons/bs";
import UserIcon from "../../assets/images/small_user.svg";
import api, { assembly_endpoint } from "../../api/api";

const AssemblyCard = ({
	id,
	name,
	date1,
	hour1,
	place1,
	date2,
	hour2,
	place2,
	deleteAssembly,
}) => {
	const deleteCard = async () => {
		deleteAssembly(id);
		try {
			await api().delete(`${assembly_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<div
			className="general-card mt-1 flex-column flex-sm-row align-items-start align-items-sm-center"
			style={{ borderLeftColor: "#7879F1" }}>
			<div className="d-flex">
				<div className="d-flex flex-column flex-auto">
					<span className="general-card-title">
						{"1° convocazione: " +
							(date1
								? new Date(date1).toLocaleDateString("it-IT")
								: "no date1") +
							" , " +
							(hour1 ? hour1 : "no date") +
							" - " +
							place1}
					</span>
					<div className="d-flex">
						<div className="me-2">
							<img loading="lazy" src={UserIcon} alt="" className="me-1 small-icon" />
							<span className="small-span">Mario Rossi</span>
						</div>
					</div>
					{/* <span className="affitti-card-date mt-1">
				<span>{`Inizio locazione: ${
					start ? new Date(start).toLocaleDateString("it-IT") : "no date"
				}`}</span>
				<span>{`Fine periodo iniziale: ${
					end ? new Date(end).toLocaleDateString("it-IT") : "no date"
				}`}</span>
				<span>{`Fine rinnovo: ${
					renewal_end
						? new Date(renewal_end).toLocaleDateString("it-IT")
						: "no date"
				}`}</span>
			</span> */}
				</div>
			</div>
			<div className="d-flex justify-content-between">
				<div>
					<button
						type="button"
						className="btn round-icon-btn"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							deleteCard();
						}}>
						<BsTrash />
					</button>
				</div>
			</div>
		</div>
	);
};

export default AssemblyCard;
