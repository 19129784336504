import React, { useState, useEffect } from "react";
import SelectToggleBox from "../SelectToggleBox";
import TopHeaderPart from "../TopHeaderPart";
import dropLines from "../../../../assets/images/dwon-lines.svg";
import FliterByDate from "../FliterByDate";
import MonthRateU from "./MonthRateU";
import MediaQuery from "react-responsive";
import Container from "../../../Container";


import TopHeading from "../../../TopHeading";

import SearchInput from "../../../SearchInput";

import filter from "../../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../../assets/images/icon-sidebar/sort.svg"

import { sortDatesByKey, sortStringByKey } from "../../../../utils/filtering";

import { Row, Col, Modal } from "react-bootstrap";

import api , { rate_endpoint } from "../../../../api/api";
import { useParams, useNavigate } from "react-router-dom";

import ButtonRm from "../../../ButtonRm";

import Alert from "react-bootstrap/Alert";
import { checkMaster, checkUserRole } from "../../../../utils/checkUserRole";

import { Breadcrumb } from "react-bootstrap";




export default function InnerDetailsU() {
  const navigate = useNavigate()
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

    //SEARCH
    const [rate, setRate] = useState([])


    //FOUND ONLY INSTALLMENTS OF THIS UNIT
    const {unitId} = useParams()

    //FARLO ANCH PER CONDOMINI
    const ratesForUnità = rate?.length > 0 ? rate.filter(rateObj => rateObj.unit?.length > 0 && parseInt(rateObj.unit[0].id) === parseInt(unitId)) : []
    const ratesForCondo = rate?.length > 0 ? rate.filter(rateObj => rateObj.condominium?.length > 0 && parseInt(rateObj.condominium[0].id) === parseInt(unitId)) : []

    const ratesForUnit = [...ratesForUnità, ...ratesForCondo]

  //get rate
  async function fetchRate() {
    try {
    const response = await api().get(`${rate_endpoint}`);
    if (response.data) {
        setRate(response.data?.content);
    }
    } catch (error) {
    console.log(error);
    }
  }

  useEffect(() => {
    fetchRate();
  }, []);

  //CREA LA LISTA DI UNITA PER IL FILTRO PER UNITA
  const members = ratesForUnit?.length > 0 && ratesForUnit.reduce((acc, rateObj) => {
    const rateOwn = rateObj.owners;
      rateOwn.forEach(owner => {
        if (!acc.find(u => u.id === owner.id)) {
          acc.push({ id: owner.id, name: owner.name, surname: owner.surname });
        }
      })
    return acc;
  }, []);


  //FILTRA PER OWNER
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [search, setSearch] = useState("")

  const filteredRatesForMember = ratesForUnit && ratesForUnit.length > 0
  ? (selectedOwner
      ? ratesForUnit.filter(rateObj => rateObj.owners?.length > 0 && parseInt(rateObj.owners[0].id) === parseInt(selectedOwner))
      : ratesForUnit
  ).filter(rateObj => rateObj.installmentName?.toLowerCase().includes(search?.toLowerCase()))
  : [];
  

  const [rateGroups, setRateGroups] = useState({});

  //FILTER-SEARCH-SORT
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [sortBy, setSortBy] = useState("più-recenti");
  const [searchTerm, setSearchTerm] = useState("")



      //RAGGRUPPA RATE PER MESE

      const groupRates = (rates, startDate, endDate, sortBy, searchTerm) => {
        const groups = {};
      
        rates.forEach((rateObj) => {
          const rateStartDate = new Date(rateObj?.competenceStartDate);
          const isWithinRange =
            (!startDate || rateStartDate >= new Date(startDate)) &&
            (!endDate || rateStartDate <= new Date(endDate)) 
          if (isWithinRange) {
            const monthYear = rateStartDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            });
            if (!groups[monthYear]) {
              groups[monthYear] = [];
            }
            groups[monthYear].push(rateObj);
          }
        });
      
        // Ordinamento delle chiavi (mesi/anni) in base alla logica di sortBy
        const sortedKeys = Object.keys(groups).sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          if (sortBy === "meno-recenti") {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        });
      
        // Creazione dell'oggetto ordinato
        const sortedGroups = {};
        sortedKeys.forEach((key) => {
          sortedGroups[key] = groups[key];
        });
      
         // Applicazione del filtro searchTerm
        const filteredGroups = {};
        Object.keys(sortedGroups).forEach((key) => {
          const filteredRates = sortedGroups[key].filter((rateObj) =>
            rateObj.installmentName.toLowerCase().includes(searchTerm.toLowerCase())
          );
          if (filteredRates.length > 0) {
            filteredGroups[key] = filteredRates;
          }
        });

        return filteredGroups;
      };
      
      useEffect(() => {
        const groupedRates = groupRates(filteredRatesForMember, startDate, endDate, sortBy, searchTerm);
        setRateGroups(groupedRates);
      }, [rate, startDate, endDate, sortBy, searchTerm]);

      function clearFiltersAndSort() {
        setEndDate("")
        setStartDate("")
        setSearchTerm("")
      }
      


    //SALDO CALCULATOR - SUM TOTALDUE OF EACH RATA
    const today = new Date();

    const saldo = ratesForUnit?.length > 0 && ratesForUnit.reduce((accumulator, currentRate) => {
      if ( today >= new Date(currentRate.competenceStartDate)) {
        return accumulator + currentRate?.totalDue;
      }
      return accumulator;
    }, 0);

    /* */

const [selectedItems, setSelectedItems] = useState([]);

// Funzione per gestire la selezione multipla
const handleSelection = (e, id) => {
  if(e){
    e.stopPropagation()
  }

  if (selectedItems.includes(id)) {
    setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
  } else {
    setSelectedItems([...selectedItems, id]);

  }
};

const handleSelectAll = () => {

  const ids = Object.entries(rateGroups).flatMap(([monthYear, rateArray]) => 
  rateArray.map(el => el.id)
  );

  setSelectedItems(ids)
}

const handleDeselectAll = () => {
  setSelectedItems([])
}

// Funzione per eliminare gli stabili selezionati
const deleteSelected = async () => {
  // Effettua l'operazione di eliminazione per ogni id selezionato
  for (const id of selectedItems) {
  try {
    await api().delete(`${rate_endpoint}${id}`);
  } catch (err) {
    console.log(err);
  }
  }
  await fetchRate()
  setSelectedItems([]);

};


//handle modal delete
const [show, setShow] = useState(false);
const handleClose = (e) => {
  setShow(false);
  e.preventDefault();
  e.stopPropagation();
};
const handleShow = () => setShow(true);

//handle modal permessi
const [showPermit, setShowPermit] = useState(false);
const handleClosePermit = (e) => {
  setShowPermit(false);
  e.preventDefault();
  e.stopPropagation();
};
const handleShowPermit = () => setShowPermit(true);


//MI SERVE LISTA COLLABORATORI MASTER
const [memberList, setMemberList] = useState([
  {
    id:1,
    name:"Ema",
    surname:"Mavi",
    email:"ema@gmail.com"
  },
  {
    id:2,
    name: 'Greta',
    surname: 'Pio',
    email: 'gre@gmail.com'
  }
])

const handleCheckboxChange = (userId, action) => {
  //da gestire
};

let pageTitle

  if(ratesForUnit?.length > 0 && ratesForUnit[0]?.unit?.length > 0) {
    pageTitle =  `${ratesForUnit[0].unit[0]?.name}`
  } else if(ratesForUnit?.length > 0 &&
    ratesForUnit[0]?.condominium?.length > 0 ){
    pageTitle =  `${ratesForUnit[0].condominium[0]?.name}`

  } 

  return (
    <>
      <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="" 
            back_modal={false}
          />
        </div>
        <Col md={12} className="my-3 mb-0 px-0">
          <Breadcrumb>
            <Breadcrumb.Item 
              className="settings-archive"
              onClick={() => {
                navigate('/rate')
              }}>
              Rate
            </Breadcrumb.Item>
            <Breadcrumb.Item active={true} 
              className="settings-archive"
            >
              {pageTitle}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Row className="mobile-container d-flex flex-column flex-md-row  justify-content-between align-items-center mt-md-4 div-title-container mb-3">
          <Col >
            <h2 className="fw-bold text-start">
              {pageTitle}
            </h2>
          </Col>
          <Col md={3} xl={2}>
            <div className={`saldo-rate ${saldo > 0 ? "bg-danger" : "bg-success"}`} >
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="fs-16 text-white fw-normal w-100 me-2 ">
                          Saldo
                        </p>
                        <p className="fs-20 text-white fw-semibold w-100 text-end">€{saldo ? saldo.toFixed(2) : 0}</p>
                      </div>
                  </div>
          </Col>
        
        </Row>
      </div>

        <div className="dashboard-container">
          <div className="px-2 px-md-0">
          {<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<SearchInput setSearch={setSearchTerm}/>

				<div className="d-flex align-items-center ms-3">
					<div className="user-dropdown">
					<div className="filter">                   
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
						<img loading="lazy" src={filter}/>
						</div>
            <div className="user-dropdown-content">
            <div className="fs-14 text-uppercase">Periodo</div>
                  <label className="fs-12">Da</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="mb-2"
                  />
                  <label className="fs-12">A</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
               <a className="text-decoration-underline text-dark text-center mt-3" onClick={clearFiltersAndSort}>
                Rimuovi filtri
              </a>
						</div>
            </div>
					</div>

					<div className="user-dropdown">
						<div className="sort d-flex">
				<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
					<img loading="lazy" src={sort}/>
				</div>
						</div>
						<div className="user-dropdown-content">
							<div className="fs-16 fw-bold">Ordina per</div>
				<div
								className="fs-14 mb-1 sort-text"
				onClick={() => setSortBy("meno-recenti")}
								>
								Meno recenti
							</div>
				<div
								className="fs-14 mb-1 sort-text"
				onClick={() => setSortBy("più-recenti")}
								>
								Più recenti
							</div>

						</div>
					</div>
				</div>

			  </Col>}
            <div className="d-block my-3 position-relative">
              <SelectToggleBox textToggle="Tutte le unità" units={members} setSelectedUnit={setSelectedOwner}/>
              {checkUserRole() &&
                	selectedItems.length > 0 && 
                  <div className="d-flex mt-4">
                    <ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
                      Seleziona tutti
                    </ButtonRm>
                    <ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
                      Deseleziona Tutti
                    </ButtonRm>
                  </div>
                }
            </div>
            <MediaQuery minWidth={768}>
            <div className="d-block mb-2">
                    <div className="d-flex flex-column">

                      {Object.entries(rateGroups).map(([monthYear, rateArray]) => (
                        <div key={monthYear}>
                          <p className="fs-14 text-uppercase mt-3 mb-1">{monthYear}</p>
                          {rateArray.map(rateObj => (
                            <MonthRateU  key={rateObj.id} item={rateObj} handleSelection={handleSelection} selectedItems={selectedItems} pageTitle={pageTitle} />
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
              
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <div className="d-flex flex-column">
                {Object.entries(rateGroups).map(([monthYear, rateArray]) => (
                  <div key={monthYear}>
                    <p className="fs-14 text-uppercase mt-3 mb-1">{monthYear}</p>
                    {rateArray.map(rateObj => (
                      <MonthRateU  key={rateObj.id} item={rateObj} handleSelection={handleSelection} selectedItems={selectedItems} pageTitle={pageTitle} />
                    ))}
                  </div>
                ))}
              </div>
            </MediaQuery> 
          </div>
          <Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>
        </div>

        <Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{memberList.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ memberList.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>
      
    </>
  );
}
