import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoRendiconto () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Rendiconto"
        />
    )
}

function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Effettuare l'accesso" />
            <TreeItem nodeId="2" label="Codice OTP" />
        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Rendiconto</h1>
      <h2 style={{ color:'transparent', height: 0}}>Rendiconto</h2>

      <p className="mt-3">Il rendiconto è un report di riepilogo delle entrate e uscite, relativo agli immobili di un proprietario o uno specifico immobile.</p>
      <p>Con pochi click, tramite le informazioni presenti in prima nota, potrai ottenere come output un documento professionale per rendicontare la proprietà</p>
      <Tips variant="automatization" content="Per ottenere un rendiconto completo e corretto, dovrai registrare le spese (entrate e uscite) in prima nota, datarle e associarle all'unità e al proprietario di riferimento" />

      <h2 id="2">Creazione di un rendiconto</h2>
      <p>Dovrai definire</p>
      <ul>
        <li><p>Se vuoi creare il rendiconto per una singola unità o per proprietario (in questo caso verrà creato un rendiconto relativo a tutte le unità che appartengono al proprietatio in questione)</p></li>
        <li><p>Seleziona l'immobile in questione o il proprietario</p></li>
        <li><p>Seleziona il periodo che vuoi rendicontare. Se non selezioni una data di inizio e di fine, mostreremo tutte le entrate e uscite presenti.</p></li>
        <li><p>Definisci se nel rendiconto vuoi includere o meno la tua parcella selezionando la casella di spunta "INCLUDI PARCELLA GESTORE". Definisci l'IVA che applichi e se il tuo compenso è un ammontare fisso o calcolato in % sulle entrate.</p></li>
      </ul>
      <img loading="lazy" src="https://i.postimg.cc/yNBYjPhQ/parcella-1.gif"  />

      <h3 id="2">Riepilogo spese</h3>
      <p>In questi step troverai un riepilogo di tutte le spese in entrata e in uscità relative all'unità o proprietario che hai selezionato</p>
      <img loading="lazy" src="https://i.postimg.cc/jjrN61Xw/step-2.png"  />


      <p>Se alcune informazioni non non sono presenti o vuoi modificare o rimuovere alcune voci potrai farlo direttamente da questo passaggio (Le modifiche che effettui non andranno a modificare le prime note originali)</p>
      <p className="subtitle">Elimina voce</p>
      <p>Clicca sull'icona del cestino a destra, per rimuovere una voce dal riepilogo</p>
      <img loading="lazy" src="https://i.postimg.cc/3wcPkg7w/elimina.gif"  />
       


      <p className="subtitle">Aggiungi voce</p>
      <p>Clicca sul bottone AGGIUNGI SOTTOCONTO.</p>
      <p>Potrai definire il nome e l'importo</p>
      <Tips variant="info" content="Per definire un importo in USCITA, inserisci il segno '-' prima del numero" />
      <img loading="lazy" src="https://i.postimg.cc/hvYfrVhv/aggiunta-sezione-3.gif"  />


      <p className="subtitle">Modifica voce</p>     
       <p>Puoi modificare nomi e importi direttamente dalla tabella, potrai inoltre riordinare le voci con azione "drag and drop"</p>
       <img loading="lazy" src="https://i.postimg.cc/dVGVGXCZ/modifica-2.gif"  />



      <p className="subtitle">Aggiungi sezione</p>     
       <p>Se desideri suddividere le voci in diverse categorie, oltre a quelle predefinite in prima nota, seleziona al voce AGGIUNGI CONTO</p>
       <p>Verrà creato un elemento azzurro, e potrai raggrauppare sotto esso le voci pertinenti a quella macrocategoria</p>
       <img loading="lazy" src="https://i.postimg.cc/xjpJnVgQ/sottoconto-1.gif"  />


       <h3 id="2">Spettanze Gestore</h3>
       <p className="subtitle">Ammontare fisso</p>   
       <p>Troverai l'ammontare fisso che hai definito + IVA, all'interno del riepilogo.</p> 
       <p>La tua parcella verrà sotratta dall'imponibile di gestione </p>
       <img loading="lazy" src="https://i.postimg.cc/KYgLt2By/ammontare-fisso.png"  />

        {/* DA RIVEDERE */}
       <p className="subtitle">Percentuale</p>   
       <p>Clicca su calcola per definire su quali entrate, calcolare la tua parcella. </p> 
       <img loading="lazy" src="https://i.postimg.cc/8zcLPWCc/ammontare-percentuale.png"  />

       <h3>Generazione Rendiconto</h3>
       <p>Clicca sul pulsante Genera in basso a destra per generare il rendiconto</p>

       <img loading="lazy" src="https://i.postimg.cc/P57YLxL4/genera.png"  />

       <h2 id="2">Visualizza rendiconto</h2>
       <p>Dopo la generazione, verrà creata una card relativa al rendiconto che potrai scaricare o aprire sul tuo browser</p>
       <img loading="lazy" src="https://i.postimg.cc/hvKdFQwS/card.png"  />

       <img loading="lazy" src="https://i.postimg.cc/DZBPgb7b/ouput.png"  />



       <h2 id="2">Elimina rendiconto</h2>
       <p>Per eliminare un rendiconto clicca sull'icona del cestino a destra</p>
   









 

    </div>
  );
}

