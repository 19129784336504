import React, { useEffect, useState, useRef } from "react";
import {Col, Row} from "react-bootstrap"
import { Contenutipopup } from "./Contenutipopup";
import { ModemFill } from "react-bootstrap-icons";
import { DeletePopUp } from "./DeletPopUp";
import { AggiungiPopup } from "./AggiungiPopup";

import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from "react-redux";
import { addDeadline } from "../../../actions/deadline";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import Modal from "react-bootstrap/Modal";
import ButtonRm from '../../ButtonRm';
import Table from 'react-bootstrap/Table';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import RiassuntoContabilita from "./RiassuntoContabilita";
import Participants from "./Participants";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import { Outlet } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

import TopHeading from "../../TopHeading";

import esempio from "./signatureexample"

import { chat_list_endpoint, chat_msg_endpoint, verbal_endpoint, accounting_endpoint, first_note_endpoint, note_unit_assignment_endpoint ,note_condominium_assignment_endpoint, user_endpoint  } from "../../../api/api";
import api from "../../../api/api"

import {useNavigate} from "react-router-dom"
import ChatMsg from "./ChatMsg";

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

import { MultiSelect } from "react-multi-select-component";

import CustomToggle from "../../CustomToggle";
import { add, addDays,addMonths, addWeeks, addYears, isAfter } from 'date-fns';
import { parseISO } from 'date-fns'

import ExportButton from "../../Membri/Fornitori/ExportButton";

import ImgFileCard from "../../UnitàImmobiliariStabilli/UnitàImmobiliari/ImgFileCard";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";

import ChatSearch from "./ChatDetails/ChatSearch";
import Collapse from '@mui/material/Collapse';

import { checkUserRole } from "../../../utils/checkUserRole";
import CustomTooltipGeneral from "../../CustomTooltipGeneral";
import WebSocketComponent from "./Websocket";
import { WebSocketProvider } from "../../../utils/websocketContext";

//DETTAGLIO DI UNA CONVERSAZIONE SELEZIONATA

const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    name: "Identificativo",
    realEstateManagements: "Immobili della gestione",
    activities: 'Attività',
    address: "Indirizzo",
    unitName: 'Immobile di riferimento',
    author: "Creato da",
    priority: "Priorità",
    startDate: "Data inizio",
    expirationDate: "Data di scadenza",
    endDate: "Data fine lavori",
    typology: "Tipologia",
    status: 'Stato',
    assignee: 'Incaricato',
    members: 'Partecipanti',
    description: 'Descrizione',
    predicition: 'Previsione di spesa',
    message: 'Messaggio',
    content: 'Contenuto',
}

const schema = yup.object().shape({
    title: yup.string().required("Inserisci un titolo per questo evento"),
    section: yup.string(),
    startDate: yup.date("Seleziona la data di inizio").required(), //mettere come valore iniziale data di oggi per tutte le date
    endDate:	yup.date("Seleziona la data di inizio").required(),
    every:	yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
    recurrenceType:	yup.string(),
    toDate:	yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    notification: yup.string(),
    sharing: yup.string(),
    note: yup.string()
    //aggiungere voci api giorni di preavviso
})

export const Chatdetails = (props) => {
    const [headerpopup, setheaderpopup] = useState(false);
    const [middlepopup, setmiddlepopup] = useState(false);
    const [contenutipopup, setcontenutipopup] = useState(false);
    const [copiapopup, setcopiapopup] = useState(false);
    const [rispondipopup, setrispondipopup] = useState(false);
    const [seleziona, setseleziona] = useState(false);
    const [elimina, setelimina] = useState(false);
    const [chatfooter, setchatfooter] = useState(false);
    const [documenti, setdocumenti] = useState("documenti");
    const [firma, setfirma] = useState("firma");
    const [checklisteverbali, setchecklisteverbali] =
        useState("checklisteverbali");
    const [evento, setevento] = useState("evento");
    const [contabilità, setcontabilità] = useState("contabilità");
    const [aggiungifirma, setaggiungifirma] = useState(false);

    const rispondifun = () => {
        setrispondipopup(!rispondipopup);
        setmiddlepopup(false);
        setseleziona(false);
        setelimina(false);
        setchatfooter(false);
        setcontenutipopup(false);
    };
    const selezionafun = () => {
        setseleziona(!seleziona);
        setmiddlepopup(false);
        setrispondipopup(false);
        setelimina(false);
        setchatfooter(false);
        setcontenutipopup(false);
    };
    const eliminafun = () => {
        setelimina(!elimina);
        setmiddlepopup(false);
        setchatfooter(false);
        setseleziona(false);
        setrispondipopup(false);
        setcontenutipopup(false);
    };
    const aggiungifirmafun = () => {
        setaggiungifirma(!aggiungifirma);
    };
    const chatfooterfun = () => {
        setchatfooter(!chatfooter);
        setmiddlepopup(false);
        setrispondipopup(false);
        setelimina(false);
        setcontenutipopup(false);
        setseleziona(false);
    };
    const contenutipopupfun = () => {
        setcontenutipopup(!contenutipopup);
        setchatfooter(false);
        setmiddlepopup(false);
        setrispondipopup(false);
        setelimina(false);
        setseleziona(false);
    };
    const middlepopupfun = () => {
        setmiddlepopup(!middlepopup);
        setcontenutipopup(false);
        setchatfooter(false);
        setrispondipopup(false);
        setseleziona(false);
        setelimina(false);
    };
    const documentifun = () => {};
    const firmafun = () => {};
    const checklisteverbalifun = () => {};
    const eventofun = () => {};
    const contabilitàfun = () => {};


    /* MESSAGGIO */

    const {chatId} = useParams()
    const [esempioFirma, setEsempio] = useState(esempio)
    //GET THIS CHAT
    const [chat, setChat] = useState({})
    //GET A SPECIFIC MESSAGE
    const [msg, setMsg] = useState({})


     //RISPONDI
    const [replyId, setReplyId] = useState(null)
    const [replyMsg, setReplyMsg] = useState({})
    const [replyMode, setReplyMode] = useState(true)

    async function getOne(id) {
        try {
            const response = await api().get(`${chat_list_endpoint}${id}`);
            if(response.data) {
                response.data.members = response.data.members?.map((item) => JSON.parse(
                    item.replace(/'/g, '"')
                )) 
            }
            setChat(response.data)
        }
        catch(error) {
                console.log(error)
        }
    }

    //EDIT PER MODIFICA PREVISIONE chat
    async function editItem(id, data) {
        try {
            const response = await api().put(`${chat_list_endpoint}${id}`, data);
            await getOne()
        }
        catch(error) {
                console.log(error)
        }
    }

    async function getMsg(id) {
        try {
            const response = await api().get(`${chat_msg_endpoint}${id}`);
            setMsg(response.data)
        }
        catch(error) {
                console.log(error)
        }
    }

    useEffect(() => {
        if(chatId){
            getOne(chatId)
        }
        setChatValue("")
        setEditMsg(false)
        setSelectedIds([])
        setSelectMsg(false)
    }, [chatId])

    useEffect(() => {
        if(msg.message){
            setChatValue(msg.message)
        }
    }, [msg])

    function handleEsempioFirma(url){
        setEsempio(url)
        handleCloseFirma(!showFirma)
        setchatfooter(false)
    }

    const isDesktop = useMediaQuery({
        query: "(min-width: 992px)",
    });
    

    /* MODAL INFO ATTIVITA' */
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    };
    const handleShow = () => setShow(true);

    /* MODAL Riassunto contabilita' */
    const [showRc, setShowRc] = useState(false);
    const handleCloseRc = () => setShowRc(false);

    /* MODAL CHECKLIST */
    const [showChecklist, setShowChecklist] = useState(false);
    const handleCloseChecklist = () => setShowChecklist(false);

    /* MODAL CONTABILITA */
    const [showContabilita, setShowContabilita] = useState(false);
    const handleCloseContabilita = () => setShowContabilita(false);

    /* MODAL EVENTO */
    const [showEvento, setShowEvento] = useState(false);
    const handleCloseEvento= () => setShowEvento(false);

    /* MODAL PARTICIPANT */
    const [showPart, setShowPart] = useState(false);
    const handleClosePart= () => setShowPart(false);

    /* MODAL PARTICIPANT */
    const [showCa, setShowCa] = useState(false);
    const handleCloseCa = () => setShowCa(false);

    /* MODAL FIRMA */
    const [showFirma, setShowFirma] = useState(false);
    const [firmaMsg, setFirmaMsg] = useState("");
    const handleCloseFirma = () => {
        setShowFirma(false)
        setFirmaMsg("")
    }
    const handleOpenFirma = (id) => {
        setShowFirma(true)
        setFirmaMsg(id)
    }

    /* MODAL FIRMA */
    const [showPayment, setShowPayment] = useState(false);
    const handleClosePayment = () => {
        setShowPayment(false)
    }

    /* MODAL ABBANDON */

    const [showAbbandon, setShowAbbandon] = useState(false)

    function handleOpenAbbandon(){
        setShowAbbandon(true)
    }

    function handleCloseAbbandon(){
        setShowAbbandon(false)
    }

    /* MODAL ARCHIVE */

    const [showArchive, setShowArchive] = useState(false)

    function handleOpenArchive(){
        setShowArchive(true)
    }

    function handleCloseArchive(){
        setShowArchive(false)
    }

     /* MODAL DELETE */

     const [showDelete, setShowDelete] = useState(false)

     function handleOpenDelete(){
         setShowDelete(true)
         }
 
     function handleCloseDelete(){
     setShowDelete(false)
     }



    /* CONTROL CHECKLIST INPUT */
    /* ADD BTN TO CHECK MODAL, AND ON CLICK ADD OBJ TO MESSAGE ARRAY */
    /* CREATE A NEW PREVENTIVO MESSAGE */
    /* AUDIO */

    const [recordingExample, setRecording] = useState("")

    const addAudioElement = (blob) => {
        setRecording( URL.createObjectURL(blob))
    };
    
    const recorderControls = useAudioRecorder()

    const customRecord = () => {
        recorderControls.stopRecording()
        addAudioElement()
    }


    // quando record start set variable to true and change ui  of audio component using this variable.

    const message = [
        {
            id: 1,
            userId: "123",
            cDat: "22/11/2022 16:30",
            userName:"Mario Rossi",
            content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel quam sit amet ante faucibus blandit. Donec ex nisi, facilisis in interdum vitae, aliquam quis nisi. Nulla at diam eget metus aliquet maximus. Sed consectetur felis vel metus suscipit, eu euismod dui semper.",
            defaultMessage: true,
            messageOfTheUser: true, /*questo dato qui è temporaneo, per evincere questo dato bisognerà fare un controllo tra id utente in store e userId */
            type:""
        },
        {
            id: 2,
            userId: "123",
            cDat: "22/11/2022 16:30",
            userName:"Gino Grigi",
            content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel quam sit amet ante faucibus blandit. Donec ex nisi, facilisis in interdum vitae, aliquam quis nisi. Nulla at diam eget metus aliquet maximus. Sed consectetur felis vel metus suscipit, eu euismod dui semper.",
            defaultMessage: true,
        },
        {
            id: 3,
            userId: "123",
            cDat: "22/11/2022 16:30",
            userName:"Mario Rossi",
            content:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel quam sit amet ante faucibus blandit. Donec ex nisi, facilisis in interdum vitae, aliquam quis nisi. Nulla at diam eget metus aliquet maximus. Sed consectetur felis vel metus suscipit, eu euismod dui semper.",
            defaultMessage: true,
            messageOfTheUser: true
        },
        {
            id: 4,
            userId: "123",
            cDat: "22/11/2022 16:30",
            userName:"Pippo Petto",
            content:{
                id:1,
                name:"verbale 123",
                content: [
                    {
                        id:1,
                        label: "data fine intervento",
                        value: "25/07/2022"
                    },
                    {
                        id:2,
                        label: "descrizione intervento",
                        value: "Lorem ipsum dolor sit amet consectetur adipiscing elit"
                    },
                    {
                        id:3,
                        label: "tipologia intervento",
                        value: "ordinario"
                    }
                ]
            },
            defaultMessage: false,
            type:"preventivo"
        },
        {
            id: 5,
            userId: "123",
            cDat: "22/11/2022 16:30",
            userName:"Pippo Petto",
            content:esempioFirma,
            defaultMessage: false,
            type: "signature-whole-chat"
        },
        {
            id: 6,
            userId: "123",
            cDat: "22/11/2022 16:30",
            userName:"Gino Grigi",
            content: recordingExample,
            defaultMessage: false,
            type: "audio"
        },
    ]

    const checklists = [
        {
            id:1,
            title: 'verbale 1',
        },
        {
            id:2,
            title: 'checklist 1',
            
        } 
    ]

    const [chatValue, setChatValue] = useState("")
    
    function handleChatValue(e){
        setChatValue(e.target.value)
    }

    const bodyForCreate = {
        message: chatValue,
        senderEmail: "",
        replyID: replyMode? replyId : -1,
        attachments: [],
        tag: [],
        messageType: "text",
        content: "no content",
        deleted: false,
        chatGroupID: chatId
    }

    async function postItem(data){
        try {

            //GESTIONE ALLEGATI IMMAGINE
            let formDataToSendImages = new FormData();
            imageFiles.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSendImages.append("parts", blob, file.name);
			})
            let sentUploadImages = formDataToSendImages.get("parts") !== null;



            const response = await api().post(
                `${chat_msg_endpoint}`,
                data
            );
            
            //CREAZIONE ALLEGATI IMMAGINI
            if (response && sentUploadImages) {
                await api().put(`${chat_msg_endpoint}upload/${response.data.id}`, formDataToSendImages);
            }

            await getOne(chatId);
        } catch (error) {
            console.log(error);
        }
    }

    //EDIT
    async function editItem(data) {
        try {
        await api().put(`${chat_msg_endpoint}${msg.id}`, data);
        await getOne(chatId)
        } catch (error) {
        console.log(error);
        }
    }

        //GESTIONE ALLEGATI IMMAGINI
        const [imageFiles, setImageFiles] = useState([]);

        const handleImageUpload = (event) => {
            const files = event.target.files;
            const fileArray = Array.from(files);
            setImageFiles((prevFiles) => [...prevFiles, ...fileArray]);
        };

    function handleSubmit (e, type = null, signImg = null){
        e.preventDefault()

        console.log(signImg, "url img")

        const bodyEdit = {...msg, message: chatValue}

        if(!editMsg && bodyForCreate.chatGroupID && bodyForCreate.message && type == null){
            delete bodyForCreate.attachments
            postItem(bodyForCreate)
        }

        //CREAZIONE MSG CON FIRMA
        let str
        if(!editMsg && bodyForCreate.chatGroupID && type){
            str = {...bodyForCreate, messageType: "firma intera", attachments: signImg}
            postItem(str)
        }

        if(editMsg && chatValue){
            editItem(bodyEdit)
        }
        setEditMsg(false)
        setChatValue("")
        setMsg({})

        if(replyMode){
            setReplyMode(false)
            setReplyId(null)
            setReplyMsg({})
        }
    }

    const inputRef = useRef([]);

    //EDIT MSG
    const [editMsg, setEditMsg] = useState(false)
    function handleEdit(id){
        setEditMsg(true)
        getMsg(id)
    }

    //SELECT MSG
    const [selectMsg, setSelectMsg] = useState(false)
    const [selectedIds, setSelectedIds] = useState([])
    const selectedItems = chat.chats?.filter(function(item){
        return selectedIds.indexOf(item.id) != -1;
    });

    function handleSelect(id){
        setSelectMsg(true)
        //qui anziché mandare solo id, posso mandare intero oggetto
        //sennò faccio un for each con GET per ogni id
        setSelectedIds(prev => [...prev, id])
    }

    //SELEZIONA
    //deseleziona elementi e svuota lista ids
    function deselect(){
        setSelectMsg(false)
        setSelectedIds([])
    }



    //REPLY FUNCTIONS

    function handleReplyMode(id=null){
        setReplyMode(!replyMode)
        setReplyId(id)
        setReplyMsg({})
    }

    useEffect(() => {
        let replyObj
        if(replyId){
            replyObj = chat.chats.find(item => item.id == replyId)
            setReplyMsg(replyObj)
        } else {
            setReplyMode(false)
            setReplyMsg({})
        }
    }, [replyId])


    //MANAGE CLICKOUTSIDE DROPDOWN

    //ADD IN CHAT DROPDOWN
    const dropDownRefChat = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRefChat.current && !dropDownRefChat.current.contains(event.target)) {
                setchatfooter(false);
            }
        }

        // Aggiungi l'event listener quando il componente viene montato
        document.addEventListener("mousedown", handleClickOutside);
        // Rimuovi l'event listener quando il componente viene smontato
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRefChat]); // Aggiorna se cambia dropDownRef

    // CHAT MENU DROPDOWN
    const dropDownRefMenu = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRefMenu.current && !dropDownRefMenu.current.contains(event.target)) {
                setheaderpopup(false);
            }
        }

        // Aggiungi l'event listener quando il componente viene montato
        document.addEventListener("mousedown", handleClickOutside);
        // Rimuovi l'event listener quando il componente viene smontato
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRefMenu]); // Aggiorna se cambia dropDownRef

    //RICERCA

    const [showSearch, setShowSearch] = useState(false);

    //PERMESSI

    const [userData, setUserData] = useState(null)

    async function fetchUserData(type) {
        try {
            const response = await api().get(`${user_endpoint}`);
            if (response.data) {
                setUserData(response.data?.content[0]);

                console.log("response.data.content", response.data?.content[0])
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    console.log(showArchive, "SHOW ARCHIVE")

    /* controlla partecipant */

    //lista di utenti già presenti in chat
    const [currentParticipants, setCurrentParticipants] = useState([])

    async function getAllParticipants(){
        try {
            let response = await api().get(
                `/api/v1/chat-box/getAllParticipants?chatBoxId=${chatId}`
            );
    
            setCurrentParticipants(response.data.members)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllParticipants()
    }, [])


    const jwtUser = props.userData.token

    
    return (
        <>
            {
                !isDesktop &&
                <Row>
                    <Col>
                    <TopHeading SmallHeading="" back_modal={false}/>
                    </Col>
                </Row>
            }
            <div className="inner-flex rghtcht-sec my-3">
                <div className="chat-detail position-relative">
                    {/* HEADER CHAT */}
                    <div className="chat-header d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="chathead-user">
                                <h6 className="fs-20 fw-bold text-capitalize text-light">{chat.name}</h6>
                                <div style={{cursor:"pointer"}} onClick={() => setShowPart(!showPart)}>
                                    <p className="fs-12">
                                        <span className="text-capitalize">Io{chat.members?.length > 1 && ","}</span>
                                        {
                                            chat.members?.length > 0 &&
                                            chat.members.map((item, index) => {
                                                return index > 1 ?
                                                <span className="text-capitalize"> {item.name}{item.surname}{chat.members[index+1] ? ',' : ''}</span>  : 
                                                ""
                                            }) 
                                        
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex align-items-center" style={{cursor:"pointer"}} >
                                <div className="me-2" onClick={() => setShowSearch(!showSearch)}>
                                    <i class="bi bi-search text-light"></i>                
                                </div>

                                <div
                                    className="elipsv-icon chthdr-elipse dropdown"
                                    onClick={() => setheaderpopup(!headerpopup)}
                                >
                                    <span
                                        className="elipsv-svg dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                    >
                                        <i class="bi bi-three-dots-vertical"></i>
                                    </span>
                                    {headerpopup == true && (
                                        <div className="dropdown-menu drpdown-chatdesrp" ref={dropDownRefMenu}>
                                            <ul>
                                                <li className="dropdown-item">
                                                    <a onClick={() => setShow(!show)}>Info attività</a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a
                                                        href="#"
                                                        className="cntntitvt-ppbtn"
                                                        onClick={() => setShowCa(!showCa)}
                                                    >
                                                        Contenuti attività
                                                    </a>
                                                </li>

                                                <li className="dropdown-item">
                                                    <a href="#">
                                                    <a onClick={() => setShowRc(!showRc)}>RiassuntoContabilità</a>
                                                    </a>
                                                </li>
                                                <li className="dropdown-item">
                                                    <a onClick={() => handleOpenArchive()}>Archivia Chat</a>
                                                    
                                                </li>
                                                {/* SE CI SONO ALMENO 2 PARTECIPANTI - ABBANDONA LA CHAT */}

                                               { currentParticipants.length >= 2 && <li className="dropdown-item">
                                                    <a onClick={() => handleOpenAbbandon()}>Abbandona Chat</a>
                                                </li>}
                                                {/* SE C'E' SOLO UN PARTECIPANTE - OVVERO L'UNICO UTENTE - ELIMINA LA CHAT */}
                                               { currentParticipants.length == 1 && <li className="dropdown-item">
                                                    <a onClick={(e) => handleOpenDelete(e)}>Elimina Chat</a>
                                                </li>
                                                }
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>


                         {/* Mostra il componente di ricerca all'interno del Collapse */}
                        <Collapse in={showSearch} className="w-100">
                            <div>
                                <ChatSearch chat={chat} />
                            </div>
                        </Collapse>
                    </div>

                  
                    {/* CORPO CHAT CON TUTTI I MESSAGGI */}
                    <div className="chating-sec"
                    style={{overflowY: "scroll", position: "relative", paddingBottom:"5rem !important"}}>
                        {/* Gestione dei messaggi */}
                        {   chat?.chats?.length > 0 &&
                            chat.chats.map(item => {
                                return <ChatMsg
                                        key={item.id}
                                        stripePromise={props.stripePromise} 
                                        item={item}
                                        ref={(el) => (inputRef.current[item.id] = el)}
                                        getOne={getOne}
                                        chatId={chatId}
                                        handleEdit={handleEdit}
                                        handleSelect={handleSelect}
                                        selectMsg={selectMsg}
                                        isChecked={selectedIds.includes(item.id)}
                                        selectedIds={selectedIds}
                                        setSelectedIds={setSelectedIds}
                                        handleReplyMode={handleReplyMode}
                                        chat={chat}
                                        showFirma={showFirma}
                                        handleOpenFirma={handleOpenFirma}
                                        setShowFirma={setShowFirma}
                                        handleCloseFirma={handleCloseFirma}
                                        getMsg={getMsg}
                                        chatList={props.chatList}

                                        sendName={props.sendName} greetings={props.greetings}
                                        />
                        /*     {elimina == true && (
                                <DeletePopUp
                                    elimina={elimina}
                                    setelimina={setelimina}
                                />
                            )} */
                            })
                        }
                    <WebSocketProvider>
                        <WebSocketComponent chatId={chatId} userData={props.userData} />
                    </WebSocketProvider>
                    </div>

                    {/* FOOTER CHAT */}
                    <div className="chat-footer"
                        style={{ marginTop: chat.chats?.length >= 3 ? "" : "50%"}} >
                            <div className="chatftr-main">
                                <div className="atachment-icon dropdown">
                                    <span
                                        className="dropdown-toggle"
                                        type="button"
                                        data-toggle="dropdown"
                                        onClick={chatfooterfun}
                                    >
                                        <svg
                                            width="44"
                                            height="44"
                                            viewBox="0 0 44 44"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                className="succes-atchmnt"
                                                d="M0 22C0 9.8497 9.8497 0 22 0C34.1503 0 44 9.8497 44 22C44 34.1503 34.1503 44 22 44C9.8497 44 0 34.1503 0 22Z"
                                                fill="#606F88"
                                            />
                                            <path
                                                d="M0.5 22C0.5 10.1259 10.1259 0.5 22 0.5C33.8741 0.5 43.5 10.1259 43.5 22C43.5 33.8741 33.8741 43.5 22 43.5C10.1259 43.5 0.5 33.8741 0.5 22Z"
                                                stroke="white"
                                            />
                                            <path
                                                d="M29.499 22.9375H14.499C13.9865 22.9375 13.5615 22.5125 13.5615 22C13.5615 21.4875 13.9865 21.0625 14.499 21.0625H29.499C30.0115 21.0625 30.4365 21.4875 30.4365 22C30.4365 22.5125 30.0115 22.9375 29.499 22.9375Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M21.999 30.4375C21.4865 30.4375 21.0615 30.0125 21.0615 29.5V14.5C21.0615 13.9875 21.4865 13.5625 21.999 13.5625C22.5115 13.5625 22.9365 13.9875 22.9365 14.5V29.5C22.9365 30.0125 22.5115 30.4375 21.999 30.4375Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </span>
                                    {/* ADD OPTIONS CHAT */}
                                    {chatfooter == true && (
                                        <div className="atchment-drpdown" ref={dropDownRefChat}>
                                            <ul>
                                                    <ButtonRm  
                                                    size='small' 
                                                    variant='text' 
                                                    disabled={(checkUserRole()) ? false: true}>
                                                        <span className="position-relative cntntitvt-ppbtn">
                                                            <i class="bi bi-file-earmark-text me-2"></i>
                                                            Documenti
                                                            <input type="file" accept="application/pdf" style={{position: "absolute",height:"50px"}}/>
                                                        </span>
                                                    </ButtonRm>
                                                
                                                    <ButtonRm  
                                                    size='small' 
                                                    variant='text' 
                                                    onClick={ () => setShowFirma(!showFirma)  }
                                                    disabled={(checkUserRole()) ? false: true}>
                                                        <i class="bi bi-pen me-2"></i>
                                                        Firma
                                                    </ButtonRm>
                                        
                                                    <ButtonRm  
                                                    size='small' 
                                                    variant='text' 
                                                    onClick={() => setShowChecklist(!showChecklist)}
                                                    disabled={(checkUserRole()) ? false: true}>
                                                        <i class="bi bi-list-check me-2"></i>
                                                        Checklist e Verbali
                                                    </ButtonRm>

                                                    <ButtonRm  
                                                    size='small' 
                                                    variant='text' 
                                                    onClick={() => setShowEvento(!showEvento)}
                                                    disabled={(checkUserRole()) ? false: true}>
                                                        <i class="bi bi-calendar-check me-2"></i>
                                                        Evento
                                                    </ButtonRm>

                                                    <ButtonRm  
                                                    size='small' 
                                                    variant='text' 
                                                    onClick={() => setShowContabilita(!showContabilita)}
                                                    disabled={((checkUserRole()) || (!checkUserRole() && userData?.stripeUser)) ? false: true}>
                                                        <i class="bi bi-currency-euro me-2"></i>
                                                        Contabilità
                                                    </ButtonRm>

                                                    <span>
                                                        <ButtonRm 
                                                        size='small' 
                                                        variant='text' 
                                                        onClick={() => setShowPayment(!showPayment)} 
                                                        disabled={(userData.state == "APPROVED" && userData?.stripeUser) ? false : true}> 
                                                            <i class="bi bi-cash me-2"></i> 
                                                            Pagamento 
                                                        </ButtonRm>
                                                        {(userData.state == "APPROVED" && userData?.stripeUser) ?
                                                            "" : 
                                                            <CustomTooltipGeneral /> 
                                                        }
                                                    </span>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                
                                
                                <div className={`chat-typinput ${recorderControls.isRecording ? "border" : "none" }`}>
                                    {/* <input
                                        type="text"
                                        placeholder="Scrivi il messaggio"
                                        className={`${recorderControls.isRecording ? "d-none" : "d-block" }`}
                                    /> */}
                                    <textarea className={`${recorderControls.isRecording ? "d-none" : "d-block" } chat-textarea`} value={chatValue}
                                    onChange={handleChatValue}/> 

                                    <div className={`chatfiles-uplodr`} onClick={handleImageUpload} >
                                        <div className={`uplodr-flx`}>
                                            <div
                                            className={`chatimg-upldr ${recorderControls.isRecording ? "d-none" : "d-block" }`}>
                                                    <li className="dropdown-item">
                                                    <a
                                                        className="cntntitvt-ppbtn"
                                                    >
                                                        <span
                                                            className="drpdn-iocn" 
                                                        >
                                                            <i class="bi bi-image text-dark"></i>
                                                        </span>{" "}
                                                        <input type="file" accept="image/*" style={{position: "absolute",height:"50px"}}/>

                                                    </a>
                                                </li>
                                            </div>
                                            <div className="chat-mic">
                                                <AudioRecorder onRecordingComplete={addAudioElement}     recorderControls={recorderControls}/>
                                            </div>
                                            <div className="msgsend-svgbtn"
                                            onClick={recorderControls.isRecording && customRecord}>
                                                <i class="bi bi-mic-fill"></i>
                                            </div>
                                        </div>
                                    </div>


                                    {replyMode && (
                                    <div className="respondi-msgbox">
                                        <div className="repondimsg-card">
                                            <div className="respondi-detail">
                                                <h6 className="mb-2">RISPOSTA A {replyMsg?.userID}</h6>
                                                <p>{replyMsg?.message}</p>
                                                <div className="close-respns">
                                                    <span onClick={()=> handleReplyMode(null)}>
                                                        
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                                <div className="sendmain-btn">
                                    <button className="btn primary-btn" /* onClick={recorderControls.isRecording ? customRecord : handleSubmit} */  onClick={() => props.sendName()}> 
                                        {editMsg ? "Modifica" : "Invia"}
                                    </button>
                                </div>
                            </div>

                    </div>

                    {selectMsg && (
                        <Alert className="alert-options chat-alert" style={{background:"#606F88", height:"fit-content"}} onClose={deselect} dismissible>
                            <Alert.Link className="me-2" href="#" >
                                {
                                    <ExportButton data={selectedItems[0]} mapping={translationMapping} isButton={false} classes="text-white"/>
                                }
                            </Alert.Link>
                        </Alert>
                    )}
                </div>
            </div>
            {/* MODALE INFO ATTIVITA' */}
            <InfoAttività show={show} handleClose={handleClose} chat={chat} setChatList={props.setChatList} setChat={setChat} userData={userData}/>
            {/* MODALE CHECKLIST */}
            <AddChecklist showChecklist={showChecklist} handleCloseChecklist={handleCloseChecklist} checklists={checklists} postItem={postItem} chatId={chatId} replyMode={replyMode} replyId={replyId}/>
            {/* MODALE CONTABILITA' */}
            <AddContabilità showContabilita={showContabilita} handleCloseContabilita={handleCloseContabilita} postItem={postItem} chatId={chatId} chat={chat} userData={userData}/>
            {/* MODALE EVENTO */}
            <AddEvento showEvento={showEvento} handleCloseEvento={handleCloseEvento} postItem={postItem} chatId={chatId}/>
            {/* MODALE RIASSUNTO CONTABILITA' */}
            <RiassuntoContabilita showRc={showRc} handleCloseRc={handleCloseRc} chat={chat} onEdit={editItem} userData={userData}/>
            {/* MODALE PARTECIPANTI' */}
            <Participants show={showPart} handleClose={handleClosePart} getAllList={props.getAll} chatId={chatId} chatList={props.chatList} />
            {/* MODALE FIRMA */}
            <AggiungiPopup show={showFirma} handleClose={handleCloseFirma} setEsempioFirma={handleEsempioFirma} firmaMsg={firmaMsg} getOne={getOne} chatId={chatId} handleSubmit={handleSubmit} userData={userData}/>
            {/* MODALE CONTENUTO ATTIVITA' */}
            <Contenutipopup showCa={showCa} handleCloseCa={handleCloseCa} chats={chat.chats}/>
            {/* MODALE PAGAMENTO */}
            <PaymentModal showPayment={showPayment} handleClosePayment={handleClosePayment} postItem={postItem} chatId={chatId}/>
            <AbbandonChat handleOpenAbbandon={handleOpenAbbandon} handleCloseAbbandon={handleCloseAbbandon} showAbbandon={showAbbandon} chatId={chatId}/>
            <ArchiveChat handleOpenArchive={handleOpenArchive} handleCloseArchive={handleCloseArchive} showArchive={showArchive} chatId={chatId}/>
            <DeleteChat handleOpenDelete={handleOpenDelete} handleCloseDelete={handleCloseDelete} showDelete={showDelete} chatId={chatId} setChatList={props.setChatList} />

            
        </>
    );
};


function InfoAttività(props){

    const navigate = useNavigate()
    
    //MODAL: NEW GESTIONE
    const create_date = props.chat.cdat?.substr(0, 10).split("-").join("-")


    //MANCA DESCRIZIONE
    const {id, name } = props.chat
    
    function handleChatData(e){
        
        const {name, value} = e.target

        props.setChat(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    //GET
    async function getAll() {
        try {
            const response = await api().get(`${chat_list_endpoint}`);
            if(response.data) {
                props.setChatList(response.data?.content)
            }
        }
        catch(error) {
                console.log(error)
        }
    }

    //DELETE
    async function deleteItem() {
        try {
            const response = await api().delete(`${chat_list_endpoint}${id}`);
            props.handleClose()
            await getAll();
        }
        catch(error) {
                console.log(error)
        }
    }

    //PUT
    async function updateItem() {
        try {
            const response = await api().put(`${chat_list_endpoint}${id}`, {name});
            props.handleClose()
            await getAll();
        }
        catch(error) {
                console.log(error)
        }
    }

    async function handleDelete(){
        deleteItem()
        navigate("/statoavanzamentolavori/chat")
    }



    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.show} onHide={props.handleClose} className="p-5 squadreModal" centered >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Info Attività</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-4 pb-3 px-md-5">
                    <Form>
                        <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                            <Form.Label>Nome chat</Form.Label>
                                <input
                                type="text"
                                style={{ height: "50px",  
                                borderRadius: "6px",
                                border: "1px solid #BDBDBD",
                                padding: "0 .5rem",
                                }}
                                onChange={handleChatData}
                                name="name"
                                value={name}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                            <Form.Label>Descrizione</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    style={{ height: "50px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem",
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                            <Form.Label>Creato da</Form.Label>
                                <input
                                type="text"
                                style={{ height: "50px",  
                                borderRadius: "6px",
                                border: "1px solid #BDBDBD",
                                padding: "0 .5rem",
                                }}
                                value={`${props.chat.members?.[0]?.name} ${props.chat.members?.[0]?.surname}`}
                                readOnly
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                            <Form.Label>Creato il</Form.Label>
                                <input
                                type="text"
                                style={{ height: "50px",  
                                borderRadius: "6px",
                                border: "1px solid #BDBDBD",
                                padding: "0 .5rem",
                                }}
                                value={create_date}
                                readOnly
                                disabled
                            />
                        </Form.Group>
                        {/* {((props.userData?.id == props.chat?.userID) || checkUserRole()) && <div className="d-flex align-items-center">
                            <i className="bi bi-trash me-2"></i>
                            <a className="text-decoration-underline text-dark" onClick={handleDelete}>Elimina Chat</a>
                        </div>} */}
                    </Form>
                </Modal.Body>
                <Modal.Footer
                    className="d-flex flex-column  px-md-5 pb-md-4"
                    style={{ borderTop: "none" }}
                >
                    <ButtonRm variant="primary" onClick={updateItem}>
                    Salva
                    </ButtonRm>
                    <ButtonRm variant="text" onClick={props.handleClose}>
                    Annulla
                    </ButtonRm>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function AddChecklist1(props){

    const [listView, setListView] = useState(true)

    const [array, setArray] = useState([])

    const [selectedArr, setSelectedArr] = useState({})

    const [form, setForm] = useState({})

    //PER OGNI ID TROVA IL CORRISPONDENTE LABEL

    function getLabelValue(){
        const properties = Object.getOwnPropertyNames(form)
        let obj = {}

        properties?.forEach(item => {
            //find the related value
            let value = selectedArr?.content?.find(el => el.id == item)
            obj[value?.name] = form[item]
        })

        if (multiselect.length > 0) {
            const multiselectName = selectedArr?.content?.find((el) => el.type === 'multiselect')?.name;
            obj[multiselectName] = multiselect.map(option => option.value);
          }
        

        return obj
    }
    

    function handleList(){
        setListView(!listView)
        setSelectedArr({})
    }

    function handleSelectedArr(id){
        setListView(!listView)
        const selectedItem = array.find(item => item.id == id)
        setSelectedArr(selectedItem)
    }

     /* API CALL */

    //GET
    async function getAll(){
        try {
        const response = await api().get(`${verbal_endpoint}`);
            if (response.data.content) {
                const parsedData = response.data.content.map((item) => {
                    return {
                        ...item,
                        content: item.content
                        ? JSON.parse(item.content.replace(/'/g, '"'))
                        : ""
                    };
                });
                setArray(parsedData)
            }
        } catch (error) {
        console.log(error);
        }
    }


    //GESTISCI ONCHANGE E VALUE DEI FORM
    function handleValues(){
        let arr = {}
        selectedArr.content.forEach((item) => {
            if(item.type != "multiselect")
            arr[item.id] = ""
        })
        setForm(arr)
    }

    const [multiselect, setMultiselect] = useState([])


    function handleChange(e, id = null) {
        if (e.target && e.target.type !== "multiselect") {
        const { name, value, type, checked } = e.target;
        setForm((prevFormData) => {
            return {
            ...prevFormData,
            [name]: type === "checkbox" ? checked : value,
            };
        });
        } 
    }

    useEffect(() => {
        getAll()
    }, [])


    useEffect(() => {
        if(selectedArr.name){     
            handleValues()
        }
    }, [selectedArr])

    useEffect(() => {
        const isEmpty = Object.keys(form).length === 0;

        if(!isEmpty){     
            getLabelValue()
        }
    }, [form])

     /* PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE */
    const overrideString = { 
        "selectAll": "Seleziona tutti",
        "search": "Cerca",
        "selectSomeItems": "Seleziona",
        "allItemsAreSelected": "Tutti gli elementi sono selezionati"
    }

    const bodyForCreate = {
        message: "",
        senderEmail: "fakeEmail",
        replyID: props.replyMode? props.replyId : -1,
        attachments: [],
        tag: [],
        messageType: "verbale",
        content: getLabelValue(),
        deleted: false,
        userID: 0,
        chatGroupID: props.chatId
    }

    function handleSubmit(e){
        e.preventDefault()
        let strdata;
        if (bodyForCreate.content) {
            strdata = {
            ...bodyForCreate,
            content: JSON.stringify(bodyForCreate.content).replace(/"/g, "'"),
            };
        props.postItem(strdata)
        props.handleCloseChecklist()
        setListView(true)
        }
    }


    const parseOptions = (optionsString) => {
        return optionsString.split(',').filter(option => option.trim() !== ''); // Rimuove eventuali stringhe vuote
    };
    
    
    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showChecklist} onHide={props.handleCloseChecklist} className="p-5" centered 
            style={{minHeight:"500px !important"}}>
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    {!listView && <div onClick={handleList}
                    style={{cursor:"pointer"}}><i className="bi bi-arrow-left"></i></div>}
                    <Modal.Title>Checklist/Verbale</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-4 pb-3 px-md-5 checklistModalBody"
                >
                    {   listView &&
                        <>
                        <div className="chatlst-srch mb-4">
                        <input type="search" placeholder="Cerca" />
                        <span className="srch-icon">
                            <i class="bi bi-search"></i>
                        </span>
                        </div>
                        <div>
                            {array.map(checklist => {
                                return <div className='checklist-card d-flex align-items-center justify-content-between'
                                onClick={ () => handleSelectedArr(checklist.id)}>
                                <p>{checklist.name}</p>
                            </div>
                            })}
                        </div>
                        </>
                    }
                    {   !listView &&
                        <>
                        <Form>
                    {
                        selectedArr?.content?.map(input => {
                            /* LOGICA PER OPZIONI X CAMPI LIBERI TYPE SELECT  */
                            /* PRENDO LA STRINGA VALUE DEL CAMPO OPZIONE, SPLITTO QUANDO TROVO UNA VIRGOLA E OTTENGO UN ARRAY CON LE DIVERSE OPZIONI */
                            let opzioniAnteprimaCampoLibero =  input.campoLibero && input.options?.length > 0? input.options[0].split(",") : ''
                            /* PER OGNI ELEMENTO DELL'ARRAY CREO UN OGGETTO, DA POTER MAPPARE PER CREARE LE OPZIONI DI QUEL CAMPO CHE SI VEDRA' IN VISUALIZZAZIONE ANTEPRIMA */
                            let arrayAnteprimaCampoLibero
                            if(opzioniAnteprimaCampoLibero.length > 1){
                                arrayAnteprimaCampoLibero = opzioniAnteprimaCampoLibero.map(item => {
                                    return {
                                        name: item
                                    }
                                })
                            }

                            /* MULTI SELECT */
                            let arrayAnteprimaCampoLiberoMulti
                            if(opzioniAnteprimaCampoLibero.length > 1){
                                arrayAnteprimaCampoLiberoMulti = opzioniAnteprimaCampoLibero.map(item => {
                                    return {
                                        value: item,
                                        label: item
                                    }
                                })
                            }
                                                        
                            return <Form.Group className="mb-3 d-flex flex-column" key={input.id}>
                            {
                                input.type != "checkbox" &&
                                <Form.Label className='text-capitalize'>{input.name}</Form.Label>
                            }
                            
                                { input.type == "select"  &&
                                    <Form.Select aria-label="seleziona"  
                                        name={input.id}
                                        onChange={handleChange}
                                        value={form[input.id]}
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                    }}>
                                        {
                                            input.campoLibero && arrayAnteprimaCampoLibero?
                                            arrayAnteprimaCampoLibero.map(
                                                option => <option value={option?.name}>{option?.name}</option>
                                            ) :
                                            input.options.map(
                                                option => <option value={option?.name}>{option?.name}</option>
                                            )
                                        }
                                    </Form.Select>
                                }

                            { input.type == "multiselect"  && arrayAnteprimaCampoLibero &&
                                    <MultiSelect
                                        options={arrayAnteprimaCampoLiberoMulti}
                                        labelledBy="Seleziona"
                                        overrideStrings={overrideString}
                                        value={multiselect }
                                        type={input.type}
                                        name={input.id}
                                        onChange={setMultiselect}
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                        }}
                                    />
                                }   
                            

                                {/* TROVARE UN MODO PER CONDENSARE QUESTA LOGICA */}
                                {   input.type == "text" &&
                                    <Form.Control
                                        type={input.type}
                                        name={input.id}
                                        onChange={handleChange}
                                        value={form[input.id]}
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                    }}
                                    />
                                }
                                {  input.type == "number" &&
                                    <Form.Control
                                    type={input.type}
                                    onChange={handleChange}
                                    value={form[input.id]}
                                    name={input.id}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type == "date" &&
                                    <Form.Control
                                    type={input.type}
                                    onChange={handleChange}
                                    value={form[input.id]}
                                    name={input.id}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type == "time" &&
                                    <Form.Control
                                    type={input.type}
                                    name={input.id}
                                    onChange={handleChange}
                                    value={form[input.id]}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {/* FINO A QUI */}

                                { input.type == "textarea" &&
                                    <Form.Control
                                    as="textarea"
                                    name={input.id}
                                    onChange={handleChange}
                                    value={form[input.id]}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {   input.type == "checkbox" &&
                                    <Form.Check
                                        onChange={handleChange}
                                        name={input.id}
                                        checked={form[input.id]}
                                        label={input.name}
                                        type={input.type}
                                    />
                                }
                                    
                        </Form.Group>
                        })
                    }
                        </Form>
                        <Modal.Footer
                            className="d-flex flex-column  px-md-5 pb-md-4"
                            style={{ borderTop: "none" }}
                        >
                            {/* ON CLICK, INVIA UNA POST PER UN checklist */}
                            <ButtonRm variant="primary" onClick={handleSubmit}>
                            Salva
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={props.handleCloseChecklist}>
                            Annulla
                            </ButtonRm>
                        </Modal.Footer>
                        </>
                    }
                    
                </Modal.Body>
                
            </Modal>
        </div>
    )
}


function AddChecklist(props) {
    const [listView, setListView] = useState(true);
    const [array, setArray] = useState([]);
    const [selectedArr, setSelectedArr] = useState({});
    const [form, setForm] = useState([]);
    const [multiselect, setMultiselect] = useState({});

    const handleMultiSelectChange = (fieldId, selectedOptions) => {
        // Aggiorna lo stato multiselect
        setMultiselect(prev => ({
            ...prev,
            [fieldId]: selectedOptions // Aggiorna solo le selezioni per il campo specifico
        }));
    
        // Aggiorna lo stato form con i nuovi valori selezionati per il campo multiselect specifico
        setForm(prevForm => prevForm.map(field => {
            if (field.id === fieldId) {
                return {
                    ...field,
                    value: selectedOptions.map(option => option.value) // Aggiorna il campo con i valori selezionati
                };
            }
            return field;
        }));
    };
    
    async function getAll(){
        try {
        const response = await api().get(`${verbal_endpoint}`);
            if (response.data.content) {
                const parsedData = response.data.content.map((item) => {
                    return {
                        ...item,
                        content: item.content
                        ? JSON.parse(item.content.replace(/'/g, '"'))
                        : ""
                    };
                });
                setArray(parsedData)
            }
        } catch (error) {
        console.log(error);
        }
    }



    useEffect(() => {
        getAll();
    }, []);

    function handleSelectedArr(id) {
        setListView(!listView);


        const initialFormState = array.find(item => item.id == id).content

        setForm(initialFormState);  
    }



    function handleChange(e, id) {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setForm(prevForm => prevForm.map(field => field.id === parseInt(name) ? { ...field, value: checked } : field));
        } else {
            setForm(prevForm => prevForm.map(field => field.id === parseInt(name) ? { ...field, value: value } : field));
        }
    }

    function getLabelValue() {
        return form.map(({ id, name, type, value, options, campoLibero }) => ({
            id,
            name,
            type,
            value,
            options,
            campoLibero
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        const content = getLabelValue();

        const bodyForCreate = {
            message: "",
            senderEmail: "fakeEmail",
            replyID: props.replyMode ? props.replyId : -1,
            attachments: [],
            tag: [],
            messageType: "verbale",
            content: JSON.stringify(content).replace(/"/g, "'"),
            deleted: false,
            userID: 0,
            chatGroupID: props.chatId
        };

        console.log('Submitting', bodyForCreate);
        props.postItem(bodyForCreate);
        props.handleCloseChecklist();
        setListView(true);
    }

    return (
        <div>
            <Modal show={props.showChecklist} onHide={props.handleCloseChecklist} className="p-5" centered style={{ minHeight: "500px !important" }}>
                <Modal.Header closeButton className="pt-md-4 px-md-5 modalCustomHeader">
                    {!listView && <div onClick={() => setListView(!listView)} style={{ cursor: "pointer" }}><i className="bi bi-arrow-left"></i></div>}
                    <Modal.Title>Checklist/Verbale</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-4 pb-3 px-md-5 checklistModalBody">
                    {listView ? (
                        <>
                            <div className="chatlst-srch mb-4">
                                <input type="search" placeholder="Cerca" />
                                <span className="srch-icon">
                                    <i className="bi bi-search"></i>
                                </span>
                            </div>
                            <div>
                                {array.map(checklist => (
                                    <div key={checklist.id} className='checklist-card d-flex align-items-center justify-content-between' onClick={() => handleSelectedArr(checklist.id)}>
                                        <p>{checklist.name}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            {form.length > 0 && form.map((input, index) => {
                                // Logica per opzioni x campi liberi di tipo select
                                let opzioniAnteprimaCampoLibero = input.campoLibero && input.options?.length > 0 ? input.options[0].split(",").map(option => option.trim()) : [];
                                let arrayAnteprimaCampoLibero = opzioniAnteprimaCampoLibero.map(item => ({ name: item }));
                                let arrayAnteprimaCampoLiberoMulti = opzioniAnteprimaCampoLibero.map(item => ({ value: item, label: item }));
                        
                                // Costruzione dei componenti Form
                                return <Form.Group className="mb-3 d-flex flex-column" key={index}>
                                    <Form.Label className='text-capitalize'>{input.name}</Form.Label>
                                    {input.type === "text" || input.type === "number" || input.type === "date" || input.type === "time" ? (
                                        <Form.Control
                                            type={input.type}
                                            name={String(input.id)}
                                            onChange={handleChange}
                                            value={input.value}
                                            style={{ height: "44px", borderRadius: "6px", border: "1px solid #BDBDBD", padding: "0 .5rem", width: '100%' }}
                                        />
                                    ) : input.type === "textarea" ? (
                                        <Form.Control
                                            as="textarea"
                                            name={String(input.id)}
                                            onChange={handleChange}
                                            value={input.value}
                                            style={{ height: "100px", borderRadius: "6px", border: "1px solid #BDBDBD", padding: ".5rem", width: '100%' }}
                                        />
                                    ) : input.type === "select" ? (
                                            <Form.Select
                                                name={String(input.id)}
                                                onChange={handleChange}
                                                value={input.value}
                                                style={{ height: "44px", borderRadius: "6px", border: "1px solid #BDBDBD", padding: "0 .5rem", width: '100%' }}
                                            >
                                                {input.campoLibero ? arrayAnteprimaCampoLibero.map((option, idx) => (
                                                    <option key={idx} value={option.name}>{option.name}</option>
                                                )) : input.options.map((option, idx) => (
                                                    <option key={idx} value={option.name}>{option.name}</option>
                                                ))}
                                            </Form.Select>
                                        )  : input.type === "multiselect" ? (
                                       
                                      
                                        <Form.Group key={index}>
                            <MultiSelect
                                            options={input.campoLibero ? arrayAnteprimaCampoLiberoMulti : input.options.map(option => ({ label: option.name, value: option.id.toString() }))}
                                            value={multiselect[input.id] || []} // Usa l'ID del campo per ottenere le selezioni
                                onChange={(selectedOptions) => handleMultiSelectChange(input.id, selectedOptions)}
                                labelledBy="Seleziona"
                            />
                        </Form.Group>
                                    ) : input.type === "checkbox" ? (
                                        <Form.Check
                                            type="checkbox"
                                            label={input.name}
                                            name={String(input.id)}
                                            checked={input.value}
                                            onChange={handleChange}
                                        />
                                    ) : null}
                                </Form.Group>
                            })}
                            <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{ borderTop: "none" }}>
                                <ButtonRm variant="primary" type="submit">Salva</ButtonRm>
                                <ButtonRm variant="secondary" onClick={props.handleCloseChecklist}>Annulla</ButtonRm>
                            </Modal.Footer>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}


function AddContabilità(props){

    const [listView, setListView] = useState(true)
    const [contType, setContType] = useState("")

    //handle tablerows and values
    const [tableRowN, setTableRow] = useState([])

    //GESTIONE SPESA

    const spesaData = {
        section: "",
        name: "",
        description: "",
        cost: "",
        vatCode: ""
    }

    const [spesa, setSpesa] = useState(spesaData)

    function handleSpesa(e){
        const {value, name} = e.target
        setSpesa(prev => {
            return {
                ...prev,
                [name]:value
            }
        })
    }



    function handleChange(e, sectionId, rowId){
        const {value, name} = e.target
        
        const newArr = tableRowN.map(el => {
            return el.id == sectionId ?
            {...el, rows: el.rows.map((item, i) => {
                console.log(sectionId, i, rowId, item, name, value)
                return i == rowId ?
                {...item, [name]: value }:
                item
            })} :
            el
        })

        setTableRow(newArr)
    }

    const [key, setKey] = useState('mezzi');

    function addRow(id = null){
        if(!id){
        const arr = sezioniCustom?.map(item => {
            let obj = {}
            const defaultTabs = tabSection.map(el => el.name)
            const customTabs = item.customFields
            const tabs = item.customFields?.length > 0 ? [...defaultTabs, ...customTabs] : defaultTabs
            tabs.forEach(el => obj[el] = "")
            return {id: item.id, name: item.name, rows: [obj]}})
        setTableRow(arr)
        } else {
            const newArr = tableRowN.map(item => {
                return item.id == id ?
                {...item, rows: [...item.rows, 
                    Object.keys(item.rows[0]).reduce((accumulator, value) => {
                    return {...accumulator, [value]: ''};
                    }, {}) 
                ]}:
                item
            })
            setTableRow(newArr)
        }
    }

    function deleteRow(sectionId, itemIndex){
        const newArr = tableRowN.map(item => {
                return item.id == sectionId ?
                {...item, rows: item.rows.filter((el, i) => i != itemIndex)}:
                item
            })
            setTableRow(newArr)
    }

    function handleList(){
        if(contType){
            setListView(!listView)
        }
    }
    
    function handleContType(type){
        if(type === "spesa"){
            setContType("spesa")
        } else if (type === "preventivo") {
            setContType("preventivo")
        }
    }

    function closeContModal(){
        props.handleCloseContabilita()
        setListView(true)
        setContType("")
    }

    const tabSection = [
        {id:1, name:"Nome", default: true},
        {id:2, name:"Unità di misura", default: true},
        {id:3, name:"Costo unitario", default: true},
        {id:4, name:"Quantità", default: true},
    ]

    //GETALL

    const [sezioniCustom, setSezioniCustom] = useState([{   
        id:"Materiali",
        name: 'Materiali',
        content: "", 
        customFields: [], 
        default: true
    },
    {
        id: "Persone",
        content: "", 
        customFields: [], 
        name: 'Persone',
        default: true
        
    },
    {
        id:"Mezzi",
        name: 'Mezzi',
        default: true,
        content: "", 
        customFields: [], 
    }   ])

    async function getAll(){
        try {
            const response = await api().get(`${accounting_endpoint}`);
            if(response.data.content)
            setSezioniCustom([...sezioniCustom, ...response.data.content])
        } catch (error) {
        console.log(error);
        }
    }

    useEffect(() => {
        getAll()
    }, [])    

    useEffect(() => {
        addRow()
    }, [sezioniCustom])

    const todayDate = new Date()
    const date = todayDate.toISOString().substring(0,16)

    const bodyForCreate = {
        message: "",
        senderEmail: "fakeEmail",
        replyID: props.replyMode? props.replyId : -1,
        attachments: [],
        tag: [],
        messageType: "preventivo",
        content: tableRowN,
        deleted: false,
        chatGroupID: props.chatId
    }

    const bodyForCreateSpesa = {
        message: "",
        senderEmail: "fakeEmail",
        replyID: props.replyMode? props.replyId : -1,
        attachments:[],
        tag: [],
        messageType: "spesa",
        content: [{name: spesa.section, rows:[{Nome: spesa.name,["Costo unitario"]:spesa.cost * spesa.vatCode, Quantità:1}], description: spesa.description}],
        deleted: false,
        chatGroupID: props.chatId
    }



    //DA AGGIUNGERE SOGGETTI E IMMOBILE PER PRIMA NOTA
    const bodyPrimaNota = {
        typology: "Movimento",
        account: "Spese Generali", 
        subAccount: "pagamento",
        operationDescription: spesa.name, 
        operationDate: todayDate, 
        isEnteringAmount: false, 
        taxable: spesa.cost, 
        vatCode: spesa.vatCode,
        amount: spesa.cost 
    }


    async function handleSubmitSpesa(e){
        e.preventDefault()
        let strdata;
        if (bodyForCreateSpesa.content) {
            strdata = {
            ...bodyForCreateSpesa,
            content: JSON.stringify(bodyForCreateSpesa.content).replace(/"/g, "'"),
            };
        //CREO MSG IN CHAT CON LA SPESA    
        props.postItem(strdata)
        //CREA PRIMA NOTA RELATIVA IN PRIMA NOTA
        let response = await api().post(
            `${first_note_endpoint}`,
            bodyPrimaNota
        );

        const unitID = props.chat.activities?.length > 0 ?
                    props.chat.activities[0]?.unitID : ""

        const condominiumID = props.chat.activities?.length > 0 ?
        props.chat.activities[0]?.condominiumID : ""

          // assign unit
        if (response.data && unitID) {
        api().post(`${note_unit_assignment_endpoint}`, {
                noteID: response.data.id,
                unitID: unitID
            })
        }

         // assign condominium
      if (response.data && unitID) {
        api().post(`${note_condominium_assignment_endpoint}`, {
          noteID: response.data.id,
          condominiumID: condominiumID
        });
      }

        closeContModal()
        setTableRow([])
        }
    }

    function handleSubmit(e){
        e.preventDefault()
        let strdata;
        if (bodyForCreate.content) {
            strdata = {
            ...bodyForCreate,
            content: JSON.stringify(bodyForCreate.content).replace(/"/g, "'"),
            };
        props.postItem(strdata)
        closeContModal()
        setTableRow([])
        }
    }

    
    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showContabilita} onHide={closeContModal} 
            className={`p-5 contabilitàModal ${contType === "preventivo" && !listView ? "preventivo" : ""} centered `}
            style={{minHeight:"500px !important"}}
            >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    {!listView && <div onClick={handleList}
                    style={{cursor:"pointer"}}><i className="bi bi-arrow-left"></i></div>}
                    <Modal.Title>
                        {listView && "Contabilità"}
                        {!listView && contType === "spesa" && "Nuova Spesa"}
                        {!listView && contType === "preventivo" && "Nuovo Preventivo"}
                    </Modal.Title>
                </Modal.Header>
                {!checkUserRole() ? 
                <Modal.Body className="pt-4 pb-3 px-md-5 contabilitàModalBody"
                >
                    {   listView &&
                        <>
                        <div className="mt-4 my-5">
                            <p>
                            Seleziona il tipo di contabilità da aggiungere.
                            Il Preventivo esso dovrà essere accettato e firmato prima di confluire in riassunto contabilità.
                            </p>
                        </div>

                        <Row className="d-flex gy-2 mt-3 mb-5">
                            <Col>
                                <div className={`card-contabilità-btn ${contType === "preventivo" ? "selected" : "" } text-center `}
                                style={{cursor:"pointer"}}
                                onClick={() => handleContType("preventivo")}>
                                    <i class="bi bi-receipt"></i>
                                    <p>Preventivo</p>
                                </div>
                            </Col>
                        </Row>
                        <Modal.Footer
                            className="d-flex flex-column  px-md-5 pb-md-4"
                            style={{ borderTop: "none" }}
                        >
                            <ButtonRm variant="primary" onClick={handleList} disabled={!contType}>
                            Continua
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={closeContModal}>
                            Annulla
                            </ButtonRm>
                        </Modal.Footer>
                        </>
                    }
                    
                    {   !listView  && contType === "preventivo" &&
                        <>
                            
                            <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="cont-tabs"
                            >
                                {
                                    sezioniCustom.length > 0 &&
                                    sezioniCustom.map((section, secIndex) => <Tab key={section.id} eventKey={section.name}title={section.name} >
                                    <Table >
                                    <thead>
                                        <tr>
                                            {
                                                tabSection.length > 0 &&
                                                tabSection.map(section => <th key={section.id}>
                                                    {section.name}</th>)
                                                
                                            }
                                            {
                                                section.customFields.length > 0 &&
                                                section.customFields.map((item,i ) => <th key={i}>
                                                    {item}</th>)
                                                
                                            }
                                            <th style={{border:"none"}}>
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableRowN.find(row => row.id == section.id).rows.map((el, rowIndex) => {
                                                return <tr>
                                                        {
                                                            Object.keys(el)?.map((item, inputIndex )=> {
                                                            return <td key={inputIndex} >
                                                            <input type="text" name={item} value={tableRowN?.[secIndex]?.rows?.[rowIndex]?.[item]}
                                                            onChange={(e) => handleChange(e, section.id, rowIndex)}
                                                            />
                                                            </td>}  )
                                                        }                            
                                                        
                                                        <td style={{border:"none"}}>
                                                            <i className="bi bi-plus-circle-fill fs-16"
                                                            onClick={() => addRow(section.id)}
                                                            style={{cursor: "pointer"}}
                                                            ></i>
                                                        </td>
                                                        {
                                                            tableRowN.find(row => row.id == section.id).rows.length > 1 &&
                                                            <td style={{border:"none"}}>
                                                                <i class="bi bi-trash"
                                                                onClick={() => deleteRow(section.id, rowIndex)}
                                                                style={{cursor: "pointer"}}></i>
                                                            </td>
                                                        }
                                                        
                                                    </tr>
                                                    })
                                        }
                                        
                                    </tbody>
                                </Table>
                                    </Tab>)
                                    }
                            </Tabs>
                            
                        </>
                    }
                    
                </Modal.Body>:
                <Modal.Body className="pt-4 pb-3 px-md-5 contabilitàModalBody"
                >
                    {   listView &&
                        <>
                        <div className="mt-4 my-5">
                            <p>
                            Seleziona il tipo di contabilità da aggiungere.
                            Se Preventivo esso dovrà essere accettato e firmato prima di confluire in riassunto contabilità, mentre una spesa vi confluirà in maniera diretta.
                            {(checkUserRole() && (props.userData?.stripeUser == null)) &&  "Per abilitare i preventivi devi prima creare un account di pagamento (Impostazioni > Profilo)"}
                        
                            
                            </p>
                        </div>

                        <Row className="d-flex gy-2 mt-3 mb-5">
                            <Col>
                            <div className={`card-contabilità-btn ${contType === "spesa" ? "selected" : "" } text-center `}
                            style={{cursor:"pointer"}}
                            onClick={() => handleContType("spesa")}>
                                <i class="bi bi-wallet2"></i>
                                <p>Spesa</p>
                            </div>
                                
                            </Col>
                            <Col>
                                <button 
                                style={{cursor:"pointer", outline: "none", backgroundColor: "transparent", border: "none"}}
                                className={`card-contabilità-btn w-100 ${contType === "preventivo" ? "selected" : "" } text-center `} disabled={ props.userData?.stripeUser ? false : true } 
                                onClick={() => handleContType("preventivo")}
                                >
                                    <i class="bi bi-receipt"></i>
                                    <p>Preventivo</p>
                                </button>
                            </Col>
                        </Row>
                        <Modal.Footer
                            className="d-flex flex-column  px-md-5 pb-md-4"
                            style={{ borderTop: "none" }}
                        >
                            <ButtonRm variant="primary" onClick={handleList} disabled={!contType}>
                            Continua
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={closeContModal}>
                            Annulla
                            </ButtonRm>
                        </Modal.Footer>
                        </>
                    }
                    {   !listView  && contType === "spesa" &&
                        <>
                            <Form>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Sezione di riferimento*</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleSpesa} name="section" value={spesa.section}>
                                        <option>Seleziona</option>
                                        <option value="spese generali">Spese Generali</option>
                                        <option value="mezzi">Mezzi</option>
                                        <option value="persone">Persone</option>
                                        <option value="materiali">Materiali</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-2" >
                                    <Form.Label>Nome*</Form.Label>
                                    <Form.Control type="text" onChange={handleSpesa} name="name" value={spesa.name}/>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1" >
                                    <Form.Label>Descizione</Form.Label>
                                    <Form.Control as="textarea" onChange={handleSpesa} name="description" value={spesa.description}/>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Costo*</Form.Label>
                                    <Form.Control onChange={handleSpesa} name="cost" value={spesa.cost} type="number"/>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>IVA*</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleSpesa} name="vatCode" value={spesa.vatCode}>
                                        <option value="">Seleziona</option>
                                        <option value={1}> Esente </option>
                                        <option value={1.04}> 4% </option>
                                        <option value={1.05}> 5% </option>
                                        <option value={1.1}> 10% </option>
                                        <option value={1.2}> 20% </option>
                                        <option value={1.21}> 21% </option>
                                        <option value={1.22}> 22% </option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                            <Modal.Footer
                                className="d-flex flex-column  px-md-5 pb-md-4"
                                style={{ borderTop: "none" }}
                            >
                                <ButtonRm variant="primary" onClick={(e) => handleSubmitSpesa(e)}
                                disabled={(spesa.name && spesa.section && spesa.cost && spesa.vatCode) ? false: true}>
                                Salva
                                </ButtonRm>
                                <ButtonRm variant="text" onClick={closeContModal}>
                                Annulla
                                </ButtonRm>
                            </Modal.Footer>
                        </>
                    }
                    {   !listView  && contType === "preventivo" &&
                        <>
                            
                            <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="cont-tabs"
                            >
                                {
                                    sezioniCustom.length > 0 &&
                                    sezioniCustom.map((section, secIndex) => <Tab key={section.id} eventKey={section.name}title={section.name} >
                                    <Table >
                                    <thead>
                                        <tr>
                                            {
                                                tabSection.length > 0 &&
                                                tabSection.map(section => <th key={section.id}>
                                                    {section.name}</th>)
                                                
                                            }
                                            {
                                                section.customFields.length > 0 &&
                                                section.customFields.map((item,i ) => <th key={i}>
                                                    {item}</th>)
                                                
                                            }
                                            <th style={{border:"none"}}>
                                                
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableRowN.find(row => row.id == section.id).rows.map((el, rowIndex) => {
                                                return <tr>
                                                        {
                                                            Object.keys(el)?.map((item, inputIndex )=> {
                                                            return <td key={inputIndex} >
                                                            <input type="text" name={item} value={tableRowN?.[secIndex]?.rows?.[rowIndex]?.[item]}
                                                            onChange={(e) => handleChange(e, section.id, rowIndex)}
                                                            />
                                                            </td>}  )
                                                        }                            
                                                        
                                                        <td style={{border:"none"}}>
                                                            <i className="bi bi-plus-circle-fill fs-16"
                                                            onClick={() => addRow(section.id)}
                                                            style={{cursor: "pointer"}}
                                                            ></i>
                                                        </td>
                                                        {
                                                            tableRowN.find(row => row.id == section.id).rows.length > 1 &&
                                                            <td style={{border:"none"}}>
                                                                <i class="bi bi-trash"
                                                                onClick={() => deleteRow(section.id, rowIndex)}
                                                                style={{cursor: "pointer"}}></i>
                                                            </td>
                                                        }
                                                        
                                                    </tr>
                                                    })
                                        }
                                        
                                    </tbody>
                                </Table>
                                    </Tab>)
                                    }
                            </Tabs>
                            
                        </>
                    }
                    
                </Modal.Body>
                }
                { !listView  && contType === "preventivo" &&
                    <Modal.Footer
                    className="d-flex px-md-5 pb-md-4"
                    style={{ borderTop: "none" }}
                >
                    <ButtonRm variant="tertiary" onClick={closeContModal}>
                    Annulla
                    </ButtonRm>
                    <ButtonRm variant="primary" onClick={(e) => handleSubmit(e)}>
                    Salva
                    </ButtonRm>
                </Modal.Footer>
                }
                
            </Modal>
        </div>
    )
}

function AddEvento(props){
    


    const todayDate = new Date()
    const date = todayDate.toISOString().substring(0,16)

    const eventData = {
        title: "",
        section: "",
        startDate: date,
        endDate:	date,
        every:	"",
        recurrenceType:	"",
        toDate:	undefined,
        notification: "",
        sharing: "",
        note: ""
        //manca campo per giorni di preavviso => type: number
    }

    const [formData, setFormData] = useState(eventData)

    //RICORRENZA
    function createRecurringEvent(title, startDate, endDate, frequency, frequencyUnit, endRecurrenceDate) {
        const recurrenceIntervals = {
            "giorno/i": addDays,
            "settimana/e": addWeeks,
            "mese/i": addMonths,
            "anno/i": addYears,
        };
        const recurrenceInterval = recurrenceIntervals[frequencyUnit];
        
        const events = [];
        let nextStartDate = recurrenceInterval(parseISO(startDate), frequency);
        let nextEndDate = recurrenceInterval(parseISO(endDate), frequency);
        
        let recurrenceEndDate = parseISO(endRecurrenceDate);
    
        while (isAfter(recurrenceEndDate, nextStartDate)) {
            events.push({ title, startDate: nextStartDate, endDate: nextEndDate });
    
            nextStartDate = recurrenceInterval(nextStartDate, frequency);
            nextEndDate = recurrenceInterval(nextEndDate, frequency);
    
            if (isAfter(nextEndDate, endRecurrenceDate)) {
            nextEndDate = endRecurrenceDate;
            recurrenceEndDate = nextStartDate;
            }
        }
    
        return events;
        }

    useEffect( () => {

        if(formData.allDay && formData.startDate && formData.endDate){
        let originalStart = formData.startDate.substring(11)
        let originalEnd = formData.endDate.substring(11)

        setFormData(prev => {
            return {
                ...prev,
                startDate: formData.startDate.replace(originalStart, "00:00"),
                endDate: formData.endDate.replace(originalEnd, "23:59")
            }
        })

        }

    }, [formData.allDay])


    const emails = formData.share?.split(',')

      //CREATION
      const dispatch = useDispatch();

      function postItem(data) {
          dispatch(addDeadline(data))
      }

    //handle whole form
    function handleFormChange(e) {
        const {name, value, type, checked} = e.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

     //VALIDAZIONE INPUT
     const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const bodyForCreate = {
        message: "",
        senderEmail: "fakeEmail",
        replyID: props.replyMode? props.replyId : -1,
        attachments: [],
        tag: [],
        messageType: "event",
        content: formData,
        deleted: false,
        chatGroupID: props.chatId
    }

    const onSubmit = (data) => {
/*             let str = {
            ...formData,
            startDate: convertDataForApi(formData.startDate) ,
            endDate:convertDataForApi(formData.endDate),
            toDate: formData.toDate ? convertDataForApi(formData.toDate): formData.toDate
        } */
        // controlla se ci sono errori di validazione
        if (Object.keys(errors).length > 0) {
            console.log("Ci sono errori di validazione", errors);
            return;
        } else {
        // azione da eseguire quando i dati sono tutti corretti
        console.log("corretti", {data})
        
        //CREA MSG
        let strdata;
        if (bodyForCreate.content) {
            strdata = {
            ...bodyForCreate,
            content: JSON.stringify(bodyForCreate.content).replace(/"/g, "'"),
            };
        props.postItem(strdata)
        }

        //CREA EVENTO IN ALERT
        if(formData.every && formData.recurrenceType && formData.toDate){
            let dateArray = createRecurringEvent(formData.title, formData.startDate, formData.endDate, formData.every, formData.recurrenceType, formData.toDate)

             dateArray.forEach((date, index) => {
                const dataObj = {
                    title:`${date.title} ${index + 1}`,
                    section: formData.section? formData.section : "",
                    isRecurrent: true,
                    startDate: new Date(date.startDate).toISOString().slice(0,10),
                    endDate: new Date(date.endDate).toISOString().slice(0,10) ,

                }
                postItem(dataObj)
            }) 

        } 
            postItem(formData)
        

        setFormData(eventData)
        props.handleCloseEvento()
        }

        
    };

    //HANDLE ACCORDION
    const [activeKey, setActiveKey] = useState("")

    
    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showEvento} onHide={props.handleCloseEvento} className="p-5" centered 
            style={{minHeight:"500px !important"}}>
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body className="eventModalBody">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                    
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
                            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="0" activeKey={activeKey}>Dati <span className='mandatory-field'>*</span></CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className="d-md-flex flex-column">   
                                    
                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Titolo  <span className='mandatory-field'>*</span></Form.Label>
                                        <Form.Control
                                        {...register("title")}
                                        isInvalid={!!errors.title}
                                        type="text" placeholder="Inserisci un titolo" 
                                        name="title" 
                                        value={formData.title} 
                                        onChange={handleFormChange}
                        
                                        />
                                        
                                    </Form.Group>

                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Sezione di riferimento</Form.Label>
                                        <Form.Select aria-label="Default select example" 
                                            {...register("section")}
                                            isInvalid={!!errors.section}
                                            name="section"
                                            value={formData.section} 
                                            onChange={handleFormChange} 
                                        >
                                            <option>
                                                Seleziona
                                            </option>
                                            <option value="Unità e Stabili">Unità e Stabili</option>
                                            <option value="Affitti">Affitti</option>
                                            <option value="SAL">SAL</option>
                                            <option value="Scadenze Personali">Scadenze Personali</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.section?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="1" activeKey={activeKey}>Date e Durata  <span className='mandatory-field'>*</span></CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className=" ">  

                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Data di Inizio  <span className='mandatory-field'>*</span></Form.Label>
                                        <Form.Control 
                                        {...register("startDate")}
                                        isInvalid={!!errors.startDate}
                                        type="datetime-local" 
                                        name="startDate" 
                                        value={formData.startDate} 
                                        onChange={handleFormChange} 
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.startDate?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Data di Fine  <span className='mandatory-field'>*</span></Form.Label>
                                        <Form.Control 
                                        {...register("endDate")}
                                        isInvalid={!!errors.endDate}
                                        type="datetime-local"   
                                        name="endDate" 
                                        value={formData.endDate} 
                                        onChange={handleFormChange} 
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.endDate?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                           {/*  <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="2" activeKey={activeKey}>Ricorrenza <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>Si tratta di un evento ricorrente? Anziché creare più volte lo stesso evento, puoi impostare di seguito i dettagli di ricorrenza di questo. <br/> Se ad esempio si tratta di un evento che si svolge ogni 2 mesi fino al 31/12/2023, dovrai selezionare: <br/>
                                Frequenza:  "Mesi", <br/>
                                Ogni: 2, <br/>
                                Fine Ricorrenza: "31/12/2023"
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-2"></i>
                                </OverlayTrigger>
                                </CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row>
                                </Row>
                                <Row className="">   
                                <Form.Group className="mb-3 ">
                                                <Form.Label className="input-label">Frequenza</Form.Label>
                                                <Form.Select 
                                                {...register("recurrenceType")}
                                                isInvalid={!!errors.recurrenceType}name="recurrenceType"
                                                value={formData.recurrenceType} 
                                                onChange={handleFormChange} 
                                                >
                                                    
                                                    <option>
                                                    
                                                    </option>
                                                    <option value="giorno/i">Giorno/i</option>
                                                    <option value="settimana/e">Settimana/e</option>
                                                    <option value="mese/i">Mese/i</option>
                                                    <option value="anni/i">Anno/i</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" >
                                            {errors.recurrenceType?.message}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                                                        
                                            <Form.Group className="mb-3 input-size">
                                                <Form.Label className="input-label">Ogni</Form.Label>
                                                <Form.Control 
                                                {...register("every")}
                                                isInvalid={!!errors.every}
                                                type="number" placeholder="Inserisci un numero" name="every" 
                                                value={formData.every} 
                                                onChange={handleFormChange}
                                                />
                                                <Form.Control.Feedback type="invalid" >
                                                {errors.every?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3 ">
                                                <Form.Label className="input-label">Fine Ricorrenza</Form.Label>
                                                <Form.Control 
                                                {...register("toDate")}
                                                isInvalid={!!errors.toDate}type="datetime-local"   
                                                name="toDate" 
                                                value={formData.toDate} 
                                                onChange={handleFormChange}
                                                />
                                                <Form.Control.Feedback type="invalid" >
                                                {errors.toDate?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="3" activeKey={activeKey}>Notifiche</CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className=" "> 

                                <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label"> Quanti giorni prima di questo evento vuoi essere avvisato?</Form.Label>
                                        <Form.Control type="text" 
                                        //DATO MANCANTE IN API 

                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group className="mb-3 mb-sm-0 ">
                                    <Form.Label className="input-label">Dove vuoi ricevere la notifica?</Form.Label>
                                    <Form.Select
                                    {...register("notification")}
                                    isInvalid={!!errors.notification} 
                                    name="notification"
                                    value={formData.notification} 
                                    onChange={handleFormChange}
                                    >
                                        <option>
                                            
                                        </option>
                                        <option value="Gestionale">Notifica sul gestionale</option>
                                        <option value="Email">Notifica per Email</option>
                                        <option value="Entrambe">Entrambe</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                        {errors.notification?.message}
                                        </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="4" activeKey={activeKey}>Altro</CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className=" ">  

                                <Form.Group className="mb-3 mb-sm-0 ">
                                        <Form.Label className="input-label">Condividi</Form.Label>
                                        <Form.Control 
                                        {...register("sharing")}
                                        isInvalid={!!errors.sharing}
                                        type="text"  
                                        name="sharing" 
                                        value={formData.sharing} 
                                        onChange={handleFormChange}
                                        />
                                        <Form.Text>
                                        Inserisci le email dei partecipanti separati da virgola
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.sharing?.message}
                                        </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 ">
                                    <Form.Label className="input-label">Note</Form.Label>
                                    <Form.Control
                                        {...register("note")}
                                        isInvalid={!!errors.note} 
                                        as="textarea"
                                        name="note" 
                                        value={formData.note} 
                                        onChange={handleFormChange} 
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.note?.message}
                                        </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                                </Accordion.Body>
                            </Accordion.Item> */}

                        </Accordion>
                            <div className='d-flex justify-content-end my-3' >
                                <ButtonRm variant="tertiary" className="me-2" onClick={props.handleCloseEvento}>Annulla</ButtonRm>
                                <ButtonRm variant="primary" type="submit">Salva</ButtonRm>
                            </div>
                    </Form>
                </Modal.Body>
                
                
            </Modal>
        </div>
    )
}

function PaymentModal(props){
    

    const paymentData = {
        causale:"",
        deadlineDate:"",
        amount:""
    }

    const [formData, setFormData] = useState(paymentData)

     //handle whole form
     function handleFormChange(e) {
        const {name, value, type, checked} = e.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    const bodyForCreate = {
        message: "",
        senderEmail: "",
        replyID:  -1,
        attachments: [],
        tag: [],
        messageType: "paymentMsg",
        content: {
            causale: formData.causale,
            soggetto: "",
            scadenza: formData?.deadlineDate?.substring(0,10),
            prezzo: formData?.amount,
        },
        deleted: false,
        chatGroupID: props.chatId
    }



    const onSubmit = () => {
            //CREA MSG
            let strdata;
            if (bodyForCreate.content) {
                strdata = {
                ...bodyForCreate,
                content: JSON.stringify(bodyForCreate.content).replace(/"/g, "'"),
                };
            props.postItem(strdata)
            }

            props.handleClosePayment()
    };

//HANDLE ACCORDION
    
    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showPayment} onHide={props.handleClosePayment} className="p-5" centered 
            style={{minHeight:"500px !important"}}>
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Pagamento</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body className="eventModalBody">
                <p className="my-3">Compila i dati per farti pagare direttamente in chat</p>
                    <Form>

                        <Row className="d-md-flex flex-column">   
                                    
                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Causale del pagamento <span className='mandatory-field'>*</span></Form.Label>
                                        <Form.Control
                                        type="text" 
                                        name="causale" 
                                        value={formData.causale} 
                                        onChange={handleFormChange}
                                        />
                                        
                                    </Form.Group>

                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Data entro cui inviare il pagamento <span className='mandatory-field'>*</span></Form.Label>
                                        <Form.Control
                                        type="date" 
                                        name="deadlineDate" 
                                        value={formData.deadlineDate} 
                                        onChange={handleFormChange}
                                        />
                                        
                                    </Form.Group>

                                    <Form.Group className="mb-3 ">
                                        <Form.Label className="input-label">Importo <span className='mandatory-field'>*</span></Form.Label>
                                        <Form.Control
                                        type="number" 
                                        name="amount" 
                                        value={formData.amount} 
                                        onChange={handleFormChange}
                                        />
                                        
                                    </Form.Group>

                        </Row>

                                
                            <div className='d-flex justify-content-end my-3' >
                                <ButtonRm variant="tertiary" className="me-2" onClick={props.handleClosePayment}>Annulla</ButtonRm>
                                <ButtonRm variant="primary" onClick={onSubmit}>Salva</ButtonRm>
                            </div>
                    </Form>
                </Modal.Body>
                
                
            </Modal>
        </div>
    )
}

function ArchiveChat(props){
    const navigate = useNavigate()

    //ARCHIVE
    async function archiveItem() {
        try {
            const response = await api().post(`api/v1/chat-box/archive-chat-box`, {
                chatBoxId: props.chatId
            });
            navigate("/statoavanzamentolavori/chat")
            props.handleCloseArchive()

            
        }
        catch(error) {
                console.log(error)
        }
    }

    return (
        <Modal show={props.showArchive} onHide={(e) => props.handleCloseArchive(e)} centered>
            <Modal.Body className="p-5">
                <div className="d-flex flex-column text-center">
                <h2 className="fs-32 text-dark fw-semibold mb-3">
                    Vuoi archiviare la chat?
                </h2>
                <h6 className="fs-16 text-dark fw-normal mb-3">
                    Non riceverai più notifiche relative a questa conversazione.
                    Potrai ripristinare la chat dalla sezione <span onClick={() => navigate("/impostazioni?tab=Archivio")} className="fw-bold cursor-pointer">Impostazioni / Archivio / Chat</span>.
                </h6>
                <ButtonRm 
                    variant="primary"
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        archiveItem()
                    }}
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"
                    onClick={(e) => props.handleCloseArchive(e)}
                >
                    Annulla
                </ButtonRm >
                </div>
            </Modal.Body>
        </Modal>  
    )
  }

  function AbbandonChat(props){

    const navigate = useNavigate()

    //DELETE
    async function abbandonItem() {
        try {
            const response = await api().post(`api/v1/chat-box/abandon-chat-box`, {
                chatBoxId: props.chatId
            });
        }
        catch(error) {
                console.log(error)
        }
    }

    //DELETE
    async function handleAbbandon(){
        abbandonItem()
        navigate("/statoavanzamentolavori/chat")
    }


    return (
    <Modal show={props.showAbbandon} onHide={(e) => props.handleCloseAbbandon(e)} centered>
          <Modal.Body className="p-5">
            <div className="d-flex flex-column text-center">
              <h2 className="fs-32 text-dark fw-semibold mb-3">
                Vuoi abbandonare questa conversazione?
              </h2>
              <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa conversazione verrà rimossa dalla tua lista. Potrai farti invitare per rientrarci.
              </h6>
              <ButtonRm 
                variant="primary"
                onClick={(e) => { 
                  e.preventDefault()
                  e.stopPropagation()
                  handleAbbandon()
                }}
              >
                Conferma
              </ButtonRm >
              <ButtonRm 
                variant="text"
                onClick={(e) => props.handleCloseAbbandon(e)}
              >
                Annulla
              </ButtonRm >
            </div>
          </Modal.Body>
    </Modal>  
    )
  }

  function DeleteChat(props){

    const navigate = useNavigate()

    //GET
    async function getAll() {
        try {
            const response = await api().get(`${chat_list_endpoint}`);
            if(response.data) {
                props.setChatList(response.data?.content)
            }
        }
        catch(error) {
                console.log(error)
        }
    }


    //DELETE
    async function deleteItem() {
        console.log(props, "PROPS")

        try {
            const response = await api().delete(`/api/v1/chat-box/delete-chat-box?chatBoxId=${parseInt(props.chatId)}`)
        }
        catch(error) {
                console.log(error)
        }
    }

    //DELETE
    async function handleDelete(){
        deleteItem()
        navigate("/statoavanzamentolavori/chat")
    }

    return (    
    <Modal show={props.showDelete} onHide={(e) => props.handleCloseDelete(e)} centered>
        <Modal.Body className="p-5">
            <div className="d-flex flex-column text-center">
                <h2 className="fs-32 text-dark fw-semibold mb-3">
                Vuoi eliminare questa conversazione?
                </h2>
                <h6 className="fs-16 text-dark fw-normal mb-3">
                Questa conversazione verrà eliminata in maniera permanente
                </h6>
                <ButtonRm 
                    variant="primary"
                    onClick={(e) => { 
                        e.preventDefault()
                        e.stopPropagation()
                        handleDelete()
                    }}
                >
                    Conferma
                </ButtonRm >
                <ButtonRm 
                    variant="text"
                    onClick={(e) => props.handleCloseDelete(e)}
                >
                    Annulla
                </ButtonRm >
            </div>
        </Modal.Body>
    </Modal> 
    )
}