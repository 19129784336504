import React, { useState } from "react";
import AddIcon from "../../../assets/images/add.svg";
import FileLoadCard from "./FileLoadCard";

import uploadImg from "../../../assets/images/upload.svg"


export default function UploadBtn(props) {
  const [file, setFile] = useState([]);
  const [dragActive, setDragActive] = useState(false);


  const handleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
		let temp = [...file];
		setFile([...temp, ...e.target.files]);
    props.setFiles && props.setFiles([...temp, ...e.target.files]);
	};

  console.log(file, "fiiile")
  

 // handle drag events
const handleDrag = function (e) {
  e.preventDefault();
  e.stopPropagation();
  if (e.type === "dragenter" || e.type === "dragover") {
    setDragActive(true);
  } else if (e.type === "dragleave") {
    setDragActive(false);
  }
};

// triggers when files are dropped
const handleDrop = function (e) {
  e.preventDefault();
  e.stopPropagation();
  setDragActive(false);
  const droppedFiles = Array.from(e.dataTransfer.files);
  const updatedFiles = [...file, ...droppedFiles];
  setFile(updatedFiles);
  props.setFiles && props.setFiles(updatedFiles);
};


  //DELETE ITEM

  function deleteItem(){
    setFile([])
  }

  return (
    <div className="upload-div mt-3 mb-3">
      <button
        type="button"
        className={dragActive ? "drag-active btn upload-btn" : "btn upload-btn"}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <img loading="lazy" src={uploadImg} alt="Image No"></img>
        <p className="fs-16 text-center opacity-75">
          Trascina qui il tuo file o &nbsp;
        </p>
          <label htmlFor="input-file" className="input-file-label text-center d-inline">
            clicca per caricare
          </label>
          <input
            type="file"
            id="input-file"
            className="d-none"
            multiple={true}
            onChange={handleChange}
            accept={props.format}
          ></input>
       {props.format &&  <p className="fs-12 text-secondary text-center ">
          Formati supportati: {props.format}
        </p>}
      </button>
      {file.length > 0 && file.map((el) => <FileLoadCard name={el.name} deleteItem={deleteItem} />) }

    </div>
  );
}
