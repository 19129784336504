import React from "react";
import cardimage from "../../../../assets/images/allegati-ph.svg";
import axios from "axios";
import mimeTypes from "../../../../allegatiType.js";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard.js";
import { BASE_URL } from "../../../../api/api";

export default function AllegatiV(props) {

  const downloadFile = async (name) => {
		const fileUrl = `${BASE_URL}api/v1/owner/download/${props.formData.id}/${name}`;
	
		const response = await axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${
			JSON.parse(localStorage.getItem("user"))?.token
			}`,
			"Content-Type": `application/json`,
			
		},
		});
	
		// ottieni l'estensione del file
		const fileExtension = name.split('.').pop();
	
		const mimeType = mimeTypes[fileExtension];
	
		// crea un nuovo Blob con il tipo MIME corretto
		const file = new Blob([response.data], { type: mimeType });
	
		// crea un URL per il Blob
		const fileURL = URL.createObjectURL(file);
	
		// scarica il file
		window.open(fileURL);
	};

  return (
    <div className="row">
    <div className="col-12">
      <div className="form-item-align-box-white pt-5 pb-5">
        {props.formData.attachment.length > 0 ? (
          <>
					{props.formData.attachment.map((item, index) => {
						return (
							<FileCard
								key={index}
								name={item}
								setFileName={() => {}}
								deleteFile={() => {}}
								id={props.formData.id}
								download={downloadFile}
								deleteVisible={false}
							/>
						);
					})}
				</>
        ) : (
          <div
              className="col-12 my-5"
              style={{
                display: props.formData.attachment.length === 0 ? "" : "none",
              }}>
              <div className="mx-auto d-table text-center">
                <div className="image-user mx-auto d-table w-160">
                  <img loading="lazy" src={cardimage} alt="user-key" />
                </div>
                <p className="fs-18 text-secondary text-center">
                  Non hai aggiunto alcun allegato
                </p>
              </div>
            </div>
        )}
      </div>
    </div>
  </div>
  );
}
