import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"

import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import {loadConnectAndInitialize} from '@stripe/connect-js';


import {
    ConnectPaymentDetails,
    ConnectComponentsProvider,
    ConnectPayments,
    ConnectPayouts,
} from "@stripe/react-connect-js";

import { BASE_URL, token } from "../../api/api";




const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    sex: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	})
});

export default function Pagamenti (props){

    const data = {
        email:"",
        role:""
    }

    const [basicInvitation, setBasicInvitation] = useState([data])
    const [collabInvitation, setCollabInvitation] = useState([])
    //COLLABORATORE ROWS => BASED ON THE NUMBER OF COLLABORATOR SUBSCRIPTIONS BOUGHT

    function addNewRow(){
        setBasicInvitation(prev => {
            return [...prev, data]
        })
    }

    function sendBasicInvite(){
        //invia email
        setBasicInvitation([data])
    }

    function sendCollabInvite(){
        //invia email
        setCollabInvitation([data])
    }

    function removeRow(id){
        const newArr = basicInvitation.filter((item, index) => index != id)
        setBasicInvitation(newArr)
    }


    //PER PAYMENTS DETAILS
    const [visible, setVisible] = React.useState(false);
    const onOpen = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    function initiateSession() {
        console.log("INITIATE_SESSION")
        fetch(`${BASE_URL}api/v1/payment/account`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "datStripe");
/*           setClientSecret(data["client_secret"]);
           setAccountConnect(data["target_account"]); */
 
          // Aggiornare stripePromise con l'account ID ricevuto
         /*  stripePromise.then(stripe => {
            stripe.update({ stripeAccount: data["target_account"] });
          }); */
        })
        .catch((error) => {
          console.error('Errore durante la richiesta:', error);
        });
     
    }

    useEffect(() => {
        //initiateSession()

    }, [])

    console.log(props.stripeConnectInstance)

    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Pagamenti</p>
                    <p className="fs-14 opacity-75">Qui potrai gestire i tuoi pagamenti</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                <p className="fs-18 text-dark fw-bold">Riepilogo Pagamenti</p>


                    { props.stripeConnectInstance && 
                        <ConnectComponentsProvider connectInstance={props.stripeConnectInstance}>
                            <ConnectPayments />
                        </ConnectComponentsProvider>
                    }


                </Row>


                <Row className="mt-3">
                <p className="fs-18 text-dark fw-bold">Bilancio e Prelievi sul conto</p>

                {props.stripeConnectInstance && <ConnectComponentsProvider connectInstance={props.stripeConnectInstance}>
                    <ConnectPayouts />
                </ConnectComponentsProvider>}

                    <hr className="opacity-10 my-3"/>

                </Row>


        

            </div >
            
        </>

        
    )
}