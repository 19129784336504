import React, { useState, useEffect } from "react";
import TopHeading from "../TopHeading";
import { useNavigate } from "react-router-dom";
import api, { assembly_endpoint, stabili_endpoint } from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericInput from "react-numeric-input";
import { BsPlusCircleFill } from "react-icons/bs";
const CreateAssembly = () => {
	const [formData, setFormData] = useState({
		userID: 0,
		name: "",
		description: "",
		date1: "",
		hour1: 0,
		place1: "",
		toCalendar1: false,
		date2: "",
		hour2: 0,
		place2: "",
		toCalendar2: false,
		row1: "",
		row2: "",
		customRows: "",
		president: "",
		secretary: "",
		customSecretaries: [],
		headerRow1: "",
		headerRow2: "",
		headerRow3: "",
		condominium: [],
	});

	const onChange = (e) => {
		if (e.target.type == "checkbox") {
			setFormData({ ...formData, [e.target.name]: e.target.checked });
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const navigate = useNavigate();

	const save = async () => {
		try {
			await api().post(`${assembly_endpoint}`, formData);
		} catch (error) {
			console.log(error);
		}
		navigate(-1);
	};

	const [stabili, setStabili] = useState([]);
	async function getStabili() {
		try {
			const response = await api().get(`${stabili_endpoint}`);
			if (response.data) {
				setStabili(response.data.content);
				console.log("stabili", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		getStabili();
	}, []);
	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="Nuovo Assemblea" />
				</div>
			</div>
			<div className="step-show">
				<div className="content-container">
					<div className="form-item-align-box d-block">
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Nome</label>
									<input
										name="name"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.name}
										onChange={onChange}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">
										Stabile di riferimento
									</label>
									<select
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
										name="condominium"
										// value={
										// 	formData.condominium.length > 0
										// 		? formData.condominium[0].name
										// 		: "Seleziona"
										// }
										onChange={(e) => {
											setFormData({
												...formData,
												[e.target.name]: [JSON.parse(e.target.value)],
											});
										}}>
										<option value="">Seleziona</option>
										{stabili?.map((item, index) => {
											return (
												<option key={index + 1} value={JSON.stringify(item)}>
													{item.name}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Descrizione</label>
									<input
										name="description"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.description}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<label
							className="f-label fs-14 mt-4"
							style={{ textDecoration: "underline" }}>
							1° CONVOCAZIONE
						</label>
						<div className="row mt-3">
							<div className="col-md-3">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Data</label>
									<input
										name="date1"
										value={formData.date1}
										onChange={onChange}
										className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
										max="9999-12-31"
										type="date"></input>
								</div>
							</div>
							<div className="col-md-4">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Ora</label>
									<NumericInput
										value={formData.hour1}
										className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
										onChange={(value) => {
											let e = {
												target: { name: "hour1", value: value },
											};
											onChange(e);
										}}
									/>
								</div>
							</div>
							<div className="col-md-5">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Luogo</label>
									<input
										name="place1"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.place1}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<div className="input-checkbox mt-3">
							<input
								type="checkbox"
								name="toCalendar1"
								checked={formData.toCalendar1}
								onChange={onChange}
							/>
							<label
								className="fs-16 me-2"
								style={{ textDecoration: "underline" }}>
								Aggiungi al calendario
							</label>
						</div>
						<label
							className="f-label fs-14 mt-4"
							style={{ textDecoration: "underline" }}>
							2° CONVOCAZIONE
						</label>
						<div className="row mt-3">
							<div className="col-md-3">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Data</label>
									<input
										name="date2"
										value={formData.date2}
										onChange={onChange}
										className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
										max="9999-12-31"
										type="date"></input>
								</div>
							</div>
							<div className="col-md-4">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Ora</label>
									<NumericInput
										value={formData.hour2}
										className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
										onChange={(value) => {
											let e = {
												target: { name: "hour2", value: value },
											};
											onChange(e);
										}}
									/>
								</div>
							</div>
							<div className="col-md-5">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Luogo</label>
									<input
										name="place2"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.place2}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<div className="input-checkbox mt-3">
							<input
								type="checkbox"
								name="toCalendar2"
								checked={formData.toCalendar2}
								onChange={onChange}
							/>
							<label
								className="fs-16 me-2"
								style={{ textDecoration: "underline" }}>
								Aggiungi al calendario
							</label>
						</div>
						<label className="fs-16 mt-3 fw-bold">ORDINE DEL GIORNO</label>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Riga 1</label>
									<input
										name="row1"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.row1}
										onChange={onChange}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Riga 2</label>
									<input
										name="row2"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.row2}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<label className="black-link mt-2">
							<BsPlusCircleFill className="plus-icon" />
							Aggiungi riga
						</label>
						<div>
							<label className="fs-16 mt-3 fw-bold">
								PRESIDENTE E SEGRETARIO
							</label>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Presidente</label>
									<input
										name="president"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.president}
										onChange={onChange}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Segretario</label>
									<input
										name="secretary"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.secretary}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<div className="row justify-content-end">
							<label className="black-link mt-2 col-md-6">
								<BsPlusCircleFill className="plus-icon" />
								Aggiungi riga
							</label>
						</div>
						<div>
							<label className="fs-16 mt-4 fw-bold">
								INTESTAZIONE (per convocazione e verbale assemblea)
							</label>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Riga 1</label>
									<input
										name="headerRow1"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.headerRow1}
										onChange={onChange}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Riga 2</label>
									<input
										name="headerRow2"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.headerRow2}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Riga 3</label>
									<input
										name="headerRow3"
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
										value={formData.headerRow3}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateAssembly;
