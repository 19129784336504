import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from "../../../../actions/accountAction";
import TreeRm from "./TreeRm";
import List from "./List";
import { PieChart } from '@mui/x-charts/PieChart';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import api from '../../../../api/api';

import { Tree, TreeNode } from 'react-organizational-chart';





export default function UsersC(){

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);


    //HANDLE USERS

    //const users = useSelector(state => state.userReducer?.users)

    const users = [
        {
            "id": 1,
            "cdat": "2022-12-03T15:45:10.442+00:00",
            "udat": "2024-03-01T13:09:01.103+00:00",
            "username": "Izanagi",
            "email": "xiachenghao95@gmail.com",
            "password": "$2a$10$TGVd44ZrlfNiNa9t/6VCbOVHBlVb9SSQDXxleU/xgZvJ8GmmCEg5y",
            "phone": "100",
            "name": "cheng",
            "surname": "Xia",
            "fiscalCode": "fakeCode",
            "genre": "M",
            "birthday": "1995-01-18T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1995-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": "no privacy",
            "state": "WAIT_UPDATE",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 2,
            "cdat": "2022-12-05T09:02:39.533+00:00",
            "udat": "2022-12-05T09:02:39.533+00:00",
            "username": "Izanagi",
            "email": "dufgu888@gmail.com",
            "password": "$2a$10$heoNpxvtoS5KR3t0s84eBeTemFRCRuOap1lbKKYYxybBnCKy6Cy9a",
            "phone": "100",
            "name": "Olek",
            "surname": "Xia",
            "fiscalCode": "fakeCode",
            "genre": "M",
            "birthday": "1995-01-18T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1995-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 3,
            "cdat": "2022-12-06T10:18:33.720+00:00",
            "udat": "2024-04-24T12:56:37.121+00:00",
            "username": "test1",
            "email": "realmetatest1@gmail.com",
            "password": "$2a$10$pERMdYgviQemGESPXzRheebN/EGOZEO2Gq8cFmbSJVR7YRtYBAQEC",
            "phone": "3407306650",
            "name": "Bianca",
            "surname": "Rossi",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": "F",
            "birthday": "1994-01-18T00:00:00.000+00:00",
            "address": "Via Bensa",
            "houseNumber": "5",
            "province": "Asti",
            "municipality": "Antignano",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1994-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": "APPROVED",
            "role": "MASTER",
            "stripeUser": "acct_1P3C40QIW8QoF2RU",
            "stripeCustomer": null,
            "accountingResources": [
                {
                    "id": 6,
                    "cdat": "2023-06-12T11:20:13.551+00:00",
                    "udat": null,
                    "userID": 3,
                    "name": "Conto gestore 1",
                    "accountingType": "conto corrente bancario",
                    "openingBalance": 0.0,
                    "iban": "it327749393939",
                    "province": "",
                    "ownBy": "",
                    "abi": "",
                    "cabi": "",
                    "conto": "",
                    "cin": "",
                    "bank": "",
                    "sia": "",
                    "municipality": "",
                    "cucSepa": "",
                    "bic": "",
                    "users": [
                        {
                            "id": 3,
                            "cdat": "2022-12-06T10:18:33.720+00:00",
                            "udat": "2024-04-24T12:56:37.121+00:00",
                            "username": "test1",
                            "email": "realmetatest1@gmail.com",
                            "password": "$2a$10$pERMdYgviQemGESPXzRheebN/EGOZEO2Gq8cFmbSJVR7YRtYBAQEC",
                            "phone": "3407306650",
                            "name": "Bianca",
                            "surname": "Rossi",
                            "fiscalCode": "MVGMNL86D64D969P",
                            "genre": "F",
                            "birthday": "1994-01-18T00:00:00.000+00:00",
                            "address": "Via Bensa",
                            "houseNumber": "5",
                            "province": "Asti",
                            "municipality": "Antignano",
                            "documentType": "Documento",
                            "issueEntity": "fake",
                            "issueDate": "1994-01-18T00:00:00.000+00:00",
                            "documentNumber": "fake",
                            "issuePlace": "fake",
                            "documentFile": "fake",
                            "faceFile": "fake",
                            "rootAccountID": null,
                            "supplierAccountIDs": null,
                            "businessName": null,
                            "businessMunicipality": null,
                            "businessProvince": null,
                            "businessAddress": null,
                            "businessHouseNumber": null,
                            "businessCap": null,
                            "numberOfEmployees": null,
                            "companyRole": null,
                            "vatNumber": null,
                            "isCompany": null,
                            "uniqueCode": null,
                            "consentDateUpdate": null,
                            "privacy": null,
                            "cookie": null,
                            "termsConditions": null,
                            "source": null,
                            "browserInformation": null,
                            "formCode": null,
                            "privacyInformation": null,
                            "state": "APPROVED",
                            "role": "MASTER",
                            "stripeUser": "acct_1P3C40QIW8QoF2RU",
                            "stripeCustomer": null,
                            "publicPages": [],
                            "cap": "16123"
                        }
                    ]
                }
            ],
            "publicPages": [],
            "cap": "16123"
        },
        {
            "id": 4,
            "cdat": "2022-12-06T10:43:48.926+00:00",
            "udat": "2024-06-17T14:21:21.651+00:00",
            "username": "",
            "email": "demorealmeta@gmail.com",
            "password": "$2a$10$2pcq/iPQWX6INBFdzGkHgudsiELsT7evmjsqO0BiOLKfSusO0lg1W",
            "phone": "3407306650",
            "name": "demo",
            "surname": "demo",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": "M",
            "birthday": "2024-03-12T00:00:00.000+00:00",
            "address": "Viale Matteotti",
            "houseNumber": "10",
            "province": "Reggio Calabria",
            "municipality": "Bianco",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1994-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": "EMAMAVI",
            "businessMunicipality": "Bargagli",
            "businessProvince": "Genova",
            "businessAddress": "Via Domenico Enrico dall'Orto",
            "businessHouseNumber": "5",
            "businessCap": "16142",
            "numberOfEmployees": "12",
            "companyRole": "CEO",
            "vatNumber": "",
            "isCompany": false,
            "uniqueCode": "12355",
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": "acct_1OyUdW4Uif9lYRka",
            "stripeCustomer": "cus_PsygsXTouD5OBv",
            "accountingResources": [
                {
                    "id": 12,
                    "cdat": "2024-03-11T13:06:48.602+00:00",
                    "udat": null,
                    "userID": 4,
                    "name": "Emanuela Maviglia",
                    "accountingType": "conto corrente bancario",
                    "openingBalance": null,
                    "iban": "IT39E27737927972JDJDJD000M",
                    "province": "GE",
                    "ownBy": "",
                    "abi": "",
                    "cabi": "",
                    "conto": "",
                    "cin": "",
                    "bank": "",
                    "sia": "",
                    "municipality": "",
                    "cucSepa": "",
                    "bic": "",
                    "users": [
                        {
                            "id": 4,
                            "cdat": "2022-12-06T10:43:48.926+00:00",
                            "udat": "2024-06-17T14:21:21.651+00:00",
                            "username": "",
                            "email": "demorealmeta@gmail.com",
                            "password": "$2a$10$2pcq/iPQWX6INBFdzGkHgudsiELsT7evmjsqO0BiOLKfSusO0lg1W",
                            "phone": "3407306650",
                            "name": "demo",
                            "surname": "demo",
                            "fiscalCode": "MVGMNL86D64D969P",
                            "genre": "M",
                            "birthday": "2024-03-12T00:00:00.000+00:00",
                            "address": "Viale Matteotti",
                            "houseNumber": "10",
                            "province": "Reggio Calabria",
                            "municipality": "Bianco",
                            "documentType": "Documento",
                            "issueEntity": "fake",
                            "issueDate": "1994-01-18T00:00:00.000+00:00",
                            "documentNumber": "fake",
                            "issuePlace": "fake",
                            "documentFile": "fake",
                            "faceFile": "fake",
                            "rootAccountID": null,
                            "supplierAccountIDs": null,
                            "businessName": "EMAMAVI",
                            "businessMunicipality": "Bargagli",
                            "businessProvince": "Genova",
                            "businessAddress": "Via Domenico Enrico dall'Orto",
                            "businessHouseNumber": "5",
                            "businessCap": "16142",
                            "numberOfEmployees": "12",
                            "companyRole": "CEO",
                            "vatNumber": "",
                            "isCompany": false,
                            "uniqueCode": "12355",
                            "consentDateUpdate": null,
                            "privacy": null,
                            "cookie": null,
                            "termsConditions": null,
                            "source": null,
                            "browserInformation": null,
                            "formCode": null,
                            "privacyInformation": null,
                            "state": null,
                            "role": "MASTER",
                            "stripeUser": "acct_1OyUdW4Uif9lYRka",
                            "stripeCustomer": "cus_PsygsXTouD5OBv",
                            "publicPages": [],
                            "cap": "89032"
                        }
                    ]
                }
            ],
            "publicPages": [],
            "cap": "89032"
        },
        {
            "id": 5,
            "cdat": "2022-12-16T15:45:13.871+00:00",
            "udat": "2023-06-09T07:28:26.365+00:00",
            "username": "riccardogiacomazzi",
            "email": "riki.giacomazzi@gmail.com",
            "password": "$2a$10$I2h1ABjvBUkNuu2iNZnNt.tShnYctaA1wjyCjydEEWtVNf/WrMGgC",
            "phone": "100",
            "name": "Riccardo",
            "surname": "Giacomazzi",
            "fiscalCode": "fakeCode",
            "genre": "F",
            "birthday": "1994-12-29T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genova",
            "municipality": "Genova",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1996-01-01T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": "Anthill srl",
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": "10",
            "companyRole": "CEO",
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [
                {
                    "id": 4,
                    "cdat": "2023-06-09T11:24:35.294+00:00",
                    "udat": null,
                    "userID": 5,
                    "name": "Conto 2",
                    "accountingType": "conto corrente bancario",
                    "openingBalance": 0.0,
                    "iban": "IT33E27737927972JDJDJD000M",
                    "province": "",
                    "ownBy": "",
                    "abi": "",
                    "cabi": "",
                    "conto": "",
                    "cin": "",
                    "bank": "",
                    "sia": "",
                    "municipality": "",
                    "cucSepa": "",
                    "bic": "",
                    "users": [
                        {
                            "id": 5,
                            "cdat": "2022-12-16T15:45:13.871+00:00",
                            "udat": "2023-06-09T07:28:26.365+00:00",
                            "username": "riccardogiacomazzi",
                            "email": "riki.giacomazzi@gmail.com",
                            "password": "$2a$10$I2h1ABjvBUkNuu2iNZnNt.tShnYctaA1wjyCjydEEWtVNf/WrMGgC",
                            "phone": "100",
                            "name": "Riccardo",
                            "surname": "Giacomazzi",
                            "fiscalCode": "fakeCode",
                            "genre": "F",
                            "birthday": "1994-12-29T00:00:00.000+00:00",
                            "address": "fakeAddress",
                            "houseNumber": "1A",
                            "province": "Genova",
                            "municipality": "Genova",
                            "documentType": "Documento",
                            "issueEntity": "fake",
                            "issueDate": "1996-01-01T00:00:00.000+00:00",
                            "documentNumber": "fake",
                            "issuePlace": "fake",
                            "documentFile": "fake",
                            "faceFile": "fake",
                            "rootAccountID": null,
                            "supplierAccountIDs": null,
                            "businessName": "Anthill srl",
                            "businessMunicipality": null,
                            "businessProvince": null,
                            "businessAddress": null,
                            "businessHouseNumber": null,
                            "businessCap": null,
                            "numberOfEmployees": "10",
                            "companyRole": "CEO",
                            "vatNumber": null,
                            "isCompany": null,
                            "uniqueCode": null,
                            "consentDateUpdate": null,
                            "privacy": null,
                            "cookie": null,
                            "termsConditions": null,
                            "source": null,
                            "browserInformation": null,
                            "formCode": null,
                            "privacyInformation": null,
                            "state": null,
                            "role": "MASTER",
                            "stripeUser": null,
                            "stripeCustomer": null,
                            "publicPages": [],
                            "cap": "16134"
                        }
                    ]
                },
                {
                    "id": 5,
                    "cdat": "2023-06-09T12:27:46.261+00:00",
                    "udat": "2023-06-09T12:29:49.315+00:00",
                    "userID": 5,
                    "name": "Conto 3",
                    "accountingType": "conto corrente bancario",
                    "openingBalance": 10.0,
                    "iban": "IT23E27737927972JDJDJD000M",
                    "province": "",
                    "ownBy": "",
                    "abi": "",
                    "cabi": "",
                    "conto": "",
                    "cin": "",
                    "bank": "",
                    "sia": "",
                    "municipality": "",
                    "cucSepa": "",
                    "bic": "",
                    "users": [
                        {
                            "id": 5,
                            "cdat": "2022-12-16T15:45:13.871+00:00",
                            "udat": "2023-06-09T07:28:26.365+00:00",
                            "username": "riccardogiacomazzi",
                            "email": "riki.giacomazzi@gmail.com",
                            "password": "$2a$10$I2h1ABjvBUkNuu2iNZnNt.tShnYctaA1wjyCjydEEWtVNf/WrMGgC",
                            "phone": "100",
                            "name": "Riccardo",
                            "surname": "Giacomazzi",
                            "fiscalCode": "fakeCode",
                            "genre": "F",
                            "birthday": "1994-12-29T00:00:00.000+00:00",
                            "address": "fakeAddress",
                            "houseNumber": "1A",
                            "province": "Genova",
                            "municipality": "Genova",
                            "documentType": "Documento",
                            "issueEntity": "fake",
                            "issueDate": "1996-01-01T00:00:00.000+00:00",
                            "documentNumber": "fake",
                            "issuePlace": "fake",
                            "documentFile": "fake",
                            "faceFile": "fake",
                            "rootAccountID": null,
                            "supplierAccountIDs": null,
                            "businessName": "Anthill srl",
                            "businessMunicipality": null,
                            "businessProvince": null,
                            "businessAddress": null,
                            "businessHouseNumber": null,
                            "businessCap": null,
                            "numberOfEmployees": "10",
                            "companyRole": "CEO",
                            "vatNumber": null,
                            "isCompany": null,
                            "uniqueCode": null,
                            "consentDateUpdate": null,
                            "privacy": null,
                            "cookie": null,
                            "termsConditions": null,
                            "source": null,
                            "browserInformation": null,
                            "formCode": null,
                            "privacyInformation": null,
                            "state": null,
                            "role": "MASTER",
                            "stripeUser": null,
                            "stripeCustomer": null,
                            "publicPages": [],
                            "cap": "16134"
                        }
                    ]
                }
            ],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 6,
            "cdat": "2022-12-21T11:28:01.952+00:00",
            "udat": "2022-12-21T11:28:01.952+00:00",
            "username": "simone bona",
            "email": "s.bona72@gmail.com",
            "password": "$2a$10$89E6MQ4DY29A/ztiV.h4b.nLCWLXhw9XIQ2Wl7LITrTEFGtziBPia",
            "phone": "100",
            "name": "Simone",
            "surname": "Bona",
            "fiscalCode": "fakeCode",
            "genre": "M",
            "birthday": "1995-01-18T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1995-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 7,
            "cdat": "2022-12-21T11:31:22.661+00:00",
            "udat": "2022-12-21T11:31:22.661+00:00",
            "username": "Tomaso Giacomazzi",
            "email": "tomasogiacomazzi@gmail.com",
            "password": "$2a$10$OUlKEyxANV3LZ9dywVlH8.WPqWL6cIfj2wge6YZ/HGLDnwBf29X7i",
            "phone": "100",
            "name": "Tomaso",
            "surname": "Giacomazzi",
            "fiscalCode": "fakeCode",
            "genre": "M",
            "birthday": "1995-01-18T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1995-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 40,
            "cdat": "2022-12-22T11:04:09.883+00:00",
            "udat": "2022-12-22T11:04:09.883+00:00",
            "username": "Claudio Brignone",
            "email": "info@propertyservices.it",
            "password": "$2a$10$s/IbhuqZ5i9S6lY6l1l.cONv8Cfvt872tihvv73aNrkbRNAm6eX6O",
            "phone": "100",
            "name": "Claudio",
            "surname": "Brignone",
            "fiscalCode": "fakeCode",
            "genre": "M",
            "birthday": "1995-01-18T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1995-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 41,
            "cdat": "2022-12-23T23:06:14.125+00:00",
            "udat": "2022-12-23T23:06:14.125+00:00",
            "username": "otis",
            "email": "pifevot933@pro5g.com",
            "password": "$2a$10$tNzKDQ33fgfFJy1mO7o1Ou.DKKt33RWpNCx6QnIMuyfHqe6gxFoF6",
            "phone": "+3802376979",
            "name": "name",
            "surname": "surname",
            "fiscalCode": "XIACNG95A18Z210L",
            "genre": "M",
            "birthday": "2022-12-13T00:00:00.000+00:00",
            "address": "Via del Lagaccio",
            "houseNumber": "12",
            "province": "Genova",
            "municipality": "Busalla",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "comune",
            "issueDate": "2022-12-21T00:00:00.000+00:00",
            "documentNumber": "21943123",
            "issuePlace": "Genova",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 42,
            "cdat": "2022-12-23T23:17:00.931+00:00",
            "udat": "2022-12-23T23:17:00.931+00:00",
            "username": "username",
            "email": "titola1212@subdito.com",
            "password": "$2a$10$Ms.INbEVsj6y8OIMILDxtenGF4SdE65x8c.FcO0x6WUx09saaWEjq",
            "phone": "+3802376979",
            "name": "Chenghao",
            "surname": "Xia",
            "fiscalCode": "XIACNG95A18Z210L",
            "genre": "F",
            "birthday": "2022-12-23T00:00:00.000+00:00",
            "address": "Via del Lagaccio",
            "houseNumber": "212",
            "province": "Bari",
            "municipality": "Adelfia",
            "documentType": "patente",
            "issueEntity": "212",
            "issueDate": "2022-12-18T00:00:00.000+00:00",
            "documentNumber": "21943123",
            "issuePlace": "GE",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "1212"
        },
        {
            "id": 43,
            "cdat": "2023-01-03T11:22:02.089+00:00",
            "udat": "2023-01-03T11:22:02.089+00:00",
            "username": "otis",
            "email": "pehaf69979@kaftee.com",
            "password": "$2a$10$irWZgezhFLHTBvFq85zGR..prrj00G.g.Pu8C69eqz4jUD68v7sXm",
            "phone": "+3802376979",
            "name": "name",
            "surname": "surname",
            "fiscalCode": "XIACNG95A18Z210L",
            "genre": "M",
            "birthday": "2022-12-13T00:00:00.000+00:00",
            "address": "Via del Lagaccio",
            "houseNumber": "12",
            "province": "Genova",
            "municipality": "Busalla",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "comune",
            "issueDate": "2022-12-21T00:00:00.000+00:00",
            "documentNumber": "21943123",
            "issuePlace": "Genova",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 44,
            "cdat": "2023-01-11T15:15:43.618+00:00",
            "udat": "2023-01-11T15:15:43.618+00:00",
            "username": "Ludovica Giacomazzi",
            "email": "ludovica.giacomazzi@gmail.com",
            "password": "$2a$10$OAS4wJ7Fj/8q4k4.SnDOFeydAB121eTE/G8huMGpk3bqdBgEIEvjy",
            "phone": "100",
            "name": "Ludovica",
            "surname": "Giacomazzi",
            "fiscalCode": "fakeCode",
            "genre": "F",
            "birthday": "1993-01-11T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1993-01-18T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 45,
            "cdat": "2023-01-20T15:21:31.388+00:00",
            "udat": "2023-01-20T15:21:31.388+00:00",
            "username": "nerinazure",
            "email": "amministrazione@azurerealestate.it",
            "password": "$2a$10$6P9d9ai5fzhZ8QkduvXD5eWlrcA..RcWS..dxO6IsAT6kmyRwfCGC",
            "phone": "100",
            "name": "Nerina",
            "surname": "Moscatelli",
            "fiscalCode": "fakeCode",
            "genre": "F",
            "birthday": "1996-01-01T00:00:00.000+00:00",
            "address": "fakeAddress",
            "houseNumber": "1A",
            "province": "Genoa",
            "municipality": "Genoa",
            "documentType": "Documento",
            "issueEntity": "fake",
            "issueDate": "1996-01-01T00:00:00.000+00:00",
            "documentNumber": "fake",
            "issuePlace": "fake",
            "documentFile": "fake",
            "faceFile": "fake",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 46,
            "cdat": "2023-03-08T13:16:26.442+00:00",
            "udat": "2023-03-08T13:16:26.442+00:00",
            "username": "test",
            "email": "hohepiv569@fenwazi.com",
            "password": "$2a$10$/KM6iAfoiC4vWoypiVhGAO9S4vAuldEwdBpfOCLf4iUMEmlu8.kie",
            "phone": "",
            "name": "",
            "surname": "",
            "fiscalCode": "",
            "genre": null,
            "birthday": null,
            "address": "",
            "houseNumber": "",
            "province": "",
            "municipality": "",
            "documentType": "",
            "issueEntity": "",
            "issueDate": null,
            "documentNumber": "",
            "issuePlace": "",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": ""
        },
        {
            "id": 47,
            "cdat": "2023-07-04T20:38:51.150+00:00",
            "udat": "2023-07-04T20:38:51.150+00:00",
            "username": "212",
            "email": "xipanal610@edulena.com",
            "password": "$2a$10$KlnB.3YCFFZvZqdXFGF17Ov6idALfJZ2tKJb1PI6.h3Hy5WzfLrAS",
            "phone": "+21212",
            "name": "asas",
            "surname": "dsadasd",
            "fiscalCode": "ADASD",
            "genre": null,
            "birthday": "2023-06-29T23:00:00.000+00:00",
            "address": "dsad",
            "houseNumber": "asds",
            "province": "Alessandria",
            "municipality": "Albera Ligure",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "32321",
            "issueDate": "2023-07-12T23:00:00.000+00:00",
            "documentNumber": "43242",
            "issuePlace": "42342",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "31223"
        },
        {
            "id": 48,
            "cdat": "2023-08-29T12:55:30.252+00:00",
            "udat": "2024-02-29T08:51:52.203+00:00",
            "username": "emavi",
            "email": "xalifeg908@xgh6.com",
            "password": "$2a$10$Y8eDFD2HXKXPhgQGuC1MluX4D2c6x/kqB5IPpdbKCDPwdt64jGGRK",
            "phone": "+3407574747",
            "name": "Emanuela",
            "surname": "Maviglia",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-08-16T23:00:00.000+00:00",
            "address": "via toppo",
            "houseNumber": "44",
            "province": "Ancona",
            "municipality": "Arcevia",
            "documentType": "passaporto",
            "issueEntity": "444",
            "issueDate": "2023-08-10T23:00:00.000+00:00",
            "documentNumber": "e333",
            "issuePlace": "fff",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": "WAIT_UPDATE",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "333"
        },
        {
            "id": 49,
            "cdat": "2023-08-29T13:25:31.062+00:00",
            "udat": "2023-08-29T13:25:31.062+00:00",
            "username": null,
            "email": "noraveb214@trazeco.com",
            "password": "$2a$10$WqvLOBTJpIbR6Ah5HNO9tuimoXjem93rdez4GQNtpWUT6lLNDwBHu",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": 1234,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 50,
            "cdat": "2023-08-29T14:19:03.482+00:00",
            "udat": "2023-08-29T14:19:03.482+00:00",
            "username": null,
            "email": "xawelos822@vikinoko.com",
            "password": "$2a$10$b4GfvDyu78IdW.XCnU69yeu5Y5gWJdVkg/1P5oIRntDUkHcwVU762",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": 1233,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 51,
            "cdat": "2023-08-29T14:38:20.887+00:00",
            "udat": "2023-08-29T14:38:20.887+00:00",
            "username": null,
            "email": "gefiwa3562@trazeco.com",
            "password": "$2a$10$wAxRregJRYUoTzFHObsDuObkTl8B0OYj4qi5SR8BhGLy0kA3uEkKa",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "CONDUCTOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 52,
            "cdat": "2023-08-30T06:15:00.368+00:00",
            "udat": "2023-08-30T06:15:00.368+00:00",
            "username": null,
            "email": "bowewo2287@trazeco.com",
            "password": "$2a$10$m1P0TvEh5ZizNcjOjWnK0ecjj4PqoPD0kcCM.z17GGEvXFwDmW8nS",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 53,
            "cdat": "2023-09-01T13:05:20.407+00:00",
            "udat": "2024-02-29T08:23:44.530+00:00",
            "username": "Privacy",
            "email": "xojiyil589@synclane.com",
            "password": "$2a$10$AB3yqZCnGlYPwjSkjQRz6e8w8Du42wcK4CZBXci1E0d5jj9cWZ3Hy",
            "phone": "+3407306650",
            "name": "Ema",
            "surname": "Mavi",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-08-29T23:00:00.000+00:00",
            "address": "Via Domenico Enrico dall'Orto",
            "houseNumber": "4",
            "province": "Ancona",
            "municipality": "Barbara",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "eee",
            "issueDate": "2023-09-13T23:00:00.000+00:00",
            "documentNumber": "eee",
            "issuePlace": "eee",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": "APPROVED",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "444"
        },
        {
            "id": 54,
            "cdat": "2023-09-01T13:51:24.063+00:00",
            "udat": "2023-09-01T13:51:24.063+00:00",
            "username": null,
            "email": "silofim520@synclane.com",
            "password": "$2a$10$kBf4dGW8IUmKbKPzR/hjZuYIUVghSQHV7bOqiiDUz5Jayq3lfFOTm",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": null,
            "privacy": null,
            "cookie": null,
            "termsConditions": null,
            "source": null,
            "browserInformation": null,
            "formCode": null,
            "privacyInformation": null,
            "state": null,
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 55,
            "cdat": "2023-09-03T20:49:03.704+00:00",
            "udat": "2023-09-03T20:49:03.704+00:00",
            "username": null,
            "email": "pejegis833@xgh6.com",
            "password": "$2a$10$Yx4lV7SMrSeXNFxL6tqEHewcnLgGeullM.vTzWrXzBpiRJgzgyxdW",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-02T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "CONDUCTOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 56,
            "cdat": "2023-09-08T12:00:03.836+00:00",
            "udat": "2023-09-08T12:00:03.836+00:00",
            "username": null,
            "email": "bivahay976@nickolis.com",
            "password": "$2a$10$q2EN1F/nFmY1uCMCA7SPmO6DJVEwd8y0Fszv6WmOhbb.X4pN1M3ZK",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-07T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 57,
            "cdat": "2023-09-08T13:55:49.627+00:00",
            "udat": "2024-03-01T09:58:04.078+00:00",
            "username": "provolino",
            "email": "kisif94997@gameszox.com",
            "password": "$2a$10$Q16bnQ//IhQJjR.7LZ9arecY9gabE7rUyB7p2uZ1hP8sUQeJXFL/u",
            "phone": "+3407306650",
            "name": "Ema",
            "surname": "Mav",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-05-11T23:00:00.000+00:00",
            "address": "Via posll",
            "houseNumber": "2",
            "province": "Alessandria",
            "municipality": "Alessandria",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "33",
            "issueDate": "2023-09-06T23:00:00.000+00:00",
            "documentNumber": "333",
            "issuePlace": "44",
            "documentFile": "",
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-07T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_UPDATE",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "111"
        },
        {
            "id": 58,
            "cdat": "2023-09-08T14:00:06.944+00:00",
            "udat": "2023-09-08T14:00:06.944+00:00",
            "username": null,
            "email": "foyet46147@gameszox.com",
            "password": "$2a$10$65wvMn0h.jz8hyX8os47o.ZO.IEg/.kzx5yC6qI2IYp1O1CcgTH3K",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-07T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 59,
            "cdat": "2023-09-08T14:57:04.012+00:00",
            "udat": "2023-09-08T14:57:04.012+00:00",
            "username": "peov",
            "email": "reway77964@cohodl.com",
            "password": "$2a$10$pXI0evOGUmnP5G9dLN3Lluw5kEpYZY5dEl/5/GTYA9U/MRDB8ENe.",
            "phone": "+3407306650",
            "name": "dd",
            "surname": "ee",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-08-29T23:00:00.000+00:00",
            "address": "dd",
            "houseNumber": "ee",
            "province": "Alessandria",
            "municipality": "Albera Ligure",
            "documentType": "passaporto",
            "issueEntity": "dd",
            "issueDate": "2023-09-13T23:00:00.000+00:00",
            "documentNumber": "ddd",
            "issuePlace": "ddd",
            "documentFile": null,
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-07T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "27"
        },
        {
            "id": 60,
            "cdat": "2023-09-15T07:13:23.838+00:00",
            "udat": "2023-09-15T07:13:23.838+00:00",
            "username": "emmola",
            "email": "biveras463@tenjb.com",
            "password": "$2a$10$G7OqGq56rxArzODl5Oph0eSqtT.NQwjqlnLVnulMZCNornbgb4HVW",
            "phone": "+348585886",
            "name": "ema",
            "surname": "mav",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-08-31T23:00:00.000+00:00",
            "address": "VIA TOPI",
            "houseNumber": "34",
            "province": "Alessandria",
            "municipality": "Albera Ligure",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "444",
            "issueDate": "2023-09-21T23:00:00.000+00:00",
            "documentNumber": "123",
            "issuePlace": "GENOVA",
            "documentFile": null,
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-14T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "333"
        },
        {
            "id": 61,
            "cdat": "2023-09-15T07:17:53.415+00:00",
            "udat": "2023-09-15T07:17:53.415+00:00",
            "username": "emmy",
            "email": "homihoc287@ipnuc.com",
            "password": "$2a$10$M2524vOjHyjRa0gMn6go.ezwYBIlr94TyM41xQlNYK3TZp6IaPa9u",
            "phone": "+35474757589",
            "name": "emm",
            "surname": "mavv",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-09-01T23:00:00.000+00:00",
            "address": "cIA POP",
            "houseNumber": "123",
            "province": "Arezzo",
            "municipality": "Badia Tedalda",
            "documentType": "patente",
            "issueEntity": "WEI",
            "issueDate": "2023-09-14T23:00:00.000+00:00",
            "documentNumber": "66464",
            "issuePlace": "GENOVA",
            "documentFile": null,
            "faceFile": "",
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-14T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "65759"
        },
        {
            "id": 62,
            "cdat": "2023-09-15T08:47:33.250+00:00",
            "udat": "2023-09-15T08:47:33.250+00:00",
            "username": "eee",
            "email": "titita4533@ipnuc.com",
            "password": "$2a$10$N03kGrTdiq/5Tg73D33Os.MSzdOx7OmvRtioYiJW3aJK9yv8tmZlW",
            "phone": "+4444",
            "name": "dd",
            "surname": "fff",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-09-12T23:00:00.000+00:00",
            "address": "via piopp",
            "houseNumber": "4",
            "province": "Ancona",
            "municipality": "Ancona",
            "documentType": "patente",
            "issueEntity": "eee",
            "issueDate": "2023-09-13T23:00:00.000+00:00",
            "documentNumber": "333",
            "issuePlace": "genova",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-14T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "888"
        },
        {
            "id": 63,
            "cdat": "2023-09-20T08:11:14.647+00:00",
            "udat": "2023-09-20T08:18:34.548+00:00",
            "username": "pie123",
            "email": "loken50380@ipnuc.com",
            "password": "$2a$10$6r/2t3kTacs7DBWGmNctMuFAgDoT3kQJ6Y3wChRUIBr7bHMOf3wLu",
            "phone": null,
            "name": "Piero",
            "surname": "Venni",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-09-07T23:00:00.000+00:00",
            "address": "Via pioppo",
            "houseNumber": "5/3",
            "province": "Arezzo",
            "municipality": "Arezzo",
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": "Idra srl",
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-19T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "89033"
        },
        {
            "id": 64,
            "cdat": "2023-09-21T20:49:48.991+00:00",
            "udat": "2023-09-21T20:49:48.991+00:00",
            "username": "test",
            "email": "gimir15614@cdeter.com",
            "password": "$2a$10$ZIuYzDF8nQb18JAHPU.3iOVtYwZ092dK3Flm3JSZOZKWeFihbOtvG",
            "phone": "+3407306650",
            "name": "Ema",
            "surname": "Ma",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2023-09-19T23:00:00.000+00:00",
            "address": "Via Domenico Enrico dall'Orto",
            "houseNumber": "4",
            "province": "Ancona",
            "municipality": "Arcevia",
            "documentType": "",
            "issueEntity": "",
            "issueDate": null,
            "documentNumber": "",
            "issuePlace": "",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-20T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "44"
        },
        {
            "id": 65,
            "cdat": "2023-09-26T09:46:08.557+00:00",
            "udat": "2023-09-26T09:46:08.557+00:00",
            "username": null,
            "email": "caboyam653@cdeter.com",
            "password": "$2a$10$kU3wAfrSEbc5Pfeo/dL1meckHtfm2eiZanLW8n457M0rb14MjEZE6",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-25T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 66,
            "cdat": "2023-09-26T09:49:28.115+00:00",
            "udat": "2023-09-26T09:49:28.115+00:00",
            "username": null,
            "email": "tabig69497@ipniel.com",
            "password": "$2a$10$L9VJlS.r6hHbRU1qUkGVjuYN2.WeBvF/pxbJa1JhKcALvA5wtn556",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-25T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 67,
            "cdat": "2023-09-26T11:27:12.957+00:00",
            "udat": "2023-09-26T11:27:12.957+00:00",
            "username": null,
            "email": "mocedi2476@alvisani.com",
            "password": "$2a$10$xQ1KbBLTvLv2KUaCseEp6e/B.FFLlJr72EY5mBCw3wGgnxGWo8FbK",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-25T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 68,
            "cdat": "2023-09-27T08:31:10.688+00:00",
            "udat": "2023-09-27T08:45:09.799+00:00",
            "username": null,
            "email": "moxav75124@bnovel.com",
            "password": "$2a$10$sdqN6ntdQ.rCPnDasSra..J9lpNtp52R8.8iCTpum5RXORulSAsZ6",
            "phone": null,
            "name": "Gino",
            "surname": "Mancuso",
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-09-26T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/116.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 69,
            "cdat": "2023-10-09T06:09:17.816+00:00",
            "udat": "2023-10-09T06:09:17.816+00:00",
            "username": null,
            "email": "kopel61669@estudys.com",
            "password": "$2a$10$9AYBHSzbO0vIdfJW.4tUqOipFpDcvJwdnzrtjK2yDg91N6LOVV9.O",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-10-08T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 70,
            "cdat": "2023-10-20T15:21:29.948+00:00",
            "udat": "2023-10-20T15:21:29.948+00:00",
            "username": null,
            "email": "yixoj93530@tutoreve.com",
            "password": "$2a$10$Prk6wKnZIg8TF2AI4e4haOj6uokwxrsvmwIqP5ypch2H8Y5WG53vG",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2023-10-19T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/117.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 71,
            "cdat": "2024-01-09T08:05:16.514+00:00",
            "udat": "2024-01-09T08:05:16.514+00:00",
            "username": "testolo",
            "email": "xavah80478@tanlanav.com",
            "password": "$2a$10$k67GLuDk0M6g.buB52sDGu.wm9uMQWz6LiIXak7RL1CCDatsCWnCm",
            "phone": "+3407306650",
            "name": "eee",
            "surname": "rrr",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "2024-01-07T00:00:00.000+00:00",
            "address": "Via Domenico Enrico dall'Orto",
            "houseNumber": "3",
            "province": "Ancona",
            "municipality": "Arcevia",
            "documentType": "patente",
            "issueEntity": "555",
            "issueDate": "2024-01-18T00:00:00.000+00:00",
            "documentNumber": "444",
            "issuePlace": "55555",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-01-09T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "45678"
        },
        {
            "id": 72,
            "cdat": "2024-01-09T08:29:43.972+00:00",
            "udat": "2024-01-09T08:29:43.972+00:00",
            "username": "arashalghasi",
            "email": "arash.alqasi@gmail.com",
            "password": "$2a$10$SO1bAPEkYYgSBoFqoKPp/O9ExZgg5qq4N0zOF1Siv/krbdAW8wan6",
            "phone": "+3515886733",
            "name": "Arash",
            "surname": "Alghasi",
            "fiscalCode": "LGHRSH95H18Z224L",
            "genre": null,
            "birthday": "1995-06-17T23:00:00.000+00:00",
            "address": "via ponza ",
            "houseNumber": "2",
            "province": "Genova",
            "municipality": "Genova",
            "documentType": "carta d'identità elettronica/cartacea",
            "issueEntity": "Comune di Genova",
            "issueDate": "2021-01-14T00:00:00.000+00:00",
            "documentNumber": "CA68126HW",
            "issuePlace": "Genova",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-01-09T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 73,
            "cdat": "2024-01-09T13:55:16.857+00:00",
            "udat": "2024-01-09T13:55:16.857+00:00",
            "username": null,
            "email": "emanuelamaviglia91@gmail.com",
            "password": "$2a$10$cpSpMx/Gm/aCK9f5CMsRO.TkYCpMm.SOp8gs4dIItV4jIH0B/ia2u",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": 1234,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-01-09T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 74,
            "cdat": "2024-02-01T13:51:44.443+00:00",
            "udat": "2024-02-01T13:51:44.443+00:00",
            "username": null,
            "email": "gamohon547@namewok.com",
            "password": "$2a$10$HW/eJ50NEVzDht7WkyRHh.VXOZPph2NWL8Rbtyoxdx8g2cIQymhVC",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-02-01T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 75,
            "cdat": "2024-02-02T07:53:18.904+00:00",
            "udat": "2024-02-02T07:53:18.904+00:00",
            "username": null,
            "email": "bigisig380@namewok.com",
            "password": "$2a$10$yHSA8xH6frXTRapkpNR/AOCdd0znXbw5xYVubTYMW6GyuR5cj9PgC",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-02-02T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Mobile Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 76,
            "cdat": "2024-02-05T07:49:27.827+00:00",
            "udat": "2024-02-05T07:49:27.827+00:00",
            "username": null,
            "email": "raner92473@alibrs.com",
            "password": "$2a$10$j1M6Y6G80R.INEG4BzlIFuA/YAW.mLoP9kwwUS6wa23Qho.92/H8y",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-02-05T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 77,
            "cdat": "2024-02-05T13:21:16.739+00:00",
            "udat": "2024-02-05T13:21:16.739+00:00",
            "username": "arashalghasi1995@gmail.com",
            "email": "arashalghasi1995@gmail.com",
            "password": "$2a$10$ndfXJDjyGn.jJ8NqjzLkhOeMAnGP46YUN0DMctVwUbb0FZnU1VUG.",
            "phone": "+393515886733",
            "name": "arash",
            "surname": "alghasi",
            "fiscalCode": "L",
            "genre": null,
            "birthday": "1995-06-17T23:00:00.000+00:00",
            "address": "via ponza",
            "houseNumber": "2",
            "province": "Genova",
            "municipality": "Genova",
            "documentType": "patente",
            "issueEntity": "",
            "issueDate": null,
            "documentNumber": "",
            "issuePlace": "",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-02-05T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 16-01-2024 / Privacy Policy 16-01-2024 / Cookie Policy 16-01-2024",
            "state": null,
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16134"
        },
        {
            "id": 78,
            "cdat": "2024-02-12T09:49:04.256+00:00",
            "udat": "2024-02-12T09:49:04.256+00:00",
            "username": null,
            "email": "solisix678@oprevolt.com",
            "password": "$2a$10$DKsoVEQY1ON/6Y6FuGwtaeldpLsSje6n9rF213ESg/bZLrYr8DTC2",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-02-12T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": null,
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 79,
            "cdat": "2024-03-19T14:54:08.762+00:00",
            "udat": "2024-03-19T14:54:08.762+00:00",
            "username": "emanuè",
            "email": "emanuelamaviglia@anthillgroup.it",
            "password": "$2a$10$f0gFaPA6SDmR5KHSOsGJpe4IkhsiH5hBw.8WRHRRZOaUgnYfX/53C",
            "phone": "+3407306650",
            "name": "Emma",
            "surname": "Mavi",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "1996-06-05T23:00:00.000+00:00",
            "address": "Viale Matteotti",
            "houseNumber": "10",
            "province": "",
            "municipality": "",
            "documentType": "",
            "issueEntity": "",
            "issueDate": null,
            "documentNumber": "",
            "issuePlace": "",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-03-19T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 16-01-2024 / Privacy Policy 16-01-2024 / Cookie Policy 16-01-2024",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [
                {
                    "id": 13,
                    "cdat": "2024-03-19T14:56:01.277+00:00",
                    "udat": null,
                    "userID": 79,
                    "name": "BNL 929",
                    "accountingType": "conto corrente bancario",
                    "openingBalance": 0.0,
                    "iban": "IT39E27737927972J",
                    "province": "",
                    "ownBy": "",
                    "abi": "",
                    "cabi": "",
                    "conto": "",
                    "cin": "",
                    "bank": "",
                    "sia": "",
                    "municipality": "",
                    "cucSepa": "",
                    "bic": "",
                    "users": [
                        {
                            "id": 79,
                            "cdat": "2024-03-19T14:54:08.762+00:00",
                            "udat": "2024-03-19T14:54:08.762+00:00",
                            "username": "emanuè",
                            "email": "emanuelamaviglia@anthillgroup.it",
                            "password": "$2a$10$f0gFaPA6SDmR5KHSOsGJpe4IkhsiH5hBw.8WRHRRZOaUgnYfX/53C",
                            "phone": "+3407306650",
                            "name": "Emma",
                            "surname": "Mavi",
                            "fiscalCode": "MVGMNL86D64D969P",
                            "genre": null,
                            "birthday": "1996-06-05T23:00:00.000+00:00",
                            "address": "Viale Matteotti",
                            "houseNumber": "10",
                            "province": "",
                            "municipality": "",
                            "documentType": "",
                            "issueEntity": "",
                            "issueDate": null,
                            "documentNumber": "",
                            "issuePlace": "",
                            "documentFile": null,
                            "faceFile": null,
                            "rootAccountID": null,
                            "supplierAccountIDs": null,
                            "businessName": null,
                            "businessMunicipality": null,
                            "businessProvince": null,
                            "businessAddress": null,
                            "businessHouseNumber": null,
                            "businessCap": null,
                            "numberOfEmployees": null,
                            "companyRole": null,
                            "vatNumber": null,
                            "isCompany": null,
                            "uniqueCode": null,
                            "consentDateUpdate": "2024-03-19T00:00:00.000+00:00",
                            "privacy": true,
                            "cookie": true,
                            "termsConditions": true,
                            "source": "/extendedRegister",
                            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36",
                            "formCode": "",
                            "privacyInformation": "Termini e condizioni 16-01-2024 / Privacy Policy 16-01-2024 / Cookie Policy 16-01-2024",
                            "state": "WAIT_APPROVAL",
                            "role": "MASTER",
                            "stripeUser": null,
                            "stripeCustomer": null,
                            "publicPages": [],
                            "cap": ""
                        }
                    ]
                }
            ],
            "publicPages": [],
            "cap": ""
        },
        {
            "id": 80,
            "cdat": "2024-03-27T15:29:06.335+00:00",
            "udat": "2024-03-27T15:29:06.335+00:00",
            "username": null,
            "email": "riki.spam1@gmail.com",
            "password": "$2a$10$RTst02N9G/pHLqGWF5AU7.LnP3SRelhCmLG9jRtWmnrf5GlP4bNUC",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-03-27T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 81,
            "cdat": "2024-03-28T07:56:00.586+00:00",
            "udat": "2024-03-28T07:56:00.586+00:00",
            "username": null,
            "email": "r.giacomazzi@anthillgroup.it",
            "password": "$2a$10$Ejc379N.cbvzZzaVFGL4uudZxvUJ5D5gotINjxmDQHVEyt6gVX2xO",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-03-28T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 82,
            "cdat": "2024-03-28T08:33:13.796+00:00",
            "udat": "2024-03-28T08:33:13.796+00:00",
            "username": null,
            "email": "r.giacomazzi@giacomazzi-re.it",
            "password": "$2a$10$zEJSGYAvuv2zwk6w/TiGzOsIQmR1FMPg7.d6hlPfWr/iOx2.vQHuy",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-03-28T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "CONDUCTOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 83,
            "cdat": "2024-03-28T09:18:01.862+00:00",
            "udat": "2024-03-28T09:18:01.862+00:00",
            "username": null,
            "email": "jitij61375@nimadir.com",
            "password": "$2a$10$.pFyiIPnBcRjJqbWd4kO3eqS5uFQKmzFlP0jhNl0PQXFHpQEm4NCS",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-03-28T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 84,
            "cdat": "2024-03-29T08:32:13.419+00:00",
            "udat": "2024-03-29T08:32:13.419+00:00",
            "username": null,
            "email": "volipak255@mnsaf.com",
            "password": "$2a$10$VRC6t8wX2rFK5fb97ca3Ney0y8K4CwB4RdCOzNTjdvKZfQiTQhr6e",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": 4,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-03-29T00:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 85,
            "cdat": "2024-04-02T08:01:19.101+00:00",
            "udat": "2024-04-02T08:01:19.101+00:00",
            "username": "test-321",
            "email": "kogopik319@dacgu.com",
            "password": "$2a$10$0k5QVD7Pt0JeCVfPMpgWz.hDATpRParM96t926nXNZTwyJJAAR1q6",
            "phone": "+393407306650",
            "name": "Emma",
            "surname": "Viglia",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": null,
            "birthday": "1996-02-09T00:00:00.000+00:00",
            "address": "Via Domenico Enrico dall'Orto",
            "houseNumber": "5",
            "province": "Arezzo",
            "municipality": "Bibbiena",
            "documentType": "patente",
            "issueEntity": "Comune di genova",
            "issueDate": "2024-04-16T23:00:00.000+00:00",
            "documentNumber": "12345",
            "issuePlace": "Genova",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-01T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 16-01-2024 / Privacy Policy 16-01-2024 / Cookie Policy 16-01-2024",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "873636"
        },
        {
            "id": 86,
            "cdat": "2024-04-02T08:58:56.979+00:00",
            "udat": "2024-04-02T08:58:56.979+00:00",
            "username": "gonica8573@dacgu.com",
            "email": "gonica8573@dacgu.com",
            "password": "$2a$10$4xriW2n7ve1bxYGKox/tQuVfS07iJeByVC6yWeddqa1xwuocR3pBq",
            "phone": "+3407306650",
            "name": "EMA",
            "surname": "MAVI",
            "fiscalCode": "MVGMNL96D64D969P",
            "genre": null,
            "birthday": "2024-03-31T23:00:00.000+00:00",
            "address": "Via Domenico Enrico dall'Orto",
            "houseNumber": "4",
            "province": "Genova",
            "municipality": "Coreglia Ligure",
            "documentType": "patente",
            "issueEntity": "COMUNE",
            "issueDate": "2024-04-25T23:00:00.000+00:00",
            "documentNumber": "AT6775",
            "issuePlace": "GENOVA",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-01T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 16-01-2024 / Privacy Policy 16-01-2024 / Cookie Policy 16-01-2024",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16142"
        },
        {
            "id": 87,
            "cdat": "2024-04-02T10:23:04.833+00:00",
            "udat": "2024-04-02T10:23:04.833+00:00",
            "username": null,
            "email": "tabexow808@felibg.com",
            "password": "$2a$10$wtjVW35qvMDcj9/jKiv0HuYRSApqeS1mJzzXGluqleUuF70vMGpEC",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": 3,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-01T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 88,
            "cdat": "2024-04-03T07:52:40.914+00:00",
            "udat": "2024-04-05T09:18:48.558+00:00",
            "username": "emanuelamaviglia",
            "email": "moxedo6841@acentni.com",
            "password": "$2a$10$7VdSHvI6FwBDPkjJEjJFFuyApyWDttFGn4M5eO6UOPzA/eh9h3Imi",
            "phone": "3407306650",
            "name": "Emanuela",
            "surname": "Maviglia",
            "fiscalCode": "MVGMNL86D64D969P",
            "genre": "F",
            "birthday": "2024-04-10T23:00:00.000+00:00",
            "address": "Via Domenico Enrico dall'Orto",
            "houseNumber": "Via Domenico Enrico dall'Orto",
            "province": "Genova",
            "municipality": "Genova",
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-02T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "SUPPLIER",
            "stripeUser": "acct_1P28vB9F4C8Pl475",
            "stripeCustomer": null,
            "accountingResources": [
                {
                    "id": 14,
                    "cdat": "2024-04-05T09:17:50.652+00:00",
                    "udat": null,
                    "userID": 88,
                    "name": "Emanuela Maviglia BNL",
                    "accountingType": "conto corrente bancario",
                    "openingBalance": 0.0,
                    "iban": "IT39E27737927972JDJDJD000M",
                    "province": "GE",
                    "ownBy": "",
                    "abi": "",
                    "cabi": "",
                    "conto": "",
                    "cin": "",
                    "bank": "",
                    "sia": "",
                    "municipality": "",
                    "cucSepa": "",
                    "bic": "",
                    "users": [
                        {
                            "id": 88,
                            "cdat": "2024-04-03T07:52:40.914+00:00",
                            "udat": "2024-04-05T09:18:48.558+00:00",
                            "username": "emanuelamaviglia",
                            "email": "moxedo6841@acentni.com",
                            "password": "$2a$10$7VdSHvI6FwBDPkjJEjJFFuyApyWDttFGn4M5eO6UOPzA/eh9h3Imi",
                            "phone": "3407306650",
                            "name": "Emanuela",
                            "surname": "Maviglia",
                            "fiscalCode": "MVGMNL86D64D969P",
                            "genre": "F",
                            "birthday": "2024-04-10T23:00:00.000+00:00",
                            "address": "Via Domenico Enrico dall'Orto",
                            "houseNumber": "Via Domenico Enrico dall'Orto",
                            "province": "Genova",
                            "municipality": "Genova",
                            "documentType": null,
                            "issueEntity": null,
                            "issueDate": null,
                            "documentNumber": null,
                            "issuePlace": null,
                            "documentFile": null,
                            "faceFile": null,
                            "rootAccountID": null,
                            "supplierAccountIDs": null,
                            "businessName": null,
                            "businessMunicipality": null,
                            "businessProvince": null,
                            "businessAddress": null,
                            "businessHouseNumber": null,
                            "businessCap": null,
                            "numberOfEmployees": null,
                            "companyRole": null,
                            "vatNumber": null,
                            "isCompany": null,
                            "uniqueCode": null,
                            "consentDateUpdate": "2024-04-02T23:00:00.000+00:00",
                            "privacy": true,
                            "cookie": true,
                            "termsConditions": true,
                            "source": "/reducedRegister",
                            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
                            "formCode": "",
                            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
                            "state": "WAIT_APPROVAL",
                            "role": "SUPPLIER",
                            "stripeUser": "acct_1P28vB9F4C8Pl475",
                            "stripeCustomer": null,
                            "publicPages": [],
                            "cap": "16142"
                        }
                    ]
                }
            ],
            "publicPages": [],
            "cap": "16142"
        },
        {
            "id": 89,
            "cdat": "2024-04-03T14:22:37.618+00:00",
            "udat": "2024-04-03T14:22:37.618+00:00",
            "username": null,
            "email": "pesara8488@dacgu.com",
            "password": "$2a$10$if2zqnPOrp2tDWkgDNTnyuBr.O4Hog/9UzgYrOGuUJu6jmQrrNcQ.",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-02T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "SUPPLIER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 90,
            "cdat": "2024-04-05T14:24:33.573+00:00",
            "udat": "2024-04-05T14:24:33.573+00:00",
            "username": null,
            "email": "fiheha6044@acentni.com",
            "password": "$2a$10$FxhrsRuPaTkJhniDGKndjOjca4ululGGpV7uBLWSSYAXYK8UJiPYS",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-04T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "CONDUCTOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 91,
            "cdat": "2024-04-06T07:49:44.258+00:00",
            "udat": "2024-04-06T07:49:44.258+00:00",
            "username": null,
            "email": "mefoj94068@dacgu.com",
            "password": "$2a$10$TbgbEAaYfPPPL9twb4noGOKhpfBa4mUju.PGYHNlHJgiWNvuEziT.",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-05T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 92,
            "cdat": "2024-04-06T13:06:13.852+00:00",
            "udat": "2024-04-06T13:06:13.852+00:00",
            "username": null,
            "email": "kowetok672@ekposta.com",
            "password": "$2a$10$.PCFVIqlIlJ6jkazNwJoMOCB0SQ8CI2BupebKb0D5nd8Rfe6da7Q2",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-05T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 93,
            "cdat": "2024-04-19T10:17:24.772+00:00",
            "udat": "2024-06-26T09:42:32.816+00:00",
            "username": "Marco Maniscalco",
            "email": "maniscalco.marco@gmail.com",
            "password": "$2a$10$xQbyUXwWz3Z6wF0KOfZGiuk17KAH2wOggJkMbxXSBnWZnkc0oXJhy",
            "phone": "+333455782",
            "name": "Marco",
            "surname": "Maniscalco",
            "fiscalCode": "MNSMRC93D12D969X",
            "genre": null,
            "birthday": "1993-04-11T23:00:00.000+00:00",
            "address": "Via Ausonia",
            "houseNumber": "27/4",
            "province": "",
            "municipality": "",
            "documentType": "",
            "issueEntity": "",
            "issueDate": null,
            "documentNumber": "",
            "issuePlace": "",
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-18T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/extendedRegister",
            "browserInformation": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 16-01-2024 / Privacy Policy 16-01-2024 / Cookie Policy 16-01-2024",
            "state": "APPROVED",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": "16137"
        },
        {
            "id": 94,
            "cdat": "2024-04-22T07:18:32.968+00:00",
            "udat": "2024-04-22T07:18:32.968+00:00",
            "username": null,
            "email": "bojev28569@iliken.com",
            "password": "$2a$10$KhOBpnbAd4nnqAKWCCL8CenoLK5vd2EjpRUac.bc8zzbsYJPSJQ5a",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-21T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 95,
            "cdat": "2024-04-22T07:25:15.682+00:00",
            "udat": "2024-04-22T07:25:15.682+00:00",
            "username": null,
            "email": "dehepex718@etopys.com",
            "password": "$2a$10$Kx1M2H4.VWMG98BisLgzl.UTcU0k60NCYP8qGzV3UdmX8yrVgFFum",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-21T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 96,
            "cdat": "2024-04-23T13:32:28.587+00:00",
            "udat": "2024-04-23T13:32:28.587+00:00",
            "username": null,
            "email": "hosegi6666@buzblox.com",
            "password": "$2a$10$JuByAJ19KWaRqCXMCAKC6.kiKd1wYwEP5pRFf0OkzicdYeXW27vnG",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": 2255,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-22T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 97,
            "cdat": "2024-04-23T13:40:11.520+00:00",
            "udat": "2024-04-23T13:40:11.520+00:00",
            "username": null,
            "email": "leler42461@funvane.com",
            "password": "$2a$10$P4Rwij65mx2FFGqMtYjBPOdF95Wk7BYV9lSWRvvym1tRA3IeOdPIe",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-22T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 98,
            "cdat": "2024-04-23T13:49:47.719+00:00",
            "udat": "2024-04-23T13:49:47.719+00:00",
            "username": null,
            "email": "bakit41710@picdv.com",
            "password": "$2a$10$00MfpV7o6.amJ1wd6MYU8elSxoXqFsc0OzidpE3NykgRHdWBxjymi",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-22T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 99,
            "cdat": "2024-04-24T09:09:28.264+00:00",
            "udat": "2024-04-24T09:09:28.264+00:00",
            "username": null,
            "email": "bixok35297@picdv.com",
            "password": "$2a$10$nOuHUNAnyhStzWtnNSlETueEXMiTvEL7KVIskEzabYPbpekgEw3/O",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-04-23T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 100,
            "cdat": "2024-05-13T07:46:20.901+00:00",
            "udat": "2024-05-13T07:46:20.901+00:00",
            "username": null,
            "email": "hebis96080@bsomek.com",
            "password": "$2a$10$/cQEu.0Mn7NCXQ2zzm1nO.B7plu4CA66E4SIPqaorjqBqACNC3LVa",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-05-12T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 101,
            "cdat": "2024-05-13T07:51:42.619+00:00",
            "udat": "2024-05-13T07:51:42.619+00:00",
            "username": null,
            "email": "mexar44384@nweal.com",
            "password": "$2a$10$BJa2yGVwjHOBzSrbXLYNU.oaHuGww.KUyR6FwNUV2SEYVXcozLx8u",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-05-12T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "MASTER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 102,
            "cdat": "2024-05-13T08:42:13.807+00:00",
            "udat": "2024-05-13T08:42:13.807+00:00",
            "username": null,
            "email": "javodi2678@ahieh.com",
            "password": "$2a$10$OIS.nGJe6z65RhMkDhQU2.nsAMlSu8ogPlp8/POQx93o/0.PZ04Gy",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-05-12T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4 Safari/605.1.15",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "COLLABORATOR",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        },
        {
            "id": 103,
            "cdat": "2024-05-13T08:56:14.887+00:00",
            "udat": "2024-05-13T08:56:14.887+00:00",
            "username": null,
            "email": "biwen27010@facais.com",
            "password": "$2a$10$NFIp4y3rw4t/JzLR0knk/uJelsuMQZSISzoHOoX2I2kXXT/SWRtiK",
            "phone": null,
            "name": "",
            "surname": null,
            "fiscalCode": null,
            "genre": null,
            "birthday": null,
            "address": null,
            "houseNumber": null,
            "province": null,
            "municipality": null,
            "documentType": null,
            "issueEntity": null,
            "issueDate": null,
            "documentNumber": null,
            "issuePlace": null,
            "documentFile": null,
            "faceFile": null,
            "rootAccountID": null,
            "supplierAccountIDs": null,
            "businessName": null,
            "businessMunicipality": null,
            "businessProvince": null,
            "businessAddress": null,
            "businessHouseNumber": null,
            "businessCap": null,
            "numberOfEmployees": null,
            "companyRole": null,
            "vatNumber": null,
            "isCompany": null,
            "uniqueCode": null,
            "consentDateUpdate": "2024-05-12T23:00:00.000+00:00",
            "privacy": true,
            "cookie": true,
            "termsConditions": true,
            "source": "/reducedRegister",
            "browserInformation": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
            "formCode": "",
            "privacyInformation": "Termini e condizioni 01-09-2023 / Privacy Policy 01-09-2023 / Cookie Policy 01-09-2023",
            "state": "WAIT_APPROVAL",
            "role": "OWNER",
            "stripeUser": null,
            "stripeCustomer": null,
            "accountingResources": [],
            "publicPages": [],
            "cap": null
        }
    ]

    const suppliers = users? users.filter(el => el.role == "SUPPLIER") : []
    const owners = users? users.filter(el => el.role == "OWNER") : []
    const conductors = users? users.filter(el => el.role == "CONDUCTOR") : []
    const masters = users? users.filter(el => el.role == "MASTER") : []
    const masters_pro = users? users.filter(el => el.role == "MASTER_PRO") : []
    const collaborators = users? users.filter(el => el.role == "COLLABORATOR") : []

    const mastersArray = [...masters, masters_pro]

    const mappingMasters = {}

    mastersArray.forEach(el => {
        mappingMasters[el.id] = el.email
    })

    console.log(mappingMasters)


    const data = [
        { id: 0, value: masters_pro?.length, label: 'Masters Pro' },
        { id: 1, value: masters?.length, label: 'Masters' },
        { id: 2, value: collaborators?.length, label: 'Collaborators' },
        { id: 3, value: owners?.length, label: 'Owners' },
        { id: 4, value: conductors?.length, label: 'Conductors' },
        { id: 5, value: suppliers?.length, label: 'Suppliers' },
    ];


    const [view, setView] = useState('tree');

    const handleChange = (event, newView) => {
        setView(newView);
    };


    const [all, setAll] = useState([
        {
            "master_id": 1,
            "team": []
        },
        {
            "master_id": 2,
            "team": []
        },
        {
            "master_id": 5,
            "team": []
        },
        {
            "master_id": 6,
            "team": []
        },
        {
            "master_id": 7,
            "team": []
        },
        {
            "master_id": 40,
            "team": []
        },
        {
            "master_id": 41,
            "team": []
        },
        {
            "master_id": 42,
            "team": []
        },
        {
            "master_id": 43,
            "team": []
        },
        {
            "master_id": 44,
            "team": []
        },
        {
            "master_id": 45,
            "team": []
        },
        {
            "master_id": 46,
            "team": []
        },
        {
            "master_id": 47,
            "team": []
        },
        {
            "master_id": 48,
            "team": []
        },
        {
            "master_id": 49,
            "team": []
        },
        {
            "master_id": 50,
            "team": []
        },
        {
            "master_id": 51,
            "team": []
        },
        {
            "master_id": 52,
            "team": []
        },
        {
            "master_id": 57,
            "team": []
        },
        {
            "master_id": 61,
            "team": []
        },
        {
            "master_id": 83,
            "team": [
                {
                    "id": 4,
                    "name": "Demo",
                    "surname": "Demo",
                    "associationId": 83,
                    "chatRole": "SUPPLIER",
                    "email": "demorealmeta@gmail.com",
                    "teamNumber": 83,
                    "originalRole": "SUPPLIER",
                    "down": true
                }
            ]
        },
        {
            "master_id": 84,
            "team": [
                {
                    "id": 4,
                    "name": "Demo",
                    "surname": "Demo",
                    "associationId": 84,
                    "chatRole": "SUPPLIER",
                    "email": "demorealmeta@gmail.com",
                    "teamNumber": 84,
                    "originalRole": "SUPPLIER",
                    "down": true
                },
                {
                    "id": 3,
                    "name": "",
                    "surname": "",
                    "associationId": 84,
                    "chatRole": "SUPPLIER",
                    "email": "realmetatest1@gmail.com",
                    "teamNumber": 84,
                    "originalRole": "SUPPLIER",
                    "down": true
                }
            ]
        },
        {
            "master_id": 86,
            "team": [
                {
                    "id": 4,
                    "name": "Demo",
                    "surname": "Demo",
                    "associationId": 86,
                    "chatRole": "SUPPLIER",
                    "email": "demorealmeta@gmail.com",
                    "teamNumber": 86,
                    "originalRole": "SUPPLIER",
                    "down": true
                },
                {
                    "id": 87,
                    "name": "",
                    "surname": null,
                    "associationId": 86,
                    "chatRole": "COLLABORATOR",
                    "email": "yerowi6499@noefa.com",
                    "teamNumber": 86,
                    "originalRole": "COLLABORATOR",
                    "down": true
                },
                {
                    "id": 3,
                    "name": "",
                    "surname": "",
                    "associationId": 86,
                    "chatRole": "SUPPLIER",
                    "email": "realmetatest1@gmail.com",
                    "teamNumber": 86,
                    "originalRole": "SUPPLIER",
                    "down": true
                },
                {
                    "id": 67,
                    "name": "",
                    "surname": null,
                    "associationId": 86,
                    "chatRole": "SUPPLIER",
                    "email": "vahidxv@gmail.com",
                    "teamNumber": 86,
                    "originalRole": "SUPPLIER",
                    "down": true
                },
                {
                    "id": 67,
                    "name": "",
                    "surname": null,
                    "associationId": 87,
                    "chatRole": "SUPPLIER",
                    "email": "vahidxv@gmail.com",
                    "teamNumber": 86,
                    "originalRole": "SUPPLIER",
                    "down": true
                },
                {
                    "id": 69,
                    "name": "",
                    "surname": null,
                    "associationId": 86,
                    "chatRole": "SUPPLIER",
                    "email": "arashalghasidotnet@gmail.com",
                    "teamNumber": 86,
                    "originalRole": "SUPPLIER",
                    "down": true
                }
            ]
        }
    ])


    
      //GET LIST 
      async function getAll() {
          try {
              const response = await api().get(`${'/api/v1/invitation/getTeamAll'}`);
              if (response.data ) {
                setAll(response.data);
              }
          } catch (err) {
              console.log(err);
          }
      }
    
      useEffect(() => {
        getAll()
      }, [])
    
      console.log(manageTeamData(all))
    
      function manageTeamData(teams){
        let newTeam = teams.length > 0 && teams.map(el => {
          return {
            name: el.master_id, //DEVO CREARE DIZIONARIO USANDO GETTEAM ALL E GET USERS PER PRENDERE NOME O EMAIL DEL MASTER => ID: USER.EMAIL
            children: [
              {
                name: "PROPRIETARI",
                children: el.team.length > 0 ?
                  el.team.filter(item => item.chatRole == "OWNER").length > 0?
                    el.team.filter(item => item.chatRole == "OWNER").map(user => {
                      return {
                        name: user.email
                      }
                    }) :
                    []
                  
                : []
    
              },
              {
                name: "CONDUTTORI",
                children: el.team.length > 0 ?
                  el.team.filter(item => item.chatRole == "CONDUCTOR").length > 0?
                    el.team.filter(item => item.chatRole == "CONDUCTOR").map(user => {
                      return {
                        name: user.email
                      }
                    }) :
                    []
                  
                : []
    
              },
              {
                name: "FORNITORI",
                children: el.team.length > 0 ?
                  el.team.filter(item => item.chatRole == "SUPPLIER").length > 0?
                    el.team.filter(item => item.chatRole == "SUPPLIER").map(user => {
                      return {
                        name: user.email
                      }
                    }) :
                    []
                  
                : []
    
              },
              {
                name: "COLLABORATORI",
                children: el.team.length > 0 ?
                  el.team.filter(item => item.chatRole == "COLLABORATOR").length > 0?
                    el.team.filter(item => item.chatRole == "COLLABORATOR").map(user => {
                      return {
                        name: user.email
                      }
                    }) :
                    []
                  
                : []
    
              },
            ]
          }
        }) 
    
        return newTeam
      }
    
      const orgChart = {
      name: 'MASTER',
      children: manageTeamData(all),
    };


    return(

        <>
        <Row>
                    <p className="fs-18 text-dark fw-bold">Utenti</p>
                    <p className="fs-14 opacity-75">Dettagli utenti RM</p>
                    <hr className="opacity-10 my-3"/>
                </Row>
                <Row className="mt-2">
                    <p className="fw-bold fs-20">Ripartizione Utenti</p>
                    <p>Totale utenti: {users?.length}</p>
                    <PieChart
                        series={[
                            {
                            data,
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                        ]}
                        height={200}
                    />
                </Row> 
                <hr className="my-4"/>
                <Row className="mt-2">
                    <p className="fw-bold fs-20">Dipendenze</p>
                    <div className="mt-3 mb-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={view}
                        exclusive
                        onChange={handleChange}
                        style={{padding: 0, margin: 0, paddingLeft:0, paddingRight:0}}
                        >
                        <ToggleButton value="tree">Albero</ToggleButton>
                        <ToggleButton value="list">Lista</ToggleButton>
                    </ToggleButtonGroup>
                    </div>
                    {view == "tree" && <TreeRm manageTeamData={manageTeamData} all={all} mappingMasters={mappingMasters} />}
                    {view == "list"  && <List manageTeamData={manageTeamData} all={all} mappingMasters={mappingMasters}  />}
                </Row> 
        </>

        
    )
}