import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

import CustomTooltip from "../../../CustomTooltip";
import CustomToggle from "../../../CustomToggle";

import CustomBadge from "../../../CustomBadge";


import { marketRefType, 
        bareOwnershipType, 
        leaseProfitabilityType, 
        rentalStateType, 
        wineryType,
        parkingAccessibilityType,
        boxType,
        boxAccessibilityType,
        boxLocationType,
        possessionType,
        condominialParkingSpaceType, 
        condominialParkingType,
        floorType, 
        ceilingHeightType,
        brightnessType,
        noisinessType,
        internalLevelsType,
        internalDistributionType,
        coveredMqRoomsRatioType,
        coveredMqBathsRatioType,
        finishingQualityType,
        status_type,
        energeticClassType,
        buildingAgeType,
        buildingLocationType,
        buildingTypologyType,
        building_status_type,
        heatingTypeType,
        office_position,
        shopFloor,
        shopVisibility,
        buildingTypologyTypeNegozi,
        windowPositionType,
        commercialVisibilityType,
        boxTypeNegozi

    } from "./options";


export default function Negozi (props) {
  console.log(props.formValues, "FORM VALUES NEW")

    return (
        <>
        {/* MQ COPERTI */}
        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="4" activeKey={props.activeKey}>Superifici coperte</CustomToggle>
            </Card.Header>
            <Accordion.Body>
              <div className="row mt-1">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq coperti <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="grossCoveredMq" valMin={0} valMax={1} />
                    </label>
                    <input
                      type="text"
                      name="grossCoveredMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => props.handleValueChange("grossCoveredMq", 'value', e.target.value)}
                      value={props.formValues["grossCoveredMq"].value}
                    />
                  </div>
                </div>
            
              </div>
            </Accordion.Body>
        </Accordion.Item>
                
         {/* MQ SCOPERTI */}
         <Accordion.Item eventKey="11" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="11" activeKey={props.activeKey}>Superfici scoperte</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="row">
                <div className="col-md-6">
                <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq balconi 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="balconyMq" valMin={0} valMax={1} />
                    </label>
                    <input
                    type="number"
                    name="balconyMq"
                    onChange={(e) => props.handleValueChange("balconyMq", 'value', e.target.value)}
                    value={props.formValues["balconyMq"].value}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq terrazzi 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="terraceMq" valMin={0} valMax={1} />
                  </label>
                  <input
                    type="number"
                    name="terraceMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                    onChange={(e) => props.handleValueChange("terraceMq", 'value', e.target.value)}
                    value={props.formValues["terraceMq"].value}
                  />
                </div>
              </div>
            </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq giardini o cortili                     
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="gardenMq" valMin={0} valMax={1} />

                    </label>
                    <input
                      type="text"
                      name="gardenMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("gardenMq", 'value', e.target.value)}
                      value={props.formValues["gardenMq"].value}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq loggie o patii 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="lodgeMq" valMin={0} valMax={1} />

                    </label>
                    <input
                      type="text"
                       name="lodgeMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("lodgeMq", 'value', e.target.value)}
                    value={props.formValues["lodgeMq"].value}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq lastrico solare                       
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="solarPavementMq" valMin={0} valMax={1} />
                    </label>
                    <input
                      type="number"
                      name="solarPavementMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("solarPavementMq", 'value', e.target.value)}
                    value={props.formValues["solarPavementMq"].value}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Altri mq scoperti 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="otherUncoveredMq" valMin={0} valMax={1} />
                    </label>
                    <input
                      type="number"
                      name="otherUncoveredMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("otherUncoveredMq", 'value', e.target.value)}
                      value={props.formValues["otherUncoveredMq"].value}
                    />
                  </div>
                </div>
              </div>
          </Accordion.Body>
        </Accordion.Item>
        
        {/* CARATTERISTICHE COSTRUTTIVE */}
        <Accordion.Item eventKey="16" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="16" activeKey={props.activeKey}>Caratteristiche costruttive</CustomToggle>
            </Card.Header>
            <Accordion.Body>
              <div className="row mt-1">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano principale 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="floor" valMin={-0.1} valMax={0.1} />
                  </label>
                  <select
                    name="floor"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    onChange={(e) => props.handleValueChange("floor", 'value', e.target.value)}
                    value={props.formValues["floor"].value}
                  >
                    {shopFloor.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipologia edificio
                  </label>
                  <select
                    name="buildingTypology"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={props.formData.buildingTypology}
                    onChange={props.onChange}
                  >
                    {buildingTypologyTypeNegozi.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              </div>
          </Accordion.Body>
        </Accordion.Item>
        
        {/* VISIBILITA' COMMERCIALE */}
        <Accordion.Item eventKey="17" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="17" activeKey={props.activeKey}>Visibilità commerciale</CustomToggle>
            </Card.Header>
            <Accordion.Body>
              <div className="row mt-1">
              <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Posizione negozio
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="positionShops" valMin={-0.1} valMax={0.1} />

                    </label>
                    <select
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      onChange={(e) => props.handleValueChange("positionShops", 'value', e.target.value)}
                      value={props.formValues["positionShops"].value}
                      name="positionShops"
                    >
                      {shopVisibility.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {capitalizeFirstLetter(menu)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Posizione vetrine
                    </label>
                    <select
                      name="windowPosition"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      value={props.formData.windowPosition}
                      onChange={props.onChange}
                    >
                      {windowPositionType.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {capitalizeFirstLetter(menu)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Visibilità commerciale del negozio
                    </label>
                    <select
                      name="commercialVisibility"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      value={props.formData.commercialVisibility}
                      onChange={props.onChange}
                    >
                      {commercialVisibilityType.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {capitalizeFirstLetter(menu)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </Accordion.Body>
        </Accordion.Item>

        {/* BOX */}
        <Accordion.Item eventKey="18" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="18" activeKey={props.activeKey}>Posto auto</CustomToggle>
            </Card.Header>
            <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Posto auto coperto 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="coveredParkingSpace" valMin={0} valMax={1} />

                </label>
                <select
                  name="coveredParkingSpace"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("coveredParkingSpace", 'value', e.target.value)}
                    value={props.formValues["coveredParkingSpace"]?.value}
                >
                  {boxTypeNegozi.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq posto coperto 
                    </label>
                    <input
                      type="text"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => {
                        props.onChange(e);
                      }}
                      value={props.formData?.coveredParkingSpaceMq}
                      name="coveredParkingSpaceMq"

                    />
              </div>
            </div>
            
         
          </div>

          <div className="row mt-3">
           
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Posto auto scoperto 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="uncoveredParkingSpace" valMin={0} valMax={1} />

                </label>
                <select
                  name="uncoveredParkingSpace"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("uncoveredParkingSpace", 'value', e.target.value)}
                    value={props.formValues["uncoveredParkingSpace"]?.value}
                >
                  {boxTypeNegozi.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq posto scoperto 
                    </label>
                    <input
                      type="text"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => {
                        props.onChange(e);
                      }}
                      value={props.formData?.uncoveredParkingSpaceMq}
                      name="uncoveredParkingSpaceMq"

                    />
              </div>
            </div>
          </div>
          </Accordion.Body>
        </Accordion.Item>
        
        {/* ALTRE CARATTERISTICHE */}
        <Accordion.Item eventKey="27" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="27" activeKey={props.activeKey}>Altre caratteristiche</CustomToggle>
            </Card.Header>
              <Accordion.Body>
                <div className="row mt-1">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Altezza soffitti 
                        <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="ceilingHeight" valMin={-0.03} valMax={0.03} />
                      </label>
                      <select
                        name="ceilingHeight"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        onChange={(e) => props.handleValueChange("ceilingHeight", 'value', e.target.value)}
                   value={props.formValues["ceilingHeight"]?.value}
                      >
                        {ceilingHeightType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {capitalizeFirstLetter(menu)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Stato conservativo interno 
                        <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="internalState" valMin={-0.03} valMax={0.03} />

                      </label>
                      <select
                        name="internalState"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        onChange={(e) => props.handleValueChange("internalState", 'value', e.target.value)}
                        value={props.formValues["internalState"]?.value}
                      >
                        {status_type.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {capitalizeFirstLetter(menu)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="input-checkbox mt-3">
                  <input
                    type="checkbox"
                    name="lift"
                    checked={props.formData.lift}
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                  />
                  <label className="f-label fs-16 d-flex align-items-center
me-2">Ascensore 
                  </label>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Tipo di riscaldamento 
                        <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="heater" valMin={-0.05} valMax={0.05} />
                      </label>
                      <select
                        name="heater"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    onChange={(e) => props.handleValueChange("heater", 'value', e.target.value)}
                    value={props.formValues["heater"]?.value}
                      >
                        {heatingTypeType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {capitalizeFirstLetter(menu)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Classe energetica 
                        <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}  name="energeticClass" valMin={-0.03} valMax={0.03} />
                      </label>
                      <select
                        name="energeticClass"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        onChange={(e) => props.handleValueChange("energeticClass", 'value', e.target.value)}
                    value={props.formValues["energeticClass"]?.value}
                      >
                        {energeticClassType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
          </Accordion.Item>
      
        
        {/* DATI EDIFICIO */}
        <Accordion.Item eventKey="28" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="28" activeKey={props.activeKey}>Dati edificio</CustomToggle>
            </Card.Header>
              <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Età edificio             
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}  name="buildingAge" valMin={-0.05} valMax={0.05} />
                </label>
                <select
                  name="buildingAge"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("buildingAge", 'value', e.target.value)}
                    value={props.formValues["buildingAge"]?.value}
                >
                  {buildingAgeType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {menu}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Stato conservazione edificio 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}  name="buildingState" valMin={-0.05} valMax={0.05} />
                </label>
                <select
                  name="buildingState"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("buildingState", 'value', e.target.value)}
                  value={props.formValues["buildingState"]?.value}
                >
                  {building_status_type.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Totale piani edificio
                </label>
                <input
                  type="text"
                  name="totalFloorNumber"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    props.onChange(e);
                  }}
                  value={props.formData.totalFloorNumber}
                />
              </div>
            </div>
          </div>
          </Accordion.Body>
        </Accordion.Item>
        

        </>
    )
}