import { useState, useEffect } from "react";
import { BsPrinter, BsPencil, BsDownload, BsTrash } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import ButtonRm from "../../ButtonRm";
import { Link } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import CustomBadge from "../../CustomBadge";


const TariffeModal = (props) => {

    //VISIONE TABELLA
    const [tariffeList, setTariffeList] = useState([])
    //CREAZIONE-MODIFICA-ELIMINA TARIFFA
	const [tariffa, setTariffa] = useState({});

    const [tableView, setTableView] = useState(true)

    const [newMode, setNewMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [viewMode, setViewMode] = useState(false)

    //TITOLO MODALE
    const [title, setTitle] = useState("")

    useEffect(() => {
        if(props.type == "gasPlan"){
            setTariffeList(props.formData.gasPlan)
            setTitle("gas")
        } else if(props.type == "coldWaterPlan") {
            setTariffeList(props.formData.coldWaterPlan)
            setTitle("acqua")
        } else if(props.type == "electricityPlan"){
            setTariffeList(props.formData.electricityPlan)
            setTitle("elettricità")
        }    
    }, [props.formData.gasPlan, props.formData.coldWaterPlan, props.formData.electricityPlan ])


	const init = () => {
			setTariffa({
				type: props.type,
                name:"",
                fixedCost:"",
                variableCost1:"",
                variableCost2:"",
                variableCost3:"",

			});
		}


	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		const {name, value} = e.target
		setTariffa({ ...tariffa, [name]: value });
	};


    //ADD LETTURA

    const addTariffa = (tariffa) => {
        let temp = [...tariffeList];
        temp.push(tariffa);

        //IMPOSTA IN FORMDATA IL NOME CORRETTO COME TEMP
        props.setFormData({ ...props.formData, [props.type]: temp }); 
        //TORNA ALLA TABELLA

        setNewMode(false)
        setTableView(true)
        init()
   }; 

   console.log(props.formData?.[props.type])
   console.log(tariffa, tariffeList)

   //VIEW E EDIT LETTURA
   const [tariffaEdit, setTariffaEdit] = useState(-1); //ID EL EDITED


 
   function closeSelectedTariffa(){
        init()
       setTariffaEdit(null)
       setViewMode(false)
       setEditMode(false)
       setTableView(true)
   }

   function handleTariffa(id){
       setViewMode(true)
       setTableView(false)
       let current =  props.formData[props.type].find((item, index) => index == id)
       setTariffa(current)
       setTariffaEdit(id)
   }


   function updateTariffa (tariffa){
       let temp =  props.formData[props.type].map((item, index) => {
           return index == tariffaEdit?
           tariffa:
           item
       })
       props.setFormData({ ...props.formData, [props.type]: temp });
       closeSelectedTariffa()
   };

   const deleteTariffa = () => {
       let temp =  props.formData[props.type].filter((item, index) => index != tariffaEdit)
       props.setFormData({ ...props.formData, [props.type]: temp }); 
       closeSelectedTariffa()
   };  


   const handleDelete = () => {
    deleteTariffa()
    setViewMode(false)
    setTableView(true)
};

   function createView(e){
    e.preventDefault()
    setTableView(false)
    setNewMode(true)
    setViewMode(false)
    setEditMode(false)

   }

   function handleSave(){
    if(newMode){
        addTariffa(tariffa)
    }else if(editMode){
        updateTariffa(tariffa)
    }
   }


	return (
        <>
            {tableView && <div>
            <div className="fs-18 fw-semibold">
                Tariffe {title? title : ""}           
            </div>
            <div className="row py-2">
                    <Col md={12}>
                    <ButtonRm onClick={createView}>Nuovo</ButtonRm> 
                    </Col>
                </div>	

                <Row>
                    {tariffeList?.length> 0  ? (
                        <div className="mt-4">
                            <Table className="result-table table-border out-border-none">
                            <thead>
                                <tr>
                                <th>Nome</th>
                                <th>Costo Fisso</th>
                                <th>Costo a consumo</th>
                                </tr>
                            </thead>
                            <tbody>
                                { tariffeList.map((item, index) => {
                                return (
                                    <tr key={index} onClick={() => handleTariffa(index)}>
                                    <td>{item.name}</td>
                                    <td>€ {item.fixedCost}</td>
                                    <td>€ {item.variableCost1} {item.variableCost2? `- € ${item.variableCost2}` : ""} {item.variableCost3? `- € ${item.variableCost3}` : ""}</td>
                                    </tr>
                                );
                                })}
                            </tbody>
                            </Table>
                        </div>
                        ) : (
                        <div className="unit-empty-img">
                            <span className="img-comment fs-18">
                            Nessuna tariffa
                            </span>
                        </div>
                    )}
                    
                </Row>			
            </div>}

            {!tableView &&<div
                className={
                    editMode === undefined ? "mt-4" : "edit-content-container"
                }
                /* style={{maxHeight: "400px", overflowY: "auto", borderRadius:".5rem", overflowX:"hidden"}} */
                >
                {newMode ? (
                    <div className="fs-18 fw-semibold mb-3">Nuova tariffa</div>
                ) : (
                    <div className="d-flex justify-content-between">
                        <div className="fs-18 fw-semibold">
                            {editMode && "Modifica tariffa"} 
                            {viewMode && "Visualizza tariffa"}
                        </div>

                        <div className={`d-flex ${editMode ? "d-none" : ""}`}>
                            <button
                                type="button"
                                className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
                                <BsDownload color="gray" />
                            </button>
                            <button
                                type="button"
                                className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
                                onClick={() => {
                                    setEditMode(true);
                                    setViewMode(false);
                                }}>
                                <BsPencil color="gray" />
                            </button>
                            <button
                                type="button"
                                className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
                                <BsPrinter color="gray" />
                            </button>
                            <button
                                type="button"
                                className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40"
                                onClick={handleDelete}>
                                <BsTrash color="gray" />
                            </button>
                        </div>
                    </div>
                )}
                <div className="d-block">
                    <fieldset disabled={editMode || newMode ? false : true}>

                        <div className="row d-flex flex-column gy-2">

                            <div className="col-md-12">
                                <div className="d-flex flex-column">
                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                        Nome 
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                                        onChange={onChange}
                                        value={tariffa.name}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="d-flex flex-column">
                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                        Costo fisso mensile
                                    </label>
                                    <input
                                        type="number"
                                        name="fixedCost"
                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                                        onChange={onChange}
                                        value={tariffa.fixedCost}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="d-flex flex-column">
                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                        Costo a consumo 
                                    </label>
                                    <input
                                        type="number"
                                        name="variableCost1"
                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                                        onChange={onChange}
                                        value={tariffa.variableCost1}
                                    />
                                </div>
                            </div>

                            { props.type == "electricityPlan" &&
                                <>
                                <div className="col-md-12">
                                <div className="d-flex flex-column">
                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                        Costo a consumo 2
                                    </label>
                                    <input
                                        type="number"
                                        name="variableCost2"
                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                                        onChange={onChange}
                                        value={tariffa.variableCost2}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="d-flex flex-column">
                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                        Costo a consumo 3
                                    </label>
                                    <input
                                        type="number"
                                        name="variableCost3"
                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                                        onChange={onChange}
                                        value={tariffa.variableCost3}
                                    />
                                </div>
                            </div>
                            </>
                            }

                        </div>
                        
                    </fieldset>
                </div>

                {editMode || newMode ? (
                    
                        <div className="d-flex align-items-center mt-5 form-last-btns justify-content-center mb-4">
                        <ButtonRm variant="tertiary" onClick={() => {
                                    props.setShow(false);
                                }} className="me-2" >Annulla</ButtonRm>
                        <ButtonRm variant={"primary"} onClick={handleSave}
                        
                        >Salva</ButtonRm>
                    </div>
                ) : (
                    ""
                )}
            </div>}

        </>
	);
};

export default TariffeModal;
