import React, { useEffect, useState } from "react";
import axios from "axios";
import BuildingCard from "./BuildingCard";
import ButtonRm from "../../ButtonRm";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import CustomBadge from "../../CustomBadge";


const Related = (props) => {
	const similarStateType = [
		"Nuovo/in costruzione",
		"Ottimo/ristrutturato",
		"Buono/abitabile",
		"Da ristrutturare",
	];

	const type = [
		"appartamenti",
		"garage",
		"ville",
		"villette",
		"case",
		"capannoni",
		"uffici",
		"negozi",
		"attici",
		"loft",
		"terreni",
		"palazzi",
		"rustici"
	];

	const [similarData, setSimilarData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [hasSearched, setHasSearched] = useState(false)

	const [dealType, setDealType] = useState("vendita")

	const isNewConstruction = props?.formData?.internalState == "nuovo/in costruzione" ? "true" : "false"

	


	//se l'utente fa una ricerca diversa, svuota gli immobili precedenti
	useEffect(() => {
		var ele = { target: { name: "similiarBuildings", value: [] } };
		props.onChange(ele)
		setVisibleItems(8)
	}, [similarData])

	//TIPOLOGIA DEVE ESSERE SELECT => quindi chiedere a xia che tipologie possiamo inserire
	//Mancano campi per piano e stato imobile nell'endpoint
	//cosa fare se utente non compila un campo tipo surface? lasciare vuoto surface="" o omettere dall'endpoint totalmente


	//TO DO: spostare funzioni in una cartella utils ed importarle all'occorrenza

	function adressFormat(adress) {
		if(!adress){
			return ""
		}
		return adress.toLowerCase().replace(/\s+/g, '-');
	}

	function lowerCase(text){
		return text.toLowerCase()
	}

	const address = (props.formData.similarTypology == "appartamenti" || props.formData.similarTypology == "ville" || props.formData.similarTypology == "villette" ||  props.formData.similarTypology == "case" || props.formData.similarTypology == "uffici" || props.formData.similarTypology == "negozi" || props.formData.similarTypology == "attici" || props.formData.similarTypology == "loft") ? adressFormat(props.formData.similarStreet): ""

	const getData = async () => {
		setLoading(true);

		try {
			const response = await axios.get(
				`https://anthill-service.onrender.com/get_similar_real_estate_immobiliare?deal_type=${lowerCase(dealType)}&building_type=${lowerCase(props.formData.similarTypology)}&area=${props.formData.similarMq}&city=${lowerCase(props.formData.similarMunicipality)}&zona=${lowerCase(props.formData.similarZone)}&street=${address}&new_construction=${isNewConstruction}`
			);
			if(response.data.data){
			setSimilarData(response.data.data);
			} else {
				setSimilarData([]);
			}
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
		setHasSearched(true)

	};

	//RIMUOVI O AGGIUNGI IMMOBILE AL REPORT

	const addToReport = (item) => {
		var temp = [...props.formData.similiarBuildings];
		temp.push(JSON.stringify(item));
		var ele = { target: { name: "similiarBuildings", value: temp } };
		props.onChange(ele);
		console.log(props.formData.similiarBuildings);
	};

	const removeToReport = (item) => {
		var temp = [...props.formData.similiarBuildings];
		var compareData = JSON.stringify(item);
		for (var i = 0; i < temp.length; i++) {
			if (compareData===temp[i]) {
				temp.splice(i, 1);
				break;
			}
		}
		var ele = { target: { name: "similiarBuildings", value: temp } };
		props.onChange(ele);
	};

	console.log(props.formData.similiarBuildings, "immobili simili")


	//MOSTRA INIZIALMENTE AL MASSIMO 8 ELEMENTI

	const [visibleItems, setVisibleItems] = useState(8);

	const showMore = () => {
	  setVisibleItems(visibleItems + 8);
	};

	const renderButton = () => {
		if (similarData.length > 8) {
		  return (
			<ButtonRm variant="tertiary" onClick={showMore}>
			  Mostra altro
			</ButtonRm >
		  );
		}
		return null;
	  };
	

	return (
		<div className="form-item-align-box-white d-block">
			
				<div className="el d-inline-block px-2 title-padding">
					<h1 className="fs-16 text-dark text-uppercase">
						IMMOBILI IN VENDITA CORRELATI
					</h1>
				</div>
			<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Comune
						</label>
						<input
							type="text"
							name="similarMunicipality"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							onChange={(e) => {
								props.onChange(e);
							}}
							value={props.formData.similarMunicipality}
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Zona
						</label>
						<input
							type="text"
							name="similarZone"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							onChange={(e) => {
								props.onChange(e);
							}}
							value={props.formData.similarZone}
						/>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Indirizzo
						</label>
						<input
							type="text"
							name="similarStreet"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
							value={props.formData.similarStreet}
							onChange={props.onChange} />
							
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Tipologia
						</label>
						<select
							name="similarTypology"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
							value={props.formData.similarTypology}
							onChange={props.onChange}>
																	<option>Seleziona</option>

							{type.map((menu, index) => {
								return (
									<>
									<option key={index} value={menu}>
										{capitalizeFirstLetter(menu)}
									</option>
									</>
								);
							})}
						</select>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Mq
						</label>
						<input
							type="number"
							name="similarMq"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							onChange={(e) => {
								props.onChange(e);
							}}
							value={props.formData.similarMq}
						/>
					</div>
				</div>
			</div>
			<label
				className="bag-btn w-160 general-btn mt-3"
				style={{ height: "48px", textAlign: "center", cursor: "pointer" }}
				onClick={getData}>
				Trova Immobili
			</label>
			<div className="mt-3">
      {loading && <p>Caricamento...</p>}
      {(similarData.length === 0 && hasSearched) && !loading && <p>Nessun immobile trovato</p>}
      {similarData.slice(0, visibleItems).map((item, index) => (
        <div key={index} className="mb-4">
          <BuildingCard
            item={item}
            typology={props.formData.similarTypology}
            addToReport={addToReport}
            removeToReport={removeToReport}
            buildingArray={props.formData.similiarBuildings}
          />
          <hr></hr>
        </div>
      ))}
	  <div className="text-center">
      {visibleItems < similarData.length && renderButton()}
	  </div>
    </div>
		</div>
	);
};

export default Related;
