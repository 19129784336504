// TIME
function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

const formatDate = (date) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};

const formatDateFromString = (date) => {
  date = new Date(date);
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};

const formatItalianDate = (date) => {
  date = new Date(date);
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

// VALIDATION
const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

const isValidPassword = (password) => {
  return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm.test(
    password
  );
};

function isEmptyObject(obj) {
  return JSON.stringify(obj) === "{}";
}

// function containsSpecialChars(str) {
//   const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
//   return specialChars.test(str);
// }

// function containsNumber(str) {
//   return /\d/.test(str);
// }

// function checkUppercase(str) {
//   for (var i = 0; i < str.length; i++) {
//     if (
//       str.charAt(i) == str.charAt(i).toUpperCase() &&
//       str.charAt(i).match(/[a-z]/i)
//     ) {
//       return true;
//     }
//   }
//   return false;
// }

// function checkLowercase(str) {
//   for (var i = 0; i < str.length; i++) {
//     if (
//       str.charAt(i) == str.charAt(i).toLowerCase() &&
//       str.charAt(i).toLowerCase().match(/[a-z]/i)
//     ) {
//       return true;
//     }
//   }
//   return false;
// }

export {
  formatDate,
  formatDateFromString,
  formatItalianDate,
  isValidEmail,
  isValidPassword,
  isEmptyObject,
};
