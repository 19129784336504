import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoUnità () {

    return (
        <Struttura 
        articoloComponent={<ArticoloUnitaImmobiliari />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Unità"
        />
    )
}


function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="2" label="Funzione" />
            <TreeItem nodeId="3" label="Struttura della Pagina" />

            <TreeItem nodeId="4" label="Pagina Iniziale">
            <TreeItem nodeId="5" label="Visione Lista" />
            <TreeItem nodeId="6" label="Visione Tabella" />
            </TreeItem>

            <TreeItem nodeId="7" label="Creazione di un'Unità" >
            <TreeItem nodeId="8" label="Categoria" />
            <TreeItem nodeId="9" label="Proprietari" />
            <TreeItem nodeId="10" label="Catasto" />
            <TreeItem nodeId="11" label="Condominio" />
            <TreeItem nodeId="12" label="Destinazione" />
            <TreeItem nodeId="13" label="Elenchi" />

            <TreeItem nodeId="14" label="Facoltativi">
            <TreeItem nodeId="15" label="Descrizioni" />
            <TreeItem nodeId="16" label="Caratteristiche" />

            <TreeItem nodeId="17" label="Utenze" />
            <TreeItem nodeId="18" label="Redditività" />
            <TreeItem nodeId="19" label="Atti" />

            <TreeItem nodeId="20" label="Pertinenze" />
            <TreeItem nodeId="21" label="Foto e Planimetrie" />
            <TreeItem nodeId="22" label="Allegati" />
            </TreeItem>

            </TreeItem>

            <TreeItem nodeId="23" label="Visualizzazione Unità" />
            <TreeItem nodeId="24" label="Modifica Unità" />
            




        </TreeView>
    );
}

function ArticoloUnitaImmobiliari() {
  return (
    <div className="support-article">
      <h1>Unità</h1>
      <h2 style={{ color:'transparent', height: 0}}>Unità</h2>

      <h2 id="2">Funzione</h2>
      <p>La sezione unità ti consente di creare e inserire all’interno della piattaforma tutte le unità che gestisci per conto tuo o di terzi.</p>
      <p>Questa sezione ti permetterà di avere un anagrafica digitale di tutte le tue unità, a cui potrai accedere da qualunque dispositivo, ovunque tu sia.</p>

      <h2 id="3">Struttura della Pagina</h2>
      <p>In alto a destra trovi il pulsante rosso “+”. Cliccando su questo pulsante aprirai il modulo di creazione di un unità.</p>
      <img loading="lazy" src="https://i.postimg.cc/ZnrBvxqf/aggiungi-copia.png" alt="aggiungi-copia"  />   
      <p>Poco sotto, in alto a destra troverai la barra di ricerca delle tue unità, per semplificare la ricerca di un immobile specifico tramite identificativo. </p>

      <p> In alternativa trovi a sinistra il bottone filtra (icona dell’imbuto) che ti permette di filtrare le unità per i seguenti criteri: 
      </p>
      <ul>
        <li><p >periodo di creazione</p></li>
        <li><p >categoria, se ad esempio puoi
        filtrare tutti gli immobili che appartengono alla categoria “negozi”</p></li>
        <li><p >proprietari, se ad esempio vuoi
        visualizzare tutti gli immobili che appartengono a uno specifico
        proprietario</p></li>
        <li><p >gestione, puoi filtrare tutte le
        unità che appartengono a una specifica gestione 
        </p></li>
      </ul>
      <img loading="lazy" src="https://i.postimg.cc/gkV8xmXY/filtra.png" />

      <img loading="lazy" src="https://i.postimg.cc/bw66XZ5s/filtro.gif" />

      <p >A fianco al pulsante filtra, trovi il
        pulsante ordina (icona con tre righe orizzontali) che ti permette di
        </p>
        <ul>
          <li><p >Ordinare gli elementi in modo
          alfabetico dalla A alla Z o viceversa</p></li>
          <li><p >Ordinare gli elementi mettendo
          prima quelli creati più di recente o in alternativa mettendo per
          primi quelli creati meno recentemente</p></li>
        </ul>
      <img loading="lazy" src="https://i.postimg.cc/CKQf3Kt4/ordina.png" />

      <p>A destra prima del bottone filtro, trovi il bottone “CAMBIO VISUALIZZAZIONE”, che ti permette di passare dalla visione lista a quella tabellare e viceversa.</p>
      <img loading="lazy" src="https://i.postimg.cc/QtKWh3ZZ/cambia-visualizzazione.png"  />


      <h2 id="4">Pagina Iniziale</h2>
      <p>Nella pagina iniziale avrai visione delle unità create sotto forma di lista o tabella.</p>

      <h3 id="5">Visione Lista</h3>
      <p>Per ogni unità troverai un elemento (o card) relativo di riepilogo contenente: </p>
        <ul>
        <li><p >identificativo dell’immobile</p></li>
        <li><p >Indirizzo</p></li>
        <li><p >proprietario</p></li>
        <li><p >categoria catastali</p></li>
      </ul>
      <p>Dalla card puoi eseguire due azioni:</p>
      <ul>
      <li><p><strong>Eliminare l’unità</strong>: devi
      cliccare sull’icona col cestino e poi dare una seconda conferma
      nella finestra pop up che apparirà.</p></li>
      <li><p><strong>Esportare informazioni</strong>: devi
      cliccare sul tasto contenente una freccia che va verso il basso,
      apparirà un sottomenu e potrai scegliere se esportare i dati in
      formato PDF o CSV <br/>
      (L'importazione in formato CSV -Comma-Separated
      Values è un modo semplice ed efficace per esportare e condividere
      dati in un formato tabellare compatibile con molti software. Può
      essere aperto e visualizzato con facilità utilizzando applicazioni
      come Excel, Google Fogli, Numbers, Blocco note etc.)</p></li>
    </ul>
      <img loading="lazy" src="https://i.postimg.cc/zGphk7F6/card.png" />


      <h3 id="6">Visione Tabella</h3>
      <p>Potrai visualizzare le tue unità in formato tabellare. In questo formato saranno visualizzabili più informazioni rispetto alla card.</p>
      <img loading="lazy" src="https://i.postimg.cc/T3TmmFgn/tabella.png" />


      <h2 id="7">Creazione di un'Unità</h2>
      <h3 ><u>Struttura</u></h3>
<p >Dalla pagina iniziale della sezione
Unità, cliccando il pulsante rosso “+” in alto a destra si avvia
il processo di creazione di un unità.</p>
<br/>
<p >Il processo di creazione di un’unità
si articola in 7 passaggi, che puoi visualizzare nella barra in alto.</p>
      <ul>
	<li><p> Categoria</p></li>
	<li><p >Proprietari</p></li>
	<li><p >Catasto</p></li>
	<li><p >Condominio</p></li>
	<li><p >Destinazione</p></li>
	<li><p >Elenchi</p></li>
	<li><p >Facoltativi</p></li>
</ul>
      <img loading="lazy" src="https://i.postimg.cc/VN6CZt76/stepper-creazione.png"  />

      
      <p>Per andare a un passaggio successivo premere il pulsante “AVANTI” o “INDIETRO” qualora voleste tornare a un passaggio precedente.</p>
      <img loading="lazy" src="https://i.postimg.cc/7hvJLWqG/indietro-avanti.png" />

      <p >Per uscire dalla profilazione clicca
sulla freccia che trovi a sinistra nella barra in alto.</p>

<p >I campi obbligatori della profilazione
verranno indicati col simbolo rosso asterisco *</p>


      <h3 id="8">Categoria</h3>
      <p >In questo campo definisci la categoria
dell’immobile, indirizzo e identificativo.</p>
<p ><br/>

</p>
<p >Nell’inserimento dell’indirizzo,
ricordati di selezionare prima la Provincia e poi il Comune.</p>
<p >Sono dati correlati, e selezionando
prima il Comune potresti non trovare la città nella provincia che
stai cercando.</p>
<p ><br/>

</p>
<p >L’identificativo è un nome che ti
permette di riconoscere l’immobile più facilmente, è del tutto
personale la scelta e strutturazione dell’identificativo.</p>

      <h3 id="9">Proprietari</h3>
      <p >In questa sezione puoi inserire:</p>
<ul>
	<li><p >Proprietari</p></li>
	<li><p >Nuda proprietà</p></li>
	<li><p >Usufruttuari</p></li>
	<li><p >Conduttori (o Inquilini)</p></li>
</ul>

<p >L’inserimento può avvenire in due
modi: <strong>nuova creazione</strong> e <strong>selezione</strong>.</p>
<ul>
	<li><p >Cliccando sul pulsante “NUOVO”
	vi si aprirà la profilazione relativa, che potrete procedere a
	compilare e creare. Dopo la creazione verrete rimandati al passaggio
	“PROPRIETARI”. Cliccate quindi il pulsante “SELEZIONA” e
	selezionate il soggetto di vostro interesse.</p></li>
	<li><p >Se invece avete precedentemente
	creato gli utenti, basterà cliccare il pulsante “SELEZIONA” e
	selezionate il soggetto di vostro interesse.</p></li>
</ul>
<p >Verranno creati uno o più elementi
(card) contenti i soggetti selezionati.</p>
<p >Se avete selezionato erroneamente un
soggetto, vi basterà cliccare sulla <strong>"x"</strong> in alto a destra per
rimuoverlo dall’unità in questione.</p>
<p >All’interno della card troverete
delle informazioni di riepilogo relative al soggetto</p>
<ul>
	<li><p >Indirizzo</p></li>
	<li><p >Civico</p></li>
	<li><p >Città</p></li>
	<li><p >Telefono</p></li>
	<li><p >Codice fiscale</p></li>
</ul>
<p >e alcune informazioni nuove da inserire
relative al soggetto, che sono relative all’unità al quale lo
state associando</p>
<ul>
	<li><p >Parti di proprietà - Bisogna inserire il dato come rapporto, ad esempio 1/3</p></li>
	<li><p >Referente</p></li>
	<li><p >Data d’inizio possesso</p></li>
</ul>

<Tips variant="suggestion" content="Suggeriamo di effettuare la
creazione di proprietari e conduttori, così come di un eventuale
condominio, prima della creazione di un'unità, poichè questo
renderà il processo più agevole. " />

<Tips variant="automatization" content="Dopo aver associato
un Proprietario ad un unità, troverai in automatico l’unità
relativa inserita nei dati del proprietario (Utenti &gt; Proprietari
&gt; Unità)" />


<h3 id="10">CATASTO</h3>
<p >In questa sezione potrai inserire i
dati catastali relativi all’unità</p>
<p >Abbiamo inserito alcune specifiche
indicate dal simbolo “i” che potrai visualizzare avvicinando il
mouse all’icona.</p>

<h3 id="11">CONDOMINIO</h3>
<p >In questa sezione potrai associare un
condominio all’unità che stai creando</p>

<p className="subtitle">MILLESIMI</p>
<p >Nella sezione millesimi dovrai inserire
i millesimi riportati sull’anagrafe condominiale.</p>
<img loading="lazy" src="https://i.postimg.cc/0NwqKJH3/millesimi.png" />


<Tips variant="automatization" content="Questa sezione è
importante se gestisci tutti gli immobili all’interno di un
condominio, poiché in automatico creeremo una scheda di riepilogo
con tutte le unità appartenenti al condominio in
questione (contenente per ciascuna: proprietario, superficie,
millesimi) nella sezione Edifici &gt; Stabili &gt; Scheda Condomini." />




<p className="subtitle">DATI EDIFICIO</p>
<img loading="lazy" src="https://i.postimg.cc/W3ccGbB4/edificio.png" />

<Tips variant="automatization" content="Questi dati vengono
richiesti anche durante la profilazione per una valutazione
immobiliare e verranno automaticamente compilati, se decidi di creare
la valutazione di un’unità per la quale avevi compilato questa
sezione." />



<h3 id="12" >DESTINAZIONE</h3>
<p >Inserisci i dati relativi alla finalità
d’uso dell’immobile</p>

<h3 id="13" >ELENCHI</h3>
<p >La sezione elenchi è suddivisa in
Fornitori, Lavori, Assicurazione</p>
<img loading="lazy" src="https://i.postimg.cc/sxVFLHDd/elenchi.png" />


<p className="subtitle" >Fornitori</p>
<p >Puoi
aggiungere i fornitori di questa unità</p>

<p className="subtitle" >Lavori</p>

<Tips variant="automatization" content="Le
attività che aggiungi nella sezione SAL che associ a una specifica
unità, verranno visualizzate in questa sezione in maniera
automatizzata." />


<p className="subtitle" >Assicurazione</p>

<p >Qui
potrai inserire le polizze relative al tuo immobile</p>

<h3 id="14" >FACOLTATIVI</h3>
<p >In questa sezione ci sono altri dati
facoltativi che puoi compilare in un secondo momento, dopo la
creazione dell’unità, che appariranno in un menù a discesa. Per compilare i dati di una sezione clicca sul bottone relativo.</p>

<p >
Se decidi di non compilare i dati
facoltativi e non spunti nessuna casella, clicca sul pulsante SALVA
in basso a destra, per completare la creazione dell’Unità</p>

<h3 id="15" >DESCRIZIONI</h3>
<p >Questi sono dei campi di testo liberi
dove potrai caricare eventuali dati che non sei riuscito a collocare
da altre parti.</p>

<h3 id="16" >CARATTERISTICHE - TO DO</h3>
<p >In questa sezione puoi inserire i dati
tecnici e descrittivi dell’immobile:</p>
<p >informazioni sulle superfici,
esposizione, distanza, locali etc.</p>


<Tips variant="automatization" content="Questi dati vengono
richiesti anche durante la profilazione per una valutazione
immobiliare e verranno automaticamente compilati, se decidi di creare
la valutazione di un’unità per la quale avevi compilato questa
sezione." />

<h3 id="17">UTENZE</h3>
<p >All’interno di questa sezione puoi
indicare le utenze relative all’unità:</p>
<ul>
	<li><p >Elettricità</p></li>
	<li><p >Gas</p></li>
	<li><p >Acqua fredda</p></li>
	<li><p >Acqua calda</p></li>
	<li><p >Riscaldamento</p></li>
	<li><p >Costi annuali</p></li>
</ul>

<p >E salvare le letture relative ai vari
contatori.</p>

<h3 id="18">REDDITIVITA’</h3>

<p >In questa sezione puoi inserire e
salvare i dati della redditività dei tuoi immobili.</p>

<Tips variant="automatization - to do" content='I dati "media
affitto", "media vendita" e "canone attuale" possono
essere automatizzati.
Media affitto e Canone Attuale:
	verranno automatizzati se ci sono degli affitti associati all’unità
	(puoi crearli nella sezione Unità immobiliari &gt; Affitti) 
Media vendita: verrà automatizzato
	se ci sono delle valutazioni immobiliari associate all’unità
	(puoi crearli nella sezione Unità Immobiliari &gt; Valutazione
	Immobiliare)' />




<h3 id="19">ATTI</h3>
<p >In questa sezione puoi creare ed
inserire gli atti relativi all’unità in questione (di successione,
compravendita etc.).</p>

<h3 id="20">PERTINENZE</h3>
<p >In questa sezione puoi inserire le
pertinenze relative al tuo immobile.</p>



<Tips variant="info" content="E' necessario creare le
pertinenze dalla sezione Unità prima della creazione
dell’immobile al quale desideri associarle. Poi potrai selezionarle
durante la creazione del’Immobile, in questa scheda." />

<h3 id="21">FOTO E PLANIMETRIE</h3>
<p >Qui potrai caricare le foto e le
planimetrie relative all’unità.</p>

<Tips variant="automatization" content="Le planimetrie sono
molto importanti e si integrano con la sezione STATO AVANZAMENTO
LAVORO (SAL) &gt; Attività. Le planimetrie che carichi in questa
sezione appariranno
quando crei una nuova attività e selezioni l’unità in questione
Se carichi una planimetria potrai poi in attività indicare su di essa i punti di interesse  per i lavori
di manutenzione che potrai condividere con inquilini e fornitori.
Trovi maggiori informazioni nella sezione di questa guida relativa
alla sezione SAL." />



<h3 id="22">ALLEGATI</h3>
<p >Se possiedi documentazione o altro
relativo all’immobile che desideri conservare in maniera digitale o
che non è stato possibile collocare nei passaggi precedenti, puoi
caricare tutto in questa sezione. 
</p>

<br/>
<p >Infine clicca sul pulsante SALVA in
basso a destra, per completare la creazione dell’Unità</p>





      <h2 id="23">Visualizzazione di un'Unità</h2>
      <p >Cliccando su una card della lista o una
riga della tabella potrai visualizzare i dati dell’immobile che hai
creato.</p>
<br/>
<p >La  pagina di visualizzazione è
suddivisa in “schede” uguali a quelle presenti in fase di
creazione, in modo tale saprai che i dati che hai inserito in una
scheda durante la CREAZIONE puoi trovarli nella relativa scheda
durante la  VISUALIZZAZIONE</p>
<br/>
<img loading="lazy" src="https://i.postimg.cc/xCGDtVQ3/vista.png" />

<p >In alto a destra trovi i pulsanti di
stampa, per stampare i dati di ogni singola scheda, e esportazione,
per esportare i dati di una scheda specifica. Potrai scegliere se
esportare i dati in formato PDF o CSV <br/> (L'importazione in formato CSV
-Comma-Separated Values è un modo semplice ed efficace per esportare
e condividere dati in un formato tabellare compatibile con molti
software. Può essere aperto e visualizzato con facilità utilizzando
applicazioni come Excel, Google Fogli, Numbers, Blocco note etc.)</p>
<img loading="lazy" src="https://i.postimg.cc/HkKgLt3X/esporta.png" />


      <h2 id="24">Modificare i Dati di un'Unità</h2>
      <p>Dalla schermata di visualizzazione di un’unità, cliccando il pulsante MODIFICA in alto a destra potrai modificare e/o aggiungere dati. </p>
      <img loading="lazy" src="https://i.postimg.cc/tCPVQPVg/modifiche.png" />


      <p> Per salvare le modifiche effettuate e tornare in modalità VISUALIZZAZIONE dovrai poi cliccare sul pulsante “SALVA MODIFICHE”.</p>
      <img loading="lazy" src="https://i.postimg.cc/Dyxh9hXC/salva-modifiche.png" />


    </div>
  );
}

