import React from "react";
import logo from "../../assets/images/logo-red.svg";

import "../../assets/css/RegisterSuccess.css";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import ButtonRm from "../ButtonRm";

import api from "../../api/api";

import {useState, useEffect} from "react"

export default function ReturnAccount() {

  const navigate  = useNavigate()

  const login  = () => {
    navigate("/");
  };

  const [ newUrl, setNewUrl ] = useState()

  const handleOpenPopup = (url) => {
    const popupUrl = url
     // Dimensioni desiderate per la finestra popup
    const width = 600;
    const height = 800;

    // Calcola le posizioni left e top per centrare la finestra
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
    const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
        
    // Apertura della finestra popup
    window.open(popupUrl, "StripeOnboarding", options);
};


  

useEffect(() => {

  if(newUrl) {
    handleOpenPopup(newUrl)
  }
}, [newUrl])


  return (
    <>
      <div className="register-div">
        <img loading="lazy" src={logo} alt="logo" className="register-logo"></img>
        <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
              width="80px"
              style={{ display: "flex", margin: "auto" }}
            ></img>
            <h3 className="my-4">
              Il tuo account di pagamento è stato creato!
            </h3>
          

            <ButtonRm variant="primary" onClick={login}>Vai alla Dashboard</ButtonRm>
    

          </div>
        </div>
      </div>
       <Footer />
    </>
  );
}
