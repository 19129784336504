import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import EvaluationCard from "./EvaluationCard";
import estate_img from "../../../assets/images/placeholder.png";

import axios from "axios";

import {Row, Col} from "react-bootstrap"

import filter from "../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../assets/images/icon-sidebar/sort.svg"

import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import SearchInput from "../../SearchInput";

import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";

import api, { evaluation_download_endpoint, BASE_URL, evaluation_endpoint  } from "../../../api/api";

import Modal from 'react-bootstrap/Modal';

import { type as categories } from "../constant.js"

import { checkMaster, checkUserRole } from "../../../utils/checkUserRole";
import Alert from "react-bootstrap/Alert";

import { MultiSelect } from "react-multi-select-component";
import ButtonRm from "../../ButtonRm";



const EvaluationFirstPage = (props) => {

	//SEARCH

	const [search, setSearch] = useState("")

	//DERIVED LIST WITH ONLY THE UNITS THAT FITS A RESEARCH
	const searchedList =
	props.data && props.data.length > 0
		? props.data?.filter((unit) =>
			unit.identifier.toLowerCase().includes(search.toLocaleLowerCase())
		)
		: [];

		/* const categories = [
		"Abitazioni di 1° fascia",
		"Abitazioni di fascia media",
		"Abitazioni di 2° fascia",
		"Ville e villini",
		"Uffici",
		"Negozi",
		"Box e autorimesse",
		"Posti auto coperti",
		"Posti auto scoperti",
		"Laboratori",
		"Magazzini",
		"Capannoni tipici",
		"Capannoni industriali",
		]; */


		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("più-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


		useEffect(() => {
		if(props.data.length > 0)  {
			setFilteredUnits(
				props.data.filter((unit) => {
			return (
				(selectedCategories.length === 0 || selectedCategories.includes(unit?.category)) &&
				unit.identifier?.toLowerCase().includes(search.toLowerCase())
			);
			})
		)}
		}, [search, selectedCategories, props.data]);

		const [sortedUnits, setSortedUnits] = useState(filteredUnits);

		useEffect(() => {
		switch (sortBy.toLowerCase()) {
			case "z-a":
			setSortedUnits(sortStringByKey(filteredUnits, "name", false));
			break;
			case "a-z":
			setSortedUnits(sortStringByKey(filteredUnits, "name"));
			break;
			case "meno-recenti":
			setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
			break;
			default:
			case "più-recenti":
			setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
		}
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearch("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(props.data);
		}

		/*  async function getEvaluation() {
			try {
				await api().get(`${evaluation_download_endpoint}`);
			} catch (err) {
				console.log(err);
			}
		} */

		const user = localStorage.getItem("user");
		const token = user ? JSON.parse(user).token : null;

		function getEvaluation() {
				/* const axios = require('axios');
				let data = '';
				
				let config = {
				  method: 'get',
				  maxBodyLength: Infinity,
				  url: '${BASE_URL}api/v1/evaluation/downloadDocument',
				  headers: { 
					'Content-Type': 'application/json', 
					'Authorization': 'Bearer ' + token
				  },
				  data : data
				};
				
				axios.request(config)
				.then((response) => {
				  console.log(JSON.stringify());
				  const blob = new Blob([response.data], { type: 'application/pdf' });
				  const href = window.URL.createObjectURL(blob);
				  const theLink = document.createElement('a');
				  theLink.href = href;
				  //theLink.download = props.id + '.pdf';				  document.body.appendChild(theLink);
				  theLink.click();
				  document.body.removeChild(theLink);
				})
				.catch((error) => {
				  console.log(error);
				}); */
		} 
 
			/* 
			async function getEvaluation() {
			try {
				const response = await axios.get(`https://anthillrealmeta.herokuapp.com${evaluation_download_endpoint}`, {
				headers: {
					'Authorization':  `Bearer ${
						JSON.parse(localStorage.getItem("user"))?.token
					}`,
					'Content-Type': 'application/json',
				},
				//data: {}  // Corpo vuoto come oggetto JSON
				});

				// Gestisci la risposta qui
				console.log(response.data);
			} catch (error) {
				console.error(error);
			}
			}  */

			const [selectedItems, setSelectedItems] = useState([]);

			// Funzione per gestire la selezione multipla
			const handleSelection = (e, id) => {
				if(e){
					e.stopPropagation()
				}
		
				if (selectedItems.includes(id)) {
					setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
				} else {
					setSelectedItems([...selectedItems, id]);
		
				}
			};
		
			const handleSelectAll = () => {
				let selectedItems = sortedUnits.map((item, index) => item.id)
				setSelectedItems(selectedItems)
			}
		
			const handleDeselectAll = () => {
				setSelectedItems([])
			}
		
			// Funzione per eliminare gli stabili selezionati
			const deleteSelected = async () => {
				// Effettua l'operazione di eliminazione per ogni id selezionato
				for (const id of selectedItems) {
				try {
					await api().delete(`${evaluation_endpoint}${id}`);
				} catch (err) {
					console.log(err);
				}
				}
				props.getUnits()
				setSelectedItems([]);
		
			};

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//handle modal permessi
	const [showPermit, setShowPermit] = useState(false);
	const handleClosePermit = (e) => {
		setShowPermit(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShowPermit = () => setShowPermit(true);
	

	  //MI SERVE LISTA COLLABORATORI MASTER
	  const [members, setMembers] = useState([
		{
			id:1,
			name:"Ema",
			surname:"Mavi",
			email:"ema@gmail.com"
		},
		{
			id:2,
			name: 'Greta',
			surname: 'Pio',
			email: 'gre@gmail.com'
		}
	  ])
	
	  const handleCheckboxChange = (userId, action) => {
		//da gestire
	  };


	return (
		<>
		<Row className="dashboard-container">
			{props.data.length > 0 && <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<SearchInput setSearch={setSearch}/>

				<div className="d-flex align-items-center ms-3">
					<div className="user-dropdown">
					<div className="filter">                   
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
						<img loading="lazy" src={filter}/>
						</div>
					<div className="user-dropdown-content">
					<div className="fs-14 fw-bold mb-2">Filtra per</div>
					<div className="fs-14">CATEGORIA</div>
					{categories.map((category) => (
					<label key={category} className="d-block">
						<input
						type="checkbox"
						className="me-2"
						value={category}
						checked={selectedCategories.includes(category)}
						onChange={(event) =>
							setSelectedCategories((prevCategories) =>
							event.target.checked
								? [...prevCategories, category]
								: prevCategories.filter((cat) => cat !== category)
							)
						}
						/>
						{category}
					</label>
					))}

					<a className="text-decoration-underline text-dark text-center mt-3" onClick={clearFiltersAndSort}>Rimuovi filtri</a>

					
					</div>
					</div>
					</div>
					<div className="user-dropdown">
						<div className="sort d-flex">
				<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
					<img loading="lazy" src={sort}/>
				</div>
						</div>
						<div className="user-dropdown-content">
							<div className="fs-16 fw-bold">Ordina per</div>
							<div
				onClick={() => setSortBy("z-a")}
								className="fs-14 sort-text"
								>
								Z-A
							</div>
				<div
				onClick={() => setSortBy("a-z")}
								className="fs-14 sort-text"
								>
								A-Z
							</div>
				<div
								className="fs-14 mb-1 sort-text"
				onClick={() => setSortBy("meno-recenti")}
								>
								Meno recenti
							</div>
				<div
								className="fs-14 mb-1 sort-text"
				onClick={() => setSortBy("più-recenti")}
								>
								Più recenti
							</div>

						</div>
					</div>
				</div>
			</Col>}

			{checkUserRole() &&
                	selectedItems.length > 0 && 
					<div className="d-flex mb-3">
						<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
							Seleziona tutti
						</ButtonRm>
						<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
							Deseleziona Tutti
						</ButtonRm>
					</div>
				}
			{sortedUnits.map((item, index) => {
				return (
					<div key={index} className="mb-3">

						<a href={require('../../../../src/report-chiaravagna.pdf')} target = "_blank">
							<EvaluationCard
								id={item.id}
								name={item.identifier}
								internal={item.unit[0]?.internal}
								address={item.address}
								municipality={item.municipality}
								scale={item.unit[0]?.scale}
								cap={item.unit[0]?.cap}
								floor={item.unit[0]?.floor}
								imgUrl={estate_img}
								cdat={item.cdat}
								deleteEvaluation={props.deleteEvaluation}
								getEvaluation={getEvaluation}
								handleSelection={handleSelection}
											selectedItems={selectedItems}
							/>
							</a>
					</div>
				);
			})}

<Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>
		</Row>
		<Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>
		</>
	);
};

export default EvaluationFirstPage;
