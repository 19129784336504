import React,{useState} from "react";
import MidTabs from "../components/Membri/Conduttori/view_mode/MidTabs";
import TopHeadPartV from "../components/Membri/Conduttori/view_mode/TopHeadPartV";
import Container from "../components/Container";
import { useLocation } from "react-router-dom";
import TopHeading from "../components/TopHeading";
import { conductor_endpoint, BASE_URL } from "../api/api";
import { useEffect } from "react";
import axios from "axios";


export default function ViewConduttori(props) {
  const location = useLocation();
 
  const [conductor, setConductor] = useState({});

  // TODO con api() da problemi con la chiamata API, da risolvere
  const fetchConductor = () => {
    axios
      .create({
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
        },
      })
      .get(
        conductor_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
      .then((res) => {
        if (res.data) {
          setConductor({
            ...res.data,
            initialBankAccountsIDs: res.data.bankAccounts.map(
              (bankAccount) => bankAccount.id
            ),
          });
        }
      });
  };

  useEffect(() => {
    fetchConductor();
  }, []);

  return (
    <>
      <TopHeading SmallHeading="" back_modal=""/>
      <TopHeadPartV parms={conductor} />
      <MidTabs  parms={conductor}/>
    </>
  );
}
