import React, { useRef } from "react";
import "../../../assets/css/AttivitàCard.css";
import Form from "react-bootstrap/Form";
import PriorityTag from "../PriorityTag";
import { useState } from "react";
import useOnClickOutside from "../useOnClickOutside";
import { mergeRefs } from "react-merge-refs";
import { Link } from "react-router-dom";
import { checkUserRole } from "../../../utils/checkUserRole";

import RiassuntoContabilita from "../Chat/RiassuntoContabilita";

const BASEURL = window.location.origin;

//TO-DO
//vedere come passare ref a priority correttamente
//merge nameRef e ref

const AttivitàCard = React.forwardRef((props, ref) => {
  //Mi passo tutto l'oggetto di questa attività
  const item = props.item;
  const [data, setData] = useState({
    id: props.id,
    name: props.name,
    assignee: props.assignee,
    priority: props.priority,
    expirationDate: props.expirationDate?.substr(0, 10).split("-").join("-"),
  });

  //MANTENGONO LO STATO DEI DROPDROWN, SE SONO APERTI O MENO
  const [showPrioritiyOptions, setPriorityOptions] = useState(false);
  const [showAssigneeOptions, setAssigneeOptions] = useState(false);

 /*  const initials = data.assignee
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 2)), ""); */

  //APRI/CHIUDI DROPDOWN PRIORITA'
  function handlePriority() {
    setPriorityOptions((prev) => !prev);
  }

  //APRI/CHIUDI DROPDOWN INCARICATO
  function handleAssignee() {
    setAssigneeOptions((prev) => !prev);
  }

  //REF PER INCARICATO E GESTIONE CAMBIAMENTO INCARICATO INLINE
  const dropRef = useRef(); //cliccando fuori questo elemento si chiude dropdown
  const assigneeRef = useRef(); //anche cliccando fuori questo elemento si chiude dropdown

  function editAssignee(value) {
    if (showAssigneeOptions) {
      setAssigneeOptions(value);
    }

    if (data.assignee !== props.assignee) {
      console.log("patch assignee");
      props.onEdit(props.id, { ...item, assignee: data.assignee });
    }
  }

  useOnClickOutside(dropRef, () => editAssignee(false), assigneeRef);

  //REF PER PRIORITA' E GESTIONE CAMBIAMENTO PRIORITA' INLINE
  const priorityDrop = useRef();
  const pTagRef = useRef();

  function editPriority(value) {
    if (showPrioritiyOptions) {
      setPriorityOptions(value);
    }

    if (data.priority !== props.priority) {
      console.log("patch priority");
      props.onEdit(props.id, { ...item, priority: data.priority });
    }
  }

  useOnClickOutside(priorityDrop, () => editPriority(false), pTagRef);

  //REF PER NAME E GESTIONE CAMBIAMENTO NAME INLINE
  const nameRef = useRef();

  function editNameInput() {
    if (data.name !== props.name) {
      console.log("patch name" + props.id);
      props.onEdit(props.id, { ...item, name: data.name });
    }
  }

  useOnClickOutside(nameRef, () => editNameInput());

  //REF PER PRIORITA' E GESTIONE CAMBIAMENTO PRIORITA' INLINE
  const dateRef = useRef();

  function editDataInput() {
    if (
      data.expirationDate !==
      props.expirationDate?.substr(0, 10).split("-").join("-")
    ) {
      console.log("patch data" + props.id);
      props.onEdit(props.id, { ...item, expirationDate: data.expirationDate });
    }
  }

  useOnClickOutside(dateRef, () => editDataInput());

  //UPDATE PARTIAL DATA
  function updateData(e) {
    console.log("update");
    const { name, value } = e.target;
    console.log(name, value);
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }

  const words = data.assignee.split(" "); // Separazione delle parole in base agli spazi

  let initials = "";

  if (words.length >= 2) {
    initials = words[0].charAt(0) + words[1].charAt(0); // Estrazione delle iniziali delle prime due parole
  } else if (words.length === 1) {
    initials = words[0].charAt(0); // Se c'è solo una parola, viene estratta solo la sua iniziale
  }

  return (
    <>
    <div
      className={`attivitàCard d-flex justify-content-between align-items-center text-dark ${
        showPrioritiyOptions || showAssigneeOptions ? "zindexSAL" : ""
      }`}
    >
      <div className="d-flex zindexSAL">
        <div className="pe-3 d-flex justify-content-center align-items-center">
          <Form.Check
            type="checkbox"
            checked={props.selectedList.some(el => el.id === props.id)}
            onChange={(e) => props.handleSelection(e, props.id, props.item )}
          />
        </div>
        <div>
          <p className="fs-12 opacity-50 text-uppercase">{props.unit_name}</p>
          <input
            type="text"
            className="inline-text-sal"
            value={data.name}
            name="name"
            onChange={updateData}
            ref={mergeRefs([nameRef, ref])}
          />
          <p className="fs-12">
            Creato da {props.author}, il{" "}
            {props.creation_date?.substr(0, 10).split("-").join("-")}
          </p>
        </div>
      </div>
      <div className="items-activity-container me-4 d-none d-md-flex flex-column zindexSAL">
        <div className="d-flex mb-1 justify-content-end">
          <p className="text-uppercase fs-10 col-33">Incaricato</p>
         {props.priority && <p className="text-uppercase fs-10 col-33">Priorità</p>}
          <p className="text-uppercase fs-10 col-33">Scadenza</p>
        </div>

        { (checkUserRole()) && <div className="d-flex align-items-center justify-content-end">
          <div className="col-33 ">
            <p
              className="unit-assignee-circle"
              onClick={handleAssignee}
              ref={assigneeRef}
            >
              {initials}
            </p>
            <div
              ref={dropRef}
              className={`assignee-dropdown border ${
                showAssigneeOptions ? "" : "d-none"
              }`}
            >
              <Form.Group className="mb-3">
                <Form.Label className="fs-14 mb-2">
                  Seleziona incaricato
                </Form.Label>
                <Form.Select
                  className="inline-select"
                  name="assignee"
                  value={data.assignee}
                  onChange={updateData}
                  defaultValue={data.assignee}
                >
                  <option value="">Seleziona</option>
                  {props.members?.length > 0 &&
                    props.members.map((member) => {
                      const label = member.includes('{') ? JSON.parse(member.replace(/'/g, '"')).label : member;
                        return (
                          <option key={member} value={member}>
                            {label}
                          </option>
                        );
                    })}
                </Form.Select>
              </Form.Group>
              <div className="my-3">
                {data.assignee && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="gestione-assignee">{initials}</p>
                    <a className="fs-14 text-dark text-decoration-underline">
                      Rimuovi
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>

          {props.priority && <div className="col-33 priority ">
            <PriorityTag
              priority={data.priority}
              size="mini"
              onClick={handlePriority}
              ref={pTagRef}
            />
            <div
              ref={priorityDrop}
              className={`activity-dropdown ${
                showPrioritiyOptions ? "" : "d-none"
              }`}
            >
              <Form>
                <Form.Check type="radio" id="priority-options">
                  <Form.Check.Input
                    type="radio"
                    name="priority"
                    value="high"
                    checked={data.priority === "high"}
                    onChange={updateData}
                  />
                  <Form.Check.Label>
                    {<PriorityTag priority="high" size="mini" />}{" "}
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type="radio" id="priority-options">
                  <Form.Check.Input
                    type="radio"
                    name="priority"
                    value="medium"
                    checked={data.priority === "medium"}
                    onChange={updateData}
                  />
                  <Form.Check.Label>
                    {<PriorityTag priority="medium" size="mini" />}{" "}
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check
                  type="radio"
                  id="priority-options"
                  name="proprity"
                  value="low"
                  checked={data.priority === "low"}
                >
                  <Form.Check.Input
                    type="radio"
                    name="priority"
                    value="low"
                    checked={data.priority === "low"}
                    onChange={updateData}
                  />
                  <Form.Check.Label>
                    {<PriorityTag priority="low" size="mini" />}{" "}
                  </Form.Check.Label>
                </Form.Check>
              </Form>
            </div>
          </div>}

          <div className="col-33">
            <input
              type="date"
              className="inline-date-sal"
              value={data.expirationDate}
              name="expirationDate"
              onChange={updateData}
              ref={dateRef}
            />
          </div>
        </div>}
      </div>
      <Link to={`${props.id}`} className="trans-div"></Link>
    </div>

    </>
  );
});

function AttivitàCardMap(props) {
  //const func =  props.handleItemSelected()/*  : props.handleGanttItem(); */
  const initialsName = props.name.substr(0, 2).toUpperCase();

  return (
    <a
      onClick={() => {
        if(props.type == "map"){
          props.handleItemSelected(props.id)
        }
      }}
      style={{
        cursor: "pointer",
        width:"100%",
        border:
          props.selectItem===props.id ? "2px solid lightblue" : "transparent",
      }}
    >
      <div className="attività-card-map">
        <div className="me-1">
          <p className="activity-assignee-circle ">{initialsName}</p>
        </div>
        <div className="mx-2">
          <p className="fs-10 text-uppercase opacity-50">{props.unit_name}</p>
          <p
            className="fw-bold fs-14 d-inline
                        "
          >
            {props.name}
          </p>
        </div>
        <div>
          <PriorityTag priority={props.priority} size="mini" />
        </div>
      </div>
    </a>
  );
}

function AttivitàCardMapMobile(props) {
  const initialsName = props.name.substr(0, 2).toUpperCase();

  return (
    <a
      onClick={() => props.handleItemSelected(props.id)}
      style={{
        cursor: "pointer",
        border:
          props.selectItem===props.id ? "1px solid lightblue" : "transparent",
      }}
    >
      <div className="attivitàCardMapMobile d-flex align-items-center">
        <div className="me-2">
          <p className="activity-assignee-circle c-30">{initialsName}</p>
        </div>
        <div>
          <p className="fs-10 text-uppercase opacity-50">{props.unit_name}</p>
          <p className="fw-bold fs-14">{props.name}</p>
        </div>
        <div className="ms-2">
          <PriorityTag priority={props.priority} size="mini" />
        </div>
      </div>
    </a>
  );
}

export { AttivitàCard, AttivitàCardMap, AttivitàCardMapMobile };
