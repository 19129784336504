import React, { createContext, useContext, useEffect, useState } from 'react';
import api, { partners_endpoint } from '../api/api';

export const PartnersContext = createContext();

export const PartnersProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);

  const [filteredPartners, setFilteredPartners] = useState([]);
  const [sortOrder, setSortOrder] = useState('A-Z');
  const [searchQuery, setSearchQuery] = useState(''); //ricerca per title

  const [selectedCategories, setSelectedCategories] = useState([]); // Ora è un array
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedServices, setSelectedServices] = useState([])

  const [cityWorkplaceArray, setCityWorkplaceArray] = useState([]);
  const [provinceWorkplaceArray, setProvinceWorkplaceArray] = useState([])
  

  const getPartners = async () => {
    try {
      const res = await api().get(`${partners_endpoint}`);
      if (res.data && res.data.content) {
        setPartners(res.data.content);
        setFilteredPartners(res.data.content); // Inizializza filteredPartners con tutti i partner

  
        // Crea un array di cityWorkplace e provinceWorkplace uniche
        const cities = res.data.content
          .map(item => item.cityWorkplace)
          .filter((city, index, self) => city && self.indexOf(city) === index)
          .map(city => ({ value: city, label: `${city} (comune)` }));
  
        const provinces = res.data.content
          .map(item => item.provinceWorkplace)
          .filter((province, index, self) => province && self.indexOf(province) === index)
          .map(province => ({ value: province, label: `${province} (provincia)` }));
  
        // Imposta gli array di cityWorkplace e provinceWorkplace
        setCityWorkplaceArray(cities);
        setProvinceWorkplaceArray(provinces); 
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  const sortPartners = (partners) => {
    let sortedPartners = [...partners];
    switch (sortOrder) {
        case 'A-Z':
            sortedPartners.sort((a, b) => {
                const titleA = a.title || ""; // Se non c'è un title, usa una stringa vuota
                const titleB = b.title || "";
                return titleA.localeCompare(titleB);
            });
            break;
        case 'Z-A':
            sortedPartners.sort((a, b) => {
                const titleA = a.title || "";
                const titleB = b.title || "";
                return titleB.localeCompare(titleA);
            });
            break;
        case 'Più costosi':
            sortedPartners.sort((a, b) => {
                const payA = parseFloat(a.pay || 0); // Se non c'è un pay, usa 0
                const payB = parseFloat(b.pay || 0);
                return payB - payA;
            });
            break;
        case 'Meno costosi':
            sortedPartners.sort((a, b) => {
                const payA = parseFloat(a.pay || 0);
                const payB = parseFloat(b.pay || 0);
                return payA - payB;
            });
            break;
        default:
            break;
    }
    return sortedPartners;
};


const filterPartners = () => {
  let filtered = partners.filter(partner => {
      // Filtra per la query di ricerca
      const matchesSearchQuery = searchQuery ?
          (partner.title && partner.title.toLowerCase().includes(searchQuery.toLowerCase())) :
          true;

      // Filtra per città o provincia
      const matchesCityOrProvince = selectedLocation ?
          (partner.cityWorkplace === selectedLocation || partner.provinceWorkplace === selectedLocation) :
          true;

      // Filtra per categoria
      const matchesCategory = selectedCategories.length > 0 ?
          selectedCategories.some(category => partner.typologies.includes(category)) :
          true;

      // Filtra per servizi
      /* const matchesServices = selectedServices.length > 0 ?
          selectedServices.some(service => service.forEach(el => el.includes(el.title))) :
          true; */

      return matchesSearchQuery && matchesCityOrProvince && matchesCategory 
  });

  // Ordina la lista filtrata
  const sortedFiltered = sortPartners(filtered);
  setFilteredPartners(sortedFiltered);
};

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <PartnersContext.Provider value={{
      filteredPartners,
      filterPartners,
      setSelectedCategories,
      setSelectedLocation,
      selectedCategories,
      selectedLocation,
      cityWorkplaceArray,
      provinceWorkplaceArray,
      getPartners,
      sortOrder,
      setSortOrder,
      searchQuery,
      setSearchQuery
    }}>
      {children}
    </PartnersContext.Provider>
  );
};

export const usePartners = () => {
  const context = useContext(PartnersContext);
  if (!context) {
    throw new Error('usePartners must be used within a PartnersProvider');
  }
  return context;
};
