import { useEffect, useState, useRef, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import axios from "axios";

import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";

import ButtonRm from "../../ButtonRm";
import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import SearchInput from "../../SearchInput";
import EmptyStateContainer from "../../EmptyStateContainer";
import GestioneCard from "./GestioneCard";

import EmptyState from "../../../assets/images/gestione-empty-state.svg";
import SearchIllustration from "../../../assets/images/search-illustration.svg";
import filter from "../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../assets/images/icon-sidebar/sort.svg"

import "../../../assets/css/StatoLavoriInCorso.css";
import "../../../assets/css/Gestioni.css";

import { management_endpoint, status_management_endpoint } from "../../../api/api";
import api from "../../../api/api.js";

import { useDispatch, useSelector } from "react-redux";
import RiassuntoContabilita from "../Chat/RiassuntoContabilita";
import { addGestione, fetchGestioni, removeGestione, editGestione } from "../../../actions/gestioni";
import { ADD_GESTIONE } from "../../../actions/types";

import FilterSearchBar from "./FilterSearchBar";

import RiassuntoContabilitaUnità from "../Chat/RiassuntoContabilitàUnità";
import { parseManagements } from "../contabilità-logic";

import ExportButton from '../../Membri/Fornitori/ExportButton'

import { checkUserData, checkMaster, checkUserRole } from "../../../utils/checkUserRole.js";
import ModalCheckUser from "../../ModalCheckUser.js";
import ShareChat from "../../ShareChat.js";

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import CustomBadge from "../../CustomBadge.js";


export default function Gestioni() {
  //GET GESTIONI LIST FROM REDUX
  const gestioniList = useSelector((state) => state.gestioniReducer.gestioni);

  //GESTIONI DATA DERIVED FROM GESTIONILIST BUT WITH A PROPERTY TO HANDLE CHECKBOX
  const [gestioni, setGestioni] = useState([]);

  //HANDLE SEARCH
  const [searchTerm, setSearchTerm] = useState("");

  //HANDLE DATA FOR THE CREATION OF A NEW GESTIONE
  const [newGestione, setNewGestione] = useState({
    name: "",
  });

  //MODAL TO HANDLE CREATION OF A NEW GESTIONE
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const dispatch = useDispatch();

  //DERIVED LIST WITH GESTIONI CHECKED


  const [selectedList, setSelectedList] = useState([]
    )


  const inputRef = useRef([]);

  //HANDLE NEW GESTIONE DATA
  function handleNewGestione(e) {
    setNewGestione((prev) => ({ ...prev, name: e.target.value }));
  }

  //ADD "checked" key to all the items of "gestioni" list
  function addCheckedKey(data) {
    const checkedArray = data?.map((gestione) => {
      return { ...gestione, checked: false };
    });
    return checkedArray;
  }

  //handle checked items
  function handleCheckbox(id) {
    const newGestioni =
      gestioni?.length > 0 &&
      gestioni.map((gestione) => {
        return gestione.id===id
          ? { ...gestione, checked: !gestione.checked }
          : gestione;
      });
    setGestioni(newGestioni);
  }


  //CREATION  
  function postItem(data) {
    dispatch(addGestione(data)).then(() => {
        dispatch(fetchGestioni());
      }
    )
  }

  //DELETE - check first if this gestione have some units related to itself and if no, delete it
  function handleDelete(e) {
    e.preventDefault();
    selectedList.forEach((item) => {
       /*  if(item.realEstateManagements.length > 0){
          console.log("Questa gestione non può essere eliminate perché contiene delle unità associate")
        } else { */
          dispatch(removeGestione(item.id))
          
        /* } */
    });
  }

 

  //EDIT
  async function editItem(id, data) {
    dispatch(editGestione(id, data)).then(() => {
      dispatch(fetchGestioni());
      }
    )
  }

  function handleEdit() {
    inputRef.current[selectedList[0]].focus();
  }

  //ARCHIVE ==> DA GESTIRE IN REDUX
  const onArchive = async (id) => {
    try {
      await api().put(`${status_management_endpoint}${id}`);
      // await getGestioni();
    } catch (error) {
      console.log(error);
    }
  };

  function handleArchive(e) {
    e.preventDefault();
    selectedList.forEach((item) => onArchive(item.id));
  }

  //get gestioni when this component render for the first time
  useEffect(() => {
    dispatch(fetchGestioni())
  }, []);

  //set GESTIONI===GESTIONILIST FROM REDUX
  useEffect(() => {
    if(gestioniList){
      setGestioni(addCheckedKey(gestioniList))
    }
  }, [gestioniList]); 

  //send data to create a new GESTIONE
  function handleSubmit(e) {
    e.preventDefault();
    if (newGestione.name) {
      postItem(newGestione);
      setNewGestione({ name: "" });
    }
    handleClose();
  }


 //NEW SEARCH & FILTER

	//SEARCH


	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

  


    const categories = [
		/* {
		name: 'Tipologia',
		items: [
			"Affitto",
			"Proposta"
		]
		},
		{
		name: 'Unità',
		items: units.map((unit) => unit.name),

		},
		{
			name: 'Gestione',
			items: gestioni.map(el => String(el.id)),
		} */
      
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (gestioni.length > 0) {
        setFilteredUnits(
			gestioni.filter((el) => {

            const elDate = new Date(el.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && elDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && elDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return el.units.some((unit) => {
						return cat.items.includes(unit.name);
						});
					}  
					})) &&
					el.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, gestioni, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(gestioni);
		setStartDate("")
		setEndDate("")

		}

  //GESTIONE RC DA BUTTON CONTABILITA'

  const [gestioneForRc, setGestioneForRc] = useState({})


  function handleButtonContabilità(item){
    setGestioneForRc(item)
  }

  const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    name: "Identificativo",
    realEstateManagements: "Immobili della gestione"
  }

 


  /* MODAL Riassunto contabilita' */
  const [showRc, setShowRc] = useState(false);
  const handleShowRc = () => setShowRc(true)
  const handleCloseRc = () => {
    setGestioneForRc({})
    setShowRc(false)
  };

  //LOGICA PER RIASSUNTO CONTABILITA'

  const contabilitàGestioneTotale = parseManagements(gestioni)
  const contabilitàGestione = selectedList.length > 0? contabilitàGestioneTotale[selectedList[0].name] : {}

  //CONTABILITA' BTN

  const contabilitàClickBtn = gestioneForRc.name?  contabilitàGestioneTotale[gestioneForRc.name] : {}
  //per prendere l'oggetto on click del btn forse mi basta solo gestioneForRc.id

  console.log(gestioneForRc, "gestione cliccata")

  //CHECK IF DATA REQUIRED ARE COMPILED
  const [dataRequired, setDataRequired] = useState(true);
  //Gestisce il modale
  const [showCheck, setShowCheck] = useState(false)

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await checkUserData();
              setDataRequired(response.isVerified);
              if (!response.isVerified) {
                setShowCheck(true);
            }
          } catch (error) {
              // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
              console.error('Errore durante il recupero dei dati utente:', error);
          }
      };

      fetchData();
  }, []);

   //handle modal delete
   const [showModal, setShowModal] = useState(false);
   const handleCloseModal = (e) => {
     setShowModal(false);
     e.preventDefault();
     e.stopPropagation();
   };

   const handleShowModal = () => setShowModal(true);


  //handle modal permessi
  const [showPermit, setShowPermit] = useState(false);
  const handleClosePermit = (e) => {
    setShowPermit(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleShowPermit = () => setShowPermit(true);

      //MI SERVE LISTA COLLABORATORI MASTER
      const [members, setMembers] = useState([
      {
        id:1,
        name:"Ema",
        surname:"Mavi",
        email:"ema@gmail.com"
      },
      {
        id:2,
        name: 'Greta',
        surname: 'Pio',
        email: 'gre@gmail.com'
      }
      ])
    
      const handleCheckboxChange = (userId, action) => {
      //da gestire
      };

       // Funzione per eliminare gli stabili selezionati
	 const deleteSelected = async () => {
    // Effettua l'operazione di eliminazione per ogni id selezionato
    for (const id of selectedList) {
    try {
      await api().delete(`${management_endpoint}${id}`);
    } catch (err) {
      console.log(err);
    }
    }
    dispatch(fetchGestioni())
    setSelectedList([])
   

  };

  const handleSelectAll = () => {
		let selectedItems = sortedItems.map((item, index) => item.id)
    setSelectedList(selectedItems)
	}

	const handleDeselectAll = () => {
    setSelectedList([])


	}


  const handleSelection = (e,id) => {
		if(e){
			e.stopPropagation()
		}

		if (selectedList.includes(id)) {
			setSelectedList(selectedList.filter((itemId) => itemId !== id));
		} else {
			setSelectedList([...selectedList, id]);

		}
	 };


  return (
    <>        
      <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="empty"
          />
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Gestioni</h2>
          </Col>
          <Col className="d-flex justify-content-end ">
              <AddBtn
                tooltip="Nuova gestione"
                className="me-3 me-md-0 order-md-1"
                onClick={handleShow}
                dataRequired={dataRequired}
              />
          </Col>
        </Row>
      </div>

      <Row className="dashboard-container">

      {gestioni.length > 0 ? 
        <Row className="position-relative mx-0 px-0">
          <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
            <FilterSearchBar
              setSearchTerm={setSearchTerm}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              clearFiltersAndSort={clearFiltersAndSort}
              setSortBy={setSortBy}
              categories={categories}
              setStartDate={setStartDate} setEndDate={setEndDate}
            />
          </Col>
          <div>
          {checkUserRole() &&
								selectedList.length > 0 && 
								<div className="d-flex mb-3">
									<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
										Seleziona tutti
									</ButtonRm>
									<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
										Deseleziona Tutti
									</ButtonRm>
								</div>
								}
          </div>
            {sortedItems.length > 0 ? 
              sortedItems.map((gestione) => {
                return (
                  <GestioneCard
                    key={gestione.id}
                    id={gestione.id}
                    name={gestione.name}
                    creation_date={gestione.cdat}
                    assignee={gestione?.assignee}
                    update_date={gestione.udat}
                    checked={gestione.checked}
                    //handleCheckbox={handleCheckbox}
                    onDelete={handleDelete}
                    onEdit={editItem}
                    item={gestione}
                    ref={(el) => (inputRef.current[gestione.id] = el)}
                    handleShowRc={handleShowRc}
                    handleButtonContabilità={handleButtonContabilità}
                    handleSelection={handleSelection}
                    selectedList={selectedList}
                  />
                );
              }) : 
              <EmptyStateContainer
                content="Nessuna corrispondenza trovata"
                img={SearchIllustration}
              />
            }
          <Row className="alert-container">
            {selectedList.length===1 && (
              <Alert  className="alert-options " style={{background:"#606F88", height:"fit-content"}}>
{/*                 <ShareChat  isButton={false}/>
 */}                <Alert.Link className="me-2" href="#">
                  <ExportButton data={selectedList[0]} mapping={translationMapping} isButton={false} classes="text-white"/>
                </Alert.Link>
                <Alert.Link className="me-2" onClick={handleEdit}>
                  Modifica
                </Alert.Link>
                <Alert.Link className="me-2" onClick={handleShowModal}>
                  Elimina
                </Alert.Link>
                { ( selectedList.length == 1 && checkMaster()) &&
                  <Alert.Link 
                    className="me-2" 
                    onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleShowPermit();
                  }}>
                    Permessi
                  </Alert.Link>
                  }
                {/* <Alert.Link className="me-2" href="#" onClick={handleArchive}>
                  Archivia
                </Alert.Link> */}
               {/*  <Alert.Link className="me-2" href="#" onClick={handleShowRc}>
                  Contabilità
                </Alert.Link> */}
              </Alert>
            )}

            {selectedList.length > 1 && (
              <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
{/*                 <Alert.Link className="me-2" href="#">
                  Esporta
                </Alert.Link> */}
                <Alert.Link className="me-2" onClick={handleShowModal}>
                  Elimina
                </Alert.Link>
               {/*  <Alert.Link className="me-2" onClick={handleArchive}>
                  Archivia
                </Alert.Link> */}
              </Alert>
            )}
          </Row>
        </Row> :
        <Row className="dashboard-container ">
          <div className="mx-auto text-center empty-container ">
            <div className="mx-auto d-table w-160">
              <img loading="lazy" src={EmptyState} alt="empty" />
            </div>
            <p className="fs-20 text-secondary text-center mt-3">
              Non hai aggiunto ancora alcuna gestione
            </p>
          </div>
        </Row>
      }
      </Row>

      {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
      <Modal show={show} onHide={handleClose} className="p-5" centered >
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className=" pt-md-5 px-md-5"
          >
            <Modal.Title>Nuova Gestione</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 px-md-5">
            <Form>
              <Form.Group className=" d-flex flex-column" controlId="gestioneName">
                <Form.Label>Nome gestione  <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <input
                  type="text"
                  placeholder="Inserisci il nome della gestione"
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem" 
                  }}
                  onChange={handleNewGestione}
                  value={newGestione.name}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer
            className="d-flex flex-column  px-md-5 pb-md-4"
            style={{ borderTop: "none" }}
          >
            <ButtonRm variant="primary"  onClick={handleSubmit} disabled={newGestione.name ? false : true}>
              Salva
            </ButtonRm>
            <ButtonRm variant="text" onClick={handleClose}>
              Annulla
            </ButtonRm>
          </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={(e) => handleCloseModal(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleCloseModal(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleCloseModal(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

      <Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

{/*       <RiassuntoContabilita chat={gestioneForRc.id? objForContabilitàBtn : objRiassuntoContabilità} showRc={showRc} handleCloseRc={handleCloseRc}/> */}

  <RiassuntoContabilitaUnità showRc={showRc} handleCloseRc={handleCloseRc} totalCosts={contabilitàGestione && Object.keys(contabilitàGestione).length > 0 ? contabilitàGestione : {}} item={selectedList.length > 0 ? selectedList[0] : {}} onEdit={editItem}/>

  {
    gestioneForRc.name &&  <RiassuntoContabilitaUnità showRc={true} handleCloseRc={handleCloseRc} totalCosts={contabilitàClickBtn && Object.keys(contabilitàClickBtn).length > 0 ? contabilitàClickBtn : {}}
    item={gestioneForRc} onEdit={editItem}/>
  }

<ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione delle gestioni, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>
    </>
  );
}
