// utils/Data.js
 const Data = {
 /*  labels: ['Legno', 'Ferro', 'Cemento'],
  datasets: [
    {
      label: '',
      data: [1, 3, 6],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 1,
    },
  ], */
};

const Data1 = {
    /* labels: ['Persone', 'Materiali', 'Mezzi', 'Spese Generali'],
    datasets: [
      {
        label: '',
        data: [1, 1,1,1],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ], */
  };

  export  {Data, Data1}