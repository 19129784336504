import React, { useState, useEffect } from "react";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard";
import photoImg from "../../../../assets/images/photo.png";
import UploadImg from "../../../../assets/images/upload_file.svg";
import ImgFileCard from "../ImgFileCard";
import ButtonRm from "../../../ButtonRm";
import axios from "axios";
import mimeTypes from "../../../../allegatiType";
import { public_page_image, BASE_URL  } from "../../../../api/api";

const PhotoE = ({ photo, planimetry, img, update, editMode, saveState, id }) => {
	const [data, setData] = useState({});

	const [previewNewPhoto, setPreviewNewPhoto] = useState([]);
	const [previewNewPlanimetry, setPreviewNewPlanimetry] = useState([]);


	const init = () => {
		setData({
			photo: photo?.length > 0? [...photo] : [],
			planimetry: planimetry?.length > 0? [...planimetry] : []
		});
	};

	useEffect(() => {
		if (photo && planimetry) init();
	}, [photo, planimetry]);

	//const [editMode, setEditMode] = useState(false);

	/* const handleSave = () => {
		setEditMode(false);
		update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */



	const setPhotoFileName = (index, new_name) => {
		let temp = [...data.photo];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setData({ ...data, ["photo"]: temp });
	};
	const deletePhotoFile = (index) => {
		let temp = [...data.photo];
		temp.splice(index, 1);
		setData({ ...data, ["photo"]: temp });
	};
	const setPlanimetryFileName = (index, new_name) => {
		let temp = [...data.planimetry];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setData({ ...data, ["planimetry"]: temp });
	};
	const deletePlanimetryFile = (index) => {
		let temp = [...data.planimetry];
		temp.splice(index, 1);
		setData({ ...data, ["planimetry"]: temp });
	};

	const [dragActivePhoto, setDragActivePhoto] = useState(false);
	const [dragActivePlanimetry, setDragActivePlanimetry] = useState(false);

	// handle drag events
	const handleDragPhoto = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActivePhoto(true);
		} else if (e.type === "dragleave") {
			setDragActivePhoto(false);
		}
	};

	const handleDragPlanimetry = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActivePlanimetry(true);
		} else if (e.type === "dragleave") {
			setDragActivePlanimetry(false);
		}
	};

	// triggers when file is dropped
	const handleDropPhoto = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActivePhoto(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let temp = [...data.photo];
			temp = [...temp, ...e.dataTransfer.files];
			setData({ ...data, ["photo"]: temp });
		}
	};

	const handleDropPlanimetry = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActivePlanimetry(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let temp = [...data.planimetry];
			temp = [...temp, ...e.dataTransfer.files];
			setData({ ...data, ["planimetry"]: temp });
		}
	};

	//GESTIONE ALLEGATI NUOVI
	const [newPhoto, setNewPhoto] = useState([])
	const [newPlanimetry, setNewPlanimetry] = useState([])

	const handleChangePhoto = (e) => {
		let temp = [...newPhoto];
		setNewPhoto([...temp, ...e.target.files]);
	  
		// Genera l'anteprima dei nuovi file foto
		const previewFiles = Array.from(e.target.files).map((file) => ({
		  preview: URL.createObjectURL(file),
		  name: file.name
		}));
		setPreviewNewPhoto((prevPreview) => [...prevPreview, ...previewFiles]);
	  };
	  
	  const handleChangePlanimetry = (e) => {
		let temp = [...newPlanimetry];
		temp = [...temp, ...e.target.files];
		setNewPlanimetry(temp);
	  
		// Genera l'anteprima dei nuovi file planimetria
		const previewFiles = Array.from(e.target.files).map((file) => ({
		  preview: URL.createObjectURL(file),
		  name: file.name
		}));
		setPreviewNewPlanimetry((prevPreview) => [...prevPreview, ...previewFiles]);
	  };
	  
	  


	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		let data = {
		  newPhoto: [...newPhoto],
		  newPlanimetry: [...newPlanimetry]
		};
		if (saveState === true) {
		  update(data);
		  // Pulisci le anteprime dei nuovi file dopo il salvataggio
		  setPreviewNewPhoto([]);
		  setPreviewNewPlanimetry([]);
		} else {
		  init();
		}
	  }, [saveState, editMode]);
	  

		const downloadFile = async (name) => {
			const fileUrl = `${BASE_URL}api/v1/unit/download/${id}/${name}`;
			const response = await axios({
			url: fileUrl,
			method: 'GET',
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${
				JSON.parse(localStorage.getItem("user"))?.token
				}`,
				"Content-Type": `application/json`,
			},
		});
	
		// ottieni l'estensione del file
		const fileExtension = name.split('.').pop();
	
		const mimeType = mimeTypes[fileExtension];
	
		// crea un nuovo Blob con il tipo MIME corretto
		const file = new Blob([response.data], { type: mimeType });
	
		// crea un URL per il Blob
		const fileURL = URL.createObjectURL(file);
	
		// scarica il file
		window.open(fileURL);
	};


	console.log (newPhoto, newPlanimetry)

	return (
		<div>

			<div className="edit-content-container mt-3">
				<div className="form-item-align-box-white d-block">
				<label htmlFor="input-file-photo" className={editMode ? "" : "d-none"}>
					<div className="edit-btn edit-white me-2  mb-5">Aggiungi foto</div>
					</label>
					<input
					type="file"
					id="input-file-photo"
					className="d-none"
					multiple={true}
					name="photo"
					onChange={handleChangePhoto}
					/>

					<label htmlFor="input-file-planimetry" className={editMode ? "" : "d-none"}>
					<div className="edit-btn edit-white me-2  mb-5">Aggiungi planimetria</div>
					</label>
					<input
					type="file"
					id="input-file-planimetry"
					className="d-none"
					multiple={true}
					name="planimetry"
					onChange={handleChangePlanimetry}
					/>

					{(data.photo?.length === 0 && data.planimetry?.length === 0  && newPhoto.length == 0 && newPlanimetry.length == 0)?  (
						<div className="unit-empty-img">
							<img loading="lazy" src={photoImg} alt="No image" />
							<span className="img-comment">
								Non hai aggiunto ancora nessuna foto o planimetria
							</span>
						</div>
					) : (
						<>
							{(data.planimetry?.length > 0 || newPlanimetry.length> 0) && (
								<>
									<div className="label-heading mb-3 d-block w-100 mt-4">
										<div className="label-text rel d-inline-block px-2 title-padding">
											<h1 className="fs-16 text-white text-uppercase ">
												PLANIMETRIA
											</h1>
										</div>
									</div>
									<div className="d-flex flex-wrap">
										{data.planimetry.map((item, index) => {
											return (
												<div key={index}>
													<ImgFileCard id={id} name={item} path="download_planimetry" />
													<div className="image-div">
														<FileCard
															id={id}
															name={item}
															setFileName={setPlanimetryFileName}
															deleteFile={deletePlanimetryFile}
															view_mode={editMode}
															download={downloadFile}

														/>
													</div>
												</div>
											);
										})}
										{previewNewPlanimetry.map((item, index) => (
											<div key={index}>
												<div className="image-file-card" key={index}>
												<img loading="lazy" src={item.preview} alt="Preview" />
												</div>
												<div className="image-div">
												<FileCard
													id={id}
													name={item.name}
													setFileName={setPlanimetryFileName}
													deleteFile={deletePlanimetryFile}
													view_mode={editMode}
													download={downloadFile}
												/>
												</div>
											</div>
											))}




									</div>
								</>
							)}
							{(data.photo?.length || newPhoto.length > 0 )  && (
								<>
									<div className="label-heading mb-3 d-block w-100 mt-4">
										<div className="label-text rel d-inline-block px-2 title-padding">
											<h1 className="fs-16 text-white text-uppercase ">FOTO</h1>
										</div>
									</div>
									<div className="d-flex flex-wrap">
										{data.photo.map((item, index) => {
											return (
												<div key={index}>
													<ImgFileCard id={id} name={item} path="download_photo"/>
													<div className="image-div">
														<FileCard
															id={id}
															name={item}
															setFileName={setPhotoFileName}
															deleteFile={deletePhotoFile}
															view_mode={editMode}
															download={downloadFile}

														/>
													</div>
												</div>
											);
										})}
										{previewNewPhoto.map((item, index) => (
											<div>
											<div className="image-file-card" key={index}>
												<img loading="lazy" src={item.preview} alt="Preview" />
											</div>

											<div className="image-div">
												<FileCard
													id={id}
													name={item.name}
													setFileName={setPlanimetryFileName}
													deleteFile={deletePlanimetryFile}
													view_mode={editMode}
													download={downloadFile}

												/>
											</div>
										
										</div>
										))}
										
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

  

export default PhotoE;
