import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import bacheca from "../assets/images/icon-menu-slc/bacheca.svg";
import chat from "../assets/images/icon-menu-slc/chat.svg";
import setting from "../assets/images/icon-menu-slc/gear.svg";
import task from "../assets/images/icon-menu-slc/task.svg";
import gestioni from "../assets/images/icon-menu-slc/gestioni.svg";
import unit from "../assets/images/icon-menu-slc/house.svg";
import download from "../assets/images/icon-menu-slc/export.svg";
import { useMediaQuery } from "react-responsive";
import { checkSupplier, checkUserRole, checkAdmin } from "../utils/checkUserRole";
import supporto from "../assets/images/icon-sidebar/support-sb.svg"



export default function MenuSlc(props) {
	const [subMenuOpened, setSubMenuOpened] = useState([
		true,
		true,
		true,
		true,
		true,
		true,
		true,
	]);
	const onHandleCollapse = (index) => {
		const tempSubMenu = [true, true, true, true, true, true, true];
		if (subMenuOpened[index] !== false) {
			tempSubMenu[index] = false;
		}

		setSubMenuOpened(tempSubMenu);
	};
	const setMobileMenu = () => {
		if (typeof props.setMobileMenu === "function") {
			props.setMobileMenu(false);
		}
	};

	const isDesktop = useMediaQuery({
		query: "(min-width: 992px)",
	});

	console.log (
		checkSupplier(), checkUserRole()
	)

	return (
		<>
            <ul className="links-log ls p-0 d-flex flex-column mt-4">
				<li>
                    <Link to="/statoavanzamentolavori/bacheca" onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={bacheca} alt="icon" />
						</span>
						<span className="text-link">Bacheca</span>
					</Link>
                </li>

                {(checkUserRole())  && <li>
                    <Link to="/statoavanzamentolavori/gestioni" onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={gestioni} alt="icon" />
						</span>
						<span className="text-link">Gestioni</span>
					</Link>
                </li>}

                <li>
                    <Link to="/statoavanzamentolavori/unitaestabili" onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={unit} alt="icon" />
						</span>
						<span className="text-link">Unità e Stabili</span>
					</Link>
				</li>

                <li>
                    <Link to="/statoavanzamentolavori/attivita" onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={task} alt="icon" />
						</span>
						<span className="text-link">Attività</span>
					</Link>
				</li>
              {/*   <li>
                    <Link to={`${
										isDesktop
											? "/statoavanzamentolavori/chat"
											: "/statoavanzamentolavori/chat"
									}`} onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={chat} alt="icon" />
						</span>
						<span className="text-link">Chat</span>
					</Link>
				</li> */}

				{/* SOLO PER UTENTI MASTER */}
				{/* { <li>
                    <Link to="/statoavanzamentolavori/trova-fornitore" onClick={setMobileMenu}>
						<span className="icon-link">
							<i class="bi bi-person-add"></i>
						</span>
						<span className="text-link">Trova un fornitore</span>
					</Link>
				</li>} */}

				{/* {(!checkUserRole())  && 
				<li>
                    <Link to="/statoavanzamentolavori/supporto" onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={supporto} alt="icon" />
						</span>
						<span className="text-link">Supporto</span>
					</Link>
				</li>}
 */}
				{(!checkUserRole())  && 
				<li>
                    <Link to="/statoavanzamentolavori/impostazioni" onClick={setMobileMenu}>
						<span className="icon-link">
							<img loading="lazy" src={setting} alt="icon" />
						</span>
						<span className="text-link">Impostazioni</span>
					</Link>
				</li>}

                
                
        


			</ul>
		</>
	)
}
