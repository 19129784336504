import React from "react";

const CadastreCard = ({
	id,
	modificationTypes,
	modificationDate,
	associatedWork,
}) => {
	return (
		<div className="cadastre-card">
			<span>{modificationTypes}</span>
			<span>{modificationDate}</span>
			<span>{associatedWork}</span>
		</div>
	);
};

export default CadastreCard;
