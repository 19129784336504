import { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import TopHeading from "../../../TopHeading";
import AddBtn from "../../../AddBtn";
import EmptyStateContainer from "../../../EmptyStateContainer";
import "../../../../assets/css/ImpostazioniChat.css";
import emptyState from "../../../../assets/images/squadre.svg";
import SearchInput from "../../../SearchInput";
import ButtonRm from "../../../ButtonRm";

import { MultiSelect } from "react-multi-select-component";
import Toast from "react-bootstrap/Toast";
import Card from "react-bootstrap/Card";

import api from "../../../../api/api"
import { team_endpoint, owner_endpoint, supplier_endpoint, conductor_endpoint } from "../../../../api/api";

import SearchIllustration from "../../../../assets/images/search-illustration.svg";

const bodyForCreate = {
  name: "",
  members: [],
}

//UPDATE MAGGIO 2024: CREO LA LISTA DI SQUADRE CON GLI UTENTI GIA' INVITATI ALL'INTERNO DELLA PIATTAFORMA

function Squadre() {
  //LIST OF SQUADRE
  const [squadre, setSquadre] = useState([])
  //LIST OF PARTECIPANTI TO FILL UP "PARTECIPANTI" FIELD
  const [members, setMembers] = useState([])

  //HANDLE SEARCH
  const [searchTerm, setSearchTerm] = useState("");
  const searchedList =
  squadre && squadre.length > 0
    ? squadre.filter((squadra) =>
        squadra.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    : [];

  //VARIABLE FOR THE CREATION OF A NEW SQUADRA
  const [newTeam, setNewTeam] = useState(bodyForCreate)
  //MODAL CREATE: CREATE SQUADRA
  const [show, setShow] = useState(false)
  const handleClose = () => {setShow(false)}
  const handleShow = () => setShow(true)

  //HANDLE NEW SQUADRA DATA
  function handleTeam(e) {
    const {value, name} = e.target
    setNewTeam(prev => {
      return {
        ...prev,
        [name]:value
      }
    });
  }

  //HANDLE CREATION SUBMIT
  async function handleSubmit(e) {
    let data
    if(newTeam.name){
      data = {
        ...newTeam,
        members: selected.map(el => el.value),
      }
      await postItem(data)
      setNewTeam(bodyForCreate);
    }
    handleClose()
    setSelected([])
  }

  //REMOVE A SELECTED PARTECIPANTS DURING CREATION
  function removeItem(id){
    const newArr = selected.filter(
      item => item.key != id
    )
    setSelected(newArr)
  }

  //VARIABLE TO HANDLE EDIT OF A SQUADRA
  const [editMode, setEditMode] = useState(false)
  const [editedItem, setEditedItem] = useState({})
  const [editedItemId, setEditedItemId] = useState("")
  //MODAL EDIT: EDIT SQUADRA
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => {
        setEditMode(false)
        setEditedItem({})
        setShowEdit(false)
        setEditedItemId("")
    };
  const handleShowEdit = () => setShowEdit(true)
  
  //HANDLE EDIT MODE
  function handleEditMode(id){
    setEditMode(true)
    getOne(id)
    handleShowEdit()
    setEditedItemId(id)
  }

  //HANDLE EDIT ITEM DATA
  function handleEditChange(e) {
    const {value, name} = e.target
    setEditedItem(prev => {
      return {
        ...prev,
        [name]:value
      }
    });
  }

  //HANDLE EDIT SUBMIT
  async function handleEdit(e) {
    let data
    let newMembers = selected.map(el => el.value)
    if(editedItem?.name && selected.length > 0){
      data = {
        ...editedItem,
        members: [...editedItem.members, ...newMembers ]
      }
      await editItem(data)
      setEditedItem({})
      setEditMode(false)
      setEditedItemId("");
    } else if(editedItem.name && selected.length == 0){
      data = {
        ...editedItem,
        members: [...editedItem.members]
      }
      await editItem(data)
      setEditedItem({})
      setEditMode(false)
      setEditedItemId("")
    }
    handleCloseEdit();
  }

  //REMOVE SA SAVED PARTECIPANTS DURING EDIT
  function removeEditItem(index){
    const newArr = editedItem?.members?.filter(
      (item, i) => i != index)
    setEditedItem(prev => {
      return {
        ...prev,
        members: newArr
      }
    })
  }

  //HANDLE DELETE SQUADRA
  function handleDelete(id) {
    onDelete(id)
  }

  //HANDLE MULTISELECT MODAL
  const [selected, setSelected] = useState([]);
  const partecipanti = members.map((user) => {
    return {
      key: user.id,
      label: `${user.name ? user.name : ""} <${user.email}>`,
      value: user.email
    }
  })

  //PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE 
  const overrideString = {
    selectAll: "Seleziona tutti",
    search: "Cerca",
    selectSomeItems: "Seleziona",
    allItemsAreSelected: "Tutti gli elementi sono selezionati",
  }

  //CREATE THE "PARTECIPANTI" LIST WITH ITEMS FROM SECTION PROPRIETARI
  //CONDUTTORI E FORNITORI
  const [owners, setOwners] = useState([])
  const [conductors, setConductors] = useState([])
  const [suppliers, setSuppliers] = useState([])

  async function getOwners(){
    try {
      const response = await api().get(`${owner_endpoint}`);
        if (response.data.content) {
          setOwners(response.data.content)
        }
    } catch (error) {
      console.log(error);
    }
  }

  async function getSuppliers(){
    try {
      const response = await api().get(`${supplier_endpoint}`);
        if (response.data.content) {
          setSuppliers(response.data.content)
        }
    } catch (error) {
      console.log(error);
    }
  }

  async function getConductors(){
    try {
      const response = await api().get(`${conductor_endpoint}`);
        if (response.data.content) {
          setConductors(response.data.content)
        }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOwners()
    getConductors()
    getSuppliers()
  }, [])


  useEffect(() =>{
    if(owners.length > 0 || conductors.length > 0 || suppliers.length > 0){
      setMembers([...owners,...suppliers,...conductors])
    }
  }, [owners, conductors, suppliers])


  //API REQUESTS FOR SQUADRE

  //GET ALL SQUADRE
  async function getAll(){
    try {
      const response = await api().get(`${team_endpoint}`);
        if (response.data.content) {
            setSquadre(response.data.content)
        }
    } catch (error) {
      console.log(error);
    }
  }

   //GET ONE SQUADRA
  async function getOne(id){
    try {
      const response = await api().get(`${team_endpoint}${id}`);
        if (response.data) {
            setEditedItem(response.data)
        }
    } catch (error) {
      console.log(error);
    }
  }

  //CREATE A SQUADRA
  async function postItem(data){
    try {
        const response = await api().post(`${team_endpoint}`, data);
        await getAll()
    } catch (error) {
      console.log(error);
    }
  }

  //EDIT A SQUADRA
  async function editItem(item) {
    try {
      await api().put(`${team_endpoint}${editedItemId}`, item);
      await getAll()
    } catch (error) {
      console.log(error);
    }
  }

  //DELETE A SQUADRA
  async function onDelete(id) {
    try {
      await api().delete(
        `${team_endpoint}${id}`
      );
      await getAll();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAll()
  }, [])


  //SORT LIST
  function sortAZ() {
    const sortedArrAZ = [...squadre].sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setSquadre(sortedArrAZ);
  }

  function sortZA() {
    const sortedArrZA = [...squadre].sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();
      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });
    setSquadre(sortedArrZA);
  }

  return (
    <>
      <Row>
        <Col>
          <TopHeading SmallHeading="" back_modal={false} />
        </Col>
      </Row>

      <Row className=" d-flex flex-column mt-md-4">
        <Col>
          <h2 className="fw-bold text-center">Squadre</h2>
        </Col>
        <Col className="d-flex mt-3 justify-content-md-between">
          <AddBtn
            tooltip="Nuova squadra"
            className="me-3 me-md-0 order-md-1"
            onClick={handleShow}
            dataRequired={true}

          />
          <SearchInput
            className="order-md-0"
            setSearch={(term) => {
              setSearchTerm(term);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center justify-content-end">
          <div className="d-flex aling-items-center me-2 mt-4">
            {/* <i className="bi bi-arrow-down-up"></i>
                <p className="fs-14 ms-1 mt-1">Ordina</p> */}
            <div className="user-dropdown">
              <div className="sort d-flex aling-items-center">
                <i className="bi bi-arrow-down-up"></i>
                <p className="fs-14 ms-1">Ordina</p>
              </div>
              <div className="user-dropdown-content">
                <div className="fs-16 fw-bold">Ordina</div>
                <div
                  className="fs-14 mt-3 mb-1"
                  onClick={sortAZ}
                  style={{ cursor: "pointer" }}
                >
                  Dalla A alla Z
                </div>
                <div
                  className="fs-14"
                  style={{ cursor: "pointer" }}
                  onClick={sortZA}
                >
                  Dalla Z alla A
                </div>
              </div>
            </div>
          </div>
        </Col>
        {searchedList.length > 0 && (
          <div
            className="mb-5 mt-2 d-flex flex-wrap empty-state-container justify-content-center"
            style={{ padding: "3rem" }}
          >
            {searchedList.map((squadra) => (
              <SquadreCard
                key={squadra.id}
                squadra={squadra}
                handleDelete={handleDelete}
                handleEditMode={handleEditMode}
              />
            ))}
          </div>
        )}
        {searchTerm && searchedList.length === 0 && (
          <EmptyStateContainer
            content="Nessuna corrispondenza trovata"
            img={SearchIllustration}
          />
        )}
        {!searchTerm && squadre.length === 0 && (
          <EmptyStateContainer
            content="Non hai aggiunto ancora alcuna squadra"
            img={emptyState}
          />
        )}
      </Row>
      {/* QUI POSSIAMO SEMPLIFICAR EIL UTTO USANDO UN UNICO MODAL E LA VAIRBAILE EDITMODE  */}
      {/*modal create*/}
      <Modal
        show={show}
        onHide={handleClose}
        className="p-5 squadreModal"
        centered
      >
        <Modal.Header
          closeButton
          className=" pt-md-4 px-md-5 modalCustomHeader"
        >
          <Modal.Title>Nuova Squadra</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-3 px-md-5">
          <Form>
            <Form.Group
              className="mb-3 d-flex flex-column"
              controlId="gestioneName"
            >
              <Form.Label>Nome squadra</Form.Label>
              <input
                type="text"
                name="name"
                placeholder="Inserisci il nome della squadra"
                style={{
                  height: "50px",
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem",
                  minWidth: "400px",
                }}
                onChange={handleTeam}
                value={newTeam.name}
              />
            </Form.Group>
            <div className="d-flex align-items-center justify-content-between">
              <Col md={12}>
                <Form.Group
                  className="mb-3 d-flex flex-column"
                  controlId="gestioneName"
                >
                  <Form.Label>Partecipanti</Form.Label>
                  <MultiSelect
                    options={partecipanti}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Seleziona"
                    overrideStrings={overrideString}
                    /* isCreatable={true}
                    onCreateOption={handleNewField} */
                  />
                </Form.Group>
              </Col>
             {/*  <Col md={1} className="mt-2">
                <i
                  className="bi bi-plus-circle-fill ms-2 pt-2 fs-30"
                  style={{ cursor: "pointer" }}
                  onClick={addNewParticipants}
                ></i>
              </Col> */}
            </div>
          </Form>
          {selected.length > 0 && (
            <div className="mt-3">
              {selected.map((item) => (
                <NewItem key={item.id} item={item} removeItem={removeItem}/>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="d-flex flex-column  px-md-5 pb-md-4"
          style={{ borderTop: "none" }}
        >
          <ButtonRm
            variant="primary"
            onClick={handleSubmit}
            disabled={!newTeam.name}
          >
            Salva
          </ButtonRm>
          <ButtonRm variant="text" onClick={handleClose}>
            Annulla
          </ButtonRm>
        </Modal.Footer>
      </Modal>

      {/*edit modal*/}
      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        className="p-5 squadreModal"
        centered
      >
        <Modal.Header
          closeButton
          className=" pt-md-4 px-md-5 modalCustomHeader"
        >
          <Modal.Title>Modifica Squadra</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-3 px-md-5">
        <Form>
            <Form.Group
              className="mb-3 d-flex flex-column"
              controlId="gestioneName"
            >
              <Form.Label>Nome squadra</Form.Label>
              <input
                type="text"
                name="name"
                placeholder="Inserisci il nome della squadra"
                style={{
                  height: "50px",
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem",
                  minWidth: "400px",
                }}
                onChange={handleEditChange}
                value={editedItem?.name}
              />
            </Form.Group>
            <div className="d-flex align-items-center justify-content-between">
              <Col md={12}>
                <Form.Group
                  className="mb-3 d-flex flex-column"
                  controlId="gestioneName"
                >
                  <Form.Label>Partecipanti</Form.Label>
                  <MultiSelect
                    options={partecipanti}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Seleziona"
                    overrideStrings={overrideString}
                    /* isCreatable={true}
                    onCreateOption={handleNewField} */
                  />
                </Form.Group>
              </Col>
            </div>
        </Form>
          {editedItem?.members?.length > 0 && (
              <div className="mt-3">
                {editedItem.members.map((item,index) => (
                  <NewEditItem key={index} index={index} item={item} removeEditItem={removeEditItem}/>
                ))}
              </div>
          )}
          {selected.length > 0 && (
            <div className="mt-3">
              {selected.map((item) => (
                <NewItem key={item.id} item={item} removeItem={removeItem}/>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="d-flex flex-column  px-md-5 pb-md-4"
          style={{ borderTop: "none" }}
        >
          <ButtonRm
            variant="primary"
            onClick={handleEdit}
            disabled={!editedItem?.name}
          >
            Salva
          </ButtonRm>
          <ButtonRm variant="text" onClick={handleCloseEdit}>
            Annulla
          </ButtonRm>
        </Modal.Footer>
      </Modal>

      
    </>
  );
}
export default Squadre;

function SquadreCard(props) {
  return (
    <Card className="squadra-card mb-4 me-3" style={{ width: "30%" }}>
      <Card.Header className="bg-primary d-flex align-items-center justify-content-between">
        <p className="text-light">{props.squadra.name}</p>
        <div>
          <i className="bi bi-pencil text-light me-2" style={{ cursor: "pointer" }}
          onClick={()=> props.handleEditMode(props.squadra.id)}></i>
          <i class="bi bi-trash3 text-light" onClick={() => props.handleDelete(props.squadra.id)}style={{ cursor: "pointer" }}></i>
        </div>
      </Card.Header>
      <Card.Body
        style={{ minHeight: "200px", maxHeight: "280px", overflowY: "auto" }}
      >
        {props.squadra.members &&
          props.squadra.members.map((item) => {
            return (
              <div className="d-flex align-items-center justify-content-between py-2 mb-3">
                <div className="d-flex align-items-center">
                  <div
                    className="me-2 text-uppercase p-1 text-center"
                    style={{ borderRadius: "50%", background: "lightblue" }}
                  >
                    <p className="fs-12" style={{ minWidth: "18px" }}>
                      {item.substring(0, 2)}
                    </p>
                  </div>
                  <p className="text-truncation">{item}</p>
                </div>
                {/* <i class="bi bi-x-lg"></i> */}
              </div>
            );
          })}
      </Card.Body>
      <Card.Footer className="text-muted d-flex align-items-center">
        <i className="bi bi-person"></i>
        <p className="text-uppercase ms-2 fs-12">
          {props.squadra.members.length} membri
        </p>
      </Card.Footer>
    </Card>
  );
}


function NewItem(props) {
  return (
    <>
      {!props.item.invalid && (
        <div className="d-flex mb-2 align-items-center">
          <div className="newParticipantCard me-2 d-flex align-items-center justify-content-between">
            <p>{props.item.label}</p>
            <i class="bi bi-x" onClick={() => props.removeItem(props.item.key)}
            style={{cursor:"pointer"}}></i>
          </div>
          {/* {props.item.newItem && (
            <>
              <Form.Check
                label="Proprietario"
                type="radio"
                className="me-2"
                name={props.item.key}
                checked={props.item.role === "proprietario"}
                onChange={(e) => props.handleChange(e, props.item.key)}
                value="proprietario"
              />
              <Form.Check
                label="Inquilino"
                type="radio"
                className="me-2"
                name={props.item.id}
                checked={props.item.role === "inquilino"}
                onChange={(e) => props.handleChange(e, props.item.key)}
                value="inquilino"
              />
              <Form.Check
                label="Fornitore"
                type="radio"
                className="me-2"
                name={props.item.id}
                checked={props.item.role === "fornitore"}
                onChange={(e) => props.handleChange(e, props.item.key)}
                value="fornitore"
              />
            </>
          )} */}
        </div>
      )}
    </>
  );
}


function NewEditItem(props) {
  return (
    <>
        <div className="d-flex mb-2 align-items-center">
          <div className="newParticipantCard me-2 d-flex align-items-center justify-content-between">
            <p>{props.item}</p>
            <i class="bi bi-x" onClick={() => props.removeEditItem(props.index)}
            style={{cursor:"pointer"}}></i>
          </div>
        </div>
    </>
  );
}

