import React, { useEffect, useState } from "react";
import mobileMenu from "../assets/images/mobile_menu.svg";
import mobileMenuClose from "../assets/images/white_close.svg";
import TopHeading from "../components/TopHeading";
import FormBox from "../components/calcoli_immobiliari/calcolatrice/index";

export default function Calculator(props) {
  const [mobile_menu, setMobileMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const toggleMobileMenu = () => {
    setMobileMenu(!mobile_menu);
  };
  useEffect(() => {
    if (width <= 575) {
      setIsMobile(true);
      setIsDesktop(false);
    } else if (width >= 576 && width < 767) {
      setIsMobile(true);
      setIsDesktop(false);
    } else if (width >= 768 && width < 991) {
      setIsMobile(false);
      setIsDesktop(true);
    } else if (width >= 992 && width < 1199) {
      setIsMobile(false);
      setIsDesktop(true);
    } else {
      setIsMobile(false);
      setIsDesktop(true);
    }
  }, [width]);
  return (
    <>
      <div className="mb-4">
        <TopHeading SmallHeading="empty"  />
        
      </div>
      {isMobile &&
          <button className="mobile-menu">
            <img
              src={mobile_menu ? mobileMenuClose : mobileMenu}
              alt="mobileMenuIcon"
              className="mobile-menu-icon"
              onClick={toggleMobileMenu}
            />
          </button>
        }
      <FormBox />
    </>
  );
}
