import { read, utils } from 'xlsx';
import { format, parse } from 'date-fns';

import api, {owner_endpoint, supplier_endpoint, conductor_endpoint, stabili_endpoint, unit_endpoint, condominium_unit_assignment_endpoint, owner_unit_assignment_endpoint, rent_conductor_assignment_endpoint, rent_unit_assignment_endpoint, affitti_endpoint} from '../api/api';

// Funzione ausiliaria per dividere businessName in nome e cognome, in conduttori e proprietari
const separateBusinessName = (value) => {
  const parts = value?.split(' ');
  const name = parts[0];
  const surname = parts.slice(1).join(' ');
  return { name, surname };
};

let ignoreFirstRow = true

let mappingOwner = {
  'Denominazione': 'businessName',
  'Tipo di soggetto': 'subjectType',
  'CF': 'fiscalCode',
  'P. IVA': 'vatNumber',
  'Nome': 'name',
  'Cognome': 'surname',
  'Indirizzo': 'residenceAddress',
  'Civico': 'residenceHouseNumber',
  'Comune': 'residenceMunicipality',
  'Prov': 'residenceProvince',
  'Tel. Ufficio': 'officeTelephone',
  'Tel. Cellulare': 'cellularTelephone',
  'Fax': 'fax',
  'E-Mail normale': 'email',
  'E-mail certificata': 'pec',
  'Sito web': 'website',
  'Nato il': 'birthday'
}

let mappingConductor = {
  'Denominazione': 'businessName',
	'Tipo di soggetto': 'subjectType',
	'CF': 'fiscalCode',
	'P. IVA': 'vatNumber',
	'Nome': 'name',
	'Cognome': 'surname',
	'Indirizzo': 'residenceAddress',
	'Civico': 'residenceHouseNumber',
	'Comune': 'residenceMunicipality',
	'Prov': 'residenceProvince',
	'Tel. Ufficio': 'officeTelephone',
	'Tel. Cellulare': 'cellularTelephone',
	'Fax': 'fax',
	'E-Mail normale': 'email',
	'E-mail certificata': 'pec',
	'Sito web': 'website',
	'Nato il': 'birthday',
}

let mappingSupplier = {
  'Denominazione': 'businessName',
  'Tipo di soggetto': 'subjectType',
  'Categoria': 'category',
  'CF': 'fiscalCode',
  'P. IVA': 'vatNumber',
  'Nome': 'name',
  'Cognome': 'surname',
  'Indirizzo': 'officeAddress',
  'Civico': 'officeHouseNumber',
  'Comune': 'officeMunicipality',
  'Prov': 'officeProvince',
  'Tel. Ufficio': 'officeTelephone',
  'Fax': 'fax',
  'E-Mail normale': 'email',
  'E-mail certificata': 'pec',
  'Sito web': 'website',
}

let mappingStabili = {
  "Nome": "name",
  "Amministratore": "administrator",
  "Codice fiscale": "fiscalCode",
  "Indirizzo": "address",
  "Civico": "houseNumber",
  "Citta": "municipality",
  "Prov": "province",
  "CAP": "cap",
}

let mappingUnit = {
  'Categoria': 'category',
  'Tipologia': 'typology',
  'Nome': 'name',
  'Condominio': 'condominium',
  'Indirizzo': 'address',
  'Civico': 'houseNumber',
  'Interno': 'internal',
  'Scala': 'scale',
  'Piano': 'floor',
  'Comune': 'municipality',
  'Prov': 'province',
  'CAP': 'cap',
  'Locatore di riferimento': 'owners',
  'Sezione urbana': 'urbanSection',
  'Foglio': 'sheet',
  'Particella': 'parcel',
  'Zona censuaria': 'zone',
  'Microzona': 'microZone',
  'Subalterno': 'subaltern',
  'Categoria': 'categoryLandRegistry',
  'Classe': 'classLandRegistry',
  'Vani': 'rooms',
  'Rendita catastale (o Domenicale nel caso dei terreni)': 'cadastralIncome',
  'Codice comune catastale': 'municipalityCode'
}

let mappingRent = {
  'Tipo di locazione': 'rentType',
		'Nome': 'name',
		'Inizio': 'startRentingDate',
		'Fine': 'endFirstPeriodDate',
		'Fine rinnovo': 'endRenewalDate',
		'Tipologia di contratto': 'contractType',
		'Conduttore': 'conductors',
		'Percentuale di locazione': 'rentPercentage',
		'Consegna chiavi': 'keyDeliveryDate',
		'Preavvisso conduttori': 'cancellationRenewalForTenant',
		'Preavviso locatori': 'cancellationRenewalForOwner',
		'Giorno di scadenza pagamento': 'dueDayForPayment',
		'Inizio Gestione': 'startManagementDate',
		'Tipo': 'warrantyType',
		'Importo garantito (deposito cauzionale, garanzie, ecc.)': 'warrantyDeposit',
		'Interessi sulla cauzione': 'depositInterest',
		'Importo fideiussioni': 'warrantyTotalAmount',
		'Cadenza': 'feeFrequency',
		'Giorno scadenza rata': 'dueDayForPayment',
		'Base canone': 'feePeriod',
		'C. Attuale': 'currentMonthlyFee',
		'S. attuali': 'currentMonthlyRateExpenses',
		'Metodo di pagamento preferito': 'favoritePaymentMethod',
		'Data di registrazione': 'registrationDate',
		'Ufficio dell\'Agenzia delle Entrate': 'agenziaEntrateOffice',
		'Numero di bolli': 'stampsCopiesNumber',
		'Copie di contratto': 'contractCopies',
		'% sui bolli a carico dei conduttori': 'percentageStampConductor',
		'% imposta di registro': 'percentageTaxConductor'
}

const transmigrationFunction123 = (acceptedFiles) => {

  acceptedFiles.forEach((file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const validSheetNames = ['Proprietari', 'Conduttori', 'Fornitori', 'Condomini', 'Unità', 'Affitti']; // Nomi dei fogli validi

      // Filtra i fogli dell'Excel per quelli validi
      const validSheets = workbook.SheetNames.filter((sheetName) =>
        validSheetNames.includes(sheetName)
      );

      if (validSheets.length === 0) {
        console.warn('Nessun foglio valido trovato nell\'Excel.');
        return; // Ignora l'importazione se non ci sono fogli validi
      }

      const createOwners = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Proprietari'; // Nome del foglio Excel per i proprietari
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei proprietari verrà saltata.`);
            resolve(); // Salta la creazione dei proprietari se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 2; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingOwner[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }

                // Conversione del campo 'email' in un array contenente la singola email
                if (item.email && typeof item.email === "string") {
                  item.email = [item.email];
                }

                // Aggiungi i campi name e surname, se non esistono nell'oggetto ma esiste businnesName
                if ((!item.hasOwnProperty('name')) && item?.businessName !== '') {
                  const { name, surname } = separateBusinessName(item.businessName);
                  item.name = name;
                }

                if ((!item.hasOwnProperty('surname')) && item?.businessName !== '') {
                  const { name, surname } = separateBusinessName(item.businessName);
                  item.surname = surname;
                }

                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api().post(owner_endpoint, item)
                  .then((response) => {
                    console.log("Elemento creato:", response.data);
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione dell'elemento:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione proprietari completata.");
                resolve(); // Risolvi la promessa dopo la creazione dei proprietari
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei proprietari:", error);
                reject(error);
              });
          }
        });
      };

      const createSuppliers = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Fornitori'; // Nome del foglio Excel per i fornitori
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei fornitori verrà saltata.`);
            resolve(); // Salta la creazione dei fornitori se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 2; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingSupplier[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }

                // Conversione del campo 'email' in un array contenente la singola email
                if (item.email && typeof item.email === "string") {
                  item.email = [item.email];
                }

                // Aggiungi i campi name e surname, se non esistono nell'oggetto ma esiste businnesName
                if ((!item.hasOwnProperty('name')) && item?.businessName !== '') {
                  const { name, surname } = separateBusinessName(item.businessName);
                  item.name = name;
                }

                if ((!item.hasOwnProperty('surname')) && item?.businessName !== '') {
                  const { name, surname } = separateBusinessName(item.businessName);
                  item.surname = surname;
                }

                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api().post(supplier_endpoint, item)
                  .then((response) => {
                    console.log("Elemento creato:", response.data);
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione dell'elemento:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione fornitori completata.");
                resolve(); // Risolvi la promessa dopo la creazione dei fornitori
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei fornitori:", error);
                reject(error);
              });
          }
        });
      };

      const createTenants = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Conduttori'; // Nome del foglio Excel per i conduttori
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei conduttori verrà saltata.`);
            resolve(); // Salta la creazione dei conduttori se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 2; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingConductor[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }

                // Conversione del campo 'email' in un array contenente la singola email
                if (item.email && typeof item.email === "string") {
                  item.email = [item.email];
                }

                // Aggiungi i campi name e surname, se non esistono nell'oggetto ma esiste businnesName
                if ((!item.hasOwnProperty('name')) && item?.businessName !== '') {
                  const { name, surname } = separateBusinessName(item.businessName);
                  item.name = name;
                }

                if ((!item.hasOwnProperty('surname')) && item?.businessName !== '') {
                  const { name, surname } = separateBusinessName(item.businessName);
                  item.surname = surname;
                }

                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api().post(conductor_endpoint, item)
                  .then((response) => {
                    console.log("Elemento creato:", response.data);
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione dell'elemento:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione conduttori completata.");
                resolve(); // Risolvi la promessa dopo la creazione dei conduttori
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei conduttori:", error);
                reject(error);
              });
          }
        });
      };
      const createCondominiums = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Condomini'; // Nome del foglio Excel per i condomini
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei condomini verrà saltata.`);
            resolve(); // Salta la creazione dei condomini se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row, rowIndex) => {
                const item = {};
                for (let i = 1; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingStabili[datosKey];
                    console.log({metaKey, datosKey})
                    if (metaKey) {
                      console.log(metaKey, item[metaKey], row[i])
                      item[metaKey] = row[i];
                    }
                  }
                }
                return item; // Aggiungi il return dell'oggetto item
              });
      
            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api().post(stabili_endpoint, item)
                  .then((response) => {
                    console.log("Elemento creato:", response.data, item);
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione dell'elemento:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione condomini completata.");
                // Esegui l'associazione tra condomini e unità
                return createUnitCondominiumAssociations();
              })
              .then(() => {
                resolve(); // Risolvi la promessa dopo la creazione dei condomini e le associazioni
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei condomini:", error);
                reject(error);
              });
          }
        });
      };
      


      const createUnitCondominiumAssociations = () => {
        return new Promise((resolve, reject) => {
          // Implementa la logica per l'associazione tra unità e condomini
          // Utilizza i dati degli altri fogli Excel (Unità, Proprietari) e le informazioni dei condomini per eseguire le associazioni
          // ...

          // Esempio di chiamata API per l'associazione di un'unità a un condominio
          /*
          api().post('endpoint-di-associazione', { unitId: 'id-unità', condominiumId: 'id-condominio' })
            .then((response) => {
              console.log("Associazione unità-condominio creata:", response.data);
            })
            .catch((error) => {
              console.error("Errore durante l'associazione unità-condominio:", error);
            });
          */

          resolve(); // Risolvi la promessa dopo l'esecuzione dell'associazione
        });
      };

      Promise.resolve()
      .then(() => validSheets.includes('Proprietari') ? createOwners() : null)
      .then(() => validSheets.includes('Fornitori') ? createSuppliers() : null)
      .then(() => validSheets.includes('Conduttori') ? createTenants() : null)
      .then(() => validSheets.includes('Condomini') ? createCondominiums() : null)
      .then(() => {
        console.log('Importazione completata con successo.');
        // Esegui altre azioni dopo l'importazione completa
        // ...
      })
      .catch((error) => {
        console.error('Errore durante l\'importazione:', error);
        // Gestisci l'errore in modo appropriato
        // ...
      });
    };

    reader.readAsArrayBuffer(file);
  });
};



const transmigrationFunction = (acceptedFiles) => {
  acceptedFiles.forEach((file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array', cellDates: true  });
      const validSheetNames = ['Proprietari', 'Conduttori', 'Fornitori', 'Condomini', 'Unità', 'Affitti']; // Nomi dei fogli validi

      // Filtra i fogli dell'Excel per quelli validi
      const validSheets = workbook.SheetNames.filter((sheetName) =>
        validSheetNames.includes(sheetName)
      );

      if (validSheets.length === 0) {
        console.warn('Nessun foglio valido trovato nell\'Excel.');
        return; // Ignora l'importazione se non ci sono fogli validi
      }

      const createdUnits = []; // Array per memorizzare le unità create
      const createdOwners = []; // Array per memorizzare i proprietari creati
      const createdConductors = []; // Array per memorizzare i proprietari creati
      const createdCondominiums = []; // Array per memorizzare i condomini creati

      const createUnitCondominiumAssociation = (unitId, condominiumName) => {
        return new Promise((resolve, reject) => {
          console.log("associazione cond", unitId, condominiumName, createdCondominiums);
      
          // Trova il condominio corrispondente a condominiumName tra i condomini creati
          const condominium = createdCondominiums.find((condominium) => condominium.name == condominiumName);
      
          if (!condominium) {
            console.warn(`Il condominio ${condominiumName} non è stato trovato tra i condomini creati. L'associazione con l'unità ${unitId} verrà saltata.`);
            resolve(); // Risolvi la promessa con successo senza eseguire l'associazione
          } else {
            // Effettua l'associazione tra l'unità e il condominio utilizzando gli endpoint appropriati
            const associationData = {
              unitID: unitId,
              condominiumID: condominium.id
            };

            console.log("sto associaziondo cond", associationData)

            api()
              .post(condominium_unit_assignment_endpoint, associationData)
              .then((response) => {
                console.log(`Associazione tra l'unità ${unitId} e il condominio ${condominiumName} creata con successo.`,  response);
                resolve(); // Risolvi la promessa con successo dopo l'associazione
              })
              .catch((error) => {
                console.error(`Errore durante l'associazione tra l'unità ${unitId} e il condominio ${condominiumName}:`, error);
                reject(error); // Rifiuta la promessa in caso di errore durante l'associazione
              });
          }
        });
      };
      

      const createUnitOwnerAssociation = (unitId, ownerName) => {
        return new Promise((resolve, reject) => {
          console.log("Associazione owner:", unitId, ownerName);
      
          // Trova il proprietario corrispondente a ownerName tra i proprietari creati

          const ownerObj = createdOwners.find((owner) => {
            if (owner.name && owner.surname) {
              const fullName = `${owner.name} ${owner.surname}`;
              return fullName === ownerName ? owner : {}
            }

            if (owner.businessName) {
              return owner.businessName === ownerName ? owner : {};
            }

            return {}
          })

          const owner = createdOwners.find((owner) => {
            // Verifica se il nome del proprietario corrisponde
            if (owner.name && owner.surname) {
              const fullName = `${owner.name} ${owner.surname}`;
              return fullName === ownerName;
            }
            // Verifica se il nome dell'azienda corrisponde
            if (owner.businessName) {
              return owner.businessName === ownerName;
            }
            return false;
          });
      
          if (!owner) {
            console.warn(`Il proprietario ${ownerName} non è stato trovato tra i proprietari creati. L'associazione con l'unità ${unitId} verrà saltata.`);
            resolve();
          } else {
            // Effettua l'associazione tra l'unità e il proprietario utilizzando gli endpoint appropriati
            const associationData = {
              unitID: unitId,
              ownerID: owner.id,
            };

            
      
            api()
              .post(owner_unit_assignment_endpoint, associationData)
              .then((response) => {
                console.log(`Associazione tra l'unità ${unitId} e il proprietario ${ownerName} creata con successo.`);
      
                // Aggiungi l'oggetto relationData all'unità di riferimento
                const relationData = [{
                  parts: '',
                  possessionDate: '',
                  ownerType: 'proprietario',
                  procurator: '',
                  ...ownerObj, // Includi tutti i dati del proprietario
                }];
      
                // Esegui una richiesta PUT per aggiornare l'unità con il nuovo relationData
                api()
                .get(`${unit_endpoint}${unitId}`)
                .then((response) => {
                  const unitData = response.data; // I dati dell'unità sono presenti nella proprietà "data" della risposta
                  // Aggiungi l'oggetto "relationData" al corpo della richiesta PUT
                  delete unitData.pertinence;
                  delete unitData.rent;
                  delete unitData.activities;
                  delete unitData.owners;
                  delete unitData.condominium;
                  delete unitData.conductors;
                  delete unitData.suppliers
                  delete unitData.insurances
                  delete unitData.readCounters
                  delete unitData.acts
                  delete unitData.photo
                  delete unitData.planimetry
                  delete unitData.attachment
                  delete unitData.newAttachment
                  delete unitData.newPhoto
                  delete unitData.newPlanimetry
                  delete unitData.attachment
                  delete unitData.reports
                  delete unitData.installments
                  delete unitData.firstNotes

                  const requestBody = { ...unitData, relationData: JSON.stringify(relationData) };
                
                  // Effettua la richiesta PUT con il corpo aggiornato
                  api()
                    .put(`${unit_endpoint}${unitId}`, requestBody)
                    .then((response) => {
                      console.log(`Aggiornamento relationData dell'unità ${unitId} effettuato con successo.`);
                      resolve();
                    })
                    .catch((error) => {
                      console.error(`Errore durante l'aggiornamento relationData dell'unità ${unitId}:`, error);
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.error(`Errore durante il recupero dei dati dell'unità ${unitId}:`, error);
                  reject(error);
                });
              
              })
              .catch((error) => {
                console.error(`Errore durante l'associazione tra l'unità ${unitId} e il proprietario ${ownerName}:`, error);
                reject(error);
              });
          }
        });
      };
      

      const createUnitRentAssociation = (rentId, unitName) => {
        return new Promise((resolve, reject) => {
          console.log("Associazione affitto - unità:", rentId, unitName);
      
          // Trova l'unità corrispondente a unitName tra le unità create
          const unit = createdUnits.find((unit) => unit.name === unitName);
      
          if (!unit) {
            console.warn(`L'unità ${unitName} non è stata trovata tra le unità create. L'associazione con l'affitto ${rentId} verrà saltata.`);
            resolve(); // Risolvi la promessa con successo senza eseguire l'associazione
          } else {
            // Effettua l'associazione tra l'affitto e l'unità utilizzando gli endpoint appropriati
            const associationData = {
              rentID: rentId,
              unitID: unit.id,
            };
      
            api()
              .post(rent_unit_assignment_endpoint, associationData)
              .then((response) => {
                console.log(`Associazione tra l'affitto ${rentId} e l'unità ${unitName} creata con successo.`, response);
                resolve(); // Risolvi la promessa con successo dopo l'associazione
              })
              .catch((error) => {
                console.error(`Errore durante l'associazione tra l'affitto ${rentId} e l'unità ${unitName}:`, error);
                reject(error); // Rifiuta la promessa in caso di errore durante l'associazione
              });
          }
        });
      };
      
      const createRentConductorAssociation = (rentId, conductorName) => {
        return new Promise((resolve, reject) => {
          console.log("Associazione affitto - conduttore:", rentId, conductorName);
      
          // Trova il conduttore corrispondente a conductorName tra i conduttori creati
          const conductor = createdConductors.find((conductor) => {
            // Verifica se il nome del conduttore corrisponde
            if (conductor.name && conductor.surname) {
              const fullName = `${conductor.name} ${conductor.surname}`;
              return fullName ===conductorName;
            }
            // Verifica se il nome dell'azienda corrisponde
            if (conductor.businessName) {
              return conductor.businessName === conductorName;
            }
            return false;
          });
      
          if (!conductor) {
            console.warn(`Il conduttore ${conductorName} non è stato trovato tra i conduttori creati. L'associazione con l'affitto ${rentId} verrà saltata.`);
            resolve(); // Risolvi la promessa con successo senza eseguire l'associazione
          } else {
            // Effettua l'associazione tra l'affitto e il conduttore utilizzando gli endpoint appropriati
            const associationData = {
              rentID: rentId,
              conductorID: conductor.id,
            };
      
            api()
              .post(rent_conductor_assignment_endpoint, associationData)
              .then((response) => {
                console.log(`Associazione tra l'affitto ${rentId} e il conduttore ${conductorName} creata con successo.`, response);
                resolve(); // Risolvi la promessa con successo dopo l'associazione
              })
              .catch((error) => {
                console.error(`Errore durante l'associazione tra l'affitto ${rentId} e il conduttore ${conductorName}:`, error);
                reject(error); // Rifiuta la promessa in caso di errore durante l'associazione
              });
          }
        });
      };
      
      
      
      //TEST 1 FILE CON TUTTI TIPI DI ASSOCIAIZONE xx
      //TEST 1 FILE CON VARI ITEMS, E VARIE ASSOCIAZIONI SPARSE


      const createOwners = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Proprietari'; // Nome del foglio Excel per i proprietari
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei proprietari verrà saltata.`);
            resolve(); // Salta la creazione dei proprietari se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 1; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingOwner[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }
                 // Conversione del campo 'email' in un array contenente la singola email
            if (item.email && typeof item.email === "string") {
              item.email = [item.email];
            }

            // Aggiungi i campi name e surname, se non esistono nell'oggetto ma esiste businnesName
            if ((!item.hasOwnProperty('name')) && item?.businessName !== '') {
                const { name, surname } = separateBusinessName(item.businessName);
                item.name = name;
              }
              
              if ((!item.hasOwnProperty('surname')) && item?.businessName !== '') {
                const { name, surname } = separateBusinessName(item.businessName);
                item.surname = surname;
              }
                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api()
                  .post(owner_endpoint, item)
                  .then((response) => {
                    console.log("Proprietario creato:", response.data);
                    const completeOwner = { ...item, id: response.data.id };
                    createdOwners.push(completeOwner );
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione del proprietario:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione proprietari completata.");
                resolve();
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei proprietari:", error);
                reject(error);
              });
          }
        });
      };

      const createTenants = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Conduttori'; // Nome del foglio Excel per i conduttori
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei conduttori verrà saltata.`);
            resolve(); // Salta la creazione dei conduttori se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 1; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingConductor[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }

                 // Conversione del campo 'email' in un array contenente la singola email
            if (item.email && typeof item.email === "string") {
              item.email = [item.email];
            }

            // Aggiungi i campi name e surname, se non esistono nell'oggetto ma esiste businnesName
            if ((!item.hasOwnProperty('name')) && item?.businessName !== '') {
                const { name, surname } = separateBusinessName(item.businessName);
                item.name = name;
              }
              
              if ((!item.hasOwnProperty('surname')) && item?.businessName !== '') {
                const { name, surname } = separateBusinessName(item.businessName);
                item.surname = surname;
              }
                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api()
                  .post(conductor_endpoint, item)
                  .then((response) => {
                    console.log("Conduttore creato:", response.data);
                    const completeConductors = { ...item, id: response.data.id };
                    createdConductors.push( completeConductors);

                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione del conduttore:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione conduttori completata.");
                resolve();
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei conduttori:", error);
                reject(error);
              });
          }
        });
      };

      const createSuppliers = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Fornitori'; // Nome del foglio Excel per i fornitori
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei fornitori verrà saltata.`);
            resolve(); // Salta la creazione dei fornitori se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 1; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingSupplier[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }

                 // Conversione del campo 'email' in un array contenente la singola email
            if (item.email && typeof item.email === "string") {
              item.email = [item.email];
            }

            // Aggiungi i campi name e surname, se non esistono nell'oggetto ma esiste businnesName
            if ((!item.hasOwnProperty('name')) && item?.businessName !== '') {
                const { name, surname } = separateBusinessName(item.businessName);
                item.name = name;
              }
              
              if ((!item.hasOwnProperty('surname')) && item?.businessName !== '') {
                const { name, surname } = separateBusinessName(item.businessName);
                item.surname = surname;
              }
              
                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api()
                  .post(supplier_endpoint, item)
                  .then((response) => {
                    console.log("Fornitore creato:", response.data);
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione del fornitore:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione fornitori completata.");
                resolve();
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei fornitori:", error);
                reject(error);
              });
          }
        });
      };

      const createCondominiums = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Condomini'; // Nome del foglio Excel per i condomini
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei condomini verrà saltata.`);
            resolve(); // Salta la creazione dei condomini se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                for (let i = 1; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingStabili[datosKey];
                    if (metaKey) {
                      item[metaKey] = row[i];
                    }
                  }
                }
                return item;
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map((item) =>
                api()
                  .post(stabili_endpoint, item)
                  .then((response) => {
                    console.log("Condominio creato:", response.data);
                    const completeCondominium = { ...item, id: response.data.id };
                    createdCondominiums.push(completeCondominium);

                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione del condominio:", error);
                  })
              )
            )
              .then(() => {
                console.log("Creazione condomini completata.");
                // Esegui l'associazione tra condomini e unità
              })
              .then(() => {
                resolve(); // Risolvi la promessa dopo la creazione dei condomini e le associazioni
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei condomini:", error);
                reject(error);
              });
          }
        });
      };

      const createUnits = () => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Unità'; // Nome del foglio Excel per le unità
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione delle unità verrà saltata.`);
            resolve(); // Salta la creazione delle unità se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                let parcelValue = null;
                let subalternValue = null;
                let condominiumName = null; // Variabile per memorizzare il nome del condominio
                let ownerName = null
                for (let i = 2; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingUnit[datosKey];
                    if (metaKey) {
                      if (metaKey === 'parcel') {
                        parcelValue = row[i];
                        subalternValue = row[i + 1];
                      } else if (metaKey === 'subaltern') {
                        // Ignora il campo "subaltern" nel mapping
                        continue;
                      } else if (metaKey === 'condominium') {
                        condominiumName = row[i]; // Salva il nome del condominio nella variabile
                      } else if (metaKey === 'owners') {
                        ownerName = row[i]; // Salva il nome del condominio nella variabile
                      } 
                      else {
                        item[metaKey] = row[i];
                      }
                    }
                  }
                }
      
                if (parcelValue) {
                  item.parcel = [`{'parcel':'${parcelValue}','subaltern':[${subalternValue ? `${subalternValue}` : ''}]}`];
                }
      
                if (item.cap) {
                  item.cap = Number(item.cap);
                }
      
                if (item.rooms) {
                  item.rooms = Number(item.rooms);
                }
      
                if (typeof item.houseNumber === 'number') {
                  item.houseNumber = String(item.houseNumber);
                }
                if (typeof item.fiscalCode === 'number') {
                  item.fiscalCode = String(item.fiscalCode);
                }
      
                if (typeof item.cadastralIncome === 'number') {
                  item.cadastralIncome = String(item.cadastralIncome);
                }
      
                if (typeof item.urbanSection === 'number') {
                  item.urbanSection = String(item.urbanSection);
                }
      
                if (typeof item.zone === 'number') {
                  item.zone = String(item.zone);
                }
      
                if (typeof item.microZone === 'number') {
                  item.microZone = String(item.microZone);
                }
      
                // Rimuovi 'condominium' e 'owners', che ha un endpoint di associazione separato
                delete item.condominium;
                delete item.owners;

      
                return { item, condominiumName, ownerName }; // Restituisce l'oggetto item, il nome dell'owner e il nome del condominio come risultato

                //VEDERE SE RIUSCIAMO GESTIRE PIU' DI UN OWNER
              });
      
            // Crea gli elementi su Realmeta
            // Crea gli elementi su Realmeta
Promise.all(
  items.map(({ item, condominiumName, ownerName }) => {

    return api()
      .post(unit_endpoint, item)
      .then((response) => {
        console.log("Unità creata:", response.data, condominiumName, ownerName);
        const completeUnit = { ...item, id: response.data.id };
        createdUnits.push(completeUnit);
        const unitId = response.data.id; // Ottieni l'ID dell'unità creata dalla risposta

        // Crea la promessa per l'associazione con il condominio
        const condominiumPromise = condominiumName
        ? createUnitCondominiumAssociation(unitId, condominiumName)
        : Promise.resolve();

      // Crea la promessa per l'associazione con il proprietario
      const ownerPromise = ownerName
        ? createUnitOwnerAssociation(unitId, ownerName)
        : Promise.resolve();

      // Restituisci una promessa che si risolve quando entrambe le associazioni sono completate
      return Promise.all([condominiumPromise, ownerPromise]);

      })
      .catch((error) => {
        console.error("Errore durante la creazione dell'unità:", error);
      });
  })
)


              .then(() => {
                console.log("Creazione unità completata.");
                resolve(); // Risolvi la promessa dopo la creazione delle unità e le associazioni
              })
              .catch((error) => {
                console.error("Errore durante la creazione delle unità:", error);
                reject(error);
              });
          }
        });
      };

      const createRents = (rents, units) => {
        return new Promise((resolve, reject) => {
          const sheetName = 'Affitti'; // Nome del foglio Excel per i conduttori
          const worksheet = workbook.Sheets[sheetName];
          if (!worksheet) {
            console.warn(`Il foglio ${sheetName} non è presente nel file Excel. La creazione dei conduttori verrà saltata.`);
            resolve(); // Salta la creazione dei conduttori se il foglio non è presente
          } else {
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
            const startIndex = ignoreFirstRow ? 2 : 1;
            const items = jsonData
              .slice(startIndex, -1) // Ignora l'ultima riga
              .map((row) => {
                const item = {};
                let conductorName = null
                for (let i = 2; i < row.length; i++) {
                  if (row[i]) {
                    const datosKey = jsonData[1][i];
                    const metaKey = mappingRent[datosKey];
                    if (metaKey) {
                      if (metaKey === 'startRentingDate' || metaKey === 'endFirstPeriodDate' || metaKey === 'endRenewalDate' || metaKey === 'keyDeliveryDate' || metaKey === 'registrationDate') {
                        // Conversione del numero di serie della data nel formato corretto
                        const dateValue = new Date(row[i]); // Converti la data in un oggetto di tipo "Date"
                        item[metaKey] = format(dateValue, 'yyyy-MM-dd');
                      } else if (metaKey === 'conductors') {
                        conductorName = row[i]; // Salva il nome del condominio nella variabile
                      } else {
                        item[metaKey] = row[i];
                      }
                    }
                  }
                }

                if(item.cap){
                  item.cap = Number(item.cap)
                }
        
                if (typeof item.houseNumber === 'number') {
                  item.houseNumber = String(item.houseNumber);
                }
                if (typeof item.fiscalCode === 'number') {
                  item.fiscalCode = String(item.fiscalCode);
                }
      
                
                delete item.condominium;
                delete item.conductors;
                

                return { item, conductorName }
              });

            // Crea gli elementi su Realmeta
            Promise.all(
              items.map(({ item, conductorName }) => {
            
                return api()
                  .post(affitti_endpoint, item)
                  .then((response) => {
                    console.log("Affitto creato:", response.data, conductorName);
                    const rentId = response.data.id; // Ottieni l'ID dell'affitto creata dalla risposta
            
                    // Crea la promessa per l'associazione con il unità
                    const unitPromise = item.name
                    ? createUnitRentAssociation(rentId, item.name)
                    : Promise.resolve();
            
                  // Crea la promessa per l'associazione con il conduttore
                  const conductorPromise = conductorName
                    ? createRentConductorAssociation(rentId, conductorName)
                    : Promise.resolve();
            
                  // Restituisci una promessa che si risolve quando entrambe le associazioni sono completate
                  return Promise.all([unitPromise, conductorPromise]);
            
                  })
                  .catch((error) => {
                    console.error("Errore durante la creazione dell'affitto:", error);
                  });
              })
            )
              .then(() => {
                console.log("Creazione affitti completata.");
                resolve(); // Risolvi la promessa dopo la creazione dei conduttori
              })
              .catch((error) => {
                console.error("Errore durante la creazione dei affitti:", error);
                reject(error);
              });
          }
        });
      };
      

      
      





      // Visualizza un modale/pop-up per confermare l'azione
      const confirmModal = window.confirm(`Vuoi creare elementi da questo Excel?`);

      if (confirmModal) {
        // Crea gli elementi su Realmeta
        Promise.resolve()
      .then(() => validSheets.includes('Proprietari') ? createOwners() : null)
      .then(() => validSheets.includes('Fornitori') ? createSuppliers() : null)
      .then(() => validSheets.includes('Conduttori') ? createTenants() : null)
      .then(() => validSheets.includes('Condomini') ? createCondominiums() : null)
      .then(() => validSheets.includes('Unità') ? createUnits() : null)
      .then(() => validSheets.includes('Affitti') ? createRents() : null)
      .then(() => {
        console.log('Importazione completata con successo.');
       
      })
      .catch((error) => {
        console.error('Errore durante l\'importazione:', error);
        
      });
      } else {
        console.log("Creazione annullata");
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

export default transmigrationFunction;


