import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../assets/css/Supporto.css"
import TopHeading from "../components/TopHeading";
import { Link } from "react-router-dom";


const sections = [
  {
    name: 'Generale',
    subsections: [
      { icon: 'bi bi-box-arrow-in-right', text: 'accesso' },
      { icon: 'bi bi-shield-lock', text: 'password' },
      { icon: 'bi bi-diagram-3', text: 'struttura' },
      { icon: 'bi bi-grid', text: 'dashboard' }    ]
  },
  {
    name: 'Unità Immobiliari',
    subsections: [
      { icon: 'bi-house', text: 'unità' },
      { icon: 'bi-file-earmark-text', text: 'affitti' },
      { icon: 'bi-graph-up', text: 'valutazione' }
    ]
  },
  {
      name: 'Amministrazione Finanziaria',
      subsections: [
        { icon: 'bi-house', text: 'prima nota' },
        { icon: 'bi-file-earmark-text', text: 'rate' },
        { icon: 'bi-graph-up', text: 'rendiconto' }
      ]
    },
    {
      name: 'Stato avanzamento lavoro',
      subsections: [
        { icon: 'bi-house', text: 'sal' },
        { icon: 'bi-file-earmark-text', text: 'chat' },
      ]
    },
    {
      name: 'Altro',
      subsections: [
        { icon: 'bi-house', text: 'alert' },
        { icon: 'bi-file-earmark-text', text: 'calcoli' },
      ]
    },
    {
      name: 'TO DO',
      subsections: [
        { icon: 'bi-file-earmark-text', text: 'pagina' },
        { icon: 'bi-house', text: 'impostazioni' },
        { icon: 'bi-file-earmark-text', text: 'pagamenti' },
        { icon: 'bi-file-earmark-text', text: 'utenti' },
      ]
    },
];

export default function Supporto () {
    return (
        <>
        <div className="right-header">
        <TopHeading SmallHeading="empty" Heading="Supporto" />
        <h2 style={{ color:'transparent', height: 0}}>Supporto</h2>

    </div>
    <Container fluid> 
        <Row className="settings-container">
            
            {sections.map(section => (
                <>
                    <Row key={section.name}>
                    <p className="text-uppercase mx-3 fs-12 mb-2">{section.name}</p>
                    </Row>

                    <Row className="gx-3">
                    {section.subsections.map((subsection, index) => (
                        <Col key={index} md={4} xl={3} className=" mb-3">
                            <Link to={`${subsection.text}`}>
                            <div className="support-container">
                            <span className="">
                                <i class={`${subsection.icon} text-light`}></i>
                            </span>
                            <p className="text-capitalize">{subsection.text}</p> 
                            </div>
                        </Link>
                        </Col>
                    ))}
                    </Row>
                    </>
                ))}


        </Row>
        </Container>
            </>
    )
}