import React, { useState } from "react";
import User from "../../assets/images/conduttori-ph.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ConductorCard from "./ConductorCard";
import ConductorModal from "./ConductorModal";
import api, { conductor_endpoint, conductor_guarantor_endpoint } from "../../api/api";
import { Link } from "react-router-dom"
import Alert from 'react-bootstrap/Alert'
import ButtonRm from "../ButtonRm";



function Conductor(props) {
	const [show, setShow] = useState(false);
	const [tabState, setTabState] = useState(0);
	const [conductors, setConductors] = useState([]);
	const [guarantors, setGuarantors] = useState([]);

	const addSelectedConductor = async (id) => {
		console.log(props.formData.conductors);
		let filteredList = props.formData.conductors.filter(
			(conductor) => conductor.id === id
		);
		if (filteredList.length === 0) {
			props.formData.conductors.push(
				conductors.filter((conductor) => conductor.id === id)[0]
			);
			props.setFormData({
				...props.formData,
				conductors: props.formData.conductors,
			});
		}
	};

	const removeSelectedConductor = async (id) => {
		props.setFormData({
			...props.formData,
			conductors: props.formData.conductors.filter(
				(conductor) => conductor.id !== id
			),
		});
	};

	const fetchConductors = async () => {
		try {
			const res = await api().get(`${conductor_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setConductors(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const fetchConductorList = async () => {
		await fetchConductors();
		setShow(true);
	};

	//LISTA GARANTI

	const fetchGuarantors = async () => {
		try {
			const res = await api().get(`${conductor_guarantor_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setGuarantors(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const fetchGuarantorsList = async () => {
		await fetchGuarantors();
		setShow(true);
	};

	return (
		<>
			<div className="form-item-align-box-white d-block">
				<div>
					<div className={`tab-state`}>
						<div className="d-flex">
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(0);
								}}>
								<label className={tabState === 0 ? "" : "non-active"}>
									Conduttori attuali
								</label>
								<div
									className={`tab-bar ${
										tabState === 0 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(1);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 1 ? "" : "non-active"}>
									Garanti
								</label>
								<div
									className={`tab-bar ${
										tabState === 1 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(2);
								}}
								style={{ marginLeft: "-10px" }}>
								<label className={tabState === 2 ? "" : "non-active"}>
									Subentri
								</label>
								<div
									className={`tab-bar ${
										tabState === 2 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
						</div>
					</div>
					<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
						<div >
						<div className="d-flex justify-content-start mt-5">
					<Link to="/conduttori/create">
						<button type="button" className="general-btn white-btn w-193 me-2">
							Nuovo
						</button>
					</Link>
					<button
						type="button"
						className="general-btn white-btn w-193"
						onClick={fetchConductorList}>
						Seleziona
					</button>
				</div>
						</div>
						{props.formData.conductors.length > 0 ? (
					<div className="row mt-3">
						{props.formData.conductors?.filter(el => !el.guarantor).map((conductor) => (
							<div className="col-md-6">
								<ConductorCard
									key={conductor.id}
									title={conductor.name + " " + conductor.surname}
									id={conductor.id}
									table_data={{
										name: conductor.name,
										surname: conductor.surname,
										residenceAddress: conductor?.residenceAddress,
										residenceHouseNumber: conductor?.residenceHouseNumber,
										residenceMunicipality: conductor.residenceMunicipality,
										cellularTelephone: conductor.cellularTelephone,
										fiscalCode: conductor.fiscalCode,
										typeOfConductor: conductor.typeOfConductor,
									}}
									className="mt-4"
									remove={removeSelectedConductor}
								/>
							</div>
						))}
					</div>
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={User} alt="" />
						<span className="img-comment">Nessun conduttore selezionato</span>
					</div>
				)}
					</div>
					<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>
						<div >
						<div className="d-flex justify-content-start mt-5">
					<Link to="/conduttori/create">
						<button type="button" className="general-btn white-btn w-193 me-2">
							Nuovo
						</button>
					</Link>
					<button
						type="button"
						className="general-btn white-btn w-193"
						onClick={fetchGuarantorsList}>
						Seleziona
					</button>
				</div>
						</div>
						{props.formData.conductors.length > 0 ? (
					<div className="row mt-3">
						{props.formData.conductors?.filter(el => el.guarantor).map((conductor) => (
							<div className="col-md-6">
								<ConductorCard
									key={conductor.id}
									title={conductor.name + " " + conductor.surname}
									id={conductor.id}
									table_data={{
										name: conductor.name,
										surname: conductor.surname,
										residenceAddress: conductor?.residenceAddress,
										residenceMunicipality: conductor.residenceMunicipality,
										cellularTelephone: conductor.cellularTelephone,
										fiscalCode: conductor.fiscalCode,
										typeOfConductor: conductor.typeOfConductor,
									}}
									className="mt-4"
									remove={removeSelectedConductor}
								/>
							</div>
						))}
					</div>
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={User} alt="" />
						<span className="img-comment">Nessun garante selezionato</span>
					</div>
				)}
					</div>
					<div className={`mt-3 ${tabState === 2 ? "" : "d-none"}`}>
						<div >
							<div className="d-flex mt-3">
							<Alert variant="warning" className="d-flex align-items-center w-100" style={{maxHeight:"50px"}}>
							<i class="bi bi-info-circle me-2"></i>
							Potrai inserire un subentro, in un secondo momento, dopo la creazione di un affitto.
        </Alert>
							</div>
						</div>
						<div>
							<div className="unit-empty-img">
							<img loading="lazy" src={User} alt="" />
							<span className="img-comment">Nessun subentro</span>
						</div>
						
					</div>
					</div>				
				</div>
			</div>
			<div className="d-flex justify-content-end mt-5">
				<ButtonRm
				variant="tertiary"
				onClick={() => props.backward()}
				>
				Indietro
				</ButtonRm>

				<ButtonRm
					variant="primary"
					className="ms-2"
					onClick={() => props.forward()}
					disabled={props.formData.conductors.length > 0 ? false: true}
				>
					Avanti
				</ButtonRm>
			  </div>
			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					{tabState == 0 &&<ConductorModal
						table_data={
							conductors.length > 0 &&
							conductors.filter(el => !el.guarantor).map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								conductor.fiscalCode,
							])
						}
						ids={conductors.map((conductor) => conductor.id)}
						setShow={setShow}
						addConductor={addSelectedConductor}
					/>}
					{tabState == 1 &&<ConductorModal
						table_data={
							guarantors.length > 0 &&
							guarantors.map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								conductor.fiscalCode,
							])
						}
						ids={guarantors.map((conductor) => conductor.id)}
						setShow={setShow}
						addConductor={addSelectedConductor}
					/>}
				</Modal.Body>
			</Modal>
		</>
	);
}

export default Conductor;
