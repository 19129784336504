import React, {useState, useEffect} from "react";
import { MODALITY, CONTI } from "../../../../constants";

import api, {
  user_accounts_endpoint
} from "../../../../api/api";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../../../components/CustomToggle";
import CustomBadge from "../../../CustomBadge";


export default function VersamentoTraCassaEConto(props) {

  const [managerAccounts, setManagerAccounts] = useState([])
  
	//CONTI GESTORI
	async function getAccounts() {
	  try {
		const response = await api().get(`${user_accounts_endpoint}`);
		if (response.data) {
		  setManagerAccounts(response.data.content);
		}
	  } catch (err) {
		console.log(err);
	  }
	}

  useEffect(() => {
		getAccounts()
	}, []);

  return (
    <>
    <Accordion.Item eventKey="7" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="7" activeKey={props.activeKey}>Versamento<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
      </Card.Header>
      <Accordion.Body>

        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
            Descrizione dell’operazione
            </label>
            <input
              type="text"
              name="operationDescription"
              value={props.formData.operationDescription}
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="8" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="8" activeKey={props.activeKey}>Date</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="col-md-6">
        <div className="d-flex flex-column">
          <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
            Data dell’operazione
          </label>
          <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
            <input
              name="operationDate"
              className="border-0 fs-16 text-dark flex-grow-1 input-min-height"
              max="9999-12-31"
              type="date"
              onChange={props.onChange}
              value={props.formData.operationDate}
            ></input>
          </div>
        </div>
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="9" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="9" activeKey={props.activeKey}>Importo</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Importo
            </label>
            <input
              name="amount"
              value={props.formData.amount}
              type="text"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Modalità
            </label>
            <select
              name="modality"
              value={props.formData.modality}
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
            >
              {MODALITY.map((menu, index) => {
                return (
                  <option key={index} value={index}>
                    {menu}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Dal conto
            </label>
            <select
              name="fromAccount"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.formData.fromAccount}

            >
              <option></option>
              {managerAccounts.length> 0 && managerAccounts.map((conto) => {
                return <option value={conto.name}> {conto.name}</option>
              })}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Al conto
            </label>
            <select
              name="toAccount"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.formData.toAccount}
            >
              <option></option>
              {managerAccounts.length> 0 && managerAccounts.map((conto) => {
                return <option value={conto.name}> {conto.name}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      </Accordion.Body>
    </Accordion.Item>

    </>
  );
}
