import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LeftPanel from "../../LeftPanel";
import "../../../assets/css/RecoverPassword.css";

import ReturnIcon from "../../../assets/images/returnIcon.svg";
import ErrorIcon from "../../../assets/images/input_error.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
// const ModalForVerify = ({ show, loginInfo }) => {
export default function VerifyCode(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [verifyCode, setVerifyCode] = useState("");

  const { state } = useLocation();
  const returnLogin = () => {
    navigate("/");
  };

  const onChange = async (e) => {
    setVerifyCode(e.target.value);
  };

  const onSubmit = async (e) => {
    const res = false;
    var verifyData = state;

    state.otp = verifyCode;
    console.log(verifyData);
    axios
      .post(process.env.REACT_APP_REQUEST_URL + "api/v1/auth/verifyOTP", state)
      .then((res) => {
        /* console.log(res);
        if (res.data.success === true) {
          console.log(true);
          toast.success("", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        } else {
        } */
      })
      .catch((err) => {
        // toast
        toast.error("L'email inserita non è corretta", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
    if (res === true) {
      setError(true);
    } else {
      setError(false);
      navigate("/newPassword", { state: verifyData.email });
      // navigate("/newPassword");
    }

    e.preventDefault();
  };

  return (
    <div className="d-flex" style={{ height: "100vh",  backgroundColor: "#ffffff" }}>
      <LeftPanel />
      <ToastContainer />
      <div className="m-auto">
        <div className="return-login" onClick={() => returnLogin()}>
          <img
            src={ReturnIcon}
            alt="return"
            style={{ marginRight: "17.6px" }}
          ></img>
          Torna al login
        </div>
        <div className="recover-text">Verifica codice</div>
        <div className="recover-text2">
          Abbiamo inviato un codice al tuo indirizzo email
        </div>
        <div className="recover-text2">
         Inserisci il codice ricevuto nel campo sottostante
        </div>
        {error && (
          <div className="emailtypeError-div">
            <img
              src={ErrorIcon}
              alt="input_error"
              style={{ marginRight: "22px", marginLeft: "32px" }}
            ></img>
            L’email inserita non è corretta
          </div>
        )}
        <div className="recover-subtitle">Codice di recupero</div>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="Inserisci il codice"
            value={verifyCode}
            onChange={onChange}
            className="form-control password-form"
          />
          <input
            type="submit"
            className="recover-btn"
            value="Verifica Codice"
          />
        </form>
        <div
          style={{
            width: "300px",
            textAlign: "center",
            margin: "auto",
            paddingTop: "30px",
          }}
        >
        </div>
      </div>
    </div>
  );
}
