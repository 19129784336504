import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoPassword () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Password Dimenticata"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Recupero Password" />

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Password</h1>

      <h2 style={{ color:'transparent', height: 0}}>Password</h2>


      <h2 id="1">Recupero password</h2>

      <p > Dalla
schermata del login clicca sul link “<u className="text-dark"><a className="text-dark" href="https://realmeta.it/recover">Hai
dimenticato la password?</a></u>” 
        </p>

        <img loading="lazy" src="https://i.postimg.cc/zGG6GLX8/link.png" />
        <p className="subtitle">Inserisci la tua email</p>


<p >Inserisci l’email del tuo
	account.</p>

	<p >Invieremo un codice OTP all’email
	inserita, se esiste un account registrato sulla nostra piattaforma.</p>
    <img loading="lazy" src="https://i.postimg.cc/7LCRSp2R/email.png" />

    <p className="subtitle">Inserisci l'OTP</p>

  
<p >Inserisci il codice OTP (codice di
	recupero) e clicca su “verifica codice”</p>
    <img loading="lazy" src="https://i.postimg.cc/rwZb08KW/otp-3.png" />

    <p className="subtitle">Crea la password</p>

	<p >Crea la tua nuova password. Dovrà
	rispettare i seguenti parametri:</p>
    <ul>
	<li><p >lunghezza minima di 8 caratteri</p></li>
		<li><p >presenza di almeno 1 numero</p></li>
		<li><p >presenza di caratteri maiuscoli e
		minuscoli</p></li>
		<li><p >presenza di un elemento speciale
		(ad esempio !,@,_)</p></li>
        </ul>

<img loading="lazy" src="https://i.postimg.cc/QN6MM7jd/password.png" />


<p >Premi sul pusante “Imposta
	Password” per confermare il processo di cambiamento password</p>

      

    </div>
  );
}

