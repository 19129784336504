import React, { useState, useEffect } from "react";
import DownArrow from "../../../../assets/images/select-down-arrow.svg";
import TopArrow from "../../../../assets/images/select-up-arrow.svg";
import AllegatiE from "../create_mode/Allegati";
import AnagraficaE from "../create_mode/Anagrafica";
import CorrentiE from "../create_mode/Correnti";
import DocumentoE from "../create_mode/Documento";
import FornitoriE from "../create_mode/Fornitori";
import NoteE from "../create_mode/Note";
import RecapitiE from "../create_mode/Recapiti";
import "../../../../assets/css/Membri.css";
import UnitaE from "./UnitaE";
import axios from "axios";

export default function MidTabsE(props) {
  const [count, setCount] = useState(1);
  const [clicked, setClicked] = useState(false);

  const [provinceList, setProvinceList] = useState([]);

  const fetchProvince = async () => {
    axios.get(`https://anthill-service.onrender.com/provinces?region`).then((res) => {
      setProvinceList(res.data.data);
    });
  };

  useEffect(() => {
    fetchProvince();
  }, []);

  const forward = (e) => {
    setCount(count + 1);
  };

  const backward = () => {
    setCount(count - 1);
  };

  const addBankAccount = (bankAccount) => {
    const bankAccounts = props.formData.bankAccounts;
    bankAccounts.push(bankAccount);
    props.setFormData({ ...props.formData, bankAccounts: bankAccounts });
  };

  const removeBankAccount = (index) => {
    props.formData.bankAccounts.splice(index, 1);
    props.setFormData({
      ...props.formData,
      bankAccounts: props.formData.bankAccounts,
    });
  };

  const tab1Click = () => {
    setCount(1);
  };
  const tab2Click = () => {
    setCount(2);
  };
  const tab3Click = () => {
    setCount(3);
  };
  const tab4Click = () => {
    setCount(4);
  };
  const tab5Click = () => {
    setCount(5);
  };
  const tab6Click = () => {
    setCount(6);
  };



  const returnContent = (count) => {
    switch (count) {
      case 1:
        return (
          <AnagraficaE
            formData={props.formData}
            setFormData={props.setFormData}
            next={forward}
            back={() => props.setShow(false)}
            provinceList={provinceList}
          />
        );
      case 2:
        return (
          <RecapitiE
            formData={props.formData}
            setFormData={props.setFormData}
            next={forward}
            back={() => backward()}
            provinceList={provinceList}
          />
        );
      case 3:
        return (
          <DocumentoE
            formData={props.formData}
            setFormData={props.setFormData}
            next={forward}
            back={() => backward()}
          />
        );
      case 4:
        return (
          <CorrentiE
            formData={props.formData}
            setFormData={props.setFormData}
            addBankAccount={addBankAccount}
            removeBankAccount={removeBankAccount}
            next={forward}
            back={() => backward()}
          />
        );
      case 5:
        return (
          <FornitoriE
            formData={props.formData}
            setFormData={props.setFormData}
            next={forward}
            back={() => backward()}
          />
        );
      case 6:
        return (
          <AllegatiE
            formData={props.formData}
            setFormData={props.setFormData}
            next={forward}
            back={() => backward()}
            currentBank={props.currentBank}

          />
        );
      default:
        return (
          <AnagraficaE
            formData={props.formData}
            setFormData={props.setFormData}
            next={forward}
            back={() => props.setShow(false)}
            provinceList={provinceList}
          />
        );
    }
  };
  const tabSelect = (e) => {
    switch (e.target.value) {
      case "ana":
        tab1Click();
        break;
      case "rec":
        tab2Click();
        break;
      case "doc":
        tab3Click();
        break;
      case "con":
        tab4Click();
        break;
      case "for":
        tab5Click();
        break;
      case "all":
        tab6Click();
        break;
      default:
        tab1Click();
        break;
    }
  };

  return (
    <div className="mt-30">
      <div className="justify-content-center d-flex tabs-select-div">
        <select
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white tabs-select"
          onChange={(e) => tabSelect(e)}
          onClick={() => setClicked(!clicked)}
        >
          <option value="ana">Anagrafica</option>
          <option value="rec">Recapiti</option>
          <option value="doc">Documento</option>
          <option value="con">Conti Correnti</option>
          <option value="for">Fornitori del locatore</option>
          <option value="all">Allegati</option>
        </select>
        <img
          src={clicked ? DownArrow : TopArrow}
          style={{
            display: "absolute",
            marginLeft: clicked ? "-43px" : "-37px",
          }}
        ></img>
      </div>
      <div className="justify-content-center tabs-title-div">
        <div
          className="tabs-title"
          onClick={() => tab1Click()}
          style={{
            background: count === 1 ? "#9ccbf2" : "",
            color: count === 1 ? "#FFFFFF" : "",
          }}
        >
          Anagrafica
        </div>
        <div
          className="tabs-title"
          onClick={() => tab2Click()}
          style={{
            background: count === 2 ? "#9ccbf2" : "",
            color: count === 2 ? "#FFFFFF" : "",
          }}
        >
          Recapiti
        </div>
        <div
          className="tabs-title"
          onClick={() => tab3Click()}
          style={{
            background: count === 3 ? "#9ccbf2" : "",
            color: count === 3 ? "#FFFFFF" : "",
          }}
        >
          Documento
        </div>
        <div
          className="tabs-title"
          onClick={() => tab4Click()}
          style={{
            background: count === 4 ? "#9ccbf2" : "",
            color: count === 4 ? "#FFFFFF" : "",
          }}
        >
          Conti Correnti
        </div>
        <div
          className="tabs-title"
          onClick={() => tab5Click()}
          style={{
            background: count === 5 ? "#9ccbf2" : "",
            color: count === 5 ? "#FFFFFF" : "",
          }}
        >
          Fornitori del locatore
        </div>
        <div
          className="tabs-title x "
          onClick={() => tab6Click()}
          style={{
            background: count === 6 ? "#9ccbf2" : "",
            color: count === 6 ? "#FFFFFF" : "",
          }}
        >
          Allegati
        </div>
      </div>
      <div className="mt-25">
        <div className="tabContent-div">{returnContent(count)}</div>
      </div>
    </div>
  );
}
