import React, { useState } from "react";
import TopHeading from "../components/TopHeading";
import DocumentiBtn from "../components/Documenti/prima_nota/DocumentiBtn";
import Documents from "../components/Documenti/prima_nota/Documents";
import "../assets/css/Documenti.css";

import { Row, Col, Container } from "react-bootstrap";
import AddBtn from "../components/AddBtn";

import { useEffect } from "react";

import api, { first_note_endpoint, stabili_endpoint, unit_endpoint, user_accounts_endpoint } from "../api/api";

import { sortDatesByKey, sortStringByKey} from "../utils/filtering";

import { checkUserData } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";

function PrimaNota(props) {
  const [documents, setDocuments] = useState([]);
  const [counterDict, setCounterDict] = useState(new Map());


  //NEW SEARCH & FILTER

  //WE NEED THESE ITEMS FOR FILTERS

	const [units, setUnits] = useState([]);

	async function getUnits() {
			try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				setUnits(response.data.content);
				console.log(response.data.content);
			}
			} catch (err) {
			console.log(err);
			}
		}
		
    useEffect(() => {
			getUnits()
		}, []);

    const [stabili, setStabili] = useState([]);

    async function getStabili() {
        try {
        const response = await api().get(`${stabili_endpoint}`);
        if (response.data) {
          setStabili(response.data.content);
          console.log(response.data.content);
        }
        } catch (err) {
        console.log(err);
        }
      }
      
      useEffect(() => {
        getStabili()
      }, []);

	//SEARCH

	const [searchTerm, setSearchTerm] = useState("")
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [managerAccounts, setManagerAccounts] = useState([])



    const categories = [
      {
        name: 'Categoria',
        items: [
          "Movimento",
          "Versamento tra cassa e conto",
          "Prelievo da conto in cassa",
          "Trasferimento tra conti bancari"
        ]
      },
      {
        name: 'Pagamento',
        items: ['true', 'false']
      },
      {
        name: 'Conti',
        items:  managerAccounts.map((account) => account.name),
      },
      {
        name: 'Unità',
        items: units.map((unit) => unit.name),
      },
      {
        name: 'Stabili',
        items: stabili.map((unit) => unit.name)
      }
    ];
    
    


		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (documents.length > 0) {
        setFilteredUnits(
          documents.filter((item) => {
            const itemDate = new Date(item.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && itemDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && itemDate > new Date(endDate)) {
				return false;
				}
            return (
              (selectedCategories.length === 0 ||
                selectedCategories.some((cat) => {
                  if (cat.name === 'Pagamento') {
                    return (
                      item.isEnteringAmount !== undefined &&
                      cat.items.some((itemValue) => itemValue === String(item.isEnteringAmount))
                    );
                  } else if (cat.name === 'Unità') {
                    return item.unit.some((unit) => {
                    return cat.items.includes(unit.name);
                    });
                  } else if (cat.name === 'Stabili') {
                    return item.unit.some((unit) => {
                    return cat.items.includes(unit.name);
                    });
                  } else {
                    return (
                      cat.items.includes(item.typology) ||
                      cat.items.includes(item.toAccount) ||
                      cat.items.includes(item.fromAccount)
                    );
                  }
                })) &&
              item.operationDescription.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
        );
      }
    }, [searchTerm, selectedCategories, documents]);
    

		const [sortedUnits, setSortedUnits] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedUnits(sortStringByKey(filteredUnits, "operationDescription", false));
        break;
        case "a-z":
        setSortedUnits(sortStringByKey(filteredUnits, "operationDescription"));
        break;
        case "meno-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "operationDate"));
        break;
        default:
        case "più-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "operationDate", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(documents);
    setStartDate("")
		setEndDate("")
		}
  
  
	//CONTI GESTORI
	async function getAccounts() {
	  try {
		const response = await api().get(`${user_accounts_endpoint}`);
		if (response.data) {
		  setManagerAccounts(response.data.content);
		}
	  } catch (err) {
		console.log(err);
	  }
	}

  useEffect(() => {
		getAccounts()
	}, []);

  //LISTA DI CONTI
  const accountDict = {};

  managerAccounts.length > 0 && managerAccounts.forEach((account) => {
    accountDict[account.id] = account.name;
  });

  console.log(accountDict)

  const getDocuments = async () => {
    try {
      const response = await api().get(`${first_note_endpoint}`);
      if (response.data) {
        setDocuments(response.data.content);

      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);


  useEffect(() => {
        let dict = {};
        let totalCounter = 0

        //PRENDE OPENING BALANCE
        Object.entries(accountDict).forEach(([id, account]) => {
          dict[account] = managerAccounts.find((acc) => acc.name === account)?.openingBalance || 0;
          totalCounter += dict[account];
        });
        
        documents.forEach((document) => {
        if (document.isEnteringAmount !== null && document.isEnteringAmount) {
          totalCounter += document.amount;
      /*     dict[document.fromAccount] = dict[document.fromAccount]
            ? dict[document.fromAccount] + document.amount
            : document.amount; */
          dict[document.toAccount] = dict[document.toAccount]
            ? dict[document.toAccount] + document.amount
            : +document.amount;
        } else {
          totalCounter -= document.amount;
          dict[document.fromAccount] = dict[document.fromAccount]
            ? dict[document.fromAccount] - document.amount
            : -document.amount;
        /*  dict[document.toAccount] = dict[document.toAccount]
            ? dict[document.toAccount] + document.amount
            : document.amount; */
        }
});

dict["Tutti i conti"] = totalCounter;
setCounterDict(dict);
  }, [documents])

 //CHECK IF DATA REQUIRED ARE COMPILED
 const [dataRequired, setDataRequired] = useState(true);
 //Gestisce il modale
 const [showCheck, setShowCheck] = useState(false)

 useEffect(() => {
   const fetchData = async () => {
     try {
       const response = await checkUserData();
       setDataRequired(response.isVerified);
       if (!response.isVerified) {
         setShowCheck(true);
       }
     } catch (error) {
       // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
       console.error('Errore durante il recupero dei dati utente:', error);
     }
   };

   fetchData();
 }, []);

  return (
    <>
      <div className="title-search-group">
        <div className="right-header">
          <TopHeading SmallHeading="empty"/>
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Prima Nota</h2>
          </Col>
          
            <DocumentiBtn  getDocuments={getDocuments} dataRequired={dataRequired}/>
            {/* <SearchInput
              className="order-md-0"
              setSearch={(term) => {
                setSearchTerm(term);
              }}
            /> */}
        </Row>
        {/* <div className="model-modul-group">
          <DocumentiBtn setSearchName={setSearchName} />
        </div> */}
      </div>
      
      <Documents 
              counterDict={counterDict} 
              sortedUnits={sortedUnits}
              searchTerm={searchTerm} 
              setSearchTerm={setSearchTerm}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              clearFiltersAndSort={clearFiltersAndSort}
              setSortBy={setSortBy}
              startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}

              categories={categories}
              getDocuments={getDocuments}
              />
              
      {/* {Object.keys(props.documents).length===0 ? (
        <div className="d-block mb-4 mt-auto mb-auto">
          <EmptyList />
        </div>
      ) : (
        <Documents search_name={search_name} />
      )} */}
      {/* <div className="d-block mb-4 mt-auto mb-auto">
        <EmptyList />
      </div>
      <DocumentCard data={data} /> */}

<ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
			<p className="mb-3">Per procedere con la creazione della prima nota, è necessario completare le seguenti informazioni:</p>
			<p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
			
			{/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
		</ModalCheckUser>

    </>
  );
}
export default PrimaNota;