import React from "react";
import one from "../../../assets/images/images-chat/doc-galery/1.png";
import two from "../../../assets/images/images-chat/doc-galery/2.png";
import three from "../../../assets/images/images-chat/doc-galery/3.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
export const AnteprimaMode = (props) => {
    return (
        // <!-- Start Anteprima Slider POPUP -->
        <div className="add-popup anteprima-modal">
            {/* add-popup */}
            <div className="lm-outer">
                <div className="lm-inner">
                    <div className="rlsttpwb-tabs">
                        <div className="tphead-cntnti">
                            <div className="pop-heading">
                                <h4>Anteprima</h4>
                            </div>
                        </div>
                        <div className="innerpop-descrp">
                            <div className="sinkpopup-crousel">
                                <Carousel showArrows={true} autoPlay>
                                    <div>
                                        <img loading="lazy" src={one} />
                                    </div>
                                    <div>
                                        <img loading="lazy" src={two} />
                                    </div>
                                    <div>
                                        <img loading="lazy" src={three} />
                                    </div>
                                    <div>
                                        <img loading="lazy" src={one} />
                                    </div>
                                    <div>
                                        <img loading="lazy" src={two} />
                                    </div>
                                    <div>
                                        <img loading="lazy" src={three} />
                                    </div>
                                </Carousel>
                            </div>
                            <div className="chiudi-ttl" 
                            onClick={() => props.setanteprimamode(!props.anteprimamode)}>
                                <h4 className="chidu-close">Chiudi Anteprima</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </div>
        // <div className="add-popup anteprima-modal">
        //     <div className="lm-outer">
        //         <div className="lm-inner">
        //             <div className="rlsttpwb-tabs">
        //                 <div className="tphead-cntnti">
        //                     <div className="pop-heading">
        //                         <h4>Anteprima</h4>
        //                     </div>
        //                 </div>
        //                 <div className="innerpop-descrp">
        //                     <div className="sinkpopup-crousel">
        //                         <div
        //                             id="sync1"
        //                             className="owl-carousel owl-theme"
        //                         >
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${one})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${two})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${three})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${one})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${two})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${three})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${one})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${two})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                             <div className="item">
        //                                 <div className="sldr-img">
        //                                     <span
        //                                         className="sldr-bg"
        //                                         style={{
        //                                             backgroundImage: `url(${three})`,
        //                                         }}
        //                                     ></span>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div
        //                             id="sync2"
        //                             className="owl-carousel owl-theme crusel-thumbnail"
        //                         >
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${one})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${two})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${three})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${one})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${two})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${three})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${one})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${two})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                             <div className="item">
        //                                 <span
        //                                     className="thumb-sldrimg"
        //                                     style={{
        //                                         backgroundImage: `url(${three})`,
        //                                     }}
        //                                 ></span>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="chiudi-ttl">
        //                         <h4 className="chidu-close">Chiudi Anteprima</h4>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="overlay"></div>
        // </div>
        // <!-- END Anteprima Slider POPUP -->
    );
};
