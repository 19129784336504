import React, { useState } from 'react'
import Section1 from './page4/section1';
import "../../../assets/css/calcoli/page2.css";

export default function Page3() {
    const [status, setStatus] = useState(1);

    const Section = () => {
        switch (status) {
            case 1:
                return <Section1 onChangeSection={(v) => setStatus(v)} />
                break;
        }
        return;
    }
    return (
        <Section />
    )
}