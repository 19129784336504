import { SET_ENTERSHOW, CLEAR_ENTERSHOW } from "../actions/types";

const initialState = {
  showForEnterRegister: false
};

function ModalForEnterCode(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ENTERSHOW:
      return {
        ...initialState,
        showForEnterRegister: payload,
      };
    case CLEAR_ENTERSHOW:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default ModalForEnterCode;
