import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import CondominiumCard from "./CondominiumCard";
import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../ButtonRm";

import delete_icon from "../../../assets/images/delete.svg";
import ImgFileCard from "./ImgFileCard";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import CustomBadge from "../../CustomBadge";

const buildingLocationType = [
    "Edificio indipendente (non confinante con altri)",
    "Edificio a schiera (confinante con altri)",
    "Comprensorio immobiliare (senza servizi in comune)",
    "Complesso residenziale (con servizi e/o verde in comune)",
    "Complesso residenziale con piscina",
  ];

  const buildingTypologyType = [
    "Signorile",
    "Parzialmente signorile",
    "Molto signorile",
    "Di pregio",
    "Straordinario",
    "Civile",
  ];

  const buildingAgeType = [
    "1-5 anni",
    "5-20 anni",
    "20-40 anni",
    "Oltre 40 anni",
    "Antecedente 1930. storico antecedente 1900",
  ];

  const buildingStateType = [
    "Buono stato, da ristrutturare",
    "Discrete condizioni",
    "Ottimo stato",
    "Ristrutturato",
    "Nuova costruzione",
    "Parzialmente ristrutturato",
    "Da ripulire",
    "Nuova costruzione (mai abitato)",
    "Fatiscente-rudere",
  ];

const schema = yup.object().shape({

		
});

const Condominium = (props) => {
	const handleStabili = (e) => {
		let temp = [];
		if (e.target.value !== "") {
			temp.push(JSON.parse(e.target.value));
		}
		let ele = { target: { name: "condominium", value: temp } };
		props.onChange(ele);
	};
	const deleteCondominium = (id) => {
		let temp = [...props.formData.condominium];
		temp.splice(id, 1);
		let ele = { target: { name: "condominium", value: temp } };
		props.onChange(ele);
	};

	//HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	return (
		<Form>
			<div className="form-item-align-box d-block">
			<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Categoria</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<div className="d-flex justify-content-start">
					<Link to="/stabili/create">
						<button type="button" className="general-btn white-btn w-193 me-2">
							Nuovo
						</button>
					</Link>
				</div>
				<div className="row mt-4">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Condominio/Stabile/Residenza
							</label>
							<select
								name="condominium"
								value={JSON.stringify(props.formData.condominium[0])}
								
								onChange={handleStabili}>
								<option key={-1} value="">
									Seleziona
								</option>
								{props.stabili?.map((item, key) => {
									return (
										<option key={key} value={JSON.stringify(item)}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						{props.formData.condominium[0]?.structures.length > 0 ? (
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Struttura di riferimento
								</label>
								<select
									name="structure"
									value={props.formData.structure}
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
									onChange={props.onChange}>
									<option key={-1} value="">
										Seleziona
									</option>
									{props.formData.condominium[0].structures.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})}
								</select>
							</div>
						) : (
							""
						)}
					</div>
				</div>
				<div className="mt-3 row">
					{props.formData.condominium?.length > 0 &&
						props.formData.condominium.map((item, index) => {
							return (
								<CondominiumCard
									key={index}
									id={index}
									data={item}
									deleteCondominium={deleteCondominium}
									edit_mode={false}
								/>
							);
						})}
				</div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="" activeKey={activeKey}>Millesimi</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<label className="f-label fs-16">
					I millesimi vengono utilizzati dal programma per poter inserirli nei
					modelli di contratto
				</label>
				<div className="col-md-11 mt-3">
					<div className="row">
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label className="f-label fs-16 d-flex align-items-center
mb-2">Di proprietà</label>

							<input
								name="ofProperty"
								value={props.formData.ofProperty}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Scale
							</label>
							<input
								name="scaleMillesimi"
								value={props.formData.scaleMillesimi}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Ascensore
							</label>
							<input
								name="lift"
								value={props.formData.lift}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 text-nowrap">
								Portineria
							</label>
							<input
								name="reception"
								value={props.formData.reception}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
				</div>
				<div className="col-md-11 mt-3">
					<div className="row">
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label className="f-label fs-16 d-flex align-items-center
mb-2">Riscaldamento</label>

							<input
								name="heating"
								value={props.formData.heating}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Comprensorio
							</label>
							<input
								name="district"
								value={props.formData.district}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Acqua
							</label>
							<input
								name="water"
								value={props.formData.water}
								onChange={(e) => {
									props.onChange(e);
								}}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
				</div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Dati edificio</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<div className="row mt-3">
							<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Ubicazione edificio
								</label>
								<select
								name="buildingLocation"
								value={props.formData.buildingLocation}
								onChange={props.onChange}
								>
								{buildingLocationType.map((menu, index) => {
									return (
									<option key={index} value={menu}>
										{menu}
									</option>
									);
								})}
								</select>
							</div>
							</div>
							<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Tipologia edificio
								</label>
								<select
								name="buildingType"
								value={props.formData.buildingType}
								onChange={props.onChange}
								>
								{buildingTypologyType.map((menu, index) => {
									return (
									<option key={index} value={menu}>
										{menu}
									</option>
									);
								})}
								</select>
							</div>
							</div>
						</div>
						<div className="row my-3">
						<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Età edificio
							</label>
							<select
							name="buildingAge"
							value={props.formData.buildingAge}
							onChange={props.onChange}
							>
							{buildingAgeType.map((menu, index) => {
								return (
								<option key={index} value={menu}>
									{menu}
								</option>
								);
							})}
							</select>
						</div>
						</div>
						<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Stato conservazione edificio
							</label>
							<select
							name="buildingState"
							value={props.formData.buildingState}
							onChange={props.onChange}
							>
							{buildingStateType.map((menu, index) => {
								return (
								<option key={index} value={menu}>
									{menu}
								</option>
								);
							})}
							</select>
						</div>
						</div>
					</div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

			</Accordion>
			</div>
			<div className="d-flex justify-content-end mt-5">						
				<button
					type="button"
					className="general-btn white-btn me-2 w-193"
					onClick={() => props.backward()}>
					Indietro
				</button>
				<button
					type="button"
					className="general-btn pink-btn w-193"
					onClick={() => props.forward()}>
					Avanti
				</button>
			</div>
		</Form>
	);
};

export default Condominium;
