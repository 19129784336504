import * as React from 'react';
import {useState, useEffect} from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import api, { public_page } from '../api/api';

import ButtonRm from './ButtonRm';

/*

PRIMO INGRESSO FORNITORE:

INSERIMENTO IN DATABASE:
-NECESSARIO COMPILARE CAMPI PROFILAZIONE
-NECESSARIO COMPILARE CAMPI OBBLIGATORI PAGINA

-PRENDO I DATI USER/ID E CONTROLLO I CAMPI:
name x
surname x
address x
houseNumberx
provincex
municipalityx
capx
fiscalCodex
birthdayx
genrex
username
isCompany
vatNumber
businessName
businessAddress
businessHouseNumber
businessProvince
businessMunicipality
businessCap
uniqueCode


-FACCIO UNA RICHIESTA PER PRENDERE I DATI DELLA PAGINA DI USER/ID
-PRENDO I DATI E CONTROLLO I CAMPI:
title
provinceWorkplace
cityWorkplace
address
pay
availableNow
typologies


SE TUTTI I DATI DEFINITI SONO COMPILATI, IL TERZO STEP VIENE SBLOCCATO IN AUTOMATICO E L'UTENTE VIENE INSERITO NEL DATABASE

!! NOTA BENE: BISOGNA PARLARNE COL BACKEND PERCHE' AL MOMENTO FORNITORE VIENE INSERITO IN AUTOMATICO NEL DATABSE DOPO LA REGISTRAZIONE


****************

- SE TUTTI E TRE GLI STEP SONO COMPLETATI INSERISCI BOTTONE: VOGLIO GESTIRE I PAGAMENTI CON REAL META
- RENDI INVISIBILE STEPS PRECEDENTI PER SEMPRE (SALVARE IN LOCAL STORAGE)

MOSTRA BARRA PER STEPS CREAZIONE ACCOUNT STRIPE


*/


const partialSteps = [
  {
    label:'Inserisci i tuoi dati anagrafici',
    id:'anagrafica',
    isCompleted: false
  },
  {
    label:'Compila i dati nella sezione Pagina',
    id:'pagina',
    isCompleted: false
  },
  {
    label:'Inserimento nel database fornitori',
    id:'database',
    isCompleted: false
  },
  
];

const steps = [
  {
    label:'Inserisci i tuoi dati anagrafici',
    id:'anagrafica',
    isCompleted: false
  },
  {
    label:'Inserisci i tuoi link social',
    id:'social',
    isCompleted: false
  },
  {
    label:'Inserisci un conto bancario',
    id:'conto',
    isCompleted: false
  },
  {
    label:'Abilita la ricezione dei pagamenti',
    id:'',
    isCompleted: false

  }
];

export default function ProgressFornitori(props) {

    const [stepStatus, setStepStatus] = useState(steps)
    const [partialStepStatus, setPartialStepStatus] = useState(partialSteps)

    const [showStripeSteps, setShowStripeSteps] = useState((localStorage.getItem("stripeStep")) || false)
    const [enablePayments, setEnablePayments] = useState((localStorage.getItem("enablePaymentsStep")) || false)

    useEffect(() => {
      if (
        partialStepStatus[0].isCompleted &&
        partialStepStatus[1].isCompleted &&
        partialStepStatus[2].isCompleted 
      ){
        setEnablePayments(true)
        localStorage.setItem("enablePaymentsStep", JSON.stringify(true));

      }
    }, [partialStepStatus])


    const [newUrl, setNewUrl ] = useState()
    const [publicPage, setPublicPage] = useState(null)

     //API REQUEST TO GET PAGE DATA
     async function fetchData() {
      api().get(`${public_page}`)
           .then(response => {
               // Trovare la pagina con userID corrispondente e salvarla in publicaPage
               const page = response.data.content.find(p => p.userID === props.data.id);
               console.log(page, "paagesx")
               if(page?.id){
                  setPublicPage(page);
               }
           })
           .catch(error => {
               console.log('Errore nella richiesta GET:', error);
             });
   }


   //CHIAMA L'ENDPOINT PER PRENDERE I DATI DELLA PAGINA APPENA IL COMPONENTE MONTA
   useEffect(() => {
    fetchData()
   }, [props.data])



    function areFirstThreeStepsCompleted(steps) {
      // Verifica se i primi tre step sono completati
      for (let i = 0; i < 3; i++) {
        if (!steps[i].isCompleted) {
          return false; // Se uno dei primi tre step non è completato, restituisci false
        }
      }
      return true; // Se tutti e tre i primi step sono completati, restituisci true
    }

    function navigateToPagina(){
      props.setKey("Pagina");
      props.setShowMenu(!props.showMenu);
      props.handleSelect("Pagina")
  }

    const handleOpenPopup = (url) => {
        const popupUrl = url;
         // Dimensioni desiderate per la finestra popup
        const width = 600;
        const height = 800;

        // Calcola le posizioni left e top per centrare la finestra
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
        const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
            
        // Apertura della finestra popup
        window.open(popupUrl, "StripeOnboarding", options);
    };

    const handleClick = (index, itemId) => {
      const element = document.getElementById(itemId);
  
      if (itemId && element) {
          element.scrollIntoView({ behavior: 'smooth' });
      } else if (index == 3 && areFirstThreeStepsCompleted(stepStatus)) {
        console.log("red")
          startOnboarding().then(message => {
              handleOpenPopup(message);
          }).catch(err => {
              console.log(err);
          });
      }

      if(itemId === "pagina"){
        navigateToPagina()
      }
  };
  
  const [activeStep, setActiveStep] = useState(0);
  const [activePartialStep, setActivePartialStep] = useState(0);

  
  async function startOnboarding() {
      try {
          const response = await api().post(`/api/v1/payment/create`, {});
          return response.data.message;
      } catch (err) {
          throw err;
      }
  }
  


   



  //STEP
    

  useEffect(() => {
      if(
        props.data.name &&
        props.data.surname &&
        props.data.birthday &&
        props.data.email &&
        props.data.phone &&
       /*  props.data.businessName && */
        props.data.cap &&
        props.data.fiscalCode &&
        props.data.genre &&
        props.data.houseNumber &&
        props.data.municipality &&
        props.data.province &&
        props.data.address 
    /*     props.data.vatNumber  */
        ){
          //IMPOSTA STEP 1 COME COMPLETATO
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 0) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
        } else {
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
        }

        if(props.data.name){
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
        } else {
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
        }

        if(props.data.accountingResources?.length > 0){
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 2) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
        } else {
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 2) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
        }
  }, [props.data])

  //PARTIALSTEPS

  useEffect(() => {

    if(props.data){
      if(props.data.isCompany){
        if(
        props.data.name &&
        props.data.surname &&
        props.data.birthday &&
        props.data.email &&
        props.data.phone &&
        props.data.cap &&
        props.data.fiscalCode &&
        props.data.genre &&
        props.data.houseNumber &&
        props.data.municipality &&
        props.data.province &&
        props.data.address &&
        props.data.username  &&

        props.data.isCompany &&
        props.data.vatNumber &&
        props.data.businessName &&
        props.data.businessAddress &&
        props.data.businessHouseNumber &&
        props.data.businessProvince &&
        props.data.businessMunicipality &&
        props.data.businessCap &&
        props.data.uniqueCode
        ){
          //IMPOSTA STEP 1 COME COMPLETATO
          setPartialStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 0) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
          console.log("PRIMO STEP - ANAGRAFICA - COMPLETATO")
        } else {
        //IMPOSTA STEP 1 COME NON COMPLETATO
        setPartialStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 0) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
          console.log("PRIMO STEP - ANAGRAFICA - NON COMPLETATO")

        }
      } else {
      if(
         props.data.name &&
        props.data.surname &&
        props.data.birthday &&
        props.data.email &&
        props.data.phone &&
        props.data.cap &&
        props.data.fiscalCode &&
        props.data.genre &&
        props.data.houseNumber &&
        props.data.municipality &&
        props.data.province &&
        props.data.address &&
        props.data.username  
        ){
          //IMPOSTA STEP 1 COME COMPLETATO
          setPartialStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 0) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
          console.log("PRIMO STEP - ANAGRAFICA B - COMPLETATO")

        } else {
          setPartialStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 0) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
          console.log("PRIMO STEP - ANAGRAFICA B - NON COMPLETATO")

        }
      }
    }


    if(publicPage){
      if(
      publicPage?.title &&
      publicPage?.provinceWorkplace &&
      publicPage?.cityWorkplace &&
      publicPage?.address &&
      publicPage?.pay &&
      publicPage?.typologies 
      ){
        setPartialStepStatus(prevSteps => {
        return prevSteps.map((step, index) => {
          if (index === 1) {
            return { ...step, isCompleted: true };
          }
          return step;
        });
      });
      console.log("SECONDO STEP - PAGINA - COMPLETATO")

    } else {
      setPartialStepStatus(prevSteps => {
        return prevSteps.map((step, index) => {
          if (index === 1) {
            return { ...step, isCompleted: false };
          }
          return step;
        });
      });
      console.log("SECONDO STEP - PAGINA - NON COMPLETATO")

    }
    }
    else{
      console.log("non esiste publicpage")

    }


    if (
      partialStepStatus[0].isCompleted &&
      partialStepStatus[1].isCompleted 
    ) {
      setPartialStepStatus(prevSteps => {
        return prevSteps.map((step, index) => {
          if (index === 2) {
            return { ...step, isCompleted: true };
          }
          return step;
        });
      });
      console.log("TERZO STEP  - COMPLETATO")

    } else {
      setPartialStepStatus(prevSteps => {
        return prevSteps.map((step, index) => {
          if (index === 2) {
            return { ...step, isCompleted: false };
          }
          return step;
        });
      });
      console.log("TERZO STEP  - NON COMPLETATO")
    }

}, [props.data, publicPage, activePartialStep])


    //stripe steps
    useEffect(() => {
      // Logica esistente per aggiornare lo stato di completamento degli step...
      
      // Trova il primo step che non è ancora completato e imposta activeStep su quell'indice
      const nextActiveStep = stepStatus.findIndex(step => !step.isCompleted);
      

      setActiveStep(nextActiveStep !== -1 ? nextActiveStep : stepStatus.length);
    
    }, [props.data, stepStatus]); 
    
    //partial steps
    useEffect(() => {
      
      const nextActiveStep = partialStepStatus.findIndex(step => !step.isCompleted);
      
      setActivePartialStep(nextActiveStep !== -1 ? nextActiveStep : partialStepStatus.length);
    
    }, [props.data, publicPage, partialStepStatus]); 

 

    console.log(partialStepStatus, "partialSteps", publicPage, props.data.userID, props.data.id)

    useEffect(() => {
      // Verifica se publicPage è stato impostato prima di eseguire il resto del codice
      console.log(publicPage)
      if (publicPage !== null) {
        // Il tuo codice originale qui...
        if (
          publicPage?.title &&
          publicPage?.provinceWorkplace &&
          publicPage?.cityWorkplace &&
          publicPage?.address &&
          publicPage?.pay &&
          publicPage?.typologies 
        ) {
          setPartialStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
          console.log("SECONDO STEP - PAGINA - COMPLETATO");
        } else {
          setPartialStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
          console.log("SECONDO STEP - PAGINA - NON COMPLETATO");
        }
      }
    }, [publicPage]);
  return (
    <>
  
    { (showStripeSteps ) ?
      <div className="text-center">
      <h5 className="mb">Completa il tuo profilo</h5>
      <p className="fs-14 opacity-75 ">
      Per cominciare a ricevere pagamenti su Real Meta, bisogna abilitare il processo di pagamento.
      </p>
      <p className="fs-14 opacity-75 mb-4">Cliccando sul passaggio relativo ti indicheremo le sezioni da completare/compilare (segnalati col simbolo *)</p>
      <Box sx={{ width: '100%', marginBottom:"2rem" }}>
<Stepper activeStep={activeStep} alternativeLabel>
{stepStatus.map((el, index) => (
<Step 
key={index} 
completed={el.isCompleted}
onClick={() => {
handleClick(index, el.id)
}}
style={{cursor: "pointer"}}>
<StepLabel>{el.label}</StepLabel>
</Step>
))}
</Stepper>
</Box> 
  </div> : 

    <div className="text-center mb-4">
      <h5 className="mb">Completa il tuo profilo</h5>
      <p className="fs-14 opacity-75 ">
      Per poter essere inserito nel nostro database di fornitori visibile agli utenti Real Meta dovrai compilare alcune informazioni obbligatorie.
      </p>
      <p className="fs-14 opacity-75 mb-4">Cliccando sul passaggio relativo ti indicheremo le sezioni da completare/compilare</p>
      <Box sx={{ width: '100%', marginBottom:"2rem" }}>
            <Stepper activeStep={activePartialStep} alternativeLabel>
              {partialStepStatus.map((el, index) => (
                <Step 
                key={index} 
                completed={el.isCompleted}
                onClick={() => {
                  handleClick(index, el.id)
                }}
                style={{cursor: "pointer"}}>
                  <StepLabel>{el.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
      </Box> 

      {(enablePayments) &&
      <ButtonRm variant="outline" size="small" onClick={() => {
        setShowStripeSteps(true)
        localStorage.setItem("stripeStep", JSON.stringify(true));
      }}>Abilita la ricezione di pagamenti</ButtonRm>}
    </div> 

    
  }

    </>
  );
}