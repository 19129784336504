import { BsPlusCircleFill } from "react-icons/bs";
import { useState, useEffect } from "react";

import delete_icon from "../../../assets/images/delete.svg";
import PhoneInput from "react-phone-number-input/input";

import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../CustomToggle";
import CustomBadge from "../../CustomBadge";


const Data = (props) => {
	const changeParcel = (index, e) => {
		let temp = [...props.formData.parcel];
		if (temp.length===index) temp.push({ parcel: "", subaltern: [] });
		temp[index].parcel = e.target.value;
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const changeSubaltern = (id1, id2, e) => {
		let temp = [...props.formData.parcel];
		temp[id1].subaltern[id2] = e.target.value;
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const addParcel = () => {
		let temp = [...props.formData.parcel];
		if (temp[temp.length - 1].parcel !== "")
			temp.push({ parcel: "", subaltern: [""] });
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const addSubaltern = () => {
		let temp = [...props.formData.parcel];
		if (
			temp[temp.length - 1].subaltern[
				temp[temp.length - 1].subaltern.length - 1
			] !== ""
		)
			temp[temp.length - 1].subaltern.push("");
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const deleteParcel = (id) => {
		let temp = [...props.formData.parcel];
		temp.splice(id, 1);
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};
	const deleteSubaltern = (id1, id2) => {
		let temp = [...props.formData.parcel];
		temp[id1].subaltern.splice(id2, 1);
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const handleChange = (e) => {
		let ele = {
			target: { name: "logo", value: e.target.files[0] },
		};
		props.onChange(ele);
	};


  //HANDLE ACCORDION COMPONENT
  const [activeKey, setActiveKey] = useState('');

	return (
		<div className="form-item-align-box d-block">
			<Form>
        	<Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
				<Card.Header>
				<CustomToggle eventKey="0" activeKey={activeKey}>Dati catastali</CustomToggle>
				</Card.Header>
				<Accordion.Body>
				<div className="d-flex flex-column">
				<div className="row">
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Codice comune
						</label>
						<input
							name="municipalityCode"
							value={props.formData.municipalityCode}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Foglio
						</label>
						<input
							name="paper"
							value={props.formData.paper}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<div className="d-flex align-items-center  mb-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
								Particella/Mappale
							</label>
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<div className="flex-auto">
								<input
									name="parcel"
									value={
										props.formData.parcel?.length > 0
											? props.formData.parcel[0].parcel
											: ""
									}
									onChange={(e) => {
										changeParcel(0, e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
								/>
							</div>
							<div style={{ padding: "3px" }}>
								<BsPlusCircleFill
									className="add-section-btn"
									onClick={addParcel}
								/>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column col-sm-3">
						<div className="d-flex align-items-center  mb-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
								Subalterno
							</label>
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<div className="flex-auto">
								<input
									name="subaltern"
									value={
										props.formData.parcel?.length > 0
											? props.formData.parcel[0].subaltern[0]
											: ""
									}
									onChange={(e) => {
										changeSubaltern(0, 0, e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
								/>
							</div>
							<div style={{ padding: "3px" }}>
								<BsPlusCircleFill
									className="add-section-btn"
									onClick={addSubaltern}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{props.formData.parcel?.length > 0 &&
				props.formData.parcel[0].subaltern.length > 1 &&
				props.formData.parcel[0].subaltern.map((item, index) => {
					if (index !== 0) {
						return (
							<div key={index} className="row justify-content-end">
								<div className="d-flex flex-column col-sm-3">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Subalterno
									</label>
									<div className="d-flex align-items-center justify-content-between">
										<div className="flex-auto">
											<input
												name="subaltern"
												value={item}
												onChange={(e) => {
													changeSubaltern(0, index, e);
												}}
												type="text"
												className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
											/>
										</div>
										<div style={{ padding: "3px" }}>
											<div
												className="delete-icon-btn"
												onClick={() => {
													deleteSubaltern(0, index);
												}}>
												<img loading="lazy" src={delete_icon} alt="icon" />
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}
				})}
			{props.formData.parcel?.length > 1 &&
				props.formData.parcel.map((item, index) => {
					if (index !== 0) {
						return (
							<div key={index}>
								<div className="row justify-content-end">
									<div className="d-flex flex-column col-sm-3">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Particella/Mappale
										</label>
										<div className="d-flex align-items-center justify-content-between">
											<div className="flex-auto">
												<input
													name="subaltern"
													value={item.parcel}
													onChange={(e) => {
														changeParcel(index, e);
													}}
													type="text"
													className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
												/>
											</div>
											<div style={{ padding: "3px" }}>
												<div
													className="delete-icon-btn"
													onClick={() => {
														deleteParcel(index);
													}}>
													<img loading="lazy" src={delete_icon} alt="icon" />
												</div>
											</div>
										</div>
									</div>
									<div className="d-flex flex-column col-sm-3">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Subalterno
										</label>
										<div className="d-flex align-items-center justify-content-between">
											<div className="flex-auto">
												<input
													name="subaltern"
													value={item.subaltern[0]}
													onChange={(e) => {
														changeSubaltern(index, 0, e);
													}}
													type="text"
													className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
												/>
											</div>
											<div style={{ padding: "3px" }}>
												<div
													className="delete-icon-btn"
													onClick={() => {
														deleteSubaltern(index, 0);
													}}>
													<img loading="lazy" src={delete_icon} alt="icon" />
												</div>
											</div>
										</div>
									</div>
								</div>
								{item.subaltern?.length > 1 &&
									item.subaltern.map((element, id) => {
										if (id !== 0) {
											return (
												<div>
													<div key={id} className="row justify-content-end">
														<div className="d-flex flex-column col-sm-3">
															<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
																Subalterno
															</label>
															<div className="d-flex align-items-center justify-content-between">
																<div className="flex-auto">
																	<input
																		name="subaltern"
																		value={element}
																		onChange={(e) => {
																			changeSubaltern(index, id, e);
																		}}
																		type="text"
																		className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
																	/>
																</div>
																<div style={{ padding: "3px" }}>
																	<div
																		className="delete-icon-btn"
																		onClick={() => {
																			deleteSubaltern(index, id);
																		}}>
																		<img loading="lazy" src={delete_icon} alt="icon" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											);
										}
									})}
							</div>
						);
					}
				})}
			<div className="row mt-3">
				<div className="d-flex flex-column col-sm-3 pe-2">
					<div className="d-flex align-items-center  mb-2">
						<label className="f-label fs-16 d-flex align-items-center
me-2">Categoria</label>
					</div>
					<input
						name="category"
						value={props.formData.category}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
				<div className="d-flex flex-column col-sm-3 pe-2">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
						Classe
					</label>
					<input
						name="classType"
						value={props.formData.classType}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
				<div className="d-flex flex-column col-sm-3 pe-2">
					<div className="d-flex align-items-center  mb-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
							Consistenza
						</label>
					</div>
					<input
						name="consistency"
						value={props.formData.consistency}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
				<div className="d-flex flex-column col-sm-3 pe-2">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 text-nowrap">
						Rendita Catastale
					</label>
					<input
						name="cadastralIncome"
						value={props.formData.cadastralIncome}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
			</div>
			<div className="row mt-3">
				<div className="d-flex flex-column col-sm-3 pe-2">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
						Sezione Urbana
					</label>
					<input
						name="urbanSection"
						value={props.formData.urbanSection}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
				<div className="d-flex flex-column col-sm-3 pe-2">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
						Zona
					</label>
					<input
						name="zone"
						value={props.formData.zone}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
				<div className="d-flex flex-column col-sm-3 pe-2">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
						Microzona
					</label>
					<input
						name="microZone"
						value={props.formData.microZone}
						onChange={(e) => {
							props.onChange(e);
						}}
						type="text"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
					/>
				</div>
			</div>
				</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
				<Card.Header>
				<CustomToggle eventKey="1" activeKey={activeKey}>Richiedente valutazione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
				</Card.Header>
					<Accordion.Body>
				<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Nome e Cognome del valutatore <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</label>
						<input
							name="applicantName"
							value={props.formData.applicantName}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Telefono
						</label>
						<PhoneInput
							name=""
							maxLength="14"
							value={props.formData.applicantPhone}
							onChange={(value) => {
								let e = { target: { name: "applicantPhone", value: value } };
								props.onChange(e);
							}}
							className="tabInputField form-control w-100"
						/>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Email
						</label>
						<input
							name="applicantEmail"
							value={props.formData.applicantEmail}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="email"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Indirizzo
						</label>
						<input
							name="applicantAddress"
							value={props.formData.applicantAddress}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
			</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
				<Card.Header>
				<CustomToggle eventKey="2" activeKey={activeKey}>Valutatore <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
				</Card.Header>
					<Accordion.Body>
					<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Nome e Cognome del valutatore <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</label>
						<input
							name="evaluatorName"
							value={props.formData.evaluatorName}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Telefono
						</label>
						<PhoneInput
							name=""
							maxLength="14"
							value={props.formData.evaluatorPhone}
							onChange={(value) => {
								let e = { target: { name: "evaluatorPhone", value: value } };
								props.onChange(e);
							}}
							className="tabInputField form-control w-100"
						/>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Email
						</label>
						<input
							name="evaluatorEmail"
							value={props.formData.evaluatorEmail}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="email"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Indirizzo
						</label>
						<input
							name="evaluatorAddress"
							value={props.formData.evaluatorAddress}
							onChange={(e) => {
								props.onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
			</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
				<Card.Header>
				<CustomToggle eventKey="3" activeKey={activeKey}>Riepilogo valutazione</CustomToggle>
				</Card.Header>
					<Accordion.Body>
					<div className="d-flex flex-column mt-3">
				<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
					Riepilogo
				</label>
				<textarea
					name="overview"
					value={props.formData.overview}
					onChange={(e) => {
						props.onChange(e);
					}}
					id=""
					cols="30"
					rows="4"
					className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
			</div>
			<div className="mt-3">
				<div className="col-md-6 d-flex justify-content-between align-items-end">
					<div className="d-flex flex-column col-sm-8">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Logo
						</label>
						<label
							className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
							style={{ height: "50px" }}>
							{props.formData.logo ? props.formData.logo.name : ""}
						</label>
					</div>
					<div className="col-sm-3">
						<label
							htmlFor="input-file"
							className="bag-btn w-100 general-btn"
							style={{
								height: "50px",
								textAlign: "center",
							}}>
							Carica
						</label>
						<input
							type="file"
							id="input-file"
							className="d-none"
							name="image"
							multiple={false}
							onChange={handleChange}></input>
					</div>
				</div>
			</div>
				</Accordion.Body>
				</Accordion.Item>
				
			{/* 	<Accordion.Item> 

					<Accordion.Body>
					
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo minimo affitto (al mq)
								</label>
								<input
					
									type="number"
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo massimo affitto (al mq)
								</label>
								<input
									
									type="number"
								/>
							</div>
						</div>
						
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo minimo vendita (al mq)
								</label>
								<input
									
									type="number"
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo massimo vendita (al mq)
								</label>
								<input
									
									type="number"
								/>
							</div>
						</div>
						
					</div>
					</Accordion.Body>
				</Accordion.Item>  */}


			
			</Accordion>
			</Form>
		</div>
	);
};

export default Data;
