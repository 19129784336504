import { useState, useEffect } from "react";
import { Form } from 'react-bootstrap'
import '../../assets/css/Scadenzario.css'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { useParams, useNavigate, Link } from 'react-router-dom'
import {Button} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsArchive, BsCheck2Circle } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, fetchDeadline, removeDeadline, editDeadline } from "../../actions/deadline";

import Badge from 'react-bootstrap/Badge';
import ShareChat from "../ShareChat";


export default function ScadenzarioCard(props){
    const navigate = useNavigate()

    //GET THIS ITEM
    const deadlineList = useSelector((state) => state.deadlineReducer?.deadline);
    const [currentItem, setItem] = useState({})


    useEffect(() => {
        if(props.id){
            const deadlineItem = deadlineList.length > 0 ? deadlineList.find(item => item.id == props.id) : {}

            setItem(deadlineItem)
        }
    }, [props.id])

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const startDate = props.startDate? new Date(props.startDate).toLocaleDateString('it-IT', options) : ''
    const startTime = props.startDate? new Date(props.startDate).toLocaleTimeString('it-IT').substring(0,5) : ''
    const endDate = props.endDate? new Date(props.endDate).toLocaleDateString('it-IT', options) : ''
    const endTime = props.endDate? new Date(props.endDate).toLocaleTimeString('it-IT').substring(0,5) : ''

    const toDate = props.toDate? new Date(props.toDate).toLocaleDateString('it-IT', options) : ''
    
    const dispatch = useDispatch();

    //DELETE AND FETCH GESTIONI
    function handleDelete(id) {
        dispatch(removeDeadline(id)).then(() => {
            dispatch(fetchDeadline());
            }
        )          
    }

    function onDelete(e){
        handleDelete(props.id)
        handleClose(e)
    }

    //handle modal delete
	const [show, setShow] = useState(false);

	const handleClose = (e) => {
		e.preventDefault();
		e.stopPropagation();
        setShow(false)
	};
	const handleShow = () => setShow(true);

    let badge_status
    let badge_name

    const today = new Date()

    if(props.isDone){
        badge_status = "success"
        badge_name = "Completato"
    }   else if (props.recurrenceType && props.every && props.toDate && today > new Date(props.toDate) ){
        badge_status = "danger"
        badge_name = "In ritardo"       
    } else if (props.recurrenceType && props.every && props.toDate){
        badge_status = "primary"
        badge_name = "Ricorrente"
    } else if (props.isRecurrent){
        badge_status = "primary"
        badge_name = "Ricorrente"
    } else if( today > new Date(props.endDate)){
        badge_status = "danger"
        badge_name = "In ritardo"
    }


    //EDIT STATUS



    //EDIT
    async function editItem(id, data) {
        dispatch(editDeadline(id, data)).then(() => {
        dispatch(fetchDeadline());
        }
        )
    }

    function changeStatus(){
        let str 
        str = {
            ...currentItem,
            isDone: true
        }
        if(str){
            editItem(props.id, str)
        }
    }

    const handleCardClick = (e) => {
		// Verifica se l'elemento cliccato è il checkbox
		if (e.target.type === "checkbox") {
		  return; // Se sì, non fare nulla
		}
	
		navigate(`${props.id}`)
	}
    

    return (
        <>
            <div className={`scadenzario-card-container d-flex align-items-center justify-content-between mb-2 ${props.isDone? "opacity-50" : ""}`} onClick={handleCardClick}>
                <div className="d-flex">
                    <input

						type="checkbox"
                        onClick={(e)=> {
                            e.stopPropagation()
                        }}
						checked={props.selectedItems?.includes(props.id)}
                        onChange={(e) => {
                            props.handleSelection(e, props.id);
                          }}
						className="me-2 alert-checbox-card"
					/>
                    <div>
                        <p className='fs-12 opacity-50 text-uppercase'>{props.section}</p>
                        <div className="d-flex align-items-center ">
                            <h6 className='fs-16 text-capitalize fw-bold me-2 '>{props.title}</h6>
                            <Badge pill bg={badge_status} >
                            { badge_name}
                            </Badge>
                        </div>
                        
                        <div className='d-md-flex'>
                            <p className='fs-14 me-4 text-capitalize'>Inizio: {startDate}, {startTime} </p>
                            <p className='fs-14 text-capitalize '> Fine: {endDate}, {endTime} </p>
                        </div>
                        {props.recurrenceType && props.every && props.toDate && 
                        <div className='d-md-flex'>
                            <p className='fs-14 me-3'>Ricorrenza:</p>
                            <p className='fs-14 me-3 '> Ogni {props.every} {props.recurrenceType} </p>
                            <p className='fs-14 me-3 '> Fino a {toDate} </p>
                        </div>}
                        
                    </div>
                </div>
                <div className='scadenzario-card-delete d-flex'>
{/*                     <ShareChat elId={props.id} />
 */}                    {!props.isDone && <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 300 }}
                    overlay={
                    <Tooltip className='tooltipContabilita'>
                        Marca questa scadenza come completata
                    </Tooltip>
                    }
                    >
                    <button
                        type="button"
                        className="btn round-icon-btn me-2"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            changeStatus()
                        }}>
                        <BsCheck2Circle />
                    </button>
                    </OverlayTrigger>}
                  {/*   <button
                        type="button"
                        className="btn round-icon-btn me-2"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                        <BsArchive />
                    </button> */}
                    <button
                        type="button"
                        className="btn round-icon-btn me-2"
                        onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleShow()
                    }}>
                        <BsTrash />
                    </button>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className="p-5">
                <div className="d-flex flex-column text-center">
                    <h2 className="fs-32 text-dark fw-semibold mb-3">
                    Vuoi eliminare questo elemento?
                    </h2>
                    <h6 className="fs-16 text-dark fw-normal mb-3">
                    Questa azione è irreversibile
                    </h6>
                    <button
                    type="button"
                    className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
                    onClick={(e) => onDelete(e)}
                    >
                    Conferma
                    </button>
                    <button
                    type="button"
                    className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
                    onClick={(e) => handleClose(e)}
                    >
                    Annulla
                    </button>
                </div>
                </Modal.Body>
            </Modal>
        </>
    )
}