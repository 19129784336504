import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import Alert from 'react-bootstrap/Alert';

import FilterSearchBar from "./FilterSearchBar"
import EmptyStateContainer from "../../EmptyStateContainer"
import { AttivitàCard } from "../Attività/AttivitàCard";

import SearchIllustration from "../../../assets/images/search-illustration.svg";
import EmptyState from "../../../assets/images/attività-empty-state.svg";
import ExportButton from "../../Membri/Fornitori/ExportButton";

import TableView from "../Attività/TableView";

import CustomBadge from "../../CustomBadge";
import { checkUserRole, checkMaster } from "../../../utils/checkUserRole";
import ShareChat from "../../ShareChat";

import ButtonRm from "../../ButtonRm";

const ListViewActivity =  React.forwardRef((props, ref) => {
    console.log(props.selectedList, 'selectedList')
    const translationMapping = {
        id:"Id",
        cdat: "Data di creazione",
        udat: "Ultima modifica",
        userID: "ID utente",
        name: "Identificativo",
        realEstateManagements: "Immobili della gestione",
        activities: 'Attività',
        address: "Indirizzo",
        unitName: 'Immobile di riferimento',
        author: "Creato da",
        priority: "Priorità",
        startDate: "Data inizio",
        expirationDate: "Data di scadenza",
        endDate: "Data fine lavori",
        typology: "Tipologia",
        status: 'Stato',
        assignee: 'Incaricato',
        members: 'Partecipanti',
        description: 'Descrizione',
        predicition: 'Previsione di spesa'
    }

    const [viewType, setViewType] = useState(localStorage.getItem('viewTypeActivity') || 'table');

    return (
        <Row className="dashboard-container">
            {props.attività.length > 0 ? 
            <Row className="position-relative mx-0 px-0">
            <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                <FilterSearchBar
                    setSearchTerm={props.setSearchTerm}
                    selectedCategories={props.selectedCategories}
                    setSelectedCategories={props.setSelectedCategories}
                    clearFiltersAndSort={props.clearFiltersAndSort}
                    setSortBy={props.setSortBy}
                    categories={props.categories}
                    setStartDate={props.setStartDate} setEndDate={props.setEndDate}
                    multiView={true}
                    setViewType={setViewType}
                    viewType={viewType}
                />
            </Col>
            <div>
            { checkUserRole() &&
                props.selectedList.length > 0 && 
                <div className="d-flex mb-3">
                    <ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={props.handleSelectAll}>
                        Seleziona tutti
                    </ButtonRm>
                    <ButtonRm variant='outline' size="extra-small" onClick={props.handleDeselectAll}>
                        Deseleziona Tutti
                    </ButtonRm>
                </div>
            }
            </div>
                {(props.sortedItems.length > 0 && viewType != "card") && 
                    <TableView item={props.sortedItems} handleCheckbox={props.handleCheckbox}   handleSelection={props.handleSelection}
                    selectedList={props.selectedList}
                    />
                }

                {(props.sortedItems.length > 0 && viewType == "card") && 
                props.sortedItems.map( item => {
                    return (
                        <>
                         <AttivitàCard
                            id={item.id}
                            key={item.id}
                            name={item.name}
                            unit_name={item.unitName}
                            author={item.author}
                            creation_date={item.cdat}
                            assignee={item.assignee}
                            expirationDate={item.expirationDate}
                            priority={item.priority}
                            handleCheckbox={props.handleCheckbox}
                            checked={item.checked}
                            members={item.members}
                            item={item}
                            ref={(element) => (ref.current[item.id] = element)}
                            handleSelection={props.handleSelection}
                            selectedList={props.selectedList}
                        /> 


                        </>
                    );
                }) 
                }
                
                { props.sortedItems.length == 0 &&
                <EmptyStateContainer
                    content="Nessuna corrispondenza trovata"
                    img={SearchIllustration}
                />
                }

                <Row className="alert-container">
                    {
                    props.selectedList.length===1 && 
                    <Alert  className="alert-options " style={{background:"#606F88", height:"fit-content"}}>
{/*                         <ShareChat  isButton={false}/>
 */}                        <Alert.Link className="me-2" href="#">
                        <ExportButton data={props.selectedList[0]} mapping={translationMapping} isButton={false} classes="text-white"/>
                        </Alert.Link>
                        {checkUserRole() && <>
                        {viewType == "card" && <Alert.Link className="me-2" onClick={props.handleEdit}>
                        Modifica
                        </Alert.Link>}
                       { checkUserRole() && <Alert.Link className="me-2"  onClick={props.handleShowModal}>
                        Elimina
                        </Alert.Link>}
                        { ( props.selectedList.length == 1 && checkMaster()) &&
                            <Alert.Link 
                                className="me-2" 
                                onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.handleShowPermit();
                            }}>
                                Permessi
                            </Alert.Link>
                            }
                       {/*  <Alert.Link className="me-2">Archivia</Alert.Link> */}
                        {/* <Alert.Link className="me-2" onClick={props.handleShowRc}>
                        Contabilità
                        </Alert.Link> */}
                        </>}
                    </Alert>
                    }

                    { (checkUserRole()) &&
                    props.selectedList.length > 1 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        {/* <Alert.Link className="me-2" href="#">
                        Esporta
                        </Alert.Link> */}
                        {(checkUserRole())  && <>
                        {((checkUserRole())) && <Alert.Link className="me-2" onClick={props.handleShowModal}>
                        Elimina
                        </Alert.Link>}
                        
                       {/*  <Alert.Link className="me-2">Archivia</Alert.Link> */}
                        </>}
                  </Alert>
                    }
                </Row>
            </Row> :
            <Row className="dashboard-container ">
            <div className="mx-auto text-center empty-container ">
                <div className="mx-auto d-table w-90">
                <img loading="lazy" src={EmptyState} alt="empty" />
                </div>
                <p className="fs-20 text-secondary text-center mt-3">
               {checkUserRole() ?  "Non hai aggiunto ancora alcuna attività" : "Non sei stato ancora aggiunto in alcuna attività"}
                </p>
            </div>
            </Row>
        }
        </Row>
    )
})


export default ListViewActivity