import React, { useEffect, useState } from "react";
import edit from "../../../assets/images/edit.svg";
import delete_icon from "../../../assets/images/delete.svg";
import CheckBtn from "../../../assets/images/check_btn.svg";
import CloseBtn from "../../../assets/images/close_btn.svg";

const UserField = (props) => {
  const [change_state, setChangeState] = useState(false);
  const [name, setName] = useState("");
  const changeName = () => {
    setChangeState(false);
    if (name !== props.name) props.changeName(props.id, name);
  };
  useEffect(() => {
    setName(props.name);
  }, [props.name]);
  return (
    <div className="user-field">
      <input
        type="text"
        className="fs-16 text-dark card-input"
        disabled={change_state ? false : true}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      {(props.editMode === undefined || props.editMode === true) && (
        <>
          <div className={`d-flex ${change_state ? "d-none" : ""}`}>
            <button
              type="button"
              className="btn round-icon-btn small"
              onClick={() => {
                setChangeState(true);
              }}
            >
              <img loading="lazy" src={edit} alt="icon" />
            </button>
            <button
              type="button"
              className="btn round-icon-btn small"
              onClick={() => {
                props.deleteItem(props.name);
              }}
            >
              <img loading="lazy" src={delete_icon} alt="icon" />
            </button>
          </div>
        </>
      )}

      <div className={`d-flex ${change_state ? "" : "d-none"}`}>
        <button
          type="button"
          className="btn round-icon-btn small me-3"
          onClick={changeName}
        >
          <img loading="lazy" src={CheckBtn} alt="check-icon" />
        </button>
        <button
          type="button"
          className="btn round-icon-btn small"
          onClick={() => {
            setChangeState(false);
            setName(props.name);
          }}
        >
          <img loading="lazy" src={CloseBtn} alt="check-icon" />
        </button>
      </div>
    </div>
  );
};

export default UserField;
