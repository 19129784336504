import React, { useState, useRef, useEffect } from 'react';
import { Tooltip, Overlay } from 'react-bootstrap';
import Slider from '@mui/material/Slider';

const CustomTooltip = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null); // Riferimento al trigger del tooltip
  const tooltipRef = useRef(null); // Riferimento al contenuto del tooltip

  // Toggle visibilità tooltip
  const handleTooltipToggle = () => setShow(!show);

  // Funzione da eseguire quando il tooltip si chiude
  const handleTooltipClose = () => {
    console.log("update");
  };

  // Gestione del click al di fuori del tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        target.current && 
        !target.current.contains(event.target) &&
        tooltipRef.current && 
        !tooltipRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Esegui la funzione quando il tooltip si chiude
  useEffect(() => {
    if (!show) {
      handleTooltipClose();
    }
  }, [show]);

  //HANDLE DATA

  const coefficientName = props.name 
  const value = props.coeffObj?.[coefficientName]?.coeff

  console.log('value', value)

  return (
    <>
      <span ref={target} onClick={handleTooltipToggle} style={{ cursor: "pointer" }}>
        <i className="bi bi-fire fs-14 ms-2" style={{ color: "#2F80ED" }}></i>
      </span>

      <Overlay
        show={show}
        target={target.current}
        placement="top"
        containerPadding={20}
      >
        <Tooltip className='exclamation-tooltip'>
          <div ref={tooltipRef}>
            <p>Influenza l'algoritmo per la valutazione</p>
            <Slider
             min={props.valMin !== undefined ? props.valMin : -1}
             max={props.valMax !== undefined ? props.valMax : 1}
              name={props.name}
              step={0.01}
              valueLabelDisplay="on"
              value={value === "" ? 0 : value} 
              onChange={(e, newValue) => props.handleCoeffObj(e, newValue)}
              />
            <small>Trascina lo slider per modificare il valore del coefficiente di ponderazione di questo campo</small>
            <br />
            <small><u>SCOPRI DI PIU'</u></small>
          </div>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default CustomTooltip;
