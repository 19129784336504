import { useState, useEffect } from "react";
import edit from "../../assets/images/edit.svg";

export default function ConductorCard(props) {

	const table_header = [
		"INDIRIZZO",
		"CITTA’",
		"CIVICO",
		"TELEFONO",
		"CF",
		"TIPOLOGIA",
	];

	const data = props.table_data ? [
		props.table_data.residenceAddress,
		props.table_data.residenceMunicipality,
		props.table_data.cellularTelephone,
		props.table_data.fiscalCode,
		props.table_data.typeOfConductor,
	] : [];

	const handleChange = (e) => {};

	const [editableInput, setEditableInput] = useState(false);
	useEffect(() => {
		if (props.edit_mode !== true) {
			setEditableInput(-1);
		}
	}, []);

	return (
		<div className="mt-4">
			<div className="popup-purple d-block overflow-hidden rounded-3">
				<div className="purple-popup-header d-flex align-items-center justify-content-between card-title-padding">
					<h3 className="fs-16 text-white ">
						{props.table_data ? props.table_data.name + " " + props.table_data.surname : ""}
					</h3>
					{(props.edit_mode === undefined || props.edit_mode === true) && (
						<button
							type="button"
							className="border-0 bg-transparent text-white"
							onClick={() => props.remove(props.id)}>
							<i class="bi bi-x"></i>
						</button>
					)}
				</div>
				<div className="table-list d-block  card-table">
					<table className="table table-borderless">
						<tbody>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									{table_header[0]}
								</td>

								<td className="d-flex fs-14 text-secondary px-3">
									{props.editable === true ? (
										<>
											<input
												type="text"
												value={"???"}
												name=""
												className="flex-auto w-100 editable-input"
												disabled={!editableInput}
												onChange={handleChange}></input>
											<button
												type="button"
												className={`btn round-icon-btn small ${
													props.edit_mode == true ? "" : "d-none"
												}`}
												onClick={() => {
													setEditableInput(true);
												}}>
												<img loading="lazy" src={edit} alt="icon" />
											</button>
										</>
									) : (
										data[0]
									)}
								</td>
							</tr>

							{data.map((data, index) => {
								if (index != 0) {
									return (
										<tr key={index}>
											<td className="text-uppercase fs-14 text-secondary px-3">
												{table_header[index]}
											</td>
											<td className="text-capitalize fs-14 text-dark px-3">
												{data}
											</td>
										</tr>
									);
								}
							})}

						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
