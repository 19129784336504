import { useState, useEffect } from "react";
import {
	BsPrinter,
	BsDownload,
	BsFilter,
	BsTrash,
	BsPencil,
} from "react-icons/bs";
import EmptyImg from "../../../../assets/images/policy_empty.png";
import ProviderCard from "./ProviderCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { BsPlusCircleFill } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import ProviderModal from "./ProviderModal";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard";
import api, { supplier_endpoint, activity_endpoint } from "../../../../api/api";
import SupplierModal from "../../../Documenti/prima_nota/Tipologia/SupplierModal";
import ExportButton from "../../../Membri/Fornitori/ExportButton";

import translationMapping from "../../../Membri/mapping";

import { Link, useNavigate } from "react-router-dom";

import InsuranceModal from "../../Stabili/InsuranceModal";

import ButtonRm from "../../../ButtonRm";
import translationMappingAssicurazioni from "../../mappingAssicurazioni";

import TableView from "../../../StatoLavoriInCorso/Attività/TableView";

import TableM from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import MyDocument from "../../../AmministrazioneFinanziaria/Rendiconto/ReportPdf";

import translationMappingAffitti from "../../../Affitti/mapping";
import { formattedTimestamp } from "../../../../utils/dateManipulation";

import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';


const Lists = (props) => {

	const navigate = useNavigate()

	function goToActivity(link){
		navigate(link)
	}

	const [data, setData] = useState({});

	const init = () => {
		setData({
			suppliers: Array.isArray(props.formData.suppliers) ? [...props.formData.suppliers] : [],
    		activities: Array.isArray(props.formData.activities) ? [...props.formData.activities] : [],
    		insurances: Array.isArray(props.formData.insurances) ? [...props.formData.insurances] : []
		});
	};

	console.log(data.suppliers, props.formData.suppliers)

	const [insurance, setInsurance] = useState({});

	const initInsurance = () => {
		setInsurance({
			company: "",
			agency: "",
			billType: "",
			billNumber: "",
			reward: "",
			originalStart: "",
			withdrawalYears: "",
			deadlineReward: "",
			deadlineBill: "",
			lastPayment: "",
			nominative: "",
			coverage1: "",
			coverage2: "",
			coverage3: "",
			massimal1: "",
			massimal2: "",
			massimal3: "",
			bill1: "",
			bill2: "",
			bill3: "",
			note: "",
			attachment: [],
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	useEffect(() => {
		initInsurance();
	}, [data.insurances]);

	const insuranceChange = (e) => {
		setInsurance({ ...insurance, [e.target.name]: e.target.value });
	};

	const [fullMode, setFullMode] = useState(false);

	/* const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		props.update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const billType = [
		"Furto",
		"Furto e incendi",
		"Globale fabbricato",
		"incendio",
		"Incendio e furto",
		"responsabilità civile terzi",
		"Rischio locativo",
	];

	

	/* const addProvider = (id) => {
		let temp = [...data.suppliers];
		let tempData = {};
		tempData.category = table_data[id][0];
		tempData.name = table_data[id][1];
		tempData.homeTelephone = table_data[id][2];
		tempData.subjectType = table_data[id][3];
		temp.push(tempData);
		setData({ ...data, ["suppliers"]: temp });
	}; */

	const remove = (id) => {
		let temp = [...data.suppliers];
		temp.splice(id, 1);
		setData({ ...data, ["suppliers"]: temp });
	};

	const [tabState, setTabState] = useState(0)

	const handleChangeTab = (event, newValue) => {
		setTabState(newValue);
	  };

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}

	}, [props.saveState, props.editMode]);

	//GET SUPPLIERS

	const [providerModalShow, setProviderModalShow] = useState(false);

 //GESTIONE FORNITORI

	const [supplierList, setSupplierList] = useState([]);

	const addSelectedSuppliers = (id) => {
		let filteredList = data.suppliers.filter(
			(supplier) => supplier.id === id
		);
		filteredList.length === 0 &&
			data.suppliers.push(
				supplierList.filter((supplier) => supplier.id === id)[0]
			);
		setData({
			...data,
			suppliers: data.suppliers,
		});

		setProviderModalShow(false)
	};


	const removeSelectedSupplier = (id) => {
		setData({
			...data,
			suppliers: data.suppliers.filter((supplier) => supplier.id !== id),
		});
	};

	const fetchSuppliers = async () => {
		try {
			const res = await api().get(`${supplier_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setSupplierList(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	//PRENDI LAVORI ANNESSI
	const [activitiesList, setActivitiesList] = useState([]);


	const fetchActivities = async () => {
		try {
			const res = await api().get(`${activity_endpoint}unit/${props.formData.id}`);
			if (res.data) {
				console.log(res.data.content);
				setActivitiesList(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};


	useEffect(() => {
		fetchSuppliers();
		fetchActivities()

	}, []);



	//insurance
	const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const add = (insurance) => {
    let temp = [...data.insurances];
	setData(prev => {
		return {...prev, 
			insurances: 
			[...temp, insurance]
		}
	});
    setShow(false);
  };

  const [insuranceEditNum, setInsuranceEditNum] = useState(-1);

  const updateInsurance = (insurance) => {
    let temp = [...data.insurances];

	let newArr = temp.map((el, index) => {
		return  index == insuranceEditNum ?
			insurance :
			el
		
	})

    setData(prev => {
		return {...prev, 
			insurances: newArr
		}
	});
    setEditModal(false);
    setInsuranceEditNum(-1);
  };

  const deleteInsurance = () => {
    let temp = [...data.insurances];
	let newArr = temp.filter((el, index) => index != insuranceEditNum )

    setData(prev => {
		return {...prev, 
			insurances: newArr
		}
	});
    setEditModal(false);
    setInsuranceEditNum(-1);
  };

	const handleInsuranceEdit = (id) => {
		setInsuranceEditNum(id);
		setEditModal(true);
	};

	const today = new Date()

	const headerNamesAffitti = [
		"name",
		"typology",
		"rentType",
		"startManagementDate",
		"rentPercentage",
		"managementType" ,
		'signDate',
		'deadline'
	];

	const [pdfUrl, setPdfUrl] = useState(null);


	return (
		<div>
			<div className={`mt-2 ${fullMode ? "d-none" : ""}`}>
				
			</div>
			<div className="edit-content-container-white mt-3">
			<Tabs
				value={tabState}
				onChange={handleChangeTab}
				variant="scrollable"
				aria-label="scrollable auto tabs example"
				scrollButtons
				allowScrollButtonsMobile
			  
			>
				<Tab label="Fornitori" value={0}/>
				<Tab label="Lavori" value={1}/>
				<Tab label="Assicurazioni" value={2}/>
				<Tab label="Affitti" value={3}/>
				<Tab label="Rate" value={4} />
				<Tab label="Valutazione" value={5}/>
				<Tab label="Rendiconto" value={6} />
				<Tab label="Prime note" value={7} />

			</Tabs>
				<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
					<div className={fullMode === true ? "" : "d-none"}>
						<label className="edit-subpage-title">FORNITORI</label>
					</div>
					
					<div className={`d-flex mt-3 ${props.editMode ? "" : "d-none"}`}>
						<Link to="/fornitori/create">
						<button type="button" className="edit-btn edit-white me-2 w-144">
							Nuovo
						</button>
					</Link>
						<div
							class="edit-btn edit-white me-2 w-144"
							onClick={() => {
								setProviderModalShow(true);
							}}>
							Seleziona
						</div>
					</div>
					{data.suppliers?.length > 0 ? (
						<div className="row mt-3">
							<div className={`d-flex ${
							props.editMode === false ? "" : "d-none"
						}`}>
							
							<ExportButton data={data.suppliers?.length > 0 ? data.suppliers : []} mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40" showPrint/>
						</div>
							{data.suppliers.map((item, index) => {
								return (
									<ProviderCard
										key={index}
										edit_mode={props.editMode}
										id={index}
										data={item}
										remove={remove}
									/>
								);
							})}
						</div>
					) : (
						<div className="unit-empty-img">
							<span className="img-comment">Nessun Fornitori selezionato</span>
						</div>
					)}
				</div>
				<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>					
					<div className="user-alert green mt-3">
						<label>
							<i className="fal fa-exclamation-circle me-2"></i> Puoi
							aggiungere un nuovo intervento dalla sezione
							<Link to="/statoavanzamentolavori/attivita"><label className="link-label mx-1">Stato avanzamento lavori</label></Link>
						</label>
					</div>

					{activitiesList?.length > 0 ? (
						<div className="mt-2">
							<TableView item={activitiesList} selectedList={[props.selectedList]} showCheckbox={false}/>
						</div>
					) : (
						<div className="unit-empty-img">
							<span className="img-comment">Nessun lavoro creato</span>
						</div>
					)}
				</div>
				<div className={`mt-3 ${tabState === 2 ? "" : "d-none"}`}>
				<div className={props.editMode ? "" : "d-none"}>
					<ButtonRm
						onClick={() => {
						setShow(true);
						}}
					>
						Nuovo
					</ButtonRm>
					</div>
							{data.insurances?.length > 0 ? (
					<div className="mt-4">
						<div className={`d-flex ${
										props.editMode === false ? "" : "d-none"
									}`}>
										
										<ExportButton data={data.insurances?.length > 0 ? data.insurances : []} mapping={translationMappingAssicurazioni} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40" showPrint/>
						</div>

						<div className="mt-3">
						<TableContainer component={Paper}  className="table-font table-activity">
							<TableM  aria-label="simple table table-activity">
								<TableHead>
									<TableRow>
										<TableCell className='border'>Tipo di polizza</TableCell>
										<TableCell  className='border'>Compagnia</TableCell>
										<TableCell  className='border'>Inizio originale</TableCell>
										<TableCell  className='border'>Scadenza Polizza</TableCell>
										<TableCell  className='border'>Premio</TableCell>

									</TableRow>
								</TableHead>
								<TableBody>
								{data.insurances.length > 0 && data.insurances.map((item, index) => {
								return (
									<TableRow
									key={index}
									onClick={() => {
										handleInsuranceEdit(index);
									}}
									>
									<TableCell  className='border min-width-custom'>{item.billType}</TableCell>
									<TableCell  className='border min-width-custom'>{item.company}</TableCell>
									<TableCell  className='border min-width-custom'>
										{new Date(item.originalStart).toLocaleDateString()}
									</TableCell>
									<TableCell  className='border min-width-custom'>
										{new Date(item.deadlineBill).toLocaleDateString()}
									</TableCell>
									<TableCell  className='border min-width-custom'>{item.reward}</TableCell>
									</TableRow>
								);
								})}
								
								</TableBody>
							</TableM>
						</TableContainer>
						</div>
						
					</div>
					) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={EmptyImg} alt="No image" />
						<span className="img-comment fs-18">
						Non hai aggiunto ancora alcuna assicurazione
						</span>
					</div>
					)}
				</div>
				<div className={`mt-3 ${tabState === 3 ? "" : "d-none"}`}>
					<div className={fullMode === true ? "" : "d-none"}>
						<label className="edit-subpage-title">AFFITTI</label>
					</div>
					<div className="user-alert green my-3">
							<label>
								<i className="fal fa-exclamation-circle me-2"></i> Puoi
								aggiungere un nuovo affitto dalla sezione
								<Link to="/affitti"><label className="link-label mx-1">Affitti</label></Link>
							</label>
						</div>
					{props.formData?.rent?.length > 0 ?

					<div>

					<TableContainer component={Paper}  className="table-font table-activity">
					<TableM  aria-label="simple table table-activity">
						<TableHead>
						<TableRow>
						{headerNamesAffitti.map((header) => (
							<TableCell key={header} className='border'>{translationMappingAffitti[header]}</TableCell>
							))}
						</TableRow>
						</TableHead>
						<TableBody>
						{props.formData?.rent?.length > 0 &&
							props.formData.rent.map((row, index) => (
							<TableRow
								key={index}
								onClick={() => {
									navigate(`/affitti/edit/${row.id}`);
								}}
							>
								{headerNamesAffitti.map((header) => {
											return <TableCell  key={header} className='border min-width-custom'>
											<Link to={`/affitti/edit/${row.id}`} className="text-dark" >
											{row[header]}
											</Link>
										</TableCell >
								})}
							</TableRow>
						))}
						
						</TableBody>
					</TableM>
					</TableContainer> 
					</div> :
						
						<div> 
							Nessun affitto creato per questa unità
						</div>
				}
				</div>
				<div className={`mt-3 ${tabState === 4 ? "" : "d-none"}`}>
					<div className={fullMode === true ? "" : "d-none"}>
						<label className="edit-subpage-title">RATE</label>
					</div>
					<div className="user-alert green my-3">
							<label>
								<i className="fal fa-exclamation-circle me-2"></i> Puoi
								aggiungere una nuova rata dalla sezione
								<Link to="/rate"><label className="link-label mx-1">Rate</label></Link>
							</label>
						</div>
					{props.formData?.installments?.length > 0 ?
					<div>
						<TableContainer component={Paper}  className="table-font table-activity">
						<TableM  aria-label="simple table table-activity">
							<TableHead>
							<TableRow>
								<TableCell className='border'> Identificativo</TableCell>
								<TableCell  className='border'> Data inizio competenza</TableCell>
								<TableCell  className='border'> Data di scadenza
								</TableCell>
								<TableCell  className='border'>Imponibile</TableCell>
								<TableCell className='border'>Iva</TableCell>
								<TableCell className='border'>Già versato</TableCell>
								<TableCell className='border'>Totale dovuto</TableCell>
								<TableCell className='border'>Saldo scaduto</TableCell>
							</TableRow>
							</TableHead>
							<TableBody>
							{props.formData?.installments?.length > 0 &&
								props.formData?.installments.map((row, index) => (
								<TableRow
									key={index}
									onClick={() => {
										navigate(`/rate/${row.id}`);
									}}
								>
									<TableCell  className='border min-width-custom'>
									<Link to={`/rate/${row.id}`} className="text-dark" >
									{row.installmentName}</Link>
									</TableCell>
									
									<TableCell className='border min-width-custom'>
									<Link to={`/rate/${row.id}`} className="text-dark" >
									{row.competenceStartDate?.substring(0,10)}
									</Link>
									</TableCell>
									<TableCell className='border min-width-custom'>
									<Link to={`/rate/${row.id}`} className="text-dark" >
									{row.deadlineDate?.substring(0,10)}
									</Link>
									</TableCell>
									<TableCell className='border min-width-custom'>
										<Link to={`/rate/${row.id}`} className="text-dark" >
										€ 
										{row?.amount}
										</Link>
									</TableCell>
									<TableCell className='border min-width-custom'>
										
									<Link to={`/rate/${row.id}`} className="text-dark" >
										 {Math.ceil((row.vatCode ? parseFloat(row?.vatCode) : 1) * 100 - 100) === 0 ? "Esente" : Math.ceil((row?.vatCode ? parseFloat(row.vatCode) : 1) * 100 - 100) + "%"}
									</Link>	 
									</TableCell>
									<TableCell className='border min-width-custom'><Link to={`/rate/${row.id}`} className="text-dark" > € {row?.totalPaid}</Link></TableCell>
									<TableCell className='border min-width-custom'><Link to={`/rate/${row.id}`} className="text-dark" > € {row?.totalDue}</Link></TableCell>
									<TableCell className='border min-width-custom text-danger'> <Link to={`/rate/${row.id}`} className="text-danger" > € {today.getTime() > new Date(row?.deadlineDate?.substring(0,10)).getTime() ? row?.totalDue : 0} </Link> </TableCell>
								</TableRow>
							))}
							
							</TableBody>
						</TableM>
						</TableContainer>
						</div> :
						<div>
							Nessuna rata creata per questa unità
						</div>
					}
						
				</div>
				<div className={`mt-3 ${tabState === 5 ? "" : "d-none"}`}>
					<div className={fullMode === true ? "" : "d-none"}>
						<label className="edit-subpage-title">VALUTAZIONE</label>
					</div>
					<div className="user-alert green my-3">
							<label>
								<i className="fal fa-exclamation-circle me-2"></i> Puoi
								aggiungere una nuova valutazione dalla sezione
								<Link to="/evaluation"><label className="link-label mx-1">Valutazione Immobiliare</label></Link>
							</label>
						</div>
					<div>
					{props.formData?.evaluations?.length > 0 ?
					<TableContainer component={Paper}  className="table-font table-activity">
						<TableM  aria-label="simple table table-activity">
							<TableHead>
								<TableRow>
									<TableCell className='border'> Identificativo</TableCell>
									<TableCell  className='border'>Indirizzo</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{
								props.formData?.evaluations.map((row, index) => (
								<TableRow
									key={index}
									onClick={() => {
										console.log("view evaluation")
									}}
								>
									<TableCell  className='border min-width-custom'>
									{row.name}
									</TableCell>
									<TableCell className='border min-width-custom'>
								{`${row.address} ,Interno ${row.internal}, Scala ${row.scale} CAP ${row.cap}`}
							</TableCell>
								</TableRow>
							))}
							
							</TableBody>
						</TableM>
					</TableContainer>:
					<div>
						Nessuna valutazione creata per questa unità
					</div>
					}
						
						
						
					</div>
				</div>
				<div className={`mt-3 ${tabState === 6 ? "" : "d-none"}`}>
					<div className={fullMode === true ? "" : "d-none"}>
						<label className="edit-subpage-title">RENDICONTO</label>
					</div>
					<div className="user-alert green my-3">
							<label>
								<i className="fal fa-exclamation-circle me-2"></i> Puoi
								aggiungere un nuovo rendiconto dalla sezione
								<Link to="/report"><label className="link-label mx-1">Rendiconto</label></Link>
							</label>
						</div>
					{props.formData?.reports?.length > 0 ?
					<div>
					<TableContainer component={Paper} 			  	className="table-font table-activity">
					<TableM  aria-label="simple table table-activity">
						<TableHead>
						<TableRow>
							<TableCell className='border'> 
								Identificativo
							</TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{
							props.formData?.reports.map((row, index) => {
							const objParse = JSON.parse(row.movement);
							const newObj = {...row, movement: objParse}
							return <TableRow
								key={index}
								onClick={() => {
									console.log("rendiconto view")
								}}
							>
								<TableCell  className='border min-width-custom'>
								
								{row && <PDFDownloadLink
								document={<MyDocument el={newObj} />}
								fileName="report.pdf"
								style={{ textDecoration: 'none', color: 'inherit' }}
								>
								{({ blob, url, loading, error }) => {
									console.log(row, "ITEM ROW")

									if (!loading && url) {
									setPdfUrl(url);
									}
									return loading ? (
									''
									) : (
										<p>Rendiconto del {formattedTimestamp(row.cdat).split(" ")[0]}</p>
									);
								}}
								</PDFDownloadLink>} 
								
								</TableCell>
							</TableRow>
						})}
						
						</TableBody>
					</TableM>
					</TableContainer>
					</div> :
					<div>
						Nessun rendiconto creato per questa unità
					</div>
					
					}
					
				</div>
				<div className={`mt-3 ${tabState === 7 ? "" : "d-none"}`}>
					<div className={fullMode === true ? "" : "d-none"}>
						<label className="edit-subpage-title">PRIME NOTE</label>
					</div>
					<div className="user-alert green my-3">
							<label>
								<i className="fal fa-exclamation-circle me-2"></i> Puoi
								aggiungere una nuova prima nota dalla sezione
								<Link to="/prima-nota"><label className="link-label mx-1">Prima Nota</label></Link>
							</label>
						</div>
					
					{props.formData?.firstNotes?.length > 0 ?
					<div>
					<TableContainer component={Paper} 			  	className="table-font table-activity">
					<TableM  aria-label="simple table table-activity">
						<TableHead>
						<TableRow>
							<TableCell className='border'> 
								Identificativo
							</TableCell>
							<TableCell className='border'> 
								Data
							</TableCell>
							<TableCell className='border'> 
								Conto partenza
							</TableCell>
							<TableCell className='border'> 
								Conto destinazione
							</TableCell>
							<TableCell className='border'> 
								Entrata
							</TableCell>
							<TableCell className='border'> 
								Uscita
							</TableCell>
						
						</TableRow>
						</TableHead>
						<TableBody>
						{props.formData?.firstNotes?.length > 0 &&
							props.formData?.firstNotes.map((row, index) => (
							<TableRow
								key={index}
								onClick={() => {
									navigate(`/prima-nota/${row.id}`);
								}}
							>
								<TableCell  className='border min-width-custom'>
								<Link to={`/prima-nota/${row.id}`} className="text-dark" > {row.operationDescription}</Link>
								</TableCell>

								<TableCell  className='border min-width-custom'>
								<Link to={`/prima-nota/${row.id}`} className="text-dark" > {formattedTimestamp(row.operationDate)}</Link>
								</TableCell>

								<TableCell  className='border min-width-custom'>
								<Link to={`/prima-nota/${row.id}`} className="text-dark" > {row.fromAccount }</Link>
								</TableCell>

								<TableCell  className='border min-width-custom'>
								<Link to={`/prima-nota/${row.id}`} className="text-dark" > {row.toAccount }</Link>
								</TableCell>

								<TableCell  className='border min-width-custom'>
								<Link to={`/prima-nota/${row.id}`} className="text-dark" >   {row.typology == "Movimento" && row.isEnteringAmount && row.amount !== null ? `€${row.amount.toFixed(2)} ` : ""}</Link>
								</TableCell>

								<TableCell  className='border min-width-custom'>
								<Link to={`/prima-nota/${row.id}`} className="text-dark" > {row.typology == "Movimento" && !row.isEnteringAmount && row.amount !== null ? `€${row.amount.toFixed(2)} ` : ""}</Link>
								</TableCell>

							

								
								
							</TableRow>
						))}
						
						</TableBody>
					</TableM>
					</TableContainer>
					</div> :
					<div>
						Nessuna prima nota creata per questa unità
					</div>
					}
				</div> 
			</div>
				{fullMode ? (
					<div className="back-btn-1" >
						<i className="fal fa-long-arrow-left" />
					</div>
				) : (
					""
				)}
			<Modal
				show={providerModalShow}
				className="type-modal"
				onHide={() => {
					setProviderModalShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setProviderModalShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<SupplierModal
						table_data={
							supplierList.length > 0 &&
							supplierList.map((supplier) => [
								supplier.category,
								supplier.businessName,
								supplier.cellularTelephone,
								supplier.subjectType,
							])
						}
						ids={supplierList.map((supplier) => supplier.id)}
						setShow={setProviderModalShow}
						addSupplier={addSelectedSuppliers}
					/>
				</Modal.Body>
			</Modal>

			<Modal
          show={show}
          className="right-full-pop"
          backdrop
		  onHide={() =>setShow(false)}
        >
			<Modal.Header closeButton></Modal.Header>

          <Modal.Body className="p-4">
            <InsuranceModal
              setShow={setShow}
              newMode={true}
              add={add}
              editMode={true}
			  editView={true}
			  formData={props.formData}
			  getStabiliById={props.getUnitById}
            />
          </Modal.Body>
        	</Modal>

			<Modal
			show={editModal}
			className="right-full-pop"
			backdrop
			onHide={() =>setEditModal(false)}

			>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className="p-4">
				<InsuranceModal
				setShow={setEditModal}
				newMode={false}
				update={updateInsurance}
				delete={deleteInsurance}
				editMode={true}
				editView={true}
				insurance={data.insurances && data.insurances[insuranceEditNum]}
				condoId={insuranceEditNum}
				getStabiliById={props.getUnitById}
				/>
			</Modal.Body>
			</Modal>
		</div>
	);
};

export default Lists;
