import React, { useState, useEffect } from "react";
import SelectToggleBox from "../SelectToggleBox";
import TopHeaderPart from "../TopHeaderPart";
import dropLines from "../../../../assets/images/dwon-lines.svg";
import FliterByDate from "../FliterByDate";
import MonthRateM from "../Members/MonthRateM";
import MediaQuery from "react-responsive";
import Container from "../../../Container";

import TopHeading from "../../../TopHeading";

import SearchInput from "../../../SearchInput";

import filter from "../../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../../assets/images/icon-sidebar/sort.svg"

import { sortDatesByKey, sortStringByKey } from "../../../../utils/filtering";

import { Row, Col } from "react-bootstrap";

import { useParams } from "react-router-dom";
import api , { rate_endpoint } from "../../../../api/api";

export default function InnerDetailsOtherMembers(props) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const [rate, setRate] = useState([])
  const [search, setSearch] = useState("")

  //PRENDI LE RATE SENZA OWNER
  const ratesByOwner = rate.reduce((acc, item) => {
    const ownerId = item.owners[0]?.id;
    const conductorId = item.conductors[0]?.id;
    const supplierId = item.suppliers[0]?.id;

    //add conducotr and supplier
    if (!ownerId && !conductorId && !supplierId) {
      acc.unowned.push(item);
    } 
    return acc;
  }, { unowned: [] });
  
  const {unowned, ...onlyRatesbyOwner} = ratesByOwner

  console.log(unowned)
  


  //get rate
  async function fetchRate() {
    try {
    const response = await api().get(`${rate_endpoint}`);
    if (response.data) {
        setRate(response.data?.content);
    }
    } catch (error) {
    console.log(error);
    }
  }

  useEffect(() => {
    fetchRate();
  }, []);

  //filtr arate per ricerca
  const filteredRatesForMember = unowned && unowned.length > 0
  ? unowned.filter(rateObj => rateObj.installmentName?.toLowerCase().includes(search?.toLowerCase()))
  : [];
  


  const [rateGroups, setRateGroups] = useState({});

  //FILTER-SEARCH-SORT
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [sortBy, setSortBy] = useState("più-recenti");
  const [searchTerm, setSearchTerm] = useState("")



      //RAGGRUPPA RATE PER MESE

      const groupRates = (rates, startDate, endDate, sortBy, searchTerm) => {
        const groups = {};
      
        rates.forEach((rateObj) => {
          const rateStartDate = new Date(rateObj?.competenceStartDate);
          const isWithinRange =
            (!startDate || rateStartDate >= new Date(startDate)) &&
            (!endDate || rateStartDate <= new Date(endDate)) 
          if (isWithinRange) {
            const monthYear = rateStartDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            });
            if (!groups[monthYear]) {
              groups[monthYear] = [];
            }
            groups[monthYear].push(rateObj);
          }
        });
      
        // Ordinamento delle chiavi (mesi/anni) in base alla logica di sortBy
        const sortedKeys = Object.keys(groups).sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          if (sortBy === "meno-recenti") {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        });
      
        // Creazione dell'oggetto ordinato
        const sortedGroups = {};
        sortedKeys.forEach((key) => {
          sortedGroups[key] = groups[key];
        });
      
         // Applicazione del filtro searchTerm
        const filteredGroups = {};
        Object.keys(sortedGroups).forEach((key) => {
          const filteredRates = sortedGroups[key].filter((rateObj) =>
            rateObj.installmentName.toLowerCase().includes(searchTerm.toLowerCase())
          );
          if (filteredRates.length > 0) {
            filteredGroups[key] = filteredRates;
          }
        });

        return filteredGroups;
      };
      
      useEffect(() => {
        const groupedRates = groupRates(filteredRatesForMember, startDate, endDate, sortBy, searchTerm);
        setRateGroups(groupedRates);
      }, [rate, startDate, endDate, sortBy, searchTerm]);

      function clearFiltersAndSort() {
        setEndDate("")
        setStartDate("")
        setSearchTerm("")
      }
      

    
    //SALDO CALCULATOR - SUM TOTALDUE OF EACH RATA
    const today = new Date();

    const saldo =  unowned?.length > 0 && unowned.reduce((accumulator, currentRate) => {
      if ( today >= new Date(currentRate.competenceStartDate)) {
        return accumulator + currentRate?.totalDue;
      }
      return accumulator;
    }, 0);






  return (
    <>

<div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="" 
            back_modal={false}
          />
        </div>
        <Row className="mobile-container d-flex flex-column flex-md-row  justify-content-between align-items-center mt-md-4 div-title-container mb-3">
          <Col >
            <h3>
            Altre rate
            </h3>
          </Col>
          <Col md={3} xl={2}>
            <div className={`saldo-rate ${saldo > 0 ? "bg-danger" : "bg-success"}`} >
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="fs-16 text-white fw-normal w-100 me-2 ">
                          Saldo
                        </p>
                        <p className="fs-20 text-white fw-semibold w-100 text-end">€{saldo ? saldo.toFixed(2) : 0}</p>
                      </div>
                  </div>
          </Col>
        
        </Row>
      </div>
        <div className="dashboard-container">
        <div className="px-2 px-md-0">
        {<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<SearchInput setSearch={setSearchTerm}/>

				<div className="d-flex align-items-center ms-3">
					<div className="user-dropdown">
					<div className="filter">                   
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
						<img loading="lazy" src={filter}/>
						</div>
            <div className="user-dropdown-content">
            <div className="fs-14 text-uppercase">Periodo</div>
                  <label className="fs-12">Da</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="mb-2"
                  />
                  <label className="fs-12">A</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
               <a className="text-decoration-underline text-dark text-center mt-3" onClick={clearFiltersAndSort}>
                Rimuovi filtri
              </a>
						</div>
            </div>
					</div>

					<div className="user-dropdown">
						<div className="sort d-flex">
				<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
					<img loading="lazy" src={sort}/>
				</div>
						</div>
						<div className="user-dropdown-content">
							<div className="fs-16 fw-bold">Ordina per</div>
				<div
								className="fs-14 mb-1 sort-text"
				onClick={() => setSortBy("meno-recenti")}
								>
								Meno recenti
							</div>
				<div
								className="fs-14 mb-1 sort-text"
				onClick={() => setSortBy("più-recenti")}
								>
								Più recenti
							</div>

						</div>
					</div>
				</div>

			  </Col>}
          {/* <div className="d-block my-3">
            <SelectToggleBox textToggle="Tutte le unità" units={units} setSelectedUnit={setSelectedUnit} />
          </div> */}
          <MediaQuery minWidth={1000}>
            <div className="d-block mb-4">
              <div className="d-flex flex-column">
                {/* <h3 className="fs-16 text-dark fw-bold mb-2 text-uppercase">
                  Luglio 2022
                </h3> */}
                {Object.entries(rateGroups).map(([monthYear, rateArray]) => (
                  <div key={monthYear}>
                    <p className="fs-14 text-uppercase mt-3 mb-1">{monthYear}</p>
                    {rateArray.map(rateObj => (
                      <MonthRateM  key={rateObj.id} item={rateObj} />
                    ))}
                  </div>
                ))}
  
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={999}>
        <div className="d-flex flex-column">
        {Object.entries(rateGroups).map(([monthYear, rateArray]) => (
                  <div key={monthYear}>
                    <p className="fs-14 text-uppercase mt-3 mb-1">{monthYear}</p>
                    {rateArray.map(rateObj => (
                      <MonthRateM  key={rateObj.id} item={rateObj} />
                    ))}
                  </div>
                ))}
        </div>
      </MediaQuery> 
      </div>
        </div>
      
    </>
  );
}
