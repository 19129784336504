import { REMEMBER_DATA} from "./types";

/* ACTION CREATORS */
const changeRememberData = () => {
    return {
        type: REMEMBER_DATA,
    };
}

/* REDUCER */
const initialState = {
    rememberData: "storage"
}

const rememberReducer = (state = initialState, action) => {
    switch(action.type){
        case REMEMBER_DATA:
            return {...state, 
                rememberData: state.rememberData == "storage" ? "session" : "storage"
                }
        default:
            return state
    }
}

export default rememberReducer
export { changeRememberData };
