const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    name:'Identificativo',
    category: 'Categoria',
    typology: 'Tipologia',
    address: 'Indirizzo',
    houseNumber: 'Numero civico',
    scale: 'Scala',
    floor: 'Piano',
    internal: 'Interno',
    municipality: 'Comune',
    province: 'Provincia',
    cap: 'CAP',
        grossCoveredMq: "Mq coperti lordi",
        basementMq: "Mq seminterrati",
        finishingVerandaMq: "Mq verande rifinite",
        notFinishingVerandaMq: "Mq verande non rifinite",
        mezzanineMq: "Mq soppalchi abitabili",
        canopyMq: "Mq tettoie",
        balconyMq: "Mq balconi",
        terraceMq: "Mq terrazzi",
        gardenMq: "Mq giardini/cortili",
        godremo: "Mq loggie patii",
        solarPavementMq: "Mq lastrico solare",
        otherUncoveredMq: "Altri mq scoperti",
        courtyardAreaMq: "Mq aree cortilizie",
        attachedLandMq: "Mq terreni annessi",
        uncoveredMq: "Mq scoperti",
        wineryMq: "Mq cantina",
        garretMq: "Mq soffitta",
        box: "Box auto",
        boxMq: "Mq box auto",
        boxAccessibility: "Manovra accesso box",
        boxLocation: "Ubicazione box",
        coveredParkingSpace: "Posto auto coperto",
        coveredParkingAccessibility: "Manovra accesso auto coperto",
        coveredParkingLocation: "Ubicazione posto auto coperto",
        uncoveredParkingSpace: "Posto auto scoperto",
        uncoveredParkingAccessibility: "Manovra accesso auto scoperto",
        uncoveredParkingLocation: "Ubicazione posto auto scoperto",
        condominialParkingSpace: "Posto auto condominiale",
        condominialParkingType: "Tipo posto auto condominiale",
        parkingAccessibility: "Difficoltà parcheggio zona",
        floor: "Piano immobile",
        lastFloor: "Attico/Ultimo piano/Mansarda",
        lift: "Ascensore",
        mainFloor: "Piano principale",
        numberExposition: "N. affacci principali",
        mainExposition: "Esposizione principale",
        mainView: "Vista principale",
        brightness: "Luminosità",
        noisiness: "Rumorosità",
        ceilingHeight: "Altezza soffitti",
        internalLevels: "Livelli interni",
        internalDistribution: "Distribuzione vani interni",
        coveredMqRoomsRatio: "Rapporto tra mq coperti e n vani",
        coveredMqBathsRatio: "Rapporto tra mq coperti e bagni",
        internalState: "Stato conservativo interno",
        finishingQuality: "Grado finiture",
        heatingType: "Tipo riscaldamento",
        energeticClass: "Classe energetica",
        buildingLocation: "Ubicazione edificio",
        buildingTypology: "Tipologia edificio",
        buildingAge: "Età edificio",
        buildingState: "Stato conservazione edificio",
        totalFloorNumber: "Totale piani edificio",
        buildingType: "Tipo edificio",
        marketRef: "Mercato di riferimento",
        estateType: "Tipo di proprietà",
        rentalState: "Stato locativo",
        leaseProfitability: "Redditività locazione",
        windowPosition: "Posizione vetrine",
        commercialVisibility: "Visibilità commerciale del negozio",
        entranceWidth: "Larghezza entrata box in mq",
        carCapacity: "Capienza auto",
        motorcycleCapacity: "Capienza moto",
        buildingYear: "Anno di costruzione",
        underbeamHeight: "Altezza sottotrave in metri",
        supportingStructureType: "Tipo di struttura portante",
        mainLevelCeilingHeight: "Altezza soffitti livello principale",
        locationVisibility: "Visibilità ubicazione",
        entryType: "Tipo di ingresso al magazzino",
        labEntryType: "Tipo di ingresso al laboratorio",
        description: "Descrizione immobile",			  
    cadastralType: 'Tipo di catasto',
    cadastralTypeProperty: 'Tipologia catastale dell’immobile',
    portionOfCadastralUnit: 'L’unità inserita è una porzione…',
    historicProperty: 'Immobile storico',
    municipalityCode: 'Codice comune',
    sheet: 'Foglio',
    parcel: 'Particella/mappale',
    subaltern: 'Subalterno',
    categoryLandRegistry: 'Categoria',
    classLandRegistry: 'Classe',
    consistence: 'Consistenza',
    cadastralIncome: 'Rendita catastale',
    urbanSection: 'Sezione urbana',
    zone: 'Zona',
    microZone: 'Microzona',
    inStackingProcess: 'In via di accatastamento',
    surveyAddress: 'Indirizzo di visura',
    censusArea: 'Zona censuaria',
    unitDestination: 'Destinazione unità immobiliare',
    assignmentType: 'Tipo di incarico',
    haveSocialFee: 'Soggetto a canone sociale',
    managementType: 'Tipo di gestione',
    withholdingDeposit: 'Trattenere la cauzione per conto del locatore',
    defaultRegistrationOffice: 'Ufficio predefinito registrazione',
    typologyNotRented: 'Tipologia non locati negli studi del settore',
    ownerCommissions: 'Provvigioni proprietario',
    buyerCommissions: 'Provvigioni acquirente',
    lessorCommissions: 'Provvigioni locatore',
    conductorCommissions: 'Provvigioni conduttore',
    unit: 'Unità',
    furniture: 'Arredo',
    equipment: 'Dotazione',
    energyCertification: 'APE',
    otherCertification: 'Altre certificazioni',
    includedExpenses: 'Spese incluse',
    excludedExpenses: 'Spese escluse',
    contractAddendum: 'Addendum al contratto',
    keyDelivery: 'Consegna delle chiavi',
    notes: 'Note',
    imuNote: 'Note IMU',
    status: 'Stato unità',
    rentalState: 'Stato locativo',
    hasLift: 'Ascensore',
    furnished: 'Arredato',
    rooms: 'Locali',
    bathrooms: 'Bagni',
    bedrooms: 'Posti letto',
    coveredMq: 'Mq coperti',
    notCoveredMq: 'Mq scoperti',
    northExposition: 'Nord',
    westExposition: 'Ovest',
    eastExposition: 'Est',
    southExposition: 'Sud',
    energyClass: 'Classe energetica',
    energyPerformanceIndex: 'Indice di prestazione energetica',
    apeDeadline: 'Scadenza dell’APE',
    heatingType: 'Tipo di riscaldamento',
    haveAirConditioning: 'Aria condizionata',
    haveFireplace: 'Camino',
    haveCondominiumParkingSpace: 'Posto auto condominiale',
    havePrivateParkingSpace: 'Posto auto privato',
    haveBox: 'Box',
    haveCellar: 'Cantina',
    haveAttic: 'Mansarda',
    haveBalcony: 'Balcone',
    haveTerrace: 'Terrazza',
    haveGarden: 'Giardino',
    havePool: 'Piscina',
    haveDisabilityAccess: 'Accesso per i disabili',
    haveReception: 'Portineria',
    havePetPermission: 'Animali domestici permessi',
    electricitySupplier: 'Fornitore elettricità',
    electricityCounterNumber: 'Matricola contatore elettrico',
    podCode: 'Codice POD',
    electricityTariffPlan: 'Piano tariffario elettricità',
    electricityState: 'Stato elettricità',
    electricityUserCode: 'Codice utente elettricità',
    gasSupplier: 'Fornitore gas',
    gasCounterNumber: 'Matricola contatore gas',
    pdrCode: 'Codice PDR',
    gasTariffPlan: 'Piano tariffario gas',
    gasState: 'Stato gas',
    gasUserCode: 'Codice utente gas',
    hotWaterSupplier: 'Fornitore acqua calda',
    coldWaterSupplier: 'Fornitore acqua fredda',
    coldWaterTariffPlan: 'Piano tariffario acqua fredda',
    coldWaterState: 'Stato acqua fredda',
    coldWaterUserCode: 'Codice utente acqua fredda',
    heatingSupplier: 'Fornitore riscaldamento',
    boilerCode: 'Matricola caldaia',
    costCenter: 'Centro di costo',
    propertyTaxes: 'Imposte patrimoniali (IMU, TASI)',
    incomeTaxes: 'Imposte sul reddito (IRPEF o IRES)',
    condominiumFees: 'Spese condominiali (lato proprietà)',
    directCostCda: 'Costi diretti CDA',
    indirectCostCda: 'Costi indiretti CDA',
    photo: 'Aggiungi foto',
    planimetry: 'Aggiungi planimetria',
    attachment: 'Allegati',
    pertinence: 'Altre API',
    image: 'Immagine unità immobiliare',
    name: 'Identificativo',
    userId: 'User ID',
    accountable: 'Accountable',
    accountingType: 'Accounting Type',
    cadastralMq: 'Superficie catastale',
    commercialMq: 'Superficie commerciale',
    netMq: 'Sup utile netta',
    grossMq: 'Sup utile lorda',
    manufactureYear: 'Anno di fabbricazione',
    renovationLastYear: 'Anno ultima ristrutturazione',
    antiquity: 'Vetustà (anni)',
    freeFrom: 'Libero dal',
    bookValue: 'Valore di bilancio',
    omiValue: 'Valore OMI',
    income: 'Rendita',
    socialFee: 'Canone sociale',
    unusable: 'Inagibile',
    registrationTaxes: 'Imposte di registro',
    total: 'Totale',
    averageRent: 'Media affitto',
    averageSale: 'Media vendita',
    grossSaleIncomeNew: 'Red lorda vendita (nuovo)',
    grossSaleIncomeOld: 'Red lorda vendita (vetustà)',
    rentIncomeNew: 'Red affitto (nuovo)',
    rentIncomeOld: 'Red affitto (vetustà)',
    actualNetIncome: 'Red effettiva netta',
    potentialNetIncome: 'Red potenziale netta',
    grossIncomeOnBookValue: 'Red lorda su val di bilancio',
    netIncomeOnBookValue: 'Red netta su val di bilancio',
    currentFee: 'Canone attuale',
    analyticalResult: 'Risultato analitico',
    note: 'Note',
    rendicontable: 'Rendicontabile',
    protocolNumber: 'Numero protocollo',
    procolDate: 'Data protocollo',
    installmentsRate: 'Cadenza rate',
    bailmentProposal: 'Deposito cauzionale proposta',
    warrantyType: 'Tipo di garanzia richiesta',
    bailmentRent: 'Deposito cauzionale affitto',
    favoritePaymentMethod: 'Modalità di pagamento preferita',
    monthlyFee: 'Canone mensile',
    flateRateExpenses: 'Spese forfettarie',
    objectToSell: 'Oggetto vendita',
    price: 'Prezzo',
    denominatorParcel: 'Particella denominatore',
    freeImu: 'Esente IMU',
    imuTaxableBase: 'Base imponibile IMU',
    iciTaxableBase: 'Base imponibile ICI',
    imuRate: 'Aliquota IMU',
    iciRate: 'Aliquota ICI',
    modificationTypes: 'Tipo di modifica',
    modificationDates: 'Date di modifica',
    associatedWorks: 'Lavori associati',
    modificationDate: 'Data di modifica',
    holders: 'Intestatari',
    realRights: 'Diritti reali',
    stapled: 'Graffato',
    cadastralNote: 'Note',
    associatedWork: 'Lavoro associato',
    olderData: 'Dati catastali precedenti',
    buildingLocation: 'Tipo di edificio',
    buildingAge: 'Età edificio',
    buildingType: 'Tipologia Edificio',
    buildingState: 'Stato conservativo Edificio',
    conductors: 'Conduttori',
    owners: 'Proprietari',
    suppliers:"Fornitori",
    ofProperty: 'Millesimi di proprietà',
    scaleMillesimi: 'Millesimi di scale',
    reception: 'Millesimi di portineria',
    heating: 'Millesimi di riscaldamento',
    district : 'Millesimi di Comprensorio',
    water: "Millesimi di acqua",
    condominium: 'Condominio'
};

export default translationMapping