import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "../../../../assets/css/Membri.css";
import cardimage from "../../../../assets/images/bank-icon.svg";
import CreateAccount from "../../CreateAccount";
import DeleteConfirm from "./DeleteConfirm";

import ButtonRm from "../../../ButtonRm";


import { BankCard,BankCardItem } from "../../../BankCard";

export default function Correnti(props) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checked, setChecked] = useState(false);

  const forward = () => {
    props.next();
  };

      /* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname

  return (
    <div
      className="d-block w-100 mb-3 bg-white"
      style={{ borderRadius: "16px" }}
    >
      <Form>
        <div className="form-item-align-box d-block py-5">
          <div className="row gy-4">
            <div className="col-12">
              <div className="d-flex align-items-center form-last-btns">
              <ButtonRm variant="tertiary" onClick={() => setShowCreateModal(true)}>Nuovo</ButtonRm>
              </div>
            </div>
            {props.formData.bankAccounts?.length === 0 ? (
              <div className="col-12 mt-5">
                <div className="mx-auto d-table text-center">
                  <div className="image-user mx-auto d-table w-130 fw-100">
                    <img loading="lazy" src={cardimage} alt="user-key" />
                  </div>
                  <p className="fs-18 text-secondary text-center">
                    Non hai aggiunto ancora alcun conto
                  </p>
                </div>
              </div>
            ) : (
              props.formData.bankAccounts?.length > 0 && props.formData.bankAccounts.map((bankAccount, index) => (
              <BankCard 
                name={bankAccount.name}         color="#78ab8b" 
                deleteFunc={props.removeBankAccount} 
                index={index}>
                  <BankCardItem property="IBAN" value={bankAccount.iban}/>
                  <BankCardItem property="INTESTATO A" value={bankAccount.header}/>
                  <BankCardItem property="BANCA" value={bankAccount.bank}/>
                  <BankCardItem property="ABI" value={bankAccount.abi}/>
                  <BankCardItem property="CAB" value={bankAccount.cab}/>
                  <BankCardItem property="CIN" value={bankAccount.cin}/>
                  <BankCardItem property="CONTO" value={bankAccount.conto}/>
                  <BankCardItem property="BIC/SWIFT" value={bankAccount.bic}/>
                  <BankCardItem property="CODICE SIA" value={bankAccount.siaCode}/>
                  <BankCardItem property="AGENZIA" value={bankAccount.agency}/>
                  <BankCardItem property="SEPA CUC" value={bankAccount.cucSepaCode}/>
              </BankCard>
              ))
            )}
            { path == "/conduttori/create" && <div className="col-12">
              <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
                <ButtonRm variant="tertiary" onClick={() => props.back()} className="me-2">Indietro</ButtonRm>
                <ButtonRm variant="primary"  onClick={forward}>Avanti</ButtonRm>
              </div>
            </div>}
          </div>
        </div>
      </Form>
      <CreateAccount
        show={showCreateModal}
        setShow={setShowCreateModal}
        setShowDeleteModal={setShowDeleteModal}
        addBankAccount={props.addBankAccount}
        // setChecked={(v) => setChecked(v)}
        // setData={(v) => setData(v)}
      />
      <DeleteConfirm
        show={showDeleteModal}
        // confirm={() => reset()}
        // setShowDeleteModal={setShowDeleteModal}
      />
    </div>
  );
}
