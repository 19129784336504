import { FaBullseye } from "react-icons/fa";

const verifications = [
    {
        name: 'Identità',
        icon: 'bi bi-person-fill',
        status: true
    },
    {
        name: 'Metodo di pagamento',
        icon: 'bi bi-credit-card-2-back-fill',
        status: false
    },
    {
        name: 'Numero di telefono',
        icon: 'bi bi-telephone-fill',
        status: true
    },
    {
        name: 'Email',
        icon: 'bi bi-envelope-fill',
        status: true
    },
    {
        name: 'Social',
        icon: 'bi bi-globe',
        status: false
    }
]

const company = {
    address: "Via tulpo 12, Genova",
    categories: ['Idraulico', 'Elettricista'],
    hourlyFee: "12",    
    name: "Idra srl",
    status: "Disponibilità immediata"
}

const projectsEx = [{
    name: 'Ristrutturazione bagno moderno',
    description: 'Ho gestito con successo la ristrutturazione completa di un bagno, installando nuovi impianti idraulici, sostituendo i sanitari e creando un ambiente moderno e funzionale per il cliente. Ho garantito una corretta installazione degli impianti idraulici, con attenzione ai dettagli per un risultato di alta qualità.',
    skills: 'installazione impianti, sostituzione sanitari',
    img: null
},
{
    name: 'Sistema di riscaldamento',
    description: "Ho progettato e installato un efficiente sistema di riscaldamento a pavimento in una residenza, offrendo comfort e efficienza energetica al cliente. Ho lavorato in stretta collaborazione con il cliente per valutare le loro esigenze e ho realizzato un sistema su misura, garantendo l'installazione corretta e la perfetta funzionalità del sistema.",
    skills: 'progettazione riscaldamento',
    img: null
},
{
    name: 'Sostituzione tubature',
    description: "Ho gestito con successo la sostituzione di vecchie tubature in un edificio residenziale, migliorando l'efficienza e la sicurezza del sistema idraulico. Ho pianificato attentamente l'intervento, rimuovendo le tubature vecchie e installando nuovi materiali di alta qualità. Ho eseguito il lavoro in modo efficiente, garantendo una corretta connessione delle tubature e il corretto flusso dell'acqua.",
    skills: 'sostituzione tubi',
    img: null
},
{
    name: 'Sistema di riscaldamento',
    description: "Ho progettato e installato un efficiente sistema di riscaldamento a pavimento in una residenza, offrendo comfort e efficienza energetica al cliente. Ho lavorato in stretta collaborazione con il cliente per valutare le loro esigenze e ho realizzato un sistema su misura, garantendo l'installazione corretta e la perfetta funzionalità del sistema.",
    skills: 'progettazione riscaldamento',
    img: null
},
{
    name: 'Sostituzione tubature',
    description: "Ho gestito con successo la sostituzione di vecchie tubature in un edificio residenziale, migliorando l'efficienza e la sicurezza del sistema idraulico. Ho pianificato attentamente l'intervento, rimuovendo le tubature vecchie e installando nuovi materiali di alta qualità. Ho eseguito il lavoro in modo efficiente, garantendo una corretta connessione delle tubature e il corretto flusso dell'acqua.",
    skills: 'sostituzione tubi',
    img: null
}


]

const servicesEx =  [
    {
      name: 'Installazione nuovi impianti idraulici',
      description: 'Installazione di impianti idraulici completi per nuove costruzioni o ristrutturazioni.',
      min: 500,
      max: 2000
    },
    {
      name: 'Riparazione perdite d\'acqua',
      description: 'Individuazione e riparazione di perdite d\'acqua in tubazioni, rubinetti o sistemi idraulici.',
      min: 100,
      max: 500
    },
    {
      name: 'Sostituzione sanitari',
      description: 'Rimozione e sostituzione di sanitari come lavandini, wc, docce, vasche da bagno, ecc.',
      min: 200,
      max: 800
    },
    {
      name: 'Pulizia e sblocco scarichi',
      description: 'Pulizia e sblocco di scarichi ostruiti o lenti a defluire.',
      min: 80,
      max: 300
    },
    {
      name: 'Installazione sistemi di filtrazione acqua',
      description: 'Installazione di sistemi di filtrazione dell\'acqua per garantire acqua potabile sicura e di qualità.',
      min: 300,
      max: 1000
    }
  ];
  

  const productsEx = [
    {
      name: 'Rubinetto a cascata',
      description: 'Rubinetto moderno a cascata per lavandino o vasca da bagno.',
      cost: 50
    },
    {
      name: 'Doccia a pioggia',
      description: 'Soffione per doccia a effetto pioggia con funzioni di massaggio.',
      cost: 120
    },
    {
      name: 'Set di valvole termostatiche',
      description: 'Valvole termostatiche per il controllo della temperatura dell\'acqua nella doccia.',
      cost: 80
    },
    {
      name: 'Pompa di drenaggio',
      description: 'Pompa di drenaggio per rimuovere acqua stagnante da cantine o seminterrati.',
      cost: 200
    },
    {
      name: 'Depuratore d\'acqua',
      description: 'Depuratore d\'acqua per migliorare la qualità dell\'acqua potabile.',
      cost: 150
    }
  ];
  

  const workExperiences = [
    {
      company: 'ABC Plumbing Services',
      role: 'Idraulico Senior',
      year: '2018-2021'
    },
    {
      company: 'XYZ Construction',
      role: 'Responsabile Reparto Idraulica',
      year: '2015-2018'
    },
    {
      company: 'Smith Plumbing Solutions',
      role: 'Apprendista Idraulico',
      year: '2013-2015'
    }
  ];
  

const certifications = [
    {
      name: 'Certificato di Installazione Impianti Idraulici Avanzati',
      year: '2019'
    },
    {
      name: 'Certificazione di Sicurezza e Norme di Lavoro',
      year: '2017'
    },
    {
      name: 'Corso di Gestione delle Emergenze Idrauliche',
      year: '2016'
    }
  ];

  const publicationsEx = [
    {
      title: "Nuove tendenze nell'idraulica moderna",
      magazine: 'Rivista degli Idraulici Professionisti',
      year: '2022'
    },
    {
      title: 'Gestione efficiente dei consumi idrici nelle abitazioni',
      magazine: "Il Quotidiano dell'Idraulica",
      year: '2021'
    },
    {
      title: 'Innovazioni tecnologiche per il risparmio idrico',
      magazine: 'Journal of Plumbing Technology',
      year: '2020'
    }
  ];

  const reviews = [
    {
        autore: 'Mario R.',
        recensione: 'Professionista affidabile e competente, ha risolto il mio problema idraulico in modo rapido e efficiente. Consigliato!',
        stelle: 5
    },
    {
        autore: 'Laura B.',
        recensione: "Servizio eccellente! L'idraulico è stato puntuale, cortese e ha fatto un lavoro impeccabile. Lo raccomando senza esitazioni.",
        stelle: 4
    },
    {
        autore: 'Luigi V.',
        recensione: "Sono rimasto molto soddisfatto del servizio offerto. L'idraulico è stato professionale, ha risolto il problema in modo rapido e il prezzo era ragionevole. Voto 4 su 5.",
        stelle: 4
    },
    {
        autore: 'Anna G.',
        recensione: "Ho chiamato l'idraulico per una perdita d'acqua urgente e sono rimasta molto soddisfatta dell'intervento tempestivo e della qualità del lavoro svolto. Merita 5 stelle!",
        stelle: 5
    }
    ];

    const filterItems = [
        {
          id: 1,
          name: 'Filtro 1'
        },
        {
          id: 2,
          name: 'Filtro 2'
        },
        {
          id: 3,
          name: 'Filtro 3'
        },
        {
          id: 4,
          name: 'Filtro 4'
        },
        {
          id: 5,
          name: 'Filtro 5'
        },
        {
          id: 6,
          name: 'Filtro 6'
        },
        {
          id: 7,
          name: 'Filtro 7'
        },
        {
          id: 8,
          name: 'Filtro 8'
        },
        {
          id: 9,
          name: 'Filtro 9'
        },
        {
          id: 10,
          name: 'Filtro 10'
        }
      ];
      


export  {verifications, company, servicesEx, projectsEx, productsEx, workExperiences, certifications, publicationsEx, reviews, filterItems}