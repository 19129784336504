import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import support from  "../../assets/images/supp.png"

import logo from "../../assets/images/Group 1189.svg"

import { Link } from 'react-router-dom';

import ResponsiveNavbar from './NavRm';

import Footer from './Footer';
import Gestionali from './Gestionali';



export default function Servizi (){
    return (
        <div style={{background:'#fff', position: "relative"}}>
            <div style={{background: ''}}>
                <Container className="px-3 px-md-0">

                <ResponsiveNavbar/>

                    <Row className="flex-column mb-2">
                        <Col className="d-flex justify-content-center" style={{padding: "5rem 0rem 2rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center fs-40 fs-md-50 syne-font">Servizi</h1>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className="mt-2 justify-content-center align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col>
                            <p>
                                Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                garantendo massima efficienza e sicurezza dei dati trattati.
                            </p>
                            <p>
                                Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                garantendo massima efficienza e sicurezza dei dati trattati.
                            </p>
                            <p>
                                Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                garantendo massima efficienza e sicurezza dei dati trattati.
                            </p>
                            <p>
                                Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                garantendo massima efficienza e sicurezza dei dati trattati.
                            </p>
                        </Col>
                        
                    </Row>

                    <Row className="mx-auto" style={{maxWidth: "1100px",  marginTop:"4rem", marginBottom:'4rem'}}>
                        <Row className="flex-column flex-md-row align-items-center mb-5 mx-auto justify-content-between gy-5 gy-lg-0" style={{maxWidth: "1100px"}} >
                            <Col md={5} className='order-2 order-lg-1'>
                                <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle"/>
                            </Col>
                            <Col md={5} className='order-1 order-lg-2'>
                                <div>
                                    <h1 className='fs-24 fs-md-32 syne-font mb-1'>Vantaggi
                                    </h1>
                                    <p>  Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                garantendo massima efficienza e sicurezza dei dati trattati.</p>
                                    
                                        <p>♦ Real Meta permette di velocizzare e ottimizzare i processimi</p>
                                        <p>♦ Real Meta permette di velocizzare e ottimizzare i processimi</p>
                                        <p>♦ Real Meta permette di velocizzare e ottimizzare i processimi</p>
                                        <p>♦ Real Meta permette di velocizzare e ottimizzare i processimi</p>
                                    
                                </div>
                            </Col>
                            
                        </Row>
                       
                      
                    </Row>

                    <Row className="flex-column mb-4">
                        <Col className="d-flex justify-content-center" style={{padding: "5rem 0rem 0rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <h2 className="text-center fs-24 fs-md-30 syne-font">Personalizzazione</h2>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className=" justify-content-start align-items-center mx-auto position-relative" >
                        <Gestionali />
                    </Row>

                    <Row className="mt-5 justify-content-start align-items-center mx-auto p-5 position-relative" style={{maxWidth: "1100px", background: "#0F1B4C", borderRadius: "1rem", color:"white"}}>
                        <Col md={8} className="left">
                            <h2 className="syne-font">Scopri di più</h2>
                            <p className='mt-2 mb-3'>Real Meta semplifica e automattiza i processi di gestione immobiliare, mentre tu puoi  concentrarti e far crescere la tua attività.</p>
                            <Link to="/contatti">
                                <Button variant="primary" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}}>Scarica la brochure</Button>
                            </Link>
                            <br/>
                        </Col>

                      
        
                    </Row>



                    <Footer />

                   

                </Container>
            </div>

            {/*             <Image src={support} width="250px" style={{position: "fixed", bottom: "0px", right: "0px"}}  className='d-none d-lg-block'/>
            */}        
            </div>
    )
}