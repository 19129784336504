import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { BsPlusCircleFill } from "react-icons/bs";
import AddWarranty from "./AddWarranty";
import CustomBadge from "../CustomBadge";


export default function NewSuretyModal(props) {
  const state = [
    "Da stipulare",
    "Stipulata",
    "Richiesto il rinnovo",
    "Rinnovata",
    "Escussa parzialmente",
    "Escussa totalmente",
    "Restituita",
  ];
  const guarantor = ["Da stipulare"];
  const guarantee_type = [
    "Garanzia ripristino status quo ante immobili per modifiche apportate",
    "Garanzia delle obbligazioni contrattuali assunte",
    "Garanzia",
    "Garanzia del pagamento dei canoni di locazione e degli oneri accessori",
    "Garanzia dei danni arrecati alla cosca locata",
    "Garanzia del danno conseguente la ritardata restituzione della cosa locata",
  ];
  const [state_index, setStateIndex] = useState(0);
  const [warranty, setWarranty] = useState([]);
  const addWarranty = () => {
    let temp = [...warranty];
    temp.push("");
    setWarranty(temp);
  };
  const deleteWarranty = (id) => {
    let temp = [...warranty];
    temp.splice(id, 1);
    setWarranty(temp);
  };
  return (
    <>
      <div className="d-block w-100 mb-3 mt-5">
        <div>
          <h2 className="fs-20 text-dark mb-2 fw-bold">Nuova fideiussione</h2>
        </div>
      </div>
      <Form>
        <div className="form-item-align-box d-block">
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Stato
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => {
                    setStateIndex(e.target.value);
                  }}
                >
                  {state.map((menu, index) => {
                    return (
                      <option key={index} value={index}>
                        {menu}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label className="f-label fs-16 d-flex align-items-center
mb-2">
                  {state_index === 0 ? "Scadenza per la stipula" : "Scadenza"}
                </label>
                <input
                  className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
                  max="9999-12-31"
                  type="date"
                ></input>
              </div>
            </div>
          </div>
          {state_index === 0 && (
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Fideiussore
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
              </div>
            </div>
          )}
          {state_index !== 0 && (
            <>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Decorrenza
                    </label>
                    <input
                      className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
                      max="9999-12-31"
                      type="date"
                    ></input>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Numero di fideiussioni (obbligatorio)
                    </label>
                    <input
                      type="text"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Fideiussore
                    </label>
                    <select className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white">
                      {guarantor.map((menu, index) => {
                        return (
                          <option key={index} value={index}>
                            {menu}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="label-heading mb-3 d-block w-100 mt-4">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase ">GARANZIA</h1>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Tipo di garanzia
                </label>
                <select className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white">
                  {guarantee_type.map((menu, index) => {
                    return (
                      <option key={index} value={index}>
                        {menu}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-row align-items-end">
                <div className="d-flex flex-column flex-auto">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Importo
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
                <BsPlusCircleFill
                  className="add-section-btn mb-1"
                  onClick={addWarranty}
                />
              </div>
            </div>
          </div>
          {warranty.length > 0 && (
            <>
              <div className="split-line">
                <hr></hr>
              </div>
              {warranty.map((item, index) => {
                return (
                  <div>
                    <AddWarranty
                      id={index}
                      guarantee_type={guarantee_type}
                      deleteWarranty={deleteWarranty}
                      item={item}
                    />
                  </div>
                );
              })}
            </>
          )}

          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Totale
                </label>
                <input
                  type="text"
                  className="green-input border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="label-heading mb-3 d-block w-100 mt-4">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase ">NOTE</h1>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Note
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-end mt-2 form-last-btns">
              <button
                type="button"
                className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
                onClick={() => {
                  props.setShow(false);
                }}
              >
                Annulla
              </button>
              <button
                type="button"
                className="next-btn btn-last rounded-3 shadow w-193 border-0"
              >
                Salva
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
