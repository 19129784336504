const translationMappingAssicurazioni = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    type: "Tipo",
    name: "Nome",
    company: "Compagnia",
    agency: "Agenzia",
    billType: "Tipo di polizza",
    billNumber: "Polizza numero",
    reward: "Premio",
    DATE: "DATE",
    originalStart: "Inizio originale",
    withdrawalYears: "Recesso in anni",
    deadlineReward: "Scadenza premio",
    deadlineBill: "Scadenza polizza",
    lastPayment: "Ultimo pagamento",
    CONTRAENTI: "CONTRAENTI",
    nominative: "Nominativi",
    ["CAPITALI ASSICURATIVI"]: "CAPITALI ASSICURATIVI",
    coverage1:  "Copertura1",
    coverage2:  "Copertura2",
    coverage3: "Copertura3",
    massimal1: "Massimale1",
    massimal1: "Massimale2",
    massimal3: "Massimale3",
    bill1: "Polizza 1",
    bill2: "Polizza 2",
    bill3: "Polizza 3",
    NOTE: "NOTE",
    note: "Note",
    attachment: "Allegati",
};

export default translationMappingAssicurazioni
