import { useState, useEffect } from "react";
import NumericInput from "react-numeric-input";

import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../CustomToggle";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"

import Slider from '@mui/material/Slider';

import CustomTooltip from "../../CustomTooltip"

import { appartamentiMqCoperti } from "../../Impostazioni/Coefficienti/Appartamenti";

import Appartamenti from "./Feature/Appartamenti";
import Ville from "./Feature/Ville";
import Uffici from "./Feature/Uffici";
import Negozi from "./Feature/Negozi";
import Box from "./Feature/Box";
import PostoAuto from "./Feature/PostoAuto";
import Laboratori from "./Feature/Laboratori";
import Magazzini from "./Feature/Magazzini";
import Capannoni from "./Feature/Capannoni";

const Feature = (props) => {
  const [typologyState, setTypologyState] = useState(0);

  useEffect(() => {
    var text = props.formData.typology;
    if ( text.includes("Ville")) {
      setTypologyState(0);
    }
     else if (
      text.includes("Appartamenti")
    ) {
      setTypologyState(1);
    }  
    else if (text.includes("Uffici")) {
      setTypologyState(2);
    } else if (text.includes("Negozi")) {
      setTypologyState(3);
    } else if (text.includes("Box e autorimesse")) {
      setTypologyState(4);
    } else if (text.includes("Posti auto")) {
      setTypologyState(5);
    } else if (text.includes("Capannoni")) {
      setTypologyState(6);
    } else if (text.includes("Magazzini")) {
      setTypologyState(7);
    } else if (text.includes("Laboratori")) {
      setTypologyState(8);
    }
  }, []);



  const setWineryGarretMq = (value) => {
    var val;
    switch (value) {
      case "piccola":
        val = 4;
        break;
      case "normale":
        val = 6;
        break;
      case "grande":
        val = 8;
        break;
      case "doppia":
        val = 12;
        break;
      case "tripla":
        val = 18;
        break;
      case "quadrupla":
        val = 24;
        break;
      case "presente":
        val = 0;
        break;
      default:
        break;
    }
    return val;
  };

  useEffect(() => {
    let val = setWineryGarretMq(props.formData.winery);

    const ele = { target: { name: "wineryMq", value: val } };
    props.onChange(ele);
  }, [props.formData.winery]);

  useEffect(() => {
    let val = setWineryGarretMq(props.formData.garret);

    const ele = { target: { name: "garretMq", value: val } };
    props.onChange(ele);
  }, [props.formData.garret]);


  useEffect(() => {
    var val;
    switch (props.formData.box) {
      case "molto piccolo":
        val = 12;
        break;
      case "piccolo":
        val = 14;
        break;
      case "medio":
        val = 18;
        break;
      case "grande":
        val = 24;
        break;
      case "doppio":
        val = 32;
        break;
      case "triplo":
        val = 40;
        break;
      case "quadruplo":
        val = 50;
        break;
      case "presente":
        val = 0;
        break;
      default:
        break;
    }
    const ele = { target: { name: "boxMq", value: val } };
    props.onChange(ele);
  }, [props.formData.box]);


  //HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

   //HANDLE BOX VIEW
  const [boxSelected, setBoxSelected] = useState({
    box: false,
    pac: false,
    pas: false,
    pacondo: false
  })

  function handleSelectedBox(e){
    const {name, checked} = e.target

    setBoxSelected(prev => {
      return {
        ...prev,
        [name]: checked
      }
    })
  }



  //HANDLE ROSA DEI VENTI
  //NEED TO BE OPTIMIZED - MOVE SVG IN A COMPONENT WHICH TAKES cardinalPoint and function

  const [cardinalPoint, setCardinalPoint] = useState(null)

  function handleCardinalPoint(point){
    setCardinalPoint(point)
  }

  const [cardinalPoint2, setCardinalPoint2] = useState(null)

  function handleCardinalPoint2(point){
    setCardinalPoint2(point)
  }

  const [cardinalPoint3, setCardinalPoint3] = useState(null)

  function handleCardinalPoint3(point){
    setCardinalPoint3(point)
  }

  const [cardinalPoint4, setCardinalPoint4] = useState(null)

  function handleCardinalPoint4(point){
    setCardinalPoint4(point)
  }

  return (
    <div className="form-item-align-box d-block">
      <Form>
        <Accordion defaultActiveKey={['2']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
          
      
      {typologyState === 0 && (
        <>
          <Ville
              coeffObj={props.coeffObj} 
              handleCoeffObj={props.handleCoeffObj}
              onChange={props.onChange} 
              formData={props.formData}
              activeKey={activeKey}
              boxSelected={boxSelected}
              handleSelectedBox={handleSelectedBox}
              handleCardinalPoint={handleCardinalPoint}
              handleCardinalPoint2={handleCardinalPoint2}
              handleCardinalPoint3={handleCardinalPoint3}
              handleCardinalPoint4={handleCardinalPoint4}
              cardinalPoint={cardinalPoint}
              cardinalPoint2={cardinalPoint2}
              cardinalPoint3={cardinalPoint3}
              cardinalPoint4={cardinalPoint4}
              handleValueChange={props.handleValueChange}
              handleCoeffChange={props.handleCoeffChange}
              formValues={props.formValues}
          />
        </>
      )}

    {typologyState === 1 && (
        <>
          <Appartamenti 
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            boxSelected={boxSelected}
            handleSelectedBox={handleSelectedBox}
            handleCardinalPoint={handleCardinalPoint}
            handleCardinalPoint2={handleCardinalPoint2}
            handleCardinalPoint3={handleCardinalPoint3}
            handleCardinalPoint4={handleCardinalPoint4}
            cardinalPoint={cardinalPoint}
            cardinalPoint2={cardinalPoint2}
            cardinalPoint3={cardinalPoint3}
            cardinalPoint4={cardinalPoint4}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
      )}

    {typologyState === 2 && (
        <>
          <Uffici 
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}

    {typologyState === 3 && (
        <>
          <Negozi
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}

    {typologyState === 4 && (
        <>
          <Box
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}

    {typologyState === 5 && (
        <>
        {/* x2 */}
          <PostoAuto
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}

    {typologyState === 6 && (
        <>
                {/* x2 */}

          <Capannoni
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}

    {typologyState === 7 && (
        <>
          <Magazzini
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}

    {typologyState === 8 && (
        <>
          <Laboratori
            coeffObj={props.coeffObj} 
            handleCoeffObj={props.handleCoeffObj}
            onChange={props.onChange} 
            formData={props.formData}
            activeKey={activeKey}
            handleValueChange={props.handleValueChange}
            handleCoeffChange={props.handleCoeffChange}
            formValues={props.formValues}
          />
        </>
    )}
      </Accordion>
      </Form>
    </div>
  );
};

export default Feature;
