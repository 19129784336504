import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"





import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import Slider from '@mui/material/Slider';
import { current } from "@reduxjs/toolkit";

import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import translations from "./reportTranslationMapping";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";


const defaultValues = {
    name:"",
    default:false,
    statoLocativo: {
        abitazioniLibere: 100,
        abitazioniLocateLungoPeriodo: -20,
        abitazioniLocateBrevePeriodo: -5
    },
    mqCopertiLordi: 100,//
    mqSeminterrati: 60,
    mqVerandeRifinite: 75,
    mqVerandeNonRifinite: 50,
    mqSoppalchi: 80,
    mqMansardati: 75,
    mqTerrazzi: 35,
    mqGiardiniCortili: 15,
    mqBalconi:25,
    mqLoggiePatii:35,
    mqLastricoSolare:25,
    cantina:30,
    soffitta:30,
    pianoConAscensore:{
        seminterrato: -25,
        pianoTerraRialzatoConGiardino: -10,
        pianoTerraRialzatoSenzaGiardino: -20,  
        piano1: -10,
        piano2: -3,
        piano3: 0,
        pianoSuperiori: 5,
        ultimoPiano: 10,
        attico: 20
    },
    pianoSenzaAscensore:{
        seminterrato: -25,
        pianoTerraRialzatoConGiardino: -10,
        pianoTerraRialzatoSenzaGiardino: -20,  
        piano1: -10,
        piano2: -15,
        piano3: -20,
        pianoSuperiori: -30,
        ultimoPiano: -30,
        attico: -25
    },
    boxAuto: 50, //
    postoAutoCoperto: 35,
    postoAutoScoperto:20, 
    postoAutoCondominiale:50, //
    tipoPossesso: {
        proprietà: 100,
        comodato: 50
    }, //
    tipologiaVista: {
        esternaPanoramica: 10,
        esterna: 5,
        mista: 0,
        interna: -5,
        completamenteInterna: -10

    },
    luminosità: {
        moltoLuminoso: 10,
        luminoso: 5,
        mediamenteLuminoso: 0,
        pocoLuminoso: -5
    },
    statoConservazioneInterno: {
        daRistrutturare: -10,
        buonoStato: 0,
        ristrutturato: 5,
        finementeRistrutturato: 10,
        nuovaCostruzione: 15
    },
    classeEnergetica:50, //
    riscaldamento: {
        autonomo: 5,
        centralizzato: 0,
        assente: -5,
        centralizzatoContabilizzatore: 2
    }, //
    etàEdificio: {
        da1A20:{
            ottimoStato:0,
            normale: 0,
            scadente: -5
        },
        da20A40:{
            ottimoStato:5,
            normale: 0,
            scadente: -10
        },
        oltre40:{
            ottimoStato:10,
            normale: 0,
            scadente: -15
        },
    }
}


export default function Valutazione(){

    const coefficientiStandard = {
        ...defaultValues,
        name: "CoefficientiStandard",
        default: true
    }

    const [listCoeff, setListCoeff] = useState([coefficientiStandard])

    const [newCoeff, setNewCoeff] = useState(defaultValues)

    const [modalCreation, setModalCreation] = useState(false)

    function showModalCreation(){
        setModalCreation(true)
    }

    function closeModalCreation(){
        setModalCreation(false)
        setNewCoeff(defaultValues)
    }

    function handleListCoeff(e){
        e.preventDefault()

        setListCoeff(prev => {
            return [...prev, newCoeff]
        })
        closeModalCreation()
        setNewCoeff(defaultValues)
    }


    //handle modal PREDEFINITO
    //handle modal view/edit/delete/print coefficiente
    //real value for each coeffciiente

    const [modalDefault, setModalDefault] = useState(false)

    function showModalDefault(){
        setModalDefault(true)
    }

    function closeModalDefault(){
        setModalDefault(false)
    }


    //HANDLE NEW COEFFICIENTE

    function handleNewCoeff (e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function handleStatoLocativo(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                statoLocativo:{
                    ...prev.statoLocativo,
                    [name]: value
                }
            }
        })
    }

    function handlePianoConAscensore(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                pianoConAscensore:{
                    ...prev.pianoConAscensore,
                    [name]: value
                }
            }
        })
    }

    function handlePianoSenzaAscensore(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                pianoSenzaAscensore:{
                    ...prev.pianoSenzaAscensore,
                    [name]: value
                }
            }
        })
    }

    function handlePossessoType(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                tipoPossesso:{
                    ...prev.tipoPossesso,
                    [name]: value
                }
            }
        })
    }

    function handleBrightness(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                luminosità:{
                    ...prev.luminosità,
                    [name]: value
                }
            }
        })
    }

    function handleConservativeState(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                statoConservazioneInterno:{
                    ...prev.statoConservazioneInterno,
                    [name]: value
                }
            }
        })
    }

    function handleHeater(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                riscaldamento:{
                    ...prev.riscaldamento,
                    [name]: value
                }
            }
        })
    }

    function handleEsposition(e){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                tipologiaVista:{
                    ...prev.tipologiaVista,
                    [name]: value
                }
            }
        })
    }

    function handleBuildingAge(e, age){
        const {name, value} = e.target
        setNewCoeff(prev => {
            return {
                ...prev,
                etàEdificio:{
                    ...prev.etàEdificio,
                    [age]: {
                        ...prev.etàEdificio[age],
                        [name]: value
                    }
                }
            }
        })
    }


    /*  DEFAULT COEFFICIENTI */

    const [defaultCoefficientiId, setDefaultCoefficienteId] = useState(0)

    function handleDefaultCoefficienti (e){
        setDefaultCoefficienteId(e.target.value)
    }

    function updateDefault(){
        const newArr = listCoeff.map((item, index) => {
            return index == defaultCoefficientiId?
            {...item, default: true}:
            {...item, default: false}
        })

        setListCoeff(newArr)

        closeModalDefault()
    }


    /* DELETE COEFFICIENTE PACK */

    function deleteCoeff(e, id){
        e.stopPropagation()
        const newArr = listCoeff.filter((item, index) =>  index != id
        )

        setListCoeff(newArr)
    }


    /* VIEW EDIT MODAL */
    const [modalView, setModalView] = useState(false)

    function showModalView(){
        setModalView(true)
    }

    function closeModalView(){
        setModalView(false)
        setCurrentView(null)
        setCurrentViewId(null)
    }

    const [currentView, setCurrentView] = useState(null)
    const [currentViewId, setCurrentViewId] = useState(null)

    function handleCurrent(e, id){
        e.stopPropagation()
        const item = listCoeff.find((item, index) => index == id)
        setCurrentView(item)
        setCurrentViewId(id)
        showModalView()
    }

    function handleSaveEdit(e){

        e.preventDefault() 

        const newArr = listCoeff.map((item, index) => {
            return index == currentViewId?
            currentView:
            item
        })

        setListCoeff(newArr)
        closeModalView()
    }

    //HANDLE EDIT

    function handleEditCoeff (e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function handleEditStatoLocativo(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                statoLocativo:{
                    ...prev.statoLocativo,
                    [name]: value
                }
            }
        })
    }

    function handleEditPianoConAscensore(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                pianoConAscensore:{
                    ...prev.pianoConAscensore,
                    [name]: value
                }
            }
        })
    }

    function handleEditPianoSenzaAscensore(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                pianoSenzaAscensore:{
                    ...prev.pianoSenzaAscensore,
                    [name]: value
                }
            }
        })
    }

    function handleEditPossessoType(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                tipoPossesso:{
                    ...prev.tipoPossesso,
                    [name]: value
                }
            }
        })
    }

    function handleEditBrightness(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                luminosità:{
                    ...prev.luminosità,
                    [name]: value
                }
            }
        })
    }

    function handleEditConservativeState(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                statoConservazioneInterno:{
                    ...prev.statoConservazioneInterno,
                    [name]: value
                }
            }
        })
    }

    function handleEditHeater(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                riscaldamento:{
                    ...prev.riscaldamento,
                    [name]: value
                }
            }
        })
    }

    function handleEditBuildingAge(e, age){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                etàEdificio:{
                    ...prev.etàEdificio,
                    [age]: {
                        ...prev.etàEdificio[age],
                        [name]: value
                    }
                }
            }
        })
    }

    function handleEditEsposition(e){
        const {name, value} = e.target
        setCurrentView(prev => {
            return {
                ...prev,
                tipologiaVista:{
                    ...prev.tipologiaVista,
                    [name]: value
                }
            }
        })
    }

    /* NEW CUSTOM COEFFICIENTS */

    const [value, setValue] = React.useState('Appartamenti');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

   {/* Da ricontrollare: deve essere come initialCoefficients in CreateEvaluation.js */}
  const[ coefficientsObject, setCoefficientsObject] = useState({
    'Appartamenti': {
        grossCoveredMq: 10,
        basementMq: 5,
        finishingVerandaMq: 6,
        notFinishingVerandaMq: 7,
        mezzanineMq:8,
        canopyMq: 9,
        balconyMq: 10,
        terraceMq: 11,
        gardenMq: 12,
        solarPavementMq:15,
        lodgeMq:12,
        otherUncoveredMq: 13,
        winery:14,
        garret:15,
        box: 16,
        coveredParkingSpace: 17,
        uncoveredParkingSpace: 18,
        condominialParkingSpace: 19,
        floorWithLift: {
/*             undergroundFloor: 1,
 */            basementFloor: 2,
            groundFloor: 3,
            mezzanineFloor: 4,
            '1Floor': 5,
            '2Floor': 6,
            '3Floor': 7,
            '4Floor': 8,
            '5Floor': 9,
            '6Floor': 10,
            '7Floor': 11,
            '8Floor': 12,
            '9Floor': 13,
            '10Floor': 14,
            'beyond10Floor': 15,
            'topFloor': 16,
            penthouse: 17
        },
        floorWithoutLift: {
/*             undergroundFloor: 20,
 */            basementFloor: 21,
            groundFloor: 22,
            mezzanineFloor: 23,
            '1Floor': 24,
            '2Floor': 25,
            '3Floor': 26,
            '4Floor': 27,
            '5Floor': 28,
            '6Floor': 29,
            '7Floor': 30,
            '8Floor': 31,
            '9Floor': 32,
            '10Floor': 33,
            'beyond10Floor': 34,
            'topFloor': 35,
            penthouse: 36
        },
        ageStateBuilding:{
            "years1To5ToRenovateTotallyBuilding": 115,
            "years1To5ToRenovatePartiallyBuilding": 114,
            "years1To5HabitableBuilding": 113,
            "years1To5ExcellentBuilding": 112,
            "years1To5NewConstructionBuilding": 111,
            "years5To20ToRenovateTotallyBuilding": 125,
            "years5To20ToRenovatePartiallyBuilding": 124,
            "years5To20HabitableBuilding": 123,
            "years5To20ExcellentBuilding": 122,
            "years5To20NewConstructionBuilding": 121,
            "years20To40ToRenovateTotallyBuilding": 135,
            "years20To40ToRenovatePartiallyBuilding": 134,
            "years20To40HabitableBuilding": 133,
            "years20To40ExcellentBuilding": 132,
            "years20To40NewConstructionBuilding": 131,
            "over40YearsToRenovateTotallyBuilding": 145,
            "over40YearsToRenovatePartiallyBuilding": 144,
            "over40YearsHabitableBuilding": 143,
            "over40YearsExcellentBuilding": 142,
            "over40YearsNewConstructionBuilding": 141,
        },
        boxAccessibility: {
            veryDifficultBoxAccessibility: -0.1,
            difficultBoxAccessibility: -0.5,
            uncomfortableBoxAccessibility: -0.3,
            normalBoxAccessibility: 0.0,
            comfortableBoxAccessibility: 0.05
        },
        boxLocation: {
            inTheSameBoxLocation: 1,
            nearBoxLocation: 2,
            farBoxLocation: 3,
            veryfarBoxLocation: 4
        },
       /*  boxPossessionType: {
            propertyBoxPossession: 0.5,
            concessionBoxPossession: 0.3
        }, */
        coveredParkingAccessibility: {
            veryDifficultCoveredParkingAccessibility: -0.1,
            difficultCoveredParkingAccessibility: -0.5,
            uncomfortableCoveredParkingAccessibility: -0.3,
            normalCoveredParkingAccessibility: 0.0,
            comfortableCoveredParkingAccessibility: 0.05,
          },
          coveredParkingLocation: {
            inTheSameCoveredParkingLocation: 1,
            nearCoveredParkingLocation: 2,
            farCoveredParkingLocation: 3,
            veryfarCoveredParkingLocation: 4
          },
          /* coveredParkingPossessionType: {
            propertyCoveredParkingPossession: 0.5,
            concessionCoveredParkingPossession: 0.3
          }, */
          uncoveredParkingAccessibility: {
            veryDifficultUncoveredParkingAccessibility: -0.1,
            difficultUncoveredParkingAccessibility: -0.5,
            uncomfortableUncoveredParkingAccessibility: -0.3,
            normalUncoveredParkingAccessibility: 0.0,
            comfortableUncoveredParkingAccessibility: 0.05,
          },
          uncoveredParkingLocation: {
            inTheSameUncoveredParkingLocation: 1,
            nearUncoveredParkingLocation: 2,
            farUncoveredParkingLocation: 3,
            veryfarUncoveredParkingLocation: 4
          },
          /* uncoveredParkingPossessionType: {
            propertyUncoveredParkingPossession: 0.5,
            concessionUncoveredParkingPossession: 0.3
          }, */
         /*  condominialParkingPossessionType: {
            propertyCondominialParkingPossession: 0.5,
            concessionCondominialParkingPossession: 0.3
          }, */
          mainView: {
            externalPanoramicView: 1.0,
            externalView: 0.8,
            mixedView: 0.5,
            internalView: 0.2,
            fullInternalView: 0.1
          },
          brightness: {
            bright: 0.05,
            veryBright: 0.1,
            mediumBright: 0.0,
            lowBright: -0.05
          },
          noisiness: {
            normalNoise: 0.0,
            veryHighNoise: -0.2,
            highNoise: -0.1,
            lowNoise: 0.05,
            veryLowNoise: 0.1,
          },
          internalDistribution: {
            veryEfficientDistribution: 0.1,
            efficientDistribution: 0.05,
            lowEfficientDistribution: 0.0,
            notEfficientDistribution: -0.05,
        }, 
        ceilingHeight: {
            mediumCeilingHeight: 0.0,
            inferiorCeilingHeight: -0.05,
            superiorCeilingHeight: 0.05
        },
        internalState: {
            totallyRenovated: 0.2,
            partiallyRenovated: 0.1,
            habitable: 0.0,
            renovated: 0.05,
            finelyRenovated: 0.1,
            newConstruction: 0.15,
        },
        finishingQuality: {
            standardFinish: 0.0,
            inexpensiveFinish: -0.1,
            excellentFinish: 0.05,
            premiumFinish: 0.1,
        },
        energeticClass: {
            a1: 0.2,
            a2: 0.15,
            a3: 0.1,
            a4: 0.05,
            b: 0.0,
            c: -0.05,
            d: -0.1,
            e: -0.15,
            f: -0.2,
            g: -0.25,
        },
        heater: {
            autonomousHeating: 0.1,
            centralizedHeating: 0.2,
            centralizedMeteringHeating: 0.3,
            absentHeating: -0.1,
        },
        /* parkingAccessibility: {
            easyParkingAccessibility: 1,
            mediumParkingAccessibility: 2,
            hardParkingAccessibility: 3,
            veryhardParkingAccessibility: 4,
        }, */
    },
    "Ville e villini": {
        grossCoveredMq: 110,
        basementMq: 115,
        finishingVerandaMq: 116,
        notFinishingVerandaMq: 117,
        mezzanineMq:118,
        canopyMq: 119,
        balconyMq: 110,
        terraceMq: 111,
        gardenMq: 112,
        solarPavementMq:115,
        lodgeMq:112,
        otherUncoveredMq: 113,
        winery:114,
        garret:115,
        box: 116,
        coveredParkingSpace: 117,
        uncoveredParkingSpace: 118,
        condominialParkingSpace: 119,
        floorWithLift: {
/*             undergroundFloor: 1,
 */            basementFloor: 2,
            groundFloor: 3,
            mezzanineFloor: 4,
            '1Floor': 5,
            '2Floor': 6,
            '3Floor': 7,
            '4Floor': 8,
            '5Floor': 9,
            '6Floor': 10,
            '7Floor': 11,
            '8Floor': 12,
            '9Floor': 13,
            '10Floor': 14,
            'beyond10Floor': 15,
            'topFloor': 16,
            penthouse: 17
        },
        floorWithoutLift: {
/*             undergroundFloor: 20,
 */            basementFloor: 21,
            groundFloor: 22,
            mezzanineFloor: 23,
            '1Floor': 24,
            '2Floor': 25,
            '3Floor': 26,
            '4Floor': 27,
            '5Floor': 28,
            '6Floor': 29,
            '7Floor': 30,
            '8Floor': 31,
            '9Floor': 32,
            '10Floor': 33,
            'beyond10Floor': 34,
            'topFloor': 35,
            penthouse: 36
        }, 
        ageStateBuilding:{
            "years1To5ToRenovateTotallyBuilding": 115,
            "years1To5ToRenovatePartiallyBuilding": 114,
            "years1To5HabitableBuilding": 113,
            "years1To5ExcellentBuilding": 112,
            "years1To5NewConstructionBuilding": 111,
            "years5To20ToRenovateTotallyBuilding": 125,
            "years5To20ToRenovatePartiallyBuilding": 124,
            "years5To20HabitableBuilding": 123,
            "years5To20ExcellentBuilding": 122,
            "years5To20NewConstructionBuilding": 121,
            "years20To40ToRenovateTotallyBuilding": 135,
            "years20To40ToRenovatePartiallyBuilding": 134,
            "years20To40HabitableBuilding": 133,
            "years20To40ExcellentBuilding": 132,
            "years20To40NewConstructionBuilding": 131,
            "over40YearsToRenovateTotallyBuilding": 145,
            "over40YearsToRenovatePartiallyBuilding": 144,
            "over40YearsHabitableBuilding": 143,
            "over40YearsExcellentBuilding": 142,
            "over40YearsNewConstructionBuilding": 141,
        },
        boxAccessibility: {
            veryDifficultBoxAccessibility: 111,
            difficultBoxAccessibility: 115,
            uncomfortableBoxAccessibility: 113,
            normalBoxAccessibility: 111,
            comfortableBoxAccessibility: 111
        },
        boxLocation: {
            inTheSameBoxLocation: 111,
            nearBoxLocation: 113,
            farBoxLocation: 112,
            veryfarBoxLocation: 114
        },
        /* boxPossessionType: {
            propertyBoxPossession: 0.5,
            concessionBoxPossession: 0.3
        }, */
        coveredParkingAccessibility: {
            veryDifficultCoveredParkingAccessibility: -0.1,
            difficultCoveredParkingAccessibility: -0.5,
            uncomfortableCoveredParkingAccessibility: -0.3,
            normalCoveredParkingAccessibility: 0.0,
            comfortableCoveredParkingAccessibility: 0.05,
          },
          coveredParkingLocation: {
            inTheSameCoveredParkingLocation: 1,
            nearCoveredParkingLocation: 2,
            farCoveredParkingLocation: 3,
            veryfarCoveredParkingLocation: 4
          },
          /* coveredParkingPossessionType: {
            propertyCoveredParkingPossession: 0.5,
            concessionCoveredParkingPossession: 0.3
          }, */
          uncoveredParkingAccessibility: {
            veryDifficultUncoveredParkingAccessibility: -0.1,
            difficultUncoveredParkingAccessibility: -0.5,
            uncomfortableUncoveredParkingAccessibility: -0.3,
            normalUncoveredParkingAccessibility: 0.0,
            comfortableUncoveredParkingAccessibility: 0.05,
          },
          uncoveredParkingLocation: {
            inTheSameUncoveredParkingLocation: 1,
            nearUncoveredParkingLocation: 2,
            farUncoveredParkingLocation: 3,
            veryfarUncoveredParkingLocation: 4
          },
         /*  uncoveredParkingPossessionType: {
            propertyUncoveredParkingPossession: 0.5,
            concessionUncoveredParkingPossession: 0.3
          },
          condominialParkingPossessionType: {
            propertyCondominialParkingPossession: 0.5,
            concessionCondominialParkingPossession: 0.3
          }, */
          mainView: {
            externalPanoramicView: 1.0,
            externalView: 0.8,
            mixedView: 0.5,
            internalViewA: 0.2,
            fullInternalView: 0.1
          },
          brightness: {
            bright: 0.05,
            veryBright: 0.1,
            mediumBright: 0.0,
            lowBright: -0.05
          },
          noisiness: {
            normalNoise: 0.0,
            veryHighNoise: -0.2,
            highNoise: -0.1,
            lowNoise: 0.05,
            veryLowNoise: 0.1,
          },
          internalDistribution: {
            veryEfficientDistribution: 0.1,
            efficientDistribution: 0.05,
            lowEfficientDistribution: 0.0,
            notEfficientDistribution: -0.05,
        }, 
        ceilingHeight: {
            mediumCeilingHeight: 0.0,
            inferiorCeilingHeight: -0.05,
            superiorCeilingHeight: 0.05
        },
        internalState: {
            totallyRenovated: 0.2,
            partiallyRenovated: 0.1,
            habitable: 0.0,
            renovated: 0.05,
            finelyRenovated: 0.1,
            newConstruction: 0.15,
        },
        finishingQuality: {
            standardFinish: 0.0,
            inexpensiveFinish: -0.1,
            excellentFinish: 0.05,
            premiumFinish: 0.1,
        },
        energeticClass: {
            a1: 0.2,
            a2: 0.15,
            a3: 0.1,
            a4: 0.05,
            b: 0.0,
            c: -0.05,
            d: -0.1,
            e: -0.15,
            f: -0.2,
            g: -0.25,
        },
        heater: {
            autonomousHeating: 0.1,
            centralizedHeating: 0.2,
            centralizedMeteringHeating: 0.3,
            absentHeating: -0.1,
        },
        parkingAccessibility: {
            easyParkingAccessibility: 1,
            mediumParkingAccessibility: 2,
            hardParkingAccessibility: 3,
            veryhardParkingAccessibility: 4,
        },
    },
    "Negozi": {
        grossCoveredMq: 15,
        balconyMq: 110,
        terraceMq: 111,
        gardenMq: 112,
        solarPavementMq:115,
        lodgeMq:112,
        otherUncoveredMq: 113,
        floor: {
            basementDown: 1,
            groundFloor:2,
            mezzanineFloorFrom1to3:3,
            floorAboveThe3rd:4
        },
        position: {
            particularlyFavorableToTrade:1,
            favorableToTrade:2,
            ordinary:3,
            unfavorableToTrade:4
        },
        coveredParkingSpace: 15,
        uncoveredParkingSpace:17,
        ageStateBuilding:{
            "years1To5ToRenovateTotallyBuilding": 115,
            "years1To5ToRenovatePartiallyBuilding": 114,
            "years1To5HabitableBuilding": 113,
            "years1To5ExcellentBuilding": 112,
            "years1To5NewConstructionBuilding": 111,
            "years5To20ToRenovateTotallyBuilding": 125,
            "years5To20ToRenovatePartiallyBuilding": 124,
            "years5To20HabitableBuilding": 123,
            "years5To20ExcellentBuilding": 122,
            "years5To20NewConstructionBuilding": 121,
            "years20To40ToRenovateTotallyBuilding": 135,
            "years20To40ToRenovatePartiallyBuilding": 134,
            "years20To40HabitableBuilding": 133,
            "years20To40ExcellentBuilding": 132,
            "years20To40NewConstructionBuilding": 131,
            "over40YearsToRenovateTotallyBuilding": 145,
            "over40YearsToRenovatePartiallyBuilding": 144,
            "over40YearsHabitableBuilding": 143,
            "over40YearsExcellentBuilding": 142,
            "over40YearsNewConstructionBuilding": 141,
        },
        energeticClass: {
            a1: 0.2,
            a2: 0.15,
            a3: 0.1,
            a4: 0.05,
            b: 0.0,
            c: -0.05,
            d: -0.1,
            e: -0.15,
            f: -0.2,
            g: -0.25,
        },
        heater: {
            autonomousHeating: 0.1,
            centralizedHeating: 0.2,
            centralizedMeteringHeating: 0.3,
            absentHeating: -0.1,
        },
        internalState: {
            totallyRenovated: 0.2,
            partiallyRenovated: 0.1,
            habitable: 0.0,
            renovated: 0.05,
            finelyRenovated: 0.1,
            newConstruction: 0.15,
        },
        ceilingHeight: {
            mediumCeilingHeight: 0.0,
            inferiorCeilingHeight: -0.05,
            superiorCeilingHeight: 0.05
        },
        
    },
    "Uffici": {
        grossCoveredMq: 15,
        grossCoveredMq: 110,
        balconyMq: 110,
        terraceMq: 111,
        gardenMq: 112,
        solarPavementMq:115,
        lodgeMq:112,
        otherUncoveredMq: 113,
        winery:114,
        garret:115,
        box: 116,
        coveredParkingSpace: 117,
        uncoveredParkingSpace: 118,
        ageStateBuilding:{
            "years1To5ToRenovateTotallyBuilding": 115,
            "years1To5ToRenovatePartiallyBuilding": 114,
            "years1To5HabitableBuilding": 113,
            "years1To5ExcellentBuilding": 112,
            "years1To5NewConstructionBuilding": 111,
            "years5To20ToRenovateTotallyBuilding": 125,
            "years5To20ToRenovatePartiallyBuilding": 124,
            "years5To20HabitableBuilding": 123,
            "years5To20TExcellentBuilding": 122,
            "years5To20TNewConstructionBuilding": 121,
            "years20To40ToRenovateTotallyBuilding": 135,
            "years20To40ToRenovatePartiallyBuilding": 134,
            "years20To40HabitableBuilding": 133,
            "years20To40ExcellentBuilding": 132,
            "years20To40NewConstructionBuilding": 131,
            "over40YearsToRenovateTotallyBuilding": 145,
            "over40YearsToRenovatePartiallyBuilding": 144,
            "over40YearsHabitableBuilding": 143,
            "over40YearsExcellentBuilding": 142,
            "over40YearsNewConstructionBuilding": 141,
        },
        position: {
            centralPosition: 0.15,
            intermediatePosition: 0.0,
            secondaryPosition: -0.1,
        },
        internalState: {
            totallyRenovated: 0.2,
            partiallyRenovated: 0.1,
            habitable: 0.0,
            renovated: 0.05,
            finelyRenovated: 0.1,
            newConstruction: 0.15,
        },
        energeticClass: {
            a1: 0.2,
            a2: 0.15,
            a3: 0.1,
            a4: 0.05,
            b: 0.0,
            c: -0.05,
            d: -0.1,
            e: -0.15,
            f: -0.2,
            g: -0.25,
        },
        heater: {
            autonomousHeating: 0.1,
            centralizedHeating: 0.2,
            centralizedMeteringHeating: 0.3,
            absentHeating: -0.1,
        },
        parkingAccessibility: {
            easyParkingAccessibility: 1,
            mediumParkingAccessibility: 2,
            hardParkingAccessibility: 3,
            veryhardParkingAccessibility: 4,
        },
        withLift: {
/*             undergroundFloor: 111,
 */            basementFloor: 112,
            groundFloor: 113,
            mezzanineFloor: 114,
            '1Floor': 115,
            '2Floor': 116,
            '3Floor': 117,
            '4Floor': 118,
            '5Floor': 119,
            '6Floor': 110,
            '7Floor': 111,
            '8Floor': 112,
            '9Floor': 113,
            '10Floor': 114,
            'beyond10Floor': 115,
            'topFloor': 116,
            penthouse: 117
        },
        withoutLift: {
/*             undergroundFloor: 120,
 */            basementFloor: 21,
            groundFloor: 122,
            mezzanineFloor: 123,
            '1Floor': 124,
            '2Floor': 125,
            '3Floor': 126,
            '4Floor': 127,
            '5Floor': 128,
            '6Floor': 129,
            '7Floor': 130,
            '8Floor': 131,
            '9Floor': 132,
            '10Floor': 133,
            'beyond10Floor': 134,
            'topFloor': 135,
            penthouse: 136
        }
    },
    "Box e autorimesse": {
        grossCoveredMq: 25,
        parkingAccessibility: {
            easyParkingAccessibility: 1,
            mediumParkingAccessibility: 2,
            hardParkingAccessibility: 3,
            veryhardParkingAccessibility: 4,
        },
        garage: {
            outsideTheGarage:1,
            insideGarage:2
        },
        height: {
            averagHeight:1,
            avarageAbove:2
        }
    },
    "Posti auto coperti": {
        grossCoveredMq: 26,
        parkingAccessibility: {
            easyParkingAccessibility: 1,
            mediumParkingAccessibility: 2,
            hardParkingAccessibility: 3,
            veryhardParkingAccessibility: 4,
        },
    },
    "Posti auto scoperti": {
        grossCoveredMq: 26,
        parkingAccessibility: {
            easyParkingAccessibility: 1,
            mediumParkingAccessibility: 2,
            hardParkingAccessibility: 3,
            veryhardParkingAccessibility: 4,
        },
    },
    Laboratori: {
      grossCoveredMq: 27,
      floor: {
        groundFloorWithAccess: 1,
        groundFloorWithoutAccess: 2,
        basementWithAccess: 3,
        basementWithoutAccess: 4
      },
      ageStateBuilding: {
        years1To5ToRenovateTotallyBuilding: 115,
        years1To5ToRenovatePartiallyBuilding: 114,
        years1To5HabitableBuilding: 113,
        years1To5ExcellentBuilding: 112,
        years1To5NewConstructionBuilding: 111,
        years5To20ToRenovateTotallyBuilding: 125,
        years5To20ToRenovatePartiallyBuilding: 124,
        years5To20HabitableBuilding: 123,
        years5To20ExcellentBuilding: 122,
        years5To20NewConstructionBuilding: 121,
        years20To40ToRenovateTotallyBuilding: 135,
        years20To40ToRenovatePartiallyBuilding: 134,
        years20To40HabitableBuilding: 133,
        years20To40ExcellentBuilding: 132,
        years20To40NewConstructionBuilding: 131,
        over40YearsToRenovateTotallyBuilding: 145,
        over40YearsToRenovatePartiallyBuilding: 144,
        over40YearsHabitableBuilding: 143,
        over40YearsExcellentBuilding: 142,
        over40YearsNewConstructionBuilding: 141,
      },
    },
    Magazzini: {
      grossCoveredMq: 28,
      floor: {
        groundFloorWithAccess: 1,
        groundFloorWithoutAccess: 2,
        basementWithAccess: 3,
        basementWithoutAccess: 4
      },
      ageStateBuilding: {
        years1To5ToRenovateTotallyBuilding: 115,
        years1To5ToRenovatePartiallyBuilding: 114,
        years1To5HabitableBuilding: 113,
        years1To5ExcellentBuilding: 112,
        years1To5NewConstructionBuilding: 111,
        years5To20ToRenovateTotallyBuilding: 125,
        years5To20ToRenovatePartiallyBuilding: 124,
        years5To20HabitableBuilding: 123,
        years5To20ExcellentBuilding: 122,
        years5To20NewConstructionBuilding: 121,
        years20To40ToRenovateTotallyBuilding: 135,
        years20To40ToRenovatePartiallyBuilding: 134,
        years20To40HabitableBuilding: 133,
        years20To40ExcellentBuilding: 132,
        years20To40NewConstructionBuilding: 131,
        over40YearsToRenovateTotallyBuilding: 145,
        over40YearsToRenovatePartiallyBuilding: 144,
        over40YearsHabitableBuilding: 143,
        over40YearsExcellentBuilding: 142,
        over40YearsNewConstructionBuilding: 141,
      },
    },
    'Capannoni tipici': {
      grossCoveredMq: 28,
      floor: {
        groundFloor: 1,
        mezzanine: 2
      },
      access: {
        articulatedVehicleAccess: 3,
        withoutArticulatedVehicleAccess: 4,
      },
      ageStateBuilding: {
        years1To5ToRenovateTotallyBuilding: 115,
        years1To5ToRenovatePartiallyBuilding: 114,
        years1To5HabitableBuilding: 113,
        years1To5ExcellentBuilding: 112,
        years1To5NewConstructionBuilding: 111,
        years5To20ToRenovateTotallyBuilding: 125,
        years5To20ToRenovatePartiallyBuilding: 124,
        years5To20HabitableBuilding: 123,
        years5To20ExcellentBuilding: 122,
        years5To20NewConstructionBuilding: 121,
        years20To40ToRenovateTotallyBuilding: 135,
        years20To40ToRenovatePartiallyBuilding: 134,
        years20To40HabitableBuilding: 133,
        years20To40ExcellentBuilding: 132,
        years20To40NewConstructionBuilding: 131,
        over40YearsToRenovateTotallyBuilding: 145,
        over40YearsToRenovatePartiallyBuilding: 144,
        over40YearsHabitableBuilding: 143,
        over40YearsExcellentBuilding: 142,
        over40YearsNewConstructionBuilding: 141,
      },
    },
    "Capannoni industriali": {
        grossCoveredMq: 28,
        floor: {
            groundFloor: 1,
            mezzanine:2
        },
        access: {
            articulatedVehicleAccess: 3,
            withoutArticulatedVehicleAccess:4,
        },
        ageStateBuilding:{
            "years1To5ToRenovateTotallyBuilding": 115,
            "years1To5ToRenovatePartiallyBuilding": 114,
            "years1To5HabitableBuilding": 113,
            "years1To5ExcellentBuilding": 112,
            "years1To5NewConstructionBuilding": 111,
            "years5To20ToRenovateTotallyBuilding": 125,
            "years5To20ToRenovatePartiallyBuilding": 124,
            "years5To20HabitableBuilding": 123,
            "years5To20ExcellentBuilding": 122,
            "years5To20NewConstructionBuilding": 121,
            "years20To40ToRenovateTotallyBuilding": 135,
            "years20To40ToRenovatePartiallyBuilding": 134,
            "years20To40HabitableBuilding": 133,
            "years20To40ExcellentBuilding": 132,
            "years20To40NewConstructionBuilding": 131,
            "over40YearsToRenovateTotallyBuilding": 145,
            "over40YearsToRenovatePartiallyBuilding": 144,
            "over40YearsHabitableBuilding": 143,
            "over40YearsExcellentBuilding": 142,
            "over40YearsNewConstructionBuilding": 141,
        },

    }
  })

  const getTranslationKey = (key) => {
    // Lista di termini da rimuovere
    const termsToRemove = [
        "Laboratories", "Store", "Sheds", "IndustrialSheds",
        "UncoveredParking", "CoveredParking", "Shops",
        "Office", "Villa", "Apartment"
    ];

    // Rimuovi ciascun termine solo se è alla fine della frase e la prima lettera è maiuscola
    termsToRemove.forEach(term => {
        const regex = new RegExp(`\\b${term}\\b`, 'i'); // Match esatta ignorando maiuscole/minuscole
        if (regex.test(key)) { // Verifica se il termine è presente nella chiave
            const lastIndex = key.lastIndexOf(term); // Trova l'ultimo indice del termine nella chiave
            if (lastIndex !== -1 && lastIndex === key.length - term.length && key[lastIndex - 1] === key[lastIndex - 1].toUpperCase()) { // Controlla se il termine è alla fine e la lettera precedente è maiuscola
                key = key.slice(0, lastIndex); // Rimuove il termine dalla chiave
            }
        }
    });

    return key;
};


    const handleCoefficientChange = (key, value) => {
        setCoefficientsObject((prevCoefficients) => {
          // Trova la categoria e la proprietà corrispondente al coefficiente modificato
          const [category, property, subProperty] = key.split('.');
      
          // Crea una nuova copia dello stato per evitare mutazioni
          const updatedCoefficients = { ...prevCoefficients };
      
          if (subProperty) {
            // Aggiorna il sottoproprietà del coefficiente
            updatedCoefficients[category][property][subProperty] = value;
          } else {
            // Aggiorna la proprietà del coefficiente
            updatedCoefficients[category][property] = value;
          }
      
          return updatedCoefficients;
        });
      };

    
      const renderCategory = (categoryKey) => {
        const category = coefficientsObject[categoryKey];
        return (
          <div key={categoryKey} className="mt-3">
            {Object.keys(category).map((propertyKey) => {
              if (typeof category[propertyKey] === 'object') {
                return (
                  <div key={propertyKey}>
                    <small>{translations[propertyKey]}</small>
                    {Object.keys(category[propertyKey]).map((subPropertyKey) => {
                      const translationKey = getTranslationKey(subPropertyKey);
                      const keyWithCategory = `${categoryKey}.${propertyKey}.${subPropertyKey}`;
                      return (
                        <Col className="mb-2" key={subPropertyKey}>
                          <div className="valSettingsDiv text-start">
                            <div className="d-md-flex justify-content-md-between align-items-md-center">
                              <Col md={6}>
                                <p className="fs-16 fw-bold">{capitalizeFirstLetter(translations[translationKey])}</p>
                              </Col>
                              <Col md={5}>
                              {/* TO DO: DEFINIRE I RANGE CORRETTI PER OGNI PROPRIETà COME DEFINITO
                              https://docs.google.com/spreadsheets/d/1IFzvJeVwt_mTJZn20gfLoHeSV_lGJF8s5gYnBAOoHJo/edit?gid=558017178#gid=558017178
                              */}

                                <Slider
                                  min={-50}
                                  name={subPropertyKey}
                                  value={category[propertyKey][subPropertyKey]}
                                  onChange={(e, value) => handleCoefficientChange(keyWithCategory, value)}
                                  step={1}
                                  valueLabelDisplay="on"
                                />
                              </Col>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </div>
                );
              } else {
                const translationKey = getTranslationKey(propertyKey);
                const keyWithCategory = `${categoryKey}.${propertyKey}`;
                return (
                  <Col className="mb-2" key={propertyKey}>
                    <div className="valSettingsDiv text-start">
                      <div className="d-md-flex justify-content-md-between align-items-md-center">
                        <Col md={6}>
                          <p className="fs-16 fw-bold">{capitalizeFirstLetter(translations[translationKey])}</p>
                        </Col>
                        <Col md={5}>
                          <Slider
                            min={-50}
                            name={propertyKey}
                            value={category[propertyKey]}
                            onChange={(e, value) => handleCoefficientChange(keyWithCategory, value)}
                            step={1}
                            valueLabelDisplay="on"
                          />
                        </Col>
                      </div>
                    </div>
                  </Col>
                );
              }
            })}
          </div>
        );
      };


    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Valutazione</p>
                    <p className="fs-14 opacity-75">Qui potrai gestire le impostazioni per le tue valutazioni immobiliari</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                    <Col md={12} className="px-0 mb-3">
                        <ButtonRm variant="outline" className="me-2" onClick={showModalDefault}>Imposta predefinito</ButtonRm>
                        <ButtonRm variant="outline-gray" onClick={showModalCreation}>Crea nuovi coefficienti</ButtonRm>
                    </Col>

                </Row>

                <Row className="mt-3 flex-column">
                    {
                        listCoeff.length > 0 &&
                        listCoeff.map((el, index) => {
                            return  <Col key={index} className="px-0 mb-2">
                        <div className="faceIdDiv text-start d-flex align-items-center justify-content-between" onClick={(e) => handleCurrent(e, index)}>
                            <div className="d-flex">
                                <p className="fs-16 fw-bold text-capitalize">{el.name}</p>
                                {el.default && <CustomBadge variant="custom-badge-success">Predefinito</CustomBadge>}
                            </div>
                            {index !== 0 && 
                                <button
                                    type="button"
                                    className="btn round-icon-btn"
                                    onClick={(e) => deleteCoeff(e, index)}
                                    >
                                    <BsTrash />
                                </button>}
                            </div>
                    </Col>
                        } ) 
                    }
                </Row>                

            </div >

            {/* MODAL COEFFICIENTI */}
            <Modal
                    show={modalCreation}
                    onHide={closeModalCreation}
                    centered
                    className="editor-modal"
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body className="editor-modal-body">
                        <div
                        className="px-4 pb-4 pt-0 text-start"
                        >
                            <Form onSubmit={handleListCoeff}>
                                <Row className="mt-1 flex-column">                                
                                    <Col className="mb-3">
                                        <p className="fs-16 fw-bold mb-1">
                                        Crea un nuovo pacchetto di coefficienti
                                        </p>
                                        <p className="fs-14 opacity-75">
                                        Qui potrai creare una variante per i valori dei coefficienti di merito (%), da utilizzare nelle tue valutazioni. 
                                        </p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <div className="d-flex">
                                                <p className="fs-14 text-uppercase opacity-75 mt-3 mb-2">
                                                IDENTIFICATIVO
                                                </p>
                                            </div>
                                            <Form.Control 
                                                type="text" 
                                                name="name"
                                                value={newCoeff.name}
                                                onChange={handleNewCoeff}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <div className="my-3">
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            sx={{
                                                [`& .${tabsClasses.scrollButtons}`]: {
                                                    '&.Mui-disabled': { opacity: 0.3 },
                                                },
                                            }}
                                        >
                                            <Tab label="Appartamenti" value="Appartamenti" />
                                            <Tab label="Ville e villini" value="Ville e villini" />
                                            <Tab label="Negozi" value="Negozi" />
                                            <Tab label="Uffici" value="Uffici" />
                                            <Tab label="Box e autorimesse" value="Box e autorimesse" />
                                            <Tab label="Posti auto coperti" value="Posti auto coperti" />
                                            <Tab label="Posti auto scoperti" value="Posti auto scoperti" />


                                            <Tab label="Laboratori" value="Laboratori" />
                                            <Tab label="Magazzini" value="Magazzini" />
                                            <Tab label="Capannoni" value="Capannoni tipici" />
                                            <Tab label="Capannoni industriali" value="Capannoni industriali" />

    

                                        </Tabs>
                                        {value === 'Appartamenti' && renderCategory('Appartamenti')}
                                        {value === 'Ville e villini' && renderCategory('Ville e villini')}

                                        {value === 'Negozi' && renderCategory('Negozi')}
                                        {value === 'Uffici' && renderCategory('Uffici')}
                                        {value === 'Box e autorimesse' && renderCategory('Box e autorimesse')}
                                        {value === 'Posti auto coperti' && renderCategory('Posti auto coperti')}
                                        {value === 'Posti auto scoperti' && renderCategory('Posti auto scoperti')}

                                        {value === 'Laboratori' && renderCategory('Laboratori')}
                                        {value === 'Magazzini' && renderCategory('Magazzini')}
                                        {value === 'Capannoni tipici' && renderCategory('Capannoni tipici')}
                                        {value === 'Capannoni industriali' && renderCategory('Capannoni industriali')}

                                    </div>

                                </Row>                

                                <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                                <ButtonRm type="submit" variant="primary">Salva</ButtonRm>
                                <ButtonRm variant="text" onClick={closeModalCreation}>Annulla</ButtonRm>
                                </div>
                            </Form>
                        
                        </div>

                    </Modal.Body>
            </Modal>

            {/* MODAL PREDEFINITO*/}
                <Modal
                    show={modalDefault}
                    onHide={closeModalDefault}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body >
                        <div
                        className="px-4 pt-0 text-start"
                        >
                            <Col md={12} className="mb-3">
                                <p className="fs-20 fw-bold mb-1">
                                Imposta coefficienti predefiniti
                                </p>
                                <p className="fs-14 opacity-75">
                                Seleziona i coefficienti predefiniti per le tue valutazioni.  
                                Quando crei una valutazione potrai eventualmente sovrascrivere questa scelta qualora avessi bisogno di usare dei coefficienti diversi da quelli predefiniti.
                                </p>
                            </Col>
                            <Col  className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Coefficienti predefiniti
                                    </Form.Label>
                                    <Form.Select
                                        value={defaultCoefficientiId}
                                        onChange={handleDefaultCoefficienti}
                                    >
                                        {listCoeff.length > 0 &&
                                        listCoeff.map((item, index) => {
                                            return <option key={index} value={index}>{item.name}</option>
                                        })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                                <ButtonRm type="submit" variant="primary" onClick={updateDefault}>Salva</ButtonRm>
                                <ButtonRm variant="text" onClick={closeModalDefault}>Annulla</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
                </Modal>
            
            {/* MODAL View*/}
            <Modal
                    show={modalView}
                    onHide={closeModalView}
                    centered
                    className="editor-modal"
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body className="editor-modal-body val-padding">
                        <div
                        className="px-4 pb-4 pt-0 text-start"
                        >
                            <Form onSubmit={handleSaveEdit}>
                                <Row className="flex-column">                                
                                    <Col className="mb-3">
                                        <p className="fs-20 fw-bold mb-1 text-capitalize">
                                        Custom
                                        </p>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <div className="d-flex">
                                                <p className="fs-14 text-uppercase opacity-75 mt-3 mb-2">
                                                IDENTIFICATIVO
                                                </p>
                                            </div>
                                            <Form.Control 
                                                type="text" 
                                                name="name"
                                                value={currentView?.name}
                                                onChange={handleEditCoeff}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>

                                    <div className="my-3">
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
  scrollButtons
  allowScrollButtonsMobile
  sx={{
    [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 },
    },
}}>
                                            <Tab label="Appartamenti" value="Appartamenti" />
                                            <Tab label="Ville e villini" value="Ville e villini" />
                                            <Tab label="Negozi" value="Negozi" />
                                            <Tab label="Uffici" value="Uffici" />
                                            <Tab label="Box e autorimesse" value="Box e autorimesse" />
                                            <Tab label="Posti auto coperti" value="Posti auto coperti" />
                                            <Tab label="Posti auto scoperti" value="Posti auto scoperti" />


                                            <Tab label="Laboratori" value="Laboratori" />
                                            <Tab label="Magazzini" value="Magazzini" />
                                            <Tab label="Capannoni" value="Capannoni tipici" />
                                            <Tab label="Capannoni industriali" value="Capannoni industriali" />

    

                                        </Tabs>
                                        {value === 'Appartamenti' && renderCategory('Appartamenti')}
                                        {value === 'Ville e villini' && renderCategory('Ville e villini')}

                                        {value === 'Negozi' && renderCategory('Negozi')}
                                        {value === 'Uffici' && renderCategory('Uffici')}
                                        {value === 'Box e autorimesse' && renderCategory('Box e autorimesse')}
                                        {value === 'Posti auto coperti' && renderCategory('Posti auto coperti')}
                                        {value === 'Posti auto scoperti' && renderCategory('Posti auto scoperti')}

                                        {value === 'Laboratori' && renderCategory('Laboratori')}
                                        {value === 'Magazzini' && renderCategory('Magazzini')}
                                        {value === 'Capannoni tipici' && renderCategory('Capannoni tipici')}
                                        {value === 'Capannoni industriali' && renderCategory('Capannoni industriali')}

                                    </div>
                                </Row>                

                                <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                                <ButtonRm type="submit" variant="primary">Salva</ButtonRm>
                                <ButtonRm variant="text" onClick={closeModalCreation}>Annulla</ButtonRm>
                                </div>
                            </Form>
                        
                        </div>

                    </Modal.Body>
            </Modal>

        </>

        
    )
}