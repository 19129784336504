import React, { useState, useEffect } from "react";
import "../../../../assets/css/Membri.css";
import { BsFillPlusCircleFill } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import ContactDetails from "./ContactDetails";
import EmailDetails from "./EmailDetails";
import axios from "axios";
import PressoToolTip from "../../../../assets/images/presso-tooltip.svg";
import NotifyIcon from "../../../../assets/images/notify.jpg";
import TrashIcon from "../../../../assets/images/trash.png";
import CustomToggle from "../../../CustomToggle";
import ButtonRm from "../../../ButtonRm";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CustomBadge from "../../../CustomBadge";


const schema = yup.object().shape({
	officeAddress: yup.string().required("Inserisci l'indirizzo"),
	officeHouseNumber: yup.string().required("Inserisci il civico ed eventualmente l'interno"),
	officeProvince: yup.string().required("Seleziona la provincia"),
	officeMunicipality: yup.string().required("Seleziona il comune"),
	correspondenceAt: yup.string().nullable(),

 /*  cellularTelephone: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null), */
  officeTelephone: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null),
  homeTelephone: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null),
  fax: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null),
  skype: yup.string(),
  secondTelephone: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null),
  thirdTelephone: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null),
  fourthTelephone: yup.number("Inserisci solo numeri").nullable().transform((_, val) => val ? Number(val) : null),
/*   email: yup.string().email("Inserisci una mail valida"),
 */  pec: yup.string().email("Inserisci una pec valida"),
  website:  yup.string()
  //manca metodo di contatto => stringa

  
});

const Recapiti = (props) => {
  const [pressoToolTipShow, setPressoToolTipShow] = useState(false);
  const [isAddContact, setIsAddContact] = useState(false);
  const [isAddEmail, setIsAddEmail] = useState(false);
  const [contact, setContact] = useState([
    "homeTelephone",
    "officeTelephone",
    "cellularTelephone",
    "fax",
    "skype",
  ]);
  const [email, setEmail] = useState(["email", "pec"]);
  const [flag, setFlag] = useState(false);
  const [residenceMunicipalityList, setResidenceMunicipalityList] = useState(
    []
  );
  const [correspondenceMunicipalityList, setCorrespondenceMunicipalityList] =
    useState([]);

    //HANDLE ACCORDION
    const [activeKey, setActiveKey] = useState("")


  const fetchCorrespondenceMunicipalityList = () => {
    axios
      .get(
        `https://anthill-service.onrender.com/municipalities?province=${props.formData.correspondenceProvince}`
      )
      .then((res) => {
        setCorrespondenceMunicipalityList(res.data.data);
        if (
          res.data.data.length > 0 &&
          props.formData.correspondenceMunicipality === ""
        ) {
          props.setFormData({
            ...props.formData,
            correspondenceMunicipality: res.data.data[0],
            residenceMunicipality: res.data.data[0],
          });
          console.log(res.data.data[0]);
        }
      });
  };

  const fetchResidenceMunicipalityList = () => {
    axios
      .get(
        `https://anthill-service.onrender.com/municipalities?province=${props.formData.officeProvince}`
      )
      .then((res) => {
        setResidenceMunicipalityList(res.data.data);
      });
  };

  useEffect(() => {
    fetchCorrespondenceMunicipalityList();
  }, [props.formData.correspondenceProvince]);

  useEffect(() => {
    fetchResidenceMunicipalityList();
  }, [props.formData.officeProvince]);

  const addContact = (selected) => {
    let details = props.formData;
    details[selected] = "";
    switch (selected) {
      case "Secondo cellulare":
        contact.push("secondMobile");
        break;
      case "Terzo cellulare":
        contact.push("thirdMobile");
        break;
      case "Quarto cellulare":
        contact.push("fourthMobile");
        break;
    }
    setContact(contact);
    props.setFormData({ ...props.formData, details });
  };

  const addEmail = (selected) => {
    let details = props.formData;
    details[selected] = "";
    switch (selected) {
      case "Secondo email":
        email.push("secondEmail");
        break;
      case "Terzo email":
        email.push("thirdEmail");
        break;
      case "Quarto email":
        email.push("fourthEmail");
        break;
    }
    setEmail(email);
    props.setFormData({ ...props.formData, details });
  };

  const deleteContact = (selected) => {
    let details = props.formData;
    delete details[selected];
    switch (selected) {
      case "Secondo cellulare":
        contact.pop("secondMobile");
        break;
      case "Terzo cellulare":
        contact.pop("thirdMobile");
        break;
      case "Quarto cellulare":
        contact.pop("fourthMobile");
        break;
    }
    setContact(contact);
    props.setFormData({ ...props.formData, details });
    setFlag(!flag);
  };

  const deleteEmail = (selected) => {
    let details = props.formData;
    delete details[selected];
    switch (selected) {
      case "Secondo email":
        email.pop("secondEmail");
        break;
      case "Terzo email":
        email.pop("thirdEmail");
        break;
      case "Quarto email":
        email.pop("fourthEmail");
        break;
    }
    setEmail(email);
    props.setFormData({ ...props.formData, details });
    setFlag(!flag);
  };

  const forward = () => {
      props.next()
  };

/*   const onChange = async (e) => {
    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.value,
    });
  }; */

  /* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname


  //handle phone numbers
  const [cellularPhoneNumbers, setCellularPhoneNumbers] = useState(path == "/proprietari/create" ? [""] : props.formData.cellularTelephone);

  const addPhoneNumberField = () => {
    setCellularPhoneNumbers([...cellularPhoneNumbers, ""]);
  };

  const handleRemovePhoneNumber = (index) => {
    const updatedPhoneNumbers = [...cellularPhoneNumbers];
    updatedPhoneNumbers.splice(index, 1);
    setCellularPhoneNumbers(updatedPhoneNumbers);
  };

  //handle email

  const [emailAddresses, setEmailAddresses] = useState(path == "/proprietari/create"  ? [""] :  props.formData.email);

  const handleAddEmailAddress = () => {
    setEmailAddresses([...emailAddresses, ""]);
  };

  const handleRemoveEmailAddress = (index) => {
    const updatedEmailAddresses = [...emailAddresses];
    updatedEmailAddresses.splice(index, 1);
    setEmailAddresses(updatedEmailAddresses);
};

const onChange = (e, index) => {
const { name, value } = e.target;

if (name === "cellularTelephone") {
  const updatedNumbers = [...cellularPhoneNumbers];
  updatedNumbers[index] = value;
  setCellularPhoneNumbers(updatedNumbers);
} else if (name === "email") {
  const updatedEmails = [...emailAddresses];
  updatedEmails[index] = value;
  setEmailAddresses(updatedEmails);
}  else {
  props.setFormData({
    ...props.formData,
    [name]: value,
  });
}
};

useEffect(() => {
  props.setFormData({
    ...props.formData,
    cellularTelephone: cellularPhoneNumbers,
  });
}, [cellularPhoneNumbers]);

useEffect(() => {
  props.setFormData({
    ...props.formData,
    email: emailAddresses,
  });
}, [emailAddresses]);


  //VALIDAZIONE INPUT

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {

    console.log({data})
		// controlla se ci sono errori di validazione
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		forward()
	};

  console.log(errors, props.formData)


  return (
    <div
      className="d-block w-100 mb-3 bg-sm-white"
      style={{ borderRadius: "16px" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-item-align-box d-block pt-5 pb-5">
          <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="0" activeKey={activeKey}>Sede <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Indirizzo (Via/V.le/Piazza/Corso ecc)
                        <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                      </Form.Label>
                      <Form.Control 
                        {...register("officeAddress")}
                        type="text" 
                        value={props.formData.officeAddress}
                        name="officeAddress"
                        onChange={onChange}
                        isInvalid={!!errors.officeAddress}
                      />
                      <Form.Control.Feedback type="invalid" >
										  {errors.officeAddress?.message}
										</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Civico e Interno
                        <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                      </Form.Label>
                      <Form.Control 
                        {...register("officeHouseNumber")}
                        type="text" 
                        name="officeHouseNumber"
                        value={props.formData.officeHouseNumber}
                        isInvalid={!!errors.officeHouseNumber}
                        onChange={onChange}
                      />
                      <Form.Control.Feedback type="invalid" >
										  {errors.officeHouseNumber?.message}
										</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Provincia
                        <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                      </Form.Label>
                      <Form.Select 
                        {...register("officeProvince")}
                        isInvalid={!!errors.officeProvince}
                        name="officeProvince"
                        value={props.formData.officeProvince}
                        onChange={onChange}
                      >
                        {props.provinceList.map((province, index) => {
                        return (
                          <option value={province} key={index}>
                            {province}
                          </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid" >
										  {errors.officeProvince?.message}
										</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Comune
                          <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                        </Form.Label>
                        <Form.Select 
                          {...register("officeMunicipality")}
                          isInvalid={!!errors.officeMunicipality}
                          name="officeMunicipality"
                          value={props.formData.officeMunicipality}
                          onChange={(e) => onChange(e)}
                        >
                          { residenceMunicipalityList?.length > 0 && residenceMunicipalityList?.map((province, index) => {
                          return (
                            <option value={province} key={index}>
                              {province}
                            </option>
                          );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
										  {errors.officeMunicipality?.message}
										</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="1" activeKey={activeKey}>Indirizzo di Corrispondenza 
</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <Row >
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Presso
                      </Form.Label>
                      <Form.Control 
                        {...register("correspondenceAt")}
                        type="text" 
                        name="correspondenceAt"
                        value={props.formData.correspondenceAt}
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Indirizzo (Via/V.le/Piazza/Corso ecc)
                        
                      </Form.Label>
                      <Form.Control 
                        {...register("correspondenceAddress")}
                        isInvalid={!!errors.correspondenceAddress}
                        type="text" 
                        name="correspondenceAddress"
                        value={props.formData.correspondenceAddress}
                        onChange={onChange}
                      />
                      <Form.Control.Feedback type="invalid" >
                      {errors.correspondenceAddress?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Civico e Interno
                        
                      </Form.Label>
                      <Form.Control 
                        {...register("correspondenceHouseNumber")}
                        isInvalid={!!errors.correspondenceHouseNumber}
                        type="text" 
                        value={props.formData.correspondenceHouseNumber}
                        name="correspondenceHouseNumber"
                        onChange={onChange}
                      />
                       <Form.Control.Feedback type="invalid" >
										{errors.correspondenceHouseNumber?.message}
										</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Provincia
                      
                      </Form.Label>
                      <Form.Select
                        {...register("correspondenceProvince")}
                        isInvalid={!!errors.correspondenceProvince}
                        name="correspondenceProvince"
                        value={props.formData.correspondenceProvince}
                        onChange={onChange}
                      >
                        {props.provinceList?.length > 0 && props.provinceList.map((province, index) => {
                        return (
                          <option value={province} key={index}>
                            {province}
                          </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid" >
										{errors.correspondenceProvince?.message}
										</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Comune
                         
                        </Form.Label>
                        <Form.Select
                          {...register("correspondenceMunicipality")}
                          isInvalid={!!errors.correspondenceMunicipality} 
                          name="correspondenceMunicipality"
                          value={props.formData.correspondenceMunicipality}
                          onChange={(e) => onChange(e)}
                        >
                          {correspondenceMunicipalityList.length>0 && correspondenceMunicipalityList.map((province, index) => {
                        return (
                          <option value={province} key={index}>
                            {province}
                          </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
										{errors.correspondenceMunicipality?.message}
										</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="2" activeKey={activeKey}>Recapiti Telefonici</CustomToggle>
              </Card.Header>
              <Accordion.Body>
              <Row>
              {cellularPhoneNumbers.map((phoneNumber, index) => {
                    return <Col md={6} key={index}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                           {index === 0 ? "Telefono cellulare" : `Cellulare ${index + 1}`}
                        {index > 0 && (
                        <i
                          className="bi bi-trash ms-2"
                          onClick={() => handleRemovePhoneNumber(index)}
                        ></i>
                    )}
                    </Form.Label>
                     <Form.Control
                        type="number"
                        value={phoneNumber}
                        name="cellularTelephone"
                        onChange={(e) => onChange(e, index)}
                      />
                       </Form.Group>
                       </Col>   
                  })}
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Telefono ufficio
                      </Form.Label>
                      <Form.Control 
                        {...register("officeTelephone")}
                        isInvalid={!!errors.officeTelephone}
                        type="number"
                        value={props.formData.officeTelephone}
                        name="officeTelephone"
                        onChange={onChange}
                      />
                      <Form.Control.Feedback type="invalid" >
                      {errors.officeTelephone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>                  
                </Row>
                
                <Row >
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Telefono casa
                      </Form.Label>
                      <Form.Control 
                        {...register("homeTelephone")}
                        isInvalid={!!errors.homeTelephone}
                        type="number"
                        value={props.formData.homeTelephone}
                        name="homeTelephone"
                        onChange={onChange}
                      />
                      <Form.Control.Feedback type="invalid" >
                      {errors.homeTelephone?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Fax
                      </Form.Label>
                      <Form.Control 
                        {...register("fax")}
                        isInvalid={!!errors.fax}
                        type="number"
                        value={props.formData.fax}
                        name="fax"
                        onChange={onChange}
                      />
                      <Form.Control.Feedback type="invalid" >
                      {errors.fax?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  
                </Row>

                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Skype
                      </Form.Label>
                      <Form.Control 
                        {...register("skype")}
                        type="text"
                        value={props.formData.skype}
                        name="skype"
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>

                  <div className="col-12">
                  <button
                    type="button"
                    className="other-contact p-0 text-dark fs-16 bg-transparent border-0 fw-semibold"
                    onClick={addPhoneNumberField}
                    >
                    <BsFillPlusCircleFill /> Aggiungi altri recapiti
                  </button>
                </div>                 
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="3" activeKey={activeKey}>Posta Elettronica </CustomToggle>
              </Card.Header>
              <Accordion.Body>
              <Row className="mb-3">
              {emailAddresses.map((email, index) => (
                <Col md={6} key={index}>
                  <Form.Group className="mb-3">
                    <Form.Label className="f-label fs-16 d-flex align-items-center
mb-2">
                      {index === 0 ? "Email" : `Email ${index + 1}`}
                      {index > 0 && (
                        <i
                          className="bi bi-trash ms-2"
                          onClick={() => handleRemoveEmailAddress(index)}
                        ></i>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      value={email}
                      name="email"
                      onChange={(e) => onChange(e, index)}
                    />
                  </Form.Group>
                </Col>
              ))}
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                      Email posta certificata (PEC)
                      </Form.Label>
                      <Form.Control 
                        {...register("pec")}
                        isInvalid={!!errors.pec}
                        type="email"
                        value={props.formData.pec}
                        name="pec"
                        onChange={onChange}
                      />
                      <Form.Control.Feedback type="invalid" >
                      {errors.pec?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <div className="col-12">
                  <button
                    type="button"
                    className="other-contact p-0 text-dark fs-16 bg-transparent border-0 fw-semibold"
                    onClick={handleAddEmailAddress}
                  >
                    <BsFillPlusCircleFill /> Aggiungi altre email
                  </button>
                </div>                  
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="4" activeKey={activeKey}>Sito web</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <Row >
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Sito web
                      </Form.Label>
                      <Form.Control 
                        {...register("website")}
                        type="text"
                        value={props.formData.website}
                        name="website"
                        onChange={onChange}
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="5" activeKey={activeKey}>Metodo di contatto preferito  </CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <div className="row mb-3">
                <Col md={6}>
                    <Form.Group className="mb-3">
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Metodo di contatto preferito
                        </Form.Label>
                        <Form.Select 
                          //da definire e aggiungere value e name, register
                          {...register("favoriteContact")}
                          isInvalid={!!errors.favoriteContact}
                          type="text"
                          value={props.formData.favoriteContact}
                          name="favoriteContact"
                          onChange={onChange}
                        >
                          <option value="Posta">Posta</option>
                          <option value="PEC">PEC</option>
                          <option value="Chat">Chat</option>
                          <option value="Email">Email</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          { path == "/fornitori/create" && <div className="col-12">
            <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
              <ButtonRm variant="tertiary" onClick={() => props.back()} className="me-2">Indietro</ButtonRm>
              <ButtonRm variant="primary"  type="submit">Avanti</ButtonRm>
            </div>    
          </div>}
        </div>
      </Form>
   {/*  <ContactDetails
        show={isAddContact}
        addContact={(v) => addContact(v)}
        setAdd={(v) => setIsAddContact(v)}
      />
      <EmailDetails
        show={isAddEmail}
        addEmail={(v) => addEmail(v)}
        setAdd={(v) => setIsAddEmail(v)}
      />  */}
    </div>
  );
};

const InputComponent = (props) => {
  return (
    <div className="col-md-6">
      <div className="d-flex flex-column">
        <label
          htmlFor=""
          className="f-label fs-16 d-flex align-items-center
mb-2 d-flex align-items-center"
        >
          <div>{props.value}</div>
          <img
            src={TrashIcon}
            style={{
              width: "20px",
              height: "15px",
              cursor: "pointer",
              marginLeft: "5px",
              display: props.index < props.limit ? "none" : "",
            }}
            onClick={() => props.deleteComp(props.value)}
          ></img>
        </label>
        <input
          type="text"
          name={props.Name}
          defaultValue={props.keyValue}
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
          onChange={(e) => props.onChange(e)}
        />
      </div>
    </div>
  );
};

export default Recapiti;
