import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import DocumentCard from "./Tipologia/DocumentCard";
import { Link } from "react-router-dom";
import api, { first_note_endpoint } from "../../../api/api";
import EmptyList from "../EmptyList";
import { BANK_ACCOUNTS, AMOUNTS } from "../../../constants";

import { Row, Col, Container, Modal } from "react-bootstrap";

import SearchInput from "../../../components/SearchInput";

import filter from "../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../assets/images/icon-sidebar/sort.svg"

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ButtonRm from "../../../components/ButtonRm";
import CustomToggle from "../../../components/CustomToggle";

import FilterSearchBar from "../../StatoLavoriInCorso/Gestioni/FilterSearchBar";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";

import { checkUserRole, checkMaster } from "../../../utils/checkUserRole";

import Alert from "react-bootstrap/Alert";

function Documents(props) {

  let counter = 0;

  const [selectedItems, setSelectedItems] = useState([]);

	// Funzione per gestire la selezione multipla
	const handleSelection = (e, id) => {
			e.stopPropagation()

		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
		} else {
			setSelectedItems([...selectedItems, id]);

		}
	};

	const handleSelectAll = () => {
		let selectedItems = props.sortedUnits.map((item, index) => item.id)
		setSelectedItems(selectedItems)
	}

	const handleDeselectAll = () => {
		setSelectedItems([])
	}

	// Funzione per eliminare gli stabili selezionati
	const deleteSelected = async () => {
		// Effettua l'operazione di eliminazione per ogni id selezionato
		for (const id of selectedItems) {
		try {
			await api().delete(`${first_note_endpoint}${id}`);
      console.log("ELIMINATA PRIMA NOTA ", id)
		} catch (err) {
			console.log(err);
		}
		}
		await props.getDocuments()
		setSelectedItems([]);
  }

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//handle modal permessi
	const [showPermit, setShowPermit] = useState(false);
	const handleClosePermit = (e) => {
		setShowPermit(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShowPermit = () => setShowPermit(true);
	

	  //MI SERVE LISTA COLLABORATORI MASTER
	  const [members, setMembers] = useState([
		{
			id:1,
			name:"Ema",
			surname:"Mavi",
			email:"ema@gmail.com"
		},
		{
			id:2,
			name: 'Greta',
			surname: 'Pio',
			email: 'gre@gmail.com'
		}
	  ])
	
	  const handleCheckboxChange = (userId, action) => {
		//da gestire
	  };



  return (
    <>
    <Row className="dashboard-container">
      {<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
          <FilterSearchBar
              setSearchTerm={props.setSearchTerm}
              selectedCategories={props.selectedCategories}
              setSelectedCategories={props.setSelectedCategories}
              clearFiltersAndSort={props.clearFiltersAndSort}
              setSortBy={props.setSortBy}
              categories={props.categories}
              startDate={props.startDate} 
              endDate={props.endDate}
              setStartDate={props.setStartDate} 
              setEndDate={props.setEndDate}
            />
			</Col>}

      <div className="account-document mt-2">
        <div className="d-block my-3 w-100">
          <select
            className="drop-rate"
            onChange={(e) => {
              props.setSelectedCategories([{
                items:[e.target.value],
                name:"Conti"
              }])
            }}
          >
                {Object.keys(props.counterDict).map((key) => {
                  return (
                      <option value={key == "Tutti i conti" ? "" : key}
                      selected={key === "Tutti i conti"}
                      >{key} € {props.counterDict[key]}</option>
                  );
                })}

          </select>
          </div>

        {/* TABLE */}
        <div className="document position-relative">
        {checkUserRole() &&
                	selectedItems.length > 0 && 
					<div className="d-flex mb-3">
						<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
							Seleziona tutti
						</ButtonRm>
						<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
							Deseleziona Tutti
						</ButtonRm>
					</div>
				}
  <table className="document-table-note mb-3">
    <thead>
      <tr className="">
        <th className="fs-14 fw-bold w-13p"></th>
        <th className="fs-14 fw-bold w-13p">Descrizione</th>
        <th className="fs-14 fw-bold w-13p">Data</th>
        <th className="fs-14 fw-bold w-13p d-none d-md-table-cell">Conto partenza</th>
        <th className="fs-14 fw-bold w-13p d-none d-md-table-cell">Conto destinazione</th>
        <th className="fs-14 fw-bold w-13p  d-none d-md-table-cell">Entrata</th>
        <th className="fs-14 fw-bold w-13p  d-none d-md-table-cell">Uscita</th>
        <th className="fs-14 fw-bold w-13p  d-none d-md-table-cell">Saldo</th>
        <th className="fs-14 fw-bold w-13p  d-table-cell d-md-none">Importo</th>
      </tr>
    </thead>
    <tbody> 
      {props.sortedUnits !== null && props.sortedUnits.length !== 0 ? (
        props.sortedUnits.map((item, index) => {
          if (index === 0) counter = 0;
          if (
            item.operationDescription &&
            item.operationDescription
              .toLowerCase()
              .indexOf(props.searchTerm.toLowerCase()) !== -1
          ) {
            if (item.typology == "Movimento" && item.isEnteringAmount !== null)
              counter = item.isEnteringAmount
                ? counter + item.amount
                : counter - item.amount;
            return (
                <DocumentCard data={item} counter={counter} selectedCategories={props.selectedCategories} selectedItems={selectedItems} handleSelection={handleSelection} />
            )
          } else {
            return null;
          }
        })
      ) : (
        <tr>
          <td colSpan="7">Nessun dato disponibile</td>
        </tr>
      )}
    </tbody>
  </table>


  <Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>
        </div>

        
      </div>
      </Row>

      <Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>
    </>
  );
}

export default Documents;
