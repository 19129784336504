import React from "react";
import MenuSlc from "./MenuSlc";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-bianco.svg";
import logoslc from "../assets/images/logo-sal.svg";
import LastMenuSlc from "./LastMenuSlc";

export default function SidebarSlc() {
  return (
    <>
      <Link to="/dashboard" className="logo fw-100 mx-auto d-table mb-5">
        <img loading="lazy" src={logoslc} alt="logo-image" />
      </Link>
      <MenuSlc />
      <div className="last-links mt-auto">
        <LastMenuSlc />
      </div>
    </>
  );
}
