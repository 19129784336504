import React, { useState, useEffect, useRef } from "react";
import MediaQuery from "react-responsive";
import Sidebar from "./Sidebar";
import logo from "../assets/images/logo-bianco.svg";
import mobileMenu from "../assets/images/mobile_menu.svg";
import mobileMenuClose from "../assets/images/white_close.svg";
import { Link } from "react-router-dom";
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import Menu from "./Menu";
import LastMenu from "./LastMenu";

import hero from "../assets/images/bg-hero-fornitori-8.png"
import CustomBadge from "./CustomBadge";

import {
  getPanelElement,
  getPanelGroupElement,
  getResizeHandleElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
  ImperativePanelHandle,

} from "react-resizable-panels";


import '../App.css'

export default function Container(props) {
  //STATO SIDEBAR
  const [isActive, setActive] = useState(true);

  useEffect(() => {
    localStorage.setItem("isActive", JSON.stringify(isActive));
  }, [isActive]);


  const [mobile_menu, setMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenu(!mobile_menu);
  };

  //GESTISCI PANEL SIDEBAR
  
  const panelRef = useRef(null);

  const [panelSize, setPanelSize] = useState(null);

  useEffect(() => {
    // Recupera panelSize da localStorage al caricamento iniziale
    const savedPanelSize = localStorage.getItem('panelSize');
    if (savedPanelSize) {
      setPanelSize(JSON.parse(savedPanelSize));

      if(savedPanelSize >= 15){
        setActive(true)
      } else {
        setActive(false)
      }

    } else {
      if (panelRef.current) {
        const size = panelRef.current.getSize();
        localStorage.setItem('panelSize', JSON.stringify(size));

        if(size >= 15){
          setActive(true)
        } else {
          setActive(false)
        }
      }
  
    }
  }, []);

  const handleResize = (newSize) => {
    setPanelSize(newSize);
    // Salva panelSize in localStorage quando viene modificato
    localStorage.setItem('panelSize', JSON.stringify(newSize));
    if(newSize >= 15){
      setActive(true)
    } else {
      setActive(false)
    }

     // Poi, crea e dispatcha un evento personalizzato 
  const event = new CustomEvent('local-storage-change', {
    detail: {
      key: 'panelSize',
      newValue: newSize
    }
  });

  // Dispatcha l'evento sulla window
  window.dispatchEvent(event);
  };

  const [scrollTop, setScrollTop] = useState(0);

  // Effetto per gestire l'evento di scroll
  useEffect(() => {
    const handleScroll = () => {
      const newScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollTop(newScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  
    useEffect(() => {
      // Definisce la funzione da chiamare quando la finestra viene ridimensionata
      const handleResizeWindow = () => {

        if(window.innerWidth < 1124){
          handleResize(8)
        }
      };
  
      window.addEventListener('resize', handleResizeWindow);
  
      return () => {
        window.removeEventListener('resize', handleResizeWindow);
      };
    }, []); 

    //GESTISCE COLLASSO/ESPANSIONE
    const handleCollapse = () => {
      setActive(!isActive);
      handleResize(8)
    };

    const handleExpand = () => {
      setActive(isActive);
      handleResize(16)
    };
  

  return (
    <>
      <div className={`mobile-sidebar ${mobile_menu ? "full-height" : ""}`}>
        <div className="logo-menu-group">
          <Link to="/">
            <img loading="lazy" src={logo} alt="logo-image" className="mobile-logo" />
          </Link>
          <button className="mobile-menu">
            <img
              src={mobile_menu ? mobileMenuClose : mobileMenu}
              alt="mobileMenuIcon"
              className="mobile-menu-icon"
              onClick={toggleMobileMenu}
            />
          </button>
          
        </div>
        
        <div className={`${mobile_menu ? "display-show" : "display-hidden"}`}>
          <div className="mt-5">
            <Menu mobile_menu={mobile_menu} setMobileMenu={setMobileMenu} />
          </div>
        </div>
      </div>
      
      <MediaQuery minWidth={900} >
        <div className={`app-box d-flex ${(isActive && panelSize >= 15) ? "" : "app-small"}`}>      
          <PanelGroup direction="horizontal" style={{overflow: "unset"}}>

          <Panel defaultSize={16} maxSize={20} minSize={8} onResize={handleResize} ref={panelRef}  style={{overflow: "visible"}}>
{/*           <div className="d-flex flex-column sidebar-main">
 */}           
          <div className=" d-flex flex-column sidebar-main"  style={{ position: "sticky", top: 0 }}>
            <Sidebar isActive={isActive && panelSize >= 15} panelSize={panelSize}  />
              {(!isActive && panelSize < 15) && <button
                className="
                  border-0
                  bg-transparent
                  text-white
                  open-close-btn"
                onClick={handleExpand}
              >
                <BsChevronDoubleRight  />
              </button> 
          }
          {(isActive && panelSize >= 15) &&
              <button
                className="
                  border-0
                  bg-transparent
                  text-white
                  open-close-btn"
                onClick={handleCollapse}
              >
                <BsChevronDoubleLeft />
              </button> 
          }

          </div>
          </Panel>
          <PanelResizeHandle />



          <Panel defaultSize={84} maxSize={90} minSize={75} className="mt-5" style={{overflow: "unset"}}>
         {/*  <div className="right-side-box d-block overflow-x-hidden h-100" style={{background: 'rgba(230, 240, 255, 0)',  backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed"}}> */}
            <div 
             style={{
              width: `calc(100vw - 4px - ${panelSize}vw)`, 
              backgroundSize: "cover", 
              backgroundRepeat: "no-repeat", 
              backgroundAttachment: "fixed",
              padding: "0 1rem",
              paddingBottom: "2rem"
            }}>
            {props.children}
          </div>
          </Panel>

          </PanelGroup>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={900}>
        <div className={`app-box d-flex ${isActive ? "app-small" : ""}`}>
          <div className="sidebar-main d-flex flex-column">
            <Sidebar />
          </div>
          <div
            className={`right-side-box d-block ${mobile_menu ? "display-hidden" : "display-show"
              }`} style={{ backgroundImage: ``, backgroundSize: "contain", backgroundRepeat: "no-repeat"}}
          >
            {props.children}
          </div>
        </div>
      </MediaQuery> 
    </>
  );
}

//             className={`right-side-box px-3 px-md-4 p-5  d-block ${
