const options = [
    { value: 'Idraulico', label: 'Idraulico' },
    { value: 'Elettricista', label: 'Elettricista' },
    { value: 'Ditta Edile', label: 'Ditta Edile' },
    { value: 'Architetto', label: 'Architetto' },
    { value: 'Geometra', label: 'Geometra' },
    { value: 'Agente Immobiliare', label: 'Agente Immobiliare' },
    { value: 'Interior Designer', label: 'Interior Designer' },
    { value: 'Muratore', label: 'Muratore' },
    { value: 'Pittore Edile', label: 'Pittore Edile' },
    { value: 'Falegname', label: 'Falegname' },
    { value: 'Giardiniere', label: 'Giardiniere' },
    { value: 'Spazzacamino', label: 'Spazzacamino' },
    { value: 'Tecnico del Riscaldamento', label: 'Tecnico del Riscaldamento' },
    { value: 'Tecnico del Condizionamento', label: 'Tecnico del Condizionamento' },
    { value: 'Perito Edile', label: 'Perito Edile' },
    { value: 'Serraturiere', label: 'Serraturiere' },
    { value: 'Architetto', label: 'Architetto' },
    { value: 'Lavorazione di marmo e affini', label: 'Lavorazione di marmo e affini' },
    { value: 'Traslochi', label: 'Traslochi' },
    { value: 'Vetrai', label: 'Vetrai' },

]

export default options