import React, {useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const isLoggedIn = localStorage.getItem("user") !== null;
  useEffect(() => {
    // Creare un nuovo elemento script
    const s1 = document.createElement("script");
    
    // Definire le proprietà dello script come nel tuo esempio
    s1.async = true;
    s1.src = 'https://embed.tawk.to/64e3174094cf5d49dc6b9120/1h8bh5lpr';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    
    // Trovare il primo script nel documento
    const s0 = document.getElementsByTagName("script")[0];
    
    // Inserire il nuovo script prima di quello trovato
    s0.parentNode.insertBefore(s1, s0);
    
    // Opzionale: puoi fare una pulizia rimuovendo lo script quando il componente viene smontato
    return () => {
        s1.remove();
    };
}, []); // 

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;
