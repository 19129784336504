import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../../assets/css/AddDetails.css";
import NewTarrifa from "./NewTarrifa";
import DeleteConfirm from "../../ProprietariSublocatori/create_mode/DeleteConfirm";

export default function Tarrafari(props) {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  const [newTarrifa, setNewTarrifa] = useState(false);
  const [del, setDel] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props]);

  const handleClose = () => {
    setShow(false);
    props.setAdd(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tarrafari-Modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} />
      <Modal.Body>
        <div>
          <div className="contactDetails-title">Piani tariffari</div>
          <div className="d-flex align-items-center mt-5 mb-3 form-last-btns justify-content-start">
            <button
              type="button"
              className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
              onClick={() => setNewTarrifa(true)}
            >
              Nuovo
            </button>
          </div>
          <table className="tarrafari-table mb-5">
            <thead>
                <tr>
                    <th>Tipo di utenza</th>
                    <th>Nome del piano tariffario</th>
                    <th>Costo fisso mensile</th>
                    <th>Costo a consumo</th>
                </tr>
            </thead>
            <tbody>
              <tr onClick={() => {setSelected("gas"); setNewTarrifa(true);}} style={{cursor: "pointer"}}>
                <td>Gas</td>
                <td>Tariffa 3</td>
                <td>€10,00</td>
                <td>€10,00</td>
              </tr>
              <tr onClick={() => {setSelected("elettricita"); setNewTarrifa(true);}} style={{cursor: "pointer"}}>
                <td>Elettricità</td>
                <td>Tariffa 4</td>
                <td>€10,00</td>
                <td>€10,00 - €10,00 - €10,00</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <NewTarrifa show={newTarrifa} setAdd={(v) => setNewTarrifa(v)} selected={selected} setSelected={(v) => setSelected(v)} setDel={(v) => setDel(v)} />
      <DeleteConfirm show={del} setDel={(v) => setDel(v)} />
    </Modal>
  );
}
