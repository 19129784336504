import React, { useState } from 'react'
import Section1 from './page10/section1'

export default function Page10() {
    const [status, setStatus] = useState(1);
    const Section = () => {
        switch (status) {
            case 1:
                return <Section1 onChangeSection={(v) => setStatus(v)} />
                break;
        }
        return;
    }
    return (
        <Section />
    )
}

