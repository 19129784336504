import React, { useRef, useState } from "react";
import PrintDwonloadIcons from "./PrintDwonloadIcons";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Form } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import { BsFillPlusCircleFill, BsPrinter } from "react-icons/bs";

import CustomToggle from "../../../CustomToggle";
import { Row, Col } from "react-bootstrap";

import translationMapping from '../../mapping'
import ExportButton from "../../Fornitori/ExportButton";
import CustomBadge from "../../../CustomBadge.js";


export default function RecapitiV(props) {
  const componentRef = useRef();

  /* GESTISCE L'ELEMENTO ACCORDION ATTIVO */
  const [activeKey, setActiveKey] = useState('');

  let item = {
    "residenceAddress": props.formData.residenceAddress,
    "residenceHouseNumber": props.formData.residenceHouseNumber,
    "residenceProvince": props.formData.residenceProvince,
    "residenceMunicipality": props.formData.residenceMunicipality,
    "correspondenceAt": props.formData.correspondenceAt,
    "correspondenceAddress": props.formData.correspondenceAddress,
    "correspondenceHouseNumber": props.formData.correspondenceHouseNumber,
    "correspondenceProvince": props.formData.correspondenceProvince,
    "correspondenceMunicipality": props.formData.correspondenceMunicipality,
    "officeTelephone": props.formData.officeTelephone,
    "homeTelephone": props.formData.homeTelephone,
    "fax": props.formData.fax,
    "skype": props.formData.skype,
    "pec": props.formData.pec,
    "favoriteContact": props.formData.favoriteContact,
    ...props.formData.cellularTelephone.reduce((result, phoneNumber, index) => {
      result[`cellularTelephone${index + 1}`] = phoneNumber;
      return result;
    }, {}),
    ...props.formData.email.reduce((result, email, index) => {
      result[`email${index + 1}`] = email;
      return result;
    }, {}),
  
  };

  return (
    <>
     <div className="d-flex mb-3 justify-content-end mt-3 me-3">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

				
					<ExportButton showPrint data={item} mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
      <fieldset disabled  ref={componentRef}>
      <div className="form-item-align-box d-block pb-5">
      <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="0" activeKey={activeKey}>Sede o Residenza</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Indirizzo (Via/V.le/Piazza/Corso ecc)
                      </Form.Label>
                      <Form.Control 
                        type="text" 
                        value={props.formData.residenceAddress}
                        name="residenceAddress"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Civico e Interno
                      </Form.Label>
                      <Form.Control 
                        type="text" 
                        name="residenceHouseNumber"
                        value={props.formData.residenceHouseNumber}
                        required
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="me-2">
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Provincia
                      </Form.Label>
                      <Form.Select 
                        name="residenceProvince"
                        value={props.formData.residenceProvince}
                      >
                         <option value={props.formData.residenceProvince}>{props.formData.residenceProvince}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="me-2">
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Comune
                        </Form.Label>
                        <Form.Select 
                          name="residenceMunicipality"
                          value={props.formData.residenceMunicipality}
                        >
                         <option value={props.formData.residenceMunicipality}>{props.formData.residenceMunicipality}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="1" activeKey={activeKey}>Indirizzo di Corrispondenza</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Presso
                      </Form.Label>
                      <Form.Control 
                        type="text" 
                        name="correspondenceAt"
                        value={props.formData.correspondenceAt}
                        required
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Indirizzo (Via/V.le/Piazza/Corso ecc)
                      </Form.Label>
                      <Form.Control 
                        type="text" 
                        name="correspondenceAddress"
                        value={props.formData.correspondenceAddress}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Civico e Interno
                      </Form.Label>
                      <Form.Control 
                        type="text" 
                        value={props.formData.correspondenceHouseNumber}
                        name="correspondenceHouseNumber"
                        required
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="me-2">
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        Provincia
                      </Form.Label>
                      <Form.Select 
                        name="correspondenceProvince"
                        value={props.formData.correspondenceProvince}
                      >
                        <option value={props.formData.correspondenceProvince}>{props.formData.correspondenceProvince}</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="me-2">
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Comune
                        </Form.Label>
                        <Form.Select 
                          name="correspondenceMunicipality"
                          value={props.formData.correspondenceMunicipality}
                        >
                           <option value={props.formData.correspondenceMunicipality}>{props.formData.correspondenceMunicipality}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="2" activeKey={activeKey}>Recapiti Telefonici</CustomToggle>
              </Card.Header>
              <Accordion.Body>
              <div className="row gy-4">
                {props.formData.cellularTelephone.map((phoneNumber, index) => {
                    return <div className="col-md-6" key={index}>
                    <Form.Group className="" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        {index === 0 ? "Telefono cellulare" : `Cellulare ${index + 1}`}
                        

                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={phoneNumber}
                        name="cellularTelephone"
                        readOnly
                      />
                    </Form.Group>
                  </div>   
                  })}
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Telefono ufficio
                    </label>
                    <input
                      type="text"
                      value={props.formData.officeTelephone}
                      name="officeTelephone"

                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Telefono casa
                    </label>
                    <input
                      type="text"
                      value={props.formData.homeTelephone}
                      name="homeTelephone"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Fax
                    </label>
                    <input
                      type="text"
                      value={props.formData.fax}
                      name="fax"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Skype
                    </label>
                    <input
                      type="text"
                      value={props.formData.skype}
                      name="skype"
                    />
                  </div>
                </div>
              </div>
             {/*  <div className="col-12">
                <button
                  type="button"
                  className="other-contact p-0 text-dark fs-16 bg-transparent border-0 fw-semibold"
                  // onClick={() => setIsAddContact(true)}
                >
                  <BsFillPlusCircleFill /> Aggiungi altri recapiti
                </button>
              </div> */}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="3" activeKey={activeKey}>Posta Elettronica</CustomToggle>
              </Card.Header>
              <Accordion.Body>
              <div className="row gy-4">
                {props.formData.email.map((email, index) => {
                    return <div className="col-md-6" key={index}>
                    <Form.Group className="" >
                      <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                      >
                        {index === 0 ? " Email per notifiche" : `Email ${index + 1}`}
                        

                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={email}
                        name="email"
                        readOnly
                      />
                    </Form.Group>
                  </div>   
                  })} 
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Email posta certificata (PEC)
                    </label>
                    <input
                      type="text"
                      value={props.formData.pec}
                      name="pec"
                    />
                  </div>
                </div>

               {/* <div className="col-12">
                  <button
                    type="button"
                    className="other-contact p-0 text-dark fs-16 bg-transparent border-0 fw-semibold"
                    // onClick={() => setIsAddEmail(true)}
                  >
                    <BsFillPlusCircleFill /> Aggiungi altre email
                  </button>
                </div> */}
              </div>
              </Accordion.Body>
            </Accordion.Item>

           {/*  <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="4" activeKey={activeKey}>Sito web</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Sito web
                      </label>
                      <input
                        type="text"
                        value={props.formData.website}
                        name="website"
                      />
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item> */}

            <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="5" activeKey={activeKey}>Metodo di contatto preferito  </CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <div className="row gy-4">
                <Col md={6}>
                    <Form.Group className="me-2">
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Metodo di contatto preferito
                        </Form.Label>
                        <Form.Select 
                          name="favoriteContact" 
                          value={props.formData.favoriteContact}
                          readOnly
                        >
                          <option value="Posta">Posta</option>
                          <option value="PEC">PEC</option>
                          <option value="Chat">Chat</option>
                          <option value="Chat">Email</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
      </div>

      </fieldset>
    </>
  );
}
