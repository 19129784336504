import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../../assets/css/Impostazioni.css"



import "../../../assets/css/Account.css"






import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/Tabs.css";


import SearchInput from "../../SearchInput";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from "../../../actions/accountAction";

import ButtonRm from "../../ButtonRm";


export default function DeleteC(){

    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);


    //HANDLE SEARCH
    const [searchTerm, setSearchTerm] = useState("");

    const users = useSelector(state => state.userReducer.users)?.filter(el => ((el.name)?.toLowerCase().includes(searchTerm) || el.surname?.toLowerCase().includes(searchTerm)));


    return(

        <>
        <Row>
            <p className="fs-18 text-dark fw-bold">Elimina utenti</p>
            <p className="fs-14 opacity-75">Eliminazione account RM</p>
            <hr className="opacity-10 my-3"/>
        </Row>
        <Row className="mt-2">
        
        <Col md={6} className="ps-0 mb-3">
                    <p className="fs-14 text-dark opacity-75">Eliminando un utente, verrano eliminati anche tutti gli account da lui dipendenti (fornitori esclusi)</p>
            </Col>
            {/* QUANDO GESTIAMO API DI QUESTA PARTE AGGIUNGERE POI MODALE DI CONFERMA ELIMINAZIONE */}
            <Col md={8} className="ps-0">
                <div className="mb-2">
                    <Col md={8} className="me-2">
                        <Form.Group>
                            <Form.Control 
                                type="email" 
                                placeholder="Inserisci l'email dell'utente da eliminare"
                                // name="issueEntity"
                                // value={data.issueEntity}
                                // onChange={onChange}
                            />
                            <Form.Control.Feedback type="invalid" >
                            {/* {errors.issueEntity?.message} */}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
              
                </div>
            
                <div className="d-flex justify-content-start">
                    <ButtonRm variant="outline-gray" >Elimina</ButtonRm>
                </div>
                
            </Col>
        </Row> 
        </>

        
    )
}