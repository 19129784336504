import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';

//TABELLA



	const HeaderRow = ({ columns, styles }) => (
		<View style={[styles.tableRow, styles.tableHeader]}>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>Nome</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>Entrata/Uscita</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>Iva</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>Descrizione</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>Data</Text>
		  </View>
		  {columns?.length > 0 && columns.map((column, index) => (
			<View style={styles.tableCol} key={index}>
			  <Text style={styles.tableCell}>{capitalizeFirstLetter(column)}</Text>
			</View>
		  ))}
		</View>
	  );
	
	const AccountRow = ({ name, styles, columns }) => (
		<View style={[styles.tableRow, styles.accountRow]}> 

			<View style={[styles.tableCol, { width: '100%' }]}>
				<Text style={styles.tableCell}>{capitalizeFirstLetter(name)}</Text>
			</View>

		
        </View>
	);
	
	const SubAccountRow = ({ name, taxable, vat, description, date, preventivo, note, fornitore, columns, styles }) => (
		<View style={styles.tableRow}>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>{capitalizeFirstLetter(name)}</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>€ {taxable}</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>
				{vat == 1 ? "Esente" :
				vat == 1.05 ? "5%" :
				vat == 1.1 ? "10%" :
				vat == 1.2 ? "20%" :
				vat == 1.21 ? "21%" :
				vat == 1.22 ? "22%" :
				""}
			</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>{capitalizeFirstLetter(description)}</Text>
		  </View>
		  <View style={styles.tableCol}>
			<Text style={styles.tableCell}>{capitalizeFirstLetter(date)}</Text>
		  </View>
		  {columns?.length > 0 && columns.map((column, index) => (
			<View style={styles.tableCol} key={index}>
			  <Text style={styles.tableCell}>
			  {column == "preventivo" && `€ ${preventivo}`}
			  {column == "fornitore" && fornitore}
			  {column == "note" && note}
			  </Text>
			</View>
		  ))}
		</View>
	  );

const MyDocument = ({ el }) => {

	const source = "http://fonts.gstatic.com/s/biryani/v1/E2eZ6gDqHsQctcgNMH1j7w.ttf"

	Font.register({ family: 'Biryani', src: source });

	//VEDI SE CI SONO DATI IN PREVENTIVO - NOTE - FORNITORE IN ALMENO UN ELEMENTO DI EL.MOVEMENT, PER DETERMINARE QUALI E QUANTE COL MOSTRARE

	const hasPreventivo = el.movement?.length> 0 && el.movement?.some((item) => !!item.preventivo);
	const hasFornitore =  el.movement?.length> 0 && el.movement?.some((item) => !!item.fornitore);
	const hasNote =  el.movement?.length> 0 && el.movement?.some((item) => !!item.note);

	const columns = [];
	if (hasPreventivo) columns.push("preventivo");
	if (hasFornitore) columns.push("fornitore");
	if (hasNote) columns.push("note");

	const numColumns = 5 + columns.length;
	const columnWidth = `${100 / numColumns}%`;

	const styles = StyleSheet.create({
		table: { 
			display: "table", 
			width: "auto", 
			borderStyle: "solid", 
			borderColor: "#e0e0e0",
			borderWidth: 0.5, 
			borderRightWidth: 0, 
			borderBottomWidth: 0,
						fontFamily: 'Biryani'

		  }, 
		  tableRow: { 
			margin: "auto", 
			flexDirection: "row",
			borderColor: "#e0e0e0",
			fontFamily: 'Biryani'

 
		  }, 
		  tableCol: { 
			width: columnWidth,
			borderStyle: "solid", 
			borderLeftWidth: 0, 
			borderTopWidth: 0,
			borderColor: "#e0e0e0",
			borderWidth: 0.5, 
			fontFamily: 'Biryani'
		}, 
		tableCell: { 
			margin: 5,
			fontSize: 10,
			fontFamily: 'Biryani',
		},
	
		  body: {
			paddingTop: 35,
			paddingBottom: 65,
			paddingHorizontal: 35,
	
		  },
		  title: {
			fontSize: 20,
			textAlign: 'left',
			marginBottom: 5,
			fontFamily: 'Biryani'
		  },
		  titleUnit: {
			fontSize: 20,
			textAlign: 'left',
			marginBottom: 20,
			fontFamily: 'Biryani'

		  },
		  author: {
			fontSize: 10,
			textAlign: 'left',
			marginBottom: 20,
			fontFamily: 'Biryani'

		  },
		  subtitle: {
			fontSize: 10,
			marginBottom: 10,
			fontFamily: 'Biryani'

		  },
		  text: {
			margin: 12,
			fontSize: 14,
			fontFamily: 'Biryani'

		  },
		  tableHeader: {
			backgroundColor: '#800021',
			borderColor: "#e0e0e0",
			color: 'white'

		  },
			accountRow: {
			backgroundColor: '#eee',
			borderColor: "#e0e0e0",

			},

			
			tableRowTotal: { 
			flexDirection: "row",
			justifyContent: 'space-between', // Distribuisce uniformemente i figli
			width: '100%', // Occupa l'intera larghezza
			borderStyle: "solid", 
			borderWidth: 0.5, 
			borderBottomWidth: 0 ,
			borderColor: "#e0e0e0",

			}, 
			tableRowTotalFinal: { 
				flexDirection: "row",
				borderStyle: "solid", 
				borderWidth: 0.5, 
				borderColor: "#e0e0e0",

			},
			tableColTotal: { 
			width: "50%", 
			}, 	
	
			tableTotal: { 
			display: "table", 
			width: "auto", 
			marginTop: 10
		}, 
	});


    //controlla le date se esistone e formattale
    function formatDate(inputDate) {
        if(!inputDate) return ''; // Se la data non esiste, ritorna una stringa vuota
        
        const date = new Date(inputDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi vanno da 0 a 11, quindi aggiungiamo 1
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    }

    let initialDate = formatDate(el.fromDate)
    let finalDate = formatDate(el.toDate)
    
    //controlla se è unità o owner
    const reportType = el?.unit?.length > 0 ? "unit" : "owner"
    const reportName = reportType == "unit" ? el?.unit?.[0]?.name : `${el?.owner?.[0]?.surname + " " + el?.owner?.[0]?.name}`
    
    //se unità prendi indirizzo
    let getAddress = (unit) => {
        let address = unit?.address || '';
        if (unit.houseNumber) {
            address += ' ' + unit.houseNumber;
        }
        if (unit.scale) {
            address += ', Scala ' + unit.scale;
        }
        if (unit.internal) {
            address += ', Interno ' + unit.internal;
        }
        return address.trim();
        }

    const reportUnitAddress = reportType == "unit" ? getAddress(el.unit?.[0]) : ''

    //CALCOLO TOTALI (spettanze gestore con iva + total gestione)
    
	const total = el.totalExpenses?  el.totalExpenses - (el.managerCompetence < 0 ? el.managerCompetence * -1 : el.managerCompetence ) : 0;

	//SE OWNER - TROVO SPESE RIPARTITE PER UNITA'

	const unitàConSpese = {};

	if(el.movement){
		for (const spesa of el.movement) {
		const { unit } = spesa;
        if (unit && unit.id) {
            if (!unitàConSpese[unit.id]) {
            unitàConSpese[unit.id] = [];
            }
            unitàConSpese[unit.id].push(spesa);
        } else if (spesa.amount) {
            if (!unitàConSpese['altro']) {
            unitàConSpese['altro'] = [];
            }
            unitàConSpese['altro'].push(spesa);
        }
	}}


	var sumTaxable = el.movement?.length>0 && el.movement?.reduce(function(total, obj) {
	if (obj.type === "subAccount") {
	  return total + obj.taxable;
	}
	return total;
  	}, 0);
  
  console.log(sumTaxable);
	const imponibileGestione = sumTaxable ? sumTaxable : 0

    return (
	<Document>
		<Page size="A4" style={styles.body}>
            { (initialDate && finalDate) && <Text style={styles.subtitle}>Dal {initialDate} al {finalDate}</Text> }
			<Text style={styles.title}>Rendiconto</Text>
			<Text style={styles.author}>{reportName}
            {reportType == "unit" && ` - ${reportUnitAddress}`}</Text>
			<View style={styles.table}>
				<HeaderRow columns={columns} styles={styles}/>

				{el.movement?.length>0 && el.movement?.map((item, i) => {
				if (item.type === 'account') {
					return <AccountRow key={i} name={item.name} columns={columns} styles={styles}/>;
				} else if (item.type === 'subAccount') {
					return <SubAccountRow key={i} {...item} columns={columns} styles={styles}/>;
				}
				})}
			</View>

			<View style={styles.tableTotal}> 

				<View style={[styles.tableRowTotal]}> 
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>Imponibile Gestione:</Text> 
					</View>
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>€ {imponibileGestione}</Text> 
					</View>
        		</View>

				<View style={[styles.tableRowTotal]}> 
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>Totale Gestione <br/> (Imponibile + IVA): </Text> 
					</View>
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>€ {el.totalExpenses?.toFixed(2)}</Text> 
					</View>
        		</View>

				<View style={styles.tableRowTotal}> 
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>Spettanze Gestore:</Text> 
					</View>
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>€ {el.managerCompetence?.toFixed(2)}</Text> 
					</View>
        		</View>

				<View style={[styles.tableRowTotalFinal, styles.tableHeader]}> 
					<View style={styles.tableColTotal}> 
						<Text style={styles.tableCell}>Totale:</Text> 
					</View>
					<View style={styles.tableColTotal}> 
						<Text style={[styles.tableCell, { fontFamily: 'Biryani' }]}>€ {total ? total.toFixed(2) : 0}</Text> 
					</View>
        		</View>
			</View>


            {/* if reporttype == owner, add a table for each unit associated with this user */}

			
		</Page>
        {/* se proprietari - dettagli di ciascuna unità ,ognuno in una sua pagina*/}
		{reportType == "owner" && Object.entries(unitàConSpese).map(([unitId, speseUnita]) => {
        const totalAmount = speseUnita.reduce((total, spesa) => total + spesa.amount, 0);

        return (
			<Page key={unitId} size="A4" style={styles.body}>
				<View>
					<Text style={styles.titleUnit}>
						{unitId === 'altro' ? 'Altro' : `${speseUnita?.[0].unit.name} - ${speseUnita?.[0].unit.address}, ${speseUnita?.[0].unit.municipality} `}
					</Text>
					<View style={styles.table}>
						<HeaderRow styles={styles}/>
						{speseUnita.map((spesa, i) => (
						<SubAccountRow key={i} {...spesa} styles={styles}/>
						))}
					</View>
				</View>

				<View style={styles.tableTotal}> 
					<View style={[styles.tableRowTotalFinal, styles.tableHeader]}> 
						<View style={styles.tableColTotal}> 
							<Text style={styles.tableCell}>Totale:</Text> 
						</View>
						<View style={styles.tableColTotal}> 
							<Text style={styles.tableCell}>€ {totalAmount?.toFixed(2)}</Text> 
						</View>
					</View>
				</View>
			</Page>
			);
		})}
	</Document>
)}


export default MyDocument