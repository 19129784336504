import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, {
	supplier_endpoint,
	bank_account_endpoint,
	supplier_bank_account_assignment_endpoint,
	BASE_URL 
} from "../../../../api/api.js";
import "../../../../assets/css/Membri.css";
import cardimage from "../../../../assets/images/allegati-ph.svg";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard";
import { useNavigate } from "react-router-dom";
import ButtonRm from "../../../ButtonRm.js";
import { PropriText } from "../../../../pages/Proprietari.js";
import axios from "axios";
import mimeTypes from "../../../../allegatiType.js";


export default function Allegati(props) {


	//PROPS.FORM DATA => membri >fornitori > create_mode >TabForm.js
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const inputRef = useRef(null);

	const setFileName = (index, new_name) => {
		let temp = [...props.formData.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "attachment", value: temp } };
		props.setFormData({
			...props.formData,
			ele,
		});
	};
	
	const deleteFile = (index) => {
		console.log("Before deletion:", props.formData.attachment);
		let temp = [...props.formData.attachment];
		temp.splice(index, 1);
		console.log("After deletion:", temp);

		let ele = { target: { name: "attachment", value: temp } };
		props.setFormData({
			...props.formData,
			attachment: temp,
		});
	};

	const deleteApi = (id, name) => {
		api()
		.delete(`${supplier_endpoint}remove/${id}/${name}`)
		.then((res) => {
			console.log("delete attachment");
			return api().get(`${supplier_endpoint}${id}`); // Eseguo una GET per ottenere i dati aggiornati
		})
		.then(updatedSupplier => {
			// Indirizza l'utente alla vista dettagliata utilizzando navigate
			navigate(`/fornitori-view/view/${id}`);
		})
		.catch((error) => {
			console.error("Failed operation:", error);
		});
	}

	const handleChange = (e) => {
		let temp = [...props.formData.attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		let ele = {
			target: { name: e.target.name, value: temp },
		};
		console.log(ele)
		props.setFormData({
			...props.formData,
			attachment: temp,
		});
	};

	const ModifyFileName = (value) => {};

	const btnSaveClicked = () => {

		//SE TUTTI I DATI MANDATORY SONO STATI INSERITI FAI POST
		//SE DATI MANCANTI MESSAGGIO DI ERRORE

		const data = {...props.formData}

		const attachment = [...data.attachment]

		delete props.formData.bankAccounts
		delete props.formData.attachment
		delete data.attachment

		//gestione allegati

		let formDataToSend = new FormData();

		attachment.forEach((file) => {
			const blob = new Blob([file]);
			formDataToSend.append("parts", blob, file.name);
			//formDataToSend.append("parts", file, file.name);
		});
		
		let sentUpload = formDataToSend.get("parts") !== null;

		if(!props.formData.businessName ||
			!props.formData.category ||
			!props.formData.name ||
			!props.formData.surname ){
			toast.error("Dati mancanti nello step Anagrafica", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		} else if (!props.formData.officeAddress ||
			!props.formData.officeHouseNumber ||
			!props.formData.officeProvince ||
			!props.formData.officeMunicipality 
			){
				console.log(props.formData.officeAddress,
					props.formData.officeHouseNumber,
					props.formData.officeProvince,
					props.formData.officeMunicipality,
					props.formData.correspondenceAddress,
					props.formData.correspondenceHouseNumber,
					props.formData.correspondenceProvince,
					props.formData.correspondenceMunicipality)
				toast.error("Dati mancanti nello step Recapiti", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000,
					hideProgressBar: false,
				});
				return
		} else {
			api()
			.post(supplier_endpoint, data)
			.then((res) => {
				if (res.data.success === true) {
					toast.success("Fornitore creato!", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000,
						hideProgressBar: false,
					});
				}

				data.bankAccounts.forEach((bankAccount) => {
					api()
						.post(bank_account_endpoint, {
							...bankAccount,
						})
						.then((bankRes) => {
							api().post(supplier_bank_account_assignment_endpoint, {
								supplierID: res.data.id,
								bankAccountID: bankRes.data.id,
							});
						})
				});

				//INVIA ALLEGATI
				if (sentUpload) {
					api().put(`${supplier_endpoint}upload/${res.data.id}`, formDataToSend);
				}

				(navigate(-1))
			})
			.catch((err) => {
				toast.error("Errore nella creazione del fornitore! " + err, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000,
					hideProgressBar: false,
				});
			});
		props.setShow(false);
		}
	};

	const handleClose = () => setShow(false);

	/* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname

	const downloadFile = async (name) => {
		const fileUrl = `${BASE_URL}api/v1/supplier/download/${props.formData.id}/${name}`;
	
		const response = await axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${
			JSON.parse(localStorage.getItem("user"))?.token
			}`,
			"Content-Type": `application/json`,
		},
		});
	
		// ottieni l'estensione del file
		const fileExtension = name.split('.').pop();
	
		const mimeType = mimeTypes[fileExtension];
	
		// crea un nuovo Blob con il tipo MIME corretto
		const file = new Blob([response.data], { type: mimeType });
	
		// crea un URL per il Blob
		const fileURL = URL.createObjectURL(file);
	
		// scarica il file
		window.open(fileURL);
	};

	return (
		<>
			<div
				className="d-block w-100 mb-3 bg-white"
				style={{ borderRadius: "16px" }}>
				<Form>
					<ToastContainer />
					<div className="form-item-align-box d-block pt-5 pb-5">
						<div className="row gy-4">
							<div className="col-12">
							<div className="unit-btn-group mb-4">
								<label
									type="button"
									htmlFor="input-file"
									className="general-btn white-btn text-center">
									Seleziona
								</label>
								<input
									type="file"
									id="input-file"
									className="d-none"
									multiple={true}
									name="attachment"
									onChange={handleChange} 
								/>
							</div>
							</div>

							{props.formData.attachment?.length > 0 ? (
								<>
									{props.formData.attachment.map((item, index) => {
										return (
											<FileCard
												key={index}
												name={item.name? item.name : item}
												setFileName={setFileName}
												deleteFile={path == "/fornitori/create"? deleteFile : deleteApi}
												id={props.formData.id ? props.formData.id  : index}
												download={downloadFile}
											/>
										);
									})}
								</>
							) : (
								<div className="unit-empty-img">
									<img loading="lazy" src={cardimage} alt="" />
									<span className="img-comment">
										Non hai aggiunto ancora alcun allegato
									</span>
								</div>
							)}

							{path == "/fornitori/create" &&  <div className="col-12">
								<div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
								<ButtonRm variant="tertiary" onClick={() => props.back()} className="me-2">Indietro</ButtonRm>
								{props.createMode && (
									<ButtonRm variant="primary" onClick={() => {
										btnSaveClicked()}}>Salva</ButtonRm>
								)}
								</div>
							</div>}
						</div>
					</div>
				</Form>
			</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Body className="p-5">
					<div className="d-flex flex-column">
						<h2 className="fs-32 text-dark fw-semibold mb-3">
							Rinomina documento
						</h2>
						<Form className="form-item-align-box">
							<div className="d-flex flex-column mb-3">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Name*
								</label>
								<input
									type="text"
									value="file"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/>
							</div>
						</Form>
						<button
							type="button"
							className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white">
							Rinomina
						</button>
						<button
							type="button"
							className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
							onClick={() => handleClose()}>
							Annulla
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
