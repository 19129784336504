import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import dashboard from '../../assets/images/attori.png'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import home from "../../assets/images/home-1.png"
import disc from "../../assets/images/discount1.svg"

import casa from "../../assets/images/casa+pin.png"


import sfondo from "../../assets/images/sfondo-10.png"

import logo from "../../assets/images/Group 1189.svg"

import { Link } from 'react-router-dom';

import Footer from './Footer';


export default function Proprietario (){
    return (
        <div style={{background:'#fff'}}>
            <div style={{background: 'linear-gradient(180deg, #E6F0FF 0%, rgba(230, 240, 255, 0) 100%)', backgroundImage: `url(${sfondo})`, backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
                <Container className="px-3 px-md-0">

                    <Navbar bg="" data-bs-theme="light" className='d-flex align-items-center pt-3' style={{background: "transparent"}}>
                    <Navbar.Brand href="#" className='me-3'>
                            <Image src={logo} width={"85px"}/>
                        </Navbar.Brand>
                        <Nav className="me-auto bg-alert w-100 d-flex align-items-center">
                      
                            
                            <div className='ms-auto d-flex align-items-center'>
                            <Link to="/landing">
                                <Button variant="outline-primary me-3" style={{color: "#0F1B4C", borderColor: "#0F1B4C"}}>Sei un gestore?</Button>
                            </Link>
                            </div>
                        </Nav>
                    </Navbar>

                    <Row className="flex-column mb-5">
                        <Col className="d-flex justify-content-center" style={{padding: "5rem 0rem 4rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center mb-4 fs-40 fs-lg-50 syne-font">Rivoluziona la Gestione delle tue proprietà con Real Meta</h1>
                               {/*  <p className='text-center mb-4'>Real Meta è la soluzione cloud che semplifica la comunicazione con il tuo property manager. 
Resta aggiornato su tutti gli eventi importanti che coinvolgono la tua proprietà.</p> */}

{/* <p>Iscriviti gratuitamente e ottieni una vetrina online per mostrare il tuo portfolio e i tuoi servizi</p>

 */}                                <Link to="/suggerimenti" className='position-relative'><Button size="lg" variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} >Suggerisci Real Meta al tuo gestore</Button>
 <div style={{position: "absolute", right: "36%" }}>
    <Image src={disc} width="80px" height="80px" />
 </div>
 </Link>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={5}>
                            <h2 className="syne-font">Monitora gli interventi in corso</h2>
                            <p>Potrai visionare, approvare e gestire (attraverso foto, video, audio, pin sulle planimetrie..)  l’avanzamento dei tuoi lavori. 
Verbalizzando e gestendo tutti gli attori che intervengono comodamente dal tuo divano.

                            </p>
                        </Col>
                        <Col md={5}>
                            <Image src={casa} rounded className='w-100'  />
                        </Col>
                    </Row>

                    <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={5} className="order-2 order-md-1 my-3">
                            <Image src={dashboard} rounded className='w-100' />
                        </Col>

                        <Col md={5} className="order-1 order-md-2">
                            <h2 className="syne-font">Comunica con facilità </h2>
                            <p>
                            Puoi pagare, verbalizzare, firmare.. in maniera gratuita tutte le mansioni in cui il tuo gestore ti coinvolge.
                            </p>
                        </Col>
                    </Row>


                    <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={5}>
                            <h2 className="syne-font">Rimani aggiornato, ovunque tu sia</h2>
                            <p>Puoi tenere traccia quotidiana dell’andamento del valore dei tuoi immobili, vedere le spettanze dovute ed altro ancora.
                            </p>
                        </Col>
                        <Col md={5} className="d-flex justify-content-end">
                            <Image src={home} rounded className='w-100'/>
                        </Col>
                    </Row>

                    
                    <Row className="mt-5 justify-content-center align-items-center mx-auto px-3 py-5 p-md-5" style={{maxWidth: "1100px", background: "#0F1B4C", borderRadius: "1rem", color:"white"}}>
                        <Col md={12} className="text-center">
                            <h2 className="syne-font">Desideri maggiori informazioni?</h2>
                            <p className='mt-2 mb-3'>Se hai dubbi o hai bisogno di chiarimenti in merito ai nostri servizi non esitare a contattarci</p>
                            <Link to="/contatti">
                            <Button variant="primary" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}}>Contattaci</Button>
                            </Link>
                        </Col>
        
                    </Row>


                    <Footer />

                   

                </Container>
            </div>
        </div>
    )
}