import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setShowEnterCode } from "../../../actions/ModalForEnterCode";
import { setShow } from "../../../actions/ModalForVerify";
import "../../../assets/css/Login.css";
import ErrorIcon from "../../../assets/images/input_error.svg";
import LeftPanel from "../../LeftPanel";
import ToggleSwitch from "../../ToggleSwitch";
import ModalForEnterCode from "../register/ModalForEnterCode";
import ModalForVerify from "./ModalForVerify";

import Lottie from "lottie-react";
import logo from "../../../assets/images/logoLottie.json";
import { BASE_URL } from "../../../api/api";

const Login = ({ show }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [codice, setCodice] = useState("");

  const [error, setError] = useState(false);
  const [readyLogin, setReadyLogin] = useState(false);
  const [ready, setReady] = useState(false);
  const { email, password } = formData;

  // const validationSchema = () => {
  //   return Yup.object().shape({
  //     email: Yup.string().required("Campo Obbligatorio!"),
  //     password: Yup.string().required("Campo Obbligatorio!"),
  //   });
  // }

  // const handleLogin = (email, password) => {
  //   setMessage('');
  //   setLoading(true);

  //   //Finire la gestionedelle chiamate per il login
  //   AuthService.login(email, password).then(
  //     () => {
  //       handleShow();
  //       /* this.props.history.push("/profile");
  //       window.location.reload(); */
  //       //Dovra' diventare la dashboard
  //       /* let navigate = useNavigate();
  //       navigate("/profile", { replace: true }); */
  //     },
  //     (error) => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();

  //         setLoading(false);
  //         setMessage(resMessage);
  //     }
  //   );
  // }

  // const handleValueCodice= (code) => {
  //   setCodice(code);
  //   /*  AuthService.signUpVerify() */
  //   AuthService.loginVerify(email, password, code).then(
  //     (response) => {
  //       console.log("response:", response.data);
  //       if (response.data?.return_value) {
  //         dispatch(setShow(false));
  //         navigate("/", { replace: true });
  //         //Redirect alla dashboard
  //       } else {
  //         //Errore nel form, gestirlo in qualche maniera
  //       }
  //     },
  //     (error) => {
  //       const resMessage =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();

  //       setMessage(resMessage);
  //     }
  //   );
  // }

  const { mode } = useParams();
  const [invitationCode, setInvitationCode] = useState('')
  const [invitationEmail, setInvitationEmail] = useState('')


  useEffect(() => {
    if (formData.email.length === 0 || formData.password.length === 0) {
      setReadyLogin(false);
    } else {
      setReadyLogin(true);
    }
  }, [formData]);

  const handleShow = () => dispatch(setShow(true));

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPassword = (password) => {
    if (password.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {

    if (!isValidEmail(formData.email) || !isValidPassword(formData.password)) {
      setError(true);
    } else {
      setError(false);
      handleShow();
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}api/v1/auth/login`, {...formData/* , invitation_code: invitationCode */})
      .then((res) => {
        setLoading(false);
        if (res.data.success === true) {
          setReady(true);
        } 
      })
      .catch((err) => {
        // toast
        setLoading(false);
        toast.error("I dati inseriti non sono corretti", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
    // }

    e.preventDefault();
  };

  const create = () => {
   navigate("/chooseRole")
  };

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [showLoader, setShowLoader] = useState(false)




  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search)
    //prendo codice di invito e email
    const invitation = params.get('invitation_code');
    const invitation_email = params.get('invitation_email');
    if(invitation && invitation_email){
      setInvitationCode(invitation ? invitation : '' ); 
      setInvitationEmail(invitation_email ? invitation_email : '')
      setFormData({ ...formData, ['email']: invitation_email });
      localStorage.setItem("invitation_code", JSON.stringify(invitation));
    }

    if(invitation && invitation_email){
      // Rimuovere il parametro 'invitation_code' dall'URL
      params.delete('invitation_code');

      // Rimuovere il parametro 'invitation_email' dall'URL
      params.delete('invitation_email');

      window.history.replaceState({}, document.title, url.pathname /* + '?' + params */);
    }



  }, []);

   console.log({
    invitationCode,
    invitationEmail
   })



  return (
    <div>
    
        {showLoader && <div className="loader123" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#f5f5f5', // Sfondo semitrasparente
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999 // Alziamo il livello dello zIndex per sovrapporlo ad altri elementi se necessario
        }}>
          <Lottie animationData={logo} />
        </div>}
      
      <div className="mobile-background">
        <div className="logo-mobile-div"></div>
        <div className="d-flex login-div" style={{ background: "#fff" }}>

          <LeftPanel />
          <div className="rightPanel py-md-5">
            <div>
              <div className="welcome-title">Bentornato</div>
              <div className="welcome-description">
                Effettua il login per accedere alla tua area personale
              </div>
              <div
                className="fs-20 m-auto"
                style={{ paddingTop: "15px", paddingBottom: "10px",  }}
              >
                Login
              </div>
              {error && (
                <div className="error-div">
                  <img
                    src={ErrorIcon}
                    alt="Input error"
                    style={{
                      height: "17.69px",
                      width: "20.53",
                      paddingLeft: "20px",
                      paddingRight: "10px",
                    }}
                  />
                  <div>L’email o la password inserita non è corretta</div>
                </div>
              )}
              <div className="subtitle">Email</div>
              <form className="form" onSubmit={onSubmit} >
                <ToastContainer />
                <input
                  autoComplete="on"
                  type="email"
                  placeholder="Inserisci la tua email"
                  name="email"
                  value={email}
                  disabled={invitationEmail ? true : false}
                  onChange={onChange}
                  id={error ? "input_error" : ""}
                  className="form-control"
                  style={{ fontFamily: "Mulish", height: "50px" }}
                />
                <div className="subtitle">Password</div>
                <div style={{ position: "relative" }}>
                  <input
                    autoComplete="on"
                    type={passwordType}
                    placeholder="Inserisci la tua password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    minLength="4"
                    id={error ? "input_error" : ""}
                    className="form-control"
                    style={{
                      marginBottom: "20px",
                      fontFamily: "Mulish",
                      height: "50px",
                    }}
                  />
                  <div onClick={togglePassword} className="passwordHideToggle">
                    {passwordType === "password" ? <BsEye /> : <BsEyeSlash />}
                  </div>
                </div>
               {/*  <ToggleSwitch label="Ricordami su questo computer" value={rememberUser} setRememberUser={setRememberUser}/> */}
                <input
                  type={readyLogin ? "submit" : "button"}
                  id={readyLogin ? "" : "disable-login"}
                  className="login-btn"
                  value="Accedi"
                />
              </form>
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  paddingTop: "30px",
                }}
              >
                <Link to="/recover" className="reset-password">
                  Hai dimenticato la password?
                </Link>
              </div>
              <div
                className="row"
                style={{ justifyContent: "center", paddingTop: "30px" }}
              >
                <hr className="col-4" style={{ marginTop: "12px" }}></hr>
                <div
                  className="col-3"
                  style={{ textAlign: "center", color: "#696F79" }}
                >
                  Oppure
                </div>
                <hr className="col-4" style={{ marginTop: "12px" }}></hr>
              </div>
              <Link to="/chooseRole">
                <div
                  className="createAccount-btn"
                  style={{ color: "#000000" }}
                  onClick={() => create()}
                >
                  Crea il tuo account
                </div>
              </Link>
              {ready && (
                <div>
                  <ModalForVerify loginInfo={formData}  setShowLoader={setShowLoader} setReady={setReady} />
                  <ModalForEnterCode mode={mode} />
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  show: state.ModalForVerify.show,
});

export default connect(mapStateToProps, { setShow })(Login);
