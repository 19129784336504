import {Col, Row, Modal, Form } from "react-bootstrap"
import Alert from "react-bootstrap/Alert";

import React, { useEffect, useState, useRef, useMemo } from "react";
import useOnClickOutside from "../useOnClickOutside";
import {chat_msg_endpoint, 
        chat_confirm_seen_endpoint, 
        chat_confirm_seen_association_endpoint,
        chat_confirm_content_endpoint,
        chat_confirm_content_association_endpoint
        } from "../../../api/api";
import api from "../../../api/api"

import ButtonRm from "../../ButtonRm";

import ImgFileCard from "../../UnitàImmobiliariStabilli/UnitàImmobiliari/ImgFileCardChat";

import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import {
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";

  import { Elements } from "@stripe/react-stripe-js";
import Payment from "./ChatMsg/Payment";
import Preventivo from "./ChatMsg/Preventivo/Preventivo";
import Verbale from "./ChatMsg/Verbale/Verbale";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { MultiSelect } from "react-multi-select-component";

import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import EventMsg from "./ChatMsg/Evento/EventMsg";
import TagChat from "./ChatMsg/TagChat";

const stripePromise = loadStripe('pk_test_51OhUnVGHFxsJG3MhHK3fieznBZaXAz4eWf98VCp3p0DHdZxa2Rx3ocCxIkHwATgfmeAVsARXlmA9SDHOB8iKNUTG00ycysJFyM');

const ChatMsg =  React.forwardRef((props, ref) => {

/*     const stripe = useStripe();
    const elements = useElements(); */

    console.log(props.item.signs.length)
    
    const isContentEmpty = Object.keys(props.item.content).length === 0;

    const parsedContent = props.item?.messageType == "verbale" || props.item?.messageType == "preventivo" || props.item?.messageType == "event" || (props.item?.messageType == "paymentMsg" && !props.item.message) || props.item?.messageType == "spesa" ?  
    JSON.parse(props.item.content?.replace(/'/g, '"')) : null
    
    const parsedContentKey= parsedContent? Object.getOwnPropertyNames(parsedContent) : []

    //MESSAGE DROPDOWN OPTIONS
    const [showChatOptions, setChatOptions] = useState(false)

    //DELETE MODAL
    const [showModal, setShowModal] = useState(false)

    //FORWARD MODAL
    const [ showModalForward, setShowModalForward] = useState(false)

    //APRI/CHIUDI OPZIONI
    function handleOptions() {
        setChatOptions((prev) => !prev);
    }

    //REF PER OPZIONI 
    const dropRef = useRef(); //cliccando fuori questo elemento si chiude dropdown
    const optionRef = useRef(); //anche cliccando fuori questo elemento si chiude dropdown

    function handleDropDown(value) {
        if (showChatOptions) {
            setChatOptions(value);
        }
    }

    //prende 3 parametri
    //1. elemento target, ovvero il dropdown
    //2. azione da svolgere quando clicco fuori dall'elemento target
    //3. elemento che chiude dropdown se viene cliccato (facoltativo)
    useOnClickOutside(dropRef, () => handleDropDown(false), optionRef);

    //COPY
    const messageToCopy = `${props.item.message}, UserID: ${props.item.userID}, Data: ${props.item.cdat?.substr(0, 10).split("-").join("-")} `

    function handleCopy(){
        if(!props.item.deleted){
            navigator.clipboard.writeText(messageToCopy)
        }
        handleOptions()
    }

    //EDIT
    async function editItem(data) {
        try {
        await api().put(`${chat_msg_endpoint}${props.item.id}`, data);
        await props.getOne(props.chatId)
        } catch (error) {
        console.log(error);
        }
    }

    //DELETE
    function handleDelete(e){
        e.preventDefault()
        const body = {...props.item, deleted: true, content: "no content", messageType:"text"}
        editItem(body)
        setShowModal(false)
    }

    //EDIT
    //1.clicco sul messaggio e props.item.message di quel messaggio diventa la value di textarea

    function onEdit(e){
        e.preventDefault()
        console.log("editaando")
        if((props.item.messageType != 'verbale' && props.item.messageType != 'preventivo' && props.item.messageType!= 'spesa') && !props.item.deleted){
            props.handleEdit(props.item.id)
        } else if (( props.item.messageType == 'preventivo')){
            console.log("modifica tipi particolari")
        }
        handleOptions()
    }

    function handleCheckbox(){
        let array 
        if(props.selectedIds.includes(props.item.id)){
            array = props.selectedIds.filter(item => item !== props.item.id)
            props.setSelectedIds(array)
        } else {
            props.setSelectedIds(prev => [...prev, props.item.id])
        }
    }


    //CHIUDI DROPDOWN OPZIONI, SE SIAMO IN MODALITà
    useEffect(() => {
        if(props.selectMsg){
            setChatOptions(false)
        }
    }, [props.selectMsg])


    //REPLY MESSAGGE
    let replyObj = props.item.replyID ? props.chat.chats.find(item => item.id===props.item.replyID) : {}



//GESTIONE PRESA VISIONE
    //CREATE-ASSOCIATIONE
    async function postChatSeenAssociation(data){
        try {
            const response = await api().post(`${chat_confirm_seen_association_endpoint}`, data);
            if(response.data){
                await props.getOne(props.chatId);
            }
        } catch (error) {
        console.log(error);
        }
    }

    //CREATE
    async function postChatSeen(){
        try {
            const response = await api().post(`${chat_confirm_seen_endpoint}`, {});
            if(response.data?.id){
                postChatSeenAssociation({chatID:props.item.id, confirmSeenID:response.data.id})
            }

        } catch (error) {
        console.log(error);
        }
    }


    //GET DA SPOSTARE

  /*   const [presaVisione, setPresaVisione] = useState([])

    async function getPresaVisione(){
        try {
        const response = await api().get(`${chat_confirm_seen_association_endpoint}`);
            if (response.data.content) {
                setPresaVisione(response.data.content)
            }
        } catch (error) {
        console.log(error);
        }
    } */

    //MOSTRARE CHI L'HA VISTO
    //SE USER ID CHE HA VISTO è QUELLO DELL'USER LUI VEDRA SCRITTA
    //SE USER ID che ha visto != vedrà checkbox 
  /*   const isThisMsgSeen = useMemo(() => props.item.messageType == "verbale" ||  props.item.messageType == "preventivo" && presaVisione.length > 0 ? presaVisione.filter(item => item.chatID == props.item.id) : [], [presaVisione])

    const isThisMsgSeenByMe = isThisMsgSeen && isThisMsgSeen.length > 0 ? 
    isThisMsgSeen.filter(item => item.userID == JSON.parse(localStorage.getItem('user')).id ) : [] */
    
    const [verbalCheckbox, setVerbalCheckbox ] = useState(false)

    function handleVerbalCheckbox(){
        if(!verbalCheckbox){
            setVerbalCheckbox(true)
            postChatSeen()
        }
    }

    const [acceptBtn, setAcceptBtn] = useState(false)
    const [declineBtn, setDeclineBtn] = useState(false)

    function handleAccept(){
        setAcceptBtn(true)
    }

    function handleDecline(){
        setDeclineBtn(true)
    }


    /* useEffect(() => {
        getPresaVisione()
    }, [verbalCheckbox]) */



    //PREVENTIVO TOTAL COST
    function getTotal(){
        let preventivoTotal
        let sum
        if(props.item.messageType == "preventivo"){
            preventivoTotal = parsedContent?.map(el => {
                return el.rows.map(row => {
                    return row["Nome"] && parseInt(row["Quantità"]) * parseInt(row["Costo unitario"])
                } )
            })
            sum = preventivoTotal.flat().reduce((accumulator, currentValue) => accumulator + currentValue, 0)
            return sum
        }
    }

    //ACCETTA RIFIUTA

    //GESTIONE PRESA VISIONE
    //CREATE-ASSOCIATIONE
    async function postChatConfirmAssociation(data){
        try {
            const response = await api().post(`${chat_confirm_content_association_endpoint}`, data);
            if(response.data){
                await props.getOne(props.chatId);
            }
        } catch (error) {
        console.log(error);
        }
    }

    //CREATE
    async function postChatConfirm(value){
        try {
            const response = await api().post(`${chat_confirm_content_endpoint}`, {accepted: value});
            if(response.data?.id){
                postChatConfirmAssociation({chatID:props.item.id, confirmContentID:response.data.id})
            }

        } catch (error) {
        console.log(error);
        }
    }

    //GESTIONE FUNZIONE INOLTRO/FORWARD

    const forwardInfo = {
        type: "existingChat",
    }

    const [forwardData, setForwardData] = useState(forwardInfo)

    function handleForwardChange (e){
        const {value, name} = e.target
        setForwardData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }



    //GET LIST OF PARTECIPANTS

    const [partecipantList, setPartecipantList] = useState([])

    async function getPartecipants() {
        try {
            const response = await api().get(`${'/api/v1/invitation/getTeam'}`);
            if (response.data ) {
                setPartecipantList(response.data.members);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getPartecipants()
    }, [])

    const [selected, setSelected] = useState([]);
    const [showSelectedItem, setShowSelectedItem] = useState(false)
    const partecipanti = partecipantList.map(user => {
        return {
                key: user.id, 
                label:`${(user.name && user.surname )? `${user.name} ${user.surname}` : ""} <${user.email}>`, 
                value: user.email
            }
    })

     /* PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE */
     const overrideString = { 
        "selectAll": "Seleziona tutti",
        "search": "Cerca o Crea nuovo",
        "selectSomeItems": "Seleziona",
        "allItemsAreSelected": "Tutti gli elementi sono selezionati",
        "create": "Crea nuovo"
    }

    const [ checkboxForward, setCheckboxForward ] = useState(false)
    
    function handleCheckboxForward(e){
        const {checked} = e.target
        setCheckboxForward(checked)
    }

    return (
        <div key={props.item.id} className="msg-sender d-flex my-3" id={props.item.id} >
            {   props.selectMsg &&
                <div className="msg-chk slect-chat">
                    <label>
                        <input type="checkbox" checked={props.isChecked ? true : false} onChange={handleCheckbox}/>
                        <span></span>
                    </label>
                </div>
            }
            <div className="d-flex align-items-end ms-auto w-100">
                {/* per distinguere msg sent from received */}
                {/* <div className={`card ${item.messageOfTheUser ? "order-0 ms-5": " order-1 me-5"} `} > */}
                <div className="card order-0 ms-auto" style={{minWidth: "50%"}}>
                    {/* MESSAGE HEADER*/ }
                    {/* {item.messageOfTheUser ? */}
                    <div className="msg-head d-flex align-items-center justify-content-between">
                        <p className="fs-12 me-4">ID {props.item.userID}, {props.item.cdat?.substr(0, 10).split("-").join("-")}</p>
                        <div className="msg-sendername d-flex align-items-center">
                            <p className="text-uppercase fs-12">{props.chat.members?.[0]?.name} {props.chat.members?.[0]?.surname} </p>
                            <div className="badge-crclname ms-2">
                                <span className="text-uppercase">{props.chat.members?.[0]?.name?.[0]}{props.chat.members?.[0]?.surname?.[0]}
                                </span>
                            </div>
                        </div>
                    </div> 
                    {/*    DA utilizzare x distinguere msg sent from received
                    <div className="msg-head d-flex align-items-center justify-content-between">
                        <div className="msg-sendername d-flex align-items-center">
                            <div className="badge-crclname me-2">
                                <span>{item.userName.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')}</span>
                            </div>
                            <p className="text-uppercase fs-12">{item.userName}</p>
                        </div>
                        <p className="fs-12">ID{item.userId}, {item.cDat}</p>
                    </div> */}

                    {/* MESSAGE ITEM */}

                   

                    
                    {/* MESSAGE CONTENT */ }
                    <div className="msg-descrp">
                        {
                            (props.item.messageType==="text" ) &&
                            <>
                                {
                                    props.item.replyID !== -1 &&
                                    <div className="reply-resultmsg">
                                        <div className="rply-bg">
                                            <p className="fs-12 mb-2">RISPOSTA A {props.chat.members?.[0]?.name} {props.chat.members?.[0]?.surname} </p>
                                            <p>{replyObj?.message}</p>
                                        </div>
                                    </div>
                                }
                            <p className={`fs-14 ${props.item.deleted && "fst-italic opacity-50"}`}>
                                {
                                    props.item.deleted ?
                                    "Messaggio eliminato":
                                    <>
                                        <p>{props.item.message}</p>
                                        {props.item.attachments.length> 0 && props.item.attachments.map((item, index) => {
											return (
												<div key={index}>
													<ImgFileCard id={props.item.id} name={item} path="download"/>
													{/* <div className="image-div">
														<FileCard
															id={id}
															name={item}
															setFileName={setPhotoFileName}
															deleteFile={deletePhotoFile}
															view_mode={editMode}
															download={downloadFile}

														/>
													</div> */}
												</div>
											);
										})}

                                    </>
                                }
                            </p>
                            {   (props.item.udat && !props.item.deleted && props.item.tag.length == 0) &&
                                <p className={`fs-12 fst-italic opacity-50`}>
                                    Messaggio Modificato
                                </p>
                            }
                            </>
                        }
                        {
                            props.item.messageType==="preventivo" && !props.item.deleted &&

                            <Preventivo 
                                parsedContent={parsedContent}
                                handleOpenFirma={props.handleOpenFirma}
                                item={props.item}
                                chat={props.chat}
                                postChatConfirm={postChatConfirm}
                                verbalCheckbox={verbalCheckbox}
                                handleVerbalCheckbox={handleVerbalCheckbox}
                                getTotal={getTotal}
                                getOne={props.getOne}
                            />

                                
            
                        }
                        {
                            props.item.messageType==="preventivo" && props.item.deleted &&
                            <>
                                <p className={`fs-14 ${props.item.deleted && "fst-italic opacity-50"}`}>
                                {
                                    props.item.deleted ?
                                    "Messaggio eliminato":
                                    props.item.message
                                }
                            </p>
                            </>
                        }
                        {
                            props.item.messageType == "verbale" && !props.item.deleted &&
                            <>
                                <Verbale 
                                    parsedContent={parsedContent}
                                    parsedContentKey={parsedContentKey}
                                    item={props.item}
                                    verbalCheckbox={verbalCheckbox}
                                    handleVerbalCheckbox={handleVerbalCheckbox}
                                    chat={props.chat}
                                    postChatConfirm={postChatConfirm}
                                    handleOpenFirma={props.handleOpenFirma}
                                    getOne={props.getOne} 
                                />
                            </>
                        }
                        {/* STRUTTURA SIMILE PER PAGAMENTO */}
                        {
                            props.item.messageType=== "event" && !props.item.deleted &&
                            <>
                               <EventMsg parsedContent={parsedContent} stripePromise={props.stripePromise} getOne={props.getOne} item={props.item}
                                chat={props.chat}/>
                            </>
                        }
                        {/* STRUTTURA SIMILE PER PAGAMENTO */}
                        {
                            props.item.messageType==="paymentMsg" && !props.item.deleted &&
                            <>
                                <Payment parsedContent={parsedContent} parsedContentKey={parsedContentKey} stripePromise={props.stripePromise} getOne={props.getOne} item={props.item}
                                chat={props.chat}/>
                            </>
                        }
                        {
                            props.item.messageType==="spesa" && !props.item.deleted &&
                            <>
                                <div className="my-3" >
                               

                                <div className="d-flex align-items-center">
                    <p className="text-capitalize fs-14">Spesa</p>
                    <button onClick={() => exportPDF(parsedContent)} className="btn round-icon-btn btn mx-3"><i class="bi bi-download fs-12"></i></button>

                  
                </div>

                                <hr className="my-2"/>

                                <TableContainer style={{ backgroundColor: 'transparent' }}>
                                    {parsedContent.map((el) => (
                                        <React.Fragment key={el.id}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                {/* Assuming "Nome" is one of the keys and you want to create a header for each key */}
                                                {el.rows?.[0] && Object.keys(el.rows[0]).map((key) => (
                                                <TableCell key={key}>{key}</TableCell>
                                                ))}
                                                <TableCell>Tot</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {el.rows?.map((row, i) => (
                                                    <TableRow key={i}>
                                                    {Object.keys(row).map((item, index) => (
                                                        <TableCell key={index}>{row[item]}</TableCell>
                                                    ))}
                                                    <TableCell>
                                                        {parseInt(row["Quantità"]) * parseInt(row["Costo unitario"])}€
                                                    </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        </React.Fragment>
                                    ))}
                                </TableContainer>
                              
                            </div>
                            </>
                        }
                        
                        {
                            props.item.messageType==="verbale" && props.item.deleted &&
                            <>
                                <p className={`fs-14 ${props.item.deleted && "fst-italic opacity-50"}`}>
                                {
                                    props.item.deleted ?
                                    "Messaggio eliminato":
                                    props.item.message
                                }
                            </p>
                            </>
                        }

                        {
                            props.item.messageType == "firma intera"  && 
                            <img loading="lazy" width="300px" height="auto" src={props.item.attachments} className="my-2"/>
                        }
                        {
                            props.item.messageType==="audio"  && 
                            <audio src={props.item.content} className="my-2" controls={true}></audio>
                            
                        }
                    </div>

                    <TagChat parsedContent={parsedContent}  getOne={props.getOne} item={props.item} chat={props.chat} />
                </div>
                {/* /* OPEN MESSAGE OPTIONS */ }
                {/* <div className={`elipsv-icon dropdown ${item.messageOfTheUser ? "order-1": "order-0"} `}> */}
                <div className={`elipsv-icon dropdown order-1`}>
                    <span
                        onClick={() => setChatOptions(!showChatOptions)}
                        className="elipsv-svg dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        ref={optionRef}
                    >
                        <i class="bi bi-three-dots-vertical text-dark"></i>

                    </span>
                    <div className={` drpdown-chatdesrp edit-elpsdrp ${showChatOptions ? "" : "d-none"} py-2`}  ref={dropRef}>
                        {/* <div className={` ${item.messageOfTheUser? "drpdown-chatdesrp": "msg-reciver drpdown-chatdesrp"} edit-elpsdrp`}> */}
                        
                        <ul>
                            { props.item.messageType == "text"  && 
                            <li className="dropdown-item">
                                <a href="#" onClick={handleCopy}>
                                    <span className="drpdn-iocn">
                                        <i class="bi bi-clipboard"></i>
                                    </span>
                                    Copia
                                </a>
                            </li>}
                            <li className="dropdown-item">
                                <a
                                    // onClick={rispondifun}
                                    href="#"
                                    className="respondi-btn"
                                    onClick={() => props.handleReplyMode(props.item.id)}
                                >
                                    <span className="drpdn-iocn">
                                        <i class="bi bi-reply"></i>
                                    </span>
                                    Rispondi
                                </a>
                            </li>
                            <li className="dropdown-item">
                                <a
                                    href="#"
                                    className="respondi-btn"
                                    onClick={() => setShowModalForward(true)}
                                >
                                    <span className="drpdn-iocn">
                                        <i class="bi bi-share"></i>
                                    </span>
                                    Inoltra
                                </a>
                            </li>
                            <li className="dropdown-item">
                                <a
                                    className="slezion-btn"
                                    onClick={() => props.handleSelect(props.item.id)}
                                >
                                    <span className="drpdn-iocn">
                                        <i class="bi bi-check2-circle"></i>
                                    </span>
                                    Seleziona
                                </a>
                            </li>
                            {( props.item.messageType == "text") &&

                             <li className="dropdown-item">
                                <a onClick={onEdit}>
                                    <span className="drpdn-iocn">
                                        <i class="bi bi-pencil"></i>
                                    </span>
                                    Modifica
                                </a>
                            </li>}
                            <li className="dropdown-item pb-2">
                                <a
                                    onClick={() => setShowModal(true)}
                                    className="elimina-btnm"
                                >
                                    <span className="drpdn-iocn">
                                        <i class="bi bi-trash3"></i>
                                    </span>
                                    Elimina
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Modal show={showModal} centered>
                <Modal.Body className="p-5">
                <div className="d-flex flex-column text-center">
                    <h2 className="fs-32 text-dark fw-semibold mb-3">
                    Vuoi eliminare questo elemento?
                    </h2>
                    <h6 className="fs-16 text-dark fw-normal mb-3">
                    Questa azione è irreversibile
                    </h6>
                    <button
                    type="button"
                    className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
                    onClick={handleDelete}>
                    Conferma
                    </button>
                    <button
                    type="button"
                    className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
                    onClick={() => setShowModal(false)}
                    >
                    Annulla
                    </button>
                </div>
                </Modal.Body>
            </Modal>

            <Modal show={showModalForward} centered>
                <Modal.Body className="p-5">
                <div className="d-flex flex-column text-center">
                <h2 className="fs-32 text-dark fw-semibold mb-3">
                    Inoltra messaggio
                </h2>
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <div className="d-flex">
                            <Form.Check
                                inline
                                label="Chat esistente"
                                type="radio"
                                value="existingChat"
                                checked={forwardData.type === "existingChat"}
                                onChange={handleForwardChange}
                                name="type"
                            />
                           
                                <Form.Check
                                    inline
                                    label="Contatto"
                                    type="radio"
                                    value="contact"
                                    checked={forwardData.type === "contact"}
                                    onChange={handleForwardChange}
                                    name="type"
                                />
                            
                        </div>
                    </div>
                </div>

                <div>
                    { 
                    forwardData.type === "existingChat" &&
                        <Form.Group className="mb-3" >
                            <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                            >
                            </Form.Label>
                            <Form.Select 
                            name="managementType"
                            >
                                <option >
                                </option>
                                {
                                    props.chatList.map(el => {
                                        return  <option>
                                            {el.name}
                                        </option>
                                    })
                                }

                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >

                        </Form.Control.Feedback>
                    </Form.Group>
                    }

                { 
                    forwardData.type === "contact" &&
                    <>
                        <Form.Group className="mb-3" >
                            <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                            >
                            </Form.Label>
                            <Form.Select 
                            name="managementType"
                            >
                                <option >
                                </option>
                                <MultiSelect
                                    options={partecipanti}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy="Seleziona"
                                    overrideStrings={overrideString}
                                    isCreatable={false}
                                />

                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >

                        </Form.Control.Feedback>
                        </Form.Group>

                        <Col md={12} className="my-3 mx-0 px-0">
                        <Form.Check 
                            type="checkbox"
                            label="Crea una chat unica con tutti i contatti selezionati"
                            checked={checkboxForward} 
                            onChange={handleCheckboxForward}
                        />
                        </Col>
                    </>
                    }
                </div>
                    <ButtonRm variant="primary" >
                        Inoltra
                    </ButtonRm>
                    <ButtonRm variant="text" onClick={() => setShowModalForward(false)}>
                        Anulla
                    </ButtonRm>
                </div>
                </Modal.Body>
            </Modal>

        </div>
        
    )
    
    }
)

export default ChatMsg




// Funzione per generare il PDF
const exportPDF = (parsedContent) => {
  const doc = new jsPDF();
  doc.text("Spesa", 14, 20);


  parsedContent.forEach((el, index) => {
    if (index > 0) {
      doc.addPage();
    }

    const tableColumn = el.rows?.[0] ? Object.keys(el.rows[0]) : [];
    tableColumn.push("Tot"); // Aggiunge la colonna Tot

    const tableRows = el.rows?.map(row => {
      const rowData = Object.values(row).map(cell => cell.toString());
      rowData.push((parseInt(row["Quantità"]) * parseInt(row["Costo unitario"])).toString() + '€'); // Calcola il totale e lo aggiunge
      return rowData;
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows || [],
      startY: 25,
      theme: 'grid',
                styles: {
                  textColor: [0, 0, 0],
                  lineColor: [0, 0, 0],
                  lineWidth: 0.1,
                  cellPadding: 2,
                },
                headStyles: {
                  fillColor: [131, 17, 47],
                  textColor: [255, 255, 255],
                },
      didDrawPage: function (data) {
        // Aggiungi qui eventuali intestazioni o piedi di pagina
      }
    });
  });

  doc.save("report.pdf");
};
