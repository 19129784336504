import PropTypes from 'prop-types';
import "../../assets/css/PriorityTag.css";

export default function PriorityTag({priority, size='', ...props }){

    return (
        <div className={`priorityTag ${size} ${priority }`} {...props} >
            {priority === 'low' && <p>Priorità Bassa</p>}
            {priority === 'medium' && <p>Priorità Media</p>}
            {priority === 'high' && <p>Priorità Alta</p>}
        </div>
    )
}

PriorityTag.propTypes = {
    priority: PropTypes.oneOf(['low', 'medio', 'high']),
    size: PropTypes.oneOf(['mini'])
}