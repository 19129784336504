import React, { useEffect, useState, useRef } from "react";
import { NewChatgroupPopup } from "./NewChatgroupPopup";
import AddBtn from "../../AddBtn";

import ModalCheckUser from "../../ModalCheckUser";
import { checkUserData } from "../../../utils/checkUserRole";

//CONTIENE NOME, BOTTONE, MODALE CREAZIONE NUOVE CHAT

export const ChatTopsec = (props) => {
     //CHECK IF DATA REQUIRED ARE COMPILED
 const [dataRequired, setDataRequired] = useState({});
 //Gestisce il modale
 const [showCheck, setShowCheck] = useState(false)


 useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await checkUserData();
            setDataRequired(response);
            if (!response.isVerified) {
            setShowCheck(true);
        } else if (!response.isVerified /* && !response.hasAccountConnect */) {
            setShowCheck(true);
        }
        } catch (error) {
            // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
            console.error('Errore durante il recupero dei dati utente:', error);
        }
    };

     fetchData();
 }, []);

    return (
        <>
        <div className="top-sec mt-md-4">
            <h2 className="text-center">Chat</h2>
            <AddBtn
                tooltip="Nuova chat"
                className="me-3 me-md-0 order-md-1"
                onClick={() => props.setChatPopup(!props.chatCreatePopup)}
                dataRequired={dataRequired}
            />
                {
                    props.chatCreatePopup === true &&
                    <NewChatgroupPopup 
                        chatCreatePopup={props.chatCreatePopup}
                        setChatPopup={props.setChatPopup}
                        setChatList={props.setChatList}
                        getAll={props.getAll}
                    />
                }
            </div>

            <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
            <p className="mb-3">Per procedere con la creazione di una chat, è necessario completare le seguenti informazioni:</p>
            <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>

        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>
        </>
    );
};
