import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";

export default function Section1({ onChangeSection }) {
  const [isShowDetail, setIsShowDetail] = useState(false);
  //mostra calcolo mediazione
  const [showMediazione, setShowMediazione] = useState(false)

  //rendica catastale ponderata
  const [renditCatastaleCalc, setRenditaCatastaleCalc] = useState(0)
  //persona fisica
  const [inputcatastale, setcatastale] = useState(null); //RENDITA - VALORE - PREZZO
  const [inputmediazione, setmediazione] = useState(null);
  const [inputvendita, setvendita] = useState(null);
  const [registro, setregistro] = useState(0);
  const [immobile, setImmobile] = useState(1);
  const [Venditore, setVenditore] = useState("1");
  const [calcolo, setCalcolo] = useState("1");
  //se impresa oltre 5 anni
  const [impostaIpotecaria, setImpostaIpotecaria] = useState(50);
  const [impostaCatastale, setImpostaCatastale] = useState(50);
  const [totaleImposte, setTotaleImposte] = useState(0);
 
  const ivaMediazione = 22
 
  //se impresa entro 5 anni (con IVA)
  const [ivaImpresa, setIvaImpresa] = useState(0)
  const tributoVoltura = 90
  const tassaIpotecaria = 200
  const impostaCatastaleImpresa = 200
  const impostaRegistroImpresa = 200
  const impostaBollo = 230

   //se altro
   const impostaCatastaleAltro = 200
   const tassaIpotecariaAltro = 200
   const impostaRegistroAltro = 200
   const ivaAltro = 0.22


  const onImmobileChange = (event) => {
    setImmobile(event.target.value);
  };

  const oncalcoloChange = (event) => {
    setCalcolo(event.target.value);
  };

  const coefficientiCatastali = {
    A1: 126,
    A2: 126,
    A3: 126,
    A4: 126,
    A5: 126,
    A6: 126,
    A7: 126,
    A8: 126,
    A9: 126,
    A10: 64,
    A11: 126,
    B1: 176.4,
    B2: 176.4,
    B3: 176.4,
    B4: 176.4,
    B5: 176.4,
    B6: 176.4,
    B7: 176.4,
    B8: 176.4,
    C1: 42.84,
    C2: 126,
    C3: 126,
    C4: 126,
    C5: 126,
    C6: 126,
    C7: 126,
    D1: 63,
    D2: 63,
    D3: 63,
    D4: 63,
    D5: 63,
    D6: 63,
    D7: 63,
    D8: 63,
    D9: 63,
    D10: 63,
    E1: 42.84,
    E2: 42.84,
    E3: 42.84,
    E4: 42.84,
    E5: 42.84,
    E6: 42.84,
    E7: 42.84,
    E8: 42.84,
    E9: 42.84,
  };

  const categorieCatastali = {
    1: ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "A9", "A11"],
    2: ["A10"],
    3: ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8"],
    4: ["C1"],
    5: ["C2", "C3", "C4", "C5", "C6", "C7"],
    6: ["D1", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "D9", "D10"],
    7: ["E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8", "E9"],
  };

  const [categoriaCatastale, setCategoriaCatastale] = useState("");
  const [imponibileMediazione,setImponibileMediazione] = useState(0)

  function calcolaValori(
    Venditore,
    immobile,
    registro,
    impostaIpotecaria,
    tassaIpotecaria,
    tributoVoltura,
    impostaBollo,
    impostaCatastale,
    ivaImpresa,
    ivaAltro,
    imponibileMediazione,
    ivaMediazione,
    totaleImposte
  ) {
    let result = {};
  
    if (Venditore === "1") {
      result['Descrizione'] = 'Imposta di registro';
      result['Importo'] = `€ ${registro > 1000 ? registro : 1000}`;
  
      result['Imposta ipotecaria'] = '€ 50';
  
      if (imponibileMediazione !== 0 && imponibileMediazione) {
        result['Imponibile mediazione'] = `€ ${imponibileMediazione}`;
        result['Iva mediazione'] = `% ${ivaMediazione}`;
      }
  
      result['Totale'] = `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  
    } else if (Venditore === "2") {
      result['Descrizione'] = 'Imposta di registro';
      result['Importo'] = `€ ${impostaIpotecaria}`;
  
      result['Imposta ipotecaria'] = `€ ${impostaIpotecaria}`;
      result['Tassa ipotecaria'] = `€ ${tassaIpotecaria}`;
      result['Tributo voltura'] = `€ ${tributoVoltura}`;
      result['Imposta di bollo'] = `€ ${impostaBollo}`;
      result['Imposta catastale'] = `€ ${impostaCatastale}`;
      result['Iva'] = `% ${ivaImpresa * 100}`;
  
      if (imponibileMediazione !== 0 && imponibileMediazione) {
        result['Imponibile mediazione'] = `€ ${imponibileMediazione}`;
        result['Iva mediazione'] = `% ${ivaMediazione}`;
      }
  
      result['Totale'] = `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  
    } else if (Venditore === "3") {
      if (immobile === "1" || immobile === "2") {
        result['Descrizione'] = 'Imposta di registro';
        result['Importo'] = `€ ${registro}`;
  
        result['Imposta ipotecaria'] = `€ ${impostaIpotecaria}`;
        result['Imposta catastale'] = `€ ${impostaCatastale}`;
  
        if (imponibileMediazione !== 0 && imponibileMediazione) {
          result['Imponibile mediazione'] = `€ ${imponibileMediazione}`;
          result['Iva mediazione'] = `% ${ivaMediazione}`;
        }
  
        result['Totale'] = `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  
      } else {
        result['Descrizione'] = 'Imposta di registro';
        result['Importo'] = `€ ${impostaIpotecaria}`;
  
        result['Imposta ipotecaria'] = `€ ${impostaIpotecaria}`;
        result['Imposta catastale'] = `€ ${impostaCatastale}`;
        result['Tassa ipotecaria'] = `€ ${tassaIpotecaria}`;
  
        if (imponibileMediazione !== 0 && imponibileMediazione) {
          result['Imponibile mediazione'] = `€ ${imponibileMediazione}`;
          result['Iva mediazione'] = `% ${ivaMediazione}`;
        }
  
        result['Totale'] = `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      }
    }
  
    return result;
  }
  


  const calcolaImposte = () => {
    let percentualeRegistro = 0;
    let minimoRegistro = 1000;
    let impostaRegistro = 1000;

    setImponibileMediazione((inputmediazione / 100) * inputvendita);
    let ivaMed = (ivaMediazione / 100) * imponibileMediazione;

    //SE VENDITORE E' PRIVATO
    if (Venditore == 1) {

        if (immobile == 1) {
          //SE IMMOBILE E' PRIMA CASA
            percentualeRegistro = 0.02;
        } else {
          //SE IMMOBILE E' DIVERSO DA PRIMA CASA
            percentualeRegistro = 0.09;
        }

        //SE BASE DI CALCOLO E' RENDITA CATASTALE
        if (calcolo == 2) {
            //SE PRIMA CASA VALORE PONDERAZIOE CATASTALE è 110, ALTRIMENTI 12O
            let cateValue = immobile == 1 ? 110 : 120;

            //PONDERO INPUT RENDITA CATASTALE
            const valoreCatastaleAnn = parseFloat(inputcatastale) * 1.05 * cateValue;
            setRenditaCatastaleCalc(valoreCatastaleAnn)

            //CALCOLO IMPOSTE DI REGISTRO, E SETTO IL MINIMO IMPORTO
            impostaRegistro = Math.max(percentualeRegistro * valoreCatastaleAnn, minimoRegistro);
            setregistro(impostaRegistro);
        } else {
            //SE BASE DI CALCOLO E' DIVERSO DA RENDITA CASTATALE USO VALORE INSERITO DALL'UTENTE PER 'INPUTCATASTALE' NON PONDERATO
            impostaRegistro = Math.max(percentualeRegistro * inputcatastale, minimoRegistro);
            setregistro(impostaRegistro);
        }
    } else if (Venditore == 2) {
       //SE VENDITORE E' IMPRESA ENTRO I 5 ANNI
       //COME MAI NON DEFINIAMO PERCENTUALE DI IMPOSTA?
        if (immobile == 1) {
          //SE PRIMA CASA IMPOSTO IVA AL 4%
            setIvaImpresa(0.04);
        } else if (immobile == 2) {
           //SE SECONDA CASA IMPOSTO IVA AL 10%
            setIvaImpresa(0.10);
        } else {
          //SE ABITAZIONE DI LUSSO IMPOSTO IVA AL 22%
            setIvaImpresa(0.22);
        }
    } else if (Venditore == 3 || Venditore == 4) {
      //SE VENDITORE E' IMPRESA OLTRE I 5 ANNI O SOGGETTO GIURIDICO

        if (immobile == 1) {
          //SE IMMOBILE E' PRIMA CASA
            percentualeRegistro = 0.02;
        } else {
          //SE IMMOBILE E' DIVERSO DA PRIMA CASA
            percentualeRegistro = 0.09;
        }

        if (immobile == 1) {
          //SE PRIMA CASA
            if (calcolo == 2) {
               //SE BASE DI CALCOLO E' RENDITA CATASTALE
                let cateValue = 110;
                //PONDERO RENDITA CATASTALE
                const valoreCatastaleAnn = parseFloat(inputcatastale) * 1.05 * cateValue;
                setRenditaCatastaleCalc(valoreCatastaleAnn)

                //CALCOLO IMPOSTE DI REGISTRO, E SETTO IL MINIMO IMPORTO
                impostaRegistro = Math.max(percentualeRegistro * valoreCatastaleAnn, minimoRegistro);
                setregistro(impostaRegistro);
            } else {
               //SE BASE DI CALCOLO E' DIVERSA DA RENDITA CATASTALE, USO INPUT UTENTE
                impostaRegistro = Math.max(percentualeRegistro * inputcatastale, minimoRegistro);
                setregistro(impostaRegistro);
            }
        } else {
            //SE SECONDA CASA O ABITAZIONE DI LUSSO
            percentualeRegistro = 0.09;
            if (calcolo == 2) {
                //SE BASE DI CALCOLO E' RENDITA CATASTALE
                let cateValue = 120;
                const valoreCatastaleAnn = parseFloat(inputcatastale) * 1.05 * cateValue;
                setRenditaCatastaleCalc(valoreCatastaleAnn)

                impostaRegistro = Math.max(percentualeRegistro * valoreCatastaleAnn, minimoRegistro);
                setregistro(impostaRegistro);

            } else {
                //SE BASE DI CALCOLO E' DIVERSA DA RENDITA CATASTALE, USO INPUT UTENTE
                impostaRegistro = Math.max(percentualeRegistro * inputcatastale, minimoRegistro);
                setregistro(impostaRegistro);

            }
        }
    }
    
    //CALCOLO IMPONIBILE MEDIAZIONE == (mediazione / 100) * inputVendita
    let totaleImposte = 0;
    if (Venditore == 1) {
        totaleImposte = impostaRegistro + impostaIpotecaria + impostaCatastale + imponibileMediazione + ivaMed;
        setTotaleImposte(parseFloat(totaleImposte))
    } else if (Venditore == 2) {
        let iva = inputcatastale * ivaImpresa;
        totaleImposte = (tributoVoltura + tassaIpotecaria + impostaRegistroImpresa + impostaBollo + impostaCatastaleImpresa + imponibileMediazione + ivaMed) + iva;
        setTotaleImposte(parseFloat(totaleImposte))
        console.log(totaleImposte, "totale imposte")


    } else if (Venditore == 3) {
        totaleImposte = impostaCatastale + impostaIpotecaria + impostaRegistro + imponibileMediazione + ivaMed;
        setTotaleImposte(parseFloat(totaleImposte))
        console.log(totaleImposte, "totale imposte")


    } else if (Venditore == 4 ) {
      //DEFINIRE OUTPUT E TOTALE PER VENDITORE IMPRESA OLTRE 5 ANNI
      let iva = 0; //impresa oltre 5 anni è esente iva
      totaleImposte = impostaCatastale + impostaIpotecaria + impostaRegistro + iva + imponibileMediazione + ivaMed;
      setTotaleImposte(parseFloat(totaleImposte))

      console.log(totaleImposte, "totale imposte")


    } 

    const itemData = calcolaValori(
        Venditore,
        immobile,
        registro,
        impostaIpotecaria,
        tassaIpotecaria,
        tributoVoltura,
        impostaBollo,
        impostaCatastale,
        ivaImpresa,
        ivaAltro,
        imponibileMediazione,
        ivaMediazione,
        totaleImposte
    );

    setIsShowDetail(true);
};

console.log(registro, "REGISTRO")

//dati per esportazione
let item = {};

if ( isShowDetail && Venditore == 1) {
  item = {
    "Valore": calcolo === 1 ? "Valore catastale" : calcolo === 2 ? "Valore Catastale Calcolato" : "Valore di vendita",
    "Importo": calcolo === 1 ? `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
      calcolo === 2 ? `€ ${renditCatastaleCalc?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
        `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "IVA": "Fuori campo IVA",
    "Imposta di registro": `€ ${registro > 1000 ? registro.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (1000).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta ipotecaria": `€ ${impostaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta catastale": `€ ${impostaCatastale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imponibile mediazione": imponibileMediazione ? `€ ${imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : null,
    "Iva mediazione": imponibileMediazione ? `% ${ivaMediazione}` : null,
    "Totale": `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  };
} else if ( isShowDetail && Venditore == 2) {
  item = {
    "Valore di vendita": `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    [`IVA ${ivaImpresa * 100}%`]: `€ ${(inputcatastale * ivaImpresa)?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta di registro": `€ ${impostaRegistroImpresa.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta ipotecaria": `€ ${tassaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta catastale": `€ ${impostaCatastaleImpresa.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta di bollo": `€ ${impostaBollo.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Tassa di trascrizione e voltura": `€ ${tributoVoltura.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imponibile mediazione": imponibileMediazione ? `€ ${imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : null,
    "Iva mediazione": imponibileMediazione ? `% ${ivaMediazione}` : null,
    "Totale": `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  };
} else if (isShowDetail && (Venditore == 4 || Venditore == 3)) {
  item = {
    "Valore": calcolo === 1 ? "Valore catastale" : calcolo === 2 ? "Valore Catastale Calcolato" : "Valore di vendita",
    "Importo": calcolo === 1 ? `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
      calcolo === 2 ? `€ ${renditCatastaleCalc?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` :
        `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "IVA": "ESENTE",
    "Imposta di registro": `€ ${registro > 1000 ? registro.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (1000).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta ipotecaria": `€ ${impostaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imposta catastale": `€ ${impostaCatastale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
    "Imponibile mediazione": imponibileMediazione ? `€ ${imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : null,
    "Iva mediazione": imponibileMediazione ? `% ${ivaMediazione}` : null,
    "Totale": `€ ${totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  };
}
console.log(item, "item")


  return (
    <div className={`page-body`}>

      <div className="space-top1">
        <div className="sub-title mb-3">Tipologia Venditore</div>
        <select value={Venditore} onChange={(e) => setVenditore(e.target.value)} className="input-section small">
            <option value="1">Persona fisica</option>
            <option value="2">
              Impresa costruttrice o ristrutturatrice entro 5 anni da fine
              lavori
            </option>
            {/* OLTRE: è esente iva rispetto a entro */}
            <option value="4"> 
              Impresa costruttrice o ristrutturatrice oltre 5 anni da fine
              lavori
            </option>
            <option value="3">
              Soggetto giuridico non costruttore/ristrutturatore{" "}
            </option>
        </select>
      </div>

      <div className="space-top1">
      <div className="sub-title mb-3">Tipologia Immobile</div>
        <select value={immobile} onChange={(e) => setImmobile(e.target.value)}  className="input-section small">
          <option value="1">Abitazione principale non di lusso</option>
          <option value="2">Abitazione secondaria non di lusso</option>
          <option value="3">Abitazione di lusso</option>
         {/*  <option value="4">Altri immobili</option> */}
        </select>
      </div>

      {Venditore != 2 && <div className="space-top1">
        <div className="sub-title">Base del calcolo</div>
        <div style={{ marginTop: "1%" }}>
          <select
            name="cars"
            id="cars"
            className="input-section small"
            onChange={oncalcoloChange}
          >
            <option value="1">Valore catastale dell'immobile</option>
            <option value="2">Rendita catastale non rivalutata</option>
            <option value="3">Prezzo o valore dell'immobile</option>
          </select>
        </div>
      </div>}

     {/*  {Venditore == 1 && calcolo == 2 && <div className="space-top1">
        <div className="sub-title">Categoria catastale</div>
        <div style={{ marginTop: "1%" }}>
          <select className="input-section small" value={categoriaCatastale} onChange={(e) => setCategoriaCatastale(e.target.value)}>
            <option value="">Seleziona una categoria</option>
            <option value="1">A1~A9, A11</option>
            <option value="2">A10</option>
            <option value="3">B1~B8</option>
            <option value="4">C1</option>
            <option value="5">C2~C7</option>
            <option value="6">D1~D10</option>
            <option value="7">E1~E9</option>
          </select>
        </div>
      </div>} */}

      <div className="space-top1">
        <div className="sub-title">
          {Venditore != 2 && calcolo == 2 && "Rendita catastale (non rivalutata)"}
          {Venditore != 2 && calcolo == 1 && "Valore catastale"}
          {Venditore != 2 && calcolo == 3 && "Prezzo o valore dell’immobile"}
          {Venditore == 2 && "Prezzo o valore dell’immobile"}
        </div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText">
                <input
                  type="number"
                  className="input-calc-symbol"
                  value={inputcatastale}
                  onChange={(e) => setcatastale(parseFloat(e.target.value))}
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Row className="mb-4">
            <div className="radioLabel">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={showMediazione}
                onChange={(e) => setShowMediazione(e.target.checked)}
              />
              <label className="sub-title1" for="check">
                {" "}
                Calcola i costi di mediazione
              </label>
            </div>
          </Row>

      {showMediazione &&
        <>
          <div className="space-top1">
            <div className="sub-title">% mediazione (0 per nessuna)</div>
            <div style={{ marginTop: "1%" }}>
              <Row>
                <Col className="">
                  <div className="divInputText">
                    <input
                      type="number"
                      className="input-calc-symbol"
                      value={inputmediazione}
                      onChange={(e) => setmediazione(e.target.value)}
                    ></input>
                    <span className="spanInput1">%</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="space-top1">
            <div className="sub-title">Prezzo di vendita</div>
            <div style={{ marginTop: "1%" }}>
              <Row>
                <Col className="">
                  <div className="divInputText">
                    <input
                      type="number"
                      className="input-calc-symbol"
                      value={inputvendita}
                      onChange={(e) => setvendita(e.target.value)}
                    ></input>
                    <span className="spanInput1">€</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      }

      <div className="space-top1 ">
        <div className="space-top1">
          <ButtonRm variant="secondary" onClick={calcolaImposte} 
           disabled={
            !inputcatastale || (showMediazione && (!inputmediazione || !inputvendita))
          }
          >
            Calcola
          </ButtonRm>
        </div>
      </div>

      {/* OUPUT  */}

      {/* PERSONA FISICA */}
      {isShowDetail && (Venditore == 1 ) && (
        
        <div className="space-top3">
            <div className="text-end ms-auto d-flex align-items-center justify-content-end">
           <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
{/*            <ShareChat styleBtn="top-btn-icon" />
 */}           
          </div>
          <table className="tb mt-4">
            <thead>
            <tr>
              <th width="60%" className="tb-head">
                Descrizione
              </th>
              <th width="40%" className="tb-head">
                Importo
              </th>
            </tr>
            </thead>
            
            <tbody>
            <tr>
              <td className="tb-body">
                {calcolo == 1 && 'Valore catastale'}
                {calcolo == 2 && 'Valore Catastale Calcolato'}
                {calcolo == 3 && 'Valore di vendita'}
              </td>
              <td className="tb-body fw-bold">
                {calcolo == 1 && `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                {calcolo == 2 && `€ ${renditCatastaleCalc?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                {calcolo == 3 && `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              </td>
            </tr>
            <tr>
              <td className="tb-body">IVA</td>
              <td className="tb-body fw-bold">Fuori campo IVA</td>
            </tr>
            <tr>
              <td className="tb-body ">Imposta di registro</td>
              <td className="tb-body fw-bold">€ {registro > 1000 ? registro.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (1000).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta ipotecaria</td>
              <td className="tb-body fw-bold">€ {impostaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta catastale</td>
              <td className="tb-body fw-bold">€ {impostaCatastale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            { imponibileMediazione != 0 && imponibileMediazione &&
            <>
              <tr>
                <td className="tb-body">Imponibile mediazione</td>
                <td className="tb-body fw-bold">€ {imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
              <tr>
                <td className="tb-body">Iva mediazione</td>
                <td className="tb-body fw-bold">% {ivaMediazione}</td>
              </tr>
            </>
            }
            <tr>
                <td className="tb-body">Totale </td>
                <td className="tb-body fw-bold">€ {totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* IMPRESA ENTRO 5 ANNI */}
      {isShowDetail && (Venditore == 2 ) && (
        
        <div className="space-top3">
            <div className="text-end ms-auto d-flex align-items-center justify-content-end">
           <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
{/*            <ShareChat styleBtn="top-btn-icon" />
 */}           
          </div>
          <table className="tb mt-4">
            <tr>
              <th width="60%" className="tb-head">
                Descrizione
              </th>
              <th width="40%" className="tb-head">
                Importo
              </th>
            </tr>
            <tr>
              <td className="tb-body">
                {'Valore di vendita'}
              </td>
              <td className="tb-body fw-bold">
                {`€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              </td>
            </tr>
            <tr>
              <td className="tb-body">IVA {ivaImpresa*100}% </td>
              <td className="tb-body fw-bold">€ {(inputcatastale * ivaImpresa)?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body ">Imposta di registro</td>
              <td className="tb-body fw-bold">€ {impostaRegistroImpresa.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta ipotecaria</td>
              <td className="tb-body fw-bold">€ {tassaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta catastale</td>
              <td className="tb-body fw-bold">€ {impostaCatastaleImpresa.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta di bollo</td>
              <td className="tb-body fw-bold">€ {impostaBollo.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Tassa di trascrizione e voltura</td>
              <td className="tb-body fw-bold">€ {tributoVoltura.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            { imponibileMediazione != 0 && imponibileMediazione &&
            <>
              <tr>
                <td className="tb-body">Imponibile mediazione</td>
                <td className="tb-body fw-bold">€ {imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
              <tr>
                <td className="tb-body">Iva mediazione</td>
                <td className="tb-body fw-bold">% {ivaMediazione}</td>
              </tr>
            </>
            }
              <tr>
                <td className="tb-body">Totale </td>
                <td className="tb-body fw-bold">€ {totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          </table>
        </div>
      )}

      {/* IMPRESA OLTRE 5 ANNI */}
      {isShowDetail && (Venditore == 4) && (
        
        <div className="space-top3">
           <div className="text-end ms-auto d-flex align-items-center justify-content-end">
           <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
{/*            <ShareChat styleBtn="top-btn-icon" />
 */}           
          </div>
          <table className="tb mt-4">
            <tr>
              <th width="60%" className="tb-head">
                Descrizione
              </th>
              <th width="40%" className="tb-head">
                Importo
              </th>
            </tr>
            <tr>
              <td className="tb-body">
                {calcolo == 1 && 'Valore catastale'}
                {calcolo == 2 && 'Valore Catastale Calcolato'}
                {calcolo == 3 && 'Valore di vendita'}
              </td>
              <td className="tb-body fw-bold">
                {calcolo == 1 && `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                {calcolo == 2 && `€ ${renditCatastaleCalc?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                {calcolo == 3 && `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              </td>
            </tr>
            <tr>
              <td className="tb-body">IVA </td>
              <td className="tb-body fw-bold">ESENTE</td>
            </tr>
            <tr>
              <td className="tb-body ">Imposta di registro</td>
              <td className="tb-body fw-bold">€ {registro > 1000 ? registro.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (1000).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta ipotecaria</td>
              <td className="tb-body fw-bold">€ {impostaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta catastale</td>
              <td className="tb-body fw-bold">€ {impostaCatastale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            { imponibileMediazione != 0 && imponibileMediazione &&
            <>
              <tr>
                <td className="tb-body">Imponibile mediazione</td>
                <td className="tb-body fw-bold">€ {imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
              <tr>
                <td className="tb-body">Iva mediazione</td>
                <td className="tb-body fw-bold">% {ivaMediazione}</td>
              </tr>
            </>
            }
              <tr>
                <td className="tb-body">Totale </td>
                <td className="tb-body fw-bold">€ {totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          </table>
        </div>
      )}

      {/* SOGGETTO GIURIDICO - IVA RISULTA ESENTE */}
      {isShowDetail && (Venditore == 3) && (
        
        <div className="space-top3">
           <div className="text-end ms-auto d-flex align-items-center justify-content-end">
           <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
{/*            <ShareChat styleBtn="top-btn-icon" />
 */}           
          </div>
          <table className="tb mt-4">
            <tr>
              <th width="60%" className="tb-head">
                Descrizione
              </th>
              <th width="40%" className="tb-head">
                Importo
              </th>
            </tr>
            <tr>
              <td className="tb-body">
                {calcolo == 1 && 'Valore catastale'}
                {calcolo == 2 && 'Valore Catastale Calcolato'}
                {calcolo == 3 && 'Valore di vendita'}
              </td>
              <td className="tb-body fw-bold">
                {calcolo == 1 && `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                {calcolo == 2 && `€ ${renditCatastaleCalc?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` }
                {calcolo == 3 && `€ ${inputcatastale?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              </td>
            </tr>
            <tr>
              <td className="tb-body">IVA </td>
              <td className="tb-body fw-bold">ESENTE</td>
            </tr>
            <tr>
              <td className="tb-body ">Imposta di registro</td>
              <td className="tb-body fw-bold">€ {registro > 1000 ? registro.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : (1000).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta ipotecaria</td>
              <td className="tb-body fw-bold">€ {impostaIpotecaria.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="tb-body">Imposta catastale</td>
              <td className="tb-body fw-bold">€ {impostaCatastale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            { imponibileMediazione != 0 && imponibileMediazione &&
            <>
              <tr>
                <td className="tb-body">Imponibile mediazione</td>
                <td className="tb-body fw-bold">€ {imponibileMediazione.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
              <tr>
                <td className="tb-body">Iva mediazione</td>
                <td className="tb-body fw-bold">% {ivaMediazione}</td>
              </tr>
            </>
            }
              <tr>
                <td className="tb-body">Totale </td>
                <td className="tb-body fw-bold">€ {totaleImposte?.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
          </table>
        </div>
      )}

   

    



    </div>
  );
}
