import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import NumericInput from "react-numeric-input";
import ButtonRm from "../../../ButtonRm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";



export default function Section2({ onChangeSection }) {
  const [inputCatastale, setInputCatastale] = useState(10);
  const [inputannuo, setannuo] = useState(10);
  const [inputlocati, setlocati] = useState(10);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [inputCategoria, setCategoria] = useState("1");
  const [inputAnnual, setInputAnnual] = useState(0);
  const [dichiarare, setdichiarare] = useState(0);
  const [showOutputValore, setShowOutputValore] = useState(false)
  const onInputcatastaleHandler = (event) => {
    // console.log("previdenza - ", event.target.value);
    setCategoria(event.target.value);
  };

  function spinFormat(num) {
    return num + "gg";
  }

  const [renditaCatastale, setRenditaCatastale] = useState("");
  const [canoneAnnuo, setCanoneAnnuo] = useState("");
  const [giorniLocati, setGiorniLocati] = useState("");
  const [redditoDichiarato, setRedditoDichiarato] = useState("");
  const [valoreCatastale, setValoreCatastale] = useState("");
  const [categoriaCatastale, setCategoriaCatastale] = useState("");

  const categorieCatastali = {
    1: ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "A9", "A11"],
    2: ["A10"],
    3: ["B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8"],
    4: ["C1"],
    5: ["C2", "C3", "C4", "C5", "C6", "C7"],
    6: ["D1", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "D9", "D10"],
    7: ["E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8", "E9"],
  };
  
  const calcolaValoreCatastale = () => {
    if (!renditaCatastale || !categoriaCatastale) {
      alert('Per favore, inserisci sia la rendita che la categoria.');
      return;
    }

    const renditaNum = parseFloat(renditaCatastale);
    let renditaRivalutata;
    let coefficiente;

    switch (categoriaCatastale) {
      case 'APC':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 110;
        break;
      case 'ANPC':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 120;
        break;
      case 'A10':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 60;
        break;
      case 'B':
        renditaRivalutata = renditaNum * 1.40; // Rivalutazione del 40%
        coefficiente = 140;
        break;
      case 'C':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 120;
        break;
      case 'C1':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 40.80;
        break;
      case 'D':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 60;
        break;
      case 'E':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 40.80;
        break;
      case 'T':
        //Per i terreni non edificabili il valore catastale si calcola moltiplicando per 75 il reddito dominicale rivalutato del 25%.
  
        renditaRivalutata = (renditaNum * 1.25) //* 75; // Rivalutazione del 25%
        coefficiente = 130;
        break;
      case 'TIC':
        renditaRivalutata = renditaNum * 1.25; // Rivalutazione del 25%
        coefficiente = 110;
        break;
      default:
        alert('Categoria catastale non valida.');
        return;
    }

    const valore = renditaRivalutata * coefficiente;


    setValoreCatastale(valore);
    setShowOutputValore(true)
  };

  const formatCurrency = (number) => {
    return new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  };
  

  //DATI PER ESPORTAZIONE
  const item = {
    ['Rendita catastale']: `€ ${renditaCatastale}`,
    ['Valore catastale ']: formatCurrency(valoreCatastale),
    ['Reddito da dichiarare']: parseFloat(redditoDichiarato).toFixed(2),
  }



  return (
    <div className={`page-body`}>
   {/*    <small>Per poter calcolare il Reddito di fabbricato, prima calcola il valore catastale</small> */}
      {/* <div className='page-title'>
                <Image src={nav1} width="100%" />
            </div> */}
      <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">
            Valore Catastale
          </h6>
        </div>
      </div>
      <div className="space-top1">
        <div className="sub-title">Categoria catastale</div>
        <div style={{ marginTop: "1%" }}>
          <select value={categoriaCatastale} onChange={(e) => setCategoriaCatastale(e.target.value)}>
          <option value="" selected="selected">Seleziona categoria catastale</option>
            <option value="APC">Categoria A (escluso A/10) "prima casa"</option>
            <option value="ANPC">Categoria A (escluso A/10) "non prima casa"</option>
            <option value="A10">Categoria A/10</option>
            <option value="B">Categoria B</option>
            <option value="C">Categoria C (escluso C/1)</option>
            <option value="C1">Categoria C/1</option>
            <option value="D">Fabbricato D</option>
            <option value="E">Fabbricato E</option>
            <option value="T">Terreno non edificabile</option>
            <option value="TIC">Terreno (imprenditori agricoli/coltivatori diretti)</option>
          </select>
        </div>
      </div>

      <div className="space-top1">
        <div className="sub-title">Rendita Catastale</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText w-50">
                <input
                  type="number"
                  className="input-calc-symbol"
                  value={renditaCatastale}
                  onChange={(e) => setRenditaCatastale(e.target.value)}
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="space-top1 ">
        <div className="space-top1 mg-13">
          <ButtonRm
            variant="secondary"
            disabled={(categoriaCatastale && renditaCatastale)? false: true}
            onClick={calcolaValoreCatastale}
          >
            Calcola
          </ButtonRm>
        </div>
      </div>

      {showOutputValore && <>

      <div className="space-top1">
        <div className="sub-title">VALORE CATASTALE</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText w-50">
                <input
                  className="input-calc-symbol"
                  value={formatCurrency(valoreCatastale)}
                  readOnly
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

     {/* DA COMPLETARE CALCOLO REDDITO DI FABBRICATO */}

     {/*  <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">
            Reddito di Fabbricato
          </h6>
        </div>
      </div>


      <div className="space-top1">
        <div className="sub-title">VALORE CATASTALE</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText w-50">
                <input
                  className="input-calc-symbol"
                  value={valoreCatastale}
                  readOnly
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>   

      <div className="space-top1">
        <div className="sub-title">Importo del canone annuo</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText w-50">
                <input
                  type="number"
                  className="input-calc-symbol"
                  value={canoneAnnuo}
                  onChange={(e) => setCanoneAnnuo(e.target.value)}
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="space-top1">
        <div className="sub-title">Giorni locati</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
          <Col className="">
              <div className="divInputText w-50">
                <input
                  type="number"
                  value={giorniLocati}
                  onChange={(e) => setGiorniLocati(e.target.value)}
                ></input>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="space-top1 ">
        <div className="space-top1 mg-13">
          <ButtonRm
            variant="secondary"
            disabled={giorniLocati && categoriaCatastale && renditaCatastale && canoneAnnuo? false: true}
          >
            Calcola
          </ButtonRm>
        </div>
      </div>

      {isShowDetail && (
        <div className="space-top1">
           <div className="text-end ms-auto d-flex align-items-center justify-content-end">
          <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
        
          </div>
          <Row>
            <Col xs={6}>
              <div className="sub-title">Reddito da dichiarare</div>
            </Col>
            <Col xs={6}>
              <div className="sub-title">Valore catastale dell'immobile</div>
            </Col>
          </Row>



          <Row className="space-top1">
            <Col xs={6}>
              <div className="input-text">
                € {parseFloat(redditoDichiarato).toFixed(2)}
              </div>
            </Col>
            <Col xs={6}>
              <div className="input-text">
                € {parseFloat(valoreCatastale).toFixed(2)}
              </div>
            </Col>
          </Row>
        </div>
      )} */}

      </>}
     
    </div>
  );
}



function CalcolaValoreCatastale() {
  const [rendita, setRendita] = useState('');
  const [categoria, setCategoria] = useState('');
  const [valoreCatastale, setValoreCatastale] = useState(null);

  const calcolaValoreCatastale = () => {
    if (!rendita || !categoria) {
      alert('Per favore, inserisci sia la rendita che la categoria.');
      return;
    }

    const renditaNum = parseFloat(rendita);
    let renditaRivalutata;
    let coefficiente;

    switch (categoria) {
      case 'APC':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 110;
        break;
      case 'ANPC':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 120;
        break;
      case 'A10':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 60;
        break;
      case 'B':
        renditaRivalutata = renditaNum * 1.40; // Rivalutazione del 40%
        coefficiente = 140;
        break;
      case 'C':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 120;
        break;
      case 'C1':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 40.80;
        break;
      case 'D':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 60;
        break;
      case 'E':
        renditaRivalutata = renditaNum * 1.05; // Rivalutazione del 5%
        coefficiente = 40.80;
        break;
      case 'T':
        renditaRivalutata = renditaNum * 1.25; // Rivalutazione del 25%
        coefficiente = 130;
        break;
      case 'TIC':
        renditaRivalutata = renditaNum * 1.25; // Rivalutazione del 25%
        coefficiente = 110;
        break;
      default:
        alert('Categoria catastale non valida.');
        return;
    }

    const valore = renditaRivalutata * coefficiente;
    setValoreCatastale(valore);
  };

  return (
    <div>
      <h1>Calcolo Valore Catastale</h1>
      <div>
        <label>
          Rendita Catastale:
          <input
            type="number"
            value={rendita}
            onChange={(e) => setRendita(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Categoria Catastale:
          <select
            name="CboCat"
            id="CboCat"
            className="imputGrandeselect"
            data-msg-required="Seleziona Categoria Catastale"
            data-rule-required="true"
            aria-required="true"
            aria-invalid="false"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          >
            <option value="" selected="selected">Seleziona categoria catastale</option>
            <option value="APC">Categoria A (escluso A/10) "prima casa"</option>
            <option value="ANPC">Categoria A (escluso A/10) "non prima casa"</option>
            <option value="A10">Categoria A/10</option>
            <option value="B">Categoria B</option>
            <option value="C">Categoria C (escluso C/1)</option>
            <option value="C1">Categoria C/1</option>
            <option value="D">Fabbricato D</option>
            <option value="E">Fabbricato E</option>
            <option value="T">Terreno non edificabile</option>
            <option value="TIC">Terreno (imprenditori agricoli/coltivatori diretti)</option>
          </select>
        </label>
      </div>
      <button onClick={calcolaValoreCatastale}>Calcola</button>
      {valoreCatastale !== null && (
        <div>
          <h2>Valore Catastale: €{valoreCatastale.toFixed(2)}</h2>
        </div>
      )}
    </div>
  );
}


