import React, { useState, useEffect, useMemo } from "react";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard";
import { BsPrinter, BsDownload } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import { BsPlusCircleFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import UserField from "../UserField";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";
import CustomBadge from "../../../CustomBadge";


const DataE = (props) => {
	const [data, setData] = useState({});

	const init = () => {
		setData({
			unit: props.formData.unit,
			furniture: props.formData.furniture,
			equipment: props.formData.equipment,
			energyCertification: props.formData.energyCertification,
			otherCertification: props.formData.otherCertification,
			includedExpenses: props.formData.includedExpenses,
			excludedExpenses: props.formData.excludedExpenses,
			contractAddendum: props.formData.contractAddendum,
			keyDelivery: props.formData.keyDelivery,
			notes: props.formData.notes,
			imuNote: props.formData.imuNote,
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const otherOpenSpaceObj = useMemo(
		() =>
			data.otherOpenSpace === "" || data.otherOpenSpace === undefined
				? {}
				: JSON.parse(data.otherOpenSpace.replace(/'/g, '"')),
		[data.otherOpenSpace]
	);

	const otherObj = useMemo(
		() =>
			data.other === "" || data.other === undefined
				? {}
				: JSON.parse(data.other.replace(/'/g, '"')),
		[data.other]
	);

	const otherDistanceObj = useMemo(
		() =>
			data.otherDistance === "" || data.otherDistance === undefined
				? {}
				: JSON.parse(data.otherDistance.replace(/'/g, '"')),
		[data.otherDistance]
	);

	const [fullMode, setFullMode] = useState(false);

/* 	const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		props.update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */

	const onChange = (e) => {
		if (e.target.type === "checkbox")
			setData({ ...data, [e.target.name]: e.target.checked });
		else setData({ ...data, [e.target.name]: e.target.value });
	};

	const status_type = [
		"Da ristrutturare totalmente",
		"Da ristrutturare parzialmente",
		"Buono/abitabile",
		"Ottimo/ristrutturato",
		"Nuovo/in costruzione",
	];
	const rentalState_type = [
		"Immobile libero",
		"Libero (utilizzato dalla proprietà)",
		"Locato brevissimo termine (1 anno)",
		"Locato breve termine (2 anni)",
		"Locato medio termine (3 anni)",
		"Locato lungo temine (4 anni)",
		"Locato lunghissimo termine (oltre 4 anni)",
	];

	const energyClass_type = [
		"A4",
		"A3",
		"A2",
		"A1",
		"A+",
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
	];

	const heating_type = [
		"Assente",
		"Centralizzato",
		"Autonomo termosifoni",
		"autonomo pavimento",
		"geotermico",
		"centralizzato con contacalorie",
		"teleriscaldamento con contacalorie",
	];
	const [open_space_modal, setOpenSpaceModal] = useState(false);
	const [open_space, setOpenSpace] = useState("");

	const addOpenSpace = () => {
		let temp = { ...otherOpenSpaceObj };
		temp = { ...temp, [open_space]: true };
		setData({ ...data, ["otherOpenSpace"]: JSON.stringify(temp) });
		setOpenSpace("");
		setOpenSpaceModal(false);
	};

	const changeOpenSpace = (e) => {
		let temp = { ...otherOpenSpaceObj };
		temp = { ...temp, [e.target.name]: e.target.checked };
		setData({ ...data, ["otherOpenSpace"]: JSON.stringify(temp) });
	};

	const changeOpenSpaceName = (id, name) => {
		let temp = {};
		for (var i = 0; i < Object.keys(otherOpenSpaceObj).length; i++) {
			if (i === id) {
				temp = {
					...temp,
					[name]: otherOpenSpaceObj[Object.keys(otherOpenSpaceObj)[i]],
				};
			} else {
				temp = {
					...temp,
					[Object.keys(otherOpenSpaceObj)[i]]:
						otherOpenSpaceObj[Object.keys(otherOpenSpaceObj)[i]],
				};
			}
		}
		setData({ ...data, ["otherOpenSpace"]: JSON.stringify(temp) });
	};

	const deleteOpenSpace = (name) => {
		let temp = { ...otherOpenSpaceObj };
		delete temp[name];
		setData({ ...data, ["otherOpenSpace"]: JSON.stringify(temp) });
	};

	const [other_modal, setOtherModal] = useState(false);
	const [other_name, setOtherName] = useState("");

	const addOther = () => {
		let temp = { ...otherObj };
		temp = { ...temp, [other_name]: true };
		setData({ ...data, ["other"]: JSON.stringify(temp) });
		setOtherName("");
		setOtherModal(false);
	};

	const changeOther = (e) => {
		let temp = { ...otherObj };
		temp = { ...temp, [e.target.name]: e.target.checked };
		setData({ ...data, ["other"]: JSON.stringify(temp) });
	};

	const changeOtherName = (id, name) => {
		let temp = {};
		for (var i = 0; i < Object.keys(otherObj).length; i++) {
			if (i === id) {
				temp = { ...temp, [name]: otherObj[Object.keys(otherObj)[i]] };
			} else {
				temp = {
					...temp,
					[Object.keys(otherObj)[i]]: otherObj[Object.keys(otherObj)[i]],
				};
			}
		}
		setData({ ...data, ["other"]: JSON.stringify(temp) });
	};

	const deleteOther = (name) => {
		let temp = { ...otherObj };
		delete temp[name];
		setData({ ...data, ["other"]: JSON.stringify(temp) });
	};

	const [distance_modal, setDistanceModal] = useState(false);
	const [distance_name, setDistanceName] = useState("");

	const addDistance = () => {
		let temp = { ...otherDistanceObj };
		temp = { ...temp, [distance_name]: 0 };
		setData({ ...data, ["otherDistance"]: JSON.stringify(temp) });
		setDistanceName("");
		setDistanceModal(false);
	};

	const changeDistance = (name, value) => {
		let temp = { ...otherDistanceObj };
		temp = { ...temp, [name]: value };
		setData({ ...data, ["otherDistance"]: JSON.stringify(temp) });
	};

	const changeDistanceName = (id, name) => {
		let temp = {};
		for (var i = 0; i < Object.keys(otherDistanceObj).length; i++) {
			if (i === id) {
				temp = {
					...temp,
					[name]: otherDistanceObj[Object.keys(otherDistanceObj)[i]],
				};
			} else {
				temp = {
					...temp,
					[Object.keys(otherDistanceObj)[i]]:
						otherDistanceObj[Object.keys(otherDistanceObj)[i]],
				};
			}
		}
		setData({ ...data, ["otherDistance"]: JSON.stringify(temp) });
	};

	const deleteDistance = (name) => {
		let temp = { ...otherDistanceObj };
		delete temp[name];
		setData({ ...data, ["otherDistance"]: JSON.stringify(temp) });
	};



	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}

	}, [props.saveState, props.editMode]);
	return (
		<div className="form-item-align-box-white">
			<div className={`d-flex justify-content-end align-items-center ${
							props.editMode === false ? "" : "d-none"
						}`}>
						<div className="d-flex mb-1">
							
							<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>
						</div>
					</div>
			<fieldset disabled={props.editMode ? false : true}>
			
			<div className={` py-3 px-4 `}>

							<div className="row  gy-3">
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Unità
										</label>
										<textarea
											name="unit"
											value={data.unit}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Arredo
										</label>
										<textarea
											name="furniture"
											value={data.furniture}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
							</div>
							<div className="row gy-3 mt-2">
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Dotazione
										</label>
										<textarea
											name="equipment"
											value={data.equipment}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
								<div className="col-md-12 ">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											A.P.E.
										</label>
										<textarea
											name="energyCertification"
											value={data.energyCertification}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
							</div>
							<div className="row gy-3 mt-2">
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Altre certificazioni
										</label>
										<textarea
											name="otherCertification"
											value={data.otherCertification}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Spese incluse
										</label>
										<textarea
											name="includedExpenses"
											value={data.includedExpenses}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
							</div>
							<div className="row gy-3 mt-2">
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Spese escluse
										</label>
										<textarea
											name="excludedExpenses"
											value={data.excludedExpenses}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Addendum al contratto
										</label>
										<textarea
											name="contractAddendum"
											value={data.contractAddendum}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
							</div>
							<div className="row gy-3 mt-2">
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Consegna delle chiavi
										</label>
										<textarea
											name="keyDelivery"
											value={data.keyDelivery}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Note
										</label>
										<textarea
											name="notes"
											value={data.notes}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
							</div>
							<div className="row gy-3 mt-2
							">
								<div className="col-md-12">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16">
											Note IMU
										</label>
										<textarea
											name="imuNote"
											value={data.imuNote}
											onChange={(e) => {
												onChange(e);
											}}
											id=""
											cols="30"
											rows="4"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
									</div>
								</div>
							</div>
							
				</div>
					</fieldset>
	
			<Modal show={open_space_modal} centered>
				<Modal.Body className="p-5">
					<div className="d-block w-100 mb-3">
						<div>
							<h2 className="fs-20 text-dark mb-2 fw-bold">Aggiunta campo</h2>
						</div>
					</div>
					<div className="form-item-align-box d-block">
						<div className="mt-3">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Nome campo</label>
								<input
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={(e) => {
										setOpenSpace(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-last-btns mt-3 section-modal-btn">
							<button
								type="button"
								className="next-btn btn-last rounded-3 shadow me-2 border-0 col-md-12"
								disabled={open_space !== "" ? false : true}
								onClick={addOpenSpace}>
								Aggiungi
							</button>
							<button
								type="button"
								className="black-btn btn-last rounded-3 shadow border-0 col-md-12 mt-3"
								onClick={() => {
									setOpenSpaceModal(false);
								}}>
								Annulla
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={other_modal} centered>
				<Modal.Body className="p-5">
					<div className="d-block w-100 mb-3">
						<div>
							<h2 className="fs-20 text-dark mb-2 fw-bold">Aggiunta campo</h2>
						</div>
					</div>
					<div className="form-item-align-box d-block">
						<div className="mt-3">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Nome campo</label>
								<input
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={(e) => {
										setOtherName(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-last-btns mt-3 section-modal-btn">
							<button
								type="button"
								className="next-btn btn-last rounded-3 shadow me-2 border-0 col-md-12"
								disabled={other_name !== "" ? false : true}
								onClick={addOther}>
								Aggiungi
							</button>
							<button
								type="button"
								className="black-btn btn-last rounded-3 shadow border-0 col-md-12 mt-3"
								onClick={() => {
									setOtherModal(false);
								}}>
								Annulla
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={distance_modal} centered>
				<Modal.Body className="p-5">
					<div className="d-block w-100 mb-3">
						<div>
							<h2 className="fs-20 text-dark mb-2 fw-bold">Aggiunta campo</h2>
						</div>
					</div>
					<div className="form-item-align-box d-block">
						<div className="mt-3">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Nome campo</label>
								<input
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={(e) => {
										setDistanceName(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-last-btns mt-3 section-modal-btn">
							<button
								type="button"
								className="next-btn btn-last rounded-3 shadow me-2 border-0 col-md-12"
								disabled={distance_name !== "" ? false : true}
								onClick={addDistance}>
								Aggiungi
							</button>
							<button
								type="button"
								className="black-btn btn-last rounded-3 shadow border-0 col-md-12 mt-3"
								onClick={() => {
									setDistanceModal(false);
								}}>
								Annulla
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default DataE;
