import { useState } from "react";
import EmptyCard from "../../../assets/images/cards.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AccountModal from "./AccountModal";
import AccountCard from "./AccountCard";



import { BankCard,BankCardItem } from "../../BankCard";

const Account = ({ accounts, onChange }) => {
	const [show, setShow] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [id, setId] = useState(-1);

	const add = (account) => {
		let temp = [...accounts];
		temp.push(account);
		let e = { target: { name: "currentAccounts", value: temp } };
		onChange(e);
		setShow(false);
	};

	const update = (account) => {
		let temp = [...accounts];
		temp[id] = account;
		let e = { target: { name: "currentAccounts", value: temp } };
		onChange(e);
		setEditModal(false);
	};

	const handleClick = (index) => {
		setId(index);
		setEditModal(true);
	};

	const remove = (index) => {
		let temp = [...accounts];
		temp.splice(index, 1);
		let e = { target: { name: "currentAccounts", value: temp } };
		onChange(e);
	};

	console.log(accounts)


	return (
		<div className="form-item-align-box-white d-block">
			<div>
				<button
					type="button"
					className="general-btn white-btn w-193 me-2"
					onClick={() => {
						setShow(true);
					}}>
					Nuovo
				</button>
			</div>
			
			<div className="row mt-3">
				{accounts?.length > 0 ? (
					accounts.map((bankAccount, index) => (
						<BankCard 
						key={index}
						name={bankAccount.Nome}         color="#78ab8b" 
						deleteFunc={remove} 
						index={index}>
						  <BankCardItem property="IBAN" value={bankAccount.IBAN}/>
						  <BankCardItem property="INTESTATO A" value={bankAccount.Intestazione}/>
						  <BankCardItem property="BANCA" value={bankAccount.Banca}/>
						  <BankCardItem property="ABI" value={bankAccount.ABI}/>
						  <BankCardItem property="CAB" value={bankAccount.CAB}/>
						  <BankCardItem property="CIN" value={bankAccount.CIN}/>
						  <BankCardItem property="CONTO" value={bankAccount.CONTO}/>
						  <BankCardItem property="BIC/SWIFT" value={bankAccount["BIC/SWIFT"]}/>
						  <BankCardItem property="CODICE SIA" value={bankAccount["Codice SIA"]}/>
						  <BankCardItem property="AGENZIA" value={bankAccount["Agenzia/Comune"]}/>
						  <BankCardItem property="SEPA CUC" value={bankAccount["Codice CUC SEPA"]}/>
					  </BankCard>
					))
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={EmptyCard} alt="No image" />
						<span className="img-comment">
							Non hai aggiunto ancora alcun conto
						</span>
					</div>
				)}
			</div>
			<Modal show={show}  className="right-full-pop" backdrop>
				<Modal.Body className="p-4">
					<Button
						variant=""
						onClick={() => {
							setShow(false);
						}}
						className="float-end border-0 bg-white"
						style={{ marginTop: "-1rem" }}>
						<i className="bi bi-x"></i>
					</Button>
					<AccountModal setShow={setShow} newMode={true} add={add} />
				</Modal.Body>
			</Modal>
			<Modal
				show={editModal}
				className="right-full-pop"
				backdrop>
				<Modal.Body className="p-4">
					<Button
						variant=""
						onClick={() => {
							setEditModal(false);
						}}
						className="float-end border-0 bg-white"
						style={{ marginTop: "-1rem" }}>
						<i className="bi bi-x"></i>
					</Button>
					<AccountModal
						setShow={setEditModal}
						newMode={false}
						update={update}
						account={accounts[id]}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Account;
