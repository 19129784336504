import { BsPrinter, BsDownload } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CadastreModal from "./CadastreModal";
import EditCadastreModal from "./EditCadastreModal";
import "../../../../assets/css/Tabs.css";
import User from "../../../../assets/images/user-key.png";
import OwnerCard from "../OwnerCard";
import OwnerModal from "../OwnerModal";
import FolderImg from "../../../../assets/images/empty-folder.png";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard";
import Table from "react-bootstrap/Table";
import { MultiSelect } from "react-multi-select-component";
import NumericInput from "react-numeric-input";
import CurrencyInput from "react-currency-input-field";
import DateModal from "./DateModal";
import translationMapping from "../mapping";

import pc from "../../../../assets/images/placeholder-unit-medium.png";

import {
	getProvince,
	getMunicipality,
} from "../../../../actions/addressAction";
import { Link } from "react-router-dom";
import api, { supplier_endpoint, owner_endpoint } from "../../../../api/api";
import axios from "axios";
import CadastreCard from "./CadastreCard";

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import {Row, Col} from "react-bootstrap";

import { connect } from "react-redux";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../../ButtonRm";

import delete_icon from "../../../../assets/images/delete.svg";
import ImgFileCard from "./../ImgFileCard";

import CustomToggle from "../../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ExportButton from "../../../Membri/Fornitori/ExportButton";

import {type as category} from "../../constant.js"

import CustomBadge from "../../../CustomBadge";



const schema = yup.object().shape({
	category: yup.string().required("Seleziona la categoria"),
	typology: yup.string(),
	address: yup.string().required("Inserisci l’indirizzo"),
	houseNumber: yup.string().required("Inserisci il civico"),
	scale: yup.string(),
	floor: yup.string(),
	internal: yup.string(),
	cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	province: yup.string().required("Seleziona la provincia"),
	municipality: yup.string().required("Seleziona il comune"),
	name: yup.string().required("Inserisci un identificativo")
		
	});

const RegistryE = (props) => {
	
	const [summary, setSummary] = useState({});

	useEffect(() => {
		props.getProvince();
	}, []);

	useEffect(() => {
		props.getMunicipality(props.province[0]);
	}, [props.province]);

	const handleProvince = (e) => {
		onChange(e);
		props.getMunicipality(e.target.value);
	};

	const initSummary = () => {
		setSummary({
			category: props.formData.category,
			houseNumber: props.formData.houseNumber,
			municipality: props.formData.municipality,
			typology: props.formData.typology,
			scale: props.formData.scale,
			internal: props.formData.internal,
			cap: props.formData.cap,
			floor: props.formData.floor,
			name: props.formData.name,
			province: props.formData.province,
			address: props.formData.address,
			image:  props.formData.image 
		});
	};

	useEffect(() => {
		initSummary();
	}, [props]);

	
	const onChange = (e) => {
		setSummary({ ...summary, [e.target.name]: e.target.value });
	};
	
	const saveSummary = () => {
		props.update(summary);
	};

	const [edit_mode, setEditMode] = useState(false);

	const toggleEditMode = () => {
		if (edit_mode == true) {
			saveSummary();
		}
	};

	const [count, setCount] = useState(0);

	const [file, setFile] = useState([]);
	const setFileName = (index, new_name) => {
		// let temp = [...summary.attachment];
		// var file = temp[index];
		// var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		// temp[index] = new File([file], name, { type: file.type });
		// let ele = { target: { name: "attachment", value: temp } };
		// onChange(ele);

		let temp = [...file];
		var file1 = temp[index];
		var name = new_name + file1.name.substring(file1.name.lastIndexOf("."));
		temp[index] = new File([file1], name, { type: file1.type });
		setFile(temp);
	};
	const deleteFile = (index) => {
		// let temp = [...summary.attachment];
		// temp.splice(index, 1);
		// let ele = { target: { name: "attachment", value: temp } };
		// onChange(ele);
		let temp = [...file];
		temp.splice(index, 1);
		setFile(temp);
	};
	const handleChangeImg = (e) => {
		// let temp = [...summary.attachment];
		// let ele = {
		//   target: { name: e.target.name, value: [...temp, ...e.target.files] },
		// };
		// onChange(ele);
		let temp = [...file];
		setFile([...temp, ...e.target.files]);
	};

	//HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	//HANDLE IDENTIFIER
	const handleChange = (e) => {
		let ele = { target: { name: "image", value: e.target.files[0] } };
		onChange(ele);
	};
	const deleteImage = () => {
		let ele = { target: { name: "image", value: "" } };
		onChange(ele);


		if(file.length > 0){
			deleteFile(0)
		}

	};

	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		console.log(data)
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		props.forward()
	};

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {		
		if (summary && props.saveState === true) {
			props.update(summary);
		} else {
			initSummary();
		}
	}, [props.saveState, props.editMode]);


	console.log(summary.image, file)



	return (
		<>
				<Form  noValidate>
				<div className={`d-flex justify-content-end align-items-center ${
							props.editMode === false ? "" : "d-none"
						}`}>
						<div className="d-flex mb-3">
							
							<ExportButton data={summary} mapping={translationMapping} showPrint classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>
						</div>
					</div>
				<fieldset disabled={props.editMode ? false : true}>
					
				<div className="form-item-align-box d-block">
				<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Identificativo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={12}>
								<label className="f-label fs-14 mb-2">
									Scegli un identificativo univoco e un’immagine per questa unità
									immobiliare
								</label>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Identificativo
										<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Control 
										{...register("name")}
										type="text" 
										name="name"
										value={summary.name}
										onChange={onChange}
										isInvalid={!!errors.name}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.name?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>
							{/* <div className="row mt-3">
						<div className="col-md-6 d-flex justify-content-between align-items-end">
							<div className="d-flex flex-column col-sm-8">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Immagine unità immobiliareeee
								</label>
								
							</div>
							{!summary.image && props.editMode &&
							<div className="col-sm-3">
								<label
									htmlFor="input-file"
									className="bag-btn w-100 general-btn"
									style={{  textAlign: "center" }}>
									Scegli
								</label>
								<input
									type="file"
									id="input-file"
									className="d-none"
									name="image"
									multiple={false}
									onChange={handleChangeImg}
									></input>
							</div>}
						</div>
					</div> */}
				{/* {summary.image && (
						<div style={{ position: "relative" }}>
							<div className="image-file-card">
								<img loading="lazy" src={summary.image} alt="" />
							</div>
							{props.editMode && <div style={{ position: "absolute", top: "10px", left: "238px" }}>
								<button
									type="button"
									className="btn round-icon-btn small"
									onClick={deleteImage}>
									<img loading="lazy" src={delete_icon} alt="icon" />
								</button>
							</div>}
						</div>
					)}
 				*/}{/* 
					{summary.image ? (
						<div style={{ position: "relative" }}>
							<ImgFileCard id={props.id} name={summary.image} path="download_image" />
							{props.editMode && <div style={{ position: "absolute", top: "10px", left: "238px" }}>
								<button
									type="button"
									className="btn round-icon-btn small"
									onClick={deleteImage}>
									<img loading="lazy" src={delete_icon} alt="icon" />
								</button>
							</div>}
						</div>
					):
					(
						<div className="image-file-card" >
							<img loading="lazy" src={pc} alt="" /> 
						</div>
					)
					} */}
					
								
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Categoria<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
							<Col md={12} className="mb-3">
								<label className="f-label fs-14">
								Le categorie ti permettono di raggruppare diversi tipi di unità immobiliari e ti aiutano a filtrare le unità nelle tue liste.
								</label>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Categoria <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Select 
											{...register("category")}
											isInvalid={!!errors.category}
											value={summary.category}
											onChange={onChange} >
											{category.map((menu, index) => {
											return (
												<option key={index} value={menu}>
													{menu}
												</option>
											);
											})}
										</Form.Select>
										<Form.Control.Feedback type="invalid" >
											{errors.category?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								</Row>
								<Row>
								<Col md={12} className="mb-3">
								<label className="f-label fs-14">
								Le categorie ti permettono di raggruppare diversi tipi di unità immobiliari e ti aiutano a filtrare le unità nelle tue liste.
								</label>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Tipologia						
									</Form.Label>
									<Form.Control 
										{...register("typology")}
										type="text" 
										name="typology"
										value={summary.typology}
										onChange={onChange}
										isInvalid={!!errors.typology}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.typology?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>						
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Indirizzo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>						
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Indirizzo (Via/V.le/Piazza/Corso, ecc.)					
									</Form.Label>
									<Form.Control 
										{...register("address")}
										type="text" 
										name="address"
										value={summary.address}
										onChange={onChange}
										isInvalid={!!errors.address}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.address?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Numero Civico						
									</Form.Label>
									<Form.Control 
										{...register("houseNumber")}
										type="text" 
										name="houseNumber"
										value={summary.houseNumber}
										onChange={onChange}
										isInvalid={!!errors.houseNumber}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.houseNumber?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Scala						
									</Form.Label>
									<Form.Control 
										{...register("scale")}
										type="text" 
										name="scale"
										value={summary.scale}
										onChange={onChange}
										isInvalid={!!errors.scale}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.scale?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Piano						
									</Form.Label>
									<Form.Control 
										{...register("floor")}
										type="text" 
										name="floor"
										value={summary.floor}
										onChange={onChange}
										isInvalid={!!errors.floor}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.floor?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Interno						
									</Form.Label>
									<Form.Control 
										{...register("internal")}
										type="text" 
										name="internal"
										value={summary.internal}
										onChange={onChange}
										isInvalid={!!errors.internal}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.internal?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>

								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Provincia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Select 
											{...register("province")}
											isInvalid={!!errors.province}
											value={summary.province}
											onChange={handleProvince} 
											>
											<option></option>
											{props.province?.length > 0 &&
											props.province.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})} 
										</Form.Select>
										<Form.Control.Feedback type="invalid" >
											{errors.province?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>

							</Row>
							<Row>
								
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Comune <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Select 
											{...register("municipality")}
											isInvalid={!!errors.municipality}
											value={summary.municipality}
											onChange={onChange}
											>
											<option value={summary.municipality}>{summary.municipality}</option>
											{props.municipality?.length > 0 &&
											props.municipality.map((item, key) => {
												return (
													<option key={key} value={item}>
														{item}
													</option>
												)
											})}
										</Form.Select>
										<Form.Control.Feedback type="invalid" >
											{errors.municipality?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										CAP					
									</Form.Label>
									<Form.Control 
										{...register("cap")}
										type="text" 
										name="cap"
										value={summary.cap}
										onChange={onChange}
										isInvalid={!!errors.cap}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cap?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>							
						</Accordion.Body>
					</Accordion.Item>
					
				</Accordion>
				</div>
				</fieldset>
				</Form>
		</>
	);
};

const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
});
export default connect(mapStateToProps, { getProvince, getMunicipality })(
	RegistryE
);
