//DATA SOURCE FOR THE OPTIONS OF "CATEGORIE" FIELD IN EVALUATION AND UNIT SECTIONS

{
    /*
    APPARTAMENTI 0
    VILLE E VILLINI 1
    UFFICI 2
    NEGOZI 3
    BOX E AUTORIMESSE 4
    POSTI AUTO COPERERTI 5
    POSTI AUTO SCOPERTI 6
    LABORATORI 7
    MAGAZZINI 8
    CAPANNONI TIPICI 9
    CAPANNONI INDUSTRIALI 10
    */
}

const type = [
    "Appartamenti",
    "Ville e villini",
    "Uffici",
    "Negozi",
    "Box e autorimesse",
    "Posti auto coperti",
    "Posti auto scoperti",
    "Laboratori",
    "Magazzini",
    "Capannoni tipici",
    "Capannoni industriali",
];


export {type}