import React from "react";
export default function SelectToggleBox(props) {
  return (
    <select
      name=""
      id=""
      className="drop-rate"
      onChange={(e) => props.setSelectedUnit(e.target.value)}
    >
      <option value="">Tutti</option>
      {
        props.units.length > 0 && 
        props.units.map(unit => <option value={unit.id}>{unit.name} {unit?.surname? unit?.surname : ""}</option>)
      }
    </select>
  );
}
