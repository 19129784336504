import React,{ useState } from "react";
import {chat_list_endpoint} from "../../../api/api"
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import api from "../../../api/api";
import ButtonRm from "../../ButtonRm";

//MODALE CREAZIONE CHAT


export const NewChatgroupPopup = (props) =>{

    const [chatName, setChatName] = useState("")

    //CREA UNA CHAT 
    const bodyForCreation = {
        name:chatName
    }

    function handleChatName(e){
        setChatName(e.target.value)
    }

    async function postItem(data){
        try {
            await api().post(
                `/api/v1/chat-box/create`,
                data
            );
            await props.getAll();
        } catch (error) {
            console.log(error);
        }
    }

    function handleSubmit(e){
        e.preventDefault()
        let strdata
        if (bodyForCreation.name) {
          strdata = {
            ...bodyForCreation,
          };
          postItem(strdata)
        }
        props.setChatPopup(!props.chatCreatePopup)
    
    }

    

    return(
        <Modal show={props.chatCreatePopup} onHide={() => props.setChatPopup(!props.chatCreatePopup)} className="p-5" centered >
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className=" pt-md-5 px-md-5"
          >
            <Modal.Title>Nuova Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 px-md-5">
            <Form>
              <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                <Form.Label>Nome Chat</Form.Label>
                <input
                  type="text"
                  placeholder="Inserisci il nome della chat"
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem" 
                  }}
                  value={chatName} 
                  onChange={handleChatName}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer
            className="d-flex flex-column  px-md-5 pb-md-4"
            style={{ borderTop: "none" }}
          >
            <ButtonRm variant="primary" disabled={chatName? false:true} onClick={handleSubmit}>
              Salva
            </ButtonRm>
            <ButtonRm variant="tertiary" onClick={() => props.setChatPopup(!props.chatCreatePopup) }>
              Annulla
            </ButtonRm>
          </Modal.Footer>
        </Modal>
        // <!-- END Add New Chat POPUP -->
    )
}