import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoNota () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Prima Nota"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="2" label="Creazione Prima Nota" >
              <TreeItem nodeId="3" label="Versamento tra cassa e conto" />
              <TreeItem nodeId="4" label="Prelievo da conto in cassa" />
              <TreeItem nodeId="5" label="Trasferimento tra conti bancari" />
              <TreeItem nodeId="6" label="Movimento" />
            </TreeItem>

            <TreeItem nodeId="7" label="Visualizzazione lista" >
              <TreeItem nodeId="8" label="Filtra e ordina" />
            </TreeItem>
            <TreeItem nodeId="9" label="Visualizza e Modifica un elemento" />


            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1 >Prima Nota</h1>
      <h2 style={{ color:'transparent', height: 0}}>Prima Nota</h2>

      <img src="https://i.postimg.cc/GhPKP63r/panoramica.png" loading="lazy"/>

      <h2 id="2">CREAZIONE PRIMA NOTA</h2>
<h3 >Definizione della tipologia</h3>
<p >Per tipologia si intende il tipo di
pagamento che stai registrando/annotando.</p>
<p >Se si tratta di un pagamento da o verso
un soggetto terzo seleziona la voce MOVIMENTO.</p>

<p >Se si tratta di movimenti interni tra i
tuoi conti o conti e cassa seleziona le opzioni relative: 
</p>
<ul>
  <li><p >Versamento tra cassa e conto</p></li>
  <li><p >Prelievo da conto in cassa</p></li>
  <li><p >Trasferimento tra conti bancari</p></li>
</ul>
<p id="3" className="subtitle">Versamento tra cassa e conto</p>

<p >Se viene definito il versamento bisogna
procedere con l’inserimento di:</p>
<ul>
<li><p >”Descrizione dell’operazione”</p></li>
<li><p >”Data”</p></li>
<li><p >”Importi”</p></li>
<li><p >”Conti”</p></li>
</ul>

<Tips variant="info" content="Puoi creare i tuoi conti in Impostazioni > Profilo > Conti" />

<p id="4" className="subtitle">Prelievo da conto in cassa</p>

<p >Se viene definito il versamento bisogna
procedere con l’inserimento di:</p>
<ul>
  <li><p >”Descrizione dell’operazione”</p></li>
  <li><p >”Data”</p></li>
  <li><p >”Importi”</p></li>
  <li><p >”Conti”</p></li>
</ul>

<p id="5" className="subtitle">Trasferimento tra conti bancari</p>

<p >Se viene definito il versamento bisogna
procedere con l’inserimento di:</p>
<ul>
  <li><p >”Descrizione dell’operazione”</p></li>
  <li><p >”Data”</p></li>
  <li><p >”Importi”</p></li>
  <li><p >”Conti"</p></li>
</ul>

<p  id="6" className="subtitle">Movimento</p>
<p >Se viene definito il versamento bisogna
procedere con l’inserimento di:</p>

<ul><li>Unità o Stabile</li></ul>

<p >Unità o Stabile
di riferimento: Rappresenta l'immobile di riferimento al quale verrà
associata questa prima nota. Questa associazione ti tornerà utile ai
fini della rendicontazione poiché potrai recuperare automaticamente
tutte le prime note associate a uno specifico immobile o
proprietario.</p>
<p >Dopo aver selezionato l’Unità, se
hai definito conduttori e proprietari relativi a questa, apparirà di
seguito la lista dei soggetti,</p>
<p >Potrai spuntare la casella con il
soggetto al quale il pagamento che stai creando è riferito.</p>
<p >Se il pagamento proviene da o è stato
inviato a più soggetti potrai selezionare più soggetti.</p>

<img loading="lazy" src="https://i.postimg.cc/tT90sdVh/unita.png"/>


<p >A destra trovi una casella di spunta
denominata PERCENTUALE, che indica la % di attribuzione di un
pagamento al soggetto..</p>
<p >Se selezioni un unico soggetto, la
percentuale è del 100% poiché sarà completamente attribuito al
soggetto in questione.</p>
<p >Se selezioni due soggetti, di default
questa casella non è spuntata, ciò significa che l’attribuzione
del pagamento sarà del 50% per soggetto e così via.</p>
<img loading="lazy" src="https://i.postimg.cc/x8FBpWpR/percentuale.gif"/>

<p >Se spunti la casella percentuale invece
potrai andare a definire direttamente la percentuale di attribuzione
per ogni soggetto.</p>
<p >Se inserisci la percentuale manualmente
è obbligatorio, per poter procedere con la creazione della prima
nota, che la percentuale risulti 100%</p>

<Tips variant="automatization" content="L’aggiunta di un unità di
riferimento e di un soggetto relativo alla prima nota è importante
ai fini delle automatizzazioni all’interno della piattaforma. La nostra sezione RENDICONTO infatti
usa le informazioni provenienti dalla sezione prima nota per creare
dei report finanziari con entrate e uscite relative ad un’unità o 
uno specifico proprietario." />

<ul><li>Conto e Sottoconto</li></ul>

<p >Dopo aver definito unità e soggetto
di riferimento è importante selezionare Conto e Sottoconto, sempre
ai fini della rendicontazione. 
</p>
<p className="subtitle">FUNZIONE</p>
<p >La definizione del conto e del
sottoconto servono per organizzare le spese derivanti dalla prima
nota all’interno del rendiconto in macrocategorie e microcategorie.</p>

<Tips variant="suggestion" content="Se stai inserendo delle spese che non
riesci ad attribuire a nessuna delle opzioni della voce Conto, ti
consigliamo di selezionare l’opzione Spese Generali. Per quanto riguarda la voce SOTTOCONTO
qualora non trovassi la categoria di riferimento, potrai aggiungerne
una personalizzata cliccando sul tasto nero + a destra." />

<ul><li>Altri campi da definire</li></ul>


<p><strong>Descrizione dell’operazione</strong>,
inserisci una descrizione che ti aiuti a capire e riconoscere il
pagamento effettuato o ricevuto,</p>
<p ><strong>Competenza</strong>, ovvero la data
dell’operazione</p>
<p ><strong>Importo</strong>, definendo se si tratta di
un importo in entrata o uscita</p>
<p ><strong>Fornitori</strong>, eventuali fornitori legati al
pagamento 
</p>
<p ><strong>Allegati</strong></p>

<img loading="lazy" src="https://i.postimg.cc/1XwHjjpG/dati.png"/>


<h2 id="7">VISUALIZZAZIONE LISTA PRIMA NOTA</h2>
<p >Dopo aver aggiunto le tue prime note
visualizzerai una tabella di riepilogo di ogni prima nota inserita.</p>

<p >La tabella contiene: 
</p>
<ul>
	<li><p >Descrizione</p></li>
	<li><p >Data</p></li>
	<li><p >Conto di partenza</p></li>
	<li><p >Conto di destinazione</p></li>
	<li><p >Entrata</p></li>
	<li><p >Uscita</p></li>
	<li><p >Saldo</p></li>
</ul>

<img loading="lazy" src="https://i.postimg.cc/Jn9xKLB7/tabella.png"/>

<h3 id="8">FILTRA E ORDINA</h3>
<p >Potrai filtrare per conto usando il
filtro sopra la tabella.</p>
<p >Inoltre avrai la possibilità di
filtrare per</p>

<ul>
	<li><p >Periodo</p></li>
	<li><p >Categoria</p></li>
	<li><p >Pagamento</p></li>
	<li><p >Unità di riferimento</p></li>
</ul>

<p >A fianco al pulsante filtra, trovi il
pulsante ORDINA (icona con tre righe orizzontali) che ti permette di</p>
<ul>
	<li><p >Ordinare gli elementi in modo
	alfabetico dalla A alla Z o viceversa</p></li>
	<li><p >Ordinare gli elementi mettendo
	prima quelli creati più di recente o in alternativa mettendo per
	primi quelli creati meno recentemente</p></li>
</ul>

<img loading="lazy" src="https://i.postimg.cc/yNGyYHjh/tipologie.gif"/>


<h2 id="9">VISUALIZZA E MODIFICA
UNA PRIMA NOTA</h2>
<p >Cliccando su una riga della tabella
potrai visualizzare la prima nota creata.</p>
<img loading="lazy" src="https://i.postimg.cc/k4tF2pY0/open-nota.gif"/>

<p >Cliccando il bottone MODIFICA, puoi
modificare la prima nota creata.</p>
<p >Cliccando sul bottone con l’icona della "x"
potrai eliminare la prima nota creata.</p>
<img loading="lazy" src="https://i.postimg.cc/W4151SvV/salva-modifiche.png"/>


<p >Puoi stampare e ed esportare i dati
cliccando sui bottoni in alto a sinistra.</p>
<img loading="lazy" src="https://i.postimg.cc/Y948190y/stampa.png"/>

    </div>
  );
}

