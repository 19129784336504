import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsPen} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import pc from '../../assets/images/placeholder-colorato.png'

import TopHeading from "../TopHeading";

import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import SearchInput from "../SearchInput";
import "../../assets/css/Pagina.css"

import {verifications, company, servicesEx, productsEx, projectsEx, workExperiences, certifications, 
    publicationsEx, reviews, filterItems
} from "./data";

import StarRating from "./StarRating";
import FilterItem from "./FilterItem";

import Select from 'react-select';
import FilterFornitori from "./FilterFornitori";

import { useParams } from "react-router-dom";

import api, { public_page,
    portfolio_endpoint, 
    portfolio_page_assignment_enpoint,
    service_endpoint,
    service_page_assignment_endpoint,
    product_endpoint,
    product_page_assignment_endpoint,
    experience_endpoint,
    experience_page_assignment_endpoint,
    certification_endpoint,
    certification_page_assignment_endpoint,
    publication_endpoint,
    publication_page_assignment_endpoint
} from "../../api/api";

import options from "../../partnerList";



    //PROGETTI
    const roles = [
        {
            category:"Progetto 1",
            img:"https://images.pexels.com/photos/6474205/pexels-photo-6474205.jpeg?auto=compress&cs=tinysrgb&w=1600"
        },
        {
            category:"Progetto 2",
            img:"https://images.pexels.com/photos/5691588/pexels-photo-5691588.jpeg?auto=compress&cs=tinysrgb&w=1600"
        },
        {
            category:"Progetto 3",
            img:"https://images.pexels.com/photos/5691479/pexels-photo-5691479.jpeg?auto=compress&cs=tinysrgb&w=1600"
        },
        {
            category:"Progetto 4",
            img:"https://images.pexels.com/photos/5561310/pexels-photo-5561310.jpeg?auto=compress&cs=tinysrgb&w=1600"
        },
        {
            category:"Progetto 5",
            img:"https://images.pexels.com/photos/5561310/pexels-photo-5561310.jpeg?auto=compress&cs=tinysrgb&w=1600"
        }
    ]


export default function FornitoriPage(){

   /*  const [form, setForm] = useState({
        name:"",
        typologies:[],
        address:"",
        hourlyFee:"",
        status: ""
    }) */


    const {pageId} = useParams()

    const [form, setForm] = useState(useState({
        title: "",
        typologies: [
        ],
        image: "",
        address: "",
        pay: "",
        availableNow: true,
        cityWorkplace : "",
        provinceWorkplace: "",
        portfolios: [],
        certifications: [],
        experiences: [],
        publications: [],
        services: [],
        products: []
        }))
    

        async function fetchOne() {
            api().get(`${public_page}${pageId}`)
                .then(response => {
                setForm(response.data)
                })
                .catch(error => {
                    console.log('Errore nella richiesta GET:', error);
                });
        }
    

    useEffect(() => {
        fetchOne()
    }, [pageId])

    const handleForm = (e) => {
        const { name, value, type, checked, options } = e.target;
            setForm((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    //GESTIONE EDIT SEZIONE
    const [editInfoBox, setEditInfoBox] = useState(false)
    const [editCopertina, setEditCopertina] = useState(false)
    const [editPortfolio, setEditPortfolio] = useState(false)
    const [editService, setEditService] = useState(false)
    const [editPublication, setEditPublication] = useState(false)
    const [editCertificato, setEditCertificato] = useState(false)
    const [editExperience, setEditExperience] = useState(false)
    

    //GESTIONE LOGO
    const [logoImg, setLogoImg] = useState(roles[0].img);

    const handleUploadLogoImg = (event) => {
        const file = event.target.files[0];
        setLogoImg(file);
    };

    const handleRemoveLogoImg = () => {
        setLogoImg(null);
    };

    //GESTIONE COPERTINA
    const [selectedHeroImage, setSelectedHeroImage] = useState(null);

    const handleOpenModal = () => {
        const fileInput = document.getElementById("heroImgInput");
        if(editCopertina){
            fileInput.click()
        }
    };

    const handleUploadHeroImage = (event) => {
        const file = event.target.files[0];
        const imageURL = URL.createObjectURL(file);
        setSelectedHeroImage(imageURL)
        setEditCopertina(!editCopertina)
    };

    const heroImageStyle = {
        backgroundImage: selectedHeroImage ? `url(${selectedHeroImage})` : "url(https://images.pexels.com/photos/323780/pexels-photo-323780.jpeg)",
    };

    useEffect(() => {
        if (editCopertina) {
            handleOpenModal();
        }
    }, [editCopertina]);

    //HANDLE PORTFOLIO
    const [showAll, setShowAll] = useState(false);
    const [visibleCards, setVisibleCards] = useState(3);
    //const [projects, setProjects] = useState(projectsEx)

    const newProjectData = {
        title: '',
        description: '',
        skill: '',
        img: null
    }

    //HANDLE A NEW PORTFOLIO PROJECT
    const [showPortfolioCreate, setShowPortfolioCreate] = useState(false)
    const [newProject, setNewProject] = useState(newProjectData)

    function handleNewProject(e){
        const {name, value, type, files} = e.target
        setNewProject(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }

    

    function saveNewProject(){
       /*  if(newProject.name){
            setProjects(prev => {
                return [...prev, newProject]
            })
        }
        setNewProject(newProjectData)
        setShowPortfolioCreate(false) */
    }

    const handleShowAll = () => {
        setShowAll(true);
        setVisibleCards(roles.length);
    };

    const handleHideAll = () => {
        setShowAll(false);
        setVisibleCards(3);
    };

    //SHOW PORTFOLIO PROJECT

    const [showPortfolioProject, setShowPortfolioProject] = useState(false)
    const [currentPortfolioProject, setCurrentPortfolioProject] = useState(null)
    const [currentPortfolioProjectId, setCurrentPortfolioProjectId] = useState(null)


    function handleCurrentPortfolioProject(id){
        const el = form?.portfolios.length > 0 && form.portfolios.find((item, index) => item.id == id)
        setCurrentPortfolioProject(el)
        setShowPortfolioProject(true)
    }


    //EDIT PORTFOLIO PROJECT
    const [showEditPortfolioProject, setShowEditPortfolioProject] = useState(false)

    function handleEditPortfolioProject(id){
        const el = form?.portfolios.length > 0 && form.portfolios.find((item, index) => item.id == id)
        setCurrentPortfolioProject(el)
        setShowEditPortfolioProject(true)
        setCurrentPortfolioProjectId(id)
    }

    function handleEditProjectForm(e){
        const {name, value, type, files} = e.target
        setCurrentPortfolioProject(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }

    function updateProject(){
        api().put(`${portfolio_endpoint}${currentPortfolioProjectId}`, currentPortfolioProject)
        .then(() => {
            fetchOne()
            setCurrentPortfolioProject(null)
            setCurrentPortfolioProjectId(null)
            setShowEditPortfolioProject(false)
        })
    }



    function removePortfolioImg(index, type) {
        if(type == 'create'){
            console.log("CREATE", index, type, newProject.img)
            const updatedImages = Object.entries(newProject.img)
            .filter(([key, value]) => Number(key) != index)
            .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
            }, {});
            console.log(updatedImages)

            // Aggiorna lo stato con le immagini aggiornate
            setNewProject((prevState) => ({
                ...prevState,
                img: updatedImages,
            }))
        } else {
            console.log("EDIT", currentPortfolioProject)

            const updatedImages = Object.entries(currentPortfolioProject.img)
            .filter(([key, value]) => Number(key) != index)
            .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
            }, {});
            setCurrentPortfolioProject((prevState) => ({
                ...prevState,
                img: updatedImages,
            }))
        }
    }

     //DELETE PORTFOLIO PROJECT
    const [showDeletePortfolioProject, setShowDeletePortfolioProject] = useState(false)

    function handleDeleteProject(id){
        setCurrentPortfolioProjectId(id)
        setShowDeletePortfolioProject(true)
    }

    function deleteProject(){
        api().delete(`${portfolio_endpoint}${currentPortfolioProjectId}`)
        .then(() => {
            setCurrentPortfolioProjectId(null)
            setShowDeletePortfolioProject(false)
            fetchOne();
        })
    }
    
    //HANDLE SERVIZI E PRODOTTI
    const [tabState, setTabState] = useState('servizi');

    //HANDLE SERVIZI
    const [showServiceCreate, setShowServiceCreate] = useState(false)
    //const [services, setServices] = useState(servicesEx)

    const newServiceData = {
        name: '',
        description: '',
        type: 'hourly',
        cost: '',
        min: null,
        max: null
    }

    //HANDLE A NEW SERVICE PROJECT
    const [newService, setNewService] = useState(newServiceData)

    function handleNewService(e){
        const {name, value, type, files} = e.target
        setNewService(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }

    function saveNewService(){
   /*      if(newService.name){
            setServices(prev => {
                return [...prev, newService]
            })
        }
        setNewService(newServiceData)
        setShowServiceCreate(false) */
    }

    //VIEW SERVICE
    const [showService, setShowService] = useState(false)
    const [currentService, setCurrentService] = useState(null)
    const [currentServiceId, setCurrentServiceId] = useState(null)

    function handleCurrentService(id){
        const el = form?.services.find((item, index) => item.id == id)
        setCurrentService(el)
        setShowService(true)
    }

     //EDIT SERVICE
    const [showEditService, setShowEditService] = useState(false)

    function handleEditService(id){
        const el = form?.services.find((item, index) => item.id == id)
        setCurrentService(el)
        setShowEditService(true)
        setCurrentServiceId(id)
    }

    function handleEditServiceForm(e){
        const {name, value, type, files} = e.target
        setCurrentService(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }

    function updateService(){
        api().put(`${service_endpoint}${currentServiceId}`, currentService)
        .then(() => {
            fetchOne()
            setCurrentService(null)
            setCurrentServiceId(null)
            setShowEditService(false)
        })
    }

     //DELETE SERVICE
    const [showDeleteService, setShowDeleteService] = useState(false)

    function handleDeleteService(id){
    setCurrentServiceId(id)
    setShowDeleteService(true)
    }

    function deleteService(){
        api().delete(`${service_endpoint}${currentServiceId}`)
        .then(() => {
            //setServices(newArr)
            setCurrentServiceId(null)
            setShowDeleteService(false)
            fetchOne();
        })
    }

    //HANDLE PRODOTTI
    const [showProductCreate, setShowProductCreate] = useState(false)
    //const [products, setProducts] = useState(productsEx)

    const newProductData = {
        name: '',
        description: '',
        cost: null,
        discount: false,
        discountedCost: null,
        img: null
    }

    //HANDLE A NEW PRODUCT
    const [newProduct, setNewProduct] = useState(newProductData)

    function handleNewProduct(e){
        const {name, value, type, files} = e.target
        setNewProduct(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }

    function saveNewProduct(){
       /*  if(newProduct.name){
            setProducts(prev => {
                return [...prev, newProduct]
            })
        }
        setNewProduct(newProductData)
        setShowProductCreate(false) */
    }


    //VIEW PRODUCT

    const [showProduct, setShowProduct] = useState(false)
    const [currentProduct, setCurrentProduct] = useState(null)
    const [currentProductId, setCurrentProductId] = useState(null)

 
    function handleCurrentProduct(id){
        const el = form?.products?.find((item, index) => item.id == id)
        setCurrentProduct(el)
        setShowProduct(true)
    }

    //console.log(currentProduct)
    //EDIT PRODUCT
    const [showEditProduct, setShowEditProduct] = useState(false)

    
    function handleEditProduct(id){
        const el = form?.products?.find((item, index) => item.id  == id)
        setCurrentProduct(el)
        setShowEditProduct(true)
        setCurrentProductId(id)
    }
 
    function handleEditProductForm(e){
        const {name, value, type, files, checked} = e.target
        setCurrentProduct(prev => {
            if(type == 'file'){
                return {
                    ...prev,
                    [name]:  files 
                }
            }  else if(type == 'checkbox'){
                return {
                    ...prev,
                    [name]:  checked 
                }
            } else {
                return {
                    ...prev,
                    [name]:  value
                }
        }
        })
    }
 
    function updateProduct(){
        api().put(`${product_endpoint}${currentProductId}`, currentProduct)
        .then(() => {
            fetchOne()
            setCurrentProduct(null)
            setCurrentProductId(null)
            setShowEditProduct(false)
        })
    }
 
    //DELETE PRODUCT
    const [showDeleteProduct, setShowDeleteProduct] = useState(false)

    function handleDeleteProduct(id){
    setCurrentProductId(id)
    setShowDeleteProduct(true)
    }

    function deleteProduct(){
        api().delete(`${product_endpoint}${currentProductId}`)
        .then(() => {
            fetchOne()
            setCurrentProductId(null)
            setShowDeleteProduct(false)
        })
    }

    function removeProductImg(index, type) {
        if(type == 'create'){
            console.log("CREATE", index, type, newProduct.img)
            const updatedImages = Object.entries(newProduct.img)
            .filter(([key, value]) => Number(key) != index)
            .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
            }, {});
            console.log(updatedImages)

            // Aggiorna lo stato con le immagini aggiornate
            setNewProduct((prevState) => ({
                ...prevState,
                img: updatedImages,
            }))
        } else {
            console.log("EDIT", currentProduct)

            const updatedImages = Object.entries(currentProduct.img)
            .filter(([key, value]) => Number(key) != index)
            .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
            }, {});
            setCurrentProduct((prevState) => ({
                ...prevState,
                img: updatedImages,
            }))
        }
    }


    //HANDLE CERTIFICATI
    const [showCertificateCreate, setShowCertificateCreate] = useState(false)
    //const [certificates, setCertificates] = useState(certifications)

    const newCertificateData = {
        title: '',
        year: ''
    }

    //HANDLE A NEW CERTIFICATE
    const [newCertificate, setNewCertificate] = useState(newCertificateData)

    function handleNewCertificate(e){
        const {name, value, type, files} = e.target
        setNewCertificate(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }

    function saveNewCertificate(){
       /*  if(newCertificate.name){
            setCertificates(prev => {
                return [...prev, newCertificate]
            })
        }
        setNewCertificate(newCertificateData)
        setShowCertificateCreate(false) */
    }

    //VIEW CERTIFICATE
    const [showCertificate, setShowCertificate] = useState(false)
    const [currentCertificate, setCurrentCertificate] = useState(null)
    const [currentCertificateId, setCurrentCertificateId] = useState(null)

 
    function handleCurrentCertificate(id){
        const el = form?.certifications?.find((item, index) => item.id == id)
        setCurrentCertificate(el)
        setShowCertificate(true)
        setCurrentCertificateId(id)
    }

    //EDIT CERTIFICATE
    const [showEditCertificate, setShowEditCertificate] = useState(false)

    function handleEditCertificate(id){
        const el = form?.certifications?.find((item, index) => item.id == id)
        setShowCertificate(false)
        setCurrentCertificate(el)
        setShowEditCertificate(true)
        setCurrentCertificateId(id)
    }
 
    function handleEditCertificateForm(e){
        const {name, value, type, files} = e.target
        setCurrentCertificate(prev => {
            return {
                ...prev,
                [name]: type === "file"? files : value
            }
        })
    }
 
    function updateCertificate(){
        api().put(`${certification_endpoint}${currentCertificateId}`, currentCertificate)
        .then(() => {
            fetchOne()
            setCurrentCertificate(null)
            setCurrentCertificateId(null)
            setShowEditCertificate(false)
        })
    }
 
    //DELETE CERTIFICATE
    const [showDeleteCertificate, setShowDeleteCertificate] = useState(false)

    function handleDeleteCertificate(id){
    setShowCertificate(false)
    setCurrentCertificateId(id)
    setShowDeleteCertificate(true)
    }

    function deleteCertificate(){
        api().delete(`${certification_endpoint}${currentCertificateId}`)
        .then(() => {
            fetchOne()
            setCurrentCertificateId(null)
            setShowDeleteCertificate(false)
            setShowCertificate(false)
        })

    }

    // HANDLE EXPERIENCES
const [showExperienceCreate, setShowExperienceCreate] = useState(false);
//const [experiences, setExperiences] = useState(workExperiences);

const newExperienceData = {
  company: '',
  role:'',
  year: ''
};

// HANDLE A NEW EXPERIENCE
const [newExperience, setNewExperience] = useState(newExperienceData);

function handleNewExperience(e) {
  const { name, value, type, files } = e.target;
  setNewExperience(prev => {
    return {
      ...prev,
      [name]: type === "file" ? files : value
    };
  });
}

function saveNewExperience() {
 /*  if (newExperience.role) {
    setExperiences(prev => {
      return [...prev, newExperience];
    });
  }
  setNewExperience(newExperienceData);
  setShowExperienceCreate(false); */
}

    // VIEW EXPERIENCE
    const [showExperience, setShowExperience] = useState(false);
    const [currentExperience, setCurrentExperience] = useState(null);
    const [currentExperienceId, setCurrentExperienceId] = useState(null);

    
    function handleCurrentExperience(id) {
        const el = form?.experiences?.find((item, index) => item.id === id);
        setCurrentExperience(el);
        setShowExperience(true);
        setCurrentExperienceId(id);
        }
    
        // EDIT EXPERIENCE
        const [showEditExperience, setShowEditExperience] = useState(false);
    
        function handleEditExperience(id) {
        const el = form?.experiences?.find((item, index) => item.id === id);
        setShowExperience(false);
        setCurrentExperience(el);
        setShowEditExperience(true);
        setCurrentExperienceId(id);
        }
    
        function handleEditExperienceForm(e) {
        const { name, value, type, files } = e.target;
        setCurrentExperience(prev => {
            return {
            ...prev,
            [name]: type === "file" ? files : value
            };
        });
        }
        function updateExperience() {
            api().put(`${experience_endpoint}${currentExperienceId}`, currentExperience)
            .then(() => {
                fetchOne()
                setCurrentExperience(null);
                setCurrentExperienceId(null);
                setShowEditExperience(false);
            })
        }

    // DELETE EXPERIENCE
    const [showDeleteExperience, setShowDeleteExperience] = useState(false);

    function handleDeleteExperience(id) {
    setShowExperience(false);
    setCurrentExperienceId(id);
    setShowDeleteExperience(true);
    }

    function deleteExperience() {

        api().delete(`${experience_endpoint}${currentExperienceId}`)
        .then(() => {
            fetchOne()
            setCurrentExperienceId(null);
            setShowDeleteExperience(false);
            setShowExperience(false);
        })
    }

    // HANDLE PUBLICATIONS
const [showPublicationCreate, setShowPublicationCreate] = useState(false);
const [publications, setPublications] = useState( publicationsEx);

const newPublicationData = {
  title: '',
  magazine:'',
  year: ''
};

// HANDLE A NEW PUBLICATION
const [newPublication, setNewPublication] = useState(newPublicationData);

function handleNewPublication(e) {
  const { name, value, type, files } = e.target;
  setNewPublication(prev => {
    return {
      ...prev,
      [name]: type === "file" ? files : value
    };
  });
}

function saveNewPublication() {
/*   if (newPublication.title) {
    setPublications(prev => {
      return [...prev, newPublication];
    });
  }
  setNewPublication(newPublicationData);
  setShowPublicationCreate(false); */
}

// VIEW PUBLICATION
const [showPublication, setShowPublication] = useState(false);
const [currentPublication, setCurrentPublication] = useState(null);
const [currentPublicationId, setCurrentPublicationId] = useState(null);

function handleCurrentPublication(id) {
  const el = publications.find((item, index) => index === id);
  setCurrentPublication(el);
  setShowPublication(true);
  setCurrentPublicationId(id);
}

// EDIT PUBLICATION
const [showEditPublication, setShowEditPublication] = useState(false);

function handleEditPublication(id) {
    const el = form?.publications?.find((item, index) => item.id === id);
    setShowPublication(false);
    setCurrentPublication(el);
    setShowEditPublication(true);
    setCurrentPublicationId(id);
  }
  
  function handleEditPublicationForm(e) {
    const { name, value, type, files } = e.target;
    setCurrentPublication(prev => {
      return {
        ...prev,
        [name]: type === "file" ? files : value
      };
    });
  }
  
  function updatePublication() {
  
      api().put(`${publication_endpoint}${currentPublicationId}`, currentPublication)
      .then(() => {
          fetchOne()
          setCurrentPublication(null);
          setCurrentPublicationId(null);
          setShowEditPublication(false);
      })
  
  }
// DELETE PUBLICATION
const [showDeletePublication, setShowDeletePublication] = useState(false);

function handleDeletePublication(id) {
  setShowPublication(false);
  setCurrentPublicationId(id);
  setShowDeletePublication(true);
}

function deletePublication() {

    api().delete(`${publication_endpoint}${currentPublicationId}`)
          .then(() => {
              fetchOne()
              setCurrentPublicationId(null);
              setShowDeletePublication(false);
              setShowPublication(false);
          })
  
    
  }

//bookmark and like

const [like, setLike] = useState(false)
const [bookmark, setBookmark] = useState(false)

// reviews
const [showReview, setShowReview] = useState(false);

const totalReviews = reviews.length;
let totalStars = 0;

// Calcola il totale delle stelle
reviews.forEach(review => {
totalStars += review.stelle;
});

  // Calcola la media delle stelle
    const averageStars = totalReviews > 0 ? totalStars / totalReviews : 0;
    const roundedAverage = Math.floor(averageStars);
    const hasHalfStar = averageStars - roundedAverage >= 0.5;


  //GESTIONE FILTRI => MA CHE FILTRI? PER FILTRARE COSA?

  const [activeFilters, setActiveFilters] = useState([]);
  const maxFiltersToShow = 5; // Numero massimo di filtri da mostrare prima di utilizzare il pulsante "Mostra tutti"


    return(
        
        <>
            <div className="title-search-group">
                <div className="right-header">
                    <TopHeading SmallHeading="" back_modal={false}/>
                </div>
            </div> 
            
           

            {/* HERO */}
            <Row className="pagina-hero mt-3 mb-0 justify-content-between"  style={heroImageStyle}>
                <Col xl={2} ></Col>
                <Col xl={6} className="col-hero-search-view">
                    <div className="pagina-hero-search-view rounded">
                        <Row>
                            <label htmlFor="logoImgInput">
                                <div className="roundImgContainer roundImg  mx-auto">
                                {logoImg ? (
                                    <img
                                    src={logoImg}
                                    alt="Logo"
                                    className="roundImg rounded-circle border avatar-pagina"
                                    />
                                ) : (
                                    <div className="placeholderImg border rounded-circle avatar-pagina d-flex align-items-center justify-content-center">
                                        <i class="bi bi-image-alt fs-32"></i>
                                    </div>
                                )}
                                {(logoImg && editInfoBox) && (
                                    <img
                                    className="roundImgRemove "
                                    src={removeImg}
                                    alt="Remove"
                                    onClick={handleRemoveLogoImg}
                                    
                                    />
                                )}
                                </div>
                            </label>
                            <input
                                id="logoImgInput"
                                type="file"
                                accept="image/*"
                                onChange={handleUploadLogoImg}
                                style={{ display: 'none' }}
                                disabled={editInfoBox ? false : true}
                            />
                        </Row>
                            <>
                            <h2 className="fs-20 fs-md-32 fw-bold mb-1 mt-2" style={{ lineHeight:'1.2'}}>{form.title? form.title : "Nome Azienda"}</h2>
                            <p className="mb-3">{form.typologies?.length > 0 ? 
                            form.typologies.map((category, index) => <CustomBadge variant="custom-badge-pagina" key={index}>{category}</CustomBadge>) : "Categorie"}</p>

                            <div className="d-flex align-items-center justify-content-center mb-2">
                                {/* Mostra le icone delle stelle colorate in base alla media */}
                                {Array.from({ length: 5 }, (_, index) => {
                                    if (index < roundedAverage) {
                                    return <i key={index} className="bi bi-star-fill" ></i>;
                                    } else if (index === Math.floor(averageStars) && hasHalfStar) {
                                    return <i key={index} className="bi bi-star-half"></i>;
                                    } else {
                                    return <i key={index} className="bi bi-star" style={{ color: '' }}></i>;
                                    }
                                })}
                                <p className="ms-2 fs-14 fs-md-16 text-decoration-underline fw-bold" onClick={()=> {
                                    setShowReview(true)}}>{totalReviews} recensioni</p>
                             </div>
                                

                            <p className="fs-14 fs-md-16"><i class="bi bi-geo-alt me-2 mb-2"></i>{form.address? form.address : "Indirizzo"}</p>

                            <div className="d-md-flex align-items-md-center justify-content-md-between mt-2 mt-md-3">
                                <p className="fs-14 fs-md-16"><i class="bi bi-cash-coin me-2 "></i>{form.pay? `Tariffa Oraria € ${form.pay}` : "Tariffa Oraria "}</p>
                                <p className="fs-14 fs-md-16 mt-1 mt-md-0"><i class={`bi bi-lightning-charge-fill me-2 ${(form.availableNow == "Disponibilità immediata" || form.availableNow == "Disponibile") ? "text-success" : "text-danger"}`}></i>{form?.availableNow? "Disponibile" : "Non Disponibile"}</p>
                            </div>
                            </>
                        

                        
                    </div>
                    <label htmlFor="heroImgInput" className="hero-img-input-label-view"></label>
                    <input
                        id="heroImgInput"
                        type="file"
                        accept="image/*"
                        onChange={handleUploadHeroImage}
                        style={{ display: 'none' }}
                        disabled={editCopertina ? false : true} 
                    />
                        {/* <div className="edit-icon-pagina rounded-circle w-50 h-50" onClick={() => {
                            setEditCopertina(!editCopertina)
                        }}>
                            {editCopertina? <i class="bi bi-check2-circle"></i> : <i class="bi bi-pen"></i>} 
                        </div> */}
                     <div className="edit-icon-pagina-bookmark rounded-circle w-30 h-30" onClick={() => setBookmark(!bookmark)}>
                            <i className={`${ bookmark ? "bi bi-bookmark-fill text-warning" : "bi bi-bookmark" }`}></i>
                        </div>     
                        
                        <div className="edit-icon-pagina-heart rounded-circle w-30 h-30" onClick={() => setLike(!like)}>
                            <i className={`${ like ? "bi bi-suit-heart-fill text-danger" : "bi bi-suit-heart" }`}></i>
                        </div>
                </Col>
                <Col xl={4} className="d-none d-xl-block col-hero-contact-view ">
                    <Col className="pagina-categories mt-4 pt-5 fornitori-margin-to text-center rounded"> 
                            <p className="fs-20 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Affidati a un professionista! Contatta {form.title} per un servizio di qualità</p>
                            
                            <ButtonRm
                            variant="primary"
                            size="small"
                            className="my-4"
                            
                            >
                            Contatta
                            </ButtonRm>
                            <p className='fs-12 '>Cliccando su Contatta, dichiari di aver letto e di accettare i nostri termini e condizioni e la normativa sulla privacy .</p>
                            
                            
                            
                           {/*  <div className="edit-icon-pagina-bottom rounded-circle w-50 h-50" onClick={() => { setEditExperience(!editExperience) }}>
                                {editExperience ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col xl={4} className="d-block d-xl-none">
                    <Col className="pagina-categories-contact mt-4 pt-5 fornitori-margin-to text-center rounded h-auto"> 
                            <p className="fs-20 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Affidati a un professionista! Contatta {form.title} per un servizio di qualità</p>
                            
                            <ButtonRm
                            variant="primary"
                            size="small"
                            className="my-4"
                            
                            >
                            Contatta
                            </ButtonRm>
                            <p className='fs-12 '>Cliccando su Contatta, dichiari di aver letto e di accettare i nostri termini e condizioni e la normativa sulla privacy .</p>
                            
                            
                            
                           {/*  <div className="edit-icon-pagina-bottom rounded-circle w-50 h-50" onClick={() => { setEditExperience(!editExperience) }}>
                                {editExperience ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                    </Col>
                </Col>
            </Row>
            <Row >
                <Col xl={2}>
                    <div className="">
                   {/*  <FilterFornitori 
                        filterItems={filterItems} 
                        title='Filtri' 
                        activeFilters={activeFilters} 
                        setActiveFilters={setActiveFilters} 
                        maxFiltersToShow={maxFiltersToShow}
                    /> */}
                    </div>
                </Col>
                <Col xl={6}>
                    {/* PORTFOLIO */}
                    <div className="pagina-categories mt-4 fornitori-margin-top rounded"> 
                        <p className="fs-24 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Portfolio</p>
                        {editPortfolio && <ButtonRm variant="outline" size="small" className="my-2"
                            onClick={()=> setShowPortfolioCreate(true)}
                        >Aggiungi progetto</ButtonRm>}
                        {/* <div className="edit-icon-pagina rounded-circle w-50 h-50" onClick={() => { setEditPortfolio(!editPortfolio) }}>
                            {editPortfolio? <i className="bi bi-check2-circle mt-2"></i> : <i className="bi bi-pen"></i>} 
                        </div> */}
                        <div className="pagina-categories-carousel p-0 mb-2">
                            {form?.portfolios?.length > 0 && form.portfolios.slice(0, visibleCards).map((el, index) => (
                                <CarouselCardPartner 
                                    img={el.img?.[0]? URL.createObjectURL(el.img[0]) : pc} 
                                    category={el.title} 
                                    key={index}
                                    id={el.id} 
                                    handleCurrentPortfolioProject={handleCurrentPortfolioProject}
                                    handleEdit={handleEditPortfolioProject}
                                    handleDelete={handleDeleteProject}
                                    editPortfolio={editPortfolio}
                                />
                            ))}
                            {form?.portfolios?.length == 0 && <div className={`d-flex justify-content-center align-items-center w-100 ${editPortfolio? 'mt-3' : 'mt-5 pt-3'}`}>Nessun progetto</div>
                            }
                        </div>
                        {form?.portfolios?.length > 3 && <div className="p-0 text-center">
                            {!showAll && roles.length > visibleCards && (
                                <ButtonRm
                                    variant="text"
                                    size="small"
                                    className="my-3 mx-auto text-decoration-underline"
                                    onClick={handleShowAll}
                                >
                                    Mostra tutto
                                </ButtonRm>
                            )}
                            {showAll && (
                                <ButtonRm
                                    variant="text"
                                    size="small"
                                    className="my-3 mx-auto text-decoration-underline"
                                    onClick={handleHideAll}
                                >
                                    Nascondi
                                </ButtonRm>
                            )}
                        </div>}
                    </div>
                    
                    {/* SERVIZI E PRODOTTI */}
                    <div className="pagina-categories pt-5 mt-3 rounded"> 
                        <div className={`tab-state mt-0 mb-2`}>
                            <div className="d-flex">
                                <div
                                    className="tab-container"
                                    onClick={(e) => {
                                        setTabState('servizi');
                                    }}>
                                    <label className={tabState === "servizi" ? "primary-color" : "non-active"}>
                                        Servizi
                                    </label>
                                    <div
                                        className={`tab-bar ${
                                            tabState === 'servizi' ? "active-tab-bar-create" : ""
                                        }`}></div>
                                </div>
                                <div
                                    className="tab-container"
                                    onClick={(e) => {
                                        setTabState('prodotti');
                                    }}
                                    style={{ marginLeft: "-5px" }}>
                                    <label className={tabState === 'prodotti' ? "primary-color" : "non-active"}>
                                        Prodotti
                                    </label>
                                    <div
                                        className={`tab-bar ${
                                            tabState === 'prodotti' ? "active-tab-bar-create" : ""
                                        }`}></div>
                                </div>
                            </div>
                        </div>
                        {tabState == "servizi" && <div>
                            {editService && <ButtonRm variant="outline" size="small" className="my-2"
                                onClick={()=> setShowServiceCreate(true)}
                            >Aggiungi servizio</ButtonRm>}
                            <div className="mt-3">
                            {
                               form?.services?.length > 0 &&
                               form.services.map((el, index) => {
                                    return <div className="my-2 border rounded p-3 d-flex" key={index} onClick={() => handleCurrentService(el.id)}>
                                    <Col>
                                        <p className="text-capitalize fw-bold">{el.name}</p>
                                        <p className="text-capitalize fs-14">€{el.min}-{el.max}</p>
                                    </Col>
                                    {editService && <Col className="d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn round-icon-btn"
                                            onClick={(e)  => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                handleDeleteService(index)
                                            }}
                                        >
                                            <BsTrash />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn round-icon-btn"
                                            onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    handleEditService(index)
                                                }
                                            }
                                        >
                                            <BsPen />
                                        </button>
                                    </Col>}
                                </div>
                                })
                            }
                            {form?.services?.length == 0 && <div className={`d-flex justify-content-center align-items-center w-100  mt-5 pb-5`}>Nessun servizio</div>
                            }
                            </div>

                           {/*  <div className="edit-icon-pagina rounded-circle w-50 h-50" onClick={() => { setEditService(!editService) }}>
                                {editService? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                        </div>}
                        {tabState == "prodotti" && <div>
                            {editService && <ButtonRm variant="outline" size="small" className="my-2"
                                onClick={()=> setShowProductCreate(true)}
                            >Aggiungi prodotto</ButtonRm>}
                            <div className="mt-3">
                            {
                                form?.products?.length > 0 &&
                                form?.products?.map((el, index) => {
                                    return <div className="my-2 border rounded p-3 d-flex" key={index} onClick={() => handleCurrentProduct(el.id)}>
                                    <Col>
                                    <p className="text-capitalize fw-bold">{el.name}</p>
                                <p className="text-capitalize fs-14">€ {(el.discount && el.discountedCost)? el.discountedCost : el.cost}</p>
                                    </Col>
                                    {editService && <Col className="d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn round-icon-btn"
                                            onClick={(e)  => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                handleDeleteProduct()
                                            }}
                                        >
                                            <BsTrash />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn round-icon-btn"
                                            onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    handleEditProduct(index)
                                                }
                                            }
                                        >
                                            <BsPen />
                                        </button>
                                    </Col>}
                                </div>
                                })
                            }
                            {form?.products?.length == 0 && <div className={`d-flex justify-content-center align-items-center w-100  mt-5 pb-5`}>Nessun prodotto</div>
                            }
                            </div>

                            {/* <div className="edit-icon-pagina rounded-circle w-50 h-50" onClick={() => { setEditService(!editService) }}>
                                {editService? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>} 
                            </div> */}
                        </div>}
                        
                    </div>
                    
                </Col>
                <Col xl={4}>
                    {/* INFO */}
                    <Col className="pagina-categories mt-3 pt-5 fornitori-margin-top rounded"> 
                            <p className="fs-24 fw-bold mt-0 mb-3" style={{ lineHeight:'1.2'}}>Verifiche</p>
                            {editPublication && (
                                <ButtonRm
                                variant="outline"
                                size="small"
                                className="my-2"
                                onClick={(e) => {
                                    setShowPublicationCreate(true)
                                }}
                                >
                                Aggiungi
                                </ButtonRm>
                            )}
                     
                                {   
                                    verifications.map((el, index) => {
                                        return <div className={`d-flex mb-3 justify-content-between ${el.status? 'text-success' : 'text-danger'}`} key={index}>
                                            <div className='d-flex align-items-center'>
                                                <i className={`${el.icon} text-dark`}></i>
                                                <p className="ms-2 text-dark">{el.name}</p>
                                            </div>
                                            <i className={el.status? 'bi bi-check-circle-fill' : 'bi bi-x-circle-fill'}></i>
                                        </div>
                                    })
                                }
                                
                            
                            {/* <div className="edit-icon-pagina-bottom rounded-circle w-50 h-50" onClick={() => { setEditPublication(!editPublication) }}>
                                {editPublication ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                        </Col>
                        <Col className="pagina-categories mt-4 pt-5  rounded"> 
                            <p className="fs-24 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Esperienze</p>
                            {editExperience && (
                                <ButtonRm
                                variant="outline"
                                size="small"
                                className="my-2"
                                onClick={(e) => {
                                    setShowExperienceCreate(true)
                                }}
                                >
                                Aggiungi
                                </ButtonRm>
                            )}
                            {
                                 form?.experiences?.length > 0 ?
                                 form.experiences.map((el, index) => {
                                    return <ul className="list-unstyled my-2" key={index} onClick={() => 
                                    handleCurrentExperience(el.id)}>
                                    <li className="fw-bold text-capitalize">
                                        <span className="bullet"></span>
                                        {el.role}
                                    </li>
                                    <li className="mb-2 fs-14">
                                        <span className="no-bullet"></span>
                                        {el.company} - {el.year}
                                    </li>
                                </ul>
                                }) :
                                <div className={`d-flex justify-content-center align-items-center w-100  mt-5 pb-5`}>Nessun esperienza</div>
                            }
                            
                           {/*  <div className="edit-icon-pagina-bottom rounded-circle w-50 h-50" onClick={() => { setEditExperience(!editExperience) }}>
                                {editExperience ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                        </Col>

                        <Col className="pagina-categories mt-3 pt-5 rounded"> 
                            <p className="fs-24 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Certificati</p>
                            {editCertificato && (
                                <ButtonRm
                                variant="outline"
                                size="small"
                                className="my-2"
                                onClick={(e) => {
                                    setShowCertificateCreate(true)
                                }}
                                >
                                Aggiungi
                                </ButtonRm>
                            )}
                            {
                                form?.certifications?.length > 0 ?
                                form.certifications.map((el, index) => {
                                    return <ul className="list-unstyled my-2" key={index} onClick={() => 
                                    handleCurrentCertificate(el.id)}>
                                    <li className="fw-bold">
                                        <span className="bullet"></span>
                                        {el.title}
                                    </li>
                                    <li className="mb-2 fs-14">
                                        <span className="no-bullet"></span>
                                        {el.year}
                                    </li>
                                </ul>
                                }) :
                                <div className={`d-flex justify-content-center align-items-center w-100  mt-5 pb-5`}>Nessun certificato</div>
                            }
                            
                            {/* <div className="edit-icon-pagina-bottom rounded-circle w-50 h-50" onClick={() => { setEditCertificato(!editCertificato) }}>
                                {editCertificato? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                        </Col>

                        <Col className="pagina-categories mt-3 pt-5 rounded"> 
                            <p className="fs-24 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Pubblicazioni</p>
                            {editPublication && (
                                <ButtonRm
                                variant="outline"
                                size="small"
                                className="my-2"
                                onClick={(e) => {
                                    setShowPublicationCreate(true)
                                }}
                                >
                                Aggiungi
                                </ButtonRm>
                            )}
                            {
                               form?.publications?.length > 0 ?
                               form.publications.map((el, index) => {
                                    return <ul className="list-unstyled my-2" key={index} onClick={() => 
                                    handleCurrentPublication(index)}>
                                    <li className="fw-bold text-capitalize">
                                        <span className="bullet"></span>
                                        {el.title}
                                    </li>
                                    <li className="mb-2 fs-14">
                                        <span className="no-bullet"></span>
                                        {el.magazine} - {el.year}
                                    </li>
                                </ul>
                                }) :
                                <div className={`d-flex justify-content-center align-items-center w-100  mt-5 pb-5`}>Nessuna pubblicazione</div>
                            }
                            
                            {/* <div className="edit-icon-pagina-bottom rounded-circle w-50 h-50" onClick={() => { setEditPublication(!editPublication) }}>
                                {editPublication ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                            </div> */}
                        </Col>
                        
                        <Col className="text-center">
                            <ButtonRm variant="outline" className="mt-3">Segnala utente</ButtonRm>
                        </Col>
                </Col>
            </Row>


{/* CREATE PORTOFLIO */}
<Modal
    show={showPortfolioCreate}
    onHide={()=> setShowPortfolioCreate(false)}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body className="portfolio-fornitori-modal">
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mb-2">Crea Progetto</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Progetto*
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="title"
                        value={newProject.title}
                        onChange={handleNewProject}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Descrizione*
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}  
                        name="description"
                        value={newProject.description}
                        onChange={handleNewProject}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Skill, tecniche e/o software utilizzati
                        <OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className='tooltipContabilita'>
                                    Inserisci skill e/o software separati da virgola. Esempio: Decorazione pareti, Stucco veneziano...
                                </Tooltip>
								}
								>
								<i class="bi bi-info-circle ms-2"></i>
							</OverlayTrigger>
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={2}  
                        name="skill"
                        value={newProject.skill}
                        onChange={handleNewProject}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-1">
                <Form.Group >
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Immagini
                    </Form.Label>
                    <Form.Control 
                        type="file"
                        name="img"
                        accept="image/png, image/jpeg"
                        onChange={handleNewProject}
                        multiple
                    />
        
                    {(newProject.img != null) && 
                    <div className="">
                        {
                                Object.entries(newProject.img).map(([key, value]) => {
                                    return <div className="portfolio-category-card mt-3 me-2">
                                    <Image className="portfolioImg"  src={newProject.img[key]? URL.createObjectURL(newProject.img[key]) : ""} />
                                    <img loading="lazy" className="portfolioImgRemove" src={removeImg} onClick={() => removePortfolioImg(key, 'create')}/>
                                </div>
                                })
                            }
                    </div>
                    
                    } 
                </Form.Group>
            </Col>            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewProject} disabled={(newProject.description && newProject.name)? false: true}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowPortfolioCreate(false)}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* SHOW PORTOFLIO */}
<Modal
    show={showPortfolioProject}
    onHide={()=> {
        setShowPortfolioProject(false)
        setCurrentPortfolioProject(null)
    }
    
    }
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body className="portfolio-fornitori-modal">
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-20 fw-bold mb-2 text-capitalize">{currentPortfolioProject?.title}</p>
            
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label mb-2 fs-12 text-uppercase"
                    >
                        Descrizione
                    </Form.Label>
                    <p className="fs-16 mb-2">
                        {currentPortfolioProject?.description}
                    </p>
                </Form.Group>
            </Col>
            {currentPortfolioProject?.skill && 
                <Col  className="mb-3">
                    <Form.Group>
                        <Form.Label 
                            className="f-label mb-2  fs-12 text-uppercase"
                        >
                            Skill, tecniche e/o software utilizzati
                            
                        </Form.Label>
                            <p className="mb-3">{currentPortfolioProject?.skill?.split(',').length > 0 ? 
                            currentPortfolioProject?.skill.split(',').map((el, index) => <CustomBadge variant="custom-badge-pagina" key={index} >{el}</CustomBadge>) : "Nessun elemento inserito"}
                            </p>
                    </Form.Group>
                </Col>
            }
            { currentPortfolioProject?.img &&
                <Col  className="mb-1">
                <Form.Group >
                    <Form.Label 
                        className="f-label mb-0  fs-12 text-uppercase"
                    >
                        Immagini
                    </Form.Label>
                   {/*  <Form.Control 
                        type="file"
                        name="img"
                        accept="image/png, image/jpeg"
                        onChange={handleNewProject}
                        multiple
                    /> */}
        
                    {(currentPortfolioProject?.img != null) && 
                    <div className="">
                        {
                                Object.entries(currentPortfolioProject?.img).map(([key, value]) => {
                                    return <div className="portfolio-category-card mt-3 me-2">
                                    <Image className="portfolioImg"  src={currentPortfolioProject?.img[key]? URL.createObjectURL(currentPortfolioProject?.img[key]) : ""} />
                                   {/*  <img loading="lazy" className="portfolioImgRemove" src={removeImg} onClick={() => removePortfolioImg(key)}/> */}
                                </div>
                                })
                            }
                    </div>
                    
                    } 
                </Form.Group>
                </Col>  
            }          
            {/* <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewProject}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowPortfolioCreate(false)}>Annulla</ButtonRm>
            </div> */}
        
        </div>

    </Modal.Body>
</Modal>

{/* EDIT PORTOFLIO */}
<Modal
    show={showEditPortfolioProject}
    onHide={()=> {
        setShowEditPortfolioProject(false)
        setCurrentPortfolioProject(null)
        setCurrentPortfolioProjectId(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body className="portfolio-fornitori-modal">
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mb-2">Modifica Progetto</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Progetto
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={currentPortfolioProject?.name}
                        onChange={handleEditProjectForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Descrizione
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}  
                        name="description"
                        value={currentPortfolioProject?.description}
                        onChange={handleEditProjectForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Skill, tecniche e/o software utilizzati
                        <OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className='tooltipContabilita'>
                                    Inserisci skill e/o software separati da virgola. Esempio: Decorazione pareti, Stucco veneziano...
                                </Tooltip>
								}
								>
								<i class="bi bi-info-circle ms-2"></i>
							</OverlayTrigger>
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={2}  
                        name="skills"
                        value={currentPortfolioProject?.skills}
                        onChange={handleEditProjectForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-1">
                <Form.Group >
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Immagini
                    </Form.Label>
                    <Form.Control 
                        type="file"
                        name="img"
                        accept="image/png, image/jpeg"
                        onChange={handleEditProjectForm}
                        multiple
                    />
        
                    {(currentPortfolioProject?.img != null) && 
                    <div className="">
                        {
                                Object.entries(currentPortfolioProject?.img).map(([key, value]) => {
                                    return <div className="portfolio-category-card mt-3 me-2">
                                    <Image className="portfolioImg"  src={currentPortfolioProject?.img[key]? URL.createObjectURL(currentPortfolioProject?.img[key]) : ""} />
                                    <img loading="lazy" className="portfolioImgRemove" src={removeImg} onClick={() => removePortfolioImg(key, 'edit')}/>
                                </div>
                                })
                            }
                    </div>
                    
                    } 
                </Form.Group>
            </Col>            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={updateProject}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> {
                    setShowEditPortfolioProject(false)
                    setCurrentPortfolioProject(null)
                    setCurrentPortfolioProjectId(null)
                }}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* DELETE PORTFOLIO */}
<Modal show={showDeletePortfolioProject} onHide={() => {
    showDeletePortfolioProject(false)
    setCurrentPortfolioProjectId(null)
} } centered>
    <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
        <h2 className="fs-32 text-dark fw-semibold mb-3">
            Vuoi eliminare questo elemento?
        </h2>
        <h6 className="fs-16 text-dark fw-normal mb-3">
            Questa azione è irreversibile
        </h6>
        <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm 
                    variant="primary" 
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        deleteProject()
                    }} 
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"  
                    onClick={() => {
                        setShowDeletePortfolioProject(false)
                        setCurrentPortfolioProjectId(null)
                    }}
                    >
                    Annulla
                </ButtonRm>
            </div>
        </div>
    </Modal.Body>
</Modal>

{/* CREATE SERVICE */}
<Modal
    show={showServiceCreate}
    onHide={()=> setShowServiceCreate(false)}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Nuovo servizio</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Servizio
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={newService.name}
                        onChange={handleNewService}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Descrizione
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}
                        name="description"
                        value={newService.description}
                        onChange={handleNewService}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Prezzo minimo
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="min"
                            value={newService.min}
                            onChange={handleNewService}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Prezzo massimo
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="max"
                            value={newService.max}
                            onChange={handleNewService}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewService}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowServiceCreate(false)}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* VIEW SERVICE */}
<Modal
    show={showService}
    onHide={()=> {
        setShowService(false)
        setCurrentService(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0 text-center"
        >
            <p className="fs-24 fw-bold mt-1 mb-2 text-capitalize">{currentService?.name}</p>
            <p className="mb-3">
                {currentService?.description}
            </p>
            <p className="fw-bold">
                Prezzo €{currentService?.min}- €{currentService?.max}
            </p>
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
            <ButtonRm variant="primary">Chiedi Informazioni</ButtonRm>
            <ButtonRm variant="text" onClick={()=> {
                setShowService(false)
                setCurrentService(null)
            }}>Annulla</ButtonRm>
            </div>
        </div>

    </Modal.Body>
</Modal>

{/* EDIT SERVICE */}
<Modal
    show={showEditService}
    onHide={()=> {
        setShowEditService(false)
        setCurrentService(null)
        setCurrentServiceId(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Modifica servizio</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Servizio
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={currentService?.name}
                        onChange={ handleEditServiceForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Descrizione
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}
                        name="description"
                        value={currentService?.description}
                        onChange={handleEditServiceForm}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Prezzo minimo
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="min"
                            value={currentService?.min}
                            onChange={handleEditServiceForm}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Prezzo massimo
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="max"
                            value={currentService?.max}
                            onChange={handleEditServiceForm}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={updateService}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> {
                    setShowEditService(false)
                    setCurrentService(null)
                    setCurrentServiceId(null)
                }}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* DELETE SERVICE */}
<Modal show={showDeleteService} onHide={() => {
    showDeleteService(false)
    setCurrentServiceId(null)
} } centered>
    <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
        <h2 className="fs-32 text-dark fw-semibold mb-3">
            Vuoi eliminare questo elemento?
        </h2>
        <h6 className="fs-16 text-dark fw-normal mb-3">
            Questa azione è irreversibile
        </h6>
        <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm 
                    variant="primary" 
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        deleteService()
                    }} 
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"  
                    onClick={() => {
                        showDeleteService(false)
                        setCurrentServiceId(null)
                    }}
                    >
                    Annulla
                </ButtonRm>
            </div>
        </div>
    </Modal.Body>
</Modal>

{/* CREATE PRODUCT */}
<Modal
    show={showProductCreate}
    onHide={()=> setShowProductCreate(false)}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Nuovo Prodotto</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Prodotto
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={newProduct.name}
                        onChange={handleNewProduct}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Descrizione
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}
                        name="description"
                        value={newProduct.description}
                        onChange={handleNewProduct}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Costo
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="cost"
                            value={newService.cost}
                            onChange={handleNewProduct}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Col  className="mb-1">
                <Form.Group >
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Immagini
                    </Form.Label>
                    <Form.Control 
                        type="file"
                        name="img"
                        accept="image/png, image/jpeg"
                        onChange={handleNewProduct}
                        multiple
                    />
        
                    {(newProduct.img != null) && 
                    <div className="">
                        {
                                Object.entries(newProduct.img).map(([key, value]) => {
                                    return <div className="portfolio-category-card mt-3 me-2">
                                    <Image className="portfolioImg"  src={newProduct.img[key]? URL.createObjectURL(newProduct.img[key]) : ""} />
                                    <img loading="lazy" className="portfolioImgRemove" src={removeImg} onClick={() => removeProductImg(key, 'create')}/>
                                </div>
                                })
                            }
                    </div>
                    
                    } 
                </Form.Group>
            </Col>  
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewProduct}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowProductCreate(false)}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* VIEW PRODUCT */}
<Modal
    show={showProduct}
    onHide={()=> {
        setShowProduct(false)
        setCurrentProduct(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0 text-center"
        >
            <p className="fs-24 fw-bold mt-1 mb-2 text-capitalize">{currentProduct?.name}</p>
            <p className="mb-3">
                {currentProduct?.description}
            </p>
            <p className="fw-bold">
                Prezzo €{currentProduct?.cost}
            </p>
            { currentProduct?.img &&
                <Col  className="mb-1">
                <Form.Group > 
                   {/*  <Form.Control 
                        type="file"
                        name="img"
                        accept="image/png, image/jpeg"
                        onChange={handleNewProject}
                        multiple
                    /> */}
        
                    {(currentProduct?.img != null) && 
                    <div className="">
                        {
                                Object.entries(currentProduct?.img).map(([key, value]) => {
                                    return <div className="portfolio-category-card mt-3 me-2">
                                    <Image className="portfolioImg"  src={currentProduct?.img[key]? URL.createObjectURL(currentProduct?.img[key]) : ""} />
                                   {/*  <img loading="lazy" className="portfolioImgRemove" src={removeImg} onClick={() => removePortfolioImg(key)}/> */}
                                </div>
                                })
                            }
                    </div>
                    
                    } 
                </Form.Group>
                </Col>  
            } 
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
            <ButtonRm variant="primary">Acquista ora</ButtonRm>
            <ButtonRm variant="text" onClick={()=> {
                setShowProduct(false)
                setCurrentProduct(null)
            }}>Annulla</ButtonRm>
            </div>
        </div>

    </Modal.Body>
</Modal>

{/* EDIT PRODUCT */}
<Modal
    show={showEditProduct}
    onHide={()=> {
        setShowEditProduct(false)
        setCurrentProduct(null)
        setCurrentProductId(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Modifica prodotto</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Prodotto
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={currentProduct?.name}
                        onChange={ handleEditProductForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Descrizione
                    </Form.Label>
                    <Form.Control 
                        as="textarea" 
                        rows={3}
                        name="description"
                        value={currentProduct?.description}
                        onChange={handleEditProductForm}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Costo
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="cost"
                            value={currentProduct?.cost}
                            onChange={handleEditProductForm}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Col  className="mb-1">
                <Form.Group >
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Immagini
                    </Form.Label>
                    <Form.Control 
                        type="file"
                        name="img"
                        accept="image/png, image/jpeg"
                        onChange={handleEditProductForm}
                        multiple
                    />
        
                    {(currentProduct?.img != null) && 
                    <div className="">
                        {
                                Object.entries(currentProduct?.img).map(([key, value]) => {
                                    return <div className="portfolio-category-card mt-3 me-2">
                                    <Image className="portfolioImg"  src={currentProduct?.img[key]? URL.createObjectURL(currentProduct?.img[key]) : ""} />
                                    <img loading="lazy" className="portfolioImgRemove" src={removeImg} onClick={() => removeProductImg(key, 'edit')}/>
                                </div>
                                })
                            }
                    </div>
                    
                    } 
                </Form.Group>
            </Col>  
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={updateProduct}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> {
                    setShowProduct(false)
                    setCurrentProduct(null)
                    setCurrentProductId(null)
                }}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* DELETE PRODUCT */}
<Modal show={showDeleteProduct} onHide={() => {
    showDeleteProduct(false)
    setCurrentProductId(null)
} } centered>
    <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
        <h2 className="fs-32 text-dark fw-semibold mb-3">
            Vuoi eliminare questo elemento?
        </h2>
        <h6 className="fs-16 text-dark fw-normal mb-3">
            Questa azione è irreversibile
        </h6>
        <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm 
                    variant="primary" 
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        deleteProduct()
                    }} 
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"  
                    onClick={() => {
                        showDeleteProduct(false)
                        setCurrentProductId(null)
                    }}
                    >
                    Annulla
                </ButtonRm>
            </div>
        </div>
    </Modal.Body>
</Modal>

{/* CREATE CERTIFICATE */}
<Modal
    show={showCertificateCreate}
    onHide={()=> setShowCertificateCreate(false)}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Nuovo Certificato</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Certificato
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={newCertificate.name}
                        onChange={handleNewCertificate}
                    />
                </Form.Group>
            </Col>

            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Anno
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="year"
                            value={newCertificate.year}
                            onChange={handleNewCertificate}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewCertificate}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowCertificateCreate(false)}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* VIEW CERTIFICATE */}
<Modal
    show={showCertificate}
    onHide={()=> {
        setShowCertificate(false)
        setCurrentCertificate(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0 text-center"
        >
            <p className="fs-24 fw-bold mt-1 mb-2 text-capitalize">{currentCertificate?.title}</p>
            <p className="fw-bold">
                Anno: {currentCertificate?.year}
            </p>
            {editCertificato && <Col className="d-flex justify-content-center mt-4">
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e)  => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleDeleteCertificate(currentCertificateId)
                    }}
                >
                    <BsTrash />
                </button>
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleEditCertificate(currentCertificateId)
                        }
                    }
                >
                    <BsPen />
                </button>
            </Col>}
        </div>

    </Modal.Body>
</Modal>

{/* EDIT CERTIFICATE */}
<Modal
    show={showEditCertificate}
    onHide={()=> {
        setShowEditCertificate(false)
        setCurrentCertificate(null)
        setCurrentCertificateId(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Modifica Certificato</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Nome Certificato
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="name"
                        value={currentCertificate?.title}
                        onChange={ handleEditCertificateForm}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Anno
                        </Form.Label>
                        <Form.Control 
                            type="number" 
                            name="year"
                            value={currentCertificate?.year}
                            onChange={ handleEditCertificateForm}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={updateCertificate}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> {
                    setShowCertificate(false)
                    setCurrentCertificate(null)
                    setCurrentCertificateId(null)
                }}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* DELETE CERTIFICATE */}
<Modal show={showDeleteCertificate} onHide={() => {
    showDeleteCertificate(false)
    setCurrentCertificateId(null)
} } centered>
    <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
        <h2 className="fs-32 text-dark fw-semibold mb-3">
            Vuoi eliminare questo elemento?
        </h2>
        <h6 className="fs-16 text-dark fw-normal mb-3">
            Questa azione è irreversibile
        </h6>
        <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm 
                    variant="primary" 
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        deleteCertificate()
                    }} 
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"  
                    onClick={() => {
                        showDeleteCertificate(false)
                        setCurrentCertificateId(null)
                    }}
                    >
                    Annulla
                </ButtonRm>
            </div>
        </div>
    </Modal.Body>
</Modal>

{/* CREATE EXPERIENCE */}
<Modal
    show={showExperienceCreate}
    onHide={()=> setShowExperienceCreate(false)}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Nuova Esperienza</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Ruolo
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="role"
                        value={newExperience.role}
                        onChange={handleNewExperience}
                    />
                </Form.Group>
            </Col>

            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Azienda
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="company"
                        value={newExperience.company}
                        onChange={handleNewExperience}
                    />
                </Form.Group>
            </Col>

            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Anno
                        </Form.Label>
                        <Form.Control 
                            type="text" 
                            name="year"
                            value={newExperience.year}
                            onChange={handleNewExperience}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewExperience}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowExperienceCreate(false)}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* VIEW EXPERIENCE */}
<Modal
    show={showExperience}
    onHide={()=> {
        setShowExperience(false)
        setCurrentExperience(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0 text-center"
        >
            <p className="fs-24 fw-bold mt-1 mb-2 text-capitalize">{currentExperience?.role}</p>
            <p className="">
                {currentExperience?.company}
            </p>
            <p className="">
                {currentExperience?.year}
            </p>
            {editExperience && <Col className="d-flex justify-content-center mt-4">
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e)  => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleDeleteExperience(currentExperienceId)
                    }}
                >
                    <BsTrash />
                </button>
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleEditExperience(currentExperienceId)
                        }
                    }
                >
                    <BsPen />
                </button>
            </Col>}
        </div>

    </Modal.Body>
</Modal>

{/* EDIT EXPERIENCE */}
<Modal
    show={showEditExperience}
    onHide={()=> {
        setShowEditExperience(false)
        setCurrentExperience(null)
        setCurrentExperienceId(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Modifica Esperienza</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                    Ruolo
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="role"
                        value={currentExperience?.role}
                        onChange={ handleEditExperienceForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                    Azienda
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="company"
                        value={currentExperience?.company}
                        onChange={ handleEditExperienceForm}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Anno
                        </Form.Label>
                        <Form.Control 
                            type="text" 
                            name="year"
                            value={currentExperience?.year}
                            onChange={ handleEditExperienceForm}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={updateExperience}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> {
                    setShowExperience(false)
                    setCurrentExperience(null)
                    setCurrentExperienceId(null)
                }}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* DELETE EXPERIENCE */}
<Modal show={showDeleteExperience} onHide={() => {
    setShowDeleteExperience(false)
    setCurrentExperienceId(null)
} } centered>
    <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
        <h2 className="fs-32 text-dark fw-semibold mb-3">
            Vuoi eliminare questo elemento?
        </h2>
        <h6 className="fs-16 text-dark fw-normal mb-3">
            Questa azione è irreversibile
        </h6>
        <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm 
                    variant="primary" 
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        deleteExperience()
                    }} 
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"  
                    onClick={() => {
                        setShowDeleteExperience(false)
                        setCurrentExperienceId(null)
                    }}
                    >
                    Annulla
                </ButtonRm>
            </div>
        </div>
    </Modal.Body>
</Modal>

{/* CREATE PUBLICATION */}
<Modal
    show={showPublicationCreate}
    onHide={()=> setShowPublicationCreate(false)}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Nuova Pubblicazione</p>

            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Titolo
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="title"
                        value={newPublication.title}
                        onChange={handleNewPublication}
                    />
                </Form.Group>
            </Col>

            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                        Rivista
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="magazine"
                        value={newPublication.magazine}
                        onChange={handleNewPublication}
                    />
                </Form.Group>
            </Col>

            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Anno
                        </Form.Label>
                        <Form.Control 
                            type="text" 
                            name="year"
                            value={newPublication.year}
                            onChange={handleNewPublication}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={saveNewPublication}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> setShowPublicationCreate(false)}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* VIEW PUBLICATION */}
<Modal
    show={showPublication}
    onHide={()=> {
        setShowPublication(false)
        setCurrentPublication(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0 text-center"
        >
            <p className="fs-24 fw-bold mt-1 mb-2 text-capitalize">{currentPublication?.title}</p>
            <p className="">
                {currentPublication?.magazine}
            </p>
            <p className="">
                {currentPublication?.year}
            </p>
            {editPublication && <Col className="d-flex justify-content-center mt-4">
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e)  => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleDeletePublication(currentPublicationId)
                    }}
                >
                    <BsTrash />
                </button>
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            handleEditPublication(currentPublicationId)
                        }
                    }
                >
                    <BsPen />
                </button>
            </Col>}
        </div>

    </Modal.Body>
</Modal>

{/* EDIT PUBLICATION */}
<Modal
    show={showEditPublication}
    onHide={()=> {
        setShowEditPublication(false)
        setCurrentPublication(null)
        setCurrentPublicationId(null)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body>
        <div
        className="px-4 pb-2 pt-0"
        >
            <p className="fs-18 fw-bold mt-1 mb-2">Modifica Pubblicazione</p>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                    Titolo
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="title"
                        value={currentPublication?.title}
                        onChange={ handleEditPublicationForm}
                    />
                </Form.Group>
            </Col>
            <Col  className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                    Rivista
                    </Form.Label>
                    <Form.Control 
                        type="text" 
                        name="magazine"
                        value={currentPublication?.magazine}
                        onChange={ handleEditPublicationForm}
                    />
                </Form.Group>
            </Col>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Anno
                        </Form.Label>
                        <Form.Control 
                            type="text" 
                            name="year"
                            value={currentPublication?.year}
                            onChange={ handleEditPublicationForm}
                        />
                    </Form.Group>
                </Col>
            </Row>
            
            <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm variant="primary" onClick={updatePublication}>Salva</ButtonRm>
                <ButtonRm variant="text" onClick={()=> {
                    setShowPublication(false)
                    setCurrentPublication(null)
                    setCurrentPublicationId(null)
                }}>Annulla</ButtonRm>
            </div>
        
        </div>

    </Modal.Body>
</Modal>

{/* DELETE PUBLICATION */}
<Modal show={showDeletePublication} onHide={() => {
    setShowDeletePublication(false)
    setCurrentPublicationId(null)
} } centered>
    <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
        <h2 className="fs-32 text-dark fw-semibold mb-3">
            Vuoi eliminare questo elemento?
        </h2>
        <h6 className="fs-16 text-dark fw-normal mb-3">
            Questa azione è irreversibile
        </h6>
        <div className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <ButtonRm 
                    variant="primary" 
                    onClick={(e) =>{ 
                        e.preventDefault()
                        e.stopPropagation()
                        deletePublication()
                    }} 
                >
                    Conferma
                </ButtonRm>
                <ButtonRm 
                    variant="text"  
                    onClick={() => {
                        setShowDeletePublication(false)
                        setCurrentPublicationId(null)
                    }}
                    >
                    Annulla
                </ButtonRm>
            </div>
        </div>
    </Modal.Body>
</Modal>

{/* VIEW REVIEW */}
<Modal
    show={showReview}
    onHide={()=> {
        setShowReview(false)
    }}
    centered
>
    <Modal.Header closeButton style={{border:"none"}}>
    </Modal.Header>
    <Modal.Body className="portfolio-fornitori-modal pb-4">
        <p className="fs-24 fw-bold mt-0 mb-2 text-capitalize text-center">Recensioni ({totalReviews})</p>        

        {reviews?.length > 0 && <div>
            <div>
                <div className="d-flex align-items-center  my-3">
                    <div className="col-6 text-center mb-2">
            {/* Mostra le icone delle stelle colorate in base alla media */}
            {Array.from({ length: 5 }, (_, index) => {
                if (index < roundedAverage) {
                return <i key={index} className="bi bi-star-fill" ></i>;
                } else if (index === Math.floor(averageStars) && hasHalfStar) {
                return <i key={index} className="bi bi-star-half"></i>;
                } else {
                return <i key={index} className="bi bi-star" style={{ color: '' }}></i>;
                }
            })}
            <p className="ms-2 fs-14 fs-md-16 ">Puntualità</p>
                    </div>
                    <div className="col-6 text-center mb-2">
            {/* Mostra le icone delle stelle colorate in base alla media */}
            {Array.from({ length: 5 }, (_, index) => {
                if (index < roundedAverage) {
                return <i key={index} className="bi bi-star-fill" ></i>;
                } else if (index === Math.floor(averageStars) && hasHalfStar) {
                return <i key={index} className="bi bi-star-half" ></i>;
                } else {
                return <i key={index} className="bi bi-star" style={{ color: '' }}></i>;
                }
            })}
            <p className="ms-2 fs-14 fs-md-16 ">Budget rispettato</p>
                    </div>
                </div>
                <div  className="d-flex align-items-center  mb-3">
                    <div className="col-6 text-center mb-2">
                        {/* Mostra le icone delle stelle colorate in base alla media */}
                        {Array.from({ length: 5 }, (_, index) => {
                            if (index < roundedAverage) {
                            return <i key={index} className="bi bi-star-fill" ></i>;
                            } else if (index === Math.floor(averageStars) && hasHalfStar) {
                            return <i key={index} className="bi bi-star-half" ></i>;
                            } else {
                            return <i key={index} className="bi bi-star" style={{ color: '' }}></i>;
                            }
                        })}
                        <p className="ms-2 fs-14 fs-md-16 ">Cordialità</p>
                    </div>
                    <div className="text-center mb-2 col-6">
                        {/* Mostra le icone delle stelle colorate in base alla media */}
                        {Array.from({ length: 5 }, (_, index) => {
                            if (index < roundedAverage) {
                            return <i key={index} className="bi bi-star-fill" ></i>;
                            } else if (index === Math.floor(averageStars) && hasHalfStar) {
                            return <i key={index} className="bi bi-star-half" ></i>;
                            } else {
                            return <i key={index} className="bi bi-star" style={{ color: '' }}></i>;
                            }
                        })}
                        <p className="ms-2 fs-14 fs-md-16 ">Tempistiche rispettate</p>
                    </div>
                </div>
            </div>
        </div>}

        {
            reviews.map(el => {
                return   <div className="my-2 border rounded p-3 d-flex">
                <Col>
                    <StarRating stelle={el.stelle} />
                    <p className="text-capitalize fw-bold">{el.autore}</p>
                    <p className="text-capitalize fs-14">{el.recensione}</p>
                </Col>
                
            </div>
            })
        }

    </Modal.Body>
</Modal>

        </>
    )
}



const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0.375rem',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : null,
    border: state.isFocused ? '1px solid #80bdff' : '1px solid #ced4da',

  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const CustomSelect = ({ value, onChange }) => {
    const [selectedTypologies, setSelectedTypologies] = useState([]);

    useEffect(() => {
    setSelectedTypologies(value);
    }, [value]);

    const handleCategoryChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedTypologies(selectedValues);
        onChange(selectedValues); // Richiama la funzione di callback per aggiornare il form
    }
    console.log(selectedTypologies);
    return (
        <Select
            isMulti
            isSearchable
            name="typologies"
            options={options}
            styles={customStyles}
            className="basic-multi-select pagina-select"
            value={options.filter((option) =>
                selectedTypologies.includes(option.value)
            )}
            onChange={handleCategoryChange}
        />
    );
};



//CARD PORTFOLIO

function CarouselCardPartner(props) {
  const [showIcons, setShowIcons] = useState(false);

  const handleMouseEnter = () => {
    setShowIcons(true);
  };

  const handleMouseLeave = () => {
    setShowIcons(false);
  };

  return (
    <div
      className="carousel-card-pagina m-2 position-relative mb-3"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => props.handleCurrentPortfolioProject(props.id)}
    >
      {(props.editPortfolio && showIcons) && (
        <div className="icons-portfolio-container">
          {/* Aggiungi icone per delete e edit */}
          <button
                type="button"
                className="btn round-icon-btn"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.handleDelete(props.id)
                }}>
                <BsTrash />
            </button>
            <button
                type="button"
                className="btn round-icon-btn"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.handleEdit(props.id)
                }}>
                <BsPen />
            </button>
        </div>
      )}
      <img loading="lazy" src={props.img} alt="" />
      <p className="text-capitalize">{props.category}</p>
    </div>
  );
}


function CategoryCard (props) {
    return (
        <div className="portfolio-category-card mt-3 me-2">
            <img loading="lazy" src={props.img}/>
        </div>
    )
}