import React from "react";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router";
import icon9 from "../assets/images/m9.svg";
import icon10 from "../assets/images/m10.svg";
import icon11 from "../assets/images/m11.svg";
import slc from "../assets/images/property-COLL-T.svg";
import ButtonRm from "./ButtonRm";
import { useDispatch } from "react-redux";
import { changeTheme } from "../actions/theme";
import { checkUserRole } from "../utils/checkUserRole";

export default function LastMenuSlc() {

	const navigate = useNavigate()
	const dispatch = useDispatch()

	function goToProperty(){
		navigate("/dashboard")
		//dispatch(changeTheme())
	}


	return (
		<>
			<ul className="links-log ls p-0 d-flex flex-column">
                {/* SOLO PER UTENTI BASE */}
				{/* <li>
					<Link to="/">
						<span className="icon-link">
							<img loading="lazy" src={icon9} alt="icon" />
						</span>
						<span className="text-link">Supporto</span>
					</Link>
				</li> */}
                {/* SOLO PER UTENTI BASE */}
				{/* <li>
					<Link to="/">
						<span className="icon-link">
							<img loading="lazy" src={icon10} alt="icon" />
						</span>
						<span className="text-link">Impostazioni</span>
					</Link>
				</li> */}

				{(checkUserRole()) && <a onClick={goToProperty}>
					<li className="d-flex justify-content-center mt-2">
							<span className="icon-link">
								<img loading="lazy" src={slc} alt="icon" />
							</span>
							<ButtonRm variant="primary-round" className="text-link">Passa a Property</ButtonRm>
					</li>
				</a>}
			</ul>
			
		</>
	);
}
