import React from "react"
import { Row, Col } from "react-bootstrap"
import Alert from 'react-bootstrap/Alert';

import FilterSearchBar from "./FilterSearchBar"
import { UnitàCard } from "../Unità/UnitàCard"
import EmptyStateContainer from "../../EmptyStateContainer"

import SearchIllustration from "../../../assets/images/search-illustration.svg";
import EmptyState from '../../../assets/images/unità-empty-state.svg'
import ExportButton from "../../Membri/Fornitori/ExportButton";
import { checkUserRole, checkMaster } from "../../../utils/checkUserRole";
import ShareChat from "../../ShareChat";

import ButtonRm from "../../ButtonRm";


function ListViewUnit(props){

    const translationMapping = {
        id:"Id",
        cdat: "Data di creazione",
        udat: "Ultima modifica",
        userID: "ID utente",
        name: "Identificativo",
        realEstateManagements: "Immobili della gestione",
        activities: 'Attività',
        address: "Indirizzo"
    }

    return (
        <Row className="dashboard-container">
            {props.units.length > 0 ? 
            <Row className="position-relative mx-0 px-0">
            <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                <FilterSearchBar
                setSearchTerm={props.setSearchTerm}
                selectedCategories={props.selectedCategories}
                setSelectedCategories={props.setSelectedCategories}
                clearFiltersAndSort={props.clearFiltersAndSort}
                setSortBy={props.setSortBy}
                categories={props.categories}
                setStartDate={props.setStartDate} setEndDate={props.setEndDate}
                />
            </Col>
            <div>
            {checkUserRole() &&
                props.selectedList.length > 0 && 
                <div className="d-flex mb-3">
                    <ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={props.handleSelectAll}>
                        Seleziona tutti
                    </ButtonRm>
                    <ButtonRm variant='outline' size="extra-small" onClick={props.handleDeselectAll}>
                        Deseleziona Tutti
                    </ButtonRm>
                </div>
            }
          </div>
                {props.sortedItems.length > 0 ? 
                props.sortedItems.map(unit => {
                    return (
                        <UnitàCard
                            key={unit.id}
                            id={unit.id}
                            name={unit.name}
                            image={unit.image}
                            adress={unit.adress}
                            type={unit.category}
                            author={unit.author}
                            creation_date={unit.cdat}
                            update_date={unit.udat}
                            unit={unit}
                            checked={unit.checked}
                            handleCheckbox={props.handleCheckbox}
                            handleSelection={props.handleSelection}
                            selectedList={props.selectedList}
                        /> 
                    );
                }) : 
                <EmptyStateContainer
                    content="Nessuna corrispondenza trovata"
                    img={SearchIllustration}
                />
                }


                <Row className="alert-container">
                    {
                    props.selectedList.length===1 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}} >
{/*                         <ShareChat  isButton={false}/>
 */}
                        <Alert.Link className="me-2" href="#">
                        <ExportButton data={props.selectedList[0]} mapping={translationMapping} isButton={false} classes="text-white"/>
                        </Alert.Link>
                       { checkUserRole()  &&  <>
                        <Alert.Link className="me-2" onClick={props.handleShowCG}>Cambia Gestione</Alert.Link>
                          <Alert.Link className="me-2" onClick={props.handleShowModal}>Rimuovi</Alert.Link>
{/*                         <Alert.Link className="me-2" onClick={props.handleShowRc}>Contabilità</Alert.Link>
 */}                        </>}
                       {/*  <Alert.Link className="me-2" href="#">Contenuti Unità</Alert.Link> */}
                       {/*  <Alert.Link className="me-2" href="#">Archivia</Alert.Link> */}
                       { ( props.selectedList.length == 1 && checkMaster()) &&
                            <Alert.Link 
                                className="me-2" 
                                onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.handleShowPermit();
                            }}>
                                Permessi
                            </Alert.Link>
                            }
                    </Alert>
                    }

                    { checkUserRole() &&
                    props.selectedList.length > 1 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        {/* <Alert.Link className="me-2" href="#">Esporta</Alert.Link> */}

                        { 
                            <Alert.Link className="me-2" onClick={props.handleShowModal}>Rimuovi</Alert.Link>
                        }
                        {/* <Alert.Link className="me-2" href="#">Archivia</Alert.Link> */}
                    </Alert>
                    }
                </Row>
            </Row> :
            <Row className="dashboard-container ">
            <div className="mx-auto text-center empty-container ">
                <div className="mx-auto d-table w-90">
                <img loading="lazy" src={EmptyState} alt="empty" />
                </div>
                <p className="fs-20 text-secondary text-center mt-3">
                {checkUserRole() ? "Non hai aggiunto ancora alcuna unità" :
                "Non sei stato aggiunto in nessuna unità"}
                </p>
            </div>
            </Row>
        }

        </Row>
    )
}


export default ListViewUnit