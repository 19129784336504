import Badge from 'react-bootstrap/Badge'
import '../assets/css/CustomBadge.css'

/*
This component has 3 variants:
"custom-badge-success" (green)
"custom-badge-danger" (red)
"custom-badge-alert" (yellow)

+ altri colori vedi CSS

Example of use:

<CustomBadge variant="custom-badge-success">Verificato</CustomBadge>

*/

export default  function CustomBadge({children, variant, classes, margin="mx-2", ...props}) {
    return (
        <Badge  bg="custom" className={`${variant} ${margin} ${classes}`} {...props}>{children}</Badge>
    );
}