import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ScrollToBottom from "react-scroll-to-bottom";
import ReactTooltip from "react-tooltip";
import "../../../assets/css/calculator.css";
import CalCulator from "../../../assets/images/Calculator.svg";
import Import_Light from "../../../assets/images/Import_light.svg";
import Print_Light from "../../../assets/images/Print_light.svg";
import Trash_Light from "../../../assets/images/Trash_light.svg";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import ExportButton from "../../Membri/Fornitori/ExportButton";

import Calculator from "./calc";
import { BsTrash, BsDownload, BsArrowClockwise} from "react-icons/bs";
import ButtonRm from "../../ButtonRm";

import handleSpecialCases from "../../../utils/calcolatrice/handleSpecialOperators";


export default function () {
  const [inputedArray, setInputedArray] = useState([
    {
      number: "",
      symbol: "",
      nota:""
    } 
  ]);

  const [calcName, setCalcName] = useState("")

  function handleCalcName(e){
    const {name, value} = e.target
    setCalcName(prev => value)
  }


  const [data, setData] = useState("");
  const [rowCount, setRowCount] = useState(1);
  const [inputNumber, setInputNmber] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [changeIva, setChangeIva] = useState(0);
  const [changePrevidenza, setChangePrevidenza] = useState(0);
  const [changeRitenuta, setChangeRitenuta] = useState(0);
  const [powArray, setPowArray] = useState([]);
  const [refreshKey, setRefreshKey] = useState(true);

  console.log(data, inputedArray, inputNumber)

  const onIvaChangeHandler = (event) => {
    setChangeIva(event.target.value);
  };

  const onRitenutaChangeHandler = (event) => {
    setChangeRitenuta(event.target.value);
  };

  const onPrevidenzaChangeHandler = (event) => {
    setChangePrevidenza(event.target.value);
  };

  const piClicked = () => {
    const pi = 3.14;
    if (inputedArray[inputedArray.length - 1].number === "") {
      inputedArray[inputedArray.length - 1].number = pi;
      setInputedArray(inputedArray);
      setData(prevData => prevData  + pi);
    } else {
      inputedArray.push({ number: 3.141592, symbol: "" });
      setInputedArray(inputedArray);
      setData(pi);
    }
    setInputNmber(pi);
    setIsChange(!isChange);
  };

  const phiClicked = () => {
    const phi = 1.61;
    if (inputedArray[inputedArray.length - 1].number === "") {
      inputedArray[inputedArray.length - 1].number = phi;
      setInputedArray(inputedArray);
      setData(prevData => prevData + phi);
    } else {
      inputedArray.push({ number: phi, symbol: "" });
      setInputedArray(inputedArray);
      setData(phi);
    }
    setInputNmber(phi);
    setIsChange(!isChange);
  };


  /* console.log({
    inputedArray,
    data,
    inputNumber
  }) */

  const clickNumber = (rowLength, number) => {

    //Funzione che gestisce la separazione di blocchi di calcolo differenti, con operazioni speciali
    handleSpecialCases(inputedArray)


    if (powArray.length === 0) {
      //se non c'è un operatore nel calcolo precedente, ricomincia da capo, senza prevData
      if(inputedArray.length > 2 && inputedArray[inputedArray.length - 2].symbol === ""){
          setData(number); //aggiorna data (contiene il blocco di calcolo corrente)
          inputedArray[inputedArray.length - 1].number = inputedArray[inputedArray.length - 1].number + "" + number; //aggiorna l'elemento specifo dell'array con number
          setInputedArray(inputedArray); //aggiorna la cronologia di calcolo completa
          setInputNmber(inputedArray[inputedArray.length - 1].number); //aggiorna il campo di input
          setIsChange(!isChange)
      } else {
        setData(prevData => prevData + number);
        inputedArray[inputedArray.length - 1].number = inputedArray[inputedArray.length - 1].number + "" + number;
        setInputedArray(inputedArray);
        setInputNmber(inputedArray[inputedArray.length - 1].number);
        setIsChange(!isChange)
      }
    } else {
      inputedArray.push({ number: "", symbol: "Y" });
      inputedArray.push({ number: "", symbol: "X^Y" });

      inputedArray[inputedArray.length - 2].number = number;

      inputedArray[inputedArray.length - 1].number = Math.pow(
        powArray[0],
        inputedArray[inputedArray.length - 2].number
      ).toFixed(2);
      setInputNmber(
        parseFloat(inputedArray[inputedArray.length - 1].number).toFixed(2)
      );
      setData( 
        parseFloat(inputedArray[inputedArray.length - 1].number).toFixed(2)
      );
      setInputedArray(inputedArray);
      setPowArray([]);
    }


  };

  const clickOperation = (rowLength, symbol) => {
    console.log("data in clickOperation", data)
    if (powArray.length === 0) {
      if (inputedArray[inputedArray.length - 1].number !== "") {
        switch (symbol) {
          case "*":
            setData(prevData => prevData + "*");
            break;
          case "÷":
            setData(prevData => prevData+ "/");
            break;
          case "%":
            setData(prevData => prevData+ "%");
            break;
          default:
            setData(prevData => prevData + symbol);
            break;
        }
        inputedArray.push({ number: "", symbol: "" });

        inputedArray[inputedArray.length - 2].symbol = symbol;
        setRowCount(rowCount + 1);
        setInputNmber("");
        setIsChange(!isChange);
        setInputedArray(inputedArray);
      }
    }
    //console.log("Data dopo dell'evento:", data);

  };

  //da rivedere
  const onApplicaClicked2 = () => {

    let imponibile = inputedArray[inputedArray.length - 1].number
    let tempArr = []

    inputedArray[inputedArray.length - 1].symbol = "+";
    tempArr.push({ number: "", symbol: "+", nota: "" });
    tempArr.push({ number: "", symbol: "-", nota: "" });
    tempArr.push({ number: "", symbol: "=", nota: "" });
    tempArr.push({ number: "", symbol: "", nota: "" });

    // Calcolo dell'IVA
    let ivaAmount = 0;
    let ivaNote = "";
    switch (changeIva) {
      case "2":
        ivaAmount = imponibile * 0.04;
        ivaNote = "IVA 4%";
        break;
      case "3":
        ivaAmount = imponibile * 0.1;
        ivaNote = "IVA 10%";
        break;
      case "4":
        ivaAmount = imponibile * 0.22;
        ivaNote = "IVA 22%";
        break;
      default:
        break;
    }
  
    // Calcolo della ritenuta d'acconto
    let ritenutaAmount = 0;
    let ritenutaNote = "";
    switch (changeRitenuta) {
      case "2":
        ritenutaAmount = imponibile * 0.02;
        ritenutaNote = "Ritenuta 2%";
        break;
      case "3":
        ritenutaAmount = imponibile * 0.04;
        ritenutaNote = "Ritenuta 4%";
        break;
      case "4":
        ritenutaAmount = imponibile * 0.06;
        ritenutaNote = "Ritenuta 2% + INPS 4%";
        break;
      case "5":
        ritenutaAmount = imponibile * 0.08;
        ritenutaNote = "Ritenuta 4% + INPS 4%";
        break;
      default:
        break;
    }
  
    // Calcolo della previdenza
    let previdenzaAmount = 0;
    let previdenzaNote = "";
    switch (changePrevidenza) {
      case "2":
        previdenzaAmount = imponibile * 0.2;
        previdenzaNote = "Previdenza 20%";
        break;
      case "3":
        previdenzaAmount = imponibile * 0.115;
        previdenzaNote = "Previdenza 23% sul 50%";
        break;
      case "4":
        previdenzaAmount = imponibile * 0.046;
        previdenzaNote = "Previdenza 23% sul 20%";
        break;
      case "5":
        previdenzaAmount = imponibile * 0.04;
        previdenzaNote = "Previdenza 4%";
        break;
      default:
        break;
    }
  
    // Calcolo dell'importo totale della parcella
    const importoTotale = parseFloat(imponibile) + ivaAmount - ritenutaAmount - previdenzaAmount;

    console.log({
      imponibile,
      ivaAmount,
      ritenutaAmount,
      previdenzaAmount
    })

    console.log(parseFloat(imponibile) + ivaAmount - ritenutaAmount - previdenzaAmount)

    // imposta iva 
    tempArr[0].nota = ivaNote;
    tempArr[0].number = ivaAmount

    // imposta previdenza
    tempArr[2].nota = previdenzaNote;
    tempArr[2].number = previdenzaAmount

    // imposta ritenuta
    tempArr[1].nota = ritenutaNote;
    tempArr[1].number = ritenutaAmount

    //tot
    tempArr[3].number = importoTotale


    console.log(tempArr[0], tempArr, "TEMP ARR", inputedArray)

    setInputedArray(prev => [...prev, ...tempArr]);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
    setIsChange(!isChange);
  
    // Output dell'importo totale e delle note
    console.log("Importo totale della parcella:", importoTotale);
    console.log("Note:");
    console.log("- " + ivaNote);
    console.log("- " + ritenutaNote);
    console.log("- " + previdenzaNote);
  
    // Aggiornamento dello stato o dell'output desiderato
  };
  

  const onApplicaClicked = () => {
    if (powArray.length === 0) {
      inputedArray[inputedArray.length - 1].symbol = "+";
      inputedArray.push({ number: "", symbol: "+", nota: "" });
      inputedArray.push({ number: "", symbol: "-", nota: "" });
      inputedArray.push({ number: "", symbol: "=", nota: "" });
      inputedArray.push({ number: "", symbol: "", nota: "" });
      switch (changeIva) {
        case "0":
          break;
        case "1":
          inputedArray[inputedArray.length - 3].nota = "IVA Nesuna";
           /* inputedArray[inputedArray.length - 3].number =
            inputedArray[inputedArray.length - 5].number +
            inputedArray[inputedArray.length - 4].number; */
          break;
        case "2":
          inputedArray[inputedArray.length - 3].nota = "IVA " + 4 + "%";
          inputedArray[inputedArray.length - 3].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.04;
          break;
        case "3":
          inputedArray[inputedArray.length - 3].nota = "IVA " + 10 + "%";
          inputedArray[inputedArray.length - 3].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.1;
          break;
        case "4":
          inputedArray[inputedArray.length - 3].nota = "IVA " + 22 + "%";
          inputedArray[inputedArray.length - 3].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.22;
          break;
      }

      switch (changeRitenuta) {
        case "0":
          break;
        case "1":
          inputedArray[inputedArray.length - 4].nota = "Ritenuta Nessuna";
        /*   inputedArray[inputedArray.length - 4].number =
            inputedArray[inputedArray.length - 5].number; */
          break;
        case "2":
          inputedArray[inputedArray.length - 4].nota = "Maggiorazione 2%";
          inputedArray[inputedArray.length - 4].number =
            inputedArray[inputedArray.length - 5].number * 0.02;
          break;
        case "3":
          inputedArray[inputedArray.length - 4].nota = "Maggiorazione 4%";
          inputedArray[inputedArray.length - 4].number =
            inputedArray[inputedArray.length - 5].number * 0.04;
          break;
        case "4":
          inputedArray[inputedArray.length - 4].nota =
            "Maggiorazione 2% + INPS 4%";
          inputedArray[inputedArray.length - 4].number =
            inputedArray[inputedArray.length - 5].number * 0.06;
          break;
        case "5":
          inputedArray[inputedArray.length - 4].nota =
            "Maggiorazione 4% + INPS 4%";
          inputedArray[inputedArray.length - 4].number =
            inputedArray[inputedArray.length - 5].number * 0.08;
          break;

        default:
          break;
      }

      switch (changePrevidenza) {
        case "0":
          break;
        case "1":
          inputedArray[inputedArray.length - 2].nota = "Previdenza Nessuna";
         /*  inputedArray[inputedArray.length - 2].number =
            inputedArray[inputedArray.length - 5].number +
            inputedArray[inputedArray.length - 4].number; */
          break;
        case "2":
          inputedArray[inputedArray.length - 2].nota = "Previdenza  20%";
          inputedArray[inputedArray.length - 2].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.2;
          break;
        case "3":
          inputedArray[inputedArray.length - 2].nota = "23% sul 50%";
          inputedArray[inputedArray.length - 2].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.115;
          break;
        case "4":
          inputedArray[inputedArray.length - 2].nota = "23% sul 20%";
          inputedArray[inputedArray.length - 2].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.046;
          break;
        case "5":
          inputedArray[inputedArray.length - 2].nota = "Previdenza  4%";
          inputedArray[inputedArray.length - 2].number =
            (inputedArray[inputedArray.length - 5].number +
              inputedArray[inputedArray.length - 4].number) *
            0.04;
          break;
        default:
          break;
      }
      inputedArray[inputedArray.length - 1].number =
        inputedArray[inputedArray.length - 5].number * 1.0 +
        inputedArray[inputedArray.length - 4].number * 1.0 +
        inputedArray[inputedArray.length - 3].number * 1.0 -
        inputedArray[inputedArray.length - 2].number * 1.0;
      setInputedArray(inputedArray);
      setData("");
      setData(inputedArray[inputedArray.length - 1].number);
      setIsChange(!isChange);
    }
  };

  const dotClicked = (rowLength, symbol) => {
    if (powArray.length === 0) {
      setData(prevData => prevData + symbol);
      if (!inputedArray[inputedArray.length - 1].number.includes(".")) {
        inputedArray[inputedArray.length - 1].number =
        inputedArray[inputedArray.length - 1].number + ".";
        
        setInputNmber(inputedArray[inputedArray.length - 1].number);

        setInputedArray(inputedArray);

        setIsChange(!isChange);
      }
    }
  };

  const zeroClicked = (rowLength, symbol) => {
    if (powArray.length === 0) {
      setData(prevData => prevData + 0);
      if (inputedArray[inputedArray.length - 1].number.charAt(0) !== "0") {
        inputedArray[inputedArray.length - 1].number =
          inputedArray[inputedArray.length - 1].number + "0";
        setInputedArray(inputedArray);
        setInputNmber(inputedArray[inputedArray.length - 1].number);
        setIsChange(!isChange);
      }
    }
  };

  const lastDeleted = (rowLength, symbol) => {
    console.log('LAST DELE SclickNumber called with:', rowLength, symbol);

    if (powArray.length === 0) {
      inputedArray[inputedArray.length - 1].number = inputedArray[
        inputedArray.length - 1
      ].number
        .toString()
        .slice(0, -1);
      setData(data.toString().slice(0, -1));
      setInputedArray(inputedArray);
      setInputNmber(inputedArray[inputedArray.length - 1].number);
      setIsChange(!isChange);
    }
  };




const oprationResult = (rowLength, symbol) => {
  // Verifica se il simbolo dell'elemento precedente non è "="
  if (inputedArray[inputedArray.length - 2]?.symbol !== "=") {
      // Verifica se il numero dell'elemento corrente non è una stringa vuota
      if (inputedArray[inputedArray.length - 1]?.number !== "") {
          // Aggiungi un nuovo oggetto vuoto all'array inputedArray
          inputedArray.push({ number: "", symbol: "" });

          // Imposta il simbolo dell'elemento precedente con il simbolo corrente
          inputedArray[inputedArray.length - 2].symbol = symbol;

          // Incrementa il conteggio delle righe
          setRowCount(rowCount + 1);

          // Ottiene il numero corrente dall'elemento corrente
          const currentNumber = parseFloat(inputedArray[inputedArray.length - 1].number);

          // Calcola il risultato dell'espressione valutando il contenuto di data
          console.log(data , "DATA")
          inputedArray[inputedArray.length - 1].number = eval(data);

          // Imposta il risultato come il nuovo dato corrente
          setData(inputedArray[inputedArray.length - 1].number);

          // Imposta il numero corrente come input number con due cifre decimali
          setInputNmber(parseFloat(inputedArray[inputedArray.length - 1].number).toFixed(2));
      }
      // Imposta il flag di cambiamento su true
      setIsChange(!isChange);
      
      // Aggiorna l'array inputedArray
      setInputedArray(inputedArray);
  }
};

  const ivaOperate = (rowLength, type, percent) => {
    //I calcoli iva sono applicabili solo se esiste almeno un numero inserito
    if(inputedArray.length > 0 && inputedArray[0].number){
      if (powArray.length === 0) {
        if (inputedArray[inputedArray.length - 1].symbol === "") {
          inputedArray.push({ number: "", symbol: "" });

          inputedArray[inputedArray.length - 1].number =
            (inputedArray[inputedArray.length - 2].number * percent) / 100;

          switch (type) {
            case 0:
              inputedArray[inputedArray.length - 2].symbol = "+";
              inputedArray[inputedArray.length - 1].symbol =
                "IVA " + percent + "%";

              inputedArray.push({
                number: calculate(
                  parseFloat(inputedArray[inputedArray.length - 2].number),
                  parseFloat(inputedArray[inputedArray.length - 1].number),
                  inputedArray[inputedArray.length - 2].symbol
                ),
                symbol: "",
              });

              break;
            case 1:
              inputedArray[inputedArray.length - 2].symbol = "-";
              inputedArray[rowLength].symbol = "IVA " + percent + "%";

              inputedArray.push({
                number: calculate(
                  parseFloat(inputedArray[inputedArray.length - 2].number),
                  parseFloat(inputedArray[inputedArray.length - 1].number),
                  inputedArray[inputedArray.length - 2].symbol
                ),
                symbol: "",
              });

              break;
            case 2:
              inputedArray[inputedArray.length - 2].symbol = "=";
              inputedArray[inputedArray.length - 1].symbol =
                "IVA " + percent + "%";
              break;
            default:
              break;
          }

          setInputedArray(inputedArray);
          setData("");
          setData(inputedArray[inputedArray.length - 1].number);
          setRowCount(rowCount + 1);

          setIsChange(!isChange);
        }
      }
    }
  };

  const ArrotondaClicked = () => {
    if (inputedArray.length > 0) {
        // Ottieni il valore memorizzato in inputedArray[inputedArray.length-1], lo arrotondi a due decimali
        const roundedValue = parseFloat(inputedArray[inputedArray.length-1].number).toFixed(2);
        
        // Aggiorna lo stato per visualizzare il valore arrotondato
        inputedArray[inputedArray.length-1].number = roundedValue;
        setInputedArray([...inputedArray]); // Aggiorna lo stato inputedArray
        setInputNmber(roundedValue); // Aggiorna lo stato per la visualizzazione
    }
};

  const clickCclicked = () => {
    if (inputedArray.length !== 1) {
      if (
        inputedArray[inputedArray.length - 1].number === "" &&
        inputedArray[inputedArray.length - 1].symbol === ""
      ) {
        var substr = data.substring(
          0,
          data.length - inputedArray[inputedArray.length - 1].symbol.length
        );
        inputedArray.pop(inputedArray[inputedArray.length] - 1);
        inputedArray[inputedArray.length - 1].symbol = "";
        setInputedArray(inputedArray);
        setData(substr);
        // console.log('ddd');
      } else {
        if (inputedArray[inputedArray.length - 1].symbol === "") {
          var substr = data
            .toString()
            .substring(
              0,
              data.length - inputedArray[inputedArray.length - 1].number.length
            );
          inputedArray.pop(inputedArray[inputedArray.length - 1].number);
          setData(substr);

          inputedArray.push({ number: "", symbol: "" });
          setInputedArray(inputedArray);
          // console.log('ddddd');
        }
      }
    } else {
      if (inputedArray[inputedArray.length - 1].number !== "") {
        inputedArray[inputedArray.length - 1].number = "";
      }
    }

    // console.log(inputedArray[inputedArray.length-1].number.length);
    // setInputNmber('');
    // setInputedArray([
    //   {
    //     number: "",
    //     symbol: "",
    //   }
    // ]);
    // setRowCount(1);
    // setData('');
    setIsChange(!isChange);
  };

  const clickClear = () => {
    setInputNmber("");
    setInputedArray([
      {
        number: "",
        symbol: "",
      },
    ]);
    setRowCount(1);
    setData("");
  };

 

  const calculate = (num1, num2, operate) => {
    switch (operate) {
      case "+":
        return num1 + num2;
        break;
      case "-":
        return num1 - num2;
        break;
      case "x":
        return num1 * num2;
        break;
      case "÷":
        return num1 / num2;
        break;
      case "%":
        return num1 % num2;
      case "powy":
        return Math.pow(num1, num2);
    }
  };

  const downloadClicked = () => {
    const input = document.getElementById("pdf-element");
    const { width, height } = input.getBoundingClientRect();
  
    document.getElementById("pdf-element").style.height = "auto";
  
    html2canvas(input, { width, height }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("download.pdf");
    });
  
    document.getElementById("pdf-element").style.height = "250px";
  };

  const printClicked = () => {
    const input = document.getElementById("pdf-element");
    document.getElementById("pdf-element").style.height = "auto";

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var a = window.open("", "", "height=500, width=500");
      a.document.write("<html>");
      a.document.write("<body >");
      a.document.write(imgData.outerHTML);
      a.document.write("</body></html>");
      a.document.close();
      a.print();
    });
  };

  const sqrtClicked = (rowLength, symbol) => {
    inputedArray[inputedArray.length - 1].symbol = "sqrt";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = Math.sqrt(
      inputedArray[inputedArray.length - 2].number
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

   const percentageClicked = (rowLength, symbol) => {
    inputedArray[inputedArray.length - 1].symbol = "%";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = ((inputedArray[inputedArray.length - 2].number
    ) / 100).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const expClicked = (rowLength) => {
    inputedArray[inputedArray.length - 1].symbol = "exp";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = Math.exp(
      inputedArray[inputedArray.length - 2].number
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const logClicked = (rowLength) => {
    inputedArray[inputedArray.length - 1].symbol = "log";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = Math.log(
      inputedArray[inputedArray.length - 2].number
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const lnClicked = (rowLength) => {
    inputedArray[inputedArray.length - 1].symbol = "Ln";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = Math.log10(
      inputedArray[inputedArray.length - 2].number
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const divideClicked = (rowLength) => {
    inputedArray[inputedArray.length - 1].symbol = "1/X";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = (
      1 / inputedArray[inputedArray.length - 2].number
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const pow2Clicked = (rowLength) => {
    inputedArray[inputedArray.length - 1].symbol = "X^2";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = Math.pow(
      inputedArray[inputedArray.length - 2].number,
      2
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const pow3Clicked = (rowLength) => {
    inputedArray[inputedArray.length - 1].symbol = "X^3";
    inputedArray.push({ number: "", symbol: "" });

    inputedArray[inputedArray.length - 1].number = Math.pow(
      inputedArray[inputedArray.length - 2].number,
      3
    ).toFixed(2);

    setInputNmber(inputedArray[inputedArray.length - 1].number);
    setInputedArray(inputedArray);
    setData("");
    setData(inputedArray[inputedArray.length - 1].number);
  };

  const powyClicked = (rowLength, symbol) => {
    // inputedArray[inputedArray.length - 1].symbol = 'X';
    inputedArray[inputedArray.length - 1].symbol = "X^";

    setInputNmber("");
    setInputedArray(inputedArray);

    powArray.push(inputedArray[inputedArray.length - 1].number);

    setPowArray(powArray);
    
    setData(inputedArray[inputedArray.length - 1].number);
    // setInputNmber('');
    // setInputedArray([
    //   {
    //     number: "",
    //     symbol: "",
    //   }
    // ]);
    // setRowCount(1);
    // setIsChange(false);
  };

  //GESTISCI TASTIERA
  function handleKeyDown(event) {
    if (['Shift', 'Ctrl', 'Alt'].includes(event.key)) {
        return;
    }

    if (event.target.id === "calculator-input-field") { // Verifica se l'evento proviene dall'inputNumber
        if (event.key >= '0' && event.key <= '9') {
          const number = parseInt(event.key);
          clickNumber(rowCount, number); 
        } else if (event.key === 'Delete') {
          lastDeleted(rowCount, "del");
        }  else if (event.key === 'Enter') {
          oprationResult(rowCount, "=");
        } else if(event.key === '.' || event.key === ',' ){
            dotClicked(rowCount, ".")
        }
    }

    if (event.key === '+') {
        clickOperation(rowCount, '+');
    }

    if (event.key === '-') {
        clickOperation(rowCount, '-');
    }

    if (event.key === '*') {
        clickOperation(rowCount, '*');
    }

    if (event.key === '/') {
        clickOperation(rowCount, '/');
    }
}


  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

    
  }, [refreshKey, data]); // array vuoto come secondo argomento
  

  const TrashClicked = () => {
    setInputNmber("");
    setInputedArray([
      {
        number: "",
        symbol: "",
      },
    ]);
    setRowCount(1);
    setData("");
    setIsChange(false);
    setRefreshKey(prevKey => !prevKey);  // refresha tastiera


    console.log(data, inputedArray, inputNumber, "dopo trash click")

  };


  useEffect(() => {
    console.log("DATA CHANGED", data)
  }, [data])



const ricalcola = () => {
  // Chiama la funzione oprationResult per ricalcolare il risultato
  oprationResult(rowCount, "=");
};

const handlePlusMinus = () => {
  // Se non c'è un numero in input, inserisci il segno meno
  if (inputedArray.length === 0 || inputedArray[inputedArray.length - 1].number === "") {
      // Se l'ultimo simbolo è già il segno meno, rimuovilo
      if (inputedArray[inputedArray.length - 1]?.symbol === "-") {
          inputedArray.pop();
      } else {
          inputedArray.push({ number: "", symbol: "-" });
      }
  } else {
      // Altrimenti, inverti il segno del numero attuale
      const lastNumber = inputedArray[inputedArray.length - 1].number;
      inputedArray[inputedArray.length - 1].number = parseFloat(lastNumber) * -1;
  }

  // Aggiorna lo stato
  setInputedArray([...inputedArray]);
  setData(inputedArray[inputedArray.length - 1].number);

};



const handleNoteChange = (index, newValue) => {
  // Copia l'array inputedArray
  const updatedArray = [...inputedArray];

  // Aggiorna il valore delle note per l'elemento corrente
  updatedArray[index].nota = newValue;

  // Aggiorna lo stato con il nuovo array
  setInputedArray(updatedArray);
};



//oggetto per esportazione e stampa da passare a EXPORT BUTTON
let itemToExport = {
  "Nome": calcName ? calcName : '',

}

inputedArray.length > 0 && inputedArray.forEach((el, i) => {
  itemToExport[`Riga ${i + 1} ${el.nota ? `- ${el.nota}` : ''}`] = `${el?.number} ${el?.symbol}`
})

//CAPIRE COME GESTIRE RICALCOLO DI PIù CONTI
//CAPIRE GESTIONE DELLE OPERAZIONI NON BASICHE
//CAPIRE COME GESTIRE CANCELLAZIONE DI UN NUMERO QUANDO RIMANE VUOTO
//RIFAR FUNZIONARE CALCOLI E DATA - QUANDO I SONO MOLTI DATI/CALCOLI SEPARATI


//RICALCOLO: OBIETTIVO EFFETTUARE RICALCOLO DELLA PORZIONE DI NUMERI CHE E' STATA MODIFICATA
//DALL'INIZIO DEL CALCOLO FINO ALL'UGUALE, solo quella, non di tutti i caloli presenti nell'array
const recalculate = (arr) => {
  let result = '';
  for (let i = 0; i < arr.length - 1; i++) { // Escludiamo l'ultimo elemento (il risultato)
    const item = arr[i];
    const value = item.number;
    
    result += value;
    
    switch (item.symbol) {
      case '+':
      case '-':
      case '*':
      case '/':
        result += item.symbol;
        break;
      case '%':
        return eval(result) / 100;
      case 'sqrt':
        return Math.sqrt(eval(result));
      case 'powy':
        return Math.pow(eval(result), arr[i+1].number);
      case 'exp':
        return Math.exp(eval(result));
      case 'log':
        return Math.log10(eval(result));
      case 'ln':
        return Math.log(eval(result));
      case '1/X':
        return 1 / eval(result);
      case '=':
        return result; // Termina la funzione se incontra '='
      default:
        break;
    }
  }
  return result;
};
/* COLONNA SALVATAGGIO */

//sostituire con array di calcoli salvati nel db che abbia NAME e DATA (GET)
const [dataSaved, setDataSaved] = useState([])

//SOSTITUIRE CON RICHIESTE POST
function saveCalculation(e){
  e.preventDefault()
  if(!calcName){
    setCalcName("Calcolo")
  }
  setDataSaved(prev => {
    return [...prev, {name: calcName, data: inputedArray}]
  })
  setCalcName("")
}

//SOSTITUIRE CON RICHIESTE DELETE
function deleteCalculation(index){
  let updateArr = dataSaved.filter((el, i) => i != index)
  setDataSaved(updateArr)
}

function redoCalculation(index){
  let selectedCalc = dataSaved.find((el, i) => i == index)
  setInputedArray(prev => {
    if(inputedArray.length > 1){
      return [
        ...prev, 
        {
        number: "",
        symbol: "",
        nota:""
        },
        ...selectedCalc.data
      ]
    } else {
      return [
        ...selectedCalc.data
      ]
    }
  
})
}

/* riutilizza valore */
const [reuseIndex, setReuseIndex] = useState(-1); // Stato per tenere traccia dell'indice su cui è attivo il riutilizzo

const handleMouseEnter = (index) => {
  //se è stata inserita un operazione, e ora l'utente deve inserire un numero
  if((inputedArray.length > 2 &&  (inputedArray[inputedArray.length - 1].symbol == "" && inputedArray[inputedArray.length - 1].number == ""  )))
    setReuseIndex(index); 
};

const handleMouseLeave = () => {
  setReuseIndex(-1); // Resetta l'indice quando il mouse esce dalla cella
};

const handleReuseClick = (currentValue) => {
  const newArray = [...inputedArray];

  // Verifica se inputedArray ha almeno un elemento prima di sostituirlo
  if (newArray.length > 0) {

    // Sostituisci l'ultimo elemento con il nuovo oggetto
    newArray[newArray.length - 1] = { number: currentValue, symbol: "", nota: "" };

    // Aggiorna lo stato con il nuovo array modificato
    setInputedArray(newArray);

    // Aggiorno Data
    setData(prevData => prevData + currentValue);


    // Resetta l'indice per far scomparire il bottone dopo il click
    setReuseIndex(-1);
  }
};


  return (
    <div className="d-flex">
      <div className="calculator-container text-center">
        <h2 className="title pt-0">Calcolatrice</h2>
        <div className="">
          <Col xs={4} className="mx-auto d-flex">
            <input
                type="text"
                onChange={handleCalcName}
                value={calcName}
                className="me-2"
            />
            <ButtonRm onClick={saveCalculation} variant="outline-gray" size="small" disabled={(inputedArray?.length > 1 && calcName)? false : true}>Salva</ButtonRm>
          </Col>
          <div className="button-group d-flex justify-content-center">
          
            <button className="top-btn ms-2" data-tip data-for="calculatorTooltip" onClick={ricalcola}>
              {/* <FaCalculator /> */}
              <img loading="lazy" src={CalCulator} alt="Your SVG" />
              &nbsp;&nbsp;&nbsp; Ricalcola
            </button>
          {/*  <ReactTooltip id="calculatorTooltip" place="top" effect="solid">
              Ricalcola l’elenco dopo aver modificato dei valori
            </ReactTooltip> */}
            <button className="top-btn-icon ms-2" onClick={() => TrashClicked()}>
              {/* <BsTrash /> */}
              <img loading="lazy" src={Trash_Light} alt="Your SVG" />
            </button>
            <ExportButton data={itemToExport} mapping={{}} showPrint={true} classes="top-btn-icon"/>


          </div>
        </div>

        <ScrollToBottom className="table-section">
          <table className="table_calculator" id="pdf-element">
            <thead>
              <tr>
                <th width="5%" className="table-header"></th>
                <th width="20%" className="table-header">
                  Valore
                </th>
                <th width="15%" className="table-header">
                  Operazione
                </th>
                <th width="55%" className="table-header">
                  Nota
                </th>
              </tr>
            </thead>
            <tbody>
              {inputedArray.length &&
                inputedArray.map((item, index) => {
                  return (
                    <tr style={{ height: "10px" }} key={index}>
                      
                      <td className="table-line">{index + 1}</td>
                      <div  onMouseEnter={() => handleMouseEnter(index)} 
                      onMouseLeave={handleMouseLeave}>
                        <td className="table-line">
                        <input
                        style={{ border: 'none', borderBottom: (item.number != "" && !isNaN(item?.number) && item.symbol == '') ? "1px solid gray" : "none", borderRadius: 0 }}
                        type="text"
                        value={item.number}
                        /* TENTATIVO DI IMPLEMTAZIONE FUNZIONE MODIFICA CALCOLO - FUNZIONE PER OPERAZIONI BASE +, -, *, /,
                        MA PARZIALMENTE PER LE OPERAZIONI SPECIALI */
                       /*  onChange={(e) => {
                          let newVal = e.target.value;
                        
                          // Crea una copia dell'array
                          let newArr = [...inputedArray];
                        
                          // Gestisci diversamente gli elementi vuoti (destinati ai commenti)
                          if (item.number === "" && item.symbol === "" && item.nota !== undefined) {
                            // Questo è un elemento di commento, permetti qualsiasi input
                            newArr[index] = { ...item, number: newVal };
                          } else {
                            // Per gli altri elementi, mantieni la logica precedente
                            if (!isNaN(item.number) && String(item.number).trim() !== '') {
                              // Elemento numerico esistente, permetti solo numeri
                              if (!isNaN(newVal) && newVal.trim() !== '') {
                                newVal = newVal.replace(/[^0-9]/g, '');
                                newArr[index] = { ...item, number: newVal };
                        
                                // Trova l'inizio del blocco di calcolo corrente
                                let startIndex = index;
                                while (startIndex > 0 && newArr[startIndex - 1].number !== '' && newArr[startIndex - 1].symbol !== '') {
                                  startIndex--;
                                }
                        
                                // Trova la fine del blocco di calcolo corrente 
                                let endIndex = index;
                                while (endIndex < newArr.length - 1 && newArr[endIndex].symbol !== '=') {
                                  if (['%', 'sqrt', 'powy', 'exp', 'log', 'ln', '1/X'].includes(newArr[endIndex].symbol)) {
                                      endIndex++; // Include l'elemento successivo per le operazioni speciali
                                  }
                                  endIndex++;
                                }

                                // Include il simbolo '=' se presente
                                if (endIndex < newArr.length && newArr[endIndex].symbol === '=') {
                                    endIndex++;
                                }                                
                                
                                console.log(startIndex, endIndex, 'indici')
                        
                                // Estrai il blocco di calcolo corrente
                                let currentBlock = newArr.slice(startIndex, endIndex + 1);

                                console.log(currentBlock, 'blocco corrente')

                                // Ricalcola solo il blocco corrente
                                const calculatedResult = eval(recalculate(currentBlock));

                                // Aggiorna il risultato nel blocco corrente
                                if(currentBlock[0].symbol == "sqrt" ||
                                  currentBlock[0].symbol == "exp" ||
                                  currentBlock[0].symbol == "%" ||
                                  currentBlock[0].symbol == "powy" ||
                                  currentBlock[0].symbol == "log" ||
                                  currentBlock[0].symbol == "ln" ||
                                  currentBlock[0].symbol == "1/X" 
                                ){
                                  newArr[endIndex - 1].number = calculatedResult
                                } else{
                                  newArr[endIndex].number = calculatedResult
                                }
                              }
                            } else {
                              // Nuovo elemento o elemento vuoto, permetti qualsiasi input
                              newArr[index] = { ...item, number: newVal };
                            }
                          }
                        
                          console.log(newArr, "NUOVO");
                          setInputedArray(newArr);
                        }} */
                      />
                      

                      

                            {reuseIndex === index ? ( 
                              <button onClick={() => handleReuseClick(item.number)} className="reuse-button">
                                Riutilizza
                              </button>
                            ) : null}

                          {inputedArray.length !== index + 1 &&
                          item.symbol === "=" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol.includes("IVA") ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "sqrt" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "exp" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "log" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "Ln" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "1/X" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "X^2" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "X^3" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "Y" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "Pi" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                          {inputedArray.length !== index + 1 &&
                          item.symbol === "Phi" ? (
                            <hr />
                          ) : (
                            ""
                          )}
                        </td>
                      </div>
                      <td className="table-line_symbol">{item.symbol}</td>
                      <td className="table-inline">
                        <input
                          type="text"
                          className="table-note"
                          id="table-note"
                          value={inputedArray[index]?.nota ? inputedArray[index]?.nota : ""}
                          onChange={(e) => handleNoteChange(index, e.target.value)}

                        
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </ScrollToBottom>

        <div className="group-input">
          <Row className="w-100 justify-content-between">
            <Col className="col-xl-5 col-md-6 col-12 mg-13 mr-13" style={{ marginLeft: "2%"
            }}>
            <input
                type="text"
                value={inputedArray.length > 0 ? inputedArray[inputedArray.length-1].number : ""}
                id="calculator-input-field"
                className="w-100"
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' || e.key === 'Delete') {
                    // Se il tasto premuto è "Backspace" o "Delete", chiama la funzione lastDeleted
                    lastDeleted(rowCount, e.key);
                  }
                }}
              
              />
            </Col>
            <Col className="col-xl-4 col-md-6 col-12 mg-13">
              <button
                className="top-btn"
                data-tip
                data-for="ArrotondaTooltip"
                onClick={() => ArrotondaClicked()}
              >
                € Arrotonda
              </button>
              <ReactTooltip id="ArrotondaTooltip" place="top" effect="solid">
                arrotonda al centesimo
              </ReactTooltip>
              <button
                className="top-btn-icon"
                data-tip
                data-for="piTooltip"
                onClick={() => piClicked()}
              >
                π
              </button>
              <ReactTooltip id="piTooltip" place="top" effect="solid">
                il numero pi greco
              </ReactTooltip>
              <button
                className="top-btn-icon"
                data-tip
                data-for="phiTooltip"
                onClick={() => phiClicked()}
              >
                Φ
              </button>
              <ReactTooltip id="phiTooltip" place="top" effect="solid">
                il numero phi
              </ReactTooltip>
            </Col>
          </Row>
        </div>

        <div className="group-calculator">
          <Row className="w-100 justify-content-between m-0">
            <Col className="col-lg-5 col-md-12 col-12 mr-13">
              <div>
                <div className="block">
                  <Row>
                    <div className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-primary"
                        onClick={() => clickCclicked()}
                      >
                        C
                      </button>
                    </div>
                    <div className="col-xl-2 col-md-2 col-2">
                      <button className="cal-button-primary" onClick={handlePlusMinus}>±</button>
                    </div>
                    <div className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-primary"
                        onClick={() => percentageClicked(rowCount, "%")}
                      >
                        %
                      </button>
                    </div>
                    <div className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-second"
                        onClick={() => clickOperation(rowCount, "÷")}
                      >
                        ÷
                      </button>
                    </div>
                    <div className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="sqrtTooltip"
                        onClick={() => sqrtClicked(rowCount, "sqrt")}
                      >
                        Sqrt
                      </button>
                      <ReactTooltip id="sqrtTooltip" place="top" effect="solid">
                        Radice quadrata
                      </ReactTooltip>
                    </div>
                    <div className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="divideTooltip"
                        onClick={() => divideClicked(rowCount)}
                      >
                        1/x
                      </button>
                      <ReactTooltip id="divideTooltip" place="top" effect="solid">
                        inverso
                      </ReactTooltip>
                    </div>
                  </Row>
                </div>
                <div className="block">
                  <Row>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 7)}
                      >
                        7
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 8)}
                      >
                        8
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 9)}
                      >
                        9
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-second"
                        onClick={() => clickOperation(rowCount, "*")}
                      >
                        *
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="expTooltip"
                        onClick={() => expClicked(rowCount)}
                      >
                        Exp
                      </button>
                      <ReactTooltip id="expTooltip" place="top" effect="solid">
                        e elevato a x
                      </ReactTooltip>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="pow2Tooltip"
                        onClick={() => pow2Clicked(rowCount)}
                      >
                        x^2
                      </button>
                      <ReactTooltip id="pow2Tooltip" place="top" effect="solid">
                        x al quadrato
                      </ReactTooltip>
                    </Col>
                  </Row>
                </div>
                <div className="block">
                  <Row>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 4)}
                      >
                        4
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 5)}
                      >
                        5
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 6)}
                      >
                        6
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-second"
                        onClick={() => clickOperation(rowCount, "-")}
                      >
                        -
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="logTooltip"
                        onClick={() => lnClicked(rowCount)}
                      >
                        Log
                      </button>
                      <ReactTooltip id="logTooltip" place="top" effect="solid">
                        logaritmo base 10
                      </ReactTooltip>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="pow3Tooltip"
                        onClick={() => pow3Clicked(rowCount)}
                      >
                        x^3
                      </button>
                      <ReactTooltip id="pow3Tooltip" place="top" effect="solid">
                        x al cubo
                      </ReactTooltip>
                    </Col>
                  </Row>
                </div>
                <div className="block">
                  <Row>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 1)}
                      >
                        1
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 2)}
                      >
                        2
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => clickNumber(rowCount, 3)}
                      >
                        3
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-second"
                        onClick={() => clickOperation(rowCount, "+")}
                      >
                        +
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="lnTooltip"
                        onClick={() => logClicked(rowCount)}
                      >
                        Ln
                      </button>
                      <ReactTooltip id="lnTooltip" place="top" effect="solid">
                        logaritmo naturale
                      </ReactTooltip>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-third"
                        data-tip
                        data-for="powyTooltip"
                        onClick={() => powyClicked(rowCount, "powy")}
                      >
                        x^y
                      </button>
                      <ReactTooltip id="powyTooltip" place="top" effect="solid">
                        x elevato y
                      </ReactTooltip>

                      
                    </Col>
                  </Row>
                </div>
                <div className="block">
                  <Row>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => dotClicked(rowCount, ".")}
                      >
                        ,
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => zeroClicked(rowCount, "0")}
                      >
                        0
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-default"
                        onClick={() => lastDeleted(rowCount, "del")}
                      >
                        ⇐
                      </button>
                    </Col>
                    <Col className="col-xl-2 col-md-2 col-2">
                      <button
                        className="cal-button-second"
                        onClick={() => oprationResult(rowCount, "=")}
                      >
                        =
                      </button>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col className="col-lg-6 col-md-8 col-12 d-none d-lg-block">
              <div className="block mb-4">
                <Row className="align-items-start text-start">
                  <div>
                    Calcolo parcella <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1000, hide: 300 }}
                    overlay={
                      <Tooltip className="tooltipCalc">Calcolo l'importo della parcella secondo i dati di IVA, ritenuta d'acconto e  cassa previdenziale, partendo dall'imponibile.</Tooltip>
                    }
                  >
                    <i class="bi bi-info-circle ms-2"></i>
                  </OverlayTrigger> 
                  </div>
                </Row>
                <Row className="align-items-end">
                  <div className="col-3 d-flex flex-column justify-content-start">
                    <div className="text-start"
                      style={{
                      
                        fontFamily: "Mulish",
                        fontSize: "13px",
                      }}
                    >
                      Iva
                    </div>
                    <select
                      className="input-section"
                      onChange={onIvaChangeHandler}
                      style={{ height: "40px" }}
                    >
                      <option value="0"></option>
                      <option value="1">Nessuna</option>
                      <option value="2">4%</option>
                      <option value="3">10%</option>
                      <option value="4">22%</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <div
                      style={{
                        fontFamily: "Mulish",
                        fontSize: "13px",
                      }}
                      className="text-start"
                    >
                      Rit.d’acconto
                    </div>
                    <select
                      className="input-section"
                      onChange={onRitenutaChangeHandler}
                      style={{ height: "40px" }}
                    >
                      <option value="0"></option>
                      <option value="1">Nessuna</option>
                      <option value="2">Maggiorazione 2%</option>
                      <option value="3">Maggiorazione 4%</option>
                      <option value="4">Maggiorazione 2% + INPS 4% </option>
                      <option value="5">Maggiorazione 4% + INPS 4%</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <div
                      style={{
                        
                        fontFamily: "Mulish",
                        fontSize: "13px",
                      }}
                      className="text-start"
                    >
                      Previdenza
                    </div>
                    <select
                      className="input-section"
                      onChange={onPrevidenzaChangeHandler}
                      style={{ height: "40px" }}
                    >
                      <option value="0"></option>
                      <option value="1">Nessuna</option>
                      <option value="2">20%</option>
                      <option value="3">23% sul 50%</option>
                      <option value="4">23% sul 20%</option>
                      <option value="5">4%</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <button
                      className="cal-button-forth mg-13"
                      onClick={() => onApplicaClicked2(rowCount)}
                      disabled={(inputNumber && (changeIva != 0 && changePrevidenza != 0 && changeRitenuta != 0)) ? false:true }
                    >
                      <i class="bi bi-arrow-right"></i>
                    </button>
                  </div>
                
                </Row>
              </div>
              <div className="block">
                <Row>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 1, 22);
                      }}
                    >
                      -Iva 22%
                    </button>
                  </Col>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 1, 10);
                      }}
                    >
                      -Iva 10%
                    </button>
                  </Col>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 1, 4);
                      }}
                    >
                      -Iva 4%
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="block">
                <Row>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 0, 22);
                      }}
                    >
                      +Iva 22%
                    </button>
                  </Col>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 0, 10);
                      }}
                    >
                      +Iva 10%
                    </button>
                  </Col>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 0, 4);
                      }}
                    >
                      +Iva 4%
                    </button>
                  </Col>
                </Row>
              </div>
              <div className="block">
                <Row>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 2, 22);
                      }}
                    >
                      Iva 22%
                    </button>
                  </Col>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 2, 10);
                      }}
                    >
                      Iva 10%
                    </button>
                  </Col>
                  <Col className="col-xl-3 col-md-3 col-3 mg">
                    <button
                      className="cal-button-default-1"
                      onClick={() => {
                        ivaOperate(rowCount, 2, 4);
                      }}
                    >
                      Iva 4%
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>


      </div>

      <div className="calc-col-save">
        <div>
          <small className="fw-bold mb-2 text-center">Calcoli salvati</small>
          {
            dataSaved.length > 0 &&
            dataSaved.map((el, i) => {
              return <CalcItem key={i} el={el} index={i} deleteCalculation={deleteCalculation} redoCalculation={redoCalculation} />
            })
          }
        </div>
      </div>
    </div>
  );
}


function CalcItem(props){


  //GESTIONE ESPORTAZIONE SALVATI
  let itemSavedToExport = {
    "Nome": props.el?.name
  }

  props.el.data.length > 0 &&  props.el.data.forEach((el, i) => {
    itemSavedToExport[`Riga ${i + 1} ${el.nota ? `- ${el.nota}` : ''}`] = `${(el?.number)} ${el?.symbol}`
  })

  return(
    <div className="calc-item-container">
      <div className="calc-item-card">
        <small className="mb-4">{props.el?.name}</small>
        <div className="d-flex mt-2">
          <OverlayTrigger
          placement="top"
          delay={{ show: 600, hide: 300 }}
          overlay={
          <Tooltip>
            Elimina
          </Tooltip>
          }
          >
          <button
              type="button"
              className="btn round-icon-btn me-2"
              onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.deleteCalculation(props.index)
                  
              }}>
              <BsTrash />
          </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={{ show: 600, hide: 300 }}
            overlay={
              <Tooltip>
                Esporta
              </Tooltip>
            }
            >

            <ExportButton data={itemSavedToExport} mapping={{}} showPrint={false} classes="btn round-icon-btn btn me-2"/>

            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 300 }}
              overlay={
              <Tooltip>
                Riutilizza
              </Tooltip>
              }
              >
              <button
                  type="button"
                  className="btn round-icon-btn me-2"
                  onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.redoCalculation(props.index)
                  }}>
                  <BsArrowClockwise />
                  </button>
              </OverlayTrigger>
        </div>
      </div>
    </div>
  )
}



