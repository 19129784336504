import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../../assets/css/DeleteConfirm.css";

export default function DeleteConfirm(props) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(props.show);
  }, [props]);

  const handleClose = () => {
    setShow(false);
    props.setDel(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="deleteConfirm-Modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} />
      <Modal.Body>
        <div>
          <div className="deleteConfirm-text1">
            Vuoi eliminare questo elemento?
          </div>
          <div className="deleteConfirm-text2 mt-8">
            Questa azione è irreversibile
          </div>
          <div className="d-flex align-items-center mt-3 mb-3 form-last-btns justify-content-center flex-column">
            <button
              type="button"
              className="next-btn btn-last rounded-3 shadow w-193 border-0"
              onClick={() => {
                handleClose();
                props.confirm();
              }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="back-btn btn-last rounded-3 shadow w-193 border-0 mt-3"
            >
              Annulla
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
