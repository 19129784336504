import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"

import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import { checkUserRole, checkMaster } from "../../utils/checkUserRole";

import { checkUserData } from "../../utils/checkUserRole";
import ModalCheckUser from "../ModalCheckUser";

import {OverlayTrigger} from "react-bootstrap";
import {Tooltip }from "react-bootstrap";

import api, { BASE_URL } from "../../api/api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    sex: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	})
});

export default function Inviti (props){

    const data = {
        email:"",
        role:""
    }

    const [basicInvitation, setBasicInvitation] = useState([data])
    const [collabInvitation, setCollabInvitation] = useState([])
    //COLLABORATORE ROWS => BASED ON THE NUMBER OF COLLABORATOR SUBSCRIPTIONS BOUGHT

    function addNewRow(){
        setBasicInvitation(prev => {
            return [...prev, data]
        })
    }

    

    function sendCollabInvite(){
        //invia email
        setCollabInvitation([data])
    }

    function removeRow(id){
        const newArr = basicInvitation.filter((item, index) => index != id)
        setBasicInvitation(newArr)
    }

    //CHECK IF DATA REQUIRED ARE COMPILED
    const [dataRequired, setDataRequired] = useState(true);
    //Gestisce il modale
    const [showCheck, setShowCheck] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
         try {
             const response = await checkUserData();
             setDataRequired(response.isVerifiedFull);
             if (!response.isVerifiedFull) {
               setShowCheck(true);
           }
         } catch (error) {
             // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
             console.error('Errore durante il recupero dei dati utente:', error);
         }
     };

     fetchData();
 }, []);


 const [invitationBody, setInvitationBody] = useState({
    role:"",
    email:""
 })

 function handleChange(e){
    const {name, value} = e.target
    setInvitationBody(prev => {
        return {
            ...prev,
            [name]: value
        }
    })
 }


 async function sendInvitation(){
    //determina ruolo
    let body = {...invitationBody}
    //SE RUOLO ESISTENTE E' MASTER O COLLABORATORE, NEGLI ALTRI CASI MANTINEI IL RUOLO DEFINITO DALL'UTENTE
    if(isInviteeMasterOrCollaborator){
        body = {...invitationBody, role: "SUPPLIER"}
    }

    try {
         let response = await api().post(`/api/v1/invitation/`, body)
         if(response?.data?.success){
            toast.success("Invito inoltrato.", {
                toastId: 18902,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
              });
         } else {
            toast.error("A causa di un problema tecnico non siamo riusciti a inoltrare l'invito. Riprova più tardi.", {
                toastId: 18903,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });
       
        } 
    } catch (err) {
        console.log(err);
        toast.error("A causa di un problema tecnico non siamo riusciti a inoltrare l'invito. Riprova più tardi.", {
            toastId: 18904,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
        });
    }

 }


function sendBasicInvite(){
    //invia email
    sendInvitation()
}

//GESTISCE IL RUOLO DELL'UTENTE CHE VIENE INVITATO
const [isInviteeMasterOrCollaborator, setInviteeMasterOrCollaborator] = useState(false)

//GESTISCE SE L'EMAIL E' STATA CONTROLLATA O MENO 
const [emailIsChecked, setEmailIsChecked] = useState(false)

useEffect(() => {
    setInviteeMasterOrCollaborator(false)
    setEmailIsChecked(false)
}, [invitationBody.email])

async function checkInvitationEmail(){
    setInviteeMasterOrCollaborator(false)
    setEmailIsChecked(false)

    try {
      let response =  await api().post(`/api/v1/invitation/invitationEmailCheck`, {email: invitationBody.email})

      console.log(response, "response")

      if(response?.data?.success){
        //SE LA MAIL GIA' ESISTE NEL DATABASE
        setEmailIsChecked(true)
        if(response.data.emailExists){
            console.log("Corrispondenza trovata", response.data.role )

            //SE IL RUOLO E' MASTER O COLLABORATORE
            if(response.data.role === "MASTER" || response.data.role === "MASTER_PRO" || response.data.role === "COLLABORATOR"   ){
                setInviteeMasterOrCollaborator(true)
            } 

        } else {
            //LA MAIL NON ESISTE
          

        }
      } else if(!response?.data?.success){
        if(response.data.emailExists){
            //L'UTENTE HA PROVATO A INSERIRE LA PROPRIA MAIL
            console.log("You cannot enter your own email")
            setEmailIsChecked(false)
            toast.error("Non è consentito inserire la propria e-mail in questo campo", {
                toastId: 18905,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });

            
        } else {
            //CI SONO DEI PROBLEMI TECNICI
            console.log("Due to technical problems we cannot provide the requested information")
            setEmailIsChecked(false)
            toast.error("A causa di problemi tecnici non siamo ing rado di completare la tua richiesta in questo momemento. Riprova più tardi.", {
                toastId: 18906,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });

        }
      }
      
   } catch (err) {
      console.log(err, "errore")
    }
}


console.log({
    emailIsChecked,
    isInviteeMasterOrCollaborator
})

    return(
        <>
            <div className="settings-tab-content">
            <ToastContainer />

                <Row>
                    <p className="fs-18 text-dark fw-bold">Inviti</p>
                    <p className="fs-14 opacity-75">Qui potrai gestire gli inviti</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Invita Utenti Base</p>
                            <p className="fs-14 text-dark opacity-75">Per utenti base si intende conduttori, proprietari, fornitori . Questi utenti entrano gratuitamente sulla piattaforma, dietro invito. Il numero di inviti è illimitato.</p>
                    </Col>

                    <Col md={6} className="ps-0">
                        {basicInvitation.map((item, index) => {
                            return <div className="d-flex mb-2">
                            <Col md={8} className="me-2">
                                <Form.Group className="">
                                    <Form.Control 
                                        // {...register("issueEntity")}
                                        type="email" 
                                        placeholder="Inserisci l'email"
                                        name="email"
                                        onChange={handleChange}
                                        
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {/* {errors.issueEntity?.message} */}
                                    </Form.Control.Feedback>

                                </Form.Group>

                               {(!emailIsChecked) && <ButtonRm variant="outline" size={'small'} onClick={checkInvitationEmail}  disabled={(invitationBody.email) ? false: true} className="mt-2">
                                Controlla email</ButtonRm>
                               }
                            </Col>

                           { /* RUOLO: mostrare solo se account non esiste o 
                                account esiste già e ruolo è diverso da MASTER, MASTER_PRO, COLLABORATORE
                                SE RUOLO è MASTER, MASTER_PRO, COLLABORATORE non mostarre campo ruolo che di default sarà SUPPLIER
                           */
                            (emailIsChecked && !isInviteeMasterOrCollaborator) && <Col md={4}>
                                <Form.Group >
                                    <Form.Select
                                        // {...register("documentType")}
                                        type="text" 
                                        name="role"
                                        onChange={handleChange}
                                    >
                                        <option></option>
                                        <option value="SUPPLIER">Fornitore</option>
                                        <option value="CONDUCTOR">Conduttore</option>
                                        <option value="OWNER">Proprietario</option>
                                     <option value="COLLABORATOR">Collaboratore</option>                                   </Form.Select>
                                
                                </Form.Group>
                            </Col>
                            
                            }
                            {basicInvitation.length > 1 && <img loading="lazy" src={removeImg} onClick={() => removeRow(index)} className="remove-image-invitation ms-2"/>}
                        </div>
                        })}
{/*                         <p className="text-secondary-settings mt-1" onClick={addNewRow}>+ Aggiungi Riga</p>
 */}                        <div className="d-flex justify-content-end">
                        {(emailIsChecked && !dataRequired) && <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 50, hide: 50 }}
                                                        overlay={
                                                          <Tooltip id="tooltip" className="exclamation-tooltip">
                                                                 <p className="mb-3">Per procedere inviare degli inviti, è necessario prima completare le seguenti informazioni:</p>
                                                                <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
                                                                <p>2. <strong>Face ID</strong> </p>
                                                                <p>3. <strong>Documenti</strong> </p>
                                                          </Tooltip>
                                                        }>
                                                        <i class="bi  bi-info-circle me-2 fs-14"></i>
                                                      </OverlayTrigger>
                                                      }
                            {emailIsChecked && <ButtonRm variant="outline-gray" onClick={sendBasicInvite} /* disabled={dataRequired? false: true} */>Invia</ButtonRm>}
                        </div>
                        
                    </Col>
                    <hr className="opacity-10 my-3"/>
                </Row>

                {(checkMaster()) &&  <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Invita Collaboratori</p>
                            <p className="fs-14 text-dark opacity-75">Per poter invitare collaboratori è necessario acquistare il pacchetto collaboratori.</p>
                    </Col>

                    <Col md={6} className="ps-0">
                        {collabInvitation.length > 0 && collabInvitation.map((item, index) => {
                            return <div className="d-flex mb-2">
                            <Col md={8} className="me-2">
                                <Form.Group>
                                    <Form.Control 
                                        // {...register("issueEntity")}
                                        type="email" 
                                        placeholder="Inserisci l'email"
                                        // name="issueEntity"
                                        // value={data.issueEntity}
                                        // onChange={onChange}
                                        // isInvalid={!!errors.issueEntity}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {/* {errors.issueEntity?.message} */}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group >
                                    <Form.Select
                                        // {...register("documentType")}
                                        type="text" 
                                        // name="documentType"
                                        // value={data.documentType}
                                        // onChange={onChange}
                                        // isInvalid={!!errors.documentType}
                                    >
                                        <option value="Collbaoratore">Collaboratore</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {/* {errors.documentType?.message} */}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </div>
                        })}
                        {collabInvitation.length > 0 && <div className="d-flex justify-content-end mt-2">
                            <ButtonRm variant="outline-gray" onClick={sendCollabInvite}>Salva</ButtonRm>
                        </div>}
                        {
                            collabInvitation.length == 0 && 
                            <div className="faceIdDiv text-start mb-2">
                                <div className="d-flex flex-column align-items-center">
                                    <img loading="lazy" src={pianoIcon} width="50px"/> 
                                    <p className="fs-16 mb-3">Non hai ancora acquistato pacchetti collaboratore</p>
                                    <ButtonRm variant="outline-gray" onClick={() => props.setKey("Pacchetti")}>Acquista Ora</ButtonRm>
                                </div>
                            
                            {/* <div className="mt-3">
                                <ButtonRm variant="outline" className="me-2" >Rimuovi collaboratore</ButtonRm>
                                <ButtonRm variant="outline" >Gestisci</ButtonRm>
                            </div> */}
                        </div>
                        }
                        
                    </Col>
                    <hr className="opacity-10 my-3"/>
                </Row>   
                } 

            </div >
            
        </>

        
    )
}