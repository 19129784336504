import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";

export default function Section1({ onChangeSection }) {
  const [status, setStatus] = useState(0);
  const [radioStatus, setRadioStatus] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputNumber, setInputNmber] = useState(null);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [Sanzioni, setSanzioni] = useState(0);
  const [Interessi, setInteressi] = useState(0);
  const [betweenDate, setBetweenDate] = useState(0);

  const [violazionePvc, setViolazionePvc] = useState(false)

  useEffect(() => {
    setViolazionePvc(false)
  }, [radioStatus])

  function handleViolazione(e){
    setViolazionePvc(e.target.checked)
  }

  //Violazione sanata dopo l’emissione del Pvc ( processo verbale di constatazione

  const radioChanged = (value) => {
    setRadioStatus(value);
  };

  const calClicked = () => {
    if (radioStatus !== 0 && inputNumber !== "" && inputNumber !== null) {
      setIsShowDetail(true);
      let start = moment(startDate);
      let end = moment(endDate);
      let date = end.diff(start, "day");
      let san = 0;
      let per;
      let inter = (parseInt(date) / 365) * 0.0125;
      setBetweenDate(date);
      let radio = parseInt(radioStatus);

      switch (radio) {
        case 1:
        case 2:
        case 4:
          if (date >= 0 && date <= 30) {
            san = (6 / 100) * date;
          } else if (date > 30 && date <= 90) {
            san = (12 / 100) * date;
          } else if (date > 90 && date <= 365) {
            san = (15 / 100) * date;
          } else if (date > 365 && date <= 730) {
            san = (17.14 / 100) * date;
          } else if (date > 730) {
            san = (20 / 100) * date;
          }
          break;
        case 3:
        case 5:
        case 6:
        case 7:
          if (date >= 0 && date <= 14) {
            san = (0.1 / 100) * date;
          } else if (date > 14 && date <= 30) {
            san = (1.5 / 100) * date;
          } else if (date > 30 && date <= 90) {
            san = (1.67 / 100) * date;
          } else if (date > 90 && date <= 365) {
            san = (3.75 / 100) * date;
          } else if (date > 365 && date <= 730) {
            san = (4.29 / 100) * date;
          } else if (date > 730) {
            san = (5 / 100) * date;
          }
          break;

        default:
          break;
      }

      setSanzioni(san * inputNumber);
      setInteressi(inter * inputNumber);
    }
  };

  const downloadClicked = () => {
    const input = document.getElementById("page-body");
    document.getElementById("page-body").style.height = "auto";

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("download.pdf");
    });
    document.getElementById("page-body").style.height = "742px";
  };

  const printClicked = async () => {
    // const input = document.getElementById("page-body").innerHTML;
    // var a = window.open('', '', 'height=500, width=500');
    // a.document.write('<html>')
    // a.document.write('<body >');
    // a.document.write(input);
    // a.document.write('</body></html>');
    // a.document.close();
    // a.print();
  };

  // Funzione per calcolare i ravvedimenti per le imposte di registro

// Funzione per calcolare la differenza in giorni tra due date
function giorniTraDate(data1, data2) {
  var diff = Math.abs(data2.getTime() - data1.getTime());
  var giorni = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return giorni;
}

const [message, setMessage] = useState("")
const [messageInteressi, setMessageInteressi] = useState("")

function calcolaRavvedimenti(dataStipula, dataPagamento, importo) {
  const dataStipulaConverted = new Date(dataStipula)
  const dataPagamentoConverted = new Date(dataPagamento)
  var giorniRitardo = giorniTraDate(dataStipulaConverted, dataPagamentoConverted) - 30;
  var sanzione = 0;
  var interessi = 0;

  if (giorniRitardo <= 0) {
    setMessage("Niente da pagare perché la registrazione è entro i 30 giorni stabiliti dalla legge.")
  }

  if(radioStatus == 1 || radioStatus == 2 || radioStatus == 4){
    if (giorniRitardo > 0) {
      if(violazionePvc){
        sanzione = importo * 0.24;
        setMessage(`€${sanzione.toFixed(2)} = 24% dell'imposta poiché violazione sanata dopo l'emissione del PVC`)
      }
      else if (giorniRitardo <= 15) {
        sanzione = Math.max(importo * 0.001, 20);
        setMessage(`€${sanzione.toFixed(2)} = 0.1% dell'imposta dal primo al 15° giorno (min. 20 euro)`)
      }
      else if (giorniRitardo <= 30) {
        sanzione = Math.max(importo * 0.06, 20);
        setMessage(`€${sanzione.toFixed(2)} = 6% dell'imposta entro il 30° giorno (min. 20 euro)`)
      } else if (giorniRitardo <= 90) {
        sanzione = importo * 0.12;
        setMessage(`€${sanzione.toFixed(2)} = 12% dell'imposta entro il 90° giorno`)
      } else if (giorniRitardo <= 365) {
        sanzione = importo * 0.15;
        setMessage(`€${sanzione.toFixed(2)} = 15% dell'imposta entro 1 anno`)
      } else if (giorniRitardo <= 730) {
        sanzione = importo * 0.1714;
        setMessage(`€${sanzione.toFixed(2)} = 17,14% dell'imposta entro 2 anno`)
      } else {
        sanzione = importo * 0.2;
        setMessage(`€${sanzione.toFixed(2)} = 20% dell'imposta oltre 2 anni`)
      }
      const interesseAnnuale = 0.025; // 2.5% annuale nel 2024
      const interesseGiornaliero = interesseAnnuale / 365; // Interesse giornaliero
      interessi = importo * interesseGiornaliero * giorniRitardo;
    } 
    //mostra sanzioni e interessi
    setSanzioni(sanzione.toFixed(2))
    setInteressi(interessi.toFixed(2))
    setMessageInteressi(`€${interessi.toFixed(2)} - Totale interessi (2,5% annuo)`)

  }

  if (radioStatus == 3 || radioStatus == 5 || radioStatus == 6 || radioStatus == 7){
    if (giorniRitardo > 0) {
      if (giorniRitardo > 0 && giorniRitardo <= 14) {
        sanzione = importo * (0.001 * giorniRitardo);
        setMessage(`€${sanzione.toFixed(2)} = 0,1% dell'imposta entro 14gg`)
      } else if (giorniRitardo <= 30) {
        sanzione = importo * 0.015;
        setMessage(`€${sanzione.toFixed(2)} = 1,5% dell'imposta entro 30gg`)
      } else if (giorniRitardo <= 90) {
        sanzione = importo * 0.0167;
        setMessage(`€${sanzione.toFixed(2)} = 1,67% dell'imposta entro 90gg`)
      } else if (giorniRitardo <= 365) {
        sanzione = importo * 0.0375;
        setMessage(`€${sanzione.toFixed(2)} = 3,75% dell'imposta entro 365`)
      } else if (giorniRitardo <= 730) {
        sanzione = importo * 0.0429;
        setMessage(`€${sanzione.toFixed(2)} = 4,29% dell'imposta entro 2 anni`)
      } else {
        sanzione = importo * 0.05;
        setMessage(`€${sanzione.toFixed(2)} = 5% dell'imposta oltre 2 anni`)
      }
    
      const interesseAnnuale = 0.025; // 2.5% annuale nel 2024
      const interesseGiornaliero = interesseAnnuale / 365; // Interesse giornaliero
      interessi = importo * interesseGiornaliero * giorniRitardo;
    } 
    //mostra sanzioni e interessi
    setSanzioni(sanzione.toFixed(2))
    setInteressi(interessi.toFixed(2))
    setMessageInteressi(`€${interessi.toFixed(2)} - Totale interessi (2,5% annuo)`)
  }

  setIsShowDetail(true)

  }

  //DATI PER ESPORTAZIONE
  const item = {
    ['Imposta dovuta']: `€ ${inputNumber}`,
    ['Sanzioni']: message,
    ['Interessi']: messageInteressi,
    ['Totale da pagare']: `Totale da pagare: € ${(inputNumber + parseFloat(Interessi) + parseFloat(Sanzioni)).toFixed(2)} `,
  }


  return (
    <div className={`page-body`} id="page-body">
      <div class="label-heading mb-3 d-block w-94 mt-15">
        <div class="label-text rel d-inline-block px-2 title-padding">
          <h1 class="fs-16 text-white text-uppercase">TIPO DI REGISTRAZIONE</h1>
        </div>
        <div class="label-heading1"></div>
      </div>
      {/* <div className='page-title'>
                <Image src={nav1} width="100%" />
            </div> */}
      <div className="space-top1">
        <div className="space-top1">
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice1"
              value="1"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(1)}
            />
            <label htmlFor="contactChoice1" className="btn-radio">
              &nbsp;Registrazione del contratto di locazione
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice2"
              value="2"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(2)}
            />
            <label htmlFor="contactChoice2" className="btn-radio">
              &nbsp;Registrazione del contratto di comodato
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice3"
              value="3"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(3)}
            />
            <label htmlFor="contactChoice3" className="btn-radio">
              &nbsp;Risoluzione anticipata del contratto
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice4"
              value="4"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(4)}
            />
            <label htmlFor="contactChoice4" className="btn-radio">
              &nbsp;Registrazione proposta d’acquisto o compromesso
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice5"
              value="5"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(5)}
            />
            <label htmlFor="contactChoice5" className="btn-radio">
              &nbsp;Annualità successiva
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice6"
              value="6"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(6)}
            />
            <label htmlFor="contactChoice6" className="btn-radio">
              &nbsp;Proroga (reg.normale)
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice7"
              value="7"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(7)}
            />
            <label htmlFor="contactChoice7" className="btn-radio">
              &nbsp;Cessione
            </label>
          </div>
        </div>
      </div>
      {/* <div className='page-title'>    
                <Image src={nav2} width="100%" />
            </div> */}
      <div class="label-heading mb-3 d-block w-94 mt-15">
        <div class="label-text rel d-inline-block px-2 title-padding">
          <h1 class="fs-16 text-white text-uppercase">DATE</h1>
        </div>
        <div class="label-heading1"></div>
      </div>
      <div className="space-top1">
        <div className="sub-title">Data di stipula del contratto</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <input
                type="date"
                className="dateInput"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="space-top1">
        <div className="sub-title">
          Data prevista per il pagamento dell’imposta
        </div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <input
                type="date"
                className="dateInput"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      {radioStatus == 1 && <div className="radioLabel mb-3">
        <input
          type="checkbox"
          id="check"
          name="iva"
          checked={violazionePvc}
          onChange={handleViolazione}
        />
        <label className="ms-2" for="check">
          {" "}
          Violazione sanata dopo l’emissione del Pvc ( processo verbale di constatazione)
        </label>
      </div>}

      <div class="label-heading mb-3 d-block w-94 mt-15">
        <div class="label-text rel d-inline-block px-2 title-padding">
          <h1 class="fs-16 text-white text-uppercase">IMPORTI</h1>
        </div>
        <div class="label-heading1"></div>
      </div>
      {/* <div className='page-title'>
                <Image src={nav3} width="100%" />
            </div> */}
      <Row>
        <Col md={5} >
            <div className="sub-title">Imposta dovuta</div>
            <div className="divInput">
              <span className="spanInput1">€</span>
              <input
                type="number"
                className="input-calc-symbol"
                value={inputNumber ? inputNumber : ""}
                onChange={(e) => {
                  let convertedValue = parseFloat(e.target.value.replace(",", "."))
                  setInputNmber(convertedValue)
                }}
              ></input>
            </div>
          </Col>
          <Col>
          <ButtonRm
                  style={{ marginTop: "1.5rem" }}
                  variant="secondary"
                  size="small"
                  onClick={() => calcolaRavvedimenti(startDate, endDate, inputNumber)}
                  disabled={(radioStatus && startDate && endDate && inputNumber) ? false : true}
                >
                  Calcola
                </ButtonRm>
          </Col>
      </Row>
      <div className="space-top1">
        {isShowDetail && (
          <div>
            <div className="space-top1">
              <Row>
                <Col xs={6}>
                  <div className="sub-title">Sanzioni</div>
                </Col>
                <Col xs={6}>
                  <div className="sub-title">Interessi</div>
                </Col>
              </Row>

              <Row className="space-top1">
                <Col xs={6}>
                  <div className="input-text">
                    € {((parseFloat(Sanzioni)).toFixed(2)).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="input-text">
                    € {((parseFloat(Interessi)).toFixed(2)).toLocaleString('it-IT', {minimumFractionDigits: 2})}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="page-title">
              <div className="label-heading my-3 d-block w-100">
                <div className="label-text rel d-inline-block py-2 px-2">
                  <h6 className="fs-14 text-white text-uppercase fw-semibold">
                    Elenco dei calcoli
                  </h6>
                </div>
              </div>
            </div>

            {/* <div className=' top-btn-section space-top1'>
                             <button className="top-btn-icon" onClick={() => printClicked()}>
                                <img loading="lazy" src={Print_Light} alt="Your SVG" />
                            </button>
                            <button className="top-btn-icon" onClick={() => downloadClicked()}>
                                <img loading="lazy" src={Import_Light} alt="Your SVG" /> */}
            <div className="text-end d-flex justify-content-end">
{/*             <ShareChat styleBtn="top-btn-icon ms-2" />
 */}
            {/*   <button className="top-btn-icon" onClick={() => printClicked()}>
                <i class="bi bi-printer"></i>
              </button> */}
              <ExportButton data={item} mapping={{}} classes="top-btn-icon ms-2"/>
            </div>

            {<div className="sub-title">
              {
              Sanzioni > 0 ?
              <div>
                <p className="fw-bold text-uppercase">Sanzione</p>
                <p className="mb-3">{message}</p>
                <p className="fw-bold text-uppercase">Interessi</p>
                <p className="mb-3">{messageInteressi}</p>
                <p className="fw-bold text-uppercase">Totale da pagare: € {(inputNumber + parseFloat(Interessi.replace(",", ".")) + parseFloat(Sanzioni.replace(",", "."))).toFixed(2)} </p>
              </div> 
              :
              <p>{message}</p>
             }

            </div>}
          </div>
        )}
      </div>
    </div>
  );
}
