import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { BsPlus, BsDash } from "react-icons/bs";
import { Link } from "react-router-dom";

function FAQComponent({faqs}) {
  const [open, setOpen] = useState([false, false, false, false]);

  const handleToggle = (index) => {
    let newState = [...open];
    newState[index] = !newState[index];
    setOpen(newState);
  };

 

  return (
    <Row className="my-5 flex-column mx-auto">
      {faqs.map((faq, index) => (
        <Col md={12} className="d-flex flex-column mx-auto p-3 px-5 mb-2" style={{ maxWidth: "1100px", background: "#E7EFF9", borderRadius: "1rem", cursor: "pointer" }}>
          <div className="d-flex justify-content-between align-items-center" onClick={() => handleToggle(index)}>
            <p className='fw-bold'>{faq.question}</p>
            
            {open[index] ? <BsDash className="fw-bold fs-30" /> : <BsPlus className="fw-bold fs-30" />}
          </div>
          {open[index] && <p>{faq.answer} {index == 3 && <span>  <Link to="/pacchetto" className="text-decoration-underline text-dark">Consulta la nostra pagina dei prezzi per maggiori dettagli.
                                </Link></span>}</p>}
        </Col>
      ))}
    </Row>
  );
}

export default FAQComponent;
