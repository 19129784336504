import React, { useState } from "react";
import FolderImg from "../../../assets/images/allegati-ph.svg";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";

const Attachment = ({ attachment, onChange }) => {
	const setFileName = (index, new_name) => {
		let temp = [...attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "attachment", value: temp } };
		onChange(ele);
	};
	const deleteFile = (index) => {
		let temp = [...attachment];
		temp.splice(index, 1);
		let ele = { target: { name: "attachment", value: temp } };
		onChange(ele);
	};
	const handleChange = (e) => {
		let temp = [...attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		let ele = {
			target: { name: e.target.name, value: temp },
		};
		onChange(ele);
	};

	console.log(attachment)
	return (
		<div className="form-item-align-box-white d-block">
			
			<div className="unit-btn-group mb-4">
				<label
					type="button"
					htmlFor="input-file"
					className="general-btn white-btn text-center">
					Seleziona
				</label>
				<input
					type="file"
					id="input-file"
					className="d-none"
					multiple={true}
					name="attachment"
					onChange={handleChange}></input>
			</div>
			{attachment?.length > 0 ? (
				<>
					{attachment.map((item, index) => {
						return (
							<FileCard
								key={index}
								name={item.name}
								setFileName={setFileName}
								deleteFile={deleteFile}
								id={index}
							/>
						);
					})}
				</>
			) : (
				<div className="unit-empty-img">
					<img loading="lazy" src={FolderImg} alt="" />
					<span className="img-comment">
						Non hai aggiunto ancora alcun allegato
					</span>
				</div>
			)}
		</div>
	);
};

export default Attachment;
