import TopHeading from "../components/TopHeading";
import "../assets/css/Intermediazione/TrovaFornitori.css"
import React, { useState, useEffect, useRef, useContext } from "react";


import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { Col } from "react-bootstrap";

import btnApple from "../assets/images/btn-apple.png"
import btnGp from "../assets/images/btn-gp.png"

import mock1 from "../assets/images/Group 29.png"
import mock2 from "../assets/images/Group 30.png"
import { useNavigate } from "react-router-dom";

import mockup from "../assets/images/mockup-intermediazione.png"
import api, { partners_endpoint } from "../api/api";

import Select from "react-select"


import options from "../partnerList";

import { usePartners } from "../context/partners";





const roles = [
    {
        category:"idraulico",
        img:"https://images.pexels.com/photos/6474205/pexels-photo-6474205.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"elettricista",
        img:"https://images.pexels.com/photos/5691588/pexels-photo-5691588.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"imbianchino",
        img:"https://images.pexels.com/photos/5691479/pexels-photo-5691479.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"giardiniere",
        img:"https://images.pexels.com/photos/5561310/pexels-photo-5561310.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"interior designer",
        img:"https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"architetto",
        img:"https://images.pexels.com/photos/4491459/pexels-photo-4491459.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"imprese di pulizia",
        img:"https://images.pexels.com/photos/4239031/pexels-photo-4239031.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        category:"notaio",
        img:"https://images.pexels.com/photos/7641842/pexels-photo-7641842.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
]



export default function TrovaFornitori () {
    
    const navigate = useNavigate();

    const {
        filteredPartners,
        filterPartners,
        setSelectedCategories,
        selectedCategories,
        selectedLocation,
        setSelectedLocation,
        cityWorkplaceArray,
        provinceWorkplaceArray
        //getPartners 
    } = usePartners();

   /*  useEffect(() => {
        getPartners();
    }, [getPartners]); */

    const handleCategoryChange = (selectedOption) => {
        setSelectedCategories(selectedOption ? [selectedOption.value] : []);
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption ? selectedOption.value : null);
    };

    
    const customStyles = {
    control: (provided) => ({
        ...provided,
        border: 'none', // Rimuove il bordo
        boxShadow: 'none', // Rimuove l'outline
        borderBottom: '0px solid #ccc', // Aggiungi un bordo inferiore se necessario
        minWidth: '150px', // Imposta la larghezza minima
    }),
    dropdownIndicator: () => ({
        display: 'none', // Nasconde l'icona chevron down
    }),
    indicatorSeparator: () => ({
        display: 'none', // Nasconde il separatore
    }),
    singleValue: (provided) => ({
        ...provided,
        maxWidth: '130px', // Imposta la larghezza massima del testo
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        }),
    
    };


    const viewList = (e) => {
        e.preventDefault()
        filterPartners();
        navigate("/statoavanzamentolavori/trova-fornitore/lista")
    };

    console.log(filteredPartners)

    return(
        <div>
            <div className="title-search-group">
                <div className="right-header">
                    <TopHeading SmallHeading="empty" />
                </div>
            </div> 

            <div className="partner-hero">
                <div className="partner-hero-search ">
                    <p className="fs-24 fs-md-32 fw-bold mb-3" style={{ lineHeight:'1.2'}} >Il buon gusto deriva più dal giudizio che dall’intelligenza</p>
                    <p className="mb-4">Scegli con criterio i tuoi professionisti e monitora il merito</p>
                    <div className="partner-hero-double-input">
                        <InputGroup className="partner-hero-search-input">
                            <InputGroup.Text id="basic-addon1"><i className="bi bi-search"></i></InputGroup.Text>
                            <Select
                            isSearchable
                            name="categories"
                            options={options}
                            className="partner-hero-search-input-text"
                            value={options.find((option) => selectedCategories?.includes(option.value))}
                            onChange={handleCategoryChange}
                            placeholder="Cosa cerchi?"
                            styles={customStyles}
                            />
                        </InputGroup>
                        <div className="partner-separator-input">|</div>
                        <InputGroup className="partner-hero-search-input">
                            <InputGroup.Text id="basic-addon2"><i className="bi bi-geo-alt"></i></InputGroup.Text>
                            <Select
                            isSearchable
                            name="locations"
                            options={[...cityWorkplaceArray, ...provinceWorkplaceArray]}
                            className="partner-hero-search-input-text"
                            value={[...cityWorkplaceArray, ...provinceWorkplaceArray].find((option) => option.value === selectedLocation)}
                            onChange={handleLocationChange}
                            placeholder="Dove?"
                            styles={customStyles}
                            />
                        </InputGroup>
                        <button onClick={viewList}>Cerca</button>
                        </div>
                </div>
            </div>

            <div className="partner-categories">
                <p className="fs-24 fw-bold mt-4" style={{ lineHeight:'1.2'}} >I servizi più amati</p>
                <p className="mb-4">Trova il servizio che fa per te e inizia a monitorare il suo operato.</p>
                <div className="partner-categories-carousel">
                    {
                        roles.map((el, index) => {
                            return  <CategoryCard category={el.category} img={el.img}/>
                        })
                    }
                </div>
            </div>

            <div className="partner-app d-md-flex align-items-md-center justify-content-md-between">
                <Col md={6} className="m2 d-flex justify-content-center align-items-center "> 
                    <img loading="lazy" src={mockup} className="w-50 img-fornitori-app"/>
                </Col>
                <Col md={6} className="m2 text-center">
                    <p className="fs-32 fw-bold mt-4 mb-2" style={{ lineHeight:'1.2'}}>Il bello è prenderci gusto</p>
                    <p className="mb-4">Scarica l'app di Real Meta e monitora da dove vuoi, qualunque cosa desideri.</p>
                    <div className="partner-app-btn d-flex justify-content-center align-items-center">
                        <img loading="lazy" src={btnGp} className="h-44 me-2"/>
                        <img loading="lazy" src={btnApple} className="h-44"/>
                    </div>
                </Col>
            </div>
        </div>
    )
}


function CategoryCard (props) {
    return (
        <div className="partner-category-card m-2">
            <img loading="lazy" src={props.img}/>
            <div className="py-2">
                <p className="fw-bold text-capitalize">{props.category}</p>
            </div>
        </div>
    )
}