import React, { useEffect, useState } from "react";
import User from "../../../assets/images/conduttori-ph.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ConductorCard from "../ConductorCard";
import ConductorModal from "../ConductorModal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { conductor_endpoint, conductor_guarantor_endpoint } from "../../../api/api";
import api from "../../../api/api"
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";
import ButtonRm from "../../ButtonRm";
import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";
import CustomBadge from "../../CustomBadge";


//import { conductor_endpoint } from "../../../api/api";
//import api from "../../../api/api"

const ConductorE = (props) => {
	
	const [show, setShow] = useState(false);
	const [tabState, setTabState] = useState(0);

	const [conductors, setConductors] = useState([]);
	const [subentriList, setSubentriList] = useState([])
	const [guarantors, setGuarantors] = useState([]);



	const [data, setData] = useState({
		conductors: props.formData.conductors.length > 0 ?  props.formData.conductors : []
	})

	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			setData(
				{
		conductors: props.formData.conductors.length > 0 ?  props.formData.conductors : [],
		subEntrance: subentriList
		}
			);
		}
	}, [props.saveState, props.editMode]);

	const [transfer_conductor, setTransferConductor] = useState([]);
	const [lessor, setLessor] = useState([]);
	const [show_conductor, setShowConductor] = useState(false);
	const [show_lessor, setShowLessor] = useState(false);

	const addConductor = () => {
		let temp = transfer_conductor;
		temp.push("");
		setTransferConductor(temp);
	};
	const addLessor = () => {
		let temp = lessor;
		temp.push("");
		setLessor(temp);
	};
	const table_data = [
		[
			"Mario",
			"Rossi",
			"Via Berli",
			12,
			"Bologna",
			"BO",
			"75646",
			"RSSMRO76CF5D929T",
		],
		[
			"Giulia",
			"Verdi",
			"Via Berli",
			13,
			"Bologna",
			"BO",
			"75646",
			"RSSMRO76CF5D929T",
		],
	];
	const [take_over, setTakeOver] = useState(false);
	const setToggleFullMode = () => {
		props.setFullMode(!props.full_mode);
	};
	const [other_card, setOtherCard] = useState([1]);

	const next = () => {
		const slidesContainer = document.getElementById("slides-container");
		const slide = document.querySelector(".slide");
		const slideWidth = slide.clientWidth;
		slidesContainer.scrollLeft += slideWidth;
	};

	const prev = () => {
		const slidesContainer = document.getElementById("slides-container");
		const slide = document.querySelector(".slide");
		const slideWidth = slide.clientWidth;
		slidesContainer.scrollLeft -= slideWidth;
	};

	const addSelectedConductor = async (id) => {
		console.log(data.conductors);
		let filteredList = data.conductors.filter(
			(conductor) => conductor.id === id
		);
		if (filteredList.length === 0) {
			data.conductors.push(
				conductors.filter((conductor) => conductor.id === id)[0]
			);
			setData({
				...data,
				conductors: data.conductors,
			});
		}
	};

	const removeSelectedConductor = async (id) => {
		setData({
			...data,
			conductors: data.conductors.filter(
				(conductor) => conductor.id !== id
			),
		});
	};

	const fetchConductors = async () => {
		try {
			const res = await api().get(`${conductor_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setConductors(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const fetchConductorList = async () => {
		await fetchConductors();
		//setShow(true);
	};

	/* HANDLE SUBENTRI TAB */
	const [addSubentro, setAddSubentro] = useState(false)

	const [newSub, setNewSub] = useState({
		date: null,
		cedente: {},
		subentro: {}
	})

	function createSubentro(){
		setSubentriList(prev => [...prev, newSub])
		const removePreviousConductor = data.conductors.filter(item => item.id != newSub.cedente.id)
		setData(prev => {
			return {
				...prev,
				conductors: [...removePreviousConductor, newSub.subentro]
			}
		})
		setNewSub({date: null,
			cedente: {},
			subentro: {}})
		setAddSubentro(false)
		
	}

	const handleSelectChange = (e) => {
		const { name, value} = e.target
		const selectedValue = name == "cedente" || name == "subentro" ? JSON.parse(value) : value
		setNewSub(prev => {
			return {
				...prev,
				[name]: selectedValue
			}
		});
	  };



	useEffect(() => {
		fetchConductorList()
	}, [])


	console.log(subentriList, data.conductors, props.formData)

	//quando ci sarà la voce per takeover nell'api, aggiungervi subentriList

		//LISTA GARANTI
		const fetchGuarantors = async () => {
			try {
				const res = await api().get(`${conductor_guarantor_endpoint}`);
				if (res.data) {
					console.log(res.data.content);
					setGuarantors(res.data.content);
				}
			} catch (err) {
				console.log(err);
			}
		};
	
		const fetchGuarantorsList = async () => {
			await fetchGuarantors();
			setShow(true);
		};


		const item = (props.formData.conductors.length > 0) ? [...props.formData.conductors] : []

	return (
		<Form>
			<div className="form-item-align-box-white d-block py-5">
					<div>
					{!props.edit_mode && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					
					<ExportButton data={item} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
					<div className={`tab-state`}>
						<div className="d-flex">
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(0);
								}}>
								<label className={tabState === 0 ? "" : "non-active"}>
									Conduttori attuali
								</label>
								<div
									className={`tab-bar ${
										tabState === 0 ? "active-tab-bar" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(1);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 1 ? "" : "non-active"}>
									Garanti
								</label>
								<div
									className={`tab-bar ${
										tabState === 1 ? "active-tab-bar" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(2);
								}}
								style={{ marginLeft: "-10px" }}>
								<label className={tabState === 2 ? "" : "non-active"}>
									Subentri
								</label>
								<div
									className={`tab-bar ${
										tabState === 2 ? "active-tab-bar" : ""
									}`}></div>
							</div>
						</div>
					</div>
					<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
						<div >
						
					{props.edit_mode &&	
					<div className="d-flex justify-content-start mt-5">
						<Link to="/conduttori/create">
							<button type="button" className="general-btn white-btn w-193 me-2">
								Nuovo
							</button>
						</Link>
						<button
							type="button"
							className="general-btn white-btn w-193"
							onClick={()=> {
								fetchConductorList()
								setShow(true);
								}}>
							Seleziona
						</button>
					</div>
					}
						</div>
						{data.conductors?.length > 0 ? (
					<div className="row mt-3">
						{data.conductors?.filter(el => !el.guarantor).map((conductor) => (
							<div className="col-md-6">
								<ConductorCard
									key={conductor.id}
									title={conductor.name + " " + conductor.surname}
									id={conductor.id}
									table_data={{
										name: conductor.name,
										surname: conductor.surname,
										residenceAddress: conductor?.residenceAddress,
										residenceAddress: conductor?.residenceHouseNumber,
										residenceMunicipality: conductor.residenceMunicipality,
										cellularTelephone: conductor.cellularTelephone,
										fiscalCode: conductor.fiscalCode,
										typeOfConductor: conductor.typeOfConductor,
									}}
									className="mt-4"
									remove={removeSelectedConductor}
									edit_mode={props.edit_mode}
								/>
							</div>
						))}
					</div>
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={User} alt="" />
						<span className="img-comment">Nessun conduttore selezionato</span>
					</div>
				)}
					</div>
					<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>
						<div >
						{props.edit_mode &&	
					<div className="d-flex justify-content-start mt-5">
						<Link to="/conduttori/create">
							<button type="button" className="general-btn white-btn w-193 me-2">
								Nuovo
							</button>
						</Link>
						<button
							type="button"
							className="general-btn white-btn w-193"
							onClick={()=> {
								fetchGuarantorsList()
								setShow(true);
								}}>
							Seleziona
						</button>
					</div>
					}
						</div>
						{data.conductors?.length > 0 ? (
					<div className="row mt-3">
						{data.conductors?.filter(el => el.guarantor).map((conductor) => (
							<div className="col-md-6">
								<ConductorCard
									key={conductor.id}
									title={conductor.name + " " + conductor.surname}
									id={conductor.id}
									table_data={{
										name: conductor.name,
										surname: conductor.surname,
										residenceAddress: conductor?.residenceAddress,
										residenceMunicipality: conductor.residenceMunicipality,
										cellularTelephone: conductor.cellularTelephone,
										fiscalCode: conductor.fiscalCode,
										typeOfConductor: conductor.typeOfConductor,
									}}
									className="mt-4"
									remove={removeSelectedConductor}
									edit_mode={props.edit_mode}
								/>
							</div>
						))}
					</div>
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={User} alt="" />
						<span className="img-comment">Nessun garante selezionato</span>
					</div>
				)}
					</div>


					{/* subentro */}
					<div className={`mt-3 ${tabState === 2 ? "" : "d-none"}`}>
						<div >
							{props.edit_mode &&	
								<div className="d-flex justify-content-start mt-5">
								<button onClick={(e) => {
									e.preventDefault()
									setAddSubentro(true)}} className="general-btn white-btn w-193 me-2">
									Nuovo
								</button>
								</div>
							}
						</div>
						{subentriList.length > 0 ?
						<div>

						<Table bordered className="border my-4">
							<thead style={{ backgroundColor: '#f2f2f2' }}>
								<tr>
								<th className="border">Data Cedente</th>
								<th className="border">Conduttore Cedente</th>
								<th className="border">Conduttore che subentra</th>
								 <th className="border"></th> 
								</tr>
							</thead>
							<tbody>
								{
									subentriList.map(item => {
										return <tr key={item.id}>
													<td className="border">{item.date}</td>
													<td className="border">{item.cedente.name} {item.cedente.surname}</td>
													<td className="border">{item.subentro.name} {item.subentro.surname}</td>
													<td className="border text-decoration-underline"
													style={{cursor:"pointer"}}
												//  onClick={() => {
												// 	{/* MANCANO GLI ID DI SUBENTRI LIST, AGGIUNGERLI PER POTER FAR FUNZIONARE LA RIMOZION */}
												// 	let newList = subentriList.filter(el => el.id != item.id )
												// 	setSubentriList(newList)
												// } 
												// }
												>Rimuovi </td> 
											</tr>
									})
								}
							</tbody>
							</Table>
						</div>:
						
						<div>
							<div className="unit-empty-img">
							<img loading="lazy" src={User} alt="" />
							<span className="img-comment">Nessun subentro</span>
							</div>
						</div>
						}

					</div>				
					</div>
				
			</div>
			<Modal
				show={show_conductor}
				className="type-modal"
				onHide={() => {
					setShowConductor(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShowConductor(false);
						}}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					{tabState == 0 &&<ConductorModal
						table_data={
							conductors.length > 0 &&
							conductors.filter(el => !el.guarantor).map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								conductor.fiscalCode,
							])
						}
						ids={conductors.map((conductor) => conductor.id)}
						setShow={setShow}
						addConductor={addSelectedConductor}
					/>}
					{tabState == 1 &&<ConductorModal
						table_data={
							guarantors.length > 0 &&
							guarantors.map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								conductor.fiscalCode,
							])
						}
						ids={guarantors.map((conductor) => conductor.id)}
						setShow={setShow}
						addConductor={addSelectedConductor}
					/>}
				</Modal.Body>
			</Modal>
			<Modal
				show={show_lessor}
				className="type-modal"
				onHide={() => {
					setShowLessor(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShowLessor(false);
						}}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<ConductorModal
						table_data={table_data}
						setShow={setShowLessor}
						edit_mode={props.edit_mode}
						addFunc={addLessor}
					/>
				</Modal.Body>
			</Modal>

			<Modal
				show={addSubentro}
				onHide={() => {
					setAddSubentro(false);
				}}
				centered
				>
				<Modal.Header closeButton>
					<h4>Crea subentro</h4>
				</Modal.Header>
				<Modal.Body className="p-3">
				<div className="">
					<div className="row flex-column">
						<div className="col-md-12 mb-3">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Data cessione
								</label>
								<input
									max="9999-12-31"
									type="date"
									name="date"
									onChange={handleSelectChange}
									value={newSub.date}
									></input>
							</div>
						</div>
						<div className="col-md-12 mb-3">
							<div className="d-flex flex-row align-items-end">
								<div className="d-flex flex-column flex-auto">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-1">
										Conduttore Cedente
									</label>
									<select
									name="cedente"
									onChange={handleSelectChange}
									value={JSON.stringify(newSub.cedente)}

									>
										{/* LISTA data.conductors */}
										<option value=""></option>
										{data.conductors?.length > 0 &&
										data.conductors.map(item => {
											return <option value={JSON.stringify(item)}>{item.surname} {item.name} - {item.municipality}</option>
										})
										}
									</select>
									
								</div>
				
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-row align-items-end">
								<div className="d-flex flex-column flex-auto">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-1">
										Conduttore che subentra
									</label>
									<select
									name="subentro"
									onChange={handleSelectChange}
									value={JSON.stringify(newSub.subentro)}
									>
										{/* LISTA data.conductors */}
										<option value=""></option>										{conductors?.length > 0 &&
										conductors.map(item => {
											return <option value={JSON.stringify(item)}>{item.surname} {item.name} - {item.municipality}</option>
										})
										}
									</select>
								</div>
								<Link to="/conduttori/create">
								<BsPlusCircleFill
									className="add-section-btn mb-1 ms-2 text-dark"
								/>
								</Link>
							</div>
						</div>
					</div>
						
					<div className="d-flex align-items-center mt-5 form-last-btns justify-content-center">
						<ButtonRm
							variant="tertiary"
							type="button"
							className="me-2"
							onClick={() => {
	
								setAddSubentro(false);
							}}>
							Annulla
						</ButtonRm>
						<ButtonRm

							variant="blue"
							type="button"
							onClick={createSubentro}>
							Salva
						</ButtonRm>
					</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					{tabState == 0 && <ConductorModal
						table_data={
							conductors.length > 0 &&
							conductors.filter(el => !el.guarantor).map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								conductor.fiscalCode,
							])
						}
						ids={conductors.map((conductor) => conductor.id)}
						setShow={setShow}
						addConductor={addSelectedConductor}
					/>}
					{tabState == 1 && <ConductorModal
						table_data={
							guarantors.length > 0 &&
							guarantors.map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								conductor.fiscalCode,
							])
						}
						ids={guarantors.map((conductor) => conductor.id)}
						setShow={setShow}
						addConductor={addSelectedConductor}
					/>}
				</Modal.Body>
			</Modal> 
		</Form>
	);
};

export default ConductorE;
