import "../../assets/css/Intermediazione/TrovaFornitori.css"
import { useState, useEffect, useContext } from "react";
import TopHeading from "../TopHeading"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Col } from "react-bootstrap";
//import { filterItems } from "./data";
import CustomBadge from "../CustomBadge";
import api, { partners_endpoint } from "../../api/api";

import pcUnit from '../../assets/images/placeholder-colorato.png'

import FilterFornitori from "./FilterFornitori";

import { usePartners } from "../../context/partners";
import { useNavigate } from "react-router-dom";
import options from "../../partnerList";


const roles = options.map((option, index) => ({
    id: index + 1,
    category: option.label,
    img:"https://images.pexels.com/photos/6474205/pexels-photo-6474205.jpeg?auto=compress&cs=tinysrgb&w=1600"
}));



/* const roles = [
    {   
        id:'20',
        category:"idraulico",
        img:"https://images.pexels.com/photos/6474205/pexels-photo-6474205.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {   id:'20',
        category:"elettricista",
        img:"https://images.pexels.com/photos/5691588/pexels-photo-5691588.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        id:'21',
        category:"imbianchino",
        img:"https://images.pexels.com/photos/5691479/pexels-photo-5691479.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        id:'22',
        category:"giardiniere",
        img:"https://images.pexels.com/photos/5561310/pexels-photo-5561310.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        id:'23',
        category:"interior designer",
        img:"https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        id:'24',
        category:"architetto",
        img:"https://images.pexels.com/photos/4491459/pexels-photo-4491459.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        id:'25',
        category:"imprese di pulizia",
        img:"https://images.pexels.com/photos/4239031/pexels-photo-4239031.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
    {
        id:'26',
        category:"notaio",
        img:"https://images.pexels.com/photos/7641842/pexels-photo-7641842.jpeg?auto=compress&cs=tinysrgb&w=1600"
    },
] */



export default function PartnerList(){

    const {
        filteredPartners,
        filterPartners,
        setSelectedCategories,
        selectedCategories,
        selectedLocation,
        setSelectedLocation,
        cityWorkplaceArray,
        provinceWorkplaceArray,
        sortOrder,
        setSortOrder,
        setSearchQuery,
        searchQuery
        //getPartners 
    } = usePartners();
    const navigate = useNavigate();

    console.log(filteredPartners, selectedCategories)

    //const [selectedCategories, setSetSelectedCategories] = useState([]);
    const maxFiltersToShow = 5; // Numero massimo di filtri da mostrare prima di utilizzare il pulsante "Mostra tutti"


    const handleFilter = (id, category) => {
        const filterIndex = selectedCategories.indexOf(id);
        
        if (filterIndex === -1) {
            setSelectedCategories([...selectedCategories, category]);
        } else {
            const updatedCategories = selectedCategories.filter((el) => category !== el);
            setSelectedCategories(updatedCategories);
        }
        // Rifiltra la lista dei partners dopo aver aggiornato le categorie selezionate
        filterPartners();
    };




    useEffect(() => {
        // Rifiltra la lista dei partners
        filterPartners();
    }, [selectedCategories, sortOrder, selectedLocation, searchQuery]);

    const [partners, setPartners] = useState([]);

    const getPartners = async () => {
    try {
        const res = await api().get(`${partners_endpoint}`);
        if (res.data) {
        setPartners(res.data.content);
        }
    } catch (err) {
        console.log(err);
    }
    };

    useEffect(() => {
        getPartners()
    }, [])

    //go back
    function goBack (e) {
        e.preventDefault()
        navigate("/statoavanzamentolavori/trova-fornitore")
    }
    
    //ottieni filtri
    
    const filterItems = options.map((option, index) => ({
        id: index + 1,
        name: option.label,
    }));
    
    console.log(filteredPartners, "filteredPartners")

    let array = []

    const servicesArray = filteredPartners.map(el => {
        return el.services.forEach((service) => {
            array.push(service.name)
        })
    })

    const filterItems2 = array.length>0 && array.map((option, index) => ({
        id: index + 1,
        name: option,
    }));

    console.log(filterItems2, "listaa")



    return(
        <div>
            <div className="title-search-group">
                <div className="right-header">
                <TopHeading SmallHeading="" back_modal={false}/>
                </div>
            </div> 

            <h2 className="fw-bold fs-24 mb-2">Trova il tuo professionista</h2>
            <div className="mb-2">
                <div className="partner-categories-carousel">
                    {
                        roles.map((el, index) => {
                            return <CarouselCardPartner img={el.img} category={el.category} id={el.id} handleFilter={handleFilter}  checked={selectedCategories.includes(el.category)}/>
                        })
                    }
                </div>
            </div>

            <div className="my-3 d-md-flex align-items-md-center">
                <Col md={3} className="mb-2">
                    <div className="me-md-3 fornitori-location">
                        <div className="d-flex align-items-center">
                            <i class="bi bi-geo-alt-fill me-2"></i>
                            <p className="fs-14">{selectedLocation? selectedLocation : ""}</p>
                        </div>
                        <small className="text-decoration-underline" style={{cursor: "pointer"}} onClick={goBack}>Cambia zona</small>
                    </div>
                </Col>
                <Col md={9}>
                    <div className="partner-hero-double-input" style={{ background:"white" }}>
                    <InputGroup className="partner-hero-search-input">
                    <InputGroup.Text id="basic-addon1"><i className="bi bi-search"></i></InputGroup.Text>
                    <Form.Control
                        placeholder="Cosa cerchi?"
                        aria-label="Cosa cerchi"
                        aria-describedby="basic-addon1"
                        className="partner-hero-search-input-text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </InputGroup>
                    </div>
                </Col>
            </div>

            <div className="mb-3 d-md-flex">
                <Col md={3} className="d-none d-md-block">
                <div className="pe-md-3 mb-3">
                    <FilterFornitori 
                        filterItems={filterItems} 
                        title='Filtri' 
                        selectedCategories={selectedCategories} 
                        setSelectedCategories={setSelectedCategories} 
                        maxFiltersToShow={maxFiltersToShow}
                    />
                    <FilterFornitori 
                        filterItems={filterItems} 
                        title='Servizi' 
                        selectedCategories={selectedCategories} 
                        setSelectedCategories={setSelectedCategories} 
                        maxFiltersToShow={maxFiltersToShow}
                    />
                    {/*  <FilterFornitori 
                            filterItems={filterItems2} 
                            title='Servizi' 
                            selectedCategories={selectedCategories} 
                            setSelectedCategories={setSelectedCategories} 
                            maxFiltersToShow={maxFiltersToShow}
                    /> */}
                </div>
              {/*   <div className="pe-md-3">
                    <FilterFornitori 
                        filterItems={filterItems} 
                        title='Filtri' 
                        selectedCategories={selectedCategories} 
                        setSetSelectedCategories={setSetSelectedCategories} 
                        maxFiltersToShow={maxFiltersToShow}
                    />
                    </div> 
                */}
                </Col>
                <Col md={9}>
                    <div className="mb-2 d-flex justify-content-between">
                        <p><i class="bi bi-info-circle me-2"></i>{filteredPartners ? filteredPartners.length : 0} fornitori trovati</p>
                        <div className="d-flex">
                            <div className="user-dropdown me-2 d-block d-md-none">
                                <div>
                                    <p><i class="bi bi-funnel me-3"></i>Filtra</p>
                                </div>
                                <div className="user-dropdown-content">
                                    <FilterFornitori 
                                        filterItems={filterItems} 
                                        title='Filtra' 
                                        selectedCategories={selectedCategories} 
                                        setSelectedCategories={setSelectedCategories} 
                                        maxFiltersToShow={maxFiltersToShow}
                                    />
                                </div>
                            </div>
                            <div className="user-dropdown">
            <div>
                <p><i className="bi bi-filter me-2"></i>Ordina</p>
            </div>
            <div className="user-dropdown-content">
                <div className="fs-16 fw-bold">Ordina </div>
                <div className="fs-14 sort-text" onClick={() => setSortOrder('Z-A')}>
                    Z-A
                </div>
                <div className="fs-14 sort-text" onClick={() => setSortOrder('A-Z')}>
                    A-Z
                </div>
                <div className="fs-14 mb-1 sort-text" onClick={() => setSortOrder('Più costosi')}>
                    Più costosi
                </div>
                <div className="fs-14 mb-1 sort-text" onClick={() => setSortOrder('Meno costosi')}>
                    Meno costosi
                </div>
            </div>
        </div>
                        </div>
                    
                    </div>
                    <div className="partner-list-container">
                        {
                            filteredPartners.length > 0 &&
                            filteredPartners.map(item => {
                                return <CarouselListPartner id={item.id} title={item.title} city={item.cityWorkplace} typologies={item.typologies} pay={item.pay}/>

                            })
                        }
                    </div>
                </Col>
            </div>

        </div>
    )
}


function CarouselCardPartner(props){
    const [isHovered, setIsHovered] = useState(false);
    return(
        <div className={`carousel-card-partner m-2`} 
        onClick={() => props.handleFilter(props.id, props.category)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <img loading="lazy" src={props.img}/>
            <div className="d-flex">
                <i
                className={`bi bi-check-lg ${
                    (props.checked || isHovered) ? 'filterCardFornitoreIconActive' : 'filterCardFornitoreIcon'
                }`}
                ></i>
                <p className="filterCardFornitoreText">{props.category}</p>
            </div>
        </div>
    )
}

function CarouselListPartner(props){

    const navigate = useNavigate();

    function showDetails(e){
        e.preventDefault()
        navigate(`/statoavanzamentolavori/trova-fornitore/lista/${props.id}`)
    }



    return(
        <div 
        className={`mb-4 d-sm-flex align-items-start align-items-sm-center`} onClick={showDetails} style={{cursor: "pointer"}}>
            
                <Col sm={4} className="bg-custom-fornitore mb-2 mb-sm-0 me-sm-3" style={{ backgroundImage: `url(${pcUnit})` }}>
                    <img loading="lazy" src="https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=1600" className="logo-fornitori" />
                </Col>

                <Col sm={8}>
                    <div className="d-flex align-items-center">
                        <p className="fw-bold fs-18 me-2">{props.title ? props.title : "Azienda"}</p>
                        <i class="bi bi-envelope"></i>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Col sm={6}>
                            <div >
                                {
                                    props.typologies?.length > 0 && props.typologies.map(el => {
                                        return <CustomBadge variant="custom-badge-pagina" classes="mx-0 me-0 ms-0 mb-1" >{el}</CustomBadge>
                                    })
                                }
                                <div className="fs-14 mb-2"><i class="bi bi-star me-2"></i> 5 stelle (10 recensioni)</div>
                                <div className="fs-14"><i class="bi bi-heart me-2"></i>100 like</div>
                            </div>
                        </Col>
            
                        <Col sm={5}>
                            <div className="fs-14 mb-2"><i class="bi bi-geo-alt me-2 "></i>{props.city? props.city : "città"}</div>
                            <p className="fs-14 mb-2"><i class="bi bi-cash-coin me-2"></i> Costo orario: € {props.pay? props.pay : 0}</p>
                            <p className="fs-14"><i class="bi bi-exclamation-octagon me-2"></i>Promozioni</p>
                        </Col>
                    </div>

                </Col>


        </div>
    )
}