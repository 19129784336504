import React, { useState, useEffect } from "react";
import "../../../assets/css/Tabs.css";
import TopHeading from "../../TopHeading";
import { useNavigate } from "react-router-dom";
import Registry from "./Registry";
import Account from "./Account";
import Note from "./Note";
import Provider from "./Provider";
import Insurance from "./Insurance";
import Attachment from "./Attachment";
import api, {
  stabili_endpoint,
  insurance_endpoint,
  supplier_condominium_assignment_endpoint,
  insurance_condo_assignment_endpoint
} from "../../../api/api";
import condominoImg from "../../../assets/images/condomino-ph.svg"
import FolderImg from "../../../assets/images/allegati-ph.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateStabili = () => {
	const [formData, setFormData] = useState(() => {
		const initialValue = JSON.parse(localStorage.getItem("stabili"));
		return {
			name: initialValue?.name || "",
			fiscalCode: initialValue?.fiscalCode || "",
			administrator: initialValue?.administrator || "",
			address: initialValue?.address || "",
			municipality: initialValue?.municipality || "",
			houseNumber: initialValue?.houseNumber || "",
			province: initialValue?.province || "",
			cap: initialValue?.cap || null,
			paymentCausal: initialValue?.paymentCausal || "",
			accountantCode: initialValue?.accountantCode || "",
			currentAccounts: initialValue?.currentAccounts || [],
			bankAccounts: initialValue?.bankAccounts || null,
			notes: initialValue?.notes || "",
			suppliers: initialValue?.suppliers || [],
			insurances: initialValue?.insurances || [],
			attachment: initialValue?.attachment || [],
			units: initialValue?.units || [],
			structures: initialValue?.structures || [],
		};
	});

	useEffect(() => {
		localStorage.setItem("stabili", JSON.stringify(formData));
	}, [formData]);

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [count, setCount] = useState(() => {
		const initialCount = JSON.parse(localStorage.getItem("stabiliState"));
		return initialCount ? initialCount : 0;
	});
	useEffect(() => {
		localStorage.setItem("stabiliState", JSON.stringify(count));
	}, [count]);

	const navigate = useNavigate();

	const forward = () => {
		var num = count;
		setCount(++num);
	};

	const backward = () => {
		var num = count;
		if (num > 0) {
			setCount(--num);
		}
	};

	const previousPage = () => {
		navigate(-1);
	};

	const setStep = (index) => {
		setCount(index);
	};

	const save = async () => {

		
		if(formData.name && 
		formData.address &&
		formData.houseNumber &&
		formData.municipality &&
		formData.province){
			console.log("create")
			try {

				//gestione allegati
				let formDataToSend = new FormData();
				console.log(formData.attachment);
	
				formData.attachment.forEach((file) => {
					const blob = new Blob([file]);
					formDataToSend.append("parts", blob, file.name);
				});

				let sentUpload = formDataToSend.get("parts") !== null;


				delete formData.attachment;

				//RIMUOVI ELEMENTI DA ASSOCIARE
				const suppliers = [...formData.suppliers];
				const insurances = [...formData.insurances];



				delete formData.suppliers
				delete formData.insurances
				//STRINIGY CIO CHE C'E' DA STIRNIGFY
				const currentAccountsStringArray = formData.currentAccounts.map((account) => JSON.stringify(account));
				
				let response
				response = await api().post(`${stabili_endpoint}`, {...formData, currentAccounts: currentAccountsStringArray  });

				//INVIA ALLEGATI
				if (response) {
					if (sentUpload) {
					await api().put(`${stabili_endpoint}upload/${response.data.id}`, formDataToSend);
					}
				}
				

				//ASSOCIAZIONE FORNITORI
					if (suppliers.length > 0 && response.data.id) {
						for (const supplier of suppliers) {
						await api().post(`${supplier_condominium_assignment_endpoint}`, {
							supplierID: supplier.id,
							condominiumID: response.data.id,
						});
					}
				}

				//ASSOCIAZ ASSICURAZIONI APPENA CREATE
				// DOPO CREAZIONE COND

				if (response.data.id && insurances.length > 0) {
					for (const insuranceData of insurances) {
						//stringify agency first
						const {attachment, ...restData} = insuranceData
						//GESTIONE ALLEGATI
						let formDataToSend = new FormData();
					
						attachment.forEach((file) => {
							const blob = new Blob([file]);
							formDataToSend.append("parts", blob, file.name);
						});

						const agencyString = JSON.stringify(insuranceData.agency);

						const insuranceDataStringified = {
						...restData,
						agency: agencyString,
						condominiumID: response.data.id
						};

						let sentUpload = formDataToSend.get("parts") !== null;

						let create = await api().post(`${insurance_endpoint}`, insuranceDataStringified);

						if (create && sentUpload) {
							await api().put(`${insurance_endpoint}upload/${create.data.id}`, formDataToSend);
						}

						await api().post(`${insurance_condo_assignment_endpoint}`, {
							insuranceID: create.data.id,
							condominiumID: response.data.id,
							})


					}
				}


			} catch (error) {
				console.log(error);
			}
			localStorage.removeItem("stabili");
			localStorage.removeItem("stabiliState");
			navigate(-1);
		} else {
			console.log("error")

			toast.error("Dati mancanti nello step Anagrafica", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
		}
		
	};

	console.log("data", formData)

	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="Nuovo Stabile" />
				</div>
			</div>
			<ToastContainer />
			<div className="step-show">
				<div className="tabs-div">
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(0);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "127px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 5C2 2.79086 3.79086 1 6 1H106.679C108.114 1 109.44 1.76914 110.152 3.01544L123.866 27.0154C124.569 28.2452 124.569 29.7548 123.866 30.9846L110.152 54.9846C109.44 56.2309 108.114 57 106.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
									fill={
										count >= 0 ? (count >= 1 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 0 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title1"
								style={{
									color:
										count >= 0
											? count >= 1
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Anagrafica
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 0 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 1 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 0 ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Anagrafica
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(1);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "145px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M130.152 3.01544C129.439 1.76914 128.114 1 126.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H126.679C128.114 57 129.439 56.2309 130.152 54.9846L143.866 30.9846C144.569 29.7548 144.569 28.2452 143.866 27.0154L130.152 3.01544Z"
									fill={
										count >= 1 ? (count >= 2 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 1 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 1
											? count >= 2
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Conti Correnti
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 1 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 2 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 1 ? "" : "none",
									position: "absolute",
									left: "-20px",
								}}>
								Conti Correnti
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(2);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "114px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M97.1516 3.01544C96.4394 1.76914 95.114 1 93.6786 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H93.6786C95.114 57 96.4394 56.2309 97.1516 54.9846L110.866 30.9846C111.569 29.7548 111.569 28.2452 110.866 27.0154L97.1516 3.01544Z"
									fill={
										count >= 2 ? (count >= 3 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 2 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 2
											? count >= 3
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Lavori
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 2 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 3 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 2 ? "" : "none",
									position: "absolute",
									left: "7px",
								}}>
								Lavori
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(3);
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M103.152 3.01544C102.439 1.76914 101.114 1 99.6786 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89263 57H99.6786C101.114 57 102.439 56.2309 103.152 54.9846L116.866 30.9846C117.569 29.7548 117.569 28.2452 116.866 27.0154L103.152 3.01544Z"
									fill={
										count >= 3 ? (count >= 4 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 3 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 3
											? count >= 4
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Fornitori
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 3 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 4 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 3 ? "" : "none",
									position: "absolute",
									left: "-2px",
								}}>
								Fornitori
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(4);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "145px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M130.152 3.01544C129.439 1.76914 128.114 1 126.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H126.679C128.114 57 129.439 56.2309 130.152 54.9846L143.866 30.9846C144.569 29.7548 144.569 28.2452 143.866 27.0154L130.152 3.01544Z"
									fill={
										count >= 4 ? (count >= 5 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 4 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 4
											? count >= 5
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Assicurazioni
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 4 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 5 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 4 ? "" : "none",
									position: "absolute",
									left: "-16px",
								}}>
								Assicurazioni
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(5);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "145px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M130.152 3.01544C129.439 1.76914 128.114 1 126.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H126.679C128.114 57 129.439 56.2309 130.152 54.9846L143.866 30.9846C144.569 29.7548 144.569 28.2452 143.866 27.0154L130.152 3.01544Z"
									fill={
										count >= 5 ? (count >= 6 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 5 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 5
											? count >= 6
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Condomini
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 5 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 6 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 5 ? "" : "none",
									position: "absolute",
									left: "-16px",
								}}>
								Condomini
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(6);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "139px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H133C135.209 1 137 2.79086 137 5V53C137 55.2091 135.209 57 133 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
									fill={
										count >= 6 ? (count >= 7 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 6 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 6
											? count >= 7
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Allegati
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 6 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 7 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 6 ? "" : "none",
									position: "absolute",
									left: "-5px",
								}}>
								Allegati
							</div>
						</div>
					</div>
				</div>
				<div className="content-container">
					{count === 0 && <Registry formData={formData} onChange={onChange} forward={forward}/>}
					{count === 1 && (
						<Account accounts={formData.currentAccounts} onChange={onChange} />
					)}
					{count === 2 && <Note note={formData.notes} onChange={onChange}
					alert="Puoi aggiungere una nuova attività dalla sezione Stato avanzamento lavori (SAL) dopo la creazione dello stabile"
					img={FolderImg}
					placeholder="Nessun lavoro"
					/>}
					{count === 3 && (
						<Provider formData={formData} setFormData={setFormData} />
					)}
					{count === 4 && (
						<Insurance formData={formData} onChange={onChange} setFormData={setFormData}/>
					)}
					{count === 5 && <Note note={formData.notes} 
					onChange={onChange}
					placeholder="Nessun condomino"
					alert="La lista dei condomini e la ripartizione in millesimi viene creata automaticamente quando associ una o più unità allo stabile di riferimento
					(sezione unità - step condominio)"
					img={condominoImg}
					/>}
					{count === 6 && (
						<Attachment attachment={formData.attachment} onChange={onChange} />
					)}
					<div className="d-flex justify-content-end mt-5">
						{count > 0 &&
							<button
								type="button"
								className="general-btn white-btn me-2 w-193"
								onClick={() => backward()}>
								Indietro
							</button>
						}
						{count === 6 && 
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={save}>
								Salva
							</button>
						}
							
						{ count > 0 && count < 6 &&
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={() => forward()}>
								Avanti
							</button>
						}
		
						
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateStabili;
