import { useState, useEffect } from "react";
import { BsPrinter, BsPencil, BsDownload, BsTrash } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import ButtonRm from "../../ButtonRm";
import { Link } from "react-router-dom";

import axios from "axios";
import { BsPlusCircleFill } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import api, { supplier_endpoint, owner_endpoint, BASE_URL  } from "../../../api/api";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import delete_icon from "../../../assets/images/delete.svg";
import ImgFileCard from "./ImgFileCard";

import CustomToggle from "../../CustomToggle";
import mimeTypes from "../../../allegatiType";

import CustomBadge from "../../CustomBadge";


const actType = [
    "Successione",
    "Compravendita",
    "Divisione",
    "Donazione",
    "Riunione usufrutto e successione",
];

const ActModal = (props) => {
	const [attoForm, setAttoForm] = useState({});
	const [editMode, setEditMode] = useState(false);

    const [supplier, setSupplier] = useState([]);
	const [office, setOffice] = useState([]);
	const [owners, setOwners] = useState([]);

	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setOwners(response.data.content);
				console.log("owner", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getOffice() {
		try {
			const response = await axios.get(
				`https://anthill-service.onrender.com/getOfficeList`
			);
			if (response.data) {
				setOffice(response.data.data);
				console.log("office", response.data.data);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getSupplier() {
		try {
			const response = await api().get(`${supplier_endpoint}`);
			if (response.data) {
				setSupplier(response.data.content);
				console.log("supplier", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOffice();
		getSupplier();
		getOwners();
	}, []);

    //HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');


	const init = () => {
		if (props.atto !== undefined) {
			setAttoForm(props.atto);
		} else {
			setAttoForm({
                type:"",
                actDate:"",
                fiscalEffectDate:"",
                notary:"",
                collectionNumber:"",
                repertoireNumber:"",
                registrationOffice:"",
                registrationDate:"",
                registrationSerial:"",
                registrationNumber:"",
                transcriptionDate:"",
                transcriptionLocation:"",
                transcriptionNumber:"",
                actValue:"",
                historicalValue:"",
                revaluedValue:"",
                transferor: "",
                transferee: "",
                note:"",
                attachment: []
			});
		}
	};


	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		const {name, value} = e.target
		setAttoForm({ ...attoForm, [name]: value });
	};

	const deleteFile = (index) => {
		let temp = [...attoForm.attachment];
		temp.splice(index, 1);
		setAttoForm({ ...attoForm, ["attachment"]: temp });
	};

	const handleChange = (e) => {
		let temp = [...attoForm.attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		setAttoForm({  ...attoForm, ["attachment"]: temp });
	}

	const downloadFile = async (name) => {
		const fileUrl = `${BASE_URL}api/v1/act/download/${props.atto.id}/${name}`;
	
		const response = await axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${
			JSON.parse(localStorage.getItem("user"))?.token
			}`,
			"Content-Type": `application/json`,
			},
		});
	
		// ottieni l'estensione del file
		const fileExtension = name.split('.').pop();
	
		const mimeType = mimeTypes[fileExtension];
	
		// crea un nuovo Blob con il tipo MIME corretto
		const file = new Blob([response.data], { type: mimeType });
	
		// crea un URL per il Blob
		const fileURL = URL.createObjectURL(file);
	
		// scarica il file
		window.open(fileURL);
	};

	/* const save = () => {
		if (props.newMode) props.add(readCounter);
		else props.update(readCounter);
	};

	const setFileName = (index, new_name) => {
		let temp = [...readCounter.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setReadCounter({ ...readCounter, ["attachment"]: temp });
	};

	const deleteFile = (index) => {
		let temp = [...readCounter.attachment];
		temp.splice(index, 1);
		setReadCounter({ ...readCounter, ["attachment"]: temp });
	};
	const handleChange = (e) => {
		let temp = [...readCounter.attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		setReadCounter({ ...readCounter, ["attachment"]: temp });
	}; */
	const handleDelete = () => {
		props.delete();
	};

	console.log(attoForm.attachment)
	return (
		<div
			className={
				props.editMode === undefined ? "mt-4" : "edit-content-container"
			}
			style={{maxHeight: "500px", overflowY: "auto", borderRadius:".5rem", overflowX:"hidden"}} 
			>
			{props.newMode ? (
				<div className="fs-18 fw-semibold mb-3">Nuovo atto</div>
			) : (
				<div className="d-flex justify-content-between">
					<div className="fs-18 fw-semibold">
						{editMode ? "Modifica atto" : "Visualizza atto"}
					</div>

					<div className={`d-flex ${editMode ? "d-none" : ""}`}>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsDownload color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
							onClick={() => {
								setEditMode(true);
							}}>
							<BsPencil color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40"
							onClick={handleDelete}>
							<BsTrash color="gray" />
						</button>
					</div>
				</div>
			)}
			<div className="d-block">
				<fieldset disabled={editMode || props.newMode ? false : true}>

                    <div>

                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Dati</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="row gy-3">
                                <div className="col-md-12">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                            Tipo
                                        </label>
                                        <select                                           
                                            name="type"
                                            value={attoForm.type}
                                            onChange={onChange}
                                            >
                                            {actType.map((menu, index) => {
                                                return (
                                                    <option key={index} value={menu}>
                                                        {menu}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
											<div className="col-md-12">
												<div className="d-flex flex-column">
													<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
														Data dell’atto
													</label>
													<input
														name="actDate"
                                                        value={attoForm.actDate}
                                                        onChange={onChange}
														max="9999-12-31"
														type="date"></input>
												</div>
											</div>
					</div>
					<div className="row gy-3 mt-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Data di effetto fiscale
								</label>
								<input
									name="fiscalEffectDate"
									value={attoForm.fiscalEffectDate}
									onChange={onChange}
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Notaio
								</label>
								<div className="d-flex align-items-center justify-content-between">
									<div className="flex-auto">
										<select
											
											name="notary"
											value={attoForm.notary}
											onChange={onChange}
										>
											{supplier?.map((menu, index) => {
												return (
													<option key={index} value={menu.name + " " + menu.surname}>
														{menu.name + " " + menu.surname}
													</option>
												);
											})}
										</select>
									</div>
									<Link to="/fornitori/create">
										<div style={{ padding: "3px" }}>
											<BsPlusCircleFill
												className="add-section-btn text-dark"
												// onClick={addParcel}
											/>
										</div>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="row gy-3 mt-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Raccolta n°
								</label>
								<input
									type="text"
									name="collectionNumber"
									value={attoForm.collectionNumber}
									onChange={onChange}
									// name="consistence"
									// value={cadastre.consistence}
									// onChange={onChangeCadastre}
									
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Repertorio n°
								</label>
								<input
									// name="xxxx"
									// value={cadastre.urbanSection}
									// onChange={onChangeCadastre}
									type="text"
									name="repertoireNumber"
									value={attoForm.repertoireNumber}
									onChange={onChange}
									
								/>
							</div>
						</div>
					</div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="1" activeKey={activeKey}>Registrazione</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="row gy-3">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Ufficio del registro
								</label>
								<select
									
									name="registrationOffice"
									value={attoForm.registrationOffice}
									onChange={onChange}
								>
									{office?.map((item, index) => {
										return (
											<option key={index} value={item.code}>
												{item.office + " - " + item.code  }
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Data
								</label>
								<input
									name="registrationDate"
									value={attoForm.registrationDate}
									onChange={onChange}
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
					</div>
					<div className="row gy-3 mt-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Serie
								</label>
								<input
									type="text"
									name="registrationSerial"
									value={attoForm.registrationSerial}
									onChange={onChange}
									
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Numero
								</label>
								<input
									// name="xxxx"
									// value={cadastre.urbanSection}
									// onChange={onChangeCadastre}
									type="text"
									name="registrationNumber"
									value={attoForm.registrationNumber}
									onChange={onChange}
									
								/>
							</div>
						</div>
					</div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Trascrizione</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="row gy-3 ">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Luogo
								</label>
								<input
									type="text"
									name="transcriptionLocation"
									value={attoForm.transcriptionLocation	}
									onChange={onChange}
									
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Data
								</label>
								<input
									name="transcriptionDate"
									value={attoForm.transcriptionDate}
									onChange={onChange}
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
					</div>
					<div className="row gy-3 mt-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Numero
								</label>
								<input
									type="text"
									name="transcriptionNumber"
									value={attoForm.transcriptionNumber}
									onChange={onChange}
									// name="consistence"
									// value={cadastre.consistence}
									// onChange={onChangeCadastre}
									
								/>
							</div>
						</div>
					</div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="3" activeKey={activeKey}>Importi</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="row gy-3">
											<div className="col-md-12">
												<div className="d-flex flex-column">
													<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
														Valore dell’atto
													</label>
													<input
														type="number"
                                                        name="actValue"
                                                        value={attoForm.actValu}
                                                        onChange={onChange}
														// name="consistence"
														// value={cadastre.consistence}
														// onChange={onChangeCadastre}
														
													/>
												</div>
											</div>
											<div className="col-md-12">
												<div className="d-flex flex-column">
													<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
														Valore storico
													</label>
													<input
														type="number"
                                                        name="historicalValue"
                                                        value={attoForm.historicalValue}
                                                        onChange={onChange}
														// name="consistence"
														// value={cadastre.consistence}
														// onChange={onChangeCadastre}
														
													/>
												</div>
											</div>
										</div>
										<div className="row gy-3 mt-2">
											<div className="col-md-12">
												<div className="d-flex flex-column">
													<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
														Valore rivalutato
													</label>
													<input
														type="number"
                                                        name="revaluedValue"
                                                        value={attoForm.revaluedValue}
                                                        onChange={onChange}
														// name="consistence"
														// value={cadastre.consistence}
														// onChange={onChangeCadastre}
														
													/>
												</div>
											</div>
										</div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="4" activeKey={activeKey}>Proprietario cedente</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="d-flex flex-column">
                                <Link to="/proprietari/create">
                                    <ButtonRm variant="tertiary">Nuovo</ButtonRm>
                                </Link>
                                <div
                                    >
                                    <div className="col-md-12 mt-2">
                                    <label>Seleziona</label>
									<select																
										name="transferor"
										value={attoForm.transferor}
										onChange={onChange}
									>
										{owners.length>0 && owners?.map((menu, index) => {
											return (
												<option key={index} value={menu.name + " " + menu.surname}>
													{menu.name + " " + menu.surname}
												</option>
											);
										})}
									</select>
                                    </div>
                                </div>
                            </div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="5" activeKey={activeKey}>Proprietario cessionario</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="d-flex flex-column">
                                <div
                                    >
                                    <div className="col-md-12 ">
                                    <label>Seleziona</label>
                                    <select																
										name="transferee"
										value={attoForm.transferee}
										onChange={onChange}
									>
										{props.formData?.owners?.length >0 && props.formData?.owners?.map((menu, index) => {
											return (
												<option key={index} value={menu.name + " " + menu.surname}>
													{menu.name + " " + menu.surname}
												</option>
											);
										})}
									</select>
                                    </div>
                                </div>
                            </div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="6" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="6" activeKey={activeKey}>Note</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-2">
                                    Inserisci nuova nota
                                </label>
                                <textarea
                                    // name="unit"
                                    // value={props.formData.unit}
                                    // onChange={(e) => {
                                    //   props.onChange(e);
                                    // }}
                                    id=""
                                    cols="30"
                                    rows="6"
                                    name="note"
                                    value={attoForm.note}
                                    onChange={onChange}
                                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 mb-3"></textarea>
                            </div>
							
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="7" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="7" activeKey={activeKey}>Allegati</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					{(editMode || props.newMode) && <label className="black-link mb-3" htmlFor="input-file">
						<BsPlusCircleFill className="plus-icon" />
						Aggiungi allegati
					</label>}
					<input
						type="file"
						id="input-file"
						className="d-none"
						multiple={true}
						name="attachment"
						onChange={handleChange}></input>
						{attoForm.attachment?.length > 0 &&
							attoForm.attachment.map((item, index) => {
								return (
									<FileCard
										key={index}
										name={item}
										setFileName={() => {}}
										deleteFile={deleteFile}
										id={index}
										download={downloadFile}
									/>
								);
						})}
						
                        
					</Accordion.Body>
				</Accordion.Item>



			</Accordion>
                    
                    </div>
                    
				</fieldset>
			</div>
			{editMode || props.newMode ? (
				
					<div className="d-flex align-items-center mt-5 form-last-btns justify-content-center mb-4">
					<ButtonRm variant="tertiary" onClick={() => {
								props.setShow(false);
							}} className="me-2" >Annulla</ButtonRm>
					<ButtonRm variant={"primary"} onClick={()=>props.add(attoForm)}>Salva</ButtonRm>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default ActModal;
