import { useState, useEffect } from "react";
import { BsPrinter, BsPencil, BsDownload, BsTrash } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import ButtonRm from "../../ButtonRm";
import { Link } from "react-router-dom";
import CustomBadge from "../../CustomBadge";



const ReadCounterModal = (props) => {
	const [readCounter, setReadCounter] = useState({});
	const [editMode, setEditMode] = useState(false);

	console.log(readCounter)

	const init = () => {
		if (props.readCounter !== undefined) {
			setReadCounter(props.readCounter);
		} else {
			setReadCounter({
				type:"",
                name:"",
                date:"",
                value:""
			});
		}
	};

	const counterType = [
		"Acqua",
        "Riscaldamento",
        "Elettricità",
        "Gas"
	];

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		const {name, value} = e.target
		setReadCounter({ ...readCounter, [name]: value });
	};

	const save = () => {
		if (props.newMode) props.add(readCounter);
		else props.update(readCounter);
	};

	const setFileName = (index, new_name) => {
		let temp = [...readCounter.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setReadCounter({ ...readCounter, ["attachment"]: temp });
	};

	const deleteFile = (index) => {
		let temp = [...readCounter.attachment];
		temp.splice(index, 1);
		setReadCounter({ ...readCounter, ["attachment"]: temp });
	};
	const handleChange = (e) => {
		let temp = [...readCounter.attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		setReadCounter({ ...readCounter, ["attachment"]: temp });
	};
	const handleDelete = () => {
		props.delete();
	};
	return (
		<div
			className={
				props.editMode === undefined ? "mt-4" : "edit-content-container"
			}
			/* style={{maxHeight: "400px", overflowY: "auto", borderRadius:".5rem", overflowX:"hidden"}} */
			>
			{props.newMode ? (
				<div className="fs-18 fw-semibold mb-3">Nuova lettura</div>
			) : (
				<div className="d-flex justify-content-between">
					<div className="fs-18 fw-semibold">
						{editMode ? "Modifica lettura" : "Visualizza lettura"}
					</div>

					<div className={`d-flex ${editMode ? "d-none" : ""}`}>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsDownload color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
							onClick={() => {
								setEditMode(true);
							}}>
							<BsPencil color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40"
							onClick={handleDelete}>
							<BsTrash color="gray" />
						</button>
					</div>
				</div>
			)}
			<div className="d-block">
				<fieldset disabled={editMode || props.newMode ? false : true}>

					<div className="row d-flex flex-column gy-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Tipo di utenza 
								</label>
								<select
									required
									name="type"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
									value={readCounter.type}
									onChange={onChange}
                                    >
									{counterType.map((menu, index) => {
                                        return (
                                            <option key={index} value={menu}>
                                                {menu}
                                            </option>
                                        );
                                    })}
								</select>
							</div>
						</div>
                        <div className="col-md-12 mb-2">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Data lettura
								</label>
								<input
									name="date"
									value={readCounter.date}
									onChange={onChange}
									className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>

						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Nome lettura
								</label>
								<input
									type="text"
									name="name"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={readCounter.name}
								/>
							</div>
						</div>

                        <div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Valore lettura
								</label>
								<input
									type="text"
									name="value"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={readCounter.value}
								/>
							</div>
						</div>
					</div>
                    
				</fieldset>
			</div>
			{editMode || props.newMode ? (
				
					<div className="d-flex align-items-center mt-5 form-last-btns justify-content-center mb-4">
					<ButtonRm variant="tertiary" onClick={() => {
								props.setShow(false);
							}} className="me-2" >Annulla</ButtonRm>
					<ButtonRm variant={"primary"} onClick={()=>props.add(readCounter)}>Salva</ButtonRm>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default ReadCounterModal;
