import React, { useState, useEffect } from "react";
import { BsPrinter, BsDownload } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import { BsPlusCircleFill } from "react-icons/bs";
import NewCounterModal from "./NewCounterModal";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import api, { supplier_endpoint } from "../../../../api/api";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import CurrencyInput from "react-currency-input-field";
import ReadCounterModal from "../UtilityModal";
import TariffeModal from "../TariffeModal";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";



import {Row, Col, Button} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ButtonRm from "../../../ButtonRm";

import CustomToggle from "../../../CustomToggle";

import CustomBadge from "../../../CustomBadge";


const UtilitiesE = (props) => {
	const navigate = useNavigate();
	const goSupplier = () => {
		navigate("/fornitori/create");
	};
	const [supplier, setSupplier] = useState([]);

	async function getSupplier() {
		try {
			const response = await api().get(`${supplier_endpoint}`);
			if (response.data) {
				setSupplier(response.data.content);
				console.log("supplier", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getSupplier();
	}, []);

	const [data, setData] = useState({});

	const init = () => {
		setData({
			electricitySupplier: props.formData.electricitySupplier,
			electricityCounterNumber: props.formData.electricityCounterNumber,
			podCode: props.formData.podCode,
			electricityState: props.formData.electricityState,
			electricityUserCode: props.formData.electricityUserCode,
			electricityTariffPlan: props.formData.electricityTariffPlan,
			gasSupplier: props.formData.gasSupplier,
			gasCounterNumber: props.formData.gasCounterNumber,
			pdrCode: props.formData.pdrCode,
			gasState: props.formData.gasState,
			gasUserCode: props.formData.gasUserCode,
			gasTariffPlan: props.formData.gasTariffPlan,
			hotWaterSupplier: props.formData.hotWaterSupplier,
			coldWaterSupplier: props.formData.coldWaterSupplier,
			coldWaterUserCode: props.formData.coldWaterUserCode,
			coldWaterState: props.formData.coldWaterState,
			coldWaterTariffPlan: props.formData.coldWaterTariffPlan,
			heatingSupplier: props.formData.heatingSupplier,
			boilerCode: props.formData.boilerCode,
			propertyTaxes: props.formData.propertyTaxes,
			incomeTaxes: props.formData.incomeTaxes,
			condominiumFees: props.formData.condominiumFees,
			directCostCda: props.formData.directCostCda,
			indirectCostCda: props.formData.indirectCostCda,
			readCounters: props.formData.readCounters,
			gasPlan: props.formData.gasPlan,
			electricityPlan : props.formData.electricityPlan,
			coldWaterPlan : props.formData.coldWaterPlan,

		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const [fullMode, setFullMode] = useState(false);
/* 
	const handleSave = () => {
		setEditMode(false);
		props.update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const [newCounterModal, setNewCounterModal] = useState(false);

	const [counter, setCounter] = useState([]);

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const [activeKey, setActiveKey] = useState('');

	//LETTURA CONTATORI

	//ADD LETTURA

	const [show, setShow] = useState(false);


 	const addCounter = (counter) => {
		let temp = [...data.readCounters];
		temp.push(counter);
		let e = { target: { name: "readCounters", value: temp } };
		onChange(e);
		setShow(false);
	}; 

	console.log(data, "DATA")

	//VIEW E EDIT LETTURA

	const [showSelectedCounter, setShowSelectedCounter] = useState(false)
	const [selectedCounter, setSelectedCounter] = useState({})
	const [counterEdit, setCounterEdit] = useState(-1);


	function closeSelectedCounter(){
		setShowSelectedCounter(false)
		setSelectedCounter({})
		setCounterEdit(null)

	}

	function handleCounter(id){
		setShowSelectedCounter(true)
		let current =  data.readCounters.find((item, index) => index == id)
		setSelectedCounter(current)
		setCounterEdit(id)
	}


	function updateCounter (counter){
		let temp =  data.readCounters.map((item, index) => {
			return index == counterEdit?
			counter:
			item
		})
		setData({ ...data, ["readCounters"]: temp });
		closeSelectedCounter()
	};

	const deleteCounter = () => {
		let temp =  data.readCounters.filter((item, index) => index != counterEdit)
		setData({ ...data, ["readCounters"]: temp }); 
		closeSelectedCounter()
	}; 

	//PIANI TARIFFARI
const [showTariffe, setShowTariffe] = useState(false)
const [tariffaType, setTariffaType] = useState("")

function handleShowTariffe(type){
	setTariffaType(type)
	setShowTariffe(true)
}


	return (
		<div>
			<div className={`mt-2`}>
				{/* <img
					src={data.image}
					alt=""
					style={{ height: "300px", width: "100%" }}
				/> */}
			</div>
			{/* <div className={`save-change-btn-group ${fullMode ? "" : "d-none"}`}>
				<div
					className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}>
					<div
						className="edit-btn w-144 me-2"
						onClick={() => {
							setEditMode(true);
						}}>
						Modifica
					</div>
				</div>
				<div
					className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}>
					<div className="edit-btn w-173 me-2" onClick={handleSave}>
						Salva modifiche
					</div>
					<button
						type="button"
						onClick={cancelEdit}
						className="border border-secondary bg-white rounded-3 edit-icon-btn">
						<i
							className="fal fa-times"
							style={{ color: "var(--edit-color)" }}></i>
					</button>
				</div>
			</div> */}
			
			<div className="form-item-align-box d-block">
			<div className={`d-flex justify-content-end align-items-center ${
							props.editMode === false ? "" : "d-none"
						}`}>
						<div className="d-flex mb-3">
								
								<ExportButton data={data} mapping={translationMapping} showPrint classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

							</div>
					</div>
				<fieldset disabled={props.editMode? false: true}>
			
				
				<Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Elettricità</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row mb-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Fornitore
									</label>
									<div className="d-flex">
										<select
											name="electricitySupplier" 
											style={{width:"90%"}}
											value={data.electricitySupplier}
											onChange={onChange}>
											<option value="">Seleziona</option>
											{supplier?.map((menu, index) => {
												return (
													<option
														key={index}
														value={menu.name + " " + menu.surname}>
														{menu.name + " " + menu.surname}
													</option>
												);
											})}
										</select>
										<BsPlusCircleFill
											className="col-sm-2 col-md-1 fs-35 plus-button"
											onClick={goSupplier}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">
										Matricola contatore
									</label>
									<input
										name="electricityCounterNumber"
										value={data.electricityCounterNumber}
										onChange={onChange}
										type="text"
									/>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 ">
										Codice POD
									</label>
									<input
										name="podCode"
										value={data.podCode}
										onChange={onChange}
										type="text"
									/>
								</div>
							</div>
							<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Stato
										</label>
										<div className="d-block">
											<select
												name="electricityState"
												value={data.electricityState}
												onChange={onChange}>
													<option value="aperto">Aperto</option>
													<option value="chiuso">Chiuso</option>
												</select>
										</div>
									</div>
								</div>
						</div>	
						<div className="row mt-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Codice utente
										</label>
										<input
											type="text"
											name="electricityUserCode"
											onChange={onChange}
											value={data.electricityUserCode
											}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Piano tariffario
										</label>
										<div className="d-flex justify-content-between align-items-center">
										<select
											type="text"
											name="electricityTariffPlan"
											onChange={onChange}
											value={data.electricityTariffPlan}
										>
											<option></option>
											{
													data?.electricityPlan?.length>0 &&
													data.electricityPlan.map((el,index) => {
														return <option  key={index} value={el.name}>{el.name}</option>
													})
												}
										</select>
										<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={() => {handleShowTariffe("electricityPlan")}}
												/>
											</div> 
									</div>
									</div>
								</div>
							</div>				
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Gas</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row">
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Fornitore
											</label>
											<div className="d-flex justify-content-between align-items-center">
												<select
													name="gasSupplier"
													value={data.gasSupplier}
													onChange={onChange}>
													{" "}
													{supplier?.map((menu, index) => {
														return (
															<option key={index} value={menu.name + " " + menu.surname}>
															{menu.name + " " + menu.surname}
															</option>
														);
													})}
												</select>
												<div
													style={{ padding: "3px" }}>
													<BsPlusCircleFill
														className="add-section-btn"
														onClick={goSupplier}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label className="f-label fs-16 d-flex align-items-center
mb-2">
												Matricola contatore
											</label>
											<input
												name="gasCounterNumber"
												value={data.gasCounterNumber}
												onChange={onChange}
												type="text"
											/>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Codice PDR
											</label>
											<input
												name="pdrCode"
												value={data.pdrCode}
												onChange={onChange}
												type="text"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Stato
											</label>
											<div className="d-block">
												<select
													name="gasState"
													value={data.gasState}
													onChange={onChange}>
														<option value="aperto">Aperto</option>
													<option value="chiuso">Chiuso</option>
													</select>
											</div>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Codice utente
											</label>
											<input
												type="text"
												name="gasUserCode"
												onChange={onChange}
												value={data.gasUserCode}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Piano tariffario
											</label>
											<div className="d-flex justify-content-between align-items-center">
											<select
												name="gasTariffPlan"
												onChange={onChange}
												value={data.gasTariffPlan}
											>
												<option></option>
												{
													data?.gasPlan?.length>0 &&
													data.gasPlan.map((el,index) => {
														return <option key={index} value={el.name}>{el.name}</option>
													})
												}
												
											</select>
											<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={() => {handleShowTariffe("gasPlan")}}
												/>
											</div> 
										</div>
										</div>
									</div>
								</div>			
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Acqua fredda</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row ">
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Fornitore
											</label>
											<div className="d-flex justify-content-between align-items-center">
												<select
													name="coldWaterSupplier"
													value={data.coldWaterSupplier}
													onChange={onChange}>
													{supplier?.map((menu, index) => {
														return (
															<option key={index} value={menu.name + " " + menu.surname}>
															{menu.name + " " + menu.surname}
															</option>
														);
													})}
												</select>
												<div
													style={{ padding: "3px" }}>
													<BsPlusCircleFill
														className="add-section-btn"
														onClick={goSupplier}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Codice utente
											</label>
											<input
												type="text"
												name="coldWaterUserCode"
												onChange={onChange}
												value={data.coldWaterUserCode}
											/>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Stato
											</label>
											<div className="d-block">
												<select
													name="coldWaterState"
													value={data.coldWaterState}
													onChange={onChange}>
														<option value="aperto">Aperto</option>
													<option value="chiuso">Chiuso</option>
													</select>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="d-flex flex-column">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Piano tariffario
											</label>
											<div className="d-flex justify-content-between align-items-center">
											<select
												name="coldWaterTariffPlan"
												onChange={onChange}
												value={data.coldWaterTariffPlan}
											>
												<option></option>
												{
													data?.coldWaterPlan?.length>0 &&
													data.coldWaterPlan.map((el,index) => {
														return <option value={el.name}>{el.name}</option>
													})
												}
											</select>
											<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={() => {handleShowTariffe("coldWaterPlan")}}
												/>
											</div> 
										</div>
										</div>
									</div>
								</div>		
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="3" activeKey={activeKey}>Acqua Calda</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Fornitore
								</label>
								<div className="d-flex">
									<select
										name="hotWaterSupplier"
										value={data.hotWaterSupplier}
										onChange={onChange}>
										<option value="">Seleziona</option>
										{supplier?.map((menu, index) => {
											return (
												<option
													key={index}
													value={menu.name + " " + menu.surname}>
													{menu.name + " " + menu.surname}
												</option>
											);
										})}
									</select>
									<BsPlusCircleFill
										className="col-sm-2 col-md-1 fs-35 plus-button"
										onClick={goSupplier}
									/>
								</div>
							</div>
						</div>
					</div>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="4" activeKey={activeKey}>Riscaldamento</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Fornitore
									</label>
									<div className="d-flex">
										<select
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
											name="heatingSupplier"
											value={data.heatingSupplier}
											onChange={onChange}>
											<option value="">Seleziona</option>
											{supplier?.map((menu, index) => {
												return (
													<option
														key={index}
														value={menu.name + " " + menu.surname}>
														{menu.name + " " + menu.surname}
													</option>
												);
											})}
										</select>
										<BsPlusCircleFill
											className="col-sm-2 col-md-1 fs-35 plus-button"
											onClick={goSupplier}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
										<div className="d-flex flex-column">
											<label className="f-label fs-16 d-flex align-items-center
mb-2">
												Matricola contatore
											</label>
											<input 
											type="text" 
											name="boilerCode" 
											value={data.boilerCode} 
											onChange={onChange}/>		
										</div>
									</div>
						</div>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="5" activeKey={activeKey}>Costi annuali</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Imposte patrimoniali (imu, tasi)
								</label>
								<CurrencyInput
									id="input-example"
									name="propertyTaxes"
									decimalsLimit={2}
									groupSeparator="."
									decimalSeparator=","
									value={data.propertyTaxes}
									suffix="€"
									onValueChange={(value, name) => {
										let e = {
											target: { name: name, value: value },
										};
										onChange(e);
									}}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Imposte sul reddito (IRPEF o IRES)
								</label>
								<CurrencyInput
									id="input-example"
									name="incomeTaxes"
									decimalsLimit={2}
									groupSeparator="."
									decimalSeparator=","
									value={data.incomeTaxes}
									suffix="€"
									onValueChange={(value, name) => {
										let e = {
											target: { name: name, value: value },
										};
										onChange(e);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Spese condominiali (lato proprietà)
								</label>
								<CurrencyInput
									id="input-example"
									name="condominiumFees"
									decimalsLimit={2}
									groupSeparator="."
									decimalSeparator=","
									value={data.condominiumFees}
									suffix="€"
									onValueChange={(value, name) => {
										let e = {
											target: { name: name, value: value },
										};
										onChange(e);
									}}
								/>
							</div>
						</div>
						{/* <div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Costi diretti CDA
								</label>
								<CurrencyInput
									id="input-example"
									name="directCostCda"
									decimalsLimit={2}
									groupSeparator="."
									decimalSeparator=","
									value={data.directCostCda}
									suffix="€"
									onValueChange={(value, name) => {
										let e = {
											target: { name: name, value: value },
										};
										onChange(e);
									}}
								/>
							</div>
						</div> */}
					</div>
					{/* <div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Costi indiretti CDA
								</label>
								<CurrencyInput
									id="input-example"
									name="indirectCostCda"
									decimalsLimit={2}
									groupSeparator="."
									decimalSeparator=","
									value={data.indirectCostCda}
									suffix="€"
									onValueChange={(value, name) => {
										let e = {
											target: { name: name, value: value },
										};
										onChange(e);
									}}
								/>
							</div>
						</div>
					</div> */}
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="6" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="6" activeKey={activeKey}>Letture contatori
					
					</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						{props.editMode && <div className="row py-2">
							<Col md={12}>
							<ButtonRm onClick={() => setShow(true)}>Nuovo</ButtonRm> 
							</Col>
						</div>	}

						<Row>
						{data.readCounters?.length> 0  ? (
							<div className="mt-4">
								<Table className="result-table table-border out-border-none">
								<thead>
									<tr>
									<th>Tipo</th>
									<th>Data</th>
									<th>Nome</th>
									<th>Valore</th>
									</tr>
								</thead>
								<tbody>
									{ data.readCounters.map((item, index) => {
									return (
										<tr key={index} onClick={() => handleCounter(index)}>
										<td>{item.type}</td>
										<td>{new Date(item.date).toLocaleDateString()}</td>
										<td>{item.name}</td>
										<td>{item.value}</td>
										</tr>
									);
									})}
								</tbody>
								</Table>
							</div>
							) : (
							<div className="unit-empty-img">
								<span className="img-comment fs-18">
								Nessuna lettura
								</span>
							</div>
						)}
							
						</Row>								
					</Accordion.Body>
				</Accordion.Item> 

					

				</Accordion>
				</fieldset>
			</div>
			{/* <div className="d-flex justify-content-end mt-5">						
				<button
					type="button"
					className="general-btn white-btn me-2 w-193"
					onClick={() => props.backward()}>
					Indietro
				</button>
				<button
					type="button"
					className="general-btn pink-btn w-193"
					onClick={() => props.forward()}>
					Avanti
				</button>
			</div> */}
	
		{/* CREATE LETTURA */}
		<Modal show={show}  className="right-full-pop" backdrop>
			<Modal.Body className="p-4">
				<Button
				variant=""
				onClick={() => {
					setShow(false);
				}}
				className="float-end border-0 bg-white"
				style={{ marginTop: "-1rem" }}
				>
				<i className="bi bi-x"></i>
				</Button>
				<ReadCounterModal  setShow={setShow} newMode={true} add={addCounter} />
			</Modal.Body>
		</Modal>
			
		{/* VIEW lettura */}
		<Modal show={showSelectedCounter}  className="right-full-pop" backdrop>
			<Modal.Body className="p-4">
			<Button
				variant=""
				onClick={closeSelectedCounter}
				className="float-end border-0 bg-white"
				style={{ marginTop: "-1rem" }}
			>
				<i className="bi bi-x"></i>
			</Button>
			<ReadCounterModal setShow={closeSelectedCounter} readCounter={selectedCounter}  newMode={false} add={updateCounter} delete={deleteCounter}/>
			</Modal.Body>
		</Modal> 

		 {/* TARIFFE MODAL */}
		<Modal show={showTariffe}  className="right-full-pop" backdrop>
			<Modal.Body className="p-4">
			<Button
				variant=""
				onClick={() => {setShowTariffe(false)}}
				className="float-end border-0 bg-white"
				style={{ marginTop: "-1rem" }}
			>
				<i className="bi bi-x"></i>
			</Button>
			<TariffeModal setShow={setShowTariffe} type={tariffaType} setFormData={setData} formData={data}/>
			</Modal.Body>
		</Modal> 
		</div>
	);
};

export default UtilitiesE;
