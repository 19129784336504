import React, { useState, useEffect, useRef } from "react";
import ImportFile from "../assets/images/upload_icon.svg";
import CloseIcon from "../assets/images/close.svg";
import DeleteIcon from "../assets/images/delete.svg";
import CloseErrorIcon from "../assets/images/closeError.svg";

import "../assets/css/UploadBtn.css";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function UploadBtn({onFileChange, className="", fileDescription="", format=""}) {
  const [file, setFile] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleChange = async (e) => {
    if (e.target.files.length !== 0) {
      const _file = Array.from(file);
      for (var i = 0; i < e.target.files.length; i++) {
        _file.push(e.target.files[i]);
      }
      setFile(_file);
      if (onFileChange) {
        onFileChange(_file); // invoca onFileChange con la lista di file
      }
    }
  };

  const removeOne = (index) => {
    const _file = Array.from(file);
    _file.splice(index, 1);
    setFile(_file);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const _file = Array.from(e.dataTransfer.files);
      setFile(_file);
      if (onFileChange) {
        onFileChange(_file); // invoca onFileChange con la lista di file
      }
    }
  };

  return (
    <div className="mt-3 mb-3" style={{ width: "100%" }}>
      <div
        className={
          dragActive ? `drag-active tab3RightBorder ${className}` : `tab3RightBorder ${className}`
        }
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <div
          className="tab3RightInsideBorder"
          style={{ height: file.length > 0 ? "40%" : "" }}
        >
          <img loading="lazy" src={ImportFile} style={{ width: "45px", height: "50px" }}></img>

          <div className="d-flex uploadBtn-description">
            <div className="importfile-description">
              Trascina qui il tuo file o
            </div>
            <label htmlFor="input-file" className="input-file-label">
              clicca per caricare
            </label>
            <input
              type="file"
              id="input-file"
              className="d-none"
              multiple={true}
              ref={inputRef}
              onChange={handleChange}
            />
          </div>
          <div className="importfile-description" style={{ marginLeft: "4px" }}>
            {fileDescription}
          </div>
          <div className="importfile-format">
            Formati supportati: {format}
          </div>
        </div>
        <div
          className="importFileStatus-div"
          style={{ display: file.length > 0 ? "inline" : "none" }}
        >
          <div
            className="importFileStatusTitle-div"
            id="importFileStatusTitle-div"
          >
            In Carimento - {file.length + "/" + file.length} files
          </div>
          <div className="completed-div" id="completed">
            Caricati
          </div>
          {file.length > 0 &&
            Array.from(file).map((element, index) => {
              return (
                <ImportFileProgress
                  file={element}
                  index={index}
                  key={index}
                  remove={(v) => removeOne(v)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

const ImportFileProgress = ({ file, index, remove }) => {
  const [now, setNow] = useState(0);
  const [isValid, setIsValid] = useState(true);

  const isValidFileType = (file) => {
    const validExtensions = ["png", "jpeg", "pdf", "jfif", "heif"];
    const fileExtension = file.type.split("/")[1];
    setIsValid(validExtensions.includes(fileExtension));
  };

  useEffect(() => {
    setNow(0);
    const timer = setInterval(() => {
      setNow((oldProgress) => {
        return Math.min(oldProgress + Math.random() * 60, 100);
      });
    }, 499);
    isValidFileType(file);
    // console.log("valide");
    // if (isValid === false) {
    //   console.log("valide");
    //   const div1 = document.getElementById("importFileStatusTitle-div");
    //   const div2 = document.getElementById(index);
    //   div2.setAttribute("width", "100%");
    //   if (div2 !== null) {
    //     div1.appendChild(div2);
    //   }
    // }
    return () => {
      clearInterval(timer);
      setIsValid(true);
    };
  }, [file]);

  const removeIcon = async (e) => {
    remove(index);
  };

  return (
    <div key={index + 1} id={index}>
      <div
        className="importFileProgressbar"
        style={{
          borderColor: isValid === true ? "" : "#E41D1D",
          position: "relative",
        }}
      >
        <div className="container">
          {file.name}
          <ProgressBar
            style={{ display: now === 100 ? "none" : "" }}
            now={now}
            visuallyHidden
            className="progressbar-upload"
          />
        </div>
        <img
          src={
            now === 100
              ? isValid === true
                ? DeleteIcon
                : CloseErrorIcon
              : CloseIcon
          }
          style={{ position: "absolute", right: "7px" }}
          className="closeIcon"
          onClick={(e) => removeIcon(e)}
        ></img>
      </div>
      <div
        className="fileTypeError-div"
        style={{ display: isValid === true ? "none" : "" }}
      >
        Questo tipo di file non è supportato.
      </div>
    </div>
  );
};
