// WebSocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
const BASE_URL_WS = 'ws://192.168.1.2:8080/';
var userData = JSON.parse(localStorage.getItem("user"));


const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const ws = new WebSocket(`${BASE_URL_WS}event-emitter`);
        //aggiunto parametro type per distinguere messaggi informativi da messaggi per chat
        ws.onopen = () => ws.send((JSON.stringify({type: "info", content: "", chatboxID: null, jwt: userData.token, replyToId: null })));
        ws.onclose = () => ws.send((JSON.stringify({type: "info", content: "", chatboxID: null, jwt: userData.token, replyToId: null })))
        ws.onerror = (error) => console.error('WebSocket error:', error);
        ws.onmessage = (event) => {
            // Gestisci i messaggi ricevuti
            console.log('Message from server:', event.data);
        };

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, []);

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
