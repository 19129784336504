import { useState, useEffect } from "react";
import filter_img from "../../../assets/images/filter.svg";
import sort_img from "../../../assets/images/sort.svg";
import { Link } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import StabiliCard from "./StabiliCard";

import {Row, Col, Modal} from "react-bootstrap"

import filter from "../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../assets/images/icon-sidebar/sort.svg"

import SearchInput from "../../SearchInput";

import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";
import FilterSearchBar from "../../StatoLavoriInCorso/Gestioni/FilterSearchBar";
import translationMapping from "./mapping";

import transmigrationFunction from "../../../utils/transmigrationFunctionCondo";

import Dropzone from 'react-dropzone'

import api, { stabili_endpoint } from "../../../api/api";

import { checkUserRole, checkMaster } from "../../../utils/checkUserRole";
import Alert from "react-bootstrap/Alert";
import ButtonRm from "../../ButtonRm";
import { formattedTimestamp } from "../../../utils/dateManipulation";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TableSortLabel } from '@mui/material';

import CustomBadge from "../../CustomBadge";

const headerNames = [
	"cdat",
	"name",
	"address",
	"houseNumber",
	"province",
	"municipality",
	"cap" ,
	"fiscalCode" ,
	"administrator"
];

	


// Definisci le varianti dei badge
const badgeVariants = [
	'custom-badge-success',
	'custom-badge-danger',
	'custom-badge-alert',
	'custom-badge-info',
	'custom-badge-pagina',
	'custom-badge-gray',
	'custom-badge-red',
	'custom-badge-orange',
	'custom-badge-yellow',
	'custom-badge-lime',
	'custom-badge-green',
	'custom-badge-teal',
	'custom-badge-turquoise',
	'custom-badge-aqua',
	'custom-badge-blue',
	'custom-badge-ultramarine',
	'custom-badge-purple',
	'custom-badge-pink',
	'custom-badge-transparent',
];


const columnBadgeVariantMap = {};
	headerNames.forEach((header, index) => {
	columnBadgeVariantMap[header] = badgeVariants[index % badgeVariants.length];
	});

	


const StabiliFirstPage = (props) => {

	const [sortOption, setSortOption] = useState(0);

	//gestione visione tabellare
	const [multiView, setMultiView] = useState(true)
	const [viewType, setViewType] = useState(localStorage.getItem('viewTypeStabili') || 'card');

	useEffect(() => {
	localStorage.setItem('viewTypeStabili', viewType);
	}, [viewType]);
	
   
	

	const mappingFromCompetitor1 = {
		"Nome": "name",
		"Amministratore": "administrator",
		"Codice fiscale": "fiscalCode",
		"Indirizzo": "address",
		"Civico": "houseNumber",
		"Citta": "municipality",
		"Prov": "province",
		"CAP": "cap",
	};
	
	//STABILI SELEZIONATI
	const [selectedItems, setSelectedItems] = useState([]);

	// Funzione per gestire la selezione multipla
	const handleSelection = (e, id) => {
		if(e){
			e.stopPropagation()
		}

		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
		} else {
			setSelectedItems([...selectedItems, id]);

		}
	};

	// Funzione per eliminare gli stabili selezionati
	const deleteSelected = async () => {
		// Effettua l'operazione di eliminazione per ogni id selezionato
		for (const id of selectedItems) {
		try {
			await api().delete(`${stabili_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
		}
		// Dopo aver eliminato gli stabili selezionati, reimposta lo stato di selezione
		setSelectedItems([]);
	};
  

		//handle modal delete
		const [show, setShow] = useState(false);
		const handleClose = (e) => {
			setShow(false);
			e.preventDefault();
			e.stopPropagation();
		};
		const handleShow = () => setShow(true);
	
		const handleSelectAll = () => {
		let selectedItems = props.sortedUnits.map((item, index) => item.id)
		setSelectedItems(selectedItems)
	}

	const handleDeselectAll = () => {
		setSelectedItems([])
	}

	//handle modal permessi
	const [showPermit, setShowPermit] = useState(false);
	const handleClosePermit = (e) => {
		setShowPermit(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShowPermit = () => setShowPermit(true);
	

	//MI SERVE LISTA COLLABORATORI MASTER
	const [members, setMembers] = useState([
	{
		id:1,
		name:"Ema",
		surname:"Mavi",
		email:"ema@gmail.com"
	},
	{
		id:2,
		name: 'Greta',
		surname: 'Pio',
		email: 'gre@gmail.com'
	}
	])
	
	const handleCheckboxChange = (userId, action) => {
	//da gestire
	};

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('');

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const sortedUnits = [...props.sortedUnits].sort((a, b) => {
	  if (orderBy) {
		/* if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
		  return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
		} */
		if (a[orderBy] && b[orderBy]) { 
		  return order === 'asc'
			? a[orderBy].localeCompare(b[orderBy])
			: b[orderBy].localeCompare(a[orderBy]);
		}
   	}
	  return 0;
	});


	return (
		<>
		<Row className="dashboard-container">
			{<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<FilterSearchBar
					setSearchTerm={props.setSearchTerm}
					selectedCategories={props.selectedCategories}
					setSelectedCategories={props.setSelectedCategories}
					clearFiltersAndSort={props.clearFiltersAndSort}
					setSortBy={props.setSortBy}
					categories={props.categories}
					startDate={props.startDate} 
					endDate={props.endDate}
					setStartDate={props.setStartDate} setEndDate={props.setEndDate}

					multiView={multiView}
					setViewType={setViewType}
					viewType={viewType}
            	/>
			</Col>}

			<div className="mb-3">
			{checkUserRole() &&
                	selectedItems.length > 0 && 
					<div className="d-flex mb-3">
						<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
							Seleziona tutti
						</ButtonRm>
						<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
							Deseleziona Tutti
						</ButtonRm>
					</div>
				}
				{viewType == 'card' ? 
					props.sortedUnits?.map((item, index) => {
							return (
								<div key={index}>
									<StabiliCard
										id={item.id}
										name={item.name}
										address={item.address}
										province={item.province}
										cap={item.cap}
										deleteStabili={props.deleteStabili}
										units={item.units}
										admin={item.administrator}
										item={item}
										handleSelection={handleSelection}
										selectedItems={selectedItems}
									/>
								</div>
							);
					})
					: 
					<>
					
					<TableContainer component={Paper} className="table-font table-activity">
      					<Table stickyHeader aria-label="simple table  table-activity">
       						<TableHead>
          					    <TableRow className="table-font table-act-head-cell">
									<TableCell style={{ minWidth: '10px', paddingLeft: "16px !important"  }} className="table-font table-act-head-cell"></TableCell>
									{headerNames.map((header) => (
									<TableCell style={{ minWidth: '160px', paddingLeft: "16px !important" }} className="table-font table-act-head-cell" key={header}>
									<TableSortLabel
										active={orderBy === header}
										direction={orderBy === header ? order : 'asc'}
										onClick={() => handleRequestSort(header)}
										>
										{translationMapping[header]}
										</TableSortLabel>
									</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody className="table-font">
							{sortedUnits.map((item, index) => (
								<TableRow key={item.id} className="table-font cursor-pointer" >
									<TableCell style={{ minWidth: '5px' }} className="table-font">
									<input
										type="checkbox"
										checked={selectedItems.includes(item.id)}
										onChange={(e) => handleSelection(e, item.id)}
										className="me-2"
									/>
									</TableCell>
									{headerNames.map((header) => {
                						const variant = columnBadgeVariantMap[header];

										return (header == 'cdat')?
											<TableCell td key={header}  style={{ minWidth: '150px' }}
											className="table-font cursor-pointer"> 
												      {item[header] && 
													<Link to={`/stabili/edit/${item.id}`}>
														<CustomBadge variant={variant} margin="mx-0">
														
															{formattedTimestamp(item[header])}
														</CustomBadge>
													</Link>
												}
											</TableCell>:
											<TableCell key={header} style={{ minWidth: '150px' }}
											className="table-font cursor-pointer"> 
											      {item[header] && 
													<Link to={`/stabili/edit/${item.id}`}className="" >
												<CustomBadge variant={variant} margin="mx-0">
													
													{ item[header]}
													
												</CustomBadge>
												</Link>
												}
											</TableCell>
									})}
								</TableRow>
							))}
							</TableBody>
						</Table>
					</TableContainer>

					</>
					}
			</div>

			<Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>
		</Row>

		<Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		</>
	);
};

export default StabiliFirstPage;
