import React from "react";

const ProviderCard = (props) => {
  const table_header = ["CATEGORIA", "TIPO DE SOGGETTO", "TELEFONO"];
  const remove = () => {
    props.remove(props.id);
  };

  return (
    <div className="mt-3 col-md-6">
      <div className="popup-yellow d-block overflow-hidden rounded-3">
        <div className="yellow-popup-header d-flex align-items-center justify-content-between p-2">
          <h3 className="fs-16 text-white fw-semibold py-1">{props.data.name +
							" " +
							props.data.surname +
							": " +
							props.data.businessName}</h3>
          {(props.edit_mode === undefined || props.edit_mode === true) && (
            <button
              type="button"
              className="border-0 bg-transparent text-white"
              onClick={remove}
            >
              <i className="bi bi-x"></i>
            </button>
          )}
        </div>
        <div className="table-list d-block  card-table">
          <table className="table table-borderless">
            <tbody>
              <tr>
                <td className="text-uppercase fs-14 text-secondary px-3">
                  {table_header[0]}
                </td>
                <td className="text-capitalize fs-14 text-dark px-3">
                  {props.data.category}
                </td>
              </tr>
              <tr>
                <td className="text-uppercase fs-14 text-secondary px-3">
                  {table_header[1]}
                </td>
                <td className="text-capitalize fs-14 text-dark px-3">
                  {props.data.subjectType}
                </td>
              </tr>
              <tr>
                <td className="text-uppercase fs-14 text-secondary px-3">
                  {table_header[2]}
                </td>
                <td className="text-capitalize fs-14 text-dark px-3">
                  {props.data.homeTelephone}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProviderCard;
