import {useState} from 'react'
import TopHeading from "../../TopHeading"
import '../../../assets/css/ImpostazioniChat.css'
import {Link} from 'react-router-dom'
import {Row, Col} from "react-bootstrap"
import ImpostazioniChatCard from './ImpostazioniChatCard'
import { checkSupplier, checkUserRole } from '../../../utils/checkUserRole'



function ImpostazioniChat(){

    return(
    <>
        <Row className="flex-column mt-5">
            <Col >
                <ImpostazioniChatCard name="Checklist e Verbali" icon="card-checklist" section="checklist" description="Crea e gestisci i tuoi modelli, condivisibili tramite chat"/>
            </Col>
             <Col >
                <ImpostazioniChatCard name="Contabilità" icon="cash-coin" section="contabilita" description="Gestisci i modelli per i tuoi preventivi, condivisibili tramite chat "/>
            </Col>
            {(checkUserRole()) && <Col >
                <ImpostazioniChatCard name="Squadre" icon="people" section="squadre" description="Crea i tuoi team di lavoro e condividi con loro le informazioni che desideri"/>
            </Col>}
            <Col >
                <ImpostazioniChatCard name="Tag" icon="tags" section="tag" description="Crea e gestisci i tuoi tag. Puoi associarli ai messaggi in chat per ritrovarli più rapidamente"/>
            </Col>
        </Row>
    </>
    )
}

export default ImpostazioniChat