import React, { useMemo, useState, useEffect, useRef } from "react";
import {Row, Col, Button, Modal} from 'react-bootstrap'
import TopHeading from "../components/TopHeading";
import { Link, Navigate, useNavigate } from "react-router-dom";
import api, { unit_endpoint, evaluation_endpoint, activity_endpoint, real_estate_management_endpoint } from "../api/api";
import  { owner_endpoint, management_endpoint, first_note_endpoint } from "../api/api";


import shortcutUnit from "../assets/images/shortcut-unit.svg"
import shortcutRent from "../assets/images/shortcuts-rent.svg"
import shortcutPrimanota from "../assets/images/shortcuts-primanota.svg"
import shortcutRate from "../assets/images/shortcuts-rata.svg"
import shortcutUser from "../assets/images/icon-sidebar/shortcuts-user.svg"
import shortcutScad from "../assets/images/icon-sidebar/shortcuts-scad.svg"
import location from "../assets/images/location.svg"

import filter from "../assets/images/icon-sidebar/filter.svg"
import sort from "../assets/images/icon-sidebar/sort.svg"
import searchIcon from "../assets/images/icon-sidebar/search.svg"
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";

import { LineChart } from '@mui/x-charts/LineChart';

import '../assets/css/Dashboard.css'

import dbUnit from '../assets/images/dashbutton-unit.svg'
import dbRent from '../assets/images/db-rent.svg'
import dbRata from '../assets/images/db-rata.svg'
import dbOwner from '../assets/images/db-owner.svg'
import dbConduttore from '../assets/images/db-conduttore.svg'
import dbFornitore from '../assets/images/db-fornitore.svg'
import dbPrimaNota from '../assets/images/db-primanota.svg'

import emptyActivities from '../assets/images/empty-activities-bacheca.svg'
import emptyGestione from '../assets/images/empty-gestione-bacheca.svg'

import pcUnit from '../assets/images/placeholder-colorato.png'

import DonutChart from 'react-donut-chart';

import PropTypes from 'prop-types';
//import Carousel from '../components/Carousel'
import ButtonRm from "../components/ButtonRm";

//import {Carousel} from '3d-react-carousal';

import { Logger } from "logging-library";
import FileViewer from "react-file-viewer";

import Carousel from 'react-spring-3d-carousel';
import { config } from "react-spring";

import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import chart1ph from "../assets/images/chart2-placeholder.svg"
import UnitCard from "../components/Affitti/UnitCard";
import SearchInput from "../components/SearchInput";
import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, removeDeadline, editDeadline, fetchDeadline } from "../actions/deadline";
import Dropzone from 'react-dropzone';
import transmigrationFunction from "../utils/transmigrationFunctionComplete";

import FilterSearchBar from "../components/StatoLavoriInCorso/Gestioni/FilterSearchBar";

import Alert from "react-bootstrap/Alert";

import { checkMaster, checkUserData } from "../utils/checkUserRole";

import ThreeD from "../components/3D/3d";

import { Tree, TreeNode } from 'react-organizational-chart';
import { SvgIcon } from '@mui/material';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import lineph from "../assets/images/bar-chart-pc.png"

import EmptyImg from "../assets/images/user-group.svg";




function CircleIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="8" />
    </SvgIcon>
  );
}


let todayDate = new Date().toISOString().slice(0, 10);

export default function Dashboarod() {

  const [preview_modal_show, setPreviewModalShow] = useState(false);
  const previewModalClose = () => setPreviewModalShow(false);
  const previewModalShow = () => setPreviewModalShow(true);
  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };

  /* PER AVERE LA DASHBOARD GENERALE VUOTA SVUOTARE I SEGUENTI 3 ARRAY */

  //GET DEADLINE LIST FROM REDUX
  const deadlineList = useSelector((state) => state.deadlineReducer?.deadline);

  const dispatch = useDispatch();

  //get deadline when this component render for the first time
  useEffect(() => {
    dispatch(fetchDeadline())
  }, []);

  const navigate = useNavigate();

  /*get units*/
  const [units, setUnits] = useState([]);
  const [gestioni, setGestioni] = useState([]);
  const [owners, setOwners] = useState([]);

  async function getUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [note, setNote]  = useState([])

  async function getNote() {
    try {
      const response = await api().get(`${first_note_endpoint}`);
      if (response.data) {
        setNote(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getUnits();
    getNote()
  }, []);

    /*get units SLC*/
    const [unitsSlc, setUnitsSlc] = useState([]);

    async function getUnitsSlc() {
      try {
        const response = await api().get(`${real_estate_management_endpoint}`);
        if (response.data) {
          setUnitsSlc(response.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    useEffect(() => {
      getUnitsSlc();
    }, []);

  //check if a unit is also in manutenzione
  const unitManutenzione =  unitsSlc.filter(el => el.activities?.length > 0);

  /* GET RENTAL STATE */
  //per manutenzione: aggiungere controllo, se unità ha attività associate sarà in manutenzione
  const rentalState = units.map(item => {
    let status
    if(item.rentalState == "Immobile libero" || item.rentalState == "Libero (utilizzato dalla proprietà)"){
      status = "libero"
    } else if (item.rentalState === ""){
      status = "non definito"
    } else {
      status = "occupato"
    }
    return  status
  })

  const values = ["libero", "non definito", "occupato"];
    let count = {};
    values.forEach(value => count[value] = 0);

  rentalState.forEach(item => {
      if (values.includes(item)) {
          count[item] += 1;
      }
  });
  
  console.log(count)

  /*get evaluation*/
  const [evaluationList, setEvaluationList] = useState([]);

  async function getEvaluation() {
    try {
      const response = await api().get(`${evaluation_endpoint}`);
      if (response.data) {
        setEvaluationList(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getEvaluation();
  }, []);

    /*get acitivities*/
    const [activities, setActivities] = useState([]);

    async function getActivities() {
      try {
        const response = await api().get(`${activity_endpoint}`);
        if (response.data) {
          setActivities(response.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    useEffect(() => {
      getActivities();
    }, []);



  let slides =  units.length> 0 ?
  units.map(item => {
    return {
      key: item.id,
      content:<CardCarousel key={item.id} item={item} handleVal={previewModalShow} evaluationList={evaluationList}/>
    }
  }):
  []

  const data = {
    labels: ['In Manutenzione', 'Occupate', 'Vuote'],
    datasets: [
      {
        label: 'N° di Unità',
        data: [unitManutenzione.length, count["occupato"], count["libero"]],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };


  const options = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltips: {
            enabled: false
        },
        emptyDoughnut: {
          color: "gray",
          width: 50,
          radiusDecrease: 20
        }
    }
}

  /*LIMITA NUMERO DI ELEMENTI VISIBILE*/

  const limitAct = 3;
  const sliceEndAct = activities.length < limitAct ? activities.length : limitAct;
  const sliceEndScad = deadlineList.length < limitAct ? deadlineList.length : limitAct;



  //SHORTCUTS

  const shortcuts = [
    {
      id:1,
      name: "Nuova unità",
      img: shortcutUnit,
      target: "/real-estate-units/create"
    },
    {
      id:2,
      name: "Nuovo affitto",
      img: shortcutRent,
      target: "/affitti/create"
    },
    {
      id:3,
      name: "Nuova rata",
      img: shortcutRate,
      target: "/rate/create"
    },
    {
      id:4,
      name: "Nuova prima nota",
      img: shortcutPrimanota,
      target: "/prima-nota/nuova-nota"
    },
    {
      id:5,
      name: "Nuovo proprietario",
      img: shortcutUser,
      target: "/proprietari/create"
    },
    {
      id:6,
      name: "Nuovo conduttore",
      img: shortcutUser,
      target: "/conduttori/create"
    },
    {
      id:7,
      name: "Nuova fornitore",
      img: shortcutUser,
      target: "/fornitori/create"
    },
    {
      id:8,
      name: "Nuova scadenza",
      img: shortcutScad,
      target: "/alert/nuovo-evento"
    }
]

//SCROLL CON FRECCE - SLIDESHOW

const containerCardRef = useRef(null);

useEffect(() => {
  const container = containerCardRef.current;
  const leftArrow = container?.previousSibling;
  const rightArrow = container?.nextSibling;

  const handleLeftArrowClick = () => {
    container.scrollTo({
      left: container.scrollLeft - 100,
      behavior: 'smooth'
    });
  };

  const handleRightArrowClick = () => {
    container.scrollTo({
      left: container.scrollLeft + 100,
      behavior: 'smooth'
    });
  };

  leftArrow?.addEventListener('click', handleLeftArrowClick);
  rightArrow?.addEventListener('click', handleRightArrowClick);

  return () => {
    leftArrow?.removeEventListener('click', handleLeftArrowClick);
    rightArrow?.removeEventListener('click', handleRightArrowClick);
  };
}, [units]);


//SCROLL CON FRECCE - SHORTCUTS

const containerShortcutsRef = useRef(null);

useEffect(() => {
  const containerSc = containerShortcutsRef.current;
  const leftArrowSc = containerSc?.previousSibling;
  const rightArrowSc = containerSc?.nextSibling;

  const handleLeftArrowClickSc = () => {
    containerSc.scrollTo({
      left: containerSc.scrollLeft - 100,
      behavior: 'smooth'
    });
  };

  const handleRightArrowClickSc = () => {
    containerSc.scrollTo({
      left: containerSc.scrollLeft + 100,
      behavior: 'smooth'
    });
  };

  leftArrowSc?.addEventListener('click', handleLeftArrowClickSc);
  rightArrowSc?.addEventListener('click', handleRightArrowClickSc);

  return () => {
    leftArrowSc?.removeEventListener('click', handleLeftArrowClickSc);
    rightArrowSc?.removeEventListener('click', handleRightArrowClickSc);
  };
}, []);


/* //SEARCH

const [search, setSearch] = useState("")

  //DERIVED LIST WITH ONLY THE UNITS THAT FITS A RESEARCH
  const searchedList =
    units && units.length > 0
      ? units.filter((unit) =>
          unit.name.toLowerCase().includes(search.toLocaleLowerCase())
        )
      : [];

      const categories = [
        "Abitazioni di 1° fascia",
        "Abitazioni di fascia media",
        "Abitazioni di 2° fascia",
        "Ville e villini",
        "Uffici",
        "Negozi",
        "Box e autorimesse",
        "Posti auto coperti",
        "Posti auto scoperti",
        "Laboratori",
        "Magazzini",
        "Capannoni tipici",
        "Capannoni industriali",
      ];


      const [selectedCategories, setSelectedCategories] = useState([]);
      const [sortBy, setSortBy] = useState("più-recenti");

      const [filteredUnits, setFilteredUnits] = useState([])


      useEffect(() => {
        if(units.length > 0)  {
          setFilteredUnits(
          units.filter((unit) => {
            return (
              (selectedCategories.length === 0 || selectedCategories.includes(unit.category)) &&
              unit.name.toLowerCase().includes(search.toLowerCase())
            );
          })
        )}
      }, [search, selectedCategories, units]);

      const [sortedUnits, setSortedUnits] = useState(filteredUnits);

      useEffect(() => {
        switch (sortBy.toLowerCase()) {
          case "z-a":
            setSortedUnits(sortStringByKey(filteredUnits, "name", false));
            break;
          case "a-z":
            setSortedUnits(sortStringByKey(filteredUnits, "name"));
            break;
          case "meno-recenti":
            setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
            break;
          default:
          case "più-recenti":
            setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
        }
      }, [filteredUnits, sortBy]);

      function clearFiltersAndSort() {
        setSearch("");
        setSelectedCategories([]);
        setSortBy("più-recenti");
        setFilteredUnits(units);
      } */


      

      //GET ADRESS

      function getAddressString(item) {
        let addressString = "";
      
        if (item.address) {
          addressString += `${item.address}`;
        }
      
        if (item.houseNumber) {
          addressString += ` ${item.houseNumber}`;
        }
      
        if (item.scale) {
          addressString += `, scala ${item.scale}`;
        }
      
        if (item.municipality) {
          addressString += `, ${item.municipality}`;
        }
      
        return addressString;
      }
      


      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
      };


      //FILTER-SEARCH-ORDER

      const [searchTerm, setSearchTerm] = useState("")

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  


    const categories = [
      {
        name: 'Categoria',
        items: [
          "Abitazioni di 1° fascia",
          "Abitazioni di fascia media",
          "Abitazioni di 2° fascia",
          "Ville e villini",
          "Uffici",
          "Negozi",
          "Box e autorimesse",
          "Posti auto coperti",
          "Posti auto scoperti",
          "Laboratori",
          "Magazzini",
          "Capannoni tipici",
          "Capannoni industriali",
        ]
      },
      {
        name: 'Proprietari',
        items: owners.map((owner) => `${owner.surname} ${owner.name}`),

      },
      {
      name: 'Gestione',
      items: gestioni.map(el => String(el.id)),
    }

    ];
    
    


		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])

    console.log(selectedCategories)


    useEffect(() => {
      if (units.length > 0) {
        setFilteredUnits(
          units.filter((unit) => {

            const unitDate = new Date(unit.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && unitDate < new Date(startDate)) {
              return false;
            }
    
            // Filtro per la data di fine (endDate)
            if (endDate && unitDate > new Date(endDate)) {
              return false;
            }
    
            return (
              (selectedCategories.length === 0 ||
                selectedCategories.some((cat) => {
                  if (cat.name === 'Proprietari') {
                    return unit.owners.some((owner) => {
                      const ownerFullName = `${owner.surname} ${owner.name}`;
                      return cat.items.includes(ownerFullName);
                    });
                  } if (cat.name === "Gestione") {
                    return gestioni.some((gestione) =>
                      gestione.realEstateManagements?.some(
                        (management) =>
                          management.unitID == unit.id && cat.items.includes(String(gestione.id))
                      )
                    );
                  } else {
                    return cat.items.includes(unit.category);
                  }
                })) &&
              unit.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
        );
      }
    }, [searchTerm, selectedCategories, units, startDate, endDate]);
    

		const [sortedUnits, setSortedUnits] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedUnits(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedUnits(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(units);
    setStartDate("")
    setEndDate("")

		}

  
  
  
  

  
    async function getGestioni() {
      try {
        const response = await api().get(`${management_endpoint}`);
        if (response.data) {
          setGestioni(response.data.content);
          console.log(response.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    async function getOwners() {
      try {
        const response = await api().get(`${owner_endpoint}`);
        if (response.data) {
          setOwners(response.data.content);
          console.log(response.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    useEffect(() => {
      getUnits()
      getGestioni()
      getOwners()
    }, []);

   //CHECK IF DATA REQUIRED ARE COMPILED
   const [dataRequired, setDataRequired] = useState(true);
   //Gestisce il modale
   const [showCheck, setShowCheck] = useState(false)
 
   useEffect(() => {
     const fetchData = async () => {
       try {
         const response = await checkUserData();
         setDataRequired(response.isVerified);
         if (!response.isVerified) {
           setShowCheck(true);
         }
       } catch (error) {
         // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
         console.error('Errore durante il recupero dei dati utente:', error);
       }
     };
 
     fetchData();
   }, []);


  const [team, setTeam] = useState([
    { master_id: "Master",
      team: []
    }
    ])


    function manageTeamData(teams){
      let newTeam = teams.length > 0 && teams.map(el => {
        return {
          name: 'MASTER',
          children: [
            {
              name: "PROPRIETARI",
              children: el.team.length > 0 ?
                el.team.filter(item => item.chat_role == "OWNER").length > 0?
                  el.team.filter(item => item.chat_role == "OWNER").map(user => {
                    return {
                      name: user.email
                    }
                  }) :
                  []
                
              : []
  
            },
            {
              name: "CONDUTTORI",
              children: el.team.length > 0 ?
                el.team.filter(item => item.chat_role == "CONDUCTOR").length > 0?
                  el.team.filter(item => item.chat_role == "CONDUCTOR").map(user => {
                    return {
                      name: user.email
                    }
                  }) :
                  []
                
              : []
  
            },
            {
              name: "FORNITORI",
              children: el.team.length > 0 ?
                el.team.filter(item => item.chat_role == "SUPPLIER").length > 0?
                  el.team.filter(item => item.chat_role == "SUPPLIER").map(user => {
                    return {
                      name: user.email
                    }
                  }) :
                  []
                
              : []
  
            },
            {
              name: "COLLABORATORI",
              children: el.team.length > 0 ?
                el.team.filter(item => item.chat_role == "COLLABORATOR").length > 0?
                  el.team.filter(item => item.chat_role == "COLLABORATOR").map(user => {
                    return {
                      name: user.email
                    }
                  }) :
                  []
                
              : []
  
            },
          ]
        }
      }) 
  
      return newTeam
    }


    console.log(manageTeamData(team), "TEAM")

  return (
    <div className="overflow-hidden">
      <Row>
          <Col>
            <TopHeading SmallHeading="empty"/>
          </Col>
      </Row>

      <Row className="mobile-container d-flex flex-column mt-md-4 ms-1 margin-dashboard-0">
          <Col className="d-flex justify-content-start m-0 p-0">
              <h2 className="fw-bold ">Dashboard</h2>
          </Col>
      </Row>
      {showCheck && <Alert variant="danger" className="d-flex align-items-center w-100" style={{maxHeight:"50px"}}>
          <i class="bi bi-info-circle me-2"></i>
          Inserisci i tuoi dettagli anagrafici in <span onClick={() => navigate("/impostazioni")} className="fw-bold ms-2">Impostazioni / Profilo / Anagrafica</span>
      </Alert>}

      <Row className="dashboard-container-bg dashboard-container">
          <Col sm={12} className="shortcuts-dashboard-container d-flex align-items-center mb-0">
                <button className="shortcuts-dashboard-left-arrow"><i class="bi bi-caret-left"></i></button>
              <Col className="d-flex shortcuts-container mb-4" ref={containerShortcutsRef}>
                  {
                    shortcuts.map(item => {
                      return <CardShortcuts img={item.img} shortcut={item.name} link={item.target}/>
                    })
                  }
              </Col>
              <button className="shortcuts-dashboard-right-arrow"><i class="bi bi-caret-right"></i></button>
          </Col>

          <hr className="space-hr" />
          
          {/* {units.length > 0 && <Col className="mt-4 mb-3 d-flex align-items-center justify-content-between">
            <SearchInput setSearch={setSearch}/>

            <div className="d-flex align-items-center ms-2">
                <div className="user-dropdown">
                  <div className="filter">                   
                    <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
                      <img loading="lazy" src={filter}/>
                    </div>
                  <div className="user-dropdown-content">
                  <div className="fs-14 fw-bold mb-2">Filtra per</div>
                <div className="fs-14">CATEGORIA</div>
                {categories.map((category) => (
                  <label key={category} className="d-block">
                    <input
                      type="checkbox"
                      className="me-2"
                      value={category}
                      checked={selectedCategories.includes(category)}
                      onChange={(event) =>
                        setSelectedCategories((prevCategories) =>
                          event.target.checked
                            ? [...prevCategories, category]
                            : prevCategories.filter((cat) => cat !== category)
                        )
                      }
                    />
                    {category}
                  </label>
                ))}

                <a className="text-decoration-underline text-dark text-center mt-3" onClick={clearFiltersAndSort}>Rimuovi filtri</a>

                
                  </div>
                </div>
                </div>
                <div className="user-dropdown">
					<div className="sort d-flex">
            <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
                <img loading="lazy" src={sort}/>
              </div>
					</div>
					<div className="user-dropdown-content">
						<div className="fs-16 fw-bold">Ordina per</div>
						<div
              onClick={() => setSortBy("z-a")}
							className="fs-14 sort-text"
							>
							Z-A
						</div>
            <div
              onClick={() => setSortBy("a-z")}
							className="fs-14 sort-text"
							>
							A-Z
						</div>
            <div
							className="fs-14  sort-text"
              onClick={() => setSortBy("meno-recenti")}
							>
							Meno recenti
						</div>
            <div
							className="fs-14 sort-text"
              onClick={() => setSortBy("più-recenti")}
							>
							Più recenti
						</div>

					</div>
				        </div>
            </div>
          </Col>} */}

          {units.length > 0 && <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<FilterSearchBar
					setSearchTerm={setSearchTerm}
					selectedCategories={selectedCategories}
					setSelectedCategories={setSelectedCategories}
					clearFiltersAndSort={clearFiltersAndSort}
					setSortBy={setSortBy}
					categories={categories}
					startDate={startDate} 
					endDate={endDate}
					setStartDate={setStartDate} setEndDate={setEndDate}

					multiView={false}
          />
			</Col>}


          {sortedUnits?.length > 0 && 
          <Col sm={12} className="slider-dashboard-container d-flex align-items-center">
            <button className="slider-dashboard-left-arrow"><i class="bi bi-caret-left fs-24"></i></button>
            <Col className="d-flex shortcuts-container"  ref={containerCardRef}>
            {
              sortedUnits?.map(item => {
                return <UnitCardSlide data={item.cdat} img={item.image} name={item.name} adress={getAddressString(item)} item={item} handleVal={previewModalShow}  />
              })
            }
            </Col>
            <button className="slider-dashboard-right-arrow"><i class="bi bi-caret-right fs-24"></i></button>
          </Col>}
          
          {units.length > 0 && <hr className="space-hr mt-4 d-bl" />}

          <Col sm={12}>
            <Row className="mt-2 gy-4">
            {/* CHART */}
            <Col xs={12} md={6}  xl={4}>
              <DashContainer >
                  {/* <p className="text-uppercase fs-14">Unità Immobiliari </p>
                  <div className="text-center chart-cont-big">
                    <DonutChart
                      formatValues={(values, total) => `${values} su ${total}`}
                      strokeColor='transparent'
                      clickToggle={false}
                      className="dchart"
                      data={[
                        {
                          label: 'Occupate',
                          value: 0,
                          isEmpty: true
                        },
                        {
                          label: 'Vuote',
                          value: 0,
                          isEmpty: true
                        },
                        {
                          label: 'In Manutenzione',
                          value: 0,
                          isEmpty: true
                        }
                      ]}
                    />
                  </div> */}

            {/* questo dato deriva dal controllo del campo: stato locativo di ciascuna abitazione */}
                <p className="text-uppercase fs-14">Unità Immobiliari - {units.length} unità</p>
                {
                  data?.datasets?.[0]?.data?.[0] === 0 &&
                  data?.datasets?.[0]?.data?.[1] === 0 &&
                  data?.datasets?.[0]?.data?.[2] === 0 ?
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img loading="lazy" src={chart1ph} className="mt-5 mb-3" height="120px"/>
                    <p className='opacity-50'>Nessun dato disponibile</p>
                  </div> :
                  <Doughnut data={data} className="rc-donut-dashboard"
                  options={options} />
                  }

              </DashContainer>
            </Col>

            <Col xs={12} md={6}  xl={4}>
              <DashContainer>
                <div className="mb-4 d-flex justify-content-between align-items-center">
                  <p className="text-uppercase fs-14">Attività Recenti</p>
                  {activities.length > 0 &&
                  <Link to="/statoavanzamentolavori/attivita" className="text-uppercase fs-10 text-uppercase text-decoration-underline text-dark">Vedi Tutto</Link>
                  }
                </div>

                { activities.length > 0 ? 
                  activities.slice(0, sliceEndAct).map((item,index) => <CardAttività key={index} name={item.name} immobile={item.unitName} item={item} />):
                  <div className="w-100 text-center py-4">
                      <img loading="lazy" src={emptyGestione} width="35%"/>
                      <p className="fs-14 mt-3 opacity-50">Nessuna attività</p>
                  </div>
                }
              </DashContainer>
            </Col>

            <Col xs={12} md={6}  xl={4}>
              <DashContainer>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                  <p className="text-uppercase fs-14">Scadenze</p>
                  {deadlineList.length > 0 &&
                    <Link to="/alert/scadenzario" className="text-uppercase fs-10 text-uppercase text-decoration-underline text-dark">Vedi Tutto</Link>
                    }
                  
                </div>
                {
                  deadlineList.length > 0 ?
                  deadlineList.slice(0, sliceEndScad).map((item,index) => <CardScadenze key={index} id={item.id} name={item.title}
                  section={item.section} time={formatDate(item.startDate)} repeat={item.repeat}/>):
                  <div className="w-100 text-center py-4">
                      <img loading="lazy" src={emptyActivities} width="35%"/>
                      <p className="fs-14 mt-3 opacity-50">Nessuna scadenza impostata</p>
                  </div>
                }
              </DashContainer>
            </Col>
          
            </Row>
          </Col>

          <Col sm={12}>
            <Row className="mt-2 gy-4">
            {/* CHART */}
            <Col xs={12} >
              <DashContainer2 >
                  {/* <p className="text-uppercase fs-14">Unità Immobiliari </p>
                  <div className="text-center chart-cont-big">
                    <DonutChart
                      formatValues={(values, total) => `${values} su ${total}`}
                      strokeColor='transparent'
                      clickToggle={false}
                      className="dchart"
                      data={[
                        {
                          label: 'Occupate',
                          value: 0,
                          isEmpty: true
                        },
                        {
                          label: 'Vuote',
                          value: 0,
                          isEmpty: true
                        },
                        {
                          label: 'In Manutenzione',
                          value: 0,
                          isEmpty: true
                        }
                      ]}
                    />
                  </div> */}

            {/* questo dato deriva dal controllo del campo: stato locativo di ciascuna abitazione */}
                <p className="text-uppercase fs-14 mb-4">Prime Note - Riepilogo</p>
                

                {note?.length > 0 ? 
                <PrimaNotaChart data={note} /> :

                <div className="text-center d-flex flex-column  align-items-center mt-4">
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                      <div className="mx-auto d-table mb-3">
                        <img loading="lazy" src={lineph} alt="empty" width={"40%"} className=""  />
                      </div>
                      <p className="fs-14 text-secondary text-center">
                        Nessun dato disponibile
                      </p>
                      </div>
                    </div>
              
              }
      
                

              </DashContainer2>
            </Col>

            {checkMaster() && <Col xs={12} >
              <DashContainer2 >
              <div id="treeWrapper" style={{ width: '100%'}} className="custom-overflow">
                <p className="text-uppercase fs-14 mb-4">Il tuo team</p>
                
                {
                  team[0]?.team?.length > 0 ?
                    <TreeComponent team={manageTeamData(team)} /> :
                    <div className="text-center d-flex flex-column  align-items-center mt-4">
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                      <div className="mx-auto d-table w-160 mb-3">
                        <img loading="lazy" src={EmptyImg} alt="empty" className="opacity-50" width="120px" />
                      </div>
                      <p className="fs-14 text-secondary text-center">
                        Non hai ancora invitato nessuno
                      </p>
                      </div>
                    </div>

                }

              </div>
              </DashContainer2>
            </Col>}
            </Row>
          </Col>

        {/* <Row>
            <ThreeD />
        </Row>  */}
      </Row>

      {/* <Row className="my-4">
        <Col className="d-flex flex-wrap justify-content-between">
          <Link className="dashbutton text-center" to='/real-estate-units/create'><DashButton img={dbUnit} text='Nuova Unità'/></Link>
          <Link className="dashbutton text-center" to='/affitti/create'><DashButton img={dbRent} text='Nuovo Affitto'/></Link>
          <Link className="dashbutton text-center" to='/rate'><DashButton img={dbRata} text='Nuova Rata'/></Link>
          <Link className="dashbutton text-center" to='/conduttori/create'><DashButton img={dbConduttore} text='Nuovo Conduttore' long/></Link>
          <Link className="dashbutton text-center" to='/fornitori/create'><DashButton img={dbFornitore} text='Nuovo Fornitore'/></Link>
          <Link className="dashbutton text-center" to='/proprietari/create'> <DashButton img={dbOwner} text='Nuovo Proprietario' long/></Link>
          <Link className="dashbutton text-center" to='/'><DashButton img={dbPrimaNota} text='Nuova Prima Nota' long/></Link>
        </Col>
      </Row>
      
      <Row className="justify-content-center">
        <Col sm={10} md={8} lg={6} xl={5} className='dashCol'>
          
          {
            units.length > 2 &&
              <CarouselRM
                cards={slides}
                height="400px"
                width="100%"
                offset={1}
                showArrows={false}
              />            
          
          }
        </Col>
      </Row> */}

      <Modal
        show={preview_modal_show}
        onHide={previewModalClose}
        className="val-preview"
        centered
      >
        <Button
          variant=""
          onClick={previewModalClose}
          className="modal-close-btn"
        >
          <i className="fal fa-times modal-close-icon"></i>
        </Button>
        <Modal.Body className="px-5">
          <FileViewer
            fileType={"pdf"}
            filePath={window.location.origin + "/val-im.pdf"}
            onError={onError}
          />
        </Modal.Body>
      </Modal>

        {/* PER GESTIRE LA TRASMIGRAZIONE - BISOGNA DROPPARE FILE ESXCEL CON STRUTTURA SPECIFICA VEDI FILE: trasmigrazione mac */}
       <Dropzone onDrop={(acceptedFiles) => transmigrationFunction(acceptedFiles)
			} >
				{({ getRootProps, getInputProps }) => (
					<section className="">
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<div className="filter">
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
							<i className="bi bi-upload fs-25"></i>
						</div>
						</div>
					</div>
					</section>
				)}
			</Dropzone> 
    </div>
  );
}


const PrimaNotaChart = ({ data }) => {
  const [filter, setFilter] = useState('30'); // Default 30 days

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const today = new Date();
  const filteredData = data.filter(entry => {
    const entryDate = new Date(entry.operationDate);
    const daysDifference = (today - entryDate) / (1000 * 60 * 60 * 24);
    switch (filter) {
      case '30':
        return daysDifference <= 30;
      case '90':
        return daysDifference <= 90;
      case '6months':
        return today.getFullYear() === entryDate.getFullYear() && today.getMonth() - entryDate.getMonth() <= 6;
      default:
        return true;
    }
  });

  const sortedData = [...filteredData].sort((a, b) => new Date(a.operationDate) - new Date(b.operationDate));

  const dailyData = sortedData.reduce((acc, entry) => {
    const day = new Date(entry.operationDate).toISOString().slice(0, 10);
    const amount = entry.amount;
    const isEnteringAmount = entry.isEnteringAmount;

    if (!acc[day]) {
      acc[day] = { totalEntering: 0, totalExiting: 0 };
    }

    if (isEnteringAmount) {
      acc[day].totalEntering += amount;
    } else {
      acc[day].totalExiting += amount;
    }

    return acc;
  }, {});

  const enteringData = [];
  const exitingData = [];
  const xLabels = [];

  for (const day in dailyData) {
    enteringData.push(dailyData[day].totalEntering);
    exitingData.push(dailyData[day].totalExiting);
    xLabels.push(day);
  }

  const hasData = enteringData.length > 0 || exitingData.length > 0;

  return (
    <div className="text-center d-flex flex-column  align-items-center">
      <ToggleButtonGroup
        value={filter}
        exclusive
        onChange={handleFilterChange}
        aria-label="Filter by period"
        sx={{ marginBottom: '20px' }}
      >
        <ToggleButton value="30" aria-label="Last 30 days">
          30 giorni
        </ToggleButton>
        <ToggleButton value="90" aria-label="Last 90 days">
          90 giorni
        </ToggleButton>
        <ToggleButton value="6months" aria-label="Last 6 months">
          Ultimi 6 mesi
        </ToggleButton>
      </ToggleButtonGroup>

      {hasData ? (
        <LineChart
          width={500}
          height={300}
          series={[
            { id: "Entrate", data: enteringData, label: 'Entrate', area: true, showMark: true },
            { id: "Uscite", data: exitingData, label: 'Uscite', area: true, showMark: true },
          ]}
          colors={["lightgreen", "lightcoral"]}
          sx={{
            '& .MuiResponsiveChart-container': {
              margin: "0 auto",
            },
            '& .MuiLineElement-root': {
              strokeWidth: 1,
            },
            '& .MuiAreaElement-series-Entrate': {
              fill: "url('#myGreenGradient')",
            },
            '& .MuiAreaElement-series-Uscite': {
              fill: "url('#myRedGradient')",
            },
            '& .MuiLineElement-series-Entrate': {
              stroke: 'lightgreen',
            },
            '& .MuiLineElement-series-Uscite': {
              stroke: 'lightcoral',
            },
            '& .MuiChartsLegend-mark': {
              borderRadius: '50%',
            },
            '& .MuiLegend-label': {
              '&[data-series-id="Entrate"]': {
                color: 'lightgreen',
              },
              '&[data-series-id="Uscite"]': {
                color: 'lightcoral',
              },
            },
          }}
          xAxis={[{ scaleType: 'point', data: xLabels }]}
          className="mx-auto"
        >
          <defs>
            <linearGradient id="myGreenGradient" gradientTransform="rotate(90)">
              <stop offset="5%" stopColor="lightgreen" stopOpacity="0.5" />
              <stop offset="5%" stopColor="lightgreen" stopOpacity="0.5" />
            </linearGradient>
            <linearGradient id="myRedGradient" gradientTransform="rotate(90)">
              <stop offset="5%" stopColor="lightcoral" stopOpacity="0.5" />
              <stop offset="5%" stopColor="lightcoral" stopOpacity="0.5" />
            </linearGradient>
          </defs>
        </LineChart>
      ) : (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <img src={lineph} height={200}/>
        </div>
      )}
    </div>
  );
}

function DashButton(props){

  return (
    <>
      <img loading="lazy" src={props.img}/>
      <p className={`${props.long? 'fs-15' : ''}`}>{props.text}</p>
    </>
  )
}

function DashContainer(props){

  return (
    <div className={`dashContainer overflow-hidden w-100 ${props.center? 'chart' : ''} `} >
      {props.children}
    </div>
  )
}

function DashContainer2(props){

  return (
    <div className={`dashContainer overflow-hidden w-100 ${props.center? 'chart' : ''} `} style={{maxHeight: "unset"}}>
      {props.children}
    </div>
  )
}

function CardAttività({name, immobile, item}){
    
  //a STATUSTAG bisogna passare lo "stato" dell'attività (stringa)
  //tra => aperta, incorso, risolta, ritardo

  const startDate = item.startDate?.substr(0, 10);
  const expirationDate = item.expirationDate?.substr(0, 10);
  const endDate = item.end_date ? item.endDate.substr(0, 10) : "";
  let status;

  if (todayDate < startDate?.split(",").join("-")) {
    status = "aperta";
  } else if (todayDate >= startDate?.split(",").join("-")) {
    status = "incorso";
  } else if (item.endDate) {
    status = "risolta";
  } else if (todayDate > expirationDate?.split(",").join("-")) {
    status = "in ritardo";
  }

  return(
    <Link to={`/statoavanzamentolavori/attivita/${item.id}`} className="text-dark">
      <div className="mb-2 card-gestione">
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-14 fw-bold">{capitalizeFirstLetter(name)}</p>
          <StatusTag status={status} size='mini'/>
        </div>
        <p className="fs-12 opacity-50 gestione-details text-uppercase"> {immobile} </p>
      </div>
    </Link>
  )
}

function CardScadenze({name, section, time, repeat, id}){
  return(
    <Link to={`/alert/scadenzario/${id}`} className="text-dark">
      <div className="mb-2 card-scadenze">
          <p className="fs-10 opacity-50 scadenze-details text-uppercase"> {section} </p>
          <p className="fs-14 fw-bold text-capitalize">{capitalizeFirstLetter(name)}</p>
          <div className="d-flex align-items-center">
            <p className="fs-12 opacity-50 gestione-details text-uppercase"> {time} </p>
            {/* <p className="mx-2 opacity-50 fs-10">•</p> */}
            <p className="fs-12 opacity-50 gestione-details text-capitalize"> {repeat} </p>
          </div>
          
      </div>
      </Link>
  )
}


function StatusTag({status, size='', ...props }){
    return (
        <div className={`statusTag ${size} ${status }`} {...props} >
            {status === 'aperta' && <p>Aperta</p>}
            {status === 'incorso' && <p>In Corso</p>}
            {status === 'risolta' && <p>Risolta</p>}
            {status === 'ritardo' && <p>In Ritardo</p>}
        </div>
    )
}

function CardCarousel(props){

  function getInitials(name, surname) {
    return name.charAt(0) + surname.charAt(0);
  }    
  const navigate = useNavigate()
  return(
    <div className="card-car">
      <div className="card-car-header w-100" style={{backgroundImage:`url(${props.item.image? props.item.image : pcUnit})`}}>
        {props.item.owners?.length > 0 && <div className="card-car-initials">{getInitials(props.item.owners?.[0]?.name, props.item.owners?.[0]?.surname)}</div>}
      </div>
      <div className="card-car-body">
        <h6 className="fw-bold">{props.item.name}</h6>
        <div className="my-2">
          <p className="fs-12 opacity-75 text-uppercase">Indirizzo</p>
            <p className="text-capitalize">
            {props.item.address ? `${props.item.address}` : ""} 
            {props.item.houseNumber ? ` ${props.item.houseNumber} ` : ""} 
            {props.item.scale? ` scala ${props.item.scale}, ` : ""} 
            {props.item.municipality? props.item.municipality : ""}
          </p> 
        </div>
         {
          props.item.owners.length > 0 &&
          <div>
          <p className="fs-12 opacity-75 text-uppercase">Proprietario</p>
           <p className="text-capitalize">{props.item.owners.length > 1 ? `${props.item.owners?.[0]?.name} ${props.item.owners?.[0]?.surname} e altri` : `${props.item.owners?.[0]?.name} ${props.item.owners?.[0]?.surname}`} </p>
        </div>
        } 
        
      </div>
      <div className="card-car-footer py-3 text-center">
        {
        <ButtonRm variant="primary" onClick={() => navigate('/evaluation/create')}>
          <p className="fs-14">Genera Valutazione</p>
        </ButtonRm>}
       {/*  {props.evaluationList.length > 0 && 
        <ButtonRm variant="primary" onClick={props.handleVal}>
          <p className="fs-12">Valutazione immobiliare</p>
          <p className="fs-12 fw-bold val_price">528.250€</p>
        </ButtonRm>} */}
      </div>
    </div>
  )
}

StatusTag.propTypes = {
    status: PropTypes.oneOf(['aperta', 'in corso', 'risolta', 'ritardo']),
    size: PropTypes.oneOf(['small'])
}



function CarouselRM(props){
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => setGoToSlide(index) };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
}




function CardShortcuts({img, shortcut, link}){
  return(
    <Link to={link}>
      <div className="shortcutCard">
        <img loading="lazy" src={img}/>
        <p className="fs-14 fw-bold">{shortcut}</p>
      </div>
    </Link>
  )
}


function UnitCardSlide(props){
  return (<div className="cardUnitDashboard me-3">
    {/* <Link to="/"> */}
    <div className="cardUnitDashboardImg" onClick={props.handleVal}>
      <img loading="lazy" src={pcUnit}/>
      <div className="cardUnitDashboardImgOverlay">
        <p>Valutazione Immobile</p>
        <p className="cardDashboardVal">€ 113.000</p>
      </div>
    </div>
{/*     </Link>
 */}    <Link to={`/real-estate-units/edit/${props.item.id}`} className="cardUnitDashboardContentText">
    <div className="cardUnitDashboardContent"> 
      <p className="fw-bold mt-2 mb-1 text-capitalize">{props.name}</p>
      {props.adress && <div className="d-flex align-items-center">
        <img loading="lazy" src={location}/>
        <p className="fs-14 ms-1 address-card-dashboard">{props.adress}</p>
      </div>}
    </div>
    </Link>
  </div>)
}



const TreeComponent = ({ team }) => {
  const rootNode = team[0];
  
  // Inizializza lo stato con "MASTER" espanso di default
  const [expandedNodes, setExpandedNodes] = useState({ [rootNode.name]: true });

  const handleNodeClick = (nodeName) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [nodeName]: !prevState[nodeName]
    }));
  };

  const renderTreeNodes = (data, parentName = '') => {
    return data.map((node) => {
      const nodeName = `${parentName}${node.name}`;
      const isExpanded = expandedNodes[nodeName];
      const hasChildren = node.children && node.children.length > 0;
      const nodeClass = hasChildren ? (isExpanded ? 'tree-node-expanded' : 'tree-node-collapsed') : '';

      return (
        <TreeNode
          key={nodeName}
          label={
            <div onClick={() => hasChildren && handleNodeClick(nodeName)} style={{ cursor: hasChildren ? 'pointer' : 'default' }}>
              {node.name} {hasChildren && (isExpanded ? '-' : '+')}
            </div>
          }
          
        >
          {hasChildren && isExpanded && renderTreeNodes(node.children, `${nodeName}-`)}
        </TreeNode>
      );
    });
  };

  return (
    <Col xs={12}>
      <div style={{ width: '100%' }} className="custom-overflow">
        <Tree
          label={
            <div onClick={() => handleNodeClick(rootNode.name)} style={{ cursor: 'pointer' }}>
              {rootNode.name} {expandedNodes[rootNode.name] ? '-' : '+'}
            </div>
          }
        >
          {expandedNodes[rootNode.name] && renderTreeNodes(rootNode.children, `${rootNode.name}-`)}
        </Tree>
      </div>
    </Col>
  );
};