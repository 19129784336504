import React, { useState, useEffect } from "react";
import FolderImg from "../../../../assets/images/empty-folder.png";
import FileCard from "../../../Documenti/prima_nota/Tipologia/FileCard";
import ButtonRm from "../../../ButtonRm";
import axios from "axios";
import mimeTypes from "../../../../allegatiType";
import { BASE_URL } from "../../../../api/api";

const AttachmentsE = ({ attachment, img, update, saveState, editMode, id, planimetry }) => {
	const [attachmentData, setAttachmentData] = useState([])
	//GESTIONE NUOVI ALLEGATI 
	const [newAttachments, setNewAttachments] = useState([])

	const init = () => {
		setAttachmentData(attachment);
		setNewAttachments([])
	};

	useEffect(() => {
		init();
	}, [attachment]);

	/* 
		const [editMode, setEditMode] = useState(false);
		const handleSave = () => {
			setEditMode(false);
			let data = { attachment: attachmentData };
			update(data);
		};

		const cancelEdit = () => {
			setEditMode(false);
			init();
		};
	*/

	const setFileName = (index, new_name) => {
		let temp = [...attachmentData];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setAttachmentData(temp);
	};

	const deleteFile = (index) => {
		let temp = [...attachmentData];
		temp.splice(index, 1);
		setAttachmentData(temp);
	};

	const handleChange = (e) => {
		let temp = [...newAttachments];
		setNewAttachments([...temp, ...e.target.files]);
	};

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		let data = { newAttachment: [ ...newAttachments] };
		if (saveState === true) {
			update(data);
		} else {
			init();
		}

	}, [saveState, editMode]);

	const downloadFile = async (name) => {
			const fileUrl = `${BASE_URL}api/v1/unit/download/${id}/${name}`;
		
			const response = await axios({
			url: fileUrl,
			method: 'GET',
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${
				JSON.parse(localStorage.getItem("user"))?.token
				}`,
				"Content-Type": `application/json`,
				
			},
			});
		
			// ottieni l'estensione del file
			const fileExtension = name.split('.').pop();
		
			const mimeType = mimeTypes[fileExtension];
		
			// crea un nuovo Blob con il tipo MIME corretto
			const file = new Blob([response.data], { type: mimeType });
		
			// crea un URL per il Blob
			const fileURL = URL.createObjectURL(file);
		
			// scarica il file
			window.open(fileURL);
		};

	return (
		<div>
			<div className="mt-2">
				{/* <img loading="lazy" src={img} alt="" style={{ height: "300px", width: "100%" }} /> */}
			</div>
			{/* <div className="save-change-btn-group">
				<div
					className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}>
					<div
						className="edit-btn w-144 me-2"
						onClick={() => {
							setEditMode(true);
						}}>
						Modifica
					</div>
				</div>
				<div
					className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}>
					<div className="edit-btn w-173 me-2" onClick={handleSave}>
						Salva modifiche
					</div>
					<button
						type="button"
						onClick={cancelEdit}
						className="border border-secondary bg-white rounded-3 edit-icon-btn">
						<i
							className="fal fa-times"
							style={{ color: "var(--edit-color)" }}></i>
					</button>
				</div>
			</div> */}
			<div className="edit-content-container mt-3">
				<div className="form-item-align-box-white d-block">
					<label htmlFor="input-file" className={editMode ? "" : "d-none"}>
						<div class="edit-btn edit-white me-2 w-144 mb-5">Seleziona</div>
					</label>
					<input
						type="file"
						id="input-file"
						className="d-none"
						multiple={true}
						name="attachment"
						onChange={handleChange}
					/>
					{attachmentData?.length > 0 && (
						attachmentData.map((item, index) => {
							return (
								<FileCard
									key={index}
									name={item}
									setFileName={setFileName}
									deleteFile={deleteFile}
									id={index}
									view_mode={editMode}
									download={downloadFile}
								/>
							);
						})
					)}

					{ newAttachments.length > 0 && (
						newAttachments.map((file, index) => {
							return (
							<FileCard
								key={index}
								name={file.name}
								id={index}
								view_mode={editMode}
								download={() => {}}
								isNewAttachment={true}
							/>
							);
						})
					)}

					{( newAttachments.length == 0 && attachmentData?.length == 0 ) &&
						<div className="unit-empty-img">
							<img loading="lazy" src={FolderImg} alt="" />
							<span className="img-comment">
								Non hai aggiunto ancora alcun allegato
							</span>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

export default AttachmentsE;
