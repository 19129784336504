
import { Navbar, Nav, Button, Image, Row, Col } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Link } from 'react-router-dom';

import logo from "../../assets/images/Group 1189.svg"

const navyColor = "#004b87";


const renderDropdownItem = (iconClass, title, description, link="/") => (
  
  <Link to={`${link}`}>
  <NavDropdown.Item href="#action/3.2">
    <div className='d-flex align-items-start p-3'>
     {/*  <span className='me-4'>
        <i className={iconClass + " bi fs-20"}></i>
      </span> */}
      <div>
        <h6 className='syne-font'>{title}</h6>
        <p>{description}</p>
      </div>
    </div>
  </NavDropdown.Item>
  </Link>
);


function ResponsiveNavbar() {
    return (<Navbar bg="" data-bs-theme="light" className='d-flex align-items-center pt-3 mx-auto' expand="lg" style={{background: "transparent", maxWidth: "1200px"}}>
            <Link to="/home">
                <Navbar.Brand href="#" className='me-3' >
                    <Image src={logo} width={"85px"}/>
                </Navbar.Brand>
            </Link>

    {/* Hamburger icon */}
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

    {/* Navbar items */}
    <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto bg-alert w-100 d-flex align-items-md-center my-3 my-md-0">
            <Link to="/features"> <NavDropdown title="Moduli" id="multi-col-nav-dropdown" className=' d-flex mb-2 mb-md-0 mx-md-5 multi-col-drop'>
              {renderDropdownItem("bi-houses-fill", "Patrimonio", "Descrizione", "/moduli")}
              {renderDropdownItem("bi bi-key-fill", "Locazioni", "Descrizione")}
              {renderDropdownItem("bi-calculator-fill", "Calcoli", "Descrizione")}
              {renderDropdownItem("bi-cone-striped", "Manutenzioni", "Descrizione")}
              {renderDropdownItem("bi-cash-coin", "Valutazione", "Descrizione")}
              {renderDropdownItem("bi-graph-up-arrow", "Spese", "Descrizione")}

            </NavDropdown></Link>
            <Link to="/pacchetto"><Nav.Link href="#features" className=' mb-2 mb-md-0 me-md-5'>Pacchetti</Nav.Link></Link>
            <Link to="/tech"><Nav.Link href="#features" className=' mb-2 mb-md-0 me-md-5'>Tecnologie</Nav.Link></Link>
            <Link to="/attori"><Nav.Link href="#features" className=' mb-2 mb-md-0 me-md-5'>Attori</Nav.Link></Link>
            <Link to="/servizi"> <NavDropdown title="Servizi" id="basic-nav-dropdown" className=' mb-2 mb-md-0 me-md-5'>
              {renderDropdownItem("bi-houses-fill", "Enterprise", "Enterprise", "/servizi")}
        {/*       {renderDropdownItem("bi-geo-alt-fill", "Private", "Private", "/servizi")}
              {renderDropdownItem("bi-calculator-fill", "Express", "Express", "/servizi")} */}
              {renderDropdownItem("bi-calculator-fill", "Trova il tuo gestore", "Trova il tuo gestore", "/trova-gestore")}
            </NavDropdown></Link>
            <Link to="/contatti"><Nav.Link href="#features" className=' mb-2 mb-md-0 me-md-5'>Contatti</Nav.Link></Link>
{/*             <Link to="/"><Nav.Link href="#features" className=' mb-2 mb-md-0 me-md-3'>Blog</Nav.Link></Link>
 */}


            
            <div className='ms-md-auto d-md-flex align-items-md-center'>
{/*                 <Link to="/fornitore"><Nav.Link href="/fornitore" className='me-3  mb-2 mb-md-0'>Diventa fornitore</Nav.Link></Link>
 */}
                <Link to="/">
                <Button variant="outline-primary" className="me-2  mb-2 mb-md-0" style={{color: "#0F1B4C", borderColor: "#0F1B4C"}}>Area Riservata</Button>
                </Link>
                <Link to="/contatti">
                <Button variant="primary" className=' mb-2 mb-md-0' style={{background: navyColor, borderColor: "#0F1B4C"}}>Prova gratis</Button>
                </Link>
            </div>
        </Nav>
    </Navbar.Collapse>
</Navbar>
    )
}

export default ResponsiveNavbar;
