import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { BsPlusCircleFill } from "react-icons/bs";
import delete_icon from "../../../assets/images/delete.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ButtonRm from "../../ButtonRm";

import ImgFileCard from "./ImgFileCard";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import CustomBadge from "../../CustomBadge";

const categorieCatastali = [
	"A/1 - Abitazione di tipo signorile.",
	"A/2 - Abitazione di tipo civile.",
	"A/3 - Abitazioni di tipo economico.",
	"A/4 - Abitazioni di tipo popolari.",
	"A/5 - Abitazioni di tipo ultrapopolare.",
	"A/6 - Abitazione di tipo rurale.",
	"A/7 - Abitazione in villini.	",
	"A/8 - Abitazione in villa.",
	"A/9 - Castelli, palazzi di eminenti pregi artistici o storici.",
	"A/10 - Uffici e studi privati",
	"A/11 - Abitazioni o alloggi tipici dei luoghi.",
	"B/1 - Collegi e convitti, educandati, ricoveri, orfanotrofi, ospizi, conventi, seminari, caserme.",
	"B/2 - Case di cura e ospedali senza fine di lucro	",
	"B/3 - Prigioni e riformatori.",
	"B/4 - Uffici Pubblici.",
	"B/5 - Scuole e laboratori scientifici.",
	"B/6 - Biblioteche, pinacoteche, musei, gallerie, accademie che non hanno sede in edifici della categoria A/9.",
	"B/7 - Cappelle ed oratori non destinati all’esercizio pubblico del culto.",
	"B/8 - Magazzini sotterranei per depositi di derrate.",
	"C/1 - Negozi e Botteghe.",
	"C/2 - Magazzini e locali di Deposito.",
	"C/3 - Laboratori per arti e mestieri.",
	"C/4 - Fabbricati e locali per esercizi sportivi (senza fine di lucro).",
	"C/5 - Stabilimenti balneari e di acque curative (senza fine di lucro).",
	"C/6 - Stalle, scuderie, rimesse, autorimesse.",
	"C/7 - Tettoie chiuse od aperte.",
	"D/1 - Opifici.",
	"D/2 - Alberghi e pensioni (con fine di lucro).",
	"D/3 - Teatri, cinematografi, sale per concerti e spettacoli e simili (con fine di lucro).",
	"D/4 - Case di cura ed ospedali (con fine di lucro)",
	"D/5 - Istituto di credito, cambio e assicurazione (con fine di lucro).	",
	"D/6 - Fabbricati e locali per esercizi sportivi (con fine di lucro).",
	"D/7 - Fabbricati costruiti o adattati per le speciali esigenze di un’attività industriale e non suscettibili di destinazione diversa senza radicali trasformazioni.",
	"D/8 - Fabbricati costruiti o adattati per le speciali esigenze di un’attività commerciale e non suscettibili di destinazione diversa senza radicali trasformazioni.",
	"D/9 - Edifici galleggianti o sospesi assicurati a punti fissi del suolo, ponti privati soggetti a pedaggio.",
	"D/10 - Fabbricati per funzioni produttive connesse alle attività agricole.",
	"E/1 - Stazioni per servizi di trasporto, terrestri, marittimi ed aerei.",
	"E/2 - Ponti comunali e provinciali soggetti a pedaggio.",
	"E/3 - Costruzioni e fabbricati per speciali esigenze pubbliche.",
	"E/4 - Recinti chiusi per speciali esigenze pubbliche.",
	"E/5 - Fabbricati costituenti fortificazioni e loro dipendenze.",
	"E/6 - Fari, semafori, torri per rendere d’uso pubblico l’orologio comunale.",
	"E/7 - Fabbricati destinati all’esercizio pubblico dei culti.",
	"E/8 - Fabbricati e costruzioni nei cimiteri, esclusi i colombari, i sepolcri e le tombe di famiglia.",
	"E/9 - Edifici a destinazione particolare non compresi nelle categorie precedenti del gruppo E.",
	"F/1 - Area urbana.",
	"F/2 - Unità collabenti.",
	"F/3 - Unità in corso di costruzione.",
	"F/4 - Unità in corso di definizione.",
	"F/5 - Lastrico solare.",
	"F/6 -Fabbricato in attesa di dichiarazione."

]

const schema = yup.object().shape({
	category: yup.string().required("Seleziona la categoria"),
	typology: yup.string(),
	address: yup.string().required("Inserisci l’indirizzo"),
	houseNumber: yup.string().required("Inserisci il civico"),
	scale: yup.string(),
	floor: yup.string(),
	internal: yup.string(),
	cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	province: yup.string().required("Seleziona la provincia"),
	municipality: yup.string().required("Seleziona il comune"),
	name: yup.string().required("Inserisci un identificativo")
		
	});

const Cadastre = (props) => {
	const cadastre_type = ["Catasto tavolare", "Sistema del libro fondiario"];
	const property_type = [
		"Urbano",
		"Terreni",
		"Senza dati catastali",
		"Pertinenza a servizio di un'unità",
	];
	// const addParcelSubaltern = () => {
	//   let temp = [...props.formData.parcel];
	//   temp.push("a");
	//   let obj_parcel = { target: { name: "parcel", value: temp } };
	//   props.onChange(obj_parcel);

	//   temp = [...props.formData.subaltern];
	//   temp.push("a");
	//   let obj_subaltern = {
	//     target: { name: "subaltern", value: temp },
	//   };
	//   // props.onChange(obj_subaltern);

	//   // console.log("sss", props.formData.parcel);
	// };
	// // const deleteParcelSubaltern = (id) => {
	// //   let temp = [...parcel_subaltern];
	// //   temp.splice(id, 1);
	// //   setParcelSubaltern(temp);
	// // };
	const changeParcel = (index, e) => {
		let temp = [...props.formData.parcel];
		if (temp.length === index) temp.push({ parcel: "", subaltern: [] });
		temp[index].parcel = e.target.value;
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const changeSubaltern = (id1, id2, e) => {
		let temp = [...props.formData.parcel];
		temp[id1].subaltern[id2] = e.target.value;
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const addParcel = () => {
		let temp = [...props.formData.parcel];
		if (temp[temp.length - 1].parcel !== "")
			temp.push({ parcel: "", subaltern: [""] });
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const addSubaltern = () => {
		let temp = [...props.formData.parcel];
		if (
			temp[temp.length - 1].subaltern[
				temp[temp.length - 1].subaltern.length - 1
			] !== ""
		)
			temp[temp.length - 1].subaltern.push("");
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

	const deleteParcel = (id) => {
		let temp = [...props.formData.parcel];
		temp.splice(id, 1);
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};
	const deleteSubaltern = (id1, id2) => {
		let temp = [...props.formData.parcel];
		temp[id1].subaltern.splice(id2, 1);
		let obj = { target: { name: "parcel", value: temp } };
		props.onChange(obj);
	};

		//HANDLE ACCORDION COMPONENT
		const [activeKey, setActiveKey] = useState('');

		//VALIDAZIONE INPUT
		const { register, handleSubmit, formState: { errors }, reset } = useForm({
			resolver: yupResolver(schema),
		});
	
		const onSubmit = (data) => {
			// controlla se ci sono errori di validazione
			console.log(data)
			if (Object.keys(errors).length > 0) {
				console.log("Ci sono errori di validazione", errors);
				return;
			} 
			// azione da eseguire quando i dati sono tutti corretti
			props.forward()
		};

	return (
		<Form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="form-item-align-box d-block">
			<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Catasto</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Tipo di catasto 
										<OverlayTrigger
									placement="top"
									delay={{ show: 50, hide: 50 }}
									overlay={
										<Tooltip id="tooltip" className="exclamation-tooltip">
											Il catasto urbano e Terreni è quello preponderante in
											Italia. Il Catasto Tavolare fu in uso nell’impero
											austroungarico ed oggi è ancora vigente in Italia nelle
											province di Trieste, Gorizia, Trento, Bolzano e in alcuni
											comuni del norditalia.
										</Tooltip>
									}>
									<i className="fal fa-exclamation-circle ms-2"></i>
								</OverlayTrigger>
									</Form.Label>
									<Form.Select 
										{...register("cadastralType")}
										isInvalid={!!errors.cadastralType}
										value={props.formData.cadastralType}
										onChange={props.onChange} >
										{cadastre_type.map((menu, index) => {
									return (
										<option key={index} value={index}>
											{menu}
										</option>
									);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.category?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Tipologia catastale dell’immobile
									</Form.Label>
									<Form.Select 
										{...register("cadastralTypeProperty")}
										isInvalid={!!errors.cadastralType}
										value={props.formData.cadastralTypeProperty}
										onChange={props.onChange} >
										{property_type.map((menu, index) => {
											return (
												<option key={index} value={index}>
													{menu}
												</option>
											);
										})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.cadastralType?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
						<div className="input-checkbox mt-3">
					<input
						type="checkbox"
						name="portionOfCadastralUnit"
						checked={props.formData.portionOfCadastralUnit}
						onChange={(e) => {
							props.onChange(e);
						}}
					/>
					<div className="d-flex align-items-center ">
						<label className="f-label fs-16 d-flex align-items-center
me-2">
							L’unità inserita è una porzione di una unità immobiliare
							accatastata
						</label>
						<OverlayTrigger
							placement="top"
							delay={{ show: 50, hide: 50 }}
							overlay={
								<Tooltip id="tooltip" className="exclamation-tooltip">
									Per esempio: una camera in un appartamento, un’area di un
									capannone, un appezzamento di terreno ecc.
								</Tooltip>
							}>
							<i className="fal fa-exclamation-circle"></i>
						</OverlayTrigger>
					</div>
					</div>
					<div className="input-checkbox mt-3">
						<input
							name="historicProperty"
							checked={props.formData.historicProperty}
							type="checkbox"
							onChange={(e) => {
								props.onChange(e);
							}}
						/>
						<label className="f-label fs-16">Immobile Storico</label>
					</div>
					<div className="input-checkbox mt-3">
						<input
							name="unusable"
							checked={props.formData.unusable}
							type="checkbox"
							onChange={(e) => {
								props.onChange(e);
							}}
						/>
						<label className="f-label fs-16">Inagibile</label>
					</div>
					<div className="input-checkbox mt-3">
						<input
							name="haveSocialFee"
							checked={props.formData.haveSocialFee}
							type="checkbox"
							onChange={(e) => {
								props.onChange(e);
							}}
						/>
						<label className="f-label fs-16">Canone sociale</label>
					</div>
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="1" activeKey={activeKey}>Dati Catastali</CustomToggle>
					</Card.Header>
					<Accordion.Body>						
						<Row>
						<label className="f-label fs-16">
					I dati catastali sono necessari per la registrazione del contratto e
					altre comunicazioni (cessione di fabbricato, modello CDC ecc.)
				</label>
				<div className="mt-4 border-label-div">
					<label className="border-label">
						Questi dati sono obbligatori per la registrazione dal 1 Luglio 2010
					</label>
					<div className="d-flex flex-column">
						<div className="row">
							<div className="d-flex flex-column col-sm-3 pe-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Codice comune
								</label>
								<input
									name="municipalityCode"
									value={props.formData.municipalityCode}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/>
							</div>
							<div className="d-flex flex-column col-sm-3 pe-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Foglio
								</label>
								<input
									name="sheet"
									value={props.formData.sheet}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/>
							</div>
							<div className="d-flex flex-column col-sm-3 pe-2">
								<div className="d-flex align-items-center  mb-2">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
										Particella/Mappale
									</label>
									<OverlayTrigger
										placement="top"
										delay={{ show: 50, hide: 50 }}
										overlay={
											<Tooltip id="tooltip" className="exclamation-tooltip">
												Ci sono alcune unità immobiliari che a catasto sono
												definite nella sua interezza da più dati dati catastali
												per subalterno e/o particella. Per indicare al programma
												questa situazione basta cliccare sul pulsante aggiungi
												(+). Questa azione creerà dei nuovi campi per particella
												e subalterno. Nel caso ci sia un’unica particella con
												più subalterni, non è necessario inserire la stessa
												particella più volte.
											</Tooltip>
										}>
										<i className="fal fa-exclamation-circle"></i>
									</OverlayTrigger>
								</div>
								<div className="d-flex align-items-center justify-content-between">
									<div className="flex-auto">
										<input
											name="parcel"
											value={
												props.formData.parcel.length > 0
													? props.formData.parcel[0].parcel
													: ""
											}
											onChange={(e) => {
												changeParcel(0, e);
											}}
											type="text"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
										/>
									</div>
									<div style={{ padding: "3px" }}>
										<BsPlusCircleFill
											className="add-section-btn"
											onClick={addParcel}
										/>
									</div>
								</div>
							</div>
							<div className="d-flex flex-column col-sm-3">
								<div className="d-flex align-items-center  mb-2">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
										Subalterno
									</label>
									<OverlayTrigger
										placement="top"
										delay={{ show: 50, hide: 50 }}
										overlay={
											<Tooltip id="tooltip" className="exclamation-tooltip">
												Ci sono alcune unità immobiliari che a catasto sono
												definite nella sua interezza da più dati dati catastali
												per subalterno e/o particella. Per indicare al programma
												questa situazione basta cliccare sul pulsante aggiungi
												(+). Questa azione creerà dei nuovi campi per particella
												e subalterno. Nel caso ci sia un’unica particella con
												più subalterni, non è necessario inserire la stessa
												particella più volte.
											</Tooltip>
										}>
										<i className="fal fa-exclamation-circle"></i>
									</OverlayTrigger>
								</div>
								<div className="d-flex align-items-center justify-content-between">
									<div className="flex-auto">
										<input
											name="subaltern"
											value={
												props.formData.parcel.length > 0
													? props.formData.parcel[0].subaltern[0]
													: ""
											}
											onChange={(e) => {
												changeSubaltern(0, 0, e);
											}}
											type="text"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
										/>
									</div>
									<div style={{ padding: "3px" }}>
										<BsPlusCircleFill
											className="add-section-btn"
											onClick={addSubaltern}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					{props.formData.parcel.length > 0 &&
						props.formData.parcel[0].subaltern.length > 1 &&
						props.formData.parcel[0].subaltern.map((item, index) => {
							if (index !== 0) {
								return (
									<div key={index} className="row justify-content-end">
										<div className="d-flex flex-column col-sm-3">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Subalterno
											</label>
											<div className="d-flex align-items-center justify-content-between">
												<div className="flex-auto">
													<input
														name="subaltern"
														value={item}
														onChange={(e) => {
															changeSubaltern(0, index, e);
														}}
														type="text"
														className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
													/>
												</div>
												<div style={{ padding: "3px" }}>
													<div
														className="delete-icon-btn"
														onClick={() => {
															deleteSubaltern(0, index);
														}}>
														<img loading="lazy" src={delete_icon} alt="icon" />
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							}
						})}
					{props.formData.parcel.length > 1 &&
						props.formData.parcel.map((item, index) => {
							if (index !== 0) {
								return (
									<div key={index}>
										<div className="row justify-content-end">
											<div className="d-flex flex-column col-sm-3">
												<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
													Particella/Mappale
												</label>
												<div className="d-flex align-items-center justify-content-between">
													<div className="flex-auto">
														<input
															name="subaltern"
															value={item.parcel}
															onChange={(e) => {
																changeParcel(index, e);
															}}
															type="text"
															className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
														/>
													</div>
													<div style={{ padding: "3px" }}>
														<div
															className="delete-icon-btn"
															onClick={() => {
																deleteParcel(index);
															}}>
															<img loading="lazy" src={delete_icon} alt="icon" />
														</div>
													</div>
												</div>
											</div>
											<div className="d-flex flex-column col-sm-3">
												<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
													Subalterno
												</label>
												<div className="d-flex align-items-center justify-content-between">
													<div className="flex-auto">
														<input
															name="subaltern"
															value={item.subaltern[0]}
															onChange={(e) => {
																changeSubaltern(index, 0, e);
															}}
															type="text"
															className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
														/>
													</div>
													<div style={{ padding: "3px" }}>
														<div
															className="delete-icon-btn"
															onClick={() => {
																deleteSubaltern(index, 0);
															}}>
															<img loading="lazy" src={delete_icon} alt="icon" />
														</div>
													</div>
												</div>
											</div>
										</div>
										{item.subaltern.length > 1 &&
											item.subaltern.map((element, id) => {
												if (id !== 0) {
													return (
														<div>
															<div key={id} className="row justify-content-end">
																<div className="d-flex flex-column col-sm-3">
																	<label
																		htmlFor=""
																		className="f-label fs-16 d-flex align-items-center
mb-2">
																		Subalterno
																	</label>
																	<div className="d-flex align-items-center justify-content-between">
																		<div className="flex-auto">
																			<input
																				name="subaltern"
																				value={element}
																				onChange={(e) => {
																					changeSubaltern(index, id, e);
																				}}
																				type="text"
																				className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
																			/>
																		</div>
																		<div style={{ padding: "3px" }}>
																			<div
																				className="delete-icon-btn"
																				onClick={() => {
																					deleteSubaltern(index, id);
																				}}>
																				<img loading="lazy" src={delete_icon} alt="icon" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													);
												}
											})}
									</div>
								);
							}
						})}
					
				</div>
				<div className="mt-2" >
					<div className="d-flex flex-column">
						<div className="row align-items-end">
							<div className="row col-md-11">
								<div className="d-flex flex-column col-sm-3 pe-2">
									<div className="d-flex align-items-center  mb-2">
										<label className="f-label fs-16 d-flex align-items-center
me-2">Categoria</label>
										<OverlayTrigger
											placement="top"
											delay={{ show: 50, hide: 50 }}
											overlay={
												<Tooltip id="tooltip" className="exclamation-tooltip">
													Dato fondamentale per capire se l’unità può essere o
													no soggetta a cedolare secca
												</Tooltip>
											}>
											<i className="fal fa-exclamation-circle"></i>
										</OverlayTrigger>
									</div>
									
									<select
										name="categoryLandRegistry"
										value={props.formData.categoryLandRegistry}
										onChange={(e) => {
											props.onChange(e);
										}}
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									>
										{categorieCatastali.map(el => {
											return <option value={el}>{el}</option>
										})}
									</select>
								</div>
								<div className="d-flex flex-column col-sm-3 pe-2">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Classe
									</label>
									<input
										name="classLandRegistry"
										value={props.formData.classLandRegistry}
										onChange={(e) => {
											props.onChange(e);
										}}
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									/>
								</div>
								<div className="d-flex flex-column col-sm-3 pe-2">
									<div className="d-flex align-items-center  mb-2">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
											Consistenza
										</label>
										<OverlayTrigger
											placement="top"
											delay={{ show: 50, hide: 50 }}
											overlay={
												<Tooltip id="tooltip" className="exclamation-tooltip">
													Potete aggiungere vani, mq, mc, ecc.
												</Tooltip>
											}>
											<i className="fal fa-exclamation-circle"></i>
										</OverlayTrigger>
									</div>
									<input
										name="consistence"
										value={props.formData.consistence}
										onChange={(e) => {
											props.onChange(e);
										}}
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									/>
								</div>
								<div className="d-flex flex-column col-sm-3 pe-2">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 text-nowrap">
										Rendita Catastale
									</label>
									<input
										name="cadastralIncome"
										value={props.formData.cadastralIncome}
										onChange={(e) => {
											props.onChange(e);
										}}
										type="number"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mt-2">
					<div className="d-flex flex-column">
						<div className="row align-items-end">
							<div className="row col-md-11">
								<div className="d-flex flex-column col-sm-3 pe-2">
									<div className="d-flex align-items-center  mb-2">
										<label className="f-label fs-16 d-flex align-items-center
me-2">Particella denominatore</label>
									</div>
									<input
										name="denominatorParcel"
										onChange={(e) => {
											props.onChange(e);
										}}
										value={props.formData.denominatorParcel}
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									/>
								</div>
								<div className="d-flex flex-column col-sm-3 pe-2">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Indirizzo visura
									</label>
									<input
										name="surveyAddress"
										value={props.formData.surveyAddress}
										onChange={(e) => {
											props.onChange(e);
										}}
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									/>
								</div>
					
			
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-9 mt-4 border-label-div general-border">
					<label className="border-label general-color">
						Questi dati potrebbero non esserci
					</label>
					<div className="row align-items-end">
						<div className="row">
							<div className="d-flex flex-column col-sm-4 pe-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Sezione Urbana
								</label>
								<input
									name="urbanSection"
									value={props.formData.urbanSection}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/>
							</div>
							<div className="d-flex flex-column col-sm-4 pe-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Zona
								</label>
								<input
									name="zone"
									value={props.formData.zone}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/>
							</div>
							<div className="d-flex flex-column col-sm-4 pe-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Microzona
								</label>
								<input
									name="microZone"
									value={props.formData.microZone}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/>
							</div>
						</div>
					</div>
				</div>
						</Row>
						
												
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Altri dati</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<div className="row gy-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie catastale
										</label>
										<input
											type="number"
											name="cadastralMq"
											value={props.formData.cadastralMq}
											onChange={props.onChange}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie commerciale
										</label>
										<input
											type="number"
											name="commercialMq"
											value={props.formData.commercialMq}
											onChange={props.onChange}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie utile lorda
										</label>
										<input
											name="grossMq"
											value={props.formData.grossMq}
											onChange={props.onChange}
											type="number"
											
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie utile netta
										</label>
										<input
											type="number"
											name="netMq"
											value={props.formData.netMq}
											onChange={props.onChange}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Anno di fabbricazione
										</label>
										<input
											name="manufactureYear"
											value={props.formData.manufactureYear}
											onChange={props.onChange}
											type="number"
											
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Anno ultima ristrutturazione
										</label>
										<input
											type="number"
											name="renovationLastYear"
											value={props.formData.renovationLastYear}
											onChange={props.onChange}
										/>
									</div>
								</div>
							</div>
							<div className="input-checkbox mt-3">
								<input
									type="checkbox"
									name="freeImu"
									checked={props.formData.freeImu}
									onChange={props.onChange}
								/>
								<label className="f-label fs-16 d-flex align-items-center
me-2">Esente Imu </label>
							</div>
							<div className="row mt-3 gy-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Base imponibile IMU
										</label>
										<input
											type="number"
											name="imuTaxableBase"
											value={props.formData.imuTaxableBase}
											onChange={(e) => {
												props.onChange(e);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Base imponibile ICI
										</label>
										<input
											type="number"
											name="iciTaxableBase"
											value={props.formData.iciTaxableBase}
											onChange={(e) => {
												props.onChange(e);
											}}
											
										/>
									</div>
								</div>
							
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Aliquota IMU 
										</label>
										<input
											type="number"
											name="imuRate"
											value={props.formData.imuRate}
											onChange={(e) => {
												props.onChange(e);
											}}
											
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Aliquota TASI
										</label>
										<input
											type="number"
											name="iciRate"
											value={props.formData.iciRate}
											onChange={(e) => {
												props.onChange(e);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Vetustà (vani)
										</label>
										<input
											type="text"
											name="antiquity"
											value={props.formData.antiquity}
											onChange={(e) => {
												props.onChange(e);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Valore OMI
										</label>
										<input
											type="number"
											name="omiValue"
											value={props.formData.omiValue}
											onChange={(e) => {
												props.onChange(e);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Libero dal
										</label>
										<input
											type="date"
											name="freeFrom"
											value={props.formData.freeFrom}
											onChange={(e) => {
												props.onChange(e);
											}}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Valore di bilancio
										</label>
										<input
											type="number"
											name="bookValue"
											value={props.formData.bookValue}
											onChange={(e) => {
												props.onChange(e);
											}}
										/>
									</div>
								</div>
							</div>									
					</Accordion.Body>
				</Accordion.Item>

			{/* 	<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="3" activeKey={activeKey}>Dati catastali precedenti
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 50 }}
						overlay={
							<Tooltip id="tooltip" className="exclamation-tooltip">
								Quando effettui delle modifiche ai dati catastali attuali, creeremo uno storico contenente i dati catastali precedenti, oppure puoi inserire dei dati catastali precedenti manualmente
							</Tooltip>
						}>
						<i className="fal fa-exclamation-circle ms-2"></i>
					</OverlayTrigger>
					</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<div className="row py-4">
							<Col md={12}>
							<ButtonRm>Nuovo</ButtonRm> 
							</Col>
								
						</div>									
					</Accordion.Body>
				</Accordion.Item> */}

				
			</Accordion>
				
			</div>
			<div className="d-flex justify-content-end mt-5">						
				<button
					type="button"
					className="general-btn white-btn me-2 w-193"
					onClick={() => props.backward()}>
					Indietro
				</button>
				<button
					type="button"
					className="general-btn pink-btn w-193"
					onClick={() => props.forward()}>
					Avanti
				</button>
			</div>
		</Form>
	);
};

export default Cadastre;
