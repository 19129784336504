import React, { useState, useEffect } from "react";
import axios from "axios";
import BuildingCard from "./BuildingCard";
import CustomBadge from "../../CustomBadge";

const RelatedE = (props) => {
	const [data, setData] = useState({});

	const init = () => {
		setData({
			similiarBuildings: props.formData.similiarBuildings,
			similarMunicipality: props.formData.similarMunicipality,
			similarMq: props.formData.similarMq,
			similarLocals: props.formData.similarLocals,
			similarTypology: props.formData.similarTypology,
			similarState: props.formData.similarState,
			similarFloor: props.formData.similarFloor,
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (props.saveState===true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const similarStateType = [
		"Nuovo/in costruzione",
		"Ottimo/ristrutturato",
		"Buono/abitabile",
		"Da ristrutturare",
	];

	const [similarData, setSimilarData] = useState([]);

	const getData = async () => {
		try {
			const response = await axios.get(
				`https://anthill-service.onrender.com/get_similar_real_estate?type=${data.similarTypology}&surface=${data.similarMq}&room=${data.similarLocals}&place=${data.similarMunicipality}`
			);

			setSimilarData(response.data.data);
		} catch (err) {
			console.log(err);
		}
	};

	const addToReport = (item) => {
		var temp = [...data.similiarBuildings];
		temp.push(JSON.stringify(item));
		var ele = { target: { name: "similiarBuildings", value: temp } };
		onChange(ele);
		console.log(data.similiarBuildings);
	};

	const removeToReport = (item) => {
		var temp = [...data.similiarBuildings];
		var compareData = JSON.stringify(item);
		for (var i = 0; i < temp.length; i++) {
			if (compareData===temp[i]) {
				temp.splice(i, 1);
				break;
			}
		}
		var ele = { target: { name: "similiarBuildings", value: temp } };
		onChange(ele);
	};
	return (
		<div className="form-item-align-box d-block">
			<fieldset disabled={props.editMode ? false : true}>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">
							IMMOBILI IN VENDITA CORRELATI
						</h1>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Comune, località, indirizzo
							</label>
							<input
								type="text"
								name="similarMunicipality"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.similarMunicipality}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Mq
							</label>
							<input
								type="text"
								name="similarMq"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.similarMq}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								N°Locali
							</label>
							<input
								type="text"
								name="similarLocals"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.similarLocals}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Tipologia
							</label>
							<input
								type="text"
								name="similarTypology"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.similarTypology}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Stato Locativo
							</label>
							<select
								name="similarState"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
								value={data.similarState}
								onChange={onChange}>
								{similarStateType.map((menu, index) => {
									return (
										<option key={index} value={menu}>
											{menu}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Piano
							</label>
							<input
								type="text"
								name="similarFloor"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.similarFloor}
							/>
						</div>
					</div>
				</div>
				<div
					className="bag-btn w-160 general-btn mt-3"
					style={{ height: "48px", textAlign: "center", cursor: "pointer" }}
					onClick={getData}>
					Trova Immobili
				</div>
				<div className="mt-3">
					{similarData.length > 0
						? similarData.map((item, index) => {
								return (
									<div key={index}>
										<BuildingCard
											item={item}
											typology={data.similarTypology}
											addToReport={addToReport}
											removeToReport={removeToReport}
										/>
										<hr></hr>
									</div>
								);
						  })
						: ""}
				</div>
			</fieldset>
		</div>
	);
};

export default RelatedE;
