import React, { useState, useEffect } from "react";
import LeftPanel from "../../LeftPanel";
import { Link, useNavigate } from "react-router-dom";
import ReturnIcon from "../../../assets/images/returnIcon.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ModalForVerify from "./ModalForVerify";
import { useDispatch, connect } from "react-redux";
import { setShow } from "../../../actions/ModalForVerify";
import { toast } from "react-toastify";
import { isValidEmail, isValidPassword } from "../../../utility";
import axios from "axios";
import { BASE_URL } from "../../../api/api";
import { ToastContainer } from "react-toastify";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


//rootAccountID

const RegisterShort = ({ show }) => {

  const [roleValue, setRoleValue] = useState('');

  const [invitationCode, setInvitationCode] = useState('')
  const [invitationEmail, setInvitationEmail] = useState('')

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const role = params.get('role');
    //prendo codice di invito e email
    const invitation = params.get('invitationCode');
    const invitation_email = params.get('invitation_email');
    let newform = {...formData}
    if(role){
      setRoleValue(role); // Salva il valore del parametro "role" nello stato
      //setFormData({ ...formData, ['role']: role });
      newform = {...newform, ['roleUser']: role}
    }

    if(invitation && invitation_email){
      setInvitationCode(invitation ? invitation : '' ); 
      setInvitationEmail(invitation_email ? invitation_email : '')
      //setFormData({ ...formData, ['email']: invitation_email, ['confirmEmail']: invitation_email });

      newform = {...newform, ['email']: invitation_email, ['confirmEmail']: invitation_email, ['invitationCode']: invitation}


      //setFormData({ ...formData, ['email']: invitation_email });
      localStorage.setItem("invitationCode", JSON.stringify(invitation));
    }

    if(role){
      // Rimuovere il parametro 'role' dall'URL
      params.delete('role');
    }

    if(invitation && invitation_email){
      // Rimuovere il parametro 'invitation_code' dall'URL
      params.delete('invitationCode');

      // Rimuovere il parametro 'invitation_email' dall'URL
      params.delete('invitation_email');
    }

    setFormData({ ...newform });

      window.history.replaceState({}, document.title, url.pathname /* + '?' + params */);
  }, []);

  console.log({roleValue, invitationEmail, invitationCode})

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    name: "",
    rootAccountID: "",
    consentDateUpdate: "",
    privacy: false,
    cookie: false,
    termsConditions: false,
    source: "",
    browserInformation:"",
    formCode:"",
    privacyInformation: "",
    roleUser: "",
    invitationCode:""
  });


  const [passwordType, setPasswordType] = useState("password");
  const [readyLogin, setReadyLogin] = useState(false);

  const { email, confirmEmail, password, rootAccountID} = formData;

  //GESTISCI MESSAGGIO ERRORE INVITO ASSENTE PER PROPRIETARI- COLLABORATORI- INQUILINI
  const [ showInvitationError, setShowInvitationError ] = useState(false)
 
  useEffect(() => {
    if (
      !isValidEmail(email) ||
      !isValidPassword(password) ||
      !checkEmail(email, confirmEmail) ||
      !formData.privacy 
    ) {

      setReadyLogin(false);

      

    } else {
      setReadyLogin(true);
       //SE IL RUOLO E' DIVERSO DA MASTER O FORNITORE
       if (!(roleValue == 'MASTER' || roleValue == 'SUPPLIER')) {
        //SE INVITATION CODE NON E' DEFINITO
        if(!invitationCode){
          //IMPEDISCI LA REGISTRAZIONE
          setReadyLogin(false);
          //MOSTRA MESSAGGIO DI ERRORE
          setShowInvitationError(true)
        }
      }
    }
  }, [formData]);

  const checkEmail = (email, confirmEmail) => {
    if (email === confirmEmail) {
      return true;
    } else {
      return false;
    }
  };


//RUOLO DA INVIARE COME USER
  const confirm = () => {
    console.log("sono la funzione confirm")

    var request = {
      ...formData,
      email: formData.email,
      password: formData.password,
      invitationCode: invitationCode? invitationCode : "3Vcy2024ar",
      role: roleValue

    };

    //url: `${BASE_URL}api/v1/auth/signup?role=${roleValue}`,
    axios({
      url: `${BASE_URL}api/v1/auth/signup`,
      method: "POST",
      data: request,
    })
    .then((res) => {
        console.log(res, "ress");
        if (res.data.success === true) {
          console.log("sono dentro true");

          toast.success("I dati di registrazione inseriti sono corretti. Procedi con l'inserimento dell'OTP.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
          dispatch(setShow(true));
        } else {
          console.log("sono dentro else");

          let notValidCode = ""

          if(res.data.message == "The invitation code has expired. Please contact your manager"){
            notValidCode = "Il codice di invito è scaduto. Contatta il tuo gestore per ricevere un nuovo codice di invito"
          }

          toast.error(notValidCode? notValidCode : res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
    })
    .catch((err) => {
      console.log("sono dentro error");

      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
      });
    });
  };

  const onSubmit = async (e) => {
    console.log("sono la funzione submit")
    e.preventDefault();
    confirm();
    //dispatch(setShow(true));
  };

   // Le stringhe delle policy
   const termPolicy = "Termini e condizioni 01-09-2023";
   const privacyPolicy = "Privacy Policy 01-09-2023";
   const cookiePolicy = "Cookie Policy 01-09-2023";
 
   // Concatenazione delle stringhe delle policy
  const combinedPolicyInfo = `${termPolicy} / ${privacyPolicy} / ${cookiePolicy}`;


  const onChange = (e) => {
  const { name, checked, value } = e.target;


  if (name === "allPoliciesAccepted") {
    setFormData({
      ...formData,
      privacy: checked,
      cookie: checked,
      termsConditions: checked,
      consentDateUpdate: checked ? new Date().toISOString() : formData.consentDateUpdate,
      browserInformation: checked ? navigator.userAgent : formData.browserInformation,
      source: checked ? window.location.pathname : formData.source,
      privacyInformation: checked ? combinedPolicyInfo : formData.privacyInformation,
    });
  } else {
    setFormData({ ...formData, [name]: value });
  }
};


  const returnLogin = () => {
    navigate("/");
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };


  console.log(formData)

  return (
    <div
      className="d-flex recover-div"
      style={{ height: "100vh", background: "#fff" }}
    >
      <LeftPanel />
      <div className="m-auto rightPanel">
      <ToastContainer />

        <div className="return-login" onClick={() => returnLogin()}>
          <img
            src={ReturnIcon}
            alt="return"
            style={{ marginRight: "17.6px" }}
          ></img>
          Torna al login
        </div>

        <div style={{color: 'transparent'}}>
          {
            showInvitationError && toast.error("Non puoi procedere alla registrazione senza essere stato invitato da un gestore. Contatta il tuo gestore o il supporto per maggiori informazioni. ", {
              toastId: 12,
              position: toast.POSITION.TOP_RIGHT,
              autoClose: false,
              hideProgressBar: false,
            })
          }
        </div>
        <div className="recover-text">Crea il tuo account</div>
        <form className="form" onSubmit={onSubmit}>
          <div className="subtitle">Email</div>
          <input
            type="email"
            placeholder="Inserisci la tua email"
            name="email"
            disabled={invitationEmail ? true : false}

            value={email}
            onChange={onChange}
            className="form-control"
            style={{ fontFamily: "Mulish", height: "50px" }}
          />
          <div className="subtitle">Conferma Email</div>
          <input
            type="email"
            placeholder="Inserisci la tua email"
            name="confirmEmail"
            disabled={invitationEmail ? true : false}

            value={confirmEmail}
            onChange={onChange}
            className="form-control"
            style={{ fontFamily: "Mulish", height: "50px" }}
          />
          <div className="subtitle">Password 
            <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 300 }}
              overlay={
                <Tooltip className='tooltipContabilita'><p>
                La password deve contenere: <br/>
                -minimo 8 caratteri <br/>
                -almeno una lettera minuscola <br/>
                -almeno una lettera maiuscola <br/>
                -almeno un simbolo <br/>
                -almeno un numero <br/>
                </p>
                </Tooltip>
              }
              >
              <i class="bi bi-info-circle ms-2"></i>
              </OverlayTrigger>
                            </div>
          <div style={{ position: "relative" }}>
            <input
              type={passwordType}
              placeholder="Inserisci la tua password"
              name="password"
              id={(password.length > 3  && !isValidPassword(password)) ? "input_error" : ""}
              value={password}
              onChange={onChange}
              minLength="4"
              className="form-control"
              style={{
                fontFamily: "Mulish",
                height: "50px",
              }}
            />
            <div onClick={togglePassword} className="passwordHideToggle2">
              {passwordType === "password" ? <BsEye /> : <BsEyeSlash />}
            </div>
          </div>
        
          {/* facoltativo solo per fornitori, pr tutti gli altri obbligatorio */}
         {/*  {roleValue !== "MASTER" &&
          <>
            <div className="subtitle">Codice d'invito</div>
            <div style={{ position: "relative" }}>
              <input
                type='text'
                placeholder=""
                name="rootAccountID"
                value={rootAccountID}
                onChange={onChange}
                className="form-control"
                style={{
                  marginBottom: "",
                  fontFamily: "Mulish",
                  height: "50px",
                }}
              />
              
            </div>
          </>
          } */}
          <div className="d-flex align-item-start mt-3">
          <input
            type="checkbox"
            className="me-2"
            name="allPoliciesAccepted" // Nome specifico per il checkbox
            onChange={onChange} // Funzione da chiamare quando il checkbox viene selezionato
          />
          <label htmlFor="allPoliciesAccepted">Ho letto l'informativa privacy e cookie, e termini e condizioni e accetto</label>
          </div>

          <input
            type={readyLogin ? "submit" : "button"}
            id={readyLogin ? "" : "disable-login"}
            className="login-btn"
            value="Registrati"
          />
        </form>
        <div
          style={{
            textAlign: "center",
            margin: "auto",
            paddingTop: "30px",
          }}
        >
          <Link to="/" className="reset-password">
            Sei già registrato? Effettua il Login
          </Link>
        </div>
      </div>
       <ModalForVerify acrossSms={true} singupInfo={formData} /> 
    </div>
  );
};

const mapStateToProps = (state) => ({
  show: state.ModalForVerify.show,
});

export default connect(mapStateToProps, { setShow })(RegisterShort);
