export const marketRefType = ["libero mercato", "immobile in asta"];

export const bareOwnershipType = [
    "intera proprietà",
    "nuda proprietà da 0 a 20 anni (-95%)",
    "nuda proprietà da 21 a 30 anni (-90%)", 
    "nuda proprietà da 31 a 40 anni (-85%)",
    "nuda proprietà da 41 a 45 anni (-79%)",
    "nuda proprietà da 46 a 50 anni (-74%)",
    "nuda proprietà da 51 a 53 (-69%)",
    "nuda proprietà da 54 a 56 anni (-64%)",
    "nuda proprietà da 57 a 60 (-58%)",
    "nuda proprietà da 61 a 63 anni (-53%)",
    "nuda proprietà da 64 a 66 anni (-48%)",
    "nuda proprietà da 67 a 69 anni (-43%)",
    "nuda proprietà da 70 a 72 anni (-37%)",
    "nuda proprietà da 73 a 75 anni (-32%)",
    "nuda proprietà da 76 a 78 anni (-27%)",
    "nuda proprietà da 79 a 82 anni (-22%)",
    "nuda proprietà da 83 a 86 anni (-16%)",
    "nuda proprietà da 87 a 92 anni (-11%)",
    "nuda proprietà da 93 a 99 anni (-10%)",
]

export const rentalStateType = [
    "abitazioni libere", 
    "abitazione locato a breve termine (<=4 anni)", 
    "abitazione locate a lungo termine (>=4 anni)"
];

export const leaseProfitabilityType = [
    "non locato",
    "molto bassa",
    "bassa",
    "media",
    "alta",
    "molto alta",
];

export const wineryType = [
    "assente",
    "presente",
    "piccola",
    "normale",
    "grande",
    "doppia",
    "tripla",
    "quadrupla",
];

export const parkingAccessibilityType = [
    "-",
    "più facile della media di zona",
    "nella media di zona",
    "più difficoltoso della media di zona",
    "molto più difficoltoso della media di zona",
];

export const boxType = [
    "assente",
    "presente",
    "piccola",
    "normale",
    "grande",
    "doppia",
    "tripla",
    "quadrupla",
];

export const boxAccessibilityType = [
    "-",
    "accesso molto difficoltoso",
    "accesso difficoltoso",
    "accesso scomodo",
    "accesso normale",
    "accesso comodo",
];

export const boxLocationType = [
    "-",
    "nello stesso edificio",
    "limitrofo all'edificio", "distante all'edificio",
    "molto distante all'edificio",
];

export const possessionType = [
    "proprietà",
    "concessione"
]

export const condominialParkingSpaceType = ["presente", "assente"];

export const condominialParkingType = [
    "-",
    "posto auto coperto",
    "posto auto scoperto",
    "posto auto a rotazione coperto scoperto",
];

export const floorType = [
    "piano seminterrato",
    "piano terra",
    "piano rialzato",
    "1 piano",
    "2 piano",
    "3 piano",
    "4 piano",
    "5 piano",
    "6 piano",
    "7 piano",
    "8 piano",
    "9 piano",
    "10 piano",
    "oltre il 10 piano",
    "ultimo piano",
    "attico"
];

export  const ceilingHeightType = [
    "nella media di zona",
    "inferiori alla media di zona",
    "superiori alla media zona",
];

export const brightnessType = [
    "poco luminoso",
    "mediamente luminoso",
    "luminoso",
    "molto luminoso",
];

export const noisinessType = [
    "normale",
    "molto alta",
    "alta",
    "bassa",
    "molto bassa",
];

export const internalDistributionType = [
    "distribuzione inefficiente",
    "distribuzione poco efficiente",
    "efficiente",
    "distribuzione molto efficiente",
];

export const internalStateType = [
    "discreto",
    "completamente ristrutturare",
    "da ristrutturare",
    "parzialmente da ristrutturare",
    "buono stato, ottimo stato",
    "parzialmente ristrutturato",
    "ristrutturato",
    "nuovo costruzione",
    "fatiscente",
];

export const finishingQualityType = [
    "standard",
    "economiche",
    "ottime",
    "di pregio",
];

export const heatingTypeType = [
    "centralizzato con contabilizzatore",
    "assente",
    "centralizzato",
    "autonomo",
];

export const energeticClassType = [
    "A1",
    "A2",
    "A3",
    "A4",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
];

export const buildingLocationType = [
    "edificio indipendente (non confinante con altri)",
    "edificio a schiera (confinante con altri)",
    "comprensorio immobiliare (senza servizi in comune)",
    "complesso residenziale (con servizi e/o verde in comune)",
    "complesso residenziale con piscina",
];

export const buildingTypologyType = [
    "edificio signorile",
    "edificio parzialmente signorile",
    "edificio molto signorile",
    "edificio di pregio",
    "edificio straordinario",
    "edificio civile",
    "edificio storico",
    "edificio popolare"
];

export const buildingAgeType = [
    "1-5 anni",
    "5-20 anni",
    "20-40 anni",
    "oltre 40 anni",
];

export const internalLevelsType = [
    "disposto completamente su 1 livello",
    "disposto principalmente su 1 livello",
    "disposto su 2 livelli",
    "disposto su 3 livelli",
    "disposto su 4 livelli",
  ];

export const coveredMqRoomsRatioType = [
    "adeguato",
    "inadeguato- pochissimi vani rispetto ai mq coperti",
    "poco adeguato - pochi vani rispetto al mq coperti",
    "ottimo",
  ];

export  const coveredMqBathsRatioType = [
    "adeguato",
    "inadeguato - pochi bagni rispetto al nr vani totali",
    "ottimo",
  ];

export  const status_type = [
    "da ristrutturare totalmente",
    "da ristrutturare parzialmente",
    "buono/abitabile",
    "ristrutturato",
    "finemente ristrutturato",
    "nuovo/in costruzione",
]

export const building_status_type = [
    "da ristrutturare totalmente",
    "da ristrutturare parzialmente",
    "buono/abitabile",
    "ottimo",
    "nuovo/in costruzione",
];

export const office_position = [
    "posizione di elevato interesse (centrale)",
    "posizione intermedia",
    "posizione secondaria"
];

export const shopVisibility = [
    "particolarmente favorevole al commercio",
    "favorevole al commercio",
    "ordinaria",
    "poco favorevole al commercio"
]

export const capFloor = [
    "piano terra",
    "piano rialzato"
]

export const capAccess = [
    "accesso autoarticolato",
    "senza accesso autoarticolato"
]

export const shopFloor = [
    "seminterrato in giù",
    "piano terra",
    "piano rialzato (da 1 a 3)",
    "piano superiore al 3"
]

export const autorimessaType = [
    "fuori autorimessa",
    "dentro l'autorimessa"
]

export const boxHeight = [
    "nella media",
    "sopra la media (>3mt)"
]

export const buildingTypologyTypeNegozi = [
    "edificio civile",
    "edificio economico",
    "edificio signorile",
    "edificio di pregio",
    "edificio direzionale",
    "centro commerciale",
    "piccolo centro commerciale",
    "edificio indipendente",
  ];

export const windowPositionType = [
    "fronte strada in linea",
    "fronte strada in posizione angolare",
    "fronte strada in posizione arretrata",
    "non fronte strada",
  ];

export const commercialVisibilityType = [
    "nella media di zona",
    "inferiore alla media di zona",
    "superiore alla media di zona",
  ];

export const boxTypeNegozi = ["presente", "assente"];

export const floorTypeBox = ["interrato", "piano terra", "2° interrato", "3° interrrato "];

export const floorMag = [
    "a piano terra o rialzato con accesso carrabile",
    "a piano terra o rialzato senza accesso carrabile",
    "seminterrato con accesso carrabile",
    "seminterrato accessibile solo da scale"
  ]

export const supportingStructureType = [
    "struttura in cemento armato",
    "struttura metallica",
    "struttura in muratura ordinaria",
  ];

export const mainLevelCeilingHeightType = [
    "standard 6/8 metri",
    "inferiore a 6 metri",
    "oltre 8 metri",
  ];

export const locationVisibilityType = [
    "strada intermedia",
    "strada secondaria",
    "strada principale",
  ];

export const entryType = [
    "esterno fronte strada (senza passo carrabile)",
    "interno all'edificio (senza passo carrabile)",
    "interno all'edificio (con passo carrabile)",
    "esterno fronte strada (con passo carrabile)",
];

export  const buildingType = [
    "edificio civile",
    "edificio economico",
    "edificio signorile",
    "edificio di pregio",
    "edificio direzionale",
    "centro commerciale",
    "piccolo centro commerciale",
    "edificio indipendente",
];
