import React, { useState, useEffect } from "react";
import "../../../assets/css/Bacheca.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Row, Col } from "react-bootstrap";

import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import SearchInput from "../../SearchInput";
import StatusCardGroup from "./StatusCardGroup";
import {CardAttività, ContainerCardBacheca, CardGestione, CardUnità, CardPersona, ButtonBacheca} from "./CardBacheca";

import emptyActivities from '../../../assets/images/empty-activities-bacheca.svg'
import emptyGestione from '../../../assets/images/empty-gestione-bacheca.svg'
import emptyUnità from '../../../assets/images/empty-unità-bacheca.svg'
import emptyPersona from '../../../assets/images/empty-persona-bacheca.svg'

import api, { unit_endpoint, activity_endpoint, real_estate_management_endpoint, owner_endpoint,conductor_endpoint, supplier_endpoint } from "../../../api/api";

import { useDispatch, useSelector } from "react-redux";
import { addGestione, fetchGestioni, removeGestione, editGestione } from "../../../actions/gestioni";

import {persone} from './data'

import {Link, useNavigate} from 'react-router-dom'

import SearchIllustration from "../../../assets/images/search-illustration.svg";

import EmptyStateContainer from "../../EmptyStateContainer";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { act } from "react-dom/test-utils";
import ContabilitaGest from "../Gestioni/ContabilitàGest";
import { preventContextMenu } from "@fullcalendar/core";
import { checkSupplier, checkUserRole, checkUserData, checkOwner } from "../../../utils/checkUserRole";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

export default function Bacheca () {
    const [searchTerm, setSearchTerm] = useState("");

    //HANDLE USERS

    const [users, setUsers] = useState({
        owners: [],
        conductors: [],
        suppliers:[]
    })

    async function getOwners(){
    try {
        const response = await api().get(`${owner_endpoint}`);
        if (response.data.content) {
            setUsers(prev => {
                return {
                    ...prev,
                    owners:response.data.content
                }
            })
        }
    } catch (error) {
        console.log(error);
    }
    }

    async function getSuppliers(){
    try {
        const response = await api().get(`${supplier_endpoint}`);
        if (response.data.content) {
            setUsers(prev => {
                return {
                    ...prev,
                    suppliers:response.data.content
                }
            })
        }
    } catch (error) {
        console.log(error);
    }
    }

    async function getConductors(){
    try {
        const response = await api().get(`${conductor_endpoint}`);
        if (response.data.content) {
            setUsers(prev => {
                return {
                    ...prev,
                    conductors:response.data.content
                }
            })
        }
    } catch (error) {
        console.log(error);
    }
    }

    useEffect(() => {
    getOwners()
    getConductors()
    getSuppliers()
    }, [])


    console.log(users)


    /*get units*/
    const [units, setUnits] = useState([]);

    console.log(units)

    async function getUnits() {
    try {
        const response = await api().get(`${real_estate_management_endpoint}`);
        if (response.data) {
        setUnits(response.data.content);
        }
    } catch (err) {
        console.log(err);
    }
    }

    /* NUMBER UNITS CARD TO SHOW IN CAROUSEL */
    let cardsUnitCarousel 

    if (units.length == 1) {
        cardsUnitCarousel = 1
    } else if (units.length == 2){
        cardsUnitCarousel = 2
    } else {
        cardsUnitCarousel = 3
    }

    /* NUMBER UNITS CARD TO SHOW IN CAROUSEL */

    let cardsPeopleCarousel 

    if (users.owners.length + users.conductors.length + users.suppliers.length == 1) {
        cardsPeopleCarousel = 1
    } else if (users.owners.length + users.conductors.length + users.suppliers.length == 2){
        cardsPeopleCarousel = 2
    } else {
        cardsPeopleCarousel = 3
    }

    //define date
    let options = {'weekday': 'long', 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'};
    let today = new Date().toLocaleString('it-IT', options)
    let newDate =  today.charAt(0).toUpperCase() + today.substring(1)

    useEffect(() => {
    getUnits();
    }, []);

    //Settings for carousels Unit & Persone
    let settingsUnit = {
        dots:true,
        arrows: true,
        speed: 500,
        slidesToShow: cardsUnitCarousel ,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 650,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                slidesToShow: 2,
                }
            },
        ]
    };

    //Settings for carousels  Persone
    let settings = {
        dots:true,
        arrows: true,
        speed: 500,
        slidesToShow: cardsPeopleCarousel,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 650,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                slidesToShow: 2,
                }
            },
        ]
    };

    const dispatch = useDispatch();


    /*get acitivities*/
    const [activities, setActivities] = useState([]);

    async function getActivities() {
        try {
        const response = await api().get(`${activity_endpoint}`);
        if (response.data) {
            setActivities(response.data.content);
        }
        } catch (err) {
        console.log(err);
        }
    }
    
    useEffect(() => {
        getActivities();
    }, []);

    /* get gestioni */
    const gestioni = useSelector((state) => state.gestioniReducer.gestioni);
    //get gestioni when this component render for the first time
    useEffect(() => {
        dispatch(fetchGestioni())
    }, []);

      //CHECK IF DATA REQUIRED ARE COMPILED
  const [dataRequired, setDataRequired] = useState(true);
  //Gestisce il modale
  const [showCheck, setShowCheck] = useState(false)

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await checkUserData();
              setDataRequired(response.isVerified);
              if (!response.isVerified) {
                setShowCheck(true);
            }
          } catch (error) {
              // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
              console.error('Errore durante il recupero dei dati utente:', error);
          }
      };

      fetchData();
  }, []);


    /*LIMITA NUMERO DI ELEMENTI VISIBILE*/

    const membersMix =  [...users.owners, ...users.conductors, ...users.suppliers]

    const limitAct = 4;
    const sliceEndAct = activities.length < limitAct ? activities.length : limitAct;

    const limitGest = 3;
    const sliceEndGest = gestioni.length < limitGest ? gestioni.length : limitGest;

    const limitUnit = 5;
    const sliceEndUnit = units.length < limitUnit ? units.length : limitUnit;

    const limitMember = 5;
    const sliceEndMemb = membersMix.length < limitMember ? membersMix.length : limitMember;





    const navigate = useNavigate()

    function createActivity() {
        navigate("/statoavanzamentolavori/attivita/nuova-attivita");
    }

    /* MODAL Riassunto contabilita' */
    const [showRc, setShowRc] = useState(false);
    const handleCloseRc = () => setShowRc(false);

    const activitiesWithType = activities.length > 0 ? activities.map(item => {
        return {
            ...item,
            tipo: "attività"
        }
    }) : []

    const unitsWithType = units.length > 0 ? units.map(item => {
        return {
            ...item,
            tipo: "immobile"
        }
    }) : []

    const gestioniWithType = gestioni.length > 0 ? gestioni.map(item => {
        return {
            ...item,
            tipo: "gestione"
        }
    }) : []

    const arrayMix = [...activitiesWithType, ...unitsWithType, ...gestioniWithType]

    //DERIVED LIST WITH ONLY THE ITEMS THAT FITS A RESEARCH
  const searchedList =
  arrayMix && arrayMix.length > 0
    ? arrayMix.filter((el) =>
        el.name?.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    : [];


    function handlePath(tipo, id){
        if(tipo && id){
            if(tipo == "attività"){
                navigate(`/statoavanzamentolavori/attivita/${id}`)
            } else if(tipo == "immobile"){
                navigate(`/statoavanzamentolavori/unitaestabili/${id}`)
            } else if (tipo == "gestione") {
                navigate(`/statoavanzamentolavori/gestioni/${id}`)
            }
        }
    }




      const hasElements = [users.owners, users.suppliers, users.conductors].some(arr => arr.length > 0);
    return (
        <>
        <Row>
            <Col>
                <TopHeading SmallHeading="empty"/>
                <h2 style={{ color:'transparent', height: 0}}>Bacheca</h2>

            </Col>
        </Row>

        <Row className="flex-column align-items-start mobile-container flex-md-row align-items-md-center mt-md-4 mobile-margin-top mx-1 margin-dashboard-0 mb-3 mb-md-0"
        >
            <Col>
                <p className="fs-14">{newDate}</p> {/* DATE GOES HERE formatted in this way: WeekDay, day month year */}
                <p className="fw-bold fs-24">Buongiorno {capitalizeFirstLetter(JSON.parse(localStorage.getItem('user'))?.name)} </p> {/*Replace "Mario" with the username */}
            </Col>
            <Col md={4} className="d-flex mt-3 mt-md-0">
                {checkUserRole() && <AddBtn tooltip="Nuova attività" className="ms-md-auto me-3 "  onClick={createActivity} dataRequired={dataRequired} />}

                <span className="ms-auto">
                    <SearchInput setSearch={setSearchTerm} />  
                </span>  
            </Col>
        </Row>

        {!searchTerm ?
            <Row className="dashboard-container">

                <Row className="pe-0">
                    <StatusCardGroup activities={activities}/>
                </Row>

                <Row className="mt-4 w-100 justify-content-between mx-auto">

                    <ContainerCardBacheca name="Attività assegnate">
                        {
                            activities?.length > 0 &&
                            activities.slice(0, sliceEndAct).map( activity => {
                                return <CardAttività 
                                            key={activity.id}
                                            name={activity.name}
                                            priority={activity.priority}
                                            id={activity.id}
                                            item={activity}
                                        />
                            })
                        }
                        {activities?.length > 0 &&
                        <Link to="/statoavanzamentolavori/attivita" className="text-uppercase fs-10 text-uppercase text-decoration-underline text-dark">Vedi Tutto</Link>
                        }
                        {
                            activities.length === 0 &&
                                <div className="w-100 text-center py-3">
                                    <img loading="lazy" src={emptyActivities} width="35%"/>
                                    <p className="fs-14 mt-3 opacity-50">Nessuna attività assegnata</p>
                                </div>
                            
                        }
                    </ContainerCardBacheca>

                    {(checkUserRole()) && 
                    <ContainerCardBacheca name="Gestioni">
                        {
                            gestioni?.length > 0 &&
                            gestioni.slice(0, sliceEndGest).map( gestione => {
                                return <CardGestione 
                                            key={gestione.id}
                                            name={gestione.name}
                                            author={gestione.userID}
                                            creation_date={gestione.cdat}
                                            id={gestione.id}
                                            setShowRc={setShowRc}
                                            handleCloseRc={handleCloseRc}
                                        />
                            })
                        }
                        {gestioni?.length > 0 &&
                        <Link to="/statoavanzamentolavori/gestioni" className="text-uppercase fs-10 text-uppercase text-decoration-underline text-dark">Vedi Tutto</Link>
                        }
                        {
                            gestioni.length === 0 &&
                                <div className="w-100 text-center py-3">
                                    <img loading="lazy" src={emptyGestione} width="35%"/>
                                    <p className="fs-14 mt-3 opacity-50">Nessuna Gestione creata</p>
                                </div>
                            
                        }
                    </ContainerCardBacheca>}
                
                    {checkOwner() && <ContainerCardBacheca name="Unità" direction="row" morePadding={true}>
                        {units?.length > 0 &&
                            <Slider {...settingsUnit} className="w-100 mt-3">
                            {units.slice(0, sliceEndUnit).map( unit => {
                                return <CardUnità
                                            key={unit.id}
                                            name={unit.name}
                                            image={unit.image}
                                            typology={unit.category}
                                            id={unit.id}
                                        />
                            })}
                            </Slider>
                        }
                        {
                            units.length === 0 &&
                                <div className="w-100 text-center py-3">
                                    <img loading="lazy" src={emptyUnità} width="35%"/>
                                    <p className="fs-14 mt-3 opacity-50">Nessuna unità</p>
                                </div>
                            
                        }
                    </ContainerCardBacheca> }

                    {!checkOwner() && <ContainerCardBacheca name="Unità" direction="row" morePadding={true}>
                        {units?.length > 0 &&
                            <Slider {...settingsUnit} className="w-100 mt-3">
                            {units.slice(0, sliceEndUnit).map( unit => {
                                return <CardUnità
                                            key={unit.id}
                                            name={unit.name}
                                            image={unit.image}
                                            typology={unit.category}
                                            id={unit.id}
                                        />
                            })}
                            </Slider>
                        }
                        {
                            units.length === 0 &&
                                <div className="w-100 text-center py-3">
                                    <img loading="lazy" src={emptyUnità} width="35%"/>
                                    <p className="fs-14 mt-3 opacity-50">Nessuna unità</p>
                                </div>
                            
                        }
                    </ContainerCardBacheca> }

                    <ContainerCardBacheca name="Persone" direction="row" morePadding={true}>
                        {hasElements  &&
                        <Slider {...settings} className="w-100  mt-3">
                        {membersMix.slice(0, sliceEndMemb).map( persona => {
                            console.log("dsdkncks", persona)
                            return <CardPersona
                                        id={persona.id}
                                        key={persona.id}
                                        name={persona.name}
                                        surname={persona.surname}
                                        role="proprietario"
                                    />
                        })}
                        </Slider>}
                        {
                            !hasElements &&
                                <div className="w-100 text-center py-3">
                                    <img loading="lazy" src={emptyPersona} width="35%"/>
                                    <p className="fs-14 mt-3 opacity-50">Nessuna Contatto Recente</p>
                                </div>
                            
                        } 
                    </ContainerCardBacheca> 

                    <ContabilitaGest showRc={showRc} handleCloseRc={handleCloseRc}/>

                </Row>

               {/*  <Row className="flex-wrap justify-content-between gx-0 gx-sm-3 mt-4">
                    <ButtonBacheca  onClick={() => navigate("/statoavanzamentolavori/impostazionichat/checklist")} isGestore={checkUserRole()}>
                        <i className="bi bi-card-checklist me-2"></i>
                            Nuovo Verbale
                    </ButtonBacheca>
                    {checkUserRole() && <ButtonBacheca onClick={() => navigate("/statoavanzamentolavori/impostazionichat/squadre")} isGestore={checkUserRole()}>
                        <i className="bi bi-people me-2"  ></i>
                            Nuova Squadra
                    </ButtonBacheca>}
                    <ButtonBacheca  onClick={() => navigate("/statoavanzamentolavori/impostazionichat/tag")} isGestore={checkUserRole()}>
                        <i className="bi bi-tags me-2"></i>
                            Nuovo Tag
                    </ButtonBacheca>
                    {<ButtonBacheca onClick={() => navigate("/statoavanzamentolavori/impostazionichat/contabilita")} isGestore={checkUserRole()}>
                        <i className="bi bi-cash-coin me-2"></i>
                            Nuova Contabilità
                    </ButtonBacheca>}
                </Row> */}
                </Row>:

            <Row className="dashboard-container">
                <Row className="position-relative">
                    {searchedList.length > 0 ? 
                    searchedList.map(item => {
                        return (
                            <div className='unitàCard d-flex justify-content-start align-items-center text-dark' onClick={() => handlePath(item.tipo, item.id)} style={{cursor:"pointer"}}>
                                <div>
                                    <p className='fs-12 text-uppercase opacity-50'>{item.tipo}</p>
                                    <p className='fw-bold fs-16'>{item.name}</p>
                                    <p className='fs-12'>Creato da user {item.userID} </p>
                                    
                                </div>
                            </div>
                        );
                    }) : 
                    <EmptyStateContainer
                        content="Nessuna corrispondenza trovata"
                        img={SearchIllustration}
                    />
                    }
                </Row>
            </Row>
        }
 
        </>
    )
}