import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { AiFillTool } from "react-icons/ai";

import { checkUserRole } from "../../../utils/checkUserRole";


import TopHeading from "../../TopHeading";
import ButtonRm from "../../ButtonRm";
import "../../../assets/css/AttivitàDetails.css";
import api, { activity_endpoint, unit_endpoint, BASE_URL, management_endpoint   } from "../../../api/api";
import { MapContainer, Marker, ImageOverlay, Rectangle } from "react-leaflet";
import emptyFolder from "../../../assets/images/empty-folder.png";
import L from "leaflet";

import { NewItem } from "../Chat/Participants";

import mimeTypes from "../../../allegatiType";

import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";

import axios from "axios";

import ExportButton from "../../Membri/Fornitori/ExportButton";

import CustomBadge from "../../CustomBadge";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

import Breadcrumb from 'react-bootstrap/Breadcrumb';


const translationMapping = {
  id:"Id",
  cdat: "Data di creazione",
  udat: "Ultima modifica",
  userID: "ID utente",
  name: "Identificativo",
  realEstateManagements: "Immobili della gestione",
  activities: 'Attività',
  address: "Indirizzo",
  unitName: 'Immobile di riferimento',
  author: "Creato da",
  priority: "Priorità",
  startDate: "Data inizio",
  expirationDate: "Data di scadenza",
  endDate: "Data fine lavori",
  typology: "Tipologia",
  status: 'Stato',
  assignee: 'Incaricato',
  members: 'Partecipanti',
  description: 'Descrizione',
  predicition: 'Previsione di spesa'
}

const AttivitàDetails = () => {
  const { activityId } = useParams();
  const [singleAttività, setSingleAttività] = useState({});
  const [show, setShow] = useState(false);
  const creationDate = singleAttività.cdat?.substr(0, 10).split("-").join("-");
  const startDate = singleAttività.startDate
    ?.substr(0, 10)
    .split("-")
    .join("-");
  const expDate = singleAttività.expirationDate
    ?.substr(0, 10)
    .split("-")
    .join("-");

  const navigate = useNavigate();

/*   const initialsMembers =
    singleAttività.members && singleAttività.members.length > 0
      ? singleAttività.members.map((member) => {
          return member
            .split(/\s/)
            .reduce((response, word) => (response += word.slice(0, 2)), "");
        })
      : [];
  const initialAssignee = singleAttività.assignee
    ?.split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 2)), ""); */

    const [currentPlanimetryId, setCurrentPlanimetryId] = useState(
      singleAttività.planimetries?.length > 0 ? singleAttività?.planimetries?.[0]?.id : null
    )
    const [currentPlanimetry, setCurrentPlanimetry] = useState(
      singleAttività?.planimetries?.length > 0 ? singleAttività.planimetries[0] : {}
    )
  

  function handleCurrentPlanimetryId(e) {
    setCurrentPlanimetryId(e.target.value);
  }

  const handleCurrentPlanimetry = () => {
    let selectedPlan = singleAttività.planimetries?.find(
      (planimetry) => planimetry.id===currentPlanimetryId
    );
    setCurrentPlanimetry(selectedPlan);
  };

  useEffect(() => {
    if (currentPlanimetryId) {
      handleCurrentPlanimetry();
    }
  }, [currentPlanimetryId, singleAttività]);

  let priorità;

  if (singleAttività.priority==="medium") {
    priorità = "Media";
  }
  if (singleAttività.priority==="low") {
    priorità = "Bassa";
  }
  if (singleAttività.priority==="high") {
    priorità = "Alta";
  }

  //get single item
  async function getOne(id) {
    try {
      const response = await api().get(`${activity_endpoint}${id}`);
      if (response.data) {
        response.data.members = response.data.members?.length > 0 ? response.data.members.map(str => eval(`(${str})`)) : [];
        response.data.planimetries = response.data.planimetries.map(item => JSON.parse(item.replace(/'/g, '"')));
 /*        response.data.members = JSON.parse(
          response.data.members.replace(/'/g, '"')
        ); */
      }
      setSingleAttività(response.data);
      setCurrentPlanimetry(response.data.planimetries?.[0]);
      setCurrentPlanimetryId(response.data.planimetries?.[0]?.id);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (activityId) {
      getOne(activityId);
    }
  }, [activityId]);
  
  const rectangles = {
    ...currentPlanimetry?.markers,
    features: currentPlanimetry?.markers?.features
      ? currentPlanimetry.markers.features.filter(
          (item) => item.geometry.type==="Polygon"
        )
      : [],
  };

  const points = {
    ...currentPlanimetry?.markers,
    features: currentPlanimetry?.markers?.features
      ? currentPlanimetry.markers.features.filter(
          (item) => item.geometry.type==="Point"
        )
      : [],
  };

  const handleModal = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleDelete = async (id) => {
    try {
      await api().delete(`${activity_endpoint}${id}`);
      setShow(false);
      navigate("/statoavanzamentolavori/attivita");
    } catch (error) {
      console.log(error);
    }
  };

  const incaricato = (singleAttività.assignee && singleAttività.assignee.includes('{') ? JSON.parse(singleAttività.assignee .replace(/'/g, '"')).label : singleAttività.assignee );

  const [attachmentData, setAttachmentData] = useState([])

  useEffect(() => {
		setAttachmentData(singleAttività.attachments);
	}, [singleAttività.attachments]);

  console.log(attachmentData)

  const downloadFile = async (name) => {
    const fileUrl = `${BASE_URL}api/v1/activity/download/${activityId}/${name}`;
  
    const response = await axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token
      }`,
      "Content-Type": `application/json`,
      
    },
    });
  
    // ottieni l'estensione del file
    const fileExtension = name.split('.').pop();
  
    const mimeType = mimeTypes[fileExtension];
  
    // crea un nuovo Blob con il tipo MIME corretto
    const file = new Blob([response.data], { type: mimeType });
  
    // crea un URL per il Blob
    const fileURL = URL.createObjectURL(file);
  
    // scarica il file
    window.open(fileURL);
  };

  //prendi planimetrie


  const [unitPlanimetries, setUnitPlanimetries] = useState([])

  //FETCH PLANIMETRIES SOLO SE UNITA'
  async function getPlanimetries(id){
    try {
      const response = await api().get(`${unit_endpoint}${id}` )
      if (response.data) {
        setUnitPlanimetries(response.data.planimetry)
      }
    } catch (error) {
      console.log(error);
    }
  }

  //OTTIENI IMMAGE URL DI OGNI PLANIMETRIA, POI CHIAMA
  //CREATE OBJ FUNCTION CHE CREA UN OGGETTO PER OGNI IMG
  //E SETTA SINGLEATTIVITÀ.PLANIMETRIES
  const fetchImages = async (fileNames) => {
    const imageUrls = [];
  
    for (const fileName of fileNames) {
      const fileUrl = `${BASE_URL}api/v1/unit/download_planimetry/${singleAttività.unitID}/${fileName}`;
      try {
        const response = await axios.get(fileUrl, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token}`,
            "Content-Type": "application/json",
          },
        });


          //CODICE PER BLOB IMG
          const imageUrl = URL.createObjectURL(response.data);
      imageUrls.push(imageUrl);
  
          if (imageUrls.length === fileNames.length) {
            createPlanimetryObject(imageUrls);
          }
        
      } catch (error) {
        console.error(`Failed to fetch image: ${fileName}`, error);
        imageUrls.push(null); // or any fallback value if image fetching fails
  
        if (imageUrls.length === fileNames.length) {
          createPlanimetryObject(imageUrls);
        }
      }
    }
  };
  
  //SOSTITUISCI IMAGE CON LE IMMAGINI PRESE DA UNITA
  const createPlanimetryObject = (urls) => {
    const updatedPlanimetries = singleAttività.planimetries.map((planimetry, index) => {
      if (index < urls.length) {
        return {
          ...planimetry,
          image: urls[index],
        };
      }
      return planimetry;
    });
  
    setSingleAttività((prev) => ({
      ...prev,
      planimetries: updatedPlanimetries,
    }));
  };
  


  //IMAGE SRC DI OGNI PLANIMETRIE
  const [planimetriesImages, setPlanimetriesImages ] = useState([])
  
  useEffect(() => {
    const fetchPlanimetriesImages = async () => {
      if (unitPlanimetries.length > 0) {
        const images = await fetchImages(unitPlanimetries);
        setPlanimetriesImages(images);
      }
    };
  
    fetchPlanimetriesImages();
  }, [unitPlanimetries]);
  
  useEffect(() => {
    if(singleAttività.unitID){
      getPlanimetries(singleAttività.unitID)
    }
    
  }, [singleAttività.unitID])



  console.log(singleAttività)

/* gestione full screen */
const [fullscreen, setFullscreen] = useState(false);

const toggleFullscreen = () => {
  setFullscreen(!fullscreen);
};

 //controlla path
 const [gestioneId, setGestioneId] = useState(null);
 const [gestioneReference, setGestioneReference] = useState(null)

 const [sectionReference, setSectionReference] = useState(null)

 useEffect(() => {
    const url = window.location.pathname;
    const parts = url.split('/');
    if (parts.length >= 0) {
      setSectionReference(parts[2]);
      setGestioneId(parts[3])
    }

    console.log(parts)
 }, []);

     //get single gestione
     async function getOneGestione(id) {
      try {
          const response = await api().get(
              `${management_endpoint}${id}`
          );
          if(response.data) {
            setGestioneReference(response.data)
          }
      }
          catch(error) {
              console.log(error)
      }
  }

    useEffect(() => {
      getOneGestione(gestioneId)
    }, [gestioneId])

  return (
    <>
      <Row>
        <Col>
          <TopHeading SmallHeading="" back_modal={false} />
        </Col>
      </Row>

      <Row>
        <Col md={12} className="my-3 px-0 mx-2">
          <Breadcrumb>

                  {sectionReference == "gestioni" && 
                  <>
                    <Breadcrumb.Item 
                      className="settings-archive"
                      onClick={() => {
                          navigate('/statoavanzamentolavori/gestioni')
                      }}>
                      Gestioni
                    </Breadcrumb.Item>

                    <Breadcrumb.Item 
                        className="settings-archive"
                        onClick={() => {
                            navigate(`/statoavanzamentolavori/gestioni/${gestioneId}`)
                        }}
                    >
                        {gestioneReference?.name}
                    </Breadcrumb.Item>
                    
                    <Breadcrumb.Item 
                      className="settings-archive"
                      onClick={() => {
                          navigate(`/statoavanzamentolavori/gestioni/${gestioneId}/${singleAttività?.realEstateManagementID}`)
                      }}
                    >
                        {singleAttività?.unitName}
                    </Breadcrumb.Item>
                  </>
                  }

                  {sectionReference == "unitaestabili" && 
                  <>
                    <Breadcrumb.Item 
                        className="settings-archive"
                        onClick={() => {
                            navigate('/statoavanzamentolavori/unitaestabili')
                        }}>
                        Unità e Stabili
                    </Breadcrumb.Item>
                    
                    <Breadcrumb.Item 
                        className="settings-archive"
                        onClick={() => {
                            navigate(`/statoavanzamentolavori/unitaestabili/${singleAttività?.realEstateManagementID}`)
                        }}
                    >
                        {singleAttività?.unitName}
                    </Breadcrumb.Item>
                  </>
                  }

                  {sectionReference == "attivita" && 
                  <Breadcrumb.Item 
                      className="settings-archive"
                      onClick={() => {
                          navigate('/statoavanzamentolavori/attivita')
                      }}>
                      Attività
                  </Breadcrumb.Item>
                  }
              
                  <Breadcrumb.Item active={true} 
                      className="settings-archive"
                  >
                      {singleAttività?.name}
                  </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="mobile-container flex-column flex-md-row mt-md-4 justify-content-end">
        <Col >
          <h2 className="fw-bold">{singleAttività.name}</h2>
          <p className="fs-14 mt-2">
            Creato da {singleAttività.author}, il {creationDate}
          </p>
        </Col>
        <Col className="d-flex mt-2 mt-lg-0 justify-content-end align-items-center">
          <ExportButton data={singleAttività} mapping={translationMapping} isButton={true} classes="bg-white text-dark  rounded custom-export-sal"/>
            { checkUserRole() && <Link
              to={`/statoavanzamentolavori/attivita/${activityId}/edit`}
              className="attività-options me-2"
            >
              <i class="bi bi-pencil"></i>
            </Link>
            }
          { checkUserRole() &&
          <a className="attività-options" onClick={handleModal}>
            <i class="bi bi-trash3"></i>
          </a>
          }
        </Col>
      </Row>

      <Row>
        <div class="d-flex flex-column flex-lg-row activity-details g-5">
          {/* LEFT COLUMN */}
          <Col className={`${fullscreen ? "d-none" : "d-block pe-5 mb-4 mb-lg-0"}`}>
            {/* FIRST ROW */}
            <Row className="">
              <Col >
                <p className="text-uppercase fs-14 fw-bold mb-2">
                  Partecipanti
                </p>
                <div className="d-flex">
                  {/* {initialsMembers.length > 0 &&
                    initialsMembers.map((item) => (
                      <p className="gestione-assignee me-1" key={item}>
                        {item}
                      </p>
                    ))} */}
                    {
                    singleAttività?.members?.length > 0 &&
                    <div className='mb-3'>
                        {
                            singleAttività.members.map( item => <p className='table-font fs-18 fw-regular'>
                            <CustomBadge variant="custom-badge-aqua" margin="mx-0"          
                            > <span><i class="bi bi-person-fill me-2"></i></span>{capitalizeFirstLetter(item.label)}    
                            </CustomBadge></p>)
                        }
                        
                        
                    </div>
                    }
                </div>
              </Col>
              <Col>
                <p className="text-uppercase fs-14 fw-bold mb-2">Incaricato</p>
                <div className="d-flex mb-3">
                 {/*  <p className="gestione-assignee me-1">{initialAssignee}</p> */}
                 {incaricato && 
                 <p className='table-font fs-18 fw-regular'>
                 <CustomBadge variant="custom-badge-teal" margin="mx-0"          
                 > <span><i class="bi bi-person-fill me-2"></i></span>{incaricato}    
                 </CustomBadge></p>}
                </div>
              </Col>
            </Row>
            {/* SECOND ROW */}
            <Row className="d-flex flex-row mb-2">
              <Col>
                <p className="text-uppercase fs-14 fw-bolder">Informazioni</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p className="text-uppercase fs-12 opacity-75">
                  Immobile/stabile di riferimento
                </p>
                <p className="text-capitalize fs-16 ">
                <p className='table-font fs-18 fw-regular'>
                            <CustomBadge variant="custom-badge-gray" margin="mx-0"          
                            > {capitalizeFirstLetter(singleAttività.unitName)} 
                            </CustomBadge></p>
                </p>
              </Col>
            </Row>

            {/* THIRD ROW */}
            <Row className="d-flex flex-row ">
              <Col>
                <p className="text-uppercase fs-14 bold ">Date</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p className="text-uppercase fs-12 opacity-75">
                  Data di inizio stimata
                </p>
                <p className='table-font fs-18 fw-regular'>
                            <CustomBadge variant="custom-badge-gray" margin="mx-0"          
                            > {startDate} 
                            </CustomBadge></p>
              </Col>
              <Col>
                <p className="text-uppercase fs-12 opacity-75">
                  Data di scadenza stimata
                </p>
                <p className='table-font fs-18 fw-regular'>
                            <CustomBadge variant="custom-badge-gray" margin="mx-0"          
                            > {expDate} 
                            </CustomBadge></p>
              </Col>
            </Row>

            {/* FORTH ROW */}
            <Row className="d-flex flex-row mb-2">
              <Col>
                <p className="text-uppercase fs-14 bold ">Dettagli</p>
              </Col>
            </Row>
            <Row>
              {singleAttività.typology && <Col>
                <p className="text-uppercase fs-12 opacity-75">
                  Tipologia di Intervento
                </p>
              

                            <p className='table-font fs-18 fw-regular text-dark'>
        <CustomBadge variant="custom-badge-gray" margin="mx-0"> <span className='me-1'><AiFillTool />
          </span>{capitalizeFirstLetter(singleAttività.typology)}    
          </CustomBadge>
      </p>
              </Col>}
              {priorità && <Col>
                <p className="text-uppercase fs-12 opacity-75">Priorità</p>
                <p className="text-capitalize fs-16 "></p>
                <p className='table-font fs-18 fw-regular'>
        
                  <CustomBadge variant={priorità === "Alta" ? "custom-badge-red" : priorità === "Media" ? "custom-badge-yellow" : priorità === "Bassa" ? "custom-badge-aqua"  : "default"}  margin="mx-0" >{priorità} </CustomBadge>

        </p>
              </Col>}
            </Row>
            {singleAttività.description && <Row className="mb-5 mt-2">
              <Col className="mt-2">
                <p className="text-uppercase fs-12 opacity-75">Descrizione</p>
                <p className=" fs-16 ">
                  {capitalizeFirstLetter(singleAttività.description)}
                </p>
              </Col>
            </Row>}
            {/* FIFTH ROW */}
            {/* <Row className="d-flex flex-row mt-3">
              <Col>
                <p className="text-uppercase fs-14 bold mb-2">Chat</p>
              </Col>
            </Row>
            {singleAttività.chatGroup?.length>0 && <Row>
              <Col className="mt-2">
                <Link to={`/statoavanzamentolavori/chat/${singleAttività.chatGroup[0]?.id}`}>
                  <ButtonRm variant="tertiary">
                    <i class="bi bi-chat-left-dots me-2"></i>Vai alla Chat
                  </ButtonRm>
                </Link>
              </Col>
            </Row>} */}
          </Col>
          {/* RIGHT COLUMN */}
          <Col className="px-lg-3">
            {/* SECOND ROW */}
            <Row className="mb-2">
              <Col>
                <p className="text-uppercase fs-14 fw-bolder">Planimetrie</p>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Select
                  style={{ height: "50px" }}
                  value={currentPlanimetryId}
                  onChange={handleCurrentPlanimetryId}
                >
                  {singleAttività?.planimetries?.length > 0 &&
                    singleAttività.planimetries?.map((planimetry) => {
                      return (
                        <option key={planimetry.id} value={planimetry.id}>
                          {planimetry.name}
                        </option>
                      );
                    })}
                </Form.Select>

                <MapContainer
                  key={fullscreen}
                  crs={L.CRS.Simple}
                  center={[0, 0]}
                  bounds={[
                    [0, 0],
                    [512, 512],
                  ]}
                  className={`leaflet-container-newattività ${fullscreen ? "leaflet-container-newattività-fullscreen" : "" }`}
                  minZoom={fullscreen ? -2 : -3}
                  scrollWheelZoom={false}
                >
                                                    <FullscreenControl fullscreen={fullscreen} toggleFullscreen={toggleFullscreen} /> 

                  <ImageOverlay
                    bounds={[
                      [0, 0],
                      [512, 512],
                    ]}
                    url={
                      currentPlanimetry?.image
                        ? currentPlanimetry?.image
                        : "https://myben.it/wp-content/uploads/2019/11/placeholder-images-image_large.png"
                    }
                  />
                  
                  {rectangles.features?.length > 0 &&
                    rectangles.features.map((rect) => {
                      return (
                        <Rectangle
                          key={rect.id}
                          bounds={rect.geometry.coordinates}
                        ></Rectangle>
                      );
                    })}
                  {points.features?.length > 0 &&
                    points.features.map((point) => {
                      return (
                        <Marker
                          key={point.id}
                          position={point.geometry.coordinates}
                        ></Marker>
                      );
                    })}
                </MapContainer>
              </Col>
            </Row>
            {/* SECOND ROW */}
            <Row>
              <Col>
                <p className={`text-uppercase fs-14 fw-bolder mb-2 ${fullscreen ? "d-none" : "d-block" }`}>Allegati</p>
              </Col>
            </Row>
            <Row className={`${fullscreen ? "d-none" : "d-block" }`}>
              {attachmentData?.length == 0 &&<Col>
                <div className="attachment-container border p-5 d-flex justify-content-center align-items-center flex-column ">
                  <img loading="lazy" src={emptyFolder} className="img-fluid" width="60px" />
                  <p>Nessun allegato</p>
                </div>
              </Col>}
              {attachmentData?.length > 0 && (
						attachmentData.map((item, index) => {
							return (
								<FileCard
									key={index}
									name={item}
									//setFileName={setFileName}
									//deleteFile={deleteFile}
									id={index}
									//view_mode={editMode}
									download={downloadFile}
								/>
							);
						})
					)}

            </Row>
          </Col>
        </div>
      </Row>

      <Modal show={show} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={() => {
                handleDelete(activityId);
              }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => setShow(false)}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const FullscreenControl = (props) => {
  // const map = useMap();

  const positionControl = { top: "86%", right: "4px" }; // Adjust the position as needed
  const positionControlFull = { top: "90%", left: "4px", position: "absolute" };

  return (
    <div className="leaflet-control leaflet-control-custom" style={props.fullscreen ? positionControlFull : positionControl}>
      <ButtonRm onClick={props.toggleFullscreen}>
        {props.fullscreen ? <i class="bi bi-arrows-angle-contract"></i> : <i class="bi bi-arrows-angle-expand"></i>}
      </ButtonRm>
    </div>
  );
};

export default AttivitàDetails;
