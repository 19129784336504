import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import NumericInput from "react-numeric-input";
import CurrencyInput from "react-currency-input-field";
import PrintDownloadComponent from "../../PrintDownloadComponent";
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";
import Alert from 'react-bootstrap/Alert';
import axios from "axios";


import CustomToggle from "../../CustomToggle";
import ButtonRm from "../../ButtonRm";

import { Row, Col, Modal } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';

import Badge from 'react-bootstrap/Badge';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import CreateDocumenti from "../../Documenti/prima_nota/CreateDocumenti"
import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";

import CustomBadge from "../../CustomBadge";


const schema = yup.object().shape({
	contractType: yup.string(),
	registrationDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	agenziaEntrateOffice: yup.string(),
	serial: yup.string(),
	number: yup.string(),
	subNumber: yup.string(),
	percentageTaxConductor: yup.number("Inserisci un numero").max(100, "La percentuale inserita deve essere minore o uguale a 100").nullable().transform((_, val) => val ? Number(val) : null),
	percentageStampConductor: yup.number("Inserisci un numero").max(100, "La percentuale inserita deve essere minore o uguale a 100").nullable().transform((_, val) => val ? Number(val) : null),
	contractCopies: yup.number("Inserisci un numero").integer("Inserisci un numero intero").nullable().transform((_, val) => val ? Number(val) : null),
	copiesNumber:yup.number("Inserisci un numero").integer("Inserisci un numero intero").nullable().transform((_, val) => val ? Number(val) : null),
	privateScriptureNumber: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	receiptsNumber: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	mapsNumber: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	privateScriptureAmount: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	receiptsAmount:  yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	mapsAmount:  yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	condominiumFee: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null)


})


const RegistrationE = (props) => {
	const contract_type = [
		"Abitativo 4+4",
		"Abitativo 4+4 - Arredato",
		"Abitativo 4+4 - Arredato - Spese forfettarie",
		"Abitativo 4+4 - Arredato - Cedolare secca",
		"Abitativo 4+4 - Spese forfettarie",
		"Abitativo concordato 3+2",
		"Abitativo concordato 3+2 - Cedolare secca",
		"Abitativo concordato 3+2 - Spese forfettarie",
		"Autorimessa 1+1",
		"Autorimessa 4+4",
		"Comodato gratuito - Abitativo",
		"Fondo rustico agricolo",
		"Foresteria 4+4",
		"Negozio 6+6",
		"Negozio 6+6 - Spese Forfettarie",
		"Residenza Extra-Alberghiera",
		"Studenti Universitari - Arredato -Spese Forfettarie",
		"Transitorio -Arredato - Spese Forfettarie",
		"Transitorio -Arredato - Spese Forfettarie - Comuni con più di 10.000 abitanti",
		"Turistico",
		"Turistico - Agenzia Immobiliare - Con Acconto",
		"Ufficio 6+6",
		"Ufficio 6+6 - Spese forfettarie",
		"Studenti universitari - Arredato- S. forfettarie - Garanti",
	];
	const setToggleFullMode = () => {
		props.setFullMode(!props.full_mode);
	};
	const onChangeNumericInput = (value, identity) => {
		setData({ ...data, [identity]: value });
	};

	const [data, setData] = useState({});
	const [nextYearTaxesArray, setNextYearsTaxesArray] = useState([])


	const init = () => {
		setData({
			contractType: props.formData.contractType,
			registrationTaxFree: props.formData.registrationTaxFree,
			stampDutyFree: props.formData.stampDutyFree,
			mainBuilding: props.formData.mainBuilding,
			registered: props.formData.registered,
			registrationDate: props.formData.registrationDate?.substring(0, 10),
			agenziaEntrateOffice: props.formData.agenziaEntrateOffice,
			officeCode: props.formData.officeCode,
			serial: props.formData.serial,
			number: props.formData.number,
			subNumber: props.formData.subNumber,
			contractCopies: 0, //ADD TO API
			contractCode: props.formData.contractCode,
			registrationForAllDuration: props.formData.registrationForAllDuration,
			extensionForAllDuration: props.formData.extensionForAllDuration,
			percentageTaxConductor: props.formData.percentageTaxConductor,
			percentageStampConductor: props.formData.percentageStampConductor,
			registrationFromConductor: props.formData.registrationFromConductor,
			amountConductor: props.formData.amountConductor,
			amountOwner: props.formData.amountOwner,
			registrationTotalAmount: props.formData.registrationTotalAmount,
			registrationTax: props.formData.registrationTax,
			contractCopies: props.formData.contractCopies,
			copiesNumber: props.formData.copiesNumber,
			stampsCopiesNumber: props.formData.stampsCopiesNumber,
			privateScriptureNumber: props.formData.privateScriptureNumber,
			receiptsNumber: props.formData.receiptsNumber,
			mapsNumber: props.formData.mapsNumber,
			privateScriptureAmount: props.formData.privateScriptureAmount,
			receiptsAmount: props.formData.receiptsAmount,
			mapsAmount: props.formData.mapsAmount,
			stampTotalAmount: props.formData.stampTotalAmount,
			condominiumFee: props.formData.condominiumFee,
			paid: props.formData.paid,
			registrationContractExpenses: props.formData.registrationContractExpenses
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const [office, setOffice] = useState([]);
	console.log(data)
	async function getOffice() {
		try {
			const response = await axios.get(
				`https://anthill-service.onrender.com/getOfficeList`
			);
			if (response.data) {
				setOffice(response.data.data);
				console.log("office", response.data.data);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOffice();
	}, []);

	const onChange = (e) => {
		if (e.target.type == "checkbox") {
			setData({ ...data, [e.target.name]: e.target.checked });
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
		}
	};

	useEffect(() => {
		if (props.saveState === true) {
			props.update({...data, registrationContractExpenses: nextYearTaxesArray });
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const componentRef = useRef();
	const [printData, setPrintData] = useState({});
	useEffect(() => {
		setPrintData({
			"Tipologia di contratto": data.contractType,
			"OPZIONI DEL CONTRATTO": data.registrationTaxFree,
			"Esente di imposta di bollo": data.stampDutyFree,
			"Abitazione principale del conduttore": data.mainBuilding,
			Registrato: data.registered,
			"Data registrazione": data.registrationDate,
			"Ufficio dell’Agenzia delle Entrate": data.agenziaEntrateOffice,
			"Codice dell’ufficio al momento della registrazione": data.officeCode,
			Serie: data.serial,
			Numero: data.number,
			"Sotto numero": data.subNumber,
			"Codice identificativo del contratto": data.contractCode,
			"Registrazione per l’intera durata nei contratti pluriennali":
				data.registrationForAllDuration,
			"Proroga da registrare per l’intera durata nei contratti pluriennali":
				data.extensionForAllDuration,
			"% sulle imposte a carico del conduttore": data.percentageTaxConductor,
			"% sui bolli contratto a carico del conduttore":
				data.percentageStampConductor,
			"Registrazione fatta dai conduttori": data.registrationFromConductor,
			"Importo conduttori": data.amountConductor,
			"Importo locatori": data.amountOwner,
			"Importo Totale": data.registrationTotalAmount,
			"Imposta di registro": data.registrationTax,
			"Numero copie del contratto": data.contractCopies,
			"Numero di facciate del contratto": data.copiesNumber,
			"Numero di bolli per copia contratto": data.stampsCopiesNumber,
			"Scritture private, certificazioni e inventari":
				data.privateScriptureNumber,
			"Ricevute e quietanze": data.receiptsNumber,
			"NMappe, planimetrie e disegni": data.mapsNumber,
			"Per contratto e allegati riguardanti certificazioni, scritture...":
				data.privateScriptureAmount,
			"GGG Ricevute e quietanze": data.receiptsAmount,
			"Mappe, planimetrie e disegni": data.mapsAmount,
			"Totale imposta di bollo": data.stampTotalAmount,
		});
	}, [data]);

		//Stato temporaneo che gestisce stato pagamento bolli
	const [bolliStatus, setBolliStatus] = useState(false)

	function changeBolliStatus(){
		setBolliStatus(prev => !prev)
	}

	//Stato temporaneo che gestisce stato pagamento imposta 1° anno
	const [impostaStatus, setImpostaStatus] = useState(false)

	function changeImpostaStatus(){
		setImpostaStatus(prev => !prev)
	}

	//Stato temporaneo che gestisce cedolare secca
	const [cedolareSecca, setCedolareSecca] = useState(false)
	
	function handleCedolareSecca(){
		setCedolareSecca(prev => !prev)
	}

	//Stato temporaneo che gestisce soggetto Iva
	const [soggettoIva, setSoggettoIva] = useState(false)
	
	function handleSoggettoIva(){
		setSoggettoIva(prev => !prev)
	}

		//HANDLE ACCORDION
		const [activeKey, setActiveKey] = useState("")

		//VALIDAZIONE INPUT
		const { register, handleSubmit, formState: { errors }, reset } = useForm({
			resolver: yupResolver(schema),
		});
	
		const onSubmit = (data) => {
		console.log({data})
			// controlla se ci sono errori di validazione
			if (Object.keys(errors).length > 0) {
				console.log("Ci sono errori di validazione", errors);
				return;
			} 
			// azione da eseguire quando i dati sono tutti corretti
			props.forward()
		};
	
		const [show, setShow] = useState(false);
		const handleClose = () => setShow(false);
		const handleShow = () => setShow(true);
		const [fullscreen, setFullscreen] = useState(true);
	
		const canone = props.formData.currentMonthlyFee
		const percentualeImposteConduttore = data.percentageTaxConductor 
		const percentualeBolliConduttore = data.percentageStampConductor 
		const percentualeImposteLocatore = data.percentageTaxConductor && data.percentageTaxConductor <= 100? 100 - parseInt(data.percentageTaxConductor) : 0
		const percentualeBolliLocatore = data.percentageStampConductor && data.percentageStampConductor <= 100? 100 - parseInt(data.percentageStampConductor) : 0
	
	
		/* AUTOMATIZZAZIONE CALCOLO BOLLI */
		const numeroBolliPerCopiaContratto = data.copiesNumber? Math.ceil(props.formData.copiesNumber / 4) : 0
		const numeroBolliTotaleContratto = numeroBolliPerCopiaContratto ? numeroBolliPerCopiaContratto  * (data.contractCopies ?? 0) : 0
	
		const costoTotaleBolliContratto = numeroBolliTotaleContratto * props.formData.privateScriptureAmount
		const costoTotaleScritture = props.formData.privateScriptureNumber * props.formData.privateScriptureAmount 
		const costoTotaleRicevute = props.formData.receiptsNumber * props.formData.receiptsAmount
		const costoTotaleMappe = props.formData.mapsNumber * props.formData.mapsAmount
		const spesaTotaleBolli = costoTotaleBolliContratto + costoTotaleScritture + costoTotaleRicevute + costoTotaleMappe
		const costoBolliLocatore = (spesaTotaleBolli * percentualeBolliLocatore) / 100
		const costoBolliConduttore = (spesaTotaleBolli * percentualeBolliConduttore) / 100
	
	
		/* AUTOMATIZZAZIONE SPESE REGISTRO - RIVEDI CALCOLI - SEMPLIFICATA */
		const dataInizioLocazione = props.formData.startRentingDate?.substring(0,4)
	
		//1.RICAVA CANONE ANNUALE PER LE IMPOSTE DI REGISTRO 
		const canoneAnnuale = canone * 12
	
		//2.CAPIRE LA PERCENTUALE
		let percentualeApplicabile
		if(!soggettoIva ){
			percentualeApplicabile = 2
		} else {
			percentualeApplicabile = 1
		}
		
		//OUTPUT
		const impostaAnnuale = (canoneAnnuale * percentualeApplicabile) / 100
		const spesaRegistrazione = impostaAnnuale > 67 ? impostaAnnuale : 67
		const speseRegistrazioneLocatore = (spesaRegistrazione * percentualeImposteLocatore) / 100
		const speseRegistrazioneConduttore = (spesaRegistrazione * percentualeImposteConduttore) / 100
	
	
	
		/* CALCOL0 SPESE REGISTRO ANNI SUCCESSIVI */
	
		const [nextYearTaxData, setNextYearTaxData] = useState({
			year: null,
			amount: null,
			paid: false
		})
	
		const [nextYearTaxModal, setNextYearTaxModal] = useState(false)
		
		function showNYTModal (){
			setNextYearTaxModal(true)
		}
	
		function closewNYTModal (){
			setNextYearTaxModal(false)
			setNextYearTaxData({
				year: null,
				amount: null,
				paid: false
			})
		}
	
		function handleNYTData(e){
			const {value, name, checked, type} = e.target
			setNextYearTaxData(prev => {
				return {
					...prev,
					[name]: type == "checked" ? checked : value
					}
			})
		}
	
		function addNewTax(){
			setNextYearsTaxesArray(prev => {
				return [...prev, nextYearTaxData]
			})
			closewNYTModal()
		}
	
		function changeNYTPaidStatus(index){
			const newArray = nextYearTaxesArray.map((el, i) => {
				return index == i ?
				{...el, paid: !el.paid}:
				el
			})
			setNextYearsTaxesArray(newArray)
		}
	
		function deleteNYTRow(index){
			const newArray = nextYearTaxesArray.filter((item, i) => index != i)
			setNextYearsTaxesArray(newArray)	
		}
	
		//console.log(props.formData)

	
	 	useEffect(() => {
			setNextYearsTaxesArray(props.formData.registrationContractExpenses)
		}, []) 

		console.log(data.registrationContractExpenses, nextYearTaxesArray, props.formData.registrationContractExpenses)

	return (
		<div className="d-block w-100 mb-3">
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="form-item-align-box d-block py-3" ref={componentRef}>

			{!props.edit_mode && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}
					<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
				<fieldset disabled={props.edit_mode ? false : true}>
				
					<Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
						<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="0" activeKey={activeKey}>Contratto </CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Tipologia di contratto
									</Form.Label>
									<Form.Select 
										{...register("contractType")}
										name="contractType"
										value={data.contractType}
										onChange={onChange}
										isInvalid={!!errors.contractType}
									>
									{contract_type.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.managementType?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>

								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("registrationTaxFree")}
											name="registrationTaxFree"
											checked={data.registrationTaxFree}
											onChange={onChange}label="Esente di imposta di registro" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("stampDutyFree")}
											name="stampDutyFree"
											checked={data.stampDutyFree}
											onChange={onChange}label="Esente di imposta di bollo" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("mainBuilding")}
											name="mainBuilding"
											checked={data.mainBuilding}
											onChange={onChange}label="Abitazione principale del conduttore (rilevante al fine del
												calcolo dell’IMU)" />
									</Form.Group>
								</Col>
							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="1" activeKey={activeKey}>Dati registrazione contratto</CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("registered")}
											name="registered"
											checked={data.registered}
											onChange={onChange}
											label="Registrato" />
									</Form.Group>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Data registrazione
									</Form.Label>
									<Form.Control
									{...register("registrationDate")}
									type="date"
									value={data.registrationDate}
									onChange={onChange}
									isInvalid={!!errors.startManagementDate}
									>
									</Form.Control>
									<Form.Control.Feedback type="invalid" >
									{errors.registrationDate?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Ufficio dell’Agenzia delle Entrate
									</Form.Label>
									<Form.Select 
										{...register("agenziaEntrateOffice")}
										name="agenziaEntrateOffice"
										value={data.agenziaEntrateOfficee}
										onChange={onChange}
										isInvalid={!!errors.agenziaEntrateOffice}
									>
									{office?.map((item, index) => {
										return (
											<option key={index} value={`${item.office}-${item.code}`}>
												{item.office} -  {item.code}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.managementType?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>								
							</Row>
							<Row>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Serie
							</Form.Label>
							<Form.Control 
								{...register("serial")}
								type="text"
								name="serial"
								value={data.serial}
								onChange={onChange}
								isInvalid={!!errors.serial}

							/>
							<Form.Control.Feedback type="invalid" >
							{errors.serial?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Numero
									</Form.Label>
									<Form.Control 
										{...register("number")}
										type="text"
										name="number"
										value={data.number}
										onChange={onChange}
										isInvalid={!!errors.number}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.number?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Sotto numero
									</Form.Label>
									<Form.Control 
										{...register("subNumber")}
										type="text"
										name="subNumber"
										value={data.subNumber}
										onChange={onChange}
										isInvalid={!!errors.subNumber}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.subNumber?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="2" activeKey={activeKey}>Dati sul pagamento </CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>

								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("rregistrationForAllDuration")}
											name="registrationForAllDuration"
											checked={data.registrationForAllDuration}
											onChange={onChange}label="Registrazione per l’intera durata nei contratti pluriennali" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("extensionForAllDuration")}
											name="extensionForAllDuration"
											checked={data.extensionForAllDuration}
											onChange={onChange}
											label="Proroga da registrare per l’intera durata nei contratti
											pluriennali" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("registrationFromConductor")}
											name="registrationFromConductor"
											checked={data.registrationFromConductor}
											onChange={onChange}label="Registrazione fatta dai conduttori" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											checked={soggettoIva}
											onChange={handleSoggettoIva}
											label="Soggetto a IVA" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											checked={cedolareSecca}
											onChange={handleCedolareSecca}
											label="Cedolare secca"
											/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        % sulle imposte a carico del conduttore
                        </Form.Label>
                        <Form.Control
						{...register("percentageTaxConductor")}
						type="number"
						name="percentageTaxConductor"
						value={data.percentageTaxConductor}
						onChange={onChange}
						isInvalid={!!errors.percentageTaxConductor}
						max={100}
						min={0}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.percentageTaxConductor?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        % sulle imposte a carico del locatore
                        </Form.Label>
                        <Form.Control
						type="number"
						value={percentualeImposteLocatore}
						readOnly
	
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							   <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        % sui bolli contratto a carico del conduttore
                        </Form.Label>
                        <Form.Control
						{...register("percentageStampConductor")}
						type="number"
						name="percentageStampConductor"
									value={data.percentageStampConductor}
									onChange={onChange}
						isInvalid={!!errors.percentageStampConductor}
						max={100}
						min={0}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.percentageStampConductor?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        % sui bolli a carico del locatore
                        </Form.Label>
                        <Form.Control
						type="number"
						value={percentualeBolliLocatore}
						readOnly
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="3" activeKey={activeKey}>Spese registrazione contratto </CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
								<Col md="12">
								{!canone && <Alert variant="warning" className="d-flex ">
									<i class="bi bi-info-circle me-2"></i>
									Per automatizzare questi calcoli, assicurati di aver compilato i seguenti campi:
									<br/>
									-% sulle imposte a carico del conduttore (step REGISTRAZIONE)
									<br/>
									-% sulle imposte a carico del locatore (step REGISTRAZIONE)
									<br/>
									-Inizio locazione (step ANAGRAFICA)
									<br/>
									-Canone attuale (step ANAGRAFICA)
								</Alert>}
								{
									cedolareSecca && <Alert variant="warning" className="d-flex ">
									<i class="bi bi-info-circle me-2"></i>
									Quando l’opzione cedolare secca è spuntata, le imposte di registro non sono dovute.
								</Alert>
								}
								{cedolareSecca && <Table bordered striped responsive>
									<thead className="thead-light">
										<tr>
										<th className="border">Spese di registrazione</th>
										<th className="border">Importo conduttori</th>
										<th className="border">Importo locatori</th>
										{/* SE PAGATO, PULSANTE PRIMA NOTA */}
										</tr>
									</thead>
									<tbody  className="table-white">
										<tr className="border">
										<td className="border">€0</td>
										<td className="border">€0</td>
										<td className="border">€0</td>
										</tr>
										{/* ADD NEW ROW HERE */}
									</tbody>
									</Table> 
									}

									{!cedolareSecca && canone && dataInizioLocazione && 
									<Table bordered striped responsive>
									<thead className="thead-light">
										<tr>
										<th className="border">Anno</th>
										<th className="border">Spese di registrazione</th>
										<th className="border">Importo conduttori</th>
										<th className="border">Importo locatori</th>
										<th className="border">Pagato</th>
										{/* SE PAGATO, PULSANTE PRIMA NOTA */}
										</tr>
									</thead>
									<tbody  className="table-white">
										{
											nextYearTaxesArray?.length > 0 &&
											nextYearTaxesArray.map((item, index) => {
												const totalNYT = ((item.amount * 12) * percentualeApplicabile)/100
												const spesaRegistrazioneNYT = totalNYT > 67 ? totalNYT : 67
												const speseRegistrazioneLocatoreNYT = (spesaRegistrazioneNYT * percentualeImposteLocatore) / 100
												const speseRegistrazioneConduttoreNYT = (spesaRegistrazioneNYT * percentualeImposteConduttore) / 100
												return <tr key={index }className="border">
														<td className="border">{item.year}</td>
														<td className="border">€{spesaRegistrazioneNYT}</td>
														<td className="border">€{speseRegistrazioneConduttoreNYT}</td>
														<td className="border">€{speseRegistrazioneLocatoreNYT}</td>
														<td className="border">
															<Form.Group>
																<Form.Check type="checkbox"
																	checked={item.paid}
																	onChange={() => changeNYTPaidStatus(index)}
																	/>
															</Form.Group>
														</td>
														{
															item.paid &&
															<td className="border text-center"> 		
																<Badge bg="dark" onClick={handleShow}> 
																	Crea prima nota
																</Badge>
															</td>
														}
														<td className="border text-center"><i class="bi bi-x-circle" style={{cursor:"pointer"}} onClick={() => deleteNYTRow(index)}></i></td>	
												</tr>
											})
										}
									</tbody>
									</Table>
								}
								{!cedolareSecca && canone && dataInizioLocazione && 
									<label
									onClick={showNYTModal}
									className="black-link mt-2 text-decoration-underline"
									>
									<BsPlusCircleFill className="plus-icon" />
									Calcola spese di registrazioni anni successivi 
									</label>
								}
								</Col>
							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="4" activeKey={activeKey}>Imposte di Bollo</CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Numero copie del contratto
                        </Form.Label>
                        <Form.Control
						{...register("contractCopies")}
						type="number"
						name="contractCopies"
						value={data.contractCopies}
						isInvalid={!!errors.contractCopies}
						onChange={onChange}
						step={1}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.contractCopies?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Numero di facciate del contratto
                        </Form.Label>
                        <Form.Control
						type="number"
						{...register("copiesNumber")}
						name="copiesNumber"
						value={data.copiesNumber}
						isInvalid={!!errors.copiesNumber}
						onChange={onChange}
						step={1}
	
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" > {errors.copiesNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							   <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Numero di bolli per copia contratto
                        </Form.Label>
                        <Form.Control
						type="number"
						name="stampsCopiesNumber"
						value={numeroBolliPerCopiaContratto}
						disabled
						readOnly
                        >
                        </Form.Control>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Numero di bolli totali
                        </Form.Label>
                        <Form.Control
						type="number"
						value={numeroBolliTotaleContratto}
						readOnly
						disabled={true}
                        >
                        </Form.Control>
                    </Form.Group>
                   			</Col>
							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="5" activeKey={activeKey}>Bolli allegati</CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Scritture private, certificazioni e inventari
                        </Form.Label>
                        <Form.Control
						{...register("privateScriptureNumber")}
						type="number"
						name="privateScriptureNumber"
						value={data.privateScriptureNumber}
						isInvalid={!!errors.contractCopies}
						onChange={onChange}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.privateScriptureNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Ricevute e quietanze
                        </Form.Label>
                        <Form.Control
						type="number"
						{...register("receiptsNumber")}
						name="receiptsNumber"
						value={data.receiptsNumber}
						isInvalid={!!errors.receiptsNumber}
						onChange={onChange}
	
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" > {errors.receiptsNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							   <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Mappe, planimetrie e disegni
                        </Form.Label>
                        <Form.Control
						type="number"
						{...register("mapsNumber")}
						name="mapsNumber"
						value={data.mapsNumber}
						isInvalid={!!errors.mapsNumber}
						onChange={onChange}
	
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" > {errors.mapsNumber?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>

							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="6" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="6" activeKey={activeKey}>Importi delle marche da bollo in € </CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Per contratto e allegati riguardanti certificazioni,
						scritture...
                        </Form.Label>
                        <Form.Control
						{...register("privateScriptureAmount")}
						type="number"
						name="privateScriptureAmount"
						value={data.privateScriptureAmount}
						isInvalid={!!errors.privateScriptureAmount}
						onChange={onChange}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.privateScriptureAmount?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Ricevute e quietanze
                        </Form.Label>
                        <Form.Control
						type="number"
						{...register("receiptsAmount")}
						name="receiptsAmount"
						value={data.receiptsAmount}
						isInvalid={!!errors.receiptsAmount}
						onChange={onChange}
	
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" > {errors.receiptsAmount?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Mappe, planimetrie e disegni
                        </Form.Label>
                        <Form.Control
						type="number"
						{...register("mapsAmount")}
						name="mapsAmount"
						value={data.mapsAmount}
						isInvalid={!!errors.mapsAmount}
						onChange={onChange}
	
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" > {errors.mapsAmount?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   			</Col>

							</Row>
							<Row>
							<Col md="12">
								<Table bordered striped responsive>
									<thead className="thead-light">
										<tr>
										<th className="border">Totale Bolli</th>
										<th className="border">Importo conduttori</th>
										<th className="border">Importo locatori</th>
										<th className="border">Pagato</th>
										{
											data.paid &&
											<td className="border"></td>
										}	
										{/* SE PAGATO, PULSANTE PRIMA NOTA */}
										</tr>
									</thead>
									<tbody  className="table-white">
										<tr className="border">
										<td className="border">€{spesaTotaleBolli}</td>
										<td className="border">€{costoBolliConduttore}</td>
										<td className="border">€{costoBolliLocatore}</td>
										<td className="border">
											<Form.Group>
												<Form.Check type="checkbox"
													name="paid"
													checked={data.paid}
													onChange={onChange}
													/>
											</Form.Group>
										</td>
										{
											data.paid &&
											<td className="border text-center"> <Badge bg="dark" onClick={handleShow
											}> Crea prima nota</Badge></td>
										}	
										</tr>
									</tbody>
									</Table>
								</Col>
							</Row>
							</Accordion.Body>
						</Accordion.Item>

						<Accordion.Item eventKey="7" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="7" activeKey={activeKey}>Riassunto contabilità </CustomToggle>
							</Card.Header>
							<Accordion.Body>
							<Row>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Canone
                        </Form.Label>
                        <Form.Control
						type="number"
						defaultValue={canone}
						readOnly
						disabled	
                        >
                        </Form.Control>
                    </Form.Group>
                   			</Col>
							   <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Spese Registrazione
                        </Form.Label>
                        <Form.Control
						type="number"
						value={cedolareSecca ? 0 : spesaRegistrazione}
						readOnly
						disabled	
                        />
						</Form.Group>
						</Col>

							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                       Imposte di Bollo
                        </Form.Label>
                        <Form.Control
						type="number"
						value={spesaTotaleBolli}
						readOnly
						disabled	
                        >
                        </Form.Control>
                    </Form.Group>
                   			</Col>
							<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Spese condominiali previsionali (annuale)
                        </Form.Label>
                        <Form.Control
						{...register("condominiumFee")}
						type="number"
						name="condominiumFee"
						value={data.condominiumFee}
						onChange={onChange}
						isInvalid={!!errors.condominiumFee}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.condominiumFee?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                   		</Col>




							</Row>

							</Accordion.Body>
						</Accordion.Item>

						
					</Accordion>

			</fieldset>
			</div>

			</Form>
			
			<Modal
				show={show}
				fullscreen={fullscreen}
				className="right-full-pop"
				onHide={handleClose}
			>
				<Modal.Body className="p-4">
				<CreateDocumenti setShow={setShow} />
				</Modal.Body>
			</Modal>

			<Modal show={nextYearTaxModal} onHide={closewNYTModal} className="p-5" centered >
				<Modal.Header
					closeButton
					style={{ borderBottom: "none" }}
					className=" pt-md-5 px-md-5"
				>
					<Modal.Title>Calcola spese registrazione</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-3 px-md-5">
					<Form>
					<Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
						<Form.Label>Canone mensile</Form.Label>
						<input
						type="number"		
						onChange={handleNYTData}
						value={nextYearTaxData.amount}
						placeholder="Per esempio: 500"
						name="amount"
						/>
					</Form.Group>
					<Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
						<Form.Label>Anno di riferimento</Form.Label>
						<input
						type="number"		
						onChange={handleNYTData}
						value={nextYearTaxData.year}
						placeholder="Per esempio: 2024"
						name="year"
						/>
					</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer
					className="d-flex flex-column  px-md-5 pb-md-4"
					style={{ borderTop: "none" }}
				>
					<ButtonRm variant="primary" onClick={addNewTax} disabled={nextYearTaxData.amount && nextYearTaxData.year ? false : true}>
					Salva
					</ButtonRm>
					<ButtonRm variant="tertiary" onClick={closewNYTModal}>
					Annulla
					</ButtonRm>
				</Modal.Footer>
        </Modal>


		</div>

	);
};

export default RegistrationE;
