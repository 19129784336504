import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AddTypeModal from "../Tipologia/AddTypeModal";
import SupplierModal from "../Tipologia/SupplierModal";
import SubjectCard from "../Tipologia/SubjectCard";
import { BsPlusCircleFill } from "react-icons/bs";
import { formatDateFromString } from "../../../../utility";
import { PAYMENT_METHODS, MOVEMENT_TYPES, TIPOLOGIA, MODALITY_PRELIEVO, CONTI } from "../../../../constants";
import api, { supplier_endpoint } from "../../../../api/api";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ButtonRm from "../../../ButtonRm";
import CustomToggle from "../../../CustomToggle";
import CustomBadge from "../../../CustomBadge";


function PrelievoE(props) {
    const onChange = (e) => {
        props.setData({ ...props.data, [e.target.name]: e.target.value });
    };


return (
    <>
    <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="0" activeKey={props.activeKey}>Tipologia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="d-block">
                <div className="col-md-6">
                <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipologia
                    </label>
                    <div className="d-block">
                    <select
                        name="typology"
                        disabled={props.edit_mode ? false : true}
                        onChange={onChange}
                        value={props.data?.typology}
                    >
                        {TIPOLOGIA.map((menu, index) => {
                        return (
                            <option key={index} value={menu}>
                            {menu}
                            </option>
                        );
                        })}
                    </select>
                    </div>
                </div>
                </div>
            </div>				
            </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="7" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="7" activeKey={props.activeKey}>Prelievo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="d-block">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 ">
              Descrizione dell’operazione
            </label>
            <input
            type="text"
                className="border border-secondary rounded-3 border-opacity-50"
              name="operationDescription"
              value={props.data?.operationDescription}
              id=""
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="8" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="8" activeKey={props.activeKey}>Date</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="col-md-6">
        <div className="d-flex flex-column">
          <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
            Data dell’operazione
          </label>
          <div className="border border-secondary rounded-3 border-opacity-50">
            <input
                name="operationDate"
                className="border-0 fs-16 text-dark flex-grow-1"
                type="date"
                value={
                    props.data?.operationDate && formatDateFromString(props.data?.operationDate)
                }
                onChange={onChange}
                max="9999-12-31"
                disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="9" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="9" activeKey={props.activeKey}>Importo</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Importo
            </label>
            <input
              name="amount"
              type="text"
              onChange={onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
              value={props.data?.amount}
              disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Modalità
            </label>
            <select
              name="modality"
              value={props.data?.modality}
              onChange={onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              disabled={props.edit_mode ? false : true}
            >
              {MODALITY_PRELIEVO.map((menu, index) => {
                return (
                  <option key={index} value={index}>
                    {menu}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Da
            </label>
            <select
              name="fromAccount"
              onChange={onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.data?.fromAccount}
              disabled={props.edit_mode ? false : true}
            >
             {/*  {CONTI.map((conto) => {
                return <option> {conto}</option>;
              })} */}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              A
            </label>
            <select
              name="toAccount"
              onChange={onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.data?.toAccount}
              disabled={props.edit_mode ? false : true}
            >
              {/* {CONTI.map((conto) => {
                return <option> {conto}</option>;
              })} */}
            </select>
          </div>
        </div>
      </div>
      </Accordion.Body>
    </Accordion.Item>




    </>
  );
}
export default PrelievoE;
