import { useState, useEffect } from "react";
import TopHeading from "../TopHeading";
import AddBtn from "../AddBtn";
import EmptyStateContainer from "../EmptyStateContainer";

import { Link } from "react-router-dom";

import ScadenzarioCard from "./ScadenzarioCard";
import SearchIllustration from "../../assets/images/search-illustration.svg";

import filter from "../../assets/images/icon-sidebar/filter.svg"
import sort from "../../assets/images/icon-sidebar/sort.svg"

import emptyState from "../../assets/images/empty-scadenzario.svg";

import { Row, Col, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import SearchInput from "../SearchInput";

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, removeDeadline, editDeadline, fetchDeadline } from "../../actions/deadline";

import Dropzone from 'react-dropzone'
import transmigrationFunction from "../../utils/transmigrationFunctionDeadline";

import { deadline_endpoint } from "../../api/api";

import { checkUserData } from "../../utils/checkUserRole"
import ModalCheckUser from "../ModalCheckUser"

import { checkMaster, checkUserRole } from "../../utils/checkUserRole";
import Alert from "react-bootstrap/Alert";

import ButtonRm from "../ButtonRm";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TableSortLabel } from '@mui/material';

import CustomBadge from "../CustomBadge";

import { formattedTimestamp } from "../../utils/dateManipulation";

// Definisci le varianti dei badge
const badgeVariants = [
  'custom-badge-success',
  'custom-badge-danger',
  'custom-badge-alert',
  'custom-badge-info',
  'custom-badge-pagina',
  'custom-badge-gray',
  'custom-badge-red',
  'custom-badge-orange',
  'custom-badge-yellow',
  'custom-badge-lime',
  'custom-badge-green',
  'custom-badge-teal',
  'custom-badge-turquoise',
  'custom-badge-aqua',
  'custom-badge-blue',
  'custom-badge-ultramarine',
  'custom-badge-purple',
  'custom-badge-pink',
  'custom-badge-transparent',
];

 //ADD ALTRI CAMPI
 const headerNames = ["title", "section", 'startDate',"endDate", "every", "recurrenceType", "toDate", "note"];



// Funzione per ottenere una variante casuale
const getRandomBadgeVariant = () => {
const randomIndex = Math.floor(Math.random() * badgeVariants.length);
return badgeVariants[randomIndex];
};

const columnBadgeVariantMap = {};
headerNames.forEach((header, index) => {
columnBadgeVariantMap[header] = badgeVariants[index % badgeVariants.length];
});

function Scadenzario() {
  //GET DEADLINE LIST FROM REDUX
  const deadlineList = useSelector((state) => state.deadlineReducer?.deadline);

  const dispatch = useDispatch();

  //get deadline when this component render for the first time
  useEffect(() => {
    dispatch(fetchDeadline())
  }, []);

  const navigate = useNavigate();

  function createEvent() {
    navigate("/alert/nuovo-evento");
  }

  function fetch(){
    dispatch(fetchDeadline())
  }

  //FILTER AND SEARCH

  const [selectedSections, setSelectedSections] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [elements, setElements] = useState(deadlineList);
  const [hideCompleted, setHideCompleted] = useState(false)


  //FILTRO SEZIONE
  /* const handleSectionChange = (section) => {
    const newSelectedSections = [...selectedSections];
    const sectionIndex = newSelectedSections.indexOf(section);
    if (sectionIndex === -1) {
      newSelectedSections.push(section);
    } else {
      newSelectedSections.splice(sectionIndex, 1);
    }
    setSelectedSections(newSelectedSections);
    setElements(filterElements(newSelectedSections, filterStartDate, filterEndDate, searchTerm));
  }; */

  //FILTRO INTERVALLO
  function handleStartDateChange(event) {
    setFilterStartDate(event.target.value);
    //setElements(filterElements(selectedSections, filterStartDate, filterEndDate, searchTerm));
  }

  function handleEndDateChange(event) {
    setFilterEndDate(event.target.value);
    //setElements(filterElements(selectedSections, filterStartDate, filterEndDate, searchTerm));
  }

  //FILTRO COMPLETI
  function handleHideCompleted() {
    setElements(filterElements(selectedSections, filterStartDate, filterEndDate, searchTerm));
  }

  useEffect(() => {
    handleHideCompleted()
  }, [hideCompleted])



  const clearFilter = () => {
    setSelectedSections([]);
    setFilterEndDate("");
    setFilterStartDate("");
    setSearchTerm('');
    setElements(deadlineList);
    setHideCompleted(false)
  };


  const [sortOption, setSortOption] = useState("")

  const clearSort = () => {
    setSortOption("")
  };

     //FILTRO sort
     function handleSort(option) {
      setSortOption(option)
    }

    useEffect(() => {
      setElements(filterElements(selectedSections, filterStartDate, filterEndDate, searchTerm));

    }, [deadlineList])

    useEffect(() => {
      setElements(filterElements(selectedSections, filterStartDate, filterEndDate, searchTerm));
    }, [sortOption])

    useEffect(() => {
      if(filterEndDate && filterStartDate){
        setElements(filterElements(selectedSections, filterStartDate, filterEndDate, searchTerm))
      }
    }, [filterEndDate, filterStartDate])


  const filterElements = (sections, startDate, endDate, searchTerm) => {

    // Aggiungi una nuova proprietà "isDoneLast" a ciascun elemento in base al valore della proprietà "isDone"
  const elementsWithIsDoneLast = deadlineList.map((element) => {
    return { ...element, isDoneLast: element.isDone ? 1 : 0 };
  });

  let filteredElements = elementsWithIsDoneLast;

    if (sections.length > 0) {
      filteredElements = filteredElements.filter((element) => sections.includes(element.section));
    }
     // Filter by date range
    if (startDate && endDate) {
      filteredElements = filteredElements.filter((element) => {
        const elementStartDate = new Date(element.startDate);
        const elementEndDate = new Date(element.startDate)
        //EVENTO DATA DAL 12/4 AL 16/4
        //SE START DATE >= 12/4
        //SE END DATE E' <= 16/4

        return (elementStartDate - (new Date(startDate)) > -1) 
          && ((elementEndDate - (new Date(endDate)) < 1))
      });
    }

    if (searchTerm !== '') {
      filteredElements = filteredElements.filter((element) => element.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    if (hideCompleted){
      filteredElements = hideCompleted ? filteredElements.filter(element => element.isDone !== true) : filteredElements.filter(element => true)
    }



       // Ordina gli elementi in base alla proprietà selezionata
      if (sortOption === "title-desc") {
        filteredElements =  filteredElements.sort((a, b) => (b.title.localeCompare(a.title)));
      } else if (sortOption === "title-asc") {
        filteredElements =  filteredElements.sort((a, b) => (a.title.localeCompare(b.title)));
      } else if (sortOption === "date-asc") {
        filteredElements =  filteredElements.sort((a, b) => ((new Date(b.startDate)) - (new Date(a.startDate ))))
      } else if (sortOption === "date-desc") {
        filteredElements =  filteredElements.sort((a, b) => ((new Date(a.startDate)) - (new Date(b.startDate ))))
      } else {
        // Ordina gli elementi per prima cosa in base alla proprietà "isDoneLast"
        // Se due elementi hanno lo stesso valore di "isDoneLast", allora li ordina in base alla proprietà "cdat"
        filteredElements = filteredElements.sort((a, b) => {
          if (a.isDoneLast === b.isDoneLast) {
            return ((new Date(b.cdat)) - (new Date(a.cdat )))
          } else {
            return a.isDoneLast > b.isDoneLast ? 1 : -1;
          }
        });
      }
    

    return filteredElements;
    };

    useEffect(() => {
      clearFilter()
      clearSort()
    }, [])


    const mappingFromCompetitor1 = {
      "Nome" : "title",
      "Inizio" : "startDate",
      "Fine" : "endDate"
    }
    //multiview
    const [viewType, setViewType] = useState('card')

    const headerNames = ["title", "section", 'startDate',"endDate", "every", "recurrenceType", "toDate", "note"];


const translationMapping = {
  title: "Titolo",
  section: "Sezione di riferimento",
  startDate: "Data d'inizio",
  endDate:	"Data di fine",
  every:	"Ogni",
  recurrenceType:	"Tipo di ricorrenza",
  toDate:	"Fino al",
  note: "Note"
  //manca campo per giorni di preavviso => type: number
}


//COLLAPSE-EXPAND FILTER


  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isSectionOpen, setIsSectionOpen] = useState(false);
  const [statusSearch, setStatusSearch] = useState("");
  const [sectionSearch, setSectionSearch] = useState("");
  const [isPeriodOpen, setIsPeriodOpen] = useState(false);


  const handleSectionChange = (section) => {
    setSelectedSections(prev => 
      prev.includes(section) ? prev.filter(s => s !== section) : [...prev, section]
    );
  };

  const sections = ["SAL", "Unità e Stabili", "Affitti", "Scadenze Personali"];
  const filteredSections = sections.filter(s => s.toLowerCase().includes(sectionSearch.toLowerCase()));

  //CHECK IF DATA REQUIRED ARE COMPILED
  const [dataRequired, setDataRequired] = useState(true);
  //Gestisce il modale
  const [showCheck, setShowCheck] = useState(false)

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await checkUserData();
              setDataRequired(response.isVerified);
              if (!response.isVerified) {
                setShowCheck(true);
            }
          } catch (error) {
              // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
              console.error('Errore durante il recupero dei dati utente:', error);
          }
      };

      fetchData();
  }, []);

  /***/ 

  const [selectedItems, setSelectedItems] = useState([]);

	// Funzione per gestire la selezione multipla
	const handleSelection = (e, id) => {
		if(e){
			e.stopPropagation()
		}

		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
		} else {
			setSelectedItems([...selectedItems, id]);

		}
	};

	const handleSelectAll = () => {
		let selectedItems = elements.map((item, index) => item.id)
		setSelectedItems(selectedItems)
	}

	const handleDeselectAll = () => {
		setSelectedItems([])
	}

	// Funzione per eliminare gli stabili selezionati
	const deleteSelected = async () => {
		// Effettua l'operazione di eliminazione per ogni id selezionato
		for (const id of selectedItems) {
		try {
			dispatch(removeDeadline(id))
		} catch (err) {
			console.log(err);
		}
		}
	 dispatch(fetchDeadline())
		setSelectedItems([]);

	};
  

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//handle modal permessi
	const [showPermit, setShowPermit] = useState(false);
	const handleClosePermit = (e) => {
		setShowPermit(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShowPermit = () => setShowPermit(true);
	

	  //MI SERVE LISTA COLLABORATORI MASTER
	  const [members, setMembers] = useState([
		{
			id:1,
			name:"Ema",
			surname:"Mavi",
			email:"ema@gmail.com"
		},
		{
			id:2,
			name: 'Greta',
			surname: 'Pio',
			email: 'gre@gmail.com'
		}
	  ])
	
	  const handleCheckboxChange = (userId, action) => {
		//da gestire
	  };


    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
  
    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const sortedUnits = elements.sort((a, b) => {
        if (orderBy) {
      /* if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
          return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
      } */
      if (a[orderBy] && b[orderBy]) { 
          return order === 'asc'
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
      }
    }
      return 0;
    });


  return (
    <>
       <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="empty"
          />
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Scadenzario</h2>
          </Col>
          <Col className="d-flex justify-content-end ">
            <AddBtn
              tooltip="Nuovo evento"
              className="me-3 me-md-0 order-md-1"
              onClick={createEvent}
              dataRequired={dataRequired}
            />

          </Col>
        </Row>

      </div>




        {elements.length > 0  && (
          <Row className="dashboard-container">
            <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
        <SearchInput
              className="order-md-0"
              setSearch={(term) => {
                setSearchTerm(term);
                setElements(filterElements(selectedSections, filterStartDate, filterEndDate, term));
              }}
          />

				<div className="d-flex align-items-center ms-3">
        <div className="user-dropdown" onClick={() => setViewType(viewType == 'card'? 'table' : 'card')}>

              <div className="filter">
                <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
                  {viewType == 'table' && <i class="bi bi-table fs-25"></i>}
                  {viewType == 'card' && <i class="bi bi-view-list fs-25"></i>}
                </div>
          </div>
                      

        </div>

					<div className="user-dropdown">
					<div className="filter">                   
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
						<img loading="lazy" src={filter}/>
						</div>
            <div className="user-dropdown-content">
      <div className="fs-16 fw-bold">Filtra per</div>
      
      {/* Stato Section */}
      <div className="fs-14 mb-2" onClick={() => setIsStatusOpen(!isStatusOpen)}>
        STATO {isStatusOpen ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}
      </div>
      {isStatusOpen && (
        <div>
          <div className="mt-2">
            {/* Add checkbox items for Status section here */}
            <div className="input-checkbox">
              <input
                type="checkbox"
                checked={hideCompleted}
                onChange={() => { setHideCompleted(prev => !prev) }}
              />
              <label className="f-label fs-14 me-2">Nascondi Completati</label>
            </div>
          </div>
        </div>
      )}

      {/* Sezione di Riferimento Section */}
      <div className="fs-14  mb-2" onClick={() => setIsSectionOpen(!isSectionOpen)}>
        SEZIONE DI RIFERIMENTO {isSectionOpen ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}
      </div>
      {isSectionOpen && (
        <div>
          <input type="text" placeholder="Cerca in SEZIONE DI RIFERIMENTO" className="mb-1" value={sectionSearch} onChange={e => setSectionSearch(e.target.value)} />
          <div className="mt-2">
            {filteredSections.map((section, index) => (
              <div key={index} className="input-checkbox">
                <input
                  type="checkbox"
                  name={section}
                  checked={selectedSections.includes(section)}
                  onChange={() => handleSectionChange(section)}
                />
                <label className="f-label fs-14 me-2">{section}</label>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Periodo Section */}
      <div className="fs-14" onClick={() => setIsPeriodOpen(!isPeriodOpen)}>
        PERIODO {isPeriodOpen ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}
      </div>
      {isPeriodOpen && (
        <div className="my-2">
          <input type="date" value={filterStartDate} onChange={e => setFilterStartDate(e.target.value)} />
          <span> - </span>
          <input type="date" value={filterEndDate} onChange={e => setFilterEndDate(e.target.value)} />
        </div>
      )}

      <a className="text-decoration-underline text-dark text-center" onClick={() => { /* Clear Filters */ }}>Rimuovi filtri</a>
    </div>
					</div>
					</div>

					<div className="user-dropdown">
						<div className="sort d-flex">
				<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
					<img loading="lazy" src={sort}/>
				</div>
						</div>
						<div className="user-dropdown-content">
							<div className="fs-16 fw-bold">Ordina per</div>
              <div
                onClick={() => handleSort("title-desc")}
                className="mt-3 fs-14 sort-text"
                >
                Z-A
              </div>
              <div
                onClick={() => handleSort("title-asc")}
                className="fs-14 sort-text"
                >
                A-Z
              </div>
              <div
                className="fs-14 sort-text"
                onClick={() => handleSort("date-desc")}
                >
                Data ascendente
              </div>
              <div
                className="fs-14 mb-1 sort-text"
                onClick={() => handleSort("date-asc")}
                >
                Data discendente
              </div>
              <div
                className="fs-14 mb-1 sort-text"
                onClick={() => handleSort("")}
                >
                Creati più recentemente 
              </div>
              <a className="text-decoration-underline text-dark text-center" onClick={clearSort}>Rimuovi filtro</a>
						</div>
					</div>
				</div>

			    </Col>

          <div>
          {checkUserRole() &&
                	selectedItems.length > 0 && 
					<div className="d-flex mb-3">
						<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
							Seleziona tutti
						</ButtonRm>
						<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
							Deseleziona Tutti
						</ButtonRm>
					</div>
				}


            {viewType == 'card' ? 
            
            <>
            {/* Filtra e mappa gli elementi non completati */}
            {elements.filter(event => event.isDone !== "Completato").map(event => (
              <ScadenzarioCard
                key={event.id}
                id={event.id}
                title={event.title}
                section={event.section}
                startDate={event.startDate}
                endDate={event.endDate}
                recurrenceType={event.recurrenceType}
                every={event.every}
                toDate={event.toDate}
                isDone={event.isDone}
                isRecurrent={event.isRecurrent}
                handleSelection={handleSelection}
											selectedItems={selectedItems}
              />
            ))}
            {/* Filtra e mappa gli elementi completati */}
            {elements.filter(event => event.isDone === "Completato").map(event => (
              <ScadenzarioCard
                key={event.id}
                id={event.id}
                title={event.title}
                section={event.section}
                startDate={event.startDate}
                endDate={event.endDate}
                recurrenceType={event.recurrenceType}
                every={event.every}
                toDate={event.toDate}
                isDone={event.isDone}
                isRecurrent={event.isRecurrent}
                handleSelection={handleSelection}
											selectedItems={selectedItems}
              />
            ))}
            </>
            :
            <>
            <div className="table-view-custom-container" style={{background: 'transparent'}}>
									<table className="document-table-note table-bordered">
									<thead>
									<tr className="min-width-custom">
                    <th></th>
									{headerNames.map((header) => (
      <th key={header} className='border'>{translationMapping[header]}</th>
    ))}
						
									
										</tr>
									</thead>
										<tbody>
									{elements.map((item, index) => (
										<tr  key={index}>
                      <td>
											<input
												type="checkbox"
												checked={selectedItems.includes(item.id)}
												onChange={(e) => handleSelection(e, item.id)}
												className="me-2"
											/>
											</td>
										{headerNames.map((header) => (
											<td key={header} className='border min-width-custom' /* style={{maxWidth: "250px",
                        wordWrap: "break-word",
                        whiteSpace: "normal"}} */>
											<Link to={`/alert/scadenzario/${item.id}`} className="text-dark" >
											{(header == "startDate" || header == "endDate")? 
                        formattedTimestamp(item[header]) 
                        : item[header]}
											</Link>
										</td>
										))}
										</tr>
									))}
									</tbody>

									
									
							 	 	</table>
						</div>

            <TableContainer component={Paper} className="table-font table-activity">
      					<Table stickyHeader aria-label="simple table  table-activity">
       						<TableHead>
          					    <TableRow className="table-font table-act-head-cell">
									<TableCell style={{ minWidth: '10px', paddingLeft: "16px !important"  }} className="table-font table-act-head-cell"></TableCell>
									{headerNames.map((header) => (
									<TableCell style={{ minWidth: '160px', paddingLeft: "16px !important" }} className="table-font table-act-head-cell" key={header}>
									<TableSortLabel
										active={orderBy === header}
										direction={orderBy === header ? order : 'asc'}
										onClick={() => handleRequestSort(header)}
										>
										{translationMapping[header]}
										</TableSortLabel>
									</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody className="table-font">
							{sortedUnits.map((item, index) => (
								<TableRow key={item.id} className="table-font cursor-pointer" >
									<TableCell style={{ minWidth: '5px' }} className="table-font">
									<input
										type="checkbox"
										checked={selectedItems.includes(item.id)}
										onChange={(e) => handleSelection(e, item.id)}
										className="me-2"
									/>
									</TableCell>
									{headerNames.map((header) => {
                						const variant = columnBadgeVariantMap[header];

										return (header == 'cdat' || header == 'startManagementDate' || header == 'signDate' || header == 'deadline')?
											<TableCell td key={header}  style={{ minWidth: '150px' }}
											className="table-font cursor-pointer"> 
												      {item[header] && 
													<Link to={`/alert/scadenzario/${item.id}`}>
														<CustomBadge variant={variant} margin="mx-0">
														
															{formattedTimestamp(item[header])}
														</CustomBadge>
													</Link>
												}
											</TableCell>:
											<TableCell key={header} style={{ minWidth: '150px' }}
											className="table-font cursor-pointer"> 
											      {item[header] && 
													<Link to={`/alert/scadenzario/${item.id}`} className="" >
												<CustomBadge variant={variant} margin="mx-0">
													
													{ item[header]}
													
												</CustomBadge>
												</Link>
												}
											</TableCell>
									})}
								</TableRow>
							))}
							</TableBody>
						</Table>
					</TableContainer>
            </>
          }
          </div>
          <Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>
          </Row>
        )}
          {
          searchTerm && elements.length == 0 && 
          (
            <EmptyStateContainer
              content="Nessuna corrispondenza trovata"
              img={SearchIllustration}
            />
          )}

        {deadlineList.length === 0 && (
          <Row className="dashboard-container ">
          <div className="mx-auto d-table text-center empty-container ">
            <div className="mx-auto d-table w-160">
              <img loading="lazy" src={emptyState} alt="empty" />
            </div>
            <p className="fs-20 text-secondary text-center">
              Non hai aggiunto ancora nessuna scadenza
            </p>
          </div>
        </Row>
        )}

{/* <Dropzone onDrop={(acceptedFiles) => transmigrationFunction(acceptedFiles, true, mappingFromCompetitor1, deadline_endpoint, fetch)
			} >
				{({ getRootProps, getInputProps }) => (
					<section className="">
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<div className="filter">
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
							<i className="bi bi-upload fs-25"></i>
						</div>
						</div>
					</div>
					</section>
				)}
			</Dropzone> */}

      <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione delle scadenze, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>

      <Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
      </Modal>

      <Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
        <Modal.Body className="p-5">
        <div className="d-flex flex-column text-center">
          <h2 className="fs-32 text-dark fw-semibold mb-3">
          Gestisci i permessi per questo elemento
          </h2>
          {members.length > 0 && 
          <table className="my-3">
            <thead>
              <tr>
              <th>Collaboratore</th>
              <th>Visualizza</th>
              <th>Modifica</th>
              <th>Elimina</th>
              </tr>
            </thead>
            <tbody>
            { members.map((user, i) => (
            <tr key={i}>
              <td>{user.email}</td>
              <td>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(i, 'visualizza')}
              />
              </td>
              <td>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(i, 'modifica')}
              />
              </td>
              <td>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(i, 'elimina')}
              />
              </td>
            </tr>
            ))}
          </tbody>
          </table>
          }
          <button
          type="button"
          className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClosePermit(e);
          }}
          >
          Salva
          </button>
          <button
          type="button"
          className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
          onClick={(e) => handleClosePermit(e)}
          >
          Annulla
          </button>
        </div>
        </Modal.Body>
      </Modal>


    </>

    
  );
}

export default Scadenzario;
