import axios from "axios";

/* const BASE_URL = "https://anthillrealmeta.herokuapp.com"; //"http://localhost:8080";
//const BASE_URL_WS = "ws://anthillrealmeta.herokuapp.com:62357"; //"ws://localhost:62357/ */

// const BASE_URL = "http://213.229.116.152:8080/"; 
// https://192.168.1.26:8080/
//const BASE_URL =  "https://service.anthillgroup.it:8080/" 
const BASE_URL =  "https://application.anthillgroup.it:8080/" 

//const BASE_URL =  "http://192.168.1.23:8080/" //da usare con server locale con arash
const BASE_URL_WS = "wss://192.168.24.208:8080/"; 

// TODO to remove
const user = localStorage.getItem("user");
const token = user ? JSON.parse(user)?.token : null;

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

const getCustomAxios = () => {
	console.log("refresh token");
	return axios.create({
		baseURL: BASE_URL,
		headers: {
		Authorization: `Bearer ${
			JSON.parse(localStorage.getItem("user"))?.token
		}`,
		"Content-Type": `application/json`,
		},
	});
};

export default getCustomAxios;

/* STATO LAVORI IN CORSO / GESTIONI */
const management_endpoint = "/api/v1/management/";
const status_management_endpoint = "/api/v1/management/status/";
const real_estate_management_endpoint = "/api/v1/real_estate_management/";

/* STATO LAVORI IN CORSO / ATTIVITA'*/
const activity_endpoint = "/api/v1/activity/";
const activity_unit_association_endpoint = "/api/v1/activity/";

/* STATO LAVORI IN CORSO / CHAT'*/
const chat_msg_endpoint = "/api/v1/chat/";
const chat_list_endpoint = "/api/v1/chat_group/";
const chat_confirm_seen_endpoint = "/api/v1/confirm_seen/";
const chat_confirm_seen_association_endpoint =
  "/api/v1/chat_confirm_seen_association/";
const chat_confirm_content_endpoint = "/api/v1/confirm_content/";
const chat_confirm_content_association_endpoint =
  "/api/v1/chat_confirm_content_association/";
const chat_sign_endpoint = "/api/v1/sign/";
const chat_sign_association_endpoint = "/api/v1/chat_sign_association/";

const chat_activity_association_endpoint ="/api/v1/activity_chat_group_association/"

/* IMPOSTAZIONI CHAT  */

const tag_endpoint = "/api/v1/tag/";
const verbal_endpoint = "/api/v1/verbal/";
const accounting_endpoint = "/api/v1/accounting/";
const team_endpoint = "/api/v1/team/";

/* Unit API */
const unit_endpoint = "/api/v1/unit/";

/* Stabili API */
const stabili_endpoint = "/api/v1/condominium/";

/* Evaluation API */
const evaluation_endpoint = "/api/v1/evaluation/";
const evaluation_endpoint_gen = "/api/v1/evaluation/generateDocument";
const evaluation_unit_endpoint = "/api/v1/evaluation_unit_assignment/";
const evaluation_download_endpoint = "/api/v1/evaluation/downloadDocument"

const affitti_endpoint = "/api/v1/rent/";

/* PRIMA NOTA API */
const first_note_endpoint = "/api/v1/note/";

/* SECTION API */
const section_endpoint = "/api/v1/section/";

/* PRIMA NOTA API */
const model_endpoint = "/api/v1/model/";
const model_upload_endpoint = "/api/v1/model/upload/";
const model_download_endpoint = "/api/v1/model/download/";
const subcount_endpoint =  "/api/v1/subCount/";

/* MODEL SECTION ASSIGNMENT API */
const model_section_assignment_endpoint = "/api/v1/model_section_assignment/";

/* SUPPLIER API */
const supplier_endpoint = "/api/v1/supplier/";

/* OWNER API */
const owner_endpoint = "/api/v1/owner/";

/* CONDUCTOR API */
const conductor_endpoint = "/api/v1/conductor/";
const conductor_guarantor_endpoint = "/api/v1/conductor/getGuarantors";

/* note_owner_assignment API */
const note_owner_assignment_endpoint = "/api/v1/note_owner_assignment/";

const bank_account_endpoint = "/api/v1/bank_account/";

const owner_bank_account_assignment_endpoint =
  "/api/v1/owner_bank_account_assignment/";
const supplier_bank_account_assignment_endpoint =
  "/api/v1/supplier_bank_account_assignment/";
const conductor_bank_account_assignment_endpoint =
  "/api/v1/conductor_bank_account_assignment/";

const owner_supplier_assignment_endpoint = "/api/v1/owner_supplier_assignment/";

//UNIT ASSIGNMENET
const owner_unit_assignment_endpoint = "/api/v1/owner_unit_assignment/";
const conductor_unit_assignment_endpoint = "/api/v1/conductor_unit_assignment/";
const supplier_unit_assignment_endpoint = "/api/v1/supplier_unit_assignment/";
const condominium_unit_assignment_endpoint = "/api/v1/condominium_unit_assignment/";
const rent_unit_assignment_endpoint = "/api/v1/rent_unit_assignment/";
const pertinence_unit_assignment_endpoint = "/api/v1/unit_pertinence_association/";
const insurance_unit_assignment_endpoint = "/api/v1/insurance_unit_assignment/";
const insurance_condo_assignment_endpoint = "/api/v1/insurance_condominium_assignment/";
const counter_unit_assignment_endpoint = "/api/v1/counter_unit_assignment/";
const act_unit_assignment_endpoint = "/api/v1/act_unit_assignment/";
const act_endpoint = "/api/v1/act/";

//ASSICURAZIONE
const insurance_endpoint = "/api/v1/insurance/";

//READCOUNTERS
const counter_endpoint = "/api/v1/counter/";

const supplier_condominium_assignment_endpoint = "/api/v1/supplier_condominium_assignment/";

//const activity_unit_assignment_endpoint = "/api/v1/activity_unit_assignment/"
const condominium_endpoint = "/api/v1/condominium/";

const rent_conductor_assignment_endpoint = "/api/v1/rent_conductor_assignment/";

const management_re_association_endpoint = "/api/v1/management_unit_association/";

//RENDICONTO
const report_endpoint = "/api/v1/report/";
const report_unit_assignment_endpoint = "/api/v1/report_unit_assignment/"
const report_owner_assignment_endpoint = "/api/v1/report_owner_assignment/"

const assembly_endpoint = "/api/v1/assembly/";

const note_condominium_assignment_endpoint = "/api/v1/note_condominium_assignment/";
const note_conductor_assignment_endpoint = "/api/v1/note_conductor_assignment/";
const note_unit_assignment_endpoint = "/api/v1/note_unit_assignment/";
const note_supplier_assignment_endpoint = "/api/v1/note_supplier_assignment/";

const deadline_endpoint = "/api/v1/deadline/";

const rate_endpoint = "/api/v1/installment/"
const rate_unit_assignment_endpoint = "/api/v1/unit_installment_assignment/"
const rate_condominium_assignment_endpoint = "/api/v1/condominium_installment_assignment/"
const rate_note_assignment_endpoint  = "/api/v1/note_installment_assignment/"
const rate_supplier_assignment_endpoint  = "/api/v1/supplier_installment_assignment/"
const rate_conductor_assignment_endpoint  = "/api/v1/conductor_installment_assignment/"
const rate_owner_assignment_endpoint  = "/api/v1/owner_installment_assignment/"

//IMPOSTAZIONI PROFILO
const user_endpoint = "/api/v1/user/";
const user_accounts_endpoint = "/api/v1/accounting_resource/"
const user_accounts_assignment_endpoint = "/api/v1/accounting_resource_user_association/"
const user_get_all_endpoint = "/api/v1/user/getAll/"

//EMAIL
const email_endpoint = "/api/v1/email/"

//CAMBIA PASSWORD
const change_password_endpoint = "/api/v1/auth/changePassword";

//PARTNER
const partners_endpoint = "/api/v1/public_page/getAll"
const public_page = "/api/v1/public_page/"

const public_page_image = "/api/v1/public_page/upload_image/"

const portfolio_endpoint = "/api/v1/portfolio/"
const portfolio_page_assignment_enpoint = "/api/v1/public_page_portfolio_association/"

const service_endpoint = "/api/v1/service/"
const service_page_assignment_endpoint = "/api/v1/public_page_service_association/"

const product_endpoint = "/api/v1/product/"
const product_page_assignment_endpoint = "/api/v1/public_page_product_association/"

const experience_endpoint = "/api/v1/experience/"
const experience_page_assignment_endpoint = "/api/v1/public_page_experience_association/"

const certification_endpoint = "/api/v1/certification/"
const certification_page_assignment_endpoint = "/api/v1/public_page_certification_association/"

const publication_endpoint = "/api/v1/publication/"
const publication_page_assignment_endpoint = "/api/v1/public_page_publication_association/"

export {
	user,
/* 	BASE_URL_WS,
 */	management_endpoint,
	real_estate_management_endpoint,
	status_management_endpoint,
	activity_endpoint,
	unit_endpoint,
	token /* CAPIRE PERCHE THIERRY MANDA TOKEN DA QUI */,
	stabili_endpoint,
	evaluation_endpoint,
	evaluation_unit_endpoint,
	first_note_endpoint,
	section_endpoint,
	model_endpoint,
	model_section_assignment_endpoint,
	supplier_endpoint,
	owner_endpoint,
	note_owner_assignment_endpoint,
	chat_msg_endpoint,
	chat_list_endpoint,
	tag_endpoint,
	verbal_endpoint,
	team_endpoint,
	accounting_endpoint,
	chat_confirm_seen_association_endpoint,
	chat_confirm_seen_endpoint,
	chat_confirm_content_association_endpoint,
	chat_confirm_content_endpoint,
	chat_sign_endpoint,
	chat_sign_association_endpoint,
	owner_supplier_assignment_endpoint,
	conductor_endpoint,
	bank_account_endpoint,
	owner_bank_account_assignment_endpoint,
	supplier_bank_account_assignment_endpoint,
	conductor_bank_account_assignment_endpoint,
	affitti_endpoint,
	owner_unit_assignment_endpoint,
	condominium_endpoint,
	condominium_unit_assignment_endpoint,
	supplier_unit_assignment_endpoint,
	rent_unit_assignment_endpoint,
	rent_conductor_assignment_endpoint,
	management_re_association_endpoint,
	assembly_endpoint,
	report_endpoint,
	note_unit_assignment_endpoint,
	note_condominium_assignment_endpoint,
	note_supplier_assignment_endpoint,
	chat_activity_association_endpoint,
	deadline_endpoint,
	model_upload_endpoint,
	note_conductor_assignment_endpoint,
	rate_endpoint,
	model_download_endpoint,
	rate_unit_assignment_endpoint,
	rate_condominium_assignment_endpoint,
	rate_note_assignment_endpoint,
	rate_supplier_assignment_endpoint,
	rate_conductor_assignment_endpoint,
	rate_owner_assignment_endpoint,
	evaluation_endpoint_gen,
	evaluation_download_endpoint,
	subcount_endpoint,
	report_unit_assignment_endpoint,
	user_endpoint,
	conductor_unit_assignment_endpoint,
	pertinence_unit_assignment_endpoint,
	insurance_unit_assignment_endpoint,
	insurance_endpoint,
	counter_unit_assignment_endpoint,
	counter_endpoint,
	act_unit_assignment_endpoint,
	act_endpoint,
	report_owner_assignment_endpoint,
	conductor_guarantor_endpoint,
	supplier_condominium_assignment_endpoint,
	user_accounts_endpoint,
	user_accounts_assignment_endpoint,
	insurance_condo_assignment_endpoint,
	partners_endpoint,
	public_page,
	portfolio_endpoint,
	portfolio_page_assignment_enpoint,
	service_endpoint,
	service_page_assignment_endpoint,
	product_endpoint,
	product_page_assignment_endpoint,
	experience_endpoint,
	experience_page_assignment_endpoint,
	certification_endpoint,
	certification_page_assignment_endpoint,
	publication_endpoint,
	publication_page_assignment_endpoint,
	public_page_image,
	BASE_URL,
	user_get_all_endpoint,
	email_endpoint

};
