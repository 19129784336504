/* ELEMENTO CUSTOM PER ACCORDION HEADER */
//Esempio
// <CustomToggle eventKey="0" activeKey={activeKey}>Dati</CustomToggle>

import React from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import accIcon from "../assets/images/accordion-icon.svg";
import accBtn from "../assets/images/acc-btn.svg";


function CustomToggle({ children, eventKey, activeKey, img }) {
	const decoratedOnClick = useAccordionButton(eventKey, () =>
	console.log('toggling!'),
	)

	return (
	<div className="d-flex justify-content-between px-3 cursor-pointer" 		onClick={decoratedOnClick}

	style={{height:"50px", color:"#666F7E", borderBottom: activeKey == eventKey ? "0.5px solid #dee2e6" : "0.1px solid #dee2e6"}}>
		<div className="d-flex align-items-center">
			<img loading="lazy" src={img? img : accIcon} width={32} className="me-2"></img>
			<p className="d-flex align-items-center">{ children }</p>
		</div>
		<a
		className="d-flex align-items-center justify-content-center"
		style={{transform: activeKey == eventKey ? "rotate(180deg)" : "rotate(0deg)", transition:"all .5s ease"}}
		>
			<img loading="lazy" src={accBtn}></img>
		</a>
	</div>
	);
}

export default CustomToggle