import React from 'react';
import "../../assets/css/Tips.css"

const Tips = ({ variant, content }) => {
  let iconClass = '';
  let name = ""

  switch (variant) {
    case 'suggestion':
      iconClass = 'bi bi-lightbulb-fill'; 
      name = "Consigli"
      break;
    case 'info':
      iconClass = 'bi bi-exclamation-triangle-fill'; 
      name = "Nota Bene"
      break;
    case 'automatization':
      iconClass = 'bi bi-lightning-fill'; 
      name= "Automatizzazioni"
      break;
    default:
      console.warn('Variant not recognized');
  }

  return (
    <div className={`tips ${variant}`}>
      <div>
        <i className={iconClass}></i>
        <p>{name}</p>
      </div>
      <p>{content}</p>
    </div>
  );
};

export default Tips;
