import React, { useState } from "react";
import Section1 from "./page9/section1";

export default function Page9() {
  const [status, setStatus] = useState(1);
  const Section = () => {
    switch (status) {
      case 1:
        return <Section1 onChangeSection={(v) => setStatus(v)} />;
      default:
        break;
    }
    return;
  };
  return <Section />;
}
