import {compareAsc, compareDesc, parse, parseISO, toDate} from "date-fns"


export function sortDatesByKey(arr, key, asc = true) {
    const sorted = [...arr]
    sorted.sort(function (a, b) {
        const dateA = parseISO(a[key]);
        const dateB = parseISO(b[key]);     
        return asc ? compareAsc(dateA, dateB) : compareDesc(dateA, dateB)
    })
    return [...sorted]
}

export function sortStringByKey(arr, key, asc = true) {
    const sorted = [...arr]
    sorted.sort(function (a, b) {
        const compare = a[key].localeCompare(b[key]) 
        if (compare == 0) {
            return 0
        } else if (compare < 0) {
            return asc ? -1 : 1
        } else { // compare > 0
            return asc ? 1 : -1
        }
    })
    return [...sorted]
}



