import { useState, useEffect } from "react";
import filter_img from "../../assets/images/filter.svg";
import sort_img from "../../assets/images/sort.svg";
import { Link } from "react-router-dom";
import AssemblyCard from "./AssemblyCard";
const AssemblyFirstPage = (props) => {
	const [filteredData, setFilteredData] = useState(props.data);
	const [sortOption, setSortOption] = useState(0);
	const [filterOption, setFilterOption] = useState({});
	const filterFunc = () => {
		var temp = [];
		// if (affitto == true) {
		// 	let t = [];
		// 	t = props.data.filter((item) => item.typology == false);
		// 	if (t.length > 0) temp = [...temp, t];
		// }
		// if (proposta == true) {
		// 	let t = [];
		// 	t = props.data.filter((item) => item.typology == true);
		// 	if (t.length > 0) temp = [...temp, t];
		// }
		// setFilteredData(temp);
		// if (affitto == false && proposta == false) {
		// 	setFilteredData(props.data);
		// }
		setFilteredData(props.data);
	};
	useEffect(() => {
		filterFunc();
	}, [props]);

	return (
		<div className="sort-filter-container">
			<div className="filter_sort_bar">
				<div className="user-dropdown">
					<div className="me-5 filter">
						<img loading="lazy" src={filter_img} alt="" />
						<span>Filtra</span>
					</div>
					<div className="user-dropdown-content">
						{/* <div className="fs-16 fw-bold">TIPOLOGIA</div>
						<div className="mt-2">
							<div className="input-checkbox">
								<input
									type="checkbox"
									name="typology"
									checked={affitto}
									onChange={(e) => setAffitto(e.target.checked)}
								/>
								<label className="f-label fs-16 d-flex align-items-center
me-2">Affitto</label>
							</div>
							<div className="input-checkbox">
								<input
									type="checkbox"
									name="typology"
									checked={proposta}
									onChange={(e) => setProposta(e.target.checked)}
								/>
								<label className="f-label fs-16 d-flex align-items-center
me-2">Proposta</label>
							</div>
						</div> */}
					</div>
				</div>
				<div className="user-dropdown">
					<div className="sort">
						<img loading="lazy" src={sort_img} alt="" />
						<span>Ordina</span>
					</div>
					<div className="user-dropdown-content">
						<div className="fs-16 fw-bold">Ordina per</div>
						<div
							className="fs-14 mt-3 mb-1 sort-text"
							style={
								sortOption === 0
									? { color: "var(--primary)" }
									: { color: "black" }
							}
							onClick={() => {
								setSortOption(0);
							}}>
							Data di creazione
						</div>
						<div
							className="fs-14 sort-text"
							style={
								sortOption === 1
									? { color: "var(--primary)" }
									: { color: "black" }
							}
							onClick={() => {
								setSortOption(1);
							}}>
							A-Z
						</div>
					</div>
				</div>
			</div>
			<div>
				{sortOption === 0
					? filteredData.map((item, index) => {
							return (
								<div key={index}>
									<Link to={`/assembly/edit/${item.id}`}>
										<AssemblyCard
											id={item.id}
											report={item.report}
											fromDate={item.fromDate}
											toDate={item.toDate}
											unitName={item.unitName}
											ownerName={item.ownerName}
											type={item.type}
											deleteReport={props.deleteReport}
										/>
									</Link>
								</div>
							);
					  })
					: []
							.concat(filteredData)
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map((item, index) => {
								return (
									<div key={index}>
										<Link to={`/assembly/edit/${item.id}`}>
											<AssemblyCard
												id={item.id}
												report={item.report}
												fromDate={item.fromDate}
												toDate={item.toDate}
												unitName={item.unitName}
												ownerName={item.ownerName}
												type={item.type}
												deleteReport={props.deleteReport}
											/>
										</Link>
									</div>
								);
							})}
			</div>
		</div>
	);
};

export default AssemblyFirstPage;
