import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

export default function SupplierModal(props) {
  const [table_select, setTableSelect] = useState(-1);
  const [search_name, setSearchName] = useState("");
  const addSupplier = () => {
    props.addSupplier(props.ids[table_select]);
    props.setShow(false);
  };

  return (
    <>
      <div className="d-block w-100 mb-3 mt-4">
        <div>
          <h2 className="fs-20 text-dark mb-2 fw-bold">Seleziona soggetto</h2>
        </div>
      </div>
      <Form>
        <div className="modal-search">
          <div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
            <i className="fal fa-search me-2 text-secondary opacity-50"></i>
            <input
              type="text"
              name=""
              placeholder="Cerca"
              className="flex-grow-1 border-0 w-100 fs-14"
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
            />
          </div>
        </div>
        <Table className="mt-4 table-border" responsive>
          <thead>
            <tr>
              <th>Categoria</th>
              <th>Denominazione</th>
              <th>Telefono</th>
              <th>Tipo di soggetto</th>
            </tr>
          </thead>
          <tbody className="">
            {props.table_data &&
              props.table_data.map((data, index) => {
                if (
                  data[0]?.toLowerCase().indexOf(search_name.toLowerCase()) !==
                    -1 ||
                  data[1]?.toLowerCase().indexOf(search_name.toLowerCase()) !==
                    -1
                ) {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        if (table_select !== index) {
                          setTableSelect(index);
                        } else setTableSelect(-1);
                      }}
                      className={table_select !== index ? "" : "table_tr"}
                    >
                      {data.map((sub_data, id) => {
                        return <td key={id}>{sub_data}</td>;
                      })}
                    </tr>
                  );
                }
              })}
          </tbody>
        </Table>
        <div className="col-12">
          <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
            <button
              type="button"
              className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
              onClick={() => {
                props.setShow(false);
              }}
            >
              Annulla
            </button>
            <button
              type="button"
              className={`btn-last rounded-3 shadow w-193 border-0
                ${table_select >= 0 ? "next-btn" : "disable"}`}
              disabled={table_select >= 0 ? false : true}
              onClick={addSupplier}
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}
