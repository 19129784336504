import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import axios from "axios";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";

import api, { user_endpoint, user_accounts_endpoint, user_accounts_assignment_endpoint, email_endpoint } from "../../../api/api";

import CustomBadge from "../../CustomBadge";
import ButtonRm from "../../ButtonRm"
import Image from 'react-bootstrap/Image'

import logoRed from "../../../assets/images/logo-red.svg"


/* import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import deskIcon from "../../assets/images/deskIcon.svg" */

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/Tabs.css";


/* import Webcam from "react-webcam";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateManagerAccount from "../components/Impostazioni/CreateManagerAccount";
import { BankCard, BankCardItem } from "../components/BankCard";

import FileInputButton from "../components/FileInputButton";

import SignatureCanvas from 'react-signature-canvas' */


import { useDispatch } from 'react-redux';
import { fetchUser, approveUser, sendEmail, rejectUser } from "../../../actions/accountAction";

const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

//minimo 8 caratteri, contenga minuscole, maiuscole, almeno un simbolo e almeno un numero
const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    genre: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	}),
    /* newPassword: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un simbolo e un numero')
    .required('La password è obbligatoria') */
});

export default function DetailAccount(props){

    const dispatch = useDispatch();

    const [data, setData] = useState(null); 

    const fetchUser = async () => {
        try {
            const response = await api().get(`${user_endpoint}${props.id}`);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        
        if (props.id) {
            fetchUser();
        }

        return () => {
            // Clean up any resources (if needed)
        };
    }, [props.id]);
    

    async function handleApproveUser () {
        dispatch(approveUser(props.id))
        setIsConfirmed(true)
        await fetchUser();
    }
    
    ///////////////
    
    function onChange(e){
        const {value, name} = e.target
       /*  setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        }) */
    }

    //VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
        updateData()
        fetchData()
	};

    //HANDLE PROFILE IMG
    const [profileImg, setProfileImg] = useState("")

    function handleProfileImg(e){
        setProfileImg(e.target.files[0])
    }

    function handleRemoveProfileImg(){
        setProfileImg("")
    }

    //HANDLE FACE ID
    const [faceIdModal, setFaceId] = useState(false)
    const [faceIdPhoto, setFaceIdPhoto] = useState("")

    const showFaceId = () => (setFaceId(true))
    const closeFaceId = () => (setFaceId(false))

    function handleRemoveFaceId(){
        setFaceIdPhoto("")
    }

    useEffect(() => {
        /* setData(prev => {
            return { 
                ...prev,
                faceFile: faceIdPhoto
            }
        }) */
    }, [faceIdPhoto])

    //HANDLE WEBCAM

    const webcamRef = React.useRef(null);
    const [camera, setCamera] = useState(false);

    const handleWebcamAccess = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          setCamera(stream);
        } catch (error) {
          console.log('Error accessing camera:', error);
        }
      };


    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user",
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        //let link_img = document.createElement("a");
        //link_img.style.display = "none";
        //document.body.appendChild(link_img);
        //link_img.href = imageSrc;
        //link_img.download = "face_id.png";
        //link_img.click();
        setFaceIdPhoto(imageSrc)
        closeFaceId()
        setCamera(false)
    }, [webcamRef]);

    //HANDLE MODAL OTP
    const [otpModal, setOtpModal] = useState(false)
    const showOtpModal = () => setOtpModal(true)
    const closeOtpModal = () => {
        setOtpModal(false)
        setStep1(true)
    }
    const [valuesOtp, setValuesOtp] = React.useState(['', '', '', '', '','']);


    //HANDLE CONTI

    const [conti, setConti] = useState([])


    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //HANDLE FIRME

    const [firmeImg, setFirmeImg] = useState([]) 
    const [ modalSelectFirma, setModalSelectFirma] = useState(false)
    const showModalSelectFirma = () => setModalSelectFirma(true)
    const closeModalSelectFirma = () => {
        setModalSelectFirma(false)
        setNewFirma({
            name:"",
            img:"",
            created: false
        })
    }

    const [ modalCreateFirma, setModalCreateFirma] = useState(false)
    const showModalCreateFirma = () => {
        setModalCreateFirma(true)
        setNewFirma(prev => {
            return {
                ...prev,
                created: true,
            }
            })
    }
    const closeModalCreateFirma = () => {
        setModalCreateFirma(false)
        setNewFirma({
            name:"",
            img:"",
            created: false
        })
    }

    const [newFirma, setNewFirma] = useState({
        name:"",
        img:"",
        created: false,
    })

    function handleNewFirma(e){
        const {value, name} = e.target
        if(name == "img"){
            setNewFirma(prev => {
                return {
                    ...prev,
                    [name]: e.target.files[0]
                }
            })
        } else {
            setNewFirma(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            })
        }
    }

    function handleRemoveSelectedFirmaImg(){
        setNewFirma(prev => {
            return {
                ...prev,
                img: "",
                created: false
            }
        })
    }

    function addNewFirma(){
        setFirmeImg(prev => {
            return [...prev, newFirma]
        })
        closeModalSelectFirma()
    }

    function removeFirma(id){
        const newArr = firmeImg.filter((item,index )=> index != id)
        setFirmeImg(newArr)
    }

    //CREATE IMG
    let sigCanvas = useRef({})

    //ON SAVE => aggiungi new firma 
    function handleSign(e){
        setFirmeImg(prev => {
            return [...prev, {...newFirma, img: sigCanvas.getTrimmedCanvas().toDataURL('image/png')}]
        })
        closeModalCreateFirma()
    }

    /* const handleFileSelected = (file) => {
        console.log('File selezionato:', file);
        setFirmeImg(prev => {
            return [...prev, file]
        })
    }; */

    //HANDLE BOTH INTESTAZIONE E PIE DI PAGINA

    const [intestazioni, setIntestazioni] = useState([])

    const [modalIntestazione, setModalIntestazione] = useState(false)

    const [newIntestazione, setNewIntestazione] = useState({
        riga1:"",
        riga2:"",
        riga3:"",
        type:""
    })

    function showModalIntestazione(type){
        setModalIntestazione(true)
        setNewIntestazione(prev => {
            return {
                ...prev,
                type: type
            }
        })
    }

    function closeModalIntestazione(){
        setModalIntestazione(false)
        setNewIntestazione({
            riga1:"",
            riga2:"",
            riga3:"",
            type:""
        })
    }

    function handleNewIntestazione(e){
        const {name, value} = e.target

        setNewIntestazione(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function handleAddIntestazione(){
        setIntestazioni(prev => {
            return [...prev, newIntestazione]
        })

        closeModalIntestazione()
    }

    function deleteIntestazione(id){
        const newArr = intestazioni.filter((item, index) => index != id)
        setIntestazioni(newArr)
    }

    //EDIT INTESTAZIONE
    const [currentIntestazione, setCurrentIntestazione] = useState(null)
    const [modalEditIntestazione, setModalEditIntestazione] = useState(false)

    function showModalEditIntestazione(){
        setModalEditIntestazione(true)
    }

    function closeModalEditIntestazione(){
        setModalEditIntestazione(false)
        setCurrentIntestazione(null)
    }


    function editIntestazione(id){
        setCurrentIntestazione(intestazioni.find((item, index) => index == id))
        showModalEditIntestazione()
    }

    function handleEditIntestazione(e){
        const {name, value} = e.target
        setCurrentIntestazione(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function updateIntestazione(){
        const newArr = intestazioni.map(item => {
            return item.type == currentIntestazione.type ?
                currentIntestazione:
                item
        })

        setIntestazioni(newArr)
        closeModalEditIntestazione()
    }



    //HANDLE LOGO

     const [logoImg, setLogoImg] = useState("")

     function handleLogoImg(e){
         setLogoImg(e.target.files[0])
     }
 
     function handleRemoveLogoImg(){
         setLogoImg("")
     }


    //GET DATA

    async function fetchData(type) {
            try {
                const response = await api().get(`${user_endpoint}`);
                if (response.data) {
                    //setData(response.data?.content[0]);

                }
            } catch (error) {
                console.log(error);
            }
    }

    useEffect(() => {
            fetchData()
    }, [])



     //HANDLE UPDATE

     const updateData = async () => {
        let str = {...data}
        const id = str.id; // Salva il valore di data.id in una variabile separata
        delete str.id;
        delete str.cdat;
        delete str.udat;

        try {
            await api().put(`${user_endpoint}${id}`, str); // Utilizza la variabile id invece di data.id
        } catch (err) {
            console.log(err);
        }
    };
    


     //FETCH PROVINCES

    const [provinceList, setProvincesList] = useState([])

    const fetchProvinces = () => {
        axios
        .get(
            `https://anthill-service.onrender.com/provinces?region`
        )
        .then((res) => {
            setProvincesList(res.data.data);
        });
    };
    
    useEffect(() => {
            fetchProvinces()
    }, [data?.province])

    //FETCH MUNICIPALITIES

    const [citiesList, setCitiesList] = useState([])

    const fetchCities = () => {
        axios
        .get(
            `https://anthill-service.onrender.com/municipalities?province=${data.province}`
        )
        .then((res) => {
            setCitiesList(res.data.data);
        });
    };
    
    useEffect(() => {
        if (data?.province){
            fetchCities()
        }
    }, [provinceList])

    //HANDLE CONTO

    async function addConto(account){
        //POST
        try {
            let response = await api().post(`${user_accounts_endpoint}`, account)
            let responseID = response.data.id
            
            if(responseID) {
                await api().post(`${user_accounts_assignment_endpoint}`, {
                    accountingResourceID: responseID,
                    userID: data.id
                })
            }

            await fetchData()
        } catch (err) {
            console.log(err);
        }
    }

    async function removeConto(id){
        await api().delete(`${user_accounts_assignment_endpoint}${id}`)
        //check se serve anche dissociazione o basta delete
    }

    const [editContoID, setEditContoId] = useState(null)

    async function updateAccount(account, id){
        //POST
        try {
            let response = await api().put(`${user_accounts_endpoint}${id}`, account)
            await fetchData()
        } catch (err) {
            console.log(err);
        }
    }

    //ckeck se verified, unverified, requestedInfo => mi serve parametro che arriva con dati utente sullo stato, cosi da impostare bottoni e badge

    //SEND EMAIL TO USER

    const [missingRequirements, setMissingRequirements] = useState("")

    function handleMissingRequirements (e) {
        setMissingRequirements(e.target.value)
    }


    //SEND EMAIL TO USER

    const [step1, setStep1] = useState(true)

    const myMail = "emanuelamaviglia91@gmail.com"
    const bodyMail = {email: myMail, text: missingRequirements}

    async function sendEmail(){
        //INVIA EMAIL - INTEGRAZIONE API XIA
        try {
            await api().post(`${email_endpoint}`, bodyMail)
            console.log("Email inviata con successo all'utente con ID:", myMail);
        } catch (error) {
            console.error("Errore durante l'invio dell'email:", error);
        }
        dispatch(rejectUser(props.id)); 
        await fetchUser();
        //DOPO INVIO
        setStep1(false)
    }

    function backToList(){
        setStep1(true)
        props.setId("")
        setVerifiedModal(false)
    }


    //VERFIIED MODA

    const [verifiedModal, setVerifiedModal] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false);

    const formatDateToISO = (dateString) => {
        return dateString ? dateString.split('T')[0] : '';
      };

      const formattedBirthday = formatDateToISO(data?.birthday);


    return(
        <>
            <div className="settings-tab-content mx-3">
              

                <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {/*ANAGRAFICA*/}

                    <Row>
                        <Col md={12} className="ps-0 mb-3" id="anagrafica">
                            <p className="fs-16 text-dark fw-bold">Anagrafica</p>
                        </Col>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Nome *
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="name"
                                        value={data?.name}
                                        onChange={onChange}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Cognome *
                                </Form.Label>
                                <Form.Control
                             
                                    type="text"
                                    name="surname"
                                    value={data?.surname}
                                    onChange={onChange}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid" >
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Indirizzo di Residenza *
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="address"
                                        value={data?.address}
                                        onChange={onChange}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Numero Civico e Interno *
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="houseNumber"
                                    value={data?.houseNumber}
                                    onChange={onChange}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid" >
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Provincia *
                                    </Form.Label>
                                    <Form.Select
                                        type="text" 
                                        name="province"
                                        value={data?.province}
                                        onChange={onChange}
                                        disabled
                                    >
                                        <option value=""></option>
                                        {provinceList?.length > 0 && provinceList.map((province, index) => {
                                            return (
                                            <option value={province} key={index}>
                                                {province}
                                            </option>
                                            );
                                            })} 
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Comune *
                                    </Form.Label>
                                    <Form.Select
                                        type="text" 
                                        name="municipality"
                                        value={data?.municipality}
                                        onChange={onChange}
                                        disabled
                                    >   
                                        <option value=""></option>
                                        
                                       
                                        
                                        <option></option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        CAP *
                                    </Form.Label>
                                    <Form.Control 
                                        type="number" 
                                        name="cap"
                                        value={data?.cap}
                                        onChange={onChange}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Codice Fiscale *
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fiscalCode"
                                    value={data?.fiscalCode}
                                    onChange={onChange}
                                    disabled
                                />
                                <Form.Control.Feedback type="invalid" >
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Data di nascita *
                                    </Form.Label>
                                    <Form.Control 
                                        type="date" 
                                        name="birthday"
                                        value={formattedBirthday} // Usare la data formattata qui
                                        onChange={onChange}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Sesso *
                                    </Form.Label>
                                    <Form.Select
                                        type="text" 
                                        name="genre"
                                        value={data?.genre}
                                        onChange={onChange}
                                        disabled
                                    >
                                         <option value=""></option>

                                        <option value="M">Maschio</option>
                                        <option value="F">Femmina</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.genre?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Username *
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="username"
                                        value={data?.username}
                                        onChange={onChange}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                           
                        </Row>

                        <Row className="mx-0 px-0">
                            <Col md={6} className="my-3 mx-0 px-0">
                            <Form.Check 
                                type="checkbox"
                                label="Sono un'azienda"
                                name="isCompany"
                                checked={data?.isCompany} 
                                onChange={onChange}
                            />
                            </Col>
                        </Row>

                        { data?.isCompany &&
                            <>
                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                P. IVA*
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="vatNumber"
                                                value={data?.vatNumber}
                                                onChange={onChange}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Ragione sociale*
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessName"
                                                onChange={onChange}
                                                value={data?.businessName}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Indirizzo di fatturazione*
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessAddress"
                                                onChange={onChange}
                                                value={data?.businessAddress}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                            Numero Civico e Interno*
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessHouseNumber"
                                                onChange={onChange}
                                                value={data?.businessHouseNumber}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                    <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Provincia *
                                    </Form.Label>
                                    <Form.Select
                                        type="text" 
                                        name="businessProvince"
                                        value={data?.businessProvince}
                                        onChange={onChange}
                                        isInvalid={!!errors.businessProvince}
                                        disabled
                                    >
                                        <option ></option>
                                    
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                                    
                                    </Col>
                                    <Col md={6} className="mb-3">
                                    <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Comune *
                                    </Form.Label>
                                    <Form.Select
                                        type="text" 
                                        name="businessMunicipality"
                                        value={data?.businessMunicipality}
                                        onChange={onChange}
                                        disabled
                                    >   
                                        <option value=""></option>
                                        
                                        <option></option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                                    </Col>
                                
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                            CAP *
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessCap"
                                                onChange={onChange}
                                                value={data?.businessCap}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Codice univoco *
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="uniqueCode"
                                                onChange={onChange}
                                                value={data?.uniqueCode}
                                                disabled
                                                
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Numero di dipendenti 
                                            </Form.Label>
                                            <Form.Control 
                                                type="number" 
                                                name="numberOfEmployees"
                                                onChange={onChange}
                                                value={data?.numberOfEmployees}
                                                disabled
                                                
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Ruolo
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="companyRole"
                                                onChange={onChange}
                                                value={data?.companyRole}

                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        }
                        
                        <hr className="opacity-10 my-3"/>
                    </Row>

                    {/* CONTATTI- SOCIAL */}
                    <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Indirizzo email *
                                        <CustomBadge variant="custom-badge-success">
                                            Verificato
                                        </CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        name="email"
                                        value={data?.email}
                                        onChange={onChange}
                                        readOnly
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0 ">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Numero di telefono *
                                    {/* <CustomBadge variant="custom-badge-danger" onClick={showOtpModal}>
                                        Da verificare
                                    </CustomBadge> */}
                                    </Form.Label>
                                    <Form.Control
                                        type="number" 
                                        name="phone"
                                        value={data?.phone}
                                        onChange={onChange}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr className="opacity-10 my-3" id="social"/>                   


                        <Row className="p-0" >
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Profilo Linkedin
                                
                                    </Form.Label>
                                    <Form.Control
                                        type="Text" 
                                        disabled
                                        
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                       

                        <Row className="p-0 d-flex flex-row" >
                            <Col md={6} className="mb-3" >
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Profilo Facebook
                                    
                                    </Form.Label>
                                    <Form.Control
                                       type="Text" 
                                       disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0 d-flex flex-row">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Sito internet
                                
                                    </Form.Label>
                                    <Form.Control
                                        type="Text" 
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row>

                    {/*FACE ID*/}
                    <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-1">
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label 
                                        className="fs-16 text-dark fw-bold"
                                    >
                                        Face Id**
                                    </Form.Label>

                                   {/*  {!faceIdPhoto && <div className="faceIdDiv" onClick={showFaceId}>
                                        <img loading="lazy" src={onlyFace} className="mb-3"/>
                                        <p >Carica il tuo Face ID</p>
                                    </div>} */}
                                </Form.Group>
                            </Col>
                            {
                                /* faceIdPhoto &&  */
                                <Row>
                                    <div className="roundImgContainer roundImg">
                                        <Image roundedCircle src={faceIdPhoto? faceIdPhoto : ""} className="roundImg border"/>
                                       {/*  <img loading="lazy" className="roundImgRemove" src={removeImg} onClick={handleRemoveFaceId}/> */}
                                    </div>
                                </Row>
                            }
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row>
                    
                    {/*DOCUMENTI*/}
                    <Row>
                        <Col md={12} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Documenti</p>
                        </Col>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Tipo di documento
                                    </Form.Label>
                                    <Form.Select
                                        {...register("documentType")}
                                        type="text" 
                                        name="documentType"
                                        value={data?.documentType}
                                        onChange={onChange}
                                        isInvalid={!!errors.documentType}
                                        disabled
                                    >
                                        <option></option>
                                        <option value="Carta d'identità">Carta d'identità</option>
                                        <option value="Passaporto">Passaporto</option>
                                        <option value="Patente">Patente</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.documentType?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Rilasciato da
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("issueEntity")}
                                        type="text" 
                                        name="issueEntity"
                                        value={data?.issueEntity}
                                        onChange={onChange}
                                        isInvalid={!!errors.issueEntity}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.issueEntity?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

        

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Data di rilascio
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("issueDate")}
                                        type="date" 
                                        name="issueDate"
                                        value={data?.issueDate}
                                        onChange={onChange}
                                        isInvalid={!!errors.issueDate}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.issueDate?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Luogo di rilascio
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("issuePlace")}
                                        type="text" 
                                        name="issuePlace"
                                        value={data?.issuePlace}
                                        onChange={onChange}
                                        isInvalid={!!errors.issuePlace}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.issuePlace?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Numero del documento
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("documentNumber")}
                                        type="text" 
                                        name="documentNumber"
                                        value={data?.documentNumber}
                                        onChange={onChange}
                                        isInvalid={!!errors.documentNumber}
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.documentNumber?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6}>
                                <div className="faceIdDiv">
                                       {/*  <img loading="lazy" src={uploadImg} className="mb-3"/>
                                        <p>Clicca qui o trascina i documenti</p> */}
                                        {/* <input type="file" /> */}
                                        Documento
                                    </div>
                            </Col>
                        </Row>
                        
                        <hr className="opacity-10 my-3"/>
                    </Row>
                    {data?.state != "APPROVED" && <div>
                        <ButtonRm variant="outline" className="me-2" onClick={showOtpModal}>Rifichiedi modifiche</ButtonRm>
                        <ButtonRm variant="outline-red" onClick={()=> setVerifiedModal(true)}>Verifica</ButtonRm>
                    </div>
                    }
                    

                </Form>

                {/* MODAL REQUEST */}
                <Modal
                    show={otpModal}
                    onHide={closeOtpModal}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                           {  step1 &&   <>
                            <img loading="lazy" src={logoRed} />

                                    <p className="fs-18 fw-bold mt-3 mb-2">Richiedi Modifiche</p>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Requisiti mancanti</Form.Label>
                                        <Form.Control as="textarea" rows={5} value={missingRequirements} onChange={(e) => handleMissingRequirements(e)}/>
                                    </Form.Group>
                                </> 
                            }

                            { !step1 && 
                                <>
                                    <div className="m-auto mt-2 text-center">
                                        <img
                                        src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                        width="80px"
                                        style={{ display: "flex", margin: "auto" }}
                                        ></img>
                                        <h4 className="mt-4">
                                        Avviso inviato
                                        </h4>
                                    </div>
                                </>
                            }

                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                                {
                                    step1 ? 
                                    <>
                                    <ButtonRm variant="primary" onClick={() => sendEmail()}>Invia avviso</ButtonRm>
                                    <ButtonRm variant="text" onClick={closeOtpModal}>Annulla</ButtonRm>
                                    </> 
                                    :
                                    <ButtonRm variant="primary" onClick={backToList}> Torna alla lista</ButtonRm>


                                }


                            </div>
                        </div>

                    </Modal.Body>
                </Modal>

                {/* MODAL verified */}
                <Modal
                    show={verifiedModal}
                    onHide={()=> setVerifiedModal(false)}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                    {isConfirmed ? (
                    <div className="px-4 pb-4 pt-0 text-center">
                        <div className="m-auto mt-2 text-center">
                            <img
                                src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                width="80px"
                                style={{ display: "flex", margin: "auto" }}
                            ></img>
                            <h4 className="mt-4">Account verificato</h4>
                        </div>

                        <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                            <ButtonRm variant="primary" onClick={backToList}>
                                Torna alla lista
                            </ButtonRm>
                        </div>
                    </div>
                ) : (

                    <div className="text-center px-4 pb-4 pt-0">
                        <h4>Confermi la verifica dell'utente?</h4>
                        <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                            <ButtonRm variant="secondary" className="mr-3" onClick={handleApproveUser}>
                                Conferma
                            </ButtonRm>
                            <ButtonRm variant="danger" onClick={() => setVerifiedModal(false)}>
                                Annulla
                            </ButtonRm>
                        </div>
                    </div>
                )}

                    </Modal.Body>
                </Modal>
            </div >
        </>

        
    )
}