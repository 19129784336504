import {useState, useRef, useEffect} from 'react'

import {Row, Col} from "react-bootstrap"
import { Form } from "react-bootstrap";

import TopHeading from "../../../TopHeading"
import ButtonRm from '../../../ButtonRm';
import {Button} from 'react-bootstrap';

import emptyState from "../../../../assets/images/checklist-empty.svg"

import Modal from 'react-bootstrap/Modal';

import { MultiSelect } from "react-multi-select-component";

import api from "../../../../api/api"
import {verbal_endpoint} from "../../../../api/api"

import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";



function EditChecklist(){


    const {editId} = useParams()

    const [checklistName, setChecklistName]= useState("")
    const [array, setArray] = useState([])
    const [show, setShow] = useState(false);

    /* FOR MULTISELECT */
    const [selected, setSelected] = useState([]);
    const navigate = useNavigate();


    /* PREVIEW MODAL */
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    /* PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE */
    const overrideString = { 
                            "selectAll": "Seleziona tutti",
                            "search": "Cerca",
                            "selectSomeItems": "Seleziona",
                            "allItemsAreSelected": "Tutti gli elementi sono selezionati"
                        }

    /* ADD ELEMENT TO ARRAY */
    function addElToArr(type, name = "", options = null, campoLibero = false){
            setArray(prev => [...prev, {id: array.length + 1, type, name, options, campoLibero}])
    }

    //HANDLE NAME 
    function handleChecklistName(e) {
        setChecklistName( e.target.value )
    }

    //DELETE FUNC
    function deleteAll(e){
        e.preventDefault()
        setArray([])
    }
    function deleteItem(id){
        let newArr = array.filter(el => el.id != id)
        setArray(newArr)
    }

    //HANDLE OPTIONS FOR DEFAULT FIELDS
    const statoOptions = [
        {id:1, name:"In corso"},
        {id:2, name:"Aperto"},
        {id:3, name:"In Ritardo"},
        {id:4, name:"Risolto"}
    ]

    const priorityOptions = [
        {id:1, name:"Alta"},
        {id:2, name:"Bassa"},
        {id:3, name:"Normale"},
    ]

    /* HANDLE INPUT NAMES FOR CAMPI LIBERI */

    function handleInputName(e, id){
        let newArr = array.map(el => {
            return el.id == id ?
                    {...el, name: e.target.value}:
                    el
        })
        setArray(newArr)
    }

    /* HANDLE INPUT OPTIONS FOR CAMPI LIBERI */

    function handleInputOptions(e, id){

        let newArr = array.map(el => {
            return el.id == id ?
                    {...el, options: [ e.target.value]}:
                    el
        })
        setArray(newArr)
    }

    /* DRAG */

    const btnList = [
        {   
            id:1,
            type: "text",
            name: "Titolo",
            options: null,
            campoLibero: false,
            labelBtn: "Titolo"
        },
        {   
            id:2,
            type: "select",
            name: "Stato",
            options: statoOptions,
            campoLibero: false,
            labelBtn: "Stato"
        },
        {   
            id:3,
            type: "select",
            name: "Priorità",
            options: priorityOptions,
            campoLibero: false,
            labelBtn: "Priorità"
        },
        {   
            id:4,
            type: "number",
            name: "Percentuale di Avanzamento",
            options: null,
            campoLibero: false,
            labelBtn: "Percentuale di Avanzamento"
        },
        {   
            id:5,
            type: "date",
            name: "Data di Scadenza",
            options: null,
            campoLibero: false,
            labelBtn: "Data di Scadenza",
        },
        {   
            id:6,
            type: "number",
            name: "",
            options: null,
            campoLibero: true,
            labelBtn: "Numero",
        },
        {   
            id:7,
            type: "text",
            name: "",
            options: null,
            campoLibero: true,
            labelBtn: "Testo Breve",
        },
        {   
            id:8,
            type: "textarea",
            name: "",
            options: null,
            campoLibero: true,
            labelBtn: "Descrizione",
        },
        {   
            id:9,
            type: "checkbox",
            name: "",
            options: null,
            campoLibero: true,
            labelBtn: "Casella di spunta",
        },
        {   
            id:10,
            type: "date",
            name: "",
            options: null,
            campoLibero: true,
            labelBtn: "Data",
        },
        {   
            id:11,
            type: "time",
            name: "",
            options: null,
            campoLibero: true,
            labelBtn: "Orario",
        },
        {   
            id:12,
            type: "select",
            name: "",
            options: [],
            campoLibero: true,
            labelBtn: "Scelta Singola",
        },
        {   
            id:13,
            type: "multiselect",
            name: "",
            options: [],
            campoLibero: true,
            labelBtn: "Scelta Multipla",
        }
    ]

    const btnCampiPredefiniti = btnList.filter(item =>!item.campoLibero)
    const btnCampiLiberi = btnList.filter(item => item.campoLibero)

    /* SORTABLE DRAG LIST */

    const [dragItemIndex, setDragItemIndex] = useState();
    const [dragOverItemIndex, setDragOverItemIndex] = useState();

    const handleDragStart = index => {
        setDragItemIndex(index)
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = index => {
        console.log(`move item ${dragItemIndex} to position ${index}}`)
        const newArr= [...array];
        const dragItemContent = newArr[dragItemIndex];
        newArr.splice(dragItemIndex, 1);
        newArr.splice(dragOverItemIndex, 0, dragItemContent);
        setDragItemIndex(undefined);
        setDragOverItemIndex(undefined);
        setArray(newArr);
    }

    const handleDragEnter = index => {
        console.log(`New position ${index}}`)
        setDragOverItemIndex(index)
    }

    const handleDragLeave = (event) => {
        setDragOverItemIndex(undefined)
    }

    const handleDragEnd = event => {
        setDragItemIndex(undefined);
        setDragOverItemIndex(undefined);
    }

    //EDIT
    async function editItem(data){
        try {
            await api().put(`${verbal_endpoint}${editId}`, data);
        } catch (error) {
        console.log(error);
        }
    }

    //GET ONE
    async function getOne(id){
        try {
        const response = await api().get(`${verbal_endpoint}${id}`);
            if (response.data) {
                const parsedData = response.data.content
                        ? JSON.parse(response.data.content.replace(/'/g, '"'))
                        : {}
                setChecklistName(response.data.name)
                setArray(parsedData)
            }
        }
        catch (error) {
        console.log(error);
        }
    }

    useEffect(() => {
        getOne(editId)
    }, [editId])


    /* FORM DATA */
    function handleSubmit(e) {
        e.preventDefault();
        let data;
        if (checklistName && array.length > 0) {
        data = {
            name: checklistName,
            content: JSON.stringify(array).replace(/"/g, "'"),
        };
        editItem(data);
        //ADD INPUT VALIDATION
        navigate(-1);
        }
    }
    const [draggingItemId, setDraggingItemId] = useState(null);

    /* DRAG AND DROP */
  
    const onDragEnd = (result) => {
      setDraggingItemId(null);
      if (!result.destination) return;
      const items = Array.from(array);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setArray(items);
    };
    

    return(
        <>
            <Row>
                <Col>
                    <TopHeading SmallHeading=""/>
                </Col>
            </Row>

            <Row className=" d-flex flex-column mt-md-4">
                <Col className='text-center'>
                <h2 className="fw-bold  fs-24">Modifica Checklist</h2>
                </Col>
                <Col className="d-flex justify-content-between align-items-center">
                    <Form>
                        <Form.Group className="my-3 d-flex flex-column" controlId="gestioneName">
                            <Form.Control
                            type="text"
                            placeholder="Inserisci il nome del verbale*"
                            style={{ height: "44px",  
                            borderRadius: "6px",
                            border: "1px solid #BDBDBD",
                            padding: "0 .5rem", 
                            width: '300px'
                            }}
                            onChange={handleChecklistName}
                            value={checklistName}
                            required
                            />
                        </Form.Group>
                    </Form>
                    <ButtonRm variant="tertiary" disabled={!array.length > 0} onClick={handleShow}>
                        Anteprima
                    </ButtonRm>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col lg={4} className="d-flex justify-content-center col-2-xxxl mb-2">
                    <div className="unit-container">                        
                        <div className="d-flex flex-column align-items-center py-3 px-5">
                            <p className='mb-2 fs-14'>CAMPI PREDEFINITI</p>
                                {
                                    btnCampiPredefiniti?.map(item => <ButtonRm key={item.id} variant="blue" size="full-width-small" className="mb-2" 
                                    onClick={() => {addElToArr(item.type, item.name, item.options, item.campoLibero)}}
                                    onDragEnd={() => {addElToArr(item.type, item.name, item.options, item.campoLibero)}}
                                    draggable>{item.labelBtn}</ButtonRm> )
                                }
                            <p className='my-2 fs-14'>CAMPI LIBERI</p>
                                {
                                    btnCampiLiberi?.map(item => <ButtonRm key={item.id} variant="blue" size="full-width-small" className="mb-2" 
                                    onClick={() => {addElToArr(item.type, item.name, item.options, item.campoLibero)}}
                                    onDragEnd={() => {addElToArr(item.type, item.name, item.options, item.campoLibero)}}
                                    draggable>{item.labelBtn}</ButtonRm> )
                                }
                        </div>
                    </div>
                </Col>

                <Col lg={8} className="col-10-xxxl">
                    <div className="unit-container position-relative d-flex flex-column  align-items-center " onDragOver={e => handleDragOver(e)}>
                        {/* SHOW EMPTY STATE */}
                        {
                            array.length == 0 &&
                            <div className='d-flex
                            flex-column justify-content-center align-items-center h-100' style={{color:'#B1B4BA'}}>
                                <img loading="lazy" src={emptyState} className="mb-2"/>
                                <p>Trascina i campi dalla colonna a sinistra</p>
                                <p>a questa tela per creare il tuo verbale
                                </p>
                            </div>
                        }
                        {/* SHOW CONTENT */}
                        {/* SHOW CONTENT */}
                        {array.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Row className=' w-100 flex-column mb-auto '>
                    <Row className="d-flex pt-3 mb-2 w-100 m-auto align-items-start ">
                        <Col className="fs-14 text-uppercase " sm={6}>
                            <p className="">Nome Campo</p>
                        </Col>
                        <Col className="fs-14 text-uppercase " sm={5}>
                            <p>Tipo di campo</p>
                        </Col>
                    </Row>
                    <Row className="d-flex w-100 m-auto ">

                <Droppable droppableId="droppable" >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="w-100 flex-column mb-auto"
                    >
                      {array.map((el, index) => (
                        
                        <Draggable
                          key={el.id.toString()}
                          draggableId={el.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="d-flex mb-2 w-100 m-auto align-items-start"
                            >
                              <InputCard
                                el={el}
                                index={index}
                                deleteItem={deleteItem}
                                handleInputName={handleInputName}
                                handleInputOptions={handleInputOptions}
                                draggingItemId={draggingItemId}
                                setDraggingItemId={setDraggingItemId}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                </Row>
                </Row>
              </DragDropContext>
                        )}
                        
                            <div className='new-checklist-btn-group mt-3 '>
                                <ButtonRm variant="tertiary" >
                                Annulla
                                </ButtonRm>
                                <ButtonRm variant="primary" className="ms-1" disabled={!array.length > 0 } onClick={handleSubmit} >
                                Salva
                                </ButtonRm>
                            </div>
                    </div>
                </Col>
            </Row>

            {/* ANTEPRIMA MODAL */}
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Anteprima</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: "300px", maxHeight: "400px", overflowY: "auto"}}>
                    <Form>
                    {
                        array.map(input => {
                            /* LOGICA PER OPZIONI X CAMPI LIBERI TYPE SELECT  */
                            /* PRENDO LA STRINGA VALUE DEL CAMPO OPZIONE, SPLITTO QUANDO TROVO UNA VIRGOLA E OTTENGO UN ARRAY CON LE DIVERSE OPZIONI */
                            let opzioniAnteprimaCampoLibero =  input.campoLibero && input.options?.length > 0? input.options[0].split(",") : ''
                            /* PER OGNI ELEMENTO DELL'ARRAY CREO UN OGGETTO, DA POTER MAPPARE PER CREARE LE OPZIONI DI QUEL CAMPO CHE SI VEDRA' IN VISUALIZZAZIONE ANTEPRIMA */
                            let arrayAnteprimaCampoLibero
                            if(opzioniAnteprimaCampoLibero.length > 1){
                                arrayAnteprimaCampoLibero = opzioniAnteprimaCampoLibero.map(item => {
                                    return {
                                        name: item
                                    }
                                })
                            }

                            /* MULTI SELECT */
                            let arrayAnteprimaCampoLiberoMulti
                            if(opzioniAnteprimaCampoLibero.length > 1){
                                arrayAnteprimaCampoLiberoMulti = opzioniAnteprimaCampoLibero.map(item => {
                                    return {
                                        value: item,
                                        label: item
                                    }
                                })
                            }

                            console.log(array)
                                                        
                            return <Form.Group className="mb-3 d-flex flex-column" key={input.id}>
                            {
                                input.type != "checkbox" &&
                                <Form.Label className='text-capitalize'>{input.name}</Form.Label>
                            }
                            
                                { input.type == "select"  &&
                                    <Form.Select aria-label="seleziona"  
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                    }}>
                                        {
                                            input.campoLibero && arrayAnteprimaCampoLibero?
                                            arrayAnteprimaCampoLibero.map(
                                                option => <option value={option?.name}>{option?.name}</option>
                                            ) :
                                            input.options.map(
                                                option => <option value={option?.name}>{option?.name}</option>
                                            )
                                        }
                                    </Form.Select>
                                }

                                { input.type == "multiselect"  && arrayAnteprimaCampoLibero &&
                                    <MultiSelect
                                        options={arrayAnteprimaCampoLiberoMulti}
                                        labelledBy="Seleziona"
                                        overrideStrings={overrideString}
                                        value={selected}
                                        onChange={setSelected}
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                        }}
                                    />
                                }   

                            

                                {/* TROVARE UN MODO PER CONDENSARE QUESTA LOGICA */}
                                {   input.type == "text" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type == "number" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type == "date" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type == "time" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {/* FINO A QUI */}

                                { input.type == "textarea" &&
                                    <Form.Control
                                    as="textarea"
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {   input.type == "checkbox" &&
                                    <Form.Check
                                        label={input.name}
                                        type={input.type}
                                    />
                                }
                                    
                        </Form.Group>
                        })
                    }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="mx-auto">
                        Chiudi Anteprima
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditChecklist


function InputCard(props){
    let tipo

    if(props.el?.type == "text"){ tipo = "Testo Breve"}
    if(props.el?.type == "date"){ tipo = "Data"}
    if(props.el?.type == "select"){ tipo = "Scelta Singola"}
    if(props.el?.type == "number"){ tipo = "Numero"}
    if(props.el?.type == "textarea"){ tipo = "Descrizione"}
    if(props.el?.type == "checkbox"){ tipo = "Casella di Spunta"}
    if(props.el?.type == "multiselect"){ tipo = "Scelta Multipla"}
    if(props.el?.type == "time"){ tipo = "Orario"}

    return (
        <>
             <Draggable draggableId={props.el.id.toString()} index={props.index}>
            {(provided, snapshot) => (
            <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="d-flex align-items-center p-0 mb-1 w-100"
          style={{
            cursor: "move",
            background: snapshot.isDragging ? "#eee" : "none",
            transition: "all ease 0.2s",
            opacity: snapshot.isDragging ? ".8" : "1",
          }}
        >
         
                <Col className="d-flex flex-column  justify-content-between border py-2 ps-2" sm={11}>
                        <Col className='d-flex align-items-center' >
                            <Col className="ms-2 " sm={6}>
                                {props.el.campoLibero?
                                    <Form.Control
                                    type="text"
                                    onChange={(e) => {props.handleInputName(e, props.el.id)}}
                                    placeholder="Definisci il nome del campo"
                                    value={props.el.name}
                                    style={{ height: "35px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem"
                                    }}
                                    required
                                    />:
                                    <p>{props.el?.name}</p>
                                    
                                }         
                            </Col>
                            <Col className="ms-4 " sm={5}>
                                <p>{tipo}</p>
                            </Col>
                            <Col className="pe-3 "><i className="bi bi-grip-vertical me-4"></i></Col>
                        </Col>
                        {props.el?.options &&
                        <Col className="d-flex align-items-center py-2 ps-2">
                            <p>Opzioni:</p>
                            {
                                props.el.campoLibero?
                                <Form.Control
                                type="text"
                                onChange={(e) => {props.handleInputOptions(e, props.el.id)}}
                                placeholder="Inserisci le opzioni separate da virgola"
                                value={props.el.options}
                                style={{ height: "35px",  
                                borderRadius: "6px",
                                border: "1px solid #BDBDBD",
                                padding: "0 .5rem",
                                width:"98%"
                                }}
                                required
                                />:
                                props.el?.options?.map(option => <OptionCard key={option.id} name={option.name}/>)
                                
                            }
                        </Col>
                        }
                </Col>
                <Col className="text-center"  onClick={()=>{{props.deleteItem(props.el.id)}}}>
                    <i className="bi bi-trash" style={{cursor:'pointer'}}></i>
                </Col>
            </div > )}
        </Draggable>

        </>
    )
}

function OptionCard(props){
    return(
        <div style={{background:"#eee", borderRadius:"10px", padding:".2rem .5rem", margin:"0 .2rem"}}>{props.name}</div>
    )
}