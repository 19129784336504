import React, { useState, useEffect, useRef } from "react";
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";
import PrintDownloadComponent from "../../PrintDownloadComponent";
import { connect } from "react-redux";
import { getProvince, getMunicipality } from "../../../actions/addressAction";
import { Link } from "react-router-dom";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import Modal from "react-bootstrap/Modal";
import api, { supplier_endpoint } from "../../../api/api";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../CustomToggle";
import Form from "react-bootstrap/Form";
import ButtonRm from "../../ButtonRm";


import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { useNavigate } from "react-router-dom";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "./mapping";

import CustomBadge from "../../CustomBadge";

const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const schema = yup.object().shape({
	address: yup.string().required("Inserisci l'indirrizzo"),
	houseNumber: yup.string().required("Inserisci il civico"),
	province: yup.string().required("Seleziona la provincia"),
	municipality: yup.string().required("Seleziona il comune"),
	cap: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
	administrator: yup.string(),
	name: yup.string().required("Inserisci un identificativo univoco per questo stabile"),
	notes:yup.string()
})


const RegistryE = (props) => {
	const navigate = useNavigate()    
	const [supplier, setSupplier] = useState([]);

	// useEffect(() => {
	// 	let e = { target: { name: "suppliers", value: supplier } };
	// 	onChange(e);
	// }, [supplier]);
	async function getSupplier() {
		try {
			const response = await api().get(`${supplier_endpoint}`);
			if (response.data) {
				setSupplier(response.data.content);
				console.log("supplier", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		props.getProvince();
		getSupplier();
	}, []);

	const [editMode, setEditMode] = useState(false);
	const handleSave = () => {
		props.update(data);
		setEditMode(false);
	};

	const cancelEdit = () => {
		setEditMode(false);
		// setBackModalShow(true);
	};

	const [data, setData] = useState({});

	const init = () => {
		setData({
			name: props.formData.name,
			fiscalCode: props.formData.fiscalCode,
			administrator: props.formData.administrator,
			address: props.formData.address,
			municipality: props.formData.municipality,
			houseNumber: props.formData.houseNumber,
			province: props.formData.province,
			cap: props.formData.cap,
			paymentCausal: props.formData.paymentCausal,
			accountantCode: props.formData.accountantCode,
			structures: props.formData.structures,
		});
	};

	useEffect(() => {
		init();
	}, [props.formData]);

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const componentRef = useRef();

	const [printData, setPrintData] = useState({});
	useEffect(() => {
		setPrintData({
			Nome: data.name,
			"Codice fiscale (opzionale)": data.fiscalCode,
			"Amministratore (opzionale)": data.administrator,
			Indirizzo: data.address,
			Comune: data.municipality,
			Civico: data.houseNumber,
			Provincia: data.province,
			CAP: data.cap,
			STRUTTURA: data.structures ? data.structures[0] : "",
		});
	}, [data]);

	useEffect(() => {
		props.getMunicipality(props.province[0]);
	}, [props.province]);

	const handleProvince = (e) => {
		onChange(e);
		props.getMunicipality(e.target.value);
	};

	const setFileName = (index, newName) => {
		let temp = [...data.structures];
		temp[index] = newName;
		let ele = { target: { name: "structures", value: temp } };
		onChange(ele);
	};

	const deleteFile = (index) => {
		let temp = [...data.structures];
		temp.splice(index, 1);
		let ele = { target: { name: "structures", value: temp } };
		onChange(ele);
	};

	const [modalShow, setModalShow] = useState(false);
	const [structureName, setStructureName] = useState("");

	const addStructure = () => {
		let temp = [...data.structures];
		temp.push(structureName);
		let ele = { target: { name: "structures", value: temp } };
		onChange(ele);
		setStructureName("");
		setModalShow(false);
	};

	  //HANDLE ACCORDION
	   const [activeKey, setActiveKey] = useState("")

	   	  //VALIDAZIONE INPUT
		const { register, handleSubmit, formState: { errors }, reset } = useForm({
			resolver: yupResolver(schema),
		});


	const onSubmit = (data) => {
		console.log({data})
			// controlla se ci sono errori di validazione
			if (Object.keys(errors).length > 0) {
				console.log("Ci sono errori di validazione", errors);
				return;
			} 
			// azione da eseguire quando i dati sono tutti corretti
			props.forward()
	};


	return (
		<>
			<div className="save-change-btn-group-stabili">
				<div
					className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}>
					<div
						className="edit-btn w-144 me-2"
						onClick={() => {
							setEditMode(true);
						}}>
						Modifica
					</div>
				</div>
				<div
					className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}>
					<div className="edit-btn w-173 me-2" onClick={handleSave}>
						Salva modifiche
					</div>
					<button
						type="button"
						onClick={cancelEdit}
						className="border border-secondary bg-white rounded-3 edit-icon-btn">
						<i
							className="bi bi-x"
							style={{ color: "var(--edit-color)" }}></i>
					</button>
				</div>
			</div>

			<div className="edit-content-container">
			{!editMode && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
				{/* <div className="d-flex mb-3 justify-content-end">
					<button
						type="button"
						className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
						onClick={handlePrint}>
						<BsPrinter color="var(--edit-color)" />
					</button>
					<button
						type="button"
						className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
						<BsDownload color="var(--edit-color)" />
					</button>
				</div> */}
				<div ref={componentRef} className="form-item-align-box d-block p-0">
					<fieldset disabled={editMode ? false : true}>
					<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<div className="form-item-align-box d-block p-0 pb-5">
				<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Identificativo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={12} className="f-label mb-3">
									<p>Scegli un identificativo univoco per questo stabile</p>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
										>
										Identificativo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
										{...register("name")}
										type="text" 
										name="name"
										onChange={onChange}
										value={data.name}
										isInvalid={!!errors.name}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.name?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								
							</Row>



						</Accordion.Body>
					</Accordion.Item>
					
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Dati <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Indirizzo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Control 
									{...register("address")}
									type="text" 
									name="address"
									onChange={(e) => {
										onChange(e);
									}}
									value={data.address}
									isInvalid={!!errors.address}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.address?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
										>
										Numero Civico <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
										{...register("houseNumber")}
										type="text" 
										name="houseNumber"
										onChange={onChange}
										value={data.houseNumber}
										isInvalid={!!errors.houseNumber}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.houseNumber?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								
							</Row>

							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Provincia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
									{...register("province")}
									isInvalid={!!errors.province}
									name="province"
									value={data.province}
									onChange={handleProvince}>
									{props.province.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.province?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Comune <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
									{...register("municipality")}
									isInvalid={!!errors.municipality}
									name="municipality"
									value={data.municipality									}
									onChange={onChange}>
										<option value={data.municipality}>
												{data.municipality}
											</option>
									{props.municipality?.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.municipality?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Cap
									</Form.Label>
									<Form.Control 
										{...register("cap")}
										type="number" 
										isInvalid={!!errors.cap}
										name="cap"
										onChange={(e) => {
											onChange(e);
										}}
										value={data.cap}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cap?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Codice fiscale
										</Form.Label>
										<Form.Control 
											{...register("fiscalCode")}
											type="text" 
											isInvalid={!!errors.fiscalCode}
											name="fiscalCode"
											onChange={onChange}
											value={data.fiscalCode}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.cap?.fiscalCode}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>

							<Row className="mb-3">
									<Col md={6} className="d-flex align-items-center">
										<Form.Group className="me-2" style={{width: editMode ? "100%" : "90%"}}>	
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Amministratore
											</Form.Label>
											<Form.Select 
												{...register("administrator")}
												name="administrator"
												value={data.administrator}
												onChange={onChange}
												isInvalid={!!errors.administrator}>
												<option value="">Seleziona</option>
												{supplier?.map((item, index) => {
													if (item.category == "Amministratore")
														return (
															<option
																key={index}
																value={item.name + " " + item.surname}>
																{item.name + " " + item.surname}
															</option>
														);
												})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.administrator?.message}
											</Form.Control.Feedback>
										</Form.Group>
										{editMode && <BsPlusCircleFill
											className="col-sm-2 col-md-1 fs-30 plus-button mt-4"
											onClick={() => navigate("/fornitori/create")}
										/>}
										
									</Col>
							</Row>	


						</Accordion.Body>
					</Accordion.Item>

					

					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Struttura</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={12} className="f-label mb-3">
									<p>Per struttura si intende la composizione dell’edificio, ad esempio se comprende più scale o palazzine. <br/>
									Per ogni struttura aggiungi una nuova voce e definisci un identificativo.</p>
								</Col>
							</Row>
							<Row className="mb-3"> 
								<Col md={12}>
								{editMode && <label
					className="black-link mt-2"
					onClick={() => {
						setModalShow(true);
					}}>
					<BsPlusCircleFill className="plus-icon" />
					Aggiungi struttura
				</label>}
				<div className="mt-3">
					{data.structures?.length > 0 &&
						data.structures.map((item, index) => {
							return (
								<FileCard
									key={index}
									name={item}
									setFileName={setFileName}
									deleteFile={deleteFile}
									id={index}
								/>
							);
						})}
						{data.structures?.length == 0 && !editMode &&
						<div className="text-center">
							Nessuna struttura creata
						</div>
						}
				</div>
								</Col>
								
							</Row>



						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="5" activeKey={activeKey}>Note</CustomToggle>
							</Card.Header>
							<Accordion.Body>
								<Row>
									<Col>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Note
											</Form.Label>
											<Form.Control 
												{...register("notes")}
												as="textarea"
												name="notes"
												value={data.notes}
												onChange={onChange}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>	


				</Accordion>
				</div>

			</Form>


			<Modal show={modalShow} centered>
				<Modal.Body className="p-5">
					<div className="d-block w-100 mb-3">
						<div>
							<h2 className="fs-20 text-dark mb-2 fw-bold text-center">
								Aggiungi una struttura
							</h2>
						</div>
					</div>
					<div className="form-item-align-box d-block">
						<div className="mt-3">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Nome</label>
								<input
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={(e) => {
										setStructureName(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-last-btns mt-3 section-modal-btn">

							<button
								type="button"
								className="next-btn btn-last rounded-3 me-2 border-0 col-md-12"
								disabled={structureName !== "" ? false : true}
								onClick={addStructure}>
								Aggiungi
							</button>
							<button
								type="button"
								className="black-btn btn-last rounded-3  border-0 col-md-12 mt-3"
								onClick={() => {
									setModalShow(false);
								}}>
								Annulla
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

					</fieldset>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
});
export default connect(mapStateToProps, { getProvince, getMunicipality })(
	RegistryE
);
