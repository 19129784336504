/*
import units from './data/units.json' assert {type: 'json'}
import managements from './data/managements.json' assert {type: 'json'}
import activities from './data/activities.json' assert {type: 'json'}
*/

function parseExpensesFromChatGroup(chatGroup) {
    const data = []
    for (const cg of chatGroup) {
        if (cg.chats?.length < 1) {
            continue
        }
        for (const chat of cg.chats) {
            const isDeletedChat = chat.deleted !== false
            const isExpenses = chat.messageType === 'spesa'
            const isApprovedQuotation = chat.messageType === 'preventivo' && chat.confirmContents[0]?.accepted === true
            if (isDeletedChat || (!isExpenses && !isApprovedQuotation)) {
                continue
            }
            const normalizedContent = chat.content.replaceAll("'", '"')
            const contents = JSON.parse(normalizedContent)
            for (const content of contents) {
                for (const row of content.rows) {
                    if (row['Costo unitario'] === '' || row['Quantià'] === '') {
                        continue
                    }
                    data.push({
                        content_name: content.name,
                        content_id: content.id,
                        name: row['Nome'],
                        unit_cost: row['Costo unitario'],
                        quantity: row['Quantità'],
                    })
                }
            }
        }
    }
    return data
}

function parseNormalizedExpensesFromActivities(activities) {
    const expenses = []
    for (const activity of activities) {
        if (activity?.chatGroup.length < 1) {
            continue
        }
        const activityCosts = parseExpensesFromChatGroup(activity.chatGroup)
        if (activityCosts.length < 0) {
            continue
        }
        const activityExpenses = activityCosts.map(ac => ({
            ...ac,
            activity_name: activity.name,
            activity_id: activity.id
        }))
        expenses.push(activityExpenses)
    }
    return expenses
}

function parseNormalizedExpensesFromUnits(units) {
    const activities = []
    if(units?.length > 0 ) {
        for (const unit of units) {
            for (const activity of unit.activities) {
                if (activity?.chatGroup.length < 1) {
                    continue
                }
                const activityCosts = parseExpensesFromChatGroup(activity.chatGroup)
                if (activityCosts.length < 0) {
                    continue
                }
                const activityExpenses = activityCosts.map(ac => ({
                    ...ac,
                    activity_name: activity.name,
                    unit_name: unit.name,
                    unit_id: unit.id,
                }))
                activities.push(activityExpenses)
            }
        }
    }
    return activities
}


function parseActivities(activities) {
    const activitiesExpenses = {}
    const activityExpenses = parseNormalizedExpensesFromActivities(activities)
    activityExpenses.forEach(activityExpense => {
        activityExpense.forEach(ae => {
            if (!activitiesExpenses[ae.activity_name]) {
                activitiesExpenses[ae.activity_name] = {}
            }
            if (!activitiesExpenses[ae.activity_name][ae.content_name]) {
                activitiesExpenses[ae.activity_name][ae.content_name] = {
                    cost: 0,
                    expenses: []
                }
            }
            activitiesExpenses[ae.activity_name][ae.content_name]['cost'] += parseFloat(ae.unit_cost) * parseInt(ae.quantity)
            activitiesExpenses[ae.activity_name][ae.content_name]['expenses'].push(
                {nome: ae.name, 'costo unitario': parseFloat(ae.unit_cost), 'quantità': parseInt(ae.quantity)}
            )
        })

    })
    return activitiesExpenses
}

function parseUnits(units) {
    const unitsExpenses = {}
    const activityExpenses = parseNormalizedExpensesFromUnits(units)
    activityExpenses.forEach(activityExpense => {
        activityExpense.forEach(ae => {
            if (!unitsExpenses[ae.unit_name]) {
                unitsExpenses[ae.unit_name] = {}
            }
            if (!unitsExpenses[ae.unit_name][ae.activity_name]) {
                unitsExpenses[ae.unit_name][ae.activity_name] = {
                    cost: 0,
                    expenses: []
                }
            }
            unitsExpenses[ae.unit_name][ae.activity_name]['cost'] += parseFloat(ae.unit_cost) * parseInt(ae.quantity)
            unitsExpenses[ae.unit_name][ae.activity_name]['expenses'].push(
                {nome: ae.content_name, 'costo unitario': parseFloat(ae.unit_cost), 'quantità': parseInt(ae.quantity)}
            )
        })

    })
    return unitsExpenses
}


function parseManagements(managements) {
    const managementExpenses = {}
    managements.forEach(management => {
        const unitExpenses = parseNormalizedExpensesFromUnits(management.realEstateManagements)
        unitExpenses.forEach(expenses => {
            const individualExpenses = expenses.map(exp => ({
                ...exp,
                management_name: management.name,
                management_id: management.id
            }))
            individualExpenses.forEach(ae => {
                if (!managementExpenses[ae.management_name]) {
                    managementExpenses[ae.management_name] = {}
                }
                if (!managementExpenses[ae.management_name][ae.unit_name]) {
                    managementExpenses[ae.management_name][ae.unit_name] = {
                        cost: 0,
                        expenses: []
                    }
                }
                managementExpenses[ae.management_name][ae.unit_name]['cost'] += parseFloat(ae.unit_cost) * parseInt(ae.quantity)
                managementExpenses[ae.management_name][ae.unit_name]['expenses'].push(
                    {nome: ae.activity_name, 'costo unitario': parseFloat(ae.unit_cost), 'quantità': parseInt(ae.quantity)}
                )
            })
        })

    })

    return managementExpenses
}

/*
const datasource = {
    activities: parseActivities(activities),
    units: parseUnits(units),
    managements: parseManagements(managements)
}
*/


export {parseActivities, parseUnits, parseManagements}