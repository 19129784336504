import React, { useState } from 'react'
import Section2 from './page5/section2';
export default function Page4() {
    const [status, setStatus] = useState(2);
    const Section = () => {
        switch (status) {
            case 2:
                return <Section2 onChangeSection={(v) => setStatus(v)} />
                break;
        }
        return;
    }
    return (
        <Section />
    )
}

