import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "../../../../assets/css/Membri.css";
import cardimage from "../../../../assets/images/fornitore-ph.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import api, { supplier_endpoint } from "../../../../api/api";
import SubjectCard from "../../../Documenti/prima_nota/Tipologia/SubjectCard";
import SupplierModal from "../../../Documenti/prima_nota/Tipologia/SupplierModal";
import ButtonRm from "../../../ButtonRm";

import { BankCard,BankCardItem } from "../../../BankCard";


export default function Fornitori(props) {
  const [show, setShow] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  const fetchSuppliers = async () => {
    try {
      const res = await api().get(`${supplier_endpoint}`);
      if (res.data) {
        console.log(res.data.content);
        setSupplierList(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const addSelectedSuppliers = (id) => {
    let filteredList = props.formData.suppliers.filter(
      (supplier) => supplier.id === id
    );
    filteredList.length === 0 &&
      props.formData.suppliers.push(
        supplierList.filter((supplier) => (supplier.id === id))[0]
      );
    props.setFormData({
      ...props.formData,
      suppliers: props.formData.suppliers,
    });
  };

  const removeSelectedSupplier = (id) => {
    console.log(props.formData.suppliers);

    props.setFormData({
      ...props.formData,
      suppliers: props.formData.suppliers.filter(
        (supplier) => supplier.id !== id
      ),
    });
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const forward = () => {
    props.next();
  };

  /* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname

  return (
    <>
      <div
        className="d-block w-100 mb-3 bg-white"
        style={{ borderRadius: "16px" }}
      >
        <Form>
          <div className="form-item-align-box d-block pt-5 pb-5">
            <div className="row gy-4">
              <div className="col-12">
                <div className="d-flex align-items-center form-last-btns">
                <ButtonRm variant="tertiary" className="me-2">Nuovo</ButtonRm>
                <ButtonRm variant="tertiary" onClick={handleShow}>Seleziona</ButtonRm>
                </div>
              </div>
              {props.formData.suppliers?.length > 0 ? (
                props.formData.suppliers.map((supplier, index) => (
                <BankCard
                key={index} 
                name={supplier.businessName}
                color="#FFB36D" 
                deleteFunc={removeSelectedSupplier} 
                index={supplier.id}>
                  <BankCardItem property="CATEGORIA" value={supplier.category}/>
                  <BankCardItem property="NOME" value={supplier.name}/>
                  <BankCardItem property="COGNOME" value={supplier.surname}/>
                  {supplier.cellularTelephone && <BankCardItem property="TELEFONO" value={supplier.cellularTelephone}/>}
                </BankCard>
                ))
              ) : (
                <div className="col-12 mt-5">
                  <div className="mx-auto d-table text-center">
                    <div className="image-user mx-auto d-table w-160 fw-100">
                      <img loading="lazy" src={cardimage} alt="user-key" />
                    </div>
                    <p className="fs-18 text-secondary text-center">
                      Nessun fornitore associato
                    </p>
                  </div>
                </div>
              )}
              { path == "/proprietari/create" && <div className="col-12">
              <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
                <ButtonRm variant="tertiary" onClick={() => props.back()} className="me-2">Indietro</ButtonRm>
                <ButtonRm variant="primary"  onClick={forward}>Avanti</ButtonRm>
              </div>
            </div>}
            </div>
          </div>
        </Form>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="acc-table-box"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body className="p-5">
          <Button
            variant="secondary"
            onClick={handleClose}
            className="float-end bg-white border-0 text-dark"
          >
            <i className="bi bi-x"></i>
          </Button>
          <SupplierModal
            table_data={
              supplierList.length > 0 &&
              supplierList.map((supplier) => [
                supplier.category,
                supplier.businessName,
                supplier.cellularTelephone,
                supplier.subjectType,
              ])
            }
            ids={supplierList.map((supplier) => supplier.id)}
            setShow={setShow}
            addSupplier={addSelectedSuppliers}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
