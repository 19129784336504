import React, { useState, useEffect } from "react";
import TopHeading from "../components/TopHeading";
import EmptyEstate from "../assets/images/empty_estate.svg";
import { BsPlusCircleFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../assets/css/REUnitsEstablishments.css";
import api, { unit_endpoint, owner_endpoint, management_endpoint } from "../api/api";
import UnitFirstPage from "../components/UnitàImmobiliariStabilli/UnitàImmobiliari/UnitFirstPage";


import {Row, Col} from "react-bootstrap"
import { Link } from "react-router-dom";
import AddBtn from "../components/AddBtn";

import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

import {type as category} from "../components/UnitàImmobiliariStabilli/constant.js"

import { checkUserData } from "../utils/checkUserRole.js";
import ModalCheckUser from "../components/ModalCheckUser.js";

import SearchInput from "../components/SearchInput";

const UnitàImmobiliari = () => {
  const [units, setUnits] = useState([]);
  const [gestioni, setGestioni] = useState([]);
  const [owners, setOwners] = useState([]);

  async function getUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getGestioni() {
    try {
      const response = await api().get(`${management_endpoint}`);
      if (response.data) {
        setGestioni(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getOwners() {
    try {
      const response = await api().get(`${owner_endpoint}`);
      if (response.data) {
        setOwners(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getUnits()
    getGestioni()
    getOwners()
  }, []);

  const deleteUnit = (id) => {
    let temp = [...units];
    temp = temp.filter((item) => item.id !== id);
    setUnits(temp);
  };


 //NEW SEARCH & FILTER

	//SEARCH

	const [searchTerm, setSearchTerm] = useState("")

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  


    const categories = [
      {
        name: 'Categoria',
        items: category
      },
      {
        name: 'Proprietari',
        items: owners.map((owner) => `${owner.surname} ${owner.name}`),

      },
      {
      name: 'Gestione',
      items: gestioni.map(el => String(el.id)),
    }

    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])

    console.log(selectedCategories)


    useEffect(() => {
      if (units.length > 0) {
        setFilteredUnits(
          units.filter((unit) => {

            const unitDate = new Date(unit.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && unitDate < new Date(startDate)) {
              return false;
            }
    
            // Filtro per la data di fine (endDate)
            if (endDate && unitDate > new Date(endDate)) {
              return false;
            }
    
            return (
              (selectedCategories.length === 0 ||
                selectedCategories.some((cat) => {
                  if (cat.name === 'Proprietari') {
                    return unit.owners.some((owner) => {
                      const ownerFullName = `${owner.surname} ${owner.name}`;
                      return cat.items.includes(ownerFullName);
                    });
                  } if (cat.name === "Gestione") {
                    return gestioni.some((gestione) =>
                      gestione.realEstateManagements?.some(
                        (management) =>
                          management.unitID == unit.id && cat.items.includes(String(gestione.id))
                      )
                    );
                  } else {
                    return cat.items.includes(unit.category);
                  }
                })) &&
              unit.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
        );
      }
    }, [searchTerm, selectedCategories, units, startDate, endDate]);
    

		const [sortedUnits, setSortedUnits] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedUnits(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedUnits(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(units);
    setStartDate("")
    setEndDate("")
		}


    //CHECK IF DATA REQUIRED ARE COMPILED
    const [dataRequired, setDataRequired] = useState(true);
    //Gestisce il modale
    const [showCheck, setShowCheck] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await checkUserData();
                setDataRequired(response.isVerified);
                if (!response.isVerified) {
                  setShowCheck(true);
              }
            } catch (error) {
                // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                console.error('Errore durante il recupero dei dati utente:', error);
            }
        };

        fetchData();
    }, []);




  return (
    <>
      <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="empty"
          />
        </div>

        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Unità</h2>
          </Col>
          <Col className="d-flex justify-content-end ">
            <Link to="/real-estate-units/create">
              <AddBtn
                tooltip="Nuova unità"
                className="me-3 me-md-0 order-md-1"
                dataRequired={dataRequired}
              />
            </Link>
            {/* <SearchInput
              className="order-md-0"
              setSearch={(term) => {
                setSearchTerm(term);
              }}
            /> */}
          </Col>
        </Row>
        {/* <div className="model-modul-group">
          <div className="d-flex align-items-center mt-2 justify-content-between btn-search-group">
            <div className="search-input">
              <div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
                <i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
                <input
                  type="text"
                  name=""
                  placeholder="Cerca"
                  className="flex-shink-0 border-0 w-100 fs-16"
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </div>
            </div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 300 }}
              overlay={
                <Tooltip className="tooltip-container">
                  Nuovo unità immobiliari
                </Tooltip>
              }
            >
              <Link to="/real-estate-units/create">
                <div className="plus-button">
                  <BsPlusCircleFill className="fs-40 plus-btn" />
                </div>
              </Link>
            </OverlayTrigger> 
          </div>
        </div> */}
      </div>
      {units.length > 0 ? (
        <UnitFirstPage data={units} deleteUnit={deleteUnit}  
        sortedUnits={sortedUnits}
        setItems={setUnits}
        getUnits={getUnits}
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        clearFiltersAndSort={clearFiltersAndSort}
        setSortBy={setSortBy}
        categories={categories}
        startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}/>
      ) : (
        <Row className="dashboard-container ">
          <div className="mx-auto text-center empty-container ">
            <div className="mx-auto d-table w-160">
              <img loading="lazy" src={EmptyEstate} alt="empty" />
            </div>
            <p className="fs-20 text-secondary text-center mt-3">
              Non hai aggiunto ancora nessuna unità immobiliare
            </p>
          </div>
        </Row>
      )}
      <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione dell'unità, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>
    </>
  );
};

export default UnitàImmobiliari;
