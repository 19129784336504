import React from "react";
import logo from "../../assets/images/logo-red.svg";

import "../../assets/css/RegisterSuccess.css";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import ButtonRm from "../ButtonRm";

import api from "../../api/api";

import {useState, useEffect} from "react"

export default function RefreshAccount() {

  const navigate  = useNavigate()

  const login  = () => {
    navigate("/");
  };

  const [ newUrl, setNewUrl ] = useState()

  const handleOpenPopup = (url) => {
    const popupUrl = url
     // Dimensioni desiderate per la finestra popup
    const width = 600;
    const height = 800;

    // Calcola le posizioni left e top per centrare la finestra
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
    const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
        
    // Apertura della finestra popup
    window.open(popupUrl, "StripeOnboarding", options);
};


  async function startOnboarding() {
    try {
        const response = await api().post(`/api/v1/payment/create`, {}); 
        setNewUrl(response.data.message)
        console.log(response)
    } catch (err) {
        console.log(err);
    }
    
} 

useEffect(() => {

  if(newUrl) {
    handleOpenPopup(newUrl)
  }
}, [newUrl])


  return (
    <>
      <div className="register-div">
        <img loading="lazy" src={logo} alt="logo" className="register-logo"></img>
        <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            <img
              src="https://cdn0.iconfinder.com/data/icons/pinpoint-notifocation/48/none-512.png"
              width="80px"
              style={{ display: "flex", margin: "auto" }}
            ></img>
            <h3 className="mt-4">
                Questo link non è più valido
            </h3>
            <p className="opacity-75 mb-4 px-3" style={{ maxWidth: "600px" }}>
                Se hai già creato il tuo account per i pagamenti non devi fare altro, puoi tornare alla Home.
                Se vuoi creare un account di pagamento clicca sul pulsante "Crea un nuovo link".
                Verrai reindirizzato al processo di creazione.
            </p>

            <ButtonRm variant="primary" onClick={() => startOnboarding()}>Crea un nuovo link</ButtonRm>
            <br/>
            <ButtonRm variant="text" onClick={login}>Torna alla home</ButtonRm>

          </div>
        </div>
      </div>
       <Footer />
    </>
  );
}
