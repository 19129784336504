import { useState, useEffect } from "react";
import REUnitCard from "./REUnitCard";
import filter_img from "../../../assets/images/filter.svg";
import sort_img from "../../../assets/images/sort.svg";
import { Link } from "react-router-dom";
import {Row, Col, Modal} from "react-bootstrap"

import FilterSearchBar from "../../StatoLavoriInCorso/Gestioni/FilterSearchBar";
import translationMapping from "./mapping";

import api, { unit_endpoint } from "../../../api/api";

import { checkMaster, checkUserRole } from "../../../utils/checkUserRole";
import Alert from "react-bootstrap/Alert";

import { MultiSelect } from "react-multi-select-component";
import ButtonRm from "../../ButtonRm";

import { formattedTimestamp } from "../../../utils/dateManipulation";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TableSortLabel } from '@mui/material';

import CustomBadge from "../../CustomBadge";

// Definisci le varianti dei badge
const badgeVariants = [
	'custom-badge-success',
	'custom-badge-danger',
	'custom-badge-alert',
	'custom-badge-info',
	'custom-badge-pagina',
	'custom-badge-gray',
	'custom-badge-red',
	'custom-badge-orange',
	'custom-badge-yellow',
	'custom-badge-lime',
	'custom-badge-green',
	'custom-badge-teal',
	'custom-badge-turquoise',
	'custom-badge-aqua',
	'custom-badge-blue',
	'custom-badge-ultramarine',
	'custom-badge-purple',
	'custom-badge-pink',
	'custom-badge-transparent',
  ];

  //ADD ALTRI CAMPI
	const headerNames = [
		"cdat",
		"category",
		"name",
		"address",
		"houseNumber",
		"province",
		"municipality",
		"cap",
		"grossCoveredMq",
		"municipalityCode",
		"sheet",
		"categoryLandRegistry",
		"classLandRegistry",
		"cadastralIncome",
		"urbanSection",
		"zone",
		"microZone",
	];
  
  // Funzione per ottenere una variante casuale
  const getRandomBadgeVariant = () => {
	const randomIndex = Math.floor(Math.random() * badgeVariants.length);
	return badgeVariants[randomIndex];
  };

  const columnBadgeVariantMap = {};
headerNames.forEach((header, index) => {
  columnBadgeVariantMap[header] = badgeVariants[index % badgeVariants.length];
});


const UnitFirstPage = (props) => {

	const [sortOption, setSortOption] = useState(0);

	//gestione visione tabellare
	const [multiView, setMultiView] = useState(true)
	const [viewType, setViewType] = useState(localStorage.getItem('viewTypeUnit') || 'card');

	useEffect(() => {
	  localStorage.setItem('viewTypeUnit', viewType);
	}, [viewType]);

	

	const mappingFromCompetitor1 = {
		'Categoria': 'category',
		'Tipologia': 'typology',
		'Nome': 'name',
		'Condominio': 'condominium',
		'Indirizzo': 'address',
		'Civico': 'houseNumber',
		'Interno': 'internal',
		'Scala': 'scale',
		'Piano': 'floor',
		'Comune': 'municipality',
		'Prov': 'province',
		'CAP': 'cap',
		'Locatore di riferimento': 'owners',
		'Sezione urbana': 'urbanSection',
		'Foglio': 'sheet',
		'Particella': 'parcel',
		'Zona censuaria': 'zone',
		'Microzona': 'microZone',
		'Subalterno': 'subaltern',
		'Categoria': 'categoryLandRegistry',
		'Classe': 'classLandRegistry',
		'Vani': 'rooms',
		'Rendita catastale (o Domenicale nel caso dei terreni)': 'cadastralIncome',
		'Codice comune catastale': 'municipalityCode',
	  };

	const [selectedItems, setSelectedItems] = useState([]);

	// Funzione per gestire la selezione multipla
	const handleSelection = (e, id) => {
		if(e){
			e.stopPropagation()
		}

		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
		} else {
			setSelectedItems([...selectedItems, id]);

		}
	};

	const handleSelectAll = () => {
		let selectedItems = props.sortedUnits.map((item, index) => item.id)
		setSelectedItems(selectedItems)
	}

	const handleDeselectAll = () => {
		setSelectedItems([])
	}

	// Funzione per eliminare gli stabili selezionati
	const deleteSelected = async () => {
		// Effettua l'operazione di eliminazione per ogni id selezionato
		for (const id of selectedItems) {
		try {
			await api().delete(`${unit_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
		}
		await props.getUnits()
		setSelectedItems([]);

	};
  

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//handle modal permessi
	const [showPermit, setShowPermit] = useState(false);
	const handleClosePermit = (e) => {
		setShowPermit(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShowPermit = () => setShowPermit(true);
	

	  //MI SERVE LISTA COLLABORATORI MASTER
	  const [members, setMembers] = useState([
		{
			id:1,
			name:"Ema",
			surname:"Mavi",
			email:"ema@gmail.com"
		},
		{
			id:2,
			name: 'Greta',
			surname: 'Pio',
			email: 'gre@gmail.com'
		}
	  ])
	
	  const handleCheckboxChange = (userId, action) => {
		//da gestire
	  };


	  const [order, setOrder] = useState('asc');
	  const [orderBy, setOrderBy] = useState('');
	
	  const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	  };
	
	  const sortedUnits = [...props.sortedUnits].sort((a, b) => {
		if (orderBy) {
			if (a[orderBy] && b[orderBy]) { 
				return order === 'asc'
				  ? a[orderBy].localeCompare(b[orderBy])
				  : b[orderBy].localeCompare(a[orderBy]);
			  }
		}
		return 0;
	  });


	return (
		<>
		<Row className="dashboard-container">
			
			   {<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<FilterSearchBar
					setSearchTerm={props.setSearchTerm}
					selectedCategories={props.selectedCategories}
					setSelectedCategories={props.setSelectedCategories}
					clearFiltersAndSort={props.clearFiltersAndSort}
					setSortBy={props.setSortBy}
					categories={props.categories}
					startDate={props.startDate} 
					endDate={props.endDate}
					setStartDate={props.setStartDate} setEndDate={props.setEndDate}

					multiView={multiView}
					setViewType={setViewType}
					viewType={viewType}
            	/>
			</Col>}
			<div className="mb-3">
				{checkUserRole() &&
                	selectedItems.length > 0 && 
					<div className="d-flex mb-3">
						<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
							Seleziona tutti
						</ButtonRm>
						<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
							Deseleziona Tutti
						</ButtonRm>
					</div>
				}
				{viewType == 'card' ? 
					props.sortedUnits.map((item, index) => {
							return (
								<div key={index}>
									
										<REUnitCard
											id={item.id}
											type={item.category}
											name={item.name}
											address={item.address}
											internal={item.internal}
											scale={item.scale}
											province={item.province}
											municipality={item.municipality}
											cap={item.cap}
											floor={item.floor}
											img_url={item.image}
											categoryLandRegistry={item.categoryLandRegistry}
											coveredMq={item.grossCoveredMq}
											notCoveredMq={item.notCoveredMq}
											deleteUnit={props.deleteUnit}
											owners={item.owners}
											item={item}
											handleSelection={handleSelection}
											selectedItems={selectedItems}
										/>
								</div>
							);
					  })
					: 
					  <>
					  <TableContainer component={Paper} className="table-font table-activity">
      					<Table stickyHeader aria-label="simple table  table-activity">
       						<TableHead>
          					    <TableRow className="table-font table-act-head-cell">
									<TableCell style={{ minWidth: '10px', paddingLeft: "16px !important"  }} className="table-font table-act-head-cell"></TableCell>
									{headerNames.map((header) => (
									<TableCell style={{ minWidth: '150px', paddingLeft: "16px !important" }} className="table-font table-act-head-cell" key={header}>
									<TableSortLabel
										active={orderBy === header}
										direction={orderBy === header ? order : 'asc'}
										onClick={() => handleRequestSort(header)}
										>
										{translationMapping[header]}
										</TableSortLabel>
									</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody className="table-font">
							{sortedUnits.map((item, index) => (
								<TableRow key={item.id} className="table-font cursor-pointer" >
									<TableCell style={{ minWidth: '5px' }} className="table-font">
									<input
										type="checkbox"
										checked={selectedItems.includes(item.id)}
										onChange={(e) => handleSelection(e, item.id)}
										className="me-2"
									/>
									</TableCell>
									{headerNames.map((header) => {
                						const variant = columnBadgeVariantMap[header];

										return header == 'cdat'?
											<TableCell td key={header}  style={{ minWidth: '150px' }}
											className="table-font cursor-pointer"> 
												      {item[header] && 
												<Link to={`/real-estate-units/edit/${item.id}`}>
													<CustomBadge variant={variant} margin="mx-0">
													
														{formattedTimestamp(item[header])}
													
													</CustomBadge>
												</Link>
												}
											</TableCell>:
											<TableCell key={header} style={{ minWidth: '150px' }}
											className="table-font cursor-pointer"> 
											      {item[header] && 
													<Link to={`/real-estate-units/edit/${item.id}`} className="text-dark" >
													<CustomBadge variant={variant} margin="mx-0">
														
														{header == "cadastralIncome" ?  (item[header] ? `€ ${parseFloat(item[header]).toFixed(2)}` : '') : item[header]}
														
													</CustomBadge>
												</Link>
												}
											</TableCell>
									})}
								</TableRow>
							))}
							</TableBody>
						</Table>
					</TableContainer>
					{/* <div className="table-view-custom-container">
									<table className="document-table-note table-bordered">
									<thead>
									<tr className="min-width-custom">
										<th></th>
	
									{headerNames.map((header) => (
										<th key={header} className='border'>{translationMapping[header]}</th>
										))}
										</tr>
									</thead>
										<tbody>
									{props.sortedUnits.map((item, index) => (
										<tr  key={index}>
											<td>
											<input
												type="checkbox"
												checked={selectedItems.includes(item.id)}
												onChange={(e) => handleSelection(e, item.id)}
												className="me-2"
											/>
											</td>
										{headerNames.map((header) => {
											return header == 'cdat'?
												<td td key={header} className='border min-width-custom'> 
													<Link to={`/real-estate-units/edit/${item.id}`} className="text-dark" >
													 {formattedTimestamp(item[header]) }
													</Link>
												</td>:
												<td td key={header} className='border min-width-custom'> 
													<Link to={`/real-estate-units/edit/${item.id}`} className="text-dark" >
													{header == "cadastralIncome" ?  (item[header] ? `€ ${item[header]}` : '') : item[header]}
													</Link>
												</td>
										})}
										</tr>
									))}
									</tbody>

									
									
							 	</table>
					</div> */}
					</>
				}
			</div>

			<Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>
		</Row>

		<Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		{/* <Dropzone onDrop={(acceptedFiles) => transmigrationFunction(acceptedFiles, true, mappingFromCompetitor1, unit_endpoint, props.setItems)
			} >
				{({ getRootProps, getInputProps }) => (
					<section className="">
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<div className="filter">
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
							<i className="bi bi-upload fs-25"></i>
						</div>
						</div>
					</div>
					</section>
				)}
			</Dropzone> */}
		</>
	);
};

export default UnitFirstPage;
