import React, { useState } from "react";
import "../../../assets/css/calcoli/page2.css";
import Section1 from "./page3/section1";

export default function Page2_1({selectMenu}) {
  const [status, setStatus] = useState(0);

  const Section = () => {
    switch (status) {
      case 0:
        return <Section1 selectMenu={selectMenu}  onChangeSection={(v) => setStatus(v)} />;
      default:
        break;
    }

    return;
  };
  return <Section />;
}
