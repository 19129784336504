import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import api, { supplier_endpoint, owner_endpoint } from "../../../../api/api";
import ActModal from "../ActModal";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";
import { BsPrinter, BsDownload } from "react-icons/bs";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ButtonRm from "../../../ButtonRm";

import CustomToggle from "../../../CustomToggle";
import translationMappingAtti from "../../mappingAtti";

const actType = [
    "Successione",
    "Compravendita",
    "Divisione",
    "Donazione",
    "Riunione usufrutto e successione",
];

const AttiE = (props) => {

    const [data, setData] = useState({});

	const init = () => {
		setData({
			acts: props.formData.acts
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};


	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	//ADD ATTO

	const [show, setShow] = useState(false);


 	const addAct = (act) => {
		let temp = [...data.acts];
		temp.push(act);
		let e = { target: { name: "acts", value: temp } };
		onChange(e);
		setShow(false);
	}; 

	console.log(props.formData.acts, "atti arr")

	//VIEW E EDIT LETTURA

	const [showSelectedAct, setShowSelectedAct] = useState(false)
	const [selectedAct, setSelectedAct] = useState({})
	const [actEdit, setActEdit] = useState(-1);


	function closeSelectedAct(){
		setShowSelectedAct(false)
		setSelectedAct({})
		setActEdit(null)

	}

	function handleAct(id){
		setShowSelectedAct(true)
		let current =  data.acts.find((item, index) => index == id)
		setSelectedAct(current)
		setActEdit(id)
	}


	function updateAct (act){
		let temp =  data.acts.map((item, index) => {
			return index == actEdit?
			act:
			item
		})
		setData({ ...data, ["acts"]: temp });
		closeSelectedAct()
	};

	const deleteAct = () => {
		let temp =  data.acts.filter((item, index) => index != actEdit)
		setData({ ...data, ["acts"]: temp }); 
		closeSelectedAct()
	}; 

	//FARE POST A INSURANCE ENDPOINT IN ATTI EDIT MODAL => ON SAVE

	return (
		<div  className="form-item-align-box-white d-block py-5">
			<div >
			</div>
			{props.editMode && <div className="unit-btn-group mt-3">
				<button
					type="button"
					className="general-btn white-btn w-193"
					onClick={(e) => {
						setShow(true);
					}}>
					Nuovo
				</button>
			</div>}
			{data?.acts?.length > 0 ? (
				<>
				<div className={`d-flex ${
							props.editMode === false ? "" : "d-none"
						}`}>
					
					<ExportButton data={ props.formData.acts} mapping={translationMappingAtti} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40" showPrint/>

				</div>
				<Table className="mt-3 result-table table-border out-border-none">
					<thead>
						<tr>
							<th>Tipo</th>
							<th>Cedente</th>
							<th>Cessionario</th>
							<th>Data dell’atto</th>
							<th>Data di effetto</th>
							
						</tr>
					</thead>
					<tbody>
						{data?.acts.map((item, index) => {
							return (
								<tr
									key={index}
									onClick={() => handleAct(index)}
									>
									<td>{item.type}</td>
									<td>{item.transferor}</td>
									<td>{item.transferee}</td>
									<td>{item.actDate}</td>
									<td>{item.fiscalEffectDate}</td>
									
								</tr>
							);
						})}
					</tbody>
				</Table>
				</>
			) : (
				<div className="unit-empty-img">
					<label className="edit-black-label mt-5 mb-5">
						Nessun atto notarile precedente inserito
					</label>
				</div>
			)}
			{/* CREATE ATTO */}
		<Modal show={show}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={() => {
              setShow(false);
            }}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
          <ActModal  setShow={setShow} newMode={true} add={addAct} formData={data}/>
        </Modal.Body>
      </Modal>
			
		{/* VIEW ATTO*/}
		 <Modal show={showSelectedAct}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={closeSelectedAct}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
		  <ActModal setShow={closeSelectedAct} atto={selectedAct}  newMode={false} add={updateAct} delete={deleteAct} formData={data}/>
        </Modal.Body>
      </Modal> 
		</div>
	);
};

export default AttiE;
