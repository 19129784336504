import React from "react";
import EmailIcon from "../assets/images/Message_light.svg";
import PhoneIcon from "../assets/images/Phone_light.svg";
import "../assets/css/LeftPanel.css";
import logo from "../assets/images/logo-bianco.svg";


export default function LeftPanel() {
  return (
    <div className="left-panel">
      <div className="left-background m-auto px-3"  >
        <div className="side-login">
          <img loading="lazy" src={logo} width="120px" class="logo-div" />
    
          <div className="login-text">
            <h3>
              Hai bisogno di assistenza?
            </h3>
            <div
              className="border-outline border"
              style={{ width: "100%", height: "0px", margin: "auto" }}
            ></div>
          </div>
          <div >
            <p class="mb-4 text-light">
              Il nostro call center è a tua disposizone dal lunedì al venerdì
            dalle 8:00 alle 17:00</p>
            <div className="email-icon">
              <img
                src={EmailIcon}
                alt="email-icon"
                style={{ paddingRight: "10px" }}
              ></img>
              <a href="mailto:info@realmeta.it" className="text-light">
              info@realmeta.it
              </a>

            </div>
            <div className="phone-icon">
              <img
                src={PhoneIcon}
                alt="phone-icon"
                style={{ paddingRight: "10px" }}
              ></img>
 <a className="text-light" href="tel: +393272734237
">      +39 327 273 4237        </a>
            </div>
          </div>
        </div>
      </div>

       
      </div>
  );
}
