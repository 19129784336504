import { GET_USERS, GET_USER, SEND_EMAIL, APPROVE_USER, REJECT_USER } from "./types";
import api from '../api/api'
import { user_get_all_endpoint, user_endpoint, email_endpoint} from "../api/api";

/*
STEP PER SALVARE DATI IN REDUX:
1. CREA ACTION TYPE IN ./types
2. CREA ACTION CREATORS (fetch..., add..., edit..., etc)
3. CREA REDUCER E IMPORTALO IN reducers/index.js
4. ESPORTA TUTTO
5. IMPORTA DOVE SERVE USEDISPATCH E USESELECTOR E ACTION CREATORS
*/


/* ACTION CREATORS */
export const fetchUsers = () => async (dispatch) => {
    try {
        const response = await api().get(user_get_all_endpoint);
        dispatch({
            type: GET_USERS,
            payload: response.data?.content
        });
    } catch (error) {
        console.error("Errore durante il recupero degli utenti:", error);
    }
};

export const fetchUser = (id) => async (dispatch) => {
    try {
        const response = await api().get(`${user_endpoint}${id}`);
        dispatch({
            type: GET_USER,
            payload: response.data  
        });

        console.log("ress", response.data  )
    } catch (error) {
        console.error("Errore durante il recupero dell'utente:", error);
    }
};

export const approveUser = (id) => async (dispatch) => {
    try {
        const response = await api().put(`${user_endpoint}${id}/approve`);
        dispatch({
            type: APPROVE_USER,
            payload: response.data
        });
    } catch (error) {
        console.error("Errore durante l'approvazione dell'utente:", error);
    }
};

export const rejectUser = (id) => async (dispatch) => {
    try {
        const response = await api().put(`${user_endpoint}${id}/reject`);
        dispatch({
            type: REJECT_USER,
            payload: response.data
        });
    } catch (error) {
        console.error("Errore durante il rifiuto dell'utente:", error);
    }
};


export const sendEmail = ({ email, data }) => async (dispatch) => {
    try {
        await api().post(email_endpoint, {email: email, data: data}); // Effettua la richiesta API per inviare l'email
        dispatch({
            type: SEND_EMAIL,
            payload: {
                email,
                data
            }
        });
        console.log("Email inviata con successo all'utente con ID:", email);
    } catch (error) {
        console.error("Errore durante l'invio dell'email:", error);
    }
};


/* REDUCER */
const initialState = {
    users: [],
};

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload 
            };
        case GET_USER:
        case REJECT_USER:
        case APPROVE_USER:
        case SEND_EMAIL:
            return state;
        default:
            return state;
    }
};

export default userReducer;

