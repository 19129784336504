import {useState, useEffect} from 'react'

import {Row, Col} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import {Button} from "react-bootstrap"

import TopHeading from "../../../TopHeading"
import AddBtn from "../../../AddBtn"
import EmptyStateContainer from "../../../EmptyStateContainer";
import '../../../../assets/css/ImpostazioniChat.css'
import SearchInput from "../../../SearchInput"
import ButtonRm from '../../../ButtonRm';


import { useNavigate } from "react-router-dom"

import emptyState from "../../../../assets/images/tag.svg"
import preview from "../../../../assets/images/preview.svg"
import edit from "../../../../assets/images/edit-btn.svg"
import deleteIcon from "../../../../assets/images/delete-btn.svg"

import SearchIllustration from "../../../../assets/images/search-illustration.svg";


import api from "../../../../api/api"
import {verbal_endpoint} from "../../../../api/api"
import { MultiSelect } from "react-multi-select-component";




const examplechecklist = [
    {
        id:1,
        title: 'verbale 1',
    },
    {
        id:2,
        title: 'checklist 1',
        
    } 
]

function Checklist(){
    const [checklist, setChecklist] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const navigate = useNavigate()

    const searchedList =
    checklist && checklist.length > 0
      ? checklist.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : [];

    //MODAL: NEW GESTIONE
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function createChecklist() {
        navigate("/statoavanzamentolavori/impostazionichat/checklist/nuovo");
    }

    let exampleArr = [
        {
            "id": 1,
            "type": "text",
            "name": "Titolo",
            "options": null,
            "campoLibero": false
        },
        {
            "id": 2,
            "type": "select",
            "name": "Stato",
            "options": [
                {
                    "id": 1,
                    "name": "In corso"
                },
                {
                    "id": 2,
                    "name": "Aperto"
                },
                {
                    "id": 3,
                    "name": "In Ritardo"
                },
                {
                    "id": 4,
                    "name": "Risolto"
                }
            ],
            "campoLibero": false
        },
        {
            "id": 3,
            "type": "select",
            "name": "Priorità",
            "options": [
                {
                    "id": 1,
                    "name": "Alta"
                },
                {
                    "id": 2,
                    "name": "Bassa"
                },
                {
                    "id": 3,
                    "name": "Normale"
                }
            ],
            "campoLibero": false
        },
        {
            "id": 4,
            "type": "number",
            "name": "Percentuale di Avanzamento",
            "options": null,
            "campoLibero": false
        },
        {
            "id": 5,
            "type": "date",
            "name": "Data di Scadenza",
            "options": null,
            "campoLibero": false
        }
    ]

    /* ORDINA PER */
    function sortAZ(){
        const sortedArrAZ = [...checklist].sort((a, b) => {
            let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        setChecklist(sortedArrAZ)
    }

    function sortZA(){
        const sortedArrZA = [...checklist].sort((a, b) => {
            let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();
        
            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return 0;
        });

        setChecklist(sortedArrZA  )
    }

    /* API CALL */

    //GET
    async function getAll(){
        try {
        const response = await api().get(`${verbal_endpoint}`);
            if (response.data.content) {
                const parsedData = response.data.content.map((item) => {
                    return {
                      ...item,
                      content: item.content
                        ? JSON.parse(item.content.replace(/'/g, '"'))
                        : ""
                    };
                  });
                setChecklist(parsedData)
            }
        } catch (error) {
        console.log(error);
        }
    }

    //HANDLE DELETE
    async function onDelete(id) {
        try {
        await api().delete(
            `${verbal_endpoint}${id}`
        );
        await getAll();
        } catch (error) {
        console.log(error);
        }
    }
    
    function handleDelete(id){
        onDelete(id )
    }


    useEffect(() => {
        getAll()
    }, [])

    //KEEP THE ITEM TO PREVIEW
    const [previewArray, setPreviewArray] = useState({}) 

    /* FOR MULTISELECT */
    const [selected, setSelected] = useState([]);
    /* PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE */
    const overrideString = { 
        "selectAll": "Seleziona tutti",
        "search": "Cerca",
        "selectSomeItems": "Seleziona",
        "allItemsAreSelected": "Tutti gli elementi sono selezionati"
    }


    return(
    <>
        <Row>
            <Col>
                <TopHeading SmallHeading="" back_modal={false}/>
            </Col>
        </Row>

        <Row className=" d-flex flex-column mt-md-4">
            <Col>
                <h2 className="fw-bold text-center">Checklist e Verbali</h2>
            </Col>
            <Col className="d-flex mt-3 justify-content-md-between">
                <AddBtn 
                tooltip="Nuovo checklist o verbale"
                className="me-3 me-md-0 order-md-1"
                onClick={createChecklist}
                dataRequired={true}
                />
                <SearchInput
                className="order-md-0"
                setSearch={(term) => {
                    setSearchTerm(term);
                }}
            />
            </Col>
        </Row>

        <Row>
        <Col className="d-flex align-items-center justify-content-end">
            <div className="d-flex aling-items-center me-2 mt-4">
                {/* <i className="bi bi-arrow-down-up"></i>
                <p className="fs-14 ms-1 mt-1">Ordina</p> */}
                <div className="user-dropdown">
                    <div className="sort d-flex aling-items-center">
                        <i className="bi bi-arrow-down-up"></i>
                        <p className="fs-14 ms-1">Ordina</p>
                    </div>
                    <div className="user-dropdown-content">
                        <div className="fs-16 fw-bold">Ordina</div>
                        <div className="fs-14 mt-3 mb-1" onClick={sortAZ} style={{cursor:"pointer"}}>Dalla A alla Z</div>
                        <div className="fs-14"  style={{cursor:"pointer"}} onClick={sortZA}>Dalla Z alla A</div>
                    </div>
                </div>
            </div>
        </Col>
            {
                searchedList.length > 0 &&
                <div className='mb-5 mt-2'>
                    {
                        searchedList.map(item => <ChecklistCard key={item.id} 
                            title={item.name} 
                            handleShow={handleShow} 
                            handleClose={handleClose}
                            item={item} 
                            setPreviewArray={setPreviewArray}
                            handleDelete={handleDelete}
                            />
                            ) 
                    }
                </div>
            }
            {   
                searchTerm && searchedList.length===0 &&
                <EmptyStateContainer 
                content="Nessuna corrispondenza trovata"
                img={SearchIllustration} />                  
            }
            {   
                !searchTerm && checklist.length===0 &&
                <EmptyStateContainer 
                content="Non hai aggiunto ancora alcun verbale"
                img={emptyState} />                  
            }
        </Row>

                {/* ANTEPRIMA MODAL */}
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Anteprima</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: "300px", maxHeight: "400px", overflowY: "auto"}}>
                    <Form>
                    {
                        previewArray?.content?.map(input => {
                            /* LOGICA PER OPZIONI X CAMPI LIBERI TYPE SELECT  */
                            /* PRENDO LA STRINGA VALUE DEL CAMPO OPZIONE, SPLITTO QUANDO TROVO UNA VIRGOLA E OTTENGO UN ARRAY CON LE DIVERSE OPZIONI */
                            let opzioniAnteprimaCampoLibero =  input.campoLibero && input.options?.length > 0? input.options[0].split(",") : ''
                            /* PER OGNI ELEMENTO DELL'ARRAY CREO UN OGGETTO, DA POTER MAPPARE PER CREARE LE OPZIONI DI QUEL CAMPO CHE SI VEDRA' IN VISUALIZZAZIONE ANTEPRIMA */
                            let arrayAnteprimaCampoLibero
                            if(opzioniAnteprimaCampoLibero.length > 1){
                                arrayAnteprimaCampoLibero = opzioniAnteprimaCampoLibero.map(item => {
                                    return {
                                        name: item
                                    }
                                })
                            }

                            /* MULTI SELECT */
                            let arrayAnteprimaCampoLiberoMulti
                            if(opzioniAnteprimaCampoLibero.length > 1){
                                arrayAnteprimaCampoLiberoMulti = opzioniAnteprimaCampoLibero.map(item => {
                                    return {
                                        value: item,
                                        label: item
                                    }
                                })
                            }
                                                        
                            return <Form.Group className="mb-3 d-flex flex-column" key={input.id}>
                            {
                                input.type !== "checkbox" &&
                                <Form.Label className='text-capitalize'>{input.name}</Form.Label>
                            }
                            
                                { input.type==="select"  &&
                                    <Form.Select aria-label="seleziona"  
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                    }}>
                                        {
                                            input.campoLibero && arrayAnteprimaCampoLibero?
                                            arrayAnteprimaCampoLibero.map(
                                                option => <option value={option?.name}>{option?.name}</option>
                                            ) :
                                            input.options.map(
                                                option => <option value={option?.name}>{option?.name}</option>
                                            )
                                        }
                                    </Form.Select>
                                }

                                { input.type == "multiselect"  && arrayAnteprimaCampoLibero &&
                                    <MultiSelect
                                        options={arrayAnteprimaCampoLiberoMulti}
                                        labelledBy="Seleziona"
                                        overrideStrings={overrideString}
                                        value={selected}
                                        onChange={setSelected}
                                        style={{ height: "44px",  
                                        borderRadius: "6px",
                                        border: "1px solid #BDBDBD",
                                        padding: "0 .5rem", 
                                        width: '100%'
                                        }}
                                    />
                                }   
                            

                                {/* TROVARE UN MODO PER CONDENSARE QUESTA LOGICA */}
                                {   input.type==="text" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type==="number" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type==="date" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {  input.type==="time" &&
                                    <Form.Control
                                    type={input.type}
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {/* FINO A QUI */}

                                { input.type==="textarea" &&
                                    <Form.Control
                                    as="textarea"
                                    style={{ height: "44px",  
                                    borderRadius: "6px",
                                    border: "1px solid #BDBDBD",
                                    padding: "0 .5rem", 
                                    width: '100%'
                                    }}
                                    />
                                }
                                {   input.type==="checkbox" &&
                                    <Form.Check
                                        label={input.name}
                                        type={input.type}
                                    />
                                }
                                    
                        </Form.Group>
                        })
                    }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} className="mx-auto">
                        Chiudi Anteprima
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
    )
}

export default Checklist


function ChecklistCard(props){

    const navigate = useNavigate()


    function handlePreview(){
        props.handleShow()
        props.setPreviewArray(props.item)
    }

    return(
        <div className='checklist-card d-flex align-items-center justify-content-between'>
            <p>{props.title}</p>
            <div className='d-flex'>
                <img loading="lazy" src={preview} onClick={handlePreview}/>
                <img loading="lazy" src={edit} className="mx-2" onClick={() =>navigate(`/statoavanzamentolavori/impostazionichat/checklist/edit/${props.item.id}`)
 }/>
                <img loading="lazy" src={deleteIcon} onClick={() => props.handleDelete(props.item.id)}/>
            </div>          
        </div>
    )
}


