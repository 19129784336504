import { useEffect, useState } from "react";
import {Col} from "react-bootstrap"
import ButtonRm from "../../../ButtonRm"

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import logoPng from '../../../../assets/images/logo-red-big.png';

import { Modal, Form, Row } from "react-bootstrap";

import api, { chat_msg_endpoint, BASE_URL, token } from "../../../../api/api";

import {
    PaymentElement,
    useStripe,
    useElements
  } from "@stripe/react-stripe-js";

  import { Elements } from "@stripe/react-stripe-js";
  import { loadStripe } from "@stripe/stripe-js";

  import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
  } from '@stripe/react-stripe-js';
  import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
  } from "react-router-dom";

  import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

  import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
  import { checkCollaborator } from "../../../../utils/checkUserRole";
  

  //inserisco la nostra chiave (test) + ID dell'account connect che riceverà il pagamento


//COMPONENT FOR MESSAGE TO GET PAYMENTS FROM OTHER USERS

const stripePromise = loadStripe("pk_test_51OYkWoGIy0lE619XNFRvHIiwKBkhE1jm481zovf6ird0RJMvy729yjwAARTGcdFPTQc1BSoAOu0f8AYJgb0zjFxu00BttmKT7Y", {
  stripeAccount: ""
});

export default function Payment(props) {

    //DOPO CHE IL CLIENTE CLICCA SU PAGA ORA AVVIA UN PAGAMENTO => richiesta per crazione checkout che genera client secret
    //https://docs.stripe.com/checkout/quickstart, deve gestire backendend creazione checkout
    //https://docs.stripe.com/connect/enable-payment-acceptance-guide?platform=web&payment-ui=embedded-form#create-checkout-session
 
    const [clientSecret, setClientSecret] = useState("");
    const [accountConnect, setAccountConnect] = useState("")

    function initiateCheckout() {
      if (props.parsedContent.causale && props.parsedContent.prezzo) {
        fetch(`${BASE_URL}api/v1/payment/payCheckout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({ name: props.parsedContent.causale, price: (props.parsedContent.prezzo * 100) })
        })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "datStripe");
          window.open(data.url);
/*           setClientSecret(data["client_secret"]);
           setAccountConnect(data["target_account"]); */
 
          // Aggiornare stripePromise con l'account ID ricevuto
         /*  stripePromise.then(stripe => {
            stripe.update({ stripeAccount: data["target_account"] });
          }); */
        })
        .catch((error) => {
          console.error('Errore durante la richiesta:', error);
        });
      } else {
        console.log("Mancano causale e/o prezzo")
      }
    }

    
  

     /* MODAL EDIT => ALSO SHOULD SEND REQUEST TO BACKEND FOR UPDATE */
     const [showP, setShowP] = useState(false);
     const handleCloseP = () => setShowP(false);

     /* MODAL SHARE WITH GESTORE */
    const [show, setShow] = useState(false)

    function handleClose(){
      setShow(false)
    }

    /* MODAL CHECKOUT */

    const [showCheckout, setShowCheckout] = useState(false);
    const handleCloseCheckout = () => {
      setShowCheckout(false)
      setClientSecret("");
      setAccountConnect("");
    };

     const options = {clientSecret};

     useEffect(() => {
      if(clientSecret && accountConnect){
        setShowCheckout(true)
      }
    }, [clientSecret, accountConnect])


    return (                     
            <div className="my-3" >
                <div className="d-flex align-items-center">
                    <p className="text-capitalize fs-14">Pagamento</p>
                    <TableVerbale parsedContentKey={props.parsedContentKey}  parsedContent={props.parsedContent}  />
                    <button className="btn round-icon-btn btn ms-1" onClick={() => setShowP(true)}><i class="bi bi-pencil-square"></i></button>
                </div>
                <hr className="my-2"/>
                <div className="mb-3">     
                {props.parsedContentKey.map((el, i) => {
                        
                        return <div key={i} className="d-flex">
                                {
                                props.parsedContent[el] &&
                                <>
                                   

                                    <TableContainer style={{ backgroundColor: 'transparent' }} >
                                      <Table  aria-label="customized table">
                                          <TableBody>
                                                  <TableRow key={i}>
                                                  <TableCell component="th" scope="row">
                                                      {capitalizeFirstLetter(el)}
                                                  </TableCell>
                                                  <TableCell align="right" style={{maxWidth: "400px"}}>{el == "prezzo" && "€"}  {props.parsedContent[el]}</TableCell>
                                                  </TableRow>
                                          </TableBody>
                                      </Table>
                                  </TableContainer>
                                </>
                                }
                                
                            </div>
                    })}
                </div>
               {/*  <form action="/api/v1/payment/payCheckout" method="POST">
                  <button type="submit">
                    Checkout
                  </button>
                </form> */}
               
                {!checkCollaborator() && <ButtonRm variant="outline-gray" size="small" onClick={() => { initiateCheckout()}}> Paga ora </ButtonRm>}
                {checkCollaborator() && <ButtonRm variant="outline-gray" size="small" onClick={() => setShow(true)}> Inoltra al gestore </ButtonRm>}
                
                <Checkout clientSecret={clientSecret} options={options} handleCloseCheckout={handleCloseCheckout} showCheckout={showCheckout} accountConnect={accountConnect}
                />

                <ShareWithMaster show={show} handleClose={handleClose}/>

                <EditPayment parsedContent={ props.parsedContent} showP={showP} handleCloseP={handleCloseP } getOne={props.getOne} item={props.item} chat={props.chat} />

            </div>
    )}

//PAGINA DI RITORNO DOPO PAGAMENTO
const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
  
      fetch(`/session-status?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }, []);
  
    if (status === 'open') {
      return (
        <Navigate to="/checkout" />
      )
    }
  
    if (status === 'complete') {
      return (
        <section id="success">
          <p>
            Grazie per il tuo acquisto {customerEmail}.
  
          </p>
        </section>
      )
    }
  
    return null;
}


// Funzione modificata che genera il PDF senza intestazione
const exportPDF = (parsedContentKey, parsedContent) => {
  const doc = new jsPDF();

  const logoImg = logoPng
    const logoWidth = 26;
    const logoHeight = 13;
    doc.addImage(logoPng, 'PNG', 15, 15, logoWidth, logoHeight); 

  // Aggiungi il titolo "Verbale"
  //doc.text('Verbale', 40, 20);  Modifica le coordinate x, y per allineare il titolo come preferisci

  // Prepara i dati per autoTable senza utilizzare intestazioni di colonna esplicite
  const tableRows = [];

  // Costruisci le righe della tabella a partire dai tuoi dati
  parsedContentKey.forEach((key) => {
    const rowData = [capitalizeFirstLetter(key), capitalizeFirstLetter(parsedContent[key])]; // Costruisci ogni riga con chiave e valore
    tableRows.push(rowData);
  });

  // Aggiungi la tabella al documento senza intestazioni di colonna
  doc.autoTable({
    body: tableRows,
    // Puoi aggiungere qui eventuali opzioni per personalizzare lo stile della tabella
    didDrawCell: (data) => {
      // Esempio: Modifica lo stile di una cella specifica, se necessario
    },
    startY: 35
  });

  // Nome file del PDF che verrà scaricato
  const date = Date.now();
  const pdfTitle = `Export_${date}.pdf`;

  // Salva il PDF
  doc.save(pdfTitle);
};

// Componente React per il pulsante di esportazione, rimane invariato
const TableVerbale = ({parsedContentKey, parsedContent}) => (
  <button onClick={() => exportPDF(parsedContentKey, parsedContent)} className="btn round-icon-btn btn mx-3 me-2"><i class="bi bi-download fs-12"></i>
  </button>
);



function EditPayment(props) {

  const [formData, setFormData] = useState({
    causale: props.parsedContent?.causale || "",
    deadlineDate: props.parsedContent?.scadenza || "", 
    amount: props.parsedContent?.prezzo || "" 
  });

  // Gestore per gli eventi di modifica degli input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  async function putItem(body){
    const response = await api().put(
      `${chat_msg_endpoint}${props.item.id}`,
      body
    )

    await props.getOne(props.chat.id)
  
  }

  // Gestore per l'invio del modulo
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepara il body per la richiesta PUT
    const bodyForCreate = {
      message: "",
      senderEmail: "",
      replyID: -1,
      attachments: [],
      tag: [],
      messageType: "paymentMsg",
      content: JSON.stringify({
        causale: formData.causale,
        soggetto: "", 
        scadenza: formData.deadlineDate?.substring(0, 10),
        prezzo: formData.amount,
      }).replace(/"/g, "'"),
      deleted: false,
      chatGroupID: props.chat.id
    };

   putItem(bodyForCreate);
    
    props.handleCloseP(); // Chiude il modal dopo l'invio
  };


  console.log(formData, props.chat, props.item)

  return (
    <div>
      <Modal show={props.showP} onHide={props.handleCloseP} className="p-5" centered style={{minHeight:"500px !important"}}>
          <Modal.Header closeButton className=" pt-md-4 px-md-5 modalCustomHeader">
              <Modal.Title>Modifica Pagamento</Modal.Title>
          </Modal.Header>
          <Modal.Body className="eventModalBody">
              <Form onSubmit={handleSubmit}>
                  <Row className="d-md-flex flex-column">   
                      <Form.Group className="mb-3 ">
                          <Form.Label className="input-label">Causale del pagamento</Form.Label>
                          <Form.Control
                            type="text" 
                            name="causale" 
                            value={formData.causale}
                            onChange={handleInputChange}
                          />
                      </Form.Group>

                      <Form.Group className="mb-3 ">
                          <Form.Label className="input-label">Data entro cui inviare il pagamento</Form.Label>
                          <Form.Control
                            type="date" 
                            name="deadlineDate" 
                            value={formData.deadlineDate}
                            onChange={handleInputChange}
                          />
                      </Form.Group>

                      <Form.Group className="mb-3 ">
                          <Form.Label className="input-label">Importo</Form.Label>
                          <Form.Control
                            type="number" 
                            name="amount" 
                            value={formData.amount}
                            onChange={handleInputChange}
                          />
                      </Form.Group>
                  </Row>
                  <div className='d-flex justify-content-end my-3' >
                      <ButtonRm variant="tertiary" className="me-2" onClick={props.handleCloseP}>Annulla</ButtonRm>
                      <ButtonRm type="submit" variant="primary">Salva</ButtonRm>
                  </div>
              </Form>
          </Modal.Body>
      </Modal>
    </div>
  );
}

function Checkout(props) {

  return (
      <Modal show={props.showCheckout} onHide={props.handleCloseCheckout} className="p-5" centered style={{minHeight:"500px !important"}}>
          <Modal.Body className="eventModalBody">

              {(props.clientSecret && props.accountConnect) && (
                      <>
                           <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={props.options}
                          >
                          <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider> 
                      </>
                    )}

              
          </Modal.Body>
      </Modal>


  );
}

function ShareWithMaster(props){
  <Modal show={props.show} onHide={(e) => props.handleClose(e)} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi informare il tuo gestore di questo pagamento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
            Invieremo una notifica al tuo gestore
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) =>{ 
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={(e) => props.handleClose(e)}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
  </Modal>  
}
