import React, { useState } from "react";
import MidTabs from "../components/Membri/ProprietariSublocatori/view_mode/MidTabs";
import TopHeadPartV from "../components/Membri/ProprietariSublocatori/view_mode/TopHeadPartV";
import { useLocation } from "react-router-dom";
import TopHeading from "../components/TopHeading";
import { owner_endpoint, BASE_URL } from "../api/api";
import { useEffect } from "react";
import axios from "axios";

export default function ViewPropritari(props) {

  const location = useLocation();
  const [owner, setOwner] = useState({});

  // TODO con api() da problemi con la chiamata API, da risolvere
  const fetchOwner = () => {
    axios
      .create({
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
        },
      })
      .get(
        owner_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
      .then((res) => {
        if (res.data) {
          setOwner({
            ...res.data,
            initialBankAccountsIDs: res.data.bankAccounts.map(
              (bankAccount) => bankAccount.id
            ),
          });
        }
      });
  };

  useEffect(() => {
    fetchOwner();
  }, []);


  return (
    <>
      <TopHeading SmallHeading="" back_modal=""/>
      <TopHeadPartV parms={owner} />
      <MidTabs parms={owner} />
    </>
  );
}
