import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CondutText } from "../../../../pages/Conduttori";
import { FornitoriText } from "../../../../pages/Fornitori";
import { PropriText } from "../../../../pages/Proprietari";
import Allegati from "./Allegati";
import Anagrafica from "./Anagrafica";
import Correnti from "./Correnti";
import Documento from "./Documento";
import Fornitori from "./Fornitori";
import Note from "./Note";
import Recapiti from "./Recapiti";
import TopHeading from "../../../TopHeading";
import { useLocation } from 'react-router-dom';


export default function TabForm(props) {
	const [formData, setFormData] = useState({
		type: "persona fisica",
		name: "",
		surname: "",
		birthday: "",
		state: "Italy",
		municipality: "",
		province: "",
		fiscalCode: "",
		sex: "Maschio",
		residenceAddress: "",
		residenceHouseNumber: "",
		residenceMunicipality: "",
		residenceProvince: "",
		businessName: "",
		withholding: "",
		subjectType: "",
		societyFiscalCode: "",
		vatNumber: "",
		chamberOfCommerceNumber: "",
		chamberOfCommerceCity: "",
		reaNumber: "",
		shareCapital: "",
		inVirtueOf: [""],
		correspondenceAt: "",
		correspondenceAddress: "",
		correspondenceHouseNumber: "",
		correspondenceMunicipality: "",
		correspondenceProvince: "",
		homeTelephone: "",
		officeTelephone: "",
		cellularTelephone: [""],
		fax: "",
		skype: "",
		email: [""],
		pec: [""],
		documentType: "carta d'identità",
		documentNumber: "",
		documentReleaseDate: "",
		documentReleaseEntity: "",
		documentDeadline: "",
		citizenship: "",
		bankAccounts: [],
		installments: [],
		suppliers: [],
		units: [],
		firstNotes: [],
		notes: "",
		attachment: [],
		favoriteContact:"",
	});


	console.log(formData)

	const topHeading1 = useContext(CondutText);
	const topHeading2 = useContext(PropriText);
	const topHeading3 = useContext(FornitoriText);
	const [count, setCount] = useState(1);
	const [provinceList, setProvinceList] = useState([]);

	const fetchProvince = async () => {
		axios.get(`https://anthill-service.onrender.com/provinces?region`).then((res) => {
			setProvinceList(res.data.data);
			if (res.data.data.length > 0 && formData.province === "") {
				setFormData({
					...formData,
					province: res.data.data[0],
					residenceProvince: res.data.data[0],
					correspondenceProvince: res.data.data[0],
				});
			}
		});
	};

	useEffect(() => {
		fetchProvince();
	}, []);

	const forward = () => {
		if (count <= 6) {
			setCount(count + 1);
		}
	};

	const backward = () => {
		var num = count;
		if (num > 1) {
			setCount(--num);
		}
	};

	const addBankAccount = (bankAccount) => {
		const bankAccounts = formData.bankAccounts;
		bankAccounts.push(bankAccount);
		setFormData({ ...formData, bankAccounts: bankAccounts });
	};

	const removeBankAccount = (index) => {
		console.log(index);
		console.log(formData.bankAccounts);
		formData.bankAccounts.splice(index, 1);
		console.log(formData.bankAccounts);
		setFormData({ ...formData, bankAccounts: formData.bankAccounts });
	};

	const tab1Click = () => {
		setCount(1);
	};
	const tab2Click = () => {
		setCount(2);
	};
	const tab3Click = () => {
		setCount(3);
	};
	const tab4Click = () => {
		setCount(4);
	};
	const tab5Click = () => {
		setCount(5);
	};
	const tab6Click = () => {
		setCount(6);
	};

	const returnContent = (count) => {
		switch (count) {
			case 1:
				return (
					<Anagrafica
						next={forward}
						formData={formData}
						setFormData={setFormData}
						provinceList={provinceList}
					/>
				);
			case 2:
				return (
					<Recapiti
						formData={formData}
						setFormData={setFormData}
						next={forward}
						back={() => backward()}
						provinceList={provinceList}
					/>
				);
			case 3:
				return (
					<Documento
						formData={formData}
						setFormData={setFormData}
						next={forward}
						back={() => backward()}
					/>
				);
			case 4:
				return (
					<Correnti
						formData={formData}
						setFormData={setFormData}
						addBankAccount={addBankAccount}
						removeBankAccount={removeBankAccount}
						next={forward}
						back={() => backward()}
					/>
				);
			case 5:
				return (
					<Fornitori
						formData={formData}
						setFormData={setFormData}
						next={forward}
						back={() => backward()}
					/>
				);
			case 6:
				return (
					<Allegati
						formData={formData}
						setFormData={setFormData}
						back={() => backward()}
						next={forward}
						setShow={() => props.setShow(false)}
						createMode={true}
					/>
				);
			default:
				return (
					<Anagrafica
						next={forward}
						back={() => props.setShow(false)}
						formData={formData}
						setFormData={setFormData}
						provinceList={provinceList}
					/>
				);
		}
	};

	//TITOLO SE PROVENIENTI DA UNITA
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tab = searchParams.get('tab');
    
	let pageTitle = '';

	if (tab === 'nudaProprieta') {
	pageTitle = 'Nuova Nuda Proprietà';
	} else if (tab === 'usufruttuario') {
	pageTitle = 'Nuovo Usufruttuario';
	} else if (tab === 'proprietario') {
		pageTitle = 'Nuovo Proprietario';
	}

	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading
						SmallHeading=""
						Heading={tab ? "" : "Nuovo proprietario"}
					/>
				</div>
			</div>
			<div className="main-from-outer d-block">
				<h2 className="top-form-heading d-block mb-4 fs-32 text-dark fw-semibold">
					{!tab && topHeading1}
					{!tab && topHeading2}
					{ !tab && topHeading3}
					{ tab && pageTitle}
				</h2>
				<div className="tabs-div" style={{ marginBottom: "40px" }}>
					<div className="tab-icon" onClick={() => tab1Click()}>
						<div className="desktop-icon">
							<svg style={{ width: "131px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 5C2 2.79086 3.79086 1 6 1H112.679C114.114 1 115.44 1.76914 116.152 3.01544L129.866 27.0154C130.569 28.2452 130.569 29.7548 129.866 30.9846L116.152 54.9846C115.44 56.2309 114.114 57 112.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
									fill={
										count > 0 ? (count > 1 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 0 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className={"tab-icon-title1"}
								style={{
									color:
										count > 0 ? (count > 1 ? "#FFF8F8" : "#83112F") : "#3C4257",
									marginLeft: "23px",
								}}>
								Anagrafica
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 1 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 0 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 1 ? "" : "none",
									position: "absolute",
									left: "-15px",
								}}>
								Anagrafica
							</div>
						</div>
					</div>
					<div className="tab-icon" onClick={() => tab2Click()}>
						<div className="desktop-icon">
							<svg
								className="tab-icon-background"
								style={{ width: "126px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count > 1 ? (count > 2 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 1 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className={"tab-icon-title2"}
								style={{
									color:
										count > 1 ? (count > 2 ? "#FFF8F8" : "#83112F") : "#3C4257",
									marginLeft: "33px",
								}}>
								Recapiti
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 2 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 1 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 2 ? "" : "none",
									position: "absolute",
									left: "0px",
								}}>
								Recapiti
							</div>
						</div>
					</div>
					<div className="tab-icon" onClick={() => tab3Click()}>
						<div className="desktop-icon">
							<svg className="tab-icon-background" style={{ width: "147px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M132.152 3.01544C131.439 1.76914 130.114 1 128.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H128.679C130.114 57 131.439 56.2309 132.152 54.9846L145.866 30.9846C146.569 29.7548 146.569 28.2452 145.866 27.0154L132.152 3.01544Z"
									fill={
										count > 2 ? (count > 3 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 2 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className={"tab-icon-title3"}
								style={{
									color:
										count > 2 ? (count > 3 ? "#FFF8F8" : "#83112F") : "#3C4257",
									marginLeft: "34px",
								}}>
								Documento
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 3 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 2 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 3 ? "" : "none",
									position: "absolute",
									left: "-7px",
								}}>
								Documento
							</div>
						</div>
					</div>
					<div className="tab-icon" onClick={() => tab4Click()}>
						<div className="desktop-icon">
							<svg style={{ width: "159px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M144.152 3.01544C143.439 1.76914 142.114 1 140.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H140.679C142.114 57 143.439 56.2309 144.152 54.9846L157.866 30.9846C158.569 29.7548 158.569 28.2452 157.866 27.0154L144.152 3.01544Z"
									fill={
										count > 3 ? (count > 4 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 3 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title4"
								style={{
									color:
										count > 3 ? (count > 4 ? "#FFF8F8" : "#83112F") : "#3C4257",
								}}>
								Conti Correnti
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 4 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 3 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 4 ? "" : "none",
									position: "absolute",
									left: "-10px",
								}}>
								Conti correnti
							</div>
						</div>
					</div>
					<div className="tab-icon" onClick={() => tab5Click()}>
						<div className="desktop-icon">
							<svg className="tab-icon-background" style={{ width: "156px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M141.152 3.01544C140.439 1.76914 139.114 1 137.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89263 57H137.679C139.114 57 140.439 56.2309 141.152 54.9846L154.866 30.9846C155.569 29.7548 155.569 28.2452 154.866 27.0154L141.152 3.01544Z"
									fill={
										count > 4 ? (count > 5 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 4 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className={"tab-icon-title5"}
								style={{
									color:
										count > 4 ? (count > 5 ? "#FFF8F8" : "#83112F") : "#3C4257",
									marginLeft: "38px",
									marginTop: "6px",
								}}>
								<div>Fornitori del</div>
								<div>locatore</div>
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 5 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 4 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 5 ? "" : "none",
									position: "absolute",
									left:"-30px"
								}}>
								Fornitore del locatore
							</div>
						</div>
					</div>
					<div className="tab-icon" onClick={() => tab6Click()}>
						<div className="desktop-icon">
							<svg style={{ width: "100px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H94.9999C97.2091 1 98.9999 2.79086 98.9999 5V53C98.9999 55.2091 97.2091 57 94.9999 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
									fill={
										count > 5 ? (count > 6 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 5 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title6"
								style={{
									color:
										count > 5 ? (count > 6 ? "#FFF8F8" : "#83112F") : "#3C4257",
									marginLeft: "27px",
								}}>
								Allegati
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 6 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 6 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 6 ? "" : "none",
									position: "absolute",
									left: "-3px",
								}}>
								Allegati
							</div>
						</div>
					</div>
				</div>
				{returnContent(count)}
			</div>
		</>
	);
}
