const translationMappingConti = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    name: "Nome",
    type: "Tipo",
    iban: "IBAN",
    province: "Provincia",
    header: "Intestazione",
    abi: "ABI",
    cab: "CAB",
    conto: "CONTO",
    cin: "CIN",
    bic: "BIC/SWIFT",
    bank: "Banca",
    siaCode: "Codice SIA",
    agency: "Agenzia/Comune",
    cucSepaCode: "Codice CUC SEPA",
};


export default translationMappingConti 