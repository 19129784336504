import { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import TopHeading from "../../../TopHeading";
import AddBtn from "../../../AddBtn";
import EmptyStateContainer from "../../../EmptyStateContainer";
import "../../../../assets/css/ImpostazioniChat.css";
import emptyState from "../../../../assets/images/tag.svg";
import SearchInput from "../../../SearchInput";
import ButtonRm from "../../../ButtonRm";
import SearchIllustration from "../../../../assets/images/search-illustration.svg";
import api from "../../../../api/api";
import { tag_endpoint } from "../../../../api/api";
import { Checklist } from "tabler-icons-react";

function Tag() {
  const [tags, setTags] = useState([]);

  const [tagName, setTagName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const nameAlreadyExist = tags.filter((item) => item.name == tagName);

  const searchedList =
    tags && tags.length > 0
      ? tags.filter((tag) =>
          tag.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : [];

  //MODAL: NEW GESTIONE
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //HANDLE NEW GESTIONE DATA
  function handleTagName(e) {
    setTagName(e.target.value);
  }

  //HANDLE CREATION
  function handleSubmit(e) {
    e.preventDefault();
    if (tagName && nameAlreadyExist.length == 0) {
      postItem({
        name: tagName,
      });
    }
    handleClose();
    setTagName("");
  }

  //HANDLE DELETE
  async function onDelete(id) {
    try {
      await api().delete(`${tag_endpoint}${id}`);
      await getAll();
    } catch (error) {
      console.log(error);
    }
  }

  function handleDelete(id) {
    onDelete(id);
  }

  function sortAZ() {
    const sortedArrAZ = [...tags].sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setTags(sortedArrAZ);
  }

  function sortZA() {
    const sortedArrZA = [...tags].sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });

    setTags(sortedArrZA);
  }

  /* API CALL */

  //GET
  async function getAll() {
    try {
      const response = await api().get(`${tag_endpoint}`);
      if (response.data.content) {
        setTags(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //CREATE
  async function postItem(data) {
    try {
      const response = await api().post(`${tag_endpoint}`, data);
      await getAll();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <TopHeading SmallHeading="" back_modal={false} />
        </Col>
      </Row>

      <Row className=" d-flex flex-column mt-md-4">
        <Col>
          <h2 className="fw-bold text-center">Tag</h2>
        </Col>
        <Col className="d-flex mt-3 justify-content-md-between">
          <AddBtn
            tooltip="Nuovo tag"
            className="me-3 me-md-0 order-md-1"
            onClick={handleShow}
            dataRequired={true}

          />
          <SearchInput
            className="order-md-0"
            setSearch={(term) => {
              setSearchTerm(term);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center justify-content-end">
          <div className="d-flex aling-items-center me-2 mt-4">
            {/* <i className="bi bi-arrow-down-up"></i>
                <p className="fs-14 ms-1 mt-1">Ordina</p> */}
            <div className="user-dropdown">
              <div className="sort d-flex aling-items-center">
                <i className="bi bi-arrow-down-up"></i>
                <p className="fs-14 ms-1">Ordina</p>
              </div>
              <div className="user-dropdown-content">
                <div className="fs-16 fw-bold">Ordina</div>
                <div
                  className="fs-14 mt-3 mb-1"
                  onClick={sortAZ}
                  style={{ cursor: "pointer" }}
                >
                  Dalla A alla Z
                </div>
                <div
                  className="fs-14"
                  style={{ cursor: "pointer" }}
                  onClick={sortZA}
                >
                  Dalla Z alla A
                </div>
              </div>
            </div>
          </div>
        </Col>
        {searchedList.length > 0 && (
          <div
            className="mb-5 mt-2 d-flex flex-wrap"
            style={{ marginTop: " 1rem !important" }}
          >
            {searchedList.map((tag) => (
              <TagCard
                key={tag.id}
                id={tag.id}
                name={tag.name}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        )}
        {searchTerm && searchedList.length === 0 && (
          <EmptyStateContainer
            content="Nessuna corrispondenza trovata"
            img={SearchIllustration}
          />
        )}
        {!searchTerm && tags.length === 0 && (
          <EmptyStateContainer
            content="Non hai aggiunto ancora alcun tag"
            img={emptyState}
          />
        )}
      </Row>

      {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
      <Modal show={show} onHide={handleClose} className="p-5" centered>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          className=" pt-md-5 px-md-5"
        >
          <Modal.Title>Nuovo Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3 px-md-5">
          <Form>
            <Form.Group
              className="mb-3 d-flex flex-column"
              controlId="gestioneName"
            >
              <Form.Label>Nome tag</Form.Label>
              <input
                type="text"
                placeholder="Inserisci il nome del tag"
                style={{
                  height: "50px",
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem",
                }}
                onChange={handleTagName}
                value={tagName}
              />
              <Form.Text>
                {nameAlreadyExist.length > 0 &&
                  "Esiste un altro elemento con questo nome, scegline un altro"}
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer
          className="d-flex flex-column  px-md-5 pb-md-4"
          style={{ borderTop: "none" }}
        >
          <ButtonRm variant="primary" onClick={handleSubmit}>
            Salva
          </ButtonRm>
          <ButtonRm variant="tertiary" onClick={handleClose}>
            Annulla
          </ButtonRm>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Tag;

function TagCard(props) {
  return (
    <div className="tag-card d-flex align-items-center justify-content-center border">
      <i
        className="bi bi-x-lg me-2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.handleDelete(props.id);
        }}
      ></i>
      <p>{props.name}</p>
    </div>
  );
}
