/*
    Questo componente accetta come props:
    -nome del conto corrente (name) - STRING
    -funzione per eliminare la card (deleteFunc) - FUNCTION
    -id o indice dell'elemento da eliminare (index) - STRING
    -gestione della visibilità o meno del pulsante x (deleteVisible) - BOOLEAN
    -valore per capire se conto è disabilitato o meno (disabled) - BOOLEAN
    -colore del bordo e header (color) - STRING
    -children => ovvero <BankCardItem/> => accetta due valori property e value - STRING
*/

function BankCard({children, 
                    name,
                    openingBalance = null,
                    deleteFunc, 
                    index, 
                    color, 
                    deleteVisible = true, 
                    disabled = false,
                    showModal = null,
                    col="col-lg-5"
                    }){

                        console.log(openingBalance)
    return(
        <div className={`${col} mb-2` }
        >
            {/* BORDO */}
            <div className="d-block overflow-hidden rounded-3"
            style={{border:`2px solid ${color}`}}
            >
            {/* HEADER */}
            <div className="d-flex align-items-center justify-content-between py-2"
            style={{backgroundColor:`${color}`, paddingLeft:"1rem"}}>
                <h3 className="mt-1 text-light fw-semibold fs-16 text-uppercase" >
                {name} {(openingBalance != null || openingBalance != undefined) && `- SALDO INIZIALE € ${openingBalance}`}
                </h3>
                {deleteVisible && <button
                type="button"
                className="border-0 bg-transparent text-white"
                onClick={() => deleteFunc(index)}
                style={{ paddingRight:"1rem"}}
                >
                <i class="bi bi-x"></i>
                </button>}
            </div>
            {/* BODY */}
            <div
                className="d-block"
                style={{ padding:"1rem", background: disabled ? "#F2F2F2" : "" }}
                
            >
                <table className="table table-borderless table-correnti">
                <tbody onClick={showModal ? showModal : () => {}} className="">
                    {children}
                </tbody>
                </table>
            </div>
            </div>
        </div>
    )
}


function BankCardItem({property, value}){
    return(
        <tr>
            <td className="text-uppercase fs-14 text-secondary">
                <div>{property}</div>
            </td>
            <td className="text-capitalize fs-14 text-dark">
                {value}
            </td>
        </tr>
    )
}


export {BankCard, BankCardItem}