import { BsPlusCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../assets/css/AddBtn.css";
import { useSelector } from "react-redux";



//you should pass as props for this component onClick and text content of the tooltip
//data required: if false data is missing, if true data required are complete
export default function AddBtn({onClick, tooltip, className, dataRequired=false}){

console.log("bottone cliccato")


    return (
        <a className={className} >
            <OverlayTrigger
                placement="top"
                delay={{ show: 600, hide: 300 }}
                overlay={
                <Tooltip className="tooltip-container">{tooltip}</Tooltip>
                }
                >
                <button className="plus-button" onClick={() => {
                    if(onClick){
                        onClick()
                    }
                }
                } disabled={dataRequired? false : true} style={{background:'transparent', border:"none"}} >
                    <BsPlusCircleFill className="addBtn"
                    style={{color: dataRequired ? "#83112f": "gray"}} />
                </button>
            </OverlayTrigger>
        </a>
    )
}

