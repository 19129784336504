import React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../../../assets/css/AddDetails.css";
import BackwardIcon from "../../../../assets/images/backward.svg";

import TrashBinIcon from "../../../../assets/images/trashbin.svg";
import PrintIcon from "../../../../assets/images/print.svg";
import PencilIcon from "../../../../assets/images/pencil.svg";
import CustomBadge from "../../../CustomBadge";


export default function NewTarrifa(props) {
  const [show, setShow] = useState(true);
  const [mode, setMode] = useState(true);

  useEffect(() => {
    setShow(props.show);
  }, [props]);

  const handleClose = () => {
    setShow(false);
    props.setAdd(false);
    props.setSelected("");
  };

  const categoriClick = (e) => {
    if (e.target.value === "elettricità") {
      setMode(true);
    } else {
      setMode(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="newTarrifa-Modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} />
      <Modal.Body>
        <div>
          <img
            src={BackwardIcon}
            onClick={() => handleClose()}
            style={{ cursor: "pointer" }}
          ></img>
          <div
            className="d-flex justify-content-between"
            style={{ alignItems: "center" }}
          >
            <div className="contactDetails-title mt-5">Nuova Tariffa</div>
            <div style={{ display: props.selected === "" ? "none" : "" }}>
                <img
                  src={TrashBinIcon}
                  style={{ marginRight: "9px", cursor: "pointer" }}
                  onClick={() => {
                    props.setDel(true);
                    handleClose();
                  }}
                />
                <img
                  src={PencilIcon}
                  style={{ marginRight: "9px", cursor: "pointer" }}
                  onClick={() => {
                    props.setSelected("")
                  }}
                />
                <img loading="lazy" src={PrintIcon} style={{ cursor: "pointer" }} />
              </div>
          </div>
          <fieldset disabled={props.selected === "" ? false : true}>
            <div
              className="form-item-align-box d-block pb-5 pt-5"
              style={{ width: "100%" }}
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Tipo di utenza
                    </label>
                    <select
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      id="categori-select"
                      onClick={(e) => categoriClick(e)}
                    >
                      <option defaultValue="el" selected>
                        elettricità
                      </option>
                      <option value="gas">gas</option>
                      <option value="ac">acqua</option>
                      <option value="ri">riscadalmento</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Nome del piano tariffario
                    </label>
                    <input
                      type="text"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    />
                  </div>
                </div>
              </div>
              {mode === true ? (
                <div className="row gy-4 mt-2">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Costo fisso mensile
                      </label>
                      <input
                        type="text"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        F1
                      </label>
                      <input
                        type="number"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        F2
                      </label>
                      <input
                        type="number"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        F3
                      </label>
                      <input
                        type="number"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row gy-4 mt-2">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Costo fisso mensile
                      </label>
                      <input
                        type="number"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Costo a consumo
                      </label>
                      <input
                        type="number"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </fieldset>

          <div className="d-flex align-items-center mb-3 form-last-btns justify-content-end">
            <button
              type="button"
              className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
              onClick={() => handleClose()}
            >
              Nuovo
            </button>
            <button
              type="button"
              className="next-btn btn-last rounded-3 shadow w-193 me-2 border-0"
              onClick={() => handleClose()}
            >
              Salva
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
