import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../../assets/css/Impostazioni.css"

import { Modal } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BsDownload, BsTrash, BsFiEdit2, BsArchive} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../../assets/images/PayPal.svg"
import Mastercard from  "../../../assets/images/Mastercard.svg"
import Maestro from "../../../assets/images/Maestro.svg"


import DetailAccount from "./DetailAccount";
import Unverified from "./Unverified";
import Verified from "./Verified";



import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "../../../assets/css/Account.css"





import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../../assets/css/Tabs.css";


import SearchInput from "../../SearchInput";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from "../../../actions/accountAction";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";

/* 
    STATI
    in attesa di approvazione: quando l'utente che si registra si registra o fa una modifica
    in attesa di modifica: quando mandi la richiesta di modifica
    approvato: quando approvi la registrazione 

    /user/{id}/approve: setta lo stato ad APPROVED
    /user/{id}/reject: setta lo stato a WAIT_UPDATE
*/


export default function AccountC(){

    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);


    //HANDLE SEARCH
    const [searchTerm, setSearchTerm] = useState("");

    const users = useSelector(state => state.userReducer.users)?.filter(el => ((el.name)?.toLowerCase().includes(searchTerm) || el.surname?.toLowerCase().includes(searchTerm)));
    const verifiedUsers = users.filter(user => user.state === 'APPROVED');
    const unverifiedUsers = users.filter(user => user.state !== 'APPROVED');

    const [id, setId] = useState(null)
    const [subsection, setSubsection] = useState(null)

    function handleId(id){
        setId(id)
    }


    //HANDLE KEY
    const [key, setKey] = useState('unverified');



    return(

                <>
                <Row>
                    <p className="fs-18 text-dark fw-bold">Gestione utenti</p>
                    <p className="fs-14 opacity-75">Verifica dei dati dei nuovi account.</p>
                    <hr className="opacity-10 my-3"/>
                </Row>
                <Row className="mt-2">
                { !id &&
                    <Col md={3} className="px-0 mb-3">
                        <SearchInput
                            setSearch={(term) => {
                            setSearchTerm(term);
                            }}
                        />
                    </Col>
                }

                    {!id && <Row className="mt-3  align-items-center justify-content-center">

                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="account-tabs"
                        >
                        <Tab eventKey="unverified" title="Account da verificare">
                            <Unverified data={unverifiedUsers} handleId={handleId} />
                        </Tab>
                        <Tab eventKey="verified" title=" Account verificati">
                            <Verified data={verifiedUsers} handleId={handleId}/>
                        </Tab>
                        
                    </Tabs>

                </Row> }

                    <Col md={12} className="my-3 px-0">
                        <Breadcrumb>
                            {id && 
                                <Breadcrumb.Item 
                                    className="settings-archive"
                                    onClick={() => {
                                    setSubsection(null)
                                    setId(null)
                                    }}>
                                    Pagina iniziale
                                </Breadcrumb.Item>
                            }
                            {id &&
                                <Breadcrumb.Item active={id && !subsection ? true: false} 
                                    className="settings-archive"
                                    onClick={() => setSubsection(null)}>
                                    {id}
                                </Breadcrumb.Item>
                            }
                            
                            {id && subsection && 
                                <Breadcrumb.Item   className="settings-archive"
                                active={subsection ? true: false} >{subsection}</Breadcrumb.Item>
                            }
                        </Breadcrumb>
                    </Col>

                    {/* DETAILS - TRAMITE SECTION POSSIAMO MANDARE ID DELL'UTENTE TO GET DETAILS */}
                    
                    { id && !subsection &&
                        <DetailAccount id={id} setId={setId} />
                    }




                    
                </Row> 
                </>

        
    )
}