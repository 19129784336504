import * as React from 'react';
import {useState, useEffect} from 'react';
import api from '../api/api';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  {
    label:'Inserisci le informazioni mancanti',
    id:'anagrafica',
    isCompleted: false
  },
  {
    label:'Inserisci i tuoi link social',
    id:'social',
    isCompleted: false

  },
  {
    label:'Inserisci un conto bancario',
    id:'conto',
    isCompleted: false

  },
  {
    label:'Abilita la ricezione dei pagamenti',
    id:'',
    isCompleted: false

  }
];

export default function Progress(props) {

    const [stepStatus, setStepStatus] = useState(steps)
    const [ newUrl, setNewUrl ] = useState()


    function areFirstThreeStepsCompleted(steps) {
      // Verifica se i primi tre step sono completati
      for (let i = 0; i < 3; i++) {
        if (!steps[i].isCompleted) {
          return false; // Se uno dei primi tre step non è completato, restituisci false
        }
      }
      return true; // Se tutti e tre i primi step sono completati, restituisci true
    }


    const handleOpenPopup = (url) => {
        const popupUrl = url;
         // Dimensioni desiderate per la finestra popup
        const width = 600;
        const height = 800;

        // Calcola le posizioni left e top per centrare la finestra
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
        const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
            
        // Apertura della finestra popup
        window.open(popupUrl, "StripeOnboarding", options);
    };

    const handleClick = (index, itemId) => {
      const element = document.getElementById(itemId);
  
      if (itemId && element) {
          element.scrollIntoView({ behavior: 'smooth' });
      } else if (index == 3 && areFirstThreeStepsCompleted(stepStatus)) {
        console.log("red")
          startOnboarding().then(message => {
              handleOpenPopup(message);
          }).catch(err => {
              console.log(err);
          });
      }
  };
  
  const [activeStep, setActiveStep] = useState(0);
  
  async function startOnboarding() {
      try {
          const response = await api().post(`/api/v1/payment/create`, {});
          return response.data.message;
      } catch (err) {
          throw err;
      }
  }
  


   




    

    useEffect(() => {
      if(
        props.data.name &&
        props.data.surname &&
        props.data.birthday &&
        props.data.email &&
        props.data.phone &&
       /*  props.data.businessName && */
        props.data.cap &&
        props.data.fiscalCode &&
        props.data.genre &&
        props.data.houseNumber &&
        props.data.municipality &&
        props.data.province &&
        props.data.address 
    /*     props.data.vatNumber  */
        ){
          //IMPOSTA STEP 1 COME COMPLETATO
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 0) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
        } else {
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
        }

        if(props.data.name){
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
        } else {
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 1) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
        }

        if(props.data.accountingResources?.length > 0){
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 2) {
                return { ...step, isCompleted: true };
              }
              return step;
            });
          });
        } else {
          setStepStatus(prevSteps => {
            return prevSteps.map((step, index) => {
              if (index === 2) {
                return { ...step, isCompleted: false };
              }
              return step;
            });
          });
        }
    }, [props.data])
  
    useEffect(() => {
      // Logica esistente per aggiornare lo stato di completamento degli step...
      
      // Trova il primo step che non è ancora completato e imposta activeStep su quell'indice
      const nextActiveStep = stepStatus.findIndex(step => !step.isCompleted);
      
      // Se tutti gli step sono completati, potresti voler impostare activeStep
      // alla lunghezza di stepStatus per indicare che il processo è completo
      // Oppure, potresti voler gestire questo caso in modo diverso a seconda delle tue esigenze
      setActiveStep(nextActiveStep !== -1 ? nextActiveStep : stepStatus.length);
    
    }, [props.data, stepStatus]); // Assicurati di includere stepStatus nelle dipendenze di useEffect se il suo stato viene aggiornato all'interno di useEffect
    


  return (
    <Box sx={{ width: '100%', marginBottom:"2rem" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {stepStatus.map((el, index) => (
          <Step 
          key={index} 
          completed={el.isCompleted}
          onClick={() => {
            handleClick(index, el.id)
          }}
          style={{cursor: "pointer"}}>
            <StepLabel>{el.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}