import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { BsPlusCircleFill } from "react-icons/bs";
import api, {
  first_note_endpoint,
  note_owner_assignment_endpoint,
  note_unit_assignment_endpoint,
  note_supplier_assignment_endpoint,
  note_condominium_assignment_endpoint,
  note_conductor_assignment_endpoint,
  supplier_endpoint
  
} from "../../../api/api";
import { useNavigate } from "react-router-dom";

import FileCard from "./Tipologia/FileCard";
import Movimento from "./Tipologia/Movimento";
import PrelievoDaContoInCassa from "./Tipologia/PrelievoDaContoInCassa";
import TrasferimenttoTraContiBancari from "./Tipologia/TrasferimenttoTraContiBancari";
import VersamentoTraCassaEConto from "./Tipologia/VersamentoTraCassaEConto";
import { isEmptyObject } from "../../../utility";

import {Row, Col, Button, Modal} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ButtonRm from "../../../components/ButtonRm";
import CustomToggle from "../../../components/CustomToggle";

import SupplierModal from "./Tipologia/SupplierModal";

import CustomBadge from "../../CustomBadge";

export default function DocumentiModal(props) {

  const navigate = useNavigate()

  const [formData, setFormData] = useState(() => {
    const initialValue = JSON.parse(localStorage.getItem("prima_nota"));
    return {
      typology: initialValue?.typology || "Movimento",
      account: initialValue?.account || "Spese Generali",
      subAccount: initialValue?.subAccount || "bollette",
      //administrator: initialValue?.administrator || "",

      operationDescription: initialValue?.operationDescription || "",
      operationDate: initialValue?.operationDate || "", //field competenza
      startDate: initialValue?.startDate || "",
      endDate: initialValue?.endDate || "", //fieldscadenza
      billDate: initialValue?.billDate || "",
      billed: initialValue?.billed || false,
      paid: initialValue?.paid || false,

      isEnteringAmount: initialValue?.isEnteringAmount || true,
      taxable: initialValue?.taxable || 0,
      vatCode: initialValue?.vatCode || 1,
      paymentMethod: initialValue?.paymentMethod || "",
      fromAccount: initialValue?.fromAccount || "",
      toAccount: initialValue?.toAccount || "",
      addWithholdingTax: initialValue?.addWithholdingTax || false,
      withholdingTax: initialValue?.withholdingTax || "",

      amount: initialValue?.amount || 0,
      modality: initialValue?.modality || "",

      conductors: initialValue?.conductors || [],
      owners: initialValue?.owners || [],
      suppliers: initialValue?.suppliers || [],

      attachment: initialValue?.attachment || [],
      condominium: initialValue?.condominium || {},
      unit: initialValue?.unit || {},
      typeBuilding: initialValue?.typeBuilding || "Unità",

    };
  });


  const [selectedOwners, setSelectedOwners] = useState([]);

  //USIAMO QUESTA VARIABILE PER SUPPLIERS SELEZIONATI
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  useEffect(() => {
    localStorage.setItem("prima_nota", JSON.stringify(formData));
  }, [formData]);

  //funzione per aggiornare il form
  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(e);
    type === "checkbox" ? console.log(checked) : console.log(value);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]:
          type === "checkbox"
            ? checked
            : name === "isEnteringAmount"
            ? !formData.isEnteringAmount
            : value,
      };
    });
    console.log(formData);
  };

  //funzione che non viene usata
  const onChangeFromSelect = (e) => {
    const value = [...props.unit, e.target];
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [e.target.name]: value,
      };
    });
  };

  //funzione di salvataggio
  const save = async () => {
    //faccio una copia dei dati in formdata
    let formDataToSend = { ...formData };
    //elimino i dati che bisogna associare con endpoint differente
      delete formDataToSend.unit;
      delete formDataToSend.condominium;
      delete formDataToSend.suppliers;
      delete formDataToSend.conductors;
      delete formDataToSend.owners;
      delete formDataToSend.attachment;

      //STRINIGFY FORMDATA OWNERS E CONDUCTORS
      let partition = JSON.stringify([...formData.owners, ...formData.conductors])

      //aggiorno l'oggetto coi dati da inviare e aggiungo in coda partition stringified
      formDataToSend = {...formDataToSend, partition}


    try {
       //gestione allegati

      let formDataToSendAttachment = new FormData();

      file.forEach((file) => {
        const blob = new Blob([file]);
        formDataToSendAttachment.append("parts", blob, file.name);
        //formDataToSend.append("parts", file, file.name);
      });
      
      let sentUploadAttachment = formDataToSendAttachment.get("parts") !== null;

      let result;

        //riaggiorno l'oggetto coi dati da inviare, per il cacolo di amount
        // se movimento lo calcolo come imponibile + iva
        //se altro tipo, rimane solo amount
        let formDataToSendBody = { ...formDataToSend };
        formDataToSendBody.amount = parseInt(formDataToSendBody.amount);
        result = await api().post(
          `${first_note_endpoint}`,
          formDataToSend.typology === "Movimento"
            ? {
                ...formDataToSendBody,
                amount: formDataToSend.taxable * formDataToSend.vatCode, 
              }
            : { ...formDataToSendBody, amount: parseInt(formDataToSend.amount) }
        );
      
        //INVIA ALLEGATI DOPO  LA POST PRECEDENTE 
				if (result) {
					if (sentUploadAttachment) {
					await api().put(`${first_note_endpoint}upload/${result.data.id}`, formDataToSendAttachment);
					}
				}
				
      //GESTIONE ASSEGNAZIONI

      // assign owners
      if (formData.owners.length > 0) {
        formData.owners.forEach((owner) => {
          api().post(`${note_owner_assignment_endpoint}`, {
            noteID: result.data.id,
            ownerID: owner.id,
          });
        });
      }

       // assign conductors
      if (formData.conductors.length > 0) {
        formData.conductors.forEach((conductor) => {
          api().post(`${note_conductor_assignment_endpoint}`, {
            noteID: result.data.id,
            conductorID: conductor.id,
          });
        });
      }

      // assign suppliers
      if (selectedSuppliers.length > 0) {
        console.log("yes, at leats a supplier exist. association!")
        selectedSuppliers.forEach((supplier) => {
          api().post(`${note_supplier_assignment_endpoint}`, {
            noteID: result.data.id,
            supplierID: supplier.id,
          });
        });
      }

      // assign unit
      if (!isEmptyObject(formData.unit)) {
        api().post(`${note_unit_assignment_endpoint}`, {
          noteID: result.data.id,
          unitID: formData.unit.id,
        });
      }

      // assign condominium
      if (!isEmptyObject(formData.condominium)) {
        api().post(`${note_condominium_assignment_endpoint}`, {
          noteID: result.data.id,
          condominiumID: formData.condominium.id,
        });
      }

      //rimuovo oggetto prima_nota da storage
      localStorage.removeItem("prima_nota");
    } catch (error) {
      console.log(error);
    } finally{
      navigate(-1)
    }

  };

  const Tipologia = [
    "Movimento",
    "Versamento tra cassa e conto",
    "Prelievo da conto in cassa",
    "Trasferimento tra conti bancari",
  ];
  const [Tipologia_index, setTipologiaIndex] = useState("0");

  //GESTIONE ALLEGATI 
  const [file, setFile] = useState([]);
  const setFileName = (index, new_name) => {
    let temp = file;
    temp[index].name = new_name;
    setFile(temp);
  };
  const deleteFile = (index) => {
    let temp = [...file];
    temp.splice(index, 1);
    setFile(temp);
  };
  const handleChange = (e) => {
    let temp = [...file];
    setFile([...temp, ...e.target.files]);
  };

  const setTypologyManagement = (e) => {
    //console.log(e.target.value);
    //setTipologiaIndex(e.target.value);
    if (e.target.value === 4)
      setFormData({ ...formData, isEnteringAmount: false });
    else if (e.target.value === 5)
      setFormData({ ...formData, isEnteringAmount: true });
    else setFormData({ ...formData, isEnteringAmount: null });
  };

  //ACCORDION
  const [activeKey, setActiveKey] = useState('');

  //SUPPLIER MODAL
  const [subject_modal_show, setSupplierModalShow] = useState(false);
  const supplierModalClose = () => {
  //MODAL SWAP
    setSupplierModalShow(false)
  };
  const supplierModalShow = () => {
    setSupplierModalShow(true)
  };
  
  //lista fornitori
  const [suppliers, setSuppliers] = useState([]);
  //prendi fornitori
  const fetchSuppliers = async () => {
    try {
      const res = await api().get(`${supplier_endpoint}`);
      if (res.data) {
        setSuppliers(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //salva i fornitori selezionati in selectedSuppliers
  const addSelectedSuppliers = async (id) => {
    let filteredList = selectedSuppliers.filter(
      (supplier) => supplier.id === id
    );
    filteredList.length === 0 &&
      setSelectedSuppliers([
        ...selectedSuppliers,
        suppliers.filter((supplier) => supplier.id === id)[0],
      ]);
  };

  //rimuovi fornitori
  const removeSelectedSupplier = async (id) => {
    setSelectedSuppliers(
      selectedSuppliers.filter((supplier) => supplier.id !== id)
    );

  };

  //prende i supplier
  const getSupplierList = async () => {
    await fetchSuppliers();
    supplierModalShow();
  };

  //gestione modale esci dalla profilazione
  const [show, setShow] = useState(false);
  const handleModal = () => {
      setShow(true);
  };

  return (
    <div className="d-block w-100 ">
      {!subject_modal_show &&
      <> 
        <div>
{/*           <h2 className="fs-20 text-dark mb-2 fw-bold">Nuova Prima Nota</h2>
 */}          <p>I campi contrassegnati da <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
 sono obbligatori.</p>
        </div>
        <Form className="mt-4">
          <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="0" activeKey={activeKey}>Tipologia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Tipologia
                    </label>
                    <select
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      name="typology"
                      onChange={onChange}
                    >
                      {Tipologia.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {menu}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>						
              </Accordion.Body>
            </Accordion.Item>

            {formData.typology === "Movimento" ? (
                <div>
                  <Movimento
                    formData={formData}
                    setFormData={setFormData}
                    onChange={onChange}
                    selectedSuppliers={selectedSuppliers}
                    setSelectedSuppliers={setSelectedSuppliers}
                    getSelectedSuppliers={selectedSuppliers}
                    activeKey={activeKey}
                    getSupplierList={getSupplierList}
                    removeSelectedSupplier={removeSelectedSupplier}
                    suppliers={suppliers}
                  />
                </div>
              ) : formData.typology  === "Versamento tra cassa e conto" ? (
                <VersamentoTraCassaEConto
                  formData={formData}
                  onChange={onChange}
                  onChangeFromSelect={onChangeFromSelect}
                />
              ) : formData.typology === "Prelievo da conto in cassa" ? (
                <PrelievoDaContoInCassa
                  formData={formData}
                  onChange={onChange}
                  onChangeFromSelect={onChangeFromSelect}
                />
              ) : formData.typology === "Trasferimento tra conti bancari" ? (
                <TrasferimenttoTraContiBancari
                  formData={formData}
                  onChange={onChange}
                  onChangeFromSelect={onChangeFromSelect}
                />
              ) : (
                <div>
                  <Movimento
                    ormData={formData}
                    setFormData={setFormData}
                    onChange={onChange}
                    selectedSuppliers={selectedSuppliers}
                    setSelectedSuppliers={setSelectedSuppliers}
                    getSelectedSuppliers={selectedSuppliers}
                    activeKey={activeKey}
                    getSupplierList={getSupplierList}
                    removeSelectedSupplier={removeSelectedSupplier}
                  />
                </div>
              )}

        <Accordion.Item eventKey="6" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="6" activeKey={activeKey}>Allegati</CustomToggle>
              </Card.Header>
              <Accordion.Body>
                <label htmlFor="input-file" className="black-link my-2">
                  <BsPlusCircleFill className="plus-icon" />
                  Aggiungi allegati
                </label>
                <input
                  type="file"
                  id="input-file"
                  className="d-none"
                  multiple={true}
                  onChange={handleChange}
                ></input>

              {file.length > 0
                  ? file.map((item, index) => {
                      return (
                        <FileCard
                          key={index}
                          name={item.name}
                          setFileName={setFileName}
                          deleteFile={deleteFile}
                          id={index}
                        />
                      );
                    })
                  : ""}
              </Accordion.Body>
            </Accordion.Item>
              <div className="col-12">
                <div className="d-flex align-items-center mt-5 form-last-btns">
                  <ButtonRm
                    variant="outline"
                    className="me-2"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Annulla
                  </ButtonRm>
                  <ButtonRm
                    variant="primary"
                    onClick={save}
                    disabled={
                      !(formData.operationDate && formData.operationDescription && (formData.taxable || formData.amount))
                    }
                  >
                    Salva
                  </ButtonRm>
                </div>
              </div>
          </Accordion>
        </Form>
      </>
      }

      <Modal
        show={subject_modal_show}
        className="center-modal"
        onHide={supplierModalClose}
        fullscreen={true}
        centered 
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            onClick={supplierModalClose}
            className="float-end border-0 bg-white"
          >
            <i className="bi bi-x"></i>
          </Button>
          <SupplierModal
            table_data={
              suppliers.length > 0 &&
              suppliers.map((supplier) => [
                supplier.category,
                supplier.businessName,
                supplier.cellularTelephone,
                supplier.subjectType,
              ])
            }
            ids={suppliers.map((supplier) => supplier.id)}
            setShow={setSupplierModalShow}
            addSupplier={addSelectedSuppliers}
          />
        </Modal.Body>
      </Modal>

      <Modal show={show} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi uscire dalla profilazione?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              I dati inseriti verranno persi
            </h6>
            <ButtonRm variant="primary" onClick={() => {
                navigate("/prima-nota");
              }}>
                Conferma
              </ButtonRm>
           
           
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => setShow(false)}
            >
              Indietro
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
