import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import people from "../../assets/images/people.png"

import hero from "../../assets/images/bg-hero-fornitori.png"

import casa from "../../assets/images/casa+pin.png"

import vetrina from "../../assets/images/vetrina-mock-2.png"

import building3 from "../../assets/images/build.png"

import logo from "../../assets/images/Group 1189.svg"

import { Link } from 'react-router-dom';

import ResponsiveNavbar from './NavRm';

import { useMediaQuery } from 'react-responsive';

import Footer from './Footer';


export default function Fornitore (){
    const isDesktop = useMediaQuery({
		query: "(min-width: 767px)",
	});

    return (
        <div style={{background:'#fff'}}>
            <div style={{background: 'linear-gradient(180deg, #E6F0FF 0%, rgba(230, 240, 255, 0) 100%)', backgroundImage: `url(${hero})`, backgroundSize: isDesktop? "contain" : "", backgroundRepeat: "no-repeat"}}>
                <Container className="px-3 px-md-0">

                    <ResponsiveNavbar />

                    <Row className="flex-column mb-5">
                        <Col className="d-flex justify-content-center" style={{padding: "7rem 0rem 4rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center mb-4 fs-40 fs-lg-50 syne-font">Amplia la tua rete di clienti 
e opportunità di business</h1>
{/* <p>Iscriviti gratuitamente e ottieni una vetrina online per mostrare il tuo portfolio e i tuoi servizi</p>
 */}                               <Link to="/"> <Button size="lg" variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Entra in Real Meta</Button> </Link>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px", marginTop:"5rem" }}>
                        <Col md={5}>
                            <h2 className="syne-font">Accedi a un vasto bacino di clienti</h2>
                            <p>Ogni operatore che accede alla nostra piattaforma potrà visionare la vostra realtà, mettendosi direttamente in contatto con voi per qualsiasi necessità voi riusciate a soddisfare.
                            </p>
                        </Col>
                        <Col md={5} className="d-flex justify-content-end">
                            <Image src={people} rounded className='w-100 px-3 ' />
                        </Col>
                    </Row>

                    <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={5} className="order-2 order-md-1 my-3">
                            <Image src={vetrina} rounded className='w-100' />
                        </Col>

                        <Col md={5}className="order-1 order-md-2">
                            <h2 className="syne-font">Crea il tuo sito vetrina gratuito </h2>
                            <p>Avrai un nuovo canale dove poterti sponsorizzare gratuitamente.
In pochi click riuscirai a crearti il tuo spazio digitale.
                            </p>
                        </Col>
                    </Row>

                    <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={5}>
                            <h2 className="syne-font">Gestisci le tue commesse</h2>
                            <p>Una volta preso un lavoro potrai gestire all’interno della piattaforma l’intera ciclo produttivo.
                            </p>
                        </Col>
                        <Col md={5}>
                            <Image src={casa} rounded className='w-100'  />
                        </Col>
                    </Row>

                    <Row className="my-5 justify-content-between align-items-center mx-auto " style={{maxWidth: "1100px", marginBottom:"3rem"}}>
                        <Col lg={3} style={{maxWidth: "1100px", background: "#E7EFF9", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                            <h1 className="fs-80 opacity-50 syne-font">1</h1>
                            <p className='mt-2 fw-bold syne-font'>Registrati gratuitamente</p>
                            <p>
                            L’iscrizione e i servizi all’interno di Real Meta Property sono gratuiti per i fornitori.
                            </p>
                        </Col>
                        <Col lg={3} style={{maxWidth: "1100px", background: "#E7EFF9", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                            <h1 className="fs-80 opacity-50 syne-font">2</h1>
                            <p className='mt-2 fw-bold syne-font'>Inserisci le tue informazioni</p>
                            <p>
                            Con una procedura guidata in pochissimo tempo avrai una tua vetrina digitale.
                            </p>
                        </Col>
                        <Col lg={3} style={{maxWidth: "1100px", background: "#E7EFF9", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                            <h1 className="fs-80 opacity-50 syne-font">3</h1>
                            <p className='mt-2 fw-bold syne-font'>Inizia a collaborare con Real Meta</p>
                            <p>
                            Sei già pronto a iniziare le tue prossime collaborazioni.

                            </p>
                        </Col>
                    </Row>

                    
                    <Row className="mt-5 justify-content-start align-items-center mx-auto p-5 position-relative" style={{maxWidth: "1100px", background: "#0F1B4C", borderRadius: "1rem", color:"white"}}>
                        <Col md={8} className="left">
                            <h2 className="syne-font">Creare sinergie non è mai stato cosi semplice.
</h2>
                            <Link to="/">
                            <Button variant="primary" className="mt-3" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}}>Prova gratis</Button></Link>

                        </Col>

                        <Image src={building3} width="300px" style={{ width: "300px",
                            bottom: 0,
                            right: 0,
                            position: "absolute"}}
                            className='d-none d-md-block'
                        />
        
                    </Row>


                    <Footer />

                   

                </Container>
            </div>
        </div>
    )
}