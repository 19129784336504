import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ButtonRm from "../../../ButtonRm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";
import CustomBadge from "../../../CustomBadge";

export default function Section1({ selectMenu }) {

  /* MANTIENE LA SCELTA DEL TIPO DI REGISTRAZIONE */
  const [radioStatus, setRadioStatus] = useState(-1);

  //MOSTRA PAGINA OUTPUT
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [output, setOutput] = useState({canoneComplessivo: 0,
    canoneMensile: 0,
    canoneAnnuale: 0,
    impostaCalc: 0,
    impostadaversare: 0,
    sconto: 0,
    impostaScontata:0,
    importoDepositoCauzionale: 0
  })

  //MOSTRA PRIMA PAGINA
  const [isFirst, setIsFirst] = useState(true);

  //MOSTRA SECONDO STEP - IMPOSTE DI REGISTRAZIONE AFFITTO
  const [isRegistrazione, setRegistrazione] = useState(false);

  //MOSTRA SECONDO STEP - IMPOSTE DI CESSIONE O SUBENTRO
  const [isCessione, setCessione] = useState(false);

   //MOSTRA SECONDO STEP - IMPOSTE DI CESSIONE O SUBENTRO
  const [isProposta, setProposta] = useState(false);

  const [codice, setCodice] = useState("");
  const [importo, setImporto] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const [isMobile, setIsMobile] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const [mobile_menu, setMobileMenu] = useState(false);

  /*HANDLE IMPOSTE DI REGISTRO LOCAZIONE*/
  const [registrationTaxData, setRegistrationTaxData] = useState({
    canone: null,
    base: "Mensile",
    immobile: "Fabbricati a uso abitativo",
    durata: null,
    deposito: null,
    iva:false,
    concordato: false,
    tensione: false,
    tipoDurata: 'mese',
    tipoPagamento: "unico"
  })

  /*HANDLE IMPOSTE DI REGISTRO CESSIONE*/
  const [cessioneTaxData, setCessioneTaxData] = useState({
    tipo:"con corrispettivo",
    canone: null,
  })

  /*HANDLE IMPOSTE DI REGISTRO PROPOSTA*/
  const [propostaTaxData, setPropostaTaxData] = useState({
    tipo: "fabbricati",
    pagamento: "nessuno",
    caparra: "",
    acconto: "",
    iva: false
  })

  function handleRegistrationTaxData(e){
    const {value, name, type, checked} = e.target

    /* se value è di tipo number verrà convertito da stringa in numero */
    let convertedValue 

   

    setRegistrationTaxData(prevFormData => {

      if(type === "number"){
        convertedValue = parseInt(value.replace(",", "."))
      } else { 
        convertedValue = value 
      }

      return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : convertedValue
      }
    })

    if((registrationTaxData.tipoDurata == "anno" && registrationTaxData.durata == 1) || (registrationTaxData.tipoDurata == "mese" && registrationTaxData.durata < 18)){
        setRegistrationTaxData(prev => {
          return {
            ...prev,
            tipoPagamento: 'unico'
          }
        })
      
    }

    /* if(name == 'tipoPagamento' && value == 'rata'){
      setRegistrationTaxData(prev => {
        return {
          ...prev,
          tipoDurata: 'anno'
        }
      })
    } */
  }

  function handleCessioneTaxData(e){
    const {value, name, type, checked} = e.target

    /* se value è di tipo number verrà convertito da stringa in numero */
    let convertedValue 

    setCessioneTaxData(prevFormData => {

      if(type === "number"){
        convertedValue = parseInt(value.replace(",", "."))
      } else { 
        convertedValue = value 
      }

      return {
          ...prevFormData,
          [name]: convertedValue
      }
    })
  }

  function handlePropostaTaxData(e){
    const {value, name, type, checked} = e.target

    /* se value è di tipo number verrà convertito da stringa in numero */
    let convertedValue 

    setPropostaTaxData(prevFormData => {

      if(type === "number"){
        convertedValue = parseInt(value.replace(",", "."))
      } else { 
        convertedValue = value 
      }

      return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : convertedValue
      }
    })
  }

  /* AGGIORNA IL VALORE DEL RADIO BUTTON - TIPO DI REGISTRAZIONE */
  const radioChanged = (value) => {
    setRadioStatus(value);
  };

  /* GESTISCE LO STEP DA MOSTRARE QUANDO SI CLICCA IL PULSANTE AVANTI
  A SECONDA DEL RADIO BUTTON SELEZIONATO - TIPO DI REGISTRAZIONE*/
  const AvantiClicked = () => {
    switch (radioStatus) {
      case 0:
        setIsFirst(false);
        setRegistrazione(true);
        break;
      case 1:
        setIsShowDetail(true);
        setIsFirst(false);
        setCodice(1503);
        setImporto(parseFloat(67).toFixed(2));
        break;
      case 2:
        setCessione(true)
        setIsFirst(false);
        break;
      case 3:
        setIsShowDetail(true);
        setIsFirst(false);
        setCodice("1550");
        setImporto(parseFloat(200).toFixed(2));
        break;
      case 4:
        setProposta(true)
        setIsFirst(false);
        break;
      default:
        break;
    }
  };

  /* GESTISCE LO STEP DA MOSTRARE QUANDO SI CLICCA IL PULSANTE INDIETRO*/
  const registrazionePrevious = () => {
    /* GESTIONE CASO DIVERSO, PER IMPOSTE DI REGISTRO AFFITTO */
    if(radioStatus == 0 && isShowDetail) {
      setRegistrazione(true)
      setIsShowDetail(false);
      /* GESTIONE CASO DIVERSO, PER IMPOSTE DI REGISTRO CONCESSIONE */
    } else if(radioStatus == 2 && isShowDetail){
      setCessione(true)
      setIsShowDetail(false); 
    } else if(radioStatus == 4 && isShowDetail){
      setProposta(true)
      setIsShowDetail(false)}
      else {
      setIsShowDetail(false);
      setRegistrazione(false);
      setCessione(false)
      setProposta(false)
      setIsFirst(true);
    };
  }

  /* GESTISCE L'OUTPUT PER REGISTRAZIONE AFFITTO*/
  const canoneClicked = () => {
      setRegistrazione(false);
      setIsShowDetail(true);

      //valori da calcolare
      let canoneAnnuale
      let percentualeApplicabile
      let impostaAnnuale
      let impostePerTuttaLaDurata

      let canoneComplessivo
      let canoneMensile

      //TASSO INTERESSE 2024
      let tassoInteresse = 2.5
      let tassoInteressePerCalcolo = tassoInteresse  / 2

      
      //1.RICAVA CANONE ANNUALE PER LE IMPOSTE DI REGISTRO 

      //SE CANONE SU BASE ANNUALE
      if(registrationTaxData.base == "Annuale"){
        //CANONE ANNUALE == INPUT UTENTE
        canoneAnnuale = registrationTaxData.canone
      } else if (registrationTaxData.base == "Mensile") {
        //SE CANONE SU BASE MENSILE, MOLTIPLICHIAMO * 12
        canoneAnnuale = registrationTaxData.canone * 12
      } else {
        //SE CANONE PER TUTTA LA DURATA
        if (registrationTaxData.tipoDurata == 'anno'){
          //SE LA DURATA è ESPRESSA IN ANNI, OTTENIAMO IL CANONE ANNUALE DIVIDENDO CANONE PER LA DURATA 
          canoneAnnuale = registrationTaxData.canone / registrationTaxData.durata
        } else {
          //SE LA DURATA E' ESPRESSA IN MESI, 
          //calcolo l'ammontare della singola mensilità 
          //la moltiplico x 12 per ottenere il canone annuale
          let mensilità = registrationTaxData.canone / registrationTaxData.durata
          canoneAnnuale = mensilità * 12
        }
      }

      //CALCOLO CANONE COMPLESSIVO 

      //SE CANONE SU BASE ANNUALE
      if(registrationTaxData.base == "Annuale"){
        //SE DURATA E' ESPRESSA IN ANNI
        if (registrationTaxData.tipoDurata == 'anno'){
          //CANONE COMPLESSIVO OTTENUTO MOLTIPLICANDO ANNUALE * DURATA IN ANNI
          canoneComplessivo = canoneAnnuale * registrationTaxData.durata
          //CANONE MENSILE : ANNUALE : 12
          canoneMensile = canoneAnnuale / 12
        } else {
          //SE DURATA E' ESPRESSA IN MESI
          //PER OTTENERE IL CANONE MENSILE, DIVIDO IL CANONE ANNUALE PER 12
          canoneMensile = registrationTaxData.canone / 12
          //PER OTTENERE IL COMPLESSIVO MOLTIPLICO IL MESNILE * LA DURATA
          canoneComplessivo = canoneMensile * registrationTaxData.durata
        }
      } else if(registrationTaxData.base == "Mensile"){
         //SE CANONE SU BASE MENSILE
        if (registrationTaxData.tipoDurata == 'anno'){
          //SE DURATA E' ESPRESSA IN ANNI
          //moltiplico il canone annuale * la durata in anni
          canoneComplessivo = canoneAnnuale * registrationTaxData.durata
          //per il mensile divide il canone per 12 o posso usare direttamente registrationTaxData.canone
          canoneMensile = canoneAnnuale / 12
        } else {
          //SE DURATA E' ESPRESSA IN MESI
          canoneMensile = registrationTaxData.canone 
          //CANONE MENSILE * I MESI DI DURATA
          canoneComplessivo = canoneMensile * registrationTaxData.durata
        }
      } else {
        //SE CANONE PER TUTTA LA DURATA
        //PRENDO INPUT UTENTE DIRETTAMENTE
        canoneComplessivo = registrationTaxData.canone 
        if (registrationTaxData.tipoDurata == 'anno'){
          //SE DURATA E' ESPRESSA IN ANNI
          //PRENDO CANONE ANNUALE CALCOLATO PRECEDENT. registrationTaxData.canone / registrationTaxData.durata
          canoneMensile = canoneAnnuale / 12
        } else {
          //SE DURATA E' ESPRESSA IN MESI
          canoneMensile = registrationTaxData.canone / registrationTaxData.durata

        }


      }
      //2.CAPIRE LA PERCENTUALE
      if(registrationTaxData.immobile === "Fabbricati a uso abitativo" ||
        registrationTaxData.immobile === "Altri immobili" ){
          percentualeApplicabile = 2
      } else if (registrationTaxData.immobile === "Fabbricati strumentali per natura" && !registrationTaxData.iva ) {
        percentualeApplicabile = 2
      } else if (registrationTaxData.immobile === "Fabbricati strumentali per natura" && registrationTaxData.iva ) {
        percentualeApplicabile = 1
      } else if (registrationTaxData.immobile === "Fondi rustici" ){
        percentualeApplicabile = 0.5
      }
      
      //3.CAPIRE SE CANONE CONCORDATO
      if(registrationTaxData.concordato && registrationTaxData.tensione){
        const imponibileRidottoAnnuale = (canoneAnnuale * 70)/100
        impostaAnnuale = (imponibileRidottoAnnuale * percentualeApplicabile) / 100
        if(registrationTaxData.tipoDurata == 'anno'){
          impostePerTuttaLaDurata = impostaAnnuale * registrationTaxData.durata
        } else {
          impostePerTuttaLaDurata = (impostaAnnuale / 12) * registrationTaxData.durata
        }
      } else {
        impostaAnnuale = (canoneAnnuale * percentualeApplicabile) / 100
          if(registrationTaxData.tipoDurata == 'anno'){
            impostePerTuttaLaDurata = impostaAnnuale * registrationTaxData.durata
          } else {
            impostePerTuttaLaDurata = (impostaAnnuale / 12) * registrationTaxData.durata
          }
      }


      //SI ROMPE SE FONDO RUSTICO
      console.log({
        impostaAnnuale,
        impostePerTuttaLaDurata
      })

      //4.CALCOLO DEPOSITO CAUZIONALE - calcolato solo una volta, non per ogni anno del contratto
      const importoDepositoCauzionale = registrationTaxData.deposito && registrationTaxData.deposito != NaN ? (registrationTaxData.deposito * 0.5)/100 : 0

      //FORMULA SINGOLO ANNO => [(importo annuale * percentuale)/ 100]  + deposito
      //FORMULA PIU' ANNI => {[(importo annuale * percentuale)/ 100] * durata}  + deposito

      let impostaCalc
      let impostadaversare
      let sconto
      let impostaScontata

      //SE UNICA SOLUZIONE
      //applica lo sconto solo se durata è > 18 mesi o > 1 anno
      if(registrationTaxData.tipoPagamento == "unico"){

        if((registrationTaxData.tipoDurata == "anno" && registrationTaxData.durata == 1) || (registrationTaxData.tipoDurata == "mese" &&    registrationTaxData.durata < 18)){
          impostaCalc = impostePerTuttaLaDurata 
          sconto = 0
          impostaScontata = impostaCalc - (impostaCalc * sconto) / 100
          impostadaversare = impostaScontata > 67? parseFloat(impostaScontata).toFixed(2):parseFloat(67).toFixed(2) + importoDepositoCauzionale
        } else {
          impostaCalc = impostePerTuttaLaDurata 
          //DA CORREGGERE VEDI ASANA
          // Definizione del tasso di interesse per il calcolo (metà del tasso di interesse del 2024)

          // Calcolo dello sconto
          if (registrationTaxData.tipoDurata == 'anno') {
              // Se la durata è in anni, moltiplica l'interesse per il numero di anni
              sconto = tassoInteressePerCalcolo * registrationTaxData.durata;
          } else {
              // Se la durata è in mesi, calcola il numero approssimato di anni
              let anniApprossimati = Math.ceil(registrationTaxData.durata / 12);
              
              // Calcola lo sconto in base agli anni approssimati
              sconto = tassoInteressePerCalcolo * anniApprossimati;
          }

        // Arrotonda lo sconto a due decimali
        sconto = Math.round(sconto * 100) / 100;

          impostaScontata = impostaCalc - (impostaCalc * sconto) / 100
          impostadaversare = impostaScontata > 67? parseFloat(impostaScontata).toFixed(2):parseFloat(67).toFixed(2) + importoDepositoCauzionale
        }
        
      } else {
        //SE RATEALE
        impostaCalc = impostaAnnuale 
        //NOM C'E' SCONTO
        sconto = 0
        //USO IMPOSTA SCONTATA PER IMPORTO RATE SUCCESIVE
        impostaScontata = impostaCalc - (impostaCalc * sconto) / 100
        //USO IMPOSTA DA VERSARE PER IMPORTO PRIMA RATA
        //CONTROLLO => IL PRIMO ANNO L'IMPOSTA NON PUO' ESSERE INFERIORE AI 67

        impostadaversare = impostaScontata > 67? parseFloat(impostaScontata).toFixed(2):parseFloat(67).toFixed(2)
      }


      setCodice(1500);
      //setImporto(result > 67? parseFloat(result).toFixed(2):parseFloat(67).toFixed(2) );
      setOutput({
        canoneComplessivo,
        canoneMensile,
        canoneAnnuale,
        impostaCalc,
        impostadaversare,
        sconto,
        impostaScontata,
        importoDepositoCauzionale

      })
  };

  /* GESTISCE L'OUTPUT PER REGISTRAZIONE Cession*/
  const cessioneClicked = () => {
    setRegistrazione(false);
    setCessione(false)
    setIsShowDetail(true);

    if(cessioneTaxData.tipo == "senza corrispettivo"){
      setCodice(1502);
      setImporto(parseFloat(67).toFixed(2));
    } else if (cessioneTaxData.tipo == "con corrispettivo" && cessioneTaxData.canone){
      const result = (cessioneTaxData.canone * 2)/100
      setCodice(1502);
      setImporto(result > 67? parseFloat(result).toFixed(2):parseFloat(67).toFixed(2) );
    }

  };

  /* GESTISCE L'OUTPUT PER REGISTRAZIONE Proposta*/
  const propostaClicked = () => {
    setRegistrazione(false);
    setCessione(false)
    setProposta(false)
    setIsShowDetail(true);

    if(propostaTaxData.pagamento == "nessuno"){
      setCodice(1550);
      setImporto(parseFloat(200).toFixed(2));
    } else if (propostaTaxData.pagamento == "acconto sul prezzo di vendita"){
      const result = (parseFloat(propostaTaxData.acconto) * 3)/100
      setCodice(1550);
      setImporto((200 + parseFloat(result)).toFixed(2) );
    } else if (propostaTaxData.pagamento == "caparra confirmatoria"){
      const result = (parseFloat(propostaTaxData.caparra) * 0.5)/100
      setCodice(1550);
      setImporto((200 + parseFloat(result)).toFixed(2) );
    } else if (propostaTaxData.pagamento == "caparra e acconto"){
      const result = (parseFloat(propostaTaxData.caparra) * 0.5)/100 + (parseFloat(propostaTaxData.acconto) * 3)/100
      setCodice(1550);
      setImporto((200 + parseFloat(result)).toFixed(2) );
    }
  }

  /* GESTISCE OUTPUT DI TUTTI GLI ALTRI CASI*/
  const resultClicked = () => {
    setIsShowDetail(false);
    setIsFirst(true);
  };

/* GESTISCE VERSIONE DESKTOP-MOBILE BY THIERRY => MA NON SERVE */
  useEffect(() => {
    if (width <= 575) {
      setIsMobile(true);
      setIsDesktop(false);
    } else if (width >= 576 && width < 767) {
      setIsMobile(true);
      setIsDesktop(false);
    } else if (width >= 768 && width < 991) {
      setIsMobile(false);
      setIsDesktop(true);
    } else if (width >= 992 && width < 1199) {
      setIsMobile(false);
      setIsDesktop(true);
    } else {
      setIsMobile(false);
      setIsDesktop(true);
    }
  }, [width]);

  //DATI ESPORTAZIONE
  let item = {};

  if (radioStatus === 0) {
    item = {
      "Durata del contratto": `${registrationTaxData.durata} ${
        registrationTaxData.tipoDurata === "anno" ? "anno/i" : "mese/i"
      }`,
      "Canone complessivo": `€ ${output.canoneComplessivo.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
      "Canone annuale": `€ ${output.canoneAnnuale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
      "Canone mensile": `€ ${output.canoneMensile.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
      "Modalità di pagamento dell’imposta": registrationTaxData.tipoPagamento === "rata" ? "Rateale" : "Unica Soluzione",
    };
  
    if (registrationTaxData.tipoPagamento === "unico") {
      item["Imposta calcolata"] = `€ ${output.impostaCalc.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      if (registrationTaxData.base !== 'Per tutta la durata del contratto') {
        item["Sconto applicato"] = `${output.sconto.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% - € ${(output.impostaCalc - output.impostaScontata).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      }
      item["Imposta da versare"] = `€ ${output.impostadaversare.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      if (registrationTaxData.deposito) {
        item["Imposta + % sul Deposito"] = `€ ${(parseFloat(output.impostadaversare) + parseFloat(output.importoDepositoCauzionale)).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      }
    } else if (registrationTaxData.tipoPagamento === "rata") {
      item["Prima rata da versare"] = `€ ${output.impostadaversare.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      item[`Ciascuna delle successive ${registrationTaxData.durata - 1} rate`] = `€ ${output.impostaScontata.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      item["Imposta complessiva"] = `€ ${(parseFloat(output.impostadaversare) + (parseFloat(output.impostaScontata) * (registrationTaxData.durata - 1))).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      item["Imposta + % sul Deposito"] = `€ {((parseFloat(output.impostadaversare) + (parseFloat(output.impostaScontata) * (registrationTaxData.durata - 1))) + parseFloat(output.importoDepositoCauzionale)).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }
  
    item["Codice tributo"] = codice;
  } else if (radioStatus === 1 || radioStatus === 3) {
    item = {
      "Importo": `€ ${importo}`,
      "Codice tributo": codice
    };
  } else if (radioStatus === 2) {
    item = {
      "Corrispettivo": `€ ${cessioneTaxData.canone}`,
      "Importo": `€ ${importo}`,
      "Codice tributo": codice
    };
  } else if (radioStatus === 4) {
    item = {
      "Tipo di pagamento previsto": propostaTaxData.pagamento,
    };
  
    if (propostaTaxData.pagamento === "caparra confirmatoria" || propostaTaxData.pagamento === "caparra e acconto") {
      item["Caparra"] = `€ ${propostaTaxData.caparra}`;
    }
  
    if (propostaTaxData.pagamento === "acconto sul prezzo di vendita" || propostaTaxData.pagamento === "caparra e acconto") {
      item["Acconto"] = `€ ${propostaTaxData.acconto}`;
    }
  
    item["Importo"] = `€ ${importo}`;
    item["Codice tributo"] = codice;
  }
  
  console.log(item, "item")
  
  return (
    <div className={`page-body py-5`}>
      {/* PRIMO STEP */}
      {isFirst && (
        <div>
          <div className="page-title">Tipo di registrazione</div>
          <div className="space-top1">
            <Form>
              <div className="radioLabel">
                <input
                  type="radio"
                  id="contactChoice1"
                  value="0"
                  name="radio"
                  className="btn-radio"
                  checked={radioStatus === 0}
                  onChange={() => radioChanged(0)}
                />
                <label htmlFor="contactChoice1" className="btn-radio">
                  &nbsp;Registrazione del contratto d’affitto, della proroga o
                  dell’annualità
                </label>
              </div>

              <div className="radioLabel">
                <input
                  type="radio"
                  id="contactChoice2"
                  value="1"
                  name="radio"
                  className="btn-radio"
                  checked={radioStatus === 1}
                  onChange={() => radioChanged(1)}
                />
                <label htmlFor="contactChoice2" className="btn-radio">
                  &nbsp;Registrazione della risoluzione
                </label>
              </div>

              <div className="radioLabel">
                <input
                  type="radio"
                  id="contactChoice3"
                  value="2"
                  name="radio"
                  className="btn-radio"
                  checked={radioStatus === 2}
                  onChange={() => radioChanged(2)}
                />
                <label htmlFor="contactChoice3" className="btn-radio">
                  &nbsp;Registrazione del subentro del conduttore
                </label>
              </div>

              <div className="radioLabel">
                <input
                  type="radio"
                  id="contactChoice4"
                  value="3"
                  name="radio"
                  className="btn-radio"
                  checked={radioStatus === 3}
                  onChange={() => radioChanged(3)}
                />
                <label htmlFor="contactChoice4" className="btn-radio">
                  &nbsp;Registrazione del contratto in comodato gratuito
                </label>
              </div>

              <div className="radioLabel">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="4"
                  name="radio"
                  className="btn-radio"
                  checked={radioStatus === 4}
                  onChange={() => radioChanged(4)}
                />
                <label htmlFor="contactChoice5" className="btn-radio">
                  &nbsp;Registrazione di una proposta d’acquisto o preliminare
                  di vendita
                </label>
              </div>

            </Form>
          </div>

          <Row className="mt-5 text-end">
            <div>
              <ButtonRm variant="primary" size="small"
                onClick={() => AvantiClicked()}>
                Avanti
            </ButtonRm>
            </div>
          </Row>

        </div>
      )}

      {/* SECONDO STEP - SOLO PER IMPOSTE DI REGISTRO AFFITTO */}
      {isRegistrazione && (
        <Form onSubmit={() => canoneClicked()}>
          <div className="page-title">Imposte di registro</div>
          <div className="space-top1">
          {/*   <div className="space-top1 d-flex flex-wrap">
              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="singolo anno"
                  name="tipo"
                  className="btn-radio"
                  checked={registrationTaxData.tipo === "singolo anno"}
                  onChange={handleRegistrationTaxData}
                />
                <label htmlFor="contactChoice5">
                  Pagamento imposta per singolo anno
                </label>
              </div>
              <div className="radioLabelRT">
                <input
                  type="radio"
                  id="contactChoice6"
                  value="pluriennale"
                  name="tipo"
                  className="btn-radio"
                  checked={registrationTaxData.tipo === "pluriennale"}
                  onChange={handleRegistrationTaxData}
                />
                <label htmlFor="contactChoice6">
                  Pagamento per tutta la durata del contratto
                </label>
              </div>
            </div> */}

            <Row className="my-3 d-flex-column d-md-flex ">
            <Col>
              <Form.Group>
                <Form.Label className="sub-title">
                Importo del canone  <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                </Form.Label>
                <Form.Control 
                  type="number" 
                  name="canone" 
                  value={registrationTaxData.canone? registrationTaxData.canone : ""}
                  onChange={handleRegistrationTaxData}
                  required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label className="sub-title">
                  Canone su base <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                </Form.Label>
                <Form.Select 
                  name="base" 
                  value={registrationTaxData.base}
                  onChange={handleRegistrationTaxData}
                  required            
                >
                  <option value="Mensile">Mensile</option>
                  <option value="Annuale">Annuale</option>
                  <option value="Per tutta la durata del contratto">Per tutta la durata del contratto</option>
                </Form.Select>
              </Form.Group>
            </Col>
            </Row>

            <Row className="my-3 d-flex-column d-md-flex">
              <Col>
                <Form.Group>
                  <Form.Label className="sub-title">
                    Tipo di immobile <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                  </Form.Label>
                  <Form.Select  
                    name="immobile" 
                    value={registrationTaxData.immobile}
                    onChange={handleRegistrationTaxData}
                    required              
                  >
                    <option value="Fabbricati a uso abitativo">Fabbricati a uso abitativo</option>
                    <option value="Fabbricati strumentali per natura">Fabbricati strumentali per natura</option>
                    <option value="Fondi rustici">Fondi rustici</option>
                    <option value="Altri immobili">Altri immobili</option>
                  </Form.Select>
                </Form.Group>
              </Col>
             
              <Col >
              <Form.Group>
                <Form.Label className="sub-title">
                Deposito cauzionale versato da terzi
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 1000, hide: 300 }}
                  overlay={
                    <Tooltip className="tooltipCalc">Sul deposito cauzionale versato dall’inquilino non è dovuta l’imposta di registro, quindi non è necessario fornire queste informazione ai fini del calcolo per le imposte di registro. Diversamente però, se il deposito è pagato da un terzo estraneo al rapporto di locazione, va versata l’imposta nella misura dello 0,50% e bisognerà inserire l'importo del deposito cauzionale in questo campo.</Tooltip>
                  }
                >
                  <i class="bi bi-info-circle ms-2"></i>
                </OverlayTrigger> 
                </Form.Label>
                <Form.Control type="number" name="deposito" 
                    value={registrationTaxData.deposito ? registrationTaxData.deposito : undefined}
                    onChange={handleRegistrationTaxData} />
              </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 d-flex-column d-md-flex ">
            <Col md={6}>
            
                <Form.Group>
                  <Form.Label className="sub-title">
                    Durata del contratto <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                  </Form.Label>
                  <div className="space-top1 d-flex flex-wrap mb-2">
              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="anno"
                  name="tipoDurata"
                  className="btn-radio"
                  checked={registrationTaxData.tipoDurata === "anno"}
                  onChange={handleRegistrationTaxData}
                />
                <label htmlFor="contactChoice5">
                  In anni
                </label>
              </div>
              <div className="radioLabelRT">
                <input
                  type="radio"
                  id="contactChoice6"
                  value="mese"
                  name="tipoDurata"
                  className="btn-radio"
                  checked={registrationTaxData.tipoDurata === "mese"}
                  onChange={handleRegistrationTaxData}
                />
                <label htmlFor="contactChoice6">
                  In mesi
                </label>
              </div>
            </div>
                  <Form.Control type="number" placeholder="inserisci un numero" name="durata" 
                    value={registrationTaxData.durata ? registrationTaxData.durata : ""}
                    onChange={handleRegistrationTaxData}
                    required   />
                </Form.Group>
              </Col>
           
            </Row>

            <Row className="mt-3 d-flex-column d-md-flex ">
            <Col md={6}>
            
                <Form.Group>
                  <Form.Label className="sub-title">
                    Pagamento Imposta
                  </Form.Label>
                  <div className="space-top1 d-flex flex-wrap mb-2">
              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="unico"
                  name="tipoPagamento"
                  className="btn-radio"
                  checked={registrationTaxData.tipoPagamento === "unico"}
                  onChange={handleRegistrationTaxData}
                />
                <label htmlFor="contactChoice5">
                  Unica Soluzione
                </label>
              </div>
              <div className="radioLabelRT">
                <input
                  type="radio"
                  id="contactChoice6"
                  value="rata"
                  name="tipoPagamento"
                  className="btn-radio"
                  checked={registrationTaxData.tipoPagamento === "rata"}
                  onChange={handleRegistrationTaxData}
                  disabled={(registrationTaxData.tipoDurata == "anno" && registrationTaxData.durata == 1) || (registrationTaxData.tipoDurata == "mese" && registrationTaxData.durata < 18) ? true : false}
                />
                <label htmlFor="contactChoice6">
                  Rateale {(registrationTaxData.tipoDurata == "anno" && registrationTaxData.durata == 1) || (registrationTaxData.tipoDurata == "mese" && registrationTaxData.durata < 18) && <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 1000, hide: 300 }}
                  overlay={
                    <Tooltip className="tooltipCalc">Per i contratti transitori, la cui durata non può superare i 18 mesi (vedi ad esempio i contratti stagionali), il versamento deve essere effettuato anticipatamente in unica soluzione utilizzando il codice tributo 107T.
                    Inserendo nella maschera una durata compresa tra 1 e 18 mesi l’applicazione assume che il contratto sia di tipo transitorio. </Tooltip>
                  }

                  
                >
                  <i class="bi bi-info-circle ms-2"></i>
                </OverlayTrigger> 
                  
                  }
                </label>
              </div>
            </div>
                  
                </Form.Group> 
              </Col>
           
            </Row>

            <Row className="d-flex-columns mb-4">
              <div className="radioLabel">
                <input
                  type="checkbox"
                  id="check"
                  name="iva"
                  checked={registrationTaxData.iva}
                  onChange={handleRegistrationTaxData}
                  disabled={registrationTaxData.immobile == "Fabbricati strumentali per natura"? false : true}
                />
                <label className="sub-title1" for="check">
                  {" "}
                  La locazione è effettuata da soggetti passivi Iva
                </label>
              </div>

              <div className="radioLabel">
                <input
                  type="checkbox"
                  id="check"
                  name="concordato"
                  checked={registrationTaxData.concordato}
                  onChange={handleRegistrationTaxData}
                  disabled={registrationTaxData.immobile == "Fabbricati a uso abitativo"? false : true}
                />
                <label className="sub-title1" for="check">
                  {" "}
                  Canone concordato
                </label>
              </div>

              <div className="radioLabel">
                <input
                  type="checkbox"
                  id="check"
                  name="tensione"
                  checked={registrationTaxData.tensione}
                  onChange={handleRegistrationTaxData}
                />
                <label className="sub-title1" for="check">
                  {" "}
                  Comune ad elevata tensione abitativa
                </label>
              </div>
            </Row>
          </div>

          <Row className="text-end">
            <div>
              <ButtonRm variant="tertiary" size="small"
                onClick={() => registrazionePrevious()} className="me-2">
                Indietro
              </ButtonRm>
              <ButtonRm type="submit" variant="primary" size="small">
                Avanti
            </ButtonRm>
            </div>
          </Row>
          </Form>
      )}

      {/* SECONDO STEP - SOLO PER IMPOSTE SUBENTRO O CESSIONE */}
      {isCessione && (
        <Form onSubmit={(e) => cessioneClicked()}>
          <div className="page-title">Imposte di registro</div>
          <div className="space-top1">
            <div className="space-top1 d-flex flex-wrap">
              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="con corrispettivo"
                  name="tipo"
                  className="btn-radio"
                  checked={cessioneTaxData.tipo === "con corrispettivo"}
                  onChange={handleCessioneTaxData}
                />
                <label htmlFor="contactChoice5">
                  Con corrispettivo
                </label>
              </div>
              <div className="radioLabelRT">
                <input
                  type="radio"
                  id="contactChoice6"
                  value="senza corrispettivo"
                  name="tipo"
                  className="btn-radio"
                  checked={cessioneTaxData.tipo === "senza corrispettivo"}
                  onChange={handleCessioneTaxData}
                />
                <label htmlFor="contactChoice6">
                  Senza corrispettivo
                </label>
              </div>
            </div>
            <Row className="my-3 d-flex-column d-md-flex ">
            {cessioneTaxData.tipo == "con corrispettivo" && <Col>
              <Form.Group>
                <Form.Label className="sub-title">
                Importo corrispettivo  <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                </Form.Label>
                <Form.Control 
                  type="number" 
                  name="canone" 
                  value={cessioneTaxData.canone? cessioneTaxData.canone : ""}
                  onChange={handleCessioneTaxData}
                  required />
              </Form.Group>
            </Col>}

            </Row>
          </div>

          <Row className="text-end">
            <div>
              <ButtonRm variant="tertiary" size="small"
                onClick={() => registrazionePrevious()} className="me-2">
                Indietro
              </ButtonRm>
              <ButtonRm type="submit" variant="primary" size="small">
                Avanti
            </ButtonRm>
            </div>
          </Row>
          </Form>
      )}

      {/* SECONDO STEP - SOLO PER IMPOSTE PROPOSTA */}
      {isProposta&& (
        <Form onSubmit={(e) => propostaClicked()}>
          <div className="page-title">Imposte di registro</div>
          <div className="space-top1">

            <div className="space-top1 d-flex flex-column my-2 mt-3">
              <p className="mb-1">Il preliminare prevede un pagamento?</p>
              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="nessuno"
                  name="pagamento"
                  className="btn-radio"
                  checked={propostaTaxData.pagamento === "nessuno"}
                  onChange={handlePropostaTaxData}
                />
                <label htmlFor="contactChoice5">
                  Nessuno
                </label>
              </div>

              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="caparra confirmatoria"
                  name="pagamento"
                  className="btn-radio"
                  checked={propostaTaxData.pagamento === "caparra confirmatoria"}
                  onChange={handlePropostaTaxData}
                />
                <label htmlFor="contactChoice5">
                  Caparra confirmatoria
                </label>
              </div>

              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="acconto sul prezzo di vendita"
                  name="pagamento"
                  className="btn-radio"
                  checked={propostaTaxData.pagamento === "acconto sul prezzo di vendita"}
                  onChange={handlePropostaTaxData}
                />
                <label htmlFor="contactChoice5">
                  Acconto sul prezzo di vendita
                </label>
              </div>

              <div className="radioLabelRT d-inline me-4">
                <input
                  type="radio"
                  id="contactChoice5"
                  value="caparra e acconto"
                  name="pagamento"
                  className="btn-radio"
                  checked={propostaTaxData.pagamento === "caparra e acconto"}
                  onChange={handlePropostaTaxData}
                />
                <label htmlFor="contactChoice5">
                  Caparra e Acconto
                </label>
              </div>

            </div>

            {(propostaTaxData.pagamento == "acconto sul prezzo di vendita" ||
              propostaTaxData.pagamento == "caparra e acconto") &&
            <Row className="my-3 d-flex-column d-md-flex ">
              <Col>
              <Form.Group>
                <Form.Label>
                Importo acconto  <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                </Form.Label>
                <Form.Control 
                  type="number" 
                  name="acconto" 
                  value={propostaTaxData.acconto? propostaTaxData.acconto : ""}
                  onChange={handlePropostaTaxData}
                  required={(propostaTaxData.pagamento == "acconto sul prezzo di vendita" ||
                  propostaTaxData.pagamento == "caparra e acconto")? true : false} />
              </Form.Group>
            </Col>
            </Row>
            }

            <Row className="my-3 d-flex-column d-md-flex ">
            {(propostaTaxData.pagamento == "caparra confirmatoria" ||
              propostaTaxData.pagamento == "caparra e acconto")
            && <Col>
              <Form.Group>
                <Form.Label>
                Importo caparra  <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                </Form.Label>
                <Form.Control 
                  type="number" 
                  name="caparra" 
                  value={propostaTaxData.caparra? propostaTaxData.caparra : ""}
                  onChange={handlePropostaTaxData}
                  required={(propostaTaxData.pagamento == "caparra confirmatoria" ||
                  propostaTaxData.pagamento == "caparra e acconto") ? true: false} />
              </Form.Group>
            </Col>
            
            }
            </Row>

            <Row className="my-3 d-flex-column d-md-flex ">
              <div className="radioLabel">
                <input
                  type="checkbox"
                  id="check"
                  name="iva"
                  checked={propostaTaxData.iva}
                  onChange={handlePropostaTaxData}
                />
                <label className="ms-2" for="check">
                  {" "}
                  Il trasferimento dell’immobile è soggetto a Iva
                </label>
              </div>
            </Row>

          </div>

          <Row className="text-end">
            <div>
              <ButtonRm variant="tertiary" size="small"
                onClick={() => registrazionePrevious()} className="me-2">
                Indietro
              </ButtonRm>
              <ButtonRm type="submit" variant="primary" size="small">
                Avanti
            </ButtonRm>
            </div>
          </Row>
          </Form>
      )}

     { /*STEP FINALE CON IMPORTI E CODICE */}
      {isShowDetail && (
        <div>
          <div className="page-title mb-3">Imposta di registro
            {radioStatus == 1 && <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 1000, hide: 300 }}
                    overlay={
                      <Tooltip className="tooltipCalc">L'imposta di registro dovuta per la risoluzione anticipata del contratto è pari alla misura fissa di 67 euro e deve essere versata, entro 30 giorni dall'evento: utilizzando i servizi telematici dell'Agenzia (software RLI o RLI-web) tramite richiesta di addebito su conto corrente. Attenzione: L’imposta di registro per la risoluzione del contratto non è dovuta se tutti i locatori hanno optato per il regime della cedolare secca. L’imposta è dovuta se almeno un locatore non ha optato per la cedolare secca </Tooltip>
                    }
                  >
                    <i class="bi bi-info-circle ms-2"></i>
            </OverlayTrigger> 
            }
            {radioStatus == 4 && <OverlayTrigger
              placement="bottom"
              delay={{ show: 1000, hide: 300 }}
              overlay={
                <Tooltip className="tooltipCalc">In entrambi i casi, l’imposta pagata con il preliminare sarà poi detratta da quella dovuta per la registrazione del contratto definitivo di compravendita. Nel caso in cui l’imposta proporzionale versata per la caparra confirmatoria e per gli acconti di prezzo risulti superiore all’imposta di registro dovuta per il contratto definitivo, spetta il rimborso della maggiore imposta versata per la registrazione del contratto preliminare. Il rimborso deve essere richiesto, a pena di decadenza, entro tre anni dalla data di registrazione del contratto definitivo. La domanda di rimborso deve essere presentata all'ufficio che ha eseguito la registrazione. </Tooltip>
              }
            >
              <i class="bi bi-info-circle ms-2"></i>
            </OverlayTrigger> }

            {radioStatus == 3 && <OverlayTrigger
              placement="bottom"
              delay={{ show: 1000, hide: 300 }}
              overlay={
                <Tooltip className="tooltipCalc">La registrazione del contratto di comodato può essere effettuata telematicamente avvalendosi dei servizi telematici dell’Agenzia delle Entrate. In alternativa, la registrazione del contratto di comodato può essere effettuata presentando presso qualsiasi ufficio territoriale dell’Agenzia delle Entrate il modello di richiesta di registrazione atti privati (modello RAP).
                Per i contratti di comodato è dovuta l’imposta di registro di 200 euro, inoltre per i comodati in forma scritta è dovuta l’imposta di bollo di 16 euro. L’importo dei contrassegni deve essere di 16 euro ogni 4 facciate scritte e, comunque, ogni 100 righe.</Tooltip>
              }
            >
              <i class="bi bi-info-circle ms-2"></i>
            </OverlayTrigger> }
          </div>
          <div className="">

          <div className="text-end ms-auto d-flex align-items-center justify-content-end">
          <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
{/*           <ShareChat styleBtn="top-btn-icon" />
 */}          
          </div>
            <Row className="">
            {radioStatus == 0 && 
            <>
              <table className="tb mt-4">
              <tr>
                <th width="60%" className="tb-head">
                  Descrizione
                </th>
                <th width="40%" className="tb-head">
                  Importo
                </th>
              </tr>
              <tr>
                <td className="tb-body">Durata del contratto	 </td>
                  <td className="tb-body fw-bold">{registrationTaxData.durata} {registrationTaxData.tipoDurata == "anno"? "anno/i" : "mese/i"} </td>
              </tr>
              <tr>
                <td className="tb-body ">Canone complessivo	</td>
                <td className="tb-body fw-bold">€ {output.canoneComplessivo.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
              <tr>
                <td className="tb-body">Canone annuale</td>
                <td className="tb-body fw-bold">€ {output.canoneAnnuale.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
              <tr>
                <td className="tb-body">Canone mensile	</td>
                <td className="tb-body fw-bold">€ {output.canoneMensile.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>
             
                <tr>
                  <td className="tb-body">Modalità di pagamento dell’imposta	</td>
                  <td className="tb-body fw-bold">{registrationTaxData.tipoPagamento == "rata" ? "Rateale" : "Unica Soluzione"} </td>
                </tr>
               {registrationTaxData.tipoPagamento == "unico" && <>
                <tr>
                  <td className="tb-body">Imposta calcolata </td>
                  <td className="tb-body fw-bold">€ {output.impostaCalc.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>
                </tr>
                  { 
                    registrationTaxData.base != 'Per tutta la durata del contratto' && <tr>
                    <td className="tb-body">Sconto applicato {output.sconto.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %	</td>
                    <td className="tb-body fw-bold">€ {(output.impostaCalc - output.impostaScontata).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  </tr>
                  }
                <tr>
                  <td className="tb-body">Imposta da versare </td>
                  <td className="tb-body fw-bold">€ {output.impostadaversare.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>

                {registrationTaxData.deposito && <tr>
                  <td className="tb-body">Imposta + % sul Deposito € {registrationTaxData.deposito}</td>
                  <td className="tb-body fw-bold">€ {(parseFloat(output.impostadaversare) + parseFloat(output.importoDepositoCauzionale
) ).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>}

                </>}
                {registrationTaxData.tipoPagamento == "rata" && <>
                <tr>
                  <td className="tb-body">Prima rata da versare	</td>
                  <td className="tb-body fw-bold">€ {output.impostadaversare.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </td>
                </tr>
                <tr>
                  <td className="tb-body">Ciascuna delle successive {registrationTaxData.durata - 1}  rate	<OverlayTrigger
                  placement="bottom"
                  delay={{ show: 1000, hide: 300 }}
                  overlay={
                    <Tooltip className="tooltipCalc">Si ricorda che nel caso di pagamento rateale, la tassa di registro dovrà essere ricalcolata ogni anno sulla base del canone rivalutato secondo gli indici Istat.</Tooltip>
                  }
                >
                  <i class="bi bi-info-circle ms-2"></i>
                </OverlayTrigger> </td>
                  <td className="tb-body fw-bold">€ {(output.impostaScontata).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
                <tr>
                  <td className="tb-body">Imposta complessiva </td>
                  <td className="tb-body fw-bold">€ {(parseFloat(output.impostadaversare) + (parseFloat(output.impostaScontata) * (registrationTaxData.durata - 1))).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
                <tr>
                  <td className="tb-body">Imposta +  % sul Deposito  €{registrationTaxData.deposito}  </td>
                  <td className="tb-body fw-bold">€ {((parseFloat(output.impostadaversare) + (parseFloat(output.impostaScontata) * (registrationTaxData.durata - 1))) + parseFloat(output.importoDepositoCauzionale)).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                </tr>
                </>}
                
                
              <tr>
                  <td className="tb-body">Codice tributo </td>
                  <td className="tb-body fw-bold">{codice}</td>
              </tr>
              </table>
              <small className="mt-2" onClick={() => selectMenu(0)}><u>Vai al calcolo del canone rivalutato</u></small>
            </>
            }

        {(radioStatus == 1  || radioStatus == 3 )  && 
            <>
              <table className="tb mt-4">
              <tr>
                <th width="60%" className="tb-head">
                  Descrizione
                </th>
                <th width="40%" className="tb-head">
                  Importo
                </th>
              </tr>
              <tr>
                <td className="tb-body">Importo	 </td>
                  <td className="tb-body fw-bold">€ {importo}</td>
              </tr>
                
              <tr>
                  <td className="tb-body">Codice tributo </td>
                  <td className="tb-body fw-bold">{codice}</td>
              </tr>
              </table>
              <small className="mt-2" onClick={() => selectMenu(2)}><u>Se non hai effettuato il pagamento entro 30gg, calcola il ravvedimento</u></small>
            </>
            }

        {radioStatus == 2 && 
          <>
            <table className="tb mt-4">
            <tr>
              <th width="60%" className="tb-head">
                Descrizione
              </th>
              <th width="40%" className="tb-head">
                Importo
              </th>
            </tr>
            {cessioneTaxData.tipo == 'con corrispettivo' &&
              <tr>
                  <td className="tb-body">Corrispettivo</td>
                  <td className="tb-body fw-bold">€  {cessioneTaxData.canone}</td>
              </tr>
            }
            <tr>
              <td className="tb-body">Importo	 </td>
                <td className="tb-body fw-bold">€ {importo}</td>
            </tr>
              
            <tr>
                <td className="tb-body">Codice tributo </td>
                <td className="tb-body fw-bold">{codice}</td>
            </tr>
            </table>
            <small className="mt-2" onClick={() => selectMenu(2)}><u>Se non hai effettuato il pagamento entro 30gg, calcola il ravvedimento</u></small>
          </>
        }

        {radioStatus == 4 && 
            <>
              <table className="tb mt-4">
              <tr>
                <th width="60%" className="tb-head">
                  Descrizione
                </th>
                <th width="40%" className="tb-head">
                  Importo
                </th>
              </tr>
              <tr>
                <td className="tb-body">Tipo di pagamento previsto</td>
                  <td className="tb-body fw-bold text-uppercase">{propostaTaxData.pagamento}</td>
              </tr>
             {(propostaTaxData.pagamento == "caparra confirmatoria" ||
              propostaTaxData.pagamento == "caparra e acconto") && <tr>
                <td className="tb-body">Caparra	 </td>
                  <td className="tb-body fw-bold">€ {propostaTaxData.caparra}</td>
              </tr>}

              {(propostaTaxData.pagamento == "acconto sul prezzo di vendita" ||
              propostaTaxData.pagamento == "caparra e acconto") && 
              <tr>
                <td className="tb-body">Acconto	 </td>
                <td className="tb-body fw-bold">€ {propostaTaxData.acconto}</td>
              </tr>
              }

              <tr>
                <td className="tb-body">Importo	 </td>
                  <td className="tb-body fw-bold">€ {importo}</td>
              </tr>
                
              <tr>
                  <td className="tb-body">Codice tributo </td>
                  <td className="tb-body fw-bold">{codice}</td>
              </tr>
              </table>
              <small className="mt-2" onClick={() => selectMenu(2)}><u>Se non hai effettuato il pagamento entro 30gg, calcola il ravvedimento</u></small>
            </>
            }

            
            </Row>
          </div>
          <div className="mt-5 text-end">
            <div>
            <ButtonRm variant="tertiary" size="small"
              onClick={() => registrazionePrevious()} className="me-2">
              Indietro
            </ButtonRm>
            <ButtonRm variant="secondary" size="small"
              onClick={() => resultClicked()}>
              Torna all'inizio
            </ButtonRm>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}
