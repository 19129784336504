import { useState, useEffect } from "react";
import edit from "../../../../assets/images/edit.svg";
import DeleteIcon from "../../../../assets/images/delete.svg";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import CustomBadge from "../../../CustomBadge";


import api, { conductor_endpoint, owner_endpoint } from "../../../../api/api";

const EditableOwnerCard = (props) => {
	const removeOwner = () => {
		props.remove(props.id);
	};

	console.log(props.data, "data")

	const [editableInput, setEditableInput] = useState(-1);
/*
	const [editableValues, setEditableValues] = useState({
		parts:"",
		procurator: "",
		date:""
	}) */

/* 	const handleChange = (e) => {
		const {value, name} = e.target
		setEditableValues(prev => {
			return {
				...prev,
				[name]:value
			}
		})

	}
	 */
	
	const handleProsecutor = () => {
		setModalShow(false);
	};

	useEffect(() => {
		if (props.edit_mode !== true) {
			setEditableInput(-1);
		}
	}, []);

	

	const [modalShow, setModalShow] = useState(false);


	//FETCH OWNERS E CONDUCTORS DATA FROM API

	const [realOwners, setRealOwners] = useState([]);
	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setRealOwners(response.data.content);
				console.log("owner", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [realConductors, setRealConductors] = useState([]);
	async function getConductors() {
		try {
			const response = await api().get(`${conductor_endpoint}`);
			if (response.data) {
				setRealConductors(response.data.content);
				console.log("conductor", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOwners();
		getConductors();
	}, []);

	const options = [...realOwners, ...realConductors]
	return (
		<>
			<div className="mt-4 col-xl-6">
				<div className="popup-pink d-block overflow-hidden rounded-3">
					<div className="pink-popup-header d-flex align-items-center justify-content-between p-2">
						<h3 className="fs-16 text-white fw-semibold">
							{props.data?.name + " " + props.data?.surname}
						</h3>
						{(props.edit_mode === undefined || props.edit_mode === true) && (
							<button
								type="button"
								className="border-0 bg-transparent text-white"
								onClick={removeOwner}>
								<i className="bi bi-x"></i>
							</button>
						)}
					</div>
					<div className="table-list d-block card-table editable-card-table">
						<table className="table table-borderless">
							<tbody>
							<tr className="mb-4">
									<td className="text-uppercase fs-14 text-secondary px-3">
										INDIRIZZO
									</td>
									<td className="text-capitalize fs-14 text-dark px-3">
										{props.data?.residenceAddress}
									</td>
								</tr>
								<tr className="mb-4">
									<td className="text-uppercase fs-14 text-secondary px-3">
										CIVICO
									</td>
									<td className="text-capitalize fs-14 text-dark px-3">
										{props.data?.residenceHouseNumber}
									</td>
								</tr>
								
								<tr className="mb-4">
									<td className="text-uppercase fs-14 text-secondary px-3">
										CITTA’
									</td>
									<td className="text-capitalize fs-14 text-dark px-3">
										{props.data?.residenceMunicipality}
									</td>
								</tr>
								{props.data?.cellularTelephone && <tr>
									<td className="text-uppercase fs-14 text-secondary px-3">
										TELEFONO
									</td>
									<td className="text-capitalize fs-14 text-dark px-3">
										{props.data?.cellularTelephone}
									</td>
								</tr>}
								{props.data?.fiscalCode && <tr>
									<td className="text-uppercase fs-14 text-secondary px-3">
										CF
									</td>
									<td className="text-capitalize fs-14 text-dark px-3">
										{props.data?.fiscalCode}
									</td>
								</tr>}
								<tr>
									<td className="text-uppercase fs-14 text-secondary px-3">
										PARTI DI PROPRIETA
									</td>
									<td className="d-flex fs-14 text-secondary px-3">
									<Form.Group className="mb-3 w-100">
										<Form.Control
											type="text"
											pattern="^[0-9]+\/[0-9]+$"
											value={props.data.parts}
											name="parts"
											className={`flex-auto w-100 editable-input w-100 border-0 ${props.data.parts &&
											!/^[\d]+\/[\d]+$/.test(props.data.parts) ? 'is-invalid' : ''
											}`}
											disabled={editableInput === 0 ? false : true}
											onChange={(e) => props.handleChange(e, props.id)}
										/>
										{props.data.parts && !/^[\d]+\/[\d]+$/.test(props.data.parts) && (
											<div className="invalid-feedback">
											Inserisci un valore nel formato 'numero/numero', es. 2/3
											</div>
										)}
										</Form.Group>
										<button
											type="button"
											className={`btn round-icon-btn small ${
												props.edit_mode == true ? "" : "d-none"
											}`}
											onClick={() => {
												setEditableInput(0);
											}}>
											<img loading="lazy" src={edit} alt="icon" />
										</button>
									</td>
								</tr>
								<tr>
									<td className="text-uppercase fs-14 text-secondary px-3">
										REFERENTE
									</td>
									<td className="d-flex fs-14 text-secondary px-3">
										<input
											type="text"
											value={props.data.procurator}
											name="procurator"
											className="flex-auto w-100 editable-input"
											disabled={editableInput === 1 ? false : true}
											onChange={(e) => props.handleChange(e, props.id)}
										/>
										{props.data.procurator == "" ? (
											<button
												type="button"
												className={`btn round-icon-btn small ${
													props.edit_mode == true ? "" : "d-none"
												}`}
												onClick={() => {
													setModalShow(true);
												}}>
												<img loading="lazy" src={edit} alt="icon" />
											</button>
										) : (
											<button
												type="button"
												className={`btn round-icon-btn small ${
													props.edit_mode == true ? "" : "d-none"
												}`}
												onClick={(e) => {
													props.handleChange({ ...e, target: { ...e.target, value: "" } });
												}}
												>
												<img loading="lazy" src={DeleteIcon} alt="icon" />
											</button>
										)}
									</td>
								</tr>
								<tr>
									<td className="text-uppercase fs-14 text-secondary px-3">
										DATA D'INIZIO POSSESSO
									</td>
									<td className="d-flex fs-14 text-secondary px-3">
										<input
											name="possessionDate"
											value={props.data.possessionDate}
											onChange={(e) => props.handleChange(e, props.id)}
											className="flex-auto w-100 editable-input input-custom-width"
											disabled={editableInput === 2 ? false : true}
											max="9999-12-31"
											type="date"></input>
										<button
											type="button"
											className={`btn round-icon-btn small ${
												props.edit_mode == true ? "" : "d-none"
											}`}
											onClick={() => {
												setEditableInput(2);
											}}>
											<img loading="lazy" src={edit} alt="icon" />
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<Modal show={modalShow} centered>
				<Modal.Body className="p-5">
					<div className="d-block w-100 mb-3">
						<div>
							<h2 className="fs-20 text-dark mb-2 fw-bold text-center">
								Seleziona Reference
							</h2>
						</div>
					</div>
					<div className="form-item-align-box d-block">
						<div className="mt-3">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Referente</label>
								<select
									type="text"
									value={props.data.procurator}
									name="procurator"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={(e) => props.handleChange(e, props.id)}
									>
									<option value=""></option>
									{
										options.length > 0 && options.map(el => {
											return <option key={el.id} value={`${el.surname} ${el.name}`}>{`${el.surname} ${el.name}`}</option>
										})
									}
								</select>
							</div>
						</div>
						<div className="form-last-btns mt-3 section-modal-btn">
							<button
								type="button"
								className="next-btn btn-last rounded-3 shadow me-2 border-0 col-md-12"
								disabled={props.data.procurator !== "" ? false : true}
								onClick={handleProsecutor}>
								Salva
							</button>
							<button
								type="button"
								className="black-btn btn-last rounded-3 shadow border-0 col-md-12 mt-3"
								onClick={() => {
									setModalShow(false);
								}}>
								Annulla
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EditableOwnerCard;
