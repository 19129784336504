import { combineReducers } from "redux";
import ModalForVerify from "./ModalForVerify";
import ModalForEnterCode from "./ModalForEnterCode";
import profile from "./profile";
// import affittiReducer from "./affittiReducer";
import gestioniReducer from "../actions/gestioni";
import addressReducer from "./addressReducer";
import themeReducer from "../actions/theme";
import deadlineReducer from "../actions/deadline";
import ownerReducer from "../actions/owner";
import condominiumReducer from "../actions/stabile";
import rememberReducer from "../actions/rememberData";
import userReducer from "../actions/accountAction"

export default combineReducers({
  ModalForVerify,
  ModalForEnterCode,
  profile,
  // affittiReducer,
  gestioniReducer,
  addressReducer,
  themeReducer,
  deadlineReducer,
  ownerReducer,
  condominiumReducer,
  rememberReducer,
  userReducer
});
