import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../../../assets/css/calculator.css";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";
import ButtonRm from "../../../ButtonRm";

export default function Section2() {
  const [inputNumber, setInputNmber] = useState(null);
  const [inputPercent, setPercent] = useState(null);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [proprieta, setproprieta] = useState(0);
  const [valore_usufrutto, set_valore_usufrutto] = useState(0);
  const [prima_casa, set_prima_casa] = useState(0);
  const [seconda_casa, set_seconda_casa] = useState(0);

  const interessiLegali = 0.025 //ANNO 2024 2.5%
  const [coefficiente, setCoefficiente] = useState(0) //coefficiente 
  const[percentualeUsufrutto, setPercentualeUsufrutto] = useState(0)
  const[percentualeNudaProprietà, setPercentualeNudaProprietà] = useState(0)

  const calClicked = () => {
    const value = parseInt(inputPercent); //anni del proprietario
    let number = parseInt(inputNumber) * interessiLegali; //valore dell'immobile * interessi legali
    let value1 = 0; //risultato dato da valore dell'immobile * coefficiente



    if (value >= 0 && value <= 20) {
      value1 = number * 0.38;
      setCoefficiente(0.38 * 100)
      setPercentualeUsufrutto(0.95)
      setPercentualeNudaProprietà(0.05)

    } else if (value >= 21 && value <= 30) {
      value1 = number * 0.36;
      setCoefficiente(0.36 * 100)
      setPercentualeUsufrutto(0.90)
      setPercentualeNudaProprietà(0.10)

    } else if (value >= 31 && value <= 40) {
      value1 = number * 0.34;
      setCoefficiente(0.34 * 100)
      setPercentualeUsufrutto(0.85)
      setPercentualeNudaProprietà(0.15)

    } else if (value >= 41 && value <= 45) {
      value1 = number * 0.32;
      setCoefficiente(0.32 * 100)
      setPercentualeUsufrutto(0.80)
      setPercentualeNudaProprietà(0.20)

    } else if (value >= 46 && value <= 50) {
      value1 = number * 0.30;
      setCoefficiente(0.30 * 100)
      setPercentualeUsufrutto(0.75)
      setPercentualeNudaProprietà(0.25)

    } else if (value >= 51 && value <= 53) {
      value1 = number * 0.28;
      setCoefficiente(0.28 * 100)
      setPercentualeUsufrutto(0.70)
      setPercentualeNudaProprietà(0.30)

    } else if (value >= 54 && value <= 56) {
      value1 = number * 0.26;
      setCoefficiente(0.26 * 100)
      setPercentualeUsufrutto(0.65)
      setPercentualeNudaProprietà(0.35)

    } else if (value >= 57 && value <= 60) {
      value1 = number * 0.24;
      setCoefficiente(0.24 * 100)
      setPercentualeUsufrutto(0.60)
      setPercentualeNudaProprietà(0.40)

    } else if (value >= 61 && value <= 63) {
      value1 = number * 0.22;
      setCoefficiente(0.22 * 100)
      setPercentualeUsufrutto(0.55)
      setPercentualeNudaProprietà(0.45)

    } else if (value >= 64 && value <= 66) {
      value1 = number * 0.20;
      setCoefficiente(0.20 * 100)
      setPercentualeUsufrutto(0.50)
      setPercentualeNudaProprietà(0.50)

    } else if (value >= 67 && value <= 69) {
      value1 = number * 0.18;
      setCoefficiente(0.18 * 100)
      setPercentualeUsufrutto(0.45)
      setPercentualeNudaProprietà(0.55)

    } else if (value >= 70 && value <= 72) {
      value1 = number * 0.16;
      setCoefficiente(0.16 * 100)
      setPercentualeUsufrutto(0.40)
      setPercentualeNudaProprietà(0.60)

    } else if (value >= 73 && value <= 75) {
      value1 = number * 0.14;
      setCoefficiente(0.14 * 100)
      setPercentualeUsufrutto(0.35)
      setPercentualeNudaProprietà(0.65)

    } else if (value >= 76 && value <= 78) {
      value1 = number * 0.12;
      setCoefficiente(0.12 * 100)
      setPercentualeUsufrutto(0.30)
      setPercentualeNudaProprietà(0.70)

    } else if (value >= 79 && value <= 82) {
      value1 = number * 0.10;
      setCoefficiente(0.10 * 100)
      setPercentualeUsufrutto(0.25)
      setPercentualeNudaProprietà(0.75)

    } else if (value >= 83 && value <= 86) {
      value1 = number * 0.08;
      setCoefficiente(0.08 * 100)
      setPercentualeUsufrutto(0.20)
      setPercentualeNudaProprietà(0.80)

    } else if (value >= 87 && value <= 92) {
      value1 = number * 0.06;
      setCoefficiente(0.06 * 100)
      setPercentualeUsufrutto(0.15)
      setPercentualeNudaProprietà(0.85)

    } else if (value >= 93 && value <= 99) {
      value1 = number * 0.04;
      setCoefficiente(0.04 * 100)
      setPercentualeUsufrutto(0.10)
      setPercentualeNudaProprietà(0.90)

    }


    setproprieta(inputNumber - ((parseInt(inputNumber) * interessiLegali) * coefficiente)); // valore dell'immobile - (valore dell'immobile * interssi legali * coeficiente)
    set_valore_usufrutto((parseInt(inputNumber) * interessiLegali) * coefficiente); // valore dell'immobile * interssi legali * coeficiente

    set_prima_casa(value1 * 0.03); // risultato dato da valore dell'immobile * coefficiente * 0.03
    set_seconda_casa(value1 * 0.07); // risultato dato da valore dell'immobile * coefficiente * 0.07
    setIsShowDetail(true);
  };

   //DATI PER ESPORTAZIONE
   const item = {
    ['Interessi legali vigenti']: `${interessiLegali * 100}%`,
    ['Valore piena proprietà']: `€ ${inputNumber}`,
    ['Età dell\'usufruttuario']: parseInt(inputPercent),
    ['Coefficiente moltiplicatore']: parseInt(coefficiente),
    ['Valore dell\'usufrutto']: `€ ${(inputNumber * percentualeUsufrutto).toFixed(2)}`,
    ['Valore della nuda proprietà']: `€ ${(inputNumber * percentualeNudaProprietà).toFixed(2)}`,
  }

  console.log({
    inputNumber, coefficiente
  })



  // USUFRUTTO TARDIVO
  const [valoreProprieta, setValoreProprieta] = useState(inputNumber);
  const [durataUsufrutto, setDurataUsufrutto] = useState(0);
  const [valoreNudaProprieta, setValoreNudaProprieta] = useState(0);

  const calcolaValoreNudaProprieta = () => {
    const valoreNuda = parseFloat(valoreProprieta) + (0.025 * durataUsufrutto);
    setValoreNudaProprieta(valoreNuda);
  };

  

  return (
    <div className={`page-body`}>
      <div className="space-top1">
        <div className="sub-title">Valore dell’immobile</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText">
                <input
                  type="number"
                  className="input-calc-symbol"
                  value={inputNumber}
                  onChange={(e) => setInputNmber(e.target.value)}
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="space-top1">
        <div className="sub-title">Anni compiuti dal usufruttuario</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText">
                <input
                  className="inputText1"
                  type="number"
                  value={inputPercent}
                  onChange={(e) => setPercent(e.target.value)}
                ></input>
              </div>
            </Col>
          </Row>
        </div>
      </div>

  {/*     <div className="space-top1">
        <div className="sub-title">Durata dell'usufrutto</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText">
                <input
                  className="inputText1"
                  type="number"
                  value={durataUsufrutto}
                  onChange={(e) => setDurataUsufrutto(e.target.value)}
                ></input>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}

      <div className="space-top1 ">
        <div className="space-top1">
          <ButtonRm onClick={() =>{ calClicked()
          calcolaValoreNudaProprieta()
          }} variant='secondary' disabled={(inputNumber && inputPercent) ? false : true}>
            Calcola
          </ButtonRm>
        </div>
      </div>

      {isShowDetail && (
        <div>
<div className="space-top2">
          <div className="text-end d-flex justify-content-end ">
{/*             <ShareChat styleBtn="top-btn-icon ms-2" />
 */}
              <ExportButton data={item} mapping={{}} classes="top-btn-icon ms-2"/>
            </div>
            </div>
        <table className="tb mt-4">
          <tr>
            <th width="60%" className="tb-head">
              Descrizione
            </th>
            <th width="40%" className="tb-head">
              Importo
            </th>
          </tr>
            <tr>
                <td className="tb-body">Interessi legali vigenti</td>
                  <td className="tb-body fw-bold">{interessiLegali * 100}%</td>
              </tr>
            <tr>
                <td className="tb-body">Valore piena proprietà</td>
                  <td className="tb-body fw-bold"> € {inputNumber}</td>
              </tr>
              <tr>
                <td className="tb-body">Età dell'usufruttuario	</td>
                  <td className="tb-body fw-bold"> {inputPercent}</td>
              </tr>
              <tr>
                <td className="tb-body">Coefficiente moltiplicatore</td>
                  <td className="tb-body fw-bold"> {coefficiente}</td>
              </tr>
              <tr>
                <td className="tb-body">Valore dell'usufrutto	</td>
                  <td className="tb-body fw-bold"> € {(inputNumber * percentualeUsufrutto).toFixed(2)}</td>
              </tr>
              <tr>
                <td className="tb-body">Valore della nuda proprietà	</td>
                  <td className="tb-body fw-bold"> € {(inputNumber * percentualeNudaProprietà).toFixed(2)}</td>
              </tr>
        </table>

          {/* <pre>
            <h3>USUFRUTTUARIO VITALIZIO</h3>
            <p>valore piena proprietà: {inputNumber}</p>
            <p>età usufruttuario: {inputPercent}</p>
            <p>tasso d'interesse {interessiLegali * 100}</p>
            <p>COEFFICIENTE {coefficiente}</p>
            <p>valore usufrutto  {valore_usufrutto}</p>
            <p>nuda proprietà  {proprieta}</p>

            <h3>USUFRUTTUARIO VITALIZIO</h3>
            <p>valore piena proprietà: {inputNumber}</p>

            <p>% nuda proprietà  { percentualeNudaProprietà}</p>
            <p> % valore usufrutto  {percentualeUsufrutto }</p>


            <p>valore usufrutto  {inputNumber * percentualeUsufrutto }</p>
            <p>nuda proprietà  {inputNumber * percentualeNudaProprietà}</p>

            <h3>USUFRUTTUARIO TARDIVO</h3>
            <p>valore piena proprietà: {inputNumber}</p>
            <p>durata: {durataUsufrutto}</p>
            <p>nuda proprietà  {valoreNudaProprieta}</p>
           


          </pre> */}
          <div className="space-top2">
         
            <div className="sub-title">
            {/* <div style={{ marginTop: "1%" }}>
              <Row>
                <Col>
                  <div className="divInputText">
                    <input
                      className="inputText1"
                      value={parseFloat(proprieta).toFixed(2)}
                      readOnly
                    ></input>
                    <span className="spanInput1">€</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="space-top1">
            <div className="sub-title">Valore dell’usufrutto</div>
            <div style={{ marginTop: "1%" }}>
              <Row>
                <Col>
                  <div className="divInputText">
                    <input
                      className="inputText1"
                      value={parseFloat(valore_usufrutto).toFixed(2)}
                      readOnly
                    ></input>
                    <span className="spanInput1">€</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>


          <div className="space-top2">
            <div className="sub-title"> Imposta di registro dell'usufrutto - Prima casa</div>
            <div style={{ marginTop: "1%" }}>
              <Row>
                <Col>
                  <div className="divInputText">
                    <input
                      className="inputText1"
                      value={parseFloat(prima_casa).toFixed(2)}
                      readOnly
                    ></input>
                    <span className="spanInput1">€</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="space-top1">
            <div className="sub-title">Imposta di registro dell'usufrutto - Seconda casa </div>
            <div style={{ marginTop: "1%" }}>
              <Row>
                <Col>
                  <div className="divInputText">
                    <input
                      className="inputText1"
                      value={parseFloat(seconda_casa).toFixed(2)}
                      readOnly
                    ></input>
                    <span className="spanInput1">€</span>
                  </div>
                </Col>
              </Row>
            </div>
          </div> */}

          <div className="space-top2"></div>
          </div>
          </div>
        </div>
      )}
    </div>
  );
}
