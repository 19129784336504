import React from "react";

const EditBackCheckModal = (props) => {
	const handleCancel = () => {
		props.setShow(false);
		props.init();
		props.setEditMode(false);
	};
	return (
		<div className="d-flex flex-column text-center align-items-center">
			<h2>Vuoi annullare le modifiche?</h2>
			<h6 className="fs-16 text-dark fw-normal mb-3">
				Se non salvi le modifiche, i cambiamenti effettuati andranno persi
			</h6>
			<div className="edit-btn w-210" onClick={handleCancel}>
				Conferma annullamento
			</div>
			<div
				type="button"
				className="back-btn rounded-3 fs-16 py-2 w-210 border-0 text-dark bg-white"
				onClick={(e) => {
					props.setShow(false);
				}}>
				Indietro
			</div>
		</div>
	);
};

export default EditBackCheckModal;
