import Form from "react-bootstrap/Form";
import UnitCard from "../UnitCard";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UnitModal from "../UnitModal";
import { Link } from "react-router-dom";
import ButtonRm from "../../ButtonRm";

import EmptyEstate from "../../../assets/images/empty_estate.svg";
import img from "../../../";
import translationMapping from "../../Membri/mapping";
import ExportButton from "../../Membri/Fornitori/ExportButton";
import { BsPrinter } from "react-icons/bs";
import CustomBadge from "../../CustomBadge";



const UnitE = (props) => {
	const [data, setData] = useState({});

	//Aggiunge a "data" la lista di un unità 
	const init = () => {
		setData({ units: props.formData.units });
	};

	//Chiama init ogni volta che props subisce modifiche
	useEffect(() => {
		init();
	}, [props]);

	//salva le modifiche effettuate oppure annulla modifiche
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	//aggiunge unità a data
	const saveUnit = (id) => {
		let temp = [...data.units];
		temp.push(props.units[id]);
		setData({ ...data, units: temp });
	};

	//rimuovw unità da data
	const deleteUnit = (id) => {
		let temp = [...props.formData.units];
		temp.splice(id, 1);
		setData({ ...data, units: temp });
	};

	const [show_unit, setShowUnit] = useState(false);
	const setToggleFullMode = () => {
		props.setFullMode(!props.full_mode);
	};
	const [other_card, setOtherCard] = useState([1, 2]);



	//GESTISCE SLIDER, MA NON C'è PIù
	const next = () => {
		const slidesContainer = document.getElementById("slides-container");
		const slide = document.querySelector(".slide");
		const slideWidth = slide.clientWidth;
		slidesContainer.scrollLeft += slideWidth;
	};

	const prev = () => {
		const slidesContainer = document.getElementById("slides-container");
		const slide = document.querySelector(".slide");
		const slideWidth = slide.clientWidth;
		slidesContainer.scrollLeft -= slideWidth;
	};

	const item = (props.formData.units?.length > 0) ? [...props.formData.units] : []

	return (
		<>	
		<Form>
				<div className="edit-content-container mt-3">
					<div className="form-item-align-box-white d-block">
						{/* {props.edit_mode} */}
						<div className="">
							<div className="">
							<h1 className="f-label fs-16 d-flex align-items-center
text-uppercase mb-3">unità<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</h1>
							<div className="comment-container">
							{props.edit_mode && (
									<>
										<label className="affitti-comment mb-3">
											Crea o seleziona unità immobiliare
										</label>
										<div className="d-flex justify-content-start mt-3">
											<Link to="/real-estate-units/create">
												<ButtonRm variant="tertiary" className="me-2">Nuovo</ButtonRm>
											</Link>
											<ButtonRm variant="tertiary" onClick={(e) => {
													setShowUnit(true);
												}} >Seleziona</ButtonRm>
										</div>
									</>
								)}
							{data.units?.length > 0 &&
									<div className={`d-flex ${
										props.edit_mode === false ? "" : "d-none"
										}`}>
										
										<ExportButton data={item} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>
									</div>
									}	
							</div>
								
								<div className={ " mb-5"}>
															
									{data.units?.length > 0 && (
										<UnitCard
											classNam="mt-4"
											data={data.units[0]}
											id={0}
											deleteUnit={deleteUnit}
											edit_mode={props.edit_mode}
										/>
									)}
								</div>
							</div>
							<div className="">
							<hr className="mt-5"/>

						<div className="mt-5 mb-3 d-block w-100">
							<span className="f-label fs-16 d-flex align-items-center
text-uppercase">PERTINENZE O ALTRE UNITA’ IMMOBILIARI</span>
						</div>
								{props.edit_mode && (
									<>
										<div className="d-flex justify-content-start mt-3">
											<Link to="/real-estate-units/create">
												<ButtonRm variant="tertiary" className="me-2">Nuovo</ButtonRm>
											</Link>
											<ButtonRm variant="tertiary" onClick={(e) => {
													setShowUnit(true);
												}} >Seleziona</ButtonRm>
										</div>
									</>
								)}
								<div >
									{data.units?.length > 1 ? (
										!props.full_mode ? (
											<div className="slider-wrapper">
												<ul className="slides-container" id="slides-container">
													{data.units.map((item, index) => {
														if (index != 0)
															return (
																<li className="slide" key={index}>
																	<UnitCard
																		id={index}
																		data={item}
																		deleteUnit={deleteUnit}
																		edit_mode={props.edit_mode}
																	/>
																</li>
															);
													})}
												</ul>
												<div className="prev" onClick={prev}></div>
												<div className="next" onClick={next}></div>
											</div>
										) : (
											data.units.map((item, index) => {
												if (index != 0)
													return (
														<UnitCard
															id={index}
															classNam="mt-4"
															data={item}
															deleteUnit={deleteUnit}
															edit_mode={props.edit_mode}
														/>
													);
											})
										)
									) : (
										<div className="unit-empty-img ">
								<img loading="lazy" src={EmptyEstate} alt="" />
								<span className="img-comment">
									Nessuna unità immobiliare selezionata
								</span>
							</div>
									)}
								</div>
							</div>
						</div>
						
					</div>
				</div>
		</Form>

			<Modal
				show={show_unit}
				className="type-modal"
				onHide={() => {
					setShowUnit(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShowUnit(false);
						}}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<UnitModal
						table_data={props.units}
						setShow={setShowUnit}
						saveUnit={saveUnit}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default UnitE;
