import React , { useState, useEffect} from "react";
import logo from "../../../assets/images/logo-red.svg";
import SuccessLogo from "../../../assets/images/success.png";

import "../../../assets/css/RegisterSuccess.css";
import Footer from "../../Footer";

import { useNavigate } from "react-router-dom";
import ButtonRm from "../../ButtonRm";

import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import api, {BASE_URL} from "../../../api/api";

import Skeleton from '@mui/material/Skeleton';


const translationMapping = {
    "The invitation code is valid and unused": "Il codice di invito è valido e non è stato utilizzato",
    "The invitation code already used": "Il codice di invito è già stato utilizzato",
    "The invitation code is not valid": "Il codice di invito utilizzato non è valido",
    "Due to technical problem we cannot send the invitation email to the invitee": "A causa di un problema tecnico non è stato possibile completare il collegamento. Riprova più tardi o contatta il supporto.",
    "The invitation code has expired. Please contact your manager" : "Il codice di invito è scaduto. Contatta il tuo gestore per ricevere un nuovo codice di invito"
}

export default function InviteLanding() {

  const navigate  = useNavigate()

  const [formData, setFormData] = useState({
    connectionCode:""
  });

  const [isLoading, setIsLoading] = useState(true)
  const [success, setSuccess] = useState(null)
  const [message, setMessage] = useState(null)

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const code = params.get('connectionCode');

  useEffect(() => {

    let newform = {...formData}
    if(code){
      newform = {...newform, ['connectionCode']: code}
    }

    if(code){
      // Rimuove il parametro 'connectionCode' dall'URL
      params.delete('connectionCode');
    }
    setFormData({ ...newform });

    window.history.replaceState({}, document.title, url.pathname /* + '?' + params */);

  }, []);


  const confirm = () => {
    if(code || formData.connectionCode){
      setIsLoading(true)
      axios({
        url: `${BASE_URL}api/v1/invitation/connectionAutomatically/`,
        method: "POST",
        data: formData,
      })
      .then((res) => {
          if (res.data.success === true) {
            setSuccess(true)
            setIsLoading(false)
            setMessage(res.data.message)
            console.log("success true", res)
          } else {
            setSuccess(false)
            setIsLoading(false)
            setMessage(res.data.message)
            console.log("success false", res)
          }
      })
      .catch((err) => {
        setIsLoading(false)
        setSuccess(false)
        setMessage(err.data.message)
        console.log("success false", err)

      })
    } else {
      setIsLoading(false)
      setSuccess(false)
      setMessage("The invitation code is not valid")
      console.log("conecctionCode non è definito")

    }
  };

  const login  = () => {
    navigate("/");
  };

  useEffect(() => {
    confirm()
  }, [formData])

  console.log({
    success, message
  })

  return (
    <>
      <ToastContainer />

      {/* 
      <div className="register-div">
        <img loading="lazy" src={logo} alt="logo" className="register-logo"></img>
        <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            <img
              src="https://cdn0.iconfinder.com/data/icons/business-startup-10/50/65-512.png"
              width="100px"
              style={{ display: "flex", margin: "auto" }}
            ></img>
            <h3 className="mt-4">
              Conferma il collegamento
            </h3>
            <p className="opacity-75 mb-4" style={{ maxWidth: "600px" }}>
              Per accettare l'invito e confermare il collegamento, clicca sul pulsante qui sotto.
            </p>

            <ButtonRm variant="primary" onClick={acceptInvitation}>Accetta l'invito</ButtonRm>
          </div>
        </div>
      </div> */}

      <div className="register-div">
        <img loading="lazy" src={logo} alt="logo" className="register-logo"></img>
        <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            { isLoading &&
              <Skeleton variant="circular" width={100} height={100} style={{ display: "flex", margin: "auto" }}/>

            }
            {(!isLoading && success != null) &&  success === true &&
              <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
              width="100px"
              style={{ display: "flex", margin: "auto" }}
              ></img>
            }
            { (!isLoading && success != null) && success === false &&
              <img
              src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
              width="100px"
              style={{ display: "flex", margin: "auto" }}
              ></img>
            }
            { (!isLoading && success != null) &&
              success === true && <h3 className="my-4">
              Ti sei collegato con successo!
              </h3> 
            }

            { (!isLoading && success != null) &&
              success === false && 
              <>
                <h3 className="mt-4">Collegamento non riuscito</h3>
                <p className="opacity-75 mb-4" style={{ maxWidth: "600px" }}>
                {message && translationMapping[message]}
                </p>
              </>
            }

            { isLoading &&
              <Skeleton className="mt-4" variant="text"/>
            }
            
            { isLoading ?
              <Skeleton variant="rectangular" width={150} height={40} /> :
              <ButtonRm variant="primary" onClick={login}>Vai alla home</ButtonRm>
            }
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
