import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoSal () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Stato Avanzamento Lavoro"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Funzione" />
            <TreeItem nodeId="2" label="Struttura" /> 
            <TreeItem nodeId="3" label="Gestioni" > 
              <TreeItem nodeId="4" label="Creazione di una gestione" /> 
              <TreeItem nodeId="5" label="Card gestione" /> 
              <TreeItem nodeId="6" label="Apertura di una gestione" />
                <TreeItem nodeId="7" label="Aggiunta di un immobile" /> 
                <TreeItem nodeId="8" label="Visione lista e Mappa" /> 
            </TreeItem>
            <TreeItem nodeId="9" label="Unità e Stabili" >
              <TreeItem nodeId="10" label="Importazione Immobili" /> 
              <TreeItem nodeId="11" label="Visione Lista e Mappa" /> 
              <TreeItem nodeId="12" label="Assegnazione Gestione" /> 
              <TreeItem nodeId="13" label="Apertura di un'unità" /> 
              <TreeItem nodeId="14" label="Visione attività" /> 
            </ TreeItem> 
            <TreeItem nodeId="15" label="Attività" >
            <TreeItem nodeId="16" label="Creazione di un'attività" /> 
              <TreeItem nodeId="17" label="Planimetrie" /> 
              <TreeItem nodeId="18" label="Visione attività" /> 
              <TreeItem nodeId="19" label="Contabilità di un attività" /> 
              <TreeItem nodeId="20" label="Apertura di un'attività" /> 
            </ TreeItem> 


        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Stato Avanzamento Lavoro (SAL)</h1>
      <h2 style={{ color:'transparent', height: 0}}>SAL</h2>

      <h2 id="1">Funzione</h2>
      <p >La sezione SAL permette la gestione delle attività relative ai tuoi immobili a 360°.
        Qui potrai creare specifiche relative agli interventi di manutenzione ordinaria o straordinaria, comunicare tramite chat con tutti gli attori (proprietari, inquilini e fornitori che hai invitato all'interno della piattaforma), inviare e/o ricevere pagamenti (per esempio riscuotere un affitto o pagare i tuoi fornitori)
      </p>
      <Tips variant="info" content="E' necessario creare un account di pagamento per poter ricevere pagamenti su Real Meta. Puoi farlo in Impostazioni > Profilo" />
      <h2 id="2">Struttura</h2>
      <p>Per accedere a SAL puoi cliccare sul bottone in basso a sinistra "Passa a SAl" </p>
      <img loading="lazy" src="GIF DA PROPERTY A SAL"  />

      <p>In SAL troverai una bacheca di riepilogo contenente</p>
      <ul>
        <li><p >Le tue attività recenti</p></li>
        <li><p >Panoramica sullo stato delle tue attività</p></li>
        <li><p >Le tue gestioni recenti</p></li>
        <li><p >Panoramica delle unità in SAL 
        </p></li>
        <li><p >Attori coinvolti
        </p></li>
        <li><p >Azioni rapide relative alla chat
        </p></li>

      </ul>
      <img loading="lazy" src="GIF overview bachececa"  />
      <p>Per tornare a REAL META PROPERTY puoi cliccare sul bottone in basso a sinistra "Passa a Property"</p>
      <p>SAL si struttura in: </p>
      <ul>
        <li><p >Gestioni</p></li>
        <li><p >Unità e Stabili</p></li>
        <li><p >Attività</p></li>
      </ul>

      <h2 id="3">Gestioni</h2>
      <p>
        All'interno della gestione puoi raggruppare più unità che hai in gestione, seguendo i tuoi criteri personali.
      </p>
      <Tips variant="suggestion" content="Puoi ad esempio creare la gestione 'MARIO ROSSI' e inserire all'interno della gestione tutti gli immobili relativi a questo proprietario" />
      <h3 id="4">Creazione di una gestione</h3>
      <p>Per creare una gestione clicca sul pulsante rosso in alto a destra, e definisci il nome che vuoi dare alla tua Gestione</p>
      <img loading="lazy" src="screen modale"  />

      <h3 id="5">Card Gestione</h3>
      <p>Quando crei una gestione, verrà aggiunto un elemento "card" alla lista delle tue Gestioni</p>
      <img loading="lazy" src="screen card"  />
      <p>Nella card potrai vedere quando è stato creata quella gestione e definire un incaricato</p>
      <Tips variant="info" content="L'incaricato può essere un utente che hai invitato sulla piattaforma e si è iscritto su Real Meta col ruolo di collaboratore" />
      <p>Cliccando sulla casella di spunta a sinistra, potrai modificare il nome della gestione, esportare i dati della gestione o eliminare la gestione in questione</p>
      <img loading="lazy" src="screen modifica"  />
      <p className="subtitle">Eliminazione multipla</p>
      <p>Selezionando più card, e cliccando sul tasto elimina hai la possibilità di eliminare più gestioni in una sola volta</p>

      <h3 id="6">Apertura di una gestione</h3>
      <p>Cliccando su una card, entrerai all'interno della gestione</p>
      <p id="7" className="subtitle">Aggiunta di un immobile</p>

      <p>All'interno potrai aggiungere le unità relative a questa gestione cliccando sul pulsante rosso in alto a destra</p>
      <p>Qui potrai selezionare le unità che desideri aggiungere alla gestione.</p>
      <Tips variant="info" content="L'elenco sarà composto dalle unità e stabili che hai aggiunto in Real Meta Property." />

      <p className="subtitle" id="8">Visione lista e Mappa</p>
      <p>Potrai visualizzare gli immobili che hai aggiunto come card, o su mappa. Con una mappa interattiva che ti fornisce una panoramica degli immobili della gestione e la loro localizzazione</p>

      <img loading="lazy" src="gif apertura gestione"  />


      <p>Dalla "Lista" selezionando la casella di spunta a sinistra, potrai rimuovere l'unità dalla gestione in questione, esportarnee i dati relativi o cambiare gestione (dovrai selezionare la nuova gestione di destinazione)</p>

      <img loading="lazy" src="MODALE opzioni"  />

      <h2 id="9">Unità e Stabili</h2>
      <h3 id="10">Importazione Immobili</h3>
      <p>
        Per poter gestire degli immobili nella sezione SAL, devi importarli da Real Meta Property.
      </p>
      <p>Puoi farlo inserendoli all'interno di una gestione (come abbiamo visto nello step precedente) oppure dalla sezione "Unità e Stabili" di SAL cliccando sul pulsante rosso in alto a destra, tramite il quale potrai selezionarli e importarli</p>
      <Tips variant="info" content="Quando crei un attività puoi associarla agli immobili importati in SAL, che trovi in questa sezione" />

      <p className="subtitle" id="11">Visione lista e Mappa</p>
      <p>Potrai visualizzare gli immobili che hai aggiunto come card, o su mappa. Con una mappa interattiva che ti fornisce una panoramica di tutti gli immobili e la loro localizzazione</p>

      <Tips variant="info" content="Puoi distinguere le unità dagli stabili importati tramite un badge colorato che trovi a sinistra del nome" />

      <img loading="lazy" src="screen card diverse"  />


      <p className="subtitle" id="12">Assegnazione Gestione</p>
      <p>Dalla "Lista" selezionando la casella di spunta a sinistra, potrai rimuovere l'unità da SAL, esportarne i dati relativi o cambiare gestione (potrai cambiare o assegnare una gestione all'unità selezionata)</p>

      <Tips variant="info" content="Quando rimuovi un immobile da questa sezione, viene rimossa solo da SAL. L'unità originale che hai creato in Real Meta Property non viene eliminata." />

      <Tips variant="info" content="Se l'immobile in SAL è associato almeno ad un'attività NON potrà essere rimossa da SAL. Per procedere all'eliminazione dovrai prima eliminare tutte le attività ad esso associate." />

      <h3 id="13">Apertura di un'unità</h3>
      <p>Cliccando su una card, accederai alle informazioni relative alle attività ad esso associate</p>
      <p>Potrai aggiungere le attività relative a questa unità cliccando sul pulsante rosso in alto a destra</p>
      <Tips variant="automatization" content="Se inserisci un'attività da questa sezione, non dovrai specificare l'immobile di appartenza poiché questa informazione verrà automatizzata" />

      <img loading="lazy" src="modale creazione attività"  />

      <p id="14" className="subtitle">Visione attività</p>
      <p>Avrai la possibilità di visualizzare le attività relative a questa unità in 3 modalità:</p>
      <ul>
        <li><strong >Lista / Tabellare</strong>, con una panoramica dei dati principali</li>
        <li><strong>Visione su planimetria</strong>, con una panoramica sui punti di intervento relativi all'attività</li>
        <li><strong >Visione su Gantt</strong>, con una panoramica delle tempistiche relative all'attività in questione</li>
      </ul>

      <img loading="lazy" src="gid con le 3/4 modalità di vista"  />


      <h2 id="15">Attività</h2>
      <p>Questa sezione ti permette di gestire gli interventi relativi ai tuoi immobili</p>
      <h3 id="16">Creazione di un'attività</h3>
      <p>
       Cliccando sul pulsante rosso in alto a destra, aprirai la profilazione per la creazione di un attività</p>
       <p>Qui dovrai andare a definire:</p>
       <ul>
        <li><strong >Nome dell'attività</strong></li>
        <li><strong>Immobile di riferimento</strong>, è un dato obbligatorio per poter procedere con la creazione di un'attività</li>
        <li><strong >Date di inizio e fine intervento</strong>, sono dati obbligatori, ed è necessario che la data di inizio sia precedente alla data di fine </li>
        <li><strong >Priorità</strong></li>
        <li><strong >Tipologia</strong></li>
        <li><strong >Descrizione</strong></li>
        <li><strong >Partecipanti</strong>, questa lista viene presa dai soggetti presenti nella tua sezione Utenti in Real Meta Property</li>
        <li><strong >Incaricato</strong></li>
        <li><strong >Allegati</strong></li>
        <li><strong >Planimetrie</strong></li>
      </ul>
       <img loading="lazy" src="screen profilazione"  />

       <p id="17" className="subtitle">Planimetrie</p>
       <p>Le planimetrie di permettono di definire i punti di intervento relativi alla tua attività</p>

   <Tips variant="automatization" content="Le planimetrie possono essere aggiunte in Real Meta Property > Unità > Facoltativi > Foto e Planimetrie > Planimetrie. Le immagini e foto che inserisci li, saranno visibili durante la creazione di un attività. Se non hai inserito nessuna planimetria, questo campo non sarà visibile" />

   <img loading="lazy" src="dimostrazione planimetria e aggiunta punto intervento"  />

   <Tips variant="automatization" content="Quando crei un attività, viene creato in automatico una chat relativa all'attività in questione, con i partecipanti che hai definito nell'attività" />



   <h3 id="18">Visione attività</h3>
      <p>Avrai la possibilità di visualizzare le attività relative a questa unità in 3 modalità:</p>
      <ul>
        <li><strong >Lista / Tabellare</strong>, con una panoramica dei dati principali</li>
        <li><strong>Visione su planimetria</strong>, con una panoramica sui punti di intervento relativi all'attività</li>
        <li><strong >Visione su Gantt</strong>, con una panoramica delle tempistiche relative all'attività in questione</li>
      </ul>

      <p className="subtitle">Visione Tabellare / Lista</p>
      <p>A destra prima del bottone filtro, trovi il bottone “CAMBIO VISUALIZZAZIONE”, che ti permette di passare dalla visione lista a quella tabellare e viceversa.</p>
      <img loading="lazy" src="https://i.postimg.cc/QtKWh3ZZ/cambia-visualizzazione.png"  />
      <img loading="lazy" src="screen card/tabella"  />


      <p>Cliccando sulla casella di spunta a sinistra, potrai rimuovere l'attività selezionata, esportarne i dati o visualizzare informazioni relative alla contabilità</p>

      <p className="mt-3" id="19"><strong>Contabilità di un attività</strong></p>
      <p>La contabilità ti permette di visualizzare i costi relativi all'attività in questione</p>
      <p>I costi vengono eleborati attraverso la chat ad esso correlata, in particolare tramite l'aggiunta di Preventivi e Spese.</p>

      <Tips variant="automatization" content="Le spese confluiscono in automatico nella contabilità, mentre i preventivi confluiscono in contabilità solo dopo che li avrai accettati." />


      <img loading="lazy" src="screen modale contabilità"  />

      <h3 id="20">Apertura di un'attività</h3>
      <p>Cliccando su un elemento della lista potrai visualizzare il riepilogo delle informazioni inserite</p>

      <img loading="lazy" src="screen attività dentro"  />

      <p className="subtitle">Modifica ed Elimina</p>
      <p>In alto a destra trovi i pulsanti per la modifica, eliminazione ed espotazione di un'attività </p>
      <img loading="lazy" src="foto pulsanti modifica/elimina"  />






    </div>
  );
}

