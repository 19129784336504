import React, { useState, useEffect } from "react";
import "../../../assets/css/Tabs.css";
import TopHeading from "../../TopHeading";
import { useNavigate } from "react-router-dom";
import Category from "./Category";
import Address from "./Address";
import Cadastre from "./Cadastre";
import Condominium from "./Condominium";
import Destination from "./Destination";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../assets/css/Tabs.css";
import Attachment from "./Attachment";
import Description from "./Description";
import DryCouponModal from "./DryCouponModal";
import Features from "./Features";
import Optional from "./Optional";
import Owners from "./Owners";
import PhotoesAndPlans from "./PhotoesAndPlans";
import Relevance from "./Relevance";
import Utilities from "./Utilities";
import List from "./List"
import api, {
	unit_endpoint,
	owner_endpoint,
	stabili_endpoint,
	supplier_endpoint,
	condominium_unit_assignment_endpoint,
	owner_unit_assignment_endpoint,
	pertinence_unit_assignment_endpoint,
	conductor_unit_assignment_endpoint,
	supplier_unit_assignment_endpoint,
	insurance_unit_assignment_endpoint,
	counter_unit_assignment_endpoint,
	act_unit_assignment_endpoint,
	act_endpoint,
	counter_endpoint,
	insurance_endpoint
} from "../../../api/api";
import axios from "axios";
import Redditività from "./Redditività";
import Atti from "./Atti";
import { Collapse } from "react-bootstrap";

const CreateRealEstateUnit = () => {
	const [owners, setOwners] = useState([]);

	const [stabili, setStabili] = useState([]);

	const [office, setOffice] = useState([]);
	const [officeIndex, setOfficeIndex] = useState(-1);

	const [supplier, setSupplier] = useState([]);

	const [units, setUnits] = useState([]);

	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setOwners(response.data.content);
				console.log("owner", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getStabili() {
		try {
			const response = await api().get(`${stabili_endpoint}`);
			if (response.data) {
				setStabili(response.data.content);
				console.log("stabili", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getOffice() {
		try {
			const response = await axios.get(
				`https://anthill-service.onrender.com/getOfficeList`
			);
			if (response.data) {
				setOffice(response.data.data);
				console.log("office", response.data.data);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getSupplier() {
		try {
			const response = await api().get(`${supplier_endpoint}`);
			if (response.data) {
				setSupplier(response.data.content);
				console.log("supplier", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getUnits() {
		try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				setUnits(response.data.content);
				console.log("units", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOwners();
		getStabili();
		getOffice();
		getSupplier();
		getUnits();
	}, []);

	const [formData, setFormData] = useState(() => {
		const initialValue = JSON.parse(localStorage.getItem("unit"));
		return {
			category: initialValue?.category || "",
			typology: initialValue?.typology || "",
			address: initialValue?.address || "",
			houseNumber: initialValue?.houseNumber || "",
			scale: initialValue?.scale || "",
			floor: initialValue?.floor || "",
			internal: initialValue?.internal || "",
			municipality: initialValue?.municipality || "",
			province: initialValue?.province || "",
			cap: initialValue?.cap || null,
			owners: initialValue?.owners || [],
			conductors: initialValue?.conductors || [],
			cadastralType: initialValue?.cadastralType || "",
			cadastralTypeProperty: initialValue?.cadastralTypeProperty || "",
			portionOfCadastralUnit: initialValue?.portionOfCadastralUnit || false,
			historicProperty: initialValue?.historicProperty || false,
			municipalityCode: initialValue?.municipalityCode || "",
			sheet: initialValue?.sheet || "",
			parcel: initialValue?.parcel || [],
			categoryLandRegistry: initialValue?.categoryLandRegistry || "",
			classLandRegistry: initialValue?.classLandRegistry || "",
			consistence: initialValue?.consistence || "",
			cadastralIncome: initialValue?.cadastralIncome || "",
			urbanSection: initialValue?.urbanSection || "",
			zone: initialValue?.zone || "",
			microZone: initialValue?.microZone || "",
			ofProperty: initialValue?.ofProperty || "",
			scaleMillesimi: initialValue?.scaleMillesimi || "",
			lift: initialValue?.lift || "",
			reception: initialValue?.reception || "",
			heating: initialValue?.heating || "",
			district: initialValue?.district || "",
			water: initialValue?.water || "",
			unitDestination: initialValue?.unitDestination || "",
			defaultRegistrationOffice: initialValue?.defaultRegistrationOffice || "",
			typologyNotRented: initialValue?.typologyNotRented || "",
			assignmentType: initialValue?.assignmentType || "",
			haveSocialFee: initialValue?.haveSocialFee || false,
			managementType: initialValue?.managementType || "",
			withholdingDeposit: initialValue?.withholdingDeposit || false,
			ownerCommissions: initialValue?.ownerCommissions || "",
			buyerCommissions: initialValue?.buyerCommissions || "",
			lessorCommissions: initialValue?.lessorCommissions || "",
			conductorCommissions: initialValue?.conductorCommissions || "",
			unit: initialValue?.unit || "",
			furniture: initialValue?.furniture || "",
			equipment: initialValue?.equipment || "",
			energyCertification: initialValue?.energyCertification || "",
			otherCertification: initialValue?.otherCertification || "",
			includedExpenses: initialValue?.includedExpenses || "",
			excludedExpenses: initialValue?.excludedExpenses || "",
			keyDelivery: initialValue?.keyDelivery || "",
			contractAddendum: initialValue?.contractAddendum || "",
			notes: initialValue?.notes || "",
			imuNote: initialValue?.imuNote || "",
			status: initialValue?.status || "",
			rentalState: initialValue?.rentalState || "",
			furnished: initialValue?.furnished || false,
			hasLift: initialValue?.hasLift || false,
			rooms: initialValue?.rooms || 0,
			bathrooms: initialValue?.bathrooms || 0,
			bedrooms: initialValue?.bedrooms || 0,
			coveredMq: initialValue?.coveredMq || 0,
			notCoveredMq: initialValue?.notCoveredMq || 0,
			energyClass: initialValue?.energyClass || "",
			energyPerformanceIndex: initialValue?.energyPerformanceIndex || 0,
			apeDeadline: initialValue?.apeDeadline || "",
			heatingType: initialValue?.heatingType || "",
			haveAirConditioning: initialValue?.haveAirConditioning || false,
			haveFireplace: initialValue?.haveFireplace || false,
			haveCondominiumParkingSpace:
				initialValue?.haveCondominiumParkingSpace || false,
			havePrivateParkingSpace: initialValue?.havePrivateParkingSpace || false,
			haveBox: initialValue?.haveBox || false,
			haveCellar: initialValue?.haveCellar || false,
			haveAttic: initialValue?.haveAttic || false,
			haveBalcony: initialValue?.haveBalcony || false,
			haveTerrace: initialValue?.haveTerrace || false,
			haveGarden: initialValue?.haveGarden || false,
			havePool: initialValue?.havePool || false,
			otherOpenSpace: initialValue?.otherOpenSpace || "",
			centreDistance: initialValue?.centreDistance || 0,
			metroDistance: initialValue?.metroDistance || 0,
			busDistance: initialValue?.busDistance || 0,
			trainDistance: initialValue?.trainDistance || 0,
			roadJunctionDistance: initialValue?.roadJunctionDistance || 0,
			seaDistance: initialValue?.seaDistance || 0,
			otherDistance: initialValue?.otherDistance || "",
			haveDisabilityAccess: initialValue?.haveDisabilityAccess || false,
			haveReception: initialValue?.haveReception || false,
			havePetPermission: initialValue?.havePetPermission || false,
			other: initialValue?.other || "",
			electricitySupplier: initialValue?.electricitySupplier || "",
			electricityCounterNumber: initialValue?.electricityCounterNumber || "",
			podCode: initialValue?.podCode || "",
			gasSupplier: initialValue?.gasSupplier || "",
			gasCounterNumber: initialValue?.gasCounterNumber || "",
			pdrCode: initialValue?.pdrCode || "",
			coldWaterSupplier: initialValue?.coldWaterSupplier || "",
			hotWaterSupplier: initialValue?.hotWaterSupplier || "",
			heatingSupplier: initialValue?.heatingSupplier || "",
			costCenter: initialValue?.costCenter || "",
			propertyTaxes: initialValue?.propertyTaxes || "",
			incomeTaxes: initialValue?.incomeTaxes || "",
			condominiumFees: initialValue?.condominiumFees || "",
			directCostCda: initialValue?.directCostCda || "",
			indirectCostCda: initialValue?.indirectCostCda || "",
			photo: initialValue?.photo || [],
			planimetry: initialValue?.planimetry || [],
			attachment: initialValue?.attachment || [],
			pertinence: initialValue?.pertinence || [],
			acts: initialValue?.acts || [],

			image: initialValue?.image || "",
			name: initialValue?.name || "",
			northExposition: initialValue?.northExposition || false,
			westExposition: initialValue?.westExposition || false,
			southExposition: initialValue?.southExposition || false,
			eastExposition: initialValue?.eastExposition || false,
			structure: initialValue?.structure || "",
			condominium: initialValue?.condominium || [],
			insurances: initialValue?.insurances || [],
			suppliers: initialValue?.suppliers || [],

			denominatorParcel: initialValue?.denominatorParcel || "",
			freeImu: initialValue?.freeImu || false,
			imuTaxableBase: initialValue?.imuTaxableBase || null,
			iciTaxableBase: initialValue?.iciTaxableBase || null,
			imuRate:  initialValue?.imuRate || null,
			iciRate:  initialValue?.iciRate || null,
			freeFrom:  initialValue?.freeFrom || null,
			omiValue: initialValue?.omiValue || null,
			bookValue: initialValue?.bookValue || null,
			antiquity:  initialValue?.antiquity || null,

			buildingLocation: initialValue?.buildingLocation || "",	
			buildingAge: initialValue?.buildingAge || "",
			buildingType: initialValue?.buildingType || "",
			buildingState: initialValue?.buildingState || "",

			readCounters: initialValue?.readCounters || [],
			
			registrationTaxes: initialValue?.registrationTaxes || "",
			haveSocialFee: initialValue?.haveSocialFee || false,

			gasPlan : [],
			electricityPlan : [],
			coldWaterPlan : [],

			electricityTariffPlan:"",
			gasTariffPlan:"",
			coldWaterTariffPlan:"",

			relationData: initialValue?.relationData || [],

			marketRef:"",
			bareOwnership:"",
			leaseProfitability: "",
			grossCoveredMq: 0,
			basementMq: 0,
			finishingVerandaMq	: 0,
			notFinishingVerandaMq	: 0,
			mezzanineMq	: 0,
			canopyMq	: 0,
			balconyMq	: 0,
			terraceMq	: 0,
			gardenMq	: 0,
			lodgeMq	: 0,
			solarPavementMq	: 0,
			otherUncoveredMq: 0,	
			winery: "",
			wineryMq: 0,
			garret: "",
			garretMq: 0,
			parkingAccessibility:"",
			boxExist: false,
			box: "",
			boxMq: 0,
			boxAccessibility: "",
			boxLocation: "",
			boxPossessionType: "",
			coveredParkingSpaceExist: false,
			coveredParkingSpace: "",
			coveredParkingAccessibility: "",
			coveredParkingLocation: "",
			coveredParkingPossessionType: "",
			uncoveredParkingExist: false,
			uncoveredParking: "",
			uncoveredParkingAccessibility: "",
			uncoveredParkingLocation:"",
			uncoveredParkingPossessionType: "",
			condominialParkingSpaceExist: false,
			condominialParkingSpace: "",
			condominialParkingType: "",
			condominialParkingPossessionType: "",
			mainFaces: "",
			viewType: "",
			context:"",
			cardinalPoint1: "",
			mainView1 : "",
			typologyView1:	"",
			cardinalPoint2:	"",
			mainView2:	"",
			typologyView2:	"",
			cardinalPoint3:	"",
			mainView3:	"",
			typologyView3:	"",
			cardinalPoint4:	"",
			mainView4:	"",
			typologyView4:	"",
			brightness:	"",
			noisiness:	"",
			ceilingHeight:	"",
			internalState:	"",
			finishingQuality:	"",
			energeticClass:	"",
			heater:	"",
			heaterType:	"",
			radiantElements: "",
			airConditioner	:false,
			internalLevels:	"",
			internalDistribution:	"",
			coveredMqRoomsRatio:	"",
			coveredMqBathsRatio:	"",
	}});

	useEffect(() => {
		localStorage.setItem("unit", JSON.stringify(formData));
		console.log("ssssss", formData);
	}, [formData]);

	const { categoryLandRegistry } = formData;

	const [option, setOption] = useState(() => {
		const initialOption = JSON.parse(localStorage.getItem("option"));
		return initialOption ? initialOption : [];
	});

	useEffect(() => {
		localStorage.setItem("option", JSON.stringify(option));
	}, [option]);

	const save = async () => {
		console.log(formData);
		try {
			// TODO manage better in future
			const parcelStrings = [];
			const owners = [...formData.owners]
			const conductors = [...formData.conductors]
			const pertinences = [...formData.pertinence]
			const condominium = [...formData.condominium]
			const suppliers = [...formData.suppliers];
			const insurances = [...formData.insurances];
			const readCounters = [...formData.readCounters]
			const acts = [...formData.acts]

			delete formData.pertinence;
			delete formData.owners;
			delete formData.condominium;
			delete formData.conductors;
			delete formData.suppliers
			delete formData.insurances
			delete formData.readCounters
			delete formData.acts

			// PRIMA DI TUTTO Creazione delle assicurazioni
			const newInsurances = [];

			console.log('assicurazione', insurances)
			if (insurances.length > 0) {
				for (const insuranceData of insurances) {

					const {attachment, ...dataToSend} = insuranceData

			//GESTIONE ALLEGATI
			let formDataToSend = new FormData();
			let sentUpload = formDataToSend.get("parts") !== null;
		
			attachment.forEach((file) => {
				if(typeof file != 'string'){
					const blob = new Blob([file]);
					formDataToSend.append("parts", blob, file.name)
				}
			});

			const agencyString = JSON.stringify(insuranceData.agency);
			const insuranceDataStringified = {
			...dataToSend,
			agency: agencyString,
			};

			const createdInsuranceResponse = await api().post(`${insurance_endpoint}`, insuranceDataStringified);


			if(sentUpload && createdInsuranceResponse) {
				await api().put(`${insurance_endpoint}upload/${createdInsuranceResponse.data.id}`, formDataToSend);
			}

			console.log('caricata', formDataToSend, insuranceDataStringified)

			const createdInsurance = createdInsuranceResponse.data;
				newInsurances.push(createdInsurance);
				}
			}


			// PRIMA DI TUTTO Creazione delle letture
			const newReadCounters = [];

			if (readCounters.length > 0) {
				for (const counter of readCounters) {
					const createdCounterResponse = await api().post(`${counter_endpoint}`, counter);
					const createdCounter = createdCounterResponse.data;
					newReadCounters.push(createdCounter );
				}
			}

			// PRIMA DI TUTTO Creazione degli atti
			const newActs = [];

			if (acts.length > 0) {
				for (const act of acts) {

					const {attachment, ...dataToSend} = act

					//GESTIONE ALLEGATI
					let formDataToSend = new FormData();
				
					attachment.forEach((file) => {
						if(typeof file != 'string'){
							const blob = new Blob([file]);
							formDataToSend.append("parts", blob, file.name)
						}
					})
			
					let sentUpload = formDataToSend.get("parts") !== null;

					
					const createdActResponse = await api().post(`${act_endpoint}`, dataToSend);

					if(sentUpload && createdActResponse) {
						await api().put(`${act_endpoint}upload/${createdActResponse.data.id}`, formDataToSend);
					}

					const createdAct = createdActResponse.data;
					newActs.push(createdAct);
				}
			}

			//REMOVE SOME ITEMS FROM FORM DATA
			//WE NEED TO ADD ALSO OWNERS IN A SECOND MOMENT
			const {attachment,
				planimetry, photo, image,
				...dataToSend} = formData

			dataToSend.parcel.forEach((objString) => {
				parcelStrings.push(JSON.stringify(objString));
			});
			dataToSend.parcel = parcelStrings;


			//GESTIONE ALLEGATI (sono array di file, tranne image)

			let formDataToSendAttachment = new FormData();
			let formDataToSendPlanimetry = new FormData();
			let formDataToSendPhoto = new FormData();
			let formDataToSendImage = new FormData();

			attachment.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSendAttachment.append("parts", blob, file.name);
			});

			planimetry.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSendPlanimetry.append("parts", blob, file.name);
			});

			photo.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSendPhoto.append("parts", blob, file.name);
			});

			console.log(image, 'wee')

			if (image !== "") {
				const blob = new Blob([image]);
			  
				formDataToSendImage.append("parts", blob, image.name); // Aggiungi il Blob all'oggetto FormData
			  }

			let sentUploadAttachment = formDataToSendAttachment.get("parts") !== null;
			let sentUploadPlanimetry = formDataToSendPlanimetry.get("parts") !== null;
			let sentUploadPhoto = formDataToSendPhoto.get("parts") !== null;
			let sentUploadImage = formDataToSendImage.get("parts") !== null;


			// Stringify dei piani tariffari prima della post
			dataToSend.gasPlan = JSON.stringify(dataToSend.gasPlan);
			dataToSend.coldWaterPlan = JSON.stringify(dataToSend.coldWaterPlan);
			dataToSend.electricityPlan = JSON.stringify(dataToSend.electricityPlan);
			dataToSend.relationData = JSON.stringify(dataToSend.relationData);

			//POST UNIT
			let response;
			response = await api().post(`${unit_endpoint}`, dataToSend);
	
			//PUT PER ALLEGATI
			if (response) {
				if (sentUploadAttachment) {
					await api().put(`${unit_endpoint}upload/${response.data.id}`, formDataToSendAttachment);
				}
				if (sentUploadPlanimetry) {
					await api().put(`${unit_endpoint}upload_planimetry/${response.data.id}`, formDataToSendPlanimetry);
				}
				if (sentUploadPhoto) {
					await api().put(`${unit_endpoint}upload_photo/${response.data.id}`, formDataToSendPhoto);
				}
				if (sentUploadImage) {
					await api().put(`${unit_endpoint}upload_image/${response.data.id}`, formDataToSendImage);
				}
			}


			// ASSOCIAZIONE CONDOMINIO
			if (condominium.length > 0 && response.data.id) {
				await api().post(`${condominium_unit_assignment_endpoint}`, {
				condominiumID: condominium[0].id,
				unitID: response.data.id,
				});
			}
			
			// ASSOCIAZIONE OWNERS
			if (owners.length > 0 && response.data.id) {
				for (const owner of owners) {
				await api().post(`${owner_unit_assignment_endpoint}`, {
					ownerID: owner.id,
					unitID: response.data.id,
				});
				}
			}
			
			// ASSOCIAZIONE CONDUTTORI
			if (conductors.length > 0 && response.data.id) {
				for (const conductor of conductors) {
				await api().post(`${conductor_unit_assignment_endpoint}`, {
					conductorID: conductor.id,
					unitID: response.data.id,
				});
				}
			}

			//ASSOCIAZIONE PERTINENZE
				if (pertinences.length > 0 && response.data.id) {
					for (const pertinence of pertinences) {
					await api().post(`${pertinence_unit_assignment_endpoint}`, {
						pertinenceID: pertinence.id,
						unitID: response.data.id,
					});
					}
				}
				
			

			//ASSOCIAZIONE FORNITORI
			if (suppliers.length > 0 && response.data.id) {
					for (const supplier of suppliers) {
					await api().post(`${supplier_unit_assignment_endpoint}`, {
						supplierID: supplier.id,
						unitID: response.data.id,
					});
					}
			}

			//ASSOCIAZ ASSICURAZIONI APPENA CREATE

			if (newInsurances.length > 0 && response.data.id) {
				for (const insurance of newInsurances) {
				await api().post(`${insurance_unit_assignment_endpoint}`, {
				insuranceID: insurance.id,
				unitID: response.data.id,
				});
			}
			}

			//ASSOCIAZ COUNTER APPENA CREATE

			if (newReadCounters.length > 0 && response.data.id) {
				for (const readCounter of newReadCounters) {
				await api().post(`${counter_unit_assignment_endpoint}`, {
				counterID: readCounter.id,
				unitID: response.data.id,
				});
			}
			}

				//ASSOCIAZ ATTI APPENA CREATE

				if (newActs.length > 0 && response.data.id) {
					for (const act of newActs) {
					await api().post(`${act_unit_assignment_endpoint}`, {
					actID: act.id,
					unitID: response.data.id,
					});
				}
				}

					
				


		} catch (error) {
			console.log(error);
		}
		localStorage.removeItem("unitState");
		localStorage.removeItem("unit");
		localStorage.removeItem("option");
		navigate(-1);
	};

	const onChange = (e) => {
		const {value, name, checked, type} = e.target

		let convertedValue

		if(	name == "ofProperty" ||
			name == "scaleMillesimi" ||
			name == "lift" ||
			name == "reception"||
			name == "heating" || 
			name == "district" || 
			name == "water" || type === "number"){
				convertedValue = value.replace(',', '.')
			} else {
				convertedValue = value
			}

			console.log({convertedValue})

		if (type === "checkbox") {
			setFormData({ ...formData, [name]:checked });
		} else {
			setFormData({ ...formData, [name]: convertedValue });
		}
	};

	const [count, setCount] = useState(() => {
		const initialCount = JSON.parse(localStorage.getItem("unitState"));
		return initialCount ? initialCount : 0;
	});

	useEffect(() => {
		localStorage.setItem("unitState", JSON.stringify(count));
	}, [count]);
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const forward = () => {
		var num = count;
		console.log("funzione forward")
		if (num <= option.length + 7) {
			setCount(++num);
		}
			

		
	};

	const backward = () => {
		var num = count;
		if (num > 0) {
			setCount(--num);
		}
	};

	const previousPage = () => {
		navigate(-1);
	};

	const setStep = (index) => {
		setCount(index);
	};

	console.log({count:count, option:option.length})

	//submenu
	const [subState, setSubState] = useState((1));

	const handleSubState = (index) => {
		setSubState(index);
	};

	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="Nuovo unità immobiliari" />
				</div>
			</div>
			<div className="step-show">
				<div className="tabs-div">
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(0);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "126.393px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 5C2 2.79086 3.79086 1 6 1H106.679C108.114 1 109.44 1.76914 110.152 3.01544L123.866 27.0154C124.569 28.2452 124.569 29.7548 123.866 30.9846L110.152 54.9846C109.44 56.2309 108.114 57 106.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
									fill={
										count >= 0 ? (count >= 1 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 0 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title1"
								style={{
									color:
										count >= 0
											? count >= 1
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Categoria
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 0 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 1 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 0 ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Categoria
							</div>
						</div>
					</div>
					{/* <div
						className="tab-icon"
						onClick={(e) => {
							setStep(1);
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count >= 1 ? (count >= 2 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 1 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 1
											? count >= 2
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Indirizzo
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 1 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 2 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 1 ? "" : "none",
									position: "absolute",
									left: "-7px",
								}}>
								Indirizzo
							</div>
						</div>
					</div> */}
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(1);
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count >= 1 ? (count >= 2 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 1 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 1
											? count >= 2
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Proprietari
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 1 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 2 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 1 ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Proprietari
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(2);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "118px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M103.152 3.01544C102.439 1.76914 101.114 1 99.6786 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89263 57H99.6786C101.114 57 102.439 56.2309 103.152 54.9846L116.866 30.9846C117.569 29.7548 117.569 28.2452 116.866 27.0154L103.152 3.01544Z"
									fill={
										count >= 2 ? (count >= 3 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 2 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 2
											? count >= 3
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Catasto
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 2 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 3 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 2 ? "" : "none",
									position: "absolute",
									left: "-2px",
								}}>
								Catasto
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(3);
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count >= 3 ? (count >= 4 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 3 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 3
											? count >= 4
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Condominio
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 3 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 4 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 3 ? "" : "none",
									position: "absolute",
									left: "-16px",
								}}>
								Condominio
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(4);
						}}>
						<div className="desktop-icon">
							
							<svg style={{ width: "145px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M130.152 3.01544C129.439 1.76914 128.114 1 126.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H126.679C128.114 57 129.439 56.2309 130.152 54.9846L143.866 30.9846C144.569 29.7548 144.569 28.2452 143.866 27.0154L130.152 3.01544Z"
									fill={
										count >= 4 ? (count >= 5 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 4 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 4
											? count >= 5
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Destinazione
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 4 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 5 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 4 ? "" : "none",
									position: "absolute",
									left: "-15px",
								}}>
								Destinazione
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(5);
						}}>
						<div className="desktop-icon">
							
							<svg style={{ width: "118px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M103.152 3.01544C102.439 1.76914 101.114 1 99.6786 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89263 57H99.6786C101.114 57 102.439 56.2309 103.152 54.9846L116.866 30.9846C117.569 29.7548 117.569 28.2452 116.866 27.0154L103.152 3.01544Z"
									fill={
										count >= 5 ? (count >= 6 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 5 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 6
											? count >= 6
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Elenchi
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 5 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 6 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 5 ? "" : "none",
									position: "absolute",
									left: "-15px",
								}}>
								Elenchi
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(6);
						}}>
						<div className="desktop-icon">
							

							<svg style={{ width: "138px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H133C135.209 1 137 2.79086 137 5V53C137 55.2091 135.209 57 133 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
									fill={
										count >= option.length + 6
											? count >= option.length + 7
												? "#83112F"
												: "#FFF8F8"
											: "#fff"
									}
									stroke={count >= option.length + 7 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>

							<div
								className="tab-icon-title"
								style={{
									color:
										count >= option.length + 6
											? count >= option.length + 7
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Facoltativi
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === option.length + 6 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= option.length + 7 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 6 ? "" : "none",
									position: "absolute",
									left: "-8px",
								}}>
								Facoltativi
							</div>
						</div>
					</div>
					<br/>
					

					{/* <div
						className="tab-icon"
						onClick={(e) => {
							setStep(option.length + 7);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "138px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H133C135.209 1 137 2.79086 137 5V53C137 55.2091 135.209 57 133 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
									fill={
										count >= option.length + 7
											? count >= option.length + 8
												? "#83112F"
												: "#FFF8F8"
											: "#fff"
									}
									stroke={count >= option.length + 7 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= option.length + 7
											? count >= option.length + 8
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Identificativo
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === option.length + 7 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 14 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === option.length + 7 ? "" : "none",
									position: "absolute",
									left: "-16px",
								}}>
								Identificativo
							</div>
						</div>
					</div> */}
				</div>

				<Collapse in={count == 6}>
					<div className="state-bar-red">
						
						<div className="state-btn-group-red mt-4">
							<>
							<div
								className={`${subState === 1 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(1);
								}}>
								Descrizione
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(1);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 1) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 1 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 1) ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Descrizione
							</div>
							</div>
							</>
							
							<>
							<div
								className={`${subState === 2 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(2);
								}}>
								Caratteristiche
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(2);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 2) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 2 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 2) ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Caratteristiche
							</div>
							</div>
							</>
							
							<>
							<div
								className={`${subState === 3 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(3);
								}}>
								Utenze
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(3);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 3) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 3 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 3) ? "" : "none",
									position: "absolute",
									left: "0px",
								}}>
								Utenze
							</div>
							</div>
							</>
							
							<>
							<div
								className={`${subState === 4 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(4);
								}}>
								Redditività
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(4);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 4) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 4 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 4) ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Redditività
							</div>
							</div>
							</>

							<>
							<div
								className={`${subState === 5 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(5);
								}}>
								Atti
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(5);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 5) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 5 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 5) ? "" : "none",
									position: "absolute",
									left: "9px",
								}}>
								Atti
							</div>
							</div>
							</>

							<>
							<div
								className={`${subState === 6 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(6);
								}}>
								Pertinenze
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(6);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 6) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 6 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 6) ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Pertinenze
							</div>
							</div>

							</>

							<>
							<div
								className={`${subState === 7 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(7);
								}}>
								Foto
							</div>

							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(7);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 7) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 7 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 7) ? "" : "none",
									position: "absolute",
									left: "9px",
								}}>
								Foto
							</div>
							</div>

							</>

							<>
							<div
								className={`${subState === 8 ? "active" : ""} tabs-title-min-red desktop-icon`}
								onClick={(e) => {
									handleSubState(8);
								}}>
								Allegati
							</div>
							<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}
							onClick={(e) => {
								handleSubState(8);
							}}
							>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: (count === 6 && subState === 8) ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={subState >= 8 ? "#d0b3b3" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								
								style={{
									display: (count == 6 && subState == 8) ? "" : "none",
									position: "absolute",
									left: "0px",
								}}>
								Allegati
							</div>
							</div>
							</>
						</div>
					</div>
					</Collapse>

				<div className="content-container">
					{count === 0 && <Category formData={formData} 
									onChange={onChange} 
									previousPage={previousPage}
									forward={forward}

					/>}
					{/* {count === 1 && <Address formData={formData} onChange={onChange} />} */}
					{count === 1 && (
						<Owners formData={formData} onChange={onChange} owners={owners} forward={forward} backward={backward}/>
					)}
					{count === 2 && <Cadastre formData={formData} onChange={onChange} forward={forward} backward={backward}/>}
					{count === 3 && (
						<Condominium
							formData={formData}
							onChange={onChange}
							stabili={stabili}
							forward={forward} backward={backward}
						/>
					)}
					{count === 4 && (
						<Destination
							formData={formData}
							onChange={onChange}
							office={office}
							setOfficeIndex={setOfficeIndex}
							forward={forward} backward={backward}
						/>
					)}
					{count === 5 && (
						<List
							formData={formData}
							setFormData={setFormData}
							onChange={onChange}
							option={option}
							setOption={setOption}
							forward={forward} backward={backward}
						/>
					)}
					{/* {count === 6 && (
						<Optional
							formData={formData}
							onChange={onChange}
							option={option}
							setOption={setOption}
							forward={forward} backward={backward}
						/>
					)} */}
					{count == 6 && subState == 1 &&
						<Description formData={formData} onChange={onChange} forward={forward} backward={backward} />}
					{count == 6 && subState == 2 &&
						<Features formData={formData} onChange={onChange} forward={forward} backward={backward} save={save} />}
					{count == 6 && subState  == 3 &&
						
						<Utilities
							formData={formData}
							setFormData={setFormData}
							onChange={onChange}
							supplier={supplier}
							forward={forward} backward={backward}
							save={save}
						/>
					}
					{count == 6 && subState == 7 &&
						<PhotoesAndPlans formData={formData} onChange={onChange} forward={forward} backward={backward}  save={save}/>
					}
					{count == 6 && subState == 8 && <Attachment formData={formData} onChange={onChange}  forward={forward} backward={backward}  save={save}/>}

					{count == 6 && subState == 6 &&
						
						<Relevance formData={formData} onChange={onChange} units={units} forward={forward} backward={backward} save={save} />
					}
					{count == 6 && subState == 5 &&
						<Atti formData={formData} onChange={onChange} units={units} forward={forward} backward={backward} save={save} setFormData={setFormData}/>
					}
					{count == 6 && subState == 4 &&
						<Redditività formData={formData} onChange={onChange} units={units} forward={forward} backward={backward} save={save} />
					}

					{/* {count === option.length + 7 && (
						<Identifier formData={formData} onChange={onChange} />
					)} */}

					{count >= 6 && <div className="d-flex justify-content-end mt-5">
						
							<button
								type="button"
								className="general-btn white-btn me-2 w-193"
								onClick={() => backward()}>
								Indietro
							</button>
					
						{count === 6 && option.length == 0 && (
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={save}>
								Salva
							</button>
						) }

						{count === 6 && option.length > 0 &&
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={() => forward()}>
								Avanti
							</button>
						}
				{/* 	{count != 6 && option.length > 0 && count < (6 + option.length) &&
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={() => forward()}>
								Avanti
							</button>
						}
					{option.length > 0  && count == (6 + option.length) &&
						<button
							type="button"
							className="general-btn pink-btn w-193"
							onClick={save}>
							Salva
						</button>
					}
	 */}
					</div> }
				</div>
			</div>
			<Modal
				show={show}
				fullscreen={true}
				className="right-full-pop"
				backdrop="static">
				<Modal.Body className="p-5">
					<Button
						variant=""
						onClick={(e) => setShow(false)}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<DryCouponModal setShow={setShow} setCount={setCount} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default CreateRealEstateUnit;
