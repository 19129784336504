import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AllegatiV from "./AllegatiV";
import AnagraficaV from "./AnagraficaV";
import CorrentiV from "./CorrentiV";
import DocumentoV from "./DocumentoV";
import FornitoriV from "./FornitoriV";
import NoteV from "./NoteV";
import RecapitiV from "./RecapitiV";
import UnitaV from "./UnitaV";
import "../../../../assets/css/Membri.css";
import TopArrow from "../../../../assets/images/select-up-arrow.svg";
import DownArrow from "../../../../assets/images/select-down-arrow.svg";

export default function MidTabs(props) {
  const [key, setKey] = useState(props.parms);
  const [count, setCount] = useState(1);
  const [clicked, setClicked] = useState(false);

  console.log("parms",  props.parms)

  const tab1Click = () => {
    setCount(1);
  };
  const tab2Click = () => {
    setCount(2);
  };
  const tab3Click = () => {
    setCount(3);
  };
  const tab4Click = () => {
    setCount(4);
  };
  const tab5Click = () => {
    setCount(5);
  };
  const tab6Click = () => {
    setCount(6);
  };
  const tab7Click = () => {
    setCount(7);
  };
  const tab8Click = () => {
    setCount(8);
  };

  const returnContent = (count) => {
    switch (count) {
      case 1:
        return <AnagraficaV formData={props.parms} />;
      case 2:
        return <RecapitiV formData={props.parms} />;
      case 3:
        return <DocumentoV formData={props.parms} />;
      case 4:
        return <CorrentiV formData={props.parms} />;
      case 5:
        return <UnitaV formData={props.parms} />;
      case 6:
        return <FornitoriV formData={props.parms} />;
      case 7:
        return <AllegatiV formData={props.parms} />;
      default:
        return <AnagraficaV formData={props.parms} />;
    }
  };
  const tabSelect = (e) => {
    switch (e.target.value) {
      case "ana":
        tab1Click();
        break;
      case "rec":
        tab2Click();
        break;
      case "doc":
        tab3Click();
        break;
      case "con":
        tab4Click();
        break;
      case "uni":
        tab5Click();
        break;
      case "for":
        tab6Click();
        break;
      case "all":
        tab7Click();
        break;
      default:
        tab1Click();
        break;
    }
  };

  return (
    <div className="mt-30">
      <div className="justify-content-center d-flex tabs-select-div">
        <select
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white tabs-select"
          onChange={(e) => tabSelect(e)}
          onClick={() => setClicked(!clicked)}
        >
          <option value="ana">Anagrafica</option>
          <option value="rec">Recapiti</option>
          <option value="doc">Documento</option>
          <option value="con">Conti Correnti</option>
          <option value="uni">Unità</option>
          <option value="for">Fornitori del locatore</option>
          <option value="all">Allegati</option>
        </select>
        <img
          src={clicked ? DownArrow : TopArrow}
          style={{
            display: "absolute",
            marginLeft: clicked ? "-43px" : "-37px",
          }}
        ></img>
      </div>
      <div className="justify-content-center tabs-title-div">
        <div
          className="tabs-title"
          onClick={() => tab1Click()}
          style={{
            background: count === 1 ? "#9ccbf2" : "",
            color: count === 1 ? "#FFFFFF" : "",
          }}
        >
          Anagrafica
        </div>
        <div
          className="tabs-title "
          onClick={() => tab2Click()}
          style={{
            background: count === 2 ? "#9ccbf2" : "",
            color: count === 2 ? "#FFFFFF" : "",
          }}
        >
          Recapiti
        </div>
        <div
          className="tabs-title"
          onClick={() => tab3Click()}
          style={{
            background: count === 3 ? "#9ccbf2" : "",
            color: count === 3 ? "#FFFFFF" : "",
          }}
        >
          Documento
        </div>
        <div
          className="tabs-title"
          onClick={() => tab4Click()}
          style={{
            background: count === 4 ? "#9ccbf2" : "",
            color: count === 4 ? "#FFFFFF" : "",
          }}
        >
          Conti Correnti
        </div>
        <div
          className="tabs-title"
          onClick={() => tab5Click()}
          style={{
            background: count === 5 ? "#9ccbf2" : "",
            color: count === 5 ? "#FFFFFF" : "",
          }}
        >
          Unità
        </div>
        <div
          className="tabs-title "
          onClick={() => tab6Click()}
          style={{
            background: count === 6 ? "#9ccbf2" : "",
            color: count === 6 ? "#FFFFFF" : "",
          }}
        >
          Fornitori del locatore
        </div>
        <div
          className="tabs-title "
          onClick={() => tab7Click()}
          style={{
            background: count === 7 ? "#9ccbf2" : "",
            color: count === 7 ? "#FFFFFF" : "",
          }}
        >
          Allegati
        </div>
      </div>
      <div className="mt-25">
        <div className="tabContent-div pt-3">{returnContent(count)}</div>
      </div>
    </div>
  );
}
