import '../../../assets/css/StatusCard.css'

import { Col } from 'react-bootstrap'

//This is single the Status Card component, in order to modify the component 
//changes should be made here and in the related css file

export default function StatusCard({icon, activity_number, status_name}){

    const statusCard = 
        <div className='status-card d-flex justify-content-md-between flex-md-column'>
            <div>
                <img loading="lazy" src={icon} className="status-icon"/>
            </div>
            <div className='d-flex align-items-center flex-md-column align-items-md-end text-md-end'>
                <h3 className='fw-bold me-2'>{activity_number}</h3>
                <p>{status_name}</p>
            </div>
        </div>

    return (
        <Col xs={12} sm={6} md={3} className="mb-1">
            {statusCard}
        </Col>
    )
}
