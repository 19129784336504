import { useState } from "react";
import FilterItem from "./FilterItem";

/* A questo componente bisogna passare lista con nome e id di filtri, array di filtri attiviti e funzione per gestirli,
variabile con numero max di elementi mostrati */

const FilterFornitori = ({ filterItems, title = 'Filtri', selectedCategories=[], setSelectedCategories, maxFiltersToShow }) => {
  const [showAllFilters, setShowAllFilters] = useState(false);

  const handleFilter = (label) => {
    if (selectedCategories.includes(label)) {
      setSelectedCategories(selectedCategories.filter((category) => category !== label));
    } else {
      setSelectedCategories([...selectedCategories, label]);
    }
  };

  const toggleShowAllFilters = () => {
    setShowAllFilters(!showAllFilters);
  };

  const filteredItems = showAllFilters ? filterItems : filterItems.slice(0, maxFiltersToShow);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className="fw-bold fs-16">{title}</p>
        <p className="fs-14 opacity-75 text-decoration-underline pointer-cursor" onClick={() => setSelectedCategories([])}>Resetta</p>
      </div>
      <div>
        {filteredItems.map((filter) => (
          <FilterItem
            key={filter.id}
            id={filter.id}
            name={filter.name}
            handleFilter={() => handleFilter(filter.name)}
            checked={selectedCategories?.includes(filter.name)}
          />
        ))}
      </div>
      {filterItems.length > maxFiltersToShow && (
        <p className="fs-14 opacity-75 text-decoration-underline text-center pointer-cursor" onClick={toggleShowAllFilters}>
          {showAllFilters ? 'Nascondi' : 'Mostra tutti'}
        </p>
      )}
    </div>
  );
};

export default FilterFornitori;


/* const FilterFornitori = ({filterItems, title='Filtri', activeFilters, maxFiltersToShow, setActiveFilters}) => {

    const [showAllFilters, setShowAllFilters] = useState(false);

  
    const handleFilter = (id) => {
      const filterIndex = activeFilters.indexOf(id);
  
      if (filterIndex === -1) {
        setActiveFilters([...activeFilters, id]);
      } else {
        const updatedFilters = activeFilters.filter((filterId) => filterId !== id);
        setActiveFilters(updatedFilters);
      }
    };
  
    const toggleShowAllFilters = () => {
      setShowAllFilters(!showAllFilters);
    };
  
    const filteredItems = showAllFilters ? filterItems : filterItems.slice(0, maxFiltersToShow);
  
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2">
            <p className="fw-bold fs-16">{title}</p>
            <p className="fs-14 opacity-75 text-decoration-underline pointer-cursor" onClick={() => setActiveFilters([])}>Resetta</p>
        </div>
        <div>
          {filteredItems.map((filter) => (
            <FilterItem
              key={filter.id}
              id={filter.id}
              name={filter.name}
              handleFilter={handleFilter}
              checked={activeFilters.includes(filter.id)}
            />
          ))}
        </div>
        {filterItems.length > maxFiltersToShow && (
          <p className="fs-14 opacity-75 text-decoration-underline text-center pointer-cursor" onClick={toggleShowAllFilters}>
            {showAllFilters ? 'Nascondi' : 'Mostra tutti'}
          </p>
        )}
      </div>
    );
  };
  
  export default FilterFornitori; */
  