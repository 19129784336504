import { useEffect, useState } from "react";
import {Col} from "react-bootstrap"
import ButtonRm from "../../../../ButtonRm";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import CustomBadge from "../../../../CustomBadge";

import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import capitalizeFirstLetter from "../../../../../utils/capitalizeFirstLetter"

import logoPng from '../../../../../assets/images/logo-red-big.png';


import Modal from "react-bootstrap/Modal";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import api, { accounting_endpoint, chat_msg_endpoint, BASE_URL, token } from "../../../../../api/api";

import { checkUserData } from "../../../../../utils/checkUserRole";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";




export default function Preventivo(props) {

   

    // Funzione per calcolare il totale di una riga
    const calculateRowTotal = (row) => {
        const quantity = parseInt(row['Quantità']) || 0;
        const unitCost = parseFloat(row['Costo unitario']) || 0;
        return quantity * unitCost;
    };

    // Funzione per calcolare il totale generale di tutte le righe
    const calculateTotal = () => {
        let total = 0;
        props.parsedContent.forEach((el) => {
        el.rows.forEach((row) => {
            total += calculateRowTotal(row);
        });
        });
        return total;
    };


      /* MODAL CONTABILITA */
      const [showContabilita, setShowContabilita] = useState(false);
      const handleCloseContabilita = () => setShowContabilita(false);

      function initiateCheckout() {
        if ( calculateTotal()) {
          const prezzo = calculateTotal()
          fetch(`${BASE_URL}api/v1/payment/payCheckout`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ name: "Pagamento Preventivo", price: (prezzo * 100) })
          })
          .then((res) => res.json())
          .then((data) => {
            console.log(data, "datStripe");
            window.open(data.url);
  /*           setClientSecret(data["client_secret"]);
              setAccountConnect(data["target_account"]); */
    
            // Aggiornare stripePromise con l'account ID ricevuto
            /*  stripePromise.then(stripe => {
              stripe.update({ stripeAccount: data["target_account"] });
            }); */
          })
          .catch((error) => {
            console.error('Errore durante la richiesta:', error);
          });
        } else {
          console.log("Mancano causale e/o prezzo")
        }
      }

      //CHECK IF DATA REQUIRED ARE COMPILED
			const [dataRequired, setDataRequired] = useState({
        hasAccountConnect: false,
        isCustomer: false,
        isVerified: false,
        isVerifiedFull: false
      });
			//Gestisce il modale
			const [showCheck, setShowCheck] = useState(false)
		
			useEffect(() => {
				const fetchData = async () => {
					try {
						const response = await checkUserData();
						setDataRequired(response);
						if (!response.isVerifiedFull) {
						  setShowCheck(true);
					  }
					} catch (error) {
						// Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
						console.error('Errore durante il recupero dei dati utente:', error);
					}
				};
		
				fetchData();
			}, []);


      //CONTROLLO BTN PAGA ORA
      /*
      1. CONTROLLARE CHE L'UTENTE AUTORE DEL MESSAGGIO ABBIA STRIPEUSER  (ricerca utente tramite id)
      2. SE HA STRIPEUSER MOSTRARE BOTTONE

      PROBLEMA: la chiamata GETALL per prendere tutti gli utenti e cercare quello con l'id specifico funziona solo da master, bisogna trovre un altro modo o aggiungere un campo per capire se il bottone paga ora può essere visibile o no.
      
      */
		

    return (                     
            <div className="my-3" >
              <div className="my-3" >
                <div className="d-flex align-items-center">
                    <p className="text-capitalize fs-14">PREVENTIVO</p>
                    <TableComponent parsedContent={props.parsedContent}  calculateRowTotal={calculateRowTotal} calculateTotal={calculateTotal} capitalizeFirstLetter={capitalizeFirstLetter} />
                    <button className="btn round-icon-btn btn ms-1" onClick={() => setShowContabilita(true)}><i class="bi bi-pencil-square"></i></button>
                   {/*  <ButtonRm variant="outline-gray" size="small" onClick={() => { initiateCheckout() }}>Paga ora</ButtonRm>  */}

                </div>
                <hr className="my-2"/>
                <div>
                  {props.parsedContent.map((el) => (
                    <div key={el.id}>
                      {el.rows?.length > 0 && el.rows.some(row => Object.values(row).some(value => value !== '')) && (
                        <div>
                          <p className="mt-3 mb-1 fw-bold text-uppercase">{capitalizeFirstLetter(el.name)}</p>
                          <div className="d-flex justify-content-between">
                            <TableContainer style={{ backgroundColor: 'transparent' }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {Object.keys(el.rows[0]).map((key) => (
                                      <TableCell key={key} align="left">
                                        {capitalizeFirstLetter(key)}
                                      </TableCell>
                                    ))}
                                    <TableCell align="left">Totale</TableCell> {/* Nuova colonna Totale */}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {el.rows.map((row, index) => (
                                    <TableRow key={index}>
                                      {Object.keys(row).map((key) => (
                                        <TableCell key={key} align="left">
                                          {row[key]}
                                        </TableCell>
                                      ))}
                                      <TableCell align="left">{calculateRowTotal(row)}</TableCell> {/* Calcolo del totale per la riga */}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                            )}
                            </div>
                    ))}
                {/* Riga finale con il totale generale */}
                    <div>
                    <TableRow className="w-100">
                        <TableCell  align="left" >
                        <p className=" fw-bold text-uppercase fs-12">Totale</p>
                        </TableCell>
                        <TableCell  align="left">
                        <p className=" fw-bold text-uppercase fs-12">{calculateTotal()}</p>
                        </TableCell>
                    </TableRow>
                  </div>
                </div>



           


                                                 
                                </div>
                                <div className="vision-chksec">
                                    {/* CASO 1: IO NON HA FLAGGATO CHECKBOX*/}
                                    {   props.item?.confirmSeen?.length > 0 &&
                                        props.item?.confirmSeen.map(el => {
                                            return el.userID != JSON.parse(localStorage.getItem('user')).id && <div className="msg-chk">
                                            <label>
                                                <input type="checkbox" value={props.verbalCheckbox} onChange={props.handleVerbalCheckbox}/>
                                                <span></span>
                                            </label>
                                            <p className="fs-14">
                                                Confermo di aver presa
                                                visione
                                            </p>
                                        </div>
                                        })                                
                                    }
                                    {/* CASO 2: NESSUN UTENTE HA FLAGGATO CHECKBOX*/}
                                    {   props.item?.confirmSeen?.length == 0 &&
                                        <div className="msg-chk">
                                            <label>
                                                <input type="checkbox" value={props.verbalCheckbox} onChange={props.handleVerbalCheckbox}/>
                                                <span></span>
                                            </label>
                                            <p className="fs-14">
                                                Confermo di aver presa
                                                visione
                                            </p>
                                        </div>                             
                                    }
                                    {/* CASO 3: UTENTE IO HA FLAGGATO CHECKBOX E UTENTE  IO NON HA ACCETTATO*/}
                                    {   props.item?.confirmSeen?.length > 0 && props.item?.confirmContents?.length  == 0 &&
                                        props.item?.confirmSeen.map(el => {
                                            /*  el.userID == props.chat.members?.[0]?.id ? */
                                            return <div>
                                                <div className="d-flex justify-content-end align-items-center mb-2 text-end">                                              

                                                    <CustomBadge variant="custom-badge-success"> <p className="fs-14">{props.chat.members?.[0]?.name} {props.chat.members?.[0]?.surname}  ha confermato la presa visione</p></CustomBadge>

                                                </div>
                                                
                                                  <div className="dualbtn-main">
                                                    {!dataRequired.isVerifiedFull && <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 50, hide: 50 }}
                                                        overlay={
                                                          <Tooltip id="tooltip" className="exclamation-tooltip">
                                                            <p className="mb-3">Per poter accettare o rifiutare un preventivo, è necessario completare le seguenti informazioni:</p>

                                                            <p>1. <strong>Anagrafica Completa:</strong> <br/>Anagrafica / Face ID /Documenti</p>
                                                            <em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>
                                                          </Tooltip>
                                                        }>
                                                        <i class="bi  bi-info-circle me-2 fs-14"></i>
                                                      </OverlayTrigger>
                                                      }

                                                    <div className="btn-gp">
                                                        <button className={`btn btn-success fs-12`} onClick={() => props.postChatConfirm(true)} disabled={dataRequired.isVerifiedFull? false : true}>
                                                            Accetto 
                                                        </button>
                                                    </div>
                                                    <div className="btn-gp">
                                                   
                                                        <button className={`btn btn-danger  fs-12`} onClick={() => props.postChatConfirm(false)} disabled={dataRequired.isVerifiedFull? false : true}>
                                                            Non Accetto 
                                                        </button>
                                                    </div>
                                                  </div>
                                                
                                            </div> 
                                        })                                
                                    }
                                    {/* CASO 4: UTENTE IO HA FLAGGATO CHECKBOX E UTENTE  IO HA ACCETTATO / rifiutato*/}
                                    {   props.item?.confirmSeen?.length > 0 && props.item?.confirmContents?.length  > 0 &&
                                        props.item?.confirmSeen.map(el => {
                                            return  el.userID == JSON.parse(localStorage.getItem('user')).id ?
                                            <div>
                                                <div className="d-flex justify-content-end align-items-center mb-2 text-end">
                                                    <CustomBadge variant="custom-badge-success"> <p className="fs-12 fw-regular"><i class="bi bi-check"></i> {capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)}  ha confermato la presa visione</p></CustomBadge>
                                                </div>
                                                <div>
                                        
                                        {   props.item?.confirmContents[0].accepted &&
                                            <div className="d-flex justify-content-end align-items-center mb-2 text-end">
                                                <CustomBadge variant="custom-badge-success"> <p className="fs-12 fw-regular"><i class="bi bi-check"></i> {capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)}  ha accettato il
                                                preventivo il {props.item?.confirmContents[0].cdat.substring(0,10)}, {props.item?.confirmContents[0].cdat.substring(11,19)}</p></CustomBadge>
                                            </div>
                                        }
                                        {    !props.item?.confirmContents[0].accepted &&
                                            
                                            <div className="d-flex justify-content-end align-items-center mb-2 text-end">
                                                <CustomBadge variant="custom-badge-danger"> <p className="fs-12 fw-regular"><i class="bi bi-x"></i> {capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)} non  ha accettato il
                                                preventivo il {props.item?.confirmContents[0].cdat.substring(0,10)}, {props.item?.confirmContents[0].cdat.substring(11,19)}</p></CustomBadge>
                                            </div>
                                        }
                                    </div>
                                            </div> :
                                            <div></div>
                                        })                                
                                    }

                                        {/* MANCA UI PER CHI NON HA FIRMATO, ma vede chi ha firmato */}
                                        {/* MANCA UI PER CHI HA FIRMATO, e vede chi altro ha firmato */}
                                    
                                </div>

                                {/* GESTIONE FIRME */}
                                {props.item.signs.length > 0 ?
                                <>
                                    
                                    <div className="text-end">
                                    <CustomBadge variant="custom-badge-alert">  
                                        <p className="fs-12">
                                        <i class="bi bi-vector-pen me-1"></i>
                                            <span>Firmato da  {" "}</span>
                                        
                                            {  props.item.signs.map((el, index) => {
                                                return index == 0 ? <span> 
                                                    {props.chat.members?.[0]?.name} {props.chat.members?.[0]?.surname} , il {el.cdat.substring(0,10)}, {el.cdat.substring(11,19)}
                                                </span> : ""
                                                }
                                            )
                                            }
                                        </p>
                                    
                                    </CustomBadge>
                                    </div>

                                    <div className="notarizzato me-2">
                                     
                                            <i class="bi bi-shield-check me-1"></i>
                                            <span className="fs-12">Preventivo notarizzato</span>
                                    </div> 
                                </> :
                                <div className="tag-flxmsg">
                                   {!dataRequired.isVerifiedFull && <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 50, hide: 50 }}
                                                        overlay={
                                                          <Tooltip id="tooltip" className="exclamation-tooltip">
                                                            <p className="mb-3">Per poter firmare un messaggio, è necessario completare le seguenti informazioni:</p>

                                                            <p>1. <strong>Anagrafica Completa:</strong> <br/>Anagrafica / Face ID /Documenti</p>
                                                            <em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>
                                                          </Tooltip>
                                                        }>
                                                        <i class="bi  bi-info-circle me-2 fs-14"></i>
                                                      </OverlayTrigger>
                                                      }
                                    <ButtonRm
                                    disabled={dataRequired.isVerifiedFull? false : true}
                                        onClick={() => props.handleOpenFirma(props.item.id)}
                                        className="aggiungi-firma fs-14 text-decoration-underline"
                                        style={{cursor: "pointer"}}
                                        variant="text"
                                        

                                    >
                                         AGGIUNGI FIRMA
                                    </ButtonRm>
                                </div>
                                }


                          <AddContabilità 
                          showContabilita={showContabilita} 
                          handleCloseContabilita={handleCloseContabilita}  parsedContent={props.parsedContent} chat={props.chat}getOne={props.getOne} item={props.item} />
                          </div>
                          )}

    
    const TableComponent = ({ parsedContent, calculateRowTotal, calculateTotal, capitalizeFirstLetter }) => {
        const exportPDF = () => {
          const doc = new jsPDF();
          const logoWidth = 26;
            const logoHeight = 13;
            doc.addImage(logoPng, 'PNG', 15, 15, logoWidth, logoHeight); // Aggiungi il logo PNG
            doc.setFontSize(12); // Imposta la dimensione del testo dell'intestazione

      
          parsedContent.forEach((el) => {
            if (el.rows.length > 0 && el.rows.some(row => Object.values(row).some(value => value !== ''))) {
              // Titolo della sezione
              doc.setFontSize(12);
              doc.text(capitalizeFirstLetter(el.name), 14, doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 10 : 10);
      
              const rows = el.rows.map(row => [
                ...Object.values(row),
                calculateRowTotal(row) // Aggiungi il totale per ogni riga
              ]);
      
              const columns = [
                ...Object.keys(el.rows[0]).map(key => key),
                'Totale' // Colonna del totale per la riga
              ];
      
              doc.autoTable({
                head: [columns],
                body: rows,
                startY: doc.lastAutoTable.finalY ? doc.lastAutoTable.finalY + 15 : 15,
                theme: 'grid',
                styles: {
                  textColor: [0, 0, 0],
                  lineColor: [0, 0, 0],
                  lineWidth: 0.1,
                  cellPadding: 2,
                },
                headStyles: {
                  fillColor: [131, 17, 47],
                  textColor: [255, 255, 255],
                },
              });
            }
          });
      
          // Totale generale alla fine del documento
          if (doc.lastAutoTable) {
            doc.text(`Totale: € ${calculateTotal()}`, 14, doc.lastAutoTable.finalY + 10);
          }
      
          // Salva il PDF
          doc.save('exported-data.pdf');
        };
      
        return (
          <button onClick={exportPDF} className="btn round-icon-btn btn mx-3 me-2"><i class="bi bi-download fs-12"></i></button>
        );
      };
    




      function AddContabilità(props){

        console.log(props.parsedContent, "CONTENUTO DA MODIFICARE")

    
        const [sezioniCustom, setSezioniCustom] = useState([])
    
        async function getAll(){
            try {
                const response = await api().get(`${accounting_endpoint}`);
                if(response.data.content)
                setSezioniCustom(response.data.content)
            } catch (error) {
            console.log(error);
            }
        }
    
        useEffect(() => {
            getAll()
        }, [])    
    
        useEffect(() => {
        }, [sezioniCustom])
    
        const [key, setKey] = useState('mezzi');

    
    // Utilizza uno stato locale per gestire i dati modificati
    const [data, setData] = useState(props.parsedContent);


    // Aggiornare la funzione handleInputChange per modificare lo stato locale 'data'
    const handleInputChange = (sectionId, rowIndex, inputName, inputValue) => {
        const newData = [...data];
        const sectionIndex = newData.findIndex(s => s.id === sectionId);
        if (sectionIndex !== -1) {
            newData[sectionIndex].rows[rowIndex][inputName] = inputValue;
            setData(newData);
        }
    };

    // Aggiornare la funzione addNewRow per modificare lo stato locale 'data'
    const addNewRow = (sectionId) => {
        const newData = [...data];
        const sectionIndex = newData.findIndex(s => s.id === sectionId);
        if (sectionIndex !== -1) {
            const newRow = Object.keys(newData[sectionIndex].rows[0])
                .reduce((acc, key) => ({ ...acc, [key]: '' }), {});
            newData[sectionIndex].rows.push(newRow);
            setData(newData);
        }
    };

    // Aggiornare la funzione removeRow per modificare lo stato locale 'data'
    const removeRow = (sectionId, rowIndex) => {
        const newData = [...data];
        const sectionIndex = newData.findIndex(s => s.id === sectionId);
        if (sectionIndex !== -1 && newData[sectionIndex].rows.length > 1) {
            newData[sectionIndex].rows.splice(rowIndex, 1);
            setData(newData);
        }
    };


    async function putItem(body){
      const response = await api().put(
        `${chat_msg_endpoint}${props.item.id}`,
        body
      )

      await props.getOne(props.chat.id)
    
    }

    // Aggiornare la funzione handleSubmit per utilizzare lo stato locale 'data'
    function handleSubmit(e) {
        e.preventDefault();

        const bodyForCreate = {
            message: "",
            senderEmail: "fakeEmail",
            replyID: 1,
            attachments: [],
            tag: [],
            messageType: "preventivo",
            content: JSON.stringify(data).replace(/"/g, "'"),
            deleted: false,
            chatGroupID: props.chat?.id
        };

        putItem(bodyForCreate);
        props.handleCloseContabilita()
    }

    
    useEffect(() => {
      setData(props.parsedContent);
  }, [props.parsedContent]);
  
        console.log(data, "data", props.chat, props.item.id)
        return(
            <div>
                <Modal 
                  show={props.showContabilita} 
                  className={`p-5 contabilitàModal preventivo centered `}
                  style={{minHeight:"500px !important"}}
                  onHide={props.handleCloseContabilita}
                >
                  <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>
                        { "Modifica Preventivo"}
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="pt-4 pb-3 px-md-5 contabilitàModalBody">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => setKey(k)}
                      className="cont-tabs"
                    >
                       {/* Mappa ogni sezione per creare una tab per ciascuna */}
                    {props.parsedContent.map(section => (
                      <Tab key={section.id} eventKey={section.name} title={section.name} >
                        <Table bordered>
                          <thead>
                            <tr>
                              {/* Prendi le chiavi dal primo oggetto delle rows per creare le intestazioni */}
                              {section.rows.length > 0 && Object.keys(section.rows[0]).map((key, index) => (
                                <th key={index}>{key}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {/* Mappa ogni riga per creare le celle della tabella */}
                            {section.rows.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                  {Object.keys(row).map((key, cellIndex) => (
                                    <td key={cellIndex}>
                                      <input
                                        type="text"
                                        name={key}
                                        value={row[key]}
                                        onChange={(e) => handleInputChange(section.id, rowIndex, key, e.target.value)}
                                      />
                                    </td>
                                  ))}
                                  
                                  <td style={{border:"none"}}>
                                      <i className="bi bi-plus-circle-fill fs-16"
                                       onClick={() => addNewRow(section.id)}
                                      style={{cursor: "pointer"}}
                                      ></i>
                                  </td>

                                  {section.rows.length > 1 && (
                                    <td style={{border:"none"}}>
                                        <i class="ms-2 bi bi-trash"
                                        onClick={() => removeRow(section.id, rowIndex)}
                                        style={{cursor: "pointer"}}></i>
                                    </td>
                                  )}
                                  
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab>
                    ))}
                    </Tabs>

                  </Modal.Body>

                  <Modal.Footer
                    className="d-flex px-md-5 pb-md-4"
                    style={{ borderTop: "none" }}
                >
                    <ButtonRm variant="tertiary" onClick={props.handleCloseContabilita}>
                    Annulla
                    </ButtonRm>
                    <ButtonRm variant="primary" onClick={(e) => handleSubmit(e)}>
                    Salva
                    </ButtonRm>
                </Modal.Footer>
                  </Modal>
            </div>
        )
    }