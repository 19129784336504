import React from 'react';
import "../../assets/css/Tips.css"

const TipsPro = ({ children, variant, name="Info" }) => {
  let iconClass = '';

  switch (variant) {
    case 'suggestion':
      iconClass = 'bi bi-lightbulb-fill'; 
      break;
    case 'info':
      iconClass = 'bi bi-exclamation-triangle-fill'; 
      break;
    case 'automatization':
      iconClass = 'bi bi-lightning-fill'; 
      break;
    default:
  }

  return (
    <div className={`tips ${variant} tips-pro`}>
      <div>
        <i className={iconClass}></i>
        <p>{name}</p>
      </div>
      {children}
    </div>
  );
};

export default TipsPro;
