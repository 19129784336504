import React, { useState } from "react";
import "../../../assets/css/Membri.css";
import AddCross from "../../../assets/images/add_Cross.svg";
import { Link } from "react-router-dom";

const NewownerBtn = (props) => {
	const [show, setShow] = useState(false);
	const [fullscreen, setFullscreen] = useState(true);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
		props.setShow(true);
	};

	return (
		<>
			<div className="">
				<div className="mobile-container d-flex flex-column mt-md-4 row">
					<div className="d-flex col"></div>
				</div>

				<div className="d-flex align-item-center mt-25 newownerBtn-div justify-content-between ">
					<div
						className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden"
						style={{ width: "30%", maxWidth: "300px", minWidth: "150px" }}>
						<i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
						<input
							type="text"
							name=""
							placeholder="Cerca"
							className="flex-grow-1 border-0 w-100 fs-14"
							onChange={(e) => props.setSearchName(e.target.value)}
						/>
					</div>
					<Link to="/conduttori/create">
						<div className="flex-shrink-0 ">
							<img loading="lazy" src={AddCross} onClick={handleShow}></img>
						</div>
					</Link>
				</div>
			</div>
		</>
	);
};

export default NewownerBtn;
