import React, { useState, useEffect } from "react";
import "../../../../assets/css/Membri.css";
import cardimage from "../../../../assets/images/gestione-empty-state.svg";
import { BankCard, BankCardItem } from "../../../BankCard";
import Alert from 'react-bootstrap/Alert';
import api, { owner_supplier_assignment_endpoint, unit_endpoint } from "../../../../api/api";

import {  BsPrinter } from "react-icons/bs";
import ExportButton from "../../Fornitori/ExportButton";
import translationMapping from "../../../UnitàImmobiliariStabilli/UnitàImmobiliari/mapping";

export default function UnitaV(props) {
  const [data, setData] = useState([]);

  //CHECK UNITS OF THIS OWNER
  //HANDLE GET
  async function getData() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      setData(response.data.content);
      console.log(response.data.content)
    } catch (err) {
      console.log(err);
    }
  }

  const unitsOfThisOwner = data.filter(unit => unit.owners.some(owner => owner.id === props.formData.id));

  useEffect(() => {
      getData()
  }, [])

  console.log(data, unitsOfThisOwner)

  return (
    <div className="row">
      <div className="row w-100">
        <div className="form-item-align-box-white d-block pt-2 pb-5">
          <Alert variant="warning" className="d-flex justify-content-start align-items-center mt-4" style={{height:"44px"}}>
          <div className="d-flex ">
            <i class="bi bi-info-circle me-2"></i> 
            In questa sezione convergeranno in automatico le unità alle quali è associato questo proprietario
          </div>
          </Alert>
          {unitsOfThisOwner?.length>0 && <div className="d-flex mb-3 justify-content-end mt-3 me-3">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					
					<ExportButton data={unitsOfThisOwner} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>}
          {unitsOfThisOwner.length === 0 ? (
            <div className="col-12 my-5">
              <div className="mx-auto d-table text-center">
                <div className="image-user mx-auto d-table w-130 fw-100">
                  <img loading="lazy" src={cardimage} alt="user-key" />
                </div>
                <p className="fs-18 text-secondary text-center">
                  Nessuna unità associata a questo utente
                </p>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-wrap justify-content-around">

              {unitsOfThisOwner.map((unit, index) => (
                <BankCard 
                    name={unit.name}         
                    color="#5998bc" 
                    index={index}
                    deleteVisible={false}
                    >
                      <BankCardItem property="INDIRIZZO" value={unit?.address}/>
                      <BankCardItem property="CIVICO" value={unit?.houseNumber}/>
                      {unit?.internal && <BankCardItem property="INTERNO" value={unit?.internal}/>}
                      <BankCardItem property="CITTA'" value={unit?.municipality}/>
                      <BankCardItem property="CATEGORIA" value={unit?.category}/>

                  </BankCard>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
