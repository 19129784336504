import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProvince, getMunicipality } from "../../../actions/addressAction";
import { type } from "../constant";

import CustomBadge from "../../CustomBadge";


const TypologyE = (props) => {
	useEffect(() => {
		props.getProvince();
	}, []);

	useEffect(() => {
		props.getMunicipality(props.province[0]);
	}, [props.province]);

	const handleProvince = (e) => {
		onChange(e);
		props.getMunicipality(e.target.value);
	};

	const [data, setData] = useState({});

	const init = () => {
		setData({
			unitName: props.formData.unitName,
			identifier: props.formData.identifier,
			typology: props.formData.typology,
			province: props.formData.province,
			municipality: props.formData.municipality,
			address: props.formData.address,
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (props.saveState===true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const handleUnit = (e) => {
		onChange(e);
		props.setUnitsIndex(e.target.selectedIndex - 1);
	};

	return (
		<div className="form-item-align-box d-block">
			<fieldset disabled={props.editMode ? false : true}>
				<div className="label-heading mb-3 d-block w-100">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">CATEGORIA</h1>
					</div>
				</div>
				<label className="f-label fs-16 d-flex align-items-center
mb-2">
					Se hai già inserito nel gestionale l’immobile di cui vuoi fare la
					valutazione, selezionalo nel campo sottostante e/o inserisci un
					identificativo per questa valutazione
				</label>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Unità immobiliare
							</label>
							<select
								name="unitName"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
								value={data.unitName}
								onChange={handleUnit}>
								<option key={-1} value="">
									Seleziona
								</option>
								{props.units?.map((item, key) => {
									return (
										<option key={key} value={item.name}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Identificativo*
							</label>
							<input
								type="text"
								name="identifier"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.identifier}
							/>
						</div>
					</div>
				</div>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">TIPOLOGIA</h1>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Tipologia
							</label>
							<select
								name="typology"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
								value={data.typology}
								onChange={(e) => {
									onChange(e);
								}}>
								{type.map((menu, index) => {
									return (
										<option key={index} value={menu}>
											{menu}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Provincia
							</label>
							<select
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
								name="province"
								value={data.province}
								onChange={handleProvince}>
								{props.province.map((item, key) => {
									return (
										<option key={key} value={item}>
											{item}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Comune
							</label>
							<select
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
								name="municipality"
								value={data.municipality}
								onChange={onChange}>
								{props.municipality?.map((item, key) => {
									return (
										<option key={key} value={item}>
											{item}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Quartiere
							</label>
							<input
								type="text"
								name="address"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								/* onChange={(e) => {
									onChange(e);
								}}
								value={data.address} */
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Indirizzo
							</label>
							<input
								type="text"
								name="address"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									onChange(e);
								}}
								value={data.address}
							/>
						</div>
					</div>
				</div>
			</fieldset>
		</div>
	);
};

const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
});
export default connect(mapStateToProps, { getProvince, getMunicipality })(
	TypologyE
);
