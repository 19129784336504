import React, { useState } from "react";
import LeftPanel from "../../LeftPanel";
import "../../../assets/css/RecoverPassword.css";

import ReturnIcon from "../../../assets/images/returnIcon.svg";
import ErrorIcon from "../../../assets/images/input_error.svg";

import { useNavigate } from "react-router";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RecoverPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const onChange = async (e) => {
    setEmail(e.target.value);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('dddd');
    formData.email = email;
    setFormData(formData);
    axios
      .post(
        process.env.REACT_APP_REQUEST_URL + "api/v1/auth/forgetPassword",
        formData
      )
      .then((res) => {
        if (res.data.success === true) {
         /*  toast.success("Success Notification !", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
          }); */
        } else {
        }
      })
      .catch((err) => {
        // toast
       /*  toast.error("error!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        }); */
      });
    if (!isValidEmail(email)) {
      setError(true);
    } else {
      setError(false);
      navigate("/verify", { state: formData });
      // navigate("/verify" ,formData);
    }
  };

  const returnLogin = () => {
    navigate("/");
  };

  return (
    <div
      className="d-flex recover-div"
      style={{ height: "100vh", background: "#fff" }}
    >
      <LeftPanel />
      <ToastContainer />

      <div className="m-auto">
        <div className="return-login" onClick={() => returnLogin()}>
          <img
            src={ReturnIcon}
            alt="return"
            style={{ marginRight: "17.6px" }}
          ></img>
          Torna al login
        </div>
        <div className="recover-text">Hai dimenticato la password?</div>
        <div className="recover-text2">
          Inserisci il tuo indirizzo e-mail utilizzato per la registrazione.
        </div>
        <div className="recover-text2">
          Ti invieremo un codice per reimpostare la password.
        </div>
        <div className="recover-title">Reimposta la password</div>
        {error && (
          <div className="emailtypeError-div">
            <img
              src={ErrorIcon}
              alt="input_error"
              style={{ marginRight: "22px", marginLeft: "32px" }}
            ></img>
            L’email inserita non è corretta
          </div>
        )}
        <div className="recover-subtitle">Email</div>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            placeholder="Inserisci la tua email"
            name="email"
            id={error ? "input_error" : ""}
            value={email}
            onChange={onChange}
            className="form-control password-form"
          />
          <input
            type="submit"
            className="recover-btn"
            value="Reimposta Password"
          />
        </form>
      </div>
    </div>
  );
}
