import {useEffect, useState, useMemo} from 'react'

import {Row, Col} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import TopHeading from "../../../TopHeading"
import AddBtn from "../../../AddBtn"
import EmptyStateContainer from "../../../EmptyStateContainer";
import '../../../../assets/css/ImpostazioniChat.css'
import emptyState from "../../../../assets/images/tag.svg"
import SearchInput from "../../../SearchInput"
import ButtonRm from '../../../ButtonRm';
import edit from "../../../../assets/images/edit-btn.svg"
import deleteIcon from "../../../../assets/images/delete-btn.svg"

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Table from 'react-bootstrap/Table';
import { array } from 'prop-types';

import SearchIllustration from "../../../../assets/images/search-illustration.svg";

import api from "../../../../api/api"
import {accounting_endpoint} from "../../../../api/api"

const defaultSections = [
    {   
        id:"Materiali",
        name: 'Materiali',
        content: "", 
        customFields: [], 
        default: true
    },
    {
        id: "Persone",
        content: "", 
        customFields: [], 
        name: 'Persone',
        default: true
        
    },
    {
        id:"Mezzi",
        name: 'Mezzi',
        default: true,
        content: "", 
        customFields: [], 
    }    
] 

function Contabilità(){

    const [sezioniCustom, setSezioniCustom] = useState([])

    const [sezioni, setSezioni] = useState([...defaultSections])

    useEffect(() => {
    setSezioni(prev => {
        // Filtra le sezioni personalizzate che non sono già presenti nell'array sezioni
        const nuoveSezioniCustom = sezioniCustom.filter(sezioneCustom => {
            return !prev.some(sezione => sezione.id === sezioneCustom.id);
        });

        // Unisci le nuove sezioni personalizzate con l'array sezioni esistente
        return [...prev, ...nuoveSezioniCustom];
    });
}, [sezioniCustom]);

    const [sectionName, setSectionName]= useState("")
    const [searchTerm, setSearchTerm] = useState("");

    let searchedList = sezioni.filter((sezione) => sezione.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()))

    //MODAL CREATION
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //MODAL TABLE
    const [showTableModal, setHandleShowTableModal] = useState(false);
    const handleCloseTableModal = () => {
        setHandleShowTableModal(false)
        setEditedItemId("")
        setEditedItem({})
    };
    const handleShowTableModal = (id) => { 
        setHandleShowTableModal(true)
        setEditedItemId(id)
    };

    //HANDLE NEW SECTION DATA
    function handleSectionName(e) {
        setSectionName( e.target.value )
    }

    //EDITED ITEM

    const [editedItemId, setEditedItemId] = useState("")
    const [editedItem, setEditedItem] = useState(null)
    // const [sectionNameEdit, setSectionNameEdit] = useState("")
    // function handleEditSectionName(e){
    //     setSectionNameEdit(e.target.value)
    // }

    

    useEffect(() => {
        if(editedItemId){
            const el = sezioni.find(item => item.id == editedItemId)
            setEditedItem(el)
        }
    }, [editedItemId])

    


    const bodyForCreate = {
            name: sectionName,
            content: "", 
            customFields: [],         
    }

    //HANDLE CREATION
    function handleSubmit(e) {
        e.preventDefault()
        if(sectionName){
            postItem(bodyForCreate)
        }
        handleClose()
        setSectionName("")
    }


    //HANDLE DELETE
    async function onDelete(id) {
        try {
        await api().delete(
            `${accounting_endpoint}${id}`
        );
        await getAll();
        } catch (error) {
        console.log(error);
        }
    }

    function handleDelete(id){
        onDelete(id )
    }

    //TOOLTIP
    const infoHover = "Le sezioni della contabilità rappresentano le voci che potrai andare a compilare quando crei il preventivo di un servizio/lavoro, di default ogni preventivo avrà le voci Persone, Materiali e Mezzi, alla quale potrai aggiungere ulteriori voci in base alle tue esigenze."

    //SEZIONI TABELLA & EDIT SECTION

    const tabSection =
        [{id:1, name:"Nome", default: true},
        {id:2, name:"Unità di misura", default: true},
        {id:3, name:"Costo unitario", default: true},
        {id:4, name:"Quantità", default: true}]
    

    function handleTabName(e, id){
        let newArr = editedItem?.customFields?.map((sect, i) => {
            return i == id ?
                    e.target.value:
                    sect
        })

        setEditedItem(prev => ({...prev, customFields: newArr}))
    }

    //handle edit section name
    function handleEditName(e){
        setEditedItem(prev => ({...prev, name: e.target.value}))
    }

    function addNewSection(){
        setEditedItem(prev => ({...prev, customFields: [...prev.customFields, ""]}))
    }

    function deleteTabSection(id){
        const newArr = editedItem?.customFields?.filter((item, i)=> i != id)
        setEditedItem(prev => ({...prev, customFields: newArr}))
    }

    function sortAZ(){
        const sortedArrAZ = [...sezioni].sort((a, b) => {
            let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        setSezioni(sortedArrAZ)
    }

    function sortZA(){
        const sortedArrZA = [...sezioni].sort((a, b) => {
            let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();
        
            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return 0;
        });

        setSezioni(sortedArrZA  )
    }

    /* API CALL */
    //GET
    async function getAll(){
        try {
            const response = await api().get(`${accounting_endpoint}`);
            setSezioniCustom(response.data.content)
        } catch (error) {
        console.log(error);
        }
    }

     //CREATE
    async function postItem(data){
        try {
            await api().post(`${accounting_endpoint}`, data);
            await getAll()
        } catch (error) {
        console.log(error);
        }
    }

    //EDIT
    async function editItem() {
        if(editedItem.default){
            let newArr = sezioni.map(el => {
                return el.id == editedItemId ?
                editedItem:
                el
            })

        setSezioni(newArr)

        } else {
            try {
                await api().put(`${accounting_endpoint}${editedItemId}`, editedItem);
                await getAll()
            } catch (error) {
                console.log(error);
            }
        }
        
    }

    async function onEdit(){
        if(editedItem.name){
            await editItem()
            handleCloseTableModal()
        }
    }



    useEffect(() => {
        getAll()
    }, [])



    return(
    <>
        <Row>
            <Col>
                <TopHeading SmallHeading="" back_modal={false}/>
            </Col>
        </Row>

        <Row className=" d-flex flex-column mt-md-4">
            <Col>
            <h2 className="fw-bold text-center">Contabilità</h2>
            </Col>
            <Col className="d-flex mt-3 justify-content-md-between">
            <AddBtn tooltip="Nuova sezione contabilità" className="me-3 me-md-0 order-md-1"
                    onClick={handleShow}
                    dataRequired={true}
                    />
            <SearchInput
                className="order-md-0"
                setSearch={(term) => {
                setSearchTerm(term);
                }}
            />
            </Col>
        </Row>

        <Row className='my-3 flex-column'>
            <Col className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                    <p className='me-2'>Sezioni della contabilità</p>
                    <OverlayTrigger
                    placement="top"
                    delay={{ show: 600, hide: 300 }}
                    overlay={
                        <Tooltip className='tooltipContabilita'>{infoHover}</Tooltip>
                        }
                        >
                        <i class="bi bi-info-circle"></i>
                    </OverlayTrigger>
                </div>
                <div className="d-flex aling-items-center me-2">
                    {/* <i className="bi bi-arrow-down-up"></i>
                    <p className="fs-14 ms-1 mt-1">Ordina</p> */}
                    <div className="user-dropdown">
                        <div className="sort d-flex aling-items-center">
                            <i className="bi bi-arrow-down-up"></i>
                            <p className="fs-14 ms-1">Ordina</p>
                        </div>
                        <div className="user-dropdown-content">
                            <div className="fs-16 fw-bold">Ordina</div>
                            <div className="fs-14 mt-3 mb-1" onClick={sortAZ} style={{cursor:"pointer"}}>Dalla A alla Z</div>
                            <div className="fs-14"  style={{cursor:"pointer"}} onClick={sortZA}>Dalla Z alla A</div>
                        </div>
                    </div>
                </div>
            </Col>
            
            <Col>
                {
                    searchedList.length > 0 &&
                    <div className='my-3 d-flex flex-column'>
                        {
                            searchedList.map((section, i) => <ContabilitàCard key={i} section={section} handleDelete={handleDelete} handleShowTableModal={handleShowTableModal } />) 
                        }
                    </div>
                }

            {   
                searchTerm && searchedList.length===0 &&
                <EmptyStateContainer 
                content="Nessuna corrispondenza trovata"
                img={SearchIllustration} />                  
            }
            </Col>        
        </Row>

        {/*CREATION MODAL*/}
        <Modal show={show} onHide={handleClose} className="p-5" centered >
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className=" pt-md-5 px-md-5"
          >
            <Modal.Title>Nuova sezione della contabilità</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 px-md-5">
            <Form>
              <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                <Form.Label>Nome Sezione</Form.Label>
                <input
                  type="text"
                  placeholder="Inserisci il nome della sezione"
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem" 
                  }}
                  onChange={handleSectionName}
                  value={sectionName}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer
            className="d-flex flex-column  px-md-5 pb-md-4"
            style={{ borderTop: "none" }}
          >
            <ButtonRm variant="primary" onClick={handleSubmit}>
              Salva
            </ButtonRm>
            <ButtonRm variant="tertiary" onClick={handleClose}>
              Annulla
            </ButtonRm>
          </Modal.Footer>
        </Modal>

        {/*TABLE MODAL*/}
        <Modal show={showTableModal} onHide={handleCloseTableModal} className="p-5 tableModal" centered >
            <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className=" pt-md-5 px-md-5"
            >
                <Modal.Title>Modifica sezione della contabilità</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3 px-md-5">
                <Form className="mb-3">
                    <Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
                        <Form.Label>Nome Sezione</Form.Label>
                        <input
                            type="text"
                            placeholder="Modifica il nome della sezione"
                            style={{ height: "40px",  
                            borderRadius: "6px",
                            border: "1px solid #BDBDBD",
                            padding: "0 .5rem" 
                            }}
                            onChange={handleEditName}
                            value={editedItem?.name}
                        />
                    </Form.Group>
                </Form>
                <div className='contabilita-table'>
                    <Table bordered >
                        <thead>
                            <tr>
                                {
                                    tabSection.length > 0 &&
                                    tabSection.map(section => <th key={section.id}>
                                    {section.default?
                                        section.name:
                                        <span className='d-flex aling-items-center'>
                                            <input
                                            type="text"
                                            style={{ height: "25px",
                                            width:"100px",  
                                            borderRadius: "6px",
                                            border: "1px solid #BDBDBD",
                                            background: "white",
                                            padding: "0 .5rem" 
                                            }}
                                            onChange={(e) => {handleTabName(e, section.id)}}
                                            value={section.name}
                                            />
                                            <i className="bi bi-trash ms-1 mt-1"
                                            style={{cursor: "pointer"}}
                                            onClick={() => {deleteTabSection(section.id)}}></i>
                                        </span>
                                        
                                    }
                                    </th>)
                                    
                                }
                                {/* custom fields */}
                                {
                                    editedItem?.customFields?.length > 0 &&
                                    editedItem?.customFields?.map((section,i) => <th key={i}>
                                 
                                        <span className='d-flex aling-items-center'>
                                            <input
                                            type="text"
                                            style={{ height: "25px",
                                            width:"100px",  
                                            borderRadius: "6px",
                                            border: "1px solid #BDBDBD",
                                            background: "white",
                                            padding: "0 .5rem" 
                                            }}
                                            onChange={(e) => {handleTabName(e, i)}}
                                            value={section}
                                            />
                                            <i className="bi bi-trash ms-1 mt-1"
                                            style={{cursor: "pointer"}}
                                            onClick={() => {deleteTabSection(i)}}></i>
                                        </span>
                                    </th>)
                                    
                                }
                                <th style={{border:"none"}}>
                                    <i className="bi bi-plus-circle-fill fs-16"
                                    style={{cursor: "pointer"}}
                                    onClick={addNewSection}></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {
                                    tabSection.length > 0 &&
                                    tabSection.map(section => <td key={section.id}></td>)                                
                                }
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer
            className="d-flex px-md-5 pb-md-4"
            style={{ borderTop: "none" }}
            >
                <ButtonRm variant="primary" onClick={onEdit} disabled={editedItem?.name? false : true}>
                Salva
                </ButtonRm>
                <ButtonRm variant="tertiary" onClick={handleCloseTableModal}>
                Annulla
                </ButtonRm>
            </Modal.Footer>
        </Modal>
    </>
    )
}
export default Contabilità


function ContabilitàCard(props){
    console.log(props.section.id)
    return(
        <div className='checklist-card d-flex align-items-center justify-content-between'>
            <p>{props.section.name}</p>
            <div className='d-flex'>
                <img loading="lazy" src={edit} className="mx-2" onClick={ () => props.handleShowTableModal(props.section.id) }/>
                {!props.section.default && <img loading="lazy" src={deleteIcon} onClick={(e) => {props.handleDelete(props.section.id)}}/>}
            </div>          
        </div>
    )
}