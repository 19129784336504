import {useEffect, useState} from 'react'
import { useNavigate, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import TopHeading from '../../TopHeading';
import ButtonRm from '../..//ButtonRm'
import {Row, Col} from "react-bootstrap"
import Form from 'react-bootstrap/Form'

import CustomToggle from "../../CustomToggle";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
/* import { addDeadline, fetchDeadlineaddDeadline, removeDeadlineaddDeadline, editDeadlineaddDeadline } from "../../../actions/deadline";
 */
import { add, addDays,addMonths, addWeeks, addYears, isAfter } from 'date-fns';
import { parseISO } from 'date-fns';

import DocumentiModal from './CreateDocumenti';



const todayDate = new Date()
const date = todayDate.toISOString().substring(0,16)



const eventData = {
    title: "",
    section: "",
    startDate: date,
    endDate:	date,
    every:	"",
    recurrenceType:	"",
    toDate:	undefined,
    notification: "",
    sharing: "",
    note: ""
    //manca campo per giorni di preavviso => type: number
}

const schema = yup.object().shape({
    title: yup.string().required("Inserisci un titolo per questo evento"),
    section: yup.string(),
    startDate: yup.date("Seleziona la data di inizio").required(), //mettere come valore iniziale data di oggi per tutte le date
    endDate:	yup.date("Seleziona la data di inizio").required(),
    every:	yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
    recurrenceType:	yup.string(),
    toDate:	yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    notification: yup.string(),
    sharing: yup.string(),
    note: yup.string()
    //aggiungere voci api giorni di preavviso

})

function NuovaNota() {
    const [formData, setFormData] = useState(eventData)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleModal = () => {
        setShow(true);
    };


    //HANDLE ACCORDION
    const [activeKey, setActiveKey] = useState("")

    

    return (
        <>
            <Row>
                <Col>
                    <TopHeading SmallHeading="" />
                </Col>
            </Row>

            <Row className="mobile-container d-flex flex-column mt-md-4">
                <Col className="d-flex">
                    <h2 className="fw-bold">Nuova Prima Nota</h2>
                </Col>
            </Row>

            <Row>
                <DocumentiModal />
            </Row>

            


            
        </>

    )
}

export default NuovaNota