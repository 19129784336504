import React from "react";
import { useState, useEffect, useRef } from "react";
import { BsFilter } from "react-icons/bs";
import PrintDownloadComponent from "../../PrintDownloadComponent";
import Table from "react-bootstrap/Table";
import FolderImg from "../../../assets/images/allegati-ph.svg";
import Alert from 'react-bootstrap/Alert';


//ADESSO E' ELENCHI/LAVORI
const Note = (props) => {
 
  return (
    <div className="form-item-align-box-white d-block">
     <Alert variant="warning" className="d-flex justify-content-start align-items-center fs-14" style={{height:"50px"}}>
          <div className="d-flex">
            <i class="bi bi-info-circle me-2"></i> 
            {props.alert}
          </div>
          </Alert>
    <div className="edit-content-container ">
				<div className="unit-empty-img">
              <img loading="lazy" src={props.img} alt="" />
              <span className="img-comment fs-16">
                {props.placeholder}
              </span>
				</div>
		</div>
  </ div>
  );
};

export default Note;
