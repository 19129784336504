import { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

import api, { activity_endpoint, real_estate_management_endpoint, stabili_endpoint, supplier_endpoint, owner_endpoint, conductor_endpoint, unit_endpoint, BASE_URL  } from "../../../api/api";

import { Row, Col } from "react-bootstrap";

import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import ButtonRm from "../../ButtonRm";

import { MultiSelect } from "react-multi-select-component";

import Form from "react-bootstrap/Form";

import { NewItem } from "../Chat/Participants";

import {
  MapContainer,
  Marker,
  ImageOverlay,
  Rectangle,
  FeatureGroup,
  Circle,
  Pane,
} from "react-leaflet";

import L from "leaflet";

import UploadBtn from "../../Documenti/Modelli/UploadBtn";

import "leaflet-draw/dist/leaflet.draw.css";

import Planimetry from "./Planimetry";

import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import CustomBadge from "../../CustomBadge";

export default function EditAttività() {
  //MODAL: OPEN PLANIMETRY MODAL
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //HANDLE ATTACHMENTS
  const [files, setFiles] = useState([]);

  //PLANIMETRY
  const [formData, setFormData] = useState({});
  const [currentPlanimetryId, setCurrentPlanimetryId] = useState(
    formData.planimetries?.length > 0 ? formData?.planimetries?.[0]?.id : null
  )
  const [currentPlanimetry, setCurrentPlanimetry] = useState(
    formData?.planimetries?.length > 0 ? formData.planimetries[0] : {}
  )


 
  const bounds = [
    [0, 0],
    [512, 512],
  ];
  const navigate = useNavigate();

    /* HANDLE CURRENT PLANIMETRY */
    const handleCurrentPlanimetry = () => {
      let selectedPlan = formData.planimetries?.find(
        (planimetry) => planimetry.id === currentPlanimetryId
      );
      setCurrentPlanimetry(selectedPlan);
    };
  
    const handleCurrentPlanimetryId = (e) => {
      const { value } = e.target;
      setCurrentPlanimetryId(value);
    };
  
    useEffect(() => {
      if (formData.planimetries?.length > 0 ) {
          setCurrentPlanimetryId(formData?.planimetries?.[0]?.id)
      }
    }, [formData.planimetries]);
  
    useEffect(() => {
      if (currentPlanimetryId) {
        handleCurrentPlanimetry();
      }
    }, [currentPlanimetryId, formData.planimetries]);

  //EDIT
  async function postItem(data) {
    try {
      await api().put(`${activity_endpoint}`, data);
  
      // GESTIONE ALLEGATI
      let formDataToSend = new FormData();
  
      files.forEach((file) => {
        const blob = new Blob([file]);
        formDataToSend.append("parts", blob, file.name);
      });
  
      let sentUpload = formDataToSend.get("parts") !== null;
  
      // INVIA ALLEGATI
      if (sentUpload) {
        await api().put(`${activity_endpoint}upload/${activityId}`, formDataToSend);
      }
    } catch (error) {
      console.log(error);
    }
  }
  

  /* REACT LEAFLET DRAW */

  function onSaveMarkers(markers) {
    handleClose();
    setFormData((prev) => {
      return {
        ...prev,
        planimetries: prev.planimetries.map((planimetry) => {
          return planimetry.id===currentPlanimetryId
            ? { ...planimetry, markers }
            : planimetry;
        }),
      };
    });
  }

  const rectangles =
    currentPlanimetry && currentPlanimetry.markers
      ? {
          ...currentPlanimetry.markers,
          features: currentPlanimetry.markers?.features
            ? currentPlanimetry.markers.features.filter(
                (item) => item.geometry.type==="Polygon"
              )
            : [],
        }
      : {};

  const points =
    currentPlanimetry && currentPlanimetry.markers
      ? {
          ...currentPlanimetry.markers,
          features: currentPlanimetry.markers?.features
            ? currentPlanimetry.markers.features.filter(
                (item) => item.geometry.type==="Point"
              )
            : [],
        }
      : {};

  /* FORM DATA */

  function handleSubmit(e) {
    e.preventDefault();
    let strdata;
    let formCopy = {...formData}
    delete formCopy.cdat
    delete formCopy.udat
    delete formCopy.chatGroup


    if (formData.name && formData.realEstateManagementID && formData.startDate && formData.expirationDate && new Date(formData.startDate) < new Date(formData.expirationDate)) {
      strdata = {
        ...formCopy,
        planimetries: formData.planimetries.length > 0 ? formData.planimetries.map(el => JSON.stringify(el).replace(/"/g, "'")) : [],
        members: formData.members.length >0 ? formData.members.map(obj => JSON.stringify(obj)) : []

      };
      postItem(strdata);
      console.log(strdata);
      //GO TO LIST ACTIVITY
      //ADD INPUT VALIDATION
    }

    setFormData({});
    navigate(-1);
  }

  //handle field PARTECIPANTI
  const handleMembers = (newValue, actionMeta) => {
    //const values = newValue.map(value => value.value)

    setFormData((prevData) => {
      return { ...prevData, members: newValue };
    });
  };

  //handle whole form
  function handleFormChange(e) {
    const { name, value } = e.target;

    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
  }


  const { activityId } = useParams();

  //GET ONE

  //get single item
  async function getOne(id) {
    try {
      const response = await api().get(`${activity_endpoint}${id}`);
      if (response.data) {
        response.data.planimetries =  response.data.planimetries.map(item => JSON.parse(item.replace(/'/g, '"'))
        );
        // response.data.members = JSON.parse(
        //   response.data.members.replace(/'/g, '"')
        // );
        response.data.members = response.data.members?.length > 0 ? response.data.members.map(str => eval(`(${str})`)) : []

      }
      setFormData(response.data);
      setCurrentPlanimetry(response.data.planimetries?.[0]);
      setCurrentPlanimetryId(response.data.planimetries?.[0].id);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOne(activityId);
  }, []);


  //THESE WILL BE OPTIONS for units/stabili
  const [listaUnità, setListaUnità] = useState([]);

    //CAMPO IN CUI SALAVRE I DATI DELL UNITA STABILE SEZIONATE PER CAPIRNE IL TIPO
    const [itemSelected, setItemSelected] = useState({})


   //get details (like list of unit) associated to the stabile selected
   const [stabileInfo, setStabileInfo] = useState({})


  async function getUnitsList() {
    try {
      const response = await api().get(`${real_estate_management_endpoint}`);
      if (response.data) {
        setListaUnità(response.data?.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getUnit(id) {
    try {
      const response = await api().get(
        `${real_estate_management_endpoint}${id}`
      );
      if (response.data) {
        setFormData((prev) => {
          return {
            ...prev,
            unitName: response.data?.name,
          };
        });
        setItemSelected(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }



  async function getStabileDetails(id){
    try {
      const response = await api().get(`${stabili_endpoint}${id}` )
      if (response.data) {
        setStabileInfo(response.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUnitsList();
  }, []);

  useEffect(() => {
    if (formData?.realEstateManagementID) {
      getUnit(formData.realEstateManagementID);
    }

  }, [formData?.realEstateManagementID]);

  useEffect(() => {
      if(itemSelected.realEstateType == "stabile"){
        getStabileDetails(parseInt(itemSelected.unitData))
      } else {
        setStabileInfo({})
      }

  }, [itemSelected]);


  const [unitPlanimetries, setUnitPlanimetries] = useState([])

  //FETCH PLANIMETRIES SOLO SE UNITA'
  async function getPlanimetries(id){
    try {
      const response = await api().get(`${unit_endpoint}${id}` )
      if (response.data) {
        setUnitPlanimetries(response.data.planimetry)
      }
    } catch (error) {
      console.log(error);
    }
  }

  //OTTIENI IMMAGE URL DI OGNI PLANIMETRIA, POI CHIAMA
  //CREATE OBJ FUNCTION CHE CREA UN OGGETTO PER OGNI IMG
  //E SETTA FORMDATA.PLANIMETRIES
  const fetchImages = async (fileNames) => {
    const imageUrls = [];
  
    for (const fileName of fileNames) {
      const fileUrl = `${BASE_URL}api/v1/unit/download_planimetry/${itemSelected.unitID}/${fileName}`;
      try {
        const response = await axios.get(fileUrl, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token}`,
            "Content-Type": "application/json",
          },
        });


          //CODICE PER BLOB IMG
          const imageUrl = URL.createObjectURL(response.data);
      imageUrls.push(imageUrl);
  
          if (imageUrls.length === fileNames.length) {
            createPlanimetryObject(imageUrls);
          }
        
      } catch (error) {
        console.error(`Failed to fetch image: ${fileName}`, error);
        imageUrls.push(null); // or any fallback value if image fetching fails
  
        if (imageUrls.length === fileNames.length) {
          createPlanimetryObject(imageUrls);
        }
      }
    }
  };
  
  //SOSTITUISCI IMAGE CON LE IMMAGINI PRESE DA UNITA
  const createPlanimetryObject = (urls) => {
    const updatedPlanimetries = formData.planimetries.map((planimetry, index) => {
      if (index < urls.length) {
        return {
          ...planimetry,
          image: urls[index],
        };
      }
      return planimetry;
    });
  
    setFormData((prev) => ({
      ...prev,
      planimetries: updatedPlanimetries,
    }));
  };
  


  //IMAGE SRC DI OGNI PLANIMETRIE
  const [planimetriesImages, setPlanimetriesImages ] = useState([])
  
  useEffect(() => {
    const fetchPlanimetriesImages = async () => {
      if (unitPlanimetries.length > 0) {
        const images = await fetchImages(unitPlanimetries);
        setPlanimetriesImages(images);
      }
    };
  
    fetchPlanimetriesImages();
  }, [unitPlanimetries]);
  
  useEffect(() => {
    if(itemSelected.unitID){
      getPlanimetries(itemSelected.unitID)
    }
    
  }, [itemSelected])

  //FETCH OWNERS E CONDUCTORS E SUPPLIERS DATA FROM API

	const [realOwners, setRealOwners] = useState([]);
	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setRealOwners(response.data.content);
				console.log("owner", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [realConductors, setRealConductors] = useState([]);
	async function getConductors() {
		try {
			const response = await api().get(`${conductor_endpoint}`);
			if (response.data) {
				setRealConductors(response.data.content);
				console.log("conductor", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

  const [realSuppliers, setRealSuppliers] = useState([]);
	async function getSuppliers() {
		try {
			const response = await api().get(`${supplier_endpoint}`);
			if (response.data) {
				setRealSuppliers(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOwners();
		getConductors();
    getSuppliers()
	}, []);

  //CREA ARRAY CON TUTTI I MEMBRI

  const optionConductors = realConductors.map(item => ({ value: item.id, label: `${item.surname} ${item.name} - Conduttore` }))
  const optionOwners = realOwners.map(item => ({ value: item.id, label: `${item.surname} ${item.name} - Proprietario` }))
  const optionSuppliers = realSuppliers.map(item => ({ value: item.id, label: `${item.surname} ${item.name} - Fornitore` }))

  const options = [...optionConductors, ...optionOwners, ...optionSuppliers]

  const [selectedParticipants, setSelected] = useState([]);

  console.log(formData)

  useEffect(() => {
    if(selectedParticipants.length > 0) {
      setFormData(prev => {
        return {
          ...prev,
          members: selectedParticipants
        }
      })
    }
  }, [selectedParticipants])

  useEffect(() => {
    if(formData?.members?.length > 0) {
      setSelected(formData.members)
    }
  }, [formData.members])

    /* PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE */
    const overrideString = { 
      "selectAll": "Seleziona tutti",
      "search": "Cerca",
      "selectSomeItems": "Seleziona",
      "allItemsAreSelected": "Tutti gli elementi sono selezionati"
    }

  return (
    <>
      <Row>
        <Col>
          <TopHeading SmallHeading="" />
        </Col>
      </Row>

      <Row className="mobile-container d-flex flex-column mt-md-4">
        <Col className="d-flex">
          <h2 className="fw-bold">Modifica Attività</h2>
        </Col>
      </Row>

      <Row>
        <div className="inputs-container">
          <Form>
            <Row className="d-md-flex">
              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">Nome Attività <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <input
                  type="text"
                  placeholder="Inserisci un nome per questa attività"
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem",
                  fontSize: "1rem",
                  background:'transparent'
                  }}
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">
                  Immobile/Stabile di riferimento <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  style={{ height: "50px" }}
                  name="realEstateManagementID"
                  value={formData.realEstateManagementID}
                >
                  <option value="">Seleziona</option>
                    {listaUnità.length > 0 &&
                      listaUnità.map((unit) => (
                        <option value={parseInt(unit.id)}>{unit.name}</option>
                      ))}
                </Form.Select>
              </Form.Group>
            </Row>

            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Date
                </h6>
              </div>
            </div>

            <Row className="d-md-flex ">
              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">
                  Data Inizio Stimata <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                </Form.Label>
                <input
                  type="date"
                  placeholder="Enter email"
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem",
                  fontSize: "1rem"

                  }}
                  name="startDate"
                  value={formData.startDate?.substr(0, 10).split("-").join("-")}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">
                  Data Fine Stimata <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                </Form.Label>
                <input
                  type="date"
                  placeholder="Enter email"
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem",
                  fontSize: "1rem"

                  }}
                  name="expirationDate"
                  value={formData.expirationDate
                    ?.substr(0, 10)
                    .split("-")
                    .join("-")}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Row>

            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Dettagli
                </h6>
              </div>
            </div>

            <Row className="d-md-flex ">
              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">Priorità</Form.Label>
                <Form.Select
                  style={{ height: "50px" }}
                  name="priority"
                  value={formData.priority}
                  onChange={handleFormChange}
                >
                  <option>Seleziona</option>
                  <option value="medium">Media</option>
                  <option value="high">Alta</option>
                  <option value="low">Bassa</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">
                  Tipologia di intervento
                </Form.Label>
                <Form.Select
                  style={{ height: "50px" }}
                  name="typology"
                  value={formData.typology}
                  onChange={handleFormChange}
                >
                  <option>Seleziona</option>
                  <option value="ordinario">Ordinario</option>
                  <option value="straordinario">Strardinario</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">Descrizione</Form.Label>
                <textarea
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: ".5rem",
                  fontSize: "1rem"
                  }}
                  name="description"
                  value={formData.description}
                  onChange={handleFormChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">Note ##</Form.Label>
                  <textarea
                    style={{ height: "50px",  
                    borderRadius: "6px",
                    border: "1px solid #BDBDBD",
                    padding: ".5rem",
                    fontSize: "1rem"
                    }}
                  />
              </Form.Group>
            </Row>

            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Partecipanti
                </h6>
              </div>
            </div>

            <Row className="d-md-flex align-items-start">
              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">Partecipanti</Form.Label>
                <MultiSelect
                  name="members"
                  isMulti
                  value={selectedParticipants}
                  onChange={setSelected}
                  options={options}
                  isCreatable={false}
                  labelledBy="Seleziona"
                  overrideStrings={overrideString}
                  style={{ height: "44px",  
                    borderRadius: "6px",
                    border: "1px solid #BDBDBD",
                    padding: "0 .5rem", 
                    width: '100%'
                    }}
                />

                {
                    selectedParticipants.length > 0 &&
                    <div className='mt-3'>
                        {
                            selectedParticipants.map( item => <NewItem item={item}/>)
                        }
                    </div>
                    }
              </Form.Group>


              <Form.Group className="mb-3 input-size d-flex flex-column">
                <Form.Label className="input-label">Incaricato</Form.Label>
                <Form.Select
                  style={{ height: "50px" }}
                  name="assignee"
                  value={formData.assignee}
                  onChange={handleFormChange}
                >
                  <option>Seleziona</option>
                  {selectedParticipants.length > 0 &&
                    selectedParticipants.map((member) => {
                      return (
                        <option value={member.id} key={member.id}>
                          {member.label}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <div className="input-size">
                <div className="label-heading my-3 d-block">
                  <div className="label-text rel d-inline-block py-2 px-2">
                    <h6 className="fs-14 text-white text-uppercase fw-semibold">
                      Planimetrie
                    </h6>
                  </div>
                </div>

                <Form.Select
                  style={{ height: "50px" }}
                  value={currentPlanimetryId}
                  onChange={handleCurrentPlanimetryId}
                >
                  {formData?.planimetries?.length > 0 &&
                    formData.planimetries?.map((planimetry) => {
                      return (
                        <option key={planimetry.id} value={planimetry.id}>
                          {planimetry.name}
                        </option>
                      );
                    })}
                </Form.Select>

                <div onClick={handleShow}>
                  <MapContainer
                    crs={L.CRS.Simple}
                    center={[0, 0]}
                    bounds={[
                      [0, 0],
                      [512, 512],
                    ]}
                    className="leaflet-container-newattività"
                    minZoom={-3}
                    scrollWheelZoom={false}
                  >
                    <ImageOverlay
                      bounds={[
                        [0, 0],
                        [512, 512],
                      ]}
                      url={currentPlanimetry?.image? currentPlanimetry?.image : 'https://cdn1.iconfinder.com/data/icons/seo-and-web-development-6/32/development_picture_illustration_design_image-1024.png'}
                     
                      />
                    {rectangles.features?.length > 0 &&
                      rectangles.features.map((rect) => {
                        return (
                          <Rectangle
                            key={rect.id}
                            bounds={rect.geometry.coordinates}
                          ></Rectangle>
                        );
                      })}
                    {points.features?.length > 0 &&
                      points.features.map((point) => {
                        return (
                          <Marker
                            key={point.id}
                            position={point.geometry.coordinates}
                          ></Marker>
                        );
                      })}
                  </MapContainer>
                </div>
              </div>

             
              <div className="input-size">
                <div className="label-heading my-3 d-block">
                  <div className="label-text rel d-inline-block py-2 px-2">
                    <h6 className="fs-14 text-white text-uppercase fw-semibold">
                      Allegati
                    </h6>
                  </div>
                </div>

                <Col className="mb-3">
                  <UploadBtn setFiles={setFiles} format='.png,.docx,.jpeg'/>
                </Col>
              </div>
            </Row>

            <div className="d-flex justify-content-end my-3">
              <ButtonRm variant="tertiary" className="me-2">
                Annulla
              </ButtonRm>
              <ButtonRm variant="primary" onClick={handleSubmit}>
                Salva
              </ButtonRm>
            </div>
          </Form>

          {/* PLANIMETRY MODAL */}
          {currentPlanimetry && (
            <Planimetry
              myMarkers={currentPlanimetry.markers}
              image={currentPlanimetry.image}
              onSaveMarkers={onSaveMarkers}
              showModal={show}
            ></Planimetry>
          )}
        </div>
      </Row>
    </>
  );
}
