import React, { useState } from "react";
import User from "../../../assets/images/affitti_user.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ConductorCard from "../ConductorCard";
import ConductorModal from "../ConductorModal";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { BsPrinter } from "react-icons/bs";

import CustomBadge from "../../CustomBadge";


const TakeoverE = (props) => {
  const [transfer_conductor, setTransferConductor] = useState([""]);
  const [lessor, setLessor] = useState([""]);
  const [show_conductor, setShowConductor] = useState(false);
  const [show_lessor, setShowLessor] = useState(false);
  const addConductor = () => {
    let temp = transfer_conductor;
    temp.push("");
    setTransferConductor(temp);
  };
  const addLessor = () => {
    let temp = lessor;
    temp.push("");
    setLessor(temp);
  };
  const table_data = [
    [
      "Mario",
      "Rossi",
      "Via Berli",
      12,
      "Bologna",
      "BO",
      "75646",
      "RSSMRO76CF5D929T",
    ],
    [
      "Giulia",
      "Verdi",
      "Via Berli",
      13,
      "Bologna",
      "BO",
      "75646",
      "RSSMRO76CF5D929T",
    ],
  ];
  return (
    <Form>
      <fieldset disabled={props.edit_mode ? false : true}>
        <div className="edit-content-container mt-5">
          {!props.edit_mode && (
            <div className="d-flex mb-3 justify-content-end">
              <button
                type="button"
                className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40"
              >
                <BsPrinter color="var(--edit-color)" />
              </button>
            </div>
          )}
          <div className="row ">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Data cessione
                </label>
                <input
                  className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
                  max="9999-12-31"
                  type="date"
                ></input>
              </div>
            </div>
          </div>
          <div className="label-heading d-block w-100 mt-3">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">
                CONDUTTORE CEDENTE
              </h1>
            </div>
          </div>
          <div
            className={`d-flex justify-content-start mt-3 ${
              !props.edit_mode && "d-none"
            }`}
          >
            <div className="edit-btn edit-white me-2 w-144">Nuovo</div>
            <div
              className="edit-btn edit-white w-144"
              onClick={(e) => {
                setShowConductor(true);
              }}
            >
              Seleziona
            </div>
          </div>
          {transfer_conductor.length > 0 ? (
            <div className="card-container">
              {transfer_conductor.map((item, index) => {
                return (
                  <div style={{ width: "90%" }}>
                    <ConductorCard key={index} edit_mode={props.edit_mode} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="unit-empty-img">
              <img loading="lazy" src={User} alt="No image" />
              <span className="img-comment">Nessun conduttore</span>
            </div>
          )}
          <div className="label-heading d-block w-100 mt-3">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">
                CONDUTTORE DOPO IL SUBENTRO
              </h1>
            </div>
          </div>
          <div
            className={`d-flex justify-content-start mt-3 ${
              !props.edit_mode && "d-none"
            }`}
          >
            <div className="edit-btn edit-white me-2 w-144">Nuovo</div>
            <div
              className="edit-btn edit-white w-144"
              onClick={(e) => {
                setShowLessor(true);
              }}
            >
              Seleziona
            </div>
          </div>
          {lessor.length > 0 ? (
            <div className="card-container">
              {lessor.map((item, index) => {
                return (
                  <div style={{ width: "90%" }}>
                    <ConductorCard key={index} edit_mode={props.edit_mode} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="unit-empty-img">
              <img loading="lazy" src={User} alt="No image" />
              <span className="img-comment">Nessun conduttore</span>
            </div>
          )}
        </div>
        <Modal
          show={show_conductor}
          className="type-modal"
          onHide={() => {
            setShowConductor(false);
          }}
          backdrop="static"
          size="lg"
        >
          <Modal.Body className="p-3">
            <Button
              variant=""
              onClick={(e) => {
                setShowConductor(false);
              }}
              className="float-end border-0 bg-white"
            >
              <i class="bi bi-x"></i>
            </Button>
            <ConductorModal
              table_data={table_data}
              setShow={setShowConductor}
              edit_mode={props.edit_mode}
              addFunc={addConductor}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={show_lessor}
          className="type-modal"
          onHide={() => {
            setShowLessor(false);
          }}
          backdrop="static"
          size="lg"
        >
          <Modal.Body className="p-3">
            <Button
              variant=""
              onClick={(e) => {
                setShowLessor(false);
              }}
              className="float-end border-0 bg-white"
            >
              <i className="bi bi-x"></i>
            </Button>
            <ConductorModal
              table_data={table_data}
              setShow={setShowLessor}
              edit_mode={props.edit_mode}
              addFunc={addLessor}
            />
          </Modal.Body>
        </Modal>
      </fieldset>
    </Form>
  );
};

export default TakeoverE;
