import React, { useState, useEffect } from "react";
import "../../assets/css/Tabs.css";
import TopHeading from "../TopHeading";
import { useNavigate } from "react-router-dom";
import Unit from "./Unit";
import Type from "./Type";
import RegistryData from "./RegistryData";
import Registration from "./Registration";
import Contract from "./Contract";
import Conductor from "./Conductor";
import Attachments from "./Attachments";
import Identifier from "./Identifier";
import api, {
  affitti_endpoint,
  unit_endpoint,
  rent_unit_assignment_endpoint,
  rent_conductor_assignment_endpoint,
  user_accounts_endpoint,
  bank_account_endpoint,
  rate_endpoint,
  rate_conductor_assignment_endpoint,
  rate_unit_assignment_endpoint,
  conductor_unit_assignment_endpoint,

} from "../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonRm from "../ButtonRm"
import Typology from "./Edit/Typology";

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, fetchDeadlineaddDeadline, removeDeadlineaddDeadline, editDeadlineaddDeadline } from "../../actions/deadline";

import { addDays, subDays, format, formatISO } from 'date-fns';
import { add, addMonths, addWeeks, addYears, isAfter, startOfMonth, endOfMonth, isBefore , subMonths} from 'date-fns';
import { parseISO } from 'date-fns';


export default function CreateAffitti() {
  const [units, setUnits] = useState([]);
  const [unitsIndex, setUnitsIndex] = useState(-1);


  // DEADLINE CREATION
	const dispatch = useDispatch();

	function postItem(data) {
		console.log(data)
		dispatch(addDeadline(data))
	}

  // useEffect(() => {
  // 	if (unitsIndex != -1) {
  // 		const unitsData = {
  // 			province: units[unitsIndex].province,
  // 			municipality: units[unitsIndex].municipality,
  // 			municipalityCode: units[unitsIndex].municipalityCode,
  // 			paper: units[unitsIndex].sheet,
  // 			address: units[unitsIndex].address,
  // 			category: units[unitsIndex].categoryLandRegistry,
  // 			classType: units[unitsIndex].classLandRegistry,
  // 			consistency: units[unitsIndex].consistence,
  // 			cadastralIncome: units[unitsIndex].cadastralIncome,
  // 			urbanSection: units[unitsIndex].urbanSection,
  // 			zone: units[unitsIndex].zone,
  // 			microZone: units[unitsIndex].microZone,
  // 		};
  // 		setFormData({ ...formData, ...unitsData });
  // 	}
  // }, [unitsIndex]);

  async function getUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data.content);
        console.log("units in affitti", response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const [managerAccounts, setManagerAccounts] = useState([])
  const [accounts, setAccounts] = useState([])
  const [ownerAccounts, setOwnerAccounts] = useState([])


  //CONTI GESTORI
  async function getAccounts() {
    try {
      const response = await api().get(`${user_accounts_endpoint}`);
      if (response.data) {
        setManagerAccounts(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //CONTI MEMBRI
  async function getBankAccounts() {
    try {
      const response = await api().get(`${bank_account_endpoint}`);
      if (response.data) {
		setAccounts(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    getUnits();
	getAccounts()
	getBankAccounts()
  }, []);



	const [formData, setFormData] = useState(() => {
		const initialValue = JSON.parse(localStorage.getItem("affitti"));
		return {
			typology: initialValue?.typology || false,
			units: initialValue?.units || [],
			rentType: initialValue?.rentType || "",
			rentPercentage: initialValue?.rentPercentage || 100,
			portionName: initialValue?.portionName || "",
			patrimonialManager: initialValue?.patrimonialManager || "",
			signDate: initialValue?.signDate || "",
			deadline: initialValue?.deadline || "",
			bail: initialValue?.bail || 0,
			paymentMethod: initialValue?.paymentMethod || "",
			paymentNote: initialValue?.paymentNote || "",
			proposalNote: initialValue?.proposalNote || "",
			managementType: initialValue?.managementType || "",
			startManagementDate: initialValue?.startManagementDate || "",
			startRentingDate: initialValue?.startRentingDate || null,
			endFirstPeriodDate: initialValue?.endFirstPeriodDate || null, //SCADENZARIO
			endRenewalDate: initialValue?.endRenewalDate || "", //SCADENZARIO
			cancellationRenewalForOwner:
				initialValue?.cancellationRenewalForOwner || null,
			cancellationRenewalForTenant:
				initialValue?.cancellationRenewalForTenant || null,
			cancellationRentForTenant: initialValue?.cancellationRentForTenant || null,
			deadlineForCancellation: initialValue?.deadlineForCancellation || null,//scadenzario
			//AGGIORNAMENTO ISTAT => 1° GIORNO al 28° DELLO STESSO MESE DI STARTRENTDATE + 1 ANNO 
			keyDeliveryDate: initialValue?.keyDeliveryDate || null,
			dueDayForPayment: initialValue?.dueDayForPayment || 0,
			closingContractDate: initialValue?.closingContractDate || null,
			buildingDeliveryDate: initialValue?.buildingDeliveryDate || null,
			buildingDeliveryNote: initialValue?.buildingDeliveryNote || "",
			warrantyType: initialValue?.warrantyType || "",
			warrantyInPrivateDocument: initialValue?.warrantyInPrivateDocument || "",
			warrantyDeposit: initialValue?.warrantyDeposit || null,
			warrantyPaymentMethod: initialValue?.warrantyPaymentMethod || "",
			depositInterest: initialValue?.depositInterest || "",
			warrantyDeliveryDate: initialValue?.warrantyDeliveryDate || null,
			voluntaryPenaltyClause: initialValue?.voluntaryPenaltyClause || false,
			warrantySubjectToVat: initialValue?.warrantySubjectToVat || false,
			warrantyPaymentDate: initialValue?.warrantyPaymentDate || "",
			generateIndependentFee: initialValue?.generateIndependentFee || false,
			warrantyTotalAmount: initialValue?.warrantyTotalAmount || null,
			fee: initialValue?.fee || 0,
			feePeriod: initialValue?.feePeriod || "Mensile",
			favoritePaymentMethod: initialValue?.favoritePaymentMethod || "Bonifico",
			bankAccount: initialValue?.bankAccount || "",
			notificationType: initialValue?.notificationType || "",
			paymentPatrimonialManager: initialValue?.paymentPatrimonialManager || "",
			debtorClientCode: initialValue?.debtorClientCode || "",
			generateCbiSepa: initialValue?.generateCbiSepa || false,
			currentMonthlyFee: initialValue?.currentMonthlyFee || null,
			originalMonthlyFee: initialValue?.originalMonthlyFee || null,
			currentMonthlyRateExpenses: initialValue?.currentMonthlyRateExpenses || null,
			originalMonthlyRateExpenses:
				initialValue?.originalMonthlyRateExpenses || null,
			readCounterManagement: initialValue?.readCounterManagement || "",
			counterNote: initialValue?.counterNote || "",
			haveAdjustmentOnTurnover: initialValue?.haveAdjustmentOnTurnover || false,
			turnoverThreshold: initialValue?.turnoverThreshold || 0,
			subjectToInvoice: initialValue?.subjectToInvoice || false,
			vatCanon: initialValue?.vatCanon || 0,
			istatUpdateFrom: initialValue?.istatUpdateFrom || "",//se != "" && ""Nessun aggiornamento""
			istat: initialValue?.istat || "", 
			feeOverview: initialValue?.feeOverview || 0,
			condominiumFee: initialValue?.condominiumFee || 0,
			registrationExpenses: initialValue?.registrationExpenses || 0,
			advanceCharges: initialValue?.advanceCharges || null,
			contractType: initialValue?.contractType || "",
			registrationTaxFree: initialValue?.registrationTaxFree || false,
			stampDutyFree: initialValue?.stampDutyFree ||false,
			mainBuilding: initialValue?.mainBuilding || false,
			registered: initialValue?.registered ||false,
			registrationDate: initialValue?.registrationDate || null,
			agenziaEntrateOffice: initialValue?.agenziaEntrateOffice || "",
			officeCode: initialValue?.officeCode || "",
			serial: initialValue?.serial || "",
			number: initialValue?.number || "",
			subNumber: initialValue?.subNumber || "",
			contractCode: initialValue?.contractCode || "",
			registrationForAllDuration:
				initialValue?.registrationForAllDuration ||false,
			extensionForAllDuration: initialValue?.registrationForAllDuration || false,
			percentageTaxConductor: initialValue?.percentageTaxConductor || 50,
			percentageStampConductor: initialValue?.percentageStampConductor ||50,
			registrationFromConductor:
				initialValue?.registrationFromConductor || false,
			amountConductor: initialValue?.amountConductor || 0,
			amountOwner: initialValue?.amountOwner || 0,
			registrationTotalAmount: initialValue?.registrationTotalAmount || 0,
			registrationTax: initialValue?.registrationTax || 0,
			copiesNumber: initialValue?.copiesNumber || 0,
			contractCopies: initialValue?.contractCopies || 1, //era mancante
			stampsCopiesNumber: initialValue?.stampsCopiesNumber || 0,
			privateScriptureNumber: initialValue?.privateScriptureNumber || 0,
			receiptsNumber: initialValue?.receiptsNumber || 0,
			mapsNumber: initialValue?.mapsNumber || 0,
			privateScriptureAmount: initialValue?.privateScriptureAmount || 16,
			receiptsAmount: initialValue?.receiptsAmount || 2,
			mapsAmount: initialValue?.mapsAmount || 2,
			stampTotalAmount: initialValue?.stampTotalAmount || 0,
			contractModelName: initialValue?.contractModelName || "",
			sureties: initialValue?.sureties || [],
			conductors: initialValue?.conductors || [],
			amounts: initialValue?.amounts || [],
			attachment: initialValue?.attachment || [],
			name: initialValue?.name || "",
			waiverOfCancellation: initialValue?.waiverOfCancellation || false,
			borrowedAmount: initialValue?.borrowedAmount || false,
			istatGrow: initialValue?.istatGrow || false,
			contractText: initialValue?.contractText || "",

			registrationContractExpenses: initialValue?.registrationContractExpenses || [],
			paid: initialValue?.paid || false,
			subEntrance:  initialValue?.subEntrance || [],
			bankAccount: initialValue?.bankAccount || {} //STRINGIFY NELLA POST


		};
	});

	const [generateRate, setGenerateRate] = useState(false)


	useEffect(() => {
		if (formData.units.length > 0) {
		  let ids = formData.units.flatMap(unit => unit.owners?.map(owner => owner.id)) // Utilizzo flatMap per ottenere un array piatto di ID degli owner

		  // Calcolo degli account degli owner
		  const contiCorrispondenti = accounts.filter(conto => {
			return conto.owners.some(owner => ids.includes(owner.id));
		  });
	  
		  // Salva gli account degli owner nello stato ownerAccounts
		  setOwnerAccounts(contiCorrispondenti);
		}
	  }, [formData.units]);




  useEffect(() => {
    localStorage.setItem("affitti", JSON.stringify(formData));
  }, [formData]);

  const onChange = (e) => {
    if (e.target.type == "checkbox") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [count, setCount] = useState(() => {
    const initialCount = JSON.parse(localStorage.getItem("affittiState"));
    return initialCount ? initialCount : 1;
  });

  useEffect(() => {
    localStorage.setItem("affittiState", JSON.stringify(count));
  }, [count]);

  const navigate = useNavigate();

  const forward = () => {
    var num = count;
    if (num <= 8) {
      setCount(++num);
    }
  };

  const backward = () => {
    var num = count;
    if (num > 1) {
      setCount(--num);
    }
  };

  const previousPage = () => {
    navigate(-1);
  };

  const setStep = (index) => {
    setCount(index);
  };

  	//funzione per creare array di date e poter creare aggiornamento istat-evento ricorrente
	function createRecurringEvents(startDate, endDate) {
		const startYear = new Date(startDate).getFullYear() + 1;
		const endYear = new Date(endDate).getFullYear();
		const recurringDates = [];
	
		for (let year = startYear; year <= endYear; year++) {
			const month = new Date(startDate).getMonth();
			const recurringStartDate = new Date(year, month, 1);
			const lastDayOfMonth = new Date(year, month + 1, 0);
			const recurringEndDate = new Date(year, month, lastDayOfMonth.getDate());
		
			recurringDates.push({ startDate: recurringStartDate, endDate: recurringEndDate });
		}

		return recurringDates;
	}


	function createDeadlineForThisRent(){
		//CREA SCADENZA PER FINE PERIODO INIZIALE 
		if(formData.endFirstPeriodDate){
			const dataForEndFirstPeriodDate = {
				title:`Fine periodo iniziale Locazione - ${formData.units[0]?.name} - ${formData.name}`,
				section:"Affitti",
				startDate: formData.endFirstPeriodDate,
				endDate: formData.endFirstPeriodDate,
				note:`Questo evento viene creato in automatico, al momento della creazione dell'affitto corrispondendte. E'relativo alla data di "Fine periodo iniziale" dell'unità '${formData.units[0]?.name}', e dell'affitto con identificativo '${formData.name}'.
				` 
			}
			postItem(dataForEndFirstPeriodDate)
		}

		//CREA SCADENZA PER FINE RINNOVO
		if(formData.endRenewalDate){
			const dataForRenewalFirstPeriodDate = {
				title:`Fine Rinnovo Locazione - ${formData.units[0]?.name} - ${formData.name}`,
				section:"Affitti",
				startDate: formData.endRenewalDate,
				endDate: formData.endRenewalDate,
				note:`Questo evento viene creato in automatico, al momento della creazione dell'affitto corrispondendte. E'relativo alla data di "Fine Rinnovo" dell'unità '${formData.units[0]?.name}', e dell'affitto con identificativo '${formData.name}'.
				` 
			}
			postItem(dataForRenewalFirstPeriodDate)
		}

		//CREA SCADENZA PER Data entro cui può essere inviata la disdetta
		if(formData.deadlineForCancellation){
			const dataForDeadlineForCancellation = {
				title:`Data entro cui può essere inviata la disdetta del Rinnovo - ${formData.units[0]?.name} - ${formData.name}`,
				section:"Affitti",
				startDate: formData.deadlineForCancellation,
				endDate: formData.deadlineForCancellation,
				note:`Questo evento viene creato in automatico, al momento della creazione dell'affitto corrispondendte. E'relativo alla data "entro cui può essere inviata la disdetta del rinnovo" dell'unità '${formData.units[0]?.name}', e dell'affitto con identificativo '${formData.name}'.
				` 
			}
			postItem(dataForDeadlineForCancellation)
		}

		//CREA SCADENZA RICORRENTE PER AGG. ISTAT
		//OGNI ANNO FINO A DATA FINE RINNOVO || O FINE PERIODO INIZIALE
		
		
		if (formData.endFirstPeriodDate && formData.startRentingDate) {
			console.log("AGG. ISTAT")
			let dateArray = createRecurringEvents(formData.startRentingDate, formData.endRenewalDate ? formData.endRenewalDate : formData.endFirstPeriodDate);
		
			dateArray.forEach((date) => {
				console.log("inizio elab", date)

				// Sottrai un mese dalle date
				const newStartDate = new Date(new Date(date.startDate).setMonth(new Date(date.startDate).getMonth() - 1)).toISOString().slice(0, 10);
				const newEndDate = new Date(new Date(date.endDate).setMonth(new Date(date.endDate).getMonth() - 1)).toISOString().slice(0, 10);
		
				const dataObj = {
					title: `Aggiornamento Istat - ${formData.units[0]?.name} - ${formData.name}`,
					section: "Affitti",
					isRecurrent: true,
					startDate: newStartDate,
					endDate: newEndDate,
					note: `Questo evento viene creato in automatico, al momento della creazione dell'affitto corrispondendte. E'relativo all' AGGIORNAMENTO ISTAT dell'unità '${formData.units[0]?.name}', e dell'affitto con identificativo '${formData.name}'.`
				};
		
				postItem(dataObj);
			});
		}
		
	} 



	//CREATE RATA 
	async function postRata(data){

	//CREO COPIA DI DATA
	const bodyToSend = {...data}
	/* owners: data.subjectType == "owner" ? data.subject: [],
	conductors: data.subjectType == "conductor" ? data.subject: [],
	suppliers: data.subjectType == "supplier" ? data.subject: [], */
	
	//RIMUOVO SUBJECTS
	delete  bodyToSend.subject;
	//RIMUOVO ELEMENTI DA ASSEGNARE CON UN DIVERSO ENDPOINT
	delete  bodyToSend.unit;
	delete  bodyToSend.condominium;
	delete  bodyToSend.primaNotaRelated

	try {    
		const response = await api().post(`${rate_endpoint}`, bodyToSend);
		const installmentID = response?.data.id;

		//assign conductor
		if (data.subjectType === "conductor" && installmentID ) {
			const conductorID = formData.conductors?.[0]?.id; 
			await api().post(`${rate_conductor_assignment_endpoint}`, {conductorID: conductorID,installmentID: installmentID});    
		} 


		//assign unit
		if (data.typeBuilding === "Unità" && installmentID ) {
			const unitID = formData.units?.[0]?.id; 
			await api().post(`${rate_unit_assignment_endpoint}`, {unitID: unitID, installmentID: installmentID});    
		} 

		console.log("creazione rata")


	} catch (error) {
		console.log(error);
	}
}


	//CREA RATE RICORRENTI
	// IN UN SECONDO MOMENTO MAGARI AGGIUNGERE CONDIZIONE IN BASE A AVOLOTAN UTENTE, NON A PRESCINDERE
	function createRecurringEvent(title, startDate, endDate, frequency, frequencyUnit, endRecurrenceDate, deadlineDate) {

		console.log("siamo dentro createRecurringEvent")
        const recurrenceIntervals = {
            "giorno/i": addDays,
            "settimana/e": addWeeks,
            "mese/i": addMonths,
            "anno/i": addYears,
        };
        const recurrenceInterval = recurrenceIntervals[frequencyUnit];
        
        const events = [];
		let nextStartDate = startOfMonth(addMonths(startDate, frequency));
        //let nextStartDate = recurrenceInterval(startDate, frequency);
        let nextEndDate = recurrenceInterval(endDate, frequency);
        let nextDeadlineDate = recurrenceInterval(deadlineDate, frequency);

        
        let recurrenceEndDate = endRecurrenceDate;

		console.log({
			startDate,
			nextStartDate
		})


		while (isAfter(nextStartDate, startDate) && isBefore(nextStartDate, recurrenceEndDate)) {
			events.push({ title, startDate: nextStartDate, endDate: nextEndDate, deadlineDate: nextDeadlineDate });
		  
			nextStartDate = addMonths(nextStartDate, frequency);
			nextEndDate = addMonths(nextEndDate, frequency);
			nextDeadlineDate = addMonths(nextDeadlineDate, frequency);
		  
			if (isAfter(nextEndDate, endRecurrenceDate)) {
			  nextEndDate = endRecurrenceDate;
			  nextDeadlineDate = subDays(nextEndDate, 1);
			}
		  }
    
        return events;
    }

	const save = async () => {
		if(formData.name && 
			formData.units.length > 0 &&
			formData.conductors.length > 0 &&
			formData.startRentingDate &&
			formData.endFirstPeriodDate && 
			formData.currentMonthlyFee)
 		{
			try {

				//gestione allegati

				let formDataToSend = new FormData();

				formData.attachment.forEach((file) => {
					const blob = new Blob([file]);
					formDataToSend.append("parts", blob, file.name);
					//formDataToSend.append("parts", file, file.name);
				});
				
				let sentUpload = formDataToSend.get("parts") !== null;
				//if (formDataToSend.entries().length === 0) sentUpload = false;

				delete formData.attachment;

				/*REMOVE UNITS AND CONDUCTORS FROM FORM DATA */
				const {units, conductors, ...dataToSend} = formData

				//STRINIGFY
				dataToSend.registrationContractExpenses = JSON.stringify(dataToSend.registrationContractExpenses);
				dataToSend.subEntrance = JSON.stringify(dataToSend.subEntrance);
				dataToSend.bankAccount = JSON.stringify(dataToSend.bankAccount)

				let response;

				response = await api().post(`${affitti_endpoint}`, dataToSend);

				//INVIA ALLEGATI
				if (response) {
					if (sentUpload) {
					await api().put(`${affitti_endpoint}upload/${response.data.id}`, formDataToSend);
					}
				}
				
				formData.units.forEach((unit) => {
					try {
						api().post(`${rent_unit_assignment_endpoint}`, {
							unitID: unit.id,
							rentID: response.data.id,
						});
					} catch (error) {
						console.log(error);
					}
				});



			// ASSOCIAZIONE CONDUTTORI- UNITA'
if (conductors.length > 0 && formData.units.length > 0) {
    for (const conductor of formData.conductors) {
        for (const unit of formData.units) {
            await api().post(`${conductor_unit_assignment_endpoint}`, {
                conductorID: conductor.id,
                unitID: unit.id,
            });
        }
    }
}


				formData.conductors.forEach((conductor) => {
					try {
						api().post(`${rent_conductor_assignment_endpoint}`, {
							conductorID: conductor.id,
							rentID: response.data.id,
						});
					} catch (error) {
						console.log(error);
					}
				});

				//associazione pertinenza

				//CREAZIONE DEADLINE per questo affitto
				createDeadlineForThisRent()

				//CREAZIONE RATE PER QUESTO AFFITTO

				if (generateRate){
					// Determina la data di inizio della prima rata (il primo giorno del mese successivo alla data di inizio dell'affitto)
				const firstInstallmentStartDate = startOfMonth(parseISO(formData.startRentingDate));

				//scadenza rata starting date + duedyfor payment
				const deadlineInstallmentDate = addDays(firstInstallmentStartDate , formData.dueDayForPayment ? formData.dueDayForPayment : 10)

				// Calcola la data di fine del periodo di competenza della prima rata (un giorno prima della data di scadenza)
				const firstInstallmentEndDate = subDays(deadlineInstallmentDate, 1)

				//CREA PRIMA RATA

				//HANDLE RICORRENZA
				let dateArray = createRecurringEvent(
					formData.name, 
					firstInstallmentStartDate,
					firstInstallmentEndDate, // fine periodo competenza rata
					"1", 
					"mese/i", 
					formData?.endRenewalDate? parseISO(formData.endRenewalDate) : parseISO(formData.endFirstPeriodDate), 
					deadlineInstallmentDate
				)
				
				{/* RATE CANONE - CON IVA SCOMPOSTA */}
				dateArray.forEach((date, index) => {

					const startDate = startOfMonth(date.startDate);

					const dataObj = {
						installmentName: `${date.title} - Rata canone ${index + 1}`,
						type: "ReceivePayment", //STRING

						subjectType: "conductor", //STRING
						subject: formData?.conductors?.[0] , //OBJ

						typeBuilding : "Unità", //STRING
						unit: formData?.units?.[0], //OBJ

						competenceStartDate:  startDate,
						competenceEndDate:  subDays(addDays(startDate , formData.dueDayForPayment ? formData.dueDayForPayment : 10), 1),
						deadlineDate:  addDays(startDate , formData.dueDayForPayment ? formData.dueDayForPayment : 10),

						vatCode: (formData?.subjectToInvoice && formData?.vatCanon)? formData.vatCanon : 1, //NUMBER
						amount: formData?.currentMonthlyFee, //NUMBER
						totalDue: formData?.currentMonthlyFee, //NUMBER 
						totalPaid: 0, //NUMBER

					}
					postRata(dataObj)
				}) 

				{/* RATE ONERI ACCESSORI - SEMPRE SENZA IVA */}
				if(formData?.advanceCharges)
					{
					dateArray.forEach((date, index) => {
					const startDate = startOfMonth(date.startDate)
					const dataObj = {
						installmentName: `${date.title} - Rata  oneri accessori ${index + 1}`,
						type: "ReceivePayment", //STRING
						subjectType: "conductor", //STRING
						subject: formData?.conductors?.[0] , //OBJ
						typeBuilding : "Unità", //STRING
						unit: formData?.units?.[0], //OBJ
						competenceStartDate:  startDate,
						competenceEndDate:  subDays(addDays(startDate , formData.dueDayForPayment ? formData.dueDayForPayment : 10), 1),
						deadlineDate:  addDays(startDate , formData.dueDayForPayment ? formData.dueDayForPayment : 10),
						vatCode: 1, //NUMBER
						amount: formData?.advanceCharges, //NUMBER
						totalDue: formData?.advanceCharges, //NUMBER 
						totalPaid: 0, //NUMBER
					}
					postRata(dataObj)
				}) }}
	

			} catch (error) {
				console.log(error);
			}
			localStorage.removeItem("affittiState");
			localStorage.removeItem("affitti");
			navigate(-1);
		} else if(formData.units?.length == 0){
			toast.error("Dati mancanti nello step Unità", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		} else if(!formData.name){
			toast.error("Dati mancanti nello step Tipologia", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		} else if(formData.conductors.length == 0){
			toast.error("Dati mancanti nello step Conduttori", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		} else if(!formData.startRentingDate ||
			!formData.endFirstPeriodDate || 
			!formData.currentMonthlyFee){
			toast.error("Dati mancanti nello step Anagrafica", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	}

	const [errorText, setErrorText] = useState("");

	const mandataryCheck = () => {
		var temp = "";
		if (formData.name == "") {
			temp = temp + "\n-Identificativo (step - Identificativo)";
		}
		if (formData.startRentingDate == "") {
			temp = temp + "\n-Inizio locazione (step- Anagrafica)";
		}
		if (formData.endFirstPeriodDate == "") {
			temp = temp + "\n-Fine periodo iniziale (step - Anagrafica)";
		}

		if (temp != "") {
			setErrorText("Non hai riempito i seguenti campi:" + temp);
		} else setErrorText("");
	};

	useEffect(() => {
		mandataryCheck();
	}, [formData.name, formData.startRentingDate, formData.endFirstPeriodDate]);

	console.log(generateRate, 'generaRate')
	return (
		<>
			<ToastContainer />
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="Nuovo Affitto" />
				</div>
			</div>
			<div className="step-show">
				<div className="tabs-div">
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(1);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "102.395px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 5C2 2.79086 3.79086 1 6 1H82.6787C84.1141 1 85.4395 1.76914 86.1517 3.01544L99.866 27.0154C100.569 28.2452 100.569 29.7548 99.866 30.9846L86.1517 54.9846C85.4395 56.2309 84.1141 57 82.6787 57H6C3.79086 57 2 55.2091 2 53V5Z"
									fill={
										count > 0 ? (count >= 1 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 0 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title1"
								style={{
									color:
										count > 0
											? count >= 1
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Unità
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 1 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 0 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 1 ? "" : "none",
									position: "absolute",
									left: "5px",
								}}>
								Unità
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(2);
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count > 1 ? (count > 2 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 1 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count > 1 ? (count > 2 ? "#FFF8F8" : "#83112F") : "#3C4257",
								}}>
								Tipologia
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 2 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 1 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 2 ? "" : "none",
									position: "absolute",
									left: "-8px",
								}}>
								Tipologia
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(3);
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count > 2 ? (count > 3 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 2 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count > 2 ? (count > 3 ? "#FFF8F8" : "#83112F") : "#3C4257",
								}}>
								Conduttori
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 3 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 2 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 3 ? "" : "none",
									position: "absolute",
									left: "-12px",
								}}>
								Conduttori
							</div>
						</div>
					</div>
          <div
						className="tab-icon"
						onClick={(e) => {
							setStep(4
              );
						}}>
						<div className="desktop-icon">
							<svg className="general-size">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
									fill={
										count > 3 ? (count > 4 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count > 3 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count > 3 ? (count > 4 ? "#FFF8F8" : "#83112F") : "#3C4257",
								}}>
								Anagrafica
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 4 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 3 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 4 ? "" : "none",
									position: "absolute",
									left: "-12px",
								}}>
								Anagrafica
							</div>
						</div>
					</div>
          <div
						className="tab-icon"
						onClick={(e) => {
							setStep(5
              );
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "145px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M130.152 3.01544C129.439 1.76914 128.114 1 126.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H126.679C128.114 57 129.439 56.2309 130.152 54.9846L143.866 30.9846C144.569 29.7548 144.569 28.2452 143.866 27.0154L130.152 3.01544Z"
									fill={
										count >= 5 ? (count >= 6 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 5 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 5
											? count >= 6
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Registrazione
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 5 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count > 4 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 5 ? "" : "none",
									position: "absolute",
									left: "-12px",
								}}>
								Registrazione
							</div>
						</div>
					</div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setStep(6);
            }}
          >
            <div className="desktop-icon">
              <svg className="general-size">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
                  fill={
                    count > 5 ? (count > 6 ? "#83112F" : "#FFF8F8") : "#fff"
                  }
                  stroke={count > 5 ? "#83112F" : "#C1C9D2"}
                />
              </svg>
              <div
                className={"tab-icon-title"}
                style={{
                  color:
                    count > 5 ? (count > 6 ? "#FFF8F8" : "#83112F") : "#3C4257",
                }}
              >
                Contratto
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1E2E2CF"
                    style={{
                      margin: "auto",
                      display: count === 6 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={count > 5 ? "#83112F" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: count === 6 ? "" : "none",
                  position: "absolute",
                  left: "-8px",
                }}
              >
                Contratto
              </div>
            </div>
          </div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setStep(7);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "138px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H133C135.209 1 137 2.79086 137 5V53C137 55.2091 135.209 57 133 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
                  fill={
                    count > 6 ? (count > 7 ? "#83112F" : "#FFF8F8") : "#fff"
                  }
                  stroke={count > 6 ? "#83112F" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title"
                style={{
                  color:
                    count > 6 ? (count > 7 ? "#FFF8F8" : "#83112F") : "#3C4257",
                }}
              >
                Allegati
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1E2E2CF"
                    style={{
                      margin: "auto",
                      display: count === 7 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={count > 6 ? "#83112F" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: count === 7 ? "" : "none",
                  position: "absolute",
                  left: "-16px",
                }}
              >
                Allegati
              </div>
            </div>
          </div>
        </div>
        <div className="content-container">
          {count == 1 && (
            <Unit
              formData={formData}
              onChange={onChange}
              units={units}
              setUnitsIndex={setUnitsIndex}
              previousPage={previousPage}
              forward={forward}
            />
          )}
          {count == 2 && <Type formData={formData} onChange={onChange}
          forward={forward} backward={backward} />}
          {count == 3 && (
            <Conductor
              formData={formData}
              setFormData={setFormData}
              onChange={onChange}
              forward={forward} backward={backward}
            />
          )}
          {count == 4 && (
            <RegistryData formData={formData} onChange={onChange} forward={forward} backward={backward} 
			managerAccounts={managerAccounts} ownerAccounts={ownerAccounts} setGenerateRate={setGenerateRate} generateRate={generateRate}
			/>
          )}
          {count == 5 && (
            <Registration formData={formData} onChange={onChange} forward={forward} backward={backward} setFormData={setFormData} />
          )}
          {count == 6 && <Contract formData={formData} onChange={onChange} forward={forward} backward={backward} />}

          {count == 7 && (
            <Attachments formData={formData} onChange={onChange}  forward={forward} save={save}/>
          )}
{/* 
          <div className="d-flex justify-content-end mt-5">
            {count === 7 &&
              <ButtonRm
              variant="tertiary"
              onClick={() => previousPage()}
              >
              Annulla
              </ButtonRm>
           }
            {count == 7 &&
              <ButtonRm
                variant="primary"
                onClick={save}
                className="ms-2"
              >
                Salva
              </ButtonRm>
            }
          </div>  */}
        </div>
      </div>
   	 	</>
  );
}
