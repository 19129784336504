import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../../../assets/css/Membri.css";
import { Link } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import LogoMark from "../../../../assets/images/logo_mark.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { token } from "../../../../api/api.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonRm from "../../../ButtonRm.js";
import { Breadcrumb, Col } from "react-bootstrap";

import CustomBadge from "../../../CustomBadge.js";



export default function TopHeadPartV(props) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ownerDelete = () => {
    axios
      .delete(
        process.env.REACT_APP_REQUEST_URL +
          "api/v1/conductor/" +
          props.parms.id +
          "/",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.data.success == true) {
          toast.success("Success Notification !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
        handleClose();
        const timer = setTimeout(() => {
          navigate("/conduttori");
        }, 2000);
        return () => clearTimeout(timer);

        // setItems(res.data.content)
      });
  };

  return (
    <>
    <Col md={12} className="my-3 mb-0 px-0">
				<Breadcrumb>
					<Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate('/conduttori')
						}}>
						Conduttori
					</Breadcrumb.Item>

					
				
					<Breadcrumb.Item active={true} 
						className="settings-archive"
					>
					{props.parms?.surname + " " + props.parms?.name}

					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
      <div
        className="d-flex flex-column flex-md-row
       justify-content-between align-items-md-end topHeading-title"
      >
        <div className="flex-grow-1">
          <div className="d-flex flex-column">
            <h2 className="fs-24 fs-md-30 text-dark fw-semibold mb-3 mb-md-0 text-capitalize">
            {(props.parms.surname && props.parms.name) ? `${props.parms.surname} ${props.parms.name}` : ``}
            </h2>
          </div>
        </div>
        <div className="flex-shrink-0">
          <div className="d-flex align-items-center">
            <Link
              to={`/conduttori-edit/${props.parms.id}`}
              state={{ data: props.parms }}
            >
              <ButtonRm variant="blue">Modifica</ButtonRm>
            </Link>
          {/*   <button
              type="button"
              onClick={handleShow}
              className="border border-secondary bg-white text-secondary rounded-3 py-2 px-3"
            >
              <BsTrash />
            </button> */}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo proprietario?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={() => ownerDelete()}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={handleClose}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
