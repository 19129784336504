import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BsDownload, BsTrash, BsFiEdit2, BsArchive} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"

import DetailAccount from "./Account/DetailAccount";
import Unverified from "./Account/Unverified";
import Verified from "./Account/Verified";


import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "../../assets/css/Account.css"




import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import SearchInput from "../SearchInput";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from "../../actions/accountAction";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import AccountC from "./Account/Account";
import DeleteC from "./Account/DeleteAccount";
import UsersC from "./Account/Users/Users";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

/* 
    STATI
    in attesa di approvazione: quando l'utente che si registra si registra o fa una modifica
    in attesa di modifica: quando mandi la richiesta di modifica
    approvato: quando approvi la registrazione 

    /user/{id}/approve: setta lo stato ad APPROVED
    /user/{id}/reject: setta lo stato a WAIT_UPDATE
*/


export default function Account(){

    const dispatch = useDispatch();



    useEffect(() => {
        dispatch(fetchUsers());
    }, [dispatch]);


    //HANDLE SEARCH
    const [searchTerm, setSearchTerm] = useState("");

    const users = useSelector(state => state.userReducer.users)?.filter(el => ((el.name)?.toLowerCase().includes(searchTerm) || el.surname?.toLowerCase().includes(searchTerm)));
  


    //CURRENT TAB

    const [tab, setTab] = useState("verifica")

    const handleChange = (event, newTab) => {
        setTab(newTab);
    };

    return(
        <>
            <div className="settings-tab-content">
                    <p className="fs-18 text-dark fw-bold"> Utenti RM</p>
                    <div className="mt-3 mb-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={tab}
                        exclusive
                        onChange={handleChange}
                        style={{padding: 0, margin: 0, paddingLeft:0, paddingRight:0}}
                        >
                        <ToggleButton value="verifica">Verifica Utenti</ToggleButton>
                        <ToggleButton value="utenti">Info Utenti</ToggleButton>
                        <ToggleButton value="elimina">Elimina Utenti</ToggleButton>
                    </ToggleButtonGroup>
                    </div>

               

                <hr className="opacity-10 my-3"/>


               {tab === "verifica" && <AccountC /> }

                {tab === "elimina" && <DeleteC /> }

                {tab === "utenti" && <UsersC />}
            
            

            </div >
            
        </>
    )
}