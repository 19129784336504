import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PrintDwonloadIcons from "./PrintDwonloadIcons";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";
import { Form } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";

import CustomToggle from "../../../CustomToggle";

import translationMapping from '../../mapping'
import ExportButton from "../../Fornitori/ExportButton";

import CustomBadge from "../../../CustomBadge";


export default function AnagraficaV(props) {
  const componentRef = useRef();

  /* GESTISCE L'ELEMENTO ACCORDION ATTIVO */
  const [activeKey, setActiveKey] = useState('');

  const [viewMode, setMode] = useState(true)

  const onChange = async (e) => {
    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.value,
    });
  };    

  let item = {
	name: props.formData.name,
	surname: props.formData.surname,
	birthday: props.formData.birthday,
	state: props.formData.state,
	province: props.formData.province,
	municipality: props.formData.municipality,
	fiscalCode: props.formData.fiscalCode,
	sex: props.formData.sex,
	notes: props.formData.notes,
	businessName: props.formData.businessName,
	withholding: props.formData.withholding,
	subjectType: props.formData.subjectType,
	societyFiscalCode: props.formData.societyFiscalCode,
	vatNumber: props.formData.vatNumber,
	chamberOfCommerceNumber: props.formData.chamberOfCommerceNumber,
	chamberOfCommerceCity: props.formData.chamberOfCommerceCity,
	reaNumber: props.formData.reaNumber,
    shareCapital: props.formData.shareCapital,
	category: props.formData.category
  };


  //aggiungere un controllo in base al path => se edit mode no bottoni in basso

  return (
    <>
      <div className="d-flex mb-3 justify-content-end mt-3 me-3">
		
		<ExportButton data={item} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

	</div>
      <fieldset disabled={viewMode} ref={componentRef}>
        <div className="form-item-align-box d-block pb-5">
          <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Dati</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Denominazione o ragione sociale
										</Form.Label>
										<Form.Control 
											type="text" 
											name="businessName"
											value={props.formData.businessName}
											// onChange={onChange}
											// required
										/>
									</Form.Group>
								</Col>
								<Col md={6} className="d-flex align-items-center">
								<Form.Group className="me-2 w-100">
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Categoria
									</Form.Label>
									<Form.Select 
										name="category"
										value={props.formData.category}
										// onChange={onChange}
									>
										<option value={props.formData.category}>{props.formData.category}</option>
										<option value="Assicurazione">Assicurazione</option>
										<option value="Geometra">Geometra</option>
										<option value="Idraulico">Idraulico</option>
										<option value="Notaio">Notaio</option>
										<option value="Riparazioni varie">Riparazioni varie</option>
										<option value="Ristrutturazioni">Ristrutturazioni</option>
										<option value="Stato">Stato</option>
										<option value="Utenze-Energia">Utenze - Energia</option>
										<option value="Amministratore">Amministratore</option>
									</Form.Select>
								</Form.Group>
								{/* <BsPlusCircleFill
									className="col-sm-2 col-md-1 fs-30 plus-button mt-4"
								/> */}
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Codice Fiscale
										</Form.Label>
										<Form.Control 
											type="text" 
											name="fiscalCode"
											value={props.formData.fiscalCode}
											// onChange={onChange}
										/>
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Partita IVA
										</Form.Label>
										<Form.Control 
											type="text" 
											name="vatNumber"
											value={props.formData.vatNumber} 
											// onChange={onChange}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Tipo di soggetto
									</Form.Label>
									<Form.Select 
										name="subjectType"
										value={props.formData.subjectType}
										// onChange={onChange}
									>
										<option value="Società">Società</option>
										<option value="Ditta individuale">Ditta individuale</option>
										<option value="Cooperativa">Cooperativa</option>
										<option value="Ente">Ente</option>
										<option value="Associazione">Associazione</option>
										<option value="Fondazione">Fondazione</option>
										<option value="Studio">Studio</option>
										<option value="Professionista">Professionista</option>
									</Form.Select>
								</Form.Group>
								</Col>
								
							</Row>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Contatto</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Nome
										</Form.Label>
										<Form.Control 
											type="text" 
											name="name"
											value={props.formData.name}
											// onChange={onChange}
											// required
										/>
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Cognome
										</Form.Label>
										<Form.Control 
											type="text" 
											name="surname"
											value={props.formData.surname}
											// onChange={onChange}
											// required
										/>
									</Form.Group>
								</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Note</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Note
										</Form.Label>
										<Form.Control 
											as="textarea"
											name="note"
											value={props.formData.note}
											// onChange={onChange}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>
					
				</Accordion>
            </div>
      </fieldset>
    </>
  );
}
