import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../../../assets/css/Membri.css";
import Tarrafari from "./Tarrafari";
import { useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../../ButtonRm";
import { Modal } from "react-bootstrap";

import CustomToggle from "../../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import CustomBadge from "../../../CustomBadge";

const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
	businessName: yup.string().required("Inserisci la denominazione o ragione sociale"),
	category: yup.string().required("Seleziona o crea una categoria"),
	fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	}),
	subjectType: yup.string(),
	name: yup.string().required("Inserisci il nome"),
	surname: yup.string().required("Inserisci il cognome"),
	notes: yup.string()
});


export default function Anagrafica(props) {
	
	const navigate = useNavigate();

	const [checkedType, setCheckedType] = useState(
		"persona fisica" === props.formData.type ? false : true
	);
	const [linkShow, setLinkShow] = useState(false);
	const [show, setShow] = useState(false);

	const onChange = async (e) => {
		const { name, value } = e.target
	
		if (name === "inVirtueOf") {
			props.setFormData({
			...props.formData,
			[name]: [value],
			});
		} else {
			props.setFormData({
			...props.formData,
			[name]: value,
			});
		}
	};

	const avanti = () => {
		props.next();
		// TODO check values are present
	};


	/* CUSTOM HEADER */
	/* ci possono essere più elementi attivi, quindi magari fare un array, in cui salviamo gli event key, e funzione per cercare se event key corrent è presente nell'array e viceversa (rimozione) */
	const [activeKey, setActiveKey] = useState('');

	function handleActiveKey(item) {
		
	}

	/* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname

	/* HANDLE CATEGORIA OPTIONS */
	/* servirà salvare nel database le categorie di ogni utente */
	const [categoryOptions, setCategoryOptions] = useState([
		"Assicurazione",
		"Geometra",
		"Idraulico",
		"Notaio",
		"Riparazioni Varie",
		"Ristrutturazioni",
		"Stato",
		"Utenze - Energia",
		"Amministratore",
		"Amministratore di Condominio"
	])

	/* HANDLE MODAL CATEGORIA */

	//eventualmente spostare in utilities
	function capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	const [categoryName, setCategoryName] = useState("")
	const [categoryModal, setCategoryModal] = useState(false) 

	function closeCategoryModal(){
		setCategoryModal(false)
	}

	function openCategoryModal (){
		setCategoryModal(true)
	}

	function handleCategoryName(e){
		setCategoryName(e.target.value)
	}

	function createCategory(e){
		e.preventDefault()
		e.stopPropagation()

		if(categoryName){
			setCategoryOptions(prev => {
				return [...prev, (capitalizeFirstLetter(categoryName))]
			})
		}
		closeCategoryModal()
		setCategoryName("")
	}

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		avanti()
	};

	console.log(path)

	return (
		<div
			className="d-block w-100 mb-3 bg-sm-white"
			style={{ borderRadius: "16px" }}>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<div className="form-item-align-box d-block pt-5 pb-5">
				<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Dati <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Denominazione o ragione sociale
											<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
											{...register("businessName")}
											type="text" 
											name="businessName"
											value={props.formData.businessName}
											onChange={onChange}
											isInvalid={!!errors.businessName}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.businessName?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col md={6} className="d-flex align-items-start">
								<Form.Group className="me-2 mb-3" style={{width:"90%"}}>
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Categoria <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
										{...register("category")}
										name="category"
										value={props.formData.category}
										onChange={onChange}
										isInvalid={!!errors.category}
									>
										<option value=""></option>
										{categoryOptions.map((category, index) => {
										return (
										<option className="text-capitalize" value={category} key={index}>
											{category}
										</option>
										);
										})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.category?.message}
									</Form.Control.Feedback>
								</Form.Group>
								<BsPlusCircleFill
									className="col-sm-2 col-md-1 fs-30 plus-button"
									style={{marginTop:"2rem"}}
									onClick={openCategoryModal}
								/>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Codice Fiscale
										</Form.Label>
										<Form.Control 
											{...register("fiscalCode")}
											type="text" 
											name="fiscalCode"
											value={props.formData.fiscalCode}
											onChange={onChange}
											isInvalid={!!errors.fiscalCode}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.fiscalCode?.message}
									</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Partita IVA
										</Form.Label>
										<Form.Control 
											{...register("vatNumber")}
											type="text" 
											name="vatNumber"
											value={props.formData.vatNumber} 
											onChange={onChange}
											isInvalid={!!errors.vatNumber}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.vatNumber?.message}
									</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3">
									<Form.Label 
										className="f-label fs-16"
									>
										Tipo di soggetto
									</Form.Label>
									<Form.Select 
										{...register("subjectType")}
										name="subjectType"
										value={props.formData.subjectType}
										onChange={onChange}
									>
										<option value="Società">Società</option>
										<option value="Ditta individuale">Ditta individuale</option>
										<option value="Cooperativa">Cooperativa</option>
										<option value="Ente">Ente</option>
										<option value="Associazione">Associazione</option>
										<option value="Fondazione">Fondazione</option>
										<option value="Studio">Studio</option>
										<option value="Professionista">Professionista</option>
									</Form.Select>
								</Form.Group>
								</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Contatto <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Nome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
											{...register("name")}	
											type="text" 
											name="name"
											value={props.formData.name}
											onChange={onChange}
											isInvalid={!!errors.name}

										/>
										<Form.Control.Feedback type="invalid" >
										{errors.name?.message}
									</Form.Control.Feedback>
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Cognome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
											{...register("surname")}
											type="text" 
											name="surname"
											value={props.formData.surname}
											onChange={onChange}
											isInvalid={!!errors.surname}

										/>
										<Form.Control.Feedback type="invalid" >
										{errors.surname?.message}
									</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>
    
					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Note</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Note
										</Form.Label>
										<Form.Control 
											{...register("notes")}
											as="textarea"
											name="notes"
											value={props.formData.notes}
											onChange={onChange}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Accordion.Body>
					</Accordion.Item>
					
				</Accordion>
		
				<div
					className="col-md-12 mt-5 tariffari-link-div"
					style={{ display: linkShow ? "" : "none" }}
					onClick={() => setShow(true)}>
					Visualizza elenco dei piani tariffari
					</div>
					{ path == "/fornitori/create" && <div className="col-12">
						<div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
							<ButtonRm 
								variant="tertiary"
								onClick={() => {navigate(-1)}}
								className="me-2"
							>
								Annulla
							</ButtonRm>
							<ButtonRm variant="primary" 
								type="submit"
								// onClick={() => avanti()}
							>
								Avanti
							</ButtonRm>
						</div>
					</div>}
				</div>
				<Tarrafari show={show} setAdd={(v) => setShow(v)} />
			</Form>

			<Modal show={categoryModal} onHide={closeCategoryModal} centered className="verifyModal"> 
				<Modal.Header closeButton style={{borderBottom:"none"}} />
				<Modal.Body className="p-5 pt-2">
					<h4 className="text-dark fw-semibold">
					Crea una nuova categoria
					</h4>
					<div className="d-flex flex-column pb-3">
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Nome
							</Form.Label>
							<Form.Control 
								type="text" 
								value={categoryName}
								onChange={handleCategoryName}
							/>
						</Form.Group>
						<button
						type="submit"
						className=" mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
						style={{backgroundColor:path == "/fornitori/create" ? "#83112f" : "#9ccbf2"}}
						onClick={(e) => createCategory(e)}
						>
						Conferma
						</button>
						<button
						type="button"
						className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
						onClick={closeCategoryModal}
						>
						Annulla
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
