import { BASE_URL } from "../../../../api/api";
import fetchBlobUrl from "../../../../utils/fetchBlobUrl";
import React, { useState, useEffect, useRef } from "react";
import { BsDownload, BsTrash, BsPen} from "react-icons/bs";



export default function CarouselCardPartner(props) {
        const [showIcons, setShowIcons] = useState(false);
    
        const handleMouseEnter = () => {
        setShowIcons(true);
        };
    
        const handleMouseLeave = () => {
        setShowIcons(false);
        };
    
        const myUrl = `${BASE_URL}api/v1/portfolio/download/${props.id}/${props.imgName}`;
    
        const [imageUrl, setImageUrl] = useState(null);
    
        useEffect(() => {
            async function getImageUrl() {
                const url = await fetchBlobUrl(myUrl);
                setImageUrl(url);
            }
    
            getImageUrl();
        }, [props.id, props.imgName]);
    
        return (
        <div
            className="carousel-card-pagina m-2 position-relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => props.handleCurrentPortfolioProject(props.id)}
        >
            {(props.editPortfolio && showIcons) && (
            <div className="icons-portfolio-container">
                {/* Aggiungi icone per delete e edit */}
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.handleDelete(props.id)
                    }}>
                    <BsTrash />
                </button>
                <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.handleEdit(props.id)
                    }}>
                    <BsPen />
                </button>
            </div>
            )}
            <img loading="lazy" src={imageUrl} alt="" />
            <p className="text-capitalize">{props.category}</p>
        </div>
        );
    }