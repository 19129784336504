import React, { useEffect, useState } from "react";
import "../assets/css/Documenti.css";
import TopHeading from "../components/TopHeading";
import axios from "axios";
import ModelliBtn from "../components/Documenti/Modelli/ModelliBtn";
import UserTemplate from "../components/Documenti/Modelli/UserTemplate";
import { connect } from "react-redux";
import api, { section_endpoint, model_endpoint, model_upload_endpoint, model_section_assignment_endpoint, model_download_endpoint, BASE_URL  } from "../api/api";
import { BsPlusCircleFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";

import { Modal } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ButtonRm from "../components/ButtonRm";

import UploadBtn from "../components/Documenti/Modelli/UploadBtn";

import SearchInput from "../components/SearchInput";

import { Viewer } from 'react-doc-viewer';

import AddBtn from "../components/AddBtn";

import filter from "../assets/images/icon-sidebar/filter.svg"
import sort from "../assets/images/icon-sidebar/sort.svg"

import UserTemplateDefault from "../components/Documenti/Modelli/UserTemplateDefault";

import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

//import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import FileViewer from "react-file-viewer";
import Docxtemplater from 'docxtemplater';

import { checkUserData } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";

import CustomBadge from "../components/CustomBadge";


function Modelli(props) {
  const [formData, setFormData] = useState([]);
  const [search_name, setSearchName] = useState("");

  //HANDLE ATTACHMENTS
  const [files, setFiles] = useState([]);
  const getModelsBySection = async () => {
    try {
      const res = await api().get(`${section_endpoint}`);
      if (res.data) {
        setFormData(res.data.content);
        console.log(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeModel = async (id) => {
    console.log(id)
    try {
      const res = await api().delete(`${model_endpoint}${id}`);
      if (res.data) {
        setFormData(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
    getModelsBySection();
  };


  //CREATION MODAL

  const [modalNew, setModalNew] = useState(false)

  function showModalNew(){
    setModalNew(true)
  }

  function closeModalNew(){
    setModalNew(false)
    setFiles([])
  }

 


  //NEW DATA

  const modalData = {
    name:"",
    section:"",
    sectionID:""
  }

  const [newModal, setNewModal] = useState(modalData)


  function handleNewModal(e){
    const {name, value} = e.target
    setNewModal(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const [sectionType, setSectionType] = useState("Sezione esistente")

  function handleSectionType(e){
    const { value} = e.target
    setSectionType(value)
  }


  //SECTIONS CREATED

  const [sections, setSections] = useState([])

  const getSections = async () => {
    try {
      const res = await api().get(`${section_endpoint}`);
      if (res.data) {
        setSections(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getModelsBySection();
    getSections()
  }, []);

  useEffect(() => {
    getSections()
  }, [modalNew]);

  //SAVE NEW

  async function saveModel(e){
    console.log("saving")
    e.preventDefault()
    addModel()
    closeModalNew()
    setNewModal (
      modalData
    )
  }

  //POST


  async function createModel(formDataToSendBody) {
    try {
      const response = await api().post(`${model_upload_endpoint}`, formDataToSendBody, {
        headers: {
            'Origin': window.location.origin  // Specifica l'origine della richiesta
        }
    });
      const modelID = response.data[0].id;

        if (sectionType === "Crea nuova") {
          const sectionData = {name: newModal.section}; 
          const sectionResponse = await api().post(`${section_endpoint}`, sectionData);
          const sectionID = sectionResponse.data.id;
    
          await api().post(`${model_section_assignment_endpoint}`, {sectionID: sectionID, modelID: modelID});
          await getModelsBySection()

          } else {
            console.log(modelID, response.data.id)
            const sectionID = newModal.sectionID; 
            await api().post(`${model_section_assignment_endpoint}`, {sectionID: sectionID, modelID: modelID});
            await getModelsBySection()

        }
    } catch (error) {
      console.error(error);
    }
  }
  
  
  
  


  const addModel = async () => {

    console.log("addModel")

    try {  
      let formDataToSendBody = new FormData();
      const file = files[0];


      const blob = new Blob([file]);


      //INVIA ALLEGATO
      formDataToSendBody.append("parts", blob, file.name);

      //INVIA NOME
      formDataToSendBody.append("body", JSON.stringify({ name: newModal.name }));

      createModel(formDataToSendBody)

      } catch (error) {
        console.log(error);
      }
}

console.log(newModal, sectionType)


//HANDLE PREVIEW

  const config = {
    header: false
  }

  const [previewModal, setPreviewModal] = useState(false)

  function showPreviewModal(){
    setPreviewModal(true)
  }

  function closePreviewModal(){
    setPreviewModal(false)
  }

  const [currentPreview, setCurrentPreview] = useState({})

  function handleItemToPreview(id){
    const item = formData.find(item => item.id == id)
    setCurrentPreview(item)
    showPreviewModal()
  }

  function handleItemToPreviewDefault(id){
    //setCurrentPreview(id)
    showPreviewModal()
  }

 


  //BLOB
  const [fileBlob, setFileBlob] = useState(null)
  const [fileBlobName, setFileBlobName] = useState("")
  const [fileTypeBlob,  setFileTypeBlob] = useState("")
  const [soloBlob, setSoloBlob] = useState(null)
  const [viewerUrl, setViewerUrl] = useState("")

  const previewFile = async (id, name) => {
    const fileUrl = `${BASE_URL}api/v1/model/download/${id}/${name}`;
    // Estrai l'estensione del file dal nome del file
    const extension = name.split('.').pop();
    
    const response = await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("user"))?.token
        }`,
        "Content-Type": `application/json`,
      },
      })

      // Assegna il tipo corretto in base all'estensione
    let type;
    switch (extension) {
      case 'doc':
        type = 'application/msword';
        break;
      case 'docx':
        type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'pages':
        type = 'application/vnd.apple.pages';
        break;
      default:
        type = 'application/octet-stream';
}
  
    // Converte il file blob in URL oggetto
    const file = new Blob([response.data], {type});
    const fileURL = URL.createObjectURL(file);

    setSoloBlob(file)
    setFileBlobName(name) //imposta il nome del file da vedere in preview
    setFileBlob(fileURL) //salva l'url del file blob da vedere in preview
    setFileTypeBlob(extension) ////salva estensione file


    //CREO LINK  VIEWER (al momento non funziona)
    setViewerUrl(`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true&chrome=false&mime=${encodeURIComponent(type)}`) 
    showPreviewModal()
  }

  const previewDefaultFile = async (id) => {
    const fileUrl = `https://anthill-service.onrender.com/downloadFileDoc?filename=${id}.pdf`;
    // Estrai l'estensione del file dal nome del file
    const extension = `${id}.pdf`.split('.').pop();
    
    const response = await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
    });

  // Assegna il tipo corretto in base all'estensione
  let type;
  switch (extension) {
    case 'doc':
      type = 'application/msword';
      break;
    case 'docx':
      type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'pages':
      type = 'application/vnd.apple.pages';
      break;
    default:
      type = 'application/octet-stream';
}

  // Converte il file blob in URL oggetto
  const file = new Blob([response.data], {type});
  const fileURL = URL.createObjectURL(file);

  setSoloBlob(file)
  setFileBlobName(id) //imposta il nome del file da vedere in preview
  setFileBlob(fileURL) //salva l'url del file blob da vedere in preview
  setFileTypeBlob(extension) ////salva estensione file


  //CREO LINK  VIEWER (al momento non funziona)
  setViewerUrl(`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true&chrome=false&mime=${encodeURIComponent(type)}`) 
  showPreviewModal()
  };


  const downloadFile = async (id, name) => {
    const fileUrl = `${BASE_URL}api/v1/model/download/${id}/${name}`;
    
    const response = await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("user"))?.token
        }`,
        "Content-Type": `application/json`,
      },
    });
  
    // Converte il file blob in URL oggetto
    const file = new Blob([response.data], {type: 'application/msword'});
    const fileURL = URL.createObjectURL(file);

    //Scarica il file
    window.open(fileURL);
  };

  const downloadFileDefault = async (id) => {
    const fileUrl = `https://anthill-service.onrender.com/downloadFileDoc?filename=${id}.docx`;
    
    const response = await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
      /* headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("user"))?.token
        }`,
        "Content-Type": `application/json`,
      }, */
    });
  
    // Converte il file blob in URL oggetto
    const file = new Blob([response.data], {type: 'application/msword'});
    const fileURL = URL.createObjectURL(file);

    //Scarica il file
    window.open(fileURL);
  };

  console.log("fileBlob", fileBlob, fileBlobName)


  const token = JSON.parse(localStorage.getItem("user"))?.token;

  //const fileUrl = '${BASE_URL}api/v1/model/download/161/pup.docx';  
  
  const sectionsDefault = [
    {
      name: "Notifiche di default",
      color: "orange",
      models: [
        {
          name: "Notifica revoca cedolare",
          id: "Notifica_Revoca_Cedolare",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Notifica cedolare",
          id: "Notifica_Cedolare",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Notifica amministratore",
          id: "Notifica_Amministratore",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Lettere di default",
      color: "green",
      models: [
        {
          name: "Lettera ultimo sollecito",
          id: "Lettera_Ultimo_Sollecito",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Lettera",
          id: "Lettera",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Lettera amministratore",
          id: "Lettera_Amministratore",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Lettera conduttori",
          id: "Lettera_Conduttori",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Fatture di default",
      color: "blue",

      models: [
        {
          name: "Fattura Splitpayment",
          id: "Fattura_Splitpayment",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Fattura rata",
          id: "Fattura_Rata",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Fattura",
          id: "Fattura",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Fattura destinazione",
          id: "Fattura_Destinazione",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Fattura competenza",
          id: "Fattura_Competenza",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Fattura 2",
          id: "Fattura_2",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Disdette di default",
      color: "gray",

      models: [
        {
          name: "Disdetta rinnovo conduttore",
          id: "Disdetta_Rinnovo_Conduttore",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Disdetta scadenza legale",
          id: "Disdetta_Scadenza_Legale",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Disdetta contratto conduttore",
          id: "Disdetta_Contratto_Conduttore",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Disdetta rinnovo",
          id: "Disdetta_Rinnovo",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Denuncie di default",
      color: "purple",

      models: [
        {
          name: "Denuncia non locato",
          id: "Denuncia_Non_Locato",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Deleghe di default",
      color: "salmon",
      models: [
        {
          name: "Delega registrazione",
          id: "Delega_Registrazione",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Delega registrazione telematica società",
          id: "Delega_Registrazione_Telematica_Societa",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Delega registrazione telematica persona",
          id: "Delega_Registrazione_Telematica_Persona",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Delega chiusura riscaldamento",
          id: "Delega_Chiusura_Riscaldamento",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Delega chiusura acqua",
          id: "Delega_Chiusura_Acqua",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Comunicazioni di default",
      color: "lightgreen",

      models: [
        {
          name: "Comunicazione risoluzione anticipata",
          id: "Comunicazione_Risoluzione_Anticipata",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Comunicazione riduzione canone mensile",
          id: "Comunicazione_Riduzione_Canone_Mensile",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Comunicazione riduzione canone",
          id: "Comunicazione_Riduzione_Canone",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Comunicazione proroga",
          id: "Comunicazione_Proroga",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Comunicazione ospite straniero",
          id: "Comunicazione_Ospite_Straniero",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Comunicazione cessione fabbricato",
          id: "Comunicazione_Cessione_Fabbricato",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Avvisi di default",
      color: "pink",

      models: [
        {
          name: "Avviso pagamento",
          id: "Avviso_Pagamento",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Autorizzazioni di default",
      color: "yellow",

      models: [
        {
          name: "Autorizzazione richiesta INAIL",
          id: "Autorizzazione_Richiesta_Inail",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Autorizzazione richiesta INPS",
          id: "Autorizzazione_Richiesta_Inps",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Aggiornamenti di default",
      color: "lightblue",

      models: [
        {
          name: "Aggiornamento ISTAT protratta locazione",
          id: "Aggiornamento_Istat_Protratta_Locazione",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Aggiornamento ISTAT",
          id: "Aggiornamento_Istat",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Verbali di default",
      color: "darkgreen",

      models: [
        {
          name: "Verbale consegna",
          id: "Verbale_Consegna",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Rinnovi di default",
      color: "brown",
      models: [
        {
          name: "Rinnovo fideiussione",
          id: "Rinnovo_Fideiussione",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Ricevute di default",
      color: "violet",
      models: [
        {
          name: "Ricevuta pagamento",
          id: "Ricevuta_Pagamento",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Ricevuta fattura",
          id: "Ricevuta_Fattura",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Ricevuta chiusura locazione",
          id: "Ricevuta_Chiusura_Locazione",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Ricevuta chiusura locazione 2",
          id: "Ricevuta_Chiusura_2",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
    {
      name: "Altro",
      color: "crimson",

      models: [
        {
          name: "Proposta",
          id: "Proposta",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
        {
          name: "Privacy persona",
          id: "Privacy_Persona",
          default: true,
          handleItemToPreview: handleItemToPreviewDefault,
          previewFile: previewDefaultFile,
          downloadFile: downloadFileDefault,
        },
      ],
    },
  ];

  console.log(formData, 'formData')
  //NEW SEARCH & FILTER

	//SEARCH

	const [search, setSearch] = useState("")

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortBy, setSortBy] = useState("più-recenti");

  const [filteredUnits, setFilteredUnits] = useState([])


  const fullArray = formData?.length > 0 ? [...formData, ...sectionsDefault] : [ ...sectionsDefault]

  useEffect(() => {
  if(fullArray?.length > 0)  {
    setFilteredUnits(
      fullArray.filter((unit) => {
    return (
      (selectedCategories.length === 0 || selectedCategories.includes(unit.name)) &&
      unit.name.toLowerCase().includes(search.toLowerCase())
    );
    })
  )}
  }, [search, selectedCategories, formData]);

  const [sortedUnits, setSortedUnits] = useState(filteredUnits);

  useEffect(() => {
  switch (sortBy.toLowerCase()) {
    case "z-a":
    setSortedUnits(sortStringByKey(filteredUnits, "name", false));
    break;
    case "a-z":
    setSortedUnits(sortStringByKey(filteredUnits, "name"));
    break;
   /*  case "meno-recenti":
    setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
    break; */
    default:
    case "a-z":
    setSortedUnits(sortDatesByKey(filteredUnits, "name"));
  }
  }, [filteredUnits, sortBy]);

  function clearFiltersAndSort() {
  setSearch("");
  setSelectedCategories([]);
  setSortBy("più-recenti");
  setFilteredUnits(formData);
  }


  
  //CHECK IF DATA REQUIRED ARE COMPILED
 const [dataRequired, setDataRequired] = useState(true);
 //Gestisce il modale
 const [showCheck, setShowCheck] = useState(false)

 useEffect(() => {
   const fetchData = async () => {
     try {
       const response = await checkUserData();
       setDataRequired(response.isVerified);
       if (!response.isVerified) {
         setShowCheck(true);
       }
     } catch (error) {
       // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
       console.error('Errore durante il recupero dei dati utente:', error);
     }
   };

   fetchData();
 }, []);


  return (
    <>
    <div className="title-search-group">
        <div className="right-header">
          <TopHeading SmallHeading="empty" />
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Modelli</h2>
          </Col>
          <Col className="d-flex justify-content-end ">
              <AddBtn
                tooltip="Nuovo modello"
                className="me-3 me-md-0 order-md-1"
                onClick={showModalNew}
                dataRequired={dataRequired}
              />
          </Col>
        </Row>
      </div>
    
      <Row className="dashboard-container">
      {<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<SearchInput setSearch={setSearch}/>

				<div className="d-flex align-items-center ms-3">
					<div className="user-dropdown">
					<div className="filter">                   
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
						<img loading="lazy" src={filter}/>
						</div>
					<div className="user-dropdown-content">
					<div className="fs-14 fw-bold mb-2">Filtra per</div>
					<div className="fs-14">SEZIONI</div>
					{fullArray?.length >0 && fullArray?.map((category, index) => (
					<label key={index} className="d-block">
						<input
						type="checkbox"
						className="me-2"
						value={category.name}
						checked={selectedCategories.includes(category.name)}
						onChange={(event) =>
							setSelectedCategories((prevCategories) =>
							event.target.checked
								? [...prevCategories, category.name]
								: prevCategories.filter((cat) => cat !== category.name)
							)
						}
						/>
						{category.name}
					</label>
					))}

					<a className="text-decoration-underline text-dark text-center mt-3" onClick={clearFiltersAndSort}>Rimuovi filtri</a>

					
					</div>
					</div>
					</div>
					<div className="user-dropdown">
						<div className="sort d-flex">
				<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
					<img loading="lazy" src={sort}/>
				</div>
						</div>
						<div className="user-dropdown-content">
							<div className="fs-16 fw-bold">Ordina per</div>
							<div
				onClick={() => setSortBy("z-a")}
								className="fs-14 sort-text"
								>
								Z-A
							</div>
				<div
				onClick={() => setSortBy("a-z")}
								className="fs-14 sort-text"
								>
								A-Z
							</div>
				

						</div>
					</div>
				</div>
			</Col>}
        <div>
{/* 
{
      sectionsDefault &&
      sectionsDefault.length > 0 &&
      sectionsDefault?.map((section) => {
        return (
          <div className="my-3">
            <p className="fw-bold text-uppercase fs-14">{section.name}</p>
            {section.models?.map((model, index) => (
              <UserTemplateDefault
                key={model.id}
                name={model.name}
                id={model.id}
                default={true}
                handleItemToPreview={handleItemToPreviewDefault}
                previewFile={previewDefaultFile}
                downloadFile={downloadFileDefault}
              />
        ))}
        
      
      </div>
    );
  })
} */}






      
{
  sortedUnits &&
  sortedUnits.length > 0 &&
  sortedUnits.map((section) => {
    return (
      <div className="my-3">
        <p className="fw-bold text-uppercase fs-14">{section.name}</p>
        {section.models?.map((model) => {
          if (model.default) {
            return (
              <UserTemplateDefault
                key={model.id}
                name={model.name}
                id={model.id}
                default={true}
                handleItemToPreview={handleItemToPreviewDefault}
                previewFile={previewDefaultFile}
                downloadFile={downloadFileDefault}
                borderColor={section.color}

              />
            );
          } else {
            return (
              <UserTemplate
                key={model.id}
                name={model.name}
                id={model.id}
                attachments={model.attachment}
                delete={removeModel}
                handleItemToPreview={handleItemToPreview}
                previewFile={previewFile}
                downloadFile={downloadFile}
                borderColor={section.color}
              />
            );
          }
        })}
      </div>
    );
  })
}


        </div>
      </Row>
      
      
{/* 
      {formData &&
        formData.length > 0 &&
        formData.map((section, index) => {
          return (
            <div key={index}>
              {
                section.models.length > 0 && ( 
                  <p key={index} className="fs-16 fw-bold mb-3 mt-4">
                    {section.name}
                  </p>
                )
              }
              

              {section.models.map((model) => {
                if (
                  model.name
                    .toLowerCase()
                    .indexOf(props.searchName.toLowerCase()) !== -1
                )
                  return (
                    <UserTemplate
                      key={model.id}
                      name={model.name}
                      id={model.id}
                      attachments={model.attachment}
                      delete={removeModel}
                    />
                  );
                else return "";
              })}
            </div>
          );
        })} */}


      <Modal
          show={modalNew}
          onHide={closeModalNew}
          centered
      >
          <Modal.Header closeButton style={{border:"none"}}>
          </Modal.Header>
          <Modal.Body className="p-0">
              <div className="px-4">
                <Form>
                <p className="fw-bold fs-24 mb-3">Nuovo Modello</p>
                  <Col className="mb-3">
                      <Form.Group>
                          <Form.Label 
                              className="f-label fs-16 d-flex align-items-center
mb-2"
                          >
                              Identificativo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                          </Form.Label>
                          <Form.Control 
                              type="text" 
                              name="name"
                              value={newModal.name}
                              onChange={handleNewModal}
                              required
                          />
                      </Form.Group>
                  </Col>

                  <Col className="mb-3">
                    <div className="d-flex">
                      <Form.Check
                        inline
                        label="Sezione esistente"
                        type="radio"
                        value="Sezione esistente"
                        checked={sectionType === "Sezione esistente"}
                        onChange={handleSectionType}
                      />
                      <Form.Check
                        inline
                        label="Crea nuova"
                        type="radio"
                        value="Crea nuova"
                        checked={sectionType === "Crea nuova"}
                        onChange={handleSectionType}
                      />
                    </div>
                  </Col>

                  {sectionType == "Crea nuova" ?
                    <Col className="mb-3">
                      <Form.Group>
                          <Form.Label 
                              className="f-label fs-16 d-flex align-items-center mb-2"
                          >
                            Nuova sezione <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                          </Form.Label>
                          <Form.Control 
                              type="text" 
                              name="section"
                              value={newModal.section}
                              onChange={handleNewModal}
                              required
                          />
                      </Form.Group>
                  </Col>:
                  <Col className="mb-3">
                    <Form.Group >
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Sezione di riferimento <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                        </Form.Label>
                        <Form.Select
                            name="sectionID"
                            value={newModal.sectionID}
                            onChange={handleNewModal}
                            required
                        >
                          <option>Seleziona</option>
                            {sections.length > 0 &&
                            sections.map(item => {
                              return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                  
                  }

                <Col className="mb-3">
                  <UploadBtn setFiles={setFiles} format='.doc,.docx,.pages'/>
                </Col>


                  
                  <div className="my-3 d-flex flex-column align-items-center justify-content-center"  >
                  <ButtonRm variant="primary" onClick={saveModel} disabled={(newModal.name && files.length > 0) ? false : true}>Salva</ButtonRm>
                  <ButtonRm variant="text" onClick={closeModalNew}>Annulla</ButtonRm>
                  </div>
                </Form>
              </div>
          </Modal.Body>
      </Modal>  


      <Modal show={previewModal} onHide={closePreviewModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualizza documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {/* {viewerUrl && <iframe
          src={viewerUrl}
          style={{ width: '100%', height: '500px' }}
          /> }  */}

       {/* {fileBlob && <DocViewer
        documents={{
          uri: fileBlob,
          fileName: fileBlobName,
          fileType: {fileTypeBlob}
        }}
        pluginRenderers={DocViewerRenderers}
    
      />}  */}

        {fileBlob &&
          <FileViewer
          fileType={fileTypeBlob}
          filePath={fileBlob}
          errorComponent={() => <div>Impossibile visualizzare il file</div>}
          style={{ overflowX: "hidden" }}
        />
      }  

        </Modal.Body>
      </Modal>

      <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
			<p className="mb-3">Per procedere con l'aggiunta di un modello è necessario completare le seguenti informazioni:</p>
			<p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
			
			{/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
		</ModalCheckUser>
    </>
  );
}
const mapStateToProps = (state) => ({
  searchName: state.profile.searchName,
});

export default connect(mapStateToProps, null)(Modelli)
