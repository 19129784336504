import { SET_ENTERSHOW, CLEAR_ENTERSHOW } from "./types";

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get current users profile
export const setShowEnterCode = (show) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ENTERSHOW,
      payload: show,
    });
  } catch {}
};

export const clearShowEnterCode = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ENTERSHOW
    });
  } catch {}
};
