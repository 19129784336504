import {Link} from 'react-router-dom'
import { Col } from 'react-bootstrap'
import ButtonRm from '../../ButtonRm'

function ImpostazioniChatCard(props){
    return(
    <>
        <Link to={`/statoavanzamentolavori/impostazionichat/${props.section}`}>

            <Col  className="px-0">
                        <div className="firmeCard">
                            <div className="d-flex align-items-center">
                                <div className="btn round-icon-btn">
                                    <i className={`bi bi-${props.icon}`}></i>
                                </div>
                                <div className="text-start ms-2">
                                    <p className="fs-16">{props.name}</p>
                                    <p className="fs-12 py-0 my-0">{props.description}</p>
                                </div>
                                
                            </div>
                            <div>
                                <ButtonRm variant="outline" className="ms-2">Gestisci</ButtonRm>
                            </div> 
                            
                    </div>
            </Col>
        </Link>
    </>
    )
}

export default ImpostazioniChatCard