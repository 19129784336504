import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import searchIcon from "../../../assets/images/search_icon.svg";
import documentIcon from "../../../assets/images/document_icon.svg";
import closeIcon from "../../../assets/images/close_icon.svg";
import CreateDocumenti from "./CreateDocumenti";
import { BsPlusCircleFill } from "react-icons/bs";

import { useNavigate, Link } from "react-router-dom";

import { Row, Col, Container } from "react-bootstrap";
import AddBtn from "../../AddBtn";


const DocumentiBtn = (props) => {
  const navigate = useNavigate()


  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow();

  const [mobile_search, setShowMobileSearch] = useState(-1);
  const showMobileSearch = () => {
    setShowMobileSearch(1);
  };
  
  const closeMobileSearch = () => {
    setShowMobileSearch(0);
  };
  return (
    <>
      <Col className="d-flex justify-content-end ">
        <Link to="/prima-nota/nuova-nota">
              <AddBtn
                tooltip="Nuova prima nota"
                className="me-3 me-md-0 order-md-1"
                onClick={handleShow}
                dataRequired={props.dataRequired}
              />
          </Link>
      </Col>
      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={handleClose}
        centered
        className="center-modal "
      >
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={handleClose}
            className="float-end border-0 bg-white"
          >
            <i className="bi bi-x"></i>
          </Button>
          <CreateDocumenti setShow={setShow}  getDocuments={props.getDocuments}/>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DocumentiBtn;
