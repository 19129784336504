import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

import { useMediaQuery } from "react-responsive";

import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

import { Link, useNavigate } from "react-router-dom";

import ButtonRm from "../../ButtonRm";
import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import SearchInput from "../../SearchInput";
import EmptyStateContainer from "../../EmptyStateContainer";

import EmptyState from "../../../assets/images/unità-empty-state.svg";
import SearchIllustration from "../../../assets/images/search-illustration.svg";
import MapPin from "../../../assets/images/pin-map.png";

import "../../../assets/css/StatoLavoriInCorso.css";

import { UnitàCard, UnitàCardMap, UnitàCardMapMobile } from "./UnitàCard";
import { data } from "./data";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import api, { real_estate_management_endpoint, unit_endpoint, stabili_endpoint, management_re_association_endpoint } from "../../../api/api";

import ListViewUnit from "../Gestioni/ListViewUnit";
import MapViewUnit from "../Gestioni/MapViewUnit";

import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";

import RiassuntoContabilita from "../Chat/RiassuntoContabilita";
import { array } from "prop-types";

import RiassuntoContabilitaUnità from "../Chat/RiassuntoContabilitàUnità";
import { parseUnits } from "../contabilità-logic";
import { useDispatch, useSelector } from "react-redux";

import Select from 'react-select';
import { checkUserRole } from "../../../utils/checkUserRole";

import { checkUserData } from "../../../utils/checkUserRole.js";
import ModalCheckUser from "../../ModalCheckUser.js";

function parseCoordinate(coord) {
  if (typeof coord === 'string') {
    return parseFloat(coord);
  }
  return coord;
}

const defaultZoom = 5;

/* const coordinateArr = data.map((item) => item.coordinate); */

//Modify map view
function ChangeView({ center, zoom }) {
  const map = useMap();
  map.flyTo(center, zoom);
  return null;
}

export default function Unità() {
  const [searchTerm, setSearchTerm] = useState("");
  const [viewType, setViewType] = useState("list");


  //GO TO UNIT CREATION E STABILI CREATION
  const navigate = useNavigate()

  function goToUnitCreation(e){
    e.preventDefault()
    navigate("/real-estate-units/create")
  }

  function goToStabiliCreation(e){
    e.preventDefault()
    navigate("/stabili/create")
  }

  /* get units SLC */
  const [unitsSlc, setUnitsSlc] = useState([]);

  async function getUnitsSlc() {
    try {
      const response = await api().get(`${real_estate_management_endpoint}`);
      if (response.data) {
        const data = response.data.content.map((item) => {
          return {
            ...item,
            coordinate: item.coordinate
            ? JSON.parse(item.coordinate.replace(/'/g, '"'))
            : ""
          };
        });
        setUnitsSlc(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const accessToken = "WXHLV8GDbjriLU6newUJ0uovevPoYb6iM6FXCz8o7YQz0v6Kt42x5jHSq1xMOZTM";

  function handleViewType() {
    if (viewType === "list") {
      setViewType("map");
    } else {
      setViewType("list");
    }
  }

  function handleCheckbox(id) {
    const newUnità = unitsSlc.map((unit) => {
      return unit.id === id ? { ...unit, checked: !unit.checked } : unit;
    });
    setUnitsSlc(newUnità);
  }

  const [selectedList, setSelectedList] = useState([]) //lista checkbox selezionati

  const searchedList = unitsSlc?.filter((unit) =>
    unit.name?.toLowerCase().includes(searchTerm?.toLocaleLowerCase())
  );

  //MANAGE OPEN-CLOSE NEW UNIT/CONDO MODAL
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //CHANGE MARKER ICON
  function myIcon() {
    return L.icon({
      iconUrl: require("../../../assets/images/pin-map.png"),
      iconSize: 35,
    });
  }


   //zoom on a pin
   function zoomOnAPin(coordinate){
    console.log("sto zommando")
    if(coordinate && coordinate[0] != null && coordinate[1]!= null)
        {setPinZoomed(prev => {
            return {
                ...prev,
                coordinate,
                zoom: 18
            }
        })}
}

  //Settings for carousels Unit map card mobile
  let settings = {
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function handleCheckbox(id){
    const newUnits = unitsSlc.map(unit => {
        return unit.id===id ?
                {...unit, checked: !unit.checked}:
                unit
    })
    setUnitsSlc(newUnits)
  }

  //DELETE UNIT FROM THIS GESTIONE
    const onDelete = async (id) => {
      try {
      await api().delete(
          `${real_estate_management_endpoint}${id}`
          //                      {headers: {"Authorization" : `Bearer ${token}`}}
      );
      await getUnitsSlc();
      } catch (error) {
      console.log(error);
      }
  };

  const [showInfo, setShowInfo] = useState(false)

  function handleDelete(e) {
      e.preventDefault();
      selectedList.forEach((item) => {
          if(item.activities.length > 0){
          console.log("Questa unità non può essere eliminate perché contiene delle attività associate")
          setShowInfo(true)
          } else {
          onDelete(parseInt(item.id))
          }
      });
  }

 //lista delle unità da sezione unità e stabili per comporre la lista delle opzioni
 const [listaUnità, setListaUnità] = useState([])
 const [listaStabili, setListaStabili] = useState([])
 const [itemIdSelectedToAdd, setItemIdToAdd] = useState({
     id:"",
     type:""
 })
 const [itemSelectedData, setItemSelectedData] = useState({})

  function handleItemSelect(e){
    setItemIdToAdd(JSON.parse(e.target.value))
  }
  
   //GET LIST OF UNITS/CONDOMINIUM AS OPTIONS => value:unit.id, name: unit.name
  async function getUnitsList() {
    try {
        const response = await api().get(`${unit_endpoint}`);
        if(response.data) {
          setListaUnità(response.data?.content)
        }
    }
    catch(error) {
            console.log(error)
    }
  }

  async function getStabiliList() {
    try {
        const response = await api().get(`${stabili_endpoint}`);
        if(response.data) {
            setListaStabili(response.data?.content)
        }
    }
    catch(error) {
            console.log(error)
    }
}

  useEffect(() => {
    getUnitsSlc();
    getUnitsList()
    getStabiliList()
     getUnits()
  }, []);

  //get all data of the unit option selected
  async function getUnitData(id) {
    try {
        const response = await api().get(`${unit_endpoint}${id}`);
        if(response.data) {
            setItemSelectedData(response.data)
        }
    }
    catch(error) {
            console.log(error)
    }
}

 //get all data of the stabili option selected
 async function getStabileData(id) {
  try {
      const response = await api().get(`${stabili_endpoint}${id}`);
      if(response.data) {
          setItemSelectedData(response.data)
      }
  }
  catch(error) {
          console.log(error)
  }
}

async function postItem(data) {
    try {
        await api().post(
            `${real_estate_management_endpoint}`,
            data
        );
        await getUnitsSlc()
    } catch (error) {
        console.log(error);
    }
}

async function editItem(id, data) {
  try {
      await api().put(
          `${real_estate_management_endpoint}${id}`,
          data
      );
      await getUnitsSlc()
  } catch (error) {
      console.log(error);
  }
}

  //BODY TO SEND FOR A POST REQUEST
  const bodyForCreate = {
    name: itemSelectedData?.name,
    // managementID:parseInt(gestioneId),
    unitData: parseInt(itemSelectedData?.id),
    unitID: parseInt(itemSelectedData?.id),
    coordinate: [itemSelectedData.latitude, itemSelectedData.longitude],
    image:itemSelectedData?.image,
    address: `${itemSelectedData.address}, ${itemSelectedData.houseNumber}`,
    realEstateType: itemIdSelectedToAdd.type
  }

  function handleSubmit(e){
    e.preventDefault()
    let str
    if(itemSelectedData){
        str = {
            ...bodyForCreate,
            coordinate: JSON.stringify(bodyForCreate.coordinate).replace(/"/g, "'")
        }
        postItem(str)
    }
    handleClose()
}

console.log(bodyForCreate)

useEffect(() => {
  if(itemIdSelectedToAdd && itemIdSelectedToAdd.type == "unit"){
      getUnitData(itemIdSelectedToAdd.id)
  } else if(itemIdSelectedToAdd && itemIdSelectedToAdd.type == "stabile"){
      getStabileData(itemIdSelectedToAdd.id)
  }
}, [itemIdSelectedToAdd]) 



    //LISTA UNITA GIA CREATE
    const [unitsAlreadyCreated, setUnitsAlreadyCreated] = useState([])
    //TROVA ARRAY DI ID DELLE UNITA GIA' CREATE

    const unitIds = unitsAlreadyCreated.length > 0 && unitsAlreadyCreated.filter(unit => unit.realEstateType == "unit").map(el => el.unitID)
    //TROVA ARRAY DI ID DEGLI STABILI GIA' CREATE
    const stabileIds = unitsAlreadyCreated.length > 0 && unitsAlreadyCreated.filter(unit => unit.realEstateType == "stabile").map(el => el.unitID)

    //FILTRA LA LISTA DI UNITA PRESE DA UNIT ENDPOINT E NON MOSTARRE QUELLE GIA' ASSOCIATE
    let filteredUnitList = unitIds.length > 0 ? listaUnità.filter(elemento => !unitIds.includes(elemento.id)) : listaUnità

    let filteredStabiliList = stabileIds.length > 0 ?listaStabili.filter(elemento => !stabileIds.includes(elemento.id)) : listaStabili

    //GET UNIT ALREADY ASSOCIATED IN SAL
    async function getUnits(){
        try {
            const response = await api().get(`${real_estate_management_endpoint}`);
            setUnitsAlreadyCreated(response.data.content)

        } catch (error) {
        console.log(error);
        }
    }


//NEW SEARCH & FILTER

	//SEARCH


	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

  


    const categories = [
		/* {
		name: 'Tipologia',
		items: [
			"Affitto",
			"Proposta"
		]
		},
		{
		name: 'Unità',
		items: units.map((unit) => unit.name),

		},
		{
			name: 'Gestione',
			items: gestioni.map(el => String(el.id)),
		} */
      
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (unitsSlc.length > 0) {
        setFilteredUnits(
          unitsSlc.filter((el) => {

            const elDate = new Date(el.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && elDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && elDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return el.units.some((unit) => {
						return cat.items.includes(unit.name);
						});
					}  
					})) &&
					el.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, unitsSlc, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(unitsSlc);
		setStartDate("")
		setEndDate("")

		}



  //SE COORDINATE NON ESISTONO DEFAULT SARA' [0,0]
  function isValidCoordinate(coord) {
    if (!Array.isArray(coord) || coord.length !== 2) {
      return false;
    }
  
    const [lat, lng] = coord.map(parseCoordinate);
  
    return typeof lat === "number" && !isNaN(lat) && 
           typeof lng === "number" && !isNaN(lng);
  }

const coordinateArr = sortedItems.length > 0 ? sortedItems.map(item => {
    try {
      const coordinate = item.coordinate;
  
      if (isValidCoordinate(coordinate)) {
        return coordinate.map(parseCoordinate);
      }
  
    } catch (error) {
      // Gestione dell'errore (se necessario)
    }
  
    return [0, 0];
  }) : [[0, 0]];

  const [pinZoomed, setPinZoomed] = useState({
    coordinate: [41.9028, 12.4964],
    zoom: defaultZoom
})


    //RIASSUNTO CONTABILITA' UNITA'

    //prendo le attività dell' unità selezionata
    let attività = selectedList.length > 0 && selectedList[0].activities

    //filtro e prendo solo le attività collegate a una chat
    let attivitàRelatedToChat = attività.length > 0 && attività.filter(item => item.chatGroup.length > 0)

    //prendo tutti i messaggi di ogni chat
    let chatArray = attivitàRelatedToChat.length > 0 && attivitàRelatedToChat.map(item => item.chatGroup[0].chats).flat()

    let array = attivitàRelatedToChat.length > 0 && attivitàRelatedToChat.map(item => item.chatGroup[0].chats)

    const transformedObject = {};


    array.length > 0 && array.forEach((subArray) => {
      subArray.forEach((obj) => {
        const chatGroupName = obj.chatGroupFromChat.name;
        transformedObject[chatGroupName] = transformedObject[chatGroupName] || [];
        transformedObject[chatGroupName].push(obj);
      });
    });
    
    console.log("obj", transformedObject);

    //creo elemento da mandare a riassunto contabilità con questa struttura:
    // {chats: [...chatArray]}

    let objRiassuntoContabilità = {chats: chatArray}


    /* MODAL Riassunto contabilita' */
    const [showRc, setShowRc] = useState(false);
    const handleShowRc = () => setShowRc(true)
    const handleCloseRc = () => setShowRc(false);

      //LOGICA PER RIASSUNTO CONTABILITA'

    const contabilitàUnitàTotale = parseUnits(unitsSlc)
    const contabilitàUnità = selectedList.length > 0? contabilitàUnitàTotale[selectedList[0].name] : {}

    //CAMBIO GESTIONE
    //GET GESTIONI LIST FROM REDUX
    const gestioniList = useSelector((state) => state.gestioniReducer.gestioni);
    
    const [newManagementId, setNewManagementId] = useState("")
    const [showCG, setShowCG] = useState(false)

    function handleManagementId(e){
        setNewManagementId(e.target.value)
    }

    function handleCloseCG(){
        setShowCG(false)
    }

    function handleShowCG(){
        setShowCG(true)
    }

      //CREATE-DISOCIAZIONE-GESTIONE-UNITA
    async function postItemAssociation(data){
        try {
            await api().post(`${management_re_association_endpoint}`, data);
        } catch (error) {
        console.log(error);
        }
    }

      //CREATE-ASSOCIAZIONE-NUOVA GESTIONE-UNITA
    async function postItemDissociation(data){
        try {
            await api()({
                method: 'delete',
                url:`${management_re_association_endpoint}disassociate`,
                data: data
            })
        } catch (error) {
        console.log(error);
        }
    }

    console.log({
      item: selectedList.length>0? selectedList[0] : null,
      newManagementId
  })

    function handleChangeCG(){
        //CONTROLLA SE IMMOBILE HA GIA' UN MANAGEMENT[0]
        const hasManagement =  selectedList.length > 0 && selectedList[0].management.length > 0 ?  selectedList[0].management[0].id : null
        //SE LO HA, ESEGUIRE DISSOCIAZIONE TRA MANAGEMENT[0].ID E SELECTEDLIST[0]
        if(hasManagement) {
            postItemDissociation({managementID: hasManagement,
            unitID: selectedList[0].id})
        }

        //DOPODICHE ESEGUIRE ASSOCIAZIONE TRA SELECTEDLIST[0] E NEWMANAGEMENTID
        postItemAssociation({managementID: newManagementId,
            unitID: selectedList[0].id})

        handleCloseCG()
    }


    //NUOVA UNITA' OPZIONI

    // Creazione dell'array di opzioni per la categoria "Unità"
    const unitOptions = filteredUnitList.map(unit => ({
      value: JSON.stringify({ id: unit.id, type: 'unit' }),
      label: `${unit.name} - Unità`
    }));

    // Creazione dell'array di opzioni per la categoria "Stabili"
    const stabiliOptions = filteredStabiliList.map(stabile => ({
      value: JSON.stringify({ id: stabile.id, type: 'stabile' }),
      label: `${stabile.name} - Stabile`
    }));

    function handleItemSelect(selectedOption) {
      if (selectedOption) {
        const { id, type } = JSON.parse(selectedOption.value);
        setItemIdToAdd({ id, type });
      } else {
        setItemIdToAdd({ id: "", type: "" });
      }
    }

    function getItemName(itemId) {
      const selectedItem = JSON.parse(itemId);
      if (selectedItem.type === "unit") {
        const unit = filteredUnitList.find(unit => unit.id === selectedItem.id);
        return unit ? unit.name : "";
      } else if (selectedItem.type === "stabile") {
        const stabile = filteredStabiliList.find(stabile => stabile.id === selectedItem.id);
        return stabile ? stabile.name : "";
      }
      return "";
    }
      
      //CHECK IF DATA REQUIRED ARE COMPILED
      const [dataRequired, setDataRequired] = useState(true);
      //Gestisce il modale
      const [showCheck, setShowCheck] = useState(false)

      useEffect(() => {
          const fetchData = async () => {
              try {
                  const response = await checkUserData();
                  setDataRequired(response.isVerified);
                  if (!response.isVerified) {
                    setShowCheck(true);
                }
              } catch (error) {
                  // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                  console.error('Errore durante il recupero dei dati utente:', error);
              }
          };

          fetchData();
      }, []);

    //handle modal delete
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = (e) => {
      setShowModal(false);
      e.preventDefault();
      e.stopPropagation();
    };

    const handleShowModal = () => setShowModal(true);
  
    //handle modal permessi
    const [showPermit, setShowPermit] = useState(false);
    const handleClosePermit = (e) => {
      setShowPermit(false);
      e.preventDefault();
      e.stopPropagation();
    };
  
    const handleShowPermit = () => setShowPermit(true);

    //MI SERVE LISTA COLLABORATORI MASTER
    const [members, setMembers] = useState([
    {
      id:1,
      name:"Ema",
      surname:"Mavi",
      email:"ema@gmail.com"
    },
    {
      id:2,
      name: 'Greta',
      surname: 'Pio',
      email: 'gre@gmail.com'
    }
    ])
  
    const handleCheckboxChange = (userId, action) => {
      //da gestire
    };

    // Funzione per eliminare gli stabili selezionati
    const deleteSelected = async () => {
    // Effettua l'operazione di eliminazione per ogni id selezionato
      for (const el of selectedList) {
        try {
          await api().delete(`${unit_endpoint}${el.id}`);
        } catch (err) {
          console.log(err);
        }
    }
      await getUnitsSlc()
      setSelectedList([])
    };

    const handleSelectAll = () => {
      let selectedItems = sortedItems.map((item, index) => item)
      setSelectedList(selectedItems)
    }

    const handleDeselectAll = () => {
      setSelectedList([])
    }

    const handleSelection = (e, id, el) => {
    if(e){
      e.stopPropagation()
    }

    if(selectedList.some(item => item.id == id)){
      setSelectedList(selectedList.filter((item) => item.id !== id));
    } else {
      setSelectedList([...selectedList, el]);
    }
  }

  return (
    <>
      {/* HEADER */}
      <div className="title-search-group mobile-container-xl ">
          <div className="right-header">
              <TopHeading
                  SmallHeading="empty"
              />
          </div>
          <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
              <Col className="d-flex flex-column flex-md-row justify-content-start align-items-start">
                  <h2 className="fw-bold text-capitalize me-4">Unità e Stabili</h2>
                  <div className="d-flex justify-content-center align-items-center ">
                      <a className={`viewType ${viewType === 'list' ? "select" : ''}`}
                      onClick={handleViewType}>
                          <i className="bi bi-list-ul"></i>
                      </a>
                      <a className={`viewType ms-2 ${viewType === 'map' ? "select" : ''}`} 
                      onClick={handleViewType}>
                          <i className="bi bi-map"></i>
                      </a>
                  </div>
              </Col>
              <Col className="d-flex justify-content-end ">
                {(checkUserRole())  && <AddBtn
                  onClick={handleShow}
                  tooltip="Nuova Unità/Stabile"
                  className="me-3 me-md-0 order-md-1"
                  dataRequired={dataRequired}
                />}
              </Col>
          </Row>
      </div>

      {/* LIST VIEW */}
      {viewType === "list" && (
          <ListViewUnit 
              units={unitsSlc}
              sortedItems={sortedItems}
              selectedList={selectedList}
              handleCheckbox={handleCheckbox}
              handleDelete={handleDelete}
              setSearchTerm={setSearchTerm}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              clearFiltersAndSort={clearFiltersAndSort}
              setSortBy={setSortBy}
              categories={categories}
              handleShowRc={handleShowRc}
              setStartDate={setStartDate} setEndDate={setEndDate}
              handleShowCG={handleShowCG}

              handleShowModal={ handleShowModal}
              handleShowPermit={ handleShowPermit}
              handleSelectAll={handleSelectAll}
              handleDeselectAll={handleDeselectAll}
              handleSelection={handleSelection}

          />
      )}

      {/* MAP VIEW */}
      {viewType === "map" && (
        <MapViewUnit 
          units={unitsSlc}
          sortedItems={sortedItems}
          selectedList={selectedList}
          setSearchTerm={setSearchTerm}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          clearFiltersAndSort={clearFiltersAndSort}
          setSortBy={setSortBy}
          categories={categories}
          zoomOnAPin={zoomOnAPin}
          coordinateArr={coordinateArr}
          pinZoomed={pinZoomed}
          setStartDate={setStartDate} setEndDate={setEndDate}

        />
      )}

  {/* <RiassuntoContabilita showRc={showRc} handleCloseRc={handleCloseRc} chat={selectedList.length> 0 ? objRiassuntoContabilità: []}/> */}

     <RiassuntoContabilitaUnità showRc={showRc} handleCloseRc={handleCloseRc} totalCosts={contabilitàUnità && Object.keys(contabilitàUnità).length > 0 ? contabilitàUnità : {}} item={selectedList.length > 0 ? selectedList[0] : {}} onEdit={editItem} type="unit"/>

      {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
      <Modal show={show} onHide={handleClose} className="p-5" centered>
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          className=" pt-md-5 px-md-5"
        >
          <Modal.Title>Nuova Unità o Stabile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-md-5">
        <Form>
          <Select
              placeholder="Seleziona"
              styles={{ control: provided => ({ ...provided, height: '50px' }) }}
              onChange={handleItemSelect}
              value={itemIdSelectedToAdd.id !== "" ? { value: JSON.stringify(itemIdSelectedToAdd), label: getItemName(JSON.stringify(itemIdSelectedToAdd)) } : null}
              options={[
                  ...unitOptions,
                  ...stabiliOptions
              ]}
              />
          <div className="d-flex decorative-div">
              <div className="left-div"></div>
              <p>Oppure</p>
              <div className="right-div"></div>
          </div>
          <div className="d-flex  flex-column  flex-md-row  justify-content-center align-items-center">
              <ButtonRm variant="tertiary" size="small"
              className="me-md-3 mb-2 mb-md-0"
              onClick={goToUnitCreation}>
                  Nuovo Immobile
              </ButtonRm>
              <ButtonRm variant="tertiary" size="small"
              onClick={goToStabiliCreation}>
                  Nuovo Stabile
              </ButtonRm>
          </div>
      </Form>
        </Modal.Body>
        <Modal.Footer
          className="d-flex flex-column px-md-5 pb-md-4"
          style={{ borderTop: "none" }}
        >
          <ButtonRm variant="primary" disabled={!itemSelectedData} onClick={handleSubmit}>Salva</ButtonRm>
          <ButtonRm variant="text" onClick={handleClose}>
            Annulla
          </ButtonRm>
        </Modal.Footer>
      </Modal>

      {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
      <Modal show={showCG} onHide={handleCloseCG} className="p-5"  centered>
                    <Modal.Header closeButton style={{borderBottom:'none'}} className=" pt-md-5 px-md-5">
                    <Modal.Title>Cambia gestione</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="px-md-5">
                        <Form>
                            <Form.Label>Scegli la nuova gestione</Form.Label>
                            <Form.Select style={{height:'50px'}} onChange={handleManagementId} value={newManagementId}>
                                <option value=''>Seleziona</option>
                                { gestioniList.length > 0 &&
                                    gestioniList.filter(item => item.id != selectedList?.[0]?.management?.[0]?.id).map(el => <option key={el.id} value={el.id}>{el.name}</option>)
                                }
                            </Form.Select>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{borderTop: 'none'}}>
                        <ButtonRm variant='primary' disabled={!newManagementId} onClick={handleChangeCG}>Salva</ButtonRm>
                        <ButtonRm variant='text' onClick={handleCloseCG}>Annulla</ButtonRm>
                    </Modal.Footer>
            </Modal>

             {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
             <Modal show={showInfo} onHide={() => setShowInfo(false)} className="p-5"  centered>
                    <Modal.Header style={{borderBottom:'none'}} className=" pt-md-5 px-md-5">
                    <Modal.Title>Rimozione non consentita</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="px-md-5">
                        <p>Non è possibile rimuovere un immobile al quale sono associate delle attività. Se desideri eliminare questo immobile, dovrai prima procedere all'eliminazione di tutte le attività in esso contenute.</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{borderTop: 'none'}}>
                        <ButtonRm variant='primary'  onClick={() => setShowInfo(false)}>Ho capito</ButtonRm>
                    </Modal.Footer>
            </Modal>

            {(checkUserRole())  && <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con l'aggiuntà di unità in SAL, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>}

      <Modal show={showModal} onHide={(e) => handleCloseModal(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleCloseModal(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleCloseModal(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		  </Modal>

      <Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		  </Modal>
    </>
  );
}
