const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    name:'Identificativo',
    address: "Indirizzo",
    houseNumber: "Numero Civico",
    province: "Provincia",
    municipality: "Comune",
    cap: "Cap",
    fiscalCode: "Codice fiscale",
    administrator: "Amministratore",
    name: "Identificativo",
    notes: "Note",
} 


export default translationMapping