import { useState, useEffect } from "react";
import filter_img from "../../../assets/images/filter.svg";
import sort_img from "../../../assets/images/sort.svg";
import { Link } from "react-router-dom";
import ReportCard from "./ReportCard";

import {Row, Col, Modal} from "react-bootstrap"

import filter from "../../../assets/images/icon-sidebar/filter.svg"
import sort from "../../../assets/images/icon-sidebar/sort.svg"

import SearchInput from "../../SearchInput";

import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";
import FilterSearchBar from "../../StatoLavoriInCorso/Gestioni/FilterSearchBar";
import api, { report_endpoint } from "../../../api/api";

import { checkMaster, checkUserRole } from "../../../utils/checkUserRole";
import Alert from "react-bootstrap/Alert";

import { MultiSelect } from "react-multi-select-component";
import ButtonRm from "../../ButtonRm";


const ReportFirstPage = (props) => {

	const [selectedItems, setSelectedItems] = useState([]);

	// Funzione per gestire la selezione multipla
	const handleSelection = (e, id) => {
		if(e){
			e.stopPropagation()
		}

		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
		} else {
			setSelectedItems([...selectedItems, id]);

		}
	};

	const handleSelectAll = () => {
		let selectedItems = props.sortedUnits.map((item, index) => item.id)
		setSelectedItems(selectedItems)
	}

	const handleDeselectAll = () => {
		setSelectedItems([])
	}

	// Funzione per eliminare gli stabili selezionati
	const deleteSelected = async () => {
		// Effettua l'operazione di eliminazione per ogni id selezionato
		for (const id of selectedItems) {
		try {
			await api().delete(`${report_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
		}
		await props.getReport()
		setSelectedItems([]);

	};

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//handle modal permessi
	const [showPermit, setShowPermit] = useState(false);
	const handleClosePermit = (e) => {
		setShowPermit(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShowPermit = () => setShowPermit(true);

	//MI SERVE LISTA COLLABORATORI MASTER
	const [members, setMembers] = useState([
	{
		id:1,
		name:"Ema",
		surname:"Mavi",
		email:"ema@gmail.com"
	},
	{
		id:2,
		name: 'Greta',
		surname: 'Pio',
		email: 'gre@gmail.com'
	}
	])

	const handleCheckboxChange = (userId, action) => {
	//da gestire
	};
	

	
	return (
		<Row className="dashboard-container">
			{<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<FilterSearchBar
					setSearchTerm={props.setSearchTerm}
					selectedCategories={props.selectedCategories}
					setSelectedCategories={props.setSelectedCategories}
					clearFiltersAndSort={props.clearFiltersAndSort}
					setSortBy={props.setSortBy}
					categories={props.categories}
					startDate={props.startDate} 
					endDate={props.endDate}
					setStartDate={props.setStartDate} setEndDate={props.setEndDate}
					alpha={false}
            	/>
			</Col>}
			<div>
			{checkUserRole() &&
                	selectedItems.length > 0 && 
					<div className="d-flex">
						<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
							Seleziona tutti
						</ButtonRm>
						<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
							Deseleziona Tutti
						</ButtonRm>
					</div>
				}
				{ props.sortedUnits.map((item, index) => {
							return (
								<div key={index}>
									{/* <Link to={`/report/edit/${item.id}`}> */}
										<ReportCard
											id={item.id}
											report={item.report}
											fromDate={item.fromDate}
											toDate={item.toDate}
											unit={item.unit}
											owner={item.owner}
											type={item.type}
											deleteReport={props.deleteReport}
											item={item}
											handleSelection={handleSelection}
											selectedItems={selectedItems}
										/>
									{/* </Link> */}
								</div>
							);
				})}
					{/* : []
							.concat(filteredData)
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map((item, index) => {
								return (
									<div key={index}>
										<Link to={`/report/edit/${item.id}`}>
											<ReportCard
												id={item.id}
												report={item.report}
												fromDate={item.fromDate}
												toDate={item.toDate}
												unitName={item.unitName}
												ownerName={item.ownerName}
												type={item.type}
												deleteReport={props.deleteReport}
											/>
										</Link>
									</div>
								);
							})} */}
			</div>
			<Row className="alert-container">
                { 
					checkUserRole() &&
                	selectedItems.length > 0 && 
                    <Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
                        <Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
							Elimina
						</Alert.Link>
						{ ( selectedItems.length == 1 && checkMaster()) &&
						<Alert.Link 
							className="me-2" 
							onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShowPermit();
						}}>
							Permessi
						</Alert.Link>
						}
                    </Alert>
                }
            </Row>

			<Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>
		</Row>
	);
};

export default ReportFirstPage;
