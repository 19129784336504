import React, { useState, useEffect, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Notifications from "react-notifications-menu";
import { Link } from "react-router-dom";

import bell from "../assets/images/bell.svg"
import { checkUserRole } from "../utils/checkUserRole";

import { checkDataToDisplay } from "../utils/dateManipulation";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ChipRm from "./ChipRm";

import {Col} from "react-bootstrap"
import CustomBadge from "./CustomBadge";


const notifications = [
  {
    id: 1,
    content: "EVENT_NAME + DATA INIZIO - DATA FINE",
    ctaType: "view",
    sectionName: "scadenzario",
    elementReferenceId: "",
    cdat: "2024-05-21T09:12:34Z",
    status: "unread"
  },
  {
    id: 3,
    content: "Sei stato aggiunto all’attività ACTIVITY_NAME",
    ctaType: "view",
    sectionName: "SAL",
    elementReferenceId: "",
    cdat: "2024-01-30T08:25:19Z",
    status: "unread"
  },
  {
    id: 5,
    content: "L’attività ACTIVITY_NAME in UNIT_NAME con data di fine lavori prevista per END_DATE. è stata ultimata?",
    ctaType: "confirmActivity",
    sectionName: "SAL",
    elementReferenceId: "",
    cdat: "2024-02-20T11:05:48Z",
    status: "unread"
  },
  {
    id: 6,
    content: "Hai ricevuto un nuovo messaggio nella chat NOME_CHAT",
    ctaType: "view",
    sectionName: "chat",
    elementReferenceId: "",
    cdat: "2024-04-01T10:33:47Z",
    status: "read"
  },
  {
    id: 7,
    content: "Sei stato aggiunto alla conversazione NOME_CHAT",
    ctaType: "view",
    sectionName: "chat",
    elementReferenceId: "",
    cdat: "2024-05-18T12:54:30Z",
    status: "unread"
  },
  {
    id: 8,
    content: "Sei diventato ADMIN della chat NOME_CHAT",
    ctaType: "view",
    sectionName: "chat",
    elementReferenceId: "",
    cdat: "2024-03-10T14:21:37Z",
    status: "read"
  },
  {
    id: 9,
    content: "USER_NAME è entrato in real meta",
    ctaType: "",
    sectionName: "inviti",
    elementReferenceId: "",
    cdat: "2024-02-28T15:40:11Z",
    status: "unread"
  },
  {
    id: 10,
    content: "NOME_UTENTE si vuole collegare con te su real meta",
    ctaType: "onlyAccept",
    sectionName: "inviti",
    elementReferenceId: "",
    cdat: "2024-04-18T18:55:52Z",
    status: "read"
  },
  {
    id: 11,
    content: "Sei stato aggiunto come incaricato di ELEMENT_NAME",
    ctaType: "view",
    sectionName: "Vario",
    elementReferenceId: "",
    cdat: "2024-05-05T09:11:29Z",
    status: "unread"
  },
  {
    id: 12,
    content: "NOME_COLLABORATORE ti ha notificato un pagamento da effettuare",
    ctaType: "view",
    sectionName: "chat",
    elementReferenceId: "",
    cdat: "2024-01-25T07:23:44Z",
    status: "read"
  },
  {
    id: 13,
    content: "NOME_COLLABORATORE vuole creare un messaggio di pagamento nella chat NOME CHAT con le seguenti informazioni: CAUSALE, AMMONTARE, IVA",
    ctaType: "acceptReject",
    sectionName: "chat",
    elementReferenceId: "",
    cdat: "2024-03-07T17:05:10Z",
    status: "unread"
  }
];

export default function TopHeading(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showLogout, setShowLogout] = useState(false);


  const [mobile_menu, setMobileMenu] = useState(false);

  const handleModal = () => {
    if (props.back_modal === undefined) setShow(true);
    else navigate(-1);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  //RETRIEVE INFO ABOUT THE THEME IN REDUX
  const theme = useSelector((state) => state.themeReducer?.section);

  //ADDED CASE: "empty" => no text will appear on the left side of the bar


  /* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
  /* IN QUESTO CASO VALUTO SE L'ULTIMO PATH == create o meno */
	const path = window.location.pathname
  const lastPath = path.split("/")[path.split("/").length - 1];

  

  // Ordina le notifiche per 'cdat' in ordine decrescente
const sortedNotifications = notifications.sort((a, b) => new Date(b.cdat) - new Date(a.cdat));

// Mappa le notifiche per adattarle al componente NOTIFICATION
const arrayForNotifications = sortedNotifications.map(el => {
  return {
  message: (
    <div className="w-100" onClick={ () => navigate(`/alert/notifiche/#${el.id}`)}>
      <p>
        {el.content}
      </p>
      <small className="opacity-50 fs-10">{checkDataToDisplay(el.cdat)}</small>
    </div>
    ),
  }
});

  const [data, setData] = useState(arrayForNotifications);

  const handleNotificationClick = (notification) => {
    console.log(notification.url);
  };

  const [panelSize, setPanelSize] = useState(() => {
    const savedPanelSize = localStorage.getItem('panelSize');
    return savedPanelSize ? JSON.parse(savedPanelSize) : 0;
  });
  

 useEffect(() => {
  // Definisce una funzione di callback che reagisce all'evento
  const handleStorageChange = (event) => {
    if (event.detail.key === 'panelSize') {
      console.log("Nuovo valore di panelSize:", event.detail.newValue);
      setPanelSize(event.detail.newValue)
    }
  };

  // Aggiunge l'ascoltatore per l'evento personalizzato
  window.addEventListener('local-storage-change', handleStorageChange);

  // Pulisce l'ascoltatore quando il componente viene smontato
  return () => {
    window.removeEventListener('local-storage-change', handleStorageChange);
  };
}, []);



const removeTabByIndex = (index) => {
  let pageViews = JSON.parse(localStorage.getItem('tabs')) || [];
  if (index >= 0 && index < pageViews.length) {
    pageViews.splice(index, 1); 
    localStorage.setItem('tabs', JSON.stringify(pageViews));
    //siccome local storage non  posso usarlo per ritriggerare mmount del componente creo un evento custom da inviare alla finestra

    const event = new CustomEvent('tabs-change', {
      detail: {
        key: 'tabs',
        newValue: pageViews
      }
    });
    window.dispatchEvent(event);
  } else {
    console.error("Indice fuori dai limiti");
  }
};

const moveTabToFront = (index) => {
  let pageViews = JSON.parse(localStorage.getItem('tabs')) || [];
  if (index >= 0 && index < pageViews.length) {
    const tab = pageViews.splice(index, 1)[0];
    pageViews.unshift(tab);
    localStorage.setItem('tabs', JSON.stringify(pageViews));

    const event = new CustomEvent('tabs-change', {
      detail: {
        key: 'tabs',
        newValue: pageViews
      }
    });
    window.dispatchEvent(event);
  } else {
    console.error("Indice fuori dai limiti");
  }
};



const [tabs, setTabs] = useState(JSON.parse(localStorage.getItem('tabs')) || [])

const [tabsNav, setTabsNav] = useState( JSON.parse(localStorage.getItem('tabsStatus')))


console.log(tabsNav)


useEffect(() => {
  // Definisce una funzione di callback che reagisce all'evento
  const handleStorageTabChange = (event) => {
    if (event.detail.key === 'tabs') {
      setTabs(event.detail.newValue)
    }
  };

  // Aggiunge l'ascoltatore per l'evento personalizzato
  window.addEventListener('tabs-change', handleStorageTabChange);

  // Pulisce l'ascoltatore quando il componente viene smontato
  return () => {
    window.removeEventListener('tabs-change', handleStorageTabChange);
  };
}, []);



console.log("TABS", tabs)

const tabsRef = useRef(null);

useEffect(() => {
  const containerSc = tabsRef.current;
  const leftArrowSc = containerSc?.previousSibling;
  const rightArrowSc = containerSc?.nextSibling;


  if (!containerSc || !leftArrowSc || !rightArrowSc) {
    console.error("Uno degli elementi di riferimento non è stato trovato");
    return;
  }

  const handleLeftArrowClickSc = () => {
    containerSc.scrollTo({
      left: containerSc.scrollLeft - 100,
      behavior: 'smooth'
    });
  };

  const handleRightArrowClickSc = () => {
    containerSc.scrollTo({
      left: containerSc.scrollLeft + 100,
      behavior: 'smooth'
    });
  };

  leftArrowSc?.addEventListener('click', handleLeftArrowClickSc);
  rightArrowSc?.addEventListener('click', handleRightArrowClickSc);

  return () => {
    leftArrowSc?.removeEventListener('click', handleLeftArrowClickSc);
    rightArrowSc?.removeEventListener('click', handleRightArrowClickSc);
  };
}, []);


	return (
		<>
			<div className="head-bar" 
       style={{background: theme == "property"? "": "",  width: `calc(100vw - ${panelSize }vw)`, 
      }} >
        {props.SmallHeading == "empty" &&  (
					<h3 className="fs-14 text-uppercase m-0"></h3>
				)}
				{props.SmallHeading != "" &&  props.SmallHeading != "empty" && (
					<h3 className="fs-14 text-uppercase m-0">{props.SmallHeading}</h3>
				)}
				{props.SmallHeading == "" && (
					<i className="bi bi-arrow-left" onClick={handleModal} ></i>
				)}
				<div className="right-logo notification-bell-container">
          <Link to={`${checkUserRole() ? "/impostazioni" : "/statoavanzamentolavori/impostazioni"}`} className="text-dark ">
            <h3 className="fs-14 text-uppercase text-decoration-underline mb-0 me-3">
              {JSON.parse(localStorage.getItem('user'))?.name} {JSON.parse(localStorage.getItem('user'))?.surname}
            </h3>
          </Link>
					{/* <img loading="lazy" src={lamp} alt="logo mark" className="logo-mark" />  */}
          <Notifications
            data={data}
            header={{
              title: "Notifiche",
              option: { text: "Vedi tutte", onClick: () => navigate("/alert/notifiche") }
            }}
            icon={bell}
          />
          {/* <span className="icon-link">
              <img loading="lazy" src={icon11} alt="icon" />
            </span> */}
          <i
            className="bi bi-power mt-1"
            style={{ fontSize: "18px" }}
            onClick={() => setShowLogout(true)}
          ></i>
        </div>
      </div>

      <Col sm={12} className="shortcuts-dashboard-container sh-dash-mob align-items-center d-none d-lg-flex">
  <button className="shortcuts-dashboard-left-arrow"><i className="bi bi-caret-left"></i></button>
  <Col className="d-flex shortcuts-container mb-0 " ref={tabsRef} style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
    {tabs?.length > 0 && (
      <Stack direction="row" spacing={1} className="schede-container">
        {tabs.map((el, index) => (
          <ChipRm 
            key={index} 
            el={el} 
            removeTabByIndex={removeTabByIndex} 
            moveTabToFront={moveTabToFront}
            index={index} 
          />
        ))}
      </Stack>
    )}
  </Col>
  <button className="shortcuts-dashboard-right-arrow"><i className="bi bi-caret-right"></i></button>
</Col>
      
      <div>
        <h1 className="fs-32 text-dark fw-bold mt-lg-3 text-start mobile-margin">
          {props.Heading}
        </h1>
      </div>
      <Modal show={show} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            < h3 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi uscire dalla profilazione?
            </h3>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              I dati inseriti verranno persi
            </h6>
            <button
              type="button"
              className="mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              style={{backgroundColor:lastPath == "create" ? "#83112f" : "#9ccbf2"}}
              onClick={() => {
                navigate(-1);
              }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => setShow(false)}
            >
              Indietro
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showLogout} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h3 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi uscire dalla piattaforma?
            </h3>
            <button
              type="button"
              className="mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              style={{backgroundColor:lastPath == "create" ? "#83112f" : "#83112f"}}
              onClick={() => {
                handleLogout()
              }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => setShowLogout(false)}
            >
              Indietro
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
