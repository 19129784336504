import React, { useEffect, useRef } from "react";

//TEXTAREA CHE CAMBIA ALTEZZA A SECONDA DEL CONTENUTO, EVTANDO CHE CI SIA VERTICAL SCROLL

export default function AutoSizeTextarea({ value, onChange }) {
    const textareaRef = useRef(null);

    const adjustHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  
    useEffect(() => {
      adjustHeight();
    }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      style={{ overflow: "hidden" }}
      className="subaccount-input"
      rows={1}
    />
  );
}
