import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { Modal } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';


//import dashboard from '../../assets/images/dashboard-rm.svg'
import responsive from '../../assets/images/responsive.png'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'
import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import logo from "../../assets/images/Group 1189.svg"
import logo2 from "../../assets/images/Group 1000001275.svg"

import blueprint from "../../assets/images/supplier.png"

import building from "../../assets/images/build.png"


//import phonee from "../../assets/images/phonee.svg"

//import stadio from "../../assets/images/casetta.png"
import features from "../../assets/images/dash-play.png"

import loghetto from "../../assets/images/log (2).png"


//import construction from "../../assets/images/pngegg.png"


import binocolo from "../../assets/images/binocolo.png"

import fornitoriImg from "../../assets/images/house-new.png"


import ResponsiveNavbar from './NavRm';

import FAQComponent from './FaqComponent';

import React, { useEffect, useState } from 'react';


import ModalVideo from 'react-modal-video'

//import video from '../../assets/images/video2.mp4'

import { Link } from 'react-router-dom';

import Footer from './Footer';
import IubendaBanner from './Iubenda';

import "../../assets/css/Home.css"

import TypeWriter from '../TypeWriter';

import issues from "../../assets/images/issues2.svg"
import Table from 'react-bootstrap/Table';
import attori from  "../../assets/images/attori-min.png"

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CountUp from 'react-countup';


const staticText = [`Hello, I'm Bruna.`, 'I like'];
const words = ['cats', 'coffee', 'fashion', 'coding', 'skating', 'music'];

const navyColor = "#004b87";

const rmFaqs= [
     

    {
      question: "Sono un proprietario. Posso usare Real Meta Property?",
      answer: "Sì, Real Meta Property è progettato per essere utilizzato non solo da grandi gestori immobiliari ma anche da piccoli proprietari."
    },
    {
      question: "Posso avere dei collaboratori in Real Meat Property? ",
      answer: "Sì, Real Meta Property ti da la possibilità di invitare i tuoi collaboratori, risparmiando sul prezzo unitario e potendo gestire ogni mansione in maniera condivisa"
    },
    {
      question: "Quanto costa Real Meta Property?",
      answer: "Il costo di Real Meta Property dipende dal piano che scegli. Sopri di più nella sezione pacchetti"
    },

  ];



export default function HomeRM (){

    const [isOpen, setOpen] = useState(false)


    useEffect(() => {
        // Creare un nuovo elemento script
        const s1 = document.createElement("script");
        
        // Definire le proprietà dello script come nel tuo esempio
        s1.async = true;
        s1.src = 'https://embed.tawk.to/64e3174094cf5d49dc6b9120/1h8bh5lpr';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        
        // Trovare il primo script nel documento
        const s0 = document.getElementsByTagName("script")[0];
        
        // Inserire il nuovo script prima di quello trovato
        s0.parentNode.insertBefore(s1, s0);
        
        // Opzionale: puoi fare una pulizia rimuovendo lo script quando il componente viene smontato
        return () => {
            s1.remove();
        };
    }, []); // 


    const scrollToModules = () => {
        const modulesSection = document.getElementById('moduli');
        if (modulesSection) {
          modulesSection.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        
        <div style={{background:'#fff'}}>
            <div style={{/*background: 'linear-gradient(180deg, #E6F0FF 0%, rgba(230, 240, 255, 0) 100%)',  backgroundImage: `url(${designUrbano})`, */ backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
                <Container className="px-3 px-md-0">

                    <ResponsiveNavbar/>

                    <Row className="flex-column mb-5 mx-auto" >
                        <Col className="d-flex justify-content-center" style={{padding: "5rem 0rem 1rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center fs-32 fs-md-46 syne-font mb-1">Il tuo alleato per una <Typewriter
                                    options={{
                                        strings: ['Gestione', 'Valorizzazione', 'Rendicontazione', 'Valutazione'],
                                        autoStart: true,
                                        loop: true,
                                        wrapperClassName: "dynamic-text"
                                    }}
                                    /> 
                                    immobiliare di successo</h1>
                                <p className='fs-18 mb-4 d-block d-lg-none'>Lorem ipsum dolor color at qumque salat tautae iuvant</p>
                                <p className='fs-20 mb-4 d-none d-lg-block '>Lorem ipsum dolor color at qumque salat tautae iuvant</p>
                              
                                <Link to="/contatti">
                                <div>
                                    <Button size="lg" variant="primary" style={{background: navyColor, borderColor: "#0F1B4C"}} className=" me-2">Prova gratis</Button>
                                    <Button size="lg" variant="light"  style={{background: "white", borderColor: navyColor}}>Richiedi una consulenza</Button>
                                </div>
                                </Link>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px", marginTop: "5rem"}}>
                                <React.Fragment>
                                    {isOpen && (
                                        <Modal show={isOpen} onHide={() => setOpen(false)} size="lg" centered>
                                        <Modal.Body style={{ position: "relative", paddingTop: "56.25%", overflow: "hidden" }}>
                                            <iframe
                                            loading="lazy"
                                            style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "none", padding: 0, margin: 0 }}
                                            src="https://www.canva.com/design/DAFtqaV_MXA/watch?embed"
                                            allowFullScreen
                                            ></iframe>
                                        </Modal.Body>
                                        </Modal>
                                    )}

                                    

                                    <Col xs={10} md={12} mt={0} onClick={()=> setOpen(true)} style={{cursor: "pointer"}}>
                                        <Image src={features} rounded width={'100%'}/>
                                    </Col>

                                </React.Fragment>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div style={{background: 'rgb(237 241 246)', backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className='py-5 position-relative'>
                <div class="custom-shape-divider-top-1715326080">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill" fill="#FFFFFF"></path>
                            </svg>
                        </div>
                    <Row className="mx-auto mt-5 puntoForza "  style={{maxWidth: "1000px"}}>
                     
                       
                        <Row className="py-5 align-items-center justify-content-between puntoForza1">
                            <Col md={4}>
                            <div>
                                <h1 className="fs-32 fs-md-40 syne-font mb-1">Tutto in un unico spazio</h1>
                                <p className='mb-3 fs-20 text-justify'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                
                                    <Button variant="light"  onClick={scrollToModules} style={{background: "transparent", borderColor: "#0F1B4C"}}>Esplora i moduli</Button>
                            </div>
                            </Col>
                            <Col md={6}>
                                <div className='text-end'>

                                <img src="https://anthillgroup.it/images/img-sal.png" width="100%" className="
                                 d-none d-md-block ms-auto" loading='lazy'/>
                                <img src="https://anthillgroup.it/images/img-sal.png" width="100%" className=" d-block d-md-none" loading='lazy'/>

                                </div>
                            </Col>
                        </Row>

                    </Row>
                </div>

                <div style={{background: '#d9e0e8', backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className='py-5 position-relative'>
                <div class="custom-shape-divider-top-1715326080">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill" fill='rgb(237 241 246)'></path>
                            </svg>
                        </div>
                <Row className="mx-auto mt-5 puntoForza "  style={{maxWidth: "1000px"}}>

                    <Row className="py-5 align-items-center justify-content-between puntoForza2">
                            <Col  md={6} className='order-2 order-md-1'>
                            <div>
                                <img src="https://anthillgroup.it/images/responsive.png" width="100%" className=" d-none d-md-block" loading='lazy'/>
                                <img src="https://anthillgroup.it/images/responsive.png" width="100%" className=" d-block d-md-none" loading='lazy'/>
                            </div>
                            </Col>

                            <Col  md={4} className='order-1 order-md-2'>
                            <div>
                                <h1 className="fs-32 fs-md-40 syne-font mb-1">Compatibile con tutti i dispositivi</h1>
                                <p className='mb-3 fs-20 text-justify'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                <Link to="/tech">
                                    <Button variant="light"  style={{background: "transparent", borderColor: "#0F1B4C"}}>Scopri le tecnologie</Button>
                                </Link>
                            </div>
                            </Col>
                            
                    </Row>
                </Row>
                </div>
                
                {/* NEW */}
                <div style={{background: 'rgb(237 241 246)', backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className='py-5 position-relative'>
                <div class="custom-shape-divider-top-1715326080">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill" fill='#d9e0e8'></path>
                            </svg>
                        </div>
                    <Row className="mx-auto mt-5 puntoForza "  style={{maxWidth: "1000px"}}>
                     
                       
                        <Row className="py-5 align-items-center justify-content-between puntoForza1">
                            <Col md={4}>
                            <div>
                                <h1 className="fs-32 fs-md-40 syne-font mb-1">Fai crescere la tua attività</h1>
                                <p className='mb-3 fs-20 text-justify'>Stai cercando nuove proprietà da gestire? Riceviamo ogni giorno richieste di proprietari in cerca di un gestore. A seconda delle esigenze, creiamo dei match tra loro e i gestori di Real Meta
                                </p>
                                
                                <Link to="/trova-gestore">
                                    <Button variant="light"  style={{background: "transparent", borderColor: "#0F1B4C"}}>Scopri di più</Button>
                                </Link>                            </div>
                            </Col>
                            <Col md={6}>
                                <div className='text-end'>

                                <img src="https://anthillgroup.it/images/img-sal.png" width="100%" className="
                                 d-none d-md-block ms-auto" loading='lazy'/>
                                <img src="https://anthillgroup.it/images/img-sal.png" width="100%" className=" d-block d-md-none" loading='lazy'/>

                                </div>
                            </Col>
                        </Row>

                    </Row>
                </div>

                <div style={{background: '#d9e0e8', backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className='py-5 position-relative'>
                <div class="custom-shape-divider-top-1715326080">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill" fill='rgb(237 241 246)'></path>
                            </svg>
                        </div>
                <Row className="mx-auto mt-5 puntoForza "  style={{maxWidth: "1000px"}}>

                    <Row className="py-5 align-items-center justify-content-between puntoForza2">
                            <Col  md={6} className='order-2 order-md-1'>
                            <div>
                                <img src="https://anthillgroup.it/images/responsive.png" width="100%" className=" d-none d-md-block" loading='lazy'/>
                                <img src="https://anthillgroup.it/images/responsive.png" width="100%" className=" d-block d-md-none" loading='lazy'/>
                            </div>
                            </Col>

                            <Col  md={4} className='order-1 order-md-2'>
                            <div>
                                <h1 className="fs-32 fs-md-40 syne-font mb-1">Risparmia tempo e denaro</h1>
                                <p className='mb-3 fs-20 text-justify'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                <Link to="/pacchetto">
                                    <Button variant="light"  style={{background: "transparent", borderColor: "#0F1B4C"}}>Calcola il risparmio</Button>
                                </Link>
                            </div>
                            </Col>
                            
                    </Row>
                </Row>
                </div>

                <div style={{background: 'rgb(237 241 246)', backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className='py-5 position-relative'>
                <div class="custom-shape-divider-top-1715326080">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill" fill='#d9e0e8'></path>
                            </svg>
                        </div>
                <Row className="mx-auto mt-5 puntoForza "  style={{maxWidth: "1000px"}}>

                    <Row className="py-5 align-items-center justify-content-between puntoForza3">
                            <Col md={4} >
                            <div>
                                <h1 className="fs-32 fs-md-40 syne-font mb-1">Collabora con tutti gli attori</h1>
                                <p className='mb-3 fs-20 text-justify'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                <Link to="/attori">
                                    <Button variant="light"  style={{background: "transparent", borderColor: "#0F1B4C"}}>Scopri gli attori</Button>
                                </Link>
                            </div>
                            </Col>
                            <Col md={6}>
                            <div className='text-end'>
                            <img src={attori} width="100%" className="d-none d-md-block ms-auto" loading='lazy'/>
                                <img src={attori} width="100%" className=" d-block d-md-none" loading='lazy'/>
                                </div>
                            </Col>
                    </Row>
                </Row>
                </div>


            
                <div style={{background: navyColor, backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className='py-5'>
                <Row className="mx-auto text-light" style={{maxWidth: "1100px"}}>
                            <Row className="flex-column flex-md-row align-items-center mb-5 mx-auto justify-content-between " style={{maxWidth: "1100px"}} id="moduli">
                                <Col md={5}>
                                    <div>
                                        <h1 className='fs-32 fs-md-46 syne-font mb-1'>Moduli e funzionalità 
                                            integrate
                                        </h1>
                                        <p>
                                            Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                            garantendo massima efficienza e sicurezza dei dati trattati.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={5}>
                            
                                <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle"/>
                                </Col>
                            </Row>
                            <Row className="flex-column flex-md-row mb-4 gx-md-5 mt-md-5">
                                <Col md={6} className="mb-4 mb-md-0">
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                        <span className='me-2'>
                                        <img src={loghetto} width="24px" />
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Patrimonio</h2>
                                            <p className='mb-3'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                            <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                
                                <Col md={6}>
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                    <span className='me-2'>
                                            <i class="bi bi-cash-coin text-light"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Valutazioni</h2>
                                            <p className='mb-3'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors.
    Code licensed MIT, docs CC BY 3.0.e</p>
                                            <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="flex-column flex-md-row  mb-4 gx-md-5">
                                <Col md={6} className="mb-4 mb-md-0 ">
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                    <span className='me-2'>
                                    <img src={loghetto} width="24px" />
                                    </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Manutenzioni</h2>
                                            <p className='mb-3'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors.
    Code licensed MIT, docs CC BY 3.0.</p>
                                        <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                    <span className='me-2'>
                                    <img src={loghetto} width="24px" />
                                    </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Locazione</h2>
                                            <p className='mb-3' >Designed and built with all the love in the world by the Bootstrap team with the help of our contributors.
    Code licensed MIT, docs CC BY 3.0.</p>
                                            <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row className="flex-column flex-md-row  mb-4 gx-md-5">
                                <Col md={6} className="mb-4 mb-md-0">
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                    <span className='me-2'>
                                    <img src={loghetto} width="24px" />
                                    </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Spese</h2>
                                            <p className='mb-3'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors.
    Code licensed MIT, docs CC BY 3.0.</p>
                                            <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                    <span className='me-2'>
                                    <img src={loghetto} width="24px" />
                                    </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Calcoli</h2>
                                            <p className='mb-3'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors.
    Code licensed MIT, docs CC BY 3.0.</p>
                                            <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className=" flex-column flex-md-row mb-4 gx-md-5">
                                <Col  md={6} className="mb-4 mb-md-0">
                                    <div className='modulo d-flex align-items-start rm-home-module'>
                                    <span className='me-2'>
                                            <img src={loghetto} width="24px" />
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Un mondo di app</h2>
                                            <p className='mb-3'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors.
    Code licensed MIT, docs CC BY 3.0.</p>
                                            <Link to="/features">
                                                <a className='text-uppercase fw-bold text-light'>Link</a>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                </Row>
                </div>
                        
                <Container className="px-3 px-md-0 my-5 ">
                        <Row className="flex-column mb-5 mx-auto py-5"  style={{maxWidth: "1000px"}}>
                            <Col >
                                <div className='text-center'>
                                    <h2 className='syne-font mb-2'>Con Real Meta non c'è patrimonio immobiliare che non possa essere gestito</h2>
                                    <p>Designed and built with all the love in the world by the Bootstrapr</p>
                                </div>
                            </Col>
                            <Col className='mt-4'>
                                <Row className="g-5">
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-hospital fs-40"></i>
                                            <h4 className='syne-font'>Strutture Sanitarie</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-train-front fs-40"></i>
                                            <h4 className='syne-font'>Trasporti e Logistica</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-bank  fs-40"></i>
                                            <h4 className='syne-font'>Pubblica Amministrazione</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-shield fs-40"></i>
                                            <h4 className='syne-font'>Banche e Assicurazioni</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-globe fs-40"></i>
                                            <h4 className='syne-font'>Global Service</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-house fs-40"></i>
                                            <h4 className='syne-font'>Residenziale</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-shop fs-40"></i>
                                            <h4 className='syne-font'>Retail e GDO</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6} md={4} lg={3}>
                                        <div className="target-home text-center">
                                            <i class="bi bi-buildings fs-40"></i>
                                            <h4 className='syne-font'>Industriale</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                </Container>
                
                <div style={{background: 'rgb(237 241 246)', backgroundSize: "contain", backgroundRepeat: "no-repeat"}} className=' py-5 position-relative'>
                        <Row className="flex-column mx-auto" style={{maxWidth: "1100px"}} >
                            <Row className="position-relative">
                                <Col className='flex-column flex-md-row'>
                                {/* <div className='text-center'><h2 className='syne-font mb-3'>I nostri traguardi</h2></div>
                                */}                                
                                <Row className="d-flex flex-column flex-md-row">
                                        <Col className="traguardo-box text-center ">
                                        
                                            <p>Anni d'esperienza</p>
                                            <h2 className='syne-font fs-50 fs-lg-70 mb-3'> <CountUp delay={2} end={5} /></h2>
                                        

                                        </Col>
                                        <Col className="traguardo-box text-center ">
                            
                                            <p>Metri quadri gestiti</p>
                                            <h2 className='syne-font fs-50 fs-lg-70 mb-3'><CountUp delay={2} end={20.000} /></h2>


                                        </Col>
                                        <Col className="traguardo-box text-center ">
                                            <p>Clienti</p>
                                            <h2 className='syne-font fs-50 fs-lg-70 mb-3'><CountUp delay={2} end={40} /></h2>


                                        </Col>
                                        <Col className="traguardo-box text-center ">
                                            <p>Attori coinvolti</p>
                                            <h2 className='syne-font fs-50 fs-lg-70 mb-3'><CountUp delay={2} end={410} /></h2>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>

                        
                </div>

                <Container className="px-3 px-md-0 my-5 ">
                        
                        <h2 className="text-center">Perché scegliere Real Meta?</h2>
                      

                         {/* DESKTOP*/}
                        <Row className="plans d-none d-xl-flex mx-auto" style={{maxWidth: "1000px"}}>
                            <Col className="plan-features mt-5">
                                <div className='plan'>
                                    <div class="plan-head-2">
                                        <h3 class="has-subtitle fs-24"></h3>
                                    </div>
                                </div>
                            <ul className='ps-0'>
                                <li className='feature'>
                                <p>Valutazione Immobiliare <OverlayTrigger
                        placement="top"
                        delay={{ show: 600, hide: 300 }}
                        overlay={
                            <Tooltip className="tooltip-container">Report dettagliato con output di vendita e locazione</Tooltip>
                        }
                        >
                        <i class="bi bi-question-circle"></i>
                        </OverlayTrigger></p>
                                </li>
                                <li className='feature'>
                                <p>Rendicontazione Automatizzata <OverlayTrigger
                        placement="top"
                        delay={{ show: 600, hide: 300 }}
                        overlay={
                            <Tooltip className="tooltip-container">A partire dalla prime note registrate</Tooltip>
                        }
                        >
                        <i class="bi bi-question-circle"></i>
                        </OverlayTrigger></p>
                                </li>
                                <li className='feature'>
                                <p>Chat Interna <OverlayTrigger
                        placement="top"
                        delay={{ show: 600, hide: 300 }}
                        overlay={
                            <Tooltip className="tooltip-container">Per comunicare con gli attori coinvolti</Tooltip>
                        }
                        >
                        <i class="bi bi-question-circle"></i>
                        </OverlayTrigger></p>
                                </li>
                                <li className='feature'>
                                <p>Gestione dei pagamenti <OverlayTrigger
                        placement="top"
                        delay={{ show: 600, hide: 300 }}
                        overlay={
                            <Tooltip className="tooltip-container">Per gestire affitti, pagamenti fornitori online</Tooltip>
                        }
                        >
                        <i class="bi bi-question-circle"></i>
                        </OverlayTrigger></p>
                                </li>

                                <li className='feature'>
                                <p>Punti di intervento su planimetria <OverlayTrigger
                        placement="top"
                        delay={{ show: 600, hide: 300 }}
                        overlay={
                            <Tooltip className="tooltip-container">Per le tue manutenzioni e le attività relative ai tuoi immobili</Tooltip>
                        }
                        >
                        <i class="bi bi-question-circle"></i>
                        </OverlayTrigger></p>
                                </li>
                            </ul>
                            </Col>
                            <Col className="plan-container mt-5">
                                <div className='plan'>
                                    <div class="plan-head">
                                    <h3 class="has-subtitle fs-24">Competitor 1</h3>
                                </div>
                                    
                                    <div className='plan-features'>
                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>


                                    </div>
                                </div>
                            </Col>
                            
                            <Col className="plan-container  mt-5">
                                <div className='plan'>
                                    <div class="plan-head">
                                    <h3 class="has-subtitle fs-24">Competitor 2</h3>
                                    
                            
                                </div>
                                    
                                    <div className='plan-features'>
                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                    </div>
                                </div>
                            </Col>
                        
                            <Col className="plan-container mt-5">
                                <div className='plan'>
                                    <div class="plan-head">
                                    <h3 class="has-subtitle fs-24">Competitor 3</h3>
                                    
                                </div>
                                    
                                    <div className='plan-features'>
                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678069-sign-error-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                    </div>
                                </div>
                            </Col>
                            <Col className="plan-container mt-5">
                                <div className='plan premium'>
                                    <div class="plan-head">
                                    <h3 class="has-subtitle fs-24">Real Meta</h3>
                                    
                                </div>
                                    
                                    <div className='plan-features'>
                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>

                                        <p class="feature regular">
                                            <span class="d-none d-lg-block"><img
                            src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                            width="20px"
                            style={{ display: "flex", margin: "auto" }}
                            ></img></span>                                                        
                                        </p>


                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* <Row  style={{maxWidth: "1100px"}} className="d-md-flex justify-content-md-between align-items-md-center mx-auto py-5">
                            <Col xs={12} md={7} className="d-flex justify-content-center justify-content-md-start mb-5 mb-md-0" style={{padding: ""}}>
                                <div className='d-flex flex-column justify-content-center justify-content-md-start align-items-md-start' >
                                    <p className='text-center text-md-start text-uppercase'>Real meta property</p>
                                    <h1 className="text-center text-md-start  fs-45 syne-font mb-2">
                                    Sei soddisfatto della tua gestione immobiliare?

                                    </h1>
                                    <p>
                                    Sei consapevole del vero potenziale delle tue proprietà? Garantisci loro l'attenzione e la cura che meritano? Gestisci in modo efficace i controlli, i costi e le attività associate? Ci sono dettagli che ti sfuggono?
                                    </p>
                                    <Link to="/contatti">
                                    <Button size="lg" variant="primary" className=" mt-3" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Passa a Real Meta</Button>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={5} className="d-flex justify-content-center justify-content-md-end mt-3">
                            <div className="tech-slideshow">
                                <div className="mover-1" style={{backgroundImage: `url(${issues})`}}></div>
                                <div className="mover-2" style={{backgroundImage: `url(${issues})`}}></div>
                            </div>
                        </Col>
                        </Row> */}

                        <FAQComponent faqs={rmFaqs} />

                    {/*  <Row className="flex-column mb-4 mx-auto">
                            <Col>
                                <div className='text-center'>
                                    <h1 className='syne-font'>Tecnologie</h1>
                                    <p>lorem ipsum dolor</p>
                                </div>
                            </Col>
                            <Col className='mt-4'>
                            <Row> 
    <div class="mb-3 mb-md-0  p-5 px-4 p-md-0  mb-0 mt-0 pt-0" id="tools"> 
            
            <div class="row ">
                
                
                <div class="icon-band mx-auto">
                    <div class="icon-container">
                        <img src="https://e7.pngegg.com/pngimages/704/688/png-clipart-google-google.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1200px-2023_Facebook_icon.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1024px-Bootstrap_logo.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Figma-1-logo.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/242px-Java_programming_language_logo.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" height="50px" class="mx-5"/>
                        <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-1024.png" height="50px" class="mx-5"/>
                        <img src="https://cdn1.iconfinder.com/data/icons/picons-social/57/github_rounded-512.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png
                        " height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/726px-CSS3_logo_and_wordmark.svg.png
                        " height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png
                        " height="50px" class="mx-5"/>
        
                        
                        <img src="https://e7.pngegg.com/pngimages/704/688/png-clipart-google-google.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1200px-2023_Facebook_icon.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1024px-Bootstrap_logo.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Figma-1-logo.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/242px-Java_programming_language_logo.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" height="50px" class="mx-5"/>
                        <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-1024.png" height="50px" class="mx-5"/>
                        <img src="https://cdn1.iconfinder.com/data/icons/picons-social/57/github_rounded-512.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png
                        " height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/726px-CSS3_logo_and_wordmark.svg.png
                        " height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png
                        " height="50px" class="mx-5"/>

    <img src="https://e7.pngegg.com/pngimages/704/688/png-clipart-google-google.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1200px-2023_Facebook_icon.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1024px-Bootstrap_logo.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Figma-1-logo.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/242px-Java_programming_language_logo.svg.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" height="50px" class="mx-5"/>
                        <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-1024.png" height="50px" class="mx-5"/>
                        <img src="https://cdn1.iconfinder.com/data/icons/picons-social/57/github_rounded-512.png" height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png
                        " height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/726px-CSS3_logo_and_wordmark.svg.png
                        " height="50px" class="mx-5"/>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png
                        " height="50px" class="mx-5"/>
        
            
        
                    
                    </div>
                    
                </div>
                
            </div>
        
        </div>
    </Row>
                            </Col>
                        </Row> 
                                        */}




                    {/*  <Row >
                            <h4 className="text-center mb-3 text-uppercase">I nostri punti di forza</h4>
                        </Row>

                        <Row className="mt-2 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px", marginTop:"3rem"}}>
                            <Col md={5}>
                                <h3 className="syne-font">Valutazione Immobiliare</h3>
                                <p>Con pochi dati inseriti, in maniera automatizzata, viene generato un report con i seguenti elementi: <br/> 

    -Indicatori socio-economici <br/>  -Servizi di zona<br/>   -Dinamicità del mercato <br/>  -Ripartizione compravendite -Caratteristiche <br/> dell'immobile <br/>  -Analisi di immobili similari <br/>  -Valori omi <br/>  -Valutazione complessiva ( Comparazione tra Valore Omi, valore medio degli annunci, valore medio del compravenduto e valore suggerito dal nostro algoritmo) <br/> 
    Inoltre potrai modificare i Coefficienti di merito per determinare il tuo valore di suggerimento ed altro ancora...
                                </p>
                            </Col>
                            <Col md={5} className="d-flex justify-content-end">
                                <Image src={evaluation} rounded />
                            </Col>
                        </Row>

                        <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                            <Col md={5} className="order-2 order-md-1">
                                <Image src={fornitoriImg} rounded   width={'100%'}/>
                            </Col>

                            <Col md={5} className="order-1 order-md-2">
                                <h3 className="syne-font">Ricerca fornitori</h3>
                                <p>Potrai trovare professionisti nel settore real estate pronti a soddisfare le tue richieste. Di ogni professionista potrai visualizzare: recensioni, portfolio dei progetti, esperienze, servizi offerti con range di prezzi correlati, certificazioni e tanto altro
                                </p>
                            </Col>
                        </Row>

                        <Row className="mt-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                            <Col md={5}>
                                <h3 className="syne-font">Tutto con un click</h3>
                                <p>Puoi usare Real Meta Property su qualsiasi dispositivo e sistema operativo. Con un solo login avrai accesso a molteplici prestazioni per la gestione a 360° dei tuoi immobili: dalla gestione di affitti e unità, alla gestione 
                                dei lavori di manutenzione (SAL)
                                </p>
                            </Col>
                            <Col md={5}>
                                <Image src={responsive} rounded  width={'100%'} className='my-3'/>
                            </Col>
                        </Row>
    */}
                        {/* <Row className="my-5 mb-2 mb-md-5 justify-content-between align-items-center mx-auto p-5" style={{maxWidth: "1100px", background: "#E7EFF9", borderRadius: "1rem", position: "relative"}}>
                            <Col xs={12} md={5}>
                                <h3 className="syne-font">Opzioni per diverse esigenze</h3>
                                <p className='my-2'>Offriamo pacchetti per soddisfare diversi bisogni</p>
                                <Link to="/pacchetto">
                                <Button variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Scegli il tuo pacchetto</Button>
                                </Link>
                            </Col>
                            <Col md={5}>
                            
                            </Col>
                            <Image src={binocolo} style={{position:"absolute", bottom: "0px" ,right:"0px", width: "200px"}} className='d-none d-md-block'/>
                        </Row> */}

<Row className="mt-5 justify-content-between align-items-center mx-auto p-5 position-relative" style={{maxWidth: "1100px", background: navyColor, borderRadius: "1rem", color:"white"}}>
                        <Col md={6} className="left">
                                <h2 className="syne-font fs-50 mb-4">Inizia con 3 semplici passaggi </h2>
                            



                                <Link to="/contatti">
                                    <Button variant="primary" size="lg" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}}>Richiedi la demo gratuita</Button>
                                </Link>
                                <br/>
                            </Col>
                            <Col md={6} className="left">
                                <p class="mt-2 mb-3  fs-30"><i class="bi bi-1-circle-fill text-light"></i> Crea un account </p>
                                <p class="mt-2 mb-3 fs-30"><i class="bi bi-2-circle-fill text-light"></i> Invita il tuo team</p>
                                <p class="mt-2 mb-3  fs-30"><i class="bi bi-3-circle-fill text-light"></i> Rivoluziona la tua gestione</p>



        
                                <br/>
                            </Col>

                        {/*    <Image src={building} width="300px" style={{ width: "300px",
                                bottom: 0,
                                right: 0,
                                position: "absolute"}}
                                className='d-none d-md-block'
                            /> */}
            
                        </Row>

                        <Row className="my-5 mt-0 mt-md-5 justify-content-between align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                            <Col md={5} className="m2 d-flex justify-content-center align-items-center" >
                                <Image src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg"  className='w-100' style={{minWidth: "500px"}}  />
                            </Col>
                            <Col md={5} class>
                                <h2 className="syne-font">Scarica l'app</h2>
                                <p className='mb-3'>Scarica l'app di Real Meta e monitora da dove vuoi, qualunque cosa desideri.
                                </p>
                                <img loading="lazy" src={btnGp} className="h-44 me-2 mb-2"/>
                                <img loading="lazy" src={btnApple} className="h-44 mb-2"/>
                            </Col>
                        </Row> 

                        {/* <FAQComponent /> */}

                        

                        <Footer />


                    {/*  <Row className="mb-5 justify-content-center align-items-center mx-auto p-5" >
                            <Col md={12} className="text-center">
                                <Navbar.Brand href="#home" className='me-3 mb-3'>
                                    <Image src={logo} />
                                </Navbar.Brand>
                                <p className='mt-2 mb-3'>Real Meta Property è un prodotto di Anthill S.r.l.s</p>
                                <p className='mt-2 mb-3'>Termini e condizioni |  Informativa privacy | Abbonamenti | Condizioni Generali | Le tue preferenze relative alla privacy</p>



                            </Col>
            
                        </Row> */}

                

                </Container>

                

               {/*  <IubendaBanner/> */}

                
            </div>
        </div>
    )
}