import { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap"
import ButtonRm from "../../../ButtonRm"

import api, { tag_endpoint, chat_msg_endpoint } from "../../../../api/api";

export default function TagChat (props) {

         const [tags, setTags] = useState([]);
         const [selectedTag, setSelectedTag] = useState([])
         const handleChange = (event) => {
            setSelectedTag([event.target.value]);
          };
    
              //MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
        //GET
      async function getAll() {
        try {
          const response = await api().get(`${tag_endpoint}`);
          if (response.data.content) {
            setTags(response.data.content);
          }
        } catch (error) {
          console.log(error);
        }
      }
    
      async function putItem(body){
        const response = await api().put(
          `${chat_msg_endpoint}${props.item.id}`,
          body
        )
    
        await props.getOne(props.chat.id)
      
      }
    
      //INSERISCI TAG
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Prepara il body per la richiesta PUT
        const bodyForCreate = {
          message: props.item.message,
          senderEmail: props.item.senderEmail,
          replyID: props.item.replyID,
          attachments: props.item.attachments,
          tag: selectedTag,
          messageType: props.item.messageType,
          content: (props.item.messageType == "event"  || props.item.messageType == "paymentMsg" || props.item.messageType == "verbale" || props.item.messageType == "spesa" || props.item.messageType == "preventivo" ) ? 
          JSON.stringify(props.parsedContent).replace(/"/g, "'") : "",
          deleted: props.item.deleted,
          chatGroupID: props.chat.id
        };
    
       putItem(bodyForCreate);
        
        handleClose() // Chiude il modal dopo l'invio
      };
    
    
      function deleteTag(){
        // Prepara il body per la richiesta PUT
        const bodyForCreate = {
            message: props.item.message,
            senderEmail: props.item.senderEmail,
            replyID: props.item.replyID,
            attachments: props.item.attachments,
            tag: [],
            messageType: props.item.messageType,
            content: (props.item.messageType == "event"  || props.item.messageType == "paymentMsg" || props.item.messageType == "verbale" || props.item.messageType == "spesa" || props.item.messageType == "preventivo" ) ? 
            JSON.stringify(props.parsedContent).replace(/"/g, "'") : "",
            deleted: props.item.deleted,
            chatGroupID: props.chat.id
          };
      
         putItem(bodyForCreate);
      }
    
      useEffect(() => {
        getAll();
      }, []);

      
    return (
        <>
        <div className="mt-3">
        {props.item.tag?.length > 0 ? 
             <div className="tag-chat-2 d-flex align-items-center justify-content-center">
             <i
               className="bi bi-x-lg me-2"
               style={{ cursor: "pointer" }}
               onClick={() => {deleteTag()}}
             ></i>
             <p>{props.item.tag[0]}</p>
           </div> :
            <button className="btn round-icon-btn btn ms-1" onClick={() => {handleShow()}}>#</button>
        }
        </div>

        <Modal show={show} onHide={handleClose} className="p-5" centered>
            <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className=" pt-md-5 px-md-5"
            >
            <Modal.Title>Seleziona Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-md-5 mt-0 pt-0">
                <p className="mb-2">I tag sono elementi che ti permettono di ricercare più velocemente un messaggio</p>
                <Form>
                    <Form.Group
                    className="d-flex flex-column"
                    >
                        <Form.Select 
                            value={selectedTag[0] || ''} 
                            onChange={handleChange}
                        >
                            <option></option>
                            {tags.map((el, index) => {
                                return (
                                    <option key={index} value={el.name}>
                                        {el.name}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer
                className="d-flex flex-column  px-md-5 pb-md-4"
                style={{ borderTop: "none" }}
            >
                <ButtonRm variant="primary" onClick={handleSubmit}>
                    Salva
                </ButtonRm>
                <ButtonRm variant="tertiary" onClick={handleClose}>
                    Annulla
                </ButtonRm>
            </Modal.Footer>
        </Modal>

        </>
    )
}