import React, { useState, useEffect } from "react";
import TopHeading from "../components/TopHeading";
import AffittiBtn from "../components/Affitti/AffittiBtn";
import empty_img from "../assets/images/ph-affitti2.svg";
import AffittiFirstPage from "../components/Affitti/AffittiFirstPage";
import api, { affitti_endpoint, management_endpoint, unit_endpoint } from "../api/api";
import "../assets/css/Affitti.css";

import {Row, Col} from "react-bootstrap"
import { Link } from "react-router-dom";
import AddBtn from "../components/AddBtn";

import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

import { checkUserData } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";

export default function Affitti() {
	const [affitti, setAffitti] = useState([]);
	async function getAffitti() {
		try {
			const response = await api().get(`${affitti_endpoint}`);
			if (response.data) {
				setAffitti(response.data.content);
			}
		} catch (err) {
			console.log(err);
		} 
	}

	useEffect(() => {
		getAffitti();
	}, []);

	const deleteAffitti = (id) => {
		let temp = [...affitti];
		temp = temp.filter((item) => item.id != id);
		setAffitti(temp);
	};

	//WE NEED THESE ITEMS FOR FILTERS

	const [units, setUnits] = useState([]);
	const [gestioni, setGestioni] = useState([]);

	async function getUnits() {
			try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				setUnits(response.data.content);
				console.log(response.data.content);
			}
			} catch (err) {
			console.log(err);
			}
		}
		
		async function getGestioni() {
			try {
			const response = await api().get(`${management_endpoint}`);
			if (response.data) {
				setGestioni(response.data.content);
				console.log(response.data.content);
			}
			} catch (err) {
			console.log(err);
			}
		}

		useEffect(() => {
			getUnits()
			getGestioni()
		}, []);

	//NEW SEARCH & FILTER

	//SEARCH

	const [searchTerm, setSearchTerm] = useState("")

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

    const categories = [
		{
		name: 'Tipologia',
		items: [
			"Affitto",
			"Proposta"
		]
		},
		{
		name: 'Unità',
		items: units.map((unit) => unit.name),

		},
		/* {
			name: 'Gestione',
			items: gestioni.map(el => String(el.id)),
		} */
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("più-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (affitti.length > 0) {
        setFilteredUnits(
			affitti.filter((affitto) => {

            const affittoDate = new Date(affitto.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && affittoDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && affittoDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return affitto.units.some((unit) => {
						return cat.items.includes(unit.name);
						});
					}  else {
						return cat.items.includes(affitto.typology);
					}
					})) &&
					affitto.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, affitti, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(units);
		setStartDate("")
		setEndDate("")

		}


		    //CHECK IF DATA REQUIRED ARE COMPILED
			const [dataRequired, setDataRequired] = useState(true);
			//Gestisce il modale
			const [showCheck, setShowCheck] = useState(false)
		
			useEffect(() => {
				const fetchData = async () => {
					try {
						const response = await checkUserData();
						setDataRequired(response.isVerified);
						if (!response.isVerified) {
						  setShowCheck(true);
					  }
					} catch (error) {
						// Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
						console.error('Errore durante il recupero dei dati utente:', error);
					}
				};
		
				fetchData();
			}, []);
		

	return (
		<>
			<div className="title-search-group mobile-container-xl">
				<div className="right-header">
					<TopHeading SmallHeading="empty" />
				</div>
				<Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
				<Col >
					<h2 className="fw-bold text-start">Affitti</h2>
				</Col>
				<Col className="d-flex justify-content-end ">
					<Link to="/affitti/create">
					<AddBtn
						tooltip="Nuovo affitto"
						className="me-3 me-md-0 order-md-1"
						dataRequired={dataRequired}
					/>
					</Link>
					{/* <SearchInput
					className="order-md-0"
					setSearch={(term) => {
						setSearchTerm(term);
					}}
					/> */}
				</Col>
				</Row>
			</div>

			{affitti.length > 0 ? (
				<AffittiFirstPage data={affitti} deleteAffitti={deleteAffitti} 
				sortedItems={sortedItems}
				searchTerm={searchTerm} 
				setSearchTerm={setSearchTerm}
				selectedCategories={selectedCategories}
				setSelectedCategories={setSelectedCategories}
				clearFiltersAndSort={clearFiltersAndSort}
				setSortBy={setSortBy}
				categories={categories}
				startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}
				setItems={setAffitti}
				getAffitti={getAffitti}
				/>

			) : (
				<Row className="dashboard-container ">
					<div className="mx-auto text-center empty-container">
						<div className="mx-auto d-table w-160">
							<img loading="lazy" src={empty_img} alt="" />
						</div>
						<p className="fs-20 text-secondary text-center">
							Non hai aggiunto ancora nessun affitto
						</p>
					</div>
				</Row>
			)}

	<ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione dell'affitto, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
       
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>
		</>
	);
}
