import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PertinenceCard from "../PertinenceCard";
import PertinenceModal from "../PertinenceModal";
import EmptyEstate from "../../../../assets/images/empty_estate.svg";
import EditBackCheckModal from "./EditBackCheckModal";
import api, { unit_endpoint } from "../../../../api/api";
import { Link } from "react-router-dom";
import ButtonRm from "../../../ButtonRm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";
import { BsPrinter, BsDownload } from "react-icons/bs";



const RelevanceE = ({ pertinence, img, update, editMode, saveState }) => {
	const [units, setUnits] = useState([]);
	async function getUnits() {
		try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				setUnits(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		getUnits();
	}, []);
	const [pertinenceData, setPertinenceData] = useState([]);

	const [show, setShow] = useState(false);
	const [backModalShow, setBackModalShow] = useState(false);

	const init = () => {
		setPertinenceData(pertinence);
	};

	useEffect(() => {
		init();
	}, [pertinence]);

/* 	const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		let data = { pertinence: pertinenceData };
		update(data);
	};

	const cancelEdit = () => {
		setBackModalShow(true);
	}; */
	const saveUnit = (id) => {
		let temp = [...pertinenceData];
		temp.push(units[id]);
		setPertinenceData(temp);
	};
	const remove = (id) => {
		let data = [...pertinenceData];
		data.splice(id, 1);
		setPertinenceData(data);
	};

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		let data = { pertinence: pertinenceData }
		if (saveState === true) {
			update(data);
		} else {
			init();
		}

	}, [saveState, editMode]);

	return (
		<div>
			{/* <div className={`mt-2 ${editMode ? "d-none" : ""}`}>
				<img loading="lazy" src={img} alt="" style={{ height: "300px", width: "100%" }} /> 
			</div>
			<div className={`save-change-btn-group ${editMode ? "" : "d-none"}`}>
				<div className="d-flex align-items-center">
					<div className="edit-btn w-173 me-2" onClick={handleSave}>
						Salva modifiche
					</div>
					<button
						type="button"
						onClick={cancelEdit}
						className="border border-secondary bg-white rounded-3 edit-icon-btn">
						<i
							className="fal fa-times"
							style={{ color: "var(--edit-color)" }}></i>
					</button>
				</div>
			</div> */}
			<div className="edit-content-container-white mt-3">
				<div className={editMode ? "d-none" : ""}>
					
					{pertinenceData?.length > 0 ? (
						<>
						<div className="d-flex">
								
								<ExportButton data={pertinence} mapping={translationMapping} showPrint classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

							</div>
						<div className="row mt-3">
							{pertinenceData.map((item, index) => {
								return (
									<PertinenceCard
										key={index}
										edit_mode={editMode}
										id={index}
										data={item}
										remove={remove}
									/>
								);
							})}
						</div>
						</>
					) : (
						<div className="unit-empty-img">
							<img loading="lazy" src={EmptyEstate} alt="" />
							<span className="img-comment">
								Nessuna pertinenza selezionata
							</span>
						</div>
					)}
					{/* <div className="d-flex mt-2 justify-content-end">
						<div
							className="edit-btn w-144"
							onClick={() => {
								setEditMode(true);
							}}>
							Modifica
						</div>
					</div> */}
				</div>
				<div className={editMode ? "" : "d-none"}>
					<label className="edit-subpage-title">PERTINENZE</label>
					<div className="d-flex mt-3">
						<Link to="/real-estate-units/create">
							<ButtonRm variant="teriary">Nuovo</ButtonRm>
						</Link>
						<ButtonRm 
						onClick={() => {
								setShow(true);
							}} 
						variant="teriary" className="ms-2">Seleziona</ButtonRm>
						
					</div>
					{pertinenceData?.length > 0 ? (
						<div className="row mt-3">
							{pertinenceData.map((item, index) => {
								return (
									<PertinenceCard
										key={index}
										edit_mode={editMode}
										id={index}
										data={item}
										remove={remove}
									/>
								);
							})}
						</div>
					) : (
						<div className="unit-empty-img">
							<img loading="lazy" src={EmptyEstate} alt="" />
							<span className="img-comment">
								Nessuna pertinenza selezionata
							</span>
						</div>
					)}
				</div>
			</div>

			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<PertinenceModal
						table_data={units}
						setShow={setShow}
						saveUnit={saveUnit}
						edit_mode={editMode}
					/>
				</Modal.Body>
			</Modal>
			<Modal
				show={backModalShow}
				onHide={() => {
					setBackModalShow(false);
				}}
				backdrop="static"
				size="lg"
				centered>
				<Modal.Body className="p-5">
					<EditBackCheckModal
						setShow={setBackModalShow}
						init={init}
						//setEditMode={setEditMode}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default RelevanceE;
