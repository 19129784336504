import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { useDispatch, connect } from "react-redux";
import { setShowEnterCode } from "../../../actions/ModalForEnterCode";
import "../../../assets/css/ModalForEnterCode.css";
import InputError from "../../../assets/images/input_error.svg";
import logo2 from "../../../assets/images/logo-red.svg";
import { useNavigate } from "react-router";

const ModalForEnterCode = ({ showForEnterRegister, mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [enterCode, setEnterCode] = useState("");
  const [color, setColor] = useState("#83112F80");

  const handleClose = () => {
    dispatch(setShowEnterCode(false));
    setError(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    dispatch(setShowEnterCode(false));
    if (enterCode.length.length !== 0) {
      if (mode === "extended") navigate("/extendedRegister");
      else if (mode === "short") navigate("/reducedRegister");
    }
    // if the code entered is incorrect, set error true.
    // setError(true);
  };

  const verifyCodeChange = (e) => {
    setEnterCode(e.target.value);

    if (e.target.value.length === 0) {
      setColor("#83112F80");
    } else {
      setColor("#83112F");
    }
  };

  return (
    <Modal
      show={showForEnterRegister}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="verify-Modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} />
      <Modal.Body>
        <img
          src={logo2}
          alt="logo"
          style={{
            marginTop: "78px",
            paddingBottom: "55px",
            margin: "auto",
            display: "flex",
          }}
        />
        <div className="titleEnterCode-div">
          <div style={{ paddingBottom: "22px" }}>
            Inserisci Codice di Profilazione
          </div>

          <form className="form" onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="Inserisci OTP"
              name="enterCode"
              value={enterCode}
              onChange={verifyCodeChange}
              id={error ? "otp_input_error" : ""}
              className="form-control"
              style={{
                fontFamily: "Mulish",
                height: "50px",
                border: "1px solid #BDBDBD",
              }}
            />
            {error && (
              <div className="opt_error_msg">
                <img loading="lazy" src={InputError} alt="input_error"></img>
                <div style={{ paddingLeft: "10px" }}>
                  Il codice inserito non è corretto
                </div>
              </div>
            )}
            <input
              type="submit"
              className="confirm-btn-div"
              value="Continua"
              style={{ marginTop: "30px", backgroundColor: color }}
            />
          </form>
          <div className="resend-div">Invia nuovamente l’OTP</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showForEnterRegister: state.ModalForEnterCode.showForEnterRegister,
});

export default connect(mapStateToProps, { setShowEnterCode })(
  ModalForEnterCode
);
