const StarRating = ({ stelle }) => {
    const stars = [];
  
    // Genera un array di elementi <i> per rappresentare le stelle
    for (let i = 0; i < 5; i++) {
      const starClass = i < stelle ? "bi bi-star-fill" : "bi bi-star";
  
      stars.push(
        <i key={i} className={starClass} ></i>
      );
    }
  
    return (
      <div>
        {stars}
      </div>
    );
  };

  export default StarRating