import React, { useState, useRef, useEffect } from 'react';
import { Tooltip, Overlay } from 'react-bootstrap';
import Slider from '@mui/material/Slider';
import { checkCollaborator } from '../utils/checkUserRole';
import { useNavigate } from 'react-router-dom';
import ButtonRm from './ButtonRm';

const CustomTooltipGeneral = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null); // Riferimento al trigger del tooltip
  const tooltipRef = useRef(null); // Riferimento al contenuto del tooltip
  const navigate = useNavigate()

  // Toggle visibilità tooltip
  const handleTooltipToggle = () => setShow(!show);

  // Funzione da eseguire quando il tooltip si chiude
  const handleTooltipClose = () => {
    console.log("update");
  };

  // Gestione del click al di fuori del tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        target.current && 
        !target.current.contains(event.target) &&
        tooltipRef.current && 
        !tooltipRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Esegui la funzione quando il tooltip si chiude
  useEffect(() => {
    if (!show) {
      handleTooltipClose();
    }
  }, [show]);

  function goSettings(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("Navigating to /impostazioni");
    navigate("/impostazioni");
  }

  return (
    <>
      <span ref={target} onClick={handleTooltipToggle} style={{ cursor: "pointer" }}>
        <i className="bi bi-info-circle ms-2" /* style={{ color: "#2F80ED" }} */></i>
      </span>

      <Overlay
        show={show}
        target={target.current}
        placement="top"
        containerPadding={20}
      >
        <Tooltip className='exclamation-tooltip'>
          <div ref={tooltipRef}>
            {!checkCollaborator() && 
              <div>
                <p>Per poter creare un pagamento in chat devi prima creare un account Stripe</p>
                <br/>
                <ButtonRm variant="text" size="small" onClick={(e) => goSettings(e)} className="text-light fs-14">Vai alle Impostazioni</ButtonRm>
              </div>
            }

            {checkCollaborator() && 
              <div>
                <p>Per poter creare un pagamento in chat il tuo Gestore deve creare prima un account Stripe</p>
              </div>
            }
          </div>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default CustomTooltipGeneral;
