import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../CustomToggle";

import CustomBadge from "../../CustomBadge";

const Amenity = (props) => {
  const [typologyState, setTypologyState] = useState(0);

  useEffect(() => {
    var text = props.formData.typology;
    if (text.includes("Appartamenti") || text.includes("Ville")) {
      setTypologyState(0);
    }
    /*  else if (
      text.includes("ABITAZIONI DI 2°") ||
      text.includes("FASCIA MEDIA")
    ) {
      setTypologyState(1);
    }  */
    else if (text.includes("Uffici")) {
      setTypologyState(2);
    } else if (text.includes("Negozi")) {
      setTypologyState(3);
    } else if (text.includes("Box e autorimesse")) {
      setTypologyState(4);
    } else if (text.includes("Posti auto")) {
      setTypologyState(5);
    } else if (text.includes("Capannoni")) {
      setTypologyState(6);
    } else if (text.includes("Magazzini")) {
      setTypologyState(7);
    } else if (text.includes("Laboratori")) {
      setTypologyState(8);
    }
  }, []);

  const pavment = [
    { name: "Ceramica", value: "ceramic" },
    { name: "Cotto", value: "cotto" },
    { name: "Graniglia", value: "grit" },
    { name: "Gres porcellanato", value: "gres" },
    { name: "Marmette d’epoca", value: "clayTiles" },
    { name: "Marmittoni", value: "tiles" },
    { name: "Marmo", value: "marble" },
    { name: "Materiale vinilico", value: "vinylMaterial" },
    { name: "Monocottura", value: "singleFired" },
    { name: "Moquette", value: "moquette" },
    { name: "Parquet", value: "parquet" },
    { name: "Resina", value: "resin" },
    { name: "Veneziana", value: "venetian" },
    { name: "Veneziana decorata", value: "decoratedVenetianBlind" },
  ];

  const windowAndDoor = [
    { name: "Grate/Inferriate", value: "railings" },
    { name: "Infissi alluminio", value: "aluminumFixtures" },
    { name: "Infissi alluminio/legno", value: "aluminumWoodFixtures" },
    { name: "Infissi legno", value: "woodFixtures" },
    { name: "Infissi pvc", value: "pvcFixtures" },
    { name: "Persiane in alluminio", value: "aluminumShutter" },
    { name: "Persiane in legno", value: "woodShutter" },
    { name: "Porta blindata", value: "securityDoor" },
    { name: "Serrande elettriche", value: "electricShutters" },
    { name: "Tapparelle alluminio", value: "aluminumBlinds" },
    { name: "Tapparelle corazzate", value: "ironcladBlinds" },
    { name: "Tapparelle legno", value: "woodBlinds" },
    { name: "Tapparelle plastica", value: "plasticBlinds" },
    { name: "Tapparelle pvc", value: "pvcBlinds" },
    { name: "Vetri blindati", value: "armoredGlass" },
    { name: "Vetri doppi", value: "doubleGlasses" },
    { name: "Vetri tripli", value: "tripleGlasses" },
    { name: "Zanzariere", value: "mosquitoNets" },
  ];

  const equipment = [
    { name: "Aria condizionata", value: "airConditioning" },
    { name: "Boiler a gas", value: "gasBoiler" },
    { name: "Cassaforte", value: "safe" },
    { name: "Caveau blindato", value: "armoredCaveau" },
    { name: "Doccia sauna", value: "saunaShower" },
    { name: "Fibra ottica", value: "opticFiber" },
    { name: "Fotovoltaico", value: "photovoltaic" },
    { name: "Illuminazione a faretti", value: "spotlightLighting" },
    { name: "Impianti domotica", value: "homeAutomationSystems" },
    { name: "Impianto allarme", value: "alarmSystem" },
    {
      name: "Impianto elettrico certificato",
      value: "certifiedElectricalSystem",
    },
    { name: "Impianto idraulico certificato", value: "certifiedPlumbing" },
    { name: "Impianto irrigazione", value: "irrigationSystem" },
    { name: "Pannelli solari", value: "solarPanels" },
    { name: "Parabola TV", value: "tvDish" },
    { name: "Rete per computer", value: "computerNetwork" },
    { name: "Vasca idromassaggio", value: "jacuzzi" },
    { name: "Videocitofono", value: "videoIntercom" },
  ];

  const furniture = [
    { name: "Armadi a muro", value: "builtinWardrobes" },
    { name: "Arredo bagni ", value: "bathroomFurniture" },
    { name: "Arredo camere", value: "roomFurnishings" },
    { name: "Arredo cucina", value: "kitchenFurniture" },
    { name: "Arredo esterni", value: "outdoorFurniture" },
    { name: "Arredo soggiorno", value: "livingRoomFurniture" },
    { name: "Barbecue", value: "barbecue" },
    { name: "Boiserie", value: "boiserie" },
    { name: "Cabina armadi", value: "walkinCloset" },
    { name: "Camino", value: "fireplace" },
    { name: "Canna fumaria", value: "flue" },
    { name: "Copritermosifoni", value: "radiatorCovers" },
    { name: "Librerie", value: "bookcase" },
    { name: "Sanitari sospesi", value: "sanitarySuspended" },
    { name: "Soppalchi", value: "mezzanines" },
    { name: "Tende parasole", value: "awnings" },
  ];

  const structure = [
    { name: "Cemento armato", value: "reinforcedConcrete" },
    {
      name: "Cemento armato prefabbricato",
      value: "prefabricatedReinforcedConcrete",
    },
    { name: "Intelaiatura in ferro", value: "ironFrame" },
    { name: "Intelaiatura in legno", value: "woodFrame" },
    { name: "Mista Muratura/cemento", value: "masonryConcreteMix" },
    { name: "Muratura portante", value: "loadBearingMasonry" },
  ];

  const cladding = [
    { name: "Ceramica/Piastrella", value: "ceramicTile" },
    { name: "Cortina/Mattoncini", value: "curtainBricks" },
    { name: "Intonaco", value: "plaster" },
    { name: "Intonaco con fregi", value: "plasterWithFriezes" },
    { name: "Intonaco e cortina", value: "plasterCurtain" },
    { name: "Intonaco rustico", value: "rusticPlaster" },
    {
      name: "Lamiere per rivestimenti esterni",
      value: "sheetMetalExternalCladding",
    },
    { name: "Marmo", value: "marbleMaterial" },
    { name: "Pannelli di calcestruzzo", value: "concretePanels" },
    { name: "Pietra/Materiali lapideo", value: "stoneMaterials" },
    { name: "Quarzo plastico", value: "plasticQuartz" },
    { name: "Vetro/Cristallo", value: "glassCrystal" },
  ];

  const condoProperty = [
    { name: "Appartamento portiere", value: "doormanApartment" },
    { name: "Area parcheggio", value: "parkingArea" },
    { name: "Cantina condominiale", value: "condominiumCellar" },
    { name: "Giardino condominiale", value: "condominiumGarden" },
    { name: "Locali commerciali ", value: "businessPremises" },
    { name: "Parco", value: "park" },
    { name: "Sala riunioni", value: "meetingRoom" },
    { name: "Strada privata", value: "privateRoad" },
    { name: "Terrazza condominiale", value: "sharedTerrace" },
  ];
  const condoSevice = [
    { name: "Acqua calda centralizzata", value: "centralizedHotWater" },
    { name: "Antenna centralizzata", value: "centralizedAntenna" },
    {
      name: "Aria condizionata centralizzata",
      value: "centralAirConditioning",
    },
    { name: "Campo da tennis", value: "tennisCourt" },
    { name: "Locale lavanderia", value: "laundry" },
    { name: "Locale palestra", value: "gym" },
    { name: "Parco giochi", value: "playground" },
    { name: "Piscina condominiale", value: "condominiumPool" },
    { name: "Portineria", value: "concierge" },
    { name: "Portineria 24 ore", value: "concierge24Hour" },
    { name: "Portineria part-time", value: "partTimeConcierge" },
    { name: "Videocitofono", value: "buildingVideoIntercom" },
    { name: "Videosorveglianza", value: "videoSurveillance" },
  ];

  const other = [
    { name: "Accesso per disabili", value: "disabledAccess" },
    { name: "Cablato ", value: "wired" },
    { name: "Controllo accessi", value: "accessControl" },
    { name: "Controsoffitto", value: "falseCeiling" },
    { name: "Impianto di allarme", value: "alarmSystem" },
    { name: "Internet a banda larga", value: "broadbandInternet" },
    { name: "BroadbandInternet", value: "emergencyLights" },
    { name: "Parcheggio bici", value: "bikeParking" },
    { name: "Uscita di emergenza ", value: "emergencyExit" },
    { name: "Porta tagliafuoco ", value: "fireDoor" },
    { name: "Reception", value: "reception" },
    { name: "Rilevatori antincendio", value: "fireDetectors" },
    { name: "Edificio di soli uffic", value: "officeBuildingOnly" },
  ];

  const otherNegozi = [
    { name: "Accesso per disabili", value: "disabledAccess" },
    { name: "Cablato ", value: "wired" },
    { name: "aspirazione fumi ", value: "smokeExtraction" },
    { name: "doppio ingresso", value: "doubleEntrance" },
    { name: "Impianto di allarme", value: "alarmSystem" },
    { name: "passo carrabile", value: "toAwayZone" },
    { name: "posizione ad angolo", value: "cornerPosition" },
    { name: "Saracinesche automatiche", value: "automaticPortcullis" },
    { name: "Uscita di emergenza ", value: "emergencyExit" },
    { name: "Porta tagliafuoco ", value: "fireDoor" },
    { name: "Saracinesche manuali", value: "manualPortcullis" },
    { name: "Rilevatori antincendio", value: "fireDetectors" },
    { name: "Vigilanza cctv", value: "cctv" },
    { name: "Canna fumaria", value: "flue" },
  ];

  const handleChange = (e) => {
    let ele = {
      target: { name: "reportReferenceImage", value: e.target.files[0] },
    };
    props.onChange(ele);
  };

  const handleChangeRealEstate = (e) => {
    let ele = {
      target: { name: "realEstateImage", value: e.target.files[0] },
    };
    props.onChange(ele);
  };


  //HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

  return (
    <div className="form-item-align-box d-block">
      <Form>
        <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
        <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="0" activeKey={activeKey}>Descrizione Immobile</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="d-flex flex-column mt-3">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Descrizione
              </label>
              <textarea
                name="description"
                value={props.formData.description}
                onChange={(e) => {
                  props.onChange(e);
                }}
                id=""
                cols="30"
                rows="4"
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
              ></textarea>
            </div>
            </Accordion.Body>
        </Accordion.Item>

      {(typologyState === 0 || typologyState === 1) && (
        <>
          <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="1" activeKey={activeKey}>Rifiniture e accessori interni</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="row mt-3">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Pavimentazione immobile
              </label>
              {pavment.map((item, index) => {
                return <Checkbox key={index} item={item} data={props} />;
              })}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Infissi e serramenti
              </label>
              {windowAndDoor.map((item, index) => {
                return <Checkbox key={index} item={item} data={props} />;
              })}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Impianti e dotazioni
              </label>
              {equipment.map((item, index) => {
                return <Checkbox key={index} item={item} data={props} />;
              })}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Arredi fissi
              </label>
              {furniture.map((item, index) => {
                return <Checkbox key={index} item={item} data={props} />;
              })}
            </div>
            <div className="mt-3">
            <div className="col-md-6 d-flex justify-content-between align-items-end">
              <div className="d-flex flex-column col-sm-8">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Immagine di riferimento nel report
                </label>
                <label
                  className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
                  style={{ height: "50px" }}
                >
                  {props.formData.reportReferenceImage
                    ? props.formData.reportReferenceImage.name
                    : ""}
                </label>
              </div>
              <div className="col-sm-3">
                <label
                  htmlFor="input-file"
                  className="bag-btn w-100 general-btn"
                  style={{ height: "50px", textAlign: "center" }}
                >
                  Scegli
                </label>
                <input
                  type="file"
                  id="input-file"
                  className="d-none"
                  name="image"
                  multiple={false}
                  onChange={handleChange}
                ></input>
              </div>
            </div>
            </div>
          </div>
            </Accordion.Body>
        </Accordion.Item>

        </>
      )}
    
      

      {/* <div className="label-heading mb-3 d-block w-100 mt-3">
        <div className="label-text rel d-inline-block px-2 title-padding">
          <h1 className="fs-16 text-white text-uppercase">
            {(typologyState === 0 || typologyState === 1) &&
              "STRUTTURA & SERVIZI FABBRICATO"}
            {typologyState === 2 && "STRUTTURA E SERVIZI"}
            {(typologyState === 3 ||
              typologyState === 4 ||
              typologyState === 5 ||
              typologyState === 6 ||
              typologyState === 7 ||
              typologyState === 8) &&
              "SERVIZI"}
          </h1>
        </div>
      </div> */}
      {(typologyState === 0 || typologyState === 1) && (
         <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
         <Card.Header>
         <CustomToggle eventKey="2" activeKey={activeKey}>Struttura e servizi</CustomToggle>
         </Card.Header>
         <Accordion.Body>
          <>
         <div className="row mt-3">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <label className="mb-2 fw-semibold color-black">
              Struttura portante edificio
            </label>
            {structure.map((item, index) => {
              return <Checkbox key={index} item={item} data={props} />;
            })}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <label className="mb-2 fw-semibold color-black">
              Rivestimento esterno edificio
            </label>
            {cladding.map((item, index) => {
              return <Checkbox key={index} item={item} data={props} />;
            })}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <label className="mb-2 fw-semibold color-black">
              Proprietà condominiali
            </label>
            {condoProperty.map((item, index) => {
              return <Checkbox key={index} item={item} data={props} />;
            })}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <label className="mb-2 fw-semibold color-black">
              Servizi condominiali
            </label>
            {condoSevice.map((item, index) => {
              return <Checkbox key={index} item={item} data={props} />;
            })}
          </div>
        </div>
        <div className="mt-3">
        <div className="col-md-6 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column col-sm-8">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Immagine di riferimento nel report
            </label>
            <label
              className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
              style={{ height: "50px" }}
            >
              {props.formData.realEstateImage
                ? props.formData.realEstateImage.name
                : ""}
            </label>
          </div>
          <div className="col-sm-3">
            <label
              htmlFor="input-file2"
              className="bag-btn w-100 general-btn"
              style={{ height: "50px", textAlign: "center" }}
            >
              Scegli
            </label>
            <input
              type="file"
              id="input-file2"
              className="d-none"
              name="realEstateImage"
              multiple={false}
              onChange={handleChangeRealEstate}
            ></input>
          </div>
        </div>
      </div>
        </>
         </Accordion.Body>
        </Accordion.Item>
        
      )}
      
      {typologyState === 2 && (
        <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="3" activeKey={activeKey}>Disposizione interni</CustomToggle>
        </Card.Header>
        <Accordion.Body>
          <>
        <div className="row mt-3">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <label className="mb-2 fw-semibold color-black">
              Disposizione Interni
            </label>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="openSpace"
                checked={props.formData.openSpace}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Open space</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="dividedByWalls"
                checked={props.formData.dividedByWalls}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Divisi da pareti</label>
            </div>
          </div>
          <div className="col-lg-9 col-md-6 col-sm-6">
            <label className="mb-2 fw-semibold color-black">Altro</label>
            {other.map((item, index) => {
              return <Checkbox key={index} item={item} data={props} />;
            })}
          </div>
        </div>
        <div className="mt-3">
        <div className="col-md-6 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column col-sm-8">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Immagine di riferimento nel report
            </label>
            <label
              className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
              style={{ height: "50px" }}
            >
              {props.formData.realEstateImage
                ? props.formData.realEstateImage.name
                : ""}
            </label>
          </div>
          <div className="col-sm-3">
            <label
              htmlFor="input-file2"
              className="bag-btn w-100 general-btn"
              style={{ height: "50px", textAlign: "center" }}
            >
              Scegli
            </label>
            <input
              type="file"
              id="input-file2"
              className="d-none"
              name="realEstateImage"
              multiple={false}
              onChange={handleChangeRealEstate}
            ></input>
          </div>
        </div>
      </div>
        </>
        </Accordion.Body>
        </Accordion.Item>        
      )}
      {/* {(typologyState === 3 ||
        typologyState === 4 ||
        typologyState === 5 ||
        typologyState === 6 ||
        typologyState === 7 ||
        typologyState === 8) && (
        <div className="mt-3">
          <label className="mb-2 fw-semibold color-black">Altro</label>
        </div>
      )} */}
      {typologyState === 3 && (
        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="4" activeKey={activeKey}>Altro</CustomToggle>
        </Card.Header>
        <Accordion.Body>
        <>
        <div>
          {otherNegozi.map((item, index) => {
            return <Checkbox key={index} item={item} data={props} />;
          })}
        </div>
        <div className="mt-3">
        <div className="col-md-6 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column col-sm-8">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Immagine di riferimento nel report
            </label>
            <label
              className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
              style={{ height: "50px" }}
            >
              {props.formData.realEstateImage
                ? props.formData.realEstateImage.name
                : ""}
            </label>
          </div>
          <div className="col-sm-3">
            <label
              htmlFor="input-file2"
              className="bag-btn w-100 general-btn"
              style={{ height: "50px", textAlign: "center" }}
            >
              Scegli
            </label>
            <input
              type="file"
              id="input-file2"
              className="d-none"
              name="realEstateImage"
              multiple={false}
              onChange={handleChangeRealEstate}
            ></input>
          </div>
        </div>
      </div>
        </>
        </Accordion.Body>
    </Accordion.Item>
      )}
      {typologyState === 4 && (
        <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="5" activeKey={activeKey}>Altro</CustomToggle>
        </Card.Header>
        <Accordion.Body>
        <>
        <div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="electricCarChargingStation"
              checked={props.formData.electricCarChargingStation}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">
              Stazione di ricarica auto elettrica
            </label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="electricDoor"
              checked={props.formData.electricDoor}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Porta elettrica</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="accessibility24h"
              checked={props.formData.accessibility24h}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Accessibilità 24h</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="disabledAccess"
              checked={props.formData.disabledAccess}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Accesso per disabili</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="cctv"
              checked={props.formData.cctv}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Vigilanza cctv</label>
          </div>
        </div>
        <div className="mt-3">
        <div className="col-md-6 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column col-sm-8">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Immagine di riferimento nel report
            </label>
            <label
              className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
              style={{ height: "50px" }}
            >
              {props.formData.realEstateImage
                ? props.formData.realEstateImage.name
                : ""}
            </label>
          </div>
          <div className="col-sm-3">
            <label
              htmlFor="input-file2"
              className="bag-btn w-100 general-btn"
              style={{ height: "50px", textAlign: "center" }}
            >
              Scegli
            </label>
            <input
              type="file"
              id="input-file2"
              className="d-none"
              name="realEstateImage"
              multiple={false}
              onChange={handleChangeRealEstate}
            ></input>
          </div>
        </div>
      </div>
        </>
        </Accordion.Body>
    </Accordion.Item>

      )}
      {typologyState === 5 && (
        <Accordion.Item eventKey="6" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="6" activeKey={activeKey}>Altro</CustomToggle>
        </Card.Header>
        <Accordion.Body>
        <>
        <div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="accessibility24h"
              checked={props.formData.accessibility24h}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Accessibilità 24h</label>
          </div>

          <div className="input-checkbox">
            <input
              type="checkbox"
              name="disabledAccess"
              checked={props.formData.disabledAccess}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Accesso per disabili</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="cctv"
              checked={props.formData.cctv}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Vigilanza cctv</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="alarmSystem"
              checked={props.formData.alarmSystem}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Impianto d’allarme</label>
          </div>
        </div>
        <div className="mt-3">
        <div className="col-md-6 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column col-sm-8">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Immagine di riferimento nel report
            </label>
            <label
              className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
              style={{ height: "50px" }}
            >
              {props.formData.realEstateImage
                ? props.formData.realEstateImage.name
                : ""}
            </label>
          </div>
          <div className="col-sm-3">
            <label
              htmlFor="input-file2"
              className="bag-btn w-100 general-btn"
              style={{ height: "50px", textAlign: "center" }}
            >
              Scegli
            </label>
            <input
              type="file"
              id="input-file2"
              className="d-none"
              name="realEstateImage"
              multiple={false}
              onChange={handleChangeRealEstate}
            ></input>
          </div>
        </div>
      </div>
        </>
        </Accordion.Body>
    </Accordion.Item>

      )}

      {(typologyState === 6 || typologyState === 7 || typologyState === 8) && (
        <Accordion.Item eventKey="7" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="7" activeKey={activeKey}>Altro</CustomToggle>
        </Card.Header>
        <Accordion.Body>
        <>
        <div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="toAwayZone"
              checked={props.formData.toAwayZone}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Passo carrabile</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="disabledAccess"
              checked={props.formData.disabledAccess}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Accesso per disabili</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="cctv"
              checked={props.formData.cctv}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Vigilanza cctv</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="alarmSystem"
              checked={props.formData.alarmSystem}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Impianto d’allarme</label>
          </div>
          <div className="input-checkbox">
            <input
              type="checkbox"
              name="fencedProperty"
              checked={props.formData.fencedProperty}
              onChange={(e) => {
                props.onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Proprietà recintata</label>
          </div>
        </div>
        <div className="mt-3">
        <div className="col-md-6 d-flex justify-content-between align-items-end">
          <div className="d-flex flex-column col-sm-8">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Immagine di riferimento nel report
            </label>
            <label
              className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
              style={{ height: "50px" }}
            >
              {props.formData.realEstateImage
                ? props.formData.realEstateImage.name
                : ""}
            </label>
          </div>
          <div className="col-sm-3">
            <label
              htmlFor="input-file2"
              className="bag-btn w-100 general-btn"
              style={{ height: "50px", textAlign: "center" }}
            >
              Scegli
            </label>
            <input
              type="file"
              id="input-file2"
              className="d-none"
              name="realEstateImage"
              multiple={false}
              onChange={handleChangeRealEstate}
            ></input>
          </div>
        </div>
      </div>
        </>
        </Accordion.Body>
    </Accordion.Item>

      )}

      
        </Accordion>
      </Form>
    </div>
  );
};

const Checkbox = ({ item, data }) => {
  return (
    <div className="input-checkbox">
      <input
        type="checkbox"
        name={item.value}
        checked={data.formData[item.value]}
        onChange={(e) => {
          data.onChange(e);
        }}
      />
      <label className="f-label fs-16 d-flex align-items-center
me-2">{item.name}</label>
    </div>
  );
};

export default Amenity;
