import React, { useState, useRef, useEffect } from "react";

import TopHeading from "../../TopHeading";

import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ButtonRm from "../../ButtonRm";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';

import { BsPrinter, BsDownload } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { addOwner, removeOwner, editOwner, fetchOwner } from "../../../actions/owner";

import { addCondominium, removeCondominium, editCondominium, fetchCondominium } from "../../../actions/stabile";

import { useMediaQuery } from "react-responsive";

import CustomBadge from "../../CustomBadge";

import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";


export default function EditModello(){

    const initialContent = `CONVOCAZIONE 
    L’anno 0000, il giorno gg, del mese di ………………………….. , alle ore 00:00, presso …………………………………………………….. , si è riunita l’Assemblea ordinaria dei soci dell’Associazione ………………………………………………………………….
    Assume la presidenza dell’Assemblea, ai sensi dell’Art. …………………………… del vigente statuto sociale il Sig. Cognome Nome, verbalizza il Sig. Cognome Nome.
    Il Presidente costatato che l’Assemblea è stata regolarmente convocata mediante avviso affisso presso la sede sociale (oppure spedito ai soci) così come previsto dall’art. …………….. dello statuto, contenente il seguente o.d.g.:
    ……………………
    ……………………
    ……………………
    ……………………
    Varie ed eventuali
    l’ora e il luogo della seduta e che trascorsa oltre mezz’ora dall’orario stabilito nell’avviso di convocazione sono presenti n° ….. soci su n° …… iscritti all’Associazione, fa rilevare che il numero dei soci presenti raggiunge / non raggiunge quello richiesto dallo statuto per la validità delle assemblee in prima convocazione.
    Dichiara pertanto che l’assemblea è / non è validamente costituita al fine di deliberare su quanto posto dall’ordine del giorno.
    
    Solo se l’assemblea non è validamente costituita
    Dichiara quindi la riunione rinviata alla seconda convocazione già fissata per il giorno gg/mm/aa, alle ore 00:00, presso la stessa sede.
    
    Letto, confermato e sottoscritto
    
        Il Segretario 								Il Presidente
    
    ……………………………………………………………..…			……………………………………………………………….`;
    const contentState = ContentState.createFromText(initialContent);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));

    const [contentToSend, setContentToSend] = useState({
        destinatari: [],
        content: ""
    })

    function handleEdit(e){
    const {name, value} = e.target
    setContentToSend(prev => {
        return {
        ...prev,
        [name]: value
        }
    })
    }

    const [type, setType] = useState("persona")

    function handleType(e){
    const { value} = e.target
    setType(value)
    }

    /* STEP */

    const [step, setStep] = useState(1)

    function next(){
        setStep(prev => prev + 1)
    }

    function back(){
        setStep(prev => prev - 1)
    }


    // Controlla se siamo mobile
    const isMobile = useMediaQuery({
		query: "(max-width: 768px)",
	})

    const esempio = [
        {   
            id:1,
            name: "Mario",
            surname: "Guidi",
            methodAvailable: ["email", "pec", "chat", "postalizza"],
            methodFav: "email",
            method1:"email",
            nameInput: "method1",
            sent:"false"
        },
        {
            id:2,
            name: "Nunzio",
            surname: "Guidi",
            methodAvailable: ["email", "pec", "chat"],
            methodFav: "pec",
            method2:"pec",
            sent:"false"
        },
        {
            id:3,
            name: "Giulio",
            surname: "Guidi",
            methodFav: "consegna",
            methodAvailable: ["email", "pec", "chat", "consegna"],
            method3:"consegna",
            sent:"false"
        },
        {
            id:4,
            name: "Fedele",
            surname: "Guidi",
            methodAvailable: ["email", "postalizza", "chat", "consegna"],
            methodFav: "chat",
            method4:"consegna",
            sent:"false"
        },
        {
            id:5,
            name: "Goiele",
            surname: "Guidi",
            methodAvailable: ["postalizza"],
            methodFav: "postalizza",
            method5:"postalizza",
            sent:"false"
        },
    ]

    const [sendDetail, setSendDetail] = useState(esempio)

    function handleMethod(e, id){
        const {name, value, type, checked} = e.target

        const newArr = sendDetail.map((item) => {
            return item.id == id ?
            {...item, [name]: type == "checkbox"? checked : value}:
            item
        })

        setSendDetail(newArr)
    }

    console.log(sendDetail)

    //MODAL POSTALIZZAZIONE

    const [postalizzaModal, setPostalizzaModal] = useState(false)

    function showPostalizzaModal(){
        setPostalizzaModal(true)
    }

    function closePostalizzaModal(){
        setPostalizzaModal(false)
    }



    return(
        <>
        <div className="right-header"> 
            <TopHeading SmallHeading="" />
        </div>
        <Container fluid> 
        <p className="fw-bold fs-24">Modifica documento</p>
        <p>Le modifiche che apporterai a questo documento sovrascriveranno il documento originale. </p>
        <Row className="settings-container flex-column">

            <Col md={6} className="mb-3">
                <Form.Group>
                    <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                    >
                    Nome del modello
                    </Form.Label>
                    <Form.Control 
                        name="name"
                        required
                    />
                    
                </Form.Group>
            </Col>

            <hr className="my-3 opacity-25" />

            <Col className="border editor-model">
                <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbarHidden={true}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                />
            </Col>
            
            <div className="mt-4 d-flex align-items-center justify-content-end"  >
                <ButtonRm variant="tertiary" className="me-2">Annulla</ButtonRm>
                <ButtonRm variant="primary" type="submit">Salva</ButtonRm>
            </div>

        </Row>
        </Container>
        


        </>
    )
}