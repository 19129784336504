import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import { parse } from "date-fns/esm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";
import CustomBadge from "../../../CustomBadge";

function MyModal(props) {

 

  return (
    <Modal
      {...props}
      width="50%"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-title">Aggiungi acconto IMU</div>
        <div style={{ margin: "5%" }}>
          <div className="space-top1">
            <div className="sub-title">
              Nome per identificare l’unità immobiliare nell’elenco
            </div>
            <div style={{ marginTop: "1%" }}>
              <input
                type="text"
                id="birthday"
                name="birthday"
                className="input-section small"
              />
            </div>
          </div>

          <div className="space-top1">
            <div className="sub-title">Comune catastale</div>
            <div style={{ marginTop: "1%" }}>
              <select name="cars" id="cars" className="select-part small ">
                <option value="volvo">Abbadia</option>
              </select>
            </div>
          </div>

          <div className="space-top">
            <button
              className="btn-primary1 "
              style={{ width: "100%", height: "45px" }}
            >
              Aggiungi
            </button>
          </div>

          <div className="space-top " style={{ color: "black" }}>
            Annulla
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onHide}>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

export default function Section1({ onChangeSection }) {
  const [catastale, setcatastale] = useState(null);
  const [principale, setPrincipale] = useState(0);
  const [possesso, setPossesso] = useState(100);
  const [mesi_possesso, setMesi_possesso] = useState(12);
  const [rate, setRate] = useState(0);
  const [deliberata, setDeliberata] = useState(0);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [immobile, setImmobile] = useState("");
  const [radio, setRadio] = useState(1);
  const [residenti, setresidenti] = useState(1);
  const [storico, setStorico] = useState(false);
  const [total_imu, setTotal_imu] = useState(0);
  const [total_detrazione, setTotal_detrazione] = useState(0);
  const [acconto_stato, setAcconto_stato] = useState(0);
  const [acconto_comune, setAcconto_comune] = useState(0);
  const [acconto_totale, setAcconto_totale] = useState(0);
  const [isResidenti, setResidenti] = useState(true);

  const [inagibile, setInagibile] = useState(false)
  const [mesiInagibili, setMesiInagibili] = useState(0)

  const [comodato, setComodato] = useState(false)
  const [mesiComodato, setMesiComodato] = useState(0)

  const [concordato, setConcordato] = useState(false)
  const [mesiConcordato, setMesiConcordato] = useState(0)

    //METTIAMO DEFAULT ABITAZIONE DI PREGIO E ABITAZIONE PRINCIPALE
    const [aliquotaImu, setAliquotaImu] = useState(4)
    const [detrazione, setDetrazione] = useState(0)

    function calculateAliquota(){
      //SE E'ABITAZIONE PRINCIPALE E ABITAZIONE DI PREGIO, NON PREGIO, O PERTINENZIALI
      if((immobile == 1 && radio == 1) || (immobile == 2 && radio == 1) || (immobile == 3 && radio == 1)){
        setAliquotaImu(4.0)
        return 4.0
  
      //SE FABBRICATI RURALI, NON QUELLI AD USO ABITATIVO
      } else if(immobile == 10 ){
        setAliquotaImu(2.0)
        return 2.0
      //SE ALTRO
      } else {
        setAliquotaImu(7.6)
        return 7.6
      }
    }

  //CAMBIA ALIQUOTA QUANDO CAMBIA IMMOBILE
  const onimmobileChange = (event) => {
    setImmobile(event.target.value);
  };

  useEffect(() => {
    if(immobile){setAliquotaImu(calculateAliquota())}
    setComodato(false)
    setMesiComodato(0)
    setConcordato(false)
    setMesiConcordato(0)
  }, [immobile, radio])
  
  const radioChanged = (value) => {
    setRadio(value);
  };
  
  const possessoClicked = (value) => {
    setPossesso(value);
  
    if (value === 100) {
      setResidenti(true);
    } else {
      setResidenti(false);
    }
  };


  


  function calculateDetrazione(){
    //let nfigli = principale
    const detrazioneBase = 200
    //const detrazionePerFiglio = 50
    //const detrazioneFigliTotale =  Math.min(nfigli * detrazionePerFiglio, 400) //50euro per figlio < 26 anni, fino a un massimo di 400 euro
    let detrazioneTotale = detrazioneBase 

    if((immobile == 1 || immobile == 2 || immobile == 3 ) && radio == 1 ) {
     if(possesso == 100){
        //SE NON CI SONO COMPROPRIETARI
        setDetrazione(detrazioneTotale)
        return detrazioneTotale
     } else if (possesso != 100) {
      setDetrazione(detrazioneTotale / residenti)
      return detrazioneTotale / residenti
     }
    } else {
      return 0
    }

  }

  function calculateCoeff(){
    let per
    switch (immobile) {
      case "1": 
      case "2":
      case "3":
      case "11":
        per = 160;
        break;
      case "4":
      case "8":
        per = 80;
      break;
      case "5":
        per = 55;
        break;
      case "6":
      case "9":
        per = 140;
        break;
      case "7":
      case "10":
        per = 65;
        break;
      default:
        per = 0;
        break;
    }

      return per

    //se aree fabricabili => non rendita catastale, ma valore venale == imponibile
    
  }

  const [output, setOutput] = useState({
    imuLorda:0,
    imuNetta: 0,
    accontoStato : 0, 
    accontoComune : 0,
    accontoTotale : 0,
    totaleDetrazioni: 0
  })

  function calculateImu(){
    let coefficient = calculateCoeff()
    let aliquota = aliquotaImu
    let detrazionePrimaCasa = calculateDetrazione()

    console.log(coefficient, aliquota, detrazionePrimaCasa)
  
    //SE AREE EDIFICABILI SI USA DIRETTAMENTE IL VALORE VENALE
  let baseImponibile = 0
    if(immobile != 12){
       baseImponibile = (parseFloat(catastale) * 1.05) * coefficient
    } else {
      baseImponibile = parseFloat(catastale)
    }

    let imuLordaAnnuale = baseImponibile * (aliquota / 1000) //4032

    let imuLorda = parseFloat(imuLordaAnnuale) * (mesi_possesso / 12) * (possesso / 100)

    let detrazioneStorico = storico ? imuLorda * 0.5 : 0
    //SOLO PER NON PRIME CASE, NON DI PREGIO
    let detrazioneComodato = (comodato && mesiComodato )> 0 ? imuLorda * (((50/12) * mesiComodato)/100) : 0

    //SOLO PER NON PRIME CASE, NON DI PREGIO E DI PREGIO
    let detrazioneConcordato = (concordato && mesiConcordato )> 0 ? imuLorda * (((25/12) * mesiConcordato)/100) : 0

    let detrazioneInagibile = (inagibile && mesiInagibili )> 0 ? imuLorda * (((50/12) * mesiInagibili)/100) : 0;
    //ADD CANONE CONCORDATO
    //ADD COMODATO

    let imuNetta = imuLorda - detrazionePrimaCasa - detrazioneStorico - detrazioneInagibile - detrazioneComodato - detrazioneConcordato

  

    console.log({baseImponibile, imuLordaAnnuale, imuLorda, imuNetta, detrazioneInagibile})

    
    //OUPUT
    let accontoStato = 0
    let accontoComune = imuNetta / 2
    let accontoTotale = accontoComune + accontoStato

    let totaleDetrazioni = detrazioneStorico + detrazionePrimaCasa + detrazioneInagibile + detrazioneConcordato + detrazioneComodato

    setOutput({
      imuLorda,
      imuNetta,
      accontoStato, 
      accontoComune,
      accontoTotale,
      totaleDetrazioni
    })

    setIsShowDetail(true)
  }

  //DATI ESPORTAZIONE
  const item = {
    ['Totale IMU']: `€ ${output.imuNetta < 0 ? 0 : output.imuNetta }`,
    ['Totale detrazione']: `€ ${output.totaleDetrazioni}`,
    ['Acconto - stato']: `€ ${output.accontoStato}`,
    ['Acconto - comune']: `€ ${output.accontoComune < 0 ? 0 :output.accontoComune}`,
    ['Acconto - totale']: `€ ${output.accontoTotale < 0 ? 0 : output.accontoTotale}`
  }

  return (
    <div className={`page-body`}>
      <div className="space-top1">
        <div className="sub-title">Tipo di immobile</div>
        <div style={{ marginTop: "1%" }}>
          <select
            name="cars"
            id="cars"
            className="select-part small "
            onChange={onimmobileChange}
          >
            <option>Seleziona</option>
            <option value="1">
              Abitazione di pregio (categorie catastali: A/1, A/8 E A/9)
            </option>
            <option value="2">
              Abitazioni non di pregio (categoria da A/2 A A/7)
            </option>
            <option value="3">
              Box auto, magazzini, posti auto, tettoie (categorie catastali:
              C/2, C/6 E C/7)
            </option>
            <option value="4">Uffici (categoria catastale A/10)</option>
            <option value="5">Negozi (categoria catastale C/1)</option>
            <option value="6">
              Laboratori artigiani, palestre e stabilimenti senza fine di lucro
              (C/3, C/4 e C/5)
            </option>
            <option value="7">
              Capannoni industriali, fabbriche, centri commerciali, ecc. (da D/1
              a D/9 escluso D/5)
            </option>
            <option value="8">
              Istituti di credito, cambio e assicurazione (categoria catastale
              D/5)
            </option>
            <option value="9">
              Collegi, scuole, caserme, ospedali pubblci, prigioni (da B1 a B/8)
            </option>
            <option value="10">
              Fabbricati per funzioni produttive connesse alle attività agricole
              (D/10)
            </option>
            <option value="11">
              Fabbricati rurali non strumentali in categoria: stalle, fienili, ecc
              (A/6, C/2, C/6 e C/7)
            </option>
            <option value="12">Aree fabbricabili</option>
          </select>
        </div>
      </div>

      { (immobile == 1 || immobile == 2 || immobile == 3) &&
        <div className="space-top1">
        <div className="radioLabel">
          <input
            type="radio"
            id="contactChoice1"
            vaue={1}
            checked={radio == 1}
            name="radio"
            className="btn-radio "
            onChange={() => radioChanged(1)}
          />
          <label htmlFor="contactChoice1" className="btn-radio">
           {immobile !=3 ? "Abitazione principale (prima casa)" : "Pertinenza dell'abitazione principale"}
          </label>
        </div>
        <div className="radioLabel">
          <input
            type="radio"
            id="contactChoice2"
            value="2"
            name="radio"
            checked={radio == 2}
            className="btn-radio "
            onChange={() => radioChanged(2)}
          />
          <label htmlFor="contactChoice2" className="btn-radio">
          {immobile !=3 ? "Non è l'abitazione principale" : "Non è pertinenza dell'abitazione principale"}
          </label>
        </div>
      </div>}

      <div className="space-top1">
        <div className="sub-title">{(immobile == 12) ? "Valore venale" : "Rendita catastale"}</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <div className="divInputText">
                <input
                  type="number"
                  className="input-calc-symbol"
                  value={catastale}
                  onChange={(e) => setcatastale(e.target.value)}
                ></input>
                <span className="spanInput1">€</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>

     {/*  <div className="space-top1">
        <div className="sub-title">
          Numero di figli minori di 26 anni con residenza e dimora
          nell’abitazione principale
        </div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col className="">
              <div className="divInputText">
                <input
                  type="number"
                  className="inputText1"
                  value={principale}
                  onChange={(e) => setPrincipale(e.target.value)}
                ></input>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}

      <div className="space-top1">
        <Row>
          <Col xs={4}>
            <div className="space-top1">
              <div className="sub-title">% di possesso</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={possesso}
                      onChange={(e) => possessoClicked(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {!isResidenti && (
            <Col xs={5}>
              <div className="space-top1">
                <div className="sub-title">
                  Numero di coproprietari residenti
                </div>
                <div style={{ marginTop: "1%" }}>
                  <Row>
                    <Col className="">
                      <input
                        type="number"
                        className="inputText1"
                        value={residenti}
                        onChange={(e) => setresidenti(e.target.value)}
                      ></input>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          )}
          <Col xs={3}>
            <div className="space-top1">
              <div className="sub-title">Mesi di possesso</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={mesi_possesso}
                      onChange={(e) => setMesi_possesso(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="space-top1">
        <div>
          <Row>
            <div className="radioLabel">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={storico}
                onChange={(e) => setStorico(e.target.checked)}
              />
              <label className="sub-title1" for="check">
                {" "}
                Immobile storico soggetto alla detrazione del 50% sulla base
                immobile
              </label>
            </div>
          </Row>
        </div>
      </div>
      <div className="space-top1">
        <div>
          <Row>
            <div className="radioLabel">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={inagibile}
                onChange={(e) => setInagibile(e.target.checked)}
              />
              <label className="sub-title1" for="check">
                {" "}
                Inagibile
              </label>
            </div>
          </Row>
        </div>
      </div>
      {
        inagibile &&
        <div className="space-top1">
        <Row>
          <Col xs={4}>
            <div className="space-top1">
              <div className="sub-title">Mesi inagibilità</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={mesiInagibili}
                      onChange={(e) => setMesiInagibili(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          
        </Row>
      </div>
      }
      {(radio != 1 && (immobile == 2 || immobile == 3) )&& <div className="space-top1">
        <div>
          <Row>
            <div className="radioLabel">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={comodato}
                onChange={(e) => setComodato(e.target.checked)}
              />
              <label className="sub-title1" for="check">
                {" "}
                Riduzione per immobile in comodato
              </label>
            </div>
          </Row>
        </div>
      </div>}
      {
        comodato &&
        <div className="space-top1">
        <Row>
          <Col xs={4}>
            <div className="space-top1">
              <div className="sub-title">Mesi comodato</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={mesiComodato}
                      onChange={(e) => setMesiComodato(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          
        </Row>
      </div>
      }
       {(radio != 1 && (immobile == 1 || immobile == 2 || immobile == 3) )&& <div className="space-top1">
        <div>
          <Row>
            <div className="radioLabel">
              <input
                type="checkbox"
                id="check"
                name="check"
                checked={concordato}
                onChange={(e) => setConcordato(e.target.checked)}
              />
              <label className="sub-title1" for="check">
                {" "}
                Immobile affittato a canone concordato:
              </label>
            </div>
          </Row>
        </div>
      </div>}
      {
        concordato &&
        <div className="space-top1">
        <Row>
          <Col xs={4}>
            <div className="space-top1">
              <div className="sub-title">Mesi affitto</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={mesiConcordato}
                      onChange={(e) => setMesiConcordato(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          
        </Row>
      </div>
      }
      <div className="space-top1">
        <Row>
          <Col xs={4}>
            <div className="space-top1">
              <div className="sub-title">Aliquota Acconto </div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={aliquotaImu}
                      //onChange={(e) => setDeliberata(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="space-top1">
              <div className="sub-title">Aliquota deliberata</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={aliquotaImu}
                      //onChange={(e) => setDeliberata(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {/* <Col xs={3}>
            <div className="space-top1">
              <div className="sub-title">Numero di rate</div>
              <div style={{ marginTop: "1%" }}>
                <Row>
                  <Col className="">
                    <input
                      type="number"
                      className="inputText1"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                    ></input>
                  </Col>
                </Row>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className="space-top1 ">
        <div className="space-top1">
          <ButtonRm
            variant="secondary"
            onClick={calculateImu}
            disabled={catastale && immobile != 0? false: true}
          >
            Calcola
          </ButtonRm>
        </div>
      </div>
      {isShowDetail && (
        <div>
          <div className="space-top2">
            <div className="text-end d-flex justify-content-end ">
{/*             <ShareChat styleBtn="top-btn-icon ms-2" />
 */}
              <ExportButton data={item} mapping={{}} classes="top-btn-icon ms-2"/>
            </div>
          </div>
          {output.imuNetta < 0 && <p className="mt-4">Nulla da pagare - Nessuna imposta dovuta.
( la detrazione spettante è maggiore dell'imposta lorda )</p>}
          <div className="space-top1">
            <Row>
              <Col>
                <div className="space-top1">
                  <div className="sub-title">Totale imu</div>
                  <div style={{ marginTop: "1%" }}>
                    <input
                      type="text"
                      id="birthday"
                      name="birthday"
                      className="input-text-blue small"
                      value={output.imuNetta < 0 ? 0 : output.imuNetta }
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="space-top1">
                  <div className="sub-title">Totale detrazione</div>
                  <div style={{ marginTop: "1%" }}>
                    <input
                      type="text"
                      id="birthday"
                      name="birthday"
                      className="input-text-blue small"
                      value={output.totaleDetrazioni }
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col></Col>
            </Row>
          </div>
          <div className="space-top1">
            <Row>
              <Col>
                <div className="space-top1">
                  <div className="sub-title">Acconto - Stato</div>
                  <div style={{ marginTop: "1%" }}>
                    <input
                      type="text"
                      id="birthday"
                      name="birthday"
                      className="input-text-blue small"
                      value={output.accontoStato}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="space-top1">
                  <div className="sub-title">Acconto - Comune</div>
                  <div style={{ marginTop: "1%" }}>
                    <input
                      type="text"
                      id="birthday"
                      name="birthday"
                      className="input-text-blue small"
                      value={output.accontoComune < 0 ? 0 :output.accontoComune}
                      disabled
                    />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="space-top1">
                  <div className="sub-title">Acconto Totale</div>
                  <div style={{ marginTop: "1%" }}>
                    <input
                      type="text"
                      id="birthday"
                      name="birthday"
                      className="input-text-blue small"
                      value={output.accontoTotale < 0 ? 0 : output.accontoTotale}
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="space-top3 "></div>

          <MyModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
    </div>
  );
}
