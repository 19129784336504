import { SET_SHOW, CLEAR_SHOW } from "./types";

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get current users profile
export const setShow = (show) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SHOW,
      payload: show,
    });
  } catch {}
};

export const clearShow = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_SHOW
    });
  } catch {}
};
