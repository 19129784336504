import React from "react";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import "../../../../assets/css/Tabs.css";
import UploadBtn from "../../../UploadBtn";

const Step3 = (props) => {
  const onChange = (e) => {
    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.value,
    });
  };

  const onFileChange = (files) => {
    props.setFormData(prevState => ({
      ...prevState,
      documentFile: files
    }));
  };
  


console.log(props.formData, "step 3")


  return (
    <div
      className="container"
      style={{ display: props.isActive === true ? "" : "none" }}
    >
      <div className="col-md-12 col-sm-12">
        <div className="row">
          <div className="col-md-6 col-sm-12 padding-right">
            <div className="tab1ContentTitle ">Caricamento Documenti</div>
            <div className="tabContentSubtitle mt-4">Tipo di documento</div>
            <Form.Select
              name="documentType"
              className="tabInputField w-100"
              onChange={onChange}
            >
              <option value="" disabled selected>
                Seleziona
              </option>
              <option value="carta d'identità elettronica/cartacea">
                Carta d'identità elettronica/cartacea
              </option>
              <option value="patente">Patente</option>
              <option value="passaporto">Passaporto</option>
            </Form.Select>
            <div className="tabContentSubtitle ">Rilasciato da</div>
            <input
              name="issueEntity"
              type="text"
              placeholder="Inserisci il nome dell’ente che ha rilasciato il documento"
              className="tabInputField form-control w-100"
              onChange={onChange}
            ></input>
            <div className="tabContentSubtitle">Data di rilascio</div>
            <input
              name="issueDate"
              type="date"
              placeholder="Inserisci il tuo cognome"
              className="tabInputField form-control w-100"
              onChange={onChange}
            ></input>
            <div className="tabContentSubtitle ">Numero del documento</div>
            <input
              name="documentNumber"
              type="text"
              placeholder="Inserisci il numero del documento"
              className="tabInputField form-control w-100"
              onChange={onChange}
            ></input>
            <div className="tabContentSubtitle">Luogo di rilascio</div>
            <input
              name="issuePlace"
              type="text"
              placeholder="Inserisci il luogo di rilascio del documento"
              className="tabInputField form-control w-100"
              onChange={onChange}
            ></input>
          </div>
          <div className="tab3RightSideContent col-md-6 col-sm-12 padding-left mt-lg-4">
            <UploadBtn onFileChange={onFileChange} format='.png,.jpeg,.jpg,.pdf'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
