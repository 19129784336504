import React, { useEffect, useState } from "react";
import LeftPanel from "../../LeftPanel";
import "../../../assets/css/CreateNewPassword.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReturnIcon from "../../../assets/images/returnIcon.svg";
import ErrorIcon from "../../../assets/images/input_error.svg";
import SuccessIcon from "../../../assets/images/success.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Modal } from "react-bootstrap";
import ButtonRm from "../../ButtonRm";
import { Link } from "react-router-dom";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function CreateNewPassword() {
  const navigate = useNavigate();
  const [readyCreate, setReadyCreate] = useState(false);
  const [now, setNow] = useState(0);
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    password1: "",
    password2: "",
  });
  const [requestData, setRequestData] = useState({
    email: "",
    password: "",
  });

  const handleClose = () => {
    setShow(false);
    navigate("/");
  };

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  function containsNumber(str) {
    return /\d/.test(str);
  }

  function checkUppercase(str) {
    for (var i = 0; i < str.length; i++) {
      if (
        str.charAt(i) === str.charAt(i).toUpperCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  }

  function checkLowercase(str) {
    for (var i = 0; i < str.length; i++) {
      if (
        str.charAt(i) === str.charAt(i).toLowerCase() &&
        str.charAt(i).toLowerCase().match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (formData.password1 !== formData.password2) {
      setReadyCreate(false);
    }
    if (
      formData.password1.length > 8 &&
      containsSpecialChars(formData.password1) &&
      containsNumber(formData.password1) &&
      checkUppercase(formData.password1) &&
      checkLowercase(formData.password1)
    ) {
      if (formData.password1 === formData.password2) {
        setReadyCreate(true);
      }

      setNow(100);
      document.querySelector(".progressbar").setAttribute("type", "3");
      document.querySelector(".progress-description").setAttribute("type", "3");
      setDescription("Forte");
    } else if (formData.password1.length >= 8) {
      setNow(40);
      document.querySelector(".progressbar").setAttribute("type", "2");
      document.querySelector(".progress-description").setAttribute("type", "2");
      setDescription("Medio - Usa una combinazione di almeno 8 tra lettere maiuscole e minuscole, numeri e simboli.");
    } else if (formData.password1.length > 0) {
      setNow(15);
      document.querySelector(".progressbar").setAttribute("type", "1");
      document.querySelector(".progress-description").setAttribute("type", "1");
      setDescription(
        "Debole - Usa una combinazione di almeno 8 tra lettere maiuscole e minuscole, numeri e simboli."
      );
    } else {
      setNow(0);
      setDescription(
        "Usa una combinazione di almeno 8 tra lettere maiuscole e minuscole, numeri e simboli."
      );
      document.querySelector(".progress-description").setAttribute("type", "");
    }
  }, [formData]);

  const { password1, password2 } = formData;

  const returnLogin = () => {
    navigate("/");
  };

  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    if (formData.password1 === formData.password2) {
      setShow(true);
      requestData.email = state;
      requestData.password = formData.password1;
      setRequestData(requestData);
      axios
        .post(
          process.env.REACT_APP_REQUEST_URL + "api/v1/auth/changePassword",
          requestData
        )
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Success Notification !", {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2000,
              hideProgressBar: false,
            });
          } else {
          }
        })
        .catch((err) => {
          // toast
          toast.error("error!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        });
    }
    e.preventDefault();
  };

  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  return (
    <div className="d-flex" style={{ height: "100vh", backgroundColor: "white" }}>
      <LeftPanel />
      <div className="return-login" onClick={() => returnLogin()}>
        <img
          src={ReturnIcon}
          alt="return"
          style={{ marginRight: "17.6px" }}
        ></img>
        Torna al login
      </div>
      <div className="m-auto createNewPass-div">
        <div className="newPassword-text">Crea una nuova password</div>
        <form
          className="form"
          onSubmit={onSubmit}
          style={{ position: "relative" }}
        >
          <div className="subtitle">Nuova password  <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 300 }}
              overlay={
                <Tooltip className='tooltipContabilita'><p>
                La password deve contenere: <br/>
                -minimo 8 caratteri <br/>
                -almeno una lettera minuscola <br/>
                -almeno una lettera maiuscola <br/>
                -almeno un simbolo <br/>
                -almeno un numero <br/>
                </p>
                </Tooltip>
              }
              >
              <i class="bi bi-info-circle ms-2"></i>
              </OverlayTrigger></div>
          <input
            type={passwordType}
            placeholder="Inserisci la tua password"
            name="password1"
            value={password1}
            onChange={onChange}
            minLength="4"
            className="form-control"
            style={{
              fontFamily: "Mulish",
              height: "50px",
            }}
          />
          <div
            onClick={togglePassword}
            className="passwordIcon"
            style={{
              position: "absolute",
              marginTop: "-35px",
              cursor: "pointer",
            }}
          >
            {passwordType === "password" ? <BsEye /> : <BsEyeSlash />}
          </div>
          <ProgressBar now={now} visuallyHidden className="progressbar" />
          <div className="progress-description">{description}</div>
          <div className="confirm-subtitle">Conferma la password</div>
          <input
            type={confirmPasswordType}
            placeholder="Conferma la nuova password"
            name="password2"
            value={password2}
            onChange={onChange}
            minLength="4"
            className="form-control password-form"
            style={{
              marginBottom: "20px",
              fontFamily: "Mulish",
              height: "50px",
            }}
          />
          <div
            onClick={toggleConfirmPassword}
            className="passwordIcon"
            style={{
              position: "absolute",
              marginTop: "-57px",
              cursor: "pointer",
            }}
          >
            {confirmPasswordType === "password" ? <BsEye /> : <BsEyeSlash />}
          </div>
          <button
  id={readyCreate ? "readyNew-btn" : ""}
  className="newPassword-btn"
  disabled={!readyCreate}
>
  Imposta Password
</button>

        </form>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="success-verify"

        >
          <Modal.Header style={{ borderBottom: "0 none" }} />
          <Modal.Body>
            <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-1024.png"
              alt="success"
              style={{
                marginTop: "40px",
                paddingBottom: "31px",
                margin: "auto",
                display: "flex",
              }}
              width="100px"
            />
            <div className="text-center">
              <p className="fs-26 mb-5"  
                style={{
                  marginTop: "40px",
                  paddingBottom: "31px",
                  margin: "auto"
                }}
              >
                La nuova password è stata impostata
              </p>
              <Link to="/login">
                <ButtonRm variant='primary' >Vai al Login</ButtonRm>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
