import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"





import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import { BASE_URL, token } from "../../api/api";




const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    sex: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	})
});

export default function Piano(){

    const [modalDeactivate, setModalDeactivate] = useState(false)
    const [modalType, setModalType] = useState(null)

    function showModalDeactivate(type){
        setModalDeactivate(true)
        setModalType(type)
    }

    function closeModalDeactivate(){
        setModalDeactivate(false)
        setModalType(null)
    }


    //handle gestione modal

    const [modalGestione, setModalGestione] = useState(false)

    function showModalGestione(type){
        setModalGestione(true)
    }

    function closeModalGestione(){
        setModalGestione(false)
    }


    //handle modale pagamenti
    const [payment, setPayment] = useState(null)
    function handleChangePayment(e){
        setPayment(e.target.value)
    }

    //gestione abbonamento

    function initiateSession() {
        fetch(`${BASE_URL}api/v1/customer/session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "datStripe");
          window.open(data.url);
/*           setClientSecret(data["client_secret"]);
           setAccountConnect(data["target_account"]); */
 
          // Aggiornare stripePromise con l'account ID ricevuto
         /*  stripePromise.then(stripe => {
            stripe.update({ stripeAccount: data["target_account"] });
          }); */
        })
        .catch((error) => {
          console.error('Errore durante la richiesta:', error);
        });
     
    }


    const handleOpenPopup = () => {
        const popupUrl = 'https://billing.stripe.com/p/session/test_YWNjdF8xR3kxaUZBbHF2S3B4SkN1LF9QY0U3MnlBNHNRMTRCRXdDT0ZXUExCaDNrSlNiT2Ni0100Le6SeufO';
         // Dimensioni desiderate per la finestra popup
        const width = 600;
        const height = 800;

        // Calcola le posizioni left e top per centrare la finestra
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
        const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
            
        // Apertura della finestra popup
        //window.open(popupUrl, "StripeOnboarding", options);

        initiateSession()
    };



    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Piano</p>
                    <p className="fs-14 opacity-75">Qui potrai gestire il tuo piano</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Il tuo piano personale</p>
                            <p className="fs-14 text-dark opacity-75">Informazioni sul pacchetto che hai acquistato</p>
                        </Col>

                    <Col md={6} className="ps-0">
                        <div className="faceIdDiv text-start mb-2">
                            
                                <div>
                                    <img loading="lazy" src={pianoIcon} width="50px"/>
                                </div>
                                <div className="d-flex">
                                    <p className="fs-16 fw-bold">Nome piano</p>
                                    <CustomBadge variant="custom-badge-success">Attivo</CustomBadge>
                                </div>
                                <p className="fs-14">Data di attivazione: </p>
                                <p className="fs-14">Prossimo Rinnovo: </p>
                                <p className="fs-14">Metodo di pagamento:  </p>
                            
                            <div className="mt-3">
                              
                                <form method="POST" action="/create-customer-portal-session" className="">
                    <ButtonRm variant="secondary"  /* type="submit" */ onClick={() => handleOpenPopup()}>Gestisci abbonamento</ButtonRm>
                </form>
                            </div>
                        </div>
                    </Col>

                    <hr className="opacity-10 my-3"/>

                </Row>

                <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                        <p className="fs-16 text-dark fw-bold">I tuoi Collaboratori</p>
                        <p className="fs-14 text-dark opacity-75">Qui puoi gestire il tuo team</p>
                    </Col>

                    <Col md={6} className="ps-0">
                        <div className="faceIdDiv text-start mb-2">
                                <div>
                                    <img loading="lazy" src={pianoIcon} width="50px"/>
                                </div>
                                <div className="d-flex">
                                    <p className="fs-16 fw-bold">Nome Collaboratore</p>
                                    <CustomBadge variant="custom-badge-success">Attivo</CustomBadge>
                                </div>
                                <p className="fs-14">Data di attivazione: </p>
                                <p className="fs-14">Prossimo Rinnovo: </p>
                                <p className="fs-14">Metodo di pagamento:  </p>
                                <p className="fs-14">Pacchetto:  </p>
                            
                            <div className="mt-3">
                                <ButtonRm variant="outline" className="me-2"
                                onClick={() => showModalDeactivate("collaboratore")}
                                >Rimuovi collaboratore</ButtonRm>
                                <ButtonRm variant="outline" onClick={showModalGestione}>Gestisci</ButtonRm>
                            </div>
                        </div>
                        <div className="faceIdDiv text-start mb-2">
                            
                                <div>
                                    <img loading="lazy" src={pianoIcon} width="50px"/>
                                </div>
                                <div className="d-flex">
                                    <p className="fs-16 fw-bold">Nome Collaboratore</p>
                                    <CustomBadge variant="custom-badge-danger">Rimosso</CustomBadge>
                                </div>
                                <p className="fs-14">Data di attivazione: </p>
                                <p className="fs-14">Prossimo Rinnovo: </p>
                                <p className="fs-14">Metodo di pagamento:  </p>
                                <p className="fs-14">Pacchetto:  </p>
                            
                             <div className="mt-3">
                                <ButtonRm variant="outline" className="me-2" >Riassegna abbonamento</ButtonRm>
                            </div> 
                        </div>
                    </Col>


                </Row>    

                   {/* <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Permessi</p>
                            <p className="fs-14 text-dark opacity-75">Gestisci i permessi dei tuoi utenti</p>
                        </Col>

                    <Col md={6} className="ps-0 mb-3">
                        <div className="faceIdDiv text-start mb-2">
                                <p className="fs-16 fw-bold">I tuoi collaboratore possono:</p>
                            
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Visualizzare gli elementi creati da te (Unità , Affitti, Stabili, Prime Note, Rendiconti...)"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Visualizzare i tuoi utenti"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Fornitori"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Proprietari"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Conduttori"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Modificare gli elementi creati da te"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Eliminare gli elementi creati da te"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Incaricare altri collaboratori"
                                />
                            </div>
                           
                        </div>
                    </Col>
                    <hr className="opacity-10 my-3"/>
                </Row>   */}          

            </div >

            {/* MODAL DISATTIVAZIONE */}
            <Modal
                    show={modalDeactivate}
                    onHide={closeModalDeactivate}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <img loading="lazy" src={logoRed} />
                            <p className="fs-18 fw-bold mt-3 mb-2">
                                {modalType == "collaboratore" ? "Conferma rimozione collaboratore" :"Conferma disattivazione"}</p>
                            <p className="fs-14 mb-4">
                                {modalType == "collaboratore" ? "Sei sicuro di voler rimuovere questo collaboratore? La rimozione sarà immediata e non potrà più accedere e gestire gli elementi presentI nel tuo account, inoltre se è presente un abbonamento attivo al momento, verrà disattivato." :
                                "Sei sicuro di voler disattivare il tuo abbonamento? Potrai ancora usufruire dei servizi, fino alla data di scadenza del tuo attuale abbonamento"}
                            </p>
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                            <ButtonRm variant="primary" onClick={closeModalDeactivate}>Conferma</ButtonRm>
                            <ButtonRm variant="text">Annulla</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
            </Modal>

            {/* MODAL GESTIONE */}
            <Modal
                    show={modalGestione}
                    onHide={closeModalGestione}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <p className="fs-18 fw-bold mb-2">
                            Gestisci Permessi Collaboratore
                            </p>

                            {/* <div className="border text-start px-2 rounded mb-2">
                                <Form.Check
                                inline
                                type="radio"
                                value="paypal"
                                checked={payment === "paypal"}
                                onChange={handleChangePayment}
                                />
                                <img loading="lazy" src={PayPal} width="50px"/>
                            </div>

                            <div className="border text-start px-2 rounded mb-2">
                                <div className="d-flex align-items-center">
                                    <Form.Check
                                    inline
                                    type="radio"
                                    value="card"
                                    checked={payment === "card"}
                                    onChange={handleChangePayment}
                                    />

                                    <div className="d-flex ">
                                        <img loading="lazy" src="" width="50px" className="me-2"/>
                                        <img loading="lazy" src={Mastercard} width="50px" className="me-2"/>
                                        <img loading="lazy" src={Maestro} width="50px" className="me-2"/>
                                    </div>
                                </div>
                                {payment == "card" && 
                                    <div>
                                        <Col className="mb-3">
                                            <Form.Group>
                                                <Form.Label 
                                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                                >
                                                Numero della carta
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    /> 
                                            </Form.Group>
                                        </Col>
                                        <Col  className="mb-3">
                                            <Form.Group>
                                                <Form.Label 
                                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                                >
                                                Titolare della carta
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                />
                                            </Form.Group>
                                        </Col>
                                         <Col md={6} className="mb-3">
                                            <Form.Group>
                                                <Form.Label 
                                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                                >
                                                CVC
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                />
                                            </Form.Group>
                                        </Col>
                                    </div>
                                }
                            </div> */}

<div className=" text-start mb-2">
                            
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Visualizzare gli elementi creati da te (Unità , Affitti, Stabili, Prime Note, Rendiconti...)"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Visualizzare i tuoi utenti"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Fornitori"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Proprietari"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="checkbox"
                                    label="Conduttori"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Modificare gli elementi creati da te"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Eliminare gli elementi creati da te"
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Incaricare altri collaboratori"
                                />
                            </div>
                           
                        </div>
                            
                                
                            
                            
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                            <ButtonRm variant="primary" onClick={closeModalGestione}>Salva</ButtonRm>
                            <ButtonRm variant="text" onClick={closeModalGestione}>Annulla</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
            </Modal>
        </>

        
    )
}