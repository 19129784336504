import React, { useState } from "react";
import CheckBoxMember from "./CheckBoxMember";

export default function MembriBtn(props) {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  return (
    <>
      <button
        type="button"
        className="drop-mem border-0 border-start"
        onClick={handleClick}
      >
        <i className="bi bi-caret-down-fill"></i>
      </button>
      <div
        className={
          isActive
            ? "option-memb rounded-bottom shadow p-3 w-140 bg-white d-block"
            : "d-none"
        }
      >
        <CheckBoxMember handleCheckboxChange={props.handleCheckboxChange}/>
      </div>
    </>
  );
}
