import { useState, useEffect } from "react";
import NumericInput from "react-numeric-input";
import CustomBadge from "../../CustomBadge";


const FeatureE = (props) => {
  const [data, setData] = useState({});

  const init = () => {
    setData({
      grossCoveredMq: props.formData.grossCoveredMq,
      basementMq: props.formData.basementMq,
      finishingVerandaMq: props.formData.finishingVerandaMq,
      notFinishingVerandaMq: props.formData.notFinishingVerandaMq,
      mezzanineMq: props.formData.mezzanineMq,
      uncoveredMq: props.formData.uncoveredMq,
      canopyMq: props.formData.canopyMq,
      courtyardAreaMq: props.formData.courtyardAreaMq,
      attachedLandMq: props.formData.attachedLandMq,
      floor: props.formData.floor,
      boxAccessibility: props.formData.boxAccessibility,
      parkingAccessibility: props.formData.parkingAccessibility,
      boxType: props.formData.boxType,
      supportingStructureType: props.formData.supportingStructureType,
      mainLevelCeilingHeight: props.formData.mainLevelCeilingHeight,
      locationVisibility: props.formData.locationVisibility,
      balconyMq: props.formData.balconyMq,
      terraceMq: props.formData.terraceMq,
      gardenMq: props.formData.gardenMq,
      lodgeMq: props.formData.lodgeMq,
      solarPavementMq: props.formData.solarPavementMq,
      otherUncoveredMq: props.formData.otherUncoveredMq,
      winery: props.formData.winery,
      wineryMq: props.formData.wineryMq,
      garret: props.formData.garret,
      garretMq: props.formData.garretMq,
      box: props.formData.box,
      boxMq: props.formData.boxMq,
      boxLocation: props.formData.boxLocation,
      coveredParkingSpace: props.formData.coveredParkingSpace,
      coveredParkingAccessibility: props.formData.coveredParkingAccessibility,
      coveredParkingLocation: props.formData.coveredParkingLocation,
      condominialParkingSpace: props.formData.condominialParkingSpace,
      uncoveredParkingAccessibility:
        props.formData.uncoveredParkingAccessibility,
      uncoveredParkingLocation: props.formData.uncoveredParkingLocation,
      condominialParkingType: props.formData.condominialParkingType,
      lastFloor: props.formData.lastFloor,
      mainFloor: props.formData.mainFloor,
      buildingTypology: props.formData.buildingTypology,
      windowPosition: props.formData.windowPosition,
      commercialVisibility: props.formData.commercialVisibility,
      lift: props.formData.lift,
      numberExposition: props.formData.numberExposition,
      mainExposition: props.formData.mainExposition,
      mainView: props.formData.mainView,
      brightness: props.formData.brightness,
      noisiness: props.formData.noisiness,
      ceilingHeight: props.formData.ceilingHeight,
      internalLevels: props.formData.internalLevels,
      internalDistribution: props.formData.internalDistribution,
      coveredMqRoomsRatio: props.formData.coveredMqRoomsRatio,
      coveredMqBathsRatio: props.formData.coveredMqBathsRatio,
      internalState: props.formData.internalState,
      finishingQuality: props.formData.finishingQuality,
      heatingType: props.formData.heatingType,
      energeticClass: props.formData.energeticClass,
      buildingLocation: props.formData.buildingLocation,
      buildingAge: props.formData.buildingAge,
      buildingState: props.formData.buildingState,
      marketRef: props.formData.marketRef,
      bareOwnership: props.formData.bareOwnership,
      rentalState: props.formData.rentalState,
      leaseProfitability: props.formData.leaseProfitability,
      entranceWidth: props.formData.entranceWidth,
      carCapacity: props.formData.carCapacity,
      motorcycleCapacity: props.formData.motorcycleCapacity,
      buildingYear: props.formData.buildingYear,
      underbeamHeight: props.formData.underbeamHeight,
      totalFloorNumber: props.formData.totalFloorNumber,
      buildingType: props.formData.buildingType,
    });
  };

  useEffect(() => {
    init();
  }, [props]);

  const onChange = (e) => {
    if (e.target.type === "checkbox")
      setData({ ...data, [e.target.name]: e.target.checked });
    else setData({ ...data, [e.target.name]: e.target.value });
  };

  const [typologyState, setTypologyState] = useState(0);

  useEffect(() => {
    var text = props.formData.typology;
    if (text !== undefined) {
      if (text.includes("Appartamenti") || text.includes("Ville")) {
        setTypologyState(0);
      }
      /*  else if (
        text.includes("ABITAZIONI DI 2°") ||
        text.includes("FASCIA MEDIA")
      ) {
        setTypologyState(1);
      }  */
      else if (text.includes("Uffici")) {
        setTypologyState(2);
      } else if (text.includes("Negozi")) {
        setTypologyState(3);
      } else if (text.includes("Box e autorimesse")) {
        setTypologyState(4);
      } else if (text.includes("Posti auto")) {
        setTypologyState(5);
      } else if (text.includes("Capannoni")) {
        setTypologyState(6);
      } else if (text.includes("Magazzini")) {
        setTypologyState(7);
      } else if (text.includes("Laboratori")) {
        setTypologyState(8);
      }
    }
  }, []);

  const wineryType = [
    "presente",
    "piccola",
    "normale",
    "grande",
    "doppia",
    "tripla",
    "quadrupla",
  ];

  const setWineryGarretMq = (value) => {
    var val;
    switch (value) {
      case "piccola":
        val = 4;
        break;
      case "normale":
        val = 6;
        break;
      case "grande":
        val = 8;
        break;
      case "doppia":
        val = 12;
        break;
      case "tripla":
        val = 18;
        break;
      case "quadrupla":
        val = 24;
        break;
      case "presente":
        val = 0;
        break;
      default:
        break;
    }
    return val;
  };

  useEffect(() => {
    let val = setWineryGarretMq(data.winery);
    const ele = { target: { name: "wineryMq", value: val } };
    onChange(ele);
  }, [data.winery]);

  useEffect(() => {
    let val = setWineryGarretMq(data.garret);
    const ele = { target: { name: "garretMq", value: val } };
    onChange(ele);
  }, [data.garret]);

  const boxType = [
    "presente",
    "molto piccolo",
    "piccolo",
    "medio",
    "grande",
    "doppio",
    "triplo",
    "quadruplo",
  ];

  useEffect(() => {
    var val;
    switch (data.box) {
      case "molto piccolo":
        val = 12;
        break;
      case "piccolo":
        val = 14;
        break;
      case "medio":
        val = 18;
        break;
      case "grande":
        val = 24;
        break;
      case "doppio":
        val = 32;
        break;
      case "triplo":
        val = 40;
        break;
      case "quadruplo":
        val = 50;
        break;
      case "presente":
        val = 0;
        break;
      default:
        break;
    }
    const ele = { target: { name: "boxMq", value: val } };
    onChange(ele);
  }, [data.box]);

  const boxAccessibilityType = [
    "-",
    "accesso molto difficoltoso",
    "accesso difficoltoso",
    "accesso scomodo",
    "accesso normale",
    "accesso comodo",
  ];

  const boxLocationType = [
    "nello stesso edificio",
    "limitrofo all'edificio, distante all'edificio",
    "molto distante all'edificio",
  ];

  const condominialParkingSpaceType = ["presente", "assente"];

  const condominialParkingType = [
    "posto auto coperto",
    "posto auto scoperto",
    "posto auto a rotazione coperto scoperto",
  ];

  const parkingAccessibilityType = [
    "più facile della media di zona",
    "nella media di zona",
    "più difficoltoso della media di zona",
    "molto più difficoltoso della media di zona",
  ];

  const floorType = [
    "Piano interrato",
    "piano seminterrato",
    "piano terra",
    "piano rialzato",
    "1 piano",
    "2 piano",
    "3 piano",
    "4 piano",
    "5 piano",
    "6 piano",
    "7 piano",
    "8 piano",
    "9 piano",
    "10 piano",
    "oltre il 10 piano",
    "intero stabile fino a 3 piani",
    "intero stabile 4 piani ed oltre",
  ];

  const lastFloorType = [
    "Attico",
    "superattico",
    "attico con superattico",
    "mansarda",
    "ultimo piano",
  ];

  const numberExpositionType = [
    "principalmente su 1 lato",
    "principalmente su 2 lati",
    "principalmente su 3 lati",
    "principalmente su 4 lati",
  ];

  const mainExpositionType = [
    "mista (interna/esterna)",
    "completamente interna",
    "principalmente interna",
    "principalmente esterna",
    "completamente esterna",
  ];

  const mainViewType = ["normale", "gradevole", "poco gradevole"];

  const brightnessType = [
    "molto bassa",
    "bassa",
    "alta",
    "molto alta",
    "normale",
  ];

  const noisinessType = [
    "normale",
    "molto alta",
    "alta",
    "bassa",
    "molto bassa",
  ];

  const ceilingHeightType = [
    "nella media di zona",
    "inferiori alla media di zona",
    "superiori alla media zona",
  ];

  const internalDistributionType = [
    "distribuzione inefficiente",
    "distribuzione poco efficiente",
    "distribuzione molto efficiente",
  ];

  const internalStateType = [
    "discreto",
    "completamente ristrutturare",
    " da ristrutturare",
    "parzialmente da ristrutturare",
    "buono stato, ottimo stato",
    "parzialmente ristrutturato",
    "ristrutturato",
    "nuovo costruzione",
    "fatiscente",
  ];
  const finishingQualityType = [
    "standard",
    "economiche",
    "ottime",
    "di pregio",
  ];

  const heatingTypeType = [
    "centralizzato con contabilizzatore",
    "assente",
    "centralizzato",
    "autonomo",
  ];

  const energeticClassType = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "A1",
    "A2",
    "A3",
    "A4",
  ];

  const buildingLocationType = [
    "edificio indipendente (non confinante con altri)",
    "edificio a schiera (confinante con altri)",
    "comprensorio immobiliare (senza servizi in comune)",
    "complesso residenziale (con servizi e/o verde in comune)",
    "complesso residenziale con piscina",
  ];

  const buildingTypologyType = [
    "signorile",
    "parzialmente signorile",
    "molto signorile",
    "di pregio",
    "straordinario",
    "civile",
  ];

  const buildingAgeType = [
    "1-5 anni",
    "5-20 anni",
    "20-40 anni",
    "oltre 40 anni",
    "antecedente 1930. storico antecedente 1900",
  ];

  const buildingStateType = [
    "buono stato, da ristrutturare",
    "discrete condizioni",
    "ottimo stato",
    "ristrutturato",
    "nuova costruzione",
    "parzialmente ristrutturato",
    "da ripulire",
    "nuova costruzione (mai abitato)",
    "fatiscente-rudere",
  ];

  const buildingStateTypeCapannoni = [
    "ottimo",
    "buono",
    "nuovo/in costruzione",
    "da ristrutturare",
  ];
  const marketRefType = ["libero mercato", "immobile in asta"];

  const bareOwnershipType = [
    "intera proprietà", "nuda proprietà da 0 a 20 anni (-95%)",
    "nuda proprietà da 21 a 30 anni (-90%), nuda proprietà da 31 a 40 anni (-85%)",
    "nuda proprietà da 41 a 45 anni (-79%)",
    "nuda proprietà da 46 a 50 anni (-74%)",
    "nuda proprietà da 51 a 53 (-69%)",
    "nuda proprietà da 54 a 56 anni (-64%)",
    "nuda proprietà da 57 a 60 (-58%)",
    "nuda proprietà da 61 a 63 anni (-53%)",
    "nuda proprietà da 64 a 66 anni (-48%)",
    "nuda proprietà da 67 a 69 anni (-43%)",
    "nuda proprietà da 70 a 72 anni (-37%)",
    "nuda proprietà da 73 a 75 anni (-32%)",
    "nuda proprietà da 76 a 78 anni (-27%)",
    "nuda proprietà da 79 a 82 anni (-22%)",
    "nuda proprietà da 83 a 86 anni (-16%)",
    "nuda proprietà da 87 a 92 anni (-11%)",
    "nuda proprietà da 93 a 99 anni (-10%)",
  ];

  const rentalStateType = [
    "immobile libero",
    "libero (utilizzato dalla proprietà)",
    "locato brevissimo termine (1 anno)",
    "locato breve termine (2 anni)",
    "locato medio termine (3 anni)",
    "locato lungo temine (4 anni)",
    "locato lunghissimo termine (oltre 4 anni)",
  ];

  const leaseProfitabilityType = [
    "non locato",
    "molto bassa",
    "bassa",
    "media",
    "alta",
    "molto alta",
  ];

  const internalLevelsType = [
    "disposto completamente su 1 livello",
    "disposto principalmente su 1 livello",
    "disposto su 2 livelli",
    "disposto su 3 livelli",
    "disposto su 4 livelli",
  ];

  const coveredMqRoomsRatioType = [
    "adeguato",
    "inadeguato- pochissimi vani rispetto ai mq coperti",
    "poco adeguato - pochi vani rispetto al mq coperti",
    "ottimo",
  ];

  const coveredMqBathsRatioType = [
    "adeguato",
    "inadeguato - pochi bagni rispetto al nr vani totali",
    "ottimo",
  ];

  const mainFloorType = [
    "Piano terra",
    "piano seminterrato",
    "piano rialzato",
    "1° piano",
    "2° piano ed oltre",
  ];

  const windowPositionType = [
    "Fronte strada in linea",
    "fronte strada in posizione angolare",
    "fronte strada in posizione arretrata",
    "non fronte strada",
  ];

  const commercialVisibilityType = [
    "Nella media di zona",
    "inferiore alla media di zona",
    "superiore alla media di zona",
  ];

  const boxTypeNegozi = ["presente", "assente"];

  const buildingTypologyTypeNegozi = [
    "edificio civile",
    "edificio economico",
    "edificio signorile",
    "edificio di pregio",
    "edificio direzionale",
    "centro commerciale",
    "piccolo centro commerciale",
    "edificio indipendente",
  ];

  const floorTypeBox = ["interrato", "terra", "2° interrato", "3° interrrato "];

  const supportingStructureType = [
    "struttura in cemento armato",
    "struttura metallica",
    "struttura in muratura ordinaria",
  ];

  const mainLevelCeilingHeightType = [
    "standard 6/8 metri",
    "inferiore a 6 metri",
    "oltre 8 metri",
  ];

  const locationVisibilityType = [
    "strada intermedia",
    "strada secondaria",
    "strada principale",
  ];

  const entryType = [
    "esterno fronte strada (senza passo carrabile)",
    "interno all'edificio (senza passo carrabile)",
    "interno all'edificio (con passo carrabile)",
    "esterno fronte strada (con passo carrabile)",
  ];

  const buildingType = [
    "edificio civile",
    "edificio economico",
    "edificio signorile",
    "edificio di pregio",
    "edificio direzionale",
    "centro commerciale",
    "piccolo centro commerciale",
    "edificio indipendente",
  ];

  useEffect(() => {
    if (props.saveState === true) {
      props.update(data);
    } else {
      init();
    }
  }, [props.saveState, props.editMode]);

  return (
    <div className="form-item-align-box d-block">
      <fieldset disabled={props.editMode ? false : true}>
        <div className="label-heading mb-3 d-block w-100">
          <div className="label-text rel d-inline-block px-2 title-padding">
            <h1 className="fs-16 text-white text-uppercase">
              {typologyState === 0 || typologyState === 1
                ? "SUPERFICI COPERTE"
                : "SUPERFICI"}
            </h1>
          </div>
        </div>
        {typologyState === 0 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq coperti lordi - compresi muri perimetrali e tramezzi
                  </label>
                  <input
                    type="text"
                    name="grossCoveredMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.grossCoveredMq}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq seminterrati - ad uso residenziale
                  </label>
                  <input
                    type="text"
                    name="basementMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.basementMq}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq verande rifinite - come vani principali
                  </label>
                  <input
                    type="text"
                    name="finishingVerandaMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.finishingVerandaMq}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq verande non rifinite
                  </label>
                  <input
                    type="text"
                    name="notFinishingVerandaMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.notFinishingVerandaMq}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq soppalchi abitabili - altezza minima 2.2m
                  </label>
                  <input
                    type="text"
                    name="mezzanineMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.mezzanineMq}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq mansardati
                  </label>
                  <input
                    type="text"
                    name="canopyMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.canopyMq}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 1 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq coperti lordi
                  </label>
                  <input
                    type="text"
                    name="grossCoveredMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.grossCoveredMq}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq verande rifinite
                  </label>
                  <input
                    type="text"
                    name="finishingVerandaMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.finishingVerandaMq}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {(typologyState === 2 || typologyState === 3) && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq coperti
                  </label>
                  <input
                    type="text"
                    name="grossCoveredMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.grossCoveredMq}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq scoperti
                  </label>
                  <input
                    type="text"
                    name="uncoveredMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.uncoveredMq}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 4 || typologyState === 5) && (
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Mq coperti
                </label>
                <input
                  type="text"
                  name="grossCoveredMq"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={data.grossCoveredMq}
                />
              </div>
            </div>
          </div>
        )}
        {(typologyState === 6 ||
          typologyState === 7 ||
          typologyState === 8) && (
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Mq coperti
                </label>
                <input
                  type="text"
                  name="grossCoveredMq"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={data.grossCoveredMq}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Mq tettoie
                </label>
                <input
                  type="text"
                  name="canopyMq"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={data.canopyMq}
                />
              </div>
            </div>
          </div>
        )}
        {typologyState === 6 && (
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Mq aree cortilizie
                </label>
                <input
                  type="text"
                  name="courtyardAreaMq"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={data.courtyardAreaMq}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Mq terreni annessi
                </label>
                <input
                  type="text"
                  name="attachedLandMq"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={data.attachedLandMq}
                />
              </div>
            </div>
          </div>
        )}
        {(typologyState === 4 ||
          typologyState === 5 ||
          typologyState === 6 ||
          typologyState === 7) && (
          <div className="label-heading mb-3 d-block w-100 mt-3">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">
                CARATTERISTICHE PRINCIPALI
              </h1>
            </div>
          </div>
        )}
        {(typologyState === 4 || typologyState === 5) && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano
                  </label>
                  <select
                    name="floor"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.floor}
                    onChange={onChange}
                  >
                    {floorTypeBox.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Facilità manovra d’accesso
                  </label>
                  <select
                    name="boxAccessibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.boxAccessibility}
                    onChange={onChange}
                  >
                    {boxAccessibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Difficoltà di parcheggio in zona
                  </label>
                  <select
                    name="parkingAccessibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.parkingAccessibility}
                    onChange={onChange}
                  >
                    {parkingAccessibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {typologyState === 4 && (
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Tipo
                    </label>
                    <select
                      name="boxType"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      value={data.boxType}
                      onChange={onChange}
                    >
                      {boxType.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {menu}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {typologyState === 6 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo di struttura portante
                  </label>
                  <select
                    name="supportingStructureType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.supportingStructureType}
                    onChange={onChange}
                  >
                    {supportingStructureType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Altezza soffitti livello principale
                  </label>
                  <select
                    name="mainLevelCeilingHeight"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.mainLevelCeilingHeight}
                    onChange={onChange}
                  >
                    {mainLevelCeilingHeightType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Visbilità ubicazione
                  </label>
                  <select
                    name="locationVisibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.locationVisibility}
                    onChange={onChange}
                  >
                    {locationVisibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano
                  </label>
                  <select
                    name="floor"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.floor}
                    onChange={onChange}
                  >
                    {floorTypeBox.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 7 || typologyState === 8) && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano
                  </label>
                  <select
                    name="floor"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.floor}
                    onChange={onChange}
                  >
                    {floorTypeBox.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {typologyState === 7 && (
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Tipo di ingresso al magazzino
                    </label>
                    <select
                      name="entryType"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      value={data.entryType}
                      onChange={onChange}
                    >
                      {entryType.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {menu}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {typologyState === 8 && (
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Tipo di ingresso al laboratorio
                    </label>
                    <select
                      name="labEntryType"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      value={data.labEntryType}
                      onChange={onChange}
                    >
                      {entryType.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {menu}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Visibilità dell’ubicazione
                  </label>
                  <select
                    name="locationVisibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.locationVisibility}
                    onChange={onChange}
                  >
                    {locationVisibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 0 || typologyState === 1) && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  SUPERFICI SCOPERTE
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq balconi
                  </label>
                  <input
                    type="text"
                    name="balconyMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.balconyMq}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq terrazzi
                  </label>
                  <input
                    type="text"
                    name="terraceMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.terraceMq}
                  />
                </div>
              </div>
            </div>
            {typologyState === 0 && (
              <>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Mq giardini o cortili
                      </label>
                      <input
                        type="text"
                        name="gardenMq"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={data.gardenMq}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Mq loggie o patii
                      </label>
                      <input
                        type="text"
                        name="lodgeMq"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={data.lodgeMq}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Mq lastrico solare
                      </label>
                      <input
                        type="text"
                        name="solarPavementMq"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={data.solarPavementMq}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Altri mq scoperti
                      </label>
                      <input
                        type="text"
                        name="otherUncoveredMq"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={data.otherUncoveredMq}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {(typologyState === 0 ||
          typologyState === 1 ||
          typologyState === 2) && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  VANI PERTINENZIALI ANNESSI
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Cantina
                  </label>
                  <select
                    name="winery"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.winery}
                    onChange={onChange}
                  >
                    {wineryType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq cantina
                  </label>
                  <input
                    type="text"
                    name="wineryMq"
                    id="wineryMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.wineryMq}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Soffita
                  </label>
                  <select
                    name="garret"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.garret}
                    onChange={onChange}
                  >
                    {wineryType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq soffitta
                  </label>
                  <input
                    type="text"
                    name="garretMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.garretMq}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {(typologyState === 0 ||
          typologyState === 1 ||
          typologyState === 2) && (
          <div className="label-heading mb-3 d-block w-100 mt-3">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">
                BOX E POSTI AUTO ANNESSI
              </h1>
            </div>
          </div>
        )}
        {(typologyState === 0 || typologyState === 1) && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Box auto
                  </label>
                  <select
                    name="box"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.box}
                    onChange={onChange}
                  >
                    {boxType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq box auto
                  </label>
                  <input
                    type="text"
                    name="boxMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.boxMq}
                  />
                </div>
              </div>
            </div>
            {typologyState === 0 && (
              <>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Manovra accesso box
                      </label>
                      <select
                        name="boxAccessibility"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.boxAccessibility}
                        onChange={onChange}
                      >
                        {boxAccessibilityType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Ubicazione box
                      </label>
                      <select
                        name="boxLocation"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.boxLocation}
                        onChange={onChange}
                      >
                        {boxLocationType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Posto auto coperto
                      </label>
                      <select
                        name="coveredParkingSpace"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.coveredParkingSpace}
                        onChange={onChange}
                      >
                        {boxType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Manovra accesso auto coperto
                      </label>
                      <select
                        name="coveredParkingAccessibility"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.coveredParkingAccessibility}
                        onChange={onChange}
                      >
                        {boxAccessibilityType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Ubicazione posto auto coperto
                      </label>
                      <select
                        name="coveredParkingLocation"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.coveredParkingLocation}
                        onChange={onChange}
                      >
                        {boxLocationType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Posto auto scoperto
                      </label>
                      <select
                        name="condominialParkingSpace"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.condominialParkingSpace}
                        onChange={onChange}
                      >
                        {boxType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Manovra accesso auto scoperto
                      </label>
                      <select
                        name="uncoveredParkingAccessibility"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.uncoveredParkingAccessibility}
                        onChange={onChange}
                      >
                        {boxAccessibilityType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Ubicazione posto auto scoperto
                      </label>
                      <select
                        name="uncoveredParkingLocation"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.uncoveredParkingLocation}
                        onChange={onChange}
                      >
                        {boxLocationType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Posto auto condominiale
                  </label>
                  <select
                    name="condominialParkingSpace"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.condominialParkingSpace}
                    onChange={onChange}
                  >
                    {condominialParkingSpaceType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo posto auto condominiale
                  </label>
                  <select
                    name="condominialParkingType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.condominialParkingType}
                    onChange={onChange}
                  >
                    {condominialParkingType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Difficoltà di parcheggio in zona
                  </label>
                  <select
                    name="parkingAccessibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.parkingAccessibility}
                    onChange={onChange}
                  >
                    {parkingAccessibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 2 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Box auto
                  </label>
                  <select
                    name="box"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.box}
                    onChange={onChange}
                  >
                    {boxType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Posto auto scoperto
                  </label>
                  <select
                    name="condominialParkingSpace"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.condominialParkingSpace}
                    onChange={onChange}
                  >
                    {boxType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Posto auto coperto
                  </label>
                  <select
                    name="coveredParkingSpace"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.coveredParkingSpace}
                    onChange={onChange}
                  >
                    {boxType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Difficoltà parcheggio in zona
                  </label>
                  <select
                    name="parkingAccessibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.parkingAccessibility}
                    onChange={onChange}
                  >
                    {parkingAccessibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 0 ||
          typologyState === 1 ||
          typologyState === 2) && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">PIANO</h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano
                  </label>
                  <select
                    name="floor"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.floor}
                    onChange={onChange}
                  >
                    {floorType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {typologyState === 0 && (
                <>
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Attico / Ultimo piano / Mansarda
                      </label>
                      <select
                        name="lastFloor"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                        value={data.lastFloor}
                        onChange={onChange}
                      >
                        {lastFloorType.map((menu, index) => {
                          return (
                            <option key={index} value={menu}>
                              {menu}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>{" "}
          </>
        )}
        {typologyState === 3 && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  CARATTERISTICHE COSTRUTTIVE
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano principale
                  </label>
                  <select
                    name="mainFloor"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.mainFloor}
                    onChange={onChange}
                  >
                    {mainFloorType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipologia edificio
                  </label>
                  <select
                    name="buildingTypology"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingTypology}
                    onChange={onChange}
                  >
                    {buildingTypologyTypeNegozi.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 3 && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  VISIBILITA’ COMMERCIALE
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Posizione vetrine
                  </label>
                  <select
                    name="windowPosition"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.windowPosition}
                    onChange={onChange}
                  >
                    {windowPositionType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Visibilità commerciale del negozio
                  </label>
                  <select
                    name="commercialVisibility"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.commercialVisibility}
                    onChange={onChange}
                  >
                    {commercialVisibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 3 && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">POSTO AUTO</h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Posto auto coperto
                  </label>
                  <select
                    name="coveredParkingSpace"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.coveredParkingSpace}
                    onChange={onChange}
                  >
                    {boxTypeNegozi.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Posto auto scoperto
                  </label>
                  <select
                    name="condominialParkingSpace"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.condominialParkingSpace}
                    onChange={onChange}
                  >
                    {boxType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 0 ||
          typologyState === 1 ||
          typologyState === 2) && (
          <div className="input-checkbox mt-3">
            <input
              type="checkbox"
              name="lift"
              checked={data.lift}
              onChange={(e) => {
                onChange(e);
              }}
            />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Ascensore</label>
          </div>
        )}

        {(typologyState === 0 || typologyState === 1) && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  ESPOSIZIONE E VISTA
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    N° affacci principali
                  </label>
                  <select
                    name="numberExposition"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.numberExposition}
                    onChange={onChange}
                  >
                    {numberExpositionType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Esposizione principale
                  </label>
                  <select
                    name="mainExposition"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.mainExposition}
                    onChange={onChange}
                  >
                    {mainExpositionType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Vista principale - in relazione al piano
                  </label>
                  <select
                    name="mainView"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.mainView}
                    onChange={onChange}
                  >
                    {mainViewType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Luminosità - in relazione al piano
                  </label>
                  <select
                    name="brightness"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.brightness}
                    onChange={onChange}
                  >
                    {brightnessType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Rumorosità - in relazione alla media di zona
                  </label>
                  <select
                    name="noisiness"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.noisiness}
                    onChange={onChange}
                  >
                    {noisinessType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Altezza soffitti
                  </label>
                  <select
                    name="ceilingHeight"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.ceilingHeight}
                    onChange={onChange}
                  >
                    {ceilingHeightType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  DISPOSIZIONE E DISTRIBUZIONE SUPERFICI
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Livelli interni
                  </label>
                  <select
                    name="internalLevels"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.internalLevels}
                    onChange={onChange}
                  >
                    {internalLevelsType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Distribuzione vani interni
                  </label>
                  <select
                    name="internalDistribution"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.internalDistribution}
                    onChange={onChange}
                  >
                    {internalDistributionType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Rapporto tra mq coperti e n° vani
                  </label>
                  <select
                    name="coveredMqRoomsRatio"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.coveredMqRoomsRatio}
                    onChange={onChange}
                  >
                    {coveredMqRoomsRatioType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Rapporto tra mq coperti e bagni
                  </label>
                  <select
                    name="coveredMqBathsRatio"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.coveredMqBathsRatio}
                    onChange={onChange}
                  >
                    {coveredMqBathsRatioType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  STATO DI CONSERVAZIONE E DOTAZIONI
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservativo interno
                  </label>
                  <select
                    name="internalState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.internalState}
                    onChange={onChange}
                  >
                    {internalStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Grado finiture
                  </label>
                  <select
                    name="finishingQuality"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.finishingQuality}
                    onChange={onChange}
                  >
                    {finishingQualityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {typologyState === 1 && (
              <div className="label-heading mb-3 d-block w-100 mt-3">
                <div className="label-text rel d-inline-block px-2 title-padding">
                  <h1 className="fs-16 text-white text-uppercase">
                    RISCALDAMENTO E CLASSE ENERGETICA
                  </h1>
                </div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo di riscaldamento
                  </label>
                  <select
                    name="heatingType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.heatingType}
                    onChange={onChange}
                  >
                    {heatingTypeType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Classe energetica
                  </label>
                  <select
                    name="energeticClass"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.energeticClass}
                    onChange={onChange}
                  >
                    {energeticClassType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  DATI EDIFICIO
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Ubicazione edificio
                  </label>
                  <select
                    name="buildingLocation"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingLocation}
                    onChange={onChange}
                  >
                    {buildingLocationType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipologia edificio
                  </label>
                  <select
                    name="buildingTypology"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingTypology}
                    onChange={onChange}
                  >
                    {buildingTypologyType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Età edificio
                  </label>
                  <select
                    name="buildingAge"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingAge}
                    onChange={onChange}
                  >
                    {buildingAgeType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservazione edificio
                  </label>
                  <select
                    name="buildingState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingState}
                    onChange={onChange}
                  >
                    {buildingStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 0 && (
          <>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  TIPO MERCATO E STATO LOCATIVO
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mercato di riferimento
                  </label>
                  <select
                    name="marketRef"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.marketRef}
                    onChange={onChange}
                  >
                    {marketRefType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Nuda proprietà
                  </label>
                  <select
                    name="bareOwnership"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.bareOwnership}
                    onChange={onChange}
                  >
                    {bareOwnershipType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato locativo
                  </label>
                  <select
                    name="rentalState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.rentalState}
                    onChange={onChange}
                  >
                    {rentalStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Redditivitò locazione - in caso di immobili locati
                  </label>
                  <select
                    name="leaseProfitability"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.leaseProfitability}
                    onChange={onChange}
                  >
                    {leaseProfitabilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 2 ||
          typologyState === 3 ||
          typologyState === 4 ||
          typologyState === 5 ||
          typologyState === 6) && (
          <div className="label-heading mb-3 d-block w-100 mt-3">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">
                ALTRE CARATTERISTICHE
              </h1>
            </div>
          </div>
        )}
        {typologyState === 4 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Larghezza entrata box in mq
                  </label>
                  <input
                    type="text"
                    name="entranceWidth"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.entranceWidth}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label className="f-label fs-16 d-flex align-items-center
mb-2">Capienza auto</label>
                  <NumericInput
                    value={data.carCapacity}
                    className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
                    onChange={(value) => {
                      let e = {
                        target: {
                          name: "carCapacity",
                          value: value,
                        },
                      };
                      onChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label className="f-label fs-16 d-flex align-items-center
mb-2">Capienza moto</label>
                  <NumericInput
                    value={data.motorcycleCapacity}
                    className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
                    onChange={(value) => {
                      let e = {
                        target: {
                          name: "motorcycleCapacity",
                          value: value,
                        },
                      };
                      onChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 5 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label className="f-label fs-16 d-flex align-items-center
mb-2">Capienza moto</label>
                  <NumericInput
                    value={data.motorcycleCapacity}
                    className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
                    onChange={(value) => {
                      let e = {
                        target: {
                          name: "motorcycleCapacity",
                          value: value,
                        },
                      };
                      onChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label className="f-label fs-16 d-flex align-items-center
mb-2">Capienza auto</label>
                  <NumericInput
                    value={data.carCapacity}
                    className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
                    onChange={(value) => {
                      let e = {
                        target: {
                          name: "carCapacity",
                          value: value,
                        },
                      };
                      onChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 6 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label className="f-label fs-16 d-flex align-items-center
mb-2">
                    Anno di costruzione
                  </label>
                  <NumericInput
                    value={data.buildingYear}
                    className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
                    onChange={(value) => {
                      let e = {
                        target: {
                          name: "buildingYear",
                          value: value,
                        },
                      };
                      onChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label className="f-label fs-16 d-flex align-items-center
mb-2">
                    Altezza sottotrave in metri
                  </label>
                  <NumericInput
                    value={data.underbeamHeight}
                    className="border fs-16 text-dark rounded-3 border-opacity-50 bg-white form-control w-100"
                    onChange={(value) => {
                      let e = {
                        target: {
                          name: "underbeamHeight",
                          value: value,
                        },
                      };
                      onChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato edificio
                  </label>
                  <select
                    name="buildingState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingState}
                    onChange={onChange}
                  >
                    {buildingStateTypeCapannoni.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 2 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservativo interno
                  </label>
                  <select
                    name="internalState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.internalState}
                    onChange={onChange}
                  >
                    {internalStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Classe energetica
                  </label>
                  <select
                    name="energeticClass"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.energeticClass}
                    onChange={onChange}
                  >
                    {energeticClassType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo di riscaldamento
                  </label>
                  <select
                    name="heatingType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.heatingType}
                    onChange={onChange}
                  >
                    {heatingTypeType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {typologyState === 3 && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Altezza soffitti
                  </label>
                  <select
                    name="ceilingHeight"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.ceilingHeight}
                    onChange={onChange}
                  >
                    {ceilingHeightType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservativo interno
                  </label>
                  <select
                    name="internalState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.internalState}
                    onChange={onChange}
                  >
                    {internalStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="input-checkbox mt-3">
              <input
                type="checkbox"
                name="lift"
                checked={data.lift}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Ascensore</label>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo di riscaldamento
                  </label>
                  <select
                    name="heatingType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.heatingType}
                    onChange={onChange}
                  >
                    {heatingTypeType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Classe energetica
                  </label>
                  <select
                    name="energeticClass"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.energeticClass}
                    onChange={onChange}
                  >
                    {energeticClassType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
        {(typologyState === 2 ||
          typologyState === 3 ||
          typologyState === 7 ||
          typologyState === 8) && (
          <div className="label-heading mb-3 d-block w-100 mt-3">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">EDIFICIO</h1>
            </div>
          </div>
        )}
        {(typologyState === 2 || typologyState === 3) && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Età edificio
                  </label>
                  <select
                    name="buildingAge"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingAge}
                    onChange={onChange}
                  >
                    {buildingAgeType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservazione edificio
                  </label>
                  <select
                    name="buildingState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingState}
                    onChange={onChange}
                  >
                    {buildingStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Totale piani edificio
                  </label>
                  <input
                    type="text"
                    name="totalFloorNumber"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={data.totalFloorNumber}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {(typologyState === 7 || typologyState === 8) && (
          <>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo di edificio
                  </label>
                  <select
                    name="buildingType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingType}
                    onChange={onChange}
                  >
                    {buildingType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservazione edificio
                  </label>
                  <select
                    name="buildingState"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingState}
                    onChange={onChange}
                  >
                    {buildingStateType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Età edificio
                  </label>
                  <select
                    name="buildingAge"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={data.buildingAge}
                    onChange={onChange}
                  >
                    {buildingAgeType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};

export default FeatureE;
