import React from "react";
import TopHeading from "../components/TopHeading";
import FormBox from "../components/calcoli_immobiliari/calcoli/index";

export default function Calcoli(props) {
  return (
    <>
      <div className="mb-4">
        <TopHeading SmallHeading="empty" />
      </div>
      <FormBox />
    </>
  );
}
