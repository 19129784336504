import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import "../../../../assets/css/Tabs.css";
import {formatDateFromString} from "../../../../utility";

const Step2 = (props) => {
  const [currentDate, setCurrentDate] = useState("");
  const [error, setError] = useState(false);

  const getCurrentDate = () => {
    var today = new Date();
    setCurrentDate(formatDateFromString(today.toString()));
  };

  useEffect(() => {
    props.getProvince();
    getCurrentDate();
  }, []);

  useEffect(() => {
    props.getMunicipality(props.province[0]);
  }, [props.province]);

  const onChange = (e) => {
    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.value,
    });
  };

  const isValidFiscalCode = (value) => {
    var regex =
      /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
    return value.match(regex);
  };

  const fiscalCodeChange = (e) => {
    e.target.value = e.target.value.toUpperCase();
    if (isValidFiscalCode(e.target.value)) {
      setError(false);
    } else {
      setError(true);
    }
    onChange(e);
  };

  const handleProvince = (e) => {
    onChange(e);
    props.getMunicipality(e.target.value);
  };

  return (
    <div
      className="container"
      style={{ display: props.isActive === true ? "" : "none" }}
    >
      <div className="tab1ContentTitle justify-content-center">
        Dati Personali
      </div>
      <div className="col-md-12 col-sm-12 mt-2">
        <div className="row mt-2">
          <div className="col-md-6 col-sm-6 padding-right tabContent-input1">
            <div className="mt-2">
              <div className="tabContentSubtitle">Nome </div>
              <input
                name="name"
                type="text"
                placeholder="Inserisci il tuo nome"
                className="tabInputField form-control w-100"
                onChange={onChange}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle">Cognome</div>
              <input
                name="surname"
                type="text"
                placeholder="Inserisci il tuo cognome"
                className="tabInputField form-control w-100"
                onChange={onChange}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle">Codice Fiscale</div>
              <input
                name="fiscalCode"
                type="text"
                id={error ? "input_error" : ""}
                placeholder="Inserisci il tuo codice fiscale"
                className="tabInputField form-control w-100"
                onChange={fiscalCodeChange}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle">Sesso</div>
              <select
                name="sex"
                onChange={onChange}
                placeholder="Seleziona"
                className="tabInputField form-control w-100"
              >
                <option value="M">Maschio</option>
                <option value="F">Femmina</option>
              </select>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle">Data di nascita</div>
              <input
                name="birthday"
                type="date"
                placeholder="Inserisci il tuo codice fiscale"
                className="tabInputField form-control w-100"
                onChange={onChange}
                max="9999-12-31"

              ></input>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 tabContent-input2 padding-left">
            <div className="mt-2">
              <div className="tabContentSubtitle ">Indirizzo di residenza</div>
              <input
                name="address"
                type="text"
                placeholder="Inserisci l’indirizzo di residenza"
                className="tabInputField form-control w-100"
                onChange={onChange}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle ">Numero civico</div>
              <input
                name="houseNumber"
                type="text"
                placeholder="Inserisci il numero civico"
                className="tabInputField form-control w-100"
                onChange={onChange}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle ">Provincia di residenza</div>
              <Form.Select
                name="province"
                id="selectProvince"
                className="tabInputField w-100"
                onChange={handleProvince}
              >
                <option selected disabled>
                  Inserisci la tua provincia di residenza
                </option>
                {props.province &&
                  props.province.map((item, key) => {
                    return (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Select>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle ">Comune di residenza</div>
              <Form.Select
                name="municipality"
                id="selectCity"
                className="tabInputField w-100"
                onChange={onChange}
              >
                <option selected disabled>
                  Inserisci il tuo comune di residenza
                </option>
                {props.municipality?.map((item, key) => {
                  return (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle ">CAP</div>
              <input
                name="cap"
                type="number"
                placeholder="Inserisci il tuo CAP"
                className="tabInputField form-control w-100"
                onChange={onChange}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
