import React from "react";

const CondominiumCard = (props) => {
	return (
		<div
			className={`mt-4 ${
				props.edit_mode === undefined || props.edit_mode === false
					? "col-md-6"
					: ""
			}`}>
			<div className="popup-blue d-block overflow-hidden rounded-3">
				<div className="blue-popup-header d-flex align-items-center justify-content-between p-2">
					<h3 className="fs-16 text-white fw-semibold">{props.data.name}</h3>
					{(props.edit_mode === undefined || props.edit_mode === true) && (
						<button
							type="button"
							className="border-0 bg-transparent text-white"
							onClick={() => {
								props.deleteCondominium(props.id);
							}}>
							<i className="bi bi-x"></i>
						</button>
					)}
				</div>
				<div className="table-list d-block  card-table">
					<table className="table table-borderless">
						<tbody>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									AMMINISTRATORE
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.administrator}
								</td>
							</tr>

							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									INDIRIZZO
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.address}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									CITTA’
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.municipality}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									CAP
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.cap}
								</td>
							</tr>
							{/* <tr>
								<td className="text-uppercase fs-14 text-secondary px-3">CF</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.fiscalCode}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									DATA D'INIZIO POSSESSO
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">???</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default CondominiumCard;
