import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { BiChevronRightCircle } from "react-icons/bi";
import '../../../assets/css/calcoli.css';
import "../../../assets/css/calculator.css";
import Page1 from '../content/page1';
import Page10 from '../content/page10';
import Page2_1 from '../content/page2_1';
import Page3 from '../content/page3';
import Page4 from '../content/page4';
import Page5 from '../content/page5';
import Page6 from '../content/page6';
import Page7 from '../content/page7';
import Page8 from '../content/page8';
import Page9 from '../content/page9';

export default function () {
  const [count, setCount] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const [mobile_menu, setMobileMenu] = useState(false);

  useEffect(() => {
    if (width <= 575) {
      setIsMobile(true);
      setIsDesktop(false);
    } else if (width >= 576 && width < 767) {
      setIsMobile(true);
      setIsDesktop(false);
    } else if (width >= 768 && width < 991) {
      setIsMobile(false);
      setIsDesktop(true);
    } else if (width >= 992 && width < 1199) {
      setIsMobile(false);
      setIsDesktop(true);
    } else {
      setIsMobile(false);
      setIsDesktop(true);
    }
  }, [width]);

  const toggleMobileMenu = () => {
    setMobileMenu(!mobile_menu);
  };

  const selectMenu = (value) => {
    setCount(value);
    setMobileMenu(false);
  }

  const Page = () => {
    switch (count) {
      case 0:
        return (<Page1 />);
      case 1:
        return (<Page2_1 selectMenu={selectMenu} />);
      case 2:
        return (<Page3 />);
      case 3:
        return (<Page4 />);
      case 4:
        return (<Page5 />);
      case 5:
        return (<Page6 />);
      case 6:
        return (<Page7 />);
      case 7:
        return (<Page8 />);
      case 8:
        return (<Page9 />);
      case 9:
        return (<Page10 />);
      default:
        return (
          <div>
            <p>No pages</p>
          </div>
        );
    }
  }
  return (
    <div className="form-outer-box d-block">

      <div className="body">

        <h2 className="title">
          Calcoli
        </h2>
        {isMobile &&
          <BiChevronRightCircle className="calcoli-mobile-menu" onClick={toggleMobileMenu} />
        }
        <div className={`${mobile_menu ? "display-show" : "display-hidden"}`} style={{marginTop: "1rem"}}>
          <div className="mt-5 menuList" style={{marginTop: "1rem"}}>
            <div className={count === 0 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(0)}>Aggiornamento ISTAT</div>
            <div className={count === 1 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(1)}> Imposte di Registro</div>
            <div className={count === 2 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(2)}>Ravvedimento imposta di registro</div>
            <div className={count === 3 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(3)}>Ravvedimento imposta di bollo</div>
            <div className={count === 4 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(4)}>Interessi</div>
            <div className={count === 5 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(5)}>{/* Reddito di fabbricato */}Valore Catastale</div>
            {/* <div className={count === 6 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(6)}>Operazioni con date</div> */}
            <div className={count === 7 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(7)}>Valore sulla nuda proprietà</div>
            <div className={count === 8 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(8)}>Imposte compravendita</div>
            <div className={count === 9 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(9)}>Acconto IMU</div>
          </div>
        </div>


        <div>
          <Row>
            {!isMobile &&
              <Col xs={3} style={{marginTop: "1rem"}}>
                <div className={count === 1 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(1)}> Imposte di Registro</div>
                <div className={count === 8 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(8)}>Imposte compravendita</div>
                <div className={count === 2 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(2)}>Ravvedimento imposta di registro</div>
                <div className={count === 3 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(3)}>Ravvedimento imposta di bollo</div>
                <div className={count === 5 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(5)}>{/* Reddito di fabbricato */}Valore Catastale</div>
               {/*  <div className={count === 6 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(6)}>Operazioni con date</div> */}
                <div className={count === 7 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(7)}>Valore sulla nuda proprietà</div>
                <div className={count === 9 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(9)}>Acconto IMU</div>
                <div className={count === 4 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(4)}>Interessi</div>
                <div className={count === 0 ? 'btn-list space active' : 'btn-list space'} onClick={() => selectMenu(0)}>Aggiornamento ISTAT</div>
              </Col>
            }
            <Col className={`${isMobile ? "col-xl-12 col-md-12 col-12" : "col-xl-9 col-md-9 col-9"}`}>
              <div className='content'>
                <Page />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div >
  );
}
