import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Modal } from 'react-bootstrap'
import { useParams, useNavigate, Link } from 'react-router-dom'

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import TopHeading from '../TopHeading'
import ButtonRm from '../ButtonRm'

import CustomToggle from "../CustomToggle";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, fetchDeadline, removeDeadline, editDeadline } from "../../actions/deadline";

import { Breadcrumb } from 'react-bootstrap';

import { formattedTimestamp } from '../../utils/dateManipulation';

import CustomBadge from '../CustomBadge';

const schema = yup.object().shape({
    title: yup.string().when("$isModified", {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
/*       startDate: yup.date().when("$isModified", {
        is: true,
        then: yup.date().required("Date is required"),
        otherwise: yup.date(),
      }),
      endDate: yup.date().when("$isModified", {
        is: true,
        then: yup.date().required("Date is required"),
        otherwise: yup.date(),
      }), */
    section: yup.string(),
    every:	yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
    recurrenceType:	yup.string(),
    toDate:	yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    notification: yup.string(),
    sharing: yup.string(),
    note: yup.string()
    //aggiungere voci api giorni di preavviso
    //aggiugnere voci api status

})

export default function EventoDetails(){
    const navigate = useNavigate();

    const {id} = useParams()

    const deadlineList = useSelector((state) => state.deadlineReducer?.deadline);

    const [formData, setFormData] = useState({})

    useEffect(() => {
        if(id){
            const deadlineItem = deadlineList.length > 0 ? deadlineList.find(item => item.id == id) : {}

            setFormData({
                ...deadlineItem,
                startDate: deadlineItem.startDate?.substring(0,16),
                endDate: deadlineItem.endDate?.substring(0,16),
                toDate: deadlineItem.toDate?  deadlineItem.toDate.substring(0,16): deadlineItem.toDate
            })
        }
    }, [id])

    const [viewMode, setViewMode] = useState(true)
    const [show, setShow] = useState(false);
    const handleModal = () => {
        setShow(true);
    };


    function handleFormChange(e) {
        const {name, value, type, checked} = e.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    function changeMode(e){
        e.preventDefault()
        setViewMode(prev => !prev)
    }

    function cancelChanges(){
        if(viewMode == false){
            const deadlineItem = deadlineList.length > 0 ? deadlineList.find(item => item.id == id) : {}

            setFormData({
                ...deadlineItem,
                startDate: deadlineItem.startDate.substring(0,16),
                endDate: deadlineItem.endDate.substring(0,16),
                toDate: deadlineItem.toDate?  deadlineItem.toDate.substring(0,16): deadlineItem.toDate
            })
        }
        setViewMode(prev => !prev)
        setShow(false)
    }

    function convertDataForApi(data){
        const inputDate =  new Date(data)
        const isoDateString = inputDate.toISOString();
        const apiDateString = isoDateString.substring(0, 19) + ".000+0000";
        return apiDateString
    }

    //EDIT
    const dispatch = useDispatch();


    //EDIT
    async function editItem(id, data) {
        dispatch(editDeadline(id, data)).then(() => {
        dispatch(fetchDeadline());
        }
        )
    }

          //VALIDAZIONE INPUT
          const { register, handleSubmit, formState: { errors }, reset } = useForm({
            resolver: yupResolver(schema),
        });

        const onSubmit = (data) => {
            /*             let str = {
                            ...formData,
                            startDate: convertDataForApi(formData.startDate) ,
                            endDate:convertDataForApi(formData.endDate),
                            toDate: formData.toDate ? convertDataForApi(formData.toDate): formData.toDate
                        } */
                        // controlla se ci sono errori di validazione
                        if (Object.keys(errors).length > 0) {
                            console.log("Ci sono errori di validazione", errors);
                            return;
                        } else {
                        // azione da eseguire quando i dati sono tutti corretti
                        console.log("corretti", {data}, errors)
                        editItem(id, formData)
                        setViewMode(prev => !prev)
                        navigate(-1)
                        }
            
                        
                    };

        //HANDLE ACCORDION
        const [activeKey, setActiveKey] = useState("")

    return(
        <>
            <Row>
                <Col>
                    <TopHeading SmallHeading={viewMode ? '' : 'empty'} back_modal={false}/>
                </Col>
            </Row>

            <Col md={12} className="my-3 mb-0 px-0">
				<Breadcrumb>
					<Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate('/alert/scadenzario')
						}}>
                        Scadenzario
					</Breadcrumb.Item>

					
				
					<Breadcrumb.Item active={true} 
						className="settings-archive"
					>
					{formData?.title}

					</Breadcrumb.Item>
				</Breadcrumb>
			  </Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mobile-container d-flex flex-column mt-md-4">
                <Col  className="d-flex mt-3">
                    <div>
                        <h2 className="fw-bold text-capitalize">{formData.title}</h2>
                       {formData.cdat && <small>Creato il {formattedTimestamp(formData.cdat)}</small>}
                    </div>
                    <div className="ms-md-auto d-flex">
                    {viewMode? 
                        <ButtonRm variant="blue" onClick={ changeMode} size="small">
                            Modifica
                        </ButtonRm>:
                         <ButtonRm variant="blue" type="submit" size="small">
                         Salva Modifiche
                        </ButtonRm>
                     }
                        {
                            !viewMode &&
                            <a className="cancel-btn ms-2" onClick={handleModal} >
                                <i class="bi bi-x-lg"></i>
                            </a>
                        }
                    </div>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center">
                <div className="bg-sm-white my-3 px-0"style={{borderRadius:"1rem", height:"fit-content", width:"98%"}}>
                    <fieldset disabled={viewMode? true : false}>
                    <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
                        <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="0" activeKey={activeKey}>Dati 
                            <span className="mandatory-field">*</span><CustomBadge variant="custom-badge-orange">
                                Campo obbligatorio</CustomBadge>
                            </CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                            <Row className="d-md-flex">   
                                
                                <Form.Group className="mb-3 input-size">
                                    <Form.Label className="input-label">Titolo   <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                                    <Form.Control
                                    {...register("title")}
                                    isInvalid={!!errors.title}
                                    type="text" placeholder="Inserisci un titolo" 
                                    name="title" 
                                    value={formData.title} 
                                    onChange={handleFormChange}
                    
                                    />
                                    
                                </Form.Group>

                                <Form.Group className="mb-3 input-size">
                                    <Form.Label className="input-label">Sezione di riferimento</Form.Label>
                                    <Form.Select aria-label="Default select example" 
                                        {...register("section")}
                                        isInvalid={!!errors.section}
                                        name="section"
                                        value={formData.section} 
                                        onChange={handleFormChange} 
                                    >
                                        <option>
                                            Seleziona
                                        </option>
                                        <option value="Unità e Stabili">Unità e Stabili</option>
                                        <option value="Affitti">Affitti</option>
                                        <option value="Attività">SAL</option>
                                        <option value="Rate">Rate</option>
                                        <option value="Scadenze Personali">Scadenze Personali</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.section?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                        </Row>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="1" activeKey={activeKey}>Date e Durata  <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                            <Row className="d-md-flex ">  

                                <Form.Group className="mb-3 input-size">
                                    <Form.Label className="input-label">Data di Inizio  <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                                    <Form.Control 
                                    {...register("startDate")}
                                    isInvalid={!!errors.startDate}
                                    type="datetime-local" 
                                    name="startDate" 
                                    value={formData.startDate} 
                                    onChange={handleFormChange} 
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.startDate?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 input-size">
                                    <Form.Label className="input-label">Data di Fine  <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                                    <Form.Control 
                                    {...register("endDate")}
                                    isInvalid={!!errors.endDate}
                                    type="datetime-local"   
                                    name="endDate" 
                                    value={formData.endDate} 
                                    onChange={handleFormChange} 
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.endDate?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="2" activeKey={activeKey}>Ricorrenza <OverlayTrigger
                            placement="top"
                            delay={{ show: 600, hide: 300 }}
                            overlay={
                            <Tooltip className='tooltipContabilita'><p>Si tratta di un evento ricorrente? Anziché creare più volte lo stesso evento, puoi impostare di seguito i dettagli di ricorrenza di questo. <br/> Se ad esempio si tratta di un evento che si svolge ogni 2 mesi fino al 31/12/2023, dovrai selezionare: <br/>
                            Frequenza:  "Mesi", <br/>
                            Ogni: 2, <br/>
                            Fine Ricorrenza: "31/12/2023"
                            </p></Tooltip>
                            }
                            >
                            <i class="bi bi-info-circle ms-2"></i>
                            </OverlayTrigger>
                            </CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                            <Row>
                            </Row>
                            <Row className="d-md-flex">   
                            <Form.Group className="mb-3 input-size">
                                            <Form.Label className="input-label">Frequenza</Form.Label>
                                            <Form.Select 
                                            {...register("recurrenceType")}
                                            isInvalid={!!errors.recurrenceType}name="recurrenceType"
                                            value={formData.recurrenceType} 
                                            onChange={handleFormChange} 
                                            >
                                                
                                                <option>
                                                   
                                                </option>
                                                <option value="giorno/i">Giorno/i</option>
                                                    <option value="settimana/e">Settimana/e</option>
                                                    <option value="mese/i">Mese/i</option>
                                                    <option value="anni/i">Anno/i</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" >
                                        {errors.recurrenceType?.message}
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                                                    
                                        <Form.Group className="mb-3 input-size">
                                            <Form.Label className="input-label">Ogni</Form.Label>
                                            <Form.Control 
                                            {...register("every")}
                                            isInvalid={!!errors.every}
                                            type="number" placeholder="Inserisci un numero" name="every" 
                                            value={formData.every} 
                                            onChange={handleFormChange}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            {errors.every?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3 input-size">
                                            <Form.Label className="input-label">Fine Ricorrenza</Form.Label>
                                            <Form.Control 
                                            {...register("toDate")}
                                            isInvalid={!!errors.toDate}type="datetime-local"   
                                            name="toDate" 
                                            value={formData.toDate} 
                                            onChange={handleFormChange}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            {errors.toDate?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="3" activeKey={activeKey}>Notifiche</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                            <Row className="d-md-flex "> 

                            <Form.Group className="mb-3 input-size">
                                    <Form.Label className="input-label"> Quanti giorni prima di questo evento vuoi essere avvisato?</Form.Label>
                                    <Form.Control 
                                    type="number" 
                                    name="daysBeforeNotification"
                                    value={formData.daysBeforeNotification} 
                                    onChange={handleFormChange}

                                />
                                <Form.Control.Feedback type="invalid" >
                                    
                                </Form.Control.Feedback>
                            </Form.Group>


                            <Form.Group className="mb-3 mb-sm-0 input-size">
                                <Form.Label className="input-label">Dove vuoi ricevere la notifica?</Form.Label>
                                <Form.Select
                                {...register("notification")}
                                isInvalid={!!errors.notification} 
                                name="notification"
                                value={formData.notification} 
                                onChange={handleFormChange}
                                >
                                    <option>
                                        
                                    </option>
                                    <option value="Gestionale">Notifica sul gestionale</option>
                                    <option value="Email">Notifica per Email</option>
                                    <option value="Entrambe">Entrambe</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" >
                                    {errors.notification?.message}
                                    </Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="4" activeKey={activeKey}>Altro</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                            <Row className="d-md-flex ">  

                            <Form.Group className="mb-3 mb-sm-0 input-size">
                                    <Form.Label className="input-label">Condividi</Form.Label>
                                    <Form.Control 
                                    {...register("sharing")}
                                    isInvalid={!!errors.sharing}
                                    type="text"  
                                    name="sharing" 
                                    value={formData.sharing} 
                                    onChange={handleFormChange}
                                    />
                                    <Form.Text>
                                    Inserisci le email dei partecipanti separati da virgola
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.sharing?.message}
                                    </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 input-size">
                                <Form.Label className="input-label">Note</Form.Label>
                                <Form.Control
                                    {...register("note")}
                                    isInvalid={!!errors.note} 
                                    as="textarea"
                                    name="note" 
                                    value={formData.note} 
                                    onChange={handleFormChange} 
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.note?.message}
                                    </Form.Control.Feedback>
                            </Form.Group>

                        </Row>

                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                    </fieldset>
                    
                </div>
            </Row>

            </Form>

            <Modal show={show} centered>
                <Modal.Body className="p-5">
                <div className="d-flex flex-column text-center">
                    <h2 className="fs-32 text-dark fw-semibold mb-3">
                    Vuoi annullare i cambiamenti effettuati?
                    </h2>
                    <h6 className="fs-16 text-dark fw-normal mb-3">
                    Le modifiche effettuate non verranno salvate
                    </h6>
                    <button
                    type="button"
                    className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
                    onClick={cancelChanges}
                    >
                    Conferma
                    </button>
                    <button
                    type="button"
                    className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
                    onClick={() => setShow(false)}
                    >
                    Indietro
                    </button>
                </div>
                </Modal.Body>
            </Modal>

        </>
    )
}