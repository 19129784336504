import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"

import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';


import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';



const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    sex: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	})
});

export default function Assemblea(){


    //documento convocazione

    const [modalConvocazione, setModalConvocazione] = useState(false)
    const [modalType, setModalType] = useState(null)

    function showModalConvocazione(){
        setModalConvocazione(true)
    }

    function closeModalConvocazione(){
        setModalConvocazione(false)
    }

    const initialContent = `
    CONVOCAZIONE - L’anno 0000, il giorno gg, del mese di ………………………….. , alle ore 00:00, presso …………………………………………………….. , si è riunita l’Assemblea ordinaria dei soci dell’Associazione ………………………………………………………………….
    Assume la presidenza dell’Assemblea, ai sensi dell’Art. …………………………… del vigente statuto sociale il Sig. Cognome Nome, verbalizza il Sig. Cognome Nome.
    Il Presidente costatato che l’Assemblea è stata regolarmente convocata mediante avviso affisso presso la sede sociale (oppure spedito ai soci) così come previsto dall’art. …………….. dello statuto, contenente il seguente o.d.g.:
    ……………………
    ……………………
    ……………………
    ……………………
    Varie ed eventuali
    l’ora e il luogo della seduta e che trascorsa oltre mezz’ora dall’orario stabilito nell’avviso di convocazione sono presenti n° ….. soci su n° …… iscritti all’Associazione, fa rilevare che il numero dei soci presenti raggiunge / non raggiunge quello richiesto dallo statuto per la validità delle assemblee in prima convocazione.
    Dichiara pertanto che l’assemblea è / non è validamente costituita al fine di deliberare su quanto posto dall’ordine del giorno.
    
    Solo se l’assemblea non è validamente costituita
    Dichiara quindi la riunione rinviata alla seconda convocazione già fissata per il giorno gg/mm/aa, alle ore 00:00, presso la stessa sede.
    
    Letto, confermato e sottoscritto
    
        Il Segretario 								Il Presidente
    
    ……………………………………………………………..…			……………………………………………………………….`;
    const contentState = ContentState.createFromText(initialContent);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));


    const [savedContent, setSavedContent] = useState(null);

    const handleSave = () => {
        const contentState = editorState.getCurrentContent();
        const contentStateJSON = JSON.stringify(convertToRaw(contentState));
        setSavedContent(contentStateJSON);
        // Salva il contenuto come formato JSON...
        closeModalConvocazione()
    };

    useEffect(() => {
        if (savedContent) {
        const contentState = convertFromRaw(JSON.parse(savedContent));
        setEditorState(EditorState.createWithContent(contentState));
        }
    }, [savedContent]);


    //documento verbale ordinario

    const [modalVerbale, setModalVerbale] = useState(false)

    function showModalVerbale(){
        setModalVerbale(true)
    }

    function closeModalVerbale(){
        setModalVerbale(false)
    }

    const initialContentVerbale = `VERBALE ORDINARIO
    L’anno 0000, il giorno gg, del mese di ………………………….. , alle ore 00:00, presso …………………………………………………….. , si è riunita l’Assemblea ordinaria dei soci dell’Associazione ………………………………………………………………….
    Assume la presidenza dell’Assemblea, ai sensi dell’Art. …………………………… del vigente statuto sociale il Sig. Cognome Nome, verbalizza il Sig. Cognome Nome.
    Il Presidente costatato che l’Assemblea è stata regolarmente convocata mediante avviso affisso presso la sede sociale (oppure spedito ai soci) così come previsto dall’art. …………….. dello statuto, contenente il seguente o.d.g.:
    ……………………
    ……………………
    ……………………
    ……………………
    Varie ed eventuali
    l’ora e il luogo della seduta e che trascorsa oltre mezz’ora dall’orario stabilito nell’avviso di convocazione sono presenti n° ….. soci su n° …… iscritti all’Associazione, fa rilevare che il numero dei soci presenti raggiunge / non raggiunge quello richiesto dallo statuto per la validità delle assemblee in prima convocazione.
    Dichiara pertanto che l’assemblea è / non è validamente costituita al fine di deliberare su quanto posto dall’ordine del giorno.
    
    Solo se l’assemblea non è validamente costituita
    Dichiara quindi la riunione rinviata alla seconda convocazione già fissata per il giorno gg/mm/aa, alle ore 00:00, presso la stessa sede.
    
    Letto, confermato e sottoscritto
    
        Il Segretario 								Il Presidente
    
    ……………………………………………………………..…			……………………………………………………………….`;
    const contentStateVerbale = ContentState.createFromText(initialContentVerbale);
    const [editorStateVerbale, setEditorStateVerbale] = useState(() => EditorState.createWithContent(contentStateVerbale));


    const [savedContentVerbale, setSavedContentVerbale] = useState(null);

    const handleSaveVerbale = () => {
        const contentStateVerbale = editorStateVerbale.getCurrentContent();
        const contentStateVerbaleJSON = JSON.stringify(convertToRaw(contentStateVerbale));
        setSavedContentVerbale(contentStateVerbaleJSON);
        // Salva il contenuto come formato JSON...
        closeModalVerbale()
    };

    useEffect(() => {
        if (savedContentVerbale) {
        const contentStateVerbale = convertFromRaw(JSON.parse(savedContentVerbale));
        setEditorStateVerbale(EditorState.createWithContent(contentStateVerbale));
        }
    }, [savedContentVerbale]);


    //documento verbale nullo

    const [modalVerbaleNullo, setModalVerbaleNullo] = useState(false)

    function showModalVerbaleNullo(){
        setModalVerbaleNullo(true)
    }

    function closeModalVerbaleNullo(){
        setModalVerbaleNullo(false)
    }

    const initialContentVerbaleNullo = `
    VERBALE NULLO
    L’anno 0000, il giorno gg, del mese di ………………………….. , alle ore 00:00, presso …………………………………………………….. , si è riunita l’Assemblea ordinaria dei soci dell’Associazione ………………………………………………………………….
Assume la presidenza dell’Assemblea, ai sensi dell’Art. …………………………… del vigente statuto sociale il Sig. Cognome Nome, verbalizza il Sig. Cognome Nome.
Il Presidente costatato che l’Assemblea è stata regolarmente convocata mediante avviso affisso presso la sede sociale (oppure spedito ai soci) così come previsto dall’art. …………….. dello statuto, contenente il seguente o.d.g.:
……………………
……………………
……………………
……………………
Varie ed eventuali
l’ora e il luogo della seduta e che trascorsa oltre mezz’ora dall’orario stabilito nell’avviso di convocazione sono presenti n° ….. soci su n° …… iscritti all’Associazione, fa rilevare che il numero dei soci presenti raggiunge / non raggiunge quello richiesto dallo statuto per la validità delle assemblee in prima convocazione.
Dichiara pertanto che l’assemblea è / non è validamente costituita al fine di deliberare su quanto posto dall’ordine del giorno.

Solo se l’assemblea non è validamente costituita
Dichiara quindi la riunione rinviata alla seconda convocazione già fissata per il giorno gg/mm/aa, alle ore 00:00, presso la stessa sede.

Letto, confermato e sottoscritto

	Il Segretario 								Il Presidente

……………………………………………………………..…			……………………………………………………………….`;

    const contentStateVerbaleNullo = ContentState.createFromText(initialContentVerbaleNullo);
    const [editorStateVerbaleNullo, setEditorStateVerbaleNullo] = useState(() => EditorState.createWithContent(contentStateVerbaleNullo));


    const [savedContentVerbaleNullo, setSavedContentVerbaleNullo] = useState(null);

    const handleSaveVerbaleNullo = () => {
        const contentStateVerbaleNullo = editorStateVerbaleNullo.getCurrentContent();
        const contentStateVerbaleNulloJSON = JSON.stringify(convertToRaw(contentStateVerbaleNullo));
        setSavedContentVerbaleNullo(contentStateVerbaleNulloJSON);
        // Salva il contenuto come formato JSON...
        closeModalVerbaleNullo()
    };

    useEffect(() => {
        if (savedContentVerbaleNullo) {
        const contentStateVerbaleNullo = convertFromRaw(JSON.parse(savedContentVerbaleNullo));
        setEditorStateVerbaleNullo(EditorState.createWithContent(contentStateVerbaleNullo));
        }
    }, [savedContentVerbaleNullo]);




    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Assemblea</p>
                    <p className="fs-14 opacity-75">Gestione della documentazione usata in assemblea</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Documento Convocazione</p>
                            <p className="fs-14 text-dark opacity-75">Questo è il documento di default che invieremo agli utenti per convocarli all’assemblea. Sa apporti delle modifiche a questo documento, le ritroverai nella sezione assemblea - convocazione</p>
                        </Col>

                    <Col md={6} className="ps-0">
                        <div className="intCard">
                            <div className="d-flex align-items-center">
                                <p className="ms-3 f-16 ">Documento convocazione</p>
                            </div>
                            <div className="row">
                                <button
                                        type="button"
                                        className="btn round-icon-btn"
                                        onClick={showModalConvocazione}
                                        >
                                        <FiEdit2 />
                                </button>
                            </div>
                        </div>
                    </Col>

                    <hr className="opacity-10 my-3"/>

                </Row>

                <Row className="mt-3">
                    <Col md={6} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Verbale</p>
                            <p className="fs-14 text-dark opacity-75">Questo è il documento di default che useremo come verbale. Sa apporti delle modifiche a questo documento, le ritroverai nella sezione assemblea - verbale</p>
                        </Col>

                    <Col md={6} className="ps-0">
                        <div className="intCard">
                            <div className="d-flex align-items-center">
                                <p className="ms-3 f-16 ">Verbale assemblea ordinaria</p>
                            </div>
                            <div className="row">
                                <button
                                        type="button"
                                        className="btn round-icon-btn"
                                        onClick={showModalVerbale}
                                        >
                                        <FiEdit2 />
                                </button>
                            </div>
                        </div>
                        <div className="intCard">
                            <div className="d-flex align-items-center">
                                <p className="ms-3 f-16 ">Verbale assemblea nulla</p>
                            </div>
                            <div className="row">
                                <button
                                        type="button"
                                        className="btn round-icon-btn"
                                        onClick={showModalVerbaleNullo}
                                        >
                                        <FiEdit2 />
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>                

            </div >

            {/* MODAL CONVOCAZIONE */}
            <Modal
                show={modalConvocazione}
                onHide={closeModalConvocazione}
                centered
                className="editor-modal"
            >
                <Modal.Header closeButton>
                    Modifica Documento di Convocazione
                </Modal.Header>
                <Modal.Body className="editor-modal-body ">
                    <div
                    className="px-2 pb-4 pt-0 text-center border"
                    >
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbarHidden={true}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        
                    />
                    
                    </div>

                </Modal.Body>
                <Modal.Footer >
                    <ButtonRm variant="primary" onClick={handleSave}>Salva</ButtonRm>
                </Modal.Footer>
            </Modal>
            
            {/* MODAL VERBALE */}
            <Modal
                show={modalVerbale}
                onHide={closeModalVerbale}
                centered
                className="editor-modal"
            >
                <Modal.Header closeButton>
                    Modifica Documento Verbale ordinario
                </Modal.Header>
                <Modal.Body className="editor-modal-body ">
                    <div
                    className="px-2 pb-4 pt-0 text-center border"
                    >
                    <Editor
                        editorState={editorStateVerbale}
                        onEditorStateChange={setEditorStateVerbale}
                        toolbarHidden={true}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        className="ditor-modal-doc"
                        
                    />
                    
                    </div>

                </Modal.Body>
                <Modal.Footer >
                    <ButtonRm variant="primary" onClick={handleSaveVerbale}> Salva</ButtonRm>
                </Modal.Footer>
            </Modal>

            {/* MODAL VERBALE NULLO */}
            <Modal
                    show={modalVerbaleNullo}
                    onHide={closeModalVerbaleNullo}
                    centered
                    className="editor-modal"
                >
                    <Modal.Header closeButton>
                        Modifica Documento Verbale Nullo
                    </Modal.Header>
                    <Modal.Body className="editor-modal-body ">
                        <div
                        className="px-2 pb-4 pt-0 text-center border editor-modal-doc"
                        >
                        <Editor
                            editorState={editorStateVerbaleNullo}
                            onEditorStateChange={setEditorStateVerbaleNullo}
                            toolbarHidden={true}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            
                        />
                        
                        </div>

                    </Modal.Body>
                    <Modal.Footer >
                        <ButtonRm variant="primary" onClick={handleSaveVerbaleNullo}> Salva</ButtonRm>
                    </Modal.Footer>
            </Modal>


          
        </>

        
    )
}