import { useState } from "react";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import UploadImg from "../../../assets/images/upload_file.svg";
import ImgFileCard from "../UnitàImmobiliari/ImgFileCard";

const Attachment = (props) => {
	const setPhotoFileName = (index, new_name) => {
		let temp = [...props.formData.photos];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "photos", value: temp } };
		props.onChange(ele);
	};

	const deletePhotoFile = (index) => {
		let temp = [...props.formData.photos];
		temp.splice(index, 1);
		let ele = { target: { name: "photos", value: temp } };
		props.onChange(ele);
	};

	const setPlanimetryFileName = (index, new_name) => {
		let temp = [...props.formData.planimetries];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "planimetries", value: temp } };
		props.onChange(ele);
	};

	const deletePlanimetryFile = (index) => {
		let temp = [...props.formData.planimetries];
		temp.splice(index, 1);
		let ele = { target: { name: "planimetries", value: temp } };
		props.onChange(ele);
	};
	const handlePhoto = (e) => {
		let temp = [...props.formData.photos];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		let ele = {
			target: { name: e.target.name, value: temp },
		};
		props.onChange(ele);
	};

	const handlePlanimetry = (e) => {
		let temp = [...props.formData.planimetries];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		let ele = {
			target: { name: e.target.name, value: temp },
		};
		props.onChange(ele);
	};
	///// extension check ////

	const [isValid, setIsValid] = useState(true);
	const isValidFileType = (file) => {
		const validExtensions = ["png", "jpeg", "pdf", "jfif", "heif"];
		const fileExtension = file.type.split("/")[1];
		setIsValid(validExtensions.includes(fileExtension));
	};

	const [dragActivePhoto, setDragActivePhoto] = useState(false);
	const [dragActivePlanimetry, setDragActivePlanimetry] = useState(false);

	// handle drag events
	const handleDragPhoto = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActivePhoto(true);
		} else if (e.type === "dragleave") {
			setDragActivePhoto(false);
		}
	};

	const handleDragPlanimetry = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActivePlanimetry(true);
		} else if (e.type === "dragleave") {
			setDragActivePlanimetry(false);
		}
	};

	// triggers when file is dropped
	const handleDropPhoto = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActivePhoto(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let temp = [...props.formData.photos];
			let ele = {
				target: { name: "photos", value: [...temp, ...e.dataTransfer.files] },
			};
			props.onChange(ele);
		}
	};

	const handleDropPlanimetry = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActivePlanimetry(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let temp = [...props.formData.planimetries];
			let ele = {
				target: {
					name: "planimetries",
					value: [...temp, ...e.dataTransfer.files],
				},
			};
			props.onChange(ele);
		}
	};

	const setFileName = (index, new_name) => {
		let temp = [...props.formData.documents];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "documents", value: temp } };
		props.onChange(ele);
	};
	const deleteFile = (index) => {
		let temp = [...props.formData.documents];
		temp.splice(index, 1);
		let ele = { target: { name: "documents", value: temp } };
		props.onChange(ele);
	};
	const handleChange = (e) => {
		let temp = [...props.formData.documents];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		let ele = {
			target: { name: e.target.name, value: temp },
		};
		props.onChange(ele);
	};

	return (
		<div className="form-item-align-box-white d-block">
			<label className="fs-20 text-dark mb-2 fw-semibold">
				FOTO E PLANIMETRIE
			</label>
			<div className="d-flex justify-content-start">
				<label
					htmlFor="photo"
					className="general-btn white-btn w-193 me-2 text-center">
					Aggiungi foto
				</label>
				<input
					type="file"
					id="photo"
					className="d-none"
					multiple={true}
					name="photos"
					onChange={handlePhoto}></input>
				<label
					htmlFor="planimetry"
					className="general-btn white-btn w-210 text-center">
					Aggiungi planimetria
				</label>
				<input
					type="file"
					id="planimetry"
					className="d-none"
					multiple={true}
					name="planimetries"
					onChange={handlePlanimetry}></input>
			</div>
			<div className=" mb-3 d-block w-100 mt-4">
				
					<h1 className="fs-16 text-dark text-uppercase ">PLANIMETRIE</h1>
			</div>
			<div className="d-flex flex-wrap">
				{props.formData.planimetries.length > 0 &&
					props.formData.planimetries.map((item, index) => {
						return (
							<div key={index}>
								<ImgFileCard id={index} img={item} />
								<div className="image-div">
									<FileCard
										id={index}
										name={item.name}
										setFileName={setPlanimetryFileName}
										deleteFile={deletePlanimetryFile}
									/>
								</div>
							</div>
						);
					})}
				<label htmlFor="planimetry" style={{ margin: "10px" }}>
					<div
						className={`file-upload-div ${
							dragActivePlanimetry ? "drag-active" : ""
						}`}
						onDragEnter={handleDragPlanimetry}
						onDragLeave={handleDragPlanimetry}
						onDragOver={handleDragPlanimetry}
						onDrop={handleDropPlanimetry}>
						<img loading="lazy" src={UploadImg} alt="" />
					</div>
				</label>
			</div>
			<hr className="opacity-25 mt-4"/>
			<div className=" mb-3 d-block w-100 mt-4">
					<h1 className="fs-16 text-dark text-uppercase ">FOTO</h1>
			</div>
			<div className="d-flex flex-wrap">
				{props.formData.photos.length > 0
					? props.formData.photos.map((item, index) => {
							return (
								<div key={index}>
									<ImgFileCard id={index} img={item} />
									<div className="image-div">
										<FileCard
											id={index}
											name={item.name}
											setFileName={setPhotoFileName}
											deleteFile={deletePhotoFile}
										/>
									</div>
								</div>
							);
					  })
					: ""}
				<label htmlFor="photo" style={{ margin: "10px" }}>
					<div
						className={`file-upload-div ${
							dragActivePhoto ? "drag-active" : ""
						}`}
						onDragEnter={handleDragPhoto}
						onDragLeave={handleDragPhoto}
						onDragOver={handleDragPhoto}
						onDrop={handleDropPhoto}>
						<img loading="lazy" src={UploadImg} alt="No image" />
					</div>
				</label>
			</div>
			<hr className="opacity-25 mt-4"/>
			<div className="mb-3 d-block w-100 mt-4">
					<h1 className="fs-16 text-dark text-uppercase ">DOCUMENTI</h1>
			</div>
			<label htmlFor="input-file" className="general-btn white-btn text-center">
				Seleziona
			</label>
			<input
				type="file"
				id="input-file"
				className="d-none"
				multiple={true}
				name="documents"
				onChange={handleChange}></input>
			<div className="mt-3">
				{props.formData.documents.length > 0 ? (
					<>
						{props.formData.documents.map((item, index) => {
							return (
								<FileCard
									key={index}
									name={item.name}
									setFileName={setFileName}
									deleteFile={deleteFile}
									id={index}
								/>
							);
						})}
					</>
				) : (
					<div className="unit-empty-img">
						<span className="img-comment">Nessun documento</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default Attachment;
