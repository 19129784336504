import React, { useState, useEffect } from "react";
import TopHeading from "../components/TopHeading";
import EmptyStabili from "../assets/images/empty_establish.png";
import { BsPlusCircleFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import "../assets/css/Stabili.css";
import api, { stabili_endpoint, supplier_endpoint, unit_endpoint, management_endpoint } from "../api/api";
import StabiliFirstPage from "../components/UnitàImmobiliariStabilli/Stabili/StabiliFirstPage";

import { sortStringByKey, sortDatesByKey } from "../utils/filtering";


import {Row, Col} from "react-bootstrap"
import AddBtn from "../components/AddBtn";

import ModalCheckUser from "../components/ModalCheckUser";
import { checkUserData } from "../utils/checkUserRole";

const Stabili = () => {
  const [stabili, setStabili] = useState([]);

  async function getStabili() {
    try {
      const response = await api().get(`${stabili_endpoint}`);
      if (response.data) {
        setStabili(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getStabili();
  }, []);

  const deleteStabili = (id) => {
    let temp = [...stabili];
    temp = temp.filter((item) => item.id !== id);
    setStabili(temp);
  };

  //NEW SEARCH & FILTER

  const [units, setUnits] = useState([]);
  const [gestioni, setGestioni] = useState([]);
  const [suppliers, setSuppliers] = useState([]);



  async function getUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getGestioni() {
    try {
      const response = await api().get(`${management_endpoint}`);
      if (response.data) {
        setGestioni(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getSuppliers() {
    try {
      const response = await api().get(`${supplier_endpoint}`);
      if (response.data) {
        setSuppliers(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getUnits()
    getGestioni()
    getSuppliers()
  }, []);

  const admin = suppliers.length > 0? suppliers.filter(el => el.category == "Amministratore") : []

	//SEARCH

	const [searchTerm, setSearchTerm] = useState("")

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  


    const categories = [
      
      {
        name: 'Amministratori',
        items: admin.map((owner) => `${owner.name} ${owner.surname}`),

      },
      {
        name: 'Gestione',
        items: gestioni.map(el => String(el.id)),
      },
      {
        name: 'Unità',
        items: units.map(el => el.name)
      },

    ];
    
    


		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])

    console.log(selectedCategories)


    useEffect(() => {
      if (stabili.length > 0) {
        setFilteredUnits(
          stabili.filter((unit) => {

            const unitDate = new Date(unit.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && unitDate < new Date(startDate)) {
              return false;
            }
    
            // Filtro per la data di fine (endDate)
            if (endDate && unitDate > new Date(endDate)) {
              return false;
            }
    
            return (
              (selectedCategories.length === 0 ||
                selectedCategories.some((cat) => {
                  if (cat.name === 'Amministratori') {
                      return cat.items.includes(unit.administrator);
                  } else if (cat.name === "Gestione") {
                    return gestioni.some((gestione) =>
                      gestione.realEstateManagements?.some(
                        (management) =>
                          management.unitID == unit.id && cat.items.includes(String(gestione.id))
                      )
                    );
                  } else if (cat.name === 'Unità') {
                    return unit.units.some((el) => {
                      console.log(cat.items, el.name)
                    return cat.items.includes(el.name);
                    });
                  }  
                })) &&
              unit.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
          })
        );
      }
    }, [searchTerm, selectedCategories, stabili, startDate, endDate]);
    

		const [sortedUnits, setSortedUnits] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedUnits(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedUnits(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(units);
    setStartDate("")
    setEndDate("")

		}

      //CHECK IF DATA REQUIRED ARE COMPILED
      const [dataRequired, setDataRequired] = useState(true);
      //Gestisce il modale
      const [showCheck, setShowCheck] = useState(false)
  
      useEffect(() => {
          const fetchData = async () => {
              try {
                  const response = await checkUserData();
                  setDataRequired(response.isVerified);
                  if (!response.isVerified) {
                    setShowCheck(true);
                }
              } catch (error) {
                  // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                  console.error('Errore durante il recupero dei dati utente:', error);
              }
          };
  
          fetchData();
      }, []);


  return (
    <>
      <div className="title-search-group mobile-container-xl">
        <div className="right-header">
          <TopHeading
            SmallHeading="empty"
          />
        </div>
        {/* <div className="model-modul-group">
          <div className="d-flex align-items-center mt-2 justify-content-between btn-search-group">
            <div className="search-input">
              <div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
                <i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
                <input
                  type="text"
                  name=""
                  placeholder="Cerca"
                  className="flex-shink-0 border-0 w-100 fs-16"
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                />
              </div>
            </div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 600, hide: 300 }}
              overlay={
                <Tooltip className="tooltip-container">Nuovo stabili</Tooltip>
              }
            >
              <Link to="/stabili/create">
                <div className="plus-button">
                  <BsPlusCircleFill className="fs-40 plus-btn" />
                </div>
              </Link>
            </OverlayTrigger>
          </div>
        </div> */}
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Stabili</h2>
          </Col>
          <Col className="d-flex justify-content-end ">
            <Link to="/stabili/create">
              <AddBtn
                tooltip="Nuovo stabile"
                className="me-3 me-md-0 order-md-1"
                dataRequired={dataRequired}
              />
            </Link>
            {/* <SearchInput
              className="order-md-0"
              setSearch={(term) => {
                setSearchTerm(term);
              }}
            /> */}
          </Col>
        </Row>
      </div>
      {stabili.length > 0 ? (
        <StabiliFirstPage data={stabili} deleteStabili={deleteStabili}
        sortedUnits={sortedUnits}
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        clearFiltersAndSort={clearFiltersAndSort}
        setSortBy={setSortBy}
        categories={categories}
        startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}
        setItems={setStabili} />
      ) : (
        <Row className="dashboard-container ">

        <div className="mx-auto text-center empty-container">
          <div className="mx-auto d-table w-160">
            <img loading="lazy" src={EmptyStabili} alt="empty" />
          </div>
          <p className="fs-20 text-secondary text-center">
            Non hai aggiunto ancora nessuna stabile
          </p>
        </div>
        </Row>
      )}

  <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione dello stabile, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>
    </>
  );
};

export default Stabili;
