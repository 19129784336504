import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoCalcoli () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Calcoli"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
          <TreeItem nodeId="1" label="Imposte di Registro" />
          <TreeItem nodeId="2" label="Imposte compravendita" />
          <TreeItem nodeId="3" label="Ravvedimento imposta di registro" />
          <TreeItem nodeId="4" label="Ravvedimento imposta di bollo" />
{/*           <TreeItem nodeId="5" label="Reddito di fabbricato" />
 */}          <TreeItem nodeId="6" label="Valore sulla nuda proprietà" />
          <TreeItem nodeId="7" label="Acconto IMU" />
          <TreeItem nodeId="8" label="Interessi" />

          <TreeItem nodeId="9" label="Aggiornamento ISTAT" />
            <TreeItem nodeId="10" label="Compilazione" />
            <TreeItem nodeId="11" label="Output" />

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Calcoli (da approfondire)</h1>
      <h2 style={{ color:'transparent', height: 0}}>Calcoli</h2>

      <p>In questa sezione, troverai delle profilazioni guidate che ti permetteranno di effettuare calcoli relative a:</p>
      
      <p className="subtitle" id="1">Imposte di Registro</p>
        <img loading="lazy" src="https://i.postimg.cc/4d877mjN/imposte-registro.gif" />
        <Tips variant="info" content="I campi contrassegnati dal * sono obbligatori per poter ottenere l'output richiesto." />

      <p className="subtitle" id="2">Imposte di Compravendita</p>
        <img loading="lazy" src="https://i.postimg.cc/tC86NnJs/compravendita.png" />

      <p className="subtitle" id="3">Ravvedimento imposta di registro</p>
        <img loading="lazy" src="https://i.postimg.cc/3R9pXL0h/ravvedimento-registro.png" />

      <p className="subtitle" id="4">Ravvedimento imposta di bollo</p>
        <img loading="lazy" src="https://i.postimg.cc/vTBn3W5V/ravvedimento-bollo.png" />

    {/*   <p className="subtitle" id="5">Reddito di fabbricato</p>
        <img loading="lazy" src="https://i.postimg.cc/wBXDvx67/reddito-fabbricato.png" /> */}

      <p className="subtitle" id="6">Valore sulla nuda proprietà</p>
        <img loading="lazy" src="https://i.postimg.cc/HWZXjhC4/nuda-proprieta.png" />

      <p className="subtitle" id="7">Acconto IMU</p>
        <img loading="lazy" src="https://i.postimg.cc/vH49ntYR/imu.png" />

      <p className="subtitle" id="8">Interessi</p>
        <img loading="lazy" src="https://i.postimg.cc/Y9tYmc98/interessi.png" />

      <p className="subtitle" id="9">Aggiornamento Istat</p>
        <img loading="lazy" src="https://i.postimg.cc/90n7SrBN/aggiornamento-istat.png" />

      

      <h2 id="10">Compilazione</h2>
      <p>La compilazione dei campi, è abbastanza intuitiva.</p>
      <p>Dovrai inserire tutte le informazioni richieste e cliccare sul pulsante Calcola</p>


      <h2 id="11">Output</h2>
      <p>L'output di ogni calcolo è esportabile in pdf o CSV</p>
      <p>
      L'importazione in formato CSV (Comma-Separated Values) è un modo semplice ed efficace per esportare e condividere dati in un formato tabellare compatibile con molti software. Può essere aperto e visualizzato con facilità utilizzando applicazioni come Excel, Google Fogli, Numbers, Blocco note etc.
      </p>

      <img loading="lazy" src="https://i.postimg.cc/rsZyQn42/export.png" />

    </div>
  );
}

