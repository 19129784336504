import { GET_OWNER, EDIT_OWNER, DELETE_OWNER, ADD_OWNER } from "./types";
import api from '../api/api'
import { owner_endpoint} from "../api/api";

/*
STEP PER SALVARE DATI IN REDUX:
1. CREA ACTION TYPE IN ./types
2. CREA ACTION CREATORS (fetch..., add..., edit..., etc)
3. CREA REDUCER E IMPORTALO IN reducers/index.js
4. ESPORTA TUTTO
5. IMPORTA DOVE SERVE USEDISPATCH E USESELECTOR E ACTION CREATORS
*/


/* ACTION CREATORS */
export const fetchOwner =  ()  =>  async (dispatch)=> {
    const response = await api().get(`${owner_endpoint}`)
    dispatch({
        type: GET_OWNER,
        payload: response.data?.content
    })
}

export const addOwner =  (data)  =>  async (dispatch)=> {
    const response = await api().post(`${owner_endpoint}`, data)
    dispatch({
        type: ADD_OWNER,
        payload: data
    })
}

export const editOwner =  (id, data)  =>  async (dispatch)=> {
    const response = await api().put(`${owner_endpoint}${id}`, data)
    dispatch({
        type: EDIT_OWNER,
        payload: {
                    data, 
                    id
                }
    })
}

export const removeOwner =  (id)  =>  async (dispatch)=> {
    const response = await api().delete(`${owner_endpoint}${id}`)
    dispatch({
        type: DELETE_OWNER,
        id: id
    })
}

/* REDUCER */

const initialState = {
    owner: [],
}

const ownerReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_OWNER:
            return {...state, 
                    owner: action.payload
                }
        case ADD_OWNER:
            return {
                ...state,
                owner: [...state.owner, action.payload]
            }
        case EDIT_OWNER:
            const newData = action.payload.data
            const updatedDeadline = state.owner && state.owner.length > 0?
            state.owner.map((el) => {
                if (el.id === action.payload.id) {
                return {...el, newData}
                } else {
                return el;
                }
            }) : []
            return  {
                ...state,
                owner: updatedDeadline
            }

        case DELETE_OWNER:
            const owners = state.owner.filter( el => el.id !== action.id)
            return  {
                ...state,
                owner: owners
            }
        default:
            return state
    }
}

export default ownerReducer