import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import api, { supplier_endpoint } from "../../../api/api";
import cardimage from "../../../assets/images/fornitore-ph.svg";
import SubjectCard from "../../Documenti/prima_nota/Tipologia/SubjectCard";
import SupplierModal from "../../Documenti/prima_nota/Tipologia/SupplierModal";
import { Link } from "react-router-dom";

const Provider = ({ setFormData, formData }) => {
	const [show, setShow] = useState(false);

	const [supplierList, setSupplierList] = useState([]);

	const addSelectedSuppliers = (id) => {
		let filteredList = formData.suppliers.filter(
			(supplier) => supplier.id === id
		);
		filteredList.length === 0 &&
			formData.suppliers.push(
				supplierList.filter((supplier) => supplier.id === id)[0]
			);
		setFormData({
			...formData,
			suppliers: formData.suppliers,
		});
	};

	const removeSelectedSupplier = (id) => {
		setFormData({
			formData,
			suppliers: formData.suppliers.filter((supplier) => supplier.id !== id),
		});
	};

	const fetchSuppliers = async () => {
		try {
			const res = await api().get(`${supplier_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setSupplierList(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchSuppliers();
	}, []);

	return (
		<div className="form-item-align-box-white d-block">
			<div className="d-flex justify-content-start">
				<Link to="/fornitori/create">
					<button type="button" className="general-btn white-btn w-193 me-2">
						Nuovo
					</button>
				</Link>
				<button
					type="button"
					className="general-btn white-btn w-193"
					onClick={(e) => {
						setShow(true);
					}}>
					Seleziona
				</button>
			</div>
			{console.log(formData)}

			{formData.suppliers.length > 0 ? (
				formData.suppliers.map((supplier) => (
					<SubjectCard
						key={supplier.id}
						title={
							supplier.name +
							" " +
							supplier.surname +
							": " +
							supplier.businessName
						}
						id={supplier.id}
						table_data={[
							supplier.category,
							supplier.subjectType,
							supplier.cellularTelephone,
						]}
						className="mt-4"
						remove={removeSelectedSupplier}
					/>
				))
			) : (
				<div className="col-12 mt-5">
					<div className="mx-auto d-table text-center">
						<div className="image-user mx-auto d-table w-160 fw-100">
							<img loading="lazy" src={cardimage} alt="user-key" />
						</div>
						<p className="fs-20 text-secondary text-center">
							Nessun fornitore associato
						</p>
					</div>
				</div>
			)}
			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<SupplierModal
						table_data={
							supplierList.length > 0 &&
							supplierList.map((supplier) => [
								supplier.category,
								supplier.businessName,
								supplier.cellularTelephone,
								supplier.subjectType,
							])
						}
						ids={supplierList.map((supplier) => supplier.id)}
						setShow={setShow}
						addSupplier={addSelectedSuppliers}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Provider;
