import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { token } from "../../api/api.js";
import "../../assets/css/Membri.css";
import { BsDownload, BsTrash, BsArchive } from "react-icons/bs";
import { Modal } from "react-bootstrap";

import api, { supplier_endpoint, conductor_endpoint, owner_endpoint } from "../../api/api";
import ExportButton from "./Fornitori/ExportButton.js";
import translationMapping from "./mapping.js";
import {useNavigate } from "react-router-dom";
import ShareChat from "../ShareChat.js";

import { checkUserRole } from "../../utils/checkUserRole.js";
import ButtonRm from "../ButtonRm.js";


/* QUESTO COMPONENTE GESTISCE LE CARD NELLE LISTE DI TUTTI I MEMBRI */

export default function ColomboList({ target, searchString, fetchData, onItemDeleted, handleSelection, selectedItems, handleSelectAll, handleDeselectAll }) {
  const navigate = useNavigate()

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(fetchData)


  useEffect(() => {
    setItems(fetchData)
  }, [fetchData])

  const [cardSelectedId, setCardSelectedId] = useState("")

  /* CONTROLLARE IN CHE SEZIONE SIAMO: SE OWNER, CONDUCTOR, SUPPLIER*/
	const findTheSection = window.location.pathname
  let path

  if(findTheSection == "/proprietari"){
    path = owner_endpoint

  } else if (findTheSection == "/conduttori"){
    path = conductor_endpoint

  } else if (findTheSection == "/fornitori"){
    path = supplier_endpoint
  }


/*   useEffect(() => {
    switch (target) {
      case "Proprietari":
        setLoading(true);
        axios
          .get(process.env.REACT_APP_REQUEST_URL + "api/v1/owner/", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setLoading(false);
            setItems(res.data.content);
          });
        break;
      case "Conduttori":
        setLoading(true);
        axios
          .get(process.env.REACT_APP_REQUEST_URL + "api/v1/conductor/", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setLoading(false);
            setItems(res.data.content);
          });
        break;
      case "Fornitore":
        setLoading(true);
        axios
          .get(process.env.REACT_APP_REQUEST_URL + "api/v1/supplier/", {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setLoading(false);
            setItems(res.data.content);
          });
        break;
    }
  }, []); */


  //handle modal delete
	const [show, setShow] = useState(false);

 
  

  //close modal
	const handleClose = (e) => {
		setShow(false)
		e.preventDefault();
		e.stopPropagation();

	};

  //show modal and save the id of the element selected
  function handleModal(id){
    setShow(true);
    setCardSelectedId(id)
  }

  {/* API CALL */}

  //HANDLE GET
 /*   async function getItems() {
    try {
      const response = await api().get(`${path}`);
      setItems(response.data.content);
    } catch (err) {
      console.log(err);
    }
  }  */
  
  //HANDLE DELETE
  function handleDelete(e){
    e.preventDefault();
    e.stopPropagation();
    deleteCard(cardSelectedId)
    handleClose(e)
  }

  const deleteCard = async (id) => {
    try {
      await api().delete(`${path}${id}`);
      onItemDeleted(); // Chiamata alla funzione di callback
    } catch (err) {
      console.log(err);
    }
  };

  /* useEffect(() => {
    if(path){
      getItems()
    }
  }, []) */

  //GESTIONE CHECKBOX

	
	

  const handleCardClick = (e, id) => {
		// Verifica se l'elemento cliccato è il checkbox
		if (e.target.type === "checkbox") {
		  return; // Se sì, non fare nulla
		}

    if(target === "Proprietari"){
		  navigate(`/proprietari-view/view/${id}`)
    } else if(target === "Conduttori"){
      navigate(`/conduttori-view/view/${id}`)
    } else {
      navigate(`/fornitori-view/view/${id}`)

    }
	}	


  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (e, id) => {
    e.stopPropagation(); // Impedisce la propagazione del clic alla card
    const isChecked = e.target.checked;
    setCheckedItems(prevState => ({
      ...prevState,
      [id]: isChecked,
    }));
    handleSelection(id, isChecked); // Passa l'id e lo stato attuale del checkbox al componente padre
  };

  return (
    <>
    
      {items.map((item, index) => {

  

          return (
            <div className="colombolist" key={item.id} >
             
              <div
                className="colo-list d-block w-100 px-4 bg-white py-3 mt-2 ms-0"
                onClick={(e) => handleCardClick(e, item.id)}
              >
                
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
						            onChange={(e) => handleSelection(e, item.id)}
                        className="me-2"
                      />
                      </div>

                      <div>
                        <p className="fs-10 text-uppercase text-dark opacity-50">{item.businessName} {(item.businessName && item.guarantor) && ` - `} {item.guarantor && `GARANTE`}</p>
                        <h2 className="fs-18 text-dark fw-bold text-capitalize mb-1">
                          {item.surname + " " + item.name}
                        </h2>

                        <div className="d-flex flex-column flex-md-row">
                          {item.cellularTelephone.length > 0 && <p className="fs-12 text-dark mb-2 mb-md-0 me-0 me-md-4">
                            <i className="fal fa-phone-alt me-2"></i> 
                            {item.cellularTelephone[0]}
                          </p>}
                          {item.email.length > 0 && <p className="fs-12 text-dark mb-2 mb-md-0 me-0 me-md-4">
                            <i className="fal fa-envelope me-2"></i>
                            {item.email[0]}
                          </p>
                          }
                          {item.homeTelephone && <p className="fs-12 text-dark mb-2 mb-md-0 me-0 me-md-4">
                            <i
                              className="fal fa-home"
                              style={{ paddingRight: "10px" }}
                            ></i>
                            {item.homeTelephone}
                          </p>}
                        </div>
                      </div>
                    </div>
                  <div className="d-flex">
{/*                     <ShareChat elId={item.id} />
 */}                    <button
                      type="button"
                      className="btn round-icon-btn me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleModal(item.id);
                      }}>
                      <BsTrash />
                    </button>

                   {/*  <button
                      type="button"
                      className="btn round-icon-btn me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                      <BsArchive />
                    </button> */}

                    <ExportButton data={item} mapping={translationMapping} classes="btn round-icon-btn btn me-2"/>
                  </div>
                </div>
              </div>
            </div>
          );
      })}

      <Modal show={show} onHide={(e) => handleClose(e)} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) => handleDelete(e)}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={(e) => handleClose(e)}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
