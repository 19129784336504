import { useState, useRef, useMemo, useCallback, useEffect } from "react";

import { activity_endpoint } from "../../../api/api";

import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Accordion from 'react-bootstrap/Accordion'

import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {  BsArchive, BsTrash } from "react-icons/bs";


import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import SearchInput from "../../SearchInput";
import EmptyStateContainer from "../../EmptyStateContainer";

import EmptyState from "../../../assets/images/attività-empty-state.svg";
import EmptyStatePl from "../../../assets/images/plan-pc.svg";
import SearchIllustration from "../../../assets/images/search-illustration.svg";
import { sortStringByKey, sortDatesByKey } from "../../../utils/filtering";


import { useParams } from "react-router-dom";
import api, { real_estate_management_endpoint, unit_endpoint, management_endpoint} from "../../../api/api";

import Breadcrumb from 'react-bootstrap/Breadcrumb';



import {
    AttivitàCard,
    AttivitàCardMap,
    AttivitàCardMapMobile,
} from "../Attività/AttivitàCard";
import attività from "./data";

import L from "leaflet";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    ImageOverlay,
    Rectangle,
} from "react-leaflet";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate} from "react-router-dom";

import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import ButtonRm from "../../ButtonRm";

import Modal from "react-bootstrap/Modal";
import { PlaneOff } from "tabler-icons-react";

import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard"
import ListViewActivity from "./ListViewActivity";
import GanttViewActivty from "./GanttViewActivity";
import MapViewActivity from "./MapViewActivity";

/* 
  {
    [12]: [{name: "Planimetria A",
    level: "Primo Piano",
    file: "https://i.pinimg.com/originals/20/22/ea/2022eaea17f3cad557da665510da6007.jpg"}]
    
  } 
*/

const examples = [
  {
    name: "Planimetria A",
    level: "Primo Piano",
    file: "https://i.pinimg.com/originals/20/22/ea/2022eaea17f3cad557da665510da6007.jpg"
  },

  {
    name: "Planimetria Ascensore",
    level: "",
    file: "https://i.pinimg.com/originals/20/22/ea/2022eaea17f3cad557da665510da6007.jpg"
  },

  {
    name: "Planimetria B",
    level: "Primo Piano",
    file: "https://i.pinimg.com/originals/20/22/ea/2022eaea17f3cad557da665510da6007.jpg"
  },

  {
    name: "Planimetria Cucina",
    level: "Secondo Piano",
    file: "https://i.pinimg.com/originals/20/22/ea/2022eaea17f3cad557da665510da6007.jpg"
  },
]

const loadImage = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions([img.height, img.width]);
  };
  img.onerror = (err) => {
    console.log("img error");
    console.error(err);
  };
};

export default function GestioneUnitActivity() {

   //GET UNIT ID RELATED TO THSI ACTIVITY
   const [unit, setUnit]= useState({})
   const {unitId} = useParams()

  let objLevels = {}

  //CREATE OBJECT WITH LEVELS ASSOCIATE WITH PLANIMETRY
  for(let i = examples.length - 1; i > 0; i-- ){
    let levelName = examples[i]["level"]
    if(levelName){objLevels[levelName] = examples.filter(item => item.level == levelName)} else {
      objLevels["noLevel"] = examples.filter(item => item.level == "")
    }
  }

  const [attività, setAttività] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewType, setViewType] = useState("list");

  const [selectItem, setSelectedItem] = useState("");

  const [planimetry, setPlanimetry] = useState([]);
  const [imageDimensions, setImageDimensions] = useState(null);

  const activitySelected = attività?.find((item) => item.id===selectItem);

  const planimetrySelected = activitySelected
    ? activitySelected.planimetries.find((item) => item.id===planimetry.id)
    : "no item seelcted";

  const currentPlanimetryImage = planimetry?.image;

  const selectedList =
    attività && attività.length > 0
      ? attività.filter((item) => item.checked)
      : [];

  const searchedList =
    attività && attività.length > 0
      ? attività.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : [];

  const inputRef = useRef([]);

  
   //get single item 
   async function getOne(id) {
    try {
        const response = await api().get(
            `${real_estate_management_endpoint}${id}`
        );
        if(response.data) {
            setUnit(response.data)
        }
    }
        catch(error) {
            console.log(error)
    }
    }

    useEffect(() => {
        getOne(unitId)
    }, []) 

  //myjson.members =  JSON.parse(myjson.members.replace(/'/g, "\""))
  //myjson.planimetries =  JSON.parse(myjson.planimetries.replace(/'/g, "\""))

  const navigate = useNavigate();


  //add "checked" key to all the items of "attività" list
  function addCheckedKey(data) {
    const checkedArray = data?.map((item) => {
      return { ...item, checked: false };
    });
    return checkedArray;
  }

  const rectangles = {
    ...planimetrySelected?.markers,
    features: planimetrySelected?.markers?.features
      ? planimetrySelected?.markers?.features.filter(
          (item) => item.geometry.type==="Polygon"
        )
      : [],
  };

  const points = {
    ...planimetrySelected?.markers,
    features: planimetrySelected?.markers?.features
      ? planimetrySelected?.markers.features.filter(
          (item) => item.geometry.type==="Point"
        )
      : [],
  };

  //handle checked items
  function handleCheckbox(id) {
    const newAttività =
      attività?.length > 0 &&
      attività.map((item) => {
        return item.id===id ? { ...item, checked: !item.checked } : item;
      });
    setAttività(newAttività);
  }

  //GET
  async function getAll() {
      if (unit.activities) {
        const data = unit.activities.map((item) => {
          return {
            ...item,
              planimetries:item.planimetries.length > 0 ?
              item.planimetries.map(str => {
                const doubleQuotesString = str.replace(/'/g, '"');
                return JSON.parse(doubleQuotesString);
              }):
              []
          };
        });

        console.log(data, "data", unit.activities)
        setSelectedItem(data?.[0]?.id);
        setPlanimetry(data?.[0]?.planimetries?.[0]);

        setAttività(addCheckedKey(data));
      }
  }

  useEffect(() => {
    getAll();
  }, [unit]);

  function createActivity() {
    navigate("nuova-attivita");
  }

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });

  //Appena il componente render, imposta la planimetria di default come la prima planimetria della prima attività tra quelle dei dati che arrivano
  //imposta anche la prima attività come quella di default

  //when user select a new planimetry, change the state Planimetry accordingly
  const handlePlanimetry = (event) => {
    const { value } = event.target;
    const newPlan = activitySelected?.planimetries.find(
      (item) => item.image===value
    );
    setPlanimetry(newPlan);
  };
  //check image dimensions for bounds
  useEffect(() => {
    if (planimetry?.image) {
      loadImage(setImageDimensions, planimetry.image);
    }
  }, [planimetry]);

  //on click of a cardmap, make the clicked item the selected one
  //and make the planimetry selected the first one of the  current activity
  function handleItemSelected(id) {
    setSelectedItem(id);
    const activitySelected = attività?.find((item) => item.id===id);
    setPlanimetry(activitySelected.planimetries[0]);
  }

  // il secondo array prende height and width dell'img
  //se voglio un pin posizionato centrale uso come position bounds[1][0] / 2, bounds[1][1] / 2
  const bounds = [[0, 0], imageDimensions ? imageDimensions : [512, 512]];

  //CHANGE MARKER ICON
  function myIcon() {
    return L.icon({
      iconUrl: require("../../../assets/images/pin-red.png"),
      iconSize: 35,
    });
  }

  //Change view type
  function handleViewType(type) {
    if (viewType===type) return;
    if (type === "list") {
      setViewType("list");
    } else if (type === "map") {
      setViewType("map");
    } else if (type === "gantt") {
      setViewType("gantt");
    }
  }

  //Settings for carousels attività map card mobile
  let settings = {
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  
  async function editItem(id, data) {
    try {
      data.planimetries = JSON.stringify(data.planimetries).replaceAll(
        '"',
        "'"
      );
      // TODO membri da stringa a lista al backend
      // data.members = JSON.stringify(data.members);
      const response = await api().put(
        `${activity_endpoint}`,
        data
        // {headers: {"Authorization" : `Bearer ${token}`,
        // "Content-Type": "application/json"}}
      );
      await getAll();
    } catch (error) {
    }
  }

  //QUANDO CLICCO OPTION => MODIFICA, METTE A FUOCO l'input name selezionato
  function handleEdit() {
    inputRef.current[selectedList[0].id].focus();
  }

  async function onDelete(id) {
    try {
      await api().delete(
        `${activity_endpoint}${id}`
        //                      {headers: {"Authorization" : `Bearer ${token}`}}
      );
      await getAll();
    } catch (error) {
      console.log(error);
    }
  }

  function handleDelete(e) {
    e.preventDefault();
    selectedList.forEach((item) => onDelete(item.id));
  }

  const [tabState, setTabState] = useState(0);

  //PLANIMETRIES

  //GESTIONE NUOVO LIVELLO
  const [show, setShow] = useState(false)
  const [levelName, setLevelName]= useState("")

  function handleNewLevel(e){
    setLevelName(e.target.value)
  }

  function handleShow(){
    setShow(prev => !prev)
  }

  function handleSubmitLevel(){
    console.log("creazione livello", levelName)
    handleShow()
  }

  //GESTIONE PLANIMETRIA
  const [showPl, setShowPl] = useState(false)
  const [plan, setPlan]= useState({
    name:"",
    level:"",
    file:""
  })

  function handleNewPlan(e){
    const {value, name} = e.target
    setPlan(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  function handleShowPl(){
    setShowPl(prev => !prev)
  }

  function handleSubmitPl(){
    console.log("creazione plan", plan)
    handleShowPl()
  }

    //GESTIONE EDIT PLANIMETRIA
    const [showEditPl, setShowEditPl] = useState(false)
    const [planEdit, setPlanEdit]= useState({
      name:"",
      level:"",
      file:""
    })
  
    function handleEditPlan(e){
      const {value, name} = e.target
      setPlan(prev => {
        return {
          ...prev,
          [name]: value
        }
      })
    }
  
    function handleShowEditPl(){
      setShowEditPl(prev => !prev)
    }

    function handleEditSubmitPl(){
      console.log("edit plan", plan)
      handleShowEditPl()
    }

     //GESTIONE ARCHIVIO
     const [showArchive, setShowArchive] = useState(false)
   
     function handleShowArchive(){
      setShowArchive(prev => !prev)
     }
 


  //GESTIONE CARD PLANIMETRIE
  
    //NEW SEARCH & FILTER

	//SEARCH

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

    const categories = [
		/* {
		name: 'Tipologia',
		items: [
			"Affitto",
			"Proposta"
		]
		},
		{
		name: 'Unità',
		items: units.map((unit) => unit.name),

		},
		{
			name: 'Gestione',
			items: gestioni.map(el => String(el.id)),
		} */
      
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (attività.length > 0) {
        setFilteredUnits(
          attività.filter((el) => {

            const elDate = new Date(el.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && elDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && elDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return el.units.some((unit) => {
						return cat.items.includes(unit.name);
						});
					}  
					})) &&
					el.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, attività, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
      setSearchTerm("");
      setSelectedCategories([]);
      setSortBy("più-recenti");
      setFilteredUnits(attività);
      setStartDate("")
      setEndDate("")
		}

    //FIND NAME OF THE GESTIONE
    console.log(unit, "UNIT")

    //trova nome della gestione di riferimento
    const [gestioneId, setGestioneId] = useState(null);
    const [gestioneReference, setGestioneReference] = useState(null)

    const [sectionReference, setSectionReference] = useState(null)


    useEffect(() => {
        const url = window.location.pathname;
        const parts = url.split('/');
        if (parts.length >= 0) {
          setSectionReference(parts[2]);
          setGestioneId(parts[3]);
        }

        console.log(url, parts, "liiink")
    }, []);

    //get single gestione
    async function getOneGestione(id) {
      try {
          const response = await api().get(
              `${management_endpoint}${id}`
          )

          if(response.data) {
            setGestioneReference(response.data)
          }
      }
          catch(error) {
              console.log(error)
      }
  }

    useEffect(() => {
      getOneGestione(gestioneId)
    }, [gestioneId])


    const [showDelete, setShowDelete] = useState(false)

    function handleDelete(){
      console.log("elimina plan")
    }

    function handleShowDelete(){
      setShowEditPl(false)
      setShowDelete(prev => !prev)
    }

  return (
    <>
            {/* HEADER */}
            <div className="title-search-group mobile-container-xl ">
                <div className="right-header">
                    <TopHeading
                        SmallHeading=""
                        back_modal={false}
                    />
                </div>

                <Col md={12} className="my-3 px-0">
                        <Breadcrumb>
                                {sectionReference == "gestioni" && 
                                  <>
                                    <Breadcrumb.Item 
                                        className="settings-archive"
                                        onClick={() => {
                                            navigate('/statoavanzamentolavori/gestioni')
                                        }}>
                                        Gestioni
                                    </Breadcrumb.Item>

                                    <Breadcrumb.Item
                                        className="settings-archive"
                                        onClick={() => {
                                          navigate(`/statoavanzamentolavori/gestioni/${gestioneId}`)
                                        }}
                                    >
                                      {gestioneReference?.name}
                                    </Breadcrumb.Item>
                                  </>
                                }

                                {sectionReference == "unitaestabili" && 
                                  <>
                                    <Breadcrumb.Item 
                                      className="settings-archive"
                                      onClick={() => {
                                          navigate('/statoavanzamentolavori/unitaestabili')
                                      }}>
                                      Unità e Stabili
                                    </Breadcrumb.Item>
                                  </>
                                }
                            
                                <Breadcrumb.Item active={true} 
                                    className="settings-archive"
                                    >
                                    {unit?.name}
                                </Breadcrumb.Item>
                            
                        </Breadcrumb>
                    </Col>

                <div className={`tab-state-slc`}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="tab-container-slc"
                      onClick={(e) => {
                        setTabState(0);
                      }}>
                      <label className={tabState === 0 ? "" : "non-active"}>
                        Attività
                      </label>
                      <div
                        className={`tab-bar-slc ${
                          tabState === 0 ? "active-tab-bar" : ""
                        }`}></div>
                    </div>
                    <div
                      className="tab-container-slc"
                      onClick={(e) => {
                        setTabState(1);
                      }}
                      style={{ marginLeft: "-5px" }}>
                      <label className={tabState === 1 ? "" : "non-active"}>
                        Planimetrie
                      </label>
                      <div
                        className={`tab-bar-slc ${
                          tabState === 1 ? "active-tab-bar" : ""
                        }`}></div>
                    </div>
                  </div>
                </div>

                {tabState === 0 && <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
                    
                      <Col className="d-flex flex-column flex-md-row justify-content-start align-items-start">
                      <h2 className="fw-bold me-4">{unit.name}</h2>
                      <div className="d-flex justify-content-center align-items-center ">
                        <a
                          className={`viewType ${viewType === "list" ? "select" : ""}`}
                          onClick={() => handleViewType("list")}
                        >
                          <i class="bi bi-list-ul"></i>
                        </a>
                        <a
                          className={`viewType ms-2 ${viewType === "map" ? "select" : ""}`}
                          onClick={() => handleViewType("map")}
                        >
                          <i class="bi bi-map"></i>
                        </a>
                        <a
                          className={`viewType ms-2 ${
                            viewType === "gantt" ? "select" : ""
                          }`}
                          onClick={() => handleViewType("gantt")}
                        >
                          <i class="bi bi-bar-chart-steps"></i>
                        </a>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end ">
                      <AddBtn
                        onClick={createActivity}
                        tooltip="Nuova attività"
                        className="me-3 me-md-0 order-md-1"
                      />
                    </Col>                    
                </Row>}
            </div>
            
            {/* ATTIVITA' VIEW */}
            <div className="edit-content-container-slc">

              <div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>

                {/** BODY **/}

                {/* LIST VIEW */}
                {viewType === "list" && (
                    <ListViewActivity
                      attività={attività}
                      sortedItems={sortedItems}
                      selectedList={selectedList}
                      handleCheckbox={handleCheckbox}
                      handleDelete={handleDelete}
                      setSearchTerm={setSearchTerm}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      clearFiltersAndSort={clearFiltersAndSort}
                      setSortBy={setSortBy}
                      categories={categories}
                      onEdit={editItem}
                      ref={inputRef}
                      setStartDate={setStartDate} setEndDate={setEndDate}

                    />
                )}

                {/* MAP VIEW */}
                {viewType === "map" && (  
                    <MapViewActivity
                      attività={unit.activities}
                      sortedItems={sortedItems}
                      selectedList={selectedList}
                      setSearchTerm={setSearchTerm}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      clearFiltersAndSort={clearFiltersAndSort}
                      setSortBy={setSortBy}
                      categories={categories}

                      handleItemSelected={handleItemSelected}
                      selectItem={selectItem}

                      bounds={bounds}
                      activitySelected={activitySelected}
                      currentPlanimetryImage={currentPlanimetryImage}

                      rectangles={rectangles}
                      points={points}
                      planimetry={planimetry}
                      handlePlanimetry={handlePlanimetry}
                      setStartDate={setStartDate} setEndDate={setEndDate}

                    />
                )}

                {/* GANTT VIEW */}
                {viewType === "gantt" && (
                    <GanttViewActivty 
                      attività={unit.activities}
                      sortedItems={sortedItems}
                      selectedList={selectedList}
                      setSearchTerm={setSearchTerm}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      clearFiltersAndSort={clearFiltersAndSort}
                      setSortBy={setSortBy}
                      categories={categories}
                      setStartDate={setStartDate} setEndDate={setEndDate}

                    />
                )}
              </div>
              
                {/* PLANIMETRIA VIEW  - SPOSTARE IN COMPONENT A PARTE*/}
              <div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>
                            <Row className="mobile-container d-flex flex-column flex-md-row mt-md-4 justify-content-between align-items-center w-100">
                                <Col className="d-flex">
                                    <h2 className="fw-bold ms-2">{unit.name? unit.name : ""}</h2>
                                </Col>
                                <Col className="d-flex justify-content-end ">
                                    <ButtonRm variant="primary" size="small"
                                    onClick={handleShowPl}>Carica Planimetria</ButtonRm>   
                                    <ButtonRm variant="tertiary" size="small" className="ms-2"
                                    onClick={handleShow}>Crea Livello</ButtonRm>      
                                </Col>
                            </Row>
                            { Object.keys(objLevels).length > 0 &&                   
                                <Row className="mt-4">
                                  {isDesktop && (
                                    <Col lg={4} className="d-flex justify-content-center col-2-xxxl">
                                      <div className="unit-container">
                                        {/* filter & sort */}
                                        <div className="d-flex px-3 pt-3">
                                          <a className="d-flex align-items-center text-dark opacity-75 me-3">
                                            <i className="bi bi-filter fs-14"></i>
                                            <p className="fs-10">Filtra</p>
                                          </a>
                                          <a className="d-flex align-items-center text-dark opacity-75">
                                            <i className="bi bi-arrow-down-up fs-10 me-1"></i>
                                            <p className="fs-10">Ordina</p>
                                          </a>
                                        </div>
                  
                                        {Object.keys(objLevels).length > 0 && (
                                          <div className="my-1 d-flex  justify-content-start flex-column">
                                            <Accordion>
                                              {Object.keys(objLevels).length > 0 &&
                                                Object.keys(objLevels).map((item, index) => {
                                                  return item != "noLevel"?
                                                    <Accordion.Item eventKey={index}>
                                                      <Accordion.Header>{item}</Accordion.Header>
                                                      
                                                      {
                                                        objLevels[item].map(el => {
                                                          return  <Accordion.Body className="border ms-3" style={{cursor:"pointer"}}>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                              {el.name}
                                                              <div className="d-flex align-items-center">
                                                                <button
                                                                  type="button"
                                                                  className="btn round-icon-btn me-2"
                                                                  onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handleShowArchive()
                                                                }}>
                                                                  <BsArchive />
                                                                </button>
                                                                <button
                                                                  type="button"
                                                                  className="btn round-icon-btn"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      handleShowEditPl()
                                                                  }}>
                                                                <i class="bi bi-pencil"></i>
                                                                </button>
                                                              </div>
                                                            </div>
                                                        </Accordion.Body>
                                                        })
                                                      }
                                                  </Accordion.Item>
                                                    :
                                                    <>
                                                    {
                                                        objLevels[item].map(el => {
                                                          return   (<Accordion.Item eventKey={index} className="accordion-disabled-slc">
                                                          <Accordion.Header>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                              {el.name}
                                                              <div className="d-flex align-items-center">
                                                                <button
                                                                  type="button"
                                                                  className="btn round-icon-btn me-2"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      handleShowArchive()
                                                                  }}>
                                                                  <BsArchive />
                                                                </button>
                                                                <button
                                                                  type="button"
                                                                  className="btn round-icon-btn"
                                                                  onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                      handleShowEditPl()

                                                                  }}>
                                                                <i class="bi bi-pencil"></i>
                                                                </button> 
                                                              </div>
                                                            </div>
                                                            </Accordion.Header>
                                                        </Accordion.Item>)
                                                        })
                                                      
                                                    }
                                                    </>
                                                  
                                                }
                                              )}
                                            </Accordion>
                                          </div>
                                        )}

                                      </div>
                                    </Col>
                                  )}
                  
                                  <Col lg={8} className="col-10-xxxl">
                                    <div className="unit-container">
                                      <img loading="lazy" src={examples[0].file} width="100%" height="auto"/>
                                    </div>
                                  </Col>
                                </Row>
                            }

                            {   examples.length == 0 && 
                              <EmptyStateContainer 
                              content="Non hai aggiunto ancora alcuna planimetria"
                              img={EmptyStatePl} />
                            }
                    {/* MODAL LEVEL */}
                    <Modal show={show} onHide={handleShow} className="p-5" centered >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                        className=" pt-md-5 px-md-5"
                      >
                        <Modal.Title>Crea livello</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-3 px-md-5">
                        <Form>
                          <Form.Group className="mb-3 d-flex flex-column" >
                            <Form.Label>Nome livello</Form.Label>
                            <input
                              type="text"
                              placeholder="Inserisci il nome del livello"
                              style={{ height: "50px",  
                              borderRadius: "6px",
                              border: "1px solid #BDBDBD",
                              padding: "0 .5rem" 
                              }}
                              onChange={handleNewLevel}
                              value={levelName}
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer
                        className="d-flex flex-column  px-md-5 pb-md-4"
                        style={{ borderTop: "none" }}
                      >
                        <ButtonRm variant="primary" onClick={handleSubmitLevel}>
                          Salva
                        </ButtonRm>
                        <ButtonRm variant="tertiary" onClick={handleShow}>
                          Annulla
                        </ButtonRm>
                      </Modal.Footer>
                    </Modal>

                    {/* MODAL PLAN*/}
                    <Modal show={showPl} onHide={handleShowPl} className="p-5" centered >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                        className=" pt-md-5 px-md-5"
                      >
                        <Modal.Title>Carica Planimetria</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-3 px-md-5">
                        <Form>
                          <Form.Group className="mb-3 d-flex flex-column" >
                            <Form.Label>Nome Planimetria</Form.Label>
                            <input
                              type="text"
                              placeholder="Inserisci il nome della planimetria"
                              style={{ height: "50px",  
                              borderRadius: "6px",
                              border: "1px solid #BDBDBD",
                              padding: "0 .5rem" 
                              }}
                              name="name"
                              onChange={handleNewPlan}
                              value={plan.name}
                            />
                          </Form.Group>

                          <Form.Label>Seleziona un livello (facoltativo)</Form.Label>
                          <Form.Select style={{height:'50px'}} onChange={handleNewPlan} value={plan.level} name="level">
                                                <option value=''>Seleziona</option>
                                                <option value='1'>Primo Piano</option>
                                                <option value='2'>Secondo Piano</option>
                                                {/* { examples.length > 0 &&
                                                    levels.map(level => <option value={level.level}>{level.level} - Unità </option>)
                                                } */}
                                            </Form.Select>
                        </Form>
                        <Form.Group controlId="formFile" className="my-3" value={plan.file} onChange={handleNewPlan}>
                          <Form.Label>Seleziona la planimetria</Form.Label>
                          <Form.Control type="file" name="file"/>
                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer
                        className="d-flex flex-column  px-md-5 pb-md-4"
                        style={{ borderTop: "none" }}
                      >
                        <ButtonRm variant="primary" onClick={handleSubmitPl}>
                          Salva
                        </ButtonRm>
                        <ButtonRm variant="tertiary" onClick={handleShowPl}>
                          Annulla
                        </ButtonRm>
                      </Modal.Footer>
                    </Modal>

                    {/* MODAL EDIT*/}
                    <Modal show={showEditPl} onHide={handleShowEditPl} className="p-5" centered >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                        className=" pt-md-5 px-md-5"
                      >
                        <Modal.Title>Modifica Planimetria</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-3 px-md-5">
                        <Form>
                          <Form.Group className="mb-3 d-flex flex-column" >
                            <Form.Label>Nome Planimetria</Form.Label>
                            <input
                              disabled
                              type="text"
                              placeholder="Inserisci il nome della planimetria"
                              style={{ height: "50px",  
                              borderRadius: "6px",
                              border: "1px solid #BDBDBD",
                              padding: "0 .5rem" 
                              }}
                              name="name"
                             
                            />
                          </Form.Group>

                          <Form.Label>Seleziona un livello (facoltativo)</Form.Label>
                          <Form.Select style={{height:'50px'}} onChange={handleEditPlan} value={planEdit.level} name="level" disabled>
                                                <option value=''>Seleziona</option>
                                                <option value='1'>Primo Piano</option>
                                                <option value='2'>Secondo Piano</option>
                                                {/* { examples.length > 0 &&
                                                    levels.map(level => <option value={level.level}>{level.level} - Unità </option>)
                                                } */}
                                            </Form.Select>
                        <Form.Group controlId="formFile" className="my-3" value={planEdit.file} onChange={handleEditPlan}>
                          <Form.Label>Seleziona la planimetria</Form.Label>
                          <Form.Control type="file" name="file" disabled/>
                        </Form.Group>
                        </Form>
                        <div onClick={() =>  handleShowDelete()
}>
                          <BsTrash /> Elimina planimetria
                        </div>
                      </Modal.Body>
                      <Modal.Footer
                        className="d-flex flex-column  px-md-5 pb-md-4"
                        style={{ borderTop: "none" }}
                      >
                        <ButtonRm variant="primary" onClick={handleEditSubmitPl}>
                          Salva
                        </ButtonRm>
                        <ButtonRm variant="tertiary" onClick={handleShowEditPl}>
                          Annulla
                        </ButtonRm>
                      </Modal.Footer>
                    </Modal>

                     {/* MODAL ARCHIVE */}
                    <Modal show={showArchive} onHide={handleShowArchive} className="p-5" centered >
                      <Modal.Header
                        closeButton
                        style={{ borderBottom: "none" }}
                        className=" pt-md-5 px-md-5"
                      >
                        <Modal.Title>Archivio</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="p-3 px-md-5">
                        <p className="mb-3">Quando modifichi il file di una planimetria, in automatico salviamo la planimetria precedente in questo archivio, per avere uno storico/cronologia delle planimetrie in questione</p>

                        <div className="">
                          <small>Data di archiviazione: 01/05/2024</small>
                          <div className="border d-flex align-items-center justify-content-between p-3">
                            <p>Nome_file</p>
                            <button
                                type="button"
                                className="btn round-icon-btn"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleShowDelete()
                                }}>
                              < BsTrash />
                            </button> 
                          </div>
                        </div>

                      </Modal.Body>
                      <Modal.Footer
                        className="d-flex flex-column  px-md-5 pb-md-4"
                        style={{ borderTop: "none" }}
                      >
                      </Modal.Footer>
                    </Modal>
                    
                    {/* MODAL DELETE */}
                    <Modal show={showDelete} onHide={(e) => handleShowDelete(e)} centered>
                      <Modal.Body className="p-5">
                          <div className="d-flex flex-column text-center">
                              <h2 className="fs-32 text-dark fw-semibold mb-3">
                              Vuoi eliminare questa planimetria?
                              </h2>
                              <h6 className="fs-16 text-dark fw-normal mb-3">
                              Questo elemento verrà eliminato in maniera permanente
                              </h6>
                              <ButtonRm 
                                  variant="primary"
                                  onClick={(e) => { 
                                      e.preventDefault()
                                      e.stopPropagation()
                                      handleDelete()
                                  }}
                              >
                                  Conferma
                              </ButtonRm >
                              <ButtonRm 
                                  variant="text"
                                  onClick={(e) => handleShowDelete(e)}
                              >
                                  Annulla
                              </ButtonRm >
                          </div>
                      </Modal.Body>
    </Modal> 
              </div>
            </div>
      
      <Outlet />
    </>
  );
}
