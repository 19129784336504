import React, { useState, useRef, useEffect } from "react";

import TopHeading from "../../TopHeading";

import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ButtonRm from "../../ButtonRm";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';

import { BsPrinter, BsDownload } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { addOwner, removeOwner, editOwner, fetchOwner } from "../../../actions/owner";

import { addCondominium, removeCondominium, editCondominium, fetchCondominium } from "../../../actions/stabile";

import { useMediaQuery } from "react-responsive";

import CustomBadge from "../../CustomBadge";

import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";


export default function ModelliInvia(){

    //GET OWNER LIST FROM REDUX
    const ownerList = useSelector((state) => state.ownerReducer?.owner);
    const stabiliList = useSelector((state) => state.condominiumReducer?.condominium);


    const dispatch = useDispatch();

    //get owners when this component render for the first time
    useEffect(() => {
        dispatch(fetchOwner())
        dispatch(fetchCondominium())
    }, []);

    const initialContent = `CONVOCAZIONE 
    L’anno 0000, il giorno gg, del mese di ………………………….. , alle ore 00:00, presso …………………………………………………….. , si è riunita l’Assemblea ordinaria dei soci dell’Associazione ………………………………………………………………….
    Assume la presidenza dell’Assemblea, ai sensi dell’Art. …………………………… del vigente statuto sociale il Sig. Cognome Nome, verbalizza il Sig. Cognome Nome.
    Il Presidente costatato che l’Assemblea è stata regolarmente convocata mediante avviso affisso presso la sede sociale (oppure spedito ai soci) così come previsto dall’art. …………….. dello statuto, contenente il seguente o.d.g.:
    ……………………
    ……………………
    ……………………
    ……………………
    Varie ed eventuali
    l’ora e il luogo della seduta e che trascorsa oltre mezz’ora dall’orario stabilito nell’avviso di convocazione sono presenti n° ….. soci su n° …… iscritti all’Associazione, fa rilevare che il numero dei soci presenti raggiunge / non raggiunge quello richiesto dallo statuto per la validità delle assemblee in prima convocazione.
    Dichiara pertanto che l’assemblea è / non è validamente costituita al fine di deliberare su quanto posto dall’ordine del giorno.
    
    Solo se l’assemblea non è validamente costituita
    Dichiara quindi la riunione rinviata alla seconda convocazione già fissata per il giorno gg/mm/aa, alle ore 00:00, presso la stessa sede.
    
    Letto, confermato e sottoscritto
    
        Il Segretario 								Il Presidente
    
    ……………………………………………………………..…			……………………………………………………………….`;
    const contentState = ContentState.createFromText(initialContent);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));

    const [contentToSend, setContentToSend] = useState({
        destinatari: [],
        content: ""
    })

    function handleEdit(e){
    const {name, value} = e.target
    setContentToSend(prev => {
        return {
        ...prev,
        [name]: value
        }
    })
    }

    const [type, setType] = useState("persona")

    function handleType(e){
    const { value} = e.target
    setType(value)
    }

    /* STEP */

    const [step, setStep] = useState(1)

    function next(){
        setStep(prev => prev + 1)
    }

    function back(){
        setStep(prev => prev - 1)
    }


    // Controlla se siamo mobile
    const isMobile = useMediaQuery({
		query: "(max-width: 768px)",
	})

    const esempio = [
        {   
            id:1,
            name: "Mario",
            surname: "Guidi",
            methodAvailable: ["email", "pec", "chat", "postalizza"],
            methodFav: "email",
            method1:"email",
            nameInput: "method1",
            sent:"false"
        },
        {
            id:2,
            name: "Nunzio",
            surname: "Guidi",
            methodAvailable: ["email", "pec", "chat"],
            methodFav: "pec",
            method2:"pec",
            sent:"false"
        },
        {
            id:3,
            name: "Giulio",
            surname: "Guidi",
            methodFav: "consegna",
            methodAvailable: ["email", "pec", "chat", "consegna"],
            method3:"consegna",
            sent:"false"
        },
        {
            id:4,
            name: "Fedele",
            surname: "Guidi",
            methodAvailable: ["email", "postalizza", "chat", "consegna"],
            methodFav: "chat",
            method4:"consegna",
            sent:"false"
        },
        {
            id:5,
            name: "Goiele",
            surname: "Guidi",
            methodAvailable: ["postalizza"],
            methodFav: "postalizza",
            method5:"postalizza",
            sent:"false"
        },
    ]

    const [sendDetail, setSendDetail] = useState(esempio)

    function handleMethod(e, id){
        const {name, value, type, checked} = e.target

        const newArr = sendDetail.map((item) => {
            return item.id == id ?
            {...item, [name]: type == "checkbox"? checked : value}:
            item
        })

        setSendDetail(newArr)
    }

    console.log(sendDetail)

    //MODAL POSTALIZZAZIONE

    const [postalizzaModal, setPostalizzaModal] = useState(false)

    function showPostalizzaModal(){
        setPostalizzaModal(true)
    }

    function closePostalizzaModal(){
        setPostalizzaModal(false)
    }



    return(
        <>
        <div className="right-header"> 
           SmallHeading="" />
        </div>
        { step == 1 &&
            <Container fluid> 
            <p className="fw-bold fs-24">Invia Documento</p>
            <p>Le modifiche che apporterai a questo documento non sovrascriveranno il documento originale. </p>
            <Row className="settings-container flex-column">
                <Col className="mt-3 mt-md-0">
                    <p className="text-uppercase opacity-75">DESTINATARIO/I</p>
                    <Col className="mb-3 mt-2">
                        <div className="d-flex">
                        <Form.Check
                            inline
                            label="Persona singola"
                            type="radio"
                            value="persona"
                            checked={type === "persona"}
                            onChange={handleType}
                        />
                        <Form.Check
                            inline
                            label="Proprietari di uno stabile"
                            type="radio"
                            value="stabile"
                            checked={type === "stabile"}
                            onChange={handleType}
                        />
                        </div>
                    </Col>
                </Col>

                <Col md={6} className="mb-3">
                    <Form.Group>
                        <Form.Label 
                            className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        {type == "persona" ? "Persona" : "Stabile"}
                        </Form.Label>
                        <Form.Select 
                            name="destinatari"
                            value={contentToSend.destinatari}
                            onChange={handleEdit}
                            required
                        >
                            {
                                type == "persona" && ownerList.length > 0 &&
                                ownerList.map((item, index) => {
                                    return <option key={index}>{item.surname} {item.name}</option>
                                })
                            }

                            {
                                type == "stabile" && stabiliList.length > 0 &&
                                stabiliList.map((item, index) => {
                                    return <option key={index}> {item.name}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>

                <hr className="my-3 opacity-25" />
                <Col>
                    <div className="d-flex mt-3 mb-3 justify-content-end">
						<div
							className="btn bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2 text-center d-flex justify-content-center align-items-center"
							//onClick={handlePrint}
                            >
							<BsPrinter />
						</div>
						<div
							className="btn bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 text-center d-flex justify-content-center align-items-center"
							//onClick={handleExport}
                            >
							<BsDownload />
						</div>
					</div>
                </Col>

                <Col className="border editor-model">
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbarHidden={true}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                    />
                </Col>
                
                <div className="mt-4 d-flex align-items-center justify-content-end"  >
                    <ButtonRm variant="tertiary" className="me-2">Annulla</ButtonRm>
                    <ButtonRm variant="primary" type="submit" onClick={next}>Avanti</ButtonRm>
                </div>

            </Row>
            </Container>
        }
        { step == 2 &&
            <Container fluid> 
            <p className="fw-bold fs-24">Invia Documento</p>
            <p>Seleziona i metodi di invio del documento per ciascuno individuo. Se hai definito dei metodi di contatto preferiti, saranno selezionati di default.</p>
            <Row className="settings-container flex-column">

            {
                sendDetail.map((item, index) => {
                    return  <Col key={item.id} className="px-0 mb-2">
                    <div className="faceIdDiv text-start d-md-flex align-items-center justify-content-between">
                        <div className="d-flex">
                            <p className="fs-16 fw-bold text-capitalize">{item.surname} {item.name}</p>
                        </div>
                        <div className={`${isMobile ? "d-flex  flex-column": "d-flex input-group-rm" }`}>
                            {
                                item.methodAvailable?.map((el, indexArr) => {
                                    return <Form.Check
                                    key={indexArr}
                                    className={`${item.methodFav == el ? " " : ""} input-group-rm-item `}
                                    inline
                                    name={`method${item.id}`}
                                    label={el.charAt(0).toUpperCase() + el.slice(1)}
                                    type="radio"
                                    value={el}
                                    checked={item[`method${item.id}`] == el}
                                    onChange={(e) =>handleMethod(e, item.id)}
                                />
                                })
                            }
                            
                        </div>
                    </div>
                    </Col> 
                })
            }               
                <div className="mt-4 d-flex align-items-center justify-content-end"  >
                    <ButtonRm variant="tertiary" className="me-2" onClick={back}>Indietro</ButtonRm>
                    <ButtonRm variant="primary" type="submit" onClick={next}>Invia</ButtonRm>
                </div>

            </Row>
            </Container>
        }
        { step == 3 &&
            <Container fluid> 
            <p className="fw-bold fs-24">Riepilogo</p>
            <p>Inserisci i dati mancanti per ultimare l’invio </p>
            <Row className="settings-container flex-column">

            {
                sendDetail.map((item, index) => {
                    return  <Col key={item.id} className="px-0 mb-2">
                    <div className="faceIdDiv text-start d-md-flex align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                            <p className="fs-16 fw-bold text-capitalize">{item.surname} {item.name}</p>
                            { item[`method${item.id}`] == "consegna" && 
                                <Form.Check                                
                                    inline
                                    label="Segna come consegnato"
                                    type="checkbox"
                                    name="sent"
                                    onChange={(e) =>handleMethod(e, item.id)}
                                    checked={item.sent}
                                />
                            }


                        </div>
                        <div className="d-flex flex-column justify-content-end align-items-end">
                            {item[`method${item.id}`] != "postalizza" &&
                            item[`method${item.id}`] != "consegna" && <CustomBadge variant="custom-badge-success">Inviato</CustomBadge>}

                            { 
                            item[`method${item.id}`] == "consegna" && item.sent == false &&
                                <>
                                    <CustomBadge variant="custom-badge-danger">Ricordati di consegnare il documento a questo soggetto</CustomBadge>
                                    <Link className="text-decoration-underline text-dark fs-14 me-2" to="/alert/nuovo-evento">Crea alert</Link>
                                </>
                            }

{ 
                            item[`method${item.id}`] == "consegna" && item.sent &&
                                <>
                                   <CustomBadge variant="custom-badge-success">Inviato</CustomBadge>
                                </>
                            }

                   

                        { item[`method${item.id}`] == "postalizza" && 
                                <>
                                    <CustomBadge variant="custom-badge-alert"
                                    onClick={showPostalizzaModal}>Inserisci le informazioni mancanti</CustomBadge>
                                </>
                            }

                        </div>
                    </div>
                    </Col> 
                })
            }               
                <div className="mt-4 d-flex align-items-center justify-content-end"  >
                    <Link to="/modelli/storico">
                        <ButtonRm variant="primary" type="submit" >Vai allo storico</ButtonRm>
                    </Link>
                </div>

            </Row>
            </Container>
        }

        {/* MODAL POSTALIZZA */}
        <Modal
                    show={postalizzaModal}
                    onHide={closePostalizzaModal}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <p className="fs-18 fw-bold mt-3 mb-2">Inserisci dati per la postalizzazione</p>
                            
                            {/* <ButtonRm variant="primary" onClick={closeOtpModal}>Verifica</ButtonRm>
                            <ButtonRm variant="text">Rinvia OTP</ButtonRm> */}                        
                        </div>

                    </Modal.Body>
        </Modal>
        </>
    )
}