import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MediaQuery from "react-responsive";
import icon1 from "../../../../assets/images/chat-alt.svg";
import icon2 from "../../../../assets/images/Trash_light.svg";
import icon3 from "../../../../assets/images/Edit_light.svg";
import icon4 from "../../../../assets/images/Print_light.svg";
import icon5 from "../../../../assets/images/download.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import ImportFile from "../../../../assets/images/addimport-file.png";
import CloseIcon from "../../../../assets/images/close.svg";
import DeleteIcon from "../../../../assets/images/delete.svg";
import CloseErrorIcon from "../../../../assets/images/closeError.svg";

import {Link} from "react-router-dom"

export default function MonthRateM(props) {
  const [show, setShow] = useState(false);

  const [file, setFile] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleChange = async (e) => {
    if (e.target.files.length !== 0) {
      const _file = Array.from(file);
      console.log("_file", _file);
      console.log(e.target.files);
      for (var i = 0; i < e.target.files.length; i++) {
        _file.push(e.target.files[i]);
      }
      console.log("file", _file);
      setFile(_file);
    }
  };

  const removeOne = (index) => {
    const _file = Array.from(file);
    _file.splice(index, 1);
    setFile(_file);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files);
    }
  };

  //CURRENT DATE
  const today = new Date()
  const deadline = new Date(props.item?.deadlineDate?.substring(0,10))
  const competenceStart = new Date(props.item?.competenceStartDate?.substring(0,10))

  const isDue = today.getTime() >= competenceStart.getTime()

  let classForDate

  if ((today.getTime() > deadline.getTime()) &&( props.item?.totalDue > 0)) {
    classForDate = "text-danger"
  } else {
    classForDate = "text-success"
  }

  const url = window.location.pathname;
  console.log(url, "RATE URL")

  return (
    <>
      <Link to={`/rate/${props.item?.id}`} state={{ from: url, name: props.pageTitle }}>
        <MediaQuery minWidth={1000}>
            <div
              className="d-flex align-items-center mb-2 justify-content-between bg-white  p-4 rate-card"
            >
              <div className="d-flex align-items-center">
              <div className="d-flex flex-column me-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                  </h6>
                  <input
                    type="checkbox"
                    onClick={(e) => e.stopPropagation()} // Prevent the click event from propagating to the row
                    checked={props.selectedItems.includes(props.item?.id)}
                    onChange={(e) => props.handleSelection(e, props.item?.id)}
                    className="me-2"
                  />
                </div>
                <div className="d-flex flex-column me-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                    identificativo
                  </h6>
                  <h2 className="fs-16 fw-semibold text-dark text-capitalize">
                    {props.item?.installmentName}
                  </h2>
                </div>
                <div className="d-flex flex-column me-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                    Inizio competenza
                  </h6>
                  <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                    {props.item?.competenceStartDate?.substring(0,10)}
                  </h2>
                </div>
                <div className="d-flex flex-column me-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                    SCADENZA
                  </h6>
                  <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                  {props.item?.deadlineDate?.substring(0,10)}
                  </h2>
                </div>
                <div className="d-flex flex-column me-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                    IMPONIBILE
                  </h6>
                  <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                    € {Number(props.item?.amount)}
                  </h2>
                </div>
                <div className="d-flex flex-column me-3">
              <h6 className="fs-10 fw-light text-dark text-uppercase">
                IVA
              </h6>
              <h2 className="fs-16 fw-semibold text-dark text-uppercase">
              {Math.ceil((props.item?.vatCode ? parseFloat(props.item?.vatCode) : 1) * 100 - 100) === 0 ? "Esente" : Math.ceil((props.item?.vatCode ? parseFloat(props.item?.vatCode) : 1) * 100 - 100) + "%"}

              </h2>
            </div>
              </div>
           
              <div className="d-flex align-items-center">
                
                <div className="d-flex flex-column ms-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                    Già versato
                  </h6>
                  <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                  € {props.item?.totalPaid}
                  </h2>
                </div>
                <div className="d-flex flex-column ms-3">
                  <h6 className={`fs-10 fw-light text-uppercase ${props.item?.totalDue >0 && isDue ? "text-danger" : "text-success"}`}>
                    Totale dovuto
                  </h6>
                  <h2 className={`fs-16 fw-semibold text-uppercase ${props.item?.totalDue >0 && isDue ? "text-danger" : "text-success"}`}>
                  € {props.item?.totalDue}
                  </h2>
                </div>
                <div className="d-flex flex-column ms-3">
                  <h6 className={`fs-10 fw-light text-uppercase ${classForDate}`}>
                    SALDO SCADUTO
                  </h6>
                  <h2 className={`fs-16 fw-semibold text-uppercase ${classForDate}`}>
                  {today.getTime() > deadline.getTime() ? props.item?.totalDue : 0}
                  </h2>
                </div>
              </div>
            </div>          
        </MediaQuery>
        <MediaQuery maxWidth={999}>
          <div
            onClick={() => setShow(true)}
            className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2 rate-card"
          >
          <div className="d-flex">
              <input
                    type="checkbox"
                    onClick={(e) => e.stopPropagation()} // Prevent the click event from propagating to the row
                    checked={props.selectedItems.includes(props.item?.id)}
                    onChange={(e) => props.handleSelection(e, props.item?.id)}
                    className="me-2"
                  />
            <div className="d-inline-flex flex-column">
            <h6 className="fs-12 text-secondary fw-light mb-2">
              Da {props.item?.competenceStartDate?.substring(0,10)} al {props.item?.deadlineDate?.substring(0,10)}
            </h6>
            <h4 className="fs-16 text-dark fw-semibold mb-2 textcapitalize">{props.item?.installmentName}</h4>
            </div>
          </div>
          <h5 className={`fs-24 fw-semibold ${classForDate}`}>  € {props.item?.totalDue} </h5>
          </div>
        </MediaQuery>
      </Link>
      
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w rounded-0"
        aria-labelledby="example-custom-modal-styling-title"
        className="rate-half-box  rounded-0"
      >
        <Modal.Body className="px-5 pb-5 pt-0 rounded-0">
          <div className="d-block rel mb-5">
            <Button
              variant=""
              className="position-absolute top-0 end-0 zi-4 border-0"
              onClick={() => setShow(false)}
            >
              <i className="bi bi-x"></i>
            </Button>
          </div>

          <div className="list-broke-box d-block">
            <div className="row">
              <div className="col-md-6">
                <div className="d-block bg-white p-4 mb-4">
                  <h3 className="text-dark fs39 fw-semibold mb-5">Rata1</h3>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">DATE</h3>
                    </div>

                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        Data inizio competenza
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        1/07/2022
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        DATA FINE COMPETENZA
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        31/07/2022
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        SCADENZA
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        31/07/2022
                      </h2>
                    </div>
                  </div>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">IMPORTO</h3>
                    </div>

                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        importo
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        1000$
                      </h2>
                    </div>
                  </div>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">TOTALE</h3>
                    </div>

                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        DOVUTO
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        1000$
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        versato
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        0$
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-danger text-uppercase">
                        saldo
                      </h6>
                      <h2 className="fs-16 fw-semibold text-danger text-uppercase">
                        1000$
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-danger text-uppercase">
                        saldo scaduto
                      </h6>
                      <h2 className="fs-16 fw-semibold text-danger text-uppercase">
                        1000$
                      </h2>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="rata-option-btn py-3 px-5 text-dark rounded-3 shadow border-0"
                >
                  Segna come pagato
                </button>
              </div>
              <div className="col-md-6">
                <div className="d-block bg-white p-4">
                  <div className="d-flex flex-row-reverse top-edit-print-chat-btn">
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40"
                    >
                      <img loading="lazy" src={icon1} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40"
                    >
                      <img loading="lazy" src={icon4} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40"
                    >
                      <img loading="lazy" src={icon5} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40"
                    >
                      <img loading="lazy" src={icon2} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40"
                    >
                      <img loading="lazy" src={icon3} alt="icon-photo" />
                    </button>
                  </div>
                  <div className="date-rata-impor my-5 d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">ALLEGATI</h3>
                    </div>
                    <div
                      className={
                        dragActive
                          ? "drag-active input-file-box rel"
                          : "input-file-box rel"
                      }
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <div
                        className="d-flex flex-column text-center"
                        style={{ height: file.length > 0 ? "40%" : "" }}
                      >
                        <label
                          htmlFor="file-input"
                          style={{ cursor: "pointer" }}
                        >
                          <h4 className="fs-16 mb-3 text-dark fw-semibold">
                            Nessun allegato
                          </h4>
                          <i
                            className="fal fa-plus w-54 h-54 text-center fs-24 rounded-circle mx-auto"
                            style={{ background: "#d0e1ef" }}
                          ></i>
                        </label>
                      </div>
                      <input
                        type="file"
                        name=""
                        id="file-input"
                        className="file-box d-none"
                        multiple={true}
                        ref={inputRef}
                        onChange={handleChange}
                      />

                      <div
                        className="importFileStatus-div"
                        style={{ display: file.length > 0 ? "inline" : "none" }}
                      >
                        <div
                          className="importFileStatusTitle-div"
                          id="importFileStatusTitle-div"
                        >
                          In Carimento - {file.length + "/" + file.length} files
                        </div>
                        <div className="completed-div" id="completed">
                          Caricati
                        </div>
                        {file.length > 0 &&
                          Array.from(file).map((element, index) => {
                            return (
                              <ImportFileProgress
                                file={element}
                                index={index}
                                key={index}
                                remove={(v) => removeOne(v)}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                  <div className="dri-heading bg-white p-2">
                    <h3 className="fs-20 text-dark fw-semibold">NOTE</h3>
                  </div>
                  <hr className="border-0" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const ImportFileProgress = ({ file, index, remove }) => {
  const [now, setNow] = useState(0);
  const [isValid, setIsValid] = useState(true);

  const isValidFileType = (file) => {
    const validExtensions = ["png", "jpeg", "pdf", "jfif", "heif"];
    const fileExtension = file.type.split("/")[1];
    setIsValid(validExtensions.includes(fileExtension));
  };

  useEffect(() => {
    setNow(0);
    const timer = setInterval(() => {
      setNow((oldProgress) => {
        return Math.min(oldProgress + Math.random() * 60, 100);
      });
    }, 499);
    isValidFileType(file);
    return () => {
      clearInterval(timer);
      setIsValid(true);
    };
  }, [file]);

  const removeIcon = async (e) => {
    remove(index);
  };

  return (
    <div key={index + 1} id={index}>
      <div
        className="importFileProgressbar"
        style={{
          borderColor: isValid === true ? "" : "#E41D1D",
          position: "relative",
        }}
      >
        <div className="container">
          {file.name}
          <ProgressBar
            style={{ display: now === 100 ? "none" : "" }}
            now={now}
            visuallyHidden
            className="progressbar-upload"
          />
        </div>
        <img
          src={
            now === 100
              ? isValid === true
                ? DeleteIcon
                : CloseErrorIcon
              : CloseIcon
          }
          style={{ position: "absolute", right: "7px" }}
          className="closeIcon"
          onClick={(e) => removeIcon(e)}
        ></img>
      </div>
      <div
        className="fileTypeError-div"
        style={{ display: isValid === true ? "none" : "" }}
      >
        Questo tipo di file non è supportato.
      </div>
    </div>
  );
};
