import * as React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_123', {
});

export default function Checkout ({clientSecret}) {
    const options = {clientSecret};

    return (
    <> 
        { clientSecret &&
            <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        }
    </>
    )
}