import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

import CustomTooltip from "../../../CustomTooltip";
import CustomToggle from "../../../CustomToggle";
import CustomBadge from "../../../CustomBadge";

import { isValidYear, validateFourDigits } from "../../../../utils/dateManipulation";

import { marketRefType, 
        bareOwnershipType, 
        leaseProfitabilityType, 
        rentalStateType, 
        wineryType,
        parkingAccessibilityType,
        boxType,
        boxAccessibilityType,
        boxLocationType,
        possessionType,
        condominialParkingSpaceType, 
        condominialParkingType,
        floorType, 
        ceilingHeightType,
        brightnessType,
        noisinessType,
        internalLevelsType,
        internalDistributionType,
        coveredMqRoomsRatioType,
        coveredMqBathsRatioType,
        finishingQualityType,
        status_type,
        energeticClassType,
        buildingAgeType,
        buildingLocationType,
        buildingTypologyType,
        building_status_type,
        heatingTypeType,
        office_position,
        shopFloor,
        shopVisibility,
        buildingTypologyTypeNegozi,
        windowPositionType,
        commercialVisibilityType,
        boxTypeNegozi,
        floorTypeBox,
        autorimessaType,
        boxHeight

    } from "./options";



export default function Box (props) {

  const [errors, setErrors] = useState({
    startConcession: '',
    endConcession: '',
  });

  const validateYears = (startYear, endYear) => {
    let startConcessionError = '';
    let endConcessionError = '';
  
    const isStartValid = isValidYear(startYear);
    const isEndValid = isValidYear(endYear);
  
    if (startYear && !isStartValid) {
      startConcessionError = 'Anno di inizio non valido';
    }
  
    if (endYear && !isEndValid) {
      endConcessionError = 'Anno di fine non valido';
    }
  
    if (isStartValid && isEndValid) {
      if (parseInt(endYear) < parseInt(startYear)) {
        endConcessionError = 'L\'anno di fine concessione deve essere superiore all\'anno di inizio concessione';
      }
    }
  
    setErrors({
      startConcession: startConcessionError,
      endConcession: endConcessionError,
    });
  };
  

  const handleConcessionChange = (e) => {
    const { name, value } = e.target;
    props.onChange(e);
    
    const updatedFormData = {
      ...props.formData,
      [name]: value
    };
    
    validateYears((updatedFormData.startConcession), updatedFormData.endConcession);
  };

  

    return (
        <>
        {/* MQ COPERTI */}
        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="4" activeKey={props.activeKey}>Mq coperti</CustomToggle>
            </Card.Header>
            <Accordion.Body>
              <div className="row mt-1">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq coperti <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="grossCoveredMq"  valMin={0} valMax={1} />
                    </label>
                    <input
                      type="text"
                      name="grossCoveredMqBox"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => props.handleValueChange("grossCoveredMq", 'value', e.target.value)}
                      value={props.formValues["grossCoveredMq"].value}
                    />
                  </div>
                </div>
            
              </div>
            </Accordion.Body>
        </Accordion.Item>
                
         {/* CARATTERISTICHE PRINCIPALI */}
        <Accordion.Item eventKey="8" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="8" activeKey={props.activeKey}>Caratteristiche Principali</CustomToggle>
            </Card.Header>
            <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Piano
                </label>
                <select
                  name="floorBox"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  value={props.formData.floorBox}
                  onChange={props.onChange}
                >
                  {floorTypeBox.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Facilità manovra d’accesso
                </label>
                <select
                  name="accessibilityBox"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  value={props.formData.accessibilityBox}
                  onChange={props.onChange}
                >
                  {boxAccessibilityType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          
          <div className="row mt-3">
             
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Auorimessa 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="garage" valMin={-0.05} valMax={0.05} />

                  </label>
                  <select
                  name="garage"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    onChange={(e) => props.handleValueChange("garage", 'value', e.target.value)}
                  value={props.formValues["garage"]?.value}
                  >
                    {autorimessaType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Altezza box 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="boxHeight" valMin={-0.05} valMax={0.05} />
                  </label>
                  <select
                  name="boxHeight"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    onChange={(e) => props.handleValueChange("boxHeight", 'value', e.target.value)}
                  value={props.formValues["boxHeight"]?.value}
                  >
                    {boxHeight.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Difficoltà di parcheggio in zona 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="parkingAccessibility" valMin={-0.05} valMax={0.05} />
                </label>
                <select
                  name="parkingAccessibility"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("parkingAccessibility", 'value', e.target.value)}
                  value={props.formValues["parkingAccessibility"]?.value}
                >
                  {parkingAccessibilityType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            
          </div>

   

          </Accordion.Body>
        </Accordion.Item>
        
        {/* ALTRE CARATTERISTICHE */}
        <Accordion.Item eventKey="24" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="24" activeKey={props.activeKey}>Altre caratteristiche</CustomToggle>
              </Card.Header>
                <Accordion.Body>
                <div className="row mt-1">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Larghezza entrata box in mq
                      </label>
                      <input
                        type="text"
                        name="entranceWidth"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          props.onChange(e);
                        }}
                        value={props.formData.entranceWidth}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Capienza auto
                      </label>
                      <input
                        type="text"
                        name="carCapacity"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          props.onChange(e);
                        }}
                        value={props.formData.carCapacity}
                      />
                    </div>
                  </div>
                 
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Capienza moto
                      </label>
                      <input
                        type="text"
                        name="motorcycleCapacity"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                          props.onChange(e);
                        }}
                        value={props.formData.motorcycleCapacity}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Tipo di proprietà
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="propertyType" valMin={1} valMax={100} />
                </label>
                <select
                  name="propertyType"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("propertyType", 'value', e.target.value)}
                  value={props.formValues["propertyType"]?.value}
                >
                  {possessionType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

                  
                </div>
                {
              props.formValues["propertyType"]?.value != "proprietà" &&

              <div className="row mt-3">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">            Anno inizio concessione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                  </label>
                  <input
                    type="number"
                    min="1900"
                    max="2999"
                  required
                    name="startConcession"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={handleConcessionChange}

                    onInput={(e) => {
                      validateFourDigits(e);
                    }} 
                    value={props.formData.startConcession}
                  />
                  {errors.startConcession && <span className="text-danger">{errors.startConcession}</span>}

                </div>
              </div>

              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Anno fine concessione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                  </label>
                  <input
                    type="number"
                    min="1900"
                    max="2999"
                    required
                    name="endConcession"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    onChange={handleConcessionChange}

                    onInput={(e) => {
                      validateFourDigits(e);
                    }}
                    value={props.formData.endConcession}
                  />
          {errors.endConcession && <span className="text-danger">{errors.endConcession}</span>}

                </div>
              </div>
                          </div>
            }
              </Accordion.Body>
        </Accordion.Item>
        </>
    )
}