import { useState, useEffect } from "react";
import { BsPrinter, BsPencil, BsDownload, BsTrash } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import ButtonRm from "../../ButtonRm";
import insuranceList from "../UnitàImmobiliari/insuranceList";
import { Link } from "react-router-dom";

import api, { insurance_endpoint, insurance_unit_assignment_endpoint, insurance_condo_assignment_endpoint, BASE_URL  } from "../../../api/api";
import mimeTypes from '../../../allegatiType.js'
import axios from "axios";

import CustomBadge from "../../CustomBadge";




const InsuranceModal = (props) => {
	const [insurance, setInsurance] = useState({});
	const [editMode, setEditMode] = useState(false);
	const list = insuranceList.map(str => {
		let capString = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
		let polishedString = capString.replace("*", "")
		return polishedString	
		}
	)

	const init = () => {
		if (props.insurance !== undefined) {
			setInsurance(props.insurance);
		} else {
			setInsurance({
				company: "",
				agency: "",
				billType: "",
				billNumber: "",
				reward: "",
				originalStart: "",
				withdrawalYears: "",
				deadlineReward: "",
				deadlineBill: "",
				lastPayment: "",
				nominative: "",
				coverage1: "",
				coverage2: "",
				coverage3: "",
				massimal1: "",
				massimal2: "",
				massimal3: "",
				bill1: "",
				bill2: "",
				bill3: "",
				note: "",
				attachment: [],
			});
		}
	};

	console.log(insurance)

	const billType = [
		"Furto",
		"Furto e incendi",
		"Globale fabbricato",
		"incendio",
		"Incendio e furto",
		"responsabilità civile terzi",
		"Rischio locativo",
	];

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		const {name, value} = e.target
		let convertedValue = name == "agency"? JSON.parse(value) : value 
		setInsurance({ ...insurance, [name]: convertedValue });
	};


	//SALVATAGGIO IN LOCALE O VIA API IN BASE A SE CREATE VIEW O EDIT VIEW
	const save = async (insurance) => {
		if (props.newMode && !props.editView) {
			//CREATE- CREA NUOVO
			console.log("NO PUT", props.newMode, props.editView)
			props.add(insurance)
		} else if (!props.newMode && !props.editView){
			//CREATE - EDIT 
			props.update(insurance)
		} else if (!props.newMode && props.editView && insurance.id ){
			//EDIT - UPDATE ITEM
			const {attachment, ...dataToSend} = insurance

			//GESTIONE ALLEGATI
			let formDataToSend = new FormData();
		
			attachment.forEach((file) => {
				if(typeof file != 'string'){
					const blob = new Blob([file]);
					formDataToSend.append("parts", blob, file.name)
				}
			});

			const agencyString = JSON.stringify(insurance.agency);
			const insuranceDataStringified = {
			...dataToSend,
			agency: agencyString,
			};

	
			let sentUpload = formDataToSend.get("parts") !== null;

			if (sentUpload) {
				await api().put(`${insurance_endpoint}upload/${insurance.id}`, formDataToSend);
			}

			delete insuranceDataStringified.condominiums
			await api().put(`${insurance_endpoint}${insurance.id}`, insuranceDataStringified);
			
			await props.getStabiliById()
			props.setShow(false)

		} else if (props.newMode && props.editView ){
			console.log("PUT")
			//EDIT - CREATE NEW
			const {attachment, ...dataToSend} = insurance

			//GESTIONE ALLEGATI
			let formDataToSend = new FormData();
		
			attachment.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSend.append("parts", blob, file.name);
			});

			const agencyString = JSON.stringify(insurance.agency);
			const insuranceDataStringified = {
			...dataToSend,
			agency: agencyString,
			};
			let response = await api().post(`${insurance_endpoint}`, insuranceDataStringified);

			let sentUpload = formDataToSend.get("parts") !== null;
			if (response && sentUpload) {
				await api().put(`${insurance_endpoint}upload/${response.data.id}`, formDataToSend);
			}

			if(props.condo){
				 await api().post(`${insurance_condo_assignment_endpoint}`, {
					insuranceID: response.data.id,
					condominiumID: props.formData.id,
					})
			} else {
				 await api().post(`${insurance_unit_assignment_endpoint}`, {
					insuranceID: response.data.id,
					unitID: props.formData.id,
					})
				}
			}	
			await props.getStabiliById()
			props.setShow(false)

	};

	const setFileName = (index, new_name) => {
		let temp = [...insurance.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setInsurance({ ...insurance, ["attachment"]: temp });
	};

	const deleteFile = (index) => {
		let temp = [...insurance.attachment];
		temp.splice(index, 1);
		setInsurance({ ...insurance, ["attachment"]: temp });
	};
	const handleChange = (e) => {
		let temp = [...insurance.attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		setInsurance({ ...insurance, ["attachment"]: temp });
	};
	const handleDelete = async () => {
		if(props.editView && insurance.id){
			await api().delete(`${insurance_endpoint}${insurance.id}`)
			await props.getStabiliById()
			props.setShow(false)

		} else if(!insurance.id) {
			props.delete()
		} else {
			props.delete()
		}
	};
	

	const downloadFile = async (name) => {
		const fileUrl = `${BASE_URL}api/v1/insurance/download/${insurance.id}/${name}`;
	
		const response = await axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${
			JSON.parse(localStorage.getItem("user"))?.token
			}`,
			"Content-Type": `application/json`,
			},
		});
	
		// ottieni l'estensione del file
		const fileExtension = name.split('.').pop();
	
		const mimeType = mimeTypes[fileExtension];
	
		// crea un nuovo Blob con il tipo MIME corretto
		const file = new Blob([response.data], { type: mimeType });
	
		// crea un URL per il Blob
		const fileURL = URL.createObjectURL(file);
	
		// scarica il file
		window.open(fileURL);
	};
	return (
		<div
			className={
				props.editMode === undefined ? "mt-4" : "edit-content-container"
			}
			style={{maxHeight: "400px", overflowY: "auto", borderRadius:".5rem", overflowX:"hidden"}}
			>
			{props.newMode ? (
				<div className="fs-18 fw-semibold">Nuova assicurazione</div>
			) : (
				<div className="d-flex justify-content-between">
					<div className="fs-18 fw-semibold">
						{editMode ? "Modifica Assicurazione" : "Visualizza assicurazione"}
					</div>

					<div className={`d-flex ${editMode ? "d-none" : ""}`}>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsDownload color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
							onClick={() => {
								setEditMode(true);
							}}>
							<BsPencil color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="gray" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40"
							onClick={handleDelete}>
							<BsTrash color="gray" />
						</button>
					</div>
				</div>
			)}
			<div className="d-block">
				<fieldset disabled={editMode || props.newMode ? false : true}>
					<div className="label-heading mb-3 d-block w-100 mt-3">
						<div className="label-text rel d-inline-block px-2 title-padding">
							<h1 className="fs-16 text-white text-uppercase ">DATI POLIZZA</h1>
						</div>
					</div>
					<div className="row d-flex flex-column gy-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Compagnia*
								</label>
								<select
									required
									name="company"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
									value={insurance.company}
									onChange={onChange}>
									{list.map((menu, index) => {
                        return (
							<option key={index} value={menu}>
								{menu}
							</option>
                        );
                      })}
								</select>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Agenzia*
								</label>
								<div className="d-flex align-items-center justify-content-between">
									<div className="flex-auto">
										<select
											required
											name="agency"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100"
											value={JSON.stringify(insurance.agency)}
											onChange={onChange}>
											<option></option>
											 {props.supplierList?.map((item, index) => {
                        return (
                          <option key={index} value={JSON.stringify(item)}>
                            {item.name} {item.surname} - {item.businessName}
                          </option>
                        );
                      })} 
										</select>
									</div>
									<div style={{ padding: "3px" }}>
									<Link to="/fornitori/create" className="text-dark">
										<BsPlusCircleFill
											className="add-section-btn"
											// onClick={addParcel}
										/>
										
									</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row   d-flex flex-column gy-2">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Tipo di polizza*
								</label>
								<select
									name="billType"
									required
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
									value={insurance.billType}
									onChange={onChange}>
										<option></option>
									{billType.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Polizza numero
								</label>
								<input
									type="text"
									name="billNumber"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={insurance.billNumber}
								/>
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Premio*
								</label>
								<input
									required
									type="number"
									name="reward"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={insurance.reward}
								/>
							</div>
						</div>
					</div>
					<div className="label-heading mb-3 d-block w-100 mt-3">
						<div className="label-text rel d-inline-block px-2 title-padding">
							<h1 className="fs-16 text-white text-uppercase ">DATE</h1>
						</div>
					</div>
					<div className="row ">
						<div className="col-md-12 mb-2">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Inizio originale*
								</label>
								<input
									required
									name="originalStart"
									value={insurance.originalStart}
									onChange={onChange}
									className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
						<div className="col-md-12 mb-2">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Recesso in anni</label>
								<input
								type="number"
								name="withdrawalYears"
								value={insurance.withdrawalYears}
								onChange={
									onChange
								}></input>
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-md-12  mb-3">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Scadenza premio
								</label>
								<input
									name="deadlineReward"
									value={insurance.deadlineReward}
									onChange={onChange}
									className="fs-16 text-dark border border-secondary  rounded-3 border-opacity-50"
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
						<div className="col-md-12  mb-3">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Scadenza polizza*
								</label>
								<input
									required
									name="deadlineBill"
									value={insurance.deadlineBill}
									onChange={onChange}
									className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Ultimo pagamento
								</label>
								<input
									name="lastPayment"
									value={insurance.lastPayment}
									onChange={onChange}
									className="fs-16 text-dark border border-secondary px-3 rounded-3 border-opacity-50"
									max="9999-12-31"
									type="date"></input>
							</div>
						</div>
					</div>
					<div className="label-heading mb-3 d-block w-100 mt-3">
						<div className="label-text rel d-inline-block px-2 title-padding">
							<h1 className="fs-16 text-white text-uppercase ">CONTRAENTI</h1>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Nominativi (opzionale)
								</label>
								<input
									type="text"
									name="nominative"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={insurance.nominative}
								/>
							</div>
						</div>
					</div>
					<div className="label-heading mb-3 d-block w-100 mt-3">
						<div className="label-text rel d-inline-block px-2 title-padding">
							<h1 className="fs-16 text-white text-uppercase ">
								CAPITALI ASSICURATIVI
							</h1>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Copertura
								</label>
								<input
									type="text"
									name="coverage1"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={insurance.coverage1}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Massimale
										</label>
										<input
											type="text"
											name="massimal1"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											onChange={onChange}
											value={insurance.massimal1}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Polizza
										</label>
										<input
											type="text"
											name="bill1"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											onChange={onChange}
											value={insurance.bill1}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Copertura
								</label>
								<input
									type="text"
									name="coverage2"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={insurance.coverage2}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Massimale
										</label>
										<input
											type="text"
											name="massimal2"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											onChange={onChange}
											value={insurance.massimal2}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Polizza
										</label>
										<input
											type="text"
											name="bill2"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											onChange={onChange}
											value={insurance.bill2}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Copertura
								</label>
								<input
									type="text"
									name="coverage3"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={insurance.coverage3}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Massimale
										</label>
										<input
											type="text"
											name="massimal3"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											onChange={onChange}
											value={insurance.massimal3}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Polizza
										</label>
										<input
											type="text"
											name="bill3"
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
											onChange={onChange}
											value={insurance.bill3}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="label-heading mb-3 d-block w-100 mt-3">
						<div className="label-text rel d-inline-block px-2 title-padding">
							<h1 className="fs-16 text-white text-uppercase ">NOTE</h1>
						</div>
					</div>
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Note
						</label>
						<input
							type="text"
							name="note"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							onChange={onChange}
							value={insurance.note}
						/>
					</div>

					<div className="label-heading mb-3 d-block w-100 mt-3">
						<div className="label-text rel d-inline-block px-2 title-padding">
							<h1 className="fs-16 text-white text-uppercase ">ALLEGATI</h1>
						</div>
					</div>
					{(editMode || props.newMode) && <label className="black-link mb-3" htmlFor="input-file">
						<BsPlusCircleFill className="plus-icon" />
						Aggiungi allegati
					</label>}
					<input
						type="file"
						id="input-file"
						className="d-none"
						multiple={true}
						name="attachment"
						onChange={handleChange}></input>
						{insurance.attachment?.length > 0 &&
							insurance.attachment.map((item, index) => {
								return (
									<FileCard
										key={index}
										name={item}
										setFileName={setFileName}
										deleteFile={deleteFile}
										id={index}
										download={downloadFile}
									/>
								);
						})}
				</fieldset>
			</div>
			{editMode || props.newMode ? (
				
					<div className="d-flex align-items-center mt-5 form-last-btns justify-content-center mb-4">
					<ButtonRm variant="tertiary" onClick={() => {
								props.setShow(false);
							}} className="me-2" >Annulla</ButtonRm>
					<ButtonRm variant={"secondary"} onClick={()=>save(insurance)}>Salva</ButtonRm>
				  </div>
			) : (
				""
			)}
		</div>
	);
};

export default InsuranceModal;
