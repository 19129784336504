import { GET_GESTIONI, EDIT_GESTIONE, CHANGE_GESTIONE_STATUS, DELETE_GESTIONE, ADD_GESTIONE } from "./types";
import api from '../api/api'
import { management_endpoint, status_management_endpoint } from "../api/api";



/* ACTION CREATORS */
export const fetchGestioni =  ()  =>  async (dispatch)=> {
    const response = await api().get(`${management_endpoint}`)
    dispatch({
        type: GET_GESTIONI,
        payload: response.data?.content
    })
}

export const addGestione =  (data)  =>  async (dispatch)=> {
    const response = await api().post(`${management_endpoint}`, data)
    dispatch({
        type: ADD_GESTIONE,
        payload: data
    })
}

export const editGestione =  (id, data)  =>  async (dispatch)=> {
    const response = await api().put(`${management_endpoint}${id}`, data)
    dispatch({
        type: EDIT_GESTIONE,
        payload: {
                    data, 
                    id
                }
    })
}

export const removeGestione =  (id)  =>  async (dispatch)=> {
    const response = await api().delete(`${management_endpoint}${id}`)
    dispatch({
        type: DELETE_GESTIONE,
        id: id
    })
}

export const changeStatus =  (id)  =>  async (dispatch)=> {
    const response = await api().put(`${status_management_endpoint}${id}`)
    dispatch({
        type: CHANGE_GESTIONE_STATUS,
    })
}


/* REDUCER */

const initialState = {
    gestioni: [],
}

const gestioniReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_GESTIONI:
            return {...state, 
                    gestioni: action.payload
                }
        case ADD_GESTIONE:
            return {
                ...state,
                gestioni: [...state.gestioni, action.payload]
            }
        case EDIT_GESTIONE:
            const newData = action.payload.data
            const updatedGestione = state.gestioni && state.gestioni.length > 0?
            state.gestioni.map((gestione) => {
                if (gestione.id === action.payload.id) {
                return {...gestione, newData}
                } else {
                return gestione;
                }
            }) : []
            

            return  {
                ...state,
                gestioni: updatedGestione
            }

        case DELETE_GESTIONE:
            const gestioni = state.gestioni.filter( gestione => gestione.id !== action.id)
            return  {
                ...state,
                gestioni
            }
        default:
            return state
    }
}

export default gestioniReducer