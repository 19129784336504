import React, { useState } from "react";
import "../../../../assets/css/Membri.css";
import { BsCalendar2Event } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import CustomBadge from "../../../CustomBadge";


export default function Membro(props) {
  const [checked, setChecked] = useState(false);
  const [linkShow, setLinkShow] = useState(false);
  const [show, setShow] = useState(false);

  const categoriaChange = (e) => {
    if (e.target.value === "utenze - energia") {
      setLinkShow(true);
    } else {
      setLinkShow(false);
    }
  };

  return (
    <div
      className="d-block w-100 mb-3 bg-white"
      style={{ borderRadius: "16px" }}
    >
      <Form>
        <div className="form-item-align-box d-block pt-5 pb-5">
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Membro
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row gy-4 mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Nominativo
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 mt-5 tariffari-link-div"
            style={{ display: linkShow ? "" : "none" }}
            onClick={() => setShow(true)}
          >
            Visualizza elenco dei piani tariffari
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
              <button
                type="button"
                className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
                onClick={() => props.back()}
              >
                Indietro
              </button>
              <button
                type="button"
                className="next-btn btn-last rounded-3 shadow w-193 border-0"
                onClick={() => props.next()}
              >
                Avanti
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
