import requestSvg from "../../../assets/images/status-card-request.svg"
import progressSvg from "../../../assets/images/status-card-progress.svg"
import successSvg from "../../../assets/images/status-card-success.svg"
import delaySvg from "../../../assets/images/status-card-delay.svg"

import StatusCard from "./StatusCard"

import { Row } from "react-bootstrap"

//this component refers to StatoLavoriInCorso/Bacheca
//here you should pass data related to activity_number filtered by status (request, in progress, solved, delayed)

export default function StatusCardGroup(props){
    let todayDate = new Date().toISOString().slice(0, 10);

    const activitiesByStatus = props.activities.map(item => {
        const startDate = item.startDate?.substr(0, 10);
        const expirationDate = item.expirationDate?.substr(0, 10);
        const endDate = item.end_date ? item.endDate.substr(0, 10) : "";
        let status;
        if (todayDate < startDate?.split(",").join("-")) {
            status = "aperta";
        }   else if (todayDate >= startDate?.split(",").join("-") && todayDate <= expirationDate?.split(",").join("-")) {
            status = "incorso";
        }   else if (item.endDate) {
            status = "risolta";
        }   else if (todayDate > expirationDate?.split(",").join("-")) {
            status = "inritardo";
        }
        return status
    })

    const values = ["aperta", "incorso", "risolta", "inritardo"];
    let count = {};
    values.forEach(value => count[value] = 0);

    activitiesByStatus.forEach(item => {
        if (values.includes(item)) {
            count[item] += 1;
        }
    });

    return (
        <Row className="mt-3 flex-wrap mx-auto p-0 gx-md-3">
                <StatusCard 
                    icon={requestSvg}            
                    activity_number={count["aperta"]}
                    status_name="Richieste Attività"
                />
                <StatusCard 
                    icon={progressSvg}       
                    activity_number={count["incorso"]}
                    status_name="Attività in corso"
                />
                <StatusCard 
                    icon={successSvg}            
                    activity_number={count["risolta"]}
                    status_name="Attività Risolte"
                />
                <StatusCard 
                    icon={delaySvg}            
                    activity_number={count["inritardo"]}
                    status_name="Attività in ritardo"
                />
        </Row>
    )
}