import React, { useRef, useState } from "react";
import { BsCalendar2Event } from "react-icons/bs";
import PrintDwonloadIcons from "./PrintDwonloadIcons";
import { formatItalianDate, formatDateFromString } from "../../../../utility";

import Form from "react-bootstrap/Form";
import CustomToggle from "../../../CustomToggle";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import {  BsPrinter } from "react-icons/bs";
import translationMapping from '../../mapping'
import ExportButton from "../../Fornitori/ExportButton";

import CustomBadge from "../../../CustomBadge";

export default function DocumentoV(props) {
  const componentRef = useRef();

  const [viewMode,setViewMode] = useState(true)
  /* GESTISCE L'ELEMENTO ACCORDION ATTIVO */
  const [activeKey, setActiveKey] = useState('');

  const item = {
    "documentType": props.formData.documentType,
    "documentNumber": props.formData.documentNumber,
    "documentReleaseEntity": props.formData.documentReleaseEntity,
    "documentReleaseDate": props.formData.documentReleaseDate, 
    "documentDeadline": props.formData.documentDeadline,
    "citizenship": props.formData.citizenship
  }
  

  return (
    <>
       <div className="d-flex mb-3 justify-content-end mt-3 me-3">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					
					<ExportButton data={item} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
      <fieldset disabled={viewMode} ref={componentRef}>
        <div className="form-item-align-box d-block pb-5">
          <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="0" activeKey={activeKey}>Documento di riconoscimento</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Tipo di documento
                        </Form.Label>
                        <Form.Select 
                          // {...register("documentType")}
                          name="documentType"
                          value={props.formData.documentType}
                          // onChange={onChange}
                          // isInvalid={!!errors.documentType}
                        >
                          <option value="carta d’identità">Carta d’identità</option>
                          <option value="passaporto">Passaporto</option>
                          <option value="patente di guida">Patente di guida</option>
                          <option value="patente nautica">Patente nautica</option>
                        </Form.Select>
                        {/* <Form.Control.Feedback type="invalid" >
                          {errors.documentType?.message}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Numero
                        </Form.Label>
                        <Form.Control 
                          // {...register("documentNumber")}
                          type="text" 
                          name="documentNumber"
                          value={props.formData.documentNumber}
                          // onChange={onChange}
                          // isInvalid={!!errors.documentNumber}
                        />
                        {/* <Form.Control.Feedback type="invalid" >
                        {errors.documentNumber?.message}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    
                  </Row>

                  <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Rilasciato dal/la
											</Form.Label>
											<Form.Control 
												// {...register("documentReleaseEntity")}
												type="text" 
                        name="documentReleaseEntity"
                        value={props.formData.documentReleaseEntity}
												// onChange={onChange}
												// isInvalid={!!errors.documentReleaseEntity}
											/>
											{/* <Form.Control.Feedback type="invalid" >
											{errors.documentReleaseEntity?.message}
											</Form.Control.Feedback> */}
										</Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Data di rilascio
                        </Form.Label>
                        <Form.Control
                          // {...register("documentReleaseDate")}
                          type="date"
                          name="documentReleaseDate"
                          defaultValue={
                            props.formData.documentReleaseDate &&
                            formatDateFromString( props.formData.documentReleaseDate)
                          }
                          
                          // onChange={onChange}
                          // isInvalid={!!errors.documentReleaseDate}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid" >
                        {errors.documentReleaseDate?.message}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Data di scadenza
                        </Form.Label>
                        <Form.Control
                          //{...register("documentDeadline")}
                          type="date"
                          name="documentDeadline"
                          defaultValue={
                            props.formData.documentDeadline &&
                            formatDateFromString( props.formData.documentDeadline)
                          }
                          // onChange={onChange}
                          // isInvalid={!!errors.documentDeadline}
                        >
                        </Form.Control>
                        {/* <Form.Control.Feedback type="invalid" >
                        {errors.documentDeadline?.message}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Cittadinanza
                        </Form.Label>
                        <Form.Control 
                          // {...register("citizenship")}
                          type="text" 
                          name="citizenship"
                          value={props.formData.citizenship}
                          // onChange={onChange}
                          // isInvalid={!!errors.citizenship}
                        />
                        {/* <Form.Control.Feedback type="invalid" >
                        {errors.citizenship?.message}
                        </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </div>
      </fieldset>
    </>
  );
}
