import { useState, useEffect } from "react";
import {
	BsPrinter,
	BsDownload,
	BsFilter,
	BsTrash,
	BsPencil,
} from "react-icons/bs";
import EmptyImg from "../../../assets/images/policy_empty.png";
import ProviderCard from "./Edit/ProviderCard";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { BsPlusCircleFill } from "react-icons/bs";
import NumericInput from "react-numeric-input";
import ProviderModal from "./Edit/ProviderModal";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import Alert from 'react-bootstrap/Alert';
import cardimage from "../../../assets/images/allegati-ph.svg";
import fornimage from "../../../assets/images/fornitore-ph.svg";
import InsuranceModal from "../Stabili/InsuranceModal";
import ButtonRm from "../../ButtonRm";
import api, { supplier_endpoint } from "../../../api/api";
import SubjectCard from "../../Documenti/prima_nota/Tipologia/SubjectCard";
import SupplierModal from "../../Documenti/prima_nota/Tipologia/SupplierModal";
import { Link } from "react-router-dom";
import { current } from "@reduxjs/toolkit";



const Lists = (props) => {
	/* const [data, setData] = useState({
		suppliers:  props.formData.suppliers,
		insurances: props.formData.insurances,
	}); */
	const [activities, setActivities] = useState([])



/* 	const  init = () => {
		setData({
			suppliers:  [],
			insurances: [],
		});
	};
 */

	/* const [insurance, setInsurance] = useState({});

	const initInsurance = () => {
		setInsurance({
			company: "",
			agency: "",
			billType: "",
			billNumber: "",
			reward: "",
			originalStart: "",
			withdrawalYears: "",
			deadlineReward: "",
			deadlineBill: "",
			lastPayment: "",
			nominative: "",
			coverage1: "",
			coverage2: "",
			coverage3: "",
			massimal1: "",
			massimal2: "",
			massimal3: "",
			bill1: "",
			bill2: "",
			bill3: "",
			note: "",
			attachment: [],
		});
	};
 */
	/* useEffect(() => {
		init();
	}, [props]); */

	/* useEffect(() => {
		initInsurance();
	}, [data.insurances]); */

	/* const insuranceChange = (e) => {
		setInsurance({ ...insurance, [e.target.name]: e.target.value });
	}; */

	const [fullMode, setFullMode] = useState(false);

	/* const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		props.update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */

	const onChange = (e) => {
		props.setFormData({ ...props.formData, [e.target.name]: e.target.value });
	};

	const billType = [
		"Furto",
		"Furto e incendi",
		"Globale fabbricato",
		"incendio",
		"Incendio e furto",
		"responsabilità civile terzi",
		"Rischio locativo",
	];

	const table_data = [
		["Idraulico", "Dario Verdi", "010384488", "Ditta individuale"],
		["Notaio", "Gaetani Aldi", "018283u9", "Ditta individuale"],
	];

	/* const addProvider = (id) => {
		let temp = [...data.suppliers];
		let tempData = {};
		tempData.category = table_data[id][0];
		tempData.name = table_data[id][1];
		tempData.homeTelephone = table_data[id][2];
		tempData.subjectType = table_data[id][3];
		temp.push(tempData);
		setData({ ...data, ["suppliers"]: temp });
	};

	const remove = (id) => {
		let temp = [...data.suppliers];
		temp.splice(id, 1);
		setData({ ...data, ["suppliers"]: temp });
	}; */

	const [tabState, setTabState] = useState(0);

	const [newInsurance, setNewInsurance] = useState(false);

	const back = () => {
		if (insuranceEdit !== -1) {
			setInsuranceEdit(-1);
		} else if (newInsurance === true) {
			setNewInsurance(false);
		} else setFullMode(false);
	};


	/* const handleInsuranceEdit = (id) => {
		setInsuranceEdit(id);
		setInsurance({ ...data.insurances[id] });
	};

	const saveInsurance = () => {
		setNewInsurance(false);
		let temp = [...data.insurances];
		temp.push(insurance);
		setData({ ...data, ["insurances"]: temp });
	}; */

	const [insuranceSubPageEdit, setInsuranceSubPageEdit] = useState(false);

	const [providerModalShow, setProviderModalShow] = useState(false);

/* 	const setFileName = (index, new_name) => {
		let temp = [...insurance.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setInsurance({ ...insurance, ["attachment"]: temp });
	};

	const deleteFile = (index) => {
		let temp = [...insurance.attachment];
		temp.splice(index, 1);
		setInsurance({ ...insurance, ["attachment"]: temp });
	};

	const handleChange = (e) => {
		let temp = [...insurance.attachment];
		temp = [...temp, ...e.target.files];
		setInsurance({ ...insurance, ["attachment"]: temp });
	};

	const [filter, setFilter] = useState(""); */

    //GESTIONE ASSICURAZIONI

    const [show, setShow] = useState(false);

    const addInsurance = (insurance) => {
      let temp = [...props.formData.insurances];
      temp.push(insurance);
      let e = { target: { name: "insurances", value: temp } };
      onChange(e);
      setShow(false);
    };

    //GESTIONE FORNITORI

    const [supplierList, setSupplierList] = useState([]);

	const addSelectedSuppliers = (id) => {
		let filteredList = props.formData.suppliers.filter(
			(supplier) => supplier.id === id
		);
		filteredList.length === 0 &&
			props.formData.suppliers.push(
				supplierList.filter((supplier) => supplier.id === id)[0]
			);
		props.setFormData({
			...props.formData,
			suppliers: props.formData.suppliers,
		});

        setProviderModalShow(false)
	};

  /*   const addInsurance = () => {
		let list  = props.formData.insurances.push(
            insurance
			);
		props.setFormData({
			...props.formData,
			insurances: list,
		});

        setShow(false)
	}; */

	const removeSelectedSupplier = (id) => {
		props.setFormData({
			...props.formData,
			suppliers: props.formData.suppliers.filter((supplier) => supplier.id !== id),
		});
	};

	const fetchSuppliers = async () => {
		try {
			const res = await api().get(`${supplier_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setSupplierList(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchSuppliers();
	}, []);


	//VIEW EDIT INSURANCE CREATED

	const [showSelectedInsurance, setShowSelectedInsurance] = useState(false)
	const [selectedInsurance, setSelectedInsurance] = useState({})
	const [insuranceEdit, setInsuranceEdit] = useState(-1);


	function closeSelecteInsurance(){
		setShowSelectedInsurance(false)
		setSelectedInsurance({})
		setInsuranceEdit(null)

	}

	function handleInsurance(id){
		setShowSelectedInsurance(true)
		let current =  props.formData.insurances.find((item, index) => index == id)
		setSelectedInsurance(current)
		setInsuranceEdit(id)
	}


	function updateInsurance (insurance){
		let temp =  props.formData.insurances.map((item, index) => {
			return index == insuranceEdit?
			insurance:
			item
		})
		props.setFormData({ ...props.formData, ["insurances"]: temp });
		closeSelecteInsurance()
	};

	const deleteInsurance = () => {
		let temp =  props.formData.insurances.filter((item, index) => index != insuranceEdit)
		props.setFormData({ ...props.formData, ["insurances"]: temp }); 
		closeSelecteInsurance()
	};

	//AGGIORNA FORM ORIGINALE CON I DATI ASSICURATIVI
/* 	 useEffect(() => {
		  props.setFormData({
			...props.formData,
			insurances: data.insurances,
		  });
	  }, [data.insurances]); 
*/
	console.log( props.formData.insurances) 
  
	return (
		<div>
			<div className="form-item-align-box-white d-block">
				<div className={`tab-state `}>
					<div className="d-flex justify-content-center">
						<div
							className="tab-container"
							onClick={(e) => {
								setTabState(0);
							}}>
							<label className={tabState === 0 ? "" : "non-active"}>
								Fornitori
							</label>
							<div
								className={`tab-bar ${
									tabState === 0 ? "active-tab-bar-create" : ""
								}`}></div>
						</div>
						<div
							className="tab-container"
							onClick={(e) => {
								setTabState(1);
							}}
							style={{ marginLeft: "-5px" }}>
							<label className={tabState === 1 ? "" : "non-active"}>
								Lavori
							</label>
							<div
								className={`tab-bar ${
									tabState === 1 ? "active-tab-bar-create" : ""
								}`}></div>
						</div>
						<div
							className="tab-container"
							style={{ marginLeft: "-10px" }}
							onClick={(e) => {
								setTabState(2);
							}}>
							<label className={tabState === 2 ? "" : "non-active"}>
								Assicurazioni
							</label>
							<div
								className={`tab-bar ${
									tabState === 2 ? "active-tab-bar-create" : ""
								}`}></div>
						</div>
					</div>
				</div>
				<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
                <div>
			<div className="d-flex justify-content-start">
				<Link to="/fornitori/create">
					<button type="button" className="general-btn white-btn  me-2">
						Nuovo
					</button>
				</Link>
				<button
					type="button"
					className="general-btn white-btn"
					onClick={(e) => {
						setProviderModalShow(true)
					}}>
					Seleziona
				</button>
			</div>

			{props.formData.suppliers.length > 0 ? (
				props.formData.suppliers.map((supplier) => (
					<SubjectCard
						key={supplier.id}
						title={
							supplier.name +
							" " +
							supplier.surname +
							": " +
							supplier.businessName
						}
						id={supplier.id}
						table_data={[
							supplier.category,
							supplier.subjectType,
							supplier.cellularTelephone,
						]}
						className="mt-4"
						remove={removeSelectedSupplier}
					/>
				))
			) : (
				<div className="col-12 mt-5">
					<div className="mx-auto d-table text-center">
						<div className="image-user mx-auto d-table w-160 fw-100">
							<img loading="lazy" src={fornimage} alt="user-key" />
						</div>
						<p className="fs-20 text-secondary text-center">
							Nessun fornitore associato
						</p>
					</div>
				</div>
			)}
	
		</div>
				</div>
				<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>
					<div >
						<div className="  mt-3">
                            <Alert variant="warning" className="d-flex justify-content-start align-items-center fs-14" style={{height:"50px"}}>
                            <div className="d-flex">
                                <i class="bi bi-info-circle me-2"></i> 
                                Puoi aggiungere una nuova attività dalla sezione Stato avanzamento lavori (SAL) dopo la creazione dell'unità
                            </div>
                            </Alert>
                            <div className="unit-empty-img">
                                <img loading="lazy" src={cardimage} alt="No image" />   
                                <span className="img-comment">Nessun lavoro selezionato</span>
                            </div>
                        </div>
                    </div>
			
				</div>
				<div className={` ${tabState === 2 ? "" : "d-none"}`}>
                <div>
      <div className="d-flex mt-3">
        <button
          type="button"
          className="general-btn white-btn w-144 me-2"
          onClick={() => {
            setShow(true);
          }}
        >
          Nuovo
        </button>
      </div>
      { props.formData.insurances?.length > 0 ? (
        <div className="mt-4">
          <Table className="result-table table-border out-border-none">
            <thead>
              <tr>
                <th>Tipo di polizza</th>
                <th>Compagnia</th>
                <th>Inizio originale</th>
                <th>Scadenza Polizza</th>
                <th>Premio</th>
              </tr>
            </thead>
            <tbody>
              { props.formData.insurances.map((item, index) => {
                return (
                  <tr key={index} onClick={() => handleInsurance(index)}>
                    <td>{item.billType}</td>
                    <td>{item.company}</td>
                    <td>{new Date(item.originalStart).toLocaleDateString()}</td>
                    <td>{new Date(item.deadlineBill).toLocaleDateString()}</td>
                    <td>{item.reward}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="unit-empty-img">
          <img loading="lazy" src={EmptyImg} alt="No image" />
          <span className="img-comment fs-18">
            Non hai aggiunto ancora alcuna assicurazione
          </span>
        </div>
      )}
      <Modal show={show}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={() => {
              setShow(false);
            }}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
          <InsuranceModal supplierList={supplierList}  setShow={setShow} newMode={true} add={addInsurance} />
        </Modal.Body>
      </Modal>
    </div>
				</div>
				{/* <div
					className={`d-flex justify-content-end mt-3 ${
						fullMode ? "d-none" : ""
					}`}>
					<div
						className="edit-btn w-144"
						onClick={() => {
							setFullMode(true);
						}}>
						Visualizza
					</div>
				</div> */}
			</div>
			{fullMode ? (
				<div className="back-btn-1" onClick={back}>
					<i className="fal fa-long-arrow-left" />
				</div>
			) : (
				""
			)}
			<Modal
				show={providerModalShow}
				className="type-modal"
				onHide={() => {
					setProviderModalShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setProviderModalShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<SupplierModal
						table_data={
							supplierList.length > 0 &&
							supplierList.map((supplier) => [
								supplier.category,
								supplier.businessName,
								supplier.cellularTelephone,
								supplier.subjectType,
							])
						}
						ids={supplierList.map((supplier) => supplier.id)}
						setShow={setShow}
						addSupplier={addSelectedSuppliers}
					/>
				</Modal.Body>
			</Modal>
            <div className="d-flex justify-content-end mt-5">						
				<button
					type="button"
					className="general-btn white-btn me-2 w-193"
					onClick={() => props.backward()}>
					Indietro
				</button>
				<button
					type="button"
					className="general-btn pink-btn w-193"
					onClick={() => props.forward()}>
					Avanti
				</button>
			</div>
		
		{/* VIEW ASSICURAZIONE */}
		<Modal show={showSelectedInsurance}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={closeSelecteInsurance}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
		  <InsuranceModal supplierList={supplierList} setShow={closeSelecteInsurance} insurance={selectedInsurance}  newMode={false} add={updateInsurance} delete={deleteInsurance}/>
        </Modal.Body>
      </Modal>

			
			
		</div>

	);
};

export default Lists;
