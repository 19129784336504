import ButtonRm from "../../../ButtonRm"
import CarouselCardPartner from "./CarouselCardPartner"
import CustomBadge from "../../../CustomBadge"
import { Modal, Form, Col, Row, Image, OverlayTrigger, Tooltip } from "react-bootstrap"

function Portfolio({
    editPortfolio,
    setShowPortfolioCreate,
    setEditPortfolio,
    form,
    visibleCards,
    handleCurrentPortfolioProject,
    handleEditPortfolioProject,
    handleDeleteProject,
    showAll,
    handleShowAll,
    handleHideAll,

    showPortfolioProject,
    setShowPortfolioProject,
    setCurrentPortfolioProject,
    currentPortfolioProject,

    showEditPortfolioProject,
    setShowEditPortfolioProject,
    setCurrentPortfolioProjectId,
    handleEditProjectForm,
    removePortfolioImg,
    updateProject,

    showDeletePortfolioProject,
    deleteProject,
    setShowDeletePortfolioProject,
    hideDeletePortfolio,

    saveNewProject,
    newProject,
    removeImg,
    handleChange,
    handleNewProject,
    showPortfolioCreate
}) {

    console.log("portfoliooooo")
    return (
        <>
        <div className="pagina-categories mt-3"> 
                <p className="fs-24 fw-bold mt-0" style={{ lineHeight:'1.2'}}>Portfolio</p>
                {editPortfolio && <ButtonRm variant="outline" size="small" className="my-2"
                    onClick={()=> setShowPortfolioCreate(true)}
                >Aggiungi progetto</ButtonRm>}
                <div className="edit-icon-pagina rounded-circle w-50 h-50" onClick={() => setEditPortfolio(!editPortfolio)}>
                    {editPortfolio? <i className="bi bi-check2-circle"></i> : <i className="bi bi-pen"></i>}
                </div>
                <div className="pagina-categories-carousel p-0">
                    {form?.portfolios?.length > 0 && form.portfolios.slice(0, visibleCards).map((el, index) => (
                        <CarouselCardPartner 
                            category={el.title} 
                            key={index}
                            id={el.id}
                            imgName={el.image} 
                            handleCurrentPortfolioProject={handleCurrentPortfolioProject}
                            handleEdit={handleEditPortfolioProject}
                            handleDelete={handleDeleteProject}
                            editPortfolio={editPortfolio}
                        />
                    ))}
                    {form?.portfolios?.length == 0 && <div className={`d-flex justify-content-center align-items-center w-100 ${editPortfolio? 'mt-3' : 'mt-5 pt-3'}`}>Nessun progetto</div>
                    }
                </div>
                {form?.portfolios?.length > 3 && <div className="p-0 text-center">
                    {!showAll && form?.portfolios?.length > visibleCards && (
                        <ButtonRm
                            variant="text"
                            size="small"
                            className="my-2 mx-auto"
                            onClick={handleShowAll}
                        >
                            Mostra tutto
                        </ButtonRm>
                    )}
                    {showAll && (
                        <ButtonRm
                            variant="text"
                            size="small"
                            className="my-2 mx-auto"
                            onClick={handleHideAll}
                        >
                            Nascondi
                        </ButtonRm>
                    )}
                </div>}
            </div>

            

            </>
    )
}

export default Portfolio