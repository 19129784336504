import Form from "react-bootstrap/Form";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getProvince, getMunicipality } from "../../actions/addressAction";

import CustomBadge from "../CustomBadge";

export default function AccountModal(props) {
  const type = ["conto corrente bancario", "cassa"];
  return (
    <>
      <div className="d-block w-100 mb-3 mt-4">
        <h2 className="fs-20 text-dark mb-2 fw-bold">
          Nuova risorsa contabile
        </h2>
      </div>
      <Form>
        <div
          className={`form-item-align-box d-block ${
            props.edit_mode !== undefined ? "edit-content-container" : ""
          }`}
        >
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Nome
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Tipo
                </label>
                <select className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white">
                  {type.map((menu, index) => {
                    return (
                      <option key={index} value={index}>
                        {menu}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="label-heading mb-3 d-block w-100 mt-4">
            <div className="label-text rel d-inline-block px-2 title-padding">
              <h1 className="fs-16 text-white text-uppercase">
                DATI DEL CONTO
              </h1>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  IBAN
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Provincia
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Intestazione
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="d-flex flex-column col-sm-2 col-md-2">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    ABI
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
                <div className="d-flex flex-column col-sm-2 col-md-2">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    CAB
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
                <div className="d-flex flex-column col-sm-3 col-md-3">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    CONTO
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
                <div className="d-flex flex-column col-sm-2 col-md-2">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    CIN
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
                <div className="d-flex flex-column col-sm-3 col-md-3">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    BIC/SWIFT
                  </label>
                  <input
                    type="text"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Banca
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Codice SIA
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Agenzia/Comune
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Codice CUC SEPA
                </label>
                <input
                  type="text"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="d-flex align-items-center justify-content-end form-last-btns">
              <button
                type="button"
                className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
                onClick={() => {
                  props.setShow(false);
                }}
              >
                Annulla
              </button>
              <button
                type="button"
                className="next-btn btn-last rounded-3 shadow w-193 border-0"
              >
                Salva
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
