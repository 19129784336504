export const SET_SHOW = "SET_SHOW";
export const CLEAR_SHOW = "CLEAR_SHOW";
export const SET_ENTERSHOW = "SET_ENTERSHOW";
export const CLEAR_ENTERSHOW = "CLEAR_ENTERSHOW";
export const SET_NAME = "SET_NAME";
export const SET_SEARCH = "SET_SEARCH";
export const USER_TEMPLATE = "USER_TEMPLATE";
export const MOVEMENT_TYPE = "MOVEMENT_TYPE";
export const AFFITTI = "AFFITTI";
export const CONDUCTOR = "CONDUCTOR";
export const ADD_UNIT = "ADD_UNIT";
export const REMOVE_UNIT = "REMOVE_UNIT";

/* GESTIONI */
export const ADD_GESTIONE = "ADD_GESTIONE";
export const GET_GESTIONI = "GET_GESTIONI";
export const DELETE_GESTIONE = "DELETE_GESTIONE";
export const EDIT_GESTIONE = "EDIT_GESTIONE";
export const CHANGE_GESTIONE_STATUS = "CHANGE_GESTIONE_STATUS";

/* SCADENZARIO */
export const ADD_DEADLINE = "ADD_DEADLINE";
export const GET_DEADLINE = "GET_DEADLINE";
export const DELETE_DEADLINE = "DELETE_DEADLINE";
export const EDIT_DEADLINE = "EDIT_DEADLINE";

/* STABILI */
export const ADD_CONDOMINIUM = "ADD_CONDOMINIUM";
export const GET_CONDOMINIUM = "GET_CONDOMINIUM";
export const DELETE_CONDOMINIUM = "DELETE_CONDOMINIUM";
export const EDIT_CONDOMINIUM = "EDIT_CONDOMINIUM";


/* PROPRIETARIO */
export const ADD_OWNER = "ADD_OWNER";
export const GET_OWNER = "GET_OWNER";
export const DELETE_OWNER = "DELETE_OWNER";
export const EDIT_OWNER = "EDIT_OWNER";

/* CONDUTTORE */

/* FORNITORE */

/* SETTINGS */

/* HANDLE CHANGE COLOR THEME BETWWEN PROPERTY AND SLC */
export const CHANGE_THEME = "CHANGE_THEME";

/* HANDLE HOW TO REMEMEVER DATA */
export const REMEMBER_DATA = "REMEMBER_DATA";

/* ADDRESS */
export const GET_PROVINCE = "GET_PROVINCE";
export const GET_MUNICIPALITY = "GET_MUNICIPALITY";
export const GET_ZONE = "GET_ZONE";


/* HANDLE ACCOUNTS */

export const GET_USERS = "GET_USERS"
export const GET_USER = "GET_USER"
export const SEND_EMAIL = "SEND_EMAIL"
export const APPROVE_USER = "APPROVE_USER"
export const REJECT_USER = "REJECT_USER"



// export const SET_TABLE_ID = "SET_TABLE_ID";
// export const SET_OTHER_TABLE_ID = "SET_OTHER_TABLE_ID";
// export const SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID";
// export const SET_TYPE_ID = "SET_TYPE_ID";
// export const SET_RENT_PERCENTAGE = "SET_RENT_PERCENTAGE";
