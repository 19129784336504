import React, {useState} from "react";
import { BsDownload, BsTrash } from "react-icons/bs";
import api, { affitti_endpoint } from "../../api/api";
import { Modal } from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CustomToggle from "../CustomToggle";
import ButtonRm from "../ButtonRm";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import translationMapping from "./mapping";

import ExportButton from "../Membri/Fornitori/ExportButton";
import { Link, useNavigate } from "react-router-dom";
import ShareChat from "../ShareChat";


const schema = yup.object().shape({
	managementType: yup.string(),
	startManagementDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	startRentingDate: yup.date().required("Inserisci la data di inizio locazione").nullable(),
	endFirstPeriodDate: yup.date().required("Inserisci la data di fine locazione").nullable(),
	endRenewalDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	cancellationRenewalForOwner:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	deadlineForCancellation: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	cancellationRentForTenant:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	cancellationRenewalForTenant:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	waiverOfCancellation: yup.boolean(),
	keyDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	closingContractDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	keyDeliveryDate: yup.number("Inserisci il giorno di riferimento").max(31, "Inserisci un numero da 1 a 31").nullable().transform((_, val) => val ? Number(val) : null),
	buildingDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	buildingDeliveryNote: yup.string(),
	warrantyDeposit: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	warrantyPaymentMethod: yup.string(),
	depositInterest: yup.string(),
	warrantyDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	warrantyTotalAmount:yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	base_fee: yup.string(),
	feeFrequency: yup.string(),
	notificationType: yup.string(),
	currentMonthlyFee: yup.number("Inserisci un numero").required("Inserisci il canone mensile"),
	currentMonthlyRateExpenses: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	turnoverThreshold: yup.number("Inserisci un numero"),
	advanceCharges:yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),

})

const AffittiCard = ({
	id,
	type,
	title,
	start,
	end,
	renewal_end,
	img_url,
	deleteAffitti,
	item,
	handleSelection,
	selectedItems
}) => {
	const deleteCard = async () => {
		deleteAffitti(id);
		try {
			await api().delete(`${affitti_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
	};

	const navigate = useNavigate()


	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false)
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//GESTIONE CHECKBOX
	const [checked, setChecked] = useState(false);

	
	const handleCheckboxChange = (e) => {
		e.stopPropagation(); // Impedisce la propagazione del clic alla card
		setChecked(!checked);
		handleSelection(id, !checked); // Passa l'id e lo stato attuale del checkbox al componente padre
	}

	const handleCardClick = (e) => {
		// Verifica se l'elemento cliccato è il checkbox
		if (e.target.type === "checkbox") {
		  return; // Se sì, non fare nulla
		}
		navigate(`/affitti/edit/${item.id}`)
	}	



	return (
		<>
		<div
			className="general-card mt-2 ms-0 flex-column flex-sm-row align-items-start align-items-sm-center cursor-pointer" onClick={handleCardClick}
			>
			<div className="d-flex">
				{/* <div className="state-img me-3">
					<img loading="lazy" src={img_url} alt="" height="auto" />
				</div> */}
				<div className="d-flex flex-column justify-content-center">
					{/* <span className="general-card-type">{type ? type : "No Type"}</span> */}
					
					<div className="d-flex align-items-center">
						<input
						type="checkbox"
						checked={selectedItems.includes(id)}
						onChange={(e) => handleSelection(e, id)}
						className="me-2"
						/>
						<span className="general-card-title text-capitalize fs-14">{title}</span>
					</div>
					<span className="affitti-card-date mt-1">
						<span>{`Inizio locazione: ${
							start ? new Date(start).toLocaleDateString("it-IT") : "no date"
						}`}</span>
						<span>{`Fine periodo iniziale: ${
							end ? new Date(end).toLocaleDateString("it-IT") : "no date"
						}`}</span>
						{renewal_end &&	<span>{`Fine rinnovo: ${
							renewal_end
								? new Date(renewal_end).toLocaleDateString("it-IT")
								: "no date"
						}`}</span>}
					</span>
				</div>
			</div>
			<div className="d-flex justify-content-between">
{/* 			<ShareChat elId={id} />
 */}
				<div>
					<button
						type="button"
						className="btn round-icon-btn"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow();
						}}>
						<BsTrash />
					</button>
				</div>
				<ExportButton data={item} mapping={translationMapping} classes="btn round-icon-btn btn me-2"/>
			</div>
		</div>

		<Modal show={show} onHide={(e) => handleClose(e)} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) =>{ 
				e.preventDefault()
				e.stopPropagation()
				deleteCard()
				handleClose(e)
			}}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={(e) => handleClose(e)}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
		</>
	);
};

export default AffittiCard;
