import { GET_PROVINCE, GET_MUNICIPALITY, GET_ZONE } from "../actions/types";

const initialState = {
	province: [],
	municipality: [],
	zone: []
};
function addressReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_PROVINCE:
			return {
				...state,
				province: [...payload],
			};
		case GET_MUNICIPALITY:
			return {
				...state,
				municipality: [...payload],
			};
		case GET_ZONE:
			return {
				...state,
				zone: [...payload],
			};
		default:
			return initialState;
	}
}

export default addressReducer;
