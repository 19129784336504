import React from "react";

const AccountCard = (props) => {
  const table_header = [
    "IBAN",
    "INTESTATO A",
    "BANCA",
    "ABI",
    "CAB",
    "CIN",
    "CONTO",
    "BIC/SWIFT",
    "Codice SIA",
    "agenzia",
    "sepa cuc",
  ];
  const objectValuesArray = Object.values(props.item);


  const remove = () => {
    props.remove(props.id);
  };
  return (
    <div className="popup-green d-block overflow-hidden rounded-3 mt-3">
      <div className="green-popup-header d-flex align-items-center justify-content-between p-2">
        <h3 className="fs-16 text-white fw-semibold">{props.item.Nome}</h3>
        {(props.editMode === undefined || props.editMode === true) && (
          <button
            type="button"
            className="border-0 bg-transparent text-white"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              remove();
            }}
          >
            <i className="bi bi-x"></i>
          </button>
        )}
      </div>
      <div className="table-list d-block  card-table">
        <table className="table table-borderless">
          <tbody>
            {objectValuesArray.map((data, index) => {
              return (
                <tr key={index}>
                  <td className="text-uppercase fs-14 text-secondary px-3">
                    {table_header[index]}
                  </td>
                  <td className="text-capitalize fs-14 text-dark px-3">
                    {objectValuesArray[index + 2]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountCard;
