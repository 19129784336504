import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import EmptyEstate from "../../assets/images/empty_estate.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UnitModal from "./UnitModal";
import UnitCard from "./UnitCard";
import { Link } from "react-router-dom";
import ButtonRm from "../ButtonRm"

import CustomBadge from "../CustomBadge";

function Unit(props) {
	const [show_unit, setShowUnit] = useState(false);

	const saveUnit = (id) => {
		let temp = [...props.formData.units];
		temp.push(props.units[id]);
		let ele = { target: { name: "units", value: temp } };
		props.onChange(ele);
	};

	const deleteUnit = (id) => {
		let temp = [...props.formData.units];
		temp.splice(id, 1);
		let ele = { target: { name: "units", value: temp } };
		props.onChange(ele);
	};

	return (
		<>
			<Form>
				<div className="form-item-align-box-white d-block">
					<h1 className="f-label fs-16 d-flex align-items-center
text-uppercase">unità’<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</h1>
					<div className="comment-container">
						<label className="affitti-comment">
							Crea o seleziona unità immobiliare
						</label>
					</div>
					{props.formData.units?.length > 0 ? (
						<>
							<UnitCard
								classNam="mt-4"
								data={props.formData.units[0]}
								id={0}
								deleteUnit={deleteUnit}
							/>
							<hr className="mt-5"/>

							<div className="mt-5 mb-3 d-block w-100">
								<span className="f-label fs-16 d-flex align-items-center
text-uppercase">PERTINENZE O ALTRE UNITA’ IMMOBILIARI</span>
							</div>
							<div className="unit-btn-group">
								<Link to="/real-estate-units/create">
									<ButtonRm variant="tertiary"className="me-2" >Nuovo</ButtonRm>
								</Link>
								<ButtonRm 
									variant="tertiary" 
									onClick={(e) => {
										setShowUnit(true);
									}}
									>Seleziona</ButtonRm>
							</div>
							{props.formData.units.length > 1 ? (
								props.formData.units.map((item, index) => {
									if (index != 0)
										return (
											<UnitCard
												id={index}
												classNam="mt-4"
												data={item}
												deleteUnit={deleteUnit}
											/>
										);
								})
							) : (
								<div className="unit-empty-img my-5 py-5">
									<img loading="lazy" src={EmptyEstate} alt="" />
									<span className="img-comment">
										Nessuna pertinenza selezionata
									</span>
								</div>
							)}
						</>
					) : (
						<>
							<div className="d-flex justify-content-start">
								<Link to="/real-estate-units/create">
									<ButtonRm variant="tertiary"className="me-2">Nuovo</ButtonRm>
								</Link>
								<ButtonRm 
									variant="tertiary" 
									onClick={(e) => {
										setShowUnit(true);
									}}
									>Seleziona</ButtonRm>
							</div>
							<div className="unit-empty-img">
								<img loading="lazy" src={EmptyEstate} alt="" />
								<span className="img-comment">
									Nessuna unità immobiliare selezionata
								</span>
							</div>
						</>
					)}
				</div>
				<div className="d-flex justify-content-end mt-5">
					<ButtonRm
					variant="tertiary"
					onClick={() => props.previousPage()}
					>
					Annulla
					</ButtonRm>
					
					<ButtonRm
					variant="primary"
						onClick={() => props.forward()}
						disabled={props.formData.units.length > 0 ? false: true}
						className="ms-2"
					>
						Avanti
					</ButtonRm>
				</div>
			</Form>
			<Modal
				show={show_unit}
				className="type-modal"
				onHide={() => {
					setShowUnit(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3" style={{maxHeight:"500px", overflowY:"auto"}}>
					<Button
						variant=""
						onClick={(e) => {
							setShowUnit(false);
						}}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<UnitModal
						table_data={props.units}
						setShow={setShowUnit}
						saveUnit={saveUnit}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default Unit;
