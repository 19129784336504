import React, { useState, useEffect } from "react";
import logo2 from "../../../assets/images/logo-red.svg";
import { Modal } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
import { setShow } from "../../../actions/ModalForVerify";
import "../../../assets/css/ModalForVerify.css";
import InputError from "../../../assets/images/input_error.svg";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import api, { public_page, BASE_URL } from "../../../api/api";


const ModalForVerify = ({ show, acrossSms = false, singupInfo}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [alowSend, setAlowSend] = useState(false);
  const [smsSuccess, setSmsSuccess] = useState(acrossSms);
  console.log("ss", singupInfo);

  const handleClose = () => {
    dispatch(setShow(false));
    setError(false);
  };
  const [verifyCode, setVerifyCode] = useState("");

  //RUOLO - non serve più

  const [roleValue, setRoleValue] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const role = params.get('role');
    setRoleValue(role); 

  }, []);



  const onSubmit = async (e) => {
    e.preventDefault()
    setAlowSend(false)

    const files = singupInfo.documentFile?.length > 0 ? [...singupInfo.documentFile] : []
    const faceId = singupInfo.faceFile
    /*You can add conditions to identify success for verification*/
    singupInfo.otp = verifyCode;
    singupInfo.supplierAccountIDs = [];
    delete singupInfo.documentFile
    delete singupInfo.faceFile

    //GESTISCI INVITATION CODE
    singupInfo.invitationCode = singupInfo.invitationCode ? singupInfo.invitationCode : "3Vcy2024ar"

    //GESTIONE ALLEGATI
    let formDataToSend = new FormData();
    files.forEach((file) => {
      const blob = new Blob([file]);
      formDataToSend.append("parts", blob, file.name);
    });
  
    let sentUpload = formDataToSend.get("parts") !== null;

    //GESTIONE ALLEGATI FACE
    let formDataToSendFace = new FormData();
    const blob = new Blob([faceId]);
    formDataToSendFace.append("parts", blob, "Face-id");
    let sentUploadFace = formDataToSendFace.get("parts") !== null;


    //.post(`${BASE_URL}api/v1/auth/verifySignup?role=${roleValue}`,
    //delete singupInfo.role //DA COMMENTARE QUANDO INTEGRIAMO BASE URL SENZA PARAM .post(`${BASE_URL}api/v1/auth/verifySignup`, E PASSARE ROLE tramite body
  
    if (verifyCode.length === 0) {
      setError(true);
    } else {
      setError(false);
      setSmsSuccess(true);
    }
    console.log(singupInfo);
    axios
      .post(`${BASE_URL}api/v1/auth/verifySignup`,
        singupInfo
      )
      .then((res) => {
        console.log(res, res.data.success);
        if (res.data.success === true) {
            toast.success("L'Otp è corretto!. Ti stiamo reindirizzando alla pagina di conferma.", {
            toastId:"123a",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          }); 

          registerSuccess();
          localStorage.removeItem("invitation_code"); //rimuove invitation_code da local storage


          //INVIA ALLEGATI
					if (sentUpload) {
            /* 
              axios
              .put(
                process.env.REACT_APP_REQUEST_URL + `api/v1/auth/upload/${res.data.id}`,
                formDataToSend
              )
              }
    
              if (sentUploadFace) {
              axios
              .put(
                process.env.REACT_APP_REQUEST_URL + `api/v1/auth/upload_face/${res.data.id}`,
                formDataToSendFace
              ) 
            */
            }
        } else {
          setAlowSend(true)

          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
      })
      .catch((err) => {
        setAlowSend(true)

        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
    //e.preventDefault();
  };

  const verifyCodeChange = (e) => {
    setVerifyCode(e.target.value);
    if (e.target.value.length === 0) {
      setAlowSend(false);
    } else {
      setAlowSend(true);
    }
  };

  const btnRequestAgain = (e) => {
    var request = {
      email: singupInfo.email,
      password: singupInfo.password,
      name: singupInfo.name,
    };

    axios({
      url: process.env.REACT_APP_REQUEST_URL + "api/v1/auth/signup",
      method: "POST",
      data: request,
    })
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          toast.success("Nuovo OTP inviato! ", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        
        } 
      })
      .catch((err) => {
        console.debug(err + ": " + process.env.REACT_APP_REQUEST_URL + "api/v1/auth/signup");
        console.log(err)
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
  };

  const registerSuccess = () => {
    setTimeout(() => {
      handleClose() //chiude modale
      navigate("/registerSuccess");
    }, 5000);
  };
  


  console.log("codice", verifyCode)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="verify-Modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} />
      <Modal.Body>
        <ToastContainer />
        <img
          src={logo2}
          alt="logo"
          style={{
            paddingBottom: "15px",
            margin: "auto",
            display: "flex",
          }}
        />
        <div className="titleOTP-div">
          <div style={{ paddingBottom: "10px" }}>
            {
              /* {smsSuccess ? "Controlla la tua casella email" : "Inserisci OTP"} */ "Inserisci OTP"
            }
          </div>
          <div className="verify-description-div">
            {smsSuccess
              ? // ? `Ti abbiamo appena inviato una e-mail di conferma a ${singupInfo.email } L'email contiene un link che dovrai aprire per confermare la validità dell'indirizzo da te fornito. Una volta confermato si notorizzerà l’intero processo di registrazione.`
                `Ti abbiamo inviato l’OTP di 6 cifre alla tua email ${singupInfo.email}.\nInseriscilo nel campo sottostante per verificare la tua email`
              : `Ti abbiamo inviato l’OTP di 6 cifre alla tua email ${singupInfo.email}.\nInseriscilo nel campo sottostante per verificare il tuo numero`}
          </div>

          <form className="form" onSubmit={onSubmit}>
            {
              // smsSuccess ? (
              //   <div></div>
              // ) :
              <input
                type="text"
                placeholder="Inserisci OTP"
                name="text"
                value={verifyCode}
                onChange={verifyCodeChange}
                id={error ? "otp_input_error" : ""}
                className="form-control"
                style={{
                  fontFamily: "Mulish",
                  height: "50px",
                  border: "1px solid #BDBDBD",
                }}
              />
            }
            {error && (
              <div className="opt_error_msg">
                <img loading="lazy" src={InputError} alt="input_error"></img>
                <div style={{ paddingLeft: "10px" }}>
                  Il codice inserito non è corretto
                </div>
              </div>
            )}
           {/*  {smsSuccess ? (
              <div
                className="confirm-emailLink-div"
                onClick={() => registerSuccess      ()}
              >
                Ok
              </div>
            ) : ( */}
              <button
                type="submit"
                id={alowSend ? "active-submit" : ""}
                className="confirm-btn-div"
                style={{ marginTop: "10px" }}
                disabled={alowSend ? false : true} 
              >
                Continua
              </button>
          {/*   )} */}
          </form>
          {smsSuccess ? (
            <div className="resend-div" onClick={() => btnRequestAgain()}>
              Invia di nuovo l’email
            </div>
          ) : (
            <div className="resend-div" onClick={() => btnRequestAgain()}>
              Invia nuovamente l’OTP
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: state.ModalForVerify.show,
});

export default connect(mapStateToProps, { setShow })(ModalForVerify);
