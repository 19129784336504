import React from "react";
import logo from "../../../assets/images/logo-red.svg";
import "../../../assets/css/Register.css";
import Footer from "../../Footer";
import Tabs from "./Tabs/Tabs";
import { useNavigate } from "react-router-dom";

export default function Register() {
	const navigate = useNavigate()
	
	return (
		<>
			<div className="register-div">
				<img
					src={logo}
					alt="logo"
					className="register-logo"
					onClick={() => navigate("/")}
					style={{ cursor: "pointer" }}></img>
				<div className="tabs-content-div">
					<div className="register-title">Crea il tuo account</div>
					<Tabs />
				</div>
			</div>
			<Footer />
		</>
	);
}
