import React from "react";
import "../../../../assets/css/Membri.css";
import cardimage from "../../../../assets/images/bank-icon.svg";
import ButtonRm from "../../../ButtonRm";
import { BankCard, BankCardItem } from "../../../BankCard";
import {  BsPrinter } from "react-icons/bs";
import ExportButton from "../../Fornitori/ExportButton";
import translationMappingConti from "../../../mappingConti";

export default function CorrentiV(props) {
  return (
    <div className="row w-100">
       <div className="form-item-align-box-white d-block pt-2 pb-5">
       {props.formData.bankAccounts?.length>0 && <div className="d-flex mb-3 justify-content-end mt-3 me-3">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

				
					<ExportButton data={props.formData.bankAccounts} showPrint mapping={translationMappingConti} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>}
        {props.formData.bankAccounts?.length === 0 ? (
          <div className="col-12 my-5">
            <div className="mx-auto d-table text-center">
              <div className="image-user mx-auto d-table w-130 fw-100">
                <img loading="lazy" src={cardimage} alt="user-key" />
              </div>
              <p className="fs-18 text-secondary text-center">
                Non hai aggiunto ancora alcun conto
              </p>
            </div>
          </div>
        ) : (
          props.formData.bankAccounts?.length > 0 && props.formData.bankAccounts.map((bankAccount, index) => (
            <BankCard 
                name={bankAccount.name}         
                color="#78ab8b" 
                deleteFunc={props.removeBankAccount} 
                index={index}
                deleteVisible={false}
                >
                  <BankCardItem property="IBAN" value={bankAccount.iban}/>
                  <BankCardItem property="INTESTATO A" value={bankAccount.header}/>
                  <BankCardItem property="BANCA" value={bankAccount.bank}/>
                  <BankCardItem property="ABI" value={bankAccount.abi}/>
                  <BankCardItem property="CAB" value={bankAccount.cab}/>
                  <BankCardItem property="CIN" value={bankAccount.cin}/>
                  <BankCardItem property="CONTO" value={bankAccount.conto}/>
                  <BankCardItem property="BIC/SWIFT" value={bankAccount.bic}/>
                  <BankCardItem property="CODICE SIA" value={bankAccount.siaCode}/>
                  <BankCardItem property="AGENZIA" value={bankAccount.agency}/>
                  <BankCardItem property="SEPA CUC" value={bankAccount.cucSepaCode}/>
              </BankCard>
          ))
        )}
      </div>
    </div>
  );
}
