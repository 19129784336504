import React,{useState} from "react";
import MidTabs from "../components/Membri/Fornitori/view_mode/MidTabs";
import TopHeadPartV from "../components/Membri/Fornitori/view_mode/TopHeadPartV";
import Container from "../components/Container";
import { useLocation } from "react-router-dom";
import TopHeading from "../components/TopHeading";

import { supplier_endpoint, BASE_URL } from "../api/api";
import { useEffect } from "react";
import axios from "axios";

export default function ViewFornitori(props) {
  const location = useLocation();
 
  const [supplier, setSupplier] = useState({});

  // TODO con api() da problemi con la chiamata API, da risolvere
  const fetchSupplier = () => {
    axios
      .create({
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
        },
      })
      .get(
        supplier_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
      .then((res) => {
        if (res.data) {
          setSupplier({
            ...res.data,
            initialBankAccountsIDs: res.data.bankAccounts.map(
              (bankAccount) => bankAccount.id
            ),
          });
        }
      });
  };

  useEffect(() => {
    fetchSupplier();
  }, []);

  return (
    <>
      {/* BARRA ROSSA */}
      <TopHeading SmallHeading="" back_modal=""/>
      {/* nome e modifica */}
      <TopHeadPartV parms={supplier}/>
      {/* tabs */}
      <MidTabs parms={supplier}/>
    </>
  );
}
