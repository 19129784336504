import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { MultiSelect } from "react-multi-select-component";
import NumericInput from "react-numeric-input";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";
import {Row, Col} from "react-bootstrap";
import axios from "axios";
import ButtonRm from "../../../ButtonRm";
import { BsPrinter, BsDownload } from "react-icons/bs";

import CustomToggle from "../../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import ExportButton from "../../../Membri/Fornitori/ExportButton"
import translationMapping from "../mapping";

import CustomBadge from "../../../CustomBadge";



let schema

const DestinationE = (props) => {

    //HANDLE UFFICI REG
    const [office, setOffice] = useState([])

    async function getOffice() {
		try {
			const response = await axios.get(
				`https://anthill-service.onrender.com/getOfficeList`
			);
			if (response.data) {
				setOffice(response.data.data);
			}
		} catch (err) {
			console.log(err);
		}
	}

    useEffect(() => {
		getOffice();
	}, []);


    //HANDLE DATA
    const [management, setManagement] = useState({});


    const initManagement = () => {
		setManagement({
			unitDestination: props.formData.unitDestination ? props.formData.unitDestination : "",
			assignmentType: props.formData.assignmentType,
			haveSocialFee: props.formData.haveSocialFee,
			managementType: props.formData.managementType,
			withholdingDeposit: props.formData.withholdingDeposit,
			lessorCommissions: props.formData.lessorCommissions,
			conductorCommissions: props.formData.conductorCommissions,
			ownerCommissions: props.formData.ownerCommissions,
			buyerCommissions: props.formData.buyerCommissions,
			defaultRegistrationOffice: props.formData.defaultRegistrationOffice,
			typologyNotRented: props.formData.typologyNotRented,
			objectToSell: props.formData.objectToSell,
			installmentsRate: props.formData.installmentsRate,
			bailmentProposal: props.formData.bailmentProposal,
			warrantyType: props.formData.warrantyType,
			bailmentRent: props.formData.bailmentRent,
			favoritePaymentMethod: props.formData.favoritePaymentMethod,
			monthlyFee: props.formData.monthlyFee,
			flateRateExpenses: props.formData.flateRateExpenses,
		});
	};


    useEffect(() => {
		initManagement();
	}, [props]);

	function myFormat(num) {
		return num + "%";
	}

    const changeManagement = (e) => {
		if (e.target.type == "checkbox")
			setManagement({ ...management, [e.target.name]: e.target.checked });
		else setManagement({ ...management, [e.target.name]: e.target.value });
	};

	const overrideString = { 
		selectAll: "Seleziona tutti",
		allItemsAreSelected: "Tutti gli elementi sono selezionati",
		clearSearch: "Cancella",
		clearSelected: "Rimuovi elementi selezionati",
		noOptions: "Nessuna corrispondenza",
		search: "Cerca",
		selectSomeItems: "Seleziona"
	
	};
	const [selected, setSelected] = useState([]);

	const unit_destination = [
		{ value: "Solo possesso e gestione", label: "Solo possesso e gestione" },
		{ value: "Affitto annuale", label: "Affitto annuale" },
		{ value: "Affitto transitorio", label: "Affitto transitorio" },
		{ value: "Vendita", label: "Vendita" },
		{ value: "Affitto vacanza o breve", label: "Affitto vacanza o breve" },
	];

    useEffect(() => {
		function setInitialDestination() {
			let temp = unit_destination.filter((item) => {
				return management.unitDestination?.split(",")?.indexOf(item.value) !== -1;
			  });
			
			console.log(temp)
			setSelected(temp);
		}
		setInitialDestination();
	}, []);

	useEffect(() => {
		function setUnitDestination() {
			var temp = "";
			if (selected.length > 0) {
				for (var i = 0; i < selected.length; i++) {
					if (i == selected.length - 1) temp += selected[i].value;
					else temp += selected[i].value + ",";
				}
			}
			setManagement({ ...management, ["unitDestination"]: temp });
		}
		setUnitDestination();
	}, [selected]);

	const isInclude = (id) => {
		const unitDestinationArray = management.unitDestination ? management.unitDestination.split(",") : [];
		return unitDestinationArray.includes(unit_destination[id].value);
	  };

	const assignment_type = [
		"Esclusiva",
		"Non in esclusiva",
		"In collaborazione",
		"Verbale",
	];

	const typology_not_rented = [
		"Uso residenziale",
		"Uso commerciale",
		"Uso industriale",
		"Uso ufficio",
		"Altro uso",
		"Terreni agricoli",
		"Altri terreni",
	];

	const management_type = [
		"Completa",
		"Parziale",
		"Invisibile",
		"Scadenza e rate - no incassi",
		"Solo scadenze",
		"Solo contratto",
		"Mediazione affitto",
	];

	const handleOffice = (e) => {
		setManagement(prev => {
			return {
				...prev,
				defaultRegistrationOffice: e.target.value
			}
		})
	};

	//HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});


	 //CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	 useEffect(() => {
		if (props.saveState === true) {
			props.update(management);
		} else {
			initManagement();
		}

	}, [props.saveState, props.editMode]);

	console.log(props.saveState, props.editMode)

	return (
		<Form>
			<div
				className={`mb-3 d-flex justify-content-end align-items-center ${
					props.editMode === false ? "" : "d-none"
				}`}>
				<div className="d-flex">
					
					<ExportButton data={management} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>
				</div>
			</div>
            <fieldset disabled={props.editMode? false: true}>
			
			<Accordion defaultActiveKey={['0', '4']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Destinazione</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Finalità unità immobiliare
							</label>
							<MultiSelect
								value={selected}
								options={unit_destination}
								onChange={setSelected}
								overrideStrings={overrideString}
								className={!props.editMode ? "disabled-multiselect" : ""}
								disabled={props.editMode? false: true}	
															
							/>
						</div>
					</div>
					{(isInclude(1) || isInclude(2) || isInclude(3) || isInclude(4)) && (
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Tipo di incarico
								</label>
								<select
									name="assignmentType"
									value={management.assignmentType}
									onChange={changeManagement}>
									{assignment_type.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					)}
				</div>
				{(isInclude(1) || isInclude(2) || isInclude(3)) && (
					<div className="input-checkbox mt-3">
						<input
							type="checkbox"
							name="haveSocialFee"
							checked={management.haveSocialFee}
							onChange={changeManagement}
						/>
						<label className="f-label fs-16 d-flex align-items-center
me-2">
							Soggetto a canone sociale
						</label>
					</div>
				)}					
					</Accordion.Body>
				</Accordion.Item>

				{(isInclude(1) || isInclude(2)) && (
					<>
					<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="1" activeKey={activeKey}>Affitti</CustomToggle>
					</Card.Header>
						<Accordion.Body>
						<div className="row ">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">Tipo di gestione</label>
									<select
										name="managementType"
										value={management.managementType}
										onChange={changeManagement}>
										{management_type.map((menu, index) => {
											return (
												<option key={index} value={menu}>
													{menu}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="input-checkbox mt-3">
							<input
								type="checkbox"
								name="withholdingDeposit"
								checked={management.withholdingDeposit}
								onChange={changeManagement}
							/>
							<label className="f-label fs-16 d-flex align-items-center
me-2">
								Trattenere la cauzione per conto del locatore
							</label>
						</div>
						</Accordion.Body>
					</Accordion.Item>
					</>
				)}

				{isInclude(3) && (
						<>
						<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Vendita</CustomToggle>
						</Card.Header>
							<Accordion.Body>
							<div className="row ">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label className="f-label fs-16 d-flex align-items-center
mb-2 ">
											Provvigioni proprietario
										</label>
										<input
											name="lessorCommissions"
											value={management.lessorCommissions}
											onChange={changeManagement}
											type="number"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 ">
											Provvigioni acquirente
										</label>
										<input
											name="conductorCommissions"
											value={management.conductorCommissions}
											onChange={changeManagement}
											type="number"
										/>
										
									</div>
								</div>
							</div>
							</Accordion.Body>
						</Accordion.Item>
						</>
				)}

				{isInclude(4) && (
						<>
						<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="3" activeKey={activeKey}>Mediazione affitti casa</CustomToggle>
						</Card.Header>
							<Accordion.Body>
							<div className="row ">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label className="f-label fs-16 d-flex align-items-center
mb-2">
										Provvigioni locatore
									</label>
									
									<input
										name="ownerCommissions"
										value={management.ownerCommissions}
										onChange={changeManagement}
										type="number"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Provvigioni conduttore
									</label>
									<input
										name="buyerCommissions"
										value={management.buyerCommissions}
										onChange={changeManagement}
										type="number"
									/>
								</div>
							</div>
							</div>
							</Accordion.Body>
						</Accordion.Item>
						</>
				)}

			<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="4" activeKey={activeKey}>Altro</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row ">
					{/* <div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Ufficio predefinito registrazione
							</label>
							<select
								name="defaultRegistrationOffice"
								value={management.defaultRegistrationOffice}
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
								onChange={handleOffice}>
								<option value={management.defaultRegistrationOffice}>{management.defaultRegistrationOffice}</option>

								{office?.map((item, index) => {
									return (
										<option key={index} value={item.code}>
											{item.office + " - " + item.code}
										</option>
									);
								})}
							</select>
						</div>
					</div> */}
				</div>
				<div className="row mt-4">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Tipologia non locati negli studi di settore
							</label>
							<select
								name="typologyNotRented"
								value={management.typologyNotRented}
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
								onChange={changeManagement}>
								{typology_not_rented.map((menu, index) => {
									return (
										<option key={index} value={menu}>
											{menu}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				</div>			
					</Accordion.Body>
			</Accordion.Item>


				
			</Accordion>
			
			</fieldset>
		</Form>
	);
};

export default DestinationE;
