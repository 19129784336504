import React, { useState } from "react";
import "../../../../assets/css/Membri.css";
import { BsCalendar2Event } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import {Row, Col} from "react-bootstrap"

import { Link } from "react-router-dom";

import CustomBadge from "../../../CustomBadge";

export default function AmountsAndData(props) {
  const [checked, setChecked] = useState(false);
  const [linkShow, setLinkShow] = useState(false);
  const [show, setShow] = useState(false);

  const categoriaChange = (e) => {
    if (e.target.value === "utenze - energia") {
      setLinkShow(true);
    } else {
      setLinkShow(false);
    }
  };

  return (
    <div
      className="d-block w-100 mb-3 bg-white"
      style={{ borderRadius: "16px" }}
    >
      <Form>
        <div className="form-item-align-box d-block pt-5 pb-5">
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Identificativo rata
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Inizio competenza
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Fine competenza
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Scadenza
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Importo
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Totale dovuto
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Total versato
                </label>
                <select
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => categoriaChange(e)}
                >
                  <option>select</option>
                  <option>select</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              {/* if this checkbox is checked, ricorrenza section should be visible */}
              <Form.Group>
                  <Form.Check type='checkbox'>
                          <Form.Check.Input type='checkbox' name='recurrence' /* checked={formData.recurrence}
                      onChange={handleFormChange} *//>
                          <Form.Check.Label>Ricorrenza</Form.Check.Label>
                  </Form.Check>
              </Form.Group>
            </div>
                  {
                          /* ricorrenza: add a condition to show this section only if the "ricorrenza" checbox is checked*/
                                <>
                                    <div className="label-heading my-3 d-block w-100 ps-0">
                                        <div className="label-text rel d-inline-block py-2 px-2">
                                            <h6 className="fs-14 text-white text-uppercase fw-semibold">
                                                RICORRENZA
                                            </h6>
                                        </div>
                                    </div>

                                    <Row className="d-md-flex">   
                                                                    
                                        <Form.Group className="mb-3 input-size">
                                            <Form.Label className="input-label">Ogni</Form.Label>
                                            <Form.Control type="number" placeholder="Inserisci un numero" style={{height:'50px'}} name="every" 
                                           /*  value={formData.every} 
                                            onChange={handleFormChange} */
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3 input-size">
                                            <Form.Label className="input-label">Frequenza</Form.Label>
                                            <Form.Select aria-label="Default select example" style={{height:'50px'}} name="frequency"
                                            /* value={formData.frequency} 
                                            onChange={handleFormChange}  */ >
                                                <option>
                                                    Seleziona
                                                </option>
                                                <option value="days">Giorni</option>
                                                <option value="weeks">Settimana</option>
                                                <option value="months">Mesi</option>
                                                <option value="years">Anni</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mb-3 input-size">
                                            <Form.Label className="input-label">Fine Ricorrenza</Form.Label>
                                            <Form.Control type="datetime-local"  style={{height:'50px'}} name="recurrenceEnd" /* value={formData.recurrenceEnd} 
                                            onChange={handleFormChange} */ />
                                        </Form.Group>
                                    </Row>
                                </>
                            }
          </div>
          <div
            className="col-md-12 mt-5 tariffari-link-div"
            style={{ display: linkShow ? "" : "none" }}
            onClick={() => setShow(true)}
          >
            Visualizza elenco dei piani tariffari
          </div>
          <div className="col-12">
            <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
              <button
                type="button"
                className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
                onClick={() => props.back()}
              >
                Indietro
              </button>
              <button
                type="button"
                className="next-btn btn-last rounded-3 shadow w-193 border-0"
                onClick={() => {props.next(false); window.amountAndData = "Crea";}}
              >
                Crea
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
