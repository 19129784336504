import { useState, useEffect } from "react";
import CustomBadge from "../../CustomBadge";


const AmenityE = (props) => {
  const [typologyState, setTypologyState] = useState(0);
  useEffect(() => {
    var text = props.formData.typology;
    if (text !== undefined) {
      if (text.includes("Appartamenti") || text.includes("Ville")) {
        setTypologyState(0);
      }
      /*  else if (
        text.includes("ABITAZIONI DI 2°") ||
        text.includes("FASCIA MEDIA")
      ) {
        setTypologyState(1);
      }  */
      else if (text.includes("Uffici")) {
        setTypologyState(2);
      } else if (text.includes("Negozi")) {
        setTypologyState(3);
      } else if (text.includes("Box e autorimesse")) {
        setTypologyState(4);
      } else if (text.includes("Posti auto")) {
        setTypologyState(5);
      } else if (text.includes("Capannoni")) {
        setTypologyState(6);
      } else if (text.includes("Magazzini")) {
        setTypologyState(7);
      } else if (text.includes("Laboratori")) {
        setTypologyState(8);
      }
    }
  }, []);

  const pavment = [
    { name: "Ceramica", value: "ceramic" },
    { name: "Cotto", value: "cotto" },
    { name: "Graniglia", value: "grit" },
    { name: "Gres porcellanato", value: "gres" },
    { name: "Marmette d’epoca", value: "clayTiles" },
    { name: "Marmittoni", value: "tiles" },
    { name: "Marmo", value: "marble" },
    { name: "Materiale vinilico", value: "vinylMaterial" },
    { name: "Monocottura", value: "singleFired" },
    { name: "Moquette", value: "moquette" },
    { name: "Parquet", value: "parquet" },
    { name: "Resina", value: "resin" },
    { name: "Veneziana", value: "venetian" },
    { name: "Veneziana decorata", value: "decoratedVenetianBlind" },
  ];

  const windowAndDoor = [
    { name: "Grate/Inferriate", value: "railings" },
    { name: "Infissi alluminio", value: "aluminumFixtures" },
    { name: "Infissi alluminio/legno", value: "aluminumWoodFixtures" },
    { name: "Infissi legno", value: "woodFixtures" },
    { name: "Infissi pvc", value: "pvcFixtures" },
    { name: "Persiane in alluminio", value: "aluminumShutter" },
    { name: "Persiane in legno", value: "woodShutter" },
    { name: "Porta blindata", value: "securityDoor" },
    { name: "Serrande elettriche", value: "electricShutters" },
    { name: "Tapparelle alluminio", value: "aluminumBlinds" },
    { name: "Tapparelle corazzate", value: "ironcladBlinds" },
    { name: "Tapparelle legno", value: "woodBlinds" },
    { name: "Tapparelle plastica", value: "plasticBlinds" },
    { name: "Tapparelle pvc", value: "pvcBlinds" },
    { name: "Vetri blindati", value: "armoredGlass" },
    { name: "Vetri doppi", value: "doubleGlasses" },
    { name: "Vetri tripli", value: "tripleGlasses" },
    { name: "Zanzariere", value: "mosquitoNets" },
  ];

  const equipment = [
    { name: "Aria condizionata", value: "airConditioning" },
    { name: "Boiler a gas", value: "gasBoiler" },
    { name: "Cassaforte", value: "safe" },
    { name: "Caveau blindato", value: "armoredCaveau" },
    { name: "Doccia sauna", value: "saunaShower" },
    { name: "Fibra ottica", value: "opticFiber" },
    { name: "Fotovoltaico", value: "photovoltaic" },
    { name: "Illuminazione a faretti", value: "spotlightLighting" },
    { name: "Impianti domotica", value: "homeAutomationSystems" },
    { name: "Impianto allarme", value: "alarmSystem" },
    {
      name: "Impianto elettrico certificato",
      value: "certifiedElectricalSystem",
    },
    { name: "Impianto idraulico certificato", value: "certifiedPlumbing" },
    { name: "Impianto irrigazione", value: "irrigationSystem" },
    { name: "Pannelli solari", value: "solarPanels" },
    { name: "Parabola TV", value: "tvDish" },
    { name: "Rete per computer", value: "computerNetwork" },
    { name: "Vasca idromassaggio", value: "jacuzzi" },
    { name: "Videocitofono", value: "videoIntercom" },
  ];

  const furniture = [
    { name: "Armadi a muro", value: "builtinWardrobes" },
    { name: "Arredo bagni ", value: "bathroomFurniture" },
    { name: "Arredo camere", value: "roomFurnishings" },
    { name: "Arredo cucina", value: "kitchenFurniture" },
    { name: "Arredo esterni", value: "outdoorFurniture" },
    { name: "Arredo soggiorno", value: "livingRoomFurniture" },
    { name: "Barbecue", value: "barbecue" },
    { name: "Boiserie", value: "boiserie" },
    { name: "Cabina armadi", value: "walkinCloset" },
    { name: "Camino", value: "fireplace" },
    { name: "Canna fumaria", value: "flue" },
    { name: "Copritermosifoni", value: "radiatorCovers" },
    { name: "Librerie", value: "bookcase" },
    { name: "Sanitari sospesi", value: "sanitarySuspended" },
    { name: "Soppalchi", value: "mezzanines" },
    { name: "Tende parasole", value: "awnings" },
  ];

  const structure = [
    { name: "Cemento armato", value: "reinforcedConcrete" },
    {
      name: "Cemento armato prefabbricato",
      value: "prefabricatedReinforcedConcrete",
    },
    { name: "Intelaiatura in ferro", value: "ironFrame" },
    { name: "Intelaiatura in legno", value: "woodFrame" },
    { name: "Mista Muratura/cemento", value: "masonryConcreteMix" },
    { name: "Muratura portante", value: "loadBearingMasonry" },
  ];

  const cladding = [
    { name: "Ceramica/Piastrella", value: "ceramicTile" },
    { name: "Cortina/Mattoncini", value: "curtainBricks" },
    { name: "Intonaco", value: "plaster" },
    { name: "Intonaco con fregi", value: "plasterWithFriezes" },
    { name: "Intonaco e cortina", value: "plasterCurtain" },
    { name: "Intonaco rustico", value: "rusticPlaster" },
    {
      name: "Lamiere per rivestimenti esterni",
      value: "sheetMetalExternalCladding",
    },
    { name: "Marmo", value: "marbleMaterial" },
    { name: "Pannelli di calcestruzzo", value: "concretePanels" },
    { name: "Pietra/Materiali lapideo", value: "stoneMaterials" },
    { name: "Quarzo plastico", value: "plasticQuartz" },
    { name: "Vetro/Cristallo", value: "glassCrystal" },
  ];

  const condoProperty = [
    { name: "Appartamento portiere", value: "doormanApartment" },
    { name: "Area parcheggio", value: "parkingArea" },
    { name: "Cantina condominiale", value: "condominiumCellar" },
    { name: "Giardino condominiale", value: "condominiumGarden" },
    { name: "Locali commerciali ", value: "businessPremises" },
    { name: "Parco", value: "park" },
    { name: "Sala riunioni", value: "meetingRoom" },
    { name: "Strada privata", value: "privateRoad" },
    { name: "Terrazza condominiale", value: "sharedTerrace" },
  ];
  const condoSevice = [
    { name: "Acqua calda centralizzata", value: "centralizedHotWater" },
    { name: "Antenna centralizzata", value: "centralizedAntenna" },
    {
      name: "Aria condizionata centralizzata",
      value: "centralAirConditioning",
    },
    { name: "Campo da tennis", value: "tennisCourt" },
    { name: "Locale lavanderia", value: "laundry" },
    { name: "Locale palestra", value: "gym" },
    { name: "Parco giochi", value: "playground" },
    { name: "Piscina condominiale", value: "condominiumPool" },
    { name: "Portineria", value: "concierge" },
    { name: "Portineria 24 ore", value: "concierge24Hour" },
    { name: "Portineria part-time", value: "partTimeConcierge" },
    { name: "Videocitofono", value: "buildingVideoIntercom" },
    { name: "Videosorveglianza", value: "videoSurveillance" },
  ];

  const other = [
    { name: "Accesso per disabili", value: "disabledAccess" },
    { name: "Cablato ", value: "wired" },
    { name: "Controllo accessi", value: "accessControl" },
    { name: "Controsoffitto", value: "falseCeiling" },
    { name: "Impianto di allarme", value: "alarmSystem" },
    { name: "Internet a banda larga", value: "broadbandInternet" },
    { name: "BroadbandInternet", value: "emergencyLights" },
    { name: "Parcheggio bici", value: "bikeParking" },
    { name: "Uscita di emergenza ", value: "emergencyExit" },
    { name: "Porta tagliafuoco ", value: "fireDoor" },
    { name: "Reception", value: "reception" },
    { name: "Rilevatori antincendio", value: "fireDetectors" },
    { name: "Edificio di soli uffic", value: "officeBuildingOnly" },
  ];

  const otherNegozi = [
    { name: "Accesso per disabili", value: "disabledAccess" },
    { name: "Cablato ", value: "wired" },
    { name: "aspirazione fumi ", value: "smokeExtraction" },
    { name: "doppio ingresso", value: "doubleEntrance" },
    { name: "Impianto di allarme", value: "alarmSystem" },
    { name: "passo carrabile", value: "toAwayZone" },
    { name: "posizione ad angolo", value: "cornerPosition" },
    { name: "Saracinesche automatiche", value: "automaticPortcullis" },
    { name: "Uscita di emergenza ", value: "emergencyExit" },
    { name: "Porta tagliafuoco ", value: "fireDoor" },
    { name: "Saracinesche manuali", value: "manualPortcullis" },
    { name: "Rilevatori antincendio", value: "fireDetectors" },
    { name: "Vigilanza cctv", value: "cctv" },
    { name: "Canna fumaria", value: "flue" },
  ];

  const handleChange = (e) => {
    let ele = {
      target: { name: "reportReferenceImage", value: e.target.files[0] },
    };
    onChange(ele);
  };

  const handleChangeRealEstate = (e) => {
    let ele = {
      target: { name: "realEstateImage", value: e.target.files[0] },
    };
    onChange(ele);
  };

  const [data, setData] = useState({});

  const init = () => {
    setData({
      description: props.formData.description,
      reportReferenceImage: props.formData.reportReferenceImage,
      openSpace: props.formData.openSpace,
      electricCarChargingStation: props.formData.electricCarChargingStation,
      electricDoor: props.formData.electricDoor,
      accessibility24h: props.formData.accessibility24h,
      disabledAccess: props.formData.disabledAccess,
      cctv: props.formData.cctv,
      alarmSystem: props.formData.alarmSystem,
      toAwayZone: props.formData.toAwayZone,
      fencedProperty: props.formData.fencedProperty,
      realEstateImage: props.formData.realEstateImage,
      ceramic: props.formData.ceramic,
      cotto: props.formData.cotto,
      grit: props.formData.grit,
      gres: props.formData.gres,
      clayTiles: props.formData.clayTiles,
      tiles: props.formData.tiles,
      marble: props.formData.marble,
      vinylMaterial: props.formData.vinylMaterial,
      singleFired: props.formData.singleFired,
      moquette: props.formData.moquette,
      parquet: props.formData.parquet,
      resin: props.formData.resin,
      venetian: props.formData.venetian,
      decoratedVenetianBlind: props.formData.decoratedVenetianBlind,
      railings: props.formData.railings,
      aluminumFixtures: props.formData.aluminumFixtures,
      aluminumWoodFixtures: props.formData.aluminumWoodFixtures,
      woodFixtures: props.formData.woodFixtures,
      pvcFixtures: props.formData.pvcFixtures,
      aluminumShutter: props.formData.aluminumShutter,
      woodShutter: props.formData.woodShutter,
      securityDoor: props.formData.securityDoor,
      electricShutters: props.formData.electricShutters,
      aluminumBlinds: props.formData.aluminumBlinds,
      ironcladBlinds: props.formData.ironcladBlinds,
      woodBlinds: props.formData.woodBlinds,
      plasticBlinds: props.formData.plasticBlinds,
      pvcBlinds: props.formData.pvcBlinds,
      armoredGlass: props.formData.armoredGlass,
      doubleGlasses: props.formData.doubleGlasses,
      tripleGlasses: props.formData.description,
      mosquitoNets: props.formData.mosquitoNets,
      airConditioning: props.formData.airConditioning,
      gasBoiler: props.formData.gasBoiler,
      safe: props.formData.safe,
      armoredCaveau: props.formData.armoredCaveau,
      saunaShower: props.formData.saunaShower,
      opticFiber: props.formData.opticFiber,
      photovoltaic: props.formData.photovoltaic,
      spotlightLighting: props.formData.spotlightLighting,
      homeAutomationSystems: props.formData.homeAutomationSystems,
      certifiedElectricalSystem: props.formData.certifiedElectricalSystem,
      certifiedPlumbing: props.formData.certifiedPlumbing,
      irrigationSystem: props.formData.irrigationSystem,
      solarPanels: props.formData.solarPanels,
      tvDish: props.formData.tvDish,
      computerNetwork: props.formData.computerNetwork,
      jacuzzi: props.formData.jacuzzi,
      videoIntercom: props.formData.videoIntercom,
      builtinWardrobes: props.formData.builtinWardrobes,
      bathroomFurniture: props.formData.bathroomFurniture,
      roomFurnishings: props.formData.roomFurnishings,
      kitchenFurniture: props.formData.kitchenFurniture,
      outdoorFurniture: props.formData.outdoorFurniture,
      livingRoomFurniture: props.formData.livingRoomFurniture,
      barbecue: props.formData.barbecue,
      boiserie: props.formData.boiserie,
      walkinCloset: props.formData.walkinCloset,
      fireplace: props.formData.fireplace,
      flue: props.formData.flue,
      radiatorCovers: props.formData.radiatorCovers,
      bookcase: props.formData.bookcase,
      sanitarySuspended: props.formData.sanitarySuspended,
      mezzanines: props.formData.mezzanines,
      awnings: props.formData.awnings,
      reinforcedConcrete: props.formData.reinforcedConcrete,
      prefabricatedReinforcedConcrete:
        props.formData.prefabricatedReinforcedConcrete,
      ironFrame: props.formData.ironFrame,
      woodFrame: props.formData.woodFrame,
      masonryConcreteMix: props.formData.masonryConcreteMix,
      loadBearingMasonry: props.formData.loadBearingMasonry,
      ceramicTile: props.formData.ceramicTile,
      curtainBricks: props.formData.curtainBricks,
      plaster: props.formData.plaster,
      plasterWithFriezes: props.formData.plasterWithFriezes,
      plasterCurtain: props.formData.plasterCurtain,
      rusticPlaster: props.formData.rusticPlaster,
      sheetMetalExternalCladding: props.formData.sheetMetalExternalCladding,
      marbleMaterial: props.formData.marbleMaterial,
      concretePanels: props.formData.concretePanels,
      stoneMaterials: props.formData.stoneMaterials,
      plasticQuartz: props.formData.plasticQuartz,
      glassCrystal: props.formData.glassCrystal,
      doormanApartment: props.formData.doormanApartment,
      parkingArea: props.formData.parkingArea,
      condominiumCellar: props.formData.condominiumCellar,
      condominiumGarden: props.formData.condominiumGarden,
      businessPremises: props.formData.businessPremises,
      park: props.formData.park,
      meetingRoom: props.formData.meetingRoom,
      privateRoad: props.formData.privateRoad,
      sharedTerrace: props.formData.sharedTerrace,
      centralizedHotWater: props.formData.centralizedHotWater,
      centralizedAntenna: props.formData.centralizedAntenna,
      centralAirConditioning: props.formData.centralAirConditioning,
      tennisCourt: props.formData.tennisCourt,
      laundry: props.formData.laundry,
      gym: props.formData.gym,
      playground: props.formData.playground,
      condominiumPool: props.formData.condominiumPool,
      concierge: props.formData.concierge,
      concierge24Hour: props.formData.concierge24Hour,
      partTimeConcierge: props.formData.partTimeConcierge,
      buildingVideoIntercom: props.formData.buildingVideoIntercom,
      videoSurveillance: props.formData.videoSurveillance,
      wired: props.formData.wired,
      accessControl: props.formData.accessControl,
      falseCeiling: props.formData.falseCeiling,
      broadbandInternet: props.formData.broadbandInternet,
      emergencyLights: props.formData.emergencyLights,
      bikeParking: props.formData.bikeParking,
      emergencyExit: props.formData.emergencyExit,
      fireDoor: props.formData.fireDoor,
      reception: props.formData.reception,
      fireDetectors: props.formData.fireDetectors,
      officeBuildingOnly: props.formData.officeBuildingOnly,
      smokeExtraction: props.formData.smokeExtraction,
      doubleEntrance: props.formData.doubleEntrance,
      cornerPosition: props.formData.toAwayZone,
      automaticPortcullis: props.formData.automaticPortcullis,
      manualPortcullis: props.formData.manualPortcullis,
    });
  };

  useEffect(() => {
    init();
  }, [props]);

  const onChange = (e) => {
    if (e.target.type === "checkbox")
      setData({ ...data, [e.target.name]: e.target.checked });
    else setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (props.saveState === true) {
      props.update(data);
    } else {
      init();
    }
  }, [props.saveState, props.editMode]);

  return (
    <div className="form-item-align-box d-block">
      <fieldset disabled={props.editMode ? false : true}>
        <div className="label-heading mb-3 d-block w-100 mt-3">
          <div className="label-text rel d-inline-block px-2 title-padding">
            <h1 className="fs-16 text-white text-uppercase">
              DESCRIZIONE IMMOBILE
            </h1>
          </div>
        </div>
        <div className="d-flex flex-column mt-3">
          <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
            Descrizione
          </label>
          <textarea
            name="description"
            value={data.description}
            onChange={(e) => {
              onChange(e);
            }}
            id=""
            cols="30"
            rows="4"
            className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
          ></textarea>
        </div>
        {(typologyState === 0 || typologyState === 1) && (
          <>
            <label className="fs-20 mt-3 mb-2">
              DOTAZIONI E STRUTTURA IMMOBILE
            </label>
            <div className="label-heading mb-3 d-block w-100 mt-3">
              <div className="label-text rel d-inline-block px-2 title-padding">
                <h1 className="fs-16 text-white text-uppercase">
                  RIFINITURE E ACCESSORI INTERNI
                </h1>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <label className="mb-2 fw-semibold color-black">
                  Pavimentazione immobile
                </label>
                {pavment.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      item={item}
                      data={data}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <label className="mb-2 fw-semibold color-black">
                  Infissi e serramenti
                </label>
                {windowAndDoor.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      item={item}
                      data={data}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <label className="mb-2 fw-semibold color-black">
                  Impianti e dotazioni
                </label>
                {equipment.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      item={item}
                      data={data}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <label className="mb-2 fw-semibold color-black">
                  Arredi fissi
                </label>
                {furniture.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      item={item}
                      data={data}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mt-3">
              <div className="col-md-6 d-flex justify-content-between align-items-end">
                <div className="d-flex flex-column col-sm-8">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Immagine di riferimento nel report
                  </label>
                  <label
                    className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
                    style={{ height: "50px" }}
                  >
                    {data.reportReferenceImage
                      ? data.reportReferenceImage.name
                      : ""}
                  </label>
                </div>
                <div className="col-sm-3">
                  <label
                    htmlFor="input-file"
                    className="bag-btn w-100 general-btn"
                    style={{ height: "50px", textAlign: "center" }}
                  >
                    Scegli
                  </label>
                  <input
                    type="file"
                    id="input-file"
                    className="d-none"
                    name="image"
                    multiple={false}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="label-heading mb-3 d-block w-100 mt-3">
          <div className="label-text rel d-inline-block px-2 title-padding">
            <h1 className="fs-16 text-white text-uppercase">
              {(typologyState === 0 || typologyState === 1) &&
                "STRUTTURA & SERVIZI FABBRICATO"}
              {typologyState === 2 && "STRUTTURA E SERVIZI"}
              {(typologyState === 3 ||
                typologyState === 4 ||
                typologyState === 5 ||
                typologyState === 6 ||
                typologyState === 7 ||
                typologyState === 8) &&
                "SERVIZI"}
            </h1>
          </div>
        </div>
        {(typologyState === 0 || typologyState === 1) && (
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Struttura portante edificio
              </label>
              {structure.map((item, index) => {
                return (
                  <Checkbox
                    key={index}
                    item={item}
                    data={data}
                    onChange={onChange}
                  />
                );
              })}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Rivestimento esterno edificio
              </label>
              {cladding.map((item, index) => {
                return (
                  <Checkbox
                    key={index}
                    item={item}
                    data={data}
                    onChange={onChange}
                  />
                );
              })}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Proprietà condominiali
              </label>
              {condoProperty.map((item, index) => {
                return (
                  <Checkbox
                    key={index}
                    item={item}
                    data={data}
                    onChange={onChange}
                  />
                );
              })}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Servizi condominiali
              </label>
              {condoSevice.map((item, index) => {
                return (
                  <Checkbox
                    key={index}
                    item={item}
                    data={data}
                    onChange={onChange}
                  />
                );
              })}
            </div>
          </div>
        )}
        {typologyState === 2 && (
          <div className="row mt-3">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">
                Disposizione Interni
              </label>
              <div className="input-checkbox">
                <input
                  type="checkbox"
                  name="openSpace"
                  checked={data.openSpace}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
                <label className="f-label fs-16 d-flex align-items-center
me-2">Open space</label>
              </div>
              <div className="input-checkbox">
                <input
                  type="checkbox"
                  name="dividedByWalls"
                  checked={data.dividedByWalls}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
                <label className="f-label fs-16 d-flex align-items-center
me-2">Divisi da pareti</label>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6">
              <label className="mb-2 fw-semibold color-black">Altro</label>
              {other.map((item, index) => {
                return (
                  <Checkbox
                    key={index}
                    item={item}
                    data={data}
                    onChange={onChange}
                  />
                );
              })}
            </div>
          </div>
        )}
        {(typologyState === 3 ||
          typologyState === 4 ||
          typologyState === 5 ||
          typologyState === 6 ||
          typologyState === 7 ||
          typologyState === 8) && (
          <div className="mt-3">
            <label className="mb-2 fw-semibold color-black">Altro</label>
          </div>
        )}
        {typologyState === 3 && (
          <div>
            {otherNegozi.map((item, index) => {
              return (
                <Checkbox
                  key={index}
                  item={item}
                  data={data}
                  onChange={onChange}
                />
              );
            })}
          </div>
        )}
        {typologyState === 4 && (
          <div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="electricCarChargingStation"
                checked={data.electricCarChargingStation}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">
                Stazione di ricarica auto elettrica
              </label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="electricDoor"
                checked={data.electricDoor}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Porta elettrica</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="accessibility24h"
                checked={data.accessibility24h}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Accessibilità 24h</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="disabledAccess"
                checked={data.disabledAccess}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Accesso per disabili</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="cctv"
                checked={data.cctv}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Vigilanza cctv</label>
            </div>
          </div>
        )}
        {typologyState === 5 && (
          <div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="accessibility24h"
                checked={data.accessibility24h}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Accessibilità 24h</label>
            </div>

            <div className="input-checkbox">
              <input
                type="checkbox"
                name="disabledAccess"
                checked={data.disabledAccess}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Accesso per disabili</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="cctv"
                checked={data.cctv}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Vigilanza cctv</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="alarmSystem"
                checked={data.alarmSystem}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Impianto d’allarme</label>
            </div>
          </div>
        )}
        {(typologyState === 6 ||
          typologyState === 7 ||
          typologyState === 8) && (
          <div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="toAwayZone"
                checked={data.toAwayZone}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Passo carrabile</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="disabledAccess"
                checked={data.disabledAccess}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Accesso per disabili</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="cctv"
                checked={data.cctv}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Vigilanza cctv</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="alarmSystem"
                checked={data.alarmSystem}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Impianto d’allarme</label>
            </div>
            <div className="input-checkbox">
              <input
                type="checkbox"
                name="fencedProperty"
                checked={data.fencedProperty}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <label className="f-label fs-16 d-flex align-items-center
me-2">Proprietà recintata</label>
            </div>
          </div>
        )}
        <div className="mt-3">
          <div className="col-md-6 d-flex justify-content-between align-items-end">
            <div className="d-flex flex-column col-sm-8">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Immagine di riferimento nel report
              </label>
              <label
                className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
                style={{ height: "50px" }}
              >
                {data.realEstateImage ? data.realEstateImage.name : ""}
              </label>
            </div>
            <div className="col-sm-3">
              <label
                htmlFor="input-file2"
                className="bag-btn w-100 general-btn"
                style={{ height: "50px", textAlign: "center" }}
              >
                Scegli
              </label>
              <input
                type="file"
                id="input-file2"
                className="d-none"
                name="realEstateImage"
                multiple={false}
                onChange={handleChangeRealEstate}
              ></input>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};
const Checkbox = ({ item, data, onChange }) => {
  return (
    <div className="input-checkbox">
      <input
        type="checkbox"
        name={item.value}
        checked={data[item.value]}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <label className="f-label fs-16 d-flex align-items-center
me-2">{item.name}</label>
    </div>
  );
};

export default AmenityE;
