import {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import TopHeading from "../TopHeading"
import ButtonRm from '../ButtonRm'
import {Row, Col} from "react-bootstrap"
import Form from 'react-bootstrap/Form'

import CustomToggle from "../CustomToggle";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, fetchDeadlineaddDeadline, removeDeadlineaddDeadline, editDeadlineaddDeadline } from "../../actions/deadline";

import { add, addDays,addMonths, addWeeks, addYears, isAfter } from 'date-fns';
import { parseISO } from 'date-fns';

import CustomBadge from '../CustomBadge';

const todayDate = new Date()
const date = todayDate.toISOString().substring(0,16)

const eventData = {
    title: "",
    section: "",
    startDate: date,
    endDate:	date,
    every:	"",
    recurrenceType:	"",
    toDate:	undefined,
    notification: "",
    sharing: "",
    note: "",
    daysBeforeNotification: 0
    //manca campo per giorni di preavviso => type: number
}

const schema = yup.object().shape({
    title: yup.string().required("Inserisci un titolo per questo evento"),
    section: yup.string(),
    startDate: yup.date("Seleziona la data di inizio").required(), //mettere come valore iniziale data di oggi per tutte le date
    endDate:	yup.date("Seleziona la data di inizio").required(),
    every:	yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
    recurrenceType:	yup.string(),
    toDate:	yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    notification: yup.string(),
    sharing: yup.string(),
    note: yup.string()
    //aggiungere voci api giorni di preavviso

})

function NuovoEvento() {
    const [formData, setFormData] = useState(eventData)
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleModal = () => {
        setShow(true);
    };

    const emails = formData.share?.split(',')

    //handle whole form
    function handleFormChange(e) {
        console.log("changing..")
        const {name, value, type, checked} = e.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }


    //CREATION
    const dispatch = useDispatch();

    function postItem(data) {
        dispatch(addDeadline(data))
    }


    //RICORRENZA
    function createRecurringEvent(title, startDate, endDate, frequency, frequencyUnit, endRecurrenceDate) {
    const recurrenceIntervals = {
        "giorno/i": addDays,
        "settimana/e": addWeeks,
        "mese/i": addMonths,
        "anno/i": addYears,
    };
    const recurrenceInterval = recurrenceIntervals[frequencyUnit];
    
    const events = [];
    let nextStartDate = recurrenceInterval(parseISO(startDate), frequency);
    let nextEndDate = recurrenceInterval(parseISO(endDate), frequency);
    
    let recurrenceEndDate = parseISO(endRecurrenceDate);

    while (isAfter(recurrenceEndDate, nextStartDate)) {
        events.push({ title, startDate: nextStartDate, endDate: nextEndDate });

        nextStartDate = recurrenceInterval(nextStartDate, frequency);
        nextEndDate = recurrenceInterval(nextEndDate, frequency);

        if (isAfter(nextEndDate, endRecurrenceDate)) {
        nextEndDate = endRecurrenceDate;
        recurrenceEndDate = nextStartDate;
        }
    }

    return events;
}



      //VALIDAZIONE INPUT
        const { register, handleSubmit, formState: { errors }, reset } = useForm({
            resolver: yupResolver(schema),
        });

        const onSubmit = (data) => {
/*             let str = {
                ...formData,
                startDate: convertDataForApi(formData.startDate) ,
                endDate:convertDataForApi(formData.endDate),
                toDate: formData.toDate ? convertDataForApi(formData.toDate): formData.toDate
            } */
            // controlla se ci sono errori di validazione
            if (Object.keys(errors).length > 0) {
                console.log("Ci sono errori di validazione", errors);
                return;
            } else {
            // azione da eseguire quando i dati sono tutti corretti
            console.log("corretti", {data})
            
            if(formData.every && formData.recurrenceType && formData.toDate){
                let dateArray = createRecurringEvent(formData.title, formData.startDate, formData.endDate, formData.every, formData.recurrenceType, formData.toDate)

                 dateArray.forEach((date, index) => {
                    const dataObj = {
                        title:`${date.title} ${index + 1}`,
                        section: formData.section? formData.section : "",
                        isRecurrent: true,
                        startDate: new Date(date.startDate).toISOString().slice(0,10),
                        endDate: new Date(date.endDate).toISOString().slice(0,10) ,
    
                    }
                    postItem(dataObj)
                }) 

            } 
                postItem(formData)
            

            setFormData(eventData)
            navigate(-1)
            }

            
        };

    //HANDLE ACCORDION
    const [activeKey, setActiveKey] = useState("")

    

    return (
        <>
            <Row>
                <Col>
                    <TopHeading SmallHeading=""/>
                </Col>
            </Row>

            <Row className="mobile-container d-flex flex-column mt-md-4">
                <Col className="d-flex">
                    <h2 className="fw-bold">Nuovo Evento</h2>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center">
                <div className="bg-sm-white my-3 px-0"style={{borderRadius:"1rem", height:"fit-content", width:"98%"}}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                    
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
                            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                                <Card.Header>
                                    <CustomToggle eventKey="0" activeKey={activeKey}>Dati  <span className="mandatory-field">*</span> 
                                        <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className="d-md-flex">   
                                    
                                    <Form.Group className="mb-3 input-size">
                                        <Form.Label className="input-label">Titolo <span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                                        <Form.Control
                                        {...register("title")}
                                        isInvalid={!!errors.title}
                                        type="text" placeholder="Inserisci un titolo" 
                                        name="title" 
                                        value={formData.title} 
                                        onChange={handleFormChange}
                        
                                        />
                                        
                                    </Form.Group>

                                    <Form.Group className="mb-3 input-size">
                                        <Form.Label className="input-label">Sezione di riferimento</Form.Label>
                                        <Form.Select aria-label="Default select example" 
                                            {...register("section")}
                                            isInvalid={!!errors.section}
                                            name="section"
                                            value={formData.section} 
                                            onChange={handleFormChange} 
                                        >
                                            <option>
                                                Seleziona
                                            </option>
                                            <option value="Unità e Stabili">Unità e Stabili</option>
                                            <option value="Affitti">Affitti</option>
                                            <option value="SAL">SAL</option>
                                            <option value="Rate">Rate</option>
                                            <option value="Scadenze Personali">Scadenze Personali</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.section?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="1" activeKey={activeKey}>Date e Durata<span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className="d-md-flex ">  

                                    <Form.Group className="mb-3 input-size">
                                        <Form.Label className="input-label">Data di Inizio<span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                                        <Form.Control 
                                        {...register("startDate")}
                                        isInvalid={!!errors.startDate}
                                        type="datetime-local" 
                                        name="startDate" 
                                        value={formData.startDate} 
                                        onChange={handleFormChange} 
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.startDate?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3 input-size">
                                        <Form.Label className="input-label">Data di Fine<span className="mandatory-field">*</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                                        <Form.Control 
                                        {...register("endDate")}
                                        isInvalid={!!errors.endDate}
                                        type="datetime-local"   
                                        name="endDate" 
                                        value={formData.endDate} 
                                        onChange={handleFormChange} 
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.endDate?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="2" activeKey={activeKey}>Ricorrenza <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>Si tratta di un evento ricorrente? Anziché creare più volte lo stesso evento, puoi impostare di seguito i dettagli di ricorrenza di questo. <br/> Se ad esempio si tratta di un evento che si svolge ogni 2 mesi fino al 31/12/2023, dovrai selezionare: <br/>
                                Frequenza:  "Mesi", <br/>
                                Ogni: 2, <br/>
                                Fine Ricorrenza: "31/12/2023"
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-2"></i>
                                </OverlayTrigger>
                                </CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row>
                                </Row>
                                <Row className="d-md-flex">   
                                <Form.Group className="mb-3 input-size">
                                                <Form.Label className="input-label">Frequenza</Form.Label>
                                                <Form.Select 
                                                {...register("recurrenceType")}
                                                isInvalid={!!errors.recurrenceType}name="recurrenceType"
                                                value={formData.recurrenceType} 
                                                onChange={handleFormChange} 
                                                >
                                                    
                                                    <option value="">
                                                        Nessuna frequenza
                                                    </option>
                                                    <option value="giorno/i">Giorno/i</option>
                                                    <option value="settimana/e">Settimana/e</option>
                                                    <option value="mese/i">Mese/i</option>
                                                    <option value="anni/i">Anno/i</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" >
                                            {errors.recurrenceType?.message}
                                            </Form.Control.Feedback>
                                            </Form.Group>
                                                                        
                                            <Form.Group className="mb-3 input-size">
                                                <Form.Label className="input-label">Ogni</Form.Label>
                                                <Form.Control 
                                                {...register("every")}
                                                isInvalid={!!errors.every}
                                                type="number" placeholder="Inserisci un numero" name="every" 
                                                value={formData.every} 
                                                onChange={handleFormChange}
                                                />
                                                <Form.Control.Feedback type="invalid" >
                                                {errors.every?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3 input-size">
                                                <Form.Label className="input-label">Fine Ricorrenza</Form.Label>
                                                <Form.Control 
                                                {...register("toDate")}
                                                isInvalid={!!errors.toDate}type="datetime-local"   
                                                name="toDate" 
                                                value={formData.toDate} 
                                                onChange={handleFormChange}
                                                />
                                                <Form.Control.Feedback type="invalid" >
                                                {errors.toDate?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="3" activeKey={activeKey}>Notifiche</CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className="d-md-flex "> 

                                <Form.Group className="mb-3 input-size">
                                        <Form.Label className="input-label"> Quanti giorni prima di questo evento vuoi essere avvisato?</Form.Label>
                                        <Form.Control 
                                        type="number" 
                                         name="daysBeforeNotification"
                                         value={formData.daysBeforeNotification} 
                                         onChange={handleFormChange}

                                    />
                                    <Form.Control.Feedback type="invalid" >
                                        
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group className="mb-3 mb-sm-0 input-size">
                                    <Form.Label className="input-label">Dove vuoi ricevere la notifica?</Form.Label>
                                    <Form.Select
                                    {...register("notification")}
                                    isInvalid={!!errors.notification} 
                                    name="notification"
                                    value={formData.notification} 
                                    onChange={handleFormChange}
                                    >
                                        <option>
                                            
                                        </option>
                                        <option value="Gestionale">Notifica sul gestionale</option>
                                        <option value="Email">Notifica per Email</option>
                                        <option value="Entrambe">Entrambe</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                        {errors.notification?.message}
                                        </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="4" activeKey={activeKey}>Altro</CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <Row className="d-md-flex ">  

                                <Form.Group className="mb-3 mb-sm-0 input-size">
                                        <Form.Label className="input-label">Condividi</Form.Label>

                                        <Form.Select
                                            {...register("sharing")}
                                            isInvalid={!!errors.sharing}
                                            name="sharing"
                                            value={formData.sharing} 
                                            onChange={handleFormChange}
                                        >
                                        <option>
                                            
                                        </option>
                                        <option value=""></option>
            
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                        {errors.notification?.message}
                                        </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 input-size">
                                    <Form.Label className="input-label">Note</Form.Label>
                                    <Form.Control
                                        {...register("note")}
                                        isInvalid={!!errors.note} 
                                        as="textarea"
                                        name="note" 
                                        value={formData.note} 
                                        onChange={handleFormChange} 
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {errors.note?.message}
                                        </Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                            <div className='d-flex justify-content-end my-3' >
                                <ButtonRm variant="tertiary" className="me-2" onClick={handleModal}>Annulla</ButtonRm>
                                <ButtonRm variant="primary" type="submit">Salva</ButtonRm>
                            </div>
                    </Form>
                </div>
            </Row>

            <Modal show={show} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi uscire dalla profilazione?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              I dati inseriti verranno persi
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={() => {
                setFormData(eventData)
                navigate(-1);
              }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => setShow(false)}
            >
              Indietro
            </button>
          </div>
        </Modal.Body>
      </Modal>
        </>

    )
}

export default NuovoEvento