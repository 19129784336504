import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";

import moment from "moment";

export default function Section2({ onChangeSection }) {
  const [startDate, setStartDate] = useState(new Date().toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().substring(0, 10));
  const [inputNumber, setInputNmber] = useState(null);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [Sanzioni, setSanzioni] = useState(0);
  const [Interessi, setInteressi] = useState(0);
  const [betweenDate, setBetweenDate] = useState(0);
  const [percent, setPercent] = useState(0);
  const [totalPercent, setTotalPercent] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);

  /* const calClicked = () => {
    if (inputNumber !== "" && !inputNumber !== null) {
      setIsShowDetail(true);
      let start = moment(startDate);
      let end = moment(endDate);
      let date = end.diff(start, "day");
      let inter = (parseInt(date) / 365) * 0.0125;
      let san = 0;
      let per;
      let totalbud;

      setBetweenDate(date);
      if (date >= 0 && date <= 14) {
        san = (0.2 / 100) * date;
        per = 0.2;
      } else if (date > 14 && date <= 30) {
        san = (3 / 100) * date;
        per = 3;
      } else if (date > 30 && date <= 90) {
        san = (3.33 / 100) * date;
        per = 3.33;
      } else if (date > 90 && date <= 365) {
        san = (3.75 / 100) * date;
        per = 3.75;
      } else if (date > 365 && date <= 730) {
        san = (4.29 / 100) * date;
        per = 4.29;
      } else if (date > 730) {
        san = (5 / 100) * date;
        per = 5;
      }
      setPercent(per);
      setSanzioni(san * inputNumber);
      setInteressi(inter * inputNumber);
      setTotalPercent(san);
      setTotalBudget(inputNumber * 1 + san * inputNumber * 1 + inter * 1);
    }
  }; */

// Funzione per calcolare la differenza in giorni tra due date
function giorniTraDate(data1, data2) {
  var diff = Math.abs(data2.getTime() - data1.getTime());
  var giorni = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return giorni;
}

const [message, setMessage] = useState("")
const [messageInteressi, setMessageInteressi] = useState("")

//calcola ravvedimenti bollo

  function calcolaRavvedimenti(dataStipula, dataPagamento, importo) {
    const dataStipulaConverted = new Date(dataStipula)
    const dataPagamentoConverted = new Date(dataPagamento)
    var giorniPassati = giorniTraDate(dataStipulaConverted, dataPagamentoConverted) ;
    console.log(giorniPassati)
    var sanzione = 0;
    var interessi = 0;
  
    if (giorniPassati <= 0) {
      setMessage("Niente da pagare")
    }
  
      if (giorniPassati > 0) {
        if (giorniPassati > 0 && giorniPassati <= 14) {
          sanzione = importo * (0.001 * giorniPassati); // 0,% per ogni giorno di ritardo
          setMessage(`€${sanzione.toFixed(2)} = 0.2% dell'imposta entro il 14° giorno`)
        } else if (giorniPassati > 14 && giorniPassati <= 30) {
          sanzione = importo * (0.015 ); // 1.5%
          setMessage(`€${sanzione.toFixed(2)} = 3% dell'imposta entro il 30° giorno`)
        } else if (giorniPassati > 30 && giorniPassati <= 90) {
          sanzione = importo * (0.0167 ); // 1,67%
          setMessage(`€${sanzione.toFixed(2)} = 3.33% dell'imposta entro il 90° giorno`)
        } else if (giorniPassati > 90 && giorniPassati <= 365) {
          sanzione = importo * (0.0375 ); // 3,75%
          setMessage(`€${sanzione.toFixed(2)} = % 3.75 dell'imposta entro 1 anno`)
        } else if (giorniPassati > 365 && giorniPassati <= 730) {
          sanzione = importo * (0.0429 ); // 4,29%
          setMessage(`€${sanzione.toFixed(2)} = 4,29% dell'imposta entro 2 anni`)
        } else if (giorniPassati > 730) {
          sanzione = importo * (0.05 ); // 5%
          setMessage(`€${sanzione.toFixed(2)} = 5% dell'imposta oltre 2 anni`)
        }
        const interesseAnnuale = 0.025; // 2,5% annuale 2024
        const interesseGiornaliero = interesseAnnuale / 365; // Interesse giornaliero
        interessi = importo * interesseGiornaliero * giorniPassati;
      } 
      //mostra sanzioni e interessi
      setSanzioni(sanzione.toFixed(2))
      setInteressi(interessi.toFixed(2))
      setMessageInteressi(`€${interessi.toFixed(2)} - Totale interessi (2,5% annuo)`)
      setIsShowDetail(true)

    }

  //DATI PER ESPORTAZIONE
  const item = {
    ['Imposta dovuta']: `€ ${inputNumber}`,
    ['Sanzioni']: message,
    ['Interessi']: messageInteressi,
    ['Totale da pagare']: `Totale da pagare: € ${(inputNumber + parseFloat(Interessi) + parseFloat(Sanzioni)).toFixed(2)} `,
  }
  


  return (
    <div className={`page-body`}>
      {/* <div className='page-title'>
                <Image src={nav2} width="100%" />
            </div> */}
      <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">Date</h6>
        </div>
      </div>
      <div className="space-top1">
        <div className="sub-title">Data di stipula del contratto</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <input
                type="date"
                className="dateInput"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="space-top1">
        <div className="sub-title">Data timbratura bolli</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <input
                type="date"
                className="dateInput"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">
            Importi
          </h6>
        </div>
      </div>
      {/* <div className='page-title space-top'>
                <Image src={nav3} width="100%" />
            </div> */}
      <Row>
        <Col md={5}>
            <div className="sub-title">Imposta dovuta</div>
            <div className="divInput">
              <span className="spanInput1">€</span>
              <input
                type="number"
                className="input-calc-symbol"
                value={inputNumber}
                onChange={(e) => {
                  let convertedValue = parseFloat(e.target.value.replace(",", "."))
                  setInputNmber(convertedValue)
                }}
              ></input>
            </div>
          </Col>
          <Col>
          <ButtonRm
                  style={{ marginTop: "1.5rem" }}
                  variant="secondary"
                  size="small"
                  onClick={() => calcolaRavvedimenti(startDate, endDate, inputNumber)}
                  disabled={(startDate && endDate && inputNumber) ? false : true}
                >
                  Calcola
                </ButtonRm>
          </Col>
      </Row>


      {isShowDetail && (
        <div>
          <div className="space-top1">
            <Row>
              <Col xs={6}>
                <div className="sub-title">Sanzioni</div>
              </Col>
              <Col xs={6} style={{ padding: "0" }}>
                <div className="sub-title">Interessi</div>
              </Col>
            </Row>

            <Row className="space-top1">
              <Col xs={6}>
                <div className="input-text-native">
                  € {parseFloat(Sanzioni).toFixed(2)}
                </div>
              </Col>
              <Col xs={6}>
                <div className="input-text-native">
                  € {parseFloat(Interessi).toFixed(2)}
                </div>
              </Col>
            </Row>
          </div>

          <div className="page-title">
            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Elenco dei calcoli
                </h6>
              </div>
            </div>
          </div>


          <div className="text-end ms-auto d-flex align-items-center justify-content-end">
            <ExportButton data={item} mapping={{}}  isButton={true}classes="top-btn-icon ms-auto"/>
{/*             <ShareChat styleBtn="top-btn-icon" />
 */}          
          </div>

          {<div className="sub-title">
             {
              Sanzioni > 0 ?
              <div>
                <p className="fw-bold text-uppercase">Sanzione</p>
                <p className="mb-3">{message}</p>
                <p className="fw-bold text-uppercase">Interessi</p>
                <p className="mb-3">{messageInteressi}</p>
                <p className="fw-bold text-uppercase">Totale da pagare: € {(inputNumber + parseFloat(Interessi.replace(",", ".")) + parseFloat(Sanzioni.replace(",", ".")))} </p>

              </div> 
              :
              <p>{message}</p>
             }

            </div>}
         
        </div>
      )}
    </div>
  );
}
