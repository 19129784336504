import { useState } from "react";
import UserIcon from "../../../assets/images/small_user.svg";
import {
	BsChevronDown,
	BsChevronUp,
	BsDownload,
	BsTrash,
} from "react-icons/bs";
import REUnitCard from "../UnitàImmobiliari/REUnitCard";
import api, { stabili_endpoint } from "../../../api/api";
import { Link, useNavigate } from "react-router-dom";
import estate_img from "../../../assets/images/real_estate.png";
import { Modal } from "react-bootstrap";
import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "./mapping";
import ShareChat from "../../ShareChat";

const StabiliCard = ({
	id,
	name,
	address,
	cap,
	province,
	deleteStabili,
	units,
	administrator,
	item,
	handleSelection,
	selectedItems

}) => {
	
	const deleteCard = async () => {
		deleteStabili(id);
		try {
		await api().delete(`${stabili_endpoint}${id}`);
		} catch (err) {
		console.log(err);
		}
	};

	//navigateTo
	const navigate = useNavigate()

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	const [subMenu, setSubMenu] = useState(false);

	const handleSubMenu = () => {
		setSubMenu(!subMenu);
	};

	const [checked, setChecked] = useState(false);

	
	const handleCheckboxChange = (e) => {
		e.stopPropagation(); // Impedisce la propagazione del clic alla card
		setChecked(!checked);
		handleSelection(id, !checked); // Passa l'id e lo stato attuale del checkbox al componente padre
	}

	const handleCardClick = (e) => {
		// Verifica se l'elemento cliccato è il checkbox
		if (e.target.type === "checkbox") {
		  return; // Se sì, non fare nulla
		}
		// Altrimenti, esegui l'azione desiderata
		// Per esempio, naviga al link della card
		// In questo caso, il codice qui sotto è solo un esempio
		// Puoi personalizzare questa parte a seconda dell'azione che desideri eseguire
		navigate(`/stabili/edit/${item.id}`)
	}

	return (
		<>
		<div className="general-card mt-2 ms-0 cursor-pointer" onClick={handleCardClick}>
			<div className="d-flex flex-column flex-auto">
			<div className="d-flex align-items-center">
				<input
				type="checkbox"
				checked={selectedItems.includes(id)}
				onChange={(e) => handleSelection(e, id)}
				className="me-2"
				/>
				<span className="general-card-title text-capitalize fs-14">{name}</span>
			</div>
			<span className="small-span">
				{`${address} , ${province}, CAP ${cap}`}
			</span>
			{administrator && (
				<div className="d-flex">
				<div className="me-2">
					<img
					loading="lazy"
					src={UserIcon}
					alt=""
					className="me-1 small-icon"
					/>
					<span className="small-span">{administrator}</span>
				</div>
				</div>
			)}
			</div>
			{units.length > 0 && (
			<div>
				<button
				type="button"
				className="btn round-icon-btn"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleSubMenu();
				}}
				>
				{subMenu ? <BsChevronUp /> : <BsChevronDown />}
				</button>
			</div>
			)}
{/* 						<ShareChat elId={id} />
 */}

			<div>
			<button
				type="button"
				className="btn round-icon-btn"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleShow();
				}}
			>
				<BsTrash />
			</button>
			</div>
			<ExportButton
			data={item}
			mapping={translationMapping}
			classes="btn round-icon-btn btn me-2"
			/>
		</div>

		{subMenu && (
			<div className="sub-menu-card-container">
			{units.map((item, index) => {
				return (
				<div key={index} className="mt-1">
					<Link to={`/real-estate-units/edit/${item.id}`}>
					<REUnitCard
						id={item.id}
						type={item.category}
						name={item.name}
						address={item.address}
						internal={item.internal}
						scale={item.scale}
						province={item.province}
						cap={item.cap}
						floor={item.floor}
						img_url={estate_img}
						categoryLandRegistry={item.categoryLandRegistry}
						coveredMq={item.grossCoveredMq}
						notCoveredMq={item.notCoveredMq}
						subMenu={subMenu}
						checkIsVisible={false}
						item={item}
					/>
					</Link>
				</div>
				);
			})}
			</div>
		)}

		<Modal show={show} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteCard();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>
		</>
	);
};

export default StabiliCard;
