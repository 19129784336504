import { useState, useEffect } from "react";
import EmptyCard from "../../../assets/images/bank-icon.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AccountModal from "./AccountModal";
import AccountCard from "./AccountCard";
import ButtonRm from "../../ButtonRm";
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMappingConti from "../../mappingConti";


const AccountE = ({ accounts, update }) => {
	const [data, setData] = useState([]);

	const init = () => {
		setData(accounts);
	};
	useEffect(() => {
		init();
	}, [accounts]);

	const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		let temp = { currentAccounts: data };
		update(temp);
	};

	const cancelEdit = () => {
		setEditMode(false);
		// setBackModalShow(true);
	};

	const [show, setShow] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [id, setId] = useState(0);

	const add = (account) => {
		let temp = [...data];
		temp.push(account);
		setData(temp);
		setShow(false);
	};

	const updateData = (account) => {
		let temp = [...data];
		temp[id] = account;
		setData(temp);
		setEditModal(false);
	};

	const handleClick = (index) => {
		setId(index);
		setEditModal(true);
	};

	const remove = (index) => {
		let newArr = data.filter((el, i) => i != index)
		setData(newArr);
	};

	console.log(data, accounts)
	return (
		<div className="">
			<div className="save-change-btn-group-stabili">
				<div
					className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}>
					<div
						className="edit-btn w-144 me-2"
						onClick={() => {
							setEditMode(true);
						}}>
						Modifica
					</div>
				</div>
				<div
					className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}>
					<div className="edit-btn w-173 me-2" onClick={handleSave}>
						Salva modifiche
					</div>
					<button
						type="button"
						onClick={cancelEdit}
						className="border border-secondary bg-white rounded-3 edit-icon-btn">
						<i
							className="bi bi-x"
							style={{ color: "var(--edit-color)" }}></i>
					</button>
				</div>
			</div>
			<div className="edit-content-container-white mt-3 py-5">
				<div className={editMode ? "" : "d-none"}>
					<ButtonRm onClick={() => {
							setShow(true);
						}}>Nuovo</ButtonRm>
				</div>
				{!editMode && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					<ExportButton data={data} showPrint mapping={translationMappingConti} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
				<div className="row">
					{data?.length > 0 ? (
						data.map((item, index) => {
							return (
								<div
									className="col-md-6"
									onClick={() => {
										handleClick(index);
									}}>
									<AccountCard
										item={item}
										key={index}
										remove={remove}
										id={index}
										editMode={editMode}
									/>
								</div>
							);
						})
					) : (
						<div className="unit-empty-img">
							<img loading="lazy" src={EmptyCard} alt="No image" />
							<span className="img-comment">
								Non hai aggiunto ancora alcun conto
							</span>
						</div>
					)}
				</div>
				<Modal
					show={show}
					fullscreen={false}
					className="right-full-pop"
					backdrop>
					<Modal.Body className="p-4">
						<Button
							variant=""
							onClick={() => {
								setShow(false);
							}}
							className="float-end border-0 bg-white"
							style={{ marginTop: "-1rem" }}>
							<i className="bi bi-x"></i>
						</Button>
						<AccountModal
							setShow={setShow}
							newMode={true}
							add={add}
							editMode={true}
						/>
					</Modal.Body>
				</Modal>
				<Modal
					show={editModal}
					fullscreen={false}
					className="right-full-pop"
					centered
					backdrop>
					<Modal.Body className="p-4">
						<Button
							variant=""
							onClick={() => {
								setEditModal(false);
							}}
							className="float-end border-0 bg-white"
							style={{ marginTop: "-1rem" }}>
							<i className="bi bi-x"></i>
						</Button>
						<AccountModal
							setShow={setEditModal}
							newMode={false}
							update={updateData}
							account={data ? data[id] : undefined}
							editMode={true}
						/>
					</Modal.Body>
				</Modal>
			</div>
		</div>
	);
};

export default AccountE;
