import { useState, useEffect } from "react"
import TopHeading from "../TopHeading"
import {Row, Col} from "react-bootstrap"
import AddBtn from "../AddBtn"
import SearchInput from "../SearchInput"
import {OverlayTrigger} from "react-bootstrap"

import { useNavigate } from "react-router-dom";


import React from 'react'

import {Tooltip} from "bootstrap"

import '../../assets/css/AlertCalendario.css'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import itLocale from '@fullcalendar/core/locales/it'

import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter"

import { useDispatch, useSelector } from "react-redux";
import { addDeadline, removeDeadline, editDeadline, fetchDeadline } from "../../actions/deadline";

import googleCalendarPlugin from '@fullcalendar/google-calendar';
import GoogleCal from "./GoogleCal"

import { checkUserData } from "../../utils/checkUserRole"
import ModalCheckUser from "../ModalCheckUser"
import GAuth from "./GAuth"



function AlertCalendario(){
    const navigate = useNavigate()
    let tooltipInstance = null;

    function createEvent(){
        navigate("/alert/nuovo-evento");
    }

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const [events, setEvents] = useState([]);



    // actions
    const handleMouseEnter = (info) => {
        if (info.event.title) {
            let tooltipTitle = `
                <h6 className="text-capitalize">${info.event.title}</h6>
                ${info.event.extendedProps.section ? `<small class="text-uppercase">${info.event.extendedProps.section}</small>` : ""}
                <p class="text-capitalize">Inizio: ${info.event.start ? capitalizeFirstLetter(new Date(info.event.start).toLocaleDateString('it-IT', options)) : ""}</p>
            `;
    
            if (info.event.extendedProps.endRecurring) {
                tooltipTitle += `<p class="text-capitalize">Fine: ${capitalizeFirstLetter(new Date(info.event.extendedProps.endRecurring).toLocaleDateString('it-IT', options))}</p>`;
            }
    
            tooltipInstance = new Tooltip(info.el, {
                title: tooltipTitle,
                html: true,
                placement: "top",
                trigger: "hover, click",
                container: "body",
                customClass: "event-style"
            });
    
            tooltipInstance.show();
        }
    };
    
    
    

    const handleMouseLeave = (info) => {
        if (tooltipInstance) {
        tooltipInstance.dispose();
        tooltipInstance = null;
        }
    };

    useEffect(() => {
        // Distruggi il tooltip quando la pagina viene scaricata
        return () => {
          if (tooltipInstance) {
            tooltipInstance.dispose();
            tooltipInstance = null;
          }
        };
      }, []);

    const dispatch = useDispatch();

     //GET DEADLINE LIST FROM REDUX
    const deadlineList = useSelector((state) => state.deadlineReducer?.deadline);

    //get deadline when this component render for the first time
    useEffect(() => {
        dispatch(fetchDeadline());
    }, []); 
    



    const handleEventClick = (event) => {
        // Reindirizza l'utente alla pagina dei dettagli dell'evento quando si clicca sull'evento

        console.log(event, "event on click")
        if (tooltipInstance) {
            tooltipInstance.dispose();
            tooltipInstance = null;
            }
        navigate(`/alert/scadenzario/${event.event.id}`)
    };


    const handlePrint = () => {
        window.print();
    };

  
  console.log(events, "new events")

  useEffect(() => {
    const newEvents = [];

    deadlineList.forEach((item) => {
        const startDate = new Date(item.startDate);
        let endDate = item.endDate ? new Date(item.endDate) : new Date(item.startDate);
        
        // Assicurati che l'ultimo giorno sia incluso per eventi multi-giorno
        endDate.setHours(23, 59, 59);

        const isSingleDayEvent = startDate.toDateString() === endDate.toDateString();
        
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            const start = new Date(d);
            let end = new Date(d);

            // Se l'evento è di un singolo giorno e non ricorrente, setta la fine 1 minuto dopo l'inizio
            if (isSingleDayEvent) {
                end.setMinutes(start.getMinutes() + 1);
                newEvents.push({
                    id: `${item.id}`,
                    title: item.title.charAt(0).toUpperCase() + item.title.slice(1),
                    start: start.toISOString(),
                    end: end.toISOString(),
                    color: getColorForSection(item.section),
                    display: 'block', // Usa 'block' per eventi singoli di un giorno
                    extendedProps: {
                        section: item.section,
                        endRecurring: item.endDate,
                        source: "custom"

                    }
                });
            } else {
                // Per eventi ricorrenti o che si estendono su più giorni, utilizza l'orario di fine originale
                // e imposta la visualizzazione come 'dot'
                end = new Date(start);
                end.setHours(23, 59, 59); 
                newEvents.push({
                    id: `${item.id}`,
                    title: item.title.charAt(0).toUpperCase() + item.title.slice(1),
                    start: start.toISOString(),
                    end: end.toISOString(),
                    color: getColorForSection(item.section),
                    display: 'list-item', 
                    extendedProps: {
                        section: item.section,
                        endRecurring: item.endDate,
                        source: 'custom'

                    }
                }); 

               /*  if (item.endDate && (new Date(item.startDate).toDateString() === new Date(item.endDate).toDateString())) {
                    end.setMinutes(start.getMinutes() + 1);
                } else {
                    
                    end = new Date(endDate);
                }
    
                newEvents.push({
                    id: `${item.id}`,
                    title: item.title.charAt(0).toUpperCase() + item.title.slice(1),
                    start: start.toISOString(),
                    end: end.toISOString(),
                    color: getColorForSection(item.section),
                    display: 'list-item', 
                    extendedProps: {
                        section: item.section,
                        endRecurring: item.endDate
                    }
                }); */
            }
        }
    });

    setEvents(newEvents);
}, [deadlineList]);



    // Funzione per ottenere il colore in base alla sezione
    const getColorForSection = (section) => {
        switch (section) {
            case 'SAL':
                return '#FFA17A';
            case 'Affitti':
                return '#0059AE';
            case 'Unità e Stabili':
                return '#9562B3';
            case 'Scadenze Personali':
                return '#008F80';
            default:
                return 'darkgray';
        }
    };

    const API_KEY = 'AIzaSyBS0ycI1BZmkjenR6AJp71ovvCBlF2bsPg';


  /* 
    const calendarEl = document.getElementById('calendar')

      let calend = new Calendar(calendarEl, {
        plugins: [ googleCalendarPlugin ],
        googleCalendarApiKey: API_KEY,
        events: {
          googleCalendarId: 'emanuelamaviglia91@gmail.com'
          
        }
      });

      calendar.render() */


    const [googleCalendarEvents, setGoogleCalendarEvents] = useState(JSON.parse(localStorage.getItem('googleCalendarEvents')) || [])

    console.log(googleCalendarEvents, "ehhhi")

    const transformGoogleCalendarEvents = (googleCalendarEvents) => {
        return googleCalendarEvents?.length > 0 && googleCalendarEvents.map(event => ({
            title: event.summary,
            start: event.start.dateTime,
            end: event.end.dateTime,
            color: '#4285F4', 
            display: 'block', 
            extendedProps: {
                source: 'Google Calendar', // Marca gli eventi come provenienti da Google Calendar
                section: 'Google Calendar'
            }
        }));
    };

    console.log(googleCalendarEvents, "ehhhi", events)

    const [eventsMix, setEventsMix] = useState(events)

    useEffect(() => {
        const transformedGoogleEvents = transformGoogleCalendarEvents(googleCalendarEvents);
      
        const combinedEvents = (Array.isArray(transformedGoogleEvents) && transformedGoogleEvents.length > 0) ? [...events, ...transformedGoogleEvents] : events
        console.log('eventsM comining', combinedEvents, transformedGoogleEvents)

        
        setEventsMix(combinedEvents); // Aggiorna lo stato degli eventi con la combinazione
      }, [googleCalendarEvents, events]); // Dipendenze: ricarica ogni volta che gli eventi di Google Calendar cambiano
      

    console.log('eventsMix', eventsMix)


    //CHECK IF DATA REQUIRED ARE COMPILED
    const [dataRequired, setDataRequired] = useState(true);
    //Gestisce il modale
    const [showCheck, setShowCheck] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await checkUserData();
                setDataRequired(response.isVerified);
                if (!response.isVerified) {
                  setShowCheck(true);
              }
            } catch (error) {
                // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                console.error('Errore durante il recupero dei dati utente:', error);
            }
        };

        fetchData();
    }, []);

    return(
        <>
            <Row>
                <Col>
                    <TopHeading SmallHeading="empty"/>
                </Col>
            </Row>

           

            <Row className="d-flex flex-column mt-md-4">
                <Col  className="d-flex mt-3 justify-content-md-between align-items-center">
                    <h2 className="fw-bold text-center me-3">Calendario</h2>
                    <AddBtn tooltip="Nuovo evento" className="me-3 me-md-0 order-md-1"
                    onClick={createEvent} dataRequired={dataRequired}
                    
                    />
                </Col>
            </Row> 

            <Row className="mt-4">
                {/* <button onClick={handlePrint}>Stampa</button> */}
                <Col>

{/*                 <GoogleCal setGoogleCalendarEvents={setGoogleCalendarEvents} setEventsMix={setEventsMix} events={events} />    
 */}
                {/* <FullCalendar
                plugins={[dayGridPlugin, googleCalendarPlugin]}
                initialView="dayGridMonth"
                googleCalendarApiKey={API_KEY}
                events={[
                    { googleCalendarId: 'emanuelamaviglia91@gmail.com' },
                ]}
            /> */}

                

                <FullCalendar
                    initialView='dayGridMonth'
                    eventMouseEnter={handleMouseEnter}
                    eventMouseLeave={handleMouseLeave}
                    weekends={true}
                    locale={itLocale}
                    allDaySlot={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}

                    events={eventsMix}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                />
            

                </Col>
            </Row>

            <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione delle scadenze, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
        
        {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
      </ModalCheckUser>
        </>
    )
}

export default AlertCalendario



// Funzione personalizzata per il rendering del contenuto degli eventi
function renderEventContent(eventInfo) {
    // Stile per il dot basato sul colore dell'evento
    const dotStyle = {
        color: eventInfo.event.backgroundColor, // Utilizza il colore dell'evento per il dot
    };

    return (
        <div className="custom-event-content">
            <span className="dot" style={dotStyle}>●</span>
            <span>{eventInfo.event.title}</span>
        </div>
    );
}


