import React from "react"
import { Row, Col } from "react-bootstrap"

import FilterSearchBar from "./FilterSearchBar"
import { UnitàCardMap, UnitàCardMapMobile} from "../Unità/UnitàCard"

import SearchIllustration from "../../../assets/images/search-illustration.svg";
import EmptyState from '../../../assets/images/unità-empty-state.svg'

import { useMediaQuery } from 'react-responsive'

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap  } from 'react-leaflet'

import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pc from '../../../assets/images/placeholder-unit-medium.png'

const defaultCenter = [41.9028, 12.4964]; // Questo potrebbe essere il centro di una zona di tuo interesse
const defaultZoom = 5;






function MapViewUnit(props){

    console.log(props, "proppps")

    const isDesktop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    //Settings for carousels Unit map card mobile
    let settings = {
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                }
            },
        ]
    }

    /*MAPPA*/

    //CHANGE MARKER ICON
    function myIcon(){
        return L.icon({
            iconUrl: require('../../../assets/images/pin-map.png'),
            iconSize:35
        })
    }

    //Modify map view
    function ChangeView({ center, zoom }) {
        console.log(center,zoom)

        const map = useMap();
    
        // Controlla se le coordinate sono valide prima di modificare la vista
        if (center && !isNaN(center[0]) && !isNaN(center[1])) {
            map.flyTo(center, zoom);
        }
        
        return null;
    }

      function parseCoordinate(coord) {
        if (typeof coord === 'string') {
          return parseFloat(coord);
        }
        return coord;
    }

    function isValidCoordinate(coord) {
        if (!Array.isArray(coord) || coord.length !== 2) {
            return false;
        }

        const [lat, lng] = coord.map(parseCoordinate);

        return typeof lat === "number" && !isNaN(lat) && 
               typeof lng === "number" && !isNaN(lng);
    }

    function getCenter() {
        const coord = props.pinZoomed.coordinate;

        if (isValidCoordinate(coord)) {
            return coord.map(parseCoordinate);
        }

        for (let i = 0; i < props.coordinateArr.length; i++) {
            if (isValidCoordinate(props.coordinateArr[i])) {
                return props.coordinateArr[i].map(parseCoordinate);
            }
        }

        return [41.9028, 12.4964];
    }
      


      



    return (
        <Row className="mx-0">
            { isDesktop &&
                <Col lg={4} className="d-flex justify-content-center col-2-xxxl" >
                    <div className="unit-container">

                        {
                            props.units?.length > 0 &&
                            <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between px-3">
                                <FilterSearchBar
                                setSearchTerm={props.setSearchTerm}
                                selectedCategories={props.selectedCategories}
                                setSelectedCategories={props.setSelectedCategories}
                                clearFiltersAndSort={props.clearFiltersAndSort}
                                setSortBy={props.setSortBy}
                                categories={props.categories}
                                                setStartDate={props.setStartDate} setEndDate={props.setEndDate}

                                />
                            </Col>
                        }

                        {  props.units?.length > 0 &&
                            <div className="card-map-container my-1 d-flex flex-column
                            align-items-center">                       
                            {   props.sortedItems.length > 0 ?
                                props.sortedItems.map( unit => {
                                        return <UnitàCardMap
                                            key={unit.id}
                                            id={unit.id}
                                            name={unit.name}
                                            image={unit.image}
                                            adress={unit.adress}
                                            type={unit.category}
                                            handleClick={
                                                () => props.zoomOnAPin(unit.coordinate)
                                            }
                                        /> 
                                    }) :
                                    <div className="my-4 mx-auto text-center">
                                        <div className="mx-auto mb-2 mt-5 w-60">
                                            <img loading="lazy" className="img-fluid" src={SearchIllustration} alt="empty-image" />
                                        </div>
                                        <p className="fs-14 text-secondary text-center">
                                            Nessuna corrispondenza trovata
                                        </p>
                                    </div>                     
                            }
                        </div>
                        } 

                        { props.units?.length===0 &&
                            <div className="my-5 mx-auto text-center d-flex flex-column
                            align-items-center justify-content-center ">
                                <div className="mx-auto mb-4 w-80">
                                    <img loading="lazy" className="img-fluid" src={EmptyState} alt="empty-image" />
                                </div>
                                <p className="fs-12 text-secondary text-center">
                                    Non hai ancora inserito alcuna unità
                                </p>
                            </div>    
                        }            
                    </div>
                </Col>
            }

            <Col lg={8} className="col-10-xxxl">
                <div className="unit-container">
                <MapContainer
            bounds={props.coordinateArr.every(isValidCoordinate) ? props.coordinateArr : [[41.9028, 12.4964], [41.9028, 12.4964]]}
            scrollWheelZoom={false}
                    className="position-relative"
                    zoom={props.pinZoomed.zoom || defaultZoom}
                    center={getCenter()}
                    >
                        <TileLayer
                            retina
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                         <ChangeView center={getCenter()} zoom={props.pinZoomed.zoom || defaultZoom} />




                        {props.sortedItems.length > 0 &&
                            <>
                                {
                                      props.sortedItems.map(unit => {
                                        return isValidCoordinate(unit["coordinate"]) ?
                                            <Marker position={unit.coordinate} 
                                            key={unit.data}

                                            //icon={myIcon()}
                                            eventHandlers={{
                                                click: () => {
                                                    console.log("Marker clicked!");
                                                    props.zoomOnAPin(unit.coordinate)}
                                            }}
                                            >
                                            {/* <Popup>
                                                <Link to="/">
                                                    <div className='unitàCardPopup d-flex align-items-center border-none'>
                                                            <div className="img-circle-map me-3">
                                                                <img loading="lazy" className='img-fluid unit-img' src={unit.image? unit.image : pc}/>
                                                            </div>

                                                            <div>
                                                                <p className='fs-10 text-uppercase opacity-50'>{unit?.type}</p>
                                                                <p className='fw-bold fs-14'>{unit?.name}</p>
                                                                <p className='fs-10'>{unit?.adress}</p>
                                                            </div>
                                                    </div>
                                                </Link>
                                            </Popup> */}
                                        </Marker>:
                                        null
                                    })
                                }
                            </>
                        }

                    </MapContainer>

                    { !isDesktop &&
                    <Slider {...settings} className="unit-card-mobile-container">
                        {   props.sortedItems.length > 0 &&
                                    props.sortedItems.map( unit => {
                                            return <UnitàCardMapMobile
                                                key={unit.id}
                                                id={unit.id}
                                                name={unit.name}
                                                image={unit.image}
                                                adress={unit.adress}
                                                type={unit.category}
                                                handleClick={
                                                    () => props.zoomOnAPin(unit.coordinate)
                                                }
                                            /> 
                                            }) 
                            }
                    </Slider>
                    }
                    
                </div>
            </Col>

            </Row>
    )
}


export default MapViewUnit