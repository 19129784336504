import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import ConductorCard from "../ConductorCard";
import ConductorModal from "../ConductorModal";

const GuarantorE = (props) => {
  const [show, setShow] = useState(false);
  const [table_array, setTableArray] = useState(["", ""]);
  const table_data = [
    [
      "Mario",
      "Rossi",
      "Via Berli",
      12,
      "Bologna",
      "BO",
      "75646",
      "RSSMRO76CF5D929T",
    ],
    [
      "Giulia",
      "Verdi",
      "Via Berli",
      13,
      "Bologna",
      "BO",
      "75646",
      "RSSMRO76CF5D929T",
    ],
  ];
  return (
    <div className="form-item-align-box d-block">
      <div className="edit-content-container mt-5">
        <div className="label-heading d-block w-100">
          <div className="label-text rel d-inline-block px-2 title-padding">
            <h1 className="fs-16 text-white text-uppercase">ATTUALI</h1>
          </div>
        </div>
        <div className="d-flex justify-content-start mt-3">
          <div className="edit-btn edit-white me-2 w-144">Nuovo</div>
          <div
            className="edit-btn edit-white w-144"
            onClick={(e) => {
              setShow(true);
            }}
          >
            Seleziona
          </div>
        </div>
        <div className="card-container">
          {table_array.map((item, index) => {
            return (
              <div style={{ width: "90%" }}>
                <ConductorCard key={index} edit_mode={props.edit_mode} />
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        show={show}
        className="type-modal"
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        size="lg"
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            onClick={(e) => {
              setShow(false);
            }}
            className="float-end border-0 bg-white"
          >
            <i class="bi bi-x"></i>
          </Button>
          <ConductorModal
            table_data={table_data}
            setShow={setShow}
            edit_mode={props.edit_mode}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GuarantorE;
