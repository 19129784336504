//PASSARE DATA DEL FORMATO ISO
const formattedTimestamp = (d)=> {
    const date = d.split('T')[0];
    const time = d.split('T')[1].slice(0, 5);
    return `${date} ${time}`
}

//PASSARE 2 PARAMETRI COME new Date("2024/05/20")
const dateDifferenceInDays = (dateInitial, dateFinal) => {
    return (dateFinal - dateInitial) / 86_400_000;
}

//CONTROLLA COME MOSTRARE UNA DATA: SE ora, giorno della settimana o data completa
//PRENDE UNA DATA IN FORMATO ISO
function checkDataToDisplay(data){
    let dataTimeToShow = ""
    const TODAY_DATE = new Date()
    const TODAY_DATE_FORMAT = TODAY_DATE.toISOString().split('T')[0]

     let fullDateFormat = formattedTimestamp(data);
    let cDate = fullDateFormat.split(" ")[0];
    let cTime = fullDateFormat.split(" ")[1];

    if(TODAY_DATE_FORMAT === cDate){
        dataTimeToShow = cTime
        return dataTimeToShow
    } else {
        if( cDate < TODAY_DATE_FORMAT ) {
            const differenceDays =  dateDifferenceInDays(new Date(cDate), new Date(TODAY_DATE_FORMAT) )

            if(differenceDays > 6){
                dataTimeToShow = cDate.split("-").reverse().join("/");

                return dataTimeToShow
            } else {
                const weekDayMapping = {
                    0: "Domenica",
                    1: "Lunedì",
                    2: "Martedì",
                    3: "Mercoledì",
                    4: "Giovedì",
                    5: "Venerdì",
                    6: "Sabato"                        
                }
                const cDateFormat = new Date(cDate)
                const getWeekDay =  cDateFormat.getDay()
                const getWeekName = weekDayMapping[getWeekDay]

                dataTimeToShow = getWeekName
                return dataTimeToShow

            }
            
        } else {
            return dataTimeToShow
        }
    }
}

//CONTROLLA SE ANNO è VALIDO
const isValidYear = (year) => {
    return year && parseInt(year) >= 1900 && parseInt(year) <= 2999;
};

//VERIFICA CHE UN ANNO ABBIA 4 CIFRE, DA PASSARE NELLA FUNZIONE ONINPUT
const validateFourDigits = (e) => {
    const value = e.target.value;
    if (value.length > 4) {
      e.target.value = value.slice(0, 4);
    }
  };


//VERIFICA CHE UN ANNO ABBIA 4 CIFRE, DA PASSARE NELLA FUNZIONE ONINPUT
const validateEightDigits = (e) => {
    const value = e.target.value;
    if (value.length > 8 ) {
      e.target.value = value.slice(0, 8);
    }
  };

export { formattedTimestamp, dateDifferenceInDays, checkDataToDisplay, validateFourDigits, isValidYear, validateEightDigits  }