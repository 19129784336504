import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import cards from "../../assets/images/cards-landing-page.svg"

import logo from "../../assets/images/Group 1189.svg"
import building from "../../assets/images/build.png"
import issues from "../../assets/images/issues2.svg"

import play from "../../assets/images/play.svg"


import React, { useEffect, useState } from 'react';


import ModalVideo from 'react-modal-video'

//import video from '../../assets/images/video2.mp4'


import { Link } from 'react-router-dom';

import { BsCheck, BsX } from 'react-icons/bs';

import FaqComponent from './FaqComponent';

import Footer from './Footer';

import "../../assets/css/Home.css"


const features = [
    {
      feature: "Web e app mobile",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Gestione unità senza limiti",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Ricerca fornitori",
      rm: true,
      soft1: false,
      soft2: false,
    },
   
    {
      feature: "Valutazioni immobiliari automatizzate",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Invita e collabora gratuitamente con proprietari, inquilini e fornitori (ognuno di loro avrà la sua dashboard personalizzata)",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)",
      rm: true,
      soft1: false,
      soft2: true,
    },
     {
      feature: "Visualizzazioni immobili su mappa",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Visualizzazioni interventi con specifiche su planimetria",
      rm: true,
      soft1: false,
      soft2: false,
    },
   
    
    {
      feature: "Rapporto degli interventi (persone materiali, mezzi, contabilità etc)",
      rm: true,
      soft1: false,
      soft2: false,
    },
    
    {
      feature: "Pagamenti affitti/ fornitori",
      rm: true,
      soft1: false,
      soft2: false,
    },
    
   
    {
      feature: "Gestione affitti",
      rm: true,
      soft1: false,
      soft2: true,
    },
    
    {
      feature: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)",
      rm: true,
      soft1: true,
      soft2: true,
    },
    
    {
      feature: "Documenti (proforma editabili)",
      rm: true,
      soft1: true,
      soft2: true,
    },
    {
      feature: "Calcolatrice immobiliare",
      rm: true,
      soft1: false,
      soft2: true,
    },
  
    {
      feature: "Gestione sincronizzata con il calendario di scadenze e alert",
      rm: true,
      soft1: true,
      soft2: true,
    },
    {
      feature: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Checklist personalizzate per raccolte dati",
      rm: true,
      soft1: false,
      soft2: false,
    },
    
    {
      feature: "Esportazione di ogni sezione in pdf e csv (excel, word)",
      rm: true,
      soft1: true,
      soft2: true,
    },
    {
      feature: "Assistenza trasmigrazione dat",
      rm: true,
      soft1: false,
      soft2: false,
    },
    {
      feature: "Gestione stabili",
      rm: true,
      soft1: false,
      soft2: true,
    },
    {
      feature: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)",
      rm: true,
      soft1: false,
      soft2: true,
    }
];

//Soluzione laternativa: caricare vide su youtube e mettere link

export default function Landing (){
  const [isOpen, setOpen] = useState(false)

    return (
        <div style={{background:'#fff'}}>
            <div style={{background: 'linear-gradient(180deg, #E6F0FF 0%, rgba(230, 240, 255, 0) 100%)'}}>
                <Container className="px-3 px-md-0">

                <Navbar bg="" data-bs-theme="light" className='d-flex align-items-center pt-3' style={{background: "transparent"}}>
                        <Navbar.Brand href="/landing" className='me-3'>
                            <Image src={logo} width={"85px"}/>
                        </Navbar.Brand>
                        <Nav className="me-auto bg-alert w-100 d-flex align-items-center">                            
                            <div className='ms-auto d-flex align-items-center'>
                              <Link to="/proprietario">
                                <Button variant="outline-primary" style={{color: "#0F1B4C", borderColor: "#0F1B4C"}}>Sei un proprietario/inquilino?</Button>
                              </Link>
                            </div>
                        </Nav>
                    </Navbar>

                    <Row  style={{maxWidth: "1100px"}} className="d-md-flex justify-content-md-between align-items-md-center mx-auto py-5">
                        <Col xs={12} md={7} className="d-flex justify-content-center justify-content-md-start mb-5 mb-md-0" style={{padding: ""}}>
                            <div className='d-flex flex-column justify-content-center justify-content-md-start align-items-md-start' >
                                <p className='text-center text-md-start text-uppercase'>Real meta property</p>
                                <h1 className="text-center text-md-start  fs-45 syne-font mb-2">
                                Sei soddisfatto della tua gestione immobiliare?

                                </h1>
                                <p>
                                Sei consapevole del vero potenziale delle tue proprietà? Garantisci loro l'attenzione e la cura che meritano? Gestisci in modo efficace i controlli, i costi e le attività associate? Ci sono dettagli che ti sfuggono?
                                </p>
                                <Link to="/contatti">
                                  <Button size="lg" variant="primary" className="rounded-pill mt-3" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Scopri Real Meta</Button>
                                </Link>
                            </div>
                        </Col>
                        <Col md={5} className="d-flex justify-content-center justify-content-md-end mt-3">
                          <div className="tech-slideshow">
                            <div className="mover-1" style={{backgroundImage: `url(${issues})`}}></div>
                            <div className="mover-2" style={{backgroundImage: `url(${issues})`}}></div>
                          </div>
                      </Col>
                    </Row>

                  </Container>
            </div>

            <Row className="my-5 justify-content-center align-items-center mx-auto p-5" style={{maxWidth: "1100px", background: "#F3F7FC", borderRadius: "1rem"}}>
                  <Col md={8} className="text-center">
                      <h3 className="syne-font">Real Meta Property - La gestione nelle tue mani</h3>
                      <p className='my-2 mb-4'> </p>
                      <div className='text-center h-50 mb-4'>
                          {/* <Image src="https://images.pexels.com/photos/1309897/pexels-photo-1309897.jpeg?auto=compress&cs=tinysrgb&w=1600" rounded style={{ height:"400px", width:'100%'}}/> */}
                          <React.Fragment>
                            <ModalVideo channel="youtube"
youtube={{ mute: 0, autoplay: 0 }}
isOpen={isOpen}
videoId="xk6YQTFaNWY" autoplay  onClose={() => setOpen(false)} allowFullScreen/>

                            <Col xs={10} md={12} mt={0} onClick={()=> setOpen(true)} style={{cursor: "pointer"}}>
                                <Image src={play} rounded width={'100px'} height={'100px'} className='my-5'/>
                            </Col>

                        </React.Fragment>

                      </div>
                      <Link to="/contatti">
                        <Button variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Richiedi una demo</Button>
                      </Link>
                  </Col>
            </Row>

            

            <Container className="px-3 px-md-0">
              <Row className="mt-5 justify-content-center align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                  <div className="text-center">
                      <h3 className="syne-font">Le persone al centro del progetto</h3>  
                      <p>Con Real Meta avrai tutto quello di cui hai bisogno con un unico abbonamento</p> 
                  </div>                   
              </Row>

              <Row className="mt-5 align-items-center justify-content-center mx-auto" style={{ maxWidth: "1100px" }}>
                <div className="mt-5" style={{ maxWidth: "1100px", margin: "auto" }}>
                
                    <Row className="align-items-center mb-3">
                      <Col xs={3}></Col>
                      <Col xs={3} className="text-center">
                          <Image src={logo} className={`mx-auto d-md-none small-screen`} />
                          <Image src={logo} className={`mx-auto d-none d-md-block large-screen`} />
                      </Col>
                      <Col xs={3} className="text-center">
                          <div className={`rounded-circle d-flex justify-content-center align-items-center fs-6 mx-auto  d-md-none small-screen`} style={{background: "#a8b8d0"}}>
                              <p>Software 1</p>
                          </div>
                          <div className={`rounded-circle d-flex justify-content-center align-items-center fs-14 mx-auto fw-bold d-none d-md-flex large-screen`} style={{background: "#a8b8d0"}}>
                              <p>Software 1</p>
                          </div>
                      </Col>
                      <Col xs={3} className="text-center">
                          <div className={`rounded-circle d-flex justify-content-center align-items-center fs-6 mx-auto  d-md-none small-screen`} style={{background: "#ccdef8"}}>
                              <p>Software 2</p>
                          </div>
                          <div className={`rounded-circle d-flex justify-content-center align-items-center fs-14 mx-auto fw-bold d-none d-md-flex large-screen`} style={{background: "#ccdef8"}}>
                              <p>Software 2</p>
                          </div>
                      </Col>
                    </Row>

                    {features.map((item, index) => (
                        <Row key={index} className="align-items-center mb-3">
                        <Col xs={3}>{item.feature}</Col>
                        <Col xs={3} className="text-center">
                            {item.rm ? <i class="bi bi-check2-circle text-success fs-20"></i> :  <i class="bi bi-x-circle"></i>}
                        </Col>
                        <Col xs={3} className="text-center">
                            {item.soft1 ? <i class="bi bi-check2-circle text-success fs-20"></i> :  <i class="bi bi-x-circle"></i>}
                        </Col>
                        <Col xs={3} className="text-center">
                            {item.soft2 ? <i class="bi bi-check2-circle text-success fs-20"></i> :  <i class="bi bi-x-circle"></i>}
                        </Col>
                        </Row>
                    ))}
                </div>
              </Row>

            



              



              <FaqComponent />

              <Row className="mt-5 justify-content-start align-items-center mx-auto p-5 position-relative" style={{maxWidth: "1100px", background: "#0F1B4C", borderRadius: "1rem", color:"white"}}>
                  <Col md={8} className="left">
                    <h2 className="syne-font">Desideri maggiori informazioni?</h2>
                    <p className='mt-2 mb-3'>Real Meta semplifica e automattiza i processi di gestione immobiliare, mentre tu puoi  concentrarti e far crescere la tua attività.</p>
                    <Link to="/contatti">
                      <Button variant="primary" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}}>Contattaci</Button>
                    </Link>
                  </Col>

                  <Image src={building} className='d-none d-md-block' width="300px" style={{ width: "300px",
                      bottom: 0,
                      right: 0,
                      position: "absolute"}}
                  />
  
              </Row>


              <Footer />

            </Container>


                   

                   

        </div>
    )
}