import React, {useState, useEffect} from "react";
import UserIcon from "../../../assets/images/small_user.svg";
import FileIcon from "../../../assets/images/small_file.svg";
import RulerIcon from "../../../assets/images/ruler.svg";
import { BsDownload, BsTrash, BsShare } from "react-icons/bs";
import DefaultUnitImage from "../../../assets/images/placeholder.png";
import api, { unit_endpoint,chat_list_endpoint } from "../../../api/api";
import { Modal, Form } from "react-bootstrap";
import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "./mapping";

import { Link, useNavigate } from "react-router-dom";
import ButtonRm from "../../ButtonRm";

import ShareChat from "../../ShareChat";

import CustomBadge from "../../CustomBadge";

import casa from "../../../assets/images/icon-units/casa.svg";
import uffici from "../../../assets/images/icon-units/uffici.svg";
import box from "../../../assets/images/icon-units/box.svg";
import pac from "../../../assets/images/icon-units/pac.svg";
import pas from "../../../assets/images/icon-units/pas.svg";
import negozio from "../../../assets/images/icon-units/negozio.svg";






const REUnitCard = ({
	id,
	type,
	name,
	address,
	internal,
	cap,
	municipality,
	province,
	img_url,
	scale,
	floor,
	categoryLandRegistry,
	coveredMq,
	notCoveredMq,
	deleteUnit,
	owners,
	item,
	handleSelection,
	selectedItems,
	checkIsVisible = true
}) => {
	const deleteCard = async () => {
		deleteUnit(id);
		try {
			await api().delete(`${unit_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
	};

	//console.log("parcel", item.parcel)
		//handle modal delete
		const [show, setShow] = useState(false);
		const handleClose = () => {
			setShow(false)
		};
		const handleShow = () => setShow(true);

		//handle modal share
		const [showShare, setShowShare] = useState(false);
		const handleCloseShare = () => {
			setShowShare(false)
		};
		const handleShowShare = () => setShowShare(true);

	//gestisce checkbox
	const navigate = useNavigate()

	const [checked, setChecked] = useState(false);

	
	const handleCheckboxChange = (e) => {
		e.stopPropagation(); 
		setChecked(!checked);
		handleSelection(id, !checked); 
	}

	const handleCardClick = (e) => {
		// Verifica se l'elemento cliccato è il checkbox
		if (e.target.type === "checkbox") {
		  return; // Se sì, non fare nulla
		}
	
		navigate(`/real-estate-units/edit/${item.id}`)
	}


	//SHARE IN CHAT

	const chatInfo = {
        type: "newChat",
        name: "",
        chatId: ""
    }

    const [chatData, setChatData] = useState(chatInfo)

    function handleChatSend (e){
        const {value, name} = e.target
        setChatData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

	//PRENDERE ENDPOINT CHAT GROUP
    //CREARE LISTA CON CHAT GROUP PER SELECT

    const [chatList, setChatList] = useState([])

    async function getChats() {
        try {
            const response = await api().get(`${chat_list_endpoint}`);
            if(response.data) {
                setChatList(response.data?.content)
            }
        }
        catch(error) {
                console.log(error)
        }
    }

	useEffect(() => {
		getChats()
	}, [])

	const imgBasedOnCategory = {
		"" :casa,
		"null": casa,
		null: casa,
		"Appartamenti": casa,
		"Abitazioni di fascia media": casa,
		"Ville e villini": casa,
		"Uffici": uffici,
		"Negozi": negozio,
		"Box e autorimesse": box,
		"Posti auto coperti": pac,
		"Posti auto scoperti": pas ,
		"Laboratori": negozio,
		"Magazzini": negozio,
		"Capannoni tipici": uffici,
		"Capannoni industriali": uffici
	}

	console.log(imgBasedOnCategory[type], type, name , "NOME")
	return (
		<>
		<div className="reunit-card mt-2 d-flex flex-column flex-md-row  align-items-start align-items-md-center ms-0 cursor-pointer" onClick={handleCardClick}>
			<div className="d-flex">
				{checkIsVisible && <div className="d-flex align-items-center">
					<input
						type="checkbox"
						checked={selectedItems ? selectedItems.includes(id) : false}
						onChange={(e) => handleSelection(e, id)}
						className="me-2"
					/>
					<div className="state-img ">
						<img
							src={imgBasedOnCategory[type]}
							alt=""
						/>
					</div>
				</div>}
				<div className="d-flex flex-column flex-auto justify-content-center">
					<span className="reunit-card-type text-uppercase">{type}</span>
					<span className="reunit-card-title text-capitalize">{name}</span>
					<span className="small-span">
						{`${address} , ${internal === "" ? "" : `Interno ${internal} ,`} ${
							scale === "" ? "" : `Scala ${scale} ,`
						} ${
							floor === "" ? "" : `Piano ${floor} ,`
						} ${municipality},  ${province}, CAP ${cap}`}
					</span>
					<div className="d-flex">
						{owners?.length > 0 && <div className="me-2">
							<img loading="lazy" src={UserIcon} alt="" className="me-1 small-icon" />
							<span className="small-span">
								{owners?.length == 1 &&
								owners[0].name + " " + owners[0].surname 
								}
								{owners?.length > 1 &&
								owners[0].name + " " + owners[0].surname + " e altri" + owners.length
								}
							</span>
						</div>}

						{categoryLandRegistry == "" ? (
							""
						) : (
							<div className="me-2">
								<img loading="lazy" src={FileIcon} alt="" className="me-1 small-icon" />
								<span className="small-span">{categoryLandRegistry}</span>
							</div>
						)}

						<div className={coveredMq + notCoveredMq === 0 ? "d-none" : "me-2"}>
							<img loading="lazy" src={RulerIcon} alt="" className="me-1 small-icon" />
							<span className="small-span">{coveredMq + notCoveredMq}mq</span>
						</div>
					</div>
				</div>
			</div>

			<div className="d-flex align-items-center justify-content-start">
				{
					/* 
						<div className="d-flex flex-column align-items-lg-center  ">
							<div className="fund-btn mt-2 text-center">
								Valutazione Immobile
								<br />
								300.000 €
							</div>
						</div> 
					*/
				}
{/* 				<ShareChat elId={id} />
 */}				<div className="">
					<button
						type="button"
						className="btn round-icon-btn"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow()
						}}>
						<BsTrash />
					</button>
				</div>
				<ExportButton data={item? Object.keys(item.length === 0) : {}} mapping={translationMapping} classes="btn round-icon-btn btn me-2"/>
			</div>
		</div>
		<Modal show={show} onHide={() => handleClose()} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) =>{ 
				e.preventDefault()
				e.stopPropagation()
				deleteCard()
				handleClose()
				setShow(false)
				}}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => handleClose()}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>


	  <Modal show={showShare} onHide={() => handleCloseShare()} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) =>{ 
				e.preventDefault()
				e.stopPropagation()
			  }}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => handleCloseShare()}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>

	  {/* SEND TO CHAT */}
	 {/*  <Modal show={showShare} onHide={() => handleCloseShare()} centered>
        <Modal.Body className="p-5">
            <div className="d-flex flex-column">
                <h2 className="fs-32 text-dark fw-semibold mb-3">
                Invia elemento
                </h2>
                <h6 className="fs-16 text-dark fw-normal mb-3">
                L’utente riceverà un file pdf dell'elemento selezionato in chat
                </h6>
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex">
                            <Form.Check
                                inline
                                label="Seleziona chat esistente"
                                type="radio"
                                value="existingChat"
                                checked={chatData.type === "existingChat"}
                                onChange={handleChatSend}
                                name="type"
                            />
                            <Form.Check
                                inline
                                label="Crea nuova chat"
                                type="radio"
                                value="newChat"
                                checked={chatData.type === "newChat"}
                                onChange={handleChatSend}
                                name="type"
                            />
                        </div>
                    </div>
                </div>

                {chatData.type == "existingChat" ?
                    <div className="d-flex flex-column my-3">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                            Seleziona chat
                        </label>
                        <div className="d-block">
                        <select
                            name="chatId"
                            onChange={handleChatSend}
                            value={chatData.rataId}
                        >
                            <option>Seleziona</option>
                            {
                                chatList.length > 0 &&
                                chatList.map(item => {
                                    return <option kye={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </select>
                        </div>
                    </div> 
                    :
					<div>
						<div className="my-3">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Nome chat
							</label>
							<div className="d-block">
								<input
								name="name"
								type="text"
								value={chatData?.name}
								onChange={handleChatSend}
								></input>
							</div>
							<MultiSelect
								options={partecipanti}
								value={selected}
								onChange={setSelected}
								labelledBy="Seleziona"
								overrideStrings={overrideString}
								isCreatable={false}
								onCreateOption={handleNewField}
							/>
						</div>
					</div>
                }

                <ButtonRm variant="primary">Invia</ButtonRm>
                    
                <button
                type="button"
                className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={() => handleCloseShare()}
                >
                Annulla
                </button>
            </div>
        </Modal.Body>
        </Modal> */}
		</>
	);
};

export default REUnitCard;
