import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import dashboard from "../assets/images/icon-sidebar/dashboard-sb.svg"
import unit from "../assets/images/icon-sidebar/unit-sb.svg"
import edifici from "../assets/images/icon-sidebar/edifici-sb.svg"
import utenti from "../assets/images/icon-sidebar/user-sb.svg"
import amministrazione from "../assets/images/icon-sidebar/ammfin-sb.svg"
import documenti from "../assets/images/icon-sidebar/document-sb.svg"
import calcoli from "../assets/images/icon-sidebar/calculator-sb.svg"
import alert from "../assets/images/icon-sidebar/calendar-sb.svg"
import supporto from "../assets/images/icon-sidebar/support-sb.svg"
import impostazioni from "../assets/images/icon-sidebar/impostazioni-sb.svg"


import { Link} from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import slc from "../assets/images/SLC-COLL-T.svg";
import ButtonRm from "./ButtonRm";
import { useDispatch } from "react-redux";
import { changeTheme } from "../actions/theme";
import CustomBadge from "./CustomBadge";




//mi serve elemento di mapping tra path corrente e nome del submenu

// Oggetto di mappatura per percorsi e sezioni
const pathSectionMap = {
  'dashboard': 'dashboard',
  'calcoli': 'calcoli',
  'calculator': 'calculator',
  'prima-nota': 'prima-nota',
  'modelli': 'modelli',
  'proprietari-view': 'proprietari',
  'proprietari': 'proprietari',
  'fornitori': 'fornitori',
  'conduttori': 'conduttori',
  'affitti': 'affitti',
  'alert': 'alert',
  'impostazioni': 'impostazioni',
  'supporto': 'supporto',
  'real-estate-units': 'unità',
  'stabili': 'stabili',
  'evaluation': 'valutazione',
  'report': 'report',
  'assembly': 'assemblea',
  'rate': 'rate',
  'unit-details': 'rate',
  'members-details': 'rate',
  'management-details': 'rate',
  'other-rate-members': 'rate',
  'other-rate-units': 'rate',
  'proprietari-edit': 'proprietari',
  'conduttori-view': 'conduttori',
  'fornitori-view': 'fornitori',
  'notifiche': 'notifiche',
  'statoavanzamentolavori/bacheca': 'bacheca',
  'statoavanzamentolavori/supporto': 'supporto',
  'statoavanzamentolavori/gestioni': 'gestioni',
  'statoavanzamentolavori/unitaestabili': 'unitaestabili',
  'statoavanzamentolavori/attivita': 'attivita',
  'statoavanzamentolavori/impostazionichat': 'impostazioni',
  'statoavanzamentolavori/trova-fornitore': 'trova-fornitore'
};


export default function Menu(props) {
	const [subMenuOpened, setSubMenuOpened] = useState("dashboard");
	const location = useLocation();

	useEffect(() => {
		//controlla current path
		const path = location.pathname + location.search;

		// Trova la sezione corrispondente al percorso attuale
		const currentSection = Object.keys(pathSectionMap).find(key => path.includes(`${key}`));
		setSubMenuOpened(currentSection)

		if (!currentSection) {
		setSubMenuOpened('dashboard')
		return; // Percorso non mappato
		}
	}, [location])


	const [subMenuCollpsedVisibility, setSubMenuVisibility] = useState(true)
	const onHandleCollapse = (name) => {
		setSubMenuOpened(name);
		setSubMenuVisibility(true)

		//SE SIDEBAR COLLASSATA, E IL MENU VIENE CLICCATO PIU' VOLTE TOGGLE VISIBILITY
		if(!props.isActive && name == subMenuOpened){
			setSubMenuVisibility(!subMenuCollpsedVisibility)
		}
	};

	const [sottosezione, setSottosezione] = useState("")

	const setMobileMenu = (name) => {
		if (typeof props.setMobileMenu === "function") {
			props.setMobileMenu(false);
		}

		setSottosezione(name)
		setSubMenuVisibility(false)
	};

	const isMobile= useMediaQuery({
		query: "(max-width: 900px)",
	});

	//PASSA A SAL
	const navigate = useNavigate()
	const dispatch = useDispatch()

	function goToSlc(){
		navigate("/statoavanzamentolavori/bacheca")
		//dispatch(changeTheme())
	}

	return (
		<>
			<ul className="menu-list ls p-0 mb-0">
				<div>
				<li>
					<Link to="/dashboard" onClick={() => {
						onHandleCollapse("dashboard")
						setMobileMenu("dashboard")
					}}>
						<span className={`desktop-icon-sidebar ${subMenuOpened == "dashboard" && !isMobile ? "active" : ""} icon-link`}>
							<img loading="lazy" src={dashboard} alt="image" />
						</span>
						{props.isActive  && <div className={`desktop-icon-sidebar ${subMenuOpened == "dashboard" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={dashboard} alt="dashboard" />
								</span>
								<p className={`menu-item-sb text-link ${subMenuOpened == "dashboard" ? "sidebar-active-item" : ""}`}>Dashboard</p>
							</div>
						</div>}
						{ isMobile  && <div className={`desktop-icon-sidebar ${subMenuOpened == "dashboard" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={dashboard} alt="dashboard" />
								</span>
								<p className={`menu-item-sb text-link ${subMenuOpened == "dashboard" ? "sidebar-active-item" : ""}`}>Dashboard</p>
							</div>
						</div>}
					</Link>
				</li>

				{/* UNITA' */}
				<li className={`${
						subMenuOpened == "unità" ? "dropdown-link " : ""
					}`}>
					<button
						onClick={() => {
							onHandleCollapse("unità");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						{/* SE SIDEBAR E' COLLASSATA - SOLO ICONA*/}

						<span className={`desktop-icon-sidebar ${subMenuOpened == "unità" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={unit} alt="image" />
						</span>

						{/* SE SIDEBAR E' ESPANSA - ICONA E TESTO + DROPDOWN */}
						{props.isActive  &&  <div className={`desktop-icon-sidebar ${subMenuOpened == "unità" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={unit} alt="unit" />
								</span>
								<p className={`menu-item-sb`}>Unità Immobiliari</p>
							</div>

							{subMenuOpened == "unità"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/real-estate-units" onClick={() => setMobileMenu("unità")}>
											<p className={` text-link ${sottosezione == "unità" ? "sidebar-active-item" : ""}`}>Unità</p>
										</Link>
									</li>
									<li>
										<Link to="/affitti" onClick={() => setMobileMenu("affitti")}>
											<p className={`text-link ${sottosezione == "affitti" ? "sidebar-active-item" : ""}`}>Affitti</p>
										</Link>
									</li>
									<li>
										<Link to="/evaluation" onClick={() => setMobileMenu("val")}>
											<p className={`text-link ${sottosezione == "val" ? "sidebar-active-item" : ""}`}>Valutazione Immobiliare</p>
										</Link>
									</li>
								</ul>
							</div>}
						</div>}
						{ isMobile &&  <div className={`desktop-icon-sidebar ${subMenuOpened == "unità" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={unit} alt="unit" />
								</span>
								<p className={`menu-item-sb`}>Unità Immobiliari</p>
							</div>

							{subMenuOpened == "unità"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/real-estate-units" onClick={() => setMobileMenu("unità")}>
											<p className={` text-link ${sottosezione == "unità" ? "sidebar-active-item" : ""}`}>Unità</p>
										</Link>
									</li>
									<li>
										<Link to="/affitti" onClick={() => setMobileMenu("affitti")}>
											<p className={`text-link ${sottosezione == "affitti" ? "sidebar-active-item" : ""}`}>Affitti</p>
										</Link>
									</li>
									<li>
										<Link to="/evaluation" onClick={() => setMobileMenu("val")}>
											<p className={`text-link ${sottosezione == "val" ? "sidebar-active-item" : ""}`}>Valutazione Immobiliare</p>
										</Link>
									</li>
								</ul>
							</div>}
						</div>}
					</button>

					{/* SE SIDEBAR E' COLLASSATA - SUBMENU */}
					{!isMobile &&  subMenuOpened == "unità"  && 
					!props.isActive &&
					subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
							<li>
								<Link to="/real-estate-units" onClick={() => setMobileMenu("unità")} className={`menu-item-sb text-link ${sottosezione == "unità" ? "sidebar-active-item" : ""}`}>
									Unità
								</Link>
							</li>
							<li>
								<Link to="/affitti" onClick={() => setMobileMenu("affitti")} className={`menu-item-sb text-link ${sottosezione == "affitti" ? "sidebar-active-item" : ""}`}>
									Affitti
								</Link>
							</li>
							<li>
								<Link to="/evaluation"  onClick={() => setMobileMenu("val")} className={`menu-item-sb text-link ${sottosezione == "val" ? "sidebar-active-item" : ""}`}>
									Valutazione Immobiliare
								</Link>
							</li>
						</ul>
					</div>}
				</li>

				{/* EDIFICI */}
				<li className={`${
						subMenuOpened == "edifici" ? "dropdown-link " : ""
					}`}>
					<button
						onClick={() => {
							onHandleCollapse("edifici");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						<span className={`desktop-icon-sidebar ${subMenuOpened == "edifici" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={edifici} alt="image" />
						</span>
						{props.isActive  && <div className={`desktop-icon-sidebar ${subMenuOpened == "edifici" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={edifici} alt="edifici" />
								</span>
								<p className="menu-item-sb">Edifici</p>
							</div>

							{subMenuOpened == "edifici"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
										<li>
										<Link to="/stabili"  onClick={() => setMobileMenu("stabili")} className={`menu-item-sb text-link ${sottosezione == "stabili" ? "sidebar-active-item" : ""}`}>
											Stabili
										</Link>
									</li>
									{/* <li>
										<Link to="/assembly" onClick={setMobileMenu}>
											Assemblee
										</Link>
									</li> */}
								</ul>
							</div>}
						</div>}
						{isMobile && <div className={`desktop-icon-sidebar ${subMenuOpened == "edifici" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={edifici} alt="edifici" />
								</span>
								<p className="menu-item-sb">Edifici</p>
							</div>

							{subMenuOpened == "edifici"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
										<li>
										<Link to="/stabili"  onClick={() => setMobileMenu("stabili")} className={`menu-item-sb text-link ${sottosezione == "stabili" ? "sidebar-active-item" : ""}`}>
											Stabili
										</Link>
									</li>
									{/* <li>
										<Link to="/assembly" onClick={setMobileMenu}>
											Assemblee
										</Link>
									</li> */}
								</ul>
							</div>}
						</div>}

					</button>
					{/*Collapsed submenu*/}
					{!isMobile && subMenuOpened == "edifici"  && 
					!props.isActive && subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
						<li>
								<Link to="/stabili"  onClick={() => setMobileMenu("stabili")} className={`menu-item-sb text-link ${sottosezione == "stabili" ? "sidebar-active-item" : ""}`}>
									Stabili
								</Link>
							</li>
							{/* <li>
								<Link to="/assembly" onClick={setMobileMenu}>
									Assemblee
								</Link>
							</li> */}
						</ul>
					</div>}
				</li>

				{/* UTENTI */}
				<li className={`${
						subMenuOpened == "utenti" ? "dropdown-link " : ""
					}`}>
					<button
						onClick={() => {
							onHandleCollapse("utenti");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						<span className={`desktop-icon-sidebar ${subMenuOpened == "utenti" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={utenti} alt="image" />
						</span>
						{props.isActive  && <div className={`desktop-icon-sidebar ${subMenuOpened == "utenti" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={utenti} alt="utenti" />
								</span>
								<p className="menu-item-sb">Utenti</p>
							</div>

							{subMenuOpened == "utenti"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/proprietari"  onClick={() => setMobileMenu("proprietari")} className={`menu-item-sb text-link ${sottosezione == "proprietari" ? "sidebar-active-item" : ""}`}>
											Proprietari
										</Link>
									</li>
									<li>
										<Link to="/conduttori"  onClick={() => setMobileMenu("conduttori")} className={`menu-item-sb text-link ${sottosezione == "conduttori" ? "sidebar-active-item" : ""}`}>
											Conduttori
										</Link>
									</li>
									<li>
										<Link to="/fornitori" onClick={() => setMobileMenu("fornitori")} className={`menu-item-sb text-link ${sottosezione == "fornitori" ? "sidebar-active-item" : ""}`}>
											Fornitori
										</Link>
									</li>
								</ul>
							</div>}
						</div>}
						{ isMobile && <div className={`desktop-icon-sidebar ${subMenuOpened == "utenti" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={utenti} alt="utenti" />
								</span>
								<p className="menu-item-sb">Utenti</p>
							</div>

							{subMenuOpened == "utenti"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/proprietari"  onClick={() => setMobileMenu("proprietari")} className={`menu-item-sb text-link ${sottosezione == "proprietari" ? "sidebar-active-item" : ""}`}>
											Proprietari
										</Link>
									</li>
									<li>
										<Link to="/conduttori"  onClick={() => setMobileMenu("conduttori")} className={`menu-item-sb text-link ${sottosezione == "conduttori" ? "sidebar-active-item" : ""}`}>
											Conduttori
										</Link>
									</li>
									<li>
										<Link to="/fornitori" onClick={() => setMobileMenu("fornitori")} className={`menu-item-sb text-link ${sottosezione == "fornitori" ? "sidebar-active-item" : ""}`}>
											Fornitori
										</Link>
									</li>
								</ul>
							</div>}
						</div>}

					</button>
					{/*Collapsed submenu*/}
					{ !isMobile && subMenuOpened == "utenti"  && 
					!props.isActive && subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
							<li>
								<Link to="/proprietari" onClick={() => setMobileMenu("proprietari")}>
									Proprietari
								</Link>
							</li>
							<li>
								<Link to="/conduttori" onClick={() => setMobileMenu("conduttori")}>
									Conduttori
								</Link>
							</li>
							<li>
								<Link to="/fornitori" onClick={() => setMobileMenu("fornitori")}>
									Fornitori
								</Link>
							</li>
						</ul>
					</div>}
				</li>

				{/* AMM. FINANZIARIA*/}
				<li className={`${
						subMenuOpened == "amministrazione" ? "dropdown-link " : ""
					}`}>
					<button
						onClick={() => {
							onHandleCollapse("amministrazione");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						<span className={`desktop-icon-sidebar ${subMenuOpened == "amministrazione" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={amministrazione} alt="image" />
						</span>
						{props.isActive  && <div className={`desktop-icon-sidebar ${subMenuOpened == "amministrazione" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={amministrazione} alt="amministrazione" />
								</span>
								<p className="menu-item-sb" style={{lineHeight:"1.2"}}>Amministrazione Finanziaria</p>
							</div>

							{subMenuOpened == "amministrazione"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/prima-nota" onClick={() => setMobileMenu("prima nota")} className={`menu-item-sb text-link ${sottosezione == "prima nota" ? "sidebar-active-item" : ""}`}>
											Prima Nota
										</Link>
									</li>
									<li>
										<Link to="/rate" onClick={() => setMobileMenu("rate")} className={`menu-item-sb text-link ${sottosezione == "rate" ? "sidebar-active-item" : ""}`}>
											Rate
										</Link>
									</li>
									<li>
										<Link to="/report" onClick={() => setMobileMenu("rendiconto")} className={`menu-item-sb text-link ${sottosezione == "rendiconto" ? "sidebar-active-item" : ""}`}>
											Rendiconto
										</Link>
									</li>
								</ul>
							</div>}
						</div>}
						{ isMobile && <div className={`desktop-icon-sidebar ${subMenuOpened == "amministrazione" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={amministrazione} alt="amministrazione" />
								</span>
								<p className="menu-item-sb" style={{lineHeight:"1.2"}}>Amministrazione Finanziaria</p>
							</div>

							{subMenuOpened == "amministrazione"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/prima-nota" onClick={() => setMobileMenu("prima nota")} className={`menu-item-sb text-link ${sottosezione == "prima nota" ? "sidebar-active-item" : ""}`}>
											Prima Nota
										</Link>
									</li>
									<li>
										<Link to="/rate" onClick={() => setMobileMenu("rate")} className={`menu-item-sb text-link ${sottosezione == "rate" ? "sidebar-active-item" : ""}`}>
											Rate
										</Link>
									</li>
									<li>
										<Link to="/report" onClick={() => setMobileMenu("rendiconto")} className={`menu-item-sb text-link ${sottosezione == "rendiconto" ? "sidebar-active-item" : ""}`}>
											Rendiconto
										</Link>
									</li>
								</ul>
							</div>}
						</div>}

					</button>
					{/*Collapsed submenu*/}
					{ !isMobile && subMenuOpened == "amministrazione"  && 
					!props.isActive && subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
							<li>
								<Link to="/prima-nota" onClick={() => setMobileMenu("prima nota")}>
									Prima Nota
								</Link>
							</li>
							<li>
								<Link to="/rate" onClick={() => setMobileMenu("rate")}>
									Rate
								</Link>
							</li>
							<li>
								<Link to="/report" onClick={() => setMobileMenu("rendiconto")}>
									Rendiconto
								</Link>
							</li>
						</ul>
					</div>}
				</li>

				{/* DOCUMENTI */}
				<li className={`${
						subMenuOpened == "documenti" ? "dropdown-link " : ""
					}`}>
					<button
						onClick={() => {
							onHandleCollapse("documenti");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						<span className={`desktop-icon-sidebar ${subMenuOpened == "documenti" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={documenti} alt="image" />
						</span>
						{props.isActive && <div className={`desktop-icon-sidebar ${subMenuOpened == "documenti" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={documenti} alt="documenti" />
								</span>
								<p className="menu-item-sb">Documenti</p>
							</div>

							{subMenuOpened == "documenti"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
								<li>
										<Link to="/modelli" onClick={() => setMobileMenu("modelli")} className={`menu-item-sb text-link ${sottosezione == "modelli" ? "sidebar-active-item" : ""}`} >
											Modelli
										</Link>
									</li>
									{/* <li>
										<Link to="/modelli/storico" onClick={() => setMobileMenu("storico")} className={`menu-item-sb text-link ${sottosezione == "storico" ? "sidebar-active-item" : ""}`}>
											Storico Documenti
										</Link>
									</li> */}
								</ul>
							</div>}
						</div>}
						{ isMobile && <div className={`desktop-icon-sidebar ${subMenuOpened == "documenti" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={documenti} alt="documenti" />
								</span>
								<p className="menu-item-sb">Documenti</p>
							</div>

							{subMenuOpened == "documenti"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
								<li>
										<Link to="/modelli" onClick={() => setMobileMenu("modelli")} className={`menu-item-sb text-link ${sottosezione == "modelli" ? "sidebar-active-item" : ""}`} >
											Modelli
										</Link>
									</li>
									{/* <li>
										<Link to="/modelli/storico" onClick={() => setMobileMenu("storico")} className={`menu-item-sb text-link ${sottosezione == "storico" ? "sidebar-active-item" : ""}`}>
											Storico Documenti
										</Link>
									</li> */}
								</ul>
							</div>}
						</div>}

					</button>
					{/*Collapsed submenu*/}
					{!isMobile && subMenuOpened == "documenti"  && 
					!props.isActive && subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
							<li>
								<Link to="/modelli" onClick={() => setMobileMenu("modelli")}>
									Modelli
								</Link>
							</li>
							{/* <li>
								<Link to="/modelli/storico" onClick={() => setMobileMenu("storico")}>
									Storico Documenti
								</Link>
							</li> */}
						</ul>
					</div>}
				</li>

				{/* CALCOLATRICE */}
				<li className={`${
						subMenuOpened == "calcoli" ? "dropdown-link " : ""
					} ${isMobile? "d-none" : ""}`}>
					<button
						onClick={() => {
							onHandleCollapse("calcoli");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						<span className={`desktop-icon-sidebar ${subMenuOpened == "calcoli" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={calcoli} alt="image" />
						</span>
						{isMobile || props.isActive && <div className={`desktop-icon-sidebar ${subMenuOpened == "calcoli" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={calcoli} alt="calcoli" />
								</span>
								<p className="menu-item-sb">Calcoli</p>
							</div>

							{subMenuOpened == "calcoli"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/Calculator" onClick={() => setMobileMenu("calcolatrice")} className={`menu-item-sb text-link ${sottosezione == "calcolatrice" ? "sidebar-active-item" : ""}`}>
											Calcolatrice
										</Link>
									</li>
									<li>
										<Link to="/calcoli" onClick={() => setMobileMenu("calcoli")} className={`menu-item-sb text-link ${sottosezione == "calcoli" ? "sidebar-active-item" : ""}`}>
											Calcoli
										</Link>
									</li>
								</ul>
							</div>}
						</div>}

					</button>
					{/*Collapsed submenu*/}
					{!isMobile && subMenuOpened == "calcoli"  && 
					!props.isActive && subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
							<li>
								<Link to="/Calculator" onClick={() => setMobileMenu("calcolatrice")}>
									Calcolatrice
								</Link>
							</li>
							<li>
								<Link to="/calcoli" onClick={() => setMobileMenu("calcoli")}>
									Calcoli
								</Link>
							</li>
						</ul>
					</div>}
				</li>

				{/* ALERT*/}
				<li className={`${
						subMenuOpened == "alert" ? "dropdown-link " : ""
					}`}>
					<button
						onClick={() => {
							onHandleCollapse("alert");
						}}
						className="d-flex w-100 justify-content-between align-items-center p-0 bg-transparent border-0 text-start">
						<span className={`desktop-icon-sidebar ${subMenuOpened == "alert" ? "active" : ""} icon-link`}>
							<img loading="lazy" src={alert} alt="image" />
						</span>
						{isMobile &&  <div className={`desktop-icon-sidebar ${subMenuOpened == "alert" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={edifici} alt="alert" />
								</span>
								<p className="menu-item-sb">Alert</p>
							</div>

							{subMenuOpened == "alert"  && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/alert/calendario" onClick={() => setMobileMenu("calendario")} className={`menu-item-sb text-link ${sottosezione == "calendario" ? "sidebar-active-item" : ""}`}>
											Calendario
										</Link>
									</li>
									<li>
										<Link to="/alert/scadenzario" onClick={() => setMobileMenu("scadenzario")} className={`menu-item-sb text-link ${sottosezione == "scadenzario" ? "sidebar-active-item" : ""}`}>
											Scadenzario
										</Link>
									</li>
									<li>
										<Link to="/alert/notifiche" onClick={() => setMobileMenu("notifiche")} className={`menu-item-sb text-link ${sottosezione == "notifiche" ? "sidebar-active-item" : ""}`}>
											Notifiche
										</Link>
									</li>
								</ul>
							</div>}
						</div>}
						{props.isActive &&  <div className={`desktop-icon-sidebar ${subMenuOpened == "alert" ? "active" : ""}`}>
							<div className="d-flex justify-content-start align-items-center">
								<span className="me-2">
									<img loading="lazy" src={edifici} alt="alert" />
								</span>
								<p className="menu-item-sb">Alert</p>
							</div>

							{subMenuOpened == "alert" && <div className="mt-2">
								<ul className="ls d-flex flex-column">
									<li>
										<Link to="/alert/calendario" onClick={() => setMobileMenu("calendario")} className={`menu-item-sb text-link ${sottosezione == "calendario" ? "sidebar-active-item" : ""}`}>
											Calendario
										</Link>
									</li>
									<li>
										<Link to="/alert/scadenzario" onClick={() => setMobileMenu("scadenzario")} className={`menu-item-sb text-link ${sottosezione == "scadenzario" ? "sidebar-active-item" : ""}`}>
											Scadenzario
										</Link>
									</li>
									<li>
										<Link to="/alert/notifiche" onClick={() => setMobileMenu("notifiche")} className={`menu-item-sb text-link ${sottosezione == "notifiche" ? "sidebar-active-item" : ""}`}>
											Notifiche
										</Link>
									</li>
								</ul>
							</div>}
						</div>}

					</button>
					{/*Collapsed submenu*/}
					{!isMobile && subMenuOpened == "alert"  && 
					!props.isActive && subMenuCollpsedVisibility &&
					<div className="sub-links">
						<ul className="ls d-flex flex-column ">
							<li>
								<Link to="/alert/calendario" onClick={() => setMobileMenu("calendario")}>
									Calendario
								</Link>
							</li>
							<li>
								<Link to="/alert/scadenzario"  onClick={() => setMobileMenu("scadenzario")}>
									Scadenzario
								</Link>
							</li>
							<li>
								<Link to="/alert/notifiche"  onClick={() => setMobileMenu("notifiche")}>
									Notifiche
								</Link>
							</li>
						</ul>
					</div>}
				</li>
				</div>

				<div>
			
					{/* SUPPORTO */}
					 <li>
						<Link to="/supporto" onClick={() => {
							onHandleCollapse("supporto")
							setMobileMenu("supporto")
							}}>
							<span className={`desktop-icon-sidebar ${subMenuOpened == "supporto" ? "active" : ""} icon-link`}>
								<img loading="lazy" src={supporto} alt="image" />
							</span>
							{props.isActive && <div className={`desktop-icon-sidebar ${subMenuOpened == "supporto" ? "active" : ""}`}>
								<div className="d-flex justify-content-start align-items-center">
									<span className="me-2">
										<img loading="lazy" src={supporto} alt="supporto" />
									</span>
									<p className={`menu-item-sb text-link ${subMenuOpened == "supporto" ? "sidebar-active-item" : ""}`}>Supporto</p>
								</div>
							</div>}
							{isMobile && <div className={`desktop-icon-sidebar ${subMenuOpened == "supporto" ? "active" : ""}`}>
								<div className="d-flex justify-content-start align-items-center">
									<span className="me-2">
										<img loading="lazy" src={supporto} alt="supporto" />
									</span>
									<p className={`menu-item-sb text-link ${subMenuOpened == "supporto" ? "sidebar-active-item" : ""}`}>Supporto</p>
								</div>
							</div>}
						</Link>
					</li> 

					{/* IMPOSTAZIONI */}
					<li >
						<Link to="/impostazioni" onClick={() => {
							onHandleCollapse("impostazioni")
							setMobileMenu("impostazioni")
							}}>
							<span className={`desktop-icon-sidebar ${subMenuOpened == "impostazioni" ? "active" : ""} icon-link`}>
								<img loading="lazy" src={impostazioni} alt="image" />
							</span>
							{props.isActive  && <div className={`desktop-icon-sidebar ${subMenuOpened == "impostazioni" ? "active" : ""}`}>
								<div className="d-flex justify-content-start align-items-center">
									<span className="me-2">
										<img loading="lazy" src={impostazioni} alt="impostazioni" />
									</span>
									<p className={`menu-item-sb text-link ${subMenuOpened == "impostazioni" ? "sidebar-active-item" : ""}`}>Impostazioni</p>
								</div>
							</div>}
							{ isMobile && <div className={`desktop-icon-sidebar ${subMenuOpened == "impostazioni" ? "active" : ""}`}>
								<div className="d-flex justify-content-start align-items-center">
									<span className="me-2">
										<img loading="lazy" src={impostazioni} alt="impostazioni" />
									</span>
									<p className={`menu-item-sb text-link ${subMenuOpened == "impostazioni" ? "sidebar-active-item" : ""}`}>Impostazioni</p>
								</div>
							</div>}
						</Link>
					</li>

					<a onClick={goToSlc}>
						<li className="d-flex justify-content-center mt-2">
								<span className="icon-link">
									<img loading="lazy" src={slc} alt="icon" />
								</span>
								<ButtonRm variant="slc" className="text-link fs-14">Passa a SAL</ButtonRm>
						</li>
					</a>
				</div>

			</ul>
		</>
	);
}
