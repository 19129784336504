import React, {useState} from "react"
import { Row, Col } from "react-bootstrap"

import FilterSearchBar from "./FilterSearchBar"
import { AttivitàCardMap, AttivitàCardMapMobile } from "../Attività/AttivitàCard";
import SearchIllustration from "../../../assets/images/search-illustration.svg";
import EmptyState from '../../../assets/images/attività-empty-state.svg'

import { useMediaQuery } from 'react-responsive'

import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

import ButtonRm from "../../ButtonRm";

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap  } from 'react-leaflet'

import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pc from '../../../assets/images/placeholder-unit-medium.png'

import { checkUserRole } from "../../../utils/checkUserRole";


function GanttViewActivty(props){
    const isDesktop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    //Settings for carousels Unit map card mobile
    let settings = {
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                }
            },
        ]
    }

    /*MAPPA*/

    //CHANGE MARKER ICON
    function myIcon(){
        return L.icon({
            iconUrl: require('../../../assets/images/pin-map.png'),
            iconSize:35
        })
    }

    //Modify map view
    function ChangeView({ center, zoom}) {
        const map = useMap();
        map.flyTo(center, zoom)
        return null;
    }


    //GANTT DATA
    let todayDate = new Date().toISOString().slice(0, 10);

    const dataGantt = props.attività?.map((item) => {
        const startDate = item.startDate? item.startDate.substr(0, 10) : "";
        const expirationDate = item.expirationDate? item.expirationDate.substr(0, 10) : "";
        const endDate = item.end_date ? item.endDate.substr(0, 10) : "";
        let status;
    
        if (todayDate < startDate?.split(",").join("-")) {
        status = "aperta";
        } else if (todayDate >= startDate?.split(",").join("-")) {
        status = "in corso";
        } else if (item.endDate) {
        status = "risolta";
        } else if (todayDate > expirationDate?.split(",").join("-")) {
        status = "in ritardo";
        }
    
        let progressPercentage;
        if (status==="aperta") {
        progressPercentage = 0;
        } else if (status==="in corso") {
        progressPercentage = 50;
        } else if (status==="risolta") {
        progressPercentage = 100;
        } else if (status==="ritardo") {
        progressPercentage = 70;
        }
    
        let color;
        if (status==="aperta") {
        color = "#B8C2CB";
        } else if (status==="in corso") {
        color = "var(--edit-color)";
        } else if (status==="risolta") {
        color = "#36D69C";
        } else if (status==="ritardo") {
        color = "#EB586A";
        }
    
    
        return {
        start: new Date(startDate),
        end: endDate ? new Date(endDate) : new Date(expirationDate),
        name: item.name,
        id: item.id,
        type: "task",
        progress: progressPercentage,
        isDisabled: true,
        styles: { progressColor: color, progressSelectedColor: "#ff9e0d" },
        };
    });
    
    const [fullscreen, setFullscreen] = useState(false);

    // Funzione per toggle fullscreen
    const toggleFullscreen = () => {
      setFullscreen(!fullscreen);
    };

    return (
        <Row className="mx-0">
            { isDesktop &&
                <Col className={` ${fullscreen ? 'd-none' : 'd-flex justify-content-center col-2-xxxl'}`} lg={fullscreen ? 0 : 4} >

                    <div className="unit-container">

                        {
                            props.attività?.length > 0 &&
                            <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between px-3">
                                <FilterSearchBar
                                setSearchTerm={props.setSearchTerm}
                                selectedCategories={props.selectedCategories}
                                setSelectedCategories={props.setSelectedCategories}
                                clearFiltersAndSort={props.clearFiltersAndSort}
                                setSortBy={props.setSortBy}
                                categories={props.categories}
                                setStartDate={props.setStartDate} setEndDate={props.setEndDate}

                                />
                            </Col>
                        }

                        {  props.attività?.length > 0 &&
                            <div className="card-map-container my-1 d-flex flex-column
                            align-items-center">                       
                            {   props.sortedItems.length > 0 ?
                                props.sortedItems.map(item => {
                                        return <AttivitàCardMap
                                                    key={item.id}
                                                    id={item.id}
                                                    type="gantt"
                                                    name={item.name}
                                                    unit_name={item.unitName}
                                                    priority={item.priority}
                                                    
                                                />
                                            }) :
                                    <div className="my-4 mx-auto text-center">
                                        <div className="mx-auto mb-2 mt-5 w-60">
                                            <img loading="lazy" className="img-fluid" src={SearchIllustration} alt="empty-image" />
                                        </div>
                                        <p className="fs-14 text-secondary text-center">
                                            Nessuna corrispondenza trovata
                                        </p>
                                    </div>                     
                            }
                        </div>
                        } 

                        { props.attività?.length===0 &&
                            <div className="my-5 mx-auto text-center d-flex flex-column
                            align-items-center justify-content-center ">
                                <div className="mx-auto mb-4 w-80">
                                    <img loading="lazy" className="img-fluid" src={EmptyState} alt="empty-image" />
                                </div>
                                <p className="fs-12 text-secondary text-center">
                                {checkUserRole() ?  "Non hai aggiunto ancora alcuna attività" : "Non sei stato ancora aggiunto in alcuna attività"}
                                </p>
                            </div>    
                        }            
                    </div>
                </Col>
            }

            <Col lg={fullscreen ? 12 : 8} className={`col-10-xxxl ${fullscreen && 'fullscreen'}`}>

                <div className="gantt-container position-relative">
                    {props.sortedItems?.length > 0 ? (
                    <Gantt
                        tasks={dataGantt}
                        listCellWidth={""}
                        ganttHeight={"100%"}
                        locale="ita"
                    />
                    ) : (
                    ""
                    )}
                </div>
                <FullscreenControl fullscreen={fullscreen} toggleFullscreen={toggleFullscreen} />

            </Col>

            </Row>
    )
}

const FullscreenControl = (props) => {
  
    const positionControl = {  }; // Adjust the position as needed
  
    return (
      <div className="text-end" style={positionControl}>
        <ButtonRm onClick={props.toggleFullscreen}>
          {props.fullscreen ? <small >Riduci</small> : <small>Espandi</small>}
        </ButtonRm>
      </div>
    );
  };


export default GanttViewActivty