import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ServerTypeCard from "./ServerTypeCard";
import UserTypeCard from "./UserTypeCard";
import NewTypeModal from "./NewTypeModal";
import { BsChevronLeft } from "react-icons/bs";
import { MOVEMENT_TYPES } from "../../../../constants";
import ButtonRm from "../../../ButtonRm";

import api, { subcount_endpoint } from "../../../../api/api";

function AddTypeModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [search_name, setSearchName] = useState("");
  const modalBack = () => {
    props.setDisplay(false);
    handleClose();
  };
  const nextModal = () => {
    props.setDisplay(true);
    handleShow();
  };

  //subcount list

  const [subcount, setSubcount] = useState([])

  let mappedSubcount = subcount.length > 0 ? subcount.map(el => el.name) : []

  let options = [...MOVEMENT_TYPES, ...mappedSubcount]

  //CALL ENDPOINT SUBCOUNT
  async function getAll() {
    try {
        const response = await api().get(`${subcount_endpoint}`);
        if(response.data) {
          setSubcount(response.data.content)
        }
    }
    catch(error) {
            console.log(error)
    }
  }
  

  useEffect(() => {
      getAll()
  }, [show])


  useEffect(() => {
      getAll()
  }, [])

console.log(options)

  //CREATE NEW SUBACCOUNT and ADD TO OPTIONS

  async function postItem(data){
    try {
        await api().post(
            `${subcount_endpoint}`,
            data
        );
        modalBack();
        props.handleClose()

    } catch (error) {
        console.log(error);
    }
}

  return (
    <>
      <div className="d-block w-100 mb-3 mt-4">
        <div>
          <h2 className="fs-20 text-dark mb-2 fw-bold">Nuovo sottoconto</h2>
        </div>
      </div>
      <Form>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="flex-shrink-0">
            <ButtonRm variant="outline" onClick={nextModal}>Nuovo</ButtonRm>
          </div>
          <div className="flex-shrink-0 ms-3">
            <div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
              <i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
              <input
                type="text"
                name=""
                placeholder="Cerca"
                className="flex-grow-1 border-0 w-100 fs-14"
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-block" style={{overflowY:"auto", maxHeight:"300px"}} >
          {options.map((menu, index) => {
            if (menu.toLowerCase().indexOf(search_name.toLowerCase()) !== -1)
              return <ServerTypeCard key={index} name={menu} />;
          })}
          {/* {props.user_movement_type.map((menu, index) => {
            if (menu.toLowerCase().indexOf(search_name.toLowerCase()) !== -1)
              return <UserTypeCard key={index} name={menu} />;
          })} */}
        </div>
        <Modal show={show} className="type-modal" backdrop="static" centered>
          <Modal.Body className="p-3">
            <label
              className="float-start black-link mt-2 back-icon"
              onClick={modalBack}
            >
              <BsChevronLeft />
              Indietro
            </label>
            <Button
              variant=""
              onClick={modalBack}
              className="float-end border-0 bg-white"
            >
              <i className="bi bi-x"></i>
            </Button>
            <NewTypeModal modalBack={modalBack} addItem={postItem}/>
          </Modal.Body>
        </Modal>
      </Form>
    </>
  );
}
export default AddTypeModal;