import "../../../assets/css/CardBacheca.css";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PriorityTag from "../PriorityTag";
import pcUnit from '../../../assets/images/placeholder-unit-medium.png'
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { useNavigate } from "react-router-dom";
import { checkUserRole, checkOwner, checkConductor, checkSupplier } from "../../../utils/checkUserRole";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { PieChart } from '@mui/x-charts/PieChart';

import api, { real_estate_management_endpoint, unit_endpoint } from "../../../api/api";

import UnitCard from "../../Affitti/UnitCard";
import { BankCard, BankCardItem } from "../../BankCard";



//generate a random color for user and keep the same color for the user always
//creerei un array con colori approvati (una decina)
//assegno un colore di quelli approvati quando creo attività
//e si salva nell'array

//oppure c'è sempre questo array di colori, e a ogni utente è assegnato uno specifico colore
/*
{
    ...object,
    user: {
        name: "Mario rossi",
        color: "red"
    }
}

*/


function ContainerCardBacheca({name, children, direction=null, morePadding=false}){

    const directionClass = direction === 'row' ? 'd-flex flex-direction-row' : ''
    const padding = morePadding? "px-5 py-4" : " px-3 py-4"

    return (
        <div  className="card-bacheca-container mb-3 " style={{overflowY: "hidden"}}>
            <div className="header">
                <p>{name}</p>
            </div>
            <div className={` ${directionClass} ${padding}  `}>
                {children}
            </div>
        </div>
    )
}

function StatusTag({status, size='', ...props }){
    return (
        <div className={`statusTag ${size} ${status }`} {...props} >
            {status === 'aperta' && <p>Aperta</p>}
            {status === 'incorso' && <p>In Corso</p>}
            {status === 'risolta' && <p>Risolta</p>}
            {status === 'ritardo' && <p>In Ritardo</p>}
        </div>
    )
}

/* CARD ATTIVITA' */

function CardAttività(props){

    const initials = props.item.name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')

    const startDate = props.item.startDate?.substr(0, 10);
  const expirationDate = props.item.expirationDate?.substr(0, 10);
  const endDate = props.item.endDate ? props.item.endDate.substr(0, 10) : "";
  let status;

  let todayDate = new Date().toISOString().slice(0, 10);


  if (todayDate < startDate?.split(",").join("-")) {
    status = "aperta";
  } else if (todayDate >= startDate?.split(",").join("-")) {
    status = "incorso";
  } else if (props.item.endDate) {
    status = "risolta";
  } else if (todayDate > expirationDate?.split(",").join("-")) {
    status = "in ritardo";
  }

    
    
    return(
        <Link to={`/statoavanzamentolavori/attivita/${props.item.id}`} className="text-dark">
             <div className="mb-2 card-gestione card-att-bacheca">
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-14 fw-bold">{capitalizeFirstLetter(props.item.name)}</p>
          <StatusTag status={status} size='mini'/>
        </div>
        <p className="fs-12 opacity-50 gestione-details text-uppercase"> {} </p>
      </div>
        </Link>
    )
}


/* CARD GESTIONI */

function CardGestione({name, id, author, creation_date, setShowRc,handleCloseRc}){
    
    return(
        <Link to={`/statoavanzamentolavori/gestioni/${id}`} className="text-dark">
            <div className="d-flex align-items-center mb-2 card-gestione">
                <div>
                    <p className="fs-14 text-capitalize">{name}</p>
                    <p className="fs-10 opacity-50 gestione-details"> Creato da user {author}, il {creation_date.substr(0, 10)} </p>
                </div>
                    
            </div>
        </Link>
    )
}

/* CARD UNITA' */

function CardUnità({name, image, typology,id }){

    const navigate = useNavigate()

    /*get units SLC*/
    const [unitSlc, setUnitSlc] = useState(null);
    const [unitProperty, setUnitProperty] = useState(null)

    const [ownerModal, setOwnerModal] = useState(false)

    function showOwnerModal(){
        setOwnerModal(true)
    }

    function closeOwnerModal(){
        setOwnerModal(false)
        setUnitProperty(false)
        setUnitSlc(false)
    }

    function handleCardUnit(e){
        e.preventDefault()

        if(checkUserRole()){
            navigate(`/statoavanzamentolavori/unitaestabili/${id}`)
        } else if (checkOwner()){
            //AGGIUNGERE CONTROLLO SE ESISTE VAL RELATIVO A QUELL'IMMOBILE
            getUnitFromSal()
            showOwnerModal()
        }
        
    }

    //PRENDO L'UNITA' CLICCATA (IMPORTATA DA PROPERTY A SAL)
    async function getUnitFromSal() {
        try {
        const response = await api().get(`${real_estate_management_endpoint}${id}`);
        if (response.data) {
            console.log(response)
            const data = response.data
            //SALVO I DATI DELL'UNITA' DA SAL
            setUnitSlc(data);
            //CERCO I DATI RELATIVI ALL'UNITA' SU PROPERTY
            getUnitFromProperty(response.data.unitData)
        }
        } catch (err) {
        console.log(err);
        }
    }

    async function getUnitFromProperty(unitId) {
        try {
        const response = await api().get(`${unit_endpoint}${unitId}`);
        if (response.data) {
            
            //SALVO I DATI DELL'UNITA' DA SAL
            setUnitProperty(response.data);

        }
        } catch (err) {
        console.log(err);
        }
    }

    console.log(unitSlc, unitProperty)

    return(
        <>
        <div className="text-dark cursor-pointer" onClick={(e) =>{ handleCardUnit(e)}}>
            <div className="align-items-center mb-2 card-unità">
                    <div className="unit-img-container">
                        <img loading="lazy" className="img-fluid" src={image? image:pcUnit} />
                    </div>
                    <div className="text-center mx-auto unit-bottom">
                        <p className="fs-10 unit-type opacity-50 text-uppercase">{typology}</p>
                        <p className="unit-name fs-14">{name}</p>
                        {checkOwner() && <small><i class="bi bi-person"></i> NOME_GESTORE</small>}
                    </div>
            </div>
        </div>

        <Modal
            centered
            show={ownerModal}
            onHide={closeOwnerModal}
        >
        <Modal.Header closeButton style={{border:"none"}}>
        </Modal.Header>
            <Modal.Body className="">
               { unitProperty && 
                <BankCard 
                name={unitProperty?.name}         
                color="#78ab8b" 
                deleteVisible={false} 
                index={unitProperty.id}
                showModal={null}
                col={'col'}
                >
                <BankCardItem property="CONDOMINIO" value={unitProperty?.condominium?.[0]?.name}/>
                <BankCardItem property="INDIRIZZO" value={unitProperty?.address}/>
                <BankCardItem property="CIVICO" value={unitProperty?.houseNumber}/>
                <BankCardItem property="INTERNO" value={unitProperty?.internal}/>
                <BankCardItem property="CITTA’" value={unitProperty?.municipality}/>
                <BankCardItem property="PROV" value={unitProperty?.province}/>
                <BankCardItem property="CAP" value={unitProperty?.cap}/>
            
            </BankCard>}
            </Modal.Body>
        </Modal>
        </>
    )
}



/* CARD PERSONE*/

function CardPersona({name, id, role, surname}){

    const initials = name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')

    return(
        <div className="align-items-center mb-2 card-persona">
                <div className="person-circle mx-auto d-flex align-items-center justify-content-center mt-3 ">
                    <p>{initials}</p>
                </div>
                <div className="text-center mx-auto person-bottom">
                    <p className="fs-10 unit-type opacity-50 text-uppercase">{role}</p>
                    <p className="unit-name fs-14">{name} {surname}</p>
                </div>
        </div>
    )
}

function ButtonBacheca({children, onClick, isGestore=true}){
    return(
        <Col className="mb-2" xs={12} sm={6} xl={isGestore ? 3 : 6}>
            <Button className="button-bacheca" onClick={onClick}>{children}</Button>
        </Col>
    )
}





export {ContainerCardBacheca, CardAttività, CardGestione, CardUnità, CardPersona, ButtonBacheca}