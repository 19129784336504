import React, { useState } from "react";
import "../../../assets/css/Documenti.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import NewTemplateModal from "./NewTemplateModal";
import { BsPlusCircleFill } from "react-icons/bs";
import { connect } from "react-redux";
import { setSearchName } from "../../../actions/profile";

const ModelliBtn = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-flex align-items-center mt-2 justify-content-between btn-search-group">
        <div className="search-input">
          <div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
            <i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
            <input
              type="text"
              name=""
              placeholder="Cerca"
              className="flex-shink-0 border-0 w-100 fs-16"
              onChange={(e) => {
                props.setSearchName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="plus-button">
          <BsPlusCircleFill className="fs-40 plus-btn" onClick={handleShow} />
        </div>
      </div>

    </>
  );
};

export default connect(null, { setSearchName })(ModelliBtn);
