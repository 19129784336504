import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoAccesso () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Accesso"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Effettuare l'accesso" />
            <TreeItem nodeId="2" label="Codice OTP" />

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Accesso (Login)</h1>
      <h2 style={{ color:'transparent', height: 0}}>Accesso</h2>


      <h2 id="1">Effettuare l'accesso</h2>
      <p >Per effettuare l’accesso inserisci
      l’email e la password che hai indicato in fase di registrazione e
      clicca sul pulsante rosso “Accedi”.</p>
      <img loading="lazy" src="https://i.postimg.cc/L6Bg6wYz/accesso-2.png"  />
      <h2 id="2">Codice OTP</h2>
      <p >Apparirà una finestra popup che ti
      chiederà di inserire di inserire l’OTP (ONE TIME PASSWORD, ovvero
      un codice numerico di 6 cifre che ti verrà inviato via e-mail).</p>
      <p >Inserisci il codice e clicca sul
      pulsante “CONTINUA” per accedere al tuo account</p>
      <img loading="lazy" src="https://i.postimg.cc/ZKhym4Rb/otp-2.png"  />

      <h3 id="2">COSA FARE SE NON ARRIVA L'OTP</h3>


      <p className="subtitle">CONTROLLA L'EMAIL</p>
      <p >Non ti è arrivato L’OTP? Potrebbero
      volerci alcuni minuti prima che ti arrivi l’email. 
      </p>
      <p >Controlla la casella di posta,
      eventualmente anche la cartella di SPAM o POSTA INDESIDERATA.</p>
      <p className="subtitle">RICHIEDI UN NUOVO OTP</p>

      <p >Altrimenti premi sul link “Invia
      nuovamente OTP”.
      </p>

      <p className="subtitle">CONTATTA IL SUPPORTO</p>

      <p >Se dopo un paio di minuti non hai
      ricevuto l’email, puoi contattarci tramite la chatbox che trovi in
      basso a destra, o puoi chiamare il supporto al numero 3272734237.</p>
      <img loading="lazy" src="https://i.postimg.cc/CxVmB3xJ/chat.png" />

    </div>
  );
}

