import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import estate_img from "../../../assets/images/real_estate.png";
import TopHeading from "../../TopHeading";
import { Row, Col } from "react-bootstrap";
import api, { unit_endpoint, 
	owner_endpoint,
	stabili_endpoint,
	supplier_endpoint,
	condominium_unit_assignment_endpoint,
	owner_unit_assignment_endpoint,
	pertinence_unit_assignment_endpoint,
	conductor_unit_assignment_endpoint,
	supplier_unit_assignment_endpoint,
	insurance_unit_assignment_endpoint,
	counter_unit_assignment_endpoint,
	act_unit_assignment_endpoint,
	act_endpoint,
	counter_endpoint,
	insurance_endpoint } from "../../../api/api";
import RegistryE from "./Edit/RegistryE";
import OwnersE from "./Edit/OwnersE";
import RelevanceE from "./Edit/RelevanceE";
import DataE from "./Edit/DataE";
import PhotoE from "./Edit/PhotoE";
import AttachmentsE from "./Edit/AttachmentsE";
import CondominiumE from "./Edit/CondominiumE";
import UtilitiesE from "./Edit/UtilitiesE";
import Profitability from "./Edit/Profitability";
import Lists from "./Edit/Lists";
import DefaultUnitImageMedium from "../../../assets/images/placeholder-unit-medium.png";
import DefaultUnitImage from "../../../assets/images/placeholder-unit.png";
import CadastreE from "./Edit/CadastreE"
import DestinationE from "./Edit/DestinationE"
import FeaturesE from "./Edit/FeaturesE";
import AttiE from "./Edit/AttiE"

import Collapse from 'react-bootstrap/Collapse';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { useNavigate } from "react-router-dom";



const EditRealEstateUnit = () => {

	const navigate = useNavigate()
	const { id } = useParams();
	const [formData, setformData] = useState({});
	const [state, setState] = useState(() => {
		let initialState = JSON.parse(localStorage.getItem("unitEditState"));
		return initialState ? initialState : 0;
	});

	function onChange (e){
		const {value, name, checked, type} = e.target

		let convertedValue

		if(	name == "ofProperty" ||
			name == "scaleMillesimi" ||
			name == "lift" ||
			name == "reception"||
			name == "heating" || 
			name == "district" || 
			name == "water" || type === "number"){
				convertedValue = value.replace(',', '.')
			} else {
				convertedValue = value
			}


		if (type === "checkbox") {
			setformData({ ...formData, [name]:checked });
		} else {
			setformData({ ...formData, [name]: convertedValue });
		}

	}

	useEffect(() => {
		localStorage.setItem("unitEditState", JSON.stringify(state));
	}, [state]);

	const handleState = (index) => {
		setState(index);
	};

	//submenu
	const [subState, setSubState] = useState((1));

	const handleSubState = (index) => {
		setSubState(index);
	};

	const getUnitById = async () => {
		try {
			const res = await api().get(`${unit_endpoint}${id}`);
			if (res.data) {
		
				if (res.data.parcel && Array.isArray(res.data.parcel)) {
					const parsedParcel = res.data.parcel.map((item) => JSON.parse(item.replace(/'/g, '"')));
					res.data.parcel = parsedParcel;
				}

				if (res.data.gasPlan) {
					const parsedGasPlan = JSON.parse(res.data.gasPlan)
					res.data.gasPlan = parsedGasPlan;
				}
		
				if (res.data.coldWaterPlan) {
				const parsedColdWaterPlan = JSON.parse(res.data.coldWaterPlan)
				res.data.coldWaterPlan = parsedColdWaterPlan;
				}
		
				if (res.data.electricityPlan) {
				const parsedElectricityPlan = JSON.parse(res.data.electricityPlan)
				res.data.electricityPlan = parsedElectricityPlan;
				}
				
				if (res.data.relationData) {
					const parsedRelationData = JSON.parse(res.data.relationData)
					res.data.relationData = parsedRelationData
				}

				/* if (res.data.image == "" || res.data.image == null)
					res.data.image = DefaultUnitImageMedium; */

				setformData(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getUnitById();
	}, []);

	/* const editUnit = async (data) => {

		console.log(data, "datasent")
		try {
			// TODO manage better in future
			const parcelStrings = [];
			const owners = [...data.owners]
			const conductors = [...data.conductors]
			const pertinences = [...data.pertinence]
			const condominium = [...data.condominium]
			const suppliers = [...data.suppliers];
			const insurances = [...data.insurances];
			const readCounters = [...data.readCounters]
			const acts = [...data.acts]

			delete data.pertinence;
			delete data.owners;
			delete data.condominium;
			delete data.conductors;
			delete data.suppliers
			delete data.insurances
			delete data.readCounters
			delete data.acts

			// PRIMA DI TUTTO Creazione delle assicurazioni (solo quelle nuove)
			const newInsurances = [];

			if (insurances.length > 0) {
				for (const insuranceData of insurances) {
					//stringify agency first
					const agencyString = JSON.stringify(insuranceData.agency);
					const insuranceDataStringified = {
					...insuranceData,
					agency: agencyString,
					};

					const createdInsuranceResponse = await api().post(`${insurance_endpoint}`, insuranceDataStringified);
					const createdInsurance = createdInsuranceResponse.data;
					newInsurances.push(createdInsurance);
				}
			}

			// PRIMA DI TUTTO Creazione delle letture solo quell nuove
			const newReadCounters = [];

			if (readCounters.length > 0) {
				for (const counter of readCounters) {
					const createdCounterResponse = await api().post(`${counter_endpoint}`, counter);
					const createdCounter = createdCounterResponse.data;
					newReadCounters.push(createdCounter );
				}
			}

			// PRIMA DI TUTTO Creazione degli atti, solo quelli nuovi
			const newActs = [];

			if (acts.length > 0) {
			for (const act of acts) {
				// Verifica se l'atto è già presente nell'existingArray
				const isAlreadyCreated = formData.acts.some(existingAct => existingAct.id === act.id);

				// Se l'atto non è già stato creato, effettua la creazione
				if (!isAlreadyCreated) {
				const createdActResponse = await api().post(`${act_endpoint}`, act);
				const createdAct = createdActResponse.data;
				newActs.push(createdAct);
				}
			}
			}









			//REMOVE SOME ITEMS FROM FORM DATA
			//WE NEED TO ADD ALSO OWNERS IN A SECOND MOMENT
			const {attachment,
				planimetry, photo, image,
				...dataToSend} = data

			dataToSend.parcel.forEach((objString) => {
				parcelStrings.push(JSON.stringify(objString));
			});
			dataToSend.parcel = parcelStrings;

			let formDataToSend = new FormData();

			attachment.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSend.append("parts", blob, file.name);
			});
			//delete formData.attachment;

			planimetry.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSend.append("parts", blob, file.name);
			});
			//delete formData.planimetry;

			photo.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSend.append("parts", blob, file.name);
			});
			//delete formData.photo;

			if (image !== "") {
				const blob = new Blob([{ ...image }]);
				formDataToSend.append("parts", blob, image.name);
			}
			//delete formData.image;

			let sentUpload = true;
			if (formDataToSend.get("parts") === null) sentUpload = false;
			formDataToSend.append("body", JSON.stringify(dataToSend));

			// Stringify dei piani tariffari prima della post
			dataToSend.gasPlan = JSON.stringify(dataToSend.gasPlan);
			dataToSend.coldWaterPlan = JSON.stringify(dataToSend.coldWaterPlan);
			dataToSend.electricityPlan = JSON.stringify(dataToSend.electricityPlan);
			dataToSend.relationData = JSON.stringify(dataToSend.relationData);
			dataToSend.parcel = formData.parcel.map(item => JSON.stringify(item))



			let response;
			if (sentUpload) {
				response = await api().put(`${unit_endpoint}upload`, formDataToSend);
			} else { 
			response = await api().put(`${unit_endpoint}`, dataToSend);
			} 

			// ASSOCIAZIONE CONDOMINIO
			if (condominium.length > 0 && response.data.id) {
				await api().post(`${condominium_unit_assignment_endpoint}`, {
				condominiumID: condominium[0].id,
				unitID: response.data.id,
				});
			}
			
			// ASSOCIAZIONE OWNERS
			if (owners.length > 0 && response.data.id) {
				for (const owner of owners) {
				await api().post(`${owner_unit_assignment_endpoint}`, {
					ownerID: owner.id,
					unitID: response.data.id,
				});
				}
			}
			
			// ASSOCIAZIONE CONDUTTORI
			if (conductors.length > 0 && response.data.id) {
				for (const conductor of conductors) {
				await api().post(`${conductor_unit_assignment_endpoint}`, {
					conductorID: conductor.id,
					unitID: response.data.id,
				});
				}
			}

			//ASSOCIAZIONE PERTINENZE
				if (pertinences.length > 0 && response.data.id) {
					for (const pertinence of pertinences) {
					await api().post(`${pertinence_unit_assignment_endpoint}`, {
						pertinenceID: pertinence.id,
						unitID: response.data.id,
					});
					}
				}
				
			

			//ASSOCIAZIONE FORNITORI
			if (suppliers.length > 0 && response.data.id) {
					for (const supplier of suppliers) {
					await api().post(`${supplier_unit_assignment_endpoint}`, {
						supplierID: supplier.id,
						unitID: response.data.id,
					});
					}
			}

			//ASSOCIAZ ASSICURAZIONI APPENA CREATE

			if (newInsurances.length > 0 && response.data.id) {
				for (const insurance of newInsurances) {
				await api().post(`${insurance_unit_assignment_endpoint}`, {
				insuranceID: insurance.id,
				unitID: response.data.id,
				});
			}
			}

			//ASSOCIAZ COUNTER APPENA CREATE

			if (newReadCounters.length > 0 && response.data.id) {
				for (const readCounter of newReadCounters) {
				await api().post(`${counter_unit_assignment_endpoint}`, {
				counterID: readCounter.id,
				unitID: response.data.id,
				});
			}
			}

				//ASSOCIAZ ATTI APPENA CREATE

				if (newActs.length > 0 && response.data.id) {
					for (const act of newActs) {
					await api().post(`${act_unit_assignment_endpoint}`, {
					actID: act.id,
					unitID: response.data.id,
					});
				}
				} 



				if (newActs.length > 0 && response.data.id) {
					for (const act of newActs) {
					// Verifica se l'atto è già associato
					const isAlreadyAssociated = formData.acts.some(existingAct => existingAct.id === act.id);

					console.log(newActs, isAlreadyAssociated)
				  
					// Se l'atto non è già associato, effettua l'associazione
					if (!isAlreadyAssociated) {
					await api().post(`${act_unit_assignment_endpoint}`, {
						actID: act.id,
						unitID: response.data.id,
					});
					}
				}
				}
		} catch (error) {
			console.log(error);
		}

		setSaveState(false)
	}; */

	const update = async (data) => {

		//STRINGIFY PIANI TARIFFARI, RELATIONDATA
		//ASSICUTRAZIONI - AGENZIA DA STRINGIFY

		//FARE ASSOCIAZIONI - ASSOCIARE SOLO QUELLI NUOVI
		//FARE DICIASSOCIAZIONI

		//editUnit({ ...formData, ...data })

		try {

			const newForm = { ...formData, ...data }

			const owners = [...newForm.owners]
			const conductors = [...newForm.conductors]
			const pertinences = [...newForm.pertinence]
			const condominium = [...newForm.condominium]
			const suppliers = [...newForm.suppliers];
			const insurances = [...newForm.insurances];
			const readCounters = [...newForm.readCounters]
			const acts = [...newForm.acts]
			const newAttachment = newForm?.newAttachment?.length > 0 ? [...newForm.newAttachment] : []
			const newPhoto = newForm?.newPhoto?.length > 0 ? [...newForm.newPhoto] : []
			const newPlanimetry = newForm?.newPlanimetry?.length > 0 ? [...newForm.newPlanimetry] : []

			delete newForm.pertinence;
			delete newForm.rent;
			delete newForm.activities;
			delete newForm.owners;
			delete newForm.condominium;
			delete newForm.conductors;
			delete newForm.suppliers
			delete newForm.insurances
			delete newForm.readCounters
			delete newForm.acts
			delete newForm.photo
			delete newForm.planimetry
			delete newForm.attachment
			delete newForm.newAttachment
			delete newForm.newPhoto
			delete newForm.newPlanimetry
			delete newForm.attachment
			delete newForm.reports
			delete newForm.installments
			delete newForm.firstNotes



			//REMOVE SOME ITEMS FROM FORM DATA
			//WE NEED TO ADD ALSO OWNERS IN A SECOND MOMENT
			const {attachment,
				planimetry, photo, image,
				...dataToSend} = newForm

			
			// PRIMA DI TUTTO Creazione degli atti, solo quelli nuovi
			const newActs = [];

			console.log(
				newForm.acts, "acts")
				console.log(
					formData.acts, "formData")
					console.log(
						data.acts, "data")

			if (acts.length > 0) {
			for (const act of acts) {

				const {attachment, ...dataToSend} = act

				//GESTIONE ALLEGATI
				let formDataToSend = new FormData();
				
				attachment.forEach((file) => {
					if(typeof file != 'string'){
						const blob = new Blob([file]);
						formDataToSend.append("parts", blob, file.name)
					}
				})
		
				let sentUpload = formDataToSend.get("parts") !== null;

				if(sentUpload) {
					await api().put(`${act_endpoint}upload/${act.id}`, formDataToSend);
				}

				// Verifica se l'atto è già presente nell'existingArray
				const isAlreadyCreated = formData.acts.some(existingAct => existingAct.id === act.id);

				// Se l'atto non è già stato creato, effettua la creazione
				if (!isAlreadyCreated) {
				const createdActResponse = await api().post(`${act_endpoint}`, dataToSend);
				const createdAct = createdActResponse.data;
				newActs.push(createdAct);
				}
			}
			}

			// PRIMA DI TUTTO Creazione delle assicurazioni
			const newInsurances = [];

			if (insurances.length > 0) {
				for (const insuranceData of insurances) {
					const {attachment, ...dataToSend} = insuranceData

					//stringify agency first
					const agencyString = JSON.stringify(dataToSend.agency);
					const insuranceDataStringified = {
					...dataToSend,
					agency: agencyString,
					};

					//GESTIONE ALLEGATI
			let formDataToSend = new FormData();
			let sentUpload = formDataToSend.get("parts") !== null;

			attachment.forEach((file) => {
				if(typeof file != 'string'){
					const blob = new Blob([file]);
					formDataToSend.append("parts", blob, file.name)
				}
			});

			if(sentUpload) {
				await api().put(`${insurance_endpoint}upload/${insuranceData.id}`, formDataToSend);
			}

					// Verifica se assicurazione è già presente nell'existingArray
					const isAlreadyCreated = formData.insurances.some(existingIns => existingIns.id === insuranceData.id);

					// Se non è già stato creato, effettua la creazione
					if(!isAlreadyCreated){
						const createdInsuranceResponse = await api().post(`${insurance_endpoint}`, insuranceDataStringified);
						const createdInsurance = createdInsuranceResponse.data;
						newInsurances.push(createdInsurance)
					}
				}
			}

			// PRIMA DI TUTTO Creazione delle letture
			const newReadCounters = [];

			if (readCounters.length > 0) {
					for (const counter of readCounters) {
						// Verifica se counter è già presente nell'existingArray
						const isAlreadyCreated = formData.readCounters.some(existingCounter => existingCounter.id === counter.id);
					
					// Se non è già stato creato, effettua la creazione
					if(!isAlreadyCreated){
						const createdCounterResponse = await api().post(`${counter_endpoint}`, counter);
						const createdCounter = createdCounterResponse.data;
						newReadCounters.push(createdCounter );
					}
				}
			}


			//ALLEGATI
			//GESTIONE ALLEGATI (sono array di file, tranne image)

			let formDataToSendAttachment = new FormData();
			let formDataToSendPlanimetry = new FormData();
			let formDataToSendPhoto = new FormData();
			let formDataToSendImage = new FormData();

			newAttachment.forEach((file) => {
				if(typeof file != 'string'){
					const blob = new Blob([file]);
					formDataToSendAttachment.append("parts", blob, file.name)
				}
			});

			 newPlanimetry.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSendPlanimetry.append("parts", blob, file.name);
			});

			newPhoto.forEach((file) => {
				const blob = new Blob([file]);
				formDataToSendPhoto.append("parts", blob, file.name);
			});

			/* if (image !== "") {
				const blob = new Blob([{ ...image }]);
				formDataToSendImage.append("parts", blob, image.name);
			}  */

			let sentUploadAttachment = formDataToSendAttachment.get("parts") !== null;
			let sentUploadPlanimetry = formDataToSendPlanimetry.get("parts") !== null;
			let sentUploadPhoto = formDataToSendPhoto.get("parts") !== null;
			/* let sentUploadImage = formDataToSendImage.get("parts") !== null; */

			if (sentUploadAttachment) {
				await api().put(`${unit_endpoint}upload/${id}`, formDataToSendAttachment);
			}
			 if (sentUploadPlanimetry) {
				await api().put(`${unit_endpoint}upload_planimetry/${id}`, formDataToSendPlanimetry);
			}
			if (sentUploadPhoto) {
				await api().put(`${unit_endpoint}upload_photo/${id}`, formDataToSendPhoto);
			}
			/* if (sentUploadImage) {
				await api().put(`${unit_endpoint}upload_image/${id}`, formDataToSendImage);
			}  */

			//PUT UNIT

			let response 
			response = await api().put(`${unit_endpoint}${id}`, {
				...newForm,
				gasPlan: JSON.stringify(newForm.gasPlan),
				coldWaterPlan: JSON.stringify(newForm.coldWaterPlan),
				electricityPlan: JSON.stringify(newForm.electricityPlan),
				relationData: JSON.stringify(newForm.relationData),
				parcel: newForm.parcel.length> 0 ? newForm.parcel.map(item => JSON.stringify(item)) : [],
				pertinenceOf: newForm.pertinenceOf.length> 0 ? newForm.pertinenceOf.map(item => JSON.stringify(item)) : []

			});

			
			

			//ASSOCIAZIONE ATTO
			if (newActs.length > 0 && response.data.id) {
				for (const act of newActs) {
					// Verifica se l'atto è già associato
					const isAlreadyAssociated = formData.acts.some(existingAct => existingAct.id === act.id);

					console.log(newActs, isAlreadyAssociated)
				
					// Se l'atto non è già associato, effettua l'associazione
					if (!isAlreadyAssociated) {
					await api().post(`${act_unit_assignment_endpoint}`, {
						actID: act.id,
						unitID: response.data.id,
					});
					}
				}
			}

			//ASSOCIAZIONE COUNTER 
			if (newReadCounters.length > 0 && response.data.id) {
				for (const readCounter of newReadCounters) {

					// Verifica se è già associato
					const isAlreadyAssociated = formData.readCounters.some(existingAct => existingAct.id === readCounter.id);

				
					// Se non è già associato, effettua l'associazione
					if (!isAlreadyAssociated) {
						await api().post(`${counter_unit_assignment_endpoint}`, {
						counterID: readCounter.id,
						unitID: response.data.id,
						})
					}
				}
			}

			//ASSOCIAZ ASSICURAZIONI APPENA CREATE

			if (newInsurances.length > 0 && response.data.id) {
				for (const insurance of newInsurances) {

					// Verifica se è già associato
					const isAlreadyAssociated = formData.insurances.some(existingEl => existingEl.id === insurance.id);

					// Se non è già associato, effettua l'associazione
					if (!isAlreadyAssociated) {
						await api().post(`${insurance_unit_assignment_endpoint}`, {
						insuranceID: insurance.id,
						unitID: response.data.id,
						})
					}	
				}
			}

			// ASSOCIAZIONE CONDOMINIO
			if (condominium.length > 0 && response.data.id) {
				// Verifica se è già associato
				const isAlreadyAssociated = formData.condominium.some(existingEl => existingEl.id === condominium[0].id)

				if (!isAlreadyAssociated) {
					await api().post(`${condominium_unit_assignment_endpoint}`, {
					condominiumID: condominium[0].id,
					unitID: response.data.id,
					})
				}
			}
			
			// ASSOCIAZIONE OWNERS
			if (owners.length > 0 && response.data.id) {
				for (const owner of owners) {
					const isAlreadyAssociated = formData.owners.some(existingEl => existingEl.id === owner.id);

					if (!isAlreadyAssociated) {
					await api().post(`${owner_unit_assignment_endpoint}`, {
						ownerID: owner.id,
						unitID: response.data.id,
					})
					}
				}
			}
			
			// ASSOCIAZIONE CONDUTTORI
			if (conductors.length > 0 && response.data.id) {
				for (const conductor of conductors) {
					const isAlreadyAssociated = formData.conductors.some(existingEl => existingEl.id === conductor.id);

					if (!isAlreadyAssociated) {
						await api().post(`${conductor_unit_assignment_endpoint}`, {
						conductorID: conductor.id,
						unitID: response.data.id,
						});
					}
				}
			}

			//ASSOCIAZIONE PERTINENZE
			if (pertinences.length > 0 && response.data.id) {
				for (const pertinence of pertinences) {

					const isAlreadyAssociated = formData.pertinence.some(existingEl => existingEl.id === pertinence.id);
					console.log("sono dentro Pert", isAlreadyAssociated)
					if (!isAlreadyAssociated) {
					await api().post(`${pertinence_unit_assignment_endpoint}`, {
						pertinenceID: pertinence.id,
						unitID: response.data.id,
						})
					}
				}
			}
				


			//ASSOCIAZIONE FORNITORI
			if (suppliers.length > 0 && response.data.id) {
				console.log("sono dentro1")
					for (const supplier of suppliers) {
						const isAlreadyAssociated = formData.suppliers.some(existingEl => existingEl.id == supplier.id);

						console.log("sono dentro2", isAlreadyAssociated, formData.suppliers)
					if (!isAlreadyAssociated) {	
						console.log("sono dentro3 - we-we")

						await api().post(`${supplier_unit_assignment_endpoint}`, {
							supplierID: supplier.id,
							unitID: response.data.id,
						})
					}
				}
			}


			 //DISSOCIAZIONE ATTI ELIMINATI E PUT SE CAMBIATI

			if (formData.acts.length > 0) {
				for (const existingAct of formData.acts) {
				  const isDeleted = !acts.some(act => act.id === existingAct.id);
			  
				  if (isDeleted) {
					// Esegui la dissociazione
					await api()({
					  method: 'delete',
					  url: `${act_unit_assignment_endpoint}disassociate`,
					  data: {
						actID: existingAct.id,
						unitID: response.data.id,
					  }
					});
				  } else {
					const matchingAct = acts.find(act => act.id === existingAct.id);
			  
					// Confronta le proprietà specifiche dell'atto
					const propertiesMatch = (
						existingAct.type === matchingAct.type &&
						existingAct.actDate === matchingAct.actDate &&
						existingAct.fiscalEffectDate === matchingAct.fiscalEffectDate &&
						existingAct.notary === matchingAct.notary &&
						existingAct.collectionNumber === matchingAct.collectionNumber &&
						existingAct.repertoireNumber === matchingAct.repertoireNumber &&
						existingAct.registrationOffice === matchingAct.registrationOffice &&
						existingAct.registrationDate === matchingAct.registrationDate &&
						existingAct.registrationSerial === matchingAct.registrationSerial &&
						existingAct.registrationNumber === matchingAct.registrationNumber &&
						existingAct.transcriptionDate === matchingAct.transcriptionDate &&
						existingAct.transcriptionLocation === matchingAct.transcriptionLocation &&
						existingAct.transcriptionNumber === matchingAct.transcriptionNumber &&
						existingAct.actValue === matchingAct.actValue &&
						existingAct.historicalValue === matchingAct.historicalValue &&
						existingAct.revaluedValue === matchingAct.revaluedValue &&
						existingAct.transferor === matchingAct.transferor &&
						existingAct.transferee === matchingAct.transferee &&
						existingAct.note === matchingAct.note &&
						JSON.stringify(existingAct.attachment) === JSON.stringify(matchingAct.attachment)
					  // Aggiungi altre proprietà da controllare...
					);
			  
					if (!propertiesMatch) {
					  // Esegui la richiesta PUT per aggiornare l'atto all'endpoint "insurance enpoint"
					  await api().put(`${act_endpoint}${existingAct.id}`, matchingAct)
					}
				  }
				}
			  }
			  

			//DISSOCIAZIONE COUNTER ELIMINATI


				if (formData.readCounters.length > 0) {
					for (const existingEl of formData.readCounters) {
						const isDeleted = !readCounters.some(item => item.id === existingEl.id);

						if (isDeleted) {
						// Esegui la dissociazione
						await api()({
							method: 'delete',
							url: `${counter_unit_assignment_endpoint}disassociate`,
							data: {
							counterID: existingEl.id,
							unitID: response.data.id,
							}
						});
						} else {
						// Trova l'elemento corrispondente in "readCounters"
						const matchingEl = readCounters.find(item => item.id === existingEl.id);

						// Confronta le proprietà specifiche dell'elemento
						const propertiesMatch = (
							existingEl.type === matchingEl.type &&
							existingEl.name === matchingEl.name &&
							existingEl.date === matchingEl.date &&
							existingEl.value === matchingEl.value
						);

						if (!propertiesMatch) {
							// Esegui la richiesta PUT per aggiornare l'elemento all'endpoint desiderato
							await api().put(`${counter_endpoint}${existingEl.id}`, matchingEl)
						}
						}
					}
					}


				//DISSOCIAZIONE ASSICURAZIONI ELIMINATI
			if (formData.insurances.length > 0) {
				for (const existingEl of formData.insurances) {
				// Verifica se counter preesistente è stato eliminato
				const isDeleted = !insurances.some(item => item.id === existingEl.id);
			
					// Se counter è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${insurance_unit_assignment_endpoint}disassociate`,
							data: {
								insuranceID: existingEl.id,
								unitID: response.data.id,
							}
						})
					}
					}
				}

			//DISSOCIAZIONE CONDOMINI ELIMINATI
			if (formData.condominium.length > 0) {
				for (const existingEl of formData.condominium) {
				// Verifica se counter preesistente è stato eliminato
				const isDeleted = !condominium.some(item => item.id === existingEl.id);
			
					// Se counter è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${condominium_unit_assignment_endpoint}disassociate`,
							data: {
								condominiumID: existingEl.id,
								unitID: response.data.id,
							}
						})
					}
					}
				}

			//DISSOCIAZIONE OWNERS ELIMINATI
			if (formData.owners.length > 0) {
				for (const existingEl of formData.owners) {
				// Verifica se counter preesistente è stato eliminato
				const isDeleted = !owners.some(item => item.id === existingEl.id);
			
					// Se counter è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${owner_unit_assignment_endpoint}disassociate`,
							data: {
								ownerID: existingEl.id,
								unitID: response.data.id,
							}
						})
					}
				}
			}

			//DISSOCIAZIONE CONDUTTORI ELIMINATI
			if (formData.conductors.length > 0) {
				for (const existingEl of formData.conductors) {
				// Verifica se counter preesistente è stato eliminato
				const isDeleted = !conductors.some(item => item.id === existingEl.id);
			
					// Se counter è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${conductor_unit_assignment_endpoint}disassociate`,
							data: {
								conductorID: existingEl.id,
								unitID: response.data.id,
							}
						})
					}
					}
			}

			//DISSOCIAZIONE PERTINENZE ELIMINATI
			if (formData.pertinence.length > 0) {
				for (const existingEl of formData.pertinence) {
				// Verifica se counter preesistente è stato eliminato
				const isDeleted = !pertinences.some(item => item.id === existingEl.id);
			
					// Se counter è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${pertinence_unit_assignment_endpoint}disassociate`,
							data: {
								pertinenceID: existingEl.id,
								unitID: response.data.id,
							}
						})
					}
				}
			}

			//DISSOCIAZIONE FORNITORI ELIMINATI
			if (formData.suppliers.length > 0) {
				for (const existingEl of formData.suppliers) {
				// Verifica se counter preesistente è stato eliminato
				const isDeleted = !suppliers.some(item => item.id === existingEl.id);
			
					// Se counter è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${supplier_unit_assignment_endpoint}disassociate`,
							data: {
								supplierID: existingEl.id,
								unitID: response.data.id,
							}
						})
					}
					}
			}

			//AGGIUNGERE ALTRE DISOCCIAZIONI SU QUESTA SCIA... */



			


		} catch (err) {
			console.log(err);
		}  
		setSaveState(false)
		await getUnitById()
	};

	const [edit_mode, setEditMode] = useState(false);

	const [saveState, setSaveState] = useState(false);

	const save = () => {
		setSaveState(true);
		setEditMode(false)
	};

	console.log(formData, "edit")
	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="" back_modal={false} />
				</div>
			</div>
			<Row className="w-100">
        <Col md={12} className="my-3 px-0 mx-2">
          	<Breadcrumb>
				<Breadcrumb.Item 
					className="settings-archive"
					onClick={() => {
						navigate('/real-estate-units')
					}}>
					Unità
				</Breadcrumb.Item>

				
			
				<Breadcrumb.Item active={true} 
					className="settings-archive"
				>
					{formData?.name}
				</Breadcrumb.Item>
          	</Breadcrumb>
        </Col>
      </Row>
			<div className="d-flex align-items-center justify-content-between mb-3 w-100">
				<h2 className="title-font fw-bold fs-24 text-capitalize">
					{formData.name ? `${formData.name}` : ""}
				</h2>
				<div className="flex-shrink-0">
					<div
						className={`d-flex align-items-center ${
							edit_mode ? "d-none" : ""
						}`}>
						<div
							className="edit-btn w-144 me-2"
							onClick={() => {
								setEditMode(true);
								setSaveState(false);

							}}>
							Modifica
						</div>
					</div>
					<div
						className={`d-flex align-items-center ${
							edit_mode ? "" : "d-none"
						}`}>
						<div className="edit-btn w-173 me-2" onClick={save}>
							Salva modifiche
						</div>
						<button
							type="button"
							onClick={() => {
								setEditMode(false);
								setSaveState(false);

							}}
							className="border border-secondary bg-white rounded-3 edit-icon-btn">
							<i
								className="bi bi-x-lg"
								style={{ color: "var(--edit-color)" }}></i>
						</button>
					</div>
				</div>
			</div>
			<div className="">
			
				<div className="state-bar">

					<div className={`state-btn-group mt-1 ${state == 6? "mb-1" : "mb-4"}`}>
						<div
							className={`${state === 0 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(0);
							}}>
							Anagrafica
						</div>
						<div
							className={`${state === 1 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(1);
							}}>
							Proprietari
						</div>
						<div
							className={`${state === 2 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(2);
							}}>
							Catasto
						</div>
						<div
							className={`${state === 3 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(3);
							}}>
							Condominio
						</div>
						<div
							className={`${state === 4 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(4);
							}}>
							Destinazione
						</div>
						<div
							className={`${state === 5 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(5);
							}}>
							Elenchi
						</div>
						<div
							className={`${state === 6 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(6);
							}}>
							Facoltativi
						</div>
					</div>
				</div>
				
				<Collapse in={state == 6}>
				<div className="state-bar">
						
						<div className="state-btn-group mt-1">
							<div
								className={`${subState === 1 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(1);
								}}>
								Descrizione
							</div>
							<div
								className={`${subState === 2 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(2);
								}}>
								Caratteristiche
							</div>
							<div
								className={`${subState === 3 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(3);
								}}>
								Utenze
							</div>
							<div
								className={`${subState === 4 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(4);
								}}>
								Redditività
							</div>
							<div
								className={`${subState === 5 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(5);
								}}>
								Atti
							</div>
							<div
								className={`${subState === 6 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(6);
								}}>
								Pertinenze
							</div>
							<div
								className={`${subState === 7 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(7);
								}}>
								Foto
							</div>
							<div
								className={`${subState === 8 ? "active" : ""} tabs-title-min`}
								onClick={(e) => {
									handleSubState(8);
								}}>
								Allegati
							</div>
						</div>
					</div>
				</Collapse>
				
					
				
				<div className="w-100 form-item-align-box py-3">
					<div
						className="mobile-state-bar mt-3"
						onChange={(e) => {
							handleState(e.target.value);
						}}>
						<select className="select-state">
							<option value="0">Anagrafica</option>
							<option value="1">Proprietari</option>
							<option value="3">Catasto</option>
							<option value="4">Condominio</option>
							<option value="4">Destinazioe</option>
							<option value="5">Elenchi</option>
							<option value="7">Descrizione</option>
							<option value="8">Caratteristiche</option>
							<option value="9">Utenze</option>
							<option value="10">Redditività</option>
							<option value="11">Atti</option>
							<option value="12">Foto</option>
							<option value="13">Allegati</option>
							<option value="14">Pertinenze</option>




						</select>
					</div>
					{state == 0 && formData && (
						<RegistryE formData={formData} 
							update={update}
							editMode={edit_mode}
							saveState={saveState}  
							id={id}
						/>
					)}
					{state == 1 && formData && (
						<OwnersE
							owners={formData.owners}
							conductors={formData.conductors}
							update={update}
							editMode={edit_mode}
							saveState={saveState}
							relationData={formData.relationData}
							
						/>
					)}
					{state == 2 && formData && (
						<CadastreE
							formData={formData}
							update={update}
							editMode={edit_mode}
							saveState={saveState}
							
						/>
					)}
					{state == 3 && formData && (
						<CondominiumE 
						formData={formData} 
						update={update}
						editMode={edit_mode}
						saveState={saveState}  />
					)}
					{state == 4 && formData && (
						<DestinationE 
						formData={formData} 
						update={update}
						editMode={edit_mode}
						saveState={saveState} />
					)}
					{state == 5 && formData && (
						<Lists formData={formData} update={update}
						editMode={edit_mode}
						saveState={saveState}
						getUnitById={getUnitById} />
					)}

					{/* {state === 4 && formData && (
						<UtilitiesE formData={formData} update={update}
						editMode={edit_mode}
						saveState={saveState} />
					)} */}

					{((state == 6 && subState == 1 && formData) || (state == 7  && formData) ) && (
						<DataE 
							formData={formData} 
							update={update}
							editMode={edit_mode}
							saveState={saveState} 
						/>
					)} 
					{((state == 6 && subState == 2 && formData) || (state == 8  && formData) ) && (
						<FeaturesE 
						formData={formData} 
						update={update}
						editMode={edit_mode}
						saveState={saveState} 
						onChange={onChange}
						/>
					)} 
					{((state == 6 && subState == 3 && formData) || (state == 9  && formData) ) && (
						<UtilitiesE 
							formData={formData} 
							update={update}
							editMode={edit_mode}
							saveState={saveState} 
						/>
					)} 
					{((state == 6 && subState == 4 && formData) || (state == 10  && formData) ) && (
						<Profitability 
							formData={formData} 
							update={update}
							editMode={edit_mode}
							saveState={saveState}
							handleSubState={handleSubState}
							handleState={handleState}
						/>
					)}
					{((state == 6 && subState == 5 && formData) || (state == 11  && formData) ) && (
						<AttiE 
							formData={formData} 
							update={update}
							editMode={edit_mode}
							saveState={saveState}
						/>
					)}
					{((state == 6 && subState == 7 && formData) || (state == 12  && formData) ) && (
						<PhotoE
							photo={formData.photo}
							planimetry={formData.planimetry}
							img={formData.image}
							update={update}
							editMode={edit_mode}
							saveState={saveState}
							id={id}
						/>
					)}
					{((state == 6 && subState == 8 && formData) || (state == 13  && formData) ) && (
						<AttachmentsE
						planimetry={formData.planimetry}
							attachment={formData.attachment}
							update={update}
							editMode={edit_mode}
							saveState={saveState}
							id={id}
							
						/>
					)}
					{((state == 6 && subState == 6 && formData) || (state == 14  && formData) ) && (
						<RelevanceE
							pertinence={formData.pertinence}
							update={update}
							editMode={edit_mode}
							saveState={saveState}
							
						/>
					)} 
				</div>
			</div>
		</>
	);
};

export default EditRealEstateUnit;
