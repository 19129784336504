import React, {useRef, useState} from "react";
import SignatureCanvas from 'react-signature-canvas'
import Modal from "react-bootstrap/Modal";
import ButtonRm from "../../ButtonRm";
import api from "../../../api/api"
import { chat_sign_endpoint, chat_sign_association_endpoint } from "../../../api/api";


export const AggiungiPopup = (props) => {

    const [signImg, setSignImg] = useState("")

    let sigCanvas = useRef({})

    const trim = () => {
        setSignImg(sigCanvas.getTrimmedCanvas().toDataURL('image/png'))
    }

    //CREATE-ASSOCIATIONE-FIRMA
    async function postSignAssociation(data){
        try {
            const response = await api().post(`${chat_sign_association_endpoint}`, data);
            if(response.data){
                await props.getOne(props.chatId);
                props.handleClose()
            }
        } catch (error) {
        console.log(error);
        }
    }


    //CREATE FIRMA FOR A VERBAL OR PREVENTIVO
    async function postSign(){
        try {
            const response = await api().post(`${chat_sign_endpoint}`, {});
            if(response.data?.id){
                postSignAssociation({chatID: props.firmaMsg, signID:response.data.id})
            }

        } catch (error) {
        console.log(error);
        }
    }

    //HANDLE SIGN
    function handleSign(e){
        trim()
        if(props.firmaMsg){
            postSign()
        } else {
            //crea firma intera
            props.handleSubmit(e, "firma intera", sigCanvas.getTrimmedCanvas().toDataURL('image/png'))
            props.handleClose()

        }

        console.log(signImg)
    }

    console.log(props.firmaMsg, "FIRMAmsg")

    const userName = (props.userData && props.userData.name && props.userData.surname) ? `${props.userData.name}  ${props.userData.surname}` : ""
    
    return (
        // <!-- START AGGIUNGI FIRMA POPUP -->
        <>
            <Modal show={props.show} onHide={props.handleClose} centered className="firma-modal">
                <Modal.Header closeButton>
                <Modal.Title>Aggiungi Firma</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border signature-canva">
                        <SignatureCanvas penColor='black'
                        ref={(ref) => {sigCanvas = ref }}
                        canvasProps={{ height: 400, className: 'sigCanvas'}} 
                        />
                    </div>
                    <p className="text-center my-3">oppure</p>
                    <div className="bl-single mb-2">
                        <label>Seleziona firma</label>
                        <select>
                            <option></option>
                        </select>
                    </div>
                    <div className="bl-single">
                        <label>Firmato da</label>
                        <input type="text" readOnly className="form-control" placeholder={``} disabled/>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <ButtonRm variant="tertiary" onClick={props.handleClose}>
                        Annulla
                    </ButtonRm>
                    <ButtonRm variant="primary" onClick={(e) => handleSign(e)}>
                        Salva
                    </ButtonRm>
                </Modal.Footer>


            </Modal>
           {/*  <div className="add-popup aggiungi-pop">
                <div className="lm-outer">
                    <div className="lm-inner">
                        <div className="rlsttpwb-tabs">
                            <div className="innerpop-descrp">
                                <div className="close-pop" onClick={() => props.setaggiungifirma(!props.aggiungifirma)}><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.5 7.5L7.5 22.5" stroke="#3A3033" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                    <path d="M7.5 7.5L22.5 22.5" stroke="#3A3033" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round" />
                                </svg></div>
                                <div className="pop-heading">
                                    <h4>Aggiungi Firma</h4>
                                </div>
    
                                <div className="border signature-canva">
                                <SignatureCanvas penColor='black'
                                canvasProps={{ height: 400, className: 'sigCanvas'}} 
                                />
                                </div>
                                <div className="bl-single">
                                    <label>Firmato da</label>
                                    <input type="text" readOnly className="form-control" placeholder="Mario Rossi" />
                                </div>
                                <div className="frigm-btn">
                                    <button className="btn teritary-btn" onClick={() => props.setaggiungifirma(!props.aggiungifirma)}>Annulla</button>
                                    <button className="btn primary-btn">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
            </div> */}
        </>
        // <!-- END AGGIUNGI FIRMA POPUP -->
    );
}