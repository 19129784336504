import { useState, useEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';

import { useMediaQuery } from 'react-responsive'

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap  } from 'react-leaflet'

import { Link, useNavigate } from "react-router-dom";

import  ButtonRm  from '../../ButtonRm'
import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import SearchInput from "../../SearchInput";
import EmptyStateContainer from "../../EmptyStateContainer";

import EmptyState from '../../../assets/images/unità-empty-state.svg'
import SearchIllustration from '../../../assets/images/search-illustration.svg'
import MapPin from '../../../assets/images/pin-map.png'

import '../../../assets/css/StatoLavoriInCorso.css'

import {UnitàCard, UnitàCardMap, UnitàCardMapMobile} from "../Unità/UnitàCard";
// import {data} from '../Unità/data'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { management_endpoint, unit_endpoint, real_estate_management_endpoint, stabili_endpoint, management_re_association_endpoint } from "../../../api/api";
import api from "../../../api/api.js";
import { useParams } from "react-router-dom";

import pc from '../../../assets/images/placeholder-unit-medium.png'

import ListViewUnit from "./ListViewUnit";
import MapViewUnit from "./MapViewUnit";

import { sortStringByKey, sortDatesByKey } from "../../../utils/filtering";

import { useDispatch, useSelector } from "react-redux";
import { addGestione, fetchGestioni, removeGestione, editGestione } from "../../../actions/gestioni";

import Select from 'react-select';
import { checkUserRole } from "../../../utils/checkUserRole.js";

import Breadcrumb from 'react-bootstrap/Breadcrumb';


const defaultZoom = 5;

function parseCoordinate(coord) {
    if (typeof coord === 'string') {
      return parseFloat(coord);
    }
    return coord;
  }


const data = []


export default function SingleGestione(){

    const [gestione, setGestione] = useState({})
    const units = gestione.realEstateManagements ? 
    gestione.realEstateManagements.map((item) => {
        return {
        ...item,
        coordinate: item.coordinate
        ? JSON.parse(item.coordinate.replace(/'/g, '"'))
        : ""
        }})
    : [] 

    const {gestioneId} = useParams()

    //lista delle unità da sezione unità e stabili per comporre la lista delle opzioni
    const [listaUnità, setListaUnità] = useState([])
    const [listaStabili, setListaStabili] = useState([])
    const [itemIdSelectedToAdd, setItemIdToAdd] = useState({
        id:"",
        type:""
    })
    const [itemSelectedData, setItemSelectedData] = useState({})

    //GO TO UNIT CREATION E STABILI CREATION
    const navigate = useNavigate()

    function goToUnitCreation(e){
        e.preventDefault()
        navigate("/real-estate-units/create")
      }
    
      function goToStabiliCreation(e){
        e.preventDefault()
        navigate("/stabili/create")
      }


    //BODY TO SEND FOR A POST REQUEST
    const bodyForCreate = {
        name: itemSelectedData?.name,
        // managementID:parseInt(gestioneId), forse anche condominiumID
        unitID: parseInt(itemSelectedData?.id),
        unitData: parseInt(itemSelectedData?.id),
        coordinate: [itemSelectedData.latitude, itemSelectedData.longitude],
        image:itemSelectedData?.image,
        address: `${itemSelectedData.address}, ${itemSelectedData.houseNumber}`,
        realEstateType: itemIdSelectedToAdd.type
    }

    function handleItemSelect(selectedOption) {
        if (selectedOption) {
          setItemIdToAdd(JSON.parse(selectedOption.value));
        } else {
          setItemIdToAdd('');
        }
      }


      

    const [searchTerm, setSearchTerm] = useState("")
    const [viewType, setViewType] = useState('list')

    //cose per mappa
    const [pinZoomed, setPinZoomed] = useState({
        coordinate: [41.9028, 12.4964],
        zoom: defaultZoom
    })


    function handleViewType(){
        if (viewType === 'list') {
            setViewType("map")
        } else {
            setViewType("list")
        }
    }

    function handleCheckbox(id){
        const newUnits = gestione?.realEstateManagements.map(unit => {
            return unit.id===id ?
                    {...unit, checked: !unit.checked}:
                    unit
        })
        setGestione(prev => ({...prev, realEstateManagements: newUnits}))
    }

    const selectedList = units.length > 0 ? 
                    units.filter(unit => unit.checked):
                        []
    const searchedList = units.length > 0 ? 
    units.filter(unit => unit.name?.toLowerCase().includes(searchTerm?.toLowerCase())):
    []


    //MANAGE OPEN-CLOSE NEW UNIT/CONDO MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
        setItemIdToAdd({
            id:"",
            type:""
        })
        setItemSelectedData({})
    };

    const handleShow = () => setShow(true);

    //zoom on a pin
    function zoomOnAPin(coordinate){

        console.log("coordinate", coordinate)
        if(coordinate && coordinate[0] != null && coordinate[1]!= null) {
            setPinZoomed(prev => {
                return {
                    ...prev,
                    coordinate,
                    zoom: 15
                }
            });
            //map.flyTo(coordinate, 18); // 18 è il livello di zoom
        }
    }


    //API CALL

    //get single item 
    async function getOne(id) {
        try {
            const response = await api().get(
                `${management_endpoint}${id}`
            );
            if(response.data) {
                setGestione(response.data)
            }
        }
            catch(error) {
                console.log(error)
        }
    }

    //ADD A NEW UNIT TO THE CURRENT MANAGEMENT

    //GET LIST OF UNITS/CONDOMINIUM AS OPTIONS => value:unit.id, name: unit.name

    async function getUnitsList() {
        try {
            const response = await api().get(`${unit_endpoint}`);
            if(response.data) {
                setListaUnità(response.data?.content)
            }
        }
        catch(error) {
                console.log(error)
        }
    }


    async function getStabiliList() {
        try {
            const response = await api().get(`${stabili_endpoint}`);
            if(response.data) {
                setListaStabili(response.data?.content)
            }
        }
        catch(error) {
                console.log(error)
        }
    }

    //get all data of the unit option selected
    async function getUnitData(id) {
        try {
            const response = await api().get(`${unit_endpoint}${id}`);
            if(response.data) {
                setItemSelectedData(response.data)
            }
        }
        catch(error) {
                console.log(error)
        }
    }

     //get all data of the stabili option selected
     async function getStabileData(id) {
        try {
            const response = await api().get(`${stabili_endpoint}${id}`);
            if(response.data) {
                setItemSelectedData(response.data)
            }
        }
        catch(error) {
                console.log(error)
        }
    }

/*     async function postItem(data) {
        try {
            await api().post(
                `${real_estate_management_endpoint}`,
                data
            );
            await getOne(gestioneId);
        } catch (error) {
            console.log(error);
        }
    } */

    //CREATE-ASSOCIAZIONE-GESTIONE-UNITA
    async function postItemAssociation(data){
        try {
            await api().post(`${management_re_association_endpoint}`, data);
            await getOne(gestioneId);
        } catch (error) {
        console.log(error);
        }
    }


    //LISTA UNITA GIA CREATE
    const [unitsAlreadyCreated, setUnitsAlreadyCreated] = useState([])
    //TROVA ARRAY DI ID DELLE UNITA GIA' CREATE

    const unitIds = unitsAlreadyCreated.length > 0 && unitsAlreadyCreated.filter(unit => unit.realEstateType == "unit").map(el => el.unitID)
    //TROVA ARRAY DI ID DEGLI STABILI GIA' CREATE
    const stabileIds = unitsAlreadyCreated.length > 0 && unitsAlreadyCreated.filter(unit => unit.realEstateType == "stabile").map(el => el.unitID)

    //FILTRA LA LISTA DI UNITA PRESE DA UNIT ENDPOINT E NON MOSTARRE QUELLE GIA' ASSOCIATE
    let filteredUnitList = unitIds.length > 0 ? listaUnità.filter(elemento => !unitIds.includes(elemento.id)) : listaUnità

    let filteredStabiliList = stabileIds.length > 0 ?listaStabili.filter(elemento => !stabileIds.includes(elemento.id)) : listaStabili

    //GET UNIT ALREADY ASSOCIATED IN SAL
    async function getUnits(){
        try {
            const response = await api().get(`${real_estate_management_endpoint}`);
            setUnitsAlreadyCreated(response.data.content)

        } catch (error) {
        console.log(error);
        }
    }

    console.log({
        unitIds,
        stabileIds
    })


    //CREATE UNIT
    async function postItem(data){
        try {
            const response = await api().post(`${real_estate_management_endpoint}`, data);
            if(response.data?.id){
                postItemAssociation({managementID: parseInt(gestioneId), unitID:response.data.id})
            }

        } catch (error) {
        console.log(error);
        }
    }


    //DELETE UNIT FROM THIS GESTIONE

    const onDelete = async (id) => {
        try {
        await api().delete(
            `${real_estate_management_endpoint}${id}`
            //                      {headers: {"Authorization" : `Bearer ${token}`}}
        );
        await getOne(gestioneId);
        } catch (error) {
        console.log(error);
        }
    };

    const [showInfo, setShowInfo] = useState(false)

    function handleDelete(e) {
        e.preventDefault();
        selectedList.forEach((item) => {
            if(item.activities.length > 0){
            console.log("Questa unità non può essere eliminate perché contiene delle attività associate")
            setShowInfo(true)

            } else {
            onDelete(item.id)
            
            }
        });
    }

    function handleSubmit(e){
        e.preventDefault()
        let str
        if(itemSelectedData){
            str = {
                ...bodyForCreate,
                coordinate: JSON.stringify(bodyForCreate.coordinate).replace(/"/g, "'")
            }
            postItem(str)
        }
        handleClose()
    }


    console.log(itemSelectedData)

    //when unit with id x is selected and saved, quindi on submit o on change, get that single unit object and send its name, photo, adress => /api/v1/real_estate_management/
    //dovrebbe così finire in GESTIONE> REAL ESTATE[]
    //di un unità mi serve photo, nome, indirizzo

    useEffect(() => {
        getOne(gestioneId)
        getUnitsList()
        getStabiliList()
        getUnits()
    }, []) 

    useEffect(() => {
        if(itemIdSelectedToAdd && itemIdSelectedToAdd.type == "unit"){
            getUnitData(itemIdSelectedToAdd.id)
        } else if(itemIdSelectedToAdd && itemIdSelectedToAdd.type == "stabile"){
            getStabileData(itemIdSelectedToAdd.id)
        }
    }, [itemIdSelectedToAdd]) 


    //NEW SEARCH & FILTER

	//SEARCH


	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

  


    const categories = [
		/* {
		name: 'Tipologia',
		items: [
			"Affitto",
			"Proposta"
		]
		},
		{
		name: 'Unità',
		items: units.map((unit) => unit.name),

		},
		{
			name: 'Gestione',
			items: gestioni.map(el => String(el.id)),
		} */
      
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (units?.length > 0) {
        setFilteredUnits(
			units?.filter((el) => {

            const elDate = new Date(el.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && elDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && elDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return el.units.some((unit) => {
						return cat.items.includes(unit.name);
						});
					}  
					})) &&
					el.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, gestione, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(units);
		setStartDate("")
		setEndDate("")

		}

    //SE COORDINATE NON ESISTONO DEFAULT SARA' [0,0]

    function isValidCoordinate(coord) {
        if (!Array.isArray(coord) || coord.length !== 2) {
          return false;
        }
      
        const [lat, lng] = coord.map(parseCoordinate);
      
        return typeof lat === "number" && !isNaN(lat) && 
               typeof lng === "number" && !isNaN(lng);
      }
    
    const coordinateArr = sortedItems.length > 0 ? sortedItems.map(item => {
        try {
          const coordinate = item.coordinate;
      
          if (isValidCoordinate(coordinate)) {
            return coordinate.map(parseCoordinate);
          }
      
        } catch (error) {
          // Gestione dell'errore (se necessario)
        }
      
        return [41.9028, 12.4964];
      }) : [[41.9028, 12.4964]];
      
      
    
    //CAMBIO GESTIONE
    //GET GESTIONI LIST FROM REDUX
    const gestioniList = useSelector((state) => state.gestioniReducer.gestioni);
    
    const [newManagementId, setNewManagementId] = useState("")
    const [showCG, setShowCG] = useState(false)

    function handleManagementId(e){
        setNewManagementId(e.target.value)
    }

    function handleCloseCG(){
        setShowCG(false)
    }

    function handleShowCG(){
        setShowCG(true)
    }

      //CREATE-DISOCIAZIONE-GESTIONE-UNITA
    async function postItemAssociation(data){
        try {
            await api().post(`${management_re_association_endpoint}`, data);
            await getOne(gestioneId);
        } catch (error) {
            console.log(error);
        }
    }

      //CREATE-ASSOCIAZIONE-NUOVA GESTIONE-UNITA
    async function postItemDissociation(data){
        try {
            await api()({
                method: 'delete',
                url:`${management_re_association_endpoint}disassociate`,
                data: data
            })
            await getOne(gestioneId);
        } catch (error) {
        console.log(error);
        }
    }

    function handleChangeCG(){
        //CONTROLLA SE IMMOBILE HA GIA' UN MANAGEMENT[0]
        const hasManagement = gestioneId ? gestioneId : null
        //SE LO HA, ESEGUIRE DISSOCIAZIONE TRA MANAGEMENT[0].ID E SELECTEDLIST[0]
        if(hasManagement) {
            postItemDissociation({managementID: gestioneId,
            unitID: selectedList[0].id})
        }

        //DOPODICHE ESEGUIRE ASSOCIAZIONE TRA SELECTEDLIST[0] E NEWMANAGEMENTID
        postItemAssociation({managementID: newManagementId,
            unitID: selectedList[0].id})

        handleCloseCG()
    }

    console.log({
        item: selectedList.length>0? selectedList[0] : null,
        currentG : gestioneId,
        newManagementId
    })


    //NUOVA UNITA' OPZIONI

    // Creazione dell'array di opzioni per la categoria "Unità"
    const unitOptions = filteredUnitList.map(unit => ({
    value: JSON.stringify({ id: unit.id, type: 'unit' }),
    label: `${unit.name} - Unità`
    }));

    // Creazione dell'array di opzioni per la categoria "Stabili"
    const stabiliOptions = filteredStabiliList.map(stabile => ({
        value: JSON.stringify({ id: stabile.id, type: 'stabile' }),
        label: `${stabile.name} - Stabile`
    }));


    function handleItemSelect(selectedOption) {
        if (selectedOption) {
        const { id, type } = JSON.parse(selectedOption.value);
        setItemIdToAdd({ id, type });
        } else {
        setItemIdToAdd({ id: "", type: "" });
        }
    }

    function getItemName(itemId) {
        const selectedItem = JSON.parse(itemId);
        if (selectedItem.type === "unit") {
        const unit = filteredUnitList.find(unit => unit.id === selectedItem.id);
        return unit ? unit.name : "";
        } else if (selectedItem.type === "stabile") {
        const stabile = filteredStabiliList.find(stabile => stabile.id === selectedItem.id);
        return stabile ? stabile.name : "";
        }
        return "";
    }

    return (
        <>
            {/* HEADER */}
            <div className="title-search-group mobile-container-xl ">
                <div className="right-header">
                    <TopHeading
                        SmallHeading=""
                        back_modal={false}
                    />
                </div>
                <Col md={12} className="my-3 px-0 mx-2">
                    <Breadcrumb>
                            <Breadcrumb.Item 
                                className="settings-archive"
                                onClick={() => {
                                    navigate('/statoavanzamentolavori/gestioni')
                                }}>
                                Gestioni
                            </Breadcrumb.Item>
                        
                            <Breadcrumb.Item active={true} 
                                className="settings-archive"
                            >
                                {gestione.name? gestione.name : ""}
                            </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
                    <Col className="d-flex flex-column flex-md-row justify-content-start align-items-start ">
                        <h2 className="fw-bold text-capitalize me-4">{gestione.name? gestione.name : ""}</h2>
                        <div className="d-flex justify-content-center align-items-center ">
                            <a className={`viewType ${viewType === 'list' ? "select" : ''}`}
                            onClick={handleViewType}>
                                <i className="bi bi-list-ul"></i>
                            </a>
                            <a className={`viewType ms-2 ${viewType === 'map' ? "select" : ''}`} 
                            onClick={handleViewType}>
                                <i className="bi bi-map"></i>
                            </a>
                        </div>
                    </Col>

                   
                    <Col className="d-flex justify-content-end ">
                        {(checkUserRole())  && <AddBtn
                            tooltip="Nuova unità"
                            className="me-3 me-md-0 order-md-1"
                            onClick={handleShow}
                        />}
                    </Col>
                </Row>
            </div>

            {/* LIST VIEW */}
            { viewType === 'list' &&
                <ListViewUnit 
                    units={units}
                    sortedItems={sortedItems}
                    selectedList={selectedList}
                    handleCheckbox={handleCheckbox}
                    handleDelete={handleDelete}
                    setSearchTerm={setSearchTerm}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    clearFiltersAndSort={clearFiltersAndSort}
                    setSortBy={setSortBy}
                    categories={categories}
                    setStartDate={setStartDate} setEndDate={setEndDate}
                    handleShowCG={handleShowCG}
                />
            }

            {/* MAP VIEW */}
            { viewType === 'map' &&
                <MapViewUnit 
                    units={units}
                    sortedItems={sortedItems}
                    selectedList={selectedList}
                    setSearchTerm={setSearchTerm}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    clearFiltersAndSort={clearFiltersAndSort}
                    setSortBy={setSortBy}
                    categories={categories}
                    zoomOnAPin={zoomOnAPin}
                    coordinateArr={coordinateArr}
                    pinZoomed={pinZoomed}
                    setStartDate={setStartDate} setEndDate={setEndDate}
                />
            }


            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={show} onHide={handleClose} className="p-5"  centered>
                    <Modal.Header closeButton style={{borderBottom:'none'}} className=" pt-md-5 px-md-5">
                    <Modal.Title>Nuova Unità o Stabile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-md-5">
                        <Form>
                        <Select
                            placeholder="Seleziona"
                            styles={{ control: provided => ({ ...provided, height: '50px' }) }}
                            onChange={handleItemSelect}
                            value={itemIdSelectedToAdd.id !== "" ? { value: JSON.stringify(itemIdSelectedToAdd), label: getItemName(JSON.stringify(itemIdSelectedToAdd)) } : null}
                            options={[
                                ...unitOptions,
                                ...stabiliOptions
                            ]}
                            />

                            <div className="d-flex decorative-div">
                                <div className="left-div"></div>
                                <p>Oppure</p>
                                <div className="right-div"></div>
                            </div>
                            <div className="d-flex  flex-column  flex-md-row  justify-content-center align-items-center">
                                <ButtonRm variant="tertiary" size="small"
                                className="me-md-3 mb-2 mb-md-0"
                                onClick={goToUnitCreation}
                                >
                                    Nuovo Immobile
                                </ButtonRm>
                                <ButtonRm variant="tertiary" size="small"
                                onClick={goToStabiliCreation}>
                                    Nuovo Stabile
                                </ButtonRm>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{borderTop: 'none'}}>
                        <ButtonRm variant='primary'disabled={!itemSelectedData} onClick={handleSubmit}>Salva</ButtonRm>
                        <ButtonRm variant='text' onClick={handleClose}>Annulla</ButtonRm>
                    </Modal.Footer>
            </Modal>

            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={showCG} onHide={handleCloseCG} className="p-5"  centered>
                    <Modal.Header closeButton style={{borderBottom:'none'}} className=" pt-md-5 px-md-5">
                    <Modal.Title>Cambia gestione</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="px-md-5">
                        <Form>
                            <Form.Label>Scegli la nuova gestione</Form.Label>
                            <Form.Select style={{height:'50px'}} onChange={handleManagementId} value={newManagementId}>
                                <option value=''>Seleziona</option>
                                { gestioniList.length > 0 &&
                                    gestioniList.filter(item => item.id != gestioneId).map(el => <option key={el.id} value={el.id}>{el.name}</option>)
                                }
                            </Form.Select>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{borderTop: 'none'}}>
                        <ButtonRm variant='primary' disabled={!newManagementId} onClick={handleChangeCG}>Salva</ButtonRm>
                        <ButtonRm variant='text' onClick={handleCloseCG}>Annulla</ButtonRm>
                    </Modal.Footer>
            </Modal>

             {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
             <Modal show={showInfo} onHide={() => setShowInfo(false)} className="p-5"  centered>
                    <Modal.Header style={{borderBottom:'none'}} className=" pt-md-5 px-md-5">
                    <Modal.Title>Rimozione non consentita</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="px-md-5">
                        <p>Non è possibile rimuovere un immobile al quale sono associate delle attività. Se desideri eliminare questo immobile, dovrai prima procedere all'eliminazione di tutte le attività in esso contenute.</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{borderTop: 'none'}}>
                        <ButtonRm variant='primary'  onClick={() => setShowInfo(false)}>Ho capito</ButtonRm>
                    </Modal.Footer>
            </Modal>
        </>
    )
}