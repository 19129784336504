import React, {useState, useEffect} from "react";
import { BsPrinter, BsDownload, BsPen, BsSend} from "react-icons/bs";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import { checkUserData } from "../../../../utils/checkUserRole";

export default function ActionsIcons(props) {

  //CHECK IF DATA REQUIRED ARE COMPILED
  const [dataRequired, setDataRequired] = useState({});
  //Gestisce il modale
  const [showCheck, setShowCheck] = useState(false)

 useEffect(() => {
     const fetchData = async () => {
         try {
             const response = await checkUserData();
             setDataRequired(response);
             if (!response.stripeUser ) {
               setShowCheck(true);
            }
         } catch (error) {
             // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
             console.error('Errore durante il recupero dei dati utente:', error);
         }
     };

     fetchData();
 }, []);

  return (
    <div className="d-flex mb-3 justify-content-end">
        <button
        type="button"
        className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
        onClick={() => props.setEditMode(true)}
      >
        <BsPen />
      </button>
{/*       {(dataRequired?.isVerifiedFull && dataRequired?.stripeUser) && 
 */}        <button
              type="button"
              className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
              onClick={props.modalSendShow}
            >
          <BsSend />
        </button>
      {/* } */}
      <ExportButton data={props.item} showPrint mapping={props.mapping} classes="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"/>
    </div>
  );
}
