import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoValutazione () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Valutazione"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="2" label="Funzione" />
            <TreeItem nodeId="3" label="Struttura della pagina" />
            <TreeItem nodeId="4" label="Creazione di una Valutazione" >
                <TreeItem nodeId="5" label="Struttura" />
                <TreeItem nodeId="6" label="Tipologia" />
                <TreeItem nodeId="7" label="Caratteristiche" />
                <TreeItem nodeId="8" label="Dotazioni" />
                <TreeItem nodeId="9" label="Dati" />
                <TreeItem nodeId="10" label="Allegati" />
                <TreeItem nodeId="11" label="Correlati" />
            </TreeItem>
            <TreeItem nodeId="12" label="Visualizzazione Report" />

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Valutazione Immobiliare</h1>
	  <h2 style={{ color:'transparent', height: 0}}>Valutazione</h2>

      <h2 id="2">FUNZIONE</h2>

<p>La sezione valutazione immobiliare ti
consente di ottenere una stima del valore delle tue unità.</p>
<img loading="lazy" src="https://i.postimg.cc/6p2tyxXW/panoramica.png"  />


<p>Compilando i dati otterrai un report
personalizzato contenente:</p>
<ul>
	<li><p >Riepilogo dei dati tecnici delle
	tue unità</p></li>
	<li><p >Informazioni sull’ubicazione</p></li>
	<li><p >Servizi disponibili vicino ai tuoi
	immobili</p></li>
	<li><p >Indicatori socio-economici</p></li>
	<li><p >Dinamicità del mercato</p></li>
	<li><p >Ripartizione compravendite</p></li>
	<li><p >Caratteristiche e descrizione
	dell’immobile</p></li>
	<li><p >Analisi degli immobili simili</p></li>
	<li><p >Dati omi</p></li>
	<li><p >Stima</p></li>
	<li><p >Foto e allegati</p></li>
</ul>

<h3 id="3">STRUTTURA DELLA PAGINA</h3>

<p >In alto a destra trovi il pulsante
rosso “+”. Cliccando su questo pulsante aprirai il modulo di
creazione di una nuova valutazione immobiliare.</p>
<img loading="lazy" src="https://i.postimg.cc/G2Xb4kXY/add.png"  />


<p >Poco sotto, In alto a destro  troverai
la barra di ricerca, per semplificare la ricerca di una valutazione
specifico tramite identificativo.</p>
<p >In alternativa trovi a destra il
bottone filtra (icona dell’imbuto) che ti permette di filtrare gli
affitti per il seguente criterio</p>
<ul>
	<li><p >Categoria di immobile</p></li>
</ul>

<p >A fianco al pulsante filtra, trovi il
pulsante ordina (icona con tre righe orizzontali) che ti permette di</p>
<ul>
	<li><p >Ordinare gli elementi in modo
	alfabetico dalla A alla Z o viceversa</p></li>
	<li><p >Ordinare gli elementi mettendo
	prima quelli creati più di recente o in alternativa mettendo per
	primi quelli creati meno recentemente</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/NFdBrgFM/filtri.png"  />


<h2 id="4">CREAZIONE DI UNA VALUTAZIONE</h2>
<h3 id="5">Struttura</h3>

<p >Dalla pagina iniziale della sezione
Affitti, cliccando il pulsante rosso “+” in alto a destra si
avvia il processo di creazione di un affitto.</p>

<p >Il processo di creazione si articola in
6  passaggi, che puoi visualizzare nella barra in alto.</p>
<ul>
	<li><p >Tipologia</p></li>
	<li><p >Caratteristiche</p></li>
	<li><p >Dotazioni</p></li>
	<li><p >Dati</p></li>
	<li><p >Allegati</p></li>
	<li><p >Correlati</p></li>
</ul>

<img loading="lazy" src="https://i.postimg.cc/dVpQBtBZ/stepper.png"  />

<p >Per andare a un passaggio successivo
premere il pulsante “AVANTI” o “INDIETRO” qualora voleste
tornare a un passaggio precedente.</p>

<img loading="lazy" src="https://i.postimg.cc/7hvJLWqG/indietro-avanti.png" />

<p >Per uscire dalla profilazione clicca
sulla freccia che trovi a sinistra nella barra in alto.</p>
<img loading="lazy" src="https://i.postimg.cc/nLRpVV6S/indietro.png"/>

<p >I campi obbligatori della profilazione
verranno indicati col simbolo rosso asterisco (<font color="#ff0000">*</font>)</p>

<img loading="lazy" src="https://i.postimg.cc/RVC0CYhh/asterisco.png"  />


<p >Troverai dei campi indicati dall’icona
di una fiamma blu. Questa icona indica i parametri che andranno ad
influenzare il valore di stima finale.</p>
<img loading="lazy" src="https://i.postimg.cc/XYp7dyw0/fiamma.png"  />

<p className="subtitle">PERSONALIZZAZIONE COEFFICIENTI DI MERITO</p>
<img loading="lazy" src="https://i.postimg.cc/LsQ3Ts6P/custom.gif"  />

<Tips variant="suggestion" content="Puoi personalizzare il valore di ponderazione che un dato ha sull'algoritmo di valutazione." />




<h3 id="6">TIPOLOGIA</h3>

<p >Puoi creare la valutazione di un’unità
che hai già creato, selezionandola nel campo “Unità Immobiliare”,
oppure puoi ottenere il report di una valutazione di un nuovo
immobile compilando i dati richiesti.</p>

<Tips variant="automatization" content="Selezionando un’unità esistente, se
hai già compilato i dati relativi in UNITA’, troverai già
inseriti i dati relativi all'immobile: indirizzo, caratteristiche, dati catastali" />

<img loading="lazy" src="https://i.postimg.cc/T3zGbNdr/tendina.gif"  />


{/* <p >COEFFICIENTI DI MERITO</p>
<p >Puoi creare i tuoi coefficienti di
merito in in IMPOSTAZIONI &gt; Valutazione o usare quelli che abbiamo
impostato come predefiniti.</p>
<p ><br/>

</p> */}

<h3 id="7" >CARATTERISTICHE</h3>
<p >A seconda della tipologia scelta
(ville, uffici, negozi…) troverai un modulo diverso per tipologia
di immobile.</p>

<h3 id="8">DOTAZIONI</h3>
<p >In questa scheda potrai definire
ulteriori descrizioni ed elementi relativi all’immobile.</p>
<p >Potrai inoltre aggiungere delle
immagini relativi all’immobile che appariranno nel report finale.</p>

<h3 id="9">DATI</h3>
<p >Qui potrai inserire i dati catastali
(verranno autocompilati se già presenti nell’unità associata),
dati di chi effettua la valutazione e dati di chi richiede la
valutazione.</p>
<p >Inoltre hai la possibilità di caricare
il logo
della tua azienda per personalizzare il report.</p>

<p className="subtitle" >OUTPUT MANUALE</p>
<p >Se desideri utilizzare il nostro
modello di report  con la stima da te definita, quindi senza usare la
nostra stima automatizzata, puoi sovrascrivere i nostri dati
compilando questi campi e definendo:</p>
<ul>
	<li><p >Prezzo minimo di affitto al mq da
	te stimato</p></li>
	<li><p >Prezzo massimo di affitto stimato
	al mq da te stimato</p></li>
	<li><p >Prezzo minimo di vendita al mq da
	te stimato</p></li>
	<li><p >Prezzo massimo di vendita al mq da
	te stimato</p></li>
</ul>

<p >Nelle ultime pagine del report che genereremo potrai
così trovare una pagina coi valori da te impostati.</p>
<img loading="lazy" src="https://i.postimg.cc/FHpRdWhW/manuale.png"  />


<h3 id="10">ALLEGATI</h3>
<p >Qui potrai caricare foto, planimetrie e
documentazione relative all’immobile, che verranno inseriti in coda
al report che verrà creato.</p>

<h3 id="11">CORRELATI</h3>
<p >In questa scheda potrai selezionare gli
immobili che verranno inseriti nella pagina di “ANALISI DEGLI
IMMOBILI SIMILI”</p>
<p >Dovrai inserire:</p>
<ul>
	<li><p >Città</p></li>
	<li><p >Indirizzo</p></li>
    <li><p >Zona/Quartiere (ad esempio per il comune di Milano le zone sono: Navigli, Corvetto etc )</p></li>
	<li><p >Mq</p></li>
	<li><p >Tipologia</p></li>
</ul>

<img loading="lazy" src="https://i.postimg.cc/j23YSZ3n/correlati.gif"  />

<h2 id="12">VISUALIZZAZIONE DEGLI ELEMENTI
CREATI</h2>
<p ><u>Struttura</u></p>
<p >Ogni elemento è strutturato in modo
tale da contenere l’identificativo della valutazione e i dati
relativi all’indirizzo.</p>
<img loading="lazy" src="https://i.postimg.cc/G3x2CRc6/card.png"  />

<p >A destra potrai visionare un riepilogo
della stima relativa a qull’immobile e il tasto per eliminare il
report creato.</p>
<p >Cliccando l’elemento di tuo interesse
si aprirà il report relativo.</p>
<img loading="lazy" src="https://i.postimg.cc/7PKk9ZPP/report.gif"  />



    </div>
  );
}

