import React from "react";
import CloseBtn from "../../../assets/images/close.svg";

export default function FileLoadCard(props) {
  return (
    <div className="file-load-card mt-3">
      <p className="fs-18">{props.name}</p>
      <img loading="lazy" src={CloseBtn} alt="icon" onClick={props.deleteItem} style={{cursor: "pointer"}}/>
    </div>
  );
}
