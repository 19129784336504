import { useState, useEffect } from "react";
import EmptyImg from "../../../assets/images/assicurazione-ph.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import InsuranceModal from "./InsuranceModal";
import ButtonRm from "../../ButtonRm";

import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMappingAssicurazioni from "../mappingAssicurazioni";

const InsuranceE = ({ insurances, update, formData, getStabiliById }) => {
  const [data, setData] = useState([]);
  const init = () => {
    setData(insurances);
  };
  useEffect(() => {
    init();
  }, [insurances]);

  const [editMode, setEditMode] = useState(false);
  const handleSave = () => {
    setEditMode(false);
    let temp = { insurances: data };
    update(temp);
  };

  const cancelEdit = () => {
    setEditMode(false);
    // setBackModalShow(true);
  };
  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const add = (insurance) => {
    let temp = [...data];
    temp.push(insurance);
    setData(temp);
    setShow(false);
  };

  const [insuranceEditNum, setInsuranceEditNum] = useState(-1);

  const updateInsurance = (insurance) => {
    let temp = [...data];
    temp[insuranceEditNum] = insurance;
    setData(temp);
    setEditModal(false);
    setInsuranceEditNum(-1);
  };

  const deleteInsurance = () => {
    let temp = [...insurances];
    temp.splice(insuranceEditNum, 1);
    setData(temp);
    setEditModal(false);
    setInsuranceEditNum(-1);
  };

  const handleInsuranceEdit = (id) => {
      setInsuranceEditNum(id);
      setEditModal(true);
    
  };
  return (
    <div className="form-item-align-box-white d-block">
      <div className="save-change-btn-group-stabili">
        <div
          className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}
        >
          <div
            className="edit-btn w-144 me-2"
            onClick={() => {
              setEditMode(true);
            }}
          >
            Modifica
          </div>
        </div>
        <div
          className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}
        >
          <div className="edit-btn w-173 me-2" onClick={handleSave}>
            Salva modifiche
          </div>
          <button
            type="button"
            onClick={cancelEdit}
            className="border border-secondary bg-white rounded-3 edit-icon-btn"
          >
            <i
              className="bi bi-x"
              style={{ color: "var(--edit-color)" }}
            ></i>
          </button>
        </div>
      </div>
      <div className="edit-content-container mt-3 py-5">
        <div className={editMode ? "" : "d-none"}>
          <ButtonRm
            onClick={() => {
              setShow(true);
            }}
          >
            Nuovo
          </ButtonRm>
        </div>
        {(!editMode && data?.length > 0) && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}
					<ExportButton showPrint data={data} mapping={translationMappingAssicurazioni} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
        {data?.length > 0 ? (
          <div className="mt-4">
            <Table className="result-table table-border out-border-none">
              <thead>
                <tr>
                  <th>Tipo di polizza</th>
                  <th>Compagnia</th>
                  <th>Inizio originale</th>
                  <th>Scadenza Polizza</th>
                  <th>Premio</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        handleInsuranceEdit(index);
                      }}
                    >
                      <td>{item.billType}</td>
                      <td>{item.company}</td>
                      <td>
                        {new Date(item.originalStart).toLocaleDateString()}
                      </td>
                      <td>
                        {new Date(item.deadlineBill).toLocaleDateString()}
                      </td>
                      <td>{item.reward}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="unit-empty-img">
            <img loading="lazy" src={EmptyImg} alt="No image" />
            <span className="img-comment fs-18">
              Non hai aggiunto ancora alcuna assicurazione
            </span>
          </div>
        )}
        <Modal
          show={show}
          className="right-full-pop"
          backdrop
          onExit={() => setShow(false)}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="p-2">
            <InsuranceModal
              setShow={setShow}
              newMode={true}
              add={add}
              editMode={true}
              editView={true}
              condo={true}
              getStabiliById={getStabiliById}
              formData={formData}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={editModal}
          className="right-full-pop"
          backdrop
          onExit={() => setEditModal(false)}
          onHide={() => setEditModal(false)}

        >
                    <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="p-2">
            <InsuranceModal
              setShow={setEditModal}
              newMode={false}
              update={updateInsurance}
              delete={deleteInsurance}
              insurance={data && data[insuranceEditNum]}
              editMode={true}
              editView={true}
              getStabiliById={getStabiliById}

            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default InsuranceE;
