import React, { useState, useContext } from "react";
import { CondutText } from "../../../../pages/Conduttori";
import { PropriText } from "../../../../pages/Proprietari";
import { FornitoriText } from "../../../../pages/Fornitori";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Membro from "./Membro";
import Unita from "./Unita";
import AmountsAndData from "./AmountsAndData";

export default function TabForm(props) {
  const [key, setKey] = useState("anagrafica");

  const topHeading1 = useContext(CondutText);
  const topHeading2 = useContext(PropriText);
  const topHeading3 = useContext(FornitoriText);

  const [count, setCount] = useState(1);
  const navigate = useNavigate();

  const forward = () => {
    var num = count;
    if (num <= 3) {
      setCount(++num);
    }
  };

  const backward = () => {
    var num = count;
    if (num > 1) {
      setCount(--num);
    }
  };

  const returnLogin = () => {
    navigate(-1);
  };

  const tab1Click = () => {
    setCount(1);
  };
  const tab2Click = () => {
    setCount(2);
  };
  const tab3Click = () => {
    setCount(3);
  };

  const returnContent = (count) => {
    switch (count) {
      case 1:
        return <Unita next={() => forward()} back={(v) => props.setShow(v)} />;
      case 2:
        return <Membro next={() => forward()} back={() => backward()} />;
      case 3:
        return <AmountsAndData next={(v) => props.setShow(v)} back={() => backward()} />;
      default:
        return <Unita next={() => forward()} back={(v) => props.setShow(v)} />;
    }
  };

  return (
    <div className="main-from-outer d-block">
      <h2 className="top-form-heading d-block mb-4 fs-32 text-dark fw-semibold">
        {topHeading1}
        {topHeading2}
        {topHeading3}
      </h2>
      <div className="tabs-div justify-content-start" style={{ marginBottom: "20px" }}>
        <div className="tab-icon" onClick={() => tab1Click()}>
          <div className="desktop-icon">
            <svg style={{ width: "131px", height: "58px" }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 5C2 2.79086 3.79086 1 6 1H112.679C114.114 1 115.44 1.76914 116.152 3.01544L129.866 27.0154C130.569 28.2452 130.569 29.7548 129.866 30.9846L116.152 54.9846C115.44 56.2309 114.114 57 112.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
                fill={count > 0 ? (count > 1 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={count > 0 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className={"tab-icon-title1"}
              style={{
                color:
                  count > 0 ? (count > 1 ? "#FFF8F8" : "#83112F") : "#3C4257",
                marginLeft: "40px",
              }}
            >
              Unità
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: count === 1 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={count > 0 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: count === 1 ? "" : "none",
                position: "absolute",
                left: "-15px",
              }}
            >
              Unità
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab2Click()}>
          <div className="desktop-icon">
            <svg
              className="tab-icon-background"
              style={{ width: "126px", height: "58px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M111.152 3.01544C110.439 1.76914 109.114 1 107.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H107.679C109.114 57 110.439 56.2309 111.152 54.9846L124.866 30.9846C125.569 29.7548 125.569 28.2452 124.866 27.0154L111.152 3.01544Z"
                fill={count > 1 ? (count > 2 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={count > 1 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className={"tab-icon-title2"}
              style={{
                color:
                  count > 1 ? (count > 2 ? "#FFF8F8" : "#83112F") : "#3C4257",
                marginLeft: "33px",
              }}
            >
              Membro
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: count === 2 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={count > 1 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: count === 2 ? "" : "none",
                position: "absolute",
                left: "-16px",
              }}
            >
              Membro
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab3Click()}>
          <div className="desktop-icon">
            <svg style={{ width: "162px", height: "58px" }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H156C158.209 1 160 2.79086 160 5V53C160 55.2091 158.209 57 156 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
                fill={count > 2 ? (count > 3 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={count > 2 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className="tab-icon-title6"
              style={{
                color:
                  count > 2 ? (count > 3 ? "#FFF8F8" : "#83112F") : "#3C4257",
                marginLeft: "35px",
              }}
            >
              Importi e date
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: count === 3 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={count > 2 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: count === 3 ? "" : "none",
                position: "absolute",
                left: "-3px",
              }}
            >
              Importi e date
            </div>
          </div>
        </div>
      </div>
      {returnContent(count)}
    </div>
  );
}
