import React from "react";
import CustomBadge from "../../CustomBadge";

const Description = (props) => {
  return (
    <div className="form-item-align-box-white d-block">
      <h2 className="fs-16 text-dark mb-2 fw-bold">DESCRIZIONI</h2>
      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
        Inserire le descrizioni relative per i diversi campi dell’unità
        immobiliare. Il riempimento dei campi è opzionale
      </label>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Unità
        </label>
        <textarea
          name="unit"
          value={props.formData.unit}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Arredo
        </label>
        <textarea
          name="furniture"
          value={props.formData.furniture}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Dotazione
        </label>
        <textarea
          name="equipment"
          value={props.formData.equipment}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          A.P.E.
        </label>
        <textarea
          name="energyCertification"
          value={props.formData.energyCertification}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Altre certificazioni
        </label>
        <textarea
          name="otherCertification"
          value={props.formData.otherCertification}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Spese incluse
        </label>
        <textarea
          name="includedExpenses"
          value={props.formData.includedExpenses}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Spese escluse
        </label>
        <textarea
          name="excludedExpenses"
          value={props.formData.excludedExpenses}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Addendum al contratto
        </label>
        <textarea
          name="contractAddendum"
          value={props.formData.contractAddendum}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Consegna delle chiavi
        </label>
        <textarea
          name="keyDelivery"
          value={props.formData.keyDelivery}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Note
        </label>
        <textarea
          name="notes"
          value={props.formData.notes}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
      <div className="d-flex flex-column">
        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
          Note IMU
        </label>
        <textarea
          name="imuNote"
          value={props.formData.imuNote}
          onChange={(e) => {
            props.onChange(e);
          }}
          id=""
          cols="30"
          rows="4"
          className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
        ></textarea>
      </div>
    </div>
  );
};

export default Description;
