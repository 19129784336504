import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setShow } from "../../../actions/ModalForVerify";
import "../../../assets/css/ModalForVerify.css";
import InputError from "../../../assets/images/input_error.svg";
import loadingIcon from "../../../assets/images/loading.gif";
import logo2 from "../../../assets/images/logo-red.svg";

import Lottie from "lottie-react";
import logo from "../../../assets/images/logoLottie.json";
import api, { public_page, BASE_URL } from "../../../api/api";

import { checkUserRole } from "../../../utils/checkUserRole";

//AFTER CLICKING ON "ACCEDI" BUTTON
const ModalForVerify = ({ show, loginInfo, setShowLoader, setReady }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alowSend, setAlowSend] = useState(false);
  const [email, setEmail] = useState(loginInfo.email);

  // const [formData, setFormData] = useState({
  //   otp: "",
  // });

  const handleClose = () => {
    dispatch(setShow(false));
    setError(false);
  };

  const [verifyCode, setVerifyCode] = useState("");

   //CREA PAGINA PUBBLICA FORNITORE
    async function createPublicPage() {
    // Recuperare l'oggetto user dal localStorage
    const user = JSON.parse(localStorage.getItem('user'));
  
    if (!user || !user.id) {
      console.log('Utente non trovato nel localStorage');
      return;
    }
  
    try {


       // Effettuare una richiesta GET a public_page per ottenere l'array di pagine
    const response1 = await api().get(`${public_page}`);

    // Verificare se la risposta contiene un array valido
    if (!Array.isArray(response1.data.content)) {
      console.log('Errore: la risposta non contiene un array di pagine');
      return;
    }

    const pages = response1.data.content;
  
      // Verificare se esiste già una pagina con userID corrispondente all'id dell'utente corrente
      const existingPage = pages?.find(page => page.userID === user.id);
  
      if (existingPage) {
        console.log('La pagina per questo utente esiste già');
        return;
      }
  
      // Se non esiste una pagina corrispondente, effettuare una richiesta POST per crearne una nuova
      let response = await api().post(`${public_page}`, {
        title: "",
        typologies: [],
        image: "",
        address: "",
        pay: "",
        availableNow: true,
        cityWorkplace: "",
        provinceWorkplace: "",
        //userID: user.id 
      });
  
      if (response.data.success) {
        console.log('Pagina pubblica creata con successo');
      } else {
        console.log('Errore nella creazione della pagina pubblica');
      }
    } catch (err) {
      console.log(err);
    }
  }
  

  const onSubmit = async (e) => {
    //disabilita il pulsante dopo submit

    setAlowSend(false)
    loginInfo.otp = verifyCode;
    setLoading(true);
    // formData.append('otp': verifyCode);
    axios
      .post(
       `${BASE_URL}api/v1/auth/verifyLogin`,
        loginInfo
      )
      .then((res) => {
        setLoading(false);

        if (res.data.success !== false) {
       
          
          localStorage.setItem("user", JSON.stringify(res.data));
          //Se login corretto, chiudi modale otp e mostra loader dopo 1s
          setTimeout(() => {
            setReady(false)
            setShowLoader(true)
            handleClose() // chiude modale
            localStorage.removeItem("invitation_code"); //rimuove invitation_code da local storage
            console.log(checkUserRole(), 'checkuserrole before redirect')

          }, 1500);


          //Se login corretto, naviga alla dashboard dopo 4 secondi
          setTimeout(() => {
            console.log(checkUserRole(), 'checkuserrole before redirect')
            navigate((checkUserRole() ) ? "/dashboard" : "/statoavanzamentolavori/bacheca");

            //crea pagina fonritori se fornitore
            if(res.data.role  == "SUPPLIER"){
            createPublicPage()
            }
          }, 4000);
          
        } else {
          setAlowSend(true)

          toast.error("L'otp inserito non è corretto", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
        // navigate("/dashboard");
      })
      .catch((err) => {
        //riabilita il pulsante se otp non corretto

        setAlowSend(true)

        toast.error("L'otp inserito non è corretto", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
    if (verifyCode.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
    e.preventDefault();
  };

  const verifyCodeChange = (e) => {
    setVerifyCode(e.target.value);
    if (e.target.value.length === 0) {
      setAlowSend(false);
    } else {
      setAlowSend(true);
    }
  };

  //REQUEST ANOTHER OTP
  const btnRequestAgain = (e) => {
    console.log("PREMUTO BOTTONE INVIA NUOVAMENTE OTP")

    axios
      .post(
        `${BASE_URL}api/v1/auth/login`,
        {
          email: loginInfo.email,
          password: loginInfo.password
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Ti abbiamo inviato un nuovo otp, controlla la casella email.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        } 
      })
      .catch((err) => {
        toast.error("error!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
    if (verifyCode.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
    //e.preventDefault();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="verify-Modal"
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} />
      <Modal.Body>
        <ToastContainer />
        <img
          src={logo2}
          alt="logo"
          style={{
            paddingBottom: "30px",
            margin: "auto",
            display: "flex",
          }}
        />
        <div className="titleOTP-div">
          <div style={{ paddingBottom: "22px" }}>Inserisci OTP</div>
          <div className="verify-description-div">
            Ti abbiamo inviato l’OTP di 6 cifre all’email {email} Inseriscilo
            nel campo sottostante.
          </div>
          <form className="form" onSubmit={onSubmit}>
            <input
              type="text"
              placeholder="Inserisci OTP"
              name="text"
              value={verifyCode}
              onChange={verifyCodeChange}
              id={error ? "otp_input_error" : ""}
              className="form-control"
              style={{
                fontFamily: "Mulish",
                height: "50px",
                border: "1px solid #BDBDBD",
              }}
            />
            {verifyCode.length > 0 && error && (
              <div className="opt_error_msg">
                <img loading="lazy" src={InputError} alt="input_error"></img>
                <div style={{ paddingLeft: "10px" }}>
                  Il codice inserito non è corretto
                </div>
              </div>
            )}
            <button
              type="submit"
              id={alowSend ? "active-submit" : ""}
              className="confirm-btn-div"
              style={{ marginTop: "10px" }}
              disabled={alowSend ? false : true}

            >
              Continua
            </button>
          </form>
          <div className="resend-div" onClick={() => btnRequestAgain()}>
            Invia nuovamente l’OTP
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: state.ModalForVerify.show,
});

export default connect(mapStateToProps, { setShow })(ModalForVerify);
