import React, { useEffect, useState } from "react";
import { AnteprimaMode } from "./AnteprimaMode";
import one from "../../../assets/images/images-chat/doc-galery/1.png";
import two from "../../../assets/images/images-chat/doc-galery/2.png";
import EmptyState from "../../../assets/images/empty-folder.png";
import { Modal } from "react-bootstrap";
import {Row, Col} from "react-bootstrap"
import { CardChecklist } from "react-bootstrap-icons";
import FilterSearchBar from "../Gestioni/FilterSearchBar";

import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";

export const Contenutipopup = (props) => {

    const msgFromParent = props.chats? props.chats : []

    const [messages, setMessages] = useState([])

    const [tabState, setTabState] = useState(0);

    useEffect(() => {
        if(msgFromParent?.length > 0){
            setMessages(msgFromParent)
        }
    }, [tabState, msgFromParent])

    const photos = []

    const contabilità = messages.filter(el => el.messageType == "preventivo" || el.messageType == "spesa")

    const checklist =  messages.filter(el => el.messageType == "verbale" )

    const payments =  messages.filter(el => el.messageType == "paymentMsg" )



/* FILTRA PER CREATORE */
/* 
    const creatorList = messages.map(item => item.userID)
    const creatorListWithoutDuplicates = [...new Set(creatorList)]

    const [filterCreatorId, setFilterCreatorId] = useState("")

    function filterByCreator(id){
        const filterArr = messages.filter(item => item.userID == id)
        setMessages(filterArr)
    }

    useEffect(()=> {
        if(filterCreatorId){
            filterByCreator(filterCreatorId)
        } else if (filterCreatorId == null){
            setMessages(msgFromParent)
        }
    }, [filterCreatorId]) */

     //FILTRO E ORDINA

    const [arrToFilter, setArrToFilter] = useState("")

    useEffect(() => {
        if(tabState == 0){
            setArrToFilter(photos)
        } else if (tabState == 1){
            setArrToFilter(checklist)
        } else if (tabState == 2){
            setArrToFilter(contabilità)
        } else if (tabState == 4){
            setArrToFilter(payments)
        }
    }, [tabState])

    const categories = [
            
    ];

    const [searchTerm, setSearchTerm] = useState("")
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [sortBy, setSortBy] = useState("più-recenti");
    //ARRAY CON ELEMENTI FILTRATI PER FILTRI E CERCA 
    const [filteredItems, setFilteredItems] = useState([])
    //ARRAY PRENDE GLI ELEMENTI FILTRATI E LI MOSTRA BY SORT
    const [sortedItems, setSortedItems] = useState(filteredItems);


    useEffect(() => {
    if(arrToFilter.length > 0)  {
        setFilteredItems(
            arrToFilter.filter((item) => {
        return (
        (selectedCategories.length === 0 || selectedCategories.includes(item.category))
        );
        })
    )}
    }, [searchTerm, selectedCategories, arrToFilter, tabState]);

    useEffect(() => {
    switch (sortBy?.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredItems, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredItems, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredItems, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredItems, "cdat", false));
    }
    }, [filteredItems, sortBy]);

    //PULISCI FILTRI
    function clearFiltersAndSort() {
        setSearchTerm("");
        setSelectedCategories([]);
        setSortBy("più-recenti");
        setFilteredItems();
    }

    console.log(sortedItems, arrToFilter)

    //FILTRI SARANNO PER SPESA O PER CREATORE

    const scrollToMessage = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        props.handleCloseCa()
    };



    return (
        <>
            <Modal show={props.showCa} onHide={props.handleCloseCa} className="p-5 rcModal" centered >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Contenuto Attività</Modal.Title>
                </Modal.Header>
                <Modal.Body className="rc-body ca-body p-md-5">
                <div className="edit-content-container-slc">
				<div>
					<div className={`tab-state-slc`}>
						<div className="d-flex justify-content-center">
							<div
								className="tab-container-slc"
								onClick={(e) => {
									setTabState(0);
								}}>
								<label className={tabState === 0 ? "" : "non-active"}>
									Foto e video
								</label>
								<div
									className={`tab-bar-ca ${
										tabState === 0 ? "active-tab-bar" : ""
									}`}></div>
							</div>
							<div
								className="tab-container-slc"
								onClick={(e) => {
									setTabState(1);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 1 ? "" : "non-active"}>
									Checklist
								</label>
								<div
									className={`tab-bar-ca ${
										tabState === 1 ? "active-tab-bar" : ""
									}`}></div>
							</div>
                            <div
								className="tab-container-slc"
								onClick={(e) => {
									setTabState(2);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 2 ? "" : "non-active"}>
									Contabilità
								</label>
								<div
									className={`tab-bar-ca ${
										tabState === 2 ? "active-tab-bar" : ""
									}`}></div>
							</div>
                            <div
								className="tab-container-slc"
								onClick={(e) => {
									setTabState(3);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 3 ? "" : "non-active"}>
									Allegati
								</label>
								<div
									className={`tab-bar-ca ${
										tabState ===  3 ? "active-tab-bar" : ""
									}`}></div>
							</div>
                            <div
								className="tab-container-slc"
								onClick={(e) => {
									setTabState(4);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 4 ? "" : "non-active"}>
									Pagamenti
								</label>
								<div
									className={`tab-bar-ca ${
										tabState ===  4 ? "active-tab-bar" : ""
									}`}></div>
							</div>
						</div>
					</div>

                    {/* FOTO E VIDEO CONTENT */}
					<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
                        <Row className="flex-wrap ca-img-row">
                            { photos.length > 0 &&
                                photos.map((item, index) => {
                                    return <Col key={index} md={4} className="mb-3 align-items-center justify-content-center">
                                        <img loading="lazy" className="ca-img " src={item} width="100%"/>
                                    </Col>
                                })
                            }

                        </Row>
					</div>
                    {/* CHECKLIST CONTENT */}
					<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>

                        {arrToFilter.length > 0 ? <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                            <FilterSearchBar 
                                setSearchTerm={setSearchTerm}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                clearFiltersAndSort={clearFiltersAndSort}
                                setSortBy={setSortBy}
                                categories={categories}
                                noSearch={true}
                            />
                        </Col> :
                        <div className="mx-auto text-center mt-5 ">
                            <div className="mx-auto d-table w-90">
                            <img loading="lazy" src={EmptyState} alt="empty" />
                            </div>
                            <p className="fs-20 text-secondary text-center mt-3">
                                Nessun elemento
                            </p>
                        </div>
                        }
                        {
                                sortedItems?.length > 0 &&
                                sortedItems.filter(el => el.messageType == "verbale" ).map(item => {
                                    return <CardCa key={item.id} id={item.id} scrollToMessage={scrollToMessage}>
                                            <CardCaChecklist item={item} />
                                        </CardCa>
                                })
                            }
                        
					</div>
                    {/* CONTABILITA CONTENT */}
                    <div className={`mt-3 ${tabState === 2 ? "" : "d-none"}`}>
                    {arrToFilter.length > 0 ? <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                            <FilterSearchBar 
                                setSearchTerm={setSearchTerm}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                clearFiltersAndSort={clearFiltersAndSort}
                                setSortBy={setSortBy}
                                categories={categories}
                                noSearch={true}
                                alpha={false}

                            />
                        </Col> :
                        <div className="mx-auto text-center mt-5 ">
                            <div className="mx-auto d-table w-90">
                            <img loading="lazy" src={EmptyState} alt="empty" />
                            </div>
                            <p className="fs-20 text-secondary text-center mt-3">
                                Nessun elemento
                            </p>
                        </div>
                        }

                        {
                            sortedItems?.length > 0 &&
                            sortedItems.filter(el => el.messageType == "preventivo" || el.messageType == "spesa").map(item => {
                                return <CardCa key={item.id} id={item.id} scrollToMessage={scrollToMessage}>
                                        <CardCaCont item={item} />
                                    </CardCa>
                            })
                        }
                        
                        
					</div>
                    {/* FILE CONTENT */}
                    <div className={`mt-3 ${tabState === 3 ? "" : "d-none"}`}>
                        {/* <CardCa>
                            <CardCaFile />
                        </CardCa> */}
					</div>

                    {/* PAGAMENTI CONTENT */}
                    <div className={`mt-3 ${tabState === 4 ? "" : "d-none"}`}>

{arrToFilter.length > 0 ? <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
    <FilterSearchBar 
        setSearchTerm={setSearchTerm}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        clearFiltersAndSort={clearFiltersAndSort}
        setSortBy={setSortBy}
        categories={categories}
        noSearch={true}
    />
</Col> :
<div className="mx-auto text-center mt-5 ">
    <div className="mx-auto d-table w-90">
    <img loading="lazy" src={EmptyState} alt="empty" />
    </div>
    <p className="fs-20 text-secondary text-center mt-3">
        Nessun elemento
    </p>
</div>
}
{
        sortedItems?.length > 0 &&
        sortedItems.filter(el => el.messageType == "paymentMsg" ).map(item => {
            return <CardCa key={item.id} id={item.id} scrollToMessage={scrollToMessage}> 
                    <CardCaPayment item={item} />
                </CardCa>
        })
    }

</div>
					</div>
			</div>
                </Modal.Body>
            </Modal>
        </>
        // <!-- END Contenuti attività POPUP -->
    );
};


function CardCa(props){
    return(
        <div className="card-ca mb-2" onClick={() => props.scrollToMessage(props.id)}>
            {props.children}
        </div>
    )
}


function CardCaChecklist(props){
    const peopleWhoAccepted = props.item.confirmContents?.filter(item => item.accepted)
    const peopleWhoNotAccepted = props.item.confirmContents?.filter(item => !item.accepted)

    return(
        <>
            <p className="fs-14 fw-bold">Checklist/verbale</p>
            <div className="my-2">
                {peopleWhoAccepted.length > 0 && 
                    <div className="d-flex" >
                    <i class="bi bi-check-circle me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Accettato da {peopleWhoAccepted.length} {peopleWhoAccepted.length > 1 ? "persone" : "persona"}</p>
                </div>
                }
                {peopleWhoNotAccepted.length > 0 && 
                    <div className="d-flex">
                    <i class="bi bi-x-circle me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Non Accettato da {peopleWhoNotAccepted.length} {peopleWhoNotAccepted.length > 1 ? "persone" : "persona"}</p>
                </div>
                }
                {props.item.signs?.length > 0 &&
                    <div className="d-flex">
                    <i class="bi bi-vector-pen me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Firmato da {props.item.signs.length} {props.item.signs.length > 1 ? "persone" : "persona"}</p>
                </div>}

            </div>
            <div className="d-flex justify-content-between align-item-center">
                <p className="fs-10 text-uppercase opacity-50">Creato da user {props.item.userID}</p>
                <p className="fs-10 text-uppercase opacity-50">{props.item.cdat?.substr(0, 10).split("-").join("-")}</p>
            </div>

        </>
    )
}

function CardCaPayment(props){
    const peopleWhoAccepted = props.item.confirmContents?.filter(item => item.accepted)
    const peopleWhoNotAccepted = props.item.confirmContents?.filter(item => !item.accepted)

    const content =  JSON.parse(props.item.content?.replace(/'/g, '"'))

    console.log(content, "content")

    return(
        <>
            <p className="fs-14 fw-bold">Pagamento - {content?.causale} - € {content.prezzo}</p>
            <div className="my-2">
                {peopleWhoAccepted.length > 0 && 
                    <div className="d-flex" >
                    <i class="bi bi-check-circle me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Accettato da {peopleWhoAccepted.length} {peopleWhoAccepted.length > 1 ? "persone" : "persona"}</p>
                </div>
                }
                {peopleWhoNotAccepted.length > 0 && 
                    <div className="d-flex">
                    <i class="bi bi-x-circle me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Non Accettato da {peopleWhoNotAccepted.length} {peopleWhoNotAccepted.length > 1 ? "persone" : "persona"}</p>
                </div>
                }
                {props.item.signs?.length > 0 &&
                    <div className="d-flex">
                    <i class="bi bi-vector-pen me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Firmato da {props.item.signs.length} {props.item.signs.length > 1 ? "persone" : "persona"}</p>
                </div>}

            </div>
            <div className="d-flex justify-content-between align-item-center">
                <p className="fs-10 text-uppercase opacity-50">Creato da user {props.item.userID}</p>
                <p className="fs-10 text-uppercase opacity-50">{props.item.cdat?.substr(0, 10).split("-").join("-")}</p>
            </div>

        </>
    )
}

function CardCaCont(props){

    const peopleWhoAccepted = props.item.confirmContents?.filter(item => item.accepted)
    const peopleWhoNotAccepted = props.item.confirmContents?.filter(item => !item.accepted)

    //OUPUT SPESA

    let output = "";

    let contentString = props.item?.content && props.item.content.replace(/'/g, '"');
    let content = contentString && JSON.parse(contentString);

    for (let j = 0; j < content.length; j++) {
        let name = content[j].name;
        let rows = content[j].rows;
        
        for (let k = 0; k < rows.length; k++) {
            let cost = rows[k]['Costo unitario'];
            let quantity = rows[k]['Quantità'];
            let total = cost * quantity;

            // Aggiungi le informazioni al paragrafo di output
            output += `${name}, prezzo: € ${total}\n`;
        }
    }

    //OUTPUT PREVENTIVO

    let outputPrev = "";

    let contentStringPrev = props.item?.content && props.item.content.replace(/'/g, '"'); // sostituisci singoli apici con doppi apici
    let contentPrev = JSON.parse(contentStringPrev);

    for (let j = 0; j < contentPrev.length; j++) {
        let name = contentPrev[j].name;
        let rows = contentPrev[j].rows;
        let totalCost = 0;
    
        for (let k = 0; k < rows.length; k++) {
            let cost = rows[k]['Costo unitario'] === "" ? 0 : Number(rows[k]['Costo unitario']);
            let quantity = rows[k]['Quantità'] === "" ? 0 : Number(rows[k]['Quantità']);
            totalCost += cost * quantity;
        }
    
        // Aggiungi le informazioni al paragrafo di output
        // Aggiungi le informazioni al paragrafo di output solo se il costo totale è maggiore di zero
        if (totalCost > 0) {
            outputPrev += `${name}, prezzo: € ${totalCost} `;
        } else {
            outputPrev += ``;
        }
    }

    console.log(output);

    return(
        <>
            {props.item.messageType == "preventivo" && <p className="fs-14 fw-bold">Preventivo - {outputPrev}</p>}
            {props.item.messageType == "spesa" && <p className="fs-14 fw-bold">Spesa - {output}</p>}
            <div className="my-2">
                {peopleWhoAccepted.length > 0 && 
                    <div className="d-flex" >
                    <i class="bi bi-check-circle me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Accettato da {peopleWhoAccepted.length} {peopleWhoAccepted.length > 1 ? "persone" : "persona"}</p>
                </div>
                }
                {peopleWhoNotAccepted.length > 0 && 
                    <div className="d-flex">
                    <i class="bi bi-x-circle me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Non Accettato da {peopleWhoNotAccepted.length} {peopleWhoNotAccepted.length > 1 ? "persone" : "persona"}</p>
                </div>
                }
                {props.item.signs?.length > 0 &&
                    <div className="d-flex">
                    <i class="bi bi-vector-pen me-2 fs-12"></i>
                    <p className="fs-12 text-capitalize opacity-50">Firmato da {props.item.signs.length} {props.item.signs.length > 1 ? "persone" : "persona"}</p>
                </div>}

            </div>
            <div className="d-flex justify-content-between align-item-center">
                <p className="fs-10 text-uppercase opacity-50">Creato da user {props.item.userID}</p>
                <p className="fs-10 text-uppercase opacity-50">{props.item.cdat?.substr(0, 10).split("-").join("-")}</p>
            </div>

        </>
    )
}

function CardCaFile(props){
    return(
        <>
            <p className="fs-10 text-uppercase opacity-50">Immobile 123</p>
            <p className="fs-14 fw-bold">Nome File</p>
            <div className="d-flex justify-content-between align-item-center">
                <p className="fs-10 text-uppercase opacity-50">Creato da Pino</p>
                <p className="fs-10 text-uppercase opacity-50">16/2/2023, 16:23</p>
            </div>

        </>
    )
}