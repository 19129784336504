import React, { useEffect, useState } from "react";
import CheckBtn from "../../../../assets/images/check_btn.svg";
import CloseBtn from "../../../../assets/images/close_btn.svg";
import delete_icon from "../../../../assets/images/delete.svg";
import edit from "../../../../assets/images/edit.svg";

export default function FileCard({ deleteVisible = true, ...props }) {
  const [change_state, setChangeState] = useState(false);
  const [new_name, setNewName] = useState(props.name);
  useEffect(() => {
    setNewName(props.name);
  }, [props.name]);
  const modifyName = () => {
    props.setFileName(props.id, new_name);
    // props.setModifyName(new_name);
    setChangeState(false);
  };

 /*  const handleCardClick = () => {
    // Crea l'URL temporaneo per il file PDF caricato
    const pdfUrl = URL.createObjectURL(props.obj);
    // Apri una nuova tab del browser e visualizza il PDF
    window.open(pdfUrl);
  };
   */


  return (
    <><a target="_blank">
        <div className="section-card mb-2" style={{cursor: 'pointer'}} onClick={() => props.download(props.name)}>
        <input
            type="text"
            className="fs-16 text-dark card-input flex-auto"
            disabled={change_state ? false : true}
            value={new_name}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
          />
          {(props.view_mode === undefined || props.view_mode === true) && (
            <>
              <div className={`icon-btn-group ${change_state ? "d-none" : ""}`}>
                {/* <button
                  type="button"
                  className="btn round-icon-btn"
                  onClick={() => {
                    setChangeState(true);
                  }}
                >
                  <img loading="lazy" src={edit} alt="icon" />
                </button> */}
                {deleteVisible && 
                  <button
                    type="button"
                    className="btn round-icon-btn"
                    onClick={(e) => {
                      e.stopPropagation(); // Impedisce la propagazione dell'evento
                      props.deleteFile(props.id, props.name);
                    }}
                  >
                    <img loading="lazy" src={delete_icon} alt="icon" />
                  </button>
                }
              </div>
              <div className={`icon-btn-group ${change_state ? "" : "d-none"}`}>
                <button
                  type="button"
                  className="btn round-icon-btn change-state-btn"
                  onClick={modifyName}
                >
                  <img loading="lazy" src={CheckBtn} alt="check-icon" />
                </button>
                <button
                  type="button"
                  className="btn round-icon-btn change-state-btn"
                  onClick={() => {
                    setChangeState(false);
                    setNewName(props.name);
                  }}
                >
                  <img loading="lazy" src={CloseBtn} alt="check-icon" />
                </button>
              </div>
            </>
          )}
        </div>
      </a>
      {/* <Modal
        show={show}
        className="type-modal"
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            onClick={handleClose}
            className="float-end border-0 bg-white"
          >
            <i className="fal fa-times"></i>
          </Button>
          <ModifyFileModal setFileName={props.setFileName} setShow={setShow} />
        </Modal.Body>
      </Modal> */}
    </>
  );
}
