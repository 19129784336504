import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoStruttura () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Struttura"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Desktop" >
                <TreeItem nodeId="2" label="Barra Laterale" />
                <TreeItem nodeId="3" label="Barra in alto" />

            </TreeItem>
            <TreeItem nodeId="4" label="Mobile" >
                <TreeItem nodeId="5" label="Barra Laterale" />
                <TreeItem nodeId="6" label="Barra in alto" />
            </TreeItem>

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">

      <h1>Struttura della Piattaforma</h1>
      <h2 style={{ color:'transparent', height: 0}}>Struttura</h2>


    <p className="mt-3">La prima pagina che vedrai appena
    entrato su Real Meta è la Dashboard, ma prima di entrare nelle
    specifiche, illustreremo la struttura della nostra piattaforma.</p>

<h2 id="1" >DESKTOP</h2>

<h3 id="2">BARRA LATERALE</h3>
<img loading="lazy" src="https://i.postimg.cc/cJpRWqN8/barra-laterale.png"/>


<p >Sulla sinistra trovate la barra
laterale.</p>
<br/>
<p >In alto trovate il logo di Real Meta,
cliccando sul logo verrete riportati alla pagina Dashboard qualora vi
trovaste in una sezione differente</p>
<br/>

<p >La barra laterale contiene il menu con
le varie sezioni (Unità immobiliari, Edifici…).</p>
<p >Tutte le sezioni, ad eccezione di
Impostazioni, contengono un sottomenù a cui potete accedere
cliccando sulla sezione relativa.</p>
<p >Ad esempio cliccando sulla voce “UNITA’
IMMOBILIARI”, comparirà il relativo sottomenu contenente le voci:</p>
<p >-Unità</p>
<p >-Affitti</p>
<p >-Valutazione Immobiliare</p>

<img loading="lazy" src="https://i.postimg.cc/qR28MmkD/submenu.png"/>
<p className="subtitle">PASSA A SAL</p>


<p >Sotto il menu delle sezioni, trovate un
bottone blu “PASSA A SAL”, questo bottone vi consente di passare
da REAL META PROPERTY, dove di fatto si concentra tutto il lavoro di
profilazioni, scadenze, calcoli e gestione finanziaria, alla nostra
piattaforma STATO AVANZAMENTO LAVORI (SAL), dove potrete gestire le
attività relative agli immobili, comunicare tramite chat, cercare
fornitori per le vostre attività e molto altro ancora che
approfondiremo nella sezione specifica del tutorial 
</p>

<img loading="lazy" src="https://i.postimg.cc/kghNj61s/sal.png"/>


{/* <p >Sotto il pulsante blu, trovate un’icona
con due freccette che vi consente di ridurre la dimensione della
barra laterale. Ricliccando sulla stessa icona potrete riaprire la
barra laterale</p> */}

<p className="subtitle">RIDUCI / ESPANDI LA BARRA</p>

<p >Puoi ridurre o espandare la barra laterale trascinando il bordo destro, relativamente a sinistra o a destra
</p>

<img src="https://i.postimg.cc/L8wDnn1J/comprimi.gif" loading="lazy" />

<h3 id="3">BARRA IN ALTO</h3>
<img loading="lazy" src="https://i.postimg.cc/k56QLJLm/barra-alto.png"/>

<p >La
barra in alto contiene a destra:</p>
<ul>
	<li><p >Profilo</p></li>
	<li><p >Notifiche:
	potrete visualizzare qui le notifiche relative alle scadenze o alla
	chat</p></li>
	<li><p >Pulsante
	di uscita (logout): cliccando su questo pulsante potrete
	disconnettere il vostro account</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/YqRzWyQJ/logout.png"/>


<p>In
alto a sinistra troverete inoltre, durante la creazione o
visualizzazioni di profilazioni, l’icona di una freccia che
rappresenta il bottone che consente di tornare indietro.</p>
<img loading="lazy" src="https://i.postimg.cc/nLRpVV6S/indietro.png"/>

<h2 id="4">MOBILE</h2>

<h3 id="5">BARRA
LATERALE</h3>
<p >Sul
mobile la Barra laterale non sarà presente. Per visualizzare il
menu bisognerà cliccare sull’icona  in alto a destra.</p>

<img loading="lazy" src="https://i.postimg.cc/FHKgggZ1/mobile-headbar.png" />


<h3 id="6">BARRA IN ALTRO</h3>
<p >La
barra in alto rimane invariata.</p>


    </div>
  );
}

