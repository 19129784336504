import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import CustomBadge from "../../CustomBadge";

const DryCouponModal = (props) => {
  return (
    <>
      <div className="d-block w-100 mb-3 mt-5">
        <div>
          <h2 className="fs-20 text-dark mb-2 fw-bold">Cedolare secca</h2>
        </div>
      </div>
      <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
        Spuntare la casella e specificare l’anno fiscale per quei locatori che
        hanno optato per la cedolare secca per questa unità
      </label>
      <Form>
        <div className="col-md-6">
          <Table className="mt-5 mb-5 table-border out-border-none">
            <thead>
              <tr>
                <th>Cedolare secca</th>
                <th>Locatore</th>
                <th>Anno fiscale</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Carmine Russo</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>Aldo Monti</td>
                <td>2013</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-end mt-5">
          <button
            type="button"
            className="general-btn white-btn me-2 w-193"
            onClick={() => props.setShow(false)}
          >
            Annulla
          </button>
          <button
            type="button"
            className="general-btn pink-btn w-193"
            onClick={() => {
              props.setCount(4);
              props.setShow(false);
            }}
          >
            Avanti
          </button>
        </div>
      </Form>
    </>
  );
};

export default DryCouponModal;
