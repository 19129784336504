const translationMappingAtti = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    type: "Tipo",
    name: "Nome",
    type: "Tipo",
    actDate: "Data dell'atto",
    fiscalEffectDate: "Data di effetto fiscale",
    notary: "Notaio",
    collectionNumber: "Raccolta N",
    repertoireNumber: "Repertorio N",
    REGISTRAZIONE: "REGISTRAZIONE",
    registrationOffice: "Ufficio di registrazione",
    registrationDate: "Data",
    registrationSerial: "Serie",
    registrationNumber: "Numero",
    TRASCRIZIONE: "TRASCRIZIONE",
    transcriptionLocation: "Luogo",
    transcriptionDate: "Data",
    transcriptionNumber: "Numero",
    IMPORTI: "IMPORTI",
    actValue: "Valore dell'atto",
    historicalValue: "Valore storico",
    revaluedValue: "Valore rivalutato",
    PROPRIETARIO: "PROPRIETARIO",
    transferor: "Cedente",
    transferee: "Cessionario",
    NOTE: "NOTE",
    note: "Note",
    ALLEGATI: "ALLEGATI",
    attachments: "Allegati",
};

export default translationMappingAtti