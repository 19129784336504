import React, { useState, useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getProvince, getMunicipality } from "../../../actions/addressAction";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import Modal from "react-bootstrap/Modal";
import api, { supplier_endpoint } from "../../../api/api";

import ButtonRm from "../../ButtonRm";
import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../CustomToggle";
import Form from "react-bootstrap/Form";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { useNavigate } from "react-router-dom";
import CustomBadge from "../../CustomBadge";


const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const schema = yup.object().shape({
	address: yup.string().required("Inserisci l'indirrizzo"),
	houseNumber: yup.string().required("Inserisci il civico"),
	province: yup.string().required("Seleziona la provincia"),
	municipality: yup.string().required("Seleziona il comune"),
	cap: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
	administrator: yup.string(),
	name: yup.string().required("Inserisci un identificativo univoco per questo stabile"),
	notes:yup.string()

})



const Registry = (props) => {
	const navigate = useNavigate()
	const [supplier, setSupplier] = useState([]);

	// useEffect(() => {
	// 	let e = { target: { name: "suppliers", value: supplier } };
	// 	props.onChange(e);
	// }, [supplier]);
	async function getSupplier() {
		try {
			const response = await api().get(`${supplier_endpoint}`);
			if (response.data) {
				setSupplier(response.data.content);
				console.log("supplier", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		props.getProvince();
		getSupplier();
	}, []);

	useEffect(() => {
		props.getMunicipality(props.province[0]);
	}, [props.province]);

	const handleProvince = (e) => {
		props.onChange(e);
		props.getMunicipality(e.target.value);
	};
	// const [data, setData] = useState({});
	// useEffect(() => {
	// 	let items = JSON.parse(localStorage.getItem("stabili"));
	// 	if (items) {
	// 		setData(data);
	// 	}
	// }, []);
	const setFileName = (index, newName) => {
		let temp = [...props.formData.structures];
		temp[index] = newName;
		let ele = { target: { name: "structures", value: temp } };
		props.onChange(ele);
	};

	const deleteFile = (index) => {
		let temp = [...props.formData.structures];
		temp.splice(index, 1);
		let ele = { target: { name: "structures", value: temp } };
		props.onChange(ele);
	};

	const [modalShow, setModalShow] = useState(false);
	const [structureName, setStructureName] = useState("");

	const addStructure = () => {
		let temp = [...props.formData.structures];
		temp.push(structureName);
		let ele = { target: { name: "structures", value: temp } };
		props.onChange(ele);
		setStructureName("");
		setModalShow(false);
	};

	   //HANDLE ACCORDION
	   const [activeKey, setActiveKey] = useState("")

	   /* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
		const path = window.location.pathname
	
	  //VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
			resolver: yupResolver(schema),
		});
	
	
	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		props.forward()
	};

	return (
		<>
		     <Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<div className="form-item-align-box d-block p-0 pb-5">
				<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Identificativo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={12} className="f-label mb-3">
									<p>Scegli un identificativo univoco per questo stabile</p>
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
										>
										Identificativo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
										{...register("name")}
										type="text" 
										name="name"
										onChange={props.onChange}
										value={props.formData.name}
										isInvalid={!!errors.name}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.name?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								
							</Row>



						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Dati <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Indirizzo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Control 
									{...register("address")}
									type="text" 
									name="address"
									onChange={(e) => {
										props.onChange(e);
									}}
									value={props.formData.address}
									isInvalid={!!errors.address}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.address?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
										>
										Numero Civico <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

										</Form.Label>
										<Form.Control 
										{...register("houseNumber")}
										type="text" 
										name="houseNumber"
										onChange={props.onChange}
										value={props.formData.houseNumber}
										isInvalid={!!errors.houseNumber}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.houseNumber?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
								
							</Row>

							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Provincia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
									{...register("province")}
									isInvalid={!!errors.province}
									name="province"
									value={props.formData.province}
									onChange={handleProvince}>
									{props.province.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.province?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Comune <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
									{...register("municipality")}
									isInvalid={!!errors.municipality}
									name="municipality"
									value={props.formData.municipality}
									onChange={props.onChange}>
									{props.municipality?.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.municipality?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Cap
									</Form.Label>
									<Form.Control 
										{...register("cap")}
										type="number" 
										isInvalid={!!errors.cap}
										name="cap"
										onChange={(e) => {
											props.onChange(e);
										}}
										value={props.formData.cap}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cap?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Codice fiscale
										</Form.Label>
										<Form.Control 
											{...register("fiscalCode")}
											type="text" 
											isInvalid={!!errors.fiscalCode}
											name="fiscalCode"
											onChange={props.onChange}
											value={props.formData.fiscalCode}
										/>
										<Form.Control.Feedback type="invalid" >
										{errors.cap?.fiscalCode}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>

							<Row className="mb-3">
									<Col md={6} className="d-flex align-items-center">
										<Form.Group className="me-2" style={{width:"90%"}}>	
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Amministratore
											</Form.Label>
											<Form.Select 
												{...register("administrator")}
												name="administrator"
												value={props.formData.administrator}
												onChange={props.onChange}
												isInvalid={!!errors.administrator}>
												<option value="">Seleziona</option>
												{supplier?.map((item, index) => {
													if (item.category == "Amministratore")
														return (
															<option
																key={index}
																value={item.name + " " + item.surname}>
																{item.name + " " + item.surname}
															</option>
														);
												})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.administrator?.message}
											</Form.Control.Feedback>
										</Form.Group>
										<BsPlusCircleFill
											className="col-sm-2 col-md-1 fs-30 plus-button mt-4"
											onClick={() => navigate("/fornitori/create")}
										/>
										
									</Col>
							</Row>	


						</Accordion.Body>
					</Accordion.Item>

					

					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Struttura</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={12} className="f-label mb-3">
									<p>Per struttura si intende la composizione dell’edificio, ad esempio se comprende più scale o palazzine. <br/>
									Per ogni struttura aggiungi una nuova voce e definisci un identificativo.</p>
								</Col>
							</Row>
							<Row className="mb-3"> 
								<Col md={12}>
								<label
					className="black-link mt-2"
					onClick={() => {
						setModalShow(true);
					}}>
					<BsPlusCircleFill className="plus-icon" />
					Aggiungi struttura
				</label>
				<div className="mt-3">
					{props.formData.structures?.length > 0 &&
						props.formData.structures.map((item, index) => {
							return (
								<FileCard
									key={index}
									name={item}
									setFileName={setFileName}
									deleteFile={deleteFile}
									id={index}
								/>
							);
						})}
				</div>
								</Col>
								
							</Row>



						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="5" activeKey={activeKey}>Note</CustomToggle>
							</Card.Header>
							<Accordion.Body>
								<Row>
									<Col>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Note
											</Form.Label>
											<Form.Control 
												{...register("notes")}
												as="textarea"
												name="notes"
												value={props.formData.notes}
												onChange={props.onChange}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>	


				</Accordion>
				</div>

				<div className="d-flex justify-content-end mt-5">
					<ButtonRm 
						variant="tertiary"
						onClick={() => {navigate(-1)}}
						className="me-2"
					>
						Annulla
					</ButtonRm>
					<ButtonRm variant="primary" 
						/* onClick={() => avanti()} */
						type="submit"
					>
						Avanti
					</ButtonRm>
				</div>

			</Form>


			<Modal show={modalShow} centered>
				<Modal.Body className="p-5">
					<div className="d-block w-100 mb-3">
						<div>
							<p className="fs-20 text-dark mb-2 fw-bold text-center">
								Aggiungi una struttura
							</p>
						</div>
					</div>
					<div className="form-item-align-box d-block">
						<div className="mt-3">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">Nome</label>
								<input
									type="text"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={(e) => {
										setStructureName(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-last-btns mt-3 section-modal-btn">

							<button
								type="button"
								className="next-btn btn-last rounded-3 me-2 border-0 col-md-12"
								disabled={structureName !== "" ? false : true}
								onClick={addStructure}>
								Aggiungi
							</button>
							<button
								type="button"
								className="black-btn btn-last rounded-3  border-0 col-md-12 mt-3"
								onClick={() => {
									setModalShow(false);
								}}>
								Annulla
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
});
export default connect(mapStateToProps, { getProvince, getMunicipality })(
	Registry
);
