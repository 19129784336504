import { useRef } from 'react'
import { MapContainer,  Marker, ImageOverlay,  Rectangle, FeatureGroup, Circle, Pane  } from 'react-leaflet'
import { EditControl } from "react-leaflet-draw"
import { Modal } from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import { flip } from '@turf/turf'

import L from 'leaflet'

import 'leaflet-draw/dist/leaflet.draw.css'

import drawLocales from 'leaflet-draw-locales'

export default function Planimetry({myMarkers, image, onSaveMarkers, showModal}){

    drawLocales('it')

    // Customize locale language
    const locale = drawLocales('it')

    L.drawLocal = locale

    //CHANGE MARKER ICON
    function myIcon(){
        return L.icon({
            iconUrl: require('../../../assets/images/pin-map.png'),
            iconSize:24
        })
    }

    const FGref = useRef()

    const rectangles = {...myMarkers,
        features : myMarkers?.features? 
        myMarkers.features.filter(item => item.geometry.type==='Polygon' ):
        []
    }


    const points = {...myMarkers,
        features : myMarkers?.features? 
        myMarkers.features.filter(item => item.geometry.type==='Point' ):
        []
    }


    function getNewMarkers(){
        const featureCollection = FGref.current.toGeoJSON()

        featureCollection.features = featureCollection.features.map(feature => flip(feature))

        return featureCollection
    }

    function handleSave(){
        onSaveMarkers(getNewMarkers())
    }


    return(

        <Modal show={showModal} onHide={ handleSave } centered>
            <Modal.Header>
                <Modal.Title>Planimetria</Modal.Title>
                <a><i className="bi bi-x-lg" onClick={handleSave}></i></a>
            </Modal.Header>

        <Modal.Body>
            
        <MapContainer crs={L.CRS.Simple} center={[0, 0]}  bounds={[[0, 0], [512, 512]]} className="leaflet-container-modal" minZoom={-1} scrollWheelZoom={false} >
            
            <ImageOverlay
                bounds={[[0, 0], [512, 512]]}
                url={image}
            />

            <FeatureGroup ref={FGref}>
                {rectangles.features?.length > 0 && rectangles.features.map(rect => {
                    return <Rectangle key={rect.id} bounds={rect.geometry.coordinates}  ></Rectangle>
                })}
                {points.features?.length > 0 && points.features.map(point => {
                    return <Marker key={point.id} position={point.geometry.coordinates}                             icon={myIcon()}
                    ></Marker>
                })}
                <EditControl
                    className="map-tool"
                    position='bottomright'
                    draw={{
                        rectangle: true,
                        circlemarker: false,
                        circle:false,
                        polygon:false,
                        polyline:false
                    }}
                    
                />
            </FeatureGroup>

        </MapContainer>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>

    </Modal>
        
    )
}