import React, {useState, useEffect} from 'react';
import api from '../../../../api/api';

import { Tree, TreeNode } from 'react-organizational-chart';


export default function (props) {

  return (

    <div id="treeWrapper" style={{ width: '100%', minHeight: '500px' }} className="border custom-overflow">
    <Tree label="Master" className="w-100 ">
      {props.manageTeamData(props.all).map(el => {
        {/* NOME MASTER */}
        return <TreeNode label={<div>{props.mappingMasters[el.name]}</div>}>
          {el.children.map(item => {
            {/* NOME RUOLI */}
            return item.children?.length > 0 ?
              <TreeNode label={<div>{item.name}</div>}>
                { item.children.map(user => {
                  {/* EMAIL UTENTE */}
                  return   <TreeNode label={<div>{user.name}</div>} />
                })}
              </TreeNode> :
              <TreeNode label={<div>{item.name}</div>} />
          })}
      </TreeNode>
      })}
    </Tree>
    </div>
  );
}

