import React, { useEffect, useState } from "react";
//import "../assets/css/Documenti.css";
import TopHeading from "../../TopHeading";
import UserTemplateStorico from "./UserTemplateStorico";
import { connect } from "react-redux";
import api, { section_endpoint, model_endpoint } from "../../../api/api";

import send from "../../../assets/images/send 1.svg"


import { Modal } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";


import CustomBadge from "../../CustomBadge";
import { Link } from "react-router-dom";

import SearchInput from "../../SearchInput";

//import ButtonRm from "../components/ButtonRm";

//import UploadBtn from "../components/Documenti/Modelli/UploadBtn";


function ModelliStorico(props) {
  const [formData, setFormData] = useState([]);
  const [search_name, setSearchName] = useState("");

  //HANDLE ATTACHMENTS
  const [files, setFiles] = useState([]);

  const getModelsBySection = async () => {
    try {
      const res = await api().get(`${section_endpoint}`);
      if (res.data) {
        setFormData(res.data.content);
        console.log(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const removeModel = async (id) => {
    try {
      const res = await api().delete(`${model_endpoint}${id}`);
      if (res.data) {
        setFormData(res.data.content);
        console.log(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
    getModelsBySection();
  };

  useEffect(() => {
    getModelsBySection();
  }, []);


  //CREATION

  const [modalNew, setModalNew] = useState(false)

  function showModalNew(){
    setModalNew(true)
  }

  function closeModalNew(){
    setModalNew(false)
  }


  //NEW DATA

  const [newModal, setNewModal] = useState({
    name:"",
    section:"",
    attachment: []
  })

  function handleNewModal(e){
    const {name, value} = e.target
    setNewModal(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const [sectionType, setSectionType] = useState("Sezione esistente")

  function handleSectionType(e){
    const { value} = e.target
    setSectionType(value)
  }

  useEffect(() => {
    setNewModal(prev => {
      return {
        ...prev,
        attachment: files
      }
    })
  }, [files])


  //SECTIONS CREATED

  const [sections, setSections] = useState([])

  function addNewSection(){
    if(sectionType == "Crea Nuova"){
      setSections(prev => {
        return [...prev, newModal.section]
      })
    }
  }

  //SAVE NEW

  function saveModel(e){
    e.preventDefault()
    setFormData(prev => {
      return [...prev, newModal]
    })
    addNewSection()
    closeModalNew()
    setNewModal(
      { name:"",
      section:"",
      attachment: []} 
    )
  }
  

  //RIEPILOGO

  const esempio = [
    {   
        id:1,
        name: "Mario",
        surname: "Guidi",
        methodAvailable: ["email", "pec", "chat", "postalizza"],
        methodFav: "email",
        method1:"email",
        nameInput: "method1",
        sent:"false"
    },
    {
        id:2,
        name: "Nunzio",
        surname: "Guidi",
        methodAvailable: ["email", "pec", "chat"],
        methodFav: "pec",
        method2:"pec",
        sent:"false"
    },
    {
        id:3,
        name: "Giulio",
        surname: "Guidi",
        methodFav: "consegna",
        methodAvailable: ["email", "pec", "chat", "consegna"],
        method3:"consegna",
        sent:"false"
    },
    {
        id:4,
        name: "Fedele",
        surname: "Guidi",
        methodAvailable: ["email", "postalizza", "chat", "consegna"],
        methodFav: "chat",
        method4:"consegna",
        sent:"false"
    },
    {
        id:5,
        name: "Goiele",
        surname: "Guidi",
        methodAvailable: ["postalizza"],
        methodFav: "postalizza",
        method5:"postalizza",
        sent:"false"
    },
]

const [sendDetail, setSendDetail] = useState(esempio)

function handleMethod(e, id){
    const {name, value, type, checked} = e.target

    const newArr = sendDetail.map((item) => {
        return item.id == id ?
        {...item, [name]: type == "checkbox"? checked : value}:
        item
    })

    setSendDetail(newArr)
}

console.log(sendDetail)

//MODAL POSTALIZZAZIONE

const [postalizzaModal, setPostalizzaModal] = useState(false)

function showPostalizzaModal(){
    setPostalizzaModal(true)
}

function closePostalizzaModal(){
    setPostalizzaModal(false)
}

//SEARCH
const [search, setSearch] = useState("")

  return (
    <>
    <div className="title-search-group">
        <div className="right-header">
          <TopHeading SmallHeading="empty" />
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Storico documenti</h2>
          </Col>
        </Row>
      </div>

      <Row className="dashboard-container">
      {<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
				<SearchInput setSearch={setSearch}/>

			</Col>}		
        <div>
            <UserTemplateStorico
              name={"PROVOLA"}
              id={1}
              attachments={[]}
              delete={removeModel}
              showModalNew={showModalNew}
            />
        </div>
		</Row>

      {/* <div className="mt-4">			
        <div>
            <UserTemplate
              name={"PROVA"}
              id={1}
              attachments={[]}
              delete={removeModel}
            />
        </div>
		</div>
      
      

      {formData &&
        formData.length > 0 &&
        formData.map((section, index) => {
          return (
            <div key={index}>
              {
                section.models.length > 0 && ( 
                  <p key={index} className="fs-16 fw-bold mb-3 mt-4">
                    {section.name}
                  </p>
                )
              }
              

              {section.models.map((model) => {
                if (
                  model.name
                    .toLowerCase()
                    .indexOf(props.searchName.toLowerCase()) !== -1
                )
                  return (
                    <UserTemplate
                      key={model.id}
                      name={model.name}
                      id={model.id}
                      attachments={model.attachment}
                      delete={removeModel}
                    />
                  );
                else return "";
              })}
            </div>
          );
        })}
*/}

      <Modal
          show={modalNew}
          onHide={closeModalNew}
          centered
          className="pb-3"
      >
          <Modal.Header closeButton style={{border:"none"}}>
          </Modal.Header>
          <Modal.Body className="p-0"
          style={{maxHeight:"500px", overflowY:"auto"}}>
              <div
              className="px-4"
              >
                <p className="fs-24 fw-bold mb-3">Riepilogo</p>
                  <div className="pb-3 mb-3">
                  { sendDetail.map((item, index) => {
                    return  <Col key={item.id} className="px-0 mb-2">
                <div className="faceIdDiv text-start d-md-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column">
                        <p className="fs-16 fw-bold text-capitalize">{item.surname} {item.name}</p>
                        { item[`method${item.id}`] !== "consegna"  && 
                        item[`method${item.id}`] != "postalizza" &&
                          <div className="d-flex">
                            <p className="fs-12 me-4"><i class="bi bi-send opacity-75 me-1"></i>{item[`method${item.id}`]}</p>
                            <p className="fs-12"><i class="bi bi-calendar opacity-75 me-1"></i>12/02/2023</p>
                          </div>
                        }
                        { item[`method${item.id}`] == "consegna"  && !item.sent && 
                            <Form.Check                                
                                inline
                                label="Segna come consegnato"
                                type="checkbox"
                                name="sent"
                                onChange={(e) =>handleMethod(e, item.id)}
                                checked={item.sent}
                            />
                        }
                        { item[`method${item.id}`] == "consegna"  && item.sent && 
                            <div className="d-flex">
                            <p className="fs-12 me-4"><i class="bi bi-send opacity-75 me-1"></i>{item[`method${item.id}`]}</p>
                            <p className="fs-12"><i class="bi bi-calendar opacity-75 me-1"></i>12/02/2023</p>
                          </div>
                        }
                    </div>
                    <div className="d-flex flex-column justify-content-end align-items-end">
                        {item[`method${item.id}`] != "postalizza" &&
                        item[`method${item.id}`] != "consegna" && <CustomBadge variant="custom-badge-success">Inviato</CustomBadge>}

                        { 
                        item[`method${item.id}`] == "consegna" && item.sent == false &&
                            <>
                                <CustomBadge variant="custom-badge-danger">Ricordati di consegnare il documento a questo soggetto</CustomBadge>
                                <Link className="text-decoration-underline text-dark fs-14 me-2" to="/alert/nuovo-evento">Crea alert</Link>
                            </>
                        }

        { 
                        item[`method${item.id}`] == "consegna" && item.sent &&
                            <>
                              <CustomBadge variant="custom-badge-success">Inviato</CustomBadge>
                            </>
                        }

              

                    { item[`method${item.id}`] == "postalizza" && 
                            <>
                                <CustomBadge variant="custom-badge-alert"
                                >Inserisci le informazioni mancanti</CustomBadge>
                            </>
                        }

                    </div>
                </div>
                            </Col>
                  })}
                  </div>
              </div>
          </Modal.Body>
      </Modal>  

      {/* MODAL POSTALIZZA */}
        <Modal
                    show={postalizzaModal}
                    onHide={closePostalizzaModal}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <p className="fs-18 fw-bold mt-3 mb-2">Inserisci dati per la postalizzazione</p>
                            
                            {/* <ButtonRm variant="primary" onClick={closeOtpModal}>Verifica</ButtonRm>
                            <ButtonRm variant="text">Rinvia OTP</ButtonRm> */}                        
                        </div>

                    </Modal.Body>
        </Modal> 
    </>
  );
}
const mapStateToProps = (state) => ({
  searchName: state.profile.searchName,
});

export default connect(mapStateToProps, null)(ModelliStorico);