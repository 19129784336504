import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap"

import pc from '../../../assets/images/placeholder-unit.png'
import { NavLink } from "react-router-dom";
import FilterSearchBar from "../Gestioni/FilterSearchBar";
import { sortDatesByKey, sortStringByKey } from "../../../utils/filtering";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsDownload, BsTrash, BsArchive, BsCheck2Circle } from "react-icons/bs";
import api from '../../../api/api';
import { IoEnterOutline } from "react-icons/io5";
import {checkMaster} from '../../../utils/checkUserRole'
import ButtonRm from "../../ButtonRm";

import { formattedTimestamp, dateDifferenceInDays } from "../../../utils/dateManipulation";

//MOSTRO LISTA DELLE CONVERSAZIONI

// import { Link } from "react-router-dom";
export const Chatlist = (props) => {

    console.log(props, "PROPS")

    const addremovelist = () => {
        // $(document).ready(function () {
        //     $(window).on("resize", function (e) {
        //         checkScreenSize();
        //     });
        //     checkScreenSize();
        //     function checkScreenSize() {
        //         var newWindowWidth = $(window).width();
        //         if (newWindowWidth < 768) {
        //             $("body").addclass(".mbl-body");
        //         } else {
        //             $("body").removeclass(".mbl-body");
        //         }
        //     }
        // });
    };

    useEffect(() => {
        addremovelist();
    }, [])

    //NEW SEARCH & FILTER
	//SEARCH
    const [searchTerm, setSearchTerm] = useState("");

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

    const [partecipantList, setPartecipantList] = useState([])

    //GET LIST OF PARTECIPANTS
    async function getPartecipants() {
        try {
            const response = await api().get(`${'/api/v1/invitation/getTeam'}`);
            if (response.data ) {
                setPartecipantList(response.data.members);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getPartecipants()
    }, [])

    const categories = [
		{
            name: 'Partecipanti',
            items: partecipantList.map(user => {
                return `${(user.name && user.surname )? `${user.name} ${user.surname}` : `${user.email}`} `
            })
		},
		{
            name: 'Tipologia',
            items: ['Create da me']
		}

    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (props.list.length > 0) {
        setFilteredUnits(
            props.list.filter((el) => {

            const elDate = new Date(el.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && elDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && elDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return el.units.some((unit) => {
						return cat.items.includes(unit);
						});
					}  
					})) &&
					el.chatBoxName?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, props.list, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "chatBoxName", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "chatBoxName"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(props.list);
		setStartDate("")
		setEndDate("")

    }


    //per ogni conversazione controlla il parametro chats x
    //se array.length > 0, trova l'ultimo elemento dell'array x
    //controlla cdat dell'ultimo elemento x
        //scomponi cdat in 2 costanti: CDAT e CTIME x
    //se cdat e TODAY DATE corrispondono mostra ora di cdat (9:02) x
    //se cdat è diversa da TODAY DATE, ma compresa tra 1 e 6 giorni precedenti a TODAY DATE  x
    //mostra il giorno della settimana relativo a quella data (lunedi, martedi) x
    //se cdat è diversa da TODAY DATE, e > 6gg precedenti a questa mostra la data dd/mm/yy x
    function checkDataToDisplay(el){
        let dataTimeToShow = ""
        const TODAY_DATE = new Date()
        const TODAY_DATE_FORMAT = TODAY_DATE.toISOString().split('T')[0]

        const chats = el?.chats ? el.chats : []
        if(chats.length > 0) {
            let lastMsg = chats[chats.length - 1];
            let fullDate = lastMsg.cdat;
            let fullDateFormat = formattedTimestamp(fullDate);
            let cDate = fullDateFormat.split(" ")[0];
            let cTime = fullDateFormat.split(" ")[1];

            if(TODAY_DATE_FORMAT === cDate){
                dataTimeToShow = cTime
                return dataTimeToShow
            } else {
                if( cDate < TODAY_DATE_FORMAT ) {
                    const differenceDays =  dateDifferenceInDays(new Date(cDate), new Date(TODAY_DATE_FORMAT) )

                    if(differenceDays > 6){
                        dataTimeToShow = cDate.split("-").reverse().join("/");

                        return dataTimeToShow
                    } else {
                        const weekDayMapping = {
                            0: "Domenica",
                            1: "Lunedì",
                            2: "Martedì",
                            3: "Mercoledì",
                            4: "Giovedì",
                            5: "Venerdì",
                            6: "Sabato"                        
                        }
                        const cDateFormat = new Date(cDate)
                        const getWeekDay =  cDateFormat.getDay()
                        const getWeekName = weekDayMapping[getWeekDay]

                        dataTimeToShow = getWeekName
                        return dataTimeToShow

                    }
                    
                } else {
                    console.log('sono in ELSE 1')
                    return dataTimeToShow
                }
            }

        } else {
            console.log('sono in ELSE 2')
            return dataTimeToShow
        }
    }


    /* Definisci il tipo di messaggio anterpima da mostrare a seconda di messageType */
    function showPreviewChat(el){
        const chats = el?.chats ? el.chats : []
        if(chats.length > 0) {
            let lastMsg = chats[chats.length - 1];
            const messageType = lastMsg.messageType
            let contentPreview = ""

            if(el.deleted){
                contentPreview = "Messaggio eliminato"
            } else {
                switch (messageType) {
                    case 'preventivo':
                        contentPreview = "Preventivo";
                        break;
                    case 'verbale':
                        contentPreview = "Verbale";
                        break;
                    case 'event':
                        contentPreview = "Evento";
                        break;
                    case 'paymentMsg':
                        contentPreview = "Pagamento";
                        break;
                    case 'spesa':
                        contentPreview = "Spesa";
                        break;
                    case 'firma intera':
                        contentPreview = "Firma";
                        break;
                    case 'audio':
                        contentPreview = "Audio";
                        break;  
                    case 'text':
                        console.log(el.message, "TEXT")
                        contentPreview = lastMsg.message;
                        break;  
                    default:
                        contentPreview = ""
                }
            }
            console.log(contentPreview, el.message, el.name)

            return contentPreview

        } else {
            return ""
        }
    }

    
    return (
        <div className="inner-flex lftcht-sec">
            <div className="">
                <Col className="mb-3 d-flex align-items-center justify-content-between">
                    <FilterSearchBar
                    setSearchTerm={setSearchTerm}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    clearFiltersAndSort={clearFiltersAndSort}
                    setSortBy={setSortBy}
                    categories={categories}
                    setStartDate={setStartDate} setEndDate={setEndDate}
                    />
                </Col>
                <div className="chatlist-card p-0">
                    <ul>
                        {
                            sortedItems.length > 0 &&
                            sortedItems.map(item => {

                                let dateTimeChat  = checkDataToDisplay(item)
                                let contentPreview = showPreviewChat(item)
                                
                                return <NavLink to={`/statoavanzamentolavori/chat/${item.id}`}
                                className={({ isActive }) =>
                                isActive ? "open-chat d-block mb-2 text-dark" : "d-block mb-2 text-dark"
                                }
                                >
                                <li className="" onClick={addremovelist}
                                >
                                <div className="card">
                                    <div className="d-flex justify-content-between">
                                        {/* <div className="flx-iner">
                                            <div className="user-chat-img me-2">
                                                <img loading="lazy" src={item.img? item.img : pc} alt="" />
                                            </div>
                                        </div> */}
                                        <div className="d-flex flex-column justify-content-center">
                                            <p className="text-capitalize fs-14 fw-bold">{item.chatBoxName}</p>
                                            <p className="fs-12">
                                            <span className="d-inline-block chat-card-name ">{contentPreview}</span>
                                            <small className="d-block">{dateTimeChat}</small>

                                            </p>
                                        </div>
                                                                        
                                        {/* 
                                            AGGIUNGERE CONTROLLO CHE SIA 
                                            - VISIBILE SOLO DA UTENTI MASTER
                                            - SE QUELL'UTENTE MASTER NON APPARTIENE GIA' ALLA CHAT
                                         */}

                                        <div className="d-flex flex-column align-items-end me-1">
                                        <i class="bi bi-1-circle-fill text-success"></i>
                                        { checkMaster() &&
                                            <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 600, hide: 300 }}
                                            overlay={
                                                <Tooltip className=''>
                                                    Richiedi di partecipare 
                                                </Tooltip>
                                            }
                                            >
                                                <button
                                                    type="button"
                                                    className="btn round-icon-btn mt-auto round-icon-btn-chatlist me-0"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <IoEnterOutline />
                                                </button>
                                            </OverlayTrigger>
                                        }
                                        </div>

                                    </div>
                                </div>
                                </li>
                            </NavLink>
                        })
                        
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};
