import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useMediaQuery } from 'react-responsive';
import MediaQuery from "react-responsive";
import dropLines from "../../../../assets/images/dwon-lines.svg";
import "../../../../assets/css/Rate.css";
// import userImg from "../../../assets/images/lightimg.png";
import homeImg from "../../../../assets/images/home.svg";
import { Link } from "react-router-dom";
import MembriBtn from "../MembriBtn";
import CheckBoxMember from "../CheckBoxMember";

import { Row, Col } from "react-bootstrap";


import {OverlayTrigger} from "react-bootstrap";
import {Tooltip} from "react-bootstrap";
import SearchInput from "../../../SearchInput";

export default function FilterTableM(props) {
  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [items, setItems] = useState(props.rate);

  useEffect(() => {
    var tempItems = ["Mario Rossi", "Aldo Parodi"];
    setItems(tempItems);
  }, []);
 /*  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  }; */
  const handleClick1 = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive1((current) => !current);
  };
  const filterBtn = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive2((current) => !current);
  };
  const memBtn = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive3((current) => !current);
  };

  //FILTER BY
  const [view, setView] = useState("membri")

  function handleView(name){
    setView(name)
  }


//TOTALE MEMBRI

const totalAmountsMembers = props.membersData?.length > 0 && props.membersData.reduce((acc, current) => {
  return {
    totalDue: acc.totalDue + current.totalDue,
    totalPaid: acc.totalPaid + current.totalPaid,
    totalOverdue: acc.totalOverdue + current.totalOverdue,
  };
}, { totalDue: 0, totalPaid: 0, totalOverdue: 0 });


//UNASSIGNED MEMBRI

const otherMembers = props.membersDataUnassigned && props.membersDataUnassigned.length >0 ? props.membersDataUnassigned.reduce(
  (acc, curr) => {
    acc.totalDue += curr.totalDue;
    acc.totalPaid += curr.totalPaid;
    const today = new Date();
    if (today > new Date(curr.deadlineDate) && curr.totalPaid < curr.totalDue) {
      acc.totalOverdue += curr.totalDue - curr.totalPaid;
    }
    return acc;
  },
  { totalDue: 0, totalPaid: 0, totalOverdue: 0 }
):
{ totalDue: 0, totalPaid: 0, totalOverdue: 0 }

//MEMBRI + ALTRO
const totaleMembri = {
  totalDue: (parseFloat(totalAmountsMembers.totalDue) + parseFloat(otherMembers.totalDue)).toFixed(2),
  totalPaid: (parseFloat(totalAmountsMembers.totalPaid) + parseFloat(otherMembers.totalPaid)).toFixed(2),
  totalOverdue: (parseFloat(totalAmountsMembers.totalOverdue) + parseFloat(otherMembers.totalOverdue)).toFixed(2)
};


//TOTALE UNITA + condo

const totalAmountsUnit = props.unitData?.length > 0 && props.unitData.reduce((acc, current) => {
  return {
    totalDue: acc.totalDue + current.totalDue,
    totalPaid: acc.totalPaid + current.totalPaid,
    totalOverdue: acc.totalOverdue + current.totalOverdue,
  };
}, { totalDue: 0, totalPaid: 0, totalOverdue: 0 });

//UNASSIGNED UNITA

const otherUnits = props.unitsDataUnassigned && props.unitsDataUnassigned.length >0 && props.unitsDataUnassigned.reduce(
  (acc, curr) => {
    acc.totalDue += curr.totalDue;
    acc.totalPaid += curr.totalPaid;
    const today = new Date();
    if (today > new Date(curr.deadlineDate) && curr.totalPaid < curr.totalDue) {
      acc.totalOverdue += curr.totalDue - curr.totalPaid;
    }
    return acc;
  },
  { totalDue: 0, totalPaid: 0, totalOverdue: 0 })

//UNITA + ALTRO
const totaleUnits = {
  totalDue: (parseFloat(totalAmountsUnit.totalDue)  + parseFloat(otherUnits.totalDue)).toFixed(2),
  totalPaid: (parseFloat(totalAmountsUnit.totalPaid)  + parseFloat(otherUnits.totalPaid)).toFixed(2),
  totalOverdue: (parseFloat(totalAmountsUnit.totalOverdue)  + parseFloat(otherUnits.totalOverdue)).toFixed(2)
};

  //FILTRO MEMBRI
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedFilters([...selectedFilters, checkboxValue]);
    } else {
      setSelectedFilters(
        selectedFilters.filter((filter) => filter !== checkboxValue)
      );
    }
  };

  console.log("unit", props.unitsData)


  //filteredData MEMBERI
  const [searchTerm, setSearchTerm] = useState("")

  const filteredData = props.membersData.filter((item) => {
    // Filtra in base ai ruoli selezionati
    if (selectedFilters.length > 0 && !selectedFilters.includes(item.role)) {
      return false;
    }

    // Filtra in base al termine di ricerca (name)
    if (searchTerm.trim() !== "" && !item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }

    return true;
  });

    //filteredData UNIT
    const filteredDataUnit = props.unitData.filter((item) => {
  
      // Filtra in base al termine di ricerca (name)
      if (searchTerm.trim() !== "" && !item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
  
      return true 
    })

    const filteredDataGest = props.gestioniWithUnits.filter((item) => {
  
      // Filtra in base al termine di ricerca (name)
      if (searchTerm.trim() !== "" && !item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
  
      return true 
    })


  const isMinWidth767 = useMediaQuery({ query: '(min-width: 767px)' });

    //GESTIONE VISUALIZZAZIONE SUBROWS
    // Aggiungi uno stato iniziale per il controllo delle subrow
const [activeRows, setActiveRows] = useState([]);

const handleClick = (index) => {
  // Crea una copia dell'array activeRows
  const updatedActiveRows = [...activeRows];
  
  // Controlla se l'indice dell'elemento è già presente nell'array activeRows
  const indexExists = updatedActiveRows.includes(index);
  
  if (indexExists) {
    // Se l'indice esiste, rimuovilo dall'array
    updatedActiveRows.splice(updatedActiveRows.indexOf(index), 1);
  } else {
    // Se l'indice non esiste, aggiungilo all'array
    updatedActiveRows.push(index);
  }
  
  // Aggiorna lo stato activeRows con l'array aggiornato
  setActiveRows(updatedActiveRows);
};


function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}


//TOTAL AMOUNTS GESTIONI

const totalAmountsGestioni = props.gestioniWithUnits?.length > 0 && props.gestioniWithUnits.reduce((acc, current) => {
  return {
    totalDue: acc.totalDue + current.totalDue,
    totalPaid: acc.totalPaid + current.totalPaid,
    totalOverdue: acc.totalOverdue + current.totalOverdue,
  };
}, { totalDue: 0, totalPaid: 0, totalOverdue: 0 });

console.log(totalAmountsGestioni)


  return (
    <>

        <Col className="my-2 d-flex align-items-center justify-content-between">
          <SearchInput  setSearch={setSearchTerm} />
          <div className="d-flex flex-row-reverse ms-4">
          <div className="d-flex align-items-center top-filter">
           {/*  <p className="fs-14 text-dark fw-regular me-2">Filtra per</p> */}
            <div className="d-flex me-2 active rel">
              <div
                className={`mebtn ${view == "membri" ? "active-membri" : ""}`}
                onClick={() => handleView("membri")}
              >
                Membri
              </div>
              {view == "membri" && <MembriBtn handleCheckboxChange={handleCheckboxChange} />}
            </div>
            <div className="me-2 ">
              <div
                onClick={() => handleView("unità")}
                className={`mebtn ${view == "unità" ? "active" : ""}`}
              >
                Unità
              </div>
            </div>
            <div className="">
              <div
                onClick={() => handleView("gestioni")}
                className={`mebtn ${view == "gestioni" ? "active" : ""}`}
              >
                Gestione
              </div>
            </div>
          </div>
          </div>
        </Col>
  
        <div className="d-block mt-4">
          <table className="table table-borderless midloop-table drop-table align-middle  w-100">
             {/* INTESTAZIONE TABELLA */}
            <thead>
              <tr>
                <th><p className="fs-12 fs-md-14">Nominativo</p></th>
                <th className="d-none d-md-table-cell"><p className="fs-12 fs-md-14">Tipo</p></th>
                <th className="d-none d-md-table-cell"><p className="fs-12 fs-md-14">Totale dovuto</p></th>
                <th className="d-none d-md-table-cell"><p className="fs-12 fs-md-14">Totale versato</p></th>
                <th><p className="fs-12 fs-md-14">Saldo</p></th>
                <th><p className="fs-12 fs-md-14">Saldo scaduto</p></th>
              </tr>
            </thead> 

            {/* TBODY MEMBRI */}

            { view == "membri" &&
              <tbody>
              {filteredData.length > 0 && filteredData.map((item, index) => {
                console.log(item)
                  return (
                    <>
                        <tr>
                          <td className="position-relative ">
                          { item.units &&  <button
                              type="button"
                              onClick={() => handleClick(index)} // Passa l'indice dell'elemento
                              className="updwon-icon border-0 bg-transparent"
                            >
                                                          <i className={`bi bi-caret-${activeRows.includes(index) ? 'up-fill' : 'down-fill'}`}></i>

                            </button>}
                            <Link to={`/members-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1 me-md-3">
                                  <div className={`name-al ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden ${item?.role == "owner" && "rateColor1"}
                                ${item?.role == "conductor" && "rateColor2"}
                                ${item?.role == "supplier" && "rateColor3"}`}>
                                    <h6 className="text-white fs-14 fs-lg-16 fw-regular">
                                    {item?.role == "owner" && "P"}
                                    {item?.role == "conductor" && "C"}
                                    {item?.role == "supplier" && "F"}
                                    </h6>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  {item?.surname} {item?.name.substr(0,15)}...
                                  </p>
                                </div>
                              </div>

                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                {item?.role == "owner" && "Proprietario"}
                                {item?.role == "conductor" && "Conduttore"}
                                {item?.role == "supplier" && "Fornitore"}
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {item.totalDue.toFixed(2)}
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {item.totalPaid.toFixed(2)}
                              </p>
                            </Link>
                          </td>
                          
                          <td>
                          <Link to={`/members-details/${item.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${item.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {item.totalDue.toFixed(2)}
                                </p>
                              </div>
                            </Link>
                          </td>

                          <td>
                          <Link to={`/members-details/${item.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${item.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                  € {item.totalOverdue > 0? item.totalOverdue.toFixed(2)  : 0}
                                </p>
                              </div>
                            </Link>
                          </td>
                        </tr>

                      {item.units && item.units.length > 0 &&  
                        item.units.map((unit) => {
                          return  <tr
                          className={activeRows.includes(index) ? '' : 'showrow-click'}
                        >
                          <td className="position-relative">
                          <Link to={`/unit-details/${unit.id}`}>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-1 me-md-3">
                                <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 `}>
                                  <img loading="lazy" src={homeImg} alt="user" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  {unit.name?.substr(0,15)}...
                                </p>
                              </div>
                            </div>
                          </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                            <Link to={`/unit-details/${unit.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Unità
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${unit.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              €{unit.totalDue.toFixed(2)}
                              </p>
                            </Link>
                          </td>
  
                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${unit.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              €{unit.totalPaid.toFixed(2)}
                              </p>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/unit-details/${unit.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${unit.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                €{unit.totalDue.toFixed(2)}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td>
                            <Link to={`/unit-details/${unit.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${unit.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}> </span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                €{unit.totalOverdue > 0? unit.totalOverdue.toFixed(2)  : 0} 
                                </p>
                              </div>
                            </Link>
                          </td>
                        </tr>
                        })
                      }
                      {item.installments && item.installments.length > 0 &&  
                        item.installments.map(installment => {
                          return  (installment.unit.length == 0 && installment.condominium.length == 0) ?
                          <tr
                          className={isActive ? "" : "showrow-click"}
                        >
                          <td className="position-relative">
                          <Link to={`/members-details/${item.id}`}>                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-1 me-md-3 ">
                                <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 p-2`}>
                                  
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  Altro
                                </p>
                              </div>
                            </div>
                          </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Altro <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>
                                  Questa rata è associata a questo membro, ma non è associata nè ad un condominio, nè ad un'unità
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-1"></i>
                                </OverlayTrigger>
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              €{installment.totalDue.toFixed(2)}
                              </p>
                            </Link>
                          </td>
  
                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              €{installment.totalPaid.toFixed(2)}
                              </p>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/members-details/${item.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3  ${installment.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                €{installment.totalDue.toFixed(2)}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/members-details/${item.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3  ${installment.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                €{installment.totalOverdue > 0? installment.totalOverdue.toFixed(2)  : 0} 
                                </p>
                              </div>
                            </Link>
                          </td>
                          </tr> :
                          ""
                        })
                      }
                    </>
                  );
              })}
                 {props.membersDataUnassigned && props.membersDataUnassigned.length >0 && !isEmptyObject(otherMembers) && (otherMembers.totalDue || otherMembers.totalPaid)  &&  <tr>
                    <td className="position-relative">
                      <Link to="/other-rate-members">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-1 me-md-3 ">
                            <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 p-2`}>
                                
                              </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Altro
                            </p>
                          </div>
                        </div>
                      </Link>
                    </td>

                    <td className="d-none d-md-table-cell">
                    <Link to="/other-rate-members">
                        <p className="fs-14 fs-lg-16 text-dark fw-regular">
                          Altro <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>
                                  Queste sono rate che non state associate a nessun proprietario, conduttore o fornitore
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-1"></i>
                                </OverlayTrigger>
                        </p>
                      </Link>
                    </td>

                    <td className="d-none d-md-table-cell">
                    <Link to="/other-rate-members">
                        <p className="fs-14 fs-lg-16 text-dark fw-regular">
                        €{otherMembers.totalDue.toFixed(2)}
                        </p>
                      </Link>
                    </td>
                    <td className="d-none d-md-table-cell">
                    <Link to="/other-rate-members">
                        <p className="fs-14 fs-lg-16 text-dark fw-regular">
                        €{otherMembers.totalPaid.toFixed(2)}
                        </p>
                      </Link>
                    </td>
                    <td >
                    <Link to="/other-rate-members">
                        <div className="d-flex align-items-center">
                          <span className={`flex-shrink-0 me-1 me-md-3 ${otherMembers.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}></span>
                          <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                          €{otherMembers.totalDue.toFixed(2)}
                          </p>
                        </div>
                      </Link>
                    </td>
                    <td>
                    <Link to="/other-rate-members">
                        <div className="d-flex align-items-center">
                          <span className={`flex-shrink-0 me-1 me-md-3  ${otherMembers.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"}  rounded-circle`}></span>
                          <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                            €{otherMembers.totalOverdue > 0? otherMembers.totalOverdue.toFixed(2)  : 0}
                          </p>
                        </div>
                      </Link>
                    </td>
                  </tr>} 
              </tbody>
            }

            {/* TBODY UNIT */}

            { view === "unità" &&
              <tbody>
              {filteredDataUnit.length > 0 && filteredDataUnit.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td className="position-relative">
                        { item.owners && <button
                            type="button"
                            onClick={() => handleClick(index)} // Passa l'indice dell'elemento
                            className="updwon-icon border-0 bg-transparent"
                          >
                                                       <i className={`bi bi-caret-${activeRows.includes(index) ? 'up-fill' : 'down-fill'}`}></i>

                          </button>}
                          <Link to={`/unit-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-1 me-md-3">
                                <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 p-2"`}>
                                  <img loading="lazy" src={homeImg} alt="user" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  {item.name?.substr(0,15)}...
                                </p>
                              </div>
                            </div>
                          </Link>
                        </td>

                        <td className="d-none d-md-table-cell">
                        <Link to={`/unit-details/${item.id}`}>
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              {item.type == "unit" && "Unità"}
                              {item.type == "condominium" && "Condominio"}
                            </p>
                          </Link>
                        </td>

                        <td className="d-none d-md-table-cell">
                        <Link to={`/unit-details/${item.id}`}>
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                            € {item.totalDue?.toFixed(2)}
                            </p>
                          </Link>
                        </td>
                        <td className="d-none d-md-table-cell">
                        <Link to={`/unit-details/${item.id}`}>
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                            € {item.totalPaid?.toFixed(2)}
                            </p>
                          </Link>
                        </td>
                        <td>
                        <Link to={`/unit-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                              <span className={`flex-shrink-0 me-1 me-md-3 ${item.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                              € {item.totalDue?.toFixed(2)}
                              </p>
                            </div>
                          </Link>
                        </td>
                        <td>
                        <Link to={`/unit-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                              <span className={`flex-shrink-0 me-1 me-md-3 ${item.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {item.totalOverdue > 0? item.totalOverdue?.toFixed(2)  : 0}
                              </p>
                            </div>
                          </Link>
                        </td>
                      </tr>
                      {item.owners && item.owners.length > 0 &&  
                        item.owners.map(owner => {
                          return  <tr
                          className={activeRows.includes(index) ? "" : "showrow-click"}
                        >
                          <td className="position-relative">
                          <Link to={`/members-details/${owner.id}`}>  
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1 me-md-3">
                                  <div className={`name-al ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden color1`}>
                                    <h6 className="text-white fs-14 fs-lg-16 fw-regular">
                                      {owner.name?.charAt(0)}
                                    </h6>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  {owner.surname} {owner.name.substr(0,15)}...
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${owner.id}`}>  
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Proprietario
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${owner.id}`}>  
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {owner.totalDue.toFixed(2)}
                              </p>
                            </Link>
                          </td>
  
                          <td className="d-none d-md-table-cell">
                          <Link to={`/members-details/${owner.id}`}>  
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {owner.totalPaid.toFixed(2)}
                              </p>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/members-details/${owner.id}`}>  
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${owner.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {owner.totalDue.toFixed(2)}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/members-details/${owner.id}`}>  
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${owner.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {owner.totalOverdue > 0? owner.totalOverdue.toFixed(2)  : 0} 
                                </p>
                              </div>
                            </Link>
                          </td>
                        </tr>
                        })
                      }
                      {item.installments && item.installments.length > 0 &&  
                        item.installments.map(installment => {
                          return  (installment.owners.length == 0 && installment.suppliers.length == 0 && installment.coductors.suppliers.length == 0) ?
                          <tr
                          className={isActive ? "" : "showrow-click"}
                        >
                          <td className="position-relative">
                          <Link to={`/unit-details/${item.id}`}>                            
                          <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-1 me-md-3">
                                <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 p-2`}>
                                  
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  Altro
                                </p>
                              </div>
                            </div>
                          </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Altro <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>
                                  Questa rata è associata a questo immobile, ma non è associata a nessun membro
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-1"></i>
                                </OverlayTrigger>
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {installment.totalDue.toFixed(2)}
                              </p>
                            </Link>
                          </td>
  
                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${item.id}`}>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {installment.totalPaid.toFixed(2)}
                              </p>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/unit-details/${item.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${installment.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {installment.totalDue.toFixed(2)}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/unit-details/${item.id}`}>
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${installment.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {installment.totalOverdue > 0? installment.totalOverdue.toFixed(2)  : 0} 
                                </p>
                              </div>
                            </Link>
                          </td>
                          </tr> :
                          ""
                        })
                      }
                    </>
                  );
              })}
                 {props.unitsDataUnassigned && props.unitsDataUnassigned.length >0 && !isEmptyObject(otherUnits) && (otherUnits.totalDue || otherUnits.totalPaid)  &&  <tr>
                    <td className="position-relative">
                    <Link to="/other-rate-units">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 me-1 me-md-3">
                            <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 p-2`}>
                                
                              </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Altro
                            </p>
                          </div>
                        </div>
                      </Link>
                    </td>

                    <td className="d-none d-md-table-cell">
                    <Link to="/other-rate-units">
                        <p className="fs-14 fs-lg-16 text-dark fw-regular">
                          Altro <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>
                                  Queste sono rate che non state associate a nessuna unità o condominio
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-1"></i>
                                </OverlayTrigger>
                        </p>
                      </Link>
                    </td>

                    <td className="d-none d-md-table-cell">
                    <Link to="/other-rate-units">
                        <p className="fs-14 fs-lg-16 text-dark fw-regular">
                        €{otherUnits.totalDue.toFixed(2)}
                        </p>
                      </Link>
                    </td>
                    <td className="d-none d-md-table-cell">
                    <Link to="/other-rate-units">
                        <p className="fs-14 fs-lg-16 text-dark fw-regular">
                        €{otherUnits.totalPaid.toFixed(2)}
                        </p>
                      </Link>
                    </td>
                    <td>
                    <Link to="/other-rate-units">
                        <div className="d-flex align-items-center">
                          <span className={`flex-shrink-0 me-1 me-md-3 ${otherUnits.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                          <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                          €{otherUnits.totalDue.toFixed(2)}
                          </p>
                        </div>
                      </Link>
                    </td>
                    <td>
                    <Link to="/other-rate-units">
                        <div className="d-flex align-items-center">
                          <span className={`flex-shrink-0 me-1 me-md-3 ${otherUnits.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                          <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                            €{otherUnits.totalOverdue > 0? otherUnits.totalOverdue.toFixed(2)  : 0}
                          </p>
                        </div>
                      </Link>
                    </td>
                  </tr>} 
              </tbody>
            }

             {/* TBODY GESTIONE */}

          { view === "gestioni" &&
              <tbody>
              {filteredDataGest.length > 0 && filteredDataGest.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td className="position-relative">
                        { item.units && <button
                            type="button"
                            onClick={() => handleClick(index)} // Passa l'indice dell'elemento
                            className="updwon-icon border-0 bg-transparent"
                          >
                            <i className={`bi bi-caret-${activeRows.includes(index) ? 'up-fill' : 'down-fill'}`}></i>
                          </button>}
                          <Link to={`/management-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 me-1 me-md-3">
                                <div className={`user-img ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden fw-100 p-2"`}>
                                  <img loading="lazy" src={homeImg} alt="user" />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                  {item.name?.substr(0,15)}...
                                </p>
                              </div>
                            </div>
                          </Link>
                        </td>

                        <td className="d-none d-md-table-cell">
                          <Link to={`/management-details/${item.id}`}>
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Gestione
                            </p>
                          </Link>
                        </td>

                        <td className="d-none d-md-table-cell">
                        <Link to={`/management-details/${item.id}`}>
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                            € {item.totalDue?.toFixed(2)}
                            </p>
                          </Link>
                        </td>
                        <td className="d-none d-md-table-cell">
                        <Link to={`/management-details/${item.id}`}>
                            <p className="fs-14 fs-lg-16 text-dark fw-regular">
                            € {item.totalPaid?.toFixed(2)}
                            </p>
                          </Link>
                        </td>
                        <td>
                        <Link to={`/management-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                              <span className={`flex-shrink-0 me-1 me-md-3 ${item.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                              € {item.totalDue?.toFixed(2)}
                              </p>
                            </div>
                          </Link>
                        </td>
                        <td>
                        <Link to={`/management-details/${item.id}`}>
                            <div className="d-flex align-items-center">
                              <span className={`flex-shrink-0 me-1 me-md-3 ${item.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                              <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {item.totalOverdue > 0? item.totalOverdue?.toFixed(2)  : 0}
                              </p>
                            </div>
                          </Link>
                        </td>
                      </tr>
                      {item.units && item.units.length > 0 &&  
                        item.units.map(unit => {
                          return  <tr
                          className={activeRows.includes(index) ? "" : "showrow-click"}
                        >
                          <td className="position-relative">
                          <Link to={`/unit-details/${unit.id}`}>  
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 me-1 me-md-3">
                                  <div className={`name-al ${isMinWidth767? "w-44 h-44" : "w-24 h-24"} rounded-circle overflow-hidden color1`}>
                                    <h6 className="text-white fs-14 fs-lg-16 fw-regular">
                                      {unit.name?.charAt(0)}
                                    </h6>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="fs-14 fs-lg-16 text-dark fw-regular">
                                   {unit.name.substr(0,15)}...
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${unit.id}`}>  
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              Immobile
                              </p>
                            </Link>
                          </td>

                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${unit.id}`}>  
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {unit.totalDue.toFixed(2)}
                              </p>
                            </Link>
                          </td>
  
                          <td className="d-none d-md-table-cell">
                          <Link to={`/unit-details/${unit.id}`}>  
                              <p className="fs-14 fs-lg-16 text-dark fw-regular">
                              € {unit.totalPaid.toFixed(2)}
                              </p>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/unit-details/${unit.id}`}>  
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${unit.totalDue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {unit.totalDue.toFixed(2)}
                                </p>
                              </div>
                            </Link>
                          </td>
                          <td>
                          <Link to={`/unit-details/${unit.id}`}>  
                              <div className="d-flex align-items-center">
                                <span className={`flex-shrink-0 me-1 me-md-3 ${unit.totalOverdue > 0 ? "bg-danger" : "bg-success"} ${isMinWidth767? "w-22 h-22" : "w-10 h-10"} rounded-circle`}></span>
                                <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1">
                                € {unit.totalOverdue > 0? unit.totalOverdue.toFixed(2)  : 0} 
                                </p>
                              </div>
                            </Link>
                          </td>
                        </tr>
                        })
                      }
                     
                    </>
                  );
              })}
                
              </tbody>
            }

            {/* TOTALE MEMBRI */}
            {view == "membri" && 
            <tfoot className="five-box">
              <tr>
                <td className="d-none d-md-table-cell"></td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                    Totale 
                  </p>
                </td>
                <td className="d-none d-md-table-cell"> 
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1 m-0">
                  €{totaleMembri.totalDue}
                  </p>
                </td>
                <td className="d-none d-md-table-cell">
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0 ">
                  €{totaleMembri.totalPaid}
                  </p>
                </td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0 ">
                  €{totaleMembri.totalDue}
                  </p>
                </td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                  €{totaleMembri.totalOverdue}
                  </p>
                </td>
              </tr>
            </tfoot>
            }

            {/* TOTALE UNIT */}
            {view == "unità" && 
            <tfoot className="five-box">
              <tr>
                <td className="d-none d-md-table-cell"></td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                    Totale 
                  </p>
                </td>
                <td className="d-none d-md-table-cell">
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1 m-0">
                    € {totaleUnits.totalDue}
                  </p>
                </td>
                <td className="d-none d-md-table-cell">
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                    € {totaleUnits.totalPaid}
                  </p>
                </td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                  € {totaleUnits.totalDue}
                  </p>
                </td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                  € {totaleUnits.totalOverdue}
                  </p>
                </td>
              </tr>
            </tfoot>
            }

             {/* TOTALE GESTIONI */}
             {view == "gestioni" && 
            <tfoot className="five-box">
              <tr>
                <td className="d-none d-md-table-cell"></td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                    Totale 
                  </p>
                </td>
                <td className="d-none d-md-table-cell">
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1 m-0">
                    € {totalAmountsGestioni?.totalDue}
                  </p>
                </td>
                <td className="d-none d-md-table-cell">
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                    € {totalAmountsGestioni?.totalPaid}
                  </p>
                </td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                  € {totalAmountsGestioni?.totalDue}
                  </p>
                </td>
                <td>
                  <p className="fs-14 fs-lg-16 text-dark fw-regular flex-grow-1  m-0">
                  € {totalAmountsGestioni?.totalOverdue}
                  </p>
                </td>
              </tr>
            </tfoot>
            }
            
          </table>
        </div>
       {/* <MediaQuery maxWidth={767}>
        <div className="d-flex flex-row-reverse mb-4 rel">
          <button
            type="button"
            className="bg-white border rounded-3 p-2"
            onClick={filterBtn}
          >
            <img loading="lazy" src={dropLines} alt="lines" />
          </button>
          <div
            className={
              isActive2
                ? "d-block bg-white rounded-3 shadow p-3 position-absolute end-0 zi-5 filter-mob"
                : "d-none"
            }
          >
            <div className="d-flex flex-column top-filter">
              <p className="fs-14 text-dark fw-regular mb-2">Filtra per</p>
              <div className="d-flex align-items-center">
                <div className="d-flex me-2 active">
                  <div
                    onClick={() => handleView("membri")}
                    className="mebtn  fs-14 text-white py-2 px-3 rounded-3 border-0"
                  >
                    Membri
                  </div>
                </div>
                <div className="">
                  <div
                    onClick={() => handleView("membri")}
                    className="unibtn fs-14 text-dark bg-white rounded-3 py-2 px-3 d-inline-block"
                  >
                    Unità
                  </div>
                </div>
              </div>
              <div className="d-block mt-4">
                <div
                  className="d-flex justify-content-between"
                  onClick={memBtn}
                >
                  <p className="fs-14 text-dark fw-regular mb-2">MEMBRI</p>
                  <button type="button" className="bg-transparent border-0">
                    <i className="fal fa-angle-down"></i>
                  </button>
                </div>
                <div className={isActive3 ? "d-flex" : "d-none"}>
                  <CheckBoxMember />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between total-bal-green mb-4 rounded-3 px-4 py-2">
          <p className="fs-14 fs-lg-16 fw-regular lh-base">Saldo Totale</p>
          <p className="fs-20 fw-regular">0€</p>
        </div>

        <div className="d-block ms-4 ms-md-5 mb-3">
          <div className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2">
            <button
              type="button"
              onClick={handleClick}
              className="updwon-icon border-0 bg-transparent"
            >
              {isActive ? (
                <i className="fas fa-caret-up"></i>
              ) : (
                <i className="fas fa-caret-down"></i>
              )}
            </button>

            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="name-al w-44 h-44 rounded-circle overflow-hidden color1">
                  <h6 className="text-white fs-14 fs-lg-16 fw-regular">A</h6>
                </div>
                
              </div>
              <div className="flex-grow-1">
                <p className="fs-14 fs-lg-16 text-dark fw-regular">Mario Rossi</p>
              </div>
            </div>
            <p className="fs-14 fs-lg-16 fw-regular text-green fs-24">0€</p>
          </div>
          <div className={isActive ? "" : "showrow-click"}>
            <Link to="/members-details">
              <div className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    

                    <div className="user-img w-44 h-44 rounded-circle overflow-hidden fw-100 p-2">
                      <img loading="lazy" src={homeImg} alt="user" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="fs-14 fs-lg-16 text-dark fw-regular">Mario Rossi</p>
                  </div>
                </div>
                <p className="fs-14 fs-lg-16 fw-regular text-green fs-24">0€</p>
              </div>
            </Link>
            <Link to="/members-details">
              <div className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    
                    <div className="user-img w-44 h-44 rounded-circle overflow-hidden fw-100 p-2">
                      <img loading="lazy" src={homeImg} alt="user" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="fs-14 fs-lg-16 text-dark fw-regular">Mario Rossi</p>
                  </div>
                </div>
                <p className="fs-14 fs-lg-16 fw-regular text-green fs-24">0€</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="d-block ms-4 ms-md-5 mb-3">
          <div className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2">
            <button
              type="button"
              onClick={handleClick1}
              className="updwon-icon border-0 bg-transparent"
            >
              {isActive1 ? (
                <i className="fas fa-caret-up"></i>
              ) : (
                <i className="fas fa-caret-down"></i>
              )}
            </button>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="name-al w-44 h-44 rounded-circle overflow-hidden color1">
                  <h6 className="text-white fs-14 fs-lg-16 fw-regular">A</h6>
                </div>
               
              </div>
              <div className="flex-grow-1">
                <p className="fs-14 fs-lg-16 text-dark fw-regular">Mario Rossi</p>
              </div>
            </div>
            <p className="fs-14 fs-lg-16 fw-regular text-green fs-24">0€</p>
          </div>
          <div className={isActive1 ? "" : "showrow-click"}>
            <Link to="/members-details">
              <div className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    

                    <div className="user-img w-44 h-44 rounded-circle overflow-hidden fw-100 p-2">
                      <img loading="lazy" src={homeImg} alt="user" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="fs-14 fs-lg-16 text-dark fw-regular">
                      Mario Rossi 2
                    </p>
                  </div>
                </div>
                <p className="fs-14 fs-lg-16 fw-regular text-green fs-24">10€</p>
              </div>
            </Link>
            <Link to="/members-details">
              <div className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    

                    <div className="user-img w-44 h-44 rounded-circle overflow-hidden fw-100 p-2">
                      <img loading="lazy" src={homeImg} alt="user" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="fs-14 fs-lg-16 text-dark fw-regular">
                      Mario Rossi 1
                    </p>
                  </div>
                </div>
                <p className="fs-14 fs-lg-16 fw-regular text-green fs-24">10€</p>
              </div>
            </Link>
          </div>
        </div>
        
      </MediaQuery> */}
    </>
  );
}
