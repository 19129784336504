import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoAlert () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Alert"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            
            <TreeItem nodeId="1" label="Scadenzario" >
              <TreeItem nodeId="2" label="Filtro, Ricerca, Ordina" />
              <TreeItem nodeId="3" label="Creazione di un elemento" >
                <TreeItem nodeId="4" label="Ricorrenza" />
                <TreeItem nodeId="5" label="Notifiche" />
                <TreeItem nodeId="6" label="Altro" />
              </TreeItem>
              <TreeItem nodeId="7" label="Visualizzazione di un elemento" >
                <TreeItem nodeId="8" label="Marca come completato" />
                <TreeItem nodeId="9" label="Archivia" />
              </TreeItem>
              <TreeItem nodeId="10" label="Visualizza dettagli" >
                <TreeItem nodeId="11" label="Modifica elemento" />
              </TreeItem>
             
            </TreeItem>

            <TreeItem nodeId="12" label="Calendario" >
            <TreeItem nodeId="13" label="Aggiunta evento" />
            <TreeItem nodeId="14" label="Dettagli evento" />

            </TreeItem>

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Alert</h1>
      <h2 style={{ color:'transparent', height: 0}}>Alert</h2>

      <p className="mt-3">La sezione alert ti consente di salvare e visionare le tue scadenze e gli eventi</p>
     

      <h2 id="1">Scadenzario</h2>
      <p>
        Nella sezione scadenzario potrai inserire eventi e scadenze da ricordare.
      </p>

      <h3 id="2">Filtro, Ricerca, Ordina</h3>
      <p>Poco sotto, in alto a destra troverai la barra di ricerca, per semplificare la ricerca di una scadenza specifico tramite titolo. </p>

      <p> In alternativa trovi a sinistra il bottone filtra (icona dell’imbuto) che ti permette di filtrare gli elementi per i seguenti criteri: 
      </p>
      <ul>
        <li><p >Periodo</p></li>
        <li><p >Stato</p></li>
        <li><p >Sezione di riferimento</p></li>
      </ul>
      <img loading="lazy" src="https://i.postimg.cc/gkV8xmXY/filtra.png" />



      <p >A fianco al pulsante filtra, trovi il
        pulsante ordina (icona con tre righe orizzontali) che ti permette di
        </p>
        <ul>
          <li><p >Ordinare gli elementi in modo
          alfabetico dalla A alla Z o viceversa</p></li>
          <li><p >Ordinare gli elementi mettendo
          prima quelli creati più di recente </p></li>
          <li><p >Ordinare gli elementi per data di inizio, i maniere ascendente o discendente </p></li>
        </ul>
      <img loading="lazy" src="https://i.postimg.cc/CKQf3Kt4/ordina.png" />

      <p>A destra prima del bottone filtro, trovi il bottone “CAMBIO VISUALIZZAZIONE”, che ti permette di passare dalla visione lista a quella tabellare e viceversa.</p>
      <img loading="lazy" src="https://i.postimg.cc/QtKWh3ZZ/cambia-visualizzazione.png"  />

      <h3 id="3">Creazione di un elemento</h3>
      <img loading="lazy" src="https://i.postimg.cc/jqZbnBv5/add-event.png" />
      <p>Clicca sul pulsante + in alto a destra, e inserirsci i dettagli dell'evento.</p>
      <ul>
        <li><p>Titolo</p></li>
        <li><p>Sezione di riferimento, puoi definire a quale sezione di Real Meta è relativo a questo evento (Unità e Stabili, Affitti, SAL, Scadenze Personali)</p></li>
        <li><p>Data di inizio</p></li>
        <li><p>Data di fine</p></li>
      </ul>
      <img loading="lazy" src="https://i.postimg.cc/sDxRYK0J/nuovo-evento.png" />

        <p className="subtitle" id="4">Ricorrenza</p>
        <p>Si tratta di un evento ricorrente? Anziché creare più volte lo stesso evento, puoi impostare di seguito i dettagli di ricorrenza di questo. Se ad esempio si tratta di un evento che si svolge ogni 2 mesi fino al 31/12/2023, dovrai selezionare: <br/>
                            Frequenza:  "Mesi", <br/>
                            Ogni: 2, <br/>
                            Fine Ricorrenza: "31/12/2023" </p>
        <img loading="lazy" src="https://i.postimg.cc/rsn26tzW/ricorrenza.png" />

        <p className="subtitle" id="5">Notifiche</p>
          <p>In questa sezione puoi definire:</p>
          <p>-La tipologia di notifiche relative alla scadenza in questione (sul gestionale o sull'email)  </p>
          <p>-Quanti giorni prima dell'evento vuoi essere avvisato</p>
          <img loading="lazy" src="https://i.postimg.cc/0yLSJDSy/notifiche.png" />


        <p className="subtitle" id="6">Altro</p>
        <p>Puoi condividere l'evento con altri soggetti, inserendo le email relative.</p>

        <img loading="lazy" src="https://i.postimg.cc/MGVQ0H0b/altro.png" />

        <h3 id="7" >Visualizzazione di un elemento</h3>
        <p>Potrai visualizzare gli eventi creati sotto forma di lista</p>
        <img loading="lazy" src="https://i.postimg.cc/nz1tkWJ3/card-event.png" />

        <p>Per ogni evento potrai visualizzare le informazioni principali</p>

        <ul>
        <li><p>Sezione di riferimento</p></li>
        <li><p>Titolo</p></li>
        <li><p>Data di inizio e Fine</p></li>
    
      </ul>

      <p className="subtitle" id="8">Marca una scadenza come completata</p>

      <p>Se hai completato una scadenza, puoi marcarla come completata cliccando sul bottone di spunta a sinistra</p>

      <img loading="lazy" src="https://i.postimg.cc/WbycXrBc/completo.gif" />

      <Tips variant="info" content="Quando completi una scadenza, l'elemento viene trasportato in fondo alla lista." />

      <p className="subtitle" id="9">Archivia una scadenza</p>
      <p>Cliccando sul secondo bottone della card (archivio), l'elemento verrà rimosso dalla sezione scadenzario e archiviato. </p>
      <p>Potrai visualizzare l'elemento andando in Impostazioni/Archivio </p>

      <h3 id="10">Visualizza dettagli</h3>
      <p>Clicca su una card per visualizzare i dettagli dell'evento creato</p>

      <p className="subtitle" id="11">Modifica elemento</p>
      <p>Clicca sul bottone azzurro "Modifica" in alto a destra, potrai modificare le informazioni relative all'evento.</p>
      <p>Per salvare le modifiche apportate clicca sul pulsante "Salva modifiche"</p>
      <p>Se non desideri salvare le modifiche effettuate clicca sul pulsante annulla</p>
      
      <img loading="lazy" src="https://i.postimg.cc/6pTSQJVN/annulla-modifiche.gif" />
      




      <h2 id="12">Calendario</h2>
      <p>La sezione calendario ti permette di visualizzare gli eventi creati nella sezione "Scadenzario" in 3 modalità</p>
      <ul>
        <li><p>Mensile</p></li>
        <li><p>Settimanale</p></li>
        <li><p>Giornaliero</p></li>
    
      </ul>
      <img loading="lazy" src="https://i.postimg.cc/0NRRqv5t/modalita.gif"/>


      <h3 id="13">Aggiunta evento/scadenza</h3>
      <p>Puoi aggiungere un nuovo elemento cliccando sul bottone + in alto a sinistra</p>

    
      <h3 id="14">Dettagli evento/scadenza</h3>
      <p>Posizionandoti col mouse vicino ad un evento sul calendario, potrai avere visione delle informazioni relative all'evento.</p>
      <p>Se desideri visualizzare più informazioni, puoi cliccare sull'evento e verrai rendirizzato alla pagina di dettaglio.</p>


     
      <img loading="lazy" src="https://i.postimg.cc/52FdGyJY/dettaglio-evento.gif" />

    </div>
  );
}

