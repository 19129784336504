import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

import CustomTooltip from "../../../CustomTooltip";
import CustomToggle from "../../../CustomToggle";

import CustomBadge from "../../../CustomBadge";


import { marketRefType, 
        bareOwnershipType, 
        leaseProfitabilityType, 
        rentalStateType, 
        wineryType,
        parkingAccessibilityType,
        boxType,
        boxAccessibilityType,
        boxLocationType,
        possessionType,
        condominialParkingSpaceType, 
        condominialParkingType,
        floorType, 
        ceilingHeightType,
        brightnessType,
        noisinessType,
        internalLevelsType,
        internalDistributionType,
        coveredMqRoomsRatioType,
        coveredMqBathsRatioType,
        finishingQualityType,
        status_type,
        energeticClassType,
        buildingAgeType,
        buildingLocationType,
        buildingTypologyType,
        building_status_type,
        heatingTypeType,
        office_position,
        shopFloor,
        shopVisibility,
        buildingTypologyTypeNegozi,
        windowPositionType,
        commercialVisibilityType,
        boxTypeNegozi,
        floorTypeBox,
        autorimessaType,
        boxHeight,
        floorMag,
        entryType,
        locationVisibilityType,
        buildingType

    } from "./options";


export default function Magazzini (props) {
    return (
        <>
        {/* MQ COPERTI */}
        <Accordion.Item eventKey="6" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="6" activeKey={props.activeKey}>Mq coperti</CustomToggle>
        </Card.Header>
        <Accordion.Body>
        <div className="row mt-1">
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Mq coperti  
                <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="grossCoveredMq" valMin={0} valMax={1} />
              </label>
              <input
                type="text"
                name="grossCoveredMq"
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                onChange={(e) => props.handleValueChange("grossCoveredMq", 'value', e.target.value)}
                value={props.formValues["grossCoveredMq"].value}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Mq tettoie 
              </label>
              <input
                type="text"
                name="canopiesMq"
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                onChange={(e) => {
                  props.onChange(e);
                }}
                value={props.formData.canopiesMq}
              />
            </div>
          </div>
        </div>
        </Accordion.Body>
        </Accordion.Item>
                
         {/* CARATTERISTICHE PRINCIPALI */}
        <Accordion.Item eventKey="10" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="10" activeKey={props.activeKey}>Caratteristiche Principali</CustomToggle>
            </Card.Header>
            <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Piano  
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="floor" valMin={-0.1} valMax={0.1} />
                </label>
                <select
                  name="floor"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("floor", 'value', e.target.value)}
                value={props.formValues["floor"].value}
                >
                  {floorMag.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipo di ingresso al laboratorio
                  </label>
                  <select
                    name="entryType"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                    value={props.formData.entryType}
                    onChange={props.onChange}
                  >
                    {entryType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Visibilità dell’ubicazione
                </label>
                <select
                  name="locationVisibility"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  value={props.formData.locationVisibility}
                  onChange={props.onChange}
                >
                  {locationVisibilityType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          </Accordion.Body>
        </Accordion.Item>
        
        {/* EDIFICIO */}
        <Accordion.Item eventKey="29" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="29" activeKey={props.activeKey}>Dati edificio</CustomToggle>
            </Card.Header>
              <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Tipo di edificio 
                </label>
                <select
                  name="buildingType"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  value={props.formData.buildingType}
                  onChange={props.onChange}
                >
                  {buildingType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Stato conservazione edificio 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}  name="buildingState" valMin={-0.05} valMax={0.05} />

                </label>
                <select
                  name="buildingState"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("buildingState", 'value', e.target.value)}
                  value={props.formValues["buildingState"]?.value}
                >
                  {building_status_type.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3">
          <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Età edificio 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}  name="buildingAge" valMin={-0.05} valMax={0.05} />
                </label>
                <select
                  name="buildingAge"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("buildingAge", 'value', e.target.value)}
                  value={props.formValues["buildingAge"]?.value}
                >
                  {buildingAgeType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {menu}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          </Accordion.Body>
          </Accordion.Item>

        </>
    )
}