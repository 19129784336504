import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useRef, useEffect, useState } from 'react';

import dashboard from '../../assets/images/attori.png'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import unit from  "../../assets/images/un.png"
import rent from  "../../assets/images/aff.png"
import calc from  "../../assets/images/calc.png"
import rend from  "../../assets/images/rend.png"
import calen from  "../../assets/images/calen.png"
import { useMediaQuery } from 'react-responsive';
import ResponsiveNavbar from './NavRm';

import { Link } from 'react-router-dom';
import logo from "../../assets/images/Group 1189.svg"
import Footer from './Footer';
import "../../assets/css/Home.css"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import attori from  "../../assets/images/attori-min.png"
import gestore from  "../../assets/images/gestore.png"



const useRefDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 })

    useEffect(() => {
        if (ref.current) {
            const { current } = ref
            const boundingRect = current.getBoundingClientRect()
            const { width, height } = boundingRect
            setDimensions({ width: Math.round(width), height: Math.round(height) })
        }
    }, [])

    useEffect(() => {
        // Definisce la funzione da chiamare quando la finestra viene ridimensionata
        const handleResizeWindow = () => {
            if (ref.current) {
                const { current } = ref
                const boundingRect = current.getBoundingClientRect()
                const { width, height } = boundingRect
                setDimensions({ width: Math.round(width), height: Math.round(height) })
            }
        };
    
        window.addEventListener('resize', handleResizeWindow);
    
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [ref]); 

    return dimensions
}

export default function Attori (){

    const refContainer = useRef();
    const boxRef = useRef()
    const dimensions = useRefDimensions(refContainer)
    const boxDimension = useRefDimensions(boxRef)

    console.log(dimensions)
    //GESTIONE MODULI

    const [isOpen, setIsOpen] = useState(null)

    function handleOpening(id) {
        setIsOpen(id)
    }

    function handleClosing() {
        setIsOpen(null)
    }

    const array = [
        {
            title: "Log di sistema",
            subtitle:"Inventario completo del patrimonio immobiliare",
            icon: "bi bi-houses",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Database crittografati",
            subtitle:"Sottotitolo 2",
            icon: "bi bi-file-earmark-break",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Accessi e Profilatura",
            subtitle:"Sottotitolo 3",
            icon: "bi bi-buildings",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        }
        
    ]

    //IMPOSTO BREAKPOINTS

    const isLg = useMediaQuery({
        query: "(min-width: 992px)",
    });

    const isMd = useMediaQuery({
        query: "(min-width: 768px)",
    });

    let secondPositionIndex
    let thirdPositionIndex
    if(isLg){
        secondPositionIndex = array.map((element, index) => {
            const colIndex = index % 3;
            // Se l'indice della colonna è 1 (seconda posizione), restituisci index
            if (colIndex === 1) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

        thirdPositionIndex = array.map((element, index) => {
            // Trovo indice della colonna corrente nella riga
            const colIndex = index % 3;
            // Se l'indice della colonna è 2 (quindi terza posizione), restituisci l'indice
            if (colIndex === 2) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

    } else if(isMd){    
        secondPositionIndex = array.map((element, index) => {
            // Calcola l'indice della colonna corrente nella riga
            const colIndex = index % 2;
            // Se l'indice della colonna è 1 (seconda posizione), restituisci l'indice
            if (colIndex === 1) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

        thirdPositionIndex = []
    } else {
        secondPositionIndex = []
        thirdPositionIndex = []
    }

    console.log(secondPositionIndex)

    return (
        <div style={{background:'#fff'}}>
            <div >
                <div style={{}}>
                    <Container className="px-3 px-md-0 overflow-hidden">

                    <ResponsiveNavbar/>


                        <Row className="mt-5 align-items-center mx-auto gy-4 text-center" style={{maxWidth: "1100px"}}>
                                <div>
                                <h1 className='fs-32 fs-md-46 syne-font mb-2'>
                                    Gli Attori di Real Meta
                                </h1>                               
                                        <p className='fs-24 mb-5'>Un account per ogni esigenza
                                    </p>
                                    <Image src={attori} style={{width: "100%"}} loading='lazy' />
                                </div>
                            
                        </Row>

                        <Row className="py-5 align-items-center justify-content-between puntoForza1 mx-auto" style={{maxWidth: "1000px"}}>
                                <Col md={6}>
                                <div>
                                <h1 className="fs-32 fs-md-46 syne-font mb-1">Gestore</h1>
                                    <p>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                </div>
                                </Col>
                                <Col md={6}>
                                    <div className='text-end'>

                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="50%" className="rounded-circle d-none d-md-block ms-auto"  loading='lazy'/>
                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle d-block d-md-none" loading='lazy'/>

                                    </div>
                                </Col>
                            </Row>

                        <Row className="mx-auto my-5 puntoForza "  style={{maxWidth: "1000px"}}>
                            <Row className="py-5 align-items-center justify-content-between puntoForza2">
                                    <Col  md={6} className='order-2 order-md-1'>
                                    <div>
                                        <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="50%" className="rounded-circle d-none d-md-block"/>
                                        <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle d-block d-md-none"/>
                                    </div>
                                    </Col>

                                    <Col  md={6} className='order-1 order-md-2' style={{maxWidth: "1000px"}}>
                                    <div>
                                        <h1 className="fs-32 fs-md-46 syne-font mb-1">Collaboratore</h1>
                                        <p>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                    </div>
                                    </Col>
                            </Row>
                        </Row> 

                        <Row className="py-5 align-items-center justify-content-between puntoForza1 mx-auto " style={{maxWidth: "1000px"}}>
                                <Col md={6}>
                                <div>
                                    <h1 className="fs-32 fs-md-46 syne-font mb-1">Proprietario</h1>
                                    <p>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                    <Link to="/proprietario">
                                        <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="mt-4">Scopri di più</Button>
                                    </Link>
                                </div>
                                </Col>
                                <Col md={6}>
                                    <div className='text-end'>

                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="50%" className="rounded-circle d-none d-md-block ms-auto"/>
                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle d-block d-md-none"/>

                                    </div>
                                </Col>
                            </Row>

                        <Row className="mx-auto my-5 puntoForza"  style={{maxWidth: "1000px"}}>
                            <Row className="py-5 align-items-center justify-content-between puntoForza2">
                                    <Col  md={6} className='order-2 order-md-1'>
                                    <div>
                                        <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="50%" className="rounded-circle d-none d-md-block"/>
                                        <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle d-block d-md-none"/>
                                    </div>
                                    </Col>

                                    <Col  md={6} className='order-1 order-md-2'>
                                    <div>
                                        <h1 className="fs-32 fs-md-46 syne-font mb-1">Inquilino</h1>
                                        <p>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                    </div>
                                    </Col>
                            </Row>
                        </Row> 

                        <Row className="py-5 align-items-center justify-content-between puntoForza1 mx-auto" style={{maxWidth: "1000px"}}>
                                <Col md={6}>
                                <div>
                                    <h1 className="fs-32 fs-md-46 syne-font mb-1">Fornitore</h1>
                                    <p>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                                    <Link to="/fornitore">
                                        <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="mt-4">Scopri di più</Button>
                                    </Link>
                                </div>
                                
                                </Col>
                                <Col md={6}>
                                    <div className='text-end'>

                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="50%" className="rounded-circle d-none d-md-block ms-auto"/>
                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle d-block d-md-none"/>

                                    </div>
                                </Col>
                            </Row>

                        <Footer />

                    </Container>
                </div>
            </div>
        </div>
    )
}

