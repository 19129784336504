import estate_img from "../../../assets/images/real_estate.png";
import Form from "react-bootstrap/Form";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import User from "../../../assets/images/empty-folder.png";
import { useState, useEffect } from "react";
import mimeTypes from "../../../allegatiType";
import axios from "axios";
import api, { affitti_endpoint,  BASE_URL } from "../../../api/api";

const AttachmentsE = (props) => {
	const [data, setData] = useState({});

	//GESTIONE NUOVI ALLEGATI 
	const [newAttachments, setNewAttachments] = useState([]);

	const handleChange = (e) => {
		let temp = [...newAttachments];
		setNewAttachments([...temp, ...e.target.files]);
	};

	const init = () => {
		setData({ attachment: props.formData.attachment });
		setNewAttachments([])
	};
	
	useEffect(() => {
		init();
	}, [props]);

	useEffect(() => {
		if (props.saveState === true) {
			props.update({  newAttachment: [ ...newAttachments] });
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const setFileName = (index, new_name) => {
		let temp = [...data.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		setData({ ...data, attachment: temp });
	};
	const deleteFile = (index) => {
		let temp = [...data.attachment];
		temp.splice(index, 1);
		setData({ ...data, attachment: temp });
	};
/* 	const handleChange = (e) => {
		let temp = [...data.attachment];
		setData({ ...data, attachment: [...temp, ...e.target.files] });
	}; */

	const downloadFile = async (name) => {
		const fileUrl = `${BASE_URL}api/v1/rent/download/${props.id}/${name}`;
	
		const response = await axios({
		url: fileUrl,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${
			JSON.parse(localStorage.getItem("user"))?.token
			}`,
			"Content-Type": `application/json`,
			
		},
		});
	
		// ottieni l'estensione del file
		const fileExtension = name.split('.').pop();
	
		const mimeType = mimeTypes[fileExtension];
	
		// crea un nuovo Blob con il tipo MIME corretto
		const file = new Blob([response.data], { type: mimeType });
	
		// crea un URL per il Blob
		const fileURL = URL.createObjectURL(file);
	
		// scarica il file
		window.open(fileURL);
	};

	console.log(newAttachments)

	return (
		<>
			<Form>
				<fieldset disabled={props.edit_mode ? false : true}>
					<div className="edit-content-container mt-3">
						<div className="form-item-align-box-white d-block">
							{props.edit_mode && (
								<div className="unit-btn-group mb-4">
									<label
										type="button"
										htmlFor="input-file"
										className="border border-secondary rounded-3 p-1 w-144 general-btn white-btn text-center">
										Seleziona
									</label>
									<input
										type="file"
										id="input-file"
										className="d-none"
										multiple={true}
										onChange={handleChange}></input>
								</div>
							)}
							{data.attachment?.length > 0 && (
								data.attachment?.map((item, index) => {
									return (
										<FileCard
											key={index}
											name={item}
											setFileName={setFileName}
											deleteFile={() => deleteFile(index)}
											id={index}
											view_mode={props.edit_mode}
											download={downloadFile}
											isNewAttachment={false}
										/>
									);
								})
							) 
						}

					{newAttachments.length > 0 ? (
					newAttachments.map((file, index) => {
						return (
						<FileCard
							key={index}
							name={file.name}
							id={index}
							view_mode={props.edit_mode}
							download={() => {}}
							isNewAttachment={true}
						/>
						);
					})
					) : null}

						{(newAttachments.length == 0 && data.attachment?.length == 0 ) &&
								<div className="unit-empty-img">
									<img loading="lazy" src={User} alt="" />
									<span className="img-comment">
										Non hai aggiunto ancora alcun allegato
									</span>
								</div>
						 }
						</div>
					</div>
				</fieldset>
			</Form>
		</>
	);
};

export default AttachmentsE;
