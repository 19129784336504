import React, { useEffect, useState } from "react";
import axios from 'axios';
import { BASE_URL } from "../../../api/api";

//fare copia con endpoint diversi => per chat msg

const ImgFileCard = ({ id, name, path, preview }) => {
    const [imageSrc, setImageSrc] = useState();
  
    useEffect(() => {
      if (preview) {
        setImageSrc(preview);
      } else if (id && name && path) {
        const fetchImage = async () => {
          const fileUrl = `${BASE_URL}api/v1/unit/${path}/${id}/${name}`;
          const response = await axios({
            url: fileUrl,
            method: 'GET',
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token}`,
              "Content-Type": `application/json`,
            },
          });
          const imageUrl = URL.createObjectURL(response.data);
          setImageSrc(imageUrl);
        };
  
        fetchImage();
      }
    }, [id, name, path, preview]);
  
    function downloadImg() {
      // scarica il file
      if (imageSrc) {
        window.open(imageSrc);
      }
    }
  
    return (
      <>
        <div className="image-file-card" onClick={downloadImg}>
          {imageSrc && <img loading="lazy" src={imageSrc} alt="" /> }
        </div>
      </>
    );
  };
  
  

export default ImgFileCard;
