import { useState, useEffect } from "react";
import EmptyImg from "../../../assets/images/assicurazione-ph.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import InsuranceModal from "./InsuranceModal";
import api, { supplier_endpoint } from "../../../api/api";

const Insurance = (props) => {

   //GESTIONE ASSICURAZIONI

   const [show, setShow] = useState(false);

   const onChange = (e) => {
		props.setFormData({ ...props.formData, [e.target.name]: e.target.value });
	};

   const addInsurance = (insurance) => {
     let temp = [...props.formData.insurances];
     temp.push(insurance);
     let e = { target: { name: "insurances", value: temp } };
     onChange(e);
     setShow(false);
   };

  //GESTIONE FORNITORI

  const [supplierList, setSupplierList] = useState([]);

	const fetchSuppliers = async () => {
		try {
			const res = await api().get(`${supplier_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setSupplierList(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchSuppliers();
	}, []);

  //VIEW EDIT INSURANCE CREATED

	const [showSelectedInsurance, setShowSelectedInsurance] = useState(false)
	const [selectedInsurance, setSelectedInsurance] = useState({})
	const [insuranceEdit, setInsuranceEdit] = useState(-1);


	function closeSelecteInsurance(){
		setShowSelectedInsurance(false)
		setSelectedInsurance({})
		setInsuranceEdit(null)

	}

	function handleInsurance(id){
		setShowSelectedInsurance(true)
		let current =  props.formData.insurances.find((item, index) => index == id)
		setSelectedInsurance(current)
		setInsuranceEdit(id)
	}


	function updateInsurance (insurance){
		let temp =  props.formData.insurances.map((item, index) => {
			return index == insuranceEdit?
			insurance:
			item
		})
		props.setFormData({ ...props.formData, ["insurances"]: temp });
		closeSelecteInsurance()
	};

	const deleteInsurance = () => {
		let temp =  props.formData.insurances.filter((item, index) => index != insuranceEdit)
		props.setFormData({ ...props.formData, ["insurances"]: temp }); 
		closeSelecteInsurance()
	};

  return (
    <div className="form-item-align-box-white d-block">
      <div className="d-flex mt-3">
        <button
          type="button"
          className="general-btn white-btn w-144 me-2"
          onClick={() => {
            setShow(true);
          }}
        >
          Nuovo
        </button>
      </div>
      { props.formData.insurances?.length > 0 ? (
        <div className="mt-4">
          <Table className="result-table table-border out-border-none">
            <thead>
              <tr>
                <th>Tipo di polizza</th>
                <th>Compagnia</th>
                <th>Inizio originale</th>
                <th>Scadenza Polizza</th>
                <th>Premio</th>
              </tr>
            </thead>
            <tbody>
              { props.formData.insurances.map((item, index) => {
                return (
                  <tr key={index} onClick={() => handleInsurance(index)}>
                    <td>{item.billType}</td>
                    <td>{item.company}</td>
                    <td>{new Date(item.originalStart).toLocaleDateString()}</td>
                    <td>{new Date(item.deadlineBill).toLocaleDateString()}</td>
                    <td>{item.reward}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="unit-empty-img">
          <img loading="lazy" src={EmptyImg} alt="No image" />
          <span className="img-comment fs-18">
            Non hai aggiunto ancora alcuna assicurazione
          </span>
        </div>
      )}
       <Modal show={show}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={() => {
              setShow(false);
            }}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
          <InsuranceModal supplierList={supplierList}  setShow={setShow} newMode={true} add={addInsurance} />
        </Modal.Body>
      </Modal>

      {/* VIEW ASSICURAZIONE */}
		<Modal show={showSelectedInsurance}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={closeSelecteInsurance}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
		  <InsuranceModal supplierList={supplierList} setShow={closeSelecteInsurance} insurance={selectedInsurance}  newMode={false} add={updateInsurance} delete={deleteInsurance}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Insurance;
