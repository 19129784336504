import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../assets/css/Impostazioni.css"

import TopHeading from "../components/TopHeading";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Profilo from "../components/Impostazioni/Profilo";
import Piano from "../components/Impostazioni/Piano";
import Privacy from "../components/Impostazioni/Privacy";
import Pacchetti from "../components/Impostazioni/Pacchetti";
import Inviti from "../components/Impostazioni/Inviti";
import File from "../components/Impostazioni/File";
import Notifiche from "../components/Impostazioni/Notification";
import Assemblea from "../components/Impostazioni/Assemblea";
import Archivio from "../components/Impostazioni/Archivio";
import Valutazione from "../components/Impostazioni/Report";
import Pagina from "../components/Impostazioni/Pagina/Pagina";
import Account from "../components/Impostazioni/Account"
import Pagamenti from "../components/Impostazioni/Pagamenti";

import Dropdown from 'react-bootstrap/Dropdown';
import { NavDropdown } from 'react-bootstrap';

import { useMediaQuery } from "react-responsive";
import ImpostazioniChat from "../components/StatoLavoriInCorso/Impostazioni chat/ImpostazioniChat";

import { checkUserRole, checkSupplier, checkMaster, checkCollaborator, checkAdmin } from "../utils/checkUserRole";

import api, { user_endpoint, user_accounts_endpoint, user_accounts_assignment_endpoint } from "../api/api";



export default function Impostazioni(props){
    const [key, setKey] = useState('Profilo');

    const [all, setAll] = useState([])

    //GET LIST 
    async function getAll() {
        try {
            const response = await api().get(`${'/api/v1/invitation/getTeamAll'}`);
            if (response.data ) {
                setAll(response.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getAll()
    }, [])

    // Usa un booleano per sapere se il menu a discesa è aperto o chiuso
    const [showMenu, setShowMenu] = useState(false);

    // Funzione che chiude il menu a discesa quando un'opzione viene selezionata
    const handleSelect = (key) => {
        setKey(key);
        setShowMenu(!showMenu);
    };

    // Controlla se il menu a discesa deve essere visualizzato in base alla larghezza dello schermo
    const shouldShowDropdown = useMediaQuery({
		query: "(max-width: 768px)",
	})

    const components = {
        Profilo,
        Piano,
        Inviti,
        Pacchetti,
        Privacy,
        File,
        Notifiche,
        Assemblea,
        Archivio,
        Valutazione,
        Pagina,
        ImpostazioniChat,
        Pagamenti,
        Account
    };

    const Component = components[key];

    //CLOSE WHEN CLICK OUTSIDE DROP DOWN
    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        }, [dropdownRef]);


        //GET USER DATA

        const [data, setData] = useState(null)

    async function fetchData(type) {
        try {
            const response = await api().get(`${user_endpoint}`);
            if (response.data) {
                setData(response.data?.content[0]);

                console.log("response.data.content", response.data?.content[0])
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData()
        //CONTROLLARE URL SE URL CONTIENE PARAMETRO TAB
        //SE TRUE, SETKEY(PARAMETRO)
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const tabSelected = params.get('tab');

        if(tabSelected){
            setKey(tabSelected)
        }
    }, [])

    console.log(data, data?.stripeUser)


    return(
        <>
            <div className="right-header">
                <TopHeading SmallHeading="empty" Heading="Impostazioni" />
                <h2 style={{ color:'transparent', height: 0}}>Impostazioni</h2>
            </div>
            <Container fluid> 
                <Row className="settings-container">
                {shouldShowDropdown ? 
            // Se lo schermo è abbastanza piccolo, mostra il menu a discesa
                <NavDropdown 
                    title={key} 
                    id="basic-nav-dropdown" 
                    className="settings-dropdown"
                    show={showMenu} 
                    onSelect={(k) => handleSelect(k)}
                    onClick={()=> setShowMenu(!showMenu)} 
                    ref={dropdownRef}>
                <NavDropdown.Item eventKey="Profilo" >Profilo</NavDropdown.Item>
                { checkMaster() &&  
                <>
                    <NavDropdown.Item eventKey="Piano">Piano</NavDropdown.Item>
                    <NavDropdown.Item eventKey="Pacchetti">Pacchetti</NavDropdown.Item>
                </>
                }

                {(!checkCollaborator  && data?.stripeUser) && <NavDropdown.Item eventKey="Pagamenti">Pagamenti</NavDropdown.Item>}

                
                <NavDropdown.Item eventKey="Privacy">Privacy</NavDropdown.Item>
                {/* <NavDropdown.Item eventKey="File">File</NavDropdown.Item> */}
                <NavDropdown.Item eventKey="Notifiche">Notifiche</NavDropdown.Item>
               { checkUserRole()  &&  
                <>
               {/*  <NavDropdown.Item eventKey="Assemblea">Assemblea</NavDropdown.Item> */}
                <NavDropdown.Item eventKey="Valutazione">Valutazione</NavDropdown.Item>
                </>}

{/*                 <NavDropdown.Item eventKey="ImpostazioniChat">Chat</NavDropdown.Item>
 */}

                { checkAdmin()  &&
                    <NavDropdown.Item eventKey="Account">Gestione Utente</NavDropdown.Item>

                }


                { <NavDropdown.Item eventKey="Inviti">Inviti</NavDropdown.Item>
                }

                {/* { (JSON.parse(localStorage.getItem("user"))?.role == "MASTER" || JSON.parse(localStorage.getItem("user"))?.role == "ADMIN" ||  JSON.parse(localStorage.getItem("user"))?.role == "COLLABORATOR")  &&
                    }
                */}
                <NavDropdown.Item eventKey="Archivio">Archivio</NavDropdown.Item>

                { (checkSupplier() || checkAdmin()) &&   
                <NavDropdown.Item eventKey="Pagina">Pagina</NavDropdown.Item>
                }

                </NavDropdown>  
                : 
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="cont-tabs"
                >
                    <Tab eventKey="Profilo" title="Profilo">
                        <Profilo showMenu={showMenu}
                        handleSelect={handleSelect}
                        setShowMenu={setShowMenu} 
                        setKey={setKey} />
                    </Tab>
                    {(checkMaster())  && 
                    <Tab eventKey="Piano" title="Piano" >
                        <Piano />
                    </Tab>
                    }

                    {(checkUserRole())  && <Tab eventKey="Inviti" title="Inviti">
                        <Inviti  setKey={setKey}/>
                    </Tab>}

                    {(!checkCollaborator() && data?.stripeUser)  && 
                    <Tab eventKey="Pagamenti" title="Pagamenti">
                        <Pagamenti setKey={setKey} stripeConnectInstance={props.stripeConnectInstance}/>
                    </Tab>}
                    { (checkMaster())  &&     
                    <Tab eventKey="Pacchetti" title="Pacchetti">
                        <Pacchetti />
                    </Tab>}
                    <Tab eventKey="Privacy" title="Privacy">
                        <Privacy data={data} />
                    </Tab>
                    {checkUserRole() &&
                    <Tab eventKey="Valutazione" title="Valutazione">
                        <Valutazione/>
                    </Tab> 
                    }
                    {/*  <Tab eventKey="File" title="File">
                        <File />
                    </Tab> */}
                    <Tab eventKey="Notifiche" title="Notifiche">
                        <Notifiche />
                    </Tab>
                    {/* <Tab eventKey="Assemblea" title="Assemblea">
                        <Assemblea />
                    </Tab> */}
                   {/*  { (JSON.parse(localStorage.getItem("user"))?.role == "MASTER" || JSON.parse(localStorage.getItem("user"))?.role == "ADMIN" ||  JSON.parse(localStorage.getItem("user"))?.role == "COLLABORATOR") && <Tab eventKey="Archivio" title="Archivio">
                        <Archivio />
                    </Tab>} */}

                    <Tab eventKey="Archivio" title="Archivio">
                        <Archivio />
                    </Tab>
                    
                    {(checkMaster()) && <Tab eventKey="Account" title="Gestione Utente">
                        <Account />
                    </Tab>}
                    
                    { (checkSupplier()) &&     
                    <Tab eventKey="Pagina" title="Pagina">
                        <Pagina      
                        showMenu={showMenu}
                        handleSelect={handleSelect}
                        setShowMenu={setShowMenu} 
                        setKey={setKey}
                        />
                    </Tab>
                    }
                    {/* {      
                    <Tab eventKey="ImpostazioniChat" title="Chat">
                        <ImpostazioniChat />
                    </Tab>
                    } */}
                    
                </Tabs>
                }

               {/* MOBILE */}
                {shouldShowDropdown && key == key &&
                    <div>
                        <Component setKey={setKey} 
                        showMenu={showMenu}
                        handleSelect={handleSelect}
                        setShowMenu={setShowMenu} 
                        stripeConnectInstance={props.stripeConnectInstance}
                        data={data}
                         />
                    </div>
                }
                </Row>
            </Container>
        </>
    )
}