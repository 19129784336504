import { useState, useEffect, useRef } from "react";
import PrintDownloadComponent from "../../PrintDownloadComponent";
import Table from "react-bootstrap/Table";
import FolderImg from "../../../assets/images/allegati-ph.svg";
import api, { activity_endpoint } from "../../../api/api";
import {
	BsPrinter,
	BsDownload,
	BsFilter,
	BsTrash,
	BsPencil,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../../Membri/mapping";



const ActivitiesE = (props) => {
	/* const [tabState, setTabState] = useState(0);
	const [filter, setFilter] = useState("");

	const [ordinary, setOrdinary] = useState([]);
	const [extraordinary, setExtraordinary] = useState([]);

	useEffect(() => {
		setOrdinary([]);
		setExtraordinary([]);
		if (units?.length > 0) {
			var tempOrdinary = [];
			var tempExtraordinary = [];
			for (var i = 0; i < units.length; i++) {
				if (units[i].activities.length > 0) {
					for (var j = 0; j < units[i].activities.length; j++) {
						if (units[i].activities[j].typology === "ordinario") {
							tempOrdinary.push(units[i].activities[j]);
						} else {
							tempExtraordinary.push(units[i].activities[j]);
						}
					}
				}
			}
			setOrdinary([...tempOrdinary]);
			setExtraordinary([...tempExtraordinary]);
		}
	}, [units]);

	const componentRef = useRef(); */

	/* const [printData, setPrintData] = useState({});

	useEffect(() => {
		if (tabState === 0) {
			if (ordinary.length > 0) {
				let temp = {};
				ordinary.map((item, index) => {
					temp = {
						...temp,
						[`Lavori Ordinari ${index + 1}`]: {
							"Descrizione intervento": item.description,
							"Unità Immobiliare": item.unitName,
							"Data richiesta": new Date(item.udat).toLocaleDateString(),
							"Data d’intervento": new Date(
								item.startDate
							).toLocaleDateString(),
						},
					};
				});
				setPrintData(temp);
			}
		} else {
			if (extraordinary.length > 0) {
				let temp = {};
				extraordinary.map((item, index) => {
					temp = {
						...temp,
						[`Lavori Straordinari
						${index + 1}`]: {
							"Descrizione intervento": item.description,
							"Unità Immobiliare": item.unitName,
							"Data richiesta": new Date(item.udat).toLocaleDateString(),
							"Data d’intervento": new Date(
								item.startDate
							).toLocaleDateString(),
						},
					};
				});
				setPrintData(temp);
			}
		}
	}, [tabState, ordinary, extraordinary]); */


		//PRENDI LAVORI ANNESSI
		const [activitiesList, setActivitiesList] = useState([]);

		const fetchActivities = async () => {
			try {
				const res = await api().get(`${activity_endpoint}condominium/${props.formData.id}`);
				if (res.data) {
					console.log(res.data.content);
					setActivitiesList(res.data.content);
				}
			} catch (err) {
				console.log(err);
			}
		};
		
		const navigate = useNavigate()

		function goToActivity(link){
			navigate(link)
		}
	
	
		useEffect(() => {
			fetchActivities()
	
		}, []);

	

	return (
		<div className="form-item-align-box-white d-block pt-3 pb-5">
			<label className="edit-subpage-title">LAVORI</label>
						<div className="user-alert green mt-3">
							<label>
								<i className="fal fa-exclamation-circle me-2"></i> Puoi
								aggiungere un nuovo intervento dalla sezione
								<Link to="/statoavanzamentolavori/attivita"><label className="link-label mx-1">Stato avanzamento lavori</label></Link>
					</label>
				</div>
				{activitiesList?.length > 0 && <div className="d-flex justify-content-between mt-3">
					<div>
						<div className="user-dropdown">
							<button
								type="button"
								className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
								<BsFilter color="var(--edit-color)" />
							</button>
							<div className="user-dropdown-content left">
								<div className="mt-2">
									<div className="fs-14">FITLRA PER</div>
									<div className="fw-semibold mt-3">TIPOLOGIA</div>
									<div className="d-flex mt-2">
										<input
											type="checkbox"
											name="fitler"
											className="w-20 me-2"
											onChange={(e) => {}}
										/>
										<div>Ordinario</div>
									</div>
									<div className="d-flex mt-2">
										<input
											type="checkbox"
											label="Ordinario"
											name="fitler"
											className="w-20 me-2"
											onChange={(e) => {}}
										/>
										<div>Straordinario</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}
					<ExportButton data={activitiesList} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				</div>}

			{activitiesList?.length > 0 ? (
				<div className="mt-2">
					<Table className="result-table table-border out-border-none">
						<thead>
							<tr>
								<th>Nome attività</th>
								<th>Tipologia</th>
								<th>Data richiesta</th>
								<th>Data d’inizio</th>
							</tr>
						</thead>
						<tbody>
							{activitiesList.map((item, index) => {
								return (
									<tr key={index} onClick={() => {goToActivity(`/statoavanzamentolavori/attivita/${item.id}`)}}>
										<td>{item.name}</td>
										<td>{item.typology}</td>
										<td>{new Date(item.cdat).toLocaleDateString()}</td>
										<td>{new Date(item.startDate).toLocaleDateString()}</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			) : (
				<div className="unit-empty-img">
					<span className="img-comment">Nessun lavoro creato</span>
				</div>
			)}
		</div>
	);
};

export default ActivitiesE;
