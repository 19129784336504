import React from "react";
import Table from 'react-bootstrap/Table';


export default function SubjectCard(props) {
  const table_header = ["CATEGORIA", "TIPO DI SOGGETTO", "TELEFONO"];
  return (
    <div className="col-md-6 mt-4">
      <div className="popup-yellow d-block overflow-hidden rounded-3">
        <div className="yellow-popup-header d-flex align-items-center justify-content-between p-2">
          <h3 className="fs-16 text-white fw-semibold">{props.title}</h3>
          <button
            type="button"
            className="border-0 bg-transparent text-white"
            onClick={() => props.remove(props.id)}
          >
            <i className="bi bi-x"></i>
          </button>
        </div>
        <div className="table-list d-block">
          <table className="table table-borderless">
            <tbody>
              {props.table_data &&
                props.table_data.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-uppercase fs-14 text-secondary px-3">
                        {table_header[index]}
                      </td>
                      <td className="text-capitalize fs-14 text-dark px-3">
                        {data}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
