import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TopHeading from "../../TopHeading";
import TypologyE from "./TypologyE";
import FeatureE from "./FeatureE";
import AmenityE from "./AmenityE";
import DataE from "./DataE";
import AttachmentE from "./AttachmentE";
import RelatedE from "./RelatedE";
import api, { evaluation_endpoint, unit_endpoint } from "../../../api/api";

const EditEvaluation = () => {
  const { id } = useParams();
  const [formData, setformData] = useState({});
  const [units, setUnits] = useState([]);
  const [unitsIndex, setUnitsIndex] = useState(-1);
  useEffect(() => {
    if (unitsIndex !== -1) {
      const unitsData = {
        province: units[unitsIndex].province,
        municipality: units[unitsIndex].municipality,
        municipalityCode: units[unitsIndex].municipalityCode,
        paper: units[unitsIndex].sheet,
        address: units[unitsIndex].address,
        category: units[unitsIndex].categoryLandRegistry,
        classType: units[unitsIndex].classLandRegistry,
        consistency: units[unitsIndex].consistence,
        cadastralIncome: units[unitsIndex].cadastralIncome,
        urbanSection: units[unitsIndex].urbanSection,
        zone: units[unitsIndex].zone,
        microZone: units[unitsIndex].microZone,
      };
      setformData({ ...formData, ...unitsData });
    }
  }, [unitsIndex]);

  async function getUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data.content);
        console.log(response.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getUnits();
  }, []);

  const getEvaluationById = async () => {
    try {
      const res = await api().get(`${evaluation_endpoint}${id}`);
      if (res.data) {
        setformData(res.data);
        if (res.data.unit?.length > 0) {
          setUnitsIndex(res.data.unit.id);
        }
        console.log(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getEvaluationById();
  }, []);

  const update = async (data) => {
    setformData({ ...formData, ...data });
    try {
      await api().put(`${evaluation_endpoint}${id}`, {
        ...formData,
        ...data,
      });
    } catch (err) {
      console.log(err);
    }
    setSaveState(false);
  };

  const [editMode, setEditMode] = useState(false);
  const [saveState, setSaveState] = useState(false);

  const save = () => {
    setEditMode(false);
    setSaveState(true);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setSaveState(false);
  };

  const [actEditState, setActEditState] = useState(0);

  const setActEditStep = (index) => {
    setActEditState(index);
  };

  return (
    <>
      <div className="title-search-group">
        <div className="right-header">
          <TopHeading SmallHeading="" Heading="" back_modal={false} />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <span className="title-font fw-bold">
          {formData.name ? formData.name : "No name"}
        </span>
        <div className="save-change-btn-group-stabili">
          <div
            className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}
          >
            <div
              className="edit-btn w-144 me-2"
              onClick={() => {
                setEditMode(true);
              }}
            >
              Modifica
            </div>
          </div>
          <div
            className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}
          >
            <div className="edit-btn w-173 me-2" onClick={save}>
              Salva modifiche
            </div>
            <button
              type="button"
              onClick={cancelEdit}
              className="border border-secondary bg-white rounded-3 edit-icon-btn"
            >
              <i
                className="bi bi-x"
                style={{ color: "var(--edit-color)" }}
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div className="step-show">
        <div className="tabs-div edit-mode">
          <div
            className="tab-icon"
            onClick={(e) => {
              setActEditStep(0);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "111px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M109 5C109 2.79086 107.209 1 105 1H6C3.79086 1 2 2.79086 2 5V53C2 55.2091 3.79086 57 6 57H105C107.209 57 109 55.2091 109 53V5Z"
                  fill={
                    actEditState >= 0
                      ? actEditState >= 1
                        ? "#2A7EC4"
                        : "#F1F8FD"
                      : "#fff"
                  }
                  stroke={actEditState >= 0 ? "#2A7EC4" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title1"
                style={{
                  color:
                    actEditState >= 0
                      ? actEditState >= 1
                        ? "#FFF8F8"
                        : "#2A7EC4"
                      : "#3C4257",
                }}
              >
                Tipologia
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1F8FDCF"
                    style={{
                      margin: "auto",
                      display: actEditState === 0 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={actEditState >= 1 ? "#2A7EC4" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: actEditState === 0 ? "" : "none",
                  position: "absolute",
                  left: "-8px",
                }}
              >
                Tipologia
              </div>
            </div>
          </div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setActEditStep(1);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "154px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M152 5C152 2.79086 150.209 1 148 1H6C3.79086 1 2 2.79086 2 5V53C2 55.2091 3.79086 57 6 57H148C150.209 57 152 55.2091 152 53V5Z"
                  fill={
                    actEditState >= 1
                      ? actEditState >= 2
                        ? "#2A7EC4"
                        : "#F1F8FD"
                      : "#fff"
                  }
                  stroke={actEditState >= 1 ? "#2A7EC4" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title1"
                style={{
                  color:
                    actEditState >= 1
                      ? actEditState >= 2
                        ? "#FFF8F8"
                        : "#2A7EC4"
                      : "#3C4257",
                }}
              >
                Caratteristiche
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1F8FDCF"
                    style={{
                      margin: "auto",
                      display: actEditState === 1 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={actEditState >= 2 ? "#2A7EC4" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: actEditState === 1 ? "" : "none",
                  position: "absolute",
                  left: "-20px",
                }}
              >
                Caratteristiche
              </div>
            </div>
          </div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setActEditStep(2);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "124px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M122 5C122 2.79086 120.209 1 118 1H6C3.79086 1 2 2.79086 2 5V53C2 55.2091 3.79086 57 6 57H118C120.209 57 122 55.2091 122 53V5Z"
                  fill={
                    actEditState >= 2
                      ? actEditState >= 3
                        ? "#2A7EC4"
                        : "#F1F8FD"
                      : "#fff"
                  }
                  stroke={actEditState >= 2 ? "#2A7EC4" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title1"
                style={{
                  color:
                    actEditState >= 2
                      ? actEditState >= 3
                        ? "#FFF8F8"
                        : "#2A7EC4"
                      : "#3C4257",
                }}
              >
                Dotazioni
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1F8FDCF"
                    style={{
                      margin: "auto",
                      display: actEditState === 2 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={actEditState >= 3 ? "#2A7EC4" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: actEditState === 2 ? "" : "none",
                  position: "absolute",
                  left: "-8px",
                }}
              >
                Dotazioni
              </div>
            </div>
          </div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setActEditStep(3);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "88px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M86 5C86 2.79086 84.2091 1 82 1H6C3.79086 1 2 2.79086 2 5V53C2 55.2091 3.79086 57 6 57H82C84.2091 57 86 55.2091 86 53V5Z"
                  fill={
                    actEditState >= 3
                      ? actEditState >= 4
                        ? "#2A7EC4"
                        : "#F1F8FD"
                      : "#fff"
                  }
                  stroke={actEditState >= 3 ? "#2A7EC4" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title1"
                style={{
                  color:
                    actEditState >= 3
                      ? actEditState >= 4
                        ? "#FFF8F8"
                        : "#2A7EC4"
                      : "#3C4257",
                }}
              >
                Dati
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1F8FDCF"
                    style={{
                      margin: "auto",
                      display: actEditState === 3 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={actEditState >= 4 ? "#2A7EC4" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: actEditState === 3 ? "" : "none",
                  position: "absolute",
                  left: "8px",
                }}
              >
                Dati
              </div>
            </div>
          </div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setActEditStep(4);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "106px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M104 5C104 2.79086 102.209 1 100 1H6C3.79086 1 2 2.79086 2 5V53C2 55.2091 3.79086 57 6 57H100C102.209 57 104 55.2091 104 53V5Z"
                  fill={
                    actEditState >= 4
                      ? actEditState >= 5
                        ? "#2A7EC4"
                        : "#F1F8FD"
                      : "#fff"
                  }
                  stroke={actEditState >= 4 ? "#2A7EC4" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title1"
                style={{
                  color:
                    actEditState >= 4
                      ? actEditState >= 5
                        ? "#FFF8F8"
                        : "#2A7EC4"
                      : "#3C4257",
                }}
              >
                Allegati
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1F8FDCF"
                    style={{
                      margin: "auto",
                      display: actEditState === 4 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={actEditState >= 5 ? "#2A7EC4" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: actEditState === 4 ? "" : "none",
                  position: "absolute",
                  left: "-2px",
                }}
              >
                Allegati
              </div>
            </div>
          </div>
          <div
            className="tab-icon"
            onClick={(e) => {
              setActEditStep(5);
            }}
          >
            <div className="desktop-icon">
              <svg style={{ width: "118px", height: "58px" }}>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M116 5C116 2.79086 114.209 1 112 1H6C3.79086 1 2 2.79086 2 5V53C2 55.2091 3.79086 57 6 57H112C114.209 57 116 55.2091 116 53V5Z"
                  fill={
                    actEditState >= 5
                      ? actEditState >= 6
                        ? "#2A7EC4"
                        : "#F1F8FD"
                      : "#fff"
                  }
                  stroke={actEditState >= 5 ? "#2A7EC4" : "#C1C9D2"}
                />
              </svg>
              <div
                className="tab-icon-title1"
                style={{
                  color:
                    actEditState >= 5
                      ? actEditState >= 6
                        ? "#FFF8F8"
                        : "#2A7EC4"
                      : "#3C4257",
                }}
              >
                Correlati
              </div>
            </div>
            <div
              className="mobile-icon"
              style={{ position: "relative", width: "40px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg height="20" width="20">
                  <ellipse
                    cx="10"
                    cy="10"
                    rx="10"
                    ry="10"
                    fill="#F1F8FDCF"
                    style={{
                      margin: "auto",
                      display: actEditState === 5 ? "" : "none",
                    }}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
                <svg
                  height="10"
                  width="10"
                  style={{
                    position: "absolute",
                  }}
                >
                  <ellipse
                    cx="5"
                    cy="5"
                    rx="5"
                    ry="5"
                    fill={actEditState >= 6 ? "#2A7EC4" : "#E0E0E0"}
                  />
                  Sorry, your browser does not support inline SVG.
                </svg>
              </div>
              <div
                className="tab1-mobile-title"
                style={{
                  display: actEditState === 5 ? "" : "none",
                  position: "absolute",
                  left: "-2px",
                }}
              >
                Correlati
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-content-container mt-3">
        {actEditState === 0 && (
          <TypologyE
            formData={formData}
            update={update}
            editMode={editMode}
            saveState={saveState}
            units={units}
            setUnitsIndex={setUnitsIndex}
          />
        )}
        {actEditState === 1 && (
          <FeatureE
            formData={formData}
            update={update}
            editMode={editMode}
            saveState={saveState}
          />
        )}
        {actEditState === 2 && (
          <AmenityE
            formData={formData}
            update={update}
            editMode={editMode}
            saveState={saveState}
          />
        )}
        {actEditState === 3 && (
          <DataE
            formData={formData}
            update={update}
            editMode={editMode}
            saveState={saveState}
          />
        )}
        {actEditState === 4 && (
          <AttachmentE
            formData={formData}
            update={update}
            editMode={editMode}
            saveState={saveState}
          />
        )}
        {actEditState === 5 && (
          <RelatedE
            formData={formData}
            update={update}
            editMode={editMode}
            saveState={saveState}
          />
        )}
      </div>
    </>
  );
};

export default EditEvaluation;
