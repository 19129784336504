import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProviderCard from "../UnitàImmobiliari/Edit/ProviderCard";
import ProviderModal from "../UnitàImmobiliari/Edit/ProviderModal";
import User from "../../../assets/images/fornitore-ph.svg";
import { Link } from "react-router-dom";
import ButtonRm from "../../ButtonRm";
import api, { supplier_endpoint } from "../../../api/api";
import SupplierModal from "../../Documenti/prima_nota/Tipologia/SupplierModal";

import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../../Membri/mapping";


const ProviderE = ({ suppliers, update }) => {
	const [show, setShow] = useState(false);


	const [data, setData] = useState([]);

	const init = () => {
		setData([...suppliers]);
	};
	useEffect(() => {
		init();
	}, []);

	const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		let temp = { suppliers: data };
		update(temp);
	};

	const cancelEdit = () => {
		setEditMode(false);
		// setBackModalShow(true);
	};

	const [supplierList, setSupplierList] = useState([]);

	const addSelectedSuppliers = (id) => {

		let findSuppl = supplierList?.find(
			(supplier) => supplier.id === id
		);
		
		setData([
			...data,
			findSuppl
		]);
	};

	const remove = (id) => {
		setData(data?.filter((supplier) => supplier.id !== id));
	};

	const fetchSuppliers = async () => {
		try {
			const res = await api().get(`${supplier_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setSupplierList(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchSuppliers();
	}, []);


	console.log("data", data)

	return (
		<div className="form-item-align-box-white d-block">
			<div className="save-change-btn-group-stabili">
				<div
					className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}>
					<div
						className="edit-btn w-144 me-2"
						onClick={() => {
							setEditMode(true);
						}}>
						Modifica
					</div>
				</div>
				<div
					className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}>
					<div className="edit-btn w-173 me-2" onClick={handleSave}>
						Salva modifiche
					</div>
					<button
						type="button"
						onClick={cancelEdit}
						className="border border-secondary bg-white rounded-3 edit-icon-btn">
						<i
							className="bi bi-x"
							style={{ color: "var(--edit-color)" }}></i>
					</button>
				</div>
			</div>
			<div className="edit-content-container mt-3 py-5">
				{editMode && (
					<div className="d-flex justify-content-start">
						<Link to="/fornitori/create">
							<ButtonRm variant="tertiary" className="me-2">Nuovo</ButtonRm>
						</Link>
						<ButtonRm variant="tertiary" 
							onClick={(e) => {
								setShow(true);
							}}>Seleziona</ButtonRm>
						
					</div>
				)}

				{(!editMode && data?.length > 0) && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}

				{data?.length > 0 ? (
					<div className="row">
						{data.map((item, index) => {
							return (
								<ProviderCard
									key={index}
									edit_mode={editMode}
									id={index}
									data={item}
									remove={remove}
								/>
							);
						})}
					</div>
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={User} alt="No image" />
						<span className="img-comment fs-16">Nessun conduttore selezionato</span>
					</div>
				)}


				<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<SupplierModal
						table_data={
							supplierList.length > 0 &&
							supplierList.map((supplier) => [
								supplier.category,
								supplier.businessName,
								supplier.cellularTelephone,
								supplier.subjectType,
							])
						}
						ids={supplierList.map((supplier) => supplier.id)}
						setShow={setShow}
						addSupplier={addSelectedSuppliers}
					/>
				</Modal.Body>
			</Modal>
			</div>
		</div>
	);
};

export default ProviderE;
