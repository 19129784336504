import { useState } from "react"

import "../assets/css/RisparmioOggettivo.css"

import av1 from "../assets/images/avatar1.png"
import av2 from "../assets/images/avatar2.png"
import av3 from "../assets/images/avatar3.png"
import av4 from "../assets/images/avatar4.png"

import ch1 from "../assets/images/chart1.png"
import ch2 from "../assets/images/chart2.png"
import ch3 from "../assets/images/chart3.png"
import ch4 from "../assets/images/chart4.png"


import { Container, Row, Col } from "react-bootstrap"

import { styled } from "@mui/material"

import ButtonRm from "../components/ButtonRm"

import { Slider } from "@mui/material"

const CustomSlider = styled(Slider)(({ theme }) => ({
    color:"#3880ff",
    height: 35,
    borderRadius: 50,
    '& .MuiSlider-thumb': {
      height: 40, // Altezza personalizzata del thumb
      width: 40, // Larghezza personalizzata del thumb
      backgroundColor: 'white !important',
    },
    '& .MuiSlider-track': {
      backgroundImage: 'linear-gradient(270deg, #58C5F4 2.48%, #2F80ED 100%)', // Immagine di sfondo personalizzata per il thumb
      backgroundSize: '100%, auto', // Dimensione dell'immagine di sfondo del thumb
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50%, center',
      border: "none !important"
    },
  }));
  



//import { MenuItem } from "@mui/base"

const roles = [
    {
        role: "Gestore",
        img: av1
    },
   /*  {
        role: "Amministratore",
        img: av2
    },  */
    {
        role: "Proprietario",
        img: av3
    },  
    {
        role: "Azienda",
        img: av4
    }
]

const charts = [
    {
        text: "Numero di unità gestibili all’ora",
        img: ch2
    },
    {
        text: "Tempo risparmiato per una singola valutazione",
        img: ch3
    }, 
    {
        text: "Velocità ed efficienza nelle comunicazioni",
        img: ch4
    },  
    {
        text: "Velocità nel reperire informazioni",
        img: ch1
    }
]

export default function RisparmioOggettivo({alone = true}){

    const [form, setForm] = useState({
        role:"Gestore",
        company:"",
        cost:"",
        employees:"",
        units:""
    })

    //VALORI OUTPUT
    const [valoreRisparmioEconomico, setValoreRisparmioEconomico] = useState(0);
    const [oreRisparmiate, setOreRisparmiate] = useState(0)
    const [ripagatoIn, setRipagatoIn] = useState("")


    function onChange(e){
        const {name, value} = e.target

        setForm(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    //PAGINA INIZIALE

    const [pageToShow, setPageToShow] = useState("form")

    function showOutput(){
        setPageToShow("output")
    }

    //PAGINA OUPUT

    function showForm(e){
        e.preventDefault()
        setPageToShow("form")
        setForm({
            role:"Gestore",
            company:"",
            cost:"",
            employees:"",
            units:""
        })
    }

    //TEMPO RIPAGAMENTO
    function convertiTempoRipagamento(tempoRipagamento) {
        if (tempoRipagamento < 1 / 365) {
            const giorni = Math.round(tempoRipagamento * 365);
            return giorni + " giorni";
            } else if (tempoRipagamento < 1 / 12) {
            const mesi = Math.round(tempoRipagamento * 12);
            return mesi + " mesi";
            } else {
            const anni = Math.floor(tempoRipagamento);
            const mesi = Math.round((tempoRipagamento - anni) * 12);
            if (anni === 0) {
                if (mesi === 1) {
                return mesi + " mese";
                } else {
                return mesi + " mesi";
                }
            } else {
                if (anni === 1) {
                if (mesi === 0) {
                    return anni + " anno";
                } else if (mesi === 1) {
                    return anni + " anno e " + mesi + " mese";
                } else {
                    return anni + " anno e " + mesi + " mesi";
                }
                } else {
                if (mesi === 0) {
                    return anni + " anni";
                } else if (mesi === 1) {
                    return anni + " anni e " + mesi + " mese";
                } else {
                    return anni + " anni e " + mesi + " mesi";
                }
                }
            }
            }
        }
    

    
    //CALCOLA OUTPUT
    function getOutput(e){
        e.preventDefault()
        const settimaneLavorative = 48
        const oreLavorativeRisparmiateASettimana = 8
        const oreRisparmiatePerPersona = settimaneLavorative * oreLavorativeRisparmiateASettimana
        const oreRisparmiateTotali = oreRisparmiatePerPersona * parseFloat(form.employees)
        const costoRealMetaAnnuale = 720

        const risparmioEconomico = (oreRisparmiateTotali * parseFloat(form.cost)) - (costoRealMetaAnnuale * parseFloat(form.employees))

        let nuovoValoreRisparmioEconomico = risparmioEconomico

        //Calcolo incremento del 1% ogni 10 unità
        nuovoValoreRisparmioEconomico += risparmioEconomico * (Math.floor(parseInt(form.units) / 10) * 0.01);


        // calcolare in quanto tempo costo del tool viene ripagato utilizzando il risparmio annuale  
        //Tempo di ripagamento = Costo del tool / Risparmio annuale

        const tempoRipagamento = (costoRealMetaAnnuale * form.employees) / nuovoValoreRisparmioEconomico
    
        setValoreRisparmioEconomico(nuovoValoreRisparmioEconomico.toFixed(0))
        setOreRisparmiate(oreRisparmiateTotali)
        setRipagatoIn(convertiTempoRipagamento(tempoRipagamento))
        showOutput()


    }

    return(
        <>

        <Container fluid style={{ height: alone ? '100vh' : ""}} >
            <Row className="justify-content-center align-items-center" style={{ height: '100%' }}>
                <div className="w-100 text-center py-5 my-5 px-xl-5 mx-xl-5">
                    <Col className="mb-4">
                        <h2 className={`fw-bold ${alone ? "" : "syne-font fs-32"}`} >Calcolo del risparmio con Real Meta</h2>
                        <p>Vuoi quantificare il risparmio reale che Real Meta può apportare alla tua attività? 
                        Compila il seguente modulo per scoprirlo.</p>
                    </Col>
                    <Col xs={12} sm={11} md={10} lg={alone? 9 : 10} xl={alone? 6 : 7} className="mx-auto">
                        <div className="ro-div">
                            {pageToShow == "form" && <div>
                                <div>
                                <p className="fw-bold text-light">Qual'è il tuo ruolo?</p>
                                    <div className="row ">
                                        {
                                        roles.map(el => (
                                            <div className="col mb-4">
                                            <CardRole img={el.img} role={el.role} form={form} setForm={setForm}/>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>

                                <div className="mt-3 mb-2">
                                    <p className="fw-bold text-light mb-2">La tua azienda</p>
                                    <input 
                                        type="text"
                                        value={form.company}
                                        onChange={onChange}
                                        name="company"
                                        className=""
                                    />
                                </div>

                                <div className="mt-2">
                                    <p className="fw-bold text-light">Numero collaboratori</p>
                                    <CustomSlider
                                        aria-label="custom slider"
                                        defaultValue={50}
                                        valueLabelDisplay="on"
                                        value={form.employees}
                                        onChange={onChange}
                                        name="employees"
                                    />  


                                </div>

                                <div className="mt-2">
                                    <p className="fw-bold text-light">Costo orario personale lordo (€/h)</p>
                                    <CustomSlider
                                    aria-label="custom slider"
                                    defaultValue={50}
                                    valueLabelDisplay="on"
                                    value={form.cost}
                                    onChange={onChange}
                                    name="cost"
                                    />  

                                </div>

                                <div className="mt-2 mb-3">
                                    <p className="fw-bold text-light">Numero di unità gestite</p>
                                    <CustomSlider
                                    aria-label="custom slider"
                                    defaultValue={50}
                                    valueLabelDisplay="on"
                                    value={form.units}
                                    onChange={onChange}
                                    name="units"
                                    min={0}
                                    max={1000}
                                    />  

                                </div>


                                <ButtonRm variant="outline" onClick={getOutput}
                                disabled={(form.employees && form.cost && form.role ? false : true)}>Calcola</ButtonRm>
                            </div> }
                            { pageToShow == "output" && <div style={{minHeight:"620px"}} className="d-flex flex-column justify-content-center align-items-center">
                                    <h3 className="fw-bold text-light mb-3">{form.company? form.company : "La tua azienda"} potrebbe risparmiare <span className="fw-bold text-blue-ro">{parseFloat(oreRisparmiate)?.toLocaleString()}</span> ore e <span className="fw-bold text-blue-ro">€ {parseFloat(valoreRisparmioEconomico)?.toLocaleString()}</span> all’anno</h3>
                                    <p className="fw-medium text-light fw-bold">Dopo {ripagatoIn? ripagatoIn : "1 mese"}, tutti gli abbonamenti Real Meta saranno completamente ripagati. <br/>
                                    In base al recente sondaggio sottoposto ai nostri clienti Gestori, questi hanno dichiarato di aver risparmiato 8 ore a settimana da quando utilizzano Real Meta.</p>
                                    <div className="d-flex w-100 my-4">
                                        {
                                            charts.map(el =>  <CardChart img={el.img} text={el.text} />)
                                        }
                                    </div>
                                    <ButtonRm variant="outline" onClick={showForm}
                                    >Torna all'inizio</ButtonRm>
                            </div>}
                        </div>

                        {
                            pageToShow == "output" &&
                            <div className="mt-4 ">
                                <p className="fs-12 text-start">
                                    L'output viene calcolato in questo modo:
                                    <br/>
                                    1. Vengono considerate le settimane lavorative in un anno (48) e le ore lavorative risparmiate stimate a settimana per persona (8). Moltiplicando questi valori si ottiene il numero totale di ore risparmiate per persona in un anno.
                                    <br/>
2. Successivamente, viene calcolato il numero totale di ore risparmiate moltiplicando il numero collaboratori per le ore risparmiate per persona. Questo fornisce un valore complessivo delle ore risparmiate in un anno.
<br/>
3. Il risparmio economico viene calcolato sottraendo il costo di Real Meta annuo (720) moltiplicato per il numero di collaboratori, dal valore delle ore complessive risparmiate per il costo del personale.
<br/>
4. Infine viene incrementato il valore del risparmio economico in base al numero delle unità gestite (+1% ogni 10 unità)
                                </p>
                            </div>
                        }
                    </Col>
                </div>
            </Row>
        </Container>
    </>
    )
}


function CardRole(props){
    return(
        <div className={`cardRoleRo ${props.form.role == props.role ? "selected" : ""}`} style={{maxHeight:"130px"}} onClick={() => props.setForm(prev => {
            return {
                ...prev,
                role: props.role
            }
        })}>
           {/*  <img loading="lazy" src={props.img}/> */}
            <p className="fw-bold fs-14">{props.role}</p>
        </div> 
    )
}

function CardChart(props){
    return(
        <div className="cardChartRo">
            <img loading="lazy" src={props.img}/>
            <p className="fw-bold text-light fs-14">{props.text}</p>
        </div>
    )
}