import { SET_SHOW, CLEAR_SHOW } from "../actions/types";

const initialState = {
  show: false
};

function ModalForVerify(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SHOW:
      return {
        ...initialState,
        show: payload,
      };
    case CLEAR_SHOW:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default ModalForVerify;
