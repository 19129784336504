import {useEffect, useState} from 'react'

import {Row, Col} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import ButtonRm from "../../ButtonRm"
import { User } from 'tabler-icons-react';
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";
import api from '../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { checkUserRole } from '../../../utils/checkUserRole';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { current } from '@reduxjs/toolkit';
import { userData } from 'three/examples/jsm/nodes/Nodes.js';



function Participants(props){
    //props.chatList, props.chatId

    const {chatId} = useParams()

    const navigate = useNavigate()
    /* Ipotizziamo questa sia lista di utenti già dentro rm */
    const [userList, setUserList] = useState([
        {
            id:1,
            name: "Paolo Monte",
            email: "paolo@gmail.com"
        },
        {
            id:2,
            name: "Carlo Pio",
            email: "carlo@gmail.com"
        },
        {
            id:3,
            name: "Miriam Tuffo",
            email: "miriam@gmail.com"
        }
    ])

    const testArr =  [
        {
            "is_Down": true,
            "chat_role": "COLLABORATOR",
            "original_role": "COLLABORATOR",
            "surname": "",
            "name": "",
            "id": 71,
            "associate_id": 3,
            "email": "arashalghasiunige@gmail.com"
        },
        {
            "is_Down": true,
            "chat_role": "SUPPLIER",
            "original_role": "MASTER",
            "surname": "Alghasi",
            "name": "Arash",
            "id": 72,
            "associate_id": 3,
            "email": "arash.alqasi@gmail.com"
        },
        {
            "is_Down": true,
            "chat_role": "SUPPLIER",
            "original_role": "MASTER",
            "surname": "Demo",
            "name": "Demo",
            "id": 4,
            "associate_id": 71,
            "email": "demorealmeta@gmail.com"
        },
        {
            "is_Down": true,
            "chat_role": "SUPPLIER",
            "original_role": "MASTER",
            "surname": "",
            "name": "",
            "id": 74,
            "associate_id": 3,
            "email": "hepiya5211@ahieh.com"
        },
        {
            "is_Down": false,
            "chat_role": "MASTER",
            "original_role": "MASTER",
            "surname": "Demo",
            "name": "Demo",
            "id": 4,
            "associate_id": 3,
            "email": "demorealmeta@gmail.com"
        },
        {
            "is_Down": true,
            "chat_role": "CONDUCTOR",
            "original_role": "SUPPLIER",
            "surname": "",
            "name": "",
            "id": 67,
            "associate_id": 3,
            "email": "vahidxv@gmail.com"
        },
        {
            "is_Down": true,
            "chat_role": "SUPPLIER",
            "original_role": "COLLABORATOR",
            "surname": "",
            "name": "",
            "id": 69,
            "associate_id": 71,
            "email": "arashalghasidotnet@gmail.com"
        }
    ]


    //lista geteam
    const [partecipantList, setPartecipantList] = useState([])


    //lista di utenti già presenti in chat
    const [currentParticipants, setCurrentParticipants] = useState([])



     const currentChat = props.chatList.length > 0 && props.chatList.find(el => el.id == chatId)
    const myId =  JSON.parse(localStorage.getItem("user"))?.id;
    const isCreator = currentChat.isCreator
    const currentRole = currentChat?.chatBoxCreatorRoleForYou 

    
    //Array che conterrà i gruppi della chat (un array per ogni gruppo)
    let arrays = []
    //SE UTENTE E' CREATORE DELLA CHAT (DEVO PASSARMI QUESTO DATO COME PROPS DA CHATDETAILS)
    if (isCreator && partecipantList.length > 0) {
        // Raggruppa i partecipanti per 'team_number'
        let uniqueTeamNumbers = [...new Set(partecipantList.map(el => el.team_number))];
    
        // Crea un array per ogni team_number unico
        arrays = uniqueTeamNumbers.map(teamNum => 
            partecipantList.filter(el => el.team_number === teamNum)
        );
    }
    

    const [showMenu, setShowMenu] = useState(false)
 
    const [showList, setList] = useState(true)
    const [showCheckEmail, setCheckEmail] = useState(false)


    function handleList(){
        setList(!showList)
    }

    const squadre = []


    const [selected, setSelected] = useState([]);
    const [showSelectedItem, setShowSelectedItem] = useState(false)
    const partecipanti = partecipantList.map(user => {
        return {
                key: user.id, 
                label:`${(user.name && user.surname )? `${user.name} ${user.surname}` : ""} <${user.email}> - ${user?.chat_role }`, 
                value: `${(user.id)}-${user?.chat_role}-${(user.is_Down)}-${user.team_number} `
            }
    })

     /* PLACEHOLDER PER CAMPO SELEZIONI MULTIPLE */
     const overrideString = { 
        "selectAll": "Seleziona tutti",
        "search": "Cerca o Crea nuovo",
        "selectSomeItems": "Seleziona",
        "allItemsAreSelected": "Tutti gli elementi sono selezionati",
        "create": "Crea nuovo"
    }

    /* HANDLE NEW ITEMS */

    const handleNewField = (value) => { 
        //check if email are valid
        //if invalid show a pop up
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)){
            setShowError(true)
            return   {label: "", value: "", invalid: true}
        }
        setUserList(prev => [...prev, {id:userList.length + 1, name:"", email: value, newItem: true}])
        return {    
            label: value,
            value: value,
            newItem: true
        }
    };


    /* CLICK ADD PARTICIPANTS BTN */

    function addNewParticipants(){
        if(selected.length >0){setShowSelectedItem(true)}
    }

    console.log(selected, "lista selezionati")

    useEffect(() => {
        if(showSelectedItem && selected.length===0) {
            setShowSelectedItem(false)
        }
    }, [selected])

    //manipolo chatMembers
    const [chatMembers, setChatMembers] = useState([])

    useEffect(() => {
        if(selected.length > 0){
            let newMembers = selected.map(el => {
                let value = el.value
                let split = value.split("-")
                let newObj = {
                    id: parseInt(split[0]),
                    isDown: split[2] /* == "true" ? true : false */,
                    chatRole: split[1] ,
                    teamNumber:split[3] 
                }

                console.log(newObj, "newObj")

                return newObj
            })

            setChatMembers(newMembers)
        }

    },[selected])

    /* INVALID EMAIL ERROR MESSAGE */

    const [showErrorMessage, setShowError] = useState(false);
    const toggleErrorMessage= () => setShowError(!showErrorMessage);

    function handleShowCheckList(){
        setCheckEmail(!showCheckEmail)
    }

    const inviteInfo = {
        type: "myList",
    }

    const [inviteData, setInviteData] = useState(inviteInfo)

    function handleInviteChange (e){
        const {value, name} = e.target
        setInviteData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const [invitationBody, setInvitationBody] = useState({
        role:"",
        email:""
     })
    
     function handleChange(e){
        const {name, value} = e.target
        setInvitationBody(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
     }

     //GESTISCE IL RUOLO DELL'UTENTE CHE VIENE INVITATO
const [isInviteeMasterOrCollaborator, setInviteeMasterOrCollaborator] = useState(false)

//GESTISCE SE L'EMAIL E' STATA CONTROLLATA O MENO 
const [emailIsChecked, setEmailIsChecked] = useState(false)

//GESTISCE SE E' UN UTENTE NON ANCORA REGISTRATO
const [newUser,setNewUser] = useState(false)

useEffect(() => {
    setInviteeMasterOrCollaborator(false)
    setEmailIsChecked(false)
    setNewUser(false)
}, [invitationBody.email])

async function checkInvitationEmail(){
    setInviteeMasterOrCollaborator(false)
    setEmailIsChecked(false)

    try {
      let response =  await api().post(`/api/v1/invitation/invitationEmailCheck`, {email: invitationBody.email})

      console.log(response, "response")

      if(response?.data?.success){
        //SE LA MAIL GIA' ESISTE NEL DATABASE
        setEmailIsChecked(true)
        if(response.data.emailExists){
            console.log("Corrispondenza trovata", response.data.role )

            //SE IL RUOLO E' MASTER O COLLABORATORE
            if(response.data.role == "MASTER" || response.data.role == "MASTER_PRO" || response.data.role == "COLLABORATOR" ){
                setInviteeMasterOrCollaborator(true)
            } 

        } else {
            //LA MAIL NON ESISTE
            setNewUser(true)

        }
      } else if(!response?.data?.success){
        if(response.data.emailExists){
            //L'UTENTE HA PROVATO A INSERIRE LA PROPRIA MAIL
            console.log("You cannot enter your own email")
            setEmailIsChecked(false)
            toast.error("Non è consentito inserire la propria e-mail in questo campo", {
                toastId: 18905,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });

            
        } else {
            //CI SONO DEI PROBLEMI TECNICI
            console.log("Due to technical problems we cannot provide the requested information")
            setEmailIsChecked(false)
            toast.error("A causa di problemi tecnici non siamo ing rado di completare la tua richiesta in questo momemento. Riprova più tardi.", {
                toastId: 18906,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });

        }
      }
      
   } catch (err) {
      console.log(err, "errore")
    }
}

async function sendInvitation(){
    //determina ruolo
    let body = {...invitationBody}
    //SE RUOLO ESISTENTE E' MASTER O COLLABORATORE, NEGLI ALTRI CASI MANTINEI IL RUOLO DEFINITO DALL'UTENTE
    if(isInviteeMasterOrCollaborator){
        body = {...invitationBody, role: "SUPPLIER"}
    }

    try {
         let response = await api().post(`/api/v1/invitation/`, body)
         if(response?.data?.success){
            toast.success("Invito inoltrato.", {
                toastId: 18902,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
              });
         } else {
            toast.error("A causa di un problema tecnico non siamo riusciti a inoltrare l'invito. Riprova più tardi.", {
                toastId: 18903,
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });
       
        } 
    } catch (err) {
        console.log(err);
        toast.error("A causa di un problema tecnico non siamo riusciti a inoltrare l'invito. Riprova più tardi.", {
            toastId: 18904,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
        });
    }

 }


function sendBasicInvite(){
    //invia email
    sendInvitation()
}
    
function goToSettings(){
    navigate("/impostazioni?tab=Inviti")
}

//GET LIST OF PARTECIPANTS
async function getPartecipants() {
    try {
        const response = await api().get(`${'/api/v1/invitation/getTeam'}`);
        if (response.data) {
            setPartecipantList(response.data.members);
        }
    } catch (err) {
        console.log(err);
    }
}

useEffect(() => {
    getPartecipants()
}, [])

const testList = [
    {
    id: 69,
    is_Down: true,
    chat_role: 'SUPPLIER',
    name:"",
    surname:"",
    email:""
}]


const [masterShow, setMasterShow] = useState(false)

//Controlli sull'aggiunta email
/* async function addParticipants(data) {
    console.log("chiamando")
        try {
            await api().post(
                `/api/v1/chat-box/add-participant`,
                { chatBoxId: data.chatBoxId, members: data.members }
            );
            await props.getAllList();
            await getAllParticipants()
        } catch (error) {
            console.log(error);
        }
*/

//gett chat participants 

console.log(arrays, "arrays")

//MEMBRO CORRENTE MASTER CHE DETERMINA CAMBIO RUOLO DINAMICO
const [currentSelectedMaster, setCurrentSelectedMaster] = useState(null)
const [currentChatCreatorId, setCurrentChatCreatorId] = useState(null)

//Controlli sull'aggiunta email
async function addParticipants(data) {
    if (currentParticipants.length > 1) {
        // Trova l'array che contiene il primo partecipante in testList
        const targetArray = arrays.find(array => array.some(participant => participant.id === currentParticipants[1].id));

        if (!targetArray) {
            console.log("Target array non trovato.");
            return;
        }

        // Separa i membri validi da quelli non validi
        const validMembers = [];
        const invalidMembers = [];

        //controlla se c'è già un master tra i partecipanti esistenti
        let hasMaster = currentParticipants.some(member => member.chat_role === 'MASTER');

        //controlla ogni membro che sta per essere aggiunto se apaprtiene al gruppo uguale a quello del primo partecipante della chat
        data.members.forEach(member => {
            const isInTargetArray = targetArray.some(targetMember => 
                targetMember.id === member.id && targetMember.chat_role === member.chatRole
            );

            //controlla se è già stato aggiunto
            const isInCurrentParticipants = currentParticipants.some(existingMember => existingMember.id === member.id);
            
            //se appartiee al gruppo giusto e non è giù stato aggiunto
            if (isInTargetArray && !isInCurrentParticipants) {
                //controlla se ha ruolo master
                if (member.chatRole === 'MASTER') {
                    if (hasMaster) {
                        invalidMembers.push(member); 
                    } else {
                        //controlla currentRole
                        if(currentRole == 'MASTER'){
                            hasMaster = true;
                            //fai apparire popup e gestisci li la richiesta
                            setMasterShow(true);
                            setCurrentSelectedMaster(member)
                        
                        } else {
                            hasMaster = true;
                            validMembers.push(member);
                            
                        }
                        
                    }
                }  //se non c'è già un master, e invito in chat un collaboratore non mio
                //avviene cambio ruolo dinamico
                else if(currentRole == 'MASTER' && !hasMaster && member.chatRole === 'COLLABORATOR' && !member.is_Down ){
                    setMasterShow(true);
                    setCurrentSelectedMaster(member)
                } else {
                    validMembers.push(member);
                }
            } else {
                invalidMembers.push(member);
            }
        });


        handleMemberAddition(validMembers, invalidMembers, chatId)
    } else {
        // Se testList è vuoto, esegui comunque i controlli su data.members
        // Pusho il primo utente inserito in questo array e lo uso per controllare come se fosse testList
        let tempArr = []
        const validMembers = [];
        const invalidMembers = [];
        let hasMaster = false;

        let targetArray = []

        data.members.forEach((member, index) => {
            if(index == 0){
                tempArr.push(member)
                targetArray = arrays.find(array => array.some(participant => participant.id === tempArr?.[0]?.id));
            }


            console.log(targetArray, "TARGETARRAY", member )

             const isInTargetArray = targetArray.some(targetMember => 
                //NB chat_role in questo caso non chatRole
                targetMember.id === member.id && targetMember.chat_role === member.chatRole
            );

            if (isInTargetArray) {
                if (member.chatRole === 'MASTER') {
                    if (hasMaster) {
                        invalidMembers.push(member);
                    } else {
                         //controlla currentRole
                        if(currentRole == 'MASTER'){
                            hasMaster = true;
                            //fai apparire popup e gestisci li la richiesta
                            //aspetta risposta che avviene nel pop up
                            setMasterShow(true);
                            setCurrentSelectedMaster(member)
                        } else {
                            hasMaster = true;
                            validMembers.push(member);
                        }
                    }
                //se non c'è già un master, e invito in chat un collaboratore non mio
                //avviene cambio ruolo dinamico
                } else if(currentRole == 'MASTER' && !hasMaster && member.chatRole === 'COLLABORATOR' && !member.is_Down ){
                    setMasterShow(true);
                    setCurrentSelectedMaster(member)
                }
                else {
                    validMembers.push(member);
                }

            } else {
                invalidMembers.push(member);
            }

            handleMemberAddition(validMembers, invalidMembers, chatId)
        });
    }
}

async function handleMemberAddition(validMembers, invalidMembers, chatBoxId) {
    if (invalidMembers.length > 0) {
        console.log("I seguenti membri non possono essere aggiunti (o sono già presenti):", invalidMembers);
        toast.error("I seguenti membri non possono essere aggiunti (o sono già presenti):", invalidMembers, {
            toastId: "9f74k",
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
        });
    }

    if (validMembers.length > 0) {
        try {
            let res = await api().post(
                `/api/v1/chat-box/add-participant`,
                { chatBoxId: chatId, members: validMembers }
            );
            if(res.data.success){
                console.log("I seguenti membri sono aggiunti:", validMembers);
                toast.success("I seguenti membri sono aggiunti:", validMembers, {
                    toastId: "9f74j",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                await props.getAllList();
                await getAllParticipants();
            } else {
                toast.error(res.data.message, {
                    toastId: "error1",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    hideProgressBar: false,
                });
            }
        } catch (error) {
            toast.error(error, {
                toastId: "error1",
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });
        }
    } else {
        console.log("Nessun membro valido da aggiungere.");
    }
}

async function getAllParticipants(){
    try {
        let response = await api().get(
            `/api/v1/chat-box/getAllParticipants?chatBoxId=${chatId}`
        );

        setCurrentParticipants(response.data.members)
    } catch (error) {
        console.log(error);
    }
}

useEffect(() => {
    getAllParticipants()
}, [])

/*TROVA IL CREATORE DELLA CHAT*/
/* ID DELL'UTENTE CORRENTE */

useEffect(() => {
    if(currentParticipants.length > 0 ){
        currentParticipants.forEach(el => {
            if(el.isCreator){
                setCurrentChatCreatorId(el.id)
            }
        })
    } else {
        setCurrentChatCreatorId(null)
    }
}, [currentParticipants])

console.log(currentChatCreatorId)

//ARRAY DI OGGETTI CON VALORE SELECTED.VALUE E' L'ARRAY DA MANDARE CON LE INFORMAZIONI RELA



    return(
        <div>
            <ToastContainer />

            {currentSelectedMaster && <RoleModal chat={chatId} member={currentSelectedMaster} masterShow={masterShow} setMasterShow={setMasterShow} setCurrentSelectedMaster={setCurrentSelectedMaster} currentRole={currentRole} getAllParticipants={getAllParticipants} getAllList={props.getAllList}
            />
}
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.show} onHide={props.handleClose} className="p-5 squadreModal" centered >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Partecipanti</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-4 pb-3 px-md-5">
                    { showList ?
                        currentParticipants.length > 0  && 
                        currentParticipants.map(item => {
                                return <div className="d-flex align-items-center justify-content-between py-2 mb-3"
                                >   
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <div className="me-2 text-uppercase p-1 text-center"
                                            style={{borderRadius:"50%", background:"lightblue" }}>
                                                <p className="fs-12 mt-1" style={{minWidth:"24px", minHeight:"22px"}}>{item.email.substring(0,2)}</p>
                                            </div>
                                            <div>
                                                {(item.name && item.surname ) && <p>{item.name} {item.surname}</p>}
                                                <p className='fs-12'>
                                                    {item.email}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        item.isCreator &&
                                        <p className="text-uppercase fs-14">Creatore</p>
                                    }

                                    {
                                        /* QUESTE OPZIONI DEVONO ESSERE VISIBILI SOLO AL CREATORE */
                                        /* DEVE SOLO APRIRSI UN DROPDOWN */

                                        !item.isCreator &&
                                        <div className="part-dots-menu">
                                            <i className="bi bi-three-dots-vertical"
                                                onClick={() => setShowMenu(!showMenu, item.id )}
                                            ></i>
                                            <div className={`participant-options-menu ${showMenu ? "d-block" : "d-none"}`}>
                                                <p className='fs-14'>Rendi admin</p>
                                                <p className='fs-14'>Rimuovi</p>
                                            </div>
                                        </div>
                                    }
                                </div>}
                            ) :
                        <div>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <div className="d-flex">
                                        <Form.Check
                                            inline
                                            label="Seleziona partecipante"
                                            type="radio"
                                            value="myList"
                                            checked={inviteData.type === "myList"}
                                            onChange={handleInviteChange}
                                            name="type"
                                        />
                                        {
                                            checkUserRole() &&
                                            <Form.Check
                                                inline
                                                label="Invita partecipante"
                                                type="radio"
                                                value="newContact"
                                                checked={inviteData.type === "newContact"}
                                                onChange={handleInviteChange}
                                                name="type"
                                            />
                                        }
                                         {
                                            checkUserRole() &&
                                            <Form.Check
                                                inline
                                                label="Invita squadra"
                                                type="radio"
                                                value="team"
                                                checked={inviteData.type === "team"}
                                                onChange={handleInviteChange}
                                                name="type"
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            { inviteData.type === "myList" &&
                                <>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Col md={12}>
                                    
                                        <Form>
                                        <Form.Group className="d-flex flex-column" controlId="gestioneName" >
                                            
                                            <MultiSelect
                                                options={partecipanti}
                                                value={selected}
                                                onChange={setSelected}
                                                labelledBy="Seleziona"
                                                overrideStrings={overrideString}
                                                isCreatable={false}
                                                onCreateOption={handleNewField}
                                            />
                                        </Form.Group>
                                        </Form>
                                        
                                    </Col>
                                    {/*  <Col md={1} >
                                        <i className="bi bi-plus-circle-fill ms-2  fs-30"
                                        style={{cursor: "pointer"}}
                                        onClick={addNewParticipants}
                                        >
                                        </i>
                                    </Col> */}

                                </div>
                                {
                                showSelectedItem && selected.length > 0 &&
                                <div className='mt-3'>
                                    {
                                        selected.map( item => <NewItem item={item}/>)
                                    }
                                </div>
                                }
                                </>
                            }

                            { inviteData.type === "newContact" &&
                                <Col md={11} className="me-2 mb-3">
                                    
                                    <Form.Group className="">
                                        <Form.Label>Inserisci l'email 
                                            {(!showList && (inviteData.type === "newContact")  && emailIsChecked && newUser ) &&
                                                 <OverlayTrigger
                                                 placement="top"
                                                 delay={{ show: 600, hide: 300 }}
                                                 overlay={
                                                   <Tooltip className='exclamation-tooltip'>Puoi invitare all'interno della chat utenti non registrati su real meta (Impostazioni / Inviti). Per poterli aggiungere nella conversazione, dovrai prima attendere che accettino l'invito. Dopodiché riceverai una notifica e potrai inserirli all'interno della conversazione scegliendoli dalla lista dei partecipanti della chat.</Tooltip>
                                                 }
                                               >
                                                    <i class="ms-2 bi bi-info-circle"></i>
                                               </OverlayTrigger>
                                            }
                                        </Form.Label>
                                        <Form.Control 
                                            // {...register("issueEntity")}
                                            type="email" 
                                            placeholder=""
                                            name="email"
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {/* {errors.issueEntity?.message} */}
                                        </Form.Control.Feedback>
    
                                    </Form.Group>
    
                                    {(!emailIsChecked) && <ButtonRm variant="outline" size={'small'} onClick={checkInvitationEmail}  disabled={(invitationBody.email) ? false: true} className="mt-2">
                                    Controlla email</ButtonRm>
                                    }
                                </Col>
                                
                            }
                            { inviteData.type === "newContact" &&
                                (emailIsChecked && !isInviteeMasterOrCollaborator && !newUser) && <Col md={11}>
                                <Form.Group >
                                <Form.Label>Seleziona il ruolo dell'utente </Form.Label>

                                    <Form.Select
                                        // {...register("documentType")}
                                        type="text" 
                                        name="role"
                                        onChange={handleChange}
                                    >
                                        <option></option>
                                        <option value="SUPPLIER">Fornitore</option>
                                        <option value="CONDUCTOR">Conduttore</option>
                                        <option value="OWNER">Proprietario</option>
{/*                                     <option value="COLLABORATOR">Collaboratore</option>
 */}                                    </Form.Select>
                                
                                </Form.Group>
                                </Col>
                            }

                            {
                                inviteData.type === "team" && 
                                <Col md={11}>
                                    <Form.Group >
                                        <Form.Label>Seleziona la squadra da invitare</Form.Label>
                                            <Form.Select
                                                type="text" 
                                                name="team"
                                            >
                                                <option></option>
                                                {/* 
                                                    QUI MI SERVE GET ALLA LISTA DELLE SQUADRE CHE L'UTENT EHA CREATO
                                                    QUANDO VIENE SELEZIONATO UNA SQUADRA, RECUPERO LA SQUADRA RELATIVA
                                                    FACCIO UN ITERAZIONE DI CIASCUN MEMBRO DELLA SQUADRA PER IL QUALE MI SERVE
                                                    ID E EMAIL E INVIO RICHIESTA CON BODY {EMAIL, ID}  AL BACKEND PER OGNI MEMBRO

                                                */}
                                                <option value="ID_SQUADRA">NOME_SQUADRA</option>
                                  </Form.Select>
                                        
                                    </Form.Group>
                                </Col>
                            }

                        </div>
                    }
                </Modal.Body>
                <Modal.Footer
                    className="d-flex flex-column  px-md-5 pb-md-4"
                    style={{ borderTop: "none" }}
                >
                     {/* AGGIUNGERE CONTROLLI: BTN VISIBILE SOLO A CREATORE DELLA CHAT 
                        CONTROLLA ID USER E ID DEL CREATORE (TROVO IL CREATORE FILTRANDO CURRENTPARTICIPANTS: IS CREATOR TRUE)
                     
                     */}

                     
                    {(showList && (currentChatCreatorId == userData.id)) &&  <ButtonRm variant="primary" onClick={handleList}>
                    Aggiungi Partecipante
                    </ButtonRm>}

                    {(!showList && (inviteData.type === "myList"))  && 
                        <>
                            <ButtonRm variant="primary" onClick={() => {
                                addParticipants({
                                    chatBoxId : chatId,
                                    members: chatMembers
                                })
                            }}>Aggiungi
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={handleList}>Annulla
                            </ButtonRm>
                        </>
                    }

                    {(!showList && (inviteData.type === "newContact") && emailIsChecked && !newUser)  && 
                        <>
                            
                            <ButtonRm variant="primary" onClick={sendBasicInvite}>Aggiungi
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={handleList}>Annulla
                            </ButtonRm>
                        </>
                    }

                    {(!showList && (inviteData.type === "newContact")  && emailIsChecked && newUser )  && 
                        <>
                            
                            <ButtonRm variant="primary" onClick={goToSettings}>Vai alla sezione inviti
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={handleList}>Annulla
                            </ButtonRm>
                        </>
                    }

                    {(!showList && (inviteData.type === "team")) && 
                        <>
                            <ButtonRm variant="primary" disabled>Aggiungi
                            </ButtonRm>
                            <ButtonRm variant="text" onClick={handleList}>Annulla
                            </ButtonRm>
                        </>
                    }
                    
                </Modal.Footer>
            </Modal>

            
        </div>
    )
}

export default Participants

function NewItem(props){
    return (
    <>    
    {   !props.item.invalid   &&
        <div className="d-flex mb-2 align-items-center">
            <div className="newParticipantCard me-2">
                <p>{props.item.label}</p>
            </div>
            {
                props.item.newItem &&
                <>
                <Form.Check
                    label="Profilo auditor"
                    type="checkbox"
                    className="me-2"
                />
                <Form.Check
                    label="Profilo Master"
                    type="checkbox"
                />
                </>
            }
        </div>
    }
    </>  
    )
}

export {NewItem}

function RoleModal(props){

    async function changeRole(){
        try {
            await api().post(
                `/api/v1/chat-box/add-participant`,
                { chatBoxId: props.chat, members: [props.member] }
            );
            await props.getAllList();
            await props.getAllParticipants();

            props.setCurrentSelectedMaster(null)
            console.log("I seguenti membri sono aggiunti:", props.member);
            toast.success("I seguenti membri sono aggiunti e cambio ruolo dinamico avvenuto con successo", props.member, {
                toastId: "9f74j",
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                hideProgressBar: false,
            });
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <Modal show={props.masterShow} centered>
                <Modal.Body className="p-5">
                <div className="d-flex flex-column text-center">
                    <h2 className="fs-32 text-dark fw-semibold mb-3">
                    Cambio ruolo
                    </h2>
                    <h6 className="fs-16 text-dark fw-normal mb-3">
                        Stai invitando l'utente { props.member?.id } con ruolo {props.member?.chatRole} all'interno di questa chat,
                        di conseguenza non potrai più essere {props.currentRole} in questa chat.
                    </h6>
                    <button
                        type="button"
                        className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
                        onClick={changeRole}
                    >
                    Conferma e invita
                    </button>
                    <button
                    type="button"
                    className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
                    onClick={() => props.setMasterShow(false)}
                    >
                    Annulla
                    </button>
                </div>
                </Modal.Body>
            </Modal>
    )
}

