import { CHANGE_THEME} from "./types";

/* ACTION CREATORS */
const changeTheme = () => {
    return {
        type: CHANGE_THEME,
    };
}

/* REDUCER */

console.log(window.location.pathname);


const initialState = {
    section:window.location.pathname == "/statoavanzamentolavori/bacheca" ?
    "property" : "property"
}

const themeReducer = (state = initialState, action) => {
    switch(action.type){
        case CHANGE_THEME:
            return {...state, 
                    section: state.section == "property" ? "slc" : "property"
                }
        default:
            return state
    }
}

export default themeReducer
export { changeTheme };
