import { GET_PROVINCE, GET_MUNICIPALITY, GET_ZONE } from "./types";
import axios from "axios";

export const getProvince = () => async (dispatch) => {
	try {
		const response = await axios.get(
			`https://anthill-service.onrender.com/provinces?region=`
		);
		// console.log("PROVINCE", response.data);
		if (response.data) {
			dispatch({ type: GET_PROVINCE, payload: response.data.data });
		}
	} catch (error) {
		console.log(error);
	}
};

export const getMunicipality = (province) => async (dispatch) => {
	try {
		const response = await axios.get(
			`https://anthill-service.onrender.com/municipalities?province=${province}`
		);
		console.log("MUNICIPALITY", response.data);
		if (response.data) {
			dispatch({ type: GET_MUNICIPALITY, payload: response.data.data });
		}
	} catch (error) {
		console.log(error);
	}
};




export const getZone = (municipality) => async (dispatch) => {
	try {
		const response = await axios.get(
			`https://anthill-service.onrender.com/zones?municipality=${municipality}`
		);
		console.log("zone", response.data);
		if (response.data) {
			dispatch({ type: GET_ZONE, payload: response.data.data });
		}
	} catch (error) {
		console.log(error);
	}
};