import React, { useState, useEffect } from "react";
import TopHeading from "../components/TopHeading";
import empty_img from "../assets/images/ph-affitti2.svg";

import {Row, Col} from "react-bootstrap"
import { Link } from "react-router-dom";

import Form from 'react-bootstrap/Form';

import { checkUserData } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";
import ButtonRm from "../components/ButtonRm";
import Modal from "react-bootstrap/Modal";
import { useLocation } from 'react-router-dom';



import { sortDatesByKey, sortStringByKey} from "../utils/filtering";

import { dateDifferenceInDays, formattedTimestamp } from "../utils/dateManipulation";

export default function Notifiche(props) {

	const notifications = [
		{
			id: 1,
			content: "EVENT_NAME + DATA INIZIO - DATA FINE", //il giorno della data d'inizio di una scadenza
			ctaType: "view",
			sectionName: "scadenzario",
			elementReferenceId: "",
			cdat: "2024-05-21T09:12:34Z",
			status: "unread"
		},

		{
			id: 2,
			content: "L'utente ha condiviso l'evento EVENT_NAME con te ", //dalla sezione alert: condividi 
			ctaType: "view",
			sectionName: "scadenzario",
			elementReferenceId: "",
			cdat: "2024-05-21T09:12:34Z",
			status: "unread"
		},
		{
			id: 3,
			content: "Sei stato aggiunto all’attività ACTIVITY_NAME", //quando l'utente viene inserito come partecipante di un'attività
			ctaType: "view",
			sectionName: "SAL",
			elementReferenceId: "",
			cdat: "2024-01-30T08:25:19Z",
			status: "unread"
		},
		{
			id: 5,
			content: "L’attività ACTIVITY_NAME in UNIT_NAME con data di fine lavori prevista per END_DATE. è stata ultimata?", //il giorno della data presunta di fine di un attività
			ctaType: "confirmActivity",
			sectionName: "SAL",
			elementReferenceId: "",
			cdat: "2024-02-20T11:05:48Z",
			status: "unread"
		},
		//QUESTI MESSAGGI SONO RELATIVI ALLA CHAT
		/* {
			id: 6,
			content: "Hai ricevuto un nuovo messaggio nella chat NOME_CHAT",
			ctaType: "view",
			sectionName: "chat",
			elementReferenceId: "",
			cdat: "2024-04-01T10:33:47Z",
			status: "read"
		},
		{
			id: 7,
			content: "Sei stato aggiunto alla conversazione NOME_CHAT",
			ctaType: "view",
			sectionName: "chat",
			elementReferenceId: "",
			cdat: "2024-05-18T12:54:30Z",
			status: "unread"
		}, 
		{
			id: 8,
			content: "Sei diventato ADMIN della chat NOME_CHAT",
			ctaType: "view",
			sectionName: "chat",
			elementReferenceId: "",
			cdat: "2024-03-10T14:21:37Z",
			status: "read"
		},
		*/
		{
			id: 9,
			content: "USER_NAME è entrato in real meta",
			ctaType: "",
			sectionName: "inviti",
			elementReferenceId: "",
			cdat: "2024-02-28T15:40:11Z",
			status: "unread"
		},
		{
			id: 10,
			content: "NOME_UTENTE si vuole collegare con te su real meta",
			ctaType: "onlyAccept",
			sectionName: "inviti",
			elementReferenceId: "",
			cdat: "2024-04-18T18:55:52Z",
			status: "read"
		},
		{
			id: 11,
			content: "Sei stato aggiunto come incaricato di ELEMENT_NAME", //quando un collaboratore diventa incaricato di una gestione e/o un utente base/collaboratore diventa incaricato di un attività
			ctaType: "view",
			sectionName: "Vario",
			elementReferenceId: "",
			cdat: "2024-05-05T09:11:29Z",
			status: "unread"
		},
		//QUESTI MESSAGGI SONO RELATIVI ALLA CHAT

		/* {
			id: 12,
			content: "NOME_COLLABORATORE ti ha notificato un pagamento da effettuare",
			ctaType: "view",
			sectionName: "chat",
			elementReferenceId: "",
			cdat: "2024-01-25T07:23:44Z",
			status: "read"
		}, 
		{
			id: 13,
			content: "NOME_COLLABORATORE vuole creare un messaggio di pagamento nella chat NOME CHAT con le seguenti informazioni: CAUSALE, AMMONTARE, IVA",
			ctaType: "acceptReject",
			sectionName: "chat",
			elementReferenceId: "",
			cdat: "2024-03-07T17:05:10Z",
			status: "unread"
		}
			*/
	];
	

	const [notifiche, setNotifiche] = useState(notifications);



	/* async function getNotifiche() {
		try {
			const response = await api().get(`${notifiche_endpoint}`);
			if (response.data) {
				setNotifiche(response.data.content);
			}
		} catch (err) {
			console.log(err);
		} 
	}

	useEffect(() => {
		getNotifiche();
	}, []); */

	//NEW SEARCH & FILTER
	//SEARCH

	const [searchTerm, setSearchTerm] = useState("")

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

    const categories = [
		/* {
			name: 'Gestione',
			items: gestioni.map(el => String(el.id)),
		} */
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("più-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])


    useEffect(() => {
      if (notifiche.length > 0) {
        setFilteredUnits(
			notifiche.filter((affitto) => {

            const affittoDate = new Date(affitto.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && affittoDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && affittoDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Unità') {
						return affitto.units.some((unit) => {
						return cat.items.includes(unit.name);
						});
					}  else {
						return cat.items.includes(affitto.typology);
					}
					})) &&
					affitto.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, notifiche, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits([]);
		setStartDate("")
		setEndDate("")

		}


		    //CHECK IF DATA REQUIRED ARE COMPILED
			const [dataRequired, setDataRequired] = useState(true);
			//Gestisce il modale
			const [showCheck, setShowCheck] = useState(false)
		
			useEffect(() => {
				const fetchData = async () => {
					try {
						const response = await checkUserData();
						setDataRequired(response.isVerified);
						if (!response.isVerified) {
						  setShowCheck(true);
					  }
					} catch (error) {
						// Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
						console.error('Errore durante il recupero dei dati utente:', error);
					}
				};
		
				fetchData();
			}, []);


			//SUDDIVIDE LE NOTIFICHE IN BASE ALLE DATE IN 3 SCAGLIONI

			const [todayNotifications, setTodayNotifications] = useState([]);
			const [lastSevenDaysNotifications, setLastSevenDaysNotifications] = useState([]);
			const [otherNotifications, setOtherNotifications] = useState([]);

			useEffect(() => {
				const today = new Date();
				const sevenDaysAgo = new Date();
				sevenDaysAgo.setDate(today.getDate() - 7);

				const todayNotifs = [];
				const lastSevenDaysNotifs = [];
				const otherNotifs = [];

				notifiche.forEach(notification => {
					const cdat = new Date(notification.cdat);

					if (cdat.toDateString() === today.toDateString()) {
						todayNotifs.push(notification);
					} else if (cdat >= sevenDaysAgo && cdat < today) {
						lastSevenDaysNotifs.push(notification);
					} else {
						otherNotifs.push(notification);
					}
				});

				todayNotifs.sort((a, b) => new Date(b.cdat) - new Date(a.cdat));
				lastSevenDaysNotifs.sort((a, b) => new Date(b.cdat) - new Date(a.cdat));
				otherNotifs.sort((a, b) => new Date(b.cdat) - new Date(a.cdat));

				setTodayNotifications(todayNotifs);
				setLastSevenDaysNotifications(lastSevenDaysNotifs);
				setOtherNotifications(otherNotifs);

			}, [notifiche]);
			
			
		  //MODAL TO HANDLE POP UP CONFIRM-ACTIVITY
		  const [show, setShow] = useState(false);
		  const handleClose = () => setShow(false);
		  const handleShow = () => setShow(true);

		//SCROLL TO NOTIFICATION CARD
		  const location = useLocation();

		useEffect(() => {
		if (location.hash) {
			const element = document.getElementById(location.hash.substring(1));
			if (element) {
			const offset = 100; // Altezza del padding top
			const bodyRect = document.body.getBoundingClientRect().top;
			const elementRect = element.getBoundingClientRect().top;
			const elementPosition = elementRect - bodyRect;
			const offsetPosition = elementPosition - offset;
	
			window.scrollTo({
				top: offsetPosition,	
				behavior: 'smooth',
				block: 'center'
			});
			}
		}
		}, [location]);
	return (
		<>
			<div className="title-search-group mobile-container-xl">
				<div className="right-header">
					<TopHeading SmallHeading="empty" />
				</div>
				<Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
				<Col >
					<h2 className="fw-bold text-start">Notifiche</h2>
				</Col>
				<Col className="d-flex justify-content-end ">
					
				
				</Col>
				</Row>
			</div>

			{notifiche.length > 0 ? (
				<Row>
                    {
						todayNotifications.length > 0 &&
						<div className="mb-3">
							<p className="fw-bold text-uppercase fs-14 ms-3">oggi</p>
							{todayNotifications.map(el => <NotificationCard key={el.id} id={el.id} sectionName={el.sectionName} content={el.content} ctaType={el.ctaType} status={el.status} cdat={el.cdat} handleShow={handleShow}/>)}
						</div>
					}

					{
						lastSevenDaysNotifications.length > 0 &&
						<div className="mb-3">
							<p className="fw-bold text-uppercase fs-14  ms-3">ultimi 7 giorni</p>
							{lastSevenDaysNotifications.map(el => <NotificationCard key={el.id} id={el.id} sectionName={el.sectionName} content={el.content} ctaType={el.ctaType} status={el.status} cdat={el.cdat} handleShow={handleShow}/>)}
						</div>
					}

					{
						otherNotifications.length > 0 &&
						<div className="mb-3">
							<p className="fw-bold text-uppercase fs-14  ms-3">altro</p>
							{otherNotifications.map(el => <NotificationCard key={el.id} id={el.id} sectionName={el.sectionName} content={el.content} ctaType={el.ctaType} status={el.status} cdat={el.cdat} handleShow={handleShow}/>)}
						</div>
					}
                </Row>

			) : (
				<Row className="dashboard-container ">
					<div className="mx-auto text-center empty-container">
						<div className="mx-auto d-table w-160">
							<img loading="lazy" src={empty_img} alt="" />
						</div>
						<p className="fs-20 text-secondary text-center">
							Nessuna notifica
						</p>
					</div>
				</Row>
			)}


<Modal show={show} onHide={handleClose} className="p-5" centered >
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className=" pt-md-5 px-md-5"
          >
            <Modal.Title>Attività</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3 px-md-5">
            <Form>
              <Form.Group className=" d-flex flex-column" controlId="gestioneName">
                <Form.Label>Imposta una nuova data di fine intervento</Form.Label>
                <input
                  type="date"
                  placeholder=""
                  style={{ height: "50px",  
                  borderRadius: "6px",
                  border: "1px solid #BDBDBD",
                  padding: "0 .5rem" 
                  }}
                 
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer
            className="d-flex flex-column  px-md-5 pb-md-4"
            style={{ borderTop: "none" }}
          >
            <ButtonRm variant="primary">
              Salva
            </ButtonRm>
            <ButtonRm variant="text" onClick={handleClose}>
              Annulla
            </ButtonRm>
          </Modal.Footer>
      </Modal>

		</>
	);
}


function NotificationCard(props){


	function checkDataToDisplay(data){
		console.log(data, "data")
        let dataTimeToShow = ""
        const TODAY_DATE = new Date()
        const TODAY_DATE_FORMAT = TODAY_DATE.toISOString().split('T')[0]

 		let fullDateFormat = formattedTimestamp(data);
		let cDate = fullDateFormat.split(" ")[0];
		let cTime = fullDateFormat.split(" ")[1];

		if(TODAY_DATE_FORMAT === cDate){
			dataTimeToShow = cTime
			return dataTimeToShow
		} else {
			if( cDate < TODAY_DATE_FORMAT ) {
				const differenceDays =  dateDifferenceInDays(new Date(cDate), new Date(TODAY_DATE_FORMAT) )

				if(differenceDays > 6){
					dataTimeToShow = cDate.split("-").reverse().join("/");

					return dataTimeToShow
				} else {
					const weekDayMapping = {
						0: "Domenica",
						1: "Lunedì",
						2: "Martedì",
						3: "Mercoledì",
						4: "Giovedì",
						5: "Venerdì",
						6: "Sabato"                        
					}
					const cDateFormat = new Date(cDate)
					const getWeekDay =  cDateFormat.getDay()
					const getWeekName = weekDayMapping[getWeekDay]

					dataTimeToShow = getWeekName
					return dataTimeToShow

				}
				
			} else {
				return dataTimeToShow
			}
		}

    }

	const location = useLocation();
	const [isMatchingId, setIsMatchingId] = useState(false);
  
	useEffect(() => {
	  const hash = location.hash.substring(1); // Rimuovi il simbolo '#'
	  if (hash == props.id) {
		setIsMatchingId(true);
	  } else {
		setIsMatchingId(false);
	  }
	}, [location, props.id]);
  

	return(
				<div
					id={props.id}
					className={`notificationCard ${props.status == "unread" ? "unread" : ""} d-md-flex justify-content-md-between align-items-md-center text-dark`} style={{ backgroundColor: isMatchingId ? '#eee' : '#ffffff' }}
				>
					<div>
						<small className="fs-12 text-uppercase">{props.sectionName}</small>
						<p className="fs-14">
							{props.content}
						</p>
						<small className="fs-10">{checkDataToDisplay(props.cdat)}</small>
					</div>
					<div className="d-flex">
						{
							props.ctaType == "onlyAccept" &&
							<ButtonRm variant='outline-gray' size="extra-small" >Accetta</ButtonRm> 
						}

						{
							props.ctaType == "acceptReject" &&
							<>
								<ButtonRm variant='outline-gray' size="extra-small" className="me-2">Accetta</ButtonRm> 
								<ButtonRm variant='outline' size="extra-small" >Rifiuta</ButtonRm> 
							</>
						}

						{
							props.ctaType == "confirmActivity" &&
							<>
								<ButtonRm variant='outline-gray' size="extra-small" className="me-2" >Si, confermo</ButtonRm> 
								<ButtonRm variant='outline' size="extra-small" onClick={props.handleShow}>No</ButtonRm> 
							</>
						}

						{
							props.ctaType == "view" &&
							<ButtonRm variant='outline' size="extra-small" >Visualizza</ButtonRm> 
						}
					</div>
				</div>
	)
}