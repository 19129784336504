import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import NumericInput from "react-numeric-input";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../ButtonRm";
import { Modal } from "react-bootstrap";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import api, { rate_endpoint, unit_endpoint, stabili_endpoint, first_note_endpoint,
chat_list_endpoint, chat_msg_endpoint, rate_condominium_assignment_endpoint, rate_unit_assignment_endpoint, rate_conductor_assignment_endpoint, rate_note_assignment_endpoint, rate_supplier_assignment_endpoint, rate_owner_assignment_endpoint, BASE_URL  } from "../../../api/api";

import TopHeading from "../../TopHeading";

import { BsTrash } from "react-icons/bs";

import {OverlayTrigger} from "react-bootstrap";
import {Tooltip} from "react-bootstrap";

import PrintDownloadComponent from "../../PrintDownloadComponent";

import ActionsIcons from "../../Documenti/prima_nota/edit/ActionsIcons";

import CreateDocumenti from "../../Documenti/prima_nota/CreateDocumenti"

import { formatDateFromString } from "../../../utility";

import axios from "axios";
import mimeTypes from "../../../allegatiType";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";

import { Breadcrumb } from "react-bootstrap";
import { formattedTimestamp } from "../../../utils/dateManipulation";

import CustomBadge from "../../CustomBadge";


const schema = yup.object().shape({
	rentType: yup.string().required("Seelziona il tipo di locazione"),
	rentPercentage: yup.number("Inserisci un numero").max(100, "Il valore massimo è 100%"),
	name:yup.string().required("Inserisci un indentificativo univoco"),
	
});

function ViewRata(props) {
	function myFormat(num) {
		return num + "%";
	}

	function myCurrencyFormat(num) {
		return num + "€";
	}

	const Metodo_di_pagamento = [
		"Accredito su conto corrente",
		"addebito su conto corrente",
		"assegno",
		"bancomat",
		"bollettino freccia",
		"bollettino postale",
		"bonifico",
		"carta di credito",
		"cessione del credito d’imposta",
		"compensazione finanziaria",
		"contanti",
		"giroconto",
		"Mav",
		"paypal",
		"rav",
		"ri.ba",
		"rid",
		"sdd ( sepa direct debit)",
		"vaglia cambiario",
		"vaglia postale",
	];

    
    

	const rentType = ["Attiva", "Passiva", "Sublocazione"];
	// const setPercentage = (percent) => {
	//   let temp_data = props.affitti_data;
	//   temp_data.rent_percentage = percent;
	//   props.updateAffitti(temp_data);
	// };

	const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		console.log(data)
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		props.forward()
	};

    //MODE - based on the path
    //const [editMode, setEditMode] = useState(false)

    //const [paid, setPaid] = useState(false)
    const [alertPrimaNota, setAlertPrimaNota] = useState(false)
    const [modalPrimaNota, setModalPrimaNota] = useState(false)

    function handlePrimaNotaModal(){
        setAlertPrimaNota(false)
        setModalPrimaNota(true)
    }

    //GET CURRENT RATA DATA

    const {rataId} = useParams() 

    const [formData, setFormData] = useState({});
    const [currentAssociation, setCurrentAssociation] = useState({})

    //GESTIONE NUOVI ALLEGATI 
    const [newAttachments, setNewAttachments] = useState([]);

    const handleChange = (e) => {
        let temp = [...newAttachments];
        setNewAttachments([...temp, ...e.target.files]);
    };
    const setFileName = (index, new_name) => {
    };

    const deleteFile = (index) => {
    };

    console.log(formData)

    const fetchRata = async () => {
        try {
            const res = await api().get(`${rate_endpoint}${rataId}`);
            if (res.data) {
                setFormData(res.data);
                setCurrentAssociation( { 
                    conductor: res.data.conductors,
                    unit: res.data.unit,
                    condominium : res.data.condominium,
                    firstNote:res.data.firstNote,
                    owner: res.data.owners,
                    supplier: res.data.suppliers
                })
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if(rataId){
            fetchRata()
        }
    }, [rataId])

    //AVVISO PRIMA NOTA
    useEffect(() => {
        if(formData.paid){
            setAlertPrimaNota(true)
        }
    }, [formData.paid])

    //UPDATE RATA

    const [editMode, setEditMode] = useState(false)

    const [subjectName, setSubjectName] = useState("")


    const onChange = (e) => {
        const { name, value, type, checked } = e.target;
        let parsedValue = (name == "unit" && value || 
                            name == "condominium" && value ||
                            name == subjectName && value ||
                            name == "primaNotaRelated" && value) ? 
                            JSON.parse(value) : value   
        
        parsedValue = ( name == "vatCode") ? Number(value) : parsedValue
                            
        setFormData((prevFormData) => {
            return {
            ...prevFormData,
            [name]:
                type === "checkbox"
                ? checked
                : parsedValue,
            };
        });
    };

   //SE UNITA SELEZIONATA RIMUOVI CONDOMINIO, E VICEVERSA
    useEffect(() => {
        if(formData.typeBuilding == "Unità"){
            setFormData(prev => {
                return {
                    ...prev,
                    condominium: {}
                }
            })
        } 

        if(formData.typeBuilding == "Stabile"){
            setFormData(prev => {
                console.log("STABILE")
                return {
                    ...prev,
                    unit: {}
            }
        })
    } 

    }, [formData.typeBuilding])

    //AUTO UPDATE TOTAL DUE IF AMOUNT OR TOTALPAID CHANGE
    const totalDue = (formData.amount * (formData.vatCode ?formData.vatCode : 1 )) - formData.totalPaid

    useEffect(() => {
        setFormData(prev => {
            return {
                ...prev,
                totalDue
            }
        })
    }, [formData.amount, formData.totalPaid, formData.vatCode])


    //GET STABILI E UNITA'

    const [units, setUnits] = useState([]);
    const [stabili, setStabili] = useState([]);

    async function fetchUnits() {
        try {
        const response = await api().get(`${unit_endpoint}`);
        if (response.data) {
            setUnits(response.data?.content);
        }
        } catch (error) {
        console.log(error);
        }
    }

    async function fetchStabili() {
        try {
        const response = await api().get(`${stabili_endpoint}`);
        if (response.data) {
            setStabili(response.data?.content);
        }
        } catch (error) {
        console.log(error);
        }
    }

    useEffect(() => {
        fetchUnits();
        fetchStabili();
    }, []);

    //GET SUBJECTS 

    const [subjects, setSubjects] = useState([])

    async function fetchSubjects(type) {
        if(type){ 
            try {
                const response = await api().get(`/api/v1/${type}/`);
                if (response.data) {
                    setSubjects(response.data?.content);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        if(formData.subjectType && formData.subjectType != "other"){
            fetchSubjects(formData.subjectType)
        }
    }, [formData.subjectType])


    //GET SUBJECTS 
    const [primeNote, setPrimeNote] = useState([])

    async function fetchPrimeNote() {
        try {
            const response = await api().get(`${first_note_endpoint}`);
            if (response.data) {
                setPrimeNote(response.data?.content);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPrimeNote()
    }, [])

     //HANDLE UPDATE

     console.log(formData, "FORM DATA 123")

    const updateRata = async (data) => {
        try {
            console.log(formData, "INSIDE TRY")
            const conductor = formData?.conductors?  [formData.conductors] : []
            const unit = formData?.unit? [formData.unit] : []
            const condominium = formData?.condominium ? [formData.condominium] : []
            const firstNote =  formData.firstNote.length > 0?  [formData.firstNote] : []
            const owner = formData?.owners ? [formData.owners] : []
            const supplier =  formData?.suppliers ? [formData.suppliers] : []
        
            //GESTIONE ALLEGATI
            let formDataToSend = new FormData();
            
            newAttachments.forEach((file) => {
                const blob = new Blob([file]);
                formDataToSend.append("parts", blob, file.name);
            });

            let sentUpload = formDataToSend.get("parts") !== null;
            if (sentUpload) {
                await api().put(`${rate_endpoint}upload/${rataId}`, formDataToSend);
            }
            

            let response = await api().put(`${rate_endpoint}${rataId}`, data);

            // ASSOCIAZIONE CONDUTTORI
			if (formData.subjectType === "conductor" && conductor.length > 0 && response.data.id) {
                const isAlreadyAssociated = currentAssociation.conductor?.some(existingEl => existingEl.id === conductor[0].id)
                if (!isAlreadyAssociated) {
                    await api().post(`${rate_conductor_assignment_endpoint}`, {
                        conductorID: conductor[0].id,
                        installmenteID: response.data.id,
                    })
                }
            }

            // ASSOCIAZIONE OWNER
			if (formData.subjectType === "owner" && owner.length > 0 && response.data.id) {
                const isAlreadyAssociated = currentAssociation.owner?.some(existingEl => existingEl.id === owner[0].id)
                if (!isAlreadyAssociated) {
                    await api().post(`${rate_owner_assignment_endpoint}`, {
                        ownerID: owner[0].id,
                        installmenteID: response.data.id,
                    })
                }
            }

            // ASSOCIAZIONE SUPPLIER
			if (formData.subjectType === "supplier" && supplier.length > 0 && response.data.id) {
                console.log("sono dentro association supplier")
                const isAlreadyAssociated = currentAssociation.supplier?.some(existingEl => existingEl.id === supplier[0].id)
                if (!isAlreadyAssociated) {
                    console.log("sto facendo la post")
                    await api().post(`${rate_supplier_assignment_endpoint}`, {
                        supplierID: supplier[0].id,
                        installmenteID: response.data.id,
                    })
                }
            }

            console.log(formData.subjectType === "supplier", supplier, response.data.id, formData, "WEEEEE")

			// ASSOCIAZIONE UNITA'
			if (unit.length > 0 && response.data.id) {
                const isAlreadyAssociated = currentAssociation.unit?.some(existingEl => existingEl.id === unit[0].id)
                if (!isAlreadyAssociated) {
                    await api().post(`${rate_unit_assignment_endpoint}`, {
                        unitID: unit[0].id,
                        installmenteID: response.data.id,
                    });
                }
            }

            // ASSOCIAZIONE CONDOMINIO
			if (condominium.length > 0 && response.data.id) {
                const isAlreadyAssociated = currentAssociation.condominium?.some(existingEl => existingEl.id ===  condominium[0].id)
                if (!isAlreadyAssociated) {
                    await api().post(`${rate_condominium_assignment_endpoint}`, {
                        condominiumID: condominium[0].id,
                        installmenteID: response.data.id,
                    });
                }
            }

			///DISSOCIAZIONE CONDUTTORI ELIMINATI
			if (formData.subjectType != "conductor" && currentAssociation.conductor?.length > 0) {
				// Verifica se conduttore preesistente è stato eliminato
				const isDeleted = !currentAssociation.conductor.some(el => el.id === conductor[0].id);
                // Se conduttore è stato eliminato, effettua la disassociazione
                if (isDeleted) {
                    await api()({
                        method: 'delete',
                        url:`${rate_conductor_assignment_endpoint}disassociate`,
                        data: {
                            conductorID: conductor[0].id,
                            installmenteID: response.data.id,
                        }
                    })
                }
			}

            ///DISSOCIAZIONE fornitori ELIMINATI
			if (formData.subjectType != "supplier" && currentAssociation.supplier?.length > 0) {
				// Verifica se conduttore preesistente è stato eliminato
				const isDeleted = !currentAssociation.supplier.some(el => el.id === supplier[0].id);
                // Se conduttore è stato eliminato, effettua la disassociazione
                if (isDeleted) {
                    await api()({
                        method: 'delete',
                        url:`${rate_supplier_assignment_endpoint}disassociate`,
                        data: {
                            supplierID: supplier[0].id,
                            installmenteID: response.data.id,
                        }
                    })
                }
			}

            ///DISSOCIAZIONE proprietari ELIMINATI
			if (formData.subjectType != "owner" && currentAssociation.owner?.length > 0) {
				// Verifica se conduttore preesistente è stato eliminato
				const isDeleted = !currentAssociation.owner.some(el => el.id === owner[0].id);
                // Se conduttore è stato eliminato, effettua la disassociazione
                if (isDeleted) {
                    await api()({
                        method: 'delete',
                        url:`${rate_owner_assignment_endpoint}disassociate`,
                        data: {
                            ownerID: owner[0].id,
                            installmenteID: response.data.id,
                        }
                    })
                }
			}

			//DISSOCIAZIONE UNITA ELIMINATE
			if (currentAssociation.unit.length > 0) {
				// Verifica se unità preesistente è stato eliminato
				const isDeleted = !currentAssociation.unit.some(el => el.id === unit[0].id)
                // Se unità è stato eliminato, effettua la disassociazione
                if (isDeleted) {
                    await api()({
                        method: 'delete',
                        url:`${rate_unit_assignment_endpoint}disassociate`,
                        data: {
                            unitID: unit[0].id,
                            installmenteID: response.data.id,
                        }
                    })						
				}
			}

            ///DISSOCIAZIONE CONDOMINIO ELIMINATE
			if (currentAssociation.condominium.length > 0) {
				// Verifica se unità preesistente è stato eliminato
				const isDeleted = !currentAssociation.condominium.some(el => el.id === condominium[0].id)
                // Se unità è stato eliminato, effettua la disassociazione
                if (isDeleted) {
                    await api()({
                        method: 'delete',
                        url:`${rate_condominium_assignment_endpoint}disassociate`,
                        data: {
                            condominiumID: condominium[0].id,
                            installmenteID: response.data.id,
                        }
                    })						
				}
			}


            await api().get(`${rate_endpoint}${rataId}`)

        } catch (err) {
            console.log(err);
        }
    };


    const handleUpdate = () => {

        const bodyToSend = {...formData}

        //RIMUOVO SUBJECTS
        delete  bodyToSend.subject;
        //RIMUOVO ELEMENTI DA ASSEGNARE CON UN DIVERSO ENDPOINT
        delete  bodyToSend.unit;
        delete  bodyToSend.condominium;
        delete  bodyToSend.firstNote
        delete  bodyToSend.conductors
        delete  bodyToSend.owners
        delete  bodyToSend.suppliers
        delete  bodyToSend.cdat
        delete  bodyToSend.udat
        delete  bodyToSend.userID
        delete  bodyToSend.attachment

        //CONVERTO IN NUMBER TOTAL PAID E AMOUNT
        bodyToSend.totalPaid = bodyToSend.totalPaid? Number(bodyToSend.totalPaid) : 0
        bodyToSend.amount = Number(bodyToSend.amount)


        setEditMode(false)
        updateRata(bodyToSend)
	};

    //HANDLE PAID
    async function handlePaidStatus(){
        //SE PAGATO, TOTAL DUE DIVENTA 0, E TOTAL PAID === TOTALDUE
        if(!formData.paid){
            let bodyToSend = {...formData}

            //RIMUOVO SUBJECTS
            delete  bodyToSend.subject;
            //RIMUOVO ELEMENTI DA ASSEGNARE CON UN DIVERSO ENDPOINT
            delete  bodyToSend.unit;
            delete  bodyToSend.condominium;
            delete  bodyToSend.firstNote
            delete  bodyToSend.conductors
            delete  bodyToSend.owners
            delete  bodyToSend.suppliers
            delete  bodyToSend.cdat
            delete  bodyToSend.udat
            delete  bodyToSend.userID
            delete  bodyToSend.attachment

            bodyToSend.totalPaid = bodyToSend.totalPaid? Number(bodyToSend.totalPaid) : 0
            bodyToSend.amount = Number(bodyToSend.amount)



            await updateRata({...bodyToSend, paid: true, totalDue: 0, totalPaid: bodyToSend.amount * (formData.vatCode? formData.vatCode : "1")})
            await fetchRata()
        } else {
            setModalPrimaNota(true)
        }
    }

    //HANDLE DELETE
    const [modalDelete, setModalDelete] = useState(false) 

    const deleteRata = async () => {
        try {
            await api().delete(`${rate_endpoint}${rataId}`);
        } catch (err) {
            console.log(err);
        }
    }
    
    const navigate = useNavigate()

    //HANDLE CHAT

    const [modalSendChat, setModalSendChat] = useState(false)

    function modalSendShow(){
        setModalSendChat(true)
        getChats()
    }

    function modalSendClose(){
        setModalSendChat(false)
    }

    const chatInfo = {
        type: "newChat",
        name: "",
        chatId: ""
    }

    const [chatData, setChatData] = useState(chatInfo)

    function handleChatSend (e){
        const {value, name} = e.target
        setChatData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    //SE NUOVA CHAT CLEAR ID, SE EXISITING CHAT CLEAR NAME
    useEffect(() => {
        if(chatData.type == "newChat"){
            setChatData(prev => {
                return {
                    ...prev,
                    chatId: ""
                }
            })
        } else {
            setChatData(prev => {
                return {
                    ...prev,
                    name: ""
                }
            })
        }
    }, [chatData.type])

    //PRENDERE ENDPOINT CHAT GROUP
    //CREARE LISTA CON CHAT GROUP PER SELECT

    const [chatList, setChatList] = useState([])

    async function getChats() {
        try {
            const response = await api().get(`${chat_list_endpoint}`);
            if(response.data) {
                setChatList(response.data?.content)
            }
        }
        catch(error) {
                console.log(error)
        }
    }

    //ONSEND =>
    //SE CHAT ESISTENTE, USARE CHAT ENDPOINT PER CREARE MESSAGGIO
    //SE CHAT NON ESISTENTE, CREARE CHAT GROUP E SUBITO DOPO AGGIUNGERE MESSAGGIO

    // QUANDO CREO LA CARD REALE IN CHAT, PARSARE CONTENT E INVIARE FORMDATA!!

    let subjectInfo

    if(formData?.subjectType == "owner"){
        subjectInfo = `${formData?.owners?.[0]?.surname} ${formData?.owners?.[0]?.name} `
    } else if(formData?.subjectType == "conductor"){
        subjectInfo = `${formData?.conductors?.[0]?.surname} ${formData?.conductors?.[0]?.name} `
    } else if(formData?.subjectType == "supplier"){
        subjectInfo = `${formData?.suppliers?.[0]?.surname} ${formData?.suppliers?.[0]?.name} `
    }
    const bodyForCreate = {
        message: "",
        senderEmail: "",
        replyID:  -1,
        attachments: "",
        tag: [],
        messageType: "paymentMsg",
        content: {
            causale: formData?.installmentName,
            soggetto: subjectInfo,
            scadenza: formData?.deadlineDate?.substring(0,10),
            prezzo: formData?.amount,
        },
        deleted: false,
        chatGroupID: chatData.chatId?  chatData.chatId : ""
    }

    //CREATE THE MESSAGE 
    async function createMsg(data){
        try {
            await api().post(
                `${chat_msg_endpoint}`,
                data
            );
        } catch (error) {
            console.log(error);
        }

        modalSendClose()
    }

    const bodyForChatCreation = {
        name: chatData.name,
        members: [{id: JSON.parse(localStorage.getItem('user')).id, name: JSON.parse(localStorage.getItem('user')).name,  surname: JSON.parse(localStorage.getItem('user')).surname, role: "chatCreator"}]
    }

    //CREATE THE NEW CHAT
    async function createChat(data){
        try {
            //dati per nuovo msg
            let strdata;
            if (bodyForCreate.content) {
            strdata = {
            ...bodyForCreate,
            content: JSON.stringify(bodyForCreate.content).replace(/"/g, "'"),
            };
            //dati per nuova chat
            let chatData
            chatData = {
                ...data,
                members: data.members.length >0 ? data.members.map(obj => JSON.stringify(obj)) : []
                }
            }
            const response = await api().post(`${chat_list_endpoint}`, chatData);
            if(response.data?.id){
                createMsg({...strdata, chatGroupID: response.data.id})
            }
        } catch (error) {
        console.log(error);
        }
    }

    //HANDLE SEND TO CHAT
    function handleSendToChat(){
        let strdata;
        if (bodyForCreate.content) {
            strdata = {
            ...bodyForCreate,
            content: JSON.stringify(bodyForCreate.content).replace(/"/g, "'"),
            };
        if(chatData.type == "existingChat" && chatData.chatId) {
        createMsg(strdata)
        } else {
        createChat(bodyForChatCreation)
        }
        }   
    }

    //QUANDO INVITI FUNZIONANO, BISOGNA CONTROLLARE CHE CI SIANO SOLO LE CHAT COL SOGETTO DI RIFERIMENTO SELEZIONABILI

    //VALUTARE SE PER OWNERS, SUPPLIERS, CONDUCOTRS SALVARE SOLO ID  in subject
    //Perché di fatto a me serve solo quello per l'assegnazione

    //stessa cosa per unit e stabile, variabile a parte con solo id

    console.log(formData)

    const translationMapping = {
        id:"Id",
        cdat: "Data di creazione",
        udat: "Ultima modifica",
        userID: "ID utente",
        attachment: 'Allegati',
        conductors: 'Conduttori',
        owners: 'Proprietari',
        suppliers:"Fornitori",
        condominium: 'Condominio',
        unit: 'Unità',
        vatCode: 'IVA', //NUMBER
        amount: 'Imponibile', //NUMBER
        totalDue: 'Imponibile + IVA', //NUMBER 
        totalPaid: 'Totale già pagato',
        type: "ReceivePayment",

        subjectType: 'Tipo di soggetto',
        subject: 'Soggetto', 

        typeBuilding : "Tipo di Immobile", 
        condominium: 'Condominio', 

        competenceStartDate: "Inizio competenza", //DATE
        competenceEndDate: "Fine competeza", //DATE
        deadlineDate: "Scadenza", //DATE
        recurrenceType: 'Tipo di ricorrenza',
        every:"Ogni",
        toDate:"Ricorrenza fino a",

        installmentName:"Identificativo",

        attachment: 'Allegati', 
        note: "Note", //STRING
        relatedToPrimaNota: 'Correlato a una prima nota',
        primaNotaRelated: 'Prima nota correlata', //tutta la prima nota se possibile, non solo ID

        paid: 'Pagato', //per capire lo stato della rata
        
    }


        //PRENDI UNITA E CONDOMINI DEL SUBJECT

        const [subjectId, setSubjectId] = useState(null)
        //const [subjectName, setSubjectName] = useState("")


        useEffect(() => {
            if(editMode){
                if(formData.subjectType == "owner" || formData.subjectType == "conductor" || formData.subjectType == "supplier"){
                setSubjectId(formData[`${formData.subjectType}s`]?.[0]?.id)
                console.log("subjectId", formData[`${formData.subjectType}s`]?.[0]?.id)
                setSubjectName(`${formData.subjectType}s`)
                }
            } else {
                setSubjectId(null)
                setSubjectName("")
            }
        }, [formData])

        const unitsFiltered = formData.subjectType == "owner" ? 
            units.filter(unit => {
                return unit.owners.some(owner => owner.id === subjectId )
            }) :
            units.filter(unit => {
                return unit.conductors.some(conductor => conductor.id === subjectId )
            });
    
        console.log(unitsFiltered, formData.subjectType, units, subjectId, subjectName, formData.subjectName)
    
        const condominiumFiltered = formData.subjectType == "owner" ? 
            stabili.filter(condo => {
            return condo.units.some(unit => unit.owners.some(owner => owner.id === subjectId));
            }) :
            stabili.filter(condo => {
            return condo.units.some(unit => unit.conductors.some(conductor => conductor.id === subjectId));
        })

    const downloadFile = async (name) => {
        const fileUrl = `${BASE_URL}api/v1/installment/download/${rataId}/${name}`;
      
        const response = await axios({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
          
        },
        });
      
        // ottieni l'estensione del file
        const fileExtension = name.split('.').pop();
      
        const mimeType = mimeTypes[fileExtension];
      
        // crea un nuovo Blob con il tipo MIME corretto
        const file = new Blob([response.data], { type: mimeType });
      
        // crea un URL per il Blob
        const fileURL = URL.createObjectURL(file);
      
        // scarica il file
        window.open(fileURL);
      };
      
      //CHECK RATE DATA
    function areDataWrong(){
        console.log("running funcion")
        if(  (formData?.competenceEndDate && formData?.competenceStartDate ) || ( formData?.competenceEndDate && formData?.competenceStartDate && formData?.deadlineDate)){
            const start = Date.parse(formData?.competenceStartDate);
            const end = Date.parse( formData?.competenceEndDate);
            const deadline = formData?.deadlineDate ?  Date.parse(formData?.deadlineDate) : null

            if (start && end && deadline) {
                if((start < end) && (start < deadline)){
                    console.log("1.START E' MINORE DI END E DEADLINE")
                    return false
                } else {
                    console.log("1.START E' MAGGIORE DI END E/O DEADLINE")

                    return true
                }
            } else if (start && end){
                if(start < end){
                    console.log("2.START E' MINORE DI END")

                    return false
                } else {
                    console.log("2.START E' MAGGIORE DI END")

                    return true
                }
            }
        }
    }

    /* PER BREADCRUMBS RATE */
    let location = useLocation();

    console.log(location.state, "USELOCATION")


    useEffect(() => {

        setFormData(prev => {
            return {
                ...prev,
                condominium: {},
                unit: {},
                owners: {},
                suppliers:{},
                conductors:{}
            }
        })

    }, [formData.subjectType])

	return (
        <>
        <div className="mx-4 my-3">
            <TopHeading SmallHeading="" back_modal={false} />
           
            <div className="flex-grow-1">
            <Col md={12} className="my-3  px-0">
				<Breadcrumb>
					{(location.state?.from && location.state?.name) &&
                    <>
                    <Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate(`/rate`)
						}}>
						Rate
					</Breadcrumb.Item>

					
                    <Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate(`${location.state.from}`)
						}}>
						{location.state.name}
					</Breadcrumb.Item>
                    </>
                    }
					<Breadcrumb.Item active={true} 
						className="settings-archive"
					>
					{formData?.installmentName}

					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
                <div className="d-flex flex-column">
                    <h2 className="fs-24 fs-md-30 text-dark fw-semibold mb-md-0 text-capitalize">
                    {formData?.installmentName}
                    </h2>
                   {formData.cdat && <small>Creato il {formattedTimestamp(formData.cdat)}</small>}
                </div>
                </div>
                <div className="flex-shrink-0">
                <div
                    className={`d-flex align-items-center ${editMode ? "d-none" : ""}`}
                >
                    <button
                    className="modi-btn fs-16 text-white rounded-3 w-197 text-center py-2 px-3 me-2"
                    onClick={handlePaidStatus}
                    >
                    {!formData.paid? "Segna come Pagato" : "Crea prima nota"}
                    </button>
                    <button
                    type="button"
                    onClick={() => setModalDelete(true)}
                    className="border border-secondary bg-white text-secondary rounded-3 py-2 px-3"
                    >
                    <BsTrash />
                    </button>
                </div>
                <div
                    className={`d-flex align-items-center ${editMode ? "" : "d-none"}`}
                >
                    <ButtonRm
                    variant="secondary"
                    onClick={handleUpdate}
                    disabled={(formData.competenceStartDate && formData.competenceEndDate && formData.deadlineDate && (areDataWrong() == false) && formData.subjectType && formData.amount && formData.installmentName)? false : true}
                    >
                    Salva modifiche
                    </ ButtonRm>
                    <button
                    type="button"
                    onClick={() => {
                        setEditMode(false);
                        fetchRata()
                    }}
                    className="border border-secondary bg-white text-secondary rounded-3 py-2 px-3 ms-2"
                    >
                    <i className="bi bi-x"></i>
                    </button>
                </div>
                </div>
            </div>
        <div className="dashboard-container">
            <div className={editMode ? "d-none" : ""}>
                <ActionsIcons setEditMode={setEditMode} modalSendShow={modalSendShow} item={formData} mapping={translationMapping}/>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
                    <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="5" activeKey={activeKey}>Identificativo <span className="mandatory-field"> *</span>
                            <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                            </CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="my-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label mb-3 fs-16 d-flex align-items-center
">
                                                Identificativo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                    name="installmentName"
                                                    type="text"
                                                    value={formData?.installmentName}
                                                    onChange={onChange}
                                                    disabled={editMode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="0" activeKey={activeKey}>Tipologia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="my-2" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex">
                                                <Form.Check
                                                    inline
                                                    label="Devo ricevere un pagamento"
                                                    type="radio"
                                                    value="ReceivePayment"
                                                    checked={formData?.type === "ReceivePayment"}
                                                    onChange={onChange}
                                                    name="type"
                                                    disabled={editMode ? false : true}
                                                />
                                                {/* <Form.Check
                                                    inline
                                                    label="Devo fare un pagamento"
                                                    type="radio"
                                                    value="MakePayment"
                                                    checked={formData?.type === "MakePayment"}
                                                    onChange={onChange}
                                                    name="type"
                                                    disabled={editMode ? false : true}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </Row>	
                                <Row className="my-3" >
                                <div className="col-md-6">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                            {formData?.type == "MakePayment" ? "A chi devi fare il pagamento?" : "Da chi devi ricevere il pagamento?"} <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                        </label>
                                        <div className="d-block">
                                        <select
                                            name="subjectType"
                                            onChange={onChange}
                                            value={formData?.subjectType}
                                            disabled={true}

                                            //disabled={props.edit_mode ? false : true}
                                        >
                                            <option value="">Seleziona</option>
                                            <option value="owner">Proprietario</option>
                                            <option value="conductor">Conduttore</option>
                                            <option value="supplier">Fornitore</option>
                                            <option value="other">Altro</option>
            
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                {(  formData?.subjectType == "supplier" ||
                                    formData?.subjectType == "conductor" ||
                                    formData?.subjectType == "owner" ) 
                                    && 
                                        <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Nominativo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name={subjectName}
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.subjectName?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                        disabled={true}

                                                    >
                                                        <option value="">Seleziona</option>
                                                        {subjects?.length>0 && subjects.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.surname} {item.name} 
                                                            {formData?.subjectType == "supplier" && item.category? ` - ${item.category}`: ""}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                                </div>
                                        }		
                                </Row>						
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="1" activeKey={activeKey}>Immobile di riferimento</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="d-flex">
                                                <Form.Check
                                                inline
                                                label="Unità"
                                                type="radio"
                                                value="Unità"
                                                checked={formData?.typeBuilding === "Unità"}
                                                onChange={onChange}
                                                name="typeBuilding"
                                                disabled={ true}
                                                />
                                                <Form.Check
                                                inline
                                                label="Stabile"
                                                type="radio"
                                                value="Stabile"
                                                checked={formData?.typeBuilding === "Stabile"}
                                                onChange={onChange}
                                                name="typeBuilding"
                                                disabled={editMode ? false : true}
                                                />
                                            </div>
                                        </div>
                                        {formData.typeBuilding == "Unità" && <>
                                        <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Unità
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="unit"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.unit?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                        disabled={editMode ? false : true}
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {(formData.subjectType == "supplier" || formData.subjectType == "other") && units.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                        {(formData.subjectType == "owner" || formData.subjectType == "conductor") && unitsFiltered.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                                </div>
                                        </>}
                                        {formData.typeBuilding == "Stabile" && <>
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Stabile
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="condominium"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.condominium?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                        disabled={editMode ? false : true}
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {(formData.subjectType == "supplier" || formData.subjectType == "other") && stabili.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                        {(formData.subjectType == "owner" || formData.subjectType == "conductor") && condominiumFiltered.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="2" activeKey={activeKey}>Date <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Inizio competenza <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="competenceStartDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.competenceStartDate && formatDateFromString(formData?.competenceStartDate)}
                                                        onChange={onChange}
                                                        disabled={editMode ? false : true}
                                                        ></input>
                                                    </div>
                                                    {
                                                            (areDataWrong()  && 
                                                            <small className="text-danger"><i class="bi bi-exclamation-circle me-2"></i>La data di Inizio Competenza non può essere successiva alla data di Fine Competenza e Scadenza</small>
                                                            )
                                                        }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Fine competenza <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="competenceEndDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.competenceEndDate && formatDateFromString(formData?.competenceEndDate)}
                                                        onChange={onChange}
                                                        disabled={editMode ? false : true}
                                                        ></input>
                                                    </div>
                                                </div>
                                                </div>

                                            <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Scadenza <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="deadlineDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.deadlineDate && formatDateFromString(formData?.deadlineDate)}
                                                        onChange={onChange}
                                                        disabled={editMode ? false : true}
                                                        ></input>
                                                    </div>
                                                </div>
                                                </div>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="3" activeKey={activeKey}>Importo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Imponibile (€) <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                    name="amount"
                                                    type="number"
                                                    value={formData?.amount}
                                                    onChange={onChange}
                                                    disabled={editMode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Codice IVA <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                </label>
                                                <select
                                                    name="vatCode"
                                                    onChange={onChange}
                                                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                                                    disabled={editMode ? false : true}
                                                    value={formData?.vatCode}
                                                >
                                                <option value={1}> Esente </option>
                                                <option value={1.04}> 4% </option>
                                                <option value={1.05}> 5% </option>
                                                <option value={1.1}> 10% </option>
                                                <option value={1.2}> 20% </option>
                                                <option value={1.21}> 21% </option>
                                                <option value={1.22}> 22% </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Totale versato (€) 
                                                    </label>
                                                    <div className="d-block">
                                                    <input
                                                        name="totalPaid"
                                                        type="number"
                                                        value={formData?.totalPaid}
                                                        onChange={onChange}
                                                        max={formData?.amount}
                                                        disabled={editMode ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Totale dovuto
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                        name="totalDue"
                                                        type="number"
                                                        value={formData?.totalDue}
                                                        disabled

                                                        //onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="4" activeKey={activeKey}>Ricorrenza
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 600, hide: 300 }}
                                    overlay={
                                    <Tooltip className='tooltipContabilita'>
                                        <p>
                                        Si tratta di un pagamento ricorrente? Anziché creare più volte lo stesso pagamento, puoi impostare di seguito i dettagli di ricorrenza di questo. <br/> Se ad esempio si tratta di un pagamento che devi ricevere ogni  mese fino al 31/12/2023, dovrai selezionare: <br/>
                                        Frequenza:  "Mesi", <br/>
                                        Ogni: 1, <br/>
                                        Fine Ricorrenza: "31/12/2023"
                                        </p></Tooltip>
                                    }
                                    >
                                    <i class="bi bi-info-circle ms-2"></i>
                                </OverlayTrigger>
                            </CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-12">
                                            <p className="opacity-75">Se il pagamento che stai impostando è un pagamento ricorrente, come ad esempio un affitto, puoi impostare le specifiche per automatizzarlo.</p>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Frequenza
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="recurrenceType"
                                                        value={formData.recurrenceType}
                                                        onChange={onChange}
                                                        disabled={editMode ? false : true}
                                                    >
                                                        <option value=""></option>
                                                        <option value="giorno/i">Giorno/i</option>
                                                        <option value="settimana/e">Settimana/e</option>
                                                        <option value="mese/i">Mese/i</option>
                                                        <option value="anni/i">Anno/i</option>
                                                    </select>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Ogni
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                    type="number" 
                                                    placeholder="Inserisci un numero" 
                                                    name="every" 
                                                    value={formData.every} 
                                                    onChange={onChange}
                                                    disabled={editMode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Fine ricorrenza
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="toDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.toDate}
                                                        onChange={onChange}
                                                        disabled={editMode ? false : true}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>

                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                       

                        <Accordion.Item eventKey="6" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="6" activeKey={activeKey}>Altro</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-12">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Note
                                                </label>
                                                <div className="d-block">
                                                    <textarea
                                                    name="note"
                                                    className="border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
                                                    value={formData?.endDate}
                                                    onChange={onChange}
                                                    disabled={editMode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-12 mt-3">
                                            <div className="d-flex">
                                                <Form.Check
                                                inline
                                                label="Imputabile in prima nota"
                                                type="checkbox"
                                                checked={formData?.relatedToPrimaNota}
                                                onChange={onChange}
                                                name="relatedToPrimaNota"
                                                disabled={editMode ? false : true}
                                                />
                                            </div>
                                        </div>

                                        {formData?.relatedToPrimaNota && 
                                            <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Prima nota di riferimento
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="primaNotaRelated"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.primaNotaRelated)}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                        disabled={editMode ? false : true}
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {primeNote.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.operationDescription}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>} */}

                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="20" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="20" activeKey={activeKey}>Allegati</CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                <label
                                    htmlFor="input-file"
                                    className={`black-link my-2 ${editMode ? "" : "d-none"}`}
                                >
                                    <BsPlusCircleFill className="plus-icon" />
                                    Aggiungi allegati
                                </label>
                                <input
                                    type="file"
                                    id="input-file"
                                    className="d-none"
                                    multiple={false}
                                    onChange={handleChange}
                                ></input>
                                {formData.attachment?.length > 0
                                    ? formData.attachment?.map((item, index) => {
                                        return (
                                        <FileCard
                                            key={index}
                                            name={item}
                                            setFileName={setFileName}
                                            deleteFile={deleteFile}
                                            id={index}
                                            download={downloadFile}
                                        />
                                        );
                                    })
                                    : ""}		

                                    {newAttachments.length > 0 ? (
                                        newAttachments.map((file, index) => {
                                            return (
                                            <FileCard
                                                key={index}
                                                name={file.name}
                                                id={index}
                                                download={() => {}}
                                                isNewAttachment={true}
                                            />
                                            );
                                        })
                                        ) : null}	
                                </Accordion.Body>
                                </Accordion.Item>



                    </Accordion>
            </Form>
        </div>

        {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
        <Modal show={alertPrimaNota} onHide={() => setAlertPrimaNota(false)} className="p-5" centered >
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
            className="px-md-5"
          >
          </Modal.Header>
          <Modal.Body className=" px-md-5">
          <Modal.Title className="mb-3">Vuoi creare una prima nota per questo pagamento?</Modal.Title>
            <p>
            Registrare ogni pagamento ricevuto/inviato è importante ai fini della rendicontazione. Potrai comunque farlo in un secondo momento cliccando sul pulsante “aggiungi in prima nota”.
            </p>
          </Modal.Body>
          <Modal.Footer
            className="d-flex flex-column  px-md-5 pb-md-4"
            style={{ borderTop: "none" }}
          >
            <ButtonRm variant="primary" onClick={handlePrimaNotaModal}>
                Crea prima nota
            </ButtonRm>
            <ButtonRm variant="tertiary" onClick={() => setAlertPrimaNota(false)}>
                Non adesso
            </ButtonRm>
          </Modal.Footer>
        </Modal>
        <Modal
            show={modalPrimaNota}
            fullscreen={true}
            className="right-full-pop"
            onHide={() => setModalPrimaNota(false)}
        >
            <Modal.Body className="p-4">
            <CreateDocumenti setShow={setModalPrimaNota} />
            </Modal.Body>
        </Modal>
        
        {/* DELETE MODAL */}
        <Modal show={modalDelete} onHide={() => setModalDelete(false) } centered>
        <Modal.Body className="p-5">
            <div className="d-flex flex-column text-center">
                <h2 className="fs-32 text-dark fw-semibold mb-3">
                Vuoi eliminare questo elemento?
                </h2>
                <h6 className="fs-16 text-dark fw-normal mb-3">
                Questa azione è irreversibile
                </h6>
                <button
                type="button"
                className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
                onClick={(e) =>{ 
                    e.preventDefault()
                    e.stopPropagation()
                    deleteRata()
                    setModalDelete(false)
                    navigate(-1)
                }}
                >
                Conferma
                </button>
                <button
                type="button"
                className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
                onClick={() => setModalDelete(false)}
                >
                Annulla
                </button>
            </div>
        </Modal.Body>
        </Modal>

        {/* SEND TO CHAT */}
        <Modal show={modalSendChat} onHide={() => setModalSendChat(false) } centered>
        <Modal.Body className="p-5">
            <div className="d-flex flex-column">
                <h2 className="fs-32 text-dark fw-semibold mb-3">
                Invia rata in chat
                </h2>
                <h6 className="fs-16 text-dark fw-normal mb-3">
                L’utente che riceverà questa rata potrà pagarla direttamente dalla chat.
                </h6>
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex">
                            <Form.Check
                                inline
                                label="Seleziona chat esistente"
                                type="radio"
                                value="existingChat"
                                checked={chatData.type === "existingChat"}
                                onChange={handleChatSend}
                                name="type"
                            />
                            <Form.Check
                                inline
                                label="Crea nuova chat"
                                type="radio"
                                value="newChat"
                                checked={chatData.type === "newChat"}
                                onChange={handleChatSend}
                                name="type"
                            />
                        </div>
                    </div>
                </div>

                {chatData.type == "existingChat" ?
                    <div className="d-flex flex-column my-3">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                            Seleziona chat
                        </label>
                        <div className="d-block">
                        <select
                            name="chatId"
                            onChange={handleChatSend}
                            value={chatData.rataId}
                        >
                            <option>Seleziona</option>
                            {
                                chatList.length > 0 &&
                                chatList.map(item => {
                                    return <option kye={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </select>
                        </div>
                    </div> 
                    :
                    <div className="my-3">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                            Nome chat
                        </label>
                        <div className="d-block">
                            <input
                            name="name"
                            type="text"
                            value={chatData?.name}
                            onChange={handleChatSend}
                            ></input>
                        </div>
                </div>
                }

                <ButtonRm variant="primary" onClick={handleSendToChat}>Invia</ButtonRm>
                    
                <button
                type="button"
                className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
                onClick={() => setModalSendChat(false)}
                >
                    Annulla
                </button>
            </div>
        </Modal.Body>
        </Modal>
        </>
	);
}

export default ViewRata;
