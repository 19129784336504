import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import api, { stabili_endpoint, insurance_endpoint, supplier_condominium_assignment_endpoint } from "../../../api/api";
import RegistryE from "./RegistryE";
import AccountE from "./AccountE";
import NoteE from "./NoteE";
import ProviderE from "./ProviderE";
import InsuranceE from "./InsuranceE";
import AttachmentE from "./AttachmentE";
import ActivitiesE from "./ActivitiesE";
import CondominiumE from "./CondominiumE";

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Col } from "react-bootstrap";


const EditStabili = () => {
	const navigate = useNavigate()
	const { id } = useParams();
	const [formData, setformData] = useState({});
	const [state, setState] = useState(0);
	const handleState = (index) => {
		setState(index);
	};

	const getStabiliById = async () => {
		try {
			const res = await api().get(`${stabili_endpoint}${id}`);
			if (res.data) {
				// res.data.otherOpenSpace = JSON.parse(
				//   res.data.otherOpenSpace.replace(/'/g, '"')
				// );
				// res.data.other = JSON.parse(res.data.other.replace(/'/g, '"'));
				// res.data.otherDistance = JSON.parse(
				//   res.data.otherDistance.replace(/'/g, '"')
				// );
				if (res.data.currentAccounts && Array.isArray(res.data.currentAccounts)) {
					const currentAccounts = res.data.currentAccounts.map((item) => JSON.parse(item.replace(/'/g, '"')));
					res.data.currentAccounts = currentAccounts	
				}
				setformData(res.data);
				console.log(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getStabiliById();
	}, []);

	const update = async (data) => {

		console.log(data, "stabil att")

		//DELETE FORNITORI INSURANCE 
		//STRINGIFY CONTI CORRENTI
		const newForm = { ...formData, ...data }

		const suppliers = [...newForm.suppliers];
		const insurances = [...newForm.insurances];
		const attachment = [...newForm.attachment]
		const newAttachment = [...newForm.newAttachment]

		delete newForm.suppliers
		delete newForm.insurances
		delete newForm.attachment
		delete newForm.newAttachment

		//GESTIONE ALLEGATI
		let formDataToSend = new FormData();
	
		newAttachment.forEach((file) => {
			const blob = new Blob([file]);
			formDataToSend.append("parts", blob, file.name);
		});

		let sentUpload = formDataToSend.get("parts") !== null;
		if (sentUpload) {
		await api().put(`${stabili_endpoint}upload/${id}`, formDataToSend);
		}
/* 
		// PRIMA DI TUTTO Creazione delle assicurazioni - manca endpoint condo insurance
		const newInsurances = [];

		if (insurances.length > 0) {
			for (const insuranceData of insurances) {
				//stringify agency first
				const agencyString = JSON.stringify(insuranceData.agency);
				const insuranceDataStringified = {
				...insuranceData,
				agency: agencyString,
				};

				// Verifica se assicurazione è già presente nell'existingArray
				const isAlreadyCreated = formData.insurances.some(existingIns => existingIns.id === insuranceData.id);

				// Se non è già stato creato, effettua la creazione
				if(!isAlreadyCreated){
					const createdInsuranceResponse = await api().post(`${insurance_endpoint}`, insuranceDataStringified);
					const createdInsurance = createdInsuranceResponse.data;
					newInsurances.push(createdInsurance)
				}
			}
		} */

		try {
			let response
			response = await api().put(`${stabili_endpoint}${id}`, {
				...newForm,
				currentAccounts: newForm.currentAccounts.map((account) => JSON.stringify(account))
			})


			//ASSOCIAZIONE FORNITORI
			if (suppliers.length > 0 && response.data.id) {
				console.log("sono dentro1")
					for (const supplier of suppliers) {
						const isAlreadyAssociated = formData.suppliers.some(existingEl => existingEl.id == supplier.id);

						console.log("sono dentro2", isAlreadyAssociated, formData.suppliers)
					if (!isAlreadyAssociated) {	
						console.log("sono dentro3 - we-we")

						await api().post(`${supplier_condominium_assignment_endpoint}`, {
							supplierID: supplier.id,
							condominiumID: response.data.id,
						})
					}
				}
			}

			//ASSOCIAZIONE assicurazioni
			/* if (insurances.length > 0 && response.data.id) {
					for (const insurance of insurances) {
						const isAlreadyAssociated = formData.insurances.some(existingEl => existingEl.id == insurance.id);

					if (!isAlreadyAssociated) {	
						const agencyString = JSON.stringify(insurance.agency);
						const insuranceDataStringified = {
						...insurance,
						agency: agencyString,
						condominiumID: response.data.id
						};

						await api().post(`${insurance_endpoint}`, insuranceDataStringified);
					}
				}
			} */


		} catch (err) {
			console.log(err);
		}

		await getStabiliById()
	};

	console.log(formData)

	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="" back_modal={false} />
				</div>
			</div>
			<Col md={12} className="my-3 px-0 mx-2">
				<Breadcrumb>
					<Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate('/stabili')
						}}>
						Stabili
					</Breadcrumb.Item>

					
				
					<Breadcrumb.Item active={true} 
						className="settings-archive"
					>
						{formData?.name}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<h2 className="title-font fs-24 fw-bold text-capitalize">
				{formData.name ? formData.name : ""}
			</h2>
			<div className="d-flex flex-column pt-10">
				<div className="state-bar no-img-state-bar">
					<div className="state-btn-group d-flex">
						<div
							className={`${state === 0 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(0);
							}}>
							Anagrafica
						</div>
						<div
							className={`${state === 1 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(1);
							}}>
							Conti Correnti
						</div>

						<div
							className={`${state === 2 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(2);
							}}>
							Fornitori
						</div>
						<div
							className={`${state === 3 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(3);
							}}>
							Lavori
						</div>
						<div
							className={`${state === 4 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(4);
							}}>
							Assicurazioni
						</div>
						<div
							className={`${state === 5 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(5);
							}}>
							Condomini
						</div>
						<div
							className={`${state === 6 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(6);
							}}>
							Allegati
						</div>
					</div>
				</div>
				<div className="w-100">
					<div
						className="mobile-state-bar"
						onChange={(e) => {
							handleState(e.target.value);
						}}>
						<select className="select-state">
							<option value="0">Anagrafica</option>
							<option value="1">Conti Correnti</option>
							<option value="2">Fornitori</option>
							<option value="3">Lavori</option>
							<option value="4">Assicurazioni</option>
							<option value="5">Condomini</option>
							<option value="6">Allegati</option>
							
						</select>
					</div>
					{state == 0 && formData && (
						<RegistryE formData={formData} update={update} />
					)}

					{state == 1 && formData && (
						<AccountE accounts={formData.currentAccounts} update={update} />
					)}

					{state == 2 && formData && (
						<ProviderE suppliers={formData.suppliers} update={update} />
					)}

					{state == 3 && formData && <ActivitiesE formData={formData} />}

					{state == 4 && formData && (
						<InsuranceE insurances={formData.insurances} update={update} formData={formData} getStabiliById={getStabiliById}/>
					)}

					{state == 5 && formData && <CondominiumE units={formData.units} />}

					{state == 6 && formData && (
						<AttachmentE attachment={formData.attachment} update={update} id={id} />
					)}
				</div>
			</div>
		</>
	);
};

export default EditStabili;
