import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import Table from "react-bootstrap/Table";
import api, { supplier_endpoint, owner_endpoint } from "../../../api/api";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ButtonRm from "../../ButtonRm";

import delete_icon from "../../../assets/images/delete.svg";
import ImgFileCard from "./ImgFileCard";

import CustomToggle from "../../CustomToggle";

import ActModal from "./ActModal";


const actType = [
    "Successione",
    "Compravendita",
    "Divisione",
    "Donazione",
    "Riunione usufrutto e successione",
];

const Atti = (props) => {

	//ADD ATTO

	const [show, setShow] = useState(false);


	const addAct = (act) => {
		let temp = [...props.formData.acts];
		temp.push(act);
		let e = { target: { name: "acts", value: temp } };
		props.onChange(e);
		setShow(false);
	}; 

	console.log(props.formData.acts, 'acts')

	console.log(props.formData.acts, "atti arr")

	//VIEW E EDIT LETTURA

	const [showSelectedAct, setShowSelectedAct] = useState(false)
	const [selectedAct, setSelectedAct] = useState({})
	const [actEdit, setActEdit] = useState(-1);


	function closeSelectedAct(){
		setShowSelectedAct(false)
		setSelectedAct({})
		setActEdit(null)

	}

	function handleAct(id){
		setShowSelectedAct(true)
		let current =  props.formData.acts.find((item, index) => index == id)
		setSelectedAct(current)
		setActEdit(id)
	}


	function updateAct (act){
		let temp =  props.formData.acts.map((item, index) => {
			return index == actEdit?
			act:
			item
		})
		props.setFormData({ ...props.formData, ["acts"]: temp });
		closeSelectedAct()
	};

	const deleteAct = () => {
		let temp =  props.formData.acts.filter((item, index) => index != actEdit)
		props.setFormData({ ...props.formData, ["acts"]: temp }); 
		closeSelectedAct()
	}; 


	return (
		<div  className="form-item-align-box-white d-block">
			<div >
				<h2 className="fs-20 text-dark mb-2 fw-bold">Atti</h2>
			</div>
			<div className="unit-btn-group mt-3">
				<button
					type="button"
					className="general-btn white-btn w-193"
					onClick={(e) => {
						setShow(true);
					}}>
					Nuovo
				</button>
			</div>
			{props.formData.acts.length > 0 ? (
										<Table className="mt-3 result-table table-border out-border-none">
											<thead>
												<tr>
													<th>Tipo</th>
													<th>Cedente</th>
                                                    <th>Cessionario</th>
                                                    <th>Data dell’atto</th>
													<th>Data di effetto</th>
													
												</tr>
											</thead>
											<tbody>
												{props.formData.acts.map((item, index) => {
													return (
														<tr
															key={index}
															onClick={() => handleAct(index)}
                                                            >
															<td>{item.type}</td>
															<td>{item.transferor}</td>
                                                            <td>{item.transferee}</td>
                                                            <td>{item.actDate}</td>
															<td>{item.fiscalEffectDate}</td>
															
														</tr>
													);
												})}
											</tbody>
										</Table>
									) : (
										<div className="unit-empty-img">
											<label className="edit-black-label mt-5 mb-5">
												Nessun atto notarile precedente inserito
											</label>
										</div>
									)}

	{/* CREATE ATTO */}
		<Modal show={show}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={() => {
              setShow(false);
            }}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
          <ActModal  setShow={setShow} newMode={true} add={addAct} formData={props.formData}/>
        </Modal.Body>
      </Modal>
			
		{/* VIEW ATTO*/}
		 <Modal show={showSelectedAct}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={closeSelectedAct}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
		  <ActModal setShow={closeSelectedAct} atto={selectedAct}  newMode={false} add={updateAct} delete={deleteAct} formData={props.formData}/>
        </Modal.Body>
      </Modal> 

		</div>
	);
};

export default Atti;
