import React, { createContext, useEffect, useState, useCallback } from "react";
import ColomboList from "../components/Membri/ColomboList";
import EmptyList from "../components/Membri/EmptyList";
import NewownerBtn from "../components/Membri/Fornitori/NewownerBtn";
import TopHeading from "../components/TopHeading";

import axios from "axios";
import { token,  BASE_URL } from '../api/api.js';
import user from "../assets/images/fornitore-ph.svg";
import Container from "../components/Container";
import TabForm from "../components/Membri/Fornitori/create_mode/TabForm";

import {Row, Col, Modal} from "react-bootstrap"
import { Link } from "react-router-dom";
import AddBtn from "../components/AddBtn";

import filter from "../assets/images/icon-sidebar/filter.svg"
import sort from "../assets/images/icon-sidebar/sort.svg"

import SearchInput from "../components/SearchInput";

import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

import api, { unit_endpoint, supplier_endpoint } from "../api/api.js";

import FilterSearchBar from "../components/StatoLavoriInCorso/Gestioni/FilterSearchBar";

import translationMapping from "../components/Membri/mapping";

import Dropzone from 'react-dropzone'

import transmigrationFunction from "../utils/transmigrationFunction";

import { checkUserData, checkUserRole, checkMaster } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";

import Alert from "react-bootstrap/Alert";

import ButtonRm from "../components/ButtonRm.js";


import { formattedTimestamp } from "../utils/dateManipulation.js";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TableSortLabel } from '@mui/material';

import CustomBadge from "../components/CustomBadge.js";




// Definisci le varianti dei badge
const badgeVariants = [
	'custom-badge-success',
	'custom-badge-danger',
	'custom-badge-alert',
	'custom-badge-info',
	'custom-badge-pagina',
	'custom-badge-gray',
	'custom-badge-red',
	'custom-badge-orange',
	'custom-badge-yellow',
	'custom-badge-lime',
	'custom-badge-green',
	'custom-badge-teal',
	'custom-badge-turquoise',
	'custom-badge-aqua',
	'custom-badge-blue',
	'custom-badge-ultramarine',
	'custom-badge-purple',
	'custom-badge-pink',
	'custom-badge-transparent',
];

const headerNames = ["cdat", "name", "surname", "fiscalCode", "businessName", "vatNumber", "officeProvince", "officeMunicipality", "officeAddress", "officeTelephone", "email", "pec"];


// Funzione per ottenere una variante casuale
const getRandomBadgeVariant = () => {
const randomIndex = Math.floor(Math.random() * badgeVariants.length);
return badgeVariants[randomIndex];
};

const columnBadgeVariantMap = {};
headerNames.forEach((header, index) => {
columnBadgeVariantMap[header] = badgeVariants[index % badgeVariants.length];
});


const FornitoriText = createContext();

export default function Fornitori() {
	const [show, setShow] = useState(false);
	const [searchName, setSearchName] = useState("");
	const [loading, setLoading] = useState(false);
	const [provider, setProvider] = useState(true);

	const [items, setItems] = useState([])

	function getSuppliers(){
		axios
			.get(BASE_URL + "api/v1/supplier/", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setLoading(false);
				if (res.data.content.length >= 1) {
					setProvider(false);
				} else {
					setProvider(true);
				}
				setItems(res.data.content)
			});
	}



	useEffect(() => {
		setLoading(true);
		axios
			.get(BASE_URL +  "api/v1/supplier/", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setLoading(false);
				if (res.data.content.length >= 1) {
					setProvider(false);
				} else {
					setProvider(true);
				}
				setItems(res.data.content)
			});
	}, []);


	 // Funzione di callback per l'eliminazione di un elemento
	 const handleItemDeleted = () => {
		setLoading(true);
		axios
			.get(BASE_URL +  "api/v1/supplier/", {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => {
				setLoading(false);
				if (res.data.content.length >= 1) {
					setProvider(false);
				} else {
					setProvider(true);
				}
				setItems(res.data.content)
			});
	  };

	  //NEW SEARCH & FILTER

	  const [units, setUnits] = useState([]);

	  async function getUnits() {
		  try {
			  const response = await api().get(`${unit_endpoint}`);
			  if (response.data) {
				  setUnits(response.data.content);
			  }
		  } catch (err) {
			  console.log(err);
		  }
	  }
  
	  useEffect(() => {
		  getUnits()
	  }, []);
  
  
	  //SEARCH
  
	  const [searchTerm, setSearchTerm] = useState("")
  
	  const [startDate, setStartDate] = useState('');
	  const [endDate, setEndDate] = useState('');
  
	
  
  
	  const categories = [
		  {
			name: 'Categoria',
			items: [
				"Assicurazione",
				"Geometra",
				"Idraulico",
				"Notaio",
				"Riparazioni Varie",
				"Ristrutturazioni",
				"Stato",
				"Utenze - Energia",
				"Amministratore"
			]
		  },
		  {
		  name: 'Unità',
		  items: units.map((unit) => unit.name),
  
		  },
		  /* {
			  name: 'Gestione',
			  items: gestioni.map(el => String(el.id)),
		  } */
		
	  ];
	  
		  const [selectedCategories, setSelectedCategories] = useState([]);
		  const [sortBy, setSortBy] = useState("meno-recenti");
  
		  const [filteredUnits, setFilteredUnits] = useState([])
  
  
	  useEffect(() => {
		if (items.length > 0) {
		  setFilteredUnits(
			  items.filter((el) => {
  
			  const elDate = new Date(el.cdat); 
  
			  // Filtro per la data di inizio (startDate)
			  if (startDate && elDate < new Date(startDate)) {
				  return false;
			  }
		  
				  // Filtro per la data di fine (endDate)
				  if (endDate && elDate > new Date(endDate)) {
				  return false;
				  }
		  
				  return (
				  (selectedCategories.length === 0 ||
					  selectedCategories.some((cat) => {
					  if (cat.name === 'Unità') {
						  return el.units.some((unit) => {
						  return cat.items.includes(unit.name);
						  });
					  }  else {
						  return cat.items.includes(el.category);
					  }
					  })) &&
					  (el.name?.toLowerCase().includes(searchTerm.toLowerCase()) || el.surname?.toLowerCase().includes(searchTerm.toLowerCase()) || el.businessName?.toLowerCase().includes(searchTerm.toLowerCase())
					  )
				  );
			  })
			  );
		  }
	  }, [searchTerm, selectedCategories, items, startDate, endDate]);

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
		setSortedItems(filteredUnits)
		}, [filteredUnits])

		console.log({
		items, filteredUnits, sortedItems
		})
  
		useEffect(() => {
			switch (sortBy.toLowerCase()) {
				case "z-a":
				setSortedItems(sortStringByKey(filteredUnits, "surname", false));
				break;
				case "a-z":
				setSortedItems(sortStringByKey(filteredUnits, "surname"));
				break;
				case "meno-recenti":
				setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
				break;
				default:
				case "più-recenti":
				setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
			}
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
			setSearchTerm("");
			setSelectedCategories([]);
			setSortBy("più-recenti");
			setFilteredUnits(units);
			setStartDate("")
			setEndDate("")
		}

		//gestione visione tabellare
		const [multiView, setMultiView] = useState(true)
		const [viewType, setViewType] = useState(localStorage.getItem('viewTypeFornitori') || 'card');

		useEffect(() => {
		localStorage.setItem('viewTypeFornitori', viewType);
		}, [viewType]);


		//GESTIONE FILE TRASMIGRAZIONE

		const mappingFromCompetitor1 = {
		'Denominazione': 'businessName',
		'Tipo di soggetto': 'subjectType',
		'Categoria': 'category',
		'CF': 'fiscalCode',
		'P. IVA': 'vatNumber',
		'Nome': 'name',
		'Cognome': 'surname',
		'Indirizzo': 'officeAddress',
		'Civico': 'officeHouseNumber',
		'Comune': 'officeMunicipality',
		'Prov': 'officeProvince',
		'Tel. Ufficio': 'officeTelephone',
		'Fax': 'fax',
		'E-Mail normale': 'email',
		'E-mail certificata': 'pec',
		'Sito web': 'website',
		};

		 //CHECK IF DATA REQUIRED ARE COMPILED
		 const [dataRequired, setDataRequired] = useState(true);
		 //Gestisce il modale
		 const [showCheck, setShowCheck] = useState(false)
	 
		 useEffect(() => {
			 const fetchData = async () => {
				 try {
					 const response = await checkUserData();
					 setDataRequired(response.isVerified);
					 if (!response.isVerified) {
					   setShowCheck(true);
				   }
				 } catch (error) {
					 // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
					 console.error('Errore durante il recupero dei dati utente:', error);
				 }
			 };
	 
			 fetchData();
		 }, []);

		 	 //gestione selezione multipla
		const [selectedItems, setSelectedItems] = useState([]);

		// Funzione per gestire la selezione multipla
		const handleSelection = (e,id) => {
			if(e){
				e.stopPropagation()
			}

			if (selectedItems.includes(id)) {
				setSelectedItems(selectedItems.filter((itemId) => itemId !== id));
			} else {
				setSelectedItems([...selectedItems, id]);

			}
		};

		const handleSelectAll = () => {
			let selectedItems = sortedItems.map((item, index) => item.id)
			setSelectedItems(selectedItems)
		}

		const handleDeselectAll = () => {
			setSelectedItems([])
		}

		// Funzione per eliminare gli stabili selezionati
		const deleteSelected = async () => {
			// Effettua l'operazione di eliminazione per ogni id selezionato
			for (const id of selectedItems) {
			try {
				await api().delete(`${supplier_endpoint}${id}`);
			} catch (err) {
				console.log(err);
			}
			}
			getSuppliers()
			setSelectedItems([]);
		};


		 //handle modal delete
		const [showModal, setShowModal] = useState(false);
		const handleClose = (e) => {
			setShowModal(false);
			e.preventDefault();
			e.stopPropagation();
		};

		const handleShow = () => setShowModal(true);
	
		//handle modal permessi
		const [showPermit, setShowPermit] = useState(false);
		const handleClosePermit = (e) => {
			setShowPermit(false);
			e.preventDefault();
			e.stopPropagation();
		};

		const handleShowPermit = () => setShowPermit(true);

		//MI SERVE LISTA COLLABORATORI MASTER
		const [members, setMembers] = useState([
		{
			id:1,
			name:"Ema",
			surname:"Mavi",
			email:"ema@gmail.com"
		},
		{
			id:2,
			name: 'Greta',
			surname: 'Pio',
			email: 'gre@gmail.com'
		}
		])
	
		const handleCheckboxChange = (userId, action) => {
		//da gestire
		};


		const [order, setOrder] = useState('asc');
		const [orderBy, setOrderBy] = useState('');
	
		const handleRequestSort = (property) => {
			const isAsc = orderBy === property && order === 'asc';
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(property);
		};
	
		const sortedUnits = sortedItems.sort((a, b) => {
			if (orderBy) {
			/* if (typeof a[orderBy] === 'number' && typeof b[orderBy] === 'number') {
				return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
			} */
			if (a[orderBy] && b[orderBy]) { 
				return order === 'asc'
				? a[orderBy].localeCompare(b[orderBy])
				: b[orderBy].localeCompare(a[orderBy]);
			}
		}
			return 0;
		});

	return (
		<>
			{/* {loading &&
                <div className='loader'>
                    <div className='loading-bar'>
                        <FadeLoader color={'white'} loading={loading} size={300} />
                    </div>
                </div>
            } */}
			<div className="title-search-group mobile-container-xl ">
        <div className="right-header">
			<TopHeading
				SmallHeading="empty"
			/>
			</div>
			<Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
			<Col >
				<h2 className="fw-bold text-start">Fornitori</h2>
			</Col>
			<Col className="d-flex justify-content-end ">
				<Link to="/fornitori/create">
				<AddBtn
					tooltip="Nuovo conduttore"
					className="me-3 me-md-0 order-md-1"
					dataRequired={dataRequired}
				/>
				</Link>
			</Col>
			</Row>
			
		</div>
			{show ? (
				<TabForm setShow={(v) => setShow(v)} />
			) : (
				<div>
					{provider == true ? (
						<Row className="dashboard-container ">
							<div className="mx-auto  text-center empty-container ">
								<div className="mx-auto d-table ">
								<img loading="lazy" src={user} alt="empty" />
								</div>
								<p className="fs-16 text-secondary text-center">
								Non hai aggiunto ancora nessun elemento
								</p>
							</div>
						</Row>
					) : (
						<Row className="dashboard-container">
							{<Col className="mt-3 mb-3 d-flex align-items-center justify-content-between">
								<FilterSearchBar
									setSearchTerm={setSearchTerm}
									selectedCategories={selectedCategories}
									setSelectedCategories={setSelectedCategories}
									clearFiltersAndSort={clearFiltersAndSort}
									setSortBy={setSortBy}
									categories={categories}
									startDate={startDate} 
									endDate={endDate}
									setStartDate={setStartDate} setEndDate={setEndDate}

									multiView={multiView}
									setViewType={setViewType}
									viewType={viewType}
								/>
							</Col>}
							<div>
								{checkUserRole() &&
								selectedItems.length > 0 && 
								<div className="d-flex mb-3">
									<ButtonRm variant='outline-gray' size="extra-small" className="me-2" onClick={handleSelectAll}>
										Seleziona tutti
									</ButtonRm>
									<ButtonRm variant='outline' size="extra-small" onClick={handleDeselectAll}>
										Deseleziona Tutti
									</ButtonRm>
								</div>
								}
							</div>
							{
								viewType == 'card' ? 
								<ColomboList target="Fornitore" fetchData={sortedItems} onItemDeleted={handleItemDeleted} handleSelection={handleSelection} selectedItems={selectedItems} handleSelectAll={handleSelectAll} handleDeselectAll={handleDeselectAll}
								/> : 
								<>

								<TableContainer component={Paper} className="table-font table-activity">
									<Table stickyHeader aria-label="simple table  table-activity">
										<TableHead>
											<TableRow className="table-font table-act-head-cell">
												<TableCell style={{ minWidth: '10px', paddingLeft: "16px !important"  }} className="table-font table-act-head-cell"></TableCell>
												{headerNames.map((header) => (
												<TableCell style={{ minWidth: '160px', paddingLeft: "16px !important" }} className="table-font table-act-head-cell" key={header}>
												<TableSortLabel
													active={orderBy === header}
													direction={orderBy === header ? order : 'asc'}
													onClick={() => handleRequestSort(header)}
													>
													{translationMapping[header]}
													</TableSortLabel>
												</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody className="table-font">
										{sortedUnits.map((item, index) => (
											<TableRow key={item.id} className="table-font cursor-pointer" >
												<TableCell style={{ minWidth: '5px' }} className="table-font">
												<input
													type="checkbox"
													checked={selectedItems.includes(item.id)}
													onChange={(e) => handleSelection(e, item.id)}
													className="me-2"
												/>
												</TableCell>
												{headerNames.map((header) => {
													const variant = columnBadgeVariantMap[header];
													return (header == 'cdat' || header == 'birthday')?
														<TableCell td key={header}  style={{ minWidth: '150px' }}
														className="table-font cursor-pointer"> 
																{item[header] && 
																<Link to={`/fornitori-view/view/${item.id}`}>
																	<CustomBadge variant={variant} margin="mx-0">
																	
																		{formattedTimestamp(item[header])}
																	</CustomBadge>
																</Link>
															}
														</TableCell>:
														<TableCell key={header} style={{ minWidth: '150px' }}
														className="table-font cursor-pointer"> 
															{item[header] && 
																<Link to={`/fornitori-view/view/${item.id}`} className="" >
															<CustomBadge variant={variant} margin="mx-0">
																
																{ item[header]}
																
															</CustomBadge>
															</Link>
															}
														</TableCell>
												})}
											</TableRow>
										))}
										</TableBody>
									</Table>
								</TableContainer>
								</>
							}

						<Row className="alert-container">
								{ 
									checkUserRole() &&
									selectedItems.length > 0 && 
									<Alert className="alert-options" style={{background:"#606F88", height:"fit-content"}}>
										<Alert.Link 
											className="me-2" 
											onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											handleShow();
										}}>
											Elimina
										</Alert.Link>
										{ ( selectedItems.length == 1 && checkMaster()) &&
										<Alert.Link 
											className="me-2" 
											onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											handleShowPermit();
										}}>
											Permessi
										</Alert.Link>
										}
									</Alert>
								}
							</Row>

						</Row>
						
				)}
				</div>
			)}

			{/* <Dropzone onDrop={(acceptedFiles) => transmigrationFunction(acceptedFiles, true, mappingFromCompetitor1, supplier_endpoint, setItems)
			} >
				{({ getRootProps, getInputProps }) => (
					<section className="">
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						<div className="filter">
						<div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
							<i className="bi bi-upload fs-25"></i>
						</div>
						</div>
					</div>
					</section>
				)}
			</Dropzone> */}






		<ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
			<p className="mb-3">Per procedere con la creazione di un fornitore, è necessario completare le seguenti informazioni:</p>
			<p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
			
			{/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
		</ModalCheckUser>

		<Modal show={showModal} onHide={(e) => handleClose(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleClose(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClose(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>

		<Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		</Modal>


		</>
	);
}
export { FornitoriText };
