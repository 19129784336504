import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import Form from "react-bootstrap/Form";

import FilterSearchBar from "./FilterSearchBar"
import { AttivitàCardMap, AttivitàCardMapMobile } from "../Attività/AttivitàCard";

import SearchIllustration from "../../../assets/images/search-illustration.svg";
import EmptyState from '../../../assets/images/attività-empty-state.svg'

import { useMediaQuery } from 'react-responsive'

import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup, useMap, ImageOverlay, Rectangle  } from 'react-leaflet'

import { Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pc from '../../../assets/images/placeholder-unit-medium.png'

import axios from "axios";

import api, { unit_endpoint, BASE_UR, activity_endpoint, BASE_URL } from "../../../api/api";
import ButtonRm from "../../ButtonRm";
import { checkUserRole } from "../../../utils/checkUserRole";


function MapViewActivity(props){

    const isDesktop = useMediaQuery({
        query: '(min-width: 992px)'
    })

    //Settings for carousels Unit map card mobile
    let settings = {
        arrows: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                }
            },
        ]
    }

    /*MAPPA*/

    //CHANGE MARKER ICON
    function myIcon(){
        return L.icon({
            iconUrl: require('../../../assets/images/pin-map.png'),
            iconSize:35
        })
    }

    //Modify map view
    function ChangeView({ center, zoom}) {
        const map = useMap();
        map.flyTo(center, zoom)
        return null;
    }


    const [singleAttività, setSingleAttività] = useState({})
    const [currentPlanimetryId, setCurrentPlanimetryId] = useState(
        singleAttività?.planimetries?.length > 0 ? singleAttività?.planimetries?.[0]?.id : null
      )
      const [currentPlanimetry, setCurrentPlanimetry] = useState(
        singleAttività?.planimetries?.length > 0 ? singleAttività.planimetries[0] : {}
      )

   


     //get single item
  async function getOne(id) {
    try {
      const response = await api().get(`${activity_endpoint}${id}`);
      if (response.data) {
        response.data.members = response.data.members?.length > 0 ? response.data.members.map(str => eval(`(${str})`)) : [];
        response.data.planimetries = response.data.planimetries.map(item => JSON.parse(item.replace(/'/g, '"')));

      }
      setSingleAttività(response.data);
      setCurrentPlanimetry(response.data.planimetries?.[0]);
      setCurrentPlanimetryId(response.data.planimetries?.[0]?.id);
    } catch (error) {
      console.log(error);
    }
  }




  useEffect(() => {
    if (props.selectItem) {
        getOne(props.selectItem);
      }
}, [props.selectItem])
    
  
    function handleCurrentPlanimetryId(e) {
      setCurrentPlanimetryId(e.target.value);
    }
  
    const handleCurrentPlanimetry = () => {
      let selectedPlan = singleAttività.planimetries?.find(
        (planimetry) => planimetry.id===currentPlanimetryId
      );
      setCurrentPlanimetry(selectedPlan);
    };
  
    useEffect(() => {
      if (currentPlanimetryId) {
        handleCurrentPlanimetry();
      }
    }, [currentPlanimetryId, singleAttività]);

//prendi planimetrie da unità




  const [unitPlanimetries, setUnitPlanimetries] = useState([])

  //FETCH PLANIMETRIES SOLO SE UNITA'
  async function getPlanimetries(id){
    try {
      const response = await api().get(`${unit_endpoint}${id}` )
      if (response.data) {
        setUnitPlanimetries(response.data.planimetry)
      }
    } catch (error) {
      console.log(error);
    }
  }

  //OTTIENI IMMAGE URL DI OGNI PLANIMETRIA, POI CHIAMA
  //CREATE OBJ FUNCTION CHE CREA UN OGGETTO PER OGNI IMG
  //E SETTA SINGLEATTIVITÀ.PLANIMETRIES
  const fetchImages = async (fileNames) => {
    const imageUrls = [];
  
    for (const fileName of fileNames) {
      const fileUrl = `${BASE_URL}api/v1/unit/download_planimetry/${singleAttività.unitID}/${fileName}`;
      try {
        const response = await axios.get(fileUrl, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token}`,
            "Content-Type": "application/json",
          },
        });


          //CODICE PER BLOB IMG
          const imageUrl = URL.createObjectURL(response.data);
      imageUrls.push(imageUrl);
  
          if (imageUrls.length === fileNames.length) {
            createPlanimetryObject(imageUrls);
          }
        
      } catch (error) {
        console.error(`Failed to fetch image: ${fileName}`, error);
        imageUrls.push(null); // or any fallback value if image fetching fails
  
        if (imageUrls.length === fileNames.length) {
          createPlanimetryObject(imageUrls);
        }
      }
    }
  };
  
  //SOSTITUISCI IMAGE CON LE IMMAGINI PRESE DA UNITA
  const createPlanimetryObject = (urls) => {
    const updatedPlanimetries = singleAttività.planimetries.map((planimetry, index) => {
      if (index < urls.length) {
        return {
          ...planimetry,
          image: urls[index],
        };
      }
      return planimetry;
    });
    console.log("UPDATE PLANIMETRIES")
    setSingleAttività((prev) => ({
      ...prev,
      planimetries: updatedPlanimetries,
    }));
  };
  


  //IMAGE SRC DI OGNI PLANIMETRIE
  const [planimetriesImages, setPlanimetriesImages ] = useState([])
  
  useEffect(() => {
    const fetchPlanimetriesImages = async () => {
      if (unitPlanimetries.length > 0) {
        const images = await fetchImages(unitPlanimetries);
        setPlanimetriesImages(images);
      }
    };
  
    fetchPlanimetriesImages();
  }, [unitPlanimetries]);
  
  useEffect(() => {
    if(props.activitySelected?.unitID){

      getPlanimetries(props.activitySelected.unitID)
    }
    
  }, [props.selectItem])


/* full screen */

const [fullscreen, setFullscreen] = useState(false);

const toggleFullscreen = () => {
  setFullscreen(!fullscreen);
};


/* gestisci bounds */

const loadImage = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions([img.height, img.width]);
  };
  img.onerror = (err) => {
    console.log("img error");
    console.error(err);
  };
};

const [imageDimensions, setImageDimensions] = useState(null);

  useEffect(() => {
    if (currentPlanimetry?.image) {
      loadImage(setImageDimensions, currentPlanimetry.image);
    }
  }, [currentPlanimetry, fullscreen]); 


const bounds = [[0, 0], imageDimensions ? imageDimensions : [512, 512]];

console.log(props.activitySelected, singleAttività, props.selectItem, "MAP1")
console.log(currentPlanimetry, currentPlanimetryId,"MAP2")

const rectangles = {
  ...currentPlanimetry?.markers,
  features: currentPlanimetry?.markers?.features
    ?currentPlanimetry?.markers?.features.filter(
        (item) => item.geometry.type==="Polygon"
      )
    : [],
};

const points = {
  ...currentPlanimetry?.markers,
  features: currentPlanimetry?.markers?.features
    ? currentPlanimetry?.markers.features.filter(
        (item) => item.geometry.type==="Point"
      )
    : [],
};

    return (
        <Row className="mx-0">
            { isDesktop &&
                <Col className={` ${fullscreen ? 'd-none' : 'col-10-xxxl d-flex justify-content-center col-2-xxxl'}`} lg={fullscreen ? 0 : 4} >

                    <div className="unit-container">

                        {
                            props.attività?.length > 0 &&
                            <Col className="mt-3 mb-3 d-flex align-items-center justify-content-between px-3">
                                <FilterSearchBar
                                setSearchTerm={props.setSearchTerm}
                                selectedCategories={props.selectedCategories}
                                setSelectedCategories={props.setSelectedCategories}
                                clearFiltersAndSort={props.clearFiltersAndSort}
                                setSortBy={props.setSortBy}
                                categories={props.categories}
                                setStartDate={props.setStartDate} setEndDate={props.setEndDate}

                                />
                            </Col>
                        }

                        {  props.attività?.length > 0 &&
                            <div className="card-map-container my-1 d-flex flex-column
                            align-items-center">                       
                            {   props.sortedItems.length > 0 ?
                                props.sortedItems.map( item => {
                                        return <AttivitàCardMap
                                        type="map"
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        unit_name={item.unitName}
                                        author={item.author}
                                        creation_date={item.cdat}
                                        assignee={item.assignee}
                                        expiration_date={item.expirationDate}
                                        priority={item.priority}
                                        handleItemSelected={props.handleItemSelected}
                                        selectItem={props.selectItem}
                                    />
                                    }) :
                                    <div className="my-4 mx-auto text-center">
                                        <div className="mx-auto mb-2 mt-5 w-60">
                                            <img loading="lazy" className="img-fluid" src={SearchIllustration} alt="empty-image" />
                                        </div>
                                        <p className="fs-14 text-secondary text-center">
                                            Nessuna corrispondenza trovata
                                        </p>
                                    </div>                     
                            }
                        </div>
                        } 

                        { props.attività?.length===0 &&
                            <div className="my-5 mx-auto text-center d-flex flex-column
                            align-items-center justify-content-center ">
                                <div className="mx-auto mb-4 w-80">
                                    <img loading="lazy" className="img-fluid" src={EmptyState} alt="empty-image" />
                                </div>
                                <p className="fs-12 text-secondary text-center">
                                   {checkUserRole() ? "Non hai ancora inserito alcuna attività" : "Non sei stato ancora aggiunto in alcuna attività"}
                                </p>
                            </div>    
                        }            
                    </div>
                </Col>
            }

          <Col lg={fullscreen ? 12 : 8} className={`col-10-xxxl ${fullscreen && 'fullscreen'}`}>
                <div className="unit-container">

                    {!isDesktop && (
                        <Slider
                            {...settings}
                            className="attività-card-mobile-container"
                        >
                            {props.attività.length > 0 &&
                            props.attività.map((item) => {
                                return (
                                <AttivitàCardMapMobile
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    unit_name={item.unitName}
                                    author={item.author}
                                    creation_date={item.cdat}
                                    assignee={item.assignee}
                                    expiration_date={item.expirationDate}
                                    priority={item.priority}
                                    handleItemSelected={props.handleItemSelected}
                                    selectItem={props.selectItem}
                                />
                                );
                            })}
                        </Slider>
                    )}

                    {props.activitySelected ? <MapContainer
                        key={fullscreen}
                        crs={L.CRS.Simple}
                        center={[0, 0]}
                        /* bounds={bounds} */
                        bounds={[
                          [0, 0],
                          [512, 512],
                        ]}
                        className="position-relative "
                        scrollWheelZoom={false}
                        minZoom={-2}

                        
                    >
                                  <FullscreenControl fullscreen={fullscreen} toggleFullscreen={toggleFullscreen} /> 
                            
                  <ImageOverlay
                    bounds={props.bounds}

                    url={
                      currentPlanimetry?.image
                        ? currentPlanimetry?.image
                        : "https://myben.it/wp-content/uploads/2019/11/placeholder-images-image_large.png"
                    }
                  />

                        {rectangles.features?.length > 0 &&
                        rectangles.features.map((rect) => {
                            return (
                            <Rectangle
                                key={rect.id}
                                bounds={rect.geometry.coordinates}
                            ></Rectangle>
                            );
                        })}
                        {points.features?.length > 0 &&
                        points.features.map((point) => {
                            return (
                            <Marker
                                key={point.id}
                                position={point.geometry.coordinates}
                            ></Marker>
                            );
                        })}
                    </MapContainer>: 
                    <h2></h2>
                    }

                    {props.activitySelected?.planimetries.length > 1 && (
                        <Form>
                        <select
                            className="planimetria-selection"
                            value={currentPlanimetryId}
                            onChange={handleCurrentPlanimetryId}
                        >
                            {singleAttività?.planimetries?.length > 0 &&
                    singleAttività.planimetries?.map((planimetry) => {
                      return (
                        <option key={planimetry.id} value={planimetry.id}>
                          {planimetry.name}
                        </option>
                      );
                    })}
                        </select>
                        </Form>
                    )}
                    
                </div>
            </Col>

            </Row>
    )
}

const FullscreenControl = (props) => {
  const map = useMap();

  const positionControl = { top: "90%", right: "5px" }; 
  

  return (
    <div className="leaflet-control leaflet-control-custom" style={positionControl}>
      <ButtonRm onClick={props.toggleFullscreen}>
        {props.fullscreen ? <i class="bi bi-arrows-angle-contract"></i> : <i class="bi bi-arrows-angle-expand"></i>}
      </ButtonRm>
    </div>
  );
};


export default MapViewActivity