import React from "react";
import { BsPrinter, BsDownload } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logo from "../assets/images/logo2.png";

const PrintDownloadComponent = (props) => {
	const flattenForm = async () => {
		const doc = new jsPDF();
		var data = [];
		var value = JSON.parse(JSON.stringify(props.printData));
		doc.addImage(logo, "PNG", 15, 10, 20, 10);
		doc.text(props.title, 15, 35);
		let startY = doc.autoTableEndPosY() + 40;

		Object.entries(value).map((item) => {
			if (item[1] != null || item[1] != undefined) {
				if (typeof item[1] === "object") {
					let dataCaseObj = [];
					Object.entries(item[1]).map((ele) => {
						console.log(ele);
						let tempCaseObj = [ele[0], ele[1]];
						dataCaseObj.push(tempCaseObj);
					});

					autoTable(doc, {
						headStyles: { fillColor: "#83112F" },
						head: [[JSON.stringify(item[0]), "value"]],
						body: dataCaseObj,
						startY: startY,
					});
					startY = doc.autoTableEndPosY() + 10;
				} else {
					let temp = [item[0], item[1]];
					data.push(temp);
				}
			}
		});
		if (data.length !== 0) {
			autoTable(doc, {
				headStyles: { fillColor: "#83112F" },
				head: [["Name", "Value"]],
				body: data,
				startY: doc.autoTableEndPosY() + 40,
			});
		}
		doc.save(`${props.title}.pdf`);
	};
	const handlePrint = useReactToPrint({
		content: () => props.componentRef.current,
	});
	return (
		<div className="d-flex justify-content-end" style={{paddingRight:"0rem", paddingTop:"0rem", paddingBottom:"1rem"}}>
		<button
		  type="button"
		  className="bg-white rounded-3 p-1 w-20 h-40 me-2"
		  onClick={handlePrint}
		  style={{color:"#9ccbf2", border: "1px solid #9ccbf2"}}
		>
		  <BsPrinter />
		</button>
		<button
		  type="button"
		  className="bg-white rounded-3 p-1 w-20 h-40"
		  onClick={() => flattenForm()}
		  style={{color:"#9ccbf2",  border: "1px solid #9ccbf2"}}
		>
		  <BsDownload />
		</button>
	  </div>
	);
};

export default PrintDownloadComponent;
