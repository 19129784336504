const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    type: "Tipo",
    name: "Nome",
    surname: "Cognome",
    birthday: "Data di nascita",
    state: "Stato di nascita",
    province: "Provincia",
    municipality: "Comune",
    fiscalCode: "Codice fiscale",
    sex: "Sesso",
    residenceAddress: "Indirizzo di residenza",
    residenceHouseNumber: "Civico di residenza",
    residenceProvince: "Provincia di residenza",
    residenceMunicipality: "Comune di residenza",
    officeAddress: "Indirizzo ",
    officeHouseNumber: "Civico ",
    officeProvince: "Provincia",
    officeMunicipality: "Comune",
    
    businessName: "Denominazione o ragione sociale",
    withholding: "Ritenuta",
    subjectType: "Tipo di soggetto",
    societyFiscalCode: "Codice fiscale",
    vatNumber: "Partita IVA",
    chamberOfCommerceNumber: "Numero",
    chamberOfCommerceCity: "Città",
    reaNumber: "REA",
    shareNumber: "Capitale sociale",
    inVirtueOf: "In virtù di",
    correspondenceAt: "Presso",
    correspondenceAddress: "Indirizzo di corrispondenza",
    correspondenceHouseNumber: "Civico di corrispondenza",
    correspondenceMunicipality: "Comune di corrispondenza",
    correspondenceProvince: "Provincia di corrispondenza",
    homeTelephone: "Telefono casa",
    officeTelephone: "Telefono ufficio",
    cellularTelephone : "Telefono cellulare",
    fax: "Fax",
    skype: "Skype",
    email: "Email",
    pec: "PEC",
    documentType: "Tipo di documento",
    documentNumber: "Numero",
    documentReleaseDate: "Data dell'operazione",
    documentReleaseEntity: "Rilasciato dal",
    documentDeadline: "Scadenza del documento",
    citizenship: "Cittadinanza",
    "bank accounts": "Conti correnti",
    supplier: "Fornitore",
    notes: "Note",
    attachment: "Allegati",
    typeOfConductor: "Tipologia di conduttore",
    guarantor: "Garante",
    ueCitizen: "Cittadino UE"
  };


  export default translationMapping