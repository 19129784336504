import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { MOVEMENT_TYPES } from "../../../../constants";
import ButtonRm from "../../../ButtonRm";
import CustomBadge from "../../../CustomBadge";


function NewTypeModal(props) {
  const [new_type, setNewType] = useState("");


  //CREA - AGGIUNGI ITEM ALL'ENPOINT SUB ACCOUNT
  return (
    <>
      <div className="d-block w-100 mb-3 mt-5">
        <div>
          <h2 className="fs-20 text-dark mb-2 fw-bold">
            Nuovo sottoconto
          </h2>
        </div>
      </div>
      <Form>
        <div className="form-item-align-box d-block">
          <div className="mt-3">
            <div className="d-flex flex-column">
              <label className="f-label fs-16 d-flex align-items-center
mb-2">Nome</label>
              <input
                type="text"
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                onChange={(e) => {
                  setNewType(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-3">
            <ButtonRm variant="primary" size="full-width"  onClick={() => props.addItem({name:new_type})}>Crea</ButtonRm>
            <ButtonRm variant="text"  size="full-width"  onClick={() => {
                props.modalBack();
              }}>Anulla</ButtonRm>

          {/*   <button
              type="button"
              className="next-btn btn-last rounded-3 shadow me-2 border-0 col-md-12"
              onClick={() => props.addItem({name:new_type})}
            >
              Crea
            </button>
            <button
              type="button"
              className="black-btn btn-last rounded-3 shadow border-0 col-md-12 mt-3"
              onClick={() => {
                props.modalBack();
              }}
            >
              Annulla
            </button> */}
          </div>
        </div>
      </Form>
    </>
  );
}
export default NewTypeModal;