import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import support from  "../../assets/images/supp.png"

import logo from "../../assets/images/Group 1189.svg"

import { Link } from 'react-router-dom';

import ResponsiveNavbar from './NavRm';

import Footer from './Footer';



export default function Contatti (){
    return (
        <div style={{background:'#fff', position: "relative"}}>
            <div style={{background: ''}}>
                <Container className="px-3 px-md-0">

                <ResponsiveNavbar/>

                    <Row className="flex-column mb-2">
                        <Col className="d-flex justify-content-center" style={{padding: "5rem 0rem 2rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center fs-40 fs-md-50 syne-font">Richiedi Informazioni</h1>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className="mt-2 justify-content-center align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={8}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Cognome</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"  />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Ruolo</Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option></option>
                                    <option value="1">Gestore</option>
                                    <option value="2">Proprietario</option>
                                    <option value="3">Inquilino</option>
                                    <option value="4">Fornitore</option>
                                    <option value="5">Altro</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Oggetto della richiesta</Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option></option>
                                    <option value="1">Richiesta prova gratuita</option>
                                    <option value="2">Richiesta informazioni generali</option>
                                    <option value="3">Richiesta informazione sui costi</option>
                                    <option value="5">Altro</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Messaggio</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Quante proprietà gestisci? (facoltativo)</Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option></option>
                                    <option value="1">Da 1 a 5 proprietà</option>
                                    <option value="2">6-50</option>
                                    <option value="3">Più di 50</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Utilizzi già un software gestionale? (facoltativo)</Form.Label>
                                <Form.Control type="text" placeholder="Inserisci il nome del gestionale"/>
                            </Form.Group>
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={`default`}
                                label={`Acconsento all'invio dei miei dati e accetto la Privacy Policy`}
                                className="mb-3"
                            />
                                                            <Button size="lg" className="mb-3" variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Invia</Button>

                        </Form>
                        </Col>
                        
                    </Row>



                    <Footer />

                   

                </Container>
            </div>

            <Image src={support} width="250px" style={{position: "fixed", bottom: "0px", right: "0px"}}  className='d-none d-lg-block'/>
        </div>
    )
}