const coefficientFloorMap = {
	"piano interrato": "undergroundFloor",
	"piano seminterrato": "basementFloo",
	"piano terra": "groundFloor",
	"piano rialzato": "mezzanineFloor",
	"1 piano": "1Floor",
	"2 piano": "2Floor",
	"3 piano": "3Floor",
	"4 piano": "4Floor",
	"5 piano": "5Floor",
	"6 piano": "6Floor",
	"7 piano": "7Floor",
	"8 piano": "8Floor",
	"9 piano": "9Floor",
	"10 piano": "10Floor",
	"oltre il 10 piano": "beyond10Floor",
	"ultimo piano": "topFloor",
	"attico": "penthouse"
};

const ageMap = {
    "1-5 anni": "years1To5",
    "5-20 anni": "years5To20",
    "20-40 anni": "years20To40Years",
    "oltre 40 anni": "over40Years"
  };
  
  const statusMap = {
    "da ristrutturare totalmente": "ToRenovateTotally",
    "da ristrutturare parzialmente": "ToRenovatePartially",
    "buono/abitabile": "Habitable",
    "ottimo": "Excellent",
    "nuovo/in costruzione": "NewConstruction"
  };

  const coefficientBoxAccessibilityMap = {
    "accesso molto difficoltoso": "veryDifficultBoxAccessibility",
    "accesso difficoltoso": "difficultBoxAccessibility",
    "accesso scomodo": "uncomfortableBoxAccessibility",
    "accesso normale": "normalBoxAccessibility",
    "accesso comodo": "comfortableBoxAccessibility"
  };

  const coefficientBoxLocationMap = {
    "-": '',
    "nello stesso edificio": "inTheSameBoxLocation",
    "limitrofo all'edificio": "nearBoxLocation",
    "distante all'edificio": "farBoxLocation",
    "molto distante all'edificio": "veryfarBoxLocation",
  };

  const coefficientPossessionTypeMap = {
    "_": '',
    "proprietà": "propertyBoxPossession",
    "concessione": "concessionBoxPossession",
  };
  
  const coefficientMainViewMap = {
    "esterna panoramica": "externalPanoramicView",
    "esterna": "externalView",
    "mista": "mixedView",
    "interna": "internalView",
    "completamente interna": "fullInternalView"
  };

  const coefficientBrightnessMap = {
    "poco luminoso": "lowBright",
    "mediamente luminoso": "mediumBright",
    "luminoso": "bright",
    "molto luminoso": "veryBright"
  };

 const coefficientNoisinessMap = {
    "normale": "normalNoise",
    "molto alta": "veryHighNoise",
    "alta": "highNoise",
    "bassa": "lowNoise",
    "molto bassa": "veryLowNoise",
};

const coefficientInternalDistributionMap = {
    "distribuzione inefficiente": "notEfficientDistribution",
    "distribuzione poco efficiente": "lowEfficientDistribution",
    "efficiente": "efficientDistribution",
    "distribuzione molto efficiente": "veryEfficientDistribution",
};

const coefficientCeilingHeightMap = {
    "nella media di zona": "mediumCeilingHeight",
    "inferiori alla media di zona": "inferiorCeilingHeight",
    "superiori alla media zona": "superiorCeilingHeight"
  };

  const coefficientInternalStateMap = {
    "da ristrutturare totalmente": "totallyRenovated",
    "da ristrutturare parzialmente": "partiallyRenovated",
    "buono/abitabile": "habitable",
    "ristrutturato": "renovated",
    "finemente ristrutturato": "finelyRenovated",
    "nuovo/in costruzione": "newConstruction",
  };

  const coefficientFinishingQualityMap = {
    "standard": "standardFinish",
    "economiche": "inexpensiveFinish",
    "ottime": "excellentFinish",
    "di pregio": "premiumFinish",
  };
  
  const coefficientEnergeticClassMap = {
    "A1": "a1",
    "A2": "a2",
    "A3": "a3",
    "A4": "a4",
    "B": "b",
    "C": "c",
    "D": "d",
    "E": "e",
    "F": "f",
    "G": "g",
  };
  
  const coefficientHeaterMap = {
    "autonomo": "autonomousHeating",
    "centralizzato": "centralizedHeating",
    "centralizzato con contabilizzatore": "centralizedMeteringHeating",
    "assente": "absentHeating",
  };

  const coefficientCoveredParkingAccessibilityMap = {
    "-": '',
    "accesso molto difficoltoso": "veryDifficultCoveredParkingAccessibility",
    "accesso difficoltoso": "difficultCoveredParkingAccessibility",
    "accesso scomodo": "uncomfortableCoveredParkingAccessibility",
    "accesso normale": "normalCoveredParkingAccessibility",
    "accesso comodo": "comfortableCoveredParkingAccessibility",
  };
  
  const coefficientCoveredParkingLocationMap = {
    "-": '',
    "nello stesso edificio": "inTheSameCoveredParkingLocation",
    "limitrofo all'edificio": "nearCoveredParkingLocation",
    "distante all'edificio": "farCoveredParkingLocation",
    "molto distante all'edificio": "veryfarCoveredParkingLocation",
  };
  
  const coefficientCoveredParkingPossessionTypeMap = {
    "-": '',
    "proprietà": "propertyCoveredParkingPossession",
    "concessione": "concessionCoveredParkingPossession",
  };
  
  const coefficientUncoveredParkingAccessibilityMap = {
    "-": '',
    "accesso molto difficoltoso": "veryDifficultUncoveredParkingAccessibility",
    "accesso difficoltoso": "difficultUncoveredParkingAccessibility",
    "accesso scomodo": "uncomfortableUncoveredParkingAccessibility",
    "accesso normale": "normalUncoveredParkingAccessibility",
    "accesso comodo": "comfortableUncoveredParkingAccessibility",
  };
  
  const coefficientUncoveredParkingLocationMap = {
    "-": '',
    "nello stesso edificio": "inTheSameUncoveredParkingLocation",
    "limitrofo all'edificio": "nearUncoveredParkingLocation",
    "distante all'edificio": "farUncoveredParkingLocation",
    "molto distante all'edificio": "veryfarUncoveredParkingLocation",
  };
  
  const coefficientUncoveredParkingPossessionTypeMap = {
    "-": '',
    "proprietà": "propertyUncoveredParkingPossession",
    "concessione": "concessionUncoveredParkingPossession",
  };
  
  const coefficientCondominialParkingPossessionTypeMap = {
    "-": '',
    "proprietà": "propertyCondominialParkingPossession",
    "concessione": "concessionCondominialParkingPossession",
  };

  const coefficientParkingAccessibilityMap = {
    "-":'',
    "più facile della media di zona": "easyParkingAccessibility",
    "nella media di zona": "mediumParkingAccessibility",
    "più difficoltoso della media di zona": "hardParkingAccessibility",
    "molto più difficoltoso della media di zona": "veryhardParkingAccessibility",
  };

  const coefficientPositionMap = {
    "posizione di elevato interesse (centrale)": "centralPosition",
    "posizione intermedia": "intermediatePosition",
    "posizione secondaria": "secondaryPosition",
  };

 const coefficientFloorShopMap = {
    "seminterrato in giù": "basementDown",
    "piano terra": "groundFloor",
    "piano rialzato (da 1 a 3)": "mezzanineFloorFrom1to3",
    "piano superiore al 3": "floorAboveThe3rd",
  };

 const coefficientShopVisibilityMap = {
    "particolarmente favorevole al commercio": "particularlyFavorableToTrade",
    "favorevole al commercio": "favorableToTrade",
    "ordinaria": "ordinary",
    "poco favorevole al commercio": "unfavorableToTrade",
  };

 const coefficientAutorimessaTypeMap = {
    "fuori autorimessa": "outsideTheGarage",
    "dentro l'autorimessa": "insideGarage",
  };
  
const coefficientBoxHeightMap = {
    "nella media": "averagHeight",
    "sopra la media (>3mt)": "avarageAbove",
  };
  
const coefficientFloorMagMap = {
    "a piano terra o rialzato con accesso carrabile": "groundFloorWithAccess",
    "a piano terra o rialzato senza accesso carrabile": "groundFloorWithoutAccess",
    "seminterrato con accesso carrabile": "basementWithAccess",
    "seminterrato accessibile solo da scale": "basementWithoutAccess",
  };
  
const coefficientCapFloorMap = {
    "piano terra": "groundFloor",
    "piano rialzato": "mezzanine",
  };
  
const coefficientCapAccessMap = {
    "accesso autoarticolato": "articulatedVehicleAccess",
    "senza accesso autoarticolato": "withoutArticulatedVehicleAccess",
  };
  
  
  


export { coefficientFloorMap, ageMap, statusMap, coefficientBoxAccessibilityMap, coefficientBoxLocationMap, coefficientPossessionTypeMap, coefficientMainViewMap,  coefficientBrightnessMap, coefficientNoisinessMap,
coefficientInternalDistributionMap, coefficientCeilingHeightMap, coefficientInternalStateMap, coefficientFinishingQualityMap, coefficientEnergeticClassMap, coefficientHeaterMap, coefficientCoveredParkingAccessibilityMap, coefficientCoveredParkingLocationMap, coefficientCoveredParkingPossessionTypeMap, coefficientUncoveredParkingAccessibilityMap, coefficientUncoveredParkingLocationMap, coefficientUncoveredParkingPossessionTypeMap, coefficientCondominialParkingPossessionTypeMap, coefficientParkingAccessibilityMap, coefficientPositionMap, coefficientFloorShopMap, coefficientShopVisibilityMap, coefficientAutorimessaTypeMap, coefficientBoxHeightMap, coefficientFloorMagMap, coefficientCapFloorMap, coefficientCapAccessMap  }