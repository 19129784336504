import React, { useEffect, useState } from "react";
import { useWebSocket } from "../../../utils/websocketContext";

/* import { BASE_URL_WS } from "../../../api/api";
 */
const BASE_URL_WS  = ""


/* const WebSocketComponent = ({chatId, userData}) => {
    const [messages, setMessages] = useState([])
    const [webSocketStatus, setWebSocketStatus] = useState("In connessione...")

    const [msg, setMsg] = useState("")

    function handleMsg(e){
        e.preventDefault()
        setMsg(e.target.value)
    }

    const clientWebSocket = new WebSocket(`/event-emitter`)


    useEffect(() => {

        clientWebSocket.onopen = () => {
            setWebSocketStatus('Connesso')
            //clientWebSocket.send("event-me-from-browser")
        }

        clientWebSocket.onclose = (error) => {
            setWebSocketStatus('Connessione Chiusa', error)
        }

        clientWebSocket.onerror = (error) => {
            setWebSocketStatus('Errore', error)
        }

        clientWebSocket.onmessage = (event) => {
            setMessages((prevMessages) => [...prevMessages, event.data])
            console.log(event, "EVENTO")
        }

        //cleanup function quando smonto il componente
        return () => {
            clientWebSocket.close()
        }

    }, [])

    function sendMsg(){
        clientWebSocket.send(JSON.stringify({content: msg, chatboxID: parseInt(chatId), jwt: userData.token, replyToId: null }))
        setMsg("")
    }

    return (
        <div>
            <h6>{webSocketStatus}</h6>
            <div>
                <input 
                    type="text"
                    value={msg}
                    onChange={(e) => handleMsg(e)}
                />
                <button onClick={sendMsg}>Invia</button>
            </div>
            <div>
                {
                    messages.length > 0 && messages.map((message, index) => {
                        return <div key={index}>
                            {message}
                        </div>
                    })
                }
            </div>
        </div>
    )
} */

const WebSocketComponent = ({chatId, userData}) => {
    const socket = useWebSocket();
    const [messages, setMessages] = useState([])
    const [webSocketStatus, setWebSocketStatus] = useState("In connessione...")

    const [msg, setMsg] = useState("")

    function handleMsg(e){
        e.preventDefault()
        setMsg(e.target.value)
    }


    function sendMsg(){
        socket.send(JSON.stringify({type:"chat", content: msg, chatboxID: parseInt(chatId), jwt: userData.token, replyToId: null }))
        setMsg("")
    }

    useEffect(() => {
        if (socket) {
            console.log("connesso", socket)
        }
    }, [socket]);

 return (
        <div>
            <div>
                <input 
                    type="text"
                    value={msg}
                    onChange={(e) => handleMsg(e)}
                />
                <button onClick={sendMsg}>Invia</button>
            </div>
            <div>
                {
                    messages.length > 0 && messages.map((message, index) => {
                        return <div key={index}>
                            {message}
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default WebSocketComponent