import React from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo-bianco.svg";
import LastMenu from "./LastMenu";
import "../assets/css/Sidebar.css"

export default function Sidebar(props) {
  return (
    <>
      <Link to="/dashboard" className="logo fw-100 mx-auto d-table ">
        <img loading="lazy" src={logo} alt="logo-image" style={{maxWidth: '100px'}} />
      </Link>
      <Menu isActive={props.isActive} panelSize={props.panelSize} />
      {/* <div className="last-links mt-auto">
        <LastMenu />
      </div> */}
    </>
  );
}
