import React, { useState, useEffect } from "react";
import TopHeading from "../components/TopHeading";
import EmptyImg from "../assets/images/user-group.svg";
import { BsPlusCircleFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import api, { assembly_endpoint } from "../api/api";
import AssemblyFirstPage from "../components/Assemblee/AssemblyFirstPage";

const Assemblee = () => {
	const [assembly, setAssembly] = useState([]);

	async function getAssembly() {
		try {
			const response = await api().get(`${assembly_endpoint}`);
			if (response.data) {
				setAssembly(response.data.content);
				console.log(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getAssembly();
	}, []);

	const deleteAssembly = (id) => {
		let temp = [...assembly];
		temp = temp.filter((item) => item.id !== id);
		setAssembly(temp);
	};

	const [search_name, setSearchName] = useState("");

	return (
		<>
			<div className="title-search-group mobile-container-xl">
				<div className="right-header">
					<TopHeading SmallHeading="empty" Heading="Assemblee" />
				</div>
				<div className="model-modul-group">
					<div className="d-flex align-items-center mt-2 justify-content-between btn-search-group">
						<div className="search-input">
							<div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
								<i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
								<input
									type="text"
									name=""
									placeholder="Cerca"
									className="flex-shink-0 border-0 w-100 fs-16"
									onChange={(e) => {
										setSearchName(e.target.value);
									}}
								/>
							</div>
						</div>
						<OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className="tooltip-container">Nuovo Assemblea</Tooltip>
							}>
							<Link to="/assembly/create">
								<div className="plus-button">
									<BsPlusCircleFill className="fs-40 plus-btn" />
								</div>
							</Link>
						</OverlayTrigger>
					</div>
				</div>
			</div>
			{assembly.length > 0 ? (
				<AssemblyFirstPage data={assembly} deleteAssembly={deleteAssembly} />
			) : (
				<div className="mx-auto d-table text-center empty-container">
					<div className="mx-auto d-table w-160">
						<img loading="lazy" src={EmptyImg} alt="empty" />
					</div>
					<p className="fs-20 text-secondary text-center">
						Nessun Assemblea creato
					</p>
				</div>
			)}
		</>
	);
};

export default Assemblee;
