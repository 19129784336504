import React from "react";
import { useState, useEffect } from "react";
import api, { condominium_endpoint } from "../../../../api/api";
import CondominiumCard from "../CondominiumCard";
import { BsPrinter, BsDownload } from "react-icons/bs";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../../ButtonRm";

import CustomToggle from "../../../CustomToggle";

import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";
import CustomBadge from "../../../CustomBadge";


const buildingLocationType = [
    "Edificio indipendente (non confinante con altri)",
    "Edificio a schiera (confinante con altri)",
    "Comprensorio immobiliare (senza servizi in comune)",
    "Complesso residenziale (con servizi e/o verde in comune)",
    "Complesso residenziale con piscina",
  ];

  const buildingTypologyType = [
    "Signorile",
    "Parzialmente signorile",
    "Molto signorile",
    "Di pregio",
    "Straordinario",
    "Civile",
  ];

  const buildingAgeType = [
    "1-5 anni",
    "5-20 anni",
    "20-40 anni",
    "Oltre 40 anni",
    "Antecedente 1930. storico antecedente 1900",
  ];

  const buildingStateType = [
    "Buono stato, da ristrutturare",
    "Discrete condizioni",
    "Ottimo stato",
    "Ristrutturato",
    "Nuova costruzione",
    "Parzialmente ristrutturato",
    "Da ripulire",
    "Nuova costruzione (mai abitato)",
    "Fatiscente-rudere",
  ];

const CondominiumE = (props) => {
	const [data, setData] = useState({});
	const [condominiums, setCondominiums] = useState([]);

	const fetchCondominium = async () => {
		try {
			const response = await api().get(`${condominium_endpoint}`);
			if (response.data) {
				setCondominiums(response.data.content);
				console.log(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};


	const init = () => {
		setData({
			condominium: props.formData.condominium,
			structure: props.formData.structure,
			ofProperty: props.formData.ofProperty,
			scaleMillesimi: props.formData.scaleMillesimi,
			lift: props.formData.lift,
			reception: props.formData.reception,
			heating: props.formData.heating,
			district: props.formData.district,
			water: props.formData.water,
			buildingLocation: props.formData.buildingLocation || "",	
			buildingAge: props.formData.buildingAge || "",
			buildingType: props.formData.buildingType || "",
			buildingState: props.formData.buildingState || "",
		});
	};

	console.log(props.formData.condominium, data.condominium)


	useEffect(() => {
		fetchCondominium();
		init();
	}, [props]); //SOLO 1 RENDER

	const [fullMode, setFullMode] = useState(false);


	/* const handleSave = () => {
		setEditMode(false);
		props.update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */
	const onChange = (e) => {
		let convertedValue

		if( e.target.type === "number"){
				convertedValue = e.target.value .replace(',', '.')
			} else {
				convertedValue = e.target.value 
		}

		setData({ ...data, [e.target.name]: convertedValue });
	};

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}

	}, [props.saveState, props.editMode]);

	//HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

	//HANDLE CONDOMINIO FIELD

	const handleStabili = (e) => {
		let temp = [];
		if (e.target.value !== "") {
			temp.push(JSON.parse(e.target.value));
		}
		let ele = { target: { name: "condominium", value: temp } };
		onChange(ele);
	};
	const deleteCondominium = (id) => {
		let temp = [...data.condominium];
		temp.splice(id, 1);
		let ele = { target: { name: "condominium", value: temp } };
		onChange(ele);
	};

	console.log(data)


	return (
		<Form>
			<div className="form-item-align-box d-block">
				<div
							className={`mb-3 d-flex justify-content-end align-items-center ${
								props.editMode === false ? "" : "d-none"
							}`}>
							<div className="d-flex">
								<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

							</div>
				</div>
			<fieldset disabled={props.editMode ? false : true}>
				
			<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Categoria</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						{props.editMode && <div className="d-flex justify-content-start">
					<Link to="/stabili/create">
						<button type="button" className="general-btn white-btn w-193 me-2">
							Nuovo
						</button>
					</Link>
				</div>}
				<div className="row mt-4">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Condominio/Stabile/Residenza
							</label>
							<select
								name="condominium"
								value={JSON.stringify(data.condominium?.[0])}
								onChange={handleStabili}>
									<option value="">
									Seleziona
								</option>
								<option value={JSON.stringify(data.condominium?.[0])}>
									{data.condominium?.[0]?.name}
								</option>

								{condominiums?.map((item, key) => {
									return (
										<option key={key} value={JSON.stringify(item)}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						{data.condominium?.[0]?.structures?.length > 0 ? (
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Struttura di riferimento
								</label>
								<select
									name="structure"
									value={data.structure}
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
									onChange={onChange}>
									<option key={-1} value="">
										Seleziona
									</option>
									{data.condominium[0].structures.map((item, key) => {
										return (
											<option key={key} value={item}>
												{item}
											</option>
										);
									})}
								</select>
							</div>
						) : (
							""
						)}
					</div>
				</div>
				<div className="mt-3 row">
					{data.condominium?.length > 0 &&
						data.condominium.map((item, index) => {
							return (
								<CondominiumCard
									key={index}
									id={index}
									data={item}
									deleteCondominium={deleteCondominium}
									edit_mode={false}
								/>
							);
						})}
				</div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="" activeKey={activeKey}>Millesimi</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<label className="f-label fs-16">
					I millesimi vengono utilizzati dal programma per poter inserirli nei
					modelli di contratto
				</label>
				<div className="col-md-11 mt-3">
					<div className="row">
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label className="f-label fs-16 d-flex align-items-center
mb-2">Di proprietà</label>

							<input
								name="ofProperty"
								value={data.ofProperty}
								onChange={onChange}

								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Scale
							</label>
							<input
								name="scaleMillesimi"
								value={data.scaleMillesimi}
								onChange={onChange}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Ascensore
							</label>
							<input
								name="lift"
								value={data.lift}
								onChange={onChange}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 text-nowrap">
								Portineria
							</label>
							<input
								name="reception"
								value={data.reception}
								onChange={onChange}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
				</div>
				<div className="col-md-11 mt-3">
					<div className="row">
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label className="f-label fs-16 d-flex align-items-center
mb-2">Riscaldamento</label>

							<input
								name="heating"
								value={data.heating}
								onChange={onChange}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Comprensorio
							</label>
							<input
								name="district"
								value={data.district}
								onChange={onChange}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Acqua
							</label>
							<input
								name="water"
								value={data.water}
								onChange={onChange}
								type="number"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
				</div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Dati edificio</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<div className="row mt-3">
							<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Ubicazione edificio
								</label>
								<select
								name="buildingLocation"
								value={data.buildingLocation}
								onChange={onChange} 
								>
								{buildingLocationType.map((menu, index) => {
									return (
									<option key={index} value={menu}>
										{menu}
									</option>
									);
								})}
								</select>
							</div>
							</div>
							<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Tipologia edificio
								</label>
								<select
								name="buildingType"
								value={data.buildingType}
								onChange={onChange} 
								>
								{buildingTypologyType.map((menu, index) => {
									return (
									<option key={index} value={menu}>
										{menu}
									</option>
									);
								})}
								</select>
							</div>
							</div>
						</div>
						<div className="row my-3">
						<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Età edificio
							</label>
							<select
							name="buildingAge"
							value={data.buildingAge}
							onChange={onChange}
							>
							{buildingAgeType.map((menu, index) => {
								return (
								<option key={index} value={menu}>
									{menu}
								</option>
								);
							})}
							</select>
						</div>
						</div>
						<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Stato conservazione edificio
							</label>
							<select
							name="buildingState"
							value={data.buildingState}
							onChange={onChange}
							>
							{buildingStateType.map((menu, index) => {
								return (
								<option key={index} value={menu}>
									{menu}
								</option>
								);
							})}
							</select>
						</div>
						</div>
					</div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

			</Accordion>
			</fieldset>
			</div>
			
		</Form>
	);
};

export default CondominiumE;
