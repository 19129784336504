import React from "react";
import cardimage from "../../../../assets/images/fornitore-ph.svg";
import SubjectCard from "../../../Documenti/prima_nota/Tipologia/SubjectCard";

import {  BsPrinter } from "react-icons/bs";
import translationMapping from '../../mapping'
import ExportButton from "../../Fornitori/ExportButton";
import { BankCard,BankCardItem } from "../../../BankCard";

import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function FornitoriV(props) {

const removeSelectedSupplier = () => {}

  return (
    <div className="row">
      <div className="row w-100">
    <div className="form-item-align-box-white d-block pt-2 pb-5">
    {props.formData.suppliers?.length>0 && <div className="d-flex mb-3 justify-content-end mt-3 me-3">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

					
					<ExportButton data={props.formData.suppliers} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>}
        {props.formData.suppliers.length > 0 ? (
        props.formData.suppliers.map((supplier, index) => (
          <BankCard
          key={index} 
          name={supplier.businessName}
          color="#FFB36D" 
          deleteFunc={removeSelectedSupplier} 
          index={supplier.id}>
            <BankCardItem property="CATEGORIA" value={supplier.category}/>
            <BankCardItem property="NOME" value={supplier.name}/>
            <BankCardItem property="COGNOME" value={supplier.surname}/>
            {supplier.cellularTelephone && <BankCardItem property="TELEFONO" value={supplier.cellularTelephone}/>}
          </BankCard>
        ))):(
          <div className="col-12 mt-5">
          <div className="mx-auto d-table text-center">
            <div className="image-user mx-auto d-table w-160 fw-100">
              <img loading="lazy" src={cardimage} alt="user-key" />
            </div>
            <p className="fs-18 text-secondary text-center">
              Nessun fornitore associato
            </p>
          </div>
        </div>
        )

        }
    </div>
  </div>


      <div className="col-md-6">
        {/* <div className="popup-yellow d-block overflow-hidden rounded-3">
          <div className="yellow-popup-header d-flex align-items-center justify-content-between p-2">
            <h3 className="fs-16 text-white fw-semibold">DARIO VERDI</h3>
          </div>
          <div className="table-list d-block" style={{ padding: "30px" }}>
            <table className="table table-borderless table-correnti">
              <tbody>
                <tr>
                  <td className="text-uppercase fs-14 text-secondary px-3">
                    CATEGORIA
                  </td>
                  <td className="text-capitalize fs-14 text-dark px-3">
                    {data.category}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase fs-14 text-secondary px-3">
                    TIPO DI SOGGETTO
                  </td>
                  <td className="text-capitalize fs-14 text-dark px-3">
                    {data['Type of subject']}
                  </td>
                </tr>
                <tr>
                  <td className="text-uppercase fs-14 text-secondary px-3">
                    TELEFONO
                  </td>
                  <td className="text-capitalize fs-14 text-dark px-3">
                    {data.phone}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
                   
      </div>
    </div>
  );
}
