import React, { useEffect } from "react";
import Login from "../auth/login/Login";
import { useNavigate } from "react-router-dom";
export default function Index() {
	const navigate = useNavigate();
	const user = localStorage.getItem("user");
	useEffect(() => {
		if (user) {
			navigate("/dashboard");
		}
	}, []);
	return (
		<>
			{!user && (
				<div style={{ background: "#fff" }}>
					<Login />
				</div>
			)}
		</>
	);
}
