import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	getProvince,
	getMunicipality,
} from "../../../../actions/addressAction";
import { BsPrinter, BsDownload } from "react-icons/bs";

import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import CustomToggle from "../../../CustomToggle";

import CurrencyInput from "react-currency-input-field";

import {formatDateFromString} from '../../../../utility'
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";

import Alert from 'react-bootstrap/Alert';

import CustomBadge from "../../../CustomBadge";





const Profitability = (props) => {
	useEffect(() => {
		props.getProvince();
	}, []);

	useEffect(() => {
		props.getMunicipality(props.province[0]);
	}, [props.province]);

	const handleProvince = (e) => {
		onChange(e);
		props.getMunicipality(e.target.value);
	};

	const [data, setData] = useState({});
	const init = () => {
		setData({
			category: props.formData.category,
			houseNumber: props.formData.houseNumber,
			municipalityCode: props.formData.municipalityCode,
			typology: props.formData.typology,
			scale: props.formData.scale,
			internal: props.formData.internal,
			cap: props.formData.cap,
			floor: props.formData.floor,
			name: props.formData.name,
			province: props.formData.province,
			municipality: props.formData.municipality,
			address: props.formData.address,
			categoryLandRegistry: props.formData.categoryLandRegistry,
			sheet: props.formData.sheet,
			cadastralMq: props.formData.cadastralMq,
			commercialMq: props.formData.commercialMq,
			netMq: props.formData.netMq,
			grossMq: props.formData.grossMq,
			manufactureYear: props.formData.manufactureYear,
			renovationLastYear: props.formData.renovationLastYear,
			antiquity: props.formData.antiquity,
			freeFrom: props.formData.freeFrom,
			bookValue: props.formData.bookValue,
			omiValue: props.formData.omiValue,
			income: props.formData.income,
			socialFee: props.formData.socialFee,
			unusable: props.formData.unusable,
			propertyTaxes: props.formData.propertyTaxes,
			incomeTaxes: props.formData.incomeTaxes,
			condominiumFees: props.formData.condominiumFees,
			directCostCda: props.formData.directCostCda,
			indirectCostCda: props.formData.indirectCostCda,
			registrationTaxes: props.formData.registrationTaxes,
			total: props.formData.total,
			averageRent: props.formData.averageRent,
			averageSale: props.formData.averageSale,
			grossSaleIncomeNew: props.formData.grossSaleIncomeNew,
			grossSaleIncomeOld: props.formData.grossSaleIncomeOld,
			rentIncomeNew: props.formData.rentIncomeNew,
			rentIncomeOld: props.formData.rentIncomeOld,
			actualNetIncome: props.formData.actualNetIncome,
			potentialNetIncome: props.formData.potentialNetIncome,
			grossIncomeOnBookValue: props.formData.grossIncomeOnBookValue,
			netIncomeOnBookValue: props.formData.netIncomeOnBookValue,
			currentFee: props.formData.currentFee,
			analyticalResult: props.formData.analyticalResult,
			note: props.formData.note,
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	/* const [editMode, setEditMode] = useState(false);

	const handleSave = () => {
		setEditMode(false);
		props.update(data);
	};

	const cancelEdit = () => {
		setEditMode(false);
		init();
	}; */

	const onChange = (e) => {
		if (e.target.type === "checkbox")
			setData({ ...data, [e.target.name]: e.target.checked });
		else setData({ ...data, [e.target.name]: e.target.value });
	};

	const category = [
		"Appartamento",
		"Villa",
		"Box",
		"Posto auto",
		"Negozio",
		"Ufficio",
		"Capannone",
		"Cantina",
		"Magazzino",
		"Laboratorio",
		"Ramo d'azienda",
		"Affidamento di reparto",
		"Altro",
		"Terreni agricoli",
		"Altri terreni",
		"Sito Telefonia mobile",
		"stanza",
		"area edificabile",
		"Camera",
	];

	const municipalityCode = ["Bologna"];

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}

	}, [props.saveState, props.editMode]);

	  //HANDLE ACCORDION COMPONENT
	  const [activeKey, setActiveKey] = useState('');

	  console.log(data?.municipality)

	return (
		<div>
			<Alert variant="warning" className="d-flex align-items-center w-100 p-3" >
          <i class="bi bi-info-circle me-2"></i>
		  <p>
          	Qui puoi visualizzare alcuni dati relativi alla tua unità. Puoi modificare le informazioni relative a   <span onClick={() => {props.handleState(2)}} className="fw-bold ms-1 cursor-pointer">Dati Catastali e Costi annuali dalla sezione CATASTO.</span> Puoi modificare le informazioni relative a <span onClick={() => {props.handleState(0)}} className="fw-bold ms-1 cursor-pointer">Generale e Indirizzo nella sezione Anagrafica</span>
		  </p>
      </Alert>

            <div className="form-item-align-box d-block py-2">
			<div
					className={`mb-3 d-flex justify-content-end align-items-center ${
						props.editMode === false ? "" : "d-none"
					}`}>
					<div className={`d-flex ${
							props.editMode === false ? "" : "d-none"
						}`}>
								
								<ExportButton data={data} mapping={translationMapping} showPrint classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

							</div>
		 	</div>
			<fieldset disabled={props.editMode? false: true}>
			
            <Accordion defaultActiveKey={['4']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Generale</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
                        <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Categoria
                                </label>
                                <select
                                    disabled
                                    name="category"
                                    value={data.category}
                                    onChange={onChange}>
                                    {category.map((menu, index) => {
                                        return (
                                            <option key={index} value={menu}>
                                                {menu}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Tipologia
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    name="typology"
                                    onChange={onChange}
                                    value={data.typology}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Identificativo
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    name="name"
                                    onChange={onChange}
                                    value={data.name}
                                />
                            </div>
                        </div>
                    </div>
							
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					
					<Card.Header>
					<CustomToggle eventKey="1" activeKey={activeKey}>Indirizzo</CustomToggle>
					</Card.Header>
					<Accordion.Body>						
						<Row>
							<Col md={6}>
							<Form.Group className="mb-2" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Indirizzo (Via/V.le/Piazza/Corso, ecc.)					
								</Form.Label>
								<Form.Control 
									type="text" 
									name="address"
									value={data.address}
									onChange={onChange}
                                    disabled
								/>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-2" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Numero Civico						
								</Form.Label>
								<Form.Control 
									type="text" 
									name="houseNumber"
									value={data.houseNumber}
									onChange={onChange}
                                    disabled
								/>
							</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-2" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Scala						
								</Form.Label>
								<Form.Control 
									type="text" 
									name="scale"
									value={data.scale}
									onChange={onChange}
                                    disabled
								/>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-2" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Piano						
								</Form.Label>
								<Form.Control 
									type="text" 
									name="floor"
									value={data.floor}
									onChange={onChange}
                                    disabled
								/>
							</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-2" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Interno						
								</Form.Label>
								<Form.Control 
									type="text" 
									name="internal"
									value={data.internal}
									onChange={onChange}
                                    disabled
								/>
							</Form.Group>
							</Col>

							<Col md={6}>
								<Form.Group className="mb-2" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Provincia 
									</Form.Label>
									<Form.Select 
										value={data.province}
										onChange={handleProvince} 
                                        disabled
										>
										<option></option>
										 {props.province?.length > 0 &&
										 props.province.map((item, key) => {
									return (
										<option key={key} value={item}>
											{item}
										</option>
									);
								})} 
									</Form.Select>
								</Form.Group>
							</Col>

						</Row>
						<Row>
							
							<Col md={6}>
								<Form.Group className="mb-2" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Comune 
									</Form.Label>
									<Form.Select 
										value={data.municipality}
										onChange={onChange}
                                        disabled
										name="municipality"
										>
										<option value={data.municipality}>{data.municipality}</option>
										 {props.municipality?.length > 0 &&
										 props.municipality.map((item, key) => {
											return (
												<option key={key} value={item}>
													{item}
												</option>
											)
										})}
									</Form.Select>
								</Form.Group>
							</Col>
							
							<Col md={6}>
							<Form.Group className="mb-2" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									CAP					
								</Form.Label>
								<Form.Control 
									type="text" 
									name="cap"
									value={data.cap}
									onChange={onChange}
                                    disabled
								/>
							</Form.Group>
							</Col>
						</Row>							
					</Accordion.Body>
				</Accordion.Item>	

                <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Dati catastali</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="row gy-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Categoria catastale
										</label>
										<input
											type="text"
											name="categoryLandRegistry"
										    value={data.categoryLandRegistry}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Foglio
										</label>
										<input
											type="text"
											name="sheet"
									        value={data.sheet}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Particella
										</label>
										<input
											name="parcel"
											value={
												props.formData?.parcel?.length > 0
													? props.formData.parcel[0].parcel
													: ""
											}
											onChange={onChange}
											type="text"
                                            disabled
											
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Subalterno
										</label>
										<input
											type="text"
											name="subaltern"
											value={
												props.formData?.parcel?.length > 0
													? props.formData.parcel[0]?.subaltern[0]
													: ""
											}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
								
					</div>
					<div className="row gy-3 mt-2">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie catastale
										</label>
										<input
											type="number"
											name="cadastralMq"
											value={data.cadastralMq}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie commerciale
										</label>
										<input
											type="number"
											name="commercialMq"
											value={data.commercialMq}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superficie utile lorda
										</label>
										<input
											name="grossMq"
											value={data.grossMq}
											onChange={onChange}
											type="number"
                                            disabled
											
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Superfiecie utile netta
										</label>
										<input
											type="number"
											name="netMq"
											value={data.netMq}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Anno di fabbricazione
										</label>
										<input
											name="manufactureYear"
											value={data.manufactureYear}
											onChange={onChange}
											type="number"
                                            disabled
											
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Anno ultima ristrutturazione
										</label>
										<input
											type="number"
											name="renovationLastYear"
											value={data.renovationLastYear}
											onChange={onChange}
                                            disabled
										/>
									</div>
								</div>
					</div>
							
                    <div className="row mt-2 gy-3">
                        
					<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Vetustà (vani)
										</label>
										<input
											type="text"
											name="antiquity"
											value={data.antiquity}
											onChange={(e) => {
												onChange(e);
											}}
											disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Valore OMI
										</label>
										<input
											type="number"
											name="omiValue"
											value={data.omiValue}
											onChange={(e) => {
												onChange(e);
											}}
											disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Libero dal
										</label>
										<input
											type="date"
											name="freeFrom"
											value={ data.freeFrom &&
												formatDateFromString( data.freeFrom)}
											onChange={(e) => {
												onChange(e);
											}}
											disabled
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Valore di bilancio
										</label>
										<input
											type="number"
											name="bookValue"
											value={data.bookValue}
											onChange={(e) => {
												onChange(e);
											}}
											disabled
										/>
									</div>
								</div>
                    </div>

                    <div className="input-checkbox mt-3">
						<input
							name="socialFee	"
							checked={data.socialFee}
							type="checkbox"
                            disabled
							 onChange={(e) => {
								props.onChange(e);
							}} 
						/>
						<label className="f-label fs-16">Canone sociale</label>
					</div>
                    <div className="input-checkbox mt-3">
						<input
							name="unusable"
                            disabled
							checked={data.unusable}
							type="checkbox"
							onChange={(e) => {
								props.onChange(e);
							}}
						/>
						<label className="f-label fs-16">Inagibile</label>
					</div>

					</Accordion.Body>
				</Accordion.Item>

                <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="3" activeKey={activeKey}>Costi annuali</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Imposte patrimoniali (imu, tasi)
							</label>
							<CurrencyInput
								id="input-example"
								name="propertyTaxes"
								decimalsLimit={2}
                                disabled
								groupSeparator="."
								decimalSeparator=","
								value={data.propertyTaxes}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Imposte sul reddito (IRPEF o IRES)
							</label>
							<CurrencyInput
								id="input-example"
								name="incomeTaxes"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={data.incomeTaxes}
                                disabled
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Spese condominiali (lato proprietà)
							</label>
							<CurrencyInput
								id="input-example"
								name="condominiumFees"
                                disabled
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={data.condominiumFees}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Costi diretti CDA
							</label>
							<CurrencyInput
                              disabled
								id="input-example"
								name="directCostCda"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={data.directCostCda}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Costi indiretti CDA
							</label>
							<CurrencyInput
								id="input-example"
								name="indirectCostCda"
								decimalsLimit={2}
                                disabled
								groupSeparator="."
								decimalSeparator=","
								value={data.indirectCostCda}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
                    <div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Imposte di registro - da automatizzare {/* <OverlayTrigger
										placement="top"
										delay={{ show: 50, hide: 50 }}
										overlay={
											<Tooltip id="tooltip" className="exclamation-tooltip">
												Questo valore viene automatizzato, quando viene creato un affitto relativo a questo immobile
											</Tooltip>
										}>
										<i className="fal fa-exclamation-circle"></i>
									</OverlayTrigger> */}
							</label>
							<CurrencyInput
								id="input-example"
								name="registrationTaxes"
                                disabled
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={data.registrationTaxes}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
				</div>
					</Accordion.Body>
				</Accordion.Item>	

                <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="4" activeKey={activeKey}>Redditività</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Media affitto <OverlayTrigger
										placement="top"
										delay={{ show: 50, hide: 50 }}
										overlay={
											<Tooltip id="tooltip" className="exclamation-tooltip">
												Valore automatizzato - Media dei canoni di affitto degli ultimi anni
											</Tooltip>
										}>
										<i className="fal fa-exclamation-circle"></i>
									</OverlayTrigger>
                                </label>
                                <input
                                    type="number"
                                    name="averageRent"
                                    onChange={onChange}
                                    value={data.averageRent}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Media vendita <OverlayTrigger
										placement="top"
										delay={{ show: 50, hide: 50 }}
										overlay={
											<Tooltip id="tooltip" className="exclamation-tooltip">
												Valore automatizzato 
											</Tooltip>
										}>
										<i className="fal fa-exclamation-circle"></i>
									</OverlayTrigger>
                                </label>
                                <input
                                    type="number"
                                    name="averageSale"
                                    onChange={onChange}
                                    value={data.averageSale}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. lorda vendita (nuovo)
                                </label>
                                <input
                                    type="number"
                                    name="grossSaleIncomeNew"
                                    onChange={onChange}
                                    value={data.grossSaleIncomeNew}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. lorda vendita (vetustà)
                                </label>
                                <input
                                    type="number"
                                    name="grossSaleIncomeOld"
                                    onChange={onChange}
                                    value={data.grossSaleIncomeOld}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. affitto (nuovo)
                                </label>
                                <input
                                    type="number"
                                    name="rentIncomeNew"
                                    onChange={onChange}
                                    value={data.rentIncomeNew}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. affitto (vetustà)
                                </label>
                                <input
                                    type="number"
                                    name="rentIncomeOld"
                                    onChange={onChange}
                                    value={data.rentIncomeOld}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. effettiva netta
                                </label>
                                <input
                                    type="number"
                                    name="actualNetIncome"
                                    onChange={onChange}
                                    value={data.actualNetIncome}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. potenziale netta
                                </label>
                                <input
                                    type="number"
                                    name="potentialNetIncome"
                                    onChange={onChange}
                                    value={data.potentialNetIncome}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red. lorda su val. bilancio
                                </label>
                                <input
                                    type="number"
                                    name="grossIncomeOnBookValue"
                                    onChange={onChange}
                                    value={data.grossIncomeOnBookValue}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Red, netta su val. bilancio
                                </label>
                                <input
                                    type="number"
                                    name="netIncomeOnBookValue"
                                    onChange={onChange}
                                    value={data.netIncomeOnBookValue}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Canone attuale <OverlayTrigger
										placement="top"
										delay={{ show: 50, hide: 50 }}
										overlay={
											<Tooltip id="tooltip" className="exclamation-tooltip">
												Questo valore viene automatizzato, quando viene creato un affitto relativo a questo immobile
											</Tooltip>
										}>
										<i className="fal fa-exclamation-circle"></i>
									</OverlayTrigger>
                                </label>
                                <input
                                    type="number"
                                    name="currentFee"
                                    onChange={onChange}
                                    value={data.currentFee}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column">
                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                    Risultato analitico
                                </label>
                                <input
                                    type="number"
                                    name="analyticalResult"
                                    onChange={onChange}
                                    value={data.analyticalResult}
                                />
                            </div>
                        </div>
                    </div>
					</Accordion.Body>
				</Accordion.Item>

                 <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="5" activeKey={activeKey}>Note</CustomToggle>
					</Card.Header>
					<Accordion.Body>
                        <div className="d-flex flex-column">
                            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                Note
                            </label>
                            <textarea
                                name="note"
                                className="border  fs-16 text-dark rounded-3 border-opacity-50"
                                onChange={onChange}
                                value={data.note}
                            />
                        </div>
					</Accordion.Body>
				</Accordion.Item>					
			</Accordion>
			</fieldset>
            </div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
});
export default connect(mapStateToProps, { getProvince, getMunicipality })(
	Profitability
);
