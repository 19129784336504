import { useEffect} from 'react'

export default function useOnClickOutside(ref, handler, except='') {
    useEffect(
      () => {
        const listener = (event) => {

          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target) || except && event.target===except.current) {
            return;
            //se è === except fai solo la patch
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }