import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api, {
  supplier_endpoint,
  supplier_bank_account_assignment_endpoint,
  bank_account_endpoint, BASE_URL
} from "../../../../api/api.js";
import "../../../../assets/css/Membri.css";
import LogoMark from "../../../../assets/images/logo_mark.svg";
import ButtonRm from "../../../ButtonRm.js";
import { useEffect } from "react";
import CustomBadge from "../../../CustomBadge";


export default function TopHeadPartE(props) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  console.log(location.pathname.substring(location.pathname.lastIndexOf("/") + 1))

  const [currentBank, setCurrentBank] = useState([])

  const currentUrl = window.location.href;
  const id = currentUrl.split("/").pop();

  // TODO con api() da problemi con la chiamata API, da risolvere
  const fetchSupplier = () => {
    axios
      .create({
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
        },
      })
      .get(
          supplier_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
      .then((res) => {
        if (res.data) {
          setCurrentBank(res.data.bankAccounts)
        }
      });
  };

  useEffect(() => {
    fetchSupplier()
  }, [])


  const editSaveClicked = () => {
    const data = { ...props.formData };
    const bankAccounts = [...data.bankAccounts];
    const attachment = [...data.attachment]
  
    delete data.bankAccounts;
    delete data.attachment

     //GESTIONE ALLEGATI
		let formDataToSend = new FormData();
	
		attachment.forEach((file) => {
      if(typeof file != 'string'){
        const blob = new Blob([file]);
			  formDataToSend.append("parts", blob, file.name);
      }
		});

		let sentUpload = formDataToSend.get("parts") !== null;

    let uploadPromise;

    if (sentUpload) {
      uploadPromise = api().put(`${supplier_endpoint}upload/${id}`, formDataToSend);
    } else {
      uploadPromise = Promise.resolve(); // Crea una promessa risolta immediatamente
    }
  
    uploadPromise
  .then(() => {
    return api().put(
      supplier_endpoint + location.pathname.substring(location.pathname.lastIndexOf("/") + 1),
      data
    );
  })
    api()
      .put(
        supplier_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1),
        data
      )
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Success Notification !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
  
          const createPromises = bankAccounts
            .filter((bankAccount) => !currentBank.find((el) => el.id === bankAccount.id))
            .map((bankAccount) => {
              return api().post(bank_account_endpoint, { ...bankAccount });
            });
  
          Promise.all(createPromises)
            .then((responses) => {
              const assignmentPromises = [];
  
              responses.forEach((bankRes) => {
                const assignmentPromise = api().post(supplier_bank_account_assignment_endpoint, {
                  supplierID: res.data.id,
                  bankAccountID: bankRes.data.id,
                });
                assignmentPromises.push(assignmentPromise);
              });
  
              return Promise.all(assignmentPromises);
            })
            .then(() => {
              // DISSOCIAZIONE CONTI ELIMINATI
              const deletePromises = currentBank
                .filter((bankAccount) => !bankAccounts.find((el) => el.id === bankAccount.id))
                .map((bankAccount) => {
                  return api().delete(`${supplier_bank_account_assignment_endpoint}disassociate`, {
                    data: {
                      supplierID: res.data.id,
                      bankAccountID: bankAccount.id,
                    },
                  });
                });
  
              return Promise.all(deletePromises);
            })
            .then(() => {
              // Completata la dissociazione dei conti bancari
              console.log("Dissociazione dei conti bancari completata");
            })
            .catch((error) => {
              console.error("Errore durante la gestione delle richieste API", error);
            });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
      })
      .finally(() => {
        props.fetchSupplier()
        navigate(-1);
      });
  };


  const editCloseClicked = () => {
    handleClose();
    navigate(-1);
  };
  return (
    <>
      
      <div
        className="d-flex flex-column flex-md-row
     justify-content-between align-items-md-end topHeading-title"
      >
        <div className="flex-grow-1">
          <div className="d-flex flex-column">
            <h2 className="fs-24 fs-md-30 text-dark fw-semibold mb-3 mb-md-0 text-capitalize">
              {(props.formData.surname && props.formData.name) ? `${props.formData.surname} ${props.formData.name}` : ``}

            </h2>
          </div>
        </div>
        <div className="flex-shrink-0">
          <div className="d-flex align-items-center">
            <ButtonRm variant="blue" onClick={() => editSaveClicked()} className="me-2">Salva Modifiche</ButtonRm>
            <button
              type="button"
              onClick={handleShow}
              className="bg-white rounded-3 py-2 px-3"
              style={{color:"#9ccbf2", border: "1px solid #9ccbf2"}}
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi annullare le modifiche?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Se non salvi le modifiche, i cambiamenti effettuati andranno persi
            </h6>
            <button
              type="button"
              onClick={() => editCloseClicked()}
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
            >
              Conferma annullamento
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={handleClose}
            >
              Indietro
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
