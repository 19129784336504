import { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useNavigate } from 'react-router-dom';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import checking from "../../assets/images/checking.png"

import PacchettoCardRm from "../../components/Impostazioni/PacchettoCardRm"

import logo from "../../assets/images/Group 1189.svg"

import ResponsiveNavbar from './NavRm';

import RisparmioOggettivo from '../../pages/RisparmioOggettivo';

import { Link } from 'react-router-dom';

import Footer from './Footer'

const prestazioni = [
    "Web app e app mobile",
    "Gestione di unità senza limiti",
    "Ricerca fornitori",
    "Valutazione immobiliare automatizzata",
    "Invita e collabora con proprietari, inquilini e fornitori",
    "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)",
    "Visualizzazioni immobili su mappa",
    "Visualizzazioni interventi con specifiche su planimetria",
    "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)",
    "Pagamenti affitti/ fornitori",
    "Gestione affitti",
    "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)",
    "Documenti (Proforma editabili)",
    "Calcolatrice Immobiliare automatizzati",
    "Gestione sincronizzata con il calendario di scadenze e alert",
    "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)",
    "Checklist personalizzabili per raccolta dati",
    "Gestione stabili",
    "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)",
    "Creazione di team operativi (squadre)",
    "Esportazione di ogni sezione in pdf e csv (excel, word)",
    "Aggiunta di prestazioni su misura",
    "Assistenza video call 30 min al mese per un anno",
    "Assistenza trasmigrazione dati",
    "Integrazioni con altri gestionali",
  ];
  

  

export default function Pacchetti (){
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('/home');

  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  const pack = [
    {
      name: "Basic",
      cost: 9.99,
      month: "al mese",
      user: "un utenza",
      bought: 0,
      used: 0,
      color: 'light',
      feature: [
        { name: "Web app e app mobile", activated: true },
        { name: "Gestione 5 unità", activated: true },
        { name: "Ricerca fornitori", activated: true },
        { name: "Invita e collabora con proprietari, inquilini e fornitori", activated: true },
        { name: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)", activated: true },
        { name: "Visualizzazioni immobili su mappa", activated: true },
        { name: "Visualizzazioni interventi con specifiche su planimetria", activated: true },
        { name: "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)", activated: true },
        { name: "Pagamenti affitti/ fornitori", activated: true },
        { name: "Gestione affitti", activated: true },
        { name: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)", activated: true },
        { name: "Documenti (Proforma editabili)", activated: true },
        { name: "Calcolatrice Immobiliare", activated: true },
        { name: "Gestione sincronizzata con il calendario di scadenze e alert", activated: true },
        { name: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)", activated: true },
        { name: "Checklist personalizzabili per raccolta dati", activated: true },
        { name: "Assistenza BASIC", activated: true },
        { name: "Valutazione immobiliare automatizzata", activated: false},
        { name: "Gestione stabili", activated: false },
        { name: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)", activated: false },
        { name: "Creazione di team operativi (squadre)", activated: false },
        { name: "Esportazione di ogni sezione in pdf e csv (excel, word)", activated: false },
        { name: "Aggiunta di prestazioni su misura", activated: false },
        { name: "Assistenza trasmigrazione dati", activated: false },
        { name: "Integrazioni con altri gestionali", activated: false },
      ],
      discount: null,
    },
    {
      name: "Pro",
      cost: 59.99,
      month: "al mese",
      user: "un utenza",
      bought: 0,
      used: 0,
      color: 'dark',
      feature: [
        { name: "Web app e app mobile", activated: true },
        { name: "Gestione di unità senza limiti", activated: true },
        { name: "Ricerca fornitori", activated: true },
        { name: "Valutazione immobiliare automatizzata", activated: true },
        { name: "Invita e collabora con proprietari, inquilini e fornitori", activated: true },
        { name: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)", activated: true },
        { name: "Visualizzazioni immobili su mappa", activated: true },
        { name: "Visualizzazioni interventi con specifiche su planimetria", activated: true },
        { name: "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)", activated: true },
        { name: "Pagamenti affitti/ fornitori", activated: true },
        { name: "Gestione affitti", activated: true },
        { name: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)", activated: true },
        { name: "Documenti (Proforma editabili)", activated: true },
        { name: "Calcolatrice Immobiliare", activated: true },
        { name: "Gestione sincronizzata con il calendario di scadenze e alert", activated: true },
        { name: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)", activated: true },
        { name: "Checklist personalizzabili per raccolta dati", activated: true },
        { name: "Gestione stabili", activated: true },
        { name: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)", activated: true },
        { name: "Creazione di team operativi (squadre)", activated: true },
        { name: "Esportazione di ogni sezione in pdf e csv (excel, word)", activated: true },
        { name: "Assistenza GOLD", activated: true },
        { name: "Aggiunta di prestazioni su misura", activated: false },
        { name: "Assistenza trasmigrazione dati", activated: false },
        { name: "Integrazioni con altri gestionali", activated: false },
      ],
      discount: null,
    },
    {
      name: "Custom",
      cost: "Su misura",
      month: "su misura",
      user: "Utenze da definire",
      bought: 0,
      used: 0,
      color: 'light',
      feature: [
        { name: "Web app e app mobile", activated: true },
        { name: "Gestione di unità senza limiti", activated: true },
        { name: "Ricerca fornitori", activated: true },
        { name: "Valutazione immobiliare automatizzata", activated: true },
        { name: "Invita e collabora con proprietari, inquilini e fornitori", activated: true },
        { name: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)", activated: true },
        { name: "Visualizzazioni immobili su mappa", activated: true },
        { name: "Visualizzazioni interventi con specifiche su planimetria", activated: true },
        { name: "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)", activated: true },
        { name: "Pagamenti affitti/ fornitori", activated: true },
        { name: "Gestione affitti", activated: true },
        { name: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)", activated: true },
        { name: "Documenti (Proforma editabili)", activated: true },
        { name: "Calcolatrice Immobiliare", activated: true },
        { name: "Gestione sincronizzata con il calendario di scadenze e alert", activated: true },
        { name: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)", activated: true },
        { name: "Checklist personalizzabili per raccolta dati", activated: true },
        { name: "Gestione stabili", activated: true },
        { name: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)", activated: true },
        { name: "Creazione di team operativi (squadre)", activated: true },
        { name: "Esportazione di ogni sezione in pdf e csv (excel, word)", activated: true },
        { name: "Assistenza PLATINUM", activated: true },
        { name: "Aggiunta di prestazioni su misura", activated: true },
        { name: "Assistenza trasmigrazione dati", activated: true },
        { name: "Integrazioni con altri gestionali", activated: true },
      ],
      discount: null,
    },
  ];

  const pack2 = [
    {
      name: "Trasmigrazione dati",
      cost: "9.99",
      month: "una tantum",
      user: "Importiamo dati e anagrafiche dal tuo vecchio gestionale a Real Meta",
      bought: 0,
      used: 0,
      color: 'light',
      feature: [],
      discount: null,
    },
    {
      name: "Assistenza clienti",
      cost: "9.99",
      month: "una tantum",
      user: "Verrai contattato da unnostro collaboratore per fissare data e ora dell’incontro",
      bought: 0,
      used: 0,
      color: 'light',
      feature: [],
      discount: null,
    },
    {
      name: "Integrazione ad altri gestionali",
      month: "una tantum",
      user: "spiegazione",
      cost: "Custom",
      bought: 0,
      used: 0,
      color: 'light',
      feature: [],
      discount: null,
    },
  ];

  function handleClick(){
    navigate("/contatti")
}

const [checked, setChecked] = useState(false);

const handleChecked = (event) => {
  setChecked(event.target.checked);
};


    return (
        <div style={{background:'#fff', position: "relative"}}>
            <div style={{background: ''}}>
                <Container className="px-3 px-md-0">

                <ResponsiveNavbar/>


                    <Row className="flex-column mb-2">
                        <Col className="d-flex justify-content-center" style={{padding: "4rem 0rem 2rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center fs-40 fs-md-50 syne-font">Pacchetti</h1>
                            </div>
                        </Col>
                        
                    </Row>

                    <Row className="mt-2 justify-content-center align-items-center mx-auto" style={{maxWidth: "1500px"}}>

                       {/*  <Nav variant="" activeKey={activeTab} onSelect={handleTabChange}>
        <Nav.Item className={`me-5  mb-4 text-dark ${activeTab == "/home" ? "fw-bold" : 'opacity-50'} `}>
          <Nav.Link className="text-dark"  eventKey="/home">Prezzi</Nav.Link>
        </Nav.Item>
        <Nav.Item className={`text-dark ${activeTab != "/home" ? "fw-bold" : 'opacity-50'}`}>
          <Nav.Link className="text-dark" eventKey="link-1">Servizi</Nav.Link>
        </Nav.Item>
                        </Nav> */}
      {/* Content based on activeTab */}
      {/* {activeTab === '/home' && (
        <Row mt={3}>
         
          {
                        pack.length > 0 &&
                        pack.map((item, index) => {
                            return <PacchettoCardRm key={index} item={item} yearly={false} showModalPayment={false}  handleCustom={handleClick} handleClick={handleClick}/>
                        })
                    }

                  
        </Row>
      )}
      {activeTab === 'link-1' && (
        <Row>
          {
                        pack2.length > 0 &&
                        pack2.map((item, index) => {
                            return <PacchettoCardRm key={index} item={item} yearly={false} showModalPayment={false} showCalcolo={false}/>
                        })
                    }
        </Row>
      )} */}

              <Row className='mx-auto'>
                <FormGroup>
                  <FormControlLabel className='mx-auto' control={<Switch />} label="Annuale"  checked={checked}
  onChange={handleChecked}/>
                </FormGroup>
              </Row>
              {/* MOBILE */}
              <Row className="flex-column flex-md-row flex-md-wrap plans d-flex d-xl-none g-3">
                <Col md={6} lg={3} className="plan-container">
                      <div className='plan'>
                        <div class="plan-head">
                        <h3 class="has-subtitle">Basic</h3>
                          <p class="subtitle subtitle text-decoration-line-through fs-20 opacity-1">{checked ? "19,99" : "21,99"}</p>
                          <div class="prices">
                              <p class="price">€ {checked ? "9,99" : "10,99"} <span class="fs-18"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={handleClick}>Prova gratis</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Fino a 5</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Assente</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: 1 </span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria: Assente</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: 30</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 72h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>

                <Col md={6} lg={3}  className="plan-container ">
                      <div className='plan'>
                        <div class="plan-head">
                        <h3 class="has-subtitle">Pro</h3>
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "49,99" : "59,99"} </p>

                          <div class="prices">
                              <p class="price">€ {checked ? "20,99" : "29,99"} <span class="fs-12"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={handleClick}>Prova gratis</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Fino a 50</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: 10 </span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria: Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 48h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>

                <Col md={6} lg={3}  className="plan-container ">
                      <div className='plan premium'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Premium</h3>
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "119,99" : "129,99"} </p>
                          <div class="prices">
                              <p class="price">€ {checked ? "59,99" : "64,99"}<span class="fs-12"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="outline" style={{background: "white", borderColor: "white", color:"#0F1B4C" }} className="me-2 w-100" onClick={handleClick}>Prova gratis</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Fino a 500</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: 50 </span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria: Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>

                <Col md={6} lg={3}  className="plan-container">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Enteprise</h3>
                          <p class="subtitle">Hai bisogno di un
preventivo personalizzato?</p>
                          <div class="prices">
                              <p class="price transparent">  <span class="fs-12"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={handleClick}>Contattaci</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria:Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Frontale</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
              </Row>        

               {/* DESKTOP*/}
              <Row className="plans d-none d-xl-flex">
                <Col className="plan-features mt-5">
                  <div className='plan-head'>
                  <p class="per-month d-none d-xl-block">Costo per utente al mese</p>
                    <p class="per-month d-block d-xl-none">Costo per utente al mese</p>
                  </div>
                  <ul className='ps-0'>
                    <li className='feature'>
                      <p>Gestione unità</p>
                    </li>
                    <li className='feature'>
                      <p>Gestione stabili</p>
                    </li>
                    <li className='feature'>
                      <p>Creazione valutazione</p>
                    </li>
                    <li className='feature'>
                      <p>Parte finanziaria</p>
                    </li>
                    <li className='feature'>
                      <p>Gestione attività</p>
                    </li>
                    <li className='feature'>
                      <p>Calcoli e calcolatrice</p>
                    </li>
                    <li className='feature'>
                      <p>Aggiornamenti del software</p>
                    </li>
                    <li className='feature'>
                      <p>Assistenza</p>
                    </li>
                    <li className='feature'>
                      <p>Formazione</p>
                    </li>
                  </ul>
                </Col>
                <Col className="plan-container mt-5">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Basic</h3>
                          <p class="subtitle subtitle text-decoration-line-through fs-20 opacity-1">{checked ? "19,99" : "21,99"}</p>
                          <div class="prices">
                              <p class="price">€ {checked ? "9,99" : "10,99"} <span class="fs-18"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100">Prova gratis</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Da 1 a 5</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Assente</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">1 valutazione</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Assente</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">30 ticket</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 72h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col className="plan-container mt-5">
                      <div className='plan premium'>
                        <div class="plan-head">
                        <h3 class="has-subtitle">Pro</h3>
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "49,99" : "59,99"} </p>
                          <div class="prices">
                              <p class="price">€ {checked ? "20,99" : "29,99"} <span class="fs-12"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="outline" style={{background: "white", borderColor: "white", color:"#0F1B4C" }} className="me-2 w-100">Prova gratis</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Fino a 50</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">10 valutazioni</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 48h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col className="plan-container  mt-5">
                      <div className='plan'>
                        <div class="plan-head">
                        <h3 class="has-subtitle">Premium</h3>
                          
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "119,99" : "129,99"} </p>
                          <div class="prices">
                         
                              <p class="price">€ {checked ? "59,99" : "64,99"}<span class="fs-12"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" onClick={handleClick} style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100">Prova gratis</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Fino a 500</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">50 valutazioni</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
              
                <Col className="plan-container mt-5">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Enteprise</h3>
                          <p class="subtitle transparent">-</p>

                          <p class="subtitle fs-12">Hai bisogno di un
preventivo personalizzato?</p>
                          <div class="prices">
                              <p class="price"> <span class="fs-12"></span></p>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={handleClick}>Contattaci</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Frontale</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
              </Row>
                        
                    </Row>

                    <Row>
                      <RisparmioOggettivo alone={false}/>
                    </Row>

                    <Row className="mt-5 justify-content-center align-items-center mx-auto p-5" style={{maxWidth: "1100px", background: "#0F1B4C", borderRadius: "1rem", color:"white"}}>
                        <Col md={12} className="text-center">
                            <h2>Desideri maggiori informazioni?</h2>
                            <p className='mt-2 mb-3'>Real Meta semplifica e automattiza i processi di gestione immobiliare, mentre tu puoi  concentrarti e far crescere la tua attività.</p>

                            <Link to="/contatti"><Button variant="primary" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}}>Contattaci</Button></Link>
                        </Col>
        
                    </Row>



                    <Footer />

                   

                </Container>
            </div>

           {/*  <Image src={checking}  width="250px" style={{position: "fixed", bottom: "0px", right: "0px"}}  className='d-none d-lg-block'/> */}
        </div>
    )
}