import { useState, useEffect, useRef } from "react";
import { BsPrinter, BsPencil } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";
import ButtonRm from "../../ButtonRm";
import CustomBadge from "../../CustomBadge";



const AccountModal = (props) => {
	const [account, setAccount] = useState({});
	const [editMode, setEditMode] = useState(false);
	const init = () => {
		if (props.account !== undefined) {
			setAccount(props.account);
		} else
			setAccount({
				Nome: "",
				Tipo: "",
				IBAN: "",
				Provincia: "",
				Intestazione: "",
				ABI: "",
				CAB: "",
				CONTO: "",
				CIN: "",
				"BIC/SWIFT": "",
				Banca: "",
				"Codice SIA": "",
				"Agenzia/Comune": "",
				"Codice CUC SEPA": "",
				"Conto disabilitato": false,
			});
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		if (e.target.type==="checkbox") {
			setAccount({ ...account, [e.target.name]: e.target.checked });
		} else setAccount({ ...account, [e.target.name]: e.target.value });
	};

	const save = () => {
		if (props.newMode) props.add(account);
		else props.update(account);
	};

	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div
			className={`mt-4 ${
				props.editMode===undefined ? "" : "edit-content-container"
			}`}
			style={{maxHeight: "400px", overflowY: "auto", borderRadius:".5rem", overflowX:"hidden"}}>
			{props.newMode ? (
				<div className="fs-24 fw-semibold">Nuova risorsa contabile</div>
			) : (
				<div className="d-flex justify-content-between">
					<div className="fs-24 fw-semibold">
						{editMode
							? "Modifica risorsa contabile"
							: "Visualizza risorsa contabile"}
					</div>

					<div className={`d-flex ${editMode ? "d-none" : ""}`}>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"
							onClick={handlePrint}>
							<BsPrinter color={props.editMode ? "var(--edit-color)" : ""} />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40"
							onClick={() => {
								setEditMode(true);
							}}>
							<BsPencil color={props.editMode ? "var(--edit-color)" : ""} />
						</button>
					</div>
				</div>
			)}
			<div ref={componentRef} className="d-block">
				<fieldset disabled={editMode || props.newMode ? false : true}>
					<div className="row mt-3 flex-column">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Nome
								</label>
								<input
									type="text"
									name="Nome"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.Nome}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Tipo
								</label>
								<input
									type="text"
									name="Tipo"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.Tipo}
								/>
							</div>
						</div>
					</div>
					<div className="label-heading my-4 d-block w-100">
						<div className="label-text rel d-inline-block py-2 px-2">
							<h1 className="fs-16 text-white text-uppercase fw-semibold">
								DATI DEL CONTO
							</h1>
						</div>
					</div>
					<div className="row gy-2 flex-column">
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									IBAN
								</label>
								<input
									type="text"
									name="IBAN"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.IBAN}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Provincia
								</label>
								<input
									type="text"
									name="Provincia"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.Provincia}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Intestazione
								</label>
								<input
									type="text"
									name="Intestazione"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.Intestazione}
								/>
							</div>
						</div>
						<div className="col-md-12 d-flex">
							<div
								className="d-flex flex-column"
								style={{ marginRight: "15px", width: "13%" }}>
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									ABI
								</label>
								<input
									type="text"
									name="ABI"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.ABI}
								/>
							</div>
							<div
								className="d-flex flex-column"
								style={{ marginRight: "15px", width: "13%" }}>
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									CAB
								</label>
								<input
									type="text"
									name="CAB"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.CAB}
								/>
							</div>
							<div
								className="d-flex flex-column"
								style={{ marginRight: "15px", width: "27%" }}>
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									CONTO
								</label>
								<input
									type="text"
									name="CONTO"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.CONTO}
								/>
							</div>
							<div
								className="d-flex flex-column"
								style={{ marginRight: "15px", width: "13%" }}>
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									CIN
								</label>
								<input
									type="text"
									name="CIN"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.CIN}
								/>
							</div>
							<div
								className="d-flex flex-column w-124"
								style={{ marginRight: "15px", width: "23%" }}>
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									BIC/SWIFT
								</label>
								<input
									type="text"
									name="BIC/SWIFT"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account["BIC/SWIFT"]}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Banca
								</label>
								<input
									type="text"
									name="Banca"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account.Banca}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Codice SIA
								</label>
								<input
									type="text"
									name="Codice SIA"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account["Codice SIA"]}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Agenzia/Comune
								</label>
								<input
									type="text"
									name="Agenzia/Comune"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account["Agenzia/Comune"]}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Codice CUC SEPA
								</label>
								<input
									type="text"
									name="Codice CUC SEPA"
									className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
									onChange={onChange}
									value={account["Codice CUC SEPA"]}
								/>
							</div>
						</div>
					</div>
					{/* <div
						className={`input-checkbox mt-3 ${props.newMode ? "d-none" : ""}`}>
						<input
							type="checkbox"
							id="disabilitato"
							name="disabilitato"
							onChange={onChange}
							value={account.disabilitato}
						/>
						<label htmlFor="disabilitato" className="f-label fs-16 d-flex align-items-center
me-2">
							Conto disabilitato
						</label>
					</div> */}
				</fieldset>
				{editMode || props.newMode ? (
					<div className="d-flex align-items-center mt-5 form-last-btns justify-content-center mb-4">
					<ButtonRm variant="tertiary" onClick={() => {
								props.setShow(false);
							}} className="me-2" >Annulla</ButtonRm>
					<ButtonRm variant="primary" onClick={save}>Salva</ButtonRm>
				  </div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default AccountModal;
