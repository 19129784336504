import React, { useState, useEffect } from "react";
import TopHeading from "../components/TopHeading";
import EmptyPage from "../assets/images/affitti_empty.png";
import { BsPlusCircleFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../assets/css/Evaluation.css";
import api, { evaluation_endpoint } from "../api/api";
import EvaluationFirstPage from "../components/UnitàImmobiliariStabilli/ValutazioneImmobilire/EvaluationFirstPage";

import {Row, Col} from "react-bootstrap"
import { Link } from "react-router-dom";
import AddBtn from "../components/AddBtn";

import { checkUserData } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";


const ValutazioneImmobilire = () => {
	const [evaluation, setEvaluation] = useState([]);

	async function getEvaluation() {
		try {
			const response = await api().get(`${evaluation_endpoint}`);
			if (response.data) {
				setEvaluation(response.data.content);
				console.log(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getEvaluation();
	}, []);

	const deleteEvaluation = (id) => {
		let temp = [...evaluation];
		temp = temp.filter((item) => item.id !== id);
		setEvaluation(temp);
	};

	const [search_name, setSearchName] = useState("");

	 //CHECK IF DATA REQUIRED ARE COMPILED
	 const [dataRequired, setDataRequired] = useState(true);
	 //Gestisce il modale
	 const [showCheck, setShowCheck] = useState(false)
 
	 useEffect(() => {
		 const fetchData = async () => {
			 try {
				 const response = await checkUserData();
				 setDataRequired(response.isVerified);
				 if (!response.isVerified) {
				   setShowCheck(true);
			   }
			 } catch (error) {
				 // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
				 console.error('Errore durante il recupero dei dati utente:', error);
			 }
		 };
 
		 fetchData();
	 }, []);
 

	return (
		<>
			<div className="title-search-group mobile-container-xl">
				<div className="right-header">
					<TopHeading
						SmallHeading="empty"
					/>
				</div>
				{/* <div className="model-modul-group">
					<div className="d-flex align-items-center mt-2 justify-content-between btn-search-group">
						<div className="search-input">
							<div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
								<i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
								<input
									type="text"
									name=""
									placeholder="Cerca"
									className="flex-shink-0 border-0 w-100 fs-16"
									onChange={(e) => {
										setSearchName(e.target.value);
									}}
								/>
							</div>
						</div>
						<OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className="tooltip-container">
									Nuovo Valutazione Immobiliare
								</Tooltip>
							}>
							<Link to="/evaluation/create">
								<div className="plus-button">
									<BsPlusCircleFill className="fs-40 plus-btn" />
								</div>
							</Link>
						</OverlayTrigger>
					</div>
				</div> */}
				<Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
					<Col >
						<h2 className="fw-bold text-start">Valutazione Immobiliare</h2>
					</Col>
					<Col className="d-flex justify-content-end ">
						<Link to="/evaluation/create">
						<AddBtn
							tooltip="Nuova valutazione"
							className="me-3 me-md-0 order-md-1"
							dataRequired={dataRequired}
						/>
						</Link>
						{/* <SearchInput
						className="order-md-0"
						setSearch={(term) => {
							setSearchTerm(term);
						}}
						/> */}
					</Col>
					</Row>
			</div>
			{evaluation.length > 0 ? (
				<EvaluationFirstPage
					data={evaluation}
					deleteEvaluation={deleteEvaluation}
				/>
			) : (
				<Row className="dashboard-container ">
					<div className="mx-auto  text-center empty-container">
						<div className="mx-auto d-table w-160">
							<img loading="lazy" src={EmptyPage} alt="" />
						</div>
						<p className="fs-20 text-secondary text-center ">
							Non hai aggiunto ancora nessuna valutazione immobiliare
						</p>
					</div>
				</Row>
			)}

		<ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
			<p className="mb-3">Per procedere con la creazione della valutazione, è necessario completare le seguenti informazioni:</p>
			<p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
			
			{/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
		</ModalCheckUser>
		</>
	);
};

export default ValutazioneImmobilire;
