import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "../../../../assets/css/Tabs.css";
import PencilEdit from "../../../../assets/images/pencil_edit.svg";

const TabContent6 = (props) => {
  return (
    <div
      className="container"
      style={{ display: props.isActive === true ? "" : "none" }}
    >
      <div className="tab1ContentTitle justify-content-center">Riepilogo</div>
      <div className="col-md-8 col-sm-8 m-auto">
        <div className="tab6Border-div">
          <div className="tab6Border-insideContainer">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="tab6Content-title">DATI DI REGISTRAZIONE</div>
              <img
                src={PencilEdit}
                style={{ cursor: "pointer" }}
                onClick={() => props.edit1()}
              ></img>
            </div>
            <hr></hr>
            <div className="d-flex">
              <div>
                <div className="tab6Content">USERNAME</div>
                <div className="tab6Content">EMAIL</div>
                <div className="tab6Content">TELEFONO</div>
              </div>
              <div style={{ marginLeft: "35%" }}>
                <div className="tab6Content">
                  {props.formData.username ? props.formData.username : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.email ? props.formData.email : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.phone ? props.formData.phone : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab6Border-div">
          <div className="tab6Border-insideContainer">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="tab6Content-title">DATI PERSONALI</div>
              <img
                src={PencilEdit}
                style={{ cursor: "pointer" }}
                onClick={() => props.edit2()}
              ></img>
            </div>
            <hr></hr>
            <div className="d-flex">
              <div>
                <div className="tab6Content">NOME E COGNOME</div>
                <div className="tab6Content">CODICE FISCALE</div>
                <div className="tab6Content">SESSO</div>
                <div className="tab6Content">DATA DI NASCITA</div>
                <div className="tab6Content">INDIRIZZO DI RESIDENZA</div>
                <div className="tab6Content">PROVINCIA DI RESIDENZA</div>
                <div className="tab6Content">COMUNE DI RESIDENZA</div>
                <div className="tab6Content">CAP</div>
              </div>
              <div style={{ marginLeft: "17%" }}>
                <div className="tab6Content">
                  {props.formData.name
                    ? props.formData.surname
                      ? props.formData.name + "  " + props.formData.surname
                      : "-"
                    : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.fiscalCode ? props.formData.fiscalCode : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.sex ? props.formData.sex : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.birthday ? props.formData.birthday : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.address
                    ? props.formData.houseNumber
                      ? props.formData.address +
                        " " +
                        props.formData.houseNumber
                      : "-"
                    : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.province ? props.formData.province : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.municipality
                    ? props.formData.municipality
                    : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.cap ? props.formData.cap : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab6Border-div">
          <div className="tab6Border-insideContainer">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="tab6Content-title">CARICAMENTO DOCUMENTI</div>
              <img
                src={PencilEdit}
                style={{ cursor: "pointer" }}
                onClick={() => props.edit3()}
              ></img>
            </div>
            <hr></hr>
            <div className="d-flex">
              <div>
                <div className="tab6Content">TIPO DI DOCUMENTO</div>
                <div className="tab6Content">RILASCIATO DA</div>
                <div className="tab6Content">DATA DI RILASCIO</div>
                <div className="tab6Content">N°DOCUMENTO</div>
                <div className="tab6Content">LUOGO DI RILASCIO</div>
                <div className="tab6Content">ALLEGATI</div>
              </div>
              <div style={{ marginLeft: "17%" }}>
                <div className="tab6Content">
                  {props.formData.documentType
                    ? props.formData.documentType
                    : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.issueEntity
                    ? props.formData.issueEntity
                    : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.issueDate ? props.formData.issueDate : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.documentNumber
                    ? props.formData.documentNumber
                    : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.issuePlace ? props.formData.issuePlace : "-"}
                </div>
                <div className="tab6Content">
                  {props.formData.documentFile
                    ? props.formData.documentFile?.[0].name
                    : "-"}

                </div> 
              </div>
            </div>
          </div>
        </div>
        <div className="tab6Border-div">
          <div className="tab6Border-insideContainer">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div className="tab6Content-title">FACE ID</div>
              <img
                src={PencilEdit}
                style={{ cursor: "pointer" }}
                onClick={() => props.edit5()}
              ></img>
            </div>
            <hr></hr>
            {props.formData.faceFile &&
            <img
            src={ props.formData.faceFile}
            className="tab5Content"
            style={{ maxWidth: "60%" }}
          ></img>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabContent6;
