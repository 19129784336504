import React, { useEffect, useState } from "react";
import search from "../../../assets/images/search.svg";
import download from "../../../assets/images/download.svg";
import edit from "../../../assets/images/edit.svg";
import delete_icon from "../../../assets/images/delete.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import url from "../../../assets/scan876.pdf";
import { Logger } from "logging-library";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
import CheckBtn from "../../../assets/images/check_btn.svg";
import riepilogo from "../../../assets/images/riepilogo.svg";

import { Link } from "react-router-dom";
import CloseBtn from "../../../assets/images/close_btn.svg";
import api, { model_endpoint, BASE_URL } from "../../../api/api";


import { Col } from "react-bootstrap";

//CARD MODELLI

function UserTemplateStorico(props) {
  const [preview_modal_show, setPreviewModalShow] = useState(false);
  const previewModalClose = () => setPreviewModalShow(false);
  const previewModalShow = () => setPreviewModalShow(true);
  const [change_state, setChangeState] = useState(false);
  const [data, setData] = useState();
  const type = "pdf";
  const onError = (e) => {
    Logger.logError(e, "error in file-viewer");
  };
  const [new_template_name, setNewTemplateName] = useState(props.name);
  const changeTemplateName = () => {
    setChangeState(false);
  };

  const update = async (newName) => {
    try {
      await api().put(`${model_endpoint}${props.id}`, {
        id: props.id,
        name: newName
      });
    } catch (err) {
      console.log(err);
    }
  }

  // const getDoc = async () => {
  //   const result = await api().get(model_endpoint+ "download/24/report boscovich.pdf");
  //   console.debug(result.data);
  //   setData(result.data);
  // }

  useEffect(() => {
    const user = localStorage.getItem("user");
    setData(JSON.parse(user).token);
  },[]);

  return (
    <>
      <div className="template-card  d-md-flex justify-content-md-between align-items-md-center">
        <Col md={6} className="mb-1 mb-md-0">
          <input
            type="text"
            className="fs-16 text-dark card-input  fw-bold template-card-input"
            disabled={change_state ? false : true}
            value={new_template_name}
            onChange={(e) => {
              setNewTemplateName(e.target.value);
              update(e.target.value);
            }}
          />
        </Col>
        <Col md={6} className="">
            <div className={`icon-btn-group ${change_state ? "d-none" : ""} justify-content-md-end`}>
                <button
                type="button"
                className="btn round-icon-btn"
                //onClick={previewModalShow}
                >
                <img loading="lazy" src={search} alt="icon" />
                </button>
    
                <button type="button" className="btn round-icon-btn">
                    <img loading="lazy" src={download} alt="icon" />
                </button>

                <button
                type="button"
                className="btn round-icon-btn"
                onClick={props.showModalNew}
                >
                    <img loading="lazy" src={riepilogo} alt="icon" />
                </button>
            </div>
        </Col>
      </div>
      <Modal
        show={preview_modal_show}
        onHide={previewModalClose}
        className="preview-screen"
      >
        <Button
          variant=""
          onClick={previewModalClose}
          className="modal-close-btn"
        >
          <i className="bi bi-x modal-close-icon"></i>
        </Button>
        <Modal.Body>
          {/* <FileViewer
            fileType={type}
            filePath={url}
            errorComponent={CustomErrorComponent}
            onError={onError}
          /> */}
          <FileViewer
            fileType={"pdf"}
            filePath={data && props.attachments && props.attachments.length > 0 && 
              BASE_URL + model_endpoint+ "download/" + props.id + "/jwt/" + data + "/" + props.attachments[0]} // TODO to manage more attachments
            errorComponent={CustomErrorComponent}
            onError={onError}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UserTemplateStorico;