import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OwnerModal from "./OwnerModal";
import { Link } from "react-router-dom";
import EditableOwnerCard from "./Edit/EditableOwnerCard";
import api, { owner_endpoint, conductor_endpoint } from "../../../api/api";
import ButtonRm from "../../ButtonRm"
import { BankCard,BankCardItem } from "../../BankCard";
import { array } from "yup";


const Owners = (props) => {
	const [show, setShow] = useState(false)
	const [tabState, setTabState] = useState(0)
	const [ownersData, setOwnersData] = useState([])
	const [conductorsData, setConductorsData] = useState([])
	const [showConductorModal, setShowConductorModal] = useState(false);

	const [ownersBoosted, setOwnersBoosted] = useState([...props.formData.relationData])

	let proprietarioArray = ownersBoosted.filter(el => el.ownerType == "proprietario") 
	let usufruttuarioArray = ownersBoosted.filter(el => el.ownerType == "usufruttuario")
	let nudaProprietaArray = ownersBoosted.filter(el => el.ownerType == "nudo proprietario")


	//TROVA TIPO DI OWNER
	const ownerType = (id) => {
		if (tabState == 0) {
		return "proprietario";
		} else if (tabState == 1) {
		return "nudo proprietario";
		} else if (tabState == 2) {
		return "usufruttuario";
		}
	};
	

	const saveOwner = (id) => {
        let temp = [...props.formData.owners];
        temp.push(props.owners[id]);
        let ele = { target: { name: "owners", value: temp }};
		props.onChange(ele);

		setOwnersBoosted(prev => {
			return [...prev, {...props.owners[id], 
				parts: '',
				possessionDate: '',
				ownerType: ownerType(id), 
				procurator: 
				''}]
		})
    };

/*     console.log({ ownersList, bearPropertyList, usufruttoList})

    console.log(props.formData.owners) */

	const remove = (id) => {
		//IN BASE AL TAB STATE
		//se 0 rimuove owner
		//se 1 rimuovo nudap
		//se 2 rimuovo usufrutto

		let temp = [...props.formData.owners];
		temp = temp.filter(item => item.id != id)
		let ele = { target: { name: "owners", value: temp } };
		props.onChange(ele);

		let newArr = ownersBoosted.filter(el => el.id != id)
		setOwnersBoosted(newArr)
	};

	const addSelectedConductor = async (id) => {
		let temp = [...props.formData.conductors];
		temp.push(realConductors[id]);
		let ele = { target: { name: "conductors", value: temp } };
		props.onChange(ele);
	};

	const removeSelectedConductor = async (id) => {
		let temp = [...props.formData.conductors];
		temp.splice(id, 1);
		let ele = { target: { name: "conductors", value: temp } };
		props.onChange(ele);
	};


	//FETCH OWNERS E CONDUCTORS DATA FROM API

	const [realOwners, setRealOwners] = useState([]);
	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setRealOwners(response.data.content);
				console.log("owner", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [realConductors, setRealConductors] = useState([]);
	async function getConductors() {
		try {
			const response = await api().get(`${conductor_endpoint}`);
			if (response.data) {
				setRealConductors(response.data.content);
				console.log("conductor", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOwners();
		getConductors();
	}, []);



	console.log(ownersBoosted, props.formData.owners, props.formData.relationData )
	console.log(proprietarioArray)




	//CREA DIZIONARIO OWNE

/* 	const [ownersDictionary, setOwnersDictionary] = useState({...props.formData.relationData});


	props.formData.owners.forEach((owner) => {
		const { id, ...ownerData } = owner;
		ownersDictionary[id] = {
			...ownerData,
			parts: '',
			possessionDate: '',
			ownerType: ownerType(id), 
			procurator: ''
		};
	});

	console.log(props.formData.relationData, ownersDictionary)

	const proprietarioArray = Object.values(ownersDictionary).filter((owner) => owner.ownerType === 'proprietario');
	const nudaProprietaArray = Object.values(ownersDictionary).filter((owner) => owner.ownerType === 'nudo proprietario');
	const usufruttuarioArray = Object.values(ownersDictionary).filter((owner) => owner.ownerType === 'usufruttuario');
 */


	//HANDLE CAMPI CARD


	const handleChange = (event, id) => {
		const { name, value } = event.target;
	  
		let newArr = ownersBoosted.map(el => {
		  if (el.id === id) {
			return {
			  ...el,
			  [name]: value
			};
		  }
		  return el;
		});
		
		setOwnersBoosted(newArr);
	  };

	  useEffect(() => {
		let ele = { target: { name: "relationData", value: ownersBoosted } };
		props.onChange(ele)

	  }, [ownersBoosted])

	return (
		<>
			<div className="form-item-align-box-white d-block">
				<div>
					<div className={`tab-state `}>
						<div className="d-flex">
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(0);
								}}>
								<label className={tabState === 0 ? "" : "non-active"}>
									Proprietari
								</label>
								<div
									className={`tab-bar ${
										tabState === 0 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(1);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 1 ? "" : "non-active"}>
									Nuda proprietà
								</label>
								<div
									className={`tab-bar ${
										tabState === 1 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(2);
								}}
								style={{ marginLeft: "-10px" }}>
								<label className={tabState === 2 ? "" : "non-active"}>
									Usufruttuari
								</label>
								<div
									className={`tab-bar ${
										tabState === 2 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(3);
								}}
								style={{ marginLeft: "-10px" }}>
								<label className={tabState === 3 ? "" : "non-active"}>
										Conduttori
								</label>
								<div
									className={`tab-bar ${
										tabState === 3 ? "active-tab-bar-create" : ""
									}`}></div>
							</div>
						</div>
					</div>
					<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
						<div >
							{/* <label className="edit-subpage-title">PROPRIETARI</label> */}
							<div className="d-flex mt-3">
								<Link to="/proprietari/create?tab=proprietario">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShow(true);

									}}>Seleziona
								</ButtonRm>
							</div>
						</div>
								{proprietarioArray?.length > 0 ? (
						proprietarioArray.map((item, index) => (
							<EditableOwnerCard
								key={index}
								id={item.id}
								data={item}
								edit_mode={true}
								remove={remove}
								handleChange={handleChange}
								/>
							))
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun proprietario selezionato
								</span>
							</div>
						)}
					</div>
					<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>
						<div >
							{/* <label className="edit-subpage-title">NUDA PROPRIETA’</label> */}
								<div className="d-flex mt-3">
								<Link to="/proprietari/create?tab=nudaProprieta">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShow(true);

									}}>Seleziona
								</ButtonRm>
								</div>
						</div>
						{nudaProprietaArray?.length > 0 ? (
						nudaProprietaArray.map((item, index) => (
							<EditableOwnerCard
							key={index}
							id={item.id}
							data={item}
							edit_mode={true}
							remove={remove}
							handleChange={handleChange}
							/>
						  ))
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun nudo proprietario selezionato
								</span>
							</div>
						)}
					</div>
					<div className={`mt-3 ${tabState === 2 ? "" : "d-none"}`}>
						<div >

							<div className="d-flex mt-3">
								<Link to="/proprietari/create?tab=usufruttuario">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShow(true);

									}}>Seleziona
								</ButtonRm>
							</div>
						</div>
						{usufruttuarioArray?.length > 0 ? (
						usufruttuarioArray.map((item, index) => (
							<EditableOwnerCard
							key={index}
							id={item.id}
							data={item}
							edit_mode={true}
							remove={remove}
							handleChange={handleChange}
							/>
						  ))
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun usufruttuario selezionato
								</span>
							</div>
						)}
					</div>
					<div className={`mt-3 ${tabState === 3 ? "" : "d-none"}`}>
						<div >

							<div className="d-flex mt-3">
								<Link to="/conduttori/create">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShowConductorModal(true);
									}}>Seleziona
								</ButtonRm>
							</div>
						</div>
						{props.formData.conductors?.length > 0 ? (
							<div className="row mt-3 flex-column">
								{props.formData.conductors.map((conductor, index) => (
									<div className=" mb-3"> 
										<BankCard 
										key={conductor?.id}
										name={conductor?.name + " " + conductor?.surname}         
										color="#a47df7" 
										deleteFunc={removeSelectedConductor} 
										index={conductor?.id}>
										<BankCardItem property="INDIRIZZO" value={conductor?.residenceAddress + ", " + conductor?.residenceHouseNumber  }/>
										<BankCardItem property="CIVICO" value={conductor.residenceHouseNumber}/>
										<BankCardItem property="CITTA'" value={conductor?.residenceMunicipality}/>
										<BankCardItem property="TELEFONO" value={conductor?.cellularTelephone}/>
										{conductor?.fiscalCode && <BankCardItem property="CF" value={conductor?.fiscalCode}/>}
										<BankCardItem property="TIPO DI CONDUTTORE" value={conductor?.typeOfConductor}/>
									</BankCard>
									</div>
								))}
							</div>
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun conduttore selezionato
								</span>
							</div>
						)}
					</div>
					{/* <div className="d-flex mt-2 justify-content-end">
						<div
							className="edit-btn w-144"
							onClick={() => {
								setEditMode(true);
							}}>
							Modifica
						</div>
					</div> */}
				</div>
			</div>
			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<OwnerModal
						table_data={props.owners}
						setShow={setShow}
						saveOwner={saveOwner}
					/>
				</Modal.Body>
			</Modal>

			<div className="d-flex justify-content-end mt-5">						
				<button
					type="button"
					className="general-btn white-btn me-2 w-193"
					onClick={() => props.backward()}>
					Indietro
				</button>
				<button
					type="button"
					className="general-btn pink-btn w-193"
					onClick={() => props.forward()}>
					Avanti
				</button>
			</div>

			<Modal
				show={showConductorModal}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShowConductorModal(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<OwnerModal
						table_data={realConductors}
						setShow={setShowConductorModal}
						saveOwner={addSelectedConductor}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Owners;
