import React from "react";
import logo from "../../../assets/images/logo-red.svg";
import SuccessLogo from "../../../assets/images/success.png";

import "../../../assets/css/RegisterSuccess.css";
import Footer from "../../Footer";

import { useNavigate } from "react-router-dom";
import ButtonRm from "../../ButtonRm";

export default function RegisterSuccess() {

  const navigate  = useNavigate()

  const login  = () => {
    navigate("/");
  };

  return (
    <>
      <div className="register-div">
        <img loading="lazy" src={logo} alt="logo" className="register-logo"></img>
        <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
              width="100px"
              style={{ display: "flex", margin: "auto" }}
            ></img>
            <h3 className="mt-4">
              Benvenuto, la tua email è stata confermata
            </h3>
            <p className="opacity-75 mb-4" style={{ maxWidth: "600px" }}>
            {/*   Nelle prossime 24/48 ore feriali un nostro operatore verificherà
              la correttezza dei documenti caricati. Una volta confermati,
              potrai avvalerti del servizio di notarizzazione. */}
            </p>

            <ButtonRm variant="primary" onClick={login}>Vai al login</ButtonRm>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
