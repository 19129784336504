import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import AddTypeModal from "../Tipologia/AddTypeModal";
import SupplierModal from "../Tipologia/SupplierModal";
import SubjectCard from "../Tipologia/SubjectCard";
import { BsPlusCircleFill } from "react-icons/bs";
import { formatDateFromString } from "../../../../utility";
import { PAYMENT_METHODS, MOVEMENT_TYPES, TIPOLOGIA, CONTI } from "../../../../constants";
import api, { supplier_endpoint, stabili_endpoint, unit_endpoint } from "../../../../api/api";

import  {
  conductor_endpoint,
  subcount_endpoint,
  user_accounts_endpoint,
  bank_account_endpoint
} from "../../../../api/api";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ButtonRm from "../../../ButtonRm";
import CustomToggle from "../../../CustomToggle";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CustomBadge from "../../../CustomBadge";

function MovimentoE(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [subject_modal_show, setSupplierModalShow] = useState(false);
  const supplierModalClose = () => setSupplierModalShow(false);
  const supplierModalShow = () => setSupplierModalShow(true);
  const [display, setDisplay] = useState(false);
  const [suppliers, setSuppliers] = useState([]);

  const onChange = (e) => {
    const { name, value, type, checked } = e.target;
    type === "checkbox" ? console.log(checked) : console.log(value);
    props.setData((prevFormData) => {
      return {
        ...prevFormData,
        [name]:
          type === "checkbox"
            ? checked
            : name === "isEnteringAmount"
            ? !props.data.isEnteringAmount
            : value,
      };
    });

  };

  const getSuppliers = async () => {
    try {
      const res = await api().get(`${supplier_endpoint}`);
      if (res.data) {
        setSuppliers(res.data.content);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addSelectedSuppliers = async (id) => {
    let filteredList = props.getSelectedSuppliers.filter(
      (supplier) => supplier.id === id
    );
    filteredList.length === 0 &&
      props.setSelectedSuppliers([
        ...props.getSelectedSuppliers,
        suppliers.filter((supplier) => supplier.id === id)[0],
      ]);
  };

  const removeSelectedSupplier = async (id) => {
    props.setSelectedSuppliers(
      props.getSelectedSuppliers.filter((supplier) => supplier.id !== id)
    );
  };

  const getSupplierList = async () => {
    await getSuppliers();
    supplierModalShow();
  };

 

  //SHOW DIV TO SELECT OWNERS
  const [selectOwners, setSelectOwners] = useState(true);

  function handleOwnersDiv() {
    setSelectOwners(!selectOwners);
  }

  //GESTIONE PERCENTUALE E LISTA PROPRIETARI
  const [percentage, setPercentage] = useState(false);
  
  function handlePercentage(e) {
    setPercentage((prev) => !prev);
  }
  //Questa variabile contien dati relativi ai soggetti selezionati e percentuale di ripartizione
  const [percentageData, setPercentageData] = useState([]);

  const soggettiSelezionati = percentageData.filter((item) => item.selected);

  const percentageTotalArr = percentageData.map((item) =>
    parseInt(item.percentage)
  );

  const percentageTotal = percentageTotalArr.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  useEffect(() => {
    props.setData((prev) => {
      return {
        ...prev,
        owners: soggettiSelezionati.filter(
          (item) => item.role == "Proprietario"
        ),
      };
    });
  }, [percentageData]);

  function handlePercentageData(e, id, key) {
    const { value, checked, type } = e.target;
    const newArr = percentageData.map((item) => {
      return item.id == id
        ? { ...item, [key]: type === "checkbox" ? checked : value }
        : item;
    });

    setPercentageData(newArr);
  }

  //handle if tutti is selected
  function handleTuttiSelected(e) {
    const newArr = percentageData.map((item) => {
      return { ...item, ["selected"]: !item.selected };
    });

    setPercentageData(newArr);
  }

  useEffect(() => {
    if (unitSelected.owners?.length > 0) {
      const newArr = unitSelected.owners.map((item) => {
        return {
          id: item.id,
          name: item.name,
          surname: item.surname,
          role: "Proprietario",
          percentage: 0,
          selected: false,
        };
      });
      setPercentageData(newArr);
    }
  },[])


  //NEW

  const [units, setUnits] = useState([]);
  const [stabili, setStabili] = useState([]);

  //GET DATA OF THE UNIT SELECTED
  const [unitSelected, setUnitSelected] = useState({});

  const onChangeUnit = (e) => {
    units &&
      units.forEach((unit) => {
        if (unit.id === e.target.value) {
          props.setData({ ...props.data, unit: unit });
          return;
        }
      });
  };

  const onChangeStabili = (e) => {
    stabili &&
      stabili.forEach((stabile) => {
        const parsedValue = e.target.value ? JSON.parse(e.target.value) : ''
        if (stabile.id == parsedValue.id) {
          props.setData({ ...props.Data, condominium: [stabile] });
          return;
        }
      });
  };

  async function fetchUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data?.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchStabili() {
    try {
      const response = await api().get(`${stabili_endpoint}`);
      if (response.data) {
        setStabili(response.data?.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchUnits();
    fetchStabili();
  }, []);

  //RIPARTIZIONE SPESE PROPRIETARIO

  console.log(props.data)

  const partitionData = props.data?.partition? JSON.parse(props.data.partition) : []

  const ownersWithAmount = props.data.owners?.length > 0 && props.data.owners?.map((owner) => {
    const partition = partitionData.find((p) => p.id === owner.id);
    const percentage = partition ? partition.percentage : 0;
    const amount = (percentage / 100) * props.data.amount;
    
    return {
      id: owner.id,
      name: owner.name,
      surname: owner.surname,
      percentage: percentage,
      amount: amount,
    };
  });

  console.log(ownersWithAmount)


  /* CONTI */

  const [managerAccounts, setManagerAccounts] = useState([])
  
	//CONTI GESTORI
	async function getAccounts() {
	  try {
		const response = await api().get(`${user_accounts_endpoint}`);

    
		if (response.data) {
		  setManagerAccounts(response.data.content);

      console.log(response.data.content, "account_Resource")
		}
	  } catch (err) {
		console.log(err);
	  }
	}

  useEffect(() => {
		getAccounts()
	}, []);

  //Account membri
  const [accounts, setAccounts] = useState([])

  //Account proprietari selezionati
  const [ownerAccounts, setOwnerAccounts] = useState([])

  //Account 
  const [mixedAccounts, setMixedAccounts] = useState([])

  useEffect(() => {
    setMixedAccounts([...managerAccounts, ...ownerAccounts])
  }, [managerAccounts, ownerAccounts])

  

    //CONTI MEMBRI
    async function getBankAccounts() {
      try {
        const response = await api().get(`${bank_account_endpoint}`);
        if (response.data) {
      setAccounts(response.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    }

    useEffect(() => {
    getBankAccounts()
    }, []);

  useEffect(() => {
    let ids = props.data.unit?.owners?.map(owner => owner.id) // Utilizzo flatMap per ottenere un array piatto di ID degli owner

    // Calcolo degli account degli owner
    const contiCorrispondenti = accounts.filter(conto => {
      return conto.owners.some(owner => ids?.includes(owner.id));
    });
	  
		// Salva gli account degli owner nello stato ownerAccounts
		  setOwnerAccounts(contiCorrispondenti);
		}

	  , [props.data.unit]);


  return (
    <>
    <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="0" activeKey={props.activeKey}>Tipologia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="d-block">
              <div className="col-md-6">
                <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Tipologia
                    </label>
                    <div className="d-block">
                    <select
                        name="typology"
                        disabled={props.edit_mode ? false : true}
                        onChange={onChange}
                        value={props.data?.typology}
                    >
                        {TIPOLOGIA.map((menu, index) => {
                           return (
                            <option key={index} value={menu}>
                            {menu}
                            </option>
                                );
                              })}
                    </select>
                    </div>
                </div>
              </div>
            </div>	
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
        <Card.Header>
        <CustomToggle eventKey="1" activeKey={props.activeKey}>Unità e stabili</CustomToggle>
        </Card.Header>
        <Accordion.Body>
      <div className="row gy-4">
        {/*<div className="col-md-12">
          <div className="d-flex">
            <Form.Check
              inline
              label="Unità"
              type="radio"
              value="Unità"
              checked={props.data?.typeBuilding === "Unità"}
              onChange={onChange}
              name="typeBuilding"
              disabled={props.edit_mode ? false : true}
            />
            <Form.Check
              inline
              label="Stabile"
              type="radio"
              value="Stabile"
              checked={props.data?.typeBuilding === "Stabile"}
              onChange={onChange}
              name="typeBuilding"
              disabled={props.edit_mode ? false : true}
            />
          </div>
          </div>*/}
        {props.data?.unit?.length > 0 && (
          <>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Unità
                </label>
                <div className="d-block">
                  <select
                    name="unit"
                    onChange={onChangeUnit}
                    value={props.data.unit}
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                    disabled={true}
                  >
                    <option value="">Seleziona</option>
                    {units.map((item) => {
                      return (
                        <option key={item.id} value={item}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {props.data.owners?.length > 0 && 
                <div className="my-3">
                <p>Proprietari</p>
                    {props.data.owners?.length > 0 &&
                      props.data.owners.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <Form.Check
                              disabled={true}
                              default
                              className="mb-2 text-end"
                              label={`${item.name} ${item.surname} - Proprietario`}
                              name={`${item.id}`}
                              checked={true}
                              /*  onChange={(e) =>
                                handlePercentageData(e, item.id, "selected")
                              } */
                              type="checkbox"
                            />
                            {/* {percentage && item.selected && (
                              <input
                                type="number"
                                style={{ height: "30px", minWidth: "50px" }}
                                placeholder="inserisci la percentuale"
                                className="text-end percentage-input-pn"
                                value={item.percentage}
                                onChange={(e) =>
                                  handlePercentageData(e, item.id, "percentage")
                                }
                              />
                            )} */}
                          </div>
                        );
                      })}
                </div>}
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Proprietari e Conduttori
                </label>
                <ButtonRm
                  variant="outline"
                  onClick={handleOwnersDiv}
                  size="small"
                >
                  {selectOwners ? "Annulla Selezione" : "Seleziona"}
                </ButtonRm>
              </div>
            </div> */}
            { selectOwners && props.data.typeBuilding === "Unità" && (
              <>
                <hr className="my-3" />
                <Row>
                  <Col className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Proprietari e Conduttori
                  </label>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          default
                          className=" text-end"
                          label="Percentuale"
                          value={percentage}
                          onChange={handlePercentage}
                          type="checkbox"
                        />
                        <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={
                          <Tooltip id="tooltip" className="exclamation-tooltip">
                            Puoi definire la percentuale dell'importo ricevuta o pagata degli utenti selezionati. Di default se "Percentuale" non è flaggato la ripartizione avviene dividendo in parti uguali il 100% dell'importo, in base al numero di utenti selezionato.
                            Per esempio se hai selezionato 2 utenti, di default la percentuale di ciascuno sarà 50%
                          </Tooltip>
                        }>
                        <i class="bi  bi-info-circle ms-2 fs-14"></i>
                      </OverlayTrigger>
                      </div>
                    </div>
                    {/* PERCENTUALE DOVREBBE APPARIRE SOLO QUANDO FLAGGATA */}
                    {/* SE LE CHECKBOX FLAGGATA E' TUTTI, tutte le chechbox vengono selezionate */}
                    {
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          default
                          className="mb-2 text-end"
                          label="Tutti"
                          name="unitOwner"
                          value={false}
                          onChange={handleTuttiSelected}
                          type="checkbox"
                        />
                      </div>

                    }


                    {percentageData?.length > 0 &&
                      percentageData.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <Form.Check
                              default
                              className="mb-2 text-end"
                              label={`${item.name} ${item.surname} - ${item.role}`}
                              name={`${item.id}`}
                              checked={item.selected}
                              onChange={(e) =>
                                handlePercentageData(e, item.id, "selected")
                              }
                              type="checkbox"
                            />
                            {percentage && item.selected && (
                              <input
                                type="number"
                                style={{ height: "30px", minWidth: "50px" }}
                                placeholder="inserisci la percentuale"
                                className="text-end percentage-input-pn"
                                value={item.percentage}
                                onChange={(e) =>
                                  handlePercentageData(e, item.id, "percentage")
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    {
                      /* APPARE SOLO SE C'E' CAMPO PERCENTUALE FLAGGATO */
                      percentage && (
                        <div className="d-flex justify-content-between align-items-center">
                          <p>PERCENTUALE TOTALE</p>
                          <p
                            className="text-end fw-bold"
                            style={{
                              color: percentageTotal == 100 ? "green" : "red",
                            }}
                          >
                            {percentageTotal}/100%
                          </p>
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        {props.data?.condominium?.length > 0 && (
          <>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Stabile
                </label>
                <div className="d-block">
                  <select
                    name="condominium"
                    onChange={onChangeStabili}
                    value={props.data.condominium[0]}
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                    disabled={props.edit_mode ? false : true}

                  >
                    <option value="">Seleziona</option>
                    {stabili.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Proprietari (Condomini)
                </label>
                <ButtonRm
                  variant="outline"
                  onClick={handleOwnersDiv}
                  size="small"
                >
                  {selectOwners ? "Annulla Selezione" : "Seleziona"}
                </ButtonRm>
              </div>
            </div> */}
            {props.data.owners?.length > 0 && 
                <div className="my-3">
                <p>Proprietari</p>
                    {props.data.owners?.length > 0 &&
                      props.data.owners.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <Form.Check
                              default
                              className="mb-2 text-end"
                              label={`${item.name} ${item.surname} - Proprietario`}
                              name={`${item.id}`}
                              checked={true}
                             /*  onChange={(e) =>
                                handlePercentageData(e, item.id, "selected")
                              } */
                              type="checkbox"
                            />
                            {/* {percentage && item.selected && (
                              <input
                                type="number"
                                style={{ height: "30px", minWidth: "50px" }}
                                placeholder="inserisci la percentuale"
                                className="text-end percentage-input-pn"
                                value={item.percentage}
                                onChange={(e) =>
                                  handlePercentageData(e, item.id, "percentage")
                                }
                              />
                            )} */}
                          </div>
                        );
                      })}
                </div>}
          </>
        )}
        
        </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="2" activeKey={props.activeKey}>Movimento</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="d-flex">
        <div className="col-md-6">
          <div className="d-flex flex-column ">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Conto
              <i class="bi bi-info-circle ms-2"></i>
            </label>
            <div className="d-block">
              <select
                name="account"
                onChange={onChange}
                disabled={props.edit_mode ? false : true}
                value={
                  props.data?.account
                }
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
              >
                <option value="Spese Generali">Spese Generali</option>
                <option value="Consumi">Consumi</option>
                <option value="Manutenzioni">Manutenzioni</option>
                <option value="Pompe acque meteoriche">
                  Pompe acque meteoriche
                </option>
              </select>
            </div>
          </div>
        </div>
        <Col md={6} className="d-flex align-items-start">
								<Form.Group className="me-2 mb-3" style={{width:`${props.edit_mode? "90%" : "100%"}`}}>
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Sottoconto
									</Form.Label>
									<Form.Select 
										name="subAccount"
                    disabled={props.edit_mode ? false : true}
                    onChange={onChange}
                    value={
                      props.data?.subAccount
                    }
									>
										{MOVEMENT_TYPES.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
									</Form.Select>
								</Form.Group>
								{props.edit_mode && <BsPlusCircleFill
									className="col-sm-2 col-md-1 fs-30 plus-button"
									style={{marginTop:"2rem"}}
									onClick={handleShow}
								/>}
				</Col>
      </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 ">
              Descrizione dell’operazione
            </label>
            <input
              type="text"
                className="border-secondary rounded-3 border-opacity-50 col-sm-10 col-md-1"
              name="operationDescription"
              value={props.data?.operationDescription}
              id=""
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="3" activeKey={props.activeKey}>Date</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Competenza
            </label>
            <div className="d-flex align-items-center justify-content-between border border-secondary rounded-3 border-opacity-50">
              <input
                name="operationDate"
                className="border-0 fs-16 text-dark flex-grow-1"
                max="9999-12-31"
                type="date"
                value={
                  props.data?.operationDate && formatDateFromString(props.data?.operationDate)
              }
                onChange={onChange}
                disabled={props.edit_mode ? false : true}
              ></input>
            </div>
          </div>
        </div>
       {/*  <div className="col-md-6">
          {!props.data?.paid && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Scadenza
              </label>
              <div className="d-flex align-items-center justify-content-between border border-secondary  rounded-3 border-opacity-50">
                <input
                  name="endDate"
                  className="border-0 fs-16 text-dark flex-grow-1"
                  max="9999-12-31"
                  type="date"
                  value={
                    props.data?.endDate && formatDateFromString(props.data?.endDate)
                }
                  onChange={onChange}
                  disabled={props.edit_mode ? false : true}
                ></input>
              </div>
            </div>
          )}
        </div> */}
       {/*  <div className="col-md-6">
          <div className="d-flex flex-column">
            <Form.Check
              default
              className="mb-2 col-md-3"
              label="Pagato"
              name="paid"
              checked={props.data?.paid}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
              type="checkbox"
            />
          </div>
        </div>
        <div className="col-md-6">
          {props.data?.paid && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Data del pagamento **
              </label>
              <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
                <input
                  name="paymentDay"
                  className="border-0 fs-16 text-dark flex-grow-1"
                  max="9999-12-31"
                  type="date"
                  //onChange={onChange}
                  //value={props.data?.paymentDay}
                  disabled={props.edit_mode ? false : true}
                ></input>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <Form.Check
              default
              className="mb-2 col-md-3"
              label="Fattura"
              name="billed"
              type="checkbox"
              value={props.data?.billed}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
        <div className="col-md-6">
          {props.data?.billed && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Data della fattura
              </label>
              <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
                <input
                  name="billedDate"
                  className="border-0 fs-16 text-dark flex-grow-1"
                  max="9999-12-31"
                  type="date"
                  value={
                    props.data?.billedDate && formatDateFromString(props.data?.billedDate)
                }
                  onChange={onChange}
                  disabled={props.edit_mode ? false : true}
                ></input>
              </div>
            </div>
          )}
        </div> */}
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="4" activeKey={props.activeKey}>Importo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="d-block col-md-12">
        <div className="mb-3 d-flex">
          <Form.Check
            inline
            label="Importo in entrata"
            type="radio"
            checked={props.data?.isEnteringAmount}
            onChange={onChange}
            disabled={props.edit_mode ? false : true}
            name="isEnteringAmount"
          />
          <Form.Check
            inline
            label="Importo in uscita"
            type="radio"
            checked={!props.data?.isEnteringAmount}
            value={props.data?.isEnteringAmount}
            onChange={onChange}
            disabled={props.edit_mode ? false : true}
            name="isEnteringAmount"
          />
        </div>
      </div>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Imponibile
            </label>
            <input
              name="taxable"
              type="text"
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
              value={props.data?.taxable}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Codice IVA
            </label>
            <select
              name="vatCode"
              value={props.data?.vatCode}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
            >
              <option value={1}> Esente </option>
              <option value={1.04}> 4% </option>
              <option value={1.05}> 5% </option>
              <option value={1.1}> 10% </option>
              <option value={1.2}> 20% </option>
              <option value={1.21}> 21% </option>
              <option value={1.22}> 22% </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Metodo di pagamento
            </label>
            <select
              name="paymentMethod"
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.data?.taxable}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            >
              {PAYMENT_METHODS.map((menu, index) => {
                return (
                  <option key={index} value={menu}>
                    {menu}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
       {!props.data.isEnteringAmount && <div className="col-md-6 ">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Da
            </label>
            <select
              name="fromAccount"
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.data?.fromAccount}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            >
                            <option></option>

              {mixedAccounts.length> 0 && mixedAccounts.map((conto) => {
                return <option value={conto.name}> {conto.name}</option>;
              })}
            </select>
          </div>
        </div>}
        {props.data.isEnteringAmount &&
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              A
            </label>
            <select
              name="toAccount"
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
              value={props.data?.toAccount}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            >
              <option></option>
            {mixedAccounts.length> 0 && mixedAccounts.map((conto) => {
                return <option value={conto.name}> {conto.name}</option>;
              })}
            </select>
          </div>
        </div>
        }
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <Form.Check
              default
              className="mb-2 col-md-6"
              label="Aggiungi ritenuta"
              name="addWithholdingTax"
              type="checkbox"
              checked={props.data?.addWithholdingTax}
              onChange={onChange}
              disabled={props.edit_mode ? false : true}
            />
          </div>
        </div>
        <div className="col-md-6">
          {props.data?.withholding && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                % di ritenuta
              </label>
              <select
                name="withholdingTax"
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                value={props.data?.withholdingTax}
                onChange={onChange}
                disabled={props.edit_mode ? false : true}
              >
                <option>4%</option>
                <option>20%</option>
                <option>22%</option>
              </select>
            </div>
          )}
        </div>
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="5" activeKey={props.activeKey}>Fornitori</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      {props.edit_mode && <div className="col-12">
        <div className="d-flex align-items-center form-last-btns">
          <ButtonRm variant="outline" className="me-2">Nuovo</ButtonRm>
          <ButtonRm variant="outline" onClick={getSupplierList}>Seleziona</ButtonRm>
        </div>
      </div>}

      {props.getSelectedSuppliers &&
        props.getSelectedSuppliers.length > 0 &&
        props.getSelectedSuppliers.map((supplier) => (
          <SubjectCard
            key={supplier.id}
            title={
              supplier.name +
              " " +
              supplier.surname +
              ": " +
              supplier.businessName
            }
            id={supplier.id}
            table_data={[
              supplier.category,
              supplier.subjectType,
              supplier.cellularTelephone,
            ]}
            className="mt-4"
            remove={removeSelectedSupplier}
          />
      ))}
  
      </Accordion.Body>
    </Accordion.Item>
  

      <Modal
        show={show}
        className={`add-section-modal ${display ? "d-none" : "small-modal"}`}
        onHide={handleClose}
        backdrop="static"
        
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            onClick={handleClose}
            className="float-end border-0 bg-white"
          >
            <i className="bi bi-x"></i>
          </Button>
          <AddTypeModal setDisplay={setDisplay} />
        </Modal.Body>
      </Modal>
      {/* ///////---------- Select Subject Modal  ---------------///// */}
      <Modal
        show={subject_modal_show}
        className="type-modal"
        onHide={supplierModalClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            onClick={supplierModalClose}
            className="float-end border-0 bg-white"
          >
            <i className="bi bi-x"></i>
          </Button>
          <SupplierModal
            table_data={
              suppliers.length > 0 &&
              suppliers.map((supplier) => [
                supplier.category,
                supplier.businessName,
                supplier.cellularTelephone,
                supplier.subjectType,
              ])
            }
            ids={suppliers.map((supplier) => supplier.id)}
            setShow={setSupplierModalShow}
            addSupplier={addSelectedSuppliers}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default MovimentoE;
