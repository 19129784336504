import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import NumericInput from "react-numeric-input";
import { Link, useNavigate } from "react-router-dom";
import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../ButtonRm";
import { Modal } from "react-bootstrap";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";

import { useDispatch } from "react-redux";
import { addDeadline } from "../../../actions/deadline";

import api, { stabili_endpoint, unit_endpoint, first_note_endpoint, rate_endpoint, rate_unit_assignment_endpoint,
	rate_condominium_assignment_endpoint,
	rate_note_assignment_endpoint,
	rate_supplier_assignment_endpoint,
	rate_conductor_assignment_endpoint,
	rate_owner_assignment_endpoint,} from "../../../api/api"

import TopHeading from "../../TopHeading";

import {OverlayTrigger} from "react-bootstrap";
import {Tooltip} from "react-bootstrap";

import { add, addDays,addMonths, addWeeks, addYears, isAfter } from 'date-fns';
import { parseISO } from 'date-fns';

import CustomBadge from "../../CustomBadge";


const schema = yup.object().shape({
	rentType: yup.string().required("Seelziona il tipo di locazione"),
	rentPercentage: yup.number("Inserisci un numero").max(100, "Il valore massimo è 100%"),
	name:yup.string().required("Inserisci un indentificativo univoco"),
	
});

const form = {
    type: "ReceivePayment", //STRING

    subjectType:"", //STRING
    subject: [], //OBJ

    typeBuilding : "Unità", //STRING
    unit: [], //OBJ
    condominium: [], //OBJ

    competenceStartDate: "", //DATE
    competenceEndDate: "", //DATE
    deadlineDate: "", //DATE

    vatCode:1, //NUMBER
    amount: null, //NUMBER
    totalDue: 0, //NUMBER 
    totalPaid: null, //NUMBER

    recurrenceType:"",
    every:"",
    toDate:"",

    installmentName:"",

    attachment: [], 
    note: "", //STRING
    relatedToPrimaNota: false,
    primaNotaRelated: [], //tutta la prima nota se possibile, non solo ID

    paid: false, //per capire lo stato della rata
}


function Profiling(props) {

    const navigate = useNavigate()

	function myFormat(num) {
		return num + "%";
	}
	function myCurrencyFormat(num) {
		return num + "€";
	}
	const Metodo_di_pagamento = [
		"Accredito su conto corrente",
		"addebito su conto corrente",
		"assegno",
		"bancomat",
		"bollettino freccia",
		"bollettino postale",
		"bonifico",
		"carta di credito",
		"cessione del credito d’imposta",
		"compensazione finanziaria",
		"contanti",
		"giroconto",
		"Mav",
		"paypal",
		"rav",
		"ri.ba",
		"rid",
		"sdd ( sepa direct debit)",
		"vaglia cambiario",
		"vaglia postale",
	];

	const rentType = ["Attiva", "Passiva", "Sublocazione"];

    //gestione allegati

    const [file, setFile] = useState([]);
    const setFileName = (index, new_name) => {
      let temp = file;
      temp[index].name = new_name;
      setFile(temp);
    };
    const deleteFile = (index) => {
      let temp = [...file];
      temp.splice(index, 1);
      setFile(temp);
    };
    const handleChange = (e) => {
      let temp = [...file];
      setFile([...temp, ...e.target.files]);
    };


	const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});


        //GESTIONE DATI FORM

        const [formData, setFormData] = useState(form)

        const onChange = (e) => {
            const { name, value, type, checked } = e.target;
            console.log(typeof value)

            let parsedValue = (name == "unit" && value || 
                                name == "condominium" && value ||
                                name == "subject" && value ||
                                name == "primaNotaRelated" && value) ? 
                                [JSON.parse(value)] : value     
            
            parsedValue = ( name == "vatCode") ? Number(value) : parsedValue
                                
            setFormData((prevFormData) => {
                return {
                ...prevFormData,
                [name]:
                    type === "checkbox"
                    ? checked
                    : parsedValue,
                };
            });
        };
        
        //SE UNITA SELEZIONATA RIMUOVI CONDOMINIO, E VICEVERSA
        useEffect(() => {
            if(formData.typeBuilding == "Unità"){
                setFormData(prev => {
                    return {
                        ...prev,
                        condominium: []
                    }
                })
            } 

            if(formData.typeBuilding == "Stabile"){
                setFormData(prev => {
                    console.log("STABILE")
                    return {
                        ...prev,
                        unit: []
                    }
                })
            } 

        }, [formData.typeBuilding])

        //AUTO UPDATE TOTAL DUE IF AMOUNT OR TOTALPAID CHANGE
        const totalDue = (formData.amount * (formData.vatCode ?formData.vatCode : 1 )) - formData.totalPaid

        useEffect(() => {
            setFormData(prev => {
                return {
                    ...prev,
                    totalDue
                }
            })
        }, [formData.amount, formData.totalPaid, formData.vatCode])


         //GET STABILI E UNITA'

        const [units, setUnits] = useState([]);
        const [stabili, setStabili] = useState([]);

        async function fetchUnits() {
            try {
            const response = await api().get(`${unit_endpoint}`);
            if (response.data) {
                setUnits(response.data?.content);
            }
            } catch (error) {
            console.log(error);
            }
        }

        async function fetchStabili() {
            try {
            const response = await api().get(`${stabili_endpoint}`);
            if (response.data) {
                setStabili(response.data?.content);
            }
            } catch (error) {
            console.log(error);
            }
        }

        useEffect(() => {
            fetchUnits();
            fetchStabili();
        }, []);

        //GET SUBJECTS 

        const [subjects, setSubjects] = useState([])

        async function fetchSubjects(type) {
            if(type){ 
                try {
                    const response = await api().get(`/api/v1/${type}/`);
                    if (response.data) {
                        setSubjects(response.data?.content);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }

        useEffect(() => {
            if(formData.subjectType && formData.subjectType != "other"){
                fetchSubjects(formData.subjectType)
            }
        }, [formData.subjectType])



        //GET SUBJECTS 

        const [primeNote, setPrimeNote] = useState([])

        async function fetchPrimeNote() {
                try {
                    const response = await api().get(`${first_note_endpoint}`);
                    if (response.data) {
                        setPrimeNote(response.data?.content);
                    }
                } catch (error) {
                    console.log(error);
                }
            
        }

        useEffect(() => {
            fetchPrimeNote()
        }, [])


        //RICORRENZA
    function createRecurringEvent(title, startDate, endDate, frequency, frequencyUnit, endRecurrenceDate, deadlineDate) {
        const recurrenceIntervals = {
            "giorno/i": addDays,
            "settimana/e": addWeeks,
            "mese/i": addMonths,
            "anno/i": addYears,
        };
        const recurrenceInterval = recurrenceIntervals[frequencyUnit];
        
        const events = [];
        let nextStartDate = recurrenceInterval(parseISO(startDate), frequency);
        let nextEndDate = recurrenceInterval(parseISO(endDate), frequency);
        let nextDeadlineDate = recurrenceInterval(parseISO(deadlineDate), frequency);

        
        let recurrenceEndDate = parseISO(endRecurrenceDate);
    
        while (isAfter(recurrenceEndDate, nextStartDate)) {
            events.push({ title, startDate: nextStartDate, endDate: nextEndDate, deadlineDate: nextDeadlineDate });
    
            nextStartDate = recurrenceInterval(nextStartDate, frequency);
            nextEndDate = recurrenceInterval(nextEndDate, frequency);
    
            if (isAfter(nextEndDate, endRecurrenceDate)) {
            nextEndDate = endRecurrenceDate;
            recurrenceEndDate = nextStartDate;
            }
        }
    
        return events;
    }


//CREAZIONE SCADENZA RELATIVA A QUESTA RATA - da vincolare in base alle impostazioni definite in notifiche
 const dispatch = useDispatch();

function postItem(data) {
  console.log(data)
  dispatch(addDeadline(data))
}

function createDeadlineForThisRata(el){
  if(el.competenceStartDate){
    const dataForEndFirstPeriodDate = {
      title:`Rata - ${el.installmentName}`,
      section:"Rate",
      startDate: el.competenceStartDate,
      endDate: el.competenceStartDate,
      note:''
    }
    postItem(dataForEndFirstPeriodDate)
  }
}
  

    //CREATE RATA 
    async function postRata(data){
        //CREO COPIA DI DATA
        let bodyToSend = {...data}
        /* owners: data.subjectType == "owner" ? data.subject: [],
        conductors: data.subjectType == "conductor" ? data.subject: [],
        suppliers: data.subjectType == "supplier" ? data.subject: [], */
        
        //RIMUOVO SUBJECTS
        delete  bodyToSend.subject;
        //RIMUOVO ELEMENTI DA ASSEGNARE CON UN DIVERSO ENDPOINT
        delete  bodyToSend.unit;
        delete  bodyToSend.condominium;
        delete  bodyToSend.primaNotaRelated
        delete  bodyToSend.attachment

        //CONVERTO IN NUMBER TOTAL PAID E AMOUNT
        bodyToSend.totalPaid = bodyToSend.totalPaid? Number(bodyToSend.totalPaid) : 0
        bodyToSend.amount = Number(bodyToSend.amount)


        try {    

            //gestione allegati

            let formDataToSendAttachment = new FormData();

            file.forEach((file) => {
                const blob = new Blob([file]);
                formDataToSendAttachment.append("parts", blob, file.name);
                //formDataToSend.append("parts", file, file.name);
            });
            
            let sentUploadAttachment = formDataToSendAttachment.get("parts") !== null;

            const response = await api().post(`${rate_endpoint}`, bodyToSend);
            const installmentID = response?.data.id;

            	//INVIA ALLEGATI
				if (response) {
					if (sentUploadAttachment) {
					await api().put(`${rate_endpoint}upload/${installmentID }`, formDataToSendAttachment);
					}

                    createDeadlineForThisRata(data) 
				}

             //assign owner
            if (data.subjectType === "owner" && installmentID ) {
                const ownerID = data.subject?.[0]?.id; 
                await api().post(`${rate_owner_assignment_endpoint}`, {ownerID: ownerID, installmentID: installmentID});    
            }   

            //assign conductor
            if (data.subjectType === "conductor" && installmentID ) {
                const conductorID = data.subject?.[0]?.id; 
                await api().post(`${rate_conductor_assignment_endpoint}`, {conductorID: conductorID,installmentID: installmentID});    
            } 

            //assign supplier
            if (data.subjectType === "supplier" && installmentID ) {
                const supplierID = data.subject?.[0]?.id; 
                await api().post(`${rate_supplier_assignment_endpoint}`, {supplierID: supplierID, installmentID: installmentID});    
            } 

            //assign unit
            if (data.typeBuilding === "Unità" && installmentID ) {
                const unitID = data.unit?.[0]?.id; 
                await api().post(`${rate_unit_assignment_endpoint}`, {unitID: unitID, installmentID: installmentID});    
            } 

            //assign condominium
            if (data.typeBuilding === "Stabile" && installmentID ) {
                const condominiumID = data.condominium?.[0]?.id; 
                await api().post(`${rate_condominium_assignment_endpoint}`, {condominiumID: condominiumID, installmentID: installmentID});    
            }
            
            //assign prima nota
            if (data.primaNotaRelated.length > 0 && installmentID ) {
                const noteID = data.primaNotaRelated?.[0]?.id; 
                await api().post(`${rate_note_assignment_endpoint}`, {noteID: noteID, installmentID: installmentID});    
            } 
            
            navigate("/rate")

        } catch (error) {
            console.log(error);
        }
    }

    //HANDLE CREATION
    const handleCreation = () => {
        const { competenceStartDate, competenceEndDate, deadlineDate } = formData;

        if (
            competenceStartDate <= competenceEndDate &&
            competenceEndDate <= deadlineDate &&
            deadlineDate >= competenceStartDate
        ) {
            // Le date sono valide, esegui la chiamata POST
            postRata(formData);

            //HANDLE RICORRENZA
            if(formData.every && formData.recurrenceType && formData.toDate){
                let dateArray = createRecurringEvent(formData.installmentName, formData.competenceStartDate, formData.competenceEndDate, formData.every, formData.recurrenceType, formData.toDate, formData.deadlineDate)

                dateArray.forEach((date, index) => {
                    const dataObj = {
                        installmentName: `${date.title} ${index + 1}`,
                        type: "ReceivePayment", //STRING

                        subjectType: formData.subjectType, //STRING
                        subject: formData.subject, //OBJ

                        typeBuilding : formData.typeBuilding, //STRING
                        unit: formData.unit, //OBJ
                        condominium: formData.condominium, //OBJ

                        competenceStartDate:  new Date(date.startDate).toISOString().slice(0,10), //DATE
                        competenceEndDate:  new Date(date.endDate).toISOString().slice(0,10), //DATE
                        deadlineDate:  new Date(date.deadlineDate).toISOString().slice(0,10), //DATE

                        vatCode: formData.vatCode, //NUMBER
                        amount: formData.amount, //NUMBER
                        totalDue: (formData.amount * (formData.vatCode ?formData.vatCode : 1 )) - formData.totalPaid, //NUMBER 
                        totalPaid: 0, //NUMBER

                    }
                    postRata(dataObj)
                }) 

            } 
        } else {
            // Le date non sono valide, mostra un avviso o esegui altre azioni necessarie
            console.log("Le date non sono valide");
        }

        
	};

    //PRENDI UNITA E CONDOMINI DEL SUBJECT

    const subjectId = formData.subject?.[0]?.id

    const unitsFiltered = formData.subjectType == "owner" ? 
        units.filter(unit => {
            return unit.owners.some(owner => owner.id === subjectId )
        }) :
        units.filter(unit => {
            return unit.conductors.some(conductor => conductor.id === subjectId )
        });

    console.log(unitsFiltered, formData.subjectType, units, subjectId)

    const condominiumFiltered = formData.subjectType == "owner" ? 
        stabili.filter(condo => {
        return condo.units.some(unit => unit.owners.some(owner => owner.id === subjectId));
        }) :
        stabili.filter(condo => {
        return condo.units.some(unit => unit.conductors.some(conductor => conductor.id === subjectId));
    })

    console.log((formData?.competenceStartDate < formData?.competenceEndDate) , (formData?.competenceStartDate < formData?.deadlineDate))
    console.log(formData)


    //CHECK RATE DATA
    function areDataWrong(){
        console.log("running funcion")
        if(  (formData?.competenceEndDate && formData?.competenceStartDate ) || ( formData?.competenceEndDate && formData?.competenceStartDate && formData?.deadlineDate)){
            const start = Date.parse(formData?.competenceStartDate);
            const end = Date.parse( formData?.competenceEndDate);
            const deadline = formData?.deadlineDate ?  Date.parse(formData?.deadlineDate) : null

            if (start && end && deadline) {
                if((start < end) && (start < deadline)){
                    console.log("1.START E' MINORE DI END E DEADLINE")
                    return false
                } else {
                    console.log("1.START E' MAGGIORE DI END E/O DEADLINE")

                    return true
                }
            } else if (start && end){
                if(start < end){
                    console.log("2.START E' MINORE DI END")

                    return false
                } else {
                    console.log("2.START E' MAGGIORE DI END")

                    return true
                }
            }
        }
    }

	return (
        <>
         <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="" 
          />
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
        <Col >
        <h2 className="fw-bold text-start">Nuova Rata</h2>
        </Col>
        
        </Row>
        </div>
        <div className="dashboard-container">
            <Form >
                <div className="form-item-align-box d-block">
                    <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
                    <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="5" activeKey={activeKey}>Identificativo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="my-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 mb-3 d-flex align-items-center
">
                                                Identificativo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                    name="installmentName"
                                                    type="text"
                                                    value={formData?.installmentName}
                                                    onChange={onChange}
                                                    //disabled={props.edit_mode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="0" activeKey={activeKey}>Tipologia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="my-2" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex">
                                                <Form.Check
                                                inline
                                                label="Devo ricevere un pagamento"
                                                type="radio"
                                                value="ReceivePayment"
                                                checked={formData?.type === "ReceivePayment"}
                                                onChange={onChange}
                                                name="type"
                                                //disabled={props.edit_mode ? false : true}
                                                />
                                               {/*  <Form.Check
                                                inline
                                                label="Devo fare un pagamento"
                                                type="radio"
                                                value="MakePayment"
                                                checked={formData?.type === "MakePayment"}
                                                onChange={onChange}
                                                name="type"
                                                //disabled={props.edit_mode ? false : true}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </Row>	
                                <Row className="my-3" >
                                <div className="col-md-6">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                            {formData.type == "MakePayment" ? "A chi devi fare il pagamento?" : "Da chi devi ricevere il pagamento?"} <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                        </label>
                                        <div className="d-block">
                                        <select
                                            name="subjectType"
                                            onChange={onChange}
                                            value={formData.subjectType}
                                            
                                            //disabled={props.edit_mode ? false : true}
                                        >
                                            <option value="">Seleziona</option>
                                            <option value="owner">Proprietario</option>
                                            <option value="conductor">Conduttore</option>
                                            <option value="supplier">Fornitore</option>
                                            <option value="other">Altro</option>
            
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                {(formData.subjectType == "supplier" ||
                                formData.subjectType == "conductor" ||
                                formData.subjectType == "owner" ) 
                                    && 
                                        <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Nominativo <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="subject"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.subject?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {subjects?.length > 0 &&
                                                        subjects?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.surname} {item.name} 
                                                            {formData.subjectType == "supplier" && item.category? ` - ${item.category}`: ""}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                                </div>
                                        }		
                                </Row>						
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="1" activeKey={activeKey}>Immobile di riferimento</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <div className="d-flex">
                                                <Form.Check
                                                inline
                                                label="Unità"
                                                type="radio"
                                                value="Unità"
                                                checked={formData?.typeBuilding === "Unità"}
                                                onChange={onChange}
                                                name="typeBuilding"
                                                //disabled={props.edit_mode ? false : true}
                                                />
                                                <Form.Check
                                                inline
                                                label="Stabile"
                                                type="radio"
                                                value="Stabile"
                                                checked={formData?.typeBuilding === "Stabile"}
                                                onChange={onChange}
                                                name="typeBuilding"
                                                //disabled={props.edit_mode ? false : true}
                                                />
                                            </div>
                                        </div>
                                        {formData.typeBuilding == "Unità" && <>
                                        <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Unità
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="unit"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.unit?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {(formData.subjectType == "supplier" || formData.subjectType == "other") && units.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                        {(formData.subjectType == "owner" || formData.subjectType == "conductor") && unitsFiltered.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}

                                                    </select>
                                                    </div>
                                                </div>
                                                </div>
                                        </>}
                                        {formData.typeBuilding == "Stabile" && <>
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Stabile
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="condominium"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.condominium?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {(formData.subjectType == "supplier" || formData.subjectType == "other") && stabili.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                        {(formData.subjectType == "owner" || formData.subjectType == "conductor") && condominiumFiltered.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.name}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="2" activeKey={activeKey}>Date <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Inizio competenza <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="competenceStartDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.competenceStartDate}
                                                        onChange={onChange}
                                                        //disabled={props.edit_mode ? false : true}
                                                        ></input>
                                                    </div>
                                                        {
                                                            (areDataWrong()  && 
                                                            <small className="text-danger"><i class="bi bi-exclamation-circle me-2"></i>La data di Inizio Competenza non può essere successiva alla data di Fine Competenza e Scadenza</small>
                                                            )
                                                        }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Fine competenza <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="competenceEndDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.competenceEndDate}
                                                        onChange={onChange}
                                                        //value={props.data?.endDate}
                                                        //onChange={onChange}
                                                        //disabled={props.edit_mode ? false : true}
                                                        ></input>
                                                    </div>
                                                </div>
                                                </div>

                                            <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Scadenza <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="deadlineDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.deadlineDate}
                                                        onChange={onChange}
                                                        //value={props.data?.endDate}
                                                        //onChange={onChange}
                                                        //disabled={props.edit_mode ? false : true}
                                                        ></input>
                                                    </div>
                                                </div>
                                                </div>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="3" activeKey={activeKey}>Importo<span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Imponibile (€) <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                    name="amount"
                                                    type="number"
                                                    value={formData?.amount}
                                                    onChange={onChange}
                                                    //disabled={props.edit_mode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Codice IVA <span className="mandatory-field"> *</span> <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                                </label>
                                                <select
                                                    name="vatCode"
                                                    onChange={onChange}
                                                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                                                >
                                                <option value={1}> Esente </option>
                                                <option value={1.04}> 4% </option>
                                                <option value={1.05}> 5% </option>
                                                <option value={1.1}> 10% </option>
                                                <option value={1.2}> 20% </option>
                                                <option value={1.21}> 21% </option>
                                                <option value={1.22}> 22% </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Totale versato (€) 
                                                    </label>
                                                    <div className="d-block">
                                                    <input
                                                        name="totalPaid"
                                                        type="number"
                                                        value={formData?.totalPaid}
                                                        onChange={onChange}
                                                        max={formData?.amount}
                                                        //disabled={props.edit_mode ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Totale dovuto
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                        name="totalDue"
                                                        type="number"
                                                        value={formData?.totalDue}
                                                        disabled

                                                        //onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="4" activeKey={activeKey}>Ricorrenza
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 600, hide: 300 }}
                                overlay={
                                <Tooltip className='tooltipContabilita'><p>Si tratta di un pagamento ricorrente? Anziché creare più volte lo stesso pagamento, puoi impostare di seguito i dettagli di ricorrenza di questo. <br/> Se ad esempio si tratta di un pagamento che devi ricevere ogni  mese fino al 31/12/2023, dovrai selezionare: <br/>
                                Frequenza:  "Mesi", <br/>
                                Ogni: 1, <br/>
                                Fine Ricorrenza: "31/12/2023"
                                </p></Tooltip>
                                }
                                >
                                <i class="bi bi-info-circle ms-2"></i>
                                </OverlayTrigger></CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-12">
                                            <p className="opacity-75">Se il pagamento che stai impostando è un pagamento ricorrente, come ad esempio un affitto, puoi impostare le specifiche per automatizzarlo.</p>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Frequenza
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="recurrenceType"
                                                        value={formData.recurrenceType}
                                                        onChange={onChange}
                                                    >
                                                        <option value=""></option>
                                                        <option value="giorno/i">Giorno/i</option>
                                                        <option value="settimana/e">Settimana/e</option>
                                                        <option value="mese/i">Mese/i</option>
                                                        <option value="anni/i">Anno/i</option>
                                                    </select>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Ogni
                                                </label>
                                                <div className="d-block">
                                                    <input
                                                    type="number" 
                                                    placeholder="Inserisci un numero" 
                                                    name="every" 
                                                    value={formData.every} 
                                                    onChange={onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Fine ricorrenza
                                                    </label>
                                                    <div className="d-block">
                                                        <input
                                                        name="toDate"
                                                        max="9999-12-31"
                                                        type="date"
                                                        value={formData?.toDate}
                                                        onChange={onChange}
                                                        //disabled={props.edit_mode ? false : true}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>

                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                  

                        <Accordion.Item eventKey="6" className="mb-2 accordionHeader">
                            <Card.Header>
                            <CustomToggle eventKey="6" activeKey={activeKey}>Altro</CustomToggle>
                            </Card.Header>
                            <Accordion.Body>
                                <Row className="mb-3" >
                                    <div className="row">
                                        <>
                                        <div className="col-md-12">
                                            <div className="d-flex flex-column">
                                                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                Note
                                                </label>
                                                <div className="d-block">
                                                    <textarea
                                                    name="note"
                                                    className="border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
                                                    value={formData?.endDate}
                                                    onChange={onChange}
                                                    //disabled={props.edit_mode ? false : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                       {/*  <div className="col-md-12 mt-3">
                                            <div className="d-flex">
                                                <Form.Check
                                                inline
                                                label="Imputabile in prima nota"
                                                type="checkbox"
                                                checked={formData?.relatedToPrimaNota}
                                                onChange={onChange}
                                                name="relatedToPrimaNota"
                                                //disabled={props.edit_mode ? false : true}
                                                />
                                            </div>
                                        </div> */}

                                       {/*  {formData?.relatedToPrimaNota && 
                                            <div className="col-md-6 mt-3">
                                                <div className="d-flex flex-column">
                                                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                                                    Prima nota di riferimento
                                                    </label>
                                                    <div className="d-block">
                                                    <select
                                                        name="primaNotaRelated"
                                                        onChange={onChange}
                                                        value={JSON.stringify(formData.primaNotaRelated?.[0])}
                                                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                                                    >
                                                        <option value="">Seleziona</option>
                                                        {primeNote.map((item) => {
                                                        return (
                                                            <option key={item.id} value={JSON.stringify(item)}>
                                                            {item.operationDescription}
                                                            </option>
                                                        );
                                                        })}
                                                    </select>
                                                    </div>
                                                </div>
                                            </div>} */}

                                        </>                                        
                                    </div>
                                </Row>							
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="20" className="mb-2 accordionHeader">
                                <Card.Header>
                                <CustomToggle eventKey="20" activeKey={activeKey}>Allegati</CustomToggle>
                                </Card.Header>
                                <Accordion.Body>
                                    <label htmlFor="input-file" className="black-link my-2">
                                    <BsPlusCircleFill className="plus-icon" />
                                    Aggiungi allegati
                                    </label>
                                    <input
                                    type="file"
                                    id="input-file"
                                    className="d-none"
                                    multiple={true}
                                    onChange={handleChange}
                                    ></input>

                                {file.length > 0
                                    ? file.map((item, index) => {
                                        return (
                                            <FileCard
                                            key={index}
                                            name={item.name}
                                            setFileName={setFileName}
                                            deleteFile={deleteFile}
                                            id={index}
                                            />
                                        );
                                        })
                                    : ""}
                                </Accordion.Body>
                                </Accordion.Item>

                    </Accordion>
                </div>
                <div className="d-flex justify-content-end mt-5">
                    <ButtonRm
                        variant="tertiary"
                    >
                        Indietro
                    </ButtonRm>

                    <ButtonRm
                        variant="primary"
                        onClick={handleCreation}
                        className="ms-2"
                        disabled={(formData.competenceStartDate && formData.competenceEndDate && formData.deadlineDate && (formData.competenceStartDate <= formData.competenceEndDate) && (formData.competenceStartDate <= formData.deadlineDate) && formData.subjectType && formData.amount && formData.installmentName)? false : true}
                    >
                        Salva
                    </ButtonRm>
                </div>
            </Form>
        </div>
        </>
	);
}

export default Profiling;
