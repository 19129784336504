import React from "react";

function UnitCard(props) {
	return (
		<div className={`mt-4 ${props.edit_mode == undefined ? "col-md-6" : "col-md-6"}`}>
			<div className="popup-blue overflow-hidden rounded-3">
				<div className="blue-popup-header d-flex align-items-center justify-content-between card-title-padding">
					<h3 className="fs-16 text-white ">{props.data.name}</h3>
					{(props.edit_mode == undefined || props.edit_mode == true) && (
						<button
							type="button"
							className="border-0 bg-transparent text-white"
							onClick={() => {
								props.deleteUnit(props.id);
							}}>
							<i class="bi bi-x"></i>
						</button>
					)}
				</div>
				<div className="table-list d-block card-table">
					<table className="table table-borderless">
						<tbody>
						{props.data?.condominium?.length > 0 &&
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									CONDOMINIO
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
										{props.data?.condominium?.[0]?.name}
								</td>
							</tr>
						}
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									INDIRIZZO
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.address}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									CIVICO
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.houseNumber}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									INTERNO
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.internal}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									CITTA’
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.municipality}
								</td>
							</tr>
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									PROV
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.province}
								</td>
							</tr>
							{props.data?.cap &&
							<tr>
								<td className="text-uppercase fs-14 text-secondary px-3">
									CAP
								</td>
								<td className="text-capitalize fs-14 text-dark px-3">
									{props.data?.cap}
								</td>
							</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

export default UnitCard;
