import '../../../assets/css/GestioneCard.css'
import Form from 'react-bootstrap/Form';
import ButtonRm from '../../ButtonRm';
import React, { useState, useRef, useEffect  } from 'react';
import useOnClickOutside from '../useOnClickOutside';
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { editGestione, fetchGestioni } from '../../../actions/gestioni';
import { Link } from "react-router-dom";

import { BASE_URL, token, management_endpoint } from "../../../api/api";
import { mergeRefs } from "react-merge-refs";

import ShareChat from '../../ShareChat';





const GestioneCard = React.forwardRef((props, ref) => {

    const members= []

    //Mi passo tutto l'oggetto di questa attività
    const item = props.item;
    const [data, setData] = useState({
      id: props.id,
      name: props.name,
      assignee: props.assignee,
    });
  
    //MANTENGONO LO STATO DEI DROPDROWN, SE SONO APERTI O MENO
    const [showAssigneeOptions, setAssigneeOptions] = useState(false);
  
    const initials = data.assignee ?
        data.assignee.split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 2)), ""):
        ""
  
    //APRI/CHIUDI DROPDOWN INCARICATO
    function handleAssignee() {
      setAssigneeOptions((prev) => !prev);
    }
  
    //REF PER INCARICATO E GESTIONE CAMBIAMENTO INCARICATO INLINE
    const dropRef = useRef(); //cliccando fuori questo elemento si chiude dropdown
    const assigneeRef = useRef(); //anche cliccando fuori questo elemento si chiude dropdown
  
    function editAssignee(value) {
      if (showAssigneeOptions) {
        setAssigneeOptions(value);
      }
  
      if (data.assignee !== props.assignee) {
        console.log("patch assignee");
        props.onEdit(props.id, { ...item, assignee: data.assignee });
      }
    }
  
    useOnClickOutside(dropRef, () => editAssignee(false), assigneeRef);
  
    //REF PER NAME E GESTIONE CAMBIAMENTO NAME INLINE
    const nameRef = useRef();
  
    function editNameInput() {
      if (data.name !== props.name) {
        console.log("patch name" + props.id);
        props.onEdit(props.id, { ...item, name: data.name });
      }
    }
  
    useOnClickOutside(nameRef, () => editNameInput());
  
  
    //UPDATE PARTIAL DATA
    function updateData(e) {
      console.log("update");
      const { name, value } = e.target;
      console.log(name, value);
      setData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        };
      });
    }
  
    return (
      <div
        className={`gestioneCard d-flex justify-content-between align-items-center text-dark ${
           showAssigneeOptions ? "zindexSAL" : ""
        }`}
      >
        <div className="d-flex zindexSAL">
          <div className="pe-3 d-flex justify-content-center align-items-center">
            <Form.Check
              type="checkbox"
              checked={props.selectedList.includes(props.id)}
              onChange={(e) => props.handleSelection(e, props.id)}
            />
          </div>
          <div >
            <input
              type="text"
              className="inline-text-sal text-capitalize"
              value={data.name}
              name="name"
              onChange={updateData}
              ref={mergeRefs([nameRef, ref])}
            />
            <p className="fs-12">
              Creato {props.author? props.author :" "} il{" "}
              {props.creation_date?.substr(0, 10).split("-").join("-")}
            </p>
          </div>
        </div>
        
        <div className="items-activity-container me-4 d-none d-md-flex flex-column zindexSAL align-items-end
        ">
          <div className="d-flex align-items-end">
            <div className="me-3">
                <p className='text-uppercase fs-10'>Incaricato</p>
                <p
                    className="unit-assignee-circle mx-auto"
                    onClick={handleAssignee}
                    ref={assigneeRef}
                >
                    {initials}
                </p>
              <div
                ref={dropRef}
                className={`assignee-dropdown border ${
                  showAssigneeOptions ? "" : "d-none"
                }`}
              >
                <Form.Group className="mb-3">
                  <Form.Label className="fs-14 mb-2">
                    Seleziona incaricato
                  </Form.Label>
                  <Form.Select
                    className="inline-select"
                    name="assignee"
                    value={data.assignee}
                    onChange={updateData}
                    defaultValue={data.assignee}
                  >
                    <option value="">Seleziona</option>
                    {members?.length > 0 &&
                      members.map((member) => {
                        return (
                          <option key={member} value={member}>
                            {member}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
                <div className="my-3">
                  {data.assignee && (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="gestione-assignee">{initials}</p>
                      <a className="fs-14 text-dark text-decoration-underline">
                        Rimuovi
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <ButtonRm variant='outline-gray' size="small" onClick={() =>props.handleButtonContabilità(item)}>Contabilità</ButtonRm> */}
           
          </div>
        </div>
        <Link to={`${props.id}`} className="trans-div"></Link>
      </div>
    );
  });

  export default GestioneCard