import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import NoSurety from "../../assets/images/no_surety.svg";
import Printer from "../../assets/images/Print_light.svg";
import NewSuretyModal from "./NewSuretyModal";
import { BsChevronLeft } from "react-icons/bs";

export default function SuretyModal(props) {
	const [search_name, setSearchName] = useState("");
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	return (
		<>
			<div className="d-block w-100 mb-3 mt-4">
				<h2 className="fs-20 text-dark mb-2 fw-bold">Elenco fideiussioni</h2>
			</div>
			<Form>
				<div className="d-flex align-items-center justify-content-between mb-3">
					<div className="flex-shrink-0">
						<button
							type="button"
							className="documenti-owner-btn fs-16 back-btn rounded-3 shadow w-193 me-2 border-0 text-dark"
							onClick={handleShow}>
							Nuovo
						</button>
						<button
							type="button"
							className="printer-btn fs-16 back-btn rounded-3 shadow  me-2 border-0">
							<img loading="lazy" src={Printer} alt="" />
						</button>
					</div>
					<div className="flex-shrink-0 ms-3">
						<div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
							<i className="fal fa-search flex-shrink-0 me-2 text-secondary opacity-50"></i>
							<input
								type="text"
								name=""
								placeholder="Cerca"
								className="flex-grow-1 border-0 w-100 fs-14"
								onChange={(e) => {
									setSearchName(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="empty-img">
					<img loading="lazy" src={NoSurety} alt="" />
				</div>
			</Form>
			<Modal
				show={show}
				fullscreen={true}
				className="right-full-pop"
				backdrop="static">
				<Modal.Body className="p-4">
					<label
						className="float-start black-link mt-2 back-icon"
						onClick={handleClose}>
						<BsChevronLeft />
						Indietro
					</label>
					<Button
						variant=""
						onClick={(e) => setShow(false)}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<NewSuretyModal setShow={setShow} />
				</Modal.Body>
			</Modal>
		</>
	);
}
