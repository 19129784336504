import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Nav } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"

import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Button } from "react-bootstrap";

import check from "../../assets/images/check-feature.svg"
import personCheck from "../../assets/images/person-check 1.svg"
import cartCheck from "../../assets/images/cart-check 1.svg"

import checking from "../../assets/images/checking.png"

import PacchettoCardRm from "./PacchettoCardRm";

import Table from 'react-bootstrap/Table';

import logo from "../../assets/images/Group 1189.svg"

import { useNavigate } from "react-router-dom";

import Checkout from "../Checkout";

import { token, BASE_URL } from "../../api/api";


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const elements = ["Giovanni Paolo", "Marco Turi", "Agnese Lino", "Patrizio Pepe", "Gino Como", "Saverio Stubi"]



const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    sex: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	})
});

export default function Pacchetti(){

    const navigate = useNavigate()

    const [modalDeactivate, setModalDeactivate] = useState(false)
    const [modalType, setModalType] = useState(null)

    function showModalDeactivate(type){
        setModalDeactivate(true)
        setModalType(type)
    }

    function closeModalDeactivate(){
        setModalDeactivate(false)
        setModalType(null)
    }

    //handle gestione modal
    const [modalGestione, setModalGestione] = useState(false)

    function showModalGestione(type){
        setModalGestione(true)
    }

    function closeModalGestione(){
        setModalGestione(false)
    }

    //HANDLE TAB
    const [tab, setTab] = useState("monthly")

    function handleTab(type){
        setTab(type)
    }

    //EXAMPLE

   /*  const pack = [
        {
            name: "Utente Pro",
            cost: "9.99",
            bought: 0,
            used: 0,
            feature: [
                "feature 1",
                "feature 2",
                "feature 3",
                "feature 4",
            ],
            discount: null
        },
        {
            name: "Utente Gestore",
            cost: "69.99",
            bought: 0,
            used: 0,
            feature: [
                "feature 1",
                "feature 2",
                "feature 3",
                "feature 4",
                "feature 5",
                "feature 6",
                "feature 7",
            ],
            discounte: null
        },
        {
            name: "Utente Gestore",
            cost: "69.99",
            bought: 3,
            used: 1,
            feature: [
                "feature 1",
                "feature 2",
                "feature 3",
                "feature 4",
                "feature 5",
                "feature 6",
                "feature 7",
            ],
            discount: "-20%"
        }
    ] */

    const [activeTab, setActiveTab] = useState('/home');

    const pack = [
      /* {
        name: "Basic",
        cost: "9.99",
        month: "al mese",
        user: "1 utenza",
        priceIdYearly: "price_1OyUTkGIy0lE619XXNQEdcqa",
        priceIdMonthly:"price_1OyUTjGIy0lE619XCZ0CmZlW",
        bought: 0,
        used: 0,
        color: 'light',
        feature: [
          { name: "Web app e app mobile", activated: true },
          { name: "Gestione 5 unità", activated: true },
          { name: "Ricerca fornitori", activated: true },
          { name: "Valutazione immobiliare automatizzata", activated: false},
          { name: "Invita e collabora con proprietari, inquilini e fornitori", activated: true },
          { name: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)", activated: true },
          { name: "Visualizzazioni immobili su mappa", activated: true },
          { name: "Visualizzazioni interventi con specifiche su planimetria", activated: true },
          { name: "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)", activated: true },
          { name: "Pagamenti affitti/ fornitori", activated: true },
          { name: "Gestione affitti", activated: true },
          { name: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)", activated: true },
          { name: "Documenti (Proforma editabili)", activated: true },
          { name: "Calcolatrice Immobiliare automatizzati", activated: true },
          { name: "Gestione sincronizzata con il calendario di scadenze e alert", activated: true },
          { name: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)", activated: true },
          { name: "Checklist personalizzabili per raccolta dati", activated: true },
          { name: "Gestione stabili", activated: false },
          { name: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)", activated: false },
          { name: "Creazione di team operativi (squadre)", activated: false },
          { name: "Esportazione di ogni sezione in pdf e csv (excel, word)", activated: false },
          { name: "Aggiunta di prestazioni su misura", activated: false },
          { name: "Assistenza video call 30 min al mese per un anno", activated: false },
          { name: "Assistenza trasmigrazione dati", activated: false },
          { name: "Integrazioni con altri gestionali", activated: false },
        ],
        discount: null,
      }, */
      {
        name: "Pro",
        cost: "59.99",
        month: "al mese",
        user: "1 utenza",
        priceIdYearly: "price_1OyUTkGIy0lE619Xcw4FVARs",
        priceIdMonthly:"price_1OyUTkGIy0lE619XlDfIWeE7",
        bought: 0,
        used: 0,
        color: 'dark',
        feature: [
          { name: "Web app e app mobile", activated: true },
          { name: "Gestione di unità senza limiti", activated: true },
          { name: "Ricerca fornitori", activated: true },
          { name: "Valutazione immobiliare automatizzata", activated: true },
          { name: "Invita e collabora con proprietari, inquilini e fornitori", activated: true },
          { name: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)", activated: true },
          { name: "Visualizzazioni immobili su mappa", activated: true },
          { name: "Visualizzazioni interventi con specifiche su planimetria", activated: true },
          { name: "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)", activated: true },
          { name: "Pagamenti affitti/ fornitori", activated: true },
          { name: "Gestione affitti", activated: true },
          { name: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)", activated: true },
          { name: "Documenti (Proforma editabili)", activated: true },
          { name: "Calcolatrice Immobiliare automatizzati", activated: true },
          { name: "Gestione sincronizzata con il calendario di scadenze e alert", activated: true },
          { name: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)", activated: true },
          { name: "Checklist personalizzabili per raccolta dati", activated: true },
          { name: "Gestione stabili", activated: true },
          { name: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)", activated: true },
          { name: "Creazione di team operativi (squadre)", activated: true },
          { name: "Esportazione di ogni sezione in pdf e csv (excel, word)", activated: true },
          { name: "Aggiunta di prestazioni su misura", activated: false },
          { name: "Assistenza video call 30 min al mese per un anno", activated: false },
          { name: "Assistenza trasmigrazione dati", activated: false },
          { name: "Integrazioni con altri gestionali", activated: false },
        ],
        discount: null,
      },
      /* {
        name: "Custom",
        cost: "Su misura",
        month: "su misura",
        user: "Utenze da definire",
        bought: 0,
        used: 0,
        color: 'light',
        feature: [
          { name: "Web app e app mobile", activated: true },
          { name: "Gestione di unità senza limiti", activated: true },
          { name: "Ricerca fornitori", activated: true },
          { name: "Valutazione immobiliare automatizzata", activated: true },
          { name: "Invita e collabora con proprietari, inquilini e fornitori", activated: true },
          { name: "Automatizzazione calcoli immobiliari (imposte, ravvedimenti, acconti etc)", activated: true },
          { name: "Visualizzazioni immobili su mappa", activated: true },
          { name: "Visualizzazioni interventi con specifiche su planimetria", activated: true },
          { name: "Rapporto degli interventi (persone, materiali, mezzi, contabilità etc)", activated: true },
          { name: "Pagamenti affitti/ fornitori", activated: true },
          { name: "Gestione affitti", activated: true },
          { name: "Profilazioni e gestione utenti (proprietari, conduttori, fornitori)", activated: true },
          { name: "Documenti (Proforma editabili)", activated: true },
          { name: "Calcolatrice Immobiliare automatizzati", activated: true },
          { name: "Gestione sincronizzata con il calendario di scadenze e alert", activated: true },
          { name: "Chat (con firma, pagamenti, creazione di eventi, verbali, etc)", activated: true },
          { name: "Checklist personalizzabili per raccolta dati", activated: true },
          { name: "Gestione stabili", activated: true },
          { name: "Gestione amministrativo finanziaria (prima nota, rate, rendiconto)", activated: true },
          { name: "Creazione di team operativi (squadre)", activated: true },
          { name: "Esportazione di ogni sezione in pdf e csv (excel, word)", activated: true },
          { name: "Aggiunta di prestazioni su misura", activated: true },
          { name: "Assistenza video call 30 min al mese per un anno", activated: true },
          { name: "Assistenza trasmigrazione dati", activated: true },
          { name: "Integrazioni con altri gestionali", activated: true },
        ],
        discount: null,
      }, */
    ];
  
    const pack2 = [
      {
        name: "Trasmigrazione dati",
        cost: "9.99",
        month: "una tantum",
        user: "Importiamo dati e anagrafiche dal tuo vecchio gestionale a Real Meta",
        bought: 0,
        used: 0,
        color: 'light',
        feature: [],
        discount: null,
      },
      {
        name: "Assistenza clienti",
        cost: "9.99",
        month: "una tantum",
        user: "Verrai contattato da unnostro collaboratore per fissare data e ora dell’incontro",
        bought: 0,
        used: 0,
        color: 'light',
        feature: [],
        discount: null,
      },
      {
        name: "Integrazione ad altri gestionali",
        month: "una tantum",
        user: "spiegazione",
        cost: "Custom",
        bought: 0,
        used: 0,
        color: 'light',
        feature: [],
        discount: null,
      },
    ];


    const invoices = [
        {
            number: "#432",
            data: "02/04/2023",
            servizio: "Abbonamento Gestore - Mensile",
            stato: "Pagato",
            costo: "60",
            url: ""

        },
        {
            number: "#433",
            data: "02/04/2023",
            servizio: "Abbonamento Gestore - Mensile",
            stato: "Pagato",
            costo: "60",
            url: ""

        },
    ]

    const [yearlyPayment, setYearlyPayment] = useState(false)

    function changePaymentUnit(){
        setYearlyPayment(prev => !prev)
    }

     //handle gestione modal

    const [modalPayment, setModalPayment] = useState(false)

    function showModalPayment(type){
        setModalPayment(true)
    }

    function closeModalPayment(){
        setModalPayment(false)
    }



    //handle modale pagamenti
    const [payment, setPayment] = useState(null)
    function handleChangePayment(e){
        setPayment(e.target.value)
    }


    function initiateSession() {
        fetch(`${BASE_URL}api/v1/customer/session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "datStripe");
          window.open(data.url);
/*           setClientSecret(data["client_secret"]);
           setAccountConnect(data["target_account"]); */
 
          // Aggiornare stripePromise con l'account ID ricevuto
         /*  stripePromise.then(stripe => {
            stripe.update({ stripeAccount: data["target_account"] });
          }); */
        })
        .catch((error) => {
          console.error('Errore durante la richiesta:', error);
        });
     
    }

    const handleOpenPopup = () => {
        const popupUrl = 'https://billing.stripe.com/p/session/test_YWNjdF8xR3kxaUZBbHF2S3B4SkN1LF9QY0U3MnlBNHNRMTRCRXdDT0ZXUExCaDNrSlNiT2Ni0100Le6SeufO';
         // Dimensioni desiderate per la finestra popup
        const width = 600;
        const height = 800;

        // Calcola le posizioni left e top per centrare la finestra
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
        const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
            
        // Apertura della finestra popup
        //window.open(popupUrl, "StripeOnboarding", options);

        initiateSession()
    };

    const [quantity, setQuantity] = useState(1)

    function changeQuantity(e){
        setQuantity(e.target.value)
    }

    //PER STRIPE => QUESTO CHECKOUT NECESSITA DI PRICE ID E' DIVERSO DA QUELLO DEL PAGAMENTO DOVE FORNIVAMO ID ACCOUN CONNECT
    const [clientSecret, setClientSecret] = useState("");

    /* FAKE DATA */
    const [collaborators, setCollaborators] = useState(0)
    const [subBuyed, setSubBuyed] = useState (0)
    const [subAssigned, setSubAssigned] = useState(0)

    const [user, setUser] = useState({subscription: [], hasSub: false})
    const [currentSubIndex, setCurrentSubIndex] = useState(null)


    //tota
    useEffect(() => {
        const total = user.subscription.length > 0 ? user.subscription.reduce((acc, item) => acc + item.quantity, 0) : 0;
        setSubBuyed(total) 
      }, [user]);


    function inviteCollaborator(e){
        e.preventDefault()
        setCollaborators(prev => prev + 1)
    }

    function handleCustom(){
        navigate("/contatti")
    }

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };

    function assignToCollab(){
        setSubAssigned(prev => prev + 1)
        setUser(prevUser => {
            const index = currentSubIndex
            // Clona l'oggetto user
            const updatedUser = { ...prevUser };
            // Verifica che l'indice sia valido
            if (index >= 0 && index < updatedUser.subscription.length) {
            // Clona l'array subscription
            const updatedSubscription = [...updatedUser.subscription];
            // Incrementa il campo 'assigned' dell'oggetto subscription all'indice specificato
            updatedSubscription[index] = { 
                ...updatedSubscription[index], 
                assigned: updatedSubscription[index].assigned + 1 
            };
            // Aggiorna l'array subscription nell'oggetto user clonato
            updatedUser.subscription = updatedSubscription;
            }
            return updatedUser;
        });
          
    }  
    
    //HANDLE MODALE ASSING

    const [assign, setAssign] = useState(false)

    function showModalAssign(id){
      setAssign(true)
      setCurrentSubIndex(id)
    }

    function closeModalAssign(){
      setAssign(false)
      setCurrentSubIndex(null)

    }

    const [renew, setRenew] = useState(false)

    function showModalRenew(){
      setRenew(true)
    }

    function closeModalRenew(){
      setRenew(false)
    }

    /* FORMAT DATE */

    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function getNextMonthDate(date) {
        const currentMonth = date.getMonth();
        const currentYear = date.getFullYear();
        let nextMonth = currentMonth;
        let nextYear = currentYear;
      
        if (nextMonth > 11) {
          nextMonth = 0;
          nextYear++;
        }
      
        const currentMonthDays = new Date(currentYear, currentMonth + 1, 0).getDate();
        const nextMonthDays = new Date(nextYear, nextMonth + 1, 0).getDate();
      
        let nextDay = date.getDate();
      
        if (currentMonthDays !== nextMonthDays) {
          // Adjust day if current and next month have different days count
          nextDay = Math.min(nextDay, nextMonthDays);
        }
      
        return new Date(nextYear, nextMonth, nextDay);
    }

    function addYear(date, years) {
        const result = new Date(date);
        result.setFullYear(result.getFullYear() + years);
        return result;
    }

    const today = new Date();
    const formattedToday = formatDate(today);

    const todayPlus30 = getNextMonthDate(addDays(today, 30));
    const formattedTodayPlus30 = formatDate(todayPlus30);

    const todayPlus1Year = addYear(today, 1);
    const formattedTodayPlus1Year = formatDate(todayPlus1Year);

    function initiateCheckout(price) {
          fetch(`${BASE_URL}api/v1/payment/subscriptionCheckout` , {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ name: 'Abbonamento', price: price, quantity: quantity, tax: 22  })
          })
          .then((res) => res.json())
          .then((data) => {
            console.log(data, "datStripe");
            window.open(data.url);
  /*           setClientSecret(data["client_secret"]);
             setAccountConnect(data["target_account"]); */
   
            // Aggiornare stripePromise con l'account ID ricevuto
           /*  stripePromise.then(stripe => {
              stripe.update({ stripeAccount: data["target_account"] });
            }); */
          })
          .catch((error) => {
            console.error('Errore durante la richiesta:', error);
          });
       
    }


    async function handleClick(price){
        //CREA SESSIONE DI CHECKOUT
    /*   try {
                fetch('/create-checkout-session', {
                method: 'POST',
                body: JSON.stringify({ priceId, quantity }), 
                headers: {
                    'Content-Type': 'application/json'
            }
            })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
        } catch (error) {
            console.error('Si è verificato un errore durante la richiesta:', error);
        } */

        initiateCheckout(price)

        //IMPOSTA NUMERO DI ABBONAMENTI CREATI
        //setSubBuyed(prev => prev + parseInt(quantity))
        setUser(prev => {
            return {
                ...prev,
                subscription: [...prev.subscription,
                {
                    buy_dat: formattedToday,
                    exp_dat: activeTab == "yearly" ?  formattedTodayPlus1Year : formattedTodayPlus30,
                    type: activeTab == "yearly" ?  "yearly" : "monthly",
                    quantity: parseInt(quantity),
                    assigned: prev.hasSub? 0 : 1
                }],
                hasSub: true
            }
        })
    }

    /* BISOGNA DEFINIRE
    -SE A COLLABORATORE PU0' ESSERE ASSEGNATO ABBONAMENTO SOLO DOPO CHE SI E' REGISTRATO 
    -COLLABORATORE AVREBBE BISOGNO DI UNA PROPRIETA' CURRENTSUB={id: verificare-se-stripe-da-un-id-per-ogni-sub, buy_date, exp_date} (POSSO IMPOSTARLO SE COLLABORATORE E' REGISTRATO AL MOMENTO DEGLL'ASSEGNAZIONE) PER NON FARLO APPARIRE SEMPRE NELLA LISTA DI ASSEGNAZIONE (PERO' POI COME LA AGGIORNIAMO SE RINNOVI AUTOMATICI?)
    -POTREBBE VALERE LA PENA SEPARARE ACQUISTO PACCHETTI MASTER DA ACQUISTO PACCHETTI COLLAB, OPPURE OGNI VOLTA CHE ACQUISTA UN PACCHETTO BISOGNA CONTROLLARE SE HA GIA' UN ABBONAMENTO ATTIVO O MENO, SE NON LO HA IL PRIMO ABB. VIENE ASSEGNATO AL MASTER
    -POTREBBE SERVIRE DATO: LICENZE ATTIVE, PER VEDERE QUALI EFFETTIAMENTE SONO IN CORSO/NON SCADUTE
    -VERIFIFCARE SE POSSO DARE A OGNI LICENZA UN ID UNIVCO, MAGARI PRESO DA STRIPE


    COI DATI ATTUALI IO SO CHE UTENTE MASTER PU0' AVERE UN TOT DI COLLABORATORI (CONTROLLANDO LICENZE ASSEGNATE E SCADENZE)
    */
    
    const [checked, setChecked] = useState(false);

const handleChecked = (event) => {
  setChecked(event.target.checked);
};


   
    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Pacchetti</p>
                    <p className="fs-14 opacity-75">Qui potrai acquistare abbonamenti e servizi di tuo interesse</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

               {/*  <Row className="mt-3  align-items-center justify-content-center">
                    <Col md={10} className="d-flex flex-column justify-content-center align-items-center">
                        <Nav variant="" activeKey={activeTab} onSelect={handleTabChange}>
                            <Nav.Item className={`me-5  mb-4 text-dark ${activeTab == "monthly" ? "fw-bold" : 'opacity-50'} `}>
                            <Nav.Link className="text-dark"  eventKey="monthly">Mensile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className={`text-dark ${activeTab != "monthly" ? "fw-bold" : 'opacity-50'}`}>
                            <Nav.Link className="text-dark" eventKey="yearly">Annuale</Nav.Link>
                            </Nav.Item>
                        </Nav> 
                    </Col>
                </Row>  */}

    

    {/*     <Row mt={3}>
                    {
                        pack.length > 0 &&
                        pack.map((item, index) => {
                            return <PacchettoCardRm key={index} item={item} yearly={activeTab == "yearly" ? true : false} showModalPayment={false} index={index} cta="Acquista ora" cta2="Richiedi informazioni" handleClick={handleClick}  handleCustom={handleCustom} quantity={quantity} changeQuantity={changeQuantity}/>
                        })
                    }

                    {
                        clientSecret && <Checkout clientSecret={clientSecret}/>
                    }

        </Row> */}

        <Row className='mx-auto'>
                <FormGroup>
                  <FormControlLabel className='mx-auto' control={<Switch />} label="Annuale"  checked={checked}
  onChange={handleChecked}/>
                </FormGroup>
              </Row>
              {/* MOBILE */}
              <Row className="flex-column flex-md-row flex-md-wrap plans d-flex d-xl-none g-3">
                <Col md={6} lg={3} className="plan-container">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Basic</h3>
                          <p class="subtitle subtitle text-decoration-line-through fs-20 opacity-1">{checked ? "19,99" : "21,99"}</p>
                          <div class="prices">
                              <p class="price">€ {checked ? "9,99" : "10,99"} <span class="fs-18"></span></p>
                              <select onChange={changeQuantity} className="invisible">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <p class="subtitle">Massimo 1 utente</p>

                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={() => handleClick(checked ? "999" : "1099")} >Acquista</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Fino a 5</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Assente</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: 1 </span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria: Assente</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: 30</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 72h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col md={6} lg={3}  className="plan-container ">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Pro</h3>
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "49,99" : "59,99"} </p>

                          <div class="prices">
                              <p class="price">€ {checked ? "20,99" : "29,99"} <span class="fs-12"></span></p>
                              <select onChange={changeQuantity}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <p class="subtitle trasparent">-</p>

                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={() => handleClick(checked ? "2099" : "2999")} >Acquista</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Fino a 50</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: 10 </span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria: Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 48h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col md={6} lg={3}  className="plan-container ">
                      <div className='plan premium'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Premium</h3>
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "119,99" : "129,99"} </p>
                          <div class="prices">
                              <p class="price">€ {checked ? "59,99" : "64,99"}<span class="fs-12"></span></p>
                              <select onChange={changeQuantity}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <div class="btn-container">
                            <Button  variant="outline" style={{background: "white", borderColor: "white", color:"#0F1B4C" }} className="me-2 w-100" onClick={() => handleClick(checked ? "5999" : "6499")} >Acquista</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Fino a 500</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: 50 </span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria: Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col md={6} lg={3}  className="plan-container">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Enteprise</h3>
                          <p class="subtitle">Hai bisogno di un
preventivo personalizzato?</p>
                          <div class="prices">
                              <p class="price transparent">  <span class="fs-12"></span></p>
                              <select onChange={changeQuantity} className="invisible">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100" onClick={handleCustom}>Contattaci</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Unità: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione Stabili: Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Valutazioni: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Parte finanziaria:Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-block d-xl-none">Gestione attività: Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Calcoli e calcolatrice: Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Aggiornamenti: Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Assistenza: Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-block d-xl-none">Formazione: Frontale</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
              </Row>        

               {/* DESKTOP*/}
              <Row className="plans d-none d-xl-flex">
                <Col className="plan-features mt-5">
                  <div className='plan-head'>
                  <p class="per-month d-none d-xl-block">Costo per utente al mese</p>
                    <p class="per-month d-block d-xl-none">Costo per utente al mese</p>
                    <p class="subtitle transparent" style={{color: 'transparent'}}>-</p>
                  </div>
                  <select onChange={changeQuantity} className="invisible">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                    </select>
                <p class="subtitle trasparent invisible">-</p>

                  <ul className='ps-0'>
                    <li className='feature'>
                      <p>Gestione unità</p>
                    </li>
                    <li className='feature'>
                      <p>Gestione stabili</p>
                    </li>
                    <li className='feature'>
                      <p>Creazione valutazione</p>
                    </li>
                    <li className='feature'>
                      <p>Parte finanziaria</p>
                    </li>
                    <li className='feature'>
                      <p>Gestione attività</p>
                    </li>
                    <li className='feature'>
                      <p>Calcoli e calcolatrice</p>
                    </li>
                    <li className='feature'>
                      <p>Aggiornamenti del software</p>
                    </li>
                    <li className='feature'>
                      <p>Assistenza</p>
                    </li>
                    <li className='feature'>
                      <p>Formazione</p>
                    </li>
                  </ul>
                </Col>
                <Col className="plan-container mt-5">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Basic</h3>
                          <p class="subtitle subtitle text-decoration-line-through fs-20 opacity-1">{checked ? "19,99" : "21,99"}</p>
                          <div class="prices">
                              <p class="price">€ {checked ? "9,99" : "10,99"} <span class="fs-18"></span></p>
                              <select onChange={changeQuantity} className="invisible">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <p class="subtitle">Massimo un utente</p>
                          </div>
                          <div class="btn-container">
                            <Button  onClick={() => handleClick(checked ? "999" : "1099")} variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100">Acquista</Button>
                          </div>
                      </div>
                        
                        <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Da 1 a 5</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Assente</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">1 valutazione</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Assente</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">30 ticket</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 72h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col className="plan-container mt-5">
                      <div className='plan premium'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Pro</h3>
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "49,99" : "59,99"} </p>
                          <div class="prices">
                              <p class="price">€ {checked ? "20,99" : "29,99"} <span class="fs-12"></span></p>
                              <select onChange={changeQuantity}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <p class="subtitle transparent">-</p>

                          <div class="btn-container">

                            <Button onClick={() => handleClick(checked ? "2099" : "2999")}  variant="outline" style={{background: "white", borderColor: "white", color:"#0F1B4C" }} className="me-2 w-100">Acquista</Button>
                          </div>
                      </div>
                        
                        <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Fino a 50</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">10 valutazioni</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 48h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
                <Col className="plan-container  mt-5">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Premium</h3>
                          
                          <p class="subtitle text-decoration-line-through fs-20 opacity-1">€ {checked ? "119,99" : "129,99"} </p>
                          <div class="prices">
                         
                              <p class="price">€ {checked ? "59,99" : "64,99"}<span class="fs-12"></span></p>
                              <select onChange={changeQuantity} >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <p class="subtitle transparent">-</p>

                          <div class="btn-container">

                            <Button  onClick={() => handleClick(checked ? "5999" : "6499")} variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100">Acquista</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Fino a 500</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">50 valutazioni</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Tutorial</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
              
                <Col className="plan-container mt-5">
                      <div className='plan'>
                        <div class="plan-head">
                          <h3 class="has-subtitle">Enteprise</h3>
                          <p class="subtitle transparent">-</p>

                          <p class="subtitle fs-12">Hai bisogno di un
preventivo personalizzato?</p>
                          <div class="prices">
                              <p class="price"> <span class="fs-12"></span></p>
                              <select onChange={changeQuantity} className="invisible">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                          </div>
                          <div class="btn-container">
                            <Button  variant="primary" onClick={handleCustom} style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="me-2 w-100">Contattaci</Button>
                          </div>
                      </div>
                        
                      <div className='plan-features'>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitate</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Compresa</span>                                                        
                            </p>
                            <p class="feature regular">
                                <span class="d-none d-lg-block">Illimitati</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Comprese</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Annuali</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Entro 24h</span>                                                        
                            </p>

                            <p class="feature regular">
                                <span class="d-none d-lg-block">Frontale</span>                                                        
                            </p>

                        </div>
                      </div>
                </Col>
              </Row>

        <Row>
            <Table striped bordered hover className="mt-5">

            <tbody>

                <tr>
                    <td><strong>Numero di collaboratori</strong></td>
                    <td>{collaborators}</td>
                    <td> </td>
                </tr>

                <tr>
                    <td><strong>Licenze Acquistate</strong></td>
                    <td>{subBuyed }</td>
                    <td>{/* <button onClick={showModalRenew} disabled={subBuyed > 0 ? false : true}>Paga rinnovo</button> */}</td>

                </tr>

                <tr>
                    <td><strong>Licenze Assegnabili</strong></td>
                    <td>{subBuyed > 0? ( (subBuyed - 1) - subAssigned) : 0 }</td>
                    <td>
                        <p>-Puoi assegnare queste licenze ai tuoi collaboratori </p>
                        <p>-Le licenze assegnabili sono inferiori rispetto a quelle acquistate, perché non vengono considerate quella assegnate a te (utente MASTER)</p>
                    </td>

                </tr>

            </tbody>

            </Table>
            
            {
            user.subscription?.length > 0 &&
            <Table striped bordered hover className="mt-5">
                <thead>
                    <th>Licenze Assegnate</th>
                    <th>{subAssigned}/{subBuyed > 0? ( subBuyed - 1 ) : 0 }</th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr>
                        <td>Quantità abbonamenti</td>
                        <td>Data di acquisto</td>
                        <td>Data di scadenza</td>
                        <td></td>
                    </tr>
                    {
                        user.subscription.map((el,i) => {
                            return <tr key={i}>
                            <td>{i == 0 ? (el.assigned - 1) : el.assigned} / {i == 0 ? el.quantity - 1 :  el.quantity}</td>
                            <td>{el.buy_dat}</td>
                            <td>{el.exp_dat}</td>
                            <td><button onClick={() => showModalAssign(i)} disabled={(i == 0 ? (el.assigned - 1) : el.assigned) == (i == 0 ? el.quantity - 1 :  el.quantity) } className="me-2">Assegna</button> 
                            <button onClick={inviteCollaborator} disabled={quantity.length > 5? true : false}>Invita</button>
                            </td>
                        </tr>
                        })
                    }
                </tbody>

            </Table>
            }
        </Row>

    {/*
    -ACQUISTA ABBOANMENTO (da, ds, type, quantity (integer),, assigned (integer))
        -salva data di acquisto (todaydate) e tipologia di abboanmento (mensile o annuale), quantità,
        -se mensile imposta todaydate + 30 come data scadenza, se annuale today + 364 come data di scadenza 

        TABELLA LICENZE ASSEGNATE:
        -creare in automatico sulla base dell'array abboanmenti user
        -quando creiamo TR, controllo ogni oggetto (acquisto abb.) e creo tr per ogni acquisto
    */}

    {
        /*
        ASSEGNA
        -è un bottone in tr, e aggiorna l'oggetto (prop. assigned) della row a cui si riferisce
         */
    }

    {/*




                <Row className="mt-3  align-items-center justify-content-center">
                    <Col md={6} className="d-flex justify-content-center">
                        <Form.Check 
                            type="switch"
                            value={yearlyPayment}
                            onChange={changePaymentUnit}
                        />
                        <p>Pagamento annuale</p>
                    </Col>
                </Row>

                <Row className="my-3  align-items-center justify-content-center">
                    <Col md={12} >
                        <p className="fs-14 text-uppercase">Abbonamento Real Meta Property</p>
                    </Col>
                </Row>   */}    

                <Row>
                    <hr className="opacity-10 my-5"/>       
                </Row>

                <form method="POST" action="/create-customer-portal-session" className="text-center">
                    <ButtonRm variant="secondary"  /* type="submit" */ onClick={() => handleOpenPopup()}>Gestisci abbonamento</ButtonRm>
                </form>

             
        </div>

        {/* MODAL PAGAMENTO */}
        <Modal
                    show={modalPayment}
                    onHide={closeModalPayment}
                    centered
                    className="editor-modal"
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <img loading="lazy" src={logoRed} />
                            <p className="fs-18 fw-bold mt-3 mb-2">
                        Acquista pacchetto
                            </p>

                            <Row className="mb-4">
                                <Col md={6}>
                                    <div className="border text-start px-2 rounded mb-2">
                                        <Form.Check
                                        inline
                                        type="radio"
                                        value="paypal"
                                        checked={payment === "paypal"}
                                        onChange={handleChangePayment}
                                        />
                                        <img loading="lazy" src={PayPal} width="50px"/>
                                    </div>
                                    <div className="border text-start px-2 rounded mb-2">
                                        <div className="d-flex align-items-center">
                                            <Form.Check
                                            inline
                                            type="radio"
                                            value="card"
                                            checked={payment === "card"}
                                            onChange={handleChangePayment}
                                            />

                                            <div className="d-flex ">
                                                <img loading="lazy" src='' width="50px" className="me-2"/>
                                                <img loading="lazy" src={Mastercard} width="50px" className="me-2"/>
                                                <img loading="lazy" src={Maestro} width="50px" className="me-2"/>
                                            </div>
                                        </div>
                                        {payment == "card" && 
                                            <div>
                                                <Col className="mb-3">
                                                    <Form.Group>
                                                        <Form.Label 
                                                            className="f-label fs-16 d-flex align-items-center
mb-2"
                                                        >
                                                        Numero della carta
                                                        </Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            // name="name"
                                                            // value={data.name}
                                                            // onChange={onChange}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid" >
                                                        {errors.name?.message}
                                                        </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                                <Col  className="mb-3">
                                                    <Form.Group>
                                                        <Form.Label 
                                                            className="f-label fs-16 d-flex align-items-center
mb-2"
                                                        >
                                                        Titolare della carta
                                                        </Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            // name="name"
                                                            // value={data.name}
                                                            // onChange={onChange}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid" >
                                                        {errors.name?.message}
                                                        </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Form.Group>
                                                        <Form.Label 
                                                            className="f-label fs-16 d-flex align-items-center
mb-2"
                                                        >
                                                        CVC
                                                        </Form.Label>
                                                        <Form.Control 
                                                            type="text" 
                                                            // name="name"
                                                            // value={data.name}
                                                            // onChange={onChange}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid" >
                                                        {errors.name?.message}
                                                        </Form.Control.Feedback> */}
                                                    </Form.Group>
                                                </Col>
                                            </div>
                                        }
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <div className="border text-start p-4 rounded mb-2">
                                        <p>Nome Pacchetto <span>PREZZO</span></p>
                                        <p className="fw-bold mt-2 mb-3">Totale <span>PREZZO</span></p>
                                        <ButtonRm variant="primary">Effettua pagamento</ButtonRm>
                                        <Col className="my-3">
                                            <Form.Group>
                                                <Form.Label 
                                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                                >
                                                Coupon
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    // name="name"
                                                    // value={data.name}
                                                    // onChange={onChange}
                                                />
                                                {/* <Form.Control.Feedback type="invalid" >
                                                {errors.name?.message}
                                                </Form.Control.Feedback> */}
                                            </Form.Group>
                                        </Col>
                                    </div>
                                </Col>

                            </Row>
                            
                        
                        </div>

                    </Modal.Body>
        </Modal>        

         <Modal
                    show={assign}
                    onHide={closeModalAssign}
                    centered
                    className="editor-modal"
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <img loading="lazy" src={logoRed} />
                            <p className="fs-18 fw-bold mt-3 mb-2">
                        Assegna pacchetto
                            </p>
                            <div>
                                <h6>{subAssigned == subBuyed ? "Abbonamenti da assegnare terminati" : ""}</h6>
                           {
                            elements.slice(0,collaborators).map(el => {
                                return <div className="border my-3 p-3">
                                    <p>{el}</p>
                                    <div>
                                    <button onClick={assignToCollab} disabled={subAssigned == subBuyed ? true : false}>Assegna abbonamento</button>
                                    </div>
                                </div>
                            })
                           }
                           </div>
                           
                            
                        
                        </div>

                    </Modal.Body>
        </Modal>    

        <Modal
                    show={renew}
                    onHide={closeModalRenew}
                    centered
                    className="editor-modal"
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <img loading="lazy" src={logoRed} />
                            <p className="fs-18 fw-bold mt-3 mb-2">
                        Rinnova pacchetto
                            </p>
                            <div>
                                <h6>Rinnoveremo l'abbonamento di tutti i pacchetti acquistati</h6>
                                <p>Al momento hai {subBuyed} abbonamenti</p>
                                <strong>NB: questo da capire se necessario, gli abbonamenti stripe vengono rinnovati in automatico finché non li sospendiamo</strong>
                          
                                <button onClick={closeModalRenew}>RINNOVA</button>
                            
                           
                           </div>
                           
                            
                        
                        </div>

                    </Modal.Body>
        </Modal>        
        </>

        
    )
}