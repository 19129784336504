import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";

import CustomTooltip from "../../../CustomTooltip";
import CustomToggle from "../../../CustomToggle";

import CustomBadge from "../../../CustomBadge";


import { marketRefType, 
        bareOwnershipType, 
        leaseProfitabilityType, 
        rentalStateType, 
        wineryType,
        parkingAccessibilityType,
        boxType,
        boxAccessibilityType,
        boxLocationType,
        possessionType,
        condominialParkingSpaceType, 
        condominialParkingType,
        floorType, 
        ceilingHeightType,
        brightnessType,
        noisinessType,
        internalLevelsType,
        internalDistributionType,
        coveredMqRoomsRatioType,
        coveredMqBathsRatioType,
        finishingQualityType,
        status_type,
        energeticClassType,
        buildingAgeType,
        buildingLocationType,
        buildingTypologyType,
        building_status_type,
        heatingTypeType,
        office_position

    } from "./options";


export default function Uffici (props) {
  console.log(props.formValues, "FORM VALUES NEW")

    return (
        <>
        {/* MQ COPERTI */}
        <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="4" activeKey={props.activeKey}>Superifici coperte</CustomToggle>
            </Card.Header>
            <Accordion.Body>
              <div className="row mt-1">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq coperti <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="grossCoveredMq" valMin={0} valMax={1} />
                    </label>

                    <input
                      type="number"
                      name="grossCoveredMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => props.handleValueChange("grossCoveredMq", 'value', e.target.value)}
                    value={props.formValues["grossCoveredMq"].value}
                    />
                  </div>
                </div>
            
              </div>
            </Accordion.Body>
        </Accordion.Item>
                
         {/* MQ SCOPERTI */}
         <Accordion.Item eventKey="11" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="11" activeKey={props.activeKey}>Superfici scoperte</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="row">
                <div className="col-md-6">
                <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq balconi 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="balconyMq"  valMin={0} valMax={1}/>
                    </label>
                    <input
                    type="number"
                    name="balconyMq"
                    onChange={(e) => props.handleValueChange("balconyMq", 'value', e.target.value)}
                    value={props.formValues["balconyMq"].value}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Mq terrazzi 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="terraceMq"  valMin={0} valMax={1}/>
                  </label>
                  <input
                    type="number"
                    name="terraceMq"
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                    onChange={(e) => props.handleValueChange("terraceMq", 'value', e.target.value)}
                    value={props.formValues["terraceMq"].value}
                  />
                </div>
              </div>
            </div>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq giardini o cortili                     
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="gardenMq"  valMin={0} valMax={1}/>

                    </label>
                    <input
                      type="text"
                      name="gardenMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("gardenMq", 'value', e.target.value)}
                      value={props.formValues["gardenMq"].value}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq loggie o patii 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="lodgeMq"  valMin={0} valMax={1}/>

                    </label>
                    <input
                      type="text"
                       name="lodgeMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("lodgeMq", 'value', e.target.value)}
                    value={props.formValues["lodgeMq"].value}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq lastrico solare                       
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="solarPavementMq"  valMin={0} valMax={1}/>
                    </label>
                    <input
                      type="number"
                      name="solarPavementMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("solarPavementMq", 'value', e.target.value)}
                    value={props.formValues["solarPavementMq"].value}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Altri mq scoperti 
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="otherUncoveredMq"  valMin={0} valMax={1}/>
                    </label>
                    <input
                      type="number"
                      name="otherUncoveredMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"

                      onChange={(e) => props.handleValueChange("otherUncoveredMq", 'value', e.target.value)}
                      value={props.formValues["otherUncoveredMq"].value}
                    />
                  </div>
                </div>
              </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* PERTINENZE */}
        <Accordion.Item eventKey="12" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="12" activeKey={props.activeKey}>Vani pertinenziali annessi</CustomToggle>
            </Card.Header>
            <Accordion.Body>
                <div className="row">
                    <div className="col-md-6">
                    <div className="d-flex flex-column">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Cantina 
                        <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="winery" valMin={0} valMax={1} />
                        </label>
                        <select
                        name="winery"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"

                        onChange={(e) => props.handleValueChange("winery", 'value', e.target.value)}
                      value={props.formValues["winery"].value}
                        >
                        {wineryType.map((menu, index) => {
                            return (
                            <option key={index} value={menu}>
                                {capitalizeFirstLetter(menu)}
                            </option>
                            );
                        })}
                        </select>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="d-flex flex-column">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Mq cantina
                        </label>
                        <input
                        type="text"
                        name="wineryMq"
                        id="wineryMq"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                            props.onChange(e);
                        }}
                        value={props.formData.wineryMq}

                        
                        />
                    </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                    <div className="d-flex flex-column">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Soffitta 
                        <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="garret" valMin={0} valMax={1} />
                        </label>
                        <select
                        name="garret"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
 

                        onChange={(e) => props.handleValueChange("garret", 'value', e.target.value)}
                      value={props.formValues["garret"].value}
                        >
                        {wineryType.map((menu, index) => {
                            return (
                            <option key={index} value={menu}>
                                {capitalizeFirstLetter(menu)}
                            </option>
                            );
                        })}
                        </select>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <div className="d-flex flex-column">
                        <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                        Mq soffitta
                        </label>
                        <input
                        type="text"
                        name="garretMq"
                        className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                        onChange={(e) => {
                            props.onChange(e);
                        }}
                        value={props.formData.garretMq}
                        />
                    </div>
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>

        {/* BOX */}
        <Accordion.Item eventKey="14" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="14" activeKey={props.activeKey}>Box auto</CustomToggle>
            </Card.Header>
            <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Box auto 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="box"  valMin={0} valMax={1} />
                </label>
                <select
                  name="box"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("box", 'value', e.target.value)}
                      value={props.formValues["box"]?.value}
                >
                  {boxType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq box auto
                    </label>
                    <input
                      type="number"
                      name="boxMq"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => {
                        props.onChange(e);
                      }}
                      value={props.formData.boxMq}
                    />
                  </div>
                </div>
            
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Posto auto coperto 
                  <CustomTooltip  coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="coveredParkingSpace" valMin={0} valMax={1} />

                </label>
                <select
                  name="coveredParkingSpace"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("coveredParkingSpace", 'value', e.target.value)}
                      value={props.formValues["coveredParkingSpace"]?.value}
                >
                  {boxType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq posto auto coperto 
                    </label>
                    <input
                      type="text"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => {
                        props.onChange(e);
                      }}
                      name="coveredParkingSpaceMq"
                      value={props.formData?.coveredParkingSpaceMq}
                    />
              </div>
            </div>
           
          </div>
          <div className="row mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Posto auto scoperto
                      <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="uncoveredParkingSpace" valMin={0} valMax={1} />
                    </label>
                    <select
                      name="uncoveredParkingSpace"
                      // className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      onChange={(e) => props.handleValueChange("uncoveredParkingSpace", 'value', e.target.value)}
                      value={props.formValues["uncoveredParkingSpace"]?.value}
                    >
                      {boxType.map((menu, index) => {
                        return (
                          <option key={index} value={menu}>
                            {capitalizeFirstLetter(menu)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Mq posto auto scoperto 
                    </label>
                    <input
                      type="number"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={(e) => {
                        props.onChange(e);
                      }}
                      name="uncoveredParkingSpaceMq"
                      value={props.formData?.uncoveredParkingSpaceMq}


                    />
                  </div>
                </div>
              
              </div>
          <div className="row mt-3">
          
          <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Difficoltà di parcheggio in zona                           
                   
                  </label>
                  <select
                    name="parkingAccessibility"
                    onChange={(e) => {
                      props.onChange(e);
                    }}
                    value={props.formData?.parkingAccessibility}

                  >
                    {parkingAccessibilityType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
          </div>
          </Accordion.Body>
        </Accordion.Item>
        
        {/* PIANO */}
        <Accordion.Item eventKey="15" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="15" activeKey={props.activeKey}>Piano</CustomToggle>
            </Card.Header>
            <Accordion.Body>
            <div className="row">
            <div className="input-checkbox mb-3">
              <input
                type="checkbox"
                name="lift"
                checked={props.formData.lift}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            <label className="f-label fs-16 d-flex align-items-center
me-2">Ascensore 
          {/*   <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="lift" /> */}
            </label>
          </div>

              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Piano 
                    <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="floor" valMin={-0.05} valMax={0.05} />
                  </label>
                  <select
                    name="floor"
                    onChange={(e) => props.handleValueChange("floor", 'value', e.target.value)}
                    value={props.formValues["floor"].value}
                  >
                    {floorType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              
            </div>
            </Accordion.Body>
        </Accordion.Item>
        
        {/* ALTRE CARATTERISTICHE */}
        <Accordion.Item eventKey="26" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="26" activeKey={props.activeKey}>Altre caratteristiche</CustomToggle>
            </Card.Header>
              <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Stato conservativo interno 
                    <CustomTooltip  coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="internalState" valMin={-0.05} valMax={0.05} />
                  </label>
                  <select
                    name="internalState"
                    onChange={(e) => props.handleValueChange("internalState", 'value', e.target.value)}
                        value={props.formValues["internalState"]?.value}
                  >
                    {status_type.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {capitalizeFirstLetter(menu)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Classe energetica 
                    <CustomTooltip  coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="energeticClass" valMin={-0.03} valMax={0.03} />
                  </label>
                  <select
                    name="energeticClass"
                    onChange={(e) => props.handleValueChange("energeticClass", 'value', e.target.value)}
                    value={props.formValues["energeticClass"]?.value}
                  >
                    {energeticClassType.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Riscaldamento 
                    <CustomTooltip  coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="heater"  valMin={-0.05} valMax={0.05}/>
                  </label>
                  <select
                    name="heater"
                    onChange={(e) => props.handleValueChange("heater", 'value', e.target.value)}
                    value={props.formValues["heater"]?.value}
                  >
                    <option value="assente">Assente</option>
                    <option value="autonomo">Autonomo</option>
                    <option value="centralizzato">Centralizzato</option>
                    <option value="centralizzato con contabilizzatore">Centralizzato con contabilizzatore</option>

                  </select>
                </div>
              </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Posizione
                  <CustomTooltip  coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="positionOffice" valMin={-0.07} valMax={0.07} />
                </label>
                <select
                  name="positionOffice"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("positionOffice", 'value', e.target.value)}
                    value={props.formValues["positionOffice"]?.value}
                >
                  {office_position.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
         
          </Accordion.Body>
        </Accordion.Item>
        
        {/* DATI EDIFICIO */}
        <Accordion.Item eventKey="28" className="mb-2 accordionHeader">
            <Card.Header>
            <CustomToggle eventKey="28" activeKey={props.activeKey}>Dati edificio</CustomToggle>
            </Card.Header>
              <Accordion.Body>
          <div className="row mt-1">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Età edificio             
                  <CustomTooltip  coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange}name="buildingAge" valMin={-0.05} valMax={0.05}  />
                </label>
                <select
                  name="buildingAge"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("buildingAge", 'value', e.target.value)}
                    value={props.formValues["buildingAge"]?.value}
                >
                  {buildingAgeType.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {menu}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Stato conservazione edificio 
                  <CustomTooltip coeffObj={props.formValues} handleCoeffObj={props.handleCoeffChange} name="buildingState" valMin={-0.05} valMax={0.05} />

                </label>
                <select
                  name="buildingState"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                  onChange={(e) => props.handleValueChange("buildingState", 'value', e.target.value)}
                    value={props.formValues["buildingState"]?.value}
                >
                  {building_status_type.map((menu, index) => {
                    return (
                      <option key={index} value={menu}>
                        {capitalizeFirstLetter(menu)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Totale piani edificio
                </label>
                <input
                  type="text"
                  name="totalFloorNumber"
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                  onChange={(e) => {
                    props.onChange(e);
                  }}
                  value={props.formData.totalFloorNumber}
                />
              </div>
            </div>
          </div>
          </Accordion.Body>
        </Accordion.Item>
        

        </>
    )
}