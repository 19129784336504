import React, { useState, useEffect } from "react";
import "../../../../assets/css/Membri.css";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { NATION } from "../../../../constants";
import { formatDateFromString } from "../../../../utility";
import { useNavigate } from "react-router-dom";

import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../../ButtonRm";
import { Modal } from "react-bootstrap";

import CustomToggle from "../../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import CustomBadge from "../../../CustomBadge";
import accIcon1 from "../../../../assets/images/icon-custom-toggle/acc-icon-2.svg";

import { validateEightDigits } from "../../../../utils/dateManipulation";


const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
	type: yup.string().required(),
	businessName: yup.string().when("type", {
		is: "società od altri soggetti",
		then: yup.string().required("Inserisci la deominazione o ragione sociale"),
		otherwise: yup.string(),
	}),
	withholding: yup.string(),
	subjectType: yup.string(),
	societyFiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
			  return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	}),
	chamberOfCommerceNumber: yup.string(),
	chamberOfCommerceCity: yup.string(),
	reaNumber: yup.string(),
	shareCapital:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	name: yup.string().required("Inserisci il nome"),
	surname: yup.string().required("Inserisci il cognome"),
	birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	state: yup.string(),
	province: yup.string("Seleziona la provincia"),
	municipality: yup.string("Seleziona il comune"),
	fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
	notes: yup.string()
});


export default function Anagrafica(props) {
	const [checkedType, setCheckedType] = useState(
		"persona fisica" === props.formData.type ? false : true
	);
	const [municipalityList, setMunicipalityList] = useState([]);

	useEffect(() => {
		fetchMunicipalityList();
	}, [props.formData.province]);

	useEffect(() => {
		setCheckedType("persona fisica" === props.formData.type ? false : true);
	}, [props.formData.type]);

	const fetchMunicipalityList = async () => {
		axios
			.get(
				`https://anthill-service.onrender.com/municipalities?province=${props.formData.province}`
			)
			.then((res) => {
				setMunicipalityList(res.data.data);
				if (res.data.data.length > 0 && props.formData.municipality === "") {
					props.setFormData({
						...props.formData,
						municipality: res.data.data[0],
					});
				}
			});
	};

	const nationList = NATION;
	const navigate = useNavigate();

	const onChange =  (e) => {
		const { name, value } = e.target;
		if (name === "inVirtueOf") {
			props.setFormData({
				...props.formData,
				[name]: [value],
			});
		} else {
			props.setFormData({
				...props.formData,
				[name]: value,
			});
		}
	};

	const avanti = () => {
		props.next();
	};

	/* CUSTOM HEADER */
	const [activeKey, setActiveKey] = useState('');

	/* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		console.log(data)
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		avanti()
	};
	
	/* HANDLE IN QUALITA' DIOPTIONS */
	/* servirà salvare nel database le "in qualità di" di ogni utente */
	const [inVirtueOfOptions, setInVirtueOfOptions] = useState([
		"Amministratore delegato", 
		"Amministratore unico",
		"Curatore fallimentare",
		"Presidente",
		"Procuratore",
		"Rappresentante legale"
	])

	/* HANDLE MODAL IN QUALITA' DI */

	//eventualmente spostare in utilities
	function capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	const [inVirtueOfName, setInVirtueOfName] = useState("")
	const [inVirtueOfModal, setinVirtueOfModal] = useState(false) 

	function closeInVirtueOfModal(){
		setinVirtueOfModal(false)
	}

	function openInVirtueOfModal (){
		setinVirtueOfModal(true)
	}

	function handleInVirtueOfName(e){
		setInVirtueOfName(e.target.value)
	}

	function createInVirtueOf(e){
		e.preventDefault()
		e.stopPropagation()

		if(inVirtueOfName){
			setInVirtueOfOptions(prev => {
				return [...prev, (capitalizeFirstLetter(inVirtueOfName))]
			})
		}
		closeInVirtueOfModal()
		setInVirtueOfName("")
	}

	console.log(props.formData, "FORM BIRTHDAY")	

	return (
		<div
			className="d-block w-100 mb-3 "
			style={{ borderRadius: "16px" }}>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<div className="form-item-align-box d-block pt-5 pb-5">
				<Accordion defaultActiveKey={['0', '1', '4']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey} img={accIcon1}>Tipo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row className="mb-3" >
								<Col md={6}>
									<div className="d-block">
										<div className="radioLabel">
											<input
												{...register("type")}
												type="radio"
												id="contactChoice1"
												value="persona fisica"
												name="type"
												className="btn-radio"
												onChange={onChange}
												checked={!checkedType}
											/>
											<label htmlFor="contactChoice1" className="btn-radio">
												&nbsp;Persona fisica
											</label>
										</div>
										<div className="radioLabel">
											<input
												{...register("type")}
												type="radio"
												id="contactChoice2"
												value="società od altri soggetti"
												name="type"
												className="btn-radio"
												onChange={onChange}
												checked={checkedType}
											/>
											<label htmlFor="contactChoice2" className="btn-radio">
												&nbsp;Società od altri soggetti
											</label>
										</div>
									</div>
								</Col>
							</Row>							
						</Accordion.Body>
					</Accordion.Item>

					{checkedType ? (
						<>
							<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="1" activeKey={activeKey}>Società <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
								</Card.Header>
								<Accordion.Body>
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Denominazione o ragione sociale
												<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												{...register("businessName")}
												type="text" 
												name="businessName"
												value={props.formData.businessName}
												onChange={onChange}
												isInvalid={!!errors.businessName}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.businessName?.message}
											</Form.Control.Feedback>
										</Form.Group>
										</Col>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Ritenuta
											</Form.Label>
											<Form.Select 
												{...register("withholding")}
												name="withholding"
												value={props.formData.withholding}
												onChange={onChange}
												isInvalid={!!errors.withholding}
											>
												<option value=""></option>
												<option value="Nessuna">Nessuna</option>
												<option value="Ritenuta normale">Ritenuta normale</option>
												<option value="Ritenuta condominiale (4%)">Ritenuta condominiale (4%)</option>
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
												{errors.withholding?.message}
											</Form.Control.Feedback>
										</Form.Group>
										</Col>
									</Row>	
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Tipo di soggetto
											</Form.Label>
											<Form.Select 
												{...register("subjectType")}
												isInvalid={!!errors.subjectType}
												name="subjectType"
												value={props.formData.subjectType}
												onChange={onChange}
											>
											<option value="società">Società</option>
												<option value="ditta individuale">Ditta Individuale</option>
												<option value="cooperativa">Cooperativa</option>
												<option value="ente">Ente</option>
												<option value="associazione">Associazione</option>
												<option value="fondazione">Fondazione</option>
												<option value="studio">Studio</option>
												<option value="professionista">Professionista</option>
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
												{errors.subjectType?.message}
											</Form.Control.Feedback>
										</Form.Group>
										</Col>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Codice Fiscale
											</Form.Label>
											<Form.Control 
												{...register("societyFiscalCode")}
												type="text" 
												name="societyFiscalCode"
												value={props.formData.societyFiscalCode}
												onChange={onChange}
												isInvalid={!!errors.societyFiscalCode}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.societyFiscalCode?.message}
										</Form.Control.Feedback>
										</Form.Group>
										</Col>
									</Row>	
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Partita IVA
											</Form.Label>
											<Form.Control 
												{...register("vatNumber")}
												type="text" 
												name="vatNumber"
												value={props.formData.vatNumber} 
												onChange={onChange}
												isInvalid={!!errors.vatNumber}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.vatNumber?.message}
										</Form.Control.Feedback>
										</Form.Group>
										</Col>
									</Row>						
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="2" activeKey={activeKey}>Camera di Commercio</CustomToggle>
								</Card.Header>
								<Accordion.Body>
									<Row>
										<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Numero
											</Form.Label>
											<Form.Control 
												{...register("chamberOfCommerceNumber")}
												type="text"
												name="chamberOfCommerceNumber"
												value={props.formData.chamberOfCommerceNumber}
												onChange={onChange}
												isInvalid={!!errors.chamberOfCommerceNumber}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.chamberOfCommerceNumber?.message}
											</Form.Control.Feedback>
										</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Città
												</Form.Label>
												<Form.Control 
													{...register("chamberOfCommerceCity")}
													type="text"
													name="chamberOfCommerceCity"
													value={props.formData.chamberOfCommerceCity}
													onChange={onChange}
													isInvalid={!!errors.chamberOfCommerceCity}
												/>
												<Form.Control.Feedback type="invalid" >
												{errors.chamberOfCommerceCity?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>						
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="3" activeKey={activeKey}>Altri dati</CustomToggle>
								</Card.Header>
								<Accordion.Body>
									<Row>
										<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													REA
												</Form.Label>
												<Form.Control 
													{...register("reaNumber")}
													type="text"
													name="reaNumber"
													value={props.formData.reaNumber}
													onChange={onChange}
													isInvalid={!!errors.reaNumber}
												/>
												<Form.Control.Feedback type="invalid" >
												{errors.reaNumber?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Capitale sociale
												</Form.Label>
												<Form.Control 
													{...register("shareCapital")}
													type="number"
													name="shareCapital"
													value={props.formData.shareCapital}
													onChange={onChange}
													isInvalid={!!errors.shareCapital}
												/>
												<Form.Control.Feedback type="invalid" >
												{errors.shareCapital?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>						
								</Accordion.Body>
							</Accordion.Item>

							<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="4" activeKey={activeKey}>Rappresentante legale <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
								</Card.Header>
								<Accordion.Body>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Nome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												{...register("name")}	
												type="text" 
												name="name"
												value={props.formData.name}
												onChange={onChange}
												isInvalid={!!errors.name}

											/>
											<Form.Control.Feedback type="invalid" >
											{errors.name?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Cognome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												{...register("surname")}
												type="text" 
												name="surname"
												value={props.formData.surname}
												onChange={onChange}
												isInvalid={!!errors.surname}

											/>
											<Form.Control.Feedback type="invalid" >
											{errors.surname?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Data di nascita
											</Form.Label>
											<Form.Control
												{...register("birthday")}
												type="date"
												name="birthday"
												value={props.formData.birthday}
												onChange={onChange}
												isInvalid={!!errors.birthday}
												max="9999-12-31"

												
											>
											</Form.Control>
											<Form.Control.Feedback type="invalid" >
											{errors.birthday?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Stato di nascita
											</Form.Label>
												<Form.Select
													{...register("state")}
													name="state"
													id="nationSelect"
													value={props.formData.state}
													onChange={onChange}
													isInvalid={!!errors.state}
												>
												{nationList &&
													Object.keys(nationList).map((key, index) => {
														return (
															<option value={nationList[key]} key={index}>
																{nationList[key]}
															</option>
														);
												})}
												</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.state?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>


								{props.formData?.state?.value == "Italy" &&  <Row >
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Provincia di Nascita
											</Form.Label>
												<Form.Select
													{...register("province")}
													name="province"
													value={props.formData.province}
													onChange={onChange}
													isInvalid={!!errors.province}
												>
												{props.provinceList?.length > 0 && props.provinceList.map((province, index) => {
													return (
														<option value={province} key={index}>
															{province}
														</option>
													);
												})}
												</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.province?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Comune di nascita
											</Form.Label>
												<Form.Select
													{...register("municipality")}
													name="municipality"
													value={props.formData.municipality}
													onChange={onChange}
													isInvalid={!!errors.municipality}
													>
													{municipalityList?.map((municipality, index) => {
														return (
															<option value={municipality} key={index}>
																{municipality}
															</option>
														);
													})}
												</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.municipality?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>	}

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Codice Fiscale
											</Form.Label>
											<Form.Control 
												{...register("fiscalCode")}
												type="text" 
												name="fiscalCode"
												value={props.formData.fiscalCode}
												onChange={onChange}
												isInvalid={!!errors.fiscalCode}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.fiscalCode?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Sesso
										</Form.Label>
										<Form.Select
											{...register("sex")}
											name="sex"
											value={props.formData.sex}
											onChange={onChange}
											isInvalid={!!errors.sex}
											>
											<option value="Maschio">Maschio</option>
											<option value="Femmina">Femmina</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid" >
										{errors.sex?.message}
										</Form.Control.Feedback>
									</Form.Group>
									</Col>
								</Row>

								<Row className="mb-3">
										<Col md={6} className="d-flex align-items-center">
											<Form.Group className="me-2" style={{width:"90%"}}>	
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													In qualità di
												</Form.Label>
												<Form.Select 
													name="inVirtueOf"
													value={props.formData.inVirtueOf?.[0]}
													onChange={onChange}
												>
													{inVirtueOfOptions.map((el, index) => {
													return (
													<option className="text-capitalize" value={el} key={index}>
														{el}
													</option>
													);
													})} 
												</Form.Select>
											</Form.Group>
											<BsPlusCircleFill
												className="col-sm-2 col-md-1 fs-30 plus-button mt-4"
												onClick={openInVirtueOfModal}
											/>
										</Col>
								</Row>						
								</Accordion.Body>
							</Accordion.Item>
						</>
						) :
							(<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
								<Card.Header>
								<CustomToggle eventKey="1" activeKey={activeKey}>Persona fisica <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
								</Card.Header>
								<Accordion.Body>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Nome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												{...register("name")}	
												type="text" 
												name="name"
												value={props.formData.name}
												onChange={onChange}
												isInvalid={!!errors.name}

											/>
											<Form.Control.Feedback type="invalid" >
											{errors.name?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Cognome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

											</Form.Label>
											<Form.Control 
												{...register("surname")}
												type="text" 
												name="surname"
												value={props.formData.surname}
												onChange={onChange}
												isInvalid={!!errors.surname}

											/>
											<Form.Control.Feedback type="invalid" >
											{errors.surname?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Data di nascita
											</Form.Label>
											<Form.Control
												{...register("birthday")}
												type="date"
												name="birthday"
												value={
													props.formData.birthday
												}
												onChange={onChange}
												isInvalid={!!errors.birthday}
												max="9999-12-31"

											>
											</Form.Control>
											<Form.Control.Feedback type="invalid" >
											{errors.birthday?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Stato di nascita
											</Form.Label>
												<Form.Select
													{...register("state")}
													name="state"
													id="nationSelect"
													value={props.formData.state}
													onChange={onChange}
													isInvalid={!!errors.state}
												>
												{nationList &&
													Object.keys(nationList).map((key, index) => {
														return (
															<option value={nationList[key]} key={index}>
																{nationList[key]}
															</option>
														);
												})}
												</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.state?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>

								{props.formData?.state?.value == "Italy" && <Row >
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Provincia di Nascita
											</Form.Label>
												<Form.Select
													{...register("province")}
													name="province"
													value={props.formData.province}
													onChange={onChange}
													isInvalid={!!errors.province}
												>
												{props.provinceList?.length > 0 && props.provinceList.map((province, index) => {
													return (
														<option value={province} key={index}>
															{province}
														</option>
													);
												})}
												</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.state?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Comune di nascita
											</Form.Label>
												<Form.Select
													{...register("municipality")}
													name="municipality"
													value={props.formData.municipality}
													onChange={onChange}
													isInvalid={!!errors.municipality}
													>
													{municipalityList?.map((municipality, index) => {
														return (
															<option value={municipality} key={index}>
																{municipality}
															</option>
														);
													})}
												</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.municipality?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>	}

								<Row>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Codice Fiscale
											</Form.Label>
											<Form.Control 
												{...register("fiscalCode")}
												type="text" 
												name="fiscalCode"
												value={props.formData.fiscalCode}
												onChange={onChange}
												isInvalid={!!errors.fiscalCode}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.fiscalCode?.message}
										</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Sesso
										</Form.Label>
										<Form.Select
											{...register("sex")}
											name="sex"
											value={props.formData.sex}
											onChange={onChange}
											isInvalid={!!errors.sex}
											>
											<option value="Maschio">Maschio</option>
											<option value="Femmina">Femmina</option>
										</Form.Select>
										<Form.Control.Feedback type="invalid" >
										{errors.sex?.message}
										</Form.Control.Feedback>
									</Form.Group>
									</Col>
								</Row>
							
								</Accordion.Body>
							</Accordion.Item>)
						}

						<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
							<Card.Header>
							<CustomToggle eventKey="5" activeKey={activeKey}>Note</CustomToggle>
							</Card.Header>
							<Accordion.Body>
			
								<Row>
									<Col>
										<Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Note
											</Form.Label>
											<Form.Control 
												{...register("notes")}
												as="textarea"
												name="notes"
												value={props.formData.notes}
												onChange={onChange}
											/>
										</Form.Group>
									</Col>
								</Row>
							</Accordion.Body>
						</Accordion.Item>	

				</Accordion>

				{ path == "/proprietari/create" && <div className="col-12">
						<div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
							<ButtonRm 
								variant="tertiary"
								onClick={() => {navigate(-1)}}
								className="me-2"
							>
								Annulla
							</ButtonRm>
							<ButtonRm variant="primary" 
								/* onClick={() => avanti()} */
								type="submit"
							>
								Avanti
							</ButtonRm>
						</div>
					</div>}

				</div>
			</Form>

			<Modal show={inVirtueOfModal} onHide={closeInVirtueOfModal} centered className="verifyModal"> 
				<Modal.Header closeButton style={{borderBottom:"none"}} />
				<Modal.Body className="p-5 pt-2">
					<h4 className="text-dark fw-semibold">
					Crea un nuovo ruolo
					</h4>
					<div className="d-flex flex-column pb-3">
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Nome
							</Form.Label>
							<Form.Control 
								type="text" 
								value={inVirtueOfName}
								onChange={handleInVirtueOfName}
							/>
						</Form.Group>
						<button
						type="submit"
						className=" mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
						style={{backgroundColor:path == "/fornitori/create" ? "#83112f" : "#9ccbf2"}}
						onClick={(e) => createInVirtueOf(e)}
						>
						Conferma
						</button>
						<button
						type="button"
						className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
						onClick={closeInVirtueOfModal}
						>
						Annulla
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
