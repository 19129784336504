import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';

const ChatSearch = ({ chat }) => {
    const [searchMsg, setSearchMsg] = useState("");
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        if (chat.chats?.length > 0) {
            const uniqueTags = [...new Set(chat.chats.flatMap(item => item.tag || []))];
            setSuggestions(uniqueTags.map(tag => ({ label: capitalizeFirstLetter(tag) })));
        }
    }, [chat.chats]);

    useEffect(() => {
        if (chat.chats?.length > 0) {
            const filtered = chat.chats.filter(el => !el.deleted)?.filter(item => {
                const searchLowerCase = searchMsg ? searchMsg.toLowerCase() : "";
                const contentLowerCase = item.content.toLowerCase();
                const messageLowerCase = item.message ? item.message.toLowerCase() : "";
                const contentIncludesSearchMsg = contentLowerCase.includes(searchLowerCase);
                const messageIncludesSearchMsg = messageLowerCase.includes(searchLowerCase);
                const tagIncludesSearchMsg = item.tag && item.tag.length > 0 && item.tag.some(tag => tag.toLowerCase().includes(searchLowerCase));
                return contentIncludesSearchMsg || messageIncludesSearchMsg || tagIncludesSearchMsg;
            });
            setFilteredMessages(filtered);
            setCurrentMessageIndex(0);
        }
    }, [chat.chats, searchMsg]);

    const scrollToMessage = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleNextMessage = () => {
        setCurrentMessageIndex(currentMessageIndex + 1);
    };
    
    const handlePreviousMessage = () => {
        setCurrentMessageIndex(currentMessageIndex - 1);
    };

    useEffect(() => {
        if (filteredMessages.length > 0) {
            const timer = setTimeout(() => {
                scrollToMessage(filteredMessages[0].id);
            }, 1500); // Wait 1.5 seconds before executing handleNextMessage
    
            return () => clearTimeout(timer);
        }
    }, [filteredMessages]);

    useEffect(() => {
        if (filteredMessages.length > 0) {
            scrollToMessage(filteredMessages[currentMessageIndex].id);
        }
    }, [currentMessageIndex, filteredMessages]);

    const handleChange = (event, value) => {
        if (value && typeof value === 'object') {
            setSearchMsg(value.label);
        } else {
            setSearchMsg(value);
        }
    };

  
      
      const handleInputChange = (event, value) => {
        setSearchMsg(event.target.value);
    };
    

    console.log(searchMsg, "search")

    

    return (
        <div className='d-flex align-items-center mt-1'>
        <div className="col-9"> 
            <Autocomplete
                freeSolo
                options={suggestions}
                value={searchMsg}
                onInputChange={handleInputChange}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} label="Cerca..." className="searchInputChat" />}
            />
        </div>
    <div className="col-3"> {/* Il gruppo di testo e bottoni occupa il restante 20% del div */}
        {searchMsg && (
            <>
                {filteredMessages.length > 0 ? (
                    <div className="d-flex align-items-center">
                    <small className='text-light mx-3 ms-auto'> {currentMessageIndex + 1} / {filteredMessages.length}</small>
                    <button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} onClick={handlePreviousMessage} className='mx-3 me-2' disabled={currentMessageIndex === 0}><i class={`bi bi-arrow-up-circle-fill text-light ${currentMessageIndex === 0 && "opacity-50"}`}></i></button>
                    <button onClick={handleNextMessage} style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='me-2' disabled={filteredMessages.length === 1 || currentMessageIndex === filteredMessages.length - 1}><i class={`bi bi-arrow-down-circle-fill text-light ${(filteredMessages.length === 1 || currentMessageIndex === filteredMessages.length - 1) &&  "opacity-50"}`}></i></button>
                </div>
                ) : (
                    <div className="d-flex align-items-center">
                    <small className='text-light mx-3 ms-auto'> {currentMessageIndex } / {filteredMessages.length}</small>
                    <button style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} onClick={handlePreviousMessage} className='mx-3 me-2' disabled={true}><i class="bi bi-arrow-up-circle-fill text-light opacity-50"></i></button>
                    <button onClick={handleNextMessage} style={{ border: 'none', background: 'none', padding: 0, margin: 0 }} className='me-2' disabled={true}><i class="bi bi-arrow-down-circle-fill text-light opacity-50"></i></button>
                </div>
                )}
            </>
        )}
    </div>
</div>

    
    );
};

export default ChatSearch;


