import {useEffect, useState, useRef} from 'react'

import {Row, Col} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import ButtonRm from "../../ButtonRm"

import chart1ph from "../../../assets/images/chart1-placeholder.svg"
import chart2ph from "../../../assets/images/chart2-placeholder.svg"

import Chart from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import {Data, Data1} from "./Data"
import { parse } from 'date-fns';

import api , { accounting_endpoint } from '../../../api/api';

import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


import { renderToStaticMarkup } from 'react-dom/server';





function RiassuntoContabilitaUnità(props){

    const {totalCosts} = props

    const [previsionExist, setPrevisionExist] = useState(false)
    const [prevision, setPrevision] = useState(props.item.prediction)
    const [showPrevision, setShowPrevision] = useState(false)
    const [editPrevision, setEditPrevision] = useState(false)

    //MOFIFICA PREVISIONE
    function handleInputPrevision(e){
        setPrevision(e.target.value)
    }

    //PUT PREVISIONE
    function handlePrevision(){
        setEditPrevision(!editPrevision)
        if(prevision && (props.type && props.type == "unit")){
            props.onEdit(props.item.id, { ...props.item, prediction: prevision, coordinate: JSON.stringify(props.item.coordinate) });
        } else {
            props.onEdit(props.item.id, { ...props.item, prediction: prevision });
        }
    }

    function handleEditPrevision(){
        setEditPrevision(!editPrevision)

    }

    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            tooltips: {
                enabled: false
            }
        }
    }



    //DATA DONUTS SPESE

    const donutData = {
        labels: [],
        datasets: [
        {
            label: "",
            data: [],
            backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            ],
            borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            ],
            borderWidth: 1,
        },
        ],
    };

      // Creazione del grafico donut con tutte le spese totali
    Object.keys(totalCosts).forEach((itemName) => {
        const itemExpenses = totalCosts[itemName].expenses;
        const itemColor = getRandomColor();

        donutData.labels.push(itemName);
        donutData.datasets[0].data.push(totalCosts[itemName].cost);
        donutData.datasets[0].backgroundColor.push(itemColor);
        donutData.datasets[0].borderColor.push(itemColor);
    });

    //console.log("totalCost", totalCosts)
    
    // Creazione dei grafici separati per ciascun itemName
    const itemDonutData = {};
    Object.keys(totalCosts).forEach((itemName) => {
        const itemExpenses = totalCosts[itemName].expenses;
        const itemColors = getRandomColors(itemExpenses.length);
    
        itemDonutData[itemName] = {
        labels: [],
        datasets: [
            {
            label: "",
            data: [],
            backgroundColor: itemColors,
            borderColor: itemColors,
            borderWidth: 1,
            },
        ],
        };
    
        itemExpenses.forEach((expense) => {
        itemDonutData[itemName].labels.push(expense.nome);
        itemDonutData[itemName].datasets[0].data.push(expense["costo unitario"]);
        });
    });

    console.log(itemDonutData, "donut")
    
  // Funzione per generare un colore casuale
  function getRandomColor() {
    const randomColor = `rgba(${getRandomValue(0, 255)}, ${getRandomValue(
      0,
      255
    )}, ${getRandomValue(0, 255)}, 0.2)`;
    return randomColor;
  }
  
  //Funzione per generare colori casuali
    function getRandomColors(count) {
        const colors = [];
            for (let i = 0; i < count; i++) {
            const randomColor = getRandomColor();
            colors.push(randomColor);
            }
        return colors;
    }
    
    //Funzione per generare un valore casuale compreso tra min e max
    function getRandomValue(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    //DONUTS DETTAGLIATI CAROSELLO
    const itemKeys = Object.keys(itemDonutData).filter(
        (itemName) => totalCosts[itemName]?.cost !== 0
    );

    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? itemKeys.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % itemKeys.length);
    };

    const currentItem = itemKeys[currentIndex];
    const currentData = itemDonutData[currentItem];
    const currentTotalCost = totalCosts[currentItem];

    //SOMMA TOTALE COSTI
    const sommaCosti = Object.values(totalCosts).reduce(
        (acc, itemData) => acc + itemData.cost,
        0
    );

    console.log(currentTotalCost, "teest")

    //lavoro props.object
    //GUADAGNO O PERDITA

    const output = (prevision && sommaCosti) ? parseFloat(prevision) - parseFloat(sommaCosti) : 0

    //esportazione




    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showRc} onHide={props.handleCloseRc} className="p-5 rcModal" centered >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Riassunto Contabilità</Modal.Title>
                </Modal.Header>
                <Modal.Body className="rc-body p-md-5">
                    <Row className="align-items-stretch">
                        <Col className="mb-3 mb-lg-0">
                            <div className="rc-card-container mb-3">
                                <div className='d-flex justify-content-between'>
                                    <p className='fw-bold mb-2'>
                                        {editPrevision ? "Modifica Previsione" : "Previsione" }
                                    </p>
                                    <i className="bi bi-pencil" onClick={handleEditPrevision}
                                    style={{cursor:'pointer'}}></i>
                                </div>
                                {
                                    (editPrevision) &&
                                    <div className="d-flex flex-column align-items-center" style={{cursor:"pointer"}}>
                                        <Form.Group className="mb-3 me-2 w-100">
                                            <Form.Control type="number" placeholder="Inserisci la tua previsione di costi" value={prevision}
                                            onChange={handleInputPrevision}/>
                                        </Form.Group>
                                        <div className='d-flex'>
                                            <ButtonRm variant="tertiary" className="me-2"   onClick={handlePrevision} >Annulla</ButtonRm>
                                            <ButtonRm variant="primary" onClick={handlePrevision}>Salva</ButtonRm>
                                        </div>
                                    </div>}
                                    { (!editPrevision) &&
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <p>Previsione</p>
                                            <p>€ {prevision ? prevision : 0}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Costi Totali</p>
                                            <p>€ {sommaCosti}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Stato: {output >= 0 ? "In pari" : "In perdita"}</p>
                                            <p>€ {output}</p>
                                        </div>
                                        <div style={{background: output >= 0 ? "green" : "red", height:"20px", width:"100%", borderRadius:"20px", marginTop: "1rem"}}></div>
                                    </div>
                                    }
                            </div>
                            <div className="rc-card-container">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <p className='fw-bold'>Panoramica  dei costi</p>
                                    <PDFDownloadLink document={<MyDocument donutData={donutData} totalCosts={totalCosts} sommaCosti={sommaCosti}  options={options}/>} fileName="rc.pdf">
                                        {({ loading }) => (loading ? 'Generazione PDF...' : 'Scarica PDF')}
                                    </PDFDownloadLink>
                                    <i class="bi bi-download"></i>
                                </div>
                                {/* <div className="d-flex flex-column flex-sm-row aling-items-center justify-content-between">
                                    <Form.Group className="mb-3 me-2" controlId="formBasicEmail">
                                        <Form.Label>Da</Form.Label>
                                        <Form.Control type="date"/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>A</Form.Label>
                                        <Form.Control type="date"/>
                                    </Form.Group>
                                </div> */}
                                {
                                    donutData.labels?.length > 0 ?
                                    <>
                                    <Doughnut data={donutData} className="rc-donut"
                                    options={options} />
                                    {Object.entries(totalCosts).map(([itemName, itemData]) => (
                                        <Row key={itemName}>
                                            <Col>
                                                <p className='text-capitalize'>{itemName}</p>
                                            </Col>
                                            <Col>
                                                <p>Costo:€ {itemData.cost}</p>
                                            </Col>
                                        </Row>
                                    ))}
                                    <p className="text-uppercase mt-2">Costo totale: €{sommaCosti}</p>

                                    </>
                                    :
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img loading="lazy" src={chart1ph} className="my-3" height="120px"/>
                                    <p className='opacity-50'>Nessun dato ancora disponibile</p>
                                    </div> 
                                }
                            </div>
                        </Col>
                        <Col>
                            <div className="rc-card-container h-100">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <p className='fw-bold'>Dettaglio dei costi</p>
                                    <i class="bi bi-download"></i>
                                </div>
                               {/*  <div className="d-flex flex-column flex-sm-row aling-items-center justify-content-between">
                                <Form.Group className="mb-3 me-2" controlId="formBasicEmail">
                                    <Form.Label>Da</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>A</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                </div> */}
                                
                                {/* 
                                    EMPTY STATE
                                    <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                                        <img loading="lazy" src={chart2ph} className="my-3" height="120px"/> 
                                        <p className='opacity-50'>Nessun dato ancora disponibile</p>
                                    </div>  
                                */}
                                <div>
                                    {Object.entries(itemDonutData).length > 0 ? (
                                        <div key={currentItem}>
                                        <p className="fw-bold text-center text-capitalize">{currentItem}</p>
                                        {currentItem === currentItem && (
                                            <Doughnut
                                            data={currentData}
                                            className="rc-donut"
                                            options={options}
                                            />
                                        )}
                                        {currentTotalCost && currentTotalCost.expenses.length > 0 ? (
                                            <div>
                                            <Row >
                                                <Col >
                                                <p className="text-uppercase fs-14">Voci di spesa</p>
                                                <p>
                                                    {currentTotalCost.expenses.map((expense, index) => (
                                                    <p key={index} className='text-capitalize'>{expense.nome}</p>
                                                    ))}
                                                </p>
                                                </Col>
                                                <Col >
                                                <p className="text-uppercase fs-14">Costi</p>
                                                <p>
                                                    {currentTotalCost.expenses.map((expense, index) => (
                                                    <p key={index}>Costo: €{expense['costo unitario']}</p>
                                                    ))}
                                                </p>
                                                </Col>
                                            </Row>
                                            <p className='text-uppercase mt-2'>Costo totale: €{currentTotalCost.cost}</p>
                                            <div className='text-center'>
                                                <button onClick={handlePrev}><i class="bi bi-caret-left-fill"></i></button>
                                                <button onClick={handleNext}><i class="bi bi-caret-right-fill"></i></button>
                                            </div>
                                            </div>
                                        ) : (
                                            <p>Nessun dato disponibile per {currentItem}</p>
                                        )}
                                        </div>
                                    ) : (
                                        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                                        <img loading="lazy" src={chart2ph} className="my-3" height="120px" />
                                        <p className="opacity-50">Nessun dato ancora disponibile</p>
                                        </div>
                                    )}
                                    </div>

                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RiassuntoContabilitaUnità


const MyDocument = ({ donutData, totalCosts, sommaCosti, options }) => {
    const [chartImage, setChartImage] = useState('');
  
    useEffect(() => {
      // Crea il grafico di Chart.js
      const ctx = document.createElement('canvas').getContext('2d');
      const myChart = new Chart(ctx, {
        type: 'doughnut',
        data: donutData,
        options: options,
      });
  
      // Ottieni l'URL dell'immagine del grafico
      const chartImageUrl = myChart.toBase64Image();
  
      // Aggiorna lo stato con l'URL dell'immagine del grafico
      setChartImage(chartImageUrl);
  
      // Rilascia le risorse quando il componente viene smontato
      return () => myChart.destroy();
    }, [donutData, options]);

   
  console.log(chartImage, "image")

    return (
      <Document>
        <Page>
          <View>
            {/* Renderizza l'immagine del grafico */}
            {chartImage && <Image src={chartImage} style={styles.chart} />}
  
            {/* Altri elementi nel PDF */}
            {Object.entries(totalCosts).map(([itemName, itemData]) => (
              <View key={itemName} style={styles.row}>
                <Text style={styles.text}>{itemName}</Text>
                <Text style={styles.text}>Costo: €{itemData.cost}</Text>
              </View>
            ))}
            <Text style={styles.text}>Costo totale: €{sommaCosti}</Text>
          </View>
        </Page>
      </Document>
    );
  };
  
  // Stili per il PDF
  const styles = StyleSheet.create({
    row: { flexDirection: 'row', justifyContent: 'space-between' },
    text: { textTransform: 'capitalize' },
    chart: { width: 0, height: 0 }, // Imposta le dimensioni dell'immagine del grafico
  });