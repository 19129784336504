import axios from 'axios';

async function fetchBlobUrl(url) {
    try {
        
        // Effettua la richiesta HTTP
        const response = await axios.get(url, { responseType: 'blob' });
        
        // Converte il Blob in un URL e restituiscilo
        const blob = response.data;
        const blobUrl = URL.createObjectURL(blob);
        return blobUrl;
    } catch (error) {
        console.error('Errore nel recupero del Blob:', error);
        return null;
    }
}

export default fetchBlobUrl;
