import {Col, Row} from "react-bootstrap"
import ButtonRm from "../../ButtonRm"
import CustomBadge from "../../CustomBadge"
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter"

function formatDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return dateTime.toLocaleString('it-IT', options);
}

export default function Unverified(props){

    return (
        <Col sm={12} className="px-0 mt-5 mx-0">
            {
                props.data.length > 0 ?
                props.data.map(el => {
                    return    <div className="firmeCard" onClick={() => props.handleId(el?.id)}>
                    <div className="d-flex align-items-center">
                        <div className="btn round-icon-btn">
                        </div>
                        <div className="text-start ms-2">
                            <p className="fs-16 ">{capitalizeFirstLetter(el?.surname)} {capitalizeFirstLetter(el?.name)} {el.state == "WAIT_UPDATE" && 
                                <span>
                                    <CustomBadge variant={`custom-badge-alert`}>
                                        In attesa di modifiche
                                    </CustomBadge>
                                </span>}
                            </p>
                            <p className="fs-12 ">Data di registrazione: <strong>{formatDateTime(el?.cdat)}</strong></p>                              
                        </div>
                    </div>
                    <div>
                        <ButtonRm variant="outline-gray" size="small">Verifica</ButtonRm>
                    </div> 
                </div>
                })
                : 
                <p>Non ci sono account da verificare</p>
            }
        
        </Col>
    )
}