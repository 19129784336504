import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import AddBtn from "../components/AddBtn";
import TopHeading from "../components/TopHeading";
import EmptyImg from "../assets/images/empty-folder.png";
import { BsPlusCircleFill } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import api, { report_endpoint, unit_endpoint, owner_endpoint } from "../api/api";
import ReportFirstPage from "../components/AmministrazioneFinanziaria/Rendiconto/ReportFirstPage";
import "../assets/css/Documenti.css";

import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

import { checkUserData, checkUserRole } from "../utils/checkUserRole";
import ModalCheckUser from "../components/ModalCheckUser";

const Rendiconto = () => {
	const [report, setReport] = useState([]);

	async function getReport() {
		try {
			const response = await api().get(`${report_endpoint}`);
			if (response.data) {
				const parsedData = response.data.content.map(item => {
					if (item.movement && typeof item.movement === "string") { // Verifica se 'movement' non è null e se è una stringa
						try {
							item.movement = JSON.parse(item.movement); // Trasforma la stringa JSON in un oggetto/array di JavaScript
						} catch (error) {
							console.error('Errore nel parsing JSON:', error);
						}
					}
					return item;
				});
	
				setReport(parsedData);
				console.log(parsedData);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getReport();
	}, []);

	const deleteReport = (id) => {
		let temp = [...report];
		temp = temp.filter((item) => item.id !== id);
		setReport(temp);
	};

	//console.log(report)

	const [units, setUnits] = useState([]);
  	const [owners, setOwners] = useState([]);



	async function getUnits() {
		try {
		const response = await api().get(`${unit_endpoint}`);
		if (response.data) {
			setUnits(response.data.content);
			console.log(response.data.content);
		}
		} catch (err) {
		console.log(err);
		}
	}


	async function getOwners() {
		try {
		const response = await api().get(`${owner_endpoint}`);
		if (response.data) {
			setOwners(response.data.content);
			console.log(response.data.content);
		}
		} catch (err) {
		console.log(err);
		}
	}

	useEffect(() => {
		getUnits()
		getOwners()
	}, []);


 //NEW SEARCH & FILTER

	//SEARCH

	const [searchTerm, setSearchTerm] = useState("")

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  


    const categories = [
     
      {
        name: 'Proprietari',
        items: owners.map((owner) => `${owner.surname} ${owner.name}`),

      },
      {
		name: 'Unità',
		items: units.map(el => el.name)
	  }

    ];
    
    


		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("più-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])

    console.log(selectedCategories)


    useEffect(() => {
      if (report.length > 0) {
        setFilteredUnits(
          report.filter((unit) => {

            const unitDate = new Date(unit.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && unitDate < new Date(startDate)) {
              return false;
            }
    
            // Filtro per la data di fine (endDate)
            if (endDate && unitDate > new Date(endDate)) {
              return false;
            }
    
            return (
              (selectedCategories.length === 0 ||
                selectedCategories.some((cat) => {
                  if (cat.name === 'Proprietari') {
                    return unit.owner.some((owner) => {
                      const ownerFullName = `${owner.surname} ${owner.name}`;
                      return cat.items.includes(ownerFullName);
                    });
                  } else if (cat.name === 'Unità') {
					return unit.unit.some((unit) => {
					return cat.items.includes(unit.name);
					});
				} else {
                    return cat.items.includes(unit.category);
                  }
                })) /* ||
              unit.name?.toLowerCase().includes(searchTerm.toLowerCase()) */
            );
          })
        );
      }
    }, [searchTerm, selectedCategories, report, startDate, endDate]);
    

		const [sortedUnits, setSortedUnits] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedUnits(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedUnits(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(report);
    setStartDate("")
    setEndDate("")

		}


		//CHECK IF DATA REQUIRED ARE COMPILED
 const [dataRequired, setDataRequired] = useState(true);
 //Gestisce il modale
 const [showCheck, setShowCheck] = useState(false)

 useEffect(() => {
   const fetchData = async () => {
     try {
       const response = await checkUserData();
       setDataRequired(response.isVerified);
       if (!response.isVerified) {
         setShowCheck(true);
       }
     } catch (error) {
       // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
       console.error('Errore durante il recupero dei dati utente:', error);
     }
   };

   fetchData();
 }, []);

	return (
		<>
			<div className="title-search-group mobile-container-xl">
				<div className="right-header">
				<TopHeading
					SmallHeading="empty"
				/>
				</div>
				<Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
				<Col >
					<h2 className="fw-bold text-start">Rendiconto</h2>
				</Col>
				<Col className="d-flex justify-content-end ">
					<Link to="/report/create">
					<AddBtn
						tooltip="Nuovo rendiconto"
						className="me-3 me-md-0 order-md-1"
						dataRequired={dataRequired}
					/>
					</Link>
				</Col>
				</Row>
			</div>

			{report.length > 0 ? (
				<ReportFirstPage data={report} deleteReport={deleteReport} 
				sortedUnits={sortedUnits}
        searchTerm={searchTerm} 
        setSearchTerm={setSearchTerm}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        clearFiltersAndSort={clearFiltersAndSort}
        setSortBy={setSortBy}
        categories={categories}
        startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate}
		getReport={getReport}
		 />
			) : (
				<Row className="dashboard-container ">

				<div className="mx-auto text-center empty-container">
				  <div className="mx-auto d-table w-160">
					<img loading="lazy" src={EmptyImg} alt="empty" />
				  </div>
				  <p className="fs-20 text-secondary text-center">
				  Nessun rendiconto creato
				  </p>
				</div>
				</Row>
			)}

<ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
			<p className="mb-3">Per procedere con la creazione di un rendiconto è necessario completare le seguenti informazioni:</p>
			<p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
			
			{/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
		</ModalCheckUser>
		</>
	);
};

export default Rendiconto;
