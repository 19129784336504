import {useEffect, useState} from 'react'

import {Row, Col} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import ButtonRm from "../../ButtonRm"

import chart1ph from "../../../assets/images/chart1-placeholder.svg"
import chart2ph from "../../../assets/images/chart2-placeholder.svg"

import { Doughnut } from 'react-chartjs-2';
import {Data, Data1} from "../Chat/Data"




function ContabilitaGest(props){
    const [previsionExist, setPrevisionExist] = useState(false)
    const [prevision, setPrevision] = useState("")
    const [showPrevision, setShowPrevision] = useState(false)
    const [editPrevision, setEditPrevision] = useState(false)

    function handleInputPrevision(e){
        setPrevision(e.target.value)
    }

    function handlePrevision(){
        if(prevision){
            setShowPrevision(!showPrevision)
        }
    }

    function handleEditPrevision(){
        if(prevision){
            setEditPrevision(!editPrevision)
            setShowPrevision(!showPrevision)
        }
    }

    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
            },
            tooltips: {
                enabled: false
            }
        }
    }

    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showRc} onHide={props.handleCloseRc} className="p-5 rcModal" centered >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5 modalCustomHeader"
                >
                    <Modal.Title>Riassunto Contabilità</Modal.Title>
                </Modal.Header>
                <Modal.Body className="rc-body p-md-5">
                    <Row className="align-items-stretch">
                        <Col className="mb-3 mb-lg-0">
                            <div className="rc-card-container mb-3">
                                <div className='d-flex justify-content-between'>
                                    <p className='fw-bold mb-2'>
                                        {editPrevision ? "Modifica Previsione" : "Previsione" }
                                    </p>
                                    {showPrevision && <i className="bi bi-pencil" onClick={handleEditPrevision}
                                    style={{cursor:'pointer'}}></i>}
                                </div>
                                {
                                    !showPrevision && previsionExist && !editPrevision &&
                                    <div className="d-flex flex-column align-items-center" style={{cursor:"pointer"}}>
                                        <Form.Group className="mb-3 me-2 w-100">
                                            <Form.Control type="number" placeholder="Inserisci la tua previsione di costi" value={prevision}
                                            onChange={handleInputPrevision}/>
                                        </Form.Group>
                                        <div className='d-flex'>
                                            <ButtonRm variant="tertiary" className="me-2"  onClick={() =>setPrevisionExist(!previsionExist)} >Annulla</ButtonRm>
                                            <ButtonRm variant="primary" onClick={handlePrevision}>Salva</ButtonRm>
                                        </div>
                                    </div>}
                                    { !showPrevision && !previsionExist &&
                                    <div className="d-flex align-items-center" style={{cursor:"pointer"}}
                                    onClick={() =>setPrevisionExist(!previsionExist)}>
                                        <i className="bi bi-plus-circle-fill me-2"></i>
                                        <p className="fs-14">Aggiungi Previsione</p>
                                    </div>
                                    }
                                    { showPrevision && prevision &&
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <p>Previsione</p>
                                            <p>{prevision}€</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Costi Totali</p>
                                            <p>0€</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>Guadagno/perdita</p>
                                            <p>{prevision}€</p>
                                        </div>
                                        <div style={{background: "green", height:"20px", width:"100%", borderRadius:"20px", marginTop: "1rem"}}></div>
                                    </div>
                                    }

                                    {
                                        editPrevision && !showPrevision &&
                                        <div className="d-flex flex-column align-items-center" style={{cursor:"pointer"}}>
                                            <Form.Group className="mb-3 me-2 w-100">
                                                <Form.Control type="number" placeholder="Inserisci una nuova previsione di costi" value={prevision}
                                                onChange={handleInputPrevision}/>
                                            </Form.Group>
                                            <div className='d-flex'>
                                                <ButtonRm variant="tertiary" className="me-2"  onClick={() =>setShowPrevision(!showPrevision)} >Annulla</ButtonRm>
                                                <ButtonRm variant="primary" onClick={handleEditPrevision}>Salva</ButtonRm>
                                            </div>
                                        </div>
                                    }
                            </div>
                            <div className="rc-card-container">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <p className='fw-bold'>Panoramica  dei costi</p>
                                    <i class="bi bi-download"></i>
                                </div>
                                <div className="d-flex flex-column flex-sm-row aling-items-center justify-content-between">
                                <Form.Group className="mb-3 me-2" controlId="formBasicEmail">
                                    <Form.Label>Da</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>A</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                </div>
                                {
                                    Data1.labels ?
                                    <>
                                    <Doughnut data={Data1} className="rc-donut"
                                    options={options} />
                                    <Row>
                                            {/* il numero di colonne dipende dal tipo di dato */}
                                            <Col>
                                                <p className="fw-bold">Nome</p>
                                                <p className="">Persone</p>
                                                <p className="">Materiali</p>
                                                <p className="">Mezzi</p>
                                                <p className="">Spese Generali</p>
                                            </Col>
                                            <Col>
                                                <p className="fw-bold">Prezzo</p>
                                                <p className="">520€</p>
                                                <p className="">520€</p>
                                                <p className="">520€</p>
                                                <p className="">520€</p>
                                            </Col>
                                    </Row>   
                                    </>
                                    :
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img loading="lazy" src={chart1ph} className="my-3" height="120px"/>
                                    <p className='opacity-50'>Nessun dato ancora disponibile</p>
                                    </div> 
                                }
                            </div>
                        </Col>
                        <Col>
                            <div className="rc-card-container h-100">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <p className='fw-bold'>Dettaglio dei costi</p>
                                    <i class="bi bi-download"></i>
                                </div>
                                <div className="d-flex flex-column flex-sm-row aling-items-center justify-content-between">
                                <Form.Group className="mb-3 me-2" controlId="formBasicEmail">
                                    <Form.Label>Da</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>A</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                </div>
                                
                                {/* 
                                EMPTY STATE
                                <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                                    <img loading="lazy" src={chart2ph} className="my-3" height="120px"/> 
                                    <p className='opacity-50'>Nessun dato ancora disponibile</p>
                                </div>  */}
                                {
                                    Data.labels ?
                                    <>
                                    <div className='d-flex align-items-center justify-content-between '>
                                    <div className='d-none d-sm-block'><i class="bi bi-caret-left-fill"></i></div>
                                    <div className='chart-rc-container'>
                                        <p className='fs-14 text-center chart-title-rc'>Materiali</p>   
                                        <Doughnut data={Data} options={options} className="rc-donut rc-details" />   
                                    </div>  
                                    <div className='d-none d-sm-block'><i class="bi bi-caret-right-fill"></i></div>
                                    </div> 
                                    <Row>
                                            {/* il numero di colonne dipende dal tipo di dato */}
                                            <Col>
                                                <p className="fw-bold">Nome</p>
                                                <p className="">Legno</p>
                                                <p className="">Ferro</p>
                                                <p className="">Cemento</p>
                                            </Col>
                                            <Col>
                                                <p className="fw-bold">Prezzo</p>
                                                <p className="">20€</p>
                                                <p className="">40€</p>
                                                <p className="">30€</p>
                                            </Col>
                                            <Col>
                                                <p className="fw-bold">Quantità</p>
                                                <p className="">1Kg</p>
                                                <p className="">5Kg</p>
                                                <p className="">10Kg</p>
                                            </Col>
                                    </Row>    
                                    </> 
                                    :
                                    <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                                        <img loading="lazy" src={chart2ph} className="my-3" height="120px"/> 
                                        <p className='opacity-50'>Nessun dato ancora disponibile</p>
                                    </div>  
                                }                                              
                                </div>
                            </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ContabilitaGest