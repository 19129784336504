import { useState } from "react";
import EmptyEstate from "../../../assets/images/empty_estate.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PertinenceCard from "./PertinenceCard";
import PertinenceModal from "./PertinenceModal";
import CustomBadge from "../../CustomBadge";


const Relevance = (props) => {
	const [show, setShow] = useState(false);
	const saveUnit = (id) => {
		let temp = [...props.formData.pertinence];
		temp.push(props.units[id]);
		let ele = { target: { name: "pertinence", value: temp } };
		props.onChange(ele);
	};

	const remove = (id) => {
		let data = [...props.formData.pertinence];
		data.splice(id, 1);
		let e = { target: { name: "pertinence", value: data } };
		props.onChange(e);
	};
	return (
		<div  className="form-item-align-box-white d-block">
			<div >
				<h2 className="fs-20 text-dark mb-2 fw-bold">PERTINENZE</h2>
			</div>
			<div className="mt-3">
				<label className="f-label fs-16 d-flex align-items-center
mb-2">
					Crea o seleziona le pertinenze di questa unità immobiliare.
				</label>
			</div>
			<div className="unit-btn-group mt-3">
				<button
					type="button"
					className="general-btn white-btn w-193"
					onClick={(e) => {
						setShow(true);
					}}>
					Seleziona
				</button>
			</div>
			{props.formData.pertinence?.length === 0 ? (
				<div className="unit-empty-img">
					<img loading="lazy" src={EmptyEstate} alt="" />
					<span className="img-comment">
						Nessuna unità immobiliare selezionata
					</span>
				</div>
			) : (
				props.formData.pertinence.map((item, index) => {
					return (
						<PertinenceCard
							key={index}
							id={index}
							data={item}
							remove={remove}
						/>
					);
				})
			)}
			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<PertinenceModal
						table_data={props.units}
						setShow={setShow}
						saveUnit={saveUnit}
					/>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Relevance;
