import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useRef, useEffect, useState } from 'react';

import dashboard from '../../assets/images/attori.png'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import unit from  "../../assets/images/un.png"
import rent from  "../../assets/images/aff.png"
import calc from  "../../assets/images/calc.png"
import rend from  "../../assets/images/rend.png"
import calen from  "../../assets/images/calen.png"
import { useMediaQuery } from 'react-responsive';
import ResponsiveNavbar from './NavRm';

import { Link } from 'react-router-dom';
import logo from "../../assets/images/Group 1189.svg"
import Footer from './Footer';
import "../../assets/css/Home.css"

const useRefDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 })

    useEffect(() => {
        if (ref.current) {
            const { current } = ref
            const boundingRect = current.getBoundingClientRect()
            const { width, height } = boundingRect
            setDimensions({ width: Math.round(width), height: Math.round(height) })
        }
    }, [])

    useEffect(() => {
        // Definisce la funzione da chiamare quando la finestra viene ridimensionata
        const handleResizeWindow = () => {
            if (ref.current) {
                const { current } = ref
                const boundingRect = current.getBoundingClientRect()
                const { width, height } = boundingRect
                setDimensions({ width: Math.round(width), height: Math.round(height) })
            }
        };
    
        window.addEventListener('resize', handleResizeWindow);
    
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [ref]); 

    return dimensions
}

export default function Features (){

    const refContainer = useRef();
    const boxRef = useRef()
    const dimensions = useRefDimensions(refContainer)
    const boxDimension = useRefDimensions(boxRef)

    console.log(dimensions)
    //GESTIONE MODULI

    const [isOpen, setIsOpen] = useState(null)

    function handleOpening(id) {
        setIsOpen(id)
    }

    function handleClosing() {
        setIsOpen(null)
    }

    const array = [
        {
            title: "Gestione Patrimoni",
            subtitle:"Inventario completo del patrimonio immobiliare",
            icon: "bi bi-houses",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Locazioni",
            subtitle:"Sottotitolo 2",
            icon: "bi bi-file-earmark-break",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Stabili",
            subtitle:"Sottotitolo 3",
            icon: "bi bi-buildings",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Patrimoni",
            subtitle:"Inventario completo del patrimonio immobiliare",
            icon: "bi bi-houses",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Locazioni",
            subtitle:"Sottotitolo 2",
            icon: "bi bi-file-earmark-break",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Stabili",
            subtitle:"Sottotitolo 3",
            icon: "bi bi-buildings",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Patrimoni",
            subtitle:"Inventario completo del patrimonio immobiliare",
            icon: "bi bi-houses",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Locazioni",
            subtitle:"Sottotitolo 2",
            icon: "bi bi-file-earmark-break",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Gestione Stabili",
            subtitle:"Sottotitolo 3",
            icon: "bi bi-buildings",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        }
        
    ]

    //IMPOSTO BREAKPOINTS

    const isLg = useMediaQuery({
        query: "(min-width: 992px)",
    });

    const isMd = useMediaQuery({
        query: "(min-width: 768px)",
    });

    let secondPositionIndex
    let thirdPositionIndex
    if(isLg){
        secondPositionIndex = array.map((element, index) => {
            const colIndex = index % 3;
            // Se l'indice della colonna è 1 (seconda posizione), restituisci index
            if (colIndex === 1) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

        thirdPositionIndex = array.map((element, index) => {
            // Trovo indice della colonna corrente nella riga
            const colIndex = index % 3;
            // Se l'indice della colonna è 2 (quindi terza posizione), restituisci l'indice
            if (colIndex === 2) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

    } else if(isMd){    
        secondPositionIndex = array.map((element, index) => {
            // Calcola l'indice della colonna corrente nella riga
            const colIndex = index % 2;
            // Se l'indice della colonna è 1 (seconda posizione), restituisci l'indice
            if (colIndex === 1) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

        thirdPositionIndex = []
    } else {
        secondPositionIndex = []
        thirdPositionIndex = []
    }

    console.log(secondPositionIndex)

    return (
        <div >
            <div style={{background: 'linear-gradient(rgb(230, 240, 255) 0%, rgb(255 255 255) 100%) 0% 0% / contain no-repeat',  backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
                <div style={{}}>
                    <Container className="px-3 px-md-0 overflow-hidden">

                    <ResponsiveNavbar/>


                        <Row className="mt-5 justify-content-between align-items-center mx-auto gy-4" style={{maxWidth: "1100px"}}>
                            <Col lg={5} xl={6}>
                                <div>
                                    <p className="mb-2 fs-20 syne-font text-uppercase">Modulo Patrimonio</p>
                                    <p className='fs-30 mb-3'>In questa sezione troviamo le specifiche di tutti gli immobili inseriti e/o gestiti.
        Si può aggiungerne altri con <span className="fw-bold">procedure guidate ed esportare ogni elemento d' interesse.</span>
                                    </p>
                                    <Button size="lg"  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Contattaci</Button>

                                </div>
                            </Col>
                            <Col lg={5} >
                                <div>
                                <Image src="https://realmeta.it/static/media/un.98dc638a234471e33196.png" rounded style={{width: "100%"}} />
                                </div>
                            </Col>
                        </Row>

                        <Row className="flex-column mb-2 mx-auto" style={{maxWidth: "1000px", marginTop:"4rem"}}>
                            <Col className="d-flex justify-content-center" style={{padding: "4rem 0rem 2rem 0rem"}}>
                                <div className='d-flex flex-column justify-content-center align-items-center' >
                                <p className='text-uppercase text-start text-lg-center d-none d-lg-block'>Real meta property</p>
                                    <h1 className="mb-2 fs-50 syne-font text-start text-lg-center">Gestione <span>patrimonio immobiliare</span></h1>
                                    <p className='text-start text-lg-center'>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>


                                </div>
                                
                            </Col>
                            
                        </Row>

                        <Row className="d-flex mx-auto justify-content-between gy-3" style={{maxWidth: "1000px",  marginBottom:"3rem"}}  ref={refContainer}>
                            {
                                array.map((el, index) => {

                                    let leftSize = 0

                                    //Verifico se l'array delle posizioni include index
                                    if(isLg){
                                        if(secondPositionIndex.includes(index)){
                                            leftSize = `-${boxDimension.width}px`
                                        } else if(thirdPositionIndex.includes(index)){
                                            leftSize = `-${(boxDimension.width * 2)}px`
                                        }
                                        
                                    } else if(isMd && secondPositionIndex.includes(index)){
                                        leftSize = `-${boxDimension.width}px`
                                    } else {
                                        leftSize = 0
                                    }

                                

                                    return  <Col key={index} xs={12} md={6} lg={4} className="modulo-parent" ref={boxRef}>
                                    <Col className="modulo-box bg-primary py-3 p-5">
                                        <div><i class={`${el.icon} fs-46 text-light`}></i></div>
                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                            <h3 className='syne-font text-light'>{el.title}</h3>
                                                <i className={`bi bi-${isOpen === index ? 'x' : 'plus' } fs-46 text-light cursor-pointer`} onClick={() => {
                                                    if(isOpen === index){
                                                        handleClosing()
                                                    } else {
                                                        handleOpening(index)
                                                    }
                                                }}>
                                            </i>
                                        </div>
                                    </Col>
                                    {isOpen === index && <Col>
                                        <div  className="modulo-box-description p-5 mt-3" style={{width: dimensions.width, left: leftSize}}   >
                                            <Row className="flex-column flex-lg-row ">
                                                <Col>
                                                    <div>
                                                        <h3>{el.subtitle}</h3>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <p>{el.p1}</p>
                                                    </div>
                                                    
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <p>{el.p2}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </ Col>}
                                            </Col>
                                })
                            }

                            

                        </Row>

                        <Row className="mx-auto" style={{maxWidth: "1000px",  marginTop:"10rem"}}>
                            <Col lg={6} className='mb-5'>
                                <h2 className="mb-2 fs-50 syne-font">Altre <span>funzionalità</span></h2>
                                <p className=''>Designed and built with all the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.</p>
                            </Col>
                            <Col lg={6} className="">
                                <Row className="g-5 flex-wrap">
                                    <Col md={6}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row className="mx-auto" style={{maxWidth: "1100px",  marginTop:"4rem", marginBottom:'4rem'}}>
                            <Row className="flex-column flex-md-row align-items-center mb-5 mx-auto justify-content-between gy-5 gy-lg-0" style={{maxWidth: "1100px"}} >
                            <Col md={5} className='order-1 order-lg-2'>
                                    <div>
                                        <h1 className='fs-32 fs-md-46 syne-font mb-1'>Personalizzazioni
                                        </h1>
                                        <p>
                                            Hai bisogno di personalizzare il prodotto con funzionalità ad hoc?
                                        </p>
                                        <Button  variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}} className="mt-4">Richiedi informazioni</Button>
                                    </div>
                                </Col>
                                <Col md={5} className='order-2 order-lg-1'>
                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle"/>
                                </Col>
                            </Row>
                        </Row>

                        <Row className="mx-auto" style={{maxWidth: "1100px",  marginTop:"4rem", marginBottom:'4rem'}}>
                            <Row className="flex-column flex-md-row align-items-center mb-5 mx-auto justify-content-between gy-5 gy-lg-0" style={{maxWidth: "1100px"}} >
                                <Col md={5} className='order-2 order-lg-1'>
                                    <div>
                                        <h1 className='fs-32 fs-md-46 syne-font mb-1'>Moduli collegati
                                        </h1>
                                        <p>
                                            Real Meta permette di velocizzare e ottimizzare i processimi di gestione immobiliare, 
                                            garantendo massima efficienza e sicurezza dei dati trattati.
                                        </p>
                                    
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row className="flex-column flex-md-row  mb-4 gx-md-5">
                                <Col md={6} className="mb-4 mb-md-0">
                                    <div className='modulo-collegato  d-flex align-items-start'>
                                    <span className='me-4'>
                                            <i class="bi bi-houses-fill fs-20"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Manutenzioni</h2>
                                        
                                            <a className='text-uppercase fw-bold text-dark'>Link</a>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='modulo-collegato  d-flex align-items-start'>
                                    <span className='me-4'>
                                            <i class="bi bi-houses-fill fs-20"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Locazione</h2>
                                            
                                            <a className='text-uppercase fw-bold text-dark'>Link</a>

                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                            <Row className="flex-column flex-md-row  mb-4 gx-md-5">
                                <Col md={6} className="mb-4 mb-md-0">
                                    <div className='modulo-collegato  d-flex align-items-start'>
                                    <span className='me-4'>
                                            <i class="bi bi-houses-fill fs-20"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Spese</h2>
                                        
                                            <a className='text-uppercase fw-bold text-dark'>Link</a>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='modulo-collegato  d-flex align-items-start'>
                                    <span className='me-4'>
                                            <i class="bi bi-houses-fill fs-20"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Calcoli</h2>
                                        
                                            <a className='text-uppercase fw-bold text-dark'>Link</a>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className=" flex-column flex-md-row mb-4 gx-md-5">
                                <Col md={6 } className="mb-4 mb-md-0">
                                    <div className='modulo-collegato d-flex align-items-start'>
                                    <span className='me-4'>
                                            <i class="bi bi-houses-fill fs-20"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Valutazioni</h2>
                                            
                                            <a className='text-uppercase fw-bold text-dark'>Link</a>

                                        </div>
                                    </div>
                                </Col>
                                <Col  md={6} className="mb-4 mb-md-0">
                                    <div className='modulo-collegato  d-flex align-items-start'>
                                        <span className='me-4'>
                                            <i class="bi bi-houses-fill fs-20"></i>
                                        </span>
                                        <div>
                                            <h2 className='syne-font mb-3'>Un mondo di app</h2>
                                            <a className='text-uppercase fw-bold text-dark'>Link</a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Row>




                    


                        <Footer />

                    

                    </Container>
                </div>
            </div>
        </div>
    )
}

