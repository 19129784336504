import {useEffect, useState} from 'react'

import {Row, Col} from "react-bootstrap"
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

import ButtonRm from './ButtonRm';

export default function ModalCheckUser(props){
    const handleClose = () => props.setShowCheck(false);
    const handleShow = () => props.setShowCheck(props.showCheck);

    const navigate = useNavigate()

    function goToSettings(){
        navigate("/impostazioni")
    }

    return(
        <div>
            {/*EVALUATE IF THIS CAN BE A COMPONENT ON ITS OWN */}
            <Modal show={props.showCheck} onHide={handleClose} className="p-5" centered >
                <Modal.Header
                    closeButton
                    className=" pt-md-4 px-md-5"
                >
                    <Modal.Title>Compila i dati mancanti</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-4 pb-3 px-md-5 w-100 text-center">
                    {props.children}
                </Modal.Body>
                <Modal.Footer
                    className="d-flex flex-column  px-md-5 pb-md-4"
                    style={{ borderTop: "none" }}
                >
                    <ButtonRm variant="primary" onClick={() => goToSettings()} >
                        Vai a Impostazioni
                    </ButtonRm>
                    <ButtonRm variant="text" onClick={handleClose}>
                        Annulla
                    </ButtonRm>
                </Modal.Footer>
            </Modal>
        </div>
    )
}