import React, { useState } from 'react'
import Section0 from './page6/section0';


export default function Page5() {
    const [status, setStatus] = useState(0);
    const Section = () => {
        switch (status) {
            case 0:
                return <Section0 onChangeSection={(v) => setStatus(v)} />
                break;

        }
        return;
    }
    return (
        <Section />
    )
}

