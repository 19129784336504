import { BASE_URL, user_endpoint } from "../api/api";

const checkMaster = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role === "MASTER" ||  user?.role === "MASTER_PRO" || user?.role === "ADMIN";
};

const checkAdmin= () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role === "ADMIN";
};

const checkCollaborator= () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role === "COLLABORATOR";
};

const checkOwner= () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role === "OWNER";
};

const checkConductor= () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role === "CONDUCTOR";
};

const checkUserRole = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    //console.log("il ruolo utente è ", user?.role)
    return user?.role === "MASTER" ||  user?.role === "MASTER_PRO" || user?.role === "ADMIN" || user?.role === "COLLABORATOR"
};

const checkSupplier = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role === "SUPPLIER";
};



 async  function checkUserData() {
        return fetch(`https://application.anthillgroup.it:8080${user_endpoint}`, {
            headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("user"))?.token
                }`,
                "Content-Type": `application/json`,
            },
        })
        .then(resp => resp.json())
        .then(json => {
            const data = json?.content?.[0]

            let basicVerify = false

            if(data){
                if(data.isCompany){
                  if(
                  data.name &&
                  data.surname &&
                  data.birthday &&
                  data.email &&
                  data.phone &&
                  data.cap &&
                  data.fiscalCode &&
                  data.genre &&
                  data.houseNumber &&
                  data.municipality &&
                  data.province &&
                  data.address &&
                  
          
                  data.isCompany &&
                  data.vatNumber &&
                  data.businessName &&
                  data.businessAddress &&
                  data.businessHouseNumber &&
                  data.businessProvince &&
                  data.businessMunicipality &&
                  data.businessCap &&
                  data.uniqueCode
                  ){
                    basicVerify = true
                    //console.log("DATI BASE - COMPLETATO")
                  } else {
                    basicVerify = false
                    //console.log("DATI BASE - NON COMPLETATO")
          
                  }
                } else {
                if(
                   data.name &&
                  data.surname &&
                  data.birthday &&
                  data.email &&
                  data.phone &&
                  data.cap &&
                  data.fiscalCode &&
                  data.genre &&
                  data.houseNumber &&
                  data.municipality &&
                  data.province &&
                  data.address 
                  
                  ){
                    basicVerify = true
                    //console.log("DATI BASE - COMPLETATO")
                  } else {
                    basicVerify = false
                    //console.log("DATI BASE - NON COMPLETATO")
                  }
                }
              }

            let hasAccountConnect = data?.stripeUser
            let isCustomer = data?.stripeCustomer
            let isVerifiedFull = data?.state == "APPROVED"

            //console.log(basicVerify, "BASIC VERIFY", data, json)
    
            return {
                hasAccountConnect,
                isCustomer,
                isVerified: basicVerify,
                isVerifiedFull
            }
        });
    }
    
    




export {checkUserRole, checkSupplier, checkMaster, checkAdmin, checkCollaborator, checkConductor, checkOwner, checkUserData}
