import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useRef, useEffect, useState } from 'react';

import dashboard from '../../assets/images/attori.png'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import unit from  "../../assets/images/un.png"
import rent from  "../../assets/images/aff.png"
import calc from  "../../assets/images/calc.png"
import rend from  "../../assets/images/rend.png"
import calen from  "../../assets/images/calen.png"
import { useMediaQuery } from 'react-responsive';
import ResponsiveNavbar from './NavRm';

import { Link } from 'react-router-dom';
import logo from "../../assets/images/Group 1189.svg"
import Footer from './Footer';
import "../../assets/css/Home.css"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const useRefDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 })

    useEffect(() => {
        if (ref.current) {
            const { current } = ref
            const boundingRect = current.getBoundingClientRect()
            const { width, height } = boundingRect
            setDimensions({ width: Math.round(width), height: Math.round(height) })
        }
    }, [])

    useEffect(() => {
        // Definisce la funzione da chiamare quando la finestra viene ridimensionata
        const handleResizeWindow = () => {
            if (ref.current) {
                const { current } = ref
                const boundingRect = current.getBoundingClientRect()
                const { width, height } = boundingRect
                setDimensions({ width: Math.round(width), height: Math.round(height) })
            }
        };
    
        window.addEventListener('resize', handleResizeWindow);
    
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [ref]); 

    return dimensions
}

export default function Tech (){

    const refContainer = useRef();
    const boxRef = useRef()
    const dimensions = useRefDimensions(refContainer)
    const boxDimension = useRefDimensions(boxRef)

    console.log(dimensions)
    //GESTIONE MODULI

    const [isOpen, setIsOpen] = useState(null)

    function handleOpening(id) {
        setIsOpen(id)
    }

    function handleClosing() {
        setIsOpen(null)
    }

    const array = [
        {
            title: "Log di sistema",
            subtitle:"Sottotitolo 1",
            icon: "bi bi-houses",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Database crittografati",
            subtitle:"Sottotitolo 2",
            icon: "bi bi-file-earmark-break",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        },
        {
            title: "Accessi e Profilatura",
            subtitle:"Sottotitolo 3",
            icon: "bi bi-buildings",
            p1:"Il nostro software di gestione del patrimonio immobiliare supporta la costruzione dell'impianto iniziale della banca dati, facilitando il censimento e la descrizione tecnica dei beni.",
            p2:"Il modulo può essere parametrizzato in base alla tipologia di patrimonio gestito e alle esigenze di manutenzione e degli aspetti locativi."
        }
        
    ]

    //IMPOSTO BREAKPOINTS

    const isLg = useMediaQuery({
        query: "(min-width: 992px)",
    });

    const isMd = useMediaQuery({
        query: "(min-width: 768px)",
    });

    let secondPositionIndex
    let thirdPositionIndex
    if(isLg){
        secondPositionIndex = array.map((element, index) => {
            const colIndex = index % 3;
            // Se l'indice della colonna è 1 (seconda posizione), restituisci index
            if (colIndex === 1) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

        thirdPositionIndex = array.map((element, index) => {
            // Trovo indice della colonna corrente nella riga
            const colIndex = index % 3;
            // Se l'indice della colonna è 2 (quindi terza posizione), restituisci l'indice
            if (colIndex === 2) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

    } else if(isMd){    
        secondPositionIndex = array.map((element, index) => {
            // Calcola l'indice della colonna corrente nella riga
            const colIndex = index % 2;
            // Se l'indice della colonna è 1 (seconda posizione), restituisci l'indice
            if (colIndex === 1) {
                return index;
            } else {
                return null;
            }
        }).filter(index => index !== null); 

        thirdPositionIndex = []
    } else {
        secondPositionIndex = []
        thirdPositionIndex = []
    }

    console.log(secondPositionIndex)

    return (
        <div style={{background:'#fff'}}>
             <div style={{background: 'linear-gradient(180deg, #E6F0FF 0%, rgba(230, 240, 255, 0) 100%)',  backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
                <div style={{}}>
                    <Container className="px-3 px-md-0 overflow-hidden">

                    <ResponsiveNavbar/>


                        <Row className="mt-5 justify-content-between align-items-center mx-auto gy-4" style={{maxWidth: "1100px"}}>
                            <Col lg={5} xl={6}>
                                <div>
                                    <p className="mb-2 fs-20 syne-font text-uppercase">TECNOLOGIA E CLOUD</p>
                                    <p className='fs-30 mb-3'>Software gestionali per il mondo immobiliare  
    <span className="fw-bold"> in cloud.</span>
                                    </p>
                                

                                </div>
                            </Col>
                            <Col lg={5} >
                                <div>
                                <Image src="https://anthillgroup.it/images/responsive.png" rounded style={{width: "100%"}} />
                                </div>
                            </Col>
                        </Row>

                        <Row className="flex-column mb-2 mx-auto" style={{maxWidth: "1000px", marginTop:"4rem"}}>
                            <Col className="justify-content-center" style={{padding: "4rem 0rem 2rem 0rem"}}>
                                <div className='d-flex flex-column justify-content-center align-items-center' >
                                <p className='text-uppercase text-start text-lg-center d-none d-lg-block'>Real meta property</p>
                                    <h1 className="mb-2 fs-50 syne-font text-start text-lg-center">Tecnologie</h1>
                                    <p className='text-start text-lg-center'>Real Meta è sviluppato in modalità Web (React), è multibrowser (Internet Explorer 11+ e Microsoft Edge 12+, Chrome 64+, Safari 52+, Firefox 45+ ed Opera 43+), multi sistema operativo (Windows – Mac Osx – iOS – Android – Linux) e multi device (PC, Mac, tablet e smartphone).</p>


                                </div>

                                <Col lg={12} className="my-5">
                                <Row className="g-5 flex-wrap">
                                    <Col md={6} lg={4}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>

                                    <Col md={6} lg={4}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="d-flex modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                            </p>

                                        </div>
                                    </Col>
                                </Row>

                                </Col>
                                
                            </Col>
                            
                        </Row>

                        <Row className="d-flex mx-auto justify-content-between gy-3" style={{maxWidth: "1000px",  marginBottom:"3rem"}}  ref={refContainer}>
                            {
                                array.map((el, index) => {

                                    let leftSize = 0

                                    //Verifico se l'array delle posizioni include index
                                    if(isLg){
                                        if(secondPositionIndex.includes(index)){
                                            leftSize = `-${boxDimension.width}px`
                                        } else if(thirdPositionIndex.includes(index)){
                                            leftSize = `-${(boxDimension.width * 2)}px`
                                        }
                                        
                                    } else if(isMd && secondPositionIndex.includes(index)){
                                        leftSize = `-${boxDimension.width}px`
                                    } else {
                                        leftSize = 0
                                    }

                                

                                    return  <Col key={index} xs={12} md={6} lg={4} className="modulo-parent" ref={boxRef}>
                                    <Col className="modulo-box modulo-box-tech  bg-primary py-3 p-5">
                                        <div><i class={`${el.icon} fs-46 text-light`}></i></div>
                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                            <h3 className='syne-font text-light'>{el.title}</h3>
                                                <i className={`bi bi-${isOpen === index ? 'x' : 'plus' } fs-46 text-light cursor-pointer`} onClick={() => {
                                                    if(isOpen === index){
                                                        handleClosing()
                                                    } else {
                                                        handleOpening(index)
                                                    }
                                                }}>
                                            </i>
                                        </div>
                                    </Col>
                                    {isOpen === index && <Col>
                                        <div  className="modulo-box-description p-5 mt-3" style={{width: dimensions.width, left: leftSize}}   >
                                            <Row className="flex-column flex-lg-row ">
                                                <Col>
                                                    <div>
                                                        <h3>{el.subtitle}</h3>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <p>{el.p1}</p>
                                                    </div>
                                                    
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <p>{el.p2}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </ Col>}
                                            </Col>
                                })
                            }

                            

                        </Row>
                        
                        <Row className="flex-column mb-2 mx-auto" style={{maxWidth: "1000px", marginTop:"4rem"}}>
                            <Col className="justify-content-center" style={{padding: "4rem 0rem 2rem 0rem"}}>
                                <div className='d-flex flex-column justify-content-center align-items-center' >
                                <p className='text-uppercase text-start text-lg-center d-none d-lg-block'>Real meta property</p>
                                    <h1 className="mb-2 fs-50 syne-font text-start text-lg-center">Cloud</h1>
                                    <p className='text-start text-lg-center'>Tutti i nostri sistemi sono accessibili anche in modalità Cloud, per consentire a tutti le organizzazioni di fruire delle nostre soluzioni senza doversi dotare di onerose infrastrutture tecnologiche, sia in termine di costo, che di mantenimento e di sicurezza.</p>


                                </div>
                                <div className="d-flex mt-5 justify-content-between">
                                    <Col lg={5}>
                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle"/>
                                    </Col>

                                    <Col lg={5} className="my-5">
                                        <Tabs
                                            defaultActiveKey="home"
                                            id="uncontrolled-tab-example"
                                            className="mb-3"
                                            >
                                            <Tab eventKey="home" title="Vantaggi Economici">
                                                <Col className='mt-4'>
                                                    <div className="d-flex modulo-feature align-items-start">
                                                        <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
              width="20px"
              style={{ display: "flex", margin: "auto" }}
              className="me-2"
              />                                     
                                                        <p>
                                                        <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                                        </p>

                                                    </div>
                                                </Col>
                                                <br/>
                                                <Col >
                                            <div className="d-flex modulo-feature">
                                            <img
                                                src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                                width="20px"
                                                style={{ display: "flex", margin: "auto" }}
                                                className="me-2"/>
                                                <p>
                                                <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                                </p>

                                            </div>
                                                </Col>
                                                <br/>
                                                <Col>
                                            <div className="d-flex modulo-feature">
                                                <img
                                                src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                                width="20px"
                                                style={{ display: "flex", margin: "auto" }}
                                                className="me-2"/>
                                                <p>
                                                <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                                </p>

                                            </div>
                                                </Col>
                                            </Tab>
                                            <Tab eventKey="profile" title="Vantaggi tecnici">
                                            <Col className='mt-4'>
                                                    <div className="d-flex modulo-feature">
                                                    <img
                                                src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                                width="20px"
                                                style={{ display: "flex", margin: "auto" }}
                                                className="me-2"/>
                                                        <p>
                                                        <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                                        </p>

                                                    </div>
                                                </Col>
                                                <br/>
                                                <Col>
                                                    <div className="d-flex modulo-feature">
                                                    <img
                                                src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                                width="20px"
                                                style={{ display: "flex", margin: "auto" }}
                                                className="me-2"/>
                                                        <p>
                                                        <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                                        </p>

                                                    </div>
                                                </Col>
                                        <br/>
                                                <Col>
                                            <div className="d-flex modulo-feature">
                                                <img
                                                src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
                                                width="20px"
                                                style={{ display: "flex", margin: "auto" }}
                                                className="me-2"/>
                                                <p>
                                                <strong>Designed and built with all </strong> the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                                </p>

                                            </div>
                                        </Col>
                                            </Tab>
                                        
                                        </Tabs>
                                    </Col>
                                </div>
                                
                            </Col>
                            
                        </Row>

                        <Row className="flex-column flex-md-row align-items-center mb-5 mx-auto justify-content-between " style={{maxWidth: "1100px"}} id="moduli">
                                <Col md={5}>
                                    <div>
                                        <h1 className='fs-32 fs-md-46 syne-font mb-1'>La Blockchain
                                        </h1>
                                        <p>
                                        Designed and built with all  the love in the world by the Bootstrap team with the help of our contributors. Code licensed MIT, docs CC BY 3.0.
                                        </p>
                                    </div>
                                </Col>
                                <Col md={5}>
                            
                                <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle"/>
                                </Col>
                            </Row>

                        <Row className="flex-column mb-4 mx-auto">
                        
                        <Col className='mt-4'>
                                <Row> 
    <div class="mb-3 mb-md-0  p-5 px-4 p-md-0  mb-0 mt-0 pt-0" id="tools"> 
        
        <div class="row ">
            
            
            <div class="icon-band mx-auto">
                <div class="icon-container">
                    <img src="https://e7.pngegg.com/pngimages/704/688/png-clipart-google-google.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1200px-2023_Facebook_icon.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1024px-Bootstrap_logo.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Figma-1-logo.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/242px-Java_programming_language_logo.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" height="50px" class="mx-5"/>
                    <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-1024.png" height="50px" class="mx-5"/>
                    <img src="https://cdn1.iconfinder.com/data/icons/picons-social/57/github_rounded-512.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png
                    " height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/726px-CSS3_logo_and_wordmark.svg.png
                    " height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png
                    " height="50px" class="mx-5"/>
    
                    
                    <img src="https://e7.pngegg.com/pngimages/704/688/png-clipart-google-google.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1200px-2023_Facebook_icon.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1024px-Bootstrap_logo.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Figma-1-logo.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/242px-Java_programming_language_logo.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" height="50px" class="mx-5"/>
                    <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-1024.png" height="50px" class="mx-5"/>
                    <img src="https://cdn1.iconfinder.com/data/icons/picons-social/57/github_rounded-512.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png
                    " height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/726px-CSS3_logo_and_wordmark.svg.png
                    " height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png
                    " height="50px" class="mx-5"/>

    <img src="https://e7.pngegg.com/pngimages/704/688/png-clipart-google-google.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/2023_Facebook_icon.svg/1200px-2023_Facebook_icon.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1024px-Bootstrap_logo.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/a/ad/Figma-1-logo.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/242px-Java_programming_language_logo.svg.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg" height="50px" class="mx-5"/>
                    <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/97_Docker_logo_logos-1024.png" height="50px" class="mx-5"/>
                    <img src="https://cdn1.iconfinder.com/data/icons/picons-social/57/github_rounded-512.png" height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1024px-HTML5_logo_and_wordmark.svg.png
                    " height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/726px-CSS3_logo_and_wordmark.svg.png
                    " height="50px" class="mx-5"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png
                    " height="50px" class="mx-5"/>
    
        
    
                    
                </div>
                
            </div>
            
        </div>
    
    </div>
                                </Row>
                        </Col>
                        </Row> 

                        

                        <Footer />

                    </Container>
                </div>
            </div>
        </div>
    )
}

