import estate_img from "../../../assets/images/real_estate.png";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useRef } from "react";
import NumericInput from "react-numeric-input";
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";
import CurrencyInput from "react-currency-input-field";
import SuretyModal from "../SuretyModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AccountModal from "../AccountModal";
import PrintDownloadComponent from "../../PrintDownloadComponent";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CustomToggle from "../../CustomToggle";
import ButtonRm from "../../ButtonRm";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { Table } from 'react-bootstrap';

import axios from "axios";
import Select from 'react-select';


import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";

import CustomBadge from "../../CustomBadge";


const schema = yup.object().shape({
	managementType: yup.string(),
	startManagementDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	startRentingDate: yup.date().required("Inserisci la data di inizio locazione").nullable(),
	endFirstPeriodDate: yup.date().required("Inserisci la data di fine locazione").nullable(),
	endRenewalDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	cancellationRenewalForOwner:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	deadlineForCancellation: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	cancellationRentForTenant:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	cancellationRenewalForTenant:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	waiverOfCancellation: yup.boolean(),
	keyDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	closingContractDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	dueDayForPayment: yup.number("Inserisci il giorno di riferimento").max(31, "Inserisci un numero da 1 a 31").nullable().transform((_, val) => val ? Number(val) : null),
	buildingDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	buildingDeliveryNote: yup.string(),
	warrantyDeposit: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	warrantyPaymentMethod: yup.string(),
	depositInterest: yup.string(),
	warrantyDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	warrantyTotalAmount:yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	base_fee: yup.string(),
	feeFrequency: yup.string(),
	notificationType: yup.string(),
	currentMonthlyFee: yup.number("Inserisci un numero").required("Inserisci il canone mensile"),
	currentMonthlyRateExpenses: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	turnoverThreshold: yup.number("Inserisci un numero"),
	advanceCharges:yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),

})

const RegistryDataE = (props) => {
	const [surety_modal_show, setSuretyModalShow] = useState(false);
	const [account_modal_show, setAccountModalShow] = useState(false);
	const management_type = [
		"Completa",
		"Parziale",
		"Invisibile",
		"Scadenza e rate - no incassi",
		"Solo scadenze",
		"Solo contratto",
		"Mediazione affitto",
	];
	const warranty_type = [
		"Deposito cauzionale",
		"Deposito cauzionale + Fideiussioni",
		"Deposito cauzionale + Garanzie personali su importo prefissato",
		"Deposito cauzionale + Garanzie personali sul canone",
		"Deposito cauzionale + Garanzie personali sul canone + spese",
		"Fideiussioni",
		"Garanzie personali su importo prefissato",
		"Garanzie personali sul canone",
		"Garanzie personali sul canone + spese",
		"Libretto al portatore",
		"Nessuna",
		"PAC. Politica Agricola Comune",
	];
	const base_fee = ["Mensile", "Annuale", "Tutta la durata"];
	const frequency = [
		"Mensile",
		"Bimestrale",
		"trimestrale",
		"quadrimestrale",
		"semestrale",
		"bimestrale anno solare",
		"trimestrale anno solare",
		"quadrimestrale anno solare",
		"semestrale anno solare",
		"annuale",
		"biennale",
		"triennale",
		"stagionale 3 mesi",
		"stagionale 4 mesi",
		"stagionale 5 mesi",
		"stagionale 6 mesi",
		"stagionale 7 mesi",
		"stagionale 8 mesi",
		"stagionale 9 mesi",
		"annuale anno solare",
		"bimestrale anno solare + 1",
		"trimestrale anno solare + 1",
		"quadrimestrale anno solare + 1",
		"semestrale anno solare + 1",
	];
	const account = ["Conto Rossi"];
	const payment_method = [
		"Accredito su conte corrente",
		"Addebito su conto corrente",
		"Assegno",
		"Bancomat",
		"Bollettino freccia",
		"Bollettino postale",
		"Bonifico",
		"Carta di credito",
		"Cessione del credito d’impresa",
		"Compensazione finanziaria",
		"Contanti",
		"Giroconto",
		"MAV",
		"PAYPAL",
		"RAV",
		"RI.BA.",
		"RID",
		"SDD - SEPA DIRECT DEBIT",
		"VAGLIA CAMBIARIO",
		"VAGLIA POSTALE",
	];
	const security_deposit_interest = [
		"Nessun interesse",
		"Alla chiusura della locazione",
		"Ricorrenza annuale",
		"All’inizio dell’anno solare",
	];
	const preffered_payment_method = [
		"Assegno",
		"Bollettino freccia",
		"Bollettino postale",
		"Bonifico",
		"Contanti",
		"MAV",
		"RAV",
		"RI.BA.",
		"RI.BA 30 60 90",
		"RID",
		"SDD - SEPA DIRECT DEBIT",
		"VAGLIA POSTALE",
	];
	const [preffered_payment_method_id, setPrefferedPaymentMethodId] =
		useState(0);
	const notification_type = ["Nessuna", "sms", "mail"];
	const meter_management = [
		"Non gestite",
		"solo lettura contatori",
		"per verbali",
		"conteggio consumi letture inizio fine locazione",
		"conteggio conteggio consumi letture arbitrarie",
	];
	const update = ["Nessun aggiornamento", "2° anno", "3° anno"];
	const istat = ["Formula piena", "75%"];
	const vat_fee_type = [
		"4%",
		"5%",
		"10%",
		"20%",
		"21%",
		"22%",
		"esente art.10",
		"escluso art.15",
		"non imponibile (generico)",
		"fuori campo IVAA art.2",
		"non imponibile art.8 comma 1",
		"non imponibile art.8 comma 2",
		"non imponibile art.9",
		"non imponibile art 72 comma 3 numero 4",
		"fuori campo iva art 7",
		"operazione soggetta ad inversione contabile art.17 comm 6 a-ter",
		"escluso art 26 comma 3",
		"esente ai sensi dell'art 1 comma da 54 a 89 della legge n. 190/2014",
		"perazione soggetta ad inversione contabile art. 17 comma 6 a-bis",
		"operazione soggetta ad inversione contabile art.17 comma 6 a-ter (natura 6.7)",
	];

	const [warranty_type_index, setWarrantyTypeIndex] = useState(0);
	const setToggleFullMode = () => {
		props.setFullMode(!props.full_mode);
	};
	const onChangeNumericInput = (value, identity) => {
		setData({ ...data, [identity]: value });
	};

	const [data, setData] = useState({});

	console.log(data)

	const init = () => {
		setData({
			managementType: props.formData.managementType,
			startManagementDate: props.formData.startManagementDate?.substring(0,10),
			startRentingDate: props.formData.startRentingDate?.substring(0,10),
			endFirstPeriodDate: props.formData.endFirstPeriodDate?.substring(0,10),
			endRenewalDate: props.formData.endRenewalDate?.substring(0,10),
			cancellationRenewalForOwner: props.formData.cancellationRenewalForOwner,
			deadlineForCancellation: props.formData.deadlineForCancellation?.substring(0,10),
			cancellationRenewalForTenant: props.formData.cancellationRenewalForTenant,
			cancellationRentForTenant: props.formData.cancellationRentForTenant,
			waiverOfCancellation: props.formData.waiverOfCancellation,
			keyDeliveryDate: props.formData.keyDeliveryDate?.substring(0,10),
			dueDayForPayment: props.formData.dueDayForPayment,
			closingContractDate: props.formData.closingContractDate?.substring(0,10),
			buildingDeliveryDate: props.formData.buildingDeliveryDate?.substring(0,10),
			buildingDeliveryNote: props.formData.buildingDeliveryNote,
			warrantyType: props.formData.warrantyType,
			warrantyDeposit: props.formData.warrantyDeposit,
			warrantyPaymentMethod: props.formData.warrantyPaymentMethod,
			borrowedAmount: props.formData.borrowedAmount,
			depositInterest: props.formData.depositInterest,
			warrantyDeliveryDate: props.formData.warrantyDeliveryDate?.substring(0,10),
			warrantyInPrivateDocument: props.formData.warrantyInPrivateDocument,
			warrantySubjectToVat: props.formData.warrantySubjectToVat,
			voluntaryPenaltyClause: props.formData.voluntaryPenaltyClause,
			warrantyTotalAmount: props.formData.warrantyTotalAmount,
			generateIndependentFee: props.formData.generateIndependentFee,
			feePeriod: props.formData.feePeriod,
			feeFrequency: props.formData.feeFrequency,
			favoritePaymentMethod: props.formData.favoritePaymentMethod,
			debtorClientCode: props.formData.debtorClientCode,
			notificationType: props.formData.notificationType,
			currentMonthlyFee: props.formData.currentMonthlyFee,
			originalMonthlyFee: props.formData.originalMonthlyFee,
			currentMonthlyRateExpenses: props.formData.currentMonthlyRateExpenses,
			originalMonthlyRateExpenses: props.formData.originalMonthlyRateExpenses,
			readCounterManagement: props.formData.readCounterManagement,
			counterNote: props.formData.counterNote,
			haveAdjustmentOnTurnover: props.formData.haveAdjustmentOnTurnover,
			turnoverThreshold: props.formData.turnoverThreshold,
			subjectToInvoice: props.formData.subjectToInvoice,
			vatCanon: props.formData.vatCanon,
			istatUpdateFrom: props.formData.istatUpdateFrom,
			istatGrow: props.formData.istatGrow,
			istat: props.formData.istat,
			feeOverview: props.formData.feeOverview,
			condominiumFee: props.formData.condominiumFee,
			registrationExpenses: props.formData.registrationExpenses,
			advanceCharges: props.formData.advanceCharges,
			bankAccount: props.formData.bankAccount
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		if (e.target.type == "checkbox") {
			setData({ ...data, [e.target.name]: e.target.checked });
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
		}
	};

	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const componentRef = useRef();
	const [printData, setPrintData] = useState({});
	useEffect(() => {
		setPrintData({
			"Tipo di gestione": data.managementType,
			"Data d’inizio gestione": data.startManagementDate?.substring(0,16),
			"Inizio locazione": data.startRentingDate?.substring(0,16),
			"Fine periodo iniziale": data.endFirstPeriodDate?.substring(0,16),
			"Fine rinnovo": data.endRenewalDate?.substring(0,16),
			"Disdetta rinnovo locatori": data.cancellationRenewalForOwner,
			"Data entro cui può essere inviata la disdetta":
				data.deadlineForCancellation?.substring(0,16),
			"Disdetta rinnovo conduttori": data.cancellationRenewalForTenant,
			"Disdetta locazione conduttori": data.cancellationRentForTenant,
			"Rinuncia al diniego del rinnovo": data.waiverOfCancellation,
			"Consegna delle chiavi": data.keyDeliveryDate,
			"Pagamento affitto entro": data.dueDayForPayment,
			"Data anticipata chiusura contratto": data.closingContractDate,
			"Data di consegna": data.buildingDeliveryDate?.substring(0,16),
			"Note sulla consegna": data.buildingDeliveryNote,
			Tipo: data.warrantyType,
			"Importo del deposito cauzionale": data.warrantyDeposit,
			"Metodo di pagamento": data.warrantyPaymentMethod,
			"Importo prestato da terzi": data.borrowedAmount,
			"Interessi sul deposito cauzionale": data.depositInterest,
			"GGGG Data di consegna": data.warrantyDeliveryDate,
			"Garanzia terzi presente in una scrittura privata":
				data.warrantyInPrivateDocument,
			"Garanzia soggetta ad IVA": data.warrantySubjectToVat,
			"Clausola pena volontaria": data.voluntaryPenaltyClause,
			"Importo totale fideiussioni": data.warrantyTotalAmount,
			"Genera una rata separata per il deposito cauzionale":
				data.generateIndependentFee,
			"Canone su base": data.feePeriod,
			"Cadenza delle rate": data.feeFrequency,
			"Modalità di pagamento preferita": data.favoritePaymentMethod,
			"Codice cliente debitore": data.debtorClientCode,
			"Tipo di notifica dei pagamenti al locatore": data.notificationType,
			"Canone attuale (mensile)": data.currentMonthlyFee,
			"Canone originale (mensile)": data.originalMonthlyFee,
			"Spese forfettarie attuali (mensile)": data.currentMonthlyRateExpenses,
			"Spese forfettarie originali (mensile)": data.originalMonthlyRateExpenses,
			"Gestione lettura contatori": data.readCounterManagement,
			"Note relative ai contatori": data.counterNote,
			"Conguaglio su fatturato conduttore": data.haveAdjustmentOnTurnover,
			"Soglia di fatturato": data.turnoverThreshold,
			"Soggetto a fattura": data.subjectToInvoice,
			"IVA canone": data.vatCanon,
			"Aggiornamento a partire dal": data.istatUpdateFrom,
			"Soggetto aumento ISTAT": data.istatGrow,
			Istat: data.istat,
			Canone: data.feeOverview,
			"Spese condominiali previsionali (annuale)": data.condominiumFee,
			"Spese di registrazione": data.registrationExpenses,
			"Anticipo oneri accessori (mensile)": data.advanceCharges,
		});
	}, [data]);
	
	console.log(data.start)
		 //TOOLTIP CESSIONE
		 const infoHover = "È una comunicazione obbligatoria che deve essere presentata da parte di chi concede l'uso esclusivo a qualsiasi titolo (vendita, affitto, uso gratuito) di un immobile, o parte di esso, per un periodo superiore a 30 giorni."

		 //HANDLE ACCORDION
		 const [activeKey, setActiveKey] = useState("")
	  
		//VALIDAZIONE INPUT
		const { register, handleSubmit, formState: { errors }, reset } = useForm({
			  resolver: yupResolver(schema),
		  });
	  
		  const onSubmit = (data) => {
		  console.log({data})
			  // controlla se ci sono errori di validazione
			  if (Object.keys(errors).length > 0) {
				  console.log("Ci sono errori di validazione", errors);
				  return;
			  } 
			  // azione da eseguire quando i dati sono tutti corretti
			  props.forward()
		  };
	
		  /* ADEGUAMENTO ISTAT*/

		const [canoneData, setCanoneData] = useState({
			index: null,
			amount: data.currentMonthlyFee,
			percentage: 100
		})

		const [canoneAgg, setCanoneAgg] = useState(null)


		const [newCanoneModal, setNewCanoneModal] = useState(false)
		
		

		//CALCOLO ADEGUAMENTO ISTAT
		const [attuale, setAttuale] = useState(null);
  const [aggiornato, setAggiornato] = useState(0);
  const [percent, setPercent] = useState(100);
  const [att, setAtt] = useState(null);
  const [isShowDetail, setIsShowDetail] = useState(false);

  /* HANDLE SELECT WITH SEARCH */
  const [isSearchable, setIsSearchable] = useState(true);

  //GET INDEX DATA FROM API
  const [indexFoi, setIndexFoi] = useState([])

  const fetchIndex = async () => {
    try {
      const response = await axios.get('https://anthill-service.onrender.com/getIstatIndexList');
      setIndexFoi(response.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchIndex()
  }, []);

  /* OPTIONS FOR SELECT, EACH OPTION SHOULD BE AN OBJECT WITH PROPRERTIES "value" AND "label" */

  function numberToMonth(number) {
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    return months[number - 1];
  }

  const [currentIndex, setCurrentIndex] = useState(null)

  function handleCurrentIndex(selectedOption){
    setCurrentIndex(selectedOption);
  }


  function handleCanoneModal(){
	setNewCanoneModal(prev => !prev)
	setCanoneData({
		index: null,
		amount: data.currentMonthlyFee,
		percentage: 100
		})
	setCanoneAgg(null)
	}
				
	function handleCanoneData(e){
		const {value, name, checked, type} = e.target
		setCanoneData(prev => {
			return {
				...prev,
				[name]: type == "checked" ? checked : value
				}
		})
	}

	console.log(canoneAgg, canoneData)

	const onCalclicked = () => {
		let str = currentIndex?.value //valore da sostituire con value dell'indice selezionato
		let strToNum = parseFloat(str)
		setCanoneAgg((canoneData.amount * (1 + (canoneData.percentage / 100) * ( strToNum / 100))).toFixed(2));
	};


  let selectOptions = indexFoi ? indexFoi.map((obj) => {
    let formattedValue = (obj.value);
    let formattedLabel = `${obj.value}% - ${numberToMonth(obj.month)} ${obj.year}`;
    return { value: formattedValue, label: formattedLabel };
  }) : [];

   //MESE ANNO PER TABELLA
   let datePart = currentIndex?.label.split(" - ")[1] ?? "";



   //GESTIONE CONTI
   const handleAccount = (e) => {
	let temp = [];
	if (e.target.value !== "") {
		temp.push(JSON.parse(e.target.value));
	}
	let ele = { target: { name: "bankAccount", value: temp[0] } };
	onChange(ele);
};

console.log(props.formData.bankAccount)

const contiArr = [...props.managerAccounts, ...props.ownerAccounts]

	return (
		<>
		<div className="d-block w-100 mb-3">
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="form-item-align-box d-block py-3" ref={componentRef}>
				{!props.edit_mode && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}
					<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
				<Accordion defaultActiveKey={['1', '7']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Gestione </CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Tipo di gestione
								</Form.Label>
								<Form.Select 
									{...register("managementType")}
									name="managementType"
									value={data.managementType}
									onChange={onChange}
									isInvalid={!!errors.managementType}
								>
								{management_type.map((menu, index) => {
									return (
										<option key={index} value={menu}>
											{menu}
										</option>
									);
								})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.managementType?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Data d’inizio gestione
								</Form.Label>
								<Form.Control
								{...register("startManagementDate")}
								type="date"
								name="startManagementDate"
								value={data.startManagementDate}
								onChange={onChange}
								isInvalid={!!errors.startManagementDate}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.startManagementDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Durata e date <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Inizio locazione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

								</Form.Label>
								<Form.Control
								{...register("startRentingDate")}
								type="date"
								name="startRentingDate"
								value={data.startRentingDate}
								onChange={onChange}
								isInvalid={!!errors.startRentingDate}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.startRentingDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Fine periodo iniziale <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

								</Form.Label>
								<Form.Control
								{...register("endFirstPeriodDate")}
								type="date"
								name="endFirstPeriodDate"
								value={data.endFirstPeriodDate}
								onChange={onChange}
								isInvalid={!!errors.endFirstPeriodDate}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.endFirstPeriodDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Fine rinnovo 
								</Form.Label>
								<Form.Control
								{...register("endRenewalDate")}
								type="date"
								name="endRenewalDate"
								value={data.endRenewalDate}
								onChange={onChange}
								isInvalid={!!errors.endRenewalDate}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.endRenewalDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Preavviso, in mesi,della disdetta del rinnovo</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Disdetta rinnovo locatori
									</Form.Label>
									<Form.Control 
										{...register("scancellationRenewalForOwner")}
										type="number"
										name="cancellationRenewalForOwner"
										value={data.cancellationRenewalForOwner}								
										onChange={onChange}
										isInvalid={!!errors.cancellationRenewalForOwner}
										placeholder="Inserisci un numero indicante i mesi"

									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Data entro cui può essere inviata la disdetta
								</Form.Label>
								<Form.Control
								{...register("deadlineForCancellation")}
								type="date"
								name="deadlineForCancellation"
								value={data.deadlineForCancellation}
								onChange={onChange}
								isInvalid={!!errors.deadlineForCancellation}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.deadlineForCancellation?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Disdetta rinnovo conduttori
									</Form.Label>
									<Form.Control 
										{...register("cancellationRenewalForTenant")}
										type="number"
										name="cancellationRenewalForTenant"
										value={data.cancellationRenewalForTenant}					
										onChange={onChange}
										isInvalid={!!errors.cancellationRenewalForTenant}
										placeholder="Inserisci un numero indicante i mesi"

									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForTenant?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Disdetta locazione conduttori
									</Form.Label>
									<Form.Control 
										{...register("cancellationRentForTenantt")}
										type="number"
										name="cancellationRentForTenant"
										value={data.cancellationRentForTenant}
										onChange={onChange}
										isInvalid={!!errors.cancellationRentForTenant}
										placeholder="Inserisci un numero indicante i mesi"

									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRentForTenant?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							
						</Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("waiverOfCancellation")}
									name="waiverOfCancellation"
									checked={data.waiverOfCancellation}
									onChange={onChange} label="Rinuncia al diniego del rinnovo" />
							</Form.Group>
						</Col>
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="3" activeKey={activeKey}>Altre date</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Data anticipata chiusura contratto
								</Form.Label>
								<Form.Control
								{...register("closingContractDate")}
								type="date"
								name="closingContractDate"
								value={data.closingContractDate}
								onChange={onChange}
								isInvalid={!!errors.closingContractDate}
								
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.closingContractDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Consegna delle chiavi
								</Form.Label>
								<Form.Control
								{...register("keyDeliveryDate")}
								type="date"
								name="keyDeliveryDate"
								value={data.keyDeliveryDate}
								onChange={onChange}
								isInvalid={!!errors.keyDeliveryDate}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.keyDeliveryDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Pagamento affitto entro giorno
									</Form.Label>
									<Form.Control 
										{...register("dueDayForPayment"
										)}
										type="number"
										name="dueDayForPayment"
										value={data.dueDayForPayment}
										onChange={onChange}								
										isInvalid={!!errors.dueDayForPayment
										}
										placeholder="Inserisci un numero indicante il giorno"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.dueDayForPayment?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							
						</Row>

						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="4" activeKey={activeKey}>Cessione di fabbricato <OverlayTrigger
									placement="top"
									delay={{ show: 600, hide: 300 }}
									overlay={
										<Tooltip className='tooltipContabilita'>{infoHover}</Tooltip>
										}
										>
										<i class="bi bi-info-circle ms-2"></i>
									</OverlayTrigger></CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Data di consegna
								</Form.Label>
								<Form.Control
								{...register("buildingDeliveryDate")}
								type="date"
								name="buildingDeliveryDate"
								value={data.buildingDeliveryDate}
								onChange={onChange}
								isInvalid={!!errors.buildingDeliveryDate}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.buildingDeliveryDate?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Note sulla consegna
								</Form.Label>
								<Form.Control
								{...register("buildingDeliveryNote")}
								name="buildingDeliveryNote"
								onChange={onChange}
								value={data.buildingDeliveryNote}
								type="text"
								isInvalid={!!errors.buildingDeliveryNote}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.buildingDeliveryNote?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>

						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="5" activeKey={activeKey}>Garanzia </CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Tipo di garanzia
								</Form.Label>
								<Form.Select 
									{...register("warrantyType")}
									name="warrantyType"
									value={data.warrantyType}
									onChange={(e) => {
										props.onChange(e);
										setWarrantyTypeIndex(e.target.selectedIndex);
									}}
									isInvalid={!!errors.warrantyType}
								>
								{warranty_type.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														<span>{menu}</span>
													</option>
												);
											})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.warrantyType?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>
							{ warranty_type_index == 0 &&
								<Row>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo del deposito cauzionale in €
									</Form.Label>
									<Form.Control 
										{...register("warrantyDeposit")}
										type="number"
										name="warrantyDeposit"
										value={data.warrantyDeposit}						
										onChange={onChange}
										isInvalid={!!errors.warrantyDeposit}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Metodo di pagamento
											</Form.Label>
											<Form.Select 
												{...register("warrantyPaymentMethod")}
												name="warrantyPaymentMethod"
												value={data.warrantyPaymentMethod}
												onChange={onChange}
												isInvalid={!!errors.warrantyPaymentMethod}
											>
											{payment_method.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.warrantyPaymentMethod?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Interessi sul deposito cauzionale
											</Form.Label>
											<Form.Select 
												{...register("depositInterest")}
												name="depositInterest"
												value={data.depositInterest}
												onChange={onChange}
												isInvalid={!!errors.depositInterest}
											>
											{security_deposit_interest.map((menu, index) => {
														return (
															<option key={index} value={menu}>
																{menu}
															</option>
														);
													})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.depositInterest?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Data di consegna
												</Form.Label>
												<Form.Control
													{...register("warrantyDeliveryDate")}
													type="date"
													name="warrantyDeliveryDate"
													value={data.warrantyDeliveryDate}
													onChange={onChange}
													className
													isInvalid={!!errors.warrantyDeliveryDate}
												>
												</Form.Control>
												<Form.Control.Feedback type="invalid" >
												{errors.warrantyDeliveryDate?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantyInPrivateDocument")}
												name="warrantyInPrivateDocument"
												checked={data.warrantyInPrivateDocument}
												onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantySubjectToVat")}
												name="warrantySubjectToVat"
												checked={data.warrantySubjectToVat}
												onChange={onChange}label="Garanzia soggetta ad IVA" />
										</Form.Group>
									</Col>
									<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
							}
							{ warranty_type_index == 1 &&
								<Row>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo del deposito cauzionale in €
									</Form.Label>
									<Form.Control 
										{...register("warrantyDeposit")}
										type="number"
										name="warrantyDeposit"
										value={data.warrantyDeposit}						
										onChange={onChange}
										isInvalid={!!errors.warrantyDeposit}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Metodo di pagamento
											</Form.Label>
											<Form.Select 
												{...register("warrantyPaymentMethod")}
												name="warrantyPaymentMethod"
												value={data.warrantyPaymentMethod}
												onChange={onChange}
												isInvalid={!!errors.warrantyPaymentMethod}
											>
											{payment_method.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.warrantyPaymentMethod?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("borrowedAmount")}
												name="borrowedAmount"
												checked={data.borrowedAmount}
												onChange={onChange}label="Importo prestato da terzi" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Interessi sul deposito cauzionale
											</Form.Label>
											<Form.Select 
												{...register("depositInterest")}
												name="depositInterest"
												value={data.depositInterest}
												onChange={onChange}
												isInvalid={!!errors.depositInterest}
											>
											{security_deposit_interest.map((menu, index) => {
														return (
															<option key={index} value={menu}>
																{menu}
															</option>
														);
													})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.depositInterest?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Data di consegna
												</Form.Label>
												<Form.Control
													{...register("warrantyDeliveryDate")}
													type="date"
													name="warrantyDeliveryDate"
													value={data.warrantyDeliveryDate}
													onChange={onChange}
													className
													isInvalid={!!errors.warrantyDeliveryDate}
												>
												</Form.Control>
												<Form.Control.Feedback type="invalid" >
												{errors.warrantyDeliveryDate?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantyInPrivateDocument")}
												name="warrantyInPrivateDocument"
												checked={data.warrantyInPrivateDocument}
												onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
										</Form.Group>
									</Col>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo totale fideiussioni
									</Form.Label>
									<Form.Control 
										{...register("warrantyTotalAmount")}
										type="number"
										name="warrantyTotalAmount"
										value={data.warrantyTotalAmount}	
										onChange={onChange}
										isInvalid={!!errors.warrantyTotalAmount}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyTotalAmount?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<div className="mb-2">
										<span
											className="link-text"
											onClick={(e) => {
												setSuretyModalShow(true);
											}}>
											Elenco fideiussioni
										</span>
									</div>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantySubjectToVat")}
												name="warrantySubjectToVat"
												checked={data.warrantySubjectToVat}
												onChange={onChange}label="Garanzia soggetta ad IVA" />
										</Form.Group>
									</Col>
									<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
									</Col>
								</Row>
							}
							{ warranty_type_index == 2 &&
								<Row>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo del deposito cauzionale in €
									</Form.Label>
									<Form.Control 
										{...register("warrantyDeposit")}
										type="number"
										name="warrantyDeposit"
										value={data.warrantyDeposit}						
										onChange={onChange}
										isInvalid={!!errors.warrantyDeposit}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Metodo di pagamento
											</Form.Label>
											<Form.Select 
												{...register("warrantyPaymentMethod")}
												name="warrantyPaymentMethod"
												value={data.warrantyPaymentMethod}
												onChange={onChange}
												isInvalid={!!errors.warrantyPaymentMethod}
											>
											{payment_method.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.warrantyPaymentMethod?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("borrowedAmount")}
												name="borrowedAmount"
												checked={data.borrowedAmount}
												onChange={onChange}label="Importo prestato da terzi" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Interessi sul deposito cauzionale
											</Form.Label>
											<Form.Select 
												{...register("depositInterest")}
												name="depositInterest"
												value={data.depositInterest}
												onChange={onChange}
												isInvalid={!!errors.depositInterest}
											>
											{security_deposit_interest.map((menu, index) => {
														return (
															<option key={index} value={menu}>
																{menu}
															</option>
														);
													})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.depositInterest?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Data di consegna
												</Form.Label>
												<Form.Control
													{...register("warrantyDeliveryDate")}
													type="date"
													name="warrantyDeliveryDate"
													value={data.warrantyDeliveryDate}
													onChange={onChange}
													className
													isInvalid={!!errors.warrantyDeliveryDate}
												>
												</Form.Control>
												<Form.Control.Feedback type="invalid" >
												{errors.warrantyDeliveryDate?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantyInPrivateDocument")}
												name="warrantyInPrivateDocument"
												checked={data.warrantyInPrivateDocument}
												onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
										</Form.Group>
									</Col>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo garantito in €
									</Form.Label>
									<Form.Control 
										{...register("warrantyTotalAmount")}
										type="number"
										name="warrantyTotalAmount"	
										value={data.warrantyTotalAmount}
										onChange={onChange}
										isInvalid={!!errors.warrantyTotalAmount}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyTotalAmount?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantySubjectToVat")}
												name="warrantySubjectToVat"
												checked={data.warrantySubjectToVat}
												onChange={onChange}label="Garanzia soggetta ad IVA" />
										</Form.Group>
									</Col>
									<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
							}
							{ warranty_type_index == 3 &&
								<Row>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo del deposito cauzionale in €
									</Form.Label>
									<Form.Control 
										{...register("warrantyDeposit")}
										type="number"
										name="warrantyDeposit"
										value={data.warrantyDeposit}						
										onChange={onChange}
										isInvalid={!!errors.warrantyDeposit}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Metodo di pagamento
											</Form.Label>
											<Form.Select 
												{...register("warrantyPaymentMethod")}
												name="warrantyPaymentMethod"
												value={data.warrantyPaymentMethod}
												onChange={onChange}
												isInvalid={!!errors.warrantyPaymentMethod}
											>
											{payment_method.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.warrantyPaymentMethod?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("borrowedAmount")}
												name="borrowedAmount"
												checked={data.borrowedAmount}
												onChange={onChange}label="Importo prestato da terzi" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Interessi sul deposito cauzionale
											</Form.Label>
											<Form.Select 
												{...register("depositInterest")}
												name="depositInterest"
												value={data.depositInterest}
												onChange={onChange}
												isInvalid={!!errors.depositInterest}
											>
											{security_deposit_interest.map((menu, index) => {
														return (
															<option key={index} value={menu}>
																{menu}
															</option>
														);
													})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.depositInterest?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Data di consegna
												</Form.Label>
												<Form.Control
													{...register("warrantyDeliveryDate")}
													type="date"
													name="warrantyDeliveryDate"
													value={data.warrantyDeliveryDate}
													onChange={onChange}
													className
													isInvalid={!!errors.warrantyDeliveryDate}
												>
												</Form.Control>
												<Form.Control.Feedback type="invalid" >
												{errors.warrantyDeliveryDate?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantyInPrivateDocument")}
												name="warrantyInPrivateDocument"
												checked={data.warrantyInPrivateDocument}
												onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantySubjectToVat")}
												name="warrantySubjectToVat"
												checked={data.warrantySubjectToVat}
												onChange={onChange}label="Garanzia soggetta ad IVA" />
										</Form.Group>
									</Col>
									<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
							}
							{ warranty_type_index == 4 &&
								<Row>
									<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Importo del deposito cauzionale in €
									</Form.Label>
									<Form.Control 
										{...register("warrantyDeposit")}
										type="number"
										name="warrantyDeposit"
										value={data.warrantyDeposit}						
										onChange={onChange}
										isInvalid={!!errors.warrantyDeposit}
										placeholder="Inserisci un numero"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Metodo di pagamento
											</Form.Label>
											<Form.Select 
												{...register("warrantyPaymentMethod")}
												name="warrantyPaymentMethod"
												value={data.warrantyPaymentMethod}
												onChange={onChange}
												isInvalid={!!errors.warrantyPaymentMethod}
											>
											{payment_method.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.warrantyPaymentMethod?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("borrowedAmount")}
												name="borrowedAmount"
												checked={data.borrowedAmount}
												onChange={onChange}label="Importo prestato da terzi" />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-3" >
											<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
											>
											Interessi sul deposito cauzionale
											</Form.Label>
											<Form.Select 
												{...register("depositInterest")}
												name="depositInterest"
												value={data.depositInterest}
												onChange={onChange}
												isInvalid={!!errors.depositInterest}
											>
											{security_deposit_interest.map((menu, index) => {
														return (
															<option key={index} value={menu}>
																{menu}
															</option>
														);
													})}
											</Form.Select>
											<Form.Control.Feedback type="invalid" >
											{errors.depositInterest?.message}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
											<Form.Group className="mb-3" >
												<Form.Label 
													className="f-label fs-16 d-flex align-items-center
mb-2"
												>
													Data di consegna
												</Form.Label>
												<Form.Control
													{...register("warrantyDeliveryDate")}
													type="date"
													name="warrantyDeliveryDate"
													value={data.warrantyDeliveryDate}
													onChange={onChange}
													className
													isInvalid={!!errors.warrantyDeliveryDate}
												>
												</Form.Control>
												<Form.Control.Feedback type="invalid" >
												{errors.warrantyDeliveryDate?.message}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantyInPrivateDocument")}
												name="warrantyInPrivateDocument"
												checked={data.warrantyInPrivateDocument}
												onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
										</Form.Group>
									</Col>
									<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("warrantySubjectToVat")}
												name="warrantySubjectToVat"
												checked={data.warrantySubjectToVat}
												onChange={onChange}label="Garanzia soggetta ad IVA" />
										</Form.Group>
									</Col>
									<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
							}
							{ warranty_type_index == 5 &&
								<Row>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantyInPrivateDocument")}
											name="warrantyInPrivateDocument"
											checked={data.warrantyInPrivateDocument}
											onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
									</Form.Group>
								</Col>
								<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Importo totale fideiussioni
								</Form.Label>
								<Form.Control 
									{...register("warrantyTotalAmount")}
									type="number"
									name="warrantyTotalAmount"
									value={data.warrantyTotalAmount}	
									onChange={onChange}
									isInvalid={!!errors.warrantyTotalAmount}
									placeholder="Inserisci un numero"
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.warrantyTotalAmount?.message}
								</Form.Control.Feedback>
							</Form.Group>
								</Col>
								<div className="mb-2">
									<span
										className="link-text"
										onClick={(e) => {
											setSuretyModalShow(true);
										}}>
										Elenco fideiussioni
									</span>
								</div>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantySubjectToVat")}
											name="warrantySubjectToVat"
											checked={data.warrantySubjectToVat}
											onChange={onChange}label="Garanzia soggetta ad IVA" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
							}
							{ warranty_type_index == 6 &&
								<Row>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantyInPrivateDocument")}
											name="warrantyInPrivateDocument"
											checked={data.warrantyInPrivateDocument}
											onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
									</Form.Group>
								</Col>
								<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Importo garantito
								</Form.Label>
								<Form.Control 
									{...register("warrantyTotalAmount")}
									type="number"
									name="warrantyTotalAmount"
									value={data.warrantyTotalAmount}	
									onChange={onChange}
									isInvalid={!!errors.warrantyTotalAmount}
									placeholder="Inserisci un numero"
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.warrantyTotalAmount?.message}
								</Form.Control.Feedback>
							</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantySubjectToVat")}
											name="warrantySubjectToVat"
											checked={data.warrantySubjectToVat}
											onChange={onChange}label="Garanzia soggetta ad IVA" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
							}
							{warranty_type_index == 7 && (
								<>
								<Row>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantyInPrivateDocument")}
											name="warrantyInPrivateDocument"
											checked={data.warrantyInPrivateDocument}
											onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
									</Form.Group>
								</Col>
								<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Importo garantito
								</Form.Label>
								<Form.Control 
									{...register("warrantyTotalAmount")}
									type="number"
									name="warrantyTotalAmount"
									value={data.warrantyTotalAmount}	
									onChange={onChange}
									isInvalid={!!errors.warrantyTotalAmount}
									placeholder="Inserisci un numero"
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.warrantyTotalAmount?.message}
								</Form.Control.Feedback>
							</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantySubjectToVat")}
											name="warrantySubjectToVat"
											checked={data.warrantySubjectToVat}
											onChange={onChange}label="Garanzia soggetta ad IVA" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
								</>
							)}
							{warranty_type_index == 8 && (
								<>
								<Row>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantyInPrivateDocument")}
											name="warrantyInPrivateDocument"
											checked={data.warrantyInPrivateDocument}
											onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantySubjectToVat")}
											name="warrantySubjectToVat"
											checked={data.warrantySubjectToVat}
											onChange={onChange}label="Garanzia soggetta ad IVA" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
								</>
							)}
							{warranty_type_index == 9 && (
								<>
								<Row>
								<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Importo depositato
								</Form.Label>
								<Form.Control 
									{...register("warrantyTotalAmount")}
									type="number"
									name="warrantyTotalAmount"
									value={data.warrantyTotalAmount}	
									onChange={onChange}
									isInvalid={!!errors.warrantyTotalAmount}
									placeholder="Inserisci un numero"
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.warrantyTotalAmount?.message}
								</Form.Control.Feedback>
							</Form.Group>
								</Col>
								<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("borrowedAmount")}
												name="borrowedAmount"
												checked={data.borrowedAmount}
												onChange={onChange}label="Importo prestato da terzi" />
										</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("generateIndependentFee")}
											name="generateIndependentFee"
											checked={data.generateIndependentFee}
											onChange={onChange}
											label="Genera una rata separata per il deposito cauzionale" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantySubjectToVat")}
											name="warrantySubjectToVat"
											checked={data.warrantySubjectToVat}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
								</>
							)}
							{warranty_type_index == 11 && (
								<>
								<Row>
								<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Importo dei diritti soggetti all’aiuto
								</Form.Label>
								<Form.Control 
									{...register("warrantyTotalAmount")}
									type="number"
									name="warrantyTotalAmount"
									value={data.warrantyTotalAmount}	
									onChange={onChange}
									isInvalid={!!errors.warrantyTotalAmount}
									placeholder="Inserisci un numero"
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.warrantyTotalAmount?.message}
								</Form.Control.Feedback>
							</Form.Group>
								</Col>
								<Col md={12}>
										<Form.Group className="mb-3" controlId="formBasicCheckbox">
											<Form.Check type="checkbox"
												{...register("borrowedAmount")}
												name="borrowedAmount"
												checked={data.borrowedAmount}
												onChange={onChange}label="Importo prestato da terzi" />
										</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantyInPrivateDocument")}
											name="warrantyInPrivateDocument"
											checked={data.warrantyInPrivateDocument}
											onChange={onChange}label="Garanzia terzi presente in una scrittura privata" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("warrantySubjectToVat")}
											name="warrantySubjectToVat"
											checked={data.warrantySubjectToVat}
											onChange={onChange}label="Garanzia soggetta ad IVA" />
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="formBasicCheckbox">
										<Form.Check type="checkbox"
											{...register("voluntaryPenaltyClause")}
											name="voluntaryPenaltyClause"
											checked={data.voluntaryPenaltyClause}
											onChange={onChange}label="Clausola pena volontaria" />
									</Form.Group>
								</Col>
								</Row>
								</>
							)}
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="6" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="6" activeKey={activeKey}>Pagamento</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Canone su base
								</Form.Label>
								<Form.Select 
									{...register("feePeriod")}
									name="feePeriod"
									value={data.feePeriod}
									onChange={onChange}
									isInvalid={!!errors.feePeriod}
								>
								{base_fee.map((menu, index) => {
									return (
										<option key={index} value={index}>
											{menu}
										</option>
									);
								})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.base_fee?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Cadenza delle rate
								</Form.Label>
								<Form.Select 
									{...register("feeFrequency")}
									name="feeFrequency"
									value={data.feeFrequency}
									onChange={onChange}
									isInvalid={!!errors.feeFrequency}
								>
								{frequency.map((menu, index) => {
									return (
										<option key={index} value={index}>
											{menu}
										</option>
									);
								})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.feeFrequency?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Modalità di pagamento preferita
								</Form.Label>
								<Form.Select 
									{...register("favoritePaymentMethod")}
									name="favoritePaymentMethod"
											value={data.favoritePaymentMethod}
											onChange={(e) => {
												props.onChange(e);
												setPrefferedPaymentMethodId(e.target.selectedIndex);
											}}
									isInvalid={!!errors.favoritePaymentMethod}
								>
							{preffered_payment_method.map((menu, index) => {
												return (
													<option key={index} value={index}>
														{menu}
													</option>
												);
											})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.favoritePaymentMethod?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Tipo di notifica dei pagamenti al locatore
								</Form.Label>
								<Form.Select 
									{...register("notificationType")}
									name="notificationType"
											value={data.notificationType}
											onChange={onChange}
									isInvalid={!!errors.notificationType}
								>
							{notification_type.map((menu, index) => {
												return (
													<option key={index} value={index}>
														{menu}
													</option>
												);
											})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.notificationType?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>
						
									<div className="col-md-6">
										<div className="d-flex flex-row align-items-end">
											<div className="d-flex flex-column flex-auto">
												<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-1">
													Sul conto
												</label>
												<select
											name="bankAccount"
											value={JSON.stringify(data.bankAccount)}
											onChange={handleAccount}
											>
											<option></option>
											<option value={JSON.stringify(data.bankAccount)}>
											{data.bankAccount?.name} </option>
											{contiArr.length> 0 && contiArr.map((el, index) => {
												return (
												<option key={index} value={JSON.stringify(el)}>
													{el.name}
												</option>
												);
											})}
											</select>
											</div>
											
										</div>
									</div>
								{/* {(preffered_payment_method_id == 7 ||
								preffered_payment_method_id == 8 ||
								preffered_payment_method_id == 9) && (
								<div className="row my-3">
									<div className="col-md-6">
										<div className="d-flex flex-row align-items-end">
											<div className="d-flex flex-column flex-auto">
												<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
													Sul conto
												</label>
												<select
													name="a"
													value={data.a}
													onChange={onChange}
													>
													{account.map((menu, index) => {
														return (
															<option key={index} value={index}>
																{menu}
															</option>
														);
													})}
												</select>
											</div>
											
										</div>
									</div>
									{preffered_payment_method_id == 9 && (
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Codice cliente debitore
										</label>
										<input
											name="debtorClientCode"
											value={data.debtorClientCode}
											onChange={onChange}
											type="text"

										/>
									</div>
								</div>
							)}
								</div>
							)} */}
						</Accordion.Body>
					</Accordion.Item>
					
					<Accordion.Item eventKey="7" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="7" activeKey={activeKey}>Importi <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
<OverlayTrigger
									placement="top"
									delay={{ show: 600, hide: 300 }}
									overlay={
										<Tooltip className='tooltipContabilita'>Utilizziamo il dato relativo al canone per calcolare le imposte di registro dovute</Tooltip>
										}
										>
										<i class="bi bi-info-circle ms-2"></i>
									</OverlayTrigger></CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row className="d-flex align-items-center ">
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Canone attuale (mensile) <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Control 
										{...register("currentMonthlyFee")}
										type="number"
										name="currentMonthlyFee"
										value={data.currentMonthlyFee}	onChange={onChange}
										isInvalid={!!errors.currentMonthlyFee}

									/>
									<Form.Control.Feedback type="invalid" >
									{errors.cancellationRenewalForOwner?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>  
							{/* BTN + TABELLA QUI IN VIEW */}  
							<Col md={6} className="mt-2">
								<ButtonRm variant="secondary" onClick={handleCanoneModal}>Calcola adeguamento ISTAT</ButtonRm>
							</Col>                
						</Row>
						<Row>              
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Spese forfettarie attuali (mensile)
									</Form.Label>
									<Form.Control 
										{...register("currentMonthlyRateExpenses")}
										type="number"
										name="currentMonthlyRateExpenses"
										value={data.currentMonthlyRateExpenses}
										onChange={onChange}
										isInvalid={!!errors.currentMonthlyRateExpenses}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.currentMonthlyRateExpenses?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Anticipo oneri accessori (mensile)
									</Form.Label>
									<Form.Control 
										{...register("advanceCharges")}
										type="number"
										name="advanceCharges"
										value={data.advanceCharges}
										onChange={onChange}
										isInvalid={!!errors.advanceCharges}
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.advanceCharges?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Gestione lettura contatori
									</Form.Label>
									<Form.Select 
										{...register("readCounterManagement")}
										name="readCounterManagement"
										value={data.readCounterManagement}
										onChange={onChange}
										isInvalid={!!errors.managementType}
									>
									{meter_management.map((menu, index) => {
										return (
											<option key={index} value={index}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.readCounterManagement?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								Note relative ai contatori
								</Form.Label>
								<Form.Control
								{...register("counterNote")}
								type="text"
								name="counterNote"
								value={data.counterNote}
								onChange={onChange}
								isInvalid={!!errors.counterNote}
								>
								</Form.Control>
								<Form.Control.Feedback type="invalid" >
								{errors.counterNote?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>
						<Row>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("haveAdjustmentOnTurnover")}
										name="haveAdjustmentOnTurnover"
										checked={data.haveAdjustmentOnTurnover}
										onChange={onChange} 
										label="Conguaglio su fatturato conduttore" />
								</Form.Group>
							</Col>
						</Row>
						{props.formData.haveAdjustmentOnTurnover &&
							<Row>
						<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Soglia di fatturato
								</Form.Label>
								<Form.Control 
									{...register("turnoverThreshold")}
									type="number"
									name="turnoverThreshold"
									value={data.turnoverThreshold}
									onChange={onChange}
									isInvalid={!!errors.turnoverThreshold}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.turnoverThreshold?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
							</Row>
						}			
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="8" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="8" activeKey={activeKey}>Fatturazione</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("subjectToInvoice")}
										name="subjectToInvoice"
										checked={data.subjectToInvoice}
										onChange={onChange}
										label="Soggetto a fattura" />
								</Form.Group>
							</Col>
						</Row>
						{ props.formData.subjectToInvoice &&
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
								>
								IVA canone
								</Form.Label>
								<Form.Select 
									{...register("vatCanon")}
									name="vatCanon"
									value={data.vatCanon}
									onChange={onChange}
									isInvalid={!!errors.vatCanon}
								>
								{vat_fee_type.map((menu, index) => {
									return (
										<option key={index} value={index}>
											{menu}
										</option>
									);
								})}
								</Form.Select>
								<Form.Control.Feedback type="invalid" >
								{errors.vatCanon?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							
						</Row>}
						</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="9" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="9" activeKey={activeKey}>Aggiornamento ISTAT</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
								Aggiornamento a partire dal
									</Form.Label>
									<Form.Select 
										{...register("istatUpdateFrom")}
										name="istatUpdateFrom"
										value={data.istatUpdateFrom}
										onChange={onChange}
										isInvalid={!!errors.istatUpdateFrom}
									>
									{update.map((menu, index) => {
										return (
											<option key={index} value={index}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.istatUpdateFrom?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
								Percentuale ISTAT
									</Form.Label>
									<Form.Select 
										{...register("istat")}
										name="istat"
										value={data.istat}
										onChange={onChange}
										isInvalid={!!errors.istat}
									>
									{istat.map((menu, index) => {
												return (
													<option key={index} value={index}>
														{menu}
													</option>
												);
											})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.istat?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							
						</Row>
						</Accordion.Body>
					</Accordion.Item>


				</Accordion>

			</div>
			<fieldset disabled={props.edit_mode ? false : true}>
				
				
				{/* <div className="d-flex justify-content-end mt-5">
						<ButtonRm
						variant="tertiary"
						onClick={() => props.backward()}
						>
						Indietro
						</ButtonRm>

						<ButtonRm
							variant="primary"
							className="ms-2"
							type="submit"
						>
							Avanti
						</ButtonRm>
				</div> */}
			</fieldset>
			</Form>
			<Modal
					show={surety_modal_show}
					fullscreen={true}
					className="right-full-pop"
					onHide={() => setSuretyModalShow(false)}>
					<Modal.Body className="p-4">
						<Button
							variant=""
							onClick={(e) => setSuretyModalShow(false)}
							className="float-end border-0 bg-white">
							<i className="bi bi-x"></i>
						</Button>
						<SuretyModal />
					</Modal.Body>
			</Modal>
			<Modal
				show={account_modal_show}
				fullscreen={true}
				className="right-full-pop"
				onHide={() => setAccountModalShow(false)}
				centered>
				<Modal.Header closeButton>
					Calcola adeguamento ISTAT
				</Modal.Header>
				<Modal.Body className="p-4">
					<Button
						variant=""
						onClick={(e) => setAccountModalShow(false)}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<AccountModal
						setShow={setAccountModalShow}
						edit_mode={props.edit_mode}
					/>
				</Modal.Body>
			</Modal>


			<Modal show={newCanoneModal}
				onHide={handleCanoneModal}
				className="p-5" centered >
				<Modal.Header
					closeButton
					style={{ borderBottom: "none" }}
					className=" pt-md-5 px-md-5"
				>
					<Modal.Title>Calcola adeguamento ISTAT</Modal.Title>
				</Modal.Header>
				{
					canoneAgg ?
					<Modal.Body className="p-3 px-md-5">
						<Table striped bordered className="table-bordered">
							<tbody>
								<tr>
								<td className="border">Canone attuale</td>
								<td className="border">{canoneData.amount}</td>
								</tr>
								<tr>
								<td className="border">Periodo di decorrenza</td>
								<td className="border">{datePart}</td>
								</tr>
								<tr>
								<td className="border">Percentuale ISTAT</td>
								<td className="border">{canoneData.percentage} %</td>
								</tr>
								<tr>
								<td className="border">Indice Istat {datePart}</td>
								<td className="border">{currentIndex?.value}</td>
								</tr>
								{/* <tr>
								<td className="border">Indice Istat Dicembre 2020</td>
								<td className="border">4,9%</td>
								</tr>
								<tr>
								<td className="border">Variazione % dell’indice</td>
								<td className="border">0,9%</td>
								</tr>
								<tr> */}
								<tr>
								<td className="border">Importo rivalutazione</td>
								<td className="border">{(canoneAgg - canoneData.amount).toFixed(2)}</td>
								</tr>
								<tr>
								<td className="border fw-bold">Canone rivalutato</td>
								<td className="border fw-bold">{canoneAgg}</td>
								</tr>
							</tbody>
							</Table>
					</Modal.Body>:
					<Modal.Body className="p-3 px-md-5">
						<Form>
						<Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
							<Form.Label>Canone mensile</Form.Label>
							<input
							type="number"		
							onChange={handleCanoneData}
							value={canoneData.amount}
							placeholder="Per esempio: 500"
							name="amount"
							/>
						</Form.Group>
						<Form.Group className="mb-3 d-flex flex-column" controlId="gestioneName">
							<Form.Label>Percentuale ISTAT (%)</Form.Label>
							<input
							type="number"
							name="percentage"		
							onChange={handleCanoneData}
							value={canoneData.percentage}
							
							/>
						</Form.Group>

						<Form.Group className="mb-3 d-flex flex-column" >
										<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
										>
										Periodo di decorrenza
										</Form.Label>
										<Select
											className="basic-single "
											classNamePrefix="select"
											value={currentIndex}
											isSearchable={isSearchable}
											name="foiIndex"
											options={selectOptions}
											placeholder="Seleziona un'opzione"
											noOptionsMessage={() => "Nessuna opzione trovata"}
											onChange={handleCurrentIndex}
											/>
									</Form.Group>
						</Form>
					</Modal.Body>
				}
				{
					canoneAgg ?	
				<Modal.Footer
					className="d-flex flex-column  px-md-5 pb-md-4"
					style={{ borderTop: "none" }}
				>
					<ButtonRm variant="primary" onClick={() => {
						setData(prev => ({...prev, currentMonthlyFee: canoneAgg}))
						handleCanoneModal()
						}
					}>
					Aggiorna Canone
					</ButtonRm>
					<ButtonRm variant="tertiary" onClick={handleCanoneModal}>
					Annulla
					</ButtonRm>
				</Modal.Footer>: 
				<Modal.Footer
				className="d-flex flex-column  px-md-5 pb-md-4"
				style={{ borderTop: "none" }}
				>
					<ButtonRm variant="primary" onClick={onCalclicked} disabled={currentIndex?.value && canoneData.amount && canoneData.percentage ? false : true}>
					Calcola
					</ButtonRm>
				</Modal.Footer> 
				}
        </Modal>
		</div>
		</>
	);
};

export default RegistryDataE;
