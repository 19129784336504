import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import MobileTableCard from "./MobileTableCard";

export default function ConductorModal(props) {
  const table_header = [
    "Nome",
    "Cognome",
    "Indirizzo",
    "Civico",
    "Interno",
    "Comune",
    "Prov",
  ];
  const [table_select, setTableSelect] = useState(-1);
  const [search_name, setSearchName] = useState("");
  const addConductor = () => {
    if (table_select === -1) return;
    props.addConductor(props.ids[table_select]);
    props.setShow(false);
  };

  const setId = () => {
    //////template////////
    if (typeof props.addFunc === "function") {
      props.addFunc();
      props.setShow(false);
    } else {
      let temp_data = props.conductor_data;
      temp_data.table_id = table_select;
      props.setShow(false);
    }
  };
  return (
    <>
      <div className="d-block w-100 mb-3 mt-4">
        <div>
          <h2 className="fs-20 text-dark mb-2 fw-bold">
            Seleziona uno o più conduttori
          </h2>
        </div>
      </div>
      <Form>
        <div className="mobile fixed-wrapper">
          <button
            type="button"
            className={`btn-last rounded-3 shadow border-0 fixed-btn
              ${table_select >= 0 ? "next-btn" : "disable"}`}
            disabled={table_select >= 0 ? false : true}
            onClick={setId}
          >
            Salva
          </button>
        </div>
        <div
          
        >
          <div className="modal-search">
            <div className="search-fence d-flex align-items-center bg-white p-2 border-opacity-50 border-secondary border rounded-3 overflow-hidden">
              <i className="fal fa-search me-2 text-secondary opacity-50"></i>
              <input
                type="text"
                name=""
                placeholder="Cerca"
                className="flex-grow-1 border-0 w-100 fs-14"
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="desktop-table">
            <Table className="mt-4 table-border">
              <thead>
                <tr>
                  {table_header.map((item, index) => {
                    return <th key={index}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {props.table_data &&
                  props.table_data.map((data, index) => {
                    if (
                      data[1] &&
                      data[1]
                        .toLowerCase()
                        .indexOf(search_name.toLowerCase()) !== -1
                    ) {
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            if (table_select !== index) {
                              setTableSelect(index);
                            } else setTableSelect(-1);
                          }}
                          className={table_select !== index ? "" : "table_tr"}
                        >
                          {data.map((sub_data, id) => {
                            return <td key={id}>{sub_data? sub_data : ""}</td>;
                          })}
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </div>
          <div className="mobile-table">
            {props.table_data &&
              props.table_data.map((item, index) => {
                return (
                  <MobileTableCard
                    table_header={table_header}
                    table_data={item}
                    key={index}
                    id={index}
                    title={item[0] + " " + item[1]}
                    setTableSelect={setTableSelect}
                  />
                );
              })}
          </div>
          <div className="col-12 desktop">
            <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
              <button
                type="button"
                className="back-btn btn-last rounded-3 shadow w-193 me-2 border-0"
                onClick={() => {
                  props.setShow(false);
                }}
              >
                Annulla
              </button>
              <button
                type="button"
                className={`btn-last rounded-3 shadow w-193 border-0
          ${table_select >= 0 ? "next-btn" : "disable"}`}
                disabled={table_select >= 0 ? false : true}
                onClick={addConductor}
              >
                Salva
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
