import React from "react";
import "../assets/css/Footer.css";
import Logo from "../assets/images/logo-bianco.svg";
import FacebookIcon from "../assets/images/facebook.svg";
import TwitterIcon from "../assets/images/twitter.svg";
import InstagramIcon from "../assets/images/linkedin_icon 1.svg";
import UpperArrow from "../assets/images/freccia-su.svg";
import { Link } from "react-router-dom";

import { useState } from 'react';
import { Row, Col, Navbar, Image, Modal, Button } from 'react-bootstrap';


import Accordion from 'react-bootstrap/Accordion';


export default function Footer() {

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [short, setShort] = useState(true)

  const handleShowModal = (title) => {
      setModalContent(title);
      setShowModal(true);
  };

  function changePrivacy() {
      setShort(prev => !prev)
  }

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="footer">
      <div className="footer-container">
        <div>
          <img
            src={Logo}
            alt="logo"
            style={{ width: "80px", height: "40px" }}
          ></img>
          <p className="footer-logo-description fs-10">
            Designed by Anthill <br/>Tutti i diritti sono riservati
          </p>
        </div>
        <div className="footer-description">
          <div className="main-description mb-2">Informative Legali</div>
            <div className='d-flex flex-md-row mb-3 text-light'>
                <Link onClick={() => handleShowModal('Termini e Condizioni')} className="me-2 text-light" style={{cursor: 'pointer'}}>Termini e condizioni</Link> 
                <Link   onClick={() => handleShowModal('Informativa Privacy')}  className="ms-2 me-2 text-light" style={{cursor: 'pointer'}}>Informativa privacy</Link>   
                <Link  onClick={() => handleShowModal('Informativa Cookie')} className="ms-2 me-2 text-light"  style={{cursor: 'pointer'}} id='cookie'>Informativa cookie</Link>   
            </div>
        </div>
        <div className="footer-link-div">
          <div className="socialLink-div text-start d-flex">
            <a href="https://www.linkedin.com/company/anthillgroup/" target="_blank"><img loading="lazy" src={InstagramIcon} alt="instagram" ></img></a>
          </div>
          <img
            src={UpperArrow}
            alt="upperArrow"
            style={{ marginRight: "25px", width: "50px", height: "50px", cursor: "pointer" }}
            onClick={() => window.scrollTo(0, 0)}
          ></img>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} dialogClassName="custom-modal-footer" centered>
                <Modal.Header closeButton>
                    <Modal.Title>            <h6 className="fw-bold">{modalContent} di realmeta.it</h6>
</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalContent == 'Termini e Condizioni' && <Terms/>
                    }
                    {
                        modalContent == 'Informativa Cookie' && <Cookies />
                    }

                    {
                        (modalContent == 'Informativa Privacy' && short) &&
                        <PrivacyShort /> 
                    }

                    {
                        (modalContent == 'Informativa Privacy' && !short) &&
                        <PrivacyFull />
                    }
                    
                   
                </Modal.Body>
                <Modal.Footer>
                    <p className='me-auto'> Ultima modifica:  10 gennaio 2024</p>
                    {modalContent == 'Informativa Privacy' && <Button variant="secondary" onClick={changePrivacy}>
                        Versione {short? 'completa' : 'semplificata'}
                    </Button>}
                </Modal.Footer>
            </Modal>
    </div>
  );
}



function AccordionEl()  {
  return (
      <Accordion defaultActiveKey="0" id="privacyAccordion">
          <Accordion.Item eventKey="0">
          <Accordion.Header>Definizioni e riferimenti legali</Accordion.Header>
          <Accordion.Body>
          <strong>Dati Personali (o Dati)</strong>
                                              <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
                          
                                          <strong>Dati di Utilizzo</strong>
                                  <p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>
                          
                                  <strong>Utente</strong>
                                  <p>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato. </p>
                          
                                  <strong>Interessato</strong>
                                  <p>La persona fisica cui si riferiscono i Dati Personali.</p>
                          
                                  <strong>Responsabile del Trattamento (o Responsabile)</strong>
                                  <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
                          
                                  <strong>Titolare del Trattamento (o Titolare)</strong>
                                  <p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.</p>
                          
                                  <strong>Questa Applicazione</strong>
                                  <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
                          
                                      <strong>Servizio</strong>
                                  <p>Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
                          
                                      <strong>Unione Europea (o UE)</strong>
                                  <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.</p>
                          
                                      <strong>Riferimenti legali</strong>
                                  <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
          </Accordion.Body>
    </Accordion.Item>


      </Accordion>
  );
};



function Terms() {
  return (
      
      <div>
<p>Questi Termini disciplinano</p>
<ul>
<li>
<p>● l&rsquo;utilizzo di questa Applicazione</p>
</li>
<li>
<p>● qualsiasi altro Accordo o rapporto giuridico con il Titolare in maniera vincolante</p>
</li>
</ul>
<p> Le espressioni con l&rsquo;iniziale maiuscola sono definite nella relativa sezione di questo documento.</p>
<p>L&rsquo;Utente &egrave; pregato di leggere attentamente questo documento.</p>
<p>Nonostante il rapporto contrattuale relativo all&rsquo;acquisto di tali Prodotti sia concluso esclusivamente tra Titolare e Utenti, gli Utenti riconoscono e accettano che, qualora la fornitura di questa Applicazione sia avvenuta tramite l'Apple App Store, Apple potrebbe esercitare diritti derivanti da questi Termini in qualit&agrave; di terzo beneficiario.</p>
<p>Questa Applicazione &egrave; un servizio di:</p>
<ul className='fw-bold'>
<p className='fw-bold'>Anthill S.r.l.s.<br />Via Colombo, 14/3<br />16121 Genova (GE) - Italy<br />P.Iva 02641290990</p>
</ul>
<p>Indirizzo email del Titolare: <strong>info@anthillgroup.it</strong></p>
<p>Pec: <strong>anthil@pec-legal.it</strong></p>


<p>I seguenti documenti sono integrati nei Termini per rinvio nei termini sottostanti:</p>
<br/>
<h5 className='fw-bold'>Da sapere a colpo d&rsquo;occhio</h5>
<ul>
<li>
<p>● Si fa presente che determinate disposizioni di questi Termini potrebbero essere solo applicabili ad alcune categorie di Utenti. In particolare, alcune disposizioni potrebbero applicarsi solo a Consumatori o solo a Utenti che non agiscono come Consumatori. Tali limitazioni sono sempre menzionate esplicitamente in ciascuna clausola interessata. In caso di mancata menzione, le clausole si applicano a tutti gli Utenti.</p>
</li>
<li>
<p>● L'utilizzo di questa Applicazione e del Servizio &egrave; riservato a Utenti maggiorenni ai sensi della legge applicabile.</p>
</li>
<li>
<p>● Gli abbonamenti a Prodotti offerti su questa Applicazione sono soggetti a rinnovo automatico. Le informazioni su: <br/>
a) durata del periodo di rinnovo, <br/> 
b) modalit&agrave; di disdetta <br/>
c) termine di preavviso sono indicate nelle rispettive sezioni di questi Termini.</p>
<br/>
</li>
</ul>
<br/>
<h5 className='fw-bold'>Come funziona questa Applicazione</h5>
<p>Ferma restando la sua funzione &ndash; sopra descritta - di mero intermediario tecnologico, il Titolare potrebbe offrire ulteriori servizi a ciascun soggetto coinvolto in tali interazioni, come ad esempio la fornitura di imballaggi, servizi di consegna o la risoluzione di controversie.</p>
<p>Questa Applicazione funge quale infrastruttura tecnica che permette agli Utenti di interagire tra di loro. Il Titolare non &egrave; pertanto direttamente coinvolto in alcuna di tali interazioni tra Utenti.</p>
<br/>
<h5 className='fw-bold'>Condizioni d'uso</h5>
<p>Salvo ove diversamente specificato, le condizioni d&rsquo;uso di questa Applicazione esposte in questa sezione hanno validit&agrave; generale.</p>
<p>Ulteriori condizioni d&rsquo;uso o d&rsquo;accesso applicabili in particolari situazioni sono espressamente indicate in questo documento.</p>
<p>Utilizzando questa Applicazione l&rsquo;Utente dichiara di soddisfare i seguenti requisiti:</p>
<ul>

<li>
<p>● L&rsquo;Utente &egrave; maggiorenne ai sensi della legge applicabile;</p>
</li>
<li>
<p>● L&rsquo;Utente non &egrave; situato in un paese soggetto ad embargo governativo da parte degli Stati Uniti d&rsquo;America o in un paese inserito nella lista degli stati che sponsorizzano il terrorismo da parte del Governo degli Stati Uniti d&rsquo;America;</p>
</li>
<li>
<p>● L&rsquo;Utente non &egrave; inserito in alcuna lista di contraenti non ammessi da parte del Governo degli Stati Uniti d&rsquo;America;</p>
</li>
</ul>
<strong>Registrazione</strong>
<p>Per usufruire del Servizio l&rsquo;Utente pu&ograve; aprire un account indicando tutti i dati e le informazioni richieste in maniera completa e veritiera.<br />Non &egrave; possibile usufruire del Servizio senza aprire un account Utente.</p>
<p>&Egrave; responsabilit&agrave; degli Utenti conservare le proprie credenziali d&rsquo;accesso in modo sicuro e preservarne la confidenzialit&agrave;. <br/> 
A tal fine, gli Utenti devono scegliere una password che corrisponda al pi&ugrave; alto livello di sicurezza disponibile su questa applicazione.</p>
<p>Creando un account l&rsquo;Utente accetta di essere pienamente responsabile di ogni attivit&agrave; posta in atto con le sue credenziali d&rsquo;accesso. Gli Utenti sono tenuti a informare il Titolare immediatamente e univocamente tramite i recapiti indicati in questo documento qualora ritengano che le proprie informazioni personali, quali ad esempio l&rsquo;account Utente, le credenziali d&rsquo;accesso o dati personali, siano state violate, illecitamente diffuse o sottratte.</p>
<br/>
<strong>Requisiti per la registrazione</strong>
<p>La registrazione di un account Utente su questa Applicazione &egrave; soggetta alle condizioni sotto specificate. <br/>
Registrando un account, l&rsquo;Utente conferma di soddisfare tali condizioni.</p>
<ul>
<li>
<p>● L&rsquo;apertura di account tramite bot o altri mezzi automatizzati non &egrave; ammessa.</p>
</li>
<li>
<p>● Salvo ove diversamente specificato, ciascun Utente pu&ograve; creare un solo account.</p>
</li>
<li>
<p>● Salvo ove espressamente consentito, l&rsquo;account di un Utente non pu&ograve; essere condiviso con altre persone.</p>
</li>
</ul>
<strong>Chiusura account</strong>
<p>L&rsquo;Utente &egrave; libero di chiudere il proprio account e cessare l&rsquo;utilizzo del Servizio in qualsiasi momento, seguendo questa procedura:</p>
<ul>
<li>
<p>● Contattando il Titolare ai recapiti in questo documento tramite pec.</p>
</li>
</ul>
<p>La chiusura dell&rsquo;account Utente sar&agrave; tuttavia sospesa fino alla scadenza di eventuali servizi a pagamento in abbonamento acquistati dall&rsquo;Utente.</p>
<br />
<strong>Sospensione e cancellazione account</strong>
<p>Il Titolare si riserva il diritto di sospendere o cancellare l&rsquo;account di un Utente in qualsiasi momento a propria discrezione e senza preavviso nei seguenti casi:</p>
<ul>
<li>
<p>● l&rsquo;Utente ha violato i Termini e condizioni qui esposte; e/o</p>
</li>
<li>
<p>● l&rsquo;accesso a questa Applicazione da parte dell&rsquo;Utente pu&ograve; causare pregiudizio al Titolare, ad altri Utenti o a terzi; e/o</p>
</li>
<li>
<p>● l&rsquo;uso di questa Applicazione da parte dell&rsquo;Utente pu&ograve; determinare violazione di leggi o regolamenti; e/o</p>
</li>
<li>
<p>● in caso di indagini della magistratura o procedure governative; e/o</p>
</li>
<li>
<p>● qualora l&rsquo;account Utente o l&rsquo;utilizzo che ne viene fatto siano considerati, ad esclusiva discrezione del Titolare, inopportuni, offensivi o contrari a questi Termini.</p>
</li>
</ul>
<p>La sospensione o cancellazione dell&rsquo;account non da all&rsquo;Utente alcun diritto di risarcimento, rimborso o indennizzo.<br />La sospensione o cancellazione di un account per cause addebitabili all&rsquo;Utente non esonera l&rsquo;Utente dal pagamento dei compensi o prezzi eventualmente applicabili.</p>
<br/>
<h5 className='fw-bold'>Contenuti su questa Applicazione</h5>
<p>Salvo ove diversamente specificato o chiaramente riconoscibile, tutti i contenuti disponibili su questa Applicazione sono di propriet&agrave; del o forniti dal Titolare o dei/dai suoi licenzianti.</p>
<p>Il Titolare adotta la massima cura affinch&eacute; il contenuto disponibile su questa Applicazione non violi la normativa applicabile o diritti di terze parti. Tuttavia, non sempre &egrave; possibile raggiungere tale risultato.<br />In tali casi, senza alcun pregiudizio ai diritti ed alle pretese legalmente esercitabili, gli Utenti sono pregati di indirizzare i relativi reclami ai recapiti specificati in questo documento.</p>
<br/>
<strong>Diritti sui contenuti di questa Applicazione</strong>
<p>Il Titolare detiene e si riserva espressamente ogni diritto di propriet&agrave; intellettuale sui suddetti contenuti.</p>
<p>Gli Utenti non sono autorizzati ad usare i contenuti in alcun modo che non sia necessario od implicito nel corretto utilizzo del Servizio.</p>
<p>In particolare, ma senza esclusioni, &egrave; fatto divieto agli Utenti di copiare, scaricare, condividere oltre i limiti sotto specificati, modificare, tradurre, elaborare, pubblicare, trasmettere, vendere, concedere sottolicenze, trasformare, trasferire/alienare a terze parti o creare opere derivate a partire dal contenuto disponibile su questa Applicazione, di permettere a terze parti di intraprendere tali attivit&agrave; tramite il proprio account Utente o dispositivo, anche a propria insaputa.</p>
<p>Ove espressamente indicato su questa Applicazione, l&rsquo;Utente &egrave; autorizzato a scaricare, copiare e/o condividere determinati contenuti disponibili su questa Applicazione esclusivamente per scopi personali e non commerciali ed a condizione che sia osservata l&rsquo;attribuzione della paternit&agrave; dell&rsquo;opera nonch&eacute; l&rsquo;indicazione di ogni altra circostanza rilevante richiesta dal Titolare.</p>
<p>Restano ferme le limitazioni ed esclusioni previste dalla normativa sul diritto d&rsquo;autore.</p>
<br/>
<h5 className='fw-bold'>Contenuti forniti dagli Utenti</h5>
<p>Il Titolare consente agli Utenti di caricare, condividere o offrire i propri contenuti su questa Applicazione.</p>
<p>All&rsquo;atto di fornire contenuti a questa Applicazione l&rsquo;Utente dichiara di essere legalmente autorizzato a farlo e conferma che detti contenuti non violano norme di legge e/o diritti di terzi.</p>
<p>Gli ulteriori requisiti che i contenuti devono soddisfare per essere considerati &ldquo;accettabili&rdquo; sono specificati nella sezione relativa all&rsquo;uso ammesso.</p>
<br/>
<strong>Diritti sui contenuti forniti dagli Utenti</strong>
<p>L&rsquo;Utente riconosce ed accetta che fornendo contenuti propri a questa Applicazione concede al Titolare a titolo gratuito il diritto non esclusivo di elaborare i contenuti con fini di operativit&agrave; e manutenzione di questa Applicazione, cos&igrave; come contrattualmente previsto.</p>
<p>Nei limiti di legge, l&rsquo;Utente rinuncia all&rsquo;esercizio di diritti morali in relazione al contenuto fornito a questa Applicazione.</p>
<p>Gli Utenti riconoscono ed accettano che i contenuti da loro offerti tramite questa Applicazione saranno resi disponibili alle stesse condizioni applicabili ai contenuti di questa Applicazione.</p>
<br/>
<strong>Responsabilit&agrave; per i contenuti forniti</strong>
<p>L&rsquo;Utente risponde in via esclusiva dei contenuti caricati, pubblicati, condivisi o comunque forniti a questa Applicazione. <br/> L&rsquo;Utente riconosce ed accetta che il Titolare non filtra n&eacute; modera tali contenuti.</p>
<p>Ciononostante, il Titolare si riserva il diritto di rimuovere, cancellare o bloccare detti contenuti a propria discrezione e di negare senza preavviso all&rsquo;Utente che li ha caricati l&rsquo;accesso a questa Applicazione:</p>
<ul>
<li>
<p>●  nel momento in cui venisse a conoscenza di una (presunta) violazione di questi Termini, dei diritti di terzi o della legge applicabile, in relazione a tale contenuto;</p>
</li>
<li>
<p>●  se ha ricevuto una notifica di violazione dei diritti di propriet&agrave; intellettuale;</p>
</li>
<li>
<p>●  se ha ricevuto una notifica di violazione della riservatezza di terzi, compresa la loro sfera intima;</p>
</li>
<li>
<p>●  per ordine dell&rsquo;Autorit&agrave;; o</p>
</li>
<li>
<p>●  qualora sia stato fatto presente al Titolare che tali contenuti, se accessibili tramite questa Applicazione, possono rappresentare un rischio per gli Utenti, per i terzi o per la disponibilit&agrave; del Servizio.</p>
</li>
</ul>
<p>La rimozione, cancellazione o il blocco dei contenuti non giustificano alcuna pretesa di risarcimento, rimborso o indennizzo in capo agli Utenti che hanno fornito tali contenuti.</p>
<p>Gli Utenti accettano di mantenere indenne il Titolare da e contro ogni pretesa avanzata e/o danno sofferto a causa di contenuti da essi forniti a o offerti tramite questa Applicazione.</p>
<br/>
<strong>Rimozione di contenuti da parte di questa Applicazione disponibili tramite App Store</strong>
<p>Se un contenuto oggetto di reclamo &egrave; considerato contestabile sar&agrave; rimosso entro 24 ore e all&rsquo;Utente responsabile del caricamento sar&agrave; negato l&rsquo;accesso al Servizio.</p>
<br/>
<strong>Accessibilit&agrave; dei contenuti forniti</strong>
<p>I contenuti forniti a questa Applicazione dagli Utenti sono messi a disposizione alle condizioni descritte in questa sezione.</p>
<br/>
<strong>Contenuti riservati a un pubblico determinato</strong>
<p>I contenuti destinati ad essere messi a disposizione di un pubblico determinato possono essere condivisi solo con le terze parti definite dall&rsquo;Utente.</p>
<p>Qualsiasi dato personale, identificativo o altra informazione che l&rsquo;Utente carica unitamente al contenuto (quali, ad esempio, la user-ID, un avatar o nickname etc.) apparir&agrave; in collegamento con il contenuto.</p>
<p>Gli Utenti possono (e sono incoraggiati a) consultare questa Applicazione per scoprire chi pu&ograve; accedere ai contenuti da loro forniti.</p>
<br/>
<h5 className='fw-bold'>Accesso a risorse esterne</h5>
<p>Tramite questa Applicazione gli Utenti potrebbero avere accesso a risorse fornite da terzi. Gli Utenti riconoscono ed accettano che il Titolare non ha alcun controllo su tali risorse e che pertanto non risponde del loro contenuto e della loro disponibilit&agrave;.</p>
<p>Le condizioni applicabili alle risorse fornite da terzi, ivi incluse quelle applicabili a eventuali concessioni di diritti su contenuti, sono determinate dagli stessi terzi e regolate nei relativi termini e condizioni o, in loro assenza, dalla legge.</p>
<p>In particolare, su questa Applicazione gli Utenti potrebbero imbattersi in avvisi pubblicitari forniti da terze parti. <br/> Il Titolare non controlla n&eacute; modera le pubblicit&agrave; visualizzate tramite questa Applicazione.<br/>  Cliccando su uno qualsiasi di tali annunci, l&rsquo;Utente interagir&agrave; con la terza parte responsabile della pubblicit&agrave;.</p>
<p>Il Titolare non risponde di quanto pu&ograve; derivare da tale interazione con i terzi, dall&rsquo;accesso a siti terzi o dall&rsquo;uso di contenuti di terzi.</p>
<br />
<h5 className='fw-bold'>Uso ammesso</h5>
<p>Questa Applicazione ed il Servizio possono essere utilizzati solo per gli scopi per i quali sono offerti, secondo questi Termini ed ai sensi della legge applicabile.</p>
<p>&Egrave; responsabilit&agrave; esclusiva dell&rsquo;Utente di far s&igrave; che l&rsquo;uso di questa Applicazione e/o del Servizio non violi la legge, i regolamenti o i diritti di terzi.</p>
<p>Pertanto, il Titolare si riserva il diritto di adottare ogni misura idonea a proteggere i propri interessi legittimi, ed in particolare di negare all&rsquo;Utente l&rsquo;accesso a questa Applicazione o al Servizio, risolvere contratti, denunciare ogni attivit&agrave; censurabile svolta tramite questa Applicazione o il Servizio alle autorit&agrave; competenti &ndash; p. es. l&rsquo;autorit&agrave; giudiziaria o amministrativa &ndash; ogniqualvolta l&rsquo;Utente ponga in essere o vi sia il sospetto che ponga in essere:</p>
<ul>
<li>
<p>● violazioni di legge, regolamenti e/o dei Termini;</p>
</li>
<li>
<p>● lesioni di diritti di terzi;</p>
</li>
<li>
<p>● atti che possono pregiudicare considerevolmente i legittimi interessi del Titolare;</p>
</li>
<li>
<p>● offese al Titolare o a un terzo.</p>
</li>
</ul>
<br />
<h5 className='fw-bold'>&ldquo;Passaparola&rdquo;</h5>
<p>Questa Applicazione consente agli Utenti di ricevere dei vantaggi se, grazie a una loro raccomandazione, un nuovo Utente acquista un Prodotto offerto su questa Applicazione.<br />Per scoprire tutti i dettagli e le condizioni applicabili, gli Utenti sono pregati di consultare i termini contrattuali specifici nella relativa sezione di questa Applicazione.</p>
<p>Il Titolare si riserva il diritto di cessare l&rsquo;offerta in qualsiasi momento a propria discrezione.</p>
<br />
<h5 className='fw-bold'>Licenza software</h5>
<p>Qualsiasi diritto di propriet&agrave; intellettuale o industriale, cos&igrave; come ogni altro diritto esclusivo esistente sul software o sulla tecnologia integrata in o relativa a questa Applicazione &egrave; detenuto dal Titolare e/o dal suo licenziante.</p>
<p>A condizione che l&rsquo;Utente rispetti questi Termini e nonostante qualsiasi disposizione divergente in essi contenuta, il Titolare concede agli Utenti una licenza revocabile, non esclusiva non cedibile n&eacute; concedibile di usare il software e/o la tecnologia integrata nel Servizio nel quadro e per gli scopi di questa Applicazione e del Servizio offerto.</p>
<p>La licenza non comprende alcun diritto di accesso a, utilizzo o rivelazione del codice sorgente originale all&rsquo;Utente. Le tecniche, gli algoritmi e le procedure contenute nel software e la relativa documentazione sono di propriet&agrave; esclusiva del Titolare o del suo licenziante.</p>
<p>La concessione di diritti e licenze all&rsquo;Utente cessa con effetti immediati in caso di risoluzione o scadenza dell&rsquo;Accordo.</p>
<br />
<h5 className='fw-bold'>Condizioni d&rsquo;uso dell&rsquo;API</h5>
<p>Gli Utenti possono accedere ai propri dati relativi a questa Applicazione attraverso l&rsquo;Application Program Interface (API). Ogni utilizzo dell&rsquo;API, anche attraverso prodotti o servizi di terzi che accedono a questa Applicazione, &egrave; soggetto ai Termini e in aggiunta alle seguenti condizioni specifiche:</p>
<ul>
<li>
<p>● l&rsquo;Utente riconosce ed accetta espressamente che il Titolare non risponde di danni o perdite conseguenti all&rsquo;utilizzo da parte dell&rsquo;Utente dell&rsquo;API o di prodotti o servizi terzi che accedono a dati tramite l&rsquo;API.</p>
</li>
</ul>
<br />
<h5 className='fw-bold'>TERMINI E CONDIZIONI DI VENDITA</h5>
<strong>Prodotti a pagamento</strong>
<p>Alcuni dei Prodotti offerti su questa Applicazione come parte del servizio sono a pagamento.</p>
<p>Le tariffe, la durata e le condizioni applicabili alla vendita di tali Prodotti sono descritte di seguito e nelle rispettive sezioni di questa Applicazione.</p>
<p>Per acquistare i Prodotti, l&rsquo;Utente &egrave; tenuto a registrarsi o ad effettuare l&rsquo;accesso a questa Applicazione.</p>
<br />
<strong>Descrizione del prodotto</strong>
<p>Prezzi, descrizioni e disponibilit&agrave; dei Prodotti sono specificati nelle rispettive sezioni di questa Applicazione e sono soggetti a modifica senza preavviso.</p>
<p>Sebbene i Prodotti su questa Applicazione siano presentati con la massima accuratezza tecnicamente possibile, la rappresentazione su questa Applicazione tramite qualsiasi mezzo (inclusi, a seconda dei casi, materiali grafici, immagini, colori, suoni) &egrave; da intendersi come mero riferimento e non implica alcuna garanzia in merito alle caratteristiche del Prodotto acquistato.</p>
<p>Le caratteristiche del Prodotto selezionato saranno specificate durante la procedura d&rsquo;acquisto.</p>
<br />
<strong>Procedura d&rsquo;acquisto</strong>
<p>Ogni fase, dalla scelta del prodotto fino all&rsquo;inoltro dell&rsquo;ordine, fa parte della procedura d&rsquo;acquisto.<br />La procedura d&rsquo;acquisto comprende i seguenti passaggi:</p>
<ul>
<li>
<p>● Cliccando sul pulsante di checkout, gli Utenti accedono all&rsquo;area di checkout di Stripe dove verr&agrave; loro richiesto di specificare i propri recapiti e un mezzi di pagamento a loro scelta.</p>
</li>
<li>
<p>● Dopo aver fornito tutte le informazioni richieste, gli Utenti sono pregati di controllare attentamente l&rsquo;ordine e, successivamente, confermarlo e inoltrarlo utilizzando il relativo pulsante o meccanismo su questa Applicazione, accettando in tal modo, i presenti Termini e impegnandosi a pagare il prezzo concordato.</p>
</li>
</ul>
<br />
<strong>Invio dell&rsquo;ordine</strong>
<p>L&rsquo;invio dell&rsquo;ordine comporta quanto segue:</p>
<ul>
<li>
<p>● L&rsquo;invio dell&rsquo;ordine da parte dell&rsquo;utente determina la conclusione del contratto e fa sorgere in capo all&rsquo;Utente l&rsquo;obbligo di pagare prezzo, tasse ed eventuali ulteriori oneri e spese, cos&igrave; come specificato nella pagina dell&rsquo;ordine.</p>
</li>
<li>
<p>● Nel caso in cui il Prodotto acquistato richieda un'azione da parte dell&rsquo;Utente, come la fornitura di informazioni o dati personali, specificazioni o richieste particolari, l&rsquo;inoltro dell&rsquo;ordine costituisce in capo all&rsquo;Utente anche l&rsquo;obbligo di collaborare di conseguenza.</p>
</li>
<li>
<p>● Una volta inoltrato l&rsquo;ordine, agli Utenti sar&agrave; inviata una conferma di ricezione dell&rsquo;ordine.</p>
</li>
</ul>
<p>Tutte le notifiche relative alla procedura d&rsquo;acquisto sopra descritta saranno inviate all&rsquo;indirizzo di posta elettronica fornito dall&rsquo;Utente a tal fine.</p>
<br/>
<strong>Prezzi</strong>
<p>Durante la procedura d&rsquo;acquisto e prima dell&rsquo;inoltro dell&rsquo;ordine, gli Utenti vengono debitamente informati di tutte le commissioni, tasse e costi (comprese eventuali spese di spedizione) che saranno loro addebitati.</p>
<p>I prezzi su questa Applicazione:</p>
<ul>
<li>
<p>● a seconda della sezione che l&rsquo;Utente sta consultando includono tutte le commissioni, tasse e costi applicabili o sono indicati al netto di commissioni, tasse e costi applicabili.</p>
</li>
</ul>
<br />
<h5 className='fw-bold'>Promozioni e sconti</h5>
<p>Il Titolare potrebbe offrire sconti o promozioni speciali per l&rsquo;acquisto dei Prodotti. Tali promozioni o sconti sono sempre soggetti ai requisiti ed a termini e condizioni previste nella relativa sezione di questa Applicazione.</p>
<p>Promozioni e offerte sono sempre concesse a sola discrezione del Titolare.</p>
<p>Promozioni o sconti ripetuti o periodici non costituiscono alcuna pretesa o diritto azionabili dagli Utenti in futuro.</p>
<p>A seconda dei casi, sconti e promozioni valgono per un determinato periodo di tempo o fino ad esaurimento scorte. <br/>  Salvo ove diversamente specificato, le limitazioni di tempo di promozioni e sconti si intendono riferite al fuso orario della sede del Titolare, come indicata nei recapiti in questo documento.</p>
<br />
<h5 className='fw-bold'>Buoni</h5>
<p>Promozioni e sconti possono essere offerti sotto forma di Buoni.</p>
<p>In caso di violazione delle condizioni applicabili ai Buoni, il Titolare pu&ograve; legittimamente rifiutare di adempiere ai propri obblighi contrattuali e si riserva espressamente il diritto di agire nelle opportune sedi anche giudiziarie al fine di tutelare i propri diritti e interessi.</p>
<p>Eventuali disposizioni aggiuntive o divergenti applicabili all&rsquo;utilizzo dei Buoni riportate nella relativa pagina informativa o sul Buono stesso prevalgono in ogni caso, a prescindere dalle disposizioni che seguono.</p>
<p>Salvo ove diversamente specificato, le seguenti regole si applicano all&rsquo;utilizzo dei Buoni:</p>
<ul>
<li>
<p>● Ciascun Buono &egrave; valido solo se utilizzato secondo le modalit&agrave; e nel periodo di tempo specificati sul sito web e/o sul Buono;</p>
</li>
<li>
<p>● Il Buono pu&ograve; essere riscattato solo integralmente al momento dell&rsquo;acquisto &ndash; non &egrave; consentito l&rsquo;utilizzo parziale;</p>
</li>
<li>
<p>● Salvo ove diversamente specificato, i Buoni monouso possono essere riscattati una sola volta per acquisto e possono pertanto essere riscattati una sola volta anche nel caso di acquisti rateizzati;</p>
</li>
<li>
<p>● I Buoni non sono cumulabili;</p>
</li>
<li>
<p>● Il Buono deve essere utilizzato entro il termine di validit&agrave; specificato. Scaduto il termine, il Buono sar&agrave; automaticamente annullato. Resta esclusa qualsiasi possibilit&agrave; di rivendicare diritti, compreso al rimborso del valore del Buono;</p>
</li>
<li>
<p>● L&rsquo;Utente non ha diritto ad alcun accredito/rimborso/compensazione nel caso vi sia differenza tra il valore del Buono e il valore riscattato;</p>
</li>
<li>
<p>● Il Buono &egrave; da intendersi esclusivamente ad uso non commerciale. La riproduzione, contraffazione e commercializzazione del Buono sono severamente vietate, cos&igrave; come qualsiasi attivit&agrave; illecita connessa all&rsquo;acquisto e/o utilizzo del Buono.</p>
</li>
</ul>
<br />
<h5 className='fw-bold'>Mezzi di pagamento</h5>
<p>I dettagli relativi ai mezzi di pagamento accettati sono evidenziati durante la procedura d&rsquo;acquisto.</p>
<p>Alcuni mezzi di pagamento sono legati ad ulteriori condizioni o comportano costi aggiuntivi. Le informazioni dettagliate sono riportate nella relativa sezione di questa Applicazione.</p>
<p>Tutti i pagamenti vengono gestiti autonomamente da servizi terzi. Pertanto, questa Applicazione non raccoglie dati relativi al pagamento &ndash; quali numeri di carta di credito &ndash; ma riceve una notifica una volta che il pagamento &egrave; andato a buon fine.</p>
<p>Nel caso in cui il pagamento effettuato con uno dei mezzi disponibili fallisca o venga rifiutato dal fornitore di servizi di pagamento, il Titolare non &egrave; obbligato a eseguire l&rsquo;ordine. Nel caso in cui il pagamento non vada a buon fine, il Titolare si riserva il diritto di richiedere all'Utente il rimborso di qualsiasi spesa o danno correlato.</p>
<br />
<h5 className='fw-bold'>Pagamento rateale</h5>
<p>Il prezzo d&rsquo;acquisto pu&ograve; essere pagato in due o pi&ugrave; rate, nei termini specificati su questa Applicazione o altrimenti comunicati dal Titolare.<br />Determinati Prodotti potrebbero essere esclusi da questa modalit&agrave; di pagamento.<br />Nel caso in cui l&rsquo;Utente risulti inadempiente rispetto aduna sola delle rate del pagamento, l&rsquo;intero importo dovuto sar&agrave; immediatamente dovuto ed esigibile.</p>
<br />
<h5 className='fw-bold'>Autorizzazione per pagamenti futuri via PayPal</h5>
<p>Nel caso in cui l&rsquo;Utente autorizzi la funzione PayPal che consente acquisti futuri, questa Applicazione memorizzer&agrave; un codice identificativo collegato all&rsquo;account PayPal dell&rsquo;Utente. In tal modo questa Applicazione potr&agrave; elaborare automaticamente i pagamenti per acquisti futuri o per il pagamento di rate periodiche di un acquisto pregresso.</p>
<p>L&rsquo;autorizzazione pu&ograve; essere revocata in ogni momento contattando il Titolare o modificando le impostazioni personali di PayPal.</p>
<br />
<h5 className='fw-bold'>Acquisto su app store</h5>
<p>Questa Applicazione o determinati Prodotti in vendita su questa Applicazione devono essere acquistati tramite un app store terzo. Per poter effettuare tali acquisti gli Utenti sono pregati di seguire le istruzioni nel relativo app store (ad esempio &ldquo;Apple App Store&rdquo; o &ldquo;Google Play&rdquo;). Tali informazioni possono variare a seconda dello specifico dispositivo utilizzato.</p>
<p>Salvo ove diversamente specificato, gli acquisti effettuati tramite negozi online di terze parti sono soggetti anche ai termini e condizioni di tali terzi. Tali termini e condizioni prevalgono in ogni caso di incongruenza o conflitto rispetto ai presenti Termini.</p>
<p>Pertanto, gli Utenti che effettuano acquisti attraverso tali negozi online di terzi sono pregati di leggere attentamente ed accettare i relativi termini e condizioni di vendita.</p>
<br />
<h5 className='fw-bold'>Riserva di propriet&agrave;</h5>
<p>Fino alla ricezione del pagamento del prezzo integrale di acquisto da parte del Titolare, l&rsquo;Utente non acquista la propriet&agrave; dei Prodotti ordinati.</p>
<br />
<h5 className='fw-bold'>Riserva dei diritti d&rsquo;uso</h5>
<p>Fino alla ricezione del pagamento del prezzo integrale di acquisto da parte del Titolare, l&rsquo;Utente non acquista i diritti d&rsquo;uso dei Prodotti ordinati.</p>
<br />
<h5 className='fw-bold'>Diritto contrattuale di recesso</h5>
<p>Il Titolare concede agli Utenti il diritto contrattuale di recedere dal contratto d&rsquo;acquisto secondo i termini e le condizioni esplicitati nella relativa sezione di questa Applicazione entro 30 giorni dalla conclusione del contratto.</p>
<br />
<h5 className='fw-bold'>Consegna</h5>
<strong>Consegna di contenuti digitali</strong>
<p>Salvo ove diversamente specificato, il contenuto digitale acquistato su questa Applicazione viene consegnato tramite download sul dispositivo o sui dispositivi scelti dall&rsquo;Utente.</p>
<p>Gli Utenti riconoscono ed accettano che, per scaricare e/o utilizzare il Prodotto, il dispositivo o i dispositivi prescelti ed il rispettivo software (compresi i sistemi operativi) debbano essere legali, d&rsquo;uso comune, aggiornati e in linea con gli attuali standard di mercato.</p>
<p>Gli Utenti riconoscono ed accettano che la possibilit&agrave; di scaricare il Prodotto acquistato potrebbe essere limitata nel tempo e nello spazio.</p>
<strong>Prestazione di servizi</strong>
<p>Il servizio acquistato sar&agrave; eseguito o reso disponibile nei tempi indicati su questa Applicazione o secondo le modalit&agrave; comunicate prima dell'inoltro dell'ordine.</p>
<br />
<h5 className='fw-bold'>Durata del contratto</h5>
<strong>Periodo di prova</strong>
<p>Gli Utenti hanno la possibilit&agrave; di provare questa Applicazione o singoli Prodotti gratuitamente per un periodo di prova determinato e non rinnovabile. Alcune funzioni o funzionalit&agrave; di questa Applicazione potrebbero non essere disponibili durante il periodo di prova.<br />Ulteriori condizioni applicabili al periodo di prova, compresa la sua durata, saranno esplicitate su questa Applicazione.</p>
<p>Il periodo di prova termina automaticamente e non si trasforma in alcun Prodotto a pagamento a meno che l&rsquo;Utente non lo acquisti intenzionalmente.</p>
<strong>Abbonamenti</strong>
<p>Grazie all&rsquo;abbonamento, l&rsquo;Utente riceve un Prodotto in via continuativa o periodica. I dettagli riguardanti il tipo di abbonamento e la risoluzione sono descritti di seguito.</p>
<strong>Abbonamenti a tempo determinato</strong>
<p>Gli abbonamenti a pagamento a tempo determinato decorrono dal giorno in cui il Titolare riceve il pagamento e restano attivi per la durata di abbonamento scelta dall&rsquo;Utente o altrimenti indicata durante la procedura d&rsquo;acquisto.</p>
<p>Una volta terminata la durata dell&rsquo;abbonamento, il Prodotto non sar&agrave; pi&ugrave; accessibile.</p>
<strong>Abbonamenti gestiti tramite Apple ID</strong>
<p>Gli Utenti possono concludere l&rsquo;abbonamento ad un Prodotto utilizzando l&rsquo;ID Apple associato al loro account Apple App Store tramite la relativa procedura su questa Applicazione. Nel fare ci&ograve;, gli Utenti riconoscono ed accettano che:</p>
<ul>
<li>
<p>● qualsiasi pagamento dovuto sar&agrave; addebitato all&rsquo;account Apple ID;</p>
</li>
<li>
<p>● gli abbonamenti si rinnovano automaticamente per la stessa durata a meno che l'Utente non comunichi disdetta almeno 24 ore prima della scadenza del periodo di abbonamento corrente;</p>
</li>
<li>
<p>● tutti i compensi o pagamenti dovuti per il rinnovo saranno addebitati entro 24 ore prima della scadenza del periodo corrente;</p>
</li>
<li>
<p>● gli abbonamenti possono essere gestiti o disdetti direttamente tramite le impostazioni dell'account Apple App Store dell&rsquo;Utente.</p>
</li>
</ul>
<p>Quanto sopra prevale su qualsiasi disposizione contrastante o divergente dei Termini.</p>
<strong>Rateizzazione</strong>
<p>Se il Prezzo di acquisto &egrave; pagato in pi&ugrave; rate, l&rsquo;abbonamento decorre dal giorno in cui il Titolare riceve il primo pagamento e rimane attivo per tutto il periodo d&rsquo;abbonamento, a condizione che tutte le altre rate siano ricevute tempestivamente.</p>
<p>Il mancato rispetto delle scadenze di pagamento potrebbe comportare l&rsquo;inacessibilit&agrave; del Prodotto.</p>
<strong>Rinnovo automatico degli abbonamenti a tempo determinato</strong>
<p>Gli abbonamenti si rinnovano automaticamente con addebito sul mezzo di pagamento scelto dall&rsquo;Utente al momento dell&rsquo;acquisto.</p>
<p>Il rinnovo ha durata uguale al periodo di abbonamento originario.</p>
<p>L&rsquo;Utente ricever&agrave; un promemoria relativo all&rsquo;imminente rinnovo con congruo anticipo, in cui sar&agrave; descritta la procedura da seguire per disattivare il rinnovo automatico.</p>
<strong>Risoluzione</strong>
<p>Gli abbonamenti possono essere risolti inviando una comunicazione di disdetta chiara ed inequivocabile al Titolare, utilizzando i recapiti riportati in questo documento o - se del caso &ndash; seguendo le istruzioni su questa Applicazione.</p>
<p>Qualora la comunicazione della disdetta pervenga al Titolare prima della data di rinnovo dell&rsquo;abbonamento, la risoluzione avr&agrave; effetto allo scadere del periodo in corso.</p>
<strong>Risoluzione</strong>
<p>Gli abbonamenti possono essere risolti inviando una comunicazione di disdetta chiara ed inequivocabile al Titolare, utilizzando i recapiti riportati in questo documento o - se del caso &ndash; seguendo le istruzioni su questa Applicazione.</p>
<p>Qualora la disdetta pervenga al Titolare non oltre 10 giorni prima della fine del mese in corso, l'abbonamento scade alla fine di tale mese.</p>
<strong>Termini e condizioni applicabili a funzioni extra</strong>
<p>Gli Utenti titolari di un abbonamento attivo possono acquistare singole componenti o funzioni aggiuntive, cos&igrave; come descritte nella relativa sezione di questa Applicazione.<br />Prezzi, durata, termini di utilizzo e disdetta di tali componenti o funzioni aggiuntive possono differire da quelli del Prodotto principale e, salvo ove diversamente specificato, non influenzano, prezzi, durata, termini di utilizzo e disdetta di quest&rsquo;ultimo.</p>
<br />
<h5 className='fw-bold'>Diritti dell&rsquo;Utente</h5>
<strong>Diritto di recesso</strong>
<p>A meno che non ricorra un&rsquo;eccezione, l&rsquo;Utente potrebbe godere del diritto di recedere dal contratto entro il termine sotto specificato (di norma 14 giorni) per qualsiasi motivo e senza giustificazione. L&rsquo;Utente pu&ograve; trovare ulteriori informazioni sul diritto di recesso in questa sezione.</p>


<br />
<h5 className='fw-bold'>Assistenza post-vendita</h5>
<p>Gli Utenti che hanno acquistato prodotti su questa Applicazione hanno diritto a usufruire dei servizi di assistenza post-vendita descritti nella relativa sezione di questa Applicazione.</p>
<br />
<h5 className='fw-bold'>Limitazione di responsabilit&agrave; e manleva</h5>
<p>Salvo ove diversamente specificato o concordato con gli Utenti, la responsabilit&agrave; del Titolare per danni connessi all'esecuzione dell'Accordo sar&agrave; esclusa, limitata e/o ridotta nei limiti massimi consentiti dalla legge applicabile.</p>
<strong>Utenti Europei</strong>
<strong>- Manleva</strong>
<p>L&rsquo;Utente si impegna a manlevare e tenere indenne il Titolare e i suoi sottoposti, affiliati, funzionari, agenti, contitolari del marchio, partner e dipendenti nella misura di legge da qualsivoglia rivendicazione o pretesa &ndash; compresi, senza alcuna limitazione, oneri e spese legali &ndash; avanzata da terzi a causa di o in collegamento con comportamenti in violazione dei presenti Termini, di diritti di terzi o di legge, posti in essere in collegamento con l'utilizzo del Servizio e addebitabili all&rsquo;Utente, ai suoi affiliati, funzionari, agenti, contitolari del marchio, partner e dipendenti, a titolo di colpa.</p>
<strong>Limitazione della responsabilit&agrave; per le attivit&agrave; dell&rsquo;Utente su questa Applicazione</strong>
<p>Gli Utenti riconoscono ed accettano che il Titolare si limita a fornire agli Utenti l&rsquo;infrastruttura tecnica e le funzionalit&agrave; disponibili su questa Applicazione.</p>
<p>Il Titolare non interviene in alcun modo in qualit&agrave; di intermediario, moderatore o promotore nelle interazioni, accordi o transazioni tra gli Utenti e declina pertanto ogni responsabilit&agrave; per tali interazioni tra Utenti, e per l&rsquo;adempimento ad eventuali obbligazioni da parte degli Utenti.</p>
<strong>Limitazione della responsabilit&agrave; per le attivit&agrave; dell&rsquo;Utente su questa Applicazione</strong>
<p>Salvo ove diversamente specificato e fatte salve le disposizioni di legge applicabili, &egrave; esclusa ogni pretesa risarcitoria nei confronti del Titolare (o di qualsiasi persona fisica o giuridica che agisca per suo conto).</p>
<p>Quanto precede non limita la responsabilit&agrave; del Titolare per morte, danno alla persona o all&rsquo;integrit&agrave; fisica o mentale, danni derivanti dalla violazione di obblighi contrattuali essenziali, quali gli obblighi strettamente necessari al raggiungimento della causa del contratto, e/o ai danni provocati con dolo o colpa grave, a condizione che l&rsquo;utilizzo di questa Applicazione da parte dell&rsquo;Utente sia stato idoneo e corretto.</p>
<p>Salvo che i danni siano stati causati con dolo o colpa grave o incidano sulla vita e/o l&rsquo;integrit&agrave; personale, fisica o mentale, il Titolare risponde solo nella misura del danno tipico per il tipo di contratto e prevedibile al momento della conclusione.</p>
<p>In particolare, nei limiti sopra riportati, il Titolare non si assume alcuna responsabilit&agrave; per quanto riguarda:</p>
<ul>
<li>
<p>● eventuali perdite che non siano conseguenza diretta di una violazione dei Termini da parte del Titolare;</p>
</li>
<li>
<p>● eventuali mancati guadagni o altre perdite, anche indirette, che l&rsquo;Utente potrebbe aver subito (quali, a mero titolo esemplificativo, perdite commerciali, perdita di ricavi, di profitti o risparmi preventivati, perdita di rapporti contrattuali o commerciali, perdita di avviamento o danni alla reputazione, etc.);</p>
</li>
<li>
<p>● danni o perdite derivanti da interruzioni o malfunzionamenti di questa Applicazione dovuti a cause di forza maggiore o eventi imprevisti ed imprevedibili e, in ogni caso, indipendenti dalla volont&agrave; e fuori dal controllo del Titolare, quali, a mero titolo esemplificativo, guasti o interruzioni delle linee telefoniche o elettriche, della connessione Internet e/o di altri mezzi di trasmissione, inaccessibilit&agrave; di siti web, scioperi, calamit&agrave; naturali, virus e attacchi informatici, interruzioni di fornitura dei prodotti, servizi o applicazioni di terzi;</p>
</li>
</ul>
<br />
<h5 className='fw-bold'>Disposizioni comuni</h5>
<strong>Nessuna rinuncia implicita</strong>
<p>Il mancato esercizio di diritti di legge o pretese derivanti da questi Termini da parte del Titolare non costituisce rinuncia agli stessi. Nessuna rinuncia pu&ograve; essere considerata definitiva in relazione ad uno specifico diritto o a qualsiasi altro diritto.</p>
<br />
<h5 className='fw-bold'>Interruzione del Servizio</h5>
<p>Per garantire il miglior livello di servizio possibile, il Titolare si riserva di interrompere il Servizio per finalit&agrave; di manutenzione, aggiornamenti di sistema o per qualsiasi altra modifica, dandone idonea notizia agli Utenti.</p>
<p>Nei limiti di legge, il Titolare si riserva di sospendere o cessare completamente l'attivit&agrave; del Servizio. <br/> In caso di cessazione dell'attivit&agrave; del Servizio, il Titolare si adoperer&agrave; affinch&eacute; gli Utenti possano estrarre i propri Dati Personali e le informazioni e rispetter&agrave; i diritti degli Utenti relativi all&rsquo;utilizzo continuato del prodotto e/o al risarcimento, secondo le disposizioni di legge.</p>
<p>Inoltre, il Servizio potrebbe non essere disponibile per cause che si sottraggono al ragionevole controllo del Titolare, quali cause di forza maggiore (p. es. malfunzionamenti infrastrutturali, blackout etc.).</p>
<p>Il Titolare si impegna a informare l'Utente con almeno 10 giorni di preavviso, ove possibile.</p>
<br />
<h5 className='fw-bold'>Rivendita del Servizio</h5>
<p>Gli Utenti non sono autorizzati a riprodurre, duplicare, copiare, vendere, rivendere o sfruttare questa Applicazione o il Servizio in toto o in parte senza previo consenso scritto del Titolare, espresso direttamente o attraverso un legittimo programma di rivendite.</p>
<br />
<h5 className='fw-bold'>Privacy policy</h5>
<p>Le informazioni sul trattamento dei Dati Personali sono contenute nella privacy policy di questa Applicazione.</p>
<br />
<h5 className='fw-bold'>Propriet&agrave; intellettuale</h5>
<p>Senza pregiudizio ad alcuna previsione pi&ugrave; specifica contenuta nei Termini, i diritti di propriet&agrave; intellettuale ed industriale, quali ad esempio diritti d&rsquo;autore, marchi, brevetti e modelli relativi a questa Applicazione sono detenuti in via esclusiva dal Titolare o dai suoi licenzianti e sono tutelati ai sensi della normativa e dei trattati internazionali applicabili alla propriet&agrave; intellettuale.</p>
<p>Tutti i marchi &ndash; denominativi o figurativi &ndash; ed ogni altro segno distintivo, ditta, marchio di servizio, illustrazione, immagine o logo che appaiono in collegamento con questa Applicazione sono e restano di esclusiva propriet&agrave; del Titolare o dei suoi licenzianti e sono tutelati ai sensi della normativa e dei trattati internazionali applicabili alla propriet&agrave; intellettuale.</p>
<br />
<h5 className='fw-bold'>Modifiche dei Termini</h5>
<p>Il Titolare si riserva il diritto di modificare i Termini in ogni momento. In tal caso, il Titolare dar&agrave; opportuna notizia delle modifiche agli Utenti.</p>
<p>Le modifiche avranno effetto nel rapporto con l&rsquo;Utente solo a partire dal momento comunicato all'Utente.</p>
<p>L&rsquo;utilizzo continuato del Servizio implica l&rsquo;accettazione dell&rsquo;Utente dei Termini aggiornati. Se l&rsquo;Utente non desidera accettare le modifiche, deve cessare l&rsquo;utilizzo del Servizio e pu&ograve; recedere dall'Accordo.</p>
<p>La versione precedente continua a disciplinare il rapporto fino all&rsquo;accettazione delle modifiche da parte dell&rsquo;Utente. <br/> Tale versione pu&ograve; essere richiesta al Titolare.</p>
<p>Se richiesto dalla legge, il Titolare comunicher&agrave; in anticipo agli Utenti la data di entrata in vigore dei Termini modificati.</p>
<p>Il Titolare informer&agrave; adeguatamente l'Utente delle modifiche imminenti 10 giorni prima della loro entrata in vigore.</p>
<br />
<h5 className='fw-bold'>Cessione del contratto</h5>
<p>Il Titolare si riserva il diritto di trasferire, cedere, disporre di, novare o appaltare singoli o tutti i diritti e le obbligazioni secondo questi Termini, avendo riguardo per gli interessi legittimi degli Utenti.</p>
<p>Si applicano le disposizioni relative alla modifica di questi Termini.</p>
<p>L&rsquo;Utente non &egrave; autorizzato a cedere o trasferire i propri diritti e le proprie obbligazioni secondo i Termini senza il consenso scritto del Titolare.</p>
<br />
<h5 className='fw-bold'>Contatti</h5>
<p>Tutte le comunicazioni inerenti all&rsquo;uso di questa Applicazione devono essere inviate ai recapiti indicati in questo documento.</p>
<br />
<h5 className='fw-bold'>Clausola di salvaguardia</h5>
<p>Qualora alcuna delle disposizioni di questi Termini dovesse essere o divenire nulla o inefficace ai sensi della legge applicabile, la nullit&agrave; o inefficacia di tale disposizione non provoca inefficacia delle restanti previsioni, che permangono pertanto valide ed efficaci.</p>
<strong>Utenti europei</strong>
<p>Qualora una disposizione di questi Termini dovesse essere o divenire nulla, invalida o inefficace, le parti si adopereranno per individuare in via amichevole una disposizione valida ed efficace sostitutiva di quella nulla, invalida o inefficace.<br />In caso di mancato accordo nei termini predetti, se permesso o previsto dalla legge applicabile, la disposizione nulla, invalida o inefficace sar&agrave; sostituita dalla disciplina legale applicabile.</p>
<p>Fermo restando quanto sopra, la nullit&agrave;, invalidit&agrave; o inefficacia di una specifica disposizione di questi Termini non comporta nullit&agrave; dell&rsquo;intero Accordo, a meno che le disposizioni nulle, invalidi o inefficaci nel quadro dell&rsquo;Accordo siano essenziali o di tale importanza, che le parti non avrebbero concluso il contratto se avessero saputo che la disposizione sarebbe stata invalida, ovvero in casi in cui le disposizioni residue comporterebbero un onere eccessivo ed inaccettabile per una delle parti.</p>
<br />
<h5 className='fw-bold'>Legge applicabile</h5>
<p>I Termini sono disciplinati dalla legge del luogo in cui &egrave; stabilito il Titolare, cos&igrave; come indicato nella relativa sezione di questo documento a prescindere dalle norme di conflitto.</p>
<strong>Prevalenza della legge nazionale</strong>
<p>Tuttavia, a prescindere da quanto precede, se la legge del paese in cui si trova l'Utente prevede un livello di tutela dei consumatori superiore, prevale tale superiore livello di tutela.</p>
<strong>Foro competente</strong>
<p>La competenza esclusiva a conoscere qualsiasi controversia derivante da o in collegamento con i Termini spetta al giudice del luogo in cui il Titolare &egrave; stabilito, cos&igrave; come indicato nella relativa sezione di questo documento.</p>
<strong>Eccezione per Consumatori in Europa</strong>
<p>Quanto precede non si applica a Utenti che agiscono come Consumatori Europei o Consumatori situati nel Regno Unito, Svizzera, Norvegia o Islanda.</p>
<br />
<h5 className='fw-bold'>Risoluzione delle controversie</h5>
<strong>Composizione amichevole delle controversie</strong>
<p>Gli Utenti possono segnalare eventuali controversie al Titolare, che cercher&agrave; di risolvere in via amichevole.</p>
<p>Per quanto resti impregiudicato il diritto degli Utenti di promuovere un&rsquo;azione in giudizio, in caso di controversie inerenti all&rsquo;uso di questa Applicazione o al Servizio, gli Utenti sono pregati di contattare il Titolare ai recapiti indicati in questo documento. </p>
<p>L&rsquo;Utente pu&ograve; indirizzare un reclamo all&rsquo;indirizzo e-mail o alla PEC del Titolare indicato in questo documento, includendo una breve descrizione e, se del caso, i dettagli dell&rsquo;ordine, acquisto o account interessato.</p>
<p>Il Titolare provveder&agrave; ad evadere la richiesta senza indebito ritardo ed entro 7 giorni lavorativi dalla sua ricezione.</p> 
<br/>
<AccordionEl />
      </div>
  )
}

function Cookies (){
  return(
      <div>
          <p>Questo documento contiene informazioni in merito alle tecnologie che consentono a questa Applicazione di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l&rsquo;utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell&rsquo;Utente quando quest&rsquo;ultimo interagisce con questa Applicazione.</p>
<p>Per semplicit&agrave;, in questo documento tali tecnologie sono sinteticamente definite &ldquo;Strumenti di Tracciamento&rdquo;, salvo vi sia ragione di differenziare.Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza di un browser. Per questo motivo, all&rsquo;interno di questo documento il termine Cookie &egrave; utilizzato solo per indicare in modo specifico quel particolare tipo di Strumento di Tracciamento.</p>
<p>Alcune delle finalit&agrave; per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso dell&rsquo;Utente. Se viene prestato il consenso, esso pu&ograve; essere revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.</p>
<p>Questa Applicazione utilizza solo Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento &ldquo;di prima parte&rdquo;).Durata e scadenza dei Cookie di prima parte e degli altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal Titolare. Alcuni di essi scadono al termine della sessione di navigazione dell&rsquo;Utente.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Attivit&agrave; strettamente necessarie a garantire il funzionamento di questa Applicazione e la fornitura del Servizio</strong></h5>
<p></p>
<p>Questa Applicazione utilizza Cookie comunemente detti &ldquo;tecnici&rdquo; o altri Strumenti di Tracciamento analoghi per svolgere attivit&agrave; strettamente necessarie a garantire il funzionamento o la fornitura del Servizio.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Come gestire le preferenze e prestare o revocare il consenso</strong></h5>
<p></p>
<p>Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove necessario:</p>
<p>Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri dispositivi - per esempio, possono impedire l&rsquo;uso o l&rsquo;archiviazione di Strumenti di Tracciamento.</p>
<p>In aggiunta, ogni qualvolta l&rsquo;utilizzo di Strumenti di Tracciamento dipenda da consenso, l&rsquo;Utente pu&ograve; prestare o revocare tale consenso impostando le proprie preferenze all&rsquo;interno dell&rsquo;informativa sui cookie o aggiornando tali preferenze tramite il widget privacy per le preferenze relative al consenso, se presente.</p>
<p>Grazie ad apposite funzioni del browser o del dispositivo &egrave; anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati, inclusi quelli utilizzati per il salvataggio delle preferenze relative al consenso inizialmente espresse dall&apos;Utente.</p>
<p>Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Individuare le impostazioni relative agli Strumenti di Tracciamento</strong></h5>
<p></p>
<p>Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei browser pi&ugrave; diffusi ai seguenti indirizzi:</p>
<ul>
  <li>●<a rel="noopener nofollow" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies" className='text-dark'>Google Chrome</a></li>
  <li>●<a rel="noopener nofollow" target="_blank" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" className='text-dark'>Mozilla Firefox</a></li>
  <li>●<a rel="noopener nofollow" target="_blank" href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/" className='text-dark'>Apple Safari</a></li>
  <li>●<a rel="noopener nofollow" target="_blank" href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" className='text-dark'>Microsoft Internet Explorer</a></li>
  <li>●<a rel="noopener nofollow" target="_blank" href="https://support.microsoft.com/it-it/help/4027947" className='text-dark'>Microsoft Edge</a></li>
  <li>●<a rel="noopener nofollow" target="_blank" href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing" className='text-dark'>Brave</a></li>
  <li>●<a rel="noopener nofollow" target="_blank" href="https://help.opera.com/latest/web-preferences/#cookies" className='text-dark'>Opera</a></li>
</ul>
<p>Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di pubblicit&agrave; per dispositivi mobili o le impostazioni relative al tracciamento in generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).</p>
<p><strong></strong></p>
<br/>
<h5><strong>Conseguenze legate al rifiuto dell&apos;utilizzo di Strumenti di Tracciamento</strong></h5>
<p></p>
<p>Gli Utenti sono liberi di decidere se permettere o meno l&apos;utilizzo di Strumenti di Tracciamento. Tuttavia, si noti che gli Strumenti di Tracciamento consentono a questa Applicazione di fornire agli Utenti un&apos;esperienza migliore e funzionalit&agrave; avanzate (in linea con le finalit&agrave; delineate nel presente documento). Pertanto, qualora l&apos;Utente decida di bloccare l&apos;utilizzo di Strumenti di Tracciamento, il Titolare potrebbe non essere in grado di fornire le relative funzionalit&agrave;.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Titolare del Trattamento dei Dati</strong></h5>
<p></p>
<p>Anthill S.r.l.s.<br/>Via Colombo, 14/3 16121 Genova (GE) - Italy<br/> P.Iva 02641290990</p>
<p><strong><strong>Indirizzo email del Titolare:</strong></strong> info@anthillgroup.it</p>
<p>Data l&apos;oggettiva complessit&agrave; di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in merito all&apos;utilizzo di tali tecnologie su questa Applicazione.</p>
      </div>
  )
}

function PrivacyShort(){
  return (
      <div>
  <p>Per avere informazioni circa i tuoi dati personali raccolti, le finalit&agrave; ed i soggetti con cui i dati vengono condivisi, contatta il Titolare.Per avere pi&ugrave; informazioni e conoscere i tuoi diritti puoi anche visualizzare la versione completa di questa privacy policy, tramite il collegamento in basso a destra.</p>
<p><strong></strong></p>
<h5><strong>Informazioni di contatto</strong></h5>
<p></p>

              <div><strong>
                      Titolare del Trattamento dei Dati
                  </strong>
                  <p>Anthill S.r.l.s.<br/> Via Colombo,  14/316121 Genova (GE) - Italy<br/>P.Iva 02641290990</p>
                  <p><strong><strong>Indirizzo email del Titolare:</strong></strong> info@anthillgroup.it</p>
              </div>

      </div>
  )
}

function PrivacyFull(){
  return (
      <>
      <div>
          <div>
          <br/>
          <h5><strong>Titolare del Trattamento dei Dati</strong></h5>
  
  <p>Anthill S.r.l.s.Via Colombo, 14/316121 Genova (GE) - ItalyP.Iva 02641290990</p>
  <p><strong><strong>Indirizzo email del Titolare:</strong></strong> info@anthillgroup.it</p>
</div>
<div>
<br/>
<h5><strong>Tipologie di Dati raccolti</strong></h5>
  
  <p>Il Titolare non fornisce una lista di tipologie di Dati Personali raccolti.</p>
  <p>Dettagli completi su ciascuna tipologia di Dati Personali raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei Dati stessi.I Dati Personali possono essere liberamente forniti dall&apos;Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l&apos;uso di questa Applicazione.Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l&rsquo;Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ci&ograve; abbia alcuna conseguenza sulla disponibilit&agrave; del Servizio o sulla sua operativit&agrave;.Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori sono incoraggiati a contattare il Titolare.L&rsquo;eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione ha la finalit&agrave; di fornire il Servizio richiesto dall&apos;Utente, oltre alle ulteriori finalit&agrave; descritte nel presente documento e nella Cookie Policy.</p>
  <p>L&apos;Utente si assume la responsabilit&agrave; dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione.</p>
</div>
<div><strong>
<br/>
<h5><strong>Modalit&agrave; e luogo del trattamento dei Dati raccolti</strong></h5>
  </strong><strong>
     Modalit&agrave; di trattamento
  </strong>
  <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l&rsquo;accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali.Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalit&agrave; organizzative e con logiche strettamente correlate alle finalit&agrave; indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell&rsquo;organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, societ&agrave; informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L&rsquo;elenco aggiornato dei Responsabili potr&agrave; sempre essere richiesto al Titolare del Trattamento.</p><strong>
     Luogo
  </strong>
  <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.I Dati Personali dell&rsquo;Utente potrebbero essere trasferiti in un paese diverso da quello in cui l&rsquo;Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l&rsquo;Utente pu&ograve; fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p><strong>
   Periodo di conservazione
  </strong>
  <p>Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalit&agrave; per la quale sono stati raccolti e potrebbero essere conservati per un periodo pi&ugrave; lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.</p>
</div>
<div></div>
<div></div>
<div><strong>
<br/>
<h5><strong>Cookie Policy</strong></h5>

  </strong>
  <p>Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di pi&ugrave;, gli Utenti possono consultare la Cookie Policy.</p>
</div>
<p><strong></strong></p>
<br/>

<h5><strong>Ulteriori informazioni per gli utenti</strong></h5><strong>
  Base giuridica del trattamento
</strong>
<p></p>
<p>Il Titolare tratta Dati Personali relativi all&rsquo;Utente in caso sussista una delle seguenti condizioni:</p>
<ul>
  <li>l&rsquo;Utente ha prestato il consenso per una o pi&ugrave; finalit&agrave; specifiche; Nota: in alcuni ordinamenti il Titolare pu&ograve; essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso dell&rsquo;Utente o un&rsquo;altra delle basi giuridiche specificate di seguito, fino a quando l&rsquo;Utente non si opponga (&ldquo;opt-out&rdquo;) a tale trattamento. Ci&ograve; non &egrave; tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;</li>
  <li>il trattamento &egrave; necessario all&apos;esecuzione di un contratto con l&rsquo;Utente e/o all&apos;esecuzione di misure precontrattuali;</li>
  <li>il trattamento &egrave; necessario per adempiere un obbligo legale al quale &egrave; soggetto il Titolare;</li>
  <li>il trattamento &egrave; necessario per l&apos;esecuzione di un compito di interesse pubblico o per l&apos;esercizio di pubblici poteri di cui &egrave; investito il Titolare;</li>
  <li>il trattamento &egrave; necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</li>
</ul>
<p>&Egrave; comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>
<p><strong></strong></p>
<br/>


<h5><strong>Ulteriori informazioni sul tempo di conservazione</strong></h5>
<p></p>
<p>Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalit&agrave; per la quale sono stati raccolti e potrebbero essere conservati per un periodo pi&ugrave; lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti.</p>
<p>Pertanto:</p>
<ul>
  <li>I Dati Personali raccolti per scopi collegati all&rsquo;esecuzione di un contratto tra il Titolare e l&rsquo;Utente saranno trattenuti sino a quando sia completata l&rsquo;esecuzione di tale contratto.</li>
  <li>I Dati Personali raccolti per finalit&agrave; riconducibili all&rsquo;interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L&rsquo;Utente pu&ograve; ottenere ulteriori informazioni in merito all&rsquo;interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</li>
</ul>
<p>Quando il trattamento &egrave; basato sul consenso dell&rsquo;Utente, il Titolare pu&ograve; conservare i Dati Personali pi&ugrave; a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo pi&ugrave; lungo in ottemperanza ad un obbligo di legge o per ordine di un&rsquo;autorit&agrave;.Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilit&agrave; dei Dati non potranno pi&ugrave; essere esercitati.</p>
<p><strong></strong></p>
<br/>

<h5><strong>Diritti dell&rsquo;Utente</strong></h5>
<p></p>
<p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
<p>In particolare, nei limiti previsti dalla legge, l&rsquo;Utente ha il diritto di:</p>
<ul>
  <li><strong><strong>revocare il consenso in ogni momento.</strong></strong> L&rsquo;Utente pu&ograve; revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.</li>
  <li><strong><strong>opporsi al trattamento dei propri Dati.</strong></strong> L&rsquo;Utente pu&ograve; opporsi al trattamento dei propri Dati quando esso avviene in virt&ugrave; di una base giuridica diversa dal consenso.</li>
  <li><strong><strong>accedere ai propri Dati.</strong></strong> L&rsquo;Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.</li>
  <li><strong><strong>verificare e chiedere la rettificazione.</strong></strong> L&rsquo;Utente pu&ograve; verificare la correttezza dei propri Dati e richiederne l&rsquo;aggiornamento o la correzione.</li>
  <li><strong><strong>ottenere la limitazione del trattamento.</strong></strong> L&rsquo;Utente pu&ograve; richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratter&agrave; i Dati per alcun altro scopo se non la loro conservazione.</li>
  <li><strong><strong>ottenere la cancellazione o rimozione dei propri Dati Personali.</strong></strong> L&rsquo;Utente pu&ograve; richiedere la cancellazione dei propri Dati da parte del Titolare.</li>
  <li><strong><strong>ricevere i propri Dati o farli trasferire ad altro titolare.</strong></strong> L&rsquo;Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare.</li>
  <li><strong><strong>proporre reclamo.</strong></strong> L&rsquo;Utente pu&ograve; proporre un reclamo all&rsquo;autorit&agrave; di controllo della protezione dei dati personali competente o agire in sede giudiziale.</li>
</ul>
<p>Gli Utenti hanno diritto di ottenere informazioni in merito alla base giuridica per il trasferimento di Dati all&apos;estero incluso verso qualsiasi organizzazione internazionale regolata dal diritto internazionale o costituita da due o pi&ugrave; paesi, come ad esempio l&rsquo;ONU, nonch&eacute; in merito alle misure di sicurezza adottate dal Titolare per proteggere i loro Dati.</p>
<p><strong></strong></p>
<br/>

<h5><strong>Dettagli sul diritto di opposizione</strong></h5>
<p></p>
<p>Quando i Dati Personali sono trattati nell&rsquo;interesse pubblico, nell&rsquo;esercizio di pubblici poteri di cui &egrave; investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
<p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalit&agrave; di marketing diretto, possono opporsi al trattamento in qualsiasi momento, gratuitamente e senza fornire alcuna motivazione. Qualora gli Utenti si oppongano al trattamento per finalit&agrave; di marketing diretto, i Dati Personali non sono pi&ugrave; oggetto di trattamento per tali finalit&agrave;. Per scoprire se il Titolare tratti Dati con finalit&agrave; di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento.</p>
<p></p>
<br/>

<h5><strong>Come esercitare i diritti</strong></h5>
<p></p>
<p>Per esercitare i propri diritti, gli Utenti possono indirizzare una richiesta ai recapiti del Titolare indicati in questo documento. La richiesta pu&ograve; essere depositate gratuitamente e il Titolare risponder&agrave; nel pi&ugrave; breve tempo possibile, in ogni caso entro un mese, fornendo all&rsquo;Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del trattamento saranno comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ci&ograve; si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all&apos;Utente tali destinatari qualora egli lo richieda.</p>
<div></div>
<br/>
<div><strong>
      <h5><strong>Ulteriori informazioni sul trattamento</strong></h5>
  </strong>
  <strong>Difesa in giudizio
  </strong>
  <p>I Dati Personali dell&rsquo;Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell&apos;utilizzo di questa Applicazione o dei Servizi connessi da parte dell&rsquo;Utente.L&rsquo;Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorit&agrave; pubbliche.</p>
  <strong>Informative specifiche</strong>
  <p>Su richiesta dell&rsquo;Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all&apos;Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p><strong>
      <strong>Log di sistema e manutenzione</strong>
  </strong>
  <p>Per necessit&agrave; legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l&rsquo;indirizzo IP Utente.</p><strong>
      Informazioni non contenute in questa policy
  </strong>
  <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p><strong>
      Modifiche a questa privacy policy
  </strong>
  <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa Applicazione nonch&eacute;, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui &egrave; in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.Qualora le modifiche interessino trattamenti la cui base giuridica &egrave; il consenso, il Titolare provveder&agrave; a raccogliere nuovamente il consenso dell&rsquo;Utente, se necessario.</p>
</div>

<br/>

<AccordionEl />
      </div>
      </>
  )
}
