import React, {useState, useEffect} from "react";
import { BsPrinter, BsDownload, BsTrash, BsShare, BsEye
} from "react-icons/bs";
import { Link } from "react-router-dom";
import UserIcon from "../../../assets/images/small_user.svg";
import api, { report_endpoint } from "../../../api/api";
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import jsPDF from 'jspdf';
import { Modal } from "react-bootstrap";

import MyDocument from "./ReportPdf";
import ShareChat from "../../ShareChat";

const ReportCard = ({
	id,
	report,
	fromDate,
	toDate,
	unit,
	owner,
	type,
	deleteReport,
	item,
	handleSelection,
	selectedItems
}) => {
	const deleteCard = async () => {
		deleteReport(id);
		try {
			await api().delete(`${report_endpoint}${id}`);
		} catch (err) {
			console.log(err);
		}
	};

	const reportType = unit?.length > 0 ? "unit" : "owner"


	
  
	const handlePreview = () => {
	  if (pdfUrl) window.open(pdfUrl, '_blank');
	};

	const [pdfUrl, setPdfUrl] = useState(null);

	
	console.log(item)

	const convertiDataInFormatoItaliano = (dataStringa) => {
		// Utilizza l'oggetto Date di JavaScript per analizzare la stringa di data
		const data = new Date(dataStringa);
	  
		// Ottieni il giorno, il mese e l'anno dalla data
		const giorno = data.getDate().toString().padStart(2, '0'); // Aggiunge uno zero davanti se è necessario
		const mese = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() ritorna un valore da 0 a 11
		const anno = data.getFullYear();
	  
		// Costruisci la stringa di data nel formato desiderato
		return `${giorno}/${mese}/${anno}`;
	  };

	  const dataIt = convertiDataInFormatoItaliano(item.cdat);

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false)
	};
	const handleShow = () => setShow(true);

	return (
		<>
		<div
			className="general-card mt-2 flex-column flex-sm-row align-items-start align-items-sm-center ms-0"
			>
			<div className="d-flex">
				<input
						type="checkbox"
						checked={selectedItems.includes(id)}
						onChange={(e) => handleSelection(e, id)}
						className="me-2"
					/>
				<div className="d-flex flex-column flex-auto">
					<span className="general-card-title">
					{`Rendiconto del ${dataIt}  ${reportType === "unit" ? `- ${unit[0].name}` : (owner && owner.length > 0 && owner[0].surname && owner[0].name ? `- ${owner[0].surname + " " + owner[0].name}` : '')}`}
					</span>
					<div className="d-flex">
						<div className="me-2">
							{reportType == "unit"  && <i class="bi bi-house text-dark me-2"></i>}
							{reportType == "owner"  && <i class="bi bi-person text-dark me-2"></i>}
							<span className="small-span">{reportType == "unit" ? unit[0].name : `${owner?.[0]?.surname + " " + owner?.[0]?.name}`}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex mt-2 mt-sm-0">
{/* 				<ShareChat elId={id}/>
 */}
				<div>
				<button
				type="button"
				className="btn round-icon-btn"
				onClick={() => window.open(pdfUrl, '_blank')}
				disabled={!pdfUrl}

				>
				<BsEye />

				</button>
				</div>
				<div>
				

				<PDFDownloadLink
				document={<MyDocument el={item} />}
				fileName="report.pdf"
				style={{ textDecoration: 'none', color: 'inherit' }}
				>
				{({ blob, url, loading, error }) => {

					 console.log(item, "ITEM")
					if (!loading && url) {
					setPdfUrl(url);
					}
					return loading ? (
					''
					) : (
					<button type="button" className="btn round-icon-btn">
						<BsDownload />
					</button>
					);
				}}
				</PDFDownloadLink>

				</div>
				<div>
					<button
						type="button"
						className="btn round-icon-btn"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleShow()
						}}>
						<BsTrash />
					</button>
				</div>
			</div>
		</div>

		<Modal show={show} onHide={() => handleClose()} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) =>{ 
				e.preventDefault()
				e.stopPropagation()
				deleteCard()
				handleClose()
				}}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={() => handleClose()}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
		</>
	);
};

export default ReportCard;
