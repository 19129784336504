import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoChat () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Chat"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Effettuare l'accesso" />
            <TreeItem nodeId="2" label="Codice OTP" />

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Chat</h1>
      <h2 style={{ color:'transparent', height: 0}}>Chat</h2>

      <p >La chat ti permetto di comunicare, scambiare documentazione ed effettuare/ricevere pagamenti sulla nostra piattaforma</p>
      <img loading="lazy" src=""  />
      <h2 id="2">Struttura</h2>
      <p >A sinistra trovi la lista delle conversazioni attive, mentre a destra hai il pannello della Chat.</p>
      <Tips variant="automatization" content="Quando crei un attività in SAL, in automatico viene creata una chat relativa in questa sezione." />
      <h3>Barra superiore</h3>
      <p>Nella barra in alto della chat troverai il nome della chat, e la lista dei partecipanti</p>
      <p>A sinistra trovi l'icona per la ricerca e  cliccando sull'icona con i 3 puntini, si aprirà un sottomenu</p>
      <ul>
        <li><p >Info attività</p></li>
        <li><p >Contenuto attività</p></li>
        <li><p >Riassunto Contabilità</p></li>
      </ul>

      <img loading="lazy" src="immagine barra"  />

  
      <p className="subtitle">Info attività</p>
      <p>Da questa sezione potrai modificare il nome della chat, aggiungere una descrizione o eliminare la conversazione</p>

      <p className="subtitle">Contenuto attività</p>
      <p>In questa sezione trovi un riepilogo dei messaggi "speciali" inviati in chat. Per messaggi speciali si intendono, i messaggi non di testo e in particolare</p>:
      <ul>
        <li><p >Foto e video</p></li>
        <li><p >Contabilità, messaggi di spesa o preventivo</p></li>
        <li><p >Pagamenti</p></li>
        <li><p >Checklist e Verbali</p></li>
        <li><p >Allegati</p></li>
      </ul>
      <p>Cliccando sulla card relativa, verrai reindirizzzato al messaggio in questione</p>

      <p className="subtitle">Riassunto Contabilità</p>
      <p>La contabilità ti permette di visualizzare i costi relativi alla chat in questione</p>
      <p>I costi vengono eleborati attraverso la chat ad esso correlata, in particolare tramite l'aggiunta di Preventivi e Spese.</p>
      <p>Per la singola chat puoi impostare una "PREVISIONE DI SPESA", che indica quando prevedi di spendere per una certa attività e potrai confrontare con le spese effettive.</p>


      <h3>Parte inferiore della chat</h3>
      <p>Cliccando sul pulsante "+" in basso a sinistra della chat potrai inviare in chat messaggi di tipo</p>
      <ul>
        <li><p>Documenti</p></li>
        <li><p>Contabilità, messaggi di spesa o preventivo</p></li>
        <li><p>Pagamenti</p></li>
        <li><p>Checklist e Verbali</p></li>
        <li><p>Eventi</p></li>
        <li><p>Firma</p></li>
      </ul>

      <p className="subtitle">Contabilità</p>
      <p>Tramite chat puoi inviare e ricevere Preventivi. </p>
      <p>I preventivi che accetti confluiranno nel Riassunto Contabilità di questa attività</p>
      <p>Le spese invece vi confluiscono direttamente, e non necessitano accettazione </p>

      <p className="subtitle">Pagamenti</p>
      <p>Definisci causale e importo, e riscuoti i pagamenti direttamente dalla chat.</p>
      <Tips variant="info" content="Per poter inviare una richiesta di pagamento devi avere creato un account di pagamento in Impostazioni > Profilo." />
      <img loading="lazy" src="foto messaggio di pagamento"  />

      <p className="subtitle">Checklist e Verbali</p>
      <p>
        Invia checklist e verbali in chat, che gli altri utenti potranno visualizzare, approvare e firmare.
      </p>

      <p className="subtitle">Evento</p>
      <p>
        Crea e condividi un evento con i membri della chat
      </p>

      <p className="subtitle">Firma Intera Chat</p>
      <p>
        La firma dell'intera chat, notarizza in blockchain tutti i messaggi precedenti alla firma.
        Questi diventano non modificabili o eliminabili
      </p>


      <h3>Eliminare e/o Modificare un messaggio</h3>
      <p className="subtitle">Messaggi di testo</p>
      <p>Clicca sull'icona con i 3 pallini vicino al messaggio e seleziona l'azione desiderata.</p>
      <img loading="lazy" src="immagine azioni"  />


      <p className="subtitle">Messaggi speciali</p>
      <p>Per modificare un messaggio speciale clicca sull'icona presente nel corpo del messaggio</p>
      <img loading="lazy" src="immagine azioni"  />

      <h3>Altre azioni su un messaggio</h3>
      <p className="subtitle">PRESA VISIONE</p>
      <p>Appare nei messaggi di tipo preventivo o checklist, permette di confermare la presa visione del messaggio in questione </p>

      <img loading="lazy" src="presa visione gif"  />

      <p className="subtitle">ACCETTAZIONE</p>
      <p>Appare nei messaggi di tipo preventivo o checklist. 
        Nel caso di dei preventivi, se un preventivo viene accetatto, questo confluisce in "Riassunto contabilità" della chat.
      </p>

      <img loading="lazy" src="accettazione gif"  />

      <p className="subtitle">FIRMA SINGOLO MESSAGGIO</p>
      <p> La firma permette di salvare in blockchain e notarizzare il messaggio.
        Dopo tale azione non sarà possibile eliminare o modificare il messaggio in questione.
      </p>

      <img loading="lazy" src="accettazione gif"  />





    </div>
  );
}

