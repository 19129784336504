import React, { useState } from 'react';
import { CSVDownload } from 'react-csv';
import { BsDownload, BsPrinter } from 'react-icons/bs';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import logoPng from '../../../assets/images/logo-red-big.png';
import { format, parseISO, isValid } from 'date-fns';

// Funzione per verificare se una stringa è una data ISO valida
const isISODate = (str) => {
  const date = parseISO(str);
  return isValid(date) && str.includes('-');
};

/*
  Componente per esportazione di dati globali in csv e pdf.
  Prende come props un oggetto o un array di dati da esportare, e un oggetto di mapping con le traduzioni delle chiavi da inglese e italiano

  VALUTARE SE RENDERE BOTTONE UI VARIABILE, PASSANDO DELLE CLASSI => fatto, usare props "classes"
*/

const ExportButton = ({ data, mapping, classes = '', isButton = true, showPrint = false }) => {
  const [download, setDownload] = useState(false);
  const [exportType, setExportType] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleExport = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    setExportType(type);
    setDropdownOpen(false);
    if (type === 'pdf') {
      const doc = new jsPDF();
      const logoWidth = 26;
      const logoHeight = 13;
      doc.addImage(logoPng, 'PNG', 15, 15, logoWidth, logoHeight); // Aggiungi il logo PNG
      doc.setFontSize(12); // Imposta la dimensione del testo dell'intestazione

      const dataArray = Array.isArray(data) ? data : [data]; // Trasforma il dato in un array se non lo è già

      dataArray.forEach((item, index) => {
        const formattedData = Object.entries(item)
          .filter(([key, value]) => {
            if (key === 'registrationContractExpenses' || 
            key === 'subEntrance' ||
            key === 'bankAccount' ||
            key === 'relationData' ||
            key === 'electricityPlan' ||
            key === 'gasPlan' ||
            key === 'coldWaterPlan' ||
            key === 'partition' ||
            key === 'type' || 
            key === 'subjectType' ||
            key === 'unitData' ||
            key === 'unitID' ||
            key === 'realEstateType' ||
            key === 'image' ||
            key === 'realEstateManagementID' ||
            key === 'replyID' ||
            key === 'messageType' ||
            key === "chatGroupID" ||
            key === "senderEmail" ||
            key === 'attachments' ||
            key == 'planimetries' ||
            key == 'content'
            ) {
          return false; 
        }
    
        if (Array.isArray(value)) {
          return value.length > 0 && (key === 'owners' || key === 'conductors' || key === 'suppliers'  || key === 'units' || key === 'realEstateManagements' || key == 'activities' || key === 'condominium');
        } else {
          return (typeof value === 'string' || typeof value === 'number') && value.toString().trim() !== '' && value !== '""';
    
        }
          })
          .map(([key, value]) => {

            if (typeof value === 'string' && isISODate(value)) {
              value = format(parseISO(value), 'dd/MM/yyyy - HH:mm');
            }
          /*   if (typeof value === 'string') {
              const dateValue = parseISO(value);
              if (isValid(dateValue)) {
                value = format(dateValue, 'dd/MM/yyyy - HH:mm');
              }
            } */

            if (key === 'owners' || key === 'conductors' || key === 'suppliers') {
              const names = value?.length > 0 && value.filter(obj => obj.name && obj.surname)
                .map(obj => `${obj.name} ${obj.surname}`)
                .join(', ');
        
              return {
                Proprietà: mapping[key] || key,
                Valore: names,
              };
            }
        
            if (key === 'units' || key === 'realEstateManagements' || key === 'activities' || key === 'condominium') {
              const names = value
                .filter(obj => obj.name)
                .map(obj => obj.name)
                .join(', ');
            
              return {
                Proprietà: mapping[key] || key,
                Valore: names,
              };
            }
            return {
              Proprietà: mapping[key] || key,
              Valore: Array.isArray(value) ? value.join(', ') : value,
            };
          });

        // Aggiungi una nuova pagina per ogni oggetto nell'array
        if (index > 0) {
          doc.addPage();
        }

        // Aggiungi la riga "Data di esportazione" con il valore corrispondente
        const today = new Date();
        const formattedDate = format(today, 'dd/MM/yyyy - HH:mm');
        const exportDateRow = { Proprietà: 'Data di esportazione', Valore: formattedDate };
        formattedData.unshift(exportDateRow);

        autoTable(doc, {
          head: [['Proprietà', 'Valore']],
          body: formattedData.map(({ Proprietà, Valore }) => [Proprietà, Valore]),
          startY: 35, // Inizia la tabella dopo il logo
          theme: 'grid',
          styles: {
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
            cellPadding: 2,
          },
          headStyles: {
            fillColor: [131, 17, 47],
            textColor: [255, 255, 255],
          },
        });
      });

      doc.save('data.pdf');
    } else if (type === 'print') {
        //window.print();

        const doc = new jsPDF();
        const logoWidth = 26;
        const logoHeight = 13;
        doc.addImage(logoPng, 'PNG', 15, 15, logoWidth, logoHeight); // Aggiungi il logo PNG
        doc.setFontSize(12); // Imposta la dimensione del testo dell'intestazione
  
        const dataArray = Array.isArray(data) ? data : [data]; // Trasforma il dato in un array se non lo è già
  
        dataArray.forEach((item, index) => {
          const formattedData = Object.entries(item)
            .filter(([key, value]) => {
              if (key === 'registrationContractExpenses' || 
              key === 'subEntrance' ||
              key === 'bankAccount' ||
              key === 'relationData' ||
              key === 'electricityPlan' ||
              key === 'gasPlan' ||
              key === 'coldWaterPlan' ||
              key === 'partition' ||
              key === 'type' || 
              key === 'subjectType' ||
              key === 'unitData' ||
              key === 'unitID' ||
              key === 'realEstateType' ||
              key === 'image' ||
              key === 'realEstateManagementID' ||
              key === 'replyID' ||
              key === 'messageType' ||
              key === "chatGroupID" ||
              key === "senderEmail" ||
              key === 'attachments' ||
              key == 'planimetries' ||
              key == 'content'
              ) {
            return false; 
          }
      
          if (Array.isArray(value)) {
            return value.length > 0 && (key === 'owners' || key === 'conductors' || key === 'suppliers'  || key === 'units' || key === 'realEstateManagements' || key == 'activities' || key === 'condominium');
          } else {
            return (typeof value === 'string' || typeof value === 'number') && value.toString().trim() !== '' && value !== '""';
      
          }
            })
            .map(([key, value]) => {
  
              if (typeof value === 'string') {
                const dateValue = parseISO(value);
                if (isValid(dateValue)) {
                  value = format(dateValue, 'dd/MM/yyyy - HH:mm');
                }
              }
  
              if (key === 'owners' || key === 'conductors' || key === 'suppliers') {
                const names = value
                  .filter(obj => obj.name && obj.surname)
                  .map(obj => `${obj.name} ${obj.surname}`)
                  .join(', ');
          
                return {
                  Proprietà: mapping[key] || key,
                  Valore: names,
                };
              }
          
              if (key === 'units' || key === 'realEstateManagements' || key === 'activities' || key === 'condominium') {
                const names = value
                  .filter(obj => obj.name)
                  .map(obj => obj.name)
                  .join(', ');
              
                return {
                  Proprietà: mapping[key] || key,
                  Valore: names,
                };
              }
              return {
                Proprietà: mapping[key] || key,
                Valore: Array.isArray(value) ? value.join(', ') : value,
              };
            });
  
          // Aggiungi una nuova pagina per ogni oggetto nell'array
          if (index > 0) {
            doc.addPage();
          }
  
          // Aggiungi la riga "Data di esportazione" con il valore corrispondente
          const today = new Date();
          const formattedDate = format(today, 'dd/MM/yyyy - HH:mm');
          const exportDateRow = { Proprietà: 'Data di esportazione', Valore: formattedDate };
          formattedData.unshift(exportDateRow);
  
          autoTable(doc, {
            head: [['Proprietà', 'Valore']],
            body: formattedData.map(({ Proprietà, Valore }) => [Proprietà, Valore]),
            startY: 35, // Inizia la tabella dopo il logo
            theme: 'grid',
            styles: {
              textColor: [0, 0, 0],
              lineColor: [0, 0, 0],
              lineWidth: 0.1,
              cellPadding: 2,
            },
            headStyles: {
              fillColor: [131, 17, 47],
              textColor: [255, 255, 255],
            },
          });
        });

        // Al termine della generazione del PDF, avvia la stampa automatica
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
  
    
    } else {
      setDownload(true);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const csvData = Array.isArray(data)
  ? data.flatMap(d =>
    Object.entries(d)
      .filter(([key, value]) => {
        if (key === 'registrationContractExpenses' || 
        key === 'subEntrance' ||
        key === 'bankAccount' ||
        key === 'relationData' ||
        key === 'electricityPlan' ||
        key === 'gasPlan' ||
        key === 'coldWaterPlan' ||
        key === 'partition' ||
        key === 'type' || 
        key === 'subjectType' ||
        key === 'unitData' ||
        key === 'unitID' ||
        key === 'realEstateType' ||
        key === 'image' ||
        key === 'realEstateManagementID' ||
        key === 'replyID' ||
        key === 'messageType' ||
        key === "chatGroupID" ||
        key === "senderEmail" ||
        key === 'attachments' || 
        key == 'planimetries'
        ) {
      return false; 
    }

    if (Array.isArray(value)) {
      return value.length > 0 && (key === 'owners' || key === 'conductors' || key === 'suppliers'  || key === 'units' || key === 'realEstateManagements' || key == 'activities' || key === 'condominium');
    } else {
      return (typeof value === 'string' || typeof value === 'number') && value.toString().trim() !== '' && value !== '""';

    }
      })
      .map(([key, value]) => {

        if (typeof value === 'string') {
          const dateValue = parseISO(value);
          if (isValid(dateValue)) {
            value = format(dateValue, 'dd/MM/yyyy - HH:mm');
          }
        }

        if (key === 'owners' || key === 'conductors' || key === 'suppliers') {
          const names = value
            .filter(obj => obj.name && obj.surname)
            .map(obj => `${obj.name} ${obj.surname}`)
            .join(', ');
    
          return {
            Proprietà: mapping[key] || key,
            Valore: names,
          };
        }
    
        if (key === 'units' || key === 'realEstateManagements' || key === 'activities' || key === 'condominium') {
          const names = value
            .filter(obj => obj.name)
            .map(obj => obj.name)
            .join(', ');
        
          return {
            Proprietà: mapping[key] || key,
            Valore: names,
          };
        }
        return {
          Proprietà: mapping[key] || key,
          Valore: Array.isArray(value) ? value.join(', ') : value,
        };
      })
  )
  :  Object.entries(data)
  .filter(([key, value]) => {
    if (key === 'registrationContractExpenses' || 
    key === 'subEntrance' ||
    key === 'bankAccount' ||
    key === 'relationData' ||
    key === 'electricityPlan' ||
    key === 'gasPlan' ||
    key === 'coldWaterPlan' ||
    key === 'partition' ||
    key === 'type' || 
    key === 'subjectType' ||
    key === 'unitData' ||
    key === 'unitID' ||
    key === 'realEstateType' ||
    key === 'image' ||
    key === 'realEstateManagementID' ||
    key === 'replyID' ||
    key === 'messageType' ||
    key === "chatGroupID" ||
    key === "senderEmail" ||
    key === 'attachments'
    ) {
  return false; 
}

if (Array.isArray(value)) {
  return value.length > 0 && (key === 'owners' || key === 'conductors' || key === 'suppliers'  || key === 'units' || key === 'realEstateManagements' || key == 'activities' || key === 'condominium');
} else {
  return (typeof value === 'string' || typeof value === 'number') && value.toString().trim() !== '' && value !== '""';

}
  })
  .map(([key, value]) => {

    if (typeof value === 'string') {
      const dateValue = parseISO(value);
      if (isValid(dateValue)) {
        value = format(dateValue, 'dd/MM/yyyy - HH:mm');
      }
    }

    if (key === 'owners' || key === 'conductors' || key === 'suppliers') {
      const names = value?.length > 0 && Array.isArray(value)
      &&
      value.filter(obj => obj.name && obj.surname)
        .map(obj => `${obj.name} ${obj.surname}`)
        .join(', ');

      return {
        Proprietà: mapping[key] || key,
        Valore: names,
      };
    }

    if (key === 'units' || key === 'realEstateManagements' || key === 'activities' || key === 'condominium') {
      const names = value
        .filter(obj => obj.name)
        .map(obj => obj.name)
        .join(', ');
    
      return {
        Proprietà: mapping[key] || key,
        Valore: names,
      };
    }
    return {
      Proprietà: mapping[key] || key,
      Valore: Array.isArray(value) ? value.join(', ') : value,
    };
  })

  console.log(isButton, "IS BUTTON")
  

  return (
    <div className="d-flex">
      {download && exportType === 'csv' && (
        <CSVDownload
          data={csvData}
          target="_blank"
          headers={['Proprietà', 'Valore']}
          filename="data.csv"
        />
      )}
      <Dropdown show={dropdownOpen}>
        <Dropdown.Toggle as={isButton ? 'button' : 'p'} className={`${classes} btn-custom-export`} onClick={handleClick}>
          {isButton ? <BsDownload /> : 'Esporta'}
        </Dropdown.Toggle>
        <Dropdown.Menu className="px-2">
          <Dropdown.Item onClick={(e) => handleExport(e, 'csv')}>
            CSV
            <OverlayTrigger
              placement="auto"
              delay={{ show: 1000, hide: 300 }}
              overlay={<Tooltip className="tooltipCalc">L'importazione in formato CSV (Comma-Separated Values) è un modo semplice ed efficace per esportare e condividere dati in un formato tabellare compatibile con molti software. Può essere aperto e visualizzato con facilità utilizzando applicazioni come Excel, Google Fogli, Numbers, Blocco note etc.</Tooltip>}
            >
              <i className="bi bi-info-circle ms-2"></i>
            </OverlayTrigger>
          </Dropdown.Item>
          <Dropdown.Item onClick={(e) => handleExport(e, 'pdf')}>PDF</Dropdown.Item>
        {/*   <Dropdown.Item onClick={(e) => handleExport(e, 'print')}>
            Stampa
          </Dropdown.Item>  */}
        </Dropdown.Menu>
      </Dropdown>
      
      {showPrint && <Dropdown.Toggle as={isButton ? 'button' : 'p'} className={`${classes} btn-custom-export ms-2`} onClick={(e) => handleExport(e, 'print')}>
          <BsPrinter /> 
        </Dropdown.Toggle>}
    </div>
  );
};

export default ExportButton;
