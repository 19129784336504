import { SET_NAME, SET_SEARCH } from "../actions/types";

const initialState = {
  name: "",
  searchName: "",
};

function profileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NAME:
      return {
        ...initialState,
        name: payload,
      };
    case SET_SEARCH:
      return {
        ...initialState,
        searchName: payload,
      };
    // case USER_TEMPLATE:
    //   return {
    //     ...initialState,
    //     user_template: payload,
    //   };
    default:
      return state;
  }
}

export default profileReducer;
