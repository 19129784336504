import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"





import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import SearchInput from "../SearchInput";



export default function File(){

    //HANDLE SEARCH
    const [searchTerm, setSearchTerm] = useState("");

    //BISOGNA CAPIRE COME SARA' IMPOSTATA L'API PER I FILE ELIMINATI


    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">File</p>
                    <p className="fs-14 opacity-75">Qui potrai trovare i file eliminati. Verrano conservati per 30gg prima della cancellazione definitiva</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-2">
                    <Col md={3} className="px-0 mb-3">
                        <SearchInput
                            setSearch={(term) => {
                            setSearchTerm(term);
                            }}
                        />
                    </Col>
                    <Col sm={12} className="px-0">
                        <div className="firmeCard d-flex flex-column flex-md-row  align-items-start align-items-md-center justify-content-md-between">
                            <div className="d-flex align-items-center mb-2">
                                <div className="btn round-icon-btn">
                                    <BsTrash />
                                </div>
                                <div className="text-start ms-2">
                                    <p className="fs-16 ">Nome file</p>
                                    <p  className="fs-12 ">03/04/2022, 16:23</p>
                                </div>
                                
                            </div>
                            <div>
                                <ButtonRm variant="outline">Elimina definitivamente</ButtonRm>
                                <ButtonRm variant="outline" className="ms-2">Ripristina</ButtonRm>
                            </div>
                            
                    </div>
                    </Col>
                </Row> 

            </div >
            
        </>

        
    )
}