import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams, useNavigate } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import MovimentoE from "./edit/MovimentoE";
import FileCard from "./Tipologia/FileCard";
import PrintDwonloadIcons from "./edit/PrintDwonloadIcons";
import TopHeading from "../../TopHeading";
import api, { first_note_endpoint , BASE_URL } from "../../../api/api";
import { Link } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import ButtonRm from "../../ButtonRm";
import CustomToggle from "../../CustomToggle";

import VersamentoE from "./edit/VersamentoE";
import PrelievoE from "./edit/PrelievoE";
import TrasferimentoE from "./edit/TrasferimentoE";

import axios from "axios";
import mimeTypes from "../../../allegatiType";

import { Col, Breadcrumb } from "react-bootstrap";

import { formattedTimestamp } from "../../../utils/dateManipulation";

import CustomBadge from "../../CustomBadge";

export default function ModificationDocument() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [state, setState] = useState(1);
  const [formData, setformData] = useState({});
  const [file, setFile] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  //DATI EDITABILI
  const [data, setData] = useState({});

  //GESTIONE NUOVI ALLEGATI 
const [newAttachments, setNewAttachments] = useState([]);

const handleChange = (e) => {
  let temp = [...newAttachments];
  setNewAttachments([...temp, ...e.target.files]);
};

  const init = () => {
    setData({
      typology: formData.typology,
      account: formData.account,
      subAccount: formData.subAccount,
      operationDescription: formData.operationDescription,
      operationDate: formData.operationDate,
      startDate: formData.startDate,
      endDate: formData.endDate,
      taxable: formData.taxable,
      billDate: formData.billDate,
      vatCode: formData.vatCode,
      paymentMethod: formData.paymentMethod,
      accountableResource: formData.accountableResource,
      amount: formData.amount,
      modality: formData.modality,
      fromAccount: formData.fromAccount,
      toAccount: formData.toAccount,
      isEnteringAmount: formData.isEnteringAmount,
      attachment: formData.attachment,
      partition: formData.partition,
      conductors: formData.conductors,
      owners: formData.owners,
      addWithholdingTax: formData.addWithholdingTax,
      attachment: formData.attachment,
      billed: formData.billed,
      condominium: formData.condominium,
      conductors: formData.conductors,
      paid: formData.paid,
      suppliers: formData.suppliers,
      unit: formData.unit,
      withholdingTax: formData.withholdingTax
    });
  };

  useEffect(() => {
    init();
    setFile(formData.attachment)
    setNewAttachments([])
  }, [formData]);


  const getDocumentById = async () => {
    try {
      const res = await api().get(`${first_note_endpoint}${id}`);
      if (res.data) {
        setformData(res.data);
        setSelectedSuppliers(res.data.suppliers);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getDocumentById();
  }, []);

  const update = async (data) => {

    //FIX EDIT UNIT-CONDO-SUPPLIER-CONDUCTORS-OWNERS
    const newForm = { ...data }
    delete newForm.attachment
    delete newForm.unit;
    delete newForm.condominium;
    delete newForm.suppliers;
    delete newForm.conductors;
    delete newForm.owners;


    setformData(newForm );
    try {

      //GESTIONE ALLEGATI
      let formDataToSend = new FormData();
    
      newAttachments.forEach((file) => {
        const blob = new Blob([file]);
        formDataToSend.append("parts", blob, file.name);
      });

      let sentUpload = formDataToSend.get("parts") !== null;
      if (sentUpload) {
        await api().put(`${first_note_endpoint}upload/${id}`, formDataToSend);
      }

      await api().put(`${first_note_endpoint}${id}`, newForm );
    } catch (err) {
      console.log(err);
    }

    // TODO add relations
  };

  const deleteData = async (data) => {
    try {
      await api().delete(`${first_note_endpoint}${id}`, { ...data });
    } catch (err) {
      console.log(err);
    }
  };

  const setFileName = (index, new_name) => {
    let temp = file;
    temp[index].name = new_name;
    setFile(temp);
  };
  const deleteFile = (index) => {
    let temp = [...file];
    temp.splice(index, 1);
    setFile(temp);
  };

  const [edit_mode, setEditMode] = useState(false);


  const saveDocument = () => {
    update(data);
    setEditMode(false);
  };

  //ACCORDION
  const [activeKey, setActiveKey] = useState('');

  const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    typology: 'Tipologia',
    account: 'Conto',
    subAccount: 'Sottoconto',
    //administrator: initialValue?.administrator || "",
    operationDescription: 'Descrizione',

    operationDate: 'Data di competenza', //field competenza

    isEnteringAmount: 'Importo in entrata',
    taxable: 'Imponibile',
    vatCode: 'IVA',
    paymentMethod: 'Metodo di Pagamento',
    fromAccount: 'Dal conto',
    toAccount: 'Al conto',
    addWithholdingTax: 'Ritenuta',
    withholdingTax: 'Ritenuta acconto %',

    amount: 'Imponibile + IVA',
    modality:  'Modalità',

    attachment: 'Allegati',
    conductors: 'Conduttori',
    owners: 'Proprietari',
    suppliers:"Fornitori",
    condominium: 'Condominio',
    unit: 'Unità',
    typeBuilding: 'Tipo di immobile',
  }

  const downloadFile = async (name) => {
    const fileUrl = `${BASE_URL}api/v1/note/download/${id}/${name}`;

    const response = await axios({
    url: fileUrl,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token
      }`,
      "Content-Type": `application/json`,
      
    },
    });

    // ottieni l'estensione del file
    const fileExtension = name.split('.').pop();

    const mimeType = mimeTypes[fileExtension];

    // crea un nuovo Blob con il tipo MIME corretto
    const file = new Blob([response.data], { type: mimeType });

    // crea un URL per il Blob
    const fileURL = URL.createObjectURL(file);

    // scarica il file
    window.open(fileURL);
  };

  return (
    <>
      <div>
        {/* CHANGES MADE BY EMA - ADD RED BAR */}
        <TopHeading SmallHeading="" back_modal={false} />
        {/* CHANGES MADE BY EMA */}
        <div className="flex-grow-1 ">
        <Col md={12} className="my-3 mb-0 px-0">
				<Breadcrumb>
					<Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate('/prima-nota')
						}}>
						Prima nota
					</Breadcrumb.Item>
				
					<Breadcrumb.Item active={true} 
						className="settings-archive"
					>
					{formData?.operationDescription}

					</Breadcrumb.Item>
				</Breadcrumb>
			  </Col>
          <div className="d-flex flex-column">
            <h2 className="fs-24 fs-md-30 text-dark fw-semibold mt-3 mb-5 mb-md-0 text-capitalize">
              {formData.operationDescription}
            </h2>
            {formData?.cdat && <small>Creato il {formattedTimestamp(formData.cdat)}</small>}

          </div>
        </div>
        <div className="flex-shrink-0">
          <div
            className={`d-flex align-items-center ${edit_mode ? "d-none" : ""}`}
          >
            <button
              className="modi-btn fs-16 text-white rounded-3 w-197 text-center py-2 px-3 me-2"
              onClick={() => {
                setEditMode(true);
              }}
            >
              Modifica
            </button>
            <button
              type="button"
              onClick={handleShow}
              className="border border-secondary bg-white text-secondary rounded-3 py-2 px-3"
            >
              <BsTrash />
            </button>
          </div>
          <div
            className={`d-flex align-items-center ${edit_mode ? "" : "d-none"}`}
          >
            <button
              className="modi-btn fs-16 text-white rounded-3 w-197 text-center py-2 px-3 me-2"
              onClick={saveDocument}
            >
              Salva modifiche
            </button>
            <button
              type="button"
              onClick={() => {
                setEditMode(false);
              }}
              className="border border-secondary bg-white text-secondary rounded-3 py-2 px-3"
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
        </div>
      </div>
      {/* CHANGES MADE BY EMA - ADD MARGIN */}
      <Form className="mt-3">
        {/* CHANGES MADE BY EMA */}
        <div className="form-item-align-box d-block edit-container py-4">
          <div className={edit_mode ? "d-none" : ""}>
            <PrintDwonloadIcons item={formData} mapping={translationMapping}/>
          </div>
          <Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <div>
              {formData.typology === "Movimento" && 
                <MovimentoE
                  data={data}
                  setData={setData}
                  edit_mode={edit_mode}
                  setSelectedSuppliers={setSelectedSuppliers}
                  getSelectedSuppliers={selectedSuppliers}
                />
            }
            {formData.typology === "Versamento tra cassa e conto" && 
                <VersamentoE
                data={data}
                setData={setData}
                edit_mode={edit_mode}
                activeKey={activeKey}
              />
            }
            {formData.typology === "Prelievo da conto in cassa" && 
                <PrelievoE
                  data={data}
                  setData={setData}
                  edit_mode={edit_mode}
                  activeKey={activeKey}
                />
            }
            {formData.typology === "Trasferimento tra conti bancari" && 
                <TrasferimentoE
                data={data}
                setData={setData}
                edit_mode={edit_mode}
                activeKey={activeKey}
              />
            }
            </div>
            <Accordion.Item eventKey="20" className="mb-2 accordionHeader">
              <Card.Header>
              <CustomToggle eventKey="20" activeKey={activeKey}>Allegati</CustomToggle>
              </Card.Header>
              <Accordion.Body>
              <label
                htmlFor="input-file"
                className={`black-link my-2 ${edit_mode ? "" : "d-none"}`}
              >
                <BsPlusCircleFill className="plus-icon" />
                Aggiungi allegati
              </label>
              <input
                type="file"
                id="input-file"
                className="d-none"
                multiple={false}
                onChange={handleChange}
              ></input>
              {file?.length > 0
                ? file.map((item, index) => {
                    return (
                      <FileCard
                        key={index}
                        name={item}
                        setFileName={setFileName}
                        deleteFile={deleteFile}
                        id={index}
                        download={downloadFile}
                      />
                    );
                  })
                : ""}		

                {newAttachments.length > 0 ? (
					newAttachments.map((file, index) => {
						return (
						<FileCard
							key={index}
							name={file.name}
							id={index}
							download={() => {}}
							isNewAttachment={true}
						/>
						);
					})
					) : null}	
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questa prima nota?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <Link to="/prima-nota">
              <button
                type="button"
                className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
                onClick={deleteData}
              >
                Conferma
              </button>
            </Link>

            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={handleClose}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
