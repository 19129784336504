import React from "react";
import { useNavigate } from "react-router-dom";

import { TableCell, TableRow } from '@mui/material';


export default function DocumentCard(props) {
  let amountShow =
    props.counter > 0 ? (
      <td className="fs-14 fw-bold w-13p c-green d-none d-md-table-cell">{`€ ${props.counter.toFixed(2)}`}</td>
    ) : props.counter < 0 ? (
      <td className="fs-14 fw-bold w-13p c-red d-none d-md-table-cell">{`€ ${props.counter.toFixed(2)}`}</td>
    ) : (
      <td className="fs-14 fw-bold w-13p d-none d-md-table-cell">{`€ ${props.counter.toFixed(2)}`}</td>
    );

    const navigate = useNavigate()

    function handleNavigate(){
      navigate(`/prima-nota/${props.data.id}`)
    }


    // Verifica se tra le categorie selezionate ci sono le categorie "Conti"  
  const selectedContiCategory = props.selectedCategories.find(category => category.name === 'Conti');

  const hasSingleContoItem = selectedContiCategory && selectedContiCategory.items.length === 1;

  let contoItemName = '';
  if (hasSingleContoItem) {
    contoItemName = selectedContiCategory.items[0];
}
  


  // Se presente la categoria "Conti", ottieni i conti selezionati

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    return string;
  }

  return (
    <tr onClick={handleNavigate} className="custom-tr">
      <td>
      <input
						type="checkbox"
            onClick={(e) => e.stopPropagation()} // Prevent the click event from propagating to the row
						checked={props.selectedItems.includes(props.data.id)}
						onChange={(e) => props.handleSelection(e, props.data.id)}
						className="me-2"
					/>
      </td>
      <td>{props.data.operationDescription.length > 15
            ? `${capitalizeFirstLetter(props.data.operationDescription.substring(0, 15))}...`
            : capitalizeFirstLetter(props.data.operationDescription)}</td>
      <td>{new Date(props.data.operationDate).toLocaleDateString("it-IT")}</td>
      <td className="d-none d-md-table-cell">
        { props.data.fromAccount ? (props.data.fromAccount.length > 15
            ? `${capitalizeFirstLetter(props.data.fromAccount.substring(0, 15))}...`
            : capitalizeFirstLetter(props.data.fromAccount)) : ""
        }
      </td>
      <td className="d-none d-md-table-cell">
        { props.data.toAccount ? (props.data.toAccount.length > 15
            ? `${capitalizeFirstLetter(props.data.toAccount.substring(0, 15))}...`
            : capitalizeFirstLetter(props.data.toAccount)) : ""
        }
      </td>
      <td className="fs-14 fw-bold w-13p c-green d-none d-md-table-cell">
        {props.data.typology == "Movimento" && props.data.isEnteringAmount && props.data.amount !== null ? `€${props.data.amount.toFixed(2)} ` : ""}
        {!hasSingleContoItem && props.data.typology != "Movimento" && props.data.amount !== null ? `€${props.data.amount.toFixed(2)} ` : ""}
        {hasSingleContoItem && props.data.typology != "Movimento" && props.data.toAccount == contoItemName && props.data.amount !== null ? `€${props.data.amount.toFixed(2)} ` : ""}

        {/* SE FILTRO CONTI != TUTTI I CONTI E TYPOLOGY != MOVIMENTO => 
          IF PROPS.DATA.TOACCOUNT == FILTRO CONTO NAME => VALORE ANDRA QUI (IN ENTRATA)
        */}
      </td>
      <td className="fs-14 fw-bold w-13p c-red d-none d-md-table-cell">
        { props.data.typology == "Movimento" && !props.data.isEnteringAmount && props.data.amount !== null ? `€ ${props.data.amount.toFixed(2)}` : ""}
        {!hasSingleContoItem && props.data.typology != "Movimento" &&  props.data.amount !== null ? `€ ${props.data.amount.toFixed(2)}` : ""}
        {hasSingleContoItem && props.data.typology != "Movimento" && props.data.fromAccount == contoItemName &&  props.data.amount !== null ? `€ ${props.data.amount.toFixed(2)}` : ""}
        {/* SE FILTRO CONTI != TUTTI I CONTI E TYPOLOGY != MOVIMENTO => 
          IF PROPS.DATA.FROMACCOUNT == FILTRO CONTO NAME => VALORE ANDRA QUI (IN USCITA)
        */}
      </td>
      <td className={`fs-14 fw-bold w-13p ${!props.data.isEnteringAmount ? "c-red" : "c-green"  } d-table-cell d-md-none`}>
        {props.data.amount !== null ? `€ ${props.data.amount.toFixed(2)}` : ""}
      </td>
      {amountShow}
    </tr>
  );
}
