const translations = {
    // Proprietà generali
    grossCoveredMq: "Superficie coperta lorda",
  
    // Floor
    groundFloorWithAccess: "Piano terra con accesso carrabile",
    groundFloorWithoutAccess: "Piano terra senza accesso carrabile",
    basementWithAccess: "Seminterrato con accesso carrabile",
    basementWithoutAccess: "Seminterrato accessibile solo da scale",
  
    // Floor (Capannoni)
    groundFloor: "Piano terra",
    mezzanine: "Piano rialzato",
  
    // Access (Capannoni)
    articulatedVehicleAccess: "Accesso autoarticolato",
    withoutArticulatedVehicleAccess: "Senza accesso autoarticolato",
  
    // Age and state of the building
    years1To5ToRenovateTotallyBuilding: "1-5 anni da ristrutturare totalmente",
    years1To5ToRenovatePartiallyBuilding: "1-5 anni da ristrutturare parzialmente",
    years1To5HabitableBuilding: "1-5 anni buono/abitabile",
    years1To5ExcellentBuilding: "1-5 anni ottimo",
    years1To5NewConstructionBuilding: "1-5 anni nuovo/in costruzione",
  
    years5To20ToRenovateTotallyBuilding: "5-20 anni da ristrutturare totalmente",
    years5To20ToRenovatePartiallyBuilding: "5-20 anni da ristrutturare parzialmente",
    years5To20HabitableBuilding: "5-20 anni buono/abitabile",
    years5To20ExcellentBuilding: "5-20 anni ottimo",
    years5To20NewConstructionBuilding: "5-20 anni nuovo/in costruzione",
  
    years20To40ToRenovateTotallyBuilding: "20-40 anni da ristrutturare totalmente",
    years20To40ToRenovatePartiallyBuilding: "20-40 anni da ristrutturare parzialmente",
    years20To40HabitableBuilding: "20-40 anni buono/abitabile",
    years20To40ExcellentBuilding: "20-40 anni ottimo",
    years20To40NewConstructionBuilding: "20-40 anni nuovo/in costruzione",
  
    over40YearsToRenovateTotallyBuilding: "Oltre 40 anni da ristrutturare totalmente",
    over40YearsToRenovatePartiallyBuilding: "Oltre 40 anni da ristrutturare parzialmente",
    over40YearsHabitableBuilding: "Oltre 40 anni buono/abitabile",
    over40YearsExcellentBuilding: "Oltre 40 anni ottimo",
    over40YearsNewConstructionBuilding: "Oltre 40 anni nuovo/in costruzione",
  
    // Sections
    floor: "Piano",
    floorWithLift: "Piano con ascensore",
    floorWithoutLift: "Piano senza ascensore",
    ageStateBuilding: "Stato e anni dell'edificio",
    access: "Accesso",
    boxAccessibility:"Manovra accesso box",
    boxLocation:"Ubicazione box",
    boxPossessionType:"Tipo di possesso box",
    coveredParkingAccessibility: "Manovra accesso posto auto coperto",
    coveredParkingLocation:"Ubicazione posto auto coperto",
    coveredParkingPossessionType: "Tipo di possesso posto auto coperto",
    uncoveredParkingAccessibility:"Manovra accesso posto auto scoperto",
    uncoveredParkingLocation:"Ubicazione posto auto scoperto",
    uncoveredParkingPossessionType:"Tipo di possesso posto auto coperto",
    condominialParkingPossessionType:"Tipo di possesso posto auto condominiale",
    mainView:"Tipologia di vista",
    brightness: "Luminosità",
    noisiness: "Rumorosità",
    internalDistribution:"Distribuzione vani interni",
    ceilingHeight:"Altezza soffitti",
    internalState:"Stato conservativo interno",
    finishingQuality:"Grado finiture",
    energeticClass:"classe energetica",
    heater: "Riscaldamento",
    parkingAccessibility:"Difficoltà di parcheggio in zona",
    parkingAccessibility:"Posizione",
    garage: "Autorimessa",
    height: "Altezza",



    //Categories
    "Laboratori": "Laboratories",
    "Magazzini": "Store",
    "Capannoni tipici": "Sheds",
    "Appartamenti": "Apartment",
    "Ville e villini":"Villa",
    "Box e autorimesse":"Box",
    "Posti auto coperti":"CoveredParking",
    "Posti auto scoperti":"UncoveredParking",
    "Capannoni industriali":"IndustrialSheds",
    "Uffici":"Office",
    "Negozi":"Shops",


    //Apartment
    basementMq: "Superficie seminterrato",
    finishingVerandaMq: "Superficie veranda rifinita",
    notFinishingVerandaMq: "Superficie veranda non rifinita",
    mezzanineMq: "Superficie mezzanino",
    canopyMq: "Superficie tettoia",
    balconyMq: "Superficie balcone",
    terraceMq: "Superficie terrazza",
    gardenMq: "Superficie giardino",
    solarPavementMq: "Superficie lastrico solare",
    lodgeMq: "Superficie logge",
    otherUncoveredMq: "Altre superfici scoperte",
    winery: "Cantina",
    garret: "Soffitta",
    box: "Box",
    coveredParkingSpace: "Posto auto coperto",
    uncoveredParkingSpace: "Posto auto scoperto",
    condominialParkingSpace: "Posto auto condominiale",
    withLift: "Con ascensore",
    withoutLift: "Senza ascensore",
    undergroundFloor: "Piano interrato",
    basementFloor: "Piano seminterrato",
    mezzanineFloor: "Piano mezzanino",
    "1Floor": "1° piano",
    "2Floor": "2° piano",
    "3Floor": "3° piano",
    "4Floor": "4° piano",
    "5Floor": "5° piano",
    "6Floor": "6° piano",
    "7Floor": "7° piano",
    "8Floor": "8° piano",
    "9Floor": "9° piano",
    "10Floor": "10° piano",
    "beyond10Floor": "Oltre il 10° piano",
    topFloor: "Ultimo piano",
    penthouse: "Attico",
    boxAccessibility: "Accessibilità box",
    veryDifficultBoxAccessibility: "Accessibilità molto difficile",
    difficultBoxAccessibility: "Accessibilità difficile",
    uncomfortableBoxAccessibility: "Accessibilità scomoda",
    normalBoxAccessibility: "Accessibilità normale",
    comfortableBoxAccessibility: "Accessibilità comoda",
    boxLocation: "Ubicazione",
    inTheSameBoxLocation: "Nello stesso stabile",
    nearBoxLocation: "Vicino",
    farBoxLocation: "Lontano",
    veryfarBoxLocation: "Molto lontano",
    boxPossessionType: "Tipo di possesso",
    propertyBoxPossession: "Proprietà",
    concessionBoxPossession: "Concessione",
    coveredParkingAccessibility: "Accessibilità parcheggio coperto",
    veryDifficultCoveredParkingAccessibility: "Accessibilità molto difficile",
    difficultCoveredParkingAccessibility: "Accessibilità difficile",
    uncomfortableCoveredParkingAccessibility: "Accessibilità scomoda",
    normalCoveredParkingAccessibility: "Accessibilità normale",
    comfortableCoveredParkingAccessibility: "Accessibilità comoda",
    coveredParkingLocation: "Ubicazione",
    inTheSameCoveredParkingLocation: "Nello stesso stabile",
    nearCoveredParkingLocation: "Vicino",
    farCoveredParkingLocation: "Lontano",
    veryfarCoveredParkingLocation: "Molto lontano",
    coveredParkingPossessionType: "Tipo di possesso",
    propertyCoveredParkingPossession: "Proprietà",
    concessionCoveredParkingPossession: "Concessione",
    uncoveredParkingAccessibility: "Accessibilità parcheggio scoperto",
    veryDifficultUncoveredParkingAccessibility: "Accessibilità molto difficile",
    difficultUncoveredParkingAccessibility: "Accessibilità  difficile",
    uncomfortableUncoveredParkingAccessibility: "Accessibilità scomoda",
    comfortableUncoveredParkingAccessibility: "Accessibilità scomoda",
    normalUncoveredParkingAccessibility: "Accessibilità normale",
    inTheSameUncoveredParkingLocation: "Nello stesso stabile",
    nearUncoveredParkingLocation: "Vicino",
    farUncoveredParkingLocation: "Lontano",
    veryfarUncoveredParkingLocation: "Molto lontano",
    uncoveredParkingPossessionType: "Tipo di possesso",
    propertyUncoveredParkingPossession: "Proprietà",
    concessionUncoveredParkingPossession: "Concessione",
    propertyCondominialParkingPossession: "Proprietà",
    concessionCondominialParkingPossession: "Concessione",
    externalPanoramicView: "Esterna panoramica",
    externalView: "esterna",
    mixedView: "mista",
    internalView: "interna",
    fullInternalView: "completamente interna",
    bright: "luminoso",
    veryBright: "molto luminoso",
    mediumBright: "mediamente luminoso",
    lowBright: "poco luminoso",
    normalNoise: "normale",
    veryHighNoise: "molto alta",
    highNoise: "alta",
    lowNoise: "bassa",
    veryLowNoise: "molto bassa",
    veryEfficientDistribution: "distribuzione molto efficiente",
    efficientDistribution: "efficiente",
    lowEfficientDistribution:  "distribuzione poco efficiente",
    notEfficientDistribution: "distribuzione inefficiente",
    mediumCeilingHeight: "nella media di zona",
    inferiorCeilingHeight: "inferiori alla media di zona",
    superiorCeilingHeight: "superiori alla media zona",
    totallyRenovated:  "da ristrutturare totalmente",
    partiallyRenovated: "da ristrutturare parzialmente",
    habitable: "buono/abitabile",
    renovated: "ristrutturato",
    finelyRenovated: "finemente ristrutturato",
    newConstruction: "nuovo/in costruzione",
    standardFinish: "standard",
    inexpensiveFinish: "economiche",
    excellentFinish: "ottime",
    premiumFinish: "di pregio",
    a1: "A1",
    a2: "A2",
    a3: "A3",
    a4: "A4",
    b: "B",
    c: "C",
    d: "D",
    e: "E",
    f: "F",
    g: "G",
    autonomousHeating: "autonomo",
    centralizedHeating: "centralizzato",
    centralizedMeteringHeating: "centralizzato con contabilizzatore",
    absentHeating: "assente",
    easyParkingAccessibility: "più facile della media di zona",
    mediumParkingAccessibility:  "nella media di zona",
    hardParkingAccessibility:  "più difficoltoso della media di zona",
    veryhardParkingAccessibility:  "molto più difficoltoso della media di zona",

    basementDown:"seminterrato in giù",
    groundFloor: "piano terra",
    mezzanineFloorFrom1to3: "piano rialzato (da 1 a 3)",
    floorAboveThe3rd: "piano superiore al 3",
    particularlyFavorableToTrade:  "particolarmente favorevole al commercio",
    favorableToTrade: "favorevole al commercio",
    ordinary: "ordinaria",
    unfavorableToTrade: "poco favorevole al commercio",
    centralPosition: "centrale",
    intermediatePosition: "intermedia",
    secondaryPosition: "secondaria",
    outsideTheGarage: "fuori dall'autorimessa",
    insideGarage: "dentro l'autorimessa",
    averagHeight:"nella media",
    avarageAbove: "sopra la media (>3mt)"


  };
  

export default translations