import React from "react";
import logo from "../../assets/images/logo-red.svg";
import "../../assets/css/Register.css";
import Footer from "../Footer";

import { useNavigate } from "react-router-dom";

import ButtonRm from "../ButtonRm";

//to do: fix card in row desktop, e on click go to registration 

export default function Role (){
    const navigate = useNavigate();

    const roles = [
        {

            img:"https://cdn1.iconfinder.com/data/icons/user-pictures/100/male3-512.png",
            name: "Gestore",
            description: "Desideri gestire le tue proprietà o proprietà altrui?"
        },
        {
            img:"https://cdn1.iconfinder.com/data/icons/user-pictures/100/female1-512.png",
            name: "Collaboratore",
            description: "Scegli questo ruolo se sei stato invitato a collaborare con un gestore"
        },
        {
            img:"https://cdn1.iconfinder.com/data/icons/user-pictures/100/boy-512.png",
            name: "Inquilino",
            description: "Connettiti con il tuo gestore, proprietario e/o fornitori con pochi click"
        },
        {
            img:"https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-1024.png",
            name: "Fornitore",
            description: "Sei un fornitore del mondo casa? Trova nuove opportunità"
        },
        {
            img:"https://cdn0.iconfinder.com/data/icons/user-pictures/100/maturewoman-3-512.png",
            name: "Proprietario",
            description: "Hai affidato le tue proprietà a un gestore? Connettiti"
        }
    ]

    function goToRegistration(name){
        if(name == 'Gestore'){
            navigate("/reducedRegister?role=MASTER")
        } else if (name == 'Collaboratore') {
            navigate("/reducedRegister?role=COLLABORATOR")
        } else if (name == 'Inquilino') {
            navigate("/reducedRegister?role=CONDUCTOR")
        } else if (name == 'Proprietario') {
            navigate("/reducedRegister?role=OWNER")
        } else if (name == 'Fornitore') {
            navigate("/reducedRegister?role=SUPPLIER")
        }
    }

    return (
        <>
			<div className="register-div">
				<img
					src={logo}
					alt="logo"
					className="register-logo"
					onClick={() => navigate("/")}
					style={{ cursor: "pointer" }}></img>
				<div className="tabs-content-div text-center mt-md-5">
					<div className="register-title fs-32">Scegli il tuo ruolo</div>
                    <div className="mx-5">
            <div className="row gy-3 mb-4 justify-content-center"> {/* gy-3 aggiunge un margine verticale tra le card quando si impilano */}
                {
                    roles.map(role => {
                        return (
                            <div className="col-12 col-md-4" style={{cursor: 'pointer'}} onClick={() => goToRegistration(role.name)}> {/* su schermi piccoli, 100% di larghezza; su schermi md e più grandi, 1/3 di larghezza */}
                                <div className="border rounded text-center p-4" style={{ minHeight:"200px", margin: '0 auto'}}> {/* larghezza fissa di 300px e centrata */}
                                    <div className="rounded-circle h-55 w-55 mx-auto mb-2">
                                        <img loading="lazy" className="w-100" src={role.img} alt={role.name}/> {/* Aggiunto alt per accessibilità */}
                                    </div>
                                    <p className="fw-bold fs-18 my-2">{role.name}</p>
                                    <p className="fs-14">{role.description}</p>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>

                    <ButtonRm variant="tertiary" className="mx-auto" onClick={() => navigate(-1)}>Indietro</ButtonRm>

				</div>

			</div>
			<Footer />
		</>
    )
}