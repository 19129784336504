import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getProvince, getMunicipality, getZone } from "../../../actions/addressAction";
import { type } from "../constant";


import Form from "react-bootstrap/Form";
import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';

import Card from 'react-bootstrap/Card';
import CustomToggle from "../../CustomToggle";

import CustomBadge from "../../CustomBadge";


const Typology = (props) => {
	useEffect(() => {
		props.getProvince();
	}, []);

useEffect(() => {
	props.getMunicipality(props.province[0]);
}, [props.province]);

	useEffect(() => {
 		props.getZone(props.municipality[0]);
 	}, [props.municipality]);

	const handleProvince = (e) => {
		props.onChange(e);
		props.getMunicipality(e.target.value);
	};

	const handleMunicipality = (e) => {
		props.onChange(e);
		props.getZone(e.target.value);
	};


	const handleUnit = (e) => {
		props.onChange(e);
		props.setUnitsIndex(e.target.selectedIndex - 1);
	};

	//HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

	console.log("props.formData.municipality",props.formData.municipality, props.zone)

	return (
		<div className="form-item-align-box d-block">

		<Form noValidate>
			<div className="form-item-align-box d-block">
				<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
					<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="0" activeKey={activeKey}>Identificativo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
							<Row>
								<Col md={12} className="mb-3">
								<label className="f-label fs-14 mb-2">
								Se hai già inserito nel gestionale l’immobile di cui vuoi fare la
								valutazione, selezionalo nel campo sottostante e/o inserisci un
								identificativo per questa valutazione
								</label>
								</Col>							
							</Row>
							<div className="row">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Unità immobiliare
							</label>
							<select
								name="unitName"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
								value={props.formData.unitName}
								onChange={handleUnit}>
								<option key={-1} value="">
									Seleziona
								</option>
								{props.units?.map((item, key) => {
									return (
										<option key={key} value={item.name}>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Identificativo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

							</label>
							<input
								type="text"
								name="identifier"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
								onChange={(e) => {
									props.onChange(e);
								}}
								value={props.formData.identifier}
							/>
						</div>
					</div>
				</div>					
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="1" activeKey={activeKey}>Tipologia<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row ">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Tipologia<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</label>
						<select
							name="typology"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
							value={props.formData.typology}
							onChange={(e) => {
								props.onChange(e);
							}}>
																	<option></option>

							{type.map((menu, index) => {
								return (
									<option key={index} value={menu}>
										{menu}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Provincia<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</label>
						<select
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
							name="province"
							value={props.formData.province}
							onChange={handleProvince}>
							{props.province.map((item, key) => {
								return (
									<option key={key} value={item}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Comune<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</label>
						<select
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
							name="municipality"
							value={props.formData.municipality}
							onChange={handleMunicipality}>
							{props.municipality?.map((item, key) => {
								return (
									<option key={key} value={item}>
										{item}
									</option>
								);
							})}
						</select>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Quartiere 
						</label>
						<select
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
							name="omiZone"
							value={props.formData.omiZone}
							onChange={props.onChange}
							>
							  {props.formData.municipality && props.zone?.length > 0 &&  props.zone.map((item, key) => {
								return (
									<option key={item.zone_code} value={item.zone_code}>
										{item.zone_description}
									</option>
								);
							})}  
						</select>
					</div>
				</div>
			</div>	
			<div className="row mt-3">

				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Indirizzo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</label>
						<input
							type="text"
							name="address"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							onChange={(e) => {
								props.onChange(e);
							}}
							value={props.formData.address}
						/>
					</div>
				</div>
			</div>				
					</Accordion.Body>
					</Accordion.Item>
				{/* 	<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="3" activeKey={activeKey}>OMI<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<div className="row">
             
							<div className="col-md-6">
								<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo di vendita minimo OMI
								</label>
								<input
									name="minSale"
									value={props.formData.minSale}
									onChange={(e) => {
									props.onChange(e);
									}} 
									type="number"
								/>
								</div>
							</div>

							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Prezzo di vendita massimo OMI
									</label>
								<input
									name="maxSale"
									value={props.formData.maxSale}
									onChange={(e) => {
									props.onChange(e);
									}} 
									type="number"
								/>
								</div>
							</div>
            			</div>
            <div className="row mt-3">
				<div className="col-md-6">
                <div className="d-flex flex-column">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  	 	Prezzo di affitto minimo OMI
                  	</label>
                  <input
                    name="minRent"
                    value={props.formData.minRent}
                    onChange={(e) => {
                      props.onChange(e);
                    }} 
                    type="number"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
				  Prezzo di affitto massimo OMI
                  </label>
                  <input
                     name="maxRent"
					 value={props.formData.maxRent}
                    onChange={(e) => {
                      props.onChange(e);
                    }} 
                    type="number"
                  />
                </div>
              </div>
            </div>
						</Accordion.Body>
					</Accordion.Item> */}
					<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
						<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey}>Coefficienti di merito<span className="mandatory-field"></span></CustomToggle>
						</Card.Header>
						<Accordion.Body>
						<Row>
								<Col md={12} className="mb-3">
								<label className="f-label fs-14 mb-2">
								Seleziona i coefficienti di merito da utilizzare per questa valutazione. Puoi creare dei coeffficienti personalizzati nella sezione "Impostazioni"
								</label>
								</Col>							
							</Row>
			<div className="row">
				<div className="col-md-6">
					<div className="d-flex flex-column">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Coefficienti di merito
						</label>
						<select
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white w-100 flex-auto"
							name="coefficients"
							value={props.formData.coefficients}
							onChange={props.onChange}
							>
							
						</select>
					</div>
				</div>
			</div>				
					</Accordion.Body>
					</Accordion.Item>

					<Accordion.Item eventKey="6" className="mb-2 accordionHeader">
				<Card.Header>
				<CustomToggle eventKey="6" activeKey={activeKey}>Valori omi manuali</CustomToggle>
				{/*  */}
				</Card.Header>
					<Accordion.Body>
						
					<Row>
								<Col md={12} className="mb-3">
								<label className="f-label fs-14 mb-2">
								Se desideri utilizzare i tuoi valori personalizzati per i prezzi al mq di locazione e vendita, compila i campi sottostanti. In caso contrario, verranno utilizzati i valori OMI di riferimento più recenti.

</label>
								</Col>							
							</Row>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo minimo affitto (al mq)
								</label>
								<input
									 name="minRent"
									value={props.formData.minRent}
									onChange={(e) => {
										props.onChange(e);
									}} 
									type="number"
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo massimo affitto (al mq)
								</label>
								<input
								 name="maxRent"
									value={props.formData.maxRent}
									onChange={(e) => {
										props.onChange(e);
									}} 
									type="number"
								/>
							</div>
						</div>
						
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo minimo vendita (al mq)
								</label>
								<input
									 name="minSell"
									value={props.formData.minSell}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="number"
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Prezzo massimo vendita (al mq)
								</label>
								<input
									name="maxSell"
									value={props.formData.maxSell}
									onChange={(e) => {
										props.onChange(e);
									}} 
									type="number"
								/>
							</div>
						</div>
						
					</div>
					</Accordion.Body>
				</Accordion.Item>
					
				</Accordion>
			</div>
		</Form>
		</div>
	);
};


//PROVINCE, COMUNI, ZONE LE PRENDIAMO DA QUI TRAMITE PROPS
//LOGICA GESTITA  IN ADRESS REDUCER- ADRESS ACTION -TYPES
const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
	zone: state.addressReducer.zone
});
export default connect(mapStateToProps, { getProvince, getMunicipality, getZone })(
	Typology
);
