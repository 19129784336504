import React, { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import PhoneInput from "react-phone-number-input/input";
import "react-toastify/dist/ReactToastify.css";
import "../../../../assets/css/Tabs.css";
import { isValidEmail, isValidPassword } from "../../../../utility";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Step1 = (props) => {
  const [error, setError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);

  const onChange = (e) => {
    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.value,
    });
  };

  const phoneChange = (value) => {
    props.setFormData({
      ...props.formData,
      phone: value,
    });
  };

  const emailChange = (e) => {
    onChange(e);

    if (!isValidEmail(e.target.value)) {
      setError(true);
      return;
    }

    setError(false);
    if (props.formData.emailConfirmation === e.target.value) {
      props.setEmailConfError(false);
    } else {
      props.setEmailConfError(true);
    }
  };

  const confirmEmailChange = (e) => {
    onChange(e);

    if (!isValidEmail(e.target.value)) {
      setError(true);
      props.setEmailConfError(true);
      return;
    }

    if (props.formData.email !== e.target.value) {
      props.setEmailConfError(true);
    } else {
      props.setEmailConfError(false);
      setError(false);
    }
  };

  const passwordChange = (e) => {
    props.setFormData({
      ...props.formData,
      password: e.target.value,
    });

    if (!isValidPassword(e.target.value)) {
      setPassError(true);
      return;
    }
    setPassError(false);
    if (props.formData.passwordConfirmation === e.target.value) {
      props.setPassConfError(false);
      setPassError(false);
    } else {
      props.setPassConfError(true);
    }
  };

  const confirmPasswordChange = (e) => {
    onChange(e);

    if (!isValidPassword(e.target.value)) {
      setPassError(true);
      props.setPassConfError(true);
      return;
    }

    if (props.formData.password === e.target.value) {
      setPassError(false);
      props.setPassConfError(false);
    } else {
      props.setPassConfError(true);
    }
  };

  const togglePassword = () => {
    setPasswordHidden(!passwordHidden);
  };

  const toggleconfirmPassword = () => {
    setConfirmPasswordHidden(!confirmPasswordHidden);
  };

  const validatePassword = (pwd) => {
    // Regole per la validazione della password
    const minLength = pwd.length >= 8;
    const hasLowercase = /[a-z]/.test(pwd);
    const hasUppercase = /[A-Z]/.test(pwd);
    const hasNumber = /\d/.test(pwd);
    const hasSymbol = /[^A-Za-z0-9]/.test(pwd);

    return minLength && hasLowercase && hasUppercase && hasNumber && hasSymbol;
  };

  const passwordsMatch = () => {
    return props.formData.password === props.formData.passwordConfirmation
  }

  const getInputBorderStyle = (inputField) => {
    if (inputField === 'password' && props.formData.password >  0) {
      return validatePassword(props.formData.password) ? '1px solid green' : '1px solid red';
    } else if ((inputField === 'passwordConfirmation' && props.formData.passwordConfirmation >  0)) {
      return passwordsMatch() && validatePassword(props.formData.passwordConfirmation) ? '1px solid green' : '1px solid red';
    }
  };

  return (
    <div
      className="container"
      style={{ display: props.isActive === true ? "" : "none" }}
    >
      <div className="tab1ContentTitle justify-content-center">
        Dati di registrazione
      </div>
      <div className="col-md-12 col-sm-12 mt-2">
        <div className="row mt-2">
          <div className="col-md-6 col-sm-6 tabContent-input1 padding-right">
            <div className="mt-2">
              <div className="tabContentSubtitle">Username</div>
              <input
                name="username"
                type="text"
                placeholder="Inserisci il tuo username"
                className="tabInputField form-control w-100"
                onChange={(e) => onChange(e)}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle">Email</div>
              <input
                name="email"
                type="email"
                placeholder="Inserisci la tua email"
                id={error ? "input_error" : ""}
                className="tabInputField form-control w-100"
                onChange={(e) => emailChange(e)}
              ></input>
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle">Conferma email</div>
              <input
                name="emailConfirmation"
                type="text"
                placeholder="Conferma la tua email"
                id={props.emailConfError ? "input_error" : ""}
                className="tabInputField form-control w-100"
                onChange={(e) => confirmEmailChange(e)}
              ></input>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 tabContent-input2 padding-left">
            <div className="mt-2">
              <div className="tabContentSubtitle ">Telefono  <OverlayTrigger
                            placement="top"
                            delay={{ show: 600, hide: 300 }}
                            overlay={
                              <Tooltip className='tooltipContabilita'><p>
                              Inserisci il prefisso prima del numero: <br/>
                              39
                              </p>
                              </Tooltip>
                            }
                            >
                            <i class="bi bi-info-circle ms-2"></i>
                            </OverlayTrigger></div>
              <PhoneInput
                id="inputbox"
                name="phone"
                placeholder="Inserisci il tuo numero di telefono"
                maxLength="16"
                onChange={(value) => {
                  phoneChange(value);
                }}
                className="tabInputField form-control w-100"
              />
            </div>
            <div className="mt-2">
              <div className="tabContentSubtitle ">Password 
                  <OverlayTrigger
                            placement="top"
                            delay={{ show: 600, hide: 300 }}
                            overlay={
                              <Tooltip className='tooltipContabilita'><p>
                              La password deve contenere: <br/>
                              -minimo 8 caratteri <br/>
                              -almeno una lettera minuscola <br/>
                              -almeno una lettera maiuscola <br/>
                              -almeno un simbolo <br/>
                              -almeno un numero <br/>
                              </p>
                              </Tooltip>
                            }
                            >
                            <i class="bi bi-info-circle ms-2"></i>
                            </OverlayTrigger>
                            </div>
              <div style={{ position: "relative" }}>
                <input
                  name="password"
                  type={passwordHidden ? "password" : "text"}
                  placeholder="Inserisci la tua password"
                  className="tabInputField form-control w-100"
                  id={passError ? "input_error" : ""}
                  onChange={(e) => passwordChange(e)}
                  style={{ border: getInputBorderStyle('password') }}
                ></input>
                <div onClick={togglePassword} className="passwordHideToggle1">
                  {passwordHidden ? <BsEye /> : <BsEyeSlash />}
                </div>
              </div>
              <div className="mt-2">
                <div className="tabContentSubtitle ">Conferma Password</div>
                <div style={{ position: "relative" }}>
                  <input
                    name="passwordConfirmation"
                    type={confirmPasswordHidden ? "password" : "text"}
                    placeholder="Conferma la tua password"
                    className="tabInputField form-control w-100"
                    id={props.passConfError ? "input_error" : ""}
                    onChange={(e) => confirmPasswordChange(e)}
                    style={{ border: getInputBorderStyle('passwordConfirmation') }}

                  ></input>
                  <div
                    onClick={toggleconfirmPassword}
                    className="passwordHideToggle1"
                  >
                    {confirmPasswordHidden ? <BsEye /> : <BsEyeSlash />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
