import React, { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../../../assets/css/Tabs.css";
import ClickCamera from "../../../../assets/images/camera-circle.png";
import FaceId from "../../../../assets/images/face id-cropped.svg";
import ButtonRm from "../../../ButtonRm";

const TabContent5 = ({ isActive, setFormData, formData }) => {
   const webcamRef = React.useRef(null);
  const [camera, setCamera] = useState(false);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

/*   const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    let link_img = document.createElement("a");
    link_img.style.display = "none";
    document.body.appendChild(link_img);
    link_img.href = imageSrc;
    link_img.download = "face_id.png";
    link_img.click();
  }, [webcamRef]); 
 */
    //HANDLE FACE ID
    const [faceIdPhoto, setFaceIdPhoto] = useState("")
/*     const [blobFile, setBlob] = useState(null)

    useEffect(() => {
      if(faceIdPhoto){
        fetch(faceIdPhoto)
        .then(res => { 
          let blob = res.blob()
          setBlob(blob)}
          )
      }
    }, [faceIdPhoto])

    console.log(faceIdPhoto, blobFile) */



 /*    useEffect(() => {
        setData(prev => {
            return { 
                ...prev,
                faceFile: faceIdPhoto
            }
        })
    }, [faceIdPhoto])
 */

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        //let link_img = document.createElement("a");
        //link_img.style.display = "none";
        //document.body.appendChild(link_img);
        //link_img.href = imageSrc;
        //link_img.download = "face_id.png";
        //link_img.click();
        setFormData(prevState => ({
          ...prevState,
          faceFile: imageSrc
        }));
        //setFaceIdPhoto(imageSrc)
        //closeFaceId()
        setCamera(false)
    }, [webcamRef]);

  return (
    <div
      className="container"
      style={{ display: isActive === true ? "" : "none" }}
    >
    {/*    
      {/* <div className="tab1ContentTitle justify-content-center">Face ID</div> */}
      <div
        className="mt-4"
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {camera ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            style={{ width: "100%" }}
            className="tab5Content position-relative"
          />
        
        ) : (
          <img
            src={formData.faceFile ? formData.faceFile: FaceId}
            className="tab5Content"
            style={{ maxWidth: "60%" }}
          ></img>
        )}
        {camera ? 
        (<ButtonRm variant="secondary"  style={{position: "absolute", bottom: 10}} onClick={capture}>Scatta foto</ButtonRm>
        ) : ( !formData.faceFile ?
          <div onClick={() => setCamera(true)} className="capture-btn">
            Clicca qui
          </div> :
          <ButtonRm variant="secondary"  style={{position: "absolute", bottom: 10}} onClick={() => setCamera(true)}>Scatta di nuovo</ButtonRm>
        )}
      </div>
    </div>
  );
};

export default TabContent5;
