import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoRate () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Rate"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
            <TreeItem nodeId="1" label="Effettuare l'accesso" />
            <TreeItem nodeId="2" label="Codice OTP" />

            




        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
      <h1>Rate</h1>
      <h2 style={{ color:'transparent', height: 0}}>Rate</h2>

      <p className="mt-3">La sezione rate serve a definire e
      gestire i pagamenti che devi ricevere da terzi, siano essi
      affitti da riscuotere o altro.</p>
      

<p >La piattaforma da inoltre la
possibilità di inviare richieste di pagamento ai soggetti coinvolti,
e ricevere i pagamenti direttamente dentro Real Meta.</p>

<img src="panoramica" loading="lazy"/>


<h2>STRUTTURA DELLA PAGINA</h2>

<p >In alto a destra trovi il pulsante
rosso “+”. Cliccando su questo pulsante aprirai il modulo di
creazione di un rata.</p>
<img src="top" loading="lazy"/>


<p >Poco sotto, In alto a sinistra troverai
la barra di ricerca, per semplificare la ricerca di una rata tramite
identificativo.</p>

<img src="filtri" loading="lazy"/>


<h2>CREAZIONE DI UN RATA</h2>
<p >Per creare una rata clicca il pulsante rosso +
in alto a destra.</p>

<ul>
	<li><p >Definisci <strong>DA CHI</strong> devi ricevere il
	pagamento: proprietario, inquilino, fornitore o altro</p></li>
	<li><p >Seleziona il soggetto di
	riferimento (Che puoi inserire dalle sezione UTENTI - link alla
	guida)</p></li>
	<li><p >Data di inizio competenza - data
	di inizio  in cui l’utente può effettuare il pagamento</p></li>
	<li><p >Data di fine competenza - data
	entro cui l’utente deve effettuare il pagamento</p></li>
	<li><p >Scadenza - data che ci aiuta a
	determinare quando un pagamento è in ritardo</p></li>
	<li><p >Imponibile</p></li>

	<li><p >IVA</p></li>
	<li><p >Totale versato - ovvero se hai già
	ricevuto un acconto (facoltativo)</p></li>
	<li><p >Identificativo - la denominazione
	è personale e ti servirà per riconoscere più facilmente una rata
	che hai creato</p></li>
</ul>

<h3>Ricorrenza</h3>
<p >Se
il pagamento che stai impostando è un pagamento ricorrente, come ad
esempio un affitto, puoi impostare le specifiche per automatizzare la
creazione di più un rate in una sola volta.</p>
<p >Questo
creerà in automatico tutte le rate necessaria durante un arco di
tempo che sarà determinato da:</p>
<ul>
  <li>Data
di inizio competenza</li>
  <li>Data
di fine ricorrenza</li>
  <li>Frequenza della ricorrenza (esempio ogni 2 settimane, o ogni
mese…) </li>
</ul>


<img src="ricorrenza" loading="lazy"/>


<h2>LISTA
RATE</h2>
<p>Una volta create le rate potrai visualizzare la tabella di riepilogo con tutte le rate create, contenente:</p>
<ul>
	<li><p >Nominativo</p></li>
	<li><p >Tipo</p></li>
	<li><p >Totale
	versato</p></li>
	<li><p >Totale
	dovuto</p></li>
	<li><p >Saldo</p></li>
	<li><p >Saldo
	scaduto</p></li>
</ul>

<p >La
tabella varia a seconda della visualizzazione selezionata:</p>

<ul>
	<li><p>Membri</p></li>
	<li><p>Unità</p></li>
</ul>

<p >Se
hai selezionato MEMBRI, ogni riga della tabella rappresenta un
soggetto per il quale hai inserito una rata.</p>

<p >Per
vedere tutte le rate relative a un soggetto clicca sulla riga
relativa.</p>

<img src="tabella gif" loading="lazy"/>


<h2>RATE
DI UN SINGOLO MEMBRO</h2>
<p >Cliccando
su una riga della tabella potrai visualizzare tutte le rate collegate
ad un soggetto.</p>

<p >In
alto a destra avrai visione del SALDO del soggetto.</p>
<p >Se
il bottone del saldo è di color verde, ciò indica che il soggetto è
in regola con i pagamenti.</p>
<p >Se
il bottone del saldo è di colore rosso, ciò indica che ci sono dei
pagamenti che questo soggetto ti deve in ritardo, e indicheremo la
cifra dovuta.</p>
<img src="saldo" loading="lazy"/>

<p >Potrai
cercare un rata specifica, utilizzando la barra di ricerca in alto a
sinistra.</p>
<p >Cliccando
sul bottone con l’icona dell’imbuto potrai filtrare per un
periodo di tempo specifico.
</p>
<p >Cliccando
sul bottone dell’icona con 3 righe,  potrai ordinare i pagamenti
dai più recenti ai meno recenti o viceversa.</p>

<p >Usando
il filtro in alto, sotto la barra di ricerca, potrai filtrare per
immobile, qualora l’utente abbia sia associato a più di un
immobile.</p>

<img src="filtri figma" loading="lazy"/>

<h3 >CARD
RATA</h3>
<p >Ogni
card di una rata contiene</p>
<p >Identificativo</p>
<ul>
  <li><p >Data di inizio competenza</p></li>
  <li><p >Scadenza</p></li>
  <li><p >Imponibile</p></li>
  <li><p >Iva</p></li>
  <li><p >Già versato</p></li>
  <li><p >Totale dovuto</p></li>
  <li><p >Saldo scaduto</p></li>
</ul>

<p >Cliccando
su un elemento rata (card) potrai avere visione dei dettagli della
rata.</p>

<img src="rata" loading="lazy"/>


<h2>DETTAGLIO RATA</h2>
<p>Aprendo la pagina di dettaglio Rata, potrei visualizzare le informazioni relative alla rata selezionata, che hai definito in fase di creazione</p>
<img src="gif apertura" loading="lazy"/>

<h3>Modifica rata</h3>
<p>Cliccando sull'icona della matita, i campi diventeranno editabili e 
potrai apportare modifiche all'elemento selezionato.</p>
<h3>Condividi rata</h3>
<p>Ti permette di condividere la rata tramite la chat, in una nuova conversazione o in una esistente.</p>
<p>Verrà creato un messaggio di pagamento, e potrai riscuotere direttamente il pagamento in chat.</p>
<h3>Esporta e stampa</h3>
<p>Puoi esportare in pdf o csv, o stampare i dati relativi alla rata selezionata</p>

<img src="recap filtri figma" loading="lazy"/>

<h3>Segna come pagato</h3>
<p>Cliccando questo bottone, puoi manualmente designare una rata come pagata</p>
<p>Subito dopo, aver segnato un pagamento come "Pagato" ti inviteremo a creare la prima nota relativa</p>
<p>Potrai farlo anche in uns econdo momento, cliccando sukl bottone "Crea prima nota"</p>
<Tips variant="suggestion" content="Registrare ogni pagamento ricevuto/inviato è importante ai fini della rendicontazione. Potrai comunque farlo in un secondo momento cliccando sul pulsante 'Crea prima nota'." />

<img src="segna come pagato gif" loading="lazy"/>



</div>
  );
}

