import { useState, useEffect } from "react";
import EmptyImg from "../../../assets/images/condomino-ph.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsCheckCircleFill, BsXCircleFill, BsPrinter } from "react-icons/bs";
import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../UnitàImmobiliari/mapping";

import CustomBadge from "../../CustomBadge";


const CondominiumE = ({ units }) => {
	const [totalAmmount, setTotalAmmount] = useState(0);
	useEffect(() => {
		if (units?.length > 0) {
			var temp = 0;
			for (var i = 0; i < units?.length; i++) {
				temp +=
					units[i].ofProperty +
					units[i].scaleMillesimi +
					units[i].lift +
					units[i].reception +
					units[i].heating +
					units[i].district +
					units[i].water;
			}
		}
		setTotalAmmount(temp);
	}, []);
	return (
		<div>
			<div className="edit-content-container-white mt-3 py-5">
			<div className="user-alert green my-3 mb-5">
					<label>
						<i className="fal fa-exclamation-circle me-2"></i> La lista dei condomini e la ripartizione in millesimi viene creata automaticamente quando associ una o più unità allo stabile di riferimento
 (sezione unità - step condominio)
					</label>
				</div>
				{units?.length > 0 ? (
					<>
						<div className="d-flex align-items-center">
							<div htmlFor="" className="fs-16 me-2">
								CONDOMINI
							</div>
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 50 }}
								overlay={
									<Tooltip id="tooltip" className="exclamation-tooltip">
										Puoi aggiungere le unità di questo stabile e relativi
										millesimi nella sezione unità, ricordati di selezionare
										questo stabile nello step "CONDOMINIO" per effettuare
										l'associazione
									</Tooltip>
								}>
								<i
									className="fa fa-exclamation-circle"
									style={{ color: "#606F88" }}></i>
							</OverlayTrigger>
						</div>
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

				
					<ExportButton data={units} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
						<div className="mt-3">
							<div
								className="w-100 d-flex justify-content-between"
								style={{ padding: "0px 10px" }}>
								<div className="f-label fs-16 d-flex align-items-center
w-20p">Nome</div>
								<div className="f-label fs-16 d-flex align-items-center
w-20p">UNITA’</div>
								<div className="f-label fs-16 d-flex align-items-center
w-20p">SUPERFICIE</div>
								<div className="f-label fs-16 d-flex align-items-center
w-20p">TIPOLOGIA</div>
								<div className="f-label fs-16 d-flex align-items-center
w-20p">MILLESIMI</div>
							</div>
							{units.map((item, index) => {
								return (
									<div key={index} className="section-card mt-2">
										<div className="w-20p">
											{item.owners?.length > 0 
												? item.owners?.[0]?.name + " " + item.owners?.[0]?.surname
												: "nessun proprietario"}
										</div>
										<div className="w-20p">{item.name}</div>
										<div className="w-20p">
											{item.coveredMq + item.notCoveredMq}mq
										</div>
										<div className="w-20p">Proprietario</div>
										<div className="w-20p">
											{item.ofProperty +
												item.scaleMillesimi +
												item.lift +
												item.reception +
												item.heating +
												item.district +
												item.water}
										</div>
									</div>
								);
							})}
							<div className="mt-4 w-100 d-flex justify-content-end">
								{totalAmmount == 1000 ? (
									<div className="fw-bold green-color d-flex align-items-center">
										<BsCheckCircleFill className="me-2" />
										TOTALE MILLESIMI 1000/1000
									</div>
								) : (
									<div className="fw-bold red-color d-flex align-items-center">
										<BsXCircleFill className="me-2" />
										TOTALE MILLESIMI {totalAmmount}/1000
									</div>
								)}
							</div>
						</div>
					</>
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={EmptyImg} alt="" />
						<span className="img-comment">Nessun Condomino</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default CondominiumE;
