import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoDashboard () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Dashboard"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
                <TreeItem nodeId="1" label="Azioni Rapide" />
                <TreeItem nodeId="2" label="Riepilogo Unità" />
                <TreeItem nodeId="3" label="Stato Unità" />
                <TreeItem nodeId="4" label="Attività" />
                <TreeItem nodeId="5" label="Scadenze" />

        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
        <h1>Dashboard</h1>
		<h2 style={{ color:'transparent', height: 0}}>Dashboard</h2>


<p className="mt-3" >La prima pagina che vedrai appena
entrato su Real Meta è la Dashboard (una sorta di bacheca iniziale).</p>

<img loading="lazy" src="https://i.postimg.cc/ZqpWSGq2/dash.png" />


<p >Procedendo dall’alto verso il basso
troverai:</p>
<ul>
	<li><p >Azioni rapide</p></li>
	<li><p >Riepilogo delle tue unità</p></li>
	<li><p >Panoramica stato unità</p></li>
	<li><p >Lista delle tue attività recenti</p></li>
	<li><p >Scadenze</p></li>
</ul>

<h2 id="1">AZIONI RAPIDE</h2>
<p >Sono pulsanti che ti consentono di
compiere le azioni più importanti con un solo click, senza dover
entrare nella sezione relativa.</p>
<img loading="lazy" src="https://i.postimg.cc/765QhnpF/azioni.png" />


<p >Per visualizzare tutti i pulsanti
clicca sulle freccette a destra e sinistra della sezione, o effettua
uno scroll orizzontale col dito o con il mouse.</p>
<ul>
	<li><p> <strong>Nuova unità:</strong> ti rimanda al modulo
	per la creazione di una nuova unità</p></li>
	<li><p ><strong>Nuovo affitto:</strong> ti rimanda al
	modulo per la creazione di un nuovo affitto</p></li>
	<li><p ><strong>Nuova Rata: </strong>
	ti rimanda al modulo per la creazione di una nuova rata</p></li>
	<li><p ><strong>Nuova Prima Nota:</strong>
	ti rimanda al modulo per la creazione di una prima nota</p></li>
	<li><p ><strong>Nuovo Proprietario:</strong> ti rimanda al
	modulo per la creazione di un nuovo proprietario</p></li>
	<li><p ><strong>Nuovo Conduttore:</strong> ti rimanda al
	modulo per la creazione di un nuovo conduttore (o inquilino)</p></li>
	<li><p ><strong>Nuovo Fornitore:</strong> ti rimanda al
	modulo per la creazione di un nuovo fornitore 
	</p></li>
	<li><p ><strong>Nuova Scadenza:</strong> ti rimanda al
	modulo per la creazione di una scadenza o evento</p></li>
</ul>

<img loading="lazy" src="https://i.postimg.cc/CMW9Z9Mm/azioni-btn.png" />



<h2 id="2">RIEPILOGO DELLE TUE UNITA’</h2>

<p >Qui troverai un riepilogo delle unità
che hai inserito all’interno della piattaforma.</p>
<p >Nel dettaglio potrai visualizzare:</p>
<ul>
	<li><p >Identificativo dell’immobile</p></li>
	<li><p >Indirizzo dell’immobile</p></li>
	<li><p >Valutazione immobiliare</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/KzCpMgbR/riepilogo.png" />

<p >Per visualizzare tutti gli elementi
clicca sulle freccette a destra e sinistra della sezione, o effettua
uno scroll orizzontale col dito o con il mouse.</p>
<img loading="lazy" src="https://i.postimg.cc/HLZhThP0/riepilogo-btn.png" />



<h3>AZIONI 
</h3>
<p >Avvicinando il mouse all’elemento
(sopra l’immagine) potrai visualizzare il valore immobiliare
dell’unità</p>

<p >Cliccando su un elemento si aprirà il report della valutazione dettagliato</p>
<img loading="lazy" src="https://i.postimg.cc/rsWnCFyg/valutazione.gif" />


<p >In alto a sinistra troverai la barra di
ricerca delle tue unità, per semplificare la ricerca di un immobile
specifico tramite identificativo.</p>

<p >In alternativa trovi a sinistra il
bottone filtra (icona dell’imbuto) che ti permette di filtrare le
unità per i seguenti criteri</p>
<ul>
	<li><p >periodo di creazione</p></li>
	<li><p >categoria, se ad esempio puoi
	filtrare tutti gli immobili che appartengono alla categoria “negozi”</p></li>
	<li><p >proprietari, se ad esempio vuoi
	visualizzare tutti gli immobili che appartengono a uno specifico
	proprietario</p></li>
	<li><p >gestione, puoi filtrare tutte le
	unità che appartengono a una specifica gestione 
	</p></li>
</ul>

<p >A fianco al pulsante filtra, trovi il
pulsante ordina (icona con tre righe orizzontali) che ti permette di</p>
<ul>
	<li><p >Ordinare gli elementi in modo
	alfabetico dalla A alla Z o viceversa</p></li>
	<li><p >Ordinare gli elementi mettendo
	prima quelli creati più di recente o in alternativa mettendo per
	primi quelli creati meno recentemente</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/YSqPKH8q/filtri.png" />


<Tips variant="info" content="Questa sezione sarà visibile dopo
la creazione di almeno un'unità" />


<h2 id="3">PANORAMICA STATO UNITA’</h2>
<p >Questo grafico rappresenta un riepilogo
dello stato delle tue unità:</p>
<ul>
	<li><p >Occupate</p></li>
	<li><p >Vuote</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/Gmjf52F5/status.png" />

<p >Puoi definire questa informazione nella
sezione UNITA’ IMMOBILIARI.</p>
<p >Clicca sull’unità di riferimento &gt;
clicca sul pulsante FACOLTATIVI &gt; dal sottomenù che appare
seleziona CARATTERISTICHE &gt; nel primo riquadro della pagina
troverai il campo “stato locativo” e potrai selezionare l’opzione
corretta.</p>
<p ><br/>

</p>
<p >Infine potrai vedere se alcuni di
questi immobili sono in manutenzione</p>

<Tips variant="info" content="Affinché un
	immobile risulti in manutenzione ci deve essere almeno un’attività
	in corso nella sezione SAL (Stato Avanzamento Lavori) associato
	all’immobile in questione." />

<h2 id="4">ATTIVITA’ RECENTI</h2>
<p >In questo riquadro saranno visibili le
ultime attività create nella sezione SAL - Stato Avanzamento Lavori.</p>
<p >Cliccando su un elemento, potrai vedere
i dettagli relativi alle attività.</p>
<p >Cliccando sul pulsante “VEDI TUTTO”
in alto a destra verrai rimandato alla lista completa delle attività
create nella sezione SAL. 
</p>
<img loading="lazy" src="https://i.postimg.cc/Sx45d8M9/task.png" />




<h2 id="5">SCADENZE</h2>
<p >In questo riquadro saranno visibili le
tue scadenza create nella sezione Alert.</p>

<p >Cliccando su un elemento, potrai vedere
i dettagli relativi all’evento.</p>
<p >Cliccando sul pulsante “VEDI TUTTO”
in alto a destra verrai rimandato alla lista completa delle scadenze
nella sezione ALERT.</p>
<img loading="lazy" src="https://i.postimg.cc/DyLH158F/deadline.png" />




    </div>
  );
}

