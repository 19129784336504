import User from "../../assets/images/allegati-ph.svg";
import FileCard from "../Documenti/prima_nota/Tipologia/FileCard";
import ButtonRm from "../ButtonRm";

import CustomBadge from "../CustomBadge";


export default function Attachments(props) {
	const setFileName = (index, new_name) => {
		let temp = [...props.formData.attachment];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "attachment", value: temp } };
		props.onChange(ele);
	};
	
	const deleteFile = (index) => {
		let temp = [...props.formData.attachment];
		temp.splice(index, 1);
		let ele = { target: { name: "attachment", value: temp } };
		props.onChange(ele);
	};
	
	const handleChange = (e) => {
		let temp = [...props.formData.attachment];
		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
		}
		let ele = {
			target: { name: "attachment", value: temp },
		};
		props.onChange(ele);
	};

	//FARE CONTROLLO FINALE, CHE I CAMPI OBBLIGATORI SIANO STATI COMPILATI O RIMANDO

	console.log(props.formData.attachment, 'att')
	return (
		<>
			<div className="form-item-align-box-white d-block">
			<div className="mt-3">
				<label className="f-label fs-16 d-flex align-items-center
mb-3">
					Allegare documenti da associare all’unità immobiliare, ad esempio atti
					di proprietà, mandati ecc.
				</label>
			</div>
			<div className="unit-btn-group mb-4">
				<label
					type="button"
					htmlFor="input-file"
					className="general-btn white-btn text-center">
					Aggiungi
				</label>
				<input
					type="file"
					id="input-file"
					className="d-none"
					multiple={true}
					name="attachment"
					onChange={handleChange}></input>
			</div>
				{props.formData.attachment?.length > 0 ? (
					props.formData?.attachment.map((item, index) => {
						return (
							<FileCard
								key={index}
								name={item.name}
								setFileName={setFileName}
								deleteFile={deleteFile}
								id={index}
							/>
						);
					})
				) : (
					<div className="unit-empty-img">
						<img loading="lazy" src={User} alt="" />
						<span className="img-comment">
							Non hai aggiunto ancora alcun allegato
						</span>
					</div>
				)}
			</div>
			<div className="d-flex justify-content-end mt-5">
				<ButtonRm
				variant="tertiary"
				onClick={() => props.backward()}
				>
				Indietro
				</ButtonRm>

				<ButtonRm
					variant="primary"
					className="ms-2"
					onClick={() => props.save()}
				>
					Salva
				</ButtonRm>
			</div>
		</>
	);
}
