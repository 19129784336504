import React, { createContext, useState, useEffect } from "react";
import EmptyList from "../components/Membri//EmptyList";
import NewownerBtn from "../components/Membri/ProprietariSublocatori/NewownerBtn";
import TopHeading from "../components/TopHeading";

import imgIcon from "../assets/images/markdown.png";
import FilterTableM from "../components/Membri/Rate/Members/FilterTableM";
import Container from "../components/Container";
import TabForm from "../components/Membri/Rate/NewOwner/TabForm";

import {Row, Col} from "react-bootstrap"
import { Link } from "react-router-dom";
import AddBtn from "../components/AddBtn";

import filter from "../assets/images/icon-sidebar/filter.svg"
import sort from "../assets/images/icon-sidebar/sort.svg"

import SearchInput from "../components/SearchInput";

import { sortDatesByKey, sortStringByKey } from "../utils/filtering";

import api, { rate_endpoint, management_endpoint } from "../api/api";


import ModalCheckUser from "../components/ModalCheckUser";
import { checkUserData } from "../utils/checkUserRole";



const RataText = createContext();

export default function Rate(props) {
  const [show, setShow] = useState(false);
  const [searchName, setSearchName] = useState("");

  const [rate, setRate] = useState([])

  //get rate
  async function fetchRate() {
    try {
    const response = await api().get(`${rate_endpoint}`);
    if (response.data) {
        setRate(response.data?.content);
    }
    } catch (error) {
    console.log(error);
    }
  }

  useEffect(() => {
      fetchRate();
  }, []);

  //NEW SEARCH & FILTER
	//SEARCH
	const [search, setSearch] = useState("")

  //DERIVED LIST WITH ONLY THE UNITS THAT FITS A RESEARCH
  const searchedList =
  rate && rate.length > 0
    ? rate.filter((unit) =>
      unit.installmentName
      .toLowerCase().includes(search?.toLocaleLowerCase())
  )
  : [];

  //QUI PRENDERE LA LISTA DELLE SEZIONI DEFAULT E CREATE DA USER
  const categories = [
  
  ];

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortBy, setSortBy] = useState("più-recenti");

  const [filteredUnits, setFilteredUnits] = useState([])

  useEffect(() => {
  if(rate.length > 0)  {
    setFilteredUnits(
      rate.filter((unit) => {
    return (
      (selectedCategories.length === 0 || selectedCategories.includes(unit.section)) &&
      unit.installmentName
      .toLowerCase().includes(search.toLowerCase())
    );
    })
  )}
  }, [search, selectedCategories, rate]);

  const [sortedUnits, setSortedUnits] = useState(filteredUnits);

  console.log("rate", rate)

  useEffect(() => {
    switch (sortBy.toLowerCase()) {
      case "z-a":
      setSortedUnits(sortStringByKey(filteredUnits, "installmentName", false));
      break;
      case "a-z":
      setSortedUnits(sortStringByKey(filteredUnits, "installmentName"));
      break;
      case "meno-recenti":
      setSortedUnits(sortDatesByKey(filteredUnits, "cdat"));
      break;
      default:
      case "più-recenti":
      setSortedUnits(sortDatesByKey(filteredUnits, "cdat", false));
    }
  }, [filteredUnits, sortBy]);

  function clearFiltersAndSort() {
  setSearch("");
  setSelectedCategories([]);
  setSortBy("più-recenti");
  setFilteredUnits(rate);
  }

//DATI PER MEMBRI
//OWNERS
//FARE STESSA COSA CONDUCTORS E SUPPLIER

console.log(rate, "rate")

const ratesByOwner = rate.reduce((acc, item) => {
  const ownerId = item.owners[0]?.id;
  if (ownerId) {
    if (!acc[ownerId]) {
      acc[ownerId] = { installments: [], units: [], condominiums: [] };
    }
    acc[ownerId].installments.push(item);
    if (item.unit?.length > 0) {
      const unitId = item.unit[0].id;
      if (!acc[ownerId].units.some(unit => unit.id === unitId)) {
        acc[ownerId].units.push(item.unit[0]);
      }
    }
    if (item.condominium?.length > 0) {
      const condoId = item.condominium[0].id;
      if (!acc[ownerId].condominiums.some(condo => condo.id === condoId)) {
        acc[ownerId].condominiums.push(item.condominium[0]);
      }
    }
  } else {
    acc.unowned.push(item);
  }
  return acc;
}, { unowned: [] });

const {unowned, ...onlyRatesbyOwner} = ratesByOwner

const ownersArray = Object.entries(onlyRatesbyOwner).map(([id, data]) => {
  const owner = rate.find(item => item.owners[0]?.id === parseInt(id))?.owners[0];

  const units = data?.units && data.units.length >0 && data.units.map(unit => {
    const unitRates = data.installments && data.installments.length > 0 && data.installments.filter(rate => rate.unit?.[0]?.id === unit.id && rate.owners[0]?.id === parseInt(id));
    const totalDue = unitRates.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = unitRates.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = unitRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    return {
      id: unit.id,
      name: unit.name,
      type: "unit",
      totalDue,
      totalPaid,
      totalOverdue,
    };
  });
  const condominiums = data?.condominiums && data?.condominiums.length>0 && data.condominiums.map(condo => {
    const condoRates = data.installments && data.installments.length > 0 && data.installments.filter(rate => rate.condominium?.[0]?.id === condo.id && rate.owners[0]?.id === parseInt(id));
    const totalDue = condoRates.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = condoRates.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = condoRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    return {
      id: condo.id,
      name: condo.name,
      type: "condominium",
      totalDue,
      totalPaid,
      totalOverdue,
    };
  });
  const ownerRates = data.installments && data.installments.filter(rate => rate.owners[0]?.id === parseInt(id));
  
  const totalDue = ownerRates?.reduce((sum, rate) => sum + rate.totalDue, 0);
  const totalPaid = ownerRates?.reduce((sum, rate) => sum + rate.totalPaid, 0);
  const today = new Date();
  const totalOverdue = ownerRates?.reduce((sum, rate) => {
    if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
      return sum + rate.totalDue - rate.totalPaid;
    }
    return sum;
  }, 0);
  return {
    id: parseInt(id),
    name: owner?.name,
    surname: owner?.surname,
    role: "owner",
    units,
    condominiums,
    installments: data.installments,
    totalDue,
    totalPaid,
    totalOverdue,
  };
});
  


//DATI CORRETTI PER DASHBOARD CONDUCTORS

const ratesByConductors = rate.reduce((acc, item) => {
  const conductorId = item.conductors[0]?.id;
  if (conductorId) {
    if (!acc[conductorId]) {
      acc[conductorId] = { installments: [], units: [], condominiums: [] };
    }
    acc[conductorId].installments.push(item);
    if (item.unit?.length > 0) {
      const unitId = item.unit[0].id;
      if (!acc[conductorId].units.some(unit => unit.id === unitId)) {
        acc[conductorId].units.push(item.unit[0]);
      }
    }
    if (item.condominium?.length > 0) {
      const condoId = item.condominium[0].id;
      if (!acc[conductorId].condominiums.some(condo => condo.id === condoId)) {
        acc[conductorId].condominiums.push(item.condominium[0]);
      }
    }
  } else {
    acc.unownedCond.push(item);
  }
  return acc;
}, { unownedCond: [] });

const {unownedCond, ...onlyRatesbyConductor} = ratesByConductors


const conductorsArray = Object.entries(onlyRatesbyConductor).map(([id, data]) => {
  const conductor= rate.find(item => item.conductors[0]?.id === parseInt(id))?.conductors[0];

  const units = data?.units && data.units.length >0 && data.units.map(unit => {
    const unitRates = data.installments && data.installments.length > 0 && data.installments.filter(rate => rate.unit?.[0]?.id === unit.id && rate.conductors[0]?.id === parseInt(id));
    const totalDue = unitRates.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = unitRates.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = unitRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    return {
      id: unit.id,
      name: unit.name,
      type: "unit",
      totalDue,
      totalPaid,
      totalOverdue,
    };
  });
  const condominiums = data?.condominiums && data?.condominiums.length>0 && data.condominiums.map(condo => {
    const condoRates = data.installments && data.installments.length > 0 && data.installments.filter(rate => rate.condominium?.[0]?.id === condo.id && rate.conductors[0]?.id === parseInt(id));
    const totalDue = condoRates.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = condoRates.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = condoRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    return {
      id: condo.id,
      name: condo.name,
      type: "condominium",
      totalDue,
      totalPaid,
      totalOverdue,
    };
  });
  const conductorRates = data.installments && data.installments.filter(rate => rate.conductors[0]?.id === parseInt(id));
  
  const totalDue = conductorRates?.reduce((sum, rate) => sum + rate.totalDue, 0);
  const totalPaid = conductorRates?.reduce((sum, rate) => sum + rate.totalPaid, 0);
  const today = new Date();
  const totalOverdue = conductorRates?.reduce((sum, rate) => {
    if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
      return sum + rate.totalDue - rate.totalPaid;
    }
    return sum;
  }, 0);
  return {
    id: parseInt(id),
    name: conductor?.name,
    surname: conductor?.surname,
    role: "conductor",
    units,
    condominiums,
    installments: data.installments,
    totalDue,
    totalPaid,
    totalOverdue,
  };
});

//DATI CORRETTI PER DASHBOARD FORNITORI
const ratesBySuppliers = rate.reduce((acc, item) => {
  const supplierId = item.suppliers[0]?.id;
  if (supplierId) {
    if (!acc[supplierId]) {
      acc[supplierId] = { installments: [], units: [], condominiums: [] };
    }
    acc[supplierId].installments.push(item);
    if (item.unit?.length > 0) {
      const unitId = item.unit[0].id;
      if (!acc[supplierId].units.some(unit => unit.id === unitId)) {
        acc[supplierId].units.push(item.unit[0]);
      }
    }
    if (item.condominium?.length > 0) {
      const condoId = item.condominium[0].id;
      if (!acc[supplierId].condominiums.some(condo => condo.id === condoId)) {
        acc[supplierId].condominiums.push(item.condominium[0]);
      }
    }
  } else {
    acc.unownedSup.push(item);
  }
  return acc;
}, { unownedSup: [] });

const {unownedSup, ...onlyRatesbySup} = ratesBySuppliers


const suppliersArray = Object.entries(onlyRatesbySup).map(([id, data]) => {
  const supplier = rate.find(item => item.suppliers[0]?.id === parseInt(id))?.suppliers[0];

  const units = data?.units && data.units.length >0 && data.units.map(unit => {
    const unitRates = data.installments && data.installments.length > 0 && data.installments.filter(rate => rate.unit?.[0]?.id === unit.id && rate.suppliers[0]?.id === parseInt(id));
    const totalDue = unitRates.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = unitRates.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = unitRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    return {
      id: unit.id,
      name: unit.name,
      type: "unit",
      totalDue,
      totalPaid,
      totalOverdue,
    };
  });
  const condominiums = data?.condominiums && data?.condominiums.length>0 && data.condominiums.map(condo => {
    const condoRates = data.installments && data.installments.length > 0 && data.installments.filter(rate => rate.condominium?.[0]?.id === condo.id && rate.suppliers[0]?.id === parseInt(id));
    const totalDue = condoRates.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = condoRates.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = condoRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    return {
      id: condo.id,
      name: condo.name,
      type: "condominium",
      totalDue,
      totalPaid,
      totalOverdue,
    };
  });
  const supplierRates = data.installments && data.installments.filter(rate => rate.suppliers[0]?.id === parseInt(id));
  
  const totalDue = supplierRates?.reduce((sum, rate) => sum + rate.totalDue, 0);
  const totalPaid = supplierRates?.reduce((sum, rate) => sum + rate.totalPaid, 0);
  const today = new Date();
  const totalOverdue = supplierRates?.reduce((sum, rate) => {
    if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
      return sum + rate.totalDue - rate.totalPaid;
    }
    return sum;
  }, 0);
  return {
    id: parseInt(id),
    name: supplier?.name,
    surname: supplier?.surname,
    role: "supplier",
    units,
    condominiums,
    installments: data.installments,
    totalDue,
    totalPaid,
    totalOverdue,
  };
});


// DATI CORRETTI PER DASHBOARD CONDO

const ratesByCondo = rate.reduce((acc, item) => {
  const condoId = item.condominium[0]?.id;
  if (condoId) {
    if (!acc[condoId]) {
      acc[condoId] = { installments: [], owners: [], conductors: [], suppliers: [] };
    }
    acc[condoId].installments.push(item);
    if (item.owners?.length > 0) {
      const ownerId = item.owners[0].id;
      if (!acc[condoId].owners.some(owner => owner.id === ownerId)) {
        acc[condoId].owners.push(item.owners[0]);
      }
    }
    if (item.conductors?.length > 0) {
      const conductorId = item.conductors[0].id;
      if (!acc[condoId].conductors.some(conductor => conductor.id === conductorId)) {
        acc[condoId].conductors.push(item.conductors[0]);
      }
    }
    if (item.suppliers?.length > 0) {
      const supplierId = item.suppliers[0].id;
      if (!acc[condoId].suppliers.some(supplier => supplier.id === supplierId)) {
        acc[condoId].suppliers.push(item.suppliers[0]);
      }
    }
  } else {
    acc.unassignedCondo.push(item);
  }
  return acc;
}, { unassignedCondo: [] });

const {unassignedCondo, ...onlyRatesByCondo} = ratesByCondo;

const condosArray = Object.entries(onlyRatesByCondo).map(([id, data]) => {
  const currentCondo = data.installments && data.installments.length > 0 && data?.installments?.[0]?.condominium?.[0];
  const ownersData = data?.owners && data.owners.length > 0 && data.owners.reduce((owners, owner) => {
    const ownerRates = data.installments && data.installments.length > 0 && data?.installments?.filter(rate => rate.owners?.[0].id === owner.id);
    const totalDue = ownerRates?.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = ownerRates?.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = ownerRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    owners.push({
      id: owner.id,
      name: owner.name,
      surname: owner.surname,
      role: "owner",
      totalDue,
      totalPaid,
      totalOverdue,
    });
    return owners;
  }, []);
  const totalDue = data.installments && data.installments.length > 0 && data.installments.reduce((sum, rate) => sum + rate.totalDue, 0);
  const totalPaid = data.installments && data.installments.length > 0 && data.installments.reduce((sum, rate) => sum + rate.totalPaid, 0);
  const today = new Date();
  const totalOverdue = data.installments && data.installments.length > 0 && data.installments.reduce((sum, rate) => {
    if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
      return sum + rate.totalDue - rate.totalPaid;
    }
    return sum;
  }, 0);
  return {
    id: currentCondo?.id,
    name: currentCondo?.name,
    type: "condominium",
    totalDue,
    totalPaid,
    totalOverdue,
    owners: ownersData,
  };
});

// DATI CORRETTI PER DASHBOARD UNIT

//FILTRO GESTIONI => PRENDI LE GESTIONI
const [gestioni, setGestioni] = useState([]);

async function getGestioni() {
  try {
    const response = await api().get(`${management_endpoint}`);
    if (response.data) {
      setGestioni(response.data.content);
    }
  } catch (err) {
    console.log(err);
  }
}

useEffect(() => {
  getGestioni()
}, []);


const ratesByUnit = rate.reduce((acc, item) => {
  const unitId = item.unit[0]?.id;
  if (unitId) {
    if (!acc[unitId]) {
      acc[unitId] = { installments: [], owners: [], conductors: [], suppliers: [] };
    }
    acc[unitId].installments.push(item);
    if (item.owners?.length > 0) {
      const ownerId = item.owners[0].id;
      if (!acc[unitId].owners.some(owner => owner.id === ownerId)) {
        acc[unitId].owners.push(item.owners[0]);
      }
    }
    if (item.conductors?.length > 0) {
      const conductorId = item.conductors[0].id;
      if (!acc[unitId].conductors.some(conductor => conductor.id === conductorId)) {
        acc[unitId].conductors.push(item.conductors[0]);
      }
    }
    if (item.suppliers?.length > 0) {
      const supplierId = item.suppliers[0].id;
      if (!acc[unitId].suppliers.some(supplier => supplier.id === supplierId)) {
        acc[unitId].suppliers.push(item.suppliers[0]);
      }
    }
  } else {
    acc.unassigned.push(item);
  }
  return acc;
}, { unassigned: [] });

const {unassigned, ...onlyRatesByUnit} = ratesByUnit;

const unitsArray = Object.entries(onlyRatesByUnit).map(([id, data]) => {
  const currentUnit = data.installments && data.installments.length > 0 && data?.installments?.[0]?.unit?.[0];
  const ownersData = data?.owners && data.owners.length > 0 && data.owners.reduce((owners, owner) => {
    const ownerRates = data.installments && data.installments.length > 0 && data?.installments?.filter(rate => rate.owners?.[0]?.id === owner.id);
    const totalDue = ownerRates?.reduce((sum, rate) => sum + rate.totalDue, 0);
    const totalPaid = ownerRates?.reduce((sum, rate) => sum + rate.totalPaid, 0);
    const today = new Date();
    const totalOverdue = ownerRates.reduce((sum, rate) => {
      if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
        return sum + rate.totalDue - rate.totalPaid;
      }
      return sum;
    }, 0);
    owners.push({
      id: owner.id,
      name: owner.name,
      surname: owner.surname,
      role: "owner",
      totalDue,
      totalPaid,
      totalOverdue,
    });
    return owners;
  }, []);
  const totalDue = data.installments && data.installments.length > 0 && data.installments.reduce((sum, rate) => sum + rate.totalDue, 0);
  const totalPaid = data.installments && data.installments.length > 0 && data.installments.reduce((sum, rate) => sum + rate.totalPaid, 0);
  const today = new Date();
  const totalOverdue = data.installments && data.installments.length > 0 && data.installments.reduce((sum, rate) => {
    if (today > new Date(rate.deadlineDate) && rate.totalPaid < rate.totalDue) {
      return sum + rate.totalDue - rate.totalPaid;
    }
    return sum;
  }, 0);
  return {
    id: currentUnit?.id,
    name: currentUnit?.name,
    type: "unit",
    totalDue,
    totalPaid,
    totalOverdue,
    owners: ownersData,
  };
});

const membersData = [...ownersArray, ...conductorsArray, ...suppliersArray]
const membersDataUnassigned = [...unowned, ...unownedCond, ...unownedSup]


const uniqueMembersDataUnassigned = membersDataUnassigned.filter((member, index, self) => (
  index === self.findIndex(obj => (
      obj.id === member.id 
  ))
));

//membersDataUnassigned SENZA DUPLICATI
const filteredMembersData = uniqueMembersDataUnassigned.filter(member => (
  member.owners.length === 0 &&
  member.conductors.length === 0 &&
  member.suppliers.length === 0
)); 

console.log(membersDataUnassigned,uniqueMembersDataUnassigned, filteredMembersData, "membersDataUnassigned")



const unitsData = [...unitsArray, ...condosArray]
console.log(unitsData, "unitsData")

const unitsDataUnassigned = [...unassigned, ...unassignedCondo]

const uniqueUnitDataUnassigned = unitsDataUnassigned.filter((el, index, self) => (
  index === self.findIndex(obj => (
      obj.id === el.id 
  ))
));

//unitsDataUnassigned SENZA DUPLICATI
const filteredUnitsData = uniqueUnitDataUnassigned.filter(member => (
  member.condominium.length === 0 &&
  member.unit.length === 0 
)); 


const rataIds = [];

//cancella tutte le rate 
const deleteRata = async (id) => {
  try {
      await api().delete(`${rate_endpoint}${id}`);
  } catch (err) {
      console.log(err);
  }
}

const handleDelete = async () => {
  try {
    rataIds.forEach(async (rataId) => {
      await deleteRata(rataId);
    });

    console.log('Rate eliminate con successo!');
  } catch (err) {
    console.log(err);
  }
}

console.log("weeeeeee", rate, unitsData, membersData)

/*

GESTIONE OBJ

{
    "id": 124,
    "name": "Casa  Rossi",
    "totalDue": 1600,
    "totalPaid": 55,
    "totalOverdue": 1600,
    "units": [
        {
            "id": 98,
            "name": "Emanuela",
            "surname": "Maviglia",
            "role": "owner",
            "totalDue": 0,
            "totalPaid": 55,
            "totalOverdue": 0
        }
    ],
    "condo": "units": [
        {
            "id": 98,
            "name": "Emanuela",
            "surname": "Maviglia",
            "role": "owner",
            "totalDue": 0,
            "totalPaid": 55,
            "totalOverdue": 0
        }
    ]
}

obbiettivo: mostrare le gestioni e relative unità

1) prendere le gestioni (gestioni)
2) prendere le unità (unitsData)
3) trovare a quale gestione corrisponde ciascuna unità o se nessuna tramite comparazione id

Mi serve un oggetto per ciascuna gestione:
 {
  gestioneId,
  units: []
}
*/

const gestioniWithUnits = gestioni.map(gestione => {
  const units = unitsData.filter(unit => {
      return gestione.realEstateManagements.some(management => management.unitID === unit.id);
  }).map(unit => unit);

  const totalAmountsUnit = units?.length > 0 ? units.reduce((acc, current) => {
    return {
      totalDue: acc.totalDue + current.totalDue,
      totalPaid: acc.totalPaid + current.totalPaid,
      totalOverdue: acc.totalOverdue + current.totalOverdue,
    };
  }, { totalDue: 0, totalPaid: 0, totalOverdue: 0 }) :
  {
    totalDue: 0,
    totalPaid: 0,
    totalOverdue: 0,
  }

  return {
      id: gestione.id,
      name: gestione.name,
      totalDue: totalAmountsUnit.totalDue ,
      totalOverdue: totalAmountsUnit.totalOverdue,
      totalPaid: totalAmountsUnit.totalPaid,
      units: units.length > 0 ? units : false
  };
});


 //CHECK IF DATA REQUIRED ARE COMPILED
 const [dataRequired, setDataRequired] = useState(true);
 //Gestisce il modale
 const [showCheck, setShowCheck] = useState(false)

 useEffect(() => {
     const fetchData = async () => {
         try {
             const response = await checkUserData();
             setDataRequired(response.isVerified);
             if (!response.isVerified) {
               setShowCheck(true);
           }
         } catch (error) {
             // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
             console.error('Errore durante il recupero dei dati utente:', error);
         }
     };

     fetchData();
 }, []);



  return (
    <>
      <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
          <TopHeading
            SmallHeading="empty"
          />
        </div>
        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">
          <Col >
            <h2 className="fw-bold text-start">Rate</h2>
            {/* <button onClick={(e) => {
              e.preventDefault()
              handleDelete()

            }

            }>Cancella tutte le rate</button> */}
          </Col>
          <Col className="d-flex justify-content-end ">
            <Link to="/rate/create">
              <AddBtn
                tooltip="Nuova Rata"
                className="me-3 me-md-0 order-md-1"
                dataRequired={dataRequired}
              />
            </Link>
            {/* <SearchInput
              className="order-md-0"
              setSearch={(term) => {
                setSearchTerm(term);
              }}
            /> */}
          </Col>
        </Row>
        
      </div>

      {show ? (
        <TabForm setShow={(v) => setShow(v)} />
      ) : (
        <div>
          {/* <RataText.Provider value={"Nuova Rata"}>
            <NewownerBtn
              btnText="Nuovo rata"
              setShow={(v) => setShow(v)}
              setSearchName={(v) => setSearchName(v)}
            />
          </RataText.Provider> */}
        {/*   {window.amountAndData === "" ? (
            <Row className="dashboard-container ">
              <div className="mx-auto d-table text-center empty-container ">
                <div className="mx-auto d-table ">
                <img loading="lazy" src={imgIcon} alt="empty" />
                </div>
                <p className="fs-16 text-secondary text-center">
                  Non hai aggiunto ancora nessun elemento
                </p>
              </div>
						</Row>
          ) : ( */}
           {(unitsData.length>0 || membersData.length>0) ?
            <Row className="dashboard-container">
              <div className="d-block mb-4 mt-4">
                <FilterTableM searchString={searchName} unitData={unitsData} unitsDataUnassigned={filteredUnitsData} membersDataUnassigned={filteredMembersData} 
                membersData={membersData} gestioniWithUnits={gestioniWithUnits}/>
              </div>
          </Row>:
           <Row className="dashboard-container ">
            <div className="mx-auto d-table text-center empty-container ">
              <div className="mx-auto d-table ">
              <img loading="lazy" src={imgIcon} alt="empty" />
              </div>
              <p className="fs-16 text-secondary text-center">
                Non hai aggiunto ancora nessun elemento
              </p>
            </div>
         </Row>
          }
          
        </div>
      )}

  <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
        <p className="mb-3">Per procedere con la creazione di una rata, è necessario completare le seguenti informazioni:</p>
          <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>

      </ModalCheckUser>
    </>
  );
}
export { RataText };
