import React, {useEffect, useState} from "react";
import { Chatdetails } from "./Chatdetails";
import { Chatlist } from "./ChatList";
import { ChatTopsec } from "./ChatTopsec";
import TopHeading from "../../TopHeading";
import "../../../assets/css/css-chat/font-awesome.min.css";
import "../../../assets/css/css-chat/style.css";
import "../../../assets/css/css-chat/responsive.css";
import "../../../assets/css/Chat.css";
import { useMediaQuery } from "react-responsive";
import userchat from  "../../../assets/images/images-chat/user-chat.png"
import chatplaceholder from  "../../../assets/images/chat-placeholder.svg"
import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';

import api from "../../../api/api";
import { chat_list_endpoint } from "../../../api/api";

import { Client } from "@stomp/stompjs";

import WebSocketComponent from "./Websocket";

const Chat = ({userData}) => {
    const [chatCreatePopup, setChatPopup] = useState(false);

    const {chatId} = useParams()

    const [clickedChat, setClickedChat] = useState("")
    const isDesktop = useMediaQuery({
        query: "(min-width: 992px)",
    });

    function selectChat (id){
        setClickedChat(id)
    }

    const [chatList, setChatList] = useState([])

    async function getAll() {
        try {
            const response = await api().get(`/api/v1/chat-box/getAllChatBox`);
            console.log(response, "response chat box")
            if (response.data) {
                console.log(response.data, response.data.chatBoxes)
                let activeChat = response.data.chatBoxes?.filter(el => el["chat-box-status-for-you"] == "ACTIVE")

                setChatList(activeChat)
            }
        }
        catch(error) {
                console.log(error)
        }
    } 

    console.log(userData, "userData")

    /*  async function getAll() {
        try {
            const response = await api().get(`${chat_list_endpoint}`);
            console.log(response, "response chat box")
            if(response.data) {
                setChatList(response.data?.content)
                
            }
        }
        catch(error) {
                console.log(error)
        }
    } */

    useEffect(() => {
        getAll()
    }, [])


    //WEB SOCKET STOMP

   /*  const [connected, setConnected] = useState(false);
    const [greetings, setGreetings] = useState([]);
    const [name, setName] = useState('');
    const [stompClient, setStompClient] = useState(null)

    console.log(greetings, "SALUTI")

    useEffect(() => {
        let client = new Client({
            brokerURL: BASE_URL_WS,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: (frame) => {
                setConnected(true);
                 client.subscribe('/topic/greetings', (greeting) => {
                    showGreeting(JSON.parse(greeting.body).content);
                });
            },
            onWebSocketError: (error) => {
                console.error('Error with WebSocket: ', error);
            },
            onStompError: (frame) => {
                console.error('Broker reported error: ' + frame.headers['message']);
                console.error('Additional details: ' + frame.body);
            }
        });

        client.activate();

        setStompClient(client)

        return () => {
            if (client ) {
                client .deactivate();
                setConnected(false);
            }
        };
    }, []); */

    /* const connect = () => {
        if (stompClient && !connected) {
            stompClient.activate();
        }
    };

    const disconnect = () => {
        if (stompClient && connected) {
            stompClient.deactivate();
            setConnected(false);
            console.log("Disconnected");
        }
    }; */

  /*   const sendName = () => {
        if (stompClient && connected) {
            stompClient.publish({
                destination: "/app/hello",
                body: JSON.stringify({'content': 'test', 'id': userData.id})
            });
        }
    };

    const showGreeting = (message) => {
        setGreetings(prevGreetings => [...prevGreetings, message]);
    }; */



    /* nuova implementazione web socket */
    

    return (
        <>
            <TopHeading />
            <main>
                <div className="middle-sec">
                    <ChatTopsec setChatPopup={setChatPopup} chatCreatePopup={chatCreatePopup} setChatList={setChatList} getAll={getAll}/>
                      <div className="main-flex">
                            <Chatlist list={chatList} handleClick={selectChat} setChatPopup={setChatPopup} chatCreatePopup={chatCreatePopup}  />

                            {   isDesktop &&
                                <Routes>
                                    <Route index element={<ChatIndex />} />
                                    <Route path=":chatId" element={<Chatdetails chatList={chatList} setChatList={setChatList} getAll={getAll} userData={userData} />} />
                                </Routes>
                            }
                        </div> 
                        
                </div>
            </main>
            <Outlet />

        </>
    );
};
export default Chat;


function ChatIndex(){
    return (
        <div className="inner-flex rghtcht-sec my-3 chat-placeholder ">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img loading="lazy" className="mb-4" src={chatplaceholder}/>
                <p className="opacity-50">Premi il pulsante <i className="bi bi-plus-circle opacity-50"></i> per creare una nuova chat</p>
                <p className="opacity-50">O seleziona una conversazione esistente dalla barra di sinistra</p>
            </div>
        </div>
    )
}