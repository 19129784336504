const translationMapping = {
    id:"Id",
    cdat: "Data di creazione",
    udat: "Ultima modifica",
    userID: "ID utente",
    name:'Identificativo',
    rentType: 'Tipo di locazione',
    isIstatGrow: "Aggiornamento ISTAT",
    typology: 'Tipologia',
    startManagementDate: 'Data d’inizio gestione',
    rentPercentage: 'Percentuale di locazione',
    portionName: 'Denominazione della porzione locata',
    managementType: 'Tipo di gestione',
    patrimonialManager: 'Eventuale gestore patrimoniale/sublocatore',
    signDate: 'Data di firma',
    deadline: 'Data di scadenza',
    bail: 'Cauzione',
    paymentMethod: 'Metodo di pagamento',
    paymentNote: 'Note sul pagamento',
    proposalNote: 'Note sulla proposta',
    startRentingDate: 'Inizio locazione',
    endFirstPeriodDate: 'Fine periodo iniziale',
    endRenewalDate: 'Fine rinnovo',
    cancellationRenewalForOwner: 'Disdetta rinnovo locatori',
    cancellationRenewalForTenant: 'Disdetta rinnovo conduttori',
    cancellationRentForTenant: 'Disdetta locazione conduttori',
    deadlineForCancellation: 'Data entro cui può essere inviata la disdetta',
    waiverOfCancellation: 'Rinuncia al diniego del rinnovo',
    keyDeliveryDate: 'Consegna delle chiavi',
    dueDayForPayment: 'Pagamento affitto entro',
    closingContractDate: 'Data anticipata chiusura contratto',
    buildingDeliveryDate: 'Data di consegna',
    buildingDeliveryNote: 'Note sulla consegna',
    warrantyType: 'Tipo',
    warrantyInPrivateDocument: 'Garanzia terzi presente in una scrittura privata',
    borrowedAmount: 'Importo prestato da terzi',
    warrantyDeposit: 'Importo del deposito cauzionale',
    warrantyPaymentMethod: 'Metodo di pagamento',
    depositInterest: 'Interessi sul deposito cauzionale',
    warrantyDeliveryDate: 'Data di consegna',
    voluntaryPenaltyClause: 'Clausola pena volontaria',
    warrantySubjectToVat: 'Garanzia soggetta a IVA',
    warrantyPaymentDate: 'Data versamento cauzione',
    warrantyTotalAmount: 'Importo totale fideiussioni/importo garantito',
    feePeriod: 'Canone su base',
    feeFrequency: 'Cadenza delle rate',
    favoritePaymentMethod: 'Modalità di pagamento preferita',
    notificationType: 'Tipo di notifica dei pagamenti al locatore',
    currentMonthlyFee: 'Canone attuale mensile',
    originalMonthlyFee: 'Canone originale mensile',
    currentMonthlyRateExpenses: 'Spese forfettarie attuali',
    originalMonthlyRateExpenses: 'Spese forfettarie mensile',
    readCounterManagement: 'Gestione lettura contatori',
    counterNote: 'Note relative ai contatori',
    haveAdjustmentOnTurnover: 'Conguaglio sul fatturato del conduttore',
    turnoverThreshold: 'Soglia di fatturato',
    subjectToInvoice: 'Soggetto a fattura',
    vatCanon: 'IVA canone',
    istatUpdateFrom: 'Aggiornamento a partire dal',
    istatGrow: 'Soggetto aumento ISTAT',
    istat: 'ISTAT',
    feeOverview: 'Canone',
    condominiumFee: 'Spese condominiali previsionali (annuale)',
    registrationExpenses: 'Spese di registrazione',
    advanceCharges: 'Anticipo oneri accessori (mensile)',
    contractType: 'Tipologia di contratto',
    registrationTaxFree: 'Esente di imposta di registro',
    stampDutyFree: 'Esente di imposta di bollo',
    mainBuilding: 'Abitazione principale del conduttore',
    registered: 'Registrato',
    registrationDate: 'Data registrazione',
    agenziaEntrateOffice: 'Ufficio dell’Agenzia delle Entrate',
    officeCode: 'Codice dell’ufficio al momento della registrazione',
    serial: 'Serie',
    number: 'Numero',
    subNumber: 'Sotto numero',
    contractCode: 'Codice identificativo del contratto',
    registrationForAllDuration: 'Registrazione per l’intera durata...',
    extensionForAllDuration: 'Proroga da registrare per l’intera durata nei...',
    percentageTaxConductor: '% sulle imposte a carico del conduttore',
    percentageStampConductor: '% sui bolli contratto a carico del conduttore',
    registrationFromConductor: 'Registrazione fatta dai conduttori',
    amountConductor: 'Importo conduttori',
    amountOwner: 'Importo locatori',
    registrationTotalAmount: 'Importo totale',
    registrationTax: 'Imposta di registro',
    copiesNumber: 'Numero di facciate del contratto',
    stampsCopiesNumber: 'Numero di bolli per copia contratto',
    privateScriptureNumber: 'Scritture private, certificazioni e inventari',
    receiptsNumber: 'Ricevute e quietanze',
    mapsNumber: 'Mappe, planimetrie e disegni',
    privateScriptureAmount: 'Per contratto e allegati riguardanti...',
    receiptsAmount: 'Ricevute e quietanze',
    mapsAmount: 'Mappe, planimetrie e disegni',
    stampTotalAmount: 'Totale imposta di bollo',
    contractModelName: 'Nome del modello',
    contractText: 'Testo del contratto',
    contractCopies: 'Copie del contratto',
    attachment: 'Allegati',
    conductors: 'Conduttori',
    owners: 'Proprietari',
    suppliers:"Fornitori"
};

export default translationMapping 