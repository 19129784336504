import Form from "react-bootstrap/Form";
import UnitCard from "../UnitCard";
import { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UnitModal from "../UnitModal";
import { Link } from "react-router-dom";

import {Row, Col} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill, BsPrinter } from "react-icons/bs";

import ButtonRm from "../../ButtonRm";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import PrintDownloadComponent from "../../PrintDownloadComponent";

import api, { owner_endpoint } from "../../../api/api";

import ExportButton from "../../Membri/Fornitori/ExportButton";
import translationMapping from "../mapping";

import CustomBadge from "../../CustomBadge";




const Metodo_di_pagamento = [
    "Accredito su conto corrente",
    "addebito su conto corrente",
    "assegno",
    "bancomat",
    "bollettino freccia",
    "bollettino postale",
    "bonifico",
    "carta di credito",
    "cessione del credito d’imposta",
    "compensazione finanziaria",
    "contanti",
    "giroconto",
    "Mav",
    "paypal",
    "rav",
    "ri.ba",
    "rid",
    "sdd ( sepa direct debit)",
    "vaglia cambiario",
    "vaglia postale",
];

const rentType = ["Attiva", "Passiva", "Sublocazione"];

const schema = yup.object().shape({
	rentType: yup.string().required("Seelziona il tipo di locazione"),
	rentPercentage: yup.number("Inserisci un numero").max(100, "Il valore massimo è 100%"),
	name:yup.string().required("Inserisci un indentificativo univoco"),
	
});


const Typology = (props) => {
	const [data, setData] = useState({});

	console.log("data", data
	)

	const init = () => {
		setData({ 
			name: props.formData.name,
			typology: props.formData.typology,
			rentType: props.formData.rentType,
			rentPercentage: props.formData.rentPercentage,
			portionName: props.formData.portionName,
			patrimonialManager: props.formData.patrimonialManager,
			signDate:props.signDate?.substring(0,10),
			deadline:props.deadline?.substring(0,10),
			bail: props.formData.bail,
			paymentMethod: props.formData.paymentMethod,
			paymentNote: props.formData.paymentNote,
			proposalNote: props.formData.proposalNote
		});
	};

	console.log(data.typology)

	useEffect(() => {
		init();
	}, [props]);


	const onChange = (e) => {
		if (e.target.type == "checkbox") {
			setData({ ...data, [e.target.name]: e.target.checked });
		} else {
			setData({ ...data, [e.target.name]: e.target.value });
		}
	};

	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	

    const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		console.log(data)
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		props.forward()
	};

	const componentRef = useRef();

	//aggiungi sublocatore o proprietario
	const [managers, setManagers] = useState([]);

	const fetchManagers = async () => {
		try {
			const res = await api().get(`${owner_endpoint}`);
			if (res.data) {
				console.log(res.data.content);
				setManagers(res.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchManagers();
	}, [])



	return (
		<>	
		<Form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="form-item-align-box d-block pt-3 pb-5" ref={componentRef}>
			{!props.edit_mode && (
					<div className="d-flex mb-3 justify-content-end">
						{/* <button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2">
							<BsPrinter color="var(--edit-color)" />
						</button>
						<button
							type="button"
							className="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40">
							<BsDownload color="var(--edit-color)" />
						</button> */}

				
					<ExportButton data={data} showPrint mapping={translationMapping} classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>

				</div>
				)}
						<fieldset disabled={props.edit_mode ? false : true}>

			<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
			<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Identificativo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Identificativo
									<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

								</Form.Label>
								<Form.Control 
									{...register("name")}
									type="text" 
									name="name"
									value={data.name}
									onChange={onChange}
									isInvalid={!!errors.name}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.name?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>	
							
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Tipo <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row className="mb-3" >
							<Col md={6}>
								<div className="d-block">
									<div className="radioLabel">
										<input
											{...register("typology")}
											type="radio"
											value={false}
											name="typology"
											checked={!data.typology}
											onChange={(e) => {
												let temp = { target: { name: e.target.name, value: false } };
												onChange(temp);
											}}
										/>
										<label htmlFor="contactChoice1" className="btn-radio">
										&nbsp;Affitto
										</label>
									</div>
									<div className="radioLabel">
										<input
											{...register("typology")}
											type="radio"
											value={true}
											name="typology"
											checked={data.typology}
											onChange={(e) => {
												let temp = { target: { name: e.target.name, value: true } };
												onChange(temp);
											}}
										/>
										<label htmlFor="contactChoice2" className="btn-radio">
										&nbsp;Proposta
										</label>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Tipo di locazione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
										{...register("rentType")}
										isInvalid={!!errors.rentType}
										value={data.rentType}
										onChange={onChange} >
										{rentType.map((item, index) => {
											return (
												<option key={index} value={item}>
													{item}
												</option>
											);
										})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.renType?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Percentuale di locazione
									</Form.Label>
									<Form.Control 
										{...register("rentPercentage")}
										type="number" 
										name="rentPercentage"
										value={data.rentPercentage}
										onChange={onChange}
										isInvalid={!!errors.rentPercentage}
										max="100"
										min="0"
									/>
									<Form.Control.Feedback type="invalid" >
									{errors.rentPercentage?.message}
									</Form.Control.Feedback>
								</Form.Group>
								</Col>
						</Row>
						{data.rentType == "Passiva" &&
							<Row>
							<Col md={6} className="d-flex align-items-center">
								<Form.Group className=" mb-3 me-2" style={{width:"90%"}}>
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Eventuale gestore patrimoniale che riceve i pagamenti
									</Form.Label>
									<Form.Select 
										{...register("patrimonialManager")}
										isInvalid={!!errors.patrimonialManager}
										name="patrimonialManager"
										value={data.patrimonialManager}
										onChange={onChange}>
											<option></option>
											{
												managers.length>0 &&
												managers.map(item => <option key={item.id} value={item.surname + " " + item.name}>{item.surname + " " + item.name}</option>)
											}
									</Form.Select>
								</Form.Group>
								<Link to="/proprietari/create">
								<BsPlusCircleFill
									className="add-section-btn mt-4"
									color="black"
								/>
							</Link>
							</Col>
						</Row>	
						}
						{data.rentType  == "Sublocazione" &&
							<Row>
							<Col md={6} className="d-flex align-items-center">
								<Form.Group className=" mb-3 me-2" style={{width:"90%"}}>
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Sublocatore
									</Form.Label>
									<Form.Select 
										{...register("patrimonialManager")}
										isInvalid={!!errors.patrimonialManager}
										name="patrimonialManager"
										value={data.patrimonialManager}
										onChange={onChange}>
											<option></option>
											{
												managers.length>0 &&
												managers.map(item => <option key={item.id} value={item.surname + " " + item.name}>{item.surname + " " + item.name}</option>)
											}
									</Form.Select>
								</Form.Group>
								<Link to="/proprietari/create">
								<BsPlusCircleFill
									className="add-section-btn mt-4"
									color="black"
								/>
							</Link>
							</Col>
						</Row>	
						}								
					</Accordion.Body>
				</Accordion.Item>
				
				{data.rentPercentage != 100 && <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Denominazione della porzione locata</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<label className="f-label fs-14 mb-2">
							Avendo scelto una percentuale di locazione diversa da 100% è
							necessario fornire una denominazione di questa porzione locata da
							inserire nel contratto generato.
						</label>
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Denominazione della porzione locata

								</Form.Label>
								<Form.Control 
									{...register("portionName")}
									type="text" 
									name="portionName"
									value={data.portionName}
									onChange={onChange}
									isInvalid={!!errors.portionName}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.portionName?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>	
							
					</Accordion.Body>
				</Accordion.Item>}
				{data.typology == true && <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="3" activeKey={activeKey}>Dati della Proposta</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<div className="row">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Data di firma
									</label>
									<input
										max="9999-12-31"
										name="signDate"
										value={data.signDate}
										onChange={onChange}
										type="date"></input>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Data di scadenza
									</label>
									<input
										max="9999-12-31"
										name="deadline"
										value={data.deadline}
										onChange={onChange}
										type="date"></input>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Cauzione della proposta
									</label>
									<input type="number" name="bail" value={data.bail} onChange={onChange}/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Metodo di pagamento
									</label>
									<select
										name="paymentMethod"
										value={data.paymentMethod}
										onChange={onChange}>
										{Metodo_di_pagamento.map((menu, index) => {
											return (
												<option key={index} value={menu}>
													{menu}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Nota sul pagamento
									</label>
									<input
										name="paymentNote"
										value={data.paymentNote}
										onChange={onChange}
										type="text"
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Note sulla proposta
									</label>
									<input
										type="text"
										name="proposalNote"
										value={data.proposalNote}
										onChange={onChange}
									/>
								</div>
							</div>
						</div>
						</Row>	
							
					</Accordion.Body>
				</Accordion.Item>}
			</Accordion>
			</fieldset>

			</div>
        </Form>

		</>
	);
};

export default Typology;
