import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import NumericInput from "react-numeric-input";
import { BsPlusCircleFill } from "react-icons/bs";
import CurrencyInput from "react-currency-input-field";
import SuretyModal from "./SuretyModal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AccountModal from "./AccountModal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import CustomToggle from "../CustomToggle";
import ButtonRm from "../ButtonRm";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import CustomBadge from "../CustomBadge";


const schema = yup.object().shape({
	managementType: yup.string(),
	startManagementDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	startRentingDate: yup.date().required("Inserisci la data di inizio locazione").nullable(),
	endFirstPeriodDate: yup.date().required("Inserisci la data di fine locazione").nullable(),
	endRenewalDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	cancellationRenewalForOwner:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	deadlineForCancellation: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	cancellationRentForTenant:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	cancellationRenewalForTenant:  yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	waiverOfCancellation: yup.boolean(),
	keyDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	closingContractDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	dueDayForPayment: yup.number("Inserisci il giorno di riferimento").max(31, "Inserisci un numero da 1 a 31").nullable().transform((_, val) => val ? Number(val) : null),
	buildingDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	buildingDeliveryNote: yup.string(),
	warrantyDeposit: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	warrantyPaymentMethod: yup.string(),
	depositInterest: yup.string(),
	warrantyDeliveryDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
	warrantyTotalAmount:yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	base_fee: yup.string(),
	feeFrequency: yup.string(),
	notificationType: yup.string(),
	currentMonthlyFee: yup.number("Inserisci un numero").required("Inserisci il canone mensile"),
	currentMonthlyRateExpenses: yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
	turnoverThreshold: yup.number("Inserisci un numero"),
	advanceCharges:yup.number("Inserisci un numero").nullable().transform((_, val) => val ? Number(val) : null),
})

export default function RegistryData(props) {
	const [surety_modal_show, setSuretyModalShow] = useState(false);
	const [account_modal_show, setAccountModalShow] = useState(false);
	const management_type = [
		"Completa",
		"Parziale",
		"Invisibile",
		"Scadenza e rate - no incassi",
		"Solo scadenze",
		"Solo contratto",
		"Mediazione affitto",
	];
	const warranty_type = [
		"Deposito cauzionale",
		"Deposito cauzionale + Fideiussioni",
		"Deposito cauzionale + Garanzie personali su importo prefissato",
		"Deposito cauzionale + Garanzie personali sul canone",
		"Deposito cauzionale + Garanzie personali sul canone + spese",
		"Fideiussioni",
		"Garanzie personali su importo prefissato",
		"Garanzie personali sul canone",
		"Garanzie personali sul canone + spese",
		"Libretto al portatore",
		"Nessuna",
		"PAC. Politica Agricola Comune",
	];
	const base_fee = ["Mensile", "Annuale", "Tutta la durata"];
	const frequency = [
		"Mensile",
		"Bimestrale",
		"trimestrale",
		"quadrimestrale",
		"semestrale",
		"bimestrale anno solare",
		"trimestrale anno solare",
		"quadrimestrale anno solare",
		"semestrale anno solare",
		"annuale",
		"biennale",
		"triennale",
		"stagionale 3 mesi",
		"stagionale 4 mesi",
		"stagionale 5 mesi",
		"stagionale 6 mesi",
		"stagionale 7 mesi",
		"stagionale 8 mesi",
		"stagionale 9 mesi",
		"annuale anno solare",
		"bimestrale anno solare + 1",
		"trimestrale anno solare + 1",
		"quadrimestrale anno solare + 1",
		"semestrale anno solare + 1",
	];
	const account = [];
	const payment_method = [
		"Accredito su conte corrente",
		"Addebito su conto corrente",
		"Assegno",
		"Bancomat",
		"Bollettino freccia",
		"Bollettino postale",
		"Bonifico",
		"Carta di credito",
		"Cessione del credito d’impresa",
		"Compensazione finanziaria",
		"Contanti",
		"Giroconto",
		"MAV",
		"PAYPAL",
		"RAV",
		"RI.BA.",
		"RID",
		"SDD - SEPA DIRECT DEBIT",
		"VAGLIA CAMBIARIO",
		"VAGLIA POSTALE",
	];
	const security_deposit_interest = [
		"Nessun interesse",
		"Alla chiusura della locazione",
		"Ricorrenza annuale",
		"All’inizio dell’anno solare",
	];
	const preffered_payment_method = [
		"Assegno",
		"Bollettino freccia",
		"Bollettino postale",
		"Bonifico",
		"Contanti",
		"MAV",
		"RAV",
		"RI.BA.",
		"RI.BA 30 60 90",
		"RID",
		"SDD - SEPA DIRECT DEBIT",
		"VAGLIA POSTALE",
	];
	const [preffered_payment_method_id, setPrefferedPaymentMethodId] =
		useState(0);
	const notification_type = ["Nessuna", "sms", "mail"];
	const meter_management = [
		"Non gestite",
		"solo lettura contatori",
		"per verbali",
		"conteggio consumi letture inizio fine locazione",
		"conteggio conteggio consumi letture arbitrarie",
	];
	const update = ["Nessun aggiornamento", "2° anno", "3° anno"];
	const istat = ["100%", "75%"];
	const vat_fee_type = [
		"4%",
		"5%",
		"10%",
		"20%",
		"21%",
		"22%",
		"esente art.10",
		"escluso art.15",
		"non imponibile (generico)",
		"fuori campo IVAA art.2",
		"non imponibile art.8 comma 1",
		"non imponibile art.8 comma 2",
		"non imponibile art.9",
		"non imponibile art 72 comma 3 numero 4",
		"fuori campo iva art 7",
		"operazione soggetta ad inversione contabile art.17 comm 6 a-ter",
		"escluso art 26 comma 3",
		"esente ai sensi dell'art 1 comma da 54 a 89 della legge n. 190/2014",
		"perazione soggetta ad inversione contabile art. 17 comma 6 a-bis",
		"operazione soggetta ad inversione contabile art.17 comma 6 a-ter (natura 6.7)",
	];
	const [warranty_type_index, setWarrantyTypeIndex] = useState(0);
	const onChangeNumericInput = (value, identity) => {
		let e = { target: { name: identity, value: value } };
		props.onChange(e);
	};



	 //TOOLTIP CESSIONE
	const infoHover = "È una comunicazione obbligatoria che deve essere presentata da parte di chi concede l'uso esclusivo a qualsiasi titolo (vendita, affitto, uso gratuito) di un immobile, o parte di esso, per un periodo superiore a 30 giorni."

	 //HANDLE ACCORDION
	 const [activeKey, setActiveKey] = useState("")
  
	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		  resolver: yupResolver(schema),
	  });
  
	  const onSubmit = (data) => {
	  console.log({data})
		  // controlla se ci sono errori di validazione
		  if (Object.keys(errors).length > 0) {
			  console.log("Ci sono errori di validazione", errors);
			  return;
		  } 
		  // azione da eseguire quando i dati sono tutti corretti
		  props.forward()
	  };

	  /* ADEGUAMENTO ISTAT*/


		const handleAccount = (e) => {
			let temp = [];
			if (e.target.value !== "") {
				temp.push(JSON.parse(e.target.value));
			}
			let ele = { target: { name: "bankAccount", value: temp[0] } };
			props.onChange(ele);
		};
	

	const contiArr = [...props.managerAccounts, ...props.ownerAccounts]


	console.log("contiarr", contiArr)

	

	return (
		<div className="d-block w-100 mb-3">
		<Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-item-align-box d-block pb-5">
          <Accordion defaultActiveKey={['1', '7']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="0" activeKey={activeKey}>Gestione </CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Tipo di gestione
                        </Form.Label>
                        <Form.Select 
							{...register("managementType")}
							name="managementType"
							value={props.formData.managementType}
							onChange={props.onChange}
							isInvalid={!!errors.managementType}
                        >
                        {management_type.map((menu, index) => {
							return (
								<option key={index} value={menu}>
									{menu}
								</option>
							);
						})}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.managementType?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Data d’inizio gestione
                        </Form.Label>
                        <Form.Control
						{...register("startManagementDate")}
						type="date"
						name="startManagementDate"
						value={props.formData.startManagementDate}
						onChange={props.onChange}
						isInvalid={!!errors.startManagementDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.startManagementDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>
                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="1" activeKey={activeKey}>Durata e date <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Inizio locazione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                        </Form.Label>
                        <Form.Control
						{...register("startRentingDate")}
						type="date"
						name="startRentingDate"
						value={props.formData.startRentingDate}
						onChange={props.onChange}
						isInvalid={!!errors.startRentingDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.startRentingDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
					<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Fine periodo iniziale <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                        </Form.Label>
                        <Form.Control
						{...register("endFirstPeriodDate")}
						type="date"
						name="endFirstPeriodDate"
						value={props.formData.endFirstPeriodDate}
						onChange={props.onChange}
						isInvalid={!!errors.endFirstPeriodDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.endFirstPeriodDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>
				<Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Fine rinnovo 
                        </Form.Label>
                        <Form.Control
						{...register("endRenewalDate")}
						type="date"
						name="endRenewalDate"
						value={props.formData.endRenewalDate}
						onChange={props.onChange}
						isInvalid={!!errors.endRenewalDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.endRenewalDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>
                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="2" activeKey={activeKey}>Preavviso, in mesi,della disdetta del rinnovo</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Disdetta rinnovo locatori
							</Form.Label>
							<Form.Control 
								{...register("scancellationRenewalForOwner")}
								type="number"
								name="cancellationRenewalForOwner"
								value={props.formData.cancellationRenewalForOwner}								
								onChange={props.onChange}
								isInvalid={!!errors.cancellationRenewalForOwner}
								placeholder="Inserisci un numero indicante i mesi"

							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Data entro cui può essere inviata la disdetta
                        </Form.Label>
                        <Form.Control
						{...register("deadlineForCancellation")}
						type="date"
						name="deadlineForCancellation"
						value={props.formData.deadlineForCancellation}
						onChange={props.onChange}
						isInvalid={!!errors.deadlineForCancellation}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.deadlineForCancellation?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>
				<Row>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Disdetta rinnovo conduttori
							</Form.Label>
							<Form.Control 
								{...register("cancellationRenewalForTenant")}
								type="number"
								name="cancellationRenewalForTenant"
								value={props.formData.cancellationRenewalForTenant}					
								onChange={props.onChange}
								isInvalid={!!errors.cancellationRenewalForTenant}
								placeholder="Inserisci un numero indicante i mesi"

							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForTenant?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Disdetta locazione conduttori
							</Form.Label>
							<Form.Control 
								{...register("cancellationRentForTenant")}
								type="number"
								name="cancellationRentForTenant"
								value={props.formData.cancellationRentForTenant}
								onChange={props.onChange}
								isInvalid={!!errors.cancellationRentForTenant}
								placeholder="Inserisci un numero indicante i mesi"

							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRentForTenant?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
                    
                </Row>
				<Col md={12}>
					<Form.Group className="mb-3" controlId="formBasicCheckbox">
						<Form.Check type="checkbox"
							{...register("waiverOfCancellation")}
							name="waiverOfCancellation"
							checked={props.formData.waiverOfCancellation}
							onChange={props.onChange} label="Rinuncia al diniego del rinnovo" />
					</Form.Group>
                </Col>
                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="3" activeKey={activeKey}>Altre date</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
					<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                       	Data anticipata chiusura contratto
                        </Form.Label>
                        <Form.Control
						{...register("closingContractDate")}
						type="date"
						name="closingContractDate"
						value={props.formData.closingContractDate}
						onChange={props.onChange}
						isInvalid={!!errors.closingContractDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.closingContractDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                       	Consegna delle chiavi
                        </Form.Label>
                        <Form.Control
						{...register("keyDeliveryDate")}
						type="date"
						name="keyDeliveryDate"
						value={props.formData.keyDeliveryDate}
						onChange={props.onChange}
						isInvalid={!!errors.keyDeliveryDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.keyDeliveryDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>
				<Row>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Pagamento affitto entro giorno
							</Form.Label>
							<Form.Control 
								{...register("dueDayForPayment")}
								type="number"
								name="dueDayForPayment"
								value={props.formData.dueDayForPayment}
								onChange={props.onChange}								
								isInvalid={!!errors.dueDayForPayment}
								placeholder="Inserisci un numero indicante il giorno"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.dueDayForPayment?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
                    
                </Row>

                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="4" activeKey={activeKey}>Cessione di fabbricato <OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className='tooltipContabilita'>{infoHover}</Tooltip>
								}
								>
								<i class="bi bi-info-circle ms-2"></i>
							</OverlayTrigger></CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
					<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                    	Data di consegna
                        </Form.Label>
                        <Form.Control
						{...register("buildingDeliveryDate")}
						type="date"
						name="buildingDeliveryDate"
						value={props.formData.buildingDeliveryDate}
						onChange={props.onChange}
						isInvalid={!!errors.buildingDeliveryDate}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.buildingDeliveryDate?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                    	Note sulla consegna
                        </Form.Label>
                        <Form.Control
						{...register("buildingDeliveryNote")}
						name="buildingDeliveryNote"
						onChange={props.onChange}
						value={props.formData.buildingDeliveryNote}
						type="text"
						isInvalid={!!errors.buildingDeliveryNote}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.buildingDeliveryNote?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>

                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="5" activeKey={activeKey}>Garanzia </CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Tipo di garanzia
                        </Form.Label>
                        <Form.Select 
							{...register("warrantyType")}
							name="warrantyType"
							value={props.formData.warrantyType}
							onChange={(e) => {
								props.onChange(e);
								setWarrantyTypeIndex(e.target.selectedIndex);
							}}
							isInvalid={!!errors.warrantyType}
                        >
                        {warranty_type.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												<span>{menu}</span>
											</option>
										);
									})}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.warrantyType?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                </Row>
					{ warranty_type_index == 0 &&
						<Row>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo del deposito cauzionale in €
							</Form.Label>
							<Form.Control 
								{...register("warrantyDeposit")}
								type="number"
								name="warrantyDeposit"
								value={props.formData.warrantyDeposit}						
								onChange={props.onChange}
								isInvalid={!!errors.warrantyDeposit}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Metodo di pagamento
									</Form.Label>
									<Form.Select 
										{...register("warrantyPaymentMethod")}
										name="warrantyPaymentMethod"
										value={props.formData.warrantyPaymentMethod}
										onChange={props.onChange}
										isInvalid={!!errors.warrantyPaymentMethod}
									>
									{payment_method.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyPaymentMethod?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Interessi sul deposito cauzionale
									</Form.Label>
									<Form.Select 
										{...register("depositInterest")}
										name="depositInterest"
										value={props.formData.depositInterest}
										onChange={props.onChange}
										isInvalid={!!errors.depositInterest}
									>
									{security_deposit_interest.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.depositInterest?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							   <Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Data di consegna
										</Form.Label>
										<Form.Control
											{...register("warrantyDeliveryDate")}
											type="date"
											name="warrantyDeliveryDate"
											value={props.formData.warrantyDeliveryDate}
											onChange={props.onChange}
											className
											isInvalid={!!errors.warrantyDeliveryDate}
										>
										</Form.Control>
										<Form.Control.Feedback type="invalid" >
										{errors.warrantyDeliveryDate?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantyInPrivateDocument")}
										name="warrantyInPrivateDocument"
										checked={props.formData.warrantyInPrivateDocument}
										onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
								</Form.Group>
                			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantySubjectToVat")}
										name="warrantySubjectToVat"
										checked={props.formData.warrantySubjectToVat}
										onChange={props.onChange}label="Garanzia soggetta ad IVA" />
								</Form.Group>
                			</Col>
							<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
					}
					{ warranty_type_index == 1 &&
						<Row>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo del deposito cauzionale in €
							</Form.Label>
							<Form.Control 
								{...register("warrantyDeposit")}
								type="number"
								name="warrantyDeposit"
								value={props.formData.warrantyDeposit}						
								onChange={props.onChange}
								isInvalid={!!errors.warrantyDeposit}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Metodo di pagamento
									</Form.Label>
									<Form.Select 
										{...register("warrantyPaymentMethod")}
										name="warrantyPaymentMethod"
										value={props.formData.warrantyPaymentMethod}
										onChange={props.onChange}
										isInvalid={!!errors.warrantyPaymentMethod}
									>
									{payment_method.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyPaymentMethod?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("borrowedAmount")}
										name="borrowedAmount"
										checked={props.formData.borrowedAmount}
										onChange={props.onChange}label="Importo prestato da terzi" />
								</Form.Group>
                			</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Interessi sul deposito cauzionale
									</Form.Label>
									<Form.Select 
										{...register("depositInterest")}
										name="depositInterest"
										value={props.formData.depositInterest}
										onChange={props.onChange}
										isInvalid={!!errors.depositInterest}
									>
									{security_deposit_interest.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.depositInterest?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							   <Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Data di consegna
										</Form.Label>
										<Form.Control
											{...register("warrantyDeliveryDate")}
											type="date"
											name="warrantyDeliveryDate"
											value={props.formData.warrantyDeliveryDate}
											onChange={props.onChange}
											className
											isInvalid={!!errors.warrantyDeliveryDate}
										>
										</Form.Control>
										<Form.Control.Feedback type="invalid" >
										{errors.warrantyDeliveryDate?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantyInPrivateDocument")}
										name="warrantyInPrivateDocument"
										checked={props.formData.warrantyInPrivateDocument}
										onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
								</Form.Group>
                			</Col>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo totale fideiussioni
							</Form.Label>
							<Form.Control 
								{...register("warrantyTotalAmount")}
								type="number"
								name="warrantyTotalAmount"
								value={props.formData.warrantyTotalAmount}	
								onChange={props.onChange}
								isInvalid={!!errors.warrantyTotalAmount}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.warrantyTotalAmount?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<div className="mb-2">
								<span
									className="link-text"
									onClick={(e) => {
										setSuretyModalShow(true);
									}}>
									Elenco fideiussioni
								</span>
							</div>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantySubjectToVat")}
										name="warrantySubjectToVat"
										checked={props.formData.warrantySubjectToVat}
										onChange={props.onChange}label="Garanzia soggetta ad IVA" />
								</Form.Group>
                			</Col>
							<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
							</Col>
						</Row>
					}
					{ warranty_type_index == 2 &&
						<Row>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo del deposito cauzionale in €
							</Form.Label>
							<Form.Control 
								{...register("warrantyDeposit")}
								type="number"
								name="warrantyDeposit"
								value={props.formData.warrantyDeposit}						
								onChange={props.onChange}
								isInvalid={!!errors.warrantyDeposit}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Metodo di pagamento
									</Form.Label>
									<Form.Select 
										{...register("warrantyPaymentMethod")}
										name="warrantyPaymentMethod"
										value={props.formData.warrantyPaymentMethod}
										onChange={props.onChange}
										isInvalid={!!errors.warrantyPaymentMethod}
									>
									{payment_method.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyPaymentMethod?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("borrowedAmount")}
										name="borrowedAmount"
										checked={props.formData.borrowedAmount}
										onChange={props.onChange}label="Importo prestato da terzi" />
								</Form.Group>
                			</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Interessi sul deposito cauzionale
									</Form.Label>
									<Form.Select 
										{...register("depositInterest")}
										name="depositInterest"
										value={props.formData.depositInterest}
										onChange={props.onChange}
										isInvalid={!!errors.depositInterest}
									>
									{security_deposit_interest.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.depositInterest?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							   <Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Data di consegna
										</Form.Label>
										<Form.Control
											{...register("warrantyDeliveryDate")}
											type="date"
											name="warrantyDeliveryDate"
											value={props.formData.warrantyDeliveryDate}
											onChange={props.onChange}
											className
											isInvalid={!!errors.warrantyDeliveryDate}
										>
										</Form.Control>
										<Form.Control.Feedback type="invalid" >
										{errors.warrantyDeliveryDate?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantyInPrivateDocument")}
										name="warrantyInPrivateDocument"
										checked={props.formData.warrantyInPrivateDocument}
										onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
								</Form.Group>
                			</Col>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo garantito in €
							</Form.Label>
							<Form.Control 
								{...register("warrantyTotalAmount")}
								type="number"
								name="warrantyTotalAmount"	
								value={props.formData.warrantyTotalAmount}
								onChange={props.onChange}
								isInvalid={!!errors.warrantyTotalAmount}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.warrantyTotalAmount?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantySubjectToVat")}
										name="warrantySubjectToVat"
										checked={props.formData.warrantySubjectToVat}
										onChange={props.onChange}label="Garanzia soggetta ad IVA" />
								</Form.Group>
                			</Col>
							<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
					}
					{ warranty_type_index == 3 &&
						<Row>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo del deposito cauzionale in €
							</Form.Label>
							<Form.Control 
								{...register("warrantyDeposit")}
								type="number"
								name="warrantyDeposit"
								value={props.formData.warrantyDeposit}						
								onChange={props.onChange}
								isInvalid={!!errors.warrantyDeposit}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Metodo di pagamento
									</Form.Label>
									<Form.Select 
										{...register("warrantyPaymentMethod")}
										name="warrantyPaymentMethod"
										value={props.formData.warrantyPaymentMethod}
										onChange={props.onChange}
										isInvalid={!!errors.warrantyPaymentMethod}
									>
									{payment_method.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyPaymentMethod?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("borrowedAmount")}
										name="borrowedAmount"
										checked={props.formData.borrowedAmount}
										onChange={props.onChange}label="Importo prestato da terzi" />
								</Form.Group>
                			</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Interessi sul deposito cauzionale
									</Form.Label>
									<Form.Select 
										{...register("depositInterest")}
										name="depositInterest"
										value={props.formData.depositInterest}
										onChange={props.onChange}
										isInvalid={!!errors.depositInterest}
									>
									{security_deposit_interest.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.depositInterest?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							   <Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Data di consegna
										</Form.Label>
										<Form.Control
											{...register("warrantyDeliveryDate")}
											type="date"
											name="warrantyDeliveryDate"
											value={props.formData.warrantyDeliveryDate}
											onChange={props.onChange}
											className
											isInvalid={!!errors.warrantyDeliveryDate}
										>
										</Form.Control>
										<Form.Control.Feedback type="invalid" >
										{errors.warrantyDeliveryDate?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantyInPrivateDocument")}
										name="warrantyInPrivateDocument"
										checked={props.formData.warrantyInPrivateDocument}
										onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
								</Form.Group>
                			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantySubjectToVat")}
										name="warrantySubjectToVat"
										checked={props.formData.warrantySubjectToVat}
										onChange={props.onChange}label="Garanzia soggetta ad IVA" />
								</Form.Group>
                			</Col>
							<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
					}
					{ warranty_type_index == 4 &&
						<Row>
							<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Importo del deposito cauzionale in €
							</Form.Label>
							<Form.Control 
								{...register("warrantyDeposit")}
								type="number"
								name="warrantyDeposit"
								value={props.formData.warrantyDeposit}						
								onChange={props.onChange}
								isInvalid={!!errors.warrantyDeposit}
								placeholder="Inserisci un numero"
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Metodo di pagamento
									</Form.Label>
									<Form.Select 
										{...register("warrantyPaymentMethod")}
										name="warrantyPaymentMethod"
										value={props.formData.warrantyPaymentMethod}
										onChange={props.onChange}
										isInvalid={!!errors.warrantyPaymentMethod}
									>
									{payment_method.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.warrantyPaymentMethod?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("borrowedAmount")}
										name="borrowedAmount"
										checked={props.formData.borrowedAmount}
										onChange={props.onChange}label="Importo prestato da terzi" />
								</Form.Group>
                			</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
									>
									Interessi sul deposito cauzionale
									</Form.Label>
									<Form.Select 
										{...register("depositInterest")}
										name="depositInterest"
										value={props.formData.depositInterest}
										onChange={props.onChange}
										isInvalid={!!errors.depositInterest}
									>
									{security_deposit_interest.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
									{errors.depositInterest?.message}
									</Form.Control.Feedback>
								</Form.Group>
                   			</Col>
							   <Col md={6}>
									<Form.Group className="mb-3" >
										<Form.Label 
											className="f-label fs-16 d-flex align-items-center
mb-2"
										>
											Data di consegna
										</Form.Label>
										<Form.Control
											{...register("warrantyDeliveryDate")}
											type="date"
											name="warrantyDeliveryDate"
											value={props.formData.warrantyDeliveryDate}
											onChange={props.onChange}
											className
											isInvalid={!!errors.warrantyDeliveryDate}
										>
										</Form.Control>
										<Form.Control.Feedback type="invalid" >
										{errors.warrantyDeliveryDate?.message}
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantyInPrivateDocument")}
										name="warrantyInPrivateDocument"
										checked={props.formData.warrantyInPrivateDocument}
										onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
								</Form.Group>
                			</Col>
							<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("warrantySubjectToVat")}
										name="warrantySubjectToVat"
										checked={props.formData.warrantySubjectToVat}
										onChange={props.onChange}label="Garanzia soggetta ad IVA" />
								</Form.Group>
                			</Col>
							<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
					}
					{ warranty_type_index == 5 &&
						<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantyInPrivateDocument")}
									name="warrantyInPrivateDocument"
									checked={props.formData.warrantyInPrivateDocument}
									onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
							</Form.Group>
						</Col>
						<Col md={6}>
					<Form.Group className="mb-3" >
						<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
						>
							Importo totale fideiussioni
						</Form.Label>
						<Form.Control 
							{...register("warrantyTotalAmount")}
							type="number"
							name="warrantyTotalAmount"
							value={props.formData.warrantyTotalAmount}	
							onChange={props.onChange}
							isInvalid={!!errors.warrantyTotalAmount}
							placeholder="Inserisci un numero"
						/>
						<Form.Control.Feedback type="invalid" >
						{errors.warrantyTotalAmount?.message}
						</Form.Control.Feedback>
					</Form.Group>
						</Col>
						<div className="mb-2">
							<span
								className="link-text"
								onClick={(e) => {
									setSuretyModalShow(true);
								}}>
								Elenco fideiussioni
							</span>
						</div>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantySubjectToVat")}
									name="warrantySubjectToVat"
									checked={props.formData.warrantySubjectToVat}
									onChange={props.onChange}label="Garanzia soggetta ad IVA" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
					}
					{ warranty_type_index == 6 &&
						<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantyInPrivateDocument")}
									name="warrantyInPrivateDocument"
									checked={props.formData.warrantyInPrivateDocument}
									onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
							</Form.Group>
						</Col>
						<Col md={6}>
					<Form.Group className="mb-3" >
						<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
						>
							Importo garantito
						</Form.Label>
						<Form.Control 
							{...register("warrantyTotalAmount")}
							type="number"
							name="warrantyTotalAmount"
							value={props.formData.warrantyTotalAmount}	
							onChange={props.onChange}
							isInvalid={!!errors.warrantyTotalAmount}
							placeholder="Inserisci un numero"
						/>
						<Form.Control.Feedback type="invalid" >
						{errors.warrantyTotalAmount?.message}
						</Form.Control.Feedback>
					</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantySubjectToVat")}
									name="warrantySubjectToVat"
									checked={props.formData.warrantySubjectToVat}
									onChange={props.onChange}label="Garanzia soggetta ad IVA" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
					}
					{warranty_type_index == 7 && (
						<>
						<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantyInPrivateDocument")}
									name="warrantyInPrivateDocument"
									checked={props.formData.warrantyInPrivateDocument}
									onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
							</Form.Group>
						</Col>
						<Col md={6}>
					<Form.Group className="mb-3" >
						<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
						>
							Importo garantito
						</Form.Label>
						<Form.Control 
							{...register("warrantyTotalAmount")}
							type="number"
							name="warrantyTotalAmount"
							value={props.formData.warrantyTotalAmount}	
							onChange={props.onChange}
							isInvalid={!!errors.warrantyTotalAmount}
							placeholder="Inserisci un numero"
						/>
						<Form.Control.Feedback type="invalid" >
						{errors.warrantyTotalAmount?.message}
						</Form.Control.Feedback>
					</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantySubjectToVat")}
									name="warrantySubjectToVat"
									checked={props.formData.warrantySubjectToVat}
									onChange={props.onChange}label="Garanzia soggetta ad IVA" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
						</>
					)}
					{warranty_type_index == 8 && (
						<>
						<Row>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantyInPrivateDocument")}
									name="warrantyInPrivateDocument"
									checked={props.formData.warrantyInPrivateDocument}
									onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantySubjectToVat")}
									name="warrantySubjectToVat"
									checked={props.formData.warrantySubjectToVat}
									onChange={props.onChange}label="Garanzia soggetta ad IVA" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
						</>
					)}
					{warranty_type_index == 9 && (
						<>
						<Row>
						<Col md={6}>
					<Form.Group className="mb-3" >
						<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
						>
							Importo depositato
						</Form.Label>
						<Form.Control 
							{...register("warrantyTotalAmount")}
							type="number"
							name="warrantyTotalAmount"
							value={props.formData.warrantyTotalAmount}	
							onChange={props.onChange}
							isInvalid={!!errors.warrantyTotalAmount}
							placeholder="Inserisci un numero"
						/>
						<Form.Control.Feedback type="invalid" >
						{errors.warrantyTotalAmount?.message}
						</Form.Control.Feedback>
					</Form.Group>
						</Col>
						<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("borrowedAmount")}
										name="borrowedAmount"
										checked={props.formData.borrowedAmount}
										onChange={props.onChange}label="Importo prestato da terzi" />
								</Form.Group>
                		</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("generateIndependentFee")}
									name="generateIndependentFee"
									checked={props.formData.generateIndependentFee}
									onChange={props.onChange}
									 label="Genera una rata separata per il deposito cauzionale" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantySubjectToVat")}
									name="warrantySubjectToVat"
									checked={props.formData.warrantySubjectToVat}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
						</>
					)}
					{warranty_type_index == 11 && (
						<>
						<Row>
						<Col md={6}>
					<Form.Group className="mb-3" >
						<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
						>
							Importo dei diritti soggetti all’aiuto
						</Form.Label>
						<Form.Control 
							{...register("warrantyTotalAmount")}
							type="number"
							name="warrantyTotalAmount"
							value={props.formData.warrantyTotalAmount}	
							onChange={props.onChange}
							isInvalid={!!errors.warrantyTotalAmount}
							placeholder="Inserisci un numero"
						/>
						<Form.Control.Feedback type="invalid" >
						{errors.warrantyTotalAmount?.message}
						</Form.Control.Feedback>
					</Form.Group>
						</Col>
						<Col md={12}>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox"
										{...register("borrowedAmount")}
										name="borrowedAmount"
										checked={props.formData.borrowedAmount}
										onChange={props.onChange}label="Importo prestato da terzi" />
								</Form.Group>
                		</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantyInPrivateDocument")}
									name="warrantyInPrivateDocument"
									checked={props.formData.warrantyInPrivateDocument}
									onChange={props.onChange}label="Garanzia terzi presente in una scrittura privata" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("warrantySubjectToVat")}
									name="warrantySubjectToVat"
									checked={props.formData.warrantySubjectToVat}
									onChange={props.onChange}label="Garanzia soggetta ad IVA" />
							</Form.Group>
						</Col>
						<Col md={12}>
							<Form.Group className="mb-3" controlId="formBasicCheckbox">
								<Form.Check type="checkbox"
									{...register("voluntaryPenaltyClause")}
									name="voluntaryPenaltyClause"
									checked={props.formData.voluntaryPenaltyClause}
									onChange={props.onChange}label="Clausola pena volontaria" />
							</Form.Group>
						</Col>
						</Row>
						</>
					)}
                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="6" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="6" activeKey={activeKey}>Pagamento</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Canone su base
                        </Form.Label>
                        <Form.Select 
							{...register("feePeriod")}
							name="feePeriod"
							value={props.formData.feePeriod}
							onChange={props.onChange}
							isInvalid={!!errors.feePeriod}
                        >
                        {base_fee.map((menu, index) => {
							return (
								<option key={index} value={menu}>
									{menu}
								</option>
							);
						})}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.base_fee?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Cadenza delle rate
                        </Form.Label>
                        <Form.Select 
							{...register("feeFrequency")}
							name="feeFrequency"
							value={props.formData.feeFrequency}
							onChange={props.onChange}
							isInvalid={!!errors.feeFrequency}
                        >
                        {frequency.map((menu, index) => {
							return (
								<option key={index} value={index}>
									{menu}
								</option>
							);
						})}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.feeFrequency?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                </Row>
				<Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Modalità di pagamento preferita
                        </Form.Label>
                        <Form.Select 
							{...register("favoritePaymentMethod")}
							name="favoritePaymentMethod"
									value={props.formData.favoritePaymentMethod}
									onChange={(e) => {
										props.onChange(e);
										setPrefferedPaymentMethodId(e.target.selectedIndex);
									}}
							isInvalid={!!errors.favoritePaymentMethod}
                        >
                      {preffered_payment_method.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.favoritePaymentMethod?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
					<Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Tipo di notifica dei pagamenti al locatore
                        </Form.Label>
                        <Form.Select 
							{...register("notificationType")}
							name="notificationType"
									value={props.formData.notificationType}
									onChange={props.onChange}
							isInvalid={!!errors.notificationType}
                        >
                      {notification_type.map((menu, index) => {
										return (
											<option key={index} value={index}>
												{menu}
											</option>
										);
									})}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.notificationType?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
				</Row>

							<div className="col-md-6">
								<div className="d-flex flex-row align-items-end">
									<div className="d-flex flex-column flex-auto">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-1">
											Sul conto
										</label>
										<select
											name="bankAccount"
											value={JSON.stringify(props.formData.bankAccount)}
											onChange={handleAccount}
											>
											<option></option>
											{contiArr.length> 0 && contiArr.map((el, index) => {
												return (
												<option key={index} value={JSON.stringify(el)}>
													{el.name}
												</option>
												);
											})}
											</select>
									</div>
									{/* <BsPlusCircleFill
										className="add-section-btn mb-1 ms-2"
										onClick={() => {
											setAccountModalShow(true);
										}}
									/> */}
								</div>
							</div>
						{/* {(preffered_payment_method_id == 7 ||
						preffered_payment_method_id == 8 ||
						preffered_payment_method_id == 9) && (
						<div className="row my-3">
							<div className="col-md-6">
								<div className="d-flex flex-row align-items-end">
									<div className="d-flex flex-column flex-auto">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Sul conto
										</label>
										<select
											name="a"
											value={props.formData.a}
											onChange={props.onChange}
											>
											{account.map((menu, index) => {
												return (
													<option key={index} value={index}>
														{menu}
													</option>
												);
											})}
										</select>
									</div>
								 	<BsPlusCircleFill
										className="add-section-btn mb-1 ms-2"
										onClick={() => {
											setAccountModalShow(true);
										}}
									/> 
								</div>
							</div>
							{preffered_payment_method_id == 9 && (
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Codice cliente debitore
								</label>
								<input
									name="debtorClientCode"
									value={props.formData.debtorClientCode}
									onChange={props.onChange}
									type="text"

								/>
							</div>
						</div>
					)}
						</div>
						)} */}
                </Accordion.Body>
            </Accordion.Item>
			
			<Accordion.Item eventKey="7" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="7" activeKey={activeKey}>Importi <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
<OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className='tooltipContabilita'>Utilizziamo il dato relativo al canone per calcolare le imposte di registro dovute</Tooltip>
								}
								>
								<i class="bi bi-info-circle ms-2"></i>
							</OverlayTrigger></CustomToggle>
                </Card.Header>
                <Accordion.Body>
				<Row>
				<Col md={12}>
							<Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox" >
								<Form.Check type="checkbox"
			
									checked={props.generateRate}
									onChange={() => {
										props.setGenerateRate(!props.generateRate)
									}}label="Crea rate relative a questo affitto" />
									<OverlayTrigger
									placement="top"
									delay={{ show: 600, hide: 300 }}
									overlay={
										<Tooltip className='tooltipContabilita'>Creeremo le rate per canone e oneri.</Tooltip>
										}
										>
										<i class="bi bi-info-circle ms-2"></i>
									</OverlayTrigger>
							</Form.Group>
						</Col>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Canone attuale (mensile) <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

							</Form.Label>
							<Form.Control 
								{...register("currentMonthlyFee")}
								type="number"
								name="currentMonthlyFee"
								value={props.formData.currentMonthlyFee}	onChange={props.onChange}
								isInvalid={!!errors.currentMonthlyFee}

							/>
							<Form.Control.Feedback type="invalid" >
							{errors.cancellationRenewalForOwner?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>  
					{/* BTN + TABELLA QUI IN VIEW */}                  
                </Row>
                <Row>              
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Spese forfettarie attuali (mensile)
							</Form.Label>
							<Form.Control 
								{...register("currentMonthlyRateExpenses")}
								type="number"
								name="currentMonthlyRateExpenses"
								value={props.formData.currentMonthlyRateExpenses}onChange={props.onChange}
								isInvalid={!!errors.currentMonthlyRateExpenses}
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.currentMonthlyRateExpenses?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
								className="f-label fs-16 d-flex align-items-center
mb-2"
							>
								Anticipo oneri accessori (mensile) {/* <OverlayTrigger
							placement="top"
							delay={{ show: 600, hide: 300 }}
							overlay={
								<Tooltip className='tooltipContabilita'>Spiegazione</Tooltip>
								}
								>
								<i class="bi bi-info-circle ms-2"></i>
							</OverlayTrigger> */}
							</Form.Label>
							<Form.Control 
								{...register("advanceCharges")}
								type="number"
								name="advanceCharges"
								value={props.formData.advanceCharges}
								onChange={props.onChange}
								isInvalid={!!errors.advanceCharges}
							/>
							<Form.Control.Feedback type="invalid" >
							{errors.advanceCharges?.message}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
                </Row>
				<Row>
                    <Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
							>
							Gestione lettura contatori
							</Form.Label>
							<Form.Select 
								{...register("readCounterManagement")}
								name="readCounterManagement"
								value={props.formData.readCounterManagement}
								onChange={props.onChange}
								isInvalid={!!errors.managementType}
							>
							{meter_management.map((menu, index) => {
								return (
									<option key={index} value={index}>
										{menu}
									</option>
								);
							})}
							</Form.Select>
							<Form.Control.Feedback type="invalid" >
							{errors.readCounterManagement?.message}
							</Form.Control.Feedback>
						</Form.Group>
                    </Col>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                        Note relative ai contatori
                        </Form.Label>
                        <Form.Control
						{...register("counterNote")}
						type="text"
						name="counterNote"
						value={props.formData.counterNote}
						onChange={props.onChange}
						isInvalid={!!errors.counterNote}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.counterNote?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>
				<Row>
					<Col md={12}>
						<Form.Group className="mb-3" controlId="formBasicCheckbox">
							<Form.Check type="checkbox"
								{...register("haveAdjustmentOnTurnover")}
								name="haveAdjustmentOnTurnover"
								checked={props.formData.haveAdjustmentOnTurnover}
								onChange={props.onChange} 
								label="Conguaglio su fatturato conduttore" />
						</Form.Group>
					</Col>
                </Row>
				{props.formData.haveAdjustmentOnTurnover &&
					<Row>
				<Col md={6}>
					<Form.Group className="mb-3" >
						<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
						>
							Soglia di fatturato
						</Form.Label>
						<Form.Control 
							{...register("turnoverThreshold")}
							type="number"
							name="turnoverThreshold"
							value={props.formData.turnoverThreshold}
							onChange={props.onChange}
							isInvalid={!!errors.turnoverThreshold}
						/>
						<Form.Control.Feedback type="invalid" >
						{errors.turnoverThreshold?.message}
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
					</Row>
				}			
                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="8" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="8" activeKey={activeKey}>Fatturazione</CustomToggle>
                </Card.Header>
                <Accordion.Body>
				<Row>
					<Col md={12}>
						<Form.Group className="mb-3" controlId="formBasicCheckbox">
							<Form.Check type="checkbox"
								{...register("subjectToInvoice")}
								name="subjectToInvoice"
								checked={props.formData.subjectToInvoice}
								onChange={props.onChange}
								label="Soggetto a fattura" />
						</Form.Group>
					</Col>
                </Row>
				{ props.formData.subjectToInvoice &&
				<Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                        className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                       	IVA canone
                        </Form.Label>
                        <Form.Select 
							{...register("vatCanon")}
							name="vatCanon"
							value={props.formData.vatCanon}
							onChange={props.onChange}
							isInvalid={!!errors.vatCanon}
                        >
                           <option value={1}> Esente </option>
                                                <option value={1.04}> 4% </option>
                                                <option value={1.05}> 5% </option>
                                                <option value={1.1}> 10% </option>
                                                <option value={1.2}> 20% </option>
                                                <option value={1.21}> 21% </option>
                                                <option value={1.22}> 22% </option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                        {errors.vatCanon?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    </Col>
                    
                </Row>}
                </Accordion.Body>
            </Accordion.Item>

			<Accordion.Item eventKey="9" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="9" activeKey={activeKey}>Aggiornamento ISTAT</CustomToggle>
                </Card.Header>
                <Accordion.Body>
				<Row>
                    <Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
							>
						Aggiornamento a partire dal
							</Form.Label>
							<Form.Select 
								{...register("istatUpdateFrom")}
								name="istatUpdateFrom"
								value={props.formData.istatUpdateFrom}
								onChange={props.onChange}
								isInvalid={!!errors.istatUpdateFrom}
							>
							{update.map((menu, index) => {
								return (
									<option key={index} value={menu}>
										{menu}
									</option>
								);
							})}
							</Form.Select>
							<Form.Control.Feedback type="invalid" >
							{errors.istatUpdateFrom?.message}
							</Form.Control.Feedback>
						</Form.Group>
                    </Col>
					<Col md={6}>
						<Form.Group className="mb-3" >
							<Form.Label 
							className="f-label fs-16 d-flex align-items-center
mb-2"
							>
						Percentuale ISTAT
							</Form.Label>
							<Form.Select 
								{...register("istat")}
								name="istat"
								value={props.formData.istat}
								onChange={props.onChange}
								isInvalid={!!errors.istat}
							>
							{istat.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
									})}
							</Form.Select>
							<Form.Control.Feedback type="invalid" >
							{errors.istat?.message}
							</Form.Control.Feedback>
						</Form.Group>
                    </Col>
                    
                </Row>
                </Accordion.Body>
            </Accordion.Item>


        </Accordion>

        </div>
		
		<div className="d-flex justify-content-end mt-5">
				<ButtonRm
				variant="tertiary"
				onClick={() => props.backward()}
				>
				Indietro
				</ButtonRm>

				<ButtonRm
					variant="primary"
					className="ms-2"
					type="submit"
				>
					Avanti
				</ButtonRm>
			  </div>
    	</Form>
			<Modal
				show={surety_modal_show}
				fullscreen={true}
				className="right-full-pop"
				onHide={() => setSuretyModalShow(false)}>
				<Modal.Body className="p-4">
					<Button
						variant=""
						onClick={(e) => setSuretyModalShow(false)}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<SuretyModal />
				</Modal.Body>
			</Modal>
			<Modal
				show={account_modal_show}
				fullscreen={true}
				className="right-full-pop"
				onHide={() => setAccountModalShow(false)}>
				<Modal.Body className="p-4">
					<Button
						variant=""
						onClick={(e) => setAccountModalShow(false)}
						className="float-end border-0 bg-white">
						<i class="bi bi-x"></i>
					</Button>
					<AccountModal setShow={setAccountModalShow} />
				</Modal.Body>
			</Modal>
		</div>
	);
}
