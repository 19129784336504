import axios from "axios";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setShow } from "../../../../actions/ModalForVerify";
import {
  getMunicipality,
  getProvince,
} from "../../../../actions/addressAction";
import "../../../../assets/css/Tabs.css";
import ModalForVerify from "../ModalForVerify";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

import ButtonRm from "../../../ButtonRm"

const Tabs = ({ province, municipality, getProvince, getMunicipality }) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState(false);

  /*STEP 1*/
  const [emailConfError, setEmailConfError] = useState(false);
  const [passConfError, setPassConfError] = useState(false);

  const [ready, setReady] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    name: "",
    surname: "",
    fiscalCode: "",
    sex: "M",
    birthday: "",
    address: "",
    houseNumber: "",
    province: "",
    municipality: "",
    cap: "",
    documentType: "",
    issueEntity: "",
    issueDate: "",
    documentNumber: "",
    issuePlace: "",
    documentFile: "",
    faceFile: "",
    role: "",
    consentDateUpdate: "",
    privacy: false,
    cookie: false,
    termsConditions: false,
    source: "",
    browserInformation:"",
    formCode:"",
    privacyInformation: ""
  });


  console.log(formData, "formData")

  const forward = () => {
    if (
      formData.email &&
      formData.email === formData.emailConfirmation &&
      formData.password &&
      formData.password === formData.passwordConfirmation // TODO check validation
    ) {
      setStep(step + 1);
    }
  };

  const backward = () => {
    setStep(step - 1);
  };

  const confirm = () => {
    var request = {
      email: formData.email,
      password: formData.password,
      name: formData.name,
    };
   
    axios({
      url: process.env.REACT_APP_REQUEST_URL + "api/v1/auth/signup",
      method: "POST",
      data: request,
    })
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          toast.success("I dati di registrazione inseriti sono corretti. Procedi con l'inserimento dell'OTP ", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: false,
          });
          setReady(true);
          dispatch(setShow(true));
        } 
      })
      .catch((err) => {
        console.debug(err + ": " + process.env.REACT_APP_REQUEST_URL + "api/v1/auth/signup");
        console.log(err)
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
        });
      });
  };

  const returnLogin = () => {
    navigate(-1);
  };

  const tab1Click = () => {
    setStep(1);
  };

  const tab2Click = () => {
    if(formData.username 
      && formData.email
      && formData.phone
      && formData.password
      && !emailConfError
      && !passConfError
      ){
      setStep(2);
    }
  };

  const tab3Click = () => {
    if(formData.name && 
      formData.surname && 
      formData.sex && 
      formData.birthday && 
      formData.address && 
      formData.province &&
      formData.municipality &&
      formData.cap &&
      formData.fiscalCode){
      setStep(3)
    }
  };

  const tab4Click = () => {
    if(formData.documentFile && 
      formData.documentNumber &&
       formData.documentType &&
       formData.issueDate &&
        formData.issuePlace &&
        formData.issueEntity
        ){
      setStep(4);
    }
  };
  const tab5Click = () => {
    if(formData.privacy &&
       formData.termsConditions
        && formData.cookie){
      setStep(5);
    }
  };
  const tab6Click = () => {
    if(formData.faceFile){
      setStep(6);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="tabs-div">
        <div className="tab-icon" onClick={() => tab1Click()}>
          <div className="desktop-icon">
            <svg style={{ width: "170px", height: "58px" }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 5C2.5 2.79086 4.29086 1 6.5 1H149.179C150.614 1 151.94 1.76914 152.652 3.01544L166.366 27.0154C167.069 28.2452 167.069 29.7548 166.366 30.9846L152.652 54.9846C151.94 56.2309 150.614 57 149.179 57H6.5C4.29086 57 2.5 55.2091 2.5 53V5Z"
                fill={step > 0 ? (step > 1 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={step > 0 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className={"tab-icon-title1"}
              style={{
                color:
                  step > 0 ? (step > 1 ? "#FFF8F8" : "#83112F") : "#3C4257",
              }}
            >
              Registrazione
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: step === 1 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={step > 0 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: step === 1 ? "" : "none",
                position: "absolute",
                left: "-15px",
              }}
            >
              Registrazione
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab2Click()}>
          <div className="desktop-icon">
            <svg className="tab-icon-background">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M143.652 3.01544C142.939 1.76914 141.614 1 140.179 1H6.39263C3.32134 1 1.39587 4.31793 2.91966 6.98456L14.3659 27.0154C15.0686 28.2452 15.0686 29.7548 14.3659 30.9846L2.91966 51.0154C1.39587 53.6821 3.32133 57 6.39262 57H140.179C141.614 57 142.939 56.2309 143.652 54.9846L157.366 30.9846C158.069 29.7548 158.069 28.2452 157.366 27.0154L143.652 3.01544Z"
                fill={step > 1 ? (step > 2 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={step > 1 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className={"tab-icon-title2"}
              style={{
                color:
                  step > 1 ? (step > 2 ? "#FFF8F8" : "#83112F") : "#3C4257",
              }}
            >
              Dati personali
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: step === 2 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={step > 1 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: step === 2 ? "" : "none",
                position: "absolute",
                left: "-16px",
              }}
            >
              Dati personali
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab3Click()}>
          <div className="desktop-icon">
            <svg className="tab-icon-background">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M143.652 3.01544C142.939 1.76914 141.614 1 140.179 1H6.39263C3.32134 1 1.39587 4.31793 2.91966 6.98456L14.3659 27.0154C15.0686 28.2452 15.0686 29.7548 14.3659 30.9846L2.91966 51.0154C1.39587 53.6821 3.32133 57 6.39262 57H140.179C141.614 57 142.939 56.2309 143.652 54.9846L157.366 30.9846C158.069 29.7548 158.069 28.2452 157.366 27.0154L143.652 3.01544Z"
                fill={step > 2 ? (step > 3 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={step > 2 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className={"tab-icon-title3"}
              style={{
                color:
                  step > 2 ? (step > 3 ? "#FFF8F8" : "#83112F") : "#3C4257",
              }}
            >
              Documenti
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: step === 3 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={step > 2 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: step === 3 ? "" : "none",
                position: "absolute",
                left: "-7px",
              }}
            >
              Documenti
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab4Click()}>
          <div className="desktop-icon">
            <svg style={{ width: "243px", height: "58px" }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M227.652 3.01544C226.939 1.76914 225.614 1 224.179 1H6.39263C3.32134 1 1.39587 4.31793 2.91966 6.98456L14.3659 27.0154C15.0686 28.2452 15.0686 29.7548 14.3659 30.9846L2.91966 51.0154C1.39587 53.6821 3.32134 57 6.39263 57H224.179C225.614 57 226.939 56.2309 227.652 54.9846L241.366 30.9846C242.069 29.7548 242.069 28.2452 241.366 27.0154L227.652 3.01544Z"
                fill={step > 3 ? (step > 4 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={step > 3 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className="tab-icon-title4"
              style={{
                color:
                  step > 3 ? (step > 4 ? "#FFF8F8" : "#83112F") : "#3C4257",
              }}
            >
              Statuto, foro e Consensi
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: step === 4 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={step > 3 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: step === 4 ? "" : "none",
                position: "absolute",
                left: "-32px",
              }}
            >
              Statuto, foro e Consensi
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab5Click()}>
          <div className="desktop-icon">
            <svg className="tab-icon-background">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M143.652 3.01544C142.939 1.76914 141.614 1 140.179 1H6.39263C3.32134 1 1.39587 4.31793 2.91966 6.98456L14.3659 27.0154C15.0686 28.2452 15.0686 29.7548 14.3659 30.9846L2.91966 51.0154C1.39587 53.6821 3.32133 57 6.39262 57H140.179C141.614 57 142.939 56.2309 143.652 54.9846L157.366 30.9846C158.069 29.7548 158.069 28.2452 157.366 27.0154L143.652 3.01544Z"
                fill={step > 4 ? (step > 5 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={step > 4 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className={"tab-icon-title5"}
              style={{
                color:
                  step > 4 ? (step > 5 ? "#FFF8F8" : "#83112F") : "#3C4257",
              }}
            >
              Face ID
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: step === 5 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={step > 4 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: step === 5 ? "" : "none",
                position: "absolute",
              }}
            >
              Face ID
            </div>
          </div>
        </div>
        <div className="tab-icon" onClick={() => tab6Click()}>
          <div className="desktop-icon">
            <svg style={{ width: "125px", height: "58px" }}>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.91966 6.98456C1.39587 4.31793 3.32134 1 6.39263 1H120.5C122.709 1 124.5 2.79086 124.5 5V53C124.5 55.2091 122.709 57 120.5 57H6.39263C3.32134 57 1.39587 53.6821 2.91966 51.0154L14.3659 30.9846C15.0686 29.7548 15.0686 28.2452 14.3659 27.0154L2.91966 6.98456Z"
                fill={step > 5 ? (step > 6 ? "#83112F" : "#FFF8F8") : "#fff"}
                stroke={step > 5 ? "#83112F" : "#C1C9D2"}
              />
            </svg>
            <div
              className="tab-icon-title6"
              style={{
                color:
                  step > 5 ? (step > 6 ? "#FFF8F8" : "#83112F") : "#3C4257",
              }}
            >
              Riepilogo
            </div>
          </div>
          <div
            className="mobile-icon"
            style={{ position: "relative", width: "40px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <svg height="20" width="20">
                <ellipse
                  cx="10"
                  cy="10"
                  rx="10"
                  ry="10"
                  fill="#F1E2E2CF"
                  style={{ margin: "auto", display: step === 6 ? "" : "none" }}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
              <svg
                height="10"
                width="10"
                style={{
                  position: "absolute",
                }}
              >
                <ellipse
                  cx="5"
                  cy="5"
                  rx="5"
                  ry="5"
                  fill={step > 5 ? "#83112F" : "#E0E0E0"}
                />
                Sorry, your browser does not support inline SVG.
              </svg>
            </div>
            <div
              className="tab1-mobile-title"
              style={{
                display: step === 6 ? "" : "none",
                position: "absolute",
                left: "-3px",
              }}
            >
              Riepilogo
            </div>
          </div>
        </div>
      </div>
      <Step1
        formData={formData}
        setFormData={setFormData}
        isActive={step === 1 ? true : false}
        selecttab1={() => tab1Click()}
        setConfirmed={(v) => setConfirmed(v)}
        emailConfError={emailConfError}
        setEmailConfError={setEmailConfError}
        passConfError={passConfError}
        setPassConfError={setPassConfError}
      />
      <Step2
        formData={formData}
        setFormData={setFormData}
        isActive={step === 2 ? true : false}
        getProvince={getProvince}
        getMunicipality={getMunicipality}
        province={province}
        municipality={municipality}
      />
      <Step3
        formData={formData}
        setFormData={setFormData}
        isActive={step === 3 ? true : false}
      />
      <Step4
        formData={formData}
        setFormData={setFormData}
        isActive={step === 4 ? true : false}
      />
      <Step5
        formData={formData}
        setFormData={setFormData}
        isActive={step === 5 ? true : false}
      />

      <Step6
        formData={formData}
        setFormData={setFormData}
        isActive={step === 6 ? true : false}
        edit1={() => tab1Click()}
        edit2={() => tab2Click()}
        edit3={() => tab3Click()}
        edit5={() => tab5Click()}
      />
      
      <div
        className="d-flex tabs-btn"
        style={{ justifyContent: "center", marginTop: "5%" }}
      >
        {step === 1 ? (
          <ButtonRm variant='tertiary'onClick={() => returnLogin()} className="me-3">Annulla</ButtonRm>
        ) : (
          <ButtonRm variant='tertiary' onClick={() => backward()} className="me-3">Indietro</ButtonRm>
        )}
        {step === 6 ? (
          <ButtonRm variant='primary'onClick={() => confirm()}>Conferma</ButtonRm>
        ) : 
        
          ((() => {
            if (step === 1) {
              return <ButtonRm variant='primary' onClick={() => forward()} disabled={!(formData.username && formData.email && formData.password && formData.phone && !emailConfError
                && !passConfError)}>Avanti</ButtonRm>;
            }
            if (step === 2) {
              return <ButtonRm variant='primary' onClick={() => forward()} disabled={!(formData.name && 
                formData.surname && 
                formData.sex && 
                formData.birthday && 
                formData.address && 
                formData.province &&
                formData.municipality &&
                formData.cap &&
                formData.fiscalCode)}>Avanti</ButtonRm>;
            }
            if (step === 3) {
              return <ButtonRm variant='primary' onClick={() => forward()}  disabled={(formData.documentFile && formData.documentNumber && formData.documentType && formData.issueDate && formData.issuePlace && formData.issueEntity) ? false : true} >Avanti</ButtonRm>
            }
            if (step === 4) {
              return <ButtonRm variant='primary' onClick={() => forward()} disabled={(formData.privacy && formData.termsConditions && formData.cookie) ? false : true}>Avanti</ButtonRm>
            }
            if (step === 5) {
              return  <ButtonRm variant='primary' onClick={() => forward()} disabled={(formData.faceFile) ? false : true} >Avanti</ButtonRm>
            }
            // ... altre condizioni
          })()
        
        
          
        )}
      </div>
      {ready && <ModalForVerify singupInfo={formData} role="1" />}
    </>
  );
};

const mapStateToProps = (state) => ({
  show: state.ModalForVerify.show,
  province: state.addressReducer.province,
  municipality: state.addressReducer.municipality,
});

export default connect(mapStateToProps, {
  setShow,
  getProvince,
  getMunicipality,
})(Tabs);
