import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import arrowGest from '../../assets/images/gestionali/arrow-gest.png'
import misura1icon from '../../assets/images/gestionali/misura-1-icon.png'
import misura2icon from '../../assets/images/gestionali/misura-2-icon.png'
import misura3icon from '../../assets/images/gestionali/misura-3-icon.png'
import misura4icon from '../../assets/images/gestionali/misura-4-icon.png'
import misura5icon from '../../assets/images/gestionali/misura-5-icon.png'

import "../../assets/css/Gestionali.css"


export default function Gestionali (){
    return(
        <Row>

    <div className=" mb-3  p-5 px-4 p-md-0 py-0">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-8 order-2 order-md-1 text-center">
                    <img src={arrowGest} width="100%" />
                </div>
            </div>
    </div>

    <div className=" mb-3  p-5 px-4 p-md-0">
        <div className="container">
            <div className="row justify-content-between justify-content-lg-center align-items-center py-md-5">
                <div className="col-md-5 order-2 order-md-1 text-center position-relative">
                    <div className="misura-img misura-img-1">
                    </div>
                    <img src={misura1icon} width="100px" height="auto" className="misura-img-icon-sn" />
                </div>
                <div className="col-md-5 order-1 order-md-2 d-flex flex-column justify-content-center align-items-start gestionali-col">
                    <h2>1. Analisi funzionale</h2>
                    <p className="fit-content">Fase analitica iniziale ed avvio</p>
                    <p>
                        
                        Durante questa prima fase raccogliamo i requisiti necessari per lo sviluppo del progetto.
                        Dopo una valutazione iniziale, indicheremo tempistiche previste per l’implementazione della tua soluzione. Nello specifico della fase:
                        <ul>
                            <li><strong>Esplicitazione ed Analisi dei Requisiti</strong> </li>
                            <li><strong>Comprendere la visione del cliente</strong> </li>
                            <li><strong>Identificare le esigenze e le aspettative del cliente per il progetto </strong> </li>
                            <li><strong>Analisi di fattibilità legale e tecnica </strong> </li>
                            <li><strong>Analisi del dominio</strong> </li>
                            <li><strong>Definizione del contesto in cui il software dovrà agire </strong> </li>
                            
                        </ul>
                        </p>
                </div>
            </div>
        </div>
    </div>
    
    <div className=" mb-3  mb-md-0  p-5 px-4 p-md-0">
        <div className="container">
        <div className="row justify-content-between justify-content-lg-center align-items-center py-md-5">
            <div className="col-md-5 order-2 order-md-2  position-relative">
                <div className="misura-img misura-img-2 ms-md-auto">
                </div>
                <img src={misura2icon} width="100px" height="auto" className="misura-img-icon-dx" />
            </div>
            <div className="col-md-5 order-1 order-md-1 d-flex flex-column justify-content-center text-left gestionali-col">
                <h2>2. Progettazione</h2>
                <p className="fit-content">Fase di strategia e concept
                </p>
                <p>
                    
                    Un team di esperti elabora per te una nuova strategia nel rispetto dei processi aziendali preesistenti. Identifica limiti e rischi correlati, creando un concept iniziale per avviare l’implementazione dell’architettura.<br/> Nello specifico della fase: 
                     <ul>
                        <li><strong>Approfondita analisi tecnica e funzionale </strong> </li>
                        <li><strong>Progettazione architetturale (con l’eventuale coinvolgimento dell’IT del cliente):</strong> </li>
                        
                    </ul>
                    </p>
            </div>
        </div>
        </div>
    </div>

    <div class=" mb-3  mb-md-0  p-5 px-4 p-md-0">
            <div class="container">
                <div class="row justify-content-between justify-content-lg-center align-items-center py-md-5">
                    <div class="col-md-5 order-2 order-md-1 text-center position-relative">
                        <div class="misura-img misura-img-3">
                        </div>
                        <img src={misura3icon} width="100px" height="auto" class="misura-img-icon-sn" />
                    </div>
                    <div class="col-md-5 order-1 order-md-2 d-flex flex-column justify-content-center gestionali-col">
                        <h2>3. Sviluppo</h2>
                        <p class="fit-content">Fase di Design e sviluppo
                        </p>
                        <p>
                            
                            Una volta concordati i dettagli, creiamo un team dedicato per il tuo specifico progetto. Potrai tracciare il processo di sviluppo del software in qualsiasi momento. <br/>
                            Nello specifico della fase: 
                             <ul>
                                <li><strong>Produzione del software  </strong> </li>
                                <li><strong>Unit, integration, end-to-end/API, carico e performance test con accettazione da parte dell’utente </strong> </li>
                                    <li><strong>Ispezione e debugging </strong> </li>
                                        <li><strong>Pentest e assessment test </strong> </li>
                                
                            </ul>
                            </p>
                    </div>
                </div>
            </div>
    </div>

    <div className=" mb-3  mb-md-0  p-5 px-4 p-md-0">
        <div className="container">
            <div className="row justify-content-between justify-content-lg-center align-items-center py-md-5">
                <div className="col-md-5 order-2 order-md-2  position-relative">
                    <div className="misura-img misura-img-4 ms-md-auto">
                    </div>
                    <img src={misura4icon} width="100px" height="auto" className="misura-img-icon-dx" />
                </div>

                <div className="col-md-5 order-1 order-md-1 d-flex flex-column justify-content-center text-left gestionali-col">
                    <h2>4. Test e validazione</h2>
                    <p className="fit-content">Fase di Verifica
                    </p>
                    <p>Durante questa fase raccogliamo i feedback degli utenti e applichiamo le correzioni per ultimare il prodotto prima del rilascio ufficiale. <br/> Nello specifico della fase: 
                        <ul>
                            <li><strong>Rilascio demo   </strong> </li>
                            <li><strong>Pianificazione dettagliata degli ultimi sviluppi emersi dai feedback (se necessari) </strong> </li>
                                <li><strong>Fase finale di preparazione del roll-out  </strong> </li>
                            
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class=" mb-3  mb-md-0  p-5 px-4 p-md-0">
        <div class="container">
            <div class="row justify-content-between justify-content-lg-center align-items-center py-md-5">
                <div class="col-md-5 order-2 order-md-1 text-center position-relative">
                    <div class="misura-img misura-img-5">
                    </div>
                    <img src={misura5icon} width="100px" height="auto" class="misura-img-icon-sn" />
                </div>
                <div class="col-md-5 order-1 order-md-2 d-flex flex-column justify-content-center">
                    <h2>5. Pubblicazione</h2>
                    <p class="fit-content">Fase di deployment e manutenzione
                    </p>
                    <p>
                        
                        Questa fase comprende il rilascio del software completo e l’avvio della manutenzione e degli aggiornamenti.<br/>Nello specifico della fase: 
                         <ul>
                            <li><strong>Rilascio ufficiale Monitoraggio Post-Go Live 
                            </strong> </li>
                            <li><strong>Monitoraggio rigorosamente supervisionato in una prima fase 
                            </strong> </li>
                                <li><strong>Monitoraggio automatico dopo un mese in assenza di bug Manutenzione 
                                </strong> </li>
                                    <li><strong>Rapida risoluzione dei problemi (con SLA operativi) e bug fixing Aggiornamento 
                                    </strong> </li>
                                    <li><strong>Documentazione evolutiva 

                                    </strong> </li>
                                    <li><strong>Prototipi legati all’evoluzione 

                                    </strong> </li>
                                    <li><strong>Offerta di evoluzione del sistema (può portare ad un nuovo ciclo di implementazione) 

                                    </strong> </li>
                            
                        </ul>
                        </p>
                </div>
            </div>
        </div>
</div>

        </Row>
    )
}