import { useEffect, useState } from "react";

import { Col } from "react-bootstrap"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CustomBadge from "../../../../CustomBadge";

import capitalizeFirstLetter from "../../../../../utils/capitalizeFirstLetter"

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import logoPng from '../../../../../assets/images/logo-red-big.png';

import { Modal, Form, Row } from "react-bootstrap";

import { MultiSelect } from "react-multi-select-component";

import ButtonRm from "../../../../ButtonRm";

import api, { chat_msg_endpoint } from "../../../../../api/api";

import { checkUserData } from "../../../../../utils/checkUserRole";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";





export default function Verbale(props) {

         /* MODAL EDIT => ALSO SHOULD SEND REQUEST TO BACKEND FOR UPDATE */
         const [showV, setShowV] = useState(false);
         const handleCloseV = () => setShowV(false);

        
      //CHECK IF DATA REQUIRED ARE COMPILED
			const [dataRequired, setDataRequired] = useState({
                hasAccountConnect: false,
                isCustomer: false,
                isVerified: false,
                isVerifiedFull: false
              });
                    //Gestisce il modale
                    const [showCheck, setShowCheck] = useState(false)
                
                    useEffect(() => {
                        const fetchData = async () => {
                            try {
                                const response = await checkUserData();
                                setDataRequired(response);
                                if (!response.isVerifiedFull) {
                                  setShowCheck(true);
                              }
                            } catch (error) {
                                // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                                console.error('Errore durante il recupero dei dati utente:', error);
                            }
                        };
                
                        fetchData();
                    }, []);

    return (
        <>
        <div className="my-3" >
            <div className="d-flex align-items-center">
                    <p className="text-capitalize fs-14">Verbale</p>
                    <TableVerbale parsedContent={props.parsedContent}  />
                    <button className="btn round-icon-btn btn ms-1" onClick={() => setShowV(true)}><i class="bi bi-pencil-square"></i></button>
                    
                </div>
            <hr className="my-2"/>
            <div>
               {/*  {props.parsedContentKey.map((el, i) => {
                    return <div key={i} className="d-flex">
                            <Col><p className="text-capitalize fs-14">{el}</p></Col>
                            <Col><p className="fs-14">{props.parsedContent[el]}</p></Col>
                        </div>
                })}
 */}
                <TableContainer style={{ backgroundColor: 'transparent' }} >
                    <Table  aria-label="customized table">
                        <TableBody>
                            {props.parsedContent.length > 0 && props.parsedContent?.map((el, index) => (
                                <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {el.name}
                                </TableCell>
                                <TableCell align="right" style={{maxWidth: "400px"}}>{ el.type == "multiselect" ? el.value.map(valore => capitalizeFirstLetter(valore).trim()).join(", ") : capitalizeFirstLetter(el.value)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        <div className="vision-chksec">
            {/* CASO 1: IO NON HA FLAGGATO CHECKBOX*/}
            {   props.item?.confirmSeen?.length > 0 &&
                props.item?.confirmSeen.map(el => {
                    return el.userID != JSON.parse(localStorage.getItem('user')).id && <div className="msg-chk">
                    <label>
                        <input type="checkbox" value={props.verbalCheckbox} onChange={props.handleVerbalCheckbox}/>
                        <span></span>
                    </label>
                    <p className="fs-14">
                        Confermo di aver presa
                        visione
                    </p>
                </div>
                })                                
            }
             {/* CASO 2: NESSUN UTENTE HA FLAGGATO CHECKBOX*/}
             {   props.item?.confirmSeen?.length == 0 &&
                <div className="msg-chk">
                    <label>
                        <input type="checkbox" value={props.verbalCheckbox} onChange={props.handleVerbalCheckbox}/>
                        <span></span>
                    </label>
                    <p className="fs-14">
                        Confermo di aver presa
                        visione
                    </p>
                </div>                             
            }
            {/* CASO 3: UTENTE IO HA FLAGGATO CHECKBOX E UTENTE  IO NON HA ACCETTATO*/}
            {   props.item?.confirmSeen?.length > 0 && props.item?.confirmContents?.length  == 0 &&
                props.item?.confirmSeen.map(el => {
                    return  el.userID == props.chat.members?.[0]?.id ?
                    <div>
                        <div className="d-flex justify-content-end align-items-center mb-2 text-end">                                              
                            <CustomBadge variant="custom-badge-success"> <p className="fs-12">{capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)} ha confermato la presa visione</p></CustomBadge>
                        </div>
                        <div className="dualbtn-main">

                        {!dataRequired.isVerifiedFull && <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 50, hide: 50 }}
                                                        overlay={
                                                          <Tooltip id="tooltip" className="exclamation-tooltip">
                                                            <p className="mb-3">Per poter accettare o rifiutare un preventivo, è necessario completare le seguenti informazioni:</p>

                                                            <p>1. <strong>Anagrafica Completa:</strong> <br/>Anagrafica / Face ID /Documenti</p>
                                                            <em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>
                                                          </Tooltip>
                                                        }>
                                                        <i class="bi  bi-info-circle me-2 fs-14"></i>
                                                      </OverlayTrigger>
                                                      }

                            <div className="btn-gp">
                                <button className={`btn btn-success fs-12`} onClick={() => props.postChatConfirm(true)} disabled={dataRequired.isVerifiedFull? false : true}>
                                    Accetto
                                </button>
                            </div>
                            <div className="btn-gp">
                                <button className={`btn btn-danger  fs-12`} onClick={() => props.postChatConfirm(false)} disabled={dataRequired.isVerifiedFull? false : true}>
                                    Non Accetto
                                </button>
                            </div>
                        </div>
                    </div> :
                    <div></div>
                })                                
            }
            {/* CASO 4: UTENTE IO HA FLAGGATO CHECKBOX E UTENTE  IO HA ACCETTATO*/}
            {   props.item?.confirmSeen?.length > 0 && props.item?.confirmContents?.length  > 0 &&
                props.item?.confirmSeen.map(el => {
                    return  el.userID == props.chat.members?.[0]?.id ?
                    <div>
                         <div className="d-flex justify-content-end align-items-center mb-2 text-end">                                              
                            <CustomBadge variant="custom-badge-success"> <p className="fs-12">{capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)}  ha confermato la presa visione</p></CustomBadge>
                        </div>
                        <div>
                
                {   props.item?.confirmContents[0].accepted &&
                   <div>
                    <div className="d-flex justify-content-end align-items-center mb-2 text-end">
                    <CustomBadge variant="custom-badge-success"> <p className="fs-12 fw-regular"><i class="bi bi-check"></i> {capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)}  ha accettato il
                        verbale il {props.item?.confirmContents[0].cdat.substring(0,10)}, {props.item?.confirmContents[0].cdat.substring(11,19)}</p></CustomBadge>
                        </div>
                    </div>
                }
                {    !props.item?.confirmContents[0].accepted &&
                    <div>
                        <div className="d-flex justify-content-end align-items-center mb-2 text-end">
                        <CustomBadge variant="custom-badge-danger"> <p className="fs-12 fw-regular"><i class="bi bi-check"></i> {capitalizeFirstLetter(props.chat.members?.[0]?.name)} {capitalizeFirstLetter(props.chat.members?.[0]?.surname)} non ha accettato il
                        verbale il {props.item?.confirmContents[0].cdat.substring(0,10)}, {props.item?.confirmContents[0].cdat.substring(11,19)}</p></CustomBadge>
                        </div>
                    </div>
                }
            </div>
                    </div> :
                    <div></div>
                })                                
            }

                {/* MANCA UI PER CHI NON HA FIRMATO, ma vede chi ha firmato */}
                {/* MANCA UI PER CHI HA FIRMATO, e vede chi altro ha firmato */}
                {/* MANCA UI PER PARTECIPANTI DIVERSI DAL CREATORE DELLA CHAT */}
            
        </div>
        {props.item.signs.length > 0 ?
            <>
                 <div className="text-end">
                    <CustomBadge variant="custom-badge-alert">  
                        <p className="fs-12">
                        <i class="bi bi-vector-pen me-1"></i>
                            <span>Firmato da  {" "}</span>
                        
                            {  props.item.signs.map((el, index) => {
                                return index == 0 ? <span> 
                                    {props.chat.members?.[0]?.name} {props.chat.members?.[0]?.surname} , il {el.cdat.substring(0,10)}, {el.cdat.substring(11,19)}
                                </span> : ""
                                }
                            )
                            }
                        </p>
                    
                    </CustomBadge>
                    </div>
                    <div className="notarizzato me-2">                                   
                            <i class="bi bi-shield-check me-1"></i>
                            <span className="fs-12">Verbale notarizzato</span>
                    </div> 
            </> :
            <div className="tag-flxmsg">
                    {!dataRequired.isVerifiedFull && <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 50, hide: 50 }}
                                                        overlay={
                                                          <Tooltip id="tooltip" className="exclamation-tooltip">
                                                            <p className="mb-3">Per poter firmare un messaggio, è necessario completare le seguenti informazioni:</p>

                                                            <p>1. <strong>Anagrafica Completa:</strong> <br/>Anagrafica / Face ID /Documenti</p>
                                                            <em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>
                                                          </Tooltip>
                                                        }>
                                                        <i class="bi  bi-info-circle me-2 fs-14"></i>
                                                      </OverlayTrigger>
                                                      }
                <p
                    onClick={() => props.handleOpenFirma(props.item.id)}
                    className="aggiungi-firma fs-14 text-decoration-underline"
                    style={{cursor: "pointer"}}
                >
                    AGGIUNGI FIRMA
                </p>
            </div>
            }

<EditVerbale parsedContent={ props.parsedContent} showV={showV} handleCloseV={handleCloseV } getOne={props.getOne} item={props.item} chat={props.chat} />
        </>
    )
}





// Funzione modificata che genera il PDF senza intestazione
const exportPDF = ( parsedContent) => {
  const doc = new jsPDF();

  const logoImg = logoPng
    const logoWidth = 26;
    const logoHeight = 13;
    doc.addImage(logoPng, 'PNG', 15, 15, logoWidth, logoHeight); 

  // Aggiungi il titolo "Verbale"
  //doc.text('Verbale', 40, 20);  Modifica le coordinate x, y per allineare il titolo come preferisci

  // Prepara i dati per autoTable senza utilizzare intestazioni di colonna esplicite
  const tableRows = [];

  // Costruisci le righe della tabella a partire dai tuoi dati
  parsedContent.forEach((key) => {
    const rowData = [key.name, key.type == "multiselect" ? key.value.map(valore => valore.trim()).join(", ") : key.value]; // Costruisci ogni riga con chiave e valore
    tableRows.push(rowData);
  });

  // Aggiungi la tabella al documento senza intestazioni di colonna
  doc.autoTable({
    body: tableRows,
    // Puoi aggiungere qui eventuali opzioni per personalizzare lo stile della tabella
    didDrawCell: (data) => {
      // Esempio: Modifica lo stile di una cella specifica, se necessario
    },
    startY: 35
  });

  // Nome file del PDF che verrà scaricato
  const date = Date.now();
  const pdfTitle = `Export_${date}.pdf`;

  // Salva il PDF
  doc.save(pdfTitle);
};

// Componente React per il pulsante di esportazione, rimane invariato
const TableVerbale = ({ parsedContent}) => (
  <button onClick={() => exportPDF(parsedContent)} className="btn round-icon-btn btn mx-3 me-2"><i class="bi bi-download fs-12"></i>
  </button>
);


function EditVerbale(props) {

    const [form, setForm] = useState(props.parsedContent);
    const [multiselect, setMultiselect] = useState(() => {
        const initialState = {};
        // Itera sugli elementi del tuo array di campi
        props.parsedContent.length > 0 && props.parsedContent.forEach(campo => {
          if (campo.type === "multiselect") {
            // Per i campi con `campoLibero` true, assicurati che i valori siano già nel formato corretto
            if (campo.campoLibero) {
              initialState[campo.id] = campo.value.map(valore => ({
                label: valore, 
                value: valore 
              }));
            } else {
              initialState[campo.id] = campo.options
                .filter(option => campo.value.includes(option.name)) // o `option.id.toString()` se utilizzi gli ID
                .map(option => ({ label: option.name, value: option.id.toString() }));
            }
          }
        });
        return initialState;
      });
      

    const handleMultiSelectChange = (fieldId, selectedOptions) => {
        // Aggiorna lo stato multiselect
        setMultiselect(prev => ({
            ...prev,
            [fieldId]: selectedOptions // Aggiorna solo le selezioni per il campo specifico
        }));
    
        // Aggiorna lo stato form con i nuovi valori selezionati per il campo multiselect specifico
        setForm(prevForm => prevForm.map(field => {
            if (field.id === fieldId) {
                return {
                    ...field,
                    value: selectedOptions.map(option => option.value) // Aggiorna il campo con i valori selezionati
                };
            }
            return field;
        }));
    };



   
    function handleChange(e, id) {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setForm(prevForm => prevForm.map(field => field.id === parseInt(name) ? { ...field, value: checked } : field));
        } else {
            setForm(prevForm => prevForm.map(field => field.id === parseInt(name) ? { ...field, value: value } : field));
        }
    }

  
    async function putItem(body){
      const response = await api().put(
        `${chat_msg_endpoint}${props.item.id}`,
        body
      )
  
      await props.getOne(props.chat.id)
    
    }

    function getLabelValue() {
        return form.map(({ id, name, type, value, options, campoLibero }) => ({
            id,
            name,
            type,
            value,
            options,
            campoLibero
        }));
    }
  
    // Gestore per l'invio del modulo
    const handleSubmit = (e) => {
      e.preventDefault();

      const content = getLabelValue();

  
      // Prepara il body per la richiesta PUT
      const bodyForCreate = {
        message: "",
        senderEmail: "",
        replyID: -1,
        attachments: [],
        tag: [],
        messageType: "verbale",
        content: JSON.stringify(content).replace(/"/g, "'"),
        deleted: false,
        chatGroupID: props.chat.id
      };
  
     putItem(bodyForCreate);

     console.log(bodyForCreate)
      
      props.handleCloseV(); // Chiude il modal dopo l'invio
    };
    
    
  
    console.log( props.chat, props.item, form)
  
    return (
      <div>
        <Modal show={props.showV} onHide={props.handleCloseV} className="p-5" centered style={{minHeight:"500px !important"}}>
            <Modal.Header closeButton className=" pt-md-4 px-md-5 modalCustomHeader">
                <Modal.Title>Modifica Verbale</Modal.Title>
            </Modal.Header>
            <Modal.Body className="eventModalBody">
            <Form onSubmit={handleSubmit}>
            {form.length > 0 && form.map((input, index) => {
                                // Logica per opzioni x campi liberi di tipo select
                                let opzioniAnteprimaCampoLibero = input.campoLibero && input.options?.length > 0 ? input.options[0].split(",").map(option => option.trim()) : [];
                                let arrayAnteprimaCampoLibero = opzioniAnteprimaCampoLibero.map(item => ({ name: item }));
                                let arrayAnteprimaCampoLiberoMulti = opzioniAnteprimaCampoLibero.map(item => ({ value: item, label: item }));
                        
                                // Costruzione dei componenti Form
                                return <Form.Group className="mb-3 d-flex flex-column" key={index}>
                                    <Form.Label className='text-capitalize'>{input.name}</Form.Label>
                                    {input.type === "text" || input.type === "number" || input.type === "date" || input.type === "time" ? (
                                        <Form.Control
                                            type={input.type}
                                            name={String(input.id)}
                                            onChange={handleChange}
                                            value={input.value}
                                            style={{ height: "44px", borderRadius: "6px", border: "1px solid #BDBDBD", padding: "0 .5rem", width: '100%' }}
                                        />
                                    ) : input.type === "textarea" ? (
                                        <Form.Control
                                            as="textarea"
                                            name={String(input.id)}
                                            onChange={handleChange}
                                            value={input.value}
                                            style={{ height: "100px", borderRadius: "6px", border: "1px solid #BDBDBD", padding: ".5rem", width: '100%' }}
                                        />
                                    ) : input.type === "select" ? (
                                            <Form.Select
                                                name={String(input.id)}
                                                onChange={handleChange}
                                                value={input.value}
                                                style={{ height: "44px", borderRadius: "6px", border: "1px solid #BDBDBD", padding: "0 .5rem", width: '100%' }}
                                            >
                                                {input.campoLibero ? arrayAnteprimaCampoLibero.map((option, idx) => (
                                                    <option key={idx} value={option.name}>{option.name}</option>
                                                )) : input.options.map((option, idx) => (
                                                    <option key={idx} value={option.name}>{option.name}</option>
                                                ))}
                                            </Form.Select>
                                        )  : input.type === "multiselect" ? (
                                       
                                      
                                        <Form.Group key={index}>
                          
                            <MultiSelect
  options={input.campoLibero ? 
           arrayAnteprimaCampoLiberoMulti : 
           input.options.map(option => ({ label: option.name, value: option.id.toString() }))}
  value={multiselect[input.id] || []}
  onChange={(selectedOptions) => handleMultiSelectChange(input.id, selectedOptions)}
  labelledBy="Seleziona"
/>

                        </Form.Group>
                                    ) : input.type === "checkbox" ? (
                                        <Form.Check
                                            type="checkbox"
                                            label={input.name}
                                            name={String(input.id)}
                                            checked={input.value}
                                            onChange={handleChange}
                                        />
                                    ) : null}
                                </Form.Group>
                            })}
                            <Modal.Footer className="d-flex flex-column px-md-5 pb-md-4" style={{ borderTop: "none" }}>
                                <ButtonRm variant="primary" type="submit">Salva</ButtonRm>
                                <ButtonRm variant="secondary" onClick={props.handleCloseV}>Annulla</ButtonRm>
                            </Modal.Footer>
                        </Form>
            </Modal.Body>
        </Modal>
      </div>
    );
  }