import { useState, useEffect } from "react";
import { Col, Modal, Form } from "react-bootstrap"
import ButtonRm from "../../../../ButtonRm"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import capitalizeFirstLetter from "../../../../../utils/capitalizeFirstLetter";

import { useDispatch } from "react-redux";
import { addDeadline } from "../../../../../actions/deadline";

import logoPng from '../../../../../assets/images/logo-red-big.png';

import api, { tag_endpoint, chat_msg_endpoint } from "../../../../../api/api";


import { jsPDF } from "jspdf";
import 'jspdf-autotable';


function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('it-IT', options).replace(',', ' h');
}

const eventData = {
    title: "",
    section: "",
    startDate: "",
    endDate:	"",
    every:	"",
    recurrenceType:	"",
    toDate:	undefined,
    notification: "",
    sharing: "",
    note: ""
    //manca campo per giorni di preavviso => type: number
}


export default function EventMsg(props){

    const [formData, setFormData] = useState(eventData)


    useEffect(() => {
        setFormData( prev => {
            return {...prev,
            title: props.parsedContent.title,
            startDate: props.parsedContent.startDate,
            endDate: props.parsedContent.endDate,
            section: props.parsedContent.section
        }
        })
    }, [props.parsedContent])

    console.log(props.parsedContent)
    const rows = [
        { key: 'Titolo', value: props.parsedContent.title },
        { key: 'Sezione', value: props.parsedContent.section },
        { key: 'Data d\'inizio', value: formatDate(props.parsedContent.startDate) },
        { key: 'Data di fine', value: formatDate(props.parsedContent.endDate) },
    ];


       //CREATION EVENT
       const dispatch = useDispatch();

       function postItem(data) {
           dispatch(addDeadline(data))
       }
  

    return(
        <>
        <div className="my-3" >
             <div className="d-flex align-items-center">
                <p className="text-capitalize fs-14">Evento</p>
                <ExportPDFButton rows={rows}  />
{/*                     <button className="btn round-icon-btn btn ms-1"><i class="bi bi-pencil-square"></i></button>
*/}                </div>
                <hr className="my-2"/>
                <div className="mb-3">
                <TableContainer style={{background: 'transparent'}}>
                <Table aria-label="simple table">
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.key}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer>
               
            </div>
            <ButtonRm variant="outline" size="small" onClick={() => postItem(formData)}>Aggiungi al calendario</ButtonRm>
           
        </div>
        
        
        </>
    )
}   


const exportPDF = (rows) => {
    const doc = new jsPDF();
  
    // Aggiungi il logo
    const logoWidth = 26;
    const logoHeight = 13;
    doc.addImage(logoPng, 'PNG', 15, 15, logoWidth, logoHeight);
    
  
    // Aggiungi la tabella al documento
    // Costruisci le righe della tabella per autoTable
    const tableRows = rows.map(row => [capitalizeFirstLetter(row.key), capitalizeFirstLetter(row.value)]);

    // Aggiungi la tabella al documento
    doc.autoTable({
        body: tableRows,
        startY: 35
        // Qui puoi aggiungere altre opzioni per personalizzare lo stile della tabella
    });
    // Salva il PDF con un titolo che include la data corrente
    const date = new Date().toISOString();
    const pdfTitle = `Export_${date}.pdf`;
  
    doc.save(pdfTitle);
};

// Componente React per il pulsante di esportazione
const ExportPDFButton = ({ rows }) => (
    <button  className="btn round-icon-btn btn mx-3 me-2" onClick={() => exportPDF(rows)}>
       <i class="bi bi-download fs-12"></i>
    </button>
);



