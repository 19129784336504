import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import support from  "../../assets/images/disc3.svg"

import logo from "../../assets/images/Group 1189.svg"

import disc from "../../assets/images/disc2.svg"

import { Link } from 'react-router-dom';


import discAlt from "../../assets/images/disc1.svg"

import Footer from './Footer';



export default function Cookies (){
    return (
        <div style={{background:'#fff', position: "relative"}}>
            <div style={{background: ''}}>
                <Container className="px-3 px-md-0">


                <Navbar bg="" data-bs-theme="light" className='d-flex align-items-center pt-3' style={{background: "transparent"}}>
                        <Navbar.Brand href="/landing" className='me-3'>
                            <Image src={logo} width={"85px"}/>
                        </Navbar.Brand>
                        <Nav className="me-auto bg-alert w-100 d-flex align-items-center">                            
                            {/* <div className='ms-auto d-flex align-items-center'>
                                <Button variant="outline-primary me-3" style={{color: "#0F1B4C", borderColor: "#0F1B4C"}}>Sei un gestore?</Button>
                            </div> */}
                        </Nav>
                    </Navbar>

                    <Row className="flex-column mb-5" >
                        <Cookie/>
                    </Row>


                    <Footer />

                   

                </Container>
            </div>

           


        </div>
    )
}

function Cookie (){
    return(
        <div>
            <h3>Cookie Policy di realmeta.it</h3>
            <p>Questo documento contiene informazioni in merito alle tecnologie che consentono a questa Applicazione di raggiungere gli scopi descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l&rsquo;utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell&rsquo;Utente quando quest&rsquo;ultimo interagisce con questa Applicazione.</p>
<p>Per semplicit&agrave;, in questo documento tali tecnologie sono sinteticamente definite &ldquo;Strumenti di Tracciamento&rdquo;, salvo vi sia ragione di differenziare.Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza di un browser. Per questo motivo, all&rsquo;interno di questo documento il termine Cookie &egrave; utilizzato solo per indicare in modo specifico quel particolare tipo di Strumento di Tracciamento.</p>
<p>Alcune delle finalit&agrave; per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso dell&rsquo;Utente. Se viene prestato il consenso, esso pu&ograve; essere revocato liberamente in qualsiasi momento seguendo le istruzioni contenute in questo documento.</p>
<p>Questa Applicazione utilizza solo Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento &ldquo;di prima parte&rdquo;).Durata e scadenza dei Cookie di prima parte e degli altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal Titolare. Alcuni di essi scadono al termine della sessione di navigazione dell&rsquo;Utente.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Attivit&agrave; strettamente necessarie a garantire il funzionamento di questa Applicazione e la fornitura del Servizio</strong></h5>
<p></p>
<p>Questa Applicazione utilizza Cookie comunemente detti &ldquo;tecnici&rdquo; o altri Strumenti di Tracciamento analoghi per svolgere attivit&agrave; strettamente necessarie a garantire il funzionamento o la fornitura del Servizio.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Come gestire le preferenze e prestare o revocare il consenso</strong></h5>
<p></p>
<p>Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove necessario:</p>
<p>Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri dispositivi - per esempio, possono impedire l&rsquo;uso o l&rsquo;archiviazione di Strumenti di Tracciamento.</p>
<p>In aggiunta, ogni qualvolta l&rsquo;utilizzo di Strumenti di Tracciamento dipenda da consenso, l&rsquo;Utente pu&ograve; prestare o revocare tale consenso impostando le proprie preferenze all&rsquo;interno dell&rsquo;informativa sui cookie o aggiornando tali preferenze tramite il widget privacy per le preferenze relative al consenso, se presente.</p>
<p>Grazie ad apposite funzioni del browser o del dispositivo &egrave; anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati, inclusi quelli utilizzati per il salvataggio delle preferenze relative al consenso inizialmente espresse dall&apos;Utente.</p>
<p>Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Individuare le impostazioni relative agli Strumenti di Tracciamento</strong></h5>
<p></p>
<p>Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei browser pi&ugrave; diffusi ai seguenti indirizzi:</p>
<ul>
    <li>●<a rel="noopener nofollow" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies" className='text-dark'>Google Chrome</a></li>
    <li>●<a rel="noopener nofollow" target="_blank" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie" className='text-dark'>Mozilla Firefox</a></li>
    <li>●<a rel="noopener nofollow" target="_blank" href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/" className='text-dark'>Apple Safari</a></li>
    <li>●<a rel="noopener nofollow" target="_blank" href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" className='text-dark'>Microsoft Internet Explorer</a></li>
    <li>●<a rel="noopener nofollow" target="_blank" href="https://support.microsoft.com/it-it/help/4027947" className='text-dark'>Microsoft Edge</a></li>
    <li>●<a rel="noopener nofollow" target="_blank" href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing" className='text-dark'>Brave</a></li>
    <li>●<a rel="noopener nofollow" target="_blank" href="https://help.opera.com/latest/web-preferences/#cookies" className='text-dark'>Opera</a></li>
</ul>
<p>Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite impostazioni del dispositivo, quali le impostazioni di pubblicit&agrave; per dispositivi mobili o le impostazioni relative al tracciamento in generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).</p>
<p><strong></strong></p>
<br/>
<h5><strong>Conseguenze legate al rifiuto dell&apos;utilizzo di Strumenti di Tracciamento</strong></h5>
<p></p>
<p>Gli Utenti sono liberi di decidere se permettere o meno l&apos;utilizzo di Strumenti di Tracciamento. Tuttavia, si noti che gli Strumenti di Tracciamento consentono a questa Applicazione di fornire agli Utenti un&apos;esperienza migliore e funzionalit&agrave; avanzate (in linea con le finalit&agrave; delineate nel presente documento). Pertanto, qualora l&apos;Utente decida di bloccare l&apos;utilizzo di Strumenti di Tracciamento, il Titolare potrebbe non essere in grado di fornire le relative funzionalit&agrave;.</p>
<p><strong></strong></p>
<br/>
<h5><strong>Titolare del Trattamento dei Dati</strong></h5>
<p></p>
<p>Anthill S.r.l.s.<br/>Via Colombo, 14/3 16121 Genova (GE) - Italy<br/> P.Iva 02641290990</p>
<p><strong><strong>Indirizzo email del Titolare:</strong></strong> info@anthillgroup.it</p>
<p>Data l&apos;oggettiva complessit&agrave; di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il Titolare qualora volessero ricevere ulteriori informazioni in merito all&apos;utilizzo di tali tecnologie su questa Applicazione.</p>
        </div>
    )
}