import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Form } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useRef, useEffect, useState } from 'react';

import dashboard from '../../assets/images/attori.png'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import unit from  "../../assets/images/un.png"
import rent from  "../../assets/images/aff.png"
import calc from  "../../assets/images/calc.png"
import rend from  "../../assets/images/rend.png"
import calen from  "../../assets/images/calen.png"
import { useMediaQuery } from 'react-responsive';
import ResponsiveNavbar from './NavRm';

import { Link } from 'react-router-dom';
import logo from "../../assets/images/Group 1189.svg"
import Footer from './Footer';
import "../../assets/css/Home.css"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonRm from '../ButtonRm';

import { navyColor } from '../../utils/rm-colors';

import FAQComponent from './FaqComponent';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import CustomBadge from '../CustomBadge';
import ProgressBar from 'react-bootstrap/ProgressBar';

import axios from 'axios';

import "../../assets/css/TrovaGestore.css"

const useRefDimensions = (ref) => {
    const [dimensions, setDimensions] = useState({ width: 1, height: 2 })

    useEffect(() => {
        if (ref.current) {
            const { current } = ref
            const boundingRect = current.getBoundingClientRect()
            const { width, height } = boundingRect
            setDimensions({ width: Math.round(width), height: Math.round(height) })
        }
    }, [])

    useEffect(() => {
        // Definisce la funzione da chiamare quando la finestra viene ridimensionata
        const handleResizeWindow = () => {
            if (ref.current) {
                const { current } = ref
                const boundingRect = current.getBoundingClientRect()
                const { width, height } = boundingRect
                setDimensions({ width: Math.round(width), height: Math.round(height) })
            }
        };
    
        window.addEventListener('resize', handleResizeWindow);
    
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [ref]); 

    return dimensions
}

const faqs = [{
    question: "Quanto costa il servizio trova il Gestore?",
    answer: "Il servizio è Gratuito, paghi solo il gestore, se siamo riusciti a trovare quello giusto per te"
  },

  {
    question: "L'uso di Real Meta per me che sono un proprietario è gratuito?",
    answer: "Sì, i proprietari invitati da un gestore su Real Meta lo possono utilizzare gratuitamente"
  },
  {
    question: "Cosa può fare per me e le mie proprietà un Property manager?",
    answer: "-"
  }]

export default function TrovaGestore (){

    const refContainer = useRef();
    const boxRef = useRef()

    //GESTIONE MODAL STEP
    const [show, setShow] = useState(false);

	const handleClose = (e) => {
        setShow(false)

	};
	const handleShow = () => setShow(true);

    console.log(show, "show")
    

    return (
        <div style={{background:'#fff'}}>
             <div style={{background: '',  backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
                <div style={{}}>
                    <Container className="px-3 px-md-0 overflow-hidden">

                    <ResponsiveNavbar/>


                        <Row className="mt-5 justify-content-between align-items-center mx-auto gy-4" style={{maxWidth: "1100px"}}>
                            <Col lg={5} xl={6}>
                                <div>
                                <h1 className="mb-2 fs-50 syne-font text-start">Trova il tuo gestore</h1>
                                <p className='fs-30 mb-3'>
                                Non hai tempo, e conoscenze per gestire le tue proprietà? Stai cercando un  property manager professionista che possa seguire e gestire con diligenza i tuoi immobili?
                                </p>
                                <Button size="lg" variant="primary" style={{background: navyColor, borderColor: "#0F1B4C"}} className=" me-2">Inviaci la tua richiesta</Button>
                                </div>
                            </Col>
                            <Col lg={5} >
                                <div>
                                    <img src="https://www.svgrepo.com/show/508699/landscape-placeholder.svg" width="100%" className="rounded-circle"/>
                                </div>
                            </Col>
                        </Row>

                        <Row className="flex-column mb-2 mx-auto" style={{maxWidth: "1000px", marginTop:"4rem"}}>
                            <Col className="justify-content-center" style={{padding: "4rem 0rem 2rem 0rem"}}>
                                <div className='d-flex flex-column justify-content-center align-items-center' >
                                <p className='text-uppercase text-start text-lg-center d-none d-lg-block'>Real meta property</p>
                                    <h1 className="mb-2 fs-50 syne-font text-start text-lg-center">Benefits</h1>
                                    <p className='text-start text-lg-center'>Il mercato immobiliare oggi può offrire molte soddisfazioni, ma allo stesso tempo ci sono incombenze burocratiche, manutentive, amministrative da considerare. Un property manager si occupa della gestione in tua vece</p>

                                </div>

                                <Col lg={12} className="my-5">
                                <Row className="g-5 flex-wrap">
                                    <Col md={6} lg={4}>
                                        <div className="d-flex flex-column align-item-start modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Zero preoccupazioni</strong> <br/>
                                            Il property manager gestisce le tue proprietà,
                                            con cura e dedizioni, gestendolo a 360°
                                            </p>

                                        </div>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <div className="d-flex flex-column align-item-start modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Professionisti Verificati</strong> <br/>
                                            I gestori di Real meta sono property manager professionisti.
                            
                                            </p>

                                        </div>
                                    </Col>

                                    <Col md={6} lg={4}>
                                        <div className="d-flex flex-column align-item-start modulo-feature">
                                            <span className='me-2'>
                                                <i class="bi bi-check-circle text-light"></i>                                        
                                            </span>
                                            <p>
                                            <strong>Monitoraggio costante</strong> <br/>
                                            Tramite l'applicaizione real meta potrai monitorare
                                            il processo di gestione 
                                            </p>

                                        </div>
                                    </Col>
                                    
                                </Row>

                                </Col>
                                
                            </Col>
                            
                        </Row>

                        <Row className="my-5 justify-content-between align-items-center mx-auto " style={{maxWidth: "1100px", marginBottom:"3rem"}}>
                        <h1 className="mb-2 fs-50 syne-font text-start text-lg-center">Come funziona il servizio?</h1>
                       
                        </Row>

                        <Row className="my-5 justify-content-between align-items-center mx-auto g-3" style={{maxWidth: "1100px", marginBottom:"3rem"}}>
                            <Col lg={3} >
                                <div style={{maxWidth: "1100px", background: "rgb(231 235 240)", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                                    <h1 className="fs-80 opacity-50 syne-font">1</h1>
                                    <p className='mt-2 fw-bold syne-font'>Invia la tua richiesta</p>
                                    <p>
                                    Compila il form con informazioni relative ai tuoi immobili, ai tuoi obiettivi e il tuo budget
                                    </p>
                                </div>
                            </Col>
                            <Col lg={3}>
                            <div style={{maxWidth: "1100px", background: "rgb(231 235 240)", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                                    <h1 className="fs-80 opacity-50 syne-font">2</h1>
                                    <p className='mt-2 fw-bold syne-font'>Valuta le proposte</p>
                                    <p>
                                    Riceverai 3 preventivi di 3 gestori della tua zona da valutare, in base alle tue richieste.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div style={{maxWidth: "1100px", background: "rgb(231 235 240)", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                                    <h1 className="fs-80 opacity-50 syne-font">3</h1>
                                    <p className='mt-2 fw-bold syne-font'>Seleziona il gestore</p>
                                    <p>
                                    Scegli la proposta che più si adatta alle tue esigenze, e stipuliamo il contratto di collaborazione.

                                    </p>
                                </div>
                            </Col>
                            <Col lg={3} >
                                <div style={{maxWidth: "1100px", background: "rgb(231 235 240)", borderRadius: "1rem"}} className='p-4 pt-2 mb-3 mb-lg-0'>
                                    <h1 className="fs-80 opacity-50 syne-font">4</h1>
                                    <p className='mt-2 fw-bold syne-font'>Monitora su Real Meta</p>
                                    <p>
                                    Verrai aggiunto su Real Meta dal tuo gestore, dove potrai gestire scambi documentali, e il tutto.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="my-5 justify-content-between align-items-center mx-auto " style={{maxWidth: "1100px", marginBottom:"3rem"}}>
                        <h1 className="mb-2 fs-50 syne-font text-start text-lg-center">Domande Frequenti</h1>
                       
                        </Row>

                        <FAQComponent faqs={faqs} />


                        <Row className="mt-5 justify-content-center align-items-center mx-auto p-5" style={{maxWidth: "1100px", background: "#0F1B4C", borderRadius: "1rem", color:"white"}}>
                        <Col md={12} className="text-center">
                            <h2>Richiedi un gestore</h2>
                            <p className='mt-2 mb-3'>Inserisci i dati richiesti, e ti ricontatteremo nel più breve tempo possibile inviandoti 3 proposte da valutare.</p>

                            <Button variant="primary" style={{background: "#fff", borderColor: "#0F1B4C", color:"#0F1B4C"}} onClick={handleShow}>Compila il form</Button>
                        </Col>
        
                    </Row>

                   {/*  <iframe width="1024" height="640" allowfullscreen src="https://demombt.s3.eu-south-1.amazonaws.com/Demo+BARCHE/Demo3d/MBT+Studio+-+BARCA+-+Riva/index.html"></iframe> */}

                        <Footer />

                    </Container>
                </div>
            </div>
          <StepperModal show={show} handleClose={handleClose} />
        </div>
    )
}



const steps = 5


const StepperModal = ({ show, handleClose }) => {
    const [activeStep, setActiveStep] = useState(1);
    const [subStep, setSubStep] = useState(1);
    const [confirmationPage, setConfirmationPage] = useState(false)
    const [formData, setFormData] = useState({
        Immobili: '1 immobile',
        AltroImmobili: '',
        telephone: '',
        email: '',
        name: '',
        note: ''
    });

    const totalSubSteps = formData.Immobili === 'Altro' ? parseInt(formData.AltroImmobili) : parseInt(formData.Immobili);

    const isFormValid = () => {
        if (activeStep === 1) {
            // Verifica se i campi obbligatori dello Step 1 sono compilati
            return formData.Immobili !== '' && (formData.Immobili !== 'Altro' || formData.AltroImmobili !== '');
        }
        
        if (activeStep === 2 || activeStep === 3) {
            const totalSubSteps = formData.Immobili === 'Altro' ? parseInt(formData.AltroImmobili) : parseInt(formData.Immobili);
            
            // Verifica se il campo obbligatorio per il sub-step corrente è compilato
            return formData[`province${subStep}`] && formData[`municipality${subStep}`];
        }
        
        if (activeStep === 4) {
            return true; 
        }
        
        if (activeStep === 5) {
            // Verifica se i campi obbligatori dello Step 5 sono compilati
            return formData.name !== '' && formData.email !== '' && formData.telephone !== '';
        }
        
        return false;
    };
    
    const handleNext = () => {
        if (isFormValid()) {
            if (activeStep === 5) {
                handleSubmit();
            } else if (activeStep === 2 && subStep < totalSubSteps) {
                setSubStep(subStep + 1);
            } else if (activeStep === 3 && subStep < totalSubSteps) {
                setSubStep(subStep + 1);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                if (activeStep === 2 || activeStep === 3) setSubStep(1); 
            }
        }
    };
    
    const handleBack = () => {
        if ((activeStep === 2 || activeStep === 3) && subStep > 1) {
            setSubStep(subStep - 1);
        } else if ((activeStep === 2 || activeStep === 3) && subStep === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            if (activeStep === 3) {
                setSubStep(totalSubSteps);  // Quando si torna allo step 2, impostare subStep all'ultimo immobile
            } else if (activeStep === 2) {
                setSubStep(totalSubSteps);  // Quando si torna allo step 1, resetta subStep a 1
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        console.log(formData);
        setConfirmationPage(true)
    };

    const resetForm = () => {
        setConfirmationPage(false)
        setActiveStep(1);
        setSubStep(1);
        setFormData({
            Immobili: '1 immobile',
            AltroImmobili: '',
            telephone: '',
            email: '',
            name: '',
            note: ''
            });
    };

    return (
        <Modal show={show} onHide={() => {
            handleClose()
            resetForm();

            }} centered className='trovaGestoreModal'>
            <Modal.Header closeButton className="text-center fs-24">
                <p className="text-center fs-24 mx-auto fw-bold">Trova il tuo gestore</p>
            </Modal.Header>
            <Modal.Body className="p-5">
                {!confirmationPage && <div className='mb-5'>
                <ProgressBar now={(activeStep) * 20} />
                </div>}

                <div>
                    {!confirmationPage && activeStep === 1 && <Step1 formData={formData} handleChange={handleChange} />}
                    { !confirmationPage && activeStep === 2 && <Step2 formData={formData} handleChange={handleChange} subStep={subStep} />}
                    { !confirmationPage &&activeStep === 3 && <Step3 formData={formData} handleChange={handleChange} subStep={subStep} />}
                    { ! confirmationPage &&activeStep === 4 && <Step4 formData={formData} handleChange={handleChange} />}
                    {!confirmationPage && activeStep === 5 && <Step5 formData={formData} handleChange={handleChange} />}
                    {confirmationPage && <ConfirmationPage />}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div style={{ marginTop: 16 }}>
                    {!confirmationPage && activeStep !== 1 && <ButtonRm
                        variant="outline"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        className="me-2"
                    >
                        Indietro
                    </ButtonRm>}
                    {!confirmationPage && <ButtonRm
                        variant="trova-gestore"
                        onClick={handleNext}
                        disabled={!isFormValid()} // Disabilita il bottone se il form non è valido
                        style={{ marginRight: 8 }}
                    >
                        {activeStep === 5 ? 'Invia' : 'Avanti'}
                    </ButtonRm>}
                    {confirmationPage && 
                        <ButtonRm
                        variant="trova-gestore"
                        onClick={() => {
                            handleClose()  
                            resetForm();                         
                        }}
                        style={{ marginRight: 8 }}
                    >
                        Chiudi
                    </ButtonRm>}
                </div>
            </Modal.Footer>
        </Modal>
    );
};

function Step1({ formData, handleChange }) {
    return (
        <Col md={12}>
            <Form.Group className="my-3" controlId="formBasicCheckbox">
                <p className='fs-18 mb-2'>Quanti immobili possiedi? <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></p>
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="1 immobile"
                    name="Immobili"
                    checked={formData.Immobili === '1 immobile'}
                    value="1 immobile"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="2 immobili"
                    name="Immobili"
                    checked={formData.Immobili === '2 immobili'}
                    value="2 immobili"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="3 immobili"
                    name="Immobili"
                    checked={formData.Immobili === '3 immobili'}
                    value="3 immobili"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Altro"
                    name="Immobili"
                    checked={formData.Immobili === 'Altro'}
                    value="Altro"
                    className='trova-gestore-input fs-18'
                />
            </Form.Group>

            {formData.Immobili === 'Altro' &&
                <Form.Group className="mb-1">
                    <Form.Label className='fs-18'>Inserisci il numero di immobili che possiedi <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                    <Form.Control
                        type="number"
                        onChange={handleChange}
                        name="AltroImmobili"
                        value={formData.AltroImmobili}
                    />
                </Form.Group>
            }
        </Col>
    )
}

function Step2({ formData, handleChange, subStep }) {
    const [provinceList, setProvincesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const fetchProvinces = () => {
        axios
            .get(`https://anthill-service.onrender.com/provinces?region`)
            .then((res) => {
                setProvincesList(res.data.data);
            });
    };

    useEffect(() => {
        fetchProvinces();
    }, []);

    const fetchCities = () => {
        axios
            .get(`https://anthill-service.onrender.com/municipalities?province=${formData[`province${subStep}`]}`)
            .then((res) => {
                setCitiesList(res.data.data);
            });
    };

    useEffect(() => {
        if (formData[`province${subStep}`]) {
            fetchCities();
        }
    }, [formData[`province${subStep}`]]);

    return (
        <Col md={12}>
            <h5 className='text-uppercase fs-16'>Immobile {subStep}</h5>
            <Form.Group className="mb-3">
                <Form.Label className="fs-18">In che provincia si trova Immobile {subStep}? <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <Form.Select
                    type="text"
                    name={`province${subStep}`}
                    value={formData[`province${subStep}`]}
                    onChange={handleChange}
                >
                    <option value=""></option>
                    {provinceList?.length > 0 && provinceList.map((province, index) => (
                        <option value={province} key={index}>
                            {province}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            <Form.Group>
                <Form.Label className="fs-18">In che comune si trova Immobile {subStep}? <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <Form.Select
                    type="text"
                    name={`municipality${subStep}`}
                    value={formData[`municipality${subStep}`]}
                    onChange={handleChange}
                >
                    <option value=""></option>
                    {citiesList?.length > 0 && citiesList.map((city, index) => (
                        <option value={city} key={index}>
                            {city}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </Col>
    );
}

function Step3({ formData, handleChange, subStep }) {
    return (
        <Col md={12}>
            <h5 className='text-uppercase fs-16'>Immobile {subStep}</h5>
            <Form.Group className="my-3" controlId="formBasicCheckbox">
                <p className='fs-18 mb-2'>In che stato di occupazione si trova Immobile {subStep} ? <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></p>
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Locato a lungo termine"
                    name={`Stato${subStep}`}
                    checked={formData[`Stato${subStep}`] === 'Locato a lungo termine'}
                    value="Locato a lungo termine"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Locato a breve termine"
                    name={`Stato${subStep}`}
                    checked={formData[`Stato${subStep}`] === 'Locato a breve termine'}
                    value="Locato a breve termine"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Non occupato"
                    name={`Stato${subStep}`}
                    checked={formData[`Stato${subStep}`] === 'Non occupato'}
                    value="Non occupato"
                    className='trova-gestore-input fs-18'
                />
            </Form.Group>

            <Form.Group className="my-3" controlId="formBasicCheckbox">
                <p className='fs-18 mb-2'>Che obiettivi hai per Immobile {subStep} ? <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></p>
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Affitto a breve termine"
                    name={`Obiettivo${subStep}`}
                    checked={formData[`Obiettivo${subStep}`] === 'Affitto a breve termine'}
                    value="Affitto a breve termine"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Affitto a lungo termine"
                    name={`Obiettivo${subStep}`}
                    checked={formData[`Obiettivo${subStep}`] === 'Affitto a lungo termine'}
                    value="Affitto a lungo termine"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Valorizzazione"
                    name={`Obiettivo${subStep}`}
                    checked={formData[`Obiettivo${subStep}`] === 'Valorizzazione'}
                    value="Valorizzazione"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Vendita"
                    name={`Obiettivo${subStep}`}
                    checked={formData[`Obiettivo${subStep}`] === 'Vendita'}
                    value="Vendita"
                    className='trova-gestore-input fs-18'
                />
                <Form.Check
                    type="radio"
                    onChange={handleChange}
                    label="Altro"
                    name={`Obiettivo${subStep}`}
                    checked={formData[`Obiettivo${subStep}`] === 'Altro'}
                    value="Altro"
                    className='trova-gestore-input fs-18'
                />
            </Form.Group>

            {formData[`Obiettivo${subStep}`] === 'Altro' &&
                <Form.Group className="mb-1">
                    <Form.Label className='fs-18'>Inserisci il tuo obiettivo per Immobile {subStep} <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                    <Form.Control
                        type="text"
                        onChange={handleChange}
                        name={`AltroObiettivo${subStep}`}
                        value={formData[`AltroObiettivo${subStep}`]}
                    />
                </Form.Group>
            }
        </Col>
    );
}

function Step4({ formData, handleChange }) {
    return (
        <Col md={12}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label className="fs-18">Note integrative (facoltativo)</Form.Label>
                <Form.Control as="textarea" rows={10} onChange={handleChange}
                    name="note"
                    value={formData.note} />
            </Form.Group>
        </Col>
    );
}

function Step5({ formData, handleChange }) {
    return (
        <Col md={12}>
            <Form.Group className="mb-3">
                <Form.Label className="fs-18">Nome e Cognome <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="name"
                    value={formData.name}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="fs-18">Email <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={formData.email}
                    required
                />
            </Form.Group>

            <Form.Group>
                <Form.Label className="fs-18">Telefono <CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge></Form.Label>
                <Form.Control
                    type="tel"
                    onChange={handleChange}
                    name="telephone"
                    value={formData.telephone}
                    required
                />
            </Form.Group>
        </Col>
    );
}


function ConfirmationPage({ formData, handleChange }) {
    return (
        <Col md={12}>
            <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
              width="100px"
              style={{ display: "flex", margin: "auto" }}
            ></img>
            <h3 className="mt-4">
              Richiesta inviata
            </h3>
            <p className="fs-18" style={{ maxWidth: "600px" }}>
            Abbiamo ricevuto la sua richiesta. Riceverà i preventivi dei nostri gestori sull’email di contatto fornita. Per ulteriori informazioni può contattarci su <span>info@realmeta.it</span> 
            </p>

          </div>
        </div>
        </Col>
    );
}
