import { useNavigate, useLocation } from "react-router-dom"
import "../assets/css/ChipRm.css"
import removeImg from "../assets/images/removeImg.svg"




function ChipRm(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname + location.search;
  
    const handleClick = () => {
      props.moveTabToFront(props.index);
      navigate(props.el.path);
    };
  
    return (
      <div className="position-relative chip-rm-container-parent">
        <div 
          className={`chip-rm-container ${(currentPath == props.el.path) && "currentActive"}`} 
          onClick={handleClick}
        >
          <small>{props.el.currentSection}</small>
          <p>{props.el.title}</p>
        </div>
        <div className="close-chip-rm-btn" onClick={() => props.removeTabByIndex(props.index)}>
          <img src={removeImg} alt="Remove" />
        </div>
      </div>
    );
  }

export default ChipRm