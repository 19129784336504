import React from "react";
import { BsPrinter, BsDownload } from "react-icons/bs";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";

export default function PrintDwonloadIcons({item, mapping}) {

  return (
    <div className="d-flex mb-3 justify-content-end">
{/*       <ShareChat elId={item.id} styleBtn="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2" />
 */}      <ExportButton data={item} showPrint mapping={mapping} classes="bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2"/>
    </div>
  );
}
