import React from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

export default function (props) {

  const mapping = {
    SUPPLIER: "Fornitore",
    OWNER:"Proprietario",
    CONDUCTOR: "Conduttore",
    COLLABORATOR: "Collaboratore"
  }

  return (
        <div id="treeWrapper" style={{ width: '100%' }} className="border">
        <TreeView
          defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
          defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
        >
          {props.all.map((master) => (
              <TreeItem key={master.master_id} nodeId={master.master_id.toString()} label={`Master ID: ${props.mappingMasters[master.master_id]}`}>
                  {master.team.map((member) => (
                      <TreeItem
                        key={member.id}
                        nodeId={member.id.toString()}
                        label={`Ruolo: ${mapping[member.chatRole]}, Email: ${member.email}`}
                      />
                  ))}
              </TreeItem>
          ))}
      </TreeView>

    </div>
  );
}

