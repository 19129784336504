import '../assets/css/EmptyStateContainer.css'

export default function EmptyStateContainer({content, img}){
    return(
        <div className="my-4 mb-4 mx-auto text-center empty-state-container">
            <div className="mx-auto mb-4 w-160">
                <img loading="lazy" className="img-fluid" src={img} alt="empty-image" />
            </div>
            <p className="fs-16 text-secondary text-center">
            {content}
            </p>
        </div>
    )
}