import React, {useState} from "react";
import "../../../../assets/css/Membri.css";
import Form from "react-bootstrap/Form";
import { formatDateFromString } from "../../../../utility";
import CustomToggle from "../../../CustomToggle";
import ButtonRm from "../../../ButtonRm";

import { Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { PropriText } from "../../../../pages/Proprietari";

import CustomBadge from "../../../CustomBadge.js";


const schema = yup.object().shape({
	documentType: yup.string(),
  documentNumber: yup.string(),
  documentReleaseEntity: yup.string(),
  documentReleaseDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
  documentDeadline: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
  citizenship: yup.string(),
  documentType2: yup.string(),
  documentNumber2: yup.string(),
  documentReleaseEntity2: yup.string(),
  documentReleaseDate2: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
  documentDeadline2: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
  //manca checbox cittadino ue

})

export default function Documento(props) {
  
  const onChange = async (e) => {
    props.setFormData({
      ...props.formData,
      [e.target.name]: e.target.type == "checkbox" ? e.target.checked :  e.target.value,
    });
  };

  const forward = () => {
    props.next();
    /* if (
      props.formData.documentType !== "" &&
      props.formData.documentNumber !== "" &&
      props.formData.documentReleaseDate !== "" &&
      props.formData.documentReleaseEntity !== "" &&
      props.formData.documentDeadline !== "" &&
      props.formData.citizenship !== ""
    ) {
      props.next();
    } */
  };

   //HANDLE ACCORDION
  const [activeKey, setActiveKey] = useState("")

   /* CONTROLLARE SE SIAMO IN CREATE MODE O EDIT MODE */
	const path = window.location.pathname

  //VALIDAZIONE INPUT
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
    console.log({data})
		// controlla se ci sono errori di validazione
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		forward()
	};

  console.log(errors, props.formData)

  return (
    <div
      className="d-block w-100 mb-3 bg-sm-white"
      style={{ borderRadius: "16px" }}
    >
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-item-align-box d-block pt-5 pb-5">
          <Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
            <Accordion.Item eventKey="0" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="0" activeKey={activeKey}>Documento di riconoscimento</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Tipo di documento
                        </Form.Label>
                        <Form.Select 
                          {...register("documentType")}
                          name="documentType"
                          value={props.formData.documentType}
                          onChange={onChange}
                          isInvalid={!!errors.documentType}
                        >
                          <option value="carta d’identità">Carta d’identità</option>
                          <option value="passaporto">Passaporto</option>
                          <option value="patente di guida">Patente di guida</option>
                          <option value="patente nautica">Patente nautica</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                          {errors.documentType?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Numero
                        </Form.Label>
                        <Form.Control 
                          {...register("documentNumber")}
                          type="text" 
                          name="documentNumber"
                          value={props.formData.documentNumber}
                          onChange={onChange}
                          isInvalid={!!errors.documentNumber}
                        />
                        <Form.Control.Feedback type="invalid" >
                        {errors.documentNumber?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    
                  </Row>

                  <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Rilasciato dal/la
											</Form.Label>
											<Form.Control 
												{...register("documentReleaseEntity")}
												type="text" 
                        name="documentReleaseEntity"
                        value={props.formData.documentReleaseEntity}
												onChange={onChange}
												isInvalid={!!errors.documentReleaseEntity}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.documentReleaseEntity?.message}
											</Form.Control.Feedback>
										</Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Data di rilascio
                        </Form.Label>
                        <Form.Control
                          {...register("documentReleaseDate")}
                          type="date"
                          name="documentReleaseDate"
                          value={
                           props.formData.documentReleaseDate
                          }
                          onChange={onChange}
                          isInvalid={!!errors.documentReleaseDate}
                          max="9999-12-31"

                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.documentReleaseDate?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Data di scadenza
                        </Form.Label>
                        <Form.Control
                          {...register("documentDeadline")}
                          type="date"
                          name="documentDeadline"
                          value={
                            props.formData.documentDeadline
                          }
                          onChange={onChange}
                          isInvalid={!!errors.documentDeadline}
                          max="9999-12-31"

                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.documentDeadline?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Cittadinanza
                        </Form.Label>
                        <Form.Control 
                          {...register("citizenship")}
                          type="text" 
                          name="citizenship"
                          value={props.formData.citizenship}
                          onChange={onChange}
                          isInvalid={!!errors.citizenship}
                        />
                        <Form.Control.Feedback type="invalid" >
                        {errors.citizenship?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
                <Card.Header>
                <CustomToggle eventKey="1" activeKey={activeKey}>Permesso di soggiorno o altro documento simile per cittadini extracomunitari ##</CustomToggle>
                </Card.Header>
                <Accordion.Body>
                  <Row >
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Tipo di documento
                        </Form.Label>
                        <Form.Select 
                          {...register("documentType2")}
                          name="documentType2"
                          value={props.formData.documentType2}
                          onChange={onChange}
                          isInvalid={!!errors.documentType2}
                        >
                          <option value="carta d’identità">Carta d’identità</option>
                          <option value="passaporto">Passaporto</option>
                          <option value="patente di guida">Patente di guida</option>
                          <option value="patente nautica">Patente nautica</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" >
                          {errors.documentType2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Numero
                        </Form.Label>
                        <Form.Control 
                          {...register("documentNumber2")}
                          type="text" 
                          name="documentNumber2"
                          value={props.formData.documentNumber2}
                          onChange={onChange}
                          isInvalid={!!errors.documentNumber2}
                        />
                        <Form.Control.Feedback type="invalid" >
                        {errors.documentNumber2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>                    
                  </Row>

                  <Row>
                    <Col md={6}>
                    <Form.Group className="mb-3" >
											<Form.Label 
												className="f-label fs-16 d-flex align-items-center
mb-2"
											>
												Rilasciato dal/la
											</Form.Label>
											<Form.Control 
												{...register("documentReleaseEntity2")}
												type="text" 
                        name="documentReleaseEntity2"
                        value={props.formData.documentReleaseEntity2}
												onChange={onChange}
												isInvalid={!!errors.documentReleaseEntity2}
											/>
											<Form.Control.Feedback type="invalid" >
											{errors.documentReleaseEntity2?.message}
											</Form.Control.Feedback>
										</Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Data di rilascio
                        </Form.Label>
                        <Form.Control
                          {...register("documentReleaseDate2")}
                          type="date"
                          name="documentReleaseDate2"
                          value={
                           props.formData.documentReleaseDate2
                          }
                          onChange={onChange}
                          isInvalid={!!errors.documentReleaseDate2}
                          max="9999-12-31"

                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.documentReleaseDate2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row >
                    <Col md={6}>
                    <Form.Group className="mb-3" >
                        <Form.Label 
                          className="f-label fs-16 d-flex align-items-center
mb-2"
                        >
                          Data di scadenza
                        </Form.Label>
                        <Form.Control
                          {...register("documentDeadline2")}
                          type="date"
                          name="documentDeadline2"
                          value={
                            props.formData.documentDeadline2
                          }
                          onChange={onChange}
                          isInvalid={!!errors.documentDeadline2}
                          max="9999-12-31"

                        >
                        </Form.Control>
                        <Form.Control.Feedback type="invalid" >
                        {errors.documentDeadline2?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <div className="d-block">
                        <div className="radioLabel">
                          {/* NAME E VALUE DA DEINIFRE PERCHé' MANCANO */}
                          <input
                            type="checkbox"
                            className="btn-radio"
                            onChange={onChange}
                            checked={props.formData.ueCitizen}
                            name="ueCitizen"
                          />
                          <label htmlFor="contactChoice1" className="btn-radio">
                            &nbsp;Cittadino UE o altro che non richiede la comunicazione d’ospitalità ad uno straniero
                          </label>
                        </div>
										</div>
                    </Col>
                  </Row>
                </Accordion.Body>
            </Accordion.Item>

          </Accordion>

          { path == "/conduttori/create" && <div className="col-12">
            <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end">
              <ButtonRm variant="tertiary" onClick={() => props.back()} className="me-2">Indietro</ButtonRm>
              <ButtonRm variant="primary"  onClick={forward}>Avanti</ButtonRm>
            </div>    
          </div>}

        </div>

      </Form>
    </div>
  );
}
