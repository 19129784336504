import React, { useState, useEffect } from "react";
import User from "../../../../assets/images/user-key.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OwnerModal from "../OwnerModal";
import EditableOwnerCard from "./EditableOwnerCard";
import EditBackCheckModal from "./EditBackCheckModal";
import api, { owner_endpoint, conductor_endpoint } from "../../../../api/api";
import { Link } from "react-router-dom";
import ConductorCard from "../../../Affitti/ConductorCard";
import ConductorModal from "../../../Affitti/ConductorModal";
import ButtonRm from "../../../ButtonRm"
import { BankCard,BankCardItem } from "../../../BankCard";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import translationMapping from "../../../Membri/mapping";
import { BsPrinter, BsDownload } from "react-icons/bs";


const OwnersE = ({ owners, conductors, update, saveState, editMode, relationData }) => {
	const [ownersData, setOwnersData] = useState([]);
	const [conductorsData, setConductorsData] = useState([]);
	const [ownersBoosted, setOwnersBoosted] = useState([])

	const [show, setShow] = useState(false);
	const [showConductorModal, setShowConductorModal] = useState(false);
	const [backModalShow, setBackModalShow] = useState(false);
	const [tabState, setTabState] = useState(0);

	let proprietarioArray = ownersBoosted?.filter(el => el.ownerType == "proprietario") 
	let usufruttuarioArray = ownersBoosted?.filter(el => el.ownerType == "usufruttuario")
	let nudaProprietaArray = ownersBoosted?.filter(el => el.ownerType == "nudo proprietario")

	//devono diventare 4 proprietari, conduttori, nuda proprietà e usufruttuari
	const init = () => {
		setOwnersData(owners);
		setConductorsData(conductors);
		setOwnersBoosted(relationData? relationData: []);

	}

	useEffect(() => {
		init();
	}, [owners, conductors])
	
	//const [editMode, setEditMode] = useState(false);
	/* const handleSave = () => {
		setEditMode(false);
		let data;
		if (tabState == 2) {
			data = { conductors: conductorsData };
		} else data = { owners: ownersData };
		update(data);
	};

	const cancelEdit = () => {
		setBackModalShow(true);
	}; */

	//TROVA TIPO DI OWNER
	const ownerType = (id) => {
		if (tabState == 0) {
		return "proprietario";
		} else if (tabState == 1) {
		return "nudo proprietario";
		} else if (tabState == 2) {
		return "usufruttuario";
		}
	};

	const saveOwner = (id) => {
		let temp = [...ownersData];
		temp.push(realOwners[id]);
		setOwnersData(temp);

		setOwnersBoosted(prev => {
			return [...prev, {...realOwners[id], 
				parts: '',
				possessionDate: '',
				ownerType: ownerType(id), 
				procurator: 
				''}]
		})
	};

	const remove = (id) => {
		let data = [...ownersData];
		let newArray = ownersData.filter(el => el.id != id)
		setOwnersData(newArray);

		let newArr = ownersBoosted.filter(el => el.id != id)
		setOwnersBoosted(newArr)
	};

	const addSelectedConductor = async (id) => {
		console.log(conductorsData);
		let temp = [...conductorsData];
		let filteredList = temp.filter((conductor) => conductor.id === id);
		if (filteredList.length === 0) {
			temp.push(realConductors.filter((conductor) => conductor.id === id)[0]);
			setConductorsData(temp);
		}
	};

	const removeSelectedConductor = async (id) => {
		setConductorsData(
			conductorsData.filter((conductor) => conductor.id !== id)
		);
	};


	//FETCH OWNERS E CONDUCTORS DATA FROM API

	const [realOwners, setRealOwners] = useState([]);
	async function getOwners() {
		try {
			const response = await api().get(`${owner_endpoint}`);
			if (response.data) {
				setRealOwners(response.data.content);
				console.log("owner", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const [realConductors, setRealConductors] = useState([]);
	async function getConductors() {
		try {
			const response = await api().get(`${conductor_endpoint}`);
			if (response.data) {
				setRealConductors(response.data.content);
				console.log("conductor", response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getOwners();
		getConductors();
	}, []);

	//CONTROLLA SE LO STATO SAVESTATE == TRUE, IN TAL CASO INVIA I DATI, OPPURE SE CANCEL RINIZIALLA I DATI
	useEffect(() => {
		let data;
		if (tabState == 3) {
			data = { conductors: conductorsData };
		} else data = { owners: ownersData, relationData: ownersBoosted };

		if (data && saveState === true) {
			update(data);
		} else {
			init();
		}
		

	}, [saveState, editMode]);

	console.log(ownersBoosted, 'OB')

	const handleChange = (event, id) => {
		const { name, value } = event.target;
	  
		let newArr = ownersBoosted.map(el => {
		  if (el.id === id) {
			return {
			  ...el,
			  [name]: value
			};
		  }
		  return el;
		});
		
		setOwnersBoosted(newArr);
	  };

	const item = (ownersData?.length > 0 || conductorsData?.length > 0) ? [...ownersData, ...conductorsData] : []
	return (
		<div>
			<div className={`mt-2 `}>

			<div className="form-item-align-box-white d-block my-5">
			<div className={`d-flex justify-content-end align-items-center ${
							editMode === false ? "" : "d-none"
						}`}>
						<div className="d-flex mb-3">
							<ExportButton data={item} mapping={translationMapping} showPrint classes="bg-white text-dark border rounded-3 p-1 w-40 h-40"/>
						</div>
					</div>
				<div>
					<div className={`tab-state `}>
						<div className="d-flex">
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(0);
								}}>
								<label className={tabState === 0 ? "" : "non-active"}>
									Proprietari
								</label>
								<div
									className={`tab-bar ${
										tabState === 0 ? "active-tab-bar" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(1);
								}}
								style={{ marginLeft: "-5px" }}>
								<label className={tabState === 1 ? "" : "non-active"}>
									Nuda proprietà
								</label>
								<div
									className={`tab-bar ${
										tabState === 1 ? "active-tab-bar" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(2);
								}}
								style={{ marginLeft: "-10px" }}>
								<label className={tabState === 2 ? "" : "non-active"}>
									Usufruttuari
								</label>
								<div
									className={`tab-bar ${
										tabState === 2 ? "active-tab-bar" : ""
									}`}></div>
							</div>
							<div
								className="tab-container"
								onClick={(e) => {
									setTabState(3);
								}}
								style={{ marginLeft: "-10px" }}>
								<label className={tabState === 3 ? "" : "non-active"}>
										Conduttori
								</label>
								<div
									className={`tab-bar ${
										tabState === 3 ? "active-tab-bar" : ""
									}`}></div>
							</div>
						</div>
					</div>
					<div className={`mt-3 ${tabState === 0 ? "" : "d-none"}`}>
						<div className={`${editMode? "" : "d-none"}`}>
							{/* <label className="edit-subpage-title">PROPRIETARI</label> */}
							<div className="d-flex mt-3">
							<Link to="/proprietari/create?tab=proprietario">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShow(true);
									}}>Seleziona
								</ButtonRm>
							</div>
						</div>
						{proprietarioArray?.length > 0 ? (
						proprietarioArray.map((item, index) => (
							<EditableOwnerCard
								key={index}
								id={item.id}
								data={item}
								edit_mode={true}
								remove={remove}
								handleChange={handleChange}
								/>
							))
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun proprietario selezionato
								</span>
							</div>
						)}
					</div>
					<div className={`mt-3 ${tabState === 1 ? "" : "d-none"}`}>
						<div className={`${editMode? "" : "d-none"}`}>
							{/* <label className="edit-subpage-title">NUDA PROPRIETA’</label> */}
								<div className="d-flex mt-3">
								<Link to="/proprietari/create?tab=nudaProprieta">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShow(true);
									}}>Seleziona
								</ButtonRm>
								</div>
						</div>
						{nudaProprietaArray?.length > 0 ? (
						nudaProprietaArray.map((item, index) => (
							<EditableOwnerCard
							key={index}
							id={item.id}
							data={item}
							edit_mode={true}
							remove={remove}
							handleChange={handleChange}
							/>
						  ))
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun nudo proprietario selezionato
								</span>
							</div>
						)}
					</div>
					<div className={`mt-3 ${tabState === 2 ? "" : "d-none"}`}>
						<div className={`${editMode? "" : "d-none"}`}>

							<div className="d-flex mt-3">
							<Link to="/proprietari/create?tab=usufruttuario">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShow(true);
									}}>Seleziona
								</ButtonRm>
							</div>
						</div>
						{usufruttuarioArray?.length > 0 ? (
						usufruttuarioArray.map((item, index) => (
							<EditableOwnerCard
							key={index}
							id={item.id}
							data={item}
							edit_mode={true}
							remove={remove}
							handleChange={handleChange}
							/>
						  ))
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun usufruttuario selezionato
								</span>
							</div>
						)}
					</div>
					<div className={`mt-3 ${tabState === 3 ? "" : "d-none"}`}>
						<div className={`${editMode? "" : "d-none"}`}>

							<div className="d-flex mt-3">
								<Link to="/conduttori/create">
									<ButtonRm variant="tertiary">Nuovo</ButtonRm>
								</Link>
								<ButtonRm variant="tertiary ms-2" onClick={() => {
										setShowConductorModal(true);
									}}>Seleziona
								</ButtonRm>
							</div>
						</div>
						{conductorsData?.length > 0 ? (
							<div className="row mt-3 flex-column">
								{conductorsData.map((conductor, index) => (
									<div className=" mb-3"> 
										<BankCard 
										key={conductor.id}
										name={conductor.name + " " + conductor.surname}
										deleteVisible={editMode? true : false}         
										color="#a47df7" 
										deleteFunc={removeSelectedConductor} 
										index={conductor.id}>
										<BankCardItem property="INDIRIZZO" value={conductor.residenceAddress + ", " + conductor.residenceHouseNumber  }/>
										<BankCardItem property="CITTA'" value={conductor.residenceMunicipality}/>
										<BankCardItem property="CIVICO" value={conductor.residenceHouseNumber}/>

										<BankCardItem property="TELEFONO" value={conductor.cellularTelephone}/>
										{conductor.fiscalCode && <BankCardItem property="CF" value={conductor.fiscalCode}/>}
										<BankCardItem property="TIPO DI CONDUTTORE" value={conductor.typeOfConductor}/>
									</BankCard>
									</div>
								))}
							</div>
						) : (
							<div className="unit-empty-img">
								<img loading="lazy"  />
								<span className="img-comment">
									Nessun conduttore selezionato
								</span>
							</div>
						)}
					</div>
					{/* <div className="d-flex mt-2 justify-content-end">
						<div
							className="edit-btn w-144"
							onClick={() => {
								setEditMode(true);
							}}>
							Modifica
						</div>
					</div> */}
				</div>
			</div>
			<Modal
				show={show}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShow(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<OwnerModal
						table_data={realOwners}
						setShow={setShow}
						saveOwner={saveOwner}
					/>
				</Modal.Body>
			</Modal>


			<Modal
				show={showConductorModal}
				className="type-modal"
				onHide={() => {
					setShow(false);
				}}
				backdrop="static"
				size="lg">
				<Modal.Body className="p-3">
					<Button
						variant=""
						onClick={(e) => {
							setShowConductorModal(false);
						}}
						className="float-end border-0 bg-white">
						<i className="bi bi-x"></i>
					</Button>
					<ConductorModal
						table_data={
							realConductors.length > 0 &&
							realConductors.map((conductor) => [
								conductor.name,
								conductor.surname,
								conductor.residenceAddress,
								conductor.residenceHouseNumber,
								conductor.internal,
								conductor.residenceMunicipality,
								conductor.residenceProvince,
								
							])
						}
						setShow={setShowConductorModal}
						ids={realConductors.map((conductor) => conductor.id)}
						addConductor={addSelectedConductor}
						edit_mode={true}
					/>
				</Modal.Body>
			</Modal>
			</div>
		</div>
	);
};

export default OwnersE;
