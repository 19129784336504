import React from "react";

const MoblieTableCard = (props) => {
	return (
		<div className="popup-grey d-block overflow-hidden rounded-3 mt-3">
			<div className="grey-popup-header d-flex align-items-center justify-content-between p-2">
				<h3 className="fs-16 text-white fw-semibold text-uppercase">
					{props.title}
				</h3>
				<input
					type="radio"
					name="card"
					style={{
						accentColor: "var(--primary)",
						width: "15px",
						height: "15px",
					}}
					onChange={(e) => {
						props.setTableSelect(props.id);
					}}></input>
				{/* <button
          type="button"
          className="border-0 bg-transparent text-white"
          onClick={() => {
            props.closeId(-1);
          }}
        >
          <i className="fal fa-times"></i>
        </button> */}
			</div>
			<div className="table-list d-block">
				<table className="table table-borderless">
					<tbody>
						{props.table_header.map((data, index) => {
							return (
								<tr key={index}>
									<td className="text-uppercase fs-14 text-secondary px-3">
										{data}
									</td>
									<td className="text-capitalize fs-14 text-dark px-3">
										{props.table_data[index]}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default MoblieTableCard;
