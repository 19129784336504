// Funzione helper per aggiungere due righe vuote e creare un blocco di calcolo separato
const addTwoEmptyRows = (arr) => {
    arr.push({ number: "", symbol: "", nota: "" });
    arr.push({ number: "", symbol: "", nota: "" });
};


//Funzione che gestisce la separazione di blocchi di calcolo differenti, con operazioni speciali e/o operatore di risultato

export default function handleSpecialCases(inputedArray) {
    const lastElement = inputedArray[inputedArray.length - 1];
    const secondLastElement = inputedArray[inputedArray.length - 2];

    // Condizione 1: Se l'ultimo simbolo è '=' e l'elemento corrente è un numero senza simbolo
    if (inputedArray.length > 2 && secondLastElement.symbol === "=" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 2: Se il penultimo simbolo è 'sqrt' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "sqrt" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 3: Se il penultimo simbolo è 'exp' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "exp" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 4: Se il penultimo simbolo è 'Ln' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "Ln" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 5: Se il penultimo simbolo è 'log' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "log" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 6: Se il penultimo simbolo è '1/X' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "1/X" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 7: Se il penultimo simbolo è 'X^2' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "X^2" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 8: Se il penultimo simbolo è 'X^3' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "X^3" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "Y" && lastElement.symbol === "X^Y") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 9: Se il penultimo simbolo è '%' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol === "%" && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }
    // Condizione 10: Se il penultimo simbolo è '%' e l'elemento corrente è un numero senza simbolo
    else if (inputedArray.length >= 2 && secondLastElement.symbol.includes("IVA") && lastElement.symbol === "") {
        addTwoEmptyRows(inputedArray);
    }

    else if (inputedArray.length >= 2 && lastElement.symbol.includes("IVA")) {
        addTwoEmptyRows(inputedArray);
    }
}

