import React from "react";
import logo from "../../assets/images/logo-red.svg";

import "../../assets/css/RegisterSuccess.css";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import ButtonRm from "../ButtonRm";

export default function ConfirmationPageAcquisti() {

  const navigate  = useNavigate()

  const login  = () => {
    navigate("/");
  };

  return (
    <>
      <div className="register-div">
        <img loading="lazy" src={logo} alt="logo" className="register-logo"></img>
        <div style={{  display: "flex" }}>
          <div className="m-auto text-center">
            <img
              src="https://cdn4.iconfinder.com/data/icons/web-ui-color/128/Checkmark-512.png"
              width="80px"
              style={{ display: "flex", margin: "auto" }}
            ></img>
            <h3 className="mt-4">
                Pagamento Confermato!
            </h3>
            <p className="opacity-75 mb-4 px-3" style={{ maxWidth: "600px" }}>
                Se hai domande riguardo al tuo ordine o necessiti di assistenza, non esitare a contattarci a info@realmeta.it
            </p>

            <ButtonRm variant="primary" onClick={login}>Vai alla Dashboard</ButtonRm>
          </div>
        </div>
      </div>
       <Footer />
    </>
  );
}
