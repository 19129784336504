import React from "react";
import Form from "react-bootstrap/Form";
export default function CheckBoxMember({handleCheckboxChange}) {
  return (
    <Form>
    {["checkbox"].map((type) => (
      <div key={`default-${type}`} className="check-row-full">
        <Form.Check
          type={type}
          id={`Fornitore-${type}`}
          label={`Fornitore`}
          value="supplier"
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type={type}
          id={`Proprietario-${type}`}
          label={`Proprietario`}
          value="owner"
          onChange={handleCheckboxChange}
        />
        <Form.Check
          type={type}
          id={`Conduttore-${type}`}
          label={`Conduttore`}
          value="conductor"
          onChange={handleCheckboxChange}
        />
      </div>
    ))}
  </Form>
  );
}
