import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"





import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';
import { checkMaster, checkUserRole } from "../../utils/checkUserRole";
import { checkUserData , checkCollaborator} from "../../utils/checkUserRole";


import {OverlayTrigger} from "react-bootstrap";
import {Tooltip }from "react-bootstrap";




const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    sex: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	})
});

export default function Notifiche(){

    const [modalDeactivate, setModalDeactivate] = useState(false)
    const [modalType, setModalType] = useState(null)

    function showModalDeactivate(type){
        setModalDeactivate(true)
        setModalType(type)
    }

    function closeModalDeactivate(){
        setModalDeactivate(false)
        setModalType(null)
    }


    //handle gestione modal

    const [modalGestione, setModalGestione] = useState(false)

    function showModalGestione(type){
        setModalGestione(true)
    }

    function closeModalGestione(){
        setModalGestione(false)
    }


        //CHECK IF DATA REQUIRED ARE COMPILED
        const [dataRequired, setDataRequired] = useState(true);
        //Gestisce il modale
        const [showCheck, setShowCheck] = useState(false)
    
        useEffect(() => {
            const fetchData = async () => {
             try {
                 const response = await checkUserData();
                 setDataRequired(response.isVerifiedFull);
                 if (!response.isVerifiedFull) {
                   setShowCheck(true);
               }
             } catch (error) {
                 // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                 console.error('Errore durante il recupero dei dati utente:', error);
             }
         };
    
         fetchData();
     }, []);


    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Notifiche Generali  {!dataRequired && <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 50, hide: 50 }}
                                                        overlay={
                                                          <Tooltip id="tooltip" className="exclamation-tooltip">
                                                                 <p className="mb-3">Per procedere con la gestione delle notifiche, è necessario prima completare le seguenti informazioni:</p>
                                                                 <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
                                                                <p>2. <strong>Face ID</strong> </p>
                                                                <p>3. <strong>Documenti</strong> </p>
                                                          </Tooltip>
                                                        }>
                                                        <i class="bi  bi-info-circle me-2 fs-14"></i>
                                                      </OverlayTrigger>
                                                      }</p>
                    <p className="fs-14 opacity-75">Qui potrai gestire le notifiche</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                    <Col md={4} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Notifiche sul gestionale</p>
                            <p className="fs-14 text-dark opacity-75">Seleziona gli elementi per cui ti interessa ricevere notifiche sul gestionale</p>
                        </Col>
                    {/* DI DEFAULT QUELLE SU GESTIONEAL DEVONO ESSERE TUTTE ABILITATE */}
                    <Col md={8} className="ps-0 mb-3">
                        <div className="faceIdDiv text-start mb-2">
                                <p className="fs-16 fw-bold">Invia notifiche sul gestionale per:</p>
                            
                            
                               {/*  <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Aggiunto in una chat"
                                        checked={true}
                                        disabled={dataRequired ? false: true}
                                    />
                                </div> */}

                              {/*   <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Ricezione messaggi in chat"
                                        disabled={dataRequired ? false: true}
                                        checked={true}

                                    />
                                </div> */}

                               {/*  <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Ricevuto ruolo di ADMIN in chat"
                                    disabled={dataRequired ? false: true}
                                    checked={true}

                                />
                                </div>
                                <hr className="opacity-10 my-3"/> */}

                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Aggiunto all'interno di un'attività"
                                    disabled={dataRequired ? false: true}
                                    checked={true}

                                />
                            </div>

                            {checkMaster() &&
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Conferma fine attività"
                                        disabled={dataRequired ? false: true}
                                        checked={true}
                                    />
                                </div>

                            }
                            <hr className="opacity-10 my-3"/>
                            {checkUserRole() &&
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Scadenze"
                                        disabled={dataRequired ? false: true}
                                        checked={true}

                                    />
                                </div>
                            }

                            <hr className="opacity-10 my-3"/>

                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Richiesta collegamento su Real meta"
                                    disabled={dataRequired ? false: true}
                                    checked={true}

                                />
                            </div>

                            {checkUserRole() && <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Utente ha accettato il tuo invito su Real Meta"
                                    disabled={dataRequired ? false: true}
                                    checked={true}

                                />
                            </div>}
                            
                           
                            
                            {/* DOVREBBE ESSERE SEMPRE TRUE altrimenti il collaboratore non riesce a creare pagamento, senza notifica */}

                            {checkMaster() && <hr className="opacity-10 my-3"/>}
                            {checkMaster() && <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Collaboratore richiede creazione pagamento in chat"
                                    disabled={true}
                                    checked={true}

                                />
                            </div>}
                            {checkMaster() && 
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Collaboratore inoltra notifica di pagamento"
                                        disabled={true}
                                        checked={true}

                                    />
                                </div>
                            }

                            {checkCollaborator() && <hr className="opacity-10 my-3"/>}

                            {checkCollaborator() && <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Aggiunto come incaricato"
                                    disabled={dataRequired ? false: true}
                                    checked={true}

                                />
                            </div>
                            }
                        </div>
                    </Col>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-3">
                    <Col md={4} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Notifiche via email</p>
                            <p className="fs-14 text-dark opacity-75">Seleziona gli elementi per cui ti interessa ricevere notifiche via email</p>
                        </Col>

                    <Col md={8} className="ps-0 mb-3">
                        <div className="faceIdDiv text-start mb-2">
                                <p className="fs-16 fw-bold">Invia notifiche sulla email per:</p>
                            
                            
                               {/*  <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Aggiunto in una chat"
                                        disabled={dataRequired ? false: true}
                                    />
                                </div> */}

                              {/*   <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Ricezione messaggi in chat"
                                        disabled={dataRequired ? false: true}

                                    />
                                </div> */}

                               {/*  <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Ricevuto ruolo di ADMIN in chat"
                                    disabled={dataRequired ? false: true}

                                />
                                </div>
                                <hr className="opacity-10 my-3"/> */}

                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Aggiunto all'interno di un'attività"
                                    disabled={dataRequired ? false: true}

                                />
                            </div>
                            {checkMaster() &&
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Conferma fine attività"
                                        disabled={dataRequired ? false: true}
                                    />
                                </div>

                            }
                            <hr className="opacity-10 my-3"/>
                            {checkUserRole() &&
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Scadenze"
                                        disabled={dataRequired ? false: true}

                                    />
                                </div>
                            }

                            <hr className="opacity-10 my-3"/>

                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Richiesta collegamento su Real meta"
                                    disabled={dataRequired ? false: true}

                                />
                            </div>

                            {checkUserRole() && <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Utente ha accettato il tuo invito su Real Meta"
                                    disabled={dataRequired ? false: true}

                                />
                            </div>}
                            
                           
                            
                            {/* DOVREBBE ESSERE SEMPRE TRUE altrimenti il collaboratore non riesce a creare pagamento, senza notifica */}

                            {checkMaster() && <hr className="opacity-10 my-3"/>}
                            {checkMaster() && <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Collaboratore richiede creazione pagamento in chat"
                                    disabled={true}

                                />
                            </div>}
                            {checkMaster() && 
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Collaboratore inoltra notifica di pagamento"
                                        disabled={true}

                                    />
                                </div>
                            }

                            {checkCollaborator() && <hr className="opacity-10 my-3"/>}

                            {checkCollaborator() && <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Aggiunto come incaricato"
                                    disabled={dataRequired ? false: true}

                                />
                            </div>
                            }
                            
                        </div>
                    </Col>
                    <hr className="opacity-10 my-3"/>
                </Row>
                {/* DIPENDE DALLA SEZIONE S
                ELEZIONATA DURANTE LA CREAIZONE DELLA SCADENZA IN ALERT/SCADENZARIO - DEADLINE ENDPOINT */}

                { (checkUserRole()) && <>
                    <Row>
                    <p className="fs-18 text-dark fw-bold">Notifiche Scadenze Automatizzate</p>
                    <p className="fs-14 opacity-75">Creeremo delle notifiche automatizzate per alcune date di tuo interesse che potrai impostare di seguito.
Queste notifiche si ripresenteranno ogni giorno, dalla di scadenza fissata alla data dell’evento, finché non vengono marcata come “Svolte”</p>

{/* QUESTA PARTE PENSAVO DI GESTIRLA, QUANDO SI CREA UNA RATA/AFFITTO/ATTIVITA' IN CONCOMITANZA CREARE LA NOTIFICA RELATIVA (ref. sezione scadenzario) CHE ABBIA LE IMPOSTAZIONI DEFINITE DALL'UTENTE CHE ARRIVI SUL GESTIONALE/EMAIL */}
                    <hr className="opacity-10 my-3"/>
                    </Row>

                    <Row className="mt-3">
                    <Col md={4} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Affitti</p>
                            <p className="fs-14 text-dark opacity-75">Gestisci di le scadenze e notifiche per gli affitti inseriti</p>
                        </Col>

                    <Col md={8} className="ps-0 mb-3">
                        <div className="faceIdDiv text-start mb-2">
                                <p className="fs-16 fw-bold">Crea scadenze automatizzate per:</p>
                            
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Aggiornamento Istat"
                                    disabled={dataRequired ? false: true}
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Data fine periodo locazione"
                                    disabled={dataRequired ? false: true}
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Data fine rinnovo locazione"
                                    disabled={dataRequired ? false: true}
                                />
                            </div>
                            <div className="mt-3">
                                <Form.Check 
                                    type="switch"
                                    label="Data entro cui può essere inviata disdetta di locazione"
                                    disabled={dataRequired ? false: true}
                                />
                            </div>
                            <div className="mt-3">
                            <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Quanti giorni prima di una scadenza nella sezione Affitti vuoi essere avvisato ?
                                    </Form.Label>
                                    <Form.Control 
                                        // {...register("documentNumber")}
                                        type="number" 
                                        // name="documentNumber"
                                        value={7}
                                        // onChange={onChange}
                                        // isInvalid={!!errors.documentNumber}
                                        disabled={dataRequired ? false: true}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {/* {errors.documentNumber?.message} */}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                    <hr className="opacity-10 my-3"/>
                    </Row>
                

                    <Row className="mt-3">
                        <Col md={4} className="ps-0 mb-3">
                                <p className="fs-16 text-dark fw-bold">Stato avazamento lavori</p>
                                <p className="fs-14 text-dark opacity-75">Gestisci di le scadenze e notifiche per stato avanzamento lavori</p>
                            </Col>

                        <Col md={8} className="ps-0 mb-3">
                            <div className="faceIdDiv text-start mb-2">
                                    <p className="fs-16 fw-bold">Crea scadenze automatizzate per:</p>
                                
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Data inizio attività"
                                        disabled={dataRequired ? false: true}
                                    />
                                </div>
                            
                                <div className="mt-3">
                                <Form.Group>
                                        <Form.Label 
                                            className="f-label fs-16 d-flex align-items-center
mb-2"
                                        >
                                            Quanti giorni prima di una scadenza nella sezione SAL vuoi essere avvisato ?
                                        </Form.Label>
                                        <Form.Control 
                                            // {...register("documentNumber")}
                                            type="number" 
                                            // name="documentNumber"
                                            value={7}
                                            // onChange={onChange}
                                            // isInvalid={!!errors.documentNumber}
                                            disabled={dataRequired ? false: true}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {/* {errors.documentNumber?.message} */}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                        <hr className="opacity-10 my-3"/>
                    </Row>       

                    <Row className="mt-3">
                        <Col md={4} className="ps-0 mb-3">
                                <p className="fs-16 text-dark fw-bold">Rate</p>
                                <p className="fs-14 text-dark opacity-75">Gestisci di le scadenze e notifiche per le rate</p>
                            </Col>

                        <Col md={8} className="ps-0 mb-3">
                            <div className="faceIdDiv text-start mb-2">
                                    <p className="fs-16 fw-bold">Crea scadenze automatizzate per:</p>
                                
                                <div className="mt-3">
                                    <Form.Check 
                                        type="switch"
                                        label="Rate"
                                        disabled={dataRequired ? false: true}
                                    />
                                </div>
                            
                                <div className="mt-3">
                                <Form.Group>
                                        <Form.Label 
                                            className="f-label fs-16 d-flex align-items-center
mb-2"
                                        >
                                            Quanti giorni prima di una scadenza nella sezione Rate vuoi essere avvisato ?
                                        </Form.Label>
                                        <Form.Control 
                                            // {...register("documentNumber")}
                                            type="number" 
                                            // name="documentNumber"
                                            value={7}
                                            // onChange={onChange}
                                            // isInvalid={!!errors.documentNumber}
                                            disabled={dataRequired ? false: true}
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                        {/* {errors.documentNumber?.message} */}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                        <hr className="opacity-10 my-3"/>
                    </Row>   
                    </>   
                }
        
            </div >
            
        </>

        
    )
}