import { useState, useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import delete_icon from "../../../assets/images/delete.svg";
import PhoneInput from "react-phone-number-input/input";
import CustomBadge from "../../CustomBadge";


const DataE = (props) => {
	const [data, setData] = useState({});

	const init = () => {
		setData({
			municipalityCode: props.formData.municipalityCode,
			paper: props.formData.paper,
			parcel: props.formData.parcel,
			category: props.formData.category,
			classType: props.formData.classType,
			consistency: props.formData.consistency,
			cadastralIncome: props.formData.cadastralIncome,
			urbanSection: props.formData.urbanSection,
			zone: props.formData.zone,
			microZone: props.formData.microZone,
			applicantName: props.formData.applicantName,
			applicantPhone: props.formData.applicantPhone,
			applicantEmail: props.formData.applicantEmail,
			applicantAddress: props.formData.applicantAddress,
			evaluatorName: props.formData.evaluatorName,
			evaluatorPhone: props.formData.evaluatorPhone,
			evaluatorEmail: props.formData.evaluatorEmail,
			evaluatorAddress: props.formData.evaluatorAddress,
			overview: props.formData.overview,
			logo: props.formData.logo,
			note: props.formData.note,
		});
	};

	useEffect(() => {
		init();
	}, [props]);

	const onChange = (e) => {
		if (e.target.type==="checkbox")
			setData({ ...data, [e.target.name]: e.target.checked });
		else setData({ ...data, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (props.saveState===true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);

	const changeParcel = (index, e) => {
		let temp = [...data.parcel];
		if (temp.length===index) temp.push({ parcel: "", subaltern: [] });
		temp[index].parcel = e.target.value;
		let obj = { target: { name: "parcel", value: temp } };
		onChange(obj);
	};

	const changeSubaltern = (id1, id2, e) => {
		let temp = [...data.parcel];
		temp[id1].subaltern[id2] = e.target.value;
		let obj = { target: { name: "parcel", value: temp } };
		onChange(obj);
	};

	const addParcel = () => {
		let temp = [...data.parcel];
		if (temp[temp.length - 1].parcel !== "")
			temp.push({ parcel: "", subaltern: [""] });
		let obj = { target: { name: "parcel", value: temp } };
		onChange(obj);
	};

	const addSubaltern = () => {
		let temp = [...data.parcel];
		if (
			temp[temp.length - 1].subaltern[
				temp[temp.length - 1].subaltern.length - 1
			] !== ""
		)
			temp[temp.length - 1].subaltern.push("");
		let obj = { target: { name: "parcel", value: temp } };
		onChange(obj);
	};

	const deleteParcel = (id) => {
		let temp = [...data.parcel];
		temp.splice(id, 1);
		let obj = { target: { name: "parcel", value: temp } };
		onChange(obj);
	};
	const deleteSubaltern = (id1, id2) => {
		let temp = [...data.parcel];
		temp[id1].subaltern.splice(id2, 1);
		let obj = { target: { name: "parcel", value: temp } };
		onChange(obj);
	};

	const handleChange = (e) => {
		let ele = {
			target: { name: "logo", value: e.target.files[0] },
		};
		onChange(ele);
	};

	return (
		<div className="form-item-align-box d-block">
			<fieldset disabled={props.editMode ? false : true}>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">DATI CATASTALI</h1>
					</div>
				</div>
				<div className="d-flex flex-column">
					<div className="row">
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Codice comune
							</label>
							<input
								name="municipalityCode"
								value={data.municipalityCode}
								onChange={(e) => {
									onChange(e);
								}}
								type="text"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Foglio
							</label>
							<input
								name="paper"
								value={data.paper}
								onChange={(e) => {
									onChange(e);
								}}
								type="text"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
						<div className="d-flex flex-column col-sm-3 pe-2">
							<div className="d-flex align-items-center  mb-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
									Particella/Mappale
								</label>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div className="flex-auto">
									<input
										name="parcel"
										value={data.parcel?.length > 0 ? data.parcel[0].parcel : ""}
										onChange={(e) => {
											changeParcel(0, e);
										}}
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
									/>
								</div>
								<div style={{ padding: "3px" }}>
									<BsPlusCircleFill
										className="add-section-btn"
										onClick={addParcel}
									/>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column col-sm-3">
							<div className="d-flex align-items-center  mb-2">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
									Subalterno
								</label>
							</div>
							<div className="d-flex align-items-center justify-content-between">
								<div className="flex-auto">
									<input
										name="subaltern"
										value={
											data.parcel?.length > 0 ? data.parcel[0].subaltern[0] : ""
										}
										onChange={(e) => {
											changeSubaltern(0, 0, e);
										}}
										type="text"
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
									/>
								</div>
								<div style={{ padding: "3px" }}>
									<BsPlusCircleFill
										className="add-section-btn"
										onClick={addSubaltern}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{data.parcel?.length > 0 &&
					data.parcel[0].subaltern?.length > 1 &&
					data.parcel[0].subaltern.map((item, index) => {
						if (index !== 0) {
							return (
								<div key={index} className="row justify-content-end">
									<div className="d-flex flex-column col-sm-3">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Subalterno
										</label>
										<div className="d-flex align-items-center justify-content-between">
											<div className="flex-auto">
												<input
													name="subaltern"
													value={item}
													onChange={(e) => {
														changeSubaltern(0, index, e);
													}}
													type="text"
													className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
												/>
											</div>
											<div style={{ padding: "3px" }}>
												<div
													className="delete-icon-btn"
													onClick={() => {
														deleteSubaltern(0, index);
													}}>
													<img loading="lazy" src={delete_icon} alt="icon" />
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						}
					})}
				{data.parcel?.length > 1 &&
					data.parcel.map((item, index) => {
						if (index !== 0) {
							return (
								<div key={index}>
									<div className="row justify-content-end">
										<div className="d-flex flex-column col-sm-3">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Particella/Mappale
											</label>
											<div className="d-flex align-items-center justify-content-between">
												<div className="flex-auto">
													<input
														name="subaltern"
														value={item.parcel}
														onChange={(e) => {
															changeParcel(index, e);
														}}
														type="text"
														className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
													/>
												</div>
												<div style={{ padding: "3px" }}>
													<div
														className="delete-icon-btn"
														onClick={() => {
															deleteParcel(index);
														}}>
														<img loading="lazy" src={delete_icon} alt="icon" />
													</div>
												</div>
											</div>
										</div>
										<div className="d-flex flex-column col-sm-3">
											<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
												Subalterno
											</label>
											<div className="d-flex align-items-center justify-content-between">
												<div className="flex-auto">
													<input
														name="subaltern"
														value={item.subaltern[0]}
														onChange={(e) => {
															changeSubaltern(index, 0, e);
														}}
														type="text"
														className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
													/>
												</div>
												<div style={{ padding: "3px" }}>
													<div
														className="delete-icon-btn"
														onClick={() => {
															deleteSubaltern(index, 0);
														}}>
														<img loading="lazy" src={delete_icon} alt="icon" />
													</div>
												</div>
											</div>
										</div>
									</div>
									{item.subaltern?.length > 1 &&
										item.subaltern.map((element, id) => {
											if (id !== 0) {
												return (
													<div>
														<div key={id} className="row justify-content-end">
															<div className="d-flex flex-column col-sm-3">
																<label
																	htmlFor=""
																	className="f-label fs-16 d-flex align-items-center
mb-2">
																	Subalterno
																</label>
																<div className="d-flex align-items-center justify-content-between">
																	<div className="flex-auto">
																		<input
																			name="subaltern"
																			value={element}
																			onChange={(e) => {
																				changeSubaltern(index, id, e);
																			}}
																			type="text"
																			className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100"
																		/>
																	</div>
																	<div style={{ padding: "3px" }}>
																		<div
																			className="delete-icon-btn"
																			onClick={() => {
																				deleteSubaltern(index, id);
																			}}>
																			<img loading="lazy" src={delete_icon} alt="icon" />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												);
											}
										})}
								</div>
							);
						}
					})}
				<div className="row mt-3">
					<div className="d-flex flex-column col-sm-3 pe-2">
						<div className="d-flex align-items-center  mb-2">
							<label className="f-label fs-16 d-flex align-items-center
me-2">Categoria</label>
						</div>
						<input
							name="category"
							value={data.category}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Classe
						</label>
						<input
							name="classType"
							value={data.classType}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<div className="d-flex align-items-center  mb-2">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
me-2">
								Consistenza
							</label>
						</div>
						<input
							name="consistency"
							value={data.consistency}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 text-nowrap">
							Rendita Catastale
						</label>
						<input
							name="cadastralIncome"
							value={data.cadastralIncome}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
				<div className="row mt-3">
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Sezione Urbana
						</label>
						<input
							name="urbanSection"
							value={data.urbanSection}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Zona
						</label>
						<input
							name="zone"
							value={data.zone}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
					<div className="d-flex flex-column col-sm-3 pe-2">
						<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
							Microzona
						</label>
						<input
							name="microZone"
							value={data.microZone}
							onChange={(e) => {
								onChange(e);
							}}
							type="text"
							className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
						/>
					</div>
				</div>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">
							RICHIEDENTE VALUTAZIONE
						</h1>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Nome e Cognome del valutatore *
							</label>
							<input
								name="applicantName"
								value={data.applicantName}
								onChange={(e) => {
									onChange(e);
								}}
								type="text"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Telefono*
							</label>
							<PhoneInput
								name=""
								maxLength="14"
								value={data.applicantPhone}
								onChange={(value) => {
									let e = { target: { name: "applicantPhone", value: value } };
									onChange(e);
								}}
								className="tabInputField form-control w-100"
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Email*
							</label>
							<input
								name="applicantEmail"
								value={data.applicantEmail}
								onChange={(e) => {
									onChange(e);
								}}
								type="email"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Indirizzo
							</label>
							<input
								name="applicantAddress"
								value={data.applicantAddress}
								onChange={(e) => {
									onChange(e);
								}}
								type="text"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
				</div>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">VALUTATORE</h1>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Nome e Cognome del valutatore *
							</label>
							<input
								name="evaluatorName"
								value={data.evaluatorName}
								onChange={(e) => {
									onChange(e);
								}}
								type="text"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Telefono*
							</label>
							<PhoneInput
								name=""
								maxLength="14"
								value={data.evaluatorPhone}
								onChange={(value) => {
									let e = { target: { name: "evaluatorPhone", value: value } };
									onChange(e);
								}}
								className="tabInputField form-control w-100"
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Email*
							</label>
							<input
								name="evaluatorEmail"
								value={data.evaluatorEmail}
								onChange={(e) => {
									onChange(e);
								}}
								type="email"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Indirizzo
							</label>
							<input
								name="evaluatorAddress"
								value={data.evaluatorAddress}
								onChange={(e) => {
									onChange(e);
								}}
								type="text"
								className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
							/>
						</div>
					</div>
				</div>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">
							RIEPILOGO VALUTAZIONE
						</h1>
					</div>
				</div>
				<div className="d-flex flex-column mt-3">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
						Riepilogo
					</label>
					<textarea
						name="overview"
						value={data.overview}
						onChange={(e) => {
							onChange(e);
						}}
						id=""
						cols="30"
						rows="4"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
				</div>
				<div className="mt-3">
					<div className="col-md-6 d-flex justify-content-between align-items-end">
						<div className="d-flex flex-column col-sm-8">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Logo
							</label>
							<label
								className="input-label border border-secondary fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
								style={{ height: "50px" }}>
								{data.logo ? data.logo.name : ""}
							</label>
						</div>
						<div className="col-sm-3">
							<label
								htmlFor="input-file"
								className="bag-btn w-100 general-btn"
								style={{
									height: "50px",
									textAlign: "center",
									color: "white !important",
								}}>
								Carica
							</label>
							<input
								type="file"
								id="input-file"
								className="d-none"
								name="image"
								multiple={false}
								onChange={handleChange}></input>
						</div>
					</div>
				</div>
				<div className="label-heading mb-3 d-block w-100 mt-3">
					<div className="label-text rel d-inline-block px-2 title-padding">
						<h1 className="fs-16 text-white text-uppercase">
							NOTE VALUTAZIONE
						</h1>
					</div>
				</div>
				<div className="d-flex flex-column mt-3">
					<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
						Note
					</label>
					<textarea
						name="note"
						value={data.note}
						onChange={(e) => {
							onChange(e);
						}}
						id=""
						cols="30"
						rows="4"
						className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"></textarea>
				</div>
			</fieldset>
		</div>
	);
};

export default DataE;
