import '../../../assets/css/UnitàCard.css'
import Form from 'react-bootstrap/Form';
import pc from '../../../assets/images/placeholder-unit.png'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import CustomBadge from '../../CustomBadge';

function UnitàCard(props) {

    const createDate = props.creation_date?.substr(0,10)
    const editDate = props.update_date?.substr(0,10)



    return (
        <div className='unitàCard d-flex justify-content-start align-items-center text-dark'>
            <div className='d-flex align-items-center zindexSAL'>
                <div className='pe-3 d-flex justify-content-center align-items-center zindexSAL'>
                    <Form.Check
                        type='checkbox'
                        checked={props.selectedList.some(el => el.id === props.id)}
                        onChange={(e) => props.handleSelection(e, props.id, props.unit )}
                    />
                </div>

                <div className="img-circle me-3">
                    <img loading="lazy" className='img-fluid unit-img' src={props.image ? props.image : pc}/>
                </div>

                <div>
                    <p className='fs-12 text-uppercase opacity-50'>{props.type}</p>
                    <div className='d-flex'>

                    <p className='fw-bold fs-16 me-1'>{capitalizeFirstLetter(props.name)}</p>
                    {props.unit.realEstateType == "unit" ? <CustomBadge variant="custom-badge-success">Unità</CustomBadge> : <CustomBadge variant="custom-badge-alert">Stabile</CustomBadge>}
                    </div>
                    <p className='fs-12'>{props.unit.address}</p>
                    {/* <div className='d-md-flex'>
                        <p  className='fs-12 opacity-50'>Creato {props.author? `da ${props.author}` : ""} il {createDate}</p>
                        {editDate && <p  className='fs-12 opacity-50 ms-md-4'>Ultima modifica {editDate} </p>}
                    </div> */}
                </div>
            </div>

                <Link to={`${props.id}`} className="trans-div"></Link>

        </div>
    )
}



function UnitàCardMap(props) {
    return (
        <a onClick={props.handleClick} className="w-100">
            <div className='unitàCardMap d-flex align-items-center'>
                    <div className="img-circle-map me-3">
                        <img loading="lazy" className='img-fluid unit-img' src={props.image? props.image : pc }/>
                    </div>

                    <div>
                        <p className='fs-10 text-uppercase opacity-50'>{props.type}</p>
                        <p className='fw-bold fs-14'>{props.name}</p>
                        <p className='fs-10'>{props.adress}</p>
                    </div>
            </div>
        </a>
    )
}

function UnitàCardMapMobile(props) {


    return (
        <a onClick={props.handleClick}>
            <div className='unitàCardMapMobile d-flex align-items-center'>
                    <div className="img-circle-map me-3">
                        <img loading="lazy" className='img-fluid unit-img' src={props.image }/>
                    </div>

                    <div>
                        <p className='fs-10 text-uppercase opacity-50'>{props.type}</p>
                        <p className='fw-bold fs-14'>{props.name}</p>
                        <p className='fs-10'>{props.adress}</p>
                    </div>
            </div>
        </a>
    )
}


export {UnitàCard, UnitàCardMap, UnitàCardMapMobile}