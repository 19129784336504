import React, { useState, useEffect } from "react";
import "../../../assets/css/Tabs.css";
import TopHeading from "../../TopHeading";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Typology from "./Typology";
import Feature from "./Feature";
import Amenity from "./Amenity";
import Data from "./Data";
import Attachment from "./Attachment";
import Related from "./Related";
import api, {
	evaluation_endpoint,
	evaluation_endpoint_gen,
	unit_endpoint,
	evaluation_unit_endpoint,

} from "../../../api/api";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";
import { floorType } from "./Feature/options";

import { coefficientFloorMap, ageMap, statusMap, coefficientBoxAccessibilityMap, coefficientBoxLocationMap, coefficientPossessionTypeMap, coefficientMainViewMap, coefficientBrightnessMap, coefficientNoisinessMap, coefficientInternalDistributionMap, coefficientCeilingHeightMap, coefficientInternalStateMap, coefficientFinishingQualityMap, coefficientEnergeticClassMap, coefficientHeaterMap, coefficientCoveredParkingAccessibilityMap, coefficientCoveredParkingLocationMap, coefficientCoveredParkingPossessionTypeMap, coefficientUncoveredParkingAccessibilityMap, coefficientUncoveredParkingLocationMap, coefficientUncoveredParkingPossessionTypeMap, coefficientCondominialParkingPossessionTypeMap,coefficientParkingAccessibilityMap, coefficientPositionMap, coefficientFloorShopMap, coefficientShopVisibilityMap, coefficientAutorimessaTypeMap, coefficientBoxHeightMap, coefficientFloorMagMap, coefficientCapFloorMap, coefficientCapAccessMap } from "./valMapping";

import { isValidYear } from "../../../utils/dateManipulation";

const user_id = JSON.parse(localStorage.getItem('user'))?.id

//INFO
//in formdata gestisco i dati non coefficentabile (handleChange)
//in formavalues i dati coefficentabili che invio al backend come stringhe json (handleValuechange)
//onsumbit invio unico oggetto ottenuto fondendo i 2 form precedenti che gestisco separatamente
//per gestire i coefficenti uso la funzione handleCoeffChange
//per la gestione dei campi coefficentabili speciali (ovvero campi select con opzioni multiple) li gestisco tramite array specialfield e lo useffect - che mi permette anche di gestire i valori custom che l'utente impposta tramite tooltip
//i coefficienti da usare nella valutazione devono essere salvati in initialCoefficients e devono avere quel tipo di struttura

// defaultCoefficients al momento contiene coefficienti fake, dovrà contenere i coefficienti standard/o custom selezionati dall'utente (renderlo uno stato di react in cui salvare dati presi da backend )

const defaultCoefficients = {
    // Metri quadrati
    grossCoveredMqApartment: 1.2,
    basementMqApartment: 0.8,
    finishingVerandaMqApartment: 0.5,
    notFinishingVerandaMqApartment: 0.3,
    mezzanineMqApartment: 0.7,
    canopyMqApartment: 0.4,
    balconyMqApartment: 0.6,
    terraceMqApartment: 0.8,
    gardenMqApartment: 0.9,
    solarPavementMqApartment: 0.5,
    canopyFullHeightMqApartment: 0.6,
    lodgeMqApartment: 0.7,
    otherUncoveredMqApartment: 0.4,
	canopyFullHeighMqApartment: 0.3,

    // Varie
    wineryApartment: 1.0,
    garretApartment: 0.5,
    boxApartment: 0.6,
    coveredParkingSpaceApartment: 0.7,
    uncoveredParkingSpaceApartment: 0.4,
    condominialParkingSpaceApartment: 0.3,

    // Piano con ascensore
    withLiftUndergroundFloorApartment: 0.8,
    withLiftBasementFloorApartment: 0.8,
    withLiftGroundFloorApartment: 1.0,
    withLiftMezzanineFloorApartment: 1.1,
    withLift1FloorApartment: 1.2,
    withLift2FloorApartment: 1.3,
    withLift3FloorApartment: 1.4,
    withLift4FloorApartment: 1.5,
    withLift5FloorApartment: 1.6,
    withLift6FloorApartment: 1.7,
    withLift7FloorApartment: 1.8,
    withLift8FloorApartment: 1.9,
    withLift9FloorApartment: 2.0,
    withLift10FloorApartment: 2.1,
    withLiftBeyond10FloorApartment: 2.2,
    withLiftTopFloorApartment: 2.3,
    withLiftPenthouseApartment: 2.4,

    // Piano senza ascensore
    withoutLiftUndergroundFloorApartment: 0.6,
    withoutLiftBasementFloorApartment: 0.6,
    withoutLiftGroundFloorApartment: 0.8,
    withoutLiftMezzanineFloorApartment: 0.9,
    withoutLift1FloorApartment: 1.0,
    withoutLift2FloorApartment: 1.1,
    withoutLift3FloorApartment: 1.2,
    withoutLift4FloorApartment: 1.3,
    withoutLift5FloorApartment: 1.4,
    withoutLift6FloorApartment: 1.5,
    withoutLift7FloorApartment: 1.6,
    withoutLift8FloorApartment: 1.7,
    withoutLift9FloorApartment: 1.8,
    withoutLift10FloorApartment: 1.9,
    withoutBeyond10FloorApartment: 2.0,
    withoutTopFloorApartment: 2.1,
    withoutPenthouseApartment: 2.2,

    // Stato dell'edificio per anni
    oneToFiveYearsToRenovateTotallyBuildingApartment: 0.9,
    oneToFiveYearsToRenovatePartiallyBuildingApartment: 0.8,
    oneToFiveYearsHabitableBuildingApartment: 0.7,
    oneToFiveYearsExcellentBuildingApartment: 0.6,
    oneToFiveYearsNewConstructionBuildingApartment: 1.2,
    fiveToTwentyYearsToRenovateTotallyBuildingApartment: 0.8,
    fiveToTwentyYearsToRenovatePartiallyBuildingApartment: 0.7,
    fiveToTwentyYearsHabitableBuildingApartment: 0.6,
    fiveToTwentyYearsExcellentBuildingApartment: 0.5,
    fiveToTwentyYearsNewConstructionBuildingApartment: 1.1,
    twentyToFortyYearsToRenovateTotallyBuildingApartment: 0.7,
    twentyToFortyYearsToRenovatePartiallyBuildingApartment: 0.6,
    twentyToFortyYearsHabitableBuildingApartment: 0.5,
    twentyToFortyYearsExcellentBuildingApartment: 0.4,
    twentyToFortyYearsNewConstructionBuildingApartment: 1.0,
    overFortyYearsToRenovateTotallyBuildingApartment: 0.6,
    overFortyYearsToRenovatePartiallyBuildingApartment: 0.5,
    overFortyYearsHabitableBuildingApartment: 0.4,
    overFortyYearsExcellentBuildingApartment: 0.3,
    overFortyYearsNewConstructionBuildingApartment: 0.9,

    // Accessibilità e posizione del box
    veryDifficultBoxAccessibilityApartment: 0.3,
    difficultBoxAccessibilityApartment: 0.4,
    uncomfortableBoxAccessibilityApartment: 0.5,
    normalBoxAccessibilityApartment: 0.6,
    comfortableBoxAccessibilityApartment: 0.7,

    intheSameBoxLocationApartment: 0.8,
    nearBoxLocationApartment: 0.7,
    farBoxLocationApartment: 0.5,
    veryfarBoxLocationApartment: 0.4,

    // Accessibilità e posizione parcheggio coperto
    veryDifficultCoveredParkingAccessibilityApartment: 0.3,
    difficultCoveredParkingAccessibilityApartment: 0.4,
    uncomfortableCoveredParkingAccessibilityApartment: 0.5,
    normalCoveredParkingAccessibilityApartment: 0.6,
    comfortableCoveredParkingAccessibilityApartment: 0.7,

    inTheSameCoveredParkingLocationApartment: 0.8,
    nearCoveredParkingLocationApartment: 0.7,
    farCoveredParkingLocationApartment: 0.5,
    veryfarCoveredParkingLocationApartment: 0.4,

    // Accessibilità e posizione parcheggio scoperto
    veryDifficultUncoveredParkingAccessibilityApartment: 0.3,
    difficultUncoveredParkingAccessibilityApartment: 0.4,
    uncomfortableUncoveredParkingAccessibilityApartment: 0.5,
    normalUncoveredParkingAccessibilityApartment: 0.6,
    comfortableUncoveredParkingAccessibilityApartment: 0.7,

    inTheSameUncoveredParkingLocationApartment: 0.8,
    nearUncoveredParkingLocationApartment: 0.7,
    farUncoveredParkingLocationApartment: 0.5,
    veryfarUncoveredParkingLocationApartment: 0.4,

    // Vista
    externalPanoramicViewApartment: 1.0,
    externalViewApartment: 0.8,
    mixedViewApartment: 0.7,
    internalViewApartment: 0.5,
    fullInternalViewApartment: 0.4,

    // Luminosità
    brightApartment: 1.0,
    veryBrightApartment: 1.2,
    mediumBrightApartment: 0.8,
    lowBrightApartment: 0.5,

    // Rumorosità
    normalNoiseApartment: 0.6,
    veryHighNoiseApartment: 0.3,
    highNoiseApartment: 0.4,
    lowNoiseApartment: 0.7,
    veryLowNoiseApartment: 0.8,

    // Distribuzione interna
    veryEfficientDistributionApartment: 1.0,
    efficientDistributionApartment: 0.8,
    lowEfficientDistributionApartment: 0.6,
    notEfficientDistributionApartment: 0.4,

    // Altezza soffitto
    mediumCeilingHeightApartment: 0.7,
    inferiorCeilingHeightApartment: 0.5,
    superiorCeilingHeightApartment: 0.9,

    // Stato interno
    totallyRenovatedApartment: 1.0,
    partiallyRenovatedApartment: 0.8,
    habitableApartment: 0.6,
    renovatedApartment: 0.7,
    finelyRenovatedApartment: 0.9,
    newConstructionApartment: 1.1,

    // Qualità finiture
    standardFinishApartment: 0.6,
    inexpensiveFinishApartment: 0.5,
    excellentFinishApartment: 0.8,
    premiumFinishApartment: 1.0,

    // Classe energetica
    a1Apartment: 1.2,
    a2Apartment: 1.1,
    a3Apartment: 1.0,
    a4Apartment: 0.9,
    bApartment: 0.8,
    cApartment: 0.7,
    dApartment: 0.6,
    eApartment: 0.5,
    fApartment: 0.4,
    gApartment: 0.3,

    // Riscaldamento
    autonomousHeatingApartment: 1.0,
    centralizedHeatingApartment: 0.8,
    centralizedMeteringHeatingApartment: 0.6,
    absentHeatingApartment: 0.4,

	//VILLE

	grossCoveredMqVilla: 1.2,
    basementMqVilla: 1.1,
    finishingVerandaMqVilla: 1.15,
    notFinishingVerandaMqVilla: 1.17,
    mezzanineMqVilla: 1.18,
    canopyMqVilla: 1.19,
    balconyMqVilla: 1.1,
    terraceMqVilla: 1.11,
    canopyFullHeightMqVilla: 0.1,
    gardenMqVilla: 1.12,
    solarPavementMqVilla: 1.15,
    lodgeMqVilla: 1.12,
    otherUncoveredMqVilla: 1.13,
	canopyFullHeighMqVilla:1.2,

    // Varie
    wineryVilla: 1.14,
    garretVilla: 1.15,
    boxVilla: 1.16,
    coveredParkingSpaceVilla: 1.17,
    uncoveredParkingSpaceVilla: 1.18,
    condominialParkingSpaceVilla: 1.19,

    // Piano con ascensore
    withLiftUndergroundFloorVilla: 1.11,
    withLiftBasementFloorVilla: 1.12,
    withLiftGroundFloorVilla: 1.13,
    withLiftMezzanineFloorVilla: 1.14,
    withLift1FloorVilla: 1.15,
    withLift2FloorVilla: 1.16,
    withLift3FloorVilla: 1.17,
    withLift4FloorVilla: 1.18,
    withLift5FloorVilla: 1.19,
    withLift6FloorVilla: 1.1,
    withLift7FloorVilla: 1.11,
    withLift8FloorVilla: 1.12,
    withLift9FloorVilla: 1.13,
    withLift10FloorVilla: 1.14,
    withLiftBeyond10FloorVilla: 1.15,
    withLiftTopFloorVilla: 1.16,
    withLiftPenthouseVilla: 1.17,

    // Piano senza ascensore
    withoutLiftUndergroundFloorVilla: 1.2,
    withoutLiftBasementFloorVilla: 1.21,
    withoutLiftGroundFloorVilla: 1.22,
    withoutLiftMezzanineFloorVilla: 1.23,
    withoutLift1FloorVilla: 1.24,
    withoutLift2FloorVilla: 1.25,
    withoutLift3FloorVilla: 1.26,
    withoutLift4FloorVilla: 1.27,
    withoutLift5FloorVilla: 1.28,
    withoutLift6FloorVilla: 1.29,
    withoutLift7FloorVilla: 1.3,
    withoutLift8FloorVilla: 1.31,
    withoutLift9FloorVilla: 1.32,
    withoutLift10FloorVilla: 1.33,
    withoutLiftBeyond10FloorVilla: 1.34,
    withoutLiftTopFloorVilla: 1.35,
    withoutLiftPenthouseVilla: 1.36,

    // Stato dell'edificio per anni
    years1To5ToRenovateTotallyBuildingVilla: 1.15,
    years1To5ToRenovatePartiallyBuildingVilla: 1.14,
    years1To5HabitableBuildingVilla: 1.13,
    years1To5ExcellentBuildingVilla: 1.12,
    years1To5NewConstructionBuildingVilla: 1.11,
    years5To20ToRenovateTotallyBuildingVilla: 1.25,
    years5To20ToRenovatePartiallyBuildingVilla: 1.24,
    years5To20HabitableBuildingVilla: 1.23,
    years5To20ExcellentBuildingVilla: 1.22,
    years5To20NewConstructionBuildingVilla: 1.21,
    years20To40ToRenovateTotallyBuildingVilla: 1.35,
    years20To40ToRenovatePartiallyBuildingVilla: 1.34,
    years20To40HabitableBuildingVilla: 1.33,
    years20To40ExcellentBuildingVilla: 1.32,
    years20To40NewConstructionBuildingVilla: 1.31,
    over40YearsToRenovateTotallyBuildingVilla: 1.45,
    over40YearsToRenovatePartiallyBuildingVilla: 1.44,
    over40YearsHabitableBuildingVilla: 1.43,
    over40YearsExcellentBuildingVilla: 1.42,
    over40YearsNewConstructionBuildingVilla: 1.41,

    // Accessibilità e posizione del box
    veryDifficultBoxAccessibilityVilla: 0.3,
    difficultBoxAccessibilityVilla: 0.4,
    uncomfortableBoxAccessibilityVilla: 0.5,
    normalBoxAccessibilityVilla: 0.6,
    comfortableBoxAccessibilityVilla: 0.7,

    inTheSameBoxLocationVilla: 0.8,
    nearBoxLocationVilla: 0.7,
    farBoxLocationVilla: 0.5,
    veryfarBoxLocationVilla: 0.4,

    // Accessibilità e posizione parcheggio coperto
    veryDifficultCoveredParkingAccessibilityVilla: -0.1,
    difficultCoveredParkingAccessibilityVilla: -0.5,
    uncomfortableCoveredParkingAccessibilityVilla: -0.3,
    normalCoveredParkingAccessibilityVilla: 0.0,
    comfortableCoveredParkingAccessibilityVilla: 0.05,

    inTheSameCoveredParkingLocationVilla: 1.0,
    nearCoveredParkingLocationVilla: 2.0,
    farCoveredParkingLocationVilla: 3.0,
    veryfarCoveredParkingLocationVilla: 4.0,

    // Accessibilità e posizione parcheggio scoperto
    veryDifficultUncoveredParkingAccessibilityVilla: -0.1,
    difficultUncoveredParkingAccessibilityVilla: -0.5,
    uncomfortableUncoveredParkingAccessibilityVilla: -0.3,
    normalUncoveredParkingAccessibilityVilla: 0.0,
    comfortableUncoveredParkingAccessibilityVilla: 0.05,

    inTheSameUncoveredParkingLocationVilla: 1.0,
    nearUncoveredParkingLocationVilla: 2.0,
    farUncoveredParkingLocationVilla: 3.0,
    veryfarUncoveredParkingLocationVilla: 4.0,

    // Vista
    externalPanoramicViewVilla: 1.0,
    externalViewVilla: 0.8,
    mixedViewVilla: 0.5,
    internalViewVilla: 0.2,
    fullInternalViewVilla: 0.1,

    // Luminosità
    brightVilla: 0.05,
    veryBrightVilla: 0.1,
    mediumBrightVilla: 0.0,
    lowBrightVilla: -0.05,

    // Rumorosità
    normalNoiseVilla: 0.0,
    veryHighNoiseVilla: -0.2,
    highNoiseVilla: -0.1,
    lowNoiseVilla: 0.05,
    veryLowNoiseVilla: 0.1,

    // Distribuzione interna
    veryEfficientDistributionVilla: 0.1,
    efficientDistributionVilla: 0.05,
    lowEfficientDistributionVilla: 0.0,
    notEfficientDistributionVilla: -0.05,

    // Altezza soffitto
    mediumCeilingHeightVilla: 0.0,
    inferiorCeilingHeightVilla: -0.05,
    superiorCeilingHeightVilla: 0.05,

    // Stato interno
    totallyRenovatedVilla: 0.2,
    partiallyRenovatedVilla: 0.1,
    habitableVilla: 0.0,
    renovatedVilla: 0.05,
    finelyRenovatedVilla: 0.1,
    newConstructionVilla: 0.15,

    // Qualità finiture
    standardFinishVilla: 0.0,
    inexpensiveFinishVilla: -0.1,
    excellentFinishVilla: 0.05,
    premiumFinishVilla: 0.1,

    // Classe energetica
    a1Villa: 0.2,
    a2Villa: 0.15,
    a3Villa: 0.1,
    a4Villa: 0.05,
    bVilla: 0.0,
    cVilla: -0.05,
    dVilla: -0.1,
    eVilla: -0.15,
    fVilla: -0.2,
    gVilla: -0.25,

    // Riscaldamento
    autonomousHeatingVilla: 0.1,
    centralizedHeatingVilla: 0.2,
    centralizedMeteringHeatingVilla: 0.3,
    absentHeatingVilla: -0.1,

	//NEGOZI
	grossCoveredMqShops: 0.5,
    balconyMqShops: 0.2,
    terraceMqShops: 0.3,
    gardenMqShops: 0.4,
    lodgeMqShops: 0.1,
    solarPavementMqShops: 0.15,
    otherUncoveredMqShops: 0.25,
    basementDownShops: 0.1,
    groundFloorShops: 0.2,
    mezzanineFloorFrom1To3Shops: 0.3,
    floorAboveThe3rdShops: 0.4,
    particularlyFavorableToTradeShops: 0.25,
    favorableToTradeShops: 0.5,
    ordinaryShops: 0.75,
    unfavorableToTradeShops: 1.0,
    coveredParkingShops: 0.3,
    uncoveredParkingShops: 0.4,
    mediumCeilingHeightShops: 0.0,
    inferiorCeilingHeightShops: -0.05,
    superiorCeilingHeightShops: 0.05,
    totallyRenovatedShops: 0.2,
    partiallyRenovatedShops: 0.1,
    habitableShops: 0.0,
    renovatedShops: 0.05,
    finelyRenovatedShops: 0.1,
    newConstructionShops: 0.15,
    a1Shops: 0.2,
    a2Shops: 0.15,
    a3Shops: 0.1,
    a4Shops: 0.05,
    bShops: 0.0,
    cShops: -0.05,
    dShops: -0.1,
    eShops: -0.15,
    fShops: -0.2,
    gShops: -0.25,
    autonomousHeatingShops: 0.1,
    centralizedHeatingShops: 0.2,
    centralizedMeteringHeatingShops: 0.3,
    absentHeatingShops: -0.1,
    years1To5ToRenovateTotallyBuildingShops: 0.4,
    years1To5ToRenovatePartiallyBuildingShops: 0.35,
    years1To5HabitableBuildingShops: 0.3,
    years1To5ExcellentBuildingShops: 0.25,
    years1To5NewConstructionBuildingShops: 0.2,
    years5To20ToRenovateTotallyBuildingShops: 0.5,
    years5To20ToRenovatePartiallyBuildingShops: 0.45,
    years5To20HabitableBuildingShops: 0.4,
    years5To20ExcellentBuildingShops: 0.35,
    years5To20NewConstructionBuildingShops: 0.3,
    years20To40ToRenovateTotallyBuildingShops: 0.6,
    years20To40ToRenovatePartiallyBuildingShops: 0.55,
    years20To40HabitableBuildingShops: 0.5,
    years20To40ExcellentBuildingShops: 0.45,
    years20To40NewConstructionBuildingShops: 0.4,
    over40YearsToRenovateTotallyBuildingShops: 0.7,
    over40YearsToRenovatePartiallyBuildingShops: 0.65,
    over40YearsHabitableBuildingShops: 0.6,
    over40YearsExcellentBuildingShops: 0.55,
    over40YearsNewConstructionBuildingShops: 0.5,

	//UFFICI
	grossCoveredMqOffice: 0.6,
    balconyMqOffice: 0.3,
    terraceMqOffice: 0.4,
    gardenMqOffice: 0.35,
    lodgeMqOffice: 0.2,
    solarPavementMqOffice: 0.25,
    otherUncoveredMqOffice: 0.3,
    wineryOffice: 0.1,
    garretOffice: 0.1,
    easyParkingAccessibilityOffice: 0.3,
    mediumParkingAccessibilityOffice: 0.2,
    hardParkingAccessibilityOffice: 0.1,
    veryHardParkingAccessibilityOffice: 0.05,
    uncoveredParkingOffice: 0.3,
    coveredParkingOffice: 0.4,
    boxOffice: 0.2,
    withUndergroundFloorOffice: 0.4,
    withBasementFloorOffice: 0.35,
    withGroundFloorOffice: 0.3,
    withMezzanineFloorOffice: 0.25,
    with1FloorOffice: 0.2,
    with2FloorOffice: 0.15,
    with3FloorOffice: 0.1,
    with4FloorOffice: 0.1,
    with5FloorOffice: 0.1,
    with6FloorOffice: 0.05,
    with7FloorOffice: 0.05,
    with8FloorOffice: 0.05,
    with9FloorOffice: 0.05,
    with10FloorOffice: 0.05,
    withBeyond10FloorOffice: 0.05,
    withTopFloorOffice: 0.2,
    withPenthouseOffice: 0.15,
    withoutUndergroundFloorOffice: 0.3,
    withoutBasementFloorOffice: 0.25,
    withoutGroundFloorOffice: 0.2,
    withoutMezzanineFloorOffice: 0.15,
    without1FloorOffice: 0.1,
    without2FloorOffice: 0.1,
    without3FloorOffice: 0.1,
    without4FloorOffice: 0.1,
    without5FloorOffice: 0.1,
    without6FloorOffice: 0.05,
    without7FloorOffice: 0.05,
    without8FloorOffice: 0.05,
    without9FloorOffice: 0.05,
    without10FloorOffice: 0.05,
    withoutBeyond10FloorOffice: 0.05,
    withoutTopFloorOffice: 0.1,
    withoutPenthouseOffice: 0.1,
    liftOffice: 0.3,
    totallyRenovatedOffice: 0.2,
    partiallyRenovatedOffice: 0.1,
    habitableOffice: 0.0,
    renovatedOffice: 0.05,
    finelyRenovatedOffice: 0.1,
    newConstructionOffice: 0.15,
    a1Office: 0.2,
    a2Office: 0.15,
    a3Office: 0.1,
    a4Office: 0.05,
    bOffice: 0.0,
    cOffice: -0.05,
    dOffice: -0.1,
    eOffice: -0.15,
    fOffice: -0.2,
    gOffice: -0.25,
    autonomousHeatingOffice: 0.1,
    centralizedHeatingOffice: 0.2,
    centralizedMeteringHeatingOffice: 0.3,
    absentHeatingOffice: -0.1,
    centralPositionOffice: 0.4,
    intermediatePositionOffice: 0.3,
    secondaryPositionOffice: 0.2,
    years1To5ToRenovateTotallyBuildingOffice: 0.4,
    years1To5ToRenovatePartiallyBuildingOffice: 0.35,
    years1To5HabitableBuildingOffice: 0.3,
    years1To5ExcellentBuildingOffice: 0.25,
    years1To5NewConstructionBuildingOffice: 0.2,
    years5To20ToRenovateTotallyBuildingOffice: 0.5,
    years5To20ToRenovatePartiallyBuildingOffice: 0.45,
    years5To20HabitableBuildingOffice: 0.4,
    years5To20ExcellentBuildingOffice: 0.35,
    years5To20NewConstructionBuildingOffice: 0.3,
    years20To40ToRenovateTotallyBuildingOffice: 0.6,
    years20To40ToRenovatePartiallyBuildingOffice: 0.55,
    years20To40HabitableBuildingOffice: 0.5,
    years20To40ExcellentBuildingOffice: 0.45,
    years20To40NewConstructionBuildingOffice: 0.4,
    over40YearsToRenovateTotallyBuildingOffice: 0.7,
    over40YearsToRenovatePartiallyBuildingOffice: 0.65,
    over40YearsHabitableBuildingOffice: 0.6,
    over40YearsExcellentBuildingOffice: 0.55,
    over40YearsNewConstructionBuildingOffice: 0.5,

	grossCoveredMqBox: 0.5,
    outsideTheGarageBox: 0.7,
    insideGarageBox: 0.6,
    averageHeightBox: 0.8,
    averageAboveBox: 0.4,
    easyParkingAccessibilityBox: 0.9,
    mediumParkingAccessibilityBox: 0.6,
    hardParkingAccessibilityBox: 0.3,
    veryHardParkingAccessibilityBox: 0.1,
	concessionPossessionBox: 0.1,
	propertyPossessionBox:1,

    grossCoveredMqCoveredParking: 0.6,
    easyParkingAccessibilityCoveredParking: 0.8,
    mediumParkingAccessibilityCoveredParking: 0.5,
    hardParkingAccessibilityCoveredParking: 0.2,
    veryHardParkingAccessibilityCoveredParking: 0.1,
	concessionPossessionCoveredParking: 0.1,
	propertyPossessionCoveredParking:1,


    grossCoveredMqUncoveredParking: 0.7,
    easyParkingAccessibilityUncoveredParking: 0.9,
    mediumParkingAccessibilityUncoveredParking: 0.6,
    hardParkingAccessibilityUncoveredParking: 0.3,
    veryHardParkingAccessibilityUncoveredParking: 0.2,
	concessionPossessionUncoveredParking: 0.1,
	propertyPossessionUncoveredParking:1,

    grossCoveredMqLaboratories: 0.5,
    groundFloorWithAccessLaboratories: 0.7,
    groundFloorWithoutAccessLaboratories: 0.3,
    basementWithAccessLaboratories: 0.4,
    basementWithoutAccessLaboratories: 0.2,
    years1To5ToRenovateTotallyBuildingLaboratories: 0.9,
    years1To5ToRenovatePartiallyBuildingLaboratories: 0.7,
    years1To5HabitableBuildingLaboratories: 0.5,
    years1To5ExcellentBuildingLaboratories: 0.4,
    years1To5NewConstructionBuildingLaboratories: 0.6,
    years5To20ToRenovateTotallyBuildingLaboratories: 0.8,
    years5To20ToRenovatePartiallyBuildingLaboratories: 0.6,
    years5To20HabitableBuildingLaboratories: 0.5,
    years5To20ExcellentBuildingLaboratories: 0.4,
    years5To20NewConstructionBuildingLaboratories: 0.7,
    years20To40ToRenovateTotallyBuildingLaboratories: 0.8,
    years20To40ToRenovatePartiallyBuildingLaboratories: 0.6,
    years20To40HabitableBuildingLaboratories: 0.5,
    years20To40ExcellentBuildingLaboratories: 0.4,
    years20To40NewConstructionBuildingLaboratories: 0.7,
    over40YearsToRenovateTotallyBuildingLaboratories: 0.8,
    over40YearsToRenovatePartiallyBuildingLaboratories: 0.6,
    over40YearsHabitableBuildingLaboratories: 0.5,
    over40YearsExcellentBuildingLaboratories: 0.4,
    over40YearsNewConstructionBuildingLaboratories: 0.7,

    grossCoveredMqStore: 0.6,
    groundFloorWithAccessStore: 0.7,
    groundFloorWithoutAccessStore: 0.3,
    basementWithAccessStore: 0.4,
    basementWithoutAccessStore: 0.2,
    years1To5ToRenovateTotallyBuildingStore: 0.9,
    years1To5ToRenovatePartiallyBuildingStore: 0.7,
    years1To5HabitableBuildingStore: 0.5,
    years1To5ExcellentBuildingStore: 0.4,
    years1To5NewConstructionBuildingStore: 0.6,
    years5To20ToRenovateTotallyBuildingStore: 0.8,
    years5To20ToRenovatePartiallyBuildingStore: 0.6,
    years5To20HabitableBuildingStore: 0.5,
    years5To20ExcellentBuildingStore: 0.4,
    years5To20NewConstructionBuildingStore: 0.7,
    years20To40ToRenovateTotallyBuildingStore: 0.8,
    years20To40ToRenovatePartiallyBuildingStore: 0.6,
    years20To40HabitableBuildingStore: 0.5,
    years20To40ExcellentBuildingStore: 0.4,
    years20To40NewConstructionBuildingStore: 0.7,
    over40YearsToRenovateTotallyBuildingStore: 0.8,
    over40YearsToRenovatePartiallyBuildingStore: 0.6,
    over40YearsHabitableBuildingStore: 0.5,
    over40YearsExcellentBuildingStore: 0.4,
    over40YearsNewConstructionBuildingStore: 0.7,

    grossCoveredMqSheds: 0.6,
    groundFloorSheds: 0.7,
    mezzanineSheds: 0.3,
    articulatedVehicleAccessSheds: 0.8,
    withoutArticulatedVehicleAccessSheds: 0.2,
    years1To5ToRenovateTotallyBuildingSheds: 0.9,
    years1To5ToRenovatePartiallyBuildingSheds: 0.7,
    years1To5HabitableBuildingSheds: 0.5,
    years1To5ExcellentBuildingSheds: 0.4,
    years1To5NewConstructionBuildingSheds: 0.6,
    years5To20ToRenovateTotallyBuildingSheds: 0.8,
    years5To20ToRenovatePartiallyBuildingSheds: 0.6,
    years5To20HabitableBuildingSheds: 0.5,
    years5To20ExcellentBuildingSheds: 0.4,
    years5To20NewConstructionBuildingSheds: 0.7,
    years20To40ToRenovateTotallyBuildingSheds: 0.8,
    years20To40ToRenovatePartiallyBuildingSheds: 0.6,
    years20To40HabitableBuildingSheds: 0.5,
    years20To40ExcellentBuildingSheds: 0.4,
    years20To40NewConstructionBuildingSheds: 0.7,
    over40YearsToRenovateTotallyBuildingSheds: 0.8,
    over40YearsToRenovatePartiallyBuildingSheds: 0.6,
    over40YearsHabitableBuildingSheds: 0.5,
    over40YearsExcellentBuildingSheds: 0.4,
    over40YearsNewConstructionBuildingSheds: 0.7,

    grossCoveredMqIndustrialSheds: 0.6,
    groundFloorIndustrialSheds: 0.7,
    mezzanineIndustrialSheds: 0.3,
    articulatedVehicleAccessIndustrialSheds: 0.8,
    withoutArticulatedVehicleAccessIndustrialSheds: 0.2,
    years1To5ToRenovateTotallyBuildingIndustrialSheds: 0.9,
    years1To5ToRenovatePartiallyBuildingIndustrialSheds: 0.7,
    years1To5HabitableBuildingIndustrialSheds: 0.5,
    years1To5ExcellentBuildingIndustrialSheds: 0.4,
    years1To5NewConstructionBuildingIndustrialSheds: 0.6,
    years5To20ToRenovateTotallyBuildingIndustrialSheds: 0.8,
    years5To20ToRenovatePartiallyBuildingIndustrialSheds: 0.6,
    years5To20HabitableBuildingIndustrialSheds: 0.5,
    years5To20ExcellentBuildingIndustrialSheds: 0.4,
    years5To20NewConstructionBuildingIndustrialSheds: 0.7,
    years20To40ToRenovateTotallyBuildingIndustrialSheds: 0.8,
    years20To40ToRenovatePartiallyBuildingIndustrialSheds: 0.6,
    years20To40HabitableBuildingIndustrialSheds: 0.5,
    years20To40ExcellentBuildingIndustrialSheds: 0.4,
    years20To40NewConstructionBuildingIndustrialSheds: 0.7,
    over40YearsToRenovateTotallyBuildingIndustrialSheds: 0.8,
    over40YearsToRenovatePartiallyBuildingIndustrialSheds: 0.6,
    over40YearsHabitableBuildingIndustrialSheds: 0.5,
    over40YearsExcellentBuildingIndustrialSheds: 0.4,
    over40YearsNewConstructionBuildingIndustrialSheds: 0.7
};


const CreateEvaluation = () => {
	const [units, setUnits] = useState([]);
	const [unitsIndex, setUnitsIndex] = useState(-1);
	useEffect(() => {
		if (unitsIndex !== -1) {
			const unitsData = {
				province: units[unitsIndex].province,//
			municipality: units[unitsIndex].municipality,//
			municipalityCode: units[unitsIndex].municipalityCode || "",//
			paper: units[unitsIndex].sheet || "",//
			address: units[unitsIndex].address || "",//
			category: units[unitsIndex].categoryLandRegistry || "",//
			classType: units[unitsIndex].classLandRegistry || "",
			consistency: units[unitsIndex].consistence || "",
			cadastralIncome: units[unitsIndex].cadastralIncome || "",
			urbanSection: units[unitsIndex].urbanSection || "",
			zone: units[unitsIndex].zone || "B01", // mettere campo per inserire info manuale
			microZone: units[unitsIndex].microZone || "B01",
			grossCoveredMq: units[unitsIndex]?.grossCoveredMq || 0,
			basementMq: units[unitsIndex]?.basementMq || 0,
			finishingVerandaMq: units[unitsIndex]?.finishingVerandaMq || 0,
			notFinishingVerandaMq: units[unitsIndex]?.notFinishingVerandaMq || 0,
			mezzanineMq: units[unitsIndex]?.mezzanineMq || 0,
			canopyMq: units[unitsIndex]?.canopyMq || 0,
			canopyFullHeightMq: units[unitsIndex]?.canopyMq || 0,
			balconyMq: units[unitsIndex]?.balconyMq || 0,
			terraceMq: units[unitsIndex]?.terraceMq || 0,
			gardenMq: units[unitsIndex]?.gardenMq || 0,
			lodgeMq: units[unitsIndex]?.lodgeMq || 0,
			solarPavementMq: units[unitsIndex]?.solarPavementMq || 0,
			otherUncoveredMq: units[unitsIndex]?.otherUncoveredMq || 0,
			courtyardAreaMq: units[unitsIndex]?.courtyardAreaMq || 0,
			attachedLandMq: units[unitsIndex]?.attachedLandMq || 0,
			uncoveredMq: units[unitsIndex]?.uncoveredMq || 0,
			wineryMq: units[unitsIndex]?.wineryMq || 0,
			garretMq: units[unitsIndex]?.garretMq || 0,
			winery: units[unitsIndex]?.winery || "assente",
			garret: units[unitsIndex]?.garret || "assente",
			box: units[unitsIndex]?.box || "assente",
			boxAccessibility: units[unitsIndex]?.boxAccessibility || "-",
			boxLocation: units[unitsIndex]?.boxLocation || "-",
			coveredParkingSpace: units[unitsIndex]?.coveredParkingSpace || "-",
			coveredParkingAccessibility:
			units[unitsIndex]?.coveredParkingAccessibility || "nella media di zona",
			coveredParkingLocation: units[unitsIndex]?.coveredParkingLocation || "-",
			uncoveredParkingSpace: units[unitsIndex]?.uncoveredParkingSpace || "-",
			uncoveredParkingAccessibility:
			units[unitsIndex]?.uncoveredParkingAccessibility || "nella media di zona",
			uncoveredParkingLocation: units[unitsIndex]?.uncoveredParkingLocation || "-",
			condominialParkingSpace: units[unitsIndex]?.condominialParkingSpace || "-",
			condominialParkingType: units[unitsIndex]?.condominialParkingType || "-",
			parkingAccessibility: units[unitsIndex]?.parkingAccessibility || "nella media di zona",
			boxType: units[unitsIndex]?.boxType || "",
			floor: units[unitsIndex]?.floor || "piano terra",
			lastFloor: units[unitsIndex]?.lastFloor || false,
			lift: units[unitsIndex]?.lift || false,
			mainFloor: units[unitsIndex]?.mainFloor || "piano terra",
			numberExposition: units[unitsIndex]?.numberExposition || "",
			mainExposition: units[unitsIndex]?.mainExposition || "",
			mainView: units[unitsIndex]?.mainView || "",
			brightness: units[unitsIndex]?.brightness || "luminoso",
			noisiness: units[unitsIndex]?.noisiness || "normale",
			ceilingHeight: units[unitsIndex]?.ceilingHeight || "nella media di zona",
			internalLevels: units[unitsIndex]?.internalLevels || "",
			internalDistribution: units[unitsIndex]?.internalDistribution || "distribuzione efficiente",
			coveredMqRoomsRatio: units[unitsIndex]?.coveredMqRoomsRatio || "",
			coveredMqBathsRatio: units[unitsIndex]?.coveredMqBathsRatio || "",
			internalState: units[unitsIndex]?.internalState || "buono/abitabile",
			finishingQuality: units[unitsIndex]?.finishingQuality || "standard",
			heatingType: units[unitsIndex]?.heatingType || "assente",
			energeticClass: units[unitsIndex]?.energeticClass || "D"
		}
	
			setFormData({ ...formData, ...unitsData });
		}
	}, [unitsIndex]);



	async function getUnits() {
		try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				setUnits(response.data.content);
				console.log(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getUnits();
	}, []);

	//gestisce valori non coefficentabili
	const [formData, setFormData] = useState(() => {
		const initialValue = JSON.parse(localStorage.getItem("evaluation"));
		return {
			unitName: initialValue?.unitName || "",//
			typology: initialValue?.typology || "Appartamenti",//
			identifier: initialValue?.identifier || "",//
			province: initialValue?.province || "",//
			municipality: initialValue?.municipality || "",//
			zone: initialValue?.zone || "",//
			address: initialValue?.address || "",//
canopiesMq: units[unitsIndex]?.canopiesMq || 0,
courtyardAreaMq: units[unitsIndex]?.courtyardAreaMq || 0,
				attachedLandMq: units[unitsIndex]?.attachedLandMq || 0,
				uncoveredMq: units[unitsIndex]?.uncoveredMq || 0,


				  wineryMq: units[unitsIndex]?.wineryMq || 0,
				garretMq: units[unitsIndex]?.garretMq || 0,

			boxMq: '',


			condominialParkingType: initialValue?.condominialParkingType || "-",//
			parkingAccessibility: initialValue?.parkingAccessibility || "nella media di zona",//
			boxType: initialValue?.boxType || "",//
			lastFloor: initialValue?.lastFloor || false, //non serve
			lift: initialValue?.lift || false, //
			mainFloor: initialValue?.mainFloor || "", //
			mainFaces: initialValue?.mainFaces || "-" , 
			context: initialValue?.context || "", //

			numberExposition: initialValue?.numberExposition || "",
			mainExposition: initialValue?.mainExposition || "", //verificare se esite
			internalLevels: initialValue?.internalLevels || "disposto completamente su 1 livello",//
	  
			coveredMqRoomsRatio: initialValue?.coveredMqRoomsRatio || "adeguato",//
			coveredMqBathsRatio: initialValue?.coveredMqBathsRatio || "adeguato",
			buildingLocation: initialValue?.buildingLocation || "complesso residenziale (con servizi e/o verde in comune)",//
			buildingTypology: initialValue?.buildingTypology || "edificio civile",//
			totalFloorNumber: initialValue?.totalFloorNumber || "-",
			buildingType: initialValue?.buildingType || "edificio civile",
			marketRef: initialValue?.marketRef || "libero mercato",//
			bareOwnership: initialValue?.bareOwnership || "intera proprietà",//
			estateType: initialValue?.estateType || "",
			rentalState: initialValue?.rentalState || "abitazioni libere",//
			leaseProfitability: initialValue?.leaseProfitability || "non locato",//
			windowPosition: initialValue?.windowPosition || "fronte strada in linea",
			commercialVisibility: initialValue?.commercialVisibility || "nella media di zona",
			entranceWidth: initialValue?.entranceWidth || "-",
			carCapacity: initialValue?.carCapacity || "-",
			motorcycleCapacity: initialValue?.motorcycleCapacity || "-",
			buildingYear: initialValue?.buildingYear || 0,
			underbeamHeight: initialValue?.underbeamHeight || "-",
			supportingStructureType: initialValue?.supportingStructureType || "struttura in muratura ordinaria",
			mainLevelCeilingHeight: initialValue?.mainLevelCeilingHeight || "standard 6/8 metri",
			locationVisibility: initialValue?.locationVisibility || "strada intermedia",
			entryType: initialValue?.entryType || "esterno fronte strada (senzo passo carrabile)",
			labEntryType: initialValue?.labEntryType || "esterno fronte strada (senzo passo carrabile)",
			description: initialValue?.description || "",
			ceramic: initialValue?.ceramic || false,
			cotto: initialValue?.cotto || false,
			grit: initialValue?.grit || false,
			gres: initialValue?.gres || false,
			clayTiles: initialValue?.clayTiles || false,
			tiles: initialValue?.tiles || false,
			marble: initialValue?.marble || false,
			vinylMaterial: initialValue?.vinylMaterial || false,
			singleFired: initialValue?.singleFired || false,
			moquette: initialValue?.moquette || false,
			parquet: initialValue?.parquet || false,
			resin: initialValue?.resin || false,
			venetian: initialValue?.venetian || false,
			decoratedVenetianBlind: initialValue?.decoratedVenetianBlind || false,
			railings: initialValue?.railings || false,
			aluminumFixtures: initialValue?.aluminumFixtures || false,
			aluminumWoodFixtures: initialValue?.aluminumWoodFixtures || false,
			woodFixtures: initialValue?.woodFixtures || false,
			pvcFixtures: initialValue?.pvcFixtures || false,
			aluminumShutter: initialValue?.aluminumShutter || false,
			woodShutter: initialValue?.woodShutter || false,
			securityDoor: initialValue?.securityDoor || false,
			electricShutters: initialValue?.electricShutters || false,
			aluminumBlinds: initialValue?.aluminumBlinds || false,
			ironcladBlinds: initialValue?.ironcladBlinds || false,
			woodBlinds: initialValue?.woodBlinds || false,
			plasticBlinds: initialValue?.plasticBlinds || false,
			pvcBlinds: initialValue?.pvcBlinds || false,
			armoredGlass: initialValue?.armoredGlass || false,
			doubleGlasses: initialValue?.doubleGlasses || false,
			tripleGlasses: initialValue?.tripleGlasses || false,
			mosquitoNets: initialValue?.mosquitoNets || false,
			airConditioning: initialValue?.airConditioning || false,
			gasBoiler: initialValue?.gasBoiler || false,
			safe: initialValue?.safe || false,
			armoredCaveau: initialValue?.armoredCaveau || false,
			saunaShower: initialValue?.saunaShower || false,
			opticFiber: initialValue?.opticFiber || false,
			photovoltaic: initialValue?.photovoltaic || false,
			spotlightLighting: initialValue?.spotlightLighting || false,
			homeAutomationSystems: initialValue?.homeAutomationSystems || false,
			alarmSystem: initialValue?.alarmSystem || false,
			certifiedElectricalSystem:
				initialValue?.certifiedElectricalSystem || false,
			certifiedPlumbing: initialValue?.certifiedPlumbing || false,
			irrigationSystem: initialValue?.irrigationSystem || false,
			solarPanels: initialValue?.solarPanels || false,
			tvDish: initialValue?.tvDish || false,
			computerNetwork: initialValue?.computerNetwork || false,
			jacuzzi: initialValue?.jacuzzi || false,
			videoIntercom: initialValue?.videoIntercom || false,
			builtinWardrobes: initialValue?.builtinWardrobes || false,
			bathroomFurniture: initialValue?.bathroomFurniture || false,
			roomFurnishings: initialValue?.roomFurnishings || false,
			kitchenFurniture: initialValue?.kitchenFurniture || false,
			outdoorFurniture: initialValue?.outdoorFurniture || false,
			livingRoomFurniture: initialValue?.livingRoomFurniture || false,
			barbecue: initialValue?.barbecue || false,
			boiserie: initialValue?.boiserie || false,
			walkinCloset: initialValue?.walkinCloset || false,
			fireplace: initialValue?.fireplace || false,
			flue: initialValue?.flue || false,
			radiatorCovers: initialValue?.radiatorCovers || false,
			bookcase: initialValue?.bookcase || false,
			sanitarySuspended: initialValue?.sanitarySuspended || false,
			mezzanines: initialValue?.mezzanines || false,
			awnings: initialValue?.awnings || false,
			reportReferenceImage: initialValue?.reportReferenceImage || "",
			reinforcedConcrete: initialValue?.reinforcedConcrete || false,
			prefabricatedReinforcedConcrete:
				initialValue?.prefabricatedReinforcedConcrete || false,
			ironFrame: initialValue?.ironFrame || false,
			woodFrame: initialValue?.woodFrame || false,
			masonryConcreteMix: initialValue?.masonryConcreteMix || false,
			loadBearingMasonry: initialValue?.loadBearingMasonry || false,
			ceramicTile: initialValue?.ceramicTile || false,
			curtainBricks: initialValue?.curtainBricks || false,
			plaster: initialValue?.plaster || false,
			plasterWithFriezes: initialValue?.plasterWithFriezes || false,
			plasterCurtain: initialValue?.plasterCurtain || false,
			rusticPlaster: initialValue?.rusticPlaster || false,
			sheetMetalExternalCladding:
				initialValue?.sheetMetalExternalCladding || false,
			marbleMaterial: initialValue?.marbleMaterial || false,
			concretePanels: initialValue?.concretePanels || false,
			stoneMaterials: initialValue?.stoneMaterials || false,
			plasticQuartz: initialValue?.plasticQuartz || false,
			glassCrystal: initialValue?.glassCrystal || false,
			doormanApartment: initialValue?.doormanApartment || false,
			parkingArea: initialValue?.parkingArea || false,
			condominiumCellar: initialValue?.condominiumCellar || false,
			condominiumGarden: initialValue?.condominiumGarden || false,
			businessPremises: initialValue?.businessPremises || false,
			park: initialValue?.park || false,
			meetingRoom: initialValue?.meetingRoom || false,
			privateRoad: initialValue?.privateRoad || false,
			sharedTerrace: initialValue?.sharedTerrace || false,
			centralizedHotWater: initialValue?.centralizedHotWater || false,
			centralizedAntenna: initialValue?.centralizedAntenna || false,
			centralAirConditioning: initialValue?.centralAirConditioning || false,
			tennisCourt: initialValue?.tennisCourt || false,
			laundry: initialValue?.laundry || false,
			gym: initialValue?.gym || false,
			playground: initialValue?.playground || false,
			condominiumPool: initialValue?.condominiumPool || false,
			concierge: initialValue?.concierge || false,
			concierge24Hour: initialValue?.concierge24Hour || false,
			partTimeConcierge: initialValue?.partTimeConcierge || false,
			buildingVideoIntercom: initialValue?.buildingVideoIntercom || false,
			videoSurveillance: initialValue?.videoSurveillance || false,
			openSpace: initialValue?.openSpace || false,
			dividedByWalls: initialValue?.dividedByWalls || false,
			disabledAccess: initialValue?.disabledAccess || false,
			wired: initialValue?.wired || false,
			accessControl: initialValue?.accessControl || false,
			falseCeiling: initialValue?.falseCeiling || false,
			broadbandInternet: initialValue?.broadbandInternet || false,
			emergencyLights: initialValue?.emergencyLights || false,
			bikeParking: initialValue?.bikeParking || false,
			emergencyExit: initialValue?.emergencyExit || false,
			fireDoor: initialValue?.fireDoor || false,
			reception: initialValue?.reception || false,
			fireDetectors: initialValue?.fireDetectors || false,
			officeBuildingOnly: initialValue?.officeBuildingOnly || false,
			smokeExtraction: initialValue?.smokeExtraction || false,
			doubleEntrance: initialValue?.doubleEntrance || false,
			toAwayZone: initialValue?.toAwayZone || false,
			cornerPosition: initialValue?.cornerPosition || false,
			automaticPortcullis: initialValue?.automaticPortcullis || false,
			manualPortcullis: initialValue?.manualPortcullis || false,
			cctv: initialValue?.cctv || false,
			accessibility24h: initialValue?.accessibility24h || false,
			electricDoor: initialValue?.electricDoor || false,
			electricCarChargingStation:
				initialValue?.electricCarChargingStation || false,
			fencedProperty: initialValue?.fencedProperty || false,
			realEstateImage: initialValue?.realEstateImage || "",
			municipalityCode: initialValue?.municipalityCode || "",//
			paper: initialValue?.paper || "",//
			parcel: initialValue?.parcel || [],//
			subaltern: initialValue?.subaltern || "",//
			category: initialValue?.category || "",//
			classType: initialValue?.classType || "",//
			consistency: initialValue?.consistency || "",//
			cadastralIncome: initialValue?.cadastralIncome || 0,//
			urbanSection: initialValue?.urbanSection || "",//
			zone: initialValue?.zone || "",//
			microzone: initialValue?.microzone || "",//
			applicantName: initialValue?.applicantName || "",//
			applicantPhone: initialValue?.applicantPhone || "",//
			applicantEmail: initialValue?.applicantEmail || "",//
			applicantAddress: initialValue?.applicantAddress || "",//
			evaluatorName: initialValue?.evaluatorName || "",//
			evaluatorPhone: initialValue?.evaluatorPhone || "",//
			evaluatorEmail: initialValue?.evaluatorEmail || "",//
			evaluatorAddress: initialValue?.evaluatorAddress || "",//
			overview: initialValue?.overview || "",//
			logo: initialValue?.logo || "",
			note: initialValue?.note || "",
			photos: initialValue?.photos || [],
			planimetries: initialValue?.planimetries || [],
			documents: initialValue?.documents || [],
			similarMunicipality: initialValue?.similarMunicipality || "",//
			similarMq: initialValue?.similarMq || 0,//
			similarZone: initialValue?.similarZone || "",//
			similarTypology: initialValue?.similarTypology || "",//
			similarStreet: initialValue?.similarStreet || "",//
			//userID: initialValue?.userID || 0,
			similarBuildings: initialValue?.similiarBuildings || [],
			minSale: null,
			maxSale: null,
			minRent: null,
			maxRent: null,
		
			boxPossessionType: "",
			coveredParkingPossessionType:"",
			uncoveredParkingPossessionType:"",

			startConcession:"",
			endConcession:"",

			omiZone:"",
			coefficients:"",

			rooms: '',
			bathrooms: '',
			bedrooms: '',

			coveredParkingSpaceMq: '',
			uncoveredParkingSpaceMq: '',
			condominialParkingSpaceMq: '',
			apeDeadline: '',
			floorBox: initialValue?.floorBox || 'piano terra',//
			accessibilityBox: initialValue?.accessibilityBox|| '-'


		};
	});

	useEffect(() => {
		localStorage.setItem("evaluation", JSON.stringify(formData));
	}, [formData]);

	const onChange = (e) => {
		if (e.target.type === "checkbox") {
			setFormData({ ...formData, [e.target.name]: e.target.checked });
		} else if (e.target.type === "number") {
			setFormData({ ...formData, [e.target.name]: parseInt(e.target.value, 10) });
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};
	
	const [count, setCount] = useState(() => {
		const initialCount = JSON.parse(localStorage.getItem("evaluationState"));
		return initialCount ? initialCount : 0;
	});

	useEffect(() => {
		localStorage.setItem("evaluationState", JSON.stringify(count));
	}, [count]);
	const navigate = useNavigate();

	const forward = () => {
		var num = count;
		setCount(++num);
	};

	const backward = () => {
		var num = count;
		if (num > 0) {
			setCount(--num);
		}
	};

	const previousPage = () => {
		navigate(-1);
	};

	const setStep = (index) => {
		setCount(index);
	};

	const user = localStorage.getItem("user");
	const token = user ? JSON.parse(user).token : null;

	function getEvaluation() {
		const axios = require('axios');
		let data = '';
		
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `https://anthill-service.onrender.com/downloadFile?filename=${formData.identifier}&id=${user_id}`,
			headers: { 
				'Content-Type': 'application/json', 
				'Authorization': 'Bearer ' + token
			},
			data : data
			};
			
			axios.request(config)
			.then((response) => {
			console.log(JSON.stringify(response.data));
			})
			.catch((error) => {
			console.log(error);
		});
} 

	const save = async () => {

		let newFormData = {...formData}

		//UNISCI CAMPI COEFFICENTABILI FORM VALUES  A CAMPI DI FORM DATA
		// Creare un nuovo oggetto per contenere le stringhe
		const stringValues = {};
    
		// Iterare su tutte le chiavi dei formValues
		for (const key in formValues) {
			if (formValues.hasOwnProperty(key)) {
				// Convertire l'oggetto in una stringa JSON e assegnarla al nuovo oggetto
				stringValues[key] = JSON.stringify(formValues[key]);
			}
		}
		
		//FONDIAMO I DUE OGGETTI
		//NEL BACKEND BISOGNERA PARSARE I VALORI DI FORM VALUES PER PRENDERE VALORE E COEFFICIENTE POICHE' SONO STRINGHE JSON
		newFormData = {...newFormData, ...stringValues}
		
		console.log(newFormData)

	try {
	  // Controlla i campi obbligatori
	  if (
		!newFormData.identifier ||
		!newFormData.typology ||
		!newFormData.province ||
		!newFormData.municipality ||
		!newFormData.address 
	  ) {
		// Visualizza un errore o esegui un'azione appropriata
		toast.error("Dati mancanti nello step Tipologia", {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
			hideProgressBar: false,
		});
		return
	  }

	  //gestisci il campo onligatorio mq coperti a seconda della categoria selezionata
	  if (
		newFormData.typology == "Appartamenti"
	  ) {
		if(!newFormData?.grossCoveredMq) {
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Ville e villini"
	  ) {
		if(!newFormData?.grossCoveredMq) {
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Uffici"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Negozi"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}	
	  }

	  if (
		newFormData.typology == "Box e autorimesse"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Posti auto coperti" || newFormData.typology == "Posti auto scoperti"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Laboratori"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Magazzini"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }

	  if (
		newFormData.typology == "Capannoni tipici" || newFormData.typology == "Capannoni industriali"
	  ) {
		if(!newFormData?.grossCoveredMq){
			toast.error("Dati mancanti nello step Caratteristiche", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				hideProgressBar: false,
			});
			return
		}
	  }


	  if (
		!newFormData.applicantName || !newFormData.evaluatorName
	  ) {
		// Visualizza un errore o esegui un'azione appropriata
		toast.error("Dati mancanti nello step Dati", {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
			hideProgressBar: false,
		});
		return
	  }

	  //GESTIONE PARCEL
	  const parcelStringList = [];
	  newFormData.parcel.forEach((parcel) => {
		parcelStringList.push(JSON.stringify(parcel));
	  });
	  newFormData.parcel = parcelStringList;
	  
	  //GESTIONE ALLEGATI (DOCUMENTS)
	  let formDataToSend = new FormData();
  
	  newFormData.documents.forEach((file) => {
		const blob = new Blob([file]);
		formDataToSend.append("parts", blob, file.name);
	  });
	  let sentUpload = true;
	  if (formDataToSend.entries.length === 0) sentUpload = false;
  
	  delete newFormData.documents;
	  formDataToSend.append("body", JSON.stringify(formData));
	  let response; //X ALLEGATI
	  let response2; //X VALUTAZIONE
	  
	  //GESTISCE RICHIESTA POST ALLEGATI
	  if (sentUpload) {
		response = await api().post(`${evaluation_endpoint}upload`, formDataToSend);
	  } 

	/* 	response = await api().post(`https://anthill-service.onrender.com/createReportAdapter?type=HOME&user_id=${user_id}&filename=${formData.identifier}`, formData); */
			
	//GESTISCE DATI FORM
	//SALVA DATI FORM NEL DB, DOPO 60 SECONDI FA RICHIESTA PER CREAZIONE REPORT
	response2 = await api().post(`${evaluation_endpoint}`, formData);
	setTimeout(() => {
		if (response.data && response2.data) {
			getEvaluation(); //RICHIESTA CREAZIONE REPORT
		}
	}, 60000); // Ritardo di 1 minuto (60000 millisecondi)

	//RICHIESTA ASSOCIAZIONE UNITA/VALUTAZIONE
	if (unitsIndex !== -1 && response2.data) {
	let postData = {
		unitID: units[unitsIndex].id,
		evaluationID: response2.id,
	};
	await api().post(`${evaluation_unit_endpoint}`, postData);
	}

		
	  
	} catch (error) {
	  console.log(error);
	}
	localStorage.removeItem("evaluationState");
	localStorage.removeItem("evaluation");
	navigate(-1);
  };
  
    // Funzione per ottenere il testo da visualizzare
	const getEvalInfo = () => {
		const {  typology, unitName, address } = formData;
		if (!unitName && !typology && !address) {
		  return null; 
		}
		const fields = [];
		if (typology) fields.push(typology);
		if (unitName) fields.push(unitName);
		if (address) fields.push(address);
		return fields.join(" - "); // Concatena i campi separati da "-"
	  };

	

	//QUI METTO I VALORI DEI COEFFICIENTI STANDARD, CHE MI ARRIVANDO DALLA RICHIESTA AL POSTO DEI NUMERI HARDCODED
	//defaultCoefficients dovrà essere definito con useState e dovrà contenere l'oggetto preso coi dati presi dal backend (ora sono dati fake) coi coefficienti standard o custom, selezionati dall'utente
	const initialCoefficients = {
			'Appartamenti': {
        grossCoveredMq: defaultCoefficients.grossCoveredMqApartment,
        basementMq: defaultCoefficients.basementMqApartment,
        finishingVerandaMq: defaultCoefficients.finishingVerandaMqApartment,
        notFinishingVerandaMq: defaultCoefficients.notFinishingVerandaMqApartment,
        mezzanineMq: defaultCoefficients.mezzanineMqApartment,
        canopyFullHeightMq: defaultCoefficients.canopyFullHeighMqApartment,
		canopyMq: defaultCoefficients.canopyMqApartment,
        balconyMq: defaultCoefficients.balconyMqApartment,
        terraceMq: defaultCoefficients.terraceMqApartment,
        gardenMq: defaultCoefficients.gardenMqApartment,
        solarPavementMq: defaultCoefficients.solarPavementMqApartment,
        canopyFullHeightMq: defaultCoefficients.canopyMqApartment,
        lodgeMq: defaultCoefficients.lodgeMqApartment,
        otherUncoveredMq: defaultCoefficients.otherUncoveredMqApartment,
        winery: defaultCoefficients.wineryApartment,
        garret: defaultCoefficients.garretApartment,
        box: defaultCoefficients.boxApartment,
        coveredParkingSpace: defaultCoefficients.coveredParkingSpaceApartment,
        uncoveredParkingSpace: defaultCoefficients.uncoveredParkingSpaceApartment,
        condominialParkingSpace: defaultCoefficients.condominialParkingSpaceApartment,
        floor: {
            withLift: {
                basementFloor: defaultCoefficients.withLiftBasementFloorApartment,
                groundFloor: defaultCoefficients.withLiftGroundFloorApartment,
                mezzanineFloor: defaultCoefficients.withLiftMezzanineFloorApartment,
                '1Floor': defaultCoefficients.withLift1FloorApartment,
                '2Floor': defaultCoefficients.withLift2FloorApartment,
                '3Floor': defaultCoefficients.withLift3FloorApartment,
                '4Floor': defaultCoefficients.withLift4FloorApartment,
                '5Floor': defaultCoefficients.withLift5FloorApartment,
                '6Floor': defaultCoefficients.withLift6FloorApartment,
                '7Floor': defaultCoefficients.withLift7FloorApartment,
                '8Floor': defaultCoefficients.withLift8FloorApartment,
                '9Floor': defaultCoefficients.withLift9FloorApartment,
                '10Floor': defaultCoefficients.withLift10FloorApartment,
                beyond10Floor: defaultCoefficients.withLiftBeyond10FloorApartment,
                topFloor: defaultCoefficients.withLiftTopFloorApartment,
                penthouse: defaultCoefficients.withLiftPenthouseApartment
            },
            withoutLift: {
                basementFloor: defaultCoefficients.withoutLiftBasementFloorApartment,
                groundFloor: defaultCoefficients.withoutLiftGroundFloorApartment,
                mezzanineFloor: defaultCoefficients.withoutLiftMezzanineFloorApartment,
                '1Floor': defaultCoefficients.withoutLift1FloorApartment,
                '2Floor': defaultCoefficients.withoutLift2FloorApartment,
                '3Floor': defaultCoefficients.withoutLift3FloorApartment,
                '4Floor': defaultCoefficients.withoutLift4FloorApartment,
                '5Floor': defaultCoefficients.withoutLift5FloorApartment,
                '6Floor': defaultCoefficients.withoutLift6FloorApartment,
                '7Floor': defaultCoefficients.withoutLift7FloorApartment,
                '8Floor': defaultCoefficients.withoutLift8FloorApartment,
                '9Floor': defaultCoefficients.withoutLift9FloorApartment,
                '10Floor': defaultCoefficients.withoutLift10FloorApartment,
                beyond10Floor: defaultCoefficients.withoutBeyond10FloorApartment,
                topFloor: defaultCoefficients.withoutTopFloorApartment,
                penthouse: defaultCoefficients.withoutPenthouseApartment
            }
        },
        ageStateBuilding: {
            years1To5ToRenovateTotallyBuilding: defaultCoefficients.oneToFiveYearsToRenovateTotallyBuildingApartment,
            years1To5ToRenovatePartiallyBuilding: defaultCoefficients.oneToFiveYearsToRenovatePartiallyBuildingApartment,
            years1To5HabitableBuilding: defaultCoefficients.oneToFiveYearsHabitableBuildingApartment,
            years1To5ExcellentBuilding:  defaultCoefficients.oneToFiveYearsExcellentBuildingApartment,
            years1To5NewConstructionBuilding:  defaultCoefficients.oneToFiveYearsNewConstructionBuildingApartment,
            years5To20ToRenovateTotallyBuilding:  defaultCoefficients.fiveToTwentyYearsToRenovateTotallyBuildingApartment,
            years5To20ToRenovatePartiallyBuilding: defaultCoefficients.fiveToTwentyYearsToRenovatePartiallyBuildingApartment,
            years5To20HabitableBuilding: defaultCoefficients.fiveToTwentyYearsHabitableBuildingApartment,
            years5To20ExcellentBuilding:  defaultCoefficients.fiveToTwentyYearsExcellentBuildingApartment,
            years5To20NewConstructionBuilding:  defaultCoefficients.fiveToTwentyYearsNewConstructionBuildingApartment,
            years20To40ToRenovateTotallyBuilding: defaultCoefficients.twentyToFortyYearsToRenovateTotallyBuildingApartment,
            years20To40ToRenovatePartiallyBuilding: defaultCoefficients.twentyToFortyYearsToRenovatePartiallyBuildingApartment,
            years20To40HabitableBuilding: defaultCoefficients.twentyToFortyYearsHabitableBuildingApartment,
            years20To40ExcellentBuilding: defaultCoefficients.twentyToFortyYearsExcellentBuildingApartment,
            years20To40NewConstructionBuilding: defaultCoefficients.twentyToFortyYearsNewConstructionBuildingApartment,
            over40YearsToRenovateTotallyBuilding: defaultCoefficients.overFortyYearsToRenovateTotallyBuildingApartment,
            over40YearsToRenovatePartiallyBuilding: defaultCoefficients.overFortyYearsToRenovatePartiallyBuildingApartment,
            over40YearsHabitableBuilding: defaultCoefficients.overFortyYearsHabitableBuildingApartment,
            over40YearsExcellentBuilding: defaultCoefficients.overFortyYearsExcellentBuildingApartment,
            over40YearsNewConstructionBuilding: defaultCoefficients.overFortyYearsNewConstructionBuildingApartment
        },
        boxAccessibility: {
            veryDifficultBoxAccessibility: defaultCoefficients.veryDifficultBoxAccessibilityApartment,
            difficultBoxAccessibility: defaultCoefficients.difficultBoxAccessibilityApartment,
            uncomfortableBoxAccessibility: defaultCoefficients.uncomfortableBoxAccessibilityApartment,
            normalBoxAccessibility: defaultCoefficients.normalBoxAccessibilityApartment,
            comfortableBoxAccessibility: defaultCoefficients.comfortableBoxAccessibilityApartment
        },
        boxLocation: {
            inTheSameBoxLocation: defaultCoefficients.intheSameBoxLocationApartment,
            nearBoxLocation: defaultCoefficients.nearBoxLocationApartment,
            farBoxLocation: defaultCoefficients.farBoxLocationApartment,
            veryfarBoxLocation: defaultCoefficients.veryfarBoxLocationApartment
        },
        coveredParkingAccessibility: {
            veryDifficultCoveredParkingAccessibility: defaultCoefficients.veryDifficultCoveredParkingAccessibilityApartment,
            difficultCoveredParkingAccessibility: defaultCoefficients.difficultCoveredParkingAccessibilityApartment,
            uncomfortableCoveredParkingAccessibility: defaultCoefficients.uncomfortableCoveredParkingAccessibilityApartment,
            normalCoveredParkingAccessibility: defaultCoefficients.normalCoveredParkingAccessibilityApartment,
            comfortableCoveredParkingAccessibility: defaultCoefficients.comfortableCoveredParkingAccessibilityApartment
        },
        coveredParkingLocation: {
            inTheSameCoveredParkingLocation: defaultCoefficients.inTheSameCoveredParkingLocationApartment,
            nearCoveredParkingLocation: defaultCoefficients.nearCoveredParkingLocationApartment,
            farCoveredParkingLocation: defaultCoefficients.farCoveredParkingLocationApartment,
            veryfarCoveredParkingLocation: defaultCoefficients.veryfarCoveredParkingLocationApartment
        },
        uncoveredParkingAccessibility: {
            veryDifficultUncoveredParkingAccessibility: defaultCoefficients.veryDifficultUncoveredParkingAccessibilityApartment,
            difficultUncoveredParkingAccessibility: defaultCoefficients.difficultUncoveredParkingAccessibilityApartment,
            uncomfortableUncoveredParkingAccessibility: defaultCoefficients.uncomfortableUncoveredParkingAccessibilityApartment,
            normalUncoveredParkingAccessibility: defaultCoefficients.normalUncoveredParkingAccessibilityApartment,
            comfortableUncoveredParkingAccessibility: defaultCoefficients.comfortableUncoveredParkingAccessibilityApartment
        },
        uncoveredParkingLocation: {
            inTheSameUncoveredParkingLocation: defaultCoefficients.inTheSameUncoveredParkingLocationApartment,
            nearUncoveredParkingLocation: defaultCoefficients.nearUncoveredParkingLocationApartment,
            farUncoveredParkingLocation: defaultCoefficients.farUncoveredParkingLocationApartment,
            veryfarUncoveredParkingLocation: defaultCoefficients.veryfarUncoveredParkingLocationApartment
        },
        mainView: {
            externalPanoramicView: defaultCoefficients.externalPanoramicViewApartment,
            externalView: defaultCoefficients.externalViewApartment,
            mixedView: defaultCoefficients.mixedViewApartment,
            internalView: defaultCoefficients.internalViewApartment,
            fullInternalView: defaultCoefficients.fullInternalViewApartment
        },
        brightness: {
            bright: defaultCoefficients.brightApartment,
            veryBright: defaultCoefficients.veryBrightApartment,
            mediumBright: defaultCoefficients.mediumBrightApartment,
            lowBright: defaultCoefficients.lowBrightApartment
        },
        noisiness: {
            normalNoise: defaultCoefficients.normalNoiseApartment,
            veryHighNoise: defaultCoefficients.veryHighNoiseApartment,
            highNoise: defaultCoefficients.highNoiseApartment,
            lowNoise: defaultCoefficients.lowNoiseApartment,
            veryLowNoise: defaultCoefficients.veryLowNoiseApartment
        },
        internalDistribution: {
            veryEfficientDistribution: defaultCoefficients.veryEfficientDistributionApartment, 
            efficientDistribution: defaultCoefficients.efficientDistributionApartment,
            lowEfficientDistribution: defaultCoefficients.lowEfficientDistributionApartment,
            notEfficientDistribution: defaultCoefficients.notEfficientDistributionApartment
        },
        ceilingHeight: {
            mediumCeilingHeight: defaultCoefficients.mediumCeilingHeightApartment,
            inferiorCeilingHeight: defaultCoefficients.inferiorCeilingHeightApartment,
            superiorCeilingHeight: defaultCoefficients.superiorCeilingHeightApartment
        },
        internalState: {
            totallyRenovated: defaultCoefficients.totallyRenovatedApartment,
            partiallyRenovated: defaultCoefficients.partiallyRenovatedApartment,
            habitable: defaultCoefficients.habitableApartment,
            renovated: defaultCoefficients.renovatedApartment,
            finelyRenovated: defaultCoefficients.finelyRenovatedApartment,
            newConstruction: defaultCoefficients.newConstructionApartment
        },
        finishingQuality: {
            standardFinish: defaultCoefficients.standardFinishApartment,
            inexpensiveFinish: defaultCoefficients.inexpensiveFinishApartment,
            excellentFinish: defaultCoefficients.excellentFinishApartment,
            premiumFinish: defaultCoefficients.premiumFinishApartment
        },
        energeticClass: {
            a1: defaultCoefficients.a1Apartment,
            a2: defaultCoefficients.a2Apartment,
            a3: defaultCoefficients.a3Apartment,
            a4: defaultCoefficients.a4Apartment,
            b: defaultCoefficients.bApartment,
            c: defaultCoefficients.cApartment,
            d: defaultCoefficients.dApartment,
            e: defaultCoefficients.eApartment,
            f: defaultCoefficients.fApartment,
            g: defaultCoefficients.gApartment
        },
        heater: {
            autonomousHeating: defaultCoefficients.autonomousHeatingApartment,
            centralizedHeating: defaultCoefficients.centralizedHeatingApartment,
            centralizedMeteringHeating: defaultCoefficients.centralizedMeteringHeatingApartment,
            absentHeating: defaultCoefficients.absentHeatingApartment
        }
    		},
			'Ville e villini': {
        grossCoveredMq: defaultCoefficients.grossCoveredMqVilla,
        basementMq: defaultCoefficients.basementMqVilla,
		canopyFullHeightMq: defaultCoefficients.canopyFullHeighMqVilla,
        finishingVerandaMq: defaultCoefficients.finishingVerandaMqVilla,
        notFinishingVerandaMq: defaultCoefficients.notFinishingVerandaMqVilla,
        mezzanineMq: defaultCoefficients.mezzanineMqVilla,
        canopyMq: defaultCoefficients.canopyMqVilla,
        balconyMq: defaultCoefficients.balconyMqVilla,
        terraceMq: defaultCoefficients.terraceMqVilla,
        gardenMq: defaultCoefficients.gardenMqVilla,
        solarPavementMq: defaultCoefficients.solarPavementMqVilla,
        canopyFullHeightMq: defaultCoefficients.canopyFullHeightMqVilla,
        lodgeMq: defaultCoefficients.lodgeMqVilla,
        otherUncoveredMq: defaultCoefficients.otherUncoveredMqVilla,
        winery: defaultCoefficients.wineryVilla,
        garret: defaultCoefficients.garretVilla,
        box: defaultCoefficients.boxVilla,
        coveredParkingSpace: defaultCoefficients.coveredParkingSpaceVilla,
        uncoveredParkingSpace: defaultCoefficients.uncoveredParkingSpaceVilla,
        condominialParkingSpace: defaultCoefficients.condominialParkingSpaceVilla,
        floor: {
            withLift: {
                basementFloor: defaultCoefficients.withLiftBasementFloorVilla,
                groundFloor: defaultCoefficients.withLiftGroundFloorVilla,
                mezzanineFloor: defaultCoefficients.withLiftMezzanineFloorVilla,
                '1Floor': defaultCoefficients.withLift1FloorVilla,
                '2Floor': defaultCoefficients.withLift2FloorVilla,
                '3Floor': defaultCoefficients.withLift3FloorVilla,
                '4Floor': defaultCoefficients.withLift4FloorVilla,
                '5Floor': defaultCoefficients.withLift5FloorVilla,
                '6Floor': defaultCoefficients.withLift6FloorVilla,
                '7Floor': defaultCoefficients.withLift7FloorVilla,
                '8Floor': defaultCoefficients.withLift8FloorVilla,
                '9Floor': defaultCoefficients.withLift9FloorVilla,
                '10Floor': defaultCoefficients.withLift10FloorVilla,
                beyond10Floor: defaultCoefficients.withLiftBeyond10FloorVilla,
                topFloor: defaultCoefficients.withLiftTopFloorVilla,
                penthouse: defaultCoefficients.withLiftPenthouseVilla
            },
            withoutLift: {
                basementFloor: defaultCoefficients.withoutLiftBasementFloorVilla,
                groundFloor: defaultCoefficients.withoutLiftGroundFloorVilla,
                mezzanineFloor: defaultCoefficients.withoutLiftMezzanineFloorVilla,
                '1Floor': defaultCoefficients.withoutLift1FloorVilla,
                '2Floor': defaultCoefficients.withoutLift2FloorVilla,
                '3Floor': defaultCoefficients.withoutLift3FloorVilla,
                '4Floor': defaultCoefficients.withoutLift4FloorVilla,
                '5Floor': defaultCoefficients.withoutLift5FloorVilla,
                '6Floor': defaultCoefficients.withoutLift6FloorVilla,
                '7Floor':  defaultCoefficients.withoutLift7FloorVilla,
                '8Floor':  defaultCoefficients.withoutLift8FloorVilla,
                '9Floor':  defaultCoefficients.withoutLift9FloorVilla,
                '10Floor': defaultCoefficients.withoutLift10FloorVilla,
                beyond10Floor:  defaultCoefficients.withoutLiftBeyond10FloorVilla,
                topFloor:  defaultCoefficients.withoutLiftTopFloorVilla,
                penthouse: defaultCoefficients.withoutLiftPenthouseVilla
            }
        },
        ageStateBuilding: {
            years1To5ToRenovateTotallyBuilding: defaultCoefficients.years1To5ToRenovateTotallyBuildingVilla,
            years1To5ToRenovatePartiallyBuilding: defaultCoefficients.years1To5ToRenovatePartiallyBuildingVilla,
            years1To5HabitableBuilding: defaultCoefficients.years1To5HabitableBuildingVilla,
            years1To5ExcellentBuilding:  defaultCoefficients.years1To5ExcellentBuildingVilla,
            years1To5NewConstructionBuilding:  defaultCoefficients.years1To5NewConstructionBuildingVilla,
            years5To20ToRenovateTotallyBuilding: defaultCoefficients.years5To20ToRenovateTotallyBuildingVilla,
            years5To20ToRenovatePartiallyBuilding: defaultCoefficients.years5To20ToRenovatePartiallyBuildingVilla,
            years5To20HabitableBuilding: defaultCoefficients.years5To20HabitableBuildingVilla,
            years5To20ExcellentBuilding: defaultCoefficients.years5To20ExcellentBuildingVilla,
            years5To20NewConstructionBuilding:  defaultCoefficients.years5To20NewConstructionBuildingVilla,
            years20To40ToRenovateTotallyBuilding:  defaultCoefficients.years20To40ToRenovateTotallyBuildingVilla,
            years20To40ToRenovatePartiallyBuilding: defaultCoefficients.years20To40ToRenovatePartiallyBuildingVilla,
            years20To40HabitableBuilding: defaultCoefficients.years20To40HabitableBuildingVilla,
            years20To40ExcellentBuilding: defaultCoefficients.years20To40ExcellentBuildingVilla,
            years20To40NewConstructionBuilding: defaultCoefficients.years20To40NewConstructionBuildingVilla,
            over40YearsToRenovateTotallyBuilding: defaultCoefficients.over40YearsToRenovateTotallyBuildingVilla,
            over40YearsToRenovatePartiallyBuilding: defaultCoefficients.over40YearsToRenovatePartiallyBuildingVilla,
            over40YearsHabitableBuilding: defaultCoefficients.over40YearsHabitableBuildingVilla,
            over40YearsExcellentBuilding: defaultCoefficients.over40YearsExcellentBuildingVilla,
            over40YearsNewConstructionBuilding: defaultCoefficients.over40YearsNewConstructionBuildingVilla
        },
        boxAccessibility: {
            veryDifficultBoxAccessibility: defaultCoefficients.veryDifficultBoxAccessibilityVilla,
            difficultBoxAccessibility: defaultCoefficients.difficultBoxAccessibilityVilla,
            uncomfortableBoxAccessibility: defaultCoefficients.uncomfortableBoxAccessibilityVilla,
            normalBoxAccessibility: defaultCoefficients.normalBoxAccessibilityVilla,
            comfortableBoxAccessibility: defaultCoefficients.comfortableBoxAccessibilityVilla
        },
        boxLocation: {
            inTheSameBoxLocation: defaultCoefficients.inTheSameBoxLocationVilla,
            nearBoxLocation: defaultCoefficients.nearBoxLocationVilla,
            farBoxLocation: defaultCoefficients.farBoxLocationVilla,
            veryfarBoxLocation: defaultCoefficients.veryfarBoxLocationVilla
        },
        coveredParkingAccessibility: {
            veryDifficultCoveredParkingAccessibility: defaultCoefficients.veryDifficultCoveredParkingAccessibilityVilla,
            difficultCoveredParkingAccessibility: defaultCoefficients.difficultCoveredParkingAccessibilityVilla,
            uncomfortableCoveredParkingAccessibility: defaultCoefficients.uncomfortableCoveredParkingAccessibilityVilla,
            normalCoveredParkingAccessibility: defaultCoefficients.normalCoveredParkingAccessibilityVilla,
            comfortableCoveredParkingAccessibility: defaultCoefficients.comfortableCoveredParkingAccessibilityVilla
        },
        coveredParkingLocation: {
            inTheSameCoveredParkingLocation: defaultCoefficients.inTheSameCoveredParkingLocationVilla,
            nearCoveredParkingLocation: defaultCoefficients.nearCoveredParkingLocationVilla,
            farCoveredParkingLocation: defaultCoefficients. farCoveredParkingLocationVilla,
            veryfarCoveredParkingLocation: defaultCoefficients.veryfarCoveredParkingLocationVilla
        },
        uncoveredParkingAccessibility: {
            veryDifficultUncoveredParkingAccessibility: defaultCoefficients.veryDifficultUncoveredParkingAccessibilityVilla,
            difficultUncoveredParkingAccessibility: defaultCoefficients.difficultUncoveredParkingAccessibilityVilla,
            uncomfortableUncoveredParkingAccessibility: defaultCoefficients.uncomfortableUncoveredParkingAccessibilityVilla,
            normalUncoveredParkingAccessibility: defaultCoefficients.normalUncoveredParkingAccessibilityVilla,
            comfortableUncoveredParkingAccessibility: defaultCoefficients.comfortableUncoveredParkingAccessibilityVilla
        },
        uncoveredParkingLocation: {
            inTheSameUncoveredParkingLocation: defaultCoefficients.inTheSameUncoveredParkingLocationVilla,
            nearUncoveredParkingLocation: defaultCoefficients.nearUncoveredParkingLocationVilla,
            farUncoveredParkingLocation: defaultCoefficients.farUncoveredParkingLocationVilla,
            veryfarUncoveredParkingLocation: defaultCoefficients.veryfarUncoveredParkingLocationVilla
        },
        mainView: {
            externalPanoramicView: defaultCoefficients.externalPanoramicViewVilla,
            externalView: defaultCoefficients.externalViewVilla,
            mixedView: defaultCoefficients.mixedViewVilla,
            internalView: defaultCoefficients.internalViewVilla,
            fullInternalView: defaultCoefficients.fullInternalViewVilla
        },
        brightness: {
            bright: defaultCoefficients.brightVilla,
            veryBright: defaultCoefficients.veryBrightVilla,
            mediumBright: defaultCoefficients.mediumBrightVilla,
            lowBright: defaultCoefficients.lowBrightVilla
        },
        noisiness: {
            normalNoise: defaultCoefficients.normalNoiseVilla,
            veryHighNoise: defaultCoefficients.veryHighNoiseVilla,
            highNoise: defaultCoefficients.highNoiseVilla,
            lowNoise: defaultCoefficients.lowNoiseVilla,
            veryLowNoise: defaultCoefficients.veryLowNoiseVilla
        },
        internalDistribution: {
            veryEfficientDistribution:defaultCoefficients. veryEfficientDistributionVilla,
            efficientDistribution: defaultCoefficients.efficientDistributionVilla,
            lowEfficientDistribution: defaultCoefficients.lowEfficientDistributionVilla,
            notEfficientDistribution: defaultCoefficients.notEfficientDistributionVilla
        },
        ceilingHeight: {
            mediumCeilingHeight: defaultCoefficients.mediumCeilingHeightVilla,
            inferiorCeilingHeight: defaultCoefficients.inferiorCeilingHeightVilla,
            superiorCeilingHeight: defaultCoefficients.superiorCeilingHeightVilla
        },
        internalState: {
            totallyRenovated: defaultCoefficients.totallyRenovatedVilla,
            partiallyRenovated: defaultCoefficients.partiallyRenovatedVilla,
            habitable: defaultCoefficients.habitableVilla,
            renovated:defaultCoefficients.renovatedVilla,
            finelyRenovated: defaultCoefficients.finelyRenovatedVilla,
            newConstruction: defaultCoefficients.newConstructionVilla
        },
        finishingQuality: {
            standardFinish: defaultCoefficients.standardFinishVilla,
            inexpensiveFinish: defaultCoefficients.inexpensiveFinishVilla,
            excellentFinish: defaultCoefficients.excellentFinishVilla,
            premiumFinish: defaultCoefficients.premiumFinishVilla
        },
        energeticClass: {
            a1: defaultCoefficients.a1Villa,
            a2: defaultCoefficients.a2Villa,
            a3: defaultCoefficients.a3Villa,
            a4: defaultCoefficients.a4Villa,
            b: defaultCoefficients.bVilla,
            c: defaultCoefficients.cVilla,
            d: defaultCoefficients.dVilla,
            e: defaultCoefficients.eVilla,
            f: defaultCoefficients.fVilla,
            g: defaultCoefficients.gVilla
        },
        heater: {
            autonomousHeating: defaultCoefficients.autonomousHeatingVilla,
            centralizedHeating: defaultCoefficients.centralizedHeatingVilla,
            centralizedMeteringHeating: defaultCoefficients.centralizedMeteringHeatingVilla,
            absentHeating: defaultCoefficients.absentHeatingVilla
        }
    		},
			'Negozi': {
        grossCoveredMq: defaultCoefficients.grossCoveredMqShops,
        balconyMq: defaultCoefficients.balconyMqShops,
        terraceMq: defaultCoefficients.terraceMqShops,
        gardenMq: defaultCoefficients.gardenMqShops,
        solarPavementMq: defaultCoefficients.solarPavementMqShops,
        lodgeMq: defaultCoefficients.lodgeMqShops,
        otherUncoveredMq: defaultCoefficients.otherUncoveredMqShops,
        floor: {
            basementDown:defaultCoefficients. basementDownShops,
            groundFloor: defaultCoefficients.groundFloorShops,
            mezzanineFloorFrom1to3: defaultCoefficients.mezzanineFloorFrom1To3Shops,
            floorAboveThe3rd: defaultCoefficients.floorAboveThe3rdShops
        },
        position: {
            particularlyFavorableToTrade: defaultCoefficients.particularlyFavorableToTradeShops,
            favorableToTrade: defaultCoefficients.favorableToTradeShops,
            ordinary: defaultCoefficients.ordinaryShops,
            unfavorableToTrade: defaultCoefficients.unfavorableToTradeShops
        },
        coveredParkingSpace: defaultCoefficients.coveredParkingShops,
        uncoveredParkingSpace: defaultCoefficients.uncoveredParkingShops,
        ageStateBuilding: {
            years1To5ToRenovateTotallyBuilding: defaultCoefficients.years1To5ToRenovateTotallyBuildingShops,
            years1To5ToRenovatePartiallyBuilding: defaultCoefficients.years1To5ToRenovatePartiallyBuildingShops,
            years1To5HabitableBuilding: defaultCoefficients.years1To5HabitableBuildingShops,
            years1To5ExcellentBuilding: defaultCoefficients.years1To5ExcellentBuildingShops,
            years1To5NewConstructionBuilding: defaultCoefficients.years1To5NewConstructionBuildingShops,
            years5To20ToRenovateTotallyBuilding: defaultCoefficients.years5To20ToRenovateTotallyBuildingShops,
            years5To20ToRenovatePartiallyBuilding: defaultCoefficients.years5To20ToRenovatePartiallyBuildingShops,
            years5To20HabitableBuilding:  defaultCoefficients.years5To20HabitableBuildingShops,
            years5To20ExcellentBuilding:  defaultCoefficients.years5To20ExcellentBuildingShops,
            years5To20NewConstructionBuilding: defaultCoefficients.years5To20NewConstructionBuildingShops,
            years20To40ToRenovateTotallyBuilding: defaultCoefficients.years20To40ToRenovateTotallyBuildingShops,
            years20To40ToRenovatePartiallyBuilding: defaultCoefficients.years20To40ToRenovatePartiallyBuildingShops,
            years20To40HabitableBuilding: defaultCoefficients.years20To40HabitableBuildingShops,
            years20To40ExcellentBuilding:  defaultCoefficients.years20To40ExcellentBuildingShops,
            years20To40NewConstructionBuilding: defaultCoefficients.years20To40NewConstructionBuildingShops,
            over40YearsToRenovateTotallyBuilding: defaultCoefficients.over40YearsToRenovateTotallyBuildingShops,
            over40YearsToRenovatePartiallyBuilding: defaultCoefficients.over40YearsToRenovatePartiallyBuildingShops,
            over40YearsHabitableBuilding: defaultCoefficients.over40YearsHabitableBuildingShops,
            over40YearsExcellentBuilding: defaultCoefficients.over40YearsExcellentBuildingShops,
            over40YearsNewConstructionBuilding: defaultCoefficients.over40YearsNewConstructionBuildingShops
        },
        energeticClass: {
            a1: defaultCoefficients.a1Shops,
            a2: defaultCoefficients.a2Shops,
            a3: defaultCoefficients.a3Shops,
            a4: defaultCoefficients.a4Shops,
            b: defaultCoefficients.bShops,
            c: defaultCoefficients.cShops,
            d: defaultCoefficients.dShops,
            e: defaultCoefficients.eShops,
            f: defaultCoefficients.fShops,
            g: defaultCoefficients.gShops
        },
        heater: {
            autonomousHeating: defaultCoefficients.autonomousHeatingShops,
            centralizedHeating: defaultCoefficients.centralizedHeatingShops,
            centralizedMeteringHeating: defaultCoefficients.centralizedMeteringHeatingShops,
            absentHeating: defaultCoefficients.absentHeatingShops
        },
        internalState: {
            totallyRenovated: defaultCoefficients.totallyRenovatedShops,
            partiallyRenovated: defaultCoefficients.partiallyRenovatedShops,
            habitable: defaultCoefficients.habitableShops,
            renovated: defaultCoefficients.renovatedShops,
            finelyRenovated: defaultCoefficients.finelyRenovatedShops,
            newConstruction: defaultCoefficients.newConstructionShops
        },
        ceilingHeight: {
            mediumCeilingHeight: defaultCoefficients.mediumCeilingHeightShops,
            inferiorCeilingHeight: defaultCoefficients.inferiorCeilingHeightShops,
            superiorCeilingHeight: defaultCoefficients.superiorCeilingHeightShops
        }
    		},		
			"Uffici": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqOffice,
				ageStateBuilding: {
					years1To5ToRenovateTotallyBuilding: defaultCoefficients.years1To5ToRenovateTotallyBuildingOffice,
					years1To5ToRenovatePartiallyBuilding: defaultCoefficients.years1To5ToRenovatePartiallyBuildingOffice,
					years1To5HabitableBuilding: defaultCoefficients.years1To5HabitableBuildingOffice,
					years1To5ExcellentBuilding: defaultCoefficients.years1To5ExcellentBuildingOffice,
					years1To5NewConstructionBuilding: defaultCoefficients.years1To5NewConstructionBuildingOffice,
					years5To20ToRenovateTotallyBuilding: defaultCoefficients.years5To20ToRenovateTotallyBuildingOffice,
					years5To20ToRenovatePartiallyBuilding: defaultCoefficients.years5To20ToRenovatePartiallyBuildingOffice,
					years5To20HabitableBuilding: defaultCoefficients.years5To20HabitableBuildingOffice,
					years5To20ExcellentBuilding: defaultCoefficients.years5To20ExcellentBuildingOffice,
					years5To20NewConstructionBuilding: defaultCoefficients.years5To20NewConstructionBuildingOffice,
					years20To40ToRenovateTotallyBuilding: defaultCoefficients.years20To40ToRenovateTotallyBuildingOffice,
					years20To40ToRenovatePartiallyBuilding: defaultCoefficients.years20To40ToRenovatePartiallyBuildingOffice,
					years20To40HabitableBuilding:  defaultCoefficients.years20To40HabitableBuildingOffice,
					years20To40ExcellentBuilding: defaultCoefficients.years20To40ExcellentBuildingOffice,
					years20To40NewConstructionBuilding: defaultCoefficients.years20To40NewConstructionBuildingOffice,
					over40YearsToRenovateTotallyBuilding: defaultCoefficients.over40YearsToRenovateTotallyBuildingOffice,
					over40YearsToRenovatePartiallyBuilding:  defaultCoefficients.over40YearsToRenovatePartiallyBuildingOffice,
					over40YearsHabitableBuilding: defaultCoefficients.over40YearsHabitableBuildingOffice,
					over40YearsExcellentBuilding: defaultCoefficients.over40YearsExcellentBuildingOffice,
					over40YearsNewConstructionBuilding: defaultCoefficients.over40YearsNewConstructionBuildingOffice
				},
				position: {
					centralPosition: defaultCoefficients.centralPositionOffice,
					intermediatePosition: defaultCoefficients.intermediatePositionOffice,
					secondaryPosition: defaultCoefficients.secondaryPositionOffice
				},
				internalState: {
					totallyRenovated: defaultCoefficients.totallyRenovatedOffice,
					partiallyRenovated: defaultCoefficients.partiallyRenovatedOffice,
					habitable: defaultCoefficients.habitableOffice,
					renovated: defaultCoefficients.renovatedOffice,
					finelyRenovated: defaultCoefficients.finelyRenovatedOffice,
					newConstruction: defaultCoefficients.newConstructionOffice
				},
				energeticClass: {
					a1: defaultCoefficients.a1Office,
					a2: defaultCoefficients.a2Office,
					a3: defaultCoefficients.a3Office,
					a4: defaultCoefficients.a4Office,
					b: defaultCoefficients.bOffice,
					c: defaultCoefficients.cOffice,
					d: defaultCoefficients.dOffice,
					e: defaultCoefficients.eOffice,
					f: defaultCoefficients.fOffice,
					g: defaultCoefficients.gOffice
				},
				heater: {
					autonomousHeating: defaultCoefficients.autonomousHeatingOffice,
					centralizedHeating: defaultCoefficients.centralizedHeatingOffice,
					centralizedMeteringHeating: defaultCoefficients.centralizedMeteringHeatingOffice,
					absentHeating: defaultCoefficients.absentHeatingOffice
				},
				floor: {
					withLift: {
						basementFloor: defaultCoefficients.withBasementFloorOffice,
						groundFloor: defaultCoefficients.withGroundFloorOffice,
						mezzanineFloor: defaultCoefficients.withMezzanineFloorOffice,
						'1Floor': defaultCoefficients.with1FloorOffice,
						'2Floor': defaultCoefficients.with2FloorOffice,
						'3Floor': defaultCoefficients.with3FloorOffice,
						'4Floor': defaultCoefficients.with4FloorOffice,
						'5Floor': defaultCoefficients.with5FloorOffice,
						'6Floor': defaultCoefficients.with6FloorOffice,
						'7Floor': defaultCoefficients.with7FloorOffice,
						'8Floor': defaultCoefficients.with8FloorOffice,
						'9Floor': defaultCoefficients.with9FloorOffice,
						'10Floor': defaultCoefficients.with10FloorOffice,
						beyond10Floor: defaultCoefficients.withBeyond10FloorOffice,
						topFloor: defaultCoefficients.withTopFloorOffice,
						penthouse: defaultCoefficients.withPenthouseOffice
					},
					withoutLift: {
						basementFloor: defaultCoefficients.withoutBasementFloorOffice,
						groundFloor: defaultCoefficients.withoutGroundFloorOffice,
						mezzanineFloor: defaultCoefficients.withoutMezzanineFloorOffice,
						'1Floor': defaultCoefficients.without1FloorOffice,
						'2Floor': defaultCoefficients.without2FloorOffice,
						'3Floor': defaultCoefficients.without3FloorOffice,
						'4Floor': defaultCoefficients.without4FloorOffice,
						'5Floor': defaultCoefficients.without5FloorOffice,
						'6Floor': defaultCoefficients.without6FloorOffice,
						'7Floor': defaultCoefficients.without7FloorOffice,
						'8Floor': defaultCoefficients.without8FloorOffice,
						'9Floor': defaultCoefficients.without9FloorOffice,
						'10Floor': defaultCoefficients.without10FloorOffice,
						beyond10Floor: defaultCoefficients.withoutBeyond10FloorOffice,
						topFloor: defaultCoefficients.withoutTopFloorOffice,
						penthouse: defaultCoefficients.withoutPenthouseOffice
					}
				},
				coveredParkingSpace: defaultCoefficients.coveredParkingOffice,
				uncoveredParkingSpace: defaultCoefficients.uncoveredParkingOffice,
				balconyMq: defaultCoefficients.balconyMqOffice,
				terraceMq: defaultCoefficients.terraceMqOffice,
				gardenMq: defaultCoefficients.gardenMqOffice,
				solarPavementMq: defaultCoefficients.solarPavementMqOffice,
				lodgeMq: defaultCoefficients.lodgeMqOffice,
				otherUncoveredMq: defaultCoefficients.otherUncoveredMqOffice,
				winery: defaultCoefficients.wineryOffice,
				garret: defaultCoefficients.garretOffice,
				box: defaultCoefficients.boxOffice,
				coveredParkingSpace: defaultCoefficients.coveredParkingOffice,
				uncoveredParkingSpace: defaultCoefficients.uncoveredParkingOffice,
			},
			"Box e autorimesse": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqBox,
				parkingAccessibility: {
					easyParkingAccessibility: defaultCoefficients.easyParkingAccessibilityBox,
					mediumParkingAccessibility: defaultCoefficients.mediumParkingAccessibilityBox,
					hardParkingAccessibility: defaultCoefficients.hardParkingAccessibilityBox,
					veryHardParkingAccessibility: defaultCoefficients.veryHardParkingAccessibilityBox
				},
				garage: {
					outsideTheGarage: defaultCoefficients.outsideTheGarageBox,
					insideGarage: defaultCoefficients.insideGarageBox
				},
				height: {
					averageHeight: defaultCoefficients.averageHeightBox,
					averageAbove: defaultCoefficients.averageAboveBox
				},
				propertyType: {
					propertyBoxPossession: defaultCoefficients.propertyPossessionBox,
					concessionBoxPossession: defaultCoefficients.concessionPossessionBox
				},
			},
			"Posti auto coperti": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqCoveredParking,
				parkingAccessibility: {
					easyParkingAccessibility: defaultCoefficients.easyParkingAccessibilityCoveredParking,
					mediumParkingAccessibility: defaultCoefficients.mediumParkingAccessibilityCoveredParking,
					hardParkingAccessibility: defaultCoefficients.hardParkingAccessibilityCoveredParking,
					veryHardParkingAccessibility: defaultCoefficients.veryHardParkingAccessibilityCoveredParking
				},
				propertyType: {
					propertyCoveredParkingPossession: defaultCoefficients.propertyPossessionCoveredParking,
					concessionCoveredParkingPossession: defaultCoefficients.concessionPossessionCoveredParking
				}
			},
			"Posti auto scoperti": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqUncoveredParking,
				parkingAccessibility: {
					easyParkingAccessibility: defaultCoefficients.easyParkingAccessibilityUncoveredParking,
					mediumParkingAccessibility: defaultCoefficients.mediumParkingAccessibilityUncoveredParking,
					hardParkingAccessibility: defaultCoefficients.hardParkingAccessibilityUncoveredParking,
					veryHardParkingAccessibility: defaultCoefficients.veryHardParkingAccessibilityUncoveredParking
				},
				propertyType: {
					propertyUncoveredParkingPossession: defaultCoefficients.propertyPossessionUncoveredParking,
					concessionUncoveredParkingPossession: defaultCoefficients.concessionPossessionUncoveredParking
				}
			},
			"Laboratori": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqLaboratories,
				floor: {
					groundFloorWithAccess:  defaultCoefficients.groundFloorWithAccessLaboratories,
					groundFloorWithoutAccess:  defaultCoefficients.groundFloorWithoutAccessLaboratories,
					basementWithAccess: defaultCoefficients.basementWithAccessLaboratories,
					basementWithoutAccess: defaultCoefficients.basementWithoutAccessLaboratories
				},
				ageStateBuilding: {
					"years1To5ToRenovateTotallyBuilding": defaultCoefficients.years1To5ToRenovateTotallyBuildingLaboratories,
					"years1To5ToRenovatePartiallyBuilding": defaultCoefficients.years1To5ToRenovatePartiallyBuildingLaboratories,
					"years1To5HabitableBuilding": defaultCoefficients.years1To5HabitableBuildingLaboratories,
					"years1To5ExcellentBuilding": defaultCoefficients.years1To5ExcellentBuildingLaboratories,
					"years1To5NewConstructionBuilding": defaultCoefficients.years1To5NewConstructionBuildingLaboratories,
					"years5To20ToRenovateTotallyBuilding": defaultCoefficients.years5To20ToRenovateTotallyBuildingLaboratories,
					"years5To20ToRenovatePartiallyBuilding": defaultCoefficients.years5To20ToRenovatePartiallyBuildingLaboratories,
					"years5To20HabitableBuilding": defaultCoefficients.years5To20HabitableBuildingLaboratories,
					"years5To20ExcellentBuilding": defaultCoefficients.years5To20ExcellentBuildingLaboratories,
					"years5To20NewConstructionBuilding": defaultCoefficients.years5To20NewConstructionBuildingLaboratories,
					"years20To40ToRenovateTotallyBuilding": defaultCoefficients.years20To40ToRenovateTotallyBuildingLaboratories,
					"years20To40ToRenovatePartiallyBuilding": defaultCoefficients.years20To40ToRenovatePartiallyBuildingLaboratories,
					"years20To40HabitableBuilding": defaultCoefficients.years20To40HabitableBuildingLaboratories,
					"years20To40ExcellentBuilding": defaultCoefficients.years20To40ExcellentBuildingLaboratories,
					"years20To40NewConstructionBuilding": defaultCoefficients.years20To40NewConstructionBuildingLaboratories,
					"over40YearsToRenovateTotallyBuilding": defaultCoefficients.over40YearsToRenovateTotallyBuildingLaboratories,
					"over40YearsToRenovatePartiallyBuilding": defaultCoefficients.over40YearsToRenovatePartiallyBuildingLaboratories,
					"over40YearsHabitableBuilding": defaultCoefficients.over40YearsHabitableBuildingLaboratories,
					"over40YearsExcellentBuilding": defaultCoefficients.over40YearsExcellentBuildingLaboratories,
					"over40YearsNewConstructionBuilding": defaultCoefficients.over40YearsNewConstructionBuildingLaboratories
				}
			},
			"Magazzini": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqStore,
				floor: {
					groundFloorWithAccess: defaultCoefficients.groundFloorWithAccessStore,
					groundFloorWithoutAccess: defaultCoefficients.groundFloorWithoutAccessStore,
					basementWithAccess: defaultCoefficients.basementWithAccessStore,
					basementWithoutAccess: defaultCoefficients.basementWithoutAccessStore
				},
				ageStateBuilding: {
					"years1To5ToRenovateTotallyBuilding": defaultCoefficients.years1To5ToRenovateTotallyBuildingStore,
					"years1To5ToRenovatePartiallyBuilding": defaultCoefficients.years1To5ToRenovatePartiallyBuildingStore,
					"years1To5HabitableBuilding": defaultCoefficients.years1To5HabitableBuildingStore,
					"years1To5ExcellentBuilding": defaultCoefficients.years1To5ExcellentBuildingStore,
					"years1To5NewConstructionBuilding": defaultCoefficients.years1To5NewConstructionBuildingStore,
					"years5To20ToRenovateTotallyBuilding": defaultCoefficients.years5To20ToRenovateTotallyBuildingStore,
					"years5To20ToRenovatePartiallyBuilding": defaultCoefficients.years5To20ToRenovatePartiallyBuildingStore,
					"years5To20HabitableBuilding": defaultCoefficients.years5To20HabitableBuildingStore,
					"years5To20ExcellentBuilding": defaultCoefficients.years5To20ExcellentBuildingStore,
					"years5To20NewConstructionBuilding": defaultCoefficients.years5To20NewConstructionBuildingStore,
					"years20To40ToRenovateTotallyBuilding": defaultCoefficients.years20To40ToRenovateTotallyBuildingStore,
					"years20To40ToRenovatePartiallyBuilding": defaultCoefficients.years20To40ToRenovatePartiallyBuildingStore,
					"years20To40HabitableBuilding": defaultCoefficients.years20To40HabitableBuildingStore,
					"years20To40ExcellentBuilding": defaultCoefficients.years20To40ExcellentBuildingStore,
					"years20To40NewConstructionBuilding": defaultCoefficients.years20To40NewConstructionBuildingStore,
					"over40YearsToRenovateTotallyBuilding": defaultCoefficients.over40YearsToRenovateTotallyBuildingStore,
					"over40YearsToRenovatePartiallyBuilding": defaultCoefficients.over40YearsToRenovatePartiallyBuildingStore,
					"over40YearsHabitableBuilding": defaultCoefficients.over40YearsHabitableBuildingStore,
					"over40YearsExcellentBuilding": defaultCoefficients.over40YearsExcellentBuildingStore,
					"over40YearsNewConstructionBuilding": defaultCoefficients.over40YearsNewConstructionBuildingStore
				}

			},
			"Capannoni tipici": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqSheds,
    floor: {
        groundFloor: defaultCoefficients.groundFloorSheds,
        mezzanine:defaultCoefficients. mezzanineSheds
    },
    access: {
        articulatedVehicleAccess: defaultCoefficients.articulatedVehicleAccessSheds,
        withoutArticulatedVehicleAccess: defaultCoefficients.withoutArticulatedVehicleAccessSheds
    },
    ageStateBuilding: {
        "years1To5ToRenovateTotallyBuilding": defaultCoefficients.years1To5ToRenovateTotallyBuildingSheds,
        "years1To5ToRenovatePartiallyBuilding": defaultCoefficients.years1To5ToRenovatePartiallyBuildingSheds,
        "years1To5HabitableBuilding": defaultCoefficients.years1To5HabitableBuildingSheds,
        "years1To5ExcellentBuilding": defaultCoefficients.years1To5ExcellentBuildingSheds,
        "years1To5NewConstructionBuilding": defaultCoefficients.years1To5NewConstructionBuildingSheds,
        "years5To20ToRenovateTotallyBuilding": defaultCoefficients.years5To20ToRenovateTotallyBuildingSheds,
        "years5To20ToRenovatePartiallyBuilding": defaultCoefficients.years5To20ToRenovatePartiallyBuildingSheds,
        "years5To20HabitableBuilding": defaultCoefficients.years5To20HabitableBuildingSheds,
        "years5To20ExcellentBuilding": defaultCoefficients.years5To20ExcellentBuildingSheds,
        "years5To20NewConstructionBuilding": defaultCoefficients.years5To20NewConstructionBuildingSheds,
        "years20To40ToRenovateTotallyBuilding": defaultCoefficients.years20To40ToRenovateTotallyBuildingSheds,
        "years20To40ToRenovatePartiallyBuilding": defaultCoefficients.years20To40ToRenovatePartiallyBuildingSheds,
        "years20To40HabitableBuilding": defaultCoefficients.years20To40HabitableBuildingSheds,
        "years20To40ExcellentBuilding": defaultCoefficients.years20To40ExcellentBuildingSheds,
        "years20To40NewConstructionBuilding": defaultCoefficients.years20To40NewConstructionBuildingSheds,
        "over40YearsToRenovateTotallyBuilding": defaultCoefficients.over40YearsToRenovateTotallyBuildingSheds,
        "over40YearsToRenovatePartiallyBuilding": defaultCoefficients.over40YearsToRenovatePartiallyBuildingSheds,
        "over40YearsHabitableBuilding": defaultCoefficients.over40YearsHabitableBuildingSheds,
        "over40YearsExcellentBuilding": defaultCoefficients.over40YearsExcellentBuildingSheds,
        "over40YearsNewConstructionBuilding": defaultCoefficients.over40YearsNewConstructionBuildingSheds
    }
			},
			"Capannoni industriali": {
				grossCoveredMq: defaultCoefficients.grossCoveredMqIndustrialSheds,
				floor: {
					groundFloor: defaultCoefficients.groundFloorIndustrialSheds,
					mezzanine:defaultCoefficients.mezzanineIndustrialSheds
				},
				access: {
					articulatedVehicleAccess: defaultCoefficients.articulatedVehicleAccessIndustrialSheds,
					withoutArticulatedVehicleAccess: defaultCoefficients.withoutArticulatedVehicleAccessIndustrialSheds
				},
				ageStateBuilding: {
					"years1To5ToRenovateTotallyBuilding": defaultCoefficients.years1To5ToRenovateTotallyBuildingIndustrialSheds,
					"years1To5ToRenovatePartiallyBuilding": defaultCoefficients.years1To5ToRenovatePartiallyBuildingIndustrialSheds,
					"years1To5HabitableBuilding": defaultCoefficients.years1To5HabitableBuildingIndustrialSheds,
					"years1To5ExcellentBuilding": defaultCoefficients.years1To5ExcellentBuildingIndustrialSheds,
					"years1To5NewConstructionBuilding": defaultCoefficients.years1To5NewConstructionBuildingIndustrialSheds,
					"years5To20ToRenovateTotallyBuilding": defaultCoefficients.years5To20ToRenovateTotallyBuildingIndustrialSheds,
					"years5To20ToRenovatePartiallyBuilding": defaultCoefficients.years5To20ToRenovatePartiallyBuildingIndustrialSheds,
					"years5To20HabitableBuilding": defaultCoefficients.years5To20HabitableBuildingIndustrialSheds,
					"years5To20ExcellentBuilding": defaultCoefficients.years5To20ExcellentBuildingIndustrialSheds,
					"years5To20NewConstructionBuilding": defaultCoefficients.years5To20NewConstructionBuildingIndustrialSheds,
					"years20To40ToRenovateTotallyBuilding": defaultCoefficients.years20To40ToRenovateTotallyBuildingIndustrialSheds,
					"years20To40ToRenovatePartiallyBuilding": defaultCoefficients.years20To40ToRenovatePartiallyBuildingIndustrialSheds,
					"years20To40HabitableBuilding": defaultCoefficients.years20To40HabitableBuildingIndustrialSheds,
					"years20To40ExcellentBuilding": defaultCoefficients.years20To40ExcellentBuildingIndustrialSheds,
					"years20To40NewConstructionBuilding": defaultCoefficients.years20To40NewConstructionBuildingIndustrialSheds,
					"over40YearsToRenovateTotallyBuilding": defaultCoefficients.over40YearsToRenovateTotallyBuildingIndustrialSheds,
					"over40YearsToRenovatePartiallyBuilding": defaultCoefficients.over40YearsToRenovatePartiallyBuildingIndustrialSheds,
					"over40YearsHabitableBuilding": defaultCoefficients.over40YearsHabitableBuildingIndustrialSheds,
					"over40YearsExcellentBuilding": defaultCoefficients.over40YearsExcellentBuildingIndustrialSheds,
					"over40YearsNewConstructionBuilding": defaultCoefficients.over40YearsNewConstructionBuildingIndustrialSheds
				}

			}
	}

	  
	  const getCombinedCoefficient = (age, status, category) => {
		const ageKey = ageMap[age];
		const statusKey = statusMap[status];
		return `${ageKey}${statusKey}Building`;
	  };


//OGGETTO TO HANDLE ONLY CUSTOM FIELDS
const [formValues, setFormValues] = useState({
	grossCoveredMq: { value: '', coeff: '' },
	basementMq: { value: '', coeff: '' },
	finishingVerandaMq: { value: '', coeff: '' },
	notFinishingVerandaMq: { value: '', coeff: '' },
	mezzanineMq: { value: '', coeff: '' },
	canopyMq: { value: '', coeff: '' },
	canopyFullHeightMq: { value: '', coeff: '' },
	balconyMq: { value: '', coeff: '' },
	terraceMq: { value: '', coeff: '' },
	gardenMq: { value: '', coeff: '' },
	lodgeMq: { value: '', coeff: '' },
	solarPavementMq: { value: '', coeff: '' },
	otherUncoveredMq: { value: '', coeff: '' },
	winery: { value: 'assente', coeff: '' },
	garret: { value: 'assente', coeff: '' },
	floor: { value: 'piano terra', coeff: '' },
parkingAccessibility: { value: 'nella media di zona', coeff: '' }, //coeff. x box e posti auto
 	box: { value: 'assente', coeff: '' },
	boxAccessibility: { value: 'accesso normale', coeff: '' },
	boxLocation: { value: "-", coeff: '' },
	//boxPossessionType: { value: '-', coeff: '' },
	coveredParkingSpace: { value: 'assente', coeff: '' },
	coveredParkingAccessibility: { value: '-', coeff: '' },
	//coveredParkingPossessionType: { value: '-', coeff: '' },
	coveredParkingLocation: { value: "-", coeff: '' },
	uncoveredParkingSpace: { value: 'assente', coeff: '' },
	uncoveredParkingLocation: { value: "-", coeff: '' },
	//uncoveredParkingPossessionType: { value: '-', coeff: '' },
	uncoveredParkingAccessibility: { value: '-', coeff: '' },
	condominialParkingSpace: { value: 'assente', coeff: '' },
	// condominialParkingPossessionType: { value: '-', coeff: '' },
	mainView: { value: 'mista', coeff: '' },
	noisiness: { value: 'normale', coeff: '' },
	brightness: { value: 'luminoso', coeff: '' },
	internalDistribution: { value: 'efficiente', coeff: '' },
	internalState: { value: 'buono/abitabile', coeff: '' },
	finishingQuality: { value: 'standard', coeff: '' },
	energeticClass: { value: 'D', coeff: '' },
	heater: { value: 'assente', coeff: '' },
	buildingAge: { value: '20-40 anni', coeff: '' },
	buildingState: { value: 'buono/abitabile', coeff: '' },
	ceilingHeight: { value: 'nella media di zona', coeff: '' },

	positionOffice: { value: 'posizione intermedia', coeff: '' },
	positionShops: { value: 'ordinaria', coeff: '' },


	garage: { value: 'fuori autorimessa', coeff: '' },
	boxHeight: { value: 'nella media', coeff: '' },

	accessibilityTypeShed: { value: 'senza accesso autoarticolato', coeff: '' },

	propertyType: { value: 'proprietà', coeff: '' },





  });
  
	//campi con più opzioni vanno inseriti qui
	const specialFields = [
		'floor', 'parkingAccessibility', 'boxAccessibility', 'boxLocation', 
		'boxPossessionType', 'coveredParkingAccessibility', 
		'coveredParkingPossessionType', 'coveredParkingLocation', 
		'uncoveredParkingLocation', 'uncoveredParkingPossessionType', 'uncoveredParkingAccessibility', 
		'condominialParkingPossessionType', 'mainView', 'noisiness', 
		'brightness', 'internalDistribution', 'internalState', 'finishingQuality', 'energeticClass', 
		'heater', 'buildingAge', 'buildingState', 'ceilingHeight', 'garage', 'boxHeight', 'accessibilityTypeShed', "positionShops",
		"positionOffice", "propertyType"
	];

	//QUANDO VENGONO AGGIORNATI CAMPI FORM SPECIALI (CON PIù OPZIONI)
	useEffect(() => {
		const updatedFormValues = { ...formValues };

		const selectedReportType = formData.typology ? formData.typology : 'Appartamenti'
	  
		for (const key in formValues) {
		  if (formValues.hasOwnProperty(key)) {
			if (specialFields.includes(key)) {
			  let newCoeff;
	  
			  // Gestione specifica per 'floor'
			  if (key === 'floor') {

				if(selectedReportType == "Appartamenti" || selectedReportType == "Ville e villini" || selectedReportType == "Uffici" ){
					const liftType = formData.lift ? 'withLift' : 'withoutLift';
					const floorType = formValues.floor?.value;
	  
					if (floorType) {
					newCoeff = initialCoefficients[selectedReportType].floor?.[liftType][coefficientFloorMap[floorType]];
					}
				} else if (selectedReportType == "Negozi" ){
					const floorType = formValues.floor?.value;
	  	  
					if (floorType) {
					newCoeff = initialCoefficients[selectedReportType]?.floor?.[coefficientFloorShopMap[floorType]];
					}
				} else if (selectedReportType == "Laboratori" || selectedReportType == "Magazzini" ){
					const floorType = formValues.floor?.value;
	  	  
					if (floorType) {
					newCoeff = initialCoefficients[selectedReportType]?.floor?.[coefficientFloorMagMap[floorType]];
					}
				} else if (selectedReportType == "Capannoni tipici" || selectedReportType == "Capannoni industriali"){
					const floorType = formValues.floor?.value;
	  	  
					if (floorType) {
					newCoeff = initialCoefficients[selectedReportType]?.floor?.[coefficientCapFloorMap[floorType]];
					}
				}
			  }
			  // Gestione per 'buildingAge' e 'buildingState'
			  else if (key === 'buildingAge' || key === 'buildingState') {
				const age = formValues.buildingAge?.value;
				const state = formValues.buildingState?.value;
	  
		if(selectedReportType == "Appartamenti" || selectedReportType == "Ville e villini" || selectedReportType == "Uffici" || selectedReportType == "Negozi" ||  selectedReportType == "Laboratori" || selectedReportType == "Magazzini" || selectedReportType == "Capannoni tipici" || selectedReportType == "Capannoni industriali"  ){
					if (age && state) {
					const combinedKey = getCombinedCoefficient(age, state, selectedReportType);
					newCoeff = initialCoefficients[selectedReportType]?.ageStateBuilding[combinedKey];
					}
				}
			  }

			  else if (key === 'boxLocation') {
				const locationType = formValues.boxLocation?.value;
				if (locationType) {
				  newCoeff = initialCoefficients[selectedReportType]?.boxLocation?.[coefficientBoxLocationMap[locationType]];
				}
			  } 

			   else if (key === 'boxAccessibility') {
				const accessibilityType = formValues.boxAccessibility?.value;
	  
				if (accessibilityType) {
				  newCoeff = initialCoefficients[selectedReportType]?.boxAccessibility?.[coefficientBoxAccessibilityMap[accessibilityType]];
				}
			  }
			
			  

			  else if (key === 'coveredParkingAccessibility') {
				const accessibility = formValues.coveredParkingAccessibility?.value;
				if (accessibility) {
				  newCoeff = initialCoefficients[selectedReportType]?.coveredParkingAccessibility?.[coefficientCoveredParkingAccessibilityMap[accessibility]];
				}
			  } 

			  else if (key === 'coveredParkingLocation') {
				const location = formValues.coveredParkingLocation?.value;
				if (location) {
				  newCoeff = initialCoefficients[selectedReportType].coveredParkingLocation?.[coefficientCoveredParkingLocationMap[location]];
				}
			  }
			  
			  else if (key === 'coveredParkingPossessionType') {
				const possessionType = formValues.coveredParkingPossessionType?.value;
				if (possessionType) {
				  newCoeff = initialCoefficients[selectedReportType]?.coveredParkingPossessionType?.[coefficientCoveredParkingPossessionTypeMap[possessionType]];
				}
			  } 

			  
			  
			  else if (key === 'uncoveredParkingLocation') {
				const location = formValues.uncoveredParkingLocation?.value;
				if (location) {
				  newCoeff = initialCoefficients[selectedReportType]?.uncoveredParkingLocation?.[coefficientUncoveredParkingLocationMap[location]];
				}
			  } 
			  
			  else if (key === 'uncoveredParkingPossessionType') {
				const possessionType = formValues.uncoveredParkingPossessionType?.value;
				if (possessionType) {
				  newCoeff = initialCoefficients[selectedReportType]?.uncoveredParkingPossessionType?.[coefficientUncoveredParkingPossessionTypeMap[possessionType]];
				}
			  } 
			  
			  else if (key === 'uncoveredParkingAccessibility') {
				const accessibility = formValues.uncoveredParkingAccessibility?.value;
				if (accessibility) {
				  newCoeff = initialCoefficients[selectedReportType]?.uncoveredParkingAccessibility?.[coefficientUncoveredParkingAccessibilityMap[accessibility]];
				}
			  } 
			  
			  else if (key === 'condominialParkingPossessionType') {
				const possessionType = formValues.condominialParkingPossessionType?.value;
				if (possessionType) {
				  newCoeff = initialCoefficients[selectedReportType]?.condominialParkingPossessionType?.[coefficientCondominialParkingPossessionTypeMap[possessionType]];
				}
			  } 

			  else if (key === 'internalState') {
				const state = formValues.internalState?.value;
				if (state) {
				  newCoeff = initialCoefficients[selectedReportType]?.internalState?.[coefficientInternalStateMap[state]];
				}
			  }

			  else if (key === 'mainView') {
				const view = formValues.mainView?.value;
				if (view) {
				  newCoeff = initialCoefficients[selectedReportType]?.mainView?.[coefficientMainViewMap[view]];
				}
			  }

			  else if (key === 'internalDistribution') {
				const distributionType = formValues.internalDistribution?.value;
				if (distributionType) {
					newCoeff = initialCoefficients[selectedReportType]?.internalDistribution?.[coefficientInternalDistributionMap[distributionType]];;
				}
			} 
			

			  else if (key === 'noisiness') {
				const noisinessType = formValues.noisiness?.value;
				if (noisinessType) {
				  newCoeff = initialCoefficients[selectedReportType]?.noisiness?.[coefficientNoisinessMap[noisinessType]];
				}
			  } 

			  else if (key === 'brightness') {
				const brightnessType = formValues.brightness?.value;
				if (brightnessType) {
				  newCoeff = initialCoefficients[selectedReportType]?.brightness?.[coefficientBrightnessMap[brightnessType]];
				}
			  } 

			else if (key === 'ceilingHeight') {
				const height = formValues.ceilingHeight?.value;
				if (height) {
					newCoeff = initialCoefficients[selectedReportType]?.ceilingHeight?.[coefficientCeilingHeightMap[height]];
				}
			}

			else if (key === 'finishingQuality') {
				const quality = formValues.finishingQuality?.value;
				if (quality) {
				  newCoeff = initialCoefficients[selectedReportType]?.finishingQuality?.[coefficientFinishingQualityMap[quality]];
				}
			  }

			  else if (key === 'energeticClass') {
				const energyClass = formValues.energeticClass?.value;
				if (energyClass) {
				  newCoeff = initialCoefficients[selectedReportType]?.energeticClass?.[coefficientEnergeticClassMap[energyClass]];
				}
			  }

			  else if (key === 'heater') {
				const heaterType = formValues.heater?.value;
				if (heaterType) {

				  newCoeff = initialCoefficients[selectedReportType]?.heater?.[coefficientHeaterMap[heaterType]];
				}
			  }

			  else if (key === 'parkingAccessibility') {
				const parking = formValues.parkingAccessibility?.value;
				if (parking) {
				  newCoeff = initialCoefficients[selectedReportType]?.parkingAccessibility?.[coefficientParkingAccessibilityMap[parking]];
				}
			  }

			  else if (selectedReportType == 'Uffici' && key === 'positionOffice') {
				const position = formValues.positionOffice?.value;
				if (position) {
				  newCoeff = initialCoefficients[selectedReportType]?.position?.[coefficientPositionMap[position]];
				}
			  }

			  else if (selectedReportType == 'Negozi' && key === 'positionShops') {
				const position = formValues.positionShops?.value;
				if (position) {
				  newCoeff = initialCoefficients[selectedReportType]?.position?.[coefficientShopVisibilityMap[position]];
				}
			  }

			  else if (selectedReportType == 'Box e autorimesse' && key === 'garage') {
				const garage = formValues.garage?.value;
				if (garage ) {
				  newCoeff = initialCoefficients[selectedReportType]?.garage?.[coefficientAutorimessaTypeMap[garage]];
				}
			  }

			  //CONCESSIONE : controlla questa proprietà (coefficentabile)
			else if (( selectedReportType == 'Posti auto scoperti') && key === 'propertyType') {
				const possessionType = formValues.propertyType?.value;
				//se ha un valore definito
				if (possessionType) {
				//trova il valore del coefficiente tra quelli standard
					newCoeff = initialCoefficients[selectedReportType]?.propertyType?.[coefficientUncoveredParkingPossessionTypeMap[possessionType]];
				}

				if(possessionType == "concessione" && formData.startConcession && formData.endConcession){
					// Verifichiamo che entrambe le date siano valide
					if (isValidYear(formData.startConcession) && isValidYear(formData.endConcession) && formData.endConcession >= formData.startConcession) {
					
						const startYear = parseInt(formData.startConcession);
						const endYear = parseInt(formData.endConcession);
						//trova durata concessione
						const concessionDuration = endYear - startYear;
						//trova percentuale di rivalutazione
						const percentageValue = 100 / concessionDuration
						newCoeff =  percentageValue
					} else {
						//le date non sono valide, usiamo il valore standard
						newCoeff = initialCoefficients[selectedReportType]?.propertyType?.[coefficientPossessionTypeMap[possessionType]];
					}
				}
			} 

			else if (selectedReportType == 'Box e autorimesse'  && key === 'propertyType') {
				const possessionType = formValues.propertyType?.value;
				//se ha un valore definito
				if (possessionType) {
				//trova il valore del coefficiente tra quelli standard
					newCoeff = initialCoefficients[selectedReportType]?.propertyType?.[coefficientPossessionTypeMap[possessionType]];
				}

				if(possessionType == "concessione" && formData.startConcession && formData.endConcession){
					// Verifichiamo che entrambe le date siano valide
					if (isValidYear(formData.startConcession) && isValidYear(formData.endConcession) && formData.endConcession >= formData.startConcession) {
					
						const startYear = parseInt(formData.startConcession);
						const endYear = parseInt(formData.endConcession);
						//trova durata concessione
						const concessionDuration = endYear - startYear;
						//trova percentuale di rivalutazione
						const percentageValue = 100 / concessionDuration
						newCoeff =  percentageValue
				
					} else {
						//le date non sono valide, usiamo il valore standard
						newCoeff = initialCoefficients[selectedReportType]?.propertyType?.[coefficientPossessionTypeMap[possessionType]];

					}
				}
			} 

			else if ( selectedReportType == 'Posti auto coperti'  && key === 'propertyType') {
			const possessionType = formValues.propertyType?.value;

			//se ha un valore definito
			if (possessionType) {
			//trova il valore del coefficiente tra quelli standard
				newCoeff = initialCoefficients[selectedReportType]?.propertyType?.[coefficientCoveredParkingPossessionTypeMap[possessionType]];
			}

			if(possessionType == "concessione" && formData.startConcession && formData.endConcession){
				// Verifichiamo che entrambe le date siano valide
				if (isValidYear(formData.startConcession) && isValidYear(formData.endConcession) && formData.endConcession >= formData.startConcession) {
				
					const startYear = parseInt(formData.startConcession);
					const endYear = parseInt(formData.endConcession);
					//trova durata concessione
					const concessionDuration = endYear - startYear;
					//trova percentuale di rivalutazione
					const percentageValue = 100 / concessionDuration
					newCoeff =  percentageValue
			
				} else {
					//le date non sono valide, usiamo il valore standard
					newCoeff = initialCoefficients[selectedReportType]?.propertyType?.[coefficientPossessionTypeMap[possessionType]];

				}
			}
			} 

			  

			  else if (selectedReportType == 'Box e autorimesse' && key === 'boxHeight') {
				const height = formValues.boxHeight?.value;
				if (height) {
				  newCoeff = initialCoefficients[selectedReportType]?.height?.[coefficientBoxHeightMap[height]];
				}
			  }
			  
			  else if ((selectedReportType == "Capannoni tipici" || selectedReportType == "Capannoni industriali") && key === 'accessibilityTypeShed') {
				const height = formValues.accessibilityTypeShed?.value;
				if (height) {
				  newCoeff = initialCoefficients[selectedReportType]?.access?.[coefficientCapAccessMap[height]];
				}
			  }
			  
  

			  // Altrimenti, ottenere il coefficiente dalla tabella dei coefficienti
			  else {
				newCoeff = initialCoefficients[selectedReportType]?.[key]?.[formValues?.[key]?.value];
			  }
	  
			  // Assegna il nuovo coefficiente se è diverso da quello attuale
			  if (newCoeff !== undefined) {
				updatedFormValues[key] = {
				  ...formValues[key],
				  coeff: newCoeff
				};
			  }
			} else {
			  // Inizializza i valori predefiniti se il campo non è un campo speciale

			  const newKey = key
			  updatedFormValues[key] = { ...formValues[key], coeff: initialCoefficients[selectedReportType][newKey] };
			}
		  }
		}
	  
		// Aggiorna lo stato solo se i formValues sono stati modificati
		if (JSON.stringify(updatedFormValues) !== JSON.stringify(formValues)) {
		  setFormValues(updatedFormValues);
		}
	  }, [formValues.floor?.value,
		formValues.parkingAccessibility?.value,
		formValues.boxAccessibility?.value,
		formValues.boxLocation?.value,
/* 		formValues.boxPossessionType?.value,
 */		formValues.coveredParkingAccessibility?.value,
/* 		formValues.coveredParkingPossessionType?.value,
 */		formValues.coveredParkingLocation?.value,
		formValues.uncoveredParkingLocation?.value,
/* 		formValues.uncoveredParkingPossessionType?.value,
 */		formValues.uncoveredParkingAccessibility?.value,
/* 		formValues.condominialParkingPossessionType?.value,
 */		formValues.mainView?.value,
		formValues.noisiness?.value,
		formValues.brightness?.value,
		formValues.internalDistribution?.value,
		formValues.internalState?.value,
		formValues.finishingQuality?.value,
		formValues.energeticClass?.value,
		formValues.heater?.value,
		formValues.buildingAge?.value,
		formValues.buildingState?.value,
		formValues.grossCoveredMq?.value,
		formValues.basementMq?.value,
		formValues.finishingVerandaMq?.value,
		formValues.notFinishingVerandaMq?.value,
		formValues.mezzanineMq?.value,
		formValues.canopyMq?.value,
		formValues.canopyFullHeightMq?.value,
		formValues.balconyMq?.value,
		formValues.terraceMq?.value,
		formValues.gardenMq?.value,
		formValues.otherUncoveredMq?.value,
		formValues.lodgeMq?.value,
		formValues.solarPavementMq?.value,
		formValues.winery?.value,
		formValues.garret?.value,
		formValues.box?.value,
		formValues.coveredParkingSpace?.value,
		formValues.uncoveredParkingSpace?.value,
		formValues.condominialParkingSpace?.value,

		formValues.positionShops?.value,
		formValues.positionOffice?.value,


		formValues.boxHeight?.value,
		formValues.garage?.value,

		formValues.propertyType?.value,

		formValues.accessibilityTypeShed?.value,

		formData?.lift, formData?.typology,
		formData?.startConcession, formData?.endConcession
	]);
	  
		  

	//GESTISCE IL CAMBIO DI VALORE DEI CAMPI CUSTOM
	const handleValueChange = (field, key, value) => {
	setFormValues((prevState) => ({
		...prevState,
		[field]: {
		...prevState[field],
		[key]: value,
		},
	}));
	};

	// GESTISCE IL CAMBIO DI VALORE DEI COEFFICIENTI
	const handleCoeffChange = (event, newValue) => {
		const { name } = event.target;
	
		// Aggiorna il coefficiente per il campo corrente
		setFormValues(prevState => ({
		...prevState,
		[name]: {
			...prevState[name],
			coeff: newValue,
		},
		}));
	
		// Se il campo modificato è buildingState, aggiorna anche il coefficiente di buildingAge
		if (name === 'buildingState') {
		setFormValues(prevState => ({
			...prevState,
			buildingAge: {
			...prevState.buildingAge,
			coeff: newValue,
			},
		}));
		}
	
		// Se il campo modificato è buildingAge, aggiorna anche il coefficiente di buildingState
		if (name === 'buildingAge') {
		setFormValues(prevState => ({
			...prevState,
			buildingState: {
			...prevState.buildingState,
			coeff: newValue,
			},
		}));
		}
	};
	



	

	console.log(formValues, "FORM VALUES NEW")

	return (
		<>

			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="Nuova Valutazione" />
				</div>
			</div>
			<ToastContainer />
			<div>
			<h5>{getEvalInfo()}</h5>
			</div>
			<div className="step-show">
				<div className="tabs-div">
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(0);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "127px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2 5C2 2.79086 3.79086 1 6 1H106.679C108.114 1 109.44 1.76914 110.152 3.01544L123.866 27.0154C124.569 28.2452 124.569 29.7548 123.866 30.9846L110.152 54.9846C109.44 56.2309 108.114 57 106.679 57H6C3.79086 57 2 55.2091 2 53V5Z"
									fill={
										count >= 0 ? (count >= 1 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 0 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title1"
								style={{
									color:
										count >= 0
											? count >= 1
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Tipologia
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 0 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 1 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 0 ? "" : "none",
									position: "absolute",
									left: "-9px",
								}}>
								Tipologia
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(1);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "168px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M151.152 3.01544C150.439 1.76914 149.114 1 147.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89263 57H147.679C149.114 57 150.439 56.2309 151.152 54.9846L164.866 30.9846C165.569 29.7548 165.569 28.2452 164.866 27.0154L151.152 3.01544Z"
									fill={
										count >= 1 ? (count >= 2 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 1 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 1
											? count >= 2
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Caratteristiche
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 1 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 2 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 1 ? "" : "none",
									position: "absolute",
									left: "-20px",
								}}>
								Caratteristiche
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(2);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "138px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M121.152 3.01544C120.439 1.76914 119.114 1 117.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82133 57 6.89262 57H117.679C119.114 57 120.439 56.2309 121.152 54.9846L134.866 30.9846C135.569 29.7548 135.569 28.2452 134.866 27.0154L121.152 3.01544Z"
									fill={
										count >= 2 ? (count >= 3 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 2 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 2
											? count >= 3
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Dotazioni
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 2 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 3 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 2 ? "" : "none",
									position: "absolute",
									left: "-8px",
								}}>
								Dotazioni
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(3);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "102px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M85.1516 3.01544C84.4394 1.76914 83.114 1 81.6786 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H81.6786C83.114 57 84.4394 56.2309 85.1516 54.9846L98.8659 30.9846C99.5686 29.7548 99.5686 28.2452 98.8659 27.0154L85.1516 3.01544Z"
									fill={
										count >= 3 ? (count >= 4 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 3 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 3
											? count >= 4
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Dati
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 3 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 4 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 3 ? "" : "none",
									position: "absolute",
									left: "8px",
								}}>
								Dati
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(4);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "120px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M103.152 3.01544C102.439 1.76914 101.114 1 99.6786 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H99.6786C101.114 57 102.439 56.2309 103.152 54.9846L116.866 30.9846C117.569 29.7548 117.569 28.2452 116.866 27.0154L103.152 3.01544Z"
									fill={
										count >= 4 ? (count >= 5 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 4 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 4
											? count >= 5
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Allegati
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 4 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 5 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 4 ? "" : "none",
									position: "absolute",
									left: "-4px",
								}}>
								Allegati
							</div>
						</div>
					</div>
					<div
						className="tab-icon"
						onClick={(e) => {
							setStep(5);
						}}>
						<div className="desktop-icon">
							<svg style={{ width: "116px", height: "58px" }}>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.41966 6.98456C1.89587 4.31793 3.82134 1 6.89263 1H110C112.209 1 114 2.79086 114 5V53C114 55.2091 112.209 57 110 57H6.89263C3.82134 57 1.89587 53.6821 3.41966 51.0154L14.8659 30.9846C15.5686 29.7548 15.5686 28.2452 14.8659 27.0154L3.41966 6.98456Z"
									fill={
										count >= 5 ? (count >= 6 ? "#83112F" : "#FFF8F8") : "#fff"
									}
									stroke={count >= 5 ? "#83112F" : "#C1C9D2"}
								/>
							</svg>
							<div
								className="tab-icon-title"
								style={{
									color:
										count >= 5
											? count >= 6
												? "#FFF8F8"
												: "#83112F"
											: "#3C4257",
								}}>
								Correlati
							</div>
						</div>
						<div
							className="mobile-icon"
							style={{ position: "relative", width: "40px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}>
								<svg height="20" width="20">
									<ellipse
										cx="10"
										cy="10"
										rx="10"
										ry="10"
										fill="#F1E2E2CF"
										style={{
											margin: "auto",
											display: count === 5 ? "" : "none",
										}}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
								<svg
									height="10"
									width="10"
									style={{
										position: "absolute",
									}}>
									<ellipse
										cx="5"
										cy="5"
										rx="5"
										ry="5"
										fill={count >= 6 ? "#83112F" : "#E0E0E0"}
									/>
									Sorry, your browser does not support inline SVG.
								</svg>
							</div>
							<div
								className="tab1-mobile-title"
								style={{
									display: count === 5 ? "" : "none",
									position: "absolute",
									left: "-5px",
								}}>
								Correlati
							</div>
						</div>
					</div>
				</div>

				<div className="content-container">
					{count === 0 && (
						<Typology
							formData={formData}
							onChange={onChange}
							units={units}
							setUnitsIndex={setUnitsIndex}
						/>
					)}
					{count === 1 && <Feature formData={formData} onChange={onChange} 
					handleValueChange={handleValueChange}
					handleCoeffChange={handleCoeffChange}
					formValues={formValues}
					/>}
					{count === 2 && <Amenity formData={formData} onChange={onChange} />}
					{count === 3 && <Data formData={formData} onChange={onChange} />}
					{count === 4 && (
						<Attachment formData={formData} onChange={onChange} />
					)}
					{count === 5 && <Related formData={formData} onChange={onChange} />}
					<div className="d-flex justify-content-end mt-5">
						{count === 0 ? (
							<button
								type="button"
								className="general-btn white-btn me-2 w-193"
								onClick={() => previousPage()}>
								Annulla
							</button>
						) : (
							<button
								type="button"
								className="general-btn white-btn me-2 w-193"
								onClick={() => backward()}>
								Indietro
							</button>
						)}
						{count === 5 ? (
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={save}>
								Crea Report
							</button>
						) : (
							<button
								type="button"
								className="general-btn pink-btn w-193"
								onClick={() => forward()}>
								Avanti
							</button>
						)}
					</div>
				</div>
			</div>

		</>
	);
};

export default CreateEvaluation;
