import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import {Row, Col} from "react-bootstrap";

import { connect } from "react-redux";
import { getProvince, getMunicipality } from "../../../actions/addressAction";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { BsPlusCircleFill } from "react-icons/bs";

import ButtonRm from "../../ButtonRm";

import delete_icon from "../../../assets/images/delete.svg";
import ImgFileCard from "./ImgFileCard";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import {type as category} from "../constant.js"

import Badge from 'react-bootstrap/Badge';
import CustomBadge from "../../CustomBadge.js";

import identificativo from "../../../assets/images/icon-custom-toggle/identificativo-icon.svg"
import categoria from "../../../assets/images/icon-custom-toggle/categoria-icon.svg"
import indirizzo from "../../../assets/images/icon-custom-toggle/indirizzo-icon.svg"



const schema = yup.object().shape({
	category: yup.string().required("Seleziona la categoria"),
	typology: yup.string(),
	address: yup.string().required("Inserisci l’indirizzo"),
	houseNumber: yup.string().required("Inserisci il civico"),
	scale: yup.string(),
	floor: yup.string(),
	internal: yup.string(),
	cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
	province: yup.string().required("Seleziona la provincia"),
	municipality: yup.string().required("Seleziona il comune"),
	name: yup.string().required("Inserisci un identificativo")
		
	});

const Category = (props) => {

	console.log(props)

	//GET INFO TO POPULATR ADRESS OPTIONS
	useEffect(() => {
		props.getProvince();
	}, []);

	useEffect(() => {
		props.getMunicipality(props.province[0]);
	}, [props.province]);

	const handleProvince = (e) => {
		props.onChange(e);
		props.getMunicipality(e.target.value);
	};

	//HANDLE ACCORDION COMPONENT
	const [activeKey, setActiveKey] = useState('');

	//VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		// controlla se ci sono errori di validazione
		console.log(data)
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
		props.forward()
	};

	//HANDLE IDENTIFIER
	const handleChange = (e) => {
		let ele = { target: { name: "image", value: e.target.files[0] } };
		props.onChange(ele);
	};
	const deleteImage = () => {
		let ele = { target: { name: "image", value: "" } };
		props.onChange(ele);
	};

	return (
		<>

		<Form onSubmit={handleSubmit(onSubmit)} noValidate>
			<div className="form-item-align-box d-block">
			<Accordion defaultActiveKey={['0', '1']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
			<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
						<CustomToggle eventKey="2" activeKey={activeKey} /* img={identificativo} */>Identificativo
							<span className="mandatory-field">
								*
							</span>
							<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

						</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
							<Col md={12}>
							<label className="f-label fs-14 mb-2">
								Scegli un identificativo univoco {/* e un’immagine */} per questa unità
								immobiliare
							</label>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Identificativo
									<span className="mandatory-field">*
									</span>
									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

								</Form.Label>
								<Form.Control 
									{...register("name")}
									type="text" 
									name="name"
									value={props.formData.name}
									onChange={props.onChange}
									isInvalid={!!errors.name}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.name?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>
						<div className="row mt-3">
					{/* <div className="col-md-6 d-flex justify-content-between align-items-end">
						<div className="d-flex flex-column col-sm-8">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Immagine unità immobiliare
							</label>
							<label
								className="input-label border fs-16 text-dark rounded-3 border-opacity-50 w-100 d-flex align-items-center p-2"
								style={{ height: "40px", textAlign: "center" }}>
								{props.formData.image ? props.formData.image.name : ""}
							</label>
						</div>
						<div className="col-sm-3">
							<label
								htmlFor="input-file"
								className="bag-btn w-100 general-btn"
								style={{  textAlign: "center" }}>
								Scegli
							</label>
							<input
								type="file"
								id="input-file"
								className="d-none"
								name="image"
								multiple={false}
								onChange={handleChange}></input>
						</div>
					</div> */}
				</div>
				{props.formData?.image && (
					<div style={{ position: "relative" }}>
						<ImgFileCard img={props.formData?.image} />
						{/* <div style={{ position: "absolute", top: "10px", left: "238px" }}>
							<button
								type="button"
								className="btn round-icon-btn small"
								onClick={deleteImage}>
								<img loading="lazy" src={delete_icon} alt="icon" />
							</button>
						</div> */}
					</div>
				)}	
							
					</Accordion.Body>
				</Accordion.Item>
				
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey} /* img={categoria} */>Categoria<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<Row>
						<Col md={12} className="mb-3">
							<label className="f-label fs-14">
							Le categorie ti permettono di raggruppare diversi tipi di unità immobiliari e ti aiutano a filtrare le unità nelle tue liste.
							</label>
							</Col>
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Categoria <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
										{...register("category")}
										isInvalid={!!errors.category}
										value={props.formData.category}
										onChange={props.onChange} >
										{category.map((menu, index) => {
										return (
											<option key={index} value={menu}>
												{menu}
											</option>
										);
										})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.category?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							</Row>
							<Row>
							<Col md={12} className="mb-3">
							<label className="f-label fs-14 mt-3">
								Serve ad identificare in modo preciso la tipologia dell’unità immobiliare (es Attico)
							</label>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Tipologia						
								</Form.Label>
								<Form.Control 
									{...register("typology")}
									type="text" 
									name="typology"
									value={props.formData.typology}
									onChange={props.onChange}
									isInvalid={!!errors.typology}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.typology?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>						
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey} /* img={indirizzo} */>Indirizzo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
					</Card.Header>
					<Accordion.Body>						
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Indirizzo (Via/V.le/Piazza/Corso, ecc.)					
								</Form.Label>
								<Form.Control 
									{...register("address")}
									type="text" 
									name="address"
									value={props.formData.address}
									onChange={props.onChange}
									isInvalid={!!errors.address}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.address?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Numero Civico						
								</Form.Label>
								<Form.Control 
									{...register("houseNumber")}
									type="text" 
									name="houseNumber"
									value={props.formData.houseNumber}
									onChange={props.onChange}
									isInvalid={!!errors.houseNumber}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.houseNumber?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Scala						
								</Form.Label>
								<Form.Control 
									{...register("scale")}
									type="text" 
									name="scale"
									value={props.formData.scale}
									onChange={props.onChange}
									isInvalid={!!errors.scale}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.scale?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Piano						
								</Form.Label>
								<Form.Control 
									{...register("floor")}
									type="text" 
									name="floor"
									value={props.formData.floor}
									onChange={props.onChange}
									isInvalid={!!errors.floor}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.floor?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									Interno						
								</Form.Label>
								<Form.Control 
									{...register("internal")}
									type="text" 
									name="internal"
									value={props.formData.internal}
									onChange={props.onChange}
									isInvalid={!!errors.internal}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.internal?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>

							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Provincia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
										{...register("province")}
										isInvalid={!!errors.province}
										value={props.formData.province}
										onChange={handleProvince} 
										>
										<option></option>
										 {props.province?.length > 0 &&
										 props.province.map((item, key) => {
									return (
										<option key={key} value={item}>
											{item}
										</option>
									);
								})} 
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.province?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>

						</Row>
						<Row>
							
							<Col md={6}>
								<Form.Group className="mb-3" >
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Comune <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

									</Form.Label>
									<Form.Select 
										{...register("municipality")}
										isInvalid={!!errors.municipality}
										value={props.formData.municipality}
										onChange={props.onChange}
										>
										<option></option>
										 {props.municipality?.length > 0 &&
										 props.municipality.map((item, key) => {
											return (
												<option key={key} value={item}>
													{item}
												</option>
											)
										})}
									</Form.Select>
									<Form.Control.Feedback type="invalid" >
										{errors.municipality?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							
							<Col md={6}>
							<Form.Group className="mb-3" >
								<Form.Label 
									className="f-label fs-16 d-flex align-items-center
mb-2"
								>
									CAP					
								</Form.Label>
								<Form.Control 
									{...register("cap")}
									type="text" 
									name="cap"
									value={props.formData.cap}
									onChange={props.onChange}
									isInvalid={!!errors.cap}
								/>
								<Form.Control.Feedback type="invalid" >
								{errors.cap?.message}
								</Form.Control.Feedback>
							</Form.Group>
							</Col>
						</Row>							
					</Accordion.Body>
				</Accordion.Item>

			
			</Accordion>
			</div>
			<div className="d-flex justify-content-end mt-5">
				<ButtonRm
				variant="tertiary"
				onClick={() => props.previousPage()}
				>
					Annulla
				</ButtonRm>

				<ButtonRm
					variant="primary"
					type="submit"
					className="ms-2"
				>
					Avanti
				</ButtonRm>
			  </div>

		</Form>

		</>
	);
};


const mapStateToProps = (state) => ({
	province: state.addressReducer.province,
	municipality: state.addressReducer.municipality,
});
export default connect(mapStateToProps, { getProvince, getMunicipality })(
	Category
);

