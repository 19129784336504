import React, { useState } from "react";
import NumericInput from "react-numeric-input";
import CurrencyInput from "react-currency-input-field";
import { BsPlusCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import ReadCounterModal from "./UtilityModal";
import {Row, Col, Button, Modal, Table} from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ButtonRm from "../../ButtonRm";

import delete_icon from "../../../assets/images/delete.svg";
import ImgFileCard from "./ImgFileCard";

import CustomToggle from "../../CustomToggle";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import TariffeModal from "./TariffeModal";

import CustomBadge from "../../CustomBadge";


const schema = yup.object().shape({

		
});

const Utilities = (props) => {
	const navigate = useNavigate();
	const goSupplier = () => {
		navigate("/fornitori/create");
	};

		//HANDLE ACCORDION COMPONENT
		const [activeKey, setActiveKey] = useState('');

		//VALIDAZIONE INPUT
		const { register, handleSubmit, formState: { errors }, reset } = useForm({
			resolver: yupResolver(schema),
		});


	//ADD LETTURA

	const [show, setShow] = useState(false);


 	const addCounter = (counter) => {
		let temp = [...props.formData.readCounters];
		temp.push(counter);
		let e = { target: { name: "readCounters", value: temp } };
		props.onChange(e);
		setShow(false);
	}; 

	console.log(props.formData.readCounters)

	//VIEW E EDIT LETTURA

	const [showSelectedCounter, setShowSelectedCounter] = useState(false)
	const [selectedCounter, setSelectedCounter] = useState({})
	const [counterEdit, setCounterEdit] = useState(-1);


	function closeSelectedCounter(){
		setShowSelectedCounter(false)
		setSelectedCounter({})
		setCounterEdit(null)

	}

	function handleCounter(id){
		setShowSelectedCounter(true)
		let current =  props.formData.readCounters.find((item, index) => index == id)
		setSelectedCounter(current)
		setCounterEdit(id)
	}


	function updateCounter (counter){
		let temp =  props.formData.readCounters.map((item, index) => {
			return index == counterEdit?
			counter:
			item
		})
		props.setFormData({ ...props.formData, ["readCounters"]: temp });
		closeSelectedCounter()
	};

	const deleteCounter = () => {
		let temp =  props.formData.readCounters.filter((item, index) => index != counterEdit)
		props.setFormData({ ...props.formData, ["readCounters"]: temp }); 
		closeSelectedCounter()
	}; 

	//TARIFFE MODAL

	const [showTariffe, setShowTariffe] = useState(false)
	const [tariffaType, setTariffaType] = useState("")

	function handleShowTariffe(type){
		setTariffaType(type)
		setShowTariffe(true)
	}


		
	return (
		<>
		<Form>
			<div className="form-item-align-box d-block">
			<h2 className="fs-18 text-dark mb-2 fw-bold mb-3">UTENZE</h2>
			<Accordion defaultActiveKey={['0']} alwaysOpen onSelect={(eventKey) => setActiveKey(eventKey)}>
				<Accordion.Item eventKey="0" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="0" activeKey={activeKey}>Elettricità</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row mb-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Fornitore
								</label>
								<div className="d-flex">
									<select
										name="electricitySupplier" 
										style={{width:"90%"}}
										value={props.formData.electricitySupplier}
										onChange={(e) => {
											props.onChange(e);
										}}>
										<option value="">Seleziona</option>
										{props.supplier?.map((menu, index) => {
											return (
												<option
													key={index}
													value={menu.name + " " + menu.surname}>
													{menu.name + " " + menu.surname}
												</option>
											);
										})}
									</select>
									<BsPlusCircleFill
										className="col-sm-2 col-md-1 fs-35 plus-button"
										onClick={goSupplier}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label className="f-label fs-16 d-flex align-items-center
mb-2">
									Matricola contatore
								</label>
								<input
									name="electricityCounterNumber"
									value={props.formData.electricityCounterNumber}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
								/>
							</div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 ">
									Codice POD
								</label>
								<input
									name="podCode"
									value={props.formData.podCode}
									onChange={(e) => {
										props.onChange(e);
									}}
									type="text"
								/>
							</div>
						</div>
						<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Stato
									</label>
									<div className="d-block">
										<select
											name="electricityState"
											value={props.formData.electricityState}
											onChange={(e) => {
												props.onChange(e);
											}}>
												<option value="aperto">Aperto</option>
												<option value="chiuso">Chiuso</option>
											</select>
									</div>
								</div>
							</div>
					</div>	
					<div className="row mt-3">
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Codice utente
									</label>
									<input
										type="text"
										name="electricityUserCode"
										onChange={(e) => {
											props.onChange(e);
										}}
										value={props.formData.electricityUserCode}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex flex-column">
									<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
										Piano tariffario
									</label>
									<div className="d-flex justify-content-between align-items-center">
										<select
											type="text"
											name="electricityTariffPlan"
											onChange={(e) => {
												props.onChange(e);
											}}
											value={props.formData.electricityTariffPlan}
										>
											<option></option>
											{
													props.formData?.electricityPlan?.length>0 &&
													props.formData.electricityPlan.map((el,index) => {
														return <option  key={index} value={el.name}>{el.name}</option>
													})
												}
										</select>
										<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={() => {handleShowTariffe("electricityPlan")}}
												/>
											</div> 
									</div>
								</div>
							</div>
						</div>				
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="1" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="1" activeKey={activeKey}>Gas</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Fornitore
										</label>
										<div className="d-flex justify-content-between align-items-center">
											<select
												name="gasSupplier"
												value={props.formData.gasSupplier}
												onChange={props.onChange}>
												{" "}
												{props.supplier?.map((menu, index) => {
													return (
														<option key={index}
														value={menu.name + " " + menu.surname}>
														{menu.name + " " + menu.surname}
														</option>
													);
												})}
											</select>
											<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={goSupplier}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label className="f-label fs-16 d-flex align-items-center
mb-2">
											Matricola contatore
										</label>
										<input
											name="gasCounterNumber"
											value={props.formData.gasCounterNumber}
											onChange={(e) => {
												props.onChange(e);
											}}
											type="text"
										/>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Codice PDR
										</label>
										<input
											name="pdrCode"
											value={props.formData.pdrCode}
											onChange={(e) => {
												props.onChange(e);
											}}
											type="text"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Stato
										</label>
										<div className="d-block">
											<select
												name="gasState"
												value={props.formData.gasState}
												onChange={props.onChange}>
													<option value="aperto">Aperto</option>
												<option value="chiuso">Chiuso</option>
												</select>
										</div>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Codice utente
										</label>
										<input
											type="text"
											name="gasUserCode"
											onChange={props.onChange}
											value={props.formData.gasUserCode}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Piano tariffario
										</label>
										<div className="d-flex justify-content-between align-items-center">
											<select
												name="gasTariffPlan"
												onChange={props.onChange}
												value={props.formData.gasTariffPlan}
											>
												<option></option>
												{
													props.formData?.gasPlan?.length>0 &&
													props.formData.gasPlan.map((el,index) => {
														return <option value={el.name}>{el.name}</option>
													})
												}
												
											</select>
											<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={() => {handleShowTariffe("gasPlan")}}
												/>
											</div> 
										</div>
									</div>
								</div>
							</div>			
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="2" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="2" activeKey={activeKey}>Acqua fredda</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row ">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Fornitore
										</label>
										<div className="d-flex justify-content-between align-items-center">
											<select
												name="coldWaterSupplier"
												value={props.formData.coldWaterSupplier}
												onChange={props.onChange}>
												{props.supplier?.map((menu, index) => {
													return (
														<option key={index}
														value={menu.name + " " + menu.surname}>
														{menu.name + " " + menu.surname}
														</option>
													);
												})}
											</select>
											<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={goSupplier}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Codice utente
										</label>
										<input
											type="text"
											name="coldWaterUserCode"
											onChange={props.onChange}
											value={props.formData.coldWaterUserCode}
										/>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Stato
										</label>
										<div className="d-block">
											<select
												name="coldWaterState"
												value={props.formData.coldWaterState}
												onChange={props.onChange}>
													<option value="aperto">Aperto</option>
												<option value="chiuso">Chiuso</option>
												</select>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Piano tariffario
										</label>
										<div className="d-flex justify-content-between align-items-center">
											<select
												name="coldWaterTariffPlan"
												onChange={props.onChange}
												value={props.formData.coldWaterTariffPlan}
											>
												<option></option>
												{
													props.formData?.coldWaterPlan?.length>0 &&
													props.formData.coldWaterPlan.map((el,index) => {
														return <option value={el.name}>{el.name}</option>
													})
												}
											</select>
											<div
												style={{ padding: "3px" }}>
												<BsPlusCircleFill
													className="add-section-btn"
													onClick={() => {handleShowTariffe("coldWaterPlan")}}
												/>
											</div> 
										</div>
									</div>
								</div>
							</div>		
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="3" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="3" activeKey={activeKey}>Acqua Calda</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Fornitore
							</label>
							<div className="d-flex">
								<select
									name="hotWaterSupplier"
									value={props.formData.hotWaterSupplier}
									onChange={(e) => {
										props.onChange(e);
									}}>
									<option value="">Seleziona</option>
									{props.supplier?.map((menu, index) => {
										return (
											<option
												key={index}
												value={menu.name + " " + menu.surname}>
												{menu.name + " " + menu.surname}
											</option>
										);
									})}
								</select>
								<BsPlusCircleFill
									className="col-sm-2 col-md-1 fs-35 plus-button"
									onClick={goSupplier}
								/>
							</div>
						</div>
					</div>
				</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="4" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="4" activeKey={activeKey}>Riscaldamento</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row mt-3">
						<div className="col-md-6">
							<div className="d-flex flex-column">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
									Fornitore
								</label>
								<div className="d-flex">
									<select
										className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
										name="heatingSupplier"
										value={props.formData.heatingSupplier}
										onChange={(e) => {
											props.onChange(e);
										}}>
										<option value="">Seleziona</option>
										{props.supplier?.map((menu, index) => {
											return (
												<option
													key={index}
													value={menu.name + " " + menu.surname}>
													{menu.name + " " + menu.surname}
												</option>
											);
										})}
									</select>
									<BsPlusCircleFill
										className="col-sm-2 col-md-1 fs-35 plus-button"
										onClick={goSupplier}
									/>
								</div>
							</div>
						</div>
						<div className="col-md-6">
									<div className="d-flex flex-column">
										<label className="f-label fs-16 d-flex align-items-center
mb-2">
											Matricola contatore
										</label>
										<input 
										type="text" 
										name="boilerCode" 
										value={props.formData.boilerCode} 
										onChange={props.onChange}/>		
									</div>
								</div>
					</div>
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="5" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="5" activeKey={activeKey}>Costi annuali</CustomToggle>
					</Card.Header>
					<Accordion.Body>
					<div className="row">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Imposte patrimoniali (imu, tasi)
							</label>
							<CurrencyInput
								id="input-example"
								name="propertyTaxes"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={props.formData.propertyTaxes}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Imposte sul reddito (IRPEF o IRES)
							</label>
							<CurrencyInput
								id="input-example"
								name="incomeTaxes"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={props.formData.incomeTaxes}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Spese condominiali (lato proprietà)
							</label>
							<CurrencyInput
								id="input-example"
								name="condominiumFees"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={props.formData.condominiumFees}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
					{/* <div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Costi diretti CDA
							</label>
							<CurrencyInput
								id="input-example"
								name="directCostCda"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={props.formData.directCostCda}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div> */}
				</div>
				{/* <div className="row mt-3">
					<div className="col-md-6">
						<div className="d-flex flex-column">
							<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
								Costi indiretti CDA
							</label>
							<CurrencyInput
								id="input-example"
								name="indirectCostCda"
								decimalsLimit={2}
								groupSeparator="."
								decimalSeparator=","
								value={props.formData.indirectCostCda}
								suffix="€"
								onValueChange={(value, name) => {
									let e = {
										target: { name: name, value: value },
									};
									props.onChange(e);
								}}
							/>
						</div>
					</div>
				</div> */}
					</Accordion.Body>
				</Accordion.Item>

				<Accordion.Item eventKey="6" className="mb-2 accordionHeader">
					<Card.Header>
					<CustomToggle eventKey="6" activeKey={activeKey}>Letture contatori
					
					</CustomToggle>
					</Card.Header>
					<Accordion.Body>
						<div className="row py-2">
							<Col md={12}>
							<ButtonRm onClick={() => setShow(true)}>Nuovo</ButtonRm> 
							</Col>
						</div>	

						<Row>
							{props.formData.readCounters?.length> 0  ? (
								<div className="mt-4">
								  <Table className="result-table table-border out-border-none">
									<thead>
									  <tr>
										<th>Tipo</th>
										<th>Data</th>
										<th>Nome</th>
										<th>Valore</th>
									  </tr>
									</thead>
									<tbody>
									  { props.formData.readCounters.map((item, index) => {
										return (
										  <tr key={index} onClick={() => handleCounter(index)}>
											<td>{item.type}</td>
											<td>{new Date(item.date).toLocaleDateString()}</td>
											<td>{item.name}</td>
											<td>{item.value}</td>
										  </tr>
										);
									  })}
									</tbody>
								  </Table>
								</div>
							  ) : (
								<div className="unit-empty-img">
								  <span className="img-comment fs-18">
									Nessuna lettura
								  </span>
								</div>
							)}
							
						</Row>								
					</Accordion.Body>
				</Accordion.Item> 

				

			</Accordion>
			</div>
			{/* <div className="d-flex justify-content-end mt-5">						
				<button
					type="button"
					className="general-btn white-btn me-2 w-193"
					onClick={() => props.backward()}>
					Indietro
				</button>
				<button
					type="button"
					className="general-btn pink-btn w-193"
					onClick={() => props.forward()}>
					Avanti
				</button>
			</div> */}
		</Form>
		
		{/* CREATE LETTURA */}
		<Modal show={show}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={() => {
              setShow(false);
            }}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
          <ReadCounterModal  setShow={setShow} newMode={true} add={addCounter} />
        </Modal.Body>
      </Modal>
			
		{/* VIEW lettura */}
		 <Modal show={showSelectedCounter}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={closeSelectedCounter}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
		  <ReadCounterModal setShow={closeSelectedCounter} readCounter={selectedCounter}  newMode={false} add={updateCounter} delete={deleteCounter}/>
        </Modal.Body>
      </Modal> 

	  {/* TARIFFE MODAL */}
	  <Modal show={showTariffe}  className="right-full-pop" backdrop>
        <Modal.Body className="p-4">
          <Button
            variant=""
            onClick={() => {setShowTariffe(false)}}
            className="float-end border-0 bg-white"
            style={{ marginTop: "-1rem" }}
          >
            <i className="bi bi-x"></i>
          </Button>
		  <TariffeModal setShow={setShowTariffe} type={tariffaType} setFormData={props.setFormData} formData={props.formData}/>
        </Modal.Body>
      </Modal> 
		</>
	);
};

export default Utilities;
