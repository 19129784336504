import React, {useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TableSortLabel } from '@mui/material';
import CustomBadge from '../../CustomBadge';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import Form from "react-bootstrap/Form";
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { AiFillTool } from "react-icons/ai";




function formatDate(inputDate) {
    // Crea un oggetto Date dal tuo input
    const date = new Date(inputDate);
    
    // Ottiene i componenti della data
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // I mesi partono da 0
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Formatta i componenti per assicurarsi che abbiano sempre due cifre
    const monthFormatted = month < 10 ? `0${month}` : month;
    const dayFormatted = day < 10 ? `0${day}` : day;
    const hoursFormatted = hours < 10 ? `0${hours}` : hours;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
  
    // Combina i componenti per ottenere il formato desiderato
    return `${year}-${monthFormatted}-${dayFormatted}`;
  }
  

export default function TableView( {showCheckbox=true,...props}) {

  const priorityToNumber = (priority) => {
    switch (priority) {
      case 'high':
        return 3;
      case 'medium':
        return 2;
      case 'low':
        return 1;
      default:
        return 0;
    }
  };

  const getStatusOrder = (status) => {
    switch (status) {
      case 'aperta':
        return 2;
      case 'incorso':
        return 3;
      case 'risolta':
        return 1;
      case 'ritardo':
        return 4;
      default:
        return 0;
    }
  };

  const calculateStatus = (startDate, expirationDate, endDate) => {
    let status;
    let todayDate = new Date().toISOString().slice(0, 10);

    if (todayDate < startDate?.split(",").join("-")) {
      status = "aperta";
    } else if (todayDate >= startDate?.split(",").join("-") && todayDate <= expirationDate?.split(",").join("-")) {
      status = "incorso";
    } else if (endDate) {
      status = "risolta";
    } else if (todayDate > expirationDate?.split(",").join("-")) {
      status = "ritardo";
    }
    return status;
  };

  const navigate = useNavigate()

  const [filterText, setFilterText] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [key, setKey] = useState('')

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };

  const handleRequestSort = (property, key = "") => {
    let newOrder = 'asc';
    if (orderBy === property && order === 'asc') {
      newOrder = 'desc';
    }
    setOrderBy(property);
    setOrder(newOrder);
    setKey(key)
  };

  const filteredItems = props.item.filter((row) => {
    return row.name.toLowerCase().includes(filterText.toLowerCase());
  });

  let sortedItems = [...filteredItems];

  if (orderBy === 'expirationDate') {
    sortedItems.sort((a, b) => {
      let aValue = new Date(a.expirationDate).getTime();
      let bValue = new Date(b.expirationDate).getTime();
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    });
  } else if (orderBy === 'priority') {
    sortedItems.sort((a, b) => {
      let aValue = priorityToNumber(a.priority);
      let bValue = priorityToNumber(b.priority);
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    });
  } else if (orderBy === 'status') {
    sortedItems.forEach(item => {
      item.status = calculateStatus(item.startDate, item.expirationDate, item.endDate);
    });

    sortedItems.sort((a, b) => {
      let aValue = getStatusOrder(a.status);
      let bValue = getStatusOrder(b.status);
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    });
  } else if (orderBy === 'name'){
      sortedItems.sort((a, b) => {
        let aValue = a?.name.toLowerCase(); 
        let bValue = b?.name.toLowerCase();
        if (aValue < bValue) return order === 'asc' ? -1 : 1;
        if (aValue > bValue) return order === 'asc' ? 1 : -1;
        return 0;
      })
  } else if (orderBy === 'unitName'){
    sortedItems.sort((a, b) => {
      let aValue = a?.unitName.toLowerCase(); 
      let bValue = b?.unitName.toLowerCase();
      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    })
} else if (orderBy === 'assignee'){
  sortedItems.sort((a, b) => {
    let aValue = a?.assignee.toLowerCase(); 
    let bValue = b?.assignee.toLowerCase();
    if (aValue < bValue) return order === 'asc' ? -1 : 1;
    if (aValue > bValue) return order === 'asc' ? 1 : -1;
    return 0;
  })
}







 
    
  return (
    <TableContainer component={Paper} className="table-font table-activity">
      <Table stickyHeader aria-label="simple table  table-activity">
        <TableHead>
          <TableRow className="table-font table-act-head-cell">
{ showCheckbox && <TableCell style={{ minWidth: '10px', paddingLeft: "16px !important"  }} className="table-font table-act-head-cell"></TableCell>
}            <TableCell style={{ minWidth: '200px',  paddingLeft: "16px !important" }} className="table-font table-act-head-cell"          >
                  <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                > Nome
                </TableSortLabel>
            </TableCell>
            <TableCell style={{ minWidth: '150px', paddingLeft: "16px !important" }} className="table-font table-act-head-cell">
              <TableSortLabel
                  active={orderBy === 'priority'}
                  direction={orderBy === 'priority' ? order : 'asc'}
                  onClick={() => handleRequestSort('priority')}
                >
                  Priorità
                </TableSortLabel>
            </TableCell>

{/*             <TableCell style={{ minWidth: '160px' }} className="table-font">Data Inizio</TableCell>
 */}            <TableCell style={{ minWidth: '160px', paddingLeft: "16px" }} className="table-font table-act-head-cell"> <TableSortLabel
                  active={orderBy === 'expirationDate'}
                  direction={orderBy === 'expirationDate' ? order : 'asc'}
                  onClick={() => handleRequestSort('expirationDate')}
                >
                  Data Scadenza
                </TableSortLabel></TableCell>
            <TableCell style={{ minWidth: '150px' , paddingLeft: "16px"}} className="table-font table-act-head-cell">
              <TableSortLabel
                  active={orderBy === 'assignee'}
                  direction={orderBy === 'assignee' ? order : 'asc'}
                  onClick={() => handleRequestSort('assignee')}
                > Incaricato
                </TableSortLabel>
            </TableCell>
            <TableCell style={{ minWidth: '150px' , paddingLeft: "16px"}} className="table-font table-act-head-cell"> <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Stato
                </TableSortLabel></TableCell>
            <TableCell style={{ minWidth: '150px', paddingLeft: "16px"}} className="table-font table-act-head-cell"><TableSortLabel
                  active={orderBy === 'unitName'}
                  direction={orderBy === 'unitName' ? order : 'asc'}
                  onClick={() => handleRequestSort('unitName')}
                > Unità
                </TableSortLabel></TableCell>
            <TableCell style={{ minWidth: '150px', paddingLeft: "16px"}} className="table-font table-act-head-cell">Tipologia</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="table-font">
     {sortedItems.map((row) => {


const words = row.assignee? row.assignee.split(" ") : "" // Separazione delle parole in base agli spazi

let initials = "";

if (words.length >= 2) {
  initials = words[0].charAt(0) + words[1].charAt(0); // Estrazione delle iniziali delle prime due parole
} else if (words.length === 1) {
  initials = words[0].charAt(0); // Se c'è solo una parola, viene estratta solo la sua iniziale
}

      // Logica per calcolare lo status per ogni riga
      const startDate = row.startDate?.substr(0, 10);
      const expirationDate = row.expirationDate?.substr(0, 10);
      const endDate = row.endDate ? row.endDate.substr(0, 10) : "";
      let status = calculateStatus(row.startDate, row.expirationDate, row.endDate);


      // Funzione per ottenere il colore appropriato in base allo status
      const getStatusColor = (status) => {
        switch (status) {
          case "aperta":
            return "custom-badge-purple"; // Yellow
          case "incorso":
            return "custom-badge-blue"; // Blue
          case "risolta":
            return "custom-badge-lime"; // Green
          case "ritardo":
            return "custom-badge-orange"; // Red
          default:
            return "custom-badge-transparent"; // Grey
        }
      };

       // Generazione del label in base allo status
       let label;
       switch (status) {
         case "aperta":
           label = "Aperta";
           break;
         case "incorso":
           label = "In Corso";
           break;
         case "risolta":
           label = "Risolta";
           break;
         case "ritardo":
           label = "In Ritardo";
           break;
         default:
           label = "";
           break;
       }

         // Generazione del label per la priorità
         let priorityLabel;
         switch (row.priority) {
          case "high":
            priorityLabel = "Alta";
            break;
          case "medium":
            priorityLabel = "Media";
            break;
          case "low":
            priorityLabel = "Bassa";
            break;
          default:
            priorityLabel = "Non definita";
            break;
         }


            // Estrarre il nome e il cognome dall'assegnatario
        const assigneeName = row.assignee ? row.assignee.split(' - ')[0] : '';
 

      return (
    <TableRow key={row.id} className="table-font cursor-pointer">
      {showCheckbox && <TableCell style={{ minWidth: '5px' }} className="table-font">
        <Form.Check
          className='p-0 form-check-input-act-table'
          type="checkbox"
          checked={props.selectedList.some(el => el.id === row.id)}
          onChange={(e) => props.handleSelection(e, row.id, row )}
        />
      </TableCell>}
      <TableCell
        style={{ minWidth: '150px' }}
        className="table-font cursor-pointer fw-bold"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
        {capitalizeFirstLetter(row.name)}
      </TableCell>
      <TableCell
        style={{ minWidth: '150px' }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
         {row.priority && <p className='table-font fs-18 fw-regular'>
        
                  <CustomBadge variant={row.priority === "high" ? "custom-badge-red" : row.priority === "medium" ? "custom-badge-yellow" : row.priority === "low" ? "custom-badge-aqua"  : "default"}  margin="mx-0" >{priorityLabel} </CustomBadge>

      
        </p> }


      </TableCell>
     {/*  <TableCell
        style={{ minWidth: '160px' }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`${row.id}`)}
      >
        {formatDate(row.startDate)}

      </TableCell> */}
      <TableCell
        style={{ minWidth: '160px' }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
        {row.expirationDate && <p className='table-font fs-18 fw-regular'>
        

          <CustomBadge variant="custom-badge-gray"  margin="mx-0" >{formatDate(row.expirationDate)}    </CustomBadge>
      </p>}  
      </TableCell>
      <TableCell
        style={{ width: "fit-content" }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
      

      {assigneeName && <p className='table-font fs-18 fw-regular'>
          <CustomBadge variant="custom-badge-teal" margin="mx-0"          
          > <span><i class="bi bi-person-fill me-2"></i></span>{assigneeName}    
          </CustomBadge>
      </p>}

      </TableCell>
    
      <TableCell
        style={{ minWidth: '150px' }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
     

        <p className='table-font fs-18 fw-regular'>
          <CustomBadge variant={getStatusColor(status)}   margin="mx-0"         
          >{label}    
          </CustomBadge>
        </p>
      </TableCell>

      <TableCell
        style={{ minWidth: '150px' }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
        <p className='table-font fs-18 fw-regular'>
          <CustomBadge variant='custom-badge-ultramarine'   margin="mx-0">
            {capitalizeFirstLetter(row.unitName)}    
          </CustomBadge>
        </p>
      </TableCell>
      
      <TableCell
        style={{ minWidth: '150px' }}
        className="table-font cursor-pointer"
        onClick={() => navigate(`/statoavanzamentolavori/attivita/${row.id}`)}
      >
        
        {row.typology && <p className='table-font fs-18 fw-regular text-dark'>
        <CustomBadge variant="custom-badge-transparent" margin="mx-0"> <span className='me-1'><AiFillTool />
          </span>{capitalizeFirstLetter(row.typology)}    
          </CustomBadge>
      </p>}      </TableCell>

    </TableRow>
      )
})}
</TableBody>

      </Table>
    </TableContainer>
  );
}
