import { GET_CONDOMINIUM, EDIT_CONDOMINIUM, DELETE_CONDOMINIUM, ADD_CONDOMINIUM } from "./types";
import api from '../api/api'
import { condominium_endpoint} from "../api/api";

/*
STEP PER SALVARE DATI IN REDUX:
1. CREA ACTION TYPE IN ./types
2. CREA ACTION CREATORS (fetch..., add..., edit..., etc)
3. CREA REDUCER E IMPORTALO IN reducers/index.js
4. ESPORTA TUTTO
5. IMPORTA DOVE SERVE USEDISPATCH E USESELECTOR E ACTION CREATORS
*/


/* ACTION CREATORS */
export const fetchCondominium =  ()  =>  async (dispatch)=> {
    const response = await api().get(`${condominium_endpoint}`)
    dispatch({
        type: GET_CONDOMINIUM,
        payload: response.data?.content
    })
}

export const addCondominium =  (data)  =>  async (dispatch)=> {
    const response = await api().post(`${condominium_endpoint}`, data)
    dispatch({
        type: ADD_CONDOMINIUM,
        payload: data
    })
}

export const editCondominium =  (id, data)  =>  async (dispatch)=> {
    const response = await api().put(`${condominium_endpoint}${id}`, data)
    dispatch({
        type: EDIT_CONDOMINIUM,
        payload: {
                    data, 
                    id
                }
    })
}

export const removeCondominium =  (id)  =>  async (dispatch)=> {
    const response = await api().delete(`${condominium_endpoint}${id}`)
    dispatch({
        type: DELETE_CONDOMINIUM,
        id: id
    })
}


/* REDUCER */

const initialState = {
    condominium: [],
}

const condominiumReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_CONDOMINIUM:
            return {...state, 
                    condominium: action.payload
                }
        case ADD_CONDOMINIUM:
            return {
                ...state,
                condominium: [...state.condominium, action.payload]
            }
        case EDIT_CONDOMINIUM:
            const newData = action.payload.data
            const updatedCondominium = state.condominium && state.condominium.length > 0?
            state.condominium.map((el) => {
                if (el.id === action.payload.id) {
                return {...el, newData}
                } else {
                return el;
                }
            }) : []
            

            return  {
                ...state,
                condominium: updatedCondominium
            }

        case DELETE_CONDOMINIUM:
            const condominiums = state.condominium.filter( el => el.id !== action.id)
            return  {
                ...state,
                condominium: condominiums
            }
        default:
            return state
    }
}

export default condominiumReducer