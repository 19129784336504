import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { BsPlusCircleFill } from "react-icons/bs";
import api, {
  stabili_endpoint,
  supplier_endpoint,
  unit_endpoint,
  conductor_endpoint,
  subcount_endpoint,
  user_accounts_endpoint,
  bank_account_endpoint
} from "../../../../api/api";
import { CONTI, MOVEMENT_TYPES, PAYMENT_METHODS } from "../../../../constants";
import ButtonRm from "../../../ButtonRm";
import AddTypeModal from "./AddTypeModal";
import SubjectCard from "./SubjectCard";
import SupplierModal from "./SupplierModal";

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from "../../../../components/CustomToggle";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Link } from "react-router-dom";
import CustomBadge from "../../../CustomBadge";



function Movimento(props) {

  // DATI PER GESTIONE MODALE SOTTOCONTO
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const [display, setDisplay] = useState(false);

  //MOSTRA DIV PROPRIETARI DI UN UNITA' => AL MOMENTRO NON CAMBIA E' SEMPRE TRUE
  const [selectOwners, setSelectOwners] = useState(true);

  function handleOwnersDiv() {
    setSelectOwners(!selectOwners);
  }

  //VARIABILI IN CUI SALVO UNITA' E STABILI
  const [units, setUnits] = useState([]);
  const [stabili, setStabili] = useState([]);

  //PRENDO LE UNITA'
  async function fetchUnits() {
    try {
      const response = await api().get(`${unit_endpoint}`);
      if (response.data) {
        setUnits(response.data?.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //PRENDO GLI STABILI
  async function fetchStabili() {
    try {
      const response = await api().get(`${stabili_endpoint}`);
      if (response.data) {
        setStabili(response.data?.content);
      }
    } catch (error) {
      console.log(error);
    }
  }


  //CHIAMA LE FUNZIONI PER PRENDERE STABILI E UNITA' LA PRIMA VOLTA CHE MONTA IL COMPONENTE
  useEffect(() => {
    fetchUnits();
    fetchStabili();
  }, []);


  /*GESTIONE PERCENTUALE E LISTA PROPRIETAR */

  //gestisce il checkbox percentuale
  const [percentage, setPercentage] = useState(false);

  //Questa variabile contiene dati relativi ai soggetti selezionati e percentuale di ripartizione
  //Mappiamo questi dati per ottenere la lista di utenti con check
  const [percentageData, setPercentageData] = useState([]);

  //gestisce il checkbox percentuale e di conseguenza aggiorna percentagedata
  //quando il checkbox viene spuntato imposta tutti i percentage degli utenti proprietari e conduttori === 0
  function handlePercentage(e) {
    setPercentage((prev) => !prev);

    const newArr = percentageData.map((item) => {
      return {
        ...item,
        percentage: 0
      }
    })

    setPercentageData(newArr)
  }

  //ogni volta che cambia unità, se typebuilding =="Unità," reimposta percentage data
  useEffect(() => {
    if (props.formData.typeBuilding === "Unità") {
      //prendo proprietari e conduttori dell'unità selezionata
      const owners = props.formData.unit?.owners || [];
      const conductors = props.formData.unit?.conductors || [];
      
      //creo un array di oggetti con i dati dei proprietari. Per ogni proprietario c'è:
      //id, nome, cognome, ruolo, percentuale (percentuale di riapartizione definita), selected (se è stato selezionato o meno)
      if (owners.length > 0 || conductors.length > 0) {
        const ownersArr = owners.map((item) => ({
          id: item.id,
          name: item.name,
          surname: item.surname,
          role: "Proprietario",
          percentage: 0,
          selected: false,
        }));
        
        //creo stesso array per i conduttori
        const conductorsArr = conductors.map((item) => ({
          id: item.id,
          name: item.name,
          surname: item.surname,
          role: "Conduttore",
          percentage: 0,
          selected: false,
        }));
        
        //concateno i due array e ottengo un unico array di proprietari + conduttori
        const newArr = ownersArr.concat(conductorsArr);

        //imposto percentageData uguale all'array appena creato
        setPercentageData(newArr);
      }
    }
  }, [props.formData.unit]);
  
    //ogni volta che cambia stabile, se typebuilding == "stabile," reimposta percentage data

  useEffect(() => {
    //controllo che il tipo di building sia stabile e che contenga almeno un elemento unità

    if (props.formData.typeBuilding === "Stabile" && props.formData.condominium?.units?.length > 0) {
      //creo un array con tutti i proprietari delle unità dello stabile selezionato
      const allOwners = props.formData.condominium.units.flatMap((unit) =>
        unit.owners.map((owner) => ({
          id: owner.id,
          name: owner.name,
          surname: owner.surname,
          role: "Proprietario",
          percentage: 0,
          selected: false,
        }))
      );

      //imposto setpercantage come l'array appena creato
      setPercentageData(allOwners);
    }
  }, [props.formData.condominium]);

  //per trovare gli elementi selezionati, filtro percentageData per la proprietà selected === true
  const soggettiSelezionati = percentageData.filter((item) => item.selected);

  //parso ad integer, per sicurezza tutte le percentuali dell'array percentageData
  const percentageTotalArr = percentageData.map((item) =>
    parseInt(item.percentage)
  );

  //sommo tutte le percentuali per ogni proprietario e conduttore, per ottenere la percentuale totale (che deve essere === 100)
  const percentageTotal = percentageTotalArr.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  //se percetange data cambia, aggiorna il form principale e in particolare i campi owners e conductors, con i proprietari e conduttori correnti
  useEffect(() => {
    props.setFormData((prev) => {
      return {
        ...prev,
        owners: soggettiSelezionati.filter(
          (item) => item.role == "Proprietario"
        ),
        conductors: soggettiSelezionati.filter(
          (item) => item.role == "Conduttore"
        ),
      };
    });
  }, [percentageData]);

  //se percentage o percentageData cambia, aggiorna form principale di proprietari e conduttori
  // se "percentuale" non è selezionato, calcola da solo la percentuale, ridistribuendola in maniera equa
  //tra i soggetti selezionati => 100/soggettiSelezionati.length
  //se "percentuale" è selezionata, resituisci l'oggetto item senza modifiche (già item contiene la percentuale definita manualmente dall'utente)
  useEffect(() => {
    props.setFormData((prev) => {
      return {
        ...prev,
        owners: prev.owners.map(item => {
          return !percentage ?
          {...item, percentage: 100/soggettiSelezionati.length}:
          item
        }),
        conductors: prev.conductors.map(item => {
          return !percentage ?
          {...item, percentage: 100/soggettiSelezionati.length}:
          item
        })
      };
    });
  }, [percentageData, percentage]);

  //gestisci i dati: selected e percentage dell'array percentageData con proprietari e/o conduttori
  function handlePercentageData(e, id, key) {
    const { value, checked, type } = e.target;
    const newArr = percentageData.map((item) => {
      return item.id == id
        ? { ...item, [key]: type === "checkbox" ? checked : value }
        : item;
    });

    setPercentageData(newArr);
  }

  //gestisce il caso in cui il checkbox tutti è selezionato
  function handleTuttiSelected(e) {
    const newArr = percentageData.map((item) => {
      return { ...item, ["selected"]: !item.selected };
    });

    setPercentageData(newArr);
  }

   //quando cambia tipologià di immobile, svuota unità o stabile se aveva dei dati
   useEffect(() => {
    if (props.formData.typeBuilding == "Unità" ) {
      props.setFormData({ ...props.formData, condominium: {}, owners: [], conductors: [] })
    }

    if (props.formData.typeBuilding == "Stabile" ) {
      props.setFormData({ ...props.formData, unit: {}, owners: [], conductors: [] })
    }
  },[props.formData.typeBuilding ])

  //quando l'utente seleziona una nuova initià ,aggiorna il form principale
  const onChangeUnit = (e) => {
    units &&
      units.forEach((unit) => {
        const parsedValue = e.target.value ? JSON.parse(e.target.value) : ''
        if (unit.id == parsedValue.id) {
          props.setFormData({ ...props.formData, unit: unit });
          return;
        }
      });
  };

    //quando l'utente seleziona un nuovo stabile ,aggiorna il form principale

  const onChangeStabili = (e) => {
    stabili &&
      stabili.forEach((stabile) => {
        const parsedValue = e.target.value ? JSON.parse(e.target.value) : ''
        if (stabile.id == parsedValue.id) {
          props.setFormData({ ...props.formData, condominium: stabile });
          return;
        }
      });
  };

  //DATI PER SOTTOCONTI

  //const [options, setOptions] = useState(MOVEMENT_TYPES)
  const [subcount, setSubcount] = useState([])

  let mappedSubcount = subcount.length > 0 ? subcount.map(el => el.name) : []

  let options = [...MOVEMENT_TYPES, ...mappedSubcount]

  //CALL ENDPOINT SUBCOUNT
  async function getAll() {
    try {
        const response = await api().get(`${subcount_endpoint}`);
        if(response.data) {
          setSubcount(response.data.content)
        }
    }
    catch(error) {
            console.log(error)
    }
  }
  

  useEffect(() => {
      getAll()
  }, [show])

  console.log(options)


  //SE !PERCENTAGE, PRIMA DELLA POST, BISOGNA MAPPARE PERCENTAGEDATA => {...item, percentage: 100/soggettiSelezionati.length} PER CAPIRE LA PERCENTUALE
  //CHE SPETTA A CIASCUN SOGGETTO xx

  //SE PERCENTAGE, bisogna aggiungere controllo che PERCENTUALE
  //TOTALE SIA UGUALE A 100, ALTRIMENTI ERRORE xx

  //SE TUTTI E' SELEZIONATO, TUTTI I SOGGETTI AVRANNO => SELECTED TRUE xx

  //AGGIUNGERE TOOLTIP PER PERCENTUALE: a cosa serve e requisiti


  /* GESTIONE CONTI DA MOSTRARE */

  const [managerAccounts, setManagerAccounts] = useState([])
  
	//CONTI GESTORI
	async function getAccounts() {
	  try {
		const response = await api().get(`${user_accounts_endpoint}`);

    
		if (response.data) {
		  setManagerAccounts(response.data.content);

      console.log(response.data.content, "account_Resource")
		}
	  } catch (err) {
		  console.log(err);
	  }
	}

  useEffect(() => {
		getAccounts()
	}, []);

  //Account membri
  const [accounts, setAccounts] = useState([])

  //Account proprietari selezionati
  const [ownerAccounts, setOwnerAccounts] = useState([])

  //Account 
  const [mixedAccounts, setMixedAccounts] = useState([])

  useEffect(() => {
    setMixedAccounts([...managerAccounts, ...ownerAccounts])
  }, [managerAccounts, ownerAccounts])

  

    //CONTI MEMBRI
    async function getBankAccounts() {
      try {
        const response = await api().get(`${bank_account_endpoint}`);
        if (response.data) {
      setAccounts(response.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    }

    useEffect(() => {
    getBankAccounts()
    }, []);

  useEffect(() => {
    let ids = props.formData.unit?.owners?.map(owner => owner.id) // Utilizzo flatMap per ottenere un array piatto di ID degli owner

    // Calcolo degli account degli owner
    const contiCorrispondenti = accounts.filter(conto => {
      return conto.owners.some(owner => ids?.includes(owner.id));
    });
	  
		// Salva gli account degli owner nello stato ownerAccounts
		  setOwnerAccounts(contiCorrispondenti);
		}

	  , [props.formData.unit]);


  return (
    <>
    <Accordion.Item eventKey="1" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="1" activeKey={props.activeKey}>Unità/Stabile <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={
                          <Tooltip id="tooltip" className="exclamation-tooltip">
                            Rappresenta l'immobile di riferimento al quale verrà associata questa prima nota. Questa associazione ti tornerà utile ai fini della rendicontazione poiché potrai recuperare automaticamente tutte le prime note associate a uno specifico immobile o proprietario.
                          </Tooltip>
                        }>
                        <i class="bi  bi-info-circle ms-2 fs-14"></i>
                      </OverlayTrigger></CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="row gy-4">
        <div className="col-md-12">
          <div className="d-flex">
            <Form.Check
              inline
              label="Unità"
              type="radio"
              value="Unità"
              checked={props.formData.typeBuilding === "Unità"}
              onChange={props.onChange}
              name="typeBuilding"
            />
            <Form.Check
              inline
              label="Stabile"
              type="radio"
              value="Stabile"
              checked={props.formData.typeBuilding === "Stabile"}
              onChange={props.onChange}
              name="typeBuilding"
            />
          </div>
        </div>
        {props.formData.typeBuilding === "Unità" && (
          <>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Unità
                </label>
                <div className="d-block">
                  <select
                    name="unit"
                    onChange={onChangeUnit}
                    value={JSON.stringify(props.formData.unit)}
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                  >
                    <option value="">Seleziona</option>
                    {units.map((item) => {
                      return (
                        <option key={item.id} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* 
            <div className="col-md-4">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Proprietari e Conduttori
                </label>
                <ButtonRm
                  variant="outline"
                  onClick={handleOwnersDiv}
                  size="small"
                >
                  {selectOwners ? "Annulla Selezione" : "Seleziona"}
                </ButtonRm>
              </div>
            </div> */}
            { props.formData.unit?.id && selectOwners && props.formData.typeBuilding === "Unità" && (
              <>
                <hr className="my-3" />
                <Row>
                  <Col className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Proprietari e Conduttori
                  </label>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          default
                          className=" text-end"
                          label="Percentuale"
                          value={percentage}
                          onChange={handlePercentage}
                          type="checkbox"
                        />
                        <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={
                          <Tooltip id="tooltip" className="exclamation-tooltip">
                            Puoi definire la percentuale dell'importo ricevuta o pagata degli utenti selezionati. Di default se "Percentuale" non è flaggato la ripartizione avviene dividendo in parti uguali il 100% dell'importo, in base al numero di utenti selezionato.
                            Per esempio se hai selezionato 2 utenti, di default la percentuale di ciascuno sarà 50%
                          </Tooltip>
                        }>
                        <i class="bi  bi-info-circle ms-2 fs-14"></i>
                      </OverlayTrigger>
                      </div>
                    </div>
                    {/* PERCENTUALE DOVREBBE APPARIRE SOLO QUANDO FLAGGATA */}
                    {/* SE LE CHECKBOX FLAGGATA E' TUTTI, tutte le chechbox vengono selezionate */}
                    {
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          default
                          className="mb-2 text-end"
                          label="Tutti"
                          name="unitOwner"
                          value={false}
                          onChange={handleTuttiSelected}
                          type="checkbox"
                        />
                      </div>

                    }


                    {percentageData?.length > 0 &&
                      percentageData.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <Form.Check
                              default
                              className="mb-2 text-end"
                              label={`${item.name} ${item.surname} - ${item.role}`}
                              name={`${item.id}`}
                              checked={item.selected}
                              onChange={(e) =>
                                handlePercentageData(e, item.id, "selected")
                              }
                              type="checkbox"
                            />
                            {percentage && item.selected && (
                              <input
                                type="number"
                                style={{ height: "30px", minWidth: "50px" }}
                                placeholder="inserisci la percentuale"
                                className="text-end percentage-input-pn"
                                value={item.percentage}
                                onChange={(e) =>
                                  handlePercentageData(e, item.id, "percentage")
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    {
                      /* APPARE SOLO SE C'E' CAMPO PERCENTUALE FLAGGATO */
                      percentage && (
                        <div className="d-flex justify-content-between align-items-center">
                          <p>PERCENTUALE TOTALE</p>
                          <p
                            className="text-end fw-bold"
                            style={{
                              color: percentageTotal == 100 ? "green" : "red",
                            }}
                          >
                            {percentageTotal}/100%
                          </p>
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        {props.formData.typeBuilding === "Stabile" && (
          <>
            <div className="col-md-6">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Stabile
                </label>
                <div className="d-block">
                  <select
                    name="condominium"
                    onChange={onChangeStabili}
                    value={JSON.stringify(props.formData.condominium)}
                    className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
                  >
                    <option value="">Seleziona</option>
                    {stabili.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  Proprietari (Condomini)
                </label>
                <ButtonRm
                  variant="outline"
                  onClick={handleOwnersDiv}
                  size="small"
                >
                  {selectOwners ? "Annulla Selezione" : "Seleziona"}
                </ButtonRm>
              </div>
            </div> */}
            {props.formData.condominium?.id && props.formData.typeBuilding === "Stabile" && (
              <>
                <hr className="my-3" />
                <Row>
                  <Col className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                    Proprietari
                  </label>
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          default
                          className=" text-end"
                          label="Percentuale"
                          value={percentage}
                          onChange={handlePercentage}
                          type="checkbox"
                        />
                        <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={
                          <Tooltip id="tooltip" className="exclamation-tooltip">
                            Puoi definire la percentuale dell'importo ricevuta o pagata degli utenti selezionati. Di default se "Percentuale" non è flaggato la ripartizione avviene dividendo in parti uguali il 100% dell'importo, in base al numero di utenti selezionato.
                            Per esempio se hai selezionato 2 utenti, di default la percentuale di ciascuno sarà 50%
                          </Tooltip>
                        }>
                        <i class="bi  bi-info-circle ms-2 fs-14"></i>
                      </OverlayTrigger>
                      </div>
                    </div>
                    {/* PERCENTUALE DOVREBBE APPARIRE SOLO QUANDO FLAGGATA */}
                    {/* SE LE CHECKBOX FLAGGATA E' TUTTI, tutte le chechbox vengono selezionate */}
                    {
                      <div className="d-flex justify-content-between align-items-center">
                        <Form.Check
                          default
                          className="mb-2 text-end"
                          label="Tutti"
                          name="unitOwner"
                          value={false}
                          onChange={handleTuttiSelected}
                          type="checkbox"
                        />
                      </div>

                    }


                    {percentageData?.length > 0 &&
                      percentageData.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <Form.Check
                              default
                              className="mb-2 text-end"
                              label={`${item.name} ${item.surname} - ${item.role}`}
                              name={`${item.id}`}
                              checked={item.selected}
                              onChange={(e) =>
                                handlePercentageData(e, item.id, "selected")
                              }
                              type="checkbox"
                            />
                            {percentage && item.selected && (
                              <input
                                type="number"
                                style={{ height: "30px", minWidth: "50px" }}
                                placeholder="inserisci la percentuale"
                                className="text-end percentage-input-pn"
                                value={item.percentage}
                                onChange={(e) =>
                                  handlePercentageData(e, item.id, "percentage")
                                }
                              />
                            )}
                          </div>
                        );
                      })}
                    {
                      /* APPARE SOLO SE C'E' CAMPO PERCENTUALE FLAGGATO */
                      percentage && (
                        <div className="d-flex justify-content-between align-items-center">
                          <p>PERCENTUALE TOTALE</p>
                          <p
                            className="text-end fw-bold"
                            style={{
                              color: percentageTotal == 100 ? "green" : "red",
                            }}
                          >
                            {percentageTotal}/100%
                          </p>
                        </div>
                      )
                    }
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="2" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="2" activeKey={props.activeKey}>Movimento <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
 </CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="d-md-flex flex-md-row">
        <div className="col-md-6 mb-3 mb-md-0">
          <div className="d-flex flex-column ">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Conto
              <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={
                          <Tooltip id="tooltip" className="exclamation-tooltip">
                            Il contro rappresenta la macrocategoria di appartenenza di questa prima nota. Ti tornerà utile per raggruppare le varie spese ai fini della rendicontazione.
                          </Tooltip>
                        }>
                        <i class="bi  bi-info-circle ms-2 fs-14"></i>
                      </OverlayTrigger>
            </label>
            <div className="d-block">
              <select
                name="account"
                onChange={props.onChange}
                defaultValue={
                  props.formData?.account
                    ? props.formData.account
                    : "Spese Generali"
                }
                className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white col-sm-10 col-md-11"
              >
                <option value="Spese Generali">Spese Generali</option>
                <option value="Consumi">Consumi</option>
                <option value="Manutenzioni">Manutenzioni</option>
                <option value="Pompe acque meteoriche">
                  Pompe acque meteoriche
                </option>
              </select>
            </div>
          </div>
        </div>
        <Col md={6} className="d-flex align-items-start">
								<Form.Group className="me-2 mb-3" style={{width:"90%"}}>
									<Form.Label 
										className="f-label fs-16 d-flex align-items-center
mb-2"
									>
										Sottoconto <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={
                          <Tooltip id="tooltip" className="exclamation-tooltip">
                            Il sottoconto rappresenta la categoria di appartenenza di questa prima nota.
                          </Tooltip>
                        }>
                        <i class="bi  bi-info-circle ms-2 fs-14"></i>
                      </OverlayTrigger>
									</Form.Label>
									<Form.Select 
										name="subAccount"
                    onChange={props.onChange}
                    value={
                      props.formData?.subAccount
                    }
									>
                    <option></option>
										{options.map((menu, index) => {
                      return (
                        <option key={index} value={menu}>
                          {menu}
                        </option>
                      );
                    })}
									</Form.Select>
								</Form.Group>
								<BsPlusCircleFill
									className="col-sm-2 col-md-1 fs-30 plus-button"
									style={{marginTop:"2rem"}}
									onClick={handleShow}
								/>
				</Col>
      </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 ">
              Descrizione dell’operazione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

            </label>
            <input
              type="text"
              className="border border-secondary rounded-3 border-opacity-50"
              name="operationDescription"
              defaultValue={props.formData.operationDescription}
              onChange={props.onChange}
            />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="3" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="3" activeKey={props.activeKey}>Date <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Competenza <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

            </label>
            <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
              <input
                name="operationDate"
                className="border-0 fs-16 text-dark flex-grow-1 input-min-height"
                max="9999-12-31"
                type="date"
                onChange={props.onChange}
              ></input>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6">
          {!props.formData.paid && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Scadenza
              </label>
              <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
                <input
                  name="endDate"
                  className="border-0 fs-16 text-dark flex-grow-1"
                  max="9999-12-31"
                  type="date"
                  onChange={props.onChange}
                ></input>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <Form.Check
              default
              className="mb-2 col-md-3"
              label="Pagato"
              name="paid"
              checked={props.formData.paid}
              onChange={props.onChange}
              type="checkbox"
            />
          </div>
        </div>
        <div className="col-md-6">
          {props.formData.paid && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Data del pagamento
              </label>
              <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
                <input
                  name="paymentDay"
                  className="border-0 fs-16 text-dark flex-grow-1"
                  max="9999-12-31"
                  type="date"
                  onChange={props.onChange}
                ></input>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <Form.Check
              default
              className="mb-2 col-md-3"
              label="Fattura"
              name="billed"
              onChange={props.onChange}
              type="checkbox"
            />
          </div>
        </div>
        <div className="col-md-6">
          {props.formData.billed && (
            <div className="d-flex flex-column">
              <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                Data della fattura
              </label>
              <div className="d-flex align-items-center justify-content-between border border-secondary px-3 rounded-3 border-opacity-50">
                <input
                  name="invoiceDay"
                  className="border-0 fs-16 text-dark flex-grow-1"
                  max="9999-12-31"
                  type="date"
                  onChange={props.onChange}
                ></input>
              </div>
            </div>
          )}
        </div> */}
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="4" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="4" activeKey={props.activeKey}>Importo<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="d-block col-md-12">
        <div className="mb-3 d-flex">
          <Form.Check
            inline
            label="Importo in entrata"
            type="radio"
            checked={props.formData.isEnteringAmount}
            onChange={props.onChange}
            name="isEnteringAmount"
          />
          <Form.Check
            inline
            label="Importo in uscita"
            type="radio"
            checked={!props.formData.isEnteringAmount}
            onChange={props.onChange}
            name="isEnteringAmount"
          />
        </div>
      </div>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Imponibile in € <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

            </label>
            <input
              name="taxable"
              type="number"
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
              onChange={props.onChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Codice IVA
            </label>
            <select
              name="vatCode"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
            >
              <option value={1}> Esente </option>
              <option value={1.04}> 4% </option>
              <option value={1.05}> 5% </option>
              <option value={1.1}> 10% </option>
              <option value={1.2}> 20% </option>
              <option value={1.21}> 21% </option>
              <option value={1.22}> 22% </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Metodo di pagamento
            </label>
            <select
              name="paymentMethod"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
            >
              <option></option>
              {PAYMENT_METHODS.map((menu, index) => {
                return (
                  <option key={index} value={menu}>
                    {menu}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

       { !props.formData.isEnteringAmount && <div className="col-md-6 ">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Dal conto
            </label>
            <select
              name="fromAccount"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
            >
              <option></option>
              {mixedAccounts.length> 0 && mixedAccounts.map((conto) => {
                return <option value={conto.name}> {conto.name}</option>;
              })}
            </select>
          </div>
        </div>}

       { props.formData.isEnteringAmount && <div className="col-md-6">
          <div className="d-flex flex-column">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Nel conto
            </label>
            <select
              name="toAccount"
              onChange={props.onChange}
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
            >
              <option></option>
              {mixedAccounts.length> 0 && mixedAccounts.map((conto) => {
                return <option value={conto.name}> {conto.name}</option>;
              })}
            </select>
          </div>
        </div>}

        <Row className="mt-3">
          <div className="col-md-6">
            <div className="d-flex flex-column">
              <Form.Check
                default
                className="mb-2 col-md-6"
                label="Aggiungi ritenuta"
                name="addWithholdingTax"
                onChange={props.onChange}
                type="checkbox"
              />
            </div>
          </div>
          <div className="col-md-6">
            {props.formData.addWithholdingTax&& (
              <div className="d-flex flex-column">
                <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                  % di ritenuta
                </label>
                <select
                  name="withholdingTax"
                  onChange={props.onChange}
                  className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                >
                  <option>4%</option>
                  <option>20%</option>
                  <option>22%</option>
                </select>
              </div>
            )}
          </div>
        </Row>
      </div>
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="5" className="mb-2 accordionHeader">
      <Card.Header>
      <CustomToggle eventKey="5" activeKey={props.activeKey}>Fornitori</CustomToggle>
      </Card.Header>
      <Accordion.Body>
      <div className="col-12">
        <div className="d-flex align-items-center form-last-btns">
          <Link to="/fornitori/create"><ButtonRm variant="outline" className="me-2">Nuovo</ButtonRm></Link>
          <ButtonRm variant="outline" onClick={props.getSupplierList}>Seleziona</ButtonRm>
        </div>
      </div>

      {props.getSelectedSuppliers &&
        props.getSelectedSuppliers.length > 0 &&
        props.getSelectedSuppliers.map((supplier) => (
          <SubjectCard
            key={supplier.id}
            title={
              supplier.name +
              " " +
              supplier.surname +
              ": " +
              supplier.businessName
            }
            id={supplier.id}
            table_data={[
              supplier.category,
              supplier.subjectType,
              supplier.cellularTelephone,
            ]}
            className="mt-4"
            remove={props.removeSelectedSupplier}
          />
      ))}
  
      </Accordion.Body>
    </Accordion.Item>

    
    <Modal show={show} className={`type-modal ${display ? "d-none" : ""}`} backdrop="static"         onHide={handleClose}
>
          <Modal.Body className="p-3">
           
          <Button
            variant=""
            onClick={handleClose}
            className="float-end border-0 bg-white"
          >
            <i className="bi bi-x"></i>
          </Button>
          <AddTypeModal setDisplay={setDisplay} handleClose={handleClose} />

          </Modal.Body>
    </Modal>

    {/*   <Modal
        show={show}
        className={`add-section-modal ${display ? "d-none" : "small-modal"} type-modal`}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            onClick={handleClose}
            className="float-end border-0 bg-white"
          >
            <i className="fal fa-times"></i>
          </Button>
          <AddTypeModal setDisplay={setDisplay} handleClose={handleClose} />
        </Modal.Body>
      </Modal> */}
      {/* ///////---------- Select Subject Modal  ---------------///// */}

    </>
  );
}

export default Movimento;
