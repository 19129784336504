import { useState } from "react";

const FilterItem = ({ id, name, handleFilter, checked = false }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className={`filterItemFornitore mb-2 ${checked ? 'checked' : ''}`}
            onClick={() => handleFilter(id)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <i
            className={`bi bi-check-lg ${
                (checked || isHovered) ? 'filterItemFornitoreIconActive' : 'filterItemFornitoreIcon'
            }`}
            ></i>
            <p className="filterItemFornitoreText">{name}</p>
        </div>
    );
};


export default FilterItem