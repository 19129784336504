import { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { CONTRACT_TEXT } from "../../../constants";
import { useReactToPrint } from "react-to-print";
import { BsPrinter, BsDownload } from "react-icons/bs";
import jsPDF from "jspdf";
import CustomBadge from "../../CustomBadge";


const ContractE = (props) => {
	const [data, setData] = useState({});
	const init = () => {
		setData({
			contractModelName: props.formData?.contractModelName,
			contractText: props.formData?.contractText,
		});
	};
	useEffect(() => {
		init();
	}, [props]);
	useEffect(() => {
		if (props.saveState === true) {
			props.update(data);
		} else {
			init();
		}
	}, [props.saveState, props.editMode]);
	const componentRef = useRef();

	const [contract, setContract] = useState(props.formData?.contractText);

	const handleContract = (index) => {
		setContract(CONTRACT_TEXT[index]);
	};

	useEffect(() => {
		setData({ ...data, contractText: contract });
	}, [contract]);

	const contract_type = [
		"Abitativo 4+4",
		"Abitativo 4+4 - Arredato",
		"Abitativo 4+4 - Arredato - Spese forfettarie",
		"Abitativo 4+4 - Arredato - Cedolare secca",
		"Abitativo 4+4 - Spese forfettarie",
		"Abitativo concordato 3+2",
		"Abitativo concordato 3+2 - Cedolare secca",
		"Abitativo concordato 3+2 - Spese forfettarie",
		"Autorimessa 1+1",
		"Autorimessa 4+4",
		"Comodato gratuito - Abitativo",
		"Fondo rustico agricolo",
		"Foresteria 4+4",
		"Negozio 6+6",
		"Negozio 6+6 - Spese Forfettarie",
		"Residenza Extra-Alberghiera",
		"Studenti Universitari - Arredato -Spese Forfettarie",
		"Transitorio -Arredato - Spese Forfettarie",
		"Transitorio -Arredato - Spese Forfettarie - Comuni con più di 10.000 abitanti",
		"Turistico",
		"Turistico - Agenzia Immobiliare - Con Acconto",
		"Ufficio 6+6",
		"Ufficio 6+6 - Spese forfettarie",
		"Studenti universitari - Arredato- S. forfettarie - Garanti",
	];

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	let str = contract


	const handleExport = async () => {
		const doc = new jsPDF({
		  orientation: "portrait",
		  unit: "mm",
		  format: "a4",
		  putOnlyUsedFonts: true,
		  compress: true,
		  fontSize: 12,
		  fontStyle: "normal",
		  textColor: "0",
		});
	  
		const topMargin = 50; // 5cm
		const bottomMargin = 43; // 4,3cm
		const leftMargin = 28; // 2,8cm
		const rightMargin = 51; // 5,1cm
		const lineHeight = 8; // 6mm per ogni riga
		const maxLinesPerPage = 25;
		const availableHeight = doc.internal.pageSize.height - topMargin - bottomMargin;
	  
		const splitTitle = doc.splitTextToSize(props.formData.contractText, doc.internal.pageSize.width - leftMargin - rightMargin);
		
		let y = topMargin;
		let linesOnPage = 0;
	  
		for (let i = 0; i < splitTitle.length; i++) {
		  const lineCount = doc.splitTextToSize(splitTitle[i], doc.internal.pageSize.width - leftMargin - rightMargin).length;
		  if (linesOnPage + lineCount > maxLinesPerPage) {
			doc.addPage();
			y = topMargin
			linesOnPage = 0;
		  }
		  doc.text(leftMargin, y, splitTitle[i]);
		  y += lineHeight * lineCount;
		  linesOnPage += lineCount;
		}
	  
		doc.save(`${props.formData.contractModelName}.pdf`);
	  };
	  
	//AUTOMARIZZAZIONI

	const durataContratto = parseInt(props.formData.endFirstPeriodDate?.substr(0,4)) - parseInt(props.formData.startRentingDate?.substr(0,4))

	const durataRinnovo = parseInt(props.formData.endRenewalDate?.substr(0,4)) - parseInt(props.formData.endFirstPeriodDate?.substr(0,4))

	/* const locatori = props.formData.units?.[0]?.owners[0]?.surname && props.formData.units?.[0]?.owners[0]?.name ?
	props.formData.units[0]?.owners[0]?.surname + " " + props.formData.units[0]?.owners[0]?.name:
	"%LOCATORI%" */

	const millesimi = props.formData.units[0]?.ofProperty && props.formData.units[0]?.scaleMillesimi ? `
	${props.formData.units[0]?.ofProperty && `Di proprietà: ${props.formData.units[0]?.ofProperty}`}
	${props.formData.units[0]?.scaleMillesimi && `Scale: ${props.formData.units[0]?.scaleMillesimi}`}
	${props.formData.units[0]?.lift && `Ascensore: ${props.formData.units[0]?.lift}`}
	${props.formData.units[0]?.reception && `Portineria: ${props.formData.units[0]?.reception}`}
	${props.formData.units[0]?.heating && `Riscaldamento: ${props.formData.units[0]?.heating}`}
	${props.formData.units[0]?.district && `Comprensorio: ${props.formData.units[0]?.district}`}
	${props.formData.units[0]?.water && `Acqua: ${props.formData.units[0]?.heating}`}`:"%MILLESIMI%"

	function arrayToString(array) {
		if (array.length === 0) {
		  return "";
		} else if (array.length === 1) {
		  return array[0];
		} else {
		  return array.slice(0, -1).join(", ") + " e " + array[array.length - 1];
		}
	  }


	const conduttoriArray = props.formData.conductors.length > 0 ?
	props.formData.conductors.map(item => `${item.surname.charAt(0).toUpperCase()}${item.surname.slice(1)} ${item.name.charAt(0).toUpperCase()}${item.name.slice(1)}`) : []
	
	const conduttori = conduttoriArray.length > 0?
	arrayToString(conduttoriArray):
	"%CONDUTTORI%"


	const immobile = 
	`${props.formData.units[0]?.typology? props.formData.units[0]?.typology : "" }${props.formData.units[0]?.address}${props.formData.units[0]?.houseNumber? `, civico ${props.formData.units[0]?.houseNumber}` : ""}${props.formData.units[0]?.internal? `, interno ${props.formData.units[0]?.internal}` : ""}${props.formData.units[0]?.scale? `, scala ${props.formData.units[0]?.scale}` : ""}
	`

	const indirizzo = `${props.formData.units[0]?.address}${props.formData.units[0]?.houseNumber? `, civico ${props.formData.units[0]?.houseNumber}` : ""}`

	const condominio = props.formData.condominium?.length > 0 ?
	props.formData.condominium[0]?.name : 
	"%CONDOMINIO%"



	let replacements = {
		"%DATAINIZIO%":  new Date(props.formData.startRentingDate.substring(0,10)).toLocaleDateString('it-IT'),
		"%DATAFINE%": new Date(props.formData.endFirstPeriodDate.substring(0,10)).toLocaleDateString('it-IT'),
		"%DURATA%":  durataContratto,
		"%DURATARINNOVO%":  durataRinnovo,
		//"%LOCATORI%" : locatori,
		"%CONDUTTORI%" : conduttori,
		"%IMMOBILI%": immobile,
		"%PREAVVISOCONDUTTORI%": props.formData.cancellationRenewalForTenant? props.formData.cancellationRenewalForTenant : "%PREAVVISOCONDUTTORI%",
		"%CANONE%": props.formData.currentMonthlyFee? props.formData.currentMonthlyFee: "%CANONE%",
		"%BASECANONE%": props.formData.feePeriod? props.formData.feePeriod : "%BASECANONE%",
		"%METODOPAGAMENTO%": props.formData.favoritePaymentMethod? props.formData.favoritePaymentMethod : "%METODOPAGAMENTO%",
		"%CAUZIONE%": props.formData.warrantyDeposit?  props.formData.warrantyDeposit : "%CAUZIONE%",
		"%SPESEFORFETTARIE%": props.formData.currentMonthlyRateExpenses?props.formData.currentMonthlyRateExpenses : "%SPESEFORFETTARIE%",
		"%MILLESIMI%": millesimi ? millesimi : "%MILLESIMI%",
		"%BOLLIPERCENTUALE%": props.formData.percentageStampConductor? props.formData.percentageStampConductor : "%BOLLIPERCENTUALE%",
		"%REGISTROPERCENTUALE%": props.formData.percentageTaxConductor?props.formData.percentageTaxConductor : "%REGISTROPERCENTUALE%",
		"%ISTATPERCENTUALE%":props.formData.istat? props.formData.istat : "%ISTATPERCENTUALE%",
		"%UNITA_COMUNE%":props.formData.units[0]?.municipality
		? props.formData.units[0]?.municipality
		: "%UNITA_COMUNE%",
		"%CLASSE_ENERGETICA%": props.formData.units[0]?.energyClass? props.formData.units[0]?.energyClass : "%CLASSE_ENERGETICA%",
		"%IPE%":props.formData.units[0]?.energyPerformanceIndex
		? props.formData.units[0]?.energyPerformanceIndex
		: "%IPE%",
		"%CANONEANNUO%":props.formData.currentMonthlyFee? props.formData.currentMonthlyFee * 12: "%CANONEANNUO%",
		"%CONDOMINIO%": condominio,
		"%UNITA_INDIRIZZO%": indirizzo,
		"%PREAVVISO_RISOLUZIONE%": props.formData.cancellationRentForTenant? props.formData.cancellationRentForTenant : "%PREAVVISO_RISOLUZIONE%",
		"%SPESEFORFETTARIEANNUE%" : props.formData.currentMonthlyRateExpenses?props.formData.currentMonthlyRateExpenses*12 : "%SPESEFORFETTARIEANNUE%",
		
	}
    

	useEffect(() => {
		if(str)
		{str = str?.replace(/%\w+%/g, function(all) {
			return replacements[all] || all;
		});
		setContract(str)}
	}, [replacements])


	return (
		<Form>
			
				<div className="edit-content-container">
					<div className="form-item-align-box-white d-block">
						<div className="d-flex justify-content-end">
							<div
								className="btn bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 me-2 text-center d-flex justify-content-center align-items-center"
								onClick={handlePrint}>
								<BsPrinter />
							</div>
							<div
								className="btn bg-white text-dark border border-secondary rounded-3 p-1 w-40 h-40 text-center d-flex justify-content-center align-items-center"
								onClick={handleExport}>
								<BsDownload />
							</div>
						</div>
						<fieldset disabled={props.edit_mode ? false : true}>
							<div className="row">
								<div className="col-md-6">
									<div className="d-flex flex-column">
										<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
											Nome del modello
										</label>
										<select
											name="contractModelName"
											value={data.contractModelName}
											onChange={(e) => {
												setData({ ...data, contractModelName: e.target.value });
												handleContract(e.target.selectedIndex);
											}}
											className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white">
											{contract_type.map((menu, index) => {
												return (
													<option key={index} value={menu}>
														{menu}
													</option>
												);
											})}
										</select>
									</div>
								</div>
							</div>
							<div className="label-heading mb-3 d-block w-100 mt-4">
								<div className="label-text rel d-inline-block px-2 title-padding">
									<h1 className="fs-16 text-white text-uppercase">
										TESTO DEL CONTRATTO
									</h1>
								</div>
							</div>
							<div className="mt-3">
								<label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2 mt-3">
									Anteprima del contratto
								</label>
							</div>

							<div className="w-100">
								<textarea
									ref={componentRef}
									name="contractText"
									value={data.contractText}
									id=""
									cols="100"
									rows="30"
									className="border border-secondary fs-16 text-dark rounded-2 border-opacity-50 w-100"
									onChange={(e) =>
										setData({ ...data, contractText: e.target.value })
									}></textarea>
							</div>
						</fieldset>
					</div>
					
				</div>
			
		</Form>
	);
};

export default ContractE;
