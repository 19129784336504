import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";


import axios from "axios";

import { BsDownload, BsTrash, BsFiEdit2} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";

import api, { user_endpoint, user_accounts_endpoint, user_accounts_assignment_endpoint } from "../../api/api";

import { ConnectAccountOnboarding, ConnectComponentsProvider } from "@stripe/react-connect-js";


import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import deskIcon from "../../assets/images/deskIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateManagerAccount from "./CreateManagerAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import Progress from '../Progress'
import { checkSupplier, checkUserRole, checkCollaborator, checkMaster } from "../../utils/checkUserRole";
import ProgressFornitori from "../ProgressFornitori";




const fiscaleCodePattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/

const vatNumberPattern = /^[0-9]{11}$/

//minimo 8 caratteri, contenga minuscole, maiuscole, almeno un simbolo e almeno un numero
const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const schema = yup.object().shape({
    username: yup.string(),
    email:  yup.string().email("Inserisci una mail valida"),
    phone: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    password: yup.string(),
    name: yup.string(),
    surname: yup.string(),
    fiscalCode: yup.string().test('empty-or-valid', 'Inserisci un codice fiscale valido', function(value) {
		if (!value) {
		  return true; // campo vuoto, la convalida ha successo
		} else {
			return fiscaleCodePattern.test(value);
		}
	}),
    genre: yup.string(),
    birthday: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    houseNumber: yup.string(),
    province: yup.string(),
    municipality: yup.string(),
    cap: yup.number("Inserisci dei numeri").nullable().transform((_, val) => val ? Number(val) : null),
    documentType: yup.string(),
    issueEntity: yup.string(),
    issueDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    documentNumber: yup.string(),
    issuePlace: yup.string(),
    documentFile: "",
    faceFile: "",
    role: yup.string(),
	vatNumber: yup.string().test('empty-or-valid', 'Inserisci una Partita IVA valida', function(value) {
		if (!value) {
		  	return true; // campo vuoto, la convalida ha successo
		} else {
			return vatNumberPattern.test(value);
		}
	}),
    /* newPassword: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'La password deve contenere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un simbolo e un numero')
    .required('La password è obbligatoria') */

});

export default function Profilo({props}){


    const [data, setData] = useState({
    })

    function onChange(e){
        const {value, name, type, checked} = e.target
        setData(prev => {
            return {
                ...prev,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }


    //VALIDAZIONE INPUT
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		if (Object.keys(errors).length > 0) {
			console.log("Ci sono errori di validazione", errors);
			return;
		} 
		// azione da eseguire quando i dati sono tutti corretti
        updateData()
	};

    //HANDLE PROFILE IMG
    const [profileImg, setProfileImg] = useState("")

    function handleProfileImg(e){
        setProfileImg(e.target.files[0])
    }

    function handleRemoveProfileImg(){
        setProfileImg("")
    }

    //HANDLE FACE ID
    const [faceIdModal, setFaceId] = useState(false)
    const [faceIdPhoto, setFaceIdPhoto] = useState("")

    const showFaceId = () => (setFaceId(true))
    const closeFaceId = () => (setFaceId(false))

    function handleRemoveFaceId(){
        setFaceIdPhoto("")
    }

    useEffect(() => {
        setData(prev => {
            return { 
                ...prev,
                faceFile: faceIdPhoto
            }
        })
    }, [faceIdPhoto])

    //HANDLE WEBCAM

    const webcamRef = React.useRef(null);
    const [camera, setCamera] = useState(false);

    const handleWebcamAccess = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          setCamera(stream);
        } catch (error) {
          console.log('Error accessing camera:', error);
        }
      };


    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user",
    };

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        //let link_img = document.createElement("a");
        //link_img.style.display = "none";
        //document.body.appendChild(link_img);
        //link_img.href = imageSrc;
        //link_img.download = "face_id.png";
        //link_img.click();
        setFaceIdPhoto(imageSrc)
        closeFaceId()
        setCamera(false)
    }, [webcamRef]);

    //HANDLE MODAL OTP
    const [otpModal, setOtpModal] = useState(false)
    const showOtpModal = () => setOtpModal(true)
    const closeOtpModal = () => setOtpModal(false)
    const [valuesOtp, setValuesOtp] = React.useState(['', '', '', '', '','']);


    //HANDLE CONTI

    const [conti, setConti] = useState([])


    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //HANDLE FIRME

    const [firmeImg, setFirmeImg] = useState([]) 
    const [ modalSelectFirma, setModalSelectFirma] = useState(false)
    const showModalSelectFirma = () => setModalSelectFirma(true)
    const closeModalSelectFirma = () => {
        setModalSelectFirma(false)
        setNewFirma({
            name:"",
            img:"",
            created: false
        })
    }

    const [ modalCreateFirma, setModalCreateFirma] = useState(false)
    const showModalCreateFirma = () => {
        setModalCreateFirma(true)
        setNewFirma(prev => {
            return {
                ...prev,
                created: true,
            }
            })
    }
    const closeModalCreateFirma = () => {
        setModalCreateFirma(false)
        setNewFirma({
            name:"",
            img:"",
            created: false
        })
    }

    const [newFirma, setNewFirma] = useState({
        name:"",
        img:"",
        created: false,
    })

    function handleNewFirma(e){
        const {value, name} = e.target
        if(name == "img"){
            setNewFirma(prev => {
                return {
                    ...prev,
                    [name]: e.target.files[0]
                }
            })
        } else {
            setNewFirma(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            })
        }
    }

    function handleRemoveSelectedFirmaImg(){
        setNewFirma(prev => {
            return {
                ...prev,
                img: "",
                created: false
            }
        })
    }

    function addNewFirma(){
        setFirmeImg(prev => {
            return [...prev, newFirma]
        })
        closeModalSelectFirma()
    }

    function removeFirma(id){
        const newArr = firmeImg.filter((item,index )=> index != id)
        setFirmeImg(newArr)
    }

    //CREATE IMG
    let sigCanvas = useRef({})

    //ON SAVE => aggiungi new firma 
    function handleSign(e){
        setFirmeImg(prev => {
            return [...prev, {...newFirma, img: sigCanvas.getTrimmedCanvas().toDataURL('image/png')}]
        })
        closeModalCreateFirma()
    }

    /* const handleFileSelected = (file) => {
        console.log('File selezionato:', file);
        setFirmeImg(prev => {
            return [...prev, file]
        })
    }; */

    //HANDLE BOTH INTESTAZIONE E PIE DI PAGINA

    const [intestazioni, setIntestazioni] = useState([])

    const [modalIntestazione, setModalIntestazione] = useState(false)

    const [newIntestazione, setNewIntestazione] = useState({
        riga1:"",
        riga2:"",
        riga3:"",
        type:""
    })

    function showModalIntestazione(type){
        setModalIntestazione(true)
        setNewIntestazione(prev => {
            return {
                ...prev,
                type: type
            }
        })
    }

    function closeModalIntestazione(){
        setModalIntestazione(false)
        setNewIntestazione({
            riga1:"",
            riga2:"",
            riga3:"",
            type:""
        })
    }

    function handleNewIntestazione(e){
        const {name, value} = e.target

        setNewIntestazione(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function handleAddIntestazione(){
        setIntestazioni(prev => {
            return [...prev, newIntestazione]
        })

        closeModalIntestazione()
    }

    function deleteIntestazione(id){
        const newArr = intestazioni.filter((item, index) => index != id)
        setIntestazioni(newArr)
    }

    //EDIT INTESTAZIONE
    const [currentIntestazione, setCurrentIntestazione] = useState(null)
    const [modalEditIntestazione, setModalEditIntestazione] = useState(false)

    function showModalEditIntestazione(){
        setModalEditIntestazione(true)
    }

    function closeModalEditIntestazione(){
        setModalEditIntestazione(false)
        setCurrentIntestazione(null)
    }


    function editIntestazione(id){
        setCurrentIntestazione(intestazioni.find((item, index) => index == id))
        showModalEditIntestazione()
    }

    function handleEditIntestazione(e){
        const {name, value} = e.target
        setCurrentIntestazione(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    function updateIntestazione(){
        const newArr = intestazioni.map(item => {
            return item.type == currentIntestazione.type ?
                currentIntestazione:
                item
        })

        setIntestazioni(newArr)
        closeModalEditIntestazione()
    }



    //HANDLE LOGO

     const [logoImg, setLogoImg] = useState("")

     function handleLogoImg(e){
         setLogoImg(e.target.files[0])
     }
 
     function handleRemoveLogoImg(){
         setLogoImg("")
     }


    //GET DATA

    async function fetchData(type) {
            try {
                const response = await api().get(`${user_endpoint}`);
                if (response.data) {
                    setData(response.data?.content[0]);

                    console.log("response.data.content", response.data?.content[0])
                }
            } catch (error) {
                console.log(error);
            }
    }

    useEffect(() => {
            fetchData()
    }, [])

    console.log(data)


     //HANDLE UPDATE

     const updateData = async () => {
        let str = {...data}
        const id = str.id; // Salva il valore di data.id in una variabile separata
        delete str.id;
        delete str.cdat;
        delete str.udat;
        delete str.accountingResources

        try {
            await api().put(`${user_endpoint}${id}`, str); // Utilizza la variabile id invece di data.id
            await fetchData()
        } catch (err) {
            console.log(err);
        }
    };
    


     //FETCH PROVINCES

    const [provinceList, setProvincesList] = useState([])
    const [provinceBusinnesList, setProvincesBusinessList] = useState([])


    const fetchProvinces = () => {
        axios
        .get(
            `https://anthill-service.onrender.com/provinces?region`
        )
        .then((res) => {
            setProvincesList(res.data.data);
        });
    };

    const fetchProvincesBusiness = () => {
        axios
        .get(
            `https://anthill-service.onrender.com/provinces?region`
        )
        .then((res) => {
            setProvincesBusinessList(res.data.data);
        });
    };
    
    useEffect(() => {
        fetchProvinces()
    }, [data?.province])

    useEffect(() => {
        fetchProvincesBusiness()
    }, [data?.businessProvince])

    //FETCH MUNICIPALITIES

    const [citiesList, setCitiesList] = useState([])
    const [citiesBusinessList, setCitiesBusinessList] = useState([])


    const fetchCities = () => {
        axios
        .get(
            `https://anthill-service.onrender.com/municipalities?province=${data.province}`
        )
        .then((res) => {
            setCitiesList(res.data.data);
        });
    };
    
    useEffect(() => {
        if (data?.province){
            fetchCities()
        }
    }, [provinceList])

    const fetchCitiesBusiness = () => {
        axios
        .get(
            `https://anthill-service.onrender.com/municipalities?province=${data.businessProvince}`
        )
        .then((res) => {
            setCitiesBusinessList(res.data.data);
        });
    };
    
    useEffect(() => {
        if (data?.businessProvince){
            fetchCitiesBusiness()
        }
    }, [provinceBusinnesList])



    //HANDLE CONTO

    async function addConto(account){
        //POST
        try {
            let response = await api().post(`${user_accounts_endpoint}`, account)
            let responseID = response.data.id
            
            if(responseID) {
                await api().post(`${user_accounts_assignment_endpoint}`, {
                    accountingResourceID: responseID,
                    userID: data.id
                })
            }

            await fetchData()
        } catch (err) {
            console.log(err);
        }
    }

    async function removeConto(id){
        await api().delete(`${user_accounts_endpoint}${id}`)
        await fetchData()
        
    }

    const [editContoID, setEditContoId] = useState(null)

    async function updateAccount(account, id){
        //POST
        try {
            let response = await api().put(`${user_accounts_endpoint}${id}`, account)
            await fetchData()
        } catch (err) {
            console.log(err);
        }
    }

    //MANCA EDIT CONTO

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = (e) => {
      e.preventDefault(); // Previene l'apertura del link in una nuova scheda
      setIsModalOpen(true); // Mostra il modale
    };
  
    const handleCloseModal = () => setIsModalOpen(false);

  /*   const handleOpenPopup = () => {
        const popupUrl = 'https://connect.stripe.com/oauth/authorize?redirect_uri=https://connect.stripe.com/hosted/oauth&client_id=ca_PWaN2FQQKrf0f937vmmcYCzYPpWLmv39&state=onbrd_PZchlY1BEvK0Gs6L8qHBIKeSMk&response_type=code&scope=read_write&stripe_user[country]=IT';
         // Dimensioni desiderate per la finestra popup
        const width = 600;
        const height = 800;

        // Calcola le posizioni left e top per centrare la finestra
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        // Opzioni per la finestra popup, includendo le dimensioni e la posizione calcolate
        const options = `width=${width},height=${height},top=${top},left=${left},scrollbars=yes`;
            
        // Apertura della finestra popup
        window.open(popupUrl, "StripeOnboarding", options);
    }; */


    //GESTIONE SE AZIENDA

    const [isBusiness, setIsBusiness] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsBusiness(event.target.checked);
    };


    
    const formatDateToISO = (dateString) => {
        return dateString ? dateString.split('T')[0] : '';
      };

      const formattedBirthday = formatDateToISO(data?.birthday);

      console.log(data.phone, "data")


      //GESTIONE SCHEDE
      const [tabsNav, setTabsNav] = useState(
        () => JSON.parse(localStorage.getItem('tabsStatus')) || true
      );
    
      console.log(tabsNav, "TABS NAV");
    
      const handleTabsNav = () => {
        const newTabsNav = !tabsNav;
    
        // Aggiorna lo stato
        setTabsNav(newTabsNav);
    
        // Aggiorna localStorage
        localStorage.setItem('tabsStatus', JSON.stringify(newTabsNav));
    
        // Invia l'evento personalizzato
        const event = new CustomEvent('tabs-status-change', {
          detail: {
            key: 'tabsStatus',
            newValue: newTabsNav,
          },
        });
        window.dispatchEvent(event);
      };
    
      useEffect(() => {
        // Funzione di callback per gestire l'evento personalizzato
        const handleStorageTabChange = (event) => {
          if (event.detail.key === 'tabsStatus') {
            setTabsNav(event.detail.newValue);
          }
        };
    
        // Aggiunge l'ascoltatore per l'evento personalizzato
        window.addEventListener('tabs-status-change', handleStorageTabChange);
    
        // Pulisce l'ascoltatore quando il componente viene smontato
        return () => {
          window.removeEventListener('tabs-status-change', handleStorageTabChange);
        };
      }, []);
    
      useEffect(() => {
        // Inizializza il localStorage se non esiste
        if (localStorage.getItem('tabsStatus') === null) {
          localStorage.setItem('tabsStatus', JSON.stringify(true));
        }
      }, []);


    return(
        <>
            <div className="settings-tab-content">
                
               {(!checkSupplier() && !checkCollaborator() && !data?.stripeUser) && <Row>
                    <div className="text-center">
                        <h5 className="mb">Completa il tuo profilo</h5>
                        <p className="fs-14 opacity-75 ">
                        Per cominciare a ricevere pagamenti su Real Meta, bisogna abilitare il processo di pagamento.
                        </p>
                        <p className="fs-14 opacity-75 mb-4">Cliccando sul passaggio relativo ti indicheremo le sezioni da completare/compilare (segnalati col simbolo *)</p>
                        <Progress data={data}/>
                    </div> 

                 
                </Row>}

                {(checkSupplier()) && <ProgressFornitori data={data}  showMenu={props?.showMenu? props.showMenu : false}
                        handleSelect={props?.handleSelect}
                        setShowMenu={props?.setShowMenu} 
                        setKey={props?.setKey}/>}

<p className="fs-18 text-dark fw-bold">Informazioni Personali</p>
                    <p className="fs-14 opacity-75">Completa le informazioni per sbloccare tutte le funzionalità della piattaforma (invitare partecipanti in chat, effettuare pagamenti, notarizzazione)</p>
        
                    <hr className="opacity-10 my-3"/>

                <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {/*ANAGRAFICA*/}
                    <Row>
                        <Col md={12} className="ps-0 mb-3" id="anagrafica">
                            <p className="fs-16 text-dark fw-bold">Anagrafica <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</p>
                        </Col>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Nome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("name")}
                                        type="text" 
                                        name="name"
                                        value={data?.name}
                                        onChange={onChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.name?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Cognome <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                </Form.Label>
                                <Form.Control
                                    {...register("surname")}
                                    type="text"
                                    name="surname"
                                    value={data?.surname}
                                    onChange={onChange}
                                    isInvalid={!!errors.surname}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {errors.surname?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Indirizzo di Residenza <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("address")}
                                        type="text" 
                                        name="address"
                                        value={data?.address}
                                        onChange={onChange}
                                        isInvalid={!!errors.address}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.address?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Numero Civico e Interno <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                </Form.Label>
                                <Form.Control
                                    {...register("houseNumber")}
                                    type="text"
                                    name="houseNumber"
                                    value={data?.houseNumber}
                                    onChange={onChange}
                                    isInvalid={!!errors.houseNumber}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {errors.houseNumber?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Provincia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Select
                                        {...register("province")}
                                        type="text" 
                                        name="province"
                                        value={data?.province}
                                        onChange={onChange}
                                        isInvalid={!!errors.province}
                                    >
                                        <option value=""></option>
                                         {provinceList?.length > 0 && provinceList.map((province, index) => {
                                            return (
                                            <option value={province} key={index}>
                                                {province}
                                            </option>
                                            );
                                            })} 
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.province?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Comune <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Select
                                        {...register("municipality")}
                                        type="text" 
                                        name="municipality"
                                        value={data?.municipality}
                                        onChange={onChange}
                                        isInvalid={!!errors.municipality}
                                    >   
                                        <option value=""></option>
                                        
                                           { citiesList?.length > 0 &&
                                            citiesList.map((city, index) => {
                                                return (
                                                    <option value={city} key={index}>
                                                        {city}
                                                    </option>
                                                    );
                                                }
                                            )}
                                        
                                        <option></option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.municipality?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        CAP <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("cap")}
                                        type="number" 
                                        name="cap"
                                        value={data?.cap}
                                        onChange={onChange}
                                        isInvalid={!!errors.cap}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.cap?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Codice Fiscale <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                </Form.Label>
                                <Form.Control
                                    {...register("fiscalCode")}
                                    type="text"
                                    name="fiscalCode"
                                    value={data?.fiscalCode}
                                    onChange={onChange}
                                    isInvalid={!!errors.fiscalCode}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    {errors.fiscalCode?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Data di nascita <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("birthday")}
                                        type="date" 
                                        name="birthday"
                                        value={formattedBirthday} // Usare la data formattata qui
                                        onChange={onChange}
                                        isInvalid={!!errors.birthday}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.birthday?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Sesso <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Select
                                        {...register("genre")}
                                        type="text" 
                                        name="genre"
                                        value={data?.genre}
                                        onChange={onChange}
                                        isInvalid={!!errors.genre}
                                    >
                                         <option value=""></option>

                                        <option value="M">Maschio</option>
                                        <option value="F">Femmina</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.genre?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Username 
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("username")}
                                        type="text" 
                                        name="username"
                                        value={data?.username}
                                        onChange={onChange}
                                        isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.username?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                           
                        </Row>

                        <Row className="mx-0 px-0">
                            <Col md={6} className="my-3 mx-0 px-0">
                            <Form.Check 
                                type="checkbox"
                                label="Sono un'azienda"
                                name="isCompany"
                                checked={data.isCompany} 
                                onChange={onChange}
                            />
                            </Col>
                        </Row>

                        { data.isCompany &&
                            <>
                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                P. IVA <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                            </Form.Label>
                                            <Form.Control 
                                                {...register("vatNumber")}
                                                type="text" 
                                                name="vatNumber"
                                                value={data.vatNumber}
                                                onChange={onChange}
                                                isInvalid={!!errors.vatNumbero}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Ragione sociale <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessName"
                                                onChange={onChange}
                                                value={data?.businessName}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Indirizzo di fatturazione <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessAddress"
                                                onChange={onChange}
                                                value={data?.businessAddress}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                            Numero Civico e Interno <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessHouseNumber"
                                                onChange={onChange}
                                                value={data?.businessHouseNumber}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                    <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Provincia <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Select
                                        {...register("businessProvince")}
                                        type="text" 
                                        name="businessProvince"
                                        value={data?.businessProvince}
                                        onChange={onChange}
                                        isInvalid={!!errors.businessProvince}
                                    >
                                        <option value=""></option>
                                        {provinceBusinnesList?.length > 0 && provinceBusinnesList.map((province, index) => {
                                            return (
                                            <option value={province} key={index}>
                                                {province}
                                            </option>
                                            );
                                            })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.businessProvince?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                    
                                    </Col>
                                    <Col md={6} className="mb-3">
                                    <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Comune <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    </Form.Label>
                                    <Form.Select
                                        {...register("businessMunicipality")}
                                        type="text" 
                                        name="businessMunicipality"
                                        value={data?.businessMunicipality}
                                        onChange={onChange}
                                        isInvalid={!!errors.businessMunicipality}
                                    >   
                                        <option value=""></option>
                                        {
                                            citiesBusinessList?.length > 0 &&
                                            citiesBusinessList?.map((city, index) => {
                                                return (
                                                    <option value={city} key={index}>
                                                        {city}
                                                    </option>
                                                    );
                                                }
                                            )
                                        }
                                        <option></option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.businessMunicipalityMunicipality?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                    </Col>
                                
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                            CAP <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="businessCap"
                                                onChange={onChange}
                                                value={data?.businessCap}
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Codice univoco <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="uniqueCode"
                                                onChange={onChange}
                                                value={data?.uniqueCode}
                                                
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="p-0">
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Numero di dipendenti 
                                            </Form.Label>
                                            <Form.Control 
                                                type="number" 
                                                name="numberOfEmployees"
                                                onChange={onChange}
                                                value={data?.numberOfEmployees}
                                                
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group>
                                            <Form.Label 
                                                className="f-label fs-16 d-flex align-items-center
mb-2"
                                            >
                                                Ruolo
                                            </Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                name="companyRole"
                                                onChange={onChange}
                                                value={data?.companyRole}
                                                
                                            />
                                            <Form.Control.Feedback type="invalid" >
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        }
                        
                        <hr className="opacity-10 my-3"/>
                    </Row>

                    {/*EMAIL & PHONE*/}
                  {/*   {JSON.parse(localStorage.getItem("user"))?.role == "MASTER" && 
                    <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Indirizzo email
                                        <CustomBadge variant="custom-badge-success">
                                            Verificato
                                        </CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("email")}
                                        type="email" 
                                        name="email"
                                        value={data?.email}
                                        onChange={onChange}
                                        isInvalid={!!errors.email}
                                        readOnly
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.email?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0 d-flex flex-row">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Numero di telefono
                                    <CustomBadge variant="custom-badge-danger" onClick={showOtpModal}>
                                        Da verificare
                                    </CustomBadge>
                                    </Form.Label>
                                    <Form.Control
                                        {...register("phone")}
                                        type="number" 
                                        name="phone"
                                        value={data?.phone}
                                        onChange={onChange}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.phone?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row>} */}

                    {/*VERIFICHE IDENTITA'*/}
                        {/* <Row>
                            <Col md={12} className="ps-0 mb-3">
                                <p className="fs-16 text-dark fw-bold">Verifica la tua identità <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</p>
                            </Col>
                        </Row> */}
                    


              <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Indirizzo email <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                        <CustomBadge variant="custom-badge-success">
                                            Verificato
                                        </CustomBadge>
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("email")}
                                        type="email" 
                                        name="email"
                                        value={data?.email}
                                        onChange={onChange}
                                        isInvalid={!!errors.email}
                                        readOnly
                                        disabled
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.email?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0 ">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Numero di telefono <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
                                    {/* <CustomBadge variant="custom-badge-danger" onClick={showOtpModal}>
                                        Da verificare
                                    </CustomBadge> */}
                                    </Form.Label>
                                    <Form.Control
                                        type="number" 
                                        name="phone"
                                        value={data?.phone}
                                        onChange={onChange}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr className="opacity-10 my-3" id="social"/>                   


                        <Row className="p-0" >
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Profilo Linkedin
                                
                                    </Form.Label>
                                    <Form.Control
                                        type="Text" 
                                        
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                       

                        <Row className="p-0 d-flex flex-row" >
                            <Col md={6} className="mb-3" >
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Profilo Facebook
                                    
                                    </Form.Label>
                                    <Form.Control
                                        {...register("phone")}
                                        type="text" 
                                        name="phone"
                                        value={data?.phone}
                                        onChange={onChange}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.phone?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0 d-flex flex-row">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Sito internet
                                
                                    </Form.Label>
                                    <Form.Control
                                        {...register("phone")}
                                        type="number" 
                                        name="phone"
                                        value={data?.phone}
                                        onChange={onChange}
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.phone?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row>

                    {/*IMMAGINE DI PROFILO*/}
                    {/*       
                    <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-1">
                                <Form.Group >
                                    <Form.Label 
                                        className="fs-16 text-dark fw-bold"
                                    >
                                        Immagine di profilo**
                                    </Form.Label>
                                    {!profileImg &&  <Form.Control 
                                        //{...register("email")}
                                        type="file"
                                        onChange={handleProfileImg}
                                        accept="image/png, image/jpeg"
                                        //isInvalid={!!errors.email}
                                    />}
                                </Form.Group>
                            </Col>
                            {
                                profileImg && 
                                <Row>
                                    <div className="roundImgContainer roundImg">
                                        <Image roundedCircle src={profileImg? URL.createObjectURL(profileImg) : ""} className="roundImg border"/>
                                        <img loading="lazy" className="roundImgRemove" src={removeImg} onClick={handleRemoveProfileImg}/>
                                    </div>
                                </Row>
                            }
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row> */}

                    {/*FACE ID*/}
                    <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-1">
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label 
                                        className="fs-16 text-dark fw-bold"
                                    >
                                        Face Id <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>

                                    </Form.Label>

                                    {!faceIdPhoto && <div className="faceIdDiv" onClick={showFaceId}>
                                        <img loading="lazy" src={onlyFace} className="mb-3"/>
                                        <p >Carica il tuo Face ID</p>
                                    </div>}
                                </Form.Group>
                            </Col>
                            {
                                faceIdPhoto && 
                                <Row>
                                    <div className="roundImgContainer roundImg">
                                        <Image roundedCircle src={faceIdPhoto? faceIdPhoto : ""} className="roundImg border"/>
                                        <img loading="lazy" className="roundImgRemove" src={removeImg} onClick={handleRemoveFaceId}/>
                                    </div>
                                </Row>
                            }
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row>
                    
                    {/*DOCUMENTI*/}
                    <Row>
                        <Col md={12} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Documenti <span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</p>
                        </Col>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group >
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                    Tipo di documento
                                    </Form.Label>
                                    <Form.Select
                                        {...register("documentType")}
                                        type="text" 
                                        name="documentType"
                                        value={data?.documentType}
                                        onChange={onChange}
                                        isInvalid={!!errors.documentType}
                                    >
                                        <option></option>
                                        <option value="Carta d'identità">Carta d'identità</option>
                                        <option value="Passaporto">Passaporto</option>
                                        <option value="Patente">Patente</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.documentType?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Rilasciato da
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("issueEntity")}
                                        type="text" 
                                        name="issueEntity"
                                        value={data?.issueEntity}
                                        onChange={onChange}
                                        isInvalid={!!errors.issueEntity}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.issueEntity?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

        

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Data di rilascio
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("issueDate")}
                                        type="date" 
                                        name="issueDate"
                                        value={data?.issueDate}
                                        onChange={onChange}
                                        isInvalid={!!errors.issueDate}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.issueDate?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Luogo di rilascio
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("issuePlace")}
                                        type="text" 
                                        name="issuePlace"
                                        value={data?.issuePlace}
                                        onChange={onChange}
                                        isInvalid={!!errors.issuePlace}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.issuePlace?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Numero del documento
                                    </Form.Label>
                                    <Form.Control 
                                        {...register("documentNumber")}
                                        type="text" 
                                        name="documentNumber"
                                        value={data?.documentNumber}
                                        onChange={onChange}
                                        isInvalid={!!errors.documentNumber}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.documentNumber?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="p-0">
                            <Col md={6}>
                                <div className="faceIdDiv">
                                        <img loading="lazy" src={uploadImg} className="mb-3"/>
                                        <p>Clicca qui o trascina i documenti</p>
                                        <input type="file" />
                                    </div>
                             </Col>
                        </Row>
                        
                        <hr className="opacity-10 my-3"/>
                    </Row>

                    {/*CONTI*/}
                    {
                    <Row id="conto">
                        <Col md={12} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Conti</p>
                        </Col>

                        <Row className="p-0">
                            <Col md={6} className="mb-3">
                                <ButtonRm variant="outline" onClick={() => setShowCreateModal(true)}>Aggiungi Conto</ButtonRm>
                            </Col>
                        </Row>

                        <Row className="p-0">
                        { data?.accountingResources?.length > 0 && data?.accountingResources.map((bankAccount, index) => (
                                <BankCard 
                                key={index}
                                openingBalance={bankAccount.openingBalance}
                                name={bankAccount.name}         color="#78ab8b" 
                                deleteFunc={removeConto} 
                                index={bankAccount.id}
                                showModal={() => {
                                    setShowCreateModal(true)
                                    setEditContoId(bankAccount.id)
                                }}
                                >
                                <BankCardItem property="IBAN" value={bankAccount.iban}/>
                                <BankCardItem property="INTESTATO A" value={bankAccount.ownBy}/>
                                <BankCardItem property="BANCA" value={bankAccount.bank}/>
                                <BankCardItem property="ABI" value={bankAccount.abi}/>
                                <BankCardItem property="CAB" value={bankAccount.cabi}/>
                                <BankCardItem property="CIN" value={bankAccount.cin}/>
                                <BankCardItem property="CONTO" value={bankAccount.conto}/>
                                <BankCardItem property="BIC/SWIFT" value={bankAccount.bic}/>
                                <BankCardItem property="CODICE SIA" value={bankAccount.sia}/>
                                <BankCardItem property="AGENZIA" value={bankAccount.municipality}/>
                                <BankCardItem property="SEPA CUC" value={bankAccount.cucSepa}/>
                            </BankCard>
                            ))}

                        </Row>

                        <hr className="opacity-10 my-3"/>
                    </Row>
                    }

                    {/*FIRME*/}
                    <Row>
                        <Col md={12} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Le tue firme**</p>
                            <p className="fs-14 text-dark opacity-75">Crea o salva qui le firme che potrai utilizzare per firmare documenti sulla piattaforma.</p>
                        </Col>
                        <Row className="mb-3">
                            <Col className="px-0">
                            <ButtonRm variant="outline" className="me-2" onClick={showModalCreateFirma}>Crea firma</ButtonRm>

                            <ButtonRm variant="outline" onClick={showModalSelectFirma}>Seleziona firma</ButtonRm>
                            </Col>
                            
                        </Row>

                        <Row className="p-0 flex-column">
                        {
                            firmeImg.length > 0 && 
                            firmeImg.map((firma, index) => {
                                return <Col md={6} key={index}>
                                    
                                <div className="firmeCard">
                                    <div className="d-flex align-items-center">
                                        {firma.created ?
                                        <div className="roundImgContainer roundImgSmall">
                                        <Image roundedCircle src={firma.img? firma.img : ""} className="roundImgSmall border"/>
                                        </div> :
                                        <div className="roundImgContainer roundImgSmall">
                                            <Image roundedCircle src={firma.img? URL.createObjectURL(firma.img) : ""} className="roundImgSmall border"/>
                                        </div>}
                                        <p className="ms-3 f-16 ">{firma.name}</p>
                                    </div>
                                    <button
                                            type="button"
                                            className="btn round-icon-btn"
                                            onClick={() => removeFirma(index)}
                                            >
                                            <BsTrash />
                                    </button>
                                </div>
                            </Col>
                            })
                                
                            }

                        </Row>

        

                        
                        
                        <hr className="opacity-10 my-3"/>
                    </Row> 

                    {/*INTESTAZIONE E PIE' DI PAGINA*/}

                    { checkUserRole() &&
                        <Row>
                        <Row>
                        <Col md={12} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Intestazione e piè di pagina**</p>
                            <p className="fs-14 text-dark opacity-75">Crea o salva qui l'intestazione e piè di pagina che potrai utilizzare nei documenti sulla piattaforma.</p>
                        </Col>

                        </Row>
                        
                        {intestazioni.length != 1  &&  intestazioni.length < 2 &&
                        <Row>
                            <Col className="px-0 pb-3">
                            <ButtonRm variant="outline" className="me-2" onClick={() => showModalIntestazione("intestazione")}>Aggiungi Intestazione</ButtonRm>
            
                            <ButtonRm variant="outline" onClick={() => showModalIntestazione("pie")}>Aggiungi piè di pagina</ButtonRm>
                            </Col>                            
                        </Row>
                        }

                        { intestazioni.length == 1 &&  intestazioni?.[0].type == "intestazione" &&
                            <Row>
                                <Col className="px-0 pb-3">
                                    <ButtonRm variant="outline" onClick={() => showModalIntestazione("pie")}>Aggiungi piè di pagina</ButtonRm>
                                </Col>                            
                            </Row>
                        }
                        { intestazioni.length == 1 &&  intestazioni?.[0].type == "pie" &&
                            <Row>
                                <Col className="px-0 pb-3">               
                                    <ButtonRm variant="outline" onClick={() => showModalIntestazione("intestazione")}>Aggiungi intestazione</ButtonRm>
                                </Col>                            
                            </Row>
                        }

                        <Row className="p-0 flex-column">
                        {
                            intestazioni.length > 0 && 
                            intestazioni.map((item, index) => {
                                return <Col md={6} key={index} className="px-0 pb-2">
                                    
                                <div className="intCard">
                                    <div className="d-flex align-items-center">
                                        <p className="ms-3 f-16 ">{item.type == "intestazione" ? "Intestazione" : "Piè di pagina"}</p>
                                    </div>
                                    <div className="row">
                                        <button
                                                type="button"
                                                className="btn round-icon-btn"
                                                onClick={() => editIntestazione(index)}
                                                >
                                                <FiEdit2 />
                                        </button>
                                        <button
                                                type="button"
                                                className="btn round-icon-btn"
                                                onClick={() => deleteIntestazione(index)}
                                                >
                                                <BsTrash />
                                        </button>
                                    </div>
                                </div>
                            </Col>
                            })
                                
                            }

                        </Row>

                        <hr className="opacity-10 my-3"/>
                        </Row> 
                    }

                    {/* LOGO */}
                    {(checkUserRole())  &&  <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-1">
                                <Form.Group >
                                    <Form.Label 
                                        className="fs-16 text-dark fw-bold"
                                    >
                                        Logo**
                                    </Form.Label>
                                    {!logoImg &&  <Form.Control 
                                        //{...register("email")}
                                        type="file"
                                        onChange={handleLogoImg}
                                        accept="image/png, image/jpeg"
                                        //isInvalid={!!errors.email}
                                    />}
                                </Form.Group>
                            </Col>
                            {
                                logoImg && 
                                <Row>
                                    <div className="roundImgContainer roundImg">
                                        <Image roundedCircle src={logoImg? URL.createObjectURL(logoImg) : ""} className="roundImg border"/>
                                        <img loading="lazy" className="roundImgRemove" src={removeImg} onClick={handleRemoveLogoImg}/>
                                    </div>
                                </Row>
                            }
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row> }

                    {/*PASSWORD*/}
                     {/* <Row>
                        <Col md={12} className="ps-0 mb-3">
                            <p className="fs-16 text-dark fw-bold">Cambia Password**</p>
                            <p className="fs-14 text-dark opacity-75">Puoi modificare la tua password attuale da questa sezione.</p>
                        </Col>

                        <Row className="mb-3 flex-column px-0">
                            
                            <Col  md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Password Attuale
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga1"
                                        value={newIntestazione.riga1}
                                        onChange={handleNewIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            <Col  md={6}  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Nuova Password
                                    </Form.Label>
                                    <Form.Control 
                                        //{...register("newPassword")}
                                        type="text" 
                                        //name="newPassword"
                                        //value={data.newPassword}
                                        //onChange={onChange}
                                        isInvalid={!!errors.newPassword}
                                    />
                                    <Form.Control.Feedback type="invalid" >
                                    {errors.newPassword?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col  md={6}  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                       Conferma Password
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga1"
                                        value={newIntestazione.riga1}
                                        onChange={handleNewIntestazione}
                                    />
                                </Form.Group>
                            </Col>
                            
                        </Row>

                        <Row className="mb-3 px-0">
                            <Col>
                                <ButtonRm variant="outline" className="me-2">Annulla</ButtonRm>
                                <ButtonRm variant="outline-gray" >Aggiorna Password</ButtonRm>
                            </Col>
                        </Row>

                        <Row className="p-0 flex-column">
                        {
                            firmeImg.length > 0 && 
                            firmeImg.map((firma, index) => {
                                return <Col md={6} key={index}>
                                    
                                <div className="firmeCard">
                                    <div className="d-flex align-items-center">
                                        {firma.created ?
                                        <div className="roundImgContainer roundImgSmall">
                                        <Image roundedCircle src={firma.img? firma.img : ""} className="roundImgSmall border"/>
                                        </div> :
                                        <div className="roundImgContainer roundImgSmall">
                                            <Image roundedCircle src={firma.img? URL.createObjectURL(firma.img) : ""} className="roundImgSmall border"/>
                                        </div>}
                                        <p className="ms-3 f-16 ">{firma.name}</p>
                                    </div>
                                    <button
                                            type="button"
                                            className="btn round-icon-btn"
                                            onClick={() => removeFirma(index)}
                                            >
                                            <BsTrash />
                                    </button>
                                </div>
                            </Col>
                            })
                                
                            }

                        </Row>

        

                        
                        
                        <hr className="opacity-10 my-3"/>
                    </Row>  */}

                    {/*DISPOSITIVI*/}
                     <Row>
                        <Row className="p-0">
                            <Col md={6} className="mb-1">
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label 
                                        className="fs-16 text-dark fw-bold"
                                    >
                                        I tuoi dispositivi**
                                    </Form.Label>

                                    <div className="faceIdDiv d-flex align-items center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img loading="lazy" src={deskIcon} width="50px"/>
                                            </div>
                                            <div className="text-start ms-3">
                                                <p className="fs-16 fw-bold">Nome dispositivo</p>
                                                <p className="FS-12">Luogo, data di connessione</p>
                                            </div>
                                        </div>
                                        <ButtonRm variant="outline" >Disconnetti</ButtonRm>
                                    </div>

                                </Form.Group>
                            </Col>
                            {
                                faceIdPhoto && 
                                <Row>
                                    <div className="roundImgContainer roundImg">
                                        <Image roundedCircle src={faceIdPhoto? faceIdPhoto : ""} className="roundImg border"/>
                                        <img loading="lazy" className="roundImgRemove" src={removeImg} onClick={handleRemoveFaceId}/>
                                    </div>
                                </Row>
                            }
                        </Row>

                        <hr className="opacity-10 my-3"/>                   
                    </Row> 

                    <Row>
                        { checkMaster() &&
                        <Row className="p-0">
                            <Col md={6} className="mb-1">
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label 
                                        className="fs-16 text-dark fw-bold"
                                    >
                                       Le tue preferenze
                                    </Form.Label>

                         {/*            <div className="faceIdDiv d-flex  flex-column align-items-start mb-4">
                                        <div className="d-flex flex-column">
                                            <div className="text-start ms-3">
                                                <p className="fs-16 fw-bold">Abilita schede di navigazione</p>
                                                <p className="fs-12 mb-3">Le schede di navigazione ti permettono di tenere traccia dei vari elementi che visiti su Real Meta. Puoi avere massimo 15 schede aperte, dopodiché dovrai rimuoverne alcune per poterne aggiungere di nuove.</p>
                                            </div>
                                        </div>
                                        <ButtonRm size="small" variant="outline" onClick={handleTabsNav}>{tabsNav ? "Disabilita" : "Abilita"}</ButtonRm>
                                    </div> */}

                                    <div className="faceIdDiv d-flex   flex-column align-items center justify-content-between">
                                        <div className="d-flex flex-column">
                                            <div className="text-start ms-3">
                                                <p className="fs-16 fw-bold">Sei alla ricerca di nuove proprietà da gestire?</p>
                                                <p className="fs-12">
                                                Stai cercando nuove proprietà da gestire? Riceviamo ogni giorno richieste di proprietari in cerca di un gestore. A seconda delle esigenze, creiamo dei match tra loro e i gestori di Real Meta
                                                </p>
                                                <u className="fs-14">Scopri i termini e le condizioni</u>

                                            </div>
                                            <div className="mt-3">
                                <Form.Check 
                                    type="check"
                                    label="Sono disponibile e accetto le condizioni del servizio"
                                    className="text-start"

                                />

                                <Form.Check 
                                    type="check"
                                    label="Non accetto"
                                    className="text-start"
                                />
                            </div>
                                        </div>
                                       
                                    </div>

                                </Form.Group>
                            </Col>
                            {
                                faceIdPhoto && 
                                <Row>
                                   
                                </Row>
                            }
                        </Row> 
                        }

                        <hr className="opacity-10 my-3"/>                   
                    </Row> 

                    <ButtonRm variant="outline-gray" type="submit">Salva</ButtonRm>
                </Form>

                {/* MODAL FACEID */}
                <Modal
                    show={faceIdModal}
                    onHide={closeFaceId}
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Face ID</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div
                        className="container"
                        >
                        <div
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            }}
                        >
                            {camera ? (
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                style={{ width: "100%" }}
                                className="tab5Content"
                            />
                            ) : (
                            <img
                                src={FaceIdImg}
                                className="tab5Content"
                                style={{ maxWidth: "60%" }}
                            ></img>
                            )}
                            {!camera &&
                            <div onClick={handleWebcamAccess} className="capture-btn fs-16">
                                Clicca qui
                            </div>
                            }
                        </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="d-flex flex-column justify-content-center align-item-center">
                    {camera && <ButtonRm variant="primary" onClick={capture}>Scatta foto</ButtonRm>}
                    <ButtonRm variant="text" onClick={closeFaceId}>Annulla</ButtonRm>
                    </Modal.Footer>
                </Modal>

                {/* MODAL OTP */}
                <Modal
                    show={otpModal}
                    onHide={closeOtpModal}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-4 pt-0 text-center"
                        >
                            <img loading="lazy" src={logoRed} />
                            <p className="fs-18 fw-bold mt-3 mb-2">Inserisci OTP</p>
                            <p className="fs-14 mb-4">Ti abbiamo inviato l’OTP di 6 cifre al numero {data?.phone}. Inseriscilo nel campo sottostante per verificare il tuo numero</p>
                            <PinInput
                            values={valuesOtp}
                            onChange={(value, index, values) => setValuesOtp(values)}
                            />
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                            <ButtonRm variant="primary" onClick={closeOtpModal}>Verifica</ButtonRm>
                            <ButtonRm variant="text">Rinvia OTP</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
                </Modal>
                
                {/* MODAL SELECT FIRMA */}
                <Modal
                    show={modalSelectFirma}
                    onHide={closeModalSelectFirma}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-2 pt-0"
                        >
                            <p className="fs-18 fw-bold mt-1 mb-2">Seleziona firma</p>
                            <p className="fs-16 mb-2">Consigliamo di caricare un file con sfondo trasparente o bianco in cui sia visibile solo la firma.</p>
                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Identificativo firma
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="name"
                                        value={newFirma.name}
                                        onChange={handleNewFirma}
                                    />
                                </Form.Group>
                            </Col>
                            <Col  className="mb-1">
                                <Form.Group >
                                    {!newFirma.img &&  <Form.Control 
                                        type="file"
                                        name="img"
                                        onChange={handleNewFirma}
                                        accept="image/png, image/jpeg"
                                    />}
                        
                                {newFirma.img && !newFirma.created &&
                                    <Row>
                                        <div className="roundImgContainer roundImg">
                                            <Image roundedCircle src={newFirma.img? URL.createObjectURL(newFirma.img) : ""} className="roundImg border"/>
                                            <img loading="lazy" className="roundImgRemove" src={removeImg} onClick={handleRemoveSelectedFirmaImg}/>
                                        </div>
                                    </Row>
                                }
                                </Form.Group>
                            </Col>
                            
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                            <ButtonRm variant="primary" onClick={addNewFirma}>Salva</ButtonRm>
                            <ButtonRm variant="text" onClick={closeModalSelectFirma}>Annulla</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
                </Modal>

                {/* MODAL CREATE FIRMA */}
                <Modal
                    show={modalCreateFirma}
                    onHide={closeModalCreateFirma}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-2 pt-0"
                        >
                        <p className="fs-18 fw-bold mt-0 mb-2">Aggiungi firma</p>
                        <div className="border signature-canva mb-3">
                            <SignatureCanvas penColor='black'
                            ref={(ref) => {sigCanvas = ref }}
                            canvasProps={{ height: 350, className: 'sigCanvas'}} 
                            />
                        </div>

                        <Col className="mb-3">
                            <Form.Group>
                                <Form.Label 
                                    className="f-label fs-16 d-flex align-items-center
mb-2"
                                >
                                    Identificativo firma
                                </Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="name"
                                    value={newFirma.name}
                                    onChange={handleNewFirma}
                                />
                            </Form.Group>
                        </Col>
                        
                        <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                        <ButtonRm variant="primary" onClick={handleSign}>Salva</ButtonRm>
                        <ButtonRm variant="text" onClick={closeModalCreateFirma}>Annulla</ButtonRm>
                        </div>
                        
                        </div>

                    </Modal.Body>
                </Modal>

                {/* MODAL INTESTAZIONE */}
                <Modal
                    show={modalIntestazione}
                    onHide={closeModalIntestazione}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-2 pt-0"
                        >
                            {newIntestazione.type == "intestazione" && 
                            <>
                                <p className="fs-18 fw-bold mt-1 mb-2">Crea Intestazione</p>
                                <p className="fs-16 mb-2">Potrai inserire questa intestazione nei tuoi documenti</p>
                            </>
                            }
                            {newIntestazione.type == "pie" && 
                            <>
                                <p className="fs-18 fw-bold mt-1 mb-2">Crea Piè di pagina</p>
                                <p className="fs-16 mb-2">Potrai inserire questo piè di pagina nei tuoi documenti</p>
                            </>
                            }
                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Riga 1
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga1"
                                        value={newIntestazione.riga1}
                                        onChange={handleNewIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Riga 2
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga2"
                                        value={newIntestazione.riga2}
                                        onChange={handleNewIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Riga 3
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga3"
                                        value={newIntestazione.riga3}
                                        onChange={handleNewIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                            <ButtonRm variant="primary" onClick={handleAddIntestazione}>Salva</ButtonRm>
                            <ButtonRm variant="text" onClick={closeModalIntestazione}>Annulla</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
                </Modal>

                {/* MODAL EDIT INTESTAZIONE */}
                <Modal
                    show={modalEditIntestazione}
                    onHide={closeModalEditIntestazione}
                    centered
                >
                    <Modal.Header closeButton style={{border:"none"}}>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                        className="px-4 pb-2 pt-0"
                        >
                            {currentIntestazione?.type == "intestazione" && 
                            <>
                                <p className="fs-18 fw-bold mt-1 mb-2">Modifica Intestazione</p>
                            </>
                            }
                            {currentIntestazione?.type == "pie" && 
                            <>
                                <p className="fs-18 fw-bold mt-1 mb-2">Modifica piè pagina</p>
                            </>
                            }
                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Riga 1
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga1"
                                        value={currentIntestazione?.riga1}
                                        onChange={handleEditIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Riga 2
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga2"
                                        value={currentIntestazione?.riga2}
                                        onChange={handleEditIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            <Col  className="mb-3">
                                <Form.Group>
                                    <Form.Label 
                                        className="f-label fs-16 d-flex align-items-center
mb-2"
                                    >
                                        Riga 3
                                    </Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="riga3"
                                        value={currentIntestazione?.riga3}
                                        onChange={handleEditIntestazione}
                                    />
                                </Form.Group>
                            </Col>

                            
                            <div className="mt-4 d-flex flex-column align-items-center justify-content-center"  >
                            <ButtonRm variant="primary" onClick={updateIntestazione}>Salva</ButtonRm>
                            <ButtonRm variant="text" onClick={closeModalEditIntestazione}>Annulla</ButtonRm>
                            </div>
                        
                        </div>

                    </Modal.Body>
                </Modal>
                
                {/* MODAL EDIT INTESTAZIONE */}
                <CreateManagerAccount
                show={showCreateModal}
                setShow={setShowCreateModal}
                setShowDeleteModal={setShowDeleteModal}
                addBankAccount={addConto}
                settings={true}
                editContoID={editContoID}
                setEditContoId={setEditContoId}
                updateAccount={updateAccount}
            />
            </div >
        </>

        
    )
}