import { GET_DEADLINE, EDIT_DEADLINE, DELETE_DEADLINE, ADD_DEADLINE } from "./types";
import api from '../api/api'
import { deadline_endpoint} from "../api/api";

/*
STEP PER SALVARE DATI IN REDUX:
1. CREA ACTION TYPE IN ./types
2. CREA ACTION CREATORS (fetch..., add..., edit..., etc)
3. CREA REDUCER E IMPORTALO IN reducers/index.js
4. ESPORTA TUTTO
5. IMPORTA DOVE SERVE USEDISPATCH E USESELECTOR E ACTION CREATORS
*/


/* ACTION CREATORS */
export const fetchDeadline =  ()  =>  async (dispatch)=> {
    const response = await api().get(`${deadline_endpoint}`)
    dispatch({
        type: GET_DEADLINE,
        payload: response.data?.content
    })
}

export const addDeadline =  (data)  =>  async (dispatch)=> {
    const response = await api().post(`${deadline_endpoint}`, data)
    dispatch({
        type: ADD_DEADLINE,
        payload: data
    })
}

export const editDeadline =  (id, data)  =>  async (dispatch)=> {
    const response = await api().put(`${deadline_endpoint}${id}`, data)
    dispatch({
        type: EDIT_DEADLINE,
        payload: {
                    data, 
                    id
                }
    })
}

export const removeDeadline =  (id)  =>  async (dispatch)=> {
    const response = await api().delete(`${deadline_endpoint}${id}`)
    dispatch({
        type: DELETE_DEADLINE,
        id: id
    })
}


/* REDUCER */

const initialState = {
    deadline: [],
}

const deadlineReducer = (state = initialState, action) => {
    switch(action.type){
        case GET_DEADLINE:
            return {...state, 
                    deadline: action.payload
                }
        case ADD_DEADLINE:
            return {
                ...state,
                deadline: [...state.deadline, action.payload]
            }
        case EDIT_DEADLINE:
            const newData = action.payload.data
            const updatedDeadline = state.deadline && state.deadline.length > 0?
            state.deadline.map((el) => {
                if (el.id === action.payload.id) {
                return {...el, newData}
                } else {
                return el;
                }
            }) : []
            

            return  {
                ...state,
                deadline: updatedDeadline
            }

        case DELETE_DEADLINE:
            const deadlines = state.deadline.filter( el => el.id !== action.id)
            return  {
                ...state,
                deadline: deadlines
            }
        default:
            return state
    }
}

export default deadlineReducer