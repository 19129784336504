import Button from 'react-bootstrap/Button';
import '../assets/css/ButtonRm.css'

export default function ButtonRm({children, variant, size='default', type="button", bord="", ...props}) {

    /*
    
    this component has 4 variants for style:
    -primary
    -secondary
    -tertiary
    -text
    -pink
    -blue
    -slc
    -outline
    -outline-gray
    -outline-red

    and 3 for size:
    default
    full-width
    small
    full-width-small
    extra-small

    variant:
    primary-round

    you can also pass custom classes without problem

    */

    return (
        <Button variant={`rm-${variant}`} size={size} type={type}  {...props}>
            {children}
        </Button>
    )
}