import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TopHeading from "../TopHeading";
import { Col } from "react-bootstrap";
import RegistryDataE from "./Edit/RegistryDataE";
import RegistrationE from "./Edit/RegistrationE";
import UnitE from "./Edit/UnitE";
import ContractE from "./Edit/ContractE";
import ConductorE from "./Edit/ConductorE";
import AttachmentsE from "./Edit/AttachmentsE";
import CurrentGuarantorE from "./Edit/CurrentGuarantorE";
import GuarantorE from "./Edit/GuarantorE";
import TakeoverE from "./Edit/TakeoverE";
import estate_img from "../../assets/images/real_estate.png";
import Modal from "react-bootstrap/Modal";
import api, { affitti_endpoint, unit_endpoint, rent_conductor_assignment_endpoint, rent_unit_assignment_endpoint,   user_accounts_endpoint,
	bank_account_endpoint } from "../../api/api";
import Typology from "./Edit/Typology"

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import CustomBadge from "../CustomBadge";



const EditAffitti = () => {
	const navigate = useNavigate()

	const { id } = useParams();
	const [formData, setformData] = useState({});
	const [units, setUnits] = useState([]);
	const [unitsIndex, setUnitsIndex] = useState(-1);
	// useEffect(() => {
	// 	if (unitsIndex != -1) {
	// 		const unitsData = {
	// 			province: units[unitsIndex].province,
	// 			municipality: units[unitsIndex].municipality,
	// 			municipalityCode: units[unitsIndex].municipalityCode,
	// 			paper: units[unitsIndex].sheet,
	// 			address: units[unitsIndex].address,
	// 			category: units[unitsIndex].categoryLandRegistry,
	// 			classType: units[unitsIndex].classLandRegistry,
	// 			consistency: units[unitsIndex].consistence,
	// 			cadastralIncome: units[unitsIndex].cadastralIncome,
	// 			urbanSection: units[unitsIndex].urbanSection,
	// 			zone: units[unitsIndex].zone,
	// 			microZone: units[unitsIndex].microZone,
	// 		};
	// 		setformData({ ...formData, ...unitsData });
	// 	}
	// }, [unitsIndex]);

	async function getUnits() {
		try {
			const response = await api().get(`${unit_endpoint}`);
			if (response.data) {
				
				setUnits(response.data.content);
				console.log(response.data.content);
			}
		} catch (err) {
			console.log(err);
		}
	}
	useEffect(() => {
		getUnits();
	}, []);

	const getAffittiById = async () => {
		try {
			const res = await api().get(`${affitti_endpoint}${id}`);
			if (res.data) {
				setformData(res.data);
				if (res.data.unit?.length > 0) {
					setUnitsIndex(res.data.unit.id);
				}
				if (res.data.registrationContractExpenses) {
					const parsedReg = JSON.parse(res.data.registrationContractExpenses)
					res.data.registrationContractExpenses = parsedReg;
				}
				if (res.data.subEntrance) {
					const parsedsubEntrance = JSON.parse(res.data.subEntrance)
					res.data.subEntrance = parsedsubEntrance;
				}

				if (res.data.bankAccount) {
					const parsedbankAccount = JSON.parse(res.data.bankAccount)
					res.data.bankAccount = parsedbankAccount;
				}

				console.log(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const [managerAccounts, setManagerAccounts] = useState([])
	const [accounts, setAccounts] = useState([])
	const [ownerAccounts, setOwnerAccounts] = useState([])
  
  
	//CONTI GESTORI
	async function getAccounts() {
	  try {
		const response = await api().get(`${user_accounts_endpoint}`);
		if (response.data) {
		  setManagerAccounts(response.data.content);
		}
	  } catch (err) {
		console.log(err);
	  }
	}
  
	//CONTI MEMBRI
	async function getBankAccounts() {
	  try {
		const response = await api().get(`${bank_account_endpoint}`);
		if (response.data) {
		  setAccounts(response.data.content);
		}
	  } catch (err) {
		console.log(err);
	  }
	}

	useEffect(() => {
		getAffittiById();
		getAccounts()
		getBankAccounts()
	}, []);

	useEffect(() => {
		if (formData.units?.length > 0) {
		  let ids = formData.units.flatMap(unit => unit.owners?.map(owner => owner.id)) // Utilizzo flatMap per ottenere un array piatto di ID degli owner
	  
		  // Calcolo degli account degli owner
		  const contiCorrispondenti = accounts.filter(conto => {
			return conto.owners.some(owner => ids.includes(owner.id));
		  });
	  
		  // Salva gli account degli owner nello stato ownerAccounts
		  setOwnerAccounts(contiCorrispondenti);
		}
	  }, [formData.units]);


	const update = async (data) => {
		//stringify
		//associazione - dissociazione
		try {
		const newForm = { ...formData, ...data }

		const conductors = [...newForm.conductors]
		const units = [...newForm.units]
		const attachment = [...newForm.attachment]
		let newAttachment = [];

		if (Array.isArray(newForm.newAttachment)) {
		// `newForm.newAttachment` è un array, puoi clonarlo correttamente
		newAttachment = [...newForm.newAttachment];
		} else {
		// `newForm.newAttachment` non è un array o non è un iterabile
		// Esegui le operazioni appropriate per la tua logica, ad esempio, assegna un valore di default o mostra un messaggio di errore.
		console.error("newForm.newAttachment non è un iterabile valido");
		}



		/*REMOVE UNITS AND CONDUCTORS FROM FORM DATA */
		delete newForm.conductors;
		delete newForm.units;
		delete newForm.attachment
		delete newForm.newAttachment

		//GESTIONE ALLEGATI
		let formDataToSend = new FormData();
	
		newAttachment.forEach((file) => {
			const blob = new Blob([file]);
			formDataToSend.append("parts", blob, file.name);
		});

		let sentUpload = formDataToSend.get("parts") !== null;
		if (sentUpload) {
		await api().put(`${affitti_endpoint}upload/${id}`, formDataToSend);
		}
		// Aggiorna lo stato dei dati per includere i nuovi allegati

		/***/

		//STRINIGFY E SEND

		console.log({
			formData: formData.conductors,
			data: data.conductors,
			conductors
		}, "wee")
		
		let response 
		response = await api().put(`${affitti_endpoint}${id}`, {
			...newForm,
			registrationContractExpenses:JSON.stringify(newForm.registrationContractExpenses),
			subEntrance: JSON.stringify(newForm.subEntrance),
			bankAccount:JSON.stringify(newForm.bankAccount)
			});

			// ASSOCIAZIONE CONDUTTORI
			if (conductors.length > 0 && response.data.id) {
				for (const conductor of conductors) {
					const isAlreadyAssociated = formData.conductors.some(existingEl => existingEl.id === conductor.id);

					if (!isAlreadyAssociated) {
						await api().post(`${rent_conductor_assignment_endpoint}`, {
						conductorID: conductor.id,
						rentID: response.data.id,
						});
					}
				}
			}

			// ASSOCIAZIONE UNITA'
			if (units.length > 0 && response.data.id) {
				for (const unit of units) {
					const isAlreadyAssociated = formData.units.some(existingEl => existingEl.id === unit.id);

					if (!isAlreadyAssociated) {
						await api().post(`${rent_unit_assignment_endpoint}`, {
						unitID:unit.id,
						rentID: response.data.id,
						});
					}
				}
			}

			///DISSOCIAZIONE CONDUTTORI ELIMINATI
			if (formData.conductors.length > 0) {
				for (const conductor of formData.conductors) {
				// Verifica se conduttore preesistente è stato eliminato
				const isDeleted = !conductors.some(el => el.id === conductor.id);
			
					// Se conduttore è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${rent_conductor_assignment_endpoint}disassociate`,
							data: {
								conductorID: conductor.id,
								rentID: response.data.id,
							}
						})
						
					}
				}
			}

			///DISSOCIAZIONE UNITA ELIMINATE
			if (formData.units.length > 0) {
				for (const unit of formData.units) {
				// Verifica se unità preesistente è stato eliminato
				const isDeleted = !units.some(el => el.id === unit.id);
			
					// Se unità è stato eliminato, effettua la disassociazione
					if (isDeleted) {
						await api()({
							method: 'delete',
							url:`${rent_unit_assignment_endpoint}disassociate`,
							data: {
								unitID: unit.id,
								rentID: response.data.id,
							}
						})
						
					}
				}
			}



		} catch (err) {
			console.log(err);
		} 
		setSaveState(false);
		await getAffittiById()
	};
	const [saveState, setSaveState] = useState(false);
	// const save = () => {
	// 	setEditMode(false);
	// 	setSaveState(true);
	// };

	// const cancelEdit = () => {
	// 	setEditMode(false);
	// 	setSaveState(false);
	// };

	const [state, setState] = useState(0);
	const [show, setShow] = useState(false);
	const [modify_cancel, setModifyCancel] = useState(false);
	const handleClose = () => setShow(false);
	const [full_mode, setFullMode] = useState(false);
	const [edit_mode, setEditMode] = useState(false);

	const save = () => {
		console.log("save function")
		if (state == 5 || state == 6 || state == 7) {
			handleState(4);
		} else handleState(state);
		setSaveState(true);
	};
	const handleState = (index) => {
		setState(index);
		if (index == 3 || index == 5 || index == 6 || index == 7 || index == 8)
			setFullMode(true);
		else {
			setFullMode(false);
		}
		if (index == 5 || index == 6) setEditMode(true);
		else setEditMode(false);
	};
	/* const handleModify = () => {
		if (state == 5 || state == 6) {
			handleState(4);
		} else setModifyCancel(true);
		setSaveState(false);
	}; */
	return (
		<>
			<div className="title-search-group">
				<div className="right-header">
					<TopHeading SmallHeading="" Heading="" back_modal={false} />
				</div>
			</div>

			<Col md={12} className="my-3 px-0 mx-2">
				<Breadcrumb>
					<Breadcrumb.Item 
						className="settings-archive"
						onClick={() => {
							navigate('/affitti')
						}}>
						Affitti
					</Breadcrumb.Item>

					
				
					<Breadcrumb.Item active={true} 
						className="settings-archive"
					>
						{formData?.name}
					</Breadcrumb.Item>
				</Breadcrumb>
			</Col>

			<div className="">
				<div className="w-100">
					<div className="d-flex align-items-center justify-content-between mb-3 w-100">
						<h2 className="title-font fw-bold fs-24 text-capitalize">
							{formData.name ? formData.name : ""}
						</h2>
							<div className="flex-shrink-0">
								<div
									className={`d-flex align-items-center ${
										edit_mode ? "d-none" : ""
									}`}>
									<div
										className="edit-btn w-144 me-2"
										onClick={() => {
											setEditMode(true);
										}}>
										Modifica
									</div>
								</div>
								<div
									className={`d-flex align-items-center ${
										edit_mode ? "" : "d-none"
									}`}>
									<div className="edit-btn w-173 me-2" onClick={save}>
										Salva modifiche
									</div>
									<button
										type="button"
										
										className="border border-secondary bg-white rounded-3 edit-icon-btn">
										<i
											class="bi bi-x"
											style={{ color: "var(--edit-color)" }}></i>
									</button>
								</div>
							</div>
					</div>
					<div
						className="mobile-state-bar mt-2"
						onChange={(e) => {
							handleState(e.target.value);
						}}>
						<select className="select-state">
							<option value="0">Dati</option>
							<option value="1">Registrazione</option>
							<option value="2">Unità</option>
							<option value="3">Contratto</option>
							<option value="4">Conduttori</option>
							<option value="5">Allegati</option>
						</select>
					</div>
					<div className="state-bar  no-img-state-bar">
					
					<div className="state-btn-group d-flex">
						<div
							className={`${state == 2 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(2);
							}}>
							Unità
						</div>
						<div
							className={`${state == 9 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(9);
							}}>
							Tipologia
						</div>
						<div
							className={`${
								state == 4 || state == 5 || state == 6 || state == 7
									? "active"
									: ""
							} tabs-title`}
							onClick={(e) => {
								handleState(4);
							}}>
							Conduttori
						</div>
						<div
							className={`${state == 0 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(0);
							}}>
							Anagrafica
						</div>
						<div
							className={`${state == 1 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(1);
							}}>
							Registrazione
						</div>
						<div
							className={`${state == 3 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(3);
							}}>
							Contratto
						</div>
						<div
							className={`${state == 8 ? "active" : ""} tabs-title`}
							onClick={(e) => {
								handleState(8);
							}}>
							Allegati
						</div>

					</div>
				</div>
					{state == 0 && (
						<RegistryDataE
							formData={formData}
							update={update}
							full_mode={full_mode}
							setFullMode={setFullMode}
							edit_mode={edit_mode}
							saveState={saveState}
							managerAccounts={managerAccounts}
							ownerAccounts={ownerAccounts}
						/>
					)}
					{state == 1 && (
						<RegistrationE
							formData={formData}
							update={update}
							full_mode={full_mode}
							setFullMode={setFullMode}
							edit_mode={edit_mode}
							saveState={saveState}
							setformData={setformData}
						/>
					)}
						{state == 2 && (
						<UnitE
							formData={formData}
							update={update}
							saveState={saveState}
							full_mode={full_mode}
							setFullMode={setFullMode}
							edit_mode={edit_mode}
							units={units}
						/>
					)}
					{state == 3 && (
						<ContractE
							formData={formData}
							update={update}
							edit_mode={edit_mode}
							saveState={saveState}
						/>
					)}
					{state == 4 && (
						<ConductorE
							formData={formData}
							update={update}
							saveState={saveState}
							full_mode={full_mode}
							setFullMode={setFullMode}
							edit_mode={edit_mode}
							handleState={handleState}
						/>
					)}
					{state == 5 && (
						<CurrentGuarantorE
							formData={formData}
							update={update}
							saveState={saveState}
							edit_mode={edit_mode}
						/>
					)}
					{state == 6 && (
						<GuarantorE
							formData={formData}
							update={update}
							saveState={saveState}
							edit_mode={edit_mode}
						/>
					)}
					{state == 7 && (
						<TakeoverE
							formData={formData}
							update={update}
							saveState={saveState}
							edit_mode={edit_mode}
						/>
					)}
					{state == 8 && (
						<AttachmentsE
							formData={formData}
							update={update}
							saveState={saveState}
							full_mode={full_mode}
							setFullMode={setFullMode}
							edit_mode={edit_mode}
							id={id}
						/>
					)}
					{state == 9 && (
						<Typology
							formData={formData}
							update={update}
							saveState={saveState}
							full_mode={full_mode}
							setFullMode={setFullMode}
							edit_mode={edit_mode}
							units={units}
						/>
					)}
				</div>
			</div>
			{/* <Modal show={show} onHide={handleClose} centered>
				<Modal.Body className="p-4">
					<div className="d-flex flex-column text-center align-items-center">
						<h2>Vuoi eliminare questo affitto?</h2>
						<h6 className="fs-16 text-dark fw-normal mb-3">
							Questa azione è irreversibile
						</h6>
						<div className="edit-btn w-193">Conferma</div>
						<button
							type="button"
							className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
							onClick={handleClose}>
							Annulla
						</button>
					</div>
				</Modal.Body>
			</Modal> */}
			<Modal
				show={modify_cancel}
				onHide={() => setModifyCancel(false)}
				centered
				>
				<Modal.Body className="p-4">
					<div className="d-flex flex-column text-center align-items-center">
						<h2>Vuoi annullare le modifiche?</h2>
						<h6 className="fs-16 text-dark fw-normal mb-3">
							Se non salvi le modifiche, i cambiamenti effettuati andranno persi
						</h6>
						<div
							className="edit-btn w-100 mb-2"
							onClick={() => {
								setEditMode(false);
								setModifyCancel(false);
							}}>
							Conferma annullamento
						</div>
						<button
							type="button"
							className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
							onClick={() => setModifyCancel(false)}>
							Indietro
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default EditAffitti;
