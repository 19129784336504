import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MediaQuery from "react-responsive";
import "../../../../assets/css/Rate.css";

import icon1 from "../../../../assets/images/chat-alt.svg";
import icon2 from "../../../../assets/images/Trash_light.svg";
import icon3 from "../../../../assets/images/Edit_light.svg";
import icon4 from "../../../../assets/images/Print_light.svg";
import icon5 from "../../../../assets/images/download.svg";

import {Link} from "react-router-dom"

export default function MonthRateG(props) {
  const [show, setShow] = useState(false);

   //CURRENT DATE
    const today = new Date()
    const deadline = new Date(props.item?.deadlineDate?.substring(0,10))
    const competenceStart = new Date(props.item?.competenceStartDate?.substring(0,10))

    const isDue = today.getTime() >= competenceStart.getTime()
  
    let classForDate
  
    if ((today.getTime() > deadline.getTime()) &&( props.item?.totalDue > 0)) {
      classForDate = "text-danger"
    } else {
      classForDate = "text-success"
    }

    const url = window.location.pathname;

  return (
    <>
    <Link to={`/rate/${props.item?.id}`} state={{ from: url, name: props.pageTitle }}>
      <MediaQuery minWidth={1000}>
        <div
          onClick={() => setShow(true)}
          className="d-flex align-items-center mb-4 justify-content-between bg-white  p-4 rate-card"
        >
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center me-3">
              <div className="flex-shrink-0">
                <div className="name-al w-44 h-44 rounded-circle overflow-hidden color1">
                  <h6 className="text-white fs-16 fw-semibold">{props.item?.owners.length >0 && props.item?.owners[0].name?.charAt(0)}</h6>
                </div>
                {/*
                if user image to show
                <div className="user-img w-44 h-44 rounded-circle overflow-hidden color1 fw-100">
                  <img loading="lazy" src={userImg} alt="user" />
                </div> */}
              </div>
            </div>
            <div className="d-flex flex-column me-3">
              <h6 className="fs-10 fw-light text-dark text-uppercase">
                identificativo
              </h6>
              <h2 className="fs-16 fw-semibold text-dark text-capitalize">
              {props.item?.installmentName}
              </h2>
            </div>
            <div className="d-flex flex-column me-3">
              <h6 className="fs-10 fw-light text-dark text-uppercase">
                Data inizio competenza
              </h6>
              <h2 className="fs-16 fw-semibold text-dark text-uppercase">
              {props.item?.competenceStartDate?.substring(0,10)}
              </h2>
            </div>
            <div className="d-flex flex-column me-3">
              <h6 className="fs-10 fw-light text-dark text-uppercase">
                SCADENZA
              </h6>
              <h2 className="fs-16 fw-semibold text-dark text-uppercase">
              {props.item?.deadlineDate?.substring(0,10)}
              </h2>
            </div>
            <div className="d-flex flex-column me-3">
              <h6 className="fs-10 fw-light text-dark text-uppercase">
              IMPONIBILE
              </h6>
              <h2 className="fs-16 fw-semibold text-dark text-uppercase">
              € {props.item?.amount} 
              </h2>
            </div>
            <div className="d-flex flex-column me-3">
              <h6 className="fs-10 fw-light text-dark text-uppercase">
                IVA
              </h6>
              <h2 className="fs-16 fw-semibold text-dark text-uppercase">
              {Math.ceil((props.item?.vatCode ? parseFloat(props.item?.vatCode) : 1) * 100 - 100) === 0 ? "Esente" : Math.ceil((props.item?.vatCode ? parseFloat(props.item?.vatCode) : 1) * 100 - 100) + "%"}

              </h2>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="d-flex flex-column ms-3">
                  <h6 className="fs-10 fw-light text-dark text-uppercase">
                    Già versato
                  </h6>
                  <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                  € {props.item?.totalPaid}
                  </h2>
                </div>
                <div className="d-flex flex-column ms-3">
                  <h6 className={`fs-10 fw-light text-uppercase ${props.item?.totalDue >0 && isDue ?"text-danger" : "text-success"}`}>
                    Totale dovuto
                  </h6>
                  <h2 className={`fs-16 fw-semibold text-uppercase ${props.item?.totalDue >0 && isDue ?"text-danger" : "text-success"}`}>
                  € {props.item?.totalDue}
                  </h2>
                </div>
                <div className="d-flex flex-column ms-3">
                  <h6 className={`fs-10 fw-light text-uppercase ${classForDate}`}>
                    SALDO SCADUTO
                  </h6>
                  <h2 className={`fs-16 fw-semibold text-uppercase ${classForDate}`}>
                  € {today.getTime() > deadline.getTime() ? props.item?.totalDue : 0}
                  </h2>
                </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={999}>
        <div
          onClick={() => setShow(true)}
          className="d-flex justify-content-between align-items-center rel bg-white rounded-3 p-3 mb-2 rate-card"
        >
          <div className="d-inline-flex flex-column">
            <h6 className="fs-12 text-secondary fw-light mb-2">
              Da {props.item?.competenceStartDate?.substring(0,10)} al {props.item?.deadlineDate?.substring(0,10)}
            </h6>
            <h4 className="fs-16 text-dark fw-semibold mb-2 textcapitalize">{props.item?.installmentName}</h4>
            <div className="blue-text-box w-auto">
              {/* <h6 className="fs-12 fw-light w-auto float-start p-1 rounded-2">
              € {props.item?.amount * props.item?.vatCode} 
              </h6> */}
            </div>
          </div>
           <h5 className={`fs-24 fw-semibold ${classForDate}`}>  € {props.item?.totalDue} </h5>
        </div>
      </MediaQuery>
    </Link>

      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w rounded-0"
        aria-labelledby="example-custom-modal-styling-title"
        className="rate-half-box  rounded-0"
      >
        <Modal.Body className="px-5 pb-5 pt-0 rounded-0">
          <div className="d-block rel mb-5">
            <Button
              variant=""
              className="position-absolute top-0 end-0 zi-4 border-0"
              onClick={() => setShow(false)}
            >
              <i className="bi bi-x"></i>
            </Button>
          </div>

          <div className="list-broke-box d-block">
            <div className="row">
              <div className="col-md-6">
                <div className="d-block bg-white p-4 mb-4">
                  <h3 className="text-dark fs39 fw-semibold mb-5">Rata1</h3>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">DATE</h3>
                    </div>

                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        Data inizio competenza
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        1/07/2022
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        DATA FINE COMPETENZA
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        31/07/2022
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        SCADENZA
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        31/07/2022
                      </h2>
                    </div>
                  </div>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">IMPORTO</h3>
                    </div>

                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        importo
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        1000$
                      </h2>
                    </div>
                  </div>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">TOTALE</h3>
                    </div>

                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        DOVUTO
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        1000$
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-dark text-uppercase">
                        versato
                      </h6>
                      <h2 className="fs-16 fw-semibold text-dark text-uppercase">
                        0$
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-danger text-uppercase">
                        saldo
                      </h6>
                      <h2 className="fs-16 fw-semibold text-danger text-uppercase">
                        1000$
                      </h2>
                    </div>
                    <div className="d-flex flex-column me-3">
                      <h6 className="fs-10 fw-light text-danger text-uppercase">
                        saldo scaduto
                      </h6>
                      <h2 className="fs-16 fw-semibold text-danger text-uppercase">
                        1000$
                      </h2>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="rata-option-btn py-3 px-5 text-dark rounded-3 shadow border-0"
                >
                  Segna come pagato
                </button>
              </div>
              <div className="col-md-6">
                <div className="d-block bg-white p-4">
                  <div className="d-flex flex-row-reverse top-edit-print-chat-btn">
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40 monthRateU-btn"
                    >
                      <img loading="lazy" src={icon1} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40 monthRateU-btn"
                    >
                      <img loading="lazy" src={icon4} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40 monthRateU-btn"
                    >
                      <img loading="lazy" src={icon5} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40 monthRateU-btn"
                    >
                      <img loading="lazy" src={icon2} alt="icon-photo" />
                    </button>
                    <button
                      type="button"
                      className="rounded-3 p-1 bg-white ms-2 w-40 h-40 monthRateU-btn"
                    >
                      <img loading="lazy" src={icon3} alt="icon-photo" />
                    </button>
                  </div>
                  <div className="date-rata-impor my-5 d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">ALLEGATI</h3>
                    </div>
                    <div className="input-file-box rel">
                      <div className="d-flex flex-column text-center">
                        <h4 className="fs-16 mb-3 text-dark fw-semibold">
                          Nessun allegato
                        </h4>
                        <i
                          className="fal fa-plus w-54 h-54 text-center fs-24 rounded-circle mx-auto"
                          style={{ background: "#d0e1ef" }}
                        ></i>
                      </div>
                      <input type="file" name="" id="" className="file-box" />
                    </div>
                  </div>
                  <div className="date-rata-impor d-flex justify-content-between rounded-3 rel mb-4 pb-2 px-3 pt-4">
                    <div className="dri-heading bg-white p-2">
                      <h3 className="fs-20 text-dark fw-semibold">NOTE</h3>
                    </div>
                    <hr className="border-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
