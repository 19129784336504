import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "../components/Container";
import MidTabsE from "../components/Membri/Conduttori/edit_mode/MidTabsE";
import TopHeadPartE from "../components/Membri/Conduttori/edit_mode/TopHeadPartE";
import { BASE_URL, conductor_endpoint } from "../api/api";
import TopHeading from "../components/TopHeading";

export default function EditConduttori() {
  const location = useLocation();
  const [conductor, setConductor] = useState({});

  // TODO con api() da problemi con la chiamata API, da risolvere
  const fetchConductor = () => {
    axios
      .create({
        baseURL: BASE_URL,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user"))?.token
          }`,
          "Content-Type": `application/json`,
        },
      })
      .get(
        conductor_endpoint +
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      )
      .then((res) => {
        if (res.data) {
          setConductor({
            ...res.data,
            initialBankAccountsIDs: res.data.bankAccounts.map(
              (bankAccount) => bankAccount.id
            ),
          });
        }
      });
  };

  useEffect(() => {
    fetchConductor();
  }, []);

  return (
    <>
      <TopHeading SmallHeading="empty"/>
      <TopHeadPartE formData={conductor} setFormData={setConductor} fetchConductor={fetchConductor} />
      <MidTabsE formData={conductor} setFormData={setConductor} />
    </>
  );
}
