import React from "react";
import delete_icon from "../../assets/images/delete.svg";

import CustomBadge from "../CustomBadge";

export default function AddWarranty(props) {
  // const deleteWarranty = () => {
  //   let temp = props.warranty;
  //   temp.splice(props.id);
  //   props.setWarranty(temp);
  // };
  return (
    <div className="row mt-3">
      <div className="col-md-6">
        <div className="d-flex flex-column">
          <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
            Tipo di garanzia
          </label>
          <select className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white">
            {props.guarantee_type.map((menu, index) => {
              return (
                <option key={index} value={index}>
                  {menu}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="col-md-6">
        <div className="d-flex flex-row align-items-end">
          <div className="d-flex flex-column flex-auto">
            <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
              Importo
            </label>
            <input
              type="text"
              className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
            />
          </div>
          <div
            className="delete-icon-btn add-section-btn mb-1"
            onClick={() => {
              props.deleteWarranty(props.id);
            }}
          >
            <img loading="lazy" src={delete_icon} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
}
