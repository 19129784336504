import React, {useState} from "react";
import { BsDownload, BsTrash } from "react-icons/bs";
import api, { evaluation_endpoint } from "../../../api/api";
import { Modal } from "react-bootstrap";
import ShareChat from "../../ShareChat";
import { formattedTimestamp } from "../../../utils/dateManipulation";

const EvaluationCard = ({
	id,
	name,
	address,
	internal,
	cap,
	imgUrl,
	scale,
	deleteEvaluation,
	getEvaluation,
	handleSelection,
	selectedItems,
	cdat
}) => {
	const deleteCard = async () => {
		deleteEvaluation(id);
		try {
			const response = await api().delete(`${evaluation_endpoint}${id}`);
			// console.log("SSS", response.data);
		} catch (err) {
			console.log(err);
		}
	};

	//handle modal delete
	const [show, setShow] = useState(false);
	const handleClose = (e) => {
		setShow(false)
		e.preventDefault();
		e.stopPropagation();
	};
	const handleShow = () => setShow(true);

	//handle modal share
	const [showShare, setShowShare] = useState(false);
	const handleCloseShare = () => {
		setShowShare(false)
	};
	const handleShowShare = () => setShowShare(true);

	return (
		<>
			<div className="general-card mt-2 flex-column flex-md-row ms-0 align-items-start " >
				<div className="d-flex ">
				<input
						type="checkbox"
						checked={selectedItems.includes(id)}
						onChange={(e) => handleSelection(e, id)}
						className="me-2"
					/>
					<div className="state-img me-2">
						<img loading="lazy" src={imgUrl} alt="" />
					</div>
					<div className="d-flex flex-column flex-auto justify-content-center">
						<span className="general-card-title">{name ? name : "No name"}</span>
						<span className="small-span">
							{`${address} ,Interno ${internal}, Scala ${scale} CAP ${cap}`}
						</span>
						<small className="text-dark fs-10">Creato il {formattedTimestamp(cdat).split(" ")?.[0]}</small>

					</div>
				</div>
				<div className="d-flex align-items-center justify-content-start" onClick={getEvaluation} style={{pointer:"cursor"}}>
					<div className="d-flex flex-column align-items-lg-center  ">
						<div className="fund-btn mt-2 text-center">
							Valutazione Immobile
							<br />
							€ --.---
						</div>
					</div>
{/* 					<ShareChat elId={id} />
 */}					<div className="ms-2">
						<button
							type="button"
							className="btn round-icon-btn"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								handleShow();
							}}>
							<BsTrash />
						</button>
					</div>
				</div>
			</div>
			<Modal show={show} onHide={(e) => handleClose(e)} centered>
        <Modal.Body className="p-5">
          <div className="d-flex flex-column text-center">
            <h2 className="fs-32 text-dark fw-semibold mb-3">
              Vuoi eliminare questo elemento?
            </h2>
            <h6 className="fs-16 text-dark fw-normal mb-3">
              Questa azione è irreversibile
            </h6>
            <button
              type="button"
              className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
              onClick={(e) =>{ 
				e.preventDefault()
				e.stopPropagation()
				deleteCard()
				handleClose(e)
			}}
            >
              Conferma
            </button>
            <button
              type="button"
              className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
              onClick={(e) => handleClose(e)}
            >
              Annulla
            </button>
          </div>
        </Modal.Body>
      </Modal>
		</>
	);
};

export default EvaluationCard;
