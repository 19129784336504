import "bootstrap-icons/font/bootstrap-icons.css";
import React, {useState} from "react";
import { flushSync } from 'react-dom';

import { Outlet, Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import "./assets/css/font.css";
import VerifyCode from "./components/auth/login/VerifyCode";
import CreateNewPassword from "./components/auth/reset_password/CreateNewPassword";
import RecoverPassword from "./components/auth/reset_password/RecoverPassword";
import Home from "./components/home";
import Calcoli from "./pages/Calcoli";
import Calculator from "./pages/Calculator";
import EditPropritari from "./pages/EditPropritari";
import Modelli from "./pages/Modelli";
import PrimaNota from "./pages/PrimaNota";
import Proprietari from "./pages/Proprietari";
import CreateProprietari from "./components/Membri/ProprietariSublocatori/create_mode/TabForm";
import ViewPropritari from "./pages/ViewPropritari";

import Conduttori from "./pages/Conduttori";
import CreateConduttori from "./components/Membri/Conduttori/create_mode/TabForm";
import EditConduttori from "./pages/EditConduttori";
import ViewConduttori from "./pages/ViewConduttori";

import EditFornitori from "./pages/EditFornitori";
import Fornitori from "./pages/Fornitori";
import CreateFornitor from "./components/Membri/Fornitori/create_mode/TabForm";
import ViewFornitori from "./pages/ViewFornitori";

import CreateAffitti from "./components/Affitti/CreateAffitti";
import EditAffitti from "./components/Affitti/EditAffitti";
import ModificationDocument from "./components/Documenti/prima_nota/ModificationDocument";
import InnerDetailsM from "./components/Membri/Rate/Members/InnerDetailsM";
import InnerDetailsU from "./components/Membri/Rate/Unit/InnerDetailsU";
import CreateStabili from "./components/UnitàImmobiliariStabilli/Stabili/CreateStabili";
import EditStabili from "./components/UnitàImmobiliariStabilli/Stabili/EditStabili";
import CreateRealEstateUnit from "./components/UnitàImmobiliariStabilli/UnitàImmobiliari/CreateRealEstateUnit";
import EditRealEstateUnit from "./components/UnitàImmobiliariStabilli/UnitàImmobiliari/EditRealEstateUnit";
import CreateEvaluation from "./components/UnitàImmobiliariStabilli/ValutazioneImmobilire/CreateEvaluation";
import EditEvaluation from "./components/UnitàImmobiliariStabilli/ValutazioneImmobilire/EditEvaluation";
import Register from "./components/auth/register/Register.js";
import RegisterSuccess from "./components/auth/register/RegisterSuccess";
import Affitti from "./pages/Affitti";
import Dashboard from "./pages/Dashboarod";
import MembersRata from "./pages/MembersRata";
import Rate from "./pages/Rate";
import Stabili from "./pages/Stabili";
import UnitRata from "./pages/UnitRata";
import UnitàImmobiliari from "./pages/UnitàImmobiliari";
import ValutazioneImmobilire from "./pages/ValutazioneImmobilire";
// import RegisterContainer from "./components/Autenticazione/registrazione/register-containter.components";
// import LoginContainer from "./components/Autenticazione/login/login-container.component";
import { Provider } from "react-redux";
import Container from "./components/Container";
import Attività from "./components/StatoLavoriInCorso/Attività/Attività";
import NuovaAttività from "./components/StatoLavoriInCorso/Attività/NuovaAttività";
import Bacheca from "./components/StatoLavoriInCorso/Bacheca/Bacheca";
import Gestioni from "./components/StatoLavoriInCorso/Gestioni/Gestioni";
import Unità from "./components/StatoLavoriInCorso/Unità/Unità";
import store from "./store";

import AttivitàDetails from "./components/StatoLavoriInCorso/Attività/AttivitàDetails";
import EditAttività from "./components/StatoLavoriInCorso/Attività/EditAttività";
import Chat from "./components/StatoLavoriInCorso/Chat/Chat";
import RegisterShort from "./components/auth/register/RegisterShort";

import AlertCalendario from "./components/Alert/AlertCalendario";
import EventoDetails from "./components/Alert/EventoDetails";
import NuovoEvento from "./components/Alert/NuovoEvento";
import Scadenzario from "./components/Alert/Scadenzario";
import { Chatdetails } from "./components/StatoLavoriInCorso/Chat/Chatdetails";
import Checklist from "./components/StatoLavoriInCorso/Impostazioni chat/Checklist/Checklist";
import NuovaChecklist from "./components/StatoLavoriInCorso/Impostazioni chat/Checklist/NuovaChecklist";
import Contabilità from "./components/StatoLavoriInCorso/Impostazioni chat/Contabilita/Contabilità";
import ImpostazioniChat from "./components/StatoLavoriInCorso/Impostazioni chat/ImpostazioniChat";
import Squadre from "./components/StatoLavoriInCorso/Impostazioni chat/Squadre/Squadre";
import Tag from "./components/StatoLavoriInCorso/Impostazioni chat/Tag/Tag";

import { useMediaQuery } from "react-responsive";
import PrivateRoute from "./PrivateRoute";
import FileEsportati from "./components/StatoLavoriInCorso/File_esportati/FileEsportati";
import GestioneUnitActivity from "./components/StatoLavoriInCorso/Gestioni/GestioneUnitActivity";
import SingleGestione from "./components/StatoLavoriInCorso/Gestioni/SingleGestione";
import Login from "./components/auth/login/Login";
import EditChecklist from "./components/StatoLavoriInCorso/Impostazioni chat/Checklist/EditChecklist";
import ContainerSlc from "./components/ContainerSlc";
import Rendiconto from "./pages/Rendiconto";
import CreateReport from "./components/AmministrazioneFinanziaria/Rendiconto/CreateReportSection";
import EditReport from "./components/AmministrazioneFinanziaria/Rendiconto/EditReport";
import Assemblee from "./pages/Assemblee";
import CreateAssembly from "./components/Assemblee/CreateAssembly";
import EditAssembly from "./components/Assemblee/EditAssembly";

import ModelliInvia from "./components/Documenti/Modelli/ModelliInvia"
import ModelliStorico from "./components/Documenti/Modelli/ModelliStorico"
import EditModello from "./components/Documenti/Modelli/EditModello";

import Impostazioni from "./pages/Impostazioni";
import Profiling from "./components/Membri/Rate/Profiling";
import ViewRata from "./components/Membri/Rate/ViewRata";
import { useEffect } from "react";
import { token, user} from "./api/api";

import InnerDetailsOtherMembers from "./components/Membri/Rate/Altro/InnerDetailsOtherMembers";
import InnerDetailsOtherUnits from "./components/Membri/Rate/Altro/InnerDetailsOtherUnits";

import RisparmioOggettivo from "./pages/RisparmioOggettivo";
import TrovaFornitori from "./pages/Partners";
import PartnerList from "./components/Partners/PartnerList";
import FornitoriPage from "./components/Partners/FornitorePage";

import HomeRM from "./components/Home RM/Home";
//import AnthillHome from "./components/Home RM/Anthill";
//import AnthillCompetence from "./components/Home RM/AnthillCompetence";
//import AnthillProjects from "./components/Home RM/AnthillProjects";
import Features from "./components/Home RM/Home copy";
import Fornitore from "./components/Home RM/Fornitore";
import Proprietario from "./components/Home RM/Proprietario";
import Contatti from "./components/Home RM/Contatti"
import Suggerimenti from "./components/Home RM/Suggerimenti";
import Landing from "./components/Home RM/Landing";
import Pacchetti from "./components/Home RM/PacchettiRm";
//import AnthillDigit from "./components/Home RM/AnthillDigit";
//import AnthillGest from "./components/Home RM/AnthillGest";
import Role from "./components/Home RM/Role";
import { PartnersProvider } from "./context/partners";

import NuovaNota from "./components/Documenti/prima_nota/NuovaNota.js";

import Cookies from "./components/Home RM/Cookie";

import ReactGA from "react-ga4";

import {loadConnectAndInitialize} from '@stripe/connect-js';

import {
	ConnectPayments,
	ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import {loadStripe} from '@stripe/stripe-js';
import ConfirmationPageAbbonamento from "./components/StatoLavoriInCorso/ConfirmationPageAbbonamento.js";
import ConfirmationPageAcquisti from "./components/StatoLavoriInCorso/ConfirmationPageAcquisti.js";
import RefreshAccount from "./components/StatoLavoriInCorso/RefreshAccount.js";
import ReturnAccount from "./components/StatoLavoriInCorso/ReturnAccount.js";

import Supporto from "./pages/Supporto.js";

import SupportoUnità from "./components/Supporto/Unità/Unita.js";
import SupportoAccesso from "./components/Supporto/Accesso/Accesso.js";
import SupportoPassword from "./components/Supporto/Password/Password.js";
import SupportoStruttura from "./components/Supporto/Struttura/Struttura.js";
import SupportoDashboard from "./components/Supporto/Dashboard/Dashboard.js";
import SupportoAffitti from "./components/Supporto/Affitti/Affitti.js";
import SupportoValutazione from "./components/Supporto/Valutazione/Valutazione.js"
import SupportoNota from "./components/Supporto/PrimaNota/PrimaNota.js";
import SupportoRate from "./components/Supporto/Rate/Rate.js";
import SupportoSal from "./components/Supporto/SAL/Sal.js";
import SupportoChat from "./components/Supporto/Chat/Chat.js";
import SupportoRendiconto from "./components/Supporto/Rendiconto/Rendiconto.js";
import SupportoCalcoli from "./components/Supporto/Calcoli/Calcoli.js";
import SupportoAlert from "./components/Supporto/Alert/Alert.js";

import { checkUserRole, checkSupplier, checkUserData } from "./utils/checkUserRole.js";

import { BASE_URL} from "./api/api";
import InnerDetailsG from "./components/Membri/Rate/Gestione/InnerDetailsG.js";

import api, { user_endpoint } from "./api/api";
import Servizi from "./components/Home RM/Servizi.js";
import InviteLanding from "./components/auth/invitation/InviteLanding.js";
import InviteSuccess from "./components/auth/invitation/InviteSuccess.js";
import Tech from "./components/Home RM/Tech.js";
import Attori from "./components/Home RM/Attori.js";
import Notifiche from "./pages/Notifiche.js";

import WebSocketComponent from "./components/StatoLavoriInCorso/Chat/Websocket.js";

//DIPENDENZE PER WEBSOCKET
//import Stomp from 'stompjs';
//import SockJS from 'sockjs-client';

import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/Icon";

import ButtonRm from "./components/ButtonRm.js";
import TrovaGestore from "./components/Home RM/TrovaGestore.js";

import { WebSocketProvider } from "./utils/websocketContext.js";

ReactGA.initialize("G-TNELYR5EB3");

const stripePromise = loadStripe('pk_test_51OhUnVGHFxsJG3MhHK3fieznBZaXAz4eWf98VCp3p0DHdZxa2Rx3ocCxIkHwATgfmeAVsARXlmA9SDHOB8iKNUTG00ycysJFyM');


// Oggetto di mappatura per percorsi e sezioni
const pathSectionMap = {
  'dashboard': 'dashboard',
  'calcoli': 'calcoli',
  'Calculator': 'calcoli',
  'prima-nota': 'prima-nota',
  'modelli': 'modelli',
  'proprietari-view': 'proprietari',
  'proprietari': 'proprietari',
  'fornitori': 'fornitori',
  'conduttori': 'conduttori',
  'affitti': 'affitti',
  'alert': 'alert',
  'impostazioni': 'impostazioni',
  'supporto': 'supporto',
  'real-estate-units': 'unità',
  'stabili': 'stabili',
  'evaluation': 'valutazione',
  'report': 'report',
  'assembly': 'assemblea',
  'rate': 'rate',
  'unit-details': 'rate',
  'members-details': 'rate',
  'management-details': 'rate',
  'other-rate-members': 'rate',
  'other-rate-units': 'rate',
  'proprietari-edit': 'proprietari',
  'proprietari-view': 'proprietari',
  'conduttori-edit': 'conduttori',
  'fornitori-edit': 'fornitori',
  'conduttori-view': 'conduttori',
  'fornitori-view': 'fornitori',
  'notifiche': 'notifiche',
  'statoavanzamentolavori/bacheca': 'bacheca',
  'statoavanzamentolavori/supporto': 'supporto',
  'statoavanzamentolavori/gestioni': 'gestioni',
  'statoavanzamentolavori/unitaestabili': 'unitaestabili',
  'statoavanzamentolavori/attivita': 'attivita',
  'statoavanzamentolavori/impostazionichat': 'impostazioni',
  'statoavanzamentolavori/trova-fornitore': 'trova-fornitore',
  'statoavanzamentolavori/impostazioni': 'impostazioni'

};

const excludedPaths = [
	'/recover',
	'/verify',
	'/newPassword',
	'/extendedRegister',
	'/chooseRole',
	'/reducedRegister',
	'/registerSuccess',
	'/conferma-collegamento',
	'/invito-confermato',
	'/login',
	'/risparmio-oggettivo',
	'/home',
	'/features',
	'/fornitore',
	'/contatti',
	'/servizi',
	'/trova-gestore',
	'/landing',
	'/tech',
	'/attori',
	'/pacchetto',
	'/proprietario',
	'/suggerimenti',
	'/conferma-pagamento',
	'/conferma-abbonamento',
	'/refresh-account',
	'/return-account',
	'/cookie-policy'
  ];

  

const App = () => {
	//GESTIONE WEB SOCKET
	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState('');
	const [nickname, setNickname] = useState('');
	const [stompClient, setStompClient] = useState(null);
	const [clientWebSocket, setClientWebSocket] = useState(null);

	const location = useLocation();

	function waitForElm(selector) {
		console.log("called waitforelm", selector, document.querySelector(selector))

		return new Promise(resolve => {
			const checkElement = () => {
				const element = document.querySelector(selector);
				if (element && element.textContent.trim() !== '') {
					resolve(element);
				} else {
					requestAnimationFrame(checkElement);
				}
			};
			checkElement();
		});
	}
	
	useEffect(() => {

		const handleLocationChange = () => {
				const path = location.pathname + location.search;
				let title = "";

				console.log("changed path", path)

			
			waitForElm('h2').then(elm => {
				title = elm.textContent.trim();
				console.log("elm", elm)
				// Verifica se il percorso attuale è nella lista di esclusioni
				if (excludedPaths.some(excludedPath => path.startsWith(excludedPath))) {
					return;
				} 
	
				// Trova la sezione corrispondente al percorso attuale
				const currentSection = Object.keys(pathSectionMap).find(key => path.startsWith(`/${key}`));
	
				if (!currentSection) {
					return; // Percorso escluso o non mappato
				}
	
				let pageViews = JSON.parse(localStorage.getItem('tabs')) || [];

				console.log("Titolo trovato:", title); // Dovrebbe stampare "Alio Ciao"

	
				if (!pageViews.some(entry => entry.path === path)) {
					if (pageViews.length >= 30) {
						alert('Non puoi aggiungere più di 30 schede. Rimuovi alcune schede per poterne aggiungere di nuove.');
					} else {
						pageViews.push({ path, title, currentSection: pathSectionMap[currentSection] });
						localStorage.setItem('tabs', JSON.stringify(pageViews));
					}
				}
			});
		};
	
		handleLocationChange();
		
	}, [location]); 
	


	const isDesktop = useMediaQuery({
		query: "(min-width: 992px)",
	});

	const AppLayout = () => (
		<Container>
			<Outlet />
		</Container>
	);

	const SlcLayout = () => (
		<ContainerSlc>
			<Outlet />
		</ContainerSlc>
	);

	var userData = JSON.parse(localStorage.getItem("user"));


	//STRIPE
	// We use `useState` to ensure the Connect instance is only initialized once
	const [stripeConnectInstance] = useState(() => {
		
		const fetchClientSecret = async () => {
			// Fetch the AccountSession client secret
			const response = await fetch(`${BASE_URL}api/v1/payment/account`, 
			{ 	method: "POST" ,  
				headers: {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${token}`
			 	}
			});
			if (!response.ok) {
			  return undefined;
			} else {
				const responseData = await response.json(); // Attendere che la risposta sia risolta
				const clientSecret = responseData.client_secret;
				return clientSecret;
			}
		  }

	console.log(fetchClientSecret(), "client_Sec")
	
		return loadConnectAndInitialize({
		  // This is your test publishable API key.
		  publishableKey: "pk_test_51OYkWoGIy0lE619XNFRvHIiwKBkhE1jm481zovf6ird0RJMvy729yjwAARTGcdFPTQc1BSoAOu0f8AYJgb0zjFxu00BttmKT7Y",
		  fetchClientSecret: fetchClientSecret,
		  fonts: [
			{
			  cssSrc: "https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap",
			}
		  ],
		  appearance: {
			// See all possible variables below
			overlays: "dialog",
			variables: {
			  colorPrimary: "#83112f",
			  buttonPrimaryColorBackground: "#83112f"

			},
		  },
		})
	});


	//GET USER DATA

	const [data, setData] = useState(null)

    async function fetchData() {
        try {
            const response = await api().get(`${user_endpoint}`);
            if (response.data) {
                setData(response.data?.content[0]);

                console.log("response.data.content", response.data?.content[0])
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
            fetchData()
    }, [])

	return (
		<Provider store={store}>
			<ConnectComponentsProvider connectInstance={stripeConnectInstance}>
	{/* 		<WebSocketProvider>
				<WebSocketComponent chatId={81} userData={userData} />
			</WebSocketProvider> */}

				<Routes>
				{/* 	<NavTabTracker /> */}
					<Route path="/recover" element={<RecoverPassword />} />
					<Route path="/verify" element={<VerifyCode />} />
					<Route path="/newPassword" element={<CreateNewPassword />} />
					<Route path="/extendedRegister" element={<Register />} />
					<Route path="/chooseRole" element={<Role />} />
					<Route path="/reducedRegister" element={<RegisterShort />} />
					<Route path="/registerSuccess" element={<RegisterSuccess />} />
					<Route path="/conferma-collegamento" element={<InviteLanding />} />
					<Route path="/invito-confermato" element={<InviteSuccess />} />


					<Route path="/:mode" element={<App />} />
					<Route path="/login" element={<Login />} />

					{/* TOOL RISPARMIO OGGETTIVO */}
					<Route path="/risparmio-oggettivo" element={<RisparmioOggettivo />} />

					<Route path="/home" element={<HomeRM />} />
					<Route path="/features" element={<Features />} />
					<Route path="/fornitore" element={<Fornitore />} />
					<Route path="/contatti" element={<Contatti/>} />
					<Route path="/servizi" element={<Servizi/>} />
					<Route path="/trova-gestore" element={<TrovaGestore/>} />


/* 					<Route path="/landing" element={<Landing />} />
 */					<Route path="/tech" element={<Tech />} />
					<Route path="/attori" element={<Attori />} />
					<Route path="/pacchetto" element={<Pacchetti />} />
					<Route path="/proprietario" element={<Proprietario />} />
					<Route path="/suggerimenti" element={<Suggerimenti/>} />
					<Route path="/conferma-pagamento" element={<ConfirmationPageAcquisti/>} />
					<Route path="/conferma-abbonamento" element={<ConfirmationPageAbbonamento/>} />
					<Route path="/refresh-account" element={<RefreshAccount/>} />
					<Route path="/return-account" element={<ReturnAccount/>} />

					<Route path="/cookie-policy" element={<Cookies/>} />





					{/* <Route path="/anthill" element={<AnthillHome />} />
					<Route path="/anthill-competence" element={<AnthillCompetence />} />
					<Route path="/anthill-projects" element={<AnthillProjects />} />
					<Route path="/anthill-digit" element={<AnthillDigit />} />
					<Route path="/anthill-gest" element={<AnthillGest />} /> */}


					<Route path="/" element={<PrivateRoute />}> 
						<Route index element={<Home />} />
						

						{/* SLC LAYOUT */}
						<Route path="/" element={<AppLayout />}>
						<Route path="dashboard" element={checkUserRole() ? <Dashboard /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="/dashboard" element={checkUserRole() ? <Dashboard /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="calcoli" element={checkUserRole() ? <Calcoli /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="Calculator" element={checkUserRole() ? <Calculator />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="prima-nota" element={checkUserRole() ? <PrimaNota /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="prima-nota/nuova-nota" element={checkUserRole() ? <NuovaNota /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="modelli" element={checkUserRole() ? <Modelli /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="modelli/invia" element={checkUserRole() ? <ModelliInvia /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="modelli/storico" element={checkUserRole() ? <ModelliStorico/> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="modelli/edit" element={checkUserRole() ? <EditModello/> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="prima-nota/:id" element={checkUserRole() ? <ModificationDocument /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route
								path="proprietari-view/view/:id"
								element={checkUserRole() ? <ViewPropritari />  : <Navigate to="/statoavanzamentolavori/bacheca" />}
							/>
							<Route path="proprietari/create" element={checkUserRole() ? <CreateProprietari /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="fornitori/create" element={checkUserRole() ? <CreateFornitor /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="conduttori/create" element={checkUserRole() ? <CreateConduttori /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="affitti" element={checkUserRole() ? <Affitti /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="affitti/create" element={checkUserRole() ? <CreateAffitti /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="affitti/edit/:id" element={checkUserRole() ? <EditAffitti /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="alert/calendario" element={checkUserRole() ? <AlertCalendario /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="alert/scadenzario" element={checkUserRole() ? <Scadenzario /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="alert/nuovo-evento" element={checkUserRole() ? <NuovoEvento /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="alert/scadenzario/:id" element={checkUserRole() ? <EventoDetails /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="impostazioni" element={checkUserRole() ? <Impostazioni stripeConnectInstance={stripeConnectInstance} /> : <Navigate to="/statoavanzamentolavori/bacheca" />}/>

							<Route path="supporto" element={checkUserRole() ? <Supporto /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="supporto/unità" element={ <SupportoUnità/>} />
							<Route path="supporto/accesso" element={ <SupportoAccesso/>} />
							<Route path="supporto/password" element={ <SupportoPassword/>} />
							<Route path="supporto/struttura" element={<SupportoStruttura/>} />
							<Route path="supporto/dashboard" element={<SupportoDashboard/>} />
							<Route path="supporto/affitti" element={<SupportoAffitti/>} />
							<Route path="supporto/valutazione" element={<SupportoValutazione />} />
							<Route path="supporto/prima nota" element={<SupportoNota />} />
							<Route path="supporto/rate" element={<SupportoRate />} />
							<Route path="supporto/sal" element={<SupportoSal />} />
							<Route path="supporto/chat" element={<SupportoChat />} />
							<Route path="supporto/rendiconto" element={<SupportoRendiconto />} />
							<Route path="supporto/calcoli" element={<SupportoCalcoli />} />
							<Route path="supporto/alert" element={<SupportoAlert />} />

							<Route path="real-estate-units" element={checkUserRole() ? <UnitàImmobiliari />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route
								path="real-estate-units/create"
								element={checkUserRole() ? <CreateRealEstateUnit />  : <Navigate to="/statoavanzamentolavori/bacheca" />}
							/>
							<Route
								path="real-estate-units/edit/:id"
								element={checkUserRole() ? <EditRealEstateUnit />  : <Navigate to="/statoavanzamentolavori/bacheca" />} 
							/>
							<Route path="stabili" element={checkUserRole() ? <Stabili />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="stabili/create" element={checkUserRole() ? <CreateStabili />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="stabili/edit/:id" element={checkUserRole() ? <EditStabili />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="evaluation" element={checkUserRole() ? <ValutazioneImmobilire />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="evaluation/create" element={checkUserRole() ? <CreateEvaluation />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="evaluation/edit/:id" element={checkUserRole() ? <EditEvaluation />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="report" element={checkUserRole() ? <Rendiconto />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="report/create" element={checkUserRole() ? <CreateReport />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="report/edit/:id" element={checkUserRole() ? <EditReport />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="assembly" element={checkUserRole() ? <Assemblee />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="assembly/create" element={checkUserRole() ? <CreateAssembly />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="assembly/edit/:id" element={checkUserRole() ? <EditAssembly />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="proprietari" element={checkUserRole() ? <Proprietari />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="rate" element={checkUserRole() ? <Rate />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
	
							<Route path="unit-details/:unitId" element={checkUserRole() ? <InnerDetailsU />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="members-details/:memberId" element={checkUserRole() ? <InnerDetailsM />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="management-details/:gestioneId" element={checkUserRole() ? <InnerDetailsG />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="other-rate-members" element={checkUserRole() ? <InnerDetailsOtherMembers/>  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="other-rate-units" element={checkUserRole() ? <InnerDetailsOtherUnits/>  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="rate/create" element={checkUserRole() ? <Profiling />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="rate/:rataId" element={checkUserRole() ? <ViewRata />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="proprietari-edit/:id" element={checkUserRole() ? <EditPropritari />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="conduttori" element={checkUserRole() ? <Conduttori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="conduttori-view/view/:id" element={checkUserRole() ? <ViewConduttori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="conduttori-edit/:id" element={checkUserRole() ? <EditConduttori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="fornitori" element={checkUserRole() ? <Fornitori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="fornitori-view/view/:id" element={checkUserRole() ? <ViewFornitori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="fornitori-edit/:id" element={checkUserRole() ? <EditFornitori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="conduttori-view" element={checkUserRole() ? <ViewConduttori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							
							<Route path="fornitori" element={checkUserRole() ? <Fornitori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route path="fornitori-view" element={checkUserRole() ? <ViewFornitori />  : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							<Route
								path="/alert/notifiche"
								element={<Notifiche />}
							/>

						</Route>
						
						<Route path="/" element={<SlcLayout />}>
							<Route
								path="statoavanzamentolavori/bacheca"
								element={<Bacheca />}
							/>
							<Route path="statoavanzamentolavori/supporto" element={ <Supporto />} />

						
							<Route path="statoavanzamentolavori/gestioni" element={checkUserRole() ? <Gestioni /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="statoavanzamentolavori/gestioni/:gestioneId" element={checkUserRole() ? <SingleGestione /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							<Route path="statoavanzamentolavori/gestioni/:gestioneId/:unitId" element={checkUserRole() ? <GestioneUnitActivity /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />
							
							<Route
								path="statoavanzamentolavori/unitaestabili"
								element={<Unità />}
							/>

							<Route
								path="statoavanzamentolavori/unitaestabili/:unitId/:activityId"
								element={<AttivitàDetails />}
							/>
							<Route
								path="statoavanzamentolavori/unitaestabili/:unitId"
								element={<GestioneUnitActivity />}
							/>
							
							<Route
								path="statoavanzamentolavori/unitaestabili/:unitId/nuova-attivita"
								element={<NuovaAttività />}
							/>
							<Route
								path="statoavanzamentolavori/gestioni/:gestioneId/:unitId/nuova-attivita"
								element={<NuovaAttività />}
							/>
							<Route
								path="statoavanzamentolavori/gestioni/:gestioneId/:unitId/:activityId"
								element={<AttivitàDetails />}
							/>

							{isDesktop ? (
								<Route path="statoavanzamentolavori/chat/*" element={<Chat userData={userData} />}>
									{/* <Route path=":chatId" element={<Chatdetails/>} /> */}
								</Route>
							) : (
								<>
									<Route path="statoavanzamentolavori/chat" element={<Chat userData={userData} />} />
									<Route
										path="statoavanzamentolavori/chat/:chatId"
										element={<Chatdetails stripePromise={stripePromise} userData={userData}/>}
									/>
								</>
							)}

							<Route
								path="statoavanzamentolavori/attivita"
								element={<Attività />}
							/>
							<Route
								path="statoavanzamentolavori/attivita/:activityId"
								element={<AttivitàDetails />}
							/>
							<Route
								path="statoavanzamentolavori/attivita/nuova-attivita"
								element={<NuovaAttività />}
							/>
							<Route
								path="statoavanzamentolavori/attivita/:activityId/edit"
								element={<EditAttività />}
							/>
							<Route
								path="statoavanzamentolavori/impostazionichat"
								element={<ImpostazioniChat />}
							/>
							<Route
								path="statoavanzamentolavori/impostazionichat/tag"
								element={<Tag />}
							/>
							<Route
								path="statoavanzamentolavori/impostazionichat/checklist"
								element={<Checklist />}
							/>
							<Route
								path="statoavanzamentolavori/impostazionichat/checklist/nuovo"
								element={<NuovaChecklist />}
							/>
							<Route
								path="statoavanzamentolavori/impostazionichat/checklist/edit/:editId"
								element={<EditChecklist />}
							/>
							<Route
								path="statoavanzamentolavori/impostazionichat/contabilita"
								element={<Contabilità />}
							/>
							
							<Route path="statoavanzamentolavori/impostazionichat/squadre" element={checkUserRole() ? <Squadre /> : <Navigate to="/statoavanzamentolavori/bacheca" />} />

							{ (!checkSupplier()) &&
								<Route path="/statoavanzamentolavori/trova-fornitore" element={
									<PartnersProvider>
										<TrovaFornitori />
									</PartnersProvider>
								} />
							}

							{ (!checkSupplier()) &&
								<Route path="/statoavanzamentolavori/trova-fornitore/lista" element={
									<PartnersProvider>
										<PartnerList />
									</PartnersProvider>
								} />
							}

							<Route
								path="/statoavanzamentolavori/trova-fornitore/lista/:pageId"
								element={<FornitoriPage />}
							/>
							<Route path="/statoavanzamentolavori/impostazioni" element={<Impostazioni stripeConnectInstance={stripeConnectInstance} />}/>
							<Route path="/statoavanzamentolavori/supporto/unità" element={ <SupportoUnità/>} />
								<Route path="/statoavanzamentolavori/supporto/accesso" element={ <SupportoAccesso/>} />
								<Route path="/statoavanzamentolavori/supporto/password" element={ <SupportoPassword/>} />
								<Route path="/statoavanzamentolavori/supporto/struttura" element={<SupportoStruttura/>} />
								<Route path="/statoavanzamentolavori/supporto/dashboard" element={<SupportoDashboard/>} />
								<Route path="/statoavanzamentolavori/supporto/affitti" element={<SupportoAffitti/>} />
								<Route path="/statoavanzamentolavori/supporto/valutazione" element={<SupportoValutazione />} />
								<Route path="/statoavanzamentolavori/supporto/prima nota" element={<SupportoNota />} />
								<Route path="/statoavanzamentolavori/supporto/rate" element={<SupportoRate />} />
								<Route path="/statoavanzamentolavori/supporto/sal" element={<SupportoSal />} />
								<Route path="/statoavanzamentolavori/supporto/chat" element={<SupportoChat />} />
								<Route path="/statoavanzamentolavori/supporto/rendiconto" element={<SupportoRendiconto />} />
								<Route path="/statoavanzamentolavori/supporto/calcoli" element={<SupportoCalcoli />} />
								<Route path="/statoavanzamentolavori/supporto/alert" element={<SupportoAlert />} />	
						</Route>

						
						
							
						
					</Route> 
				</Routes>
			</ConnectComponentsProvider>
		</Provider>
	);
};

export default App;
