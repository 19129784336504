import { useState, useRef, useMemo, useCallback, useEffect } from "react";

import api, { activity_endpoint } from "../../../api/api";

import { Row, Col, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import { Outlet } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import TopHeading from "../../TopHeading";
import AddBtn from "../../AddBtn";
import SearchInput from "../../SearchInput";
import EmptyStateContainer from "../../EmptyStateContainer";

import EmptyState from "../../../assets/images/attività-empty-state.svg";
import SearchIllustration from "../../../assets/images/search-illustration.svg";

import { checkUserRole } from "../../../utils/checkUserRole";

import {
  AttivitàCard,
  AttivitàCardMap,
  AttivitàCardMapMobile,
} from "./AttivitàCard";
import attività from "./data";

import L from "leaflet";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  ImageOverlay,
  Rectangle,
} from "react-leaflet";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate } from "react-router-dom";

import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { IconTerminal } from "@tabler/icons";

import { sortStringByKey, sortDatesByKey } from "../../../utils/filtering";

import MapViewActivity from "../Gestioni/MapViewActivity";
import ListViewActivity from "../Gestioni/ListViewActivity";
import GanttViewActivty from "../Gestioni/GanttViewActivity";

import RiassuntoContabilita from "../Chat/RiassuntoContabilita";
import RiassuntoContabilitaUnità from "../Chat/RiassuntoContabilitàUnità";

import { parseActivities } from "../contabilità-logic";

import { checkUserData } from "../../../utils/checkUserRole.js";
import ModalCheckUser from "../../ModalCheckUser.js";

const loadImage = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions([img.height, img.width]);
  };
  img.onerror = (err) => {
    console.log("img error");
    console.error(err);
  };
};

export default function Attività() {
  const [attività, setAttività] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewType, setViewType] = useState("list");

  const [selectedList, setSelectedList] = useState([]) //lista checkbox selezionati
 
  const [selectItem, setSelectedItem] = useState("");

  const [planimetry, setPlanimetry] = useState([]);
  const [imageDimensions, setImageDimensions] = useState(null);

  const activitySelected = attività?.find((item) => item.id===selectItem);

  const planimetrySelected = activitySelected
    ? activitySelected.planimetries.find((item) => item.id===planimetry.id)
    : "no item seelcted";

  const currentPlanimetryImage = planimetry?.image;

 /*  const selectedList =
    attività && attività.length > 0
      ? attività.filter((item) => item.checked)
      : []; */

  const searchedList =
    attività && attività.length > 0
      ? attività.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      : [];

  const inputRef = useRef([]);


  //myjson.members =  JSON.parse(myjson.members.replace(/'/g, "\""))
  //myjson.planimetries =  JSON.parse(myjson.planimetries.replace(/'/g, "\""))

  const navigate = useNavigate();

  let todayDate = new Date().toISOString().slice(0, 10);

  //add "checked" key to all the items of "attività" list
  function addCheckedKey(data) {
    const checkedArray = data?.map((item) => {
      return { ...item, checked: false };
    });
    return checkedArray;
  }

  const rectangles = {
    ...planimetrySelected?.markers,
    features: planimetrySelected?.markers?.features
      ? planimetrySelected?.markers?.features.filter(
          (item) => item.geometry.type==="Polygon"
        )
      : [],
  };

  const points = {
    ...planimetrySelected?.markers,
    features: planimetrySelected?.markers?.features
      ? planimetrySelected?.markers.features.filter(
          (item) => item.geometry.type==="Point"
        )
      : [],
  };

  //handle checked items
  function handleCheckbox(id) {
    const newAttività =
      attività?.length > 0 &&
      attività.map((item) => {
        return item.id===id ? { ...item, checked: !item.checked } : item;
      });
    setAttività(newAttività);
  }

  //GET
  async function getAll() {
    try {
      const response = await api().get(`${activity_endpoint}`);
      if (response.data.content) {
        const data = response.data.content.map((item) => {
          return {
            ...item,
              planimetries:item.planimetries.length > 0 ?
              item.planimetries.map(str => {
                const doubleQuotesString = str.replace(/'/g, '"');
                return JSON.parse(doubleQuotesString);
              }):
              []
          };
        });

        setSelectedItem(data?.[0]?.id);
        setPlanimetry(data?.[0]?.planimetries?.[0]);

        setAttività(addCheckedKey(data));
        console.log('planimetry', data?.[0]?.planimetries?.[0], data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAll();
  }, []);

  function createActivity() {
    navigate("/statoavanzamentolavori/attivita/nuova-attivita");
  }

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });


  const assigneeList = attività.length > 0 ?
  attività.map(el => {
    return el.assignee
  }).filter(item => item != "" ) : []
  
  let assigneeListWithoutDuplicate = [...new Set(assigneeList)];

  const unitList = attività.length > 0 ?
  attività.map(el => {
    return el.unitName
  }).filter(item => item != "" ) : []

  let unitListWithoutDuplicate = [...new Set(unitList)];

  console.log(unitList, unitListWithoutDuplicate)

  //Appena il componente render, imposta la planimetria di default come la prima planimetria della prima attività tra quelle dei dati che arrivano
  //imposta anche la prima attività come quella di default

  //when user select a new planimetry, change the state Planimetry accordingly
  const handlePlanimetry = (event) => {
    const { value } = event.target;
    const newPlan = activitySelected?.planimetries.find(
      (item) => item.image===value
    );
    setPlanimetry(newPlan);
  };
  //check image dimensions for bounds
 /*  useEffect(() => {
    if (planimetry?.image) {
      loadImage(setImageDimensions, planimetry.image);
    }
  }, [planimetry]); */

  //on click of a cardmap, make the clicked item the selected one
  //and make the planimetry selected the first one of the  current activity
  function handleItemSelected(id) {
    setSelectedItem(id);
    const activitySelected = attività?.find((item) => item.id===id);
    setPlanimetry(activitySelected.planimetries[0]);
  }

  // il secondo array prende height and width dell'img
  //se voglio un pin posizionato centrale uso come position bounds[1][0] / 2, bounds[1][1] / 2
  const bounds = [[0, 0], imageDimensions ? imageDimensions : [512, 512]];

  //CHANGE MARKER ICON
  function myIcon() {
    return L.icon({
      iconUrl: require("../../../assets/images/pin-red.png"),
      iconSize: 35,
    });
  }

  //Change view type
  function handleViewType(type) {
    if (viewType===type) return;
    if (type === "list") {
      setViewType("list");
    } else if (type === "map") {
      setViewType("map");
    } else if (type === "gantt") {
      setViewType("gantt");
    }
  }

  //Settings for carousels attività map card mobile
  let settings = {
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const dataGantt = attività?.map((item) => {
    const startDate = item.startDate?.substr(0, 10);
    const expirationDate = item.expirationDate?.substr(0, 10);
    const endDate = item.end_date ? item.endDate.substr(0, 10) : "";
    let status;

    if (todayDate < startDate?.split(",").join("-")) {
      status = "aperta";
    } else if (todayDate >= startDate?.split(",").join("-")) {
      status = "in corso";
    } else if (item.endDate) {
      status = "risolta";
    } else if (todayDate > expirationDate?.split(",").join("-")) {
      status = "in ritardo";
    }

    let progressPercentage;
    if (status==="aperta") {
      progressPercentage = 0;
    } else if (status==="in corso") {
      progressPercentage = 50;
    } else if (status==="risolta") {
      progressPercentage = 100;
    } else if (status==="ritardo") {
      progressPercentage = 70;
    }

    let color;
    if (status==="aperta") {
      color = "#B8C2CB";
    } else if (status==="in corso") {
      color = "var(--edit-color)";
    } else if (status==="risolta") {
      color = "#36D69C";
    } else if (status==="ritardo") {
      color = "#EB586A";
    }


    return {
      start: new Date(startDate),
      end: endDate ? new Date(endDate) : new Date(expirationDate),
      name: item.name,
      id: item.id,
      type: "task",
      progress: progressPercentage,
      isDisabled: true,
      styles: { progressColor: color, progressSelectedColor: "#ff9e0d" },
    };
  });

  console.log(attività)

  async function editItem(id, data) {
    try {
      data.planimetries = JSON.stringify(data.planimetries).replaceAll(
        '"',
        "'"
      );
      // TODO membri da stringa a lista al backend
      // data.members = JSON.stringify(data.members);
      const response = await api().put(
        `${activity_endpoint}`,
        data
        // {headers: {"Authorization" : `Bearer ${token}`,
        // "Content-Type": "application/json"}}
      );
      await getAll();
    } catch (error) {
      console.log(error);
    }
  }

  //QUANDO CLICCO OPTION => MODIFICA, METTE A FUOCO l'input name selezionato
  function handleEdit() {
    inputRef.current[selectedList[0].id].focus();
  }

  async function onDelete(id) {
    try {
      await api().delete(
        `${activity_endpoint}${id}`
      );
      await getAll();
    } catch (error) {
      console.log(error);
    }
  }

  function handleDelete(e) {
    e.preventDefault();
    selectedList.forEach((item) => onDelete(item.id));
  }

  console.log(selectedList)
   //NEW SEARCH & FILTER

	//SEARCH

	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

    const categories = [
		{
      name: 'Priorità',
      items: [
        "Bassa",
        "Media",
        "Alta"
      ]
		},
    {
      name: 'Unità',
      items: unitListWithoutDuplicate.length > 0 ? unitListWithoutDuplicate.map(el => el) : []
    },
    {
      name: 'Incaricato',
      items: assigneeListWithoutDuplicate.length > 0 ? assigneeListWithoutDuplicate.map(el => el) : []
    }
      
    ];
    
		const [selectedCategories, setSelectedCategories] = useState([]);
		const [sortBy, setSortBy] = useState("meno-recenti");

		const [filteredUnits, setFilteredUnits] = useState([])

    useEffect(() => {
      if (attività.length > 0) {
        setFilteredUnits(
          attività.filter((el) => {

            const elDate = new Date(el.cdat); 

            // Filtro per la data di inizio (startDate)
            if (startDate && elDate < new Date(startDate)) {
				return false;
			}
		
				// Filtro per la data di fine (endDate)
				if (endDate && elDate > new Date(endDate)) {
				return false;
				}
		
				return (
				(selectedCategories.length === 0 ||
					selectedCategories.some((cat) => {
					if (cat.name === 'Priorità') {
            let map 

            if(el.priority == "hight"){
              map = "Alta"
            } else if (el.priority == "medium"){
              map = "Media"
            } else if (el.priority == "low") {
              map = "Bassa"
            }
						return cat.items.includes(map);
					}
          if (cat.name === 'Unità') {
            let map = el.unitName
						return cat.items.includes(map);
					}
          if (cat.name === 'Incaricato') {
            let map = el.assignee
						return cat.items.includes(map);
					}
					})) &&
					el.name?.toLowerCase().includes(searchTerm.toLowerCase())
				);
			})
			);
		}
    }, [searchTerm, selectedCategories, attività, startDate, endDate]);
    

		const [sortedItems, setSortedItems] = useState(filteredUnits);

		useEffect(() => {
      switch (sortBy.toLowerCase()) {
        case "z-a":
        setSortedItems(sortStringByKey(filteredUnits, "name", false));
        break;
        case "a-z":
        setSortedItems(sortStringByKey(filteredUnits, "name"));
        break;
        case "meno-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat"));
        break;
        default:
        case "più-recenti":
        setSortedItems(sortDatesByKey(filteredUnits, "cdat", false));
      }
		}, [filteredUnits, sortBy]);

		function clearFiltersAndSort() {
		setSearchTerm("");
		setSelectedCategories([]);
		setSortBy("più-recenti");
		setFilteredUnits(attività);
		setStartDate("")
		setEndDate("")

		}

    /* MODAL Riassunto contabilita' */
    const [showRc, setShowRc] = useState(false);
    const handleShowRc = () => setShowRc(true)
    const handleCloseRc = () => setShowRc(false);

    //LOGICA PER RIASSUNTO CONTABILITA'

    const contabilitàAttivitàTotale = parseActivities(attività)
    const contabilitàAttività = selectedList.length > 0? contabilitàAttivitàTotale[selectedList[0]] : {}

    //CHECK IF DATA REQUIRED ARE COMPILED
    const [dataRequired, setDataRequired] = useState(true);
    //Gestisce il modale
    const [showCheck, setShowCheck] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await checkUserData();
                setDataRequired(response.isVerified);
                if (!response.isVerified) {
                  setShowCheck(true);
              }
            } catch (error) {
                // Gestire gli errori qui, ad esempio visualizzando un messaggio di errore
                console.error('Errore durante il recupero dei dati utente:', error);
            }
        };

        fetchData();
    }, []);

    //handle modal delete
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = (e) => {
      setShowModal(false);
      e.preventDefault();
      e.stopPropagation();
    };

    const handleShowModal = () => setShowModal(true);
  
    //handle modal permessi
    const [showPermit, setShowPermit] = useState(false);
    const handleClosePermit = (e) => {
      setShowPermit(false);
      e.preventDefault();
      e.stopPropagation();
    };
  
    const handleShowPermit = () => setShowPermit(true);

    //MI SERVE LISTA COLLABORATORI MASTER
    const [members, setMembers] = useState([
    {
      id:1,
      name:"Ema",
      surname:"Mavi",
      email:"ema@gmail.com"
    },
    {
      id:2,
      name: 'Greta',
      surname: 'Pio',
      email: 'gre@gmail.com'
    }
    ])
  
    const handleCheckboxChange = (userId, action) => {
    //da gestire
    };
 
    // Funzione per eliminare gli stabili selezionati
    const deleteSelected = async () => {
    // Effettua l'operazione di eliminazione per ogni id selezionato
    for (const el of selectedList) {
    try {
      await api().delete(`${activity_endpoint}${el.id}`);
    } catch (err) {
      console.log(err);
    }
    }
    await getAll()
      setSelectedList([])
  };

    const handleSelectAll = () => {
      let selectedItems = sortedItems.map((item, index) => item)
      setSelectedList(selectedItems)
    }

    const handleDeselectAll = () => {
      setSelectedList([])
    }

    const handleSelection = (e, id, el) => {
    if(e){
      e.stopPropagation()
    }

    if(selectedList.some(item => item.id == id)){
      setSelectedList(selectedList.filter((item) => item.id !== id));
    } else {
      setSelectedList([...selectedList, el]);
    }
  };


  console.log("attività", attività)

  

  return (
    <>
    {/* HEADER */}
      <div className="title-search-group mobile-container-xl ">
        <div className="right-header">
            <TopHeading
                SmallHeading=""
                back_modal={false}
            />
        </div>

        <Row className="mobile-container d-flex justify-content-between align-items-center mt-md-4 div-title-container">                    
          <Col className="d-flex flex-column flex-md-row justify-content-start align-items-start">
          <h2 className="fw-bold me-4">Attività</h2>
          <div className="d-flex justify-content-center align-items-center ">
            <a
              className={`viewType ${viewType === "list" ? "select" : ""}`}
              onClick={() => handleViewType("list")}
            >
              <i class="bi bi-list-ul"></i>
            </a>
            <a
              className={`viewType ms-2 ${viewType === "map" ? "select" : ""}`}
              onClick={() => handleViewType("map")}
            >
              <i class="bi bi-map"></i>
            </a>
            <a
              className={`viewType ms-2 ${
                viewType === "gantt" ? "select" : ""
              }`}
              onClick={() => handleViewType("gantt")}
            >
              <i class="bi bi-bar-chart-steps"></i>
            </a>
          </div>
          </Col>
          <Col className="d-flex justify-content-end ">
            {(checkUserRole()) && <AddBtn
              onClick={createActivity}
              tooltip="Nuova attività"
              className="me-3 me-md-0 order-md-1"
              dataRequired={dataRequired}
            />}
          </Col>                    
        </Row>
      </div>
              
      {/** BODY **/}

       {/* LIST VIEW */}
       {viewType === "list" && (
                    <ListViewActivity
                      attività={attività}
                      sortedItems={sortedItems}
                      selectedList={selectedList}
                      handleCheckbox={handleCheckbox}
                      handleDelete={handleDelete}
                      setSearchTerm={setSearchTerm}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      clearFiltersAndSort={clearFiltersAndSort}
                      setSortBy={setSortBy}
                      categories={categories}
                      onEdit={editItem}
                      ref={inputRef}
                      handleShowRc={handleShowRc}
                      setStartDate={setStartDate} setEndDate={setEndDate}
                      handleEdit={handleEdit}

                      handleShowModal={ handleShowModal}
                      handleShowPermit={ handleShowPermit}
                      handleSelectAll={handleSelectAll}
                      handleDeselectAll={handleDeselectAll}
                      handleSelection={handleSelection}
                />
                )}

                {/* MAP VIEW */}
                {viewType === "map" && (  
                    <MapViewActivity
                      attività={attività}
                      sortedItems={sortedItems}
                      selectedList={selectedList}
                      setSearchTerm={setSearchTerm}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      clearFiltersAndSort={clearFiltersAndSort}
                      setSortBy={setSortBy}
                      categories={categories}

                      handleItemSelected={handleItemSelected}
                      selectItem={selectItem}

                      bounds={bounds}
                      activitySelected={activitySelected}
                      currentPlanimetryImage={currentPlanimetryImage}

                      rectangles={rectangles}
                      points={points}
                      planimetry={planimetry}
                      handlePlanimetry={handlePlanimetry}
                      setStartDate={setStartDate} setEndDate={setEndDate}

                    />
                )}

                {/* GANTT VIEW */}
                {viewType === "gantt" && (
                    <GanttViewActivty 
                      attività={attività}
                      sortedItems={sortedItems}
                      selectedList={selectedList}
                      setSearchTerm={setSearchTerm}
                      selectedCategories={selectedCategories}
                      setSelectedCategories={setSelectedCategories}
                      clearFiltersAndSort={clearFiltersAndSort}
                      setSortBy={setSortBy}
                      categories={categories}
                      setStartDate={setStartDate} setEndDate={setEndDate}
                    />
                )}

{/*         <RiassuntoContabilita showRc={showRc} handleCloseRc={handleCloseRc} chat={selectedList.length> 0 ? selectedList[0].chatGroup[0]: []}/> */}

        <RiassuntoContabilitaUnità showRc={showRc} handleCloseRc={handleCloseRc} totalCosts={contabilitàAttività && Object.keys(contabilitàAttività).length > 0 ? contabilitàAttività : {}} item={selectedList.length > 0 ? selectedList[0] : {}} onEdit={editItem}/>
      <Outlet />


      {(checkUserRole()) && 
        <ModalCheckUser showCheck={showCheck} setShowCheck={setShowCheck}>
          <p className="mb-3">Per procedere con la creazione di attività, è necessario completare le seguenti informazioni:</p>
            <p>1. <strong>Anagrafica Base:</strong> Compila i dettagli anagrafici in Impostazioni / Profilo / Anagrafica</p>
          
          {/*<em>Un nostro operatore verificherà le informazioni fornite entro le prossime 24/48 ore lavorative. Una volta completata la verifica, questa funzionalità sarà abilitata.</em>*/}
        </ModalCheckUser>
      }

      <Modal show={showModal} onHide={(e) => handleCloseModal(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Vuoi eliminare questo elemento?
				</h2>
				<h6 className="fs-16 text-dark fw-normal mb-3">
				Questa azione è irreversibile
				</h6>
				<button
				type="button"
				className="confirm-btn mb-2 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					deleteSelected();
					handleCloseModal(e);
				}}
				>
				Conferma
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleCloseModal(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		  </Modal>

      <Modal show={showPermit} onHide={(e) => handleClosePermit(e)} centered>
			<Modal.Body className="p-5">
			<div className="d-flex flex-column text-center">
				<h2 className="fs-32 text-dark fw-semibold mb-3">
				Gestisci i permessi per questo elemento
				</h2>
				{members.length > 0 && 
				<table className="my-3">
					<thead>
						<tr>
						<th>Collaboratore</th>
						<th>Visualizza</th>
						<th>Modifica</th>
						<th>Elimina</th>
						</tr>
					</thead>
					<tbody>
					{ members.map((user, i) => (
					<tr key={i}>
						<td>{user.email}</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'visualizza')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'modifica')}
						/>
						</td>
						<td>
						<input
							type="checkbox"
							onChange={() => handleCheckboxChange(i, 'elimina')}
						/>
						</td>
					</tr>
					))}
				</tbody>
				</table>
				}
				<button
				type="button"
				className="confirm-btn my-2 mt-4 rounded-3 fs-16 py-2 w-100 border-0 text-white"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handleClosePermit(e);
				}}
				>
				Salva
				</button>
				<button
				type="button"
				className="cancel-btn rounded-3 fs-16 py-2 w-100 border-0 text-dark bg-white"
				onClick={(e) => handleClosePermit(e)}
				>
				Annulla
				</button>
			</div>
			</Modal.Body>
		  </Modal>
    </>
  );
}
