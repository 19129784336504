import React, { useState, useEffect } from "react";
import RulerIcon from "../../../assets/images/ruler.svg";
import MoneyIcon from "../../../assets/images/moneyIcon.svg";

const BuildingCard = ({ item, typology, addToReport, removeToReport, buildingArray }) => {
	const [reportState, setReportState] = useState(false);
	const add = () => {
		if(buildingArray && buildingArray.length < 4){
		addToReport(item);
		setReportState(true);
		}
	};

	const remove = () => {
		removeToReport(item);
		setReportState(false);
	};

	useEffect(() => {
		setReportState(false)
	}, [item])

	return (
		<div className="building-card d-md-flex justify-content-md-between align-items-md-center" >
			<div className="d-flex">
				<div className="building-img">
					<img loading="lazy" src={item.images[0]} alt="" />
				</div>
				<div className="d-flex flex-column flex-auto p-2">
					<span className="building-card-title text-uppercase">{typology}</span>
					<span className="small-span">{item.address}</span>
					<div className="d-flex  flex-wrap">
						<div className="me-3 d-flex align-items-center flex-nowrap">
							<img loading="lazy" src={RulerIcon} alt="" className="me-1 small-icon" />
							<span className="small-span">{item.surface}</span>
						</div>
						<div className="me-3">
							<img loading="lazy" src={MoneyIcon} alt="" className="me-1 small-icon" />
							<span className="small-span">{item.price}</span>
						</div>
						<div className="me-3">
							<img loading="lazy" src="https://cdn2.iconfinder.com/data/icons/bathroom-line-1/64/Bidets-512.png" alt="" className="me-1 small-icon" />
							<span className="small-span">{item.bath}</span>
						</div>
						<div className="me-3">
							<img loading="lazy" src="https://cdn3.iconfinder.com/data/icons/outline-amenities-icon-set/64/Emergency_Stairs-512.png" alt="" className="me-1 small-icon" />
							<span className="small-span">{item.floor}</span>
						</div>
						<div className="me-3">
							<img loading="lazy" src="https://cdn3.iconfinder.com/data/icons/construction-294/32/Construction_floor_plan-512.png" lt="" className="me-1 small-icon" />
							<span className="small-span">{item.cnt_room}</span>
						</div>
					</div>
				</div>
			</div>
			{reportState===false ? (
				<button className="report-btn add" onClick={add} disabled={buildingArray.length < 4 ? false : true}>
					Aggiungi al report
				</button>
			) : (
				<button className="report-btn remove" onClick={remove}>
					Rimuovi dal report
				</button>
			)}
		</div>
	);
};

export default BuildingCard;
