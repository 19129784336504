import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ButtonRm from "../../../ButtonRm";
import { diffDays } from "@fullcalendar/core/internal";
import ExportButton from "../../../Membri/Fornitori/ExportButton";
import ShareChat from "../../../ShareChat";

export default function Section0({ onChangeSection }) {
  const [status, setStatus] = useState(0);
  const [radioStatus, setRadioStatus] = useState(0);
  const [inputNumber, setInputNmber] = useState(null);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tasso_fisso, set_tasso_fisso] = useState(3);
  const [fissi, set_fissi] = useState(6);
  const [mora_BCE, set_mora_BCE] = useState(8);

  const [accumulati, setaccumulati] = useState(0);
  const [rivalutato, setrivalutato] = useState(0);
  const [differDate, setDifferDate] = useState(0);
  const [totalfissi, setTotalfissi] = useState(0);


  //SE INTERESSI CAPITALIZZATI
  const [capital, setcapital] = useState("trimestre");
  const [dataCapitalEnd, setDataCapitalEnd] = useState("")
  const formattedDate = dataCapitalEnd ? `${dataCapitalEnd.getFullYear()}-${(dataCapitalEnd.getMonth() + 1).toString().padStart(2, '0')}-${dataCapitalEnd.getDate().toString().padStart(2, '0')}` : ""

  const [interessiCapital, setInteressiCapital] = useState(0)



  const radioChanged = (value) => {
    setRadioStatus(value);
  };

  console.log("totalfissi", capital)

  const calClicked = () => {
    if (radioStatus !== 0 && inputNumber !== "" && inputNumber !== null) {
      setIsShowDetail(true);
      let start = moment(startDate);
      let end = moment(endDate);
      let date = end.diff(start, "day");
      let inter = 0;
      switch (radioStatus) {
        case 1:
          inter = (parseInt(date) / 365) * 0.0125;
          break;
        case 2:
          inter = (parseInt(date) / 365) * 0.0125;
          break;
        case 3:
          inter =
            ((parseInt(tasso_fisso) / 100 + 0.0125) / 365) * parseInt(date);
          break;
        case 4:
          inter = (parseInt(fissi) * parseInt(date)) / (365 * 100);
          break;
        case 5:
          inter = 0;
          break;
        case 6:
          inter = ((parseInt(mora_BCE) / 100 + 0.0125) / 365) * parseInt(date);
          break;
        default:
          break;
      }
      setaccumulati(inter * inputNumber);
      setrivalutato((1 + inter) * inputNumber);
      setDifferDate(date);
      setTotalfissi((inter / date) * 365 * 100);
    }
  };

  const oncapitalChangeHandler = (event) => {
    setcapital(event.target.value);
  };

  //CALCOLO

  const calculateInterest = () => {

    let importo = inputNumber
    // Converte le date in oggetti Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calcola il numero di giorni di ritardo
    const delayDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    setDifferDate(delayDays)

    // Calcola il tasso di interesse giornaliero
    let interestRate = 0;
    if (radioStatus == 1 || radioStatus == 2) {
      interestRate = 0.0125 / 365;
       // Calcola gli interessi capitalizzati per il periodo di 3 mesi
      if (radioStatus == 2 && capital == 'trimestre') {
        //TROVA DATE FINE TRIMESTRE
        const dataCapital = new Date(start.getTime());
        dataCapital.setMonth(dataCapital.getMonth() + 3);
        const capitalizationInterest = importo * interestRate * 90;

        setDataCapitalEnd(dataCapital)
        setInteressiCapital(capitalizationInterest)
      } else if (radioStatus == 2 && capital == 'semestre') {
        //TROVA DATE FINE TRIMESTRE
        const dataCapital = new Date(start.getTime());
        dataCapital.setMonth(dataCapital.getMonth() + 6);
        const capitalizationInterest = importo * interestRate * 180;

        setDataCapitalEnd(dataCapital)
        setInteressiCapital(capitalizationInterest)
      } else if (radioStatus == 2 && capital == 'annuale') {
        //TROVA DATE FINE TRIMESTRE
        const dataCapital = new Date(start.getTime());
        dataCapital.setMonth(dataCapital.getMonth() + 12);
        const capitalizationInterest = importo * interestRate * 365;

        setDataCapitalEnd(dataCapital)
        setInteressiCapital(capitalizationInterest)
      }
    } else if (radioStatus == 3) {
      interestRate = ((1.25 + tasso_fisso) / 100) / 365;
    } else if (radioStatus == 4) {
      interestRate = (fissi / 100) / 365;
    } else if (radioStatus == 5) {
      interestRate = 0;
    } else if ( radioStatus == 6) {
      interestRate = ((0 + mora_BCE)/100) /365;
    }

    // Calcola gli interessi accumulati
    let interest = importo * interestRate * delayDays;

    // Calcola il numero di periodi di ritardo per l'interesse capitalizzato (based on var capital)
    //TODO semestre iniziale + 6 mesi, trimestre iniziale + 3 mesi etc
    //cambiare testo di conseguenza

    // Aggiungi gli interessi dei periodi di ritardo per l'interesse capitalizzato
    //interest += capital * interestRate * delayPeriods;

    // Calcola il capitale rivalutato
    const rivalCapital = importo + interest;

    // Aggiorna gli stati con i risultati
    setaccumulati(parseFloat(interest));
    setrivalutato(parseFloat(rivalCapital));
    setIsShowDetail(true);
    setTotalfissi(parseFloat(interestRate*100*365))

    console.log(interestRate*100*365)
  };

  //DATI PER ESPORTAZIONE
  const item = {
    ['Periodo']: `€ ${parseFloat(accumulati).toFixed(2)} DAL ${startDate} al ${endDate}${" "} ${differDate} gg al ${totalfissi.toFixed(2)}% annuo`,
    ['Imposta dovuta']: `€ ${inputNumber}`,
    ['Interessi']: `€ ${parseFloat(accumulati).toFixed(2)} - Totale interessi`,
    ['Totale da pagare']: `€ ${parseFloat(rivalutato).toFixed(2)} - Totale da pagare`,
  }

  return (
    <div className={`page-body`}>
      <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">
            Tipo di registrazione
          </h6>
        </div>
      </div>
      {/* <div className='page-title'>
                <Image src={nav1} width="100%" />
            </div> */}
      <div className="space-top1">
        <div className="space-top1">
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice1"
              value="0"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(1)}
            />
            <label htmlFor="contactChoice1" className="btn-radio">
              &nbsp;Interessi legali semplici
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice2"
              value="1"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(2)}
            />
            <label htmlFor="contactChoice2" className="btn-radio">
              &nbsp;Interessi legali capitalizzati al
            </label>
            <select
              name="cars"
              id="cars"
              style={{ height: "35px", padding: "0 .5rem" }}
              className="select-section"
              onChange={oncapitalChangeHandler}
              value={capital}
            >
              <option value="trimestre">Trimestre</option>
              <option value="semestre">Semestre</option>
              <option value="annuale">Annuale</option>
            </select>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice3"
              value="2"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(3)}
            />
            <label htmlFor="contactChoice3" className="btn-radio">
              &nbsp;Interessi legali + tasso fisso del 
            </label>
            <div className="divInput">
              <input
                type="number"
                min="1"
                max="100"
                style={{ height: "35px", width: "150px" }}
                className="spin-section"
                value={tasso_fisso}
                onChange={(e) => set_tasso_fisso(e.target.value)}
              />
              <span className="spanInputPercentage">%</span>
          </div>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice4"
              value="3"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(4)}
            />
            <label htmlFor="contactChoice4" className="btn-radio">
              &nbsp;Interessi fissi del {" "}
            </label>
            <div className="divInput">
              <input
                type="number"
                min="1"
                max="100"
                style={{ height: "35px", width: "150px" }}
                className="spin-section"
                value={fissi}
                onChange={(e) => set_fissi(e.target.value)}
              />
              <span className="spanInputPercentage">%</span>
          </div>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice5"
              value="4"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(5)}
            />
            <label htmlFor="contactChoice5" className="btn-radio">
              &nbsp;Interessi di mora BCE{" "}
            </label>
          </div>
          <div className="radioLabel">
            <input
              type="radio"
              id="contactChoice6"
              value="4"
              name="radio"
              className="btn-radio"
              onChange={() => radioChanged(6)}
            />
            <label htmlFor="contactChoice6" className="btn-radio">
              &nbsp;Interessi di mora BCE + tasso fisso {" "}
            </label>
            <div className="divInput">
              <input
                type="number"
                min="1"
                max="100"
                style={{ height: "35px", width: "150px" }}
                className="spin-section"
                value={mora_BCE}
                onChange={(e) => set_mora_BCE(e.target.value)}
              />
              <span className="spanInputPercentage">%</span>
          </div>
          </div>
        </div>
      </div>
      <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">Date</h6>
        </div>
      </div>
      {/* <div className='page-title'>
                <Image src={nav2} width="100%" />
            </div> */}
      <div className="space-top1">
        <div className="sub-title">Data di stipula del contratto</div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <input
                type="date"
                className="dateInput"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="space-top1">
        <div className="sub-title">
          Data prevista per il pagamento dell’imposta
        </div>
        <div style={{ marginTop: "1%" }}>
          <Row>
            <Col>
              <input
                type="date"
                className="dateInput"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="label-heading my-3 d-block w-100">
        <div className="label-text rel d-inline-block py-2 px-2">
          <h6 className="fs-14 text-white text-uppercase fw-semibold">
            Importi
          </h6>
        </div>
      </div>
      {/* <div className='page-title'>
                <Image src={nav3} width="100%" />
            </div> */}

      <Row>
        <Col md={5} >
            <div className="sub-title">Imposta dovuta</div>
            <div className="divInput">
              <span className="spanInput1">€</span>
              <input
                type="number"
                className="input-calc-symbol"
                value={inputNumber}
                onChange={(e) => {
                  let convertedValue = parseFloat(e.target.value)
                  setInputNmber(convertedValue)}}
              ></input>
            </div>
          </Col>
          <Col>
          <ButtonRm
                  style={{ marginTop: "1.5rem" }}
                  variant="secondary"
                  size="small"
                  onClick={calculateInterest}
                  disabled={radioStatus && startDate && endDate && inputNumber ? false : true}
                >
                  Calcola
                </ButtonRm>
          </Col>
      </Row>
      {isShowDetail && (
        <div>
          <div className="space-top1">
            <Row>
              <Col xs={6}>
                <div className="sub-title">Interessi accumulati</div>
              </Col>
              <Col xs={6}>
                <div className="sub-title">Capitale rivalutato</div>
              </Col>
            </Row>

            <Row className="space-top1">
              <Col xs={6}>
                <div className="input-text">
                  € {parseFloat(accumulati).toFixed(2)}
                </div>
              </Col>
              <Col xs={6}>
                <div className="input-text">
                  € {parseFloat(rivalutato).toFixed(2)}
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <div className="page-title">
              <div className="label-heading my-3 d-block w-100">
                <div className="label-text rel d-inline-block py-2 px-2">
                  <h6 className="fs-14 text-white text-uppercase fw-semibold">
                    Elenco dei calcoli
                  </h6>
                </div>
              </div>
            </div>

            <div className="text-end d-flex justify-content-end">
{/*               <ShareChat styleBtn="top-btn-icon ms-2" />
 */}              
              <ExportButton data={item} mapping={{}} classes="top-btn-icon ms-2"/>
            </div>

            <div className="sub-title space-top1 text-dark">
              € {parseFloat(accumulati).toFixed(2)} DAL {startDate} al {endDate} {" "}
              ({differDate} gg) al {totalfissi.toFixed(2)}% annuo
            </div>

            <div className="sub-title space-top1 text-dark">
              € {parseFloat(accumulati).toFixed(2)} - Totale interessi
            </div>

            <div className="space-top1 fw-bold">
              € {parseFloat(rivalutato).toFixed(2)} - Totale da pagare
            </div>

            {
              (radioStatus == 2) &&
              <div className="mt-4">
                  <p className="fw-bold"> 
                    {capital == "trimestre" && "Trimestre"}
                    {capital == "semestre" && "Semestre"}
                    {capital == "annuale" && "Annuale"}
                  </p>
                  <div className="sub-title space-top1 text-dark">
                  € {parseFloat(interessiCapital).toFixed(2)} DAL {startDate} al {formattedDate}{" "}
                  {capital == "trimestre" && "90gg"} 
                  {capital == "semestre" && "180gg"}  
                  {capital == "annuale" && "365gg"}  
                  al 1.25% annuo
                  </div>
                  <div className="sub-title space-top1 text-dark">
                    € {parseFloat(interessiCapital).toFixed(2)} - Totale interessi
                  </div>
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
}
