import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../../assets/css/Impostazioni.css"
import { Modal } from "react-bootstrap";

import { BsDownload, BsTrash, BsFiEdit2, BsArchive} from "react-icons/bs";
import { FiEdit2} from "react-icons/fi";
import PayPal from "../../assets/images/PayPal.svg"
import Mastercard from  "../../assets/images/Mastercard.svg"
import Maestro from "../../assets/images/Maestro.svg"





import CustomBadge from "../CustomBadge";
import ButtonRm from "../ButtonRm";
import Image from 'react-bootstrap/Image'

import removeImg from "../../assets/images/removeImg.svg"
import logoRed from "../../assets/images/logo-red.svg"
import uploadImg from "../../assets/images/upload.svg"
import pianoIcon from "../../assets/images/pianoIcon.svg"

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import "react-toastify/dist/ReactToastify.css";
import Webcam from "react-webcam";
import "../../assets/css/Tabs.css";
import FaceIdImg from "../../assets/images/face id-cropped.svg";
import onlyFace from "../../assets/images/onlyFace.svg"
import { PinInput } from 'react-input-pin-code';

import CreateAccount from "../Membri/CreateAccount";
import { BankCard, BankCardItem } from "../BankCard";

import FileInputButton from "../FileInputButton";

import SignatureCanvas from 'react-signature-canvas'

import InputGroup from 'react-bootstrap/InputGroup';

import SearchInput from "../SearchInput";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import api from "../../api/api";
import { Chatlist } from "../StatoLavoriInCorso/Chat/ChatList";
import { useNavigate } from "react-router-dom";
import { checkUserRole } from "../../utils/checkUserRole";




export default function Archivio(){

    //HANDLE SEARCH
    const [searchTerm, setSearchTerm] = useState("");

    //BISOGNA CAPIRE COME SARA' IMPOSTATA L'API PER I FILE ARCHIVIATI

    const [section, setSection] = useState(null)
    const [subsection, setSubsection] = useState(null)

    function handleSection(name){
        setSection(name)
    }

    function handleSubsection(name){
        if(section){
            setSubsection(name)
        }
    }




     //CHAT
     const [chatList, setChatList] = useState([])

    async function getAll() {
        try {
            const response = await api().get(`/api/v1/chat-box/getAllChatBox`);
            console.log(response, "response chat box")
            if (response.data) {
                console.log(response.data, response.data.chatBoxes)
                let archivedChat = response.data.chatBoxes?.filter(el => el["chat-box-status-for-you"] == "ARCHIVED")
                setChatList(archivedChat)
            }
        }
        catch(error) {
                console.log(error)
        }
    } 


    useEffect(() => {
        getAll()
    }, [])

    const navigate = useNavigate()

    async function unarchive(elId){
            try {
                const response = await api().post(`api/v1/chat-box/active-chat-box`, {
                    chatBoxId: elId
                });

                navigate("/statoavanzamentolavori/chat")

            }
            catch(error) {
                    console.log(error)
            }
        
    }



    return(
        <>
            <div className="settings-tab-content">
                <Row>
                    <p className="fs-18 text-dark fw-bold">Archivio</p>
                    <p className="fs-14 opacity-75">Qui trovi gli elementi che hai archiviato.</p>
                    <hr className="opacity-10 my-3"/>
                </Row>

                <Row className="mt-2">
                    <Col md={3} className="px-0 mb-3">
                        <SearchInput
                            setSearch={(term) => {
                            setSearchTerm(term);
                            }}
                        />
                    </Col>

                    <Col md={12} className="my-3 px-0">
                        <Breadcrumb>
                            {section && 
                                <Breadcrumb.Item 
                                    className="settings-archive"
                                    onClick={() => {
                                    setSubsection(null)
                                    setSection(null)
                                    }}>
                                    Pagina iniziale
                                </Breadcrumb.Item>
                            }
                            {section &&
                                <Breadcrumb.Item active={section && !subsection ? true: false} 
                                    className="settings-archive"
                                    onClick={() => setSubsection(null)}>
                                    {section}
                                </Breadcrumb.Item>
                            }
                            
                            {section && subsection && 
                                <Breadcrumb.Item   className="settings-archive"
                                active={subsection ? true: false} >{subsection}</Breadcrumb.Item>
                            }
                        </Breadcrumb>
                    </Col>
                    
                    {/* PER OGNI SEZIONE CHE CONTIENE ELEMENTI ARCHIVIABILI BISOGNA CREARE UN ELEMENTO COME QUESTO, CON LA FUNZIONE HANDLESECTION */}

                    {
                        (!section && checkUserRole()) &&
                        <>
{/*                             <ArchiveSectionCard name='Chat' handleSection={handleSection} />
 */}                        <ArchiveSectionCard name='Unità' handleSection={handleSection} />
                            <ArchiveSectionCard name='Affitti' handleSection={handleSection} />
                            <ArchiveSectionCard name='Stabili' handleSection={handleSection} />
                            <ArchiveSectionCard name='Proprietari' handleSection={handleSection} />
                            <ArchiveSectionCard name='Fornitori' handleSection={handleSection} />
                            <ArchiveSectionCard name='Conduttori' handleSection={handleSection} />
                            <ArchiveSectionCard name='Prima nota' handleSection={handleSection} />
                            <ArchiveSectionCard name='Rate' handleSection={handleSection} />
                            <ArchiveSectionCard name='Rendiconto' handleSection={handleSection} />
                            <ArchiveSectionCard name='Calcoli' handleSection={handleSection} /> {/* per i calcoli l'archivio è una sorta di zona di salvataggio, non ci sarà il bottone ripristina, ma si potra esportare un calcolo salvato */}
                            <ArchiveSectionCard name='Scadenze' handleSection={handleSection} />
                            <ArchiveSectionCard name='Gestioni' handleSection={handleSection} />
                            <ArchiveSectionCard name='Attività' handleSection={handleSection} />

                        </>
                    }


                     
                    {/* QUESTO ELEMENTO PERMETTE DI VEDERE GLI ELEMENTI ARCHIVIATI DI QUELLA SEZIONE E RIPRISTINARLI */}

                    {/* { section == "Chat"  && 
                        <div>
                        //PRENDERE LISTA FILTRATA DEGLI ELEMENTI ARCHIVIATI DELLA SEZIONE
                        {
                            chatList.length > 0 && chatList.map(el => {
                                return  <Col sm={12} className="px-0">
                                <div className="firmeCard">
                                    <div className="d-flex align-items-center">
                                        <div className="btn round-icon-btn">
                                            <BsArchive />
                                        </div>
                                        <div className="text-start ms-2">
                                            <p className="fs-16 ">{el.chatBoxName}</p>
                                            <p  className="fs-12 ">{el.cdat}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonRm variant="outline" className="ms-2" onClick={() => unarchive(el.id)}>
                                            Ripristina
                                        </ButtonRm>
                                    </div>
                                    
                                </div>
                                </Col>
                            })
                        }
                        </div>
                    }  */}

                    { section == "Attività"  && 
                        <div>
                            <Col sm={12} className="px-0">
                                <div className="firmeCard">
                                    <div className="d-flex align-items-center">
                                        <div className="btn round-icon-btn">
                                            <BsArchive />
                                        </div>
                                        <div className="text-start ms-2">
                                            <p className="fs-16 ">TEST</p>
                                            <p  className="fs-12 ">011/01/2024</p>
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonRm variant="outline" className="ms-2" >
                                            Ripristina
                                        </ButtonRm>
                                    </div>
                                    
                                </div>
                            </Col>
                        </div>
                    } 
                    
                </Row> 

            </div >
            
        </>

        
    )
}


function ArchiveSectionCard(props){
    return (
        <Col sm={12} className="px-0">
            <div className="firmeCard" onClick={() => props.handleSection(props.name)}>
                <div className="d-flex align-items-center">
                    <div className="btn round-icon-btn">
                        <BsArchive />
                    </div>
                    <div className="text-start ms-2">
                        <p className="fs-16">{props.name}</p>
                    </div>                                
                </div>                            
            </div>
        </Col>
    )
}

