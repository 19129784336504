import React, {useState, useEffect} from "react";
import SearchInput from "../../SearchInput";

import filter from "../../../assets/images/icon-sidebar/filter.svg";
import sort from "../../../assets/images/icon-sidebar/sort.svg";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useSelector, useDispatch } from "react-redux";
import { fetchGestioni } from "../../../actions/gestioni";

const FilterSearchBar = ({ setSearchTerm, selectedCategories, setSelectedCategories, clearFiltersAndSort, setSortBy, categories, noSearch=false, alpha=true, noOrder=false, startDate, endDate, setStartDate, setEndDate, multiView = false, setViewType = null, viewType = 'card' }) => {

  const gestioniList = useSelector((state) => state.gestioniReducer.gestioni);
  const dispatch = useDispatch();

   //get gestioni when this component render for the first time
   useEffect(() => {
    dispatch(fetchGestioni())
  }, []);

  //console.log(selectedCategories) 
  

  const handleCategoryChange = (event, category) => {
    const categoryItems = selectedCategories.find((cat) => cat.name === category)?.items || [];
    const selectedItem = event.target.value;
    
    
    if (event.target.checked) {
      setSelectedCategories([
        ...selectedCategories.filter((cat) => cat.name !== category),
        { name: category, items: [...categoryItems, selectedItem] }
      ]);
    } else {
      const updatedItems = categoryItems.filter((item) => item !== selectedItem);
      if (updatedItems.length === 0) {
        setSelectedCategories(selectedCategories.filter((cat) => cat.name !== category));
      } else {
        setSelectedCategories([
          ...selectedCategories.filter((cat) => cat.name !== category),
          { name: category, items: updatedItems }
        ]);
      }
    }
  };
      
      
  const handleSort = (sortType) => {
    setSortBy(sortType);
  }


  //COLLAPSE -EXPAND - RESEARCH
  const [isPeriodOpen, setIsPeriodOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState({});


  const [searchTerms, setSearchTerms] = useState({});

  const handleSearchChange = (event, category) => {
    setSearchTerms({...searchTerms, [category]: event.target.value });
  };
  


  return (
    <>
      {!noSearch ? (
        <SearchInput
          className="order-md-0"
          setSearch={setSearchTerm}
        />
      ) : (
        <div></div>
      )}

      <div className="d-flex align-items-center ms-3">
        {multiView && 
        <div className="user-dropdown" onClick={() => setViewType(viewType == 'card'? 'table' : 'card')}>
       {/*  <OverlayTrigger
              placement="top"
              delay={{ show: 50, hide: 50 }}
              overlay={
                <Tooltip>
                  Cambia tipo di visualizzazione
                </Tooltip>
              }> */}
              <div className="filter">
                <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
                  {viewType == 'table' && <i class="bi bi-table fs-25"></i>}
                  {viewType == 'card' && <i class="bi bi-view-list fs-25"></i>}
                </div>
          </div>
                      
         {/*  
          </OverlayTrigger> */}
        </div>
       
      }
        <div className="user-dropdown">
          <div className="filter">
            <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 me-2">
              <img loading="lazy" src={filter} alt="Filter" />
            </div>
            <div className="user-dropdown-content">
              <div className="fs-14 fw-bold mb-2">Filtra per</div>

              <div className="mb-3">
  <div className="fs-14 text-uppercase" 
       onClick={() => setIsPeriodOpen(!isPeriodOpen)}>
    Periodo {isPeriodOpen ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}
  </div>
  
  {isPeriodOpen && (
    <>
      <label className="fs-12">Da</label>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="mb-2"
      />
      <label className="fs-12">A</label>
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </>
  )}
</div>


{categories?.map((category) => {
  const isOpen = openCategories[category.name] || false;
  const searchTerm = searchTerms[category.name] || '';
  
  return (
    <div key={category.name} className="mb-3">
      <div className="fs-14 text-uppercase" 
           onClick={() => setOpenCategories({...openCategories, [category.name]: !isOpen})}>
        {category.name} {isOpen ? <i class="bi bi-chevron-up"></i> : <i class="bi bi-chevron-down"></i>}
      </div>
      
      {isOpen && (
        <>
          {/* Barra di ricerca per questa categoria */}
          <input 
            type="text" 
            placeholder={`Cerca in ${category.name}`}
            value={searchTerm}
            onChange={(e) => handleSearchChange(e, category.name)}
            className="mb-2"
          />
          
          {category.items
            .filter(item => {
              const gestione = gestioniList.find((gestione) => String(gestione.id) === item);
              const gestioneNome = gestione ? gestione.name : '';
              const label = category.name === "Gestione" ? gestioneNome : item?.toString();

              return label?.toLowerCase().includes(searchTerm.toLowerCase());
            })
            .map((item) => {
              const gestione = gestioniList.find((gestione) => String(gestione.id) === item);
              const gestioneNome = gestione ? gestione.name : '';

              return (
                <label key={item} className="d-block">
                  <input
                    type="checkbox"
                    className="me-2"
                    value={item}
                    checked={selectedCategories.some((cat) => cat.name === category.name && cat.items.includes(item))}
                    onChange={(e) => handleCategoryChange(e, category.name)}
                  />
                  {category.name == "Gestione" && gestioneNome + " - "}
                  {category.name === "Pagamento" ? (item === "true" ? "In entrata" : "In uscita") : item.toString()}
                </label>
              );
            })}
        </>
      )}
    </div>
  );
})}




              <a className="text-decoration-underline text-dark text-center mt-3" onClick={clearFiltersAndSort}>
                Rimuovi filtri
              </a>
            </div>
          </div>
        </div>
        {!noOrder && (
          <div className="user-dropdown">
            <div className="sort d-flex">
              <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3">
                <img loading="lazy" src={sort} alt="Sort" />
              </div>
            </div>
            <div className="user-dropdown-content">
              <div className="fs-16 fw-bold">Ordina per</div>
              {alpha && (
                <>
                  <div onClick={() => handleSort("z-a")} className="fs-14 sort-text">
                    Z-A
                  </div>
                  <div onClick={() => handleSort("a-z")} className="fs-14 sort-text">
                    A-Z
                  </div>
                </>
              )}
              <div className="fs-14 mb-1 sort-text" onClick={() => handleSort("meno-recenti")}>
                Meno recenti
              </div>
              <div className="fs-14 mb-1 sort-text" onClick={() => handleSort("più-recenti")}>
                Più recenti
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterSearchBar;
