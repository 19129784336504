import React from 'react'
import { useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import nav1 from '../../../assets/images/page7/nav1.png'
import nav2 from '../../../assets/images/page7/nav2.png'
import nav3 from '../../../assets/images/page7/nav3.png'
import moment from 'moment'
import NumericInput from 'react-numeric-input';

export default function Page7() {

    const [startDate, setStartDate] = useState('2020-01-01');
    const [endDate, setEndDate] = useState('2020-01-01');
    const [diffDate, setdiffDate] = useState(0);
    const [startDate1, setStartDate1] = useState('2020-01-01');
    const [inputPercent, setPercent] = useState(75);
    const [diffDate1, setdiffDate1] = useState(0);
    const [startDate2, setStartDate2] = useState('2020-01-01');
    const [inputPercent1, setPercent1] = useState(75);
    const [diffDate2, setdiffDate2] = useState(0);

    const cal1Clicked = () => {
        let start = moment(startDate);
        let end = moment(endDate);
        let date = end.diff(start, "day");
        // let date =moment(startDate).add(-3, "days").calendar()

        setdiffDate(date);
    };

    function spinFormat(num) {
        return num + 'gg';
    }

    const cal2Clicked = () => {
        let start1 = moment(startDate1);
        let date = moment(startDate1).add(-inputPercent, "days").calendar()
        setdiffDate1(moment(date).format('DD/MM/YYYY'));
    };

    const cal3Clicked = () => {
        let start1 = moment(startDate2);
        let date = moment(startDate2).add(inputPercent1, "days").calendar()

        setdiffDate2(moment(date).format('DD/MM/YYYY'));
    };

    return (
        <div className={`page-body`}>
            {/* <div className='page-title'>
                <Image src={nav1} width="100%" />
            </div> */}
            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Giorni fra due date
                </h6>
              </div>
            </div>
            <div className='space-top1'>
                <div className='sub-title'>
                    Inizio
                </div>
                <div style={{ marginTop: "1%" }}>
                    <Row>
                        <Col >
                            <input type="date" className='dateInput' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='space-top1'>
                <div className='sub-title'>
                    Fine
                </div>
                <div style={{ marginTop: "1%" }}>
                    <Row>
                        <Col >
                            <input type="date" className='dateInput' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='space-top2 '>
                <div className='space-top1'>
                    <div className='btn-second medium-small' onClick={() => cal1Clicked()}>Calcola</div>
                </div>
            </div>

            <div className='space-top2 '>
                <div className='space-top1'>
                    <Row>
                        <Col className='w-50 diffdate'>
                            <NumericInput step={1} precision={0} value={diffDate} className="spin" format={spinFormat} readOnly />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Sottrarre giorni ad una data
                </h6>
              </div>
            </div>
            {/* <div className='page-title space-top'>
                <Image src={nav3} width="100%" />
            </div> */}

            <div className='space-top1'>
                <div className='sub-title'>
                    Inizio
                </div>
                <div style={{ marginTop: "1%" }}>
                    <Row>
                        <Col >
                            <input type="date" className='dateInput' value={startDate1} onChange={(e) => setStartDate1(e.target.value)} />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='space-top1'>
                <div className='sub-title'>
                    Numero di giorni
                </div>
                <div style={{ marginTop: "1%" }}>
                    <Row>
                        <Col className=""> <input type="number" min="1" max="100" className='spin-section' value={inputPercent} onChange={(e) => setPercent(e.target.value)} /></Col>
                    </Row>

                </div>
            </div>

            <div className='space-top2 '>
                <div className='space-top1'>
                    <div className='btn-second medium-small' onClick={() => cal2Clicked()}>Calcola</div>
                </div>
            </div>

            <div className='space-top2 '>
                <div className='space-top1'>
                    <Row>
                        <Col className="">
                            <div className="divInputText w-40">
                                {/* <input type="number" className="inputText1" readOnly >{diffDate1}</input> */}
                                <input type="text" className="inputText1" value={diffDate1} readOnly ></input>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="label-heading my-3 d-block w-100">
              <div className="label-text rel d-inline-block py-2 px-2">
                <h6 className="fs-14 text-white text-uppercase fw-semibold">
                  Aggiungere giorni ad una data
                </h6>
              </div>
            </div>
            {/* <div className='page-title space-top'>
                <Image src={nav2} width="100%" />
            </div> */}

            <div className='space-top1'>
                <div className='sub-title'>
                    Inizio
                </div>
                <div style={{ marginTop: "1%" }}>
                    <Row>
                        <Col >
                            <input type="date" className='dateInput' value={startDate2} onChange={(e) => setStartDate2(e.target.value)} />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='space-top1'>
                <div className='sub-title'>
                    Numero di giorni
                </div>
                <div style={{ marginTop: "1%" }}>
                    <Row>
                        <Col className=""> <input type="number" min="1" max="100" className='spin-section' value={inputPercent1} onChange={(e) => setPercent1(e.target.value)} /></Col>
                    </Row>
                </div>
            </div>

            <div className='space-top2 '>
                <div className='space-top1'>
                    <div className='btn-second medium-small' onClick={() => cal3Clicked()}>Calcola</div>
                </div>
            </div>

            <div className='space-top2 '>
                <div className='space-top1'>
                    <Row>
                        <Col className="">
                            <div className="divInputText w-40">
                                <input type="text" className="inputText1" readOnly value={diffDate2}></input>

                            </div>
                        </Col>
                    </Row>

                </div>
            </div>



        </div>
    )
}