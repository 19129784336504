import React, { useState } from "react";
import photoImg from "../../../assets/images/photo.png";
import UploadImg from "../../../assets/images/upload_file.svg";
import ImgFileCard from "./ImgFileCard";
import FileCard from "../../Documenti/prima_nota/Tipologia/FileCard";
import CustomBadge from "../../CustomBadge";

const generatePreview = (file, callback) => {
	const reader = new FileReader();
	reader.onloadend = () => {
	  callback(reader.result);
	};
	reader.readAsDataURL(file);
  };
  

const PhotoesAndPlans = (props) => {

	const [photoPreviews, setPhotoPreviews] = useState([]);
	const [planPreviews, setPlanPreviews] = useState([]);


	const setPhotoFileName = (index, new_name) => {
		let temp = [...props.formData.photo];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "photo", value: temp } };
		props.onChange(ele);
	};

	const deletePhotoFile = (index) => {
		let temp = [...props.formData.photo];
		temp.splice(index, 1);
		let ele = { target: { name: "photo", value: temp } };
		props.onChange(ele);
	};

	const setPlanimetryFileName = (index, new_name) => {
		let temp = [...props.formData.planimetry];
		var file = temp[index];
		var name = new_name + file.name.substring(file.name.lastIndexOf("."));
		temp[index] = new File([file], name, { type: file.type });
		let ele = { target: { name: "planimetry", value: temp } };
		props.onChange(ele);
	};

	const deletePlanimetryFile = (index) => {
		let temp = [...props.formData.planimetry];
		temp.splice(index, 1);
		let ele = { target: { name: "planimetry", value: temp } };
		props.onChange(ele);
	};
	
	const handlePhoto = (e) => {
		let temp = [...props.formData.photo];
		let tempPreviews = [...photoPreviews]; // Aggiungi questa riga
	  
		for (var i = 0; i < e.target.files.length; i++) {
		  temp.push(e.target.files[i]);
		  generatePreview(e.target.files[i], (preview) => {
			tempPreviews.push(preview); // Aggiungi l'anteprima alla lista delle anteprime
			setPhotoPreviews(tempPreviews); // Aggiorna lo stato delle anteprime
		  });
		}
	  
		let ele = {
		  target: { name: e.target.name, value: temp },
		};
		props.onChange(ele);
	  };

	  const handlePlanimetri = (e) => {
		let temp = [...props.formData.photo];
		let tempPreviews = [...photoPreviews]; // Aggiungi questa riga
	  
		for (var i = 0; i < e.target.files.length; i++) {
		  temp.push(e.target.files[i]);
		  generatePreview(e.target.files[i], (preview) => {
			tempPreviews.push(preview); // Aggiungi l'anteprima alla lista delle anteprime
			setPhotoPreviews(tempPreviews); // Aggiorna lo stato delle anteprime
		  });
		}
	  
		let ele = {
		  target: { name: e.target.name, value: temp },
		};
		props.onChange(ele);
	  };

	  
	const handlePlanimetry = (e) => {
		let temp = [...props.formData.planimetry];
		let tempPreviews = [...planPreviews]; // Aggiungi questa riga

		for (var i = 0; i < e.target.files.length; i++) {
			temp.push(e.target.files[i]);
			generatePreview(e.target.files[i], (preview) => {
			  tempPreviews.push(preview); // Aggiungi l'anteprima alla lista delle anteprime
			  setPlanPreviews(tempPreviews); // Aggiorna lo stato delle anteprime
			});
		  }
		let ele = {
			target: { name: e.target.name, value: temp },
		};
		props.onChange(ele);
	};
	///// extension check ////

	const [isValid, setIsValid] = useState(true);
	const isValidFileType = (file) => {
		const validExtensions = ["png", "jpeg", "pdf", "jfif", "heif"];
		const fileExtension = file.type.split("/")[1];
		setIsValid(validExtensions.includes(fileExtension));
	};

	const [dragActivePhoto, setDragActivePhoto] = useState(false);
	const [dragActivePlanimetry, setDragActivePlanimetry] = useState(false);

	// handle drag events
	const handleDragPhoto = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActivePhoto(true);
		} else if (e.type === "dragleave") {
			setDragActivePhoto(false);
		}
	};

	const handleDragPlanimetry = function (e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActivePlanimetry(true);
		} else if (e.type === "dragleave") {
			setDragActivePlanimetry(false);
		}
	};

	// triggers when file is dropped
	const handleDropPhoto = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActivePhoto(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let temp = [...props.formData.photo];
			let ele = {
				target: { name: "photo", value: [...temp, ...e.dataTransfer.files] },
			};
			props.onChange(ele);
		}
	};

	const handleDropPlanimetry = function (e) {
		e.preventDefault();
		e.stopPropagation();
		setDragActivePlanimetry(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			let temp = [...props.formData.planimetry];
			let ele = {
				target: {
					name: "planimetry",
					value: [...temp, ...e.dataTransfer.files],
				},
			};
			props.onChange(ele);
		}
	};

	return (
		<div className="form-item-align-box-white d-block">
			<div>
				<h2 className="fs-16 text-dark mb-2 fw-bold">FOTO E PLANIMETRIE</h2>
			</div>
			<label className="f-label fs-16 d-flex align-items-center
mb-2 mt-2">
				Aggiungere foto, planimetrie o altre immagini associate all’unità
				immobiliare
			</label>
			<div className="d-flex justify-content-start">
				<label
					htmlFor="photo"
					className="general-btn white-btn w-193 me-2 text-center">
					Aggiungi foto
				</label>
				<input
					type="file"
					id="photo"
					className="d-none"
					multiple={true}
					name="photo"
					onChange={handlePhoto}></input>
				<label
					htmlFor="planimetry"
					className="general-btn white-btn w-210 text-center">
					Aggiungi planimetria
				</label>
				<input
					type="file"
					id="planimetry"
					className="d-none"
					multiple={true}
					name="planimetry"
					onChange={handlePlanimetry}></input>
			</div>
			{props.formData.photo.length === 0 &&
			props.formData.planimetry.length === 0 ? (
				<div className="unit-empty-img">
					<img loading="lazy" src={photoImg} alt="" />
					<span className="img-comment">
						Non hai aggiunto ancora nessuna foto o planimetria
					</span>
				</div>
			) : (
				<>
					{props.formData.planimetry.length > 0 && (
						<>
							<div>
								<h2 className="fs-16 text-dark mb-2 fw-bold mt-5">PLANIMETRIE</h2>
							</div>
							<div className="d-flex flex-wrap">
								{props.formData.planimetry.map((item, index) => {
									return (
										<div key={index}>
											<ImgFileCard id={index} img={item} path={item.path} // Assumi che l'oggetto item abbia la proprietà 'path'
									preview={planPreviews[index]}  />
											<div className="image-div">
												<FileCard
													id={index}
													name={item.name}
													setFileName={setPlanimetryFileName}
													deleteFile={deletePlanimetryFile}
												/>
											</div>
										</div>
									);
								})}
								<label htmlFor="planimetry" style={{ margin: "10px" }}>
									<div
										className={`file-upload-div ${
											dragActivePlanimetry ? "drag-active" : ""
										}`}
										onDragEnter={handleDragPlanimetry}
										onDragLeave={handleDragPlanimetry}
										onDragOver={handleDragPlanimetry}
										onDrop={handleDropPlanimetry}>
										<img loading="lazy" src={UploadImg} alt="" />
									</div>
								</label>
							</div>
						</>
					)}
					{props.formData.photo.length > 0 && (
						<>
							<div>
								<h2 className="fs-16 text-dark mb-2 fw-bold mt-3">FOTO</h2>
							</div>
							<div className="d-flex flex-wrap">
							{props.formData.photo.map((item, index) => {
								return (
								<div key={index}>
									<ImgFileCard
									id={index}
									name={item.name}
									path={item.path} // Assumi che l'oggetto item abbia la proprietà 'path'
									preview={photoPreviews[index]} // Utilizza l'anteprima generata nel passaggio precedente
									/>
									<div className="image-div">
									<FileCard
										id={index}
										name={item.name}
										setFileName={setPhotoFileName}
										deleteFile={deletePhotoFile}
									/>
									</div>
								</div>
								);
							})}
								<label htmlFor="photo" style={{ margin: "10px" }}>
									<div
										className={`file-upload-div ${
											dragActivePhoto ? "drag-active" : ""
										}`}
										onDragEnter={handleDragPhoto}
										onDragLeave={handleDragPhoto}
										onDragOver={handleDragPhoto}
										onDrop={handleDropPhoto}>
										<img loading="lazy" src={UploadImg} alt="" />
									</div>
								</label>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default PhotoesAndPlans;
