import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import TopHeading from "../../TopHeading";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "../../../assets/css/Supporto.css"


import Box from '@mui/material/Box';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Tips from "../Tips";

import Struttura from "../Struttura";

export default function SupportoAffitti () {

    return (
        <Struttura 
        articoloComponent={<Articolo />}
        fileSystemNavigatorComponent={(props) => <FileSystemNavigator {...props} />}
        breadcrumbName="Affitti"
        />
    )
}




function FileSystemNavigator(props) {
    return (
        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<i class="bi bi-chevron-down"></i>}
            defaultExpandIcon={<i class="bi bi-chevron-right"></i>}
            onNodeSelect={props.handleSelect}
            className="support-index"
        >
                <TreeItem nodeId="1" label="Funzione" />
                <TreeItem nodeId="2" label="Struttura" />
                <TreeItem nodeId="3" label="Pagina Iniziale" >
					<TreeItem nodeId="4" label="Visione Lista" />
					<TreeItem nodeId="5" label="Visione Tabella" />
				</TreeItem>
                <TreeItem nodeId="6" label="Creazione di un affitto" >
					<TreeItem nodeId="7" label="Struttura" />
					<TreeItem nodeId="8" label="Unità" />
					<TreeItem nodeId="9" label="Tipologia" />
					<TreeItem nodeId="10" label="Conduttori" />
					<TreeItem nodeId="11" label="Anagrafica" />
					<TreeItem nodeId="12" label="Registrazione" />
					<TreeItem nodeId="13" label="Contratto" />
					<TreeItem nodeId="14" label="Allegati" />
				</TreeItem>
				<TreeItem nodeId="15" label="Visualizzazione di un affitto" />

                <TreeItem nodeId="16" label="Modificare un affitto" />

        </TreeView>
    );
}

function Articolo() {
  return (
    <div className="support-article">
        <h1>Affitti</h1>
		<h2 style={{ color:'transparent', height: 0}}>Affitti</h2> {/* BSIOGNA METTERE L'H2 A INIZIO PAGINA PER AVERE IL TITOLO DELLA SCHEDA CORRETTO */}


<h2 id="1">FUNZIONE</h2>

<p >La sezione affitti ti consente di
inserire gli affitti relativi alle tue unità.</p>

<p >Questa sezione ti permetterà di
salvare e poter accedere ai dati relativi agli affitti delle tue
unità ovunque tu sia. <br/>
Verrai supportato tramite automatizzazioni nel
calcolo delle spese di registrazione e bolli, nella creazione delle
rate relative per tutta la durata del contratto. Inoltre, se lo
vorrai puoi regolare per cosa e quando ricevere notifiche dalla
sezione Impostazioni &gt; Scheda Notifiche,
grazie all’integrazione con la nostra sezione Alert verrai avvisato
delle scadenze e incombenze correlate ai tuoi affitti (Aggiornamento
Istat, Data di fine locazione… )</p>
<img loading="lazy" src="https://i.postimg.cc/zGkHD9r2/affitti.png"/>


<h2 id="2">STRUTTURA DELLA PAGINA</h2>

<p >In alto a destra trovi il pulsante
rosso “+”. Cliccando su questo pulsante aprirai il modulo di
creazione di un affitto.</p>
<img loading="lazy" src="https://i.postimg.cc/NFrQyrrf/top.png"/>
{/* top
 */}



<p>Poco sotto, In alto a sinistra troverai
la barra di ricerca, per semplificare la ricerca di un affitto
specifico tramite identificativo.</p>

<p >In alternativa trovi a sinistra il
bottone filtra (icona dell’imbuto) che ti permette di filtrare gli
affitti per i seguenti criteri</p>

<ul>
	<li><p >periodo di creazione</p></li>
	<li><p >tipologia, per filtrare tra
	affitti e proposta di affitto</p></li>
	<li><p >unità, per trovare tutti gli
	affitti correlati a una specifica unità</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/gkV8xmXY/filtra.png" />

<p >A fianco al pulsante filtra, trovi il
pulsante ordina (icona con tre righe orizzontali) che ti permette di</p>

<ul>
	<li><p >Ordinare gli elementi in modo
	alfabetico dalla A alla Z o viceversa</p></li>
	<li><p >Ordinare gli elementi mettendo
	prima quelli creati più di recente o in alternativa mettendo per
	primi quelli creati meno recentemente</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/CKQf3Kt4/ordina.png" />


<p >A destra prima del bottone filtro,
trovi il bottone “CAMBIO VISUALIZZAZIONE”, che ti permette di
passare dalla visione lista a quella tabellare e viceversa.</p>
<img loading="lazy" src="https://i.postimg.cc/QtKWh3ZZ/cambia-visualizzazione.png"  />


<h2 id="3">PAGINA INIZIALE</h2>
<p >Nella pagina iniziale avrai visione
degli affitti creati  sotto forma di lista o tabella.</p>

<h3 id="4">VISIONE LISTA</h3>
<p >Per ogni unità troverai un elemento (o
card) relativa di riepilogo contenente:</p>
<ul>
	<li><p >Identificativo dell’immobile</p></li>
	<li><p >Inizio locazione</p></li>
	<li><p >Fine periodo iniziale</p></li>
	<li><p >Fine rinnovo (se è stato
	definito)</p></li>
</ul>

<img loading="lazy" src="https://i.postimg.cc/nzrFJWFm/card.png"  />




<p >Dalla card puoi eseguire due azioni:</p>
<ul>
	<li><p ><strong>Eliminare l’affitto</strong>: devi
	cliccare sull’icona col cestino e poi dare una seconda conferma
	nella finestra pop up che apparirà. </p></li>
	<li><p ><strong>Esporta informazioni</strong>: devi
	cliccare sul tasto contenente una freccia che va verso il basso,
	apparirà un sottomenu e potrai scegliere se esportare i dati in
	formato PDF o CSV (L'importazione in formato CSV -Comma-Separated
	Values è un modo semplice ed efficace per esportare e condividere
	dati in un formato tabellare compatibile con molti software. Può
	essere aperto e visualizzato con facilità utilizzando applicazioni
	come Excel, Google Fogli, Numbers, Blocco note etc.)</p></li>
</ul>

<h3 id="5" >VISIONE
TABELLA</h3>
<p >Potrai visualizzare le tue unità in
formato tabellare. In questo formato saranno visualizzabili più
informazioni rispetto alla card.</p>


<h2 id="6" >CREAZIONE DI UN AFFITTO</h2>

<h3 id="7">Struttura</h3>

<p >Dalla pagina iniziale della sezione
Affitti, cliccando il pulsante rosso “+” in alto a destra si
avvia il processo di creazione di un affitto.</p>

<p >Il processo di creazione articola in 7
passaggi, che puoi visualizzare nella barra in alto.</p>
<ul>
	<li><p >Unità</p></li>
	<li><p >Tipologia</p></li>
	<li><p >Conduttori</p></li>
	<li><p >Anagrafica</p></li>
	<li><p >Registrazione</p></li>
	<li><p >Contratto</p></li>
	<li><p >Allegati</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/Bn310Jd1/stepper.png"/>


<p >Per andare a un passaggio successivo
premere il pulsante “AVANTI” o “INDIETRO” qualora voleste
tornare a un passaggio precedente.</p>
<img loading="lazy" src="https://i.postimg.cc/7hvJLWqG/indietro-avanti.png" />


<p >Per uscire dalla profilazione clicca
sulla freccia che trovi a sinistra nella barra in alto.</p>
<img loading="lazy" src="https://i.postimg.cc/nLRpVV6S/indietro.png"/>

<p >I campi obbligatori della profilazione
verranno indicati col simbolo rosso asterisco (<font color="#ff0000">*</font>)</p>

<h3 id="8">UNITA’</h3>

<p > In questa sezione puoi inserire
l’unità per il quale stai creando l’affitto,</p>

<p >L’inserimento può avvenire in due
modi: nuova creazione e selezione.</p>
<ul>
	<li><p >Cliccando sul pulsante “NUOVO”
	ti si aprirà la profilazione relativa, che potrai procedere a
	compilare e creare. Dopo la creazione verrai rimandato al passaggio
	“UNITA’”. Clicca quindi il pulsante “SELEZIONA” e
	seleziona l’unità di tuo interesse.</p></li>
	<li><p >Se invece hai precedentemente
	creato l’unità, basterà cliccare il pulsante “SELEZIONA” e
	selezionare l’unità di tuo interesse.</p></li>
</ul>
<p >Verrà creata una (card) contenente
l’unità selezionata.</p>
<img loading="lazy" src="https://i.postimg.cc/8PMr97nS/unita.png"/>


<p >Se hai selezionato erroneamente
un’unità, ti basterà cliccare sulla x in alto a destra per
rimuoverlo.</p>
<p >All’interno della card troverai delle
informazioni di riepilogo relative all’unità</p>
<ul>
	<li><p >Indirizzo</p></li>
	<li><p >Civico</p></li>
	<li><p >Interno</p></li>
	<li><p >Città</p></li>
	<li><p >Provincia</p></li>
</ul>

<p >Puoi aggiungere all’affitto anche
altri immobili o pertinenze:</p>
<p >dopo aver selezionato la prima unità,
apparirà sottostante uno spazio dove potrai inserire queste
informazioni.</p>


<Tips variant="suggestion" content="Ti consigliamo di procedere alla
creazione dell’affitto DOPO aver creato l’unità relativa
per un processo più semplice e fluido." />

<h3 id="9">TIPOLOGIA</h3>
<p >In questa sezione potrai definire le
varie tipologie relative all’affitto e il relativo  identificativo.</p>
<p >L’identificativo è un nome che ti
permette di riconoscere l’affitto più facilmente, la scelta e
strutturazione dell’identificativo è del tutto personale.</p>

<h3 id="10">CONDUTTORI</h3>
<p >In questa sezione potrai inserire:</p>
<ul>
	<li><p>Conduttori</p></li>
	<li><p>Garanti</p></li>
</ul>
<p >L’inserimento può avvenire in due
modi: nuova creazione e selezione.</p>
<ul>
	<li><p >Cliccando sul pulsante “NUOVO”
	vi si aprirà la profilazione relativa, che potrete procedere a
	compilare e creare. Dopo la creazione verrete rimandati al passaggio
	“CONDUTTORI”. Cliccate quindi sul pulsante “SELEZIONA” e
	selezionate il soggetto di vostro interesse.</p></li>
	<li><p >Se invece avete precedentemente
	creato gli utenti, basterà cliccare il pulsante “SELEZIONA” e
	selezionate il soggetto di vostro interesse.</p></li>
</ul>
<p >Verranno creati uno o più elementi
(card) contenti i soggetti selezionati.</p>
<p >Se avete selezionato erroneamente un
soggetto, vi basterà cliccare sulla x in alto a destra per
rimuoverlo dall’unità in questione.</p>
<p >All’interno della card troverete
delle informazioni di riepilogo relative al soggetto</p>
<ul>
	<li><p >Indirizzo</p></li>
	<li><p >Civico</p></li>
	<li><p >Città</p></li>
	<li><p >Telefono</p></li>
	<li><p >Tipo di conduttore</p></li>
</ul>
<img loading="lazy" src="https://i.postimg.cc/WpQk5G88/conduttori.png"/>


<Tips variant="info" content="I
subentri potranno essere inseriti solo in un secondo momento, dopo la
creazione di un affitto." />

<h3 id="11">ANAGRAFICA</h3>
<p>In questa sezione potrai inserire i dati del contratto di affitto.</p>
<p className="subtitle fw-bold">Scadenze</p>

	<Tips variant="automatization" content="Creiamo in automatico
	promemoria e scadenze per le quali riceverai notifiche (che puoi
	regolare e definire nella sezione Impostazioni &gt; Notifiche)" />

	<p className="subtitle">Aggiornamento Istat</p>
<p className="mt-3">DATO
CHE ATTIVA L’AUTOMATIZZAZIONE</p>
<p>Questa
automatizzazione dipende dai dati che inserisci nella sezione
"Aggiornamento ISTAT" &gt; campo “Aggiornamento a partire dal”</p>
<p className="mt-3">FUNZIONE</p>
<p >Creeremo dei
promemoria a cadenza annuale per tutta la durata del contratto
relativi all’Aggiornamento Istat, nella sezione Alert</p>


<p className="text-uppercase">Fine periodo iniziale</p>

<p  className="mt-3 subtitle">DATO
CHE  ATTIVA L’AUTOMATIZZAZIONE</p>
<p >questa
automatizzazione dipende dai dati che inserisci nella sezione "Durata e date" &gt; campo “Fine periodo iniziale”</p>
<p className="mt-3 subtitle">FUNZIONE</p>
<p >Creeremo un
promemoria per questo dato nella sezione Alert</p>


<p className="text-uppercase">Fine rinnovo: promemoria</p>

<p  className="mt-3 subtitle">DATO
CHE  ATTIVA L’AUTOMATIZZAZIONE</p>
<p >Questa
automatizzazione dipende dai dati che inserisci nella sezione DURATA
E DATE &gt; campo “Fine rinnovo”</p>
<p className="mt-3 subtitle">FUNZIONE</p>
<p >Creeremo un
promemoria per questo dato nella sezione Alert</p>


<p className="text-uppercase">Data entro cui può essere
		inviata la disdetta</p>

<p  className="mt-3 subtitle">DATO
CHE  ATTIVA L’AUTOMATIZZAZIONE</p>
<p >Questa
automatizzazione dipende dai dati che inserisci nella sezione
Preavviso, in mesi,della disdetta del rinnovo &gt; campo “Data
entro cui può essere inviata la disdetta”</p>
<p className="mt-3 subtitle">FUNZIONE</p>
<p>
	Creeremo un promemoria per questo dato nella sezione Alert
</p>

<p className="text-uppercase">Rate</p>
<p >Creeremo le rate relative al
	canone e agli oneri accessori per tutta la durata del contratto</p>

<p className="mt-3 subtitle">DATI CHE  ATTIVANO  L’AUTOMATIZZAZIONE</p>
<p>Devono essere
compilati i dati di inizio locazione e fine locazione.</p>
<p >Deve essere
compilato il campo “canone attuale” ed eventualmente anche il
campo “Anticipo oneri accessori”</p>
<p >Deve essere
spuntata la casella “Crea rate relative a questo affitto”</p>
<p className="mt-3 subtitle" >FUNZIONE</p>
<p >Creeremo le rate
relative a questo affitto, per tutta la durata del contratto, che
potrai trovare nella sezione AMMINISTRAZIONE FINANZIARIA &gt; Rate</p>

<h3 id="12">REGISTRAZIONE</h3>
<p >In questa sezione potrai inserire i
dati relativi alla registrazione del contratto.</p>


	<Tips variant="automatization" content="Automatizziamo il calcolo delle imposte di bollo e registro" />

	<p className="text-uppercase">Spese registrazione</p>

<p className="mt-3 subtitle" >DATI CHE  ATTIVANO  L’AUTOMATIZZAZIONE</p>
<p >Devono essere
compilati i dati :
</p>
<ul>
<li><p >% sulle imposte a
carico del conduttore (scheda REGISTRAZIONE)</p></li>
<li><p >% sulle imposte a
carico del locatore (scheda REGISTRAZIONE)</p></li>
<li><p >Casella di spunta
“Cedolare secca” (scheda REGISTRAZIONE)</p></li>
<li><p >Inizio locazione
(scheda ANAGRAFICA)</p></li>
<li><p >Canone attuale
(scheda ANAGRAFICA)</p></li>
</ul>
<p className="mt-3 subtitle">FUNZIONE</p>
<p >Nella sezione
“Spese registrazione contratto” creeremo una tabella di riepilogo
con le spese di registrazione del contratto e relative ripartizioni
tra conduttore e locatore.</p>
<p >Dopo aver pagato le
spese di registrazione, e spuntato la casella “PAGATO” in
tabella, cliccando sul bottone “CREA PRIMA NOTA” potrai creare la
prima nota relativa a questa spesa. Nei paragrafi seguenti
spiegheremo in maniera approfondita la sezione “PRIMA NOTA”.</p>

<p className="text-uppercase">Imposte di Bollo</p>

<p className="mt-3 subtitle">DATI CHE  ATTIVANO  L’AUTOMATIZZAZIONE</p>
<p >Devono essere
compilati i dati nelle sezioni:</p>
<ul>
<li><p>Imposte di Bollo</p></li>
<li><p>Bolli allegati</p></li>
<li><p >Importi delle
marche da bollo in € (abbiamo impostato degli importi di default
che potrai modificare, se necessario)</p></li>
</ul>


<p className="mt-3 subtitle">FUNZIONE</p>
<p >Creeremo una
tabella di riepilogo con le imposte di bollo del contratto e relative
ripartizioni tra conduttore e locatore</p>
<p >Dopo aver pagato le
spese di registrazione, cliccando sul bottone in tabella “CREA
PRIMA NOTA” potrai creare la prima nota relativa a questa spesa.</p>

<h3 id="13">CONTRATTO</h3>
<p>Se non possiedi già un contratto di
locazione puoi usare uno dei nostri modelli, selezionando dalla lista
quello adatto alla situazione.</p>

<p >Sulla base delle informazioni che hai
inserito nelle schede precedenti, automatizziamo l’inserimento dei
dati di</p>
<ul>
	<li><p >conduttori</p></li>
	<li><p >durata</p></li>
	<li><p >data inizio locazione</p></li>
	<li><p >data di fine locazione</p></li>
	<li><p >rinnovo</p></li>
	<li><p >preavviso della disdetta dei
	conduttori</p></li>
	<li><p >canone</p></li>
	<li><p >cauzione</p></li>
</ul>

<p >Questi contratti sono editabili, quindi
potrai inserire/rimuovere/modificare dati al suo interno per
adattarlo alle tue necessità.</p>
<p >Infine potrai stampare o esportare il
contratto creato,cliccando sui bottoni in alto a destra.</p>
<img loading="lazy" src="https://i.postimg.cc/y8hYX9pD/contratto.png"/>


<p >L’esportazione e la stampa del
contratto avvengono in formato “FOGLIO A USO BOLLO”,
costituito da massimo 25 righe, margine sinistro di 26,5 mm, margine
destro d 53mm.</p>


<h3 id="14">ALLEGATI</h3>
<p >Qui potrai caricare file e documenti
relativi al tuo affitto.</p>


<p >Infine clicca sul pulsante SALVA in
basso a destra, per completare la creazione dell’Affitto</p>
<img loading="lazy" src="https://i.postimg.cc/jjsq85BZ/salva.png"/>

<h2 id="15">VISUALIZZAZIONE DI UN AFFITTO</h2>
<p >Cliccando su una card della lista o una
riga della tabella potrai visualizzare i dati dell’AFFITTO che hai
creato.</p>

<img loading="lazy" src="https://i.postimg.cc/DZ2wjcYW/open-card.gif"/>

<p >La  pagina di visualizzazione è
suddivisa in “schede” uguali a quelle presenti in fase di
creazione, in modo tale saprai che i dati che hai inserito in una
scheda durante la CREAZIONE puoi trovarlo nella relativa scheda
durante la  VISUALIZZAZIONE</p>

<img loading="lazy" src="https://i.postimg.cc/bYGn0Cxd/vista.png"/>

<p >In alto a destra trovi i pulsanti di
stampa, per stampare i dati di ogni singola scheda, e esportazione,
per esportare i dati di una scheda specifica. Potrai scegliere se
esportare i dati in formato PDF o CSV (L'importazione in formato CSV
-Comma-Separated Values è un modo semplice ed efficace per esportare
e condividere dati in un formato tabellare compatibile con molti
software. Può essere aperto e visualizzato con facilità utilizzando
applicazioni come Excel, Google Fogli, Numbers, Blocco note etc.)</p>

<img loading="lazy" src="https://i.postimg.cc/SsPyNGRv/esporta.png"/>

<h2 id="16">MODIFICARE I DATI DI UN AFFITTO</h2>
<p >Dalla schermata di visualizzazione di
un’affitto, cliccando il pulsante MODIFICA in alto a destra potrai
modificare e/o aggiungere dati.</p>

<p >Per salvare le modifiche effettuate e
tornare in modalità VISUALIZZAZIONE dovrai poi cliccare sul pulsante
“SALVA MODIFICHE”.</p>
<img loading="lazy" src="https://i.postimg.cc/CKmh90gY/salva-modifiche.png"/>



    </div>
  );
}

