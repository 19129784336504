import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import NumericInput from "react-numeric-input";
import "../../../assets/css/calcoli/page1.css";
import "../../../assets/css/calculator.css";
import ButtonRm from "../../ButtonRm";
import Select from 'react-select';
import axios from 'axios'
import ExportButton from "../../Membri/Fornitori/ExportButton";
import ShareChat from "../../ShareChat";
import CustomBadge from "../../CustomBadge";


/* function MyModal(props) {
  return (
    <Modal
      {...props}
      width="50%"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-title">Aggiungi Indice</div>
        <div style={{ margin: "5%" }}>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="sub-title">Mese</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Aprile</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label className="sub-title">Anno</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>2022</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label className="sub-title">Indice</Form.Label>
              <Form.Select aria-label="Default select example">
                <option>0</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>
          </Form>

          <div className="space-top">
            <button
              className="btn-primary1 "
              style={{ width: "100%", height: "45px" }}
            >
              Aggiungi
            </button>
          </div>

          <div className="space-top " style={{ color: "black" }}>
            Annulla
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onHide}>Close</button>
      </Modal.Footer>
    </Modal>
  );
}
 */

export default function Page1() {
  const [modalShow, setModalShow] = React.useState(false);
  const [attuale, setAttuale] = useState(null);
  const [aggiornato, setAggiornato] = useState(0);
  const [percent, setPercent] = useState(100);
  const [att, setAtt] = useState(null);
  const [isShowDetail, setIsShowDetail] = useState(false);

  /* HANDLE SELECT WITH SEARCH */
  const [isSearchable, setIsSearchable] = useState(true);

  //GET INDEX DATA FROM API
  const [indexFoi, setIndexFoi] = useState([])

  const fetchIndex = async () => {
    try {
      const response = await axios.get('https://anthill-service.onrender.com/getIstatIndexList');
      setIndexFoi(response.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchIndex()
  }, []);

  /* OPTIONS FOR SELECT, EACH OPTION SHOULD BE AN OBJECT WITH PROPRERTIES "value" AND "label" */

  function numberToMonth(number) {
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    return months[number - 1];
  }

  const [currentIndex, setCurrentIndex] = useState(null)

  function handleCurrentIndex(selectedOption){
    setCurrentIndex(selectedOption);
  }


  let selectOptions = indexFoi ? indexFoi.map((obj) => {
    let formattedValue = (obj.value);
    let formattedLabel = `${obj.value}% - ${numberToMonth(obj.month)} ${obj.year}`;
    return { value: formattedValue, label: formattedLabel };
  }) : [];

  const onCalclicked = () => {
    let str = currentIndex?.value //valore da sostituire con value dell'indice selezionato
    let strToNum = parseFloat(str)
    setAtt(attuale * (1 + (percent / 100) * (strToNum / 100)));
    setIsShowDetail(true);
    setAggiornato(attuale * (1 + (percent / 100) * ( strToNum / 100)));
  };

  function spinFormat(num) {
    return num + "%";
  }

  //MESE ANNO PER TABELLA
  let datePart = currentIndex?.label.split(" - ")[1] ?? "";
  
  //DATI PER ESPORTAZIONE
  const item = {
    ['Canone attuale']: `€ ${attuale}`,
    ['% sulla variazione']: `${percent} % di ${currentIndex?.value}`,
    ['mese / anno']: datePart,
    ['Canone aggiornato']:`€ ${parseFloat(aggiornato).toFixed(2)}`

  }


  return (
    <div className="page1">
      <Row>
        <Col>
          <Form.Group>
            <Form.Label className="sub-title">
              Variazione dell’indice FOI dell’ISTAT
            </Form.Label>
            <Select
              className="basic-single w-70"
              classNamePrefix="select"
              value={currentIndex}
              isSearchable={isSearchable}
              name="foiIndex"
              options={selectOptions}
              placeholder="Seleziona un'opzione"
              noOptionsMessage={() => "Nessuna opzione trovata"}
              onChange={handleCurrentIndex}
            />
            {/* <Form.Select
              aria-label="Default select example"
              className="form-select w-70"
            >
              <option>5-8% - APRILE 2022</option>
            </Form.Select> */}
          </Form.Group>
        </Col>
      </Row>
      <Row className="sub-title" style={{ marginLeft: "0%", marginTop: "5%" }}>
        Percentuale, da applicare, sulla variazione
      </Row>
      <Row>
        <Col className="w-50">
          <NumericInput
            step={1}
            precision={2}
            value={percent}
            className="spin"
            format={spinFormat}
            onChange={(value) => setPercent(value)}
            min={0} 
            max={100}
            strict
          />
        </Col>
      </Row>

      {/* <div style={{ marginLeft: "2%", marginTop: "5%", }}>
                    <Image
                        src={line}
                        width="100%" height='100%'
                    />
                </div> */}

      <div class="label-heading mb-3 d-block w-94 mt-15">
        <div class="label-text rel d-inline-block px-2 title-padding">
          <h1 class="fs-16 text-white text-uppercase">CANONE</h1>
        </div>
        <div class="label-heading1"></div>
      </div>

      {/* <Row className="bg-info">
                <Col className='col-xl-4 col-md-4 col-7'>

                    <div className='sub-title'  > Canone attuale</div>
                    <div className="divInput">

                        <input type='number' className="inputText" value={attuale} onChange={(e) => setAttuale(e.target.value)}></input>
                        <span className="spanInput1">€</span>
                    </div>

                </Col>
                <Col className='col-xl-1 col-md-1 col-1'></Col>
                <Col className='col-xl-2 col-md-2 col-3' >
                     <div className='btn-second small btn-margin' onClick={() => onCalclicked()}>Calcola</div> 
                    <ButtonRm variant="secondary">Calcola</ButtonRm>
                </Col>
                <Col className='col-xl-1 col-md-1 col-0'></Col>
                <Col className='col-xl-3 col-md-3 col-11' >
                    <div className='sub-title' >Canone aggiornato</div>
                    <div className='input-text'>
                        € {parseFloat(att).toFixed(3)}
                    </div>
                </Col>
            </Row> */}

      <Row className="align-items-center justify-content-between ">
        <Col>
          <div className="sub-title">Canone attuale<span className="mandatory-field">*</span> 									<CustomBadge variant="custom-badge-orange">Campo obbligatorio</CustomBadge>
</div>
          <div className="divInput">
            <span className="spanInput1">€</span>
            <input
              type="number"
              step="0.01" 
              value={attuale? attuale.toLocaleString('it-IT') : attuale}
              className="input-calc-symbol "
              onChange={(e) => setAttuale(e.target.value)}
            ></input>
          </div>
        </Col>
        <Col className="text-center mt-2">
          <ButtonRm
            variant="secondary"
            size="small"
            onClick={() => onCalclicked()}
            disabled={attuale && currentIndex?.value ? false : true}
          >
            Calcola
          </ButtonRm>
        </Col>
        <Col className="me-2">
          <div className="sub-title">Canone aggiornato</div>
          <div className="input-text">€ {att? (parseFloat(att).toFixed(2)).toLocaleString('it-IT', {minimumFractionDigits: 2}): ""}</div>
        </Col>
      </Row>

      {isShowDetail && (
        <div>
          {/* <Row style={{  marginTop: '10%' }}>
                        <Col className='tb-title'> Canone aggiornato = </Col>
                        <Col className='tb-title'> Canone attuale + </Col>
                        <Col className='tb-title'> % sulla variazione</Col>
                        <Col className='tb-title' xs={3}> mese/anno</Col>
                    </Row>
                    <Row style={{  marginTop: '0%' }}>
                        <Col className='tb-content'>{parseFloat(aggiornato).toFixed(3)}€</Col>
                        <Col className='tb-content'> €{attuale} </Col>
                        <Col className='tb-content'> {percent}% di 5,80%</Col>
                        <Col className='tb-content' xs={3}> aprile 2022</Col>
                    </Row> */}
          <div className="text-end mt-4">
{/*           <ShareChat styleBtn="top-btn-icon ms-2" />
 */}
            <ExportButton data={item} mapping={{}} classes="top-btn-icon ms-2"/>
          </div>
          <Row style={{ padding: "15px" }}>
            <Col className="col-xl-3 col-md-3 col-6 p-0">
              <Col className="tb-title"> Canone attuale + </Col>
              <Col className="tb-content"> €{attuale} </Col>
            </Col>
            <Col className="col-xl-3 col-md-3 col-6 p-0">
              <Col className="tb-title"> % sulla variazione</Col>
              <Col className="tb-content"> {percent}% di {currentIndex?.value}</Col>
            </Col>
            <Col className="col-xl-3 col-md-3 col-6 p-0 ">
              <Col className="tb-title"> mese/anno = </Col>
              <Col className="tb-content"> {datePart} </Col>
            </Col>
            <Col className="col-xl-3 col-md-3 col-6 p-0">
              <Col className="tb-title"> Canone aggiornato </Col>
              <Col className="tb-content">
                €{parseFloat(aggiornato).toFixed(2)}
              </Col>
            </Col>
          </Row>
        </div>
      )}
      <div style={{ height: "100px" }}></div>
     {/*  <MyModal show={modalShow} onHide={() => setModalShow(false)} /> */}
    </div>
  );
}
