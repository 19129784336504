import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import "../../assets/css/Membri.css";
import PencilIcon from "../../assets/images/pencil.svg";
import PrintIcon from "../../assets/images/print.svg";
import TrashBinIcon from "../../assets/images/trashbin.svg";
import ButtonRm from "../ButtonRm";

export default function CreateAccount(props) {
  const componentRef = useRef();
  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    type: "conto corrente bancario",
    iban: "",
    header: "",
    bank: "",
    agency: "",
    province: "",
    abi: "",
    cab: "",
    conto: "",
    cin: "",
    bic: "",
    siaCode: "",
    cucSepaCode: "",
  });

  const reset = () => {
    setFormData({
      name: "",
      type: "conto corrente bancario",
      iban: "",
      header: "",
      bank: "",
      agency: "",
      province: "",
      abi: "",
      cab: "",
      conto: "",
      cin: "",
      bic: "",
      siaCode: "",
      cucSepaCode: "",
    });
  };


  const onChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    props.setShow(false);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const save = () => {
    props.addBankAccount(formData);
    handleClose();
    reset();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "0 none" }} className="mt-2">                 
        <h5>Nuova risorsa contabile</h5>
      </Modal.Header>
      <Modal.Body style={{maxHeight: "400px", overflowY: "auto", borderRadius:".5rem"}}>
        <fieldset disabled={disable === false ? false : true}>
            <div
              className="d-flex justify-content-between bg-danger"
              style={{ alignItems: "center" }}
            >
              <div style={{ display: disable === false ? "none" : "" }}>
                <img
                  src={TrashBinIcon}
                  style={{ marginRight: "9px", cursor: "pointer" }}
                  onClick={() => {
                    props.setDel(true);
                    handleClose();
                  }}
                />
                <img
                  src={PencilIcon}
                  style={{ marginRight: "9px", cursor: "pointer" }}
                  onClick={() => {
                    setDisable(false);
                  }}
                />
                <img
                  src={PrintIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePrint()}
                />
              </div>
            </div>
            <div ref={componentRef}>
              <div className="row mt-0">
                <div className="col-md-12 mb-2">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16">
                      Nome
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      name="name"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Tipo
                    </label>
                    <select
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50 bg-white"
                      name="type"
                      value={formData.type}
                      onChange={onChange}
                    >
                      <option>Conto Corrente Bancario</option>
                      <option>Cassa</option>
                    </select>
                  </div>
                </div>
                {props.settings && <div className="col-md-12 mb-2">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16">
                      Saldo corrente**
                    </label>
                    <input
                      type="text"
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                    />
                  </div>
                </div>}
              </div>
              <div className="label-heading my-4 d-block w-100">
                <div className="label-text rel d-inline-block py-2 px-2">
                  <h1 className="fs-14 text-white text-uppercase fw-semibold">
                    DATI DEL CONTO
                  </h1>
                </div>
                <div className="label-heading1"></div>
              </div>
              <div className="row gy-2">
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      IBAN
                    </label>
                    <input
                      type="text"
                      name="iban"
                      value={formData.iban}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Provincia
                    </label>
                    <input
                      type="text"
                      name="province"
                      value={formData.province}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Intestazione
                    </label>
                    <input
                      type="text"
                      name="header"
                      value={formData.header}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-flex">
                  <div
                    className="d-flex flex-column"
                    style={{ marginRight: "15px", width: "13%" }}
                  >
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      ABI
                    </label>
                    <input
                      type="text"
                      name="abi"
                      value={formData.abi}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ marginRight: "15px", width: "13%" }}
                  >
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      CAB
                    </label>
                    <input
                      type="text"
                      name="cab"
                      defaultValue={formData.cab}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ marginRight: "15px", width: "27%" }}
                  >
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      CONTO
                    </label>
                    <input
                      type="text"
                      name="conto"
                      value={formData.conto}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ marginRight: "15px", width: "13%" }}
                  >
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      CIN
                    </label>
                    <input
                      type="text"
                      name="cin"
                      defaultValue={formData.cin}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className="d-flex flex-column w-124"
                    style={{ marginRight: "15px", width: "23%" }}
                  >
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      BIC/SWIFT
                    </label>
                    <input
                      type="text"
                      name="bic"
                      value={formData.bic}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Banca
                    </label>
                    <input
                      type="text"
                      name="bank"
                      value={formData.bank}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Codice SIA
                    </label>
                    <input
                      type="text"
                      name="siaCode"
                      value={formData.siaCode}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Agenzia/Comune
                    </label>
                    <input
                      type="text"
                      name="agency"
                      value={formData.agency}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex flex-column">
                    <label htmlFor="" className="f-label fs-16 d-flex align-items-center
mb-2">
                      Codice CUC SEPA
                    </label>
                    <input
                      type="text"
                      name="cucSepaCode"
                      value={formData.cucSepaCode}
                      className="border border-secondary fs-16 text-dark rounded-3 border-opacity-50"
                      onChange={onChange}
                    />
                  </div>
                </div>
                {/* <div className="col-md-6 mb-2">
                  <input
                    type="checkbox"
                    id="disabilitato"
                    name="disabilitato"
                    //onChange={() => checkClick()}
                  />
                  <label htmlFor="disabilitato" style={{ marginLeft: "10px" }}>
                    Conto disabilitato
                  </label>
                </div> */}
              </div>
            </div>
        </fieldset>
        <div className="d-flex align-items-center mt-5 form-last-btns justify-content-end mb-4">
          <ButtonRm variant="tertiary" onClick={() => {
            handleClose();
            }} className="me-2" >Annulla</ButtonRm>
          <ButtonRm variant="primary" onClick={() => save()}>Salva</ButtonRm>
        </div>
        </Modal.Body>
    </Modal>
  );
}
