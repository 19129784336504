import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';

//import dashboard from '../../assets/images/dashboard-rm.svg'
//import responsive from '../../assets/images/responsive.svg'
import supplier from '../../assets/images/Bricklayer-amico 1.svg'

import btnApple from "../../assets/images/btn-apple.png"
import btnGp from "../../assets/images/btn-gp.png"
import mockup from "../../assets/images/mockup-intermediazione.png"

import support from  "../../assets/images/disc3.svg"

import logo from "../../assets/images/Group 1189.svg"

import disc from "../../assets/images/disc2.svg"

import { Link } from 'react-router-dom';


import discAlt from "../../assets/images/disc1.svg"

import Footer from './Footer';



export default function Suggerimenti (){
    return (
        <div style={{background:'#fff', position: "relative"}}>
            <div style={{background: ''}}>
                <Container className="px-3 px-md-0">

                <Navbar bg="" data-bs-theme="light" className='d-flex align-items-center pt-3' style={{background: "transparent"}}>
                        <Navbar.Brand href="/landing" className='me-3'>
                            <Image src={logo} width={"85px"}/>
                        </Navbar.Brand>
                        <Nav className="me-auto bg-alert w-100 d-flex align-items-center">                            
                            <div className='ms-auto d-flex align-items-center'>
                                <Button variant="outline-primary me-3" style={{color: "#0F1B4C", borderColor: "#0F1B4C"}}>Sei un gestore?</Button>
                            </div>
                        </Nav>
                    </Navbar>

                    <Row className="flex-column mb-2" style={{position: "relative"}}>
                        <Col className="d-flex justify-content-center" style={{padding: "5rem 0rem 2rem 0rem"}}>
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{maxWidth: "1000px"}}>
                                <p className='text-uppercase'>Real meta property</p>
                                <h1 className="text-center fs-40 fs-md-50 syne-font">Consiglialo al tuo gestore</h1>
                            </div>
                        </Col>



                     {/*    <Image src={disc} style={{position:"absolute", width:"200px"}} />  */}
                        
                    </Row>

                    <Row className="justify-content-center align-items-center mx-auto" style={{maxWidth: "1100px"}}>
                        <Col md={8}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Il tuo nome</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Il nome del tuo gestore</Form.Label>
                                <Form.Control type="text" />
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInput1 " className="mb-3">
                                <Form.Label>L'email del tuo gestore</Form.Label>
                                <Form.Control type="email"  />
                            </Form.Group>
                            
                                                            <Button size="lg" className="mb-3" variant="primary" style={{background: "#0F1B4C", borderColor: "#0F1B4C"}}>Invia</Button>

                        </Form>
                        </Col>
                        
                    </Row>



                    <Footer />

                   

                </Container>
            </div>

            <Image src={support} width="250px" style={{position: "fixed", bottom: "0px", right: "0px"}} className='d-none d-lg-block'/>


        </div>
    )
}