
//you should pass as props for this component a function (setSearch) to set
//search term as current e.target.value

import "../assets/css/SearchInput.css"
import searchIcon from "../assets/images/icon-sidebar/search.svg"


export default function SearchInput(props){

    return (
        <div className="search-fence d-flex align-items-center bg-white p-2 rounded-3 " > 
            <img loading="lazy" src={searchIcon} className="me-2"/>
            <input
            type="text"
            name=""
            placeholder="Cerca"
            className="flex-shink-0 border-0 w-100 fs-16"
            onChange={(e) => {
                props.setSearch(e.target.value);
            }}
        />
        </div>
    )
}